//import angular from 'angular';
    
    angular.module("ui.bootstrap.dropdown")
        .directive('dropdownSubmenu', function () {
        return {
            restrict: 'C',
            priority: 1,
            link: function (scope, element, attrs) {
                var menuContainer = element;
                var menuItem = element.children('a');
                var subMenuList = element.find('.dropdown-menu');

                menuContainer.unbind('mouseleave');

                menuItem.unbind('click');

                menuItem.unbind('mouseenter');

                subMenuList.unbind('mouseleave');

                //////////////////////////////////////////////////
                subMenuList = element.find('> .dropdown-menu');

                menuContainer.bind('mouseleave', function (event) {
                    subMenuList.css({
                        "display": "none"
                    });
                });

                menuItem.bind('click', function (event) {
                    event.preventDefault();
                });

                menuItem.bind('mouseenter', function (event) {
                    var windowWidth = $(window).width();
                    var subMenuListWidth = subMenuList.width();
                    var subMenuListLeft = subMenuList.offset().left;

                    if (subMenuListWidth + subMenuListLeft > windowWidth) {
                        //var subMenuListPrevious = subMenuList.parent().closest(".dropdown-menu").length > 0 ? subMenuList.parent().closest(".dropdown-menu")[0] : undefined;

                        //if (subMenuListPrevious) {
                        //    subMenuListPrevious = $(subMenuListPrevious);
                        //}
                        var left = subMenuListWidth - 10;
                        subMenuList.css("left", "-" + left + "px");
                        subMenuList.css("top", "20px");
                    }

                    subMenuList.css({
                        "display": "block"
                    });
                });

                subMenuList.bind('mouseleave', function (event) {
                    subMenuList.css({
                        "display": "none"
                    });
                });
            }
        };
    });
