//import angular from 'angular';
import * as _ from 'underscore';



    angular.module("app.site").controller("forecastVehicleMonthlyUsageController", forecastVehicleMonthlyUsageController);

    // @ts-ignore
    forecastVehicleMonthlyUsageController.$inject = ['$scope', '$state', '$rootScope', 'amtConstants', 'amtCommandQuerySvc',
        'amtXlatSvc', 'forecastingSvc', 'notifySvc', 'WindowFactory', 'errorReporter', 'helperSvc', '$q',
        'confirmSvc', '$timeout', '$window', 'exportSvc'];

    // ReSharper disable once InconsistentNaming
    function forecastVehicleMonthlyUsageController($scope, $state, $rootScope, amtConstants, amtCommandQuerySvc,
        xlatSvc, forecastingSvc, notifySvc, WindowFactory, errorReporter, helperSvc, $q, confirmSvc, $timeout, $window, exportSvc) {

        var vm = this;
        $scope.vm = this;

        vm.apiUrls = amtConstants.url;

        var minimumMonthColumnWidth = 100;

        vm.columnWidths = {
            serialNumber: 200,
            monthlyAverage: 100,
            clear: 85
        };

        vm.forecastId = $rootScope.$stateParams.forecastid ? $rootScope.$stateParams.forecastid : 0;
        vm.vehiclespecificationid = $rootScope.$stateParams.vehiclespecificationid ? $rootScope.$stateParams.vehiclespecificationid : 0;
        vm.vehicleSpecName = $rootScope.$stateParams.vehiclespecname ? $rootScope.$stateParams.vehiclespecname : '';
        vm.forecastStartDate = $rootScope.$stateParams.forecaststartdate ? $rootScope.$stateParams.forecaststartdate : '';
        vm.forecastEndDate = $rootScope.$stateParams.forecastenddate ? $rootScope.$stateParams.forecastenddate : '';

        vm.processing = true;

        vm.vehicles = [];
        vm.gridControl = {};

        vm.filterValues = {
            forecastId: vm.forecastId,
            specificationId: vm.vehiclespecificationid
        };

        vm.editModeEnabled = false;

        this.$onInit = function () {
            if (vm.forecastId > 0) {

                let dataPromises = [];

                dataPromises.push(vm.getColumns());
                dataPromises.push(vm.getVehicles());

                $q.all(dataPromises).then(function () {

                    if (vm.gridControl && vm.gridControl.recompile) {
                        vm.gridControl.recompile();
                    }

                }).finally(function () {
                    vm.processing = false;
                });

            } else {
                $state.go('app.site.forecastlist');
            }
        };

        vm.getColumns = function () {
            return amtCommandQuerySvc.get(vm.apiUrls.getForecastMonths, { forecastId: vm.forecastId }).then(function (response) {

                if (!response || !response.data) {
                    return;
                }

                if (response.data.length > 0) {

                    let lockedColumnsTotalWidth = 0;

                    angular.forEach(vm.columnWidths, (value, key) => {
                        lockedColumnsTotalWidth += value;
                    });

                    let usageGridDiv = document.getElementById('usageGridDiv');

                    if (usageGridDiv) {

                        let remainingWidth = usageGridDiv.offsetWidth - lockedColumnsTotalWidth - 23;

                        let adjustedMonthColumnWidth = remainingWidth / response.data.length;

                        if (adjustedMonthColumnWidth > minimumMonthColumnWidth) {
                            vm.monthColumnWidth = adjustedMonthColumnWidth;
                        } else {
                            vm.monthColumnWidth = minimumMonthColumnWidth;
                        }

                    } else {
                        vm.monthColumnWidth = minimumMonthColumnWidth;
                    }
                }

                vm.gridColumns = response.data;
                vm.gridCommandColumns = ['forecastVehicles.vehicleUsageClearButton'];

            }).catch(function (error) {
                errorReporter.logError(error);
            });
        };

        vm.getVehicles = function () {
            return amtCommandQuerySvc.post(vm.apiUrls.getForecastVehicleMonthlyUsage, { filterValues: vm.filterValues }).then(function (response) {

                if (!response) {
                    return;
                }

                let vehicles = response;

                if (vehicles && vehicles.length > 0) {

                    for (var v = 0; v < vehicles.length; v++) {

                        _.forEach(vehicles[v].monthlyUsages, function (u) {

                            u.usage = +Number(u.usage).toFixed(0);

                            if (u.id === 0) {
                                u.usage = null;
                            }
                        })
                    }
                }

                vm.vehicles = vehicles;

            }).catch(function (error) {
                errorReporter.logError(error);
            });
        };

        vm.toggleEditMode = function () {

            if (vm.editModeEnabled) {

                confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {

                    vm.form.$setPristine();

                    vm.processing = true;

                    vm.editModeEnabled = false;

                    vm.getVehicles().then(function () {

                        $timeout(function () {
                            if (vm.gridControl && vm.gridControl.refresh) {
                                vm.gridControl.refresh();
                            }
                        });

                    }).finally(function () {
                        vm.processing = false;
                    })
                });
            } else {
                vm.editModeEnabled = true;
            }
        };

        vm.saveMonthlyUsages = function () {

            let criteria = {
                forecastId: vm.forecastId,
                vehicles: vm.vehicles.map(function (v) {
                    return {
                        forecastVehicleId: v.id,
                        monthlyUsages: vm.gridColumns.map(function (c) {
                            return {
                                id: v.monthlyUsages[c.yearMonthForDisplay].id,
                                value: v.monthlyUsages[c.yearMonthForDisplay].usage
                            };
                        })
                    };
                })
            };

            vm.processing = true;

            return amtCommandQuerySvc.post(amtConstants.url.saveMonthlyUsages, criteria).then(function (response) {

                notifySvc.success(xlatSvc.xlat("forecastVehicles.messageVehicleMonthlyUsageUpdateSuccess"));

                vm.refresh();

                vm.form.$setPristine();

            }).catch(function (error) {
                errorReporter.logError(error);
            }).finally(function () {
                vm.processing = false;
            });

        };

        $scope.clearVehicleUsage = function (dataItem) {

            if (vm.editModeEnabled) {

                let vehicleIndex = _.findIndex(vm.vehicles, v => v.id === dataItem.id);

                for (let i = 0; i < vm.gridColumns.length; i++) {
                    vm.setVehicleUsageValue(vehicleIndex, i, null);
                }

            } else {

                let model = {
                    forecastVehicleId: dataItem.id,
                    forecastId: vm.forecastId
                };

                vm.processing = true;

                return amtCommandQuerySvc.put(amtConstants.url.clearVehicleUsage, model).then(function (response) {

                    notifySvc.success(xlatSvc.xlat("forecastVehicles.messageVehicleMonthlyUsageClearSuccess"));

                    vm.refresh();

                }).catch(function (error) {
                    errorReporter.logError(error);
                }).finally(function () {
                    vm.processing = false;
                });
            }
        };

        vm.clearAllVehicleUsage = function () {

            for (let i = 0; i < vm.vehicles.length; i++) {
                for (let j = 0; j < vm.gridColumns.length; j++) {
                    vm.setVehicleUsageValue(i, j, null);
                }
            }
        };

        vm.exportMonthlyUsages = function () {
            exportSvc.exportData(
                vm.apiUrls.exportVehicleMonthlyUsages,
                { filterValues: vm.filterValues },
                xlatSvc.xlat('forecastVehicles.monthlyUsagesExportFilename', vm.vehicleSpecName)
            );
        };

        $scope.openEditPopup = function (param) {

            WindowFactory.openItem({
                component: 'vehicle-monthly-usage-edit',
                canMinimise: false,
                modal: true,
                canClose: false,
                footerOff: false,
                caption: xlatSvc.xlat('forecastVehicles.vehicleUsageEditPopup'),
                initParams: {
                    monthlyusageid: param.monthlyusageid,
                    monthlyusage: param.monthlyusage,
                    yearmonth: param.yearmonth,
                    forecastId: vm.forecastId
                },
                onDataChangeHandler: function () {
                    vm.refresh();
                }
            });
        };

        $scope.pasteMonthValues = function (dataItem, param) {

            param.event.preventDefault();

            let values = helperSvc.getPastedValues(param.event);

            if (values && values.length > 0) {

                let startVehicleIndex = _.findIndex(vm.vehicles, function (v) { return v.id === dataItem.id; });
                let startColumnIndex = _.findIndex(vm.gridColumns, function (c) { return c.yearMonthForDisplay == param.month; });

                for (let i = 0; i < values.length; i++) {

                    if (values[i] && values[i].length > 0) {

                        for (let j = 0; j < values[i].length; j++) {

                            vm.setVehicleUsageValue(startVehicleIndex + i, startColumnIndex + j, values[i][j]);

                        }
                    }
                }
            }
        };

        $scope.updateMonthUsageValue = function (dataItem, month) {
            let vehicle = _.find(vm.vehicles, function (v) { return v.id === dataItem.id; });

            if (vehicle && month) {
                vehicle.monthlyUsages[month].usage = dataItem.monthlyUsages[month].usage;
            }
        };

        vm.getMonthForDisplay = function (month) {
            var tzDate = new Date(Math.floor(month / 100), ((month % 100) - 1)); // Javascript months are indexed from 0
            return tzDate;
        };

        vm.goToParentRoute = function () {
            confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                vm.form.dirty = false;
                forecastingSvc.setActiveTabName("Vehicles");
                $rootScope.goBack();
            });
        };

        vm.refresh = function () {
            return vm.getVehicles().then(function () {

                $timeout(function () {
                    if (vm.gridControl && vm.gridControl.refresh) {
                        vm.gridControl.refresh();
                    }
                });
            })
        };

        vm.setVehicleUsageValue = function (vehicleIndex, columnIndex, value) {

            let vehicle = vm.vehicles[vehicleIndex];

            if (vehicle) {

                let col = vm.gridColumns[columnIndex];

                if (col) {

                    let elem = document.getElementById(String.format('vehicleMonthlyUsage{0}{1}', vehicle.id, col.yearMonthForDisplay));

                    if (elem) {
                        angular.element(elem).val(value >= 0 ? +Number(value).toFixed(0) : null);
                        angular.element(elem).change();
                    }
                }
            }
        };

        vm.resize = function (height, offsetTop) {

            if (!vm.gridHeight) {
                vm.gridTop = height + offsetTop;
            }

            let newHeight = $window.innerHeight - vm.gridTop;

            if (vm.gridHeight != newHeight) {

                vm.gridHeight = newHeight;

                if (vm.gridControl && vm.gridControl.resize) {
                    vm.gridControl.resize();
                }
            }
        };

        $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.processing'], function () {
            vm.saveDisabled = vm.form.$invalid || vm.form.$pristine || !!vm.processing;
        });
    }
