//import angular from 'angular';
import BrowserSvc from '../../../../services/browserSvc';
import AdminUrls from '../../../admin/adminUrls';
import tmpl from './IncompletePositionsWindow.html';



class IncompletePositionsCtrl implements ng.IController, IWindowController {
    form: ng.IFormController;
    wnd: IWindowObj;

    session: any;

    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: () => void };

    initParams: any;

    readonly isMobile: boolean;

    statusOptions: KeyNameDescription[] = [];
    selectedStatus: KeyNameDescription = null;

    siteId: string;
    clientId: string;
    adminUrls: any;

    transfer: any;

    transferType: string;

    static $inject = ['$scope', 'dataBroker', 'WindowFactory', 'amtXlatSvc', 'helperSvc', 'browserSvc', 'adminUrls', 'ocConfigSvc'];

    constructor(private $scope: ng.IScope, private dataBroker: IDataBroker, private WindowFactory: IWindowFactory,
        private amtXlatSvc: IAmtXlatSvc, private helperSvc: IHelperSvc, browserSvc: BrowserSvc, adminUrls: AdminUrls, ocConfigSvc) {
        this.isMobile = browserSvc.isMobile;
        this.adminUrls = adminUrls;
        this.siteId = ocConfigSvc.user.site.id;
        this.clientId = ocConfigSvc.user.client.id;
        this.transferType = StatusTypeName.dispatchedTransfer;

        // default to current date
        this.transfer = {
            eventDate: new Date()
        };
    }

    async $onInit() {

        this.wnd.processing = true;
        this.wnd.onClose = () => this.cancel();

        this.session = this.initParams.moduleItem;

        try {
            // if error on incomplete, show 'Ok' button label, otherwise 'Save'
            let saveResource = this.initParams.windowType !== 'Warning' ? 'common.ok_label' : 'common.save_label';

            // only include the ok/save button if warning on incomplete or on mobile
            if (this.initParams.windowType === 'Warning' || this.isMobile)
                this.WindowFactory.addButton(this, saveResource, saveResource, button => this.save(button), true);

            this.WindowFactory.addButton(this, 'common.cancel_label', 'cancelButton', button => this.cancel(button));

            let statusTypes = await this.dataBroker.getIncompleteWheelPositionStatusTypes();

            if (statusTypes.result) {
                this.statusOptions = statusTypes.result.map(r => {
                    return {
                        key: r.key,
                        name: r.name.toLowerCase(),
                        description: r.name.toLowerCase() === StatusTypeName.dispatchedTransfer ? this.amtXlatSvc.xlat('equipment.transferOffSite') : r.description
                    };
                });

                this.selectedStatus = this.statusOptions.find(s => s.name === StatusTypeName.outOfService);
            }
        } finally {
            this.wnd.processing = false;
        }
    }

    onDestinationChange = function (option) {
        if (!option || option.requiresManualEntry === false) {
            this.transfer.destinationOther = null;
        }
    };

    onStatusChange = function (option) {
        if (!option || option.name !== StatusTypeName.dispatchedTransfer) {
            this.transfer.destination = null;
        }
    };

    async save(button) {

        if (this.selectedStatus)
            this.session.newStatusTypeId = this.helperSvc.getKey(this.selectedStatus);

        if (this.transfer.destination) {
            if (!this.session.checkout.transferDetails) {
                this.session.checkout.transferDetails = { destinationSiteId: undefined, destinationOther: undefined, transferredFromMaintenance: true };
            }

            this.session.checkout.transferDetails.destinationSiteId = this.helperSvc.getKey(this.transfer.destination);

            if (this.transfer.destination && this.transfer.destination.requiresManualEntry)
                this.session.checkout.transferDetails.destinationOther = this.transfer.destinationOther;
        }

        if (this.wnd.onDataChanged)
            await this.wnd.onDataChanged(button);

        this.WindowFactory.closeWindow(this.wnd);
    }

    async cancel(button?) {

        if (this.wnd.onDataChanged)
            await this.wnd.onDataChanged(button);

        this.WindowFactory.closeWindow(this.wnd);
    }
}

class IncompletePositionsComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = IncompletePositionsCtrl;
    public controllerAs = 'vm';
}

angular.module('app.directives').component('incompletePositionsWindow', new IncompletePositionsComponent());