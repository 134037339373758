/**
 * @ngdoc directive
 * @name amtFramework.directive:amtMenuAction
 * @restrict E
 * 
 * @param {string} title Title
 * @param {callback} onClick() Callback that is invoked when the menu item is clicked 
 */

import { Component, Input, Output, EventEmitter, HostBinding } from "@angular/core";
import { downgradeComponent } from "@angular/upgrade/static";


@Component({
    selector: 'amt-menu-action',
    templateUrl: './amtMenuAction.html',
    styles: [':host { display: contents !important; }']
})
export class AmtMenuAction {
    @Input('title') title: string;
    @Output() onClick = new EventEmitter<any>();

    //workaround: this previously worked via AngularJS replace, and Bootstrap v3 & Otracom styles expect the parent element of the li>a in this template to have the dropdown-menu class
    @HostBinding('class.dropdown-menu') dropdownMenu = true;

    click() {
        this.onClick.emit();
    }
}
angular.module('amtFramework').directive('amtMenuAction', downgradeComponent({ component: AmtMenuAction, outputs: ['onClick'] }));