import * as _ from 'underscore'

/**
 * @ngdoc directive
 * @name amtFramework.filter.directive:amtFilter
 * @sortOrder 10
 * @restrict E
 * 
 * @tutorial filterGridScreen
 * 
 * @param {expression} filterValues Object that gets populated with the filter values
 * <pre>
 * {
 *      property1: 1,
 *      property2: [1,2,3,4,5],
 *      property3: true
 * }
 * </pre>
 * @function
 *
 * @description
 * Filter component to create a filter values object used to filter data in {@link amtFramework.grid.directive:amtGrid amtGrid}
 * Also have a look at {@tutorial filterGridScreen}
 * 
 * @example
 * <doc:example module="amtFramework">
 *   <doc:source>
 *     <amt-filter filter-values="filterValues">
 *       <amt-filter-section>
 *         <amt-filter-column>
 *              <amt-date-filteritem label="endDate"
                                     property-name="endDate"
                                     default-value="'2015-03-04'"
                                     is-required="true">

                </amt-date-filteritem>
 *         </amt-filter-column>
 *       </amt-filter-section>
 *     </amt-filter>
 *   </doc:source>
 * </doc:example>
 */
//import angular from 'angular';
import tmpl from './amtFilter.html';
import './amtFilter.scss';


angular.module('amtFramework.filter').directive('amtFilter', function () {

            return {
                restrict: 'E',
                transclude: true,
                scope: {
                    filterValues: '='
                },
                template: tmpl,
                controller: [
                    '$scope', function ($scope) {
                        $scope.filterDefinition = { filterItems: [] };
                        if ($scope.filterDefinition) {
                            var originalFilterValues = angular.copy($scope.filterValues);
                            $scope.$watch('filterDefinition.filterItems', function (newValue, oldValue) {
                                if (angular.equals(newValue, oldValue)) {
                                    return;
                                }
                                var filterValues = _.reduce($scope.filterDefinition.filterItems, function (mem, el) {
                                    if (el.value !== undefined) {
                                        mem[el.propertyName] = el.value;
                                    }
                                    return mem;
                                }, {});
                                
                                if (originalFilterValues) {
                                    for (var oriProperty in originalFilterValues) {
                                        if (oriProperty) {
                                            if (filterValues[oriProperty] === undefined) {
                                                filterValues[oriProperty] = originalFilterValues[oriProperty];
                                            }
                                        }
                                    }
                                }
                                $scope.filterValues = filterValues;
                            },
                                true);
                        }

                        this.addFilterItem = function (filterItem) {
                            $scope.filterDefinition.filterItems.push(filterItem);
                        };
                    }
                ],
            };
});

