//import angular from 'angular';


angular.module("app").config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push('templateInterceptor');
    }]);

    // register the interceptor as a service
    angular.module("app").factory('templateInterceptor', 
         ['$q', 'amtXlatSvc', '$injector', '$rootScope',
    function ($q, amtXlatSvc , $injector, $rootScope) {
        return {
            'responseError': function (rejection) {
                var isTemplate = !!rejection.config.url.match(/.html$/g);
                if (isTemplate) {
                    var message = amtXlatSvc.xlat('exception.TemplateError');
                    if (rejection.status === -1) {
                        message = amtXlatSvc.xlat('exception.TemplateOffline');
                        $rootScope.$emit('TemplateLoadFailOffline', rejection);
                    }
                    else {
                        console.error("Template error : \n" + (rejection.statusText || rejection.status)  + " : " + rejection.config.url);
                        $rootScope.$emit('TemplateLoadFail', rejection);
                        var errorReporter = $injector.get("errorReporter");
                        errorReporter.logError(rejection);
                    }
                    rejection.status = 200;
                    rejection.data = '<div style="padding:25px;" >' + message + '</div>';
                    return $q.resolve(rejection); // this is an actual resolve.
                } else {
                    return $q.reject(rejection);
                }
            }
        };
    }]);
