//import angular from 'angular';
import toastr from 'toastr';
import OcDateSvc from './ocDateSvc';
import StackTrace from 'stacktrace-js';
import OcConfigSvc from './ocConfigSvc';
import { HttpErrorResponse } from '@angular/common/http';

angular.module('app')
        .config(["$provide", function ($provide) {
            $provide.decorator("$exceptionHandler", ["$delegate", "$injector", function ($delegate, $injector) {
                return function (exception, cause) {
                    try {
                        if (exception instanceof Error &&
                            ((exception as OtracomError).number === 0 || /*IE */
                                exception.message === "0" || /* chrome */
                                exception.message === "Cannot read property 'children' of null" || /* Kendo Grid Error - Temporary Swallow (Chrome) */
                                exception.message === "Unable to get property 'children' of undefined or null reference" /* Kendo Grid Error - Temporary Swallow (IE) */
                            )
                        ) {
                            // the confirm service triggers a reject which is commonly unhandled.
                            // also the Windowfactory.openItemAsync
                            console.warn(exception.stack || exception.message);
                        }
                        else {
                            var errorReporter = $injector.get("errorReporter");
                            errorReporter.logError(exception);
                        }
                    }
                    catch (ex) {
                        // nothing we can do now.                     
                        $delegate(exception, cause);
                    }
                };
            }]);
        }]);

    angular.module('app').service("errorReporter", ["amtXlatSvc", "$filter", "$injector", "ocDateSvc", "$interval", "$q", "notifySvc",
            function (amtXlatSvc, $filter, $injector: ng.auto.IInjectorService, ocDateSvc: OcDateSvc, $interval: ng.IIntervalService, $q, notifySvc: INotifySvc) {

                // attempt to pull a string message out of an exception 
                function exceptionMessage(exception) {

                    var message = null;

                    if (!exception) {
                        return amtXlatSvc.xlat('exception.undefined');
                    }

                    if (typeof exception === "string") {
                        return exception;
                    }

                    try {
                        // all sorts of things end up here.
                        message = exception.message || exception.description;

                        if (!message && exception.data) {
                            // probably a http response
                            message = exception.data.messageDetail || exception.data.message;
                            if (!message) {
                                if (exception.data.exception) {
                                    // a .Net exception
                                    message = exception.data.exception.Message || exception.data.exception;
                                } else {
                                    if (typeof exception.data === 'string') {
                                        message = exception.data;
                                        if (message.indexOf('\\n') !== -1) {
                                            message = message.substring(0, message.indexOf('\\n'));
                                        }
                                    }
                                }
                            }
                        }

                        var addtionalDetail = "";

                        if (exception.headers && exception.status) {
                            // an amtCommandQuery
                            if (exception.config && exception.config.url) {
                                addtionalDetail = ": " + exception.config.method + " : " + exception.config.url;
                            }
                            if (!message) {
                                if (exception.status === -1) {
                                    message = "Network connection error";
                                }
                                if (exception.status === 200) {
                                    if (typeof exception.data === 'string' && exception.data.trim().startsWith("<!DOCTYPE html>")) {
                                        message = amtXlatSvc.xlat('exception.unexpected_html_content');
                                    }
                                    message = amtXlatSvc.xlat('exception.unexpected_content');
                                }
                                if (!message) {
                                    message = exception.statusText || exception.status;
                                }
                            }
                            message = message + addtionalDetail;
                        }
                        if (exception.errors) {
                            if (angular.isArray(exception.errors)) {
                                message = "";
                                for (var i = 0; i < exception.errors.length; i++) {
                                    if (exception.errors[i].message) {
                                        message = message + exception.errors[i].message + ";";
                                    }
                                    else {
                                        message = message + exception.errors[i] + ";";
                                    }
                                }
                            }
                            else {
                                message = exception.errors;
                            }
                        }
                        if (!message) {
                            message = JSON.stringify(exception).substring(0, 200);
                        }

                    } catch (ex) {
                        return amtXlatSvc.xlat('exception.unparsable');
                    }

                    return message;
                }

                var mappingStack = false;

                //var lastErrorTime = new Date();

                // places the error into the local indexed DB if mobile or sends to server.
                async function logError(exception, cause, suppressUI, suppressErrorLogging = false) {

                    if (!exception || exception === 0)                        
                        return $q.resolve(); // not an error;

                    if (exception instanceof HttpErrorResponse) {
                        notifySvc.error(exception.error.exceptionMessage);
                        return $q.resolve();
                    }

                    if (exception.preventLogException)
                        return $q.resolve();                    

                    var msg = exceptionMessage(exception);

                    try {
                        var date = new Date();

                        // clean the object and make it safe for saving
                        var ex = {} as OtracomError;

                        if (typeof exception === "string") {
                            ex.message = msg;
                        } else {
                            try {

                                if (exception._promise) {
                                    exception._promise = null; // remove the promise.
                                }

                                ex = JSON.parse(JSON.stringify(exception));

                                if (!ex.message) {
                                    throw new Error("Failed to stringify.");
                                }

                            } catch (ex2) {
                                ex.stack = exception.stack;
                                ex.message = msg;
                            }
                        }

                        ex.stack = ex.stack || ex.exception?.StackTraceString; //TODO: StackTraceString is some random property, where does this come from (serverside exceptions maybe?)

                        if (suppressUI)
                            console.warn(msg);
                        else
                            console.error(msg + " " + ex.stack);

                        let databrokerLogError = (e?: string) => {
                            if (suppressErrorLogging)
                                return;
                            if (!$injector.has('dataBroker')) {
                                console.warn("databroker not available to report error");
                                return;
                            }

                            ($injector.get('dataBroker') as IDataBroker).logError({
                                dateTime: date,
                                error: cause,
                                exception: ex,
                                message: e ? (msg + ': ' + e) : msg
                            })
                            .catch(err => { console.error("Logging of error failed:" + err) });
                        };


                        // when not in debug mode we need to map the stack trace to get a useful value
                        let handleStackMapping = !window.debug && !!ex.stack && !mappingStack;
                        let originalMsg: string = msg;

                        if (handleStackMapping) {
                            console.log("Get Location information from map files");
                            mappingStack = true;

                            //TODO: this is dumb, client downloads megabytes of stack traces just to send a few bytes made of what was already available supplemented with the data from the server
                            //should use a library like 'retrace for .net' to keep source maps on the server and suppliment the error there
                            StackTrace.fromError(ex).then(stackFrames => {
                                let formattedStack = stackFrames.map(f => f.fileName + ':' + f.lineNumber + ':' + f.columnNumber + "   (" + f.functionName + "))").join(" ");
                                if (formattedStack)
                                    originalMsg = originalMsg + ': ' + formattedStack;

                                databrokerLogError(formattedStack);
                            })
                            .finally(() => {
                                mappingStack = false;
                            });
                        } else {
                            originalMsg = window.debug ? exception.stack : originalMsg;
                            databrokerLogError();
                        }

                        if (suppressUI)
                            return $q.resolve(); //exit now if nothing to display... everything after this is GUI stuff

                        // for now lets toast it.
                        let ocConfigSvc: OcConfigSvc = $injector.has('ocConfigSvc') ? $injector.get("ocConfigSvc") : null;

                        let getErrorText = ready => ready ?
                            amtXlatSvc.xlat('exception.errorDetailMsg', ocConfigSvc?.user.client.name, ocConfigSvc?.user.site.name, ocConfigSvc?.user.name, date.toString(), originalMsg) :
                            (originalMsg + ': ' + amtXlatSvc.xlat('exception.gatheringErrorDetails'));

                        let toastrClick = () => {
                            if (!$injector.has('WindowFactory'))
                                return;

                            let wndPromise = ($injector.get('WindowFactory') as IWindowFactory).alert('exception.errorDetailHeader', ['common.ok_label'], getErrorText(handleStackMapping), null, 1000, null, true);

                            if (!handleStackMapping || !wndPromise?.windowHandle)
                                return;

                            wndPromise.windowHandle.processing = true;

                            let pollingLoop = $interval(() => {
                                if (!mappingStack) {
                                    wndPromise.windowHandle.initParams.text = getErrorText(!mappingStack); // change window text
                                    $interval.cancel(pollingLoop);
                                }
                            }, 100, 60);

                            pollingLoop.finally(() => { wndPromise.windowHandle.processing = false });
                        };

                        toastr.error( window.debug ? msg : amtXlatSvc.xlat('exception.generic_error') + " (" + $filter('date')(date, "medium") + ")<br/> " + amtXlatSvc.xlat('exception.clickForDetail'),
                                            amtXlatSvc.xlat('exception.internal_fault_heading'), { timeOut: 6000, onclick: toastrClick });

                    } catch (er) {
                        console.error("Logging of error failed: " + er);
                    }

                    return $q.resolve()
                }

                function getErrors() {
                    if ($injector.has('dataBroker')) {
                        return ($injector.get('dataBroker') as IDataBroker).getErrors();
                    } else {
                        console.warn("databroker not available to fetch errors");
                    }
                }

                return {
                    logError: logError,
                    exceptionMessage: exceptionMessage,
                    getErrors: getErrors
                };

            }]);

