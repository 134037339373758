/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var rxjs_1 = require("rxjs");
var kendo_licensing_1 = require("@progress/kendo-licensing");
var package_metadata_1 = require("./package-metadata");
var data_change_notification_service_1 = require("./data-change-notification.service");
var default_callbacks_1 = require("./default-callbacks");
var expand_state_service_1 = require("./expand-state.service");
var index_builder_service_1 = require("./index-builder.service");
var loading_notification_service_1 = require("./loading-notification.service");
var navigation_service_1 = require("./navigation/navigation.service");
var node_children_service_1 = require("./node-children.service");
var node_template_directive_1 = require("./node-template.directive");
var load_more_button_template_directive_1 = require("./load-more/load-more-button-template.directive");
var data_bound_component_1 = require("./data-bound-component");
var expandable_component_1 = require("./expandable-component");
var selection_service_1 = require("./selection/selection.service");
var treeview_lookup_service_1 = require("./treeview-lookup.service");
var utils_1 = require("./utils");
var LOAD_MORE_DOC_LINK = 'http://www.telerik.com/kendo-angular-ui/components/treeview/load-more-button/';
var providers = [
    expand_state_service_1.ExpandStateService,
    index_builder_service_1.IndexBuilderService,
    treeview_lookup_service_1.TreeViewLookupService,
    loading_notification_service_1.LoadingNotificationService,
    node_children_service_1.NodeChildrenService,
    navigation_service_1.NavigationService,
    selection_service_1.SelectionService,
    data_change_notification_service_1.DataChangeNotificationService,
    kendo_angular_l10n_1.LocalizationService,
    {
        provide: kendo_angular_l10n_1.L10N_PREFIX,
        useValue: 'kendo.treeview'
    },
    {
        provide: data_bound_component_1.DataBoundComponent,
        useExisting: core_1.forwardRef(function () { return TreeViewComponent; })
    },
    {
        provide: expandable_component_1.ExpandableComponent,
        useExisting: core_1.forwardRef(function () { return TreeViewComponent; })
    }
];
/* tslint:disable:member-ordering */
/**
 * Represents the [Kendo UI TreeView component for Angular]({% slug overview_treeview %}).
 *
 * @example
 * {% meta height:450 %}
 * {% embed_file get-started/app.component.ts preview %}
 * {% embed_file get-started/app.module.ts %}
 * {% embed_file shared/main.ts %}
 * {% endmeta %}
 */
var TreeViewComponent = /** @class */ (function () {
    function TreeViewComponent(element, changeDetectorRef, expandService, navigationService, nodeChildrenService, selectionService, treeViewLookupService, ngZone, renderer, dataChangeNotification, localization) {
        var _this = this;
        this.element = element;
        this.changeDetectorRef = changeDetectorRef;
        this.expandService = expandService;
        this.navigationService = navigationService;
        this.nodeChildrenService = nodeChildrenService;
        this.selectionService = selectionService;
        this.treeViewLookupService = treeViewLookupService;
        this.ngZone = ngZone;
        this.renderer = renderer;
        this.dataChangeNotification = dataChangeNotification;
        this.localization = localization;
        this.classNames = true;
        this.role = 'tree';
        /**
         * The hint which is displayed when the component is empty.
         */
        this.filterInputPlaceholder = "";
        /** @hidden */
        this.fetchNodes = function () { return _this.data; };
        /**
         * Fires when the children of the expanded node are loaded.
         */
        this.childrenLoaded = new core_1.EventEmitter();
        /**
         * Fires when the user blurs the component.
         */
        this.onBlur = new core_1.EventEmitter();
        /**
         * Fires when the user focuses the component.
         */
        this.onFocus = new core_1.EventEmitter();
        /**
         * Fires when the user expands a TreeView node.
         */
        this.expand = new core_1.EventEmitter();
        /**
         * Fires when the user collapses a TreeView node.
         */
        this.collapse = new core_1.EventEmitter();
        /**
         * Fires just before the dragging of the node starts ([see example]({% slug draganddrop_treeview %}#toc-setup)). This event is preventable.
         * If you prevent the event default, no drag hint will be created and the subsequent drag-related events will not be fired.
         */
        this.nodeDragStart = new core_1.EventEmitter();
        /**
         * Fires when an item is being dragged ([see example]({% slug draganddrop_treeview %}#toc-setup)).
         */
        this.nodeDrag = new core_1.EventEmitter();
        /**
         * Emits when the built-in filtering mechanism in the data-binding directives updates the node's visibility.
         * Used for the built-in auto-expand functionalities of the component and available for custom implementations.
         */
        this.filterStateChange = new core_1.EventEmitter();
        /**
         * Fires on the target TreeView when a dragged item is dropped ([see example]({% slug draganddrop_treeview %}#toc-setup)).
         * This event is preventable. If you prevent the event default (`event.preventDefualt()`) or invalidate its state (`event.setValid(false)`),
         * the `addItem` and `removeItem` events will not be triggered.
         *
         * Both operations cancel the default drop operation, but the indication to the user is different. `event.setValid(false)` indicates that the operation was
         * unsuccessful by animating the drag clue to its original position. `event.preventDefault()` simply removes the clue, as if it has been dropped successfully.
         * As a general rule, use `preventDefault` to manually handle the add and remove operations, and `setValid(false)` to indicate the operation was unsuccessful.
         */
        this.nodeDrop = new core_1.EventEmitter();
        /**
         * Fires on the source TreeView after the dragged item has been dropped ([see example]({% slug draganddrop_treeview %}#toc-setup)).
         */
        this.nodeDragEnd = new core_1.EventEmitter();
        /**
         * Fires after a dragged item is dropped ([see example]({% slug draganddrop_treeview %}#toc-setup)).
         * Called on the TreeView where the item is dropped.
         */
        this.addItem = new core_1.EventEmitter();
        /**
         * Fires after a dragged item is dropped ([see example]({% slug draganddrop_treeview %}#toc-setup)).
         * Called on the TreeView from where the item is dragged.
         */
        this.removeItem = new core_1.EventEmitter();
        /**
         * Fires when the user selects a TreeView node checkbox
         * ([see example]({% slug checkboxes_treeview %}#toc-modifying-the-checked-state)).
         */
        this.checkedChange = new core_1.EventEmitter();
        /**
         * Fires when the user selects a TreeView node
         * ([see example]({% slug selection_treeview %}#toc-modifying-the-selection)).
         */
        this.selectionChange = new core_1.EventEmitter();
        /**
         * Fires when the value of the built-in filter input element changes.
         */
        this.filterChange = new core_1.EventEmitter();
        /**
         * Fires when the user clicks a TreeView node.
         */
        this.nodeClick = new core_1.EventEmitter();
        /**
         * Fires when the user double clicks a TreeView node.
         */
        this.nodeDblClick = new core_1.EventEmitter();
        /**
         * A function that defines how to track node changes.
         * By default, the TreeView tracks the nodes by data item object reference.
         *
         * @example
         * ```ts
         *  @Component({
         *      selector: 'my-app',
         *      template: `
         *          <kendo-treeview
         *              [nodes]="data"
         *              textField="text"
         *              [trackBy]="trackBy"
         *          >
         *          </kendo-treeview>
         *      `
         *  })
         *  export class AppComponent {
         *      public data: any[] = [
         *          { text: "Furniture" },
         *          { text: "Decor" }
         *      ];
         *
         *      public trackBy(index: number, item: any): any {
         *          return item.text;
         *      }
         *  }
         * ```
         */
        this.trackBy = default_callbacks_1.trackBy;
        /**
         * A function which determines if a specific node is disabled.
         */
        this.isDisabled = default_callbacks_1.isDisabled;
        /**
         * A callback which determines whether a TreeView node should be rendered as hidden. The utility .k-display-none class is used to hide the nodes.
         * Useful for custom filtering implementations.
         */
        this.isVisible = default_callbacks_1.isVisible;
        /**
         * Determines whether the TreeView keyboard navigable is enabled.
         */
        this.navigable = true;
        /**
         * A function which provides the child nodes for a given parent node
         * ([see example]({% slug databinding_treeview %})).
         */
        this.children = function () { return rxjs_1.of([]); };
        /**
         * Indicates whether the child nodes will be fetched on node expand or will be initially prefetched.
         * @default true
         */
        this.loadOnDemand = true;
        /**
         * Renders the built-in input element for filtering the TreeView.
         * If set to `true`, the component emits the `filterChange` event, which can be used to [filter the TreeView manually]({% slug filtering_treeview %}#toc-manual-filtering).
         * A built-in filtering implementation is available to use with the [`kendoTreeViewHierarchyBinding`]({% slug api_treeview_hierarchybindingdirective %}) and [`kendoTreeViewFlatDataBinding`]({% slug api_treeview_flatdatabindingdirective %}) directives.
         */
        this.filterable = false;
        /**
         * Sets an initial value of the built-in input element used for filtering.
         */
        this.filter = '';
        this.checkboxes = false;
        this.expandIcons = false;
        this.selectable = false;
        this.touchActions = true;
        this.isActive = false;
        this.data = new rxjs_1.BehaviorSubject([]);
        this._animate = true;
        this._size = 'medium';
        this.subscriptions = new rxjs_1.Subscription();
        this.domSubscriptions = [];
        kendo_licensing_1.validatePackage(package_metadata_1.packageMetadata);
    }
    Object.defineProperty(TreeViewComponent.prototype, "direction", {
        /** @hidden */
        get: function () {
            return this.localization.rtl ? 'rtl' : 'ltr';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TreeViewComponent.prototype, "animate", {
        get: function () {
            return !this._animate;
        },
        /**
         * Determines whether the content animation is enabled.
         */
        set: function (value) {
            this._animate = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TreeViewComponent.prototype, "nodeTemplateRef", {
        get: function () {
            return this._nodeTemplateRef || this.nodeTemplateQuery;
        },
        /**
         * @hidden
         *
         * Defines the template for each node.
         * Takes precedence over nested templates in the TreeView tag.
         */
        set: function (template) {
            this._nodeTemplateRef = template;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TreeViewComponent.prototype, "loadMoreButtonTemplateRef", {
        get: function () {
            return this._loadMoreButtonTemplateRef || this.loadMoreButtonTemplateQuery;
        },
        /**
         * @hidden
         *
         * Defines the template for each load-more button.
         * Takes precedence over nested templates in the TreeView tag.
         */
        set: function (template) {
            this._loadMoreButtonTemplateRef = template;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TreeViewComponent.prototype, "nodes", {
        get: function () {
            return this.data.value;
        },
        /**
         * The nodes which will be displayed by the TreeView
         * ([see example]({% slug databinding_treeview %})).
         */
        set: function (value) {
            this.data.next(value || []);
            this.dataChangeNotification.notify();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TreeViewComponent.prototype, "hasChildren", {
        /**
         * A function which determines if a specific node has child nodes
         * ([see example]({% slug databinding_treeview %})).
         */
        get: function () {
            return this._hasChildren || default_callbacks_1.hasChildren;
        },
        set: function (callback) {
            this._hasChildren = callback;
            this.expandIcons = Boolean(this._isExpanded && this._hasChildren);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TreeViewComponent.prototype, "isChecked", {
        /**
         * A function which determines if a specific node is checked
         * ([see example]({% slug checkboxes_treeview %}#toc-modifying-the-checked-state)).
         */
        get: function () {
            return this._isChecked || default_callbacks_1.isChecked;
        },
        set: function (callback) {
            this._isChecked = callback;
            this.checkboxes = Boolean(this._isChecked);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TreeViewComponent.prototype, "isExpanded", {
        /**
         * A function which determines if a specific node is expanded.
         */
        get: function () {
            return this._isExpanded || default_callbacks_1.isExpanded;
        },
        set: function (callback) {
            this._isExpanded = callback;
            this.expandIcons = Boolean(this._isExpanded && this._hasChildren);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TreeViewComponent.prototype, "isSelected", {
        /**
         * A function which determines if a specific node is selected
         * ([see example]({% slug selection_treeview %}#toc-modifying-the-selection)).
         */
        get: function () {
            return this._isSelected || default_callbacks_1.isSelected;
        },
        set: function (callback) {
            this._isSelected = callback;
            this.selectable = Boolean(this._isSelected);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TreeViewComponent.prototype, "size", {
        get: function () {
            return this._size;
        },
        /**
         * Sets the size of the component.
         *
         * The possible values are:
         * * `'small'`
         * * `'medium'` (default)
         * * `'large'`
         * * `null`
         *
         */
        set: function (size) {
            this.renderer.removeClass(this.element.nativeElement, utils_1.getSizeClass('treeview', this.size));
            if (size) {
                this.renderer.addClass(this.element.nativeElement, utils_1.getSizeClass('treeview', size));
            }
            this._size = size;
        },
        enumerable: true,
        configurable: true
    });
    TreeViewComponent.prototype.ngOnChanges = function (changes) {
        this.navigationService.navigable = Boolean(this.navigable);
        // TODO: should react to changes.loadOnDemand as well - should preload the data or clear the already cached items
        if (kendo_angular_common_1.anyChanged(['nodes', 'children', 'hasChildren', 'loadOnDemand'], changes, false) && !this.loadOnDemand) {
            this.preloadChildNodes();
        }
    };
    TreeViewComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
        this.domSubscriptions.forEach(function (subscription) { return subscription(); });
    };
    TreeViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.add(this.nodeChildrenService
            .changes
            .subscribe(function (x) { return _this.childrenLoaded.emit(x); }));
        this.subscriptions.add(this.expandService.changes
            .subscribe(function (_a) {
            var index = _a.index, dataItem = _a.dataItem, expand = _a.expand;
            return expand
                ? _this.expand.emit({ index: index, dataItem: dataItem })
                : _this.collapse.emit({ index: index, dataItem: dataItem });
        }));
        this.subscriptions.add(this.navigationService.checks
            .subscribe(function (x) {
            return _this.checkedChange.emit(_this.treeViewLookupService.itemLookup(x));
        }));
        this.subscriptions.add(this.selectionService.changes
            .subscribe(function (x) {
            if (kendo_angular_common_1.hasObservers(_this.selectionChange)) {
                _this.ngZone.run(function () {
                    _this.selectionChange.emit(x);
                });
            }
        }));
        if (this.element) {
            this.ngZone.runOutsideAngular(function () {
                _this.attachDomHandlers();
            });
        }
        if (this.size) {
            this.renderer.addClass(this.element.nativeElement, utils_1.getSizeClass('treeview', this.size));
        }
    };
    /**
     * Blurs the focused TreeView item.
     */
    TreeViewComponent.prototype.blur = function () {
        if (!kendo_angular_common_1.isDocumentAvailable()) {
            return;
        }
        var target = utils_1.focusableNode(this.element);
        if (document.activeElement === target) {
            target.blur();
        }
    };
    /**
     * Focuses the first focusable item in the TreeView component if no hierarchical index is provided.
     *
     * @example
     * ```ts
     * import { Component } from '@angular/core';
     *
     *  @Component({
     *      selector: 'my-app',
     *      template: `
     *      <button (click)="treeview.focus('1')">Focuses the second node</button>
     *      <kendo-treeview
     *          #treeview
     *          [nodes]="data"
     *          textField="text"
     *      >
     *      </kendo-treeview>
     *  `
     *  })
     *  export class AppComponent {
     *      public data: any[] = [
     *          { text: "Furniture" },
     *          { text: "Decor" }
     *      ];
     *  }
     * ```
     */
    TreeViewComponent.prototype.focus = function (index) {
        var focusIndex = index || utils_1.nodeIndex(this.navigationService.focusableItem);
        this.navigationService.activateIndex(focusIndex);
        var target = utils_1.focusableNode(this.element);
        if (target) {
            target.focus();
        }
    };
    /**
     * Based on the specified index, returns the TreeItemLookup node.
     *
     * @param index - The index of the node.
     * @returns {TreeItemLookup} - The item that was searched (looked up).
     */
    TreeViewComponent.prototype.itemLookup = function (index) {
        return this.treeViewLookupService.itemLookup(index);
    };
    /**
     * Triggers the [`children`]({% slug api_treeview_treeviewcomponent %}#toc-children) function for every expanded node,
     * causing all rendered child nodes to be fetched again.
     */
    TreeViewComponent.prototype.rebindChildren = function () {
        this.dataChangeNotification.notify();
    };
    /**
     * Triggers the `expand` event for the provided node and displays it's loading indicator.
     */
    TreeViewComponent.prototype.expandNode = function (item, index) {
        this.expandService.expand(index, item);
    };
    /**
     * Triggers the `collapse` event for the provided node.
     */
    TreeViewComponent.prototype.collapseNode = function (item, index) {
        this.expandService.collapse(index, item);
    };
    /**
     * Gets the current page size of the checked data item children collection
     * ([see example]({% slug loadmorebutton_treeview %}#toc-managing-page-sizes)).
     *
     * > Since the root nodes collection is not associated with any parent data item, pass `null` as `dataItem` param to get its page size.
     *
     * @param dataItem {any} - The parent data item of the targeted collection.
     * @returns {number} - The page size of the checked data item children collection.
     */
    TreeViewComponent.prototype.getNodePageSize = function (dataItem) {
        this.verifyLoadMoreService();
        return this.loadMoreService.getGroupSize(dataItem);
    };
    /**
     * Sets the page size of the targeted data item children collection
     * ([see example]({% slug loadmorebutton_treeview %}#toc-managing-page-sizes)).
     *
     * > Since the root nodes collection is not associated with any parent data item, pass `null` as `dataItem` param to target its page size.
     *
     * @param dataItem {any} - The parent data item of the targeted collection.
     * @param pageSize {number} - The new page size.
     */
    TreeViewComponent.prototype.setNodePageSize = function (dataItem, pageSize) {
        this.verifyLoadMoreService();
        this.loadMoreService.setGroupSize(dataItem, pageSize);
    };
    /**
     * @hidden
     *
     * Clears the current TreeViewLookupService node map and re-registers all nodes anew.
     * Child nodes are acquired through the provided `children` callback.
     */
    TreeViewComponent.prototype.preloadChildNodes = function () {
        this.treeViewLookupService.reset();
        this.registerLookupItems(this.nodes);
    };
    TreeViewComponent.prototype.attachDomHandlers = function () {
        var element = this.element.nativeElement;
        this.clickHandler = this.clickHandler.bind(this);
        this.domSubscriptions.push(this.renderer.listen(element, 'contextmenu', this.clickHandler), this.renderer.listen(element, 'click', this.clickHandler), this.renderer.listen(element, 'dblclick', this.clickHandler), this.renderer.listen(element, 'focusin', this.focusHandler.bind(this)), this.renderer.listen(element, 'focusout', this.blurHandler.bind(this)), this.renderer.listen(element, 'keydown', this.keydownHandler.bind(this)));
    };
    TreeViewComponent.prototype.focusHandler = function (e) {
        var _this = this;
        var focusItem;
        if (utils_1.match(e.target, '.k-treeview-item')) {
            focusItem = e.target;
        }
        else if (!utils_1.isFocusable(e.target)) { // with compliments to IE
            focusItem = utils_1.closestNode(e.target);
        }
        if (focusItem) {
            this.navigationService.activateIndex(utils_1.nodeId(e.target));
            if (!this.isActive && kendo_angular_common_1.hasObservers(this.onFocus)) {
                this.ngZone.run(function () {
                    _this.onFocus.emit();
                });
            }
            this.isActive = true;
        }
    };
    TreeViewComponent.prototype.blurHandler = function (e) {
        var _this = this;
        if (this.isActive && utils_1.match(e.target, '.k-treeview-item') &&
            (!e.relatedTarget || !utils_1.match(e.relatedTarget, '.k-treeview-item') || !utils_1.hasParent(e.relatedTarget, this.element.nativeElement))) {
            this.navigationService.deactivate();
            this.isActive = false;
            if (kendo_angular_common_1.hasObservers(this.onBlur)) {
                this.ngZone.run(function () {
                    _this.onBlur.emit();
                });
            }
        }
    };
    TreeViewComponent.prototype.clickHandler = function (e) {
        var _this = this;
        var target = e.target;
        if ((e.type === 'contextmenu' && !kendo_angular_common_1.hasObservers(this.nodeClick)) ||
            (e.type === 'click' && !kendo_angular_common_1.hasObservers(this.nodeClick) && !kendo_angular_common_1.hasObservers(this.selectionChange) && !utils_1.isLoadMoreButton(target)) ||
            (e.type === 'dblclick' && !kendo_angular_common_1.hasObservers(this.nodeDblClick)) || utils_1.isFocusable(target) ||
            (!utils_1.isContent(target) && !utils_1.isLoadMoreButton(target)) || !utils_1.hasParent(target, this.element.nativeElement)) {
            return;
        }
        var index = utils_1.nodeId(utils_1.closestNode(target));
        // the disabled check is probably not needed due to the k-disabled styles
        if (!index || this.navigationService.isDisabled(index)) {
            return;
        }
        this.ngZone.run(function () {
            // record this value before emitting selectionChange (`this.navigationService.selectIndex`), as the treeview state may be changed on its emission
            var lookup = _this.treeViewLookupService.itemLookup(index);
            if (e.type === 'click') {
                var loadMoreButton = _this.navigationService.model.findNode(index).loadMoreButton;
                if (loadMoreButton) {
                    _this.navigationService.notifyLoadMore(index);
                    return;
                }
                else {
                    _this.navigationService.selectIndex(index);
                }
            }
            var emitter = e.type === 'dblclick' ? _this.nodeDblClick : _this.nodeClick;
            emitter.emit({
                item: lookup.item,
                originalEvent: e,
                type: e.type
            });
        });
    };
    TreeViewComponent.prototype.keydownHandler = function (e) {
        var _this = this;
        if (this.isActive && this.navigable) {
            this.ngZone.run(function () {
                _this.navigationService.move(e);
            });
        }
    };
    TreeViewComponent.prototype.verifyLoadMoreService = function () {
        if (core_1.isDevMode() && !utils_1.isPresent(this.loadMoreService)) {
            throw new Error("To use the TreeView paging functionality, you need to assign the `kendoTreeViewLoadMore` directive. See " + LOAD_MORE_DOC_LINK + ".");
        }
    };
    TreeViewComponent.prototype.registerLookupItems = function (data, parentItem) {
        var _this = this;
        if (parentItem === void 0) { parentItem = null; }
        if (!utils_1.isPresent(data) || data.length === 0) {
            return;
        }
        var parentIndex = utils_1.nodeIndex(parentItem);
        var treeItems = data.map(function (node, index) {
            return utils_1.buildTreeItem(node, index, parentIndex);
        });
        if (utils_1.isPresent(parentItem)) {
            this.treeViewLookupService.registerChildren(parentIndex, treeItems);
        }
        treeItems.forEach(function (item) {
            _this.treeViewLookupService.registerItem(item, parentItem);
            if (_this.hasChildren(item.dataItem)) {
                _this.children(item.dataItem)
                    .subscribe(function (children) {
                    return _this.registerLookupItems(children, item);
                });
            }
        });
    };
    tslib_1.__decorate([
        core_1.HostBinding("class.k-treeview"),
        tslib_1.__metadata("design:type", Boolean)
    ], TreeViewComponent.prototype, "classNames", void 0);
    tslib_1.__decorate([
        core_1.HostBinding("attr.role"),
        tslib_1.__metadata("design:type", String)
    ], TreeViewComponent.prototype, "role", void 0);
    tslib_1.__decorate([
        core_1.HostBinding("attr.dir"),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], TreeViewComponent.prototype, "direction", null);
    tslib_1.__decorate([
        core_1.ViewChild('assetsContainer', { read: core_1.ViewContainerRef, static: true }),
        tslib_1.__metadata("design:type", core_1.ViewContainerRef)
    ], TreeViewComponent.prototype, "assetsContainer", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], TreeViewComponent.prototype, "filterInputPlaceholder", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        core_1.HostBinding('@.disabled'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [Boolean])
    ], TreeViewComponent.prototype, "animate", null);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], TreeViewComponent.prototype, "childrenLoaded", void 0);
    tslib_1.__decorate([
        core_1.Output('blur'),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], TreeViewComponent.prototype, "onBlur", void 0);
    tslib_1.__decorate([
        core_1.Output('focus'),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], TreeViewComponent.prototype, "onFocus", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], TreeViewComponent.prototype, "expand", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], TreeViewComponent.prototype, "collapse", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], TreeViewComponent.prototype, "nodeDragStart", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], TreeViewComponent.prototype, "nodeDrag", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], TreeViewComponent.prototype, "filterStateChange", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], TreeViewComponent.prototype, "nodeDrop", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], TreeViewComponent.prototype, "nodeDragEnd", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], TreeViewComponent.prototype, "addItem", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], TreeViewComponent.prototype, "removeItem", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], TreeViewComponent.prototype, "checkedChange", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], TreeViewComponent.prototype, "selectionChange", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], TreeViewComponent.prototype, "filterChange", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], TreeViewComponent.prototype, "nodeClick", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], TreeViewComponent.prototype, "nodeDblClick", void 0);
    tslib_1.__decorate([
        core_1.ContentChild(node_template_directive_1.NodeTemplateDirective, { static: false }),
        tslib_1.__metadata("design:type", node_template_directive_1.NodeTemplateDirective)
    ], TreeViewComponent.prototype, "nodeTemplateQuery", void 0);
    tslib_1.__decorate([
        core_1.Input('nodeTemplate'),
        tslib_1.__metadata("design:type", node_template_directive_1.NodeTemplateDirective),
        tslib_1.__metadata("design:paramtypes", [node_template_directive_1.NodeTemplateDirective])
    ], TreeViewComponent.prototype, "nodeTemplateRef", null);
    tslib_1.__decorate([
        core_1.ContentChild(load_more_button_template_directive_1.LoadMoreButtonTemplateDirective, { static: false }),
        tslib_1.__metadata("design:type", load_more_button_template_directive_1.LoadMoreButtonTemplateDirective)
    ], TreeViewComponent.prototype, "loadMoreButtonTemplateQuery", void 0);
    tslib_1.__decorate([
        core_1.Input('loadMoreButtonTemplate'),
        tslib_1.__metadata("design:type", load_more_button_template_directive_1.LoadMoreButtonTemplateDirective),
        tslib_1.__metadata("design:paramtypes", [load_more_button_template_directive_1.LoadMoreButtonTemplateDirective])
    ], TreeViewComponent.prototype, "loadMoreButtonTemplateRef", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Function)
    ], TreeViewComponent.prototype, "trackBy", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Array),
        tslib_1.__metadata("design:paramtypes", [Array])
    ], TreeViewComponent.prototype, "nodes", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], TreeViewComponent.prototype, "textField", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Function])
    ], TreeViewComponent.prototype, "hasChildren", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Function])
    ], TreeViewComponent.prototype, "isChecked", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Function)
    ], TreeViewComponent.prototype, "isDisabled", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Function])
    ], TreeViewComponent.prototype, "isExpanded", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Function])
    ], TreeViewComponent.prototype, "isSelected", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Function)
    ], TreeViewComponent.prototype, "isVisible", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], TreeViewComponent.prototype, "navigable", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Function)
    ], TreeViewComponent.prototype, "children", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], TreeViewComponent.prototype, "loadOnDemand", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], TreeViewComponent.prototype, "filterable", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], TreeViewComponent.prototype, "filter", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], TreeViewComponent.prototype, "size", null);
    TreeViewComponent = tslib_1.__decorate([
        core_1.Component({
            changeDetection: core_1.ChangeDetectionStrategy.Default,
            exportAs: 'kendoTreeView',
            providers: providers,
            selector: 'kendo-treeview',
            template: "\n        <span\n            class=\"k-treeview-filter\"\n            *ngIf=\"filterable\"\n        >\n            <kendo-textbox\n                [size]=\"size\"\n                [value]=\"filter\"\n                [clearButton]=\"true\"\n                (valueChange)=\"filterChange.emit($event)\"\n                [placeholder]=\"filterInputPlaceholder\"\n            >\n                <ng-template kendoTextBoxPrefixTemplate>\n                    <span class=\"k-input-icon k-icon k-i-search\"></span>\n                </ng-template>\n            </kendo-textbox>\n        </span>\n        <ul class=\"k-treeview-lines\"\n            kendoTreeViewGroup\n            role=\"group\"\n            [size]=\"size\"\n            [loadOnDemand]=\"loadOnDemand\"\n            [checkboxes]=\"checkboxes\"\n            [expandIcons]=\"expandIcons\"\n            [selectable]=\"selectable\"\n            [touchActions]=\"touchActions\"\n            [children]=\"children\"\n            [hasChildren]=\"hasChildren\"\n            [isChecked]=\"isChecked\"\n            [isDisabled]=\"isDisabled\"\n            [isExpanded]=\"isExpanded\"\n            [isSelected]=\"isSelected\"\n            [isVisible]=\"isVisible\"\n            [nodeTemplateRef]=\"nodeTemplateRef?.templateRef\"\n            [loadMoreButtonTemplateRef]=\"loadMoreButtonTemplateRef?.templateRef\"\n            [textField]=\"textField\"\n            [nodes]=\"fetchNodes\"\n            [loadMoreService]=\"loadMoreService\"\n            [trackBy]=\"trackBy\"\n        >\n        </ul>\n        <ng-container #assetsContainer></ng-container>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.ElementRef,
            core_1.ChangeDetectorRef,
            expand_state_service_1.ExpandStateService,
            navigation_service_1.NavigationService,
            node_children_service_1.NodeChildrenService,
            selection_service_1.SelectionService,
            treeview_lookup_service_1.TreeViewLookupService,
            core_1.NgZone,
            core_1.Renderer2,
            data_change_notification_service_1.DataChangeNotificationService,
            kendo_angular_l10n_1.LocalizationService])
    ], TreeViewComponent);
    return TreeViewComponent;
}());
exports.TreeViewComponent = TreeViewComponent;
