/**
 * @ngdoc directive
 * @name amtFramework.grid.directive:amtGridColumn
 * @restrict E
 * 
 * @param {string} field Field name of the datasource
 * @param {string} title Column header title
 * @param {string=} width Initial width of the column
 * @param {string=} [type=string] Possible options: 'integer', 'double', 'boolean', 'date', 'datetime', 'dropdown', 'string'
 * @param {string=} filterType Possible options: 'dropdown'
 * @param {string=} [hidden=false] Wether the Column is initially hidden 
 * @param {string=} [optional=true] Wether the user can hide the column
 * @param {string=} attributes Additional html attributes e.g {"style":"text-align:right"}
 * @param {string=} headerattributes Additional html attributes e.g {"style":"text-align:right"}
 * @param {expression=} [filterable=true] To hide the filter function
 * @param {string=} hideText ???
 * @param {string=} [editable=true] Wether the column is editable
 * @param {string=} defaultValue Default value when adding a new row
 * @param {string=} minValue Min value for validation
 * @param {string=} maxValue Max value for validation
 * @param {expression=} filterEditorOption ???
 * @param {expression=} filterDatasource Datasource for dropdown filter
 * @param {string=} dropdownValue Selected value for dropdown
 * @param {string=} dropdownUrl Url for options in dropdown
 * @param {string=} dropdownRequired Required --> Rename to isRequired and use for all types
 * @param {string=} [aggregate=true] --> Value either sum/count/min/max/average for summary in AMT Grid summary row
 * @param {integer=} maxLength Max length
 * @param {boolean=} [isRequired=true] --> True if you want this field is required in inline edit mode. This will add an asterisk character in heading of column
 * @param {boolean=} [disableEqualFilter=false] --> True if you want to hide the equal column filter
 * 
 * @description
 * Grid column to show and edit different data types
 */

//import angular from 'angular';

angular.module('amtFramework.grid').directive('amtGridColumn', ['appConfig', 'amtXlatSvc',
    function (appConfig, xlatSvc) {
                return {
                    require: ['^amtGrid', '?^amtGridGroupHeaderColumn'],
                    restrict: 'E',
                    scope: {
                        field: '@',
                        title: '@',
                        width: '@',
                        type: '@',
                        alignment: '@',
                        filterType: "@",
                        hidden: '@',
                        forceHide: '@',
                        optional: '@',
                        attributes: '@',
                        headerAttributes: '@',
                        searchField: "@",
                        searchFieldType: "@",
                        filterable: '=?',
                        sortable: "@",
                        hideText: '@',
                        editable: '@',
                        defaultValue: "@",
                        minValue: "@",
                        maxValue: "@",
                        filterEditorOption: "=?",
                        dropdownValue: "@",
                        dropdownUrl: "@",
                        dropdownRequired: "@",
                        aggregate: "@",
                        maxLength: "@",
                        isRequired: "@",
                        disableEqualFilter: "@",
                        wrap: "@",
                        decimals: "@?",
                        locked: "@"
                    },
                    controller: ['$scope', function ($scope) {

                        $scope.optional = $scope.optional ? $scope.optional.toString().toLowerCase() === "true" : false;
                        $scope.hidden = $scope.hidden ? $scope.hidden.toString().toLowerCase() === "true" : false;
                        $scope.kendoColumnDefinition = {
                        };

                        var ASTERISK = " *";
                        $scope.kendoColumnDefinition.optional = $scope.optional;
                        $scope.kendoColumnDefinition.sortable = $scope.sortable === undefined ? true : $scope.$eval($scope.sortable);
                        $scope.kendoColumnDefinition.hidden = $scope.hidden;
                        $scope.kendoColumnDefinition.field = $scope.field;

                        $scope.kendoColumnDefinition.searchField = $scope.searchField;
                        $scope.kendoColumnDefinition.searchFieldType = $scope.searchFieldType;

                        $scope.kendoColumnDefinition.id = $scope.field;
                        $scope.kendoColumnDefinition.forceHide = $scope.forceHide === "true";


                        $scope.kendoColumnDefinition.title = $scope.title;
                        if ($scope.isRequired && $scope.isRequired === "true") {
                            $scope.kendoColumnDefinition.title += ASTERISK;
                        }
                        $scope.kendoColumnDefinition.width = $scope.width;
                        $scope.kendoColumnDefinition.editable = $scope.editable === undefined ? true : $scope.$eval($scope.editable);


                        this.setFilterDefinition = function (filterEditor, operators) {
                            $scope.filterable = {
                                ui: filterEditor,
                                operators: operators
                            };
                        };
                    }],
                    link: function (scope: any, elem, attrs, controllerarr) {
                        //Grid Controller or the Optional Grouping Header
                        var amtGridCtrl = (controllerarr[1] == null) ? controllerarr[0] : controllerarr[1];

                        if (attrs.aggregate) {
                            scope.kendoColumnDefinition.aggregates = [];
                            scope.kendoColumnDefinition.aggregates.push(scope.aggregate);
                            amtGridCtrl.addAggregate({
                                field: scope.field,
                                aggregate: scope.aggregate
                            });
                            switch (scope.type) {
                                case "integer":
                                    scope.kendoColumnDefinition.footerTemplate = "<div class='text-right'> #= isNaN(parseInt(" + scope.aggregate + ")) ? '' : kendo.toString(" + scope.aggregate + ", '\\\\##,\\\\#') #</div>";
                                    break;
                                case "double":
                                    scope.kendoColumnDefinition.footerTemplate = "<div class='text-right'> #= isNaN(parseFloat(" + scope.aggregate + ")) ? '' : kendo.toString(" + scope.aggregate + ", 'n" + (scope.decimals >= 0 ? scope.decimals : 2) + "') #</div>";
                                    break;
                                default:
                                    scope.kendoColumnDefinition.footerTemplate = "<div class='text-right'> #= " + scope.aggregate + " #</div>";
                                    break;
                            }
                        }

                        scope.$watch('forceHide', function (newValue, oldValue) {
                            if (amtGridCtrl.showHideGridColumn !== undefined && scope.forceHide) {
                                amtGridCtrl.showHideGridColumn(scope, newValue);
                            }
                        });

                        scope.$watch('locked', function (newValue) {
                            scope.kendoColumnDefinition.locked = newValue;
                        });

                        scope.$watch('width', function (newValue) {
                            scope.kendoColumnDefinition.width = newValue;
                        });

                        var buildIntegerTemplate = function () {
                            return '#if(' + scope.field + ' !== null &&' + !scope.hideText + '){#<div class="text-' + (scope.alignment ? scope.alignment : 'right') + '">#: kendo.toString(' + scope.field + ', "\\\\#\\\\#,\\\\#") #</div>#} #';
                        };

                        var buildStringTemplate = function () {
                            return '#if(' + scope.field + ' !== null &&' + !scope.hideText + '){#<div class="text-' + (scope.alignment ? scope.alignment : 'left') + '">#: ' + scope.field + ' #</div>#} #';
                        };

                        var buildYesNoTemplate = function () {
                            scope.xlatYes = scope.xlatYes || xlatSvc.xlat("common.yes_label");
                            scope.xlatNo = scope.xlatNo || xlatSvc.xlat("common.no_label");
                            return '#if(' + scope.field + ' !== null &&' + !scope.hideText + '){#<div class="text-' + (scope.alignment ? scope.alignment : 'center') + '">#if(' + scope.field + '){# ' + scope.xlatYes + '#} else {# ' + scope.xlatNo +'#}#</div>#} #';
                        };

                        var buildIntegerEditor = function (container, options) {
                            var input = $("<input class='k-input k-textbox' data-role='numerictextbox'/>");
                            input.attr("data-type", "number");
                            input.attr("name", options.field);
                            if (scope.isRequired === "true") {
                                input.prop("required", true);
                                input.attr("data-required-msg", xlatSvc.xlat('common.validationRequired', scope.title));
                            }
                            input.appendTo(container);
                            (<any>input).kendoNumericTextBox({
                                spinners: false,
                                min: scope.minValue,
                                change: function () {
                                    var value = this.value();
                                    if (value === null && !angular.isUndefined(scope.minValue)) {
                                        this.value(scope.minValue);
                                    }
                                }
                            });
                        };



                        var buildBooleanTemplate = function () {
                            return '<div class="text-center" style="display: flex; justify-content: center"><input type="checkbox" #=' + scope.field + ' ? "checked=checked" : "" # class="medium-checkbox" disabled="disabled"></input></div>';
                        };

                        var buildBooleanEditor = function (container, options) {
                            var input = $("<input/>");
                            input.attr("type", "checkbox");
                            input.attr("name", options.field);
                            if (scope.isRequired === "true") {
                                input.prop("required", true);
                            }
                            var divWrapper = $("<div class='text-center'></div>");
                            input.appendTo(divWrapper);
                            divWrapper.appendTo(container);
                        };

                        var buildDoubleTemplate = function () {
                            return '<div class="text-' + (scope.alignment ? scope.alignment : 'right') + '">#= ' + scope.field + ' !== null ? kendo.toString(' + scope.field + ', "n' + (scope.decimals >= 0 ? scope.decimals : 2) + '") : "" #</div>';
                        };

                        var buildDateTemplate = function () {
                            return '<div class="text-' + (scope.alignment ? scope.alignment : 'left') + '">#= ' + scope.field + ' !== null ? kendo.toString(new Date(' + scope.field + '), "' + appConfig.dateFormat.shortDate + '") : ""   #</div>';
                        };

                        var buildDateTimeTemplate = function () {
                            return '<div class="text-' + (scope.alignment ? scope.alignment : 'left') + '">#= ' + scope.field + ' !== null ? kendo.toString(new Date(' + scope.field + '), "' + appConfig.dateFormat.shortDateTime + '") : ""   #</div>';
                        };

                        var buildDateEditor = function (container, options) {
                            var model = options.model;
                            model[scope.field] = kendo.parseDate(model[scope.field]);
                            var input = $("<input />");
                            if (scope.isRequired === "true") {
                                input.prop("required", true);
                                input.attr("data-required-msg", xlatSvc.xlat('common.validationRequired', scope.title));
                            }
                            input.attr("data-bind", "value:" + scope.field);
                            //$('<span class="k-invalid-msg" data-for="datetimecontrol"></span>').appendTo(container);
                            input.appendTo(container);

                            (<any>input).kendoDatePicker({
                                format: appConfig.dateFormat.shortDate,
                                value: model[scope.field],
                                change: function () {
                                    model.dirty = true; // tell amtGrid know that row is dirty
                                    model[scope.field] = this.value();
                                }
                            });



                        };

                        var buildDateTimeEditor = function (container, options) {
                            var model = options.model;
                            model[scope.field] = kendo.parseDate(model[scope.field]);
                            var input = $("<input />");
                            if (scope.isRequired === "true") {
                                input.prop("required", true);
                                input.attr("data-required-msg", xlatSvc.xlat('common.validationRequired', scope.title));
                            }
                            input.appendTo(container);
                            (<any>input).kendoDateTimePicker({
                                format: appConfig.dateFormat.shortDateTime,
                                value: model[scope.field],
                                change: function () {
                                    model.dirty = true; // tell amtGrid know that row is dirty
                                    model[scope.field] = this.value();
                                }
                            });
                        };


                        var buildDropDownListTemplate = function () {
                            return '<span>#= ' + scope.field + '?' + scope.field + ':""#</span>';
                        };

                        var buildDropdownListEditor = function (container, options) {
                            var model = options.model;
                            var input = $('<input />');
                            input.attr("data-bind", "value:" + scope.dropdownValue);
                            if (scope.dropdownRequired) {
                                input.prop("required", true);
                            }
                            input.appendTo(container);
                            (<any>input).kendoComboBox({
                                autoBind: false,
                                filter: "contains",
                                dataTextField: "name",
                                dataValueField: "key",
                                valuePrimitive: true,
                                value: model[scope.dropdownValue],
                                text: model[scope.field],
                                dataSource: {
                                    serverFiltering: true,
                                    transport: {
                                        read: scope.dropdownUrl,
                                        parameterMap: function (data, type) {
                                            data.searchText = '';
                                            if (data.filter && data.filter.filters.length > 0) {
                                                data.searchText = data.filter.filters[0].value;
                                                delete data.filter;
                                            }
                                            return data;
                                        }
                                    },
                                    requestEnd: function (e) {
                                        this.data(e.response.data && e.response.data.result || []);
                                        scope.filterDatasource = e.response.data && e.response.data.result || [];

                                    }
                                },
                                change: function (e) {
                                    model.dirty = true; // tell amtGrid know that row is dirty
                                    if (this.dataItem()) {
                                        model[scope.field] = this.dataItem().name;
                                        model[scope.dropdownValue] = this.dataItem().key;
                                    }
                                }
                            });

                        };

                        var buildTextTemplate = function () {
                            if (scope.alignment) {
                                return '<span class="text-' + scope.alignment + '">#: ' + scope.field + ' #</span>';
                            }
                            return '<span>#: ' + scope.field + ' #</span>';                            
                        };

                        var buildTextEditor = function (container, options) {
                            var input = $("<input class='k-input k-textbox' />");
                            if (scope.maxLength) {
                                input.attr("maxlength", scope.maxLength);
                            }
                            if (scope.isRequired === "true") {
                                input.prop("required", true);
                                input.attr("data-required-msg", xlatSvc.xlat('common.validationRequired', scope.title));
                            }
                            input.attr("name", options.field);
                            //<span class="k-invalid-msg" data-for="time"></span>
                            input.appendTo(container);
                        };

                        var operators = {
                            //filter menu for "string" type columns
                            string: {
                                eq: scope.disableEqualFilter ? undefined : xlatSvc.xlat("framework.isEqualTo"),
                                neq: scope.disableEqualFilter ? undefined : xlatSvc.xlat("framework.neq_label"),
                                startswith: xlatSvc.xlat("framework.startsWith_label"),
                                contains: xlatSvc.xlat("framework.contains_label"),
                                endswith: xlatSvc.xlat("framework.endsWith_label")
                            },
                            //filter menu for "number" type columns
                            number: {
                                eq: scope.disableEqualFilter ? undefined : xlatSvc.xlat("framework.isEqualTo"),
                                neq: scope.disableEqualFilter ? undefined : xlatSvc.xlat("framework.neq_label"),
                                gte: xlatSvc.xlat("framework.greaterThanOrEqual_label"),
                                gt: xlatSvc.xlat("framework.greaterThan_label"),
                                lte: xlatSvc.xlat("framework.smallerThanOrEqual_label"),
                                lt: xlatSvc.xlat("framework.smallerThan_label")
                            },
                            //filter menu for "date" type columns
                            date: {
                                eq: scope.disableEqualFilter ? undefined : xlatSvc.xlat("framework.isEqualTo"),
                                neq: scope.disableEqualFilter ? undefined : xlatSvc.xlat("framework.neq_label"),
                                gte: xlatSvc.xlat("framework.greaterThanOrEqual_label"),
                                gt: xlatSvc.xlat("framework.greaterThan_label"),
                                lte: xlatSvc.xlat("framework.smallerThanOrEqual_label"),
                                lt: xlatSvc.xlat("framework.smallerThan_label")
                            }
                        };

                        var template;
                        var editor;
                        var filterable: any = "text";
                        if (scope.type) {
                            switch (scope.type) {
                                case "integer":
                                    template = buildIntegerTemplate();
                                    editor = buildIntegerEditor;
                                    filterable = {
                                        ui: function (element) {
                                            element.kendoNumericTextBox(scope.filterEditorOption || { format: 'n0' });
                                        },
                                        operators: operators
                                    };
                                    scope.type = "number";
                                    break;

                                case "double":
                                    template = buildDoubleTemplate();
                                    editor = buildIntegerEditor;
                                    filterable = {
                                        ui: function (element) {
                                            element.kendoNumericTextBox(scope.filterEditorOption || {});
                                        },
                                        operators: operators
                                    };
                                    scope.type = "number";
                                    break;

                                case "boolean":
                                    template = buildBooleanTemplate();
                                    editor = buildBooleanEditor;
                                    break;


                                case "yesno":
                                    template = buildYesNoTemplate();
                                    editor = null; //TODO: hopefully this throws, this is a temporary hack, not supporting editablity?!
                                    break;

                                case "date":
                                    template = buildDateTemplate();
                                    editor = buildDateEditor;
                                    filterable = {
                                        ui: function (element) {
                                            element.kendoDatePicker({
                                                format: appConfig.dateFormat.shortDate
                                            });
                                        },
                                        operators: operators
                                    };
                                    break;

                                case "datetime":
                                    template = buildDateTimeTemplate();
                                    editor = buildDateTimeEditor;
                                    filterable = "date";
                                    break;

                                case "dropdown":
                                    template = buildDropDownListTemplate();
                                    editor = buildDropdownListEditor;
                                    scope.type = "string";
                                    break;
                                default:
                                    template = buildStringTemplate();
                                    editor = buildTextEditor;
                            }

                        } else {
                            //template = "";
                            template = buildTextTemplate();
                            editor = buildTextEditor;
                        }


                        scope.kendoColumnDefinition.type = scope.type;
                        scope.kendoColumnDefinition.template = template;
                        scope.kendoColumnDefinition.editor = editor;
                        scope.kendoColumnDefinition.menu = scope.optional;

                        scope.kendoColumnDefinition.filterable = scope.filterable === undefined ? filterable : scope.filterable;
                        scope.kendoColumnDefinition.locked = scope.locked;

                        if (scope.attributes) {
                            if (!scope.kendoColumnDefinition.attributes) {
                                scope.kendoColumnDefinition.attributes = {};
                            }
                            var attrString = scope.attributes;
                            var attrJson = JSON.parse(attrString);
                            for (var attrname in attrJson) {
                                scope.kendoColumnDefinition.attributes[attrname] = attrJson[attrname];
                            }
                        }

                        if (scope.wrap === "true") {
                            if (!scope.kendoColumnDefinition.attributes) {
                                scope.kendoColumnDefinition.attributes = {};
                            }

                            scope.kendoColumnDefinition.attributes["style"] = "word-wrap: break-word; white-space: normal;";
                        }

                        if (scope.headerAttributes) {
                            if (!scope.kendoColumnDefinition.headerAttributes) {
                                scope.kendoColumnDefinition.headerAttributes = {};
                            }
                            attrString = scope.headerAttributes;
                            attrJson = JSON.parse(attrString);
                            for (attrname in attrJson) {
                                scope.kendoColumnDefinition.headerAttributes[attrname] = attrJson[attrname];
                            }
                        }

                        scope.$on('$destroy', function () {
                            amtGridCtrl.removeColumn(scope.kendoColumnDefinition);
                        });


                        amtGridCtrl.addColumn(scope.kendoColumnDefinition);

                        if (amtGridCtrl.showHideGridColumn !== undefined && scope.forceHide === "true") {
                            amtGridCtrl.showHideGridColumn(scope, true);
                        }
                    }
                };
    }
]);
