//import angular from 'angular';
import tmpl from './ocEquipmentComment.html';



    angular.module('app.directives').directive("ocEquipmentComment", [function () {
        return {
            scope: {
                tabObject: "=",
                equipmentObject: "=",
                openModule: "=",
                notification: "=",
                showEventCallback: "&"
            },
            template: tmpl,
            controllerAs: 'vm',
            controller:
                ['$scope', 'equipmentUrls', 'amtCommandQuerySvc', 'confirmSvc', 'amtXlatSvc', 'exportSvc', 'notifySvc', 'ocSecuritySvc', 'ocConfigSvc', 'errorReporter',
                    function ($scope, equipmentUrls, amtCommandQuerySvc, confirmSvc, amtXlatSvc, exportSvc, notifySvc, ocSecuritySvc, ocConfigSvc, errorReporter) {
                        var vm = this;

                        vm.apiUrls = equipmentUrls.url;
                        vm.config = ocConfigSvc;

                        vm.canViewStatusChange = false;

                        vm.commentGrid = {};

                        vm.columnWrapAttribute = {
                            "style": "white-space: normal;"
                        };

                        $scope.$watch('equipmentObject', function () {
                            if ($scope.equipmentObject) {
                                vm.searchCriteria = {
                                    filterValues: {
                                        equipmentId: $scope.equipmentObject.id
                                    }
                                };

                                // security
                                vm.canAdd = ($scope.equipmentObject.isVehicle ? ocSecuritySvc.isAuthorised('Security.Vehicles.Comments.Add', 'readWrite') : ocSecuritySvc.isAuthorised('Security.Components.Comments.Add', 'readWrite'));
                                vm.canDelete = ($scope.equipmentObject.isVehicle ? ocSecuritySvc.isAuthorised('Security.Vehicles.Comments.Delete', 'readWrite') : ocSecuritySvc.isAuthorised('Security.Components.Comments.Delete', 'readWrite'));
                                vm.canEdit = ($scope.equipmentObject.isVehicle ? ocSecuritySvc.isAuthorised('Security.Vehicles.Comments', 'readWrite') : ocSecuritySvc.isAuthorised('Security.Components.Comments', 'readWrite'));

                                vm.canViewStatusChange = ($scope.equipmentObject.isVehicle ? ocSecuritySvc.isAuthorised('Security.Vehicles.ChangeStatus', 'readOnly') : ocSecuritySvc.isAuthorised('Security.Components.ChangeStatus', 'readOnly'));
                                vm.canViewMaintenanceSession = ocSecuritySvc.isAuthorised('Security.Vehicles.MaintenanceSession', 'readOnly');
                                vm.canViewAttachments = ($scope.equipmentObject.isVehicle ? ocSecuritySvc.isAuthorised('Security.Vehicles.Attachments', 'readOnly') : ocSecuritySvc.isAuthorised('Security.Components.Attachments', 'readOnly'));

                                $scope.updateTabObjects();
                            }
                        });

                        $scope.rowParseFunction = function (response) {
                            var comments = response.result;

                            for (var r = 0; r < comments.length; r++) {
                                comments[r].canOpenEvent = $scope.equipmentObject.siteId === vm.config.user.site.id && (!comments[r].siteId || comments[r].siteId === vm.config.user.site.id) &&
                                    ((comments[r].source === 'EquipmentAttachment' && vm.canViewAttachments) || (comments[r].editMode === 'Maintenance' ? vm.canViewMaintenanceSession : vm.canViewStatusChange));

                                comments[r].onSite = $scope.equipmentObject.siteId === vm.config.user.site.id && (!comments[r].siteId || comments[r].siteId === vm.config.user.site.id);

                                comments[r].canDelete = comments[r].canDelete && comments[r].onSite;
                            }

                            return response;
                        };

                        vm.refresh = function () {
                            if (vm.commentGrid && vm.commentGrid.refresh) {
                                vm.commentGrid.refresh();
                            }
                        };

                        $scope.deleteComment = function (dataItem) {
                            if (dataItem.canDelete === false) {
                                notifySvc.error(amtXlatSvc.xlat("equipment.commentCannotDelete"));
                                return;
                            }

                            confirmSvc.confirmMessage('equipment.confirmDeleteComment_title', 'equipment.confirmDeleteComment_text').then(function () {

                                vm.loading = true;

                                amtCommandQuerySvc.post(vm.apiUrls.deleteComment, dataItem).then(function (response) {

                                    notifySvc.success(amtXlatSvc.xlat("equipment.commentDeleteSuccessful"));
                                    vm.refresh();

                                }).catch(function (error) {
                                    errorReporter.logError(error);
                                }).finally(function () {
                                    vm.loading = false;
                                });
                            });
                        };

                        function openComment(component, titleResourceKey, initParams) {
                            $scope.openModule(
                                component,
                                amtXlatSvc.xlat(titleResourceKey),
                                'comment',
                                initParams,
                                400,
                                null,
                                true,
                                vm.refresh, // reference to a function which will be called when the child window changes data
                                false
                            );
                        }

                        $scope.editComment = function (item) {
                            openComment(
                                'comment-popup',
                                "equipment.commentEdit",
                                {
                                    mode: "edit",
                                    equipmentId: $scope.equipmentObject.id,
                                    id: item.id,
                                    source: item.source,
                                    eventId: item.eventId,
                                    showCloseOnSave: false,
                                    readonly: !vm.canEdit
                                }
                            );
                        };

                        function addComment() {
                            openComment(
                                "comment-popup",
                                "equipment.commentAdd",
                                {
                                    mode: "new",
                                    equipmentId: $scope.equipmentObject.id,
                                    component: 'comment-popup',
                                    showCloseOnSave: false,
                                    readonly: !vm.canAdd
                                }
                            );
                        }

                        $scope.showEventDetails = function (item) {
                            switch (item.source) {
                                case "Event":
                                    if ($scope.showEventCallback) {
                                        $scope.showEventCallback({
                                            equipmentEventId: item.eventId,
                                            editMode: item.editMode,
                                            target: (item.currentStatus.toLowerCase() === 'maintenance' ?
                                                'checkin' :
                                                ((item.previousStatus && item.previousStatus.toLowerCase() === 'maintenance') ? 'checkout' :
                                                    item.currentStatus.toLowerCase()
                                                )
                                            )
                                        });
                                    }
                                    break;

                                case "EquipmentAttachment":
                                    $scope.openModule(
                                        'attachment-popup',
                                        amtXlatSvc.xlat("equipment.attachmentEdit"),
                                        'attachment',
                                        {
                                            mode: 'edit',
                                            equipmentId: $scope.equipmentObject.id,
                                            attachmentId: item.id,
                                            source: item.source,
                                            showCloseOnSave: false,
                                            readonly: !vm.canEdit
                                        },
                                        700,
                                        450,
                                        true,
                                        vm.refresh, // reference to a function which will be called when the child window changes data
                                        false
                                    );
                                    break;

                                case "Comment":
                                    $scope.editComment(item);
                                    break;
                            }
                        };

                        function getExportFilename() {
                            return amtXlatSvc.xlat('equipment.commentsExportFilename', $scope.equipmentObject.serialNumber.formatted);
                        }

                        vm.export = function () {
                            exportSvc.exportData(
                                vm.apiUrls.commentsExport, // url
                                vm.searchCriteria, // criteria
                                getExportFilename() // output filename
                            );
                        };

                        $scope.updateTabObjects = function () {
                            $scope.tabObject.refresh = vm.refresh;
                            $scope.tabObject.canRefresh = true;

                            $scope.tabObject.canDelete = vm.canDelete;

                            $scope.tabObject.canAdd = vm.canAdd;
                            $scope.tabObject.add = addComment;

                            $scope.tabObject.canExport = true;
                            $scope.tabObject.export = vm.export;
                        };

                        $scope.updateTabObjects();
                    }]
        };
    }]);
