//import angular from 'angular';
import { UpdateForecastOverrideModelMaker } from '../../updateForecastOverrideCtrl';


angular.module("app.site").controller("forecastExistingVehicleScheduleController", forecastExistingVehicleScheduleController);

    // @ts-ignore
    forecastExistingVehicleScheduleController.$inject = ['$scope', '$state', '$rootScope', 'amtConstants', 'amtCommandQuerySvc', 'enums',
        'amtXlatSvc', 'notifySvc', 'WindowFactory', 'errorReporter', 'forecastingSvc'];

    // ReSharper disable once InconsistentNaming
    function forecastExistingVehicleScheduleController($scope, $state, $rootScope, amtConstants,
        amtCommandQuerySvc, enums, xlatSvc, notifySvc, WindowFactory, errorReporter, forecastingSvc) {

        var vm = this;

        vm.apiUrls = amtConstants.url;

        vm.gridControl = {};
        vm.noEndDate = xlatSvc.xlat("forecastExistingVehicleSchedule.noEndDate");

        vm.forecastId = $rootScope.$stateParams.forecastid ? $rootScope.$stateParams.forecastid : 0;
        vm.vehiclespecificationid = $rootScope.$stateParams.vehiclespecificationid ? $rootScope.$stateParams.vehiclespecificationid : 0;
        vm.vehicleSpecName = $rootScope.$stateParams.vehiclespecname ? $rootScope.$stateParams.vehiclespecname : '';
        vm.vehicleCount = $rootScope.$stateParams.vehiclecount ? ('(' + $rootScope.$stateParams.vehiclecount + ')') : '';
        vm.forecastStartDate = $rootScope.$stateParams.forecaststartdate ? $rootScope.$stateParams.forecaststartdate : '';
        vm.forecastEndDate = $rootScope.$stateParams.forecastenddate ? $rootScope.$stateParams.forecastenddate : '';

        vm.filterValues = {
            forecastId: vm.forecastId,
            vehicleSpecificationId: vm.vehiclespecificationid
        };

        this.$onInit = function () {
            if (!(vm.forecastId > 0)) {
                $state.go('app.site.forecastlist');
            }
        };

        vm.goToParentRoute = function () {
            forecastingSvc.setActiveTabName("Vehicles");
            $rootScope.goBack();
        };

        $scope.updateVehicleEndDate = function (dataItem) {

            WindowFactory.openItem({
                component: 'edit-vehicle-end-date',
                canMinimise: false,
                modal: true,
                canClose: false,
                footerOff: false,
                caption: xlatSvc.xlat('forecastVehicles.editVehicleEndDate'),
                initParams: {
                    forecastId: vm.forecastId,
                    vehicleId: dataItem.forecastVehicleId,
                    vehicleEndDate: dataItem.vehicleEndDate,
                    forecastStartDate: vm.forecastStartDate,
                    forecastEndDate: vm.forecastEndDate
                },
                onDataChangeHandler: vm.refresh
            });
        };

        $scope.updateVehicleHoursAtStartOfForecast = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                vehicleSpecificationId: dataItem.vehicleSpecificationId,
                type: enums.forecastOverrideTypes.vehicleHoursAtStartOfForecast,
                value: dataItem.overrideVehicleHoursAtStartOfForecast,
                vehicleId: dataItem.forecastVehicleId,
                heading: xlatSvc.xlat('forecastVehicles.vehicleHoursAtStartOfForecastOverride')
            }), vm.refresh);
        };

        $scope.updateExcludeFromForecast = function (dataItem) {

            let criteria = {
                forecastId: dataItem.forecastId,
                forecastVehicleId: dataItem.forecastVehicleId,
                excludeFromForecast: dataItem.excludeFromForecast === true ? false : true
            };

            vm.processing = true;

            amtCommandQuerySvc.put(amtConstants.url.updateVehicleExcludeFromForecast, criteria).then(function (response) {

                dataItem.excludeFromForecast = !dataItem.excludeFromForecast;

                notifySvc.success(xlatSvc.xlat("forecastVehicles.messageVehicleExcludeFromForecastUpdateSuccess"));

            }).catch(function (error) {
                errorReporter.logError(error);
            }).finally(function () {
                vm.processing = false;
            });
        };

        vm.refresh = function () {
            if (vm.gridControl && vm.gridControl.refresh) {
                vm.gridControl.refresh();
            }
        };
    }
