//import angular from 'angular';
import BrowserSvc from '../../../../services/browserSvc';
import OcConfigSvc from '../../../../services/ocConfigSvc';
import tmpl from "./ReadingGrid.html";

import imgPhotoBlank from '../../../../../Content/images/photoBlank.png';
import imgPhotoAdd from '../../../../../Content/images/photoAdd.png';
import imgPhoto1 from '../../../../../Content/images/photo1.png';
import imgPhoto2 from '../../../../../Content/images/photo2.png';
import imgPhoto3 from '../../../../../Content/images/photo3.png';
import imgPhoto4 from '../../../../../Content/images/photo4.png';
import imgPhoto5 from '../../../../../Content/images/photo5.png';
import imgPhoto6 from '../../../../../Content/images/photo6.png';
import imgPhoto7 from '../../../../../Content/images/photo7.png';
import imgPhoto8 from '../../../../../Content/images/photo8.png';
import imgPhoto9 from '../../../../../Content/images/photo9.png';
import imgPhotoMany from '../../../../../Content/images/photoMany.png';



//TODO: this doesn't belong here
interface IReadGridPositions {
    fittedInThisSession: boolean;
    TyreFitment: any;
    ChainFitment: any;
    label: string;
    rtd1Selected: boolean;
    rtd2Selected: boolean;
    pressureSelected: boolean;
}


class readingGridCtrl implements ng.IController {
    private units;
    public readonly pressureMaxLength: number;

    private isMobile = this.browserSvc.isMobile;

    private deactivated: { [key: string]: boolean } = {}; // collection of deactivated columns
    private showReadOrder = false;

    private activeReading: any = null; // the row  being entered
    private activeAttr = null; // the column being entered

    public positionReadings: { positionNo: number; positionId: string; temp: any; positionLabel: string }[];
    public positions: IReadGridPositions[];
    public showReadings: boolean;
    public editableAttrs: string[];
    public useNitrogen: boolean;
    public ready: boolean;
    public allowTemperatureReadings: boolean;
    public active: boolean;
    public save: (validate?: boolean) => void;
    public disabledAttributes: { [key: string]: boolean };
    public readOnly: boolean;
    public hoursOK: string | number; //why?
    public surveyDate: any;
    public form: ng.IFormController;
    private schematicSelection: (guid, string) => void;

    //scope Listener Deregistration functions
    private modifierClickOff;
    private schematicClickOff;

    static $inject = ["$timeout", "$scope", "ocConfigSvc", "browserSvc", "amtXlatSvc",
        "WindowFactory", "$filter", "amtConstants"];

    constructor(private $timeout: ng.ITimeoutService, private $scope: ng.IScope, private ocConfigSvc: OcConfigSvc, private browserSvc: BrowserSvc, private amtXlatSvc: IAmtXlatSvc,
        private WindowFactory, private $filter: ng.IFilterService, private amtConstants) {

        this.units = this.units || this.ocConfigSvc.user.site.units;
        this.pressureMaxLength = this.units.pressure.unitAbbreviation == 'kPa' ? 4 : 3;

        this.modifierClickOff = this.$scope.$on(Keypad.MODIFIER_KEY_PRESSED, (evt, key) => this.modifierClick(key));
        this.schematicClickOff = this.$scope.$on("SchematicClickedInt", (evt, pos, seg) => this.gridCellClick(seg, pos.wheelPositionNumber));
    }

    public $onInit() {
        for (let i = 0; i < this.positionReadings.length; i++) {
            let pos = this.positions[this.positionReadings[i].positionId];
            if (!pos.disabledAttributes)
                pos.disabledAttributes = {};
        }
    }

    public dataReload() {
        if (this.positionReadings && this.showReadings) {

            this.constructEditableAttributes();

            this.$timeout(); //not sure if this is necessary but poke the digest to make sure VI icons get updated
        }
    }

    public $onChanges(changes) {
        this.dataReload();

        if (changes.allowRtd && (!changes.allowRtd.currentValue === !this.deactivated['rtd1']) && (!this.deactivated['rtdManual'])) {
            this.toggleRtd(true);
        }
    }

    private constructEditableAttributes() {
        this.editableAttrs = [];

        // site allows temperature readings or the ms/fs already has temperate readings saved (that should be editable)
        this.allowTemperatureReadings = this.ocConfigSvc.user.site.settings.tyreMaintenance.allowTemperatureReading ||
            this.positionReadings.some(p => !!p.temp && this.positions[p.positionId].TyreFitment && !this.positions[p.positionId].fittedInThisSession);

        this.useNitrogen = this.ocConfigSvc.user.site.settings.tyreMaintenance.useNitrogen;

        this.editableAttrs.push("pressure");

        if (this.allowTemperatureReadings) {
            this.editableAttrs.push("temp");
        }

        this.editableAttrs.push("adjPressure");

        if (this.useNitrogen) {
            this.editableAttrs.push("nitrogen");

            for (let i = 0; i < this.positionReadings.length; i++) {

                let pos = this.positions[this.positionReadings[i].positionId];

                if (pos.TyreFitment && !pos.TyreFitment.nitrogenFilled) {
                    if (!pos.disabledAttributes)
                        pos.disabledAttributes = {};

                    pos.disabledAttributes['nitrogen'] = true;
                }
            }
        }

        this.editableAttrs.push("rtd1");
        this.editableAttrs.push("rtd2");
    }

    public $onDestroy() {
        this.schematicClickOff();
        this.modifierClickOff();
    }

    public keyHandler(evt: JQuery.KeyDownEvent) {
        let key: string = null;
        switch (evt.which) {
            case 37 /* left */:
                key = "LEFT";
                break;
            case 39 /* right */:
            case 13 /* enter */:
                key = "RIGHT";
                break;
            case 9 /* tab */:
                key = evt.shiftKey ? "SHIFTTAB" : "TAB";
                break;
            case 38 /* up */:
                key = "UP";
                break;
            case 40 /* down */:
                key = "DOWN";
                break;
        }

        if (key) {
            evt.preventDefault();
            evt.stopPropagation();
            this.modifierClick(key);
        }
    }

    public modifierClick(key) {
        if (key === "RELOAD-READING-GRID") {
            this.dataReload();
            return;
        }

        if (!this.active)
            return false;

        let orderedPositions = this.$filter('orderBy')(this.positionReadings, v => this.sorterFunc(v));

        let xStart = this.activeAttr != null ? this.editableAttrs.findIndex(a => a === this.activeAttr) : 0;
        let yStart = this.activeReading != null ? orderedPositions.findIndex(p => p.positionNo === this.activeReading.positionNo) : 0;

        //some helpful lambdas
        let up = yStart => {
            for (let y = yStart - 1; y >= 0; --y) {
                if (this.isEdittablePositionFromId(orderedPositions[y].positionId)) {
                    return y;
                }
            }
            return -1;
        }

        let down = yStart => {
            for (let y = yStart + 1; y < orderedPositions.length; ++y) {
                if (this.isEdittablePositionFromId(orderedPositions[y].positionId)) {
                    return y;
                }
            }
            return -1;
        }

        let setActive = (x, y) => this.gridCellClick(this.editableAttrs[x], orderedPositions[y].positionNo);

        switch (key) {
            case "LEFT-CTRL":
            case "LEFT":
            case "SHIFTTAB":
                for (let x = xStart - 1, y = yStart; ; --x) {
                    if (x < 0) {
                        y = up(y);
                        if (y < 0)
                            return false; //can't go up any further

                        x += this.editableAttrs.length;
                    }

                    if (!this.deactivated[this.editableAttrs[x]]) {
                        setActive(x, y);
                        return true; //found new active cell
                    }
                }
                break;

            case "RIGHT-CTRL":
            case "RIGHT":
            case "TAB":
                xStart++; //start at the next grid pos one attrib to the right and fallthrough...
            case "SET-FOCUS":
                for (let x = xStart, y = yStart; ; ++x) {
                    if (x >= this.editableAttrs.length) {
                        y = down(y);
                        if (y < 0)
                            return false; //can't go down any further

                        x -= this.editableAttrs.length;
                    }

                    if (!this.deactivated[this.editableAttrs[x]]) {
                        setActive(x, y);
                        return true; //found new active cell
                    }
                }
                break;
            case "UP":
                if (!this.deactivated[this.editableAttrs[xStart]]) {
                    let y = up(yStart);
                    if (y >= 0) {
                        setActive(xStart, y);
                        return true;
                    }
                }
                break;
            case "DOWN":
                if (!this.deactivated[this.editableAttrs[xStart]]) {
                    let y = down(yStart);
                    if (y >= 0) {
                        setActive(xStart, y);
                        return true;
                    }
                }
                break;
        }
        return;
    }

    public visualInspection(row) {
        this.WindowFactory.openItem({
            component: "visual-inspection",
            caption: this.amtXlatSvc.xlat("fieldSurvey.visualInspection"),
            width: 950,
            canClose: false,
            top: this.isMobile ? this.amtConstants.windowTopMarginMobile : null,
            initParams: {
                readingRow: row,
                surveyDate: angular.copy(this.surveyDate),
                readonly: (this.readOnly || this.positions[row.positionId].fittedInThisSession)
            },
            modal: true,
            onDataChangeHandler: (readingRow) => {
                this.form.$setDirty();
                this.save();
                this.$timeout(); //not sure if this is necessary but poke the digest to make sure VI icons get updated
            }
        });
    }

    public visualInspectionIsActive(readingRow) {
        return !!readingRow.visualInspections?.some(vi => vi.active);
    }

    public visualInspectionImage(readingRow) {
        let position = this.positions[readingRow.positionId];

        if (!this.readOnly && !position.TyreFitment)
            return imgPhotoBlank;

        let viCount: number = readingRow.visualInspections?.length;

        switch (viCount) {
            case 1:
                return imgPhoto1;
            case 2:
                return imgPhoto2;
            case 3:
                return imgPhoto3;
            case 4:
                return imgPhoto4;
            case 5:
                return imgPhoto5;
            case 6:
                return imgPhoto6;
            case 7:
                return imgPhoto7;
            case 8:
                return imgPhoto8;
            case 9:
                return imgPhoto9;
        }

        if (viCount)
            return imgPhotoMany;

        if (!this.readOnly && position.TyreFitment && !position.fittedInThisSession)
            return imgPhotoAdd;

        return imgPhotoBlank;
    }

    public refocusHack() {
        //HACK: (quick and dirty) deselect and reselect to update focus
        if (this.activeAttr) {
            let attr = this.activeAttr;
            this.activeAttr = null;
            this.$timeout(() => { this.activeAttr = attr });
        }
    }

    //This method is to emit the ReadingGridClicked
    private onGridChange(attr: string, posNo: number): void {
        let onChange: boolean = this.onGridChangeValidation(attr, posNo);
        attr = (attr === "temp" || attr === "adjPressure") ? "pressure" : attr;

        if (onChange) {
            this.$scope.$emit('ReadingGridClicked', posNo, attr);
        }
    }

    private onGridChangeValidation(attr: string, posNo: number): boolean {
        let positionId: string = this.positionReadings.find(pr => pr.positionNo === posNo).positionId;
        let hasActiveAttr: boolean = this.activeAttr !== undefined && this.activeAttr !== null;
        let hasActiveReading: boolean = this.activeReading !== undefined && this.activeReading !== null;

        //New Location Validation
        let selectedAttrPosNo: string = attr + posNo.toString();
        let currentAttrPosNo: string = this.activeAttr + this.activeReading?.positionNo.toString();

        //onChangeGrid Validation
        let isDeactivedAttr: boolean = this.deactivated[attr] ? this.deactivated[attr] : false;
        let isEnabledColumn: boolean = this.positions[positionId].disabledAttributes[attr] || (this.positions[positionId].TyreFitment && !this.positions[positionId].fittedInThisSession);
        let isNewLocation: boolean = selectedAttrPosNo !== currentAttrPosNo;
        let isInit: boolean = !hasActiveAttr && !hasActiveReading;

        return !isDeactivedAttr && isEnabledColumn && (isInit || isNewLocation);
    }


    public gridCellClick(attr: string, posNo: number) {
        this.onGridChange(attr, posNo);
        if (!this.active || this.deactivated[attr])
            return;

        //if they set the focus to the current grid cell, see if it was to reenter rtd with chain details, and if not call the focus hack, then bail (no more to do)
        if (this.activeAttr === attr && this.activeReading && this.activeReading.positionNo === posNo) {
            if (!this.checkAndDoChain()) {
                this.refocusHack();
            }
            return;
        }

        if (this.activeReading) {
            //do not save/Validate if going rtd1 to rtd2 or back
            let validate = (this.activeAttr !== "rtd1" && this.activeAttr !== "rtd2") || (attr !== "rtd1" && attr !== "rtd2");
            this.save(validate);
            //this.form.$setPristine();
        }

        this.activeAttr = attr;
        this.activeReading = this.positionReadings.find(pr => pr.positionNo === posNo);

        //TODO: whatever this intends to do it probably doesn't belong here
        //(if needed probably should be moved into databroker when / where positions are loaded)
        if (!this.positions[this.activeReading.positionId]) {
            // WheelTypeID is used for positions on the mobile (in order to match the specifications to vehicle)
            console.warn("The readings have positions which do not match the current vehicle mobile wheel positions - re-mapping");

            for (let r of this.positionReadings || []) {
                for (let posId in this.positions) {
                    if (this.positions.hasOwnProperty(posId) && this.positions[posId].label === r.positionLabel) {
                        r.positionId = posId;
                    }
                }
            }
        }

        if (this.schematicSelection) {
            let segment = this.activeAttr == 'rtd1' || this.activeAttr == 'rtd2' ? this.activeAttr : 'pressure';
            this.schematicSelection(this.activeReading.positionId, segment);
        }

        this.checkAndDoChain();

        this.$timeout();
    }

    public checkAndDoChain() {
        //check chain
        let activeRtd = this.activeReading && !this.deactivated[this.activeAttr] &&
            (this.activeAttr === "rtd1" || this.activeAttr === "rtd2");

        let pos = activeRtd ? this.positions[this.activeReading.positionId] : null;

        if (!(pos && ((pos.ChainFitment && pos.ChainFitment.serialNumber) || (this.activeReading.chainSerial)) && !this.activeReading.chainRemoved))
            return false;

        //show chain popup
        let reading = this.activeReading; //we save and clear activeReading to focus it to refocus (physical kb input) when dialog closes
        this.activeReading = null;
        let initParams = angular.copy(reading);

        initParams.chainAndTyre1 = initParams.rtd1 != null && initParams.rcd1 != null && !initParams.chainAndTyre1
            ? initParams.rtd1 + initParams.rcd1 : null;
        initParams.chainAndTyre2 = initParams.rtd2 != null && initParams.rcd2 != null && !initParams.chainAndTyre2
            ? initParams.rtd2 + initParams.rcd2 : null;

        this.WindowFactory.openItem({
            component: "chains-popup",
            caption: this.amtXlatSvc.xlat("fieldSurvey.surveyChangeHeader", reading.positionLabel, reading.positionNo),
            initParams: initParams,
            width: 600,
            canClose: false,
            modal: true,
            onDataChangeHandler: (chainAndTyre1, rcd1, chainAndTyre2, rcd2) => {
                this.activeReading = reading;
                this.$timeout();

                if (chainAndTyre1 < 0)
                    return; //they cancelled

                let hasData = chainAndTyre1 != null || chainAndTyre2 != null;

                this.activeReading.rcd1 = hasData ? rcd1 : undefined;
                this.activeReading.rcd2 = hasData ? rcd2 : undefined;
                this.activeReading.rtd1 = hasData ? chainAndTyre1 - rcd1 : undefined;
                this.activeReading.rtd2 = hasData ? chainAndTyre2 - rcd2 : undefined;
                this.activeReading.chainAndTyre1 = hasData ? chainAndTyre1 : undefined;
                this.activeReading.chainAndTyre2 = hasData ? chainAndTyre2 : undefined;
                this.form.$setDirty();
                this.save();
            }
        });
        return true;
    }

    public sorterFunc(reading) {
        return parseInt(this.showReadOrder ? reading.positionNo : reading.positionLabel);
    }

    public isEdittablePositionFromId(posId: string): boolean {
        return !this.positions[posId].fittedInThisSession && this.positions[posId].TyreFitment;
    }

    public togglePressure() {
        this.deactivated.pressure = !this.deactivated.pressure;
        this.deactivated.temp = this.deactivated.pressure;
        this.deactivated.adjPressure = this.deactivated.pressure;

        this.updateDisabledReadings();
    }

    public toggleTemp() {
        if (!this.deactivated.pressure) {
            this.deactivated.temp = !this.deactivated.temp;

            this.updateDisabledReadings();
        }
    }

    public toggleAdjPressure() {
        if (!this.deactivated.pressure) {
            this.deactivated.adjPressure = !this.deactivated.adjPressure;

            this.updateDisabledReadings();
        }
    }

    public toggleNitrogen() {
        this.deactivated.nitrogen = !this.deactivated.nitrogen;

        this.updateDisabledReadings();
    }

    public toggleRtd(nonManual = false) {

        var current = this.deactivated.rtd1;

        if (this.ocConfigSvc.user.site.settings.tyreMaintenance.allowTreadReadingsWithoutVehicleReadings ||
            (this.hoursOK !== '' && this.hoursOK >= 0) || !current) {

            if (!nonManual) {
                this.deactivated.rtdManual = !this.deactivated.rtdManual;
            }

            this.deactivated.rtd1 = !this.deactivated.rtd1;
            this.deactivated.rtd2 = !this.deactivated.rtd2;

            this.updateDisabledReadings();
        }
    }

    public updateDisabledReadings() {
        if (this.deactivated[this.activeAttr]) {
            this.modifierClick("TAB");
        } else {
            this.refocusHack();
        }
    }
}


angular.module("app.directives").component("readingGrid", {
    template: tmpl,
    bindings: {
        units: "<?",
        showReadOrder: "=", // toggle the order on the schematic
        hoursOK: "<", // indicates the hours have been entered
        surveyDate: "<", // used as the default date for visual inspections.
        readOnly: "<",
        ready: "<",
        allowRtd: "<?",
        active: "<",
        save: "<",
        positions: "<",
        positionReadings: "<",
        form: "=?", //HACK: parent should be able to use its own form
        showReadings: "=",
        activeError: "=?",
        schematicSelection: '<'
    },
    controller: readingGridCtrl
});