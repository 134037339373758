/**
 * @ngdoc directive
 * @name amtFramework.filter.directive:amtMultiselectFilteritem
 * @sortOrder 20
 * @restrict E
 * @requires amtFramework.filter.directive:amtFilter
 * 
 * @param {string} label Label to display above the control
 * @param {string} propertyName Name of the property that is added to filterValues property of {@link amtFramework.filter.directive:amtFilter amtFilter}
 * @param {expression} url Url to get the data from. Only use when options is not set.
 * @param {expression} options Array of options. Only use when Url is not set.
 * @param {expression=} [autoComplete=true] Autocomplete
 * @param {expression=} [isRequired=false] Required
 * @param {expression=} [isDisabled=false] Disabled
 * @param {expression=} extensionParams Object that contains additional values that should be passed in the querystring. {branchId: 1, siteId: 2}
 * @param {string} idField The nme of the id field that will be used at the value of a selected item
 * @param {string} textField The nme of the text field that will be used at the description of a selected item
 * @param {expression=} sortOnKey Wether the options are sorted by key or name
 * @param {expression=} [selectAllItems=false] Determines if all items are selected by default when the control is loaded.
 * @param {expression=} [displayRight=false] Force the drop down to position on the right - for use in windows
 * @param {expression=} descriptor Describes the placeholder text when all items are selected i.e "All descriptor"
 * 
 * @description
 * Filter item that let's the user filter on multiple selected items selected from a list. Only adds the value to filtervalues when the user selects a value.
 * 
 * @example
 *<doc:example module="amtFramework">
 *   <doc:source>
 * <amt-filter>
 *     Sort on Name
 *     <amt-multiselect-filteritem ng-model="selectedIds" options='[{key: 201501, name:"01 2015"}, {key: 201502, name:"02 2015"}, {key: 201601, name:"01 2016"}, {key: 201602, name:"02 2016"}]'></amt-multiselect-filteritem>
 * 
 *     Sort on Key
 *     <amt-multiselect-filteritem ng-model="selectedIds2" sort-on-key="true" options='[{key: 201501, name:"01 2015"}, {key: 201502, name:"02 2015"}, {key: 201601, name:"01 2016"}, {key: 201602, name:"02 2016"}]'></amt-multiselect-filteritem>
 * </amt-filter>
 * </doc:source>
 * </doc:example>
 */
//import angular from 'angular';
import tmpl from './amtMultiselectFilteritem.html';

angular.module('amtFramework.filter').directive('amtMultiselectFilteritem', function() {
            return {
                require: '^amtFilter',
                restrict: 'E',
                scope: {
                    label: '@',
                    propertyName: "@",
                    name: "@",
                    url: '@',
                    options: '=',
                    autoComplete: '=?',
                    isRequired: '=?',
                    isDisabled: '=?',
                    useHttpPost: '=?',
                    extensionParams: '=?',
                    preserveOrder: '=',
                    idField: '@',
                    textField: '@',
                    sortOnKey: '=?',
                    isResetDependency: '=?',
                    clearOnDependencyPropertyClear: '@?',
                    selectedItems: '=?',
                    selectAllItems: '=?',
                    displayRight: '=?',
                    onSelectedChange: '&',
                    descriptor: '@',
                    emptyDescriptor: '@',
                    showInactive: "=",
                    control: '='
                },
                template: tmpl,
                link: function (scope: any, elem, attr, filterCtrl) {
                    scope.filterItem = { propertyName: scope.propertyName };
                    scope.onSelectedChangeInt = function (selectedIds) {
                        if (attr.onSelectedChange) {
                            scope.onSelectedChange({ options: selectedIds });
                        }
                    };

                    filterCtrl.addFilterItem(scope.filterItem);
                }
            };
});
