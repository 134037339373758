import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Widget } from '../../service/Interface/SiteDashboard.interface';
import { FilterConstant, PressureCheckUi } from '../../service/Interface/WidgetConstant.interface';
import { PressureCheckVehiceWidgetResult, PressureCheckWidget } from '../../service/Interface/Widget.interface';
import { WidgetRxjsService } from '../../service/rxjs/WidgetRxjs.service';
import { PressureCheckVehicleWidgetHelper } from '../../service/widgetHelper/PressureCheckVehicleWidget.helper';
import { WidgetConstant } from '../../service/widgetHelper/WidgetConstant';

import { Subscription } from 'rxjs';

@Component({
    selector: 'widget-pressure-checks-vehicle',
    templateUrl: './pressure-check-vehicle.component.html',
    styleUrls: ['./../../site-dashboard.component.scss']
})

//Completed
export class SiteDashboardPressureCheckVehicleComponent implements OnInit, OnDestroy {
    @ViewChild("vehicleApplicationDropdownList", { static: true })
    public vehicleApplicationList: Array<string>;
    public defaultVehicles: PressureCheckVehiceWidgetResult[];
    public selectedVehicles: PressureCheckVehiceWidgetResult[];
    public hoveredVehicle: PressureCheckVehiceWidgetResult;
    public filterTerm!: string;
    public selectedVehicleApplication: string;
    public hasReceivedResult: boolean = false;
    public isSerialNumberAscOrder: boolean = false;
    public isSerialNumberDescOrder: boolean = false;
    public isPressureCheckCountAscOrder: boolean = false;
    public isPressureCheckCountDescOrder: boolean = false;
    public siteDashboardWidgetId: string;
    public ui: PressureCheckUi;
    public isMouseHover: boolean = false;
    public result: PressureCheckWidget = {
        siteDashboardWidgetId: '',
        reportResult: [],
        hasReceivedResult: false
    };
    public resultSubscription: Subscription;

    constructor(private constant: WidgetConstant, private pressureCheckVehicleWidgetHelper: PressureCheckVehicleWidgetHelper, private widgetRxjs: WidgetRxjsService) {
        this.ui = this.constant.getPressureCheckWidgetUi();
    }

    //Get widget detail from Site Dashboard
    @Input() set getWidgetDetail(widget: Widget) {
        this.siteDashboardWidgetId = widget.siteDashboardWidgetId;
    }

    ngOnInit() {
        this.getPressureCheckPerVehicleWidgetResult();
    }

    ngOnDestroy() {
        this.resultSubscription.unsubscribe();
    }

    getPressureCheckPerVehicleWidgetResult() {
        this.resultSubscription = this.widgetRxjs.pressureCheckVehicleWidget.subscribe(
            widgets => {
                let hasPressureCheck = widgets.length > 0;

                if (hasPressureCheck) {
                    widgets.forEach(widget => {
                        if (widget.siteDashboardWidgetId === this.siteDashboardWidgetId) {
                            this.setDefaultLandingWidget(widget);
                        }
                    });
                }
            });
    }

    async setDefaultLandingWidget(result: PressureCheckWidget) {
        this.defaultVehicles = this.pressureCheckVehicleWidgetHelper.setPressureCheckCount(result.reportResult);
        this.selectedVehicles = this.defaultVehicles;
        await this.sortBySerialNumber(); //asc
        await this.sortBySerialNumber(); //desc
        await this.sortByPressureCheckCount(); //asc
        this.vehicleApplicationList = this.pressureCheckVehicleWidgetHelper.setPressureCheckVehicleApplications(result.reportResult);
        this.selectedVehicleApplication = this.vehicleApplicationList[0];
        this.result = result;
    }
    

    //When mouse is over on the serial number, set the vehicle in detail box
    showVehicleDetail(selectedSerialNumber: string) {
        this.selectedVehicles.forEach(vehicle => {
            if (vehicle.serialNumber === selectedSerialNumber)
            {
                this.hoveredVehicle = vehicle;
                this.isMouseHover = true;
            }
        });
    }

    //When mouse moved out from the serial number, dismiss the detail box
    dismissVehicleDetail() {
        this.isMouseHover = false;
    }

    //When Change the vehicle Applications get selected Vehicle Application from Default Vehicle
    changeVehicleApplication(selectedVehicleApplication: string) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        let selectedVehicleList = [];

        if (selectedVehicleApplication === filterConstant.allVehicleApplication) {
            this.selectedVehicles = this.defaultVehicles;
        }
        else {
            this.defaultVehicles.forEach(vehicle => {
                if (selectedVehicleApplication === vehicle.applicationName) {
                    selectedVehicleList.push(vehicle);
                }
            });
            this.selectedVehicles = selectedVehicleList;
        }

        this.orderByReset();
    }

    //Sort the selected vehicles to asc or desc based on the user behaviour
    sortBySerialNumber() {
        if (!this.isSerialNumberAscOrder) {
            this.selectedVehicles.sort((a, z) => (a.serialNumber > z.serialNumber) ? 1 : -1);
            this.isSerialNumberAscOrder = true;
            this.isSerialNumberDescOrder = false;
            this.isPressureCheckCountAscOrder = false;
            this.isPressureCheckCountDescOrder = false;
        } else if (this.isSerialNumberAscOrder) {
            this.selectedVehicles.sort((a, z) => (a.serialNumber < z.serialNumber) ? 1 : -1);
            this.isSerialNumberAscOrder = false;
            this.isSerialNumberDescOrder = true;
            this.isPressureCheckCountAscOrder = false;
            this.isPressureCheckCountDescOrder = false;
        }
    }

    //Sort the selected vehicles to asc or desc based on the user behaviour
    sortByPressureCheckCount() {
        if (!this.isPressureCheckCountAscOrder) {
            this.selectedVehicles.sort((a, z) => (a.pressureCheckCount > z.pressureCheckCount) ? 1 : -1);
            this.isSerialNumberAscOrder = false;
            this.isSerialNumberDescOrder = false;
            this.isPressureCheckCountAscOrder = true;
            this.isPressureCheckCountDescOrder = false;
        } else if (this.isPressureCheckCountAscOrder) {
            this.selectedVehicles.sort((a, z) => (a.pressureCheckCount < z.pressureCheckCount) ? 1 : -1);
            this.isSerialNumberAscOrder = false;
            this.isSerialNumberDescOrder = false;
            this.isPressureCheckCountAscOrder = false;
            this.isPressureCheckCountDescOrder = true;
        }
    }

    //When the selected vehicle application has changed, reset the orderBy
    orderByReset() {
        this.isSerialNumberAscOrder = false;
        this.isSerialNumberDescOrder = false;
        this.isPressureCheckCountAscOrder = false;
        this.isPressureCheckCountDescOrder = false;
    }

    //Set the vehicleSerialNumber to search in vehicle/search
    setSearchBy(selectedSerialNumber: string) {
        localStorage.setItem('vehicleSerialNumber', selectedSerialNumber);
    }

}