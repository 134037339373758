//import angular from 'angular';
import BrowserSvc from '../../../services/browserSvc';
import AdminUrls from '../../admin/adminUrls';
import tmpl from './settings.html';



    angular.module('app.common').component('systemNotificationUserSettings', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['$scope', 'amtCommandQuerySvc', 'adminUrls', 'WindowFactory', 'confirmSvc', 'ocConfigSvc', 'browserSvc', 'errorReporter', 'enums', 'amtXlatSvc',
            function ($scope, amtCommandQuerySvc, adminUrls: AdminUrls, WindowFactory: IWindowFactory, confirmSvc, ocConfigSvc, browserSvc: BrowserSvc, errorReporter, enums, amtXlatSvc: IAmtXlatSvc) {
                var vm = this;
                $scope.vm = this;

                vm.adminUrls = adminUrls;

                vm.form = {};

                vm.data = {
                    notificationTimeout: ocConfigSvc.user.notificationSettings.timeout,
                    showNotifications: ocConfigSvc.user.notificationSettings.show
                };

                $scope.$watch('vm.form.$dirty', function () {
                    vm.wnd.isDirty = vm.form.$dirty;
                });

                this.$onInit = function () {
                    vm.wnd.onClose = vm.onClose;

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: 'common.save_label',
                            name: 'saveButton',
                            click: 'saveButton',
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: true,
                            value: 'common.cancel_label',
                            name: 'cancelButton',
                            click: 'cancelButton',
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: true,
                            disabled: true
                        },
                        cancelButton: {
                            visible: true,
                            disabled: false
                        }
                    };

                    vm.buttonMethods = {
                        saveButton: vm.save,
                        cancelButton: closeWindow
                    };

                    vm.getTimeoutOptions();
                };

                vm.getTimeoutOptions = function () {

                    var timeoutOptions = [];

                    for (var i = 1; i <= 60; i++) {
                        timeoutOptions.push({
                            value: i,
                            description: i
                        });
                    }

                    timeoutOptions.push({
                        value: -1,
                        description: amtXlatSvc.xlat('alerts.remainDisplayed')
                    });

                    vm.timeoutOptions = timeoutOptions;
                };

                vm.save = function () {
                    if (!vm.form.$invalid && !vm.form.$pristine) {

                        vm.wnd.processing = true;

                        var criteria = {
                            showNotifications: vm.data.showNotifications,
                            notificationTimeout: vm.data.notificationTimeout.value
                        };

                        amtCommandQuerySvc.post(vm.adminUrls.saveNotificationSettings, criteria).then(function (response) {
                            if (response && response.status === enums.httpUpdateStatus.success) {

                                // update the config
                                ocConfigSvc.user.notificationSettings = {
                                    timeout: vm.data.notificationTimeout.value,
                                    show: vm.data.showNotifications
                                };

                                vm.form.$setPristine();

                                closeWindow();
                            }
                        }).catch(function (error) {
                            errorReporter.logError(error, "NotificationSettings-Save");
                            WindowFactory.alert('alerts.systemNotificationUserSettings', ['common.ok_label'], 'exception.generic_error');
                        }).finally(function () {
                            vm.wnd.processing = false;
                        });
                    }
                };

                function closeWindow() {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        vm.form.$setPristine();

                        WindowFactory.closeWindow(vm.wnd);
                    });
                }

                vm.onClose = function () {
                    closeWindow();
                };

                function updateButtonStates() {
                    vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.form.$pristine || vm.wnd.processing || browserSvc.isMobile;
                }

                $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing'], updateButtonStates);                
            }
        ]
    });
