//import angular from 'angular';
import BrowserSvc from '../../../services/browserSvc';
import tmpl from './list.html';

    angular.module('app.common').component('notificationsList', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controllerAs: 'vm',
        controller: ['$scope', 'WindowFactory', 'browserSvc', 'errorReporter', 'notificationUrls', 'amtCommandQuerySvc', 'ocConfigSvc', 'amtXlatSvc',
            'exportSvc', 'ocAlertAndNotificationSvc', 'confirmSvc', 'enums', 'notifySvc', '$timeout', 'ocSecuritySvc',
            function ($scope, WindowFactory: IWindowFactory, browserSvc: BrowserSvc, errorReporter, notificationUrls, amtCommandQuerySvc, ocConfigSvc, amtXlatSvc: IAmtXlatSvc, exportSvc,
                ocAlertAndNotificationSvc, confirmSvc, enums, notifySvc, $timeout: ng.ITimeoutService, ocSecuritySvc) {
                var vm = this;

                vm.apiUrls = notificationUrls;

                vm.canViewVehicles = ocSecuritySvc.isAuthorised('Security.Vehicles', 'readOnly');
                vm.canViewComponents = ocSecuritySvc.isAuthorised('Security.Components', 'readOnly');

                vm.alertGrid = {};
                vm.notificationGrid = {};
                vm.faultsGrid = {};

                vm.alertCriteria = {};

                vm.columnWrapAttribute = {
                    "style": "white-space: normal;"
                };

                // notification search criteria, defaulted here for the export.
                vm.notificationCriteria = {
                    filterValues: {},
                    group: [],
                    pageSize: 10000,
                    skip: 0,
                    take: 10000
                };

                vm.openSystemNotificationSettings = function () {
                    WindowFactory.openItem({
                        component: 'system-notification-user-settings',
                        caption: amtXlatSvc.xlat('alerts.systemNotificationUserSettings'),
                        width: 700,
                        initParams: {
                            showCloseOnSave: false
                        },
                        canClose: false,
                        modal: true
                    });
                };

                vm.tabs = {
                    alerts: {
                        name: 'alerts',
                        selected: false,
                        refresh: function () {
                            if (vm.alertGrid.refresh) {
                                vm.alertGrid.refresh();
                            }
                        },
                        settingsResource: amtXlatSvc.xlat("alerts.alertSettings"),
                        openSettings: vm.openSystemNotificationSettings,
                        export: function () {
                            exportSvc.exportData(
                                vm.apiUrls.exportAlerts,
                                vm.alertCriteria,
                                amtXlatSvc.xlat('alerts.alertExportFilename')
                            );
                        },
                        grid: vm.alertGrid
                    },
                    notifications: {
                        name: 'notifications',
                        selected: false,
                        refresh: function () {
                            console.log('refresh');
                            console.log(vm.notificationGrid.refresh);
                            if (vm.notificationGrid.refresh) {
                                vm.notificationGrid.refresh();
                            }
                        },
                        settingsResource: amtXlatSvc.xlat("alerts.notificationSettings"),
                        openSettings: vm.openSystemNotificationSettings,
                        grid: vm.notificationGrid
                    },
                    faults: {
                        name: 'faults',
                        selected: false,
                        hasSettings: false,
                        refresh: function () {
                            if (vm.faultsGrid.refresh) {
                                vm.faultsGrid.refresh();
                            }
                        }
                    }
                };

                this.$onInit = function () {
                    vm.wnd.onClose = vm.onClose;

                    vm.buttonStates = {
                        // Standard buttons
                        refreshButton: {
                            visible: true,
                            enabled: true
                        },
                        // Action buttons
                        notificationSettingsButton: {
                            visible: true
                        },
                        dismissNotificationsButton: {
                            visible: false
                        },
                        exportButton: {
                            visible: false
                        }
                    };

                    if (vm.initParams.defaultTab === 'notifications') {
                        vm.selectTab(vm.tabs.notifications);
                    } else {
                        vm.selectTab(vm.tabs.alerts);
                    }

                    vm.buttonMethods = {
                        refresh: $scope.onRefresh,
                        openSettings: vm.currentTab.openSettings,
                        dismiss: $scope.dismissAllNotifications,
                        export: function () { vm.currentTab.export() }
                    };

                    // on open of the alerts/notifications window acknowledge new notifications
                    ocAlertAndNotificationSvc.acknowledgeToast().then(function () {

                        // poll alerts/notifications
                        ocAlertAndNotificationSvc.runNow();
                    });

                    vm.tabs.alerts.refresh();
                    vm.tabs.notifications.refresh();
                };

                vm.onSelectedAlertChanged = function (item) {

                };

                vm.onSelectedNotificationChanged = function (item) {

                };

                vm.onDatabound = function () {
                    checkButtons();
                }

                $scope.dismissAllNotifications = function () {
                    confirmSvc.confirmMessage('alerts.confirmDismissAll_title', 'alerts.confirmDismissAll_text', ocConfigSvc.user.notifications.total).then(function () {

                        vm.wnd.processing = true;

                        amtCommandQuerySvc.post(
                            vm.apiUrls.dismissAllNotifications,
                            vm.notificationCriteria
                        ).then(
                            function (response) {
                                if (response) {
                                    // reduce the total count of notifications
                                    ocAlertAndNotificationSvc.runNow();
                                    // reload the grid if the server managed to suppress the notification
                                    vm.notificationGrid.refresh();
                                }
                            }, function (error) {
                                errorReporter.logError(error);
                            }
                        ).finally(function () {
                            vm.wnd.processing = false;
                        });
                    }
                    );
                };

                $scope.onRefresh = function () {
                    vm.currentTab.refresh();
                };

                $scope.openItem = function (item) {

                    var wnd = {
                        initParams: {
                            componentType: "",
                            serialNumber: "",
                            equipmentId: ""
                        },
                        caption: "",
                        type: "",
                        component: "",
                        height: 0,
                        width: 0
                    };

                    if (item.equipment) {

                        if (item.equipment.componentType === 'Vehicle') {
                            wnd.caption = amtXlatSvc.xlat('equipment.openVehicle', item.equipment.serialNumber.formatted);
                            //wnd.type = 'vehicle';
                            wnd.component = 'vehicle-details';
                        } else {
                            wnd.caption = amtXlatSvc.xlat('equipment.open' + item.equipment.componentType, item.equipment.serialNumber.formatted);
                            //wnd.type = 'component';
                            wnd.component = 'component-details';
                            wnd.initParams.componentType = item.equipment.componentType;
                        }

                        wnd.width = 800;
                        wnd.height = 850;
                        wnd.initParams.serialNumber = item.equipment.serialNumber.formatted;
                        wnd.initParams.equipmentId = item.equipment.id;

                        WindowFactory.openItem(wnd);
                    }
                };

                $scope.rowParseFunction = function (response) {
                    // TODO: need to make some changes in here to handle html characters in the name and value properties
                    if (response.result !== undefined) {
                        var notifications = response.result;

                        for (var r = 0; r < notifications.length; r++) {

                            if (notifications[r].body === null || notifications[r].body === '') {
                                notifications[r].body = "<span>-</span>";
                            }

                            if (notifications[r].equipment) {
                                notifications[r].link = amtXlatSvc.xlat("equipment.open" + notifications[r].equipment.componentType, notifications[r].equipment.serialNumber.formatted);
                                notifications[r].linkActive = (notifications[r].equipment.componentType === 'Vehicle' && vm.canViewVehicles) || (notifications[r].equipment.componentType !== 'Vehicle' && vm.canViewComponents);
                            }
                        }
                    }

                    return response;
                };

                $scope.dismissNotification = function (item) {

                    vm.wnd.processing = true;

                    amtCommandQuerySvc.post(
                        vm.apiUrls.dismissNotification,
                        { id: item.id }
                    ).then(
                        function (response) {
                            if (response) {
                                // reduce the total count of notifications
                                ocAlertAndNotificationSvc.runNow();
                                // reload the grid if the server managed to suppress the notification
                                vm.notificationGrid.refresh();
                            }
                        },
                        function (error) {
                            errorReporter.logError(error);
                        }
                    ).finally(function () {
                        vm.wnd.processing = false;
                    });
                };

                vm.selectTab = function (tab) {

                    if (vm.currentTab) {
                        vm.currentTab.selected = false;
                    }

                    vm.currentTab = tab;
                    vm.currentTab.selected = true;
                    vm.currentTabIndex = vm.currentTab.name;

                    switch (vm.currentTab) {
                        case vm.tabs.alerts:
                        case vm.tabs.notifications:
                            vm.buttons = [
                                {
                                    primary: true,
                                    cancel: false,
                                    value: 'common.refresh',
                                    name: 'refreshButton',
                                    click: 'refresh',
                                    type: enums.buttonTypes.button
                                },
                                {
                                    title: 'alerts.notificationSettings',
                                    click: 'openSettings',
                                    name: 'notificationSettingsButton',
                                    type: enums.buttonTypes.action
                                },
                                {
                                    title: 'alerts.dismissAllNotifications',
                                    click: 'dismiss',
                                    name: 'dismissNotificationsButton',
                                    type: enums.buttonTypes.action
                                },
                                {
                                    title: 'common.exportToExcel',
                                    click: 'export',
                                    name: 'exportButton',
                                    type: enums.buttonTypes.action
                                }
                            ];
                            break;
                        case vm.tabs.faults:
                            vm.buttons = [
                                {
                                    primary: true,
                                    cancel: false,
                                    value: 'common.refresh',
                                    name: 'refreshButton',
                                    click: 'refresh',
                                    type: enums.buttonTypes.button
                                }
                            ];
                            break;
                        default:
                            vm.buttons = [];
                    }

                    checkButtons();



                };

                $scope.showDetails = function (error) {
                    // just a rough and ready display for dev.
                    alert("Error Message Details:\n" + error.message + "\n\n" + error.exception.stack);
                };

                function closeWindow() {
                    WindowFactory.closeWindow(vm.wnd);
                }

                vm.onClose = function () {
                    // poll alerts/notifications
                    ocAlertAndNotificationSvc.runNow();

                    closeWindow();
                };

                // mobile faults tab 
                vm.isMobile = browserSvc.isMobile;

                if (vm.isMobile) {
                    vm.localExceptionList = [];
                    errorReporter.getErrors().then(function (list) {
                        vm.localExceptionList = list;
                    });
                }

                function checkButtons() {
                    vm.buttonStates.notificationSettingsButton.visible = !!vm.currentTab.openSettings;
                    vm.buttonStates.dismissNotificationsButton.visible = vm.currentTab === vm.tabs.notifications && !!vm.notificationGrid.getTotalItemsCount && vm.notificationGrid.getTotalItemsCount() > 0;
                    vm.buttonStates.exportButton.visible = !!vm.currentTab.export && !!vm.currentTab.grid.getTotalItemsCount && vm.currentTab.grid.getTotalItemsCount() > 0;
                }

            }
        ]
    });
