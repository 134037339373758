//import angular from 'angular';
import * as _ from 'underscore';
import tmpl from './details.html';
import HelperSvc from '../../../../services/helperSvc';


const enum ComponentSpecificationDetail_Tab {
    details = 'details',
    prices = 'prices'
}

class ComponentSpecificationDetailCtrl implements ng.IController, IWindowController {

    wnd: IWindowObj;
    form: ng.IFormController;

    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: () => void };

    initParams: any;

    status: ScreenStatus = ScreenStatus.loading;

    siteSet = false;
    canModifySystemLevel = false;
    canViewSystemLevel = false;
    readonly: boolean;

    client: any;
    site: any;

    priceGridControl: IGridControl = {};
    siteControl: any = {};

    defaults: any = {};
    data: any;

    depthUnits: any[];
    weightUnits: any[];

    activeTab: ComponentSpecificationDetail_Tab;

    originalSpecificationName: string;
    dynamicDescription: string;
    currentPriceDisplay: string;

    priceCriteria = {
        filterValues: {
            systemHierarchyId: null,
            equipmentTypeSpecificationTypeId: null
        }
    };

    static $inject = ['$scope', 'errorReporter', 'WindowFactory', '$timeout', 'ocSecuritySvc', 'confirmSvc', 'ocConfigSvc', 'amtCommandQuerySvc', 'adminUrls',
        'referenceDataUrls', 'helperSvc', 'notifySvc', 'amtXlatSvc', 'amtConstants', 'componentUrls'];

    constructor(private $scope: ng.IScope, private errorReporter: IErrorReporter, private WindowFactory: IWindowFactory, private $timeout: ng.ITimeoutService,
        private ocSecuritySvc: IOcSecuritySvc, private confirmSvc: IConfirmSvc, private ocConfigSvc: IOcConfigSvc, private amtCommandQuerySvc: IAmtCommandQuerySvc,
        private adminUrls: IAdminUrls, private referenceDataUrls: IReferenceDataUrls, private helperSvc: HelperSvc, private notifySvc: INotifySvc, private amtXlatSvc: IAmtXlatSvc,
        private amtConstants: IAmtConstants, private componentUrls: IComponentUrls) {

        this.$scope.$watchGroup([() => this.form.$invalid, () => this.form.$pristine, () => this.wnd.processing, () => this.activeTab], () => {
            this.evaluateButtons();
        });

        this.$scope.$watch(() => this.form.$dirty, () => {
            this.wnd.isDirty = this.form.$dirty;
        });

        this.$scope.$watch(() => this.data, () => {
            this.updateDynamicDescription();
        }, true);

        this.$scope.$watch(() => this.site, (newValue, oldValue) => {
            if ((!oldValue || !oldValue.key) && !!newValue && newValue.key) {
                // let us know that the site picker has loaded and vm.site has been set
                this.siteSet = true;
            }
        });

        this.$scope['editPrice'] = (item) => this.editPrice(item);
        this.$scope['deletePrice'] = (item) => this.deletePrice(item);
    }

    async $onInit() {

        this.wnd.processing = true;
        this.wnd.onClose = () => this.cancel();

        try {
            this.data = this.initParams || {};

            this.WindowFactory.addButton(this, !this.data.id ? 'common.create_label' : 'common.save_label', 'saveButton', () => this.save(), true, false, true);
            this.WindowFactory.addButton(this, 'common.edit_label', 'editButton', () => this.edit(), false, false, false);
            this.WindowFactory.addButton(this, 'common.delete_label', 'deleteButton', () => this.delete(), false, false, true);
            this.WindowFactory.addButton(this, 'common.add_label', 'addPriceButton', () => this.addPrice(), false, false, true);

            this.checkSystemAccess();

            await this.getUnitsOfMeasure();

            if (this.data.id) {

                await this.loadDetails(this.ocConfigSvc.user.site.id);

                this.priceCriteria.filterValues.equipmentTypeSpecificationTypeId = this.data.id;

                this.defaults.site = this.ocConfigSvc.user.site.id;
                this.defaults.client = this.ocConfigSvc.user.client.id;

                this.$timeout(() => {

                    this.refreshPrices(this.ocConfigSvc.user.site.id);

                    this.status = ScreenStatus.ready;

                    this.setSecurity(true);
                    this.evaluateButtons();
                });

            } else {

                this.setDefaultUnits();

                this.status = ScreenStatus.ready;

                this.$timeout(() => {
                    this.setSecurity();
                    this.evaluateButtons();
                });
            }
        } finally {
            this.wnd.processing = false;
        }
    }

    setDefaultUnits() {
        let defaultDepth = this.depthUnits.find(u => u.unitAbbreviation === UnitAbbreviation.millimetre);
        let defaultWeight = this.weightUnits.find(u => u.unitAbbreviation === UnitAbbreviation.tonne);

        this.data.originalTreadDepthUnitOfMeasureId = defaultDepth.id;
        this.data.originalChainDepthUnitOfMeasureId = defaultDepth.id;
        this.data.weightUnitOfMeasureId = defaultWeight.id;
    }

    checkSystemAccess() {
        this.canModifySystemLevel = this.ocSecuritySvc.isAuthorised(this.data.id ? 'Security.Settings.SpecificationsComponents' :
            'Security.Settings.SpecificationsComponents.Add', AccessTypes.readWrite, null, null, true);
        this.canViewSystemLevel = this.ocSecuritySvc.isAuthorised('Security.Settings.SpecificationsComponents', AccessTypes.readOnly, null, null, true);
    }

    async getUnitsOfMeasure() {

        try {
            let response = await this.amtCommandQuerySvc.post(this.referenceDataUrls.getUnitsOfMeasure, null);

            if (response && response.result) {
                this.depthUnits = response.result.filter(u => u.baseReadingUnit === BaseReadingUnit.depth);
                this.weightUnits = response.result.filter(u => u.baseReadingUnit === BaseReadingUnit.weight);
            }
        } catch (error) {
            this.status = ScreenStatus.error;
            this.errorReporter.logError(error);
        }
    }

    async loadDetails(systemHierarchyId: guid) {

        let defaultHierarchyId = this.site ? this.helperSvc.getKey(this.site) : (this.client ? this.helperSvc.getKey(this.client) : null);

        let criteria = {
            id: this.data.id,
            equipmentType: this.data.equipmentType,
            systemHierarchyId: systemHierarchyId || defaultHierarchyId
        };

        try {
            let response = await this.amtCommandQuerySvc.post(this.adminUrls.getSpecification, criteria);

            if (response) {

                this.data = response;
                this.data.id = response.equipmentTypeSpecificationTypeId;

                this.originalSpecificationName = response.description;
                this.dynamicDescription = response.description;
            }

        } catch (error) {
            this.status = ScreenStatus.ready;
            this.errorReporter.logError(error);
        }
    }

    async delete() {

        if (this.data && !this.client) {

            try {
                await this.confirmSvc.confirmMessage('specifications.confirmDelete_title', 'specifications.confirmDelete', this.originalSpecificationName);
            } catch (error) {
                return; // they cancelled
            }

            let criteria = {
                equipmentTypeSpecificationTypeId: this.data.id
            };

            this.wnd.processing = true;

            try {
                await this.amtCommandQuerySvc.post(this.adminUrls.deleteComponentSpecification, criteria);

                this.notifySvc.success(this.amtXlatSvc.xlat('specifications.componentSpecificationDeletedSuccessfully'));

                if (this.wnd.onDataChanged)
                    this.wnd.onDataChanged();

                this.closeWindow();

            } catch (error) {
                this.errorReporter.logError(error);
            } finally {
                this.wnd.processing = false;
            }
        }
    }


    async save() {

        if (this.data) {

            let criteria = {
                equipmentTypeSpecificationTypeId: this.data.id,
                manufacturerId: this.helperSvc.getKey(this.data.manufacturer),
                equipmentTypeId: this.helperSvc.getKey(this.data.componentType),
                name: this.dynamicDescription,
                tyreTypeDetails: {},
                rimTypeDetails: {},
                chainTypeDetails: {},
                systemHierarchyId: !!this.site ? this.helperSvc.getKey(this.site) : (!!this.client ? this.helperSvc.getKey(this.client) : null),
                obsolete: this.data.obsolete
            };

            let componentTypeName = this.data.componentType.name.toLowerCase();

            switch (componentTypeName) {

                case EquipmentTypeName.tyre:

                    criteria.tyreTypeDetails = {
                        equipmentSizeId: this.helperSvc.getKey(this.data.equipmentSize),
                        treadPatternId: this.helperSvc.getKey(this.data.treadPattern),
                        treadCompoundId: this.helperSvc.getKey(this.data.treadCompound),
                        plyRatingId: this.helperSvc.getKey(this.data.plyRating),
                        equipmentConstructionId: this.helperSvc.getKey(this.data.equipmentConstruction),
                        traCodeId: this.helperSvc.getKey(this.data.traCode),
                        originalTreadDepth: this.data.originalTreadDepth,
                        originalTreadDepthUnitOfMeasureId: this.helperSvc.getKey(this.data.otdUnit),
                        tkph: this.data.tkph,
                        loadIndex: this.data.loadIndex,
                        speedIndex: this.data.speedIndex,
                        tubeless: this.data.tubeless,
                        weight: this.data.weight,
                        weightUnitOfMeasureId: this.helperSvc.getKey(this.data.weightUnit)
                    };

                    break;

                case EquipmentTypeName.rim:

                    criteria.rimTypeDetails = {
                        equipmentSizeId: this.helperSvc.getKey(this.data.equipmentSize),
                        equipmentConstructionId: this.helperSvc.getKey(this.data.equipmentConstruction),
                        typeDescription: this.data.typeDescription,
                        doubleGutterRim: this.data.doubleGutterRim
                    };

                    break;

                case EquipmentTypeName.chain:

                    criteria.chainTypeDetails = {
                        equipmentSizeId: this.helperSvc.getKey(this.data.equipmentSize),
                        typeDescription: this.data.typeDescription,
                        originalChainDepth: this.data.originalChainDepth,
                        originalChainDepthUnitOfMeasureId: this.helperSvc.getKey(this.data.ocdUnit)
                    };

                    break;
            }

            this.wnd.processing = true;

            try {
                let response = await this.amtCommandQuerySvc.post(this.data.id ? this.adminUrls.modifyComponentSpecification : this.adminUrls.addComponentSpecification, criteria);

                this.notifySvc.success(this.amtXlatSvc.xlat('specifications.componentSpecificationSavedSuccessfully'));

                if (this.wnd.onDataChanged)
                    this.wnd.onDataChanged();

                // just saved new
                if (!this.data.id) {

                    this.data.id = response.data;
                    this.data.equipmentsUsingSpecification = 0;

                    // set window caption to spec name
                    this.wnd.caption = this.dynamicDescription;

                    // change 'Create' button to 'Save' button                                        
                    let saveButton = this.buttons.find(b => b.name === 'saveButton');

                    if (saveButton)
                        saveButton.value = 'common.save_label';
                }

                this.form.$setPristine();

                this.$timeout(() => {
                    this.setSecurity();
                    this.evaluateButtons();
                });

            } catch (error) {
                this.errorReporter.logError(error);
            } finally {
                this.wnd.processing = false;
            }
        }
    }

    onGetSpecificationPrices(response) {

        let currentDate = new Date();

        let prices = response.result;

        // clear current price
        this.currentPriceDisplay = null;

        if (prices && prices.length) {

            let site = this.siteSet ? (!!this.site ? this.helperSvc.getKey(this.site) : null) : this.defaults.site;
            let client = this.siteSet ? (!!this.client ? this.helperSvc.getKey(this.client) : null) : this.defaults.client;

            let modifySite = this.ocSecuritySvc.isAuthorised('Security.Settings.SpecificationsComponents', AccessTypes.readWrite, client, site, true);
            let modifyClient = this.ocSecuritySvc.isAuthorised('Security.Settings.SpecificationsComponents', AccessTypes.readWrite, client, null, true);

            for (let price of prices || []) {
                price.currencyDisplay = String.format('{0} ({1})', price.currencyType.description, price.currencyType.symbol);

                price.levelDisplay = this.amtXlatSvc.xlat(
                    (price.level === HierarchyLevel.site ? 'common.site_label' : (price.level === HierarchyLevel.client ? 'common.client_label' : 'common.system_label'))
                );

                price.canModifyPrice = (price.level === HierarchyLevel.site && modifySite) ||
                    (price.level === HierarchyLevel.client && modifyClient) ||
                    (price.level === HierarchyLevel.system && this.canModifySystemLevel);
            }

            // determine and display the current price of the specification
            let activePrices = prices.filter(p => p.dateFrom <= currentDate && (p.dateTo == null || p.dateTo > currentDate));

            if (activePrices && activePrices.length) {
                let currentPrice = _.sortBy(activePrices, p => p.level === HierarchyLevel.site ? 0 : (p.level === HierarchyLevel.client ? 1 : 2))[0];

                if (currentPrice)
                    this.currentPriceDisplay = String.format('{0}{1}', currentPrice.currencyType.symbol, currentPrice.amount.toFixed(currentPrice.currencyType.decimalPlaces));
            }
        }

        return response;
    }

    edit() {
        this.onClientChange(null);
        this.client = null;
    }

    useDefaultWeight() {
        if (this.data.equipmentSize && this.data.equipmentSize.weight) {
            this.data.weight = this.data.equipmentSize.weight;
            this.data.weightUnit = this.weightUnits.find(u => u.id === this.data.equipmentSize.weightUnitOfMeasureId);
            this.form.$setDirty();
        }
    }

    onComponentTypeChange(option) {
        if (!this.data.id && this.data.componentType && option) {
            this.data = {
                componentType: option
            };

            this.setDefaultUnits();
        }
    }

    refreshPrices(systemHierarchyId?: guid) {
        if (this.priceGridControl && this.priceGridControl.refresh) {

            this.priceCriteria.filterValues.equipmentTypeSpecificationTypeId = this.data.id;
            this.priceCriteria.filterValues.systemHierarchyId = systemHierarchyId ? systemHierarchyId : (!!this.site && this.site.key ? this.site.key : this.helperSvc.getKey(this.client));
            this.priceGridControl.refresh();
        }
    }

    updateDynamicDescription() {

        if (this.fieldHasValue(this.data.componentType) && this.status === ScreenStatus.ready && !this.form.$pristine) {
            
            switch (this.data.componentType.name.toLowerCase()) {

                case EquipmentTypeName.tyre:

                    let tyreSize = this.fieldHasValue(this.data.equipmentSize) ?
                        this.fieldHasValue(this.data.equipmentConstruction) ?
                            this.data.equipmentSize.description.replace('x', (this.data.equipmentConstruction.name === 'Radial' ? 'R' : '-')) : this.data.equipmentSize.description : '';

                    let tyreManufacturer = this.fieldHasValue(this.data.manufacturer) ? this.data.manufacturer.description : '';
                    let tyreTreadPattern = this.fieldHasValue(this.data.treadPattern) ? this.data.treadPattern.description : '';
                    let tyreTreadCompound = this.fieldHasValue(this.data.treadCompound) ?
                        (this.data.treadCompound.name.indexOf('(') > -1 ? this.data.treadCompound.description.substring(1, this.data.treadCompound.description.length - 2) : this.data.treadCompound.description)
                        : '';
                    let tyrePlyRating = this.fieldHasValue(this.data.plyRating) ? (this.data.plyRating.name.indexOf('*') > -1 ? this.data.plyRating.name : '(' + this.data.plyRating.name + ')') : '';
                    let tyreTRACode = this.fieldHasValue(this.data.traCode) ? this.data.traCode.name : '';

                    this.dynamicDescription = String.format('{0} {1} {2} {3} {4} {5}', tyreSize, tyreManufacturer, tyreTreadPattern, tyreTreadCompound, tyrePlyRating, tyreTRACode).replace('  ', ' ');

                    break;

                case EquipmentTypeName.rim:

                    let rimSize = this.fieldHasValue(this.data.equipmentSize) ? this.data.equipmentSize.description : '';
                    let rimConstruction = this.fieldHasValue(this.data.equipmentConstruction) ? this.data.equipmentConstruction.description : '';
                    let rimManufacturer = this.fieldHasValue(this.data.manufacturer) ? this.data.manufacturer.description : '';
                    let rimTypeDescription = this.data.typeDescription ? this.data.typeDescription : ''

                    this.dynamicDescription = String.format('{0} {1} {2} {3}', rimSize, rimConstruction, rimManufacturer, rimTypeDescription).replace('  ', ' ');

                    break;

                case EquipmentTypeName.chain:

                    let chainSize = this.fieldHasValue(this.data.equipmentSize) ? this.data.equipmentSize.description : '';
                    let chainManufacturer = this.fieldHasValue(this.data.manufacturer) ? this.data.manufacturer.description : '';
                    let chainTypeDescription = this.data.typeDescription ? this.data.typeDescription : '';

                    this.dynamicDescription = String.format('{0} {1} {2}', chainSize, chainManufacturer, chainTypeDescription).replace('  ', ' ');

                    break;
            }
        }
    }

    addPrice() {
        this.WindowFactory.openItem({
            component: 'add-edit-price',
            caption: this.amtXlatSvc.xlat('specifications.addPrice'),
            modal: true,
            initParams: {
                equipmentTypeSpecificationTypeId: this.data.id,
                systemHierarchyId: !!this.site ? this.helperSvc.getKey(this.site) : (!!this.client ? this.helperSvc.getKey(this.client) : this.ocConfigSvc.user.system.id),
                accessibleLevels: this.getAccessibleLevels()
            },
            width: 550,
            onDataChangeHandler: () => this.refreshPrices()
        });
    }

    editPrice(item) {
        this.WindowFactory.openItem({
            component: 'add-edit-price',
            caption: this.amtXlatSvc.xlat('specifications.editPrice'),
            modal: true,
            initParams: {
                equipmentTypeSpecificationTypeId: this.data.id,
                systemHierarchyId: !!this.site ? this.helperSvc.getKey(this.site) : (!!this.client ? this.helperSvc.getKey(this.client) : this.ocConfigSvc.user.system.id),
                accessibleLevels: this.getAccessibleLevels(),
                id: item.id,
                dateFrom: item.dateFrom,
                dateTo: item.dateTo,
                levelId: item.level === HierarchyLevel.site ? 2 : (item.level === HierarchyLevel.client ? 1 : 0),
                currencyTypeId: item.currencyType.id,
                price: item.amount
            },
            width: 550,
            onDataChangeHandler: () => this.refreshPrices()
        });
    }

    async deletePrice(item) {

        try {
            await this.confirmSvc.confirmMessage('specifications.confirmPriceDelete_title', 'specifications.confirmPriceDelete',
                item.levelDisplay, item.currencyType.symbol, item.amount,
                item.dateFrom.toString().toDateTimeFormat('dd-MM-yyyy'), (item.dateTo ? item.dateTo.toString().toDateTimeFormat('dd-MM-yyyy') : this.amtXlatSvc.xlat('common.now'))
            );
        } catch {
            return; // they cancelled
        }

        this.wnd.processing = true;

        try {
            await this.amtCommandQuerySvc.post(this.adminUrls.deleteGenericPrice, { id: item.id });

            this.notifySvc.success(this.amtXlatSvc.xlat('specifications.priceDeletedSuccessfully'));

            this.refreshPrices();

        } catch (error) {
            this.errorReporter.logError(error);
        } finally {
            this.wnd.processing = false;
        }
    }

    fieldHasValue(field) {
        return !!(field && field.key);
    }

    getAccessibleLevels() {
        var accessibleLevels = [];

        if (this.canModifySystemLevel) {

            accessibleLevels.push({
                key: 0,
                id: this.ocConfigSvc.user.system.id,
                name: this.amtXlatSvc.xlat('common.system_label')
            });
        }

        if (!!this.client && this.client.hasRoleAtClientOrAbove) {
            accessibleLevels.push({
                key: 1,
                id: this.helperSvc.getKey(this.client),
                name: this.amtXlatSvc.xlat('common.client_label')
            });
        }

        if (!!this.site) {
            accessibleLevels.push({
                key: 2,
                id: this.helperSvc.getKey(this.site),
                name: this.amtXlatSvc.xlat('common.site_label')
            });
        }

        return accessibleLevels;
    }

    evaluateButtons() {
        if (this.form) {
            this.buttonStates.saveButton.visible = this.activeTab === ComponentSpecificationDetail_Tab.details && !this.readonly;
            this.buttonStates.editButton.visible = this.activeTab === ComponentSpecificationDetail_Tab.details && !!this.data.id && !!this.client;
            this.buttonStates.deleteButton.visible = !!this.data.id && !this.readonly && !this.client && this.data.equipmentsUsingSpecification === 0;
            this.buttonStates.addPriceButton.visible = this.ocSecuritySvc.getCostAccessType((this.client ? this.client.key : null), (this.site ? this.site.key : null)) >= this.ocSecuritySvc.accessTypes.readWrite
                && this.activeTab === ComponentSpecificationDetail_Tab.prices && !this.readonly;

            this.buttonStates.saveButton.disabled = (!this.site && this.form.$invalid) || this.form.$pristine || this.wnd.processing || (!!this.client && !this.site);
            this.buttonStates.addPriceButton.disabled = this.wnd.processing;
            this.buttonStates.deleteButton.disabled = this.wnd.processing;
        }
    }

    async onClientChange(option) {

        let oldValue = angular.copy(this.client);
        let newValue = angular.copy(option);

        if ((!oldValue && newValue && newValue.key) || (oldValue && oldValue.key)) {

            try {
                await this.confirmSvc.confirmSaveChange(this.form.$dirty);
            } catch {
                this.client = oldValue;
                return; // they cancelled
            }

            if (this.site) {
                this.form.$setPristine();
                this.siteControl.clear(true);
            } else {

                this.$timeout(() => {
                    this.setSecurity();
                    this.evaluateButtons();
                    this.refreshPrices();
                    this.form.$setPristine();
                });
            }
        }
    }

    async onSiteChange(option) {

        let oldValue = angular.copy(this.site);
        let newValue = angular.copy(option);

        if ((!oldValue && newValue && newValue.key) || (oldValue && oldValue.key)) {

            try {
                await this.confirmSvc.confirmSaveChange(this.form.$dirty);
            } catch {
                this.site = oldValue;
                return; // they cancelled
            }

            let criteria = {
                siteId: this.helperSvc.getKey(this.site),
                equipmentTypeSpecificationTypeId: this.data.id
            };

            this.wnd.processing = true;

            try {
                let response = await this.amtCommandQuerySvc.post(this.adminUrls.specificationObsoleteAtSite, criteria);

                this.data.obsolete = response === true;
            } catch (error) {
                this.errorReporter.logError(error);
            } finally {
                this.wnd.processing = false;
            }

            this.$timeout(() => {
                this.setSecurity();
                this.evaluateButtons();
                this.refreshPrices();
                this.form.$setPristine();
            });
        }
    }

    setSecurity(useDefaultHierarchy?: boolean) {

        this.$timeout(() => {
            this.readonly = !!this.data.id && !this.ocSecuritySvc.isAuthorised('Security.Settings.SpecificationsComponents', AccessTypes.readWrite,
                this.helperSvc.getKey(this.client), this.helperSvc.getKey(this.site), !useDefaultHierarchy);
        });
    }

    closeWindow() {
        this.WindowFactory.closeWindow(this.wnd);
    }

    async cancel() {

        try {
            await this.confirmSvc.confirmSaveChange(this.form.$dirty);
        } catch {
            return; // they cancelled
        }

        this.form.dirty = false;
        this.closeWindow();
    }
}

class ComponentSpecificationDetailComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = ComponentSpecificationDetailCtrl;
    public controllerAs = 'vm';
}

angular.module('app.admin').component('componentSpecificationDetails', new ComponentSpecificationDetailComponent());