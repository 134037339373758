//import angular from 'angular';
import tmplError from './error.html';
import tmplDataSync from './dataSync.html';
import tmplHeaderMobile from '../templates/headerMobile.html';
import * as OfflinePluginRuntime from '@lcdp/offline-plugin/runtime';

const isMobile = document.documentElement.classList.contains('mobile'); //TODO: quick hack, not sure why but can't seem to access services from app module in app.mobile's config

// this a applies a default routing specifically for mobile
    angular.module('app.mobile')
        .config(['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {

            if (!isMobile)
                return;

            $stateProvider
                .state('mobile.error', {
                    url: '/error',                    
                    views: {
                        controller: 'errorCtrl',
                        controllerAs: 'vm',
                        "content": {
                            template: tmplError
                        }
                    }
                });

            $stateProvider
                .state('mobile.dataSync', {
                    url: '/dataSync',                    
                    views: {
                        controller: 'dataSyncCtrl',
                        controllerAs: 'vm',
                        "nav-bar": { template: tmplHeaderMobile },
                        "content": {
                            template: tmplDataSync
                        }
                    }
                });

            // default to sync for now
            $stateProvider
                .state('mobile.landing', {
                    url: '/landing',                    
                    views: {
                        controller: 'dataSyncCtrl',
                        controllerAs: 'vm',
                       "nav-bar" : { template : tmplHeaderMobile },
                       "content": { template: tmplDataSync }
                    }
                });

            $urlRouterProvider.when('/mobile', ['$state', function ($state) {
                $state.go('mobile.landing');
            }]);

            $urlRouterProvider.when('/mobile-vehicle-readings', ['$state', function ($state) {
                $state.go('mobile.fieldSurvey');
            }]);

            $urlRouterProvider.when('/mobile-tyre-readings', ['$state', function ($state) {
                $state.go('mobile.fieldSurvey');
            }]);

            $urlRouterProvider.when('/mobile-fitted-components', ['$state', function ($state) {
                $state.go('mobile.fieldSurvey');
            }]);

            $urlRouterProvider.when('/mobile-synchronise-data', ['$state', function ($state) {
                $state.go('mobile.synchroniseData');
            }]);


            $urlRouterProvider.when('/', ['$state', function ($state) {
                $state.go('mobile.landing');
            }]);

            $urlRouterProvider.when('', ['$state', function ($state) {
                $state.go('mobile.landing');
            }]);

            $urlRouterProvider.otherwise('/mobile/error');
        }]);

//TODO: this doesn't belong here
angular.module('app.mobile').run(['$timeout', '$window',
    function ($timeout: ng.ITimeoutService, $window: ng.IWindowService) {
        if (!isMobile) {
            window.cacheStatus = "none";
            return;
        }
            
        window.cacheStatus = "noupdate";
        OfflinePluginRuntime.install({
            onUpdating: () => {
                window.cacheStatus = "progress";
                console.log("CacheStatus:Updates downloading");
                $timeout();
            },
            onUpdateReady: () => {
                window.cacheStatus = "updateready";
                console.log("CacheStatus:New cache available");
                OfflinePluginRuntime.applyUpdate();
                $timeout();
            },
            onUpdateFailed: () => {
                window.cacheStatus = "error";
                console.log("CacheStatus:An error occurred");
                $timeout();
            },
            onUpdated: () => {
                //TODO:  we now reload and get the new files! but the user might have started working by now and lost stuff... do this... differently
                $window.location.href = baseUrl + 'mobile';
            }
        });
    }]);