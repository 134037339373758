//import angular from 'angular';

const moduleName = 'app.fieldSurveys';
export default moduleName;
let module = angular.module(moduleName, []);

module.config(['$stateProvider', function ($stateProvider) {

    //var notImplementedTemplate = '<br><div class="container"><div ng-click="$state.go(\'mobile.landing\')" ><span class="glyphicon glyphicon-home fa-2x"></span></div><h3>{{"mobileCommon.NotYetImplemented" | xlat}}<h3></div>';



    $stateProvider
        .state('mobile.fieldSurvey', {
            url: '/field-surveys/:vehicleId',
            params: { vehicleId: '' },
            resolve: { vehicleId: ['$transition$', $transition$ => $transition$.params().vehicleId] },
            views: {
                'content': {
                    component: 'fieldSurveys'
                }
            }
        })
        .state('mobile.receiveComponent', {
            url: '/receivecomponent',
            params: { initParams: null },
            resolve: { initParams: ['$transition$', $transition$ => $transition$.params().initParams] },
            views: {
                'content': {
                    component: 'componentReceive'
                }
            }
        })
        .state('mobile.receiveVehicle', {
            url: '/receivevehicle',
            params: { initParams: null },
            resolve: { initParams: ['$transition$', $transition$ => $transition$.params().initParams] },
            views: {
                'content': {
                    component: 'vehicleReceive'
                }
            }
        })
        .state('mobile.maintenanceSession', {
            url: '/maintenance/:vehicle/:tab',
            params: { vehicle: null, tab: '' },
            resolve: {
                initParams: ['$transition$', $transition$ => ({ vehicleId: $transition$.params().vehicle, tab: $transition$.params().tab })]
            },
            views: {
                'content': {
                    component: 'maintenance'
                }
            }
        })
        .state('mobile.statusChange', {
            url: '/statuschange',
            params: { initParams: null },
            resolve: { initParams: ['$transition$', $transition$ => $transition$.params().initParams] },
            views: {
                'content': {
                    component: 'statusChange'
                }
            }
        });
    }
]);
