/** 
 * Kendo UI v2017.2.621 (http://www.telerik.com/kendo-ui)                                                                                                                                               
 * Copyright 2017 Telerik AD. All rights reserved.                                                                                                                                                      
 *                                                                                                                                                                                                      
 * Kendo UI commercial licenses may be obtained at                                                                                                                                                      
 * http://www.telerik.com/purchase/license-agreement/kendo-ui-complete                                                                                                                                  
 * If you do not own a commercial license, this file shall be governed by the trial license terms.                                                                                                      
*/
import kendo from '@progress/kendo-ui/js/kendo.core';

kendo.cultures.es = {
    name: "es",
    numberFormat: {
        pattern: ["-n"],
        decimals: 2,
        ",": ".",
        ".": ",",
        groupSize: [3],
        percent: {
            pattern: ["-n %", "n %"],
            decimals: 2,
            ",": ".",
            ".": ",",
            groupSize: [3],
            symbol: "%"
        },
        currency: {
            name: "",
            abbr: "",
            pattern: ["-n $", "n $"],
            decimals: 2,
            ",": ".",
            ".": ",",
            groupSize: [3],
            symbol: "€"
        }
    },
    calendars: {
        standard: {
            days: {
                names: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
                namesAbbr: ["do.", "lu.", "ma.", "mi.", "ju.", "vi.", "sá."],
                namesShort: ["D", "L", "M", "X", "J", "V", "S"]
            },
            months: {
                names: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
                namesAbbr: ["ene.", "feb.", "mar.", "abr.", "may.", "jun.", "jul.", "ago.", "sep.", "oct.", "nov.", "dic."]
            },
            AM: [""],
            PM: [""],
            patterns: {
                d: "dd-MM-yyyy",
                D: "dddd, d' de 'MMMM' de 'yyyy",
                F: "dddd, d' de 'MMMM' de 'yyyy H:mm:ss",
                g: "dd-MM-yyyy H:mm",
                G: "dd-MM-yyyy H:mm:ss",
                m: "d' de 'MMMM",
                M: "d' de 'MMMM",
                s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
                t: "H:mm",
                T: "H:mm:ss",
                u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'",
                y: "MMMM' de 'yyyy",
                Y: "MMMM' de 'yyyy"
            },
            "/": "/",
            ":": ":",
            firstDay: 1
        }
    }
}