/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var animations_1 = require("@angular/animations");
var dom_queries_1 = require("./../common/dom-queries");
var core_1 = require("@angular/core");
var expansionpanel_title_directive_1 = require("./expansionpanel-title.directive");
var animations_2 = require("./animations");
var util_1 = require("../common/util");
var rxjs_1 = require("rxjs");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var kendo_licensing_1 = require("@progress/kendo-licensing");
var package_metadata_1 = require("../package-metadata");
var action_event_1 = require("./events/action-event");
var operators_1 = require("rxjs/operators");
var DEFAULT_DURATION = 200;
var CONTENT_HIDDEN_CLASS = 'k-hidden';
/**
 * Represents the [Kendo UI ExpansionPanel component for Angular]({% slug overview_expansionpanel %}).
 *
 * @example
 * ```ts-preview
 * _@Component({
 *    selector: 'my-app',
 *    template: `
 *        <kendo-expansionpanel title="Chile" subtitle="South America">
 *              There are various theories about the origin of the word Chile.
 *        </kendo-expansionpanel>
 *    `
 * })
 * class AppComponent {}
 * ```
 */
var ExpansionPanelComponent = /** @class */ (function () {
    function ExpansionPanelComponent(renderer, hostElement, ngZone, localizationService, builder) {
        this.renderer = renderer;
        this.hostElement = hostElement;
        this.ngZone = ngZone;
        this.localizationService = localizationService;
        this.builder = builder;
        /**
         * Specifies the primary text in the header of the ExpansionPanel
         * ([see example]({% slug title_expansionpanel %}#toc-title-and-subtitle)).
         */
        this.title = '';
        /**
         * Specifies the secondary text in the header of the ExpansionPanel, which is rendered next to the collapse/expand icon
         * ([see example]({% slug title_expansionpanel %}#toc-title-and-subtitle)).
         */
        this.subtitle = '';
        /**
         * Specifies whether the ExpansionPanel is disabled. If disabled, the ExpansionPanel can be neither expanded nor collapsed
         * ([see example]({% slug disabled_expansionpanel %})).
         *
         * @default false
         */
        this.disabled = false;
        /**
         * Specifies the animation settings of the ExpansionPanel
         * ([see example]({% slug animations_expansionpanel %})).
         *
         * The possible values are:
         * * Boolean
         *    * (Default) `true` Numeric values represent duration. Default duration is 200ms.
         *    * false
         * * Number
         */
        this.animation = true;
        /**
         * Fires when the `expanded` property of the component is updated.
         * Used to provide a two-way binding for the `expanded` property
         * ([see example]({% slug overview_expansionpanel %}#toc-events)).
         */
        this.expandedChange = new core_1.EventEmitter();
        /**
         * Fires when the expanded state of the ExpansionPanel is about to change. This event is preventable
         * ([see example]({% slug overview_expansionpanel %}#toc-events)).
         */
        this.action = new core_1.EventEmitter();
        /**
         * Fires when the ExpansionPanel is expanded. If there is animation it will fire when the animation is complete
         * ([see example]({% slug overview_expansionpanel %}#toc-events)).
         */
        this.expand = new core_1.EventEmitter();
        /**
         * Fires when the ExpansionPanel is collapsed. If there is animation it will fire when the animation is complete
         * ([see example]({% slug overview_expansionpanel %}#toc-events)).
         */
        this.collapse = new core_1.EventEmitter();
        this.hostClass = true;
        this.tabindex = 0;
        /**
         * @hidden
         */
        this.focused = false;
        this.animationEnd = new core_1.EventEmitter();
        this.subscriptions = new rxjs_1.Subscription();
        this._expanded = false;
        kendo_licensing_1.validatePackage(package_metadata_1.packageMetadata);
        this.direction = localizationService.rtl ? 'rtl' : 'ltr';
    }
    Object.defineProperty(ExpansionPanelComponent.prototype, "expanded", {
        get: function () {
            return this._expanded;
        },
        /**
         * Specifies whether the ExpansionPanel is expanded. The property supports two-way binding.
         * ([see example]({% slug interaction_expansionpanel %}#toc-setting-the-initial-state)).
         *
         * @default false
         */
        set: function (value) {
            if (value === this.expanded) {
                return;
            }
            this._expanded = value;
            if (this.expanded) {
                this.removeContentHiddenClass();
            }
            else {
                this.addContentHiddenClass();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExpansionPanelComponent.prototype, "expandedClass", {
        get: function () {
            return this.expanded && !this.disabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExpansionPanelComponent.prototype, "focusClass", {
        get: function () {
            return this.focused;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExpansionPanelComponent.prototype, "disabledClass", {
        get: function () {
            return this.disabled;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @hidden
     */
    ExpansionPanelComponent.prototype.onComponentBlur = function () {
        if (this.focused) {
            this.focused = false;
        }
    };
    /**
     * @hidden
     */
    ExpansionPanelComponent.prototype.onComponentFocus = function () {
        if (!this.focused) {
            this.focused = true;
        }
    };
    ExpansionPanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.renderer.removeAttribute(this.hostElement.nativeElement, 'title');
        this.subscriptions = this.localizationService.changes.subscribe(function (_a) {
            var rtl = _a.rtl;
            _this.direction = rtl ? 'rtl' : 'ltr';
        });
    };
    ExpansionPanelComponent.prototype.ngAfterViewInit = function () {
        this.initDomEvents();
        if (!this.expanded) {
            this.renderer.addClass(this.content.nativeElement, CONTENT_HIDDEN_CLASS);
        }
    };
    ExpansionPanelComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    /**
     * @hidden
     */
    ExpansionPanelComponent.prototype.initDomEvents = function () {
        var _this = this;
        if (!this.hostElement) {
            return;
        }
        if (!this.disabled) {
            this.ngZone.runOutsideAngular(function () {
                var nativeElement = _this.hostElement.nativeElement;
                _this.subscriptions.add(_this.renderer.listen(nativeElement, 'keydown', _this.keyDownHandler.bind(_this)));
            });
        }
    };
    /**
     * @hidden
     */
    ExpansionPanelComponent.prototype.keyDownHandler = function (ev) {
        var _this = this;
        var isEnterOrSpace = ev.keyCode === kendo_angular_common_1.Keys.Enter || ev.keyCode === kendo_angular_common_1.Keys.Space;
        if (!isEnterOrSpace) {
            return;
        }
        if (dom_queries_1.hasClass(ev.target, 'k-expander')) {
            ev.preventDefault();
            this.ngZone.run(function () {
                _this.onHeaderAction();
            });
        }
    };
    /**
     * @hidden
     */
    ExpansionPanelComponent.prototype.onHeaderClick = function (ev) {
        if (!dom_queries_1.isFocusable(ev.target) && !this.disabled) {
            this.onHeaderAction();
        }
    };
    /**
     * @hidden
     */
    ExpansionPanelComponent.prototype.onHeaderAction = function () {
        this.focused = true;
        var eventArgs = new action_event_1.ExpansionPanelActionEvent();
        eventArgs.action = this.expanded ? 'collapse' : 'expand';
        this.action.emit(eventArgs);
        if (!eventArgs.isDefaultPrevented()) {
            this.setExpanded(!this.expanded);
            if (this.expanded) {
                this.removeContentHiddenClass();
            }
            if (this.animation) {
                this.animateContent();
                return;
            }
            if (!this.expanded) {
                this.addContentHiddenClass();
            }
            this.emitExpandCollapseEvent();
        }
    };
    Object.defineProperty(ExpansionPanelComponent.prototype, "indicatorClasses", {
        /**
         * @hidden
         */
        get: function () {
            if (this.expanded) {
                return this.collapseIcon ? this.collapseIcon : "k-icon k-i-arrow-chevron-up";
            }
            else {
                return this.expandIcon ? this.expandIcon : "k-icon k-i-arrow-chevron-down";
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Toggles the visibility of the ExpansionPanel
     * ([see example]({% slug interaction_expansionpanel %}#toc-toggling-the-content)).
     *
     * @param expanded? - Boolean. Specifies, whether the ExpansionPanel will be expanded or collapsed.
     */
    ExpansionPanelComponent.prototype.toggle = function (expanded) {
        var previous = this.expanded;
        var current = util_1.isPresent(expanded) ? expanded : !previous;
        if (current === previous) {
            return;
        }
        this.setExpanded(current);
        if (this.expanded) {
            this.removeContentHiddenClass();
        }
        if (this.animation) {
            this.animateContent();
            return;
        }
        if (!this.expanded) {
            this.addContentHiddenClass();
        }
        this.emitExpandCollapseEvent();
    };
    ExpansionPanelComponent.prototype.setExpanded = function (value) {
        this._expanded = value;
        this.expandedChange.emit(value);
    };
    ExpansionPanelComponent.prototype.animateContent = function () {
        var _this = this;
        var duration = typeof this.animation === 'boolean' ? DEFAULT_DURATION : this.animation;
        var contentHeight = getComputedStyle(this.content.nativeElement).height;
        var animation = this.expanded ? animations_2.expand(duration, contentHeight) : animations_2.collapse(duration, contentHeight);
        var player = this.createPlayer(animation, this.content.nativeElement);
        this.animationEnd.pipe(operators_1.take(1)).subscribe(function () {
            if (!_this.expanded) {
                _this.addContentHiddenClass();
            }
            _this.emitExpandCollapseEvent();
        });
        player.play();
    };
    ExpansionPanelComponent.prototype.createPlayer = function (animation, animatedElement) {
        var _this = this;
        var factory = this.builder.build(animation);
        var player = factory.create(animatedElement);
        player.onDone(function () {
            if (player) {
                _this.animationEnd.emit();
                player.destroy();
                player = null;
            }
        });
        return player;
    };
    ExpansionPanelComponent.prototype.emitExpandCollapseEvent = function () {
        this[this.expanded ? 'expand' : 'collapse'].emit();
    };
    ExpansionPanelComponent.prototype.addContentHiddenClass = function () {
        this.renderer.addClass(this.content.nativeElement, CONTENT_HIDDEN_CLASS);
    };
    ExpansionPanelComponent.prototype.removeContentHiddenClass = function () {
        this.renderer.removeClass(this.content.nativeElement, CONTENT_HIDDEN_CLASS);
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ExpansionPanelComponent.prototype, "title", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ExpansionPanelComponent.prototype, "subtitle", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], ExpansionPanelComponent.prototype, "disabled", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [Boolean])
    ], ExpansionPanelComponent.prototype, "expanded", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ExpansionPanelComponent.prototype, "expandIcon", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ExpansionPanelComponent.prototype, "collapseIcon", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], ExpansionPanelComponent.prototype, "animation", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ExpansionPanelComponent.prototype, "expandedChange", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ExpansionPanelComponent.prototype, "action", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ExpansionPanelComponent.prototype, "expand", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ExpansionPanelComponent.prototype, "collapse", void 0);
    tslib_1.__decorate([
        core_1.ContentChild(expansionpanel_title_directive_1.ExpansionPanelTitleDirective, { static: false }),
        tslib_1.__metadata("design:type", expansionpanel_title_directive_1.ExpansionPanelTitleDirective)
    ], ExpansionPanelComponent.prototype, "titleTemplate", void 0);
    tslib_1.__decorate([
        core_1.ViewChild('content', { static: true }),
        tslib_1.__metadata("design:type", core_1.ElementRef)
    ], ExpansionPanelComponent.prototype, "content", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-expander'),
        tslib_1.__metadata("design:type", Boolean)
    ], ExpansionPanelComponent.prototype, "hostClass", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('attr.aria-expanded'),
        core_1.HostBinding('class.k-expanded'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ExpansionPanelComponent.prototype, "expandedClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-state-focus'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ExpansionPanelComponent.prototype, "focusClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.aria-disabled'),
        core_1.HostBinding('class.k-state-disabled'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ExpansionPanelComponent.prototype, "disabledClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.dir'),
        tslib_1.__metadata("design:type", String)
    ], ExpansionPanelComponent.prototype, "direction", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('attr.tabindex'),
        tslib_1.__metadata("design:type", Number)
    ], ExpansionPanelComponent.prototype, "tabindex", void 0);
    tslib_1.__decorate([
        core_1.HostListener('blur'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", void 0)
    ], ExpansionPanelComponent.prototype, "onComponentBlur", null);
    tslib_1.__decorate([
        core_1.HostListener('focus'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", void 0)
    ], ExpansionPanelComponent.prototype, "onComponentFocus", null);
    ExpansionPanelComponent = tslib_1.__decorate([
        core_1.Component({
            exportAs: 'kendoExpansionPanel',
            providers: [
                kendo_angular_l10n_1.LocalizationService,
                {
                    provide: kendo_angular_l10n_1.L10N_PREFIX,
                    useValue: 'kendo.expansionpanel'
                }
            ],
            selector: 'kendo-expansionpanel',
            template: "\n        <div\n            [class.k-expander-header]=\"true\"\n            (click)=\"onHeaderClick($event)\">\n            <ng-container *ngIf=\"!titleTemplate\">\n                <div *ngIf=\"title\" class=\"k-expander-title\">{{ title }}</div>\n                <span class=\"k-spacer\"></span>\n                <div *ngIf=\"subtitle\" class=\"k-expander-sub-title\">\n                    {{ subtitle }}\n                </div>\n            </ng-container>\n            <ng-template\n                *ngIf=\"titleTemplate\"\n                [ngTemplateOutlet]=\"titleTemplate?.templateRef\">\n            </ng-template>\n            <span class=\"k-expander-indicator\">\n                <span [ngClass]=\"indicatorClasses\"></span>\n            </span>\n        </div>\n        <div #content class=\"k-expander-content-wrapper\">\n            <div  class=\"k-expander-content\" [attr.aria-hidden]=\"!expanded\">\n                <ng-content></ng-content>\n            </div>\n        </div>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.Renderer2,
            core_1.ElementRef,
            core_1.NgZone,
            kendo_angular_l10n_1.LocalizationService,
            animations_1.AnimationBuilder])
    ], ExpansionPanelComponent);
    return ExpansionPanelComponent;
}());
exports.ExpansionPanelComponent = ExpansionPanelComponent;
