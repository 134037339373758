//import angular from 'angular';
import tmpl from '../../templates/forgottenPassword.html';

angular.module('app').component('forgottenPassword', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
		controller: ['dataBroker', 'WindowFactory', '$scope', 'amtXlatSvc', 'enums',
			function (dataBroker, windowFactory: IWindowFactory, $scope, amtXlatSvc: IAmtXlatSvc, enums) {

			    var vm = this;
			    $scope.vm = this;
			    
			    vm.email = '';
			    vm.errorMessageForgotten = '';
			    vm.processing = false;
			    vm.emailFormat = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
			    vm.offline = amtXlatSvc.xlat('login.ForgottenPasswordOffline');
			    
			    this.$onInit = function () {
					vm.buttons = [
						{
							primary: true, 
							cancel: false, 
							value: 'login.Send',
							name: 'sendButton',
							click: 'send',
							type: enums.buttonTypes.button
						},
						{
							primary: false, 
							cancel: true,
							value: 'login.Close',
							name: 'closeButton',
							click: 'close',
							type: enums.buttonTypes.button
						}
					];
					
					vm.buttonStates = {
						sendButton: {
							visible: true, 
							disabled: true
						},
						closeButton: {
							visible: true, 
							disabled: false
						}
					};
					
					vm.buttonMethods = {
						send: vm.forgottenPassword,
						close: vm.close
                    };
				};

                $scope.$watchGroup(['vm.email', 'vm.forgottenPasswordForm.$error.email', 'vm.wnd.processing'], function () {
                    vm.buttonStates.sendButton.disabled = !vm.forgottenPasswordForm || !!vm.forgottenPasswordForm.$error.email || !vm.email || !!vm.wnd.processing;
				});

			    vm.clearEmailTextbox = function () {
			        vm.email = '';
			        vm.errorMessageForgotten = '';
			    };

			    vm.forgottenPassword = function () {
			        if (!vm.wnd.processing) {
			            vm.wnd.processing = true;
			            dataBroker.forgottenPassword(vm.email).then(function (response) {
			                console.log('Attempted password recovery');
			                if (!response || response.status === -1 || !response.data ) {
			                    vm.errorMessageForgotten = vm.offline;
			                } else {
			                    vm.errorMessageForgotten = response.data;
			                }
			            }).finally(function () {
			                vm.wnd.processing = false;
			            });
			        }
			    };

			    vm.close = function () {
			        vm.wnd.onDataChanged();
			        windowFactory.closeWindow(vm.wnd);
			    };

			    return vm;
			}
		]
    });
