/**
 * @ngdoc directive
 * @name amtFramework.directive:amtRadioButton
 * @restrict E
 *
 * @description Creates a radio group.
 *
 * @param {string} name Name of the radio group.
 * @param {string=} label Label / description for radio group, i.e.: "Select One:"
 * @param {expression} options Array of objects to display in the combobox. [{key: '1', name: 'one'}, {key: '2', name: 'two'}]
 * @param {expression} selectedItem Currently selected item.
 * @param {expression} isRequired Required
 * @param {boolean=} [inline=false] Boolean to indicate whether the radio group is inline or not
 *
 * @example
    <example name="radio-button-directive" module="amtFramework">
        <file name="index.html">
            <script>
                angular.module('amtFramework', [])
                    .controller('ExampleController', ['$scope', function($scope) {
                        $scope.options = [
                            {key: 1, name: 'Option 1', 'Description 1'},
                            {key: 2, name: 'Option 2'},
                            {key: 3, name: 'Option 3', 'Description 3'},
                        ];
                        $scope.selectedItem = {};
                    }]);
            </script>
            <form name="demoForm" ng-controller="ExampleController">
                <amt-radio-button label="Select one:"
                                  name="RadioDemo"
                                  options=options
                                  ng-model="selectedItem"
                                  is-required="true"
                                  inline="inline">
                </amt-radio-button>
                <span>Selected item: {{selectedItem}}</span>
                <br />
                <input type="checkbox" ng-model="inline">Inline
            </form>
        </file>
    </example>
 */

//import angular from 'angular';
import tmpl from "./amtRadioButton.html";


angular.module("amtFramework").directive("amtRadioButton", amtRadioButton);

function amtRadioButton() {
        return {
            require: ["ngModel", "^?form"],
            restrict: "E",
            scope: {
                name: "@",
                label: "@",
                options: "=",
                selectedItemName: "@",
                selectedItem: "=ngModel",
                isRequired: "=ngRequired",
                inline: "=",
                ngDisabled: "=ngDisabled",
                hideDescription: "=?",
                showActiveOnly: "=?"
            },
            template: tmpl,
            link: function (scope, element, attrs, controllers) {

                var selected = false;
                var initialValue = false;

                if (scope.options) {

                    for (var i = 0; i < scope.options.length; i++) {

                        if (scope.selectedItem === scope.options[i]) {
                            initialValue = true;
                        }

                        if (scope.selectedItemName === scope.options[i].name) {
                            scope.selectedItem = scope.options[i];
                            selected = true;
                        }

                        if (scope.selectedItem) {
                            scope.activeSelectedItem = angular.copy(scope.selectedItem);
                        }
                    }

                    scope.invalid = scope.isRequired && !selected && !initialValue;

                    scope.$watch('selectedItem', function (newValue, oldValue) {
                        updateValidity(newValue);
                    });

                    scope.optionClicked = function (option) {

                        if (!scope.isRequired && scope.activeSelectedItem && option.name === scope.activeSelectedItem.name) {
                            // clicked on selected item and not-required, so clear the field
                            scope.selectedItem = null;
                            scope.activeSelectedItem = null;
                            updateValidity(scope.selectedItem);

                        } else {
                            scope.activeSelectedItem = option;
                            updateValidity(option);
                        }
                    };

                } else {
                    throw new Error("No options supplied on radio button:" + scope.name);
                }

                function updateValidity(option) {
                    scope.invalid = scope.isRequired && !option;
                }
            }
        };
}

