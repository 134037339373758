//import angular from 'angular';


export const enum DateUnits {
    days = 'days',
    seconds = 'seconds'
}    
    
export default class OcDateSvc {
    private readonly regexIso8601 = /^20[\d]{2}(\/|-)[\d]{2}(\/|-)[\d]{2}(\s|T)[\d]{2}:[\d]{2}:[\d]{2}(\.[\d]{2,3})?((\+|-)[0-1][\d]:?(0|3)0)?$/;

    now() : Date {
        return new Date();
    }

    removeLocalTimeZoneOffset(date: string | null | Date): Date | null {
        if (date == null)
            return null;

        if (typeof date === "string")
            date = new Date(date);

        return new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000));
    }

    addLocalTimeZoneOffset(date: string | null | Date): Date | null {
        if (date == null)
            return null;

        if (typeof date === "string")
            date = new Date(date);
        
        return new Date(date.getTime() + (date.getTimezoneOffset() * 60 * 1000));
    }

    convertDateStringsToDates(input: any): any {

        if (typeof input !== "object") {
            return input;
        }

        for (let key in input) {
            if (!key || !input.hasOwnProperty(key)) {
                continue;
            }

            const value = input[key];
            let match: RegExpMatchArray;

            if (typeof value === "string" && (match = value.match(this.regexIso8601))) {
                const milliseconds = Date.parse(match[0]);

                if (!isNaN(milliseconds)) {
                    const date = new Date(milliseconds);

                    // prevent timezone offset on client                    
                    input[key] = new Date(date.getTime() + (date.getTimezoneOffset() * 60 * 1000));
                }

            } else if (value && typeof value === "object") {
                this.convertDateStringsToDates(value);
            }
        }

        return input;
    }

    addDays(date: Date, days: number): Date {
        var result = new Date(date);
        result.setDate(date.getDate() + days);
        return result;
    }

    combineDateAndTime(date: Date | string, time: Date | string): Date {
        date = typeof date === "string" ? new Date(date) : date;
        time = typeof time === "string" ? new Date(time) : time; // fixes bug in orig version which took the time part from the date if time was a string

        return date ? (time ? new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds())
            : new Date(date.getFullYear(), date.getMonth(), date.getDate())) : null;
    }

    combineDateAndTimeToMinute(date: Date | string, time: Date | string): Date {
        date = typeof date === "string" ? new Date(date) : date;
        time = typeof time === "string" ? new Date(time) : time;

        return date ? (time ? new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes())
            : new Date(date.getFullYear(), date.getMonth(), date.getDate())) : null;
    }

    protected addZero(i: number): string {
        return (i < 10 ? '0' : '') + i;
    }

    localToString(date: string | Date): string {
        return this.toString(this.removeLocalTimeZoneOffset(date));
    }

    protected toStringInternal(date: Date, format: string): string {
        if (!date) {
            return null;
        }

        return String.format(format,
            this.addZero(date.getDate()),
            this.addZero(date.getMonth() + 1),
            date.getFullYear(),
            this.addZero(date.getHours()),
            this.addZero(date.getMinutes()),
            this.addZero(date.getSeconds()));
    }

    toString(date: Date, showTime = true): string {
        return this.toStringInternal(date, showTime ? "{0}-{1}-{2} {3}:{4}" : "{0}-{1}-{2}");
    }

    toReportString(date: Date): string {
        return this.toStringInternal(date, "{2}-{1}-{0} {3}-{4}-{5}");
    }

    toDate(date: Date): Date {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    dateAsUTC(date : Date): Date {
        return date ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) : null;
    }

    dateTimeAsUTC(date: Date): Date {
        return date ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds())) : null;
    }

    addMonths(date: Date, months: number): Date {
        let d = new Date(date.getTime());

        //modulo function as the % operator doesn't do modulo on negative numbers and instead works more like a remainder
        const mod = (x, n) => (x % n + n) % n;

        //bitwise-or ensures months is integer
        let monthsToSet = d.getMonth() + months | 0;

        d.setMonth(monthsToSet);

        var targetMonth = mod(monthsToSet, 12);

        //if we subtracted months and got an unintuitive result (which can happen with dates like 2020-3-31 minus 1 month)
        //then correct the month and day parts to be the last day of the target month
        if (months < 0 && d.getMonth() != targetMonth) {
            //date as the last day of the target month, with the year and time preserved
            d = new Date(d.getFullYear(), targetMonth + 1, 0, d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds());
        }
        return d;
    }

    getEndOfMonth(date: Date): Date {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    today(): Date {
        return this.toDate(this.now());
    }

    halfStart(date: Date): Date {
        return new Date(date.getFullYear(), date.getMonth() < 6 ? 0 : 6, 1);
    }

    getStartOfMonth(date: Date): Date {
        return new Date(date.getFullYear(), date.getMonth(), 1);
    }

    //TODO: do not use in new code and try to remove this, this is a hack to replicate behaviour of the method chain .age().ageClass().days() from extentions.ts
    getDaysDiffLegacy(date: Date): number {
        const one_day = 1000 * 3600 * 24;

        return (Date.now() - date.getTime()) / one_day;
    }

    translatedFromUTCToLocalStartofDay(utcDate: Date) {
        utcDate.setHours(0);
        utcDate.setMinutes(0);
        utcDate.setSeconds(0);
        let date: Date = new Date(utcDate + "Z");

        return new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000));
    }

    getDifference(firstDate: Date, secondDate: Date, unit: DateUnits): number {
        switch (unit) {
            case DateUnits.days:
                const one_day = 1000 * 3600 * 24;
                let days = (Number(this.dateAsUTC(firstDate)) - Number(this.dateAsUTC(secondDate))) / one_day;
                return Math.abs(days);

            case DateUnits.seconds:
                let seconds = (this.dateTimeAsUTC(firstDate).getTime() - this.dateTimeAsUTC(secondDate).getTime()) / 1000;
                return Math.abs(seconds);

            default:
                console.error('Unit not handled');
        }
    }

    approxEqual(date0: Date | number, date1: Date | number): boolean {
        const epsilon = 50; //allowable difference in milliseconds for two DateTimes to be considered equal
        // should be at least 4ms to support SQL Server DateTime storage as per https://docs.microsoft.com/en-us/sql/t-sql/functions/date-and-time-data-types-and-functions-transact-sql

        if (date0 == null && date1 == null)
            return true; // if both values are null or undefined I think it's easier in Otracom code to say these are equal (ie. that null == null)

        //relies NaN for non-numbers and Dates set to an invalid state, either being NaN will cause our comparision to return false
        let d0 = date0 instanceof Date ? date0.getTime() : date0 == null ? Number.NaN : Number(date0);
        let d1 = date1 instanceof Date ? date1.getTime() : date1 == null ? Number.NaN : Number(date1);
        return Math.abs(d1 - d0) < epsilon;
    }
}

angular.module("app").service("ocDateSvc", OcDateSvc);