import * as _ from 'underscore'

/**
 * @ngdoc directive
 * @name amtFramework.formControls.directive:amtCombobox
 * @restrict E
 * 
 * @param {string} name Name of the control, used for formvalidation to find out which field is invalid
 * @param {string} url Url to get the data. Should accept a querystring paramater ?searchText
 * @param {expression} serviceMethod Method of an Angular Service to get the data from. Should accept a parameter searchText
 * @param {expression} ngModel Object that represents the selected item
 * @param {expression=} options Array of objects to display in the combobox. [{key: '1', name: 'one'}, {key: '2', name: 'two'}]
 * @param {expression=} selectedId Id of the selected item
 * @param {expression=} selectedText Text of the selected item
 * @param {string=} [idField=key] Name of the property to bind the id to
 * @param {string=} [textField=name] Name of the property to bind the text to
 * @param {boolean=} [autoComplete=true] wether the combobox calls the url/service when searching
 * @param {string=} [placeHolder=Search options...] Text to display as placeholder in the search box,
 * @param {integer=} [delay=300] Number of miliseconds delay before autocomplete call is made
 * @param {string=} filterField NOT USED
 * @param {boolean=} [isRequired=false] Required
 * @param {expression=} [isDisabled=false] Disabled
 * @param {expression=} extensionParams Additional parameters to send to the autocomplete url/service. [{property: value}]
 * @param {expression=} [searchable] Wether the search box is displayed
 * @param {expression} extendRequired ???
 * @param {expression=} isResetDependency ???
 * @param {callback=} onSelectedChange Function to call when selectedItem changed
 *                                     Signature: onselectedChange(option)
 * @param {callback=} onClear Function to call when clear button is clicked
 * @param {expression=} control Reference to the control to execute methods:
 *                  clear() Clears the selected item
 * @function
 *
 * @description
 * Combobox with search capabilities
 *
 * 
 * @example
   <doc:example module="amtFramework">
     <doc:source>
             <amt-combobox name="model1"
                           ng-model="vm.selectedItem" 
                           options="[{key: '1', name: 'Option 1'},{key: '2', name: 'Option 2'},{key: '3', name: 'Option 3'}]",
                           auto-complete="false"
                           selected-id="selectedId"
                           text-field="name">
             </amt-combobox>
     </doc:source>
   </doc:example>
 */

//import angular from 'angular';
import tmpl from "./amtCombobox.html";
import './amtCombobox.scss';


angular.module("amtFramework.formControls").directive("amtCombobox", [
            "amtFilterSvc", "$timeout", "amtXlatSvc", "$q", "cgBusyDefaults",
            function (filterSvc, $timeout, amtXlatSvc, $q, cgBusyDefaults) {
                return {
                    restrict: "E",
                    require: ["ngModel", "^?form"],
                    scope: {
                        idField: "@",
                        textField: "@",
                        url: "@",
                        serviceMethod: "&",
                        options: "=?", //TODO. remove options and use serviceMethod instead.
                        placeHolder: "@",
                        selectedItem: "=ngModel",
                        selectedId: "=?",
                        selectedText: "=?",
                        delay: "@",
                        onSelectedChange: "&",
                        onClear: "&",
                        canClear: "=?",
                        filterField: "@",
                        isRequired: "=",
                        isDisabled: "=",
                        preserveOrder: "=",
                        isResetDependency: "@",
                        useHttpPost: "=?",
                        name: "@",
                        extensionParams: "=",
                        searchable: "=",
                        extendRequired: "=",
                        control: "=",
                        showInactive: "=",
                        attachToApp: "=?"
                    },
                    template: tmpl,
                    link: function (scope: any, element, attrs, ctrls) {
                        var modelCtrl = ctrls[0];
                        var formCtrl = ctrls[1];
                        var forceReloadData;

                        var position;

                        if (angular.isUndefined(scope.attachToApp)) {
                            scope.attachToApp = false;
                        }

                        scope.$watch('canClear', function (newValue) {
                            if (newValue !== undefined) {
                                scope.canClearInt = newValue === true;
                            } else {
                                // assume we can clear
                                scope.canClearInt = true;
                            }
                        });                            

                        cgBusyDefaults.message = amtXlatSvc.xlat('framework.loadingMessage');

                        scope.maxItemsReached = false;
                        scope.noRecords = false;

                        scope.searchText = "";
                        scope.selected = {};
                        scope.intPlaceholder = amtXlatSvc.xlat("framework.searchOptions");
                        scope.intIdField = scope.idField || "key";
                        scope.intTextField = scope.textField || "name";
                        scope.intDelay = scope.delay || 300;

                        // we use this to fetch from URL only if search parameters have changed
                        var contract;

                        scope.internalControl = scope.control || {};

                        scope.internalControl.clear = function (clearDisabled) {
                            scope.clear(clearDisabled);
                        };

                        scope.internalControl.rebind = function () {
                            //scope.search();
                            forceReloadData = true;
                        };

                        scope.dataFilter = function () {
                            if (!scope.rawData) {
                                scope.rawData = scope.filteredOptions;
                            }
                            return scope.rawData.filter(function (value) {
                                return scope.showInactive || value.isActive !== false; // specifically want the default of is active to be true
                            });
                        };

                        // scope.options is an expression and can't be assigned to
                        scope.filteredOptions = scope.options || [];
                        scope.filteredOptions = scope.dataFilter();

                        scope.toggled = function (open) {

                            if (open === undefined) {
                                open = !scope.status.isOpen;
                            }

                            // clear filter
                            scope.searchText = "";

                            if (open) {

                                // clear the set above/below position on dropdown open
                                position = null;

                                scope.search();
                            }

                            positionDropdown(open);
                        };

                        scope.toggleDropdown = function ($event, value) {
                            $event.preventDefault();
                            $event.stopPropagation();

                            if (!scope.isDisabled) {
                                if (value !== undefined && value !== null) {
                                    scope.status.isOpen = value;
                                } else {
                                    scope.status.isOpen = !scope.status.isOpen;
                                }
                            }
                        };

                        scope.selectedTitles = "";

                        scope.$on("$destroy", function () {
                            if (scope.status.isOpen) {
                                if ($dropdown && $container) {
                                    if ($dropdown.parent().hasClass("app")) {
                                        cleanUpDropdown();
                                    }
                                }
                            }
                        });

                        scope.$watch("showInactive", function () {
                            scope.filteredOptions = scope.dataFilter();
                        });

                        scope.search = function () {

                            var reload = true;

                            if (scope.url || scope.serviceMethod) {

                                var newContract = {};

                                if (angular.isDefined(scope.extensionParams)) {
                                    newContract = angular.copy(scope.extensionParams);
                                }

                                if (JSON.stringify(newContract) === JSON.stringify(contract) && forceReloadData !== true) {
                                    if (scope.searchText) {
                                        scope.filteredOptions = _.filter(scope.dataFilter(), function (item) {
                                            // The + "" ensures it is converted to a string so that we can use indexOf to search.
                                            return (item[scope.intTextField] + "").trim().toLowerCase().indexOf(scope.searchText.toLowerCase()) !== -1;
                                        });
                                    } else {
                                        scope.filteredOptions = scope.dataFilter();
                                    }
                                    sortArray(scope.filteredOptions);
                                    reload = false;
                                } else {
                                    contract = newContract;
                                }
                            }

                            if (reload === true) {

                                console.log("Search");
                                scope.maxItemsReached = false;
                                scope.noRecords = false;
                                scope.filteredOptions = [];
                                console.log("Getting data and creating promise");
                                scope.inErroredState = false;

                                scope.promise = getData(scope.searchText, scope.extensionParams).then(function (data) {
                                    console.log("Promised");
                                    scope.rawData = data.result;
                                    scope.filteredOptions = scope.dataFilter();
                                    sortArray(scope.filteredOptions);
                                    scope.maxItemsReached = data.maxItemsReached && (data.result.length < data.total) && (scope.filteredOptions.length > 0);
                                    scope.noRecords = scope.filteredOptions.length === 0;
                                    scope.promise = null;
                                    forceReloadData = false;
                                }).catch(function (error) {
                                    console.error("Data for combo box failed to load: " + scope.url);
                                    var errorTxt = amtXlatSvc.xlat("framework.dataErrorMessage");
                                    scope.selectedItem = {};
                                    scope.selectedItem[scope.intTextField] = '<span class="loading">' + errorTxt + "</span>";
                                    scope.$emit("dataLoadFail", error);
                                });
                            }

                            $timeout(function () {
                                if (scope.status.isOpen) {
                                    positionDropdown(open);
                                }
                            });
                        };

                        function getData(searchText, params?: any) {
                            if (scope.url !== undefined && scope.url !== null && scope.url !== "") {
                                if (scope.useHttpPost) {
                                    var contract = { searchText: searchText };

                                    if (angular.isDefined(params)) {
                                        contract = angular.copy(params);
                                        contract.searchText = scope.searchText;
                                    }

                                    return filterSvc.post(scope.url, contract);
                                } else {
                                    return filterSvc.async(scope.url, searchText, params);
                                }
                            }

                            var sm;
                            // scope.serviceMethod is a wrapper to retrieve the service method
                            if (scope.serviceMethod !== undefined && scope.serviceMethod !== null) {
                                sm = scope.serviceMethod({ searchText: searchText, params: params });
                            }

                            if (sm) {
                                return sm({ searchText: searchText, params: params });
                            }

                            if (!scope.options) {
                                // wait for the options to be set
                                scope.searchDefered = $q.defer();
                                return scope.searchDefered.promise.then(function () {
                                    var fResult = _.filter(scope.options, function (item) {
                                        if (searchText === "") {
                                            return true;
                                        } else {
                                            // The + "" ensures it is converted to a string so that we can use indexOf to search.
                                            return (item[scope.intTextField] + "").trim().toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
                                        }
                                    });
                                    return { result: fResult };
                                });
                            }

                            var result = _.filter(scope.options, function (item) {
                                if (searchText === "") {
                                    return true;
                                } else {
                                    // The + "" ensures it is converted to a string so that we can use indexOf to search.
                                    return (item[scope.intTextField] + "").trim().toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
                                }
                            });
                            return $q.resolve({ result: result });
                        }

                        scope.select = function (option) {
                            if (attrs.onSelectedChange && (scope.selectedItem === undefined || scope.selectedItem === null || scope.selectedItem[scope.intIdField] !== option[scope.intIdField])) {
                                scope.onSelectedChange({ option: option });
                            }
                            if (formCtrl && attrs['noDirtyCheck'] == undefined) {
                                formCtrl.$setDirty();
                            }

                            scope.selectedItem = option;
                            scope.selectedId = option[scope.intIdField];
                            scope.selectedText = option[scope.intTextField];
                        };

                        scope.clear = function (clearDisabled) {
                            if (scope.isDisabled === true && !clearDisabled) {
                                return;
                            }
                            scope.selectedId = undefined;
                            scope.selectedItem = undefined;
                            if (formCtrl && attrs['noDirtyCheck'] === undefined) {
                                formCtrl.$setDirty();
                            }
                            if (attrs.onSelectedChange) {
                                scope.onSelectedChange({ option: null });
                            }

                            if (scope.onClear) {
                                scope.onClear();
                            }
                        };

                        function sortArray(sourceArray) {
                            if (scope.preserveOrder !== true) {
                                sourceArray.sort(function (first, second) {
                                    if (!first[scope.intTextField]) {
                                        console.warn("Empty value in Combo");
                                        return 1;
                                    } // null's first ?
                                    if (!second[scope.intTextField]) {
                                        console.warn("Empty value in Combo");
                                        return -1;
                                    }

                                    return compareTextFields(first[scope.intTextField], second[scope.intTextField]) ? 1 : -1;
                                });
                            }
                        }

                        /**
                         * Compares the value of two text fields, regardless of thier type.
                         * @param {string | number} first First item to compare.
                         * @param {string | number} second Second item to compare.
                         * @return {boolean} True if `first` is larger than `second`.
                         * @throws Throws an Error if item types are not the same.
                         */
                        function compareTextFields(first, second) {
                            //If both fields are not numbers then assume they are text and compare
                            if (!isNaN(first) && !isNaN(second)) {
                                return first > second;
                            } else if (isNaN(first) || isNaN(second)) {
                                return first.trim().toLowerCase() > second.trim().toLowerCase();
                            } else {
                                // Could assume the first type is correct and convert, but this is easier (you'd have to check the conversion was possible, etc.).
                                throw Error("Items to compare must be the same type.");
                            }
                        }

                        scope.$watch("selectedItem", function (value) {
                            if (!value) {
                                scope.selectedItem = value;
                            } else {
                                scope.selectedItem = typeof (value) !== "object" ? JSON.parse(value) : value;
                            }
                            handleFormValidity();
                        });

                        function handleFormValidity() {
                            var valid = scope.isRequired === undefined || scope.isRequired === false || (scope.isRequired && scope.selectedItem !== undefined && scope.selectedItem !== null);
                            modelCtrl.$setValidity(scope.name, valid);
                        }

                        scope.$watch("selectedId", function (newValue) {

                            var hasValue = newValue !== undefined && newValue !== null;

                            // not sure why new value is getting the selectedItem object here, but we'll pluck out the Id from the object and use that.
                            if (hasValue) {
                                if (angular.isObject(newValue)) {
                                    newValue = newValue[scope.intIdField];
                                }
                            }

                            if (hasValue && (!scope.selectedItem || scope.selectedItem[scope.intIdField] !== newValue)) {
                                //get the text for the id

                                if (!scope.selectedItem || !scope.selectedItem[scope.intTextField]) {
                                    scope.selectedItem = {};
                                    scope.selectedItem[scope.intTextField] = '<span class="loading">' + amtXlatSvc.xlat("framework.loadingMessage") + "</span>";
                                }

                                var args = angular.copy(scope.extensionParams) || {};
                                if (!args.id) {
                                    args.ids = [newValue];
                                }
                                getData("", args)
                                    .then(function (response) {
                                        if (!response) {
                                            console.error("Data for combo box failed to load: " + scope.url);
                                            var errorTxt = amtXlatSvc.xlat("framework.dataErrorMessage");
                                            scope.selectedItem = {};
                                            scope.selectedItem[scope.intTextField] = '<span class="loading">' + errorTxt + "</span>";
                                            scope.$emit("dataLoadFail", errorTxt);
                                            return;
                                        }
                                        scope.filteredOptions = response.result;
                                        if (scope.filteredOptions) {
                                            sortArray(scope.filteredOptions);
                                        } else {
                                            console.warn("No items returned");
                                            scope.filteredOptions = [];
                                        }
                                        scope.selectedItem = {};
                                        scope.selectedItem[scope.intIdField] = newValue;
                                        scope.selectedId = newValue;

                                        var result = _.filter(response.result, function (item) {
                                            return item[scope.intIdField] === newValue;
                                        });

                                        if (result.length > 0) {
                                            scope.selectedItem = result[0];
                                            scope.selectedText = result[0][scope.intTextField];
                                        }

                                    }, function (error) {
                                        // failed to load data values
                                        console.error("Data for combo box failed to load: " + scope.url);
                                        var errorTxt = amtXlatSvc.xlat("framework.dataErrorMessage");
                                        scope.selectedItem = {};
                                        scope.selectedItem[scope.intTextField] = '<span class="loading">' + errorTxt + "</span>";
                                        scope.$emit("dataLoadFail", error);
                                    });
                            }
                        });
                        scope.$watch("selectedText", function (newValue) {
                            if (newValue && (scope.selectedItem === undefined || scope.selectedItem[scope.intTextField] !== newValue)) {
                                //get the text for the id
                                getData(newValue)
                                    .then(function (response) {
                                        scope.selectedItem = {};
                                        scope.selectedItem[scope.intTextField] = newValue;
                                        scope.selectedItem[scope.intIdField] = response.result[0][scope.intIdField];
                                    }).catch(function (error) {
                                        console.error("Data for combo box failed to load: " + scope.url);
                                        var errorTxt = amtXlatSvc.xlat("framework.dataErrorMessage");
                                        scope.selectedItem = {};
                                        scope.selectedItem[scope.intTextField] = '<span class="loading">' + errorTxt + "</span>";
                                        scope.$emit("dataLoadFail", error);
                                    });
                            }
                        });

                        scope.$watch("extensionParams", function (newValue, oldValue) {
                            if (newValue === oldValue) {
                                return;
                            }
                            if (scope.isResetDependency) {
                                scope.selectedId = null;
                                scope.selectedItem = null;
                            }
                            scope.filteredOptions = [];

                        }, true);

                        scope.$watch("isRequired", function (newValue, oldValue) {
                            if (newValue === oldValue) {
                                return;
                            }
                            handleFormValidity();
                        });

                        scope.$watch("options", function (newValue, oldValue) {
                            if (newValue && scope.searchDefered) {
                                scope.searchDefered.resolve();
                            }
                            if (newValue === oldValue) {
                                return;
                            }
                            scope.rawData = scope.options || [];
                            scope.filteredOptions = scope.dataFilter();
                        });

                        scope.keyPress = function (e) {

                            var currentOptionIndex = undefined;

                            switch (e.which) {

                                case 9: // TAB
                                    if (scope && scope.status) {
                                        scope.status.isOpen = false;
                                    }
                                    break;

                                case 38: // UP
                                    if (scope.filteredOptions && scope.filteredOptions.length > 0) {
                                        if (scope.selectedItem) {
                                            currentOptionIndex = _.findIndex(scope.filteredOptions, function (o) { return o[scope.intIdField] === scope.selectedId; });
                                        }

                                        scope.select(scope.filteredOptions[currentOptionIndex < 0 ? scope.filteredOptions.length - 1 : (currentOptionIndex === 0 ? scope.filteredOptions.length - 1 : currentOptionIndex - 1)]);
                                    }
                                    break;

                                case 40: // DOWN
                                    if (scope.filteredOptions && scope.filteredOptions.length > 0) {
                                        if (scope.selectedItem) {
                                            currentOptionIndex = _.findIndex(scope.filteredOptions, function (o) { return o[scope.intIdField] === scope.selectedId; });
                                        }

                                        scope.select(scope.filteredOptions[!(currentOptionIndex >= 0) ? 0 : (currentOptionIndex === scope.filteredOptions.length - 1 ? 0 : currentOptionIndex + 1)]);
                                    }
                                    break;
                            }

                            $timeout();
                        };

                        var $container = $(element).find(".combobox");
                        var $dropdown = $(element).find(".dropdown-menu");

                        $(".dropdown-menu.combobox").on("click", function () {
                            return false;
                        });

                        function positionDropdown(isOpen) {
                            if (!isOpen) {
                                if ($dropdown) {
                                    if ($dropdown.parent().hasClass("app")) {
                                        cleanUpDropdown();
                                    }
                                    $dropdown.hide();
                                }
                                return;
                            }

                            var containerOffset = $container.offset();
                            var containerOffsetTop = containerOffset.top;
                            var containerH = $container.outerHeight(false);

                            var dropdownH = $dropdown.outerHeight(false);
                            var dropdownW = $dropdown.outerWidth(false);

                            var fitLeft = containerOffset.left + dropdownW <= $(window).width();

                            var fitAbove = containerOffsetTop > dropdownH;
                            var fitBelow = ($(window).height()) - containerOffsetTop - containerH > dropdownH;

                            var css: any = {
                                left: fitLeft ? 0 : $container.outerWidth(false) - dropdownW,
                                position: "absolute"
                            };

                            // if the dropdown fits below (or not above) and hasn't already been set to open above, open below
                            if (position != 'above' && (fitBelow || !fitAbove)) {
                                css.top = containerH;
                                position = 'below';
                            } else {
                                // if dropdown set to open above, or it doesn't fit below, open above
                                css.top = -dropdownH - 3; // TODO: No idea what this magic 3 is for...
                                $dropdown.addClass("dropdown-menu-up");
                                position = 'above';
                            }

                            if (scope.attachToApp) {
                                setupDropdownMobile(css, containerOffset, containerH, dropdownH);
                            }

                            $dropdown.css(css);
                            $dropdown.show();
                        }

                        //Setups the proper position for the dropdown and attaches to the app class.
                        //Also sets up proper event handlers
                        var setupDropdownMobile = function (css, containerOffset, containerH, dropdownH) {
                            //Positioning and detatch/attach
                            css.left = containerOffset.left;
                            css.width = $container.width();
                            if (position == 'below') {
                                css.top = containerOffset.top + containerH;
                            } else {
                                css.top = containerOffset.top - dropdownH;
                            }
                            $('.app').append($dropdown.detach());

                            //Event handlers
                            $(element).on('hide.bs.dropdown', function () {
                                if ($dropdown.parent().hasClass("app")) {
                                    cleanUpDropdown();
                                }
                            });
                            //Debounced scroll event to reposition dropdown
                            $(".child-window-body").on('scroll', repositionDD);
                            //Debounced resize event
                            $(window).resize(repositionDD);
                            //TODO: add rotate event?
                            $(window).on("orientationchange", repositionDD);
                        }

                        var cleanUpDropdown = function () {
                            $(".child-window-body").off('scroll');
                            $(element).off('hide.bs.dropdown');
                            $(window).off('resize', repositionDD);
                            $(window).off("orientationchange", repositionDD);
                            $container.append($dropdown.detach());
                        }

                        var repositionDD = _.debounce(function () {
                            if (isVisible($container, $('.child-window-body'))) {
                                positionDropdown(true);
                            } else {
                                $dropdown.hide();
                            }
                        }, 350);

                        const isVisible = function (ele, container) {
                            const eleTop = ele.offset().top;
                            const eleBottom = eleTop + ele.height();

                            const containerTop = container.scrollTop();
                            const containerBottom = containerTop + container.height();

                            // The element is fully visible in the container
                            return (
                                (eleTop >= containerTop && eleBottom <= containerBottom) ||
                                // Some part of the element is visible in the container
                                (eleTop < containerTop && containerTop < eleBottom) ||
                                (eleTop < containerBottom && containerBottom < eleBottom)
                            );
                        };
                    }
                };
    }
]);
