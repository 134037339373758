//import angular from 'angular';
import OcDateSvc from '../../services/ocDateSvc'
import tmpl from './ocDateTimePicker.html';


    angular.module('app.directives')
        .directive('ocDateTimePicker', ['ocDateSvc', function (ocDateSvc: OcDateSvc) {
            return {
                transclude: true,
                require: "ngModel",
                scope: {
                    ngModel: "=",
                    minDate: "=",
                    maxDate: "=",
                    showNowButton: "=",
                    ngRequired: "=",
                    isDisabled: "=",
                    dateWidth: "=",
                    nowWidth: "=",
                    timeWidth: "=",
                    nowLimitedToMaxDate: "=",
                    allowNowToUpdateMaxDate: "=",
                    ngBlur: "&"
                },
                template: tmpl,
                link: function (scope: any, element, attrs, ngModel) {
                    function updateModelValue(newValue, oldValue) {
                        if (newValue !== oldValue) {
                            var combinedDate = ocDateSvc.combineDateAndTime(scope.dateValue, scope.timeValue);

                            if (scope.ngModel && (!combinedDate || scope.ngModel.valueOf() !== combinedDate.valueOf())) {
                                // value has changed
                                scope.ngModel = combinedDate;
                                return;
                            }

                            if (!scope.ngModel && combinedDate) {
                                // no value, but one has been picked
                                scope.ngModel = combinedDate;
                            }
                        }
                    }

                    scope.setToNow = function() {
                        var currentDate = new Date();

                        // set the milliseconds to 0 because this is what the amt-time-picker is going to do
                        // so if we don't do it here it can end up triggering a change event twice
                        currentDate.setMilliseconds(0);

                        if (scope.maxDate && scope.maxDate < currentDate && scope.allowNowToUpdateMaxDate) {
                            // if we can move max-date to current date/time, then update it
                            scope.maxDate = currentDate;
                        }

                        if (scope.maxDate && scope.nowLimitedToMaxDate && currentDate > scope.maxDate) {
                            // if we can't go past max-date, then clicking now should set max-date, if max-date is in the future
                            currentDate = scope.maxDate;
                        }
                        
                        scope.ngModel = currentDate;
                    }

                    ngModel.$validators.checkValidity = function (modelValue, viewValue) {
                        var value = modelValue || viewValue;

                        if (!value && scope.ngRequired) {
                            // can't be null
                            return false;
                        }

                        if (typeof value === 'string') {
                            value = new Date(value);
                        }

                        if (scope.minDate && value && value < scope.minDate) {
                            // can't be less than the min value
                            return false;
                        }

                        if (scope.maxDate && value && value > scope.maxDate) {
                            // can't be more than the max value
                            return false;
                        }

                        return true;
                    }

                    scope.$watch('ngModel', function (newValue, oldValue) {
                        if (!newValue) {
                            scope.dateValue = undefined;
                            scope.timeValue = undefined;
                        } else {
                            scope.dateValue = new Date(newValue);
                            scope.timeValue = new Date(newValue);
                        }

                        if (scope.ngBlur) {
                            scope.ngBlur();
                        }
                    });

                    scope.onTimeChange = updateModelValue;

                    scope.$watch('dateValue', updateModelValue);
                    scope.$watch('timeValue', updateModelValue);
                }
            };
        }]);
