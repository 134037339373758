import { Injectable } from '@angular/core';
import { NotificationService, NotificationSettings } from '@progress/kendo-angular-notification';
import { WidgetConstant } from '../widgetHelper/WidgetConstant';

@Injectable({
    providedIn: 'root'
})

export class WidgetApiNotificationService {
    private deafultFetchNotificationSetting: NotificationSettings = {
        content: "",
        hideAfter: 3500,
        position: { horizontal: "right", vertical: "bottom" },
        animation: { type: "fade", duration: 1000 },
        type: { style: "success", icon: true }
    };

    private defaultErrorNotificationSetting: NotificationSettings = {
        content: "",
        hideAfter: 3500,
        position: { horizontal: "right", vertical: "bottom" },
        animation: { type: "fade", duration: 1000 },
        type: { style: "error", icon: true }
    };

    constructor(private constant: WidgetConstant, private notification: NotificationService) { }

    //Fetch
    public async sendFetchNotification() {
        let descrption = this.constant.widgetNotification.updatedMsg;
        this.sendFetchDefaultWidgetNotification(descrption);
    }

    //Change Completed Widget - Error
    public async sendErrorChangesCompletedWidgetNotification() {
        let descrption = this.constant.widgetNotification.changesCompleted + this.constant.widgetNotification.errorMsg;
        this.sendErrorDefaultWidgetNotification(descrption);
    }

    //Total Down Time Widget - Error
    public async sendErrorTotalDownTimeWidgetNotification() {
        let descrption = this.constant.widgetNotification.totalDowntime + this.constant.widgetNotification.errorMsg;
        this.sendErrorDefaultWidgetNotification(descrption);
    }

    //Data Error Widget - Error
    public async sendErrorDataErrorWidgetNotification() {
        let descrption = this.constant.widgetNotification.dataError + this.constant.widgetNotification.errorMsg;
        this.sendErrorDefaultWidgetNotification(descrption);
    }

    //Tyre In Inspection Widget - Error
    public async sendErrorTyreInInspectionNotification() {
        let descrption = this.constant.widgetNotification.tyreInspection + this.constant.widgetNotification.errorMsg;
        this.sendErrorDefaultWidgetNotification(descrption);
    }


    //Site Health Rate - Error
    public async sendErrorSiteHealthRateNotification() {
        let descrption = this.constant.widgetNotification.siteHealthRate + this.constant.widgetNotification.errorMsg;
        this.sendErrorDefaultWidgetNotification(descrption);
    }

    //Vehicle In Maintenance - Error
    public async sendErrorVehicleInMaintenanceWidgetNotification() {
        let descrption = this.constant.widgetNotification.vehicleInMaintenance + this.constant.widgetNotification.errorMsg;
        this.sendErrorDefaultWidgetNotification(descrption);
    }


    //Site Dashboard Widget Id Dependancy Widget - Error
    public async sendErrorNotification(dashboardName: string, widgetTitle: string) {
        let description = dashboardName + " - " + widgetTitle + this.constant.widgetNotification.errorMsg;
        this.sendErrorDefaultWidgetNotification(description);
    }

    private async sendErrorDefaultWidgetNotification(description: string) {
        let defaultErrorConfig = this.defaultErrorNotificationSetting;
        defaultErrorConfig.content = description;
        this.notification.show(defaultErrorConfig);
    }


    private async sendFetchDefaultWidgetNotification(descrption: string) {
        let defaultFetchConfig = this.deafultFetchNotificationSetting;
        defaultFetchConfig.content = descrption;
        this.notification.show(defaultFetchConfig);
    }


}