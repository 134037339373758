//import angular from 'angular';

angular.module('app').filter('durationFilter', durationFilter);

    // @ts-ignore
    durationFilter.$inject = ['$filter', 'amtXlatSvc'];
    function durationFilter($filter, amtXlatSvc) {
        return function (value, zeroText) {
            if (!value) {
                return zeroText || '-';
            }

            var hours = Math.floor(value / 60);
            var mins = Math.floor(value - (hours * 60));
            var hourDesc = undefined;
            var minDesc = undefined;

            if (hours === 1) {
                hourDesc = ' ' + amtXlatSvc.xlat('common.hour_abbrev');
            } else {
                hourDesc = ' ' + amtXlatSvc.xlat('common.hours_abbrev');
            }

            if (mins === 1) {
                minDesc = ' ' + amtXlatSvc.xlat('common.minute_abbrev');
            } else {
                minDesc = ' ' + amtXlatSvc.xlat('common.minutes_abbrev');
            }

            if (hours > 0 && mins > 0) {
                return hours + hourDesc + ' ' + mins + minDesc;
            } else {
                if (hours > 0) {
                    return hours + hourDesc;
                } else {
                    return mins + minDesc;
                }
            }
        };
    }
