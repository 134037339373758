//import angular from 'angular';

angular.module('amtFramework').directive('stopEvent', [
    function () {
        return {
            restrict: 'A',
            link: function (scope, element, attr) {
                element.bind(attr.stopEvent, function (e: any) {
                    if (!e) {
                        e = window.event;
                    }

                    //e.cancelBubble is supported by IE -
                    // this will kill the bubbling process.
                    e.cancelBubble = true;
                    e.returnValue = false;

                    e.stopPropagation();
                    e.preventDefault();

                    return false;

                });
            }
        };
    }
]);

