import { Injectable } from '@angular/core';
import { InitWidgetPositionHelper } from './InitialPositionList.helper';
import { InitAddPositionHelper } from './InitialAddPositionList.helper';
import { Dashboard, UserPosition } from '../Interface/SiteDashboard.interface';
@Injectable({
    providedIn: 'root'
})

export class InitDashboardService {

    constructor(private InitPosition: InitWidgetPositionHelper, private addPosition: InitAddPositionHelper) {
    }

    //check api return value
    isNewUser(response) {
        let newUser = false;
        if (response.length === 0) {
            newUser = true;
        }
        return newUser;
    }

    //check if the modified list
    isModified(list) {
        let modified = true;
        if (list.length === 0) {
            modified = false;
        }
        return modified;
    }

    //return api user list
    getInitUserPositionList(response: any) {
        if (response === null || response == undefined) {
            return [];
        } else {
            return JSON.parse(response);
        }
    }

    // return from InitWidgetPositionHelper
    getInitPositionList(dashboardItemList: Dashboard[], tileSetting: any) {
        return this.InitPosition.setInitalWidgetPositionList(dashboardItemList, tileSetting);
    }

    // find additional from initList to userList
    addList(initList: UserPosition[], userList: any) {
        let addList: UserPosition[] = [];
        addList = initList.filter(init => {
            return !userList.find(user => {
                return user.siteDashboardWidgetId === init.siteDashboardWidgetId;
            });
        });
        return addList;
    }

    // delete initList from userList if it is not existed
    deleteList(initList: UserPosition[], userList: any) {
        let deleteList: UserPosition[] = [];
        deleteList = userList.filter(init => {
            return !initList.find(user => {
                return user.siteDashboardWidgetId === init.siteDashboardWidgetId;
            });
        });
        return deleteList;
    }

    // add newlist to userList
    addPositionList(userWidgets: UserPosition[], newWidgetList: UserPosition[]) {
        let newPositionList = [];
        userWidgets.forEach(user => {
            newPositionList.push(user);
        });
        newWidgetList.forEach(newWidget => {
            newPositionList.push(newWidget);
        });
        return newPositionList;
    }

    // delete positionList from deleteWidgetList
    deletePositionList(userWidgets: UserPosition[], deleteWidgetList: UserPosition[]) {
        let newPositionList: UserPosition[] = userWidgets;
        newPositionList.forEach((exsiting, index) => {
            deleteWidgetList.forEach(deleteWidget => {
                if (exsiting.siteDashboardWidgetId === deleteWidget.siteDashboardWidgetId){
                    newPositionList.splice(index, 1);
                }
            });
        });

        return newPositionList;
    }

    // set new location
    setNewSiteDashboardWidgetLocation(dashboardList: Dashboard[], newWidgets: UserPosition[], userPositionList: UserPosition[], tileSetting: any) {
       return this.addPosition.setNewWidgetLocation(dashboardList, newWidgets, userPositionList, tileSetting)
    }


}