//import angular from 'angular';
import AdminUrls from '../adminUrls';


    angular.module('app.admin')
        .controller('locationSearchCtrl', [
            '$scope', 'locationSvc', 'adminUrls', 'amtXlatSvc', 'ocSecuritySvc',
            function (
                $scope, locationSvc, adminUrls: AdminUrls, amtXlatSvc, ocSecuritySvc
            ) {
                var vm = this;

                vm.status = "loading";
                vm.gridControl = {};
                vm.selectedItem = null;
                vm.selectAll = true;
                vm.showClients = false;
                vm.showSites = false;

                vm.svc = locationSvc;

                vm.adminUrls = adminUrls;

                vm.results = [];

                vm.criteria = {
                    filterValues: {
                        clientIds: [],
                        siteIds: [],
                        locationName: null,
                        active: false,
                        defaultToAllSitesWhenNoSystemHierarchyFilter: true
                    }
                };

                vm.setHeaderHeight = function (height, offset) {
                    vm.gridTop = height + offset;
                };

                vm.onSearchComplete = function () {
                    vm.selectedItem = null;
                };

                vm.selectItem = function (items) {
                    if (items != null && items.length === 1) {
                        vm.selectedItem = items[0];
                    } else {
                        vm.selectedItem = null;
                    }
                };

                vm.export = function () {
                    vm.svc.export(vm.criteria);
                };

                vm.getSearchDefaultsSuccessHandler = function (result) {
                    vm.defaultClients = result.clients;
                    vm.defaultSites = result.sites;
                    vm.defaultActiveStatuses = vm.svc.activeStates.filter(function (s) { return s.default === true });

                    vm.siteId = result.sites[0].key;

                    vm.status = "ready";

                    // focus on name field by default
                    vm.focusLocationName = true;
                };

                vm.rowParseFunction = function (response) {
                    var items = response.result;

                    for (var item of items) {
                        try {
                            item.accessType = ocSecuritySvc.getAccessType(vm.svc.mainPermission, item.clientId, item.siteId);
                        } catch {
                            item.accessType = ocSecuritySvc.accessTypes.hide;
                        }
                    }

                    return response;
                };

                vm.getSearchDefaultsErrorHandler = function (error) {
                    vm.defaultErrors = error;
                    vm.status = "error";
                };

                vm.loadDefaults = function () {
                    vm.status = 'loading';

                    var defaultCriteria = {};

                    vm.svc.getSearchDefaultsAsync(defaultCriteria)
                        .then(vm.getSearchDefaultsSuccessHandler, vm.getSearchDefaultsErrorHandler);
                };

                function init() {
                    vm.loadDefaults();
                }

                vm.refreshGrid = function () {
                    vm.gridControl.refresh();
                };

                vm.searchSucessHandler = function (result) {
                    vm.results = result;
                };

                vm.searchErrorHandler = function (error) {
                    vm.results = null;
                };

                vm.filter = function () {
                    vm.selectedItem = undefined;
                    vm.showClients = vm.criteria.filterValues.clientIds.length !== 1;
                    vm.showSites = vm.criteria.filterValues.siteIds.length !== 1;

                    vm.refreshGrid();
                };

                vm.create = function () {
                    vm.svc.create(vm.refreshGrid);
                };

                vm.openItem = function (item) {
                    vm.svc.openItem(
                        item,
                        vm.refreshGrid,
                        amtXlatSvc.xlat(item.accessType === ocSecuritySvc.accessTypes.readWrite ? 'location.edit' : 'location.view', item.name)
                    );
                };

                $scope.openItem = vm.openItem;

                $scope.$on('filterSearch', function () {
                    vm.filter();
                });

                init();
            }
        ]);
