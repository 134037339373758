//import angular from 'angular';

angular.module('app').filter('unitOfMeasure', unitOfMeasureFilter);

    // @ts-ignore
    unitOfMeasureFilter.$inject = ['numberFilter', 'amtXlatSvc'];
    function unitOfMeasureFilter(numberFilter: ng.IFilterNumber, amtXlatSvc: IAmtXlatSvc) {

        var conversionFactors = [
            { readingType: "Depth", unit: "32nds", factor: 1.2598425184, isBaseUnit: false, decimalPlaces: 0 },
            { readingType: "Depth", unit: "mm", factor: 1.0000000000, isBaseUnit: true, decimalPlaces: 0 },
            { readingType: "Dimension", unit: "in", factor: 0.0393700787, isBaseUnit: false, decimalPlaces: 0 },
            { readingType: "Dimension", unit: "mm", factor: 1.0000000000, isBaseUnit: true, decimalPlaces: 0 },
            { readingType: "Distance", unit: "km", factor: 1.0000000000, isBaseUnit: true, decimalPlaces: 0 },
            { readingType: "Distance", unit: "mi", factor: 0.621371192237, isBaseUnit: false, decimalPlaces: 0 },
            { readingType: "Pressure", unit: "kPa", factor: 1.0000000000, isBaseUnit: true, decimalPlaces: 0 },
            { readingType: "Pressure", unit: "psi", factor: 0.1450377380, isBaseUnit: false, decimalPlaces: 0 },
            { readingType: "Temperature", unit: "°C", factor: 1.0000000000, isBaseUnit: true, decimalPlaces: 0 },
            { readingType: "Temperature", unit: "°F", factor: 1.0000000000, isBaseUnit: false, decimalPlaces: 0 },
            { readingType: "Time", unit: "hours", factor: 1.0000000000, isBaseUnit: true, decimalPlaces: 0 },
            { readingType: "Time", unit: "minutes", factor: 60.0000000000, isBaseUnit: false, decimalPlaces: 0 },
            { readingType: "Torque", unit: "ft-lb", factor: 0.7380000000, isBaseUnit: false, decimalPlaces: 0 },
            { readingType: "Torque", unit: "Nm", factor: 1.0000000000, isBaseUnit: true, decimalPlaces: 0 },
            { readingType: "Weight", unit: "kg", factor: 1000.0000000000, isBaseUnit: false, decimalPlaces: 0 },
            { readingType: "Weight", unit: "lb", factor: 2204.6226200000, isBaseUnit: false, decimalPlaces: 0 },
            { readingType: "Weight", unit: "lt", factor: 0.9803920000, isBaseUnit: false, decimalPlaces: 0 },
            { readingType: "Weight", unit: "st", factor: 1.1023110000, isBaseUnit: false, decimalPlaces: 0 },
            { readingType: "Weight", unit: "t", factor: 1.0000000000, isBaseUnit: true, decimalPlaces: 0 }
        ];

        return function (reading, destinationUnit: string, decimalPlaces: number, unitSuffix: string = "") {
            if (!reading || reading.value == null) {
                return null;
            }

            let destinationValue = reading.value;

            if (destinationUnit == null)
                destinationUnit = reading.unit;

            if (reading.unit !== destinationUnit) {
                if (reading.type === 'Temperature') {
                    destinationValue = reading.unit === '°C' ? reading.value * 1.8 + 32 : (reading.value - 32) * 5 / 9;
                } else {
                    let sourceFactor = conversionFactors.find(u => u.readingType == reading.unitType && u.unit == reading.unit);
                    let destinationFactor = conversionFactors.find(u => u.readingType == reading.unitType && u.unit == destinationUnit);
                    if (sourceFactor && destinationFactor) {
                        destinationValue = reading.value / sourceFactor.factor * destinationFactor.factor;
                        if (decimalPlaces == null)
                            decimalPlaces = destinationFactor.decimalPlaces;
                    }
                }
            }

            return numberFilter(destinationValue, decimalPlaces || 0) + " " + amtXlatSvc.xlat('common.unit_' + destinationUnit) + unitSuffix;
        };
    }
