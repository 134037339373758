    window.document.addEventListener('DOMContentLoaded', function () {
        var callback = function (result?) {
            var cls = result ? "webp" : "no-webp";
            var body = window.document.body;
            if (body.classList)
                body.classList.add(cls);
            else
                body.className += cls;
        };

        var img = new Image();
        img.onload = function () { callback((img.width > 0) && (img.height > 0)) };
        img.onerror = function () { callback() };
        img.src = "data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA";
    });