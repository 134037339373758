/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var utils_1 = require("../common/utils");
/**
 * Represents the directive that renders the [Kendo UI CheckBox]({% slug overview_checkbox %}) input component.
 * The directive is placed on input type="checkbox" elements.
 *
 * @example
 * ```ts-no-run
 * <input type="checkbox" kendoCheckBox />
 * ```
 */
var CheckBoxDirective = /** @class */ (function () {
    function CheckBoxDirective(renderer, hostElement) {
        this.renderer = renderer;
        this.hostElement = hostElement;
        this.kendoClass = true;
        this._size = 'medium';
        this._rounded = 'medium';
    }
    Object.defineProperty(CheckBoxDirective.prototype, "size", {
        get: function () {
            return this._size;
        },
        /**
         * The size property specifies the width and height of the CheckBox
         * ([see example]({% slug appearance_checkboxdirective %}#toc-size)).
         *
         * The possible values are:
         * * `'small'`
         * * `'medium'` (default)
         * * `'large'`
         * * `null`
         */
        set: function (size) {
            this.handleClasses(size, 'size');
            this._size = size;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CheckBoxDirective.prototype, "rounded", {
        get: function () {
            return this._rounded;
        },
        /**
         * The rounded property specifies the border radius of the CheckBox
         * ([see example]({% slug appearance_checkboxdirective %}#toc-rounded)).
         *
         * The possible values are:
         * * `'small'`
         * * `'medium'` (default)
         * * `'large'`
         * * `null`
         */
        set: function (rounded) {
            this.handleClasses(rounded, 'rounded');
            this._rounded = rounded;
        },
        enumerable: true,
        configurable: true
    });
    CheckBoxDirective.prototype.ngAfterViewInit = function () {
        var _this = this;
        var stylingInputs = ['size', 'rounded'];
        stylingInputs.forEach(function (input) {
            _this.handleClasses(_this[input], input);
        });
    };
    CheckBoxDirective.prototype.handleClasses = function (value, input) {
        var elem = this.hostElement.nativeElement;
        var classes = utils_1.getStylingClasses('checkbox', input, this[input], value);
        if (classes.toRemove) {
            this.renderer.removeClass(elem, classes.toRemove);
        }
        if (classes.toAdd) {
            this.renderer.addClass(elem, classes.toAdd);
        }
    };
    tslib_1.__decorate([
        core_1.HostBinding('class.k-checkbox'),
        tslib_1.__metadata("design:type", Boolean)
    ], CheckBoxDirective.prototype, "kendoClass", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], CheckBoxDirective.prototype, "size", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], CheckBoxDirective.prototype, "rounded", null);
    CheckBoxDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: 'input[kendoCheckBox]'
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.Renderer2,
            core_1.ElementRef])
    ], CheckBoxDirective);
    return CheckBoxDirective;
}());
exports.CheckBoxDirective = CheckBoxDirective;
