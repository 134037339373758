/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Renders the header content of the list. To define the header template, nest an `<ng-template>` tag
 * with the `kendo<ComponentName>HeaderTemplate` directive inside the component tag.
 *
 * - [Using `HeaderTemplate` with the AutoComplete]({% slug templates_autocomplete %}#toc-header-template)
 * - [Using `HeaderTemplate` with the ComboBox]({% slug templates_combobox %}#toc-header-template)
 * - [Using `HeaderTemplate` with the MultiColumnComboBox]({% slug templates_multicolumncombobox %}#toc-header-template)
 * - [Using `HeaderTemplate` with the DropDownList]({% slug templates_ddl %}#toc-header-template)
 * - [Using `HeaderTemplate` with the DropDownTree]({% slug templates_ddt %}#toc-header-template)
 * - [Using `HeaderTemplate` with the MultiSelect]({% slug templates_multiselect %}#toc-header-template)
 *
 * @example
 * ```ts
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-combobox [data]="listItems">
 *    <ng-template kendoComboBoxHeaderTemplate>
 *      <h4>Header template</h4>
 *    </ng-template>
 *  </kendo-combobox>
 * `
 * })
 * class AppComponent {
 *   public listItems: Array<string> = ["Item 1", "Item 2", "Item 3", "Item 4"];
 * }
 * ```
 */
var HeaderTemplateDirective = /** @class */ (function () {
    function HeaderTemplateDirective(templateRef) {
        this.templateRef = templateRef;
    }
    HeaderTemplateDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoDropDownListHeaderTemplate],[kendoComboBoxHeaderTemplate],[kendoDropDownTreeHeaderTemplate],[kendoMultiColumnComboBoxHeaderTemplate],[kendoAutoCompleteHeaderTemplate],[kendoMultiSelectHeaderTemplate],[kendoMultiSelectTreeHeaderTemplate]'
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.TemplateRef])
    ], HeaderTemplateDirective);
    return HeaderTemplateDirective;
}());
exports.HeaderTemplateDirective = HeaderTemplateDirective;
