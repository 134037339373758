//import angular from 'angular';
import * as _ from 'underscore';
import FileManagement from '../../../services/fileManagement';
import BrowserSvc from '../../../services/browserSvc';
import OcDateSvc, { DateUnits } from '../../../services/ocDateSvc';
import OcConfigSvc from '../../../services/ocConfigSvc';
import tmpl from './statusChange.html';



export type TStatusType = {
    id?: string,
    name: string,
    description?: string,
    components?: Array<any>,
    destinationStatuses?: Array<any>,

    listDisplay: TStatusTypeListDisplay,
    componentDisplay: TStatusTypeComponentDisplay,
};

export type TStatusTypeListDisplay = {
    movedFrom?: boolean,
    returnToSupplierReason?: boolean,
    damageReason?: boolean,
    repairer?: boolean,
    retreader?: boolean,
    ndtRepairer?: boolean,
    supplier?: boolean,
    percentWorn?: boolean,
    hoursSinceLastNdt?: boolean,
    daysSinceLastNdt?: boolean,
    totalHours?: boolean,
    destination?: boolean,
    credit?: boolean,
    totalDays?: boolean
};

export type TStatusTypeComponentDisplay = {
    totalHours?: boolean,
    totalDistance?: boolean,
    totalDays?: boolean,
    percentWorn?: boolean,

    damageCause?: boolean,
    damageLocation?: boolean,
    damageSource?: boolean,
    instant?: boolean,
    tyreBurst?: boolean,
    tyreExplosion?: boolean,

    repairer?: boolean,
    repairType?: boolean,

    hoursSinceLastNdt?: boolean,
    daysSinceLastNdt?: boolean,
    ndtRepairer?: boolean,

    destination?: boolean,

    supplier?: boolean,
    returnToSupplierReason?: boolean,

    creditAmount?: boolean,

    retreader?: boolean
};

export type TStatusChange = {
    id?: guid,
    equipmentId: guid,
    componentType: string,
    componentTypeId: guid,
    date?: Date,
    searchDescription: string,
    serialNumber: string,

    equipmentStatusIdFrom: guid,
    statusTypeIdFrom: guid,

    destinationStatusType?: any,

    // transfer
    destination?: any,
    destinationOther?: string

    // repair
    repairer?: any,
    repairerOther?: string,
    repairType?: any,

    // damage
    damageCause?: any,
    damageLocation?: any,
    damageSource?: any,
    instant?: boolean,
    tyreBurst?: boolean,
    tyreExplosion?: boolean,

    // return to supplier
    returnToSupplierReason?: any,
    returnToSupplierReasonOther?: string,
    supplier?: any,
    supplierOther?: string,

    // retread
    retreader?: any,
    retreaderOther?: string,

    creditAmount?: any,
    mwClaim?: boolean,
    consignmentNumber?: string,

    comments?: string,
    attachments: IEquipmentEventAttachment[]
};

export default class StatusChangeCtrl implements ng.IController {

    form: ng.IFormController;

    processing: boolean;

    step: number = 1;

    saveDisabled: boolean = true;

    // pending status changes
    statusChanges: Array<TStatusChange>;

    // pending status change selected from the dropdown
    selectedPendingStatusChange: TStatusChange;

    // reference data
    componentTypes: Array<any>;
    allowedStatusTypesFrom: Array<TStatusType> = new Array<TStatusType>();
    statusTypesFrom: Array<TStatusType>;
    statusFlows: Array<any>;
    repairers: Array<any>;
    retreaders: Array<any>;
    damageCauses: Array<any>;
    damageLocations: Array<any>;
    damageSources: Array<any>;
    repairTypes: Array<any>;
    destinations: Array<any>;
    suppliers: Array<any>;
    returnToSupplierReasons: Array<any>;
    currencies: Array<any>;

    filteredDamageLocations: Array<any>;
    filteredDamageSources: Array<any>;

    // selections
    selectedComponentType: any = null;
    selectedStatusTypeFrom: TStatusType = null;
    selectedComponent: any = null;
    selectedComponentId: string = null;

    statusChangeDetails: TStatusChange = null;

    componentsControl: any = {};

    showSiteSerialNumbers: any = {};

    acceptedFileTypes: string[];

    static $inject = ['$scope', '$state', 'dataBroker', 'ocConfigSvc', 'confirmSvc', 'enums', 'fileManagement', 'browserSvc',
        'amtXlatSvc', '$timeout', 'notifySvc', 'errorReporter', 'amtConstants', 'ocDateSvc', 'WindowFactory', '$window'];

    constructor(
        private $scope: ng.IScope, private $state: ng.ui.IStateService, private dataBroker: IDataBroker, private ocConfigSvc: OcConfigSvc,
        private confirmSvc: IConfirmSvc, private enums: any, private fileManagement: FileManagement, private browserSvc: BrowserSvc,
        private amtXlatSvc: IAmtXlatSvc, private $timeout: ng.ITimeoutService, private notifySvc: INotifySvc, private errorReporter: IErrorReporter,
        private amtConstants: IAmtConstants, private ocDateSvc: OcDateSvc, private WindowFactory: IWindowFactory, private $window: ng.IWindowService) {
    
        try {
            this.dataBroker.checkRefData();
        } catch {
            this.$state.go('mobile.landing');
        }
    }

    async $onInit() {

        this.showSiteSerialNumbers[EquipmentTypeName.tyre] = this.ocConfigSvc.user.site.settings.tyreMaintenance.siteSerialNumber.tyre !== ConfigurationOption.hide;
        this.showSiteSerialNumbers[EquipmentTypeName.rim] = this.ocConfigSvc.user.site.settings.tyreMaintenance.siteSerialNumber.rim !== ConfigurationOption.hide;
        this.showSiteSerialNumbers[EquipmentTypeName.chain] = this.ocConfigSvc.user.site.settings.tyreMaintenance.siteSerialNumber.chain !== ConfigurationOption.hide;

        this.acceptedFileTypes = this.fileManagement.getAcceptedFileExtensions([FileType.document, FileType.pdf, FileType.text, FileType.spreadsheet, FileType.image]);

        // inspection
        this.allowedStatusTypesFrom.push({
            name: this.enums.statusTypes.inspection,
            listDisplay: {
                damageReason: true,
                totalHours: true,
                percentWorn: true
            },
            componentDisplay: {
                totalHours: true,
                totalDistance: true,
                percentWorn: true,
                damageCause: true,
                damageLocation: true
            }
        });

        // awaiting dispatch - repair
        this.allowedStatusTypesFrom.push({
            name: this.enums.statusTypes.awaitingDispatchRepair,
            listDisplay: {
                damageReason: true,
                repairer: true,
                totalHours: true,
                percentWorn: true,
            },
            componentDisplay: {
                repairer: true,
                repairType: true,
                damageCause: true,
                damageLocation: true,
                damageSource: true,
                instant: true,
                tyreBurst: true,
                tyreExplosion: true
            }
        });

        // awaiting dispatch - return to supplier
        this.allowedStatusTypesFrom.push({
            name: this.enums.statusTypes.awaitingDispatchReturnToSupplier,
            listDisplay: {
                movedFrom: true,
                returnToSupplierReason: true,
                supplier: true
            },
            componentDisplay: {
                supplier: true,
                returnToSupplierReason: true,
                creditAmount: true
            }
        });

        // awaiting dispatch - transfer
        this.allowedStatusTypesFrom.push({
            name: this.enums.statusTypes.awaitingDispatchTransfer,
            listDisplay: {
                totalHours: true,
                percentWorn: true,
                destination: true
            },
            componentDisplay: {
                destination: true,
                creditAmount: true
            }
        });

        // awaiting dispatch - off-site ndt
        this.allowedStatusTypesFrom.push({
            name: this.enums.statusTypes.awaitingDispatchOffSiteNdt,
            listDisplay: {
                totalHours: true,
                hoursSinceLastNdt: true,
                daysSinceLastNdt: true,
                ndtRepairer: true
            },
            componentDisplay: {
                totalHours: true,
                totalDays: true,
                hoursSinceLastNdt: true,
                daysSinceLastNdt: true,
                ndtRepairer: true
            }
        });

        // awaiting dispatch - retread
        this.allowedStatusTypesFrom.push({
            name: this.enums.statusTypes.awaitingDispatchRetread,
            listDisplay: {
                damageReason: true,
                retreader: true,
                totalHours: true,
                percentWorn: true,
            },
            componentDisplay: {
                retreader: true,
                damageCause: true,
                damageLocation: true,
                instant: true,
                tyreBurst: true,
                tyreExplosion: true
            }
        });

        this.statusChanges = await this.dataBroker.getStatusChanges();

        // selection of a component
        this.$scope.$watch(() => this.selectedComponentId, (newVal) => {
            if (newVal) this.selectComponent();
        });

        // enable/disable the save button on the status change details screen
        this.$scope.$watchGroup([() => this.form.$invalid, () => this.form.$pristine, () => this.processing], () => {
            this.saveDisabled = this.form.$invalid || this.form.$pristine || this.processing;
        });

        // selection of an existing status change from the dropdown
        this.$scope.$watch(() => this.selectedPendingStatusChange, (newVal) => {
            if (newVal) this.editStatusChange();
        });

        this.getComponentTypes();
    }

    // get the available component types (step 1)
    public async getComponentTypes() {
        let componentTypes = await this.dataBroker.getComponentTypes(this.ocConfigSvc.user.site.id);

        if (componentTypes) this.componentTypes = componentTypes.result;
    }

    // get the available status types the components can move from (step 2)
    public async getStatusTypesFrom() {

        let criteria = {
            equipmentTypeId: this.selectedComponentType.id,
            excludeNewLife: true
        };

        let statusFlows = await this.dataBroker.getStatusFlows(criteria);

        if (statusFlows) {

            this.statusTypesFrom = [];

            this.allowedStatusTypesFrom.forEach(s => {
                let status = _.find(statusFlows.result, f => f.statusTypeFromName === s.name);

                if (status) {

                    let st = angular.copy(s);

                    st.id = status.statusTypeIdFrom;
                    st.description = status.statusTypeFromDescription;

                    st.destinationStatuses = statusFlows.result.filter(f => f.statusTypeIdFrom === st.id);

                    this.statusTypesFrom.push(st);
                }
            });

            this.statusTypesFrom = _.sortBy(this.statusTypesFrom, 'description');

            await this.getComponentsInStatus();
        }
    }

    // get the available components in the statuses that can change status
    public async getComponentsInStatus(statusTypeId?: string) {

        // ignore components if there status has already been changed to something else and is pending
        let components = (await this.dataBroker.getSerials(this.selectedComponentType.name)).filter(c => !c.statusChanged);

        // get any pending status changes
        this.statusChanges = await this.dataBroker.getStatusChanges();

        // get components originally in the status (and if they have already been updated, grab the details)
        this.statusTypesFrom.forEach(s => {

            // for the specific status type, or all
            if (!statusTypeId || statusTypeId === s.id) {

                let statusComponents: Array<any> = components.filter(c => c.status.name === s.name);

                // grab components that have been moved into the status by a pending status change so they can be included in the component list
                let changedComponents: Array<any> = this.statusChanges
                    .filter(c => c.componentTypeId === this.selectedComponentType.id
                        && c.destinationStatusType.id === s.id);

                // if there are applicable changed components, need to retrieve the original component and update it
                if (changedComponents && changedComponents.length > 0) {

                    if (!statusComponents) statusComponents = [];

                    changedComponents.forEach(c => {
                        let component = angular.copy(components.find(cm => cm.id === c.equipmentId));

                        component.pendingStatusChange = this.statusChanges
                            .find(sc => sc.equipmentId === c.equipmentId && sc.statusTypeIdFrom == c.destinationStatusType.id);

                        component.status = {
                            name: c.destinationStatusType.name,
                            description: c.destinationStatusType.description,
                            dateFrom: c.date,
                            damageReason: c.damageCause ? {
                                damageCause: c.damageCause,
                                damageLocation: c.damageLocation,
                                damageSource: c.damageSource,
                                instant: c.instant,
                                tyreBurst: c.tyreBurst,
                                tyreExplosion: c.tyreExplosion
                            } : null,
                            repairDetails: c.repairType ? {
                                repairer: {
                                    id: c.repairer.requiresManualEntry ? null : c.repairer.id,
                                    name: c.repairerOther || c.repairer.name,
                                    description: c.repairerOther || c.repairer.description
                                },
                                repairType: c.repairType
                            } : null,
                            returnToSupplierDetails: c.returnToSupplierReason ? {
                                supplier: {
                                    id: c.supplier.requiresManualEntry ? null : c.supplier.id,
                                    name: c.supplierOther || c.supplier.name,
                                    description: c.supplierOther || c.supplier.description
                                },
                                returnToSupplierReason: c.returnToSupplierReason
                            } : null,
                            retreadDetails: c.retreader ? {
                                retreader: {
                                    id: c.retreader.requiresManualEntry ? null : c.retreader.id,
                                    name: c.retreaderOther || c.retreader.name,
                                    description: c.retreaderOther || c.retreader.description
                                }
                            } : null,
                            transferDetails: c.destination ? {
                                destination: {
                                    id: c.destination.requiresManualEntry ? null : c.destination.id,
                                    name: c.destinationOther || c.destination.name,
                                    description: c.destinationOther || c.destination.description
                                }
                            } : null,
                            costDetails: c.creditAmount ? {
                                currencyTypeId: c.creditAmount.currency.id,
                                cost: c.creditAmount.amount
                            } : null
                        };

                        statusComponents.push(component);
                    });
                }

                if (statusComponents && statusComponents.length > 0) {

                    if (this.statusChanges) {
                        statusComponents.forEach(c => {
                            if (!c.pendingStatusChange) {
                                let pendingStatusChange = this.statusChanges.find(sc => sc.id === c.status.id);

                                c.pendingStatusChange = pendingStatusChange;
                            }
                        });
                    };

                    s.components = statusComponents;
                };
            }
        });
    }

    // select the component type (step 1)
    public async selectComponentType(componentType: any, moveToNextStep: boolean = true) {

        // record the selected component type
        this.selectedComponentType = componentType;

        // get the status types from for the component type
        await this.getStatusTypesFrom();

        await this.getReferenceData();

        // move to step 2 (status type from)
        if (moveToNextStep) this.step = 2;
    }

    // select the status type the component is moving from (step 2)
    public async selectStatusTypeFrom(statusType: any, moveToNextStep: boolean = true) {

        // record the selected status type from
        this.selectedStatusTypeFrom = statusType;

        // make sure we don't show percent worn for rims
        if (this.selectedComponentType.name.toLowerCase() === this.enums.equipmentTypes.rim) {
            this.selectedStatusTypeFrom.listDisplay.percentWorn = false;
        }

        // flatten out some fields on the components for easy display
        this.mapComponents();

        // move to step 3 (components)
        if (moveToNextStep) {
            this.step = 3;

            this.$timeout(() => {
                if (this.componentsControl && this.componentsControl.refresh) {
                    this.componentsControl.refresh();
                }
            });
        }
    }

    // select the component that will have the status change (step 3)
    public selectComponent() {

        this.selectedComponent = this.selectedStatusTypeFrom.components.find(c => c.id === this.selectedComponentId);

        if (this.selectedComponent) {

            if (this.selectedComponent.pendingStatusChange && !this.selectedPendingStatusChange) {
                this.selectedPendingStatusChange = angular.copy(this.selectedComponent.pendingStatusChange);
            }

            this.statusChangeDetails = this.selectedComponent.pendingStatusChange ||
            {
                equipmentId: this.selectedComponent.id,
                serialNumber: this.selectedComponent.serialNumber,
                equipmentStatusIdFrom: this.selectedComponent.status.id,
                statusTypeIdFrom: this.selectedStatusTypeFrom.id,
                componentTypeId: this.selectedComponentType.id,
                componentType: this.selectedComponentType.name,
                searchDescription: String.format('{0} - {1} - {2}',
                    this.selectedComponent.serialNumber,
                    this.selectedComponentType.description,
                    this.selectedComponent.status.description
                ),
                attachments: []
            };

            // move to step 4 (status change details)
            this.step = 4;

            this.form.$setPristine();
        }
    }

    // select the status the component is going to move to
    public selectDestinationStatus(option: any) {

        // default values where applicable to those provided for the previous status change
        if (option.allowsRepairer) {

            if (!this.statusChangeDetails.repairer && this.selectedComponent.status.repairDetails) {

                if (this.selectedComponent.status.repairDetails.repairer.id) {
                    this.statusChangeDetails.repairer = this.repairers.find(r => r.key === this.selectedComponent.status.repairDetails.repairer.id);
                } else {
                    this.statusChangeDetails.repairer = this.repairers.find(r => r.requiresManualEntry);
                    this.statusChangeDetails.repairerOther = this.selectedComponent.status.repairDetails.repairer.description;
                }

                if (!this.statusChangeDetails.repairType && this.selectedComponent.status.repairDetails) {
                    this.statusChangeDetails.repairType = this.repairTypes.find(r => r.key === this.selectedComponent.status.repairDetails.repairType.id);
                }
            }

            if (!this.statusChangeDetails.repairer && this.selectedComponent.ndtRepairer) {

                if (this.selectedComponent.ndtRepairer.id) {
                    this.statusChangeDetails.repairer = this.repairers.find(r => r.key === this.selectedComponent.ndtRepairer.id);
                } else {
                    this.statusChangeDetails.repairer = this.repairers.find(r => r.requiresManualEntry);
                    this.statusChangeDetails.repairerOther = this.selectedComponent.ndtRepairer.description;
                }
            }

        } else {
            this.statusChangeDetails.repairer = null;
            this.statusChangeDetails.repairerOther = null;
            this.statusChangeDetails.repairType = null;
        }

        if (option.allowsRetreader) {

            if (!this.statusChangeDetails.retreader && this.selectedComponent.status.retreadDetails) {

                if (this.selectedComponent.status.retreadDetails.retreader.id) {
                    this.statusChangeDetails.retreader = this.retreaders.find(r => r.key === this.selectedComponent.status.retreadDetails.retreader.id);
                } else {
                    this.statusChangeDetails.retreader = this.retreaders.find(r => r.requiresManualEntry);
                    this.statusChangeDetails.retreaderOther = this.selectedComponent.status.retreadDetails.retreader.description;
                }

                if (!this.statusChangeDetails.repairType && this.selectedComponent.status.repairDetails) {
                    this.statusChangeDetails.repairType = this.repairTypes.find(r => r.key === this.selectedComponent.status.repairDetails.repairType.id);
                }
            }

        } else {
            this.statusChangeDetails.retreader = null;
            this.statusChangeDetails.retreaderOther = null;
        }

        if (option.requiresCredit || option.receivesCredit) {
            if (!this.statusChangeDetails.creditAmount && this.selectedComponent.status.costDetails) {
                this.statusChangeDetails.creditAmount = {
                    currency: this.currencies.find(r => r.key === this.selectedComponent.status.costDetails.currencyTypeId),
                    amount: this.selectedComponent.status.costDetails.cost
                };
            }
        } else {
            this.statusChangeDetails.creditAmount = null;
        }

        if (option.requiresDamageReason) {

            if (this.selectedComponent.status.damageReason) {

                if (!this.statusChangeDetails.damageCause) {
                    this.statusChangeDetails.damageCause = this.damageCauses.find(r => r.key === this.selectedComponent.status.damageReason.damageCause.id);

                    this.filteredDamageLocations = this.damageLocations.filter(l => l.damageCauseId === this.selectedComponent.status.damageReason.damageCause.id);
                    this.filteredDamageSources = this.damageSources.filter(l => l.damageCauseId === this.selectedComponent.status.damageReason.damageCause.id);
                }

                if (!this.statusChangeDetails.damageLocation && this.selectedComponent.status.damageReason.damageLocation) {
                    this.statusChangeDetails.damageLocation =
                        this.filteredDamageLocations.find(r => r.key === this.selectedComponent.status.damageReason.damageLocation.id);
                }

                if (!this.statusChangeDetails.damageSource && this.selectedComponent.status.damageReason.damageSource) {
                    this.statusChangeDetails.damageSource =
                        this.filteredDamageSources.find(r => r.key === this.selectedComponent.status.damageReason.damageSource.id);
                }

                this.statusChangeDetails.instant = this.selectedComponent.status.damageReason.instant;
                this.statusChangeDetails.tyreBurst = this.selectedComponent.status.damageReason.tyreBurst;
                this.statusChangeDetails.tyreExplosion = this.selectedComponent.status.damageReason.tyreExplosion;
            }

        } else {
            this.statusChangeDetails.damageCause = null;
            this.statusChangeDetails.damageLocation = null;
        }

        if (option.requiresDestination) {

            if (!this.statusChangeDetails.destination && this.selectedComponent.status.transferDetails) {

                if (this.selectedComponent.status.transferDetails.destination.id) {
                    this.statusChangeDetails.destination = this.destinations.find(r => r.key === this.selectedComponent.status.transferDetails.destination.id);
                } else {
                    this.statusChangeDetails.destination = this.destinations.find(r => r.requiresManualEntry);
                    this.statusChangeDetails.destinationOther = this.selectedComponent.status.transferDetails.destination.description;
                }
            }

        } else {
            this.statusChangeDetails.destination = null;
            this.statusChangeDetails.destinationOther = null;
        }

        if (option.allowsReturnToSupplierReason || option.allowsSupplier) {

            if (this.selectedComponent.status.returnToSupplierDetails) {

                if (!this.statusChangeDetails.returnToSupplierReason) {

                    if (this.selectedComponent.status.returnToSupplierDetails.returnToSupplierReason.id) {

                        this.statusChangeDetails.returnToSupplierReason = this.returnToSupplierReasons
                            .find(r => r.key === this.selectedComponent.status.returnToSupplierDetails.returnToSupplierReason.id);

                    } else {
                        this.statusChangeDetails.returnToSupplierReason = this.returnToSupplierReasons.find(r => r.requiresManualEntry);
                        this.statusChangeDetails.returnToSupplierReasonOther = this.selectedComponent.status.returnToSupplierDetails.returnToSupplierReason.description;
                    }
                }

                if (!this.statusChangeDetails.supplier) {

                    if (this.selectedComponent.status.returnToSupplierDetails.supplier.id) {

                        this.statusChangeDetails.supplier = this.suppliers
                            .find(r => r.key === this.selectedComponent.status.returnToSupplierDetails.supplier.id);

                    } else {
                        this.statusChangeDetails.supplier = this.suppliers.find(r => r.requiresManualEntry);
                        this.statusChangeDetails.supplierOther = this.selectedComponent.status.returnToSupplierDetails.supplier.description;
                    }
                }
            }

        } else {
            this.statusChangeDetails.supplier = null;
            this.statusChangeDetails.supplierOther = null;
            this.statusChangeDetails.returnToSupplierReason = null;
            this.statusChangeDetails.returnToSupplierReasonOther = null;
        }

        if (!option.isDispatch) {
            this.statusChangeDetails.consignmentNumber = null;
        }
    }

    // filter damage locations and sources when changing damage cause
    public selectDamageCause(option: any) {
        if (option) {
            this.filteredDamageLocations = this.damageLocations.filter(l => l.damageCauseId === option.id);

            if (this.statusChangeDetails.damageLocation) {
                let matchingLocation = this.filteredDamageLocations.find(l => l.id === this.statusChangeDetails.damageLocation.id);

                if (!matchingLocation) this.statusChangeDetails.damageLocation = null;
            }

            this.filteredDamageSources = this.damageSources.filter(l => l.damageCauseId === option.id);

            if (this.statusChangeDetails.damageSource) {
                let matchingSource = this.filteredDamageSources.find(l => l.id === this.statusChangeDetails.damageSource.id);

                if (!matchingSource) this.statusChangeDetails.damageSource = null;
            }
        }
    }

    public selectRepairer(option: any) {
        if (!option || option.requiresManualEntry === false) {
            this.statusChangeDetails.repairerOther = null;
        }
    }

    public selectReturnToSupplierReason(option: any) {
        if (!option || option.requiresManualEntry === false) {
            this.statusChangeDetails.returnToSupplierReasonOther = null;
        }
    }

    public selectSupplier(option: any) {
        if (!option || option.requiresManualEntry === false) {
            this.statusChangeDetails.supplierOther = null;
        }
    }

    public selectRetreader(option: any) {
        if (!option || option.requiresManualEntry === false) {
            this.statusChangeDetails.retreaderOther = null;
        }
    }

    // get the reference data if it hasn't already been populated
    private async getReferenceData() {

        let repairerCriteria: IRepairerCriteria = {
            siteId: this.ocConfigSvc.user.site.id,
            includeOther: true,
            equipmentTypeIds: [this.selectedComponentType.id]
        };

        this.repairers = (await this.dataBroker.getRepairers(repairerCriteria)).result;

        let retreaderCriteria: IRetreaderCriteria = {
            siteId: this.ocConfigSvc.user.site.id,
            includeOther: true,
            equipmentTypeIds: [this.selectedComponentType.id]
        };

        this.retreaders = (await this.dataBroker.getRetreaders(retreaderCriteria)).result;

        if (!this.returnToSupplierReasons) {

            let returnToSupplierReasonCriteria: IReturnToSupplierReasonCriteria = {
                siteId: this.ocConfigSvc.user.site.id,
                includeBlank: true
            };

            this.returnToSupplierReasons = (await this.dataBroker.getReturnToSupplierReasons(returnToSupplierReasonCriteria)).result;
        }

        let supplierCriteria: ISupplierCriteria = {
            siteId: this.ocConfigSvc.user.site.id,
            includeOther: true,
            equipmentTypeIds: [this.selectedComponentType.id]
        };

        this.suppliers = (await this.dataBroker.getSuppliers(supplierCriteria)).result;

        if (!this.repairTypes) {

            let repairTypeCriteria: IRepairTypeCriteria = {
                siteId: this.ocConfigSvc.user.site.id
            };

            this.repairTypes = (await this.dataBroker.getRepairTypes(repairTypeCriteria)).result;
        }

        let damageCauseCriteria: IDamageCauseCriteria = {
            componentTypeId: this.selectedComponentType.id
        };

        this.damageCauses = (await this.dataBroker.getDamageCauses(damageCauseCriteria)).result;

        let damageLocationCriteria: IDamageLocationCriteria = {
            componentTypeId: this.selectedComponentType.id
        };

        this.damageLocations = (await this.dataBroker.getDamageLocations(damageLocationCriteria)).result;

        if (this.damageLocations && this.statusChangeDetails && this.statusChangeDetails.damageCause) {
            this.filteredDamageLocations = this.damageLocations.filter(l => l.damageCauseId === this.statusChangeDetails.damageCause.id);
        }

        let damageSourceCriteria: IDamageSourceCriteria = {
            componentTypeId: this.selectedComponentType.id
        };

        this.damageSources = (await this.dataBroker.getDamageSources(damageSourceCriteria)).result;

        if (this.damageSources && this.statusChangeDetails && this.statusChangeDetails.damageCause) {
            this.filteredDamageSources = this.damageSources.filter(l => l.damageCauseId === this.statusChangeDetails.damageCause.id);
        }

        if (!this.currencies) {

            let currencyCriteria: ICurrencyCriteria = {
                siteId: this.ocConfigSvc.user.site.id
            };

            this.currencies = (await this.dataBroker.getCurrencyTypes(currencyCriteria)).result;
        }

        if (!this.destinations) {

            this.destinations = this.ocConfigSvc.user.availableSites
                .filter(s => s.clientId == this.ocConfigSvc.user.client.id && s.key !== this.ocConfigSvc.user.site.id);

            this.destinations.unshift({
                key: this.amtConstants.emptyGuid,
                name: this.amtXlatSvc.xlat('common.other'),
                requiresManualEntry: true
            });
        }
    }

    public mapComponents() {

        if (this.selectedStatusTypeFrom && this.selectedStatusTypeFrom.components) {

            let components = this.selectedStatusTypeFrom.components;

            let date: Date = new Date();

            let yes_label: string = this.amtXlatSvc.xlat('common.yes_label');
            let no_label: string = this.amtXlatSvc.xlat('common.no_label');

            for (let i = 0; i < components.length; i++) {

                components[i].totalDays = components[i].firstReceiveDate ? this.ocDateSvc.getDifference(date, new Date(components[i].firstReceiveDate), DateUnits.days) : null;

                components[i].serialNumber = this.showSiteSerialNumbers[this.selectedComponentType.name] ?
                    (components[i].siteSerialNumber ? components[i].siteSerialNumber : components[i].manufacturerSerialNumber) :
                    components[i].manufacturerSerialNumber;

                components[i].previousStatusType = components[i].previousStatus ? components[i].previousStatus.description : null;

                components[i].repairer = components[i].status.repairDetails ? components[i].status.repairDetails.repairer.description : null;
                components[i].repairType = components[i].status.repairDetails ? components[i].status.repairDetails.repairType.description : null;

                components[i].damageCause = components[i].status.damageReason ? components[i].status.damageReason.damageCause.description : null;

                components[i].damageLocation = components[i].status.damageReason && components[i].status.damageReason.damageLocation
                    ? components[i].status.damageReason.damageLocation.description : null;

                components[i].retreader = components[i].status.retreadDetails ? components[i].status.retreadDetails.retreader.description : null;

                components[i].destination = components[i].status.transferDetails ? components[i].status.transferDetails.destination.description : null;

                components[i].instant = components[i].status.damageReason ?
                    (components[i].status.damageReason.instant ? yes_label : no_label) :
                    null;

                components[i].tyreBurst = components[i].status.damageReason ?
                    (components[i].status.damageReason.tyreBurst ? yes_label : no_label) :
                    null;

                components[i].tyreExplosion = components[i].status.damageReason ?
                    (components[i].status.damageReason.tyreExplosion ? yes_label : no_label) :
                    null;

                components[i].returnToSupplierReason = components[i].status.returnToSupplierDetails ? components[i].status.returnToSupplierDetails.returnToSupplierReason.description : null;
                components[i].supplier = components[i].status.returnToSupplierDetails ? components[i].status.returnToSupplierDetails.supplier.description : null;

                components[i].ndtRepairerName = components[i].ndtRepairer ? components[i].ndtRepairer.description : null;

                components[i].daysInStatus = this.ocDateSvc.getDifference(date, new Date(components[i].status.dateFrom), DateUnits.days);
                components[i].daysSinceLastNDT = components[i].lastNDT ? this.ocDateSvc.getDifference(date, new Date(components[i].lastNDT), DateUnits.days) : null;

                components[i].statusChanged = !!components[i].pendingStatusChange;

                components[i].creditAmount = components[i].status.costDetails ?
                    String.format('{0}{1}', this.getCurrencySymbol(components[i].status.costDetails.currencyTypeId), components[i].status.costDetails.cost)
                    : null;
            }

            this.selectedStatusTypeFrom.components = _.chain(components).sortBy('serialNumber').sortBy('statusChanged').value();
        }
    }

    private getCurrencySymbol(currencyId: string): string {
        let symbol: string;

        if (this.currencies) {
            let currency = this.currencies.find(c => c.id === currencyId);

            if (currency) symbol = currency.symbol;
        }

        return symbol;
    }

    // save the status change
    public async saveStatusChange() {

        try {
            this.processing = true;

            // find and delete any attachments that have been removed
            let attachmentsToDelete = []

            if (this.selectedPendingStatusChange) {
                for (let attachment of this.selectedPendingStatusChange.attachments || []) {
                    if (!this.statusChangeDetails.attachments || !this.statusChangeDetails.attachments.some(f => f.id === attachment.id)) {
                        attachmentsToDelete.push(attachment.id);
                    }
                }
            }

            await this.fileManagement.deleteFiles(attachmentsToDelete);

            for (let attachment of this.statusChangeDetails.attachments || [])
                attachment.source = AttachmentType.equipmentEventAttachment;

            // save new attachments
            await this.fileManagement.processFileUploads(this.statusChangeDetails.attachments);

            // change the component status
            await this.dataBroker.changeComponentStatus(this.statusChangeDetails);

            this.notifySvc.success(this.amtXlatSvc.xlat('statusChange.successfullySaved'));

            // reload the component lists for the status being moved from and the one being moved to
            await this.getComponentsInStatus(this.selectedStatusTypeFrom.id);
            await this.getComponentsInStatus(this.statusChangeDetails.destinationStatusType.id);

            await this.mapComponents();

            this.form.$setPristine();

            // go back to the components list
            this.$timeout(() => this.goBack());

        } catch (error) {
            this.errorReporter.logError(error);
        }

        this.processing = false;
    }

    // delete the current status change (when on step 4 status change details scree)
    public deleteCurrentEvent(statusChangeDetails: TStatusChange) {
        if (statusChangeDetails.id) {
            if (this.statusChanges.some(s => s.equipmentId === statusChangeDetails.equipmentId && s.date > statusChangeDetails.date)) {
                this.WindowFactory.alert('statusChange.futureStatusChangeExists_header', ['common.ok_label'], 'statusChange.futureStatusChangeExists_message');
            } else {
                this.deleteStatusChanges(this.statusChanges.filter(s => s.id === statusChangeDetails.id));
            }
        }
    }

    // delete all status changes moving from a specific status type
    public async deleteAllEventsForStatus(statusType: TStatusType) {

        let statusChanges = this.statusChanges.filter(s => statusType.id === s.statusTypeIdFrom);

        if (statusChanges.some(s => this.statusChanges.some(c => c.equipmentId === s.equipmentId && c.date > s.date))) {

            try {
                await this.confirmSvc.confirmMessage("statusChange.cannotDeleteAllStatusChanges_header", "statusChange.cannotDeleteAllStatusChanges_message");
            } catch (e) {
                return; // they cancelled the cancel
            }

            statusChanges = statusChanges.filter(s => !this.statusChanges.some(c => c.equipmentId === s.equipmentId && c.date > s.date));
        }

        this.deleteStatusChanges(statusChanges);
    }

    // delete all pending status changes on the device
    public deleteAllEvents() {
        this.deleteStatusChanges();
    }

    private async deleteStatusChanges(statusChanges?: Array<TStatusChange>) {

        try {
            this.processing = true;

            if (!statusChanges) {
                statusChanges = this.statusChanges;
            }

            if (statusChanges) {

                let statusChangesToDelete = statusChanges.map(s => {
                    return {
                        equipmentId: s.equipmentId,
                        eventId: s.id
                    }
                });

                // delete status changes
                await this.dataBroker.deletePendingStatusChanges(statusChangesToDelete);

                // delete any attachments
                let attachmentsToDelete = [];

                for (let statusChange of statusChanges) {
                    for (let attachment of statusChange.attachments || []) {
                        attachmentsToDelete.push(attachment.id);
                    }
                }

                await this.fileManagement.deleteFiles(attachmentsToDelete);
            }

            this.notifySvc.success(this.amtXlatSvc.xlat('statusChange.successfullyDeleted'));

            this.selectedPendingStatusChange = null;

            this.statusChanges = await this.dataBroker.getStatusChanges();

            if (this.step >= 3) {

                await this.getComponentsInStatus(this.selectedStatusTypeFrom.id);
                await this.mapComponents();

                if (this.step === 3) {
                    this.$timeout(() => {
                        if (this.componentsControl && this.componentsControl.refresh) {
                            this.componentsControl.refresh();
                        }
                    });
                }

                // if this was on the status change details screen go back to the component list
                if (this.step === 4) {
                    this.form.$setPristine();
                    this.$timeout(() => this.goBack());
                }
            }

        } catch (error) {
            this.errorReporter.logError(error);
        }

        this.processing = false;
    }

    // on selecting an existing pending status change from the drop down
    public async editStatusChange() {

        if (this.selectedPendingStatusChange && !(this.statusChangeDetails && this.statusChangeDetails.id === this.selectedPendingStatusChange.id)) {

            if (await this.confirmDiscardChanges()) {

                // run through the steps and set all of the values, ending with the selection of the component
                // and loading of the status change details screen
                let componentType = this.componentTypes.find(c => c.id === this.selectedPendingStatusChange.componentTypeId);

                await this.selectComponentType(componentType, false);

                let statusTypeFrom = this.statusTypesFrom.find(s => s.id === this.selectedPendingStatusChange.statusTypeIdFrom);

                await this.selectStatusTypeFrom(statusTypeFrom, false);

                this.selectedComponentId = this.selectedPendingStatusChange.equipmentId;

                await this.getReferenceData();

            } else {
                this.selectedPendingStatusChange = null;
            }
        }
    }

    // if on the status change details screen and there are unsaved changes, confirm if they really want to leave
    private async confirmDiscardChanges(): Promise<boolean> {
        try {
            if (this.step === 4 && this.form.$dirty) {
                await this.confirmSvc.confirmMessage("statusChange.confirmCloseHeader", "statusChange.confirmCloseMessage");
            }
            return true;
        } catch {
            return false; //they cancelled
        }
    }

    // move back to the previous step
    public async goBack() {

        switch (this.step) {
            case 4:

                if (await this.confirmDiscardChanges()) {

                    this.selectedComponent = null;
                    this.selectedComponentId = null;
                    this.statusChangeDetails = null;
                    this.selectedPendingStatusChange = null;

                    this.step--;

                    this.$timeout(() => {
                        if (this.componentsControl && this.componentsControl.refresh) {
                            this.componentsControl.refresh();
                        }
                    }, 300);
                }
                break;

            case 3:
                this.selectedStatusTypeFrom = null;
                this.step--;
                break;

            case 2:
                this.selectedComponentType = null;
                this.statusTypesFrom = null;
                this.step--;
                break;
        };
    }

    // return to the mobile home screen
    public async returnToHome() {
        if (await this.confirmDiscardChanges()) {
            this.$state.go("mobile.landing");
        }
    }
}

class StatusChangeComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '='
    };
    public template = tmpl;
    public controller = StatusChangeCtrl;
    public controllerAs = 'vm';
}

angular.module('app').component('statusChange', new StatusChangeComponent());