//import angular from 'angular';
import FileManagement from '../../../services/fileManagement';
import DbClass from '../../common/local-db';
import BrowserSvc from '../../../services/browserSvc';
import tmpl from './fieldSurveyDeleteAll.html';



    angular.module("app.fieldSurveys").component('fieldSurveyDeleteAll', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['WindowFactory', '$scope', 'errorReporter', '$timeout', '$db', 'amtXlatSvc', 'enums', 'dataBroker', 'fileManagement', 'browserSvc',
            function (WindowFactory: IWindowFactory, $scope, errorReporter, $timeout: ng.ITimeoutService, $db: DbClass, amtXlatSvc: IAmtXlatSvc, enums, dataBroker, fileManagement: FileManagement, browserSvc: BrowserSvc) {
                var vm = this;
                $scope.vm = this;

                vm.surveyGrid = {};
                vm.surveyList = [];
                vm.DeleteAllMsg = "";                
                vm.isMobile = browserSvc.isMobile;

                this.$onInit = function () {
                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: 'fieldSurvey.DeleteAll',
                            name: 'deleteButton',
                            click: 'deleteAll',
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: true,
                            value: 'common.cancel_label',
                            name: 'cancelButton',
                            click: 'cancel',
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        deleteButton: {
                            visible: true,
                            disabled: true
                        },
                        cancelButton: {
                            visible: true,
                            disabled: false
                        }
                    };

                    vm.buttonMethods = {
                        deleteAll: vm.deleteAll,
                        cancel: vm.cancel
                    };
                };

                // fetch all of the field surveys
                // mobile only 
                $db.fieldSurvey.orderBy("createdDate").toArray().then(function (surveys) {

                    if (surveys.length === 0) {
                        vm.DeleteAllMsg = amtXlatSvc.xlat('fieldSurvey.DeleteNone');
                    } else {
                        vm.DeleteAllMsg = amtXlatSvc.xlat('fieldSurvey.DeleteAllMsg', surveys.length);
                    }
                    vm.surveyList = surveys;
                    vm.gridOn = true;
                    $timeout();
                });

                vm.redoGrid = function () {
                    $timeout();
                };

                vm.cancel = function () {
                    vm.wnd.onDataChanged(false);
                    WindowFactory.closeWindow(vm.wnd);
                };

                vm.deleteAll = async function () {
                    vm.wnd.processing = true;

                    let surveyCount;

                    try {
                        //get a list of "files" to be deleted (VI image data captured through field surveys)
                        let fileIds = [];
                        let fieldSurveys = await $db.fieldSurvey.toArray(); //TODO: this should be in databroker? OMG the below mess is gross (sorry!)

                        surveyCount = fieldSurveys.length;

                        for (let fs of fieldSurveys) {
                            for (let pr of fs.positionReadings) {
                                for (let vi of pr.visualInspections || []) {
                                    for (let f of vi.files || []) {
                                        if (f.file) {
                                            fileIds.push(f.file);
                                        }
                                    }
                                }
                            }
                        }

                        await fileManagement.deleteFiles(fileIds);
                    }
                    catch (error) {
                        errorReporter.logError(error, "FieldSurvey-DeleteAll"); //continue after failure to delete VI files...
                    }

                    try {
                        await $db.fieldSurvey.clear();

                        console.log("deleted all field surveys");

                        // mobile audit logging
                        dataBroker.logAuditEntry('Field Survey', 'Delete All', String.format('{0} field survey(s) deleted', surveyCount));
                        
                        WindowFactory.closeWindow(vm.wnd);
                        vm.wnd.onDataChanged(true);
                    } catch (error) {
                        errorReporter.logError(error, "FieldSurvey-DeleteAll");
                        vm.wnd.processing = false;
                    }
                };

                $scope.$watch('vm.surveyList', function () {
                    vm.buttonStates.deleteButton.disabled = vm.surveyList.length === 0;
                });
            }
        ]
    });
