<!--Main-->
<div class="widget-flex-box">
    <div class="widget-number-link">
        <a href="/#!/vehicle/maintenance" (click)="setSearchBy()"><h1><b>{{maintenanceSessionsInProgress}}</b></h1></a>
    </div>

    <div class="widget-number-unit">
        <p>{{ui.inProgress}}</p>
    </div>
</div>

<!--Subtext-->
<div class="widget-description" *ngIf="isIncludeSubtext" [ngClass]="isIncludeSubtext ? 'small-font' : ''">
    <h4><b>{{maintenanceSessionsPending}}</b> {{ui.description}}</h4>
</div>

