//import angular from 'angular';
import AdminUrls from '../adminUrls';
import tmpl from './componentOwnerDetails.html';




    angular.module('app.admin').component('componentOwnerDetails',
        {
            bindings: {
                initParams: '=',
                buttonMethods: '=',
                buttonStates: '=',
                buttons: '=',
                closeOnSave: '=',
                wnd: '='
            },
            template: tmpl,
            controller: [
                '$scope', 'componentOwnerSvc', 'WindowFactory', 'confirmSvc',
                'adminUrls', '$timeout', 'notifySvc', 'amtXlatSvc', 
                function (
                    $scope, componentOwnerSvc, WindowFactory: IWindowFactory, confirmSvc,
                    adminUrls: AdminUrls, $timeout, notifySvc, amtXlatSvc
                ) {
                    var vm = this;
                                       
                    $scope.vm = this; // necessary for child directives to access what they need.

                    vm.form = {};
                    vm.status = "loading";
                    vm.showSaveButton = true;
                    vm.adminUrls = adminUrls;

                    // assume we can edit the client, it might get disabled later if one of the sites has used the component owner
                    vm.canChangeClient = true;

                    // pass along dirty flag to the window for use on closing minimised windows
                    $scope.$watch('vm.form.$dirty', function () {
                        vm.wnd.isDirty = vm.form.$dirty;
                    });

                    vm.setReady = function () {
                        vm.status = 'ready';
                    };

                    this.$onInit = function () {
                        WindowFactory.addButton(
                            this,
                            'common.save_label', // caption
                            'saveAndClose',
                            function () {
                                vm.save('close');
                            },
                            true // primary
                        );

                        if (!vm.initParams.id) {
                            WindowFactory.addButton(
                                this,
                                'common.saveAndAddAnother_label', // caption
                                'saveAndNew',
                                function () {
                                    vm.save('new');
                                }
                            );
                        }

                        vm.wnd.onClose = $scope.onClose;

                        vm.loadDetails();
                    };

                    vm.loadDetails = function () {
                        vm.wnd.processing = true;

                        if (vm.initParams.id) {
                            // load existing
                            componentOwnerSvc.getAsync(vm.initParams.id, vm.initParams.systemHierarchySystemHierarchyRoleId).then(function (result) {
                                vm.item = result;

                                vm.setPristine();
                                vm.setReady();
                            }).finally(function () {
                                vm.wnd.processing = false;
                            });
                        } else {
                            // new
                            vm.item = vm.initParams;

                            vm.setPristine();
                            vm.setReady();
                            vm.wnd.processing = false;
                        }
                    };

                    vm.setPristine = function () {
                        $timeout(function () {
                            vm.form.$setPristine();
                        });
                    };

                    vm.saveSuccessHandler = function () {

                        if (vm.wnd.onDataChanged) {
                            vm.wnd.onDataChanged();
                        }

                        notifySvc.success(amtXlatSvc.xlat('componentOwner.save_success'));
                    };

                    vm.saveSuccessAndNewHandler = function () {
                        vm.saveSuccessHandler();

                        vm.item = componentOwnerSvc.reset();

                        vm.setPristine();
                    };

                    vm.saveSuccessAndCloseHandler = function () {
                        vm.saveSuccessHandler();

                        WindowFactory.closeWindow(vm.wnd);
                    };

                    vm.saveErrorsHandler = function (error) {
                        vm.error = error;

                        notifySvc.success(amtXlatSvc.xlat('componentOwner.save_error', error.message));
                    };

                    vm.save = function (successAction) {
                        vm.wnd.processing = true;
                        vm.error = undefined;

                        var successHandler = successAction == 'close' ? vm.saveSuccessAndCloseHandler : vm.saveSuccessAndNewHandler;

                        componentOwnerSvc.saveAsync({ item: vm.item }).then(function (response) {
                            successHandler();
                        }, vm.saveErrorHandler).finally(function () {
                            vm.wnd.processing = false;
                        });
                    };

                    function closeWindow() {
                        WindowFactory.closeWindow(vm.wnd);
                    }

                    $scope.onClose = function () {
                        confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                            vm.form.$dirty = false;
                            closeWindow();
                            return true;
                        });

                        return false;
                    };

                    $scope.$watch('vm.item.clientId', function (newValue) {
                        if (vm.item && vm.item.clientId) {
                            var criteria = {
                                clientId: vm.item.clientId,
                                systemHierarchySystemHierarchyRoleId: vm.item.systemHierarchySystemHierarchyRoleId
                            };

                            // assume we can edit the client
                            vm.canChangeClient = true;

                            componentOwnerSvc.getOwnerOptionsAsync(criteria).then(function (response) {
                                vm.siteOptions = response;

                                for (var opt = 0; opt < vm.siteOptions.length; opt++) {
                                    if (vm.siteOptions[opt].componentCount > 0) {
                                        vm.canChangeClient = false;
                                    }
                                }
                            });
                        }
                    });

                    $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing'], function () {
                        determineSaveButtonState();
                    });

                    $scope.$watch('vm.item.siteIds', function () {

                        vm.errors_HasComponents = 0;
                        vm.warnings_LastOwner = 0;

                        if (vm.item && vm.siteOptions) {
                            for (var o = 0; o < vm.siteOptions.length; o++) {
                                var referenceSite = vm.siteOptions[o];

                                referenceSite.errorHasComponents = false;
                                referenceSite.warningLastOwner = false;

                                if (referenceSite.active == true) {
                                    // site no remove from the list -- what does this comment mean?
                                    if (!vm.item.siteIds || vm.item.siteIds.length == 0 || vm.item.siteIds.indexOf(referenceSite.id) == -1) {
                                        // removed
                                        if (referenceSite.componentCount > 0) {
                                            // STOP... about the site having components
                                            referenceSite.errorHasComponents = true;
                                            vm.errors_HasComponents++;
                                        }

                                        if (referenceSite.minimumOwnerCount > referenceSite.otherOwnerCount) {
                                            // warning about removing the last owner from the site
                                            referenceSite.warningLastOwner = true;
                                            vm.warnings_LastOwner++;
                                        }
                                    }
                                }
                            }
                        }

                        determineSaveButtonState();
                    });

                    function determineSaveButtonState() {
                        vm.buttonStates.saveAndClose.disabled = vm.form.$pristine || vm.form.$invalid || vm.wnd.processing || vm.errors_HasComponents > 0;

                        if (vm.buttonStates.saveAndNew) {
                            vm.buttonStates.saveAndNew.disabled = vm.buttonStates.saveAndClose.disabled;
                        }
                    };
                }
            ]
        });
