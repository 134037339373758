/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var filter_service_1 = require("../filter.service");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var boolean_filter_component_1 = require("../boolean-filter.component");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var filter_radio_button_directive_1 = require("./filter-radio-button.directive");
var utils_1 = require("../../utils");
var menu_tabbing_service_1 = require("./menu-tabbing.service");
/**
 * Represents a Boolean-filter menu component.
 *
 * @example
 *  ```html-no-run
 *      <kendo-grid-column field="Discontinued" title="Discontinued">
 *          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
 *            <kendo-grid-boolean-filter-menu
 *                [column]="column"
 *                [filter]="filter"
 *                [filterService]="filterService"
 *                >
 *            </kendo-grid-boolean-filter-menu>
 *          </ng-template>
 *      </kendo-grid-column>
 *   ```
 */
var BooleanFilterMenuComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BooleanFilterMenuComponent, _super);
    function BooleanFilterMenuComponent(localization) {
        var _this = _super.call(this, null, localization) || this;
        /**
         * The current menu filter.
         * @type {CompositeFilterDescriptor}
         */
        _this.filter = { filters: [], logic: "and" };
        _this.idPrefix = kendo_angular_common_1.guid();
        return _this;
    }
    Object.defineProperty(BooleanFilterMenuComponent.prototype, "hostClasses", {
        /**
         * @hidden
         */
        get: function () {
            return false;
        },
        enumerable: true,
        configurable: true
    });
    BooleanFilterMenuComponent.prototype.ngAfterViewInit = function () {
        this.filterService.menuTabbingService.firstFocusable = this.radioButtons.first.radioButtonEl;
        this.radioButtons.first.radioButtonEl.focus();
    };
    /**
     * @hidden
     */
    BooleanFilterMenuComponent.prototype.radioId = function (value) {
        return this.idPrefix + "_" + value;
    };
    /**
     * @hidden
     */
    BooleanFilterMenuComponent.prototype.onChange = function (value, input) {
        this.applyFilter(this.updateFilter({
            field: this.column.field,
            operator: "eq",
            value: value
        }));
        this.filterService.menuTabbingService.firstFocusable = input;
    };
    /**
     * @hidden
     */
    BooleanFilterMenuComponent.prototype.isSelected = function (radioValue) {
        return this.filtersByField(this.column.field).some(function (_a) {
            var value = _a.value;
            return value === radioValue;
        });
    };
    /**
     * @hidden
     */
    BooleanFilterMenuComponent.prototype.onShiftTab = function (e) {
        if ((!this.menuTabbingService || !this.menuTabbingService.isColumnMenu) && this.filterService.menuTabbingService.lastFocusable) {
            e.preventDefault();
            this.filterService.menuTabbingService.lastFocusable.focus();
        }
    };
    Object.defineProperty(BooleanFilterMenuComponent.prototype, "columnLabel", {
        /**
         * @hidden
         */
        get: function () {
            var localizationMsg = this.localization.get('filterInputLabel') || '';
            var columnName = this.column.title || this.column.field;
            return utils_1.replaceMessagePlaceholder(localizationMsg, 'columnName', columnName);
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        core_1.HostBinding('class.k-filtercell'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], BooleanFilterMenuComponent.prototype, "hostClasses", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], BooleanFilterMenuComponent.prototype, "filter", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", filter_service_1.FilterService)
    ], BooleanFilterMenuComponent.prototype, "filterService", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", menu_tabbing_service_1.MenuTabbingService)
    ], BooleanFilterMenuComponent.prototype, "menuTabbingService", void 0);
    tslib_1.__decorate([
        core_1.ViewChildren(filter_radio_button_directive_1.BooleanFilterRadioButtonDirective),
        tslib_1.__metadata("design:type", core_1.QueryList)
    ], BooleanFilterMenuComponent.prototype, "radioButtons", void 0);
    BooleanFilterMenuComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-grid-boolean-filter-menu',
            template: "\n        <ul class=\"k-radio-list k-reset\">\n            <li *ngFor=\"let item of items\">\n                <input type=\"radio\"\n                    kendoFilterMenuRadioButton\n                    [columnLabel]=\"columnLabel\"\n                    #input\n                    [name]=\"idPrefix\"\n                    kendoRadioButton\n                    [checked]=\"isSelected(item.value)\"\n                    [attr.id]=\"radioId(item.value)\"\n                    (change)=\"onChange(item.value, input)\"\n                    (keydown.shift.tab)=\"onShiftTab($event)\"\n                />\n                <label class=\"k-radio-label\" [attr.for]=\"radioId(item.value)\">{{item.text}}</label>\n            </li>\n        </ul>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService])
    ], BooleanFilterMenuComponent);
    return BooleanFilterMenuComponent;
}(boolean_filter_component_1.BooleanFilterComponent));
exports.BooleanFilterMenuComponent = BooleanFilterMenuComponent;
