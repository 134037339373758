//import angular from 'angular';
import * as _ from 'underscore';
import BrowserSvc from '../../services/browserSvc';
import tmpl from '../../templates/login.html'


    angular.module('app').component('loginPage', {
        bindings: {
            app: '<'
        },
        template: tmpl,
        controller: [
            'amtXlatSvc', '$scope', '$location', 'dataBroker', 'errorReporter', 'WindowFactory', '$rootScope', '$state', 
             '$window', '$timeout', 'amtConstants', '$q', '$stateParams', 'ocConfigSvc', 'notifySvc', 'browserSvc',
            function (amtXlatSvc: IAmtXlatSvc, $scope: ng.IScope, $location, dataBroker, errorReporter, WindowFactory: IWindowFactory, $rootScope: IRootScope, $state,
                $window: ng.IWindowService, $timeout: ng.ITimeoutService, amtConstants, $q, $stateParams, ocConfigSvc, notifySvc, browserSvc: BrowserSvc) {

                var vm = this;

                vm.username = '';
                vm.password = '';
                vm.email = '';
                vm.newpassword = '';
                vm.confirmpassword = '';
                vm.window = $window;

                vm.focusName = false;
                vm.focusPassword = false;
                vm.focusEmail = false;
                vm.focusNewPassword = false;
                vm.focusConfirmPassword = false;

                vm.emailFormat = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

                vm.appVersion = window.appVersion;
                vm.currentDate = new Date();

                vm.sessionKey = $stateParams.sessionKey;
                vm.resetToken = $stateParams.resetToken;

                if (vm.sessionKey) {
                    vm.errorMessage = amtXlatSvc.xlat('login.ExternalLogin');
                }

                this.$onInit = function () {
                    if (browserSvc.shouldPromptForUnsupported() && !browserSvc.isSupported()) {
                        let msie: boolean = browserSvc.isInternetExplorer();

                        if (msie) {
                            $rootScope.processing = true; //we set this and never clear it to prevent them using Internet Explorer (styling isn't great on Mobile site)
                            $timeout(() => { $window.alert(amtXlatSvc.xlat('common.unsupportedBrowserIE_text')) }, 50);
                        }

                        let browserAlert = WindowFactory.alert(msie ? 'common.unsupportedBrowserIE' : 'common.unsupportedBrowser', ['common.ok_label'],
                                                               msie ? 'common.unsupportedBrowserIE_text' : 'common.unsupportedBrowser_text');
                        vm.wnd = browserAlert.windowHandle;
                        browserAlert.then(() => { vm.wnd = null });
                    }
                    browserSvc.shownAlertForUnsupported(true);

                    dataBroker.ETLHasError().then((response) => vm.setLoginButtonCSS(response));

                    // after control is ready.
                    if (vm.sessionKey) {
                        vm.loginExternal();
                    } else if ($rootScope.loggedIn) {
                        return dataBroker.getSession(undefined, ocConfigSvc.currentSiteId())
                            .then((response) => vm.processSession(response));
                    }
                };

                try {
                    var jsSession = localStorage.getItem(dataBroker.credentialDataKey)

                    if (jsSession) {
                        var credentials = JSON.parse(jsSession);

                        if (credentials && credentials.username) {
                            vm.username = credentials.username;

                            $timeout(() => {
                                vm.password = ""; // force the user to enter the password
                                vm.focusPassword = true;
                            });
                        }
                    }
                } catch (ex) {
                    // too bad, so sad.
                }

                vm.isMobile = browserSvc.isMobile;
                vm.sessionFail = amtXlatSvc.xlat('login.sessionFail');

                if (ocConfigSvc.loginMessage) {
                    vm.sessionKey = null; // just in case they log out with the wrong URL.
                    vm.errorMessage = ocConfigSvc.loginMessage;
                    ocConfigSvc.loginMessage = "";
                }

                if (ocConfigSvc.loginHeading) {
                    vm.loginTitle = ocConfigSvc.loginHeading;
                } else if (vm.resetToken) {
                    vm.loginTitle = amtXlatSvc.xlat('login.ResetEmailTitle'); // Bit of a crap name, saves us from making duplicate resources though
                    vm.errorMessage = amtXlatSvc.xlat('login.PasswordRules'); // TODO: This is shit, find a better option
                } else {
                    vm.loginTitle = vm.isMobile ? amtXlatSvc.xlat('login.LoginScreenTitleMobile') : amtXlatSvc.xlat('login.LoginScreenTitle');
                }

                vm.mobileText = vm.isMobile ? amtXlatSvc.xlat('login.Mobile') : '';
                vm.processing = false;
                
                /* as a hack to break autocompletion of password, we read-only the password field until needed. */
                vm.passwordInputReadonly = true;

                vm.reset = function() {
                    $('body').empty(); // your gone.
                    location.reload();
                };

                vm.windowClose = function() {
                    WindowFactory.closeWindow(vm.wnd);
                    vm.wnd = null;
                };

                vm.forgottenPassword = function() {
                    vm.wnd = WindowFactory.openItem({
                        component: 'forgotten-password',
                        caption: amtXlatSvc.xlat('login.ForgotPasswordTitle'),
                        initParams: {
                        },
                        width: 350,
                        modal: true,
                        canClose: false,
                        onDataChangeHandler: vm.windowClose,
                        excludeFromWindowContainer: true
                    });

                    vm.wnd.onClose = vm.windowClose;                    
                };

                vm.support = function() {
                    vm.wnd = WindowFactory.openItem({
                        component: 'support',
                        caption: amtXlatSvc.xlat('login.SupportTitle'),
                        initParams: {
                        },
                        width: 450,
                        modal: true,
                        onDataChangeHandler: vm.windowClose,
                        excludeFromWindowContainer: true
                    });

                    vm.wnd.onClose = vm.windowClose;
                };

                vm.mobile = function() {
                    if (!vm.isMobile) {
                        $rootScope.processing = true;
                        $window.location.href = appUrl + 'mobile';
                    }
                };

                vm.desktop = function() {
                    vm.errorMessage = "";
                    // jumping out of the SPA to the correct one
                    if (vm.isMobile) {
                        $rootScope.processing = true;
                        $window.location.href = appUrl;
                    }
                };

                vm.loginPage = function() {
                    vm.errorMessage = "";
                    vm.resetToken = false;
                };

                vm.loginExternal = function() {
                    $rootScope.processing = true; // this sets a page wide busy;

                    dataBroker.loginExternal(vm.sessionKey)
                        .then(function(response) {
                            vm.sessionKey = null;
                            vm.app.operation = response.data.Operation;
                            console.log('Logged in via external, now get session data');

                            var siteId = ocConfigSvc.user === undefined ? undefined : ocConfigSvc.user.site.id;

                            return dataBroker.getSession(undefined, siteId).then(function(response) {
                                return vm.processSession(response);
                            });
                        }).catch(function(why) {
                            vm.errorMessage = errorReporter.exceptionMessage(why);
                            vm.sessionKey = ""; // this will cause the login form for display.
                        }).finally(function() {
                            $rootScope.processing = false;
                            vm.processing = false;
                        });
                };

                vm.processSession = async function(response) {
                    if (response && response.status !== 'fail') {
                        console.log('Have session from online');

                        await ocConfigSvc.updateUser(response);

                        $rootScope.userName = vm.username;

                        let newSession = !$rootScope.loggedIn;
                        $rootScope.loggedIn = true;
                        $rootScope.loggingIn = false;
                        $rootScope.$broadcast('login', newSession);

                    } else {
                        console.warn('Failed to get a valid session!');
                        return $q.reject(vm.sessionFail);
                    }
                };

                vm.setLoginButtonCSS = async function (response) {
                    if (response && response.status !== 'fail') {
                        $rootScope.LoginButtonCSS = response.data == 'True' ? 'btn btn-loginETLError' : 'btn btn-login';
                    }
                }

                vm.login = function() {
                    vm.errorMessage = "";

                    if (vm.processing) {
                        console.log('Login already in progress');
                        return;
                    }

                    vm.processing = true;
                    vm.errorMessage = "";

                    $rootScope.processing = true; // this sets a page wide busy;
                    vm.enteringApp = false;

                    // this delay ensures the spinner does not just popup and dissapear and doen't look like nothings happened.
                    var delay = $timeout(function() {}, 2000);
                    console.log('Calling login');

                    var siteId = ocConfigSvc.currentSiteId();

                    dataBroker.login(vm.username.toLowerCase().trim(), vm.password, false, siteId).then(function() {
                        //logged in now get session
                        //UserSession
                        console.log('Logged in now get session');
                        return dataBroker.getSession(undefined, siteId).then(function(response) {

                            // no possible sites to login to
                            if (!response.availableSites || response.availableSites.length === 0) {
                                vm.processing = false;
                                vm.errorMessage = amtXlatSvc.xlat('login.noAvailableSites');
                                ocConfigSvc.loginMessage = vm.errorMessage;
                                return;
                            }

                            // no default site, or default site is not available to the user
                            if (!response.siteId || _.findIndex(response.availableSites, s => s.key == response.siteId) < 0) {
                                vm.processing = false;
                                vm.showChangeSite();
                                return;
                            }

                            ocConfigSvc.loginMessage = "";
                            vm.errorMessage = null;

                            return vm.processSession(response);
                        });
                    }).catch(function(why) {
                        console.log('Failed in Login Process');
                        console.log(why);
                        return delay // The delay may have already elapsed, just forcing a minimum time for visual affect
                            .then(function() {
                                console.log('In error block');

                                vm.focusPassword = true;
                                vm.processing = false; // we only stop processing on a reject
                                if (!angular.isArray(why) || why.length === 0) {
                                    vm.errorMessage = errorReporter.exceptionMessage(why);
                                    ocConfigSvc.loginMessage = errorReporter.exceptionMessage(why);
                                } else {
                                    // why is an array with stuff in it.
                                    var code = why[0];
                                    var idx = code.indexOf('|');
                                    if (idx != -1) {
                                        code = code.substring(0, idx);
                                    }

                                    let translationKey = 'login.' + code;
                                    let translatedMsg = amtXlatSvc.xlat(translationKey);

                                    vm.errorMessage = translatedMsg && translatedMsg !== translationKey ? translatedMsg : why[1];

                                    ocConfigSvc.loginMessage = vm.errorMessage;
                                    console.error(why[1]);
                                    if (why[0].indexOf('password_expired') > -1) {
                                        vm.resetToken = why[0].split('|')[1];
                                        vm.password = null;
                                        $rootScope.processing = true;

                                        $timeout($location.url(vm.isMobile ? '/mobile/landing' : '/landing'), 2000);                                               
                                    }
                                }
                            });
                    }).finally(function() {
                        // no longer busy;
                        if (!vm.enteringApp) {
                            $rootScope.processing = false;
                        }
                        vm.processing = false;
                        $timeout();
                    });

                };

                vm.completeLogin = function(session) {
                    ocConfigSvc.loginMessage = "";
                    vm.errorMessage = null;

                    return vm.processSession(session);
                };

                vm.showChangeSite = function() {
                    vm.wnd = WindowFactory.openItem({
                        component: 'change-site',
                        caption: amtXlatSvc.xlat('common.selectSite'),
                        width: 500,
                        canClose: false,
                        initParams: {
                            showCloseOnSave: false,
                            returnSession: true
                        },
                        modal: true,
                        onDataChangeHandler: vm.completeLogin,
                        excludeFromWindowContainer: true
                    });

                    vm.wnd.onClose = vm.windowClose;
                };

                // this is called by cahce.js on ApplicationCahce events to trigger a digest cycle.
                vm.update = function() {
                    $timeout();
                };

                vm.resetPassword = function() {
                    vm.email = vm.email.trim();

                    if (vm.processing) {
                        var error = amtXlatSvc.xlat('login.passwordResetInProgress');
                        console.log(error);
                        notifySvc.error(error);
                        return;
                    }

                    if (!vm.email) {
                        var error = amtXlatSvc.xlat('login.noEmail');
                        console.log(error);
                        notifySvc.error(error);
                        vm.focusEmail = true;
                        return;
                    }

                    if (!vm.newpassword) {
                        var error = amtXlatSvc.xlat('login.noPassword');
                        console.log(error);
                        notifySvc.error(error);
                        vm.focusNewPassword = true;
                        return;
                    }

                    if (!vm.confirmpassword || (vm.confirmpassword && vm.confirmpassword !== vm.newpassword)) {
                        var error = amtXlatSvc.xlat('login.noConfirmPassword');
                        console.log(error);
                        notifySvc.error(error);
                        vm.focusConfirmPassword = true;
                        return;
                    }

                    vm.processing = true;
                    vm.errorMessage = '';

                    $rootScope.processing = true; // This sets a page-wide busy.
                    vm.enteringApp = false;

                    // this delay ensures the spinner does not just popup and dissapear and doen't look like nothings happened.
                    var delay = $timeout(function() {}, 2000);
                    console.log('Calling reset');
                    dataBroker.resetPassword(vm.email, vm.newpassword, vm.resetToken)
                        .then(function(result) {
                            if (result.data.data.succeeded) {
                                vm.errorMessage = amtXlatSvc.xlat('login.passwordReset');

                                // Redirect to login page.
                                vm.resetToken = undefined;
                                $rootScope.processing = true;
                                $timeout($location.url('/landing'), 2000);
                            } else {
                                // Remain on the reset page, give the user a chance to fix their error.
                                if (result.data.data.errors) {
                                    vm.errorMessage = result.data.data.errors[0];
                                    notifySvc.error(result.data.data.errors[0]);
                                } else {
                                    vm.errorMessage = errorReporter.exceptionMessage(result);
                                    notifySvc.error(errorReporter.exceptionMessage(result));
                                }
                            }
                        })
                        .catch(function(why) {
                            return delay // The delay may have already elapsed, just forcing a minimum time for visual effect
                                .then(function() {
                                    vm.focusPassword = true;
                                    vm.errorMessage = errorReporter.exceptionMessage(why);
                                    notifySvc.error(errorReporter.exceptionMessage(why));
                                    vm.processing = false; // we only stop processing on a reject
                                });
                        }).finally(function() {
                            // no longer busy
                            if (!vm.enteringApp) {
                                $rootScope.processing = false;
                            }
                            vm.processing = false;
                        });
                };

                // update the app cache;
                try {
                    window.applicationCache.update();
                } catch (error) {
                    // this does not work in debug/iframe mode
                }

                console.log("Login Ctrl End.");
            }
        ]
    });
