    // <oc-data-sync-button> Contains all the logic to ensure the progress bar reflects the age of the data.
//import angular from 'angular';


    angular.module('app.directives').component("ocProgress",
         {
             bindings: {
                 progressPct: '<',
                 progressClass: '<'
             },
             transclude: true,
             template: '<div class="progress oc-progress">' +
                         '<div class="progress-bar" ng-class="$ctrl.progressClass" ng-style="{width: $ctrl.progressPct + \'%\', overflow: \'hidden\' } ">' +
                                '<div class="progress-text-box" >' +
                                    '<span>' + 
                                        '<ng-transclude></ng-transclude>' +
                                    '</span>' +
                               '</div>' +
                         '</div>' +
                 '</div>',
             controller: ["$scope", function ($scope) {
                 
                 var ctrl = this;

                 this.$onInit = function () {
                 };
             }]
         });    
