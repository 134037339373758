/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Specifies the adornments in the suffix container ([see example]({% slug textarea_adornments %})).
 * ```ts-no-run
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-textarea>
 *    <kendo-textarea-suffix>
 *        <button kendoButton look="clear" icon="image"></button>
 *    </kendo-textarea-suffix>
 *  </kendo-textarea>
 * `
 * })
 * class AppComponent {}
 * ```
 */
var TextAreaSuffixComponent = /** @class */ (function () {
    function TextAreaSuffixComponent() {
        this.hostClass = true;
    }
    tslib_1.__decorate([
        core_1.HostBinding('class.k-input-suffix'),
        tslib_1.__metadata("design:type", Boolean)
    ], TextAreaSuffixComponent.prototype, "hostClass", void 0);
    TextAreaSuffixComponent = tslib_1.__decorate([
        core_1.Component({
            exportAs: 'kendoTextAreaSuffix',
            selector: 'kendo-textarea-suffix',
            template: "<ng-content></ng-content>"
        })
    ], TextAreaSuffixComponent);
    return TextAreaSuffixComponent;
}());
exports.TextAreaSuffixComponent = TextAreaSuffixComponent;
