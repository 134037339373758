//import angular from 'angular';
import BrowserSvc from '../../../services/browserSvc';
import Cleave from 'cleave.js';



interface ocKeypadInputScope extends ng.IScope {
    active: boolean;
    maxDigits: number;
}

angular.module('app').directive('ocKeypadInput', ['$locale', 'browserSvc', '$timeout', '$parse',
    function ocKeypadInput($locale: ng.ILocaleService, browserSvc: BrowserSvc, $timeout: ng.ITimeoutService, $parse: ng.IParseService): ng.IDirective {
        return {
            restrict: 'A',
            require: 'ngModel',
            scope: {
                active: '<',
                maxDigits: '<maxdigits',
            },
            link: function ($scope: ocKeypadInputScope, $element: JQuery<HTMLInputElement>, attr: ng.IAttributes, ngModel: ng.INgModelController) {
                let readOnlyHack = browserSvc.isMobile && browserSvc.isiOS12orEarlier();

                attr.$set('inputmode', "none"); //prevent onscreen keyboard
                attr.$set('readonly', readOnlyHack); //iOS 12 and earlier ignore inputmode=node
                $element[0].tabIndex = -1; //can't tab to this element from a normal element

                let cleave = new Cleave($element[0], {
                    numeral: true,
                    numeralDecimalMark: $locale.NUMBER_FORMATS.DECIMAL_SEP,
                    delimiter: $locale.NUMBER_FORMATS.GROUP_SEP
                });

                function parserHack(input: string, alwaysUpdateView = false) : string {
                    let digitsOnly = input.replace(/\D/g, '');
                    let digitsTruncated = digitsOnly.slice(0, $scope.maxDigits);
                    cleave.setRawValue(digitsTruncated);

                    if (alwaysUpdateView || input.length != digitsOnly.length || digitsOnly.length != digitsTruncated.length) {
                        let formatted = cleave.getFormattedValue();
                        ngModel.$setViewValue(formatted);
                        ngModel.$commitViewValue();
                        ngModel.$render();
                    }

                    return digitsTruncated;
                }

                ngModel.$parsers.push(() =>
                    Number.parseInt(parserHack(cleave.getRawValue()))
                );

                ngModel.$formatters.push(val => {
                    cleave.setRawValue(val);
                    return cleave.getFormattedValue();
                });

                let unregisterKeypadKeyPressListener: () => void = null;
                let unregisterKeypadModifierPressListener: () => void = null;

                function focusElement() {
                    if (readOnlyHack)
                        return;

                    $timeout(function () {
                        let endPos = $element[0].value.length;
                        $element[0].focus();
                        $element[0].setSelectionRange(endPos, endPos);
                    }, 50);
                }

                function keypadHandler(key: string) {
                    if (key == "CLEAR") {
                        parserHack('', true);
                        return;
                    }

                    //ensure key is a digit
                    if (key.length > 1 || key < '0' || key > '9') {
                        if (key == "BACKSPACE") {
                                key = "";
                        } else
                            return;
                    }

                    let formatted: string = cleave.getFormattedValue();

                    //nonfocusable element cursor/selection is always assumed to be at the end
                    let rawElement = $element[0];
                    let selStart = readOnlyHack || rawElement.selectionStart == null ? formatted.length : rawElement.selectionStart;
                    let selEnd = readOnlyHack || rawElement.selectionEnd == null ? selStart : rawElement.selectionEnd;

                    //backspace removes one additional character, unless there is a selection
                    if (key.length == 0 && selStart == selEnd)
                        selStart--;

                    parserHack(formatted.slice(0, selStart) + key + formatted.slice(selEnd), true);

                    focusElement();
                }

                function unregisterListeners() {
                    if (unregisterKeypadKeyPressListener) {
                        unregisterKeypadKeyPressListener();
                        unregisterKeypadKeyPressListener = null;
                    }

                    if (unregisterKeypadModifierPressListener) {
                        unregisterKeypadModifierPressListener();
                        unregisterKeypadModifierPressListener = null;
                    }
                        
                }

                $scope.$watch('active', function (active: boolean) {
                    $element.toggleClass('keypad-focus', active);

                    unregisterListeners();

                    if (active) {
                        unregisterKeypadKeyPressListener = $scope.$on(Keypad.KEY_PRESSED, (evt, key: string) => keypadHandler(key));
                        unregisterKeypadModifierPressListener = $scope.$on(Keypad.MODIFIER_KEY_PRESSED, (evt, key: string) => keypadHandler(key));

                        focusElement();
                    }
                });

                $scope.$on('$destroy', function () {
                    unregisterListeners();
                    cleave.destroy();
                });
            }
        }
    }
]);
