import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LayoutRxjsService } from './rxjs/LayoutRxjs.service';
import { InitDashboardService } from './initDashboardHelper/InitialDashboard.service';
import { ReorderDashboardService } from './reorderHelper/ReorderDashboard.service';
import OcConfigSvc from '../../../../services/ocConfigSvc';

import { Dashboard, SiteDashboard, SiteDashboardResult, SiteDashboardWidgetApiTrigger, UserPosition } from './Interface/SiteDashboard.interface';
import { WidgetApiHelper } from './widgetHelper/WidgetApi.helper';

@Injectable({
    providedIn: 'root'
})

export class SiteDashboardApiService {
    private readonly getSiteStatusDashboardApiUrl = baseApiUrl + 'layout/siteStatusDashboard/getInitialSiteStatusDashboard';
    private readonly savePositionApiUrl = baseApiUrl + 'layout/siteStatusDashboard/saveUserWidgetLocation';

    constructor(private http: HttpClient, private rxjs: LayoutRxjsService, private initDashboard: InitDashboardService,
        private reorderService: ReorderDashboardService, private ocConfig: OcConfigSvc, private widgetApi: WidgetApiHelper) {
    }

    //set Init Dashboard setting by using API and passing the Init Position List to userPositonList API.
    async getInitDashboardSetting(tileSetting: any) {
        let siteDashboardApi = this.http.get<SiteDashboardResult>(this.getSiteStatusDashboardApiUrl)
            .subscribe(
                result => {
                    this.widgetApi.triggerSiteDashboardWidgetApiResult(result.siteDashboardWidgetApiCriteria);
                    let initalUserDashboardPosition = this.initDashboard.getInitPositionList(result.dashboard, tileSetting);

                    this.setUserPosition(initalUserDashboardPosition, result.dashboard, tileSetting, result.userSiteDashboardWidgets);
                    this.rxjs.setDashboardList(result.dashboard);
                    this.rxjs.setSiteDashboard(result.activeSiteDashboards);
                    this.rxjs.setSelectedDashboard(result.defaultSiteDashboard);
                    siteDashboardApi.unsubscribe();
        });
    }

    //check if the init dashboard widgets and user positon widgets
    setUserPosition(initalUserDashboardPosition: UserPosition[], initDashboardItemList: Dashboard[], tileSetting: any, userPosition: string) {
        //find a add or delete widget(s)
        let userPositionList = this.initDashboard.getInitUserPositionList(userPosition);
        let addList = this.initDashboard.addList(initalUserDashboardPosition, userPositionList);
        let deleteList = this.initDashboard.deleteList(initalUserDashboardPosition, userPositionList);
        //create condition monitoring
        let newUser = this.initDashboard.isNewUser(userPositionList);
        let isAdd = this.initDashboard.isModified(addList);
        let isDelete = this.initDashboard.isModified(deleteList);

        if (newUser) {
            this.createNewUserPositionList(initalUserDashboardPosition);
            this.rxjs.setUserPositionList(initalUserDashboardPosition);
        }
        else if (!newUser) {
            if (isAdd || isDelete) {
                // if the initial widget modified, update the changed
                userPositionList = this.initDashboard.deletePositionList(userPositionList, deleteList);
                let newWidgetList = this.initDashboard.setNewSiteDashboardWidgetLocation(initDashboardItemList, addList, userPositionList, tileSetting);
                let modifiedPositionList = this.initDashboard.addPositionList(userPositionList, newWidgetList);
                this.updateUserPosition(modifiedPositionList);
            }
            else {
                this.rxjs.setUserPositionList(userPositionList);
            }
        }
    }

    //create new user
    createNewUserPositionList(newPositionList: UserPosition[]) {
        this.saveUserPositon(newPositionList);
    }

    //update user Position
    updateUserPosition(newPositionList: UserPosition[]) {
        this.saveUserPositon(newPositionList);
        this.rxjs.setUserPositionList(newPositionList);
    }

    //save in the data base
    saveUserPositon(positionList: UserPosition[]) {
        let body = this.setCriteria(positionList);
        let header = this.setHeader();
        this.http.post(this.savePositionApiUrl, body, { headers: header }).subscribe(() => {});
    }
    
    //create body
    setCriteria(positionList: UserPosition[]) {
        let stringUserPositionList = JSON.stringify(positionList)
        let personId = this.ocConfig.user.personId;
        let siteId = this.ocConfig.currentSiteId();
        let criteria = { personId: personId, siteId: siteId, userPositionList: stringUserPositionList };
        return criteria;
    }

    //create header
    setHeader() {
        return { 'content-type': 'application/json' };
    }

}