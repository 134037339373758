import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LiveForecastDialogItem } from '../Interface/Widget.interface';

@Injectable({
    providedIn: 'root'
})

export class DialogRxjsService {
    defaultLiveForecastDialog: LiveForecastDialogItem = {
        action: false,
        siteDashboardWidgetId: ''
    }

    private HealthRateDialogService: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    healthRateDialog: Observable<boolean> = this.HealthRateDialogService.asObservable();

    private LiveForecastTyreDialogService: BehaviorSubject<LiveForecastDialogItem> = new BehaviorSubject<LiveForecastDialogItem>(this.defaultLiveForecastDialog);
    liveForecastTyreDialog: Observable<LiveForecastDialogItem> = this.LiveForecastTyreDialogService.asObservable();

    private LiveForecastRimDialogService: BehaviorSubject<LiveForecastDialogItem> = new BehaviorSubject<LiveForecastDialogItem>(this.defaultLiveForecastDialog);
    liveForecastRimDialog: Observable<LiveForecastDialogItem> = this.LiveForecastRimDialogService.asObservable();

    constructor() { }

    reset() {
        let action = false;
        this.HealthRateDialogService.next(action);
        this.LiveForecastTyreDialogService.next(this.defaultLiveForecastDialog);
        this.LiveForecastRimDialogService.next(this.defaultLiveForecastDialog);
    }

    setHealthRateDialogActionListener(action: boolean) {
        this.HealthRateDialogService.next(action);
    }

    setLiveForecastTyreDialogActionListener(action: LiveForecastDialogItem) {
        this.LiveForecastTyreDialogService.next(action);
    }

    setLiveForecastRimDialogActionListener(action: LiveForecastDialogItem) {
        this.LiveForecastRimDialogService.next(action);
    }
 
    //unsubscribe Rxjs
    completed() {
        this.HealthRateDialogService.complete();
        this.LiveForecastTyreDialogService.complete();
    }

}

