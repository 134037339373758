//import angular from 'angular';
import * as _ from 'underscore'


    angular.module('app').filter('defaultText', defaultTextFilter);

    // @ts-ignore
    defaultTextFilter.$inject = ["appConfig"];
    function defaultTextFilter(appConfig) {
        return function (inputString) {
            if (!_.isNumber(inputString) && _.isEmpty(inputString)) {
                return appConfig.defaultTextIfEmpty;
            }
            return inputString;
        };
    }
