/**
 * @ngdoc directive
 * @name amtFramework.filter.directive:amtStringFilteritem
 * @restrict E
 * @requires amtFramework.filter.directive:amtFilter
 * 
 * @param {string} label Label to display above the control
 * @param {string} propertyName Name of the property that is added to filterValues property of {@link amtFramework.filter.directive:amtFilter amtFilter}
 * @param {expression=} [isRequired=false] Required
 * @param {expression=} [isReadOnly=false] Readonly
 * @param {expression=} defaultValue Default value
 * @param {integer=} maxLength Max length
 * @param {string} type Type
 * @param {boolean=} negativeEditable ???
 * 
 * @description
 * Filter item that let's the user filter on a string
 * 
 **/
//import angular from 'angular';
import tmpl from './amtStringFilteritem.html';

angular.module('amtFramework.filter').directive('amtStringFilteritem', function () {
            return {
                require: '^amtFilter',
                restrict: 'E',
                scope: {
                    label: '@',
                    propertyName: "@",
                    isRequired: '=',
                    isReadOnly: '=?',
                    maxLength: '@',
                    defaultValue: '=?',
                    type: '@',
                    negativeEditable: '@'
                },
                template: tmpl,
                link: function (scope: any, elem, attr, filterCtrl) {
                    if (!scope.type) {
                        scope.type = "text";
                    }

                    switch (scope.type) {
                        case "number":
                            scope.className = "text-right";
                            scope.defaultValue = +scope.defaultValue;
                            break;
                        default:
                            break;
                    }

                    scope.filterItem = { propertyName: scope.propertyName, value: scope.defaultValue || '' };

                    var isNegativeEditable = scope.negativeEditable === "false" ? false : true;

                    scope.$watch('defaultValue', function (newValue) {
                        scope.filterItem.value = newValue;
                        var strNumber = scope.defaultValue + '';
                        if (isNegativeEditable) {
                            return;
                        }
                        if (strNumber.indexOf("-") > -1) {
                            scope.filterItem.value = Math.abs(scope.defaultValue);
                        }
                    });

                    filterCtrl.addFilterItem(scope.filterItem);
                }
            };
});
