import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import OcConfigSvc from './ocConfigSvc';


@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
    constructor(
        protected auth: AuthService,
        protected ocConfigSvc: OcConfigSvc
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //Will need to implement the Response and ResponseError functionality from authInterceptor.cs eventually...
        //See https://dev-academy.com/how-to-use-angular-interceptors-to-manage-http-requests/ for good examples of .pipe with catchError
        return next.handle(this.addAuthToken(request));
    }

    addAuthToken(request: HttpRequest<any>) {
        const bearer = this.auth.bearer;
        const user = this.ocConfigSvc.user;

        if (!bearer) {
            return request;
        }

        return request.clone({
            setHeaders: {
                Authorization: bearer,
                OcClientId: user?.client.id,
                OcSiteId: user?.site.id
            }
        });
    }
}