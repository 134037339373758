import { Injectable } from '@angular/core';
import { UserPosition } from '../Interface/SiteDashboard.interface';
import { ReorderHelper } from './Reorder.helper';
import { ReorderLocationHelper } from './ReorderLocation.helper';

@Injectable({
    providedIn: 'root'
})

export class ReorderDashboardService {

    constructor(private reorderHelper: ReorderHelper, private reorderLocationHelper: ReorderLocationHelper) {
    }


    //Sometimes, Kendo-Tile Layout api return float instead of int
    //Set value to int
    getIntValue(value: number) {
        return this.reorderHelper.getIntValue(value);
    }

    //Find a selectedWidget from dashboard widget by using col, row and selectedDashboardId
    getSelectedWidget(selectedDashboardId: string, positionList: UserPosition[], selectedCol: number, selectedRow: number) {
        return this.reorderHelper.findWidgetByLocation(selectedDashboardId, positionList, selectedCol, selectedRow);
    }

    //Get colSpan and rowSpan
    getSelectedWidgetSize(widget: UserPosition) {
        let size = this.reorderHelper.getWidgetSize(widget);
        return size;
    }

    //Get new selected widget location reference
    getSelectedNewWidgetReferenceByWidget(selectedWidget: UserPosition, newCol: number, newRow: number) {
        let newReference = this.reorderHelper.getNewWidgetReference(selectedWidget, newCol, newRow);
        return newReference;
    }

    //Get previous selected widget reference
    getSelectedOldWidgetReference(selectedWidget: UserPosition) {
        return selectedWidget.reference;
    }

    //Get new widget reference by using colSpan and rowSpan
    getNewWidgetReferenceBySpan(colSpan: number, rowSpan: number, newCol: number, newRow: number) {
        let newReference = this.reorderHelper.getNewWidgetReferenceBySpan(colSpan, rowSpan, newCol, newRow);
        return newReference;
    }

    //Check if the newReference is over the tileSetting to limit the widget movement
    isOverMaxCol(newRef: any, tileSetting: any) {
        let check = this.reorderHelper.checkMaxColOverNewRef(newRef, tileSetting);
        return check;
    }

    //Check if the new widget is overlapped with existing widgets in the list to limit the widget movement
    isEmptySpace(targetWidget: UserPosition[]) {
        let check = this.reorderHelper.checkEmpty(targetWidget);
        return check;
    }

    //Check if the target location has multiple different widgets to limit the widget movement
    isMultipleTargetWidgets(targetWidget: UserPosition[]) {
        let check = this.reorderHelper.checkMultipleWidgets(targetWidget);
        return check;
    }

    //Check if the selected widget size is bigger than target widget size to limit the widget movement
    isOverSize(selectedWidgetSize: number, targetWidgetSize: number) {
        return selectedWidgetSize < targetWidgetSize;
    }

    //Check if the selected widget size is equals to target widget size to limit the widget movement
    isSameSize(selectedWidgetSize: number, targetWidgetSize: number) {
        return selectedWidgetSize === targetWidgetSize;
    }

    //Check if the selected widget is already exisitng in the positionList to limit the widget movement
    isIncludeSelectedWidget(selectedDashboardId: string, selectedWidget: UserPosition, newReference: any, positionList: UserPosition[]) {
        let check = this.reorderHelper.checkIncluded(selectedDashboardId, selectedWidget, newReference, positionList);
        return check;
    }

    //Get target widget(s) from the position list by using newReference
    //Filter out the selected widget to avoid the duplicate
    getTargetWidgetList(selectedSiteDashboardId: string, positionList: UserPosition[], newReference: any, selectedWidget: UserPosition) {
        let targetWidgetList: UserPosition[] = [];
        positionList.forEach(widget => {
            if (widget.siteDashboardId === selectedSiteDashboardId) {
                widget.reference.forEach(existingRef => {
                    newReference.forEach(newRef => {
                        if (existingRef.col === newRef.col && existingRef.row === newRef.row) {
                            if (selectedWidget.siteDashboardWidgetId != widget.siteDashboardWidgetId) {
                                if (!targetWidgetList.includes(widget)) {
                                    targetWidgetList.push(widget);
                                }
                            }
                        }
                    });
                });
            }
        });
        return targetWidgetList;
    }

    //Get overall of the target widget size to compare the selected widget size
    getTargetWidgetSize(targetWidgetList: UserPosition[]) {
        let size = 0;
        targetWidgetList.forEach(target => {
            size = (target.colSpan * target.rowSpan) + size;
        });
        return size;
    }

    //Find changed widget position from new positionList 
    findChangedWidgets(newPositionList: any, selectedWidget: any, targetwidgets: any) {
        let updatedWidgets = [];

        newPositionList.forEach(widget => {
            targetwidgets.forEach(target => {
                if (widget.siteDashboardWidgetId === target.siteDashboardWidgetId) {
                    updatedWidgets.push(target);
                }
            });
            if (selectedWidget.siteDashboardWidgetId === widget.siteDashboardWidgetId) {
                updatedWidgets.push(selectedWidget);
            }
        });
        return updatedWidgets;
    }

    //Set selected widget to emptyLocation from position list
    moveToEmptyLocation(positionList: UserPosition[], selectedWidget: UserPosition, newRow: number, newCol: number, newReference: any) {
        let newPositionList: UserPosition[] = [];
        positionList.forEach(widget => {
            if (widget.siteDashboardWidgetId === selectedWidget.siteDashboardWidgetId) {
                widget.reference = newReference;
                widget.col = newCol;
                widget.row = newRow;
                newPositionList.push(widget);
            } else {
                newPositionList.push(widget);
            };
        });
        return newPositionList;
    }

    //Swap the same size widget position 
    swtichSingleSameSizeWidget(positionList: UserPosition[], targetWidgets: UserPosition[], selectedWidget: UserPosition) {
        let target = targetWidgets[0];
        let targetId = target.siteDashboardWidgetId;
        let targetCol = target.col;
        let targetRow = target.row;
        let targetRef = target.reference;
        let selected = selectedWidget;
        let selectedId = selected.siteDashboardWidgetId;
        let selectedCol = selected.col;
        let selectedRow = selected.row;
        let selectedRef = selected.reference;
        let newPositionList: UserPosition[] = [];

        positionList.forEach(widget => {

            if (widget.siteDashboardWidgetId === targetId) {
                widget.col = selectedCol;
                widget.row = selectedRow;
                widget.reference = selectedRef;
                newPositionList.push(widget);
            } else if (widget.siteDashboardWidgetId === selectedId) {
                widget.col = targetCol;
                widget.row = targetRow;
                widget.reference = targetRef;
                newPositionList.push(widget);
            } else {
                newPositionList.push(widget);
            }
        });

        return newPositionList;
    }

    moveSelectedWidgetToMultipleSpace(positionList: UserPosition[], targetWidgets: UserPosition[], selectedWidget: UserPosition, newRef: any, oldRef: any) {
        let deletedTargetList = this.reorderHelper.deletedWidgets(positionList, targetWidgets);
        let changedSelectedWidgetList = this.reorderHelper.changeSelectedWidgets(deletedTargetList, selectedWidget, newRef);
        let addTargetwidgetList = this.reorderHelper.addSwitechedTargetWidgets(changedSelectedWidgetList, targetWidgets, oldRef);
        return addTargetwidgetList;
    }

    moveFromSmallToBigWidget(positionList: any, targetWidgets: any, selectedWidget: any, newRef: any, tileSetting: any, selectedDashboardId: any) {
        let targetList = this.reorderLocationHelper.widgetSortbySmallToBig(targetWidgets);
        targetList = this.reorderHelper.deleteSelectedWidget(targetWidgets, selectedWidget);
        let deletedWidgetList = this.reorderHelper.deletedWidgetsWithSelectedWidget(positionList, targetList, selectedWidget);
        let addSelectedWidgetList = this.reorderHelper.addSelectedWidget(deletedWidgetList, selectedWidget, newRef);
        let availableList = this.reorderLocationHelper.getAvailableLocation(tileSetting, addSelectedWidgetList, selectedDashboardId);
        let newList = this.reorderHelper.addNewLocationTargetWidgets(addSelectedWidgetList, targetWidgets, availableList, tileSetting, selectedDashboardId);
        return newList;
    }

    moveFromBigWidgetToSmallWidget(positionList: UserPosition[], targetWidgets: UserPosition[], selectedWidget: UserPosition, newRef: any, tileSetting: any, selectedDashboardId: string) {
        let targetList = this.reorderLocationHelper.widgetSortbyBigToSmall(targetWidgets);
        targetList = this.reorderHelper.deleteSelectedWidget(targetWidgets, selectedWidget);
        let deletedWidgetList = this.reorderHelper.deletedWidgetsWithSelectedWidget(positionList, targetList, selectedWidget);
        let addSelectedWidgetList = this.reorderHelper.addSelectedWidget(deletedWidgetList, selectedWidget, newRef);
        let availableList = this.reorderLocationHelper.getAvailableLocation(tileSetting, addSelectedWidgetList, selectedDashboardId);
        let newList = this.reorderHelper.addNewLocationTargetWidgets(addSelectedWidgetList, targetWidgets, availableList, tileSetting, selectedDashboardId);
        return newList;
    }

}


