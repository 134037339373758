/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var utils_1 = require("../utils");
/**
 * @hidden
 *
 * Represents the CheckBox component of the Kendo UI TreeView for Angular.
 *
 */
var CheckBoxComponent = /** @class */ (function () {
    function CheckBoxComponent(element, renderer, changeDetector) {
        this.element = element;
        this.renderer = renderer;
        this.changeDetector = changeDetector;
        /**
         * Specifies the [`id`](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/id) of the component.
         */
        this.id = "_" + kendo_angular_common_1.guid();
        /**
         * Specifies the [`tabindex`](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex) of the component.
         */
        this.tabindex = 0;
        /**
         * Specifies the size of the component.
         */
        this.size = 'medium';
        /**
         * Fires when the user changes the check state of the component.
         */
        this.checkStateChange = new core_1.EventEmitter();
        this.checkState = 'none';
    }
    Object.defineProperty(CheckBoxComponent.prototype, "classWrapper", {
        //XXX: implement ComponentValueAccessor
        //XXX: focus/blur methods
        get: function () { return true; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CheckBoxComponent.prototype, "indeterminate", {
        get: function () {
            return this.checkState === 'indeterminate';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CheckBoxComponent.prototype, "checked", {
        get: function () {
            return this.checkState === 'checked';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CheckBoxComponent.prototype, "checkBoxClasses", {
        get: function () {
            return "k-checkbox " + (this.size ? utils_1.getSizeClass('checkbox', this.size) : '') + " k-rounded-md";
        },
        enumerable: true,
        configurable: true
    });
    CheckBoxComponent.prototype.ngOnInit = function () {
        this.renderer.removeAttribute(this.element.nativeElement, "tabindex");
    };
    CheckBoxComponent.prototype.ngDoCheck = function () {
        this.checkState = this.isChecked(this.node, this.index);
    };
    CheckBoxComponent.prototype.handleChange = function (e) {
        var state = e.target.checked ? 'checked' : 'none';
        // update the View State so that Angular updates the input if the isChecked value is the same
        this.checkState = state;
        this.changeDetector.detectChanges();
        this.checkStateChange.emit(state);
    };
    tslib_1.__decorate([
        core_1.HostBinding('class.k-checkbox-wrapper'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], CheckBoxComponent.prototype, "classWrapper", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], CheckBoxComponent.prototype, "id", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], CheckBoxComponent.prototype, "isChecked", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], CheckBoxComponent.prototype, "node", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], CheckBoxComponent.prototype, "index", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], CheckBoxComponent.prototype, "labelText", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], CheckBoxComponent.prototype, "tabindex", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], CheckBoxComponent.prototype, "size", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], CheckBoxComponent.prototype, "checkStateChange", void 0);
    CheckBoxComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-checkbox',
            template: "\n        <input\n            type=\"checkbox\"\n            [class]=\"checkBoxClasses\"\n            [id]=\"id\"\n            [checked]=\"checked\"\n            [indeterminate]=\"indeterminate\"\n            [tabindex]=\"tabindex\"\n            (change)=\"handleChange($event)\"\n        />\n        <label\n            class=\"k-checkbox-label\"\n            tabindex=\"-1\"\n            [for]=\"id\"\n        >{{labelText}}</label>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.ElementRef,
            core_1.Renderer2,
            core_1.ChangeDetectorRef])
    ], CheckBoxComponent);
    return CheckBoxComponent;
}());
exports.CheckBoxComponent = CheckBoxComponent;
