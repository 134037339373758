//import angular from 'angular';

    angular.module('app.directives')
        .directive('ocWindowList', ['WindowFactory', function (WindowFactory: IWindowFactory) {
            return {
                template: 
                    '<nav class="flex-container-column flex">' +
                    '   <div class= flex-none" ng-class="{\'window-list-container\': windowFactory.windows.length > 0}">' +
                    '       <ul class="nav window-list-row">' +
                    '           <li><a ng-if="(windowFactory.windows | filter: {modal: false} | filter: {visible: false}).length > 0" ng-click="windowFactory.closeAllMinimisedWindows();">{{\'common.closeAllMinimisedWindows\' | xlat}}</a></li>' +
                    '           <li><a ng-if="(windowFactory.windows | filter: {modal: false} | filter: {visible: true}).length > 0" ng-click="windowFactory.closeAllOpenWindows();">{{\'common.closeAllOpenWindows\' | xlat}}</a></li>' +
                    '           <li><a ng-if="windowFactory.windows.length > 0" ng-click="windowFactory.closeAllWindows();">{{\'common.closeAllWindows\' | xlat}}</a></li>' +
                    '      </ul>' +
                    '      <div id="windowList{{$id}}" ng-style="setWindowListOverflow()" class="flex-container-column flex">' +
                    '         <ul class="nav window-list-row" ng-repeat="wnd in windowFactory.windows | filter: {modal: false } | filter: {visible: false}">' +
                    '            <li class="window-list-col">' +
                    '                <a class="window-list-title" title="{{wnd.caption}}" ng-class="{\'window-list-title-dirty\': wnd.isDirty}" ng-click="windowFactory.focusWindow(wnd)">' +
                    '                    {{wnd.shortCaption}}' +
                    '                </a>' +
                    '                <a class="window-list-button" ng-click="windowFactory.closeMinimisedWindow(wnd)"><span class="glyphicon glyphicon-remove"></span></a>' +
                    '            </li>'+
                    '         </ul>'+
                    '      </div>'+
                    '   </div>'+
                    '</nav>',
                controller: ["$scope",function ($scope) {
                    $scope.windowFactory = WindowFactory;                 
                }],
                link: function (scope: any) {

                    scope.setWindowListOverflow = function () {

                        var list = document.getElementById('windowList' + scope.$id);

                        var minimisedWindowCount = scope.windowFactory.windows.filter(w => !w.modal && !w.visible).length;

                        if (list && minimisedWindowCount >= 10) { // overflow on more than 10 windows
                            return {
                                'overflow-y': 'auto',
                                'overflow-x': 'hidden',
                                'max-height': list.offsetHeight
                            };
                        }
                    }
                }
            };
        }]);
