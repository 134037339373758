import { Component, OnDestroy, OnInit } from '@angular/core';
import { TileLayoutReorderEvent } from "@progress/kendo-angular-layout";
import { ReorderDashboardService } from './service/reorderHelper/ReorderDashboard.service';
import { SiteDashboardApiService } from './service/SiteDashboardApi.service';
import { LayoutRxjsService } from './service/rxjs/LayoutRxjs.service';
import { WidgetRxjsService } from './service/rxjs/widgetRxjs.service';
import { DialogRxjsService } from './service/rxjs/DialogRxjs.service';
import { Dashboard, UserPosition } from './service/Interface/SiteDashboard.interface';
import { Subscription } from 'rxjs';

@Component({
    selector: 'site-dashboard',
    templateUrl: './site-dashboard.component.html',
    styleUrls: ['./site-dashboard.component.scss']
})

export class SiteDashboardComponent implements OnInit, OnDestroy {
    public dashboardList: Dashboard[];
    public positionList: UserPosition[];
    public selectedDashboard: any;
    public upTrigger = '';
    public mode: boolean;
    public isShowHealthRateDialog: boolean = false;
    public isShowLiveForecastTyreDialog: boolean = false;
    public isShowLiveForecastRimDialog: boolean = false;
    public tileSetting = {
        col: 5,
        row: 15,
        reorder: true,
        flow: "column",
        height: "0.5fr"
    };

    public positionSubscription: Subscription;
    public dashboardSubscription: Subscription;
    public selectedDashboardSubscription: Subscription;

    constructor(private rxjs: LayoutRxjsService, private siteDashboardApi: SiteDashboardApiService,
        private reorderService: ReorderDashboardService, private widgetRxjs: WidgetRxjsService, private dialogRxjs: DialogRxjsService) {
        this.dialogRxjs.reset();
    }

    ngOnInit() {
        this.subscribePositionListener();
        this.subscribeDialogListener();
        this.siteDashboardApi.getInitDashboardSetting(this.tileSetting);
    }

    ngOnDestroy() {
        this.positionSubscription.unsubscribe();
        this.selectedDashboardSubscription.unsubscribe();
        this.dashboardSubscription.unsubscribe();
        this.widgetRxjs.completed();
    }

    subscribeDialogListener() {
        this.dialogRxjs.healthRateDialog.subscribe(action => {
            this.isShowHealthRateDialog = action;
            this.isShowLiveForecastTyreDialog = false;
            this.isShowLiveForecastRimDialog = false;
        });
        this.dialogRxjs.liveForecastTyreDialog.subscribe(element => {
            this.isShowLiveForecastTyreDialog = element.action;
            this.isShowLiveForecastRimDialog = false;
            this.isShowHealthRateDialog = false;
        });
        this.dialogRxjs.liveForecastRimDialog.subscribe(element => {
            this.isShowLiveForecastRimDialog = element.action;
            this.isShowLiveForecastTyreDialog = false;
            this.isShowHealthRateDialog = false;
        });
    }

    subscribePositionListener() {
        this.positionSubscription = this.rxjs.userPositionList.subscribe(pos => {
            this.positionList = pos;
        });
        this.dashboardSubscription = this.rxjs.dashboardList.subscribe(dashboard => {
            this.dashboardList = dashboard;
        });
        this.selectedDashboardSubscription = this.rxjs.selectedDashboard.subscribe(selected => {
            this.selectedDashboard = selected;
            setTimeout(_ => {
                this.upTrigger = this.selectedDashboard.id;
            }, 100);
        });
    }

    onReorder(e: TileLayoutReorderEvent) {
        e.preventDefault();
        let selectedDashboardId = this.selectedDashboard.id;
        let newRow = this.reorderService.getIntValue(e.newRow);
        let oldRow = this.reorderService.getIntValue(e.oldRow);
        let newCol = this.reorderService.getIntValue(e.newCol);
        let oldCol = this.reorderService.getIntValue(e.oldCol);
        //find widget(s)
        let selectedWidget = this.reorderService.getSelectedWidget(selectedDashboardId, this.positionList, oldCol, oldRow);
        //let selectedWidgetOldReference = this.reorderService.getSelectedOldWidgetReference(selectedWidget);
        let selectedWidgetSize = this.reorderService.getSelectedWidgetSize(selectedWidget);
        let selectedWidgetNewReference = this.reorderService.getSelectedNewWidgetReferenceByWidget(selectedWidget, newCol, newRow);
        let targetWidgets = this.reorderService.getTargetWidgetList(selectedDashboardId, this.positionList, selectedWidgetNewReference, selectedWidget);
        let targetWidgetSize = this.reorderService.getTargetWidgetSize(targetWidgets)

        //condition montioring
        let isMaxCol = this.reorderService.isOverMaxCol(selectedWidgetNewReference, this.tileSetting);
        let isInclude = this.reorderService.isIncludeSelectedWidget(selectedDashboardId, selectedWidget, selectedWidgetNewReference, this.positionList);
        let isEmpty = this.reorderService.isEmptySpace(targetWidgets);
        let isMultiple = this.reorderService.isMultipleTargetWidgets(targetWidgets);
        let isOverSize = this.reorderService.isOverSize(selectedWidgetSize, targetWidgetSize);
        let isSameSize = this.reorderService.isSameSize(selectedWidgetSize, targetWidgetSize);

        if (!isMaxCol) {
            if (isEmpty && !isOverSize) {
                let newPositionList = this.reorderService.moveToEmptyLocation(this.positionList, selectedWidget, newRow, newCol, selectedWidgetNewReference);
                this.siteDashboardApi.updateUserPosition(newPositionList);
            }
            else if (!isEmpty && !isOverSize) {
                if (!isMultiple && isSameSize) {
                    let newPositionList = this.reorderService.swtichSingleSameSizeWidget(this.positionList, targetWidgets, selectedWidget);
                    this.siteDashboardApi.updateUserPosition(newPositionList);
                }
                else if ((!isInclude && isMultiple) || (!isMultiple && !isInclude && !isSameSize)) {
                    let newPositionList = this.reorderService.moveFromBigWidgetToSmallWidget(this.positionList, targetWidgets, selectedWidget, selectedWidgetNewReference, this.tileSetting, selectedDashboardId);
                    this.siteDashboardApi.updateUserPosition(newPositionList);
                }
                else if ((isInclude && isMultiple && !isSameSize) || (isInclude && !isMultiple && !isSameSize) || (isMultiple && !isOverSize && isInclude)) {
                    let newPositionList = this.reorderService.moveFromSmallToBigWidget(this.positionList, targetWidgets, selectedWidget, selectedWidgetNewReference, this.tileSetting, selectedDashboardId);
                    this.siteDashboardApi.updateUserPosition(newPositionList);
                }
            }
            else if (!isEmpty && isOverSize) {
                let newPositionList = this.reorderService.moveFromSmallToBigWidget(this.positionList, targetWidgets, selectedWidget, selectedWidgetNewReference, this.tileSetting, selectedDashboardId);
                this.siteDashboardApi.updateUserPosition(newPositionList);
            }
        }
    }
}

