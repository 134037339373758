//import angular from 'angular';
const moduleName = 'app.admin';
export default moduleName;
import tmplUsersSearch from "./usersecurity/UsersSearch.html";
import tmplUserMergePopu from "./usersecurity/mergeuser/UserMergePopup.html";
import tmplRolesPermissions from "./systemSettings/system/rolesPermissions/rolesPermissions.html";
import tmplComponentOwnerSearch from "./componentOwner/componentOwnerSearch.html";
import tmplLocationSearch from "./locations/locationSearch.html";
import tmplSysMaintActionSearch from "./systemSettings/maintenance/action/search.html";
import tmplSysMaintDamageCause from "./systemSettings/maintenance/damageCause/search.html";
import tmplSysMaintDamageLocation from "./systemSettings/maintenance/damageLocation/search.html";
import tmplSysMaintDamageSource from "./systemSettings/maintenance/damageSource/search.html";
import tmplSysMaintDelayReason from "./systemSettings/maintenance/delayReasons/search.html";
import tmplSysMaintRemovalReason from "./systemSettings/maintenance/removalReason/search.html";
import tmplSysOrgClientSearch from "./systemSettings/organisation/client/sysOrgClientSearch.html";
import tmplSysOrgCommoditySearch from "./systemSettings/organisation/commodity/search.html";
import tmplFieldSurveyFrequency from "./fieldsurveys/fieldSurveyFrequency.html";
import tmplCopyRolesFromUserPopup from "./usersecurity/userRoles/CopyRolesFromUserPopup.html";
import tmplFleets from "./fleets/search.html";
import tmplBackupStockLevels from "./backupStockLevels/search.html";
import tmplVehicleTyreStrategy from "./vehicleTyreStrategy/vehicleTyreStrategy.html";
import tmplPerformanceBatchSearch from "./performanceBatch/search.html";
import tmplPerformanceBatchDetails from "./performanceBatch/details.html";
import tmplAlertsTriggers from "./notifications/alertsTriggers.html";
import tmplProductionCrewsSearch from "./productionCrews/search.html";
import tmplSensorsSearch from "./sensors/search.html";
import tmplComponentSpecificationsSearch from "./specifications/components/search.html";
import tmplVehicleSpecificationsSearch from "./specifications/vehicles/vehicleSpecificationSearch.html";
import tmplSiteSearch from "./systemSettings/organisation/site/search.html";
import tmplSysOrgOperationTypeSearch from "./systemSettings/organisation/operationType/search.html";
import tmplCurrencySearch from "./systemSettings/organisation/currency/search.html";
import tmplSizeSearch from "./systemSettings/component/size/search.html";
import tmplPatternSearch from "./systemSettings/component/pattern/search.html";
import tmplCompoundSearch from "./systemSettings/component/compound/search.html";
import tmplPlyRatingSearch from "./systemSettings/component/plyRating/search.html";
import tmplReportIntegrationSearch from "./systemSettings/system/reportIntegration/search.html";
import tmplTraCodeSearch from "./systemSettings/component/traCode/search.html";
import tmplVehicleApplicationSearch from "./systemSettings/vehicle/application/search.html";
import tmplResourceSearch from "./systemSettings/system/resource/search.html";
import tmplCompanySearch from "./systemSettings/organisation/company/search.html";
import tmplSystemAlerts from "./systemSettings/system/alerts/systemAlerts.html";


// note: url must be all lowercase or it redirects you to the landing page
angular.module(moduleName, [])
        .config([
            "$stateProvider",
            function ($stateProvider: ng.ui.IStateProvider) {

                $stateProvider
                    .state("app.admin.users", {
                        url: "/users",
                        template: tmplUsersSearch,
                        controller: "usersSearchCtrl",
                        controllerAs: "vm"
                    })
                    .state("app.admin.users.merge", {
                        url: "/merge",
                        template: tmplUserMergePopu,
                        controller: "userMergePopupCtrl",
                        controllerAs: "vm"
                    })
                    .state("app.admin.sysSysRolesPermissions", {
                        url: "/system/rolespermissions",
                        template: tmplRolesPermissions,
                        controller: "rolesPermissionsCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.admin.componentowner", {
                        url: "/componentowner",
                        template: tmplComponentOwnerSearch,
                        controller: "componentOwnerSearchCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.admin.locations", {
                        url: "/locations",
                        template: tmplLocationSearch,
                        controller: "locationSearchCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.admin.sysMaintAction", {
                        url: "/maintenance/action",
                        component: "sysMaintActionSearchCtrl"
                    })

                    .state("app.admin.sysMaintDamageCause", {
                        url: "/maintenance/damagecause",
                        template: tmplSysMaintDamageCause,
                        controller: "sysMaintDamageCauseSearchCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.admin.sysMaintDamageLocation", {
                        url: "/maintenance/damagelocation",
                        template: tmplSysMaintDamageLocation,
                        controller: "sysMaintDamageLocationSearchCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.admin.sysMaintDamageSource", {
                        url: "/maintenance/damagesource",
                        template: tmplSysMaintDamageSource,
                        controller: "sysMaintDamageSourceSearchCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.admin.sysMaintDelayReason", {
                        url: "/maintenance/delayreason",
                        template: tmplSysMaintDelayReason,
                        controller: "sysMaintDelayReasonSearchCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.admin.sysMaintRemovalReason", {
                        url: "/maintenance/removalreason",
                        template: tmplSysMaintRemovalReason,
                        controller: "sysMaintRemovalReasonSearchCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.admin.sysOrgClient", {
                        url: "/organisation/clients",
                        template: tmplSysOrgClientSearch,
                        controller: "sysOrgClientSearchCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.admin.sysOrgCommodity", {
                        url: "/organisation/commodity",
                        template: tmplSysOrgCommoditySearch,
                        controller: "sysOrgCommoditySearchCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.admin.fsFrequency", {
                        url: "/fsfrequency",
                        template: tmplFieldSurveyFrequency,
                        controller: "fieldSurveyFrequencyCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.admin.security.rolestab.copyrolesfromuser", {
                        url: "/copyrolesfromuser",
                        params: {
                            targetpersonid: ""
                        },
                        template: tmplCopyRolesFromUserPopup,
                        controller: "copyRolesFromUserPopupCtrl",
                        controllerAs: "vm"
                    })
                    .state("app.admin.stockCodes", {
                        url: "/stockcodes",
                        component: "stockCodeSearch"
                    })
                    .state("app.admin.fleets", {
                        url: "/fleets",
                        template: tmplFleets,
                        controller: "fleetsSearchCtrl",
                        controllerAs: "vm"
                    })
                    .state("app.admin.backupStockLevels", {
                        url: "/backupstocklevels",
                        template: tmplBackupStockLevels,
                        controller: "backupStockLevelsSearchCtrl",
                        controllerAs: "vm"
                    })
                    .state('app.admin.newTyrePositionDefaults', {
                        url: '/newtyrepositiondefaults',
                        template: tmplVehicleTyreStrategy,
                        controller: "vehicleTyreStrategyCtrl",
                        controllerAs: "vm"
                    })
                    .state("app.admin.performanceBatches", {
                        url: "/performancebatch",
                        template: tmplPerformanceBatchSearch,
                        controller: "performanceBatchSearchCtrl",
                        controllerAs: "vm"
                    })
                    .state("app.admin.performanceBatches.popupDetails", {
                        url: "/details",
                        template: tmplPerformanceBatchDetails,
                        params: {
                            batchId: "",
                            siteId: "",
                            isEdit: ""
                        },
                        controller: "performanceBatchDetailsCtrl",
                        controllerAs: "vm"
                    })
                    .state("app.admin.alerts", {
                        url: "/alerts",
                        template: tmplAlertsTriggers,
                        controller: "alertsTriggersCtrl",
                        controllerAs: "vm"
                    })
                    .state('app.admin.siteDashboardConfiguration', {
                        url: '/sitedashboard',
                        params: { initParams: null },
                        resolve: { initParams: ['$transition$', $transition$ => $transition$.params().initParams] },
                        component: 'siteDashboardConfiguration'
                    })
                    .state('app.admin.siteDetails', {
                        url: '/sitedetails',
                        params: { initParams: null },
                        resolve: { initParams: ['$transition$', $transition$ => $transition$.params().initParams] },
                        component: 'siteDetails'
                    })
                    .state("app.admin.productionCrews", {
                        url: "/productioncrews",
                        template: tmplProductionCrewsSearch,
                        controller: "productionCrewsSearchCtrl",
                        controllerAs: "vm"
                    })
                    .state("app.admin.sensors", {
                        url: "/sensors",
                        template: tmplSensorsSearch,
                        controller: "sensorsSearchCtrl",
                        controllerAs: "vm"
                    })
                    .state("app.admin.specificationsComponents", {
                        url: "/componentspecifications",
                        template: tmplComponentSpecificationsSearch,
                        controller: "componentSpecificationsSearchCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.admin.specificationsVehicles", {
                        url: "/vehiclespecifications",
                        template: tmplVehicleSpecificationsSearch,
                        controller: "vehicleSpecificationsSearchCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.admin.sysOrgSite", {
                        url: "/organisation/sites",
                        template: tmplSiteSearch,
                        controller: "siteSearchCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.admin.sysOrgOperationType", {
                        url: "/organisation/operationtype",
                        template: tmplSysOrgOperationTypeSearch,
                        controller: "sysOrgOperationTypeSearchCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.admin.sysOrgCurrency", {
                        url: "/organisation/currency",
                        template: tmplCurrencySearch,
                        controller: "currencySearchCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.admin.sysCompSize", {
                        url: "/component/sizes",
                        template: tmplSizeSearch,
                        controller: "sizeSearchCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.admin.sysCompPattern", {
                        url: "/component/patterns",
                        template: tmplPatternSearch,
                        controller: "patternSearchCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.admin.sysCompCompound", {
                        url: "/component/compounds",
                        template: tmplCompoundSearch,
                        controller: "compoundSearchCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.admin.sysCompPlyRating", {
                        url: "/component/plyratings",
                        template: tmplPlyRatingSearch,
                        controller: "plyRatingSearchCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.admin.sysSysReportIntegration", {
                        url: "/system/reportintegrations",
                        template: tmplReportIntegrationSearch,
                        controller: "reportIntegrationSearchCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.admin.sysCompTRACode", {
                        url: "/component/tracodes",
                        template: tmplTraCodeSearch,
                        controller: "traCodeSearchCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.admin.sysVehApplication", {
                        url: "/vehicle/applications",
                        template: tmplVehicleApplicationSearch,
                        controller: "vehicleApplicationSearchCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.admin.sysSysResource", {
                        url: "/system/resources",
                        template: tmplResourceSearch,
                        controller: "resourceSearchCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.admin.sysOrgCompany", {
                        url: "/organisation/companies",
                        template: tmplCompanySearch,
                        controller: "companySearchCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.admin.sysSystemAlerts", {
                        url: "/system/alerts",
                        template: tmplSystemAlerts,
                        controller: "systemAlertsCtrl",
                        controllerAs: "vm"
                    })

                    ;
            }
        ]);
