//import angular from 'angular';


angular.module('app.fieldSurveys')
        .service('pitsAndAuditService', ['baseModuleService', function (BaseModuleService) {
            var vm = this;
            var internalName = "pitsAndAudit";
            vm.details = new BaseModuleService(internalName);

            return vm.details;
        }]);
