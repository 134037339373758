/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var navigation_service_1 = require("./navigation/navigation.service");
var selection_service_1 = require("./selection/selection.service");
var default_callbacks_1 = require("./default-callbacks");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
/**
 * @hidden
 *
 * A directive which manages the expanded state of the TreeView.
 */
var TreeViewItemContentDirective = /** @class */ (function () {
    function TreeViewItemContentDirective(element, navigationService, selectionService, renderer) {
        var _this = this;
        this.element = element;
        this.navigationService = navigationService;
        this.selectionService = selectionService;
        this.renderer = renderer;
        this.initialSelection = false;
        this.isSelected = default_callbacks_1.isSelected;
        this.subscriptions = new rxjs_1.Subscription();
        this.subscriptions.add(this.navigationService.moves
            .subscribe(this.updateFocusClass.bind(this)));
        this.subscriptions.add(this.navigationService.selects
            .pipe(operators_1.filter(function (index) { return index === _this.index; }))
            .subscribe(function (index) {
            return _this.selectionService.select(index, _this.dataItem);
        }));
        this.subscriptions.add(this.selectionService.changes
            .subscribe(function () {
            _this.updateSelectionClass(_this.isSelected(_this.dataItem, _this.index));
        }));
    }
    TreeViewItemContentDirective.prototype.ngOnChanges = function (changes) {
        if (changes.initialSelection) {
            this.updateSelectionClass(this.initialSelection);
        }
        if (changes.index) {
            this.updateFocusClass();
        }
    };
    TreeViewItemContentDirective.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    TreeViewItemContentDirective.prototype.updateFocusClass = function () {
        this.render(this.navigationService.isActive(this.index), 'k-focus');
    };
    TreeViewItemContentDirective.prototype.updateSelectionClass = function (selected) {
        this.render(selected, 'k-selected');
    };
    TreeViewItemContentDirective.prototype.render = function (addClass, className) {
        var action = addClass ? 'addClass' : 'removeClass';
        this.renderer[action](this.element.nativeElement, className);
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], TreeViewItemContentDirective.prototype, "dataItem", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], TreeViewItemContentDirective.prototype, "index", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], TreeViewItemContentDirective.prototype, "initialSelection", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Function)
    ], TreeViewItemContentDirective.prototype, "isSelected", void 0);
    TreeViewItemContentDirective = tslib_1.__decorate([
        core_1.Directive({ selector: '[kendoTreeViewItemContent]' }),
        tslib_1.__metadata("design:paramtypes", [core_1.ElementRef,
            navigation_service_1.NavigationService,
            selection_service_1.SelectionService,
            core_1.Renderer2])
    ], TreeViewItemContentDirective);
    return TreeViewItemContentDirective;
}());
exports.TreeViewItemContentDirective = TreeViewItemContentDirective;
