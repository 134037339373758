//import angular from 'angular';
import DbClass from '../../common/local-db';
import FileManagement from '../../../services/fileManagement';
import BrowserSvc from '../../../services/browserSvc';
import OcDateSvc from '../../../services/ocDateSvc';



    angular.module('app.fieldSurveys')
        .service('baseModuleService', ['amtCommandQuerySvc', 'amtXlatSvc', 'dataBroker', '$q', 'errorReporter', '$db',
            '$timeout', 'fileManagement', '$state', 'ocConfigSvc', 'amtConstants', 'ocDateSvc', 'browserSvc',
            function (amtCommandQuerySvc, amtXlatSvc, dataBroker, $q, errorReporter, $db: DbClass,
                $timeout, fileManagement: FileManagement, $state, ocConfigSvc, amtConstants, ocDateSvc: OcDateSvc, browserSvc: BrowserSvc) {
                return function (internalName, tableName?) {
                    var vm = this;
                    vm.active = true;

                    vm.visualIdentifier = null;

                    if (!tableName) {
                        tableName = internalName;
                    }
                    
                    vm.count = 0;  // the outstanding count
                    //The oldest recording in the system that has not been synchronised
                    //Used to force uploading of data

                    vm.count = 0;
                    vm.intDateDiff = 0;
                    vm.intStatus = '';

                    vm.progress = 100;
                    vm.full = -1;

                    vm.route = 'mobile.' + internalName; // Route to Module
                    vm.name = amtXlatSvc.xlat(internalName + '.moduleName');
                    vm.description = amtXlatSvc.xlat(internalName + '.elementName');

                    vm.readOnly = true;

                    //Determine if there are changes to be synchronized.
                    vm.dirty = function () {
                        return true;
                    };

                    var states = [
                        { min: -10, className: 'module-status-ready' }, // ZERO
                        { min: 0, className: 'module-status-data' }, //Less than 1 day
                        { min: 1, className: 'module-status-warning' }, // Less than 2 days
                        { min: 2, className: 'module-status-old' }
                    ]; // Greater than 2 days

                    vm.generatedClass = 'module-status-ready';

                    function updateRecordAgeDetails(record) {
                        console.log('update age details:' + internalName);

                        vm.lastRecordedDate = record.createdDate;
                        vm.intDateDiff = Math.floor(ocDateSvc.getDaysDiffLegacy(vm.lastRecordedDate));

                        if (vm.intDateDiff < 1) {
                            vm.intStatus = amtXlatSvc.xlat('mobileCommon.lessThanOneDayOld');
                        }
                        else {
                            if (vm.intDateDiff === 1) {
                                vm.intStatus = amtXlatSvc.xlat('mobileCommon.oneDayOld');
                            } else if (vm.intDateDiff > 1) {
                                vm.intStatus = amtXlatSvc.xlat('mobileCommon.xDaysOld', vm.intDateDiff);
                            }
                        }

                        vm.intStatus = "(" + vm.intStatus + ")";

                        var age = ocDateSvc.getDaysDiffLegacy(vm.lastRecordedDate);

                        //Used to determine if user can access the module.
                        //Rules: must be active.
                        vm.readOnly = !vm.active;

                        for (var s = 0; s < states.length; s++) {
                            if (age > states[s].min) {
                                vm.generatedClass = states[s].className;
                            }
                        }

                        $timeout();
                    }

                    // update the display details for the module
                    vm.updateDetails = async function () {

                        // reset to defaults
                        if (!vm.active) {
                            // the description and what not should be set custom in the module.
                            // This could be permissions or something
                            return $q.resolve();
                        }

                        vm.count = 0;
                        vm.intDateDiff = 0;
                        vm.intStatus = '';
                        vm.description = ' --- ';
                        vm.readOnly = (!vm.active);

                        try {
                            let count = await $db[tableName].where('pristine').notEqual(1).count();
                            count = await vm.getRecordCount(count); // allow modules to override the record count
                            await vm.updateDescription(count);
                            $timeout();
                        } catch (error) {
                            vm.description = 'Error:' + errorReporter.exceptionMessage(error);
                        }
                    };

                    vm.getRecordCount = function (defaultCount) {
                        return defaultCount;
                    };

                    vm.updateDescription = async function (count) {

                        console.log('Updating details:' + internalName);

                        vm.description = count + ' ' + amtXlatSvc.xlat(internalName + (count == 1 ? '.elementName' : '.elementNamePlural'));

                        vm.count = count;

                        if (count > 0) {
                            let record = await $db[tableName].orderBy('createdDate').filter(r => r.pristine === 0).first();

                            if (record) {
                                updateRecordAgeDetails(record);
                            } else {
                                errorReporter.logError('Module ' + internalName + ' : count indicates records but no record found');
                                vm.description = 'Data Error';
                            }

                        } else {
                            vm.lastRecordedDate = null;
                            vm.description = amtXlatSvc.xlat('mobileCommon.readyStatus');
                            vm.generatedClass = 'module-status-ready';
                        }
                    };

                    vm.trimForUpload = function (records) {
                        return records;
                    };

                    vm.getEndpoint = function (records) {
                        return internalName + '/uploadRecords';
                    };

                    vm.wrapRecords = function (records) {
                        return records;
                    };

                    //this allows the upload of any supporting data such as files
                    vm.preProcess = function (records) {
                        return $q.resolve();
                    };

                    // additional steps for after processing has successully completed
                    vm.postProcess = function (record) {
                        // for override
                    };

                    // upload files associated to the records for mobile
                    // must locate the attachments, upload them, and update their id to the one received from the server
                    vm.uploadFiles = function (records) {
                        return $q.resolve();
                    };

                    /* into upload errors push an object
                    {
                        msg : main item error message eg : amtXlatSvc.xlat(internalName + '.elementName') + " " + (record.displayDescription || record.id) + ":";
                        subErrors : array of errors for the main record.
                    }
                    */
                    vm.processErrors = function (response, uploadErrors, postedRecord) {
                        for (var elementId in response) {
                            if (response.hasOwnProperty(elementId)) {
                                console.log("Error with record:" + postedRecord.id);
                                var msg = amtXlatSvc.xlat(internalName + '.elementName') +
                                    ' ' +
                                    (postedRecord.displayDescription || postedRecord.id) +
                                    ':';
                                uploadErrors.push({ msg: msg, subErrors: response[elementId] });
                            }
                        }
                    };

                    vm.directUploadRecord = function (record) {
                        // desktop upload
                        var records = [record];
                        var endpoint = vm.getEndpoint(records);
                        return vm.preProcess(records).then(function () {

                            try {
                                records = vm.trimForUpload(records);
                            } catch (error) {
                                console.error(error);
                                var id = record.displayDescription || record.id;
                                throw new Error("Failed in upload of record : " + id);
                            }

                            console.log("Post record");

                            return amtCommandQuerySvc.post(endpoint, vm.wrapRecords(records)).then(function (response) {

                                console.log("Record Posted");

                                var uploadErrors = [];

                                // store the pendingFieldSurveyId here.
                                if (!record.pendingFieldSurveyId && Object.keys(response)[0] !== amtConstants.emptyGuid) {
                                    record.pendingFieldSurveyId = Object.keys(response)[0];
                                }

                                vm.processErrors(response, uploadErrors, records[0]);
                                return uploadErrors;

                            }).catch(function (error) {
                                return $q.reject(error);
                            });

                        }).catch(function (error) {
                            errorReporter.logError(error);
                            return $q.reject(error);
                        });
                    };

                    async function uploadRecord() {

                        console.log('Upload:' + internalName);
                        vm.progress = (((vm.full - vm.count)) / vm.full) * 100;
                        vm.progress = Math.max(vm.progress, 2);
                        $timeout(); // poke the UI

                        try {

                            let record = await $db[tableName].orderBy('createdDate').filter(r => r.pristine === 0).first();

                            if (!record) {
                                vm.generatedClass = 'module-status-ready';
                                await vm.updateDetails();
                                return vm.uploadErrors;
                            }

                            console.log('Have record:' + internalName);

                            let records;

                            try {
                                records = vm.trimForUpload([record]);
                            } catch (error) {
                                var id = record.displayDescription || record.id;
                                console.error("Failed in upload of record : " + id);
                                console.error(error);
                                throw new Error("Failed in upload of record : " + id);
                            }

                            records.siteId = ocConfigSvc.user.site.id;
                            
                            let endpoint = vm.getEndpoint(records);

                            let postedRecord = records;
                            if (Object.prototype.toString.call(records) === '[object Array]') {
                                postedRecord = records[0];
                            }

                            if (!postedRecord.id) {
                                throw new Error("Fail in upload of record, no Id found : " + record.displayDescription);
                            }

                            try {

                                // upload any files associated with the upload and update the file ids
                                await vm.uploadFiles(records);

                                let response = await amtCommandQuerySvc.post(endpoint, vm.wrapRecords(records));

                                console.log('record posted:' + postedRecord.id);

                                // pull the resulting errors into upload errors
                                vm.processErrors(response, vm.uploadErrors, postedRecord);

                                // 2) delete it
                                await $db[tableName].delete(postedRecord.id);

                                console.log('record deleted:' + postedRecord.id);

                                vm.count--; // decrement the count
                                vm.progress = (((vm.full - vm.count)) / vm.full) * 100;

                                vm.postProcess(postedRecord);

                                if (vm.count < 0) {
                                    return new Error("upload count");
                                }

                                console.log('Updating description on upload deletion');

                                vm.description = vm.count + ' ' + amtXlatSvc.xlat(internalName + (vm.count == 1 ? '.elementName' : '.elementNamePlural'));

                                if (vm.count === 0) {
                                    $timeout(function () {
                                        vm.updateDetails();
                                        $timeout();
                                    }, 1500);
                                }

                                vm.intStatus = '';

                                let noCounts = true;
                                let record = await $db[tableName].orderBy('createdDate').filter(r => r.pristine === 0).first();
                                if (record) {
                                    vm.lastRecordedDate = record.createdDate;
                                }
                                else {
                                    vm.full = -1;
                                    vm.lastRecordedDate = null;
                                }
                            } catch (error) {
                                if (vm.visualIdentifier) {
                                    error.identifier = String.format('[{0}] {1} - ', vm.name, vm.visualIdentifier);
                                }

                                throw error;
                            }

                        } catch (error) {
                            await vm.updateDetails();
                            $timeout(); // poke the UI
                            throw error;
                        }

                        return vm.uploadErrors;
                    }

                    vm.doUpload = async function (uploadErrors) {

                        if (vm.full === -1) {
                            vm.full = vm.count;
                        }

                        vm.uploadErrors = uploadErrors;
                     
                        await uploadRecord();
                    };

                    vm.rules = [];

                    /**
                     * 
                     * @param {string} button - Actually button resource, like "common.allow".
                     * @param {string} [positiveButtonResource] - Resource to check button against. Defaults to "common.allow" if empty.
                     */
                    vm.skipAllowed = function (button, positiveButtonResource) {
                        positiveButtonResource = positiveButtonResource || "common.allow";
                        if (button === positiveButtonResource) {
                            vm.moduleItem.skipAllow[vm.currentRule.name] = vm.moduleItem.skipAllow[vm.currentRule.name] || {};
                            angular.extend(vm.moduleItem.skipAllow[vm.currentRule.name], vm.moduleItem.errorData);
                            if (typeof vm.moduleItem.skipAllow[vm.currentRule.name] === 'undefined') {
                                vm.moduleItem.skipAllow[vm.currentRule.name] = true;
                            }
                            return $q.resolve();
                        } else {
                            return $q.reject('ValidateAbort');
                        }
                    };

                    /**
                     * Run the validation rules and show any dialogs as required.
                     * @param {Object} moduleItem - Item to validate.
                     * @param {Object} [displayApi] - TODO: This isn't used?
                     * @param {boolean} [endCheck] - TODO: Unsure how to describe this.
                     * @param {boolean} [skipUi] - Skips the rule Outcome call (i.e. the popup window).
                     * @param {Object} [additionalData] - Any additional data the rule(s) might need for validation.
                     * @param {string} [fieldFilter] - Field to filter on.
                     */
                    vm.validate = function (moduleItem, displayApi, endCheck, skipUi, additionalData, fieldFilter, tab?, validateReason?) {

                        if (!moduleItem) {
                            return $q.resolve(true); // nothing to validate;
                        }

                        var rulesToCheck = tab ? vm.rules.filter(r => r.tab === tab) : vm.rules.slice();

                        vm.moduleItem = moduleItem;
                        vm.moduleItem.skipAllow = vm.moduleItem.skipAllow || {};
                        //OR-11435: Due to the front end validation, these resourceKey is not needed.
                        vm.moduleItem.errorList = vm.moduleItem.errorList !== undefined ? vm.moduleItem.errorList.filter(err => err?.resourceKey !== "checkout_nontyreRelatedWork" && err?.resourceKey !== "checkout_tyreRelatedWork") : undefined;
                        moduleItem.errorList = vm.moduleItem.errorList;
                        moduleItem.errorData = {};
                        return $q.when(
                            // check rules can return a further promise or a null.
                            checkRules(moduleItem, rulesToCheck, endCheck, skipUi, additionalData, fieldFilter, validateReason)
                        ).catch(function (error) {
                            // Errors are intentionally thrown, just ignore them.
                            // @ts-ignore
                            return $q.reject(error);
                        }).finally(function () {
                            moduleItem.activeError = {};
                            if (!moduleItem.errorList) {
                                moduleItem.errorList = [];
                            }
                            if (moduleItem.errorList.length > 0) {
                                for (var i = 0; i < moduleItem.errorList.length; i++) {
                                    if (moduleItem.errorList[i].item) {
                                        moduleItem.activeError[moduleItem.errorList[i].item.fieldIdentifier] = true;
                                    }
                                }
                            }
                        });
                    };

                    vm.deregisterError = function (moduleItem, rule, identifier) {
                        if (!identifier) {
                            identifier = rule.name;
                        }

                        for (var i = moduleItem?.errorList?.length - 1; i >= 0; i--) {
                            if (moduleItem.errorList[i].identifier === identifier) {
                                moduleItem.errorList.splice(i, 1);
                            }
                        }

                        moduleItem.errorList = moduleItem.errorList || [];
                    };

                    function registerError(moduleItem, rule, identifier) {
                        if (!identifier) {
                            identifier = rule.name;
                        }
                        moduleItem.errorList = moduleItem.errorList || [];
                        for (var i = 0; i < moduleItem.errorList.length; i++) {
                            if (identifier === moduleItem.errorList[i].identifier) {
                                return; // repeat
                            }
                        }
                        moduleItem.errorList.push({ item: angular.copy(moduleItem.errorData), rule: rule, identifier: identifier });
                    }

                    /**
                     * 
                     * @param {any} moduleItem
                     * @param {any} rulesToCheck
                     * @param {any} endCheck
                     * @param {boolean} skipUI - Skips the rule Outcome call (i.e. the popup window).
                     * @param {any} additionalData
                     * @param {string} [fieldFilter=All] - Field to filter on. Defaults to "All".
                     */
                    function checkRules(moduleItem, rulesToCheck, endCheck, skipUI, additionalData, fieldFilter, validateReason) {
                        if (!!fieldFilter === false) {
                            fieldFilter = "All";
                        }

                        if (rulesToCheck.length > 0) {
                            vm.currentRule = rulesToCheck[0];
                            if (!vm.currentRule.atEnd || endCheck) {
                                if (!vm.currentRule.check(moduleItem, additionalData, fieldFilter, validateReason)) {
                                    console.log(vm.currentRule.name + " alert");
                                    moduleItem.errorData = moduleItem.errorData || {};
                                    registerError(moduleItem, vm.currentRule, moduleItem.errorData.identifier || vm.currentRule.name);
                                    if (skipUI || (moduleItem.errorData.fieldIdentifier !== fieldFilter && fieldFilter !== "All")) {

                                        if (!(moduleItem.errorData && moduleItem.errorData.continue)) {
                                            moduleItem.errorData = {};
                                            rulesToCheck.shift();
                                        }
                                        return checkRules(moduleItem, rulesToCheck, endCheck, skipUI, additionalData, fieldFilter, validateReason);
                                    }
                                    else {
                                        console.log("Calling validation outcome.");

                                        var validationRule = angular.copy(vm.currentRule);

                                        return validationRule.outcome(moduleItem).then(function () {

                                            // continue - the user either fixed or ignored, do rest of rules ?
                                            console.log(validationRule.name + " skipped or fixed");

                                            if (!(moduleItem.errorData && moduleItem.errorData.continue)) {
                                                moduleItem.errorData = {};
                                                rulesToCheck.shift();
                                            }

                                            return checkRules(moduleItem, rulesToCheck, endCheck, skipUI, additionalData, fieldFilter, validateReason);
                                        }, function (error) {

                                            // if we have an error and have stopped processing due to a popup
                                            // we still want the rest of the rules to be run, so the error items can be highlighted,
                                            // but run without showing any further popups.

                                            console.log(validationRule.name + " Errored");

                                            if (!(moduleItem.errorData && moduleItem.errorData.continue)) {
                                                moduleItem.errorData = {};
                                                rulesToCheck.shift();
                                            }

                                            // do the remaining rules in the back ground
                                            checkRules(moduleItem, rulesToCheck, endCheck, true, additionalData, fieldFilter, validateReason);

                                            if (error === 'ValidateAbort') {
                                                validationRule.ValidateAbort = true;
                                            }

                                            return $q.reject(validationRule);
                                        });
                                    }
                                } else {
                                    //console.log(vm.currentRule.name + " passed or skipped");
                                }
                                if (moduleItem.errorData) {
                                    moduleItem.errorData.continue = null;
                                }
                            }
                            moduleItem.errorData = {};
                            rulesToCheck.shift();
                            return checkRules(moduleItem, rulesToCheck, endCheck, skipUI, additionalData, fieldFilter, validateReason);
                        }

                        return;  // all rules done and passed or ignored
                    }

                    vm.click = function (parent) {
                        dataBroker.uploadErrors();
                        $state.go(parent.module.route);
                    };
                };
            }]);
