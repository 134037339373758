import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Widget } from '../../service/Interface/SiteDashboard.interface';
import { ChangeCompletedUi } from '../../service/Interface/WidgetConstant.interface';
import { WidgetHelper } from '../../service/widgetHelper/Widget.helper';
import { WidgetRxjsService } from '../../service/rxjs/WidgetRxjs.service';
import { WidgetConstant } from '../../service/widgetHelper/WidgetConstant';
import { Subscription } from 'rxjs';
import { CompletedChangeWidgetElement } from '../../service/Interface/Widget.interface';


@Component({
    selector: 'widget-changes-completed',
    templateUrl: './changes-completed.component.html',
    styleUrls: ['./../../site-dashboard.component.scss']
})


//Completed
export class SiteDashboardChangesCompletedComponent implements OnInit, OnDestroy{
    public CompletedChangeWidgetSubscription: Subscription;
    public ui: ChangeCompletedUi;
    public isIncludeSubtext: boolean;
    public description: string;
    public result: CompletedChangeWidgetElement = {
        scheduledMaintenanceSession: 0,
        changedCompletedMaintenanceSession: 0
    }

    @Input() set showSubtext(widget: Widget) {
        let isIncludeSubtextValue = this.widgetHelper.getIncludeSubtext(widget.detailElement);
        this.isIncludeSubtext = isIncludeSubtextValue;
    }

    constructor(private widgetRxjs: WidgetRxjsService, private widgetHelper: WidgetHelper, private widgetConstant: WidgetConstant) {
        this.ui = this.widgetConstant.getChangeCompletedWidgetUi();
    }

    ngOnInit() {
        this.subscribeCompletedCompletedWidgetResult();
    }


    ngOnDestroy() {
        this.CompletedChangeWidgetSubscription.unsubscribe();
    }

    //subscribe Rxjs data
    private subscribeCompletedCompletedWidgetResult() {
        this.CompletedChangeWidgetSubscription = this.widgetRxjs.completedChangeWidget
            .subscribe(result => {
                this.result.changedCompletedMaintenanceSession = this.widgetHelper.getValidateValue(result.changedCompletedMaintenanceSession);
                this.result.scheduledMaintenanceSession = this.widgetHelper.getValidateValue(result.scheduledMaintenanceSession);
                this.setDescription();
            });
    }

    private setDescription() {
        let constant = this.widgetConstant.getChangeCompletedWidgetUi();
        let isZero = this.result.changedCompletedMaintenanceSession === 0;
        let percentage = this.result.scheduledMaintenanceSession / this.result.changedCompletedMaintenanceSession * 100;

        this.description = isZero ? constant.noChangeCompleted : Math.round(percentage).toString() + constant.percentOfCompleted;
    }

}