/**
 * @ngdoc overview
 * @name amtFramework
 * @description
 * amtFramework is a set of components to create feature rich business applications using the Angular.js framework.
 * amtFramework is published as a nuget package on the internal iSolutions nuget server: http://isauci01:8001/guestAuth/app/nuget/v1/FeedService.svc/
 * Please add this url to the package sources in visual studio.
 * 
 * 
**/
import ngRoute from 'angular-route';
import amtFilters from './components/amtFilters';
import ngAnimate from 'angular-animate';
import ngTouch from 'angular-touch';//needed by Angular UI Bootstrap
import uib from 'angular-ui-bootstrap';
import cgBusy from 'angular-busy';
import ngSanitize from "angular-sanitize";
import './main.scss';

const moduleName = 'amtFramework';
export default moduleName;

angular.module('amtFramework.config', []);
angular.module('amtFramework.filter', []);
angular.module('amtFramework.validation', []);
angular.module('amtFramework.wizard', [uib]);
angular.module('amtFramework.grid', [uib, amtFilters, 'ajoslin.promise-tracker', cgBusy, 'amtFramework.config']);
angular.module('amtFramework.formControls', [uib, amtFilters, 'ajoslin.promise-tracker', cgBusy]);
angular.module('amtFramework.controls', ['amtFramework.formControls']);

angular.module(moduleName, [
    ngAnimate,
    ngTouch,
    uib,
    ngRoute,
    'kendo.directives',
    'amtFramework.controls',
    'amtFramework.grid',
    'amtFramework.validation',
    'amtFramework.wizard',
    'amtFramework.filter',
    'amtFramework.config',
    amtFilters,
    'ajoslin.promise-tracker',
    cgBusy,
    'progressButton',
    ngSanitize //we ensure ngSanitize is included here, if it's not included by at least one module stuff breaks (angularJS will give sce:unsafe errors)
]);