import * as _ from 'underscore'
//import angular from 'angular';
import tmpl from './amtDropdown.html';

//TODO: check this code, not used at the moment

angular.module('amtFramework').directive('amtDropdown', [
            function() {
               
                return {
                    restrict: 'E',
                    scope: {
                        options: '=',
                        selectedId: '=',
                        autoComplete: '=',
                        url: '=',
                        required: '='
                    },
                    template: tmpl,
                    link: function(scope:any, element, attrs) {
                        scope.selectedOption = { key: -1, name: 'Please Select' };
                        scope.status = {
                            isopen: false
                        };

                        scope.selectOption = function(id) {
                            scope.selectedOption = _.find(scope.options, function(option) {
                                if (option.key === id) {
                                    scope.selectedId = id;
                                    return true;
                                }
                                scope.selectedId = -1;
                                return false;
                            });
                            scope.status.isopen = false;
                        };
                    }
                };
            }
]);
