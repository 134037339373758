//import angular from 'angular';
import tmpl from "./report.html";

const moduleName = 'app.reports';
export default moduleName;

angular.module(moduleName, [])
        .config([
            "$stateProvider",
            function ($stateProvider) {
                $stateProvider
                    .state("app.reports.reportpage", {
                        url: "/reportpage",
                        params: {
                            url: "",
                            name: ""
                        },
                        template: tmpl,
                        controller: "reportCtrl",
                        controllerAs: "vm"
                    })
            }
        ]);