import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, combineLatest, of, Subject, BehaviorSubject } from 'rxjs';
import { map, switchMap, shareReplay, tap } from 'rxjs/operators';
import { IVehicleSpec, VehicleSpecsService } from '../../../services/vehicle-specs.service';
import { IVehicle, VehiclesOnsiteService } from '../../../services/vehicles-onsite.service';
import { mapFrom } from './helpers';
import { XlatPipe } from '../../../amt/i18n/xlat-pipe';

export enum AlertTypes {
    MatchingLimitExceeded = "MATCHINGLIMITEXCEEDED",
    AsymmetricWearLimitExceeded = "ASYMMETRICALWEAR"
}

export interface IDisplayVehicle {
    serialNo: string,
    altText: string,
    application: string,
    model: string,
    equipmentId: guid,
    issues: MonitoringIssueData[],
    spec: IVehicleSpec,
    sizes: IAxleSpecSize[]
};

export interface IAxleSpecSize {
    tyreWidth: number,
    tyreDiameter: number,
    rimDiameter: number,
}

export interface ISerialNumberDetails {
    prefix: string,
    manufacturer: string,
    site: string,
    formatted: string,
    hierarchical: string
}

export interface IReadingDetails {
    date: Date,
    innerOuterValues: string,
    type: string, //Check return type from http call
    unit: string,
    value: number //Check return type from http call
}

export interface IPositionData {
    component: ISelectedPosition,
    position: string
}

//Interface for selected position returned from ocVehicleSchematic
export interface ISelectedPosition {
    type: string,
    serialNumberDetails: ISerialNumberDetails,
    equipmentId: guid,
    siteId: guid,
    hoursSinceLastNDT: string, //Check return type from http call
    totalDistance: string, //Check return type from http call
    totalHours: IReadingDetails,
    remainingDepth: IReadingDetails,
    primaryOTD: IReadingDetails,
    lifeOTD: IReadingDetails,
    specification: string,
    equipmentLifeId: guid,
    typeId: guid,
    pressureSensorId: guid,
    positionId: guid,
    serialNumber: string,
    isDoubleGutter: boolean,
    percentWorn: string, //Calculated by code somewhere, somehow...
    fittedVehicle: string, //Added in when position is loaded into component
    positionLabel: string //Added back in component
}

//interface from API
export interface IVisualInspectionBasicApi {
    action: string,
    cause: string,
    inspectionDate: Date,
    equipmentId: guid,
    posLabel: string,
    lastActionChangeDate?: Date,
    daysToResolve?: number,
    inspectionFrequencyDays?: number
}

export interface MonitoringIssueData extends IVisualInspectionBasicApi {
    issue: number,
    issueText: string,
    posIdx: number;
}

export interface ICMEquipmentAlerts extends IVisualInspectionBasicApi {
    pos: string, //Assuming this is always an integer - currently stored in alert params as a string
    alertTypeName: string,
    description: string,
    posIdx: number
}

export const CONTINUE_TO_MONITOR_RES = 'Continue to Monitor';

@Injectable({
    providedIn: 'root'
})
export class ConditionMonitoringService {
    constructor(
        private http: HttpClient,
        private vehicleSvc: VehiclesOnsiteService,
        private vehicleSpecsSvc: VehicleSpecsService,
        private xlatPipe: XlatPipe
    ) {
    }

    private currentVehicle$: Observable<any> = null;
    public getVehicle(eId: string): Observable<any> {
        if (!this.currentVehicle$) {
            this.currentVehicle$ = this.getVehicleDetails(eId).pipe(shareReplay(1));
        }

        return this.currentVehicle$;
    }

    private vehicles$ = this.vehicleSvc.vehicles$;
    private vehicleSpecs$ = this.vehicleSpecsSvc.vehicleSpecs$;

    private visInspMonRequest$ = this.http.get<Record<string, IVisualInspectionBasicApi[]>>(baseApiUrl + 'equipment/vehicle/conditionMonitoringData').pipe(
        map(w => Array.from(Object.entries(w))),
        //shareReplay(1), //removed so we hit the server each time
    );

    private cmEquipmentAlerts$ = this.http.get<Record<string, ICMEquipmentAlerts[]>>(baseApiUrl + 'equipment/vehicle/cmEquipmentAlerts').pipe(
        map(s => Array.from(Object.entries(s))),
        //map(s => mapFrom(s)),
        //shareReplay(1),
    );

    private diagramSizeRequest$ = this.http.get<Record<string, IAxleSpecSize[]>>(baseApiUrl + 'equipment/vehicle/paramsForDiagram').pipe(
        map(s => mapFrom(s)),
        //shareReplay(1), //removed so we hit the server each time
    );

    //Pass a 'new' date into this so we don't corrupt the original date (passed by reference)
    private addDays(date: Date, days: number): Date {
        date.setDate(date.getDate() + days);
        return date;
    }

    //private vehiclesWithAlertData$ = combineLatest(this.vehicles$, this.vehicleSpecs$, this.cmEquipmentAlerts$, this.diagramSizeRequest$).pipe(
    //    map(w => Array.from(w[0].entries()).map(v => {
    //        let [vehicleId, vehicle] = v;
    //        let [vehicleMap, vehSpecMap, alertMap, diagramSizes] = w;
    //        let alerts = alertMap.get(vehicle.serialNo.toLowerCase()) || [];
    //        if (alerts.length > 0)
    //            alerts.sort((a, b) => parseInt(a.pos) - parseInt(b.pos));
    //        let spec = vehSpecMap.get(vehicle.specificationId);
    //        let sizes = diagramSizes.get(vehicle.specificationId);
    //        let alertText = alerts.map(m => `Pos ${m.pos}: ${m.description}`).join("<br />") || this.xlatPipe.transform("conditionmonitoring.NoIssue");
    //        return <IDisplayVehicle>{
    //            serialNo: vehicle.serialNo,
    //            altText: this.xlatPipe.transform("conditionmonitoring.Alerts") + ":<br />" + alertText,
    //            application: spec?.vehicleApplication || this.xlatPipe.transform("conditionmonitoring.Unknown"),
    //            model: spec?.model || this.xlatPipe.transform("conditionmonitoring.Unknown"),
    //            equipmentId: vehicle.id,
    //            issues: [], //alerts,
    //            spec: spec,
    //            sizes: sizes
    //        };
    //    }))
    //);

    //public get vehiclesData$() {
    //    return this.vehiclesWithIssueData$;
    //}

    //public get alertData$() {
    //    return this.vehiclesWithAlertData$;
    //}

    //public getIssuesForVehicle$(vehicle$: Observable<IVehicle>) {
    //    return combineLatest(vehicle$, this.visInspMonData$).pipe(
    //        switchMap(i => of(i[0] && i[1].get(i[0]?.id)))
    //    );
    //}

    //public getAlertsBySerial$(serial: string): Observable<ICMEquipmentAlerts[]> {
    //    return this.cmEquipmentAlerts$.pipe(
    //        map(x => x.get(serial.toLowerCase()))
    //    );
    //}

    getDiagramSizes$(vehicle$: Observable<IVehicle>) {
        return combineLatest(vehicle$, this.diagramSizeRequest$).pipe(
            switchMap(i => of(i[0] && i[1].get(i[0]?.specificationId)))
        );
    }

    public getVehicleDetails(id: guid) {
        return this.http.post<any>(baseApiUrl + 'equipment/vehicle/ConditionMonitoringVehicleDetails', { equipmentId: id })
            .pipe(
                //tap(x => console.log(x))
            );
    }

    public get VehicleSpecs$() {
        return this.vehicleSpecs$;
    }

    private vehiclesWithSpecs$ = combineLatest(this.vehicles$, this.vehicleSpecs$, this.diagramSizeRequest$).pipe(
        map(w => Array.from(w[0].entries()).map(v => {
            let [vehicleId, vehicle] = v;
            let [vehicleMap, vehSpecMap, diagramSizes] = w;
            let monIssues = [];
            let spec = vehSpecMap.get(vehicle.specificationId);
            let sizes = diagramSizes.get(vehicle.specificationId);
            let issueText = this.xlatPipe.transform("conditionmonitoring.NoIssue");
            return <IDisplayVehicle>{
                serialNo: vehicle.serialNo,
                altText: this.xlatPipe.transform("conditionmonitoring.VisualInspections") + ":<br />" + issueText,
                application: spec?.vehicleApplication || this.xlatPipe.transform("conditionmonitoring.Unknown"),
                model: spec?.model || this.xlatPipe.transform("conditionmonitoring.Unknown"),
                equipmentId: vehicle.id,
                issues: monIssues,
                spec: spec,
                sizes: sizes
            };
        }))
    );

    public get getVehicles$() {
        return this.vehiclesWithSpecs$;
    }

    public get visualInspections$() {
        return this.visInspMonRequest$;
    }

    public get currentAlerts$() {
        return this.cmEquipmentAlerts$;
    }

}
