//import angular from 'angular';
import * as _ from 'underscore'

    angular.module('app.directives').directive("ocDialog", ['$compile', '$timeout', '$rootScope', 'WindowFactory',
        'errorReporter', 'enums', 'amtXlatSvc', '$window',
        function ($compile: ng.ICompileService, $timeout: ng.ITimeoutService, $rootScope: IRootScope, WindowFactory: IWindowFactory,
            errorReporter: IErrorReporter, enums, amtXlatSvc: IAmtXlatSvc, $window: ng.IWindowService) {
            return {
                replace: true,
                scope: {
                    onMinimize: "&",
                    onFullScreen: "&",
                    onWindowed: "&",
                    onClose: "&",
                    onDataChanged: "&",
                    mousedown: "&",
                    wnd: "=",
                    canClose: "=",
                    canMinimise: "=",
                    notification: "="
                },
                link: function (scope: any, element, attr) {

                    var headerHeight = WindowFactory.headerHeight;
                    var footerHeight = WindowFactory.getFooterHeight();

                    scope.wnd.element = element; // save the element
                    var bindId = uuid();
                    var dlg = element; //get the dom element
                    var pos = scope.wnd.position;
                    var oTop = pos.top;
                    if (!isNaN(scope.wnd.size.height) && scope.wnd.size.height > 0) {
                        if (!scope.wnd.footerOff) {
                            scope.wnd.size.height += footerHeight; // this is to allow for the footer.
                        }
                        if (!scope.wnd.headerOff) {
                            scope.wnd.size.height += headerHeight; // this is to allow for the header.
                        }
                    } else {
                        scope.wnd.size.height = null;
                    }

                    var oSize = angular.copy(scope.wnd.size);

                    var resized = function (event) {

                        //code commented, isFullSceen misspelled and wasn't checking the property WindowFactory is setting, is unused and the below looks suss...
                        // need to re window this because it re-renders
                        //if (scope.wnd.isFullSceen) {
                        //    scope.wnd.windowed(); // need to re window this because it re-renders
                        //}

                        if (scope.wnd.modal) {
                            return; //because this code be whack y'all, just go, let css to what it's go at!
                        }

                        // the OC dilaog size is based on the content child.
                        if (dlg[0].children.length && dlg[0].children[0].children.length) {
                            //console.log("Sized:" + $window.innerWidth + "x" + $window.innerHeight );
                            var winH = $window.innerHeight;
                            var winW = $window.innerWidth;

                            var dialogHeight = (dlg[0].children[0].children[0] as HTMLElement).offsetHeight;

                            if (oSize.height) {
                                scope.wnd.size.height = Math.min(oSize.height, winH - 10);
                            } else {
                                if (dialogHeight > winH) {
                                    scope.wnd.size.height = winH
                                }
                                else {
                                    scope.wnd.size.height = null; // free float
                                }
                            }

                            var topSpace = oTop;
                            var bottomSpace = winH - (pos.top + dialogHeight);
                            if (bottomSpace < topSpace) {
                                // adjust up;
                                var adj = (winH - dialogHeight) / 2;
                                if (adj < 0)
                                {
                                    adj = 0;
                                }                               
                            }
                            else {
                                if (pos.Top != oTop) {
                                    pos.top = oTop; // move back to original position - needed because of mobile onscreen keyboard pops;
                                    $timeout();
                                }
                            }

                            var rightOverflow = (scope.wnd.size.width + pos.left) - winW;
                            if (rightOverflow > 0) {
                                pos.left = pos.left - rightOverflow;
                                if (pos.left < 0) {
                                    pos.left = 0;
                                }
                            }
                                 
                            $timeout();
                        }
                    };

                    //might as well only call 
                    if (!scope.wnd.modal) {
                        angular.element($window).on('resize.' + bindId, resized);

                        scope.$on('$destroy', function () {
                            //console.log("removed resize bind.");
                            angular.element($window).off('resize.' + bindId);
                        });
                    }



                    scope.onCloseWrapper = function () {
                        try {
                            scope.wnd.onClose();
                        } catch (error) {
                            errorReporter.logError(error, 'ocDialog-closeWindow');
                            WindowFactory.closeWindow(scope.wnd);
                        }
                    };

                    scope.wnd.buttonMethods = {};
                    scope.wnd.buttonStates = scope.wnd.initParams.buttonStates || {};
                    scope.wnd.buttons = scope.wnd.initParams.buttons || [];
                    scope.wnd.closeOnSave = true;

                    var template = '';
                                        
                    var dialogStyle = "";
                    if (scope.wnd.modal) {
                        // add wrapper div to make the dialog modal
                        dialogStyle = "modalDialog";
                    }

                    if (!scope.wnd.size.height) {
                        scope.wnd.size.height = 0;
                    } else {
                        dialogStyle += " fixed-height";
                    }
                    if (scope.wnd.headerOff) {
                        dialogStyle += " header-off";
                    }
                    if (scope.wnd.footerOff) {
                        dialogStyle += " footer-off";
                    }

                    template += '<div class="' + dialogStyle + '">';
                    template += '<div ng-show="wnd.visible" class="child-window-container" ng-attr-style="';

                    if (!scope.wnd.modal)
                        template += 'left: {{wnd.position.left}}px; top: {{wnd.position.top}}px;';

                    template += ' width: min(100vw, {{wnd.size.width}}px);';
                    
                    if (scope.wnd.size.height > 0) {
                        template += ' height: {{wnd.size.height}}px;';
                    }
                    template += '">';
                    if (!(scope.wnd.initParams && scope.wnd.headerOff) ){
                        // draw a header
                        template += '    <div ng-attr-style="height: ' + headerHeight + 'px; padding-right: {{(wnd.modal == false ? 80 : 40 )}}px;" class="child-window-title-container">';
                        if (scope.wnd.icon) {
                            template += '    <div title="{{wnd.caption}}" class="child-window-title" ng-mousedown="mousedown({e: $event})"><span title="{{wnd.caption}}" class="glyphicon ';
                            template += scope.wnd.icon;
                            template += '"></span>&nbsp;{{wnd.caption}}</div>';
                        } else {
                            template = template + '    <div title="{{wnd.caption}}" class="child-window-title" ng-mousedown="mousedown({e: $event})">{{wnd.caption}}</div>';
                        }

                        template += '<div class="child-window-right-title">{{wnd.rightCaption}}</div>';

                        if (!scope.wnd.modal) {
                            if (!scope.wnd.fullScreenOff ) {
                                template = template + '        <button class="btn btn-sm child-window-fs-button glyphicon glyphicon-fullscreen glyphicon-black" ng-click="onFullScreen()"></button>';
                                template = template + '        <button class="btn btn-sm child-window-fs-button full-only glyphicon glyphicon-resize-small glyphicon-black" ng-click="onWindowed()"></button>';          
                            }
                            template = template + '        <button class="btn child-window-minimise-button" ng-show="wnd.canMinimise !== false" ng-click="onMinimize()">_</button>';
                        }                        
                        template = template + '        <button ng-if="wnd.onClose" class="btn child-window-close-button" ng-show="wnd.canClose !== false" ng-click="onCloseWrapper()"><span style="margin-left: -3px;" class="glyphicon glyphicon-remove glyphicon-black"></span></button>';
                        template = template + '    </div>';                        
                    }
                    else
                    {
                        headerHeight = 0;
                    }
                    
                    template += '    <div ng-attr-style=" ';                    
                    if (scope.wnd.size.height > 0) {
                        var childHeight = scope.wnd.size.height - (headerHeight > 0 ? headerHeight : -2);
                        if (!scope.wnd.footerOff) {
                            childHeight = childHeight - footerHeight; // we added this on earlier to allow for it.
                        } else {
                            childHeight = childHeight - 2;
                        }
                        template += ' max-height:' + childHeight  +'px; ';
                    }

                    if (scope.wnd.allowOverflow) {
                        template += ' overflow-y:auto;'
                    }

                    template += '" class="child-window-body ' + scope.wnd.component + ' ' + (scope.wnd.headerOff ? '' : 'has-header ') + (scope.wnd.footerOff ? '' : 'has-footer ') + '">';
                    template += '   <div ng-class="{processing : wnd.processing }" ><div></div></div>';

                    //if ($rootScope.showDialogGridGrid) {
                    //    template += '<div class="layout-grid">';
                    //    for (var i = 0; i < 12; i++) {
                    //        template += '<div class="col-xs-1"><div>&nbsp;</div></div>';
                    //    }
                    //    template += '</div>';
                    //}

                    if (!scope.wnd.component) {
                        var error = amtXlatSvc.xlat('exception.componentUnspecified');
                        errorReporter.logError(new Error(error), 'ocDialog');
                        template += error;
                    }
                    else {
                        if (scope.wnd.isAngular) {
                            template += '       <' + scope.wnd.component + ' [init-params]="wnd.initParams" [button-methods]="wnd.buttonMethods" [button-states]="wnd.buttonStates" [buttons]="wnd.buttons" [close-on-save]="wnd.closeOnSave" [wnd]="wnd" [notification]="notification"/>';
                        } else {
                            template += '       <' + scope.wnd.component + ' init-params="wnd.initParams" button-methods="wnd.buttonMethods" button-states="wnd.buttonStates" buttons="wnd.buttons" close-on-save="wnd.closeOnSave" wnd="wnd" notification="notification"/>';
                        }
                    }

                    template += '<div class="child-break"></div>';
                    template += '   </div>';

                    if (!scope.wnd.footerOff) {
                        // draw a footer                        
                        template += '<div style="height: ' + footerHeight + 'px; width: 100%;" class="child-window-footer-container">';
                        template += '	<div class="child-window-footer">';
                        
                        // Add the left-most "close on save checkbox" option.
						template += '		<label ng-show="wnd.initParams.showCloseOnSave" class=closeOnSaveCheckbox>';
						template += '			<input type="checkbox"'
												   + ' amt-ignore-dirty'
                        						   + ' ng-model="wnd.closeOnSave"/>';
						template += '			{{\'common.autoClose\' | xlat}}';
						template += '		</label>';

                        // Add all the "buttons" (filter on type in ng-repeat).
                        template += '		<input type="button"'
                                 			   + ' class="btn btn-default pull-right"'
											   + ' ng-repeat="button in wnd.buttons | filter: {type: ' + enums.buttonTypes.button + '}"'
											   + ' ng-class="{\'btn-primary\': button.primary, \'btn-cancel\': button.cancel}"'
											   + ' ng-disabled="wnd.buttonStates[button.name].disabled"'
											   + ' value="{{button.value | xlat: button.valueParameters}}"'
											   + ' ng-click="wnd.buttonClick(button.click)"'
											   + ' ng-show="!wnd.buttonStates[button.name] || wnd.buttonStates[button.name].visible !== false ">';
                        
                        // Add all the "actions" (filter on type in ng-repeat, include only visible "actions").
						template += '		<div ng-show="showActionMenu()"'
								  	  		 + ' class="pull-right">';
                        template += '			<amt-menu-actions drop-up="true" title="{{\'common.actions\' | xlat}}">';
						template += '				<amt-menu-action ng-repeat="action in wnd.buttons | filter: {type: ' + enums.buttonTypes.action + '}"'
													  	 		 + ' title="{{action.title | xlat}}"'
                                                                 + ' ng-show="!wnd.buttonStates[action.name] || wnd.buttonStates[action.name].visible !== false"'  // defaults to visible
														 		 + ' (on-click)="wnd.buttonClick(action.click)"></amt-menu-action>';
						template += '			</amt-menu-actions>';
                        template += '		</div>';
                        template += '</div>';

                        if (scope.wnd.size.spaceFooter) {   // this should possibly be the default, but at this stage perhaps a little to late
                            template += '<div style="height:55px;">&nbsp;</div>';
                        }

                        template += '    </div>';
                        template += '</div>';
                    }

                    template += '</div>';
                    template += '</div>';
                    
                    var linkFn = $compile(template);
                    var content = linkFn(scope);
                    element.append(content);   

                    //set inital size of window
                    resized(null);

                    scope.wnd.buttonClick = function (button) {
                    	if (scope.wnd.buttonMethods[button]) {
                            scope.wnd.buttonMethods[button](button);
                            $timeout();
                        } else {
                            var options = " HANDLERS:";
                            var items = Object.getOwnPropertyNames(scope.wnd.buttonMethods);
                            for (var btIdx = 0; btIdx < items.length; btIdx++) {
                                var bt = items[btIdx];
                                if (!scope.wnd.buttonMethods[bt]) {
                                    options = options + ",'" + bt + "' ( is undefined) ";
                                }
                                else {
                                    options = options + ",'" + bt +"'";
                                }                                
                            }
                            console.error("ocDialog.js : Button Clicked [" + button + "] but no handler : " + options);
                    		errorReporter.logError(new Error(amtXlatSvc.xlat('common.notYetImplemented')), 'ocDialog-buttonClick');
						}
                    };
                    
                    scope.showActionMenu = function () {
                        return _.some(scope.wnd.buttons, function (button) {
							return button.type === enums.buttonTypes.action && scope.wnd.buttonStates[button.name].visible;
						});
					};
                }
            };
        }
	]);
