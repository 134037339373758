//import angular from 'angular';
import tmpl from './order.html';



    angular.module('app.admin').component('fleetOrder', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['$scope', 'amtCommandQuerySvc', 'enums', 'amtXlatSvc', 'referenceDataUrls', 'fleetUrls', 'equipmentUrls', 'confirmSvc', 'notifySvc',
            'WindowFactory',
            function ($scope, amtCommandQuerySvc, enums, xlatSvc, referenceDataUrls, apiUrls, equipmentUrls, confirmSvc, notifySvc,
                WindowFactory: IWindowFactory) {

                var vm = this;
                $scope.vm = this;

                // set urls
                vm.apiUrls = apiUrls;
                vm.referenceDataUrls = referenceDataUrls;
                vm.equipmentUrls = equipmentUrls.url;

                this.$onInit = function () {
                    vm.refresh();

                    vm.wnd.onClose = vm.onClose;

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: 'common.save_label',
                            name: 'saveButton',
                            click: 'save',
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: true,
                            disabled: true
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.onSave
                    };
                };

                $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing'], function () {
                    vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.form.$pristine || vm.wnd.processing;
                });

                vm.findFleet = function (fleetId) {
                    for (var f = 0; f < vm.fleets.length; f++) {
                        if (vm.fleets[f].id === fleetId) {
                            return vm.fleets[f];
                        }
                    }
                };

                vm.onDrop = function (data) {
                    var sourceFleet = vm.findFleet(data.sourceId);
                    var destinationFleet = vm.findFleet(data.targetId);

                    vm.moveFleet(sourceFleet, destinationFleet.sequence);
                };

                vm.moveFleet = function (fleet, sequence) {
                    if (fleet.sequence === sequence) {
                        return;
                    }

                    if (fleet.sequence > sequence) {
                        // moving up
                        for (var f = 0; f < vm.fleets.length; f++) {
                            if (vm.fleets[f].sequence < fleet.sequence && vm.fleets[f].sequence >= sequence) {
                                // shuffle the others down to make room for this one
                                vm.fleets[f].sequence = vm.fleets[f].sequence + 1;
                            }
                        }
                    } else {
                        for (var g = 0; g < vm.fleets.length; g++) {
                            if (vm.fleets[g].sequence > fleet.sequence && vm.fleets[g].sequence <= sequence) {
                                // shuffle the others up to make room for this one
                                vm.fleets[g].sequence = vm.fleets[g].sequence - 1;
                            }
                        }
                    }

                    vm.form.$setDirty();

                    fleet.sequence = sequence;
                };

                $scope.setForm = function () {
                    $scope.$watch('vm.form.$dirty', function () {
                        vm.wnd.isDirty = vm.form.$dirty;
                    });
                };

                vm.onClose = function () {
                    closeWindow();
                };

                function closeWindow() {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        vm.form.$setPristine();

                        WindowFactory.closeWindow(vm.wnd);
                    });
                }

                vm.closeOnSave = true;

                vm.onSave = function () {

                    vm.status = 'saving';
                    vm.wnd.processing = true;

                    // save the changes to the sequence numbers
                    amtCommandQuerySvc.post(vm.apiUrls.updateFleetSequences, { fleets: vm.fleets }).then(function (response) {
                        if (response) {

                            if (vm.wnd.onDataChanged) {
                                vm.wnd.onDataChanged();
                            }

                            notifySvc.success(xlatSvc.xlat('fleet.saveOrderSuccess'));

                            if (vm.closeOnSave === true) {
                                vm.form.$setPristine();
                                closeWindow();
                            } else {
                                vm.fleets = response;
                                vm.status = enums.screenStatus.ready;
                                vm.form.$setPristine();
                            }
                        }
                    }, function (error) {

                        vm.defaultErrors = [error];
                        vm.status = enums.screenStatus.error;

                        amtCommandQuerySvc.handleError(error);

                    }).finally(function () {
                        vm.wnd.processing = false;
                    });
                };

                vm.refresh = function () {

                    vm.fleets = [];
                    vm.wnd.processing = true;

                    // load the list of fleets
                    amtCommandQuerySvc.post(vm.apiUrls.getFleetSequences, {}).then(function (response) {
                        if (response) {
                            vm.fleets = response;
                            vm.status = enums.screenStatus.ready;
                        }
                    }, function (error) {
                        vm.defaultErrors = [error];
                        vm.status = enums.screenStatus.error;
                        amtCommandQuerySvc.handleError(error);
                    }).finally(function () {
                        vm.wnd.processing = false;
                    });
                };
            }
        ]
    });
