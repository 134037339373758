//import angular from 'angular';
import OcDateSvc from '../../../../services/ocDateSvc';
import tmpl from './details.html';


angular.module('app.component').component('mwWarrantyClaimDetail', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['$scope', 'helperSvc', 'amtCommandQuerySvc', 'confirmSvc', 'enums', 'amtXlatSvc', 'componentUrls', 'referenceDataUrls',
            'WindowFactory', '$timeout', 'notifySvc', 'ocSecuritySvc', 'errorReporter', 'ocDateSvc',
            function ($scope, helperSvc: IHelperSvc, amtCommandQuerySvc: IAmtCommandQuerySvc, confirmSvc: IConfirmSvc, enums, xlatSvc: IAmtXlatSvc, componentUrls, referenceDataUrls,
                WindowFactory: IWindowFactory, $timeout: ng.ITimeoutService, notifySvc: INotifySvc, ocSecuritySvc: IOcSecuritySvc, errorReporter: IErrorReporter, ocDateSvc: OcDateSvc) {
                var vm = this;
                $scope.vm = this;

                // security
                vm.readonly = !ocSecuritySvc.isAuthorised('Security.Components.Warranty', AccessTypes.readWrite);

                vm.status = enums.screenStatus.loading;

                // set urls
                vm.apiUrls = componentUrls;
                vm.refUrls = referenceDataUrls;

                vm.paymentAmountCurrencyTypeRequired = false;

                this.$onInit = function () {
                    vm.claimId = vm.initParams.claimId;

                    vm.loadClaimDetails();

                    vm.labelColSpan = 4;
                    vm.rightBufferColSpan = 1;
                    vm.dataColSpan = (12 - vm.rightBufferColSpan - vm.labelColSpan);

                    vm.wnd.onClose = vm.onClose;

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: 'common.save_label',
                            name: 'saveButton',
                            click: 'save',
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: true,
                            value: 'common.cancel_label',
                            name: 'cancelButton',
                            click: 'cancel',
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: !vm.readonly,
                            disabled: true
                        },
                        cancelButton: {
                            visible: true,
                            disabled: false
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.onSave,
                        cancel: vm.onClose
                    };
                };

                // set form
                $scope.setForm = function () {
                    // add a watch to the dirty flag
                    $scope.$watch('vm.form.$dirty', function () {
                        vm.wnd.isDirty = vm.form.$dirty;
                    });
                };

                // core functions (load, save, undo)
                // load status details
                vm.loadClaimDetails = function () {

                    var criteria = {
                        claimId: vm.claimId
                    };

                    amtCommandQuerySvc.post(vm.apiUrls.getMwWarrantyClaim, criteria).then(function (response) {
                        if (response) {

                            vm.claimDetails = {
                                claimId: vm.claimId,
                                claimNumber: response.claimNumber,
                                serialNumber: response.formattedSerialNumber,
                                scrappedDate: response.formattedScrappedDate,
                                specification: response.specification,
                                status: response.claimStatus,
                                claimDate: response.claimDate,
                                claimAmount: response.claimAmount,
                                paymentDate: response.paymentDate,
                                paymentAmount: response.paymentAmount,
                                comment: response.comment
                            };

                            $scope.$watch('vm.claimDetails.paymentAmount.amount', function () {
                                if (vm.claimDetails.paymentAmount !== null && vm.claimDetails.paymentAmount.amount) {
                                    vm.paymentAmountCurrencyTypeRequired = true;
                                } else {
                                    vm.paymentAmountCurrencyTypeRequired = false;

                                    if (vm.claimDetails.paymentAmount !== null) {
                                        vm.claimDetails.paymentAmount.amount = null;
                                    }
                                }
                            });

                            vm.status = enums.screenStatus.ready;

                            $timeout(function () { vm.form.$setPristine(); }, 100);
                        }
                    }, function (error) {
                        vm.status = enums.screenStatus.error;

                        vm.claimDetails = {
                            errors: [error]
                        };

                        errorReporter.logError(error);
                    });
                };

                // update claim
                vm.onSave = function () {
                    if (vm.claimDetails.paymentAmount != null && vm.claimDetails.paymentAmount.amount == null && vm.claimDetails.paymentAmount.currencyType == null) {

                        vm.claimDetails.paymentAmount = null;
                    }

                    vm.saveCriteria = {
                        id: vm.claimId,
                        claimDate: ocDateSvc.removeLocalTimeZoneOffset(vm.claimDetails.claimDate),
                        statusId: helperSvc.getKey(vm.claimDetails.status),
                        claimAmount: {
                            amount: vm.claimDetails.claimAmount.amount,
                            currencyTypeId: helperSvc.getKey(vm.claimDetails.claimAmount.currencyType)
                        },
                        paymentDate: (vm.claimDetails.paymentDate !== null ?
                            ocDateSvc.removeLocalTimeZoneOffset(vm.claimDetails.paymentDate) : null),
                        paymentAmount: (vm.claimDetails.paymentAmount !== null ? {
                            amount: vm.claimDetails.paymentAmount.amount,
                            currencyTypeId: helperSvc.getKey(vm.claimDetails.paymentAmount.currencyType)
                        } : null),
                        comment: vm.claimDetails.comment
                    };

                    updateClaim(vm.saveCriteria);
                };

                function updateClaim(model) {
                    amtCommandQuerySvc.post(vm.apiUrls.updateMwWarrantyClaim, model).then(function (response) {

                        notifySvc.success(xlatSvc.xlat("component.messageWarrantyClaimUpdateSuccess"));
                        vm.form.$setPristine();

                        if (vm.wnd.onDataChanged) {
                            vm.wnd.onDataChanged();
                        }

                        closeWindow();

                    }).catch(function (error) {
                        errorReporter.logError(error);
                    });
                }

                // close window
                vm.onClose = function () {
                    closeWindow();
                };

                function closeWindow() {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        vm.form.$setPristine();

                        WindowFactory.closeWindow(vm.wnd);
                    });
                }

                $scope.$watch('vm.form.$invalid', function () {
                    vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.form.$pristine || vm.readonly;
                });

                $scope.$watch('vm.form.$pristine', function () {
                    vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.form.$pristine || vm.readonly;
                });
            }
        ]
    });
