//import angular from 'angular';
import FileManagement from '../../../services/fileManagement';
import BrowserSvc from '../../../services/browserSvc';
import OcDateSvc from '../../../services/ocDateSvc';



    angular.module('app.fieldSurveys')
        .service('fieldSurveyService', ['baseModuleService', '$q', 'WindowFactory', 'dataBroker', 'amtXlatSvc', 'fileManagement', 'amtConstants', 'ocDateSvc', 'browserSvc',
            function (BaseModuleService, $q: ng.IQService, WindowFactory: IWindowFactory, dataBroker: IDataBroker, amtXlatSvc: IAmtXlatSvc, fileManagement: FileManagement, amtConstants: IAmtConstants, ocDateSvc: OcDateSvc, browserSvc: BrowserSvc) {
                var internalName = "fieldSurvey";
                var vm = new BaseModuleService(internalName);

                vm.fieldSurveyFromReading = function (reading) {
                    if (!reading || reading === amtConstants.emptyGuid) {
                        return;
                    }
                    dataBroker.causeFromReading(reading).then(function (fs) {

                        if (fs.type === "FIELDSURVEY") {
                            WindowFactory.openItem({
                                component: 'field-surveys',
                                caption: amtXlatSvc.xlat('fieldSurvey.elementName'),
                                windowRelatedRecordId: fs.causeId,
                                initParams: {
                                    id: fs.causeId,
                                    date: fs.date,
                                    equipmentId: fs.vehicle,
                                },
                                headerOff: browserSvc.isMobile,
                                canMinimise: true,
                                canClose: true,
                                footerOff: true,
                                height: 700 + (!browserSvc.isMobile ? WindowFactory.headerHeight : 0),
                                width: 1000,
                                modal: browserSvc.isMobile,
                                parentWindow: null,
                                onDataChangeHandler: null
                            });
                        }
                        else {
                            WindowFactory.unimplementedMessage(fs.type + " Event");
                        }
                    });
                }

                // custom methods here
                vm.trimForUpload = function (records) {
                    return records.map(function (a) {

                        var newRecord = {
                            id: a.id,
                            siteId: a.siteId,
                            createdDate: ocDateSvc.removeLocalTimeZoneOffset(a.createdDate),
                            positionReadings: angular.copy(a.positionReadings), // need to copy so we can change, fix the visual inspection for upload
                            readingEventTypeCommentId: a.readingEventTypeCommentId,
                            hours: a.hours,
                            units: a.units,
                            distance: a.distance,
                            newHourMeter: a.newHourMeter,
                            newDistanceMeter: a.newDistanceMeter,
                            displayDescription: a.displayDescription,
                            pendingFieldSurveyId: a.pendingFieldSurveyId
                        };

                        // fix visual inspection creation dates
                        for (let pos of newRecord.positionReadings) {

                            pos.visualInspections = pos.visualInspections?.filter(v => v.modified);

                            for (let vi of pos.visualInspections || [])
                                vi.inspectionDateTime = ocDateSvc.dateTimeAsUTC(vi.inspectionDateTime);                            
                        }

                        return newRecord;
                    });
                };

                vm.wrapRecords = function (records) {
                    return { data: records, isMobile: browserSvc.isMobile };
                };

                vm.uploadFiles = async function (records) {

                    for (let record of records || []) {
                        for (let reading of record.positionReadings || []) {
                            for (let vi of reading.visualInspections || []) {
                                for (let attachment of vi.attachments || []) {
                                    let file = await fileManagement.uploadFile(attachment.id, false);
                                    // update the file id
                                    if (file) attachment.id = file.id;
                                }
                            }
                        }
                    }
                };

                // TODO: This could probably be moved to the baseModuleService if the header is passed too.
                /**
                 * Create and return the Alert window to be show for the given text / validation rule.
                 * @param {string} text - Main text for the Alert Window
                 * @param {string} [caption] - Caption text. Will default to "Error Message" if nothing is chosen.
                 * @return {Promise}
                 */
                function getOutcome(text, caption?, parameters?) {

                    var buttons = [];

                    if (browserSvc.isMobile) {

                        buttons.push("common.allow");
                        buttons.push("framework.close_label");

                    } else {
                        buttons.push("framework.ok_label");
                    }

                    return WindowFactory.alert(caption || "fieldSurvey.ValidationItem", buttons, text, parameters)
                        .then(vm.skipAllowed);
                }

                /* validation rules */
                // distance increased but hours didn't / hours increased by distance didn't
                vm.rules.push({
                    name: 'BR002',
                    tab: 'VehicleReadings',
                    check: function (fieldSurvey) {
                        if (typeof fieldSurvey.skipAllow[this.name] !== 'undefined') {
                            // the value would be set to the previous error data value if you want to validate if the skip is still valid;
                            return true;
                        }

                        if ((!fieldSurvey.hasDistanceMeter) || (!fieldSurvey.hasHoursMeter)) {
                            vm.deregisterError(fieldSurvey, this);
                            return true;
                        }

                        var prior = fieldSurvey.priorReadings;
                        if (prior &&
                            prior.HOURS && prior.HOURS.value &&
                            prior.DISTANCE && prior.DISTANCE.value &&
                            !fieldSurvey.newHourMeter &&
                            !fieldSurvey.newDistanceMeter
                        ) {

                            var h = fieldSurvey.hours || fieldSurvey.priorReadings.HOURS.value;
                            var d = fieldSurvey.distance || fieldSurvey.priorReadings.DISTANCE.value;

                            if ((h > fieldSurvey.priorReadings.HOURS.value) && (d <= fieldSurvey.priorReadings.DISTANCE.value)) {
                                console.warn('field survey hours increased but distance did not');
                                fieldSurvey.errorData = {
                                    fieldIdentifier: 'distance'
                                };
                                return false;
                            }

                            if ((d > fieldSurvey.priorReadings.DISTANCE.value) && (h <= fieldSurvey.priorReadings.HOURS.value)) {
                                console.warn('field survey distance increased but hours did not');
                                fieldSurvey.errorData = {
                                    fieldIdentifier: 'hours'
                                };
                                return false;
                            }

                        }
                        vm.deregisterError(fieldSurvey, this);
                        return true;
                    },
                    summary: function (fieldSurvey) {
                        return amtXlatSvc.xlat('fieldSurvey.HoursDistanceIncrease');
                    },
                    outcome: function () {
                        return getOutcome("fieldSurvey.HoursDistanceIncrease");
                    }
                });

                /* validation rules */
                // hours has decreased without new meter
                vm.rules.push({
                    name: 'BR003',
                    tab: 'VehicleReadings',
                    check: function (fieldSurvey) {
                        if (typeof fieldSurvey.skipAllow[this.name] !== 'undefined') {
                            // the value would be set to the previous error data value if you want to validate if the skip is still valid;
                            return true;
                        }

                        var prior = fieldSurvey.priorReadings;

                        if (prior &&
                            prior.HOURS && prior.HOURS.value &&
                            !fieldSurvey.newHourMeter
                        ) {

                            var h = fieldSurvey.hours || fieldSurvey.priorReadings.HOURS.value;

                            if (h < fieldSurvey.priorReadings.HOURS.value) {
                                console.warn('hours less than previous');
                                fieldSurvey.errorData = {
                                    fieldIdentifier: 'hours'
                                };
                                return false;
                            }
                        }

                        vm.deregisterError(fieldSurvey, this);
                        return true;
                    },
                    summary: function (fieldSurvey) {
                        return amtXlatSvc.xlat('fieldSurvey.HoursDecrease');
                    },
                    outcome: function () {
                        return getOutcome("fieldSurvey.HoursDecrease");
                    }
                });

                /* validation rules */
                // hours increased by more than actual hours elapsed since previous reading
                vm.rules.push({
                    name: 'BR004',
                    tab: 'VehicleReadings',
                    check: function (fieldSurvey) {

                        if (typeof fieldSurvey.skipAllow[this.name] !== 'undefined') {
                            // the value would be set to the previous error data value if you want to validate if the skip is still valid;
                            return true;
                        }

                        var prior = fieldSurvey.priorReadings;

                        if (prior &&
                            prior.HOURS && prior.HOURS.value &&
                            !fieldSurvey.newHourMeter &&
                            prior.HOURS.date) {

                            if (prior.HOURS.date > new Date()) {
                                // this occurs when the most recent field survey is set into the future. Shouldn't really happen'
                                return true;
                            }

                            var h = fieldSurvey.hours || fieldSurvey.priorReadings.HOURS.value;

                            var surveyDate = fieldSurvey.createdDate || new Date();

                            var hoursElapsed = (surveyDate - prior.HOURS.date) / (60 * 60 * 1000);

                            // @ts-ignore
                            if (hoursElapsed > 0 && hoursElapsed < (h - prior.HOURS.value)) {
                                console.warn('Hours increased more than physical hours');
                                fieldSurvey.errorData = {
                                    fieldIdentifier: 'hours'
                                };
                                return false;
                            }
                        }
                        vm.deregisterError(fieldSurvey, this);
                        return true;
                    },
                    summary: function (fieldSurvey) {
                        return amtXlatSvc.xlat('fieldSurvey.IncrorrectHoursElapsed');
                    },
                    outcome: function (fieldSurvey) {

                        var prior = fieldSurvey.priorReadings;
                        var surveyDate = fieldSurvey.createdDate || new Date();
                        var hours = fieldSurvey.hours || prior.HOURS.value;
                        var hoursElapsed = (surveyDate - prior.HOURS.date) / (60 * 60 * 1000);

                        return getOutcome("fieldSurvey.IncrorrectHoursElapsed_detail", null, [(hours - prior.HOURS.value), Math.trunc(hoursElapsed)]);
                    }
                });

                /* validation rules */
                // distance less than previous without new meter
                vm.rules.push({
                    name: 'BR005',
                    tab: 'VehicleReadings',
                    check: function (fieldSurvey) {
                        if (typeof fieldSurvey.skipAllow[this.name] !== 'undefined') {
                            // the value would be set to the previous error data value if you want to validate if the skip is still valid;
                            return true;
                        }
                        var prior = fieldSurvey.priorReadings;
                        if (prior &&
                            prior.DISTANCE && prior.DISTANCE.value &&
                            !fieldSurvey.newDistanceMeter
                        ) {
                            var d = fieldSurvey.distance || fieldSurvey.priorReadings.DISTANCE.value;
                            if (d < fieldSurvey.priorReadings.DISTANCE.value) {
                                console.warn('distance less than previous');
                                if (typeof fieldSurvey.skipAllow[this.name] !== 'undefined' && fieldSurvey.skipAllow[this.name] === d) {
                                    return true;
                                }
                                fieldSurvey.errorData = {
                                    fieldIdentifier: 'distance'
                                };
                                return false;
                            }
                        }
                        vm.deregisterError(fieldSurvey, this);
                        return true;
                    },
                    summary: function (fieldSurvey) {
                        return amtXlatSvc.xlat('fieldSurvey.distanceDecrease');
                    },
                    outcome: function () {
                        return getOutcome("fieldSurvey.distanceDecrease");
                    }
                });


                /* validation rules */
                // average speed greater than max speed
                vm.rules.push({
                    name: 'BR006',
                    tab: 'VehicleReadings',
                    check: function (fieldSurvey) {

                        if (typeof fieldSurvey.skipAllow[this.name] !== 'undefined') {
                            // the value would be set to the previous error data value if you want to validate if the skip is still valid;
                            return true;
                        }

                        var prior = fieldSurvey.priorReadings;

                        if (prior && prior.HOURS && prior.HOURS.value &&
                            prior.DISTANCE && prior.DISTANCE.value &&
                            fieldSurvey.hours && fieldSurvey.distance &&
                            !fieldSurvey.newHourMeter && !fieldSurvey.newDistanceMeter) {

                            var h = fieldSurvey.hours;
                            var d = fieldSurvey.distance;

                            var maxSpeed = fieldSurvey.distanceUnit.unitAbbreviation == 'km' ? 59 : 36;

                            var avg = (d - prior.DISTANCE.value) / (h - prior.HOURS.value);

                            if (avg > maxSpeed) {
                                console.warn('Average speed too high');
                                fieldSurvey.errorData = {
                                    fieldIdentifier: 'distance'
                                };
                                return false;
                            }
                        }

                        vm.deregisterError(fieldSurvey, this);
                        return true;
                    },
                    summary: function (fieldSurvey) {
                        return amtXlatSvc.xlat('fieldSurvey.HighAverageSpeed_' + fieldSurvey.distanceUnit.unitAbbreviation);
                    },
                    outcome: function (fieldSurvey) {
                        return getOutcome("fieldSurvey.HighAverageSpeed_" + fieldSurvey.distanceUnit.unitAbbreviation);
                    }
                });

                /* validation rules */
                // adjusted pressure/temperature entered without pressure reading
                vm.rules.push({
                    name: 'BR007',
                    tab: 'TyreReadings',
                    check: function (fieldSurvey) {

                        // for each reading
                        var i = 0;
                        if (fieldSurvey.errorData && fieldSurvey.errorData.continue) {
                            i = fieldSurvey.errorData.continue;
                        }

                        for (; i < fieldSurvey.positionReadings.length; i++) {

                            vm.deregisterError(fieldSurvey, this, 'BR007:' + i);

                            var reading = fieldSurvey.positionReadings[i];
                            var skip = fieldSurvey.skipAllow[this.name];
                            var canSkip = (typeof skip !== 'undefined' && skip['Reading' + reading.positionNo]);

                            if ((reading.temp || reading.adjPressure) && !reading.pressure) {

                                fieldSurvey.errorData = {
                                    index: i,
                                    reading: reading,
                                    identifier: 'BR007:' + i,
                                    fieldIdentifier: 'pressure,' + reading.positionNo,
                                    continue: i + 1
                                };

                                // skipping allowed marker.
                                if (!canSkip) {
                                    fieldSurvey.errorData['Reading' + reading.positionNo] = true;
                                    return false;
                                } else {
                                    continue;
                                }
                            }
                        }
                        return true;
                    },
                    summary: function (fieldSurvey) {
                        var header = amtXlatSvc.xlat('fieldSurvey.ReadingPos', fieldSurvey.errorData.reading.positionLabel, fieldSurvey.errorData.reading.positionNo);
                        return header + " " + amtXlatSvc.xlat('fieldSurvey.PressureFirst');
                    },
                    outcome: function (fieldSurvey) {
                        return getOutcome(
                            'fieldSurvey.PressureFirst',
                            amtXlatSvc.xlat('fieldSurvey.ReadingPos', fieldSurvey.errorData.reading.positionLabel, fieldSurvey.errorData.reading.positionNo)
                        );
                    }
                });

                // nitrogen must be between 0 and 100
                vm.rules.push({
                    name: 'NitrogenRange',
                    tab: 'TyreReadings',
                    check: function (fieldSurvey) {

                        // for each reading
                        let i = 0;
                        if (fieldSurvey.errorData && fieldSurvey.errorData.continue) {
                            i = fieldSurvey.errorData.continue;
                        }

                        for (; i < fieldSurvey.positionReadings.length; i++) {

                            vm.deregisterError(fieldSurvey, this, 'NitrogenRange:' + i);

                            let reading = fieldSurvey.positionReadings[i];

                            let skip = fieldSurvey.skipAllow[this.name];
                            let canSkip = (typeof skip !== 'undefined' && skip['Reading' + reading.positionNo]);

                            if (reading.nitrogen && (reading.nitrogen < 0 || reading.nitrogen > 100)) {

                                fieldSurvey.errorData = {
                                    index: i,
                                    reading: reading,
                                    identifier: 'NitrogenRange:' + i,
                                    fieldIdentifier: 'nitrogen,' + reading.positionNo,
                                    continue: i + 1
                                };

                                // skipping allowed marker.
                                if (!canSkip) {
                                    fieldSurvey.errorData['Reading' + reading.positionNo] = true;
                                    return false;
                                } else {
                                    continue;
                                }
                            }
                        }
                        return true;
                    },
                    summary: function (fieldSurvey) {
                        let header = amtXlatSvc.xlat('fieldSurvey.ReadingPos', fieldSurvey.errorData.reading.positionLabel, fieldSurvey.errorData.reading.positionNo);
                        return header + " " + amtXlatSvc.xlat('fieldSurvey.nitrogenOutOfRange');
                    },
                    outcome: function (fieldSurvey) {
                        return getOutcome(
                            'fieldSurvey.nitrogenOutOfRange',
                            amtXlatSvc.xlat('fieldSurvey.ReadingPos', fieldSurvey.errorData.reading.positionLabel, fieldSurvey.errorData.reading.positionNo)
                        );
                    }
                });

                // missing rtd1 or rtd2
                vm.rules.push({
                    name: 'BR008',
                    tab: 'TyreReadings',
                    check: function (fieldSurvey) {

                        // for each reading
                        var i = 0;
                        if (fieldSurvey.errorData && fieldSurvey.errorData.continue) {
                            i = fieldSurvey.errorData.continue;
                        }

                        for (; i < fieldSurvey.positionReadings.length; i++) {

                            vm.deregisterError(fieldSurvey, this, 'BR008:' + i);

                            var reading = fieldSurvey.positionReadings[i];
                            var skip = fieldSurvey.skipAllow[this.name];
                            var canSkip = (typeof skip !== 'undefined' && skip['Reading' + reading.positionNo]);

                            if (reading.rtd1 && !reading.rtd2) {
                                fieldSurvey.errorData = {
                                    index: i,
                                    reading: reading,
                                    data: ["RTD2", i + 1],
                                    identifier: 'BR008:' + i,
                                    fieldIdentifier: 'rtd2,' + reading.positionNo,
                                    continue: i + 1
                                };

                                // skipping allowed marker.
                                if (!canSkip) {
                                    fieldSurvey.errorData['Reading' + reading.positionNo] = true;
                                    return false;
                                } else {
                                    continue;
                                }
                            }

                            if (!reading.rtd1 && reading.rtd2) {
                                fieldSurvey.errorData = {
                                    index: i,
                                    reading: reading,
                                    data: ["RTD1", i + 1],
                                    identifier: 'BR008:' + i,
                                    fieldIdentifier: 'rtd1,' + reading.positionNo,
                                    continue: i + 1
                                };

                                // skipping allowed marker.

                                if (!canSkip) {
                                    fieldSurvey.errorData['Reading' + reading.positionNo] = true;
                                    return false;
                                } else {
                                    continue;
                                }
                            }

                        }
                        return true;
                    },
                    summary: function (fieldSurvey) {
                        var header = amtXlatSvc.xlat('fieldSurvey.ReadingPos', fieldSurvey.errorData.reading.positionLabel, fieldSurvey.errorData.reading.positionNo);
                        var text = amtXlatSvc.xlat('fieldSurvey.ReadingRequired', ...fieldSurvey.errorData.data);
                        return header + " " + text;
                    },
                    outcome: function (fieldSurvey) {
                        return getOutcome(
                            'fieldSurvey.ReadingRequired',
                            amtXlatSvc.xlat('fieldSurvey.ReadingPos', fieldSurvey.errorData.reading.positionLabel, fieldSurvey.errorData.reading.positionNo),
                            [fieldSurvey.errorData.data[0], fieldSurvey.errorData.data[1]]
                        );
                    }
                });

                // rtd value greater than previous rtd value
                vm.rules.push({
                    name: 'BR009',
                    tab: 'TyreReadings',
                    check: function (fieldSurvey) {

                        var positions = fieldSurvey.positions;

                        var i = 0;
                        if (fieldSurvey.errorData && fieldSurvey.errorData.continue) {
                            i = fieldSurvey.errorData.continue;
                        }

                        for (; i < fieldSurvey.positionReadings.length; i++) {

                            vm.deregisterError(fieldSurvey, this, 'BR009:' + i);

                            var reading = fieldSurvey.positionReadings[i];
                            var skip = fieldSurvey.skipAllow[this.name];
                            var canSkip = (typeof skip !== 'undefined' && skip['Reading' + reading.positionNo]);

                            if (!reading.rtd1 || !reading.rtd2 || reading.tyreSerial) {
                                continue; // no readings entered or different tyre
                            }

                            var avg = (Number(reading.rtd1) + Number(reading.rtd2)) / 2;
                            var pos = positions[reading.positionId];

                            if (pos && pos.TyreFitment && pos.TyreFitment.remainingDepth && pos.TyreFitment.remainingDepth.value) {

                                var lastValue = pos.TyreFitment.remainingDepth.value;

                                if (avg > lastValue) {

                                    fieldSurvey.errorData = {
                                        index: i,
                                        current: avg,
                                        previous: lastValue,
                                        serial: pos.TyreFitment.serialNumber,
                                        newSerial: reading.tyreSerial,
                                        reading: reading,
                                        identifier: 'BR009:' + i,
                                        fieldIdentifier: 'rtd1,' + reading.positionNo,
                                        continue: i + 1
                                    };

                                    // skipping allowed marker.
                                    if (!canSkip) {
                                        fieldSurvey.errorData['Reading' + reading.positionNo] = true;
                                        return false;
                                    } else {
                                        continue;
                                    }
                                }
                            }
                        }
                        return true;
                    },
                    summary: function (fieldSurvey) {
                        var header = amtXlatSvc.xlat('fieldSurvey.ReadingPos', fieldSurvey.errorData.reading.positionLabel, fieldSurvey.errorData.reading.positionNo);
                        return header + " " + amtXlatSvc.xlat('fieldSurvey.higherReading');
                    },
                    outcome: function (fieldSurvey) {

                        if (browserSvc.isMobile) {

                            if (vm.wnd) {
                                return $q.resolve(); // already error in progress;
                            }

                            var _self = this;
                            var defer = $q.defer();

                            var data = fieldSurvey.errorData;
                            var header = amtXlatSvc.xlat('fieldSurvey.ReadingError', fieldSurvey.errorData.reading.positionLabel, fieldSurvey.errorData.reading.positionNo);

                            vm.wnd = WindowFactory.openItem({
                                component: 'vehicle-validation',
                                caption: header,
                                initParams: data,
                                width: 750,
                                modal: true,
                                onDataChangeHandler: (newSerial) => {
                                    vm.wnd = null;
                                    if (newSerial) {
                                        data.reading.tyreSerial = newSerial;
                                        defer.resolve();
                                    } else {
                                        defer.reject();
                                    }
                                }
                                /*function (val, newSerial) {
                                    vm.wnd = null;
                                    if (val) {
                                        if (newSerial) {
                                            data.reading.tyreSerial = newSerial;
                                        }
                                        else {
                                            // allow this anyway
                                            fieldSurvey.skipAllow[_self.name] = fieldSurvey.skipAllow[_self.name] || {};
                                            angular.extend(fieldSurvey.skipAllow[vm.currentRule.name], vm.moduleItem.errorData);
                                        }
                                        defer.resolve();
                                    }
                                    else {
                                        defer.reject();
                                    }
                                }*/
                            });

                            vm.wnd.onClose = function () {
                                WindowFactory.closeWindow(vm.wnd);
                                vm.wnd = null;
                            };

                            return defer.promise;
                        } else {
                            // don't want them to change serial or 'allow' on desktop
                            return getOutcome(
                                'fieldSurvey.averageDepthGreaterThanPrevious',
                                amtXlatSvc.xlat('fieldSurvey.ReadingPos', fieldSurvey.errorData.reading.positionLabel, fieldSurvey.errorData.reading.positionNo),
                                [fieldSurvey.errorData.serial, fieldSurvey.errorData.current, fieldSurvey.errorData.previous]
                            );
                        }
                    }
                });

                // hours missing
                vm.rules.push({
                    name: 'BR010',
                    atEnd: true,
                    tab: 'VehicleReadings',
                    check: function (fieldSurvey) {

                        if (typeof fieldSurvey.skipAllow[this.name] !== 'undefined') {
                            // the value would be set to the previous error data value if you want to validate if the skip is still valid;
                            return true;
                        }

                        var h = fieldSurvey.hours;

                        if (!h) {
                            for (var i = 0; i < fieldSurvey.positionReadings.length; i++) {
                                var reading = fieldSurvey.positionReadings[i];
                                if (reading.rtd1 || reading.rtd2) {
                                    fieldSurvey.errorData = {
                                        fieldIdentifier: 'hours'
                                    };
                                    return false;
                                }
                            }
                        }

                        vm.deregisterError(fieldSurvey, this);
                        return true;
                    },
                    summary: function (fieldSurvey) {
                        return amtXlatSvc.xlat('fieldSurvey.hoursIsMandatory');
                    },
                    outcome: function () {
                        return getOutcome("fieldSurvey.hoursIsMandatory");
                    }
                });

                return vm;
            }]);