//import angular from 'angular';
import tmpl from './stocktakeComponentExists.html';


angular.module("app.stocktake").component('stocktakeComponentExists', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['WindowFactory', '$scope', 'enums', 'amtXlatSvc',
            function (WindowFactory: IWindowFactory, $scope: IVmScope, enums, amtXlatSvc: IAmtXlatSvc) {

                var vm = this;
                $scope.vm = this;
                
                this.$onInit = function () {                   

                    vm.data = vm.wnd.initParams;   

                    vm.componentTypeDescription = amtXlatSvc.xlat('stocktake.' + vm.data.componentType);

                    vm.data.component.includeComment = true;

                    let changes = [];

                    if (vm.data.component.status.name !== vm.data.target.status.name) {
                        changes.push(amtXlatSvc.xlat('stocktake.changeStatus', vm.data.target.status.name));
                    }

                    if (vm.data.component.specification.id !== vm.data.target.specification.id) {
                        changes.push(amtXlatSvc.xlat('stocktake.changeSpecification', vm.data.target.specification.name));
                    }

                    if (vm.data.component.location.id !== vm.data.target.location.id) {
                        changes.push(amtXlatSvc.xlat('stocktake.changeLocation', vm.data.target.location.name));
                    }

                    // pre-fill the comment
                    vm.data.component.comment = String.format('{0} - {1}', vm.data.serialNumber, changes.join(', '));

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: 'framework.save_label',
                            name: 'saveButton',
                            click: 'save',
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: true,
                            value: 'common.cancel_label',
                            name: 'cancelButton',
                            click: 'cancel',
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: true,
                            disabled: false
                        },
                        cancelButton: {
                            visible: true,
                            cancel: false
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.save,
                        cancel: vm.cancel
                    };

                    vm.wnd.onClose = closeWindow;
                };       

                vm.cancel = function () {
                    closeWindow();
                };

                vm.save = function () {
                    
                    vm.data.component.originalId = (vm.data.component.location.name || amtXlatSvc.xlat("stocktake.noLocation")) + ";"
                        + vm.data.component.status.name + ";"
                        + vm.data.component.specification.name + ";"
                        + vm.data.component.serialNumber;

                    vm.data.component.status = vm.data.target.status;
                    vm.data.component.specification = vm.data.target.specification;
                    vm.data.component.location = vm.data.target.location;
                    vm.data.component.equipmentTypeSpecificationTypeId = vm.data.t

                    vm.data.component.id = vm.data.component.location.name + ";"
                        + vm.data.component.status.name + ";"
                        + vm.data.component.specification.name + ";"
                        + vm.data.component.serialNumber;

                    if (vm.wnd.onDataChanged) {
                        vm.wnd.onDataChanged(vm.data.component);
                    }

                    closeWindow();
                };

                function closeWindow() {                 
                    WindowFactory.closeWindow(vm.wnd);     
                }
            }
        ]
    });
