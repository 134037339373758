/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Represents the group header column template of the Excel Export column component
 * ([see example]({% slug columns_excel-export %}#toc-group-header-column-template)).
 */
var GroupHeaderColumnTemplateDirective = /** @class */ (function () {
    function GroupHeaderColumnTemplateDirective(templateRef) {
        this.templateRef = templateRef;
    }
    GroupHeaderColumnTemplateDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoExcelExportGroupHeaderColumnTemplate]'
        }),
        tslib_1.__param(0, core_1.Optional()),
        tslib_1.__metadata("design:paramtypes", [core_1.TemplateRef])
    ], GroupHeaderColumnTemplateDirective);
    return GroupHeaderColumnTemplateDirective;
}());
exports.GroupHeaderColumnTemplateDirective = GroupHeaderColumnTemplateDirective;
