//import angular from 'angular';


angular.module('app.fieldSurveys')
        .service('safetyAuditService', ['baseModuleService', 'amtXlatSvc',
            function (BaseModuleService, amtXlatSvc) {

            var internalName = "safetyAudit";
            var vm = new BaseModuleService(internalName);

            // custom methods here
            vm.active = false;
            vm.description = amtXlatSvc.xlat('common.comingSoon');
            vm.generatedClass = 'module-status-inactive';
            vm.readOnly = false; // this is normally set on then cleared as it checks it's counts.
            vm.route = "mobile.landing"; // overwride the default to do nothing;

            return vm;
        }]);
