import { KeyValue } from "@angular/common";
import { Component, HostBinding, Inject, OnDestroy, OnInit } from "@angular/core";
import { XlatPipe } from "../../../../amt/i18n/xlat-pipe";
import { ConditionMonitoringService, IDisplayVehicle } from "../condition-monitoring.service";
import { ListBoxToolbarConfig } from "@progress/kendo-angular-listbox";

export const ICON_WIDTH = 96;
export const ICON_HEIGHT = 96;

export interface IGroup<T> {
    name: string,
    members: T[];
}

export interface IRendererConfig {
    lookupTable: Array<string>,
    issues: Map<number, string>
}

@Component({
    selector: 'condition-monitoring-base',
    template: ``
 })
export class ConditionMonitoringBaseComponent implements OnInit, OnDestroy {
    private _title: string;
    isProcessing: boolean;
    statusText: string;

    //These are set in the child components
    protected _displayBy: Array<any> = [];
    displayBySelectedItem: any;

    private _vehicleSpecs: Array<string> = [];
    private _specsToShow: Array<string> = [];

    readonly sortBy: Array<any> = [
        { text: this.xlatPipe.transform("conditionmonitoring.none"), value: 1 },
        { text: this.xlatPipe.transform("conditionmonitoring.vehicle_application"), value: 2 },
        { text: this.xlatPipe.transform("conditionmonitoring.vehicle_specification"), value: 3 }]; //{ text: "Last Inspected Date", value: 0 }
    //Default item
    sortBySelectedItem: any = this.sortBy.find(elem => elem.value === 2);

    public setTitle(subTitle: string) {
        this._title = `${this.xlatPipe.transform("DashboardWidget.ConditionMonitoring")} - ${this.xlatPipe.transform(subTitle)}`;
    }

    public get VehicleSpecs() {
        return this._vehicleSpecs;
    }

    public get SpecsToShow() {
        return this._specsToShow;
    }

    public get DisplayBy() {
        return this._displayBy;
    }

    public get title() {
        return this._title;
    }

    public rendererConfig = { lookupTable: [], issues: new Map<number, string>() } as IRendererConfig;

    public toolbarSettings: ListBoxToolbarConfig = {
        tools: ["transferAllTo", "transferFrom", "transferTo", "transferAllFrom" ],
    };

    @HostBinding("style.--img-w") public readonly cssImgWidth = ICON_WIDTH;
    @HostBinding("style.--img-h") public readonly cssImgHeight = ICON_HEIGHT;

    constructor(protected monSvc: ConditionMonitoringService, protected xlatPipe: XlatPipe,
        @Inject('WindowFactory') protected WindowFactory: any) {
    }
    ngOnDestroy(): void { }
    ngOnInit(): void {
        this.setProcessing();

        //configure dropdowns
        this.configureDropdowns();
        //Should load localstorage here for the last saved values and set?
    }

    protected setProcessing() {
        this.statusText = this.xlatPipe.transform("conditionmonitoring.loading_vehicle_data");
        this.isProcessing = true;
    }

    private configureDropdowns() {
        this.configureVehicleSpecsDropdown();
    }

    public showFilters: boolean = false;

    public onShowFiltersClick() {
        if (this.showFilters) {
            this.onUpdateButtonClick();
        } else {
            this.toggleFilter();
        }
    }

    protected toggleFilter(updateClicked: boolean = false) {
        if (updateClicked && !this.showFilters)
            return;

        this.showFilters = !this.showFilters;
    }

    //Implemented in child components
    public onUpdateButtonClick(): void { }

    //Pass a 'new' date into this so we don't corupot the original date (passed by reference)
    protected addDays(date: Date, days: number): Date {
        date.setDate(date.getDate() + days);
        return date;
    }
        
    protected groupBy(vehicles: IDisplayVehicle[], groupBy: number) {
        let groupMap: Map<String, IDisplayVehicle[]>;
        let result: Array<IGroup<IDisplayVehicle>>;

        //Initial serialNo sort
        vehicles.sort(({ serialNo: a }, { serialNo: b }) => a.localeCompare(b));

        switch (groupBy) {
            case 0: {
                //Last Inspection Date
                groupMap = vehicles.reduce(
                    (acc, v) => acc.set(v.model, (acc.get(v.model) || []).concat(v)),
                    new Map<string, IDisplayVehicle[]>());
                break;
            }

            case 1: {
                //None
                groupMap = vehicles.reduce(
                    (acc, v) => acc.set("Vehicles", (acc.get("Vehicles") || []).concat(v)),
                    new Map<string, IDisplayVehicle[]>());
                result = Array.from(groupMap, e => <IGroup<IDisplayVehicle>>({ name: e[0], members: e[1] }));
                break;
            }

            case 3: {
                //Vehicle Specification
                groupMap = vehicles.reduce(
                    (acc, v) => acc.set(v.model, (acc.get(v.model) || []).concat(v)),
                    new Map<string, IDisplayVehicle[]>());
                result = Array.from(groupMap, e => <IGroup<IDisplayVehicle>>({ name: e[0], members: e[1] })).sort(({ name: a }, { name: b }) => a == "Haul Truck" ? -1 : (b == "Haul Truck" ? 1 : a.localeCompare(b)));
                break;
            }

            case 2:
            default:
                //Vehicle application
                //from: https://stackoverflow.com/a/47752730
                groupMap = vehicles.reduce(
                    (acc, v) => acc.set(v.application, (acc.get(v.application) || []).concat(v)),
                    new Map<string, IDisplayVehicle[]>());
                result = Array.from(groupMap, e => <IGroup<IDisplayVehicle>>({ name: e[0], members: e[1] })).sort(({ name: a }, { name: b }) => a == "Haul Truck" ? -1 : (b == "Haul Truck" ? 1 : a.localeCompare(b)));
        }

        return result;
    }

    public trackByGroup(i: number, group: KeyValue<string, any>) {
        return group.key;
    }

    public trackByVehicle(i: number, veh: IDisplayVehicle) {
        return veh.serialNo;
    }

    public click(v: IDisplayVehicle) {
        this.WindowFactory.openItem({
            component: 'condition-monitoring-details',
            caption: this.xlatPipe.transform('conditionmonitoring.details_title', v.serialNo),
            windowRelatedRecordId: v.serialNo,
            initParams: {
                vehicle: v,
                serialNumber: v.serialNo
            },
            width: 800,
            height: 850,
            modal: false,
            footerOff: true,
            isAngular: true
        });
    }

    private async configureVehicleSpecsDropdown(selected?: any) { //Change any to a dropdowntype interface?
        this.monSvc.VehicleSpecs$.subscribe(vehSpecs => {
            this._vehicleSpecs = Array.from(vehSpecs.values()).map(elem => (elem.model)).sort();
        });
    }
}