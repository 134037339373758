/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_licensing_1 = require("@progress/kendo-licensing");
var package_metadata_1 = require("../package-metadata");
var util_1 = require("./util");
/**
 * @hidden
 */
var ProgressBarBase = /** @class */ (function () {
    /**
     * @hidden
     */
    function ProgressBarBase(localization) {
        var _this = this;
        this.localization = localization;
        this.widgetClasses = true;
        this.roleAttribute = 'progressbar';
        /**
         * The maximum value of the ProgressBar.
         * Defaults to `100`.
         */
        this.max = 100;
        /**
         * The minimum value of the ProgressBar.
         * Defaults to `0`.
         */
        this.min = 0;
        /**
         * The value of the ProgressBar.
         * Has to be between `min` and `max`.
         * By default, the value is equal to the `min` value.
         */
        /**
         * The value of the ProgressBar.
         * Has to be between `min` and `max`.
         * Defaults to `0`.
         */
        this.value = 0;
        /**
         * Defines the orientation of the ProgressBar
         * ([see example]({% slug progressbar_orientation %})).
         * Defaults to `horizontal`.
         */
        this.orientation = 'horizontal';
        /**
         * If set to `true`, the ProgressBar will be disabled
         * ([see example]({% slug progressbar_disabled %})).
         * It will still allow you to change its value.
         * Defaults to `false`.
         */
        this.disabled = false;
        /**
         * If set to `true`, the ProgressBar will be reversed
         * ([see example]({% slug progressbar_direction %})).
         * Defaults to `false`.
         */
        this.reverse = false;
        /**
         * Sets the `indeterminate` state of the ProgressBar.
         * Defaults to `false`.
         */
        this.indeterminate = false;
        this.displayValue = 0;
        this.previousValue = 0;
        kendo_licensing_1.validatePackage(package_metadata_1.packageMetadata);
        this.localizationChangeSubscription = localization.changes.subscribe(function (_a) {
            var rtl = _a.rtl;
            _this.direction = rtl ? 'rtl' : 'ltr';
        });
    }
    Object.defineProperty(ProgressBarBase.prototype, "isHorizontal", {
        get: function () {
            return this.orientation === 'horizontal';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressBarBase.prototype, "isVertical", {
        get: function () {
            return this.orientation === 'vertical';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressBarBase.prototype, "disabledClass", {
        get: function () {
            return this.disabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressBarBase.prototype, "reverseClass", {
        get: function () {
            return this.reverse;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressBarBase.prototype, "indeterminateClass", {
        get: function () {
            return this.indeterminate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressBarBase.prototype, "dirAttribute", {
        get: function () {
            return this.direction;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressBarBase.prototype, "ariaMinAttribute", {
        get: function () {
            return String(this.min);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressBarBase.prototype, "ariaMaxAttribute", {
        get: function () {
            return String(this.max);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressBarBase.prototype, "ariaValueAttribute", {
        get: function () {
            return this.indeterminate ? undefined : String(this.displayValue);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressBarBase.prototype, "isCompleted", {
        /**
         * @hidden
         */
        get: function () {
            return this.value === this.max;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressBarBase.prototype, "statusWidth", {
        /**
         * @hidden
         */
        get: function () {
            return this.orientation === 'horizontal' ? this._progressRatio * 100 : 100;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressBarBase.prototype, "statusHeight", {
        /**
         * @hidden
         */
        get: function () {
            return this.orientation === 'vertical' ? this._progressRatio * 100 : 100;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressBarBase.prototype, "statusWrapperWidth", {
        /**
         * @hidden
         */
        get: function () {
            return this.orientation === 'horizontal' ? 100 / this._progressRatio : 100;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressBarBase.prototype, "statusWrapperHeight", {
        /**
         * @hidden
         */
        get: function () {
            return this.orientation === 'vertical' ? 100 / this._progressRatio : 100;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressBarBase.prototype, "_progressRatio", {
        get: function () {
            return util_1.calculateRatio(this.min, this.max, this.displayValue);
        },
        enumerable: true,
        configurable: true
    });
    ProgressBarBase.prototype.ngOnChanges = function (changes) {
        var min = util_1.extractValueFromChanges(changes, 'min', this.min);
        var max = util_1.extractValueFromChanges(changes, 'max', this.max);
        var value = util_1.extractValueFromChanges(changes, 'value', this.value);
        if (changes.min || changes.max || changes.value) {
            if (changes.min || changes.max) {
                util_1.validateRange(min, max);
            }
            if (changes.value) {
                if (value == null || Number.isNaN(value)) {
                    this.value = min;
                }
                var previousValue = this.displayValue;
                this.displayValue = util_1.adjustValueToRange(this.min, this.max, value);
                this.previousValue = previousValue;
            }
            this.min = min;
            this.max = max;
            this.displayValue = util_1.adjustValueToRange(this.min, this.max, value);
        }
    };
    ProgressBarBase.prototype.ngOnDestroy = function () {
        if (this.localizationChangeSubscription) {
            this.localizationChangeSubscription.unsubscribe();
        }
    };
    tslib_1.__decorate([
        core_1.HostBinding('class.k-widget'),
        core_1.HostBinding('class.k-progressbar'),
        tslib_1.__metadata("design:type", Boolean)
    ], ProgressBarBase.prototype, "widgetClasses", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-progressbar-horizontal'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ProgressBarBase.prototype, "isHorizontal", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-progressbar-vertical'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ProgressBarBase.prototype, "isVertical", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-state-disabled'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ProgressBarBase.prototype, "disabledClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-progressbar-reverse'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ProgressBarBase.prototype, "reverseClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-progressbar-indeterminate'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ProgressBarBase.prototype, "indeterminateClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.dir'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], ProgressBarBase.prototype, "dirAttribute", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.role'),
        tslib_1.__metadata("design:type", String)
    ], ProgressBarBase.prototype, "roleAttribute", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('attr.aria-valuemin'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], ProgressBarBase.prototype, "ariaMinAttribute", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.aria-valuemax'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], ProgressBarBase.prototype, "ariaMaxAttribute", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.aria-valuenow'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], ProgressBarBase.prototype, "ariaValueAttribute", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], ProgressBarBase.prototype, "max", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], ProgressBarBase.prototype, "min", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], ProgressBarBase.prototype, "value", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ProgressBarBase.prototype, "orientation", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], ProgressBarBase.prototype, "disabled", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], ProgressBarBase.prototype, "reverse", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], ProgressBarBase.prototype, "indeterminate", void 0);
    return ProgressBarBase;
}());
exports.ProgressBarBase = ProgressBarBase;
