//import angular from 'angular';


angular.module('app.admin')
        .controller("sysMaintDamageCauseSearchCtrl", [
            "$scope", "enums", "ocSecuritySvc", "amtXlatSvc", "sysMaintDamageCauseSvc", "ocConfigSvc",
            function (
                $scope, enums, ocSecuritySvc, amtXlatSvc, svc, ocConfigSvc
            ) {
                var vm = this;

                vm.processing = false;
                vm.screenStatusEnums = enums.screenStatus;
                vm.status = vm.screenStatusEnums.loading;
                vm.gridControl = {};
                vm.selectedItem = null;

                vm.svc = svc;
                vm.config = ocConfigSvc;

                vm.criteria = {
                    filterValues: {
                        activeOptions: [],
                        ids: []
                    }
                };

                vm.damageCauseFilterControl = {};

                function init() {
                    vm.svc.loadDefaultsAsync().then(function () {

                        vm.defaultActiveStates = vm.svc.activeStates.filter(function (state) {
                            return state.default === true;
                        });

                        vm.status = vm.screenStatusEnums.ready;
                    });
                }

                vm.onSelectedItemChanged = function (items) {
                    if (items != null && items.length === 1) {
                        vm.selectedItem = items[0];
                    } else {
                        vm.selectedItem = null;
                    }
                };

                vm.setHeaderHeight = function (height, offset) {
                    vm.gridTop = height + offset;
                };

                vm.export = function () {
                    vm.svc.export(vm.criteria);
                };

                vm.refreshGrid = function () {
                    vm.gridControl.refresh();
                };

                $scope.editItem = function (item) {
                    vm.svc.openItem(item, function () {
                        vm.refreshGrid();
                        vm.damageCauseFilterControl.search();
                    });
                };

                $scope.addItem = function () {
                    vm.svc.addItem(function () {
                        vm.refreshGrid();
                        vm.damageCauseFilterControl.search();
                    });
                };

                init();
            }
        ]
        )
