import { Component, OnInit, HostBinding, ChangeDetectionStrategy, AfterViewInit, Input } from '@angular/core';
import { ConditionMonitoringService, IDisplayVehicle } from '../condition-monitoring.service'
import { map, tap } from 'rxjs/operators';
import { KeyValue } from '@angular/common';
import { XlatPipe } from '../../../../amt/i18n/xlat-pipe';
import { of, Observable } from 'rxjs';
import { Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { SelectEvent } from '@progress/kendo-angular-layout';

export const ICON_WIDTH = 196;
export const ICON_HEIGHT = 196;

interface IGroup<T> {
    name: string,
    members: T[];
}
   
@Component({
    selector: 'condition-monitoring-details',
    //changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './condition-monitoring-details.component.html',
    styleUrls: ['./condition-monitoring-details.component.scss'],
    providers: [
        { provide: 'desiredWidth', useValue: ICON_WIDTH },
        { provide: 'desiredHeight', useValue: ICON_HEIGHT },
        ConditionMonitoringService]
})
export class ConditionMonitoringDetailsComponent implements OnInit {
    title: string;
    @Input() wnd;
    @Input() initParams;
    vehicle: IDisplayVehicle;
    isProcessing: boolean = false;

    constructor(public monSvc: ConditionMonitoringService, private xlatPipe: XlatPipe, @Inject('WindowFactory') private WindowFactory: any) {
        this.title = xlatPipe.transform("DashboardWidget.ConditionMonitoring");
    }

    @HostBinding("style.--img-w") public readonly cssImgWidth = ICON_WIDTH;
    @HostBinding("style.--img-h") public readonly cssImgHeight = ICON_HEIGHT;

    ngOnInit(): void {
        this.isProcessing = true;
        this.vehicle = this.initParams.vehicle;
    }

    onDoneProcessing(done: boolean) {
        if (done)
            this.isProcessing = false;
    }

    public onTabSelect(e: SelectEvent): void {
        //console.log(e);
    }

}
