//import angular from 'angular';
import tmpl from './details.html';



angular.module('app.admin').component('fleetDetails', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['$scope', '$state', '$window', '$route', 'amtCommandQuerySvc', 'enums', 'amtXlatSvc', 'referenceDataUrls',
            'fleetUrls', 'equipmentUrls', 'confirmSvc', '$timeout', 'WindowFactory', 'ocConfigSvc', 'notifySvc', 'ocSecuritySvc', 'errorReporter',
            function ($scope, $state, $window, $route, amtCommandQuerySvc, enums, xlatSvc, referenceDataUrls, apiUrls, equipmentUrls,
                confirmSvc, $timeout: ng.ITimeoutService, WindowFactory: IWindowFactory, ocConfigSvc, notifySvc, ocSecuritySvc, errorReporter) {

                var vm = this;
                $scope.vm = this;

                vm.status = enums.screenStatus.loading;
                vm.closeOnSave = true;
                vm.labelColSpan = 5;
                vm.rightBufferColSpan = 1;
                vm.dataColSpan = (12 - vm.rightBufferColSpan - vm.labelColSpan);

                vm.specificationsControl = {};
                vm.applicationsControl = {};
                vm.vehiclesControl = {};

                // set urls
                vm.apiUrls = apiUrls;
                vm.referenceDataUrls = referenceDataUrls;
                vm.equipmentUrls = equipmentUrls.url;
                vm.fleetVehiclesGrid = {};
                vm.config = ocConfigSvc;
                vm.fleetDetails = {};
                vm.fleetDetails.vehicleList = {};
                vm.form = {};

                this.$onInit = function () {

                    vm.fleetDetails = vm.initParams.fleet;
                    vm.isEdit = vm.initParams.mode !== 'new';

                    // security
                    vm.readonly = !(vm.isEdit ?
                        ocSecuritySvc.isAuthorised('Security.Settings.Fleets', 'readWrite') :
                        ocSecuritySvc.isAuthorised('Security.Settings.Fleets.Add', 'readWrite')
                    );

                    vm.canOpenVehicle = ocSecuritySvc.isAuthorised('Security.Vehicles', 'readOnly');

                    vm.addResource = "common.add_label";
                    vm.saveResource = "common.save_label";

                    if (vm.initParams.mode === 'new') {
                        vm.status = enums.screenStatus.ready;
                    } else {
                        vm.loadFleetDetails();
                    }

                    vm.wnd.onClose = vm.onClose;

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: vm.saveResource,
                            name: 'saveButton',
                            click: 'save',
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: true,
                            cancel: false,
                            value: vm.addResource,
                            name: 'addButton',
                            click: 'save',
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: vm.isEdit,
                            disabled: true
                        },
                        addButton: {
                            visible: !vm.isEdit,
                            disabled: true
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.onSave
                    };
                };

                $scope.$watch('vm.form.$dirty', function () {
                    vm.wnd.isDirty = vm.form.$dirty;
                });

                $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing', 'vm.readonly'], function () {

                    vm.buttonStates.saveButton.visible = vm.isEdit && !vm.readonly;
                    vm.buttonStates.addButton.visible = !vm.isEdit && !vm.readonly;

                    vm.buttonStates.saveButton.disabled = !vm.isEdit || vm.form.$invalid || vm.form.$pristine || vm.wnd.processing;
                    vm.buttonStates.addButton.disabled = vm.isEdit || vm.form.$invalid || vm.form.$pristine || vm.wnd.processing;
                });

                $scope.$watch('vm.fleetDetails.fleetType', function () {
                    if (vm.specificationsControl.clear) vm.specificationsControl.clear();
                    if (vm.applicationsControl.clear) vm.applicationsControl.clear();
                    if (vm.vehiclesControl.clear) vm.vehiclesControl.clear();
                });

                vm.onClose = function () {
                    closeWindow();
                };

                function closeWindow() {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        vm.setPristine();

                        WindowFactory.closeWindow(vm.wnd);
                    });
                }

                $scope.constants = {
                    fleetType: {
                        vehicle: 'Vehicle',
                        application: 'Application',
                        vehicleType: 'Specification'
                    }
                };

                function getKeys(list) {
                    if (list == null) {
                        return null;
                    }

                    var keys = [];

                    for (var i = 0; i < list.length; i++) {
                        keys.push(list[i].key);
                    }

                    return keys;
                }

                vm.processSaveResponse = function (response) {
                    if (vm.wnd.onDataChanged) {
                        vm.wnd.onDataChanged();
                    }

                    // switch from new to edit after first save
                    if (vm.wnd.initParams.mode === 'new') {
                        vm.wnd.initParams.mode = 'edit';
                        vm.isEdit = true;
                        vm.buttonStates.addButton.visible = false;
                        vm.buttonStates.saveButton.visible = true;
                        vm.wnd.caption = xlatSvc.xlat('fleet.editFleet') + ' - ' + vm.fleetDetails.fleetName;
                    } else {
                        vm.wnd.caption = xlatSvc.xlat('fleet.editFleet') + ' - ' + vm.fleetDetails.fleetName;
                        if (vm.closeOnSave === true) {
                            WindowFactory.closeWindow(vm.wnd);
                            return;
                        }
                    }

                    // extract the keys because the multi-select control wont set them again
                    response.vehicleIds = getKeys(response.vehicles);
                    response.applicationIds = getKeys(response.applications);
                    response.vehicleTypeIds = getKeys(response.vehicleTypes);

                    // rebind to the returned fleet
                    vm.updateModel(response);

                    // set the form to clean, so that the user isnt prompted to save again
                    $timeout(function () {
                        vm.setPristine();
                    });
                };

                $scope.saveFleetDetailsResponse = function (response) {

                    // tell the user the save was successful
                    notifySvc.success(xlatSvc.xlat("fleet.saveSuccessful"));
                    vm.processSaveResponse(response);
                };

                $scope.saveFleetDetailsNoResponse = function (errDesc) {
                    errorReporter.logError(errDesc, 'FleetDetails-Save');
                };

                vm.onSave = function () {

                    vm.wnd.processing = true;

                    var saveCriteria = {
                        id: vm.fleetDetails.id,
                        active: vm.fleetDetails.active,
                        fleetName: vm.fleetDetails.fleetName,
                        fleetTypeId: vm.fleetDetails.fleetType.key,
                        componentChangeDowntime: vm.fleetDetails.componentChangeDowntime,
                        componentChanges: vm.fleetDetails.componentChanges,
                        pressureMaintenanceFrequency: vm.fleetDetails.pressureMaintenanceFrequency,
                        treadUtilisation: vm.fleetDetails.treadUtilisation,
                        tyreLifeDistance: vm.fleetDetails.tyreLifeDistance,
                        tyreLifeHours: vm.fleetDetails.tyreLifeHours,
                        vehicleIds: vm.fleetDetails.vehicleIds,
                        vehicleTypeIds: vm.fleetDetails.vehicleTypeIds,
                        applicationIds: vm.fleetDetails.applicationIds
                    };

                    amtCommandQuerySvc.post(vm.apiUrls.saveFleetDetails, saveCriteria).then(
                        $scope.saveFleetDetailsResponse,
                        $scope.saveFleetDetailsNoResponse
                    ).finally(function () {
                        vm.wnd.processing = false;
                    });
                };

                $scope.openVehicle = function (item) {
                    WindowFactory.openItem({
                        caption: xlatSvc.xlat('equipment.openVehicle', item.serialNumber),
                        component: 'vehicle-details',
                        windowRelatedRecordId: item.equipmentId,
                        initParams: {
                            equipmentId: item.equipmentId,
                            siteId: item.siteId,
                            serialNumber: item.serialNumber
                        },
                        width: 800,
                        height: 850,
                        modal: false
                    });
                };

                vm.fleetTypeChanged = function () {
                    vm.fleetDetails.vehicleIds = null;
                    vm.fleetDetails.vehicleTypeIds = null;
                    vm.fleetDetails.applicationIds = null;
                };

                vm.updateModel = function (fleet) {
                    vm.fleetDetails = fleet;

                    if (vm.fleetVehiclesGrid && vm.fleetVehiclesGrid.recompile) {
                        // force the vehicle grid to rebind
                        vm.fleetVehiclesGrid.recompile();
                    }
                    vm.setPristine();
                };

                vm.setPristine = function () {
                    if (vm.form) {
                        vm.form.$setPristine();
                    }
                };

                vm.loadFleetDetails = function () {

                    vm.wnd.processing = true;

                    var criteria = {
                        id: vm.fleetDetails.id,
                        excludeOutOfServiceVehicles: true
                    };

                    amtCommandQuerySvc.post(vm.apiUrls.getDetails, criteria).then(function (response) {
                        if (response) {

                            vm.updateModel(response);
                            // set the for visible
                            vm.status = enums.screenStatus.ready;
                        }
                    }, amtCommandQuerySvc.handleError).finally(function () {
                        vm.wnd.processing = false;
                    });
                };
            }
        ]
    });
