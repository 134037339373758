//import angular from 'angular';

angular.module('amtFramework').directive('biggerThen', ['$compile', function ($compile) {
                return {
                    restrict: 'A',
                    require: 'ngModel',
                    link: function(scope: any, elem, attr, ctrl) {

                        var valSpan = angular.element('<span class="error-message error-bigger-than" ng-hide="biggerThenValid">{{"common.validationBiggerThen_message" | xlat:biggerThan}}</span>');
                        scope.biggerThan = scope.$eval(attr.biggerThen);

                        scope.$watch(function() {
                            return scope.$eval(attr.biggerThen);

                        }, function(newValue, oldValue) {
                            if (newValue === oldValue) {
                                return;
                            }
                            scope.biggerThan = newValue;
                        });

                        valSpan.insertAfter(elem);

                        $compile(valSpan)(scope);

                        scope.$watch(attr.ngMin, function() {
                            ctrl.$setViewValue(ctrl.$viewValue);
                        });

                        var minValidator = function(value) {
                            var min = scope.$eval(attr.biggerThen) || 0;
                            if ((value || value === 0) && value <= min) {
                                ctrl.$setValidity('bigger-then', false);
                                scope.biggerThenValid = false;
                                return undefined;
                            } else {
                                ctrl.$setValidity('bigger-then', true);
                                scope.biggerThenValid = true;
                                return value;
                            }
                        };

                        ctrl.$parsers.push(minValidator);
                        ctrl.$formatters.push(minValidator);
                    }
                };
    }
]);

