// $db
//
// We use Dexie as an interface to indexedDB, as the native api is extremely cumbersome.
//
// On certain platforms (such as iOS) an indexedDB shim is used, and the actual data is
// stored in the WebSQL db, due to either incomplete apis or poor performance on those 
// platforms. This should not affect any of the code, but it can have implications for
// performance as WebSQL and indexedDB have different performance profiles as the volume
// of data changes.
//
// see this page for more information on the Dexie data layer: https://github.com/dfahlander/Dexie.js/wiki/Design
//import angular from 'angular';
import Dexie from 'dexie';
import { TStatusChange } from '../components/statusChange/statusChangeCtrl';
import BrowserSvc from '../../services/browserSvc';

export interface IDbVehicle {
    id: guid;
    activeVisualInspections: any; //TODO: 
    description: string;
    distanceUnit: any; //TODO
    equipmentTypeSpecificationTypeId: guid;
    hasDistanceMeter: boolean;
    hasHoursMeter: boolean;
    lastPressureReading: Date;
    lastTreadReading: Date;
    nextPressureReading: Date;
    nextTreadReading: Date;
    maintenanceStatus: string; //TODO
    maintenanceStatusDescription: string;
    manufacturerSerialNumber: string;
    obsolete: boolean;
    pressureDue: boolean;
    pressureSensorId: guid;
    previousStatus: string; //TODO
    serialNumber: string;
    siteSerialNumber: string;
    sizeId: guid;
    specificationId: guid;
    status: any; //TODO
    totalDistance: number | null;
    totalHours: number | null;
    treadDue: boolean;
}

export interface IDbFitment {
    id: guid;
    assemblyId?: guid;
    componentId: guid;
    fitmentDate?: any; //TODO:
    positionId: guid;
    type: string; //TODO
    typeId: guid;
    vehicleId: guid;
    deleted?: any;
    maintenanceSession?: guid;
}

export interface IDbProfile {
    id: number;
    availableSites?: any[]; //TODO
    clientActive?: boolean;
    clientId?: guid;
    clientName?: string;
    cultureInfo?: string;
    currencyDecimalPlaces?: number | null;
    currencySymbol?: any; //TODO
    currencyType?: any; //TODO
    currencyTypeId?: guid;
    dundas?: any; //TODO
    emailAddress?: string;
    firstName?: string;
    fullName?: string;
    hash?: string;
    isSystemAdmin?: boolean;
    languageId?: guid;
    lastName?: string;
    logo?: string;
    notificationTimeout?: number;
    personId?: guid;
    personLoginId?: guid;
    resourceCulture?: string;
    roles?: any[]; //TODO
    settings?: any;//TODO
    showNotifications?: boolean;
    siteActive?: boolean;
    siteId?: guid;
    siteName?: string;
    systemId?: guid;
    units?: any; //TODO
    userName?: string;
}

export interface IDbSystem {
    id: number;
    identifier: guid;
    lastReferenceDataSyncDate?: number;
    site?: guid;
    stocktakeType?: any; //TODO:
    readingEventTypeCommentId?: guid;
}

export interface IDbClientError {
    id: number;
}

export interface IDbReading {
    id: guid;
    equipmentId: guid;
    type: string; //TODO: this should be an enum
    date: Date;
    unit: string;
    values: number[];
}

export interface IDbFieldSurvey {
    id: guid;

    activeError: any; //TODO:
    activeVisualInspections: any; //TODO:
    averageLast: number;
    createdDate: Date;
    description: string;
    displayDescription: string;
    distance: any; //TODO: saw an empty string here - not sure what this is?
    distanceUnit: any; //TODO
    equipmentTypeSpecificationTypeId: guid;
    hasDistanceMeter: boolean;
    hasHoursMeter: boolean;
    hours: any; //TODO: saw an empty string here - not sure what this is?
    inProgress: boolean;
    lastDate: Date;
    lastDistance: number;
    lastHours: number;
    lastPressureReading: Date;
    lastTreadReading: Date;
    maintenanceStatus: string; //TODO: enum?
    maintenanceStatusDescription: string; //TODO: enum?
    manufacturerSerialNumber: string;
    obsolete: boolean;
    positionReadings: any[]; //TODO:
    positions: any; //TODO:
    pressureDate: Date;
    pressureDue: boolean;
    pressureSensorId: guid;
    previousStatus: any; //TODO: not sure?
    priorReadings: any; //TODO:
    pristine: number;
    readingEventTypeCommentId: guid;
    schematic: any; //TODO:
    serialNumber: string;
    siteId: guid;
    siteSerialNumber: string;
    sizeId: guid;
    skipAllow: any; //TODO: what is this is it meant to be serialised?
    specificationId: guid;
    status: any; //TODO:
    totalDistance: number | null;
    totalHours: number | null;
    treadDate: Date;
    treadDue: boolean;
    units: any; //TODO:
}

export interface IDbMaintenanceSession {
    id: guid;
    activeError: any; //TODO:
    changeCount: number;
    checkedIn: boolean;
    checkin: any; //TODO:
    checkout: any; //TODO:
    checkedOut?: string; //TODO: enum string?
    createdDate: Date;
    displayDescription: string;
    events: any; //TODO:
    pristine: any;// TODO:
    readingEventTypeCommentId: guid;
    schematicDate: Date;
    sessionId: guid;
    siteId: guid;
    skipAllow: any; //TODO: 
    vehicle: any; //TODO:
    positionReadings: any;
}

export interface IDbPitsAndAudit {
    id: guid;
}

export interface IDbReceive {
    id: number;

    components: any[]; //TODO: 
    createdDate: Date;
    displayDescription: string;
    isDesktop: boolean;
    isKnown: boolean;
    pendingReceivalId: guid;
    pristine: number;
    vehicles?: any[];
}

export interface IDbSafetyAudit {
    id: guid;
}

export interface IDbStocktake {
    id: string;
    components: any[];
    createdDate: Date;
    expected: number;
    items: any; //TODO:
    level: number;
    pristine: number;
}

export interface IDbFile {
    id: guid;
    data: Blob | ArrayBuffer;
}

export interface IDbFileMetadata {
    id: guid;
    name: string;
    description: string;
    uploaded: boolean;
    fileSize: number;
    createdDate: Date;
    lastModifiedDate?: Date;
    type?: string;
}

export interface IDbThumb {
    id: guid;
    data: Blob | ArrayBuffer;
}

export interface IDbEquipmentType {
    key: guid;
    id: guid;
    name: string; //TODO
}

export interface IDbLocation {
    id: guid;
    siteId: guid;
    activeFrom: Date;
    activeTo: Date;
    siteStatusTypeLocationStatusTypes: any; //TODO:
}

export interface IDbCostType extends IDbRefData {
}

export interface IDbSpecification {
    id: guid;
    application: any; //TODO
    axles: null | any[]; //TODO:
    defaultCost: any; //TODO:
    description: string;
    equipmentTypeId: guid;
    hasHoursMeter: boolean;
    maxSpeed: number | null
    serialNumberValidations: any[] | null; //TODO: what type is this?
}

export interface IDbPendingComponent {
    id: guid;
    serialNumber: any; //TODO: what type is this?
    fittedToPosition: any; //TODO:
    equipmentType: any;
    fittedToVehicleSerial: string;
}

export interface IDbStatus extends IDbRefData {
    conditionType: string; //TODO: enum?
    conditionTypeKey: string; //todo: wtf
    default: boolean;
    includeInStocktake: boolean;
    receiveDescription: string;
}

export interface IDbVisualInspectionCause extends IDbRefData {
    equipmentTypeId: guid;
}

export interface IDbVisualInspectionAction extends IDbRefData {
    daysToResolve: number;
}

export interface IDbTyre {
    id: guid;
    sizeId: guid;
    serialNumber: string;
    activeVisualInspections: any; //TODO
    description: string;
    distanceUnit: string;
    equipmentTypeSpecificationTypeId: guid;
    manufacturerSerialNumber: string;
    obsolete: boolean;
    originalDepth: any; //TODO:
    pressureSensorId: guid;
    previousStatus: any; //TODO:
    remainingDepth: any; //TODO:

    siteSerialNumber: string
    status: any; //TODO
    totalDistance: number | null;
    totalHours: number | null;
}

export interface IDbRim {
    id: guid;
    sizeId: guid;
    serialNumber: string;
    activeVisualInspections: any; //TODO
    description: string;
    distanceUnit: string;
    equipmentTypeSpecificationTypeId: guid;
    manufacturerSerialNumber: string;
    obsolete: boolean;
    originalDepth: any; //TODO:
    pressureSensorId: guid;
    previousStatus: any; //TODO:
    remainingDepth: any; //TODO:

    siteSerialNumber: string
    status: any; //TODO
    totalDistance: number | null;
    totalHours: number | null;
}

export interface IDbChain {
    id: guid;
    sizeId: guid;
    serialNumber: string;
    activeVisualInspections: any; //TODO
    description: string;
    distanceUnit: string;
    equipmentTypeSpecificationTypeId: guid;
    manufacturerSerialNumber: string;
    obsolete: boolean;
    originalDepth: any; //TODO:
    pressureSensorId: guid;
    previousStatus: any; //TODO:
    remainingDepth: any; //TODO:

    siteSerialNumber: string
    status: any; //TODO
    totalDistance: number | null;
    totalHours: number | null;
}

export interface IDbUnitOfMeasure {
    id: guid;
    alertConfigurationValues: any[];
    axleTypeOverrides: any[];
    axleTypeOverrides1: any[];
    axleTypeOverrides2: any[];
    baseReadingUnit: string; //TODO: this looks like an enum
    chainTypes: any[];
    equipmentLifeStatistics: any[];
    equipments: any[];
    equipments1: any[];
    factor: number;
    fieldSurveyDeviceReadings: any[];
    lastModifiedDate: Date;
    lastModifiedUserId: string | null;
    performanceWarranties: any[];
    performanceWarrantyClaimEquipments: any[];
    pressureTemperatureAnalysisBucketRanges: any[];
    pressureTemperatureAnalysisValues: any[];
    pressureTemperatureAnalysisValues1: any[];
    pressureTemperatureAnalysisValues2: any[];
    readingEventReadingTypes: any[];
    referenceData: any;
    referenceDataId: guid;
    siteMonthReportHSEs: any[];
    siteMonthReportHaulages: any[];
    siteMonthReportReadings: any[];
    siteMonthReports: any[];
    treadBeltTypes: any[];
    tyreTypeWeightOverrides: any[];
    tyreTypes: any[];
    unitAbbreviation: string;
    unitOfMeasureDefaults: any[];
    vehicleTypeOverrides: any[];
}

export interface IDbPendingVehicle {
    id: guid;
    serialNumber: any;
}

export interface IDbProductionCrew {
    id: guid;
    key: guid;
    activeFrom: Date;
    activeTo: Date;
    description: string;
    name: string;
}

export interface IDbFitter {
    key: guid;
    name: string;
}

export interface IDbDamageCause extends IDbRefData {
    componentTypeId: guid;
    displayDescription: string;
    hasDamageLocations: boolean;
    hasDamageSources: boolean;
    instant: boolean;
    tyreBurst: boolean;
    tyreExplosion: boolean;
}

export interface IDbDamageSource extends IDbRefData {
    componentTypeId: guid;
    damageCauseId: guid;
    displayDescription: string;
}

export interface IDbDamageLocation extends IDbRefData {
    componentTypeId: guid;
    damageCauseId: guid;
    displayDescription: string;
}

export interface IDbDownTimeType extends IDbRefData {
}

export interface IDbRemovalReason extends IDbRefData {
    componentTypeId: guid;
    displayDescription: string;
}

export interface IDbWorkshopTime extends IDbRefData {
    category: string;
}

export interface IDbAuditRecord {
    applicationVersion: string,
    browser: string,
    databaseVersion: number,
    date: Date,
    detail: string,
    event: string,
    id?: number;
    module: string;
    personId: guid;
    siteId: guid;
}

export interface IDbRefDataWithoutSequence {
    key: guid;
    id: guid;
    description: string;
    isActive: boolean;
    name: string;
    referenceDataId: guid;
}

export interface IDbRefData extends IDbRefDataWithoutSequence {
    sequence: any; //TODO:
}

export interface IDbMaintenanceType extends IDbRefData {
}

export interface IDbStatusChange {
    id: guid;
    events: TStatusChange[];
    createdDate: Date;
    pristine: number;
    componentType: string;
    componentTypeId: guid;
    serialNumber: string;
}

export interface IDbStatusFlow {
    flowId: guid;
    id: guid;
    key: guid;

    allowsDepthReading: boolean;
    allowsLocation: boolean;
    allowsMWClaim: boolean;
    allowsRepairType: boolean;
    allowsRepairer: boolean;
    allowsRetreader: boolean;
    allowsReturnToSupplierReason: boolean;
    allowsSupplier: boolean;
    description: string;
    equipmentTypeIds: guid[];
    isActive: boolean;
    isDispatch: boolean;
    isFittedTransfer: boolean;
    name: string;
    processType: string; //TODO:
    receivesCredit: boolean;
    referenceDataId: guid;
    requiresCredit: boolean;
    requiresDamageReason: boolean;
    requiresDestination: boolean;
    requiresRemoveReason: boolean;
    roles: any[]; //TODO
    sequence: any; //TODO:
    startNewLife: boolean;
    statusTypeFromDescription: string;
    statusTypeFromName: string;
    statusTypeIdFrom: guid;
    statusTypeIdTo: guid;
    vehicleOnly: boolean;
}

export interface IDbCurrency extends IDbRefData {
    applyToAllLanguages: boolean;
    currency: string;
    decimalPlaces: number;
    displayDescription: string;
    languageTypeId: guid;
    level: string; //TODO: enum?
    mode: any; //TODO:
    personId: guid;
    symbol: string;
    usedBy: any;
}

export interface IDbPendingReceival {
    id: guid;
    componentOwner: any;
    cost: any;
    date: Date;
    dateFrom: Date;
    dateTo: Date;
    depthUnit: any;
    distance: number;
    distanceUnit: string;
    equipmentLifeId: guid;
    equipmentStatusId: guid;
    equipmentType: any; //TODO:

    equipmentTypeName?: string; //TODO: not always present if it is it's just "Vehicle" (seems to be used as a flag / if unset it's something else?)

    fittedToPosition: any;
    fittedToPositionDescription: string;
    fittedToVehicleSerial: string;
    hours: number;
    hoursSinceLastNdt: number;

    positions?: any; //TODO: DataBroker looks for this, it's not a field I see in my localdb in testing

    rcd1: any;//TODO:
    rcd2: any;//TODO:
    repairCost: any;//TODO:
    repairerId: guid;
    retreaderId: guid;
    rtd1: any;//TODO:
    rtd2: any;//TODO:
    serialNumber: any;//TODO:
    site: string;
    siteId: guid;
    specification: any;//TODO:
    status: string;
    transferredFromNew: boolean;
}

export interface IDbComponentOwner extends IDbRefDataWithoutSequence {
}

export interface IDbReadingEventTypeComment extends IDbRefData {
    readingEventTypeId: guid;
    readingEventTypeName: string;
}

export interface IDbPurchaseOrder {
    id: string;
    poNumber: string;
    specifications: guid[];
    tyre: number;
    rim: number;
    chain: number;
}

export interface IDbSupplier extends IDbRefData {
    equipmentTypeIds: guid[];
    requiresManualEntry: boolean;
}

export interface IDbReturnToSupplierReason extends IDbRefData {
    requiresDamageReason: boolean;
    requiresManualEntry: boolean;
}

export interface IDbRepairTypes extends IDbRefData {
}

export interface IDbPressureSensor {
    key: guid;
    description: string;
}

export interface IDbRetreader {
    id: guid;
    key: guid;
    description: string;
    equipmentTypeIds: guid[];
    isActive: boolean;
    name: string;
    requiresManualEntry: boolean;
}

export interface IDbRepairer {
    id: guid;
    key: guid;
    description: string;
    equipmentTypeIds: guid[];
    isActive: boolean;
    name: string;
    requiresManualEntry: boolean;
}

export default class DbClass extends Dexie {
    auditRecords: Dexie.Table<IDbAuditRecord, number>;
    chain: Dexie.Table<IDbChain, guid>;
    clientErrors: Dexie.Table<IDbClientError, number>;
    componentOwner: Dexie.Table<IDbComponentOwner, guid>;
    costTypes: Dexie.Table<IDbCostType, guid>;
    currency: Dexie.Table<IDbCurrency, guid>;
    damageCauses: Dexie.Table<IDbDamageCause, guid>;
    damageLocations: Dexie.Table<IDbDamageLocation, guid>;
    damageSources: Dexie.Table<IDbDamageSource, guid>;
    downTimeTypes: Dexie.Table<IDbDownTimeType, guid>;
    equipmentTypes: Dexie.Table<IDbEquipmentType, guid>;
    fieldSurvey: Dexie.Table<IDbFieldSurvey, guid>;
    file: Dexie.Table<IDbFile, guid>;
    fileMetadata: Dexie.Table<IDbFileMetadata, guid>;
    fitment: Dexie.Table<IDbFitment, guid>;
    fitters: Dexie.Table<IDbFitter, guid>;
    locations: Dexie.Table<IDbLocation, guid>;
    maintenanceSession: Dexie.Table<IDbMaintenanceSession, guid>;
    maintenanceType: Dexie.Table<IDbMaintenanceType, guid>;
    pendingComponents: Dexie.Table<IDbPendingComponent, guid>;
    pendingReceivals: Dexie.Table<IDbPendingReceival, guid>;
    pendingVehicles: Dexie.Table<IDbPendingVehicle, guid>;
    pitsAndAudit: Dexie.Table<IDbPitsAndAudit, guid>;
    pressureSensor: Dexie.Table<IDbPressureSensor, guid>;
    productionCrew: Dexie.Table<IDbProductionCrew, guid>;
    profile: Dexie.Table<IDbProfile, number>;
    purchaseOrders: Dexie.Table<IDbPurchaseOrder, string>;
    reading: Dexie.Table<IDbReading, guid>;
    readingEventTypeComment: Dexie.Table<IDbReadingEventTypeComment, guid>;
    receive: Dexie.Table<IDbReceive, number>;
    removalReasons: Dexie.Table<IDbRemovalReason, guid>;
    repairers: Dexie.Table<IDbRepairer, guid>;
    repairTypes: Dexie.Table<IDbRepairTypes, guid>;
    retreaders: Dexie.Table<IDbRetreader, guid>;
    returnToSupplierReasons: Dexie.Table<IDbReturnToSupplierReason, guid>;
    rim: Dexie.Table<IDbRim, guid>;
    safetyAudit: Dexie.Table<IDbSafetyAudit, guid>;
    specification: Dexie.Table<IDbSpecification, guid>;
    statusChange: Dexie.Table<IDbStatusChange, guid>;
    statuses: Dexie.Table<IDbStatus, guid>;
    statusFlows: Dexie.Table<IDbStatusFlow, guid>;
    stocktake: Dexie.Table<IDbStocktake, string>;
    suppliers: Dexie.Table<IDbSupplier, guid>;
    system: Dexie.Table<IDbSystem, number>;
    thumb: Dexie.Table<IDbThumb, guid>;
    tyre: Dexie.Table<IDbTyre, guid>;
    unitsOfMeasure: Dexie.Table<IDbUnitOfMeasure, guid>;
    vehicle: Dexie.Table<IDbVehicle, guid>;
    visualInspectionActions: Dexie.Table<IDbVisualInspectionAction, guid>;
    visualInspectionCauses: Dexie.Table<IDbVisualInspectionCause, guid>;
    workshopTimes: Dexie.Table<IDbWorkshopTime, guid>;

    static $inject = ['WindowFactory', '$timeout', 'errorReporter', 'browserSvc'];

    constructor(private WindowFactory: IWindowFactory, private $timeout: ng.ITimeoutService, private errorReporter: IErrorReporter, private browserSvc: BrowserSvc) {
        super('OtracoFS');

        this.versionInit();

        this.on("populate", () => { this.system.add({ id: 1, identifier: uuid() }) });

        // force the upgrade to occur early.
        this.forceUpgradeWithOpen();
    }

    private async forceUpgradeWithOpen() {
        try {
            await this.open();
        } catch (err) {
            console.error("Failed to open local DB");
            console.log(err);

            await this.promptToDeleteOtracomDatabase();
        }
    }

    public async promptToDeleteOtracomDatabase() {
        try {
            let result = await this.WindowFactory.alert('mobileCommon.dbFailHeader', ['framework.cancel_label', 'mobileCommon.deleteDB'], 'mobileCommon.dbFail');

            if (result !== 'mobileCommon.deleteDB')
                return;

            await this.delete();
            console.error("local database deleted");
        } catch (err) {
            console.error("Could not delete local database");
            console.error(err);
        } finally {
            location.reload();
        }
    }

    private versionInit() {
        // note that only the INDEXES needed to be listed in the table definition - the stored
        // object can have any other fields, *including* nested objects, but only the specified
        // indexes can be queried and filtered against
        this.version(1).stores({
            vehicle: '&id, serialNumber',
            fitment: '&id, vehicleId, positionId, typeId',
            profile: '&id',
            system: '&id',
            clientErrors: '++id',
            reading: '&id, equipmentId, type, date',
            fieldSurvey: '&id, createdDate',
            maintenanceSession: '&id, createdDate',
            pitsAndAudit: '&id, createdDate',
            receive: '++id, createdDate',
            safetyAudit: '&id, createdDate',
            stocktake: '&id, createdDate',
            file: '&id',
            // for performance you don't want to pull the actual files just for listing and stuff
            fileMetadata: '&id',
            equipmentTypes: '&key',
            locations: '&id, siteId',
            costTypes: '&key',
            specification: '&id, equipmentTypeId, description',
            pendingComponents: '&id, equipmentType.key, [equipmentType.key+serialNumber.manufacturer]',
            statuses: '&conditionTypeKey, conditionType',
            visualInspectionCauses: '&key',
            visualInspectionActions: '&key',
            tyre: '&id, status.name, sizeId',
            rim: '&id, status.name, sizeId',
            chain: '&id, status.name, sizeId',
            unitsOfMeasure: '&id, baseReadingUnit',
            pendingVehicles: '&id, serialNumber.manufacturer',
            productionCrew: '&key, description',
            fitters: '&key,name',
            damageCauses: '&key,description',
            damageSources: '&key,description',
            damageLocations: '&key,description',
            downTimeTypes: '&key,description',
            removalReasons: '&key,description',
            workshopTimes: '&key,description',
            maintenanceType: '&key,description',
            statusFlows: '&flowId, description, statusTypeIdFrom, statusTypeIdTo'
        });

        this.version(2).stores({
            fitment: '&id, vehicleId, positionId, typeId, type'
        });

        this.version(3).stores({
            pressureSensor: '&key,name'
        });

        this.version(4).stores({
            tyre: '&id, status.name, sizeId',
            rim: '&id, status.name, sizeId',
            chain: '&id, status.name, sizeId'
        });

        this.version(5).stores({
            fieldSurvey: '&id, createdDate, pristine',
            maintenanceSession: '&id, createdDate, pristine',
            pitsAndAudit: '&id, createdDate, pristine',
            receive: '++id, createdDate, pristine',
            safetyAudit: '&id, createdDate, pristine',
            stocktake: '&id, createdDate, pristine'
        });

        this.version(6).stores({
            statusFlows: '&flowId, description, statusTypeIdFrom, statusTypeIdTo, statusTypeFromDescription'
        });

        this.version(7).stores({
            componentOwner: '&id',
            pendingReceivals: '&id, status, equipmentTypeName',
            retreaders: '&id'
        });

        this.version(8).stores({
            repairers: '&id'
        });

        this.version(9).stores({
            tyre: '&id, status.name, sizeId, serialNumber',
            rim: '&id, status.name, sizeId, serialNumber',
            chain: '&id, status.name, sizeId, serialNumber'
        });

        this.version(10).stores({
            readingEventTypeComment: '&id',
        });

        this.version(11).stores({
            purchaseOrders: '&id, poNumber, tyre, rim, chain',
            fitment: '&id, vehicleId, positionId, type, componentId'
        });

        this.version(12).stores({
            repairTypes: '&id',
            returnToSupplierReasons: '&id',
            suppliers: '&id',
            currency: '&id',
            statusChange: '&id, createdDate, pristine, statusTypeIdFrom',
            auditRecords: '++id'
        });

        this.version(13).stores({
            thumb: '&id',
            file: '&id',
            fileMetadata: '&id, uploaded'
        }).upgrade(function (trans) {
            //TODO: this is a bad place to store this AND a bad way to do this, but we can't handle this locally as the code doesn't wait for the db to open before it
            //tries to use it, so we can't spend much time manipulating data here, so we defer it until the user starts(?) a session
            localStorage.setItem("dbNeedsV2600Upgrade", "1");
            return Promise.resolve();
        });

        this.version(14).upgrade(function (trans) {
            localStorage.setItem("dbNeedsV2700Upgrade", "1");
            return Promise.resolve();
        });

        this.version(15).stores({
            pressureSensor: '&key,description'
        });
    }

    //public async upgradeOtracomDatabaseToV2600() {

    //    let needUpd = localStorage.getItem('dbNeedsV2600Upgrade'); //see db.version(13) code - this is a hack to defer db upgrade until login on mobile

    //    if (!needUpd)
    //        return;

    //    let canUseBlob = !this.browserSvc.isiOS12orEarlier(); //if older iOS, we can't store Blobs in the indexeddb

    //    let dataURItoStorage = (bstr: string): ArrayBuffer | Blob => {

    //        let splitStrings: string[] = bstr.split(',');
    //        let byteString = splitStrings[0].endsWith('base64') ? atob(splitStrings[1]) : unescape(splitStrings[1]);

    //        let buff = new ArrayBuffer(byteString.length)
    //        var buff_u8 = new Uint8Array(buff);

    //        for (var i = 0; i < byteString.length; ++i) {
    //            buff_u8[i] = byteString.charCodeAt(i);
    //        }

    //        return canUseBlob ? new Blob([buff]) : buff;
    //    }

    //    try {

    //        let fileDataMap: Map<guid, [Date, string]> = new Map();
    //        let now = new Date();

    //        //for all file table cleanup to just have the file data as binary, and save out the lastModifiedDate for the FileMetadata table
    //        await this.file.toCollection().modify((val: any, ref) => {

    //            fileDataMap.set(val.id, [val.lastModifiedDate || val.date, val.name]);

    //            //replace whatever is stored, this way any unused junk is removed/cleaned up
    //            ref.value = {
    //                id: val.id,
    //                data: dataURItoStorage(val.data || val.thumbUrl || val.thumb)
    //            };
    //        });

    //        await this.fileMetadata.toCollection().modify((val: any, ref) => {
    //            let fd = fileDataMap.get(val.id);

    //            //replace whatever is stored, this way any unused junk is removed/cleaned up
    //            ref.value = {
    //                id: val.id,
    //                name: fd[1] || val.name,
    //                uploaded: !!(val.uploaded),
    //                fileSize: val.fileSize || 0,
    //                createdDate: fd[0] || val.date || now,
    //                description: val.description
    //            };
    //        });

    //        // pull out any visual inspection images that were downloaded as part of the tyres reference data
    //        let tyres = await this.tyre.toArray();

    //        for (let tyre of tyres || []) {
    //            for (let vi of tyre.activeVisualInspections || []) {
    //                for (let image of vi.images || []) {

    //                    await this.file.put({ id: image.imageId, data: dataURItoStorage(image.image) });

    //                    await this.fileMetadata.put({
    //                        id: image.imageId,
    //                        name: image.fileName,
    //                        uploaded: true,
    //                        fileSize: 0,
    //                        createdDate: vi.dateTaken,
    //                        description: image.fileName
    //                    });
    //                }
    //            }
    //        }

    //        // pull out any visual inspection images that were downloaded as part of an in-progress maintenance session
    //        let sessions = await this.maintenanceSession.toArray();

    //        for (let session of sessions || []) {
    //            for (let pr of session.vehicle.positionReadings || []) {
    //                for (let vi of pr.visualInspections || []) {

    //                    // files has been renamed as attachments, so need to shift to a new collection
    //                    vi.attachments = [];

    //                    for (let file of vi.files || []) {

    //                        await this.file.put({ id: file.file, data: dataURItoStorage(file.thumbUrl) });

    //                        let metadata = {
    //                            id: file.file,
    //                            name: file.filename,
    //                            uploaded: true,
    //                            fileSize: 0,
    //                            createdDate: vi.inspectionDateTime,
    //                            description: file.description || file.filename
    //                        };

    //                        await this.fileMetadata.put(metadata);

    //                        vi.attachments.push(metadata);
    //                    }
    //                }
    //            }
    //        }

    //        // copy any fileIds in the receive table to the id property
    //        await this.receive.toCollection().modify(record => {
    //            for (let component of record.components || []) {
    //                for (let attachment of component.eventDetails.attachments || []) {
    //                    attachment.id = attachment.fileId;
    //                }
    //            }
    //        });

    //        // copy any fileName to name, and clear data, for all attachments in the StatusChange table
    //        await this.statusChange.toCollection().modify(record => {
    //            for (let event of record.events || []) {
    //                for (let attachment of event.attachments || []) {
    //                    attachment.name = attachment['fileName'];
    //                    attachment.data = null;
    //                }
    //            }
    //        });

    //        localStorage.removeItem('dbNeedsV2600Upgrade');

    //    } catch (error) {
    //        this.errorReporter.logError(error);
    //    }
    //}

    public async upgradeOtracomDatabaseToV2700() {

        // attempt to find any existing visual inspections on field surveys and maintenance sessions that are new or have been modified
        // and then set the Modified property to true so that they will be uploaded
        // and in the case of an edit, set the InspectionDateTime to match the field survey/maintenance session date
        // this will allow it to treat the edit as an update, creating a new visualInspectionRecord for the visual inspection

        let needUpd = localStorage.getItem('dbNeedsV2700Upgrade'); //see db.version(14) code - this is a hack to defer db upgrade until login on mobile

        if (!needUpd)
            return;

        try {

            // maintenance sessions
            let sessions = await this.maintenanceSession.toArray();

            for (let session of sessions || []) {

                for (let pr of session.vehicle.positionReadings || []) {

                    let fittedTyreId = pr.tyreComponentId;

                    if (fittedTyreId) {

                        let tyre = await this.tyre.get(fittedTyreId);

                        for (let vi of pr.visualInspections || []) {

                            if (!vi.causeId)
                                vi.causeId = vi.cause.id;

                            vi.cause = vi.cause?.description;

                            if (!vi.actionId)
                                vi.actionId = vi.action.id;

                            vi.action = vi.action?.description;

                            let matchingInspection = tyre.activeVisualInspections.find(v => v.id === vi.id);

                            if (!matchingInspection) { // new visual inspection
                                vi.modified = true;
                                vi.uncommitted = true; // can be deleted
                            } else {

                                // check if there is a change in the attachments
                                let existingAttachments = matchingInspection.attachments?.map(a => a.id) || [];
                                let currentAttachments = vi.attachments?.map(a => a.id) || [];

                                let attachmentsChanged: boolean;

                                // check for attachments that have been added or removed
                                if (existingAttachments.length !== currentAttachments.length ||
                                    existingAttachments.some(a => currentAttachments.indexOf(a) === -1) ||
                                    currentAttachments.some(a => existingAttachments.indexOf(a) === -1)) {

                                    attachmentsChanged = true;
                                }

                                // check for attachments where the file or description has been changed
                                if (!attachmentsChanged) {
                                    for (let attachment of currentAttachments || []) {
                                        let existingAttachment = existingAttachments.find(a => a.id === attachment.id);

                                        if (!attachment.uploaded || (existingAttachment && existingAttachment.description !== attachment.description))
                                            attachmentsChanged = true;
                                    }
                                }

                                if (matchingInspection.causeId !== vi.causeId ||
                                    matchingInspection.actionId !== vi.actionId ||                                    
                                    matchingInspection.otherText !== vi.comment ||
                                    !vi.active ||
                                    attachmentsChanged) {

                                    vi.modified = true;
                                    vi.inspectionDateTime = session.checkin.date;
                                }
                            }
                        }
                    }
                }

                await this.maintenanceSession.put(session);
            }

            // field surveys
            let fieldSurveys = await this.fieldSurvey.toArray();

            for (let survey of fieldSurveys || []) {

                for (let pr of survey.positionReadings || []) {

                    let fittedTyreId = pr.tyreComponentId;                    

                    if (fittedTyreId) {

                        let tyre = await this.tyre.get(fittedTyreId);

                        for (let vi of pr.visualInspections || []) {

                            if (!vi.causeId)
                                vi.causeId = vi.cause.id;

                            vi.cause = vi.cause?.description;

                            if (!vi.actionId)
                                vi.actionId = vi.action.id;

                            vi.action = vi.action?.description;

                            let matchingInspection = tyre.activeVisualInspections.find(v => v.id === vi.id);

                            if (!matchingInspection) { // new visual inspection
                                vi.modified = true;
                                vi.uncommitted = true; // can be deleted
                            } else {

                                // check if there is a change in the attachments
                                let existingAttachments = matchingInspection.attachments?.map(a => a.id) || [];
                                let currentAttachments = vi.attachments?.map(a => a.id) || [];

                                let attachmentsChanged: boolean;

                                // check for attachments that have been added or removed
                                if (existingAttachments.length !== currentAttachments.length ||
                                    existingAttachments.some(a => currentAttachments.indexOf(a) === -1) ||
                                    currentAttachments.some(a => existingAttachments.indexOf(a) === -1)) {

                                    attachmentsChanged = true;
                                }

                                // check for attachments where the file or description has been changed
                                if (!attachmentsChanged) {
                                    for (let attachment of currentAttachments || []) {
                                        let existingAttachment = existingAttachments.find(a => a.id === attachment.id);

                                        if (!attachment.uploaded || (existingAttachment && existingAttachment.description !== attachment.description))
                                            attachmentsChanged = true;
                                    }
                                }

                                if (matchingInspection.causeId !== vi.causeId ||
                                    matchingInspection.actionId !== vi.actionId ||                                    
                                    matchingInspection.otherText !== vi.comment ||
                                    !vi.active ||
                                    attachmentsChanged) {

                                    vi.modified = true;
                                    vi.inspectionDateTime = survey.createdDate;
                                }
                            }
                        }
                    }
                }

                await this.fieldSurvey.put(survey);
            }

            localStorage.removeItem('dbNeedsV2700Upgrade');

        } catch (error) {
            this.errorReporter.logError(error);
        }
    }
}

angular.module('app').service('$db', DbClass);