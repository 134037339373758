//import angular from 'angular';
import AdminUrls from '../../../adminUrls';


    angular.module('app.admin').controller("compoundSearchCtrl", compoundSearchCtrl);

    // @ts-ignore
    compoundSearchCtrl.$inject = ["adminUrls", "$scope", "enums", "ocSecuritySvc", "amtXlatSvc", "$timeout", "WindowFactory", "ocConfigSvc", "componentUrls", "$element", "exportSvc", "helperSvc", "referenceDataUrls"];

    // ReSharper disable once InconsistentNaming
    function compoundSearchCtrl(adminUrls: AdminUrls, $scope, enums, ocSecuritySvc, amtXlatSvc, $timeout, WindowFactory, ocConfigSvc, componentUrls, $element, exportSvc, helperSvc, referenceDataUrls) {

        var vm = this;

        vm.processing = false;
        vm.showChain = false;
        vm.showTyre = false;
        vm.adminUrls = adminUrls;
        vm.componentUrls = componentUrls;
        vm.referenceDataUrls = referenceDataUrls;

        vm.config = ocConfigSvc;

        vm.criteria = {
            filterValues: {               
                activeOptions: null,
                compoundIds: null,
                manufacturerIds: null
            }
        };

        vm.compoundFilterControl = {};

        vm.activeOptions = [{
            name: amtXlatSvc.xlat('common.yes_label'),
            key: true
        }, {
            name: amtXlatSvc.xlat('common.no_label'),
            key: false
        }];

        vm.status = enums.screenStatus.loading;

        vm.gridControl = {};
        vm.selectedItem = null;

        function init() {
            vm.loadDefaults();
        }

        vm.onSelectedItemChanged = function (items) {
            if (items != null && items.length === 1) {
                vm.selectedItem = items[0];
            } else {
                vm.selectedItem = null;
            }
        };

        vm.setHeaderHeight = function (height, offset) {
            vm.gridTop = height + offset;
        };

        vm.loadDefaults = function () {
            vm.defaults = {
                activeOptions: [vm.activeOptions[0]]
            };

            vm.status = enums.screenStatus.ready;
        };

        vm.export = function () {
            exportSvc.exportData(vm.referenceDataUrls.exportTreadCompounds, vm.criteria, amtXlatSvc.xlat('compound.searchTitle'));
        };

        $scope.editCompound = function (item) {
            if (item) {
                WindowFactory.openItem({
                    component: 'add-edit-compound',
                    caption: amtXlatSvc.xlat("compound.editCompound"),
                    initParams: item,
                    width: 550,
                    onDataChangeHandler: function () {
                        vm.refreshGrid();
                        vm.compoundFilterControl.search();
                    },
                    windowRelatedRecordId: item.id
                });
            }
        };

        $scope.addCompound = function () {
            WindowFactory.openItem({
                component: 'add-edit-compound',
                caption: amtXlatSvc.xlat("compound.addCompound"),
                initParams: null,
                width: 550,
                onDataChangeHandler: function () {
                    vm.refreshGrid();
                    vm.compoundFilterControl.search();
                },
                windowRelatedRecordId: uuid()
            });
        };

        vm.refreshGrid = function () {
            vm.gridControl.refresh();
        };

        init();
    }
