/**
 * @ngdoc directive
 * @name amtFramework.filter.directive:amtRadioButtonFilteritem
 * @restrict E
 * @requires amtFramework.filter.directive:amtFilter
 * 
 * @param {string} label Label to display above the control
 * @param {string} name Name of the html input element
 * @param {string} propertyName Name of the property that is added to filterValues property of {@link amtFramework.filter.directive:amtFilter amtFilter}
 * @param {expression} options Array of options. [{key: 1, name: "option 1"}, {key: 2, name: "option 2"}, {key: 3, name: "option 3"}]
 * @param {expression=} [isRequired=false] Required
 * @param {expression=} defaultValue Default value
 * @param {callback} onChange Fired when the selected value is changed
 * 
 * @description
 * Filter item that let's the user filter on a value by toggling a radiobutton
 * 
 * @example
 *<doc:example module="amtFramework">
 *   <doc:source>
 * <amt-filter filter-values="filtervalues">
 *     <amt-radio-button-filteritem label="Test options" name="test" property-name="testOptions" options='[{key: 201501, name:"01 2015"}, {key: 201502, name:"02 2015"}, {key: 201601, name:"01 2016"}, {key: 201602, name:"02 2016"}]'></amt-multiselect-filteritem>
 * </amt-filter>
 * Filtervalues: {{filtervalues}}
 * </doc:source>
 * </doc:example>
 **/
//import angular from 'angular';
import tmpl from './amtRadioButtonFilteritem.html';

angular.module('amtFramework.filter').directive('amtRadioButtonFilteritem', function () {
            return {
                require: '^amtFilter',
                restrict: 'E',
                scope: {
                    label: '@',
                    name: "@",
                    propertyName: "@",
                    isRequired: '@',
                    options: "=",
                    defaultValue: '=?',
                    onChange: '&'
                },
                template: tmpl,
                link: function (scope: any, elem, attr, filterCtrl) {
                    var defaultValue = scope.defaultValue || "";
                    scope.filterItem = { propertyName: scope.propertyName, value: defaultValue };
                    filterCtrl.addFilterItem(scope.filterItem);
                }
            };
});
