//import angular from 'angular';


angular.module('app.directives').directive('ocVehicleSchematicPosition',
            function () {
                return {
                    replace: true,
                    scope: {
                        config: "="
                    },         
                    controller: ['$scope', function ($scope) {
                        if (Object.prototype.toString.call($scope.config.text) !== '[object Array]') {
                            if ($scope.config.text) {
                                $scope.config.text = [$scope.config.text];
                            }
                            else {
                                $scope.config.text = [];
                            }
                        }          
                    }],                    
                    template: function (scope: any) {
                        var t;

                        var components = [];

                        if (scope.config.side == 'left') {
                            // chain, tyre, rim
                            components.push('rim');
                            components.push('chain');
                            components.push('tyre');
                        } else {
                            // rim, tyre, chain
                            components.push('rim');
                            components.push('tyre');
                            components.push('chain');
                        }

                        t =     '<g transform="translate()"  ng-class="[config.cssClass, { errored : config.position.errored  , selected: config.position.selected || config.component.selected || config.position[config.selector + \'Selected\'] }]" ng-click="config.click(config)" ng-mouseenter="config.hoverStart()" ng-mouseleave="config.hoverEnd()">';

                        while (components.length > 0) {
                            var component = components.pop();

                            switch (component) {
                                case "rim":
                                    t = t + '  <rect ng-attr-width="{{config.width}}" ng-attr-height="{{config.height}}" ng-attr-rx="{{config.rx}}" ng-attr-ry="{{config.ry}}" ng-attr-style="stroke-opacity: {{config.opacity || 1}}" />';
                                    break;
                                case "tyre":
                                    t = t + '  <rect ng-attr-x="{{config.x}}" ng-attr-y="{{config.y}}" ng-attr-width="{{config.width}}" ng-attr-height="{{config.height}}" ng-attr-rx="{{config.rx}}" ng-attr-ry="{{config.ry}}" ng-attr-style="stroke-opacity: {{config.opacity || 1}}" />';
                                    break;
                                case "chain":
                                    t = t + '  <rect ng-attr-x="{{config.x}}" ng-attr-y="{{config.y}}" ng-attr-width="{{config.width}}" ng-attr-height="{{config.height}}" ng-attr-rx="{{config.rx}}" ng-attr-ry="{{config.ry}}" ng-attr-style="stroke-opacity: {{config.opacity || 1}}" />';
                                    break;
                            }
                        }
                        
                        t = t + '  <text ng-attr-x="{{config.x + config.width / 2}}" ng-attr-y="{{ config.y + 4 + config.height / 2 }}" >';
                        t = t + '    <tspan ng-attr-x="{{config.x + config.width / 2}}" ng-attr-dy="{{ $index==0 ? \'0\' : \'0.9em\' }}"  ng-repeat="text in config.text" >{{text}}</tspan>';
                        t = t + '  </text>';

                        t = t + '</g>'

                        return t;
                    }   
                };
            }
        );
