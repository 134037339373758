<!--Main-->
<div class="widget-flex-box">
    <div class="widget-number-link">
        <a href="#!/reports" (click)="setReportName()"><h1><b>{{ totalDowntime }}</b></h1></a>
</div>

    <div class="widget-number-unit">
        <p>{{unit}}</p>
    </div>
</div>

<!--Subtext-->
<div class="widget-description" *ngIf="isIncludeSubtext" [ngClass]="isIncludeSubtext ? 'small-font' : ''">
    <h4>{{ui.description}}</h4>
</div>



