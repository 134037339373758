//import angular from 'angular';


    angular.module('app.admin')
        .controller('fleetsSearchCtrl', ['$scope', 'FleetFactory', 'ocSecuritySvc', 'confirmSvc', 'WindowFactory', 'amtXlatSvc',
            function ($scope, FleetFactory, ocSecuritySvc, confirmSvc, WindowFactory: IWindowFactory, amtXlatSvc: IAmtXlatSvc) {

                var vm = this;
                vm.fleets = [];

                // security
                vm.canDelete = ocSecuritySvc.isAuthorised('Security.Settings.Fleets.Delete', 'readWrite');

                // get the search criteria from the factory... 
                // NOTE: the factory has a static variable used to keep the search criteria, so..
                //       values will be kept between state transitions
                vm.criteria = FleetFactory.searchCriteria;

                vm.processing = false;
                vm.status = "loading";
                vm.select = {};

                vm.gridControl = {};
                vm.selectedItem = null;

                $scope.rearrange = function () {
                    FleetFactory.rearrange(vm.filter);
                };

                vm.onDatabound = function () {
                    vm.selectedItem = null;
                };

                vm.onSelectedItemChanged = function (items) {
                    if (items != null && items.length === 1) {
                        vm.selectedItem = items[0];
                    } else {
                        vm.selectedItem = null;
                    }
                };

                vm.setHeaderHeight = function (height, offset) {
                    var offset = height + offset;
                    //console.log("grid height based on :" + offset);
                    vm.gridTop = offset;
                };

                vm.export = function () {
                    FleetFactory.export(vm.criteria);
                };

                vm.loadFleetTypesCallback = function (fleetTypes) {
                    vm.fleetTypes = fleetTypes;
                    vm.processing = false;
                    vm.status = 'ready';

                    // focus on fleet name field by default
                    vm.focusFleetName = true;
                };

                vm.loadDefaults = function () {
                    vm.processing = true;
                    FleetFactory.listFleetTypes(vm.loadFleetTypesCallback);
                };

                $scope.editItem = function (item) {
                    if (item !== undefined && item !== null) {
                        item.open('edit', vm.filter);
                    }
                };

                $scope.addFleet = function () {
                    var fleet = FleetFactory.create();

                    fleet.active = true;

                    fleet.open('new', vm.filter);
                };

                $scope.deleteFleet = function (item) {
                    if (item.canDelete === false) {
                        WindowFactory.alert(
                            amtXlatSvc.xlat('fleet.confirmDeleteFleet_title'), // caption
                            ['framework.cancel'], // buttons
                            FleetFactory.constructCannotDeleteMessage(item), // text
                            null, // params
                            null, // width
                            null, // textalignclass
                            true // nonResourceText
                        ).then(
                            function (result) {
                            }
                        );

                        return;
                    }

                    confirmSvc.confirmMessage('fleet.confirmDeleteFleet_title', 'fleet.confirmDeleteFleet_text', item.fleetName).then(function () {
                        vm.processing = true;

                        item.delete(vm.filter).finally(function () {
                            vm.processing = false;
                        });
                    });
                };

                vm.onDataBound = function () {
                    if (vm.gridControl.recompile) {
                        vm.gridControl.recompile();
                    }
                    vm.status = 'ready';
                };

                vm.filter = function () {
                    FleetFactory.search(vm.criteria, vm.onDataBound).then(function (fleets) {
                        vm.fleets = fleets;
                    });
                };

                function init() {
                    vm.filter();

                    vm.loadDefaults();
                }

                init();
            }]);

