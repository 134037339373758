//import angular from 'angular';
import OcDateSvc from '../../../../services/ocDateSvc';
import ReportSvc, { ExportReports, ExportReportParameters, ExportReportCriteria, ExportProviders, ExportOrientation }
                    from '../../../../services/reportSvc';
import StocktakeUrls from './stocktakeUrls';
import tmpl from './stocktakeSearch.html';



const enum StocktakeStatus {
    ok = 'OK',
    error = 'ERROR'
}

type TStocktakeSearchInitParams = {
    id: string;
    serialNumber: string;
    search: string;
}

type TStocktakeSearchDefaults = {
    clients?: KeyNameDescription[],
    sites?: KeyNameDescription[],
    preferredLevel?: KeyNamePair,
    componentType?: KeyNamePair,
    stocktakeStatuses?: KeyNamePair[],
}

interface TStocktakeSearchCriteria extends IGridFilterCriteria {
    filterValues: {
        dateFrom?: Date,
        dateTo?: Date,
        clientIds?: guid[],
        siteIds?: guid[],
        componentTypeId?: guid,
        statusIds?: guid[],
        statuses?: string[],
        stocktakeStatuses?: string[],
        defaultToAllSitesWhenNoSystemHierarchyFilter?: boolean
    }
}

class StocktakeSearchCtrl implements ng.IController {

    form: ng.IFormController;

    status: ScreenStatus = ScreenStatus.loading
    processing: boolean = false;

    selectAll: boolean = true;
    downloadSubmenuVisible: boolean = false;
    showClients: boolean = false;
    showSites: boolean = false;

    resultCount: number = 0;
    siteId: guid;

    gridTop: number;
    gridControl: IGridControl = {};

    selectedItem: any = null;
    defaults: TStocktakeSearchDefaults = {};

    defaultErrors: any[];

    criteria: TStocktakeSearchCriteria = {
        filterValues: {}
    };

    statuses: KeyNamePair[] = [
        { key: StocktakeStatus.ok, name: this.amtXlatSvc.xlat('stocktake.statusOK') },
        { key: StocktakeStatus.error, name: this.amtXlatSvc.xlat('stocktake.statusERROR') }
    ];

    export() { this.stocktakeFactory.export(this.criteria) }

    static $inject = ['$scope', 'stocktakeFactory', 'stocktakeUrls', 'WindowFactory', 'confirmSvc', 'amtXlatSvc',
        'ocDateSvc', 'ocConfigSvc', 'adminUrls', '$timeout', 'notifySvc', 'errorReporter', 'reportSvc', 'amtCommandQuerySvc'];

    constructor(private $scope: ng.IScope, private stocktakeFactory: any, private stocktakeUrls: StocktakeUrls, private WindowFactory: IWindowFactory,
        private confirmSvc: IConfirmSvc, private amtXlatSvc: IAmtXlatSvc, private ocDateSvc: OcDateSvc, private ocConfigSvc: IOcConfigSvc,
        private adminUrls: IAdminUrls, private $timeout: ng.ITimeoutService, private notifySvc: INotifySvc,
        private errorReporter: IErrorReporter, private reportSvc: ReportSvc, private amtCommandQuerySvc: IAmtCommandQuerySvc) {

        this.$scope['editStocktake'] = (stocktake) => this.openStocktake(stocktake, NewOrEditMode.edit);
    }

    async $onInit() {
        await this.loadDefaults();
    }

    toggleDownloadMenu(value: boolean) {
        if (value)
            this.downloadSubmenuVisible = value;
    }

    onStocktakesLoaded(response: any) {
        this.resultCount = 0;

        if (response.result && response.result.length) {
            let data = response.result;

            for (let i = 0; i < data.length; i++) {
                data[i].onSite = data[i].siteId === this.ocConfigSvc.user.site.id;
                data[i].statusText = this.amtXlatSvc.xlat('stocktake.status' + data[i].status);
            }

            this.resultCount = response.result.length;
        }

        return response;
    }

    onStocktakeGridDatabound(event: any) {
        if (event) {
            let rows = event.sender.tbody.children();
            let data = event.sender._data;

            for (let i = 0; i < data.length; i++) {

                if (data[i].status === StocktakeStatus.error) {
                    // if the status is not Okay, then highlight the row
                    let row = $(rows[i]);
                    row.addClass('bg-danger');
                }
            }
        }
    }

    openStocktake(stocktake: any, mode: NewOrEditMode) {
        this.WindowFactory.openItem({
            component: 'stocktake',
            caption: this.amtXlatSvc.xlat(`stocktake.${mode}`),
            initParams: {
                mode: mode,
                data: stocktake
            },
            width: 1200,
            height: 800,
            windowRelatedRecordId: stocktake.id,
            modal: false,
            footerOff: true,
            onDataChangeHandler: () => this.filter()
        });
    }

    createStocktake() {

        let initParams = {
            defaults: this.defaults
        };

        this.WindowFactory.openItem({
            component: 'create-stocktake',
            caption: this.amtXlatSvc.xlat('stocktake.create'),
            initParams: initParams,
            modal: true,
            width: 800,
            canClose: false,
            onDataChangeHandler: (data) => {
                // pop open the create window with the new stocktake
                this.openStocktake({
                    createdDate: data.date,
                    preferredLevel: data.preferredLevel.key,
                    componentType: data.type.name.toLowerCase()
                }, NewOrEditMode.new);
            }
        });
    }

    async deleteStocktake() {
        let dateDescription = this.ocDateSvc.toString(this.selectedItem.createdDate);

        try {
            await this.confirmSvc.confirmMessage('stocktake.confirmDelete_title', 'stocktake.confirmDelete_text', dateDescription);
        } catch {
            return; // they cancelled
        }

        this.processing = true;

        try {
            let response = await this.amtCommandQuerySvc.post(this.stocktakeUrls.deleteStocktake, { id: this.selectedItem.id });

            if (response) {
                this.notifySvc.success(this.amtXlatSvc.xlat('stocktake.stocktakeDeleted'));
                this.filter()
            }
        } catch (error) {
            this.errorReporter.logError(error);
        } finally {
            this.processing = false;
        }
    }

    async downloadStocktakeReport(stocktake: any) {

        let report = this.reportSvc.getReport(ExportReports.physicalStocktake);

        if (report) {

            let parameters = [];

            if (report.parameters && report.parameters.length) {
                parameters = this.reportSvc.constructBaseParameters(report);
                parameters = this.reportSvc.constructParameter(parameters, report, 'Id', stocktake.id);
            }

            let filename = String.format("{0}-{1}-{2}", report.description || report.name, this.ocConfigSvc.user.site.name, this.ocDateSvc.toReportString(stocktake.createdDate));

            let exportCriteria: ExportReportCriteria = {
                report: report,
                provider: ExportProviders.pdf,
                parameters: parameters,
                filename: filename,
                orientation: ExportOrientation.portrait
            };            

            try {
                await this.reportSvc.exportReport(exportCriteria);
            } catch (error) {
                this.errorReporter.logError(error);
            }
        }
    }

    onSelectedItemChanged(items: any[]) {
        if (items && items.length) {
            this.selectedItem = items[0];
        } else {
            this.selectedItem = null;
        }
    }

    async loadDefaults() {

        this.status = ScreenStatus.loading;
        this.processing = true;

        let defaultCriteria = {
            statusTypeNames: []
        };

        this.criteria = {
            filterValues: {
                clientIds: [],
                siteIds: [],
                componentTypeId: null,
                statuses: [],
                statusIds: [],
                stocktakeStatuses: [],
                defaultToAllSitesWhenNoSystemHierarchyFilter: true
            }
        };

        try {
            let response = await this.amtCommandQuerySvc.post(this.stocktakeUrls.getDefaults, defaultCriteria);

            if (response) {

                if (response.status === HttpStatus.fail)
                    return;
                this.defaults = response;

                this.siteId = response.sites[0].key;

                this.status = ScreenStatus.ready;
            }
        } catch (error) {
            this.defaultErrors = [error];
            this.status = ScreenStatus.error;
            this.errorReporter.logError(error);
        } finally {
            this.processing = false;
            this.loadFromSiteDashboard();
        }
    }

    loadFromSiteDashboard() {
        if (localStorage.getItem("siteDashboardSearchby") == "Error") {
            this.defaults.stocktakeStatuses = [{ key: StocktakeStatus.error, name: this.amtXlatSvc.xlat('stocktake.statusERROR') }];
            this.criteria.filterValues.stocktakeStatuses = ["ERROR"];
            localStorage.setItem("siteDashboardSearchby", "undefined");

            this.$timeout(() => {
                this.showClients = this.criteria.filterValues.clientIds.length !== 1;
                this.showSites = this.criteria.filterValues.siteIds.length !== 1;
                let dateFrom = this.criteria.filterValues.dateFrom;
                let dateTo = this.criteria.filterValues.dateTo;

                this.gridControl.refresh();

                this.criteria.filterValues.dateFrom = dateFrom;
                this.criteria.filterValues.dateTo = dateTo;
            }, 3000);
        }
    }

    filter() {
        this.showClients = this.criteria.filterValues.clientIds.length !== 1;
        this.showSites = this.criteria.filterValues.siteIds.length !== 1;

        let dateFrom = this.criteria.filterValues.dateFrom;
        let dateTo = this.criteria.filterValues.dateTo;

        this.gridControl.refresh();

        this.$timeout(() => {
            this.criteria.filterValues.dateFrom = dateFrom;
            this.criteria.filterValues.dateTo = dateTo;
        });
    }

    setHeaderHeight(height: number, offset: number) {
        this.gridTop = height + offset;
    }

    downloadBlankStocktakeForm() {
        this.WindowFactory.openItem({
            component: 'blank-stocktake-form',
            modal: true,
            canClose: false,
            caption: this.amtXlatSvc.xlat('stocktake.downloadBlankForm'),
            initParams: {
                defaultComponentTypeId: this.defaults.componentType.key,
                defaultStocktakeLevelId: this.defaults.preferredLevel.key
            },
            width: 600
        });
    }
}

class StocktakeSearchComponent implements ng.IComponentOptions {
    public template = tmpl;
    public controller = StocktakeSearchCtrl;
    public controllerAs = 'vm';
}

angular.module('app.component').component('stocktakeSearch', new StocktakeSearchComponent());