/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var treeview_component_1 = require("./treeview.component");
/**
 * A directive which manages the disabled in-memory state of the TreeView node
 * ([see example]({% slug disabledstate_treeview %})).
 */
var DisableDirective = /** @class */ (function () {
    function DisableDirective(treeView, cdr) {
        var _this = this;
        this.treeView = treeView;
        this.cdr = cdr;
        /**
         * Defines the collection that will store the disabled keys.
         */
        this.disabledKeys = [];
        this.treeView.isDisabled = function (dataItem, index) { return (_this.disabledKeys.indexOf(_this.itemKey({ dataItem: dataItem, index: index })) > -1); };
    }
    Object.defineProperty(DisableDirective.prototype, "isDisabled", {
        /**
         * @hidden
         */
        set: function (value) {
            this.treeView.isDisabled = value;
        },
        enumerable: true,
        configurable: true
    });
    DisableDirective.prototype.ngOnChanges = function (changes) {
        if (changes === void 0) { changes = {}; }
        var disabledKeys = changes.disabledKeys;
        if (disabledKeys && !disabledKeys.firstChange) {
            this.cdr.markForCheck();
        }
    };
    DisableDirective.prototype.itemKey = function (e) {
        if (!this.disableKey) {
            return e.index;
        }
        if (typeof this.disableKey === "string") {
            return e.dataItem[this.disableKey];
        }
        if (typeof this.disableKey === "function") {
            return this.disableKey(e);
        }
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Function])
    ], DisableDirective.prototype, "isDisabled", null);
    tslib_1.__decorate([
        core_1.Input("kendoTreeViewDisable"),
        tslib_1.__metadata("design:type", Object)
    ], DisableDirective.prototype, "disableKey", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Array)
    ], DisableDirective.prototype, "disabledKeys", void 0);
    DisableDirective = tslib_1.__decorate([
        core_1.Directive({ selector: '[kendoTreeViewDisable]' }),
        tslib_1.__metadata("design:paramtypes", [treeview_component_1.TreeViewComponent,
            core_1.ChangeDetectorRef])
    ], DisableDirective);
    return DisableDirective;
}());
exports.DisableDirective = DisableDirective;
