//import angular from 'angular';


angular.module('app').factory('ocSecuritySvc', ['ocConfigSvc',
    function (ocConfigSvc) {

        this.accessTypes = {
            any: 0, // not a real value, this is used for searching
            hide: 0,
            readOnly: 1,
            readWrite: 2,
            highest: 2 // not a real value, this is used for searching, this value is used when a permission is checked but no level is specified, it defaults to the highest
        };

        this.getAccessType = function (permissionName, clientId, siteId, noDefault?) {
            var accessType = this.accessTypes.hide;

            if (!ocConfigSvc.user) {
                throw new Error("ocSecurity:No user set");
            }
            if (!ocConfigSvc.user.client || !ocConfigSvc.user.site) {
                throw new Error("ocSecurity:missing client and or site");
            }

            if (!ocConfigSvc.user.roles) {
                throw new Error("ocSecurity:Roles not configured");
            }

            if (!noDefault) {
                if (!clientId) {
                    // default to current site                
                    clientId = ocConfigSvc.user.client.id;
                }

                if (!siteId) {
                    // default to current site
                    siteId = ocConfigSvc.user.site.id;
                }
            }

            if (ocConfigSvc.user) {
                for (var r = 0; r < ocConfigSvc.user.roles.length; r++) {
                    var role = ocConfigSvc.user.roles[r];

                    if (role.systemHierarchyId === ocConfigSvc.user.system.id || role.systemHierarchyId === clientId || role.systemHierarchyId === siteId) {
                        for (var p = 0; p < role.permissions.length; p++) {
                            var permission = role.permissions[p];

                            if (permission.name === permissionName) {
                                if (permission.accessType > accessType) {
                                    accessType = permission.accessType;
                                }
                            }
                        }
                    }
                }
            }

            return accessType;
        };

        this.getCostAccessType = function (clientId, siteId) {
            var costType = this.accessTypes.hide;

            if (!ocConfigSvc.user) {
                throw new Error("ocSecurity:No user set");
            }
            if (!ocConfigSvc.user.client || !ocConfigSvc.user.site) {
                throw new Error("ocSecurity:missing client and or site");
            }

            if (!ocConfigSvc.user.roles) {
                throw new Error("ocSecurity:Roles not configured");
            }

            if (!clientId) {
                // default to current site                
                clientId = ocConfigSvc.user.client.id;
            }

            if (!siteId) {
                // default to current site
                siteId = ocConfigSvc.user.site.id;
            }

            if (ocConfigSvc.user) {
                for (var r = 0; r < ocConfigSvc.user.roles.length; r++) {
                    var role = ocConfigSvc.user.roles[r];

                    if (role.systemHierarchyId === ocConfigSvc.user.system.id || role.systemHierarchyId === clientId || role.systemHierarchyId === siteId) {
                        if (role.costAccessType > costType) {
                            costType = role.costAccessType;
                        }
                    }
                }
            }

            // return the max cost access type the user has at the location
            return costType;
        };

        this.hasPermission = function (permissionName, clientId, siteId, noDefault) {
            var costType = this.accessTypes.hide;

            if (!ocConfigSvc.user) {
                throw new Error("ocSecurity:No user set");
            }
            if (!ocConfigSvc.user.client || !ocConfigSvc.user.site) {
                throw new Error("ocSecurity:missing client and or site");
            }

            if (!ocConfigSvc.user.roles) {
                throw new Error("ocSecurity:Roles not configured");
            }

            if (!clientId) {
                // default to current site                
                clientId = ocConfigSvc.user.client.id;
            }

            if (!siteId) {
                // default to current site
                siteId = ocConfigSvc.user.site.id;
            }

            if (ocConfigSvc.user) {
                for (var r = 0; r < ocConfigSvc.user.roles.length; r++) {
                    var role = ocConfigSvc.user.roles[r];

                    for (var p = 0; p < role.permissions.length; p++) {
                        
                        if (role.systemHierarchyId === ocConfigSvc.user.system.id || role.systemHierarchyId === clientId || role.systemHierarchyId === siteId) {
                            if (role.permissions[p].name == permissionName) {
                                return true;
                            }
                        }
                    }
                }
            }
            return false;
        }

        // also avaliable via the webPermission filter for use in angularJS template html
        this.isAuthorised = function (permissionName, minimumAccess, clientId, siteId, noDefault?) {
            try {
                let minimumAccessNumber = this.accessTypes[minimumAccess];

                if (minimumAccessNumber == null) {
                    console.error("Security Level '" + minimumAccess + "' is not defined. permission: " + permissionName);
                    minimumAccessNumber = this.accessTypes.highest;
                }

                return this.getAccessType(permissionName, clientId, siteId, noDefault) >= minimumAccessNumber;
            }
            catch { /* fall through */ }
            return false;

        };

        return {
            isAuthorised: this.isAuthorised,
            getAccessType: this.getAccessType,
            getCostAccessType: this.getCostAccessType,
            accessTypes: this.accessTypes,
            hasPermission: this.hasPermission
        };

    }
]);