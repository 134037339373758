/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var models_1 = require("../models");
/**
 * @hidden
 */
var DragClueComponent = /** @class */ (function () {
    function DragClueComponent(cdr) {
        this.cdr = cdr;
        this.hostClasses = true;
        this.posistionStyle = 'fixed';
    }
    Object.defineProperty(DragClueComponent.prototype, "statusIconClass", {
        get: function () {
            switch (this.action) {
                case models_1.DropAction.Add: return 'k-i-plus';
                case models_1.DropAction.InsertTop: return 'k-i-insert-up';
                case models_1.DropAction.InsertBottom: return 'k-i-insert-down';
                case models_1.DropAction.InsertMiddle: return 'k-i-insert-middle';
                case models_1.DropAction.Invalid:
                default: return 'k-i-cancel';
            }
        },
        enumerable: true,
        configurable: true
    });
    // exposed as a public method that can be called from outside as the component uses `OnPush` strategy
    DragClueComponent.prototype.detectChanges = function () {
        this.cdr.detectChanges();
    };
    tslib_1.__decorate([
        core_1.HostBinding('class.k-header'),
        core_1.HostBinding('class.k-drag-clue'),
        tslib_1.__metadata("design:type", Boolean)
    ], DragClueComponent.prototype, "hostClasses", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('style.position'),
        tslib_1.__metadata("design:type", String)
    ], DragClueComponent.prototype, "posistionStyle", void 0);
    DragClueComponent = tslib_1.__decorate([
        core_1.Component({
            changeDetection: core_1.ChangeDetectionStrategy.OnPush,
            selector: 'kendo-treeview-drag-clue',
            template: "\n        <ng-container *ngIf=\"!template\">\n            <span class=\"k-icon {{statusIconClass}} k-drag-status\"></span>\n            <span>{{text}}</span>\n        </ng-container>\n\n        <ng-template\n            *ngIf=\"template\"\n            [ngTemplateOutlet]=\"template\"\n            [ngTemplateOutletContext]=\"{\n                text: text,\n                action: action,\n                sourceItem: sourceItem,\n                destinationItem: destinationItem\n            }\"\n        >\n        </ng-template>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.ChangeDetectorRef])
    ], DragClueComponent);
    return DragClueComponent;
}());
exports.DragClueComponent = DragClueComponent;
