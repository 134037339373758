//import angular from 'angular';

    angular.module('app.directives').component('uiButterbar',  {
        template: '<div class="butterbar active" ng-show="$ctrl.visible" ><span class="bar"></span><div>',
        bindings: {},
        controller: ['$rootScope', '$timeout', function ($rootScope, $timeout) {

            var ctrl = this;
            ctrl.visible = false;

            ctrl.hiding = 0;
            ctrl.on = true;

            // Note : the outstanding request counting is done out side of this. 
            // This can result in multiple requestStart, followed by a single request end;
            this.$onInit = function () {
                ctrl.cleanUpStart = $rootScope.$on('requestStart', function () {
                    ctrl.hiding++;
                    ctrl.on = true;
                    $timeout(function () {
                        // wait a moment to prevent flashing. Then ensure that the Animation is really required. 
                        if (ctrl.on) {
                            ctrl.visible = true;
                        }
                    }, 100);
                });

                ctrl.cleanUpEnd = $rootScope.$on('requestEnd', function () {                    
                    // record the request which is ending
                    var hide = ctrl.hiding;
                    ctrl.on = false;
                    // wait a moment 
                    $timeout(function () {
                        // now verify that the request which ended is the last
                        if (ctrl.hiding === hide) {
                            ctrl.visible = false; // we only hide when we don't have a new on request already
                        }
                    }, 100); // delay to stop jumping when we back to back requests.
                });
            };

            this.$onDestroy = function () {
                ctrl.cleanUpStart();
                ctrl.cleanUpEnd();
            };
        }]
    });
