//import angular from 'angular';
import tmpl from './ocImageViewer.html';



class ocImageViewerCtrl implements ng.IComponentController {

    public readonly loadingUrl = 'content/common/spinner.gif';

    url: string;
    description: string;

    fitToScreen = true;

    imageElementId = 'ocImageViewer' + this.$scope.$id;

    zoomLevel?: number;
    zoomAmount?: number;

    savedZoom: number = 1;

    savedOffsetX: number = 0;
    savedOffsetY: number = 0;

    imageWidth: number;
    imageHeight: number;

    screenCentreX = 0;
    screenCentreY = 0;


    protected zoomLevels = [0.04, .0625, .090909, .125, .1825, .25, .333333, .50, .666667, 1, 1.5, 2, 3, 4, 5.5, 8, 11, 16, 25];

    docElement: JQuery<HTMLElement>;

    static $inject = ['$scope', '$window', '$timeout', '$element', '$document'];

    constructor(private $scope: ng.IScope, private $window: ng.IWindowService, private $timeout: ng.ITimeoutService,
        private $element: ng.IRootElementService, private $document: ng.IDocumentService) {

        this.docElement = angular.element($document[0].documentElement);
    }

    public $onInit() {
    }

    public $postLink() {
        this.updateOffsetAndScale();

        this.$element.on('click', () => {
            this.closeFn();
            this.$timeout();
        });

        this.$element.on('wheel', e => {
            this.zoom(-(e.originalEvent as WheelEvent).deltaY);
            this.$timeout();
        });

        this.docElement.on('keypress.ocImageViewer', e => {
            switch (e.which) {
                case 45: // minus
                case 95: // underscore
                    this.zoom(-1);
                    this.$timeout();
                    break;
                case 43: //plus
                case 61: //equals
                    this.zoom(1);
                    this.$timeout();
                    break;
            }
        });

        angular.element(this.$window).on('resize.ocImageViewer', () => this.windowResize());

        this.$timeout(() => {
            let el = document.getElementById(this.imageElementId) as HTMLImageElement;

            if (!el)
                return;

            el.onload = ev => {
                this.imageWidth = (ev.target as HTMLImageElement).naturalWidth;
                this.imageHeight = (ev.target as HTMLImageElement).naturalHeight;
                this.zoom();
            };

            el.src = this.url;
        })
    }

    public $onDestroy() {
        this.docElement.off('keypress.ocImageViewer');
        angular.element(this.$window).off('resize.ocImageViewer');
    }

    public closeFn() {
        this.$scope.$root.$broadcast("imageViewerClose");
    }

    public windowResize() {
        // rough (over?-)estimate sizes of UI elements taking up screenspace, we'd like to initially avoid overlapping
        const fudgeX = 80;
        const fudgeY = 60;

        let w = this.$window.innerWidth - fudgeX;
        let h = this.$window.innerHeight - fudgeY;

        this.screenCentreX = w / 2;
        this.screenCentreY = h / 2 + 20;

        if (this.fitToScreen && this.imageWidth && this.imageHeight) {
            this.zoomAmount = Math.min(w / this.imageWidth, h / this.imageHeight);
            this.savedZoom = this.zoomAmount;
            this.zoomLevel = null;
        }

        this.updateOffsetAndScale();

        this.$timeout();
    }

    public updateOffsetAndScale(x: number = null, y: number = null) {
        let elementStyle = this.$element[0].style;

        if (!Number.isFinite(x))
            x = this.savedOffsetX;

        if (!Number.isFinite(y))
            y = this.savedOffsetY;

        elementStyle.setProperty('--offset-x', String(x + (this.screenCentreX - 0.5 * this.imageWidth)   ) + 'px');
        elementStyle.setProperty('--offset-y', String(y + (this.screenCentreY - 0.5 * this.imageHeight)  ) + 'px');
        elementStyle.setProperty('--scale', String(this.zoomAmount));
    }

    public zoom(change?: number) {
        if (!change || !this.zoomAmount) {
            this.fitToScreen = true;
            this.savedOffsetX = 0;
            this.savedOffsetY = 0;
            this.$timeout(() => this.windowResize());
            return;
        }

        this.fitToScreen = false;

        if (this.zoomLevel != null) {
            this.zoomLevel += Math.sign(change);
        } else {
            this.zoomLevel = 0;

            if (change > 0) {
                for (let i = this.zoomLevels.length - 1; i >= 0; --i) {
                    if (this.zoomLevels[i] < this.zoomAmount)
                        break;

                    this.zoomLevel = i;
                }
            } else {
                for (let i = 0; i < this.zoomLevels.length; ++i) {
                    if (this.zoomLevels[i] > this.zoomAmount)
                        break;

                    this.zoomLevel = i;
                }
            }
        }

        if (this.zoomLevel < 0)
            this.zoomLevel = 0;

        if (this.zoomLevel >= this.zoomLevels.length)
            this.zoomLevel = this.zoomLevels.length - 1;

        this.zoomAmount = this.zoomLevels[this.zoomLevel];
        this.savedZoom = this.zoomAmount; //HACK

        this.updateOffsetAndScale();
    }

    public onPanStart(event: HammerInput) {
        this.fitToScreen = false;        
        this.zoomLevel = null;
    }

    public onPanEnd(event: HammerInput) {
        this.savedOffsetX += event.deltaX;
        this.savedOffsetY += event.deltaY;

        this.updateOffsetAndScale();
    }

    public onPinchStart(event: HammerInput) {
        this.fitToScreen = false;
        this.zoomLevel = null;
        this.savedZoom = this.zoomAmount;
    }

    public onPinchEnd(event: HammerInput) {
        this.savedZoom = this.zoomAmount;
    }

    public onGesture(event: HammerInput) {
        this.zoomAmount = event.scale * this.savedZoom;

        //this is wrong but simple (doesn't take into account the scale pan interaction with a guesture, the position the zoom occurs around, etc)
        this.updateOffsetAndScale(this.savedOffsetX + event.deltaX, this.savedOffsetY + event.deltaY);

        this.$timeout();
    }
}

class ocImageViewerComponent implements ng.IComponentOptions {
    public bindings = {
        url: '<',
        initParams: '<?',
        description: '<'
    };
    public template = tmpl;
    public controller = ocImageViewerCtrl;
    public controllerAs = 'vm';
}

angular.module('app.directives').component('ocImageViewer', new ocImageViewerComponent());