/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * @hidden
 */
var DropHintComponent = /** @class */ (function () {
    function DropHintComponent(changeDetectorRef) {
        this.changeDetectorRef = changeDetectorRef;
        this.hostClass = true;
        this.position = 'fixed';
        this.pointerEvents = 'none';
    }
    // exposed as a public method that can be called from outside as the component uses `OnPush` strategy
    DropHintComponent.prototype.detectChanges = function () {
        this.changeDetectorRef.detectChanges();
    };
    tslib_1.__decorate([
        core_1.HostBinding('class.k-drop-hint-container'),
        tslib_1.__metadata("design:type", Boolean)
    ], DropHintComponent.prototype, "hostClass", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('style.position'),
        tslib_1.__metadata("design:type", String)
    ], DropHintComponent.prototype, "position", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('style.pointer-events'),
        tslib_1.__metadata("design:type", String)
    ], DropHintComponent.prototype, "pointerEvents", void 0);
    DropHintComponent = tslib_1.__decorate([
        core_1.Component({
            changeDetection: core_1.ChangeDetectionStrategy.OnPush,
            selector: 'kendo-treeview-drop-hint',
            template: "\n        <div\n            *ngIf=\"!template\"\n            class=\"k-drop-hint k-drop-hint-h\"\n        >\n            <div class='k-drop-hint-start'></div>\n            <div class='k-drop-hint-line'></div>\n        </div>\n\n        <ng-template\n            *ngIf=\"template\"\n            [ngTemplateOutlet]=\"template\"\n            [ngTemplateOutletContext]=\"{\n                action: action,\n                sourceItem: sourceItem,\n                destinationItem: destinationItem\n            }\"\n        >\n        <ng-template>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.ChangeDetectorRef])
    ], DropHintComponent);
    return DropHintComponent;
}());
exports.DropHintComponent = DropHintComponent;
