import 'angular-promise-tracker';
import 'angular-progress-button';

import amtFramework from './amtModule';

import '../config';
import './i18n/xlat';
import './i18n/xlat-filter';
import './services/amtFilterSvc';
import './services/amtNotificationSvc';
import './components';

export default amtFramework;