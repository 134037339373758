//import angular from 'angular';


    angular.module('app.directives')
        .directive(
        'ocMenuItem',
            ['ocSecuritySvc',                              
            function (ocSecuritySvc) {
                return {
                    transclude: true,
                    replace: true,
                    restrict: 'E',
                    requires: '^ul',
                    scope: {
                        onClick: "&?",
                        navState: "@",
                        navData: "=?",
                        text: "@",
                        isDefault: "=?",
                    },
                    link: function (scope: any, element) {
                        scope.elm = element;
                        scope.defaultData = scope.navData;
                        if (scope.activeStart) {
                            scope.elm.addClass('active');
                        }
                    },
                    controller: ["$scope", "$state", "$timeout", function ($scope, $state, $timeout) {
                        var isSiteMenuActive = false;
                        var isActiveComponent = false;
                        var navStateItemList = $scope.navState.split('.');
                        var landing = navStateItemList[1].charAt(0).toUpperCase() + navStateItemList[1].slice(1);

                        if (landing == 'Site') {
                            var component = navStateItemList[2].charAt(0).toUpperCase() + navStateItemList[2].slice(1);
                            if (component == 'Endofmonth') {
                                component = 'EndOfMonth';
                            }
                            else if (component == 'Forecastlist') {
                                component = 'Forecasting';
                            }
                            else if (component == 'PurchaseOrders') {
                                component = 'PurchaseOrders.View';
                            }
                            var security = "Security" + "." + landing + "." + component;
                            isActiveComponent = ocSecuritySvc.isAuthorised(security, 'readOnly');
                            isSiteMenuActive = true;
                        }

                        if ($scope.navData) {
                            var j = JSON.stringify($scope.navData);
                            $scope.navStateWithParams = $scope.navState + "(" + j + ")";
                        } else {
                            $scope.navStateWithParams = $scope.navState;
                        }

                        $scope.bubble = function (data) {
                            // data is bubbled up from below and passed to the route.
                            $scope.navData = data;
                            $timeout(function () {
                                $scope.elm.find('.active').removeClass('active'); 
                                $scope.elm.find('a').first().click();
                                $timeout(function () {
                                    $scope.elm.addClass('active'); // just in-case
                                });
                            });
                        }

                        $scope.itemClicked = function (data) {
                            if ($scope.elm && $scope.elm.parent()) {
                                // this clear the submenu child selection when moving sideway along the menu.
                                $scope.elm.parent().find('.active').removeClass('active');
                            }
                            if ($scope.onClick) {
                                $scope.onClick();
                            } else {
                                if ($scope.navData) {
                                    $state.go($scope.navState, $scope.navData);
                                    $scope.navData = $scope.defaultData;
                                } else {
                                    $state.go($scope.navState);
                                }
                            }
                        };

                        // if landing page is not 'Site'
                        if ($scope.navState === $state.current.name && !$scope.navData && !isSiteMenuActive) {
                            $scope.activeStart = true; 
                        }
                        else {
                            if ($scope.isDefault && !isSiteMenuActive) {
                                // we don't want to move to the default menu state if we have refreshed the page and already have a sub state.
                                if ($scope.navState.startsWith($state.current.name) || $state.current.name === 'app.landing') {
                                        $scope.activeStart = true;
                                        $scope.itemClicked();
                                }
                            }
                        }

                        // if landing page is 'Site' 
                        if (isSiteMenuActive) {

                            if ($scope.isDefault && isActiveComponent) {
                                $scope.activeStart = true;
                                $scope.itemClicked();
                            }

                            else if ($scope.isDefault && !isActiveComponent) {
                                localStorage.setItem("isDefaultNotActive", 'true');
                            }

                            else if (!$scope.isDefault && isActiveComponent) {

                                if (localStorage.getItem("isDefaultNotActive") == "true") {
                                    $scope.activeStart = true;
                                    $scope.itemClicked();
                                    localStorage.removeItem("isDefaultNotActive");
                                }

                            }
                        }

                    }],

                    template: function (scope) {
                        return '<li><a ng-click="itemClicked()"><span class="force-uppercase">{{text}}</span></a><ng-transclude /></li>';
                    }
                };
            }
        ]
    );
