//import angular from 'angular';
import AdminUrls from '../../../admin/adminUrls';
import tmpl from './AddRolePopup.html';



    angular.module("app.admin").component("addRolePopup", {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['helperSvc', '$scope', 'adminUrls', 'amtXlatSvc', 'enums', 'amtCommandQuerySvc', 'confirmSvc', 'WindowFactory', 'notifySvc', 'ocConfigSvc', 'errorReporter', 'ocSecuritySvc',
            function (helperSvc, $scope, adminUrls: AdminUrls, xlatSvc: IAmtXlatSvc, enums, amtCommandQuerySvc, confirmSvc, WindowFactory: IWindowFactory, notifySvc, ocConfigSvc, errorReporter, ocSecuritySvc) {

                var vm = this;

                $scope.vm = this;

                vm.form = {};
                vm.apiUrls = adminUrls;
                vm.config = ocConfigSvc;
                vm.isVisible = true;

                vm.client = null;
                vm.siteIds = [];
                vm.role = null;

                vm.componentOwnerIds = [];
                vm.costSelectedItem = null;
                vm.manufacturerIds = [];

                vm.showManufacturerOptions = false;
                vm.showComponentOwnerOptions = false;

                vm.roleSelectionChanged = roleSelectionChanged;
                vm.componentSelectionChanged = componentSelectionChanged;

                this.$onInit = function () {

                    vm.wnd.onClose = vm.onClose;
                    vm.mode = vm.initParams.mode;
                    vm.status = enums.screenStatus.ready;
                    vm.item = vm.initParams.item;

                    //OR-11229
                    vm.hasCostAccessGrantPermission = ocSecuritySvc.hasPermission("Security.Settings.Users.Roles.GrantCostAccess", 'readWrite');

                    vm.hasGrantManufacturerPermission = ocSecuritySvc.hasPermission("Security.Settings.Users.Roles.GrantManufacturers", 'readWrite');

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: 'common.save_label',
                            name: 'saveButton',
                            click: 'save',
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: true,
                            value: 'common.cancel_label',
                            name: 'cancelButton',
                            click: 'cancel',
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: true,
                            disabled: true
                        },
                        cancelButton: {
                            visible: true,
                            disabled: false
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.onSave,
                        cancel: vm.onClose
                    };
                };

                function closeWindow() {
                    WindowFactory.closeWindow(vm.wnd);
                }

                $scope.setForm = function () {
                    $scope.$watch('vm.form.$dirty', function () {
                        vm.wnd.isDirty = vm.form.$dirty;
                    });
                };

                vm.onClose = function () {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        vm.form.dirty = false;
                        closeWindow();
                        return true;
                    });
                    return false;
                };

                vm.onSave = function () {

                    vm.wnd.processing = true;

                    var command = {
                        personId: vm.initParams.user.personId,
                        roleClientId: helperSvc.getKey(vm.client),
                        roleSiteIds: vm.siteIds,
                        roleId: helperSvc.getKey(vm.role),
                        componentOwnerIds: vm.componentOwnerIds,
                        manufacturerIds: vm.manufacturerIds,
                        cost: undefined,
                        componentTypeId: undefined
                    };

                    if (vm.costSelectedItem) {
                        command.cost = vm.costSelectedItem.key;
                    }

                    if (vm.componentTypeSelectedItem) {
                        command.componentTypeId = vm.componentTypeSelectedItem.key;
                    }

                    amtCommandQuerySvc.post(vm.apiUrls.addNewUserRoles, command)
                        .then(function (response) {

                            if (response.data === 'addUserRoles_duplicatesEncountered') {
                                notifySvc.error(xlatSvc.xlat("userSecurity.addDuplicateRoles"));
                            } else if (response.data === 'addUserRoles_dundasFail') {
                                notifySvc.error(xlatSvc.xlat("userSecurity.addRole_dundasFail", response.errors[0].message));
                            } else {
                                notifySvc.success(xlatSvc.xlat("userSecurity.saveSuccessful"));
                            }

                            vm.wnd.onDataChanged();
                            closeWindow();

                        }).catch(function (error) {
                            errorReporter.logError(error);
                        }).finally(function () {
                            vm.wnd.processing = false;
                        });
                };

                function roleSelectionChanged(role) {
                    vm.showManufacturerOptions = (role && !role.canViewAllManufacturers);
                    vm.showComponentOwnerOptions = (role && !role.canViewAllComponentOwners);

                    if (!vm.showManufacturerOptions) {
                        vm.componentTypeSelectedItem = undefined;
                        vm.manufacturerIds = [];
                    }

                    if (!vm.showComponentOwnerOptions) {
                        vm.componentOwnerIds = [];
                    }
                }

                function componentSelectionChanged(selectedComponentType) {
                    if (selectedComponentType === undefined || selectedComponentType === {}) {
                        vm.manufacturerIds = [];
                    }
                }

                $scope.$watchGroup(['vm.form.$invalid', 'vm.wnd.processing'], function () {
                    vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.wnd.processing;
                });
            }
        ]
    });
