//import angular from 'angular';


    angular.module("app").factory("referenceDataUrls", function () {
        var referenceDataController = 'referenceData/';

        return {
            getStatuses: baseApiUrl + referenceDataController + 'getStatuses',
            getVisualInspectionActions: baseApiUrl + referenceDataController + 'getVisualInspectionActions',
            getVisualInspectionCauses: baseApiUrl + referenceDataController + 'getVisualInspectionCauses',
            getReturnToSupplierReasons: referenceDataController + 'getReturnToSupplierReasons',
            getDamageCauses: baseApiUrl + referenceDataController + 'getDamageCauses',
            getDamageLocations: baseApiUrl + referenceDataController + 'getDamageLocations',
            getDamageSources: baseApiUrl + referenceDataController + 'getDamageSources',
            getStatusFlows: baseApiUrl + referenceDataController + 'getStatusFlows',
            getRetreaders: baseApiUrl + referenceDataController + 'getRetreaders',
            getRepairers: baseApiUrl + referenceDataController + 'getRepairers',
            getSuppliers: referenceDataController + 'getSuppliers',
            getCurrencyTypes: baseApiUrl + referenceDataController + 'getCurrencyTypes',
            getRepairTypes: referenceDataController + 'getRepairTypes',
            getAxleLocations: baseApiUrl + referenceDataController + 'getAxleLocations',                    
            getAxleLocations_short: referenceDataController + 'getAxleLocations',
            getSites: baseApiUrl + referenceDataController + 'getSites',
            getClaimStatuses: baseApiUrl + referenceDataController + 'getClaimStatuses',
            getEquipmentLifeTypes: baseApiUrl + referenceDataController + 'getEquipmentLifeTypes',
            getCostTypes: baseApiUrl + referenceDataController + 'getCostTypes',
            getUnknownComponentReceiveDestinationStatusTypes: baseApiUrl + referenceDataController + 'getUnknownComponentReceiveDestinationStatusTypes',
            getMaintenanceSessionStatuses: baseApiUrl + referenceDataController + 'getMaintenanceSessionStatuses',
            getMaintenanceSessionStatuses_short: referenceDataController + "getMaintenanceSessionStatuses", // Another good example of the need for swagger.
            checkValidFitmentForPosition: referenceDataController + "checkValidFitmentForPosition",
            getOperationTypes: baseApiUrl + referenceDataController + "getOperationTypes",
            getSiteTypes: baseApiUrl + referenceDataController + "getSiteTypes",
            getMineSiteCommodities: baseApiUrl + referenceDataController + "getMineSiteCommodities",
            getUnitsOfMeasure: referenceDataController + "getUnitsOfMeasure",
            getTreadPatterns: baseApiUrl + referenceDataController + "getTreadPatterns",
            getTreadCompounds: baseApiUrl + referenceDataController + "getTreadCompounds",
            getEquipmentConstructions: baseApiUrl + referenceDataController + "getEquipmentConstructions",
            getPlyRatings: baseApiUrl + referenceDataController + "getPlyRatings",
            getPositionLabels: baseApiUrl + referenceDataController + "getPositionLabels",
            getTRACodes: baseApiUrl + referenceDataController + "getTRACodes",
            getVehicleApplications_short: referenceDataController + "getVehicleApplications",
            getVehicleApplications: baseApiUrl + referenceDataController + "getVehicleApplications",
            getSystemHierarchyUnitsOfMeasure: referenceDataController + "getSystemHierarchyUnitsOfMeasure",
            searchComponentSizes: baseApiUrl + referenceDataController + "searchComponentSizes",
            exportComponentSizes: referenceDataController + "exportComponentSizes",
            getAspectRatios: baseApiUrl + referenceDataController + "getAspectRatios",
            getComponentSizeDetails: referenceDataController + "getComponentSizeDetails",
            addComponentSize: referenceDataController + "addComponentSize",
            modifyComponentSize: referenceDataController + "modifyComponentSize",

            searchTreadPatterns: baseApiUrl + referenceDataController + "searchTreadPatterns",
            exportTreadPatterns: referenceDataController + "exportTreadPatterns",
            addTreadPattern: referenceDataController + "addTreadPattern",
            modifyTreadPattern: referenceDataController + "modifyTreadPattern",

            searchTreadCompounds: baseApiUrl + referenceDataController + "searchTreadCompounds",
            exportTreadCompounds: referenceDataController + "exportTreadCompounds",
            addTreadCompound: referenceDataController + "addTreadCompound",
            modifyTreadCompound: referenceDataController + "modifyTreadCompound",

            searchPlyRatings: baseApiUrl + referenceDataController + "searchPlyRatings",
            exportPlyRatings: referenceDataController + "exportPlyRatings",
            addPlyRating: referenceDataController + "addPlyRating",
            modifyPlyRating: referenceDataController + "modifyPlyRating",

            searchTRACodes: baseApiUrl + referenceDataController + "searchTRACodes",
            exportTRACodes: referenceDataController + "exportTRACodes",
            addTRACode: referenceDataController + "addTRACode",
            modifyTRACode: referenceDataController + "modifyTRACode",

            getTRAServiceTypes: baseApiUrl + referenceDataController + "getTRAServiceTypes",
            getTRATreadTypes: baseApiUrl + referenceDataController + "getTRATreadTypes",

            searchVehicleApplications: baseApiUrl + referenceDataController + "searchVehicleApplications",
            exportVehicleApplications: referenceDataController + "exportVehicleApplications",
            addVehicleApplication: referenceDataController + "addVehicleApplication",
            modifyVehicleApplication: referenceDataController + "modifyVehicleApplication",

            getReferenceDataTypes: referenceDataController + "getReferenceDataTypes",

            getCompanyTypes: baseApiUrl + referenceDataController + "getCompanyTypes"
        };
    });
