//import angular from 'angular';
import DbClass, { IDbFileMetadata } from '../modules/common/local-db';
import { saveAs } from 'file-saver';
import BrowserSvc from './browserSvc';
import OcConfigSvc from './ocConfigSvc';



export default class FileManagement {

    protected readonly isMobile: boolean = this.browserSvc.isMobile;

    //old iOS detection code, this code fails to detect iOS v13, however of the browsers we care about (not IE)
    //all but Safari & reskins like iOS Chrome on iOS 12 and lower should support storing Blobs, even older desktop Safari
    //conversion to ArrayBuffer will incur overhead, so we should only do it when necessary
    //See: https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
    public readonly indexeddbSupportsBlob = !this.browserSvc.isiOS12orEarlier();

    static $inject = ['amtXlatSvc', '$http', 'WindowFactory', 'fileUrls', 'errorReporter', 'ocConfigSvc', '$db', 'amtCommandQuerySvc', 'browserSvc'];

    constructor(protected amtXlatSvc: IAmtXlatSvc, protected $http: ng.IHttpService, protected WindowFactory: IWindowFactory,
        protected fileUrls: IFileUrls, protected errorReporter, protected ocConfigSvc: OcConfigSvc, protected $db: DbClass,
        protected amtCommandQuerySvc: IAmtCommandQuerySvc, private browserSvc: BrowserSvc) {
    }

    public emptyFile(): IFile {
        return {
            id: uuid(),
            createdDate: new Date(),
            name: '',
            description: '',
            uploaded: false,
            persisted: false,
            fileSize: 0
        }
    }

    //#region Conversions

    // don't call this to display a Blob use createObjectURL for that - this is used for small inline data transfer
    blobToString(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = e => reject(e);
            reader.readAsDataURL(blob)
        });
    }

    blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
        //note: no point using .arrayBuffer() method on Blob, it's pretty much not supported on the same older versions
        //of iOS Safari that can't store Blob in IndexedDb that we need the method for
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => resolve(reader.result as ArrayBuffer);
            reader.onerror = e => reject(e);
            reader.readAsArrayBuffer(blob)
        })
    }

    fileToBlob(file): Promise<Blob> {
        return new Promise((resolve, reject) => {

            if (!(file instanceof File))
                resolve(file);

            let reader = new FileReader();
            reader.onload = () => resolve(new Blob([new Uint8Array(reader.result as ArrayBuffer)], { type: file.type }));
            reader.onerror = e => reject(e);
            reader.readAsArrayBuffer(file);
        })
    }

    blobOrArrayBufferToBlob(fileData: Blob | ArrayBuffer): Blob {
        return fileData instanceof Blob ? fileData : new Blob([fileData]); //TODO: handle mimetype        
    }

    canvasToBlob(canvas: HTMLCanvasElement, mimeType: ImageMimeType, quality: number, isRetry?: boolean): Blob {

        //TODO: Should convert this to use toBlob but support for supplying image quality doesn't look great for mobile?!
        //https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob#browser_compatibility

        mimeType = mimeType || ImageMimeType.jpeg;
        let dataUrlArr: string[];

        try {
            dataUrlArr = canvas.toDataURL(mimeType, quality).split(',', 2);

            if (!dataUrlArr[0].toLowerCase().includes(mimeType))
                throw 'Error converting to ' + mimeType;

        } catch (error) {

            console.warn(error);

            if (mimeType !== ImageMimeType.jpeg && !isRetry) {
                console.warn('Retrying as jpeg');
                return this.canvasToBlob(canvas, ImageMimeType.jpeg, quality, true);
            } else {
                throw error; // no retry, rethrow the error
            }
        }

        // Convert from base64 to an ArrayBuffer
        let byteString = atob(dataUrlArr[1]);
        let intArray = new Uint8Array(new ArrayBuffer(byteString.length));

        for (let i = 0; i < byteString.length; i++)
            intArray[i] = byteString.charCodeAt(i);

        // Use the native blob constructor
        let blob = new Blob([intArray.buffer], { type: mimeType });

        return blob;
    }

    //#endregion Conversions

    //#region Get/Download
    async downloadFile(fileId: guid, file?: IFile): Promise<void> {

        if (!file || !file.data) {

            if (!fileId)
                return;

            file = await this.getFile(fileId);
        }

        await saveAs(file.data, file.name);
    }

    async getFile(fileId: guid, fromServer?: boolean, metadataOnly?: boolean): Promise<IFile> {

        if (!fileId)
            return;

        try {
            let file: IFile = this.emptyFile();

            if (this.isMobile && !fromServer) {

                let metadata = await this.$db.fileMetadata.get(fileId);

                if (metadata) {
                    file = {
                        id: metadata.id,
                        name: metadata.name,
                        description: metadata.description,
                        createdDate: metadata.createdDate,
                        uploaded: metadata.uploaded,
                        persisted: true
                    };
                }

                if (!metadataOnly) {
                    let f = await this.$db.file.get(fileId);

                    if (f)
                        file.data = this.blobOrArrayBufferToBlob(f.data);
                }

            } else {

                let response: File = await this.amtCommandQuerySvc.getAttachment(this.fileUrls.getFile, fileId);

                if (!response)
                    return;

                file.id = fileId;
                file.name = response.name;

                if (!metadataOnly)
                    file.data = await this.fileToBlob(response);
            }

            return file;

        } catch (error) {
            this.errorReporter.logError(error);
        }
    }

    async getThumbnail(fileId?: guid, file?: IFile) {

        if (!fileId && !file)
            return;

        try {
            let thumbnail: IFile = this.emptyFile();
            let thumb: Blob;

            if (this.isMobile) {

                // fileId provided, try to get thumb and metadata from local db
                if (fileId) {

                    let metadata = await this.$db.fileMetadata.get(fileId);

                    if (metadata) {
                        thumbnail = {
                            id: metadata.id,
                            name: metadata.name,
                            description: metadata.description,
                            createdDate: metadata.createdDate,
                            uploaded: metadata.uploaded,
                            persisted: true
                        };
                    }

                    let storedThumb = await this.$db.thumb.get(fileId);

                    if (storedThumb) {
                        thumb = this.blobOrArrayBufferToBlob(storedThumb.data);
                    } else if (!file || !file.data) { // couldn't find the thumb in the local database and no full-size file provided

                        // try to get it from the file table in the local database                        
                        file = this.emptyFile();

                        let dbFile = await this.$db.file.get(fileId);

                        if (dbFile) {
                            file.data = this.blobOrArrayBufferToBlob(dbFile.data);
                            file.name = thumbnail.name;
                        }
                    }
                }

                // no fileId or couldn't find the thumb in the local database
                if (!thumb && file && file.data) {

                    // generate the thumbnail from the provided file or the one from the local database                          
                    thumb = await this.generateThumbnail(file);

                    // save the thumb to the database if we can
                    if (thumb && fileId)
                        await this.$db.thumb.put({ id: fileId, data: thumb });
                }

            } else {

                // on desktop, retrieve the thumb from the server if there is a fileId
                if (fileId)
                    thumb = await this.fileToBlob(await this.amtCommandQuerySvc.getAttachment(this.fileUrls.getThumbnail, fileId));

                // if we couldn't retrieve a thumb from the server but a full-size file has been provided, use it to generate the thumbnail
                if (!thumb && file && file.data)
                    thumb = await this.generateThumbnail(file);
            }

            if (!thumb)
                return;

            thumbnail.thumb = thumb;

            return thumbnail;

        } catch (error) {
            this.errorReporter.logError(error);
        }
    }
    //#endregion Get/Download

    //#region Save/Upload

    // upload files from the mobile database to the server
    async uploadFile(fileId: guid, andDelete: boolean): Promise<any> {

        let metadata = await this.$db.fileMetadata.get(fileId);

        if (!metadata)
            return;

        let response: any = null;

        if (!metadata.uploaded) {

            let file = await this.$db.file.get(fileId);

            if (!file)
                return;

            let fileData: Blob = this.blobOrArrayBufferToBlob(file.data);

            response = (await this.uploadFileToServer(fileData, metadata.name)).data;

            metadata.uploaded = true;
            await this.$db.fileMetadata.put(metadata);

            if (!andDelete && fileId !== response.id) {
                // the id will now have changed for the file
                // this is the key, so create new records and remove the old ones
                await this.$db.file.where('id').equals(fileId).modify({ id: response.id });
                await this.$db.file.delete(fileId);

                await this.$db.fileMetadata.where('id').equals(fileId).modify({ id: response.id });
                await this.$db.fileMetadata.delete(fileId);

                await this.$db.thumb.where('id').equals(fileId).modify({ id: response.id });
                await this.$db.thumb.delete(fileId);
            }
        }

        if (andDelete)
            await this.deleteFiles([fileId]);

        return response;
    }

    // upload a file directly to the server
    async uploadFileToServer(file: Blob, fileName: string): Promise<any> {

        let fd = new FormData();
        fd.append('fileData', file, fileName);

        //See: https://withintent.uncorkedstudios.com/multipart-form-data-file-upload-with-angularjs-c23bf6eeb298 for why?
        let config = {
            transformRequest: angular.identity,
            headers: { 'Content-Type': undefined }
        }

        let data = await this.$http.post(this.fileUrls.uploadFile, fd, config);

        if (data.status !== 200)
            throw data;

        return data;
    }

    // save file to mobile database
    async saveFileLocal(file: IFile, compressImages: boolean, dataOnly?: boolean): Promise<guid> {

        if (!this.isMobile || !file)
            return null;

        let fileId: guid = file.id || uuid();

        let isImage = this.isImage(file.type || this.getExtensionType(file.name));
        let filesize = file.fileSize;
        let filename = file.name;

        if (file.data) {

            let data: Blob;

            if (isImage && compressImages) { // resize
                let resizedFile = await this.compressImage(file);
                data = resizedFile.data;
                filename = resizedFile.name;
                filesize = data.size || filesize;
            } else {
                data = file.data;
            }

            if (this.indexeddbSupportsBlob) {
                await this.$db.file.put({ id: fileId, data: data });
            } else {
                let arrbuff = await this.blobToArrayBuffer(data);
                await this.$db.file.put({ id: fileId, data: arrbuff });
            }
        }

        if (!dataOnly) {
            let fileMetadata: IDbFileMetadata = {
                id: fileId,
                name: filename,
                description: file.description,
                createdDate: file.createdDate,
                uploaded: false, //file has yet to be uploaded to server      
                fileSize: filesize,
                type: file.type
            };

            await this.$db.fileMetadata.put(fileMetadata);
        }

        // generate and store thumbnail if this is an image
        if (isImage && (file.thumb || file.data)) {

            try {
                let thumb = file.thumb || await this.generateThumbnail(file);

                if (thumb) {
                    if (this.indexeddbSupportsBlob) {
                        await this.$db.thumb.put({ id: fileId, data: thumb });
                    } else {
                        let arrbuff = await this.blobToArrayBuffer(thumb);
                        await this.$db.thumb.put({ id: fileId, data: arrbuff });
                    }
                }
            } catch {
                console.warn('failed to store thumbnail');
            }
        }

        return fileId;
    }

    // takes the files and either stores locally in the mobile DB or sends to the server.
    async processFileUploads(files: IFile[], directToServer?: boolean): Promise<void> {
        try {

            if (!files || files.length === 0)
                return;

            for (let file of files) {
                // skip upload if no file or already uploaded to server
                if (!file || file.uploaded)
                    continue;

                if (this.isMobile && !directToServer) {

                    if (!file.data && !file.persisted)
                        continue;

                    file.id = await this.saveFileLocal(file, true);
                } else {

                    if (!file.data)
                        continue;

                    let result = (await this.uploadFileToServer(file.data, file.name)).data;

                    file.id = result.id;
                    file.uploaded = true;
                }

                file.persisted = true;
            }
        } catch (error) {
            this.errorReporter.logError(error);
            throw error;
        }
    }

    async saveDescription(id: guid, description: string) {
        if (this.isMobile) {
            await this.$db.fileMetadata.update(id, { description: description });
        } else {
            await this.amtCommandQuerySvc.post(this.fileUrls.updateDescription, { id: id, description: description });
        }
    }

    //#endregion Save/Upload

    //#region Delete

    // delete selected files on mobile or desktop
    async deleteFiles(fileIds: guid[], deleteSources?: boolean): Promise<void> {

        if (!fileIds || !fileIds.length)
            return;

        if (this.isMobile) {
            await this.$db.file.where('id').anyOf(fileIds).delete();
            await this.$db.fileMetadata.where('id').anyOf(fileIds).delete();
            await this.$db.thumb.where('id').anyOf(fileIds).delete();
        } else {
            for (let id of fileIds) {
                await this.amtCommandQuerySvc.post(this.fileUrls.deleteFile, { id: id, deleteSources: deleteSources });
            }
        }
    }

    clearData(file: IFile): IFile {
        file.data = null;
        file.dataUrl = null;
        file.thumb = null;
        file.thumbUrl = null;

        return file;
    }

    //#endregion Delete

    //#region Generate
    async resize(file: File, quality: number, mimeType: ImageMimeType, maxArea: number, maxWidth?: number, maxHeight?: number): Promise<File> {

        let dataUrl = null;

        try {

            let dataUrl = URL.createObjectURL(file);
            let img = new Image();

            await new Promise<void>((resolve, reject) => {
                img.onload = () => { resolve() };
                img.onerror = (error) => reject(error);
                img.src = dataUrl;
            });

            let dpr = window.devicePixelRatio;
            let width = img.naturalWidth * dpr | 0;
            let height = img.naturalHeight * dpr | 0;

            let imageAspectRatio = width / height;

            if (maxArea > 0) { // determine width & height based on target total area (pixels)

                let imageArea = width * height;

                if (imageArea > maxArea) { // scale, otherwise only change quality
                    height = Math.round(Math.sqrt(maxArea / imageAspectRatio));
                    width = Math.round(height * imageAspectRatio);
                }

            } else { // scale to target width/height, maintaining aspect ratio

                if (!maxWidth || !maxHeight) {
                    console.warn('width and height must both be supplied for resolution scaling');
                    throw file;
                }

                let maxSizeAspectRatio = maxWidth / maxHeight;

                if (imageAspectRatio >= maxSizeAspectRatio) {
                    if (width > maxWidth) {
                        width = maxWidth;
                        height = Math.round(maxWidth / imageAspectRatio);
                    }
                }
                else {
                    if (height > maxHeight) {
                        width = Math.round(maxHeight * imageAspectRatio);
                        height = maxHeight;
                    }
                }
            }

            let canvas = document.createElement('canvas');

            canvas.width = width;
            canvas.height = height;

            let ctx = canvas.getContext('2d');

            ctx.drawImage(img, 0, 0, width, height);

            let blob: Blob = this.canvasToBlob(canvas, mimeType, quality);

            // change the extension on the file name
            let fname = file.name;
            let currentExtension = this.getExtension(fname);
            let mimeTypeExtension = this.getImageMimeTypeExtension(mimeType);

            if (currentExtension && mimeTypeExtension)
                fname = fname.replace(currentExtension, mimeTypeExtension);

            let resizedFile: File = new File([blob], fname);

            return resizedFile;
        }
        catch (error) {
            console.warn(error);
            console.warn('not an image or resize failed');
            throw file;
        } finally {
            if (dataUrl)
                URL.revokeObjectURL(dataUrl);
        }
    }

    async generateThumbnail(file: IFile): Promise<Blob> {

        try {
            return this.fileToBlob(
                await this.resize(new File([file.data], file.name),
                    this.ocConfigSvc.user.imageSettings.thumbnail.thumbnailQuality,
                    ImageMimeType.webp,
                    null,
                    this.ocConfigSvc.user.imageSettings.thumbnail.thumbnailSize,
                    this.ocConfigSvc.user.imageSettings.thumbnail.thumbnailSize,
                )
            );
        } catch (error) {
            console.warn(error);
            console.warn('failed to generate thumbnail');
        }
    }

    async compressImage(file: IFile): Promise<{ data: Blob, name: string }> {

        return new Promise<{ data: Blob, name: string }>(async resolve => {

            let result = { data: file.data, name: file.name };

            // if file is already below our max allowed size then don't compress it
            if (file.fileSize && (file.fileSize / 1024) < this.ocConfigSvc.user.imageSettings.imageCompression.maxUncompressedImageSize)
                return resolve(result);

            try {
                let resizedFile = await this.resize(new File([file.data], file.name),
                    this.ocConfigSvc.user.imageSettings.imageCompression.imageQuality,
                    ImageMimeType.jpeg,
                    this.ocConfigSvc.user.imageSettings.imageCompression.imageArea
                );

                // if resized file is larger than the original, keep the original
                if (resizedFile.size < file.fileSize) {
                    result.data = await this.fileToBlob(resizedFile);
                    result.name = resizedFile.name;
                }
            } catch (error) {
                console.warn(error);
                console.warn('compression of image failed');
            }

            return resolve(result);
        });
    }

    //#endregion Generate

    //#region Extensions & Types

    isImage(fileType: string): boolean {
        if (!fileType)
            return false;

        return fileType.startsWith(FileType.image);
    }

    getAcceptedFileExtensions(types?: FileType[]): string[] {

        let extensions: string[] = [];

        if (!types || !types.length)
            types = [FileType.document, FileType.text, FileType.pdf, FileType.spreadsheet, FileType.image, FileType.other];

        for (let type of types) {

            switch (type) {
                case FileType.document:
                    extensions.push(FileTypeExtension.doc);
                    extensions.push(FileTypeExtension.docx);
                    extensions.push(FileTypeExtension.docm);
                    break;

                case FileType.text:
                    extensions.push(FileTypeExtension.text);
                    extensions.push(FileTypeExtension.txt);
                    extensions.push(FileTypeExtension.log);
                    extensions.push(FileTypeExtension.msg);
                    break;

                case FileType.pdf:
                    extensions.push(FileTypeExtension.pdf);
                    break;

                case FileType.spreadsheet:
                    extensions.push(FileTypeExtension.xls);
                    extensions.push(FileTypeExtension.xlsx);
                    extensions.push(FileTypeExtension.csv);
                    extensions.push(FileTypeExtension.xlm);
                    extensions.push(FileTypeExtension.xlsm);
                    break;

                case FileType.image:
                    // must be image/* rather than list of image extensions for camera to be activated on mobile devices
                    extensions.push('image/*');
                    break;

                case FileType.other:
                    // tif, tiff and svg seem to have various issues with canvas, skia, and the img element
                    // so treating them as 'other' files for now
                    extensions.push(FileTypeExtension.tif);
                    extensions.push(FileTypeExtension.tiff);
                    extensions.push(FileTypeExtension.svg);
                    // treating .gif as other to avoid loss of animation through jpeg-compression
                    extensions.push(FileTypeExtension.gif);
                    extensions.push(FileTypeExtension.ppt);
                    extensions.push(FileTypeExtension.pptx);
                    break;
            }
        }

        return extensions;
    }

    getExtension(filename: string): string {

        if (!filename)
            return null;

        if (filename.indexOf('.') > -1)
            filename = filename.substr(filename.lastIndexOf('.'));

        return filename;
    }

    getExtensionType(filename: string): FileType {

        if (!filename)
            return null;

        let extension = this.getExtension(filename);

        switch (extension.toLowerCase()) {
            case FileTypeExtension.doc:
            case FileTypeExtension.docx:
            case FileTypeExtension.docm:
                return FileType.document;

            case FileTypeExtension.xls:
            case FileTypeExtension.xlsx:
            case FileTypeExtension.xlm:
            case FileTypeExtension.xlsm:
                return FileType.spreadsheet;

            case FileTypeExtension.pdf:
                return FileType.pdf;

            case FileTypeExtension.log:
            case FileTypeExtension.text:
            case FileTypeExtension.txt:
            case FileTypeExtension.msg:
                return FileType.text;

            case FileTypeExtension.jpg:
            case FileTypeExtension.jpeg:
            case FileTypeExtension.bmp:
            case FileTypeExtension.png:
            case FileTypeExtension.webp:
            case FileTypeExtension.pjp:
            case FileTypeExtension.pjpeg:
            case FileTypeExtension.jfif:
            case FileTypeExtension.ico:
                return FileType.image;

            case FileTypeExtension.tif:
            case FileTypeExtension.tiff:
            case FileTypeExtension.svg:
            case FileTypeExtension.gif:
            case FileTypeExtension.ppt:
            case FileTypeExtension.pptx:
            default:
                return FileType.other;
        }
    }

    getImageMimeTypeExtension(mimeType: ImageMimeType): FileTypeExtension {

        switch (mimeType) {
            case ImageMimeType.jpeg:
                return FileTypeExtension.jpeg;

            case ImageMimeType.webp:
                return FileTypeExtension.webp;

            default:
                return null;
        }
    }

    //#endregion Extensions & Types
}

angular.module('app').service('fileManagement', FileManagement);