//import angular from 'angular';
import * as _ from 'underscore';
import BrowserSvc from '../../../services/browserSvc';
import OcDateSvc from '../../../services/ocDateSvc';
import tmpl from "./componentReceivePopup.html";


    angular.module("app.equipment").component("componentReceivePopup", {
        bindings: {
            initParams: "=",
            buttonMethods: "=",
            buttonStates: "=",
            buttons: "=",
            closeOnSave: "=",
            wnd: "="
        },
        template: tmpl,
        controller: [
            "$scope", "confirmSvc", "WindowFactory", "dataBroker", "helperSvc", "amtXlatSvc",
            "errorReporter", "ocConfigSvc", "$timeout", "enums", "$q", "amtConstants", "ocDateSvc",
            "ocSecuritySvc", 'fileManagement', 'browserSvc',
            function (
                $scope, confirmSvc, WindowFactory, dataBroker, helperSvc, xlatSvc,
                errorReporter, ocConfigSvc, $timeout, enums, $q, amtConstants, ocDateSvc: OcDateSvc,
                ocSecuritySvc, fileManagement, browserSvc: BrowserSvc
            ) {

                var vm = this;
                $scope.vm = this;

                vm.constants = amtConstants;
                vm.config = ocConfigSvc;
                vm.isMobile = browserSvc.isMobile;

                vm.mode = "new"; // default mode

                vm.disableSaveAndNext = true;
                vm.saveAndNext_label = "";

                vm.pending = false;
                vm.pendingFitted = false;
                vm.hasInitCost = false;

                vm.statuses = [];

                vm.error = false;
                vm.errorMessage = "";

                vm.manufacturerSerialNumber = "";
                vm.selectedStatus = {};
                vm.dataFieldsReceived = "";
                vm.conditionType = {};
                vm.excludeFromPerformanceAnalysis = false;

                vm.canSetExcludeFromPerformanceAnalysis = ocSecuritySvc.isAuthorised('Security.Components.Receive.ToggleExcludeFromPerformanceAnalysis', AccessTypes.readWrite);
                vm.canViewComponentFailureReport = ocSecuritySvc.isAuthorised('Security.Reports.ComponentFailure', AccessTypes.readOnly);

                vm.acceptedFileTypes = fileManagement.getAcceptedFileExtensions([FileType.document, FileType.pdf, FileType.text, FileType.spreadsheet, FileType.image]);
                
                vm.rtd = null;
                vm.rcd = null;
                vm.originalDepth = null;
                vm.attachments = [];

                this.$onInit = function () {

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: "common.save_label",
                            name: "saveButton",
                            click: "save",
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: false,
                            value: vm.initParams.source === undefined ?
                                "component.saveAndAddAnother_label" :
                                "component.saveAndNext_label",
                            name: "saveAndNextButton",
                            click: "saveAndNext",
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: true,
                            value: "common.cancel_label",
                            name: "cancelButton",
                            click: "cancel",
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: true,
                            disabled: true
                        },
                        saveAndNextButton: {
                            visible: true,
                            disabled: true
                        },
                        cancelButton: {
                            visible: true,
                            disabled: false
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.save,
                        saveAndNext: vm.saveAndNext,
                        cancel: vm.onCancel
                    };

                    // Mode can be "new" or "edit" for now.
                    vm.mode = vm.initParams.mode;

                    vm.pending = vm.initParams.pending;
                    vm.pendingFitted = vm.initParams.pendingFitted;

                    vm.conditionType = vm.initParams.conditionType;
                    vm.showSiteSerialNumber =
                        vm.config.user.site.settings.tyreMaintenance.siteSerialNumber[vm.initParams.componentType.name.toLowerCase()];

                    vm.ndtStatuses = [{ key: 1, name: xlatSvc.xlat("common.pass") }, { key: 2, name: xlatSvc.xlat("common.fail") }];

                    if (vm.mode === "edit") {

                        var source = vm.initParams.source;

                        vm.manufacturerSerialNumber = angular.copy(source.serialNumber.manufacturer);
                        vm.siteSerialNumber = angular.copy(source.serialNumber.site);
                        vm.originalDepth = angular.copy(source.originalDepth);

                        if (vm.pending || (!vm.conditionType.isKnown && vm.conditionType.id !== 'New')) {

                            // Pending Fitted Component || 2nd Hand || Opening Balance || Retread edit stuff goes in here
                            vm.specifications = vm.initParams.specifications;
                            vm.dataFieldsReceived = source.dataFields;
                            vm.selectedComponentType = helperSvc.getKey(source.equipmentType);
                            vm.currencySymbol = vm.initParams.currencySymbol;
                            vm.currencyDecimalPlaces = vm.initParams.currencyDecimalPlaces;
                            vm.fittedToVehicleSerial = source.fittedToVehicleSerial;
                            vm.fittedWheelPosition = source.fittedToPosition;
                            vm.excludeFromPerformanceAnalysis = source.excludeFromPerformanceAnalysis;
                            vm.generateComponentFailureReport = source.generateComponentFailureReport;

                            vm.unitCost = (source.cost ? angular.copy(+source.cost) : 0);

                            if (!!vm.unitCost) {
                                vm.hasInitCost = true;
                            }

                            vm.selectedSpec = (source.specification && source.specification.id ? angular.copy(source.specification) : null);

                            vm.rtd = (source.rtd ? {
                                one: angular.copy(source.rtd.one),
                                two: angular.copy(source.rtd.two)
                            } : null);

                            vm.rcd = (source.rcd ? {
                                one: angular.copy(source.rcd.one),
                                two: angular.copy(source.rcd.two)
                            } : null);

                            vm.hours = angular.copy(source.hours);
                            vm.distance = angular.copy(source.distance);
                            vm.hoursSince = angular.copy(source.hoursSinceNDT);

                        } else if (vm.conditionType.isKnown === true) {

                            vm.selectedComponentType = helperSvc.getKey(source.equipmentType);
                            vm.currencySymbol = vm.initParams.currencySymbol;
                            vm.currencyDecimalPlaces = vm.initParams.currencyDecimalPlaces;

                            vm.unitCost = (source.cost ? angular.copy(+source.cost) : null);

                            if (!!vm.unitCost) {
                                vm.hasInitCost = true;
                            }

                            vm.selectedSpec = (source.specification && source.specification.id ? source.specification : null);
                            vm.generateComponentFailureReport = source.generateComponentFailureReport;
                            vm.rtd = angular.copy(source.rtd);
                            vm.rcd = angular.copy(source.rcd);
                            vm.hours = source.hours && source.hours > 0 ? angular.copy(source.hours) : null;
                            vm.distance = source.distance && source.distance > 0 ? angular.copy(source.distance) : null;
                            vm.hoursSince = source.hoursSinceNDT && source.hoursSinceNDT > 0 ? angular.copy(source.hoursSinceNDT) : null;
                        } else {
                            vm.unitCost = source.cost || 0;
                        }

                        // Common edit stuff goes in here.
                        vm.selectedComponentOwnerId = angular.copy(source.componentOwner);
                        vm.selectedRetreaderId = angular.copy(source.retreader);
                        vm.selectedRepairerId = angular.copy(source.repairer);
                        vm.ndtStatus = source.ndtStatus ? _.find(vm.ndtStatuses, ndt => { return ndt.key === source.ndtStatus.key; }) : null;
                        vm.attachments = angular.copy(source.attachments) || [];

                        vm.showSiteSerialNumber =
                            vm.config.user.site.settings.tyreMaintenance.siteSerialNumber[source.equipmentType.name.toLowerCase()];

                        vm.combinedDateTime = (source.date ? angular.copy(source.date) : vm.initParams.date);

                        vm.disableSaveAndNext = vm.initParams.disableSaveAndNext;
                    } else {
                        vm.combinedDateTime = vm.initParams.date;
                        vm.unitCost = vm.initParams.unitCost;
                    }

                    vm.selectedSpec = vm.selectedSpec || vm.initParams.spec;
                    vm.selectedComponentOwnerId = vm.selectedComponentOwnerId || vm.initParams.componentOwnerId;

                    vm.wnd.onClose = vm.onCancel;

                    var promise = $q.resolve();

                    if (vm.initParams.requiredFields.statusCombo || vm.initParams.requiredFields.statusLabel) {
                        var unknownComponentCriteria = { conditionType: vm.conditionType.id };

                        promise = promise.then(function () {
                            return dataBroker.getUnknownComponentReceiveDestinationStatusTypes(unknownComponentCriteria).then(function (response) {
                                vm.statuses = response;
                                vm.selectedStatus = _.find(vm.statuses,
                                    function (item) {
                                        return vm.initParams.source && vm.initParams.source.status && vm.initParams.source.status.id ? vm.initParams.source.status.id === item.id : item.default;
                                    });
                            }).catch(function (error) {
                                errorReporter.logError(error, "failed to get status types"); // TODO: translate
                            });
                        });
                    }

                    if (vm.initParams.requiredFields.componentOwner) {
                        promise = promise.then(function () {
                            return dataBroker.getComponentOwners(vm.config.user.client.id).then(function (response) {
                                vm.componentOwners = response.result;
                            }).catch(function (error) {
                                errorReporter.logError(error, "component.componentOwnerRetrieveFail");
                            });
                        });
                    }

                    if (vm.initParams.requiredFields.retreader) {
                        promise = promise.then(function () {

                            let getRetreaderCriteria: IRetreaderCriteria = {
                                siteId: vm.config.user.site.id,
                                includeOther: false,
                                equipmentTypeIds: [helperSvc.getKey(vm.initParams.componentType)]
                            };

                            return dataBroker.getRetreaders(getRetreaderCriteria).then(function (response) {
                                vm.retreaders = response.result;
                            }).catch(function (error) {
                                errorReporter.logError(error, "component.retreaderRetrieveFail");
                            });
                        });
                    }

                    if (vm.initParams.requiredFields.repairer) {
                        promise = promise.then(function () {

                            let getRepairerCriteria: IRepairerCriteria = {
                                siteId: vm.config.user.site.id,
                                includeOther: false,
                                equipmentTypeIds: [helperSvc.getKey(vm.initParams.componentType)]
                            };

                            return dataBroker.getRepairers(getRepairerCriteria).then(function (response) {
                                vm.repairers = response.result;
                            }).catch(function (error) {
                                errorReporter.logError(error, "component.repairerRetrieveFail");
                            });
                        });
                    }

                    let depthUnit = vm.config.user.site.units.depth.unitAbbreviation;

                    vm.distanceLabel = xlatSvc.xlat("component.distance_label", vm.config.user.site.units.distance.unitAbbreviation);
                    vm.costLabel = xlatSvc.xlat(vm.conditionType.costLabel + "_label", vm.config.user.site.currency.symbol);
                    vm.rtd1Label = xlatSvc.xlat("component.rtd1_label", depthUnit);
                    vm.rtd2Label = xlatSvc.xlat("component.rtd2_label", depthUnit);
                    vm.hoursLabel = xlatSvc.xlat("component.hours_label");
                    vm.rcd1Label = xlatSvc.xlat("component.rcd1_label", depthUnit);
                    vm.rcd2Label = xlatSvc.xlat("component.rcd2_label", depthUnit);

                    // call the promise
                    return promise.then(function () {
                        $timeout(function () {
                            if (vm.form) {
                                vm.form.$setPristine();
                            }
                        });
                    });
                };

                vm.onStatusChanged = function () {
                    if (vm.selectedStatus?.name.toLowerCase() !== StatusTypeName.scrapped)
                        vm.generateComponentFailureReport = false;
                };

                // local functions
                vm.save = function () {
                    onSave(false);
                };

                vm.saveAndNext = function () {
                    onSave(true);
                };

                function onSave(keepOpen) {

                    vm.wnd.processing = true;

                    return vm.validate().then(function () {

                        if (!vm.error) {
                            if (vm.initParams.source === undefined) {
                                vm.initParams.addAnother = keepOpen;
                            } else {
                                vm.initParams.next = keepOpen;
                            }
                            closeWindow("save");
                        }
                    }).finally(function () {
                        vm.wnd.processing = false;
                    });
                }

                vm.validate = function () {

                    vm.error = false;
                    vm.errorMessage = "";

                    if (new Date(vm.combinedDateTime) > new Date()) {
                        vm.error = true;
                        vm.errorMessage = xlatSvc.xlat("equipment.eventDateCannotOccurInFuture");
                        return $q.resolve(vm.errorMessage);
                    }

                    // validate depths
                    if (vm.rtd || vm.rcd) {

                        let avgDepth = (vm.rtd ? (vm.rtd.one + vm.rtd.two) / 2.0 : (vm.rcd.one + vm.rcd.two) / 2.0);

                        if (avgDepth <= 0) {
                            vm.error = true;
                            vm.errorMessage = xlatSvc.xlat("receive.validation_averageDepthZeroOrBelow");
                            return $q.resolve(vm.errorMessage);
                        }

                        if (vm.conditionType.isKnown && vm.conditionType.parentConditionId !== 'Retreaded') {

                            // check if depth reading is greater than the previous reading
                            if (vm.originalDepth) {

                                let originalAvgDepth = (vm.originalDepth.one + vm.originalDepth.two) / 2.0;

                                if (avgDepth > originalAvgDepth) {
                                    vm.error = true;
                                    vm.errorMessage = xlatSvc.xlat("receive.validation_averageDepthIncreased", avgDepth, vm.config.user.site.units.depth.unitAbbreviation, originalAvgDepth);
                                    return $q.resolve(vm.errorMessage);
                                }
                            }

                        } else {

                            // for unknown components or known retreads, compare depth to specification original depth
                            if (vm.initParams.specDetails && vm.initParams.specDetails.originalDepth) {

                                let depth = vm.rtd ? vm.rtd : vm.rcd;

                                if (depth.one > vm.initParams.specDetails.originalDepth) {

                                    if (vm.conditionType.parentConditionId === 'Retreaded') {

                                        return WindowFactory.alert(
                                            "receive.validation_averageDepthExceedsOTD_retread_title",
                                            ['common.cancel', 'common.allow'],
                                            "receive.validation_depthExceedsOTD_retread_message",
                                            [depth.one, vm.config.user.site.units.depth.unitAbbreviation, vm.selectedSpec.description, vm.initParams.specDetails.originalDepth],
                                            800).then(function (button) {
                                                if (button !== 'common.allow') {
                                                    vm.error = true;
                                                    vm.errorMessage = xlatSvc.xlat("receive.validation_depthExceedsOTD_retread_message",
                                                        [depth.one, vm.config.user.site.units.depth.unitAbbreviation, vm.selectedSpec.description, vm.initParams.specDetails.originalDepth]);
                                                    return $q.resolve(vm.errorMessage);
                                                }
                                            });

                                    } else {
                                        vm.error = true;
                                        vm.errorMessage = xlatSvc.xlat("receive.validation_depthExceedsOTD", depth.one, vm.config.user.site.units.depth.unitAbbreviation, vm.selectedSpec.description, vm.initParams.specDetails.originalDepth);
                                        return $q.resolve(vm.errorMessage);
                                    }
                                }

                                if (depth.two > vm.initParams.specDetails.originalDepth) {

                                    if (vm.conditionType.parentConditionId === 'Retreaded') {

                                        return WindowFactory.alert(
                                            "receive.validation_averageDepthExceedsOTD_retread_title",
                                            ['common.cancel', 'common.allow'],
                                            "receive.validation_depthExceedsOTD_retread_message",
                                            [depth.two, vm.config.user.site.units.depth.unitAbbreviation, vm.selectedSpec.description, vm.initParams.specDetails.originalDepth],
                                            800).then(function (button) {
                                                if (button !== 'common.allow') {
                                                    vm.error = true;
                                                    vm.errorMessage = xlatSvc.xlat("receive.validation_depthExceedsOTD_retread_message",
                                                        [depth.two, vm.config.user.site.units.depth.unitAbbreviation, vm.selectedSpec.description, vm.initParams.specDetails.originalDepth]);
                                                    return $q.resolve(vm.errorMessage);
                                                }
                                            });

                                    } else {
                                        vm.error = true;
                                        vm.errorMessage = xlatSvc.xlat("receive.validation_depthExceedsOTD", depth.two, vm.config.user.site.units.depth.unitAbbreviation, vm.selectedSpec.description, vm.initParams.specDetails.originalDepth);
                                        return $q.resolve(vm.errorMessage);
                                    }
                                }
                            }
                        }
                    }

                    // check hours since ndt is not greater than total hours
                    if (vm.hours && vm.hoursSince && (vm.hoursSince > vm.hours)) {
                        vm.error = true;
                        vm.errorMessage = xlatSvc.xlat("exception.reading_hoursSinceNdtCannotExceedTotalHours");
                        return $q.resolve(vm.errorMessage);
                    }

                    // check format of serial (as necessary), only when possible to modify manufacturer serial number
                    if (vm.initParams.specDetails &&
                        (vm.initParams.specDetails.manufacturerSerialValidations && vm.initParams.specDetails.manufacturerSerialValidations.length > 0) &&
                        vm.initParams.requiredFields.manSerialNo) {

                        let valid = false;
                        let examples;

                        for (var i = 0; i < vm.initParams.specDetails.manufacturerSerialValidations.length; i++) {

                            examples = i == 0 ?
                                vm.initParams.specDetails.manufacturerSerialValidations[i].exampleSerial :
                                examples + ', ' + vm.initParams.specDetails.manufacturerSerialValidations[i].exampleSerial;

                            if (vm.manufacturerSerialNumber.match(vm.initParams.specDetails.manufacturerSerialValidations[i].validator)) {
                                valid = true;
                            }
                        }

                        if (!valid) {
                            vm.error = true;
                            vm.errorMessage = xlatSvc.xlat("receive.invalidSerialNumber", examples);
                            return $q.resolve(vm.errorMessage);
                        }
                    }

                    var errorResource;
                    var unique = true;

                    // check if we have used these serial numbers already during the current receive
                    if (vm.manufacturerSerialNumber) {
                        var localManufacturerMatch = _.find(vm.initParams.existingSerials, function (item) {
                            return item.serialNumber && item.serialNumber.manufacturer.toUpperCase() === vm.manufacturerSerialNumber.toUpperCase();
                        });
                    }

                    if (vm.siteSerialNumber) {
                        var localSiteMatch = _.find(vm.initParams.existingSerials, function (item) {
                            return item.serialNumber && item.serialNumber.site && item.serialNumber.site.toUpperCase() === vm.siteSerialNumber.toUpperCase();
                        });
                    }

                    if (localManufacturerMatch) {
                        errorResource = "errorManSerialExists";
                        unique = false;
                    } else if (localSiteMatch) {
                        errorResource = "errorSiteSerialExists";
                        unique = false;
                    }

                    if (!unique) {
                        vm.error = true;
                        vm.errorMessage = xlatSvc.xlat("equipment." + errorResource);
                        return $q.resolve(vm.errorMessage);
                    }

                    // if unknown component
                    //if (!vm.conditionType.isKnown) {

                    // check the site/client for an existing serial number
                    return dataBroker.checkUniqueSerialNumber(
                        vm.manufacturerSerialNumber,
                        vm.siteSerialNumber,
                        vm.initParams.componentType.name.toLowerCase(),
                        (vm.initParams.source ? vm.initParams.source.id : null)
                    ).then(function (response) {

                        if (response.manufacturerSerialNumber === true) {
                            errorResource = "errorManSerialExists";
                            unique = false;
                        } else if (response.siteSerialNumber === true) {
                            errorResource = "errorSiteSerialExists";
                            unique = false;
                        }

                        if (!unique) {
                            vm.error = true;
                            vm.errorMessage = xlatSvc.xlat("equipment." + errorResource);
                        }

                    }).finally(function () {
                        $timeout();
                    });
                    //}

                    //return $q.resolve();
                };

                vm.onCancel = function () {
                    confirmSvc.confirmSaveChange(vm.form && vm.form.$dirty).then(function () {
                        vm.form.$dirty = false;
                        closeWindow("cancel");
                        return true;
                    });

                    return false;
                };
                // end local functions

                // scope functions
                $scope.setForm = function () {
                    $scope.$watch("vm.form.$dirty", function () {
                        // TODO: I think one of the AMT controls is a bit broken and making this dirty on initialise...
                        vm.wnd.isDirty = vm.form.$dirty;
                    });
                };
                // end scope functions

                // watches
                $scope.$watch("vm.selectedSpec", function () {
                    if (vm.selectedSpec && (vm.initParams.requiredFields.specification || vm.conditionType.isKnown)) {

                        vm.wnd.processing = true;

                        var receiveDate = ocDateSvc.removeLocalTimeZoneOffset(vm.combinedDateTime);

                        dataBroker.getSpecificationDetails(vm.selectedSpec.id, receiveDate).then(function (result) {

                            if (!vm.hasInitCost && vm.conditionType.defaultCost === true) {

                                if (vm.initParams.source && vm.initParams.source.repairCost) {
                                    vm.unitCost = vm.initParams.source.repairCost + (result.defaultCost ? result.defaultCost : 0);
                                } else {
                                    vm.unitCost = result.defaultCost;
                                }

                                if (!vm.unitCost) {
                                    vm.unitCost = null;
                                }
                            }

                            vm.initParams.specDetails = {
                                manufacturerSerialValidations: result.serialNumberValidations,
                                originalDepth: result.originalDepth
                            };

                        }).finally(function () {
                            vm.wnd.processing = false;
                        });
                    }
                }, true);

                $scope.$watchGroup(['vm.form.$invalid', 'vm.wnd.processing'], function () {
                    vm.buttonStates.saveButton.disabled = !vm.form || vm.form.$invalid || vm.wnd.processing;

                    // Ensure that saveAndNextButton.disabled remains true if initParams.disableSaveAndNext is true, otherwise update normally.
                    if (!vm.initParams.disableSaveAndNext) {
                        vm.buttonStates.saveAndNextButton.disabled = !vm.form || vm.form.$invalid || vm.wnd.processing;
                    }
                });
                // end watches

                // other stuff
                function closeWindow(closeReason) {
                    if (closeReason === "save") {
                        if (vm.mode === "new") {
                            vm.initParams.newSerial = {
                                id: vm.initParams.lastId + 1,
                                serialNumber: {
                                    manufacturer: vm.manufacturerSerialNumber,
                                    site: vm.siteSerialNumber
                                },
                                status: vm.selectedStatus,
                                componentOwner: vm.selectedComponentOwnerId,
                                rtd: vm.rtd,
                                hours: vm.hours,
                                excludeFromPerformanceAnalysis: vm.excludeFromPerformanceAnalysis,
                                distance: vm.distance,
                                unitCost: vm.unitCost,
                                retreader: (vm.selectedRetreader ? vm.selectedRetreader.id : null),
                                repairer: (vm.selectedRepairer ? vm.selectedRepairer.id : null),
                                hoursSince: vm.hoursSince,
                                ndtStatus: vm.ndtStatus,
                                rcd: vm.rcd,
                                attachments: vm.attachments
                            };
                        } else { // edit mode
                            vm.initParams.editedSerial = {
                                id: vm.initParams.source.id,
                                serialNumber: {
                                    manufacturer: vm.manufacturerSerialNumber,
                                    site: vm.siteSerialNumber
                                },
                                date: vm.combinedDateTime,
                                // TODO: This data is stored in Integration.ReceiveStatus table. Not translated right now.
                                status: (vm.pending ?
                                    { name: "Ready to Receive", description: xlatSvc.xlat("component.readyToReceive") }
                                    : vm.selectedStatus),
                                specification: vm.selectedSpec,
                                unitCost: vm.unitCost,
                                componentOwner: vm.selectedComponentOwnerId,
                                rtd: vm.rtd,
                                hours: vm.hours,
                                excludeFromPerformanceAnalysis: vm.excludeFromPerformanceAnalysis,
                                distance: vm.distance,
                                retreader: (vm.selectedRetreader ? vm.selectedRetreader.id : null),
                                repairer: (vm.selectedRepairer ? vm.selectedRepairer.id : null),
                                hoursSince: vm.hoursSince,
                                ndtStatus: vm.ndtStatus,
                                rcd: vm.rcd,
                                attachments: vm.attachments,
                                generateComponentFailureReport: vm.generateComponentFailureReport
                            };
                        }
                        vm.wnd.onDataChanged(vm.initParams);
                    }
                    WindowFactory.closeWindow(vm.wnd);
                }
            }
        ]
    });
