//import angular from 'angular';
import AdminUrls from '../adminUrls';
import HelperSvc from '../../../services/helperSvc';
import tmpl from './details.html';



    angular.module('app.admin').component('sensorDetails', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['$scope', 'confirmSvc', 'enums', 'adminUrls', 'WindowFactory', 'ocSecuritySvc', 'amtCommandQuerySvc', 'notifySvc', 'amtXlatSvc', 'helperSvc', 'ocConfigSvc', 'errorReporter',
            function ($scope, confirmSvc, enums, adminUrls: AdminUrls, WindowFactory: IWindowFactory, ocSecuritySvc, amtCommandQuerySvc, notifySvc, amtXlatSvc, helperSvc: HelperSvc, ocConfigSvc, errorReporter) {

                var vm = this;
                $scope.vm = this;

                vm.urls = adminUrls;
                vm.config = ocConfigSvc;

                // pass along dirty flag to the window for use on closing minimised windows
                $scope.$watch('vm.form.$dirty', function () {
                    vm.wnd.isDirty = vm.form.$dirty;
                });

                this.$onInit = function () {

                    if (vm.wnd !== null) {
                        vm.wnd.onClose = vm.cancel;
                    }

                    vm.data = angular.copy(vm.initParams);

                    if (!vm.data.id) {
                        vm.data.active = true;
                    }

                    vm.readonly = (vm.data.id && !ocSecuritySvc.isAuthorised('Security.Settings.Sensors', 'readWrite'));

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: "common.save_label",
                            name: "saveButton",
                            click: "save",
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: false,
                            value: "common.saveAndAddAnother_label",
                            name: "saveAndAddAnotherButton",
                            click: "saveAndAddAnother",
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: !vm.readonly,
                            disabled: true
                        },
                        saveAndAddAnotherButton: {
                            visible: !vm.data.id,
                            disabled: true
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.save,
                        saveAndAddAnother: vm.save
                    };
                }

                $scope.showComponentDetails = function () {
                    if (vm.data.componentAttachedTo) {
                        helperSvc.showComponentDetails({
                            equipmentId: vm.data.componentAttachedTo.id,
                            siteId: vm.data.site.id,
                        });
                    }
                };

                $scope.showVehicleDetails = function () {
                    if (vm.data.vehicleAttachedTo) {
                        helperSvc.showVehicleDetails({
                            equipmentId: vm.data.vehicleAttachedTo.key,
                            siteId: vm.data.site.id
                        });
                    }
                };

                vm.save = function (button) {

                    var criteria = {
                        id: vm.data.id,
                        serialNumber: vm.data.serialNumber,
                        active: vm.data.active
                    };

                    vm.wnd.processing = true;

                    return amtCommandQuerySvc.post((vm.data.id ? vm.urls.modifySensor : vm.urls.addSensor), criteria).then(function (response) {

                        notifySvc.success(amtXlatSvc.xlat('sensors.savedSuccessfully'));

                        if (vm.wnd.onDataChanged) {
                            vm.wnd.onDataChanged();
                        }

                        if (button === 'saveAndAddAnother') {
                            vm.data = {
                                active: true
                            };
                            vm.form.$setPristine();
                        } else {
                            vm.closeWindow();
                        }

                    }).catch(function (error) {
                        errorReporter.logError(error);
                    }).finally(function () {
                        vm.wnd.processing = false;
                    });
                };

                vm.closeWindow = function () {
                    WindowFactory.closeWindow(vm.wnd);
                };

                vm.cancel = function () {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        vm.form.dirty = false;
                        vm.closeWindow();
                        return true;
                    });

                    return false;
                };

                $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing'], function () {
                    vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.form.$pristine || vm.wnd.processing;
                    vm.buttonStates.saveAndAddAnotherButton.disabled = vm.form.$invalid || vm.form.$pristine || vm.wnd.processing;
                });
            }
        ]
    });
