/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Represents a template that defines the content of the Drawer.
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoDrawerTemplate` directive inside the `<kendo-drawer>` tag.
 * Using this template directive will override all other templates,
 * for example, `kendoDrawerHeaderTemplate` and `kendoDrawerItemTemplate`.
 */
var DrawerTemplateDirective = /** @class */ (function () {
    function DrawerTemplateDirective(templateRef) {
        this.templateRef = templateRef;
    }
    DrawerTemplateDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoDrawerTemplate]'
        }),
        tslib_1.__param(0, core_1.Optional()),
        tslib_1.__metadata("design:paramtypes", [core_1.TemplateRef])
    ], DrawerTemplateDirective);
    return DrawerTemplateDirective;
}());
exports.DrawerTemplateDirective = DrawerTemplateDirective;
