/**
 * @ngdoc directive
 * @name amtFramework.grid.directive:amtGridDynamicColumn
 * @restrict E
 * 
 * @param {string} field Field name of the datasource
 * @param {string} title Column header title
 * @param {string} value Value
 * @param {string=} width Initial width of the column
 * @param {string=} [type=string] Possible options: 'integer', 'double', 'boolean', 'date', 'datetime', 'dropdown', 'string'
 * @param {string=} filterType Possible options: 'dropdown'
 * @param {string=} [hidden=false] Wether the Column is initially hidden 
 * @param {string=} [optional=true] Wether the user can hide the column
 * @param {string=} attributes Additional html attributes
 * @param {expression=} [filterable=true] To hide the filter function
 * @param {string=} hideText ???
 * @param {string=} [editable=true] Wether the column is editable
 * @param {string=} defaultValue Default value when adding a new row
 * @param {string=} minValue Min value for validation
 * @param {string=} maxValue Max value for validation
 * @param {expression=} filterEditorOption ???
 * @param {expression=} filterDatasource Datasource for dropdown filter
 * @param {string=} dropdownValue Selected value for dropdown
 * @param {string=} dropdownUrl Url for options in dropdown
 * @param {string=} dropdownRequired Required --> Rename to isRequired and use for all types
 * @param {string=} [aggregate=true] --> Value either sum/count/min/max/average for summary in AMT Grid summary row
 * @param {integer=} maxLength Max length
 * @param {boolean=} [required=true] --> True if you want this field to be required in inline edit mode. This will add an asterisk character in heading of column
 *
 * @description
 * Dynamic Grid column to show and edit different data types
 */

//import angular from 'angular';

angular.module('amtFramework.grid').directive('amtGridDynamicColumn', [
    'appConfig', 'amtXlatSvc', '$compile', '$timeout',
    function (appConfig, xlatSvc, $compile, $timeout) {
                return {
                    require: '^amtGrid',
                    restrict: 'E',
                    scope: {
                        field: '@',
                        title: "@",
                        value: "@",
                        width: '@',
                        type: '@',
                        filterType: "@",
                        hidden: '@',
                        optional: '@',
                        attributes: '@',
                        filterable: '=?',
                        hideText: '@',
                        editable: '@',
                        defaultValue: "@",
                        minValue: "@",
                        maxValue: "@",
                        filterEditorOption: "=?",
                        filterDatasource: '=?',
                        dropdownValue: "@",
                        dropdownUrl: "@",
                        dropdownRequired: "@",
                        aggregate: "@",
                        maxLength: "@",
                        required: "@",
                        forceHide: '=?'
                    },
                    link: function (scope: any, elem, attrs, amtGridCtrl) {
                        scope.columns = [];
                        scope.$on("datasourceReady", function () {
                            var dataItems = amtGridCtrl.getDataSource();
                            if (dataItems.length > 0) {
                                var dataItem = dataItems[0];
                                amtGridCtrl.removeDynamicData(scope.field);
                                scope.columns = [];
                                $timeout(function () {
                                    for (var i = 0; i < dataItem[scope.field].length; i++) {
                                        var column: any = {};
                                        column.id = scope.field;
                                        column.field = scope.field + '[' + i + '].' + scope.value;
                                        column.title = dataItem[scope.field][i][scope.title];
                                        column.forceHide = scope.forceHide;

                                        if (scope.attributes) {
                                            column.attributes = scope.attributes.split(scope.field).join(scope.field + '[' + i + ']');
                                        }
                                        amtGridCtrl.addColumn(column);
                                        //scope.columns.push(column);
                                    }
                                    //amtGridCtrl.refresh();
                                });                                
                            }
                        });
                    }
                };
    }
]);

