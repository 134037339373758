//import angular from 'angular';
import * as _ from 'underscore';
import tmpl from './createEndOfMonth.html';


    angular.module('app.site').component('createEndOfMonth',
        {
            bindings: {
                initParams: '=',
                buttonMethods: '=',
                buttonStates: '=',
                buttons: '=',
                closeOnSave: '=',
                wnd: '='
            },
            template: tmpl,
            controller: [
                '$scope', 'endOfMonthSvc', 'amtXlatSvc', 'WindowFactory', 'confirmSvc', 'notifySvc', 'errorReporter', 'enums',
                function (
                    $scope, endOfMonthSvc, amtXlatSvc, WindowFactory, confirmSvc, notifySvc, errorReporter, enums,
                ) {
                    var vm = this;

                    $scope.vm = this; // necessary for child directives to access what they need.

                    vm.form = {};

                    vm.svc = endOfMonthSvc;

                    this.$onInit = function () {

                        vm.wnd.onClose = vm.cancel;

                        vm.wnd.processing = true;

                        vm.buttons = [
                            {
                                primary: true,
                                cancel: false,
                                value: "common.create_label",
                                name: "createButton",
                                click: "create",
                                type: enums.buttonTypes.button
                            },
                            {
                                primary: false,
                                cancel: true,
                                value: "common.cancel_label",
                                name: "cancelButton",
                                click: "cancel",
                                type: enums.buttonTypes.button
                            }
                        ];

                        vm.buttonStates = {
                            createButton: {
                                visible: false,
                                disabled: true
                            },
                            cancelButton: {
                                visible: true,
                                disabled: false
                            }
                        };

                        vm.buttonMethods = {
                            create: vm.create,
                            cancel: vm.cancel
                        };

                        vm.svc.getAvailableMonthYears().then(function (response) {
                            vm.monthYears = response.result;

                            if (!vm.monthYears || vm.monthYears.length === 0) {
                                vm.noAvailableMonthYears = true;
                            } else {
                                vm.monthYear = _.find(vm.monthYears, m => m.default === true);
                            }

                        }).catch(function (error) {
                            errorReporter.logError(error);
                        }).finally(function () {
                            vm.wnd.processing = false;
                        });
                    };

                    vm.cancel = function () {
                        closeWindow();
                    };

                    function closeWindow() {
                        WindowFactory.closeWindow(vm.wnd);
                    }

                    vm.create = function () {

                        vm.wnd.processing = true;

                        vm.endOfMonth = {};

                        vm.svc.createAsync(vm.monthYear).then(function (response) {

                            vm.endOfMonth = response.data;

                            if (!response.data.alreadyExists) {
                                notifySvc.success(amtXlatSvc.xlat('endOfMonth.successfullyCreated'));

                                closeWindow();

                                vm.svc.openItem({ id: response.data.id, monthYear: vm.monthYear.description }, null, amtXlatSvc.xlat("endOfMonth.editTitle", vm.monthYear.description));
                            }

                        }).catch(function (error) {
                            errorReporter.logError(error);
                        }).finally(function () {
                            vm.wnd.processing = false;
                        });
                    };

                    $scope.openExistingEndOfMonth = function () {
                        if (vm.endOfMonth) {
                            closeWindow();
                            vm.svc.openItem({ id: vm.endOfMonth.id }, null, amtXlatSvc.xlat("endOfMonth.editTitle", vm.monthYear.description));
                        }
                    };

                    $scope.$watchGroup(['vm.form.$invalid', 'vm.wnd.processing', 'vm.noAvailableMonthYears'], function () {
                        vm.buttonStates.createButton.disabled = vm.form.$invalid || vm.wnd.processing;
                        vm.buttonStates.createButton.visible = !vm.noAvailableMonthYears;
                    });
                }
            ]
        });
