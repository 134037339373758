/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var filter_service_1 = require("../filter.service");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var date_filter_component_1 = require("../date-filter.component");
var utils_1 = require("../../utils");
/**
 * Represents a date-filter cell component.
 *
 * @example
 *
 *  ```html-no-run
 *      <kendo-grid-column field="OrderDate" title="Order Date">
 *          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
 *          <kendo-grid-date-filter-cell
 *              [showOperators]="false"
 *              [column]="column"
 *              [filter]="filter">
 *          </kendo-grid-date-filter-cell>
 *          </ng-template>
 *      </kendo-grid-column>
 *   ```
 */
var DateFilterCellComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DateFilterCellComponent, _super);
    function DateFilterCellComponent(filterService, localization) {
        var _this = _super.call(this, filterService, localization) || this;
        _this.localization = localization;
        /**
         * Determines if the drop-down filter operators will be displayed. The default value is `true`.
         * @type {boolean}
         */
        _this.showOperators = true;
        return _this;
    }
    /**
     * @hidden
     */
    DateFilterCellComponent.prototype.messageFor = function (key) {
        return this.localization.get(key);
    };
    Object.defineProperty(DateFilterCellComponent.prototype, "columnLabel", {
        /**
         * @hidden
         */
        get: function () {
            var localizationMsg = this.localization.get('filterInputLabel') || '';
            var columnName = this.column.title || this.column.field;
            return utils_1.replaceMessagePlaceholder(localizationMsg, 'columnName', columnName);
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], DateFilterCellComponent.prototype, "showOperators", void 0);
    DateFilterCellComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-grid-date-filter-cell',
            template: "\n        <kendo-grid-filter-wrapper-cell\n            [column]=\"column\"\n            [filter]=\"filter\"\n            [operators]=\"operators\"\n            [defaultOperator]=\"operator\"\n            [showOperators]=\"showOperators\"\n        >\n            <kendo-datepicker\n                kendoFilterInput\n                kendoGridFocusable\n                [columnLabel]=\"columnLabel\"\n                [value]=\"currentFilter?.value\"\n                [format]=\"format\"\n                [formatPlaceholder]=\"formatPlaceholder\"\n                [placeholder]=\"placeholder\"\n                [activeView]=\"activeView\"\n                [bottomView]=\"bottomView\"\n                [topView]=\"topView\"\n                [min]=\"min\"\n                [max]=\"max\"\n                [weekNumber]=\"weekNumber\"\n            >\n                <kendo-datepicker-messages\n                    [toggle]=\"messageFor('filterDateToggle')\"\n                    [today]=\"messageFor('filterDateToday')\"\n                >\n                </kendo-datepicker-messages>\n            </kendo-datepicker>\n        </kendo-grid-filter-wrapper-cell>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [filter_service_1.FilterService,
            kendo_angular_l10n_1.LocalizationService])
    ], DateFilterCellComponent);
    return DateFilterCellComponent;
}(date_filter_component_1.DateFilterComponent));
exports.DateFilterCellComponent = DateFilterCellComponent;
