import './admin/adminUrls';
import './admin/backupStockLevels/detailsCtrl';
import './admin/backupStockLevels/searchCtrl';
import './admin/backupStockLevels/urls';
import './admin/componentOwner/componentOwnerDetailsCtrl';
import './admin/componentOwner/componentOwnerSearchCtrl';
import './admin/componentOwner/componentOwnerSvc';
import './admin/fieldSurveys/fieldSurveyFrequencyCtrl';
import './admin/fleets/detailsCtrl';
import './admin/fleets/orderCtrl';
import './admin/fleets/searchCtrl';
import './admin/fleets/urls';
import './admin/locations/locationDetailsCtrl';
import './admin/locations/locationSearchCtrl';
import './admin/locations/locationSvc';
import './admin/notifications/alertsTriggersCtrl';
import './admin/notifications/triggers/triggerPopupCtrl';
import './admin/notifications/triggers/triggerPopupEffectiveRecipientsCtrl';
import './admin/performanceBatch/detailsCtrl';
import './admin/performanceBatch/searchCtrl';
import './admin/productionCrews/detailsCtrl';
import './admin/productionCrews/searchCtrl';
import './admin/sensors/detailsCtrl';
import './admin/sensors/searchCtrl';
import './admin/siteDashboard/siteDashboardAddCtrl';
import './admin/siteDashboard/siteDashboardConfigurationCtrl';
import './admin/siteDashboard/siteDashboardConfigurationDetailsCtrl';
import './admin/siteDetails/siteDetails';
import './admin/siteDetails/popups/addEditKpiCtrl';
import './admin/specifications/components/detailsCtrl';
import './admin/specifications/components/searchCtrl';
import './admin/specifications/components/popups/addEditPriceCtrl';
import './admin/specifications/vehicles/specificationAttachmentSvc';
import './admin/specifications/vehicles/vehicleSpecificationAttachment';
import './admin/specifications/vehicles/vehicleSpecificationDetailsCtrl';
import './admin/specifications/vehicles/vehicleSpecificationSearchCtrl';
import './admin/specifications/vehicles/popups/addValidFitmentCtrl';
import './admin/specifications/vehicles/popups/setPositionLabel';
import './admin/stockCodes/detailsCtrl';
import './admin/stockCodes/searchCtrl';
import './admin/stockCodes/urls';
import './admin/systemSettings/component/compound/detailsCtrl';
import './admin/systemSettings/component/compound/searchCtrl';
import './admin/systemSettings/component/pattern/detailsCtrl';
import './admin/systemSettings/component/pattern/searchCtrl';
import './admin/systemSettings/component/plyRating/detailsCtrl';
import './admin/systemSettings/component/plyRating/searchCtrl';
import './admin/systemSettings/component/size/detailsCtrl';
import './admin/systemSettings/component/size/searchCtrl';
import './admin/systemSettings/component/traCode/detailsCtrl';
import './admin/systemSettings/component/traCode/searchCtrl';
import './admin/systemSettings/maintenance/action/detailsCtrl';
import './admin/systemSettings/maintenance/action/searchCtrl';
import './admin/systemSettings/maintenance/action/sysMaintActionSvc';
import './admin/systemSettings/maintenance/damageCause/detailsCtrl';
import './admin/systemSettings/maintenance/damageCause/searchCtrl';
import './admin/systemSettings/maintenance/damageCause/sysMaintDamageCauseSvc';
import './admin/systemSettings/maintenance/damageLocation/detailsCtrl';
import './admin/systemSettings/maintenance/damageLocation/searchCtrl';
import './admin/systemSettings/maintenance/damageLocation/sysMaintDamageLocationSvc';
import './admin/systemSettings/maintenance/damageSource/detailsCtrl';
import './admin/systemSettings/maintenance/damageSource/searchCtrl';
import './admin/systemSettings/maintenance/damageSource/sysMaintDamageSourceSvc';
import './admin/systemSettings/maintenance/delayReasons/delayReasonSvc';
import './admin/systemSettings/maintenance/delayReasons/detailsCtrl';
import './admin/systemSettings/maintenance/delayReasons/searchCtrl';
import './admin/systemSettings/maintenance/removalReason/detailsCtrl';
import './admin/systemSettings/maintenance/removalReason/searchCtrl';
import './admin/systemSettings/maintenance/removalReason/sysMaintRemovalReasonSvc';
import './admin/systemSettings/organisation/client/sysOrgClientDetailsCtrl';
import './admin/systemSettings/organisation/client/sysOrgClientSearchCtrl';
import './admin/systemSettings/organisation/client/sysOrgClientSvc';
import './admin/systemSettings/organisation/commodity/detailsCtrl';
import './admin/systemSettings/organisation/commodity/searchCtrl';
import './admin/systemSettings/organisation/commodity/sysOrgCommoditySvc';
import './admin/systemSettings/organisation/company/detailsCtrl';
import './admin/systemSettings/organisation/company/searchCtrl';
import './admin/systemSettings/organisation/currency/currencySvc';
import './admin/systemSettings/organisation/currency/detailsCtrl';
import './admin/systemSettings/organisation/currency/searchCtrl';
import './admin/systemSettings/organisation/operationType/detailsCtrl';
import './admin/systemSettings/organisation/operationType/searchCtrl';
import './admin/systemSettings/organisation/operationType/sysOrgOperationTypeSvc';
import './admin/systemSettings/organisation/site/detailsCtrl';
import './admin/systemSettings/organisation/site/searchCtrl';
import './admin/systemSettings/system/reportIntegration/detailsCtrl';
import './admin/systemSettings/system/reportIntegration/searchCtrl';
import './admin/systemSettings/system/alerts/systemAlertsCtrl';
import './admin/systemSettings/system/alerts/systemAlertPopupCtrl';
import './admin/systemSettings/system/resource/detailsCtrl';
import './admin/systemSettings/system/resource/searchCtrl';
import './admin/systemSettings/system/rolesPermissions/modifyRoleCtrl';
import './admin/systemSettings/system/rolesPermissions/rolesPermissionsCtrl';
import './admin/systemSettings/system/rolesPermissions/updateAccessTypeCtrl';
import './admin/systemSettings/vehicle/application/detailsCtrl';
import './admin/systemSettings/vehicle/application/searchCtrl';
import './admin/usersecurity/userDetailsCtrl';
import './admin/usersecurity/userSettingsCtrl';
import './admin/usersecurity/userSettingsTabsCtrl';
import './admin/usersecurity/usersSearchCtrl';
import './admin/usersecurity/mergeuser/userMergePopupCtrl';
import './admin/usersecurity/subscriptions/userSubscriptionsCtrl';
import './admin/usersecurity/userRoles/addRolePopupCtrl';
import './admin/usersecurity/userRoles/copyRolesFromUserPopupCtrl';
import './admin/usersecurity/userRoles/EditRolePopupCtrl';
import './admin/usersecurity/userRoles/newPermissionCtrl';
import './admin/vehicleTyreStrategy/vehicleTyreStrategyCtrl';
import './common/dataBroker';
import './common/forgottenPasswordCtrl';
import './common/local-db';
import './common/loginCtrl';
import './common/supportCtrl';
import './common/notifications/listCtrl';
import './common/notifications/settings';
import './common/notifications/urls';
import './common/popups/attachmentDetails';
import './common/popups/closeAllWindowsConfirmationCtrl';
import './common/site/changeSiteCtrl';
import './components/uploadErrorsCtrl';
import './components/directives/errorPanel';
import './components/directives/ocActivity';
import './components/directives/ocDataSyncButton';
import './components/directives/ocDataSyncModule';
import './components/directives/ocKeyPad';
import './components/directives/ocKeypadInput';
import './components/directives/ocProgress';
import './components/directives/vehicleFitments';
import './components/directives/vehicleReadings';
import './components/fieldSurvey/chainsPopupCtrl';
import './components/fieldSurvey/fieldSurveyDeleteAllCtrl';
import './components/fieldSurvey/fieldSurveysCtrl';
import './components/fieldSurvey/moduleChooserPopupCtrl';
import './components/fieldSurvey/pressureReadingTypeCtrl';
import './components/fieldSurvey/serialChangeCtrl';
import './components/fieldSurvey/vehicleDateTimeCtrl';
import './components/fieldSurvey/vehicleValidationCtrl';
import './components/maintenance/Checkin';
import './components/maintenance/Checkout';
import './components/maintenance/Events';
import './components/maintenance/Fitted';
import './components/maintenance/maintenanceCtrl';
import './components/maintenance/vehicleStats';
import './components/maintenance/dialogs/Delay';
import './components/maintenance/dialogs/DownloadPrompt';
import './components/maintenance/dialogs/EditFitment';
import './components/maintenance/dialogs/EventsActionsMenu';
import './components/maintenance/dialogs/Fit';
import './components/maintenance/dialogs/IncompletePositionsWindow';
import './components/maintenance/dialogs/Remove';
import './components/maintenance/dialogs/RemoveDetails';
import './components/maintenance/readings/ReadingGrid';
import './components/ModuleServices/baseModuleService';
import './components/ModuleServices/componentReceiveService';
import './components/ModuleServices/fieldSurveyService';
import './components/ModuleServices/maintenanceSessionService';
import './components/ModuleServices/moduleFactory';
import './components/ModuleServices/pitsAndAuditService';
import './components/ModuleServices/safetyAuditService';
import './components/ModuleServices/statusChangeService';
import './components/ModuleServices/stocktakeService';
import './components/ModuleServices/vehicleReceiveService';
import './components/statusChange/statusChangeCtrl';
import './components/stocktake/createStocktakeCtrl';
import './components/stocktake/dropBox';
import './components/stocktake/stockDateTimeCtrl';
import './components/stocktake/stockGrid';
import './components/stocktake/stockRow';
import './components/stocktake/stocktakeCommentPopupCtrl';
import './components/stocktake/stocktakeCommentsCtrl';
import './components/stocktake/stocktakeComponentExistsCtrl';
import './components/stocktake/stockTakeCtrl';
import './components/stocktake/StockTypeCtrl';
import './components/stocktake/tyreAdd';
import './equipment/equipmentUrls';
import './equipment/component/componentDetailsCtrl';
import './equipment/component/componentReceiveCtrl';
import './equipment/component/componentReceiveErrored';
import './equipment/component/componentReceiveFactory';
import './equipment/component/componentSearchCtrl';
import './equipment/component/componentUrls';
import './equipment/component/BulkMove/bulkMoveCtrl';
import './equipment/component/mwWarranty/detailsCtrl';
import './equipment/component/mwWarranty/searchCtrl';
import './equipment/component/stocktake/blankStocktakeForm';
import './equipment/component/stocktake/stocktakeSearch';
import './equipment/component/stocktake/stocktakeUrls';
import './equipment/component/visualInspection/visualInspectionCtrl';
import './equipment/component/visualInspection/visualInspectionItemCtrl';
import './equipment/component/visualInspection/visualInspectionHistory';
import './equipment/component/visualInspection/visual-inspection-cause.component';
import './equipment/directives/ocEquipmentAttachment';
import './equipment/directives/ocEquipmentComment';
import './equipment/directives/ocEquipmentHistory';
import './equipment/directives/ocEquipmentVisualInspection';
import './equipment/directives/ocVehicleFitments';
import './equipment/directives/VehicleSchematic/ocVehicleSchematic';
import './equipment/directives/VehicleSchematic/ocVehicleSchematicPoistion';
import './equipment/directives/VehicleSchematic/ocVehicleSchematicRect';
import './equipment/popups/attachmentPopupCtrl';
import './equipment/popups/changeStatusPopupCtrl';
import './equipment/popups/commentPopupCtrl';
import './equipment/popups/componentReceiveConditionPopupCtrl';
import './equipment/popups/componentReceivePopupCtrl';
import './equipment/popups/fullScreenSchematicPopupCtrl';
import './equipment/popups/transferVehiclePopupCtrl';
import './equipment/popups/vehicleChangeStatusPopupCtrl';
import './equipment/popups/vehicleReceivePopupCtrl';
import './equipment/vehicle/vehicleDetailsCtrl';
import './equipment/vehicle/vehicleReceiveCtrl';
import './equipment/vehicle/vehicleReceiveDetailsCtrl';
import './equipment/vehicle/vehicleReceiveFactory';
import './equipment/vehicle/vehicleSearchCtrl';
import './equipment/vehicle/vehicleUrls';
import './equipment/vehicle/fieldSurvey/blankFieldSurvey';
import './equipment/vehicle/fieldSurvey/fieldSurveyUrls';
import './equipment/vehicle/fieldSurvey/search';
import './equipment/vehicle/maintenance/search';
import './equipment/vehicle/maintenance/urls';
import './file/fileUrls';
import './forecasting/forecastCreateUpdate/addUpdateForecastCtrl';
import './forecasting/forecastDetails/forecastDetailsCtrl';
import './forecasting/forecastDetails/updateForecastOverrideCtrl';
import './forecasting/forecastDetails/forecastOrders/forecastOrdersEditCtrl';
import './forecasting/forecastDetails/forecastOrders/createOrdersPopup/orderCreatePopupCtrl';
import './forecasting/forecastDetails/forecastOrders/updateOrderPopups/editOrdersPopupCtrl';
import './forecasting/forecastDetails/forecastRims/rimSpecificationEdit';
import './forecasting/forecastDetails/forecastTyres/tyreSpecificationEdit';
import './forecasting/forecastDetails/forecastTyres/vehicleRotationStrategiesCtrl';
import './forecasting/forecastDetails/forecastTyres/vehicleSpecificationNewTyreChangeoutPositionsCtrl';
import './forecasting/forecastDetails/forecastTyres/tyreSpecificationPopups/updateForecastFitment';
import './forecasting/forecastDetails/forecastVehicles/editVehiclePopups/editVehicleEndDateCtrl';
import './forecasting/forecastDetails/forecastVehicles/editVehiclePopups/editVehicleStartDateCtrl';
import './forecasting/forecastDetails/forecastVehicles/existingVehicleSchedule/forecastExistingVehicleScheduleCtrl';
import './forecasting/forecastDetails/forecastVehicles/newVehicleSchedule/forecastAddNewVehicleCtrl';
import './forecasting/forecastDetails/forecastVehicles/newVehicleSchedule/forecastNewVehicleScheduleCtrl';
import './forecasting/forecastDetails/forecastVehicles/vehicleMonthlyUsage/forecastVehicleMonthlyUsageCtrl';
import './forecasting/forecastDetails/forecastVehicles/vehicleMonthlyUsage/vehicleMonthlyUsageEditPopupCtrl';
import './forecasting/forecastDetails/occurrence/rimOccurrenceListCtrl';
import './forecasting/forecastDetails/occurrence/tyreOccurrenceListCtrl';
import './forecasting/forecastDetails/recalculateForecastCtrl.ts';
import './forecasting/forecastList/forecastList';
import './forecasting/forecastList/refreshReferenceData';
import './forecasting/forecastReports/forecastReport';
import './forecasting/forecastReports/forecastReportsPopupCtrl';
import './forecasting/forecastReports/forecastReportsViewerCtrl';
import './forecasting/services/forecastingSvc';
import './referenceData/referenceDataUrls';
import './reports/reportCtrl';
import './site/endOfMonth/createEndOfMonth';
import './site/endOfMonth/details';
import './site/endOfMonth/endOfMonthSvc';
import './site/endOfMonth/endOfMonthUrls';
import './site/endOfMonth/searchCtrl';
import './site/endOfMonth/popups/addEditContinuousImprovement';
import './site/endOfMonth/popups/addEditTraining';
import './site/endOfMonth/popups/validationErrors';
import './site/purchaseOrder/purchaseOrderDetailsCtrl';
import './site/purchaseOrder/purchaseOrderSearchCtrl';
import './site/purchaseOrder/purchaseOrdersMergeCtrl';
import './site/purchaseOrder/purchaseOrderSpecificationDetails';