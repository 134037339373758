/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var column_info_service_1 = require("./../common/column-info.service");
var core_1 = require("@angular/core");
var detail_template_directive_1 = require("../rendering/details/detail-template.directive");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var utils_1 = require("../utils");
/**
 * @hidden
 */
var FilterRowComponent = /** @class */ (function () {
    function FilterRowComponent(localization, columnInfoService) {
        this.localization = localization;
        this.columnInfoService = columnInfoService;
        this.columns = [];
        this.groups = [];
        this.filterRowClass = true;
    }
    FilterRowComponent.prototype.addStickyStyles = function (column) {
        var sticky = column.sticky ? this.columnInfoService.stickyColumnsStyles(column) : null;
        return tslib_1.__assign({}, sticky, column.filterStyle);
    };
    FilterRowComponent.prototype.filterLabel = function (column) {
        var localizationMsg = this.localization.get('filterInputLabel') || '';
        var columnName = column.title || column.field;
        return utils_1.replaceMessagePlaceholder(localizationMsg, 'columnName', columnName);
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Array)
    ], FilterRowComponent.prototype, "columns", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], FilterRowComponent.prototype, "filter", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Array)
    ], FilterRowComponent.prototype, "groups", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", detail_template_directive_1.DetailTemplateDirective)
    ], FilterRowComponent.prototype, "detailTemplate", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], FilterRowComponent.prototype, "logicalRowIndex", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], FilterRowComponent.prototype, "lockedColumnsCount", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-filter-row'),
        tslib_1.__metadata("design:type", Boolean)
    ], FilterRowComponent.prototype, "filterRowClass", void 0);
    FilterRowComponent = tslib_1.__decorate([
        core_1.Component({
            selector: '[kendoGridFilterRow]',
            template: "\n        <td\n            [class.k-group-cell]=\"true\"\n            *ngFor=\"let g of groups\"\n            role=\"presentation\">\n        </td>\n        <td\n            [class.k-hierarchy-cell]=\"true\"\n            *ngIf=\"detailTemplate?.templateRef\"\n            role=\"presentation\">\n        </td>\n        <td *ngFor=\"let column of columns; let columnIndex = index\"\n            [class.k-grid-header-sticky]=\"column.sticky\"\n            [ngStyle]=\"addStickyStyles(column)\"\n            [ngClass]=\"column.filterClass\"\n            [attr.aria-label]=\"filterLabel(column)\"\n            kendoGridFilterCell\n                [column]=\"column\"\n                [filter]=\"filter\"\n            kendoGridLogicalCell\n                [logicalRowIndex]=\"logicalRowIndex\"\n                [logicalColIndex]=\"lockedColumnsCount + columnIndex\"\n      ></td>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService,
            column_info_service_1.ColumnInfoService])
    ], FilterRowComponent);
    return FilterRowComponent;
}());
exports.FilterRowComponent = FilterRowComponent;
