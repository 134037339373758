/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Represents the filter-menu template
 * ([see example]({% slug builtinfiltertemplate_grid %}#toc-customizing-filter-menus)).
 */
var FilterMenuTemplateDirective = /** @class */ (function () {
    function FilterMenuTemplateDirective(templateRef) {
        this.templateRef = templateRef;
    }
    FilterMenuTemplateDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoGridFilterMenuTemplate]'
        }),
        tslib_1.__param(0, core_1.Optional()),
        tslib_1.__metadata("design:paramtypes", [core_1.TemplateRef])
    ], FilterMenuTemplateDirective);
    return FilterMenuTemplateDirective;
}());
exports.FilterMenuTemplateDirective = FilterMenuTemplateDirective;
