/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Represents a template that defines the content of the FloatingActionButton.
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoFloatingActionButtonTemplate` directive inside the `<kendo-floatingactionbutton>` tag
 * ([see example]({% slug templates_floatingactionbutton %}#toc-floatingactionbutton-template)).
 */
var FloatingActionButtonTemplateDirective = /** @class */ (function () {
    function FloatingActionButtonTemplateDirective(templateRef) {
        this.templateRef = templateRef;
    }
    FloatingActionButtonTemplateDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoFloatingActionButtonTemplate]'
        }),
        tslib_1.__param(0, core_1.Optional()),
        tslib_1.__metadata("design:paramtypes", [core_1.TemplateRef])
    ], FloatingActionButtonTemplateDirective);
    return FloatingActionButtonTemplateDirective;
}());
exports.FloatingActionButtonTemplateDirective = FloatingActionButtonTemplateDirective;
