/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var grid_component_1 = require("../grid.component");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var kendo_common_1 = require("@progress/kendo-common");
var utils_1 = require("../utils");
/**
 * A directive which controls the expanded state of the master detail rows.
 */
var ExpandDetailsDirective = /** @class */ (function () {
    function ExpandDetailsDirective(grid) {
        this.grid = grid;
        /**
         * Fires when the expandedDetailKeys are changed.
         */
        this.expandedDetailKeysChange = new core_1.EventEmitter();
        /**
         * Defines the collection that will store the expanded keys.
         */
        this.expandedDetailKeys = [];
        /**
         * Specifies if the items should be initially expanded.
         * When set to `true` items added to the `expandedDetailKeys` collection will be collapsed, and items that are not present in it will be expanded.
         *
         * @default false
         */
        this.initiallyExpanded = false;
        this.expandedState = new Set();
        this.subscriptions = new rxjs_1.Subscription();
        this.grid.isDetailExpanded = this.isExpanded.bind(this);
        this.subscriptions.add(rxjs_1.merge(this.grid.detailExpand.pipe(operators_1.map(function (e) { return (tslib_1.__assign({ expand: true }, e)); })), this.grid.detailCollapse.pipe(operators_1.map(function (e) { return (tslib_1.__assign({ expand: false }, e)); }))).subscribe(this.toggleState.bind(this)));
    }
    Object.defineProperty(ExpandDetailsDirective.prototype, "expandDetailsKey", {
        /**
         * Defines the item key that will be stored in the `expandedDetailKeys` collection ([see example]({% slug master_detail_expanded_state_grid %}#toc-built-in-directive)).
         */
        get: function () {
            return this._expandBy;
        },
        set: function (key) {
            if (utils_1.isString(key)) {
                this._expandBy = kendo_common_1.getter(key);
            }
            else {
                this._expandBy = key;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExpandDetailsDirective.prototype, "expandDetailBy", {
        /**
         *
         * @hidden
         * A deprecated alias for setting the `expandDetailsKey` property.
         */
        get: function () {
            return this.expandDetailsKey;
        },
        set: function (key) {
            this.expandDetailsKey = key;
        },
        enumerable: true,
        configurable: true
    });
    ExpandDetailsDirective.prototype.ngOnChanges = function (changes) {
        // skip reinitialization if the user data is the same as the last state change
        if (utils_1.isPresent(changes.expandedDetailKeys) && this.lastExpandedState !== this.expandedDetailKeys) {
            this.expandedState = new Set(this.expandedDetailKeys);
        }
    };
    ExpandDetailsDirective.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    Object.defineProperty(ExpandDetailsDirective.prototype, "keyGetter", {
        get: function () {
            return this._expandBy || kendo_common_1.getter(undefined);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @hidden
     */
    ExpandDetailsDirective.prototype.isExpanded = function (args) {
        var key = this.keyGetter(args.dataItem);
        var hasKey = this.expandedState.has(key);
        // when [initiallyExpanded]="true" a present key means the corresponding detail row is collapsed
        return this.initiallyExpanded ? !hasKey : hasKey;
    };
    ExpandDetailsDirective.prototype.toggleState = function (args) {
        var key = this.keyGetter(args.dataItem);
        if (Boolean(this.initiallyExpanded) !== args.expand) {
            this.expandedState.add(key);
        }
        else {
            this.expandedState.delete(key);
        }
        this.notifyChange();
    };
    ExpandDetailsDirective.prototype.notifyChange = function () {
        this.lastExpandedState = Array.from(this.expandedState);
        this.expandedDetailKeysChange.emit(this.lastExpandedState);
    };
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ExpandDetailsDirective.prototype, "expandedDetailKeysChange", void 0);
    tslib_1.__decorate([
        core_1.Input('kendoGridExpandDetailsBy'),
        tslib_1.__metadata("design:type", Object),
        tslib_1.__metadata("design:paramtypes", [Object])
    ], ExpandDetailsDirective.prototype, "expandDetailsKey", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object),
        tslib_1.__metadata("design:paramtypes", [Object])
    ], ExpandDetailsDirective.prototype, "expandDetailBy", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Array)
    ], ExpandDetailsDirective.prototype, "expandedDetailKeys", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], ExpandDetailsDirective.prototype, "initiallyExpanded", void 0);
    ExpandDetailsDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoGridExpandDetailsBy]',
            exportAs: 'kendoGridExpandDetailsBy'
        }),
        tslib_1.__metadata("design:paramtypes", [grid_component_1.GridComponent])
    ], ExpandDetailsDirective);
    return ExpandDetailsDirective;
}());
exports.ExpandDetailsDirective = ExpandDetailsDirective;
