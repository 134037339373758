/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var flatcolorpicker_localization_service_1 = require("./flatcolorpicker-localization.service");
/**
 * @hidden
 */
var ColorGradientLocalizationService = /** @class */ (function (_super) {
    tslib_1.__extends(ColorGradientLocalizationService, _super);
    function ColorGradientLocalizationService(prefix, messageService, _rtl, flatColorPickerLocalization) {
        var _this = _super.call(this, prefix, messageService, _rtl) || this;
        _this.flatColorPickerLocalization = flatColorPickerLocalization;
        return _this;
    }
    ColorGradientLocalizationService.prototype.get = function (shortKey) {
        if (this.flatColorPickerLocalization) {
            return this.flatColorPickerLocalization.get(shortKey);
        }
        return _super.prototype.get.call(this, shortKey);
    };
    ColorGradientLocalizationService = tslib_1.__decorate([
        tslib_1.__param(0, core_1.Inject(kendo_angular_l10n_1.L10N_PREFIX)),
        tslib_1.__param(1, core_1.Optional()),
        tslib_1.__param(2, core_1.Optional()), tslib_1.__param(2, core_1.Inject(kendo_angular_l10n_1.RTL)),
        tslib_1.__param(3, core_1.Optional()), tslib_1.__param(3, core_1.Inject(flatcolorpicker_localization_service_1.FlatColorPickerLocalizationService)),
        tslib_1.__metadata("design:paramtypes", [String, kendo_angular_l10n_1.MessageService, Boolean, flatcolorpicker_localization_service_1.FlatColorPickerLocalizationService])
    ], ColorGradientLocalizationService);
    return ColorGradientLocalizationService;
}(kendo_angular_l10n_1.LocalizationService));
exports.ColorGradientLocalizationService = ColorGradientLocalizationService;
