//import angular from 'angular';

angular.module('amtFramework').directive('amtSubmit', [
    '$parse',
    function ($parse) {
                
                return {
                    restrict: 'A',
                    require: 'form',
                    link: function(scope, formElement, attributes, formController) {

                        var fn = $parse(attributes.amtSubmit);

                        formElement.bind('submit', function(event) {
                            // if form is not valid cancel it.
                            if (!formController.$valid) {
                                return false;
                            }

                            scope.$apply(function() {
                                fn(scope, { $event: event });
                            });
                            return true;
                        });
                    }
                };
    }
]);
