//import angular from 'angular';


angular.module("app.site").controller("forecastListController", forecastListController);

    // @ts-ignore
    forecastListController.$inject = ["amtXlatSvc", "ocSecuritySvc", "forecastingSvc", "$scope", "ocConfigSvc", "confirmSvc", "WindowFactory", "exportSvc", "amtConstants"];

    // ReSharper disable once InconsistentNaming
    function forecastListController(amtXlatSvc, ocSecuritySvc, forecastingSvc, $scope, ocConfigSvc, confirmSvc, WindowFactory, exportSvc, amtConstants) {

        var vm = this;

        vm.gridControl = {};
        vm.forecastListSummarySuffixTitle = "";

        vm.selectedItem = null;
        vm.status = ScreenStatus.loading;

        vm.apiUrls = amtConstants.url;

        vm.canCreateForecast = ocSecuritySvc.isAuthorised('Security.Site.Forecasting.Add', AccessTypes.readWrite) && ocConfigSvc.user.site.active;
        vm.canDeleteForecast = ocSecuritySvc.isAuthorised('Security.Site.Forecasting.Delete', AccessTypes.readWrite);

        vm.criteria = {
            filterValues: {
                active: [],
                forecastTypeIds: [],
                budgetRestricted: []
            }
        };

        let yesLabel = amtXlatSvc.xlat('common.yes_label');
        let noLabel = amtXlatSvc.xlat('common.no_label');

        this.$onInit = function () {

            vm.activeOptions = [
                { name: yesLabel, key: true },
                { name: noLabel, key: false }
            ];

            vm.budgetRestrictedOptions = [
                { name: yesLabel, key: true },
                { name: noLabel, key: false }
            ];

            vm.loadDefaults();
        };

        vm.loadDefaults = function () {

            vm.status = ScreenStatus.loading;

            vm.defaults = {
                active: [vm.activeOptions[0]],
                budgetRestricted: []
            };

            return forecastingSvc.getForecastTypes().then(function (forecastTypes) {
                vm.forecastTypes = forecastTypes;
                vm.status = ScreenStatus.ready;
            }).catch(function (error) {
                vm.defaultErrors = [error];
                vm.status = ScreenStatus.error;
            });
        };

        vm.refreshGrid = function () {
            if (vm.gridControl && vm.gridControl.refresh) {
                vm.gridControl.refresh();
            }
        };

        vm.copyForecast = function (item) {
            WindowFactory.openItem({
                component: 'add-update-forecast',
                canMinimise: false,
                modal: true,
                canClose: false,
                footerOff: false,
                caption: amtXlatSvc.xlat('forecastList.confirmCopy_title', item.rawForecastName),
                width: 800,
                initParams: {
                    mode: ForecastCreateMode.copy,
                    model: {
                        forecastId: item.id,
                        forecastName: item.rawForecastName,
                        startDate: item.forecastStartDate,
                        endDate: item.forecastEndDate,
                        selectedForecastLength: item.forecastLength,
                        selectedForecastTypeId: item.forecastTypeId,
                        selectedForecastMethodId: item.forecastMethodId,
                        fleetIds: item.fleetIds,
                        budgetRestricted: item.budgetRestricted,
                        budgetCap: item.budgetCap,
                        budgetCapTaskTypeIds: item.budgetCapTaskTypeIds.map(t => { return t; }),
                        allowNewVehicleFitmentsToExceedBudgetCap: item.allowNewVehicleFitmentsToExceedBudgetCap,
                        includeRimIntervalsByDays: item.includeRimIntervalsByDays
                    }
                },
                onDataChangeHandler: function () {
                    vm.refreshGrid();
                }
            });
        };

        vm.addNewForecast = function () {
            WindowFactory.openItem({
                component: 'add-update-forecast',
                canMinimise: false,
                modal: true,
                canClose: false,
                footerOff: false,
                caption: amtXlatSvc.xlat('forecastDetails.newForecast'),
                width: 800,
                initParams: {
                    mode: ForecastCreateMode.add
                },
                onDataChangeHandler: function () {
                    vm.refreshGrid();
                }
            });
        };

        vm.deleteForecast = function (item) {
            confirmSvc.confirmMessage('forecastList.confirmDelete_title', 'forecastList.confirmDelete_text', item.forecastName).then(function () {

                vm.processing = true;

                forecastingSvc.deleteForecast(item).then(function () {
                    vm.refreshGrid();
                }).finally(function () {
                    vm.processing = false;
                });
            });
        };

        $scope.showForecastDetails = forecastingSvc.showForecast;

        vm.onGridLoaded = function () {
            vm.selectedItem = null;
            vm.forecastListSummarySuffixTitle = amtXlatSvc.xlat("forecastList.forecastListSummarySuffixTitle", vm.gridControl.getTotalItemsCount());
        };

        vm.onSelectedItemChanged = function (items) {
            if (items != null && items.length === 1) {
                vm.selectedItem = items[0];
            } else {
                vm.selectedItem = null;
            }
        };

        vm.setHeaderHeight = function (height, offset) {
            vm.gridTop = height + offset;
        };

        vm.export = function () {
            exportSvc.exportData(vm.apiUrls.exportForecastList, vm.criteria, amtXlatSvc.xlat('forecastList.exportFilename'));
        };
    }
