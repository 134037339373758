/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var dropdownlist_component_1 = require("./dropdownlist.component");
var shared_module_1 = require("../common/shared.module");
var shared_directives_module_1 = require("../common/shared-directives.module");
var DROPDOWNLIST_DIRECTIVES = [
    dropdownlist_component_1.DropDownListComponent
];
/**
 * @hidden
 *
 * The exported package module.
 *
 * The package exports:
 * - `DropDownListComponent`&mdash;The DropDownList component class.
 * - `ItemTemplateDirective`&mdash;The item template directive.
 * - `ValueTemplateDirective`&mdash;The value template directive.
 * - `HeaderTemplateDirective`&mdash;The header template directive.
 * - `FooterTemplateDirective`&mdash;The footer template directive.
 */
var DropDownListModule = /** @class */ (function () {
    function DropDownListModule() {
    }
    DropDownListModule = tslib_1.__decorate([
        core_1.NgModule({
            declarations: [DROPDOWNLIST_DIRECTIVES],
            exports: [DROPDOWNLIST_DIRECTIVES, shared_directives_module_1.SharedDirectivesModule],
            imports: [shared_module_1.SharedModule]
        })
    ], DropDownListModule);
    return DropDownListModule;
}());
exports.DropDownListModule = DropDownListModule;
