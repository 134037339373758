import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SiteDashboard, Dashboard, UserPosition} from '../Interface/SiteDashboard.interface';

@Injectable({
    providedIn: 'root'
})

export class LayoutRxjsService {

    private readonly defaultSiteDashboard: SiteDashboard = {
        active: false,
        default: false,
        siteId: '',
        id: '',
        name: ''
    };

    //Selection of dashboard selection menu 
    private siteDashboardService: BehaviorSubject<SiteDashboard[]> = new BehaviorSubject<SiteDashboard[]>([]);
    siteDashboardList: Observable<SiteDashboard[]> = this.siteDashboardService.asObservable();

    //Selected dashboard from Site Dashboard
    private selectedDashboardService: BehaviorSubject<SiteDashboard> = new BehaviorSubject<SiteDashboard>(this.defaultSiteDashboard);
    selectedDashboard: Observable<SiteDashboard> = this.selectedDashboardService.asObservable();

    //User site dashboard widget collection
    private userPositionListService: BehaviorSubject<UserPosition[]> = new BehaviorSubject<UserPosition[]>([]);
    userPositionList: Observable<UserPosition[]> = this.userPositionListService.asObservable();

    //Inital site dashboard position collection
    private dashboardListService: BehaviorSubject<Dashboard[]> = new BehaviorSubject<Dashboard[]>([]);
    dashboardList: Observable<Dashboard[]> = this.dashboardListService.asObservable();

    constructor() { }

    async setSiteDashboard(siteDashboard: SiteDashboard[]) {
        await this.siteDashboardService.next(siteDashboard);
    }

    async setSelectedDashboard(selectedDashboard: SiteDashboard) {
        await this.selectedDashboardService.next(selectedDashboard);
    }

    async setUserPositionList(userPositionList: UserPosition[]) {
        await this.userPositionListService.next(userPositionList);
    }

    async setDashboardList(dashboardList: Dashboard[]) {
        await this.dashboardListService.next(dashboardList);
    }

    reset() {
        let empty = [];
        this.setSiteDashboard(empty);
        this.setSelectedDashboard(this.defaultSiteDashboard);
        this.setUserPositionList(empty);
        this.setDashboardList(empty);
    }

    //unsubscribe Rxjs
    completed() {
        this.selectedDashboardService.complete();
        this.siteDashboardService.complete();
        this.dashboardListService.complete();
        this.userPositionListService.complete();
    }

}

