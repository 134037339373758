import OcDateSvc from './services/ocDateSvc';
import { UrlService } from '@uirouter/core';
import tmplApp from './templates/app.html';
import tmplNavComponent from "./templates/nav_component.html";
import tmplStates from './templates/states.html';
import tmplDiag from './templates/diagnostic.html';
import tmplMobile from './templates/mobile.html';
import tmplNavSite from "./templates/nav_site.html";
import tmplNavSettings from "./templates/nav_settings.html";
import tmplNavVehicle from "./templates/nav_vehicle.html";
import tmplNavReports from "./templates/nav_reports.html";

//TODO: template cache imports for ngInclude directives :_(
import htmlHeader from './templates/header.html';
import htmlAside from './templates/aside.html';
import htmlSettings from './templates/settings.html';
import htmlSvgDefs from './templates/svgDefs.html';
import htmlConfig from './modules/admin/siteDetails/configuration.html';
import htmlOperational from './modules/site/endOfMonth/operational.html';
import htmlNvpl from './modules/site/endOfMonth/nameValuePairList.html';
import htmlKpi from './modules/site/endOfMonth/kpi.html';
import htmlTrainingRecv from './modules/site/endOfMonth/trainingReceived.html';
import htmlContImpv from './modules/site/endOfMonth/continuousImprovement.html';
import htmlForecastSummary from './modules/forecasting/forecastDetails/forecastSummary.html';
import htmlForecastVehicles from './modules/forecasting/forecastDetails/forecastVehicles/forecastVehicles.html';
import htmlForecastTyres from './modules/forecasting/forecastDetails/forecastTyres/forecastTyres.html';
import htmlForecastRims from './modules/forecasting/forecastDetails/forecastRims/forecastRims.html';
import htmlForecastOrderTyres from './modules/forecasting/forecastDetails/forecastOrders/forecastOrderTyres.html';
import htmlForecastOrderRims from './modules/forecasting/forecastDetails/forecastOrders/forecastOrderRims.html';
import htmlForecastTyresDashboard from './modules/forecasting/forecastDetails/forecastTyres/forecastTyresDashboard.html';
import htmlForecastRimsDashboard from './modules/forecasting/forecastDetails/forecastRims/forecastRimsDashboard.html';
import htmlVehTabDetails from './modules/admin/specifications/vehicles/tabs/details.html';
import htmlVehTabConf from './modules/admin/specifications/vehicles/tabs/configuration.html';
import htmlVehTabLabels from './modules/admin/specifications/vehicles/tabs/labels.html';
import htmlVehTabDocs from './modules/admin/specifications/vehicles/tabs/documents.html';
import htmlCompTabDetails from './modules/admin/specifications/components/tabs/details.html';
import htmlCompTabPrices from './modules/admin/specifications/components/tabs/prices.html';
import htmlTriggersDetails from './modules/admin/notifications/triggers/details.html';
import htmlBatchDetails from './modules/admin/performanceBatch/tabs/batchDetails.html';
import htmlPerfWarranty from './modules/admin/performanceBatch/tabs/performanceWarranty.html';
import htmlDetails from './modules/admin/siteDetails/details.html';
import htmlLocalisation from './modules/admin/siteDetails/localisation.html';
import htmlComponents from './modules/admin/siteDetails/components.html';
import htmlActivity from './modules/admin/siteDetails/activity.html';
import htmlSerialNumbers from './modules/admin/siteDetails/serialNumbers.html';
import htmlForecasting from './modules/admin/siteDetails/forecasting.html';
import htmlIntegration from './modules/admin/siteDetails/integration.html';
import htmlReporting from './modules/admin/siteDetails/reporting.html';
import htmlKpis from './modules/admin/siteDetails/kpis.html';
import htmlUserAdmin from './modules/admin/usersecurity/UserAdmin.html';
import htmlUserSettings from './modules/admin/usersecurity/UserSettings.html';
import htmlUserRoles from './modules/admin/usersecurity/userRoles/UserRoles.html';
import htmlUserSubscriptions from './modules/admin/usersecurity/subscriptions/UserSubscriptions.html';
import htmlComponentDetails from './modules/equipment/component/tabs/componentDetails.html';
import htmlVehicleDetails from './modules/equipment/vehicle/tabs/vehicleDetails.html';



const amt = angular.module('amtFramework');
amt.factory('amtHttpInterceptor', ['$q', '$injector', '$rootScope', function ($q, $injector, $rootScope) {
    //var numRequests = 0;
    return {
        request: function (config) {
            //numRequests++;
            $rootScope.$emit("requestStart");
            return config || $q.when(config);
        },
        response: function (response) {
            var $http = $injector.get('$http');
            if (!$http.pendingRequests.length) {
                $rootScope.$emit("requestEnd");
            }
            return response || $q.when(response);
        },
        responseError: function (response) {
            var $http = $injector.get('$http');
            if (!$http.pendingRequests.length) {
                $rootScope.$emit("requestEnd"); //TODO: Should probably be a broadcast
            }
            return response || $q.when(response);
        }
    };
}]);

amt.config(['$httpProvider',
    function ($httpProvider) {
        $httpProvider.interceptors.push('amtHttpInterceptor');

        /*$httpProvider.defaults.transformResponse.push(function (responseData) {
            convertDateStringsToDates(responseData);
            return responseData;
        });*/
}]);

amt.run(["$rootScope", "$document",
    function ($rootScope, $document: JQuery<Document>) {
        //show busy-loading each time an ajax request is made via jquery
        $document.ajaxStart(startRequest);
        $document.ajaxSuccess(endRequest);
        $document.ajaxError(endRequest);
        $document.ajaxComplete(endRequest);
        $document.ajaxStop(endRequest);

        function startRequest() {
            $rootScope.$emit("requestStart"); //TODO: Should probably be a broadcast
        }
        function endRequest() {
            $rootScope.$emit("requestEnd"); //TODO: Should probably be a broadcast
        }
    }
]);




const app = angular.module('app');
app.config(['$compileProvider', '$urlServiceProvider',
    function ($compileProvider, $urlService: UrlService) {
        // Using AngularJS config block, call `deferIntercept()`.
        // This tells UI-Router to delay the initial URL sync (until all bootstrapping is complete)
        $urlService.deferIntercept();

        if (!window.debug)
            $compileProvider.debugInfoEnabled(false); // for performance turn of all that extra commenting.
    }
]);

app.run(['$rootScope', '$state', '$stateParams', '$window', 'amtXlatSvc', 'WindowFactory', 'errorReporter', '$location', 'ocDateSvc', '$q', '$templateCache',
        function (
            $rootScope: IRootScope, $state: ng.ui.IStateService, $stateParams: ng.ui.IStateParamsService, $window: ng.IWindowService, xlatSvc: IAmtXlatSvc, WindowFactory: IWindowFactory,
            errorReporter: IErrorReporter, $location: ng.ILocationService, ocDateSvc: OcDateSvc, $q: ng.IQService, $templateCache: ng.ITemplateCacheService
        ) {
            JSON.useDateParser();

            window.onunhandledrejection = function (event) {
                event.preventDefault();
                var reason = event.reason;
                console.warn('Unhandled promise rejection: ' + (reason && (reason.stack || reason)));
            };

            //wire up native promise support to polyfill to $q or to support AngularJS (1.x) digest, need this to use Typescript promise features like await
            if (window.Promise) {
                //from: https://github.com/angular/angular.js/issues/16578
                const originalThen = Promise.prototype.then;

                Promise.prototype.then = function promiseThenInterceptor() {
                    const promise = originalThen.apply(this, arguments);

                    originalThen.call(promise, triggerDigestIfNeeded, triggerDigestIfNeeded);

                    function triggerDigestIfNeeded() {
                        const phase = $rootScope.$$phase;
                        const insideDigest = phase === '$apply' || phase === '$digest';
                        const alreadyHasScheduledWork = $rootScope['$$applyAsyncQueue'].length > 0;

                        if (insideDigest || alreadyHasScheduledWork) {
                            return;
                        }

                        $rootScope.$applyAsync();
                    }

                    return promise;
                } as any;
            } else {
                window.Promise = <any>$q;
            }

            //HACK: for ng-includes per https://blog.johnnyreilly.com/2016/05/13/inlining-angular-templates-with-webpack/
            //please don't add to this list... try and convert / use components instead!
            $templateCache.put("header.html", htmlHeader);
            $templateCache.put("aside.html", htmlAside);
            $templateCache.put("settings.html", htmlSettings);
            $templateCache.put("svgDefs.html", htmlSvgDefs);
            $templateCache.put("configuration.html", htmlConfig);
            $templateCache.put("operational.html", htmlOperational);
            $templateCache.put("nameValuePairList.html", htmlNvpl);
            $templateCache.put("kpi.html", htmlKpi);
            $templateCache.put("trainingReceived.html", htmlTrainingRecv);
            $templateCache.put("continuousImprovement.html", htmlContImpv);
            $templateCache.put("forecastSummary.html", htmlForecastSummary);
            $templateCache.put("forecastVehicles.html", htmlForecastVehicles);
            $templateCache.put("forecastTyres.html", htmlForecastTyres);
            $templateCache.put("forecastRims.html", htmlForecastRims);
            $templateCache.put("forecastOrderTyres.html", htmlForecastOrderTyres);
            $templateCache.put("forecastOrderRims.html", htmlForecastOrderRims);
            $templateCache.put("forecastTyresDashboard.html", htmlForecastTyresDashboard);
            $templateCache.put("forecastRimsDashboard.html", htmlForecastRimsDashboard);
            $templateCache.put("components_tabs_details.html", htmlCompTabDetails);
            $templateCache.put("components_tabs_prices.html", htmlCompTabPrices);
            $templateCache.put("vehicles_tabs_details.html", htmlVehTabDetails);
            $templateCache.put("vehicles_tabs_configuration.html", htmlVehTabConf);
            $templateCache.put("vehicles_tabs_labels.html", htmlVehTabLabels);
            $templateCache.put("vehicles_tabs_documents.html", htmlVehTabDocs);
            $templateCache.put("triggers_details.html", htmlTriggersDetails);
            $templateCache.put("batchDetails.html", htmlBatchDetails);
            $templateCache.put("performanceWarranty.html", htmlPerfWarranty);
            $templateCache.put("details.html", htmlDetails);
            $templateCache.put("localisation.html", htmlLocalisation);
            $templateCache.put("components.html", htmlComponents);
            $templateCache.put("activity.html", htmlActivity);
            $templateCache.put("serialNumbers.html", htmlSerialNumbers);
            $templateCache.put("forecasting.html", htmlForecasting);
            $templateCache.put("integration.html", htmlIntegration);
            $templateCache.put("reporting.html", htmlReporting);
            $templateCache.put("kpis.html", htmlKpis);
            $templateCache.put("UserAdmin.html", htmlUserAdmin);
            $templateCache.put("UserSettings.html", htmlUserSettings);
            $templateCache.put("UserRoles.html", htmlUserRoles);
            $templateCache.put("UserSubscriptions.html", htmlUserSubscriptions);
            $templateCache.put("componentDetails.html", htmlComponentDetails);
            $templateCache.put("vehicleDetails.html", htmlVehicleDetails);


            $rootScope.$state = $state;
            $rootScope.$stateParams = $stateParams;

            $rootScope.childWindows = WindowFactory.windows;

            $rootScope.mouseMove = function (e) {
                if ($rootScope.dragObject && $rootScope.dragObject.mouseMove) {
                    $rootScope.dragObject.mouseMove(e);
                }
            };

            //$rootScope.config = {};

            $rootScope.mouseUp = function (e) {
                if ($rootScope.dragObject && $rootScope.dragObject.mouseUp) {
                    $rootScope.dragObject.mouseUp(e);
                }
            };

            $rootScope.$on('$stateNotFound', function (event, toState, toParams, fromState, fromParams) {
                errorReporter.logError("State not found failure [" + fromState.name + " => " + toState.name + "]");
            });

            $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
                errorReporter.logError("State change failure [" + fromState.name + " => " + toState.name + "]");
            });

            $rootScope.$on('$routeChangeError', function (event, toState, toParams, fromState, fromParams, error) {
                errorReporter.logError("Route change failure [" + fromState.name + " => " + toState.name + "]");
            });

            $window.onbeforeunload = function () {
                if (!window.debug && $rootScope.loggedIn && !$rootScope.changingSites) {
                    return xlatSvc.xlat("common.leaveAppMessage");
                }

                return undefined;
            };

            $rootScope.$on('$locationChangeStart', function (event) {
                //console.log("location change start:" + $location.path());        
                if ($rootScope.actualLocation !== $location.path()) {
                    if (!$rootScope.allowBack) {
                        event.preventDefault();
                        WindowFactory.closeLastWindow();
                    }
                } else {
                    //normal-way change of page (via link click)
                }

                $rootScope.allowBack = false;
            });

            $rootScope.goBack = function () {
                $rootScope.allowBack = true;
                window.history.back();
            };

            $rootScope.$watch(() => $location.path(), function (newLocation, oldLocation) {

                // we get here when the path is changed, but not changed via a successful location change

                //if a window is fullScreen minimise it. This may be call during initialisation so check is needed.
                if (WindowFactory.closeFullscreen) {
                    WindowFactory.closeFullscreen();
                }
                //console.log("path change: " + oldLocation + " => " + newLocation);
                $rootScope.actualLocation = newLocation;
            });
        }
    ]
);
app.config(['$stateProvider', '$urlRouterProvider', '$controllerProvider', '$compileProvider', '$filterProvider', '$provide', 'isMobile',
    function ($stateProvider, $urlRouterProvider, $controllerProvider, $compileProvider, $filterProvider, $provide, isMobile: boolean) {
            // lazy controller, directive and service
            app.controller = $controllerProvider.register;
            app.directive = $compileProvider.directive;
            app.filter = $filterProvider.register;
            app.factory = $provide.factory;
            app.service = $provide.service;
            app.constant = $provide.constant;
            app.value = $provide.value;

            //Change the url to lowercase
            $urlRouterProvider.rule(function ($injector, $location) {
                if (!$location.path().contains('resetpassword')) { // Ignore reset password or the base64 encoded token gets messed up
                    var path = $location.path(), normalized = path.toLowerCase();
                    if (path !== normalized) {
                        $location.replace().path(normalized);
                    }
                }
            });

            $urlRouterProvider.otherwise(isMobile ? '/mobile/landing' : '/landing');

            $stateProvider
                .state('app', {
                    abstract: true,
                    url: '',
                    template: tmplApp,
                    controller: "AppCtrl",
                    controllerAs: "vm"
                })
                .state("app.landing", {
                    url: "/landing",
                    views: {
                        templateUrl: 'app/templates/app.html',
                        "nav": {
                            templateUrl: "app/templates/nav_component.html"
                        },
                        "content": {
                            template: '<div ui-view class="fade-in-up flex-container-column flex"></div>'
                        }
                    }
                })
                .state("app.states", {
                    url: "/states",
                    views: {
                        template: tmplApp,
                        "nav-bar": { template: '<div></div>' },
                        "content": {
                            template: tmplStates,
                            controller: "statesCtrl",
                            controllerAs: "vm",
                        }
                    }
                })
                .state("app.diagnostic", {
                    url: "/diagnostic",
                    views: {
                        template: tmplApp,
                        "nav-bar": { template: '<div></div>' },
                        "content": {
                            template: tmplDiag,

                            controller: "DiagnosticCtrl",
                            controllerAs: "vm",
                        }
                    }
                })
                .state('mobile', {
                    abstract: true,
                    url: '/mobile',
                    template: tmplMobile,
                    controller: "AppCtrl",
                    controllerAs: "vm"
                })
                .state('app.site', {
                    url: '/site',
                    views: {
                        template: tmplApp,
                        "nav": { template: tmplNavSite },
                        "content": { template: '<div ui-view class="fade-in-up  flex-container-column flex"></div>' }
                    }
                })
                .state('app.admin', {
                    url: '/admin',
                    views: {
                        template: tmplApp,
                        "nav": { template: tmplNavSettings },
                        "content": { template: '<div ui-view class="fade-in-up  flex-container-column flex"></div>' }
                    }
                })
                .state('app.vehicle', {
                    url: '/vehicle',
                    views: {
                        template: tmplApp,
                        "nav": { template: tmplNavVehicle },
                        "content": { template: '<div ui-view class="fade-in-up flex-container-column flex"></div>' }
                    }
                })
                .state('app.component', {
                    url: '/component',
                    views: {
                        template: tmplApp,
                        "nav": { template: tmplNavComponent },
                        "content": { template: '<div ui-view class="fade-in-up flex-container-column flex"></div>' }
                    }
                })
                .state('app.reports', {
                    url: '/reports',
                    views: {
                        template: tmplApp,
                        "nav": { template: tmplNavReports },
                        "content": { template: '<div ui-view class="fade-in-up"></div>', controller: 'reportLandingCtrl' }
                    }
                })
                .state('app.session', {
                    url: '/session/:sessionKey',
                    views: {
                        template: tmplApp,
                        "nav": { template: "<div></div>" },
                        "content": {
                            template: '<div></div>',
                            controller: 'SessionCtrl'
                        }
                    }
                })
                .state('app.resetPassword', {
                    url: '/resetpassword/:resetToken',
                    views: {
                        template: tmplApp,
                        "nav": { template: '<div></div>' },
                        "content": {
                            template: '<div><div>',
                            controller: 'loginCtrl'
                        }
                    }
                });
        }
    ]
);

//AngularJS interceptor. There is an Angular one too hooked up in app.module.ts. Needs to be finished off
app.config(['$httpProvider',
    function ($httpProvider) {
        $httpProvider.interceptors.push('authInterceptor');
    }
]);
