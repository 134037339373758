/* Define all extention for js object in this file */

interface Date {
    format(format: string): string;
}

interface String {
    toDateTimeFormat(format: string): string;
}

interface StringConstructor {
    format(s: string, ...args: any[]): string;
}

interface Window {
    debug: boolean;
}

Array.prototype.removeItem = function (item) {
    var index = this.indexOf(item);
    if (index > -1) {
        this.splice(index, 1);
    }
    return this;
};

Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (o !== undefined && o !== null && k in o) {
            o = o[k];
        } else {
            return undefined;
        }
    }
    return o;
}

String.format = function (format: string, ...values: any[]) {
    return kendo.format(format, ...values);
};

String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.toDateTimeFormat = function (format) {
    return kendo.toString(new Date(this), format || "ddd MMM dd yyyy HH:mm:ss");
};


Date.prototype.format = function (format) {
    return kendo.toString(this, format || "ddd MMM dd yyyy HH:mm:ss");
};