/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * @hidden
 *
 * An injection token used by the expand-directive to interface with
 * the TreeView or the DropDownTree components.
 */
var ExpandableComponent = /** @class */ (function () {
    function ExpandableComponent() {
    }
    ExpandableComponent = tslib_1.__decorate([
        core_1.Injectable()
    ], ExpandableComponent);
    return ExpandableComponent;
}());
exports.ExpandableComponent = ExpandableComponent;
