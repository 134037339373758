/**
 * @ngdoc directive
 * @name amtFramework.filter.directive:amtFilterSection
 * @sortOrder 20
 * @restrict E
 * @requires amtFramework.filter.directive:amtFilter
 * 
 * @param {string} label Label to display above the control
 *
 * @description
 * Filter Section is used to create sections in amtFilter
 * 
 * @example
 * See {@link amtFramework.filter.directive:amtFilter amtFilter}
 */
//import angular from 'angular';
import tmpl from './amtFilterSection.html';

angular.module('amtFramework.filter').directive('amtFilterSection', function() {
        return {
            require: '^amtFilter',
            transclude: true,
            restrict: 'E',
            template: tmpl,
            controller: ['$scope', function() {}],
                scope: {
                    label: '@'
                },
            
            link: function () {
                //if this link is missing then it wont throw the required missing exception
            }
        };
});
