//import angular from 'angular';

class AppConfig implements IAppConfig {

    public readonly decimalSeparator = '.'
    public readonly thoudsandsSeparator = ','
    public readonly decimalPlaces = 2
    public readonly dateFormat = {
        shortDate: 'dd-MM-yyyy',
        shortDateTime: 'dd-MM-yy HH:mm',
        shortDateServer: 'yyyy/MM/dd',
        monthYear: 'MMM-yyyy',
        windowTitleDateTime: 'dd-MM-yyyy HH:mm',
        windowTitleDate: 'dd-MM-yy',
    }
    public readonly defaultTextIfEmpty = ''
    public readonly defaultNumberIfEmpty = 0
    public readonly defaultMaxTextLength = 35
    public readonly dateTime = {
        minValue: new Date(1970, 1, 1),
        maxValue: new Date(9998, 12, 31)
    }
    public readonly maxTextLength = 8000
    public readonly int_max = 100000
    public readonly defaultPageSize = 10
    public readonly pageSizes = [10, 25, 50, 100, 200]
}
angular.module("amtFramework.config").service("appConfig", AppConfig);