/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var utils_1 = require("./../utils");
var core_1 = require("@angular/core");
var selection_service_1 = require("./selection.service");
var utils_2 = require("../utils");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
/**
 * Represents the select-all checkbox feature of the Grid ([see example]({% slug selection_grid %}#toc-select-all-feature)).
 */
var SelectAllCheckboxDirective = /** @class */ (function () {
    function SelectAllCheckboxDirective(selectionService, el, renderer, ngZone) {
        var _this = this;
        this.selectionService = selectionService;
        this.el = el;
        this.renderer = renderer;
        this.ngZone = ngZone;
        /**
         * Fires when the user clicks the `kendoGridSelectAllCheckbox` select-all checkbox
         * ([see example]({% slug selection_grid %}#toc-select-all-feature)).
         */
        this.selectAllChange = new core_1.EventEmitter();
        this.type = 'checkbox';
        this.stateSet = false;
        this.ngZone.runOutsideAngular(function () {
            _this.destroyClick = _this.renderer.listen(_this.el.nativeElement, 'click', _this.onClick.bind(_this));
        });
    }
    SelectAllCheckboxDirective.prototype.ngAfterContentChecked = function () {
        this.setState();
    };
    SelectAllCheckboxDirective.prototype.ngOnChanges = function () {
        this.stateSet = true;
    };
    SelectAllCheckboxDirective.prototype.ngOnDestroy = function () {
        if (this.destroyClick) {
            this.destroyClick();
        }
    };
    /**
     * @hidden
     */
    SelectAllCheckboxDirective.prototype.onClick = function () {
        var _this = this;
        // yields consistent cross-browser behavior when clicking an indeterminate checkbox
        var undefinedCheckedStateInIE = utils_1.detectIE() && this.selectionService.selectAllState === undefined;
        var isChecked = undefinedCheckedStateInIE ? true : this.el.nativeElement.checked;
        var options = this.selectionService.options;
        var enabledAndMultiple = options.enabled && options.mode === 'multiple';
        var shouldEmitSelectAll = kendo_angular_common_1.hasObservers(this.selectAllChange);
        if (enabledAndMultiple || shouldEmitSelectAll) {
            this.ngZone.run(function () {
                if (enabledAndMultiple) {
                    _this.selectionService.updateAll(isChecked);
                }
                if (shouldEmitSelectAll) {
                    _this.selectAllChange.emit(isChecked ? 'checked' : 'unchecked');
                }
            });
        }
    };
    /**
     * @hidden
     */
    SelectAllCheckboxDirective.prototype.setState = function () {
        var state = this.stateSet ? this.stateToBool() : this.selectionService.selectAllState;
        var elem = this.el.nativeElement;
        this.renderer.setProperty(elem, 'indeterminate', !utils_2.isPresent(state));
        this.renderer.setProperty(elem, 'checked', utils_2.isPresent(state) ? state : false);
    };
    /**
     * @hidden
     */
    SelectAllCheckboxDirective.prototype.stateToBool = function () {
        switch (this.state) {
            case 'checked':
                return true;
            case 'unchecked':
                return false;
            default:
                return undefined;
        }
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], SelectAllCheckboxDirective.prototype, "state", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], SelectAllCheckboxDirective.prototype, "selectAllChange", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('attr.type'),
        tslib_1.__metadata("design:type", String)
    ], SelectAllCheckboxDirective.prototype, "type", void 0);
    SelectAllCheckboxDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoGridSelectAllCheckbox]'
        }),
        tslib_1.__metadata("design:paramtypes", [selection_service_1.SelectionService,
            core_1.ElementRef,
            core_1.Renderer2,
            core_1.NgZone])
    ], SelectAllCheckboxDirective);
    return SelectAllCheckboxDirective;
}());
exports.SelectAllCheckboxDirective = SelectAllCheckboxDirective;
