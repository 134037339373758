/**
 * @ngdoc directive
 * @name amtFramework.filter.directive:amtBooleanFilteritem
 * @sortOrder 20
 * @restrict E
 * @requires amtFramework.filter.directive:amtFilter
 * 
 * @param {string} label Label to display above the control
 * @param {string} propertyName Name of the property that is added to filterValues property of {@link amtFramework.filter.directive:amtFilter amtFilter}
 * @param {expression=} [isRequired=false] Required
 * @param {string=} defaultValue Sets the default value when loading
 * @param {callback=} onValueChange Callback that is called when the input changes
 * @param {boolean=} isAllowNull ???
 * 
 *
 * @description
 * Filter item that let's the user filter on a boolean value true or false. Only adds the value to filtervalues when the user selects a value.
 * 
 * @example
 * See {@link amtFramework.filter.directive:amtFilter amtFilter}
 */
//import angular from 'angular';
import tmpl from './amtBooleanFilteritem.html';

angular.module('amtFramework.filter').directive('amtBooleanFilteritem', function () {
            return {
                require: '^amtFilter',
                restrict: 'E',
                scope: {
                    label: '@',
                    propertyName: "@",
                    isRequired: '=',
                    defaultValue: '@',
                    onValueChange: '=',
                    isAllowNull: '@'
                },
                template: tmpl,
                link: function (scope: any, elem, attr, filterCtrl) {
                    
                    if (scope.defaultValue && JSON.parse(scope.defaultValue) === true) {
                        scope.isDefaultValueTrue = true;
                        scope.filterItem = { propertyName: scope.propertyName, value: scope.defaultValue };
                    } else if (scope.defaultValue && JSON.parse(scope.defaultValue) === false) {
                        scope.isDefaultValueFalse = true;
                        scope.filterItem = { propertyName: scope.propertyName, value: scope.defaultValue };
                    }
                    else{
                        scope.isDefaultValueUndefined = true;
                        scope.filterItem = { propertyName: scope.propertyName, value: "" };
                    }

                    filterCtrl.addFilterItem(scope.filterItem);

                    scope.$watch('filterItem.value', function (newValue, oldValue) {
                        if (scope.onValueChange) {
                            scope.onValueChange(newValue, oldValue);
                        }
                    }, true);
                }
            };
});
