//import angular from 'angular';
import AdminUrls from '../../../../admin/adminUrls';

    angular.module('app.admin').controller("vehicleApplicationSearchCtrl", vehicleApplicationSearchCtrl);

    // @ts-ignore
    vehicleApplicationSearchCtrl.$inject = ["adminUrls", "$scope", "enums", "amtXlatSvc", "WindowFactory", "ocConfigSvc", "exportSvc", "referenceDataUrls"];

    // ReSharper disable once InconsistentNaming
    function vehicleApplicationSearchCtrl(adminUrls: AdminUrls, $scope, enums, amtXlatSvc, WindowFactory, ocConfigSvc, exportSvc, referenceDataUrls) {

        var vm = this;

        vm.processing = false;

        vm.adminUrls = adminUrls;
        vm.referenceDataUrls = referenceDataUrls;

        vm.config = ocConfigSvc;

        vm.enums = enums;

        vm.criteria = {
            filterValues: {
                activeOptions: null,
                applicationIds: null
            }
        };

        vm.applicationFilterControl = {};

        vm.activeOptions = [{
            name: amtXlatSvc.xlat('common.yes_label'),
            key: true
        }, {
            name: amtXlatSvc.xlat('common.no_label'),
            key: false
        }];

        vm.status = enums.screenStatus.loading;

        vm.gridControl = {};
        vm.selectedItem = null;

        function init() {
            vm.loadDefaults();
        }

        vm.onSelectedItemChanged = function (items) {
            if (items != null && items.length === 1) {
                vm.selectedItem = items[0];
            } else {
                vm.selectedItem = null;
            }
        };

        vm.setHeaderHeight = function (height, offset) {
            vm.gridTop = height + offset;
        };

        vm.loadDefaults = function () {
            vm.defaults = {
                activeOptions: [vm.activeOptions[0]]
            };

            vm.status = enums.screenStatus.ready;
        };

        vm.export = function () {
            exportSvc.exportData(vm.referenceDataUrls.exportVehicleApplications, vm.criteria, amtXlatSvc.xlat('application.searchTitle'));
        };

        $scope.editApplication = function (item) {
            if (item) {
                WindowFactory.openItem({
                    component: 'add-edit-vehicle-application',
                    caption: amtXlatSvc.xlat("application.edit"),
                    initParams: item,
                    width: 550,
                    onDataChangeHandler: function () {
                        vm.refreshGrid();
                        vm.applicationFilterControl.search();
                    },
                    windowRelatedRecordId: item.id
                });
            }
        };

        $scope.addApplication = function () {
            WindowFactory.openItem({
                component: 'add-edit-vehicle-application',
                caption: amtXlatSvc.xlat("application.add"),
                initParams: null,
                width: 550,
                onDataChangeHandler: function () {
                    vm.refreshGrid();
                    vm.applicationFilterControl.search();
                },
                windowRelatedRecordId: uuid()
            });
        };

        vm.refreshGrid = function () {
            vm.gridControl.refresh();
        };

        init();
    }
