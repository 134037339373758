//import angular from 'angular';
import tmpl from './updateForecastOverride.html';


export class UpdateForecastOverrideModel {
    constructor(
        public forecastId: number,        
        public type: string,
        public value: number,
        public heading: string,

        public vehicleSpecificationId?: number,
        public componentSpecificationId?: number,

        public vehicleId?: number,
        public stockId?: number,

        public min?: number,
        public max?: number,
        public decimalPlaces?: number,
        public required?: boolean
    ) { }
}

export class UpdateForecastOverrideModelMaker {
    static create(obj: UpdateForecastOverrideModel) {
        return new UpdateForecastOverrideModel(
            obj.forecastId, obj.type, obj.value, obj.heading, obj.vehicleSpecificationId, obj.componentSpecificationId,
            obj.vehicleId, obj.stockId, obj.min, obj.max, obj.decimalPlaces, obj.required
        );
    }
}

    angular.module('app.site').component('updateForecastOverride', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ["$scope", "amtConstants", "amtCommandQuerySvc", "confirmSvc", "enums", "amtXlatSvc", "notifySvc", "WindowFactory", "errorReporter",
            function ($scope, amtConstants, amtCommandQuerySvc, confirmSvc, enums, amtXlatSvc, notifySvc, WindowFactory, errorReporter) {

                var vm = this;
                $scope.vm = this;

                vm.apiUrls = amtConstants.url;

                this.$onInit = function () {

                    vm.model = vm.wnd.initParams;

                    if (!(vm.model instanceof UpdateForecastOverrideModel)) {
                        throw new Error('Model must be of type UpdateForecastOverrideModel');
                    }
                   
                    // default min to 0 and decimal places to 0 if not set
                    if (!vm.model.min) vm.model.min = 0;
                    if (!vm.model.decimalPlaces) vm.model.decimalPlaces = 0;

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: 'common.save_label',
                            name: 'saveButton',
                            click: 'save',
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: true,
                            value: 'common.cancel_label',
                            name: 'cancelButton',
                            click: 'cancel',
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: true,
                            disabled: true
                        },
                        cancelButton: {
                            visible: true,
                            disabled: false
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.save,
                        cancel: vm.cancel
                    };
                };

                vm.save = function () {

                    vm.wnd.processing = true;

                    let criteria = {
                        forecastId: +vm.model.forecastId,
                        vehicleSpecificationId: vm.model.vehicleSpecificationId,
                        componentSpecificationId: vm.model.componentSpecificationId,
                        type: vm.model.type,
                        value: vm.model.value,
                        vehicleId: vm.model.vehicleId,
                        stockId: vm.model.stockId
                    };

                    amtCommandQuerySvc.post(vm.apiUrls.updateForecastOverride, criteria).then(function (response) {

                        notifySvc.success(amtXlatSvc.xlat("forecastDetails.successfullyUpdatedForecastOverride", vm.model.heading));

                        if (vm.wnd.onDataChanged) {
                            vm.wnd.onDataChanged();
                        }

                        WindowFactory.closeWindow(vm.wnd);

                    }).catch(function (error) {
                        errorReporter.logError(error);
                    }).finally(function () {
                        vm.wnd.processing = false;
                    });
                }

                vm.cancel = function () {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        vm.form.dirty = false;
                        WindowFactory.closeWindow(vm.wnd);
                    });
                }

                $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing'], function () {
                    vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.form.$pristine || !!vm.wnd.processing;
                });
            }
        ]
    });
