<!--Loading Page-->
<div class="k-loading-image" *ngIf="!hasReceivedResult"></div>

<!--Live Forecast Tyre Container-->
<ng-container *ngIf="hasReceivedResult && hasLiveForecast">

    <section class="search-container">
        <!--Filter Selection Dropdown List -->
        <kendo-dropdownlist #displaySelectionDropdownList
                            [data]="displaySelectionList"
                            [(ngModel)]="selectedDisplay"
                            (ngModelChange)="changeDisplay($event)">
        </kendo-dropdownlist>
        <div class="live-forecast-action-button"><p class="live-forecast-action-button-text" (click)="showLiveForecastTyreDialog()">{{ui.detail}}</p></div>
    </section>

    <!--Due Date-->
    <div class="widget-table-box">
        <div id="resp-table">
            <div id="resp-table-body">
                <!--Title-->
                <div class="resp-table-row">
                    <div class="table-body-title-cell"><b>{{ui.dueDate}}</b></div>
                    <div class="table-body-title-cell" align="right"><b>{{ui.quantity}}</b></div>
                </div>

                <!--Overdue-->
                <div class="resp-table-row">
                    <div class="table-body-cell">{{ui.overDue}}</div>
                    <div class="table-body-cell" align="right"><div [ngStyle]="{'color': (selectedGroupCount.overDue > 0 ? 'red' : 'black') }">{{selectedGroupCount.overDue}}</div></div>
                </div>

                <!--Due in Next 2 Weeks-->
                <div class="resp-table-row">
                    <div class="table-body-cell">{{ui.next2Weeks}}</div>
                    <div class="table-body-cell" align="right">{{selectedGroupCount.next2Weeks}}</div>
                </div>

                <!--Due in 2-4 Weeks-->
                <div class="resp-table-row">
                    <div class="table-body-cell">{{ui.next2To4Weeks}}</div>
                    <div class="table-body-cell" align="right">{{selectedGroupCount.next2To4Weeks}}</div>
                </div>

                <!--Due in 4-8 Weeks-->
                <div class="resp-table-row">
                    <div class="table-body-cell">{{ui.next4To8Weeks}}</div>
                    <div class="table-body-cell" align="right">{{selectedGroupCount.next4To8Weeks}}</div>
                </div>

                <!--Due in 8-12 Weeks-->
                <div class="resp-table-row">
                    <div class="table-body-cell">{{ui.next8To12Weeks}}</div>
                    <div class="table-body-cell" align="right">{{selectedGroupCount.next8To12Weeks}}</div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="hasReceivedResult && !hasLiveForecast">
    <div class="live-forecast-no-result">
        <p>{{ui.noResult}}</p>
    </div>
</ng-container>