//import angular from 'angular';


    angular.module("app").factory("stockCodesUrls", function () {
        var apiPath = baseUrl + 'api/';
        var controllerPath = 'admin/stockCode/';
        var componentController = 'equipment/component/';
        var equipmentControllerPath = 'equipment/common/';
        var referenceDataController = 'referenceData/';
        var systemhierarchyController = 'systemhierarchy/';

        return {
            //search
            search: apiPath + controllerPath + 'search',
            getSearchDefaults: controllerPath + 'getSearchDefaults', // default values for search
            searchExport: controllerPath + 'searchExport',
            // details
            getDetails: controllerPath + 'getDetails',
            updateDetails: controllerPath + 'updateDetails',
                    
            // reference data
            getClients: baseApiUrl + systemhierarchyController + 'getClients',
            getSites: baseApiUrl + systemhierarchyController + 'getSites',
            getLifeTypes: apiPath + controllerPath + 'getLifeTypes',
            getEquipmentLifeTypes: baseApiUrl + referenceDataController + 'getEquipmentLifeTypes',
            getComponentTypes: baseApiUrl + controllerPath + 'getComponentTypes',
            //getSpecifications: baseApiUrl + equipmentControllerPath + 'getSpecifications',
            getSpecifications: baseApiUrl + componentController + 'getSpecifications',

            //Updates
            modifyStockCode: controllerPath + 'modify',
            addStockCode: controllerPath + 'add',

            //Delete
            deleteStockCode: controllerPath + 'delete'
        };
    });
