//import angular from 'angular';
import FileManagement from '../../../services/fileManagement';
import tmpl from './attachmentDetails.html';



class AttachmentDetailsCtrl implements ng.IController, IWindowController {

    form: ng.IFormController;
    wnd: IWindowObj;

    initParams: any = {};

    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: (button: string) => void }

    attachment: IFile;

    acceptedFileTypes: string[];

    showCameraIcon = false;
    hasFile = false;

    static $inject = ['$scope', 'amtXlatSvc', 'confirmSvc', 'WindowFactory', '$stateParams', 'fileManagement'];

    constructor(private $scope: ng.IScope, private amtXlatSvc: IAmtXlatSvc, private confirmSvc: IConfirmSvc,
        private WindowFactory: IWindowFactory, private $stateParams: ng.ui.IStateParamsService, private fileManagement: FileManagement) {

        this.$scope.$watch(() => this.form.$dirty, () => this.wnd.isDirty = this.form.$dirty);

        this.$scope.$watchGroup([() => this.form.$valid, () => this.form.$pristine, () => this.wnd.processing, () => this.attachment], () => {
            this.enableDisableButtons();
        });
    }

    $onInit() {

        this.wnd.processing = true;
        this.wnd.onClose = () => this.closeWindow();

        if (this.$stateParams && this.$stateParams.initParams)
            this.initParams = this.$stateParams.initParams;

        this.attachment = this.initParams.attachment ? angular.copy(this.initParams.attachment) : this.fileManagement.emptyFile();
        this.acceptedFileTypes = this.initParams.acceptedFileTypes || this.fileManagement.getAcceptedFileExtensions();
        this.showCameraIcon = this.initParams.showCameraIcon;

        if (this.initParams.attachment)
            this.hasFile = true;

        this.WindowFactory.addButton(this, 'common.save_label', 'saveButton', () => this.save(false), true);
        this.WindowFactory.addButton(this, 'common.saveAndAddAnother_label', 'saveAndNewButton', () => this.save(true), false);

        this.wnd.processing = false;
    }

    enableDisableButtons() {
        this.buttonStates.saveButton.disabled = this.form.$invalid || this.form.$pristine || this.wnd.processing || !(this.attachment.persisted || !!this.attachment.data);
        this.buttonStates.saveAndNewButton.disabled = this.buttonStates.saveButton.disabled;
    }

    save(reset: boolean) {        

        if (this.wnd.onDataChanged)
            this.wnd.onDataChanged(this.attachment);

        this.form.$setPristine();

        if (reset) {

            // reset the attachment
            this.attachment = this.fileManagement.emptyFile();

            // switch the caption to new (in case it was edit)
            this.wnd.caption = this.amtXlatSvc.xlat('equipment.newAttachment');
        } else {
            this.closeWindow();
        }
    }

    fileSelected() {
        this.hasFile = true;
        this.form.$setDirty();
    }

    async closeWindow() {

        try {
            await this.confirmSvc.confirmSaveChange(this.form.$dirty);
        } catch {
            return; // they cancelled
        }

        this.form.$setPristine();
        this.WindowFactory.closeWindow(this.wnd);
    }
}

class AttachmentDetailsComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '<',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = AttachmentDetailsCtrl;
    public controllerAs = 'vm';
}

angular.module('app.component').component('attachmentDetailsPopup', new AttachmentDetailsComponent());