//import angular from 'angular';


    angular.module('app.admin').factory('locationSvc',
        [
            'amtXlatSvc', 'WindowFactory', 'amtCommandQuerySvc', 'exportSvc', 'ocConfigSvc', '$q',
            function (
                amtXlatSvc, WindowFactory: IWindowFactory, amtCommandQuerySvc, exportSvc, ocConfigSvc, $q
            ) {
                var svc = this;

                svc.mainPermission = "Security.Settings.Location";

                svc.baseUrl = 'admin/location/';
                svc.baseAPIUrl = baseApiUrl + svc.baseUrl;

                svc.searchUrl = svc.baseAPIUrl + 'search';
                svc.getStatusesUrl = svc.baseAPIUrl + 'getStatuses';

                svc.searchAsync = function (params) {
                    return amtCommandQuerySvc.post(svc.baseUrl + 'search', params);
                };

                svc.getSearchDefaultsAsync = function (params) {
                    return amtCommandQuerySvc.post(svc.baseUrl + 'getSearchDefaults', params);
                };

                svc.getAsync = function (id) {
                    return $q(function (resolve, reject) {
                        amtCommandQuerySvc.post(svc.baseUrl + "getDetails", { id: id }).then(resolve, reject);
                    });
                };

                svc.saveAsync = function (params) {
                    return amtCommandQuerySvc.post(svc.baseUrl + 'save', params);
                };

                svc.export = function (params) {
                    exportSvc.exportData(svc.baseUrl + 'export', params, amtXlatSvc.xlat('location.searchExportFilename'));
                };

                svc.reset = function () {
                    return {
                        activeFrom: new Date(),
                        activeTo: null,
                        clientId: ocConfigSvc.user.client.id,
                        siteId: ocConfigSvc.user.site.id,
                        statuses: [],
                        statusIds: [],
                        name: '',
                        description: '',
                        id: null
                    };
                };

                svc.create = function (callback) {
                    return svc.openItem(svc.reset(), callback, amtXlatSvc.xlat('location.create'));
                };

                svc.openItem = function (item, callback, title) {
                    WindowFactory.openItem({
                        component: 'location-details',
                        caption: title,
                        initParams: item,
                        canClose: true,
                        width: 800,
                        modal: false,
                        onDataChangeHandler: callback,
                        windowRelatedRecordId: !!item.id ? item.id : uuid()
                    });
                };

                svc.getActiveStates = function () {
                    return [
                        {
                            key: true,
                            description: amtXlatSvc.xlat('location.activeTrue'),
                            default: true
                        },
                        {
                            key: false,
                            description: amtXlatSvc.xlat('location.activeFalse'),
                            default: false
                        }
                    ];
                };

                svc.activeStates = svc.getActiveStates();

                return svc;
            }
        ]
    );
