/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var utils_1 = require("../common/utils");
var constants_1 = require("./constants");
var utils_2 = require("./utils");
/**
 * @hidden
 */
var ColorContrastSvgComponent = /** @class */ (function () {
    function ColorContrastSvgComponent() {
        this.hostClass = true;
    }
    ColorContrastSvgComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.metrics = this.wrapper.getBoundingClientRect();
        this.oldA = this.hsva.value.a;
        this.oldH = this.hsva.value.h;
        this.hsva.subscribe(function (value) {
            if (value.h !== _this.oldH || value.a !== _this.oldA) {
                _this.oldH = value.h;
                _this.oldA = value.a;
                _this.setPaths();
            }
        });
    };
    ColorContrastSvgComponent.prototype.ngOnChanges = function (changes) {
        if (utils_1.isPresent(changes.backgroundColor) && this.metrics) {
            this.setPaths();
        }
    };
    ColorContrastSvgComponent.prototype.setPaths = function () {
        var bezierCommandCalc = utils_2.bezierCommand(utils_2.controlPoint(utils_2.line));
        this.paths = [utils_2.svgPath(this.getPaths(constants_1.AA_RATIO, constants_1.STEP_COUNT), bezierCommandCalc),
            utils_2.svgPath(this.getPaths(constants_1.AA_RATIO, constants_1.STEP_COUNT, true), bezierCommandCalc),
            utils_2.svgPath(this.getPaths(constants_1.AAA_RATIO, constants_1.STEP_COUNT), bezierCommandCalc),
            utils_2.svgPath(this.getPaths(constants_1.AAA_RATIO, constants_1.STEP_COUNT, true), bezierCommandCalc)];
    };
    ColorContrastSvgComponent.prototype.findValue = function (contrast, saturation, low, high, comparer) {
        var mid = (low + high) / 2;
        var hsva = Object.assign({}, this.hsva.value, { s: saturation / this.metrics.width, v: 1 - mid / this.metrics.height });
        var currentContrast = utils_2.getContrastFromTwoRGBAs(utils_2.getRGBA(utils_2.getColorFromHSV(hsva)), utils_2.getRGBA(this.backgroundColor || ''));
        if (low + 0.5 > high) {
            if (currentContrast < contrast + 1 && currentContrast > contrast - 1) {
                return mid;
            }
            else {
                return null;
            }
        }
        if (comparer(currentContrast, contrast)) {
            return this.findValue(contrast, saturation, low, high - (high - low) / 2, comparer);
        }
        return this.findValue(contrast, saturation, low + (high - low) / 2, high, comparer);
    };
    ColorContrastSvgComponent.prototype.getPaths = function (contrast, stepCount, reversed) {
        if (reversed === void 0) { reversed = false; }
        var points = [];
        for (var i = 0; i <= this.metrics.width; i += this.metrics.width / stepCount) {
            var value = this.findValue(contrast, i, 0, this.metrics.height, reversed ? (function (a, b) { return a < b; }) : (function (a, b) { return a > b; }));
            if (value !== null) {
                points.push([i, value]);
            }
        }
        return points;
    };
    tslib_1.__decorate([
        core_1.HostBinding('class.k-color-contrast-svg'),
        tslib_1.__metadata("design:type", Boolean)
    ], ColorContrastSvgComponent.prototype, "hostClass", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], ColorContrastSvgComponent.prototype, "wrapper", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", rxjs_1.BehaviorSubject)
    ], ColorContrastSvgComponent.prototype, "hsva", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ColorContrastSvgComponent.prototype, "backgroundColor", void 0);
    ColorContrastSvgComponent = tslib_1.__decorate([
        core_1.Component({
            selector: '[kendoColorContrastSvg]',
            template: "\n        <svg:path *ngFor=\"let path of paths\" [attr.d]=\"path\" fill=\"none\" stroke=\"white\" stroke-width=\"1\"></svg:path>\n    "
        })
    ], ColorContrastSvgComponent);
    return ColorContrastSvgComponent;
}());
exports.ColorContrastSvgComponent = ColorContrastSvgComponent;
