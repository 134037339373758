//import angular from 'angular';
import tmpl from './ocCostAccess.html';

    angular.module('app.directives')
        .directive('ocCostAccess', ['ocSecuritySvc', function (ocSecuritySvc) {
            return {
                restrict: 'E',
                replace: false,
                transclude: true,
                template: tmpl,
                scope: {
                    requiredLevel: "@",
                    costReadOnly: "=?"
                },
                link: function (scope: any) {
                    scope.hasAccess = false;

                    // default requiredLevel to readOnly access type (if not provided)
                    scope.requiredLevel = scope.requiredLevel || ocSecuritySvc.accessTypes.readOnly;

                    try {
                        scope.costReadOnly = ocSecuritySvc.getCostAccessType() === ocSecuritySvc.accessTypes.readOnly;
                    } catch {
                        scope.costReadOnly = false;
                    }

                    // whenever the required level changes, re-evaluate the users access
                    scope.$watch('requiredLevel', function (newValue) {
                        try {
                            scope.hasAccess = ocSecuritySvc.getCostAccessType() >= ocSecuritySvc.accessTypes[newValue || 'readOnly'];
                        } catch {
                            scope.hasAccess = false;
                        }
                    });
                }
            };
        }]);
