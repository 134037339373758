//import angular from 'angular';
import * as _ from 'underscore';
import OcDateSvc from '../../../../../services/ocDateSvc';
import { OcDynamicGridColumnMaker, OcDynamicGridColumnType, OcDynamicGridAlignment } from '../../../../../directives/ui/ocDynamicGrid';
import tmpl from "./editOrdersPopup.html";


    angular.module("app.site").component("editOrdersPopup", {
        bindings: {
            initParams: "=",
            buttonMethods: "=",
            buttonStates: "=",
            buttons: "=",
            closeOnSave: "=",
            wnd: "="
        },
        template: tmpl,
        controller: ["$scope", "confirmSvc", "WindowFactory", "notifySvc", "amtXlatSvc", "errorReporter", "ocConfigSvc",
            "ocDateSvc", "enums", "amtConstants", "amtCommandQuerySvc",
            function ($scope, confirmSvc, WindowFactory, notifySvc, xlatSvc, errorReporter, ocConfigSvc,
                ocDateSvc: OcDateSvc, enums, amtConstants, amtCommandQuerySvc) {

                var vm = this;
                $scope.vm = this;

                vm.apiUrls = amtConstants.url;
                vm.config = ocConfigSvc;                

                vm.columns = [];

                this.$onInit = function () {

                    vm.forecastId = vm.initParams.forecastId;
                    vm.forecastStartDate = vm.initParams.forecastStartDate;
                    vm.forecastEndDate = vm.initParams.forecastEndDate;

                    // set up the columns

                    // specification
                    vm.columns.push(OcDynamicGridColumnMaker.create({
                        headerValue: vm.initParams.specification,
                        type: OcDynamicGridColumnType.date,
                        valuePath: 'expectedDeliveryDate',
                        colSpan: 6
                    }));

                    // existing purchase orders
                    vm.columns.push(OcDynamicGridColumnMaker.create({
                        headerResource: 'forecastOrdersEdit.existingPurchaseOrders',
                        type: OcDynamicGridColumnType.string,
                        headerAlignment: OcDynamicGridAlignment.center,
                        columnAlignment: OcDynamicGridAlignment.center,
                        valuePath: 'purchaseOrders',
                        colSpan: 3
                    }));

                    // additional deliveries
                    vm.columns.push(OcDynamicGridColumnMaker.create({
                        headerResource: 'forecastOrdersEdit.additionalDeliveries',
                        type: OcDynamicGridColumnType.number,
                        minValue: 0,
                        decimalPlaces: 0,
                        headerAlignment: OcDynamicGridAlignment.center,
                        editable: 'true',
                        required: true,
                        valuePath: 'additionalDeliveries',
                        colSpan: 3
                    }));

                    // set the orders (ordered by date)
                    vm.orders = _.sortBy(vm.initParams.orders, o => o.expectedDeliveryDate);

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: "common.save_label",
                            name: "saveButton",
                            click: "save",
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: false,
                            value: "common.add_label",
                            name: "addButton",
                            click: "addOrder",
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: true,
                            value: "common.cancel_label",
                            name: "cancelButton",
                            click: "cancel",
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: vm.orders && vm.orders.length > 0,
                            disabled: true
                        },
                        addButton: {
                            visible: true,
                            disabled: false
                        },
                        cancelButton: {
                            visible: true,
                            disabled: false
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.save,
                        addOrder: vm.addOrder,
                        cancel: vm.onCancel
                    };

                    vm.wnd.onClose = vm.onCancel;
                };

                // local functions
                vm.save = function () {

                    var model = {
                        componentType: vm.initParams.componentType,
                        forecastId: vm.forecastId,
                        specificationId: vm.initParams.specificationId,
                        orders: []
                    };

                    _.each(vm.orders, function (o) {
                        model.orders.push({
                            expectedDeliveryDate: ocDateSvc.removeLocalTimeZoneOffset(o.expectedDeliveryDate),
                            quantity: +o.additionalDeliveries
                        });
                    });

                    vm.wnd.processing = true;

                    amtCommandQuerySvc.post(vm.apiUrls.createUpdateOrders, model).then(function (response) {

                        notifySvc.success(xlatSvc.xlat("forecastOrdersEdit.ordersUpdatedSuccessfully"));

                        if (vm.wnd.onDataChanged) {
                            vm.wnd.onDataChanged();
                        }

                        WindowFactory.closeWindow(vm.wnd);

                    }).catch(function (error) {
                        errorReporter.logError(error);
                    }).finally(function () {
                        vm.wnd.processing = false;
                    });

                };

                vm.addOrder = function () {

                    var existingOrderDates = _.map(vm.orders, o => o.expectedDeliveryDate);

                    WindowFactory.openItem({
                        component: 'order-create-popup',
                        canMinimise: false,
                        modal: true,
                        canClose: false,
                        width: 700,
                        caption: xlatSvc.xlat('forecastOrdersEdit.addOrderPopupTitle'),
                        initParams: {
                            specificationId: vm.initParams.specificationId,
                            specification: vm.initParams.specification,
                            componentType: vm.initParams.componentType,
                            forecastId: vm.forecastId,
                            forecastStartDate: vm.forecastStartDate,
                            forecastEndDate: vm.forecastEndDate,
                            month: vm.initParams.month,
                            existingOrderDates: existingOrderDates
                        },
                        onDataChangeHandler: function (order) {    
                            
                            vm.wnd.processing = true;

                            // add the new order to the collection
                            vm.orders.push(order);

                            // re-sort
                            vm.orders = _.sortBy(vm.orders, o => o.expectedDeliveryDate);

                            vm.wnd.processing = false;

                            // reload the orders view/edit grid to reflect the new total
                            if (vm.wnd.onDataChanged) {
                                vm.wnd.onDataChanged();
                            }
                        }
                    });

                };

                vm.onCancel = function () {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        vm.form.dirty = false;
                        WindowFactory.closeWindow(vm.wnd);
                    });
                };

                $scope.$watchGroup(['vm.form.$invalid', 'vm.wnd.processing', 'vm.form.$pristine'], function () {
                    vm.buttonStates.saveButton.disabled = !vm.form || vm.form.$invalid || vm.form.$pristine || !!vm.wnd.processing;
                    vm.buttonStates.addButton.disabled = vm.wnd.processing;
                });

                // calculate total orders for display
                $scope.$watch('vm.orders', function () {
                    vm.totalOrders = _.reduce(vm.orders, function (total, item) {
                        return total + (!item.purchaseOrders ? 0 : +item.purchaseOrders) + (!item.additionalDeliveries ? 0 : +item.additionalDeliveries);
                    }, 0);

                    vm.buttonStates.saveButton.visible = vm.orders && vm.orders.length > 0;
                }, true);
            }
        ]
    });
