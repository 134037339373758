/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var filter_operator_base_1 = require("./filter-operator.base");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
/**
 * Represents the `DoesNotContain` (**Does not contain**) filter operator.
 *
 * For more information and examples, refer to:
 * * [Setting the default filter operators]({% slug builtinfiltertemplate_grid %}#toc-setting-the-default-filter-operator)
 * * [Setting the order of the filter operators]({% slug builtinfiltertemplate_grid %}#toc-setting-the-order-of-the-filter-operators)
 */
var DoesNotContainFilterOperatorComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DoesNotContainFilterOperatorComponent, _super);
    function DoesNotContainFilterOperatorComponent(localization) {
        return _super.call(this, "doesnotcontain", localization) || this;
    }
    DoesNotContainFilterOperatorComponent_1 = DoesNotContainFilterOperatorComponent;
    var DoesNotContainFilterOperatorComponent_1;
    DoesNotContainFilterOperatorComponent = DoesNotContainFilterOperatorComponent_1 = tslib_1.__decorate([
        core_1.Component({
            providers: [
                {
                    provide: filter_operator_base_1.FilterOperatorBase,
                    useExisting: core_1.forwardRef(function () { return DoesNotContainFilterOperatorComponent_1; })
                }
            ],
            selector: 'kendo-filter-not-contains-operator',
            template: ""
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService])
    ], DoesNotContainFilterOperatorComponent);
    return DoesNotContainFilterOperatorComponent;
}(filter_operator_base_1.FilterOperatorBase));
exports.DoesNotContainFilterOperatorComponent = DoesNotContainFilterOperatorComponent;
