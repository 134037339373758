//import angular from 'angular';
import * as _ from 'underscore';
import { TMaintenanceSessionEvents_Api, TMaintenanceSession, TMaintenanceSession_Event } from './maintenanceCtrl'
import BrowserSvc from '../../../services/browserSvc';
import tmpl from './Events.html';



class MaintenanceEventsCtrl implements ng.IController {

    form: ng.IFormController;

    eventsGrid: IGridControl = {};
    actionWnd: IWindowObj;

    doneFirst: boolean;    
    loadingSchematic: boolean;
    readOnly: boolean;

    vehicle: any;
    session: TMaintenanceSession;

    changeCount = 0;

    schematicClickOff: any;

    reloadData?: Function;
    svgReset: Function;
    save: Function;
    updateChangeCount: Function;
    processing: boolean;

    active: Function; // used to indicate the tab is visible

    api: TMaintenanceSessionEvents_Api;

    static $inject = ['$scope', 'dataBroker', 'errorReporter', 'WindowFactory', '$timeout', '$uibModal',
        'ocConfigSvc', 'confirmSvc', 'helperSvc', 'notifySvc', 'amtXlatSvc', 'amtConstants', 'browserSvc'];

    constructor(private $scope: ng.IScope, private dataBroker: IDataBroker, private errorReporter: IErrorReporter,
        private WindowFactory: IWindowFactory, private $timeout: ng.ITimeoutService, private $uibModal: ng.ui.bootstrap.IModalService,
        private ocConfigSvc: IOcConfigSvc, private confirmSvc: IConfirmSvc, private helperSvc: IHelperSvc,
        private notifySvc: INotifySvc, private amtXlatSvc: IAmtXlatSvc, private amtConstants: IAmtConstants, private browserSvc: BrowserSvc) {

        this.$scope['showComponentDetails'] = (item, idx) => this.showComponentDetails(item, idx);
        this.$scope['selectActionClick'] = (item) => this.selectActionClick(item);
        this.$scope['editItem'] = (id, editDetail) => this.editItem(id, editDetail);
        this.$scope['undo'] = (event) => this.undo(event);
        this.$scope['refit'] = (data) => this.refit(data);
        this.$scope['stripAssembly'] = (data) => this.stripAssembly(data);

        this.schematicClickOff = this.$scope.$on('PositionClickedInt', (event, position, segment) => this.onPositionClick(event, position, segment));
    }

    $onInit() {

        this.loadingSchematic = !this.vehicle;
        
        this.api.gridRefresh = () => {
            if (this.eventsGrid && this.eventsGrid.refresh)
                this.eventsGrid.refresh();
        };

        this.api.gridRebind = () => {
            if (this.eventsGrid && this.eventsGrid.rebind)
                this.eventsGrid.rebind();
        };

        this.api.refit = (data, returnWindow?) => this.refit(data, returnWindow);
        this.api.stripAssembly = (data) => this.stripAssembly(data);

        if (!this.session.events)
            this.session.events = { items: [] };

        for (let item of this.session.events.items) {
            item.eventData.typeTranslated = this.amtXlatSvc.xlat('maintenanceSession.' + item.eventData.type.toLowerCase());
        }
    }

    $onDestroy() {
        this.schematicClickOff();
    }

    showComponentDetails(item, idx) {

        let component = null;

        if (item.eventData.components.length === 1) {
            component = item.eventData.components[0];
        } else if (item.eventData.components.length > 1) {
            component = item.eventData.components[idx];
        }

        if (component) {
            this.WindowFactory.openItem({
                component: 'component-details',
                caption: this.amtXlatSvc.xlat('equipment.open' + component.type, component.serialNumber),
                windowRelatedRecordId: component.equipmentId || component.componentId,
                isMobile: this.browserSvc.isMobile,
                initParams: {
                    equipmentId: component.equipmentId || component.componentId,
                    componentType: component.type,
                    serialNumber: component.serialNumber,
                    showCloseOnSave: false
                },
                width: 800,
                height: 850
            });
        }
    }

    async editFit(fitDetails) {
        try {
            if (this.browserSvc.isMobile) {
                return await this.dataBroker.saveMaintenanceSession(this.session);
            } else {
                return await this.dataBroker.msFitEvent(fitDetails, this.session);
            }
        } catch (error) {
            this.errorReporter.logError(error);
        }
    }

    async editRemove(removeDetails) {
        try {
            if (this.browserSvc.isMobile) {
                return await this.dataBroker.saveMaintenanceSession(this.session);
            } else {
                return await this.dataBroker.msRemoveEvent(removeDetails, this.session);
            }
        } catch (error) {
            this.errorReporter.logError(error);
        }
    }

    // when cliking the 'Edit' action for an event
    async editItem(id, editDetail) {

        if (this.actionWnd && this.actionWnd.open)
            return;

        let data = this.session.events.items.find(i => i.id === id);

        data.editDetail = editDetail;

        data.eventData.edit = true;
        data.eventData.sessionDate = this.session.checkin.checkinDate;

        let editData = {
            position: data.position,
            schematic: this.vehicle.schematic,
            type: (data.eventData.type || data.type).toLowerCase(),
            data: data.eventData,
            sequenceDate: data.sequenceDate
        };

        if (editData.type === EquipmentTypeName.assembly)
            data.editDetail = true;

        let pos = data.eventData.selectedPosition && data.eventData.selectedPosition.label ? data.eventData.selectedPosition : data.position;

        // if edit of a fit event and desktop and event exists in the db (and they haven't selected the 'edit fitment' option from the edit fitment modal)
        if (data.eventData.event !== MaintenanceSession_EventType.remove && !data.editDetail && !this.browserSvc.isMobile && !(this.session.errorList && this.session.errorList.length) && !this.session.heldInError) {

            this.actionWnd = this.WindowFactory.openItem({
                component: 'edit-fitment',
                caption: this.amtXlatSvc.xlat('maintenanceSession.editFitment'),
                canClose: false,
                initParams: {
                    data: editData,
                    events: this.session.events.items,
                    vehicle: this.session.vehicle
                },
                width: 650,
                modal: true,
                isMobile: this.browserSvc.isMobile,
                onDataChangeHandler: async (result) => {

                    let reload = false;
                    let positionsToUpdate = [editData.position.id];

                    // user selected 'edit' option, so re-run editItem but this time open the fitment window instead of the edit-fitment window
                    if (result.editDetail) {

                        this.actionWnd = null;
                        this.editItem(id, true);
                        return;

                    } else if (result.position) { // swap                            

                        let adjustedType = editData.type.charAt(0).toUpperCase() + editData.type.slice(1);

                        let sourcePosition = this.session.vehicle.positions[editData.position.id];
                        let targetPosition = this.session.vehicle.positions[result.position.positionId];

                        positionsToUpdate.push(result.position.positionId);

                        // swap the 'Fitment' values                            
                        let sourceComponentFitment = angular.copy(sourcePosition[adjustedType + 'Fitment']);
                        let targetComponentFitment = angular.copy(targetPosition[adjustedType + 'Fitment']);

                        sourcePosition[adjustedType + 'Fitment'] = targetComponentFitment;
                        targetPosition[adjustedType + 'Fitment'] = sourceComponentFitment;

                        // swap the values in the fitments collection      
                        for (let axle of this.session.vehicle.schematic.axles) {
                            for (let pos of axle.positions) {

                                if (pos.id === editData.position.id)
                                    sourcePosition = pos;

                                if (pos.id === result.position.positionId)
                                    targetPosition = pos;
                            }
                        }

                        let sourceFitmentIndex = sourcePosition.fitments.findIndex(f => f.type === adjustedType);
                        let sourceFitment = angular.copy(sourcePosition.fitments[sourceFitmentIndex]);

                        let targetFitmentIndex = targetPosition.fitments.findIndex(f => f.type === adjustedType);
                        let targetFitment = angular.copy(targetPosition.fitments[targetFitmentIndex]);

                        sourcePosition.fitments[sourceFitmentIndex] = targetFitment;
                        targetPosition.fitments[targetFitmentIndex] = sourceFitment;

                        // swap the events
                        let sourceEventIndex = this.session.events.items.findIndex(e => e.id === data.id);
                        let sourceEventData = angular.copy(this.session.events.items[sourceEventIndex].eventData.components[0]);

                        let targetEvent = _.sortBy(this.session.events.items.filter(i =>
                            i.eventData.components[0].equipmentId === targetFitment.equipmentId &&
                            i.position.id === result.position.positionId &&
                            i.eventData.event === MaintenanceSession_EventType.fit
                        ), s => s.sequenceDate).reverse()[0];

                        let targetEventIndex = this.session.events.items.findIndex(i => i.id === targetEvent.id);
                        let targetEventData = angular.copy(this.session.events.items[targetEventIndex].eventData.components[0]);

                        this.session.events.items[sourceEventIndex].eventData.components[0] = targetEventData;
                        this.session.events.items[sourceEventIndex].eventData.components[0].eventId = sourceEventData.eventId; // don't change event id
                        this.session.events.items[sourceEventIndex].eventData.serialNumber = targetEventData.serialNumber;

                        this.session.events.items[targetEventIndex].eventData.components[0] = sourceEventData;
                        this.session.events.items[targetEventIndex].eventData.components[0].eventId = targetEventData.eventId; // don't change event id
                        this.session.events.items[targetEventIndex].eventData.serialNumber = sourceEventData.serialNumber;

                        reload = true;

                    } else if (result.component) { // change component

                        let adjustedType = editData.type.charAt(0).toUpperCase() + editData.type.slice(1);

                        let sourcePosition;

                        for (let axle of this.session.vehicle.schematic.axles) {
                            for (let position of axle.positions) {
                                if (position.id === editData.position.id)
                                    sourcePosition = position;
                            }
                        }

                        if (sourcePosition) {

                            let position = this.session.vehicle.positions[editData.position.id];
                            let positionFitmentIndex = position.fitments.findIndex(f => f.componentId === position[adjustedType + 'Fitment'].id);

                            position[adjustedType + 'Fitment'].componentId = result.component.id;
                            position[adjustedType + 'Fitment'].id = result.component.id;

                            position.fitments[positionFitmentIndex].id = result.component.id;
                            position.fitments[positionFitmentIndex].equipmentId = result.component.id;
                            position.fitments[positionFitmentIndex].serialNumber = result.component.serialNumber;

                            let sourceFitmentIndex = sourcePosition.fitments.findIndex(f => f.type === adjustedType);

                            // set the new component id and serial number for the position                                
                            sourcePosition.fitments[sourceFitmentIndex].id = result.component.id;
                            sourcePosition.fitments[sourceFitmentIndex].equipmentId = result.component.id;
                            sourcePosition.fitments[sourceFitmentIndex].serialNumber = result.component.serialNumber;

                            // update the component id and serial number on the event
                            let sourceEventIndex = this.session.events.items.map(e => e.id).indexOf(data.id);

                            this.session.events.items[sourceEventIndex].eventData.components[0].equipmentId = result.component.id;
                            this.session.events.items[sourceEventIndex].eventData.components[0].serialNumber = result.component.serialNumber;
                            this.session.events.items[sourceEventIndex].eventData.serialNumber = result.component.serialNumber;

                            reload = true;
                        }
                    }

                    if (reload) {

                        try {
                            // update fitment data
                            await this.dataBroker.updateFitmentData(this.session, positionsToUpdate);

                            // update the reading Grid UI.
                            this.reloadData();


                            // refresh the events grid
                            if (this.eventsGrid && this.eventsGrid.refresh)
                                this.eventsGrid.refresh();

                            this.svgReset();

                            // update the fitted tab
                            this.session.vehicle.updated = uuid();
                        } finally {
                            this.WindowFactory.closeWindow(this.actionWnd);
                        }
                    } else {
                        this.WindowFactory.closeWindow(this.actionWnd);
                    }
                }
            });

        } else {

            data.editDetail = false;

            this.actionWnd = this.WindowFactory.openItem({
                component: data.eventData.event === MaintenanceSession_EventType.remove ? 'remove-component' : 'fit-component',
                caption: String.format('{0} {1} - {2} {3}', this.amtXlatSvc.xlat('maintenanceSession.editPosition'), pos.label, this.amtXlatSvc.xlat('maintenanceSession.' + data.eventData.event), this.amtXlatSvc.xlat('maintenanceSession.event')),
                initParams: editData,
                allowOverflow: false,
                canClose: false,
                isMobile: this.browserSvc.isMobile,
                width: 700,
                top: this.browserSvc.isMobile ? this.amtConstants.windowTopMarginMobile : null,
                modal: true,
                onDataChangeHandler: async (editedEventData) => {

                    this.actionWnd.processing = true;
                    this.session.updating = true;

                    if (editedEventData && !editedEventData[0])
                        editedEventData = [editedEventData];

                    delete editedEventData.schematic;

                    let submitData = {
                        eventData: editedEventData
                    };

                    // ok this is an update of the event. 
                    if (data.eventData.event === MaintenanceSession_EventType.remove) {

                        try {
                            let events = await this.editRemove(submitData);

                            let originalEventIndex = this.session.events.items.findIndex(e => e.id === data.id);

                            if (originalEventIndex > -1) {

                                editedEventData[0].eventDescription = this.amtXlatSvc.xlat('maintenanceSession.eventType_remove', editedEventData[0].selectedMoveTo.description);

                                if (events && Array.isArray(events) && events.length) {

                                    for (let c = 0; c < editedEventData[0].components.length; c++) {
                                        editedEventData[0].components[c].eventId = events[c].equipmentEventId;
                                    }

                                    editedEventData[0].events = events;
                                }

                                this.session.events.items[originalEventIndex].eventData = editedEventData[0];
                            }

                            this.reloadData(); // update the reading Grid UI.

                            if (this.eventsGrid && this.eventsGrid.refresh)
                                this.eventsGrid.refresh();

                            this.WindowFactory.closeWindow(this.actionWnd);

                        } catch (error) {
                            this.errorReporter.logError(error);
                        } finally {
                            this.actionWnd.processing = false;
                            this.session.updating = false;
                            if (this.eventsGrid && this.eventsGrid.refresh)
                                this.eventsGrid.refresh();
                        }

                    } else {

                        await this.editFit(submitData);

                        if (editedEventData[0].lubricatedStuds)
                            editedEventData[0].lubricatedStuds = this.helperSvc.getKey(editedEventData[0].lubricatedStuds) === '1';

                        let originalEventIndex = this.session.events.items.findIndex(e => e.id === data.id);

                        if (originalEventIndex > -1)
                            this.session.events.items[originalEventIndex].eventData = editedEventData[0];

                        try {
                            // update fitment data
                            await this.dataBroker.updateFitmentData(this.session, [editData.position.id]);

                            this.reloadData(); // update the reading Grid UI.

                            if (this.eventsGrid && this.eventsGrid.refresh) {
                                this.eventsGrid.refresh();
                            }

                            this.WindowFactory.closeWindow(this.actionWnd);

                        } catch (error) {
                            this.errorReporter.logError(error);
                        } finally {
                            if (this.eventsGrid && this.eventsGrid.refresh)
                                this.eventsGrid.refresh();
                            this.actionWnd.processing = false;
                            this.session.updating = false;
                        }
                    }
                }
            });
        }
    }

    async undo(event) {

        let eventToUndo = event.eventData;

        try {
            await this.confirmSvc.confirmMessage('maintenanceSession.confirmUndo_title', 'maintenanceSession.confirmUndo_' + eventToUndo.event);
        } catch {
            return; // they cancelled
        }

        this.session.updating = true;

        if (eventToUndo.event === MaintenanceSession_EventType.remove) {

            try {
                await this.dataBroker.msUndoRemove(eventToUndo, this.session);

                await this.dataBroker.updateFitmentData(this.session, [eventToUndo.position.id]);

                this.processUndo(event);

                if (eventToUndo.type === EquipmentTypeName.assembly) {

                    let assemblyIdx = this.session.assemblies.findIndex(a => a.id === eventToUndo.id);

                    if (assemblyIdx > -1)
                        this.session.assemblies.splice(assemblyIdx, 1);
                }

            } catch (error) {
                this.errorReporter.logError(error);
            } finally {
                this.session.updating = false;
            }

        } else {

            try {
                await this.dataBroker.msUndoFit(eventToUndo, this.session);

                let equipmentTypes = [];

                if (eventToUndo.type === EquipmentTypeName.assembly) {
                    let previousId: guid = null;

                    //Need to remove the fitted assembly from the assemblies array
                    let assemblyIdx = this.session.assemblies.findIndex(a => a.id === event.id);

                    if (assemblyIdx > -1) {
                        previousId = this.session.assemblies[assemblyIdx].previousId;
                        this.session.assemblies.splice(assemblyIdx, 1);
                    }

                    equipmentTypes.push('Tyre');
                    equipmentTypes.push('Rim');

                    if (!this.session.assemblies)
                        this.session.assemblies = [];

                    //Reactivate the required assembly
                    if (previousId != null) {
                        let previousIdx = this.session.assemblies.findIndex(a => a.id === previousId);

                        if (previousIdx > -1)
                            this.session.assemblies[previousIdx].active = true;
                    }

                } else {
                    equipmentTypes.push(eventToUndo.type);
                }

                await this.dataBroker.updateFitmentData(this.session, [eventToUndo.selectedPosition.id], equipmentTypes);

                this.processUndo(event);

            } catch (error) {
                this.errorReporter.logError(error);
            } finally {
                this.session.updating = false;
            }
        }
    }

    async processUndo(event) {

        let eventIndex = this.session.events.items.findIndex(e => e.id === event.id);

        this.session.events.items.splice(eventIndex, 1);
        this.session.vehicle.updated = uuid();

        try {
            await this.dataBroker.saveMaintenanceSession(this.session);

            this.$timeout(() => {
                this.updateChangeCount();
                this.reloadData(); // update the reading Grid UI.

                if (this.eventsGrid && this.eventsGrid.refresh) {
                    this.eventsGrid.refresh();
                }

                this.svgReset();
            });

            this.notifySvc.success(this.amtXlatSvc.xlat('maintenanceSession.undoSuccess'));

        } catch (error) {
            this.errorReporter.logError(error);
        }
    }

    onPositionClick(event, position, segment) {

        if (event.defaultPrevented) // already handled somewhere else
            return;

        event.preventDefault();

        if (!this.active() || (this.actionWnd && this.actionWnd.open) || !segment || this.readOnly)
            return;

        let typeParts = segment.split('.');

        if (typeParts.length !== 2 || typeParts[1] !== 'empty')
            return; // can't fit it if it's not empty.        

        let type = this.getCorrectedType(typeParts[0], position).toLowerCase();

        if (type === EquipmentTypeName.chain && this.session.checkedOut)
            return; // can't fit chains in historical sessions

        // no fitting in error session or pending session 
        // except when fitting a tyre (or a rim when track rims is turned on for the site) and the site does not allow check out with incomplete wheel positions
        if ((this.session.heldInError || this.session.pending) &&
            (this.ocConfigSvc.user.site.settings.tyreMaintenance.checkOutWithIncompleteWP !== ConfigurationOption.error || (type !== EquipmentTypeName.tyre && (!this.ocConfigSvc.user.site.settings.tyreMaintenance.trackRims || type !== EquipmentTypeName.rim)))) {
            return;
        }

        // can't fit if this is an inner position and there is a tyre or non-double gutter rim on the outside
        // or this is a rim and there is already something else fitted to the position
        for (let a = 0; a < this.session.vehicle.schematic.axles.length; a++) {
            for (let p = 0; p < this.session.vehicle.schematic.axles[a].positions.length; p++) {
                if (this.session.vehicle.schematic.axles[a].positions[p].id === position.id) {
                    if (this.hasOuterPositionConflict([type], position.id, a, p))
                        return;
                }
            }
        }

        let caption = String.format('{0} {1} - {2} {3}',
            this.amtXlatSvc.xlat('maintenanceSession.position'),
            position.label,
            this.amtXlatSvc.xlat('maintenanceSession.fit'),
            this.amtXlatSvc.xlat('maintenanceSession.' + type)
        );

        let fitRequestData = {
            position: position,
            schematic: this.vehicle.schematic,
            type: type,
            checkInEventId: this.session.checkin.eventId,
            assemblies: this.session.assemblies,
            eventData: undefined,
            sequenceDate: undefined,
            id: undefined,
            lastEventDate: this.session.checkedOut ? this.session.checkout.checkoutDate : null,
            newAssemblyId: undefined
        };

        this.actionWnd = this.WindowFactory.openItem({
            component: 'fit-component',
            caption: caption,
            canClose: false,
            isMobile: this.browserSvc.isMobile,
            initParams: fitRequestData,
            width: 700,
            top: this.browserSvc.isMobile ? this.amtConstants.windowTopMarginMobile : null,
            modal: true,
            onDataChangeHandler: async (fitDetails) => {

                this.actionWnd.processing = true;
                this.session.updating = true;

                delete fitRequestData.schematic;
                delete fitRequestData.assemblies;

                fitRequestData.eventData = fitDetails;
                fitRequestData.eventData.components = fitDetails.fitment.components;

                try {
                    let events = await this.dataBroker.msFitEvent(fitRequestData, this.session);

                    if (fitRequestData.eventData.components) {

                        for (let i = 0; i < fitRequestData.eventData.components.length; i++) {

                            let fitment = fitRequestData.eventData.components[i];

                            // create a new fitment and add it into the vehicle schematic
                            let newAssemblyFitment = {
                                componentId: fitment.equipmentId,
                                equipmentId: fitment.equipmentId,
                                eventId: events ? events[i].equipmentEventId : null,
                                id: fitment.id,
                                type: fitment.type.charAt(0).toUpperCase() + fitment.type.slice(1),
                                vehicleId: this.session.vehicle.id,
                                typeId: fitment.equipmentTypeId,
                                serialNumber: fitment.serialNumber.formatted || fitment.serialNumber,
                                onSite: true
                            };

                            position.fitments.push(newAssemblyFitment);
                            position[newAssemblyFitment.type + 'Fitment'] = newAssemblyFitment;
                        }


                        if (fitRequestData.eventData.components.length > 1) {

                            for (let c = 0; c < fitRequestData.eventData.components.length; c++) {
                                fitRequestData.eventData.components[c].eventId = events ? events[c].equipmentEventId : null;
                                fitRequestData.eventData.components[c].sequenceDate = events ? events[c].date : null;
                            }

                            let componentType = fitRequestData.eventData.components[0].type.toLowerCase();

                            if (componentType === EquipmentTypeName.tyre) {

                                fitRequestData.eventData.serialNumber = fitRequestData.eventData.components[0].serialNumberDetails ?
                                    fitRequestData.eventData.components[0].serialNumberDetails.hierarchical :
                                    fitRequestData.eventData.components[0].serialNumber;

                            } else {

                                fitRequestData.eventData.serialNumber = fitRequestData.eventData.components[1].serialNumberDetails ?
                                    fitRequestData.eventData.components[1].serialNumberDetails.hierarchical :
                                    fitRequestData.eventData.components[1].serialNumber;
                            }

                            fitRequestData.eventData.type = EquipmentTypeName.assembly;
                        }

                        position.fittedInThisSession = true;

                    } else {

                        // create a new fitment and add it into the vehicle schematic
                        let newFitment = {
                            componentId: fitRequestData.eventData.fitment.equipmentId,
                            equipmentId: fitRequestData.eventData.fitment.equipmentId,
                            eventId: events ? events[0].equipmentEventId : null,
                            id: fitRequestData.eventData.fitment.id,
                            type: type.charAt(0).toUpperCase() + type.slice(1),
                            vehicleId: this.session.vehicle.id,
                            typeId: fitRequestData.eventData.fitment.equipmentTypeId,
                            serialNumber: fitRequestData.eventData.fitment.serialNumber.formatted || fitRequestData.eventData.fitment.serialNumber,
                            serialNumberDetails: {
                                formatted: fitRequestData.eventData.fitment.serialNumber.formatted,
                                site: fitRequestData.eventData.fitment.serialNumber.site,
                                manufacturer: fitRequestData.eventData.fitment.serialNumber.manufacturer,
                                hierarchical: fitRequestData.eventData.fitment.serialNumber.site || fitRequestData.eventData.fitment.serialNumber.manufacturer
                            },
                            onSite: true
                        };

                        position.fitments.push(newFitment);
                        position[newFitment.type + 'Fitment'] = newFitment;

                        fitRequestData.eventData.serialNumber = newFitment.serialNumberDetails.hierarchical || newFitment.serialNumberDetails.formatted;
                        fitRequestData.eventData.components = [newFitment];

                        if (newFitment.type === 'Tyre')
                            position.fittedInThisSession = true;
                    }

                    fitRequestData.eventData.events = events;

                    let sequenceDate = new Date();

                    if (events && events.length)
                        sequenceDate = events[0].date;

                    fitRequestData.eventData.typeTranslated = this.amtXlatSvc.xlat('maintenanceSession.' + fitRequestData.eventData.type.toLowerCase());
                    fitRequestData.sequenceDate = sequenceDate;
                    fitRequestData.id = uuid();
                    fitRequestData.type = fitRequestData.eventData.type.toLowerCase();

                    if (fitRequestData.eventData.lubricatedStuds)
                        fitRequestData.eventData.lubricatedStuds = this.helperSvc.getKey(fitRequestData.eventData.lubricatedStuds) === '1';

                    if (fitRequestData.type === EquipmentTypeName.assembly && fitRequestData.eventData.components[0].type.toLowerCase() !== EquipmentTypeName.tyre)
                        fitRequestData.eventData.components.reverse();

                    if (fitRequestData.type === EquipmentTypeName.assembly) {
                        let previousId: guid = null;

                        let assemblyIdx = this.session.assemblies.findIndex(a => a.assemblyId === fitDetails.fitment.assemblyId);

                        if (assemblyIdx > -1) {
                            this.session.assemblies[assemblyIdx].active = false;   //this.session.assemblies.splice(assemblyIdx, 1);
                            previousId = this.session.assemblies[assemblyIdx].id;
                        }

                        //Need to update the event with the new assemblyId add in the new one - weird way of handling data around here...
                        //On mobile newAssemblyId is null - we don't actually hit the server

                        event.assemblyId = (event.newAssemblyId != null) ? event.newAssemblyId : uuid();
                        this.session.assemblies.push({
                            id: fitRequestData.id, //Not sure what this is?
                            previousId: previousId,
                            active: true,
                            eventType: MaintenanceSession_EventType.fit,
                            assemblyId: fitRequestData.newAssemblyId,
                            serialNumber: { formatted: "asfdasasd" },
                            equipmentTypeId: this.amtConstants.emptyGuid,
                            components: fitRequestData.eventData.components
                        });
                    }

                    this.session.events.items.unshift(fitRequestData); // slip in the event

                    // update the readings
                    // find the position and remove any reading entered (see OR-3519)
                    for (let reading of this.session.vehicle.positionReadings) {

                        if (reading.positionId === fitDetails.selectedPosition.id) {

                            reading.pressure = fitDetails.pressure;
                            reading.temp = fitDetails.temperature;
                            reading.torque = fitDetails.torque;

                            // Nitrogen reading is a percentage and usage is site specific.
                            reading.nitroFilled = fitDetails.nitroFilled;

                            await this.dataBroker.lookupComponent(
                                this.session.vehicle.positions[position.id].fitments[this.session.vehicle.positions[position.id].fitments.length - 1],
                                reading
                            );

                            this.session.vehicle.updated = uuid();
                        }
                    }

                    await this.dataBroker.updateFitmentData(this.session, [position.id]);

                    this.session.vehicle.updated = uuid();

                    try {
                        await this.dataBroker.saveMaintenanceSession(this.session);

                        // refresh the schematic and the grid.
                        this.reloadData();

                        if (this.eventsGrid && this.eventsGrid.refresh)
                            this.eventsGrid.refresh();

                        this.updateChangeCount();
                        this.session.vehicle.updated = uuid();
                        this.svgReset();

                        this.WindowFactory.closeWindow(this.actionWnd);

                    } catch (error) {
                        this.errorReporter.logError(error);
                    }

                } catch (error) {
                    this.errorReporter.logError(error);
                } finally {
                    this.actionWnd.processing = false;
                    this.session.updating = false;
                }
            }
        });
    }

    /**
     * Finds the correct type to fit, based on what's currently fitted to the position.
     * @param {string} type - Type of component that was clicked (rim, tyre, chain)
     * @param {Object} position - Position that was clicked.
     * @param {Object[]} position.fitments - Fitments on the position.
     * @param {string} position.fitments[].type - The component type of the fitment.
     */
    getCorrectedType(type, position) {
        let correctedType = type;

        if (type === EquipmentTypeName.tyre || type === EquipmentTypeName.chain) {
            if (!position.fitments.some(fitment => fitment.type.toLocaleLowerCase() === EquipmentTypeName.rim)
                && this.ocConfigSvc.user.site.settings.tyreMaintenance.trackRims) {
                // clicked on tyre and there's no rim and we're tracking rims, force it to fit rim first
                correctedType = EquipmentTypeName.rim;
            } else if (type === EquipmentTypeName.chain && !position.fitments.some(f => f.type.toLocaleLowerCase() === EquipmentTypeName.tyre)) {
                // clicked on chain and there's no tyre, force it to fit tyre first
                correctedType = EquipmentTypeName.tyre;
            }
        }

        return correctedType;
    }

    hasOuterPositionConflict(componentTypes, positionId, axleIndex, positionIndex) {

        let hasConflict = false;

        let rimEvent = componentTypes.findIndex(c => c.toLowerCase() === EquipmentTypeName.rim) > -1;
        let untrackedRimEvent = rimEvent && !this.ocConfigSvc.user.site.settings.tyreMaintenance.trackRims;

        // if fitting/undoing a rim, don't worry about anything else on the position or an outer position if not enforcing rim fitments                        
        if (rimEvent && !untrackedRimEvent) {
            if (this.session.vehicle.positions[positionId].fitments &&
                this.session.vehicle.positions[positionId].fitments.filter(f => f.type.toLowerCase() !== EquipmentTypeName.rim).length) {
                hasConflict = true;
            }
        }

        // if no conflict so far and not an untracked rim event
        if (!hasConflict && !untrackedRimEvent) {

            // check if outer positions have a tyre or non double-gutter rim fitted
            if (positionIndex < this.session.vehicle.schematic.axles[axleIndex].positions.length / 2) {
                for (let lp = positionIndex - 1; lp > -1; lp--) { // check positions to the left

                    let posId = this.session.vehicle.schematic.axles[axleIndex].positions[lp].id;

                    if (this.session.vehicle.positions[posId].TyreFitment ||
                        (this.session.vehicle.positions[posId].RimFitment && !this.session.vehicle.positions[posId].RimFitment.isDoubleGutter)) {
                        hasConflict = true;
                    }
                }
            } else {
                for (let rp = positionIndex + 1; rp < this.session.vehicle.schematic.axles[axleIndex].positions.length; rp++) { // check positions to the right

                    let posId = this.session.vehicle.schematic.axles[axleIndex].positions[rp].id;

                    if (this.session.vehicle.positions[posId].TyreFitment ||
                        (this.session.vehicle.positions[posId].RimFitment && !this.session.vehicle.positions[posId].RimFitment.isDoubleGutter)) {
                        hasConflict = true;
                    }
                }
            }
        }

        return hasConflict;
    }

    onEventsLoaded(response) {

        let events = response.result;

        for (let r = 0; r < events.length; r++) {

            let hasEventId = false;

            if (events[r].eventData.events && events[r].eventData.events.length)
                hasEventId = true;

            let hasPositionConflict = false; // there has been another future change on the position
            let hasEquipmentConflict = false; // the component has been used in a future event
            let outerPositionConflict = false; // outer position has a tyre or non-double gutter rim fitted
            let innerPositionConflict = false; // change has happened on a more inner position since this event

            // if this is most recent event in the session allow them to undo regardless of anything else
            if (events.some(e => e.sequenceDate > events[r].sequenceDate)) {

                hasPositionConflict = events.some(e =>
                    e.sequenceDate > events[r].sequenceDate
                    && (e.position.id || e.eventData.position.id) === (events[r].position.id || events[r].eventData.position.id)
                );

                hasEquipmentConflict = events.some(e =>
                    e.sequenceDate > events[r].sequenceDate
                    && _.intersection(
                        e.eventData.components.map(c => c.equipmentId || c.componentId || c.id),
                        events[r].eventData.components.map(c => c.equipmentId || c.componentId || c.id)
                    ).length > 0
                );

                for (let a = 0; a < this.session.vehicle.schematic.axles.length; a++) {
                    for (let p = 0; p < this.session.vehicle.schematic.axles[a].positions.length; p++) {

                        if (this.session.vehicle.schematic.axles[a].positions[p].id === (events[r].position.id || events[r].eventData.position.id)) {

                            let componentTypes = events[r].eventData.components.map(c => c.type);

                            // check for outer position conflicts
                            outerPositionConflict = this.hasOuterPositionConflict(componentTypes, (events[r].position.id || events[r].eventData.position.id), a, p);

                            // check for inner position conflicts                                        
                            if (p < this.session.vehicle.schematic.axles[a].positions.length / 2) { // position is left side of axle

                                for (let rp = p + 1; rp < (this.session.vehicle.schematic.axles[a].positions.length / 2); rp++) { // check positions to the right

                                    let posId = this.session.vehicle.schematic.axles[a].positions[rp].id;

                                    if (events.some(e => e.sequenceDate > events[r].sequenceDate && (e.position.id || e.eventData.position.id) === posId))
                                        innerPositionConflict = true;
                                }

                            } else { // position is right side of axle                                          

                                for (let lp = p - 1; lp >= (this.session.vehicle.schematic.axles[a].positions.length / 2); lp--) { // check positions to the left

                                    let posId = this.session.vehicle.schematic.axles[a].positions[lp].id;

                                    if (events.some(e => e.sequenceDate > events[r].sequenceDate && (e.position.id || e.eventData.position.id) === posId))
                                        innerPositionConflict = true;
                                }
                            }
                        }
                    }
                }
            }

            //On mobile only allow undo if the event doesnt have an id (ie. wasnt synced from server). Don't allow undo if chain and future events exist
            events[r].canUndo = ((this.browserSvc.isMobile && !hasEventId) ||
                (!this.browserSvc.isMobile && events[r].type !== "chain" ||
                    (events[r].type === "chain" && !this.session.futureEvents && !this.session.checkedOut)
                )
            ) && !hasPositionConflict && !hasEquipmentConflict && !outerPositionConflict && !innerPositionConflict;

            // can only strip if it is an assembly removal and the assembly is still in the assemblies collection (has not been refit) OR it is a refit/fit
            events[r].canStrip = events[r].eventData.type == EquipmentTypeName.assembly
                && (events[r].eventData.event == MaintenanceSession_EventType.remove && this.session.assemblies.some(a => a.assemblyId === events[r].assemblyId && a.active)
                    || (events[r].eventData.event == MaintenanceSession_EventType.fit)); //&& !this.session.assemblies.some(a => a.assemblyId === events[r].assemblyId)));

            for (let component of events[r].eventData.components)
                component.onSite = component.onSite || component.equipmentCurrentSiteId == this.ocConfigSvc.user.site.id;
        }

        return response;
    }


    // strip an assembly that has been removed from a position
    async stripAssembly(data) {

        if (data.assemblyId) {

            this.session.updating = true;

            try {
                await this.dataBroker.msStripAssembly(data.assemblyId, data.eventData.event);

                //Grab whether fit or remove before we delete the assembly.
                let eventType: MaintenanceSession_EventType = null;

                // remove the assembly from the assemblies collection
                let assemblyIdx = this.session.assemblies.findIndex(a => a.assemblyId === data.assemblyId);

                if (assemblyIdx > -1) {
                    if (this.session.assemblies[assemblyIdx].previousId != null) {
                        let previousIdx = this.session.assemblies.findIndex(a => a.id === this.session.assemblies[assemblyIdx].previousId);

                        if (previousIdx > -1)
                            this.session.assemblies[previousIdx].active = true;
                    }

                    eventType = this.session.assemblies[assemblyIdx].eventType;
                    this.session.assemblies.splice(assemblyIdx, 1);
                }

                // remove the assembly event and break it into individual component events on events grid
                let eventIndex = this.session.events.items.findIndex(e => e.id === data.id);
                this.session.events.items.splice(eventIndex, 1);

                //Order is important
                if (eventType == MaintenanceSession_EventType.fit) {
                    this.stripRim(data, eventIndex, eventType);
                    this.stripTyre(data, eventIndex, eventType);
                } else {
                    this.stripTyre(data, eventIndex, eventType);
                    this.stripRim(data, eventIndex, eventType);
                }

                this.session.vehicle.updated = uuid();

                try {
                    await this.dataBroker.saveMaintenanceSession(this.session);

                    this.$timeout(() => {
                        if (this.eventsGrid && this.eventsGrid.refresh)
                            this.eventsGrid.refresh();

                    });

                } catch (error) {
                    this.errorReporter.logError(error);
                }

            } catch (error) {
                this.errorReporter.logError(error);
            } finally {
                this.session.updating = false;
            }
        }
    }

    private stripRim(data: any, eventIndex: number, eventType: MaintenanceSession_EventType) {
        let rimEventData = angular.copy(data.eventData);
        let rimComponent = angular.copy(data.eventData.components.find(c => c.type === 'Rim'));

        rimEventData.components = [rimComponent];

        if (rimEventData.events)
            rimEventData.events = rimEventData.events.filter(e => e.equipmentEventId == rimComponent.eventId);

        rimEventData.id = uuid();
        rimEventData.serialNumber = rimComponent.serialNumber;
        rimEventData.type = rimComponent.type;
        rimEventData.typeTranslated = this.amtXlatSvc.xlat('maintenanceSession.' + rimComponent.type.toLowerCase());

        let rimDate = rimEventData.events ? rimEventData.events[0].date : null;

        if (eventType == MaintenanceSession_EventType.fit) {
            rimEventData.pressure = null;
            rimEventData.nitroFilled = null;

            if (!rimDate) {
                rimDate = new Date(data.sequenceDate);
            }
        } else {
            // clear out the tyre depth readings
            rimEventData.rtd1 = null;
            rimEventData.rtd1 = null;

            if (!rimDate) {
                rimDate = new Date(data.sequenceDate);
                rimDate.setSeconds(rimDate.getSeconds() + 1);
            }
        }

        let rimEvent: TMaintenanceSession_Event = {
            position: data.position,
            assemblyId: data.assemblyId,
            eventData: rimEventData,
            type: EquipmentTypeName.rim,
            id: uuid()
        };

        rimEvent = JSON.parse(JSON.stringify(rimEvent)); // need to strip out functions etc. for dexie
        rimEvent.sequenceDate = rimDate;
        rimEvent.eventData.sessionDate = rimEventData.sessionDate;

        // add the rim removal event to the events list
        this.session.events.items.splice(eventIndex, 0, rimEvent);
    }

    private stripTyre(data: any, eventIndex: number, eventType: MaintenanceSession_EventType) {
        let tyreEventData = angular.copy(data.eventData);
        let tyreComponent = angular.copy(data.eventData.components.find(c => c.type === 'Tyre'));

        tyreEventData.components = [tyreComponent];

        if (tyreEventData.events)
            tyreEventData.events = tyreEventData.events.filter(e => e.equipmentEventId == tyreComponent.eventId);

        tyreEventData.id = uuid();
        tyreEventData.serialNumber = tyreComponent.serialNumber;
        tyreEventData.type = tyreComponent.type;
        tyreEventData.typeTranslated = this.amtXlatSvc.xlat('maintenanceSession.' + tyreComponent.type.toLowerCase());

        let tyreDate = tyreEventData.events ? tyreEventData.events[0].date : null;

        if (eventType == MaintenanceSession_EventType.fit) {
            tyreEventData.torque = null;
            tyreEventData.lubricatedStuds = null;

            if (!tyreDate) {
                tyreDate = new Date(data.sequenceDate);
                tyreDate.setSeconds(tyreDate.getSeconds() + 1);
            }
        } else {
            if (!tyreDate) {
                tyreDate = new Date(data.sequenceDate);
            }
        }

        let tyreEvent: TMaintenanceSession_Event = {
            position: data.position,
            assemblyId: data.assemblyId,
            type: EquipmentTypeName.tyre,
            eventData: tyreEventData,
            id: uuid()
        };

        tyreEvent = JSON.parse(JSON.stringify(tyreEvent)); // need to strip out functions etc. for dexie
        tyreEvent.sequenceDate = tyreDate;
        tyreEvent.eventData.sessionDate = tyreEventData.sessionDate;

        // add the tyre removal event to the events list
        this.session.events.items.splice(eventIndex, 0, tyreEvent);
    }

    refit(data, returnWindow?: boolean) {

        if (this.actionWnd && this.actionWnd.open)
            return;

        let type = data.eventData.components[0].type.toLowerCase();

        let serials = [data.eventData.components[0].serialNumberDetails ?
            data.eventData.components[0].serialNumberDetails.hierarchical :
            data.eventData.components[0].serialNumber];

        if (data.eventData.components.length > 1) {

            if (type === EquipmentTypeName.tyre) {
                // type is component 0.
                serials = [(data.eventData.components[1].serialNumberDetails ? data.eventData.components[1].serialNumberDetails.hierarchical : data.eventData.components[1].serialNumber),
                (data.eventData.components[0].serialNumberDetails ? data.eventData.components[0].serialNumberDetails.hierarchical : data.eventData.components[0].serialNumber)];

                // need rim to be first (rim must be fitted before tyre)
                data.eventData.components = Array.from(data.eventData.components); //convert to array (from object) 
                data.eventData.components.reverse();
            } else {

                // type is component 1.                    
                serials = [(data.eventData.components[0].serialNumberDetails ? data.eventData.components[0].serialNumberDetails.hierarchical : data.eventData.components[0].serialNumber),
                (data.eventData.components[1].serialNumberDetails ? data.eventData.components[1].serialNumberDetails.hierarchical : data.eventData.components[1].serialNumber)];
            }

            type = EquipmentTypeName.assembly;
        }

        let caption = String.format('{0} {1} - {2}', this.amtXlatSvc.xlat('maintenanceSession.refit'), this.amtXlatSvc.xlat('maintenanceSession.' + type), serials.length > 1 ? '(' + serials.join('/') + ')' : serials[0]);

        let fitData = {
            refit: true,
            checkInEventId: this.session.checkin.eventId,
            eventData: angular.copy(data.eventData),
            fitment: angular.copy(data.eventData)
        };

        let fitWindow = this.WindowFactory.openItemAsync({
            component: 'fit-component',
            caption: caption,
            canClose: false,
            isMobile: this.browserSvc.isMobile,
            initParams: {
                data: fitData,
                schematic: this.vehicle.schematic,
                type: type,
                position: angular.copy(data.eventData.position)
            },
            width: 700,
            top: this.browserSvc.isMobile ? this.amtConstants.windowTopMarginMobile : null,
            modal: true,
            onResolve: async (fitDetails) => {

                this.actionWnd.processing = true;
                this.session.updating = true;                                

                try {

                    data.eventData = fitDetails;
                    data.eventData.components = fitDetails.fitment.components;

                    let position = fitDetails.selectedPosition;

                    //this position needs to be the one on the schematic - should be but make sure.
                    position = this.dataBroker.schematicToPositions(this.vehicle.schematic)[position.id];
                    data.position = position;

                    let events = await this.dataBroker.msFitEvent(data, this.session);

                    for (let h = 0; h < fitDetails.eventData.components.length; h++) {

                        let fitment = fitDetails.eventData.components[h];

                        data.eventData.components[h].eventId = events ? events[h].equipmentEventId : null;

                        // create a new fitment and add it into the vehicle schematic
                        let newFitment = {
                            componentId: fitment.equipmentId || fitment.componentId,
                            id: fitment.id,
                            type: fitment.type.charAt(0).toUpperCase() + fitment.type.slice(1),
                            vehicleId: this.session.vehicle.id,
                            typeId: fitment.equipmentTypeId,
                            serialNumber: fitment.serialNumber.formatted || fitment.serialNumber,
                            onSite: true
                        };

                        position[newFitment.type + 'Fitment'] = newFitment;
                        position.fitments.push(newFitment);

                        fitDetails.fitment = newFitment;
                    }

                    if (type === EquipmentTypeName.assembly || type === EquipmentTypeName.tyre)
                        position.fittedInThisSession = true;

                    data.eventData.typeTranslated = this.amtXlatSvc.xlat('maintenanceSession.' + data.eventData.type.toLowerCase());

                    if (data.eventData.components.length > 1) {
                        data.eventData.serialNumber = serials.join(' / ');
                    } else {
                        data.eventData.serialNumber = serials[0];
                    }

                    data.eventData.refit = false;

                    if (data.eventData.lubricatedStuds)
                        data.eventData.lubricatedStuds = this.helperSvc.getKey(data.eventData.lubricatedStuds) === '1';

                    // update the readings
                    // find the position and remove any reading entered (see OR-3519)
                    for (let reading of this.session.vehicle.positionReadings) {
                        if (reading.positionId === fitDetails.selectedPosition.id) {

                            reading.pressure = fitDetails.pressure;
                            reading.temp = fitDetails.temperature;

                            // Nitrogen reading is a percentage and usage is site specific.
                            reading.nitroFilled = fitDetails.nitroFilled;

                            await this.dataBroker.lookupComponent(
                                this.session.vehicle.positions[position.id].fitments[this.session.vehicle.positions[position.id].fitments.length - 1],
                                reading
                            );

                            this.session.vehicle.updated = uuid();

                            this.reloadData(); // update the reading Grid UI.

                            if (this.eventsGrid && this.eventsGrid.refresh)
                                this.eventsGrid.refresh();
                        }
                    }
 

                    // The `data` object that comes back is a weird subClass with extra properties we don't need... 
                    // Massage it to the right format for our needs.
                    let event = JSON.parse(JSON.stringify(data));

                    event.id = uuid();
                    event.sequenceDate = events ? events[0].date : new Date();
                    event.eventData.events = events;

                    // reverse components for display
                    event.eventData.components.reverse();

                    // if this is an assembly, remove it from the assemblies collection
                    if (type === EquipmentTypeName.assembly) {
                        let previousId: guid = null;

                        let assemblyIdx = this.session.assemblies.findIndex(a => a.assemblyId === data.assemblyId);

                        if (assemblyIdx > -1) {
                            this.session.assemblies[assemblyIdx].active = false;   //this.session.assemblies.splice(assemblyIdx, 1);
                            previousId = this.session.assemblies[assemblyIdx].id;
                        }

                        //Need to update the event with the new assemblyId add in the new one - weird way of handling data around here...
                        //On mobile newAssemblyId is null - we don't actually hit the server

                        event.assemblyId = (event.newAssemblyId != null) ? event.newAssemblyId : uuid();
                        this.session.assemblies.push({
                            id: event.id, //Not sure what this is?
                            previousId: previousId,
                            active: true,
                            eventType: MaintenanceSession_EventType.fit,
                            assemblyId: event.assemblyId,
                            serialNumber: { formatted: "asfdasasd" },
                            equipmentTypeId: this.amtConstants.emptyGuid,
                            components: event.eventData.components
                        });
                    }

                    this.session.events.items.unshift(event); // slip in the event

                    await this.dataBroker.updateFitmentData(this.session, [position.id]);

                    this.session.vehicle.updated = uuid();

                    try {
                        await this.dataBroker.saveMaintenanceSession(this.session);

                        await this.$timeout(() => {

                            if (this.eventsGrid && this.eventsGrid.refresh)
                                this.eventsGrid.refresh();

                            this.svgReset();
                            this.session.vehicle.updated = uuid();

                            this.reloadData(); // update the reading Grid UI.

                            if (this.eventsGrid && this.eventsGrid.refresh)
                                this.eventsGrid.refresh();

                            this.updateChangeCount();

                            this.WindowFactory.closeWindow(this.actionWnd);
                        });

                        return true;

                    } catch (error) {
                        // not a user displayed error.
                        this.errorReporter.logError(error, 'maintenance session save');
                    }

                } catch (error) {
                    this.errorReporter.logError(error);
                    throw error;
                } finally {

                    if (this.actionWnd)
                        this.actionWnd.processing = false;

                    this.session.updating = false;

                }
            }
        });

        this.actionWnd = fitWindow.wnd;

        return returnWindow ? fitWindow : fitWindow.promise;
    }

    moveComponents(data) {

        // create a promise to handle the remove asynchronously.
        return new Promise((resolve, reject) => {

            for (let c in data.components) {
                if (data.components.hasOwnProperty(c)) {
                    let component = data.components[c];

                    // display remove dialog here and wait for the remove to happen

                    // if something goes wrong, call reject() on the promise and display a message, might need some transaction handling here too if you're removing multiple components
                    // add the error to the data.errors array (nothing happens with that yet)
                    // reject(data);

                    if (component.type === 'Tyre')
                        data.change += 0.5;
                }
            }

            // only update the change count if there was no reject
            this.changeCount += data.change;

            resolve(data);
        });
    }

    async selectActionClick(item) {

        let menuItems = ['maintenanceSession.edit'];

        if (item.canUndo)
            menuItems.push('maintenanceSession.undo');

        if (item.eventData.canRefit && item.eventData.event === MaintenanceSession_EventType.remove && !this.session.heldInError)
            menuItems.push('maintenanceSession.refit');

        if (item.canStrip && !this.session.heldInError)
            menuItems.push('maintenanceSession.strip');

        menuItems.push('common.cancel');

        let modal = this.$uibModal.open({
            windowTopClass: 'maintenance-events-actions-menu-win-top',
            component: "maintenanceEventsActionsMenu",
            resolve: {
                position: () => item.position.label,
                serial: () => item.eventData.serialNumber,
                componentType: () => item.eventData.components[0].type,
                menuItems: () => menuItems
            }
        });

        try {
            switch (await modal.result) {
                case 'edit':
                    this.editItem(item.id, false);
                    break;
                case 'undo':
                    this.undo(item);
                    break;
                case 'refit':
                    this.refit(item);
                    break;
                case 'strip':
                    this.stripAssembly(item);
                    break;
            }
        } catch {
            /* likely not a real error and they just cancelled - no action needed */
        }
    }

    $onChanges(changes) {
        if (changes.vehicle)
            this.loadingSchematic = !this.vehicle;
    }
}

class MaintenanceEventsComponent implements ng.IComponentOptions {
    public bindings = {
        session: '<',
        vehicle: '<',
        reloadData: '&?',
        api: '<',
        svgReset: '&',
        save: '&',
        updateChangeCount: '&',
        processing: '=',
        active: '&', // used to indicate the tab is visible
        readOnly: '='
    };
    public template = tmpl;
    public controller = MaintenanceEventsCtrl;
    public controllerAs = 'vm';
}

angular.module('app.directives').component('maintenanceEvents', new MaintenanceEventsComponent());