/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * @hidden
 */
var BooleanFilterRadioButtonDirective = /** @class */ (function () {
    function BooleanFilterRadioButtonDirective(hostElement, renderer) {
        this.hostElement = hostElement;
        this.renderer = renderer;
    }
    BooleanFilterRadioButtonDirective.prototype.ngAfterViewInit = function () {
        this.radioButtonEl = this.hostElement.nativeElement;
        this.renderer.setAttribute(this.hostElement.nativeElement, 'aria-label', this.columnLabel);
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], BooleanFilterRadioButtonDirective.prototype, "columnLabel", void 0);
    BooleanFilterRadioButtonDirective = tslib_1.__decorate([
        core_1.Directive({ selector: '[kendoFilterMenuRadioButton]' }),
        tslib_1.__metadata("design:paramtypes", [core_1.ElementRef,
            core_1.Renderer2])
    ], BooleanFilterRadioButtonDirective);
    return BooleanFilterRadioButtonDirective;
}());
exports.BooleanFilterRadioButtonDirective = BooleanFilterRadioButtonDirective;
