/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * @hidden
 */
var FocusService = /** @class */ (function () {
    function FocusService() {
        this.onFocus = new core_1.EventEmitter();
    }
    FocusService.prototype.isFocused = function (index) {
        return index === this.focused;
    };
    FocusService.prototype.focus = function (index) {
        if (this.isFocused(index)) {
            return;
        }
        this.focused = index;
        this.onFocus.emit(index);
    };
    FocusService.prototype.resetFocus = function () {
        this.focused = -1;
    };
    Object.defineProperty(FocusService.prototype, "focused", {
        get: function () {
            return this.focusedIndex;
        },
        set: function (index) {
            this.focusedIndex = index;
            this.onFocus.emit(index);
        },
        enumerable: true,
        configurable: true
    });
    FocusService = tslib_1.__decorate([
        core_1.Injectable()
    ], FocusService);
    return FocusService;
}());
exports.FocusService = FocusService;
