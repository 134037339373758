/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Specifies the text and styles for the subtitle of the Card.
 */
var CardSubtitleDirective = /** @class */ (function () {
    function CardSubtitleDirective() {
        this.hostClass = true;
    }
    tslib_1.__decorate([
        core_1.HostBinding('class.k-card-subtitle'),
        tslib_1.__metadata("design:type", Boolean)
    ], CardSubtitleDirective.prototype, "hostClass", void 0);
    CardSubtitleDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoCardSubtitle]'
        })
    ], CardSubtitleDirective);
    return CardSubtitleDirective;
}());
exports.CardSubtitleDirective = CardSubtitleDirective;
