/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var colorpicker_localization_service_1 = require("./colorpicker-localization.service");
/**
 * @hidden
 */
var FlatColorPickerLocalizationService = /** @class */ (function (_super) {
    tslib_1.__extends(FlatColorPickerLocalizationService, _super);
    function FlatColorPickerLocalizationService(prefix, messageService, _rtl, colorPickerLocalization) {
        var _this = _super.call(this, prefix, messageService, _rtl) || this;
        _this.colorPickerLocalization = colorPickerLocalization;
        return _this;
    }
    FlatColorPickerLocalizationService.prototype.get = function (shortKey) {
        if (this.colorPickerLocalization) {
            return this.colorPickerLocalization.get(shortKey);
        }
        return _super.prototype.get.call(this, shortKey);
    };
    FlatColorPickerLocalizationService = tslib_1.__decorate([
        tslib_1.__param(0, core_1.Inject(kendo_angular_l10n_1.L10N_PREFIX)),
        tslib_1.__param(1, core_1.Optional()),
        tslib_1.__param(2, core_1.Optional()), tslib_1.__param(2, core_1.Inject(kendo_angular_l10n_1.RTL)),
        tslib_1.__param(3, core_1.Optional()), tslib_1.__param(3, core_1.Inject(colorpicker_localization_service_1.ColorPickerLocalizationService)),
        tslib_1.__metadata("design:paramtypes", [String, kendo_angular_l10n_1.MessageService, Boolean, colorpicker_localization_service_1.ColorPickerLocalizationService])
    ], FlatColorPickerLocalizationService);
    return FlatColorPickerLocalizationService;
}(kendo_angular_l10n_1.LocalizationService));
exports.FlatColorPickerLocalizationService = FlatColorPickerLocalizationService;
