//import angular from 'angular';


angular.module("app.site").controller("rimOccurrenceListController", rimOccurrenceListController);

    // @ts-ignore
    rimOccurrenceListController.$inject = ["$rootScope", "$state", "amtConstants", "forecastingSvc", "amtXlatSvc"];

    // ReSharper disable once InconsistentNaming
    function rimOccurrenceListController($rootScope, $state, amtConstants, forecastingSvc, amtXlatSvc) {

        var vm = this;

        vm.apiUrls = amtConstants.url;
        vm.gridControl = {};

        vm.forecastId = $rootScope.$stateParams.forecastid ? $rootScope.$stateParams.forecastid : 0;
        vm.dashboardType = $rootScope.$stateParams.dashboardType;
        vm.year = $rootScope.$stateParams.year ? $rootScope.$stateParams.year : null;
        vm.month = $rootScope.$stateParams.month ? $rootScope.$stateParams.month : null;
        vm.week = $rootScope.$stateParams.week ? $rootScope.$stateParams.week : null;
        vm.includeAll = $rootScope.$stateParams.includeAll ? $rootScope.$stateParams.includeAll : false;

        vm.filterValues = {
            forecastId: vm.forecastId,
            taskTypes: vm.taskTypes,
            year: vm.year,
            month: vm.month,
            week: vm.week,
            includeAll: vm.includeAll
        };

        this.$onInit = function () {
            if (!(vm.forecastId > 0)) {
                $state.go('app.site.forecastlist');
            }

            switch (vm.dashboardType) {
                case 'NDT':
                    vm.filterValues.taskTypes = ['NDT'];
                    vm.typeDescription = amtXlatSvc.xlat('occurrenceList.ndt');
                    break;

                case 'Scrap Rim':
                    vm.filterValues.taskTypes = ['RS'];
                    vm.typeDescription = amtXlatSvc.xlat('occurrenceList.scrapRim');
                    break;
            }
        };

        vm.goToParentRoute = function () {
            forecastingSvc.setActiveTabName("Rims");
            $rootScope.goBack();
        };
    }
