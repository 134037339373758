//import angular from 'angular';
import * as _ from 'underscore';
import BrowserSvc from '../../../../services/browserSvc';
import OcDateSvc from '../../../../services/ocDateSvc';
import AdminUrls from '../../../admin/adminUrls';


    angular.module('app.vehicle')
        .controller('maintenanceSearchCtrl', maintenanceSearchCtrl);

    // @ts-ignore
    maintenanceSearchCtrl.$inject = [
        '$scope', 'enums', 'maintenanceSessionFactory', 'adminUrls', 'referenceDataUrls', 'maintenanceUrls',
        'WindowFactory', 'amtXlatSvc', 'amtCommandQuerySvc', '$rootScope', 'ocConfigSvc', 'ocSecuritySvc',
        '$timeout', '$stateParams', 'browserSvc', 'ocDateSvc', 'maintenanceSessionService', 'appConfig', 'errorReporter', 'amtConstants'
    ];

    // ReSharper disable InconsistentNaming
    function maintenanceSearchCtrl($scope, enums, maintenanceSessionFactory, adminUrls: AdminUrls, referenceDataUrls, maintenanceUrls,
        WindowFactory, amtXlatSvc, amtCommandQuerySvc, $rootScope, ocConfigSvc, ocSecuritySvc,
        $timeout, $stateParams, browserSvc: BrowserSvc, ocDateSvc: OcDateSvc, maintenanceSessionService, appConfig, errorReporter, amtConstants) {
        // ReSharper restore InconsistentNaming

        var vm = this;

        vm.results = null;

        // security
        vm.canViewVehicles = ocSecuritySvc.isAuthorised('Security.Vehicles', 'readOnly');

        // get the search criteria from the factory...
        // NOTE: the factory has a static variable used to keep the search criteria, so..
        //       values will be kept between state transitions
        vm.criteria = angular.copy(maintenanceSessionFactory.searchCriteria);

        vm.status = "loading";
        vm.config = ocConfigSvc;

        $scope.selectAll = true;

        vm.apiUrls = maintenanceUrls;
        vm.adminUrls = adminUrls;
        vm.referenceDataUrls = referenceDataUrls;

        vm.showClients = false;
        vm.showSites = false;

        vm.gridControl = {};
        vm.selectedItem = null;

        vm.showSiteSerialNumber = vm.config.user.site.settings.tyreMaintenance.siteSerialNumber.vehicle;

        vm.statuses = [];

        $scope.rowParseFunction = function (response) {

            var sessions = response.result;

            for (var r = 0; r < sessions.length; r++) {
                sessions[r].onSite = sessions[r].siteId === vm.config.user.site.id;
                sessions[r].vehicleOnSite = sessions[r].vehicleCurrentSiteId === vm.config.user.site.id;
            }

            return response;
        };

        vm.onDatabound = function (event) {

            vm.selectedItem = null;

            if (!!event) {
                var rows = event.sender.tbody.children();
                var data = event.sender._data;

                for (var i = 0; i < data.length; i++) {

                    if (data[i].errorCount > 0) {
                        // if the status is not Okay, then highlight the row
                        var row = $(rows[i]);
                        row.addClass('bg-danger');
                    }

                    if (data[i].pending) {
                        var row = $(rows[i]);
                        row.addClass('bg-highlight');
                    }

                    if (vm.showSiteSerialNumber === 'Hide') {
                        data[i].serialNumber.formatted = data[i].serialNumber.manufacturer;
                    }
                }
            }

            vm.results = vm.gridControl.getTotalItemsCount() > 0;
        };

        vm.onSelectedItemChanged = function (items) {
            if (items != null && items.length === 1) {
                vm.selectedItem = items[0];
            } else {
                vm.selectedItem = null;
            }
        };

        vm.setHeaderHeight = function (height, offset) {
            vm.gridTop = height + offset;
        };

        vm.viewMaintenanceSession = function () {
            if (vm.selectedItem) {
                $scope.editItem(vm.selectedItem);
            }
        }

        vm.deleteMaintenanceSession = function () {
            if (vm.selectedItem) {
                maintenanceSessionService.deleteSession(vm.selectedItem, vm.selectedItem.id).then(function (response) {
                    if (response) {
                        broadcastSessionChange();

                        $timeout(function () {
                            vm.filter();
                        });
                    }
                });
            }
        };

        vm.createMaintenanceSession = function () {
            WindowFactory.openItem({
                component: 'maintenance',
                canMinimise: true,
                canClose: true,
                caption: amtXlatSvc.xlat('equipment.maintenanceSession'),
                headerOff: browserSvc.isMobile,
                fullScreenOff: true,
                footerOff: true,
                windowRelatedRecordId: vm.selectedItem ? (vm.selectedItem.equipmentId + amtConstants.emptyGuid) : amtConstants.emptyGuid,
                initParams: {
                    vehicleId: vm.selectedItem ? vm.selectedItem.equipmentId : null,
                    display: vm.selectedItem ? vm.selectedItem.serialNumber.formatted + " - " + vm.selectedItem.specification : null
                },
                height: 700 + (browserSvc.isMobile ? 0 : WindowFactory.headerHeight),
                width: 1100
            });
        }

        vm.export = function () {
            maintenanceSessionFactory.export(vm.criteria);
        };

        vm.loadDefaults = function () {

            vm.status = 'loading';

            amtCommandQuerySvc.get(vm.referenceDataUrls.getMaintenanceSessionStatuses_short + "?statusesToExclude=Ready", {})
                .then(function (response) {
                    if (response) {
                        if (response.status === "fail") {
                            vm.status = "error";
                            return;
                        }

                        vm.statuses = response.data.result;
                    }
                }, function (error) {
                    vm.status = "error";
                    errorReporter.logError(error);
                }).then(function () {

                    var defaultCriteria = {
                        statusTypeNames: []
                    };

                    amtCommandQuerySvc.post(vm.apiUrls.getDefaults, defaultCriteria).then(function (response) {
                        if (response) {

                            vm.defaults = {
                                clients: response.clients,
                                dateFrom: (vm.criteria.filterValues.dateFrom ? vm.criteria.filterValues.dateFrom : undefined),
                                dateTo: (vm.criteria.filterValues.dateTo ? vm.criteria.filterValues.dateTo : undefined),
                                serialNumber: (vm.criteria.filterValues.serialNumber ? vm.criteria.filterValues.serialNumber : undefined)
                            };

                            if ($stateParams.search === 'inError') {
                                vm.defaults.statuses = _.filter(vm.statuses, s => {
                                    return s.key === 'Error';
                                });

                            } else if ($stateParams.search === 'inProgress') {

                                vm.defaults.statuses = _.filter(vm.statuses, s => {
                                    return s.key === 'In Progress';
                                });
                            } else if ($stateParams.search === 'pending') {

                                vm.defaults.statuses = _.filter(vm.statuses, s => {
                                    return s.key === 'Pending';
                                });
                            }

                            if (localStorage.getItem("siteDashboardSearchby") == "In Progress" || localStorage.getItem("siteDashboardSearchby") == "Error") {
                                vm.defaults.statuses = _.filter(vm.statuses, s => {
                                    return s.key === localStorage.getItem("siteDashboardSearchby");
                                });
                                // delay this so that client is sorted and doesn't trigger a lookup of the site
                                setTimeout(function () {
                                    vm.filter(true);
                                }
                                    , 3000);
                                localStorage.setItem("siteDashboardSearchby", "undefined");
                            };

                            vm.status = "ready";

                            // focus on serial number field by default
                            vm.focusSerialNumber = true;

                            // delay this so that client is sorted and doesn't trigger a lookup of the site
                            $timeout(function () {
                                vm.defaults.sites = response.sites;
                                vm.siteId = response.sites[0].key;

                                if ($stateParams.search) {
                                    vm.filter(true);
                                }
                            });
                        }
                    }, function (error) {
                        vm.defaultErrors = [error];
                        vm.status = "error";
                        errorReporter.logError(error);
                    });
                });
        };

        $scope.editItem = function (item) {
            if (item !== undefined && item !== null) {
                WindowFactory.openItem({
                    component: 'maintenance',
                    headerOff: browserSvc.isMobile,
                    footerOff: true,
                    caption: amtXlatSvc.xlat('equipment.maintenanceSessionTitle', item.checkInDate.toString().toDateTimeFormat(appConfig.dateFormat.windowTitleDateTime), item.serialNumber.formatted),
                    shortCaption: amtXlatSvc.xlat('equipment.maintenanceSessionShortTitle', item.serialNumber.formatted, item.checkInDate.toString().toDateTimeFormat(appConfig.dateFormat.windowTitleDate)),
                    fullScreenOff: true,
                    canMinimise: true,
                    canClose: true,
                    windowRelatedRecordId: item.equipmentEventId, //use check in event as this is what is used when opening maintenance sessions from other places
                    initParams: {
                        vehicleId: item.equipmentId,
                        display: item.serialNumber.formatted + " - " + item.specification,
                        date: item.checkInDate,
                        id: item.id,
                        serialNumber: item.serialNumber.formatted
                    },
                    height: 725 + (browserSvc.isMobile ? 0 : WindowFactory.headerHeight),
                    width: 1100,
                    onDataChangeHandler: vm.filter
                });
            }
        };

        $scope.showVehicleDetails = function (item) {
            WindowFactory.openItem({
                caption: amtXlatSvc.xlat('equipment.openVehicle', item.serialNumber.formatted),
                component: 'vehicle-details',
                windowRelatedRecordId: item.equipmentId,
                initParams: {
                    equipmentId: item.equipmentId,
                    siteId: item.siteId,
                    serialNumber: item.serialNumber.formatted
                },
                width: 800,
                height: 850,
                modal: false
            });
        };

        function broadcastSessionChange() {
            $rootScope.$broadcast('RefreshMaintenanceSessionCounts');
        };

        function refreshWhenReady(recompile) {
            if (!vm.gridControl || !vm.gridControl.refresh || !vm.gridControl.recompile) {
                $timeout(function () { refreshWhenReady(recompile) });
            } else {

                var dateFrom = angular.copy(vm.criteria.filterValues.dateFrom);
                var dateTo = angular.copy(vm.criteria.filterValues.dateTo);

                vm.criteria.filterValues.dateFrom = ocDateSvc.removeLocalTimeZoneOffset(vm.criteria.filterValues.dateFrom);
                vm.criteria.filterValues.dateTo = ocDateSvc.removeLocalTimeZoneOffset(vm.criteria.filterValues.dateTo);
                vm.criteria.criteria.localClientDateTime = ocDateSvc.now().toLocaleString(('en-AU'));

                vm.showClients = vm.criteria.filterValues.clientIds.length !== 1;
                vm.showSites = vm.criteria.filterValues.siteIds.length !== 1;

                if (recompile === true) {
                    vm.gridControl.recompile();
                } else {
                    vm.gridControl.refresh();
                }

                $timeout(function () {
                    vm.criteria.filterValues.dateFrom = dateFrom;
                    vm.criteria.filterValues.dateTo = dateTo;
                    vm.criteria.criteria.localClientDateTime = ocDateSvc.now().toLocaleString(('en-AU'));

                    broadcastSessionChange();
                });
            }
        }

        vm.filter = function (recompile) {
            refreshWhenReady(recompile);
        };

        function init() {
            vm.loadDefaults();
        }

        init();
    }
