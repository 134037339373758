/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var FIELDS = ['bottom', 'left', 'right', 'top'];
/**
 * Represents the Kendo UI PDFMargin component for Angular.
 */
var PDFExportMarginComponent = /** @class */ (function () {
    function PDFExportMarginComponent() {
    }
    Object.defineProperty(PDFExportMarginComponent.prototype, "options", {
        /**
         * @hidden
         */
        get: function () {
            var options = {};
            for (var idx = 0; idx < FIELDS.length; idx++) {
                var field = FIELDS[idx];
                var value = this[field];
                if (typeof value !== 'undefined') {
                    options[field] = value;
                }
            }
            return options;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], PDFExportMarginComponent.prototype, "left", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], PDFExportMarginComponent.prototype, "top", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], PDFExportMarginComponent.prototype, "right", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], PDFExportMarginComponent.prototype, "bottom", void 0);
    PDFExportMarginComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-pdf-export-margin',
            template: ""
        })
    ], PDFExportMarginComponent);
    return PDFExportMarginComponent;
}());
exports.PDFExportMarginComponent = PDFExportMarginComponent;
