//import angular from 'angular';
import tmpl from "./stocktakeCommentPopup.html";



    class stocktakeCommentPopupCtrl {

        wnd: any = null;
        buttons: any = null;
        buttonStates: any = null;
        buttonMethods: any = null;

        api: any = {};
        stocktakeComment: TStocktakeComment;

        form: any;

        static $inject = ["$scope", "enums", "WindowFactory", "confirmSvc", "errorReporter"];

        constructor(private $scope: ng.IScope, private enums: any, private WindowFactory: any,
            private confirmSvc: any, private errorReporter: any) {

            $scope["vm"] = this;
        };

        public async $onInit() {

            this.stocktakeComment = angular.copy(this.wnd.initParams.comment) || {};

            this.buttons = [
                {
                    primary: true,
                    cancel: false,
                    value: 'common.save_label',
                    name: 'saveButton',
                    click: 'save',
                    type: this.enums.buttonTypes.button
                },
                {
                    primary: false,
                    cancel: true,
                    value: 'common.cancel_label',
                    name: 'cancelButton',
                    click: 'cancel',
                    type: this.enums.buttonTypes.button
                }
            ];

            this.buttonStates = {
                cancelButton: {
                    visible: true
                },
                saveButton: {
                    visible: true
                }
            };

            this.buttonMethods = {
                cancel: this.closeWindow,
                save: this.saveComment
            };

            // enable/disable the save button
            this.$scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing'], () => {
                this.buttonStates.saveButton.disabled = this.form.$invalid || this.form.$pristine || this.wnd.processing;
            });

            this.wnd.onClose = this.closeWindow;
        };

        public closeWindow = () => {
            this.confirmSvc.confirmSaveChange(this.form.$dirty).then(() => {
                this.form.$dirty = false;
                this.WindowFactory.closeWindow(this.wnd);
                return true;
            });

            return false;
        };

        public saveComment = () => {

            this.wnd.processing = true;

            try {

                if (this.wnd.onDataChanged) this.wnd.onDataChanged(this.stocktakeComment);

                this.form.$dirty = false;
                this.closeWindow();

            } catch (error) {
                this.errorReporter.logError(error);
            }

            this.wnd.processing = false;
        };
    }

    angular.module("app.stocktake")
        .controller("stocktakeCommentPopupCtrl", stocktakeCommentPopupCtrl)
        .component("stocktakeCommentPopup", {
            bindings: {
                initParams: "=",
                buttonMethods: "=",
                buttonStates: "=",
                buttons: "=",
                closeOnSave: "=",
                wnd: "="
            },
            template: tmpl,
            controllerAs: 'vm',
            controller: 'stocktakeCommentPopupCtrl'
        });
