//import angular from 'angular';
import tmpl from './closeAllWindowsConfirmation.html';


angular.module('app.common').component('closeAllWindowsConfirmation', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
		controller: ['$scope', 'enums', 'WindowFactory',
			function ($scope, enums, WindowFactory: IWindowFactory) {
			    var vm = this;
			    $scope.vm = this;
			    
			    this.$onInit = function () {
					vm.windowList = vm.initParams.windowList;
					
					vm.wnd.onClose = vm.onClose;
					
					vm.buttons = [
						{
							primary: true, 
							cancel: false, 
							value: 'common.proceed',
							name: 'proceedButton',
							click: 'proceed',
							type: enums.buttonTypes.button
						},
						{
							primary: false, 
							cancel: true, 
							value: 'common.cancel',
							name: 'cancelButton',
							click: 'cancel',
							type: enums.buttonTypes.button
						}
					];
					
					vm.buttonStates = {
						proceedButton: {
							visible: true, 
							disabled: false
						},
						cancelButton: {
							visible: true, 
							disabled: false
						}
					};
					
					vm.buttonMethods = {
						proceed: vm.onProceed,
						cancel: vm.onClose
					};
				};

			    function closeWindow() {
			        WindowFactory.closeWindow(vm.wnd);
			    }

			    vm.onClose = function () {
			        closeWindow();
			    };

			    vm.onProceed = function () {
			        vm.initParams.onProceed(true);
			        closeWindow();
			    };
			}
		]
    });
