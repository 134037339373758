//import angular from 'angular';
import AdminUrls from '../../../admin/adminUrls';
import tmpl from './UserMergePopup.html';


    angular.module('app.admin').component('userMerge',
        {
            bindings: {
                initParams: '=',
                buttonMethods: '=',
                buttonStates: '=',
                buttons: '=',
                closeOnSave: '=',
                wnd: '='
            },
            template: tmpl,
            controller: ['amtConstants', 'amtCommandQuerySvc', 'amtXlatSvc', 'enums', 'notifySvc', 'confirmSvc', 'WindowFactory', "adminUrls", "errorReporter",
                function userMergePopupCtrl(amtConstants, amtCommandQuerySvc, xlatSvc: IAmtXlatSvc, enums, notifySvc, confirmSvc, windowFactory: IWindowFactory, adminUrls: AdminUrls, errorReporter) {
                    var vm = this;
                    vm.apiUrls = amtConstants.url;
                    vm.adminUrls = adminUrls;
                    vm.isVisible = true;
                    vm.autoCompleteOptions = {
                        noDataTemplate: xlatSvc.xlat('framework.noDataFound')
                    };

                    vm.user = {};

                    this.$onInit = function () {
                        vm.buttons = [
                            {
                                primary: true,
                                cancel: true,
                                value: 'common.cancel_label',
                                name: 'cancelButton',
                                click: 'cancel',
                                type: enums.buttonTypes.button
                            },
                            {
                                primary: false,
                                cancel: false,
                                value: 'mergeUser.merge',
                                name: 'saveButton',
                                click: 'save',
                                type: enums.buttonTypes.button
                            }
                        ];

                        vm.buttonStates = {
                            saveButton: {
                                visible: true,
                                disabled: true
                            },
                            cancelButton: {
                                visible: true,
                                disabled: false
                            }
                        };

                        vm.buttonMethods = {
                            save: vm.merge,
                            cancel: vm.onCancel
                        };
                        vm.wnd.onClose = vm.onCancel;
                    }

                    vm.merge = merge;
                    vm.mergeUserPromise = null;


                    vm.users = {
                        serverPaging: false,
                        serverSorting: false,
                        transport: {
                            read: function (o) {
                                var model = {
                                    primaryUserId: vm.user.primary ? vm.user.primary.key : undefined,
                                    secondaryUserId: vm.user.secondary ? vm.user.secondary.key : undefined
                                };
                                amtCommandQuerySvc.post(vm.adminUrls.getUsersFiltered, model).then(
                                    function (response) {
                                        o.success(response.result);
                                    });
                            }
                        }
                    };

                    var checkDisabled = function () {
                        vm.buttonStates.saveButton.disabled = !(vm.user.primary && vm.user.secondary);
                    }

                    vm.changePrimary = function (e) {
                        // forced to select 
                        if (vm.user.primary && vm.user.primaryName !== vm.user.primary.name) {
                            delete vm.user.primary;
                            vm.buttonStates.saveButton.disabled = true;
                        }
                    };

                    vm.changeSecondary = function (e) {
                        // forced to select 
                        if (vm.user.secondary && vm.user.secondaryName !== vm.user.secondary.name) {
                            delete vm.user.secondary;
                            vm.buttonStates.saveButton.disabled = true;
                        }
                    };

                    vm.selectPrimary = function (e) {
                        vm.user.primary = (e).dataItem;
                        checkDisabled();
                    };

                    vm.selectSecondary = function (e) {
                        vm.user.secondary = (e).dataItem;
                        checkDisabled();
                    };

                    function merge() {

                        var model = {
                            primaryUserId: vm.user.primary.key,
                            secondaryUserId: vm.user.secondary.key
                        };

                        vm.mergeUserPromise = amtCommandQuerySvc.post(vm.adminUrls.mergeUsers, model).then(function (response) {
                            notifySvc.success(xlatSvc.xlat('mergeUser.usersMergedSuccessfully'));
                            windowFactory.closeWindow(vm.wnd);
                        }).catch(function (error) {
                            errorReporter.logError(error);
                        });
                    }

                    vm.onCancel = function () {
                        confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                            vm.form.$dirty = false;
                            windowFactory.closeWindow(vm.wnd);
                            return true;
                        });

                        return false;
                    };

                }
            ]
        });
