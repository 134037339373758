//import angular from 'angular';
import * as _ from 'underscore';
import OcDateSvc from '../../../services/ocDateSvc';
import tmpl from './details.html';


    angular.module('app.site').component('endOfMonthDetails',
        {
            bindings: {
                initParams: '=',
                buttonMethods: '=',
                buttonStates: '=',
                buttons: '=',
                closeOnSave: '=',
                wnd: '='
            },
            template: tmpl,
            controller: [
                '$scope', 'endOfMonthSvc', 'amtXlatSvc', 'ocConfigSvc', 'WindowFactory',
                'confirmSvc', 'notifySvc', 'errorReporter', 'enums', 'endOfMonthUrls', '$timeout', 'ocDateSvc',
                function (
                    $scope, endOfMonthSvc, amtXlatSvc, ocConfigSvc, WindowFactory,
                    confirmSvc, notifySvc, errorReporter, enums, endOfMonthUrls, $timeout, ocDateSvc: OcDateSvc
                ) {
                    var vm = this;

                    $scope.vm = this; // necessary for child directives to access what they need.

                    vm.form = {};
                    vm.enums = enums;

                    vm.svc = endOfMonthSvc;
                    vm.status = vm.enums.screenStatus.loading;

                    vm.trainingReceivedGridControl = {};
                    vm.continuousImprovementGridControl = {};

                    vm.endOfMonthUrls = endOfMonthUrls;
                    vm.endOfMonthStatus = null;

                    this.$onInit = function () {
                        vm.wnd.onClose = $scope.onClose;

                        vm.wnd.processing = true;

                        WindowFactory.addButton(
                            this,
                            'common.save_label', // caption
                            'save',
                            function () {
                                vm.save();
                            },
                            true // primary
                        );

                        WindowFactory.addButton(
                            this,
                            'endOfMonth.completeEndOfMonth', // caption
                            'complete',
                            function () {
                                vm.complete();
                            },
                            false // primary
                        );

                        vm.setStatus = function (status) {
                            vm.endOfMonthStatus = status.toLowerCase();
                            vm.wnd.rightCaption = amtXlatSvc.xlat('endOfMonth.status') + ": " + amtXlatSvc.xlat('endOfMonth.status_' + vm.endOfMonthStatus);
                        };

                        vm.trainingReceivedAddDescription = amtXlatSvc.xlat('endOfMonth.trainingReceivedAddDescription', 0);
                        vm.continuousImprovementAddDescription = amtXlatSvc.xlat('endOfMonth.continuousImprovementAddDescription', 0);

                        vm.monthYear = vm.wnd.initParams.monthYear;

                        vm.svc.getAsync(vm.wnd.initParams.id).then(
                            function (item) {
                                vm.data = item;

                                vm.setStatus(vm.data.status);

                                evaluateVisibility();

                                vm.trainingReceivedGridCriteria = {
                                    id: vm.data.id
                                };

                                vm.continuousImprovementGridCriteria = {
                                    id: vm.data.id
                                };

                                vm.selectTab(vm.firstVisibleTab());

                                vm.updateCompletedStatues();

                                vm.status = vm.enums.screenStatus.ready;

                                vm.wnd.processing = false;
                            },
                            function (error) {
                                vm.status = vm.enums.screenStatus.error;
                                errorReporter.logError(error);
                                vm.wnd.processing = false;
                            }
                        );

                        $scope.$watch("vm.form.$dirty", function (newValue) {
                            vm.wnd.isDirty = newValue;
                        });
                    };

                    vm.getData = function (tab, section) {
                        var results = [];

                        var key = tab.name;

                        if (section) {
                            key = key + "." + section.name;
                        }

                        for (var d = 0; d < vm.data.values.length; d++) {
                            if (vm.data.values[d].tab == key) {
                                results.push(vm.data.values[d]);
                            }
                        }

                        return results;
                    };

                    vm.updateCompletedStatues = function () {
                        for (var t = 0; t < vm.tabs.length; t++) {
                            var tab = vm.tabs[t];

                            if (tab.sections) {
                                for (var s = 0; s < tab.sections.length; s++) {
                                    var section = tab.sections[s];

                                    vm.updateComplete(section, vm.getData(tab, section));
                                }
                            } else {
                                vm.updateComplete(tab, vm.getData(tab))
                            }
                        }
                    };

                    vm.onTrainingReceivedSearch = function (response) {

                        var trainings = response.result;

                        for (var r = 0; r < trainings.length; r++) {
                            trainings[r].attendeesDisplay = (trainings[r].attendees && trainings[r].attendees.length > 0 ?
                                (trainings[r].attendees.length === 1 ? trainings[r].attendees[0].name : amtXlatSvc.xlat('common.multiple_label')) : null);
                        }

                        vm.trainingReceivedAddDescription = amtXlatSvc.xlat('endOfMonth.trainingReceivedAddDescription', response.total)

                        return response;
                    };

                    vm.onContinuousImprovementSearch = function (response) {

                        var ci = response.result;

                        for (var r = 0; r < ci.length; r++) {
                            ci[r].attachmentsDisplay = (ci[r].attachments && ci[r].attachments.length > 0 ?
                                (ci[r].attachments.length === 1 ? ci[r].attachments[0].name : amtXlatSvc.xlat('common.multiple_label')) : null);
                        }

                        vm.continuousImprovementAddDescription = amtXlatSvc.xlat('endOfMonth.continuousImprovementAddDescription', response.incompleteCount || 0);

                        return response;
                    };

                    vm.firstVisibleTab = function () {
                        for (var t = 0; t < vm.tabs.length; t++) {
                            if (vm.tabs[t].visible == true) {
                                return vm.tabs[t];
                            }
                        }
                    };

                    vm.setPristine = function () {
                        $timeout(function () {
                            if (vm.form) {
                                vm.form.$setPristine();
                            }
                        });
                    };

                    vm.getTabByVisibleIndex = function (index) {
                        var visibleIndex = -1;

                        for (var t = 0; t < vm.tabs.length; t++) {
                            if (vm.tabs[t].visible) {
                                visibleIndex++;
                            }

                            if (visibleIndex == index) {
                                return vm.tabs[t];
                            }
                        }
                    }

                    $scope.$watch('vm.activeTab', function (newValue, oldValue) {
                        if (newValue !== undefined) {
                            var newTab = vm.getTabByVisibleIndex(newValue);

                            if (!newTab) {
                                // shouldn't get here, but don't fail, just do nothing
                                return;
                            }

                            if (newTab !== vm.currentTab) { // if we're not switching back because of cancel
                                confirmSvc.confirmSaveChange(vm.wnd.isDirty && vm.currentTab.visible).then( // prompt if dirty
                                    function () {
                                        // change tab
                                        vm.setPristine();
                                        vm.selectTab(newTab);
                                    },
                                    function () {
                                        // cancel change tab
                                        vm.activeTab = oldValue;
                                    }
                                );
                            }
                        }
                    });

                    vm.selectTab = function (tab) {
                        if (tab == vm.currentTab) {
                            return;
                        }

                        if (vm.currentTab) {
                            vm.currentTab.selected = false;
                        }

                        vm.currentTab = tab;

                        if (tab) {
                            tab.selected = true;

                            if (!tab.sections) {
                                // apply the filter to the tab items
                                tab.filter = { tab: tab.name };

                                if (vm.data) {
                                    tab.values = vm.svc.getValues(vm.data, tab.filter);
                                    tab.hasValues = _.any(_.filter(tab.values, v => v.visible === true));
                                }

                                vm.canSave = tab.canSave;
                            } else {
                                if (!tab.currentSection) {
                                    // default to first section
                                    vm.setSection(tab.sections[0]);
                                }
                            }
                        } else {
                            vm.canSave = false;
                        }
                    };

                    vm.handleCompleteSuccess = function (response) {
                        if (response.data.completed === true) {

                            $scope.$parent.onDataChanged();

                            closeWindow();

                            WindowFactory.alert('endOfMonth.completeSuccess', ['common.close_label'], 'endOfMonth.completeSuccessMsg', [vm.monthYear]);

                        } else {
                            vm.svc.showValidationErrors(response.data.errors, vm.navigateToError);
                        }
                    };

                    vm.handleCompleteError = function (error) {
                        notifySvc.error("endOfMonth.completeError");
                    };

                    vm.complete = function () {
                        vm.wnd.processing = true;
                        vm.svc.completeAsync({ endOfMonthId: vm.data.id, date: ocDateSvc.removeLocalTimeZoneOffset(new Date()) }).then(
                            vm.handleCompleteSuccess,
                            vm.handleCompleteError
                        ).finally(function () {
                            vm.wnd.processing = false;
                        });
                    };

                    vm.navigateToError = function (error) {

                        var tab = _.find(vm.tabs, function (t) { return t.name === error.tab; });
                        var tabIndex = _.findIndex(_.filter(vm.tabs, function (a) { return a.visible === true; }), function (t) { return t.name === error.tab; });

                        if (tab && tabIndex > -1) {
                            vm.activeTab = tabIndex;
                        }

                        $timeout(function () {
                            if (error.section && tab.sections && tab.sections.length > 0) {
                                var section = _.find(tab.sections, function (s) { return s.name === error.section; });

                                if (section) {
                                    vm.setSection(section);
                                }
                            }
                        });
                    };

                    vm.getContext = function () {
                        return vm.currentTab.sections ? vm.currentTab.currentSection : vm.currentTab;
                    };

                    vm.save = function () {
                        var type = vm.getContext().type;

                        switch (type.toLowerCase()) {
                            case "namevaluepairlist":
                            case "kpi":
                                vm.saveValues();
                        }
                    };

                    vm.updateComplete = function (context, values) {
                        var cnt = 0;

                        for (var v = 0; v < values.length; v++) {
                            if (!values[v].visible || (values[v].value !== null && values[v].value !== undefined) || (values[v].hideOptionalMandatory && values[v].hideOptionalMandatory.toLowerCase() !== "mandatory")) {
                                cnt++;
                            }
                        }

                        context.complete = cnt == values.length;
                    };

                    vm.saveValues = function () {
                        vm.wnd.processing = true;

                        var criteria = {
                            endOfMonthId: vm.data.id,
                            values: vm.currentTab.values
                        };

                        vm.svc.saveValuesAsync(criteria).then(
                            function (response) {

                                notifySvc.success(amtXlatSvc.xlat("endOfMonth.saveSuccessful"));

                                vm.svc.copyValues(criteria.values, vm.data.values);                  

                                evaluateVisibility();

                                vm.currentTab.values = vm.getData(vm.currentTab, vm.currentTab.currentSection);
                                vm.currentTab.hasValues = _.any(_.filter(vm.currentTab.values, function (v) { return v.visible === true; }));

                                vm.updateComplete(vm.getContext(), criteria.values);                                         

                                vm.wnd.processing = false;

                                vm.setStatus(enums.endOfMonthStatuses.incomplete);
                                vm.data.isComplete = false;

                                $scope.$parent.onDataChanged();

                                vm.setPristine();
                            },
                            function (error) {
                                notifySvc.error(amtXlatSvc.xlat("endOfMonth.saveError"));
                                vm.wnd.processing = false;
                            }
                        );
                    };

                    function closeWindow() {
                        WindowFactory.closeWindow(vm.wnd);
                    }

                    $scope.onClose = function () {
                        confirmSvc.confirmSaveChange(vm.wnd.isDirty).then(function () {
                            vm.form.$dirty = false;
                            closeWindow();
                            return true;
                        });

                        return false;
                    };

                    $scope.$watchGroup(['vm.canSave', 'vm.wnd.isDirty', 'vm.wnd.processing', 'vm.endOfMonthStatus'], function () {
                        vm.buttonStates.save.visible = vm.canSave === true;
                        vm.buttonStates.save.disabled = vm.wnd.isDirty !== true || vm.wnd.processing;

                        vm.buttonStates.complete.visible = !!vm.endOfMonthStatus && vm.endOfMonthStatus.toLowerCase() === enums.endOfMonthStatuses.incomplete;
                        vm.buttonStates.complete.disabled = !vm.endOfMonthStatus || vm.endOfMonthStatus.toLowerCase() !== enums.endOfMonthStatuses.incomplete || vm.wnd.processing;
                    });

                    vm.setSection = function (section) {
                        confirmSvc.confirmSaveChange(vm.wnd.isDirty && !!vm.currentTab.currentSection).then(
                            function () {
                                vm.setPristine();

                                if (vm.currentTab.currentSection) {
                                    vm.currentTab.currentSection.selected = false;
                                }

                                vm.currentTab.currentSection = section;

                                if (section) {
                                    // apply the filter to the tab and section
                                    vm.currentTab.filter = { tab: vm.currentTab.name + "." + section.name };

                                    section.selected = true;
                                    vm.currentTab.canSave = section.canSave;

                                    if (vm.data) {
                                        vm.currentTab.values = vm.svc.getValues(vm.data, vm.currentTab.filter);      
                                        vm.currentTab.hasValues = _.any(_.filter(vm.currentTab.values, function (v) { return v.visible === true; }));
                                    }

                                    vm.canSave = section.canSave;

                                    $timeout(function () {
                                        if (section.onLoad) {
                                            section.onLoad();
                                        }
                                    });

                                } else {
                                    vm.canSave = false;
                                }
                            },
                            function () {
                            }
                        );
                    };

                    $scope.actionClick = function (action) {
                        action.click();
                    };

                    // training received actions
                    $scope.showTrainingReceived = function (item) {
                        vm.svc.openTraining(item, vm.filterTrainingReceived, vm.data.id);
                    };

                    $scope.addTrainingReceived = function () {
                        vm.svc.openTraining(null, vm.filterTrainingReceived, vm.data.id);
                    };

                    $scope.deleteTrainingReceived = function (item) {
                        confirmSvc.confirmMessage('endOfMonth.confirmDeleteTraining_title', 'endOfMonth.confirmDeleteTraining_text', null).then(function () {

                            vm.wnd.processing = true;

                            return vm.svc.deleteTrainingReceivedAsync(item).then(function (response) {
                                notifySvc.success(amtXlatSvc.xlat("endOfMonth.trainingReceivedDeleteSuccessful"));
                                vm.filterTrainingReceived();
                            }).catch(function (error) {
                                errorReporter.logError(error);
                            }).finally(function () {
                                vm.wnd.processing = false;
                            });
                        });
                    };

                    function awaitAndRetry(callback) {
                        $timeout(function () {
                            callback();
                        }, 100);
                    }

                    vm.filterTrainingReceived = function () {
                        if (vm.trainingReceivedGridControl && vm.trainingReceivedGridControl.refresh) {
                            vm.trainingReceivedGridControl.refresh();
                        } else {
                            awaitAndRetry(vm.filterTrainingReceived);
                        }
                    };

                    // continuous improvement actions
                    $scope.showContinuousImprovement = function (item) {
                        vm.svc.openContinuousImprovement(item, vm.filterContinuousImprovement, item.siteMonthReportId);
                    };

                    $scope.addContinuousImprovement = function () {
                        vm.svc.openContinuousImprovement(null, vm.filterContinuousImprovement, vm.data.id);
                    };

                    $scope.deleteContinuousImprovement = function (item) {
                        confirmSvc.confirmMessage('endOfMonth.confirmDeleteContinuousImprovement_title', 'endOfMonth.confirmDeleteContinuousImprovement_text', null).then(function () {

                            vm.wnd.processing = true;

                            return vm.svc.deleteContinuousImprovementAsync(item).then(function (response) {
                                notifySvc.success(amtXlatSvc.xlat("endOfMonth.continuousImprovementDeleteSuccessful"));
                                vm.filterContinuousImprovement();
                            }).catch(function (error) {
                                errorReporter.logError(error);
                            }).finally(function () {
                                vm.wnd.processing = false;
                            });
                        });
                    };

                    vm.filterContinuousImprovement = function () {
                        if (vm.continuousImprovementGridControl && vm.continuousImprovementGridControl.refresh) {
                            vm.continuousImprovementGridControl.refresh();
                        } else {
                            awaitAndRetry(vm.filterContinuousImprovement);
                        }
                    };

                    function evaluateVisibility() {
                        vm.tabs.forEach(function (t) {
                            setTabVisibility(t);
                        });

                        vm.data.values.forEach(function (v) {
                            v.visible = (v.hideOptionalMandatory.toLowerCase() !== 'hide' || (v.value !== null && v.value !== undefined));
                        });
                    };

                    function setTabVisibility(tab) {

                        var tabNames = [tab.name];

                        if (tab.sections) {
                            tab.sections.forEach(function (s) {
                                tabNames.push(tab.name + '.' + s.name);                                
                            });
                        }

                        tab.visible = _.any(_.filter(vm.data.values, function (v) {
                            return tabNames.indexOf(v.tab) > -1 && (v.hideOptionalMandatory.toLowerCase() !== 'hide' || (v.value !== null && v.value !== undefined));
                        }));                        
                    };

                    vm.tabs = [
                        {
                            name: 'haulage',                            
                            type: 'NameValuePairList',
                            buttons: [],
                            showUnits: true,
                            canSave: true
                        },
                        {
                            name: 'weather',
                            type: 'NameValuePairList',
                            buttons: [],
                            showUnits: true,
                            canSave: true
                        },
                        {
                            name: 'operational',
                            type: 'Operational',
                            buttons: [],
                            sections: [
                                {
                                    name: 'personnel',
                                    type: 'NameValuePairList',
                                    selected: true,
                                    canSave: true
                                },
                                {
                                    name: 'kpis',
                                    type: 'KPI',
                                    canSave: true
                                },
                                {
                                    name: 'qualifications',
                                    type: 'NameValuePairList',
                                    canSave: true
                                },
                                {
                                    name: 'trainingDelivered',
                                    type: 'NameValuePairList',
                                    canSave: true
                                },
                                {
                                    name: 'trainingReceived',
                                    type: 'TrainingReceived',
                                    canSave: false,
                                    complete: true,
                                    onLoad: vm.filterTrainingReceived
                                },
                                {
                                    name: 'engineering',
                                    type: 'NameValuePairList',
                                    canSave: true
                                },
                                {
                                    name: 'continuousImprovement',
                                    type: 'ContinuousImprovement',
                                    canSave: false,
                                    complete: true,
                                    onLoad: vm.filterContinuousImprovement
                                }
                            ]
                        }
                    ];
                }
            ]
        });
