/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var serial = 0;
/**
 * Represents an error message that will be shown underneath
 * a Kendo control or native HTML form-bound component after a validation.
 */
var ErrorComponent = /** @class */ (function () {
    function ErrorComponent() {
        this.hostClass = true;
        /**
         * Specifies the alignment of the Error message.
         *
         * The possible values are:
         * * (Default) `start`
         * * `end`
         */
        this.align = 'start';
        /**
         * @hidden
         */
        this.id = "kendo-error-" + serial++;
        this.roleAttribute = 'alert';
    }
    Object.defineProperty(ErrorComponent.prototype, "startClass", {
        get: function () {
            return this.align === 'start';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ErrorComponent.prototype, "endClass", {
        get: function () {
            return this.align === 'end';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ErrorComponent.prototype, "idAttribute", {
        get: function () {
            return this.id;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        core_1.HostBinding('class.k-form-error'),
        tslib_1.__metadata("design:type", Boolean)
    ], ErrorComponent.prototype, "hostClass", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ErrorComponent.prototype, "align", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('attr.role'),
        tslib_1.__metadata("design:type", String)
    ], ErrorComponent.prototype, "roleAttribute", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-text-start'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ErrorComponent.prototype, "startClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-text-end'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ErrorComponent.prototype, "endClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.id'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], ErrorComponent.prototype, "idAttribute", null);
    ErrorComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-formerror',
            template: "\n        <ng-content></ng-content>\n    "
        })
    ], ErrorComponent);
    return ErrorComponent;
}());
exports.ErrorComponent = ErrorComponent;
