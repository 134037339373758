import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Widget } from '../../service/Interface/SiteDashboard.interface';
import { TotalDownTimeUi } from '../../service/Interface/WidgetConstant.interface';
import { WidgetRxjsService } from '../../service/rxjs/WidgetRxjs.service';
import { WidgetHelper } from '../../service/widgetHelper/Widget.helper';
import { WidgetConstant } from '../../service/widgetHelper/WidgetConstant';
import { Subscription } from 'rxjs';
import OcConfigSvc from '../../../../../services/ocConfigSvc'

@Component({
    selector: 'widget-total-down-time',
    templateUrl: './total-down-time.component.html',
    styleUrls: ['./../../site-dashboard.component.scss']
})

//Completed
export class SiteDashboardTotalDownTimeComponent implements OnInit, OnDestroy {
    public resultSubscription: Subscription;
    public ui: TotalDownTimeUi;
    public totalDowntime: number = 0;
    public unit: string;
    public isIncludeSubtext: boolean;
    public isApiError: boolean = false;

    constructor(private widgetRxjs: WidgetRxjsService, private widgetHelper: WidgetHelper, private widgetConstant: WidgetConstant, private ocConfidSvc: OcConfigSvc) {
        this.ui = this.widgetConstant.getTotalDownTimeWidgetUi();
    }

    @Input() set showSubtext(widget: Widget) {
        let isIncludeSubtextValue = this.widgetHelper.getIncludeSubtext(widget.detailElement);
        this.isIncludeSubtext = isIncludeSubtextValue;
    }

    ngOnInit() {
        this.subscribeTotalDownTimeResult();
    }

    ngOnDestroy() {
        this.resultSubscription.unsubscribe();
    }

    subscribeTotalDownTimeResult() {
        this.resultSubscription = this.widgetRxjs.totalDownTime.subscribe(
            value => {
                this.totalDowntime = value;
                this.unit = this.widgetHelper.getHourUnit(value);
            });
    }

    public setReportName() {
        var reports = [];
        reports[0] = this.ocConfidSvc.getLanguage() == 'en' ? 'Changes' : ' Cambios';
        reports[1] = this.ocConfidSvc.getLanguage() == 'en' ? 'Component Change Downtime' : 'Cambio de componentes'
        localStorage.setItem('siteDashboardReportName', JSON.stringify(reports));
    }

}