import { Injectable } from '@angular/core';
import {
    CompletedChangeWidgetElement,
    ConditionMonitoringWidget, ConditionMonitoringWidgetResult,
    ErrorsInWidgetElement,
    LiveForecastRimResponse, LiveForecastRimWidget, LiveForecastTyreResponse,
    LiveForecastTyreWidget, PressureCheckVehiceWidgetResult,
    PressureCheckWidget, PressureKpiResult, PressureKPIWidget,
    ScrapLifeTrendOverviewResponse, ScrapLifeTrendWidget, SiteHealthRateWidget, TyreInInspectionElement
} from '../Interface/Widget.interface';
import { WidgetHelperConstant } from '../Interface/WidgetConstant.interface';
import { ScrapLifeTrendWidgetHelper } from '../../service/widgetHelper/ScrapLifeTrendWidget.helper';
import { LiveForecastRimWidgetHelper } from '../widgetHelper/LiveForecastRimWidget.helper'
import { LiveForecastTyreWidgetHelper } from '../widgetHelper/LiveForecastTyreWidget.helper';
import { WidgetConstant } from '../widgetHelper/WidgetConstant';
import OcConfigSvc from '../../../../../services/ocConfigSvc';
import { HttpClient } from '@angular/common/http';
import { WidgetHelper } from '../widgetHelper/Widget.helper';
import { WidgetRxjsService } from '../rxjs/WidgetRxjs.service';
import { WidgetApiNotificationService } from './WidgetApiNotification.helper';
import { SiteDashboardWidgetApiTrigger, SiteDashboardWidgetIdDependancyApiCriteria } from '../Interface/SiteDashboard.interface';


@Injectable({
    providedIn: 'root'
})
    //getTyreInInspectionWidget

//Widget API Helper is to help to get API and Set value by using RXJS
export class WidgetApiHelper {
    private readonly getCompletedChangeWidgetUrl: string = baseApiUrl + 'layout/dashboard/widget/getCompletedChangeWidget';
    private readonly getTotalDownTimeUrl: string = baseApiUrl + 'equipment/component/getTotalDowntime';
    private readonly getErrorWidgetUrl: string = baseApiUrl + 'layout/dashboard/widget/getErrorsWidget';
    private readonly getMaintenanceSessionCountsUrl: string = baseApiUrl + 'equipment/vehicle/getMaintenanceSessionCounts';
    private readonly getTyreInInspectionUrl: string = baseApiUrl + 'layout/dashboard/widget/getTyreInInspectionWidget';
    private readonly getSiteHealthRateUrl: string = baseApiUrl + 'layout/dashboard/widget/getSiteHealthRate';
    private readonly getScrapLifeTrendWidgetUrl: string = baseApiUrl + 'layout/dashboard/widget/getScrapLifeTrend';
    private readonly getPressureKpiWidgetUrl: string = baseApiUrl + 'layout/dashboard/widget/getPressureKpi';
    private readonly getPressureCheckWidgetUrl: string = baseApiUrl + 'layout/dashboard/widget/getPressureCheckVehicle';
    private readonly getLiveForecastRimNdtUrl = baseApiUrl + 'layout/dashboard/widget/getLiveForecastRimResult';
    private readonly getLiveForecastTyreUrl = baseApiUrl + 'layout/dashboard/widget/getLiveForecastTyreResult';
    private readonly getConditionMonitoringUrl = baseApiUrl + 'layout/dashboard/widget/getConditionMonitoring';

    constructor(
        private http: HttpClient,
        private widgetApiNotification: WidgetApiNotificationService, private widgetHelper: WidgetHelper,
        private widgetRxjs: WidgetRxjsService, private ocConfig: OcConfigSvc, private constant: WidgetConstant,
        private scrapLifeTrendWidgetHelper: ScrapLifeTrendWidgetHelper, private liveForecastTyreHelper: LiveForecastTyreWidgetHelper,
        private liveForecastRimtHelper: LiveForecastRimWidgetHelper) {
    }

    //Completed Changed Widget API
    private async getChangedCompletedWidgetResult() {
        //Maintenance Session Count
        let completedChangeApi = await this.http.get<CompletedChangeWidgetElement>(this.getCompletedChangeWidgetUrl)
            .subscribe(response => {
                let isErrorResponse = this.isErrorResponse(response);

                if (isErrorResponse) {
                    this.widgetApiNotification.sendErrorChangesCompletedWidgetNotification();
                }
                else {
                    this.widgetRxjs.setCompletedChangeWidget(response);
                }
                
                this.widgetRxjs.addCurrentApiIndex();
                completedChangeApi.unsubscribe();
            });
    }


    //Error Widget API
    private async getErrorWidgetResult() {
        let errorApi = this.http.get<ErrorsInWidgetElement>(this.getErrorWidgetUrl).subscribe(
            response => {
                let isErrorResponse = this.isErrorResponse(response);

                if (isErrorResponse) {
                    this.widgetApiNotification.sendErrorDataErrorWidgetNotification();
                }
                else {
                    this.widgetRxjs.setErrorInWidget(response);
                }
                this.widgetRxjs.addCurrentApiIndex();
                errorApi.unsubscribe();
            });
    }


    //Tyre In Inspection Widget API
    private async getTyreInInspectionWidgetResult() {
        //Inspection Count
        let inspectionApi = await this.http.get<TyreInInspectionElement>(this.getTyreInInspectionUrl)
            .subscribe(response => {
                let isErrorResponse = this.isErrorResponse(response);

                if (isErrorResponse) {
                    this.widgetApiNotification.sendErrorDataErrorWidgetNotification();
                }
                else {
                    this.widgetRxjs.setTyreInInspectionWidget(response);
                }
                this.widgetRxjs.addCurrentApiIndex();
                inspectionApi.unsubscribe();
            });
    }

    //Total Down Time Widget API
    private async getTotalDownTimeWidgetResult() {

        //Total Down Time API
        let totalDownTimeApi = await this.http.get<number>(this.getTotalDownTimeUrl)
            .subscribe(response => {
                let isErrorResponse = this.isErrorResponse(response);

                if (isErrorResponse) {
                    this.widgetApiNotification.sendErrorTotalDownTimeWidgetNotification();
                }
                else {
                    let number = Math.round(Number(response));
                    number = this.getValidateValue(number);
                    this.widgetRxjs.setTotalDownTime(number);
                }
                this.widgetRxjs.addCurrentApiIndex();
                totalDownTimeApi.unsubscribe();
            });
    }


    //Site Health Rating Widget Result
    private async getSiteHealthRatingResult() {
        let siteHealthRateApi = await this.http.get<SiteHealthRateWidget>(this.getSiteHealthRateUrl)
            .subscribe(response => {
                let isErrorResponse = this.isErrorResponse(response);

                if (isErrorResponse) {
                    this.widgetApiNotification.sendErrorSiteHealthRateNotification();
                }
                else {
                    let healthRateValue = Number(response.rate) * 100;
                    healthRateValue = this.getValidateValue(healthRateValue);
                    this.widgetRxjs.setSiteHealthRate(healthRateValue);
                    this.widgetRxjs.setSiteHealthElement(response.siteHealthRateElement);
                }
                this.widgetRxjs.addCurrentApiIndex();
                siteHealthRateApi.unsubscribe();
            });
    }


    //Vehicle In Maintenance Widget API
    private async getVehicleInMaintenanceWidgetResult() {
        let widgetConstant: WidgetHelperConstant = this.constant.getWidgetHelperConstant();

        let vehicleMaintenanceApi = await this.http.get(this.getMaintenanceSessionCountsUrl).subscribe(
            response => {
                let isErrorResponse: boolean = this.isErrorResponse(response);

                if (isErrorResponse) {
                    this.widgetApiNotification.sendErrorVehicleInMaintenanceWidgetNotification();
                }
                else {
                    for (const [key, value] of Object.entries(response)) {
                        if (key == widgetConstant.inProgress) {
                            let number = Math.round(Number(value));
                            number = this.getValidateValue(number);
                            this.widgetRxjs.setMaintenanceSessionsInProgress(number);
                        }
                        if (key == widgetConstant.pending) {
                            let number = Math.round(Number(value));
                            number = this.getValidateValue(number);
                            this.widgetRxjs.setMaintenanceSessionsPending(number);
                        }
                    }
                }
                this.widgetRxjs.addCurrentApiIndex();
                vehicleMaintenanceApi.unsubscribe();
            });
    }


    //Scrap Life Trend Widget API
    private async getScrapLifeTrendWidgetResult(criteria: SiteDashboardWidgetIdDependancyApiCriteria) {
        let userName = this.ocConfig.getUserName();
        let header = this.setHeader();
        let scrapLifeTrendCriteria = this.setScrapLifeTrendWidgetCriteria(criteria.siteDashboardWidgetId, userName);

        let scrapLifeTrendApi = await this.http.post<ScrapLifeTrendOverviewResponse>(this.getScrapLifeTrendWidgetUrl, scrapLifeTrendCriteria, { headers: header })
            .subscribe(response => {
                let isErrorResponse: boolean = this.isErrorResponse(response);

                if (isErrorResponse) {
                    this.widgetApiNotification.sendErrorNotification(criteria.dashboardName, criteria.widgetTitle);
                    let result: ScrapLifeTrendWidget = this.scrapLifeTrendWidgetHelper.setErrorResult(criteria.siteDashboardWidgetId);
                    this.widgetRxjs.updateScrapLifeTrend(result);
                }
                else {
                    let result: ScrapLifeTrendWidget = this.scrapLifeTrendWidgetHelper.setDefaultScrapLifeTrendWidgetResult(criteria.siteDashboardWidgetId, response);
                    this.widgetRxjs.updateScrapLifeTrend(result);
                }
                this.widgetRxjs.addCurrentApiIndex();
                scrapLifeTrendApi.unsubscribe();
            });
    }

    //Pressure KPI Widget API
    private async getPressureKpiWidgetResult(criteria: SiteDashboardWidgetIdDependancyApiCriteria) {
        let header = this.setHeader();
        let body = this.setCriteria(criteria.siteDashboardWidgetId);
        let resultItem: PressureKPIWidget = {
            siteDashboardWidgetId: criteria.siteDashboardWidgetId,
            hasTargetKpi: false,
            receivedResult: true,
            targetKpiValue: 0,
            currentKpiValue: 0,
            maxKpiValue: 0,
            minorUnit: 0,
            majorUnit: 0
        };

        let pressureKpiApi = await this.http.post<PressureKpiResult>(this.getPressureKpiWidgetUrl, body, { headers: header })
            .subscribe(response => {
                let isErrorResponse = this.isErrorResponse(response);

                if (isErrorResponse) {
                    this.widgetApiNotification.sendErrorNotification(criteria.dashboardName, criteria.widgetTitle);
                }
                else {
                    resultItem.receivedResult = true;
                    resultItem.hasTargetKpi = response.hasTargetKpi;
                    resultItem.targetKpiValue = response.targetKpiValue;
                    resultItem.currentKpiValue = response.currentKpiValue;
                    resultItem.maxKpiValue = response.targetKpiValue * 1.5;
                    resultItem.minorUnit = resultItem.maxKpiValue / 30;
                    resultItem.majorUnit = resultItem.minorUnit * 5;
                    this.widgetRxjs.updatePressureKpi(resultItem);
                }
                this.widgetRxjs.addCurrentApiIndex();
                pressureKpiApi.unsubscribe();
            });
    }

    //Live Forecast Rim
    private async getLiveForecastRimNdtResult(criteria: SiteDashboardWidgetIdDependancyApiCriteria) {
        let header = this.setHeader();
        let body = this.setCriteria(criteria.siteDashboardWidgetId);
        let resultItem: LiveForecastRimWidget = {
            siteDashboardWidgetId: criteria.siteDashboardWidgetId,
            result: {
                hasLiveForecast: false,
                allRims: {
                    filterOption: null,
                    overDue: null,
                    next2Weeks: null,
                    next2To4Weeks: null,
                    next4To8Weeks: null,
                    next8To12Weeks: null
                },
                ndtRim: null,
                scrapRim: null
            }
        };

        let liveForecastRimNdtApi = await this.http.post<LiveForecastRimResponse>(this.getLiveForecastRimNdtUrl, body, { headers: header })
            .subscribe(response => {
                let isErrorResponse = this.isErrorResponse(response);

                if (isErrorResponse) {
                    this.widgetApiNotification.sendErrorNotification(criteria.dashboardName, criteria.widgetTitle);
                }
                else {
                    resultItem.result.hasLiveForecast = response.hasLiveForecast;
                    resultItem.result = response;
                    if (response.hasLiveForecast) {
                        resultItem.result.allRims.filterOption = this.liveForecastRimtHelper.setFilterOption(response.allRims);
                        resultItem.result.scrapRim.filterOption = this.liveForecastRimtHelper.setFilterOption(response.scrapRim);
                        resultItem.result.ndtRim.filterOption = this.liveForecastRimtHelper.setFilterOption(response.ndtRim);
                    }

                    this.widgetRxjs.updateLiveForecastRimNdt(resultItem);
                }
                this.widgetRxjs.addCurrentApiIndex();
                liveForecastRimNdtApi.unsubscribe();
            });
    }


    //Live Forecast Tyre
    private async getLiveForecastTyreResult(criteria: SiteDashboardWidgetIdDependancyApiCriteria) {
        let header = this.setHeader();
        let body = this.setCriteria(criteria.siteDashboardWidgetId);
        let resultItem: LiveForecastTyreWidget = {
            siteDashboardWidgetId: criteria.siteDashboardWidgetId,
            result: {
                hasLiveForecast: false,
                allTyres: null,
                newChangeTyres: null,
                endLifeTyres: null
            }
        };

        let liveForecastTyreApi = await this.http.post<LiveForecastTyreResponse>(this.getLiveForecastTyreUrl, body, { headers: header })
            .subscribe(response => {
                let isErrorResponse = this.isErrorResponse(response);

                if (isErrorResponse) {
                    this.widgetApiNotification.sendErrorNotification(criteria.dashboardName, criteria.widgetTitle);
                }
                else {
                    resultItem.result.hasLiveForecast = response.hasLiveForecast;
                    resultItem.result = response;

                    if (response.hasLiveForecast) {
                        resultItem.result.allTyres.filterOption = this.liveForecastTyreHelper.setFilterOption(response.allTyres);
                        resultItem.result.endLifeTyres.filterOption = this.liveForecastTyreHelper.setFilterOption(response.endLifeTyres);
                        resultItem.result.newChangeTyres.filterOption = this.liveForecastTyreHelper.setFilterOption(response.newChangeTyres);
                    }
                    this.widgetRxjs.updateLiveForecastTyre(resultItem);
                }
                this.widgetRxjs.addCurrentApiIndex();
                liveForecastTyreApi.unsubscribe();
            });
    }


    //Pressure Chcek Widget API
    private async getPressureCheckWidgetResult(criteria: SiteDashboardWidgetIdDependancyApiCriteria) {
        let header = this.setHeader();
        let body = this.setCriteria(criteria.siteDashboardWidgetId);
        let resultItem: PressureCheckWidget = {
            siteDashboardWidgetId: criteria.siteDashboardWidgetId,
            reportResult: [],
            hasReceivedResult: true
        };

        let pressureCheckApi = await this.http.post<PressureCheckVehiceWidgetResult[]>(this.getPressureCheckWidgetUrl, body, { headers: header })
            .subscribe(response => {
                let isErrorResponse = this.isErrorResponse(response);

                if (isErrorResponse) {
                    this.widgetApiNotification.sendErrorNotification(criteria.dashboardName, criteria.widgetTitle);
                }
                else {
                    resultItem.reportResult = response;
                    this.widgetRxjs.updatePressureCheck(resultItem);
                }
                this.widgetRxjs.addCurrentApiIndex();
                pressureCheckApi.unsubscribe();
            });
    }


    //Condition Monitoring
    //TODO:
    private async getConditionMonitoringWidgetResult(criteria: SiteDashboardWidgetIdDependancyApiCriteria) {
        let header = this.setHeader();
        let body = this.setCriteria(criteria.siteDashboardWidgetId);
        let resultItem: ConditionMonitoringWidget = {
            siteDashboardWidgetId: criteria.siteDashboardWidgetId,
            receivedResult: true,
            result: {
                displayBy: {
                    isActionStatus: false,
                    isLastInspectedDate: false,
                    isRecommendedAction: false
                },
            },
        };

        let conditionMonitoringApi = await this.http.post<ConditionMonitoringWidgetResult>(this.getConditionMonitoringUrl, body, { headers: header })
            .subscribe(response => {
                let isErrorResponse = this.isErrorResponse(response);

                if (isErrorResponse) {
                    this.widgetApiNotification.sendErrorNotification(criteria.dashboardName, criteria.widgetTitle);
                } else {
                    resultItem.result = response;
                    this.widgetRxjs.updateConditionMonitoring(resultItem);
                }
                this.widgetRxjs.addCurrentApiIndex();
                conditionMonitoringApi.unsubscribe();
            });
    }


    //Determine the widget Api response is error by key value
    private isErrorResponse(res: any) {
        let keyValue = this.getObjectKeyValue(res);
        let hasExceptionKey = keyValue.filter(key => { return key === 'exception' }).length > 0;
        let hasStatusKey = keyValue.filter(key => { return key === 'status' }).length > 0;
        let hasErrorsKey = keyValue.filter(key => { return key === 'errors' }).length > 0;
        let hasDataKey = keyValue.filter(key => { return key === 'data' }).length > 0;

        return hasDataKey && hasErrorsKey && hasStatusKey && hasExceptionKey;
    }


    //Get Object Key Value List
    private getObjectKeyValue(res: any) {
        let keyValue = [];
        Object.entries(res).find(([key, value]) => {
            keyValue.push(key)
        });
        return keyValue;
    }


    //Create header
    private setHeader() {
        return { 'content-type': 'application/json' };
    }


    //Create criteria for widgets
    private setCriteria(siteDashboardWidgetId: string) {
        return { siteDashboardWidgetId: siteDashboardWidgetId};
    }


    //create criteria for ScrapLifeTrendWidget
    private setScrapLifeTrendWidgetCriteria(siteDashboardWidgetId: string, userName: string) {
        return { siteDashboardWidgetId: siteDashboardWidgetId, userName: userName};
    }


    //Validate number
    private getValidateValue(value: number) {
        let isNullOrUndefinedOrIsNaN: boolean = value === null || value === undefined || Number.isNaN(Number(value));
        return isNullOrUndefinedOrIsNaN ? 0 : value;
    }


    //This trigger the condition monitoring API based on the number of sitedashboardwidgetIds
    private async triggerConditionMonitoringWidgetApi(criterias: Array<SiteDashboardWidgetIdDependancyApiCriteria>) {
        let hasConditionMonitoring: boolean = criterias.length > 0;

        if (hasConditionMonitoring) {
            criterias.forEach(criteria => {
                this.getConditionMonitoringWidgetResult(criteria);
            });
        }
    }


    //This trigger the pressure kpi API based on the number of sitedashboardwidgetIds
    private async triggerPressureMaintenanceKpiApi(criterias: Array<SiteDashboardWidgetIdDependancyApiCriteria>) {
        let hasPressureKPI: boolean = criterias.length > 0;

        if (hasPressureKPI) {
            criterias.forEach(criteria => {
                this.getPressureKpiWidgetResult(criteria);
            });
        }
    }


    //This trigger the scrap life trend API based on the number of sitedashboardwidgetIds
    private async triggerScrapLifeTrendApi(criterias: Array<SiteDashboardWidgetIdDependancyApiCriteria>) {
        let hasScrapLifeTrend: boolean = criterias.length > 0;

        if (hasScrapLifeTrend) {
            criterias.forEach(criteria => {
                this.getScrapLifeTrendWidgetResult(criteria);
            });
        }
    }


    //This trigger the rim ndt API based on the number of sitedashboardwidgetIds
    private async triggerLiveForecastRimNdtApi(criterias: Array<SiteDashboardWidgetIdDependancyApiCriteria>) {
        let hasRimNdt: boolean = criterias.length > 0;

        if (hasRimNdt) {
            criterias.forEach(criteria => {
                this.getLiveForecastRimNdtResult(criteria);
            });
        }
    }


    //This trigger the forecast tyre API based on the number of sitedashboardwidgetIds
    private async triggerLiveForecastTyreApi(criterias: Array<SiteDashboardWidgetIdDependancyApiCriteria>) {
        let hasForecastTyre = criterias.length > 0;

        if (hasForecastTyre) {
            criterias.forEach(criteria => {
                this.getLiveForecastTyreResult(criteria);
            });
        }
    }


    //This trigger the pressure checks API based on the number of sitedashboardwidgetIds
    private async triggerPressureChecksPerVehicleApi(criterias: Array<SiteDashboardWidgetIdDependancyApiCriteria>) {
        let hasPressureCheckVehicle = criterias.length > 0;

        if (hasPressureCheckVehicle) {
            criterias.forEach(criteria => {
                this.getPressureCheckWidgetResult(criteria);
            });
        }
    }

    /** This method is to call api based on the initial site dashboard widgets from SiteDashboardApiService / getUserPosition
     * This function will beneficial to control the api call stacks
    */
    public async triggerSiteDashboardWidgetApiResult(criteria: SiteDashboardWidgetApiTrigger) {
        await this.setFinalApiIndex(criteria);
        if (criteria.hasDefaultWidget.siteHealthRating) {
            await this.getSiteHealthRatingResult();
        }
        if (criteria.hasDefaultWidget.dataErrors) {
            await this.getErrorWidgetResult();
        }
        if (criteria.hasDefaultWidget.inspectionTyres) {
            await this.getTyreInInspectionWidgetResult();
        }
        if (criteria.hasDefaultWidget.maintenanceVehicles) {
            await this.getVehicleInMaintenanceWidgetResult();
        }
        if (criteria.hasDefaultWidget.changesCompleted) {
            await this.getChangedCompletedWidgetResult();
        }
        if (criteria.hasDefaultWidget.totalDowntime) {
            await this.getTotalDownTimeWidgetResult();
        }
        await this.triggerConditionMonitoringWidgetApi(criteria.siteDashboardWidgetIdDependancy.conditionMonitoring);
        await this.triggerPressureMaintenanceKpiApi(criteria.siteDashboardWidgetIdDependancy.pressureMaintenanceKpi);
        await this.triggerLiveForecastRimNdtApi(criteria.siteDashboardWidgetIdDependancy.liveForecastRimNdt);
        await this.triggerLiveForecastTyreApi(criteria.siteDashboardWidgetIdDependancy.liveForecastTyresMonth);
        await this.triggerPressureChecksPerVehicleApi(criteria.siteDashboardWidgetIdDependancy.pressureChecksPerVehicle);
        await this.triggerScrapLifeTrendApi(criteria.siteDashboardWidgetIdDependancy.scrapLifeTrend);
    }

    private async setFinalApiIndex(criteria: SiteDashboardWidgetApiTrigger) {
        let defaultWidgetCount = 0;
        let conditionMonitoringLength = criteria.siteDashboardWidgetIdDependancy.conditionMonitoring.length;
        let liveForecastRimNdtLength = criteria.siteDashboardWidgetIdDependancy.liveForecastRimNdt.length;
        let liveForecastTyresMonthLength = criteria.siteDashboardWidgetIdDependancy.liveForecastTyresMonth.length;
        let pressureChecksPerVehicleLength = criteria.siteDashboardWidgetIdDependancy.pressureChecksPerVehicle.length;
        let pressureMaintenanceKpiLength = criteria.siteDashboardWidgetIdDependancy.pressureMaintenanceKpi.length;
        let scrapLifeTrendLength = criteria.siteDashboardWidgetIdDependancy.scrapLifeTrend.length;

        if (criteria.hasDefaultWidget.siteHealthRating) {
            defaultWidgetCount += 1;
        }
        if (criteria.hasDefaultWidget.dataErrors) {
            defaultWidgetCount += 1;
        }
        if (criteria.hasDefaultWidget.inspectionTyres) {
            defaultWidgetCount += 1;
        }
        if (criteria.hasDefaultWidget.maintenanceVehicles) {
            defaultWidgetCount += 1;
        }
        if (criteria.hasDefaultWidget.changesCompleted) {
            defaultWidgetCount += 1;
        }
        if (criteria.hasDefaultWidget.totalDowntime) {
            defaultWidgetCount += 1;
        }

        let finalApiCount = defaultWidgetCount + conditionMonitoringLength + liveForecastRimNdtLength + liveForecastTyresMonthLength +
            pressureChecksPerVehicleLength + pressureMaintenanceKpiLength + scrapLifeTrendLength;

        await this.widgetRxjs.setApiCallFinalApiIndex(finalApiCount);
    }
}