/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var util_1 = require("./util");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var util_2 = require("../common/util");
var kendo_licensing_1 = require("@progress/kendo-licensing");
var package_metadata_1 = require("../package-metadata");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
/**
 * Represents the [Kendo UI StackLayout component for Angular]({% slug overview_stacklayout %}).
 */
var StackLayoutComponent = /** @class */ (function () {
    function StackLayoutComponent(renderer, element, localization) {
        this.renderer = renderer;
        this.element = element;
        this.localization = localization;
        this.hostClass = true;
        /**
         * Specifies the gap between the inner StackLayout elements. The default value is `0`
         * ([see example]({% slug layout_stacklayout %}#toc-gaps)).
         */
        this.gap = 0;
        /**
         * Specifies the orientation of the StackLayout
         * ([see example]({% slug layout_stacklayout %}#toc-orientation)).
         *
         * The possible values are:
         * (Default) `horizontal`
         * `vertical`
         */
        this.orientation = 'horizontal';
        this._align = {
            horizontal: 'stretch',
            vertical: 'stretch'
        };
        kendo_licensing_1.validatePackage(package_metadata_1.packageMetadata);
    }
    Object.defineProperty(StackLayoutComponent.prototype, "horizontalClass", {
        get: function () {
            return this.orientation === 'horizontal';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StackLayoutComponent.prototype, "verticalClass", {
        get: function () {
            return this.orientation === 'vertical';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StackLayoutComponent.prototype, "dir", {
        get: function () {
            return this.direction;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StackLayoutComponent.prototype, "align", {
        get: function () {
            return this._align;
        },
        /**
         * Specifies the horizontal and vertical alignment of the inner StackLayout elements
         * ([see example]({% slug layout_stacklayout %}#toc-alignment)).
         */
        set: function (align) {
            this._align = Object.assign({}, this._align, align);
            this.handleAlignClasses();
        },
        enumerable: true,
        configurable: true
    });
    StackLayoutComponent.prototype.ngAfterViewInit = function () {
        this.handleAlignClasses();
        this.setGap();
    };
    StackLayoutComponent.prototype.ngOnChanges = function (changes) {
        if (kendo_angular_common_1.isChanged('gap', changes)) {
            this.setGap();
        }
        if (kendo_angular_common_1.isChanged('orientation', changes)) {
            this.handleAlignClasses();
        }
    };
    StackLayoutComponent.prototype.handleAlignClasses = function () {
        var elem = this.element.nativeElement;
        if (util_2.isPresent(this.justifyClass)) {
            this.renderer.removeClass(elem, this.justifyClass);
        }
        if (util_2.isPresent(this.alignClass)) {
            this.renderer.removeClass(elem, this.alignClass);
        }
        if (this.orientation === 'horizontal') {
            this.justifyClass = util_1.JUSTIFY_PREFIX + "-" + this.align.horizontal;
            this.alignClass = util_1.ALIGN_PREFIX + "-" + util_1.VERTICAL_SUFFIX[this.align.vertical];
        }
        else {
            this.justifyClass = util_1.JUSTIFY_PREFIX + "-" + util_1.VERTICAL_SUFFIX[this.align.vertical];
            this.alignClass = util_1.ALIGN_PREFIX + "-" + this.align.horizontal;
        }
        this.renderer.addClass(elem, this.justifyClass);
        this.renderer.addClass(elem, this.alignClass);
    };
    StackLayoutComponent.prototype.setGap = function () {
        var parsedGap = util_2.isNumber(this.gap) ? this.gap + "px" : this.gap;
        this.renderer.setStyle(this.element.nativeElement, 'gap', parsedGap);
    };
    Object.defineProperty(StackLayoutComponent.prototype, "direction", {
        get: function () {
            return this.localization.rtl ? 'rtl' : 'ltr';
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        core_1.HostBinding('class.k-stack-layout'),
        tslib_1.__metadata("design:type", Boolean)
    ], StackLayoutComponent.prototype, "hostClass", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-hstack'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], StackLayoutComponent.prototype, "horizontalClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-vstack'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], StackLayoutComponent.prototype, "verticalClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.dir'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], StackLayoutComponent.prototype, "dir", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object),
        tslib_1.__metadata("design:paramtypes", [Object])
    ], StackLayoutComponent.prototype, "align", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], StackLayoutComponent.prototype, "gap", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], StackLayoutComponent.prototype, "orientation", void 0);
    StackLayoutComponent = tslib_1.__decorate([
        core_1.Component({
            exportAs: 'kendoStackLayout',
            selector: 'kendo-stacklayout',
            providers: [
                kendo_angular_l10n_1.LocalizationService,
                {
                    provide: kendo_angular_l10n_1.L10N_PREFIX,
                    useValue: 'kendo.stacklayout'
                }
            ],
            template: "\n        <ng-content></ng-content>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.Renderer2,
            core_1.ElementRef,
            kendo_angular_l10n_1.LocalizationService])
    ], StackLayoutComponent);
    return StackLayoutComponent;
}());
exports.StackLayoutComponent = StackLayoutComponent;
