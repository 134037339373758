import { UpgradeModule } from "@angular/upgrade/static";

import ngFilter from 'angular-filter';
import ngAnimate from 'angular-animate';
import ngTouch from 'angular-touch';//needed by Angular UI Bootstrap
import uib from 'angular-ui-bootstrap';
import uiv from 'angular-ui-validate';
import cgBusy from 'angular-busy';

import amtFramework from './amt';

import appSite from './modules/site/site';
import appAdmin from './modules/admin/admin';
import appCommon from './modules/common/common';
import appMobile from './mobile/mobile';
import appVehicle from './modules/equipment/vehicle/vehicle';
import appComponent from './modules/equipment/component/component';
import appEquipment from './modules/equipment/equipment';
import appReports from './modules/reports/report';
import appFieldSurveys from './modules/components/routing'; //TODO: this smells bad - this is just not where that should be
import appStocktake from './modules/components/stocktake';

import appDirectives from './directives/app.directives';
import appControllers from './controllers/controllers';

// Declare app level module which depends on filters, and services
angular.module('app', [
    ngFilter,
    'ui.router',
    'ui.router.upgrade',
    ngAnimate,
    ngTouch,
    uib,
    uiv,
    cgBusy,
    appDirectives,
    appControllers,
    amtFramework,
    appSite,
    appAdmin,
    appCommon,
    appMobile,
    appVehicle,
    appComponent,
    appEquipment,
    appReports,
    appFieldSurveys,
    appStocktake,
    'downgraded.module'
]);

const isMobile = document.documentElement.classList.contains('mobile');
angular.module('app').constant('isMobile', isMobile);