//import angular from 'angular';
import tmpl from "./componentReceiveConditionPopup.html";



    angular.module("app.equipment").component("componentReceiveConditionPopup", {
        bindings: {
            initParams: "=",
            buttonMethods: "=",
            buttonStates: "=",
            buttons: "=",
            closeOnSave: "=",
            wnd: "="
        },
        template: tmpl,
        controller: ["$scope", "WindowFactory", "amtXlatSvc", "errorReporter", "ocConfigSvc", "$timeout", "enums",
            function ($scope, windowFactory, xlatSvc, errorReporter, ocConfigSvc, $timeout, enums) {

                var vm = this;
                $scope.vm = this;                                

                this.$onInit = function () {
                    vm.buttons = [
                        {
                            primary: false,
                            cancel: true,
                            value: "common.cancel_label",
                            name: "cancelButton",
                            click: "cancel",
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        cancelButton: {
                            visible: true,
                            disabled: false
                        }
                    };

                    vm.buttonMethods = {
                        cancel: vm.onCancel
                    };                    
                };

                vm.onCancel = function () {
                    closeWindow("cancel");
                    return true;
                };

                vm.componentConditionClick = function (condition) {                    
                    vm.wnd.onDataChanged(condition);
                    closeWindow("success");
                };
                // end local functions


                // other stuff
                function closeWindow(closeReason) {
                    windowFactory.closeWindow(vm.wnd);
                }
            }
        ]
    });
