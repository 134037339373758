//import angular from 'angular';


angular.module('app.admin')
        .controller("sysMaintDelayReasonSearchCtrl", [
            "$scope", "enums", "ocSecuritySvc", "amtXlatSvc", "delayReasonSvc", "ocConfigSvc",
            function (
                $scope, enums, ocSecuritySvc, amtXlatSvc, delayReasonSvc, ocConfigSvc
            ) {
                var vm = this;

                vm.processing = false;
                vm.screenStatusEnums = enums.screenStatus;
                vm.status = vm.screenStatusEnums.loading;
                vm.gridControl = {};
                vm.selectedItem = null;

                vm.svc = delayReasonSvc;
                vm.config = ocConfigSvc;               

                vm.criteria = {
                    filterValues: {
                        activeOptions: [],
                        ids: []
                    }
                };

                vm.delayReasonFilterControl = {};

                function init() {
                    vm.svc.loadDefaultsAsync().then(function () {

                        vm.defaultActiveStates = vm.svc.activeStates.filter(function (state) {
                            return state.default === true;
                        });

                        vm.status = vm.screenStatusEnums.ready;
                    });
                }

                vm.onSelectedItemChanged = function (items) {
                    if (items != null && items.length === 1) {
                        vm.selectedItem = items[0];
                    } else {
                        vm.selectedItem = null;
                    }
                };

                vm.setHeaderHeight = function (height, offset) {
                    vm.gridTop = height + offset;
                };

                vm.export = function () {
                    vm.svc.export(vm.criteria);
                };

                vm.rowParseFn = function (response) {
                    var results = response.result;

                    if (results && results.length > 0) {
                        for (var r = 0; r < results.length; r++) {
                            results[r].categoryDisplay = vm.svc.categories[results[r].category].name;
                        }
                    }

                    return response;
                };

                vm.refreshGrid = function () {
                    vm.gridControl.refresh();
                };

                $scope.editItem = function (item) {
                    vm.svc.openItem(item, function () {
                        vm.refreshGrid();
                        vm.delayReasonFilterControl.search();
                    });
                };

                $scope.addItem = function () {
                    vm.svc.addItem(function () {
                        vm.refreshGrid();
                        vm.delayReasonFilterControl.search();
                    });
                };

                init();
            }
        ])
