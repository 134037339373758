/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
/* tslint:disable:component-selector */
var core_1 = require("@angular/core");
var sliders_util_1 = require("./sliders-util");
/**
 * @hidden
 */
var SliderTick = /** @class */ (function () {
    function SliderTick(value) {
        this.value = value;
        this.classes = {
            'k-tick': true
        };
    }
    return SliderTick;
}());
/**
 * @hidden
 */
var SliderTicksComponent = /** @class */ (function () {
    function SliderTicksComponent() {
        this.wrapperClasses = 'k-reset k-slider-items';
        this.ticks = [];
    }
    SliderTicksComponent.prototype.ngOnChanges = function (_) {
        this.createTicks();
    };
    SliderTicksComponent.prototype.createTicks = function () {
        var count = sliders_util_1.calculateTicksCount(this.min, this.max, this.step);
        var largeStep = this.largeStep;
        var tickValueProps = {
            max: this.max,
            min: this.min,
            smallStep: this.step
        };
        var result = [];
        for (var i = 0; i < count; i++) {
            result.push(new SliderTick(sliders_util_1.calculateValueFromTick(i, tickValueProps)));
            if (largeStep && i % largeStep === 0) {
                result[i].large = true;
                result[i].classes['k-tick-large'] = true;
            }
        }
        if (result.length > 0) {
            Object.assign(result[0].classes, this.endTickClasses(true));
            Object.assign(result[result.length - 1].classes, this.endTickClasses(false));
        }
        this.ticks = result;
    };
    SliderTicksComponent.prototype.endTickClasses = function (first) {
        return {
            'k-first': (first && !this.vertical) || (!first && this.vertical),
            'k-last': (!first && !this.vertical) || (first && this.vertical)
        };
    };
    tslib_1.__decorate([
        core_1.HostBinding('class'),
        tslib_1.__metadata("design:type", String)
    ], SliderTicksComponent.prototype, "wrapperClasses", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Function)
    ], SliderTicksComponent.prototype, "tickTitle", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], SliderTicksComponent.prototype, "vertical", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], SliderTicksComponent.prototype, "step", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], SliderTicksComponent.prototype, "largeStep", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], SliderTicksComponent.prototype, "min", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], SliderTicksComponent.prototype, "max", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", core_1.TemplateRef)
    ], SliderTicksComponent.prototype, "labelTemplate", void 0);
    tslib_1.__decorate([
        core_1.ViewChildren('tickElement'),
        tslib_1.__metadata("design:type", core_1.QueryList)
    ], SliderTicksComponent.prototype, "tickElements", void 0);
    SliderTicksComponent = tslib_1.__decorate([
        core_1.Component({
            selector: '[kendoSliderTicks]',
            template: "\n    <li #tickElement *ngFor=\"let tick of ticks;\"\n        [ngClass]=\"tick.classes\"\n        title=\"{{ tickTitle(tick.value) }}\"\n        role=\"presentation\"\n     >\n         <ng-container [ngSwitch]=\"tick.large\">\n            <span class=\"k-label\" *ngSwitchCase=\"true\">\n                <ng-container [ngTemplateOutlet]=\"labelTemplate || defaultLabel\" [ngTemplateOutletContext]=\"tick\">\n                </ng-container>\n            </span>\n            <ng-container *ngSwitchCase=\"false\">&nbsp;</ng-container>\n         </ng-container>\n     </li>\n\n     <ng-template #defaultLabel let-value=\"value\">\n        {{ tickTitle(value) }}\n     </ng-template>\n  "
        })
    ], SliderTicksComponent);
    return SliderTicksComponent;
}());
exports.SliderTicksComponent = SliderTicksComponent;
