/**
 * @ngdoc directive
 * @name amtFramework.filter.directive:amtFilterColumn
 * @restrict E
 * @requires amtFramework.filter.directive:amtFilter
 * 
 *
 * @description
 * Adds a column to {@link amtFramework.filter.directive:amtFilter amtFilter}
 *
 * @example
 * See {@link amtFramework.filter.directive:amtFilter amtFilter}
 */
//import angular from 'angular';
import tmpl from './amtFilterColumn.html';

angular.module('amtFramework.filter').directive('amtFilterColumn', function() {
            
            return {
                require: '^amtFilter',
                transclude: true,
                restrict: 'E',
                template: tmpl,
            };
});
