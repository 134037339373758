//import angular from 'angular';
import { IVisualInspectionItem_InitParams, TVisualInspection, VisualInspectionMode } from '../../../../interfaces/visualInspection.interfaces';
import OcConfigSvc from '../../../../services/ocConfigSvc';
import OcDateSvc from '../../../../services/ocDateSvc';
import { VisualInspectionService } from '../../../../services/visual-inspection.service';
import tmpl from './visualInspection.html';

interface IVisualInspectionCtrlScope extends ng.IScope {
    openVisualInspection: (id?: guid) => void;
}

class VisualInspectionCtrl implements ng.IController, IWindowController {

    wnd: IWindowObj;
    form: ng.IFormController;

    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: () => void }

    initParams: any;

    visualInspectionGrid: IGridControl = {};

    readingRow: any;

    componentTypeId?: guid;

    readonly: boolean;
    canAdd: boolean;

    static $inject = ['$scope', 'errorReporter', 'WindowFactory', 'ocSecuritySvc', '$timeout', 'visualInspectionService', 'ocConfigSvc', 'ocDateSvc'];

    constructor(private $scope: IVisualInspectionCtrlScope, private errorReporter: IErrorReporter, private WindowFactory: IWindowFactory,
        private ocSecuritySvc: IOcSecuritySvc, private $timeout: ng.ITimeoutService,
        private visualInspectionService: VisualInspectionService, private ocConfigSvc: OcConfigSvc, private ocDateSvc: OcDateSvc) {

        this.$scope.openVisualInspection = (id) => this.openVisualInspection(id);
    }

    $onInit() {

        this.wnd.onClose = () => this.closeWindow();

        // security
        this.readonly = !this.ocSecuritySvc.isAuthorised('Security.Components.VisualInspections', AccessTypes.readWrite) || this.initParams.readonly;
        this.canAdd = this.ocSecuritySvc.isAuthorised('Security.Components.VisualInspections.Add', AccessTypes.readWrite) && !this.readonly;

        this.readingRow = this.initParams.readingRow;
        this.readingRow.visualInspections = this.readingRow.visualInspections || [];

        this.componentTypeId = this.readingRow.tyreComponentTypeId;

        // shouldn't happen but happens when you try to add a VI to an vehicle with no fitments.
        // It's mainly used for lookup of the details, which isn't needed if you actually have no tyre anyway.
        // so we log but suppress UI
        if (!this.componentTypeId)
            this.errorReporter.logError('readingRow.tyreComponentTypeId not set', 'VisualInspectionCtrl', true);

        this.WindowFactory.addButton(this, 'common.close_label', 'closeButton', () => this.closeWindow(), true);
        this.WindowFactory.addButton(this, 'common.add_label', 'addButton', () => this.openVisualInspection(), false, this.canAdd);

        this.$timeout(() => this.refresh());
    }

    refresh() {
        if (this.visualInspectionGrid?.refresh)
            this.visualInspectionGrid.refresh();
    }

    save(vi: TVisualInspection) {
        
        let idx = this.readingRow.visualInspections.findIndex(v => v.id === vi.id);

        // check if there is an existing active inspection with the same cause
        // and if there is, make it inactive
        if (vi.active && vi.cause != 'Other') {
            for (let v of this.readingRow.visualInspections) {
                if (v.active && v.causeId === vi.causeId && v.id !== vi.id)
                    v.active = false;
            }
        }


        vi.inspectedBy = this.ocConfigSvc.user.name;
        vi.modified = true;

        if (idx >= 0) {
            this.readingRow.visualInspections[idx] = vi;
        } else {
            this.readingRow.visualInspections.push(vi);
        }

        this.refresh();
    }

    delete(visualInspectionId: guid) {
        let idx = this.readingRow.visualInspections.findIndex(v => v.id === visualInspectionId);

        if (idx >= 0)
            this.readingRow.visualInspections.splice(idx, 1);

        this.refresh();
    }

    openVisualInspection(id?: guid) {

        let visualInspection = id ? this.readingRow.visualInspections.find(v => v.id === id) : null;

        let mode: VisualInspectionMode = !visualInspection ? VisualInspectionMode.add : (
            this.ocDateSvc.approxEqual(visualInspection.inspectionDateTime, this.initParams.surveyDate) ?
                VisualInspectionMode.edit : VisualInspectionMode.update
        );

        if (visualInspection && mode !== VisualInspectionMode.edit)
            delete visualInspection.visualInspectionRecordId;

        let initParams: IVisualInspectionItem_InitParams = {
            showDate: false, // don't show the date on the visual inspection item
            componentTypeId: this.componentTypeId,
            visualInspection: visualInspection,
            readonly: this.readonly,
            inspectionDate: this.initParams.surveyDate,
            equipmentId: this.initParams.equipmentId,
            commitOnSave: false,
            mode: mode,
            canDelete: visualInspection?.uncommitted
        };

        this.visualInspectionService.openVisualInspectionItem(initParams, (vi) => {
            if (!vi && id) { // uncommitted visual inspection that is being deleted
                this.delete(id);
            } else {
                this.save(vi);
            }
        });
    }

    private closeWindow() {
        if (this.wnd.onDataChanged)
            this.wnd.onDataChanged(this.readingRow);

        this.WindowFactory.closeWindow(this.wnd);
    }
}

class VisualInspectionComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = VisualInspectionCtrl;
    public controllerAs = 'vm';
}

angular.module('app.component').component('visualInspection', new VisualInspectionComponent());