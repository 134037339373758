//import angular from 'angular';
import AdminUrls from '../../../admin/adminUrls';
import tmpl from './newPermission.html';


    angular.module("app.admin").component("newPermission", {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['$scope', 'adminUrls', 'amtXlatSvc', 'enums', 'amtCommandQuerySvc', 'confirmSvc', 'WindowFactory', 'notifySvc', 'errorReporter',
            function ($scope, adminUrls: AdminUrls, xlatSvc: IAmtXlatSvc, enums, amtCommandQuerySvc, confirmSvc, WindowFactory: IWindowFactory, notifySvc, errorReporter) {

                var vm = this;
                $scope.vm = this;

                vm.apiUrls = adminUrls;
                vm.isVisible = true;

                this.$onInit = function () {
                    vm.wnd.onClose = vm.onClose;

                    vm.item = vm.initParams.item;

                    vm.mode = vm.initParams.mode;
                    vm.permissionId = vm.initParams.id;
                    vm.name = vm.initParams.name;
                    vm.description = vm.initParams.description;

                    initRoles();

                    vm.status = enums.screenStatus.ready;

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: 'common.save_label',
                            name: 'saveButton',
                            click: 'save',
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: true,
                            value: 'common.cancel_label',
                            name: 'cancelButton',
                            click: 'cancel',
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: true,
                            disabled: true
                        },
                        cancelButton: {
                            visible: true,
                            disabled: false
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.onSave,
                        cancel: vm.onClose
                    };
                };

                var pristineWatch = $scope.$watch('vm.form.$setPristine', function (newValue, oldValue) {
                    if (oldValue === undefined && newValue) {
                        // call pristine once the function is available
                        vm.form.$setPristine();

                        // remove the watch
                        pristineWatch();
                    }
                });

                function initRoles() {

                    vm.wnd.processing = true;

                    vm.accessTypes = [];

                    vm.accessTypes.push({ key: "0", description: xlatSvc.xlat('rolesPermissions.hide') });
                    vm.accessTypes.push({ key: "1", description: xlatSvc.xlat('rolesPermissions.readOnly') });
                    vm.accessTypes.push({ key: "2", description: xlatSvc.xlat('rolesPermissions.readWrite') });

                    var criteria = {
                        permissionId: vm.permissionId,
                        includeInactiveRoles: false
                    };

                    amtCommandQuerySvc.post(vm.apiUrls.getRoleTypesForPermission, criteria)
                        .then(function (response) {
                            var roles = [];

                            for (var i = 0; i < response.result.length; i++) {
                                var role = response.result[i];

                                roles.push({
                                    id: role.key,
                                    name: role.name,
                                    description: role.description,
                                    accessTypeItem: vm.accessTypes[role.accessType],
                                    accessType: role.accessType
                                });
                            }

                            vm.roles = roles;
                        }, amtCommandQuerySvc.handleError).finally(function () {
                            vm.wnd.processing = false;
                        });
                }

                vm.onSave = function () {

                    vm.wnd.processing = true;

                    var command = {
                        id: vm.permissionId,
                        name: vm.name,
                        description: vm.description,
                        roles: []
                    };

                    for (var r = 0; r < vm.roles.length; r++) {
                        /* has a new value different to old ? */
                        var newVal = vm.roles[r].accessTypeItem ? parseInt(vm.roles[r].accessTypeItem.key) : NaN;
                        if (newVal !== NaN && newVal !== vm.roles[r].accessType) {
                            command.roles.push({
                                id: vm.roles[r].id,
                                access: vm.roles[r].accessTypeItem == undefined ? vm.roles[r].accessType : vm.roles[r].accessTypeItem.key
                            });
                        }
                    }

                    amtCommandQuerySvc.post(vm.apiUrls.addPermission, command)
                        .then(function (response) {

                            notifySvc.success(xlatSvc.xlat("userSecurity.updatePermissionSuccessful"));
                            vm.wnd.onDataChanged();
                            closeWindow();

                        }).catch(function (error) {
                            errorReporter.logError(error);
                        }).finally(function () {
                            vm.wnd.processing = false;
                        });
                };

                function closeWindow() {
                    WindowFactory.closeWindow(vm.wnd);
                }

                $scope.setForm = function () {
                    $scope.$watch('vm.form.$dirty', function () {
                        vm.wnd.isDirty = vm.form.$dirty;
                    });
                };

                vm.onClose = function () {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        vm.form.dirty = false;
                        closeWindow();
                        return true;
                    });

                    return false;
                };

                $scope.$watchGroup(['vm.form.$dirty', 'vm.form.$invalid', 'vm.wnd.processing'], function () {
                    vm.buttonStates.saveButton.disabled = !vm.form.$dirty || vm.form.$invalid || vm.wnd.processing;
                });
            }
        ]
    });
