/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var util_1 = require("./util");
var dom_queries_1 = require("../common/dom-queries");
var constants_1 = require("./constants");
var reorder_event_1 = require("./reorder-event");
var resize_event_1 = require("./resize-event");
/**
 * @hidden
 */
var TileLayoutDraggingService = /** @class */ (function () {
    function TileLayoutDraggingService(zone, renderer, localization) {
        var _this = this;
        this.zone = zone;
        this.renderer = renderer;
        this.localization = localization;
        this.reorderable = new rxjs_1.BehaviorSubject(null);
        this.resizable = new rxjs_1.BehaviorSubject(null);
        this.reorder = new rxjs_1.Subject();
        this.resize = new rxjs_1.Subject();
        this.lastDragCursorOffset = {
            x: 0,
            y: 0
        };
        this.localizationSubscription = this.localization.changes.subscribe(function (_a) {
            var rtl = _a.rtl;
            return _this.rtl = rtl;
        });
    }
    Object.defineProperty(TileLayoutDraggingService.prototype, "colStart", {
        get: function () {
            return this.currentColStart;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TileLayoutDraggingService.prototype, "rowStart", {
        get: function () {
            return this.currentRowStart;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TileLayoutDraggingService.prototype, "itemWrapper", {
        get: function () {
            return this.draggedItemWrapper;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TileLayoutDraggingService.prototype, "order", {
        get: function () {
            return this.targetOrder;
        },
        enumerable: true,
        configurable: true
    });
    TileLayoutDraggingService.prototype.ngOnDestroy = function () {
        this.localizationSubscription.unsubscribe();
    };
    TileLayoutDraggingService.prototype.handlePress = function (originalEvent) {
        var _this = this;
        var resizing = !!originalEvent.target.classList.contains('k-resize-handle');
        var closestTile = dom_queries_1.closestInScope(originalEvent.target, function (el) { return el.classList.contains('k-tilelayout-item'); }, this.tileLayoutSettings.tileLayoutElement);
        var closestHeader = dom_queries_1.closestInScope(originalEvent.target, function (el) { return el.classList.contains('k-tilelayout-item-header'); }, this.tileLayoutSettings.tileLayoutElement);
        if (!closestTile) {
            return;
        }
        this.zone.run(function () {
            _this.draggedItemWrapper = closestTile;
            _this.draggedItem = _this.tileLayoutSettings.items
                .find(function (item) { return item.order === +closestTile.style.order; });
        });
        var reordering = !resizing && this.reorderable.getValue() && this.draggedItem.reorderable && closestHeader;
        if (!(reordering || resizing)) {
            return;
        }
        else {
            originalEvent.preventDefault();
        }
        this.zone.run(function () {
            _this.reordering = reordering;
            _this.resizing = resizing;
        });
        var tileRect = this.draggedItemWrapper.getBoundingClientRect();
        this.zone.run(function () {
            _this.offset = {
                top: originalEvent.clientY - tileRect.top,
                left: originalEvent.clientX - tileRect.left,
                x: tileRect.x,
                y: tileRect.y,
                width: tileRect.width,
                height: tileRect.height
            };
            _this.targetSize = {
                rowSpan: _this.draggedItem.rowSpan,
                colSpan: _this.draggedItem.colSpan
            };
            _this.cellSize = {
                width: (tileRect.width - ((_this.targetSize.colSpan - 1) * _this.tileLayoutSettings.gap.columns)) / _this.targetSize.colSpan,
                height: (tileRect.height - ((_this.targetSize.rowSpan - 1) * _this.tileLayoutSettings.gap.rows)) / _this.targetSize.rowSpan
            };
            _this.lastDragCursorOffset = {
                x: originalEvent.clientX,
                y: originalEvent.clientY
            };
        });
        util_1.setElementStyles(this.renderer, this.draggedItemWrapper, {
            left: tileRect.left + window.pageXOffset + 'px',
            top: tileRect.top + window.pageYOffset + 'px',
            width: tileRect.width + 'px',
            height: tileRect.height + 'px',
            zIndex: constants_1.DRAGGED_ZINDEX
        });
        util_1.setElementStyles(this.renderer, this.tileLayoutSettings.hintElement, {
            display: 'flex',
            height: (tileRect.height - constants_1.HINT_BORDERS_HEIGHT) + 'px'
        });
        this.zone.run(function () { return _this.targetOrder = _this.draggedItem.order; });
        util_1.setElementStyles(this.renderer, this.draggedItemWrapper, {
            position: 'absolute'
        });
        if (this.reorderable.getValue() && !resizing) {
            this.zone.run(function () {
                _this.currentColStart = _this.draggedItem.colStart;
                _this.currentRowStart = _this.draggedItem.rowStart;
            });
            var headerEl = this.draggedItem.elem.nativeElement.querySelector('.k-tilelayout-item-header');
            this.renderer.addClass(headerEl, 'k-cursor-grabbing');
        }
        else if (this.resizable && resizing) {
            this.zone.run(function () {
                _this.startingPoint = {
                    top: originalEvent.clientY,
                    left: originalEvent.clientX
                };
                _this.currentResizingColSpan = _this.draggedItem.colSpan;
                _this.currentResizingRowSpan = _this.draggedItem.rowSpan;
                if (_this.draggedItem.col) {
                    _this.currentColStart = _this.draggedItem.col.toString();
                }
                if (_this.draggedItem.row) {
                    _this.currentRowStart = _this.draggedItem.row.toString();
                }
                _this.direction = originalEvent.target.classList[1];
            });
        }
    };
    TileLayoutDraggingService.prototype.handleDrag = function (originalEvent) {
        if (this.draggedItemWrapper) {
            if (this.reordering) {
                this.reorderItems(originalEvent);
            }
            else if (this.resizing) {
                this.resizeItem(originalEvent);
            }
            this.lastDragCursorOffset = {
                x: originalEvent.clientX,
                y: originalEvent.clientY
            };
        }
    };
    TileLayoutDraggingService.prototype.handleRelease = function (originalEvent) {
        var _this = this;
        originalEvent.preventDefault();
        if (this.reordering) {
            var initialOrder_1 = this.draggedItem.order;
            var initialCol = this.draggedItem.col;
            var initialRow = this.draggedItem.row;
            var targetCol = util_1.normalizeValue(this.currentColStart);
            var targetRow = util_1.normalizeValue(this.currentRowStart);
            if (util_1.propsChanged([this.targetOrder, targetCol, targetRow], [initialOrder_1, initialCol, initialRow])) {
                var reorderEvent = new reorder_event_1.TileLayoutReorderEvent(this.draggedItem, this.tileLayoutSettings.items, this.targetOrder, initialOrder_1, util_1.normalizeValue(this.currentColStart), initialCol, targetRow, initialRow);
                this.reorder.next(reorderEvent);
                if (!reorderEvent.isDefaultPrevented()) {
                    if (this.targetOrder > initialOrder_1) {
                        this.zone.run(function () {
                            var _loop_1 = function (i) {
                                _this.tileLayoutSettings.items.find(function (item) { return item.order === i; }).order = i - 1;
                            };
                            for (var i = initialOrder_1 + 1; i <= _this.targetOrder; i++) {
                                _loop_1(i);
                            }
                        });
                    }
                    else {
                        this.zone.run(function () {
                            var _loop_2 = function (i) {
                                _this.tileLayoutSettings.items.find(function (item) { return item.order === i; }).order = i + 1;
                            };
                            for (var i = _this.targetOrder; i < initialOrder_1; i++) {
                                _loop_2(i);
                            }
                        });
                    }
                    this.draggedItem.order = this.targetOrder;
                    if (this.draggedItem.col) {
                        this.draggedItem.col = +this.currentColStart;
                    }
                    if (this.draggedItem.row) {
                        this.draggedItem.row = +this.currentRowStart;
                    }
                }
            }
            this.tileLayoutSettings.tileLayoutElement.appendChild(this.tileLayoutSettings.hintElement);
            this.zone.run(function () { return _this.cleanUp(); });
        }
        else if (!this.reordering && this.resizing) {
            var initialRowSpan = this.draggedItem.rowSpan;
            var initialColSpan = this.draggedItem.colSpan;
            var _a = util_1.isRowItemPresent(this.tileLayoutSettings.items) ?
                this.targetSpan() :
                { targetColSpan: this.currentResizingColSpan, targetRowSpan: this.currentResizingRowSpan }, targetColSpan = _a.targetColSpan, targetRowSpan = _a.targetRowSpan;
            if (util_1.propsChanged([initialRowSpan, initialColSpan], [targetRowSpan, targetColSpan])) {
                var resizeEvent = new resize_event_1.TileLayoutResizeEvent(this.draggedItem, this.tileLayoutSettings.items, targetRowSpan, initialRowSpan, targetColSpan, initialColSpan);
                this.resize.next(resizeEvent);
                if (!resizeEvent.isDefaultPrevented()) {
                    this.draggedItem.colSpan = this.currentResizingColSpan;
                    this.draggedItem.rowSpan = this.currentResizingRowSpan;
                }
            }
            this.zone.run(function () { return _this.cleanUp(); });
        }
    };
    TileLayoutDraggingService.prototype.reorderItems = function (event) {
        var _this = this;
        var targets = util_1.getDropTarget(event);
        var closestTile = targets.find(function (t) { return t !== _this.draggedItemWrapper; });
        var tileOrder = closestTile ? +closestTile.style.order : +this.draggedItemWrapper.style.order;
        if (this.tileLayoutSettings.autoFlow !== 'none') {
            var deltaX = event.clientX - this.lastDragCursorOffset.x;
            var deltaY = event.clientY - this.lastDragCursorOffset.y;
            var directionX = deltaX > 0 ? 'right' : deltaX < 0 ? 'left' : undefined;
            var directionY = deltaY > 0 ? 'down' : deltaX < 0 ? 'up' : undefined;
            var rect = this.draggedItemWrapper.getBoundingClientRect();
            var horizontalGap = this.tileLayoutSettings.gap.columns;
            var verticalGap = this.tileLayoutSettings.gap.rows;
            if (directionX && this.draggedItem.col) {
                var col = util_1.calculateCellFromPosition({
                    x: directionX === 'right' ? rect.right - horizontalGap : rect.left + horizontalGap,
                    y: event.clientY
                }, this.tileLayoutSettings.tileLayoutElement, this.tileLayoutSettings.gap, this.cellSize, this.tileLayoutSettings.columns, this.rtl).col;
                var targetStartCol = this.getTargetCol(col, directionX);
                this.currentColStart = targetStartCol.toString();
            }
            if (directionY && this.draggedItem.row) {
                var row = util_1.calculateCellFromPosition({
                    x: event.clientX,
                    y: directionY === 'down' ? rect.bottom - verticalGap : rect.top + verticalGap
                }, this.tileLayoutSettings.tileLayoutElement, this.tileLayoutSettings.gap, this.cellSize, this.tileLayoutSettings.columns, this.rtl).row;
                var targetStartRow = this.getTargetRow(row, directionY);
                this.currentRowStart = targetStartRow.toString();
            }
        }
        var hintBefore = tileOrder < this.targetOrder;
        var hintAfter = tileOrder > this.targetOrder;
        this.zone.run(function () { return _this.targetOrder = tileOrder; });
        if (hintBefore) {
            this.tileLayoutSettings.tileLayoutElement
                .insertBefore(this.tileLayoutSettings.hintElement, this.tileLayoutSettings.tileLayoutElement.firstChild);
        }
        else if (hintAfter) {
            this.tileLayoutSettings.tileLayoutElement.appendChild(this.tileLayoutSettings.hintElement);
        }
        util_1.setElementStyles(this.renderer, this.draggedItemWrapper, {
            top: (event.pageY - this.offset.top) + 'px',
            left: (event.pageX - this.offset.left) + 'px'
        });
    };
    TileLayoutDraggingService.prototype.resizeItem = function (event) {
        var _this = this;
        util_1.setElementStyles(this.renderer, this.tileLayoutSettings.tileLayoutElement, {
            cursor: this.direction.split('k-cursor-')[1]
        });
        var currentWidth = this.rtl ?
            this.offset.width + (this.offset.x - event.clientX) :
            this.offset.width + (event.clientX - this.startingPoint.left);
        var currentHeight = this.offset.height + (event.clientY - this.startingPoint.top);
        var hintRect = this.tileLayoutSettings.hintElement.getBoundingClientRect();
        var hintWidth = hintRect.width;
        var hintHeight = hintRect.height;
        var horizontalDragDirection = event.clientX - this.lastDragCursorOffset.x;
        var verticalDragDirection = event.clientY - this.lastDragCursorOffset.y;
        var startCol = this.draggedItem.col ? this.draggedItem.col : util_1.calculateCellFromPosition({
            x: this.rtl ? hintRect.right : hintRect.x,
            y: hintRect.y
        }, this.tileLayoutSettings.tileLayoutElement, this.tileLayoutSettings.gap, this.cellSize, this.tileLayoutSettings.columns, this.rtl).col;
        var maxWidth = (this.tileLayoutSettings.columns - startCol) * (this.cellSize.width + this.tileLayoutSettings.gap.columns) + this.cellSize.width;
        var resizeHorizontally = function () {
            util_1.setElementStyles(_this.renderer, _this.draggedItemWrapper, {
                width: Math.min(Math.max(currentWidth, _this.cellSize.width), maxWidth) + 'px'
            });
            if (_this.rtl && currentWidth > _this.cellSize.width) {
                var totalWidth = _this.tileLayoutSettings.columns * (_this.cellSize.width + _this.tileLayoutSettings.gap.columns);
                var leftBoundary = _this.tileLayoutSettings.tileLayoutElement.getBoundingClientRect().right - totalWidth;
                util_1.setElementStyles(_this.renderer, _this.draggedItemWrapper, {
                    left: Math.max(event.clientX, leftBoundary) + 'px'
                });
            }
            var deltaX = currentWidth - hintWidth;
            var _a = _this.draggedItem.elem.nativeElement.getBoundingClientRect(), x = _a.x, y = _a.y, right = _a.right;
            var col = util_1.calculateCellFromPosition({ x: (_this.rtl ? right : x), y: y }, _this.tileLayoutSettings.tileLayoutElement, _this.tileLayoutSettings.gap, _this.cellSize, _this.tileLayoutSettings.columns, _this.rtl).col;
            var resizedColSpan = col + _this.currentResizingColSpan;
            var expandingCondition = _this.rtl ? horizontalDragDirection < 0 : horizontalDragDirection > 0;
            var shrinkingCondition = _this.rtl ? horizontalDragDirection > 0 : horizontalDragDirection < 0;
            if (deltaX > constants_1.OVERLAP_THRESHOLD * _this.cellSize.width &&
                expandingCondition &&
                resizedColSpan <= _this.tileLayoutSettings.columns) {
                _this.currentResizingColSpan++;
            }
            else if (_this.currentResizingColSpan > 1 &&
                shrinkingCondition &&
                deltaX < constants_1.REVERSE_OVERLAP_THRESHOLD * _this.cellSize.width) {
                _this.currentResizingColSpan--;
            }
            util_1.setElementStyles(_this.renderer, _this.tileLayoutSettings.hintElement, {
                gridColumnEnd: "span " + _this.currentResizingColSpan
            });
        };
        var resizeVertically = function () {
            util_1.setElementStyles(_this.renderer, _this.draggedItemWrapper, {
                height: Math.max(currentHeight, _this.cellSize.height) + 'px'
            });
            var deltaY = currentHeight - hintHeight;
            if (deltaY > constants_1.OVERLAP_THRESHOLD * _this.cellSize.height && verticalDragDirection > 0) {
                _this.currentResizingRowSpan++;
            }
            else if (_this.currentResizingRowSpan > 1 &&
                verticalDragDirection < 0 && deltaY < constants_1.REVERSE_OVERLAP_THRESHOLD * _this.cellSize.height) {
                _this.currentResizingRowSpan--;
            }
            util_1.setElementStyles(_this.renderer, _this.tileLayoutSettings.hintElement, {
                gridRowEnd: "span " + _this.currentResizingRowSpan
            });
            util_1.setElementStyles(_this.renderer, _this.tileLayoutSettings.hintElement, {
                height: _this.calculateHintHeight() + "px"
            });
        };
        if (this.direction.indexOf('ew') > -1) {
            resizeHorizontally();
        }
        else if (this.direction.indexOf('ns') > -1) {
            resizeVertically();
        }
        else {
            resizeHorizontally();
            resizeVertically();
        }
    };
    TileLayoutDraggingService.prototype.cleanUp = function () {
        this.targetOrder = this.currentResizingColSpan = this.currentColStart = this.currentResizingRowSpan = this.currentRowStart = undefined;
        this.resizing = this.reordering = false;
        this.direction = null;
        if (this.draggedItemWrapper) {
            var grabHandle = this.draggedItemWrapper.querySelector('.k-cursor-grab');
            if (grabHandle) {
                this.renderer.removeClass(grabHandle, 'k-cursor-grabbing');
            }
            util_1.setElementStyles(this.renderer, this.draggedItemWrapper, {
                top: '',
                left: '',
                display: '',
                width: '',
                height: '',
                zIndex: '',
                position: ''
            });
            util_1.setElementStyles(this.renderer, this.tileLayoutSettings.hintElement, {
                display: 'none',
                height: 'auto'
            });
            util_1.setElementStyles(this.renderer, this.tileLayoutSettings.tileLayoutElement, {
                cursor: 'default'
            });
            this.draggedItemWrapper =
                this.offset =
                    this.draggedItem =
                        this.resizing =
                            this.reordering =
                                this.currentResizingColSpan =
                                    this.currentResizingRowSpan =
                                        this.startingPoint = undefined;
            this.lastDragCursorOffset = {
                x: 0,
                y: 0
            };
        }
    };
    TileLayoutDraggingService.prototype.targetSpan = function () {
        var itemRect = this.draggedItem.elem.nativeElement.getBoundingClientRect();
        var startingCell = util_1.calculateCellFromPosition({ x: this.rtl ? itemRect.right : itemRect.x, y: itemRect.y }, this.tileLayoutSettings.tileLayoutElement, this.tileLayoutSettings.gap, this.cellSize, this.tileLayoutSettings.columns, this.rtl);
        var targetEndCell = util_1.calculateCellFromPosition({
            x: this.rtl ? itemRect.x + constants_1.OVERLAP_THRESHOLD * this.cellSize.width : itemRect.right - constants_1.OVERLAP_THRESHOLD * this.cellSize.width,
            y: itemRect.bottom - constants_1.OVERLAP_THRESHOLD * this.cellSize.height
        }, this.tileLayoutSettings.tileLayoutElement, this.tileLayoutSettings.gap, this.cellSize, this.tileLayoutSettings.columns, this.rtl);
        return {
            targetColSpan: targetEndCell.col - startingCell.col + 1,
            targetRowSpan: targetEndCell.row - startingCell.row + 1
        };
    };
    TileLayoutDraggingService.prototype.getTargetCol = function (col, direction) {
        if (this.rtl) {
            return direction === 'left' ? col - this.draggedItem.colSpan + 1 : col;
        }
        return direction === 'right' ? col - this.draggedItem.colSpan + 1 : col;
    };
    TileLayoutDraggingService.prototype.getTargetRow = function (row, direction) {
        return direction === 'down' ? row - this.draggedItem.rowSpan + 1 : row;
    };
    TileLayoutDraggingService.prototype.calculateHintHeight = function () {
        var totalHintCellsHeight = this.currentResizingRowSpan * this.cellSize.height;
        var totalHintGapsHeight = (this.currentResizingRowSpan - 1) * this.tileLayoutSettings.gap.rows;
        var hintHeight = totalHintCellsHeight + totalHintGapsHeight - constants_1.HINT_BORDERS_HEIGHT;
        return hintHeight;
    };
    TileLayoutDraggingService = tslib_1.__decorate([
        core_1.Injectable(),
        tslib_1.__metadata("design:paramtypes", [core_1.NgZone,
            core_1.Renderer2,
            kendo_angular_l10n_1.LocalizationService])
    ], TileLayoutDraggingService);
    return TileLayoutDraggingService;
}());
exports.TileLayoutDraggingService = TileLayoutDraggingService;
