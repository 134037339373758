//import angular from 'angular';

angular.module('app.controllers').controller("reportLandingCtrl", ['$scope', function ($scope) {
    if (localStorage.getItem("siteDashboardReportName")) {

        const myArr = JSON.parse(localStorage.getItem("siteDashboardReportName"));

        myArr.forEach((element) => {
            waitForElement('[text="' + element + '"]>a').then((elem: HTMLElement) => {
                if (elem)
                    elem.click();
            });
        });

        localStorage.removeItem("siteDashboardReportName");
    }

    async function waitForElement(selector, timeout = 15000) {
        const start = Date.now();

        while (Date.now() - start < timeout) {
            const el = document.querySelector(selector);
            if (el) {
                return el;
            }
            await new Promise(resolve => setTimeout(resolve, 1000));
        }

        return null;
    }
}]);;
