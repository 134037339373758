import { Injectable } from '@angular/core';
import { Dashboard, UserPosition } from '../Interface/SiteDashboard.interface';

@Injectable({
    providedIn: 'root'
})

export class InitAddPositionHelper {

    constructor() {
    }

    //set newWidgetLocation
    setNewWidgetLocation(dashboardList: Dashboard[], newWidgets: UserPosition[], existingWidgets: UserPosition[], tileSetting: any) {
        const newWidgetPositionList: UserPosition[] = [];
        let maxCol = tileSetting.col;
        let maxRow = tileSetting.row;

        dashboardList.forEach(siteDashboard => {
            let siteDashboardId = siteDashboard.siteDashboardId;
            let initLocation = this.setInitLocation(maxRow, maxCol);

            //delete exsiting Widget location from initLocation
            existingWidgets.forEach(exsiting => {
                if (exsiting.siteDashboardId === siteDashboardId) {
                    exsiting.reference.forEach(ref => {
                        initLocation.forEach((available, index) => {
                            if (ref.col === available.col && ref.row === available.row) {
                                initLocation.splice(index, 1);
                            }
                        });
                    });
                }
            });

            //find a new location for new widgets
            newWidgets.forEach(newWidget => {
                if (newWidget.siteDashboardId === siteDashboardId) {
                    let available = initLocation[0];
                    let col = available.col;
                    let row = available.row;
                    let newReference = this.getNewReference(available.col, available.row, newWidget.rowSpan, newWidget.colSpan);
                    let isOverMaxCol = this.checkReachToMaxCol(maxCol, newReference);
                    let isOverlapping = this.findOverlappingArea(siteDashboardId, existingWidgets, newWidgetPositionList, newReference);

                    //check if new ref is over the maxcol and find the right place.
                    while (isOverMaxCol || isOverlapping) {
                        initLocation = this.deleteReference(initLocation, newReference);
                        available = initLocation[0];
                        col = available.col;
                        row = available.row;
                        newReference = this.getNewReference(col, row, newWidget.rowSpan, newWidget.colSpan);
                        isOverMaxCol = this.checkReachToMaxCol(maxCol, newReference);
                        isOverlapping = this.findOverlappingArea(siteDashboardId, existingWidgets, newWidgetPositionList, newReference);
                    }
                    //delete Reference in available location
                    initLocation = this.deleteReference(initLocation, newReference);

                    newWidget.row = row;
                    newWidget.col = col;
                    newWidget.reference = newReference;

                    newWidgetPositionList.push(newWidget);
                    }
                });
        });
        return newWidgetPositionList;
    }

    // check if new Location col is over the maxCol
    checkReachToMaxCol(maxCol, newLocation) {
        let check = false;
        newLocation.forEach(location => {
            if (location.col > maxCol) {
                check = true;
            }
        });
        return check;
    }

    deleteReference(location: any, newRef: any) {
        let layoutLocation = location;
        layoutLocation.forEach((location, index) => {
            newRef.forEach(newRef => {
                if (location.row === newRef.row && location.col === newRef.col) {
                    layoutLocation.splice(index, 1);
                }
            });
        });
        return layoutLocation;
    }

    getNewReference(col: number, row: number, rowSpan: number, colSpan: number) {
        let reference = [{ col: col, row: row }];
        //check spanValue and mark as overlapping area
        if (rowSpan === 2 && colSpan === 2) {
            let overlapRight = { row: row, col: col + 1 };
            let overlapBelow = { row: row + 1, col: col };
            let overlapAcute = { row: row + 1, col: col + 1 };
            reference.push(overlapBelow, overlapRight, overlapAcute);
        } else if (rowSpan === 1 && colSpan === 2) {
            let overlapRight = { row: row, col: col + 1 };
            reference.push(overlapRight);
        } else if (rowSpan === 2 && colSpan === 1) {
            let overlapBelow = { row: row + 1, col: col };
            reference.push(overlapBelow);
        }

        return reference;
    }

    findOverlappingArea(siteDashboardId: string, existingWidgets: UserPosition[], newWidgets: UserPosition[], newReference: any) {
        let isOverlapping = false

        existingWidgets.forEach(existing => {
            if (existing.siteDashboardId === siteDashboardId) {
                existing.reference.forEach(existingRef => {
                    newReference.forEach(newRef => {
                        if (newRef.col === existingRef.col && newRef.row === existingRef.row) {
                            isOverlapping = true;
                        }
                    });
                });
            }
        });

        newWidgets.forEach(existing => {
            if (existing.siteDashboardId === siteDashboardId) {
                existing.reference.forEach(existingRef => {
                    newReference.forEach(newRef => {
                        if (newRef.col === existingRef.col && newRef.row === existingRef.row) {
                            isOverlapping = true;
                        }
                    });
                });
            }
        });

        return isOverlapping;
    }

    //create a location.
    setInitLocation(maxRow: number, maxCol: number) {
        let availableLocation = [];

        for (let row = 0; row < maxRow; row++) {
            for (let col = 0; col < maxCol; col++) {
                availableLocation.push({ row: row + 1, col: col + 1 })
            }
        }
        return availableLocation;
    }

}


