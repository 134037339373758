/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var navigation_service_1 = require("./navigation/navigation.service");
var selection_service_1 = require("./selection/selection.service");
var expand_state_service_1 = require("./expand-state.service");
var index_builder_service_1 = require("./index-builder.service");
var treeview_lookup_service_1 = require("./treeview-lookup.service");
var utils_1 = require("./utils");
var operators_1 = require("rxjs/operators");
var buildItem = function (index, dataItem) { return ({ dataItem: dataItem, index: index }); };
var ɵ0 = buildItem;
exports.ɵ0 = ɵ0;
var id = 0;
var TREE_ITEM_ROLE = 'treeitem';
var BUTTON_ROLE = 'button';
/**
 * @hidden
 *
 * A directive which manages the expanded state of the TreeView.
 */
var TreeViewItemDirective = /** @class */ (function () {
    function TreeViewItemDirective(element, expandService, navigationService, selectionService, lookupService, renderer, ib) {
        this.element = element;
        this.expandService = expandService;
        this.navigationService = navigationService;
        this.selectionService = selectionService;
        this.lookupService = lookupService;
        this.renderer = renderer;
        this.ib = ib;
        this.role = TREE_ITEM_ROLE;
        this.loadOnDemand = true;
        this.isDisabled = false;
        this.isVisible = true;
        this.ariaChecked = 'false';
        this.id = id++;
        this.isInitialized = false;
        this.subscriptions = [];
        this.subscribe();
    }
    Object.defineProperty(TreeViewItemDirective.prototype, "isChecked", {
        set: function (checked) {
            if (checked === 'checked') {
                this.ariaChecked = 'true';
            }
            else if (checked === 'indeterminate') {
                this.ariaChecked = 'mixed';
            }
            else {
                this.ariaChecked = 'false';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TreeViewItemDirective.prototype, "isExpanded", {
        get: function () {
            return this._isExpanded || false;
        },
        set: function (isExpanded) {
            this._isExpanded = isExpanded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TreeViewItemDirective.prototype, "isSelected", {
        get: function () {
            return this._isSelected || false;
        },
        set: function (isSelected) {
            this._isSelected = isSelected;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TreeViewItemDirective.prototype, "isButton", {
        get: function () {
            return this.role === BUTTON_ROLE;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TreeViewItemDirective.prototype, "treeItem", {
        get: function () {
            return buildItem(this.index, this.dataItem);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TreeViewItemDirective.prototype, "parentTreeItem", {
        get: function () {
            return this.parentDataItem ? buildItem(this.parentIndex, this.parentDataItem) : null;
        },
        enumerable: true,
        configurable: true
    });
    TreeViewItemDirective.prototype.ngOnInit = function () {
        if (this.loadOnDemand && !this.isButton) {
            this.lookupService.registerItem(this.treeItem, this.parentTreeItem);
        }
        this.registerNavigationItem();
        this.isInitialized = true;
        this.setAttribute('role', this.role);
        this.setAriaAttributes();
        this.setDisabledClass();
        this.updateTabIndex();
    };
    TreeViewItemDirective.prototype.ngOnChanges = function (changes) {
        var index = changes.index, isDisabled = changes.isDisabled;
        if (kendo_angular_common_1.anyChanged(['index', 'checkable', 'isChecked', 'expandable', 'isExpanded', 'selectable', 'isSelected'], changes)) {
            this.setAriaAttributes();
        }
        if (isDisabled) {
            this.setDisabledClass();
        }
        if (this.loadOnDemand && !this.isButton) {
            this.moveLookupItem(changes);
        }
        this.moveNavigationItem(index);
        if (kendo_angular_common_1.anyChanged(['isDisabled', 'isVisible'], changes)) {
            this.updateNodeAvailability();
        }
    };
    TreeViewItemDirective.prototype.ngOnDestroy = function () {
        this.navigationService.unregisterItem(this.id, this.index);
        if (this.loadOnDemand && !this.isButton) {
            this.lookupService.unregisterItem(this.index, this.dataItem);
        }
        this.subscriptions = this.subscriptions.reduce(function (list, callback) { return (callback.unsubscribe(), list); }, []);
    };
    TreeViewItemDirective.prototype.subscribe = function () {
        var _this = this;
        this.subscriptions = [
            this.navigationService.moves
                .subscribe(function (navState) {
                _this.updateTabIndex();
                _this.focusItem(navState.shouldScroll);
            }),
            this.navigationService.expands
                .pipe(operators_1.filter(function (_a) {
                var index = _a.index;
                return index === _this.index && !_this.isDisabled;
            }))
                .subscribe(function (_a) {
                var expand = _a.expand;
                return _this.expand(expand);
            })
        ];
    };
    TreeViewItemDirective.prototype.registerNavigationItem = function () {
        this.navigationService.registerItem(this.id, this.index, this.isDisabled, this.isButton, this.isVisible);
        this.activateItem();
    };
    TreeViewItemDirective.prototype.activateItem = function () {
        if (this.isDisabled) {
            return;
        }
        var navigationService = this.navigationService;
        var selectionService = this.selectionService;
        var index = this.index;
        selectionService.setFirstSelected(index, this.isSelected);
        if (!navigationService.isActive(index) && selectionService.isFirstSelected(index)) {
            navigationService.activateIndex(index);
        }
    };
    TreeViewItemDirective.prototype.expand = function (shouldExpand) {
        this.expandService[shouldExpand ? 'expand' : 'collapse'](this.index, this.dataItem);
    };
    TreeViewItemDirective.prototype.isFocusable = function () {
        return !this.isDisabled && this.navigationService.isFocusable(this.index);
    };
    TreeViewItemDirective.prototype.focusItem = function (scrollIntoView) {
        if (scrollIntoView === void 0) { scrollIntoView = false; }
        if (this.isInitialized && this.navigationService.isActive(this.index)) {
            this.element.nativeElement.focus({ preventScroll: !scrollIntoView });
        }
    };
    TreeViewItemDirective.prototype.moveLookupItem = function (changes) {
        if (changes === void 0) { changes = {}; }
        var dataItem = changes.dataItem, index = changes.index, parentDataItem = changes.parentDataItem, parentIndex = changes.parentIndex;
        if ((index && index.firstChange) || //skip first change
            (!dataItem && !index && !parentDataItem && !parentIndex)) {
            return;
        }
        var oldIndex = (index || {}).previousValue || this.index;
        this.lookupService.replaceItem(oldIndex, this.treeItem, this.parentTreeItem);
    };
    TreeViewItemDirective.prototype.moveNavigationItem = function (indexChange) {
        if (indexChange === void 0) { indexChange = {}; }
        var currentValue = indexChange.currentValue, firstChange = indexChange.firstChange, previousValue = indexChange.previousValue;
        if (!firstChange && utils_1.isPresent(currentValue) && utils_1.isPresent(previousValue)) {
            this.navigationService.unregisterItem(this.id, previousValue);
            this.navigationService.registerItem(this.id, currentValue, this.isDisabled, this.isButton);
        }
    };
    TreeViewItemDirective.prototype.updateNodeAvailability = function () {
        var service = this.navigationService;
        if (this.isDisabled || !this.isVisible) {
            service.activateClosest(this.index); // activate before unregister the item
        }
        else {
            service.activateFocusable();
        }
        service.unregisterItem(this.id, this.index);
        service.registerItem(this.id, this.index, this.isDisabled, this.isButton, this.isVisible);
    };
    TreeViewItemDirective.prototype.setAriaAttributes = function () {
        this.setAttribute('aria-level', this.ib.level(this.index).toString());
        // don't render attributes when the component configuration doesn't allow the specified state
        this.setAttribute('aria-expanded', this.expandable ? this.isExpanded.toString() : null);
        this.setAttribute('aria-selected', this.selectable ? this.isSelected.toString() : null);
        this.setAttribute('aria-checked', this.checkable ? this.ariaChecked : null);
    };
    TreeViewItemDirective.prototype.setDisabledClass = function () {
        this.setClass('k-disabled', this.isDisabled);
    };
    TreeViewItemDirective.prototype.setClass = function (className, toggle) {
        var action = toggle ? 'addClass' : 'removeClass';
        this.renderer[action](this.element.nativeElement, className);
    };
    TreeViewItemDirective.prototype.updateTabIndex = function () {
        this.setAttribute('tabIndex', this.isFocusable() ? '0' : '-1');
    };
    TreeViewItemDirective.prototype.setAttribute = function (attr, value) {
        if (!utils_1.isPresent(value)) {
            this.renderer.removeAttribute(this.element.nativeElement, attr);
            return;
        }
        this.renderer.setAttribute(this.element.nativeElement, attr, value);
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], TreeViewItemDirective.prototype, "dataItem", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], TreeViewItemDirective.prototype, "index", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], TreeViewItemDirective.prototype, "parentDataItem", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], TreeViewItemDirective.prototype, "parentIndex", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], TreeViewItemDirective.prototype, "role", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], TreeViewItemDirective.prototype, "loadOnDemand", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], TreeViewItemDirective.prototype, "checkable", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], TreeViewItemDirective.prototype, "selectable", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], TreeViewItemDirective.prototype, "expandable", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], TreeViewItemDirective.prototype, "isChecked", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], TreeViewItemDirective.prototype, "isDisabled", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], TreeViewItemDirective.prototype, "isVisible", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [Boolean])
    ], TreeViewItemDirective.prototype, "isExpanded", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [Boolean])
    ], TreeViewItemDirective.prototype, "isSelected", null);
    TreeViewItemDirective = tslib_1.__decorate([
        core_1.Directive({ selector: '[kendoTreeViewItem]' }),
        tslib_1.__metadata("design:paramtypes", [core_1.ElementRef,
            expand_state_service_1.ExpandStateService,
            navigation_service_1.NavigationService,
            selection_service_1.SelectionService,
            treeview_lookup_service_1.TreeViewLookupService,
            core_1.Renderer2,
            index_builder_service_1.IndexBuilderService])
    ], TreeViewItemDirective);
    return TreeViewItemDirective;
}());
exports.TreeViewItemDirective = TreeViewItemDirective;
