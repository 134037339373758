/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var calendar_messages_1 = require("./calendar-messages");
/**
 * Custom component messages override default component messages ([see example]({% slug globalization_dateinputs %}#toc-custom-messages)).
 */
var CalendarCustomMessagesComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CalendarCustomMessagesComponent, _super);
    function CalendarCustomMessagesComponent(service) {
        var _this = _super.call(this) || this;
        _this.service = service;
        return _this;
    }
    CalendarCustomMessagesComponent_1 = CalendarCustomMessagesComponent;
    Object.defineProperty(CalendarCustomMessagesComponent.prototype, "override", {
        get: function () {
            return true;
        },
        enumerable: true,
        configurable: true
    });
    var CalendarCustomMessagesComponent_1;
    CalendarCustomMessagesComponent = CalendarCustomMessagesComponent_1 = tslib_1.__decorate([
        core_1.Component({
            providers: [
                {
                    provide: calendar_messages_1.CalendarMessages,
                    useExisting: core_1.forwardRef(function () { return CalendarCustomMessagesComponent_1; })
                }
            ],
            selector: 'kendo-calendar-messages',
            template: ""
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService])
    ], CalendarCustomMessagesComponent);
    return CalendarCustomMessagesComponent;
}(calendar_messages_1.CalendarMessages));
exports.CalendarCustomMessagesComponent = CalendarCustomMessagesComponent;
