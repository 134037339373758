//import angular from 'angular';
import * as _ from 'underscore'


    angular.module('app').filter('dateFormat', dateFormatFilter);

    // @ts-ignore
    dateFormatFilter.$inject = ["appConfig", "$filter"];
    function dateFormatFilter(appConfig, $filter) {
        var dateFilter = $filter('date');

        return function (date, dateFormat, includeTime) {
            if (date === undefined || date === null || (_.isString(date) && date.trim() === "")) {
                return appConfig.defaultTextIfEmpty;
            }
            var dateValue = Date.parse(date);

            if (isNaN(dateValue) && _.isString(date)) {
                dateValue = Date.parse(date.trim().replace(/-/g, ' '));
            }
            //Todo: It does not add any value for the end user to log to the console.
            //if (isNaN(dateValue)) {
            //    console.log('Invalid date time');
            //}
            if (dateValue < appConfig.dateTime.minValue.getTime() || dateValue > appConfig.dateTime.maxValue.getTime()) {
                return appConfig.defaultTextIfEmpty;
            }
            if (dateFormat) {
                return dateFilter(dateValue, dateFormat);
            }
            dateFormat = includeTime ? appConfig.dateFormat.shortDateTime : appConfig.dateFormat.shortDate;

            return dateFilter(dateValue, dateFormat);
        };
    }
