//import angular from 'angular';

    angular.module('app.directives').directive('ocChartRect',
            function () {
                return {
                    replace: true,
                    scope: {
                        config: "="
                    },
                    template:
                    '<g ng-class="config.cssClass" ng-click="config.click()" ng-mouseenter="config.hoverStart()" ng-mouseleave="config.hoverEnd()">' +
                    '    <rect ng-attr-x="{{config.x}}" ng-attr-y="{{config.y}}" ng-attr-width="{{config.width}}" ng-attr-height="{{config.height}}" />' +
                    '    <text ng-click="config.textClickHandler()" ng-attr-x="{{config.x + config.width / 2}}" ng-attr-y="{{config.y + config.height - 5}}" ng-attr-width="{{config.width}}" ng-attr-height="{{config.height}}">{{config.text}}</text>' +
                    '</g>'
                };
            }
        );
