//import angular from 'angular';
import * as _ from 'underscore';
import BrowserSvc from '../../../services/browserSvc';
import OcDateSvc from '../../../services/ocDateSvc';
import { TMaintenanceSession } from '../maintenance/maintenanceCtrl';

export interface TMaintenanceSessionCheckInCheckOutTimeValidator {
    isTotalOverTime: boolean;
    isOverRelatedWork: boolean;
    isOverDelayWork: boolean;
}

    angular.module("app.fieldSurveys").service("maintenanceSessionService",
        ["baseModuleService", "WindowFactory", "amtXlatSvc", "ocConfigSvc", "dataBroker", "$filter", "notifySvc",
            "errorReporter", "ocDateSvc", "helperSvc", "amtConstants", "fileManagement", 'browserSvc',
            // ReSharper disable once InconsistentNaming
            function (baseModuleService, WindowFactory, amtXlatSvc, ocConfigSvc, dataBroker, $filter, notifySvc,
                errorReporter, ocDateSvc: OcDateSvc, helperSvc, amtConstants, fileManagement, browserSvc: BrowserSvc) {

                var internalName = "maintenanceSession";
                // ReSharper disable once InconsistentNaming
                var vm = new baseModuleService(internalName);

                vm.trimForUpload = function (records) {

                    return records.map(function (session) {
                        var uploadRecord = {
                            id: session.id,
                            equipmentId: session.vehicle.id,
                            pendingMaintenanceSessionId: session.sessionId,
                            checkIn: vm.setCheckinData(session),
                            checkOut: vm.setCheckoutData(session),
                            removals: vm.setRemovalsData(session),
                            fitments: vm.setFitmentsData(session),
                            readings: vm.setReadingsData(session),
                            isDesktop: false,
                            serialNumber: session.vehicle.serialNumber
                        };
                        return uploadRecord;
                    });
                };

                vm.wrapRecords = function (records) {
                    return { data: records, isMobile: browserSvc.isMobile };
                };


                vm.deregisterInErrorList = function (session: TMaintenanceSession, identifier: string): any[] {
                    if (session?.errorList?.length > 0) {
                        session.errorList = session.errorList.filter(err => err?.identifier !== identifier);
                    }

                    return session.errorList;
                }

                vm.uploadFiles = async function (records) {

                    for (let record of records || []) {
                        for (let reading of record.readings || []) {
                            for (let vi of reading.visualInspections || []) {
                                for (let attachment of vi.attachments || []) {
                                    let file = await fileManagement.uploadFile(attachment.id, false);
                                    // update the file id
                                    if (file) attachment.id = file.id;
                                }
                            }
                        }
                    }
                };

                // TODO: This could probably be moved to the baseModuleService if the header is passed too.
                /**
                 * Create and return the Alert window to be show for the given text / validation rule.
                 * @param {string} text - Main text for the Alert Window
                 * @param {string} [caption] - Caption text. Will default to "Error Message" if nothing is chosen.
                 * @return {Promise}
                 */
                function getOutcome(text, caption?, preventAllow?, params?) {

                    var buttons = [];

                    if (browserSvc.isMobile) {
                        if (!preventAllow) {
                            buttons.push("common.allow");
                        }

                        buttons.push("framework.close_label");
                    } else {
                        buttons.push("framework.ok_label");
                    }

                    return WindowFactory.alert(caption || "maintenanceSession.validationError_Header", buttons, text, params, 720, "left-box")
                        .then(vm.skipAllowed);
                }

                /* validation rules */
                // Check-in date in future (BR004)
                vm.rules.push({
                    name: "inFuture",
                    tab: "checkin",
                    check: function (maintenanceSession) {
                        if (typeof maintenanceSession.skipAllow[this.name] !== "undefined") {
                            // the value would be set to the previous error data value if you want to validate if the skip is still valid;
                            return true;
                        }
                        if (!maintenanceSession.checkin) {
                            return true;
                        }
                        if (maintenanceSession.checkin.checkinDate) {
                            if (new Date(maintenanceSession.checkin.checkinDate) > new Date(Date.now())) {
                                console.warn("Checkin in future");
                                maintenanceSession.errorData = {
                                    fieldIdentifier: "checkinDate"
                                };
                                return false;
                            }
                        }
                        vm.deregisterError(maintenanceSession, this);
                        return true;
                    },
                    summary: function () {
                        return amtXlatSvc.xlat("maintenanceSession.validationSummary_CheckInDateCannotOccurInFuture");
                    },
                    outcome: function () {
                        return getOutcome("maintenanceSession.validationSummary_CheckInDateCannotOccurInFuture");
                    }
                });

                /* validation rules */
                // Check-in date in future (BR004)
                vm.rules.push({
                    name: "checkInGreaterThanLastReadingDate",
                    tab: "checkin",
                    check: function (maintenanceSession, vehicle) {
                        if (typeof maintenanceSession.skipAllow[this.name] !== "undefined") {
                            // the value would be set to the previous error data value if you want to validate if the skip is still valid;
                            return true;
                        }
                        if (!maintenanceSession.checkin) {
                            return true;
                        }
                        if (maintenanceSession.checkin.checkinDate) {
                            if (vehicle) {
                                var prior = vehicle.priorReadings;

                                if (prior.HOURS &&
                                    prior.HOURS.date > new Date(maintenanceSession.checkin.checkinDate)) {
                                    // check-in date cannot be before the previous hours reading date
                                    maintenanceSession.errorData = {
                                        fieldIdentifier: "checkinDate"
                                    };
                                    return false;
                                }

                                if (prior.DISTANCE &&
                                    prior.DISTANCE.date > new Date(maintenanceSession.checkin.checkinDate)) {
                                    // check-in date cannot be before the previous hours reading date
                                    maintenanceSession.errorData = {
                                        fieldIdentifier: "checkinDate"
                                    };
                                    return false;
                                }

                                vm.deregisterError(maintenanceSession, this);
                                return true;
                            }
                        }
                        vm.deregisterError(maintenanceSession, this);
                        return true;
                    },
                    summary: function () {
                        return amtXlatSvc.xlat("equipment.eventDateCannotOccurBeforeLastReading");
                    },
                    outcome: function () {
                        return getOutcome("equipment.eventDateCannotOccurBeforeLastReading");
                    }
                });

                // Hours is mandatory (BR005)
                vm.rules.push({
                    name: "BR005-Hours",
                    tab: "checkin",
                    check: function (maintenanceSession, vehicle) {
                        if (typeof maintenanceSession.skipAllow[this.name] !== "undefined") {
                            // the value would be set to the previous error data value if you want to validate if the skip is still valid;
                            return true;
                        }

                        if (!maintenanceSession.checkin || !maintenanceSession.checkedIn) {
                            return true;
                        }

                        if (vehicle.hasHoursMeter) {
                            if (maintenanceSession.checkin.hours === null || maintenanceSession.checkin.hours === undefined) {
                                console.warn("Hours is required");
                                maintenanceSession.errorData = {
                                    fieldIdentifier: "hours"
                                };
                                return false;
                            }
                        }
                        vm.deregisterError(maintenanceSession, this);
                        return true;
                    },
                    summary: function () {
                        return amtXlatSvc.xlat("maintenanceSession.validationSummary_HoursRequired");
                    },
                    outcome: function () {
                        return getOutcome("maintenanceSession.validationSummary_HoursRequired", null, true);
                    }
                });

                // average speed (BR005)
                vm.rules.push({
                    name: "BR005-AverageSpeed",
                    tab: "checkin",
                    check: function (maintenanceSession, vehicle, field) {

                        if (typeof maintenanceSession.skipAllow[this.name] !== "undefined") {
                            // the value would be set to the previous error data value if you want to validate if the skip is still valid;
                            return true;
                        }

                        // if no checkin, or no max speed has been defined
                        if ((!maintenanceSession.checkin) || vehicle.schematic.maxSpeed === undefined) {
                            return true;
                        }

                        // if the vehicle has both a distance and hours meter
                        if (vehicle.hasDistanceMeter && vehicle.hasHoursMeter) {

                            // if distance and hours have values, and there are both prior hours and distance readings (to calculate a speed)
                            // and neither hours or distance are new meters
                            if (maintenanceSession.checkin.distance && maintenanceSession.checkin.hours &&
                                vehicle.schematic.maxSpeed && vehicle.priorReadings && vehicle.priorReadings.DISTANCE && vehicle.priorReadings.HOURS &&
                                !maintenanceSession.checkin.hoursNewMeter && !maintenanceSession.checkin.distanceNewMeter) {

                                // get total distance/hours accrued since previous reading
                                var distanceChange = maintenanceSession.checkin.distance - vehicle.priorReadings.DISTANCE.value;
                                var hoursChange = maintenanceSession.checkin.hours - vehicle.priorReadings.HOURS.value;

                                var maxSpeed = vehicle.schematic.maxSpeed;

                                if (vehicle.distanceUnit.unitAbbreviation !== 'km') {
                                    maxSpeed = (maxSpeed * 0.621371).toFixed(1);
                                }

                                var averageSpeed = (distanceChange / hoursChange).toFixed(1);

                                if (averageSpeed > maxSpeed) {
                                    console.warn("average speed > max speed");
                                    maintenanceSession.errorData = {
                                        fieldIdentifier: "distance",
                                        averageSpeed: averageSpeed + ' ' + vehicle.distanceUnit.unitAbbreviation + "/h",
                                        maxSpeed: maxSpeed + ' ' + vehicle.distanceUnit.unitAbbreviation + "/h"
                                    };
                                    return false;
                                }
                            }
                        }
                        vm.deregisterError(maintenanceSession, this);
                        return true;
                    },
                    summary: function () {
                        return amtXlatSvc.xlat("maintenanceSession.maxSpeedExceed_summary");
                    },
                    outcome: function (maintenanceSession) {
                        return getOutcome("maintenanceSession.maxSpeedExceed_text", null, false, [maintenanceSession.errorData.averageSpeed, maintenanceSession.errorData.maxSpeed]);
                    }
                });

                // Distance is mandatory (BR005)
                vm.rules.push({
                    name: "BR005-Distance",
                    tab: "checkin",
                    check: function (maintenanceSession, vehicle) {
                        if (typeof maintenanceSession.skipAllow[this.name] !== "undefined") {
                            // the value would be set to the previous error data value if you want to validate if the skip is still valid;
                            return true;
                        }

                        if (!maintenanceSession.checkin || !maintenanceSession.checkedIn) {
                            return true;
                        }

                        if (vehicle.hasDistanceMeter) {
                            if (maintenanceSession.checkin.distance === null || maintenanceSession.checkin.distance === undefined) {
                                console.warn("Distance is required");
                                maintenanceSession.errorData = {
                                    fieldIdentifier: "distance"
                                };
                                return false;
                            }
                        }
                        vm.deregisterError(maintenanceSession, this);
                        return true;
                    },
                    summary: function () {
                        return amtXlatSvc.xlat("maintenanceSession.validationSummary_DistanceRequired");
                    },
                    outcome: function () {
                        return getOutcome("maintenanceSession.validationSummary_DistanceRequired", null, true);
                    }
                });

                // Check In Date is mandatory (BR005)
                vm.rules.push({
                    name: "BR005-CheckInDate",
                    tab: "checkin",
                    check: function (maintenanceSession, vehicle) {
                        if (typeof maintenanceSession.skipAllow[this.name] !== "undefined") {
                            // the value would be set to the previous error data value if you want to validate if the skip is still valid;
                            return true;
                        }

                        if (!maintenanceSession.checkin || !maintenanceSession.checkedIn) {
                            return true;
                        }

                        if (!maintenanceSession.checkin.checkinDate) {
                            console.warn("Check in date is required");
                            maintenanceSession.errorData = {
                                fieldIdentifier: "checkinDate"
                            };
                            return false;
                        }

                        vm.deregisterError(maintenanceSession, this);
                        return true;
                    },
                    summary: function () {
                        return amtXlatSvc.xlat("maintenanceSession.validationSummary_CheckInDateRequired");
                    },
                    outcome: function () {
                        return getOutcome("maintenanceSession.validationSummary_CheckInDateRequired", null, true);
                    }
                });

                // Hours less than previous (BR006)
                vm.rules.push({
                    name: "BR006-Less",
                    tab: "checkin",
                    check: function (maintenanceSession, vehicle) {
                        if (typeof maintenanceSession.skipAllow[this.name] !== "undefined") {
                            // the value would be set to the previous error data value if you want to validate if the skip is still valid;
                            return true;
                        }
                        if (!maintenanceSession.checkin) {
                            return true;
                        }

                        if (!maintenanceSession.checkin.hoursNewMeter) {

                            var prior = vehicle.priorReadings;

                            if (maintenanceSession.checkin.checkinDate && (prior.HOURS === null || maintenanceSession.checkin.checkinDate < prior.HOURS.date)) {
                                return true;
                            }

                            if (prior && prior.HOURS && prior.HOURS.value) {

                                var h = maintenanceSession.checkin.hours || prior.HOURS.value;

                                if (h < prior.HOURS.value) {
                                    console.warn("hours less than previous");
                                    maintenanceSession.errorData = {
                                        fieldIdentifier: "hours",
                                        keyIdentifier: "reading_readingCannotBeLower"
                                    };
                                    return false;
                                }
                            }
                        }
                        vm.deregisterError(maintenanceSession, this);
                        return true;
                    },
                    summary: function () {
                        return amtXlatSvc.xlat("maintenanceSession.validationSummary_HoursDecreased");
                    },
                    outcome: function () {
                        return getOutcome(browserSvc.isMobile
                            ? "maintenanceSession.validationError_Message_HoursDecreased_Mobile"
                            : "maintenanceSession.validationError_Message_HoursDecreased_Desktop");
                    }
                });

                // Hours more than next (BR006)
                vm.rules.push({
                    name: "BR006-More",
                    tab: "checkin",
                    check: function (maintenanceSession, vehicle) {

                        if (typeof maintenanceSession.skipAllow[this.name] !== "undefined") {
                            // the value would be set to the previous error data value if you want to validate if the skip is still valid;
                            return true;
                        }

                        if (!maintenanceSession.checkin) {
                            return true;
                        }

                        var next = vehicle.nextReadings;

                        if (next && next.HOURS && next.HOURS.value && !next.HOURS.isNewMeter) {
                            var h = maintenanceSession.checkin.hours || next.HOURS.value;
                            if (h > next.HOURS.value) {
                                console.warn("hours greater than next");
                                maintenanceSession.errorData = {
                                    fieldIdentifier: "hours",
                                    keyIdentifier: "reading_readingCannotBeHigher"
                                };
                                return false;
                            }
                        }

                        vm.deregisterError(maintenanceSession, this);
                        return true;
                    },
                    summary: function () {
                        return amtXlatSvc.xlat("maintenanceSession.validationSummary_HoursIncreased");
                    },
                    outcome: function () {
                        return getOutcome(browserSvc.isMobile
                            ? "maintenanceSession.validationError_Message_HoursIncreased_Mobile"
                            : "maintenanceSession.validationError_Message_HoursIncreased_Desktop");
                    }
                });

                // Hours increased more than physical (BR007)
                vm.rules.push({
                    name: "BR007",
                    tab: "checkin",
                    check: function (maintenanceSession, vehicle) {
                        if (typeof maintenanceSession.skipAllow[this.name] !== "undefined") {
                            // the value would be set to the previous error data value if you want to validate if the skip is still valid;
                            return true;
                        }
                        if (!maintenanceSession.checkin) {
                            return true;
                        }

                        var prior = vehicle.priorReadings;

                        if (prior &&
                            prior.HOURS && prior.HOURS.value &&
                            !maintenanceSession.checkin.hoursNewMeter &&
                            prior.HOURS.date) {

                            var sessionDate = maintenanceSession.checkin.checkinDate || new Date();

                            if (prior.HOURS.date > sessionDate) {
                                // this occurs when the most recent reading is set into the future. Shouldn't really happen'
                                return true;
                            }

                            var h = maintenanceSession.checkin.hours || vehicle.priorReadings.HOURS.value;

                            var hoursElapsed = (sessionDate - prior.HOURS.date) / (60 * 60 * 1000);

                            if (hoursElapsed > 0 && hoursElapsed < (h - vehicle.priorReadings.HOURS.value)) {
                                console.warn("Hours increased more than physical hours");
                                maintenanceSession.errorData = {
                                    fieldIdentifier: "hours",
                                    keyIdentifier: "reading_hoursCannotIncreaseMoreThanTimeElapsed"
                                };
                                return false;
                            }
                        }
                        vm.deregisterError(maintenanceSession, this);
                        return true;
                    },
                    summary: function () {
                        return amtXlatSvc.xlat("fieldSurvey.IncrorrectHoursElapsed");
                    },
                    outcome: function (maintenanceSession) {

                        var prior = maintenanceSession.vehicle.priorReadings;
                        var sessionDate = maintenanceSession.checkin.checkinDate || new Date();
                        var hours = maintenanceSession.checkin.hours || maintenanceSession.vehicle.priorReadings.HOURS.value;
                        var hoursElapsed = (sessionDate - prior.HOURS.date) / (60 * 60 * 1000);

                        return getOutcome("fieldSurvey.IncrorrectHoursElapsed_detail", null, false, [(hours - prior.HOURS.value), Math.trunc(hoursElapsed)]);
                    }
                });

                // Distance less than previous (BR008)
                vm.rules.push({
                    name: "BR008-Less",
                    tab: "checkin",
                    check: function (maintenanceSession, vehicle) {
                        if (typeof maintenanceSession.skipAllow[this.name] !== "undefined") {
                            // the value would be set to the previous error data value if you want to validate if the skip is still valid;
                            return true;
                        }

                        if (!maintenanceSession.checkin) {
                            return true;
                        }

                        var prior = vehicle.priorReadings;

                        if (prior &&
                            prior.DISTANCE && prior.DISTANCE.value &&
                            !maintenanceSession.checkin.distanceNewMeter
                        ) {

                            if (maintenanceSession.checkin.checkinDate && (prior.DISTANCE === null || maintenanceSession.checkin.checkinDate < prior.DISTANCE.date)) {
                                return true;
                            }

                            var h = maintenanceSession.checkin.distance || prior.DISTANCE.value;

                            if (h < prior.DISTANCE.value) {
                                console.warn("distance less than previous");
                                maintenanceSession.errorData = {
                                    fieldIdentifier: "distance",
                                    keyIdentifier: "reading_readingCannotBeLower"
                                };
                                return false;
                            }
                        }
                        vm.deregisterError(maintenanceSession, this);
                        return true;
                    },
                    summary: function () {
                        return amtXlatSvc.xlat("maintenanceSession.validationSummary_DistanceDecreased");
                    },
                    outcome: function () {
                        return getOutcome("maintenanceSession.validationSummary_DistanceDecreased");
                    }
                });

                // Distance more than next (BR008)
                vm.rules.push({
                    name: "BR008-More",
                    tab: "checkin",
                    check: function (maintenanceSession, vehicle) {

                        if (typeof maintenanceSession.skipAllow[this.name] !== "undefined") {
                            // the value would be set to the previous error data value if you want to validate if the skip is still valid;
                            return true;
                        }

                        if (!maintenanceSession.checkin) {
                            return true;
                        }

                        var next = vehicle.nextReadings;

                        if (next && next.DISTANCE && next.DISTANCE.value && !next.DISTANCE.isNewMeter) {
                            var h = maintenanceSession.checkin.distance || next.DISTANCE.value;
                            if (h > next.DISTANCE.value) {
                                console.warn("distance more than next");
                                maintenanceSession.errorData = {
                                    fieldIdentifier: "distance",
                                    keyIdentifier: "reading_readingCannotBeHigher"
                                };
                                return false;
                            }
                        }

                        vm.deregisterError(maintenanceSession, this);
                        return true;
                    },
                    summary: function () {
                        return amtXlatSvc.xlat("maintenanceSession.validationSummary_DistanceIncreased");
                    },
                    outcome: function () {
                        return getOutcome("maintenanceSession.validationSummary_DistanceIncreased");
                    }
                });

                // Check out date before check in date (BR010)
                vm.rules.push({
                    name: "BR010",
                    tab: "checkout",
                    check: function (maintenanceSession) {
                        if (!maintenanceSession.checkout || !maintenanceSession.checkout.checkoutDate) {
                            // Not checked out yet, can't run this rule.
                            return true;
                        }

                        if (maintenanceSession.checkout.checkoutDate <= maintenanceSession.checkin.checkinDate) {
                            console.warn("checkout before checkin");
                            maintenanceSession.errorData = {
                                fieldIdentifier: "checkoutDate"
                            };
                            return false;
                        }

                        vm.deregisterError(maintenanceSession, this);
                        return true;
                    },
                    summary: function () {
                        return amtXlatSvc.xlat("maintenanceSession.validationSummary_CheckoutDate");
                    },
                    outcome: function () {
                        return getOutcome("maintenanceSession.validationSummary_CheckoutDate");
                    }
                });

                // Total Downtime less than Tyre related work (BR012)
                vm.rules.push({
                    name: "BR012",
                    tab: "checkout",
                    check: function (maintenanceSession) {
                        if (vm.isValidCheckinCheckoutTimes(maintenanceSession)) {

                            let validated: TMaintenanceSessionCheckInCheckOutTimeValidator = vm.setValidateCheckInCheckOutTime(maintenanceSession);

                            if (!maintenanceSession.checkout || !maintenanceSession.checkout.tyreRelatedWork) {
                                // Checkout or data entry hasn't occurred yet, can't run this rule.
                                return true;
                            }

                            if (typeof maintenanceSession.skipAllow[this.name] !== "undefined") {
                                // the value would be set to the previous error data value if you want to validate if the skip is still valid;
                                return true;
                            }

                            if (validated.isOverRelatedWork) {
                                console.warn("tyre related work greater than total downtime");
                                maintenanceSession.errorData = {
                                    fieldIdentifier: "tyreRelatedWork"
                                };
                                return false;
                            }
                        }

                        vm.deregisterError(maintenanceSession, this);
                        return true;
                    },
                    summary: function () {
                        return amtXlatSvc.xlat("maintenanceSession.validationSummary_TyreRelatedWork");
                    },
                    outcome: function () {
                        return getOutcome("maintenanceSession.validationSummary_TyreRelatedWork");
                    }
                });

                // Total Downtime less than Tyre related work + Tyre related delays (BR013)
                vm.rules.push({
                    name: "BR013",
                    tab: "checkout",
                    check: function (maintenanceSession) {
                        if (vm.isValidCheckinCheckoutTimes(maintenanceSession)) {

                            let validated: TMaintenanceSessionCheckInCheckOutTimeValidator = vm.setValidateCheckInCheckOutTime(maintenanceSession);

                            if (!maintenanceSession.checkout) {
                                // Checkout or data entry hasn't occurred yet, can't run this rule.
                                return true;
                            }

                            if (typeof maintenanceSession.skipAllow[this.name] !== "undefined") {
                                // the value would be set to the previous error data value if you want to validate if the skip is still valid;
                                return true;
                            }

                            if (validated.isOverDelayWork || (!validated.isOverRelatedWork && !validated.isOverDelayWork && validated.isTotalOverTime)) {
                                console.warn("tyre related work + tyre related delays greater than total downtime");
                                maintenanceSession.errorData = {
                                    fieldIdentifier: "delayTotal"
                                };
                                return false;
                            }
                        }

                        vm.deregisterError(maintenanceSession, this);
                        return true;
                    },
                    summary: function () {
                        return amtXlatSvc.xlat("maintenanceSession.validationSummary_TyreRelatedWorkAndDelays");
                    },
                    outcome: function () {
                        return getOutcome("maintenanceSession.validationSummary_TyreRelatedWorkAndDelays");
                    }
                });

                // Check out date in future
                vm.rules.push({
                    name: "checkOutInFuture",
                    tab: "checkout",
                    check: function (maintenanceSession) {
                        if (typeof maintenanceSession.skipAllow[this.name] !== "undefined") {
                            // the value would be set to the previous error data value if you want to validate if the skip is still valid;
                            return true;
                        }

                        if (!maintenanceSession.checkout || !maintenanceSession.checkout.checkoutDate) {
                            // Not checked out yet, can't run this rule.
                            return true;
                        }

                        if (new Date(maintenanceSession.checkout.checkoutDate) > new Date(Date.now())) {
                            console.warn("Checkout in future");
                            maintenanceSession.errorData = {
                                fieldIdentifier: "checkoutDate"
                            };
                            return false;
                        }

                        vm.deregisterError(maintenanceSession, this);
                        return true;
                    },
                    summary: function () {
                        return amtXlatSvc.xlat("maintenanceSession.validationSummary_CheckOutDateCannotOccurInFuture");
                    },
                    outcome: function () {
                        return getOutcome("maintenanceSession.validationSummary_CheckOutDateCannotOccurInFuture");
                    }
                });

                // Incomplete wheel positions (BR011)
                vm.rules.push({
                    name: "BR011",
                    tab: "checkout",
                    check: function (maintenanceSession, vehicle, field) {

                        // only check this on check out/update check out
                        if (!field || field.indexOf('checkOut') === -1) {
                            return true;
                        }

                        if (!maintenanceSession.checkout ||
                            !maintenanceSession.checkout.checkoutDate ||
                            (!maintenanceSession.checkout.selectedFitters || maintenanceSession.checkout.selectedFitters.length === 0) ||
                            !maintenanceSession.vehicle.schematic ||
                            ocConfigSvc.user.site.settings.tyreMaintenance.checkOutWithIncompleteWP === "Allow") {
                            // Not checked out yet or the site doesn't care about incomplete wheel positions.
                            // don't run this on update check out
                            return true;
                        }

                        var positions = [];

                        for (var k = 0; k < maintenanceSession.vehicle.schematic.axles.length; k++) {
                            for (var j = 0; j < maintenanceSession.vehicle.schematic.axles[k].positions.length; j++) {
                                positions.push(maintenanceSession.vehicle.schematic.axles[k].positions[j]);
                            }
                        }

                        var valid = _.all(positions, function (position) {
                            if (ocConfigSvc.user.site.settings.tyreMaintenance.trackRims) {
                                // Tracking Rims, so ensure each position has atleast 1 Rim and 1 Tyre (if it has more, there's bigger issues...)
                                return _.any(position.fitments, function (fitment) {
                                    return fitment.type === "Rim";
                                }) &&
                                    _.any(position.fitments, function (fitment) {
                                        return fitment.type === "Tyre";
                                    });
                            } else {
                                // Not tracking Rims, just make sure each position has atleast 1 Tyre.
                                return _.any(position.fitments, function (fitment) {
                                    return fitment.type === "Tyre";
                                });
                            }
                        });

                        if (!valid) {
                            console.warn("1 or more positions are incomplete");
                            maintenanceSession.errorData = {
                                fieldIdentifier: "checkOut"
                            };
                        } else {
                            vm.deregisterError(maintenanceSession, this);
                        }

                        return valid;
                    },
                    summary: function () {
                        return amtXlatSvc.xlat("maintenanceSession.validationError_Message_IncompletePosition");
                    },
                    outcome: function (moduleItem) {

                        if (moduleItem) {
                            var saveResource = (ocConfigSvc.user.site.settings.tyreMaintenance.checkOutWithIncompleteWP !== "Warning" && browserSvc.isMobile ?
                                "common.ok_label" : "common.save_label");

                            return WindowFactory.openItemAsync({
                                component: "incomplete-positions-window",
                                caption: ocConfigSvc.user.site.settings.tyreMaintenance.checkOutWithIncompleteWP === "Warning"
                                    ? amtXlatSvc.xlat("maintenanceSession.validationWarning_Header")
                                    : amtXlatSvc.xlat("maintenanceSession.validationError_Header"),
                                initParams: {
                                    windowType: ocConfigSvc.user.site.settings.tyreMaintenance.checkOutWithIncompleteWP,
                                    moduleItem: moduleItem
                                },
                                canClose: false,
                                width: 500
                            }).promise.then(function (button) {
                                return vm.skipAllowed(button, saveResource);
                            });
                        } else {
                            return getOutcome("maintenanceSession.validationError_Message_IncompletePosition");
                        }
                    }
                });

                // temp or adjusted pressure entered without pressure
                vm.rules.push({
                    name: 'R001',
                    tab: 'readings',
                    check: function (maintenanceSession) {

                        // for each reading
                        var i = 0;

                        if (maintenanceSession.errorData && maintenanceSession.errorData.continue) {
                            i = maintenanceSession.errorData.continue;
                        }

                        for (; i < maintenanceSession.vehicle.positionReadings.length; i++) {

                            // remove existing error for re-evaluation
                            vm.deregisterError(maintenanceSession, this, 'R001:' + i);

                            var reading = maintenanceSession.vehicle.positionReadings[i];
                            var skip = maintenanceSession.skipAllow[this.name];
                            var canSkip = (typeof skip !== 'undefined' && skip['Reading' + reading.positionNo]);

                            if ((reading.temp || reading.adjPressure) && !reading.pressure) {
                                maintenanceSession.errorData = {
                                    index: i,
                                    identifier: 'R001:' + i,
                                    reading: reading,
                                    fieldIdentifier: 'pressure,' + reading.positionNo,
                                    continue: i + 1
                                };

                                // skipping allowed marker.
                                if (!canSkip) {
                                    maintenanceSession.errorData['Reading' + reading.positionNo] = true;
                                    return false;
                                } else {
                                    continue;
                                }
                            }
                        }
                        return true;
                    },
                    summary: function (maintenanceSession) {
                        var header = amtXlatSvc.xlat('fieldSurvey.ReadingPos', maintenanceSession.errorData.reading.positionLabel, maintenanceSession.errorData.reading.positionNo);
                        return header + " " + amtXlatSvc.xlat('fieldSurvey.PressureFirst');
                    },
                    outcome: function (maintenanceSession) {
                        return getOutcome(
                            'fieldSurvey.PressureFirst',
                            amtXlatSvc.xlat('fieldSurvey.ReadingPos', maintenanceSession.errorData.reading.positionLabel, maintenanceSession.errorData.reading.positionNo)
                        );
                    }
                });

                // nitrogen must be between 0 and 100
                vm.rules.push({
                    name: 'NitrogenRange',
                    tab: 'readings',
                    check: function (maintenanceSession) {

                        // for each reading
                        let i = 0;

                        if (maintenanceSession.errorData && maintenanceSession.errorData.continue) {
                            i = maintenanceSession.errorData.continue;
                        }

                        for (; i < maintenanceSession.vehicle.positionReadings.length; i++) {

                            // remove existing error for re-evaluation
                            vm.deregisterError(maintenanceSession, this, 'NitrogenRange:' + i);

                            let reading = maintenanceSession.vehicle.positionReadings[i];

                            let skip = maintenanceSession.skipAllow[this.name];
                            let canSkip = (typeof skip !== 'undefined' && skip['Reading' + reading.positionNo]);

                            if (reading.nitrogen && (reading.nitrogen < 0 || reading.nitrogen > 100)) {
                                maintenanceSession.errorData = {
                                    index: i,
                                    identifier: 'NitrogenRange:' + i,
                                    reading: reading,
                                    fieldIdentifier: 'nitrogen,' + reading.positionNo,
                                    continue: i + 1
                                };

                                // skipping allowed marker.
                                if (!canSkip) {
                                    maintenanceSession.errorData['Reading' + reading.positionNo] = true;
                                    return false;
                                } else {
                                    continue;
                                }
                            }
                        }
                        return true;
                    },
                    summary: function (maintenanceSession) {
                        var header = amtXlatSvc.xlat('fieldSurvey.ReadingPos', maintenanceSession.errorData.reading.positionLabel, maintenanceSession.errorData.reading.positionNo);
                        return header + " " + amtXlatSvc.xlat('fieldSurvey.nitrogenOutOfRange');
                    },
                    outcome: function (maintenanceSession) {
                        return getOutcome(
                            'fieldSurvey.nitrogenOutOfRange',
                            amtXlatSvc.xlat('fieldSurvey.ReadingPos', maintenanceSession.errorData.reading.positionLabel, maintenanceSession.errorData.reading.positionNo)
                        );
                    }
                });

                // Only one of RTD1/RTD2 provided
                vm.rules.push({
                    name: 'R002',
                    tab: 'readings',
                    check: function (maintenanceSession) {

                        // for each reading                    
                        var i = 0;
                        if (maintenanceSession.errorData && maintenanceSession.errorData.continue) {
                            i = maintenanceSession.errorData.continue;
                        }

                        for (; i < maintenanceSession.vehicle.positionReadings.length; i++) {

                            // remove existing error for re-evaluation
                            vm.deregisterError(maintenanceSession, this, 'R002:' + i);

                            var reading = maintenanceSession.vehicle.positionReadings[i];
                            var skip = maintenanceSession.skipAllow[this.name];
                            var canSkip = (typeof skip !== 'undefined' && skip['Reading' + reading.positionNo]);

                            if (reading.rtd1 && !reading.rtd2) {

                                maintenanceSession.errorData = {
                                    index: i,
                                    reading: reading,
                                    data: ["RTD2", i + 1],
                                    identifier: 'R002:' + i,
                                    fieldIdentifier: 'rtd2,' + reading.positionNo,
                                    continue: i + 1
                                };

                                // skipping allowed marker.
                                if (!canSkip) {
                                    maintenanceSession.errorData['Reading' + reading.positionNo] = true;
                                    return false;
                                } else {
                                    continue;
                                }
                            }

                            if (!reading.rtd1 && reading.rtd2) {
                                maintenanceSession.errorData = {
                                    index: i,
                                    reading: reading,
                                    data: ["RTD1", i + 1],
                                    identifier: 'R002:' + i,
                                    fieldIdentifier: 'rtd1,' + reading.positionNo,
                                    continue: i + 1
                                };

                                // skipping allowed marker.
                                if (!canSkip) {
                                    maintenanceSession.errorData['Reading' + reading.positionNo] = true;
                                    return false;
                                } else {
                                    continue;
                                }
                            }
                        }
                        return true;
                    },
                    summary: function (maintenanceSession) {
                        var header = amtXlatSvc.xlat('fieldSurvey.ReadingPos', maintenanceSession.errorData.reading.positionLabel, maintenanceSession.errorData.reading.positionNo);
                        var text = amtXlatSvc.xlat('fieldSurvey.ReadingRequired', ...maintenanceSession.errorData.data);
                        return header + " " + text;
                    },
                    outcome: function (maintenanceSession) {
                        return getOutcome(
                            'fieldSurvey.ReadingRequired',
                            amtXlatSvc.xlat('fieldSurvey.ReadingPos', maintenanceSession.errorData.reading.positionLabel, maintenanceSession.errorData.reading.positionNo),
                            false,
                            [maintenanceSession.errorData.data[0], maintenanceSession.errorData.data[1]]
                        );
                    }
                });

                // rtd greater than previous rtd
                vm.rules.push({
                    name: 'R003',
                    tab: 'readings',
                    check: function (maintenanceSession) {

                        var positions = maintenanceSession.vehicle.positions;

                        var i = 0;
                        if (maintenanceSession.errorData && maintenanceSession.errorData.continue) {
                            i = maintenanceSession.errorData.continue;
                        }

                        for (; i < maintenanceSession.vehicle.positionReadings.length; i++) {
                            // remove existing errors for re-evaluation
                            vm.deregisterError(maintenanceSession, this, 'R003:' + i);

                            var reading = maintenanceSession.vehicle.positionReadings[i];
                            var skip = maintenanceSession.skipAllow[this.name];
                            var canSkip = (typeof skip !== 'undefined' && skip['Reading' + reading.positionNo]);

                            if (!reading.rtd1 || !reading.rtd2) {
                                continue; // no readings entered or different tyre
                            }

                            var avg = (Number(reading.rtd1) + Number(reading.rtd2)) / 2;
                            var pos = positions[reading.positionId];

                            if (pos && pos.TyreFitment && pos.TyreFitment.remainingDepth && pos.TyreFitment.remainingDepth.value) {
                                var lastValue = pos.TyreFitment.remainingDepth.value;

                                if (avg > lastValue) {
                                    maintenanceSession.errorData = {
                                        index: i,
                                        current: avg,
                                        previous: lastValue,
                                        serial: pos.TyreFitment.serialNumber,
                                        reading: reading,
                                        identifier: 'R003:' + i,
                                        fieldIdentifier: 'rtd1,' + reading.positionNo,
                                        continue: i + 1
                                    };

                                    // skipping allowed marker.
                                    if (!canSkip) {
                                        maintenanceSession.errorData['Reading' + reading.positionNo] = true;
                                        return false;
                                    } else {
                                        continue;
                                    }
                                }
                            }
                        }
                        return true;
                    },
                    summary: function (maintenanceSession) {
                        var header = amtXlatSvc.xlat('fieldSurvey.ReadingPos', maintenanceSession.errorData.reading.positionLabel, maintenanceSession.errorData.reading.positionNo);
                        return header + " " + amtXlatSvc.xlat('fieldSurvey.higherReading');
                    },
                    outcome: function (maintenanceSession) {
                        return getOutcome(
                            'fieldSurvey.averageDepthGreaterThanPrevious',
                            amtXlatSvc.xlat('fieldSurvey.ReadingPos', maintenanceSession.errorData.reading.positionLabel, maintenanceSession.errorData.reading.positionNo),
                            false,
                            [maintenanceSession.errorData.serial, maintenanceSession.errorData.current, maintenanceSession.errorData.previous]
                        );
                    }
                });

                vm.setCheckoutData = function (session) {

                    if (session.checkout && session.checkout.checkoutDate) {
                        var checkoutData = {
                            checkoutDetails: {
                                nonTyreRelatedWork: session.checkout.hasNonRelatedTyreWork,
                                tyreRelatedWork: session.checkout.tyreRelatedWork,
                                fitters: session.checkout.selectedFitters.map(function (f) { return { key: (f.key ? f.key : f) } }),
                                workshopSessionTimes: [],
                                averageTyreChangeTime: session.checkout.averageTyreChange,
                                transferDetails: undefined,
                                totalDownTime: session.checkout.totalDowntime
                            },
                            checkInEventId: session.checkin.eventId,
                            equipmentId: session.vehicle.id,
                            eventDate: ocDateSvc.removeLocalTimeZoneOffset(session.checkout.checkoutDate),
                            comment: session.checkout.comment,
                            equipmentEventId: session.checkout.eventId,
                            siteId: session.siteId,
                            newStatusTypeId: (session.newStatusTypeId ? session.newStatusTypeId : session.checkout.newStatusTypeId),
                            checkedOut: session.checkedOut === true
                        }

                        if (session.checkout.transferDetails) {
                            checkoutData.checkoutDetails.transferDetails = {
                                destinationSiteId: session.checkout.transferDetails.destinationSiteId,
                                    destinationOther: session.checkout.transferDetails.destinationOther,
                                        transferredFromMaintenance: session.checkout.transferDetails.transferredFromMaintenance
                            }
                        }

                        if (session.checkout.delays) {
                            for (var d = 0; d < session.checkout.delays.length; d++) {
                                checkoutData.checkoutDetails.workshopSessionTimes.push({
                                    workshopSessionTimeTypeId: session.checkout.delays[d].selectedDelayReason.id,
                                    durationMinutes: session.checkout.delays[d].minutes
                                });
                            }
                        }

                        var remainingTime = session.checkout.totalDowntime - checkoutData.checkoutDetails.workshopSessionTimes.reduce((a, b) => { return a + b.durationMinutes; }, 0);

                        checkoutData.checkoutDetails.workshopSessionTimes.push({
                            name: "TRW",
                            durationMinutes: (session.checkout.hasNonRelatedTyreWork ? session.checkout.tyreRelatedWork : remainingTime)
                        });

                        if (session.checkout.hasNonRelatedTyreWork) {

                            remainingTime = session.checkout.totalDowntime - checkoutData.checkoutDetails.workshopSessionTimes.reduce((a, b) => { return a + b.durationMinutes; }, 0);

                            if (remainingTime) {
                                checkoutData.checkoutDetails.workshopSessionTimes.push({
                                    name: "NTRW",
                                    durationMinutes: remainingTime
                                });
                            }
                        }

                        return checkoutData;
                    } else return null;
                };

                vm.isValidCheckinCheckoutTimes = function (currentSession: TMaintenanceSession): boolean {
                    let checkInDateTime: Date | undefined | null = currentSession?.checkin?.checkinDate;
                    let checkOutDateTime: Date | undefined | null = currentSession?.checkout?.checkoutDate;

                    if (checkInDateTime && checkOutDateTime && (checkOutDateTime > checkInDateTime))
                        return true;

                    return false;
                }

                vm.setValidateCheckInCheckOutTime = function (currentSession: TMaintenanceSession): TMaintenanceSessionCheckInCheckOutTimeValidator  {
                    let totalDowntime: number = 0;
                    let tyreRelatedWork: number = 0;
                    let tyreDelayWork: number = 0;

                    if (currentSession !== undefined && currentSession?.checkedIn) {
                        let tyreDelayTimes: number[] = (currentSession?.checkout?.delays?.map(delay => delay.minutes)) || [];

                        totalDowntime = currentSession?.checkout?.totalDowntime !== undefined && currentSession?.checkout?.totalDowntime !== null ? currentSession?.checkout?.totalDowntime : 0;
                        tyreDelayWork = tyreDelayTimes.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                        tyreRelatedWork = currentSession?.checkout?.tyreRelatedWork !== undefined && currentSession?.checkout?.tyreRelatedWork !== null
                                        && currentSession?.checkout?.hasNonRelatedTyreWork ? currentSession?.checkout?.tyreRelatedWork : 0;

                    }

                    return {
                        isTotalOverTime: tyreDelayWork + tyreRelatedWork > totalDowntime,
                        isOverDelayWork: tyreDelayWork > totalDowntime,
                        isOverRelatedWork: tyreRelatedWork > totalDowntime
                    };
                }

                vm.setCheckinData = function (session) {

                    var checkinData = {
                        checkInDetails: {
                            travelTimeToWorkshop: session.checkin.travelTime,
                            downtimeTypeId: helperSvc.getKey(session.checkin.downtimeTypeControl),
                            productionCrewId: helperSvc.getKey(session.checkin.productionCrewControl),
                            maintenanceTypeId: helperSvc.getKey(session.checkin.maintenanceTypeControl),
                            workOrderNumber: session.checkin.workOrderNumber
                        },
                        equipmentId: session.vehicle.id,
                        eventDate: ocDateSvc.removeLocalTimeZoneOffset(session.checkin.checkinDate),
                        comment: session.checkin.comment,
                        equipmentEventId: session.checkin.eventId,
                        siteId: session.siteId,
                        readings: { readings: [] }
                    }

                    if (session.checkin.hours !== undefined && session.checkin.hours !== null) {
                        checkinData.readings.readings.push({
                            readingEventTypeName: "hours",
                            isNewMeter: session.checkin.hoursNewMeter,
                            unitOfMeasureAbbreviation: ocConfigSvc.user.site.units.time.unitAbbreviation,
                            values: [{
                                value: session.checkin.hours,
                                sequence: 1
                            }]
                        });
                    }

                    if (session.checkin.distance !== undefined && session.checkin.distance !== null) {
                        checkinData.readings.readings.push({
                            readingEventTypeName: "distance",
                            isNewMeter: session.checkin.distanceNewMeter,
                            unitOfMeasureAbbreviation: session.vehicle.distanceUnit.unitAbbreviation,
                            values: [{
                                value: session.checkin.distance,
                                sequence: 1
                            }]
                        });
                    }

                    return checkinData;
                };

                vm.setRemovalsData = function (session) {

                    var components = session.events.items.filter(function (e) { return e.eventData.event === "remove"; }).map(function (i) {

                        return i.eventData.components.map(function (c) {

                            if (!i.eventData.selectedMoveTo) {
                                console.error("Component eventdata has no selectedMoveTo!");
                            }

                            var component = {
                                serialNumber: c.serialNumber,
                                equipmentId: (c.equipmentId || c.componentId || c.id),
                                equipmentType: c.type,
                                equipmentEventId: c.eventId,
                                comment: i.eventData.comment,
                                statusTypeId: i.eventData.selectedMoveTo ? i.eventData.selectedMoveTo.id : null,
                                sequenceDate: ocDateSvc.removeLocalTimeZoneOffset(i.sequenceDate),
                                sequence: _.findIndex(amtConstants.removalOrder, r => { return r === c.type.toLowerCase(); }),
                                locationId: helperSvc.getKey(i.eventData.selectedLocation),
                                removeDetails: {
                                    wheelPositionId: i.eventData.position.id,
                                    removeAsAssembly: i.eventData.components.length > 1,
                                    removeReasonId: helperSvc.getKey(i.eventData.selectedRemovalReason)
                                },
                                damageDetails: (i.eventData.selectedDamageCause ? {
                                    damageCauseId: helperSvc.getKey(i.eventData.selectedDamageCause),
                                    damageLocationId: helperSvc.getKey(i.eventData.selectedDamageLocation),
                                    damageSourceId: helperSvc.getKey(i.eventData.selectedDamageSource),
                                    instant: i.eventData.instant,
                                    tyreBurst: i.eventData.tyreBurst,
                                    tyreExplosion: i.eventData.tyreExplosion
                                } : null),
                                transferDetails: (i.eventData.destination ? {
                                    destinationSiteId: helperSvc.getKey(i.eventData.destination),
                                    destinationOther: i.eventData.otherDestination,
                                    transferredFromMaintenance: true
                                } : null),
                                repairDetails: (i.eventData.repairer ? {
                                    repairerId: helperSvc.getKey(i.eventData.repairer),
                                    repairerOther: i.eventData.otherRepairer
                                } : null),
                                costDetails: (i.eventData.creditAmount ? {
                                    amount: i.eventData.creditAmount,
                                    currencyTypeId: ocConfigSvc.user.site.currency.id
                                } : null),
                                readings: { readings: [] },
                                visualInspections: i.eventData.visualInspections
                            };

                            if (i.eventData.rtd1 !== undefined && i.eventData.rtd1 !== null && c.type === 'Tyre') {
                                component.readings.readings.push({
                                    readingEventTypeName: "treaddepth",
                                    isNewMeter: false,
                                    unitOfMeasureAbbreviation: ocConfigSvc.user.site.units.depth.unitAbbreviation,
                                    values: [{
                                        value: i.eventData.rtd1,
                                        sequence: 1
                                    }, {
                                        value: i.eventData.rtd2,
                                        sequence: 2
                                    }]
                                });
                            }

                            if (i.eventData.rcd1 !== undefined && i.eventData.rcd1 !== null && c.type === 'Chain') {
                                component.readings.readings.push({
                                    readingEventTypeName: "chaindepth",
                                    isNewMeter: false,
                                    unitOfMeasureAbbreviation: ocConfigSvc.user.site.units.depth.unitAbbreviation,
                                    values: [{
                                        value: i.eventData.rcd1,
                                        sequence: 1
                                    }, {
                                        value: i.eventData.rcd2,
                                        sequence: 2
                                    }]
                                });
                            }

                            return component;
                        });
                    });

                    if (components && components.length > 0) {
                        components = components.reduce(function (prev, current) {
                            return prev.concat(current);
                        });
                    }

                    return { components: components };
                };

                vm.setFitmentsData = function (session) {

                    var components = session.events.items.filter(function (e) { return e.eventData.event === "fit" || e.eventData.event === "refit"; }).map(function (i) {

                        return i.eventData.components.map(function (c) {

                            var component = {
                                serialNumber: c.serialNumber,
                                equipmentId: (c.equipmentId || c.componentId || c.id),
                                equipmentType: c.type,
                                equipmentEventId: c.eventId,
                                sequenceDate: ocDateSvc.removeLocalTimeZoneOffset(i.sequenceDate),
                                sequence: _.findIndex(amtConstants.fitmentOrder, f => { return f === c.type.toLowerCase(); }),
                                fitDetails: {
                                    wheelPositionId: i.eventData.selectedPosition.id,
                                    fitAsAssembly: i.type === 'assembly',
                                    nitrogenFilled: i.eventData.nitroFilled,
                                    studsLubricated: i.eventData.lubricatedStuds === true || (helperSvc.getKey(i.eventData.lubricatedStuds) === 1),
                                    pressureSensorId: helperSvc.getKey(i.eventData.pressureSensors)
                                },
                                readings: { readings: [] }
                            };

                            if (i.eventData.temperature !== undefined && i.eventData.temperature !== null) {
                                component.readings.readings.push({
                                    readingEventTypeName: "treadtemp",
                                    isNewMeter: false,
                                    unitOfMeasureAbbreviation: ocConfigSvc.user.site.units.temperature.unitAbbreviation,
                                    values: [{
                                        value: i.eventData.temperature,
                                        sequence: 1
                                    }]
                                });
                            }

                            if (i.eventData.pressure !== undefined && i.eventData.pressure !== null) {
                                component.readings.readings.push({
                                    readingEventTypeName: "pressure",
                                    isNewMeter: false,
                                    unitOfMeasureAbbreviation: ocConfigSvc.user.site.units.pressure.unitAbbreviation,
                                    values: [{
                                        value: i.eventData.pressure,
                                        sequence: 1
                                    }]
                                });
                            }

                            if (i.eventData.torque !== undefined && i.eventData.torque !== null) {
                                component.readings.readings.push({
                                    readingEventTypeName: "torque",
                                    isNewMeter: false,
                                    unitOfMeasureAbbreviation: ocConfigSvc.user.site.units.torque.unitAbbreviation,
                                    values: [{
                                        value: i.eventData.torque,
                                        sequence: 1
                                    }]
                                });
                            }

                            return component;
                        });
                    });

                    if (components && components.length > 0) {
                        components = components.reduce(function (prev, current) {
                            return prev.concat(current);
                        });
                    }

                    return { components: components };
                };

                vm.setReadingsData = function (session) {

                    let unmodifiedPositions = angular.copy(_.filter(session.vehicle.positionReadings, pr => {
                        return !session.vehicle.positions[pr.positionId].fittedInThisSession && session.vehicle.positions[pr.positionId].TyreFitment;
                    }));

                    for (let pos of unmodifiedPositions || []) {

                        pos.visualInspections = pos.visualInspections?.filter(v => v.modified);

                        for (let vi of pos.visualInspections || [])
                            vi.inspectionDateTime = ocDateSvc.dateTimeAsUTC(vi.inspectionDateTime);                        

                        if (pos.pressure != null)
                            pos.readingEventTypeCommentId = session.vehicle.readingEventTypeCommentId;                        
                    }

                    return unmodifiedPositions;
                };

                vm.deleteSession = function (session, id) {

                    return WindowFactory
                        .iconAlert('glyphicon-check',
                            'maintenanceSession.deleteSession',
                            ['framework.cancel_label', 'common.delete_label'],
                            'maintenanceSession.deleteSessionMsg', null, 650).then(function (arg) {

                                if (arg === 'common.delete_label') {

                                    if (session) {

                                        return dataBroker.msDeleteSession(session, id).then(function (response) {

                                            notifySvc.success(amtXlatSvc.xlat("maintenanceSession.sessionSuccessfullyDeleted"));

                                            return true;

                                        }).catch(function (error) {

                                            if (error.param) {
                                                // assume this is a server validation error

                                                // conflicts
                                                if (error.key === 'maintenance_conflictsPreventDelete') {
                                                    WindowFactory
                                                        .iconAlert('glyphicon-remove',
                                                            'maintenanceSession.cannotUndoSession_title',
                                                            ['framework.ok_label'],
                                                            'exception.maintenance_conflictsPreventDelete', '<ul class="notification-list"><br>' + error.param.map(p => { return '<li>' + p + '</li>'; }).join('') + '</ul>', 680).then(function () {
                                                            });
                                                } else {
                                                    errorReporter.logError(error);
                                                }

                                            } else {
                                                // not a validation error
                                                errorReporter.logError(error);
                                            }

                                            return false;
                                        });
                                    }
                                } else {
                                    return null;
                                }
                            });
                };

                vm.clearReading = function (reading) {
                    reading.pressure = "";
                    reading.temp = "";
                    reading.adjPressure = "";
                    reading.rtd1 = "";
                    reading.rtd2 = "";
                }

                vm.processErrors = function (response, uploadErrors, postedRecord) {
                    for (var elementId in response) {
                        if (response.hasOwnProperty(elementId)) {
                            var msg = amtXlatSvc.xlat("maintenanceSession.uploadError", postedRecord.serialNumber,
                                $filter("date")(postedRecord.checkIn.eventDate, "medium"), (postedRecord.checkOut ? $filter("date")(postedRecord.checkOut.eventDate, "medium") : ''));
                            uploadErrors.push({ msg: msg, subErrors: response[elementId] });
                        }
                    }
                };

                return vm;
            }]);
