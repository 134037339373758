//import angular from 'angular';
import * as _ from 'underscore';
import FileManagement from '../../../services/fileManagement';
import AdminUrls from '../adminUrls';
import HelperSvc from '../../../services/helperSvc';
import tmpl from './siteDetails.html';
import OcConfigSvc from '../../../services/ocConfigSvc';

const enum SiteDetailsTab {
    details = 'details',
    localisation = 'localisation',
    components = 'components',
    activity = 'activity',
    serialNumbers = 'serialNumbers',
    forecasting = 'forecasting',
    reporting = 'reporting',
    kpis = 'kpis',
    integration = 'integration'
}

interface ISaveSiteDetailsCommand {
    otracoSite: boolean;
    operationTypes: string[];
    commodities: string[];
    physicalAddress: string;
    postalAddress: string;
    contactId?: string;
    siteUrl: string;
    siteLogo: IFile;
    webLogoBackground: string;
    siteReportLogo: IFile;
    useDefaultSiteLogo: boolean;
    useDefaultSiteReportLogo: boolean;
    isSyncTyrematics: boolean;
}

interface ISiteDetails {
    details: ISiteDetails_Details;
    localisation: any;
    components: any;
    activity: any;
    serialNumbers: any;
    forecasting: any;
    reporting: any;
    kpis: any;
    integration: any;
}

interface ISiteDetails_Details {
    client: string;
    clientId: guid;
    site: string;
    siteId: guid;
    otracoSite: boolean;
    operationTypes: any[];
    operationTypeIds: guid[];
    commodities?: any[];
    commodityIds?: guid[];
    physicalAddress?: string;
    postalAddress?: string;
    contact?: any;
    defaultUrl?: string;
    siteUrl?: string;
    defaultLogo?: IFile;
    siteLogo?: IFile;
    defaultReportLogo?: IFile;
    siteReportLogo?: IFile;
    webLogoBackgroundValue: string;
    webLogoBackground?: any;
    isSyncTyrematics: boolean;
}

interface ISiteDetailsTabs {
    details: ISiteDetailsTab<ISiteDetails_Details>;
    localisation: ISiteDetailsTab<any>;
    components: ISiteDetailsTab<any>;
    activity: ISiteDetailsTab<any>;
    serialNumbers: ISiteDetailsTab<any>;
    forecasting: ISiteDetailsTab<any>;
    reporting: ISiteDetailsTab<any>;
    kpis: ISiteDetailsTab<any>;
    integration: ISiteDetailsTab<any>;
}

interface ISiteDetailsTab<T> {
    save?: () => void,
    cancel?: () => void,
    refresh?: () => void,
    readonly: boolean,
    visible: boolean,
    data?: T,
    groups?: ISiteDetailsTabGroup[],
    actions?: ISiteDetailsTabAction[],
    updateActions?: () => void
}

interface ISiteDetailsTabAction {
    id: string;
    label: string;
    show?: boolean,
    onClick: () => void
}

interface ISiteDetailsTabGroup {
    header?: string,
    column: number,
    configurationTypes: ISiteDetailsTabGroupConfigurationType[]
}

interface ISiteDetailsTabGroupConfigurationType {
    name: string,
    min?: number, // for integer fields, default is 0
    max?: number, // for integer fields
    dependsOn?: string,
    visibleWhen?: string,
    isValid?: () => boolean
}

class SiteDetailsCtrl implements ng.IController {

    form: ng.IFormController;

    kpiGridControl: any = {};

    processing: boolean;

    useDefaultUrl = true;
    useDefaultLogo = true;
    useDefaultReportLogo = true;

    hasNewLogoFile = false;
    hasNewReportLogoFile = false;

    acceptedLogoFileTypes: string[];

    status: ScreenStatus = ScreenStatus.loading;

    site: ISiteDetails;

    localisationBaseUnits = [];
    currencyTypes = [];

    configurationTypes: any = {};
    unitsOfMeasure: any = {};

    selectedItem: any;
    readonly: boolean;

    tabs: ISiteDetailsTabs;

    currentTab: string;
    activeTab: string;

    saveDisabled = true;

    static $inject = ['helperSvc', 'adminUrls', '$scope', 'confirmSvc', 'notifySvc', 'amtCommandQuerySvc', 'referenceDataUrls',
        'ocSecuritySvc', 'amtXlatSvc', '$timeout', 'fileManagement', 'dataBroker', 'WindowFactory', 'errorReporter', 'ocConfigSvc'];

    constructor(private helperSvc: HelperSvc, private adminUrls: AdminUrls, private $scope: ng.IScope, private confirmSvc: IConfirmSvc, private notifySvc: INotifySvc,
        private amtCommandQuerySvc: IAmtCommandQuerySvc, private referenceDataUrls: IReferenceDataUrls, private ocSecuritySvc: IOcSecuritySvc,
        private amtXlatSvc: IAmtXlatSvc, private $timeout: ng.ITimeoutService, private fileManagement: FileManagement,
        private dataBroker: IDataBroker, private WindowFactory: IWindowFactory, private errorReporter: IErrorReporter,
        private ocConfigSvc: OcConfigSvc) {

        this.$scope.$watchGroup([() => this.form.$invalid, () => this.form.$pristine, () => this.processing], () => {
            this.saveDisabled = this.form.$invalid || this.form.$pristine || this.processing;
        });

        this.$scope.$watchGroup([() => this.selectedItem, () => this.currentTab], () => {
            if (this.currentTab && this.tabs && this.tabs[this.currentTab].updateActions)
                this.tabs[this.currentTab].updateActions();
        });

        this.$scope['addEditKpi'] = (dataItem) => this.addEditKpi(dataItem);
    }

    async $onInit() {

        this.acceptedLogoFileTypes = this.fileManagement.getAcceptedFileExtensions([FileType.image]);

        // set up tabs
        this.tabs = {
            details: {
                save: () => this.saveDetails(),
                cancel: () => this.cancel(),
                readonly: !this.ocSecuritySvc.isAuthorised('Security.Settings.SiteDetails.Details', AccessTypes.readWrite),
                visible: this.ocSecuritySvc.isAuthorised('Security.Settings.SiteDetails.Details', AccessTypes.readOnly)
            },

            localisation: {
                save: () => this.saveLocalisation(),
                cancel: () => this.cancel(),
                readonly: !this.ocSecuritySvc.isAuthorised('Security.Settings.SiteDetails.Localisation', AccessTypes.readWrite),
                visible: this.ocSecuritySvc.isAuthorised('Security.Settings.SiteDetails.Localisation', AccessTypes.readOnly)
            },

            components: {
                save: () => this.saveConfigurations(),
                cancel: () => this.cancel(),
                readonly: !this.ocSecuritySvc.isAuthorised('Security.Settings.SiteDetails.Components', AccessTypes.readWrite),
                visible: this.ocSecuritySvc.isAuthorised('Security.Settings.SiteDetails.Components', AccessTypes.readOnly),
                groups: [
                    {
                        header: 'componentsGroupTyres',
                        column: 1,
                        configurationTypes: [
                            { name: 'Client.TyreMaintenance.AllowTemperatureReading' },
                            { name: 'Client.General.UsePressureSensors' },
                            { name: 'Client.TyreMaintenance.UseNitrogen' },
                            { name: 'Client.TyreMaintenance.MinimumNitrogenPercentage', dependsOn: 'Client.TyreMaintenance.UseNitrogen', visibleWhen: 'True' }
                        ]
                    }, {
                        header: 'componentsGroupRims',
                        column: 1,
                        configurationTypes: [
                            { name: 'Client.General.AllowRimSerialNumberGeneration' },
                            { name: 'Client.General.RimSerialNumberGenerationNextNumber', dependsOn: 'Client.General.AllowRimSerialNumberGeneration', visibleWhen: 'True' },
                            { name: 'Client.General.RimSerialNumberGenerationScheme', dependsOn: 'Client.General.AllowRimSerialNumberGeneration', visibleWhen: 'True' }
                        ]
                    }, {
                        header: 'componentsGroupChains',
                        column: 1,
                        configurationTypes: [{ name: 'Client.TyreMaintenance.ShowChains' }]
                    }, {
                        header: 'componentsGroupComponents',
                        column: 2,
                        configurationTypes: [
                            { name: 'Client.TyreMaintenance.StoreLocation' },
                            { name: 'Client.General.EnforceComponentOwner' }
                        ]
                    }
                ]
            },

            activity: {
                save: () => this.saveConfigurations(),
                cancel: () => this.cancel(),
                readonly: !this.ocSecuritySvc.isAuthorised('Security.Settings.SiteDetails.Activity', AccessTypes.readWrite),
                visible: this.ocSecuritySvc.isAuthorised('Security.Settings.SiteDetails.Activity', AccessTypes.readOnly),
                groups: [
                    {
                        header: 'activityGroupMaintenanceField',
                        column: 1,
                        configurationTypes: [
                            { name: 'Client.TyreMaintenance.CheckOutWithIncompleteWP' },
                            { name: 'Client.TyreMaintenance.TrackRims' },
                            { name: 'Client.General.AllowTreadReadingsWithoutVehicleReadings' },
                            { name: 'Client.TyreMaintenance.WorkOrderNumber' }
                        ]
                    },
                    {
                        header: 'activityGroupProcurementIntegration',
                        column: 1,
                        configurationTypes: [
                            { name: 'Client.General.UseProcurement' },
                            { name: 'Client.General.ReceiveThirdPartyVehicles' },
                            { name: 'Client.General.ReceiveThirdPartyVehiclesData' },
                            { name: 'Client.General.ReceiveThirdPartyComponents' }
                        ]
                    },
                    {
                        header: 'activityGroupClaims',
                        column: 2,
                        configurationTypes: [
                            { name: 'Client.MaintenanceAndWarranty.ClaimNumber.AllowAutoGenerate' },
                            { name: 'Client.MaintenanceAndWarranty.ClaimNumber.Prefix', dependsOn: 'Client.MaintenanceAndWarranty.ClaimNumber.AllowAutoGenerate', visibleWhen: 'True' },
                            { name: 'Client.MaintenanceAndWarranty.ClaimNumber.Sequence', dependsOn: 'Client.MaintenanceAndWarranty.ClaimNumber.AllowAutoGenerate', visibleWhen: 'True' }
                        ]
                    }
                ]
            },

            serialNumbers: {
                save: () => this.saveConfigurations(),
                cancel: () => this.cancel(),
                readonly: !this.ocSecuritySvc.isAuthorised('Security.Settings.SiteDetails.SerialNumbers', AccessTypes.readWrite),
                visible: this.ocSecuritySvc.isAuthorised('Security.Settings.SiteDetails.SerialNumbers', AccessTypes.readOnly),
                groups: [
                    {
                        column: 1,
                        configurationTypes: [
                            { name: 'Client.TyreMaintenance.SiteSerialNumber.Vehicle' },
                            { name: 'Client.TyreMaintenance.SiteSerialNumber.Tyre' },
                            { name: 'Client.TyreMaintenance.SiteSerialNumber.Rim' },
                            { name: 'Client.TyreMaintenance.SiteSerialNumber.Chain' }
                        ]
                    }
                ]
            },

            forecasting: {
                save: () => this.saveConfigurations(),
                cancel: () => this.cancel(),
                readonly: !this.ocSecuritySvc.isAuthorised('Security.Settings.SiteDetails.Forecasting', AccessTypes.readWrite),
                visible: this.ocSecuritySvc.isAuthorised('Security.Settings.SiteDetails.Forecasting', AccessTypes.readOnly),
                groups: [
                    {
                        column: 1,
                        configurationTypes: [
                            { name: 'Client.General.PurchaseOrdersNameFormat' },
                            { name: 'Client.General.PurchaseOrdersEnforceForNewReceive' }
                        ]
                    }
                ]
            },

            reporting: {
                save: () => this.saveConfigurations(),
                cancel: () => this.cancel(),
                readonly: !this.ocSecuritySvc.isAuthorised('Security.Settings.SiteDetails.Reporting', AccessTypes.readWrite),
                visible: this.ocSecuritySvc.isAuthorised('Security.Settings.SiteDetails.Reporting', AccessTypes.readOnly),
                groups: [
                    {
                        header: 'reportingGroupEndOfMonth',
                        column: 1,
                        configurationTypes: [
                            { name: 'Client.Reporting.EndOfMonthDay' },
                            { name: 'Client.Reporting.EOM.RecordHaulage' },
                            { name: 'Client.Reporting.EOM.RecordRainfall' },
                            { name: 'Client.Reporting.EOM.RecordSnowfall' },
                            { name: 'Client.Reporting.EOM.RecordAverageTemperature' },
                            { name: 'Client.Reporting.EOM.RecordOperational' },
                            { name: 'Client.Reporting.EOM.RecordHSE' },
                            { name: 'Client.Reporting.EOM.RecordKPI' }
                        ]
                    },
                    {
                        header: 'reportingGroupGeneral',
                        column: 2,
                        configurationTypes: [
                            { name: 'Client.Reporting.EndOfYearDay' },
                            { name: 'Client.Reporting.EndOfYearMonth' },
                            { name: 'Client.Reporting.LifeTypeCalculation' },
                            { name: 'Client.Reporting.ExcludeSecondHandComponents' },
                            { name: 'Client.Reporting.ExcludeTransferredComponents' },
                            { name: ConfigurationType.treadWearThreshold_low, max: 100 },
                            {
                                name: ConfigurationType.treadWearThreshold_wornOut,
                                max: 100,
                                isValid: () => {
                                    // worn out threshold must be geater than the low tread threshold
                                    let valid = this.site[SiteDetailsTab.reporting][ConfigurationType.treadWearThreshold_wornOut].value
                                        > this.site[SiteDetailsTab.reporting][ConfigurationType.treadWearThreshold_low].value;

                                    if (!valid)
                                        this.notifySvc.error(this.amtXlatSvc.xlat('exception.configuration_wornOutThresholdtMustBeGreaterThanLowThreshold'));

                                    return valid;
                                }
                            }]
                    }
                ]
            },

            kpis: {
                readonly: !this.ocSecuritySvc.isAuthorised('Security.Settings.SiteDetails.KPIs', AccessTypes.readWrite),
                visible: this.ocSecuritySvc.isAuthorised('Security.Settings.SiteDetails.KPIs', AccessTypes.readOnly),
                refresh: () => this.refreshKpis(),
                actions: [{
                    id: 'viewKpi',
                    label: this.amtXlatSvc.xlat('siteDetails.viewKpi'),
                    onClick: () => this.addEditKpi(this.selectedItem)
                },
                {
                    id: 'addKpi',
                    label: this.amtXlatSvc.xlat('siteDetails.addKpi'),
                    onClick: () => this.addEditKpi()
                }],
                updateActions: () => {

                    let addKpi = this.tabs.kpis.actions.find(a => a.id === 'addKpi');
                    addKpi.show = !this.readonly;

                    let viewKpi = this.tabs.kpis.actions.find(a => a.id === 'viewKpi');
                    viewKpi.show = !!this.selectedItem;
                }
            },

            integration: {
                readonly: !this.ocSecuritySvc.isAuthorised('Security.Settings.SiteDetails.Details', AccessTypes.readWrite),
                visible: this.ocSecuritySvc.isAuthorised('Security.Settings.SiteDetails.Details', AccessTypes.readOnly),
                save: () => this.saveDetails(),
                cancel: () => this.cancel()
            }
        };

        this.processing = true;

        try {
            await this.loadReferenceData();
            await this.loadSiteDetails();
            this.selectTab(this.getDefaultTab(), false);
            this.status = ScreenStatus.ready;
        } catch (error) {
            this.errorReporter.logError(error);
            this.status = ScreenStatus.error;
        } finally {
            this.processing = false;
        }
    }

    async selectTab(tab, forceReload: boolean) {

        if (tab && (tab !== this.currentTab || forceReload)) {

            try {
                await this.confirmSvc.confirmSaveChange(this.form.$dirty);
            } catch (error) {
                this.activeTab = this.currentTab;
                return; // they cancelled
            }

            this.processing = true;

            this.selectedItem = null;

            if (this.currentTab && this.form.$dirty) {
                // reset the data for the current tab to the last saved data                  
                this.site[this.currentTab] = angular.copy(this.tabs[this.currentTab].data);
            }

            this.currentTab = null;

            this.$timeout(() => {
                // set readonly
                this.readonly = this.tabs[tab].readonly;

                // change tab
                this.currentTab = tab;
                this.activeTab = tab;

                if (this.site.details) {
                    this.useDefaultUrl = !this.site.details.siteUrl;

                    this.useDefaultLogo = !this.site.details.siteLogo.dataUrl;
                    this.useDefaultReportLogo = !this.site.details.siteReportLogo.dataUrl;

                    this.hasNewLogoFile = false;
                    this.hasNewReportLogoFile = false;
                }

                this.$timeout(() => {

                    if (this.tabs[tab].refresh)
                        this.tabs[tab].refresh();

                    // set the form back to pristine
                    this.form.$setPristine();
                    this.processing = false;
                });
            });
        }
    }

    cancel() {
        this.selectTab(this.currentTab, true);
    }

    async loadSiteDetails() {

        try {
            let result = await this.amtCommandQuerySvc.post(this.adminUrls.getSiteDetails);

            if (!result)
                return;

            if (result.details) {
                if (!result.details.siteLogo)
                    result.details.siteLogo = this.fileManagement.emptyFile();

                if (!result.details.siteReportLogo)
                    result.details.siteReportLogo = this.fileManagement.emptyFile();

                this.tabs.details.data = angular.copy(result.details);
            }

            if (result.localisation) {

                // units of measure
                let units = {};

                for (let uom of result.localisation.unitsOfMeasure || []) {

                    if (this.unitsOfMeasure[uom.baseReadingUnit]) {
                        let defaultUnit = this.unitsOfMeasure[uom.baseReadingUnit].find(u => u.id === u.defaultUnitOfMeasureId);

                        if (defaultUnit)
                            defaultUnit.description = String.format("{0} ({1})", defaultUnit.description, this.amtXlatSvc.xlat('siteDetails.default'));

                        units[uom.baseReadingUnit] = uom;
                    }
                }

                result.localisation.unitsOfMeasure = units;

                // currency
                if (result.localisation.currency.defaultCurrencyTypeId) {
                    for (let ct of this.currencyTypes) {
                        if (ct.id === result.localisation.currency.defaultCurrencyTypeId)
                            ct.description = String.format("{0} ({1})", ct.description, this.amtXlatSvc.xlat('siteDetails.default'));
                    }
                }

                this.tabs.localisation.data = angular.copy(result.localisation);
            }

            if (result.configurations) {

                let configurations = {};

                for (let c of result.configurations) {

                    if (this.configurationTypes[c.configurationType].lookupValues && this.configurationTypes[c.configurationType].lookupValues.length > 0) {

                        let defaultLookupValue = this.configurationTypes[c.configurationType].lookupValues.find(v => v.id == c.defaultValueId);

                        if (defaultLookupValue)
                            defaultLookupValue.name = String.format("{0} ({1})", defaultLookupValue.name, this.amtXlatSvc.xlat('siteDetails.default'));
                    }

                    if (this.configurationTypes[c.configurationType].dataType === 'integer') {
                        c.value = +c.value;
                        c.defaultValue = +c.defaultValue;
                    }

                    configurations[c.configurationType] = c;
                }

                result.components = {};
                result.activity = {};
                result.serialNumbers = {};
                result.forecasting = {};
                result.reporting = {};

                let tabConfigurations = {};

                for (let tab of Object.keys(this.tabs)) {
                    if ((this.tabs[tab].groups || []).length > 0) {
                        tabConfigurations[tab] = _.flatten(this.tabs[tab].groups.map(g =>
                            g.configurationTypes ? g.configurationTypes.map(ct => ct.name) : null));
                    }
                }

                for (let config of Object.keys(configurations)) {
                    for (let tab of Object.keys(this.tabs)) {
                        if ((tabConfigurations[tab] || []).length > 0 && tabConfigurations[tab].indexOf(config) > -1) {
                            result[tab][config] = configurations[config];
                        }
                    }
                }

                this.tabs.components.data = angular.copy(result.components);
                this.tabs.activity.data = angular.copy(result.activity);
                this.tabs.serialNumbers.data = angular.copy(result.serialNumbers);
                this.tabs.forecasting.data = angular.copy(result.forecasting);
                this.tabs.reporting.data = angular.copy(result.reporting);
            }

            this.site = angular.copy(result);

        } catch (error) {
            this.status = ScreenStatus.error;
            this.errorReporter.logError(error);
        }
    }

    loadReferenceData() {

        let referenceDataPromises: Promise<any>[] = [];

        referenceDataPromises.push(this.getUnitsOfMeasure());
        referenceDataPromises.push(this.getConfigurationTypes());
        referenceDataPromises.push(this.getCurrencyTypes());

        return Promise.all(referenceDataPromises);
    }

    async saveConfigurations() {

        let criteria = {
            configurations: []
        };

        for (let config of Object.keys(this.site[this.currentTab])) {

            let field: ISiteDetailsTabGroupConfigurationType;

            for (let group of this.tabs[this.currentTab].groups) {
                for (let configurationType of group.configurationTypes) {
                    if (configurationType.name === config) {
                        field = configurationType;
                        break;
                    }
                }
            }

            // if the field has a validator and it is found to be invalid, cancel the save
            if (field.isValid && !field.isValid())
                return;

            criteria.configurations.push({
                configurationTypeId: this.configurationTypes[config].id,
                value: this.site[this.currentTab][config].value
            })
        }

        this.processing = true;

        try {
            await this.amtCommandQuerySvc.post(this.adminUrls.saveSiteConfigurations, criteria);

            this.handleSaveSuccess();

        } catch (error) {
            this.errorReporter.logError(error);
        }
        finally {
            this.processing = false;
        }
    }

    async saveLocalisation() {

        let criteria = {
            currencyTypeId: this.helperSvc.getKey(this.site.localisation.currency),
            unitsOfMeasure: []
        };

        for (let uom of Object.keys(this.site.localisation.unitsOfMeasure)) {
            criteria.unitsOfMeasure.push({
                baseReadingUnit: this.site.localisation.unitsOfMeasure[uom].baseReadingUnit,
                unitOfMeasureId: this.helperSvc.getKey(this.site.localisation.unitsOfMeasure[uom])
            });
        }

        this.processing = true;

        try {
            await this.amtCommandQuerySvc.post(this.adminUrls.saveSiteLocalisation, criteria);

            this.handleSaveSuccess();

        } catch (error) {
            this.errorReporter.logError(error);
        } finally {
            this.processing = false;
        }
    }

    async saveDetails() {

        if (this.site.details.siteUrl && !this.isValidUrl(this.site.details.siteUrl)) {
            this.notifySvc.error(this.amtXlatSvc.xlat('siteDetails.invalidSiteUrl'));
            return;
        }

        this.processing = true;

        try {

            if (this.useDefaultUrl || !this.site.details.siteUrl)
                this.site.details.siteUrl = null;

            if (this.useDefaultLogo) {
                this.hasNewLogoFile = false;
            } else {
                if (this.hasNewLogoFile)
                    this.site.details.siteLogo.dataUrl = await this.fileManagement.blobToString(this.site.details.siteLogo.data);
            }

            if (this.useDefaultReportLogo) {
                this.hasNewReportLogoFile = false;
            } else {
                if (this.hasNewReportLogoFile)
                    this.site.details.siteReportLogo.dataUrl = await this.fileManagement.blobToString(this.site.details.siteReportLogo.data);
            }

            let criteria: ISaveSiteDetailsCommand = {
                otracoSite: this.site.details.otracoSite,
                operationTypes: this.site.details.operationTypeIds,
                commodities: this.site.details.commodityIds,
                physicalAddress: this.site.details.physicalAddress,
                postalAddress: this.site.details.postalAddress,
                contactId: this.helperSvc.getKey(this.site.details.contact),
                siteUrl: this.site.details.siteUrl,
                siteLogo: this.hasNewLogoFile ? this.site.details.siteLogo : null,
                siteReportLogo: this.hasNewReportLogoFile ? this.site.details.siteReportLogo : null,
                useDefaultSiteLogo: this.useDefaultLogo,
                useDefaultSiteReportLogo: this.useDefaultReportLogo,
                webLogoBackground: !this.useDefaultLogo && this.site.details.webLogoBackground ? this.site.details.webLogoBackground.value : null,
                isSyncTyrematics: this.site.details.isSyncTyrematics
            };

            await this.amtCommandQuerySvc.post(this.adminUrls.saveSiteDetails, criteria);

            //these are used to track whether the user has a new file to send, so we can skip sending a file the server already has
            this.hasNewLogoFile = false;
            this.hasNewReportLogoFile = false;

            this.$timeout(() => this.handleSaveSuccess());
        } catch (error) {
            this.errorReporter.logError(error);
        } finally {
            this.processing = false;
        }
    }

    async showHideIntegration() {
        try {
            if (this.site.details.isSyncTyrematics)
                await this.confirmSvc.confirmMessage(this.amtXlatSvc.xlat('component.confirmWizardAbort_Title'), 'dataIntegration.confirmFleetLocationSyncEnable', this.site.details.site);
            else
                await this.confirmSvc.confirmMessage(this.amtXlatSvc.xlat('component.confirmWizardAbort_Title'), 'dataIntegration.confirmFleetLocationSyncDisable', this.site.details.site);
        } catch {
            this.site.details.isSyncTyrematics = !this.site.details.isSyncTyrematics;
            return false; // they cancelled
        }
    }

    refreshKpis() {
        this.$timeout(() => {
            this.selectedItem = null;
            if (this.kpiGridControl && this.kpiGridControl.refresh) {
                this.kpiGridControl.refresh();
            }
        });
    }

    onSelectionChanged(items) {
        if (items != null && items.length === 1) {
            this.selectedItem = items[0];
        } else {
            this.selectedItem = null;
        }
    }

    async getUnitsOfMeasure() {

        try {
            let response = await this.amtCommandQuerySvc.post(this.referenceDataUrls.getUnitsOfMeasure, null);

            if (!response || !response.result)
                return;

            // exclude dimension
            this.unitsOfMeasure = _.groupBy(response.result.filter(r => r.baseReadingUnit !== 'Dimension'), unit => unit.baseReadingUnit);

            for (let unit of Object.keys(this.unitsOfMeasure)) {
                if (this.unitsOfMeasure[unit].filter(u => u.isActive).length > 1) {
                    this.localisationBaseUnits.push({
                        name: unit,
                        description: this.amtXlatSvc.xlat('siteDetails.' + unit.toLowerCase())
                    });

                    for (let uom of this.unitsOfMeasure[unit]) {
                        uom.description = String.format("{0} ({1})", uom.description, uom.unitAbbreviation);
                    }

                } else {
                    this.unitsOfMeasure[unit] = undefined;
                }
            }
        } catch (error) {
            this.status = ScreenStatus.error;
            this.errorReporter.logError(error);
        }
    }

    async getConfigurationTypes() {

        try {
            let response = await this.amtCommandQuerySvc.post(this.adminUrls.getConfigurationTypes, null);

            if (!response)
                return;

            let configurationTypes = {};

            for (let ct of response)
                configurationTypes[ct.name] = ct;

            this.configurationTypes = configurationTypes;

        } catch (error) {
            this.status = ScreenStatus.error;
            this.errorReporter.logError(error);
        }
    }

    async getCurrencyTypes() {

        try {
            let response = await this.dataBroker.getCurrencyTypes(null);

            if (!response || !response.result)
                return;

            this.currencyTypes = response.result;

            for (let c of this.currencyTypes)
                c.description = String.format("{0} ({1})", c.description, c.symbol);

        } catch (error) {
            this.status = ScreenStatus.error;
            this.errorReporter.logError(error);
        }
    }

    handleSaveSuccess() {
        this.notifySvc.success(this.amtXlatSvc.xlat('siteDetails.savedSuccessfully', this.amtXlatSvc.xlat('siteDetails.' + this.currentTab)));

        if (this.useDefaultLogo)
            this.site.details.siteLogo = this.fileManagement.emptyFile();

        if (this.useDefaultReportLogo)
            this.site.details.siteReportLogo = this.fileManagement.emptyFile();

        this.tabs[this.currentTab].data = angular.copy(this.site[this.currentTab]);

        this.form.$setPristine();
    }

    isValidUrl(url) {
        let valid = false;

        try {
            let validator = "^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$";
            valid = url.match(validator);
        } catch (error) { }

        return valid;
    }

    getDefaultTab() {

        for (let key in this.tabs) {
            if (this.tabs[key].visible) {
                return key;
            }
        }

        return null;
    }

    onSiteLogoSelected() {
        this.$timeout(() => {
            this.hasNewLogoFile = true;
            this.site.details.siteLogo.description = this.site.details.site + ' Web Logo';
            this.form.$setDirty();
        });
    }

    onSiteReportLogoSelected() {
        this.$timeout(() => {
            this.hasNewReportLogoFile = true;
            this.site.details.siteReportLogo.description = this.site.details.site + ' Report Logo';
            this.form.$setDirty();
        });
    }

    addEditKpi(item?) {
        this.WindowFactory.openItem({
            component: 'add-edit-kpi',
            caption: this.amtXlatSvc.xlat(item ? 'siteDetails.viewKpi' : 'siteDetails.addKpi'),
            initParams: item,
            canClose: false,
            width: 550,
            modal: true,
            onDataChangeHandler: () => this.refreshKpis()
        });
    }
}

class SitesDetailsComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '<'
    };
    public template = tmpl;
    public controller = SiteDetailsCtrl;
    public controllerAs = 'vm';
}

angular.module('app.admin').component('siteDetails', new SitesDetailsComponent());