//import angular from 'angular';


angular.module("app.site").controller("forecastNewVehicleScheduleController", forecastNewVehicleScheduleController);

    // @ts-ignore
    forecastNewVehicleScheduleController.$inject = ['$scope', '$rootScope', 'amtConstants', 'amtCommandQuerySvc', '$state',
        'amtXlatSvc', 'forecastingSvc', 'notifySvc', 'WindowFactory', 'errorReporter'];

    // ReSharper disable once InconsistentNaming
    function forecastNewVehicleScheduleController($scope, $rootScope, amtConstants,
        amtCommandQuerySvc, $state, xlatSvc, forecastingSvc, notifySvc, WindowFactory, errorReporter) {

        var vm = this;

        vm.apiUrls = amtConstants.url;
        vm.gridControl = {};

        vm.forecastId = $rootScope.$stateParams.forecastid ? $rootScope.$stateParams.forecastid : 0;
        vm.vehiclespecificationid = $rootScope.$stateParams.vehiclespecificationid ? $rootScope.$stateParams.vehiclespecificationid : 0;
        vm.vehicleSpecName = $rootScope.$stateParams.vehiclespecname ? $rootScope.$stateParams.vehiclespecname : '';
        vm.vehicleCount = $rootScope.$stateParams.vehiclecount ? ('(' + $rootScope.$stateParams.vehiclecount + ')') : '';
        vm.forecastStartDate = $rootScope.$stateParams.forecaststartdate ? $rootScope.$stateParams.forecaststartdate : '';
        vm.forecastEndDate = $rootScope.$stateParams.forecastenddate ? $rootScope.$stateParams.forecastenddate : '';

        vm.filterValues = {
            forecastId: vm.forecastId,
            vehicleSpecificationId: vm.vehiclespecificationid
        };

        this.$onInit = function () {
            if (!(vm.forecastId > 0)) {
                $state.go('app.site.forecastlist');
            }
        };

        vm.goToParentRoute = function () {
            forecastingSvc.setActiveTabName("Vehicles");
            $rootScope.goBack();
        };

        $scope.popupVehicleStartDate = function (dataItem) {

            WindowFactory.openItem({
                component: 'edit-vehicle-start-date',
                canMinimise: false,
                modal: true,
                canClose: false,
                footerOff: false,
                caption: xlatSvc.xlat('forecastVehicles.editVehicleStartDate'),
                initParams: {
                    forecastId: vm.forecastId,
                    vehicleId: dataItem.forecastVehicleId,
                    vehicleStartDate: dataItem.vehicleStartDate,
                    forecastStartDate: vm.forecastStartDate,
                    forecastEndDate: vm.forecastEndDate
                },
                onDataChangeHandler: function () {
                    if (vm.gridControl && vm.gridControl.refresh) {
                        vm.gridControl.refresh();
                    }
                }
            });
        };

        $scope.popupVehicleEndDate = function (dataItem) {

            WindowFactory.openItem({
                component: 'edit-vehicle-end-date',
                canMinimise: false,
                modal: true,
                canClose: false,
                footerOff: false,
                caption: xlatSvc.xlat('forecastVehicles.editVehicleEndDate'),
                initParams: {
                    forecastId: vm.forecastId,
                    vehicleId: dataItem.forecastVehicleId,
                    vehicleEndDate: dataItem.vehicleEndDate,
                    forecastStartDate: vm.forecastStartDate,
                    forecastEndDate: vm.forecastEndDate
                },
                onDataChangeHandler: function () {
                    if (vm.gridControl && vm.gridControl.refresh) {
                        vm.gridControl.refresh();
                    }
                }
            });
        };

        $scope.popupExcludeFromForecast = function (dataItem) {

            vm.processing = true;

            amtCommandQuerySvc.put(amtConstants.url.updateVehicleExcludeFromForecast, {
                forecastId: dataItem.forecastId,
                forecastVehicleId: dataItem.forecastVehicleId,
                excludeFromForecast: dataItem.excludeFromForecast === true ? false : true
            }).then(function (response) {
                notifySvc.success(xlatSvc.xlat("forecastVehicles.messageVehicleExcludeFromForecastUpdateSuccess"));
            }).catch(function (error) {
                errorReporter.logError(error);
            }).finally(function () {
                vm.processing = false;
            });
        };

        $scope.editVehicle = function (dataItem) {

            WindowFactory.openItem({
                component: 'forecast-add-new-vehicle',
                canMinimise: false,
                modal: true,
                canClose: false,
                footerOff: false,
                caption: xlatSvc.xlat('forecastNewVehicleSchedule.editNewVehicle'),
                width: 700,
                initParams: {
                    forecastId: dataItem.forecastId,
                    forecastVehicleId: dataItem.forecastVehicleId                    
                },
                onDataChangeHandler: function () {
                    if (vm.gridControl && vm.gridControl.refresh) {
                        vm.gridControl.refresh();
                    }
                }
            });
        };
    }
