//import angular from 'angular';
import DbClass from '../../common/local-db';
import OcDateSvc from '../../../services/ocDateSvc';



    angular.module('app.fieldSurveys')
        .service('statusChangeService', ['baseModuleService', 'ocDateSvc', 'helperSvc', 'dataBroker', 'ocConfigSvc', '$db', 'fileManagement',
            function (baseModuleService, ocDateSvc: OcDateSvc, helperSvc, dataBroker, ocConfigSvc, $db: DbClass, fileManagement) {

                let internalName: string = "statusChange";

                let vm = new baseModuleService(internalName);                

                // prepare the status changes for upload to the server
                vm.trimForUpload = function (records) {

                    vm.visualIdentifier = records[0].serialNumber;

                    return records.map(s => {

                        return {
                            id: s.id,
                            equipmentId: s.id,
                            componentType: s.componentType,
                            statusChanges: s.events.map(r => {
                                let event = {
                                    eventDate: ocDateSvc.dateTimeAsUTC(r.date),
                                    comment: r.comments,
                                    equipmentId: r.equipmentId,
                                    attachments: r.attachments,
                                    movementDetails: {
                                        newStatusTypeId: helperSvc.getKey(r.destinationStatusType),
                                        damageDetails: r.damageCause ? {
                                            damageCauseId: helperSvc.getKey(r.damageCause),
                                            damageLocationId: helperSvc.getKey(r.damageLocation),
                                            damageSourceId: helperSvc.getKey(r.damageSource),
                                            instant: r.instant,
                                            tyreExplosion: r.tyreExplosion,
                                            tyreBurst: r.tyreBurst
                                        } : null,

                                        transferDetails: r.destination ? {
                                            destinationSiteId: helperSvc.getKey(r.destination),
                                            destinationOther: r.destinationOther
                                        } : null,

                                        warrantyClaimDetails: r.mwClaim === true ? {
                                            date: ocDateSvc.dateTimeAsUTC(r.date),
                                            amount: 0,
                                            currencyTypeId: ocConfigSvc.user.site.currency.id
                                        } : null,

                                        returnToSupplierDetails: r.returnToSupplierReason ? {
                                            returnToSupplierReasonId: helperSvc.getKey(r.returnToSupplierReason),
                                            returnToSupplierReasonOther: r.returnToSupplierReasonOther,
                                            supplierId: helperSvc.getKey(r.supplier),
                                            supplierOther: r.supplierOther
                                        } : null,

                                        repairDetails: r.repairer ? {
                                            repairerId: helperSvc.getKey(r.repairer),
                                            repairerOther: r.repairerOther,
                                            repairTypeId: helperSvc.getKey(r.repairType)
                                        } : null,

                                        retreadDetails: r.retreader ? {
                                            retreaderId: helperSvc.getKey(r.retreader),
                                            retreaderOther: r.retreaderOther
                                        } : null,

                                        dispatchDetails: r.destinationStatusType.isDispatch ? {
                                            consignmentNumber: r.consignmentNumber
                                        } : null,
                                    },
                                    costDetails: r.creditAmount ? {
                                        amount: r.creditAmount.amount,
                                        currencyTypeId: helperSvc.getKey(r.creditAmount.currency)
                                    } : null
                                };

                                return event;
                            })
                        };
                    });
                };

                vm.getEndpoint = function (records) {
                    return 'equipment/component/uploadStatusChanges';
                };

                // get the actual number of status changes (as there can be more than 1 per record in the local db)
                vm.getRecordCount = async function (defaultCount) {
                    let statusChanges = await dataBroker.getStatusChanges();
                    return statusChanges.length || defaultCount;
                };

                vm.uploadFiles = async function (records) {

                    for (let record of records || []) {
                        for (let statusChange of record.statusChanges || []) {
                            for (let attachment of statusChange.attachments || []) {
                                let file = await fileManagement.uploadFile(attachment.id, false);
                                // update the file id
                                if (file) attachment.id = file.id;
                            }
                        }
                    }
                };

                // on successful upload of status change set the 'statusChanged' flat on the component (tyre/rim/chain) table
                // this is so we can prevent it from still displaying in the list of components that can change status
                vm.postProcess = async function (record) {

                    let componentRecord = await $db[record.componentType.toLowerCase()].where('id').equals(record.id).first();

                    if (componentRecord) {
                        componentRecord.statusChanged = true;

                        $db[record.componentType.toLowerCase()].put(componentRecord);
                    }
                };

                return vm;
            }]);
