/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var messages_1 = require("./messages");
/**
 * Custom component messages override default component messages ([see example]({% slug globalization_dateinputs %}#toc-custom-messages)).
 */
var TimePickerCustomMessagesComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TimePickerCustomMessagesComponent, _super);
    function TimePickerCustomMessagesComponent(service) {
        var _this = _super.call(this) || this;
        _this.service = service;
        return _this;
    }
    TimePickerCustomMessagesComponent_1 = TimePickerCustomMessagesComponent;
    Object.defineProperty(TimePickerCustomMessagesComponent.prototype, "override", {
        get: function () {
            return true;
        },
        enumerable: true,
        configurable: true
    });
    var TimePickerCustomMessagesComponent_1;
    TimePickerCustomMessagesComponent = TimePickerCustomMessagesComponent_1 = tslib_1.__decorate([
        core_1.Component({
            providers: [
                {
                    provide: messages_1.TimePickerMessages,
                    useExisting: core_1.forwardRef(function () { return TimePickerCustomMessagesComponent_1; }) // tslint:disable-line:no-forward-ref
                }
            ],
            selector: 'kendo-timepicker-messages',
            template: ""
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService])
    ], TimePickerCustomMessagesComponent);
    return TimePickerCustomMessagesComponent;
}(messages_1.TimePickerMessages));
exports.TimePickerCustomMessagesComponent = TimePickerCustomMessagesComponent;
