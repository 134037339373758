//import angular from 'angular';

angular.module('app').filter('yesNo', yesNoFilter);
	

    // @ts-ignore
    yesNoFilter.$inject = ['amtXlatSvc'];
	function yesNoFilter(xlatSvc) {
		return function (input) {
			return input ? xlatSvc.xlat('common.yes_label') : xlatSvc.xlat('common.no_label');
		};
	}
