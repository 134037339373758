/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var messages_1 = require("./messages");
/**
 * @hidden
 */
var DatePickerLocalizedMessagesDirective = /** @class */ (function (_super) {
    tslib_1.__extends(DatePickerLocalizedMessagesDirective, _super);
    function DatePickerLocalizedMessagesDirective(service) {
        var _this = _super.call(this) || this;
        _this.service = service;
        return _this;
    }
    DatePickerLocalizedMessagesDirective_1 = DatePickerLocalizedMessagesDirective;
    var DatePickerLocalizedMessagesDirective_1;
    DatePickerLocalizedMessagesDirective = DatePickerLocalizedMessagesDirective_1 = tslib_1.__decorate([
        core_1.Directive({
            providers: [
                {
                    provide: messages_1.DatePickerMessages,
                    useExisting: core_1.forwardRef(function () { return DatePickerLocalizedMessagesDirective_1; }) // tslint:disable-line:no-forward-ref
                }
            ],
            selector: '[kendoDatePickerLocalizedMessages]'
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService])
    ], DatePickerLocalizedMessagesDirective);
    return DatePickerLocalizedMessagesDirective;
}(messages_1.DatePickerMessages));
exports.DatePickerLocalizedMessagesDirective = DatePickerLocalizedMessagesDirective;
