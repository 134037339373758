/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var column_menu_service_1 = require("./column-menu.service");
/**
 * @hidden
 */
var ColumnMenuItemBase = /** @class */ (function () {
    function ColumnMenuItemBase() {
        this.hostClass = true;
    }
    ColumnMenuItemBase.prototype.ngOnInit = function () {
        if (core_1.isDevMode() && !this.service) {
            throw new Error('The service input of the predefined column menu components is mandatory.');
        }
    };
    /**
     * @hidden
     */
    ColumnMenuItemBase.prototype.close = function () {
        this.service.close();
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", column_menu_service_1.ColumnMenuService)
    ], ColumnMenuItemBase.prototype, "service", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-columnmenu-item-wrapper'),
        tslib_1.__metadata("design:type", Boolean)
    ], ColumnMenuItemBase.prototype, "hostClass", void 0);
    return ColumnMenuItemBase;
}());
exports.ColumnMenuItemBase = ColumnMenuItemBase;
