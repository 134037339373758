/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var treeview_component_1 = require("../treeview.component");
var utils_1 = require("../utils");
/**
 * A directive which enables the update of the initially provided data array during drag-and-drop.
 *
 * Either use this directive in combination with one of the data binding directives ([`kendoTreeViewHierarchyBinding`]({% slug api_treeview_hierarchybindingdirective %})
 * or [`kendoTreeViewFlatDataBinding`]({% slug api_treeview_flatdatabindingdirective %})) which set their own edit handlers, or provide
 * your own [`editService`]({% slug api_treeview_editservice %}) to this directive. The latter subscribes to and calls the
 * [`addItem`]({% slug api_treeview_treeviewcomponent %}#toc-additem) and [`removeItem`]({% slug api_treeview_treeviewcomponent %}#toc-removeitem)
 * handlers when the corresponding events are triggered by the TreeView component.
 */
var DragAndDropEditingDirective = /** @class */ (function () {
    function DragAndDropEditingDirective(treeview) {
        this.treeview = treeview;
        this.subscriptions = new rxjs_1.Subscription();
        this.subscriptions.add(this.treeview.addItem.subscribe(this.handleAdd.bind(this)));
        this.subscriptions.add(this.treeview.removeItem.subscribe(this.handleRemove.bind(this)));
    }
    Object.defineProperty(DragAndDropEditingDirective.prototype, "editService", {
        /**
         * Specifies the handlers called on drag-and-drop [`addItem`]({% slug api_treeview_treeviewcomponent %}#toc-additem)
         * and [`removeItem`]({% slug api_treeview_treeviewcomponent %}#toc-removeitem) events.
         */
        set: function (service) {
            this.treeview.editService = service;
        },
        enumerable: true,
        configurable: true
    });
    DragAndDropEditingDirective.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    DragAndDropEditingDirective.prototype.handleAdd = function (args) {
        if (!utils_1.isPresent(this.treeview.editService)) {
            throw new Error('No `editService` provided. Either provide your own implementation or use this directive in combination with one of the data binding directives (`kendoTreeViewHierarchyBinding` or `kendoTreeViewFlatDataBinding`).');
        }
        this.treeview.editService.add(args);
    };
    DragAndDropEditingDirective.prototype.handleRemove = function (args) {
        if (!utils_1.isPresent(this.treeview.editService)) {
            throw new Error('No `editService` provided. Either provide your own implementation or use this directive in combination with one of the data binding directives (`kendoTreeViewHierarchyBinding` or `kendoTreeViewFlatDataBinding`).');
        }
        this.treeview.editService.remove(args);
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object),
        tslib_1.__metadata("design:paramtypes", [Object])
    ], DragAndDropEditingDirective.prototype, "editService", null);
    DragAndDropEditingDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoTreeViewDragAndDropEditing]'
        }),
        tslib_1.__metadata("design:paramtypes", [treeview_component_1.TreeViewComponent])
    ], DragAndDropEditingDirective);
    return DragAndDropEditingDirective;
}());
exports.DragAndDropEditingDirective = DragAndDropEditingDirective;
