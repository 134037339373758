//import angular from 'angular';
import { PurchaseOrderLineItem } from './purchaseOrderDetailsCtrl';
import tmpl from './purchaseOrderSpecificationDetails.html';



class purchaseOrderSpecificationDetailsCtrl implements IWindowController, ng.IComponentController {
    public form: ng.IFormController;
    public wnd: IWindowObj;
    public buttons: IButton[];
    public buttonStates: { [key: string]: IButtonState; };
    public buttonMethods: { [key: string]: (button?: string) => void; };

    public initParams: PurchaseOrderLineItem;

    public processing = true;
    public specificationControl = {};
    public data: PurchaseOrderLineItem;
    public totalValueCurrencyType: any;
    public equipmentTypeSpecificationType: any;

    static $inject = ['WindowFactory', '$scope', 'confirmSvc', 'notifySvc', 'referenceDataUrls', 'componentUrls'];
    constructor(private WindowFactory: IWindowFactory, private $scope: ng.IScope, private confirmSvc: IConfirmSvc, private notifySvc: INotifySvc,
        private referenceDataUrls: IReferenceDataUrls, private componentUrls: IComponentUrls) {
    }

    $onInit() {
        this.data = angular.copy(this.initParams) || <PurchaseOrderLineItem>{};

        this.WindowFactory.addButton(this, this.initParams.id ? 'common.save_label' : 'common.add_label', 'saveButton', () => this.onSave(false), true, true, true);
        this.WindowFactory.addButton(this, 'maintenanceSession.saveAddAnother', 'saveAddAnother', () => this.onSave(true), false, !this.data.id, false);
        this.WindowFactory.addButton(this, 'common.cancel_label', 'cancel', () => this.onClose(), false, true, false);

        this.$scope.$watch(() => this.form.$invalid, newVal => {
            this.buttonStates.saveButton.disabled = newVal;
            this.buttonStates.saveAddAnother.disabled = newVal;
        }, false);

        this.$scope.$watch(() => this.equipmentTypeSpecificationType, newValue => {
            if (newValue) { this.data.description = newValue.description }
        });

        this.$scope.$watchGroup([() => this.form.$dirty, () => this.form.$invalid], () => {
            this.buttonStates.saveButton.disabled = (this.form.$invalid || this.wnd.processing) ? true : !(this.form.$dirty);
        });

        this.processing = false;
    }

    onSave(addAnother: boolean) {
        if (this.wnd.onDataChanged) {
            this.data.expectedTotalValueSymbol = this.totalValueCurrencyType.symbol;
            this.wnd.onDataChanged(this.data);
        }

        if (addAnother) {
            delete this.data.id;
            delete this.data.expectedDate;
            delete this.data.expectedCount;
            delete this.data.expectedTotalValue;
        } else {
            this.WindowFactory.closeWindow(this.wnd);
        }
    }

    async onClose() {
        try {
            await this.confirmSvc.confirmSaveChange(this.form && this.form.$dirty);
        } catch {
            return false;//they cancelled
        }
        this.WindowFactory.closeWindow(this.wnd);
        return true;
    }

    calculateTotal() {
        if (!this.equipmentTypeSpecificationType) {
            this.notifySvc.errorXlat("purchaseOrdersEdit.NoSpecification")
            return;
        }

        if (!this.equipmentTypeSpecificationType.unitCost) {
            this.notifySvc.errorXlat("purchaseOrdersEdit.NoSpecPrice", this.equipmentTypeSpecificationType.description);
            return;
        }

        this.data.totalValueCurrencyTypeId = this.equipmentTypeSpecificationType.unitCost.currencyTypeId;
        this.data.expectedTotalValue = (this.data.expectedCount ? this.equipmentTypeSpecificationType.unitCost.amount : 0) * (this.data.expectedCount || 0);
    }
}

angular.module('app.directives').component('purchaseOrderSpecificationDetails', {
    template: tmpl,
    bindings: {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    },
    controller: purchaseOrderSpecificationDetailsCtrl
});