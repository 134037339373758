/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_licensing_1 = require("@progress/kendo-licensing");
var util_1 = require("../common/util");
var package_metadata_1 = require("../package-metadata");
/**
 * Displays images, icons or initials representing people or other entities.
 */
var AvatarComponent = /** @class */ (function () {
    function AvatarComponent(renderer, element) {
        this.renderer = renderer;
        this.element = element;
        this.hostClass = true;
        /**
         * Sets a border to the avatar.
         */
        this.border = false;
        this._themeColor = 'primary';
        this._size = 'medium';
        this._fillMode = 'solid';
        this._rounded = 'full';
        kendo_licensing_1.validatePackage(package_metadata_1.packageMetadata);
    }
    Object.defineProperty(AvatarComponent.prototype, "borderClass", {
        /**
         * @hidden
         */
        get: function () {
            return this.border;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AvatarComponent.prototype, "flexBasis", {
        /**
         * @hidden
         */
        get: function () {
            return this.width;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AvatarComponent.prototype, "shape", {
        /**
         * Sets the shape for the avatar.
         * @hidden
         */
        set: function (shape) {
            this.rounded = util_1.mapShapeToRounded(shape);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AvatarComponent.prototype, "size", {
        get: function () {
            return this._size;
        },
        /**
         * Specifies the size of the avatar
         * ([see example]({% slug appearance_avatar %}#toc-size)).
         *
         * The possible values are:
         * * `small`
         * * `medium` (Default)
         * * `large`
         *
         */
        set: function (size) {
            if (size !== this._size) {
                this.handleClasses('size', size);
                this._size = size === null ? null : size || 'medium';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AvatarComponent.prototype, "rounded", {
        get: function () {
            return this._rounded;
        },
        /**
         * Specifies the rounded styling of the avatar
         * ([see example]({% slug appearance_avatar %}#toc-rounded-corners)).
         *
         * The possible values are:
         * * `small`
         * * `medium`
         * * `large`
         * * `full` (Default)
         * * null
         *
         */
        set: function (rounded) {
            if (rounded !== this._rounded) {
                this.handleClasses('rounded', rounded);
                this._rounded = rounded === null ? null : rounded || 'full';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AvatarComponent.prototype, "themeColor", {
        get: function () {
            return this._themeColor;
        },
        /**
         * Specifies the theme color of the avatar.
         * The theme color will be applied as background and border color, while also amending the text color accordingly.
         *
         * The possible values are:
         * * `base`&mdash; Applies the base coloring value.
         * * `primary` (Default)&mdash;Applies coloring based on primary theme color.
         * * `secondary`&mdash;Applies coloring based on secondary theme color.
         * * `tertiary`&mdash; Applies coloring based on tertiary theme color.
         * * `info`&mdash;Applies coloring based on info theme color.
         * * `success`&mdash; Applies coloring based on success theme color.
         * * `warning`&mdash; Applies coloring based on warning theme color.
         * * `error`&mdash; Applies coloring based on error theme color.
         * * `dark`&mdash; Applies coloring based on dark theme color.
         * * `light`&mdash; Applies coloring based on light theme color.
         * * `inverse`&mdash; Applies coloring based on inverted theme color.
         */
        set: function (themeColor) {
            if (themeColor !== this._themeColor) {
                this._themeColor = themeColor === null ? null : (themeColor || 'primary');
                this.handleFillModeAndThemeColorClasses(this.fillMode, this.themeColor);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AvatarComponent.prototype, "fillMode", {
        get: function () {
            return this._fillMode;
        },
        /**
         * Specifies the appearance fill style of the avatar.
         *
         * The possible values are:
         * * `solid` (Default)
         * * `outline`
         * * null
         *
         */
        set: function (fillMode) {
            if (fillMode !== this.fillMode) {
                this._fillMode = fillMode === null ? null : (fillMode || 'solid');
                this.handleFillModeAndThemeColorClasses(this.fillMode, this.themeColor);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AvatarComponent.prototype, "fill", {
        /**
         * Specifies the appearance fill style of the avatar.
         * Deprecated, left for backward compatibility.
         *
         * @hidden
         */
        set: function (fillMode) {
            this.fillMode = fillMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AvatarComponent.prototype, "avatarWidth", {
        /**
         * @hidden
         */
        get: function () {
            return this.width;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AvatarComponent.prototype, "avatarHeight", {
        /**
         * @hidden
         */
        get: function () {
            return this.height;
        },
        enumerable: true,
        configurable: true
    });
    AvatarComponent.prototype.ngOnInit = function () {
        this.verifyProperties();
    };
    AvatarComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        var stylingInputs = ['size', 'rounded'];
        stylingInputs.forEach(function (input) {
            _this.handleClasses(input, _this[input]);
        });
        this.handleFillModeAndThemeColorClasses(this.fillMode, this.themeColor);
    };
    /**
     * @hidden
     */
    AvatarComponent.prototype.iconClasses = function () {
        if (this.icon) {
            return "k-icon k-i-" + this.icon;
        }
        if (this.iconClass) {
            return "" + this.iconClass;
        }
    };
    Object.defineProperty(AvatarComponent.prototype, "customAvatar", {
        /**
         * @hidden
         */
        get: function () {
            return !(this.imageSrc || this.initials || this.icon || this.iconClass);
        },
        enumerable: true,
        configurable: true
    });
    AvatarComponent.prototype.verifyProperties = function () {
        if (!core_1.isDevMode()) {
            return;
        }
        var inputs = [this.icon || this.iconClass, this.imageSrc, this.initials];
        var inputsLength = inputs.filter(function (value) { return value; }).length;
        if (inputsLength > 1) {
            throw new Error("\n                Invalid property configuration given.\n                The kendo-avatar component can accept only one of:\n                icon, imageSrc or initials properties.\n            ");
        }
    };
    AvatarComponent.prototype.handleClasses = function (styleType, value) {
        var elem = this.element.nativeElement;
        var classes = util_1.getStylingClasses('avatar', styleType, this[styleType], value);
        if (classes.toRemove) {
            this.renderer.removeClass(elem, classes.toRemove);
        }
        if (classes.toAdd) {
            this.renderer.addClass(elem, classes.toAdd);
        }
    };
    AvatarComponent.prototype.handleFillModeAndThemeColorClasses = function (fill, themeColor) {
        var _this = this;
        var wrapperElement = this.element.nativeElement;
        // remove existing fill and theme color classes
        var currentClasses = Array.from(wrapperElement.classList);
        var classesToRemove = currentClasses.filter(function (cl) {
            return cl.startsWith('k-avatar-solid') || cl.startsWith('k-avatar-outline');
        });
        classesToRemove.forEach((function (cl) { return _this.renderer.removeClass(wrapperElement, cl); }));
        // add fill if needed
        if (fill !== null) {
            this.renderer.addClass(wrapperElement, "k-avatar-" + fill);
        }
        // add theme color class if fill and theme color
        if (fill !== null && themeColor !== null) {
            this.renderer.addClass(wrapperElement, "k-avatar-" + fill + "-" + themeColor);
        }
    };
    tslib_1.__decorate([
        core_1.HostBinding('class.k-avatar'),
        tslib_1.__metadata("design:type", Boolean)
    ], AvatarComponent.prototype, "hostClass", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-avatar-bordered'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], AvatarComponent.prototype, "borderClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('style.flexBasis'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], AvatarComponent.prototype, "flexBasis", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], AvatarComponent.prototype, "shape", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], AvatarComponent.prototype, "size", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], AvatarComponent.prototype, "rounded", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], AvatarComponent.prototype, "themeColor", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], AvatarComponent.prototype, "fillMode", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], AvatarComponent.prototype, "fill", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], AvatarComponent.prototype, "border", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], AvatarComponent.prototype, "iconClass", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], AvatarComponent.prototype, "width", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('style.width'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], AvatarComponent.prototype, "avatarWidth", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], AvatarComponent.prototype, "height", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('style.height'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], AvatarComponent.prototype, "avatarHeight", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], AvatarComponent.prototype, "cssStyle", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], AvatarComponent.prototype, "initials", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], AvatarComponent.prototype, "icon", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], AvatarComponent.prototype, "imageSrc", void 0);
    AvatarComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-avatar',
            template: "\n        <ng-content *ngIf=\"customAvatar\"></ng-content>\n\n        <ng-container *ngIf=\"imageSrc\">\n            <span class=\"k-avatar-image\">\n                <img src=\"{{imageSrc}}\" [ngStyle]=\"cssStyle\" />\n            </span>\n        </ng-container>\n\n        <ng-container *ngIf=\"initials\">\n            <span class=\"k-avatar-text\" [ngStyle]=\"cssStyle\">{{ initials.substring(0, 2) }}</span>\n        </ng-container>\n\n        <ng-container *ngIf=\"icon || iconClass\">\n            <span class=\"k-avatar-icon\" [ngStyle]=\"cssStyle\" [ngClass]=\"iconClasses()\"></span>\n        </ng-container>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.Renderer2, core_1.ElementRef])
    ], AvatarComponent);
    return AvatarComponent;
}());
exports.AvatarComponent = AvatarComponent;
