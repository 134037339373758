//import angular from 'angular';

    angular.module("app.directives")
        .directive("ocPropertyRow", ["amtXlatSvc", function (amtXlatSvc) {
            return {
                restrict: "E",              
                transclude: true,
                scope: {
                    ngRequired: "=?",
                    label: "=?",
                    labelResource: "@",
                    isStatic: "@"
                },
                template: function (elm, attrs) {
                    let labelWidth = Number.parseInt(attrs.labelWidth);
                    let valueWidth = Number.parseInt(attrs.valueWidth);

                    if (labelWidth < 1 || valueWidth < 1 || valueWidth + labelWidth > 12 /* Bootstrap columns */) {
                        labelWidth = 5;
                        valueWidth = 7;
                    }

                    let amtDisplayTemplate = `
                        <amt-display ng-required="ngRequired" label="{{label || labelResource}}" label-width="${labelWidth}" value-width="${valueWidth}" css-class="{{isStatic ? 'form-control-static' : ''}}">
                            <ng-transclude/>
                        </amt-display>\n`;
                    return `<amt-row layout="${attrs.layout || 'horizontal'}">${attrs.label || attrs.labelResource ? amtDisplayTemplate : '<ng-transclude/>'}</amt-row>`;
                },
                link: function (scope: ng.IScope | any, element, attrs) {
                    attrs.$observe('labelResource', res => {
                        scope.labelResource = amtXlatSvc.xlat(res); // changed from the following but xlat now returns it's key if no translation found: amtXlatSvc.xlatTest(res) ? amtXlatSvc.xlat(res) : res;
                    });

                    attrs.$observe('isStatic', function (res) {
                        scope.isStatic = (res === "true");
                    });
                }
            };
        }]);
