//import angular from 'angular';
import * as _ from 'underscore';
import OcDateSvc from '../../../../services/ocDateSvc';
import tmpl from './EditFitment.html';



type TEditFitmentOption = {
    id: EditFitmentOption;
    name: string;
    description: string;
    disabled: boolean;
    caption?: string;
    subtext?: string;
}

const enum EditFitmentOption {
    edit = 'edit',
    changePosition = 'change-position',
    changeComponent = 'change-component'
}

class EditFitmentCtrl implements ng.IController, IWindowController {

    form: ng.IFormController;
    wnd: IWindowObj;

    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: () => void };

    initParams: any;

    options: TEditFitmentOption[] = [];
    selectedOption: string = null;

    availablePositions = [];
    selectedPosition: any = null;
    selectedComponent: any = null;

    eventData: any;
    events: any[];
    vehicle: any;

    validFitments: any[];
    serials: any[];

    subtext = this.amtXlatSvc.xlat('maintenanceSession.editFitment_desc');

    static $inject = ['$scope', 'dataBroker', 'errorReporter', 'WindowFactory', 'amtXlatSvc', 'ocDateSvc', 'notifySvc'];

    constructor(private $scope: ng.IScope, private dataBroker: IDataBroker, private errorReporter: IErrorReporter, private WindowFactory: IWindowFactory,
        private amtXlatSvc: IAmtXlatSvc, private ocDateSvc: OcDateSvc, private notifySvc: INotifySvc) {

        this.$scope.$watchGroup([() => this.wnd.processing, () => this.selectedComponent, () => this.selectedPosition], () => {
            this.buttonStates.changePositionButton.disabled = !this.selectedPosition || this.wnd.processing;
            this.buttonStates.changeComponentButton.disabled = !this.selectedComponent || this.wnd.processing;
            this.buttonStates.backButton.disabled = this.wnd.processing;
        });
    }

    async $onInit() {

        this.wnd.processing = true;

        try {
            if (this.initParams) {
                this.eventData = this.initParams.data;
                this.events = this.initParams.events;
                this.vehicle = this.initParams.vehicle;
            }

            // buttons
            this.WindowFactory.addButton(this, 'maintenanceSession.changePosition', 'changePositionButton', () => this.changePosition(), true, false, true);
            this.WindowFactory.addButton(this, 'maintenanceSession.change', 'changeComponentButton', () => this.changeComponent(), true, false, true);
            this.WindowFactory.addButton(this, 'common.back', 'backButton', () => this.onBack(), false, false);
            this.WindowFactory.addButton(this, 'common.cancel_label', 'cancelButton', () => this.closeWindow());

            // setup 'edit' option
            let editOption: TEditFitmentOption = {
                id: EditFitmentOption.edit,
                name: this.amtXlatSvc.xlat('maintenanceSession.editFitment_edit_title'),
                description: this.amtXlatSvc.xlat('maintenanceSession.editFitment_edit_desc'),
                disabled: false
            };

            // setup 'change position' option
            let changePositionOption: TEditFitmentOption = {
                id: EditFitmentOption.changePosition,
                name: this.amtXlatSvc.xlat('maintenanceSession.editFitment_changePos_title', this.eventData.data.typeTranslated.toLowerCase()),
                description: this.amtXlatSvc.xlat('maintenanceSession.editFitment_changePos_desc', this.eventData.data.typeTranslated.toLowerCase()),
                disabled: true,
                caption: this.amtXlatSvc.xlat('maintenanceSession.editFitment_changePosition'),
                subtext: this.amtXlatSvc.xlat('maintenanceSession.editFitment_changePos_text',
                    this.eventData.data.typeTranslated,
                    this.eventData.data.serialNumber,
                    this.eventData.position.label,
                    this.vehicle.serialNumber,
                    this.eventData.data.typeTranslated.toLowerCase()
                )
            };

            // setup 'change component' option
            let changeComponentOption: TEditFitmentOption = {
                id: EditFitmentOption.changeComponent,
                name: this.amtXlatSvc.xlat('maintenanceSession.editFitment_changeComponent_title', this.eventData.data.typeTranslated.toLowerCase()),
                description: this.amtXlatSvc.xlat('maintenanceSession.editFitment_changeComponent_desc', this.eventData.data.typeTranslated.toLowerCase()),
                disabled: true,
                caption: this.amtXlatSvc.xlat('maintenanceSession.editFitment_changeComponent', this.eventData.data.typeTranslated)
            };

            // add the options
            this.options.push(editOption);
            this.options.push(changePositionOption);
            this.options.push(changeComponentOption);

            let equipmentId = this.eventData.data.components[0].equipmentId || this.eventData.data.components[0].componentId;

            await this.getValidFitments(equipmentId);

        } finally {
            this.wnd.processing = false;
        }
    }

    async getValidFitments(equipmentId: guid) {

        // get valid fitments for the position
        let validFitments = await this.dataBroker.getValidFitments(
            this.eventData.type,
            this.eventData.position.id,
            [StatusTypeName.spare, StatusTypeName.new, StatusTypeName.fitted], // include fitted because we need to validate on position swap
            this.eventData.schematic,
            this.ocDateSvc.removeLocalTimeZoneOffset(this.eventData.sequenceDate)
        );

        if (validFitments) {

            // store the raw result for validation
            this.validFitments = validFitments;

            // filter the serials that are available to be fitted to the position (new, spare)
            this.serials = this.validFitments.filter(d =>
                d.equipmentId !== equipmentId
                && !this.events.some(e =>
                    d.equipmentId === (e.eventData.components[0].equipmentId || e.eventData.components[0].componentId)
                    && e.sequenceDate >= this.eventData.sequenceDate
                )
                && (d.statusType.toLowerCase() === StatusTypeName.spare || d.statusType.toLowerCase() === StatusTypeName.new)
            ).map(d => {
                return {
                    serialNumber: d.serialNumber.formatted,
                    id: d.equipmentId
                };
            });

            // enable change component option if there are other valid fitments for the position
            this.options.find(o => o.id === EditFitmentOption.changeComponent).disabled = !(this.serials && this.serials.length);

            // find any other fitments of same component type in the session that are still currently fitted (available positions)
            await this.getValidPositionsForSwap(equipmentId);

            // enable change position option if there has been another fitment of the same component type in the session
            this.options.find(o => o.id === EditFitmentOption.changePosition).disabled = !this.availablePositions || this.availablePositions.length === 0;
        }
    }

    // an option on the first screen has been selected
    selectOption(option?: TEditFitmentOption) {

        // handle if we are setting the option back to nothing (first screen)
        this.selectedOption = option ? option.id : null;

        // set the window caption and subtext based on the option
        this.wnd.caption = option ? option.caption : this.amtXlatSvc.xlat('maintenanceSession.editFitment');
        this.subtext = option ? option.subtext : this.amtXlatSvc.xlat('maintenanceSession.editFitment_desc');

        // clear data and adjust buttons
        switch (this.selectedOption) {
            case EditFitmentOption.edit:
                // closes current window and opens the fitment details window
                if (this.wnd.onDataChanged) {
                    this.wnd.onDataChanged({
                        editDetail: true
                    });
                    this.closeWindow();
                }
                break;

            case EditFitmentOption.changePosition:
                this.buttons.find(b => b.name === 'cancelButton').primary = false;
                this.buttonStates.backButton.visible = true;
                this.buttonStates.changePositionButton.visible = true;
                this.buttonStates.changeComponentButton.visible = false;
                break;

            case EditFitmentOption.changeComponent:
                this.buttons.find(b => b.name === 'cancelButton').primary = false;
                this.buttonStates.backButton.visible = true;
                this.buttonStates.changePositionButton.visible = false;
                this.buttonStates.changeComponentButton.visible = true;
                break;

            default: // first option screen
                this.selectedPosition = null;
                this.selectedComponent = null;

                this.buttons.find(b => b.name === 'cancelButton').primary = true;
                this.buttonStates.backButton.visible = false;
                this.buttonStates.changePositionButton.visible = false;
                this.buttonStates.changeComponentButton.visible = false;
                break;
        }
    }

    // change the position the component was fitted to (swap)
    async changePosition() {

        this.wnd.processing = true;

        try {
            await this.dataBroker.msSwapPositionFitments(this.eventData.data.events[0].equipmentEventId, this.selectedPosition.equipmentEventId);

            this.notifySvc.success(this.amtXlatSvc.xlat('maintenanceSession.editFitment_swapSuccess'));

            if (this.wnd.onDataChanged) {
                try {
                    await this.wnd.onDataChanged({ position: this.selectedPosition });
                } catch (error) {
                    this.errorReporter.logError(error, 'EditFitment-ReloadSchematicAfterPositionChange');
                }
            }

        } catch (error) {
            this.errorReporter.logError(error, 'EditFitment-ChangePosition');
        } finally {
            this.wnd.processing = false;
        }
    }

    // change the component that was fitted
    async changeComponent() {

        this.wnd.processing = true;

        try {
            await this.dataBroker.msSwapComponentHistory(this.eventData.data.events[0].equipmentEventId, this.selectedComponent.id);

            this.notifySvc.success(this.amtXlatSvc.xlat('maintenanceSession.editFitment_changeSuccess'));

            if (this.wnd.onDataChanged) {
                try {
                    await this.wnd.onDataChanged({ component: this.selectedComponent });
                } catch (error) {
                    this.errorReporter.logError(error, 'EditFitment-ReloadSchematicAfterComponentChange');
                }
            }

        } catch (error) {
            this.errorReporter.logError(error, 'EditFitment-ChangeComponent');
        } finally {
            this.wnd.processing = false;
        }
    }

    // find positions with a fitment of the same component type that is still fitted
    async getValidPositionsForSwap(equipmentId: guid) {

        let positionPromises: Promise<any>[] = [];

        // find positions that have a fitment of the same component type as target position and haven't been removed later in the session
        let fitments = this.events.filter(e => {
            return e.eventData.event === MaintenanceSession_EventType.fit &&
                this.eventData.type.toLowerCase() === e.eventData.type.toLowerCase() &&
                equipmentId !== (e.eventData.components[0].equipmentId || e.eventData.components[0].componentId) &&
                !this.events.some(i =>
                    i.eventData
                    && i.sequenceDate > e.sequenceDate
                    && _.intersection(
                        i.eventData.components.map(c => c.equipmentId || c.componentId || c.id),
                        e.eventData.components.map(c => c.equipmentId || c.componentId || c.id)
                    ).length > 0
                );
        });

        if (fitments && fitments.length) {

            // limit available positions to those valid for the component      
            fitments = fitments.filter(f =>
                this.validFitments.some(v => v.equipmentId === (f.eventData.components[0].equipmentId || f.eventData.components[0].componentId))
            );

            // limit available positions to those where the current fitment is a valid fitment for target position        
            for (let fitment of fitments || []) {

                positionPromises.push(new Promise<void>(async (resolve, reject) => {
                    let valid = await this.dataBroker.checkValidFitmentForPosition(fitment.position.id, equipmentId);
                    if (valid) {
                        this.availablePositions.push({
                            positionId: fitment.position.id,
                            positionLabel: fitment.position.label,
                            equipmentId: fitment.eventData.components[0].equipmentId || fitment.eventData.components[0].componentId,
                            equipmentEventId: fitment.eventData.events[0].equipmentEventId
                        });
                    }
                    resolve();
                }));
            }
        }

        await Promise.all(positionPromises);
    }

    // go back to first screen
    onBack() {
        this.selectOption(null);
    }

    // close the window
    closeWindow() {
        this.WindowFactory.closeWindow(this.wnd);
    }
}

class EditFitmentComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = EditFitmentCtrl;
    public controllerAs = 'vm';
}

angular.module('app.directives').component('editFitment', new EditFitmentComponent());