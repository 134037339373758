//import angular from 'angular';
import tmplComponentSearch from "./componentSearch.html";
import tmplComponentReceiveErrored from "./componentReceiveErrored.html";
import tmplMwWarrantyClaimSearch from "./mwWarranty/search.html";
import tmplMwWarrantyClaimDetail from "./mwWarranty/details.html";
//import tmplComponentPopupStatus from "./popupStatus.html";
import tmplComponentPopupDetails from "./componentDetails.html";
//import tmplVisualInspectionPopup from "../popups/visualInspectionPopup.html";
import tmplAttachmentPopup from "../popups/attachmentPopup.html";
import tmplCommentPopup from "../popups/commentPopup.html";


const moduleName = 'app.component';
export default moduleName;

angular.module(moduleName, [])
        .config([
            "$stateProvider",
            function ($stateProvider: ng.ui.IStateProvider) {
                $stateProvider
                    .state("app.component.search", {
                        url: "/search",
                        template: tmplComponentSearch,
                        controller: "componentSearchCtrl",
                        controllerAs: "vm"
                    })
                    .state("app.component.receive", {
                        url: "/receive",
                        template: tmplComponentReceiveErrored,
                        params: {
                            initParams: null,
                            fromStocktake: false,
                            received: false
                        },
                        controller: "componentReceiveErroredCtrl",
                        controllerAs: "vm"
                    })
                    .state("app.component.mwWarranty", {
                        url: "/mwwarranty",
                        template: tmplMwWarrantyClaimSearch,
                        controller: "mwWarrantyClaimSearchCtrl",
                        controllerAs: "vm"
                    })
                    .state("app.component.mwWarranty.popupWarrantyClaim", {
                        url: "/details",
                        template: tmplMwWarrantyClaimDetail,
                        params: {
                            claimId: ""
                        },
                        controller: "mwWarrantyClaimDetailCtrl",
                        controllerAs: "vm"
                    })
                    /*.state("app.component.search.popupstatus", {
                        url: "/popupstatus",
                        template: tmplComponentPopupStatus,
                        params: {
                            equipmentId: "",
                            statusId: "",
                            isEdit: "",
                            siteId: "",
                            clientId: ""
                        },
                        controller: "popupStatusCtrl",
                        controllerAs: "vm"
                    })*/
                    .state("app.component.details", {
                        url: "/details/{id}",
                        template: tmplComponentPopupDetails,
                        controller: "componentDetailsCtrl",
                        controllerAs: "vm"
                    })
                    /*.state("app.component.details.popupVisualInspection", {
                        url: "/visualInspection",
                        template: tmplVisualInspectionPopup,
                        params: {
                            mode: "",
                            componentid: "",
                            visualinspectionid: ""
                        },
                        controller: "visualInspectionPopupCtrl",
                        controllerAs: "vm"
                    })*/
                    .state("app.component.details.popupAttachment", {
                        url: "/attachment",
                        template: tmplAttachmentPopup,
                        params: {
                            mode: "",
                            componentid: "",
                            attachmentid: "",
                            source: ""
                        },
                        controller: "attachmentPopupCtrl",
                        controllerAs: "vm"
                    })
                    .state("app.component.details.popupComment", {
                        url: "/comment",
                        template: tmplCommentPopup,
                        params: {
                            mode: "",
                            componentid: "",
                            commentid: ""
                        },
                        controller: "commentPopupCtrl",
                        controllerAs: "vm"
                    })
                    .state("app.component.bulkMove", {
                        url: "/bulkmove",
                        component: "bulkMove"
                    })
                    .state('app.component.stocktake', {
                        url: '/stocktake',
                        component: 'stocktakeSearch'
                    });
            }
        ]);
