//import angular from 'angular';
import tmpl from './ocVehicleFitments.html';



    angular.module('app.directives').directive("ocVehicleFitments", ['exportSvc', 'WindowFactory', '$timeout', function (exportSvc, WindowFactory) {
        return {
            scope: {
                tabObject: "=",
                equipmentObject: "=",
                openModule: "=",
                siteId: "=",
                notification: "=",
                parentWindow: "=",
                showPositionLabels: "=?",
                defaultSearchRangeInDays: "=?"
            },
            template: tmpl,
            controllerAs: 'vm',
            controller: ["$scope", "vehicleUrls", "amtCommandQuerySvc", "confirmSvc", "$q", "amtXlatSvc", "exportSvc", "WindowFactory", "ocConfigSvc", "$filter", 'ocSecuritySvc', '$timeout',
                function ocVehicleFitments($scope, vehicleUrls, amtCommandQuerySvc, confirmSvc, $q, amtXlatSvc, exportSvc, WindowFactory, ocConfigSvc, $filter, ocSecuritySvc, $timeout
                ) {
                    var vm = this;

                    // security
                    vm.canOpenFittedComponents = ocSecuritySvc.isAuthorised('Security.Components', 'readOnly');

                    vm.loadingSchematic = true;
                    vm.apiUrls = vehicleUrls;
                    vm.config = ocConfigSvc;

                    vm.positionsWide = 5;
                    vm.positionsHigh = 2;

                    vm.topAxle = 1;
                    vm.leftPos = 1;

                    $scope.selectedItem = {};

                    vm.baseGridHeight = 400;
                    vm.gridHeight = vm.baseGridHeight;

                    vm.scaleSchematic = false;
                    vm.schematicMaxHeight = 230;
                    vm.schematicMaxWidth = 760;

                    vm.schematicHeight = 0;
                    vm.schematicWidth = 0;

                    vm.selectedPosition = undefined;

                    vm.schematicHasScrollbar = false;

                    vm.fitmentsGridTyres = {};
                    vm.fitmentsGridRims = {};
                    vm.fitmentsGridChains = {};

                    $scope.$watch('vm.topAxle', function (newValue, oldValue) {
                        // when the top axle on the schematic changes, scroll the grid to show the first position at the top

                        if ($scope.equipmentObject && $scope.equipmentObject.schematic) {
                            // get the axle (topAxle is 1 based, so need to subtract one
                            var axle = $scope.equipmentObject.schematic.axles[vm.topAxle - 1];

                            // get the id of the first position
                            var positionId = axle.positions[0].id;

                            // scroll the grids
                            vm.fitmentsGridTyres.scrollToRowById(positionId);
                            vm.fitmentsGridRims.scrollToRowById(positionId);
                            vm.fitmentsGridChains.scrollToRowById(positionId);
                        }
                    });

                    // keep a running total of the changes
                    vm.onSelectedItemChanged = function (item) {
                        if (vm.selectedComponent === undefined || item[0].equipmentId !== vm.selectedComponent.equipmentId) {
                            for (var a = 0; a < $scope.equipmentObject.schematic.axles.length; a++) {
                                var axle = $scope.equipmentObject.schematic.axles[a];

                                for (var p = 0; p < axle.positions.length; p++) {
                                    var position = axle.positions[p];

                                    if (position.id === item[0].id) {
                                        vm.topAxle = Math.floor(a / 2) * 2 + 1;

                                        for (var f = 0; f < position.fitments.length; f++) {

                                            if (position.fitments[f].equipmentId === item[0].equipmentId) {

                                                if (vm.selectedPosition) {
                                                    vm.selectedPosition = undefined;
                                                }
                                                if (vm.selectedComponent) {
                                                    vm.selectedComponent = undefined;
                                                }
                                                vm.selectedComponent = position.fitments[f];
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    };

                    vm.searchCriteria = {
                        filterValues: {
                            equipmentId: null
                        }
                    };

                    vm.openSchematic = function () {
                        vm.schematicFullScreen = true;

                        WindowFactory.openItem({
                            component: 'full-screen-schematic',
                            caption: amtXlatSvc.xlat('equipment.schematicCaption', $scope.equipmentObject.serialNumber.formatted),
                            initParams: {
                                equipmentObject: $scope.equipmentObject,
                                positionView: 'fitments',
                                zoomRect: {
                                    leftPos: vm.leftPos,
                                    topAxle: vm.topAxle,
                                    positionsHigh: vm.positionsHigh,
                                    positionsWide: vm.positionsWide
                                }
                            },
                            left: $scope.parentWindow.position.left - (vm.schematicWidth + 60),
                            top: $scope.parentWindow.position.top,
                            footerOff: true,
                            fullScreenOff: true,
                            parentWindow: $scope.parentWindow,
                            width: vm.schematicWidth,
                            height: vm.schematicHeight,
                            afterWindowClosed: function (wnd) {
                                vm.schematicFullScreen = false;
                                vm.gridHeight = vm.baseGridHeight;

                                vm.leftPos = wnd.initParams.zoomRect.leftPos;
                                vm.topAxle = wnd.initParams.zoomRect.topAxle;

                                $timeout(function () {
                                    vm.fitmentsGridTyres.resize();
                                    vm.fitmentsGridRims.resize();
                                    vm.fitmentsGridChains.resize();
                                });
                            }
                        });
                        vm.gridHeight = vm.baseGridHeight + vm.schematicMaxHeight;

                        $timeout(function () {
                            vm.fitmentsGridTyres.resize();
                            vm.fitmentsGridRims.resize();
                            vm.fitmentsGridChains.resize();
                        });
                    };

                    $scope.$watch('vm.selectedComponent', function (newValue, oldValue) {

                        if (oldValue) {
                            oldValue.selected = false;
                        }

                        if (newValue) {                          

                            if (newValue.type === "Tyre") {
                                if (vm.currentTab !== vm.tabs.tyres) {
                                    $scope.selectTab(vm.tabs.tyres, newValue.positionId);
                                } else {
                                    vm.fitmentsGridTyres.selectRowById(newValue.positionId);
                                }
                            } else if (newValue.type === "Rim") {
                                if (vm.currentTab !== vm.tabs.rims) {
                                    $scope.selectTab(vm.tabs.rims, newValue.positionId);
                                } else {
                                    vm.fitmentsGridRims.selectRowById(newValue.positionId);
                                }
                            } else if (newValue.type === "Chain") {
                                if (vm.currentTab !== vm.tabs.chains) {
                                    $scope.selectTab(vm.tabs.chains, newValue.positionId);                                    
                                } else {
                                    vm.fitmentsGridChains.selectRowById(newValue.positionId);
                                }
                            }
                        }
                    });

                    $scope.onItemSelected = function (item) {
                        $scope.selectedItem = item;
                    };

                    $scope.refreshSearch = function () {
                        vm.refresh();
                    };

                    vm.tabs = {
                        tyres: { componentType: "Tyre", fitments: [], active: true },
                        rims: { componentType: "Rim", fitments: [], active: false },
                        chains: { componentType: "Chain", fitments: [], active: false }
                    };

                    vm.currentTab = vm.tabs.tyres;
                    vm.currentTabIndex = vm.currentTab.componentType;

                    $scope.selectTab = function (tab, positionId) {
                        vm.currentTab = tab;
                        vm.currentTabIndex = vm.currentTab.componentType;

                        //removed for Zendesk ticket 21525
                        //if (!positionId && vm.selectedComponent) {
                          //  vm.clearSelection();
                        //}

                        if (vm.currentTab === vm.tabs.tyres) {
                            vm.tabs.tyres.active = true;
                            vm.tabs.rims.active = false;
                            vm.tabs.chains.active = false;

                            $timeout(function (positionId) {
                                vm.fitmentsGridTyres.resize();

                                if (positionId) {
                                    $timeout(function (positionId) {
                                        vm.fitmentsGridTyres.selectRowById(positionId);
                                    }, 0, true, positionId);
                                }
                            }, 0, true, positionId);

                        } else if (vm.currentTab === vm.tabs.rims) {
                            vm.tabs.tyres.active = false;
                            vm.tabs.rims.active = true;
                            vm.tabs.chains.active = false;

                            $timeout(function (positionId) {
                                vm.fitmentsGridRims.resize();

                                if (positionId) {
                                    $timeout(function (positionId) {
                                        vm.fitmentsGridRims.selectRowById(positionId);
                                    }, 0, true, positionId);
                                }
                            }, 0, true, positionId);

                        } else if (vm.currentTab === vm.tabs.chains) {
                            vm.tabs.tyres.active = false;
                            vm.tabs.rims.active = false;
                            vm.tabs.chains.active = true;

                            $timeout(function (positionId) {
                                vm.fitmentsGridChains.resize();

                                if (positionId) {
                                    $timeout(function (positionId) {
                                        vm.fitmentsGridChains.selectRowById(positionId);
                                    }, 0, true, positionId);
                                }
                            }, 0, true, positionId);
                        }
                    };

                    function formatReadingDate(reading) {
                        if (reading) {
                            reading.formattedDate = $filter('date')(reading.date, 'short');
                        }
                    }

                    function processFitment(axle, position, fitment) {
                        fitment.label = position.label;

                        if (fitment) {

                            fitment.positionId = position.id;

                            formatReadingDate(fitment.totalHours);
                            formatReadingDate(fitment.totalDistance);

                            if (fitment.totalHours) {
                                fitment.totalHours.formattedValue = $filter('number')(fitment.totalHours.value);
                            } else {
                                fitment.totalHours = { formattedValue: "-" };
                            }

                            if (fitment.totalDistance) {
                                fitment.totalDistance.formattedValue = $filter('number')(fitment.totalDistance.value);
                            } else {
                                fitment.totalDistance = { formattedValue: "-" };
                            }

                            switch (fitment.type) {
                                case "Tyre":
                                    formatReadingDate(fitment.remainingDepth);
                                    if (!fitment.primaryOTD || fitment.primaryOTD.value === 0) {
                                        fitment.percentWorn = amtXlatSvc.xlat("equipment.missingOTD");
                                    } else {
                                        fitment.percentWorn = $filter('number')((1 - fitment.remainingDepth.value / fitment.primaryOTD.value) * 100, ocConfigSvc.general.percentageWornDecimals) + "%";
                                    }
                                    vm.tabs.tyres.fitments.push(fitment);
                                    break;
                                case "Rim":
                                    if (fitment.hoursSinceLastNDT) {                                       
                                        formatReadingDate(fitment.hoursSinceLastNDT);
                                        fitment.hoursSinceLastNDT.formattedValue = $filter('number')(fitment.hoursSinceLastNDT.value);
                                    }
                                    vm.tabs.rims.fitments.push(fitment);
                                    break;
                                case "Chain":
                                    formatReadingDate(fitment.remainingDepth);
                                    if (!fitment.primaryOTD || fitment.primaryOTD.value === 0) {
                                        fitment.percentWorn = amtXlatSvc.xlat("equipment.missingOCD");
                                    } else {
                                        fitment.percentWorn = $filter('number')((1 - fitment.remainingDepth.value / fitment.primaryOTD.value) * 100, 1) + "%";
                                    }

                                    vm.tabs.chains.fitments.push(fitment);
                                    break;
                            }
                        }
                    }

                    function processPosition(axle, position) {
                        for (var f = 0; f < position.fitments.length; f++) {
                            processFitment(axle, position, position.fitments[f]);
                        }
                    }

                    function processAxle(axle) {
                        for (var p = 0; p < axle.positions.length; p++) {
                            processPosition(axle, axle.positions[p]);
                        }
                    }

                    function processSchematic(schematic) {
                        vm.loadingSchematic = false;
                        vm.tabs.tyres.fitments = [];
                        vm.tabs.rims.fitments = [];
                        vm.tabs.chains.fitments = [];
                        if (!schematic.axles) {
                            throw new Error("Schematic has no axles");
                        }
                        for (var a = 0; a < schematic.axles.length; a++) {
                            processAxle(schematic.axles[a]);
                        }
                        $timeout();
                    }

                    $scope.$watch('equipmentObject.schematic', function (c) {
                        vm.refresh();
                    });

                    function getExportFilename() {
                        return  amtXlatSvc.xlat('vehicle.fitmentExportFilename', $scope.equipmentObject.serialNumber.formatted);
                    }

                    vm.export = function () {
                        exportSvc.exportData(
                            vm.apiUrls.historyExport, // url
                            vm.searchCriteria, // criteria
                            getExportFilename() // output filename
                        );
                    };

                    vm.refresh = function () {
                        if ($scope.equipmentObject && $scope.equipmentObject.schematic) {
                            processSchematic($scope.equipmentObject.schematic);

                            if (typeof vm.fitmentsGridTyres.recompile === "function") {
                                vm.fitmentsGridTyres.recompile();
                            }

                            if (typeof vm.fitmentsGridRims.recompile === "function") {
                                vm.fitmentsGridRims.recompile();
                            }

                            if (typeof vm.fitmentsGridChains.recompile === "function") {
                                vm.fitmentsGridChains.recompile();
                            }
                        }
                    };

                    $scope.showComponentDetails = function (item) {
                        WindowFactory.openItem({
                            component: 'component-details',
                            caption: amtXlatSvc.xlat('equipment.open' + item.type, item.serialNumber),
                            windowRelatedRecordId: item.equipmentId,
                            initParams: {
                                mode: "open",
                                componentType: item.type,
                                equipmentId: item.equipmentId,
                                siteId: item.siteId,
                                serialNumber: item.serialNumber,
                            },
                            width: 800,
                            height: 850,
                            onDataChangeHandler: vm.refresh
                        });
                    };

                    $scope.tabObject.refresh = vm.refresh;
                    $scope.tabObject.refreshResource = "equipment.refresh";
                    $scope.tabObject.canRefresh = false;
                    $scope.tabObject.canDelete = false;
                    $scope.tabObject.canEdit = false;
                    $scope.tabObject.canAdd = false;

                    $scope.tabObject.actions = [
                        {
                            title: amtXlatSvc.xlat('common.exportToExcel'),
                            click: vm.export
                        }
                    ];
                }]
        };
    }]);
