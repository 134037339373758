/**
    * @ngdoc directive
    * @name amtFramework.directive:amtCol
    * @restrict E
    * @requires amtFramework.directive:amtRow
    * @description
    * Renders a div with the correct boostrap classes based on the number of columns in the amtRow.<br><br>
    * You can also use the ng-show="true/false" directive to hide/display a column
    * @example
    * <doc:example module="amtFramework">
    *   <doc:source>
    *     <amt-row>
    *        <amt-col>
    *                <span>Col1</span>
    *            </amt-col>
    *            <amt-col>                
    *                <span>Col2</span>
    *             </amt-col>
    *     </amt-row>
    *     <amt-row>
    *         <amt-col col-span="7">
    *             <span>Col1</span>
    *         </amt-col>
    *         <amt-col col-span="5">                
    *             <span>Col2</span>
    *          </amt-col>
    *     </amt-row>
    *   </doc:source>
    * </doc:example>
**/

//import angular from 'angular';
import tmpl from "./amtCol.html";

angular.module("amtFramework").directive("amtCol", function () {
        return {
            restrict: "E",
            replace: true,
            transclude: true,
            require: "^amtRow",
            template: tmpl,
            scope: {
                colSpan: "="
            },
            link: function (scope: any, elem, attr, amtRow) {
                scope.intColSpan = parseInt(scope.colSpan > -1 ? scope.colSpan : 1);
                scope.layout = amtRow.layout;

                amtRow.add(scope.intColSpan);
                scope.row = amtRow;

                scope.$watch("row.cols", function() {
                    scope.class = String.format("col-xs-{0}", calculateWidth());
                });

                scope.$watch("colSpan", function(newVal, oldVal) {
                    if (newVal > -1 && newVal !== oldVal) {
                        scope.class = String.format("col-xs-{0}", newVal);
                    }
                });

                function calculateWidth() {
                    var width;
                    if (!amtRow.hasDisplay) {
                        width = 12 / amtRow.cols * scope.intColSpan;

                        if (width % 1 !== 0) {
                            throw new Error('The columns have to fit in a 12 column grid. Valid configurations are 1, 2, 3, 4, 6 columns or a combination with col-span, eq col-span="5" and col-span="7"');
                        }

                    } else {
                        // no scaling
                        width = scope.intColSpan;
                    }
                    return width;
                }
            }
        };
});
