//import angular from 'angular';

angular.module('app').filter('numster', numsterValFilter);

    // @ts-ignore
    numsterValFilter.$inject = ['$filter'];
    function numsterValFilter($filter) {

        return function (inputString) {
            if (isNaN(inputString)) {
                return inputString;
            }
            else {
                return $filter('number')(inputString);                
            }
        };
    }
