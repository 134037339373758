//import angular from 'angular';
import tmpl from './fullScreenSchematicPopup.html';



    angular.module('app.equipment').component('fullScreenSchematic', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            wnd: '='
        },
        template: tmpl,
        controller: [
            '$scope', 'WindowFactory', 'enums',
            function fullScreenSchematicPopupCtrl($scope, WindowFactory, enums) {
                var vm = this;
                $scope.vm = vm;

                vm.onClose = function() {
                    WindowFactory.closeWindow(vm.wnd);
                };

                vm.onProceed = function() {
                    vm.wnd.onDataChanged({
                        zoomRect: vm.initParams.zoomRect
                    });
                    WindowFactory.closeWindow(vm.wnd);
                };

                vm.setLabel = function (position) {
                    if (vm.wnd.initParams.setLabel) {
                        // save the label
                        vm.wnd.initParams.setLabel(position).then(function () {
                            if (vm.svgReset) {
                                // redraw this one too
                                vm.svgReset();
                            }
                        });
                    }
                };

                this.$onInit = function() {
                    var padding = 5;

                    vm.maxSchematicWidth = this.wnd.size.width - padding;
                    vm.maxSchematicHeight = this.wnd.size.height - padding;

                    this.wnd.onClose = vm.onClose;

                    vm.buttons =
                    [
                        {
                            value: 'common.select',
                            primary: true,
                            route: 'selectRegion',
                            name: 'selectButton',
                            click: 'select',
                            type: enums.buttonTypes.button
                        },
                        {
                            value: vm.wnd.initParams.setLabel == undefined ? 'common.cancel' : 'common.close_label',
                            primary: false,
                            cancel: true,
                            name: 'cancelButton',
                            click: 'cancel',
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        selectButton: {
                            visible: vm.wnd.initParams.setLabel == undefined,
                            disabled: false
                        },
                        cancelButton: {
                            visible: true,
                            disabled: false
                        }
                    };

                    vm.buttonMethods = {
                        select: vm.onProceed,
                        cancel: vm.onClose
                    };
                }

            }
        ]
    });
