//import angular from 'angular';


    angular.module('app.directives')
        .directive('ocScrollTop', [function () {
            return {
                restrict: 'A',
                replace: false,
                link: function (scope, elm, attrs, ctrl) {
                    scope.$watch(attrs.ocScrollTop, function (newValue: any) {
                        elm[0].scrollTop = newValue;
                    });
                }
            };
        }]);
