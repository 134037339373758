/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var tab_content_directive_1 = require("../directives/tab-content.directive");
var tab_title_directive_1 = require("../directives/tab-title.directive");
/**
 * Represents the tab component of the TabStrip.
 */
var TabStripTabComponent = /** @class */ (function () {
    function TabStripTabComponent() {
        /**
         * Used to disable a tab ([see example]({% slug tabs_tabstrip %}#toc-disabled-tabs)).
         *
         * Defaults to `false`.
         */
        this.disabled = false;
        this._tabContent = new core_1.QueryList();
        /**
         * @hidden
         *
         * Currently only disabled tabs can be focused. Otherwise they will be
         * immediately selected
         */
        this.focused = false;
    }
    Object.defineProperty(TabStripTabComponent.prototype, "tabContent", {
        get: function () {
            return this._tabContent.first;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabStripTabComponent.prototype, "tabTitle", {
        get: function () {
            return this._tabTitleDirective.first;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], TabStripTabComponent.prototype, "title", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], TabStripTabComponent.prototype, "disabled", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], TabStripTabComponent.prototype, "cssClass", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], TabStripTabComponent.prototype, "cssStyle", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], TabStripTabComponent.prototype, "selected", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], TabStripTabComponent.prototype, "closable", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], TabStripTabComponent.prototype, "closeIcon", void 0);
    tslib_1.__decorate([
        core_1.ContentChildren(tab_content_directive_1.TabContentDirective),
        tslib_1.__metadata("design:type", core_1.QueryList)
    ], TabStripTabComponent.prototype, "_tabContent", void 0);
    tslib_1.__decorate([
        core_1.ContentChildren(tab_title_directive_1.TabTitleDirective),
        tslib_1.__metadata("design:type", core_1.QueryList)
    ], TabStripTabComponent.prototype, "_tabTitleDirective", void 0);
    TabStripTabComponent = tslib_1.__decorate([
        core_1.Component({
            exportAs: 'kendoTabStripTab',
            selector: 'kendo-tabstrip-tab',
            template: ""
        })
    ], TabStripTabComponent);
    return TabStripTabComponent;
}());
exports.TabStripTabComponent = TabStripTabComponent;
