/**
 * @ngdoc directive
 * @name amtFramework.controls.directive:amtContent
 * @restrict E
 *
 * @description
 * Used to host the content on a page. mandatory on every page (but seriously, not really, guess that was the original intent)
 */
import { Component, HostBinding } from "@angular/core";
import { downgradeComponent } from "@angular/upgrade/static";

@Component({
    selector: 'amt-content',
    templateUrl: './amtContent.html',
    styles: [
        `:host { display: block }
         :host.flex { display: flex } `]
})
export class AmtContent {
    @HostBinding('class.wrapper-md') wrapperMd = true;
}

angular.module('amtFramework.controls').directive('amtContent', downgradeComponent({component: AmtContent}));