//import angular from 'angular';

angular.module('amtFramework.validation').directive('ngMax', ['$compile',
    function ($compile) {
            return {
                restrict: 'A',
                require: 'ngModel',
                link: function (scope: any, elem, attr, ctrl) {

                    var valSpan = angular.element('<span class="error-message error-ng-max" ng-hide="ngMaxValid">{{"common.validationNgMax_message" | xlat:' + attr.ngMax + '}}</span>');

                    valSpan.insertAfter(elem);
                    $compile(valSpan)(scope);

                    scope.$watch(attr.ngMax, function () {
                        ctrl.$setViewValue(ctrl.$viewValue);
                    });
                    var maxValidator = function (value) {
                        var max = scope.$eval(attr.ngMax) || Infinity;
                        if ((value || value === 0) && value > max) {
                            ctrl.$setValidity('ngMax', false);
                            scope.ngMaxValid = false;
                            return undefined;
                        } else {
                            ctrl.$setValidity('ngMax', true);
                            scope.ngMaxValid = true;
                            return value;
                        }
                    };

                    ctrl.$parsers.push(maxValidator);
                    ctrl.$formatters.push(maxValidator);
                }
            };

    }
]);
