//import angular from 'angular';
import * as _ from 'underscore';



    angular.module("app.admin").controller("fieldSurveyFrequencyCtrl", fieldSurveyFrequencyCtrl);

    // @ts-ignore
    fieldSurveyFrequencyCtrl.$inject = ["$scope", "amtCommandQuerySvc", "amtConstants", "amtXlatSvc", "confirmSvc", "$timeout", "ocConfigSvc", "notifySvc", "ocSecuritySvc", "enums"];
    function fieldSurveyFrequencyCtrl($scope, amtCommandQuerySvc, amtConstants, amtXlatSvc, confirmSvc, $timeout, ocConfigSvc, notifySvc, ocSecuritySvc, enums) {
        var vm = this;

        var constants = {
            week: "Week",
            month: "Month"
        };

        vm.siteOnlyFilter = {
            field: "systemHierarchyId",
            operator: "eq",
            value: ocConfigSvc.user.site.id
        };

        vm.checkDays = [];
        for (var i = 1; i <= 31; i++) {
            vm.checkDays.push({ key: i, name: i });
        }

        vm.checkPeriod = [];
        vm.checkPeriod.push({ key: constants.week, name: amtXlatSvc.xlat("fieldSurvey.week") });
        vm.checkPeriod.push({ key: constants.month, name: amtXlatSvc.xlat("fieldSurvey.month") });

        vm.readonly = !ocSecuritySvc.isAuthorised("Security.Settings.FieldSurveyFrequency", "readWrite");

        function ordinalSuffixOf(num) {
            var j = num % 10;
            var k = num % 100;
            var suffix = amtXlatSvc.xlat("fieldSurvey.ordinalSuffixNth");

            if (j === 1 && k !== 11) {
                suffix = amtXlatSvc.xlat("fieldSurvey.ordinalSuffixFirst");
            } else if (j === 2 && k !== 12) {
                suffix = amtXlatSvc.xlat("fieldSurvey.ordinalSuffixSecond");
            } else if (j === 3 && k !== 13) {
                suffix = amtXlatSvc.xlat("fieldSurvey.ordinalSuffixThird");
            }

            return num + suffix;
        }

        vm.checkStartDayOfWeekList = [
            amtXlatSvc.xlat("fieldSurvey.mondayAbbr"),
            amtXlatSvc.xlat("fieldSurvey.tuesdayAbbr"),
            amtXlatSvc.xlat("fieldSurvey.wednesdayAbbr"),
            amtXlatSvc.xlat("fieldSurvey.thursdayAbbr"),
            amtXlatSvc.xlat("fieldSurvey.fridayAbbr"),
            amtXlatSvc.xlat("fieldSurvey.saturdayAbbr"),
            amtXlatSvc.xlat("fieldSurvey.sundayAbbr")
        ];

        vm.checkStartDayOfWeek = _.map(vm.checkStartDayOfWeekList, function(dayOfWeek, index) {
            return {
                key: index + 1,
                name: dayOfWeek
            }
        });

        vm.checkStartDayOfMonth = [];
        for (var j = 1; j <= 28; j++) {
            vm.checkStartDayOfMonth.push({ key: j, name: ordinalSuffixOf(j) });
        }

        vm.selectTab = function (tabName) {
            confirmSvc.confirmSaveChange(vm.dirty).then(function () {
                if (vm.dirty) {
                    vm.load().then(function () {
                        $timeout(function () {
                            vm.dirty = false;
                            vm.currentRow = vm.frequencyList[vm.currentRow.index];
                            var selector = "tr:eq(" + vm.currentRow.index + ")";
                            vm.frequencyGrid.select(selector);
                        });
                    });
                }

                return true;
            }, function () {
                vm.currentTab = tabName;
            });

            return false;
        };

        vm.change = function () {
            vm.dirty = true;
        };
        vm.currentTab = "pressure";
        vm.apiUrls = amtConstants.url;
        vm.frequencyGrid = { refresh: function () { } };
        vm.frequencyList = undefined;

        vm.filterApplications = function () {
            var filters = [];
            if (!vm.filterShowAll) {
                filters = [vm.siteOnlyFilter];
            }

            if (vm.filter) {
                filters.push({
                    field: "applicationName",
                    operator: "contains",
                    value: vm.filter
                });
            }

            if (filters.length === 0) {
                vm.frequencyGrid.applyFilters();
            } else {
                // apply the filters
                vm.frequencyGrid.applyFilters([{
                    logic: "and",
                    filters: filters
                }]);
            }
        };

        vm.updateRow = function (row) {
            try {
                if (row.pressure && row.pressure.active) {
                    row.pressure.starting = (row.pressure.period.key === constants.week ?
                        row.pressure.startingWeek : row.pressure.startingMonth);

                    row.duePressure =
                        row.pressure.checks.key + " / " +
                        row.pressure.period.name + " / " +
                        row.pressure.starting.name;
                } else {
                    row.duePressure = amtXlatSvc.xlat("fieldSurvey.Inactive");
                }
            } catch (ex) {
                console.warn(ex.message);
                row.duePressure = " ??? ";
            }

            try {
                if (row.treads && row.treads.active) {

                    row.treads.starting = (row.treads.period.key === constants.week ?
                        row.treads.startingWeek : row.treads.startingMonth);

                    row.dueTreads =
                        row.treads.checks.key + " / " +
                        row.treads.period.name + " / " +
                        row.treads.starting.name;
                } else {
                    row.dueTreads = amtXlatSvc.xlat("fieldSurvey.Inactive");
                }
            } catch (ex2) {
                console.warn(ex2.message);
                row.dueTreads = " ??? ";
            }
        };

        vm.setCurrentRow = function (item) {
            vm.currentRow = item;

            var defaultVal = {
                active : false,
                period: {
                    key: constants.week,
                    name: amtXlatSvc.xlat("fieldSurvey.week")
                },
                checks: {
                    key: 1,
                    name: "1"
                },
                startingMonth: vm.checkStartDayOfMonth[0],
                startingWeek: vm.checkStartDayOfWeek[0]
            };

            vm.currentRow.treads = vm.currentRow.treads || angular.copy(defaultVal);
            vm.currentRow.pressure = vm.currentRow.pressure || angular.copy(defaultVal);
        };

        vm.selected = function (items) {
            if (vm.currentRow) {
                //We want to reload this regardless - so saves setup ids correctly
                //if (vm.currentRow.index === items[0].index) {
                //    return;
                //}

                if (!vm.dirty) {
                    vm.setCurrentRow(items[0]);
                } else {
                    confirmSvc.confirmSaveChange(vm.dirty).then(function () {
                        if (vm.dirty) {
                            vm.load().then(function () {
                                $timeout(function () {
                                    vm.dirty = false;
                                    var selector = "tr:eq(" + items[0].index + ")";
                                    vm.frequencyGrid.select(selector);
                                });
                            });
                        }

                        return true;
                    }, function () {

                        var selector = "tr:eq(" + vm.currentRow.index + ")";
                        vm.frequencyGrid.select(selector);
                        return false;
                    });
                }
            } else {
                vm.setCurrentRow(items[0]);
            }
        };

        vm.save = function () {
            var row = vm.currentRow;
            if (row) {
                vm.updateRow(row);
                vm.frequencyList[row.index] = row;                
                vm.processing = true;
                vm.page = vm.frequencyGrid.getPage(); //get the page so we can set it again in $timeout
                amtCommandQuerySvc.post(vm.apiUrls.setVehicleSurveyFrequency, row).then(function (response) {
                    vm.frequencyList = response.data;
                    populateGrid(vm, amtXlatSvc, constants);
                    vm.frequencyGrid.rebind();
                    notifySvc.success(amtXlatSvc.xlat("fieldSurvey.frequencySaved"));
                    $timeout(function () {
                        vm.filterApplications();

                        $timeout(function () {
                            if (vm.frequencyGrid.getPage() !== vm.page) {
                                vm.frequencyGrid.setPage(vm.page);
                            }
                            vm.frequencyGrid.selectRowById(row.applicationId);
                            var selector = "tr:eq(" + vm.currentRow.index + ")";
                            vm.frequencyGrid.select(selector);
                        });
                    });
                    vm.dirty = false;   
                }, amtCommandQuerySvc.handleError).finally(function () {
                    vm.processing = false;
                    vm.selected([row]);
                    $timeout();
                });
            }
        };

        vm.load = function () {
            return amtCommandQuerySvc.post(vm.apiUrls.getVehicleSurveyFrequencies).then(function (response) {
                vm.frequencyList = response.data;
                populateGrid(vm, amtXlatSvc, constants);
                vm.dirty = false;

                vm.frequencyGrid.refresh();
                // for the moment till we clean it up we filter after render
                // note that the amt framework internally uses jquery selects to find the grid, so it must render first for the current stuff to work.
                $timeout(function () {
                    //filterGrid(vm);
                    vm.filterApplications();
                  
                });

            }, amtCommandQuerySvc.handleError).finally(function () {
                vm.processing = false;
                $timeout();
            });
        };

        vm.cancel = function () {
            vm.processing = true;
            vm.load().then(function () {
                $timeout(function () {
                    vm.frequencyGrid.refresh();
                    vm.currentRow = vm.frequencyList[vm.currentRow.index];
                    vm.selected([vm.currentRow]);
                });
            });
        };

        vm.load();
    }

function filterGrid(vm: any) {
    var filters = [vm.siteOnlyFilter];
    if (vm.frequencyGrid.applyFilters) {
        vm.frequencyGrid.applyFilters([{
            logic: "and",
            filters: filters
        }]);
    }
}

function populateGrid(vm: any, amtXlatSvc: any, constants: { week: string; month: string; }) {
    for (var k = 0; k < vm.frequencyList.length; k++) {
        // adding an index so we can reselect them in the grid
        vm.frequencyList[k].index = k;
        try {
            if (vm.frequencyList[k].pressure) {
                vm.frequencyList[k].pressure.period.name = amtXlatSvc.xlat("fieldSurvey." + vm.frequencyList[k].pressure.period.key.toLowerCase());
                if (vm.frequencyList[k].pressure.period.key === constants.week) {
                    vm.frequencyList[k].pressure.startingWeek = vm.checkStartDayOfWeek[vm.frequencyList[k].pressure.starting.key - 1];
                }
                else {
                    vm.frequencyList[k].pressure.startingMonth = vm.checkStartDayOfMonth[vm.frequencyList[k].pressure.starting.key - 1];
                }
            }
            if (vm.frequencyList[k].treads) {
                vm.frequencyList[k].treads.period.name = amtXlatSvc.xlat("fieldSurvey." + vm.frequencyList[k].treads.period.key.toLowerCase());
                if (vm.frequencyList[k].treads.period.key === constants.week) {
                    vm.frequencyList[k].treads.startingWeek = vm.checkStartDayOfWeek[vm.frequencyList[k].treads.starting.key - 1];
                }
                else {
                    vm.frequencyList[k].treads.startingMonth = vm.checkStartDayOfMonth[vm.frequencyList[k].treads.starting.key - 1];
                }
            }
        } catch (ex) {
            // Hey, this is better than the nothing that was there before...
            console.error("GetVehicleSurveyFrequencies went wrong somewhere!");
        }

        vm.updateRow(vm.frequencyList[k]);
    }
}
