/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var utils_1 = require("../utils");
var kendo_draggable_1 = require("@telerik/kendo-draggable");
var DraggableDirective = /** @class */ (function () {
    function DraggableDirective(element, ngZone) {
        this.element = element;
        this.ngZone = ngZone;
        this.enableDrag = true;
        this.kendoPress = new core_1.EventEmitter();
        this.kendoDrag = new core_1.EventEmitter();
        this.kendoRelease = new core_1.EventEmitter();
    }
    DraggableDirective.prototype.ngOnInit = function () {
        this.toggleDraggable();
    };
    DraggableDirective.prototype.ngOnChanges = function (changes) {
        if (utils_1.isChanged('enableDrag', changes)) {
            this.toggleDraggable();
        }
    };
    DraggableDirective.prototype.ngOnDestroy = function () {
        this.destroyDraggable();
    };
    DraggableDirective.prototype.toggleDraggable = function () {
        var _this = this;
        if (utils_1.isDocumentAvailable()) {
            this.destroyDraggable();
            if (this.enableDrag) {
                this.draggable = new kendo_draggable_1.default({
                    drag: function (e) { return _this.kendoDrag.next(e); },
                    press: function (e) { return _this.kendoPress.next(e); },
                    release: function (e) { return _this.kendoRelease.next(e); }
                });
                this.ngZone.runOutsideAngular(function () { return _this.draggable.bindTo(_this.element.nativeElement); });
            }
        }
    };
    DraggableDirective.prototype.destroyDraggable = function () {
        if (this.draggable) {
            this.draggable.destroy();
            this.draggable = null;
        }
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], DraggableDirective.prototype, "enableDrag", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], DraggableDirective.prototype, "kendoPress", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], DraggableDirective.prototype, "kendoDrag", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], DraggableDirective.prototype, "kendoRelease", void 0);
    DraggableDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoDraggable]'
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.ElementRef, core_1.NgZone])
    ], DraggableDirective);
    return DraggableDirective;
}());
exports.DraggableDirective = DraggableDirective;
