//import angular from 'angular';
import * as _ from 'underscore';
import tmpl from './validationErrors.html';


    angular.module('app.site').component('endOfMonthValidationErrors',
        {
            bindings: {
                initParams: '=',
                buttonMethods: '=',
                buttonStates: '=',
                buttons: '=',
                closeOnSave: '=',
                wnd: '='
            },
            template: tmpl,
            controller: [
                '$scope', 'WindowFactory', 'enums',
                function ($scope, WindowFactory, enums) {

                    var vm = this;
                    $scope.vm = this;

                    this.$onInit = function () {

                        if (vm.wnd !== null) {
                            vm.wnd.onClose = vm.cancel;
                        }

                        vm.wnd.processing = true;

                        vm.errors = [];

                        angular.copy(vm.initParams).forEach(function (e) {

                            var tab = e.tab.split(".")[0];
                            var section = e.tab.split(".")[1];

                            var collection = _.find(vm.errors, r => r.tab === tab && r.section === section);

                            if (!collection) {
                                vm.errors.push({ tab: tab, section: section, errors: [e] });
                            } else {
                                collection.errors.push(e);
                            }
                        });

                        vm.buttons = [
                            {
                                primary: false,
                                cancel: true,
                                value: "common.close_label",
                                name: "closeButton",
                                click: "close",
                                type: enums.buttonTypes.button
                            }
                        ];

                        vm.buttonStates = {
                            closeButton: {
                                visible: true,
                                disabled: false
                            }
                        };

                        vm.buttonMethods = {
                            close: vm.closeWindow
                        };

                        vm.wnd.processing = false;
                    };

                    vm.closeWindow = function () {
                        WindowFactory.closeWindow(vm.wnd);
                    };

                    $scope.navigateToError = function (tab, section, name) {
                        if (vm.wnd.onDataChanged) {
                            vm.wnd.onDataChanged({ tab: tab, section: section, name: name });

                            vm.closeWindow();
                        }
                    };
                }
            ]
        });
