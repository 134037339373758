import { Component, OnInit, HostBinding, ChangeDetectionStrategy, AfterViewInit} from '@angular/core';
import { ConditionMonitoringService, CONTINUE_TO_MONITOR_RES, ICMEquipmentAlerts, IDisplayVehicle, IVisualInspectionBasicApi, MonitoringIssueData } from '../../condition-monitoring.service';
import { map, tap } from 'rxjs/operators';
import { KeyValue } from '@angular/common';
import { XlatPipe } from '../../../../../amt/i18n/xlat-pipe';
import { of, Observable, combineLatest } from 'rxjs';
import { Inject } from '@angular/core';
import { ConditionMonitoringBaseComponent, ICON_HEIGHT, ICON_WIDTH, IGroup, IRendererConfig } from '../condition-monitoring-base.component';
 
@Component({
    selector: 'tread-wear',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './../condition-monitoring-base.component.html', //./tread-wear.component.html
    styleUrls: ['./tread-wear.component.scss'],
    providers: [
        { provide: 'desiredWidth', useValue: ICON_WIDTH },
        { provide: 'desiredHeight', useValue: ICON_HEIGHT },
        ConditionMonitoringService]
})
export class TreadWearComponent extends ConditionMonitoringBaseComponent implements OnInit {  //xlat this stuff
    _displayBy = [{ text: "Tread Matching", value: 0 }, { text: "Tread Wear Thresholds", value: 1 }];
    displayBySelectedItem = this._displayBy.find(elem => elem.value === 1);

    constructor(protected monSvc: ConditionMonitoringService, protected xlatPipe: XlatPipe,
                @Inject('WindowFactory') protected WindowFactory: any) {
        super(monSvc, xlatPipe, WindowFactory);
        this.setTitle("conditionmonitoring.treadwear");
    }

    //public groupedVehicles$: Observable<IGroup<IDisplayVehicle>[]>;
    public groupedVehicles$: any;

    ngOnInit(): void {
        super.ngOnInit();
        this.setRendererConfig();
        this.updateData();
    }

    public onUpdateButtonClick(): void {
        super.toggleFilter(true);
        this.setRendererConfig();
        this.updateData();
    }

    private updateData(): void {
        this.setProcessing();

        this.groupedVehicles$ = combineLatest(this.monSvc.getVehicles$, this.monSvc.currentAlerts$).pipe(
            map((v) => {
                if (this.SpecsToShow.length === 0)
                    return v;

                //Not sure there is an easier way to do this. Might move this to filter on the server - but it works for now
                //Filter vehicles on selected specs
                //Filter issues on remaining vehicles
                //Update original variable and return it
                let x = v[0].filter(veh => this.SpecsToShow.some(sp => sp === veh.spec.model));
                let y = v[1].filter(vi => x.some(veh => veh.equipmentId === vi[0]));
                v[0] = x;
                v[1] = y
                return v;
            }),
            //tap(x => console.log(x)),
            map(v => this.sortVehicles(v, this.displayBySelectedItem.value)),
            map(v => this.groupBy(v, this.sortBySelectedItem.value)),
            //tap(x => console.log(x)),
            tap(x => this.isProcessing = false)
        );
    }

    /// Used to configure wheel colours and legend
    private setRendererConfig() {
        switch (this.displayBySelectedItem.value) {
            case 0: //Tread Matching
                this.rendererConfig.lookupTable = ["#DDD", "#E0D400"];
                this.rendererConfig.issues.set(0, "NoIssue");
                this.rendererConfig.issues.set(1, "TyreMatching");
                break;

            case 1: //Tread Wear Thresholds
            default:
                this.rendererConfig.lookupTable = ["#DDDDDD", "#DD5555"];
                this.rendererConfig.issues.set(0, "NoIssue");
                this.rendererConfig.issues.set(1, "AsymmetricWear");
        }
    }

    private sortVehicles(data: [IDisplayVehicle[], [string, IVisualInspectionBasicApi[]][]], displayBy: number): IDisplayVehicle[] {
        let vehicles = data[0];
        let issues = data[1];

        for (let issue of issues) {
            let broombroom = vehicles.find((veh) => { return veh.serialNo === issue[0] })
            var newIssueList: (MonitoringIssueData)[] = [];

            //Per vehicle NOT issue
            let axles = broombroom.spec?.axles;
            let posLabels = [].concat(...axles);

            for (let item of issue[1]) {

                if (displayBy === 0 && item.action === "ASYMMETRICALWEAR")
                    continue;

                if (displayBy === 1 && item.action === "MATCHINGLIMITEXCEEDED")
                    continue;

                switch (displayBy) {
                    case 0: //Tread Matching
                        //ensure each position only has one alert
                        let miDupIdx = newIssueList.findIndex(i => i.posLabel === item.posLabel);
                        if (miDupIdx < 0) {
                            let newIssue = <MonitoringIssueData>item;
                            newIssue.posIdx = posLabels.indexOf(item.posLabel);
                            newIssue.issueText = `Pos ${item.posLabel}: ` + this.xlatPipe.transform(`conditionmonitoring.${this.rendererConfig.issues.get(newIssue.issue)}`);
                            if (miDupIdx < 0) {
                                newIssueList.push(newIssue);
                            } else {
                                newIssueList[miDupIdx] = newIssue;
                            }
                        }
                        break;

                    case 1: //Tread Wear Thresholds
                    default:
                        //ensure each position only has one alert
                        let misDupIdx = newIssueList.findIndex(i => i.posLabel === item.posLabel);
                        if (misDupIdx < 0) {
                            let newIssue = <MonitoringIssueData>item;
                            newIssue.posIdx = posLabels.indexOf(item.posLabel);
                            newIssue.issueText = `Pos ${item.posLabel}: ` + this.xlatPipe.transform(`conditionmonitoring.${this.rendererConfig.issues.get(newIssue.issue)}`);
                            if (misDupIdx < 0) {
                                newIssueList.push(newIssue);
                            } else {
                                newIssueList[misDupIdx] = newIssue;
                            }
                        }
                }
            }
            broombroom.issues = newIssueList;
            let issueText = newIssueList.sort((a, b) => parseInt(a.posLabel) - parseInt(b.posLabel)).map(m => m.issueText).join("<br />") || this.xlatPipe.transform("conditionmonitoring.NoIssue");//Sort the array here for now. PosLabels were in DESC order (from the database - may have been because of date ordering - leave the sort in anyway?)
            broombroom.altText = this.xlatPipe.transform("conditionmonitoring.treadwear") + ":<br />" + issueText;
        }

        return vehicles;
    }
}
