/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_licensing_1 = require("@progress/kendo-licensing");
var package_metadata_1 = require("../package-metadata");
var util_1 = require("./util");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var util_2 = require("../common/util");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
/**
 * Represents the [Kendo UI GridLayout component for Angular]({% slug overview_gridlayout %}).
 */
var GridLayoutComponent = /** @class */ (function () {
    function GridLayoutComponent(renderer, element, localization) {
        this.renderer = renderer;
        this.element = element;
        this.localization = localization;
        this.hostClass = true;
        /**
         * Specifies the gaps between the elements. The default value is `0`
         * ([see example]({% slug layout_gridlayout %}#toc-gaps)).
         */
        this.gap = 0;
        this._align = {
            horizontal: 'stretch',
            vertical: 'stretch'
        };
        kendo_licensing_1.validatePackage(package_metadata_1.packageMetadata);
    }
    Object.defineProperty(GridLayoutComponent.prototype, "dir", {
        get: function () {
            return this.direction;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GridLayoutComponent.prototype, "align", {
        get: function () {
            return this._align;
        },
        /**
         * Specifies the horizontal and vertical alignment of the inner GridLayout elements
         * ([see example]({% slug layout_gridlayout %}#toc-alignment)).
         */
        set: function (align) {
            this._align = Object.assign({}, this._align, align);
            this.handleAlignClasses();
        },
        enumerable: true,
        configurable: true
    });
    GridLayoutComponent.prototype.ngAfterViewInit = function () {
        this.handleAlignClasses();
        this.handleGridTemplateStyling('rows');
        this.handleGridTemplateStyling('cols');
        this.setGap();
    };
    GridLayoutComponent.prototype.ngOnChanges = function (changes) {
        if (kendo_angular_common_1.isChanged('gap', changes)) {
            this.setGap();
        }
        if (kendo_angular_common_1.isChanged('rows', changes)) {
            this.handleGridTemplateStyling('rows');
        }
        if (kendo_angular_common_1.isChanged('cols', changes)) {
            this.handleGridTemplateStyling('cols');
        }
    };
    GridLayoutComponent.prototype.handleAlignClasses = function () {
        var elem = this.element.nativeElement;
        if (util_2.isPresent(this.justifyClass)) {
            this.renderer.removeClass(elem, this.justifyClass);
        }
        if (util_2.isPresent(this.alignClass)) {
            this.renderer.removeClass(elem, this.alignClass);
        }
        this.justifyClass = util_1.GRID_JUSTIFY_PREFIX + "-" + this.align.horizontal;
        this.alignClass = util_1.ALIGN_PREFIX + "-" + util_1.VERTICAL_SUFFIX[this.align.vertical];
        this.renderer.addClass(elem, this.justifyClass);
        this.renderer.addClass(elem, this.alignClass);
    };
    GridLayoutComponent.prototype.setGap = function () {
        var parsedGap = util_1.normalizeGap(this.gap);
        var gapStyle = util_1.generateGapStyle(parsedGap);
        this.renderer.setStyle(this.element.nativeElement, 'gap', gapStyle);
    };
    GridLayoutComponent.prototype.handleGridTemplateStyling = function (type) {
        if (!util_2.isPresent(this[type])) {
            return;
        }
        var isValid = util_1.validateGridLayoutRowsCols(this[type]);
        if (!isValid && core_1.isDevMode()) {
            var valueType = type === 'rows' ? 'GridLayoutRowSize' : 'GridLayoutColSize';
            throw new Error("The provided " + type + " value contains invalid elements. The array supports values of type number, string or " + valueType + ".");
        }
        var gridTemplateStyle = type === 'rows' ? 'grid-template-rows' : 'grid-template-columns';
        var gridStyle = util_1.generateGridStyle(this[type], type);
        this.renderer.setStyle(this.element.nativeElement, gridTemplateStyle, gridStyle.join(' '));
    };
    Object.defineProperty(GridLayoutComponent.prototype, "direction", {
        get: function () {
            return this.localization.rtl ? 'rtl' : 'ltr';
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        core_1.HostBinding('class.k-grid-layout'),
        tslib_1.__metadata("design:type", Boolean)
    ], GridLayoutComponent.prototype, "hostClass", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('attr.dir'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], GridLayoutComponent.prototype, "dir", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Array)
    ], GridLayoutComponent.prototype, "rows", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Array)
    ], GridLayoutComponent.prototype, "cols", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], GridLayoutComponent.prototype, "gap", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object),
        tslib_1.__metadata("design:paramtypes", [Object])
    ], GridLayoutComponent.prototype, "align", null);
    GridLayoutComponent = tslib_1.__decorate([
        core_1.Component({
            exportAs: 'kendoGridLayout',
            selector: 'kendo-gridlayout',
            providers: [
                kendo_angular_l10n_1.LocalizationService,
                {
                    provide: kendo_angular_l10n_1.L10N_PREFIX,
                    useValue: 'kendo.gridlayout'
                }
            ],
            template: "\n        <ng-content></ng-content>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.Renderer2,
            core_1.ElementRef,
            kendo_angular_l10n_1.LocalizationService])
    ], GridLayoutComponent);
    return GridLayoutComponent;
}());
exports.GridLayoutComponent = GridLayoutComponent;
