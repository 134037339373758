
<ng-container class="menu-box" style="display: flex; justify-content: right;">
    <kendo-dropdownlist #dropdownlist
                        [data]="siteDashboardList"
                        textField="name"
                        valueField="id"
                        [(ngModel)]="selectedDashboard"
                       (ngModelChange)="changeDashboard($event)">

    </kendo-dropdownlist>
</ng-container>