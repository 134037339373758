/**
 * @ngdoc filter
 * @name amtFramework.i18n.filter:xlat
 * 
 * @param {string} label Label to translate
 * @param {Object} parameters Parameters to substitute
 * 
 * @description
 * Used to translate resources in html-pages. 
 * 
 * @example
 * <amt-display label="'assetManager.confirmation_message' | xlat:assets.messages:assets.total">
 * </amt-display>
 */

import { Xlat } from "./xlat";

XlatFilter.$inject = ['amtXlatSvc'];
function XlatFilter(xlat: Xlat): Function {
    return function (label: string, ...parameters: any[]) {
        return xlat.xlat(label, ...parameters);
    }
}

angular.module('amtFramework').filter('xlat', XlatFilter);
