//import angular from 'angular';
import tmpl from './chainsPopup.html';


angular.module("app.fieldSurveys").component('chainsPopup', {
	    bindings: {
		    initParams: '=',
		    buttonMethods: '=',
		    buttonStates: '=',
		    buttons: '=',
		    closeOnSave: '=',
		    wnd: '='
		},
		controllerAs: 'vm',
	    template: tmpl,
		controller: ['WindowFactory', '$scope', 'enums', 'confirmSvc', '$timeout',
			function (WindowFactory: IWindowFactory, $scope, enums, confirmSvc, $timeout: ng.ITimeoutService) {
				var vm = this;
				vm.focusChainAndTyre1 = false;
		
                this.$onInit = function () {
					//copy the parameters so we can cancel out
					vm.ChainAndTyre1 = vm.wnd.initParams.chainAndTyre1;
					vm.ChainAndTyre2 = vm.wnd.initParams.chainAndTyre2;
					vm.rcd1 = vm.wnd.initParams.rcd1;
					vm.rcd2 = vm.wnd.initParams.rcd2;
					
					vm.buttons = [
						{
							primary: true, 
							cancel: false, 
							value: 'common.save_label',
							name: 'saveButton',
							click: 'save',
							type: enums.buttonTypes.button
						},
						{
							primary: false, 
							cancel: true, 
							value: 'common.cancel_label',
							name: 'cancelButton',
							click: 'cancel',
							type: enums.buttonTypes.button
						}
					];
					
					vm.buttonStates = {
						saveButton: {
							visible: true, 
							disabled: true
						},
						cancelButton: {
							visible: true, 
							disabled: false
						}
					};
					
					vm.buttonMethods = {
						save: vm.save,
						cancel: vm.cancel
					};

					//hack! Delay requesting focus for a few ms, the grid that opens this popup also gives it's self focus,
					//often taking it back if there is no delay here
					$timeout(() => { vm.focusChainAndTyre1 = true }, 150);
				};

                vm.cancel = function () {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {                        
                        WindowFactory.closeWindow(vm.wnd);
                        vm.wnd.onDataChanged(-1);
                    });                    
				};
		
				vm.save = function () {
					WindowFactory.closeWindow(vm.wnd);
					vm.wnd.onDataChanged(vm.ChainAndTyre1, vm.rcd1, vm.ChainAndTyre2, vm.rcd2);
                };           

                $scope.$watchGroup(['vm.form.$pristine', 'vm.form.$invalid'], function () {
                    vm.buttonStates.saveButton.disabled = vm.form.$pristine || vm.form.$invalid;
                });
        	}
		]
    });
