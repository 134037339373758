import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Widget } from '../../service/Interface/SiteDashboard.interface';
import { VehicleMaintenanceUi } from '../../service/Interface/WidgetConstant.interface';
import { WidgetRxjsService } from '../../service/rxjs/WidgetRxjs.service';
import { WidgetHelper } from '../../service/widgetHelper/Widget.helper';
import { WidgetConstant } from '../../service/widgetHelper/WidgetConstant';
import { Subscription } from 'rxjs';


@Component({
    selector: 'widget-vehicle-in-maintenance',
    templateUrl: './vehicle-in-maintenance.component.html',
    styleUrls: ['./../../site-dashboard.component.scss']
})


//Completed
export class SiteDashboardVehicleInMaintenanceComponent implements OnInit, OnDestroy  {
    public maintenanceSessionsInProgressSubscription: Subscription;
    public maintenanceSessionsPendingSubscription: Subscription;
    public ui: VehicleMaintenanceUi;
    public maintenanceSessionsInProgress: number = 0;
    public maintenanceSessionsPending: number = 0;
    public isIncludeSubtext: boolean;

    constructor(private widgetRxjs: WidgetRxjsService, private widgetHelper: WidgetHelper, private widgetConstant: WidgetConstant) {
        this.ui = this.widgetConstant.getVehicleMaintenanceWidgetUi();
    }

    @Input() set showSubtext(widget: Widget) {
        let isIncludeSubtextValue = this.widgetHelper.getIncludeSubtext(widget.detailElement);
        this.isIncludeSubtext = isIncludeSubtextValue;
    }

    ngOnInit() {
        this.getVehicleInMaintenanceApiResult();
    }

    ngOnDestroy() {
        this.maintenanceSessionsInProgressSubscription.unsubscribe();
        this.maintenanceSessionsPendingSubscription.unsubscribe();
    }

    //subscribe Widget Api Result
    getVehicleInMaintenanceApiResult() {
        this.maintenanceSessionsInProgressSubscription = this.widgetRxjs.maintenanceSessionsInProgress.subscribe(
            value => {
                this.maintenanceSessionsInProgress = this.widgetHelper.getValidateValue(value);
            });

        this.maintenanceSessionsPendingSubscription = this.widgetRxjs.maintenanceSessionsPending.subscribe(
            value => {
                this.maintenanceSessionsPending = this.widgetHelper.getValidateValue(value);
            });
    }

    setSearchBy() {
        localStorage.setItem('siteDashboardSearchby', 'In Progress');
    }

}