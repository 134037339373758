//import angular from 'angular';
import FileManagement from '../../services/fileManagement';
import tmpl from './ocFilePicker.html';



class ocFilePickerCtrl implements ng.IComponentController {

    ngModel: IFile;

    maxSize?: number;
    accept: string[];
    showCameraIcon = false;

    ngRequired: boolean;
    ngDisabled: boolean;

    onFileSelected: () => void;
    validate: ({ model: IFile }) => boolean;

    selectFile = (fileSelectedParams) => this.fileSelected(fileSelectedParams);

    acceptedTypes: string;

    pickerElementId = 'filePicker' + this.$scope.$id;

    static $inject = ['$scope', '$timeout', 'notifySvc', 'amtXlatSvc', 'fileManagement'];

    constructor(private $scope: ng.IScope, private $timeout: ng.ITimeoutService, private notifySvc: INotifySvc,
        private amtXlatSvc: IAmtXlatSvc, private fileManagement: FileManagement) {
    }

    $onInit() {
        this.acceptedTypes = this.accept ? this.accept.toString() : '*.*';
    }

    public addFile() {

        // locate the hidden input control
        let ctrl = document.getElementById(this.pickerElementId) as HTMLInputElement;

        if (ctrl) {
            if (!this.ngModel)
                ctrl.value = '';

            // fire the click behaviour on the input control, which should force the file dialog
            ctrl.click();
        }
    }

    // called when the user selects a file
    public fileSelected(fileSelectedParams: any) {

        if (fileSelectedParams.target.files.length > 0) {

            let file = fileSelectedParams.target.files[0];

            // check if file is an allowed type
            if (this.accept) {
                if (!this.accept.some(a => a == this.fileManagement.getExtension(file.name)
                    || (a === 'image/*' && this.fileManagement.getExtensionType(file.name) === FileType.image))) {

                    this.notifySvc.error(this.amtXlatSvc.xlat('exception.invalidFileType'));
                    return;
                }
            }

            // check name length. file table in database allows up to 255 characters
            if (file.name.length > 255) {
                this.notifySvc.error(this.amtXlatSvc.xlat('exception.fileNameExceedsLengthLimit'));
                return;
            }

            // if size limit has been set ensure the file does not exceed it
            if (this.maxSize) {
                if (file.size / 1024 > this.maxSize) {
                    this.notifySvc.error(this.amtXlatSvc.xlat('exception.fileExceedsSizeLimit', this.maxSize));
                    return;
                }
            }

            this.$timeout(async () => {

                let model = angular.copy(this.ngModel) || this.fileManagement.emptyFile();

                let file: File = fileSelectedParams.target.files[0];

                model.name = file.name;
                model.fileSize = file.size;
                model.type = file.type;
                model.createdDate = new Date();
                model.persisted = false;
                model.uploaded = false;

                let valid = true;

                if (this.validate)
                    valid = this.validate({ model: model });

                if (valid) {

                    this.fileManagement.clearData(model);
                    model.data = await this.fileManagement.fileToBlob(file);

                    if (this.fileManagement.isImage(file.type)) {
                        let thumb = await this.fileManagement.getThumbnail(null, model);

                        if (thumb)
                            model.thumb = thumb.thumb;
                    }

                    this.ngModel = model;

                    if (this.onFileSelected)
                        this.$timeout(() => this.onFileSelected());
                }
            });
        }
    }
}

class ocFilePickerComponent implements ng.IComponentOptions {
    public bindings = {
        ngModel: '=',
        ngDisabled: '=ngDisabled',
        accept: '=',
        showCameraIcon: '=',
        ngRequired: '=ngRequired',
        maxSize: '@?', // in kb
        onFileSelected: '&?',
        validate: '&?'
    };
    public requires = 'ngModel';
    public template = tmpl;
    public controller = ocFilePickerCtrl;
    public controllerAs = 'vm';
}

angular.module('app.directives').component('ocFilePicker', new ocFilePickerComponent());