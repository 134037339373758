/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var button_module_1 = require("./button/button.module");
var buttongroup_module_1 = require("./buttongroup/buttongroup.module");
var splitbutton_module_1 = require("./splitbutton/splitbutton.module");
var dropdownbutton_module_1 = require("./dropdownbutton/dropdownbutton.module");
var chip_module_1 = require("./chip/chip.module");
var floatingactionbutton_module_1 = require("./floatingactionbutton/floatingactionbutton.module");
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmodules'] }})
 * definition for the Buttons components.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Buttons module
 * import { ButtonsModule } from '@progress/kendo-angular-buttons';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, ButtonsModule], // import Buttons module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
var ButtonsModule = /** @class */ (function () {
    function ButtonsModule() {
    }
    ButtonsModule = tslib_1.__decorate([
        core_1.NgModule({
            exports: [buttongroup_module_1.ButtonGroupModule, button_module_1.ButtonModule, splitbutton_module_1.SplitButtonModule, dropdownbutton_module_1.DropDownButtonModule, chip_module_1.ChipModule, floatingactionbutton_module_1.FloatingActionButtonModule]
        })
    ], ButtonsModule);
    return ButtonsModule;
}());
exports.ButtonsModule = ButtonsModule;
