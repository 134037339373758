import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { WidgetHelper } from '../../service/widgetHelper/Widget.helper';
import { WidgetRxjsService } from '../../service/rxjs/WidgetRxjs.service';
import { Widget } from '../../service/Interface/SiteDashboard.interface';
import { TyreInInspectionUi } from '../../service/Interface/WidgetConstant.interface';
import { WidgetConstant } from '../../service/widgetHelper/WidgetConstant';
import { Subscription } from 'rxjs';
import { TyreInInspectionElement } from '../../service/Interface/Widget.interface';


@Component({
    selector: 'widget-tyres-in-inspection',
    providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
    templateUrl: './tyres-in-inspection.component.html',
    styleUrls: ['./../../site-dashboard.component.scss']
})


//Completed
export class SiteDashboardTyresInInspectionComponent implements OnInit, OnDestroy {
    public tyreInspectionResultSubscription: Subscription;
    public scrappedMonthCountSubscription: Subscription;
    public ui: TyreInInspectionUi;
    public unit: string;
    public isIncludeSubtext: boolean;
    public result: TyreInInspectionElement = {
        inspectionCount: 0,
        scrappedMonthCount: 0
    };

    constructor( private widgetHelper: WidgetHelper, private widgetRxjs: WidgetRxjsService, private widgetConstant: WidgetConstant) {
        this.ui = this.widgetConstant.getTyreInInspectionWidgetUi();
    }


    @Input() set showSubtext(widget: Widget) {
        let isIncludeSubtextValue = this.widgetHelper.getIncludeSubtext(widget.detailElement);
        this.isIncludeSubtext = isIncludeSubtextValue;
    }

    ngOnInit() {
        this.getTyreInspectionApiResult();
    }

    ngOnDestroy() {
        this.tyreInspectionResultSubscription.unsubscribe();
    }

    getTyreInspectionApiResult() {
        this.tyreInspectionResultSubscription = this.widgetRxjs.tyreInsepctionWidget.subscribe(
            result => {
                this.result = result;
                this.unit = this.widgetHelper.getTyreUnit(result.inspectionCount);
            });
    }

    setSearchBy() {
        localStorage.setItem('siteDashboardSearchby', 'INSPECTION');
    }

}