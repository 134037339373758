/**
 * @ngdoc directive
 * @name amtFramework.directive:amtTooltip
 * @restrict E
 * @param {string} content Text to be displayed
 * @param {string} tooltipPlacement Where to place it? Defaults to "top", but also accepts "bottom", "left", "right".
 *  
 * @function
 *
 * @description
 * AMT Tooltip
 */

//import angular from 'angular';
import template from './amtTooltip.html';

angular.module('amtFramework').directive('amtTooltip', [
    '$timeout',
    function ($timeout) {
                
                return {
                    restrict: 'E',
                    require: '^amtRow',
                    transclude: true,
                    template: template,
                    scope: {
                        content: '@',
                        tooltipPlacement: '@',
                    },
                    link: function($scope, elem, attr, amtRow) {
                    }
                };
    }
]);
