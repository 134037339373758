//import angular from 'angular';
import tmpl from './commentPopup.html';



    angular.module('app.equipment').component('commentPopup', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['$scope', 'amtCommandQuerySvc', 'confirmSvc', 'enums', 'amtXlatSvc', 'equipmentUrls',
            'WindowFactory', 'notifySvc', 'errorReporter',
            function ($scope, amtCommandQuerySvc, confirmSvc, enums, xlatSvc, equipmentUrls, WindowFactory, notifySvc, errorReporter) {
                var vm = this;
                $scope.vm = this;

                vm.apiUrls = equipmentUrls.url;

                vm.mode = "open";

                this.$onInit = function () {
                    // set the form mode (new/open/edit)
                    vm.mode = vm.initParams.mode;
                    vm.id = vm.initParams.id;
                    vm.eventId = vm.initParams.eventId;
                    vm.source = vm.initParams.source;
                    vm.equipmentId = vm.initParams.equipmentId;

                    // enable disable controls based on model
                    vm.readonly = vm.mode === 'open' || vm.initParams.readonly; // new and edit can edit

                    // load the data
                    vm.loadComment();

                    vm.wnd.onClose = vm.onClose;

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: 'common.save_label',
                            name: 'saveButton',
                            click: 'save',
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: true,
                            value: 'common.cancel_label',
                            name: 'cancelButton',
                            click: 'cancel',
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: !vm.readonly,
                            disabled: true
                        },
                        cancelButton: {
                            visible: true,
                            disabled: false
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.onSave,
                        cancel: vm.onClose
                    };
                };

                vm.loadComment = function () {
                    if (vm.mode !== "new") {

                        vm.wnd.processing = true;

                        var loadCriteria = {
                            id: vm.id,
                            eventId: vm.eventId,
                            source: vm.source,
                            equipmentId: vm.equipmentId
                        };

                        // get the details from the server
                        amtCommandQuerySvc.post(vm.apiUrls.getComment, loadCriteria).then(function (response) {
                            if (response) {

                                vm.comment = response;

                                // readonly if we cannot edit the event
                                vm.readonly = vm.readonly || vm.comment.canEdit === false;
                            }
                        }).catch(function (error) {
                            errorReporter.logError(error);
                        }).finally(function () {
                            vm.wnd.processing = false;
                        });
                    } else {
                        // new comment, add some default values
                        vm.comment = {
                            id: null,
                            equipmentId: vm.initParams.equipmentId,
                            eventId: null,
                            date: new Date()
                        };
                    }
                };

                vm.closeWindow = function () {
                    WindowFactory.closeWindow(vm.wnd);
                };

                vm.onClose = function () {
                    confirmSvc.confirmSaveChange(vm.form && vm.form.$dirty).then(function () {
                        vm.form.$dirty = false;
                        vm.closeWindow();

                        return true;
                    });

                    return false;
                };

                $scope.setForm = function () {
                    // add a watch to the dirty flag
                    $scope.$watch('vm.form.$dirty', function () {
                        vm.wnd.isDirty = vm.form.$dirty;
                    });
                };

                vm.onSave = function () {
                    if (vm.comment.equipmentId !== "") {

                        vm.wnd.processing = true;

                        amtCommandQuerySvc.post(vm.apiUrls.saveComment, vm.comment).then(function (response) {

                            // display a message for the user to show that the save was successful
                            notifySvc.success(xlatSvc.xlat("equipment.messageCommentSaved"));

                            // tell the parent that the data changed
                            if (vm.wnd.onDataChanged) {
                                vm.wnd.onDataChanged();
                            }

                            // close the window
                            vm.closeWindow();

                            return true;

                        }).catch(function (error) {
                            errorReporter.logError(error);
                        }).finally(function () {
                            vm.wnd.processing = false;
                        });
                    }

                    return false;
                };

                $scope.$watchGroup(['vm.form.$pristine', 'vm.form.$invalid', 'vm.wnd.processing'], function () {
                    vm.buttonStates.saveButton.disabled = !vm.form || vm.form.$invalid || vm.form.$pristine || vm.wnd.processing;
                });

                $scope.$watch('vm.readonly', function () {
                    vm.buttonStates.saveButton.visible = !vm.readonly;
                });
            }
        ]
    });
