/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var kendo_angular_dropdowns_1 = require("@progress/kendo-angular-dropdowns");
var menu_tabbing_service_1 = require("./menu-tabbing.service");
var filter_service_1 = require("../filter.service");
var filter_input_wrapper_component_1 = require("../filter-input-wrapper.component");
var utils_1 = require("../../utils");
/**
 * @hidden
 */
var FilterMenuInputWrapperComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FilterMenuInputWrapperComponent, _super);
    function FilterMenuInputWrapperComponent(localizationService) {
        var _this = _super.call(this, null) || this;
        _this.localizationService = localizationService;
        return _this;
    }
    Object.defineProperty(FilterMenuInputWrapperComponent.prototype, "hostClasses", {
        /**
         * @hidden
         */
        get: function () {
            return false;
        },
        enumerable: true,
        configurable: true
    });
    FilterMenuInputWrapperComponent.prototype.ngAfterViewInit = function () {
        if (this.isFirstDropDown && this.filterService && this.filterService.menuTabbingService) {
            this.filterService.menuTabbingService.firstFocusable = this.firstOperatorDropDown;
            this.firstOperatorDropDown.focus();
        }
    };
    FilterMenuInputWrapperComponent.prototype.operatorChange = function (dataItem) {
        this.currentOperator = dataItem;
    };
    FilterMenuInputWrapperComponent.prototype.filterChange = function (filter) {
        this.applyFilter(filter);
    };
    Object.defineProperty(FilterMenuInputWrapperComponent.prototype, "currentFilter", {
        /**
         * The current filter for the associated column field.
         * @readonly
         * @type {FilterDescriptor}
         */
        get: function () {
            return this._currentFilter;
        },
        /**
         * The current filter for the associated column field.
         * @readonly
         * @type {FilterDescriptor}
         */
        set: function (value) {
            this._currentFilter = value;
        },
        enumerable: true,
        configurable: true
    });
    FilterMenuInputWrapperComponent.prototype.updateFilter = function (filter) {
        Object.assign(this.currentFilter, filter);
        return this.filter;
    };
    FilterMenuInputWrapperComponent.prototype.onChange = function (value) {
        this.filterChange(this.updateFilter({
            field: this.column.field,
            operator: this.currentOperator,
            value: value
        }));
    };
    FilterMenuInputWrapperComponent.prototype.onShiftTab = function (e) {
        e.stopImmediatePropagation();
        if ((!this.menuTabbingService || !this.menuTabbingService.isColumnMenu) && this.isFirstDropDown && this.filterService.menuTabbingService.lastFocusable) {
            e.preventDefault();
            this.filterService.menuTabbingService.lastFocusable.focus();
        }
    };
    Object.defineProperty(FilterMenuInputWrapperComponent.prototype, "filterMenuDropDownLabel", {
        get: function () {
            var localizationMsg = this.localizationService.get('filterMenuOperatorsDropDownLabel') || '';
            var columnName = this.column ? this.column.title || this.column.field : '';
            return utils_1.replaceMessagePlaceholder(localizationMsg, 'columnName', columnName);
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", filter_service_1.FilterService)
    ], FilterMenuInputWrapperComponent.prototype, "filterService", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], FilterMenuInputWrapperComponent.prototype, "isFirstDropDown", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", menu_tabbing_service_1.MenuTabbingService)
    ], FilterMenuInputWrapperComponent.prototype, "menuTabbingService", void 0);
    tslib_1.__decorate([
        core_1.ViewChild(kendo_angular_dropdowns_1.DropDownListComponent, { static: false }),
        tslib_1.__metadata("design:type", kendo_angular_dropdowns_1.DropDownListComponent)
    ], FilterMenuInputWrapperComponent.prototype, "firstOperatorDropDown", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object),
        tslib_1.__metadata("design:paramtypes", [Object])
    ], FilterMenuInputWrapperComponent.prototype, "currentFilter", null);
    FilterMenuInputWrapperComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-grid-filter-menu-input-wrapper',
            template: "\n        <kendo-dropdownlist\n            kendoFilterMenuDropDown\n            [filterMenuDropDownLabel]=\"filterMenuDropDownLabel\"\n            [data]=\"operators\"\n            (valueChange)=\"operatorChange($event)\"\n            [value]=\"currentOperator\"\n            [valuePrimitive]=\"true\"\n            textField=\"text\"\n            valueField=\"value\"\n            (keydown.shift.tab)=\"onShiftTab($event)\">\n        </kendo-dropdownlist>\n        <ng-content></ng-content>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService])
    ], FilterMenuInputWrapperComponent);
    return FilterMenuInputWrapperComponent;
}(filter_input_wrapper_component_1.FilterInputWrapperComponent));
exports.FilterMenuInputWrapperComponent = FilterMenuInputWrapperComponent;
