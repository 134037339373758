//todo:rename properties

/**
 * @ngdoc directive
 * @name amtFramework.formControls.directive:amtNumberRange
 * @restrict E
 * 
 * @param {string} name Name, used for validation
 * @param {number} decimalPlaces Number of decimal places to display
 * @param {expression=} ngRequired Required
 * @param {expression=} ngDisabled Disabled
 * @param {number} ngModel valueMin: Minimum value
 * @param {number} ngModel valueMax: Maximum value
 * @param {callback=} onValueMinChange Function to call when Min Value changes
 * @param {callback=} onValueMaxChange Function to call when Max Value changes
 * 
 */

//import angular from 'angular';
import tmpl from './amtNumberRange.html';


angular.module('amtFramework.formControls').directive('amtNumberRange', ['appConfig',
    function (appConfig) {
            return {
                restrict: 'E',
                require: ['ngModel', '^form'],
                scope: {
                    name: '@',
                    valueMin: '=ngModel',
                    valueMax: '=ngModel',
                    decimalPlaces: '@',
                    ngRequired: '=',
                    ngDisabled: '=',
                    onValueMinChange: '&',
                    onValueMaxChange: '&'
                },
                template: tmpl,
                link: function (scope: any, element, attrs, ctrls) {
                    if (scope.name === undefined || scope.name.length === 0) {
                        throw new Error('name is mandatory');
                    }

                    scope.modelCtrl = ctrls[0];
                    scope.formCtrl = ctrls[1];

                    scope.floatValid = function() {
                        return ctrls[0].$valid;
                    };

                    var input = $(element).find('input');
                    var decimalPlaces = appConfig.decimalPlaces;
                    if (scope.decimalPlaces !== undefined && scope.decimalPlaces !== null) {
                        decimalPlaces = scope.decimalPlaces;
                    }

                    //TODO: any cast probably hides a bug, but I don't think directive is used
                    (<any>$(input)).number(true, decimalPlaces);
                    scope.onChange = function() {
                        if (scope.onValueMinChange()) {
                            scope.onValueMinChange()(scope.valueMin);
                        }
                        if (scope.onValueMaxChange()) {
                            scope.onValueMaxChange()(scope.valueMax);
                        }
                    };
                }
            };
    }
]);

