//import angular from 'angular';
import AdminUrls from '../../../admin/adminUrls';
import tmpl from './EditRolePopup.html';



    angular.module("app.admin").component("editRolePopup", {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['$scope', 'adminUrls', 'amtXlatSvc', 'enums', 'amtCommandQuerySvc', 'confirmSvc', 'WindowFactory', 'notifySvc', 'ocSecuritySvc', 'errorReporter', 'helperSvc',
            function ($scope, adminUrls: AdminUrls, xlatSvc: IAmtXlatSvc, enums, amtCommandQuerySvc, confirmSvc, WindowFactory: IWindowFactory, notifySvc, ocSecuritySvc, errorReporter, helperSvc) {

                var vm = this;
                $scope.vm = this;

                vm.apiUrls = adminUrls;
                vm.isVisible = true;

                this.$onInit = function () {

                    vm.wnd.onClose = vm.onClose;

                    vm.item = vm.initParams.item;

                    vm.readOnly = !ocSecuritySvc.isAuthorised('Security.Settings.Users.Roles', 'readWrite') || !vm.item.canEdit;

                    //OR-11229
                    vm.hasCostAccessGrantPermission = ocSecuritySvc.hasPermission('Security.Settings.Users.Roles.GrantCostAccess', 'readWrite');
                    
                    vm.hasGrantManufacturerPermission = ocSecuritySvc.hasPermission("Security.Settings.Users.Roles.GrantManufacturers", 'readWrite');

                    if (vm.item.manufacturerComponentType) {
                        vm.item.manufacturerComponentTypeId = vm.item.manufacturerComponentType.key;
                    }

                    if (vm.item.costAccessType) {
                        vm.item.costAccessType = {
                            key: vm.item.costAccessType,
                            name: vm.item.costAccessTypeDescription
                        };
                    } else {
                        vm.item.costAccessType = {
                            key: vm.item.costAccessType,
                            name: xlatSvc.xlat('rolesPermissions.hide')
                        };
                    }

                    if (vm.item.componentOwners) {
                        vm.item.componentOwnerIds = helperSvc.getKeys(vm.item.componentOwners);
                    }

                    vm.item.manufacturerIds = helperSvc.getKeys(vm.item.manufacturers);

                    vm.mode = vm.initParams.mode;

                    vm.status = enums.screenStatus.ready;

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: 'common.save_label',
                            name: 'saveButton',
                            click: 'save',
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: true,
                            value: 'common.cancel_label',
                            name: 'cancelButton',
                            click: 'cancel',
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: true,
                            disabled: true
                        },
                        cancelButton: {
                            visible: true,
                            disabled: false
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.onSave,
                        cancel: vm.onClose
                    };
                };

                var pristineWatch = $scope.$watch('vm.form.$setPristine', function (newValue, oldValue) {
                    if (oldValue === undefined && newValue) {
                        // call pristine once the function is available
                        vm.form.$setPristine();

                        // remove the watch
                        pristineWatch();
                    }
                });

                vm.onSave = function () {

                    vm.wnd.processing = true;

                    var command = {
                        personRoleId: vm.item.personRoleId,
                        componentOwnerIds: vm.item.componentOwnerIds,
                        cost: vm.item.costAccessType.key,
                        equipmentTypeId: vm.item.manufacturerComponentTypeId,
                        manufacturerIds: vm.item.manufacturerIds
                    };

                    amtCommandQuerySvc.post(vm.apiUrls.editRole, command)
                        .then(function (response) {

                            notifySvc.success(xlatSvc.xlat("userSecurity.saveSuccessful"));
                            vm.wnd.onDataChanged();
                            closeWindow();

                        }).catch(function (error) {
                            errorReporter.logError(error);
                        }).finally(function () {
                            vm.wnd.processing = false;
                        });
                };

                function closeWindow() {
                    WindowFactory.closeWindow(vm.wnd);
                }

                $scope.setForm = function () {
                    $scope.$watch('vm.form.$dirty', function () {
                        vm.wnd.isDirty = vm.form.$dirty;
                    });
                };

                vm.onClose = function () {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        vm.form.dirty = false;
                        closeWindow();
                        return true;
                    });

                    return false;
                };

                $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.readOnly', 'vm.wnd.processing'], function () {
                    vm.buttonStates.saveButton.visible = !vm.readOnly;
                    vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.form.$pristine || vm.wnd.processing;
                });
            }
        ]
    });
