//import angular from 'angular';
import BrowserSvc from '../../../services/browserSvc';
import OcDateSvc from '../../../services/ocDateSvc';
import tmpl from './vehicleDateTime.html';



    angular.module("app.fieldSurveys").component('vehicleDateTime', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['WindowFactory', '$scope', 'browserSvc', 'enums', 'ocDateSvc', 'notifySvc', 'amtXlatSvc', '$timeout',
            function (WindowFactory: IWindowFactory, $scope, browserSvc: BrowserSvc, enums, ocDateSvc: OcDateSvc, notifySvc, amtXlatSvc: IAmtXlatSvc, $timeout: ng.ITimeoutService) {
                var vm = this;
                $scope.vm = this;

                vm.maxDate = new Date();

                if (browserSvc.isMobile) {
                    vm.minDate = ocDateSvc.addDays(vm.maxDate, -2);
                }

                this.$onInit = function () {
                    vm.useDefault = false;

                    vm.date = vm.initParams.createdDate ? angular.copy(vm.initParams.createdDate) : null;

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: 'common.save_label',
                            name: 'saveButton',
                            click: 'save',
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: true,
                            value: 'common.cancel_label',
                            name: 'cancelButton',
                            click: 'cancel',
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: true,
                            disabled: true
                        },
                        cancelButton: {
                            visible: true,
                            disabled: false
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.save,
                        cancel: vm.cancel
                    };

                    $timeout(function () {
                        vm.form.$setPristine();
                    });
                };

                vm.cancel = function () {
                    vm.wnd.onDataChanged(null);
                    WindowFactory.closeWindow(vm.wnd);
                };

                vm.save = async function () {
                    vm.initParams.default = !!( vm.date && vm.useDefault ); //used as output that the date should be used as a default date in the calling code
                    vm.wnd.onDataChanged(vm.date);
                    WindowFactory.closeWindow(vm.wnd);
                };

                $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine'], function () {
                    vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.form.$pristine;
                });
            }
        ]
    });
