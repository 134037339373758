//import angular from 'angular';
import AdminUrls from '../../../adminUrls';
import tmpl from './updateAccessType.html';



angular.module('app.admin').component('updateAccessType', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['$scope', 'adminUrls', 'amtCommandQuerySvc', 'confirmSvc', 'enums', 'amtXlatSvc', 'WindowFactory', 'notifySvc', 'ocConfigSvc', 'errorReporter',
            function ($scope, adminUrls: AdminUrls, amtCommandQuerySvc, confirmSvc, enums, xlatSvc: IAmtXlatSvc, WindowFactory: IWindowFactory, notifySvc, ocConfigSvc, errorReporter) {

                var vm = this;
                $scope.vm = this;

                vm.config = ocConfigSvc;

                vm.form = {};
                vm.apiUrls = adminUrls;
                vm.title = '';

                this.$onInit = function () {

                    vm.wnd.onClose = vm.onClose;

                    vm.model = {
                        roleTypeId: vm.initParams.roleTypeId,
                        permissionId: vm.initParams.permissionId,
                        roleName: vm.initParams.roleName,
                        permissionName: vm.initParams.permissionName,
                        accessType: vm.initParams.accesType,
                        selectedAccessType: vm.initParams.selectedAccessType,
                        applyToAllRoles: false
                    };

                    vm.title = vm.model.roleName + ': ' + vm.model.permissionName;
                    vm.wnd.caption = vm.title;

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: 'common.save_label',
                            name: 'saveButton',
                            click: 'save',
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: true,
                            value: 'common.cancel_label',
                            name: 'cancelButton',
                            click: 'cancel',
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: true,
                            disabled: true
                        },
                        cancelButton: {
                            visible: true,
                            disabled: false
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.onSave,
                        cancel: vm.onClose
                    };
                };

                $scope.$watch('vm.form.$dirty', function (newValue) {
                    vm.wnd.isDirty = newValue;
                });

                vm.onClose = function () {
                    closeWindow();
                };

                function closeWindow() {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        vm.form.$setPristine();

                        WindowFactory.closeWindow(vm.wnd);
                    });
                }

                function updateAccessType(model) {

                    amtCommandQuerySvc.put(adminUrls.updateAccessType, model).then(function (response) {

                        if (response.data === 'saveRolePermission_dundasFail') {
                            notifySvc.error(xlatSvc.xlat("userSecurity.saveRolePermission_dundasFail", response.errors[0].message));
                        } else {
                            notifySvc.success(xlatSvc.xlat('rolesPermissions.updateAccessTypeSuccess'));
                        }

                        if (vm.wnd.onDataChanged) {
                            vm.wnd.onDataChanged();
                        }

                        vm.form.$setPristine();
                        closeWindow();

                    }).catch(function (error) {
                        errorReporter.logError(error);
                    }).finally(function () {
                        vm.wnd.processing = false;
                    });
                }

                vm.onSave = function () {
                    vm.wnd.processing = true;
                    vm.model.accessType = vm.model.selectedAccessType.key;
                    updateAccessType(vm.model);
                };

                var pristineWatch = $scope.$watch('vm.form.$setPristine', function (newValue, oldValue) {
                    if (oldValue === undefined && newValue) {
                        // call pristine once the function is available
                        vm.form.$setPristine();

                        // remove the watch
                        pristineWatch();
                    }
                });

                $scope.$watchGroup(['vm.form.$invalid', 'vm.wnd.processing', 'vm.form.$pristine'], function () {
                    vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.form.$pristine || vm.wnd.processing;
                });
            }
        ]
    });
