//import angular from 'angular';

angular.module('app.admin')
        .controller('backupStockLevelsSearchCtrl', ['$scope', '$timeout', 'amtCommandQuerySvc', 'amtXlatSvc', 'confirmSvc', 'referenceDataUrls',
            'backupStockLevelUrls', 'componentUrls', 'exportSvc', 'ocConfigSvc', 'WindowFactory', 'notifySvc', 'ocSecuritySvc', 'errorReporter',
            function ($scope, $timeout, amtCommandQuerySvc, xlatSvc, confirmSvc, referenceDataUrls, apiUrls, componentUrls, exportSvc, ocConfigSvc,
                WindowFactory: IWindowFactory, notifySvc, ocSecuritySvc, errorReporter) {
                var vm = this;

                // security
                vm.canDelete = ocSecuritySvc.isAuthorised('Security.Settings.BackupStockLevels.Delete', 'readWrite');

                vm.referenceDataUrls = referenceDataUrls;
                vm.apiUrls = apiUrls;
                vm.componentUrls = componentUrls;

                vm.showClients = false;
                vm.showSites = false;

                vm.criteria = {
                    filterValues: {
                        clientIds: [],
                        siteIds: [],
                        equipmentTypeId: null,
                        SpecificationIds: [],
                        specifications: [],
                        constructionIds: [],
                        SizeIds: [],
                        componentTypeId: null,
                        defaultToAllSitesWhenNoSystemHierarchyFilter: true
                    }
                };

                $scope.$watch('vm.criteria.filterValues.clientIds.length', function (cnt) {
                    if (cnt === 0 && vm.criteria.filterValues.siteIds.length > 0) {
                        // reset the sites if the clients are cleared
                        vm.criteria.filterValues.siteIds.length = 0;
                    }
                });

                vm.status = "loading";
                vm.isDirty = true;

                // get the config from root scope, this contains things like units of measure
                vm.config = ocConfigSvc;

                vm.gridControl = {};
                vm.selectedItem = null;

                function init() {
                    vm.loadDefaults();
                }

                vm.onDatabound = function () {
                    vm.selectedItem = null;
                };

                vm.onSelectedItemChanged = function (items) {
                    if (items != null && items.length === 1) {
                        vm.selectedItem = items[0];
                    } else {
                        vm.selectedItem = null;
                    }
                };

                vm.setHeaderHeight = function (height, offset) {
                    var offset = height + offset;
                    //console.log("grid height based on :" + offset);
                    vm.gridTop = offset;
                };

                vm.export = function () {
                    exportSvc.exportData(vm.apiUrls.searchExport, vm.criteria, xlatSvc.xlat('backupStockLevels.searchExportFilename'));
                };

                vm.loadDefaults = function () {

                    vm.status = 'loading';
                    vm.processing = true;

                    var defaultCriteria = {
                        statusTypeNames: []
                    };

                    amtCommandQuerySvc.post(vm.componentUrls.getDefaults, defaultCriteria).then(function (response) {
                        if (response) {

                            vm.defaults = {
                                clients: response.clients,
                                componentTypeId: response.componentType
                            };

                            // delay this so that client is sorted and doesn't trigger a lookup of the site
                            $timeout(function () {
                                vm.defaults.sites = response.sites;
                                vm.siteId = response.sites[0].key;
                            });

                            vm.status = "ready";
                        }
                    }, function (error) {
                        amtCommandQuerySvc.handleError(error);
                        vm.defaultErrors = [error];
                        vm.status = "error";
                    }).finally(function () {
                        vm.processing = false;
                    });
                };

                vm.onBackupStockLevelsLoaded = function (response) {
                    var results = response.result;

                    if (results && results.length > 0) {

                        var multipleLabel = xlatSvc.xlat('common.multiple_label');

                        for (var r = 0; r < results.length; r++) {

                            results[r].specificationDisplay = results[r].specifications && results[r].specifications.length > 0 ?
                                (results[r].specifications.length > 1 ? multipleLabel : results[r].specifications[0]) : "";
                        }
                    }

                    return response;
                };

                $scope.editBackupStockLevel = function (item) {
                    if (item !== undefined && item !== null) {
                        WindowFactory.openItem({
                            component: 'backup-stock-level-details',
                            caption: xlatSvc.xlat('backupStockLevels.editBackupStockLevel'),
                            initParams: {
                                backupStockLevelId: item.id,
                                isEdit: true,
                                siteId: item.site.id
                            },
                            width: 600,
                            onDataChangeHandler: vm.filter
                        });
                    }
                };

                $scope.addBackupStockLevel = function () {
                    WindowFactory.openItem({
                        caption: xlatSvc.xlat('backupStockLevels.addBackupStockLevel'),
                        component: 'backup-stock-level-details',
                        initParams: {
                            backupStockLevelId: null,
                            isEdit: false,
                            siteId: vm.siteId
                        },
                        width: 600,
                        onDataChangeHandler: vm.filter
                    });
                };

                $scope.deleteBackupStockLevel = function (item) {
                    confirmSvc.confirmMessage('backupStockLevels.confirmDeleteBackupStockLevel_title', 'backupStockLevels.confirmDeleteBackupStockLevel_text', item.equipmentSize.name).then(function () {

                        vm.processing = true;

                        amtCommandQuerySvc.post(vm.apiUrls.deleteBackupStockLevel, item).then(function (response) {

                            notifySvc.success(xlatSvc.xlat("backupStockLevels.deleteSuccess"));
                            vm.refreshGrid();
                            return;

                        }).catch(function (error) {
                            errorReporter.logError(error);
                        }).finally(function () {
                            vm.processing = false;
                        });
                    });
                };

                vm.refreshGrid = function () {
                    vm.gridControl.refresh();
                };

                vm.filter = function () {
                    vm.showClients = vm.criteria.filterValues.clientIds.length !== 1;
                    vm.showSites = vm.criteria.filterValues.siteIds.length !== 1;
                    vm.refreshGrid();
                };

                init();
            }]);
