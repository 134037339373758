import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { AmtContent } from './components/controls/amtContent';
import { AmtMenuActions } from './components/controls/amtMenuActions';
import { AmtMenuAction } from './components/controls/amtMenuAction';
import { TranslateModule, TranslateLoader, TranslateParser } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { XlatLoader } from './i18n/xlat-loader';
import { isMobile, IS_MOBILE } from './services/is-mobile.provider';
import { localeFactory, localeProvider } from './ajs-upgraded-providers';
import { XlatParser } from './i18n/xlat-parser';
import { XlatPipe } from './i18n/xlat-pipe';

//Cannot use as upgrade as these use replace
//import { AmtRowDirective } from './components/layout/amtRow.component';
//import { AmtColDirective } from './components/layout/amtCol.component';


//needs to be declared, entry, and exported, because of how this is consumed as a dependancy I think
const amtComponents = [
    AmtContent,
    AmtMenuActions,
    AmtMenuAction,
    XlatPipe
];

@NgModule({
    declarations: [
        amtComponents
    ],
    imports: [
        BrowserModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useClass: XlatLoader,
                deps: [HttpClient]
            },
            parser: {
                provide: TranslateParser,
                useClass: XlatParser
            }
        })
    ],
    providers: [
        { provide: IS_MOBILE, useValue: isMobile },
        { provide: '$locale', useFactory: localeFactory, deps: ['$injector'] }
    ],

    exports: amtComponents,

})
export class AmtModule {}