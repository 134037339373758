//import angular from 'angular';


    angular.module('app.directives')
        .directive('ocInvalid', function () {
            return {
                require: 'ngModel',
                link: function (scope: any, elem, attr, ngModelCtrl) {

                    // add a validator to validate on change of the value
                    ngModelCtrl.$validators.dynamicEval = function () {
                        // logical NOT here because we want to return false if the expression is true, which will add the ngInvalid class to the HTML node
                        return !scope.evaluateExpression();
                    };

                    scope.evaluateExpression = function () {
                        return scope.$eval(attr["ocInvalid"]);
                    };

                    scope.$watch(function() {
                            return scope.evaluateExpression();
                        },
                        function() {
                            ngModelCtrl.$validate();
                        });
                }
            };
        });
