//import angular from 'angular';


angular.module("app.site").controller("tyreOccurrenceListController", tyreOccurrenceListController);

    // @ts-ignore
    tyreOccurrenceListController.$inject = ["$rootScope", "$state", "amtConstants", "forecastingSvc", "amtXlatSvc"];

    // ReSharper disable once InconsistentNaming
    function tyreOccurrenceListController($rootScope, $state, amtConstants, forecastingSvc, amtXlatSvc) {

        var vm = this;

        vm.apiUrls = amtConstants.url;
        vm.gridControl = {};

        vm.forecastId = $rootScope.$stateParams.forecastid ? $rootScope.$stateParams.forecastid : 0;
        vm.dashboardType = $rootScope.$stateParams.dashboardType;
        vm.year = $rootScope.$stateParams.year ? $rootScope.$stateParams.year : null;
        vm.month = $rootScope.$stateParams.month ? $rootScope.$stateParams.month : null;
        vm.week = $rootScope.$stateParams.week ? $rootScope.$stateParams.week : null;
        vm.includeAll = $rootScope.$stateParams.includeAll ? $rootScope.$stateParams.includeAll : false;
        vm.currentTab = $rootScope.$stateParams.currentTab ? $rootScope.$stateParams.currentTab : "Vehicles";
        vm.depthUnit = $rootScope.$stateParams.depthUnit;


        vm.filterValues = {
            forecastId: vm.forecastId,
            taskTypes: vm.taskTypes,
            year: vm.year,
            month: vm.month,
            week: vm.week,
            includeAll: vm.includeAll
        };

        this.$onInit = function () {
            if (!(vm.forecastId > 0)) {
                $state.go('app.site.forecastlist');
            }

            switch (vm.dashboardType) {
                case 'New Tyre Fitment':
                    vm.filterValues.taskTypes = ['NT', 'NTS'];
                    vm.typeDescription = amtXlatSvc.xlat('occurrenceList.newTyreFitment');
                    break;

                case 'Scrap Tyre':
                    vm.filterValues.taskTypes = ['STS', 'NTS'];
                    vm.typeDescription = amtXlatSvc.xlat('occurrenceList.scrapTyre');
                    break;           
            }
        };

        vm.goToParentRoute = function () {
            forecastingSvc.setActiveTabName(vm.currentTab);
            $rootScope.goBack();
        };
    }
