/**
 * @ngdoc directive
 * @name amtFramework.filter.directive:amtCheckboxFilteritem
 * @sortOrder 20
 * @restrict E
 * @requires amtFramework.filter.directive:amtFilter
 * 
 * @param {string} label Label to display above the control
 * @param {string} propertyName Name of the property that is added to filterValues property of {@link amtFramework.filter.directive:amtFilter amtFilter}
 * @param {expression=} [isRequired=false] Required
 * @param {expression=} [isDisabled=false] Disabled
 * @param {string=} defaultValue Sets the default value when loading
 *
 * @description
 * Filter item that let's the user filter on a boolean value true or false.
 * 
 * @example
 * See {@link amtFramework.filter.directive:amtFilter amtFilter}
 */
//import angular from 'angular';
import tmpl from './amtCheckboxFilteritem.html';

angular.module('amtFramework.filter').directive('amtCheckboxFilteritem', function () {

            return {
                require: '^amtFilter',
                restrict: 'E',
                scope: {
                    label: '@',
                    propertyName: '@',
                    isRequired: '=',
                    isDisabled: '=',
                    defaultValue:'@'
                },
                template: tmpl,
                link: function (scope: any, elem, attr, filterCtrl) {

                    scope.filterItem = { propertyName: scope.propertyName, value: scope.defaultValue };
                    filterCtrl.addFilterItem(scope.filterItem);
                }
            };
});
