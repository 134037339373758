//import angular from 'angular';
import FileManagement from '../../services/fileManagement';
import BrowserSvc from '../../services/browserSvc';
import tmpl from './ocAttachments.html';

class ocAttachmentsCtrl implements ng.IComponentController {

    public readonly loadingUrl = 'content/common/spinner.gif';
    public ngModelCtrl: ng.INgModelController;
    public ngModel: IFile[];

    public addEditFn: (index?: number) => void;
    public onChange: () => void;

    public readOnly: boolean;
    public localDeleteOnly: boolean;
    public showTime: boolean;
    public processing: boolean;
    public allowDownload: boolean;

    public showThumbsAsList: boolean;

    public acceptedFileTypes: string[];

    static $inject = ['$scope', 'fileManagement', 'WindowFactory', 'amtXlatSvc', 'browserSvc', 'confirmSvc', 'errorReporter', 'notifySvc'];

    constructor(private $scope: ng.IScope, private fileManagement: FileManagement, private WindowFactory: IWindowFactory,
        private amtXlatSvc: IAmtXlatSvc, private browserSvc: BrowserSvc, private confirmSvc: IConfirmSvc,
        private errorReporter: IErrorReporter, private notifySvc: INotifySvc) {

        this.showThumbsAsList = JSON.parse(localStorage.getItem('showThumbsAsList')) != false;
    }

    $onInit() {
        if (!this.ngModel)
            this.ngModel = [];
    }

    toggleShowThumbsAsList($event: Event) {

        $event.preventDefault();
        $event.stopPropagation();

        this.showThumbsAsList = !this.showThumbsAsList;
        localStorage.setItem('showThumbsAsList', JSON.stringify(this.showThumbsAsList));
    }

    addEdit(index?: number) {

        // if an add/edit function has been provided use that
        if (this.addEditFn) {
            this.addEditFn(index);
            return;
        }

        let edit = index > -1;

        // open the attachment details window by default
        this.WindowFactory.openItem({
            component: 'attachment-details-popup',
            caption: this.amtXlatSvc.xlat(edit ? 'equipment.editAttachment' : 'equipment.newAttachment'),
            width: 550,
            initParams: {
                attachment: edit ? this.ngModel[index] : null,
                acceptedFileTypes: this.acceptedFileTypes,
                showCameraIcon: this.browserSvc.isMobile
            },
            modal: true,
            onDataChangeHandler: a => {
                if (edit) {
                    this.ngModel[index] = a;
                } else {
                    this.ngModel.unshift(a);
                }

                // clear the edit flag
                edit = false;

                if (this.onChange)
                    this.onChange();
            }
        });
    }

    async delete(attachment: IFile) {

        try {
            await this.confirmSvc.confirmMessage('equipment.confirmDeleteAttachment_title', 'equipment.confirmDeleteAttachment_text', attachment.name);
        } catch {
            return; // they cancelled
        }

        if (!attachment.id || this.localDeleteOnly) {

            let index = this.ngModel.indexOf(attachment);

            this.ngModel.splice(index, 1);

            if (this.onChange)
                this.onChange();

            return;
        }

        this.processing = true;

        try {
            await this.fileManagement.deleteFiles([attachment.id], true);

            // delete the attachment from the array
            this.ngModel.splice(this.ngModel.indexOf(attachment), 1);

            if (this.onChange)
                this.onChange();

            // tell the user it worked
            this.notifySvc.success(this.amtXlatSvc.xlat('equipment.attachmentDeleteSuccessful'));

        } catch (error) {
            this.errorReporter.logError(error)
        } finally {
            this.processing = false;
        }
    }

    async downloadAttachment(attachment: IFile) {
        if (attachment)
            await this.fileManagement.downloadFile(attachment.id, attachment);
    }

    getDateFormat() {
        let format: string = 'dd-MM-yyyy';

        if (this.browserSvc.isMobile)
            format = 'dd-MM-yy';

        if (this.showTime)
            format = format + ' HH:mm';

        return format;
    }
}

class ocAttachmentsComponent implements ng.IComponentOptions {
    public bindings = {
        ngModel: '<',
        addEditFn: '&?', // if they don't want to open the default attachment-details popup
        onChange: '&?', // after add/edit/delete
        readOnly: '<',
        localDeleteOnly: '<?',
        allowDownload: '<?',
        showTime: '@?',
        acceptedFileTypes: '=?',
        processing: '=?'
    };
    public require = { ngModelCtrl: 'ngModel' };
    public template = tmpl;
    public controller = ocAttachmentsCtrl;
    public controllerAs = 'vm';
}

angular.module('app.directives').component('ocAttachments', new ocAttachmentsComponent());