import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import BrowserSvc from './browserSvc';
import OcConfigSvc from './ocConfigSvc';

interface ICredentials {
    username: string,
    password: string,
    token: string,
    refresh_token: string,
    token_expires: string
}

interface ICredentialsWithOffline extends ICredentials {
    offline: boolean
}

const MOBILE_CREDENTIAL_DATAKEY = 'mobileCredentialData';
const DESKTOP_CREDENTIAL_DATAKEY = 'DesktopCredentialData';
const OFFLINE_TOKEN_STRING = 'offlinelogin'; //seems dumb but I'm refactoring not trying to reinvent wheels

@Injectable({
  providedIn: 'root'
})
export class AuthService {
    private readonly credentialDataKey: string;

    constructor(
        private browserSvc: BrowserSvc,
        private ocConfigSvc: OcConfigSvc
    ) {
        this.credentialDataKey = browserSvc.isMobile ? MOBILE_CREDENTIAL_DATAKEY : DESKTOP_CREDENTIAL_DATAKEY;
    }

    get credentials() {
        return (JSON.parse(localStorage.getItem(this.credentialDataKey)) || {}) as Partial<ICredentials>;
    }

    set credentials(val: Partial<ICredentialsWithOffline> | null) {
        if (val === null) {
            localStorage.removeItem(this.credentialDataKey);
        } else {
            if (val.offline)
                val.token = OFFLINE_TOKEN_STRING;

            delete val.offline;

            localStorage.setItem(this.credentialDataKey, JSON.stringify(val));
        }
    }

    get isAuthenticated() {
        let credentials = this.credentials;
        return !!(credentials.token && this.ocConfigSvc.user?.personId && (this.browserSvc.isMobile || new Date(credentials.token_expires) > new Date()));
    }

    get bearer() {
        let token = this.credentials.token;
        return token ? `Bearer ${token}` : '';
    }

    clearPasswordAndToken() {
        let credentials = this.credentials;
        if (credentials) {
            credentials.password = '';
            credentials.token = '';
        }

        this.credentials = null;
        this.credentials = credentials || {};
    }
}

angular.module('app.site').factory('authService', downgradeInjectable(AuthService) as any);