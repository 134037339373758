import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Widget } from '../../service/Interface/SiteDashboard.interface';
import { SiteHealthRateColor, SiteHealtRatehUi } from '../../service/Interface/WidgetConstant.interface';
import { WidgetRxjsService } from '../../service/rxjs/WidgetRxjs.service';
import { WidgetHelper } from '../../service/widgetHelper/Widget.helper';
import { DialogRxjsService } from './../../service/rxjs/DialogRxjs.service';
import { WidgetConstant } from '../../service/widgetHelper/WidgetConstant';
import { Subscription } from 'rxjs';

@Component({
    selector: 'widget-site-health-rating',
    templateUrl: './site-health-rating.component.html',
    styleUrls: ['./../../site-dashboard.component.scss']
})

//Completed
export class SiteDashboardHealthRatingComponent implements OnInit, OnDestroy {
    public resultSubscription: Subscription;
    public ui: SiteHealtRatehUi;
    public healthRate: number = 0;
    public healthColor: string;
    public isIncludeSubtext: boolean;

    constructor(private widgetRxjs: WidgetRxjsService, private widgetHelper: WidgetHelper, private dialogRxjs: DialogRxjsService, private widgetConstant: WidgetConstant) {
        this.ui = this.widgetConstant.getSiteHealthRateWidgetUi();
    }

    @Input() set showSubtext(widget: Widget) {
        let isIncludeSubtextValue = this.widgetHelper.getIncludeSubtext(widget.detailElement);
        this.isIncludeSubtext = isIncludeSubtextValue;
    }

    ngOnInit() {
        this.getSiteHealthRateApiResult();
    }

    ngOnDestroy() {
        this.resultSubscription.unsubscribe();
    }

    //subscribe Rxjs data
    getSiteHealthRateApiResult() {
        this.resultSubscription = this.widgetRxjs.siteHealthRate.subscribe(
            value => {
                if (0 > value) {
                    value = 0;
                }
                this.healthRate = value;
                this.getColorName(value);
            });
    }

    //Implemented with ngStyle
    getColorName(rate: number) {
        let color: SiteHealthRateColor = this.widgetConstant.componentElement.siteHealthRateColor;
        if (rate < 75) {
            return color.lessThan75Color;
        }
        else if (rate >= 75 && rate < 90) {
            return color.between75and90Color;
        }
        else if (rate >= 90) {
            return color.above90Color;
        }
    }

    showSiteHealthRateDialog() {
        let action = true;
        this.dialogRxjs.setHealthRateDialogActionListener(action);
    }

}