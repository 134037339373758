import { Directive, ElementRef, EventEmitter, HostBinding, Injector, Input, Output, SimpleChanges } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { Observable } from 'rxjs';
import { ISelectedPosition } from '../../../site/conditionMonitoring/condition-monitoring.service';

@Directive({
    selector: 'oc-vehicle-schematic'
})
export class OcVehicleSchematicDirective extends UpgradeComponent {
    @Input() public vehicleObject!: Observable<any>;
    @Input() maxWidth!: any;
    @Input() maxHeight!: any;
    @Input() includeFitments!: any;
    @Input() specification!: any;
    @Input() schematicDate!: any;
    @Input() openModule!: any;
    @Input() scaleToFit!: any;
    @Input() topAxle!: any;
    @Input() leftPos!: any;
    @Input() notification!: any;
    @Input() schematicWidth!: any;
    @Input() schematicHeight!: any;
    @Input() overflowX!: any;
    @Input() overflowY!: any;
    @Input() svgScale!: any;
    @Input() schematicHasScrollbar!: any;
    @Input() showChains!: any;
    @Input() showRims!: any;
    @Input() excludeReadings!: any;
    @Input() zoomRect!: any;
    @Input() processing!: any;
    @Input() selectedComponent: any;
    @Output() tyreSelected: EventEmitter<{component: ISelectedPosition, position: string}>;
    @Input() selectedPosition!: any;
    @Input() showPositionLabels: string;
    @Input() showPositionAsReading!: any;
    @Input() redraw!: any;
    @Input() roundAxles!: any;
    @Input() doRefresh!: any;
    @Input() positionsWide!: any;
    @Input() positionsHigh!: any;
    @Input() changeCount!: any;
    @Input() allowDragDrop!: any;
    @Input() showChangeCount!: any;
    @Input() positionView!: any;
    @Input() fitComponent!: any;
    @Input() removeComponents!: any;
    @Input() chevronIsVisible!: any;
    @Input() canOpenComponentDetails!: any;
    @Input() useReadingSequence!: any;
    @Input() disabledSegments!: any;
    @Input() onLabelSelected!: any;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('ocVehicleSchematic', elementRef, injector);
    }
}