import { Component, OnDestroy, OnInit } from '@angular/core';
import { ErrorsUi } from '../../service/Interface/WidgetConstant.interface';
import { WidgetRxjsService } from '../../service/rxjs/WidgetRxjs.service';
import { WidgetConstant } from '../../service/widgetHelper/WidgetConstant';
import { Subscription } from 'rxjs';
import { ErrorsInWidgetElement } from '../../service/Interface/Widget.interface';

@Component({
    selector: 'widget-errors',
    templateUrl: './errors.component.html',
    styleUrls: ['./../../site-dashboard.component.scss']
})


//Completed
export class SiteDashboardErrorsComponent implements OnInit, OnDestroy{
    public ui: ErrorsUi;
    public errorWidget: Subscription;
    public result: ErrorsInWidgetElement = {
        componentReceive: 0,
        stocktakesInError: 0,
        fieldSurveyError: 0,
        maintenanceSessionError: 0,
        incompletedEndOfMonth: 0
    };


    constructor(private widgetRxjs: WidgetRxjsService, private constant: WidgetConstant) {
        this.ui = this.constant.getErrorWidgetUi();
    }

    ngOnInit(){
        this.subscribeErrorWidgetResult();
    }

    ngOnDestroy() {
        this.errorWidget.unsubscribe();
    }

    private subscribeErrorWidgetResult() {
        this.errorWidget = this.widgetRxjs.errorWidget.subscribe(result => {
            this.result = result;
        });
    }

    //Implemented with ngStyle
    public getColorName(value: number) {
        if (value > 0) {
            return 'red';
        }
        else {
            return 'black';
        }
    }


    public setSearchErrorFilter() {
        localStorage.setItem('siteDashboardSearchby', 'Error');
    }

    public setSearchInCompletedFilter() {
        localStorage.setItem('siteDashboardSearchby', 'Incomplete');
    }
}