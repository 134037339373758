/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var animations_1 = require("@angular/animations");
var column_menu_item_content_template_directive_1 = require("./column-menu-item-content-template.directive");
/**
 * Represents an item that can be placed inside a
 * [ColumnMenuTemplate]({% slug api_grid_columnmenutemplatedirective %}) directive.
 *
 * @example
 * ```ts-preview
 *
 * _@Component({
 *     selector: 'my-app',
 *     template: `
 *       <kendo-grid [data]="data" [columnMenu]="true" [resizable]="true" #grid>
 *          <ng-template kendoGridColumnMenuTemplate let-service="service" let-column="column">
 *              <kendo-grid-columnmenu-item icon="arrows-resizing" text="Fit column"
 *                  (itemClick)="grid.autoFitColumn(column); service.close()">
 *              </kendo-grid-columnmenu-item>
 *          </ng-template>
 *          <kendo-grid-column field="Field1"></kendo-grid-column>
 *          <kendo-grid-column field="Field2"></kendo-grid-column>
 *       </kendo-grid>
 *     `
 * })
 *
 * class AppComponent {
 *   public data: any[] = [{ Field1: 'Foo', Field2: 'Bar' }];
 * }
 *
 * ```
 */
var ColumnMenuItemComponent = /** @class */ (function () {
    function ColumnMenuItemComponent() {
        /**
         * Fires when the item is clicked.
         */
        this.itemClick = new core_1.EventEmitter();
        /**
         * Fires when the content is expanded.
         */
        this.expand = new core_1.EventEmitter();
        /**
         * Fires when the content is collapsed.
         */
        this.collapse = new core_1.EventEmitter();
        this.contentState = 'collapsed';
    }
    Object.defineProperty(ColumnMenuItemComponent.prototype, "iconClass", {
        get: function () {
            return "k-i-" + this.icon;
        },
        enumerable: true,
        configurable: true
    });
    ColumnMenuItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes.expanded) {
            this.updateContentState();
        }
    };
    /**
     * @hidden
     */
    ColumnMenuItemComponent.prototype.onClick = function (e) {
        this.itemClick.emit(e);
        if (this.contentTemplate) {
            this.expanded = !this.expanded;
            this.updateContentState();
            if (this.expanded) {
                this.expand.emit();
            }
            else {
                this.collapse.emit();
            }
        }
    };
    ColumnMenuItemComponent.prototype.updateContentState = function () {
        this.contentState = this.expanded ? 'expanded' : 'collapsed';
    };
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ColumnMenuItemComponent.prototype, "itemClick", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ColumnMenuItemComponent.prototype, "expand", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ColumnMenuItemComponent.prototype, "collapse", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ColumnMenuItemComponent.prototype, "icon", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ColumnMenuItemComponent.prototype, "text", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], ColumnMenuItemComponent.prototype, "selected", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], ColumnMenuItemComponent.prototype, "disabled", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], ColumnMenuItemComponent.prototype, "expanded", void 0);
    tslib_1.__decorate([
        core_1.ContentChild(column_menu_item_content_template_directive_1.ColumnMenuItemContentTemplateDirective, { static: false }),
        tslib_1.__metadata("design:type", column_menu_item_content_template_directive_1.ColumnMenuItemContentTemplateDirective)
    ], ColumnMenuItemComponent.prototype, "contentTemplate", void 0);
    ColumnMenuItemComponent = tslib_1.__decorate([
        core_1.Component({
            animations: [
                animations_1.trigger('state', [
                    animations_1.state('collapsed', animations_1.style({ display: 'none' })),
                    animations_1.state('expanded', animations_1.style({ display: 'block' })),
                    animations_1.transition('collapsed => expanded', [
                        animations_1.style({
                            height: '0px',
                            display: 'block'
                        }),
                        animations_1.animate('100ms ease-in', animations_1.style({
                            height: '*'
                        }))
                    ]),
                    animations_1.transition('expanded => collapsed', [
                        animations_1.style({
                            height: '*'
                        }),
                        animations_1.animate('100ms ease-in', animations_1.style({
                            height: '0px'
                        }))
                    ])
                ])
            ],
            selector: 'kendo-grid-columnmenu-item',
            template: "\n        <div\n            class=\"k-columnmenu-item\"\n            (click)=\"onClick($event)\"\n            (keydown.enter)=\"onClick($event)\"\n            [class.k-state-selected]=\"selected\"\n            [class.k-state-disabled]=\"disabled\"\n            role=\"button\"\n            [attr.aria-expanded]=\"expanded\">\n           <span *ngIf=\"icon\" class=\"k-icon\" [ngClass]=\"iconClass\">\n           </span>\n           {{ text }}\n        </div>\n        <div *ngIf=\"contentTemplate\" [@state]=\"contentState\" style=\"overflow:hidden;\" class=\"k-columnmenu-item-content\">\n            <ng-container [ngTemplateOutlet]=\"contentTemplate.templateRef\">\n            </ng-container>\n        <div>\n    "
        })
    ], ColumnMenuItemComponent);
    return ColumnMenuItemComponent;
}());
exports.ColumnMenuItemComponent = ColumnMenuItemComponent;
