//import angular from 'angular';

export default class SysMaintActionSvc {

    readonly detailComponent = 'add-edit-visual-inspection-action';
    readonly module = 'VisualInspectionAction';
    readonly moduleGroup = 'Maintenance';
    readonly webAPIController = this.module;
    readonly resourceGroup = 'visualInspectionAction';
    readonly mainPermission = 'Security.Settings.' + this.moduleGroup + '.' + this.module;

    readonly urls = {
        getLookups: baseApiUrl + 'referenceData/getVisualInspectionActions',
        get: this.webAPIController + '/get',
        save: this.webAPIController + '/save',
        search: baseApiUrl + this.webAPIController + '/search',
        export: this.webAPIController + '/export'
    };

    readonly activeStates = [
        { key: true, description: this.amtXlatSvc.xlat('client.activeTrue'), default: true },
        { key: false, description: this.amtXlatSvc.xlat('client.activeFalse'), default: false }
    ];

    static $inject = ['amtCommandQuerySvc', 'exportSvc', 'amtXlatSvc', 'WindowFactory'];

    constructor(private amtCommandQuerySvc: IAmtCommandQuerySvc, private exportSvc: IExportSvc,
        private amtXlatSvc: IAmtXlatSvc, private WindowFactory: IWindowFactory) { }

    getAsync(id) {
        let criteria = {
            id: id
        };

        return this.amtCommandQuerySvc.post(this.urls.get, criteria);
    }

    export(searchCriteria) {
        this.exportSvc.exportData(this.urls.export, searchCriteria, this.amtXlatSvc.xlat(this.resourceGroup + '.searchTitle'));
    }

    saveAsync(item) {
        let criteria = {
            id: item.id,
            mode: item.mode,
            name: item.name,
            description: item.description,
            isActive: item.isActive,
            daysToResolve: item.monitorOnly ? 0 : item.daysToResolve,
            languageTypeId: item.languageTypeId,
            applyToAllLanguages: item.applyToAllLanguages
        };

        return this.amtCommandQuerySvc.post(this.urls.save, criteria);
    }

    addItem(cb) {
        let item = {
            isActive: false,
            applyToAllLanguages: true,
            mode: 'add'
        };

        this.openItem(item, cb);
    }

    openItem(item, cb) {
        this.WindowFactory.openItem({
            component: this.detailComponent,
            caption: this.amtXlatSvc.xlat(this.resourceGroup + '.' + (item.mode === 'add' ? 'addItem' : 'editItem')),
            initParams: item,
            width: 550,
            onDataChangeHandler: cb,
            windowRelatedRecordId: item.id,
            modal: true,
            canClose: false
        });
    }
}

angular.module('app.admin').service('sysMaintActionSvc', SysMaintActionSvc);