/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var kendo_angular_inputs_1 = require("@progress/kendo-angular-inputs");
var core_1 = require("@angular/core");
/**
 * @hidden
 */
var PagerInputDirective = /** @class */ (function () {
    function PagerInputDirective(host, renderer) {
        this.host = host;
        this.renderer = renderer;
    }
    PagerInputDirective.prototype.ngAfterViewInit = function () {
        var inputElement = this.host.numericInput.nativeElement;
        this.renderer.addClass(inputElement, 'k-pager-nav');
    };
    PagerInputDirective = tslib_1.__decorate([
        core_1.Directive({ selector: '[kendoGridPagerInput]' }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_inputs_1.NumericTextBoxComponent,
            core_1.Renderer2])
    ], PagerInputDirective);
    return PagerInputDirective;
}());
exports.PagerInputDirective = PagerInputDirective;
