import BrowserSvc from './browserSvc';
import { Injectable } from '@angular/core';

interface IOcConfigSvcSite {
    name: string;
    id: guid;
    active: boolean;
    currency: ICurrency;
    logo: any;
    settings: {
        enforceComponentOwner: string;
        usePressureSensors: boolean;
        purchaseOrdersEnforceForNewReceive: string;
        lowTreadWearThreshold: number,
        wornOutTreadWearThreshold: number,
        receive: {
            allowRimSerialNumberGeneration: boolean;
            allowThirdPartyComponents: boolean;
            allowThirdPartyVehicles: boolean;
            allowThirdPartyVehiclesData: boolean;
        };
        rimSerialNumberGenerationNextNumber: string;
        rimSerialNumberGenerationScheme: string;
        tyreMaintenance: {
            siteSerialNumber: {
                tyre: string; //TODO: enum?
                rim: string;
                chain: string;
                vehicle: string;
            };
            storeLocation: string;
            allowTemperatureReading: boolean;
            allowTreadReadingsWithoutVehicleReadings: boolean;
            showChains: boolean;
            useNitrogen: boolean;
            trackRims: boolean;
            checkOutWithIncompleteWP: string;
            workOrderNumber: string;
        };
        referenceData: {
            allowOther: {
                repairer: boolean;
                destination: boolean;
            },
            visualInspectionFrequencies: number[]
        };
    },
    units: {
        time: {
            unitAbbreviation: string;
        },
        distance: {
            unitAbbreviation: string;
        },
        depth: {
            unitAbbreviation: string;
        },
        torque: {
            unitAbbreviation: string;
        },
        temperature: {
            unitAbbreviation: string;
        },
        pressure: {
            unitAbbreviation: string;
        }
    }
}

interface IOcConfiguser {
    personId: guid;
    name: string;
    userName: string;
    emailAddress: string;
    roles: any;
    system: { id: guid };
    isSystemAdmin: boolean;
    client: {
        name: string;
        id: guid;
        active: boolean;
    },
    cultureInfo: string,
    site: IOcConfigSvcSite;
    availableSites: { clientId: guid, key: guid }[];
    imageSettings: {
        imageCompression: {
            compressImages: boolean;
            maxUncompressedImageSize: number;
            imageArea: number;
            imageQuality: number;
        };
        thumbnail: {
            generateThumbnails: boolean;
            thumbnailSize: number;
            thumbnailQuality: number;
        };
    },
    alerts?: any[];
    notifications?: any[];
    notificationSettings: {
        timeout: number;
        show: boolean;
    }
}

interface IOcConfigReports {
    dashboards: any[];
}

interface IOcConfigToast {
    acknowledge: () => void;
    count: number;
    id: guid;
    timedOut: boolean;
    timeoutPromise?: ng.IPromise<void>;
    clicked: boolean;
    onClick: () => void;
}

const MOBILE_SESSION_DATAKEY = 'MobileSessionData';
const DESKTOP_SESSION_DATAKEY = 'DesktopSessionData';

@Injectable({
    providedIn: 'root'
})
export default class OcConfigSvc {
    public readonly client = this.browserSvc.isMobile ? 'ngOtracomMobile' : 'ngOtracom'; //not the same as user.client, this is a string databroker uses for logins to differeniate mobile and desktop
    private readonly sessionDataKey = this.browserSvc.isMobile ? MOBILE_SESSION_DATAKEY : DESKTOP_SESSION_DATAKEY;

    public general = { percentageWornDecimals: 0 }

    public loginHeading: string; // for login page
    public loginMessage: string;

    public toast: IOcConfigToast; // for notifications

    public reports: IOcConfigReports;

    public userMenu = { show: false }

    public user: IOcConfiguser | null = JSON.parse(sessionStorage.getItem(this.sessionDataKey) || localStorage.getItem(this.sessionDataKey)) || null;

    constructor(private browserSvc: BrowserSvc) {
    }

    public getLanguage() {
        if (this.user && this.user.cultureInfo)
            return this.user.cultureInfo.substring(0, 2);


        //Bad - means the code below doesn't run!
        //return this.user?.cultureInfo?.substring(0, 2);

        //not logged in, get the language from the browser
        let defaultLanguage = 'en';
        let acceptedLanguages = ['en', 'es'];

        let culture = window.navigator.languages ? window.navigator.languages[0] : window.navigator.userLanguage || window.navigator.language || "";
        let lang = culture.substring(0, 2);

        return acceptedLanguages.indexOf(lang) >= 0 ? lang : defaultLanguage;
    }

    public async clearUser() {
        this.clearReports();
        this.user = null;

        sessionStorage.removeItem(this.sessionDataKey);
        localStorage.removeItem(this.sessionDataKey);
        //await this.setCurrentLanguageFrom();
    }

    public clearReports() {
        this.reports = null;
    }

    public updateUserReports(reports) {
        //this.dundasEnabled = reports.enabled;
        this.reports = reports;
    }

    public async updateUser(getSessionResponse) {
        if (!getSessionResponse)
            this.clearUser();

        this.user = {
            personId: getSessionResponse.personId,
            name: getSessionResponse.fullName,
            emailAddress: getSessionResponse.emailAddress,
            system: { id: getSessionResponse.systemId },
            client: {
                name: getSessionResponse.clientName,
                id: getSessionResponse.clientId,
                active: getSessionResponse.clientActive
            },
            site: {
                id: getSessionResponse.siteId,
                active: getSessionResponse.siteActive,
                name: getSessionResponse.siteName,
                units: getSessionResponse.units,
                settings: getSessionResponse.settings,
                currency: (getSessionResponse.currencyType ? {
                    id: getSessionResponse.currencyType.id,
                    symbol: getSessionResponse.currencyType.symbol,
                    decimalPlaces: getSessionResponse.currencyType.decimalPlaces
                } : null),
                logo: getSessionResponse.logo
            },
            roles: getSessionResponse.roles,
            userName: getSessionResponse.userName,
            cultureInfo: getSessionResponse.cultureInfo,
            availableSites: getSessionResponse.availableSites,
            notificationSettings: {
                timeout: getSessionResponse.notificationTimeout,
                show: getSessionResponse.showNotifications
            },
            isSystemAdmin: getSessionResponse.isSystemAdmin,
            imageSettings: getSessionResponse.imageSettings
        };

        let jsUser = JSON.stringify(this.user);

        // store it in session storage for this tab
        sessionStorage.setItem(this.sessionDataKey, jsUser);

        // save a copy in local storage for new tabs
        localStorage.setItem(this.sessionDataKey, jsUser);

            //await this.setCurrentLanguageFrom();
    }

    public currentSiteId() {
        return this.user?.site?.id;
    }

    public getUserName() {
        return this.user?.userName;
    }

    public isEnglishUser() {
        return this.user?.cultureInfo === "en-GB";
    }

    public isSpanishUser() {
        return this.user?.cultureInfo === "es-CL";
    }
}
