//import angular from 'angular';
import DbClass from '../modules/common/local-db';
import OcConfigSvc from '../services/ocConfigSvc';
import BrowserSvc from '../services/browserSvc';
import OcZendeskWidget from '../services/OcZendeskWidget';
import tinymd from 'tinymd';

    angular.module('app.controllers')
        .controller('AppCtrl', ['$scope', '$window', '$timeout', 'amtConstants', 'errorReporter', 'browserSvc', 
            '$rootScope', '$state', 'amtXlatSvc', 'dataBroker', 'ocAlertAndNotificationSvc', 'ocConfigSvc', 'WindowFactory', 'ocSecuritySvc',
            'amtCommandQuerySvc', 'ocZendeskWidget', 'reportSvc', '$db',
            function ($scope, $window: ng.IWindowService, $timeout: ng.ITimeoutService, amtConstants: IAmtConstants, errorReporter: IErrorReporter, browserSvc: BrowserSvc,
                $rootScope: IRootScope, $state: ng.ui.IStateService, xlatSvc: IAmtXlatSvc, dataBroker: IDataBroker, ocAlertAndNotificationSvc, ocConfigSvc: OcConfigSvc, WindowFactory: IWindowFactory, ocSecuritySvc,
                amtCommandQuerySvc: IAmtCommandQuerySvc, ocZendeskWidget: OcZendeskWidget, reportSvc, $db: DbClass) {

                var vm = this;

                try {

                    $scope.menuItems = {
                        settings: [],
                        reports: []
                    };

                    var alertInactiveTimer: ng.IPromise<void> = null;
                    var inactiveTime = null;

                    vm.apiUrls = amtConstants.url;
                    vm.inactiveTime = 90 * 60 * 1000;  // 90 minutes           

                    // config
                    $scope.app = ocConfigSvc;

                    $scope.appVersion = window.appVersion;

                    $scope.toggleUserMenu = function () {
                        ocConfigSvc.userMenu.show = !ocConfigSvc.userMenu.show;
                    };

                    $scope.closeUserMenu = function () {
                        ocConfigSvc.userMenu.show = false;
                    };

                    vm.showChangeSite = function () {
                        WindowFactory.openItem({
                            component: 'change-site',
                            caption: xlatSvc.xlat('common.changeSite'),
                            width: 550,
                            initParams: {
                                showCloseOnSave: false
                            },
                            modal: true,
                            onDataChangeHandler: function () {

                                $rootScope.changingSites = true;

                                // reload the application
                                $window.location.reload();
                            }
                        });
                    };


                    vm.logout = async function () {
                        let buttonResult: string = await WindowFactory.iconAlert('glyphicon-check', 'login.Logout',
                            ['framework.cancel_label', 'login.Logout'], 'login.SureLogout');
                            
                        if (buttonResult === 'login.Logout')
                            dataBroker.logout();
                    };

                    $scope.noBlur = function () {
                        $scope.preventBlurClose = true;
                    };

                    $scope.blurred = function () {
                        if (!$scope.preventBlurClose) {
                            $scope.closeUserMenu();
                        }
                        $scope.preventBlurClose = false;
                    };

                    // top-right menu functions
                    $scope.userMenuAction = function (action) {
                        $scope.closeUserMenu();

                        switch (action.toLowerCase()) {
                            case 'notifications':
                                showAlertsAndNotifications('notifications');
                                break;
                            case 'alerts':
                                showAlertsAndNotifications('alerts');
                                break;
                            case 'profile':
                                showUserProfile();
                                break;
                            case 'location':
                                vm.showChangeSite();
                                break;
                            case 'help':
                                showSupport();
                                break;
                            case 'logout':
                                vm.logout();
                                break;
                            default:
                                $rootScope.$broadcast('menu.' + action);
                                break;
                        }
                    };

                    $rootScope.$on('$stateChangeSuccess', function () {
                        tryToRedirectTransition();
                    });

                    tryToRedirectTransition();

                    if (browserSvc.isMobile) {
                        // tell the browser serice to start watching network change events
                        browserSvc.startConnectionMonitoring();     

                        upgradeMobileDatabase();
                    }

                    $rootScope.$on('login', (evt, newSession) => {
                        if (!browserSvc.isMobile) {
                            ocAlertAndNotificationSvc.start(5); // start with an initial interval of 5 seconds

                            getMenuItems();

                            // get the users reports
                            if (ocSecuritySvc.isAuthorised('Security.Reports', 'readOnly')) {
                                $timeout(async () => {
                                    await reportSvc.getReports(true);
                                    getMenuItems('reports');
                                });
                            }
                        }

                        //start activity monitoring
                        if (!vm.activityMonitor)
                            vm.activityMonitor = $rootScope.$on('OC.Activity', () => activity());

                        showReleaseNotesIfUnseen();
                    });

                } catch (error) {
                    $rootScope.applicationError = "Application startup failed (AppCtrl.js):" + error;
                }

                ////End of 'constructor' code

                async function upgradeMobileDatabase() {
                    //TODO: this can probably be removed at some point presuming no client DBs remain on old versions?
                    //await $db.upgradeOtracomDatabaseToV2600();

                    await $db.upgradeOtracomDatabaseToV2700();
                }

                function showReleaseNotesIfUnseen() {
                    const storageKey = 'releaseNotesSeenVersion';
                    let seenVer: string = localStorage.getItem(storageKey);
                    let currVer: string = $scope.appVersion.split('-')[0];

                    if (seenVer == currVer)
                        return;

                    let relnoteRaw: string = xlatSvc.xlat('releaseNotes.notes');

                    let lfPos = relnoteRaw.indexOf('\n');
                    let noteVer: string = relnoteRaw.slice(0, lfPos > 0 && relnoteRaw.charCodeAt(lfPos - 1) == /* cr */ 13 ? lfPos - 1 : lfPos);
                    
                    if (noteVer.trim() != currVer)
                        return;

                    let noteBody: string = relnoteRaw.slice(lfPos + 1);

                    let dialogTitle: string = xlatSvc.xlat('releaseNotes.title', currVer);
                    let relNotes: string = tinymd( xlatSvc.xlat('releaseNotes.bodyTemplate', currVer, noteBody),
                                                    { isBlank: s => true } );

                    WindowFactory.iconAlert('glyphicon-info-sign', dialogTitle, [], relNotes, [], 500, "alert-info-area", true).then(() => {
                        localStorage.setItem(storageKey, currVer);
                    });
                }

                function showAlertsAndNotifications(defaultTab) {
                    WindowFactory.openItem({
                        component: 'notifications-list',
                        caption: xlatSvc.xlat('common.notificationAndAlerts'),
                        initParams: {
                            defaultTab: defaultTab,
                            showCloseOnSave: false
                        },
                        width: 1400,
                        height: 515,
                        modal: false
                    });
                }

                function showSupport() {
                    vm.wnd = WindowFactory.openItem({
                        component: 'support',
                        caption: xlatSvc.xlat('login.SupportTitle'),
                        initParams: {
                        },
                        width: browserSvc.isMobile ? 650 : 450,
                        top: browserSvc.isMobile ? amtConstants.windowTopMarginMobile : null,
                        modal: true,
                        onDataChangeHandler: vm.windowClose
                    });

                    vm.wnd.onClose = vm.windowClose;
                }

                vm.windowClose = function () {
                    WindowFactory.closeWindow(vm.wnd);
                    vm.wnd = null;
                };

                function showUserProfile() {
                    WindowFactory.openItem({
                        component: 'user-details',
                        caption: xlatSvc.xlat('userSecurity.open', ocConfigSvc.user.name),
                        initParams: {
                            mode: 'open',
                            personId: ocConfigSvc.user.personId,
                            name: ocConfigSvc.user.name,
                            section: 'usersettings'
                        },
                        modal: false,
                        width: 1050,
                        height: browserSvc.isMobile ? 600 : 650,
                        isMobile: browserSvc.isMobile
                    });
                }

                // If the current state has set a redirect then we go to the redirection 
                function tryToRedirectTransition() {
                    var currentState = $state.current;
                    if (currentState && currentState.redirectTo) {
                        //console.log('Redirecting to new state');
                        $state.go(currentState.redirectTo as any, $state.params);
                    }
                }

                function getQueryString(name) {
                    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
                    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
                        results = regex.exec(location.search);
                    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
                }

                function alertInactive() {
                    inactiveTime = null;
                    dataBroker.sessionExpire();
                }

                function activity() {

                    var now = new Date();

                    // check if user inactive too long, if so lock the screen
                    if (inactiveTime && (now > inactiveTime)) {
                        // lock now.
                        alertInactive();
                        return;
                    }

                    // clear existing inactive timer
                    if (alertInactiveTimer) {
                        $timeout.cancel(alertInactiveTimer);
                    }

                    // start a new inactive timer
                    alertInactiveTimer = $timeout(alertInactive, vm.inactiveTime);

                    // move the inactive time relative to current time
                    inactiveTime = new Date(new Date().getTime() + vm.inactiveTime);
                }

                // menu functions
                function getMenuItems(menu?) {

                    // SETTINGS
                    if (!menu || menu === 'settings') {
                        $scope.menuItems = {
                            settings: [
                                constructMenuItem(1, 'users', 'menu.users', 'app.admin.users', 'Security.Settings.Users', 'readOnly', null),
                                constructMenuItem(1, 'forecasts', 'menu.forecasts', 'app.admin.newTyrePositionDefaults', 'Security.Settings.Forecast', 'readOnly', null),
                                constructMenuItem(1, 'stockCodes', 'menu.settingsStockCodes', 'app.admin.stockCodes', 'Security.Settings.StockCodes', 'readOnly', null),
                                constructMenuItem(1, 'performanceBatches', 'menu.settingsPerformanceBatches', 'app.admin.performanceBatches', 'Security.Settings.PerformanceBatches', 'readOnly', null),
                                constructMenuItem(1, 'backupStockLevels', 'menu.settingsBackupStockLevels', 'app.admin.backupStockLevels', 'Security.Settings.BackupStockLevels', 'readOnly', null),
                                constructMenuItem(1, 'componentOwner', 'menu.settingsComponentOwner', 'app.admin.componentowner', 'Security.Settings.ComponentOwner', 'readOnly', null),
                                constructMenuItem(1, 'fleets', 'menu.settingsFleets', 'app.admin.fleets', 'Security.Settings.Fleets', 'readOnly', null),
                                constructMenuItem(1, 'alerts', 'menu.alertsTriggers', 'app.admin.alerts', 'Security.Settings.AlertsNotifications', 'readOnly', null),
                                constructMenuItem(1, 'fsFrequency', 'menu.FieldSurveyFrequency', 'app.admin.fsFrequency', 'Security.Settings.FieldSurveyFrequency', 'readOnly', null),
                                constructMenuItem(1, 'locations', 'menu.settingsLocations', 'app.admin.locations', 'Security.Settings.Location', 'readOnly', null),
                                constructMenuItem(1, 'productionCrews', 'menu.settingsProductionCrew', 'app.admin.productionCrews', 'Security.Settings.ProductionCrews', 'readOnly', null),
                                constructMenuItem(1, 'sensors', 'menu.settingsSensors', 'app.admin.sensors', 'Security.Settings.Sensors', 'readOnly', null),
                                constructMenuItem(1, 'siteDashboard', 'menu.settingsSiteDashboard', 'app.admin.siteDashboardConfiguration', 'Security.Settings.SiteDashboard', 'readOnly', null),
                                constructMenuItem(1, 'siteDetails', 'menu.settingsSiteDetails', 'app.admin.siteDetails', 'Security.Settings.SiteDetails', 'readOnly', null),
                                constructMenuItem(1, 'specificationsVehicles', 'menu.settingsSpecificationsVehicles', 'app.admin.specificationsVehicles', 'Security.Settings.SpecificationsVehicles', 'readOnly', null),
                                constructMenuItem(1, 'specificationsComponents', 'menu.settingsSpecificationsComponents', 'app.admin.specificationsComponents', 'Security.Settings.SpecificationsComponents', 'readOnly', null),

                                constructMenuItem(2, 'system', 'menu.settingsOrganisation', 'app.admin.sysOrg', $scope.app.user.isSystemAdmin, null, [
                                    constructMenuItem(1, 'sysOrgClient', 'menu.sysOrgClient', 'app.admin.sysOrgClient', $scope.app.user.isSystemAdmin, null, null, 1),
                                    constructMenuItem(1, 'sysOrgSite', 'menu.sysOrgSite', 'app.admin.sysOrgSite', $scope.app.user.isSystemAdmin, null, null, 2),
                                    constructMenuItem(1, 'sysOrgOperationType', 'menu.sysOrgOperationType', 'app.admin.sysOrgOperationType', $scope.app.user.isSystemAdmin, null, null, 3),
                                    constructMenuItem(1, 'sysOrgCommodity', 'menu.sysOrgCommodity', 'app.admin.sysOrgCommodity', $scope.app.user.isSystemAdmin, null, null, 4),
                                    constructMenuItem(1, 'sysOrgCurrency', 'menu.sysOrgCurrency', 'app.admin.sysOrgCurrency', $scope.app.user.isSystemAdmin, null, null, 5),
                                    constructMenuItem(1, 'sysOrgCompany', 'menu.sysOrgCompany', 'app.admin.sysOrgCompany', $scope.app.user.isSystemAdmin, null, null, 6)
                                ], 1),

                                constructMenuItem(2, 'system', 'menu.settingsVehicle', 'app.admin.sysVeh', $scope.app.user.isSystemAdmin, null, [
                                    constructMenuItem(1, 'sysVehApplication', 'menu.sysVehApplication', 'app.admin.sysVehApplication', $scope.app.user.isSystemAdmin, null, null, 1)
                                ], 2),

                                constructMenuItem(2, 'system', 'menu.settingsComponent', 'app.admin.sysComp', $scope.app.user.isSystemAdmin, null, [
                                    constructMenuItem(1, 'sysCompSize', 'menu.sysCompSize', 'app.admin.sysCompSize', $scope.app.user.isSystemAdmin, null, null, 1),
                                    constructMenuItem(1, 'sysCompPattern', 'menu.sysCompPattern', 'app.admin.sysCompPattern', $scope.app.user.isSystemAdmin, null, null, 2),
                                    constructMenuItem(1, 'sysCompCompound', 'menu.sysCompCompound', 'app.admin.sysCompCompound', $scope.app.user.isSystemAdmin, null, null, 3),
                                    constructMenuItem(1, 'sysCompPlyRating', 'menu.sysCompPlyRating', 'app.admin.sysCompPlyRating', $scope.app.user.isSystemAdmin, null, null, 4),
                                    constructMenuItem(1, 'sysCompTRACode', 'menu.sysCompTRACode', 'app.admin.sysCompTRACode', $scope.app.user.isSystemAdmin, null, null, 5)
                                ], 3),

                                constructMenuItem(2, 'system', 'menu.settingsMaintenance', 'app.admin.sysMaint', $scope.app.user.isSystemAdmin, null, [
                                    constructMenuItem(1, 'sysMaintAction', 'menu.sysMaintAction', 'app.admin.sysMaintAction', $scope.app.user.isSystemAdmin, null, null, 1),
                                    constructMenuItem(1, 'sysMaintDamageCause', 'menu.sysMaintDamageCause', 'app.admin.sysMaintDamageCause', $scope.app.user.isSystemAdmin, null, null, 2),
                                    constructMenuItem(1, 'sysMaintDamageLocation', 'menu.sysMaintDamageLocation', 'app.admin.sysMaintDamageLocation', $scope.app.user.isSystemAdmin, null, null, 3),
                                    constructMenuItem(1, 'sysMaintDamageSource', 'menu.sysMaintDamageSource', 'app.admin.sysMaintDamageSource', $scope.app.user.isSystemAdmin, null, null, 4),
                                    constructMenuItem(1, 'sysMaintDelayReason', 'menu.sysMaintDelayReason', 'app.admin.sysMaintDelayReason', $scope.app.user.isSystemAdmin, null, null, 5),
                                    constructMenuItem(1, 'sysMaintRemovalReason', 'menu.sysMaintRemovalReason', 'app.admin.sysMaintRemovalReason', $scope.app.user.isSystemAdmin, null, null, 6)
                                ], 4),

                                constructMenuItem(2, 'system', 'menu.settingsSystem', 'app.admin.sysSys', $scope.app.user.isSystemAdmin, null, [
                                    constructMenuItem(1, 'sysSystemAlerts', 'menu.systemAlerts', 'app.admin.sysSystemAlerts', $scope.app.user.isSystemAdmin, null, null, 1),
                                    constructMenuItem(1, 'sysRolesPermissions', 'menu.rolePermissions', 'app.admin.sysSysRolesPermissions', $scope.app.user.isSystemAdmin, null, null, 2),
                                    constructMenuItem(1, 'sysResource', 'menu.sysResource', 'app.admin.sysSysResource', $scope.app.user.isSystemAdmin, null, null, 3),
                                    constructMenuItem(1, 'sysReportIntegration', 'menu.sysReportIntegration', 'app.admin.sysSysReportIntegration', $scope.app.user.isSystemAdmin, null, null, 4)
                                ], 5)
                            ]
                        };
                    }

                    // REPORTS
                    if (!menu || menu === 'reports') {
                        $scope.menuItems.reports = null;

                        if (ocConfigSvc.reports && ocConfigSvc.reports.dashboards.length > 0) {
                            $scope.menuItems.reports = ocConfigSvc.reports.dashboards.map(r =>
                                constructMenuItem(1, r.name, r.description || r.name, null, null, null, (r.children || []).map(c =>
                                    constructMenuItem(1, c.name, c.description || c.name, 'app.reports.reportpage', null, null, null, null, { url: c.url, name: c.name, description: c.description }))));
                        }
                    }
                }

                function constructMenuItem(groupSequence, id, textResource, state, permission, permissionLevel, childItems, sequence?, navData?) {
                    return {
                        id: id,
                        groupSequence: groupSequence,
                        sequence: sequence,
                        state: state,
                        text: xlatSvc.xlat(textResource), //assumes xlat will return it's key if not found
                        visible: (permission === true) || (permission && permissionLevel ? ocSecuritySvc.isAuthorised(permission, permissionLevel) : (permission === null)),
                        childItems: childItems,
                        navData: navData
                    };
                }

            }]);
