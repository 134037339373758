/**
* @ngdoc directive
* @name amtFramework.formControls.directive:amtMonthpicker
* @restrict E
* 
* @param {expression} ngModel Binds to a year month object 
* <pre>
* {
*   year: 2015, 
*   month: 1
* }
* </pre>
* @param {expression}  isRequired Bool Required
* @param {expression} isDisabled Bool Disabled
* @param {number} minYear Minimum year
* @param {number} maxYear Minimum Month
* @param {number} minMonth Maximum Year
* @param {number} maxMonth Maximum Month
* @param {expression=} laterThenDate To do validation compared to another date control
* @param {expression=} earlierThenDate To do validation compared to another date control
* @param {expression=} isEqual ???
* @description
*
* @example
* <doc:example module="amtFramework">
*   <doc:source>
*       <amt-monthpicker
*           ng-model="myYearMonth"
*           is-required="true"
*           is-disabled="false"
*           min-year="2000"
*           min-month="2"
*           max-year="2020"
*           max-month="12">
*       </amt-monthpicker>
*   </doc:source>
* </doc:example>
*/

//import angular from 'angular';
import tmpl from './amtMonthpicker.html';

angular.module('amtFramework.formControls').directive('amtMonthpicker', [
    function () {
            return {
                require: ['ngModel', '^?form'],
                restrict: 'E',
                scope: {
                    model: '=ngModel',
                    isRequired: '=?',
                    isDisabled: '=?',
                    minYear: '=?',
                    minMonth: '=?',
                    maxYear: '=?',
                    maxMonth: '=?',
                    laterThenDate: '=?',
                    earlierThenDate: '=?',
                    isEqual: '=?',
                    isPickonly: '=?'
                },
                template: tmpl,
           
                link: function (scope: any, element, attrs, ctrls) {
                    if (!scope.model) {
                        var now = new Date();
                        //scope.model = { month: now.getMonth() + 1, year: now.getFullYear() };
                        scope.model = new Date();
                    }

                    scope.$watch('minYear', updateMinMax);
                    scope.$watch('minMonth', updateMinMax);
                    scope.$watch('maxYear', updateMinMax);
                    scope.$watch('maxMonth', updateMinMax);

                    function updateMinMax()
                    {

                        scope.minYearInt = scope.minYear || 2000;
                        scope.maxYearInt = scope.maxYear || 2025;

                        scope.minMonthInt = (scope.minMonth || 1) - 1;
                        scope.maxMonthInt = (scope.maxMonth || 12) - 1;

                        scope.minDate = new Date(scope.minYearInt, scope.minMonthInt, 1);
                        scope.maxDate = new Date(scope.maxYearInt, scope.maxMonthInt, 1);
                    }
                }
            };
    }
]);
