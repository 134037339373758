import { Inject, Injectable } from "@angular/core";
import { downgradeInjectable } from "@angular/upgrade/static";
import { Xlat } from "../amt/i18n/xlat";

export interface IShowEquipmentDetailsCriteria {
    equipmentId: guid;
    siteId: guid;
    onDataChanged?: () => void;
}

@Injectable({
    providedIn: 'root'
})
export default class HelperSvc {

    constructor(@Inject('WindowFactory') private WindowFactory: IWindowFactory, private amtXlatSvc: Xlat) { }

    showComponentDetails(criteria: IShowEquipmentDetailsCriteria) {
        this.WindowFactory.openItem({
            component: 'component-details',
            caption: this.amtXlatSvc.xlat('component.componentDetail'),
            windowRelatedRecordId: criteria.equipmentId,
            initParams: {
                equipmentId: criteria.equipmentId,
                siteId: criteria.siteId,
                showCloseOnSave: false
            },
            width: 800,
            height: 850,
            modal: false,
            onDataChangeHandler: criteria.onDataChanged
        });
    }

    showVehicleDetails(criteria: IShowEquipmentDetailsCriteria) {
        this.WindowFactory.openItem({
            component: 'vehicle-details',
            caption: this.amtXlatSvc.xlat('vehicle.vehicleDetail'),
            windowRelatedRecordId: criteria.equipmentId,
            initParams: {
                equipmentId: criteria.equipmentId,
                siteId: criteria.siteId
            },
            width: 800,
            height: 850,
            modal: false,
            onDataChangeHandler: criteria.onDataChanged
        });
    }

    getKey(object) {

        if (object !== null && object !== undefined) {
            if (object.key !== undefined || object.id !== undefined) {
                return (object.key || object.id);
            }
        }

        return null;
    }

    getKeys(collection) {
        if (collection === null || collection === undefined) {
            return null;
        }

        let keys = [];

        for (let i = 0; i < collection.length; i++) {
            if (collection[i].key !== undefined || collection[i].id !== undefined) {
                keys.push(collection[i].key || collection[i].id);
            }
        }

        return keys;
    }

    notNullOrUndefined(item) {
        return item != null;
    }

    getElementById(element, id) {
        if (id) {

            if (id.charAt(0) !== '#') {
                id = '#' + id;
            }

            return element.find(id);
        } else {
            return null;
        }
    }

    getPastedValues(ev) {

        let values = null;

        if (ev && ev.originalEvent) {

            let data = ev.originalEvent.clipboardData.getData('text');

            if (data) {

                values = data.trimEnd().split("\n");

                if (values && values.length > 0) {

                    values = values.map(function (r) {

                        // split for excel
                        let rowValues = r.split("\t");

                        // if splitting for excel didn't actually split anything, try splitting for standard csv
                        if (!rowValues || rowValues.length === 1) {
                            rowValues = r.split(",");
                        }

                        return rowValues;
                    });
                }
            }
        }

        return values;
    }

    stringEquals(val1: string, val2: string) {
        if (!val1 || !val2) return false;

        return val1.localeCompare(val2) === 0;
    }

    frequencyDaysToText(days?: number) {

        if (!days)
            return null;

        let text;

        switch (days) {
            case 1:
                text = this.amtXlatSvc.xlat('frequency.daily');
                break;
            case 7:
                text = this.amtXlatSvc.xlat('frequency.weekly');
                break;
            case 30:
                text = this.amtXlatSvc.xlat('frequency.monthly');
                break;
            default:
                text = this.amtXlatSvc.xlat('frequency.days', days);
                break;
        }

        return text;
    }
}

angular.module('app').factory('helperSvc', downgradeInjectable(HelperSvc) as any);