//import angular from 'angular';

angular.module('amtFramework.validation').directive('amtDateVal', function () {
         return {
             require: 'ngModel',
             link: function (scope: any, element, attrs, ngModelCtrl) {
                 scope.minDate = new Date(2000, 1, 1);
                 scope.maxDate = new Date(2050, 1, 1);

                 var dateValidator = function (value) {
                     if (!value) {
                         return undefined;
                     }
                     if (value > scope.maxDate || value < scope.minDate) {
                         ngModelCtrl.$setValidity('dateValue', false);
                         return undefined;
                     } else {
                         ngModelCtrl.$setValidity('dateValue', true);
                         return value;
                     }
                 };

                 ngModelCtrl.$parsers.push(dateValidator);
                 ngModelCtrl.$formatters.push(dateValidator);
             }
         };
});
