/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var kendo_licensing_1 = require("@progress/kendo-licensing");
var package_metadata_1 = require("../package-metadata");
/**
 * Represents the [Kendo UI Card component for Angular]({% slug overview_card %})
 */
var CardComponent = /** @class */ (function () {
    function CardComponent(localizationService) {
        var _this = this;
        this.localizationService = localizationService;
        this.hostClass = true;
        /**
         * Specifies the layout of the Card content.
         *
         * The possible values are:
         * * (Default) `vertical`
         * * `horizontal`
         *
         */
        this.orientation = 'vertical';
        /**
         * Defines the width of the Card.
         * Defaults to `285px`.
         */
        this.width = '285px';
        this.rtl = false;
        kendo_licensing_1.validatePackage(package_metadata_1.packageMetadata);
        this.dynamicRTLSubscription = this.localizationService.changes.subscribe(function (_a) {
            var rtl = _a.rtl;
            _this.rtl = rtl;
            _this.direction = _this.rtl ? 'rtl' : 'ltr';
        });
    }
    Object.defineProperty(CardComponent.prototype, "widthStyle", {
        get: function () {
            return this.width;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CardComponent.prototype, "vertical", {
        get: function () {
            return this.orientation === 'vertical';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CardComponent.prototype, "horizontal", {
        get: function () {
            return this.orientation === 'horizontal';
        },
        enumerable: true,
        configurable: true
    });
    CardComponent.prototype.ngOnDestroy = function () {
        if (this.dynamicRTLSubscription) {
            this.dynamicRTLSubscription.unsubscribe();
        }
    };
    tslib_1.__decorate([
        core_1.HostBinding('class.k-widget'),
        core_1.HostBinding('class.k-card'),
        tslib_1.__metadata("design:type", Boolean)
    ], CardComponent.prototype, "hostClass", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('style.width'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], CardComponent.prototype, "widthStyle", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-card-vertical'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], CardComponent.prototype, "vertical", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-card-horizontal'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], CardComponent.prototype, "horizontal", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.dir'),
        tslib_1.__metadata("design:type", String)
    ], CardComponent.prototype, "direction", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], CardComponent.prototype, "orientation", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], CardComponent.prototype, "width", void 0);
    CardComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-card',
            providers: [
                kendo_angular_l10n_1.LocalizationService,
                {
                    provide: kendo_angular_l10n_1.L10N_PREFIX,
                    useValue: 'kendo.card.component'
                }
            ],
            template: "\n        <ng-content></ng-content>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService])
    ], CardComponent);
    return CardComponent;
}());
exports.CardComponent = CardComponent;
