//import angular from 'angular';


    angular.module('app').filter('defaultVal', defaultValFilter);

    // @ts-ignore
    defaultValFilter.$inject = [];
    function defaultValFilter() {

        return function (inputString, defaultVal) {
            return (inputString && inputString !== 0) ? inputString : defaultVal;
        };
    }
