/**
 * @ngdoc directive
 * @name amtFramework.filter.directive:amtDateFilteritem
 * @sortOrder 9
 * @restrict E
 * @requires amtFramework.filter.directive:amtFilter
 * 
 * @param {string} label Label to display above the control
 * @param {string} propertyName Name of the property that is added to filterValues property of {@link amtFramework.filter.directive:amtFilter amtFilter}
 * @param {expression=} [isRequired=false] Required
 * @param {string=} defaultValue Sets the default value when loading
 *
 * @description
 * Filter item that let's the user filter on a date value. Only adds the value to filtervalues when the user selects a value.
 * 
 * @example
 * See {@link amtFramework.filter.directive:amtFilter amtFilter}
 */
//import angular from 'angular';
import tmpl from './amtDateFilteritem.html';

angular.module('amtFramework.filter').directive('amtDateFilteritem', function () {
            return {
                require: '^amtFilter',
                restrict: 'E',
                scope: {
                    label: '@',
                    propertyName: "@",
                    mode: "=?",
                    isRequired: '=',
                    defaultValue: '=?'
                },
                template: tmpl,
                link: function (scope: any, elem, attr, filterCtrl) {
                    scope.filterItem = { propertyName: scope.propertyName };

                    if (attr.mode) {
                        scope.mode = attr.mode;
                    }
                    else
                    {
                        scope.mode = 'day';
                    }

                    scope.$watch('filterItem.value', function (newValue, oldValue) {
                        if (scope.defaultValue !== newValue) {
                            scope.defaultValue = newValue;
                        }
                    });

                    scope.$watch('defaultValue', function (newValue, oldValue) {
                        if (scope.filterItem.value !== newValue) {
                            scope.filterItem.value = newValue;
                        }
                    });

                    filterCtrl.addFilterItem(scope.filterItem);
                }
            };
});
