//import angular from 'angular';

    angular.module('app.directives')
        .directive('ocCancel', function () {
            return function (scope, element, attrs) {
                element.bind("keydown keypress keyup", function (event) {
                    if (event.which === 27) {

                        scope.$apply(function () {
                            scope.$eval(attrs.ocCancel);
                        });

                        event.preventDefault();
                    }
                });
            };
        });
