/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
/**
 * @hidden
 */
var ExpandStateService = /** @class */ (function () {
    function ExpandStateService() {
        this.changes = new rxjs_1.Subject();
    }
    ExpandStateService.prototype.expand = function (index, dataItem) {
        this.changes.next({ dataItem: dataItem, index: index, expand: true });
    };
    ExpandStateService.prototype.collapse = function (index, dataItem) {
        this.changes.next({ dataItem: dataItem, index: index, expand: false });
    };
    ExpandStateService = tslib_1.__decorate([
        core_1.Injectable()
    ], ExpandStateService);
    return ExpandStateService;
}());
exports.ExpandStateService = ExpandStateService;
