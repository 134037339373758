//import angular from 'angular';


    angular.module('app.directives')

        .directive('ocTouchMove', function () {
            return {
                link: function (scope, element, attrs) {
                    // again we need the native object
                    var el = element[0];

                    var ocTouchMove = scope.$eval(attrs.ocTouchMove);

                    el.addEventListener(
                        'mousemove',
                        function (e) {
                            if (ocTouchMove) {
                                e.preventDefault();
                                ocTouchMove.call(scope, e);
                            }
                            return false;
                        },
                        false
                    );

                    el.addEventListener(
                        'touchmove',
                        function (e) {
                            if (ocTouchMove) {
                                e.preventDefault();
                                ocTouchMove.call(scope, e);
                            }
                            return false;
                        },
                        false
                    );
                }
            }
        })

        .directive('ocTouchEnd', function () {
            return {
                link: function (scope, element, attrs) {
                    // again we need the native object
                    var el = element[0];

                    var ocTouchEnd = scope.$eval(attrs.ocTouchEnd);

                    el.addEventListener(
                        'mouseup',
                        function (e) {
                            if (ocTouchEnd) {
                                e.preventDefault();
                                ocTouchEnd.call(scope, e);
                            }
                            return false;
                        },
                        false
                    );

                    el.addEventListener(
                        'touchend',
                        function (e) {
                            if (ocTouchEnd) {
                                e.preventDefault();
                                ocTouchEnd.call(scope, e);
                            }
                            return false;
                        },
                        false
                    );
                }
            }
        })

        .directive('ocTouchStart', function () {
            return {
                link: function (scope, element, attrs) {
                    // again we need the native object
                    var el = element[0];

                    var ocTouchStart = scope.$eval(attrs.ocTouchStart);

                    el.addEventListener(
                        'mousedown',
                        function (e) {
                            if (ocTouchStart) {
                                e.preventDefault();
                                ocTouchStart.call(scope, e);
                            }
                            return false;
                        },
                        false
                    );

                    el.addEventListener(
                        'touchstart',
                        function (e) {
                            if (ocTouchStart) {
                                e.preventDefault();
                                ocTouchStart.call(scope, e);
                            }
                            return false;
                        },
                        false
                    );
                }
            }
        })
