import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";


@Pipe({
    name: 'xlat',
    pure: true //for now only update when inputs to the pipe change (won't update when language changes, doesn't seem worth wasting coding time or machine cycles on an impure pipe while most of the site is AngularJS)
})
export class XlatPipe implements PipeTransform {
    constructor(
        private translateService: TranslateService
    ) { }

    transform(value: string, ...args: any[]) {
        return this.translateService.instant(value, args);
    }
}