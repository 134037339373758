/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
/**
 * @hidden
 */
var DialItemComponent = /** @class */ (function () {
    function DialItemComponent(element, renderer, localisationService) {
        this.element = element;
        this.renderer = renderer;
        this.localisationService = localisationService;
        this.hostClass = true;
        this.role = 'menuitem';
    }
    Object.defineProperty(DialItemComponent.prototype, "disabledClass", {
        get: function () {
            return this.item.disabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialItemComponent.prototype, "title", {
        get: function () {
            var label = this.item.label;
            return label ? label : this.itemTitle;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialItemComponent.prototype, "indexAttr", {
        get: function () {
            return this.index;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialItemComponent.prototype, "iconClasses", {
        get: function () {
            var classes = [];
            if (this.item.iconClass) {
                classes.push("" + this.item.iconClass);
            }
            if (this.item.icon) {
                classes.push("k-fab-item-icon k-icon k-i-" + this.item.icon);
            }
            return classes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialItemComponent.prototype, "itemTitle", {
        get: function () {
            var icon = this.item.icon;
            var itemTitle = this.item.itemTitle;
            return (icon && itemTitle) ? itemTitle : icon;
        },
        enumerable: true,
        configurable: true
    });
    DialItemComponent.prototype.ngAfterViewInit = function () {
        var element = this.element.nativeElement;
        var rtl = this.localisationService.rtl;
        var hAlign = this.align.horizontal;
        this.renderer.addClass(element, this.getTextDirectionClass(rtl, hAlign));
    };
    DialItemComponent.prototype.getTextDirectionClass = function (rtl, hAlign) {
        var dir = rtl ? 'rtl' : 'ltr';
        var align = hAlign === 'end' ? 'end' : 'start';
        var directions = {
            rtl: { end: 'k-text-left', start: 'k-text-right' },
            ltr: { start: 'k-text-left', end: 'k-text-right' }
        };
        return directions[dir][align];
    };
    tslib_1.__decorate([
        core_1.HostBinding('class.k-fab-item'),
        tslib_1.__metadata("design:type", Boolean)
    ], DialItemComponent.prototype, "hostClass", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('attr.role'),
        tslib_1.__metadata("design:type", String)
    ], DialItemComponent.prototype, "role", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('attr.aria-disabled'),
        core_1.HostBinding('class.k-disabled'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], DialItemComponent.prototype, "disabledClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.title'),
        core_1.HostBinding('attr.aria-label'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], DialItemComponent.prototype, "title", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.data-fab-item-index'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], DialItemComponent.prototype, "indexAttr", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], DialItemComponent.prototype, "cssClass", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], DialItemComponent.prototype, "cssStyle", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], DialItemComponent.prototype, "isFocused", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], DialItemComponent.prototype, "index", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], DialItemComponent.prototype, "item", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", core_1.TemplateRef)
    ], DialItemComponent.prototype, "dialItemTemplate", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], DialItemComponent.prototype, "align", void 0);
    DialItemComponent = tslib_1.__decorate([
        core_1.Component({
            selector: '[kendoDialItem]',
            template: "\n        <ng-template *ngIf=\"dialItemTemplate\"\n            [ngTemplateOutlet]=\"dialItemTemplate\"\n            [ngTemplateOutletContext]=\"{ $implicit: item, index: index, isFocused: isFocused }\"\n        >\n        </ng-template>\n\n        <ng-container *ngIf=\"!dialItemTemplate\">\n            <span *ngIf=\"item.label\" class=\"k-fab-item-text\">{{ item.label }}</span>\n            <span *ngIf=\"item.icon || item.iconClass\" [ngClass]=\"iconClasses\"></span>\n        </ng-container>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.ElementRef,
            core_1.Renderer2,
            kendo_angular_l10n_1.LocalizationService])
    ], DialItemComponent);
    return DialItemComponent;
}());
exports.DialItemComponent = DialItemComponent;
