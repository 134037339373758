/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Represents a template that defines the content of the ExpansionPanel title.
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoExpansionPanelTitleDirective` directive inside the `<kendo-expansionpanel>` tag.
 * ([see example]({% slug title_expansionpanel %}#toc-title-template)).
 */
var ExpansionPanelTitleDirective = /** @class */ (function () {
    function ExpansionPanelTitleDirective(templateRef) {
        this.templateRef = templateRef;
    }
    ExpansionPanelTitleDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoExpansionPanelTitleDirective]'
        }),
        tslib_1.__param(0, core_1.Optional()),
        tslib_1.__metadata("design:paramtypes", [core_1.TemplateRef])
    ], ExpansionPanelTitleDirective);
    return ExpansionPanelTitleDirective;
}());
exports.ExpansionPanelTitleDirective = ExpansionPanelTitleDirective;
