/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var dragging_service_1 = require("./dragging-service");
var rxjs_1 = require("rxjs");
var kendo_draggable_1 = require("@telerik/kendo-draggable");
var tilelayout_item_component_1 = require("./tilelayout-item.component");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var kendo_licensing_1 = require("@progress/kendo-licensing");
var package_metadata_1 = require("../package-metadata");
var util_1 = require("../common/util");
var autoFlowClasses = {
    column: 'k-grid-flow-col',
    row: 'k-grid-flow-row',
    'column-dense': 'k-grid-flow-col-dense',
    'row-dense': 'k-grid-flow-row-dense'
};
/**
 * Represents the [Kendo UI TileLayout component for Angular]({% slug overview_tilelayout %})
 */
var TileLayoutComponent = /** @class */ (function () {
    function TileLayoutComponent(elem, renderer, localization, draggingService, zone) {
        this.elem = elem;
        this.renderer = renderer;
        this.localization = localization;
        this.draggingService = draggingService;
        this.zone = zone;
        /**
         * Defines the number of columns ([see example]({% slug tiles_tilelayout %}#toc-tile-size-and-position)).
         * @default 1
         */
        this.columns = 1;
        /**
         * Determines the width of the columns. Numeric values are treated as pixels ([see example]({% slug tiles_tilelayout %}#toc-tile-size-and-position)).
         * @default '1fr'
         */
        this.columnWidth = '1fr';
        /**
         * Determines whether the reordering functionality will be enabled ([see example]({% slug reordering_tilelayout %})).
         * @default false
         */
        this.reorderable = false;
        /**
         * Determines whether the resizing functionality will be enabled ([see example]({% slug resizing_tilelayout %})).
         * @default false
         */
        this.resizable = false;
        /**
         * Determines the height of the rows. Numeric values are treated as pixels ([see example]({% slug tiles_tilelayout %}#toc-tile-size-and-position)).
         * @default '1fr'
         */
        this.rowHeight = '1fr';
        /**
         * Controls how the auto-placement algorithm works, specifying exactly how auto-placed items are flowed in the TileLayout ([see example]({% slug tiles_autoflow_tilelayout %})).
         * For further reference, check the [grid-auto-flow CSS article](https://developer.mozilla.org/en-US/docs/Web/CSS/grid-auto-flow).
         *
         * The possible values are:
         * * (Default) `column`
         * * `row`
         * * `row dense`
         * * `column dense`
         * * `none`
         *
         */
        this.autoFlow = 'column';
        /**
         * Fires when the user completes the reordering of the item ([see example]({% slug overview_tilelayout %}#toc-events)).
         * This event is preventable. If you cancel it, the item will not be reordered.
         */
        this.reorder = new core_1.EventEmitter();
        /**
         * Fires when the user completes the resizing of the item ([see example]({% slug overview_tilelayout %}#toc-events)).
         * This event is preventable. If you cancel it, the item will not be resized.
         */
        this.resize = new core_1.EventEmitter();
        this.hostClass = true;
        this.subs = new rxjs_1.Subscription();
        this._gap = {
            rows: 16,
            columns: 16
        };
        kendo_licensing_1.validatePackage(package_metadata_1.packageMetadata);
    }
    Object.defineProperty(TileLayoutComponent.prototype, "gap", {
        get: function () {
            return this._gap;
        },
        /**
         * The numeric values which determine the spacing in pixels between the layout items horizontally and vertically.
         * Properties:
         * * rows - the vertical spacing. Numeric values are treated as pixels. Defaults to `16`.
         * * columns - the horizontal spacing. Numeric values are treated as pixels. Defaults to `16`.
         *
         * When bound to a single numeric value, it will be set to both `rows` and `columns` properties.
         */
        set: function (value) {
            this._gap = (typeof value === 'number') ? { rows: value, columns: value } : Object.assign(this._gap, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TileLayoutComponent.prototype, "gapStyle", {
        get: function () {
            return this.gap.rows + "px " + this.gap.columns + "px";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TileLayoutComponent.prototype, "currentColStart", {
        get: function () {
            return this.draggingService.colStart;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TileLayoutComponent.prototype, "currentRowStart", {
        get: function () {
            return this.draggingService.rowStart;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TileLayoutComponent.prototype, "draggedItemWrapper", {
        get: function () {
            return this.draggingService.itemWrapper;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TileLayoutComponent.prototype, "targetOrder", {
        get: function () {
            return this.draggingService.order;
        },
        enumerable: true,
        configurable: true
    });
    TileLayoutComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.applyColStyling();
        this.applyRowStyling();
        this.draggingService.reorderable.next(this.reorderable);
        this.draggingService.resizable.next(this.resizable);
        if (kendo_angular_common_1.hasObservers(this.reorder)) {
            this.subs.add(this.draggingService.reorder.subscribe(function (e) { return _this.reorder.emit(e); }));
        }
        if (kendo_angular_common_1.hasObservers(this.resize)) {
            this.subs.add(this.draggingService.resize.subscribe(function (e) { return _this.resize.emit(e); }));
        }
        this.subs.add(this.draggingService.reorderable.subscribe(function (reorderable) {
            if (reorderable && !_this.draggable) {
                _this.initializeDraggable();
            }
        }));
        this.subs.add(this.draggingService.resizable.subscribe(function (resizable) {
            if (resizable && !_this.draggable) {
                _this.initializeDraggable();
            }
        }));
        this.subs.add(this.localization.changes.subscribe(function (_a) {
            var rtl = _a.rtl;
            _this.direction = rtl ? 'rtl' : 'ltr';
        }));
    };
    TileLayoutComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.draggingService.tileLayoutSettings = this.draggingServiceConfig();
        this.applyAutoFlow(null, autoFlowClasses[this.autoFlow]);
        this.items.changes.subscribe(function () {
            _this.setItemsOrder();
            _this.draggingService.tileLayoutSettings.items = _this.items.toArray();
        });
    };
    TileLayoutComponent.prototype.ngAfterContentInit = function () {
        this.setItemsOrder();
    };
    TileLayoutComponent.prototype.ngOnChanges = function (changes) {
        if (changes.columns || changes.columnWidth) {
            this.applyColStyling();
        }
        if (changes.rowHeight) {
            this.applyRowStyling();
        }
        if (changes.reorderable) {
            this.draggingService.reorderable.next(changes.reorderable.currentValue);
        }
        if (changes.resizable) {
            this.draggingService.resizable.next(changes.resizable.currentValue);
        }
        if (changes.gap || changes.autoFlow || changes.columns) {
            this.draggingService.tileLayoutSettings = this.draggingServiceConfig();
            if (changes.autoFlow) {
                this.applyAutoFlow(autoFlowClasses[changes.autoFlow.previousValue] || '', autoFlowClasses[changes.autoFlow.currentValue]);
            }
        }
    };
    TileLayoutComponent.prototype.ngOnDestroy = function () {
        if (this.draggable) {
            this.draggable.destroy();
        }
        this.subs.unsubscribe();
    };
    TileLayoutComponent.prototype.handlePress = function (_a) {
        var originalEvent = _a.originalEvent;
        this.draggingService.handlePress(originalEvent);
    };
    TileLayoutComponent.prototype.handleDrag = function (_a) {
        var originalEvent = _a.originalEvent;
        this.draggingService.handleDrag(originalEvent);
    };
    TileLayoutComponent.prototype.handleRelease = function (_a) {
        var originalEvent = _a.originalEvent;
        this.draggingService.handleRelease(originalEvent);
    };
    TileLayoutComponent.prototype.applyColStyling = function () {
        var colWidth = typeof this.columnWidth === 'number' ? this.columnWidth + "px" : this.columnWidth;
        var gridTemplateColumnsStyle = "repeat(" + this.columns + ", " + colWidth + ")";
        this.renderer.setStyle(this.elem.nativeElement, 'grid-template-columns', gridTemplateColumnsStyle);
    };
    TileLayoutComponent.prototype.applyRowStyling = function () {
        var rowHeight = typeof this.rowHeight === 'number' ? this.rowHeight + "px" : this.rowHeight;
        var gridAutoRowsStyle = "" + rowHeight;
        this.renderer.setStyle(this.elem.nativeElement, 'grid-auto-rows', gridAutoRowsStyle);
    };
    TileLayoutComponent.prototype.draggingServiceConfig = function () {
        return {
            tileLayoutElement: this.elem ? this.elem.nativeElement : undefined,
            hintElement: this.hint ? this.hint.nativeElement : undefined,
            gap: this.gap,
            columns: this.columns,
            autoFlow: this.autoFlow,
            items: this.items ? this.items.toArray() : []
        };
    };
    TileLayoutComponent.prototype.initializeDraggable = function () {
        var _this = this;
        this.draggable = new kendo_draggable_1.default({
            press: this.handlePress.bind(this),
            drag: this.handleDrag.bind(this),
            release: this.handleRelease.bind(this)
        });
        this.zone.runOutsideAngular(function () { return _this.draggable.bindTo(_this.elem.nativeElement); });
    };
    TileLayoutComponent.prototype.applyAutoFlow = function (classToRemove, classToAdd) {
        var element = this.elem.nativeElement;
        if (classToRemove) {
            this.renderer.removeClass(element, classToRemove);
        }
        if (this.autoFlow !== 'none' && util_1.isPresent(classToAdd)) {
            this.renderer.addClass(element, classToAdd);
        }
    };
    TileLayoutComponent.prototype.setItemsOrder = function () {
        this.items.forEach(function (item, index) {
            if (!util_1.isPresent(item.order)) {
                item.order = index;
            }
        });
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], TileLayoutComponent.prototype, "columns", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], TileLayoutComponent.prototype, "columnWidth", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object),
        tslib_1.__metadata("design:paramtypes", [Object])
    ], TileLayoutComponent.prototype, "gap", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], TileLayoutComponent.prototype, "reorderable", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], TileLayoutComponent.prototype, "resizable", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], TileLayoutComponent.prototype, "rowHeight", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], TileLayoutComponent.prototype, "autoFlow", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], TileLayoutComponent.prototype, "reorder", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], TileLayoutComponent.prototype, "resize", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-widget'),
        core_1.HostBinding('class.k-tilelayout'),
        tslib_1.__metadata("design:type", Boolean)
    ], TileLayoutComponent.prototype, "hostClass", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('style.gap'),
        core_1.HostBinding('style.padding'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], TileLayoutComponent.prototype, "gapStyle", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.dir'),
        tslib_1.__metadata("design:type", String)
    ], TileLayoutComponent.prototype, "direction", void 0);
    tslib_1.__decorate([
        core_1.ContentChildren(tilelayout_item_component_1.TileLayoutItemComponent),
        tslib_1.__metadata("design:type", core_1.QueryList)
    ], TileLayoutComponent.prototype, "items", void 0);
    tslib_1.__decorate([
        core_1.ViewChild('hint', { static: false }),
        tslib_1.__metadata("design:type", core_1.ElementRef)
    ], TileLayoutComponent.prototype, "hint", void 0);
    TileLayoutComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-tilelayout',
            providers: [
                kendo_angular_l10n_1.LocalizationService,
                dragging_service_1.TileLayoutDraggingService,
                {
                    provide: kendo_angular_l10n_1.L10N_PREFIX,
                    useValue: 'kendo.tilelayout.component'
                }
            ],
            template: "\n        <ng-content></ng-content>\n        <div #hint class=\"k-layout-item-hint k-layout-item-hint-reorder\"\n            [style.display]=\"'none'\"\n            [style.order]=\"targetOrder\"\n            [style.gridColumnEnd]=\"draggedItemWrapper?.style.gridColumnEnd\"\n            [style.gridRowEnd]=\"draggedItemWrapper?.style.gridRowEnd\"\n            [style.gridColumnStart]=\"currentColStart\"\n            [style.gridRowStart]=\"currentRowStart\"\n            [style.zIndex]=\"'1'\"></div>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.ElementRef,
            core_1.Renderer2,
            kendo_angular_l10n_1.LocalizationService,
            dragging_service_1.TileLayoutDraggingService,
            core_1.NgZone])
    ], TileLayoutComponent);
    return TileLayoutComponent;
}());
exports.TileLayoutComponent = TileLayoutComponent;
