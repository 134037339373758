import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Widget } from '../../service/Interface/SiteDashboard.interface';
import { ConditionMonitoringWidget } from '../../service/Interface/Widget.interface';
import { WidgetRxjsService } from '../../service/rxjs/WidgetRxjs.service';
import { WidgetConstant } from '../../service/widgetHelper/WidgetConstant';
import { Subscription } from 'rxjs';


@Component({
    selector: 'widget-condition-monitoring',
    templateUrl: './condition-monitoring.component.html',
    styleUrls: ['./../../site-dashboard.component.scss']
})


//Inprogress
export class SiteDashboardConditionMonitoringComponent implements OnInit, OnDestroy {
    //TODO: Add Transform Ui
    public siteDashboardWidgetId: string;
    public displayBySelectionList: Array<string>;
    public selectedDisplayBy: string;
    public resultItem: ConditionMonitoringWidget = {
        siteDashboardWidgetId: '',
        receivedResult: false,
        result: {
            displayBy: {
                isActionStatus: false,
                isLastInspectedDate: false,
                isRecommendedAction: false
            }
        }
    };

    public resultSubscription: Subscription;
    public apiErrorSubscription: Subscription;

    constructor(private widgetRxjs: WidgetRxjsService,private widgetConstant: WidgetConstant) {
        this.displayBySelectionList = this.widgetConstant.getConditionMoinotringSelectionList();
    }

    @Input() set getWidgetDetail(widget: Widget) {
        this.siteDashboardWidgetId = widget.siteDashboardWidgetId;
    }

    ngOnInit() {
        this.getConditionMonitoringWidgetResult();
    }

    ngOnDestroy() {
        this.resultSubscription.unsubscribe();
    }

    /**Set Condition Moniotring Result*/
    getConditionMonitoringWidgetResult() {
        //Subscribe API(s) Call
        this.resultSubscription =
            this.widgetRxjs.conditionMonitoringWidget.subscribe(
                widgets => {
                    let hasConditionMonitoring = widgets.length > 0;
                    if (hasConditionMonitoring) {
                        widgets.forEach(widget => {
                            if (widget.siteDashboardWidgetId === this.siteDashboardWidgetId) {
                                this.resultItem = widget;
                                this.setDefaultSelectedDisplayBy();
                            }
                        });
                    }
                });
    }

    /**Set Default Selection */
    setDefaultSelectedDisplayBy() {
        let displayBy = this.widgetConstant.getConditionMoinotringSelectionUi();
        this.selectedDisplayBy = this.resultItem.result.displayBy.isActionStatus ? displayBy.actionStatus
                               : this.resultItem.result.displayBy.isLastInspectedDate ? displayBy.lastInspectedDate
                               : this.resultItem.result.displayBy.isRecommendedAction ? displayBy.recommendedAction : '';
    }

    /**Change the Condition Moniotring for display By*/
    onChangeDisplayBy(selectedAction: string) {
        let displayBy = this.widgetConstant.getConditionMoinotringSelectionUi();
        this.resultItem.result.displayBy.isActionStatus = selectedAction === displayBy.actionStatus;
        this.resultItem.result.displayBy.isLastInspectedDate = selectedAction === displayBy.lastInspectedDate;
        this.resultItem.result.displayBy.isRecommendedAction = selectedAction === displayBy.recommendedAction;
    }

}