//import angular from 'angular';
import { AuthService } from '../services/auth.service';

angular.module('app.controllers').run([
    '$rootScope', 'authService', '$timeout', 'dataBroker',
    function ($rootScope: IRootScope, authService: AuthService, $timeout: ng.ITimeoutService, dataBroker: IDataBroker) {

        $rootScope.debug = window.debug;

        dataBroker.setCurrentLanguageFrom().then(() => {
            // can stored session be used, or do we display the login page?
            // these trigger the page that displays, and what controls are then loaded
            $rootScope.loggedIn = authService.isAuthenticated;
            $rootScope.loggingIn = !$rootScope.loggedIn;
            $timeout(); //TODO: promise patch from app.ts may not have been applied at this point
        });
    }
]);