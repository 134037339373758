import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable  } from 'rxjs';
import {
    LiveForecastRimWidget,PressureCheckWidget, PressureKPIWidget,
    ScrapLifeTrendWidget, SiteHealthRateElement, LiveForecastTyreWidget,
    ConditionMonitoringWidget,
    ErrorsInWidgetElement,
    CompletedChangeWidgetElement,
    TyreInInspectionElement,
    WidgetApiCallListener
} from '../Interface/Widget.interface';
import { WidgetApiNotificationService } from './../widgetHelper/WidgetApiNotification.helper';

@Injectable({
    providedIn: 'root'
})


export class WidgetRxjsService {

    private defaultSiteHealthRateElement: SiteHealthRateElement = {
        crPending: 0,
        dueNewFrontTyre: 0,
        dueRimNDT: 0,
        dueScrapRim: 0,
        fsError: 0,
        inspectTyre: 0,
        msError: 0,
        msPending: 0,
        stError: 0,
        viNotAct: 0
    };

    private defaultErrorsInWidget: ErrorsInWidgetElement = {
        componentReceive: 0,
        stocktakesInError: 0,
        fieldSurveyError: 0,
        maintenanceSessionError: 0,
        incompletedEndOfMonth: 0
    };

    private defaultChangeCompletedWidget: CompletedChangeWidgetElement = {
        scheduledMaintenanceSession: 0,
        changedCompletedMaintenanceSession: 0
    };

    private defaultTyreInInspectionWidget: TyreInInspectionElement = {
        inspectionCount: 0,
        scrappedMonthCount: 0
    };

    private defaultApiListener: WidgetApiCallListener = {
        currentApiIndex: 0,
        finalApiIndex: 0,
        receivedPreviousVersion: false,
        receviedFinalApiIndex: false
    };

    //Api Call Listener
    private widgetApiCallListenerService: BehaviorSubject<WidgetApiCallListener> = new BehaviorSubject<WidgetApiCallListener>(this.defaultApiListener);
    widgetApiCallListener: Observable<WidgetApiCallListener> = this.widgetApiCallListenerService.asObservable();

    //Completed Change Widget
    private completedChangeWidgetService: BehaviorSubject<CompletedChangeWidgetElement> = new BehaviorSubject<CompletedChangeWidgetElement>(this.defaultChangeCompletedWidget);
    completedChangeWidget: Observable<CompletedChangeWidgetElement> = this.completedChangeWidgetService.asObservable();

    //Total-Down Time Widget
    private totalDownTimeService: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    totalDownTime: Observable<number> = this.totalDownTimeService.asObservable();

    //Error Widget
    private errorWidgetService: BehaviorSubject<ErrorsInWidgetElement> = new BehaviorSubject<ErrorsInWidgetElement>(this.defaultErrorsInWidget);
    errorWidget: Observable<ErrorsInWidgetElement> = this.errorWidgetService.asObservable();


    //Tyre In Inspection Widget
    private tyreInspectionWidgetService: BehaviorSubject<TyreInInspectionElement> = new BehaviorSubject<TyreInInspectionElement>(this.defaultTyreInInspectionWidget);
    tyreInsepctionWidget: Observable<TyreInInspectionElement> = this.tyreInspectionWidgetService.asObservable();

    //Site Health Rating Widget
    private siteHealthRateService: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private siteHealthRateElementService: BehaviorSubject<SiteHealthRateElement> = new BehaviorSubject<SiteHealthRateElement>(this.defaultSiteHealthRateElement);
    siteHealthRateElement: Observable<SiteHealthRateElement> = this.siteHealthRateElementService.asObservable();
    siteHealthRate: Observable<number> = this.siteHealthRateService.asObservable();

    //Vehicle In Maintenance Widget
    private maintenanceSessionsInProgressService: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private maintenanceSessionsPendingService: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    maintenanceSessionsInProgress: Observable<number> = this.maintenanceSessionsInProgressService.asObservable();
    maintenanceSessionsPending: Observable<number> = this.maintenanceSessionsPendingService.asObservable();

    //Scrap Life Trend
    private scrapLifeTrendWidgetService: BehaviorSubject<Array<ScrapLifeTrendWidget>> = new BehaviorSubject<Array<ScrapLifeTrendWidget>>([]);
    scrapLifeTrendWidget: Observable<Array<ScrapLifeTrendWidget>> = this.scrapLifeTrendWidgetService.asObservable();

    //Pressure Kpi Widget
    private pressureKPIWidgetService: BehaviorSubject<Array<PressureKPIWidget>> = new BehaviorSubject<Array<PressureKPIWidget>>([]);
    pressureKPIWidget: Observable<Array<PressureKPIWidget>> = this.pressureKPIWidgetService.asObservable();

    //Live Forecast Rim Ndt Widget
    private liveForecastRimWidgetService: BehaviorSubject<Array<LiveForecastRimWidget>> = new BehaviorSubject<Array<LiveForecastRimWidget>>([]);
    liveForecastRimWidget: Observable<Array<LiveForecastRimWidget>> = this.liveForecastRimWidgetService.asObservable();

    //Live Forecast Tyre Widget
    private liveForecastTyreWidgetService: BehaviorSubject<Array<LiveForecastTyreWidget>> = new BehaviorSubject<Array<LiveForecastTyreWidget>>([]);
    liveForecastTyreWidget: Observable<Array<LiveForecastTyreWidget>> = this.liveForecastTyreWidgetService.asObservable();

    //Condition Monitoring
    private conditionMonitoringWidgetService: BehaviorSubject<Array<ConditionMonitoringWidget>> = new BehaviorSubject<Array<ConditionMonitoringWidget>>([]);
    conditionMonitoringWidget: Observable<Array<ConditionMonitoringWidget>> = this.conditionMonitoringWidgetService.asObservable();

    //Pressure Check Per Vehicle Widget
    private pressureCheckVehicleWidgetService: BehaviorSubject<Array<PressureCheckWidget>> = new BehaviorSubject<Array<PressureCheckWidget>>([]);
    pressureCheckVehicleWidget: Observable<Array<PressureCheckWidget>> = this.pressureCheckVehicleWidgetService.asObservable();


    constructor(private widgetNotification: WidgetApiNotificationService) { }

    async setApiCallFinalApiIndex(value: number) {
        let listener = await this.widgetApiCallListenerService.getValue();
        listener.finalApiIndex = value;
        listener.currentApiIndex = 0;
        listener.receviedFinalApiIndex = true;
        this.widgetApiCallListenerService.next(listener);
    }

    async setReceivedPreviousApiVersion() {
        let listener = await this.widgetApiCallListenerService.getValue();
        listener.currentApiIndex = 0;
        listener.receivedPreviousVersion = true;
        listener.receviedFinalApiIndex = false;
        await this.widgetApiCallListenerService.next(listener);
    }

    async addCurrentApiIndex() {
        let listener = await this.widgetApiCallListenerService.getValue();
        listener.currentApiIndex += 1;
        this.confirmListener(listener)
    }

    async confirmListener(listener: WidgetApiCallListener) {
        if (listener.currentApiIndex === listener.finalApiIndex && listener.receivedPreviousVersion) {
            await this.widgetNotification.sendFetchNotification();
            await this.resetCurrentApiIndex();
        }
        else if (listener.currentApiIndex === listener.finalApiIndex && !listener.receivedPreviousVersion) {
            await this.setReceivedPreviousApiVersion();
        }
        else {
            await this.widgetApiCallListenerService.next(listener);
        }
    }

    async resetCurrentApiIndex() {
        let listener = await this.widgetApiCallListenerService.getValue();
        listener.currentApiIndex = 0;
        listener.receviedFinalApiIndex = false;
        await this.widgetApiCallListenerService.next(listener);
    }

    //Completed Change Widget
    async setCompletedChangeWidget(value: CompletedChangeWidgetElement) {
        await this.completedChangeWidgetService.next(value);
    }

    //Total Down Time Widget
    async setTotalDownTime(value: number) {
        await this.totalDownTimeService.next(value);
    }

    //Error Widget
    async setErrorInWidget(value: ErrorsInWidgetElement) {
        await this.errorWidgetService.next(value);
    }

    //Tyre In Inspection
    async setTyreInInspectionWidget(value: TyreInInspectionElement) {
        await this.tyreInspectionWidgetService.next(value);
    }

    //Site Health Rate Widget
    async setSiteHealthRate(value: number) {
        await this.siteHealthRateService.next(value);
    }

    //Site Health Rate Widget
    async setSiteHealthElement(element: SiteHealthRateElement) {
        await this.siteHealthRateElementService.next(element);
    }

    //Vehicle Maintenance Widget
    async setMaintenanceSessionsInProgress(value: number) {
        await this.maintenanceSessionsInProgressService.next(value);
    }

    //Vehicle Maintenance Widget
    async setMaintenanceSessionsPending(value: number) {
        await this.maintenanceSessionsPendingService.next(value);
    }

    //Update pressure kpi components
    async updatePressureKpi(selectedWidget: PressureKPIWidget) {
        let currentComponent = await this.pressureKPIWidgetService.getValue();
        let deletedComponent = await currentComponent.filter(widget => widget.siteDashboardWidgetId !== selectedWidget.siteDashboardWidgetId);
        let updatedComponent = await deletedComponent.concat([selectedWidget]);

        await this.pressureKPIWidgetService.next(updatedComponent);
    }

    //Delete pressure kpi
    async deletePressureKpi(selectedWidget: PressureKPIWidget) {
        let currentComponent = await this.pressureKPIWidgetService.getValue();
        let deletedComponent = await currentComponent.filter(widget => widget.siteDashboardWidgetId !== selectedWidget.siteDashboardWidgetId);

        await this.pressureKPIWidgetService.next(deletedComponent);
    }

    //Update live forecast rim ndt components
    async updateLiveForecastRimNdt(selectedWidget: LiveForecastRimWidget) {
        let currentComponent = await this.liveForecastRimWidgetService.getValue();
        let deletedComponent = await currentComponent.filter(widget => widget.siteDashboardWidgetId !== selectedWidget.siteDashboardWidgetId);
        let updatedComponent = await deletedComponent.concat([selectedWidget]);

        await this.liveForecastRimWidgetService.next(updatedComponent);
    }

    //Delete Live Forcast Rim Ndt
    async deleteLiveForecastRimNdt(selectedWidget: LiveForecastRimWidget) {
        let currentComponent = await this.liveForecastRimWidgetService.getValue();
        let deletedComponent = await currentComponent.filter(widget => widget.siteDashboardWidgetId !== selectedWidget.siteDashboardWidgetId);

        await this.liveForecastRimWidgetService.next(deletedComponent);
    }

    //Update Live Forecast Tyre
    async updateLiveForecastTyre(selectedWidget: LiveForecastTyreWidget) {
        let currentComponent = await this.liveForecastTyreWidgetService.getValue();
        let deletedComponent = await currentComponent.filter(widget => widget.siteDashboardWidgetId !== selectedWidget.siteDashboardWidgetId);
        let updatedComponent = await deletedComponent.concat([selectedWidget]);

        await this.liveForecastTyreWidgetService.next(updatedComponent);
    }

    //Delete Live Forecast Tyre
    async deleteLiveForecastTyre(selectedWidget: LiveForecastTyreWidget) {
        let currentComponent = await this.liveForecastTyreWidgetService.getValue();
        let deletedComponent = await currentComponent.filter(widget => widget.siteDashboardWidgetId !== selectedWidget.siteDashboardWidgetId);

        await this.liveForecastTyreWidgetService.next(deletedComponent);
    }

    //Update Condition Moniotring
    async updateConditionMonitoring(selectedWidget: ConditionMonitoringWidget) {
        let currentComponent = await this.conditionMonitoringWidgetService.getValue();
        let deletedComponent = await currentComponent.filter(widget => widget.siteDashboardWidgetId !== selectedWidget.siteDashboardWidgetId);
        let updatedComponent = await deletedComponent.concat([selectedWidget]);

        await this.conditionMonitoringWidgetService.next(updatedComponent);
    }

    //Delete Condition Moniotring
    async deleteConditionMonitoring(selectedWidget: ConditionMonitoringWidget) {
        let currentComponent = await this.conditionMonitoringWidgetService.getValue();
        let deletedComponent = await currentComponent.filter(widget => widget.siteDashboardWidgetId !== selectedWidget.siteDashboardWidgetId);

        await this.conditionMonitoringWidgetService.next(deletedComponent);
    }

    //Update Pressure Check
    async updatePressureCheck(selectedWidget: PressureCheckWidget) {
        let currentComponent = await this.pressureCheckVehicleWidgetService.getValue();
        let deletedComponent = await currentComponent.filter(widget => widget.siteDashboardWidgetId !== selectedWidget.siteDashboardWidgetId);
        let updatedComponent = await deletedComponent.concat([selectedWidget]);

        await this.pressureCheckVehicleWidgetService.next(updatedComponent);
    }

    //Delete Pressure Check
    async deletePressureCheck(selectedWidget: PressureCheckWidget) {
        let currentComponent = await this.pressureCheckVehicleWidgetService.getValue();
        let deletedComponent = await currentComponent.filter(widget => widget.siteDashboardWidgetId !== selectedWidget.siteDashboardWidgetId);

        await this.pressureCheckVehicleWidgetService.next(deletedComponent);
    }

    //Update Scrap Life Trend
    async updateScrapLifeTrend(selectedWidget: ScrapLifeTrendWidget) {
        let currentComponent = await this.scrapLifeTrendWidgetService.getValue();
        let deletedComponent = await currentComponent.filter(widget => widget.siteDashboardWidgetId !== selectedWidget.siteDashboardWidgetId);
        let updatedComponent = await deletedComponent.concat([selectedWidget]);

        await this.scrapLifeTrendWidgetService.next(updatedComponent);
    }

    //Delete Scrap Life Trend
    async deleteScrapLifeTrend(selectedWidget: ScrapLifeTrendWidget) {
        let currentComponent = await this.scrapLifeTrendWidgetService.getValue();
        let deletedComponent = await currentComponent.filter(widget => widget.siteDashboardWidgetId !== selectedWidget.siteDashboardWidgetId);

        await this.scrapLifeTrendWidgetService.next(deletedComponent);
    }

    //Completed
    async completed() {
        await this.totalDownTimeService.complete();
        await this.errorWidgetService.complete();
        await this.maintenanceSessionsInProgressService.complete();
        await this.maintenanceSessionsPendingService.complete();
        await this.siteHealthRateService.complete();
        await this.siteHealthRateElementService.complete();
        await this.pressureKPIWidgetService.complete();
        await this.liveForecastRimWidgetService.complete();
        await this.liveForecastTyreWidgetService.complete();
        await this.conditionMonitoringWidgetService.complete();
        await this.widgetApiCallListenerService.complete();
    }

}