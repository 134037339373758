/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var utils_1 = require("../../utils");
/**
 * Represents the Kendo UI GridSpacer component for Angular.
 * Used to give additional white space between the Pager inner elements,
 * and provides a way for customizing the spacer width.
 * It can also be used in any flex container within the Grid.
 */
var GridSpacerComponent = /** @class */ (function () {
    function GridSpacerComponent() {
        this.hostClass = true;
    }
    Object.defineProperty(GridSpacerComponent.prototype, "sizedClass", {
        get: function () {
            return utils_1.isPresent(this.width);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GridSpacerComponent.prototype, "flexBasisStyle", {
        get: function () {
            return this.width;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        core_1.HostBinding('class.k-spacer'),
        tslib_1.__metadata("design:type", Boolean)
    ], GridSpacerComponent.prototype, "hostClass", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-spacer-sized'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], GridSpacerComponent.prototype, "sizedClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('style.flexBasis'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], GridSpacerComponent.prototype, "flexBasisStyle", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], GridSpacerComponent.prototype, "width", void 0);
    GridSpacerComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-grid-spacer, kendo-pager-spacer',
            template: ""
        })
    ], GridSpacerComponent);
    return GridSpacerComponent;
}());
exports.GridSpacerComponent = GridSpacerComponent;
