//import angular from 'angular';


    angular.module('app.directives')
        .directive(
        'ocCheckbox',
        [
            function () {
                return {
                    replace: false,
                    restrict: 'E',
                    scope: {
                        value: "=ngModel",
                        label: "=",
                        isDisabled: "="
                    },
                    template: function (scope) {
                        return '<label class="checkbox-label"><input type="checkbox" ng-model="value" ng-disabled="isDisabled" />{{label}} {{ isDisabled }}</label>';
                    }
                };
            }
        ]
    );
