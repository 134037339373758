//import angular from 'angular';
import * as _ from 'underscore';
import AdminUrls from '../../adminUrls';
import tmpl from './triggerPopupEffectiveRecipients.html';



    //type PersonsForRolesResponse = { firstName: string, lastName: string, email: string, fromRole: boolean, fromPerson: boolean }[][];

    angular.module('app.admin').component('triggerPopupEffectiveRecipients', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        controllerAs: 'vm',
        template: tmpl,
        controller: ['amtCommandQuerySvc', 'amtXlatSvc', 'adminUrls', '$timeout',
            function (amtCommandQuerySvc, xlatSvc, adminUrls: AdminUrls, $timeout) {
                var vm = this;

                vm.level = 1;
                vm.levels = 1;
                vm.allRecipients = [];
                vm.recipients = [];
                vm.recipientsGrid = {};
                vm.showAllLevels = false;

                vm.updateWndCaption = function () {
                    vm.wnd.caption = xlatSvc.xlat("notifications.effectiveRecipientsView") +
                        vm.showAllLevels || vm.levels==1 ? "" : (": " + xlatSvc.xlat('notifications.notificationTab', vm.level) );
                };

                vm.$onInit = async function (): Promise<void> {
                    try {
                        vm.wnd.processing = true;
                        vm.level = vm.initParams.level;

                        await vm.load(vm.initParams.levels);
                    }
                    finally {
                        vm.wnd.processing = false;
                    }
                };

                vm.updateRecipients = function () {
                    if (vm.showAllLevels) {
                        vm.recipients = _.flatten(vm.allRecipients);
                    } else {
                        vm.recipients = vm.allRecipients[vm.level - 1];
                    }
                    vm.updateWndCaption();
                    
                    $timeout(vm.recipientsGrid.refresh);
                }

                vm.load = async function (levels: {roleIds: string[], personIds: string[]}[]): Promise<void> {
                    vm.levels = levels.length;

                    let personsForRolesResponse: { firstName: string, lastName: string, email: string, fromRole: boolean, fromPerson: boolean }[][] =
                        await amtCommandQuerySvc.post(adminUrls.getNotificationRecipientDetails, { Levels: levels });

                    if (personsForRolesResponse == null)
                        return;

                    let fromRoleText = xlatSvc.xlat('notifications.subscriptionType.role');
                    let fromPersonText = xlatSvc.xlat('notifications.subscriptionType.person');
                    let fromBothText = xlatSvc.xlat('notifications.subscriptionType.both');

                    let id = 0;

                    vm.allRecipients = personsForRolesResponse.map((o, i) => o.map(p => ({
                        id: id++, //amtGrid needs an id, it otherwise has no purpose
                        firstName: p.firstName,
                        lastName: p.lastName,
                        email: p.email,
                        level: i + 1,
                        type: p.fromRole && p.fromPerson ? fromBothText : p.fromRole ? fromRoleText : fromPersonText
                    })));

                    vm.updateRecipients();
                    return;
                }
            }
        ]
    });
