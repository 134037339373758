//import angular from 'angular';
import * as _ from 'underscore';
import OcDateSvc from '../../../../../services/ocDateSvc';
import tmpl from './orderCreatePopup.html';


    angular.module("app.site").component("orderCreatePopup",
        {
            template: tmpl,
            bindings: {
                initParams: '=',
                buttonMethods: '=',
                buttonStates: '=',
                buttons: '=',
                closeOnSave: '=',
                wnd: '='
            },
            controller: orderCreatePopupCtrl
        });

    // @ts-ignore
    orderCreatePopupCtrl.$inject = ['$scope', 'amtConstants', 'amtCommandQuerySvc', 'confirmSvc', 'amtXlatSvc', 'enums', 'notifySvc', 'WindowFactory',
        'ocDateSvc', 'errorReporter'];

    function orderCreatePopupCtrl($scope, amtConstants, amtCommandQuerySvc, confirmSvc, xlatSvc, enums, notifySvc, WindowFactory,
        ocDateSvc: OcDateSvc, errorReporter) {

        var vm = this;
        $scope.vm = this;

        vm.apiUrls = amtConstants.url;

        this.$onInit = function () {

            vm.specification = vm.initParams.specification;
            vm.forecastId = vm.initParams.forecastId;

            vm.monthStart = (vm.initParams.month < vm.initParams.forecastStartDate ? vm.initParams.forecastStartDate : vm.initParams.month);
            vm.monthEnd = (vm.initParams.forecastEndDate < ocDateSvc.getEndOfMonth(vm.monthStart) ? vm.initParams.forecastEndDate : ocDateSvc.getEndOfMonth(vm.monthStart));

            vm.buttons = [
                {
                    primary: true,
                    cancel: false,
                    value: 'common.save_label',
                    name: 'saveButton',
                    click: 'save',
                    type: enums.buttonTypes.button
                },
                {
                    primary: false,
                    cancel: true,
                    value: 'common.cancel_label',
                    name: 'cancelButton',
                    click: 'cancel',
                    type: enums.buttonTypes.button
                }
            ];

            vm.buttonStates = {
                saveButton: {
                    visible: true,
                    disabled: true
                },
                cancelButton: {
                    visible: true,
                    disabled: false
                }
            };

            vm.buttonMethods = {
                save: vm.onSave,
                cancel: vm.onCancelPopup
            };
        };

        vm.onSave = function () {

            // if there is already a record for that date then they need to edit that record instead of adding a new order
            if (vm.initParams.existingOrderDates && vm.initParams.existingOrderDates.length > 0) {
                if (_.any(vm.initParams.existingOrderDates, d => d.getTime() === vm.estimatedArrivalDate.getTime())) {
                    notifySvc.error(xlatSvc.xlat("forecastOrdersEdit.orderForDateExists"));
                    return;
                }
            }

            var model = {
                componentType: vm.initParams.componentType,
                forecastId: vm.forecastId,
                specificationId: vm.initParams.specificationId,
                orders: [{
                    quantity: vm.quantity,
                    expectedDeliveryDate: ocDateSvc.removeLocalTimeZoneOffset(vm.estimatedArrivalDate)
                }]
            };

            vm.wnd.processing = true;

            amtCommandQuerySvc.post(vm.apiUrls.createUpdateOrders, model).then(function (response) {

                notifySvc.success(xlatSvc.xlat("forecastOrdersEdit.addOrderSuccessful_message"));

                if (vm.wnd.onDataChanged) {
                    vm.wnd.onDataChanged({
                        expectedDeliveryDate: vm.estimatedArrivalDate,
                        purchaseOrders: 0,
                        additionalDeliveries: vm.quantity
                    });
                }

                WindowFactory.closeWindow(vm.wnd);

            }).catch(function (error) {
                errorReporter.logError(error);
            }).finally(function () {
                vm.wnd.processing = false;
            });
        };

        vm.onCancelPopup = function () {
            confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                WindowFactory.closeWindow(vm.wnd);
            });
        }

        $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing'], function () {
            vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.form.$pristine || !!vm.wnd.processing;
        });
    }
