//import angular from 'angular';
import AdminUrls from '../../../admin/adminUrls';
import tmpl from './CopyRolesFromUserPopup.html';



    angular.module('app.admin').component('copyRolesFromUser', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['$scope', 'adminUrls', 'amtXlatSvc', 'amtCommandQuerySvc', 'enums', 'confirmSvc', 'WindowFactory', 'notifySvc', 'errorReporter',
            function ($scope, adminUrls: AdminUrls, xlatSvc: IAmtXlatSvc, amtCommandQuerySvc, enums, confirmSvc, WindowFactory: IWindowFactory, notifySvc, errorReporter) {
                var vm = this;
                $scope.vm = this;

                vm.apiUrls = adminUrls;
                vm.isVisible = true;
                vm.copyUserRolesPromise = null;
                vm.selectedUser = {};

                this.$onInit = function () {
                    vm.personId = vm.initParams.personId ? vm.initParams.personId : '';

                    vm.wnd.onClose = vm.onClose;
                    vm.mode = vm.initParams.mode;

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: 'common.copy_label',
                            name: 'copyButton',
                            click: 'copy',
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: true,
                            value: 'common.cancel_label',
                            name: 'cancelButton',
                            click: 'cancel',
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        copyButton: {
                            visible: true,
                            disabled: true
                        },
                        cancelButton: {
                            visible: true,
                            cancel: false
                        }
                    };

                    vm.buttonMethods = {
                        copy: vm.onSave,
                        cancel: vm.onClose
                    };
                };

                vm.onSave = function () {
                    if (!vm.selectedUser) {
                        // no user selected
                        return;
                    }

                    if (!vm.selectedUser.key === vm.personId) {
                        // can't be the same person
                        notifySvc.error(xlatSvc.xlat('userSecurity.copyRolesError_SamePerson'));
                        return;
                    }

                    vm.copyUserRolesPromise = amtCommandQuerySvc.post(vm.apiUrls.copyUserRoles, {
                        sourcePersonId: vm.selectedUser.key,
                        targetPersonId: vm.personId
                    })
                        .then(function (response) {

                            notifySvc.success(xlatSvc.xlat('userSecurity.copyRolesSuccessful'));
                            vm.wnd.onDataChanged();
                            closeWindow();

                        }).catch(function (error) {
                            errorReporter.logError(error);
                        });
                };

                function closeWindow() {
                    WindowFactory.closeWindow(vm.wnd);
                }

                vm.onClose = function () {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        vm.form.dirty = false;
                        closeWindow();
                        return true;
                    });

                    return false;
                };

                $scope.$watch('vm.form.$invalid', function () {
                    vm.buttonStates.copyButton.disabled = vm.form.$invalid || vm.readOnly || vm.isUploading;
                });
            }
        ]
    });
