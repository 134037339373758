/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * @hidden
 */
var PDFService = /** @class */ (function () {
    function PDFService() {
        this.savePDF = new core_1.EventEmitter();
        this.drawPDF = new core_1.EventEmitter();
        this.exportClick = new core_1.EventEmitter();
        this.dataChanged = new core_1.EventEmitter();
    }
    PDFService.prototype.save = function (component) {
        this.emitEvent(this.savePDF, component);
    };
    PDFService.prototype.draw = function (component, promise) {
        this.emitEvent(this.drawPDF, { component: component, promise: promise });
    };
    PDFService.prototype.emitEvent = function (emitter, args) {
        if (emitter.observers.length === 0) {
            if (core_1.isDevMode()) {
                throw new Error('Creating PDF requires including the PDFModule and adding the <kendo-grid-pdf> component.');
            }
        }
        else {
            emitter.emit(args);
        }
    };
    PDFService = tslib_1.__decorate([
        core_1.Injectable()
    ], PDFService);
    return PDFService;
}());
exports.PDFService = PDFService;
