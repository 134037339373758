//import angular from 'angular';
import * as _ from 'underscore';
import OcDateSvc from '../../../../../services/ocDateSvc';
import tmpl from './forecastAddNewVehicle.html';


    angular.module('app.site').component('forecastAddNewVehicle', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['$scope', 'amtCommandQuerySvc', 'confirmSvc', 'enums', 'amtXlatSvc', 'amtConstants',
            'WindowFactory', 'notifySvc', 'ocSecuritySvc', 'errorReporter', 'ocConfigSvc', 'vehicleUrls', 'ocDateSvc', '$timeout', '$q',
            function ($scope, amtCommandQuerySvc, confirmSvc, enums, xlatSvc, amtConstants, WindowFactory, notifySvc, ocSecuritySvc, errorReporter, ocConfigSvc, vehicleUrls, ocDateSvc: OcDateSvc, $timeout, $q) {

                var vm = this;
                $scope.vm = this;

                // set urls                                
                vm.urls = amtConstants.url;
                vm.vehicleUrls = vehicleUrls;

                // security
                vm.readonly = !ocSecuritySvc.isAuthorised('Security.Site.Forecasting', 'readWrite');
                vm.isEdit = false;

                vm.status = enums.screenStatus.loading;

                vm.trackRims = ocConfigSvc.user.site.settings.tyreMaintenance.trackRims;

                vm.newCapexAvailablePositions = [];
                vm.newOpexAvailablePositions = [];
                vm.spareTyreAvailablePositions = [];

                vm.model = {};

                this.$onInit = function () {

                    vm.wnd.onClose = vm.onClose;

                    vm.isEdit = !!vm.initParams.forecastVehicleId;

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: vm.isEdit ? 'common.save_label' : 'common.add_label',
                            name: 'saveButton',
                            click: 'save',
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: true,
                            value: 'common.cancel_label',
                            name: 'cancelButton',
                            click: 'cancel',
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: !vm.readonly,
                            disabled: true
                        },
                        cancelButton: {
                            visible: true,
                            disabled: false
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.onSave,
                        cancel: vm.onClose
                    };

                    // if modifying an existing new vehicle, retrieve the details
                    if (vm.isEdit == true) {

                        vm.wnd.processing = true;

                        vm.getDetails().then(function () {
                            $timeout(function () {
                                vm.setPristine();
                            });
                        }).finally(function () {
                            vm.wnd.processing = false;
                        });

                    } else {
                        // if creating a new vehicle default the start date to the forecast start date
                        vm.model.vehicleStartDate = vm.initParams.forecastStartDate;
                    }
                };

                vm.getWheelPositions = function (vehicleSpecification) {

                    let criteria = {
                        vehicleSpecificationId: vehicleSpecification.otracomVehicleSpecificationId
                    };

                    return amtCommandQuerySvc.get(vm.vehicleUrls.getSpecificationWheelPositions, criteria).then(function (response) {

                        if (response.data) {
                            vm.axles = response.data.axles;

                            vm.availablePositions = _.flatten(vm.axles.map(function (p) { return p.wheelPositions; }));
                            _.each(vm.availablePositions, function (p) { p.key = p.wheelPositionNumber; p.selected = false; });

                            vm.newCapexAvailablePositions = angular.copy(vm.availablePositions);
                            vm.newOpexAvailablePositions = angular.copy(vm.availablePositions);
                            vm.spareTyreAvailablePositions = angular.copy(vm.availablePositions);

                            if (!!vm.positions) {
                                vm.model.newCapexTyreFitmentPositions = angular.copy(vm.positions.newCapexTyreFitmentPositions);
                                vm.model.newOpexTyreFitmentPositions = angular.copy(vm.positions.newOpexTyreFitmentPositions);
                                vm.model.spareTyreFitmentPositions = angular.copy(vm.positions.spareTyreFitmentPositions);
                            }
                        }
                    }).catch(function (error) {
                        errorReporter.logError(error);
                    });
                };

                vm.getValidFitments = function (vehicleSpecification) {

                    let criteria = {
                        vehicleSpecificationId: vehicleSpecification.otracomVehicleSpecificationId
                    };

                    return amtCommandQuerySvc.post(vm.urls.getValidFitments, criteria).then(function (response) {

                        if (response && response.result) {

                            vm.tyreSpecifications = response.result.filter(function (r) {
                                return r.equipmentType.toLowerCase() == enums.equipmentTypes.tyre;
                            });

                            vm.rimSpecifications = response.result.filter(function (r) {
                                return r.equipmentType.toLowerCase() == enums.equipmentTypes.rim;
                            });

                        }

                    }).catch(function (error) {
                        errorReporter.logError(error);
                    });
                };

                vm.onVehicleSpecificationChange = function (option) {

                    vm.wnd.processing = true;

                    // clear any select initial tyre/rim specification
                    vm.model.tyreSpecification = null;
                    vm.model.rimSpecification = null;

                    vm.getVehicleSpecificationDetails(option).finally(function () {
                        vm.wnd.processing = false;
                    });
                };

                $scope.$watch('vm.model.newCapexTyreFitmentPositions', function (newValue, oldValue) {

                    if ((oldValue && oldValue.length > 0) || (newValue && newValue.length > 0)) {

                        _.each(vm.newCapexAvailablePositions, function (p) { p.selected = vm.model.newCapexTyreFitmentPositions.indexOf(p.key) >= 0; });

                        vm.newOpexAvailablePositions = angular.copy(_.filter(vm.availablePositions, function (p) {
                            return newValue.indexOf(p.key) < 0 && vm.model.spareTyreFitmentPositions.indexOf(p.key) < 0;
                        }).map(function (p) { p.selected = vm.model.newOpexTyreFitmentPositions.indexOf(p.key) >= 0; return p; }));

                        vm.spareTyreAvailablePositions = angular.copy(_.filter(vm.availablePositions, function (p) {
                            return newValue.indexOf(p.key) < 0 && vm.model.newOpexTyreFitmentPositions.indexOf(p.key) < 0;
                        }).map(function (p) { p.selected = vm.model.spareTyreFitmentPositions.indexOf(p.key) >= 0; return p; }));
                    }
                });

                $scope.$watch('vm.model.newOpexTyreFitmentPositions', function (newValue, oldValue) {

                    if ((oldValue && oldValue.length > 0) || (newValue && newValue.length > 0)) {

                        _.each(vm.newOpexAvailablePositions, function (p) { p.selected = vm.model.newOpexTyreFitmentPositions.indexOf(p.key) >= 0; });

                        vm.newCapexAvailablePositions = angular.copy(_.filter(vm.availablePositions, function (p) {
                            return newValue.indexOf(p.key) < 0 && vm.model.spareTyreFitmentPositions.indexOf(p.key) < 0;
                        }).map(function (p) { p.selected = vm.model.newCapexTyreFitmentPositions.indexOf(p.key) >= 0; return p; }));

                        vm.spareTyreAvailablePositions = angular.copy(_.filter(vm.availablePositions, function (p) {
                            return vm.model.newCapexTyreFitmentPositions.indexOf(p.key) < 0 && newValue.indexOf(p.key) < 0;
                        }).map(function (p) { p.selected = vm.model.spareTyreFitmentPositions.indexOf(p.key) >= 0; return p; }));
                    }

                });

                $scope.$watch('vm.model.spareTyreFitmentPositions', function (newValue, oldValue) {

                    if ((oldValue && oldValue.length > 0) || (newValue && newValue.length > 0)) {

                        _.each(vm.spareTyreAvailablePositions, function (p) { p.selected = vm.model.spareTyreFitmentPositions.indexOf(p.key) >= 0; });

                        vm.newCapexAvailablePositions = angular.copy(_.filter(vm.availablePositions, function (p) {
                            return vm.model.newOpexTyreFitmentPositions.indexOf(p.key) < 0 && newValue.indexOf(p.key) < 0;
                        }).map(function (p) { p.selected = vm.model.newCapexTyreFitmentPositions.indexOf(p.key) >= 0; return p; }));

                        vm.newOpexAvailablePositions = angular.copy(_.filter(vm.availablePositions, function (p) {
                            return vm.model.newCapexTyreFitmentPositions.indexOf(p.key) < 0 && newValue.indexOf(p.key) < 0;
                        }).map(function (p) { p.selected = vm.model.newOpexTyreFitmentPositions.indexOf(p.key) >= 0; return p; }));
                    }

                });

                vm.getVehicleSpecificationDetails = function (vehicleSpecification) {

                    let vehicleSpecificationPromises = [];

                    vehicleSpecificationPromises.push(vm.getValidFitments(vehicleSpecification));
                    vehicleSpecificationPromises.push(vm.getWheelPositions(vehicleSpecification));

                    return $q.all(vehicleSpecificationPromises);
                };

                vm.getDetails = function () {

                    let criteria = {
                        forecastId: vm.initParams.forecastId,
                        forecastVehicleId: vm.initParams.forecastVehicleId
                    };

                    return amtCommandQuerySvc.get(amtConstants.url.getForecastNewVehicleDetails, criteria).then(function (response) {

                        if (response) {

                            vm.model = response.data;

                            vm.positions = {
                                newCapexTyreFitmentPositions: response.data.newCapexTyreFitmentPositions,
                                spareTyreFitmentPositions: response.data.spareTyreFitmentPositions,
                                newOpexTyreFitmentPositions: response.data.newOpexTyreFitmentPositions
                            };

                            // retrieve the valid tyre/rim specifications and the wheel positions for the vehicle specification                            
                            let vehicleSpecification = { otracomVehicleSpecificationId: vm.model.otracomVehicleSpecificationId };

                            return vm.getVehicleSpecificationDetails(vehicleSpecification);
                        }

                    }).catch(function (error) {
                        errorReporter.logError(error);
                    });

                };

                // add/edit vehicle
                vm.onSave = function () {

                    if (!!vm.model.vehicleEndDate && new Date(vm.model.vehicleStartDate) > new Date(vm.model.vehicleEndDate)) {
                        notifySvc.error(xlatSvc.xlat("forecastVehicles.endDateMustBeGreaterThanStartDate"));
                        return;
                    }

                    for (let pos of vm.availablePositions) {
                        if (vm.model.newCapexTyreFitmentPositions.indexOf(pos.key) < 0 && vm.model.newOpexTyreFitmentPositions.indexOf(pos.key) < 0 && vm.model.spareTyreFitmentPositions.indexOf(pos.key) < 0) {
                            notifySvc.error(xlatSvc.xlat("forecastNewVehicleSchedule.positionMustBeAssigned", pos.positionDisplay));
                            return;
                        }
                    }

                    vm.wnd.processing = true;

                    var vehicle = {
                        forecastId: vm.initParams.forecastId,
                        forecastVehicleId: vm.initParams.forecastVehicleId,
                        otracomVehicleSpecificationId: vm.model.specification.otracomVehicleSpecificationId,
                        initialTyreSpecificationId: vm.model.tyreSpecification.otracomComponentSpecificationId,
                        initialRimSpecificationId: vm.model.rimSpecification ? vm.model.rimSpecification.otracomComponentSpecificationId : null,
                        vehicleStartDate: ocDateSvc.removeLocalTimeZoneOffset(new Date(vm.model.vehicleStartDate)),
                        vehicleEndDate: ocDateSvc.removeLocalTimeZoneOffset(new Date(vm.model.vehicleEndDate)),
                        serialNumber: vm.model.serialNumber,
                        excludeFromForecast: vm.isEdit == true ? vm.model.excludeFromForecast : false,
                        newCapexTyreFitmentPositions: vm.model.newCapexTyreFitmentPositions,
                        newOpexTyreFitmentPositions: vm.model.newOpexTyreFitmentPositions,
                        spareTyreFitmentPositions: vm.model.spareTyreFitmentPositions
                    };

                    amtCommandQuerySvc.put(amtConstants.url.addModifyNewVehicle, vehicle).then(function (response) {

                        if (vm.isEdit == true) {
                            notifySvc.success(xlatSvc.xlat("forecastNewVehicleSchedule.updateSuccessful"));
                        } else {
                            notifySvc.success(xlatSvc.xlat("forecastVehicles.messageNewVehicleAddedSuccess"));
                        }

                        vm.setPristine();

                        if (vm.wnd.onDataChanged) {
                            vm.wnd.onDataChanged();
                        }

                        closeWindow();

                    }).catch(function (error) {
                        errorReporter.logError(error);
                    }).finally(function () {
                        vm.wnd.processing = false;
                    });
                };

                vm.setPristine = function () {
                    if (vm.form) {
                        vm.form.$setPristine();
                    }
                };

                // close window
                vm.onClose = function () {
                    closeWindow();
                };

                function closeWindow() {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        vm.form.$setPristine();

                        WindowFactory.closeWindow(vm.wnd);
                    });
                }

                $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing', 'vm.readonly'], function () {
                    vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.form.$pristine || vm.readonly || vm.wnd.processing;
                });

            }
        ]
    });
