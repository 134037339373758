/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var id_service_1 = require("../common/id.service");
var navigation_service_1 = require("./navigation.service");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var id = 0;
function nextId() {
    return id++;
}
/**
 * @hidden
 */
var LogicalRowDirective = /** @class */ (function () {
    function LogicalRowDirective(idService, navigation) {
        this.idService = idService;
        this.navigation = navigation;
        this.logicalSlaveRow = false;
        this.logicalSlaveCellsCount = 0;
        this.dataRowIndex = -1;
        this.uid = nextId();
    }
    Object.defineProperty(LogicalRowDirective.prototype, "hostRole", {
        get: function () {
            return this.logicalSlaveRow ? 'presentation' : 'row';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LogicalRowDirective.prototype, "ariaRowIndex", {
        get: function () {
            return this.logicalRowIndex + 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LogicalRowDirective.prototype, "ariaOwns", {
        get: function () {
            if (!this.navigation.enabled || this.logicalSlaveRow || this.logicalSlaveCellsCount === 0) {
                return undefined;
            }
            var ids = [];
            var total = this.logicalCellsCount + this.logicalSlaveCellsCount;
            for (var cellIndex = this.logicalCellsCount; cellIndex < total; cellIndex++) {
                ids.push(this.idService.cellId(this.logicalRowIndex, cellIndex));
            }
            return ids.join(' ');
        },
        enumerable: true,
        configurable: true
    });
    LogicalRowDirective.prototype.ngOnChanges = function (changes) {
        if (!this.navigation.enabled || this.logicalSlaveRow) {
            return;
        }
        var indexChange = changes.logicalRowIndex;
        var logicalSlaveRowChange = changes.logicalSlaveRow;
        if (indexChange || logicalSlaveRowChange) {
            var index = indexChange && !indexChange.isFirstChange() ? indexChange.previousValue : this.logicalRowIndex;
            this.navigation.unregisterRow(index, this);
            this.navigation.registerRow(this);
        }
        else if (kendo_angular_common_1.anyChanged(['dataRowIndex', 'dataItem'], changes)) {
            this.navigation.updateRow(this);
        }
    };
    LogicalRowDirective.prototype.ngOnDestroy = function () {
        this.navigation.unregisterRow(this.logicalRowIndex, this);
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], LogicalRowDirective.prototype, "logicalRowIndex", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], LogicalRowDirective.prototype, "logicalSlaveRow", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], LogicalRowDirective.prototype, "logicalCellsCount", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], LogicalRowDirective.prototype, "logicalSlaveCellsCount", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], LogicalRowDirective.prototype, "dataRowIndex", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], LogicalRowDirective.prototype, "dataItem", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('attr.role'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], LogicalRowDirective.prototype, "hostRole", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.aria-rowindex'),
        tslib_1.__metadata("design:type", Number),
        tslib_1.__metadata("design:paramtypes", [])
    ], LogicalRowDirective.prototype, "ariaRowIndex", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.aria-owns'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], LogicalRowDirective.prototype, "ariaOwns", null);
    LogicalRowDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoGridLogicalRow]'
        }),
        tslib_1.__metadata("design:paramtypes", [id_service_1.IdService,
            navigation_service_1.NavigationService])
    ], LogicalRowDirective);
    return LogicalRowDirective;
}());
exports.LogicalRowDirective = LogicalRowDirective;
