/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var treeview_component_1 = require("../treeview.component");
var utils_1 = require("../utils");
var rxjs_1 = require("rxjs");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
/**
 * A directive which manages the in-memory selection state of the TreeView node
 * ([see example]({% slug selection_treeview %})).
 */
var SelectDirective = /** @class */ (function () {
    function SelectDirective(treeView) {
        var _this = this;
        this.treeView = treeView;
        /**
         * Fires when the `selectedKeys` collection was updated.
         */
        this.selectedKeysChange = new core_1.EventEmitter();
        this.subscriptions = new rxjs_1.Subscription();
        this.selectActions = {
            'multiple': function (e) { return _this.selectMultiple(e); },
            'single': function (e) { return _this.selectSingle(e); }
        };
        /**
         * Reflectes the internal `selectedKeys` state.
         */
        this.state = new Set();
        this.subscriptions.add(this.treeView.selectionChange.subscribe(this.select.bind(this)));
        this.treeView.isSelected = function (dataItem, index) { return (_this.state.has(_this.itemKey({ dataItem: dataItem, index: index }))); };
    }
    Object.defineProperty(SelectDirective.prototype, "isSelected", {
        /**
         * @hidden
         */
        set: function (value) {
            this.treeView.isSelected = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectDirective.prototype, "getAriaMultiselectable", {
        get: function () {
            return this.options.mode === 'multiple';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectDirective.prototype, "options", {
        get: function () {
            var defaultOptions = {
                enabled: true,
                mode: 'single'
            };
            if (!utils_1.isPresent(this.selection) || typeof this.selection === 'string') {
                return defaultOptions;
            }
            var selectionSettings = utils_1.isBoolean(this.selection) ? { enabled: this.selection } : this.selection;
            return Object.assign(defaultOptions, selectionSettings);
        },
        enumerable: true,
        configurable: true
    });
    SelectDirective.prototype.ngOnChanges = function (changes) {
        if (kendo_angular_common_1.isChanged('selectedKeys', changes, false) && changes.selectedKeys.currentValue !== this.lastChange) {
            this.state = new Set(changes.selectedKeys.currentValue);
        }
    };
    SelectDirective.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    SelectDirective.prototype.itemKey = function (e) {
        if (!this.selectKey) {
            return e.index;
        }
        if (typeof this.selectKey === 'string') {
            return e.dataItem[this.selectKey];
        }
        if (typeof this.selectKey === 'function') {
            return this.selectKey(e);
        }
    };
    SelectDirective.prototype.select = function (e) {
        var _a = this.options, enabled = _a.enabled, mode = _a.mode;
        var performSelection = this.selectActions[mode] || utils_1.noop;
        if (!enabled) {
            return;
        }
        performSelection(e);
    };
    SelectDirective.prototype.selectSingle = function (node) {
        var key = this.itemKey(node);
        if (!this.state.has(key)) {
            this.state.clear();
            this.state.add(key);
            this.notify();
        }
    };
    SelectDirective.prototype.selectMultiple = function (node) {
        var key = this.itemKey(node);
        var isSelected = this.state.has(key);
        if (!utils_1.isPresent(key)) {
            return;
        }
        if (isSelected) {
            this.state.delete(key);
        }
        else {
            this.state.add(key);
        }
        this.notify();
    };
    SelectDirective.prototype.notify = function () {
        this.lastChange = Array.from(this.state);
        this.selectedKeysChange.emit(this.lastChange);
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Function])
    ], SelectDirective.prototype, "isSelected", null);
    tslib_1.__decorate([
        core_1.Input('selectBy'),
        tslib_1.__metadata("design:type", Object)
    ], SelectDirective.prototype, "selectKey", void 0);
    tslib_1.__decorate([
        core_1.Input('kendoTreeViewSelectable'),
        tslib_1.__metadata("design:type", Object)
    ], SelectDirective.prototype, "selection", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Array)
    ], SelectDirective.prototype, "selectedKeys", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], SelectDirective.prototype, "selectedKeysChange", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('attr.aria-multiselectable'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], SelectDirective.prototype, "getAriaMultiselectable", null);
    SelectDirective = tslib_1.__decorate([
        core_1.Directive({ selector: '[kendoTreeViewSelectable]' }),
        tslib_1.__metadata("design:paramtypes", [treeview_component_1.TreeViewComponent])
    ], SelectDirective);
    return SelectDirective;
}());
exports.SelectDirective = SelectDirective;
