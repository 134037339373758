//import angular from 'angular';


    angular.module('app.site').factory('endOfMonthSvc',
        [
            'amtXlatSvc', 'WindowFactory', 'amtCommandQuerySvc', 'exportSvc', '$q', '$filter', 'confirmSvc', 'notifySvc',
            function (
                amtXlatSvc, WindowFactory, amtCommandQuerySvc, exportSvc, $q, $filter, confirmSvc, notifySvc
            ) {
                var svc = this;

                svc.baseUrl = 'site/endOfMonth/';
                svc.baseAPIUrl = 'api/' + svc.baseUrl;

                svc.searchUrl = svc.baseAPIUrl + 'search';

                svc.searchAsync = function (params) {
                    return amtCommandQuerySvc.post(svc.baseUrl + 'search', params);
                };

                svc.getValues = function (data, filter) {
                    var filteredValues = $filter('filter')(data.values, filter);

                    return angular.copy(filteredValues);
                };

                svc.copyValues = function (src, dest) {
                    for (var d = 0; d < src.length; d++) {
                        for (var n = 0; n < dest.length; n++) {
                            if (src[d].tab == dest[n].tab && src[d].name == dest[n].name) {
                                dest[n].value = src[d].value;
                            }
                        }
                    }
                };

                svc.getSearchDefaultsAsync = function (params) {
                    return amtCommandQuerySvc.post(svc.baseUrl + 'getSearchDefaults', params);
                };

                svc.getAsync = function (id) {
                    return $q(function (resolve, reject) {
                        amtCommandQuerySvc.post(svc.baseUrl + "getEndOfMonth", { id: id }).then(resolve, reject);
                    });
                };

                svc.getContinuousImprovementAttachmentsAsync = function (id) {
                    return $q(function (resolve, reject) {
                        amtCommandQuerySvc.post(svc.baseUrl + "getContinuousImprovementAttachments", { id: id }).then(resolve, reject);
                    });
                };

                svc.getAvailableMonthYears = function () {
                    return $q(function (resolve, reject) {
                        amtCommandQuerySvc.post(svc.baseUrl + "getAvailableMonthYears", null).then(resolve, reject);
                    });
                };

                svc.saveValuesAsync = function (params) {
                    return amtCommandQuerySvc.post(svc.baseUrl + 'saveEndOfMonthValues', params);
                };

                svc.saveTrainingReceivedAsync = function (params) {
                    return amtCommandQuerySvc.post(svc.baseUrl + 'saveTrainingReceived', params);
                };

                svc.deleteTrainingReceivedAsync = function (params) {
                    return amtCommandQuerySvc.post(svc.baseUrl + 'deleteTrainingReceived', params);
                };

                svc.saveContinuousImprovementAsync = function (params) {
                    return amtCommandQuerySvc.post(svc.baseUrl + 'saveContinuousImprovement', params);
                };

                svc.deleteContinuousImprovementAsync = function (params) {
                    return amtCommandQuerySvc.post(svc.baseUrl + 'deleteContinuousImprovement', params);
                };                

                svc.completeAsync = function (params) {
                    return amtCommandQuerySvc.post(svc.baseUrl + 'completeEndOfMonth', params);
                };  

                svc.export = function (params) {
                    exportSvc.exportData(svc.baseUrl + 'searchExport', params, amtXlatSvc.xlat('endOfMonth.searchTitle'));
                };

                svc.create = function (callback) {
                    return WindowFactory.openItem({
                        component: 'create-end-of-month',
                        caption: amtXlatSvc.xlat('endOfMonth.createNewEndOfMonth'),                       
                        canClose: false,
                        width: 550,
                        modal: true,
                        onDataChangeHandler: callback,
                        windowRelatedRecordId: uuid()
                    });                   
                };

                svc.openItem = function (item, callback, title) {
                    WindowFactory.openItem({
                        component: 'end-of-month-details',
                        caption: title,
                        initParams: item,
                        canClose: true,
                        width: 1200,
                        height: 650,
                        modal: false,
                        onDataChangeHandler: callback,
                        windowRelatedRecordId: !!item.id ? item.id : uuid()
                    });
                };

                svc.openTraining = function (item, callback, endOfMonthId) {
                    WindowFactory.openItem({
                        component: 'add-edit-training',
                        caption: !!item ? amtXlatSvc.xlat('endOfMonth.editTraining') : amtXlatSvc.xlat('endOfMonth.addTraining'),
                        initParams: { data: item, endOfMonthId: endOfMonthId },
                        canClose: false,
                        width: 650,                        
                        modal: true,                        
                        onDataChangeHandler: callback,
                        windowRelatedRecordId: !!item && !!item.id ? item.id : uuid()
                    });
                };

                svc.openContinuousImprovement = function (item, callback, endOfMonthId) {
                    WindowFactory.openItem({
                        component: 'add-edit-continuous-improvement',
                        caption: !!item ? amtXlatSvc.xlat('endOfMonth.editContinuousImprovement') : amtXlatSvc.xlat('endOfMonth.addContinuousImprovement'),
                        initParams: { data: item, endOfMonthId: endOfMonthId },
                        canClose: false,
                        width: 950,                        
                        modal: true,
                        onDataChangeHandler: callback,
                        windowRelatedRecordId: !!item && !!item.id ? item.id : uuid()
                    });
                };

                svc.showValidationErrors = function (errors, callback) {
                    WindowFactory.openItem({
                        component: 'end-of-month-validation-errors',
                        caption: amtXlatSvc.xlat('endOfMonth.validationErrorMessage'),
                        initParams: errors,
                        canClose: false,
                        width: 750,    
                        modal: true,
                        onDataChangeHandler: callback,
                        windowRelatedRecordId: uuid()
                    });
                };

                svc.createAsync = function (monthYear) {
                    return $q(function (resolve, reject) {
                        amtCommandQuerySvc.post(svc.baseUrl + "createEndOfMonth", { year: monthYear.year, month: monthYear.month }).then(resolve, reject);
                    });
                };

                svc.delete = async function (item) {
                    try {
                        //This is implemented as a basic soft delete as it was a simpler option that implementing cascading delete...
                        await confirmSvc.confirmMessage(amtXlatSvc.xlat('endOfMonth.deleteEOMHeading'), amtXlatSvc.xlat('endOfMonth.deleteEOM') + ' ' + item.monthYear);
                        amtCommandQuerySvc.post(svc.baseUrl + 'delete', { id: item.id });
                        notifySvc.success(amtXlatSvc.xlat('endOfMonth.successfullyDeleted'));
                        return;
                    } catch {
                        return;
                    }
                }

                return svc;
            }
        ]
    );
