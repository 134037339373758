//import angular from 'angular';
import * as _ from 'underscore';
import AdminUrls from '../../../adminUrls';
import tmpl from './details.html';


    angular.module('app.admin').component('addEditReportIntegration', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['$scope', 'confirmSvc', 'enums', 'adminUrls', 'WindowFactory', 'amtCommandQuerySvc',
            'notifySvc', 'amtXlatSvc', 'ocConfigSvc', 'errorReporter', '$timeout', 'helperSvc',
            function ($scope, confirmSvc, enums, adminUrls: AdminUrls, WindowFactory: IWindowFactory, amtCommandQuerySvc,
                notifySvc, amtXlatSvc: IAmtXlatSvc, ocConfigSvc, errorReporter, $timeout: ng.ITimeoutService, helperSvc) {

                var vm = this;
                $scope.vm = this;

                vm.adminUrls = adminUrls;                

                vm.enums = enums;
                vm.config = ocConfigSvc;

                vm.secretGenerated = false;

                vm.reportsControl = {};

                // pass along dirty flag to the window for use on closing minimised windows
                $scope.$watch('vm.form.$dirty', function () {
                    vm.wnd.isDirty = vm.form.$dirty;
                });

                this.$onInit = function () {

                    vm.status = enums.screenStatus.loading;

                    if (vm.wnd !== null) {
                        vm.wnd.onClose = vm.cancel;
                    }

                    vm.wnd.processing = true;

                    if (vm.initParams) {
                        vm.data = angular.copy(vm.initParams);                        
                        vm.id = vm.data.key;                     

                        vm.data.selectedReports = _.map(vm.data.reports, function (r) {
                            return r;
                        });

                        if (vm.data.hasSecret) {
                            vm.data.secret
                        }
                    }

                    vm.readonly = !vm.config.user.isSystemAdmin;

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: "common.save_label",
                            name: "saveButton",
                            click: "save",
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: !vm.readonly,
                            disabled: true
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.save
                    };

                    vm.status = enums.screenStatus.ready;

                    $timeout(function () {
                        vm.setPristine();
                        vm.wnd.processing = false;
                    });
                }

                vm.setPristine = function () {
                    if (vm.form) {
                        vm.form.$setPristine();
                    }
                };

                vm.save = function () {

                    var criteria = {
                        key: vm.data.key,
                        siteId: helperSvc.getKey(vm.data.selectedSite),
                        active: vm.data.active,
                        secret: (vm.secretGenerated ? vm.data.secret : null),
                        reportIds: vm.data.reportIds                        
                    };

                    vm.wnd.processing = true;

                    return amtCommandQuerySvc.post((!!vm.id ? vm.adminUrls.modifyReportIntegration : vm.adminUrls.addReportIntegration), criteria).then(function (response) {

                        notifySvc.success(amtXlatSvc.xlat('reportIntegration.saveSuccessful'));

                        if (vm.wnd.onDataChanged) {
                            vm.wnd.onDataChanged();
                        }

                        vm.closeWindow();
                    }).catch(function (error) {
                        errorReporter.logError(error);
                    }).finally(function () {
                        vm.wnd.processing = false;
                    });
                };

                vm.closeWindow = function () {
                    WindowFactory.closeWindow(vm.wnd);
                };

                vm.cancel = function () {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        vm.form.dirty = false;
                        vm.closeWindow();
                        return true;
                    });

                    return false;
                };

                vm.generateSecret = function () {
                    vm.data.secret = uuid().replace(/-/g, "");
                    vm.secretGenerated = true;
                    vm.setDirty();
                };

                vm.copySecret = function () {

                    var secret = <HTMLInputElement>document.getElementById("secretText");

                    secret.select();

                    document.execCommand("copy");

                    notifySvc.info(amtXlatSvc.xlat('reportIntegration.copiedSecret'));
                };

                vm.copyKey = function () {

                    var key = <HTMLInputElement>document.getElementById("keyText");

                    key.select();

                    document.execCommand("copy");

                    notifySvc.info(amtXlatSvc.xlat('reportIntegration.copiedKey'));
                };

                vm.setDirty = function () {
                    if (vm.form) {
                        vm.form.$setDirty();
                    }
                }

                vm.onSiteChange = function (option) {
                    vm.data.key = helperSvc.getKey(option).toUpperCase();
                };

                $scope.$watchGroup(['vm.data.hasSecret', 'vm.secretGenerated', 'vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing'], function () {
                    vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.form.$pristine || vm.wnd.processing || (!vm.data.hasSecret && !vm.secretGenerated);
                });
            }
        ]
    });
