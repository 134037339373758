/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var menu_tabbing_service_1 = require("./menu/menu-tabbing.service");
/**
 * Represents a service to set the filter descriptor
 * ([see example]({% slug reusablecustomfilters_grid %})).
 */
var FilterService = /** @class */ (function () {
    /**
     * @hidden
     */
    function FilterService(menuTabbingService) {
        /**
         * Fires when the filter descriptors is set.
         */
        this.changes = new rxjs_1.Subject();
        this.menuTabbingService = menuTabbingService;
    }
    /**
     * Sets the filter descriptor.
     *
     * @param {CompositeFilterDescriptor} value - The filter descriptor that will be set.
     */
    FilterService.prototype.filter = function (value) {
        this.changes.next(value);
    };
    FilterService = tslib_1.__decorate([
        tslib_1.__param(0, core_1.Optional()),
        tslib_1.__metadata("design:paramtypes", [menu_tabbing_service_1.MenuTabbingService])
    ], FilterService);
    return FilterService;
}());
exports.FilterService = FilterService;
