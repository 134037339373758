//import angular from 'angular';
import { IVisualInspectionHistory_InitParams, IVisualInspectionItem_InitParams } from '../../../interfaces/visualInspection.interfaces';
import HelperSvc from '../../../services/helperSvc';
import { VisualInspectionService } from '../../../services/visual-inspection.service';
import tmpl from './ocEquipmentVisualInspection.html';

interface IEquipmentVisualInspectionSearchCriteria extends IGridFilterCriteria {
    equipmentId?: guid;
}

interface IEquipmentVisualInspectionScope extends ng.IScope {
    editVisualInspection: (item: any) => void;
}

class ocEquipmentVisualInspectionCtrl implements ng.IComponentController {

    equipmentObject: any;
    tabObject: any;
    autoLoad: boolean;

    searchCriteria: IEquipmentVisualInspectionSearchCriteria = { filterValues: {} }

    loading = false;

    canDelete: boolean;
    canAdd: boolean;
    canExport: boolean;
    canRefresh: boolean;

    visualInspectionGrid: any = {};

    get exportFilename() { return this.amtXlatSvc.xlat('component.visualInspectionExportFilename', this.equipmentObject.serialNumber.formatted) }

    static $inject = ['$scope', 'componentUrls', 'amtXlatSvc', 'exportSvc', 'ocSecuritySvc', 'visualInspectionService', 'helperSvc', '$timeout'];

    constructor(private $scope: IEquipmentVisualInspectionScope, private componentUrls: IComponentUrls, private amtXlatSvc: IAmtXlatSvc,
        private exportSvc: IExportSvc, private ocSecuritySvc: IOcSecuritySvc, private visualInspectionService: VisualInspectionService,
        private helperSvc: HelperSvc, private $timeout) {

        this.$scope.$watch(() => this.equipmentObject, () => {
            if (this.equipmentObject) {
                this.searchCriteria.filterValues.equipmentId = this.equipmentObject.id;
                this.refresh();

                this.tabObject.canAdd = this.canAdd;
                this.tabObject.canDelete = this.canDelete;
            }
        });

        // bind functions to scope so they can be called from the grid
        this.$scope.editVisualInspection = (item) => this.editVisualInspection(item);
    }

    $onInit() {

        // security
        this.canDelete = this.ocSecuritySvc.isAuthorised('Security.Components.VisualInspections.Delete', AccessTypes.readWrite);
        this.canAdd = this.ocSecuritySvc.isAuthorised('Security.Components.VisualInspections.Add', AccessTypes.readWrite);

        this.tabObject.export = () => this.exportVisualInspectionList();
        this.tabObject.add = () => this.addVisualInspection();
        this.tabObject.refresh = () => this.refresh();

        this.tabObject.canExport = false;
        this.tabObject.canRefresh = true;

        if (this.autoLoad)
            this.$timeout(() => this.refresh());
    }

    exportVisualInspectionList() {
        this.exportSvc.exportData(
            this.componentUrls.exportVisualInspections,
            this.searchCriteria,
            this.exportFilename
        );
    }

    refresh() {
        if (this.visualInspectionGrid.refresh)
            this.visualInspectionGrid.refresh();
    }

    onVisualInspectionsLoaded(response: any) {
        for (let r of response.result || [])
            r.frequencyDisplay = this.helperSvc.frequencyDaysToText(r.inspectionFrequencyDays);

        return response;
    }

    onVisualInspectionsDatabound() {
        this.tabObject.canExport = this.visualInspectionGrid.getTotalItemsCount() > 0;
    }

    editVisualInspection(item?) {

        let initParams: IVisualInspectionHistory_InitParams = {
            visualInspectionId: item.id,
            equipmentId: this.equipmentObject.id
        };

        this.visualInspectionService.viewVisualInspectionHistory(
            initParams,
            this.equipmentObject.serialNumber.formatted,
            () => this.refresh()
        );
    }

    addVisualInspection() {

        let initParams: IVisualInspectionItem_InitParams = {
            componentTypeId: this.equipmentObject.componentType.key,
            equipmentId: this.equipmentObject.id,
            showDate: true,
            commitOnSave: true
        };

        this.visualInspectionService.openVisualInspectionItem(initParams, () => this.refresh());
    }
}

class ocEquipmentVisualInspectionComponent implements ng.IComponentOptions {
    public bindings = {
        tabObject: '=',
        equipmentObject: '=',
        autoLoad: '='
    };
    public template = tmpl;
    public controller = ocEquipmentVisualInspectionCtrl;
    public controllerAs = 'vm';
}

angular.module('app.directives').component('ocEquipmentVisualInspection', new ocEquipmentVisualInspectionComponent());