/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Renders the column cell content of the MultiColumnComboBox. To define a column cell template, nest an `<ng-template>` tag
 * with the `kendoMultiColumnComboBoxColumnCellTemplate` directive inside the [`<kendo-combobox-column>`]({% slug api_dropdowns_comboboxcolumncomponent %}) tag
 * ([see example]({% slug templates_multicolumncombobox %})).
 *
 * The current [`column`]({% slug api_dropdowns_comboboxcolumncomponent %}) and data item are available as context variables:
 *
 * - `let-dataItem="dataItem"` (`any`) - The current data item. Also available as implicit context variable.
 * - `let-column="column"` ([`ColumnComponent`]({% slug api_dropdowns_comboboxcolumncomponent %})) - The current column configuration obejct.
 */
var ColumnCellTemplateDirective = /** @class */ (function () {
    function ColumnCellTemplateDirective(templateRef) {
        this.templateRef = templateRef;
    }
    ColumnCellTemplateDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoMultiColumnComboBoxColumnCellTemplate]'
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.TemplateRef])
    ], ColumnCellTemplateDirective);
    return ColumnCellTemplateDirective;
}());
exports.ColumnCellTemplateDirective = ColumnCellTemplateDirective;
