<!--Main-->
<div class="widget-flex-box">
    <div class="widget-number-link">
        <h1><b>{{result.changedCompletedMaintenanceSession}}</b></h1>
    </div>

    <div class="widget-number-unit">
        <p>{{ui.completed}}</p>
    </div>
</div>

<!--Subtext-->
<div class="widget-description" *ngIf="isIncludeSubtext" [ngClass]="isIncludeSubtext ? 'small-font' : ''">
    <h4>
        {{description}}
    </h4>
</div>
