/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var date_pipe_1 = require("./date.pipe");
var number_pipe_1 = require("./number.pipe");
var pipes = [
    date_pipe_1.DatePipe,
    number_pipe_1.NumberPipe
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }}docs/ts/latest/guide/ngmodule.html)
 * definition for the Intl services.
 */
var IntlModule = /** @class */ (function () {
    function IntlModule() {
    }
    IntlModule = tslib_1.__decorate([
        core_1.NgModule({
            declarations: [pipes],
            exports: [pipes]
        })
    ], IntlModule);
    return IntlModule;
}());
exports.IntlModule = IntlModule;
