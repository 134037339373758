import { Component, ViewChild } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { LiveForecastTyreElement, LiveForecastTyreGroup, LiveForecastTyreResponse, LiveForecastTyreSelectedFilterItem } from '../../service/Interface/Widget.interface';
import { LiveForecastDialogUi } from '../../service/Interface/WidgetConstant.interface';
import { WidgetRxjsService } from '../../service/rxjs/WidgetRxjs.service';
import { DialogRxjsService } from './../../service/rxjs/DialogRxjs.service';
import { LiveForecastTyreWidgetHelper } from './../../service/widgetHelper/LiveForecastTyreWidget.helper';
import { WidgetConstant } from './../../service/widgetHelper/WidgetConstant';

@Component({
    selector: 'dialog-live-forecast-tyre-element',
    templateUrl: './live-forecast-tyre-element.component.html',
    styleUrls: ['./../../site-dashboard.component.scss']
})

export class DialogLiveForecastTyreElementComponent {
    public ui: LiveForecastDialogUi;
    public hasReceivedElement: boolean = false;
    public state: State = {
        skip: 0,
        take: 10,
    };
    //Display Selection Dropdown
    public displaySelectionList: Array<string>;
    public selectedDisplay: string;

    //Duration Selection Dropdown
    public durationSelectionList: Array<string>;
    public selectedDuration: string;

    //Result
    public result: LiveForecastTyreResponse;
    public selectedResult: LiveForecastTyreGroup;
    public selectedTyre: Array<LiveForecastTyreElement>;
    public displayTyre: Array<LiveForecastTyreElement>;
    public filterOption: LiveForecastTyreSelectedFilterItem = {
        filter: {
                tyreSize: [],
                tyreSpecification: [],
                vehicleSerialNumber: [],
                vehicleSpecification: []
            },
        tyreSizeList: [],
        tyreSpecificationList: [],
        vehicleSerialNumberList: [],
        vehicleSpecificationList: [],
    };

    constructor(private widgetRxjs: WidgetRxjsService, private dialogRxjs: DialogRxjsService, private liveForecastTyreHelper: LiveForecastTyreWidgetHelper, private constant: WidgetConstant) {
        this.ui = this.constant.getLiveForecastDialog();
        this.subscribeLiveForecastDialogListener();
    }
    subscribeLiveForecastDialogListener() {
        this.dialogRxjs.liveForecastTyreDialog.subscribe(element => {
            if (element.action) {
                this.setLiveForecastTyreElement(element.siteDashboardWidgetId);
            }
        });
    }

    dismissLiveForecastTyreElement() {
        this.dialogRxjs.setLiveForecastTyreDialogActionListener(this.dialogRxjs.defaultLiveForecastDialog);
    }

    setLiveForecastTyreElement(siteDashboardWidgetId: string) {
        this.widgetRxjs.liveForecastTyreWidget.subscribe(widgets => {
            widgets.forEach(widget => {
                if (widget.siteDashboardWidgetId === siteDashboardWidgetId) {
                    this.result = widget.result;
                    this.selectedDisplay = this.liveForecastTyreHelper.getDefaultSelected();
                    this.displaySelectionList = this.liveForecastTyreHelper.getDefaultSelectionDropDownMenu();
                    this.onChangeSelectedDisplay();
                    this.hasReceivedElement = true;
                }
            });
        });
    }

    onChangeSelectedDisplay() {
        this.selectedResult = this.liveForecastTyreHelper.getSelectedTyreGroup(this.selectedDisplay, this.result);
        this.setDurationSelection();
    }

    setDurationSelection() {
        this.durationSelectionList = this.liveForecastTyreHelper.getDefaultDurationDropDownMenu(this.selectedResult.filterOption.validate);
        this.selectedDuration = this.liveForecastTyreHelper.getDefaultDurationSelected(this.durationSelectionList);
        this.onChangeSelectedDuration();
    }

    onChangeSelectedDuration() {
        this.selectedTyre = this.liveForecastTyreHelper.getSelectedTyre(this.selectedDuration, this.selectedResult);
        this.displayTyre = this.selectedTyre;
        this.filterOption = this.liveForecastTyreHelper.getSelectedFilterOption(this.selectedDuration, this.selectedResult);
    }

    onChangeTyreSize(newTyreSizeList: Array<string>) {
        this.filterOption.filter.tyreSize = this.liveForecastTyreHelper.setSelectedTyreSizeList(newTyreSizeList, this.filterOption.tyreSizeList);
        this.onChangeFilter();
    }

    onChangeTyreSpecification(newTyreSpecList: Array<string>) {
        this.filterOption.filter.tyreSpecification = this.liveForecastTyreHelper.setSelectedTyreSpecificationList(newTyreSpecList, this.filterOption.tyreSpecificationList);
        this.onChangeFilter();
    }

    onChangeVehicleSpecification(newVehicleSpecList: Array<string>) {
        this.filterOption.filter.vehicleSpecification = this.liveForecastTyreHelper.setSelectedVehicleSpecificationList(newVehicleSpecList, this.filterOption.vehicleSpecificationList);
        this.onChangeFilter();
    }

    onChangeVehicleSerialNumber(newVehicleSerialList: Array<string>) {
        this.filterOption.filter.vehicleSerialNumber = this.liveForecastTyreHelper.setSelectedVehicleSerialNumberList(newVehicleSerialList, this.filterOption.vehicleSerialNumberList);
        this.onChangeFilter();
    }

    onChangeFilter() {
        let apply = this.liveForecastTyreHelper.setApplyFilter(this.filterOption);
        this.displayTyre = this.selectedTyre.filter(tyre => {
            return apply.tyreSizeList.includes(tyre.size) && apply.tyreSpecificationList.includes(tyre.specification)
                && apply.vehicleSerialNumberList.includes(tyre.fittedVehicle.serialNumber) && apply.vehicleSpecificationList.includes(tyre.fittedVehicle.specification)
        });
    }

    setSearchByVehicleSerialNumber(selectedVehicleSerialNumber: string) {
        localStorage.setItem('vehicleSerialNumber', selectedVehicleSerialNumber);
    }

    setSearchByComponentSerialNumber(selectedComponentSerialNumber: string) {
        localStorage.setItem('componentSerialNumber', selectedComponentSerialNumber);
    }

}