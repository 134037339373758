//import angular from 'angular';

export default class AdminUrls {
    private readonly apiPath = baseUrl + 'api/';
    private readonly adminPath = 'admin/';
    private readonly performanceBatchController = 'performanceBatch/';
    private readonly notificationsController = 'notifications/';
    private readonly siteDetailController = 'siteDetail/';
    private readonly configurationController = 'configuration/';
    private readonly productionCrewController = 'productionCrew/';
    private readonly sensorController = 'sensor/';
    private readonly specificationController = 'specifications/';
    private readonly systemHierarchyController = 'systemhierarchy/';
    private readonly integrationController = 'integration/';
    private readonly multilingualController = 'multilingual/';
    private readonly dashboardController = 'dashboard/';

    //Users, tabs, permissions
    public readonly getUserSearchDefaults = 'usersecurity/getUserSearchDefaults';
    public readonly getUsers = baseApiUrl + 'usersecurity/getUsers';
    public readonly searchExport = 'usersecurity/searchExportUser';
    public readonly getUsersFiltered = 'usersecurity/getUsersFiltered';
    public readonly getUsersList = baseApiUrl + 'usersecurity/getUsersList';
    public readonly getUserSiteList = baseApiUrl + 'usersecurity/getUserSiteList';
    public readonly getUserDetails = 'usersecurity/getUserDetails/{0}';                    
    public readonly getLanguageList = baseApiUrl + this.multilingualController + 'getLanguageList';
    public readonly getNewUserDefaultLanguage = 'usersecurity/getNewUserDefaultLanguage';
    public readonly getClients = baseApiUrl + 'systemhierarchy/getClients';
    public readonly getSites = baseApiUrl + 'systemhierarchy/getSites';
    public readonly getSystemHierarchyCurrencyType = 'systemhierarchy/getSystemHierarchyCurrencyType';
    public readonly getSitesCQS = "systemhierarchy/getSites";
    public readonly getDestinationSites = baseApiUrl + "systemhierarchy/getDestinationSites";
    public readonly getRoles = baseApiUrl + 'security/getRoles';
    public readonly addNewUserRoles = 'userroles/addNewUserRoles';
    public readonly editRole = 'userroles/editUserRoles';
    public readonly addPermission = 'rolepermission/addPermission';
    public readonly allowToCopyRoles = 'usersecurity/allowToCopyRoles';
    public readonly getIsUserAdmin = 'usersecurity/getIsUserAdmin';
    public readonly copyUserRoles = 'userroles/copyUserRoles';
    public readonly getUnassignedYubikeys = baseApiUrl + 'yubikey/getUnassignedYubikeys';
    public readonly getUserActiveStatus = 'usersecurity/getUserActiveStatus/{0}';
    public readonly createNewUser = 'usersecurity/createNewUser';
    public readonly changeUserStatus = 'usersecurity/changeUserStatus';
    public readonly revalidateUser = 'usersecurity/revalidateUser';
    public readonly updateUserDetails = 'usersecurity/updateUserDetails';
    public readonly mergeUsers = 'usersecurity/mergeUsers';
    public readonly deleteUser = 'usersecurity/deleteUser';
    public readonly getAssignableRoleClients = baseApiUrl + 'rolepermission/getAssignableRoleClients';
    public readonly getAssignableRoleSites = baseApiUrl + 'rolepermission/getAssignableRoleSites';
    public readonly checkPermissionAtHierarchy = 'userSecurity/checkPermissionAtHierarchy';

    public readonly deleteAlertSubscription = this.adminPath + this.notificationsController + 'deleteAlertSubscription';
    public readonly getUserAlertSubscriptions = baseApiUrl + this.adminPath + this.notificationsController + 'getUserAlertSubscriptions';
    public readonly saveNotificationSettings = this.adminPath + this.notificationsController + 'saveNotificationSettings';

    public readonly getComponentTypes = baseApiUrl + 'equipment/common/getEquipmentTypes';
    public readonly getCostAccessTypes = baseApiUrl + 'security/getCostAccessTypes';
    public readonly getCostAccessTypeHide = baseApiUrl + 'security/getCostAccessTypeHide';
    public readonly getManufacturers = baseApiUrl + 'systemhierarchy/getManufacturers';
    public readonly getComponentOwners = baseApiUrl + 'systemhierarchy/getComponentOwners';
    public readonly getUserRoles = baseApiUrl + 'usersecurity/getUserRoles';
    public readonly exportUserRoles = 'usersecurity/exportUserRoles';
    public readonly getUserRole = 'usersecurity/getUserRole';
    public readonly deleteUserRole = 'usersecurity/deleteUserRole';
    public readonly listPeople = baseApiUrl + 'userSecurity/listPeople';

    public readonly getNotificationRecipientDetails = 'userSecurity/getNotificationRecipientDetails';

    //roles permissions
    public readonly listRoleTypes = baseApiUrl + 'rolepermission/listRoleTypes';
    public readonly listRoleTypesCode = 'rolepermission/listRoleTypes';
    public readonly getRoleTypesCode = 'rolepermission/getRoleTypes';
    public readonly getRoleTypesForPermission = 'rolepermission/getRoleTypesForPermission';
    public readonly getRoleTypesNoManufacturer = baseApiUrl + 'rolepermission/getRoleTypesNoManufacturer';
    public readonly getRoleTypes = baseApiUrl + 'rolepermission/getRoleTypes';

    public readonly switchSite = 'usersecurity/switchSite';

    // performance batches
    public readonly searchPerformanceBatches = this.apiPath + this.adminPath + this.performanceBatchController + 'search';
    public readonly deletePerformanceBatch = this.adminPath + this.performanceBatchController + 'delete';
    public readonly getPerformanceBatchLifeTypes = this.apiPath + this.adminPath + this.performanceBatchController + 'getLifeTypes';
    public readonly getPerformanceBatchTypes = this.apiPath + this.adminPath + this.performanceBatchController + 'getBatchTypes';
    public readonly getPerformanceBatchDetails = this.adminPath + this.performanceBatchController + 'getDetails';
    public readonly getPerformanceWarrantyTypes = this.apiPath + this.adminPath + this.performanceBatchController + 'getWarrantyTypes';
    public readonly getPerformanceWarrantyMeasures = this.apiPath + this.adminPath + this.performanceBatchController + 'getWarrantyMeasures';
    public readonly getPerformanceBatchInclusionOptions = this.adminPath + this.performanceBatchController + 'getInclusionOptions';
    public readonly addPerformanceBatch = this.adminPath + this.performanceBatchController + 'add';
    public readonly modifyPerformanceBatch = this.adminPath + this.performanceBatchController + 'modify';
    public readonly getPerformanceBatchSerialNumbers = this.apiPath + this.adminPath + this.performanceBatchController + 'getSerialNumbers';
    public readonly getPerformanceBatchSpecifications = this.apiPath + this.adminPath + this.performanceBatchController + 'getSpecifications';
    public readonly getPerformanceBatchDefaults = this.adminPath + this.performanceBatchController + 'getDefaults';
    public readonly PerformanceBatchsearchExport = this.adminPath + this.performanceBatchController + 'searchExport';
    public readonly getPermissions = baseApiUrl + 'rolepermission/getPermissionList';
    public readonly getReportPermissions = baseApiUrl + 'rolepermission/getReportPermissionList';
    public readonly getPermissionLevels = 'rolepermission/getPermissionLevel';
    public readonly modifyRole = 'rolepermission/modifyRole';
    public readonly updateAccessType = 'rolepermission/saveRolePermission';
    public readonly allowToAddNewRole = 'rolepermission/allowToAddNewRole';
    public readonly exportUsersToExcel = 'usersgridexport/exportmsexcel';
    public readonly exportUsersToWord = 'usersgridexport/exportmsword';

    public readonly getAlertTypes = this.apiPath + this.adminPath + this.notificationsController + 'getAlertTypes';
    public readonly exportAlertTypes = this.adminPath + this.notificationsController + 'exportAlertTypes';
    public readonly getAlertTriggers = this.apiPath + this.adminPath + this.notificationsController + 'getAlertTriggers';
    public readonly getAlertHistory = this.apiPath + this.adminPath + this.notificationsController + 'getAlertHistory';
    public readonly exportAlertTriggers = this.adminPath + this.notificationsController + 'exportAlertTriggers';
    public readonly getAlertTrigger = this.adminPath + this.notificationsController + 'getAlertTrigger';
    public readonly addModifyAlertTrigger = this.adminPath + this.notificationsController + 'addModifyAlertTrigger';
    public readonly deleteAlertTrigger = this.adminPath + this.notificationsController + 'deleteAlertTrigger';
    public readonly updateAlertEscalations = this.adminPath + this.notificationsController + 'updateAlertEscalations';
    public readonly listAlertSubscriptions = this.adminPath + this.notificationsController + 'listAlertSubscriptions';
    public readonly updateAlertSubscriptions = this.adminPath + this.notificationsController + 'updateAlertSubscriptions';
    public readonly getSystemAlertTrigger = this.adminPath + this.notificationsController + 'getSystemAlertTrigger';
    public readonly addModifySystemAlertTrigger = this.adminPath + this.notificationsController + 'addModifySystemAlertTrigger';
    public readonly updateSystemAlertSubscriptions = this.adminPath + this.notificationsController + 'updateSystemAlertSubscriptions';
    
    //siteDetails
    public readonly getSiteDetails = this.adminPath + this.siteDetailController + 'getSiteDetails';
    public readonly getSiteKpis = this.apiPath + this.adminPath + this.siteDetailController + 'getSiteKpis';
    public readonly saveSiteConfigurations = this.adminPath + this.siteDetailController + 'saveSiteConfigurations';
    public readonly saveSiteLocalisation = this.adminPath + this.siteDetailController + 'saveSiteLocalisation';
    public readonly saveSiteDetails = this.adminPath + this.siteDetailController + 'saveSiteDetails';
    public readonly saveSiteKpi = this.adminPath + this.siteDetailController + 'saveSiteKpi';

    //configuration
    public readonly getConfigurationTypes = this.adminPath + this.configurationController + 'getConfigurationTypes';

    //productionCrews
    public readonly searchProductionCrews = this.apiPath + this.adminPath + this.productionCrewController + 'search';
    public readonly searchProductionCrewsExport = this.adminPath + this.productionCrewController + 'searchExport';
    public readonly modifyProductionCrew = this.adminPath + this.productionCrewController + 'modifyProductionCrew';
    public readonly addProductionCrew = this.adminPath + this.productionCrewController + 'addProductionCrew';

    //sensors
    public readonly searchSensors = this.apiPath + this.adminPath + this.sensorController + 'search';
    public readonly searchSensorsExport = this.adminPath + this.sensorController + 'searchExport';
    public readonly getComponentsWithSensors = this.apiPath + this.adminPath + this.sensorController + 'getComponentsWithSensors';
    public readonly getVehiclesWithSensors = this.apiPath + this.adminPath + this.sensorController + 'getVehiclesWithSensors';
    public readonly modifySensor = this.adminPath + this.sensorController + 'modifySensor';
    public readonly addSensor = this.adminPath + this.sensorController + 'addSensor';

    //specifications
    public readonly searchComponentSpecifications = this.apiPath + this.adminPath + this.specificationController + 'searchComponentSpecifications';
    public readonly searchComponentSpecificationsExport = this.adminPath + this.specificationController + 'searchComponentSpecificationsExport';

    public readonly searchVehicleSpecifications = this.apiPath + this.adminPath + this.specificationController + 'searchVehicleSpecifications';
    public readonly searchVehicleSpecificationsExport = this.adminPath + this.specificationController + 'searchVehicleSpecificationsExport';

    public readonly setPositionLabel = this.adminPath + this.specificationController + 'setPositionLabel';

    public readonly addSpecificationToSite = this.adminPath + this.specificationController + 'addSpecificationToSite';
    public readonly removeSpecificationFromSite = this.adminPath + this.specificationController + 'removeSpecificationFromSite';
    public readonly getSpecification = this.adminPath + this.specificationController + 'getSpecification';

    public readonly addComponentSpecification = this.adminPath + this.specificationController + 'addComponentSpecification';
    public readonly addVehicleSpecification = this.adminPath + this.specificationController + 'addVehicleSpecification';

    public readonly modifyComponentSpecification = this.adminPath + this.specificationController + 'modifyComponentSpecification';
    public readonly deleteComponentSpecification = this.adminPath + this.specificationController + 'deleteComponentSpecification';

    public readonly modifyVehicleSpecification = this.adminPath + this.specificationController + 'modifyVehicleSpecification';
    public readonly deleteVehicleSpecification = this.adminPath + this.specificationController + 'deleteVehicleSpecification';

    public readonly specificationObsoleteAtSite = this.adminPath + this.specificationController + 'specificationObsoleteAtSite';
    public readonly getSpecificationPrices = this.apiPath + this.adminPath + this.specificationController + 'getSpecificationPrices';
    public readonly addModifyGenericPrice = this.adminPath + this.specificationController + 'addModifyGenericPrice';
    public readonly deleteGenericPrice = this.adminPath + this.specificationController + 'deleteGenericPrice';

    public readonly getValidFitments = this.apiPath + this.adminPath + this.specificationController + 'getValidFitments';
    public readonly removeValidFitment = this.adminPath + this.specificationController + 'removeValidFitment';
    public readonly addModifyValidFitment = this.adminPath + this.specificationController + 'addModifyValidFitment';

    public readonly getEquipmentTypes = 'equipment/common/getEquipmentTypes';

    public readonly searchSites = this.apiPath + this.systemHierarchyController + 'searchSites';
    public readonly searchSitesExport = this.systemHierarchyController + 'exportSites';
    public readonly getSite = this.systemHierarchyController + 'getSite';
    public readonly getSystemHierarchyCurrency = this.systemHierarchyController + 'getSystemHierarchyCurrency';
    public readonly addModifySite = this.systemHierarchyController + 'addModifySite';

    // report integration
    public readonly getIntegrationReports = this.apiPath + this.integrationController + 'getIntegrationReports';
    public readonly searchReportIntegrations = this.apiPath + this.integrationController + 'searchReportIntegrations';
    public readonly exportReportIntegrations = this.integrationController + 'exportReportIntegrations';
    public readonly addReportIntegration = this.integrationController + 'addReportIntegration';
    public readonly modifyReportIntegration = this.integrationController + 'modifyReportIntegration';

    public readonly getResources = this.apiPath + this.multilingualController + 'getResources';
    public readonly searchResources = this.apiPath + this.multilingualController + 'searchResources';
    public readonly exportResources = this.multilingualController + 'exportResources';
    public readonly addModifyLanguageResource = this.multilingualController + 'addModifyLanguageResource';

    public readonly getCompanies = baseApiUrl + this.systemHierarchyController + "getCompanies";
    public readonly searchCompanies = baseApiUrl + this.systemHierarchyController + "searchCompanies";
    public readonly exportCompanies = this.systemHierarchyController + "exportCompanies";
    public readonly addModifyCompany = this.systemHierarchyController + "addModifyCompany";

    // dashboard
    public readonly getSiteDashboards = this.apiPath + this.adminPath + this.dashboardController + 'getSiteDashboards';
    public readonly saveSiteDashboards = this.adminPath + this.dashboardController + 'saveSiteDashboards';
    public readonly deleteSiteDashboards = this.adminPath + this.dashboardController + 'deleteSiteDashboards';
    public readonly getSiteDashboardWidgets = this.apiPath + this.adminPath + this.dashboardController + 'getSiteDashboardWidgets';
    public readonly getDashboardWidgets = this.adminPath + this.dashboardController + 'getDashboardWidgets';
    public readonly getDashboardWidgetConfiguration = this.adminPath + this.dashboardController + 'getDashboardWidgetConfiguration';
    public readonly getDashboardDisplayRoleTypes = this.apiPath + this.adminPath + this.dashboardController + 'getDashboardDisplayRoleTypes';
    public readonly saveSiteDashboardWidget = this.adminPath + this.dashboardController + 'saveSiteDashboardWidget';
    public readonly getSiteDashboardWidget = this.adminPath + this.dashboardController + 'getSiteDashboardWidget';
    public readonly deleteSiteDashboardWidget = this.adminPath + this.dashboardController + 'deleteSiteDashboardWidget';
}

angular.module('app').service('adminUrls', AdminUrls);