//import angular from 'angular';
import FileManagement from '../../../services/fileManagement';
import BrowserSvc from '../../../services/browserSvc';
import OcDateSvc from '../../../services/ocDateSvc';
import OcConfigSvc from '../../../services/ocConfigSvc';
import tmpl from './vehicleReceive.html';



class VehicleReceiveCtrl implements ng.IController {

    form: ng.IFormController;

    instanceId: guid = uuid();

    status = ScreenStatus.loading;

    wizardControl: any = {};
    serialGridControl: any = {};

    kplaceholder: string = this.amtXlatSvc.xlat('vehicle.searchReceiptedVehicles');
    previousText: string = this.amtXlatSvc.xlat('common.wzrd_previous_label');
    nextText: string;
    summaryTitle: string;
    erroredReceivalsTitle: string;
    errorMessage: string;

    receiveInProgress = false;
    hidePreviousButton = true;
    hideNextButton = true;
    canFinishReceive = false;
    showStart = false;
    hasPendingFittedComponents = false;
    pending = false;
    reloadReceived = false;
    processing = false;
    inSummary = false;

    showSiteSerialNumber: boolean;
    siteSerialNumberMandatory: boolean;
    processingConditions: boolean;

    vehicleConditions: any[] = [];
    referenceData: { specifications: any; } = { specifications: null };

    erroredReceivals: any[] = [];
    receiptedVehicles: any[] = [];

    summaryItems: any[] = [];
    serialStepColumns: any[] = [];

    selectedCondition: any;
    selectedReceipted: any;

    serials: any[] = [];

    //pendingVehicleId: guid;
    lastReceivedId: guid;

    details: any;

    static $inject = ['$scope', 'amtXlatSvc', 'ocConfigSvc', 'dataBroker', '$state', 'confirmSvc', '$timeout', 'errorReporter',
        'fileManagement', 'notifySvc', 'appConfig', 'WindowFactory', 'vehicleReceiveFactory', 'ocDateSvc', '$location', 'browserSvc'];

    constructor(private $scope: ng.IScope, private amtXlatSvc: IAmtXlatSvc, private ocConfigSvc: OcConfigSvc, private dataBroker: IDataBroker,
        private $state: ng.ui.IStateService, private confirmSvc: IConfirmSvc, private $timeout: ng.ITimeoutService, private errorReporter: IErrorReporter,
        private fileManagement: FileManagement, private notifySvc: INotifySvc, private appConfig: IAppConfig,
        private WindowFactory: IWindowFactory, private vehicleReceiveFactory: any, private ocDateSvc: OcDateSvc, private $location: ng.ILocationService, private browserSvc: BrowserSvc) {

        // when errored receivals are added or removed
        this.$scope.$watch(() => this.erroredReceivals, () => {
            this.showStart = !this.browserSvc.isMobile && this.erroredReceivals.length > 0;
        }, true);

        // connect functions to dynamic grid api for vehicle serial step
        this.$scope.$watch(() => this.serialGridControl, (newValue) => {
            if (newValue)
                this.serialGridControl.editVehicleSerial = (dataItem) => this.editVehicleSerial(dataItem);
        });

        this.$scope.$watch(() => this.details, (newValue, oldValue) => {
            if (oldValue !== newValue)
                this.wizardControl.setPristine(2);
        }, true);

        // React to changing of the component collection
        // little bit dirty, makes things work well though
        this.$scope.$watch(() => this.serials, () => {

            if (this.serials && this.selectedCondition) {

                let count = this.selectedCondition.isKnown ? 0 : 1;

                if (this.selectedCondition.isKnown) {
                    for (let serial of this.serials || []) {
                        if (serial.selected)
                            count++;
                    }
                }

                if (this.serials.length === 0 || count === 0 || this.processing) {
                    this.canFinishReceive = false;
                    this.wizardControl.setPristine(3);
                } else {
                    this.canFinishReceive = true;
                }

                this.$timeout();
            }

        }, true);

        // selecting a receipted vehicles from the dropdown (mobile only)
        this.$scope.$watch(() => this.selectedReceipted, async () => {

            if (this.selectedReceipted) {

                try {

                    let response = await this.dataBroker.getPendingReceival(this.selectedReceipted.key);

                    this.hidePreviousButton = true;
                    this.wizardControl.goToStep(3);

                    this.inSummary = true;
                    this.lastReceivedId = response.id;
                    this.reloadReceived = true;

                    this.initialiseConditionList(true, false);

                    let vehicles = response.vehicles;
                    let vehicle = response.vehicles[0]; // for quick shared data reference

                    this.selectedCondition = this.vehicleConditions.find(condition => condition.id === vehicle.conditionType);

                    this.hasPendingFittedComponents = vehicles.some(v => v.hasFittedComponents);

                    await this.loadVehicleReferenceData();

                    this.serials = vehicles.map(v => {

                        let item = {
                            id: v.equipmentId || this.selectedReceipted.key,
                            manufacturerSerialNumber: v.manufacturerSerialNumber,
                            siteSerialNumber: v.siteSerialNumber,
                            attachments: v.eventDetails.attachments,
                            combinedDateTime: ocDateSvc.addLocalTimeZoneOffset(v.eventDetails.eventDate),
                            hasHoursMeter: v.hasHoursMeter,
                            hasDistanceMeter: v.hasDistanceMeter,
                            dataFields: undefined,
                            hours: null,
                            distance: null,
                            specification: {
                                id: v.specificationId,
                                name: v.specificationName,
                                description: v.specificationName
                            },
                            comments: v.eventDetails.comment,
                            selected: true,
                            valid: true,
                            fittedComponents: undefined,
                            fittedComponentUpdates: v.fittedComponentUpdates,
                            specificationDescription: undefined                            
                        };

                        if (v.conditionType === ReceiveConditionType.transferred)
                            item.specificationDescription = this.amtXlatSvc.xlat('vehicle.receiveSpecificationAndFitted', v.specificationName);

                        let hoursReading = v.eventDetails.readingDetails.readings.find(r => r.readingEventTypeName === ReadingTypeName.hours);

                        if (hoursReading)
                            item.hours = hoursReading.values[0].value;

                        let distanceReading = v.eventDetails.readingDetails.readings.find(r => r.readingEventTypeName === ReadingTypeName.distance);

                        if (distanceReading)
                            item.distance = distanceReading.values[0].value;

                        return item;
                    });

                    this.summaryItems = this.vehicleReceiveFactory.buildReceiveSummary(this.selectedCondition);

                    this.$timeout();

                } catch (error) {
                    this.errorReporter.logError(error, this.amtXlatSvc.xlat('equipment.pendingReceiptedDetailsFail'));
                }
            }
        }, true);
    }

    async $onInit() {

        try {
            await this.dataBroker.checkRefData();
        } catch {
            this.$location.url('/mobile/landing');
            return;
        }

        this.details = this.vehicleReceiveFactory.getDefaultVehicleDetails();

        this.showSiteSerialNumber = this.ocConfigSvc.user.site.settings.tyreMaintenance.siteSerialNumber.vehicle != ConfigurationOption.hide;
        this.siteSerialNumberMandatory = this.ocConfigSvc.user.site.settings.tyreMaintenance.siteSerialNumber.vehicle == ConfigurationOption.mandatory;

        this.processingConditions = true;

        this.startWizard();
    }

    loadWizard() {
        this.status = ScreenStatus.loading;

        this.initialiseConditionList(false, true);

        this.status = ScreenStatus.ready;
    }

    // Load Vehicle Conditions
    async initialiseConditionList(skipValidation: boolean, loadKnownVehicles: boolean) {

        this.processingConditions = true; // If the user has hit "previous", then this property will be false, so make sure it's true.                     

        // 1. Push all applicable conditions to the array so that buttons/spinners are shown
        this.vehicleConditions = this.vehicleReceiveFactory.getVehicleConditions(
            skipValidation,
            this.ocConfigSvc.user.site.settings.receive.allowThirdPartyVehicles,
            null
        );

        // 2. Load the relevant data.
        try {
            if (loadKnownVehicles)
                await this.getKnownVehicles(false, true, true, null);
        } finally {
            this.processingConditions = false;
        }
    }

    // click on a condition type
    async conditionClick(condition) {

        this.processing = true;

        try {

            let retainExistingData: boolean = false;

            // no previous condition or the condition has changed
            if (!this.selectedCondition || this.selectedCondition.id !== condition.id) {
                // reset values
                this.details = this.vehicleReceiveFactory.getDefaultVehicleDetails();
                this.serials = [];
            } else {
                retainExistingData = true;
            }

            this.selectedCondition = condition;

            this.pending = condition.id === ReceiveConditionType.pending;

            await this.loadVehicleReferenceData();

            this.hidePreviousButton = false;
            this.hideNextButton = false;
            this.nextText = this.amtXlatSvc.xlat("common.wzrd_finish_label");

            if (condition.isKnown === true) {

                this.serialStepColumns = this.vehicleReceiveFactory.getSerialStepColumns(condition.id);

                this.wizardControl.goToStep(2);
                this.receiveInProgress = true;

                if (!retainExistingData) {

                    if (this.selectedCondition.vehicles) {

                        this.serials = this.selectedCondition.vehicles

                        this.setPrefill();

                    } else {

                        if (this.pending) {

                            try {

                                let response = await this.dataBroker.getPendingVehicles(this.ocConfigSvc.user.site.id, true);

                                if (response && response.result) {

                                    this.serials = response.result.map(v => {
                                        return {
                                            id: v.id,
                                            manufacturerSerialNumber: v.serialNumber.manufacturer,
                                            dataFields: v.dataFields,
                                            hasFittedComponents: v.hasFittedComponents,
                                            received: v.received
                                        };
                                    });

                                    this.setPrefill();
                                }

                            } catch (error) {
                                this.errorReporter.logError(error, 'vehicle.pendingRetreiveFail');
                            }

                        } else {
                            await this.getKnownVehicles(true, false, true, [condition.associatedStatusType]);
                        }
                    }
                }
            } else {
                this.wizardControl.goToStep(1);
                this.receiveInProgress = true;
            }
        } finally {
            this.processing = false;
        }
    }

    private vehicleSort() {
        return (a, b) => {

            let cond1 = a.onSite < b.onSite ? 1 : (a.onSite > b.onSite ? -1 : 0); // vehicle is on current site
            let cond2 = a.site.localeCompare(b.site); // site name
            let cond3 = a.serialNumber.formatted.localeCompare(b.serialNumber.formatted); // serial number

            return cond1 || cond2 || cond3;
        };
    }

    // retrieve all known vehicles that can be received (including pending 3rd party vehicles if requested)
    async getKnownVehicles(setSerialsCollection: boolean, includePendingVehicles: boolean, excludeReceived: boolean, statuses?: Array<string>) {

        if (!statuses) {
            let conditions = this.vehicleReceiveFactory.getVehicleConditions(true, this.ocConfigSvc.user.site.settings.receive.allowThirdPartyComponents, 'All');
            statuses = conditions.filter(c => c.isKnown && c.associatedStatusType).map(c => c.associatedStatusType);
        }

        let response = await this.dataBroker.getVehiclesInStatus(
            this.ocConfigSvc.user.client.id,
            this.ocConfigSvc.user.site.id,
            statuses,
            null,
            false,
            excludeReceived
        );

        if (response && response.result) {

            // flag which vehicles are already at the current site
            for (let r of response.result)
                r.onSite = r.siteId === this.ocConfigSvc.user.site.id;

            response.result.sort(this.vehicleSort());

            let knownConditions = this.vehicleConditions.filter(c => c.isKnown && c.id !== ReceiveConditionType.pending);

            for (let condition of knownConditions) {

                // only do this if we searched for the status associated to the condition
                if (statuses.indexOf(condition.associatedStatusType) > -1) {

                    let vehicles = response.result.filter(c => c.status === condition.associatedStatusType);

                    let vehicleCondition = this.vehicleConditions.find(c => c.id === condition.id);

                    if (vehicleCondition) {

                        if (vehicles.length > 0) {

                            for (let v of vehicles) {

                                v.manufacturerSerialNumber = v.serialNumber.manufacturer;

                                if (vehicleCondition.id === ReceiveConditionType.transferred)
                                    v.specificationDescription = this.amtXlatSvc.xlat('vehicle.receiveSpecificationAndFitted', v.specification.description);

                                if (v.siteId === this.ocConfigSvc.user.site.id) {
                                    v.siteSerialNumber = v.serialNumber.site;
                                } else {
                                    v.siteSerialNumber = null;
                                }

                                // keep track of original hours/distance for validation
                                v.originalHours = angular.copy(v.hours);
                                v.originalDistance = angular.copy(v.distance);

                                // map fitments
                                if (v.positions) {

                                    v.fittedComponents = v.positions.map(p => {
                                        return {
                                            wheelPosition: p.wheelPositionLabel,
                                            tyre: p.fitments.find(f => f.equipmentTypeName.toLowerCase() === EquipmentTypeName.tyre),
                                            rim: p.fitments.find(f => f.equipmentTypeName.toLowerCase() === EquipmentTypeName.rim),
                                            chain: p.fitments.find(f => f.equipmentTypeName.toLowerCase() === EquipmentTypeName.chain)
                                        }
                                    });

                                    v.hasRims = v.fittedComponents.some(c => c.rim);
                                    v.hasChains = v.fittedComponents.some(c => c.chain);
                                }
                            }

                            vehicleCondition.disabled = false;
                        }

                        vehicleCondition.loading = false;
                        vehicleCondition.vehicles = vehicles;

                        if (setSerialsCollection) {

                            this.serials = vehicles;
                            this.setPrefill();
                        }
                    }
                }
            }
        }

        if (includePendingVehicles) {

            try {
                let pendingVehiclesResult = (await this.dataBroker.getPendingVehicles(this.ocConfigSvc.user.site.id, excludeReceived)).result;

                let pendingCondition = this.vehicleConditions.find(c => c.id === ReceiveConditionType.pending);

                if (pendingCondition) {

                    if (pendingVehiclesResult.length > 0)
                        pendingCondition.disabled = false;

                    pendingCondition.loading = false;

                    let pendingVehicles = pendingVehiclesResult.map(v => {
                        return {
                            id: v.id,
                            manufacturerSerialNumber: v.serialNumber.manufacturer,
                            dataFields: v.dataFields,
                            hasFittedComponents: v.hasFittedComponents,
                            received: v.received
                        };
                    });

                    pendingCondition.vehicles = pendingVehicles;

                    if (setSerialsCollection) {
                        this.serials = pendingVehicles;
                        this.setPrefill();
                    }
                }

            } catch (error) {
                this.processing = false;
                this.errorReporter.logError(error, 'vehicle.pendingRetrieveFail');
            }
        }
    }

    setPrefill() {

        for (let serial of this.serials || []) {

            if (!this.selectedCondition.detailFields.date.prefill)
                serial.combinedDateTime = null;

            if (!this.selectedCondition.detailFields.sapFields.prefill)
                serial.dataFields = null;

            if (!this.selectedCondition.detailFields.manufacturerSerialNumber.prefill)
                serial.manufacturerSerialNumber = null;

            if (!this.selectedCondition.detailFields.siteSerialNumber.prefill)
                serial.siteSerialNumber = null;

            if (!this.selectedCondition.detailFields.specification.prefill)
                serial.specificaiton = null;

            if (!this.selectedCondition.detailFields.hasDistanceMeter.prefill)
                serial.hasDistanceMeter = null;

            if (!this.selectedCondition.detailFields.hours.prefill)
                serial.hours = null;

            if (!this.selectedCondition.detailFields.hoursNewMeter.prefill)
                serial.hoursNewMeter = null;

            if (!this.selectedCondition.detailFields.distance.prefill)
                serial.distance = null;

            if (!this.selectedCondition.detailFields.distanceNewMeter.prefill)
                serial.distanceNewMeter = null;

            if (!this.selectedCondition.detailFields.comment.prefill)
                serial.comments = null;
        }
    }

    // load reference data
    async loadVehicleReferenceData() {

        let getSpecificationsCriteria = {
            siteIds: [this.ocConfigSvc.user.site.id]
        }

        try {
            let response = await this.dataBroker.getSpecifications(getSpecificationsCriteria, true);

            this.referenceData.specifications = response.result;
        } catch (error) {
            this.errorReporter.logError(error, 'vehicle.specRetrieveFail');
        }
    }

    // skip over errored vehicles receives and perform a new receive
    startReceiving() {
        this.erroredReceivals = []; // Clearing this will hide the grid and show the wizard.
    }

    // get list of pending receivals for dropdwon (mobile only)
    async loadPendingReceivals() {

        let response = await this.dataBroker.getPendingReceivals('vehicles', null);

        if (response) {

            if (this.browserSvc.isMobile) {

                if (response.data && response.data.length) {

                    this.receiptedVehicles = response.data.map(receival => {

                        let multipleVehicles = receival.vehicles.length > 1;

                        let multipleSpecs = multipleVehicles && receival.vehicles.every(v => v.specificationName === receival.vehicles[0].specificationName);

                        let multipleDates = multipleVehicles && receival.vehicles.every(v =>
                            v.eventDetails.eventDate.toString().toDateTimeFormat(this.appConfig.dateFormat.shortDate) ===
                            receival.vehicles[0].eventDetails.eventDate.toString().toDateTimeFormat(this.appConfig.dateFormat.shortDate)
                        );

                        return {
                            key: receival.id,
                            name: String.format("{0}{1} - ({2}) - {3}",
                                multipleSpecs ? this.amtXlatSvc.xlat("vehicle.multiple_label") : receival.vehicles[0].specificationName,
                                multipleVehicles ? "" :
                                    " - " + (receival.vehicles[0].siteSerialNumber
                                        ? receival.vehicles[0].siteSerialNumber + " (" + receival.vehicles[0].manufacturerSerialNumber + ")"
                                        : receival.vehicles[0].manufacturerSerialNumber),
                                receival.vehicles[0].conditionType,
                                multipleDates ? this.amtXlatSvc.xlat("vehicle.multiple_label") : receival.vehicles[0].eventDetails.eventDate.toString().toDateTimeFormat(this.appConfig.dateFormat.shortDate)
                            )
                        }
                    });
                } else {
                    this.receiptedVehicles = undefined;
                }

                this.$timeout();

            } else {
                this.erroredReceivals = response.data;

                let errCount = (this.erroredReceivals || []).length;
                this.erroredReceivalsTitle = this.amtXlatSvc.xlat(errCount == 1 ? 'vehicle.erroredReceivalsCount_singular' : 'vehicle.erroredReceivalsCount_plural', errCount);
            }
        }
    }

    async menu() {
        if (this.receiveInProgress) {
            try {
                await this.confirmSvc.confirmMessage('vehicle.confirmWizardAbort_Title', 'vehicle.confirmWizardAbort_Content');
            } catch (error) {
                return; // they cancelled
            }
        }

        this.$state.go('mobile.landing');
    }

    // move to previous step
    onPrevious(step) {

        let currentStep = step.$$prevSibling;

        while (currentStep.$$prevSibling !== null) {
            currentStep.selected = false;
            currentStep.visited = false;
            currentStep.invalid = false;
            currentStep = currentStep.$$prevSibling;
        }

        step.selected = false;
        step.visited = false;
        step.invalid = false;

        // retain data unless we are going 'back to start' from summary
        this.restart(false, step.stepId !== '4');
    }

    // move to next step
    async onNext(step) {

        // finish the receive
        if (step.stepId === '2' || step.stepId === '3') {

            this.processing = true;

            // new/2nd hand (single vehicle receive)
            if (step.stepId === '2') {

                if (this.details) {

                    this.details.selected = true;
                    this.serials = [this.details];

                    try {
                        let error = await this.vehicleReceiveFactory.validateVehicle(this.details, this.selectedCondition, this.serials);

                        this.errorMessage = error

                        if (this.errorMessage)
                            throw this.errorMessage;

                    } catch (error) {

                        this.processing = false;
                        this.canFinishReceive = true;

                        //Stay on the current step
                        if (step.stepId === '2') {
                            this.wizardControl.goToStep(0);
                        } else {
                            this.wizardControl.goToStep(1);
                        }

                        this.wizardControl.updateInvalid(2, true);

                        return;
                    }

                } else {
                    this.processing = false;
                    throw 'no vehicle details';
                }
            }

            try {

                await this.receiveVehicles();

                this.processing = false;
                this.hidePreviousButton = true;
                this.hideNextButton = true;
                this.receiveInProgress = false;

                this.wizardControl.goToStep(3); // go to summary
                this.wizardControl.updateInvalid(3, false);

            } catch (error) {

                this.processing = false;

                this.canFinishReceive = true;

                if (this.browserSvc.isMobile) {

                    let errors = [];

                    if (error.data) {
                        errors = error.data.errors.map(err => {
                            return { msg: err.Message, subErrors: err.InnerException };
                        });
                    } else {
                        errors.push(error);
                    }

                    // show something here which indicate what went wrong
                    let data = {
                        component: 'upload-errors',
                        errors: errors
                    };

                    this.WindowFactory.openItem({
                        component: 'upload-errors',
                        caption: this.amtXlatSvc.xlat('component.saveFailHeading'),
                        initParams: data,
                        width: 600,
                        modal: true
                    });

                } else {
                    if (typeof error === 'string') {
                        this.notifySvc.error(error);
                    }
                }

                //Stay on the current step
                if (step.stepId === '2') {
                    this.wizardControl.goToStep(0);
                    this.wizardControl.updateInvalid(2, true);
                } else {
                    this.wizardControl.goToStep(1);
                    this.wizardControl.updateInvalid(3, true);
                }
            }
        }
    }

    // receive vehicles
    async receiveVehicles() {

        if (this.serials.length > 0) {

            let vehiclesToReceive;

            if (this.selectedCondition.isKnown) {

                vehiclesToReceive = this.serials.filter(s => s.selected);

                // check at least 1 vehicle is checked for receive
                if (!vehiclesToReceive || vehiclesToReceive.length == 0)
                    throw this.amtXlatSvc.xlat("component.receiveNoComponentsSelected");

            } else {
                vehiclesToReceive = this.serials;
            }
         
            let saveCriteria = {
                vehicles: [],
                createdDate: new Date(),
                isDesktop: !this.browserSvc.isMobile
            };

            this.hasPendingFittedComponents = vehiclesToReceive.some(v => v.hasFittedComponents);

            for (let v of vehiclesToReceive || []) {

                for (let attachment of v.attachments || [])
                    attachment.source = AttachmentType.equipmentEventAttachment;

                await this.fileManagement.processFileUploads(v.attachments);

                let vehicle =
                {
                    id: v.id,
                    equipmentId: v.id,
                    conditionType: this.selectedCondition.id,
                    manufacturerSerialNumber: v.manufacturerSerialNumber,
                    siteSerialNumber: v.siteSerialNumber,
                    specificationId: v.specification.id,
                    specificationName: v.specification.description,
                    equipmentTypeName: 'Vehicle',
                    hasDistanceMeter: v.hasDistanceMeter,
                    hasHoursMeter: v.specification.hasHoursMeter,
                    hasFittedComponents: v.hasFittedComponents,
                    fittedComponentUpdates: [],
                    isKnown: this.selectedCondition.isKnown,
                    eventDetails: {
                        isEdit: false,
                        attachments: angular.copy(v.attachments || []).map(f => this.fileManagement.clearData(f)),
                        eventDate: this.ocDateSvc.removeLocalTimeZoneOffset(v.combinedDateTime),
                        comment: v.comments,                        
                        movementDetails: {
                            receiveDetails: {}
                        },
                        readingDetails: {
                            readings: []
                        }
                    }
                };

                if (v.hasDistanceMeter) {
                    vehicle.eventDetails.readingDetails.readings.push({
                        readingEventTypeName: ReadingTypeName.distance,
                        isNewMeter: v.distanceNewMeter === true,
                        unitOfMeasureAbbreviation: this.ocConfigSvc.user.site.units.distance.unitAbbreviation,
                        values: [
                            {
                                value: (v.distance !== undefined && v.distance !== null ? v.distance : 0),
                                sequence: 1
                            }
                        ]
                    });
                }

                if (v.specification.hasHoursMeter) {
                    vehicle.eventDetails.readingDetails.readings.push({
                        readingEventTypeName: ReadingTypeName.hours,
                        isNewMeter: v.hoursNewMeter === true,
                        unitOfMeasureAbbreviation: ReadingTypeName.hours,
                        values: [
                            {
                                value: (v.hours !== undefined && v.hours !== null ? v.hours : 0),
                                sequence: 1
                            }
                        ]
                    });
                }

                if (v.fittedComponents && v.fittedComponents.length > 0) {

                    for (let c of v.fittedComponents) {

                        if (c.tyre) {
                            let tyreUpdate = {
                                equipmentId: c.tyre.equipmentId,
                                readings: {
                                    readings: []
                                }
                            };

                            if (c.tyre.depth1) {
                                tyreUpdate.readings.readings.push({
                                    readingEventTypeName: ReadingTypeName.treadDepth,
                                    isNewMeter: false,
                                    unitOfMeasureAbbreviation: this.ocConfigSvc.user.site.units.depth.unitAbbreviation,
                                    values: [{ value: c.tyre.depth1, sequence: 1 }, { value: c.tyre.depth2, sequence: 2 }]
                                });
                            }

                            vehicle.fittedComponentUpdates.push(tyreUpdate);
                        }

                        if (c.chain) {
                            let chainUpdate = {
                                equipmentId: c.chain.equipmentId,
                                readings: {
                                    readings: []
                                }
                            };

                            if (c.chain.depth1) {
                                chainUpdate.readings.readings.push({
                                    readingEventTypeName: ReadingTypeName.chainDepth,
                                    isNewMeter: false,
                                    unitOfMeasureAbbreviation: this.ocConfigSvc.user.site.units.depth.unitAbbreviation,
                                    values: [{ value: c.chain.depth1, sequence: 1 }, { value: c.chain.depth2, sequence: 2 }]
                                });
                            }

                            vehicle.fittedComponentUpdates.push(chainUpdate);
                        }
                    }
                }

                saveCriteria.vehicles.push(vehicle);
            }

            try {

                let response = await (this.selectedCondition.isKnown ?
                    (this.selectedCondition.id === ReceiveConditionType.pending ?
                        this.dataBroker.receivePendingVehicles(this.lastReceivedId, saveCriteria) :
                        this.dataBroker.receiveKnownVehicles(this.lastReceivedId, saveCriteria)) :
                    this.dataBroker.receiveUnknownVehicles(this.lastReceivedId, saveCriteria));

                this.lastReceivedId = response;
                this.reloadReceived = false;

                //this.erroredReceive = null;
                this.inSummary = true;

                if (this.browserSvc.isMobile)
                    this.loadPendingReceivals();

                this.serials = vehiclesToReceive;

                this.summaryTitle = this.selectedCondition.showFittedComponents ?
                    this.amtXlatSvc.xlat('vehicle.receiveWizardSummaryTitle_Fitted') :
                    this.amtXlatSvc.xlat('vehicle.receiveWizardSummaryTitle_Normal');

                this.summaryItems = this.vehicleReceiveFactory.buildReceiveSummary(this.selectedCondition);

                if (vehiclesToReceive.length > 1) {
                    this.notifySvc.success(
                        this.amtXlatSvc.xlat(this.selectedCondition.showFittedComponents ? 'vehicle.receiveWizardSuccessful_multipleFitted' : 'vehicle.receiveWizardSuccessful_multiple', vehiclesToReceive.length)
                    );
                } else {
                    this.notifySvc.success(
                        this.amtXlatSvc.xlat(this.selectedCondition.showFittedComponents ? 'vehicle.receiveWizardSuccessful_fitted' : 'vehicle.receiveWizardSuccessful')
                    );
                }

                // mobile audit logging
                for (let vehicle of saveCriteria.vehicles) {
                    this.dataBroker.logAuditEntry('Vehicle Receive', 'Receive',
                        String.format('{0} - {1} ({2})', vehicle.manufacturerSerialNumber, vehicle.specificationName,
                            this.ocDateSvc.toReportString(this.ocDateSvc.addLocalTimeZoneOffset(vehicle.eventDetails.eventDate)))
                    );
                }

                //return $q.resolve();

            } catch (error) {
                this.errorReporter.logError(error);
                throw error;
            }
        }
    }

    // delete a pending receipted vehicle (mobile only - click of delete button on summary screen)
    async deleteReceiptedVehicles() {

        if (!this.lastReceivedId || !this.browserSvc.isMobile)
            return;

        try {
            await this.confirmSvc.confirmMessage_cancelPrimary('vehicle.confirmDeleteReceived_Title', 'vehicle.confirmDeleteReceived_Content');
        } catch (error) {
            return; // they cancelled
        }

        try {
            await this.dataBroker.deleteReceiptedVehicles(this.lastReceivedId);

            let filesToDelete = [];
            for (let s of this.serials || []) {
                for (let a of s.attachments || [])
                    filesToDelete.push(a.id);
            }

            await this.fileManagement.deleteFiles(filesToDelete);

            this.notifySvc.success(this.amtXlatSvc.xlat("vehicle.receiveWizardDeleteSuccessful"));

            this.restart(false, false);
            this.selectedReceipted = null;

        } catch (error) {
            this.errorReporter.logError(error);
            this.status = ScreenStatus.error;
        }
    }

    // edit a pending receipted vehicle (mobile only - click of edit button on summary screen)
    async editReceiptedVehicles() {

        if (this.lastReceivedId) {

            this.inSummary = false;
            this.receiveInProgress = true;

            this.serialStepColumns = this.vehicleReceiveFactory.getSerialStepColumns(this.selectedCondition.id);

            this.hidePreviousButton = true;
            this.hideNextButton = false;
            this.nextText = this.amtXlatSvc.xlat("common.wzrd_finish_label");

            let receivedSerials = angular.copy(this.serials);

            if (this.selectedCondition.isKnown) {

                this.processing = true;

                let currentStep = this.wizardControl.goToStep(2);
                currentStep.visited = false;

                try {

                    await this.getKnownVehicles(true, this.selectedCondition.id === ReceiveConditionType.pending, !this.reloadReceived, [this.selectedCondition.associatedStatusType]);

                    for (let rs of receivedSerials || []) {

                        let serial = this.serials.find(s => s.id === rs.id);

                        if (serial) {

                            rs.site = serial.site;
                            rs.originalDistance = serial.originalDistance;
                            rs.originalHours = serial.originalHours;
                            rs.dataFields = serial.dataFields;

                            rs.fittedComponents = serial.fittedComponents;
                            rs.hasRims = rs.fittedComponents.some(c => c.rim);
                            rs.hasChains = rs.fittedComponents.some(c => c.chain);

                            for (let u of rs.fittedComponentUpdates || []) {

                                let tyreFitment = rs.fittedComponents.find(c => c.tyre && c.tyre.equipmentId === u.equipmentId);
                                let chainFitment = rs.fittedComponents.find(c => c.chain && c.chain.equipmentId === u.equipmentId);

                                if (tyreFitment) {
                                    let depth = u.readings.readings.find(r => r.readingEventTypeName === ReadingTypeName.treadDepth);

                                    if (depth) {
                                        tyreFitment.tyre.depth1 = depth.values.find(d => d.sequence === 1).value;
                                        tyreFitment.tyre.depth2 = depth.values.find(d => d.sequence === 2).value;
                                    }
                                }

                                if (chainFitment) {
                                    let depth = u.readings.readings.find(r => r.readingEventTypeName === ReadingTypeName.chainDepth);

                                    if (depth) {
                                        chainFitment.chain.depth1 = depth.values.find(d => d.sequence === 1).value;
                                        chainFitment.chain.depth2 = depth.values.find(d => d.sequence === 2).value;
                                    }
                                }
                            }
                        }
                    }

                    this.serials = this.serials.filter(s => !s.received);

                    for (let rs of receivedSerials || [])
                        this.serials.unshift(rs);

                    this.$timeout();

                } catch (error) {
                    this.errorReporter.logError(error, "VehicleReceive-GetPendingVehicle");
                } finally {
                    this.processing = false;
                }

            } else {

                if (this.serials)
                    this.details = this.serials[0];

                let currentStep = this.wizardControl.goToStep(1);
                currentStep.visited = false;

                this.$timeout();
            }

            if (this.browserSvc.isMobile)
                this.$timeout(() => this.loadPendingReceivals());
        }
    }

    // jump to component receive (click of 'Receive Fitted Components' button on summary screen, only appears if Pending condition type and there are fitted components for the vehicle)
    receiveFittedComponents() {

        if (this.browserSvc.isMobile) {
            this.$state.go('mobile.receiveComponent', {
                initParams: {
                    vehicleSerialNumbers: this.serials.map(s => s.manufacturerSerialNumber),
                    fromStocktake: false,
                    received: false,
                    fromVehicleReceive: true
                }
            });
        } else {

            this.WindowFactory.openItem({
                component: 'component-receive',
                caption: this.amtXlatSvc.xlat('component.receiveTitle'),
                initParams: {
                    vehicleSerialNumbers: this.serials.map(s => s.manufacturerSerialNumber),
                    fromStocktake: false,
                    fromVehicleReceive: true,
                    received: false
                },
                canClose: true,
                windowRelatedRecordId: this.instanceId,
                footerOff: true,
                width: 1300
                //height: 820
            });
        }
    }

    // $scope level funtions
    // This needs to be scope so that it can called from the grid
    //async loadPendingVehicle(vehicle) {

    //    this.pendingVehicleId = vehicle.id;
    //    this.manSerialNumber = vehicle.serialNumber.manufacturer;
    //    this.siteSerialNumber = vehicle.serialNumber.site;
    //    this.hasDistanceMeter = vehicle.hasDistanceMeter;
    //    this.hasFittedComponents = vehicle.hasFittedComponents;

    //    try {
    //        let response = await this.dataBroker.getPendingComponents(this.ocConfigSvc.user.site.id, this.amtConstants.emptyGuid, true, true, false);

    //        let component = response.result.find(c => c.fittedToVehicleSerial === this.manSerialNumber);
    //        this.hasPendingFittedComponents = !!component;

    //    } catch (error) {
    //        this.errorReporter.logError(error, 'component.pendingRetreiveFail');
    //    }

    //    this.selectedDistanceUnit = this.distanceUnits.find(u => u.id === vehicle.distanceUOM.key);

    //    this.hasHoursMeter = true;

    //    this.comment = vehicle.comment;
    //    this.sapFields = vehicle.dataFields;

    //    await this.loadVehicleReferenceData();

    //    this.wizardControl.goToStep(2); // vehicle details
    //    this.hideNextButton = false;
    //    this.nextText = this.amtXlatSvc.xlat('common.wzrd_finish_label');

    //    this.setDateTimeToNow(); // default to now
    //}

    editVehicleSerial(dataItem) {

        this.WindowFactory.openItem({
            component: 'vehicle-receive-popup',
            caption: this.selectedCondition.showFittedComponents ? this.amtXlatSvc.xlat('vehicle.receiveEditVehicleAndFitments') : this.amtXlatSvc.xlat('vehicle.receiveEditVehicle'),
            canClose: false,
            initParams: {
                vehicle: dataItem,
                selectedCondition: this.selectedCondition,
                referenceData: this.referenceData,
                serials: this.serials
            },
            onDataChangeHandler: (vehicle) => {
                dataItem.comments = vehicle.comments;
                dataItem.manufacturerSerialNumber = vehicle.manufacturerSerialNumber;
                dataItem.siteSerialNumber = vehicle.siteSerialNumber;
                dataItem.combinedDateTime = vehicle.combinedDateTime;
                dataItem.specification = vehicle.specification;
                dataItem.hours = vehicle.hours;
                dataItem.hoursNewMeter = vehicle.hoursNewMeter;
                dataItem.hasDistanceMeter = vehicle.hasDistanceMeter;
                dataItem.distance = vehicle.distance;
                dataItem.distanceNewMeter = vehicle.distanceNewMeter;
                dataItem.valid = vehicle.valid;
                dataItem.selected = vehicle.selected;
                dataItem.fittedComponents = vehicle.fittedComponents;
                dataItem.attachments = vehicle.attachments;
            },
            width: 980,
            modal: true
        });
    }

    // restart
    async restart(requireConfirmation: boolean, retainData: boolean) {

        if (requireConfirmation) {
            try {
                await this.confirmSvc.confirmMessage('vehicle.confirmWizardAbort_Title', 'vehicle.confirmWizardAbort_Content');
            } catch (error) {
                return; // they cancelled
            }
        }

        this.restartProcess(retainData);
    }

    restartProcess(retainData: boolean) {

        this.receiveInProgress = false;
        this.inSummary = false;
        this.hideNextButton = true;
        this.hidePreviousButton = true;

        this.lastReceivedId = null;
        this.selectedReceipted = null;

        this.serialStepColumns = [];
        this.summaryItems = [];

        this.nextText = this.amtXlatSvc.xlat('common.wzrd_next_label'); // "Next �".
        this.previousText = this.amtXlatSvc.xlat('component.receiveWizardPrevious');

        if (!retainData) {
            // reset values                            
            this.selectedCondition = null;
            this.details = this.vehicleReceiveFactory.getDefaultVehicleDetails();
            this.serials = [];
            this.errorMessage = null;
        }

        this.wizardControl.restart();

        this.startWizard();
    }

    startWizard() {

        if (this.browserSvc.isMobile)
            this.loadPendingReceivals();

        this.loadWizard();
    }
}

class VehicleReceiveComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '='
    };
    public template = tmpl;
    public controller = VehicleReceiveCtrl;
    public controllerAs = 'vm';
}

angular.module('app.vehicle').component('vehicleReceive', new VehicleReceiveComponent());