    import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { indexByMap, makeLookup, mapFrom, mapFromWithKeyMember } from '../modules/site/conditionMonitoring/helpers';


interface IVehicleApi {
    serialNo: string,
    specificationId: guid,
    specification: string
};

export interface IVehicle extends IVehicleApi {
    id: guid;
};


@Injectable({
    providedIn: 'root'
})
export class VehiclesOnsiteService {
    constructor(private http: HttpClient) { }

    private httpVehiclesRequest$: Observable<Record<string, IVehicle>> = this.http.get<Record<string, IVehicle>>(baseApiUrl + 'equipment/vehicle/vehicleList').pipe(
        //shareReplay(1)
    );

    private vehicleMapBySerialNo: Map<string, string> = new Map();
    private vehicleMapById$ = this.httpVehiclesRequest$.pipe(
        tap(v => this.vehicleMapBySerialNo = makeLookup(v, 'serialNo')),
        map(v => mapFromWithKeyMember<IVehicle>(v, 'id'))
    );

    public get vehicles$() {
        return this.vehicleMapById$;
    }

    public getVehicle$(id$: Observable<string>) {
        return combineLatest(id$, this.vehicleMapById$).pipe(
            switchMap(s => of(s[1]?.get(s[0]) || null))
        );
    }

    public getVehicleBySerialNo$(serialNo: string) {
        return this.vehicleMapById$.pipe(
            switchMap(s => of(s.get(this.vehicleMapBySerialNo.get(serialNo)) || null))
        );
    }
}
