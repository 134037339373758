/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Represents the title template of the Kendo UI TabStrip.
 * To define the template, nest a `<ng-template>` tag with the `kendoTabTitle` directive inside the component tag.
 *
 * > The `kendoTabTitle` directive overrides the TabStripTab [title]({% slug api_layout_tabstriptabcomponent %}#toc-title) option.
 *
 * @example
 * ```ts-preview
 *
 * _@Component({
 *     selector: 'my-app',
 *     template: `
 *         <kendo-tabstrip>
 *           <kendo-tabstrip-tab [selected]="true">
 *             <ng-template kendoTabTitle>
 *               <h4>Custom Title</h4>
 *             </ng-template>
 *             <ng-template kendoTabContent>
 *               <h3>Content 1</h3>
 *             </ng-template>
 *           </kendo-tabstrip-tab>
 *
 *           <kendo-tabstrip-tab title="Sofia">
 *             <ng-template kendoTabContent>
 *               <h3>Content 2</h3>
 *             </ng-template>
 *           </kendo-tabstrip-tab>
 *         </kendo-tabstrip>
 *     `
 * })
 *
 * class AppComponent {}
 *
 * ```
 */
var TabTitleDirective = /** @class */ (function () {
    function TabTitleDirective(templateRef) {
        this.templateRef = templateRef;
    }
    TabTitleDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoTabTitle]'
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.TemplateRef])
    ], TabTitleDirective);
    return TabTitleDirective;
}());
exports.TabTitleDirective = TabTitleDirective;
