//import angular from 'angular';


angular.module('app').factory('convert', [
         function () {

             var kilometersFactor = 1.0;
             var mmFactor = 1.0;

             var milesFactor = 0.6214;
             var thirtySecondFactor = 1.2598425184;

            return {
                convertTo: function (reading, unit) {                    
                    var fromUnitFactor;
                    var toUnitFactor;
                    switch (reading.unit) {
                        case 'mm': {
                            fromUnitFactor = 1;
                            break;
                        }
                        case '32nds': {
                            fromUnitFactor = thirtySecondFactor;
                            break;
                        }
                        case 'mi': {
                            fromUnitFactor = milesFactor;
                            break;
                        }
                        case 'km': {
                            fromUnitFactor = 1;
                            break;
                        }
                        default: {
                            console.error("Conversion " + reading.unit + " unit unknown using default unit");
                            fromUnitFactor = 1;
                            break;
                        }
                    }
                    switch (unit) {
                        case 'mm': {
                            toUnitFactor = 1;
                            break;
                        }
                        case '32nds': {
                            toUnitFactor = thirtySecondFactor;
                            break;
                        }
                        case 'mi': {
                            toUnitFactor = milesFactor;
                            break;
                        }
                        case 'km': {
                            toUnitFactor = 1;
                            break;
                        }
                        default: {
                            console.error("Conversion " + unit + " unit unknown using default unit");
                            toUnitFactor = 1;
                            break;
                        }
                    }
                    reading.unit = unit;
                    reading.value = reading.value * (toUnitFactor / fromUnitFactor);
                    return reading.value;
                }
            };
         }
    ]);
