import * as _ from 'underscore';
import { Component, OnInit, HostBinding, ChangeDetectionStrategy, AfterViewInit, Input, Output, ChangeDetectorRef, ViewEncapsulation, EventEmitter } from '@angular/core';
import { AlertTypes, ConditionMonitoringService, IDisplayVehicle, IPositionData, ISelectedPosition } from '../../condition-monitoring.service'
import { map, mergeMap, shareReplay, switchMap, tap } from 'rxjs/operators';
import { DatePipe, KeyValue } from '@angular/common';
import { XlatPipe } from '../../../../../amt/i18n/xlat-pipe';
import { of, Observable, forkJoin, ReplaySubject } from 'rxjs';
import { Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { HttpClient, HttpParams } from '@angular/common/http';
import { VehicleSpecsService } from '../../../../../services/vehicle-specs.service'
import OcConfigSvc from '../../../../../services/ocConfigSvc';

export const ICON_WIDTH = 196;
export const ICON_HEIGHT = 196;

  
@Component({
    selector: 'condition-details',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './condition-details.component.html',
    styleUrls: ['./condition-details.component.scss'],
    providers: [
        { provide: 'desiredWidth', useValue: ICON_WIDTH },
        { provide: 'desiredHeight', useValue: ICON_HEIGHT },
        ConditionMonitoringService]
})
export class ConditionDetailsComponent implements OnInit {
    @Input()
    vehicle: IDisplayVehicle;
    maxWidth = 666;
    currentVehicle$: Observable<any> = null; //Observable for schematic - can probably do a different way
    isProcessing: boolean;
    selectedComponent: ISelectedPosition = null;
    currentVehicle = null; //Variable to store the currentVehicle so we can access it later
    @Output() finishedProcessing = new EventEmitter<boolean>();

    constructor(
        private http: HttpClient, public monSvc: ConditionMonitoringService, private xlatPipe: XlatPipe,
        @Inject('WindowFactory') private WindowFactory: any, private specSvc: VehicleSpecsService,
        private ocConfigSvc: OcConfigSvc, private ref: ChangeDetectorRef,
        private pipe: DatePipe
    ) {
    }

    @HostBinding("style.--img-w") public readonly cssImgWidth = ICON_WIDTH;
    @HostBinding("style.--img-h") public readonly cssImgHeight = ICON_HEIGHT;

    ngOnInit(): void {
//        this.isProcessing = true;
        this.currentVehicle$ = this.monSvc.getVehicle(this.vehicle.equipmentId).pipe(tap(x => { this.doneProcessing(); this.currentVehicle = x; }));
    }

    private doneProcessing() {
        this.finishedProcessing.emit(true);
    }

    //This is fired from ocVehicleSchematic when a tyre is selected
    tyreSelected(tyre: IPositionData) {
        if (tyre.component.type !== "Tyre" || (!tyre.component.equipmentId || tyre.component.equipmentId.length === 0))
            return; //Should throw an error?

        //If it is the currently selected tyre then unselect it? How does that work with the ocschematic...
        if (this.selectedComponent !== null && this.selectedComponent.equipmentId == tyre.component.equipmentId) {
            this.selectedComponent = null;
        } else {
            this.selectedComponent = tyre.component;

            this.selectedComponent.fittedVehicle = this.vehicle.serialNo;
            this.selectedComponent.percentWorn = ((1 - tyre.component.remainingDepth.value / tyre.component.primaryOTD.value) * 100).toFixed(this.ocConfigSvc.general.percentageWornDecimals).toString() + "%";
            this.selectedComponent.positionLabel = tyre.position;
        }
        this.ref.markForCheck();1
    }

    //Public property so template can access alert types enum
    public get alertTypes(): typeof AlertTypes {
        return AlertTypes;
    }

    //Returns boolean if an alert of the specified type exists
    alertExists(alertName: string): boolean {
        return this.selectedComponent === null
            ? this.currentVehicle.vehicleAlerts.some(u => u.alertTypeName === alertName) : this.currentVehicle.vehicleAlerts.some(u => u.alertTypeName === alertName && u.equipmentId === this.selectedComponent.equipmentId);
    }

    //Returns the status of the vehicles visual inspections
    viStatus(): string {
        var items;

        if (this.selectedComponent === null) {
            items = this.currentVehicle.visualInspectionDetails.flatMap(a => a.visualInspectionItems).filter(obj => {
                if (obj.visualInspectionRecord.action !== "VisualInspectionAction.Continue to Monitor") {
                    return true;
                }
                return false;
            });
        } else {
            items = this.currentVehicle.visualInspectionDetails.filter(obj => {
                if (obj.equipmentId === this.selectedComponent.equipmentId) {
                    return true;
                }
                return false;
            }).flatMap(a => a.visualInspectionItems).filter(obj => {
                if (obj.visualInspectionRecord.action !== "VisualInspectionAction.Continue to Monitor") {
                    return true;
                }
                return false;
            });
        }

        return items.length === 0 ? "conditionmonitoring.statusok" : items.some(x => x.visualInspectionRecord.date.setDate(x.visualInspectionRecord.date.getDate() + x.visualInspectionRecord.actionDaysToResolve) < Date.now()) ? "conditionmonitoring.statusactionoverdue" : "conditionmonitoring.statusactionrequired";
    }

    //Returns a count of visual inspections. Optionally active only
    viCount(activeOnly: boolean) {
        var items;

        if (this.selectedComponent === null) {
            items = this.currentVehicle.visualInspectionDetails;
        } else {
            items = this.currentVehicle.visualInspectionDetails.filter(obj => {
                if (obj.equipmentId === this.selectedComponent.equipmentId)
                    return true;

                return false;
            });
        }

        if (activeOnly)
            return items.reduce((count, current) => count + current.visualInspectionItems.filter(obj => {
                if (obj.active) {
                    return true;
                }

                return false;
            }).length, 0);

        return items.reduce((count, current) => count + current.visualInspectionItems.length, 0);
    }

    //Get the date from the latest VI
    latestViDate(): string {
        var items = this.getItems();

        return items.length === 0 ? "-" : this.pipe.transform(items[0].visualInspectionRecord.date, 'short');
    }

    //Gets the user from the latest VI
    latestViUser(): string {
        var items = this.getItems();

        return items.length === 0 ? "-" : items[0].visualInspectionRecord.inspectedBy;
    }

    //Returns VI items sorted by date desc
    private getItems() {
        var items;

        if (this.selectedComponent === null) {
            items = this.currentVehicle.visualInspectionDetails.flatMap(a => a.visualInspectionItems).sort((a, b) => b.visualInspectionRecord.date.getTime() - a.visualInspectionRecord.date.getTime());
        } else {
            items = this.currentVehicle.visualInspectionDetails.filter(obj => {
                if (obj.equipmentId === this.selectedComponent.equipmentId) {
                    return true;
                }
                return false;
            }).flatMap(a => a.visualInspectionItems).sort((a, b) => b.visualInspectionRecord.date.getTime() - a.visualInspectionRecord.date.getTime());
        }
        return items;
    }

    percentWorn(): string {
        return this.selectedComponent === null ? "" : `(${this.selectedComponent.percentWorn})`;
    }

    tableHeaderDetails(option?: string): string {
        if (typeof (option) !== 'undefined')
            return this.selectedComponent === null ? this.xlatPipe.transform(option) : `${this.xlatPipe.transform(option)} - Pos. ${this.selectedComponent.positionLabel}`;

        return this.selectedComponent === null ? this.xlatPipe.transform("conditionmonitoring.NoTyreDetails") : `${this.xlatPipe.transform("conditionmonitoring.TyreDetails")} - Pos. ${this.selectedComponent.positionLabel}`;


    }

    showVehicle() {
        this.WindowFactory.openItem({
            component: 'vehicle-details',
            caption: this.xlatPipe.transform('equipment.openVehicle', this.vehicle.serialNo),
            windowRelatedRecordId: this.currentVehicle.id,
            initParams: {
                equipmentId: this.currentVehicle.id,
                siteId: this.selectedComponent.siteId,
                clientId: this.ocConfigSvc.user.client.id,
                serialNumber: this.vehicle.serialNo,
                showCloseOnSave: false
            },
            width: 800,
            height: 850,
            modal: false
        });
    }

    showComponent() {
        this.WindowFactory.openItem({
            component: 'component-details',
            caption: this.xlatPipe.transform('equipment.open' + this.selectedComponent.type, this.selectedComponent.serialNumber),
            windowRelatedRecordId: this.selectedComponent.equipmentId,
            initParams: {
                equipmentId: this.selectedComponent.equipmentId,
                siteId: this.selectedComponent.siteId,
                componentType: this.selectedComponent.type,
                serialNumber: this.selectedComponent.serialNumber,
                showCloseOnSave: false,
                showVi: true
            },
            width: 800,
            height: 850
        });
    }
}

