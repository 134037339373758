/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var grid_component_1 = require("../grid.component");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var utils_1 = require("../utils");
var mapParentGroup = function (parentGroup) {
    var parentGroupKeys = [];
    while (parentGroup) {
        parentGroupKeys.push({ field: parentGroup.group.field, value: parentGroup.group.value });
        parentGroup = parentGroup.parentGroup;
    }
    return parentGroupKeys;
};
var ɵ0 = mapParentGroup;
exports.ɵ0 = ɵ0;
var DEFAULT_KEY_GETTER = function (groupRowArgs) {
    return ({
        field: groupRowArgs.group.field,
        value: groupRowArgs.group.value,
        parentGroupKeys: mapParentGroup(groupRowArgs.parentGroup)
    });
};
var ɵ1 = DEFAULT_KEY_GETTER;
exports.ɵ1 = ɵ1;
/**
 * A directive which controls the expanded state of the group rows
 * ([see example]({% slug groups_expanded_state_grid %}#toc-built-in-directive)).
 */
var ExpandGroupDirective = /** @class */ (function () {
    function ExpandGroupDirective(grid) {
        this.grid = grid;
        /**
         * Fires when the expandedGroupKeys are changed.
         */
        this.expandedGroupKeysChange = new core_1.EventEmitter();
        /**
         * Specifies if the group items should be initially expanded.
         * @default false
         */
        this.groupsInitiallyExpanded = false;
        this.subscriptions = new rxjs_1.Subscription();
        this.grid.isGroupExpanded = this.isExpanded.bind(this);
        this.subscriptions.add(rxjs_1.merge(this.grid.groupExpand.pipe(operators_1.map(function (e) { return (tslib_1.__assign({ expand: true }, e)); })), this.grid.groupCollapse.pipe(operators_1.map(function (e) { return (tslib_1.__assign({ expand: false }, e)); }))).subscribe(this.toggleState.bind(this)));
    }
    Object.defineProperty(ExpandGroupDirective.prototype, "expandGroupBy", {
        /**
         * Defines the item format that will be stored in the `expandedGroupKeys`
         * ([see example]({% slug groups_expanded_state_grid %}#toc-custom-group-key-format)).
         */
        get: function () {
            return this._expandGroupBy;
        },
        set: function (key) {
            if (typeof key === 'function') {
                this._expandGroupBy = key;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExpandGroupDirective.prototype, "expandedGroupKeys", {
        /**
         * Defines the collection that will store the expanded group keys.
         */
        get: function () {
            return this._expandedGroupKeys;
        },
        set: function (expandedGroups) {
            this._expandedGroupKeys = (expandedGroups || []).slice();
        },
        enumerable: true,
        configurable: true
    });
    ExpandGroupDirective.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    Object.defineProperty(ExpandGroupDirective.prototype, "keyGetter", {
        get: function () {
            return this.expandGroupBy || DEFAULT_KEY_GETTER;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @hidden
     */
    ExpandGroupDirective.prototype.isExpanded = function (groupArgs) {
        var itemIndex = this.getItemIndex(groupArgs);
        return itemIndex > -1 ? !this.groupsInitiallyExpanded : this.groupsInitiallyExpanded;
    };
    ExpandGroupDirective.prototype.getItemIndex = function (groupArgs) {
        if (this.expandGroupBy) {
            return this.expandedGroupKeys.indexOf(this.keyGetter(groupArgs));
        }
        return this.expandedGroupKeys.findIndex(function (item) {
            var index = 0;
            var parentGroup = groupArgs.parentGroup;
            while (utils_1.isPresent(parentGroup)) {
                if (!utils_1.isPresent(item.parentGroupKeys) || !utils_1.isPresent(item.parentGroupKeys[index]) ||
                    parentGroup.group.value !== item.parentGroupKeys[index].value ||
                    parentGroup.group.field !== item.parentGroupKeys[index].field) {
                    return false;
                }
                parentGroup = parentGroup.parentGroup;
                index++;
            }
            return item.value === groupArgs.group.value && item.field === groupArgs.group.field;
        });
    };
    ExpandGroupDirective.prototype.toggleState = function (groupArgs) {
        var key = this.keyGetter(groupArgs);
        if (Boolean(this.groupsInitiallyExpanded) !== groupArgs.expand) {
            this.expandedGroupKeys.push(key);
        }
        else {
            var index = this.expandedGroupKeys.indexOf(key);
            this.expandedGroupKeys.splice(index, 1);
        }
        this.expandedGroupKeysChange.emit(this.expandedGroupKeys.slice());
    };
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ExpandGroupDirective.prototype, "expandedGroupKeysChange", void 0);
    tslib_1.__decorate([
        core_1.Input('kendoGridExpandGroupBy'),
        tslib_1.__metadata("design:type", Object),
        tslib_1.__metadata("design:paramtypes", [Object])
    ], ExpandGroupDirective.prototype, "expandGroupBy", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Array),
        tslib_1.__metadata("design:paramtypes", [Array])
    ], ExpandGroupDirective.prototype, "expandedGroupKeys", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], ExpandGroupDirective.prototype, "groupsInitiallyExpanded", void 0);
    ExpandGroupDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoGridExpandGroupBy]',
            exportAs: 'kendoGridExpandGroupBy'
        }),
        tslib_1.__metadata("design:paramtypes", [grid_component_1.GridComponent])
    ], ExpandGroupDirective);
    return ExpandGroupDirective;
}());
exports.ExpandGroupDirective = ExpandGroupDirective;
