//import angular from 'angular';
import BrowserSvc from '../../../services/browserSvc';
import OcConfigSvc from '../../../services/ocConfigSvc';
import tmpl from './tyreAdd.html';



angular.module("app.stocktake").component('tyreAdd', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: [
            'WindowFactory', '$scope', '$q', '$state', '$filter', 'enums', 'confirmSvc', 'ocConfigSvc', 'dataBroker',
            'amtXlatSvc', '$timeout', "notifySvc", "errorReporter", "browserSvc",
            function (WindowFactory: IWindowFactory, $scope: IVmScope, $q: ng.IQService, $state: ng.ui.IStateService, $filter: ng.IFilterService, enums, confirmSvc: IConfirmSvc, ocConfigSvc: OcConfigSvc, dataBroker: IDataBroker,
                amtXlatSvc: IAmtXlatSvc, $timeout: ng.ITimeoutService, notifySvc: INotifySvc, errorReporter: IErrorReporter, browserSvc: BrowserSvc) {

                var vm = this;
                $scope.vm = this;

                vm.isMobile = browserSvc.isMobile;

                vm.config = ocConfigSvc;

                this.$onInit = function () {

                    vm.grid = vm.initParams.grid;
                    vm.gridIndex = vm.initParams.gridIndex;

                    vm.element = vm.initParams.element;
                    vm.level = vm.initParams.level;

                    vm.wnd.onClose = vm.cancel;

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: 'stocktake.check',
                            name: 'checkButton',
                            click: 'check',
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: true,
                            value: 'common.cancel_label',
                            name: 'cancelButton',
                            click: 'cancel',
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        checkButton: {
                            visible: true,
                            disabled: false
                        },
                        cancelButton: {
                            visible: true,
                            cancel: false
                        }
                    };

                    vm.buttonMethods = {
                        check: vm.check,
                        cancel: vm.cancel
                    };
                };

                vm.cancel = function () {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        vm.form.dirty = false;
                        WindowFactory.closeWindow(vm.wnd);
                        return true;
                    });

                    return false;
                };

                vm.check = function () {

                    vm.serial = vm.serial.toUpperCase();

                    vm.wnd.initParams.serial = vm.serial;

                    let component = null;

                    // check if a component with the serial number already exists in the stocktake
                    for (let i = 0; i < vm.gridIndex.length; i++) {
                        if (vm.gridIndex[i].serialNumber.toUpperCase() === vm.serial) {
                            component = vm.gridIndex[i];
                        }
                    }

                    // if the component exists already in the stocktake
                    if (component) {

                        let matchedComponent = {
                            componentId: component.componentId,
                            id: component.key || component.id,
                            serialNumber: component.serialNumber,
                            status: {
                                name: component.status.name,
                                description: component.status.description
                            },
                            location: {
                                id: component.status.siteStatusTypeLocationId,
                                name: component.status.location
                            },
                            specification: {
                                id: component.equipmentTypeSpecificationTypeId,
                                name: component.description
                            },
                            toBeReceived: component.toBeReceived
                        };

                        return componentExists(matchedComponent).then(function (response) {
                            vm.wnd.onDataChanged(response);
                            WindowFactory.closeWindow(vm.wnd);
                        });

                    } else {

                        // if the component doesn't exist in the stocktake, check if it exists at all in Otracom

                        let componentType = vm.wnd.initParams.type.toLowerCase();

                        vm.wnd.processing = true;

                        dataBroker.getComponentBySerialNumber(componentType, vm.serial, vm.wnd.initParams.specification.id, vm.initParams.date).then(function (component) {

                            // if the component exists in otracom
                            if (component) {

                                let matchedComponent = {
                                    componentId: component.id,
                                    serialNumber: component.siteSerialNumber || component.manufacturerSerialNumber,
                                    status: {
                                        id: component.status.id,
                                        name: component.status.description,
                                        description: component.status.description
                                    },
                                    location: {
                                        id: component.status.siteStatusTypeLocationId,
                                        name: component.status.location
                                    },
                                    specification: {
                                        id: component.equipmentTypeSpecificationTypeId,
                                        name: component.description
                                    }
                                };

                                return componentExists(matchedComponent).then(function (response) {
                                    vm.wnd.onDataChanged(response);
                                    WindowFactory.closeWindow(vm.wnd);
                                });

                            } else {

                                // ask if they want to receive the component

                                let icon = "glyphicon-check";
                                let caption = "stocktake.receiveOptions_" + componentType;
                                let buttons = vm.isMobile ? ["framework.cancel", "stocktake.recLater", "stocktake.recNow"] : ["framework.cancel", "stocktake.recNow"];
                                let text = "stocktake.serialNotExisting";
                                let params = [amtXlatSvc.xlat('stocktake.' + componentType), vm.serial];
                                let textAlignClass = "left-box";

                                return WindowFactory.iconAlert(icon, caption, buttons, text, params, 750, textAlignClass).then(function (dlgResult) {

                                    let data = {
                                        fromStocktake: true,
                                        includeComment: true,
                                        comment: null,
                                        serialNumber: vm.serial,
                                        date: vm.wnd.initParams.date,
                                        received: null,
                                        type: componentType,
                                        location: vm.wnd.initParams.location,
                                        status: vm.wnd.initParams.status,
                                        specification: vm.wnd.initParams.specification
                                    };

                                    switch (dlgResult) {

                                        // if they opt to recieve later, add the component to the stocktake and record a comment
                                        case 'stocktake.recLater':
                                            data.received = false;                                            

                                            data.comment = amtXlatSvc.xlat('stocktake.receiveComponent',
                                                data.serialNumber, componentType, data.specification.name, data.status, data.location ? String.format(' ({0})', data.location) : ''
                                            );

                                            vm.wnd.onDataChanged(data);
                                            WindowFactory.closeWindow(vm.wnd);
                                            break;

                                        // if they opt to receive now, jump to component receive
                                        case 'stocktake.recNow':
                                            if (vm.isMobile) {
                                                // jump to component receive
                                                vm.wnd.onDataChanged(vm.wnd.initParams);
                                                WindowFactory.closeWindow(vm.wnd);
                                                vm.wnd.initParams.fromStocktake = true;

                                                $state.go('mobile.receiveComponent', { initParams: data });
                                            } else {
                                                // pop the modal dialog box for receive

                                                WindowFactory.openItem({
                                                    component: 'component-receive',
                                                    caption: amtXlatSvc.xlat('component.receiveTitle'),
                                                    initParams: data,
                                                    canClose: true,
                                                    modal: true,
                                                    windowRelatedRecordId: uuid(),
                                                    footerOff: true,
                                                    width: 1300,
                                                    height: 820,
                                                    onDataChangeHandler: function (components) {
                                                        for (var c = 0; c < components.length; c++) {
                                                            vm.wnd.onDataChanged(components[c]);
                                                        }
                                                        vm.form.dirty = false;
                                                        WindowFactory.closeWindow(vm.wnd);
                                                    }
                                                });
                                            }
                                            break;
                                    }
                                });
                            }
                        }
                        ).catch(function (error) {
                            errorReporter.logError(error);
                        }).finally(function () {
                            vm.wnd.processing = false;
                        });
                    }
                };

                function componentExists(component) {
                    return $q(function (resolve, reject) {

                        let data = {
                            componentType: vm.initParams.type.toLowerCase(),
                            serialNumber: vm.serial,
                            component: component,
                            target: {
                                specification: vm.wnd.initParams.specification,
                                status: {
                                    name: vm.wnd.initParams.status,
                                    description: vm.wnd.initParams.status
                                },
                                location: {
                                    id: vm.wnd.initParams.element.status ? vm.wnd.initParams.element.status.siteStatusTypeLocationId : null,
                                    name: vm.wnd.initParams.location
                                }
                            }
                        };

                        WindowFactory.openItem({
                            component: 'stocktake-component-exists',
                            caption: amtXlatSvc.xlat('stocktake.receiveAlreadyExists_' + data.componentType.toLowerCase()),
                            width: 900,
                            modal: true,
                            initParams: data,
                            canClose: false,
                            onDataChangeHandler: function (response) {
                                if (!!response) {
                                    return resolve(response);
                                } else {
                                    // user cancelled the dialog
                                    return reject("cancelled");
                                }
                            }
                        });
                    });
                };

                $scope.$watchGroup(['vm.form.$invalid', 'vm.wnd.processing'], function () {
                    vm.buttonStates.checkButton.disabled = vm.form.$invalid || vm.readonly || vm.wnd.processing;
                });

            }
        ]
    });
