//import angular from 'angular';
import * as _ from 'underscore';
import OcDateSvc from '../../../../../services/ocDateSvc';
import HelperSvc from '../../../../../services/helperSvc';
import tmpl from './addEditPrice.html';



class AddEditPriceCtrl implements ng.IController, IWindowController {

    wnd: IWindowObj;
    form: ng.IFormController;

    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: () => void };

    initParams: any;

    readonly = false;
    levels: any[] = [];

    defaultCurrencyType: any = null;

    data: any;

    static $inject = ['$scope', 'confirmSvc', 'adminUrls', 'WindowFactory', 'amtCommandQuerySvc', 'notifySvc', 'amtXlatSvc', 'referenceDataUrls', 'ocDateSvc', 'helperSvc', 'errorReporter'];

    constructor(private $scope: ng.IScope, private confirmSvc: IConfirmSvc, private adminUrls: IAdminUrls, private WindowFactory: IWindowFactory,
        private amtCommandQuerySvc: IAmtCommandQuerySvc, private notifySvc: INotifySvc, private amtXlatSvc: IAmtXlatSvc, private referenceDataUrls: IReferenceDataUrls,
        private ocDateSvc: OcDateSvc, private helperSvc: HelperSvc, private errorReporter: IErrorReporter) {

        this.$scope.$watchGroup([() => this.form.$invalid, () => this.form.$pristine, () => this.wnd.processing], () => {
            this.buttonStates.saveButton.disabled = this.form.$invalid || this.form.$pristine || this.wnd.processing;
            this.buttonStates.saveAndAddAnotherButton.disabled = this.form.$invalid || this.form.$pristine || this.wnd.processing;
        });
    }

    async $onInit() {

        this.wnd.processing = true;
        this.wnd.onClose = () => this.cancel();

        try {
            this.data = angular.copy(this.initParams);
            this.levels = angular.copy(this.data.accessibleLevels);

            this.WindowFactory.addButton(this, 'common.save_label', 'saveButton', () => this.save(false), true, !this.readonly, true);
            this.WindowFactory.addButton(this, 'common.saveAndAddAnother_label', 'saveAndAddAnotherButton', () => this.save(true), false, !this.readonly && !this.data.id, true);

            if (!this.data.id) {
                this.data.dateFrom = new Date(); // default date from to now
                this.data.level = _.sortBy(this.levels, l => l.key).reverse()[0];

                await this.getDefaultCurrencyType();
            }

        } finally {
            this.wnd.processing = false;
        }
    }

    async save(addAnother?: boolean) {

        // date from cannot be in future
        if (new Date(this.data.dateFrom) > new Date()) {
            this.notifySvc.error(this.amtXlatSvc.xlat('specifications.dateFromCannotBeInFuture'));
            return;
        }

        // date from must be before date to
        if (this.data.dateTo) {
            if (new Date(this.data.dateFrom) >= new Date(this.data.dateTo)) {
                this.notifySvc.error(this.amtXlatSvc.xlat('specifications.dateToBeforeDateFrom'));
                return;
            }
        }

        let result = await this.WindowFactory.iconAlert(
            'glyphicon-check',
            'specifications.updateEquipmentCosts_title',
            ['common.yes_label', 'common.no_label'],
            'specifications.updateEquipmentCosts',
            [this.data.currencyType.symbol, this.data.price]
        );

        if (!result)
            return; // they clicked the X so just cancel the save                

        let criteria = {
            id: this.data.id,
            systemHierarchyId: this.data.level.id,
            equipmentTypeSpecificationTypeId: this.data.equipmentTypeSpecificationTypeId,
            dateFrom: this.ocDateSvc.removeLocalTimeZoneOffset(this.data.dateFrom),
            dateTo: this.ocDateSvc.removeLocalTimeZoneOffset(this.data.dateTo),
            currencyTypeId: this.helperSvc.getKey(this.data.currencyType),
            amount: this.data.price,
            updateEquipmentCosts: result === 'common.yes_label'
        };

        this.wnd.processing = true;

        try {

            await this.amtCommandQuerySvc.post(this.adminUrls.addModifyGenericPrice, criteria);

            this.notifySvc.success(this.amtXlatSvc.xlat('specifications.priceSavedSuccessfully'));

            if (this.wnd.onDataChanged)
                this.wnd.onDataChanged();

            if (addAnother) {

                this.data.id = undefined;
                this.data.dateFrom = new Date();
                this.data.dateTo = null;
                this.data.price = null;
                this.data.level = _.sortBy(this.levels, l => l.key).reverse()[0];

                if (this.defaultCurrencyType) {

                    this.data.currencyTypeId = angular.copy(this.defaultCurrencyType);
                    this.form.$setPristine();

                } else {

                    try {
                        await this.getDefaultCurrencyType();
                    } finally {
                        this.form.$setPristine();
                    }
                }

            } else {
                this.closeWindow();
            }

        } catch (error) {
            this.errorReporter.logError(error);
        } finally {
            this.wnd.processing = false;
        }
    }

    closeWindow() {
        this.WindowFactory.closeWindow(this.wnd);
    }

    async cancel() {

        try {
            await this.confirmSvc.confirmSaveChange(this.form.$dirty);
        } catch {
            return; // they cancelled
        }

        this.closeWindow();
    }

    async getDefaultCurrencyType() {

        try {
            let response = await this.amtCommandQuerySvc.get(this.adminUrls.getSystemHierarchyCurrencyType, { systemHierarchyId: this.data.systemHierarchyId });

            if (response && response.data) {
                this.defaultCurrencyType = response.data;
                this.data.currencyTypeId = angular.copy(this.defaultCurrencyType);
            }
        } catch (error) {
            this.errorReporter.logError(error);
        }
    }
}

class AddEditPriceComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = AddEditPriceCtrl;
    public controllerAs = 'vm';
}

angular.module('app.admin').component('addEditPrice', new AddEditPriceComponent());