/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var calendar_messages_1 = require("./calendar-messages");
/**
 * @hidden
 */
var CalendarLocalizedMessagesDirective = /** @class */ (function (_super) {
    tslib_1.__extends(CalendarLocalizedMessagesDirective, _super);
    function CalendarLocalizedMessagesDirective(service) {
        var _this = _super.call(this) || this;
        _this.service = service;
        return _this;
    }
    CalendarLocalizedMessagesDirective_1 = CalendarLocalizedMessagesDirective;
    var CalendarLocalizedMessagesDirective_1;
    CalendarLocalizedMessagesDirective = CalendarLocalizedMessagesDirective_1 = tslib_1.__decorate([
        core_1.Directive({
            providers: [
                {
                    provide: calendar_messages_1.CalendarMessages,
                    useExisting: core_1.forwardRef(function () { return CalendarLocalizedMessagesDirective_1; }) // tslint:disable-line:no-forward-ref
                }
            ],
            selector: '[kendoCalendarLocalizedMessages]'
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService])
    ], CalendarLocalizedMessagesDirective);
    return CalendarLocalizedMessagesDirective;
}(calendar_messages_1.CalendarMessages));
exports.CalendarLocalizedMessagesDirective = CalendarLocalizedMessagesDirective;
