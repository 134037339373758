import { defer, from, of, Subscription } from 'rxjs';
import { catchError, finalize, first } from 'rxjs/operators';
import {
    ISaveVisualInspectionCriteria, IVisualInspectionAction, IVisualInspectionCause,
    IVisualInspectionFrequency, IVisualInspectionItem_InitParams, TVisualInspection, VisualInspectionMode
} from '../../../../interfaces/visualInspection.interfaces';
import BrowserSvc from '../../../../services/browserSvc';
import FileManagement from '../../../../services/fileManagement';
import OcDateSvc from '../../../../services/ocDateSvc';
import { VisualInspectionReferenceService } from '../../../../services/visual-inspection-reference.service';
import { VisualInspectionService } from '../../../../services/visual-inspection.service';
import tmpl from './visualInspectionItem.html';

export default class VisualInspectionItemCtrl implements ng.IController, IWindowController {

    wnd: IWindowObj;
    form: ng.IFormController;

    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: () => void };

    protected visualInspection: TVisualInspection;
    protected initParams: IVisualInspectionItem_InitParams;

    mode: VisualInspectionMode;

    showDate: boolean;
    readonly: boolean;

    latestInspectionDate: Date;

    selectedCause: IVisualInspectionCause;
    selectedAction: IVisualInspectionAction;
    selectedFrequency: IVisualInspectionFrequency;

    acceptedFileTypes: string[] = this.fileManagement.getAcceptedFileExtensions([FileType.image]); // images only

    now: Date = new Date();

    refDataSubs: Subscription[] = [];

    public visualInspectionActions: IVisualInspectionAction[];
    public visualInspectionCauses: IVisualInspectionCause[];
    public visualInspectionFrequencies: IVisualInspectionFrequency[];

    static $inject = ['$scope', 'errorReporter', 'WindowFactory', 'fileManagement', '$timeout',
        'ocSecuritySvc', 'confirmSvc', 'visualInspectionReferenceService', 'visualInspectionService', 'amtXlatSvc', 'ocDateSvc', 'browserSvc'];

    constructor(private $scope: ng.IScope, private errorReporter: IErrorReporter, private WindowFactory: IWindowFactory,
        private fileManagement: FileManagement, private $timeout: ng.ITimeoutService,
        private ocSecuritySvc, private confirmSvc, private visualInspectionReferenceService: VisualInspectionReferenceService,
        private visualInspectionService: VisualInspectionService, private amtXlatSvc: IAmtXlatSvc, private ocDateSvc: OcDateSvc, private browserSvc: BrowserSvc) {

        this.$scope.$watchGroup([() => this.form.$pristine, () => this.form.$invalid, () => this.wnd.processing], () => {
            this.buttonStates.saveButton.disabled = this.form.$pristine || this.form.$invalid || this.wnd.processing;
        });
    }

    $onInit() {

        this.wnd.onClose = () => this.cancel();

        if (this.browserSvc.isMobile)
            this.wnd.fullScreen();

        // shouldn't happen but happens when you try to add a VI to an vehicle with no fitments.
        // It's mainly used for lookup of the details, which isn't needed if you actually have no tyre anyway.
        // so we log but suppress UI
        if (!this.initParams.componentTypeId)
            this.errorReporter.logError('componentTypeId not set', 'visualInspectionItemCtrl', true);

        this.showDate = this.initParams.showDate;

        this.mode = this.initParams.mode || (this.initParams.visualInspectionRecordId ? VisualInspectionMode.edit :
            (this.initParams.visualInspectionId ? VisualInspectionMode.update : VisualInspectionMode.add));

        this.WindowFactory.addButton(this, 'common.save_label', 'saveButton', () => this.save(), true);
        this.WindowFactory.addButton(this, 'common.cancel_label', 'cancelButton', () => this.cancel());

        this.refDataSubs.push(
            this.visualInspectionReferenceService.actions$.subscribe(actions => this.visualInspectionActions = actions),
            this.visualInspectionReferenceService.getCausesByEquipmentTypeId$(this.initParams.componentTypeId).subscribe(causes => this.visualInspectionCauses = causes),
            this.visualInspectionReferenceService.frequencies$.subscribe(frequencies => this.visualInspectionFrequencies = frequencies)
        );        

        switch (this.mode) {

            case VisualInspectionMode.add:

                this.setWindowCaption('visualInspection.add');
                this.readonly = this.initParams.readonly || !this.ocSecuritySvc.isAuthorised('Security.Components.VisualInspections.Add', AccessTypes.readWrite);
                this.visualInspection = {
                    id: uuid(),
                    inspectionDateTime: this.initParams.inspectionDate || new Date(),
                    equipmentId: this.initParams.equipmentId,
                    active: true,
                    uncommitted: true,
                    attachments: []
                };
                break;

            default:

                this.setWindowCaption(this.mode === VisualInspectionMode.edit ? 'visualInspection.edit' : 'visualInspection.update');
                this.readonly = this.initParams.readonly || !this.ocSecuritySvc.isAuthorised('Security.Components.VisualInspections', AccessTypes.readWrite);

                this.getVisualInspection().subscribe(
                    res => {
                        this.visualInspection = res;
                        this.visualInspection.attachments = this.visualInspection.attachments || [];

                        if (this.mode === VisualInspectionMode.update) {
                            this.latestInspectionDate = this.visualInspection.inspectionDateTime;
                            this.visualInspection.inspectionDateTime = this.initParams.inspectionDate || new Date();
                            this.visualInspection.comment = null;
                            this.visualInspection.attachments = [];
                        }

                        if (this.mode === VisualInspectionMode.edit && this.initParams.canDelete)
                            this.WindowFactory.addButton(this, 'common.delete_label', 'deleteButton', () => this.delete());

                        this.$timeout(() => this.form.$setPristine());
                    }
                );
                break;
        }
    }

    private setWindowCaption(resource: string) {
        this.wnd.caption = this.amtXlatSvc.xlat(resource);
        this.wnd.shortCaption = this.wnd.caption;
    }

    getVisualInspection() {
        return defer(() => {
            this.wnd.processing = true;
            return this.initParams.visualInspection ?
                of(angular.copy(this.initParams.visualInspection)) :
                this.visualInspectionService.getVisualInspection(this.initParams.visualInspectionId, this.initParams.visualInspectionRecordId);
        }).pipe(
            first(),
            catchError(err => {
                this.errorReporter.logError(err);
                return of(null);
            }),
            finalize(() => this.wnd.processing = false)
        );
    }

    delete() {
        if (!this.visualInspection.uncommitted && this.initParams.visualInspectionRecordId) {

            defer(() => {
                this.wnd.processing = true;
                return this.visualInspectionService.deleteVisualInspectionRecord(this.initParams.visualInspectionRecordId);
            }).pipe(
                first(),
                finalize(() => this.wnd.processing = false)
            ).subscribe(
                res => {
                    if (res)
                        this.notifyDataChangedAndClose();
                }
            );

        } else {
            this.notifyDataChangedAndClose();
        }
    }

    save() {
        defer(() => {
            this.wnd.processing = true;

            if (!this.initParams.commitOnSave) {
                return of(true);
            } else {

                let inspectionDateTime = this.ocDateSvc.dateTimeAsUTC(this.visualInspection.inspectionDateTime);
                inspectionDateTime.setSeconds(0, 0);

                let criteria: ISaveVisualInspectionCriteria = {
                    equipmentId: this.visualInspection.equipmentId || this.initParams.equipmentId,
                    visualInspectionId: this.initParams.visualInspectionId,
                    visualInspectionRecordId: this.initParams.visualInspectionRecordId,
                    inspectionDateTime: inspectionDateTime,
                    active: this.visualInspection.active,
                    comment: this.visualInspection.comment,
                    causeId: this.selectedCause?.id || this.visualInspection.causeId,                    
                    actionId: this.selectedAction.id,                    
                    inspectionFrequencyDays: this.selectedFrequency.days,
                    attachments: this.visualInspection.attachments
                };

                return this.visualInspectionService.saveVisualInspection(criteria);
            }
        }).pipe(
            first(),
            catchError(err => {
                this.errorReporter.logError(err);
                return of(false);
            }),
            finalize(() => this.wnd.processing = false)
        ).subscribe(
            success => {
                if (success)
                    this.notifyDataChangedAndClose(this.visualInspection);
            }
        );
    }

    cancel() {
        from(this.confirmSvc.confirmSaveChange(this.form.$dirty))
            .pipe(first())
            .subscribe(() => this.closeWindow());
    }

    private notifyDataChangedAndClose(visualInspection?: TVisualInspection) {

        if (visualInspection) {
            visualInspection.cause = this.selectedCause?.description || visualInspection.cause;
            visualInspection.action = this.selectedAction.description;
        }

        if (this.wnd.onDataChanged)
            this.wnd.onDataChanged(visualInspection);

        this.closeWindow();
    }

    private closeWindow() {
        this.form.$setPristine();
        this.WindowFactory.closeWindow(this.wnd);
    }

    $onDestroy() {
        this.refDataSubs.forEach(sub => sub.unsubscribe());
    }
}

class VisualInspectionItemComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = VisualInspectionItemCtrl;
    public controllerAs = 'vm';
}

angular.module('app.component').component('visualInspectionItem', new VisualInspectionItemComponent());