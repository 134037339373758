//import angular from 'angular';
import tmpl from './ocParentMenuItem.html';


    angular.module('app.directives')
        .directive('ocParentMenuItem', [function () {
            return {
                replace: true,
                transclude: true,
                restrict: 'E',
                requires: '^ul',
                scope: {
                    text: "@",
                    navState: "@"
                },
                controller: ["$scope", "$state", function ($scope, $state) {
                    $scope.uiState = $state;

                    $scope.$watch('uiState.current', function (newValue) {
                        $scope.active = newValue.name.startsWith($scope.navState);
                    });
                }],
                template: tmpl
            };
        }]);
