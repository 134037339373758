<!--<oc-header [fullTitle]="title">-->
    <!-- Put header things in here - filters, buttons etc -->
<!--</oc-header>-->

<div class="amt-view-header bg-light lter b-b ng-scope ng-isolate-scope" title-text="Condition Monitoring">
    <div class="row">
        <div class="col-lg-8 col-md-7">
            <h1 class="m-n font-thin h3 amt-title-text ng-binding ng-scope" [textContent]="title"></h1>
        </div>
        <div class="col-lg-4 col-md-5 margin-button-title">
            <div class="pull-right">
                <div class="inline-flex ng-scope">
                    <button type="button" class="btn btn-default" *ngIf="!isProcessing" kendoButton (click)="onUpdateButtonClick()">{{ "conditionmonitoring.update" | xlat }}</button>
                    <button type="button" class="btn btn-default btn-primary" *ngIf="!isProcessing" kendoButton (click)="onShowFiltersClick()">{{ showFilters ? ("conditionmonitoring.hide_filters" | xlat) : ("conditionmonitoring.show_filters" | xlat) }}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="cm-wrapper">
    <div class="container-fluid cm-main">
        <div [ngClass]="{processing: (isProcessing)}" class="flex-none ng-scope"><div></div></div>
        <div *ngIf="isProcessing" [ngClass]="{processing: (isProcessing)}" class="ng-scope" style="position: absolute; top: calc(30% + 65px); left: 50%; height: 60px; width: 200px; margin-left: -100px; padding: 5px; color: #ffffff; background: transparent; text-align: center">{{(statusText)}}</div>
        <div class="filters" [hidden]="!showFilters">
            <div class="form-group">
                <label for="conditionmonitoringFiltersVehicleSpecification">{{"DashboardElementConditionMonitoring.VehicleSpecification" | xlat }}</label>
                <div>
                    <kendo-listbox kendoListBoxDataBinding
                                   [data]="VehicleSpecs"
                                   [connectedWith]="specs"
                                   [toolbar]="toolbarSettings">
                    </kendo-listbox>
                    <kendo-listbox #specs [data]="SpecsToShow" [toolbar]="false">
                    </kendo-listbox>
                </div>
            </div>
            <div class="form-group">
                <!--(selectionChange)="selectionChange($event)"-->
                <label for="conditionmonitoringFiltersSortBy">{{"DashboardElementConditionMonitoring.SortBy" | xlat }}</label>
                <kendo-dropdownlist #conditionmonitoringFiltersSortBy
                                    [data]="sortBy"
                                    textField="text"
                                    valueField="value"
                                    [(ngModel)]="sortBySelectedItem">
                </kendo-dropdownlist>
            </div>
            <div class="form-group">
                <label for="conditionmonitoringFiltersDisplayBy">{{"DashboardElementConditionMonitoring.DisplayBy" | xlat }}</label>
                <kendo-dropdownlist #conditionmonitoringFiltersDisplayBy
                                    [data]="DisplayBy"
                                    textField="text"
                                    valueField="value"
                                    [(ngModel)]="displayBySelectedItem">
                </kendo-dropdownlist>
            </div>
        </div>

        <div *ngFor="let group of (groupedVehicles$ | async)">
            <!--  | keyvalue; trackBy: trackByGroup -->
            <h2>{{group.name}}</h2>
            <ol class="vehicles-group">
                <li [condition-monitoring-icon]="vehicle" [renderer-config]="rendererConfig" *ngFor="let vehicle of group.members; trackBy:trackByVehicle" (click)="click(vehicle)"></li> <!---->
            </ol>
        </div>
    </div>
    <div *ngIf="!isProcessing" class="legend">
        <div><h3><strong>Legend:</strong></h3></div>
        <div *ngFor="let issue of this.rendererConfig.issues | keyvalue">
            <span [ngStyle]="{'backgroundColor': this.rendererConfig.lookupTable[issue.key] }" class="icon"></span>
            {{ "conditionmonitoring." + this.rendererConfig.issues.get(issue.key) | xlat }}
        </div>
    </div>
</div>