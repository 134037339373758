/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * @hidden
 */
var FocusOnDomReadyDirective = /** @class */ (function () {
    function FocusOnDomReadyDirective(host, ngZone) {
        this.host = host;
        this.ngZone = ngZone;
    }
    FocusOnDomReadyDirective.prototype.ngAfterContentInit = function () {
        this.focusOnNextTick();
    };
    FocusOnDomReadyDirective.prototype.focusOnNextTick = function () {
        var _this = this;
        this.ngZone.runOutsideAngular(function () { return setTimeout(function () { return _this.host.nativeElement.focus(); }); });
    };
    FocusOnDomReadyDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoFocusOnDomReady]'
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.ElementRef,
            core_1.NgZone])
    ], FocusOnDomReadyDirective);
    return FocusOnDomReadyDirective;
}());
exports.FocusOnDomReadyDirective = FocusOnDomReadyDirective;
