//import angular from 'angular';
import BrowserSvc from './browserSvc';



export default class OcZendeskWidgetBase {
    protected readonly default_offsetx = 0; //initialise to zendesk widget defaults
    protected readonly default_offsety = 0;
    protected offsetx = this.default_offsetx; 
    protected offsety = this.default_offsety;

    /** If the widget is opened, this command closes the widget and shows the launcher */
    protected close() {
        zE('webWidget', 'close');
    }

    /** Forces the widget to open */
    protected open() {
        zE('webWidget', 'open');
    }

    /** Hides all parts of the widget from the page. You can invoke it before or after page load */
    protected hide() {
        zE('webWidget', 'hide');
    }

    /** Displays the widget on the host page in the state it was in before it was hidden */
    protected show() {
        zE('webWidget', 'show');
    }

    /** Executes a callback when the widget is opened */
    protected onOpen(callback: () => void) {
        zE('webWidget:on', 'open', callback);
    }

    /** Executes a callback when the widget is closed */
    protected onClose(callback: () => void) {
        zE('webWidget:on', 'close', callback);
    }

    /** Pre-fills an end-user's details on forms inside the Web Widget */
    protected prefill(name: string, email: string) {
        zE('webWidget', 'prefill', { name: { value: name }, email: { value: email } });
    }

    protected clear() {
        zE('webWidget', 'clear');
    }

    /** Sets the widget locale */
    protected setLocale(locale: string) {
        switch (locale.toLowerCase()) {
            case 'es':
                locale = 'es-419';
                break;
            case 'en':
                locale = 'en-gb';
                break;
        }

        zE('webWidget', 'setLocale', locale);
    }

    protected displayLeft() {
        this.updateSettings({ position: { horizontal: 'left', vertical: 'bottom' } });
    }

    protected displayRight() {
        this.updateSettings({ position: { horizontal: 'right', vertical: 'bottom' } });
    }

    protected offset(x: number, y: number) {
        if (x != null)
            this.offsetx = x;

        if (y != null)
            this.offsety = y;

        this.updateSettings({ offset: { horizontal: this.offsetx + 'px', vertical: this.offsety+'px' } });
    }

    protected resetOffset() {
        this.offset(this.default_offsetx, this.default_offsety);
    }

    protected updateSettings(settings: any) {
        zE('webWidget', 'updateSettings', { webWidget: settings});
    }


}


class OcZendeskWidget extends OcZendeskWidgetBase {
    readonly isMobile: boolean;
    isMobileSession = false;
    isDesktopSession = false;
    name = "";
    email = "";

    static $inject = ['$timeout', '$rootScope', 'browserSvc', 'ocConfigSvc', 'WindowFactory'];
    constructor(private $timeout: ng.ITimeoutService, $rootScope, private browserSvc: BrowserSvc, private ocConfigSvc, private WindowFactory: IWindowFactory) {
        super();

        this.isMobile = this.browserSvc.isMobile;

        this.onOpen(() => this.handleOnOpen());
        this.onClose(() => this.handleOnClose());

        $rootScope.$on('login', () => this.onLogin());
        $rootScope.$on('logout', () => this.onLogout());
        $rootScope.$on('menu.contactSupport', () => this.showForMobile());
    }

    onLogin() {
        this.isMobileSession = this.isMobile;
        this.isDesktopSession = !this.isMobile;
        this.name = this.ocConfigSvc.user.name;
        this.email = this.ocConfigSvc.user.emailAddress;

        this.close();

        if (this.isMobile) {
            this.hide();
            this.displayRight();
        } else {
            this.displayLeft();
            this.alignDesktopButton();
        }

        //do this last as it make take a moment to load another language
        this.setLocale(this.ocConfigSvc.getLanguage());
    }

    protected alignDesktopButton() {
        this.offset(50, 0);
    }

    onLogout() {
        this.name = "";
        this.email = "";
        this.close();
        this.displayRight();
        this.resetOffset();
        this.clear();
        this.show();
        this.isMobileSession = false;
        this.isDesktopSession = false;
    }

    async showForMobile() {
        if (!this.browserSvc.isOnline)
            await this.WindowFactory.alert('mobileCommon.offlineZendeskWarningCaption', [], 'mobileCommon.offlineZendeskWarning');

        this.show();
        this.open();
    }
    protected handleOnOpen() {
        //prefill only when we have to, can't clear prefill data on logout
        //See: https://develop.zendesk.com/hc/en-us/community/posts/360001651527-Widget-API-zE-identify
        //Note: nothing works as er the non-staff comments :/
        if (this.isMobileSession || this.isDesktopSession) {
            this.prefill(this.name, this.email);
        }

        if (this.isDesktopSession) {
            //just an ugly hack to allow the widget to be repositioned after it starts to animate
            this.$timeout(() => this.resetOffset(), 200);
        }
    }

    protected handleOnClose() {
        if (this.isMobileSession) {
            this.hide();
        }

        if (this.isDesktopSession) {
            this.alignDesktopButton();
        }
    }
}

angular.module('app').service('ocZendeskWidget', OcZendeskWidget);