//import angular from 'angular';
import * as _ from 'underscore'

    angular.module('app').filter('numberFormat', numberFormatFilter);

    // @ts-ignore
    numberFormatFilter.$inject = ["appConfig", "$filter"];
    function numberFormatFilter(appConfig, $filter) {
        var numberFilter = $filter('number');

        return function (number, isRequiredDecimal) {
            if (!_.isNumber(number)) {
                number = appConfig.defaultNumberIfEmpty;
            }
            var decimalPlaces = isRequiredDecimal ? 2 : 0;//TODO: should get decimalPlaces from appConfig.decimalPlaces
            return numberFilter(number, decimalPlaces);
        };
    }
