/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var common_1 = require("@angular/common");
var core_1 = require("@angular/core");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var expansionpanel_title_directive_1 = require("./expansionpanel/expansionpanel-title.directive");
var expansionpanel_component_1 = require("./expansionpanel/expansionpanel.component");
var exportedModules = [
    expansionpanel_component_1.ExpansionPanelComponent,
    expansionpanel_title_directive_1.ExpansionPanelTitleDirective
];
var declarations = exportedModules.slice();
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the ExpansionPanel component.
 */
var ExpansionPanelModule = /** @class */ (function () {
    function ExpansionPanelModule() {
    }
    ExpansionPanelModule = tslib_1.__decorate([
        core_1.NgModule({
            declarations: [declarations],
            exports: [exportedModules],
            imports: [common_1.CommonModule, kendo_angular_common_1.EventsModule]
        })
    ], ExpansionPanelModule);
    return ExpansionPanelModule;
}());
exports.ExpansionPanelModule = ExpansionPanelModule;
