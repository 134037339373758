//import angular from 'angular';
import * as _ from 'underscore';
import AdminUrls from '../../../adminUrls';
import tmpl from './details.html';



    angular.module('app.admin').component('addEditSize', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['$scope', 'confirmSvc', 'enums', 'adminUrls', 'WindowFactory', 'ocSecuritySvc', 'amtCommandQuerySvc',
            'notifySvc', 'amtXlatSvc', 'referenceDataUrls', 'ocConfigSvc', 'errorReporter', '$timeout', 'helperSvc', 'componentUrls', '$q',
            function ($scope, confirmSvc, enums, adminUrls: AdminUrls, WindowFactory: IWindowFactory, ocSecuritySvc, amtCommandQuerySvc,
                notifySvc, amtXlatSvc: IAmtXlatSvc, referenceDataUrls, ocConfigSvc, errorReporter, $timeout, helperSvc, componentUrls, $q) {

                var vm = this;
                $scope.vm = this;

                vm.urls = adminUrls;
                vm.referenceDataUrls = referenceDataUrls;
                vm.componentUrls = componentUrls;

                vm.enums = enums;
                vm.config = ocConfigSvc;

                // pass along dirty flag to the window for use on closing minimised windows
                $scope.$watch('vm.form.$dirty', function () {
                    vm.wnd.isDirty = vm.form.$dirty;
                });

                this.$onInit = function () {

                    vm.status = enums.screenStatus.loading;

                    if (vm.wnd !== null) {
                        vm.wnd.onClose = vm.cancel;
                    }

                    vm.wnd.processing = true;

                    if (vm.initParams.id) {
                        vm.id = angular.copy(vm.initParams.id);
                    }

                    vm.readonly = !vm.config.user.isSystemAdmin;

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: "common.save_label",
                            name: "saveButton",
                            click: "save",
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: !vm.readonly,
                            disabled: true
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.save
                    };

                    return getUnitsOfMeasure().then(function () {
                        return getSizeDetails();
                    }).finally(function () {
                        vm.status = enums.screenStatus.ready;

                        updateDynamicDescription();
                        
                        $timeout(function () {
                            vm.setPristine();
                            vm.wnd.processing = false;
                        });
                    });
                }

                vm.setPristine = function () {
                    if (vm.form) {
                        vm.form.$setPristine();
                    }
                };

                vm.onComponentTypeChange = function (option) {
                    if (!option.hasAspectRatio) {
                        vm.data.aspectRatio = null;
                    }

                    if (!option.hasWeight) {
                        vm.data.weight = null;
                        vm.data.weightUnitOfMeasureId = null;
                        vm.data.weightUnit = null;
                    }
                };

                vm.save = function () {

                    var criteria = {
                        id: vm.id,                        
                        name: vm.dynamicDescription,
                        componentTypeId: helperSvc.getKey(vm.data.componentType),
                        width: vm.data.width,
                        widthUnitOfMeasureId: helperSvc.getKey(vm.data.widthUnit),
                        diameter: vm.data.diameter,
                        diameterUnitOfMeasureId: helperSvc.getKey(vm.data.diameterUnit),
                        weight: vm.data.weight,
                        weightUnitOfMeasureId: helperSvc.getKey(vm.data.weightUnit),
                        aspectRatio: helperSvc.getKey(vm.data.aspectRatio),
                        active: vm.data.isActive
                    };

                    vm.wnd.processing = true;

                    return amtCommandQuerySvc.post((!!vm.id ? vm.referenceDataUrls.modifyComponentSize : vm.referenceDataUrls.addComponentSize), criteria).then(function (response) {

                        notifySvc.success(amtXlatSvc.xlat('size.saveSuccessful'));

                        if (vm.wnd.onDataChanged) {
                            vm.wnd.onDataChanged();
                        }

                        vm.closeWindow();
                    }).catch(function (error) {
                        errorReporter.logError(error);
                    }).finally(function () {
                        vm.wnd.processing = false;
                    });
                };

                vm.closeWindow = function () {
                    WindowFactory.closeWindow(vm.wnd);
                };

                vm.cancel = function () {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        vm.form.dirty = false;
                        vm.closeWindow();
                        return true;
                    });

                    return false;
                };

                function getSizeDetails() {
                    if (vm.id) {

                        return amtCommandQuerySvc.post(vm.referenceDataUrls.getComponentSizeDetails, { id: vm.id }).then(function (response) {
                            vm.data = response;
                        }).catch(function (error) {
                            errorReporter.logError(error);
                        });

                    } else {

                        // set default units
                        var defaultDimension = _.find(vm.dimensionUnits, function (u) { return u.unitAbbreviation === 'in'; });
                        var defaultWeight = _.find(vm.weightUnits, function (u) { return u.unitAbbreviation === 'kg'; });

                        vm.data = {
                            widthUnitOfMeasureId: defaultDimension.id,
                            diameterUnitOfMeasureId: defaultDimension.id,
                            weightUnitOfMeasureId: defaultWeight.id
                        };

                        return $q.resolve();
                    }
                };

                function getUnitsOfMeasure() {
                    return amtCommandQuerySvc.post(vm.referenceDataUrls.getUnitsOfMeasure, null).then(function (response) {
                        if (response && response.result) {
                            vm.dimensionUnits = response.result.filter(function (u) { return u.baseReadingUnit === 'Dimension'; });
                            vm.weightUnits = response.result.filter(function (u) { return u.baseReadingUnit === 'Weight'; });
                        }
                    }).catch(function (error) {
                        vm.status = enums.screenStatus.error;
                        errorReporter.logError(error);
                    });
                };

                function updateDynamicDescription() {

                    if (fieldHasValue(vm.data.componentType) && vm.status === enums.screenStatus.ready) {

                        if (vm.data.componentType.name.toLowerCase() === enums.equipmentTypes.tyre) {
                            // tyre
                            vm.dynamicDescription = String.format("{0}{1}{2}",
                                (helperSvc.notNullOrUndefined(vm.data.width) ? vm.data.width : ""),
                                (fieldHasValue(vm.data.aspectRatio) ? vm.data.aspectRatio.displayValue + "x" : ""),
                                (helperSvc.notNullOrUndefined(vm.data.diameter) ? vm.data.diameter : "")
                            ).replace("  ", " ");
                        } else {
                            // rim & chain
                            vm.dynamicDescription = String.format("{0}{1}",
                                (helperSvc.notNullOrUndefined(vm.data.width) ? vm.data.width + "x" : ""),
                                (helperSvc.notNullOrUndefined(vm.data.diameter) ? vm.data.diameter : "")
                            ).replace("  ", " ");
                        }
                    }
                };

                function fieldHasValue(field) {
                    return !!field && field.key;
                };

                $scope.$watch('vm.data', function () {
                    if (!!vm.data) {
                        updateDynamicDescription();
                    }
                }, true);

                $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing'], function () {
                    vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.form.$pristine || vm.wnd.processing;
                });
            }
        ]
    });
