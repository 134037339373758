// angular replacement for ocPropertyRow that doesn't rely on amtRow and amtDisplay
// haven't upgraded the existing ocPropertyRow just yet because of the change in how the widths are defined
// percentages vs bootstrap columns

import { Component, Input, OnInit } from "@angular/core";
import { downgradeComponent } from "@angular/upgrade/static";
import { Xlat } from "../../amt/i18n/xlat";

export type LabelVerticalAlignment = 'start' | 'end' | 'center' | 'stretch';

@Component({
    selector: 'property-row',
    templateUrl: './property-row.component.html',
    //TODO: convert to styleUrls (which is currently bugged in our build)
    styles: [`        
        .property-row { display: grid; padding-bottom: 0.8em; padding-top: 0.8em; }
        .property-label { text-align: right; padding-right: 15px; }        
        .property-value { padding-left: 15px; align-self: center; }   
    `]
})
export class PropertyRow implements OnInit {

    @Input() required: boolean;
    @Input() label: string;
    @Input() labelResource: string;
    @Input() labelWidth: number;
    @Input() verticalAlignLabel: LabelVerticalAlignment = 'center';

    constructor(private amtXlatSvc: Xlat) { }

    ngOnInit() {

        if (this.labelWidth < 0 || this.labelWidth > 100)
            this.labelWidth = 50;

        if (this.labelResource && !this.label)
            this.label = this.amtXlatSvc.xlat(this.labelResource);

        if (this.label && !this.label.endsWith(':'))
            this.label += ':';
    }

    getColumnStyles() {
        return { 'grid-template-columns': `${this.labelWidth}fr ${100 - this.labelWidth}fr` }
    }
}

angular.module('app.directives').directive('propertyRow', downgradeComponent({ component: PropertyRow }));