import { downgradeComponent, downgradeInjectable } from "@angular/upgrade/static";
import { Xlat } from "./amt/i18n/xlat";
import { ConditionMonitoringDetailsComponent } from "./modules/site/conditionMonitoring/components/condition-monitoring-details.component";
import BrowserSvc from "./services/browserSvc";
import OcConfigSvc from "./services/ocConfigSvc";

/*
 * Our services we are downgrading
 */
angular
    .module("downgraded.module", [])
    .factory("amtXlatSvc", downgradeInjectable(Xlat));

angular
    .module("downgraded.module")
    .factory("ocConfigSvc", downgradeInjectable(OcConfigSvc));

angular
    .module("downgraded.module")
    .factory("browserSvc", downgradeInjectable(BrowserSvc));

/*
 * Our components we are downgrading
 */
angular
    .module("downgraded.module")
    .directive("conditionMonitoringDetails", downgradeComponent({ component: ConditionMonitoringDetailsComponent }) as angular.IDirectiveFactory);
