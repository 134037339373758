//import angular from 'angular';
import OcConfigSvc from '../../../../../services/ocConfigSvc';
import tmpl from './details.html';


class AddEditSiteCtrl implements ng.IController, IWindowController {

    wnd: IWindowObj;
    form: ng.IFormController;

    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: () => void }

    initParams: any;

    readonly: boolean;
    currencyManuallySet = false;

    id: guid;
    originalClientId: guid = null;

    data: any = {};
    currencyTypes: any[];

    status = ScreenStatus.loading;

    operationTypesControl: any = {};

    static $inject = ['$scope', 'confirmSvc', 'adminUrls', 'referenceDataUrls', 'errorReporter', 'WindowFactory', 'amtXlatSvc',
        '$timeout', 'amtCommandQuerySvc', 'notifySvc', 'ocConfigSvc', 'helperSvc', '$q'];

    constructor(private $scope: ng.IScope, private confirmSvc: IConfirmSvc, private adminUrls: IAdminUrls, private referenceDataUrls: IReferenceDataUrls,
        private errorReporter: IErrorReporter, private WindowFactory: IWindowFactory, private amtXlatSvc: IAmtXlatSvc,
        private $timeout: ng.ITimeoutService, private amtCommandQuerySvc: IAmtCommandQuerySvc, private notifySvc: INotifySvc,
        private ocConfigSvc: OcConfigSvc, private helperSvc: IHelperSvc, private $q: ng.IQService) {

        this.$scope.$watch(() => this.form.$dirty, () => this.wnd.isDirty = this.form.$dirty);


        this.$scope.$watchGroup([() => this.form.$invalid, () => this.form.$pristine, () => this.wnd.processing], () => {
            this.buttonStates.saveButton.disabled = this.form.$invalid || this.form.$pristine || this.wnd.processing;
        });

        this.$scope.$watch(() => this.data.active, newValue => {
            if (newValue === true) {
                this.data.displayInSystem = true;
            }
        });
    }

    async $onInit() {
        if (this.wnd) {
            this.wnd.processing = true;
            this.wnd.onClose = () => this.closeWindow();
        }

        this.id = this.initParams.id;

        this.readonly = !this.ocConfigSvc.user.isSystemAdmin;

        this.WindowFactory.addButton(this, 'common.save_label', 'saveButton', () => this.save(), true, !this.readonly, true);

        try {
            await this.getCurrencyTypes();

            if (this.id)
                await this.getSiteDetails();
        } finally {
            this.status = ScreenStatus.ready;
            this.$timeout(() => this.wnd.processing = false, 200);
        }
    }

    async save() {

        let criteria = {
            id: this.id,
            clientId: this.helperSvc.getKey(this.data.client),
            name: this.data.site.name,
            siteTypeId: this.helperSvc.getKey(this.data.siteType),
            operationTypeIds: this.data.operationTypeIds,
            currencyTypeId: this.helperSvc.getKey(this.data.currency),
            active: this.data.active,
            displayInSystem: this.data.displayInSystem,
            reassignFilesOnClientChange: false
        };

        // if the client is being changed, ask if they want associated equipment files to be reassigned to the new client
        if (this.originalClientId && criteria.clientId !== this.originalClientId) {
            try {
                await this.confirmSvc.confirmMessage_customButtons('site.reassignFiles_title', 'site.reassignFiles_text', 'common.yes_label', 'common.no_label', true);
                criteria.reassignFilesOnClientChange = true;
            } catch (error) {
                if (error === false) // they closed the window, halt the save
                    return;

                // any other error means they selected to not reassign the files, can proceed
            }
        }

        this.wnd.processing = true;

        try {
            await this.amtCommandQuerySvc.post(this.adminUrls.addModifySite, criteria);

            this.notifySvc.success(this.amtXlatSvc.xlat('site.siteSaveSuccess'));

            if (this.wnd.onDataChanged)
                this.wnd.onDataChanged();

            this.setPristine();

            this.closeWindow();

        } catch (error) {
            this.errorReporter.logError(error);
        } finally {
            this.wnd.processing = false;
        }
    }

    async getCurrencyTypes() {
        try {
            let response = await this.amtCommandQuerySvc.get('referenceData/getCurrencyTypes', null);

            if (!response || !response.data)
                return;

            this.currencyTypes = response.data.result;

            for (let ct of this.currencyTypes || [])
                ct.description = String.format("{0} ({1})", ct.description, ct.symbol);

        } catch (error) {
            this.errorReporter.logError(error);
        }
    }

    async getSiteDetails() {

        try {
            let response = await this.amtCommandQuerySvc.post(this.adminUrls.getSite, { id: this.id });

            this.data = response;

            this.originalClientId = this.data.client.id;

            this.$timeout(() => {
                this.setPristine();

                if (this.operationTypesControl && this.operationTypesControl.search)
                    this.operationTypesControl.search();
            });

        } catch (error) {
            this.errorReporter.logError(error);
        }
    }

    async onClientChange(option) {

        if (!this.id && !this.currencyManuallySet) {
            try {
                let response = await this.amtCommandQuerySvc.post(this.adminUrls.getSystemHierarchyCurrency, { systemHierarchyId: option.key });

                if (!response || !response.length)
                    return;

                this.data.currencyTypeId = response[0].id;

            } catch (error) {
                this.errorReporter.logError(error);
            }
        }
    }

    onCurrencyChange() {
        this.currencyManuallySet = true;
    }

    async closeWindow() {

        try {
            await this.confirmSvc.confirmSaveChange(this.form.$dirty);
        } catch {
            return; // they cancelled
        }

        this.setPristine();

        this.WindowFactory.closeWindow(this.wnd);
    }

    private setPristine() {
        if (this.form) {
            this.form.$setPristine();
        }
    }
}

class AddEditSiteComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = AddEditSiteCtrl;
    public controllerAs = 'vm';
}

angular.module('app.admin').component('addEditSite', new AddEditSiteComponent());