/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var floatingactionbutton_component_1 = require("./floatingactionbutton.component");
var dial_item_template_directive_1 = require("./templates/dial-item-template.directive");
var fab_template_directive_1 = require("./templates/fab-template.directive");
var dial_list_component_1 = require("./dial-list.component");
var dial_item_component_1 = require("./dial-item.component");
var list_module_1 = require("../listbutton/list.module");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var kendo_angular_popup_1 = require("@progress/kendo-angular-popup");
var exportedModules = [
    floatingactionbutton_component_1.FloatingActionButtonComponent,
    dial_item_template_directive_1.DialItemTemplateDirective,
    fab_template_directive_1.FloatingActionButtonTemplateDirective
];
var declarations = exportedModules.concat([
    dial_list_component_1.DialListComponent,
    dial_item_component_1.DialItemComponent
]);
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the FloatingActionButton component.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the FloatingActionButton module
 * import { FloatingActionButtonModule } from '@progress/kendo-angular-buttons';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, FloatingActionButtonModule], // import FloatingActionButton module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * ```
 */
var FloatingActionButtonModule = /** @class */ (function () {
    function FloatingActionButtonModule() {
    }
    FloatingActionButtonModule = tslib_1.__decorate([
        core_1.NgModule({
            declarations: [declarations],
            exports: [exportedModules],
            imports: [common_1.CommonModule, kendo_angular_popup_1.PopupModule, list_module_1.ListModule, kendo_angular_common_1.EventsModule]
        })
    ], FloatingActionButtonModule);
    return FloatingActionButtonModule;
}());
exports.FloatingActionButtonModule = FloatingActionButtonModule;
