/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
/* tslint:disable:no-access-missing-member */
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var kendo_angular_popup_1 = require("@progress/kendo-angular-popup");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var button_item_template_directive_1 = require("../listbutton/button-item-template.directive");
var util_1 = require("../util");
var list_button_1 = require("../listbutton/list-button");
var list_component_1 = require("../listbutton/list.component");
var focus_service_1 = require("../focusable/focus.service");
var navigation_service_1 = require("../navigation/navigation.service");
var navigation_config_1 = require("../navigation/navigation-config");
var preventable_event_1 = require("../preventable-event");
var rxjs_1 = require("rxjs");
var NAVIGATION_SETTINGS = {
    useLeftRightArrows: true
};
var ɵ0 = NAVIGATION_SETTINGS;
exports.ɵ0 = ɵ0;
var NAVIGATION_SETTINGS_PROVIDER = {
    provide: navigation_config_1.NAVIGATION_CONFIG,
    useValue: ɵ0
};
/**
 * Represents the Kendo UI DropDownButton component for Angular.
 *
 * @example
 * ```ts
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-dropdownbutton [data]="data">
 *    User Settings
 *  </kendo-dropdownbutton>
 * `
 * })
 * class AppComponent {
 *   public data: Array<any> = [{
 *       text: 'My Profile'
 *   }, {
 *       text: 'Friend Requests'
 *   }, {
 *       text: 'Account Settings'
 *   }, {
 *       text: 'Support'
 *   }, {
 *       text: 'Log Out'
 *   }];
 * }
 * ```
 */
var DropDownButtonComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DropDownButtonComponent, _super);
    function DropDownButtonComponent(focusService, navigationService, wrapperRef, zone, popupService, elRef, localization, cdr) {
        var _this = _super.call(this, focusService, navigationService, wrapperRef, zone, localization, cdr) || this;
        _this.popupService = popupService;
        _this.elRef = elRef;
        /**
         * Defines the name of an existing icon in a Kendo UI theme.
         */
        _this.icon = '';
        /**
         * Defines the list of CSS classes which are used for styling the Button with custom icons.
         */
        _this.iconClass = '';
        /**
         * Defines a URL for styling the button with a custom image.
         */
        _this.imageUrl = '';
        /**
         * The size property specifies the padding of the DropDownButton
         * ([see example]({% slug api_buttons_dropdownbuttoncomponent %}#toc-size)).
         *
         * The possible values are:
         * * `'small'`
         * * `'medium'` (default)
         * * `'large'`
         * * `null`
         */
        _this.size = 'medium';
        /**
         * The shape property specifies if the DropDownButton will be a square or rectangle.
         * ([see example]({% slug api_buttons_dropdownbuttoncomponent %}#toc-shape)).
         *
         * The possible values are:
         * * `'rectangle'` (default)
         * * `'square'`
         * * `null`
         */
        _this.shape = 'rectangle';
        /**
         * The rounded property specifies the border radius of the DropDownButton
         * ([see example]({% slug api_buttons_dropdownbuttoncomponent %}#toc-rounded)).
         *
         * The possible values are:
         * * `'small'`
         * * `'medium'` (default)
         * * `'large'`
         * * `'full'`
         * * `null`
         */
        _this.rounded = 'medium';
        /**
         * The DropDownButton allows you to specify predefined theme colors.
         * The theme color will be applied as a background and border color while also amending the text color accordingly
         * ([see example]({% slug api_buttons_dropdownbuttoncomponent %}#toc-themeColor)).
         *
         * The possible values are:
         * * `base` &mdash;Applies coloring based on the `base` theme color. (default)
         * * `primary` &mdash;Applies coloring based on the `primary` theme color.
         * * `secondary`&mdash;Applies coloring based on the `secondary` theme color.
         * * `tertiary`&mdash; Applies coloring based on the `tertiary` theme color.
         * * `info`&mdash;Applies coloring based on the `info` theme color.
         * * `success`&mdash; Applies coloring based on the `success` theme color.
         * * `warning`&mdash; Applies coloring based on the `warning` theme color.
         * * `error`&mdash; Applies coloring based on the `error` theme color.
         * * `dark`&mdash; Applies coloring based on the `dark` theme color.
         * * `light`&mdash; Applies coloring based on the `light` theme color.
         * * `inverse`&mdash; Applies coloring based on the `inverse` theme color.
         * * `null` &mdash;Removes the default CSS class (no class would be rendered).
         */
        _this.themeColor = 'base';
        /**
         * Specifies the [`tabIndex`](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex) of the component.
         */
        _this.tabIndex = 0;
        /**
         * Fires each time the user clicks on a drop-down list item. The event data contains the data item bound to the clicked list item.
         */
        _this.itemClick = new core_1.EventEmitter();
        /**
         * Fires each time the popup is about to open.
         * This event is preventable. If you cancel the event, the popup will remain closed.
         */
        _this.open = new core_1.EventEmitter();
        /**
         * Fires each time the popup is about to close.
         * This event is preventable. If you cancel the event, the popup will remain open.
         */
        _this.close = new core_1.EventEmitter();
        /**
         * Fires each time the DropDownButton gets focused.
         */
        _this.onFocus = new core_1.EventEmitter(); //tslint:disable-line:no-output-rename
        /**
         * Fires each time the DropDownButton gets blurred.
         */
        _this.onBlur = new core_1.EventEmitter(); //tslint:disable-line:no-output-rename
        _this.listId = kendo_angular_common_1.guid();
        _this._fillMode = 'solid';
        _this._itemClick = _this.itemClick;
        _this._blur = _this.onBlur;
        return _this;
    }
    Object.defineProperty(DropDownButtonComponent.prototype, "popupSettings", {
        get: function () {
            return this._popupSettings;
        },
        /**
         * Configures the popup of the DropDownButton.
         *
         * The available options are:
         * - `animate: Boolean`&mdash;Controls the popup animation. By default, the open and close animations are enabled.
         * - `popupClass: String`&mdash;Specifies a list of CSS classes that are used to style the popup.
         * - `appendTo: "root" | "component" | ViewContainerRef`&mdash;Specifies the component to which the popup will be appended.
         * - `align: "left" | "center" | "right"`&mdash;Specifies the alignment of the popup.
         */
        set: function (settings) {
            this._popupSettings = Object.assign({ animate: true, popupClass: '' }, settings);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DropDownButtonComponent.prototype, "disabled", {
        get: function () {
            return this._disabled;
        },
        /**
         * Sets the disabled state of the DropDownButton.
         */
        set: function (value) {
            if (value && this.openState) {
                this.openState = false;
            }
            this._disabled = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DropDownButtonComponent.prototype, "data", {
        get: function () {
            return this._data;
        },
        /**
         * Sets or gets the data of the DropDownButton.
         *
         * > The data has to be provided in an array-like list.
         */
        set: function (data) {
            this._data = data || [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DropDownButtonComponent.prototype, "fillMode", {
        get: function () {
            return this._fillMode;
        },
        /**
         * The fillMode property specifies the background and border styles of the DropDownButton
         * ([see example]({% slug api_buttons_dropdownbuttoncomponent %}#toc-fillMode)).
         *
         * The available values are:
         * * `solid` (default)
         * * `flat`
         * * `outline`
         * * `link`
         * * `null`
         */
        set: function (fillMode) {
            this._fillMode = fillMode === 'clear' ? 'flat' : fillMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DropDownButtonComponent.prototype, "openState", {
        /**
         * @hidden
         */
        get: function () {
            return this._open;
        },
        /**
         * @hidden
         */
        set: function (open) {
            if (this.disabled) {
                return;
            }
            var eventArgs = new preventable_event_1.PreventableEvent();
            if (open) {
                this.open.emit(eventArgs);
            }
            else {
                this.close.emit(eventArgs);
            }
            if (eventArgs.isDefaultPrevented()) {
                return;
            }
            this._toggle(open);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DropDownButtonComponent.prototype, "componentTabIndex", {
        /**
         * @hidden
         */
        get: function () {
            return this.disabled ? (-1) : this.tabIndex;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DropDownButtonComponent.prototype, "appendTo", {
        get: function () {
            var appendTo = this.popupSettings.appendTo;
            if (!appendTo || appendTo === 'root') {
                return undefined;
            }
            return appendTo === 'component' ? this.container : appendTo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DropDownButtonComponent.prototype, "focused", {
        get: function () {
            return this._isFocused && !this._disabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DropDownButtonComponent.prototype, "widgetClasses", {
        get: function () {
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DropDownButtonComponent.prototype, "dir", {
        get: function () {
            return this.direction;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DropDownButtonComponent.prototype, "active", {
        /**
         * @hidden
         */
        get: function () {
            return this._active;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @hidden
     */
    DropDownButtonComponent.prototype.keydown = function (event) {
        this.keyDownHandler(event);
        if (event.keyCode === kendo_angular_common_1.Keys.Space) {
            this._active = true;
        }
    };
    /**
     * @hidden
     */
    DropDownButtonComponent.prototype.keypress = function (event) {
        this.keyPressHandler(event);
    };
    /**
     * @hidden
     */
    DropDownButtonComponent.prototype.keyup = function (event) {
        this.keyUpHandler(event);
        this._active = false;
    };
    /**
     * @hidden
     */
    DropDownButtonComponent.prototype.mousedown = function (event) {
        if (this._disabled) {
            event.preventDefault();
        }
        this._active = true;
    };
    /**
     * @hidden
     */
    DropDownButtonComponent.prototype.mouseup = function (event) {
        if (this._disabled) {
            event.preventDefault();
        }
        this._active = false;
    };
    /**
     * @hidden
     */
    DropDownButtonComponent.prototype.openPopup = function () {
        this.togglePopupVisibility();
    };
    /**
     * @hidden
     */
    DropDownButtonComponent.prototype.onButtonBlur = function () {
        if (!this.isOpen) {
            this.blurWrapper();
        }
    };
    Object.defineProperty(DropDownButtonComponent.prototype, "anchorAlign", {
        /**
         * @hidden
         */
        get: function () {
            var align = { horizontal: this.popupSettings.align || 'left', vertical: 'bottom' };
            if (this.direction === 'rtl' && !util_1.isPresent(this.popupSettings.align)) {
                align.horizontal = 'right';
            }
            return align;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DropDownButtonComponent.prototype, "popupAlign", {
        /**
         * @hidden
         */
        get: function () {
            var align = { horizontal: this.popupSettings.align || 'left', vertical: 'top' };
            if (this.direction === 'rtl' && !util_1.isPresent(this.popupSettings.align)) {
                align.horizontal = 'right';
            }
            return align;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Focuses the DropDownButton component.
     */
    DropDownButtonComponent.prototype.focus = function () {
        if (kendo_angular_common_1.isDocumentAvailable()) {
            this.button.nativeElement.focus();
        }
    };
    /**
     * Blurs the DropDownButton component.
     */
    DropDownButtonComponent.prototype.blur = function () {
        if (kendo_angular_common_1.isDocumentAvailable()) {
            this.button.nativeElement.blur();
            this.blurWrapper();
        }
    };
    DropDownButtonComponent.prototype.ngOnChanges = function (changes) {
        if (kendo_angular_common_1.isChanged("popupSettings", changes) && util_1.isPresent(this.popupRef)) {
            var popup = this.popupRef.popup.instance;
            var newSettings = changes.popupSettings.currentValue;
            popup.popupClass = newSettings.popupClass;
            popup.animate = newSettings.animate;
            popup.popupAlign = this.popupAlign;
        }
    };
    DropDownButtonComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        this.destroyPopup();
    };
    /**
     * Toggles the visibility of the popup.
     * If the `toggle` method is used to open or close the popup, the `open` and `close` events will not be fired.
     *
     * @param open - The state of the popup.
     */
    DropDownButtonComponent.prototype.toggle = function (open) {
        var _this = this;
        if (this.disabled) {
            return;
        }
        util_1.tick(function () { return (_this._toggle((open === undefined) ? !_this._open : open)); });
    };
    Object.defineProperty(DropDownButtonComponent.prototype, "isOpen", {
        /**
         * Returns the current open state of the popup.
         */
        get: function () {
            return this.openState;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @hidden
     */
    DropDownButtonComponent.prototype.handleFocus = function () {
        if (!this._disabled && !this._isFocused) {
            this._isFocused = true;
            this.onFocus.emit();
        }
    };
    /**
     * @hidden
     */
    DropDownButtonComponent.prototype.wrapperContains = function (element) {
        return this.wrapper === element
            || this.wrapper.contains(element)
            || (this.popupRef && this.popupRef.popupElement.contains(element));
    };
    DropDownButtonComponent.prototype.subscribeNavigationEvents = function () {
        this.navigationSubscription = this.navigationService.navigate
            .subscribe(this.onArrowKeyNavigate.bind(this));
        this.enterUpSubscription = this.navigationService.enterup.subscribe(this.onNavigationEnterUp.bind(this));
        this.openSubscription = this.navigationService.open.subscribe(this.onNavigationOpen.bind(this));
        this.closeSubscription = rxjs_1.merge(this.navigationService.close, this.navigationService.esc).subscribe(this.onNavigationClose.bind(this));
    };
    DropDownButtonComponent.prototype.onNavigationEnterUp = function () {
        if (!this._disabled && !this.openState) {
            this._active = false;
        }
        if (this.openState) {
            var focused = this.focusService.focused;
            if (util_1.isPresent(focused) && focused !== -1) {
                this.emitItemClickHandler(focused);
            }
        }
        this.togglePopupVisibility();
        if (!this.openState && kendo_angular_common_1.isDocumentAvailable()) {
            this.button.nativeElement.focus();
        }
    };
    DropDownButtonComponent.prototype.onNavigationOpen = function () {
        if (!this._disabled && !this.openState) {
            this.togglePopupVisibility();
        }
    };
    DropDownButtonComponent.prototype.onNavigationClose = function () {
        if (this.openState) {
            this.togglePopupVisibility();
            if (kendo_angular_common_1.isDocumentAvailable()) {
                this.button.nativeElement.focus();
            }
        }
    };
    DropDownButtonComponent.prototype.onArrowKeyNavigate = function (index) {
        this.focusService.focus(index);
    };
    DropDownButtonComponent.prototype._toggle = function (open) {
        if (this._open === open) {
            return;
        }
        this._open = open;
        this.destroyPopup();
        if (this._open) {
            this.createPopup();
        }
    };
    DropDownButtonComponent.prototype.createPopup = function () {
        var _this = this;
        this.popupRef = this.popupService.open({
            anchor: this.elRef,
            anchorAlign: this.anchorAlign,
            animate: this.popupSettings.animate,
            appendTo: this.appendTo,
            content: this.popupTemplate,
            popupAlign: this.popupAlign,
            popupClass: this.popupClasses
        });
        this.popupRef.popupAnchorViewportLeave.subscribe(function () { return _this.openState = false; });
        this.popupRef.popupOpen.subscribe(this.focusFirstItem.bind(this));
    };
    DropDownButtonComponent.prototype.destroyPopup = function () {
        if (this.popupRef) {
            this.popupRef.close();
            this.popupRef = null;
        }
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], DropDownButtonComponent.prototype, "icon", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], DropDownButtonComponent.prototype, "iconClass", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], DropDownButtonComponent.prototype, "imageUrl", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object),
        tslib_1.__metadata("design:paramtypes", [Object])
    ], DropDownButtonComponent.prototype, "popupSettings", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], DropDownButtonComponent.prototype, "textField", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [Boolean])
    ], DropDownButtonComponent.prototype, "disabled", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object),
        tslib_1.__metadata("design:paramtypes", [Object])
    ], DropDownButtonComponent.prototype, "data", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], DropDownButtonComponent.prototype, "size", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], DropDownButtonComponent.prototype, "shape", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], DropDownButtonComponent.prototype, "rounded", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], DropDownButtonComponent.prototype, "fillMode", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], DropDownButtonComponent.prototype, "themeColor", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], DropDownButtonComponent.prototype, "buttonClass", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], DropDownButtonComponent.prototype, "tabIndex", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], DropDownButtonComponent.prototype, "itemClick", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], DropDownButtonComponent.prototype, "open", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], DropDownButtonComponent.prototype, "close", void 0);
    tslib_1.__decorate([
        core_1.Output('focus'),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], DropDownButtonComponent.prototype, "onFocus", void 0);
    tslib_1.__decorate([
        core_1.Output('blur'),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], DropDownButtonComponent.prototype, "onBlur", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-focus'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], DropDownButtonComponent.prototype, "focused", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-dropdown-button'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], DropDownButtonComponent.prototype, "widgetClasses", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.dir'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], DropDownButtonComponent.prototype, "dir", null);
    tslib_1.__decorate([
        core_1.ContentChild(button_item_template_directive_1.ButtonItemTemplateDirective, { static: false }),
        tslib_1.__metadata("design:type", button_item_template_directive_1.ButtonItemTemplateDirective)
    ], DropDownButtonComponent.prototype, "itemTemplate", void 0);
    tslib_1.__decorate([
        core_1.ViewChild('button', { static: true }),
        tslib_1.__metadata("design:type", core_1.ElementRef)
    ], DropDownButtonComponent.prototype, "button", void 0);
    tslib_1.__decorate([
        core_1.ViewChild('buttonList', { static: false }),
        tslib_1.__metadata("design:type", list_component_1.ListComponent)
    ], DropDownButtonComponent.prototype, "buttonList", void 0);
    tslib_1.__decorate([
        core_1.ViewChild('popupTemplate', { static: true }),
        tslib_1.__metadata("design:type", core_1.TemplateRef)
    ], DropDownButtonComponent.prototype, "popupTemplate", void 0);
    tslib_1.__decorate([
        core_1.ViewChild('container', { read: core_1.ViewContainerRef, static: true }),
        tslib_1.__metadata("design:type", core_1.ViewContainerRef)
    ], DropDownButtonComponent.prototype, "container", void 0);
    tslib_1.__decorate([
        core_1.HostListener('keydown', ['$event']),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], DropDownButtonComponent.prototype, "keydown", null);
    tslib_1.__decorate([
        core_1.HostListener('keypress', ['$event']),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], DropDownButtonComponent.prototype, "keypress", null);
    tslib_1.__decorate([
        core_1.HostListener('keyup', ['$event']),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], DropDownButtonComponent.prototype, "keyup", null);
    tslib_1.__decorate([
        core_1.HostListener('mousedown', ['$event']),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], DropDownButtonComponent.prototype, "mousedown", null);
    tslib_1.__decorate([
        core_1.HostListener('mouseup', ['$event']),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], DropDownButtonComponent.prototype, "mouseup", null);
    DropDownButtonComponent = tslib_1.__decorate([
        core_1.Component({
            exportAs: 'kendoDropDownButton',
            providers: [
                focus_service_1.FocusService,
                navigation_service_1.NavigationService,
                NAVIGATION_SETTINGS_PROVIDER,
                kendo_angular_l10n_1.LocalizationService,
                {
                    provide: kendo_angular_l10n_1.L10N_PREFIX,
                    useValue: 'kendo.dropdownbutton'
                }
            ],
            selector: 'kendo-dropdownbutton',
            template: "\n        <button kendoButton #button\n            role=\"menu\"\n            type=\"button\"\n            [tabindex]=\"componentTabIndex\"\n            [class.k-active]=\"active\"\n            [disabled]=\"disabled\"\n            [icon]=\"icon\"\n            [iconClass]=\"iconClass\"\n            [imageUrl]=\"imageUrl\"\n            [ngClass]=\"buttonClass\"\n            [size]=\"size\"\n            [shape]=\"shape\"\n            [rounded]=\"rounded\"\n            [fillMode]=\"fillMode\"\n            [themeColor]=\"fillMode ? themeColor : null\"\n            (click)=\"openPopup()\"\n            (focus)=\"handleFocus()\"\n            (blur)=\"onButtonBlur()\"\n            [attr.aria-disabled]=\"disabled\"\n            [attr.aria-expanded]=\"openState\"\n            [attr.aria-haspopup]=\"true\"\n            [attr.aria-owns]=\"listId\"\n        >\n            <ng-content></ng-content>\n        </button>\n        <ng-template #popupTemplate>\n            <kendo-button-list\n                #buttonList\n                [id]=\"listId\"\n                [data]=\"data\"\n                [textField]=\"textField\"\n                [itemTemplate]=\"itemTemplate\"\n                (onItemClick)=\"onItemClick($event)\"\n                (keydown)=\"keyDownHandler($event)\"\n                (keypress)=\"keyPressHandler($event)\"\n                (keyup)=\"keyUpHandler($event)\"\n                [attr.dir]=\"dir\"\n                [size]=\"size\"\n            >\n            </kendo-button-list>\n        </ng-template>\n        <ng-container #container></ng-container>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [focus_service_1.FocusService,
            navigation_service_1.NavigationService,
            core_1.ElementRef,
            core_1.NgZone,
            kendo_angular_popup_1.PopupService,
            core_1.ElementRef,
            kendo_angular_l10n_1.LocalizationService,
            core_1.ChangeDetectorRef])
    ], DropDownButtonComponent);
    return DropDownButtonComponent;
}(list_button_1.ListButton));
exports.DropDownButtonComponent = DropDownButtonComponent;
