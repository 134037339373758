/**
 * @ngdoc directive
 * @name amtFramework.filter.directive:amtMonthpickerFilteritem
 * @sortOrder 9
 * @restrict E
 * @requires amtFramework.filter.directive:amtFilter
 * 
 * @param {string} label Label to display above the control
 * @param {string} propertyName Name of the property that is added to filterValues property of {@link amtFramework.filter.directive:amtFilter amtFilter}
 * @param {number} minYear Minimum year
 * @param {number} maxYear Minimum Month
 * @param {number} minMonth Maximum Year
 * @param {number} maxMonth Maximum Month
 * @param {expression=} [isRequired=false] Required
 * @param {expression=} [isDisabled=false] Bool Disabled
 * @Param {expression=} defaultValue Default value.
 * <pre>
 * {
 *   year: 2015, 
 *   month: 1
 * }
 * </pre> 
 * 
 * @description
 * Filter item that let's the user filter on a year and month. Only adds the value to filtervalues when the user selects a value.
 * 
 * @example
 * See {@link amtFramework.filter.directive:amtFilter amtFilter}
 */
//import angular from 'angular';
import tmpl from './amtMonthpickerFilteritem.html';

angular.module('amtFramework.filter').directive('amtMonthpickerFilteritem', function () {
            return {
                require: '^amtFilter',
                restrict: 'E',
                scope: {
                    label: '@',
                    propertyName: "@",
                    minYear: '=?',
                    maxYear: '=?',
                    minMonth: '=?',
                    maxMonth: '=?',
                    isRequired: '=?',
                    defaultValue: '=?',
                    isDisabled: '=?'

                },
                template: tmpl,
                link: function (scope: any, elem, attr, filterCtrl) {
                    scope.filterItem = { propertyName: scope.propertyName };
                    filterCtrl.addFilterItem(scope.filterItem);


                    scope.$watch('defaultValue', function (newValue, oldValue) {
                        if (!newValue) {
                            return;
                        }
                        scope.filterItem.value = newValue;
                    }, true);
                }
            };
});
