//import angular from 'angular';
import AdminUrls from '../../../adminUrls';
import tmpl from './setPositionLabel.html';



    angular.module('app.admin').component('setPositionLabel', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: [
            '$scope', 'WindowFactory', 'amtCommandQuerySvc', 'referenceDataUrls', 'adminUrls', 'amtXlatSvc', 'notifySvc',
            function (
                $scope, WindowFactory: IWindowFactory, amtCommandQuerySvc, referenceDataUrls, adminUrls: AdminUrls, amtXlatSvc: IAmtXlatSvc, notifySvc
            ) {
                var vm = this;
                $scope.vm = this;

                vm.form = {};

                vm.referenceDataUrls = referenceDataUrls;
                vm.adminUrls = adminUrls;

                this.$onInit = function () {
                    vm.data = {
                        systemHierarchyId: vm.wnd.initParams.systemHierarchyId,
                        wheelPositionTypeId: vm.wnd.initParams.position.id,
                        wheelPositionLabelId: vm.wnd.initParams.position.wheelPositionLabelId,
                        existingOrNew: 'EXISTING'
                    };

                    WindowFactory.addButton(
                        this,
                        'common.save_label', // caption
                        'save',
                        vm.save,
                        true
                    );

                    vm.setPristine();
                };

                vm.validate = function () {
                    var newLabel = '';

                    if (vm.data.existingOrNew === 'EXISTING') {
                        newLabel = vm.data.label.key.toUpperCase();
                        if (!vm.data.wheelPositionLabelId) {
                            return false;
                        }
                    } else {
                        newLabel = vm.data.labelText.toUpperCase();
                        if (!vm.data.labelText || vm.data.labelText.length === 0) {
                            return false;
                        }
                    }

                    for (var a = 0; a < vm.wnd.initParams.axles.length; a++) {
                        var axle = vm.wnd.initParams.axles[a];
                        for (var l = 0; l < axle.positions.length; l++) {
                            var pos = axle.positions[l];

                            if (pos.label && pos.id != vm.data.wheelPositionTypeId) {
                                if (pos.label.toUpperCase() == newLabel) {
                                    notifySvc.success(amtXlatSvc.xlat('specifications.setLabel_labelInUse'));
                                    return false;
                                }
                            }
                        }
                    }

                    return true;
                };

                vm.setPristine = function () {
                    if (vm.form && vm.form.$setPristine) {
                        vm.form.$setPristine();
                    }
                };

                vm.save = function () {

                    if (vm.validate()) {

                        vm.wnd.processing = true;

                        amtCommandQuerySvc.post(vm.adminUrls.setPositionLabel, vm.data).then(function (response) {

                            if (vm.data.existingOrNew === 'EXISTING' && vm.data.label) {
                                response.data.labelText = vm.data.label.description;
                            }

                            if (vm.wnd.onDataChanged) {
                                vm.wnd.onDataChanged(response.data);
                            }

                            vm.setPristine();

                            WindowFactory.close(vm.wnd);

                            notifySvc.success(amtXlatSvc.xlat('specifications.setLabel_success'));
                        }, amtCommandQuerySvc.handleError).finally(function () {
                            vm.wnd.processing = false;
                        });
                    }
                };

                vm.updateButtons = function () {
                    vm.buttonStates.save.disabled = vm.form.$invalid || vm.form.$pristine || vm.wnd.processing;
                };

                $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing'], function () {
                    vm.updateButtons()
                });
            }
        ]
    });
