import { defer, from, Observable, of } from 'rxjs';
import { catchError, finalize, first } from 'rxjs/operators';
import {
    ISetVisualInspectionActiveStatusCriteria, IVisualInspectionHistory, IVisualInspectionHistory_InitParams,
    IVisualInspectionInspection, IVisualInspectionItem_InitParams
} from '../../../../interfaces/visualInspection.interfaces';
import HelperSvc from '../../../../services/helperSvc';
import { VisualInspectionService } from '../../../../services/visual-inspection.service';
import tmpl from './visualInspectionHistory.html';
import './visualInspectionHistory.scss';

interface IVisualInspectionHistoryScope extends ng.IScope {
    editVisualInspection: (visualInspectionRecord) => void;
}

class VisualInspectionHistoryCtrl implements ng.IController, IWindowComponent<IVisualInspectionHistory_InitParams> {

    wnd: IWindowObj<IVisualInspectionHistory_InitParams>;
    form: ng.IFormController;

    visualInspectionId: guid;
    equipmentId: guid;

    inspectionsGrid: IGridControl = {};
    selectedInspection: IVisualInspectionInspection;

    history: IVisualInspectionHistory;

    readonly: boolean;
    canDelete: boolean;
    canModify: boolean;
    canAddFieldSurvey: boolean;

    getHistory$: Observable<IVisualInspectionHistory> = defer(() => {
        this.wnd.processing = true;
        return this.visualInspectionService.getVisualInspectionHistory(this.visualInspectionId);
    }).pipe(
        first(),
        catchError(err => {
            this.errorReporter.logError(err);
            return of(null);
        }),
        finalize(() => this.wnd.processing = false)
    );

    static $inject = ['$scope', 'WindowFactory', 'visualInspectionService', 'confirmSvc', 'helperSvc', 'ocConfigSvc', 'errorReporter', '$timeout', 'ocSecuritySvc'];

    constructor(private $scope: IVisualInspectionHistoryScope, private WindowFactory: IWindowFactory, private visualInspectionService: VisualInspectionService,
        private confirmSvc: IConfirmSvc, private helperSvc: HelperSvc, private ocConfigSvc: IOcConfigSvc, private errorReporter: IErrorReporter,
        private $timeout: ng.ITimeoutService, private ocSecuritySvc: IOcSecuritySvc) {

        this.$scope.editVisualInspection = (visualInspectionRecord) => this.update(visualInspectionRecord.id);
    }

    $onInit() {

        this.wnd.onClose = () => this.close();

        this.equipmentId = this.wnd.initParams.equipmentId;
        this.visualInspectionId = this.wnd.initParams.visualInspectionId;        
        this.readonly = this.wnd.initParams.readonly;

        this.canDelete = !this.readonly && this.ocSecuritySvc.isAuthorised('Security.Components.VisualInspections.Delete', AccessTypes.readWrite);
        this.canModify = !this.readonly && this.ocSecuritySvc.isAuthorised('Security.Components.VisualInspections', AccessTypes.readWrite);
        this.canAddFieldSurvey = !this.readonly && this.ocSecuritySvc.isAuthorised('Security.Vehicles.FieldSurveys.Add', AccessTypes.readWrite);

        this.WindowFactory.addButton(this, 'common.close_label', 'close', () => this.close(), true);

        if (this.canModify) {
            this.WindowFactory.addAction(this, 'visualInspection.update', 'update', () => this.update());
            this.WindowFactory.addAction(this, 'visualInspection.editCause', 'editCause', () => this.editCause());
        }

        this.getHistory$.subscribe(
            res => {
                this.onHistoryRetrieved(res);

                this.WindowFactory.addAction(this, 'visualInspection.openComponentDetails', 'componentDetails', () => this.openComponentDetails());

                if (this.history?.vehicleEquipmentId) {
                    this.WindowFactory.addAction(this, 'visualInspection.openVehicleDetails', 'vehicleDetails', () => this.openVehicleDetails());

                    if (this.canAddFieldSurvey)
                        this.WindowFactory.addAction(this, 'visualInspection.addFieldSurvey', 'addFieldSurvey', () => this.addFieldSurvey());
                }

                if (this.canDelete)
                    this.WindowFactory.addAction(this, 'visualInspection.delete', 'delete', () => this.delete());
            }
        );
    }

    getHistory() {
        this.getHistory$.subscribe(res => this.onHistoryRetrieved(res));
    }

    private onHistoryRetrieved(history: IVisualInspectionHistory) {
        this.history = history;
        this.refreshInspectionsGrid();

        if (this.canModify) {
            let toggleActiveButton = this.wnd.buttons.find(b => b.name === 'toggleActive');
            let toggleActiveCaption = this.history?.active ? 'visualInspection.setInactive' : 'visualInspection.setActive';

            if (toggleActiveButton) {
                toggleActiveButton.title = toggleActiveCaption;
            } else {
                this.WindowFactory.addAction(this, toggleActiveCaption, 'toggleActive', () => this.toggleActiveStatus());
            }
        }
    }

    openComponentDetails() {
        this.helperSvc.showComponentDetails({
            equipmentId: this.equipmentId,
            siteId: this.ocConfigSvc.user.site.id
        });
    }

    openVehicleDetails() {
        if (this.history.vehicleEquipmentId) {
            this.helperSvc.showVehicleDetails({
                equipmentId: this.history.vehicleEquipmentId,
                siteId: this.ocConfigSvc.user.site.id
            });
        }
    }

    // open the field survey window for a new or existing field survey for the vehicle the component was fitted to when the visual inspection was first raised
    // if a particular inspection record is selected it will use the date of that record for the field survey
    addFieldSurvey() {
        if (this.history.vehicleEquipmentId)
            this.visualInspectionService.addFieldSurvey(this.history.vehicleEquipmentId, this.selectedInspection?.date, () => this.getHistory());
    }

    update(visualInspectionRecordId?: guid) {

        let record = this.history.inspections.find(r => r.id === visualInspectionRecordId);

        let params: IVisualInspectionItem_InitParams = {
            componentTypeId: this.history.equipmentTypeId,
            visualInspectionId: this.visualInspectionId,
            visualInspectionRecordId: visualInspectionRecordId,
            showDate: true,
            commitOnSave: true,
            readonly: !this.canModify,
            canDelete: record && record.id !== this.history.id // can't delete the original inspection
        };

        this.visualInspectionService.openVisualInspectionItem(params, (visualInspection) => {
            if (this.wnd.onDataChanged)
                this.wnd.onDataChanged(visualInspection);

            this.getHistory();
        });
    }

    editCause() {
        this.visualInspectionService.editVisualInspectionCause(
            this.visualInspectionId,
            this.history.equipmentTypeId,
            this.history.causeId, () => {
                if (this.wnd.onDataChanged)
                    this.wnd.onDataChanged();

                this.getHistory()
            }
        );
    }
    
    delete() {
        if (!this.canDelete)
            return;

        defer(() => {
            this.wnd.processing = true;
            return this.visualInspectionService.deleteVisualInspection(this.visualInspectionId);
        }).pipe(
            first(),
            finalize(() => this.wnd.processing = false)
        ).subscribe(
            success => {
                if (success) {
                    if (this.wnd.onDataChanged)
                        this.wnd.onDataChanged();

                    this.closeWindow();
                }
            }
        );
    }

    toggleActiveStatus() {
        defer(() => {
            this.wnd.processing = true;

            let criteria: ISetVisualInspectionActiveStatusCriteria = {
                visualInspectionId: this.visualInspectionId,
                active: !this.history.active
            };

            return this.visualInspectionService.setVisualInspectionActiveStatus(criteria);
        })
            .pipe(
                first(),
                catchError(err => {
                    this.wnd.processing = false;
                    this.errorReporter.logError(err);
                    return of(false);
                })
            )
            .subscribe(
                success => {
                    this.wnd.processing = false;

                    if (success) {
                        this.getHistory();

                        if (this.wnd.onDataChanged)
                            this.wnd.onDataChanged();
                    }
                }
            );
    }

    onInspectionSelected(items: IVisualInspectionInspection[]) {
        this.selectedInspection = items?.[0];
    }

    refreshInspectionsGrid() {
        this.$timeout(() => {
            if (this.inspectionsGrid?.refresh)
                this.inspectionsGrid.refresh();
        });
    }

    close() {
        from(this.confirmSvc.confirmSaveChange(this.form.$dirty))
            .pipe(first())
            .subscribe(() => this.closeWindow());
    }

    closeWindow() {
        this.form.$setPristine();
        this.WindowFactory.closeWindow(this.wnd);
    }
}

class VisualInspectionHistoryComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = VisualInspectionHistoryCtrl;
    public controllerAs = 'vm';
}

angular.module('app.component').component('visualInspectionHistory', new VisualInspectionHistoryComponent());