<head>
    <!--Kendo UI script-->
    <script src="https://kendo.cdn.telerik.com/2021.1.224/js/jquery.min.js"></script>
    <script src="https://kendo.cdn.telerik.com/2021.1.224/js/kendo.all.min.js"></script>
</head>

<!--Site Dashboard Header-->
<div class="amt-view-header bg-light lter b-b ng-scope ng-isolate-scope">
    <div class="site-dashboard-menu-flex-box">

        <!--Selected Dashboard Title-->
        <h1 class="m-n font-thin h3 amt-title-text ng-binding ng-scope"> {{selectedDashboard.name}}</h1>

        <!--Site Dashboard Selection Menu-->
        <site-dashboard-menu></site-dashboard-menu>
    </div>
</div>

<!-- Dialog Container-->
<div>
    <dialog-health-rate-element class="site-dashboard-dialog-container" *ngIf="isShowHealthRateDialog"></dialog-health-rate-element>
    <dialog-live-forecast-tyre-element class="site-dashboard-dialog-container live-forecast-tyre-dialog-container-width" *ngIf="isShowLiveForecastTyreDialog"></dialog-live-forecast-tyre-element>
    <dialog-live-forecast-rim-element class="site-dashboard-dialog-container live-forecast-tyre-dialog-container-width" *ngIf="isShowLiveForecastRimDialog"></dialog-live-forecast-rim-element>
</div>

<!--Kendo Tile Layout-->
<div class="site-dashboard-tile-layout-box" [ngClass]="upTrigger ===  selectedDashboard.id ? 'up' : ''">
    <ng-container *ngFor="let dashboard of dashboardList">
        <ng-container *ngIf="dashboard.siteDashboardId === selectedDashboard.id">
            <kendo-tilelayout id="tilelayout" [columns]="tileSetting.col" rowHeight="0.95fr" [reorderable]="tileSetting.reorder" autoFlow="tileSetting.flow" style="width: 100%; justify-content: center;" (reorder)="onReorder($event)">
                <ng-container *ngFor="let position of positionList">
                    <ng-container *ngFor="let widget of dashboard.widget">
                        <kendo-tilelayout-item *ngIf="position.siteDashboardId === dashboard.siteDashboardId && widget.siteDashboardWidgetId === position.siteDashboardWidgetId" title="{{widget.title}}" [col]="position.col" [colSpan]="position.colSpan" [rowSpan]="position.rowSpan" [row]="position.row">

                            <kendo-tilelayout-item-body>
                                <!--Data Errors-->
                                <widget-errors *ngIf="widget.name === 'DataErrors'"></widget-errors>
                                <!--Change Completed-->
                                <widget-changes-completed *ngIf="widget.name === 'ChangesCompleted'" [showSubtext]="widget"></widget-changes-completed>
                                <!--Vehicles in Maintainence-->
                                <widget-vehicle-in-maintenance *ngIf="widget.name === 'MaintenanceVehicles'" [showSubtext]="widget"></widget-vehicle-in-maintenance>
                                <!--Tyre Inspection-->
                                <widget-tyres-in-inspection *ngIf="widget.name === 'InspectionTyres'" [showSubtext]="widget"></widget-tyres-in-inspection>
                                <!--Total Downtime-->
                                <widget-total-down-time *ngIf="widget.name === 'TotalDowntime'" [showSubtext]="widget"></widget-total-down-time>
                                <!--Site Health Rate-->
                                <widget-site-health-rating *ngIf="widget.name === 'SiteHealthRating' " [showSubtext]="widget"></widget-site-health-rating>
                                <!--Live Forecast Rim NDT-->
                                <widget-live-forecast-rim *ngIf="widget.name === 'LiveForecastRimNDT'" [getWidgetDetail]="widget"></widget-live-forecast-rim>
                                <!--Scrp Life Trend -->
                                <widget-scrap-life-trend *ngIf="widget.name === 'ScrapLifeTrend'" [getWidgetDetail]="widget"></widget-scrap-life-trend>
                                <!--Pressure Check Vehicle-->
                                <widget-pressure-checks-vehicle *ngIf="widget.name === 'PressureChecksPerVehicle'" [getWidgetDetail]="widget"></widget-pressure-checks-vehicle>
                                <!--Live Forecast Tyres Month-->
                                <widget-live-forecast-tyres-month *ngIf="widget.name === 'LiveForecastTyresMonth'" [getWidgetDetail]="widget"></widget-live-forecast-tyres-month>
                                <!--Pressure KPI-->
                                <widget-pressure-kpi *ngIf="widget.name === 'PressureMaintenanceKPI'" [getWidgetDetail]="widget"></widget-pressure-kpi>
                                <!--Condition Monitoring-->
                                <widget-condition-monitoring *ngIf="widget.name === 'ConditionMonitoring'" [getWidgetDetail]="widget"></widget-condition-monitoring>
                            </kendo-tilelayout-item-body>

                        </kendo-tilelayout-item>
                    </ng-container>
                </ng-container>
            </kendo-tilelayout>
        </ng-container>
    </ng-container>
</div>




