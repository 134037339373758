//import angular from 'angular';
import AdminUrls from '../../../adminUrls';

    angular.module('app.admin').controller("plyRatingSearchCtrl", plyRatingSearchCtrl);

    // @ts-ignore
    plyRatingSearchCtrl.$inject = ["adminUrls", "$scope", "enums", "ocSecuritySvc", "amtXlatSvc", "$timeout", "WindowFactory", "ocConfigSvc", "componentUrls", "$element", "exportSvc", "helperSvc", "referenceDataUrls"];

    // ReSharper disable once InconsistentNaming
    function plyRatingSearchCtrl(adminUrls: AdminUrls, $scope, enums, ocSecuritySvc, amtXlatSvc, $timeout, WindowFactory, ocConfigSvc, componentUrls, $element, exportSvc, helperSvc, referenceDataUrls) {

        var vm = this;

        vm.processing = false;

        vm.adminUrls = adminUrls;
        vm.componentUrls = componentUrls;
        vm.referenceDataUrls = referenceDataUrls;

        vm.config = ocConfigSvc;

        vm.enums = enums;

        vm.criteria = {
            filterValues: {               
                activeOptions: null,
                plyRatingIds: null,
                constructionIds: null
            }
        };

        vm.plyRatingFilterControl = {};

        vm.activeOptions = [{
            name: amtXlatSvc.xlat('common.yes_label'),
            key: true
        }, {
            name: amtXlatSvc.xlat('common.no_label'),
            key: false
        }];

        vm.status = enums.screenStatus.loading;

        vm.gridControl = {};
        vm.selectedItem = null;

        function init() {
            vm.loadDefaults();
        }

        vm.onSelectedItemChanged = function (items) {
            if (items != null && items.length === 1) {
                vm.selectedItem = items[0];
            } else {
                vm.selectedItem = null;
            }
        };

        vm.setHeaderHeight = function (height, offset) {
            vm.gridTop = height + offset;
        };

        vm.loadDefaults = function () {
            vm.defaults = {
                activeOptions: [vm.activeOptions[0]]
            };

            vm.status = enums.screenStatus.ready;
        };

        vm.export = function () {
            exportSvc.exportData(vm.referenceDataUrls.exportPlyRatings, vm.criteria, amtXlatSvc.xlat('plyRating.searchTitle'));
        };

        $scope.editPlyRating = function (item) {
            if (item) {
                WindowFactory.openItem({
                    component: 'add-edit-ply-rating',
                    caption: amtXlatSvc.xlat("plyRating.editPlyRating"),
                    initParams: item,
                    width: 550,
                    onDataChangeHandler: function () {
                        vm.refreshGrid();
                        vm.plyRatingFilterControl.search();
                    },
                    windowRelatedRecordId: item.id
                });
            }
        };

        $scope.addPlyRating = function () {
            WindowFactory.openItem({
                component: 'add-edit-ply-rating',
                caption: amtXlatSvc.xlat("plyRating.addPlyRating"),
                initParams: null,
                width: 550,
                onDataChangeHandler: function () {
                    vm.refreshGrid();
                    vm.plyRatingFilterControl.search();
                }
            });
        };

        vm.refreshGrid = function () {
            vm.gridControl.refresh();
        };

        init();
    }
