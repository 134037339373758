//import angular from 'angular';


    angular.module("app.directives").directive("ocQuickSearch", [
        '$timeout', 'amtCommandQuerySvc', 'equipmentUrls', 'amtXlatSvc', 'WindowFactory', 'UserFactory', 'notifySvc',
        function ($timeout, amtCommandQuerySvc, equipmentUrls, amtXlatSvc, WindowFactory, UserFactory, notifySvc) {
            return {
                scope: false,
                template:
                    '<ul class="nav" >' +
                    '<li>' +
                    '<div style="padding-left: 4px; padding: right: 4px;" ng-mouseenter="vm.showResultsDiv()" ng-mouseleave="vm.hideResultsDiv()">' +
                    '<span class="control-label" style="color: black;">{{"menu.quickSearch" | xlat}}</span>' +
                    '<oc-form width="100%">' +
                    '     <div><input class="form-control input-sm" style="width: 100%; text-transform: uppercase" type="text" autocorrect="off" spellcheck="false" ng-model="vm.searchValue" rem-ng-model-options="{ debounce: 1000 }" rem-ng-change="vm.search()">' +
                    '     <button type="button" class="quick-search-button" ng-click="vm.search()"><span class="glyphicon glyphicon-search" /></button>' +
                    '     <div class="quick-search-results-container fade-in fade-out" ng-show="vm.showResults || vm.searching" >' +
                    '           <ul class="nav" ng-if="vm.searching">' +
                    '             <li><a tabindex="-1"><span><i>{{"equipment.searching" | xlat }}</i></span></a></li> ' +
                    '           </ul>' +
                    '           <ul class="nav" ng-show="vm.showResults">' +
                    '             <li ng-if="!vm.results.length"><a tabindex="-1"><span><i>{{"equipment.noResults" | xlat }}</i></span></a></li> ' +
                    '             <li ng-repeat="r in vm.results">' +
                    '                 <a ng-click="vm.openItem(r)">' +
                    '                     <span>{{r.description}} - {{r.text}}</span>' +
                    '                 </a>' +
                    '             </li>' +
                    '         </ul>' +
                    '     </div>' +
                    '</oc-form>' +
                    '</div>' +
                    '</li>' +
                    '</ul>',
                controllerAs: "vm",
                controller: ["$scope", "$timeout", "amtCommandQuerySvc", "equipmentUrls", "amtXlatSvc", "WindowFactory", "ocSecuritySvc",
                    function ($scope, $timeout: ng.ITimeoutService, amtCommandQuerySvc: IAmtCommandQuerySvc, equipmentUrls, amtXlatSvc: IAmtXlatSvc, WindowFactory: IWindowFactory, ocSecuritySvc: IOcSecuritySvc) {
                        var vm = this;

                        // security
                        vm.canViewVehicles = ocSecuritySvc.isAuthorised('Security.Vehicles', AccessTypes.readOnly);
                        vm.canViewComponents = ocSecuritySvc.isAuthorised('Security.Components', AccessTypes.readOnly);
                        vm.canViewFleets = ocSecuritySvc.isAuthorised('Security.Settings.Fleets', AccessTypes.readOnly);
                        vm.canViewStockCodes = ocSecuritySvc.isAuthorised('Security.Settings.StockCodes', AccessTypes.readOnly);
                        vm.canViewUsers = ocSecuritySvc.isAuthorised('Security.Settings.Users', AccessTypes.readOnly);

                        vm.searchValue = "";
                        vm.apiUrls = equipmentUrls.url;
                        vm.results = [];
                        vm.cancelTimeout = false;
                        vm.showResults = false;

                        vm.hideResultsDiv = function () {
                            vm.showResults = false;
                        };

                        vm.showResultsDiv = function () {
                            vm.showResults = true;
                        };

                        vm.openItem = function (item) {
                            switch (item.type) {
                                case "Vehicle":
                                    WindowFactory.openItem({
                                        component: 'vehicle-details',
                                        caption: amtXlatSvc.xlat('equipment.openVehicle', item.text),
                                        windowRelatedRecordId: item.id,
                                        initParams: {
                                            equipmentId: item.id,
                                            siteId: item.siteId,
                                            serialNumber: item.text
                                        },
                                        width: 800,
                                        height: 850,
                                        modal: false
                                    });
                                    break;
                                case "Component":
                                    WindowFactory.openItem({
                                        component: 'component-details',
                                        caption: amtXlatSvc.xlat('equipment.open' + item.equipmentType, item.text),
                                        windowRelatedRecordId: item.id,
                                        initParams: {
                                            equipmentId: item.id,
                                            siteId: item.siteId,
                                            componentType: item.description,
                                            serialNumber: item.text
                                        },
                                        width: 800
                                    });
                                    break;
                                case "StockCode":
                                    WindowFactory.openItem({
                                        component: 'stock-code-details',
                                        caption: amtXlatSvc.xlat("stockCodes.editStockCode") + " - " + item.text,
                                        initParams: {
                                            stockCodeId: item.id,
                                            isEdit: true
                                        },
                                        width: 650
                                    });
                                    break;
                                case "Fleet":
                                    WindowFactory.openItem({
                                        caption: amtXlatSvc.xlat('fleet.editFleet') + ' - ' + item.text,
                                        component: 'fleet-details',
                                        width: 760,
                                        //type: "fleet",
                                        initParams: {
                                            fleet: {
                                                id: item.id,
                                                name: item.text
                                            },
                                            mode: "edit"
                                        }
                                    });
                                    break;
                                case "User":
                                    WindowFactory.openItem({
                                        component: 'user-details',
                                        caption: amtXlatSvc.xlat('userSecurity.open', item.text),
                                        initParams: {
                                            mode: 'open',
                                            personId: item.id,
                                            name: item.text,
                                            section: 'admin'
                                        },
                                        modal: false,
                                        onDataChangeHandler: $scope.onDataChangeHandler,
                                        width: 1050
                                    });
                                    break;
                            }

                            vm.hideResultsDiv();
                        };

                        vm.search = function () {
                            if (!vm.searchValue || vm.searchValue.length === 0) {
                                return;
                            }

                            vm.hideResultsDiv();

                            var criteria = {
                                searchText: vm.searchValue
                            };

                            vm.searching = true;

                            amtCommandQuerySvc.post(vm.apiUrls.quickSearch, criteria).then(function (response) {

                                if (response) {

                                    vm.results = response;

                                    // filter results based on permissions
                                    if (!vm.canViewVehicles) vm.results = vm.results.filter(function (r) { return r.type !== 'Vehicle'; });
                                    if (!vm.canViewComponents) vm.results = vm.results.filter(function (r) { return r.type !== 'Component'; });
                                    if (!vm.canViewFleets) vm.results = vm.results.filter(function (r) { return r.type !== 'Fleet'; });
                                    if (!vm.canViewStockCodes) vm.results = vm.results.filter(function (r) { return r.type !== 'StockCode'; });
                                    if (!vm.canViewUsers) vm.results = vm.results.filter(function (r) { return r.type !== 'User'; });

                                    vm.showResultsDiv();
                                }

                            }, amtCommandQuerySvc.handleError).finally(function () { vm.searching = false; });

                            $timeout();
                        };

                        $scope.$on('form:submit', function () {
                            vm.search();
                        });
                    }]
            };
        }]);
