/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var util_1 = require("../../common/util");
var multiselecttree_component_1 = require("../multiselecttree.component");
/**
 * A directive which configures the MultiSelectTree to show one single summary tag for all selected data items.
 * When a number is provided, the summary tag is displayed after the given amount of data items are selected
 * ([more information and examples]({% slug api_dropdowns_multiselecttreesummarytagdirective %})).
 *
 * @example
 * ```ts-no-run
 * <kendo-multiselecttree kendoMultiSelectTreeSummaryTag [data]="data"></kendo-multiselecttree>
 * ```
 *
 * @example
 * ```ts-no-run
 * <kendo-multiselecttree [kendoMultiSelectTreeSummaryTag]="2" [data]="data"></kendo-multiselecttree>
 * ```
 */
var MultiSelectTreeSummaryTagDirective = /** @class */ (function () {
    function MultiSelectTreeSummaryTagDirective(multiSelectTreeComponent) {
        this.multiSelectTreeComponent = multiSelectTreeComponent;
        /**
         * A numeric value that indicates the number of selected data items after which the summary tag will appear.
         */
        this.showAfter = 0;
        this.createTagMapper();
    }
    MultiSelectTreeSummaryTagDirective.prototype.ngOnChanges = function (changes) {
        if (util_1.isPresent(changes.showAfter)) {
            this.createTagMapper();
            this.multiSelectTreeComponent.handleTagMapperChange(this.showAfter);
        }
    };
    MultiSelectTreeSummaryTagDirective.prototype.createTagMapper = function () {
        var showAfter = util_1.parseNumber(this.showAfter);
        this.multiSelectTreeComponent.tagMapper = function (tags) {
            if (tags.length > showAfter) {
                // tags provided in an array are rendered as a single group tag
                return tags.slice(0, showAfter).concat([tags.slice(showAfter)]);
            }
            else {
                return tags;
            }
        };
    };
    tslib_1.__decorate([
        core_1.Input('kendoMultiSelectTreeSummaryTag'),
        tslib_1.__metadata("design:type", Object)
    ], MultiSelectTreeSummaryTagDirective.prototype, "showAfter", void 0);
    MultiSelectTreeSummaryTagDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoMultiSelectTreeSummaryTag]'
        }),
        tslib_1.__metadata("design:paramtypes", [multiselecttree_component_1.MultiSelectTreeComponent])
    ], MultiSelectTreeSummaryTagDirective);
    return MultiSelectTreeSummaryTagDirective;
}());
exports.MultiSelectTreeSummaryTagDirective = MultiSelectTreeSummaryTagDirective;
