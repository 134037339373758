//import angular from 'angular';
import OcDateSvc from '../../../../services/ocDateSvc';
import { TMaintenanceSessionEvents_Api, TMaintenanceSession, TMaintenanceSession_Event, IMaintenanceSession_Assembly  } from '../maintenanceCtrl';
import tmpl from './Fit.html';



class FitCtrl implements ng.IController, IWindowController {

    form: ng.IFormController;
    wnd: IWindowObj;

    api: TMaintenanceSessionEvents_Api;
    
    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: () => void };

    initParams: any;

    statusControl: any = {};
    positionsControl: any = {};
    pressureSensorControl: any = {};

    fittableSerialNumbers: any = null;

    showNitrogenCheckbox = this.ocConfigSvc.user.site.settings.tyreMaintenance.useNitrogen;
    allowPressureSensors = this.ocConfigSvc.user.site.settings.usePressureSensors;
    trackRims = this.ocConfigSvc.user.site.settings.tyreMaintenance.trackRims;

    autoCompleteOptions = {
        noDataTemplate: this.amtXlatSvc.xlat('framework.noDataFound')
    };

    defaultStatus = {
        statusTypeFromDescription: this.amtXlatSvc.xlat('maintenanceSession.newSpare'),
        statusTypeIdFrom: this.amtConstants.emptyGuid,
        id: this.amtConstants.emptyGuid
    };

    lubricatedStudsOptions = [
        { key: 1, name: this.amtXlatSvc.xlat('common.yes_label') },
        { key: 2, name: this.amtXlatSvc.xlat('common.no_label') }
    ];

    disabledStatus: boolean = false;
    alertOpen: boolean = false; // prevent multiple alerts opening concurrently
    showAction: boolean;

    equipmentId?: guid;
    pressureSensorId?: guid;

    pressureSensors: any[];
    availablePositions: any[];

    data: any = {};
    selectedSerial: any;
    assemblies: IMaintenanceSession_Assembly[];
    statusFrom: any[];

    actionOptions: any[];

    static $inject = ['$scope', 'dataBroker', 'errorReporter', 'WindowFactory', '$timeout',
        'ocConfigSvc', 'confirmSvc', 'amtXlatSvc', 'amtConstants', 'ocDateSvc'];

    constructor(private $scope: ng.IScope, private dataBroker: IDataBroker, private errorReporter: IErrorReporter,
        private WindowFactory: IWindowFactory, private $timeout: ng.ITimeoutService,
        private ocConfigSvc: IOcConfigSvc, private confirmSvc: IConfirmSvc,
        private amtXlatSvc: IAmtXlatSvc, private amtConstants: IAmtConstants, private ocDateSvc: OcDateSvc) {

        this.$scope.$watchGroup([() => this.form.$invalid, () => this.wnd.processing], () => {
            this.buttonStates.saveButton.disabled = this.form.$invalid || this.wnd.processing;
        });

        this.$scope.$watchGroup([() => this.data.selectedPosition, () => this.data.selectedStatus], (newVal, oldVal) => {
            if (newVal !== oldVal)
                this.getValidFitments();
        });

        // action changed
        this.$scope.$watch(() => this.data.selectedAction, async (newVal, oldVal) => {

            if (newVal !== oldVal) {

                this.data.type = newVal.type;
                this.wnd.processing = true;

                try {
                    let validFitments = await this.getValidFitments();

                    if (validFitments) {

                        // + 2 to ensure we include the "-" and the " ".
                        this.wnd.caption = this.wnd.caption.substring(0, this.wnd.caption.indexOf('-') + 2) + newVal.name;


                        if (newVal.type === EquipmentTypeName.assembly) {
                            this.data.selectedStatus = this.statusFrom.find(s => s.statusTypeFromName && s.statusTypeFromName.toLowerCase() === StatusTypeName.spare);
                            this.disabledStatus = true;
                        } else {
                            this.disabledStatus = false;
                        }

                        // clear serial number
                        this.data.serialNumber = null;

                    } else {
                        this.data.type = oldVal.type;
                        this.data.selectedAction = oldVal;
                    }
                } finally {
                    this.wnd.processing = false;
                }
            }
        });
    }

    async $onInit() {

        this.wnd.processing = true;

        try {
            this.data = this.initParams.data ? angular.copy(this.initParams.data) : {};

            if (this.data.components && this.data.components.length === 1)
                this.equipmentId = this.data.components[0].equipmentId;

            this.assemblies = this.initParams.assemblies;

            this.data.selectedPosition = this.initParams.position;
            this.data.refit = !!this.data.refit; // ensure boolean;

            if (this.initParams.data && this.initParams.data.components)
                this.data.fitment = angular.copy(this.initParams.data.components[0]);

            if (this.initParams.eventData && this.initParams.eventData.selectedMoveTo) {

                let from = this.initParams.eventData.selectedMoveTo;

                this.data.selectedStatus = {
                    description: from.description,
                    statusTypeIdFrom: from.statusTypeIdTo,
                    statusTypeIdTo: from.statusTypeIdFrom,
                    id: from.statusTypeIdFrom
                }

            } else if (!this.data.selectedStatus) {
                this.data.selectedStatus = this.defaultStatus;
            }

            if (this.initParams.type === EquipmentTypeName.tyre &&
                this.initParams.position &&
                this.initParams.position.fitments &&
                this.initParams.position.fitments.length === 0 &&
                this.trackRims) {

                this.initParams.type = EquipmentTypeName.assembly; // there is no rim but the site tracks rims, this is an assembly
            }

            this.data.event = MaintenanceSession_EventType.fit;
            this.data.eventDescription = this.amtXlatSvc.xlat('maintenanceSession.fit_description');

            let saveButtonText = this.data.edit ? 'framework.save_label' : (this.data.refit ? 'maintenanceSession.refit' : 'maintenanceSession.fit');

            this.WindowFactory.addButton(this, saveButtonText, 'saveButton', () => this.onSave(), true);
            this.WindowFactory.addButton(this, 'common.cancel_label', 'cancelButton', () => this.onClose());

            if (this.data.refit) {

                let components = angular.copy(this.initParams.data.eventData.components);

                this.data.type = components[0].type.toLowerCase();
                this.data.typeId = components[0].typeId;
                this.data.serialNumber = components[0].serialNumber;
                this.equipmentId = components[0].equipmentId || components[0].componentId;

                if (components.length > 1) {
                    this.data.type = EquipmentTypeName.assembly;
                    this.data.typeId = this.amtConstants.emptyGuid;
                    this.equipmentId = this.amtConstants.emptyGuid;
                } else {
                    this.pressureSensorId = components[0].pressureSensorId;
                }

            } else {
                this.data.type = this.initParams.type;
            }

            if (!this.data.edit) {

                let positions = this.dataBroker.schematicToPositionsArray(this.initParams.schematic);

                this.availablePositions = positions.filter(position => {

                    // 1. For Assembly or Rim (with rim tracking on), only return the position if there are no fitments.
                    // Rim is ok at any time if track rims is off
                    if (this.initParams.type === EquipmentTypeName.rim && !this.trackRims && !position.RimFitment)
                        return true;

                    if ((this.initParams.type === EquipmentTypeName.assembly || this.initParams.type === EquipmentTypeName.rim) && position.fitments.length === 0)
                        return true;

                    // 2. For Tyre
                    if (this.initParams.type === EquipmentTypeName.tyre) {
                        if (this.trackRims &&
                            position.fitments.some(fitment => fitment.type.toLowerCase() === EquipmentTypeName.rim) &&
                            position.fitments.every(fitment => fitment.type.toLowerCase() !== EquipmentTypeName.tyre)) {
                            // Site is tracking Rims, only return the position if there's a Rim fitment and no Tyre fitments.
                            return true;
                        } else if (!this.trackRims &&
                            position.fitments.every(fitment => fitment.type.toLowerCase() !== EquipmentTypeName.tyre)) {
                            // Site is not tracking Rims, only return the position if there's no Tyre fitments.
                            return true;
                        }
                    }

                    // 3. For Chain, only return the position if there is a Tyre fitment.
                    return this.initParams.type === EquipmentTypeName.chain &&
                        position.fitments.some(fitment => fitment.type.toLowerCase() === EquipmentTypeName.tyre) &&
                        position.fitments.every(fitment => fitment.type.toLowerCase() !== EquipmentTypeName.chain);
                });

                if (this.availablePositions.length === 0)
                    throw new Error('No Available positions for fitting');

                // if the selected position does not match an available position, give them the first available position.
                if (!this.availablePositions.some(position => position.id === this.data.selectedPosition.id))
                    this.data.selectedPosition = this.availablePositions[0];

            } else {
                if (this.data.type === EquipmentTypeName.assembly) {

                    let components = angular.copy(this.initParams.data.components);

                    if (components[0].type.toLowerCase() === EquipmentTypeName.tyre)
                        components.reverse();

                    this.data.serialNumber = components.map(c => c.serialNumber).join('/');
                }
            }

            let secondOptionType: string = this.data.type === EquipmentTypeName.assembly ? EquipmentTypeName.tyre : EquipmentTypeName.assembly;

            this.actionOptions = [
                {
                    key: 1,
                    name: String.format('{0} {1}', this.amtXlatSvc.xlat('maintenanceSession.fit'), this.amtXlatSvc.xlat('maintenanceSession.' + this.data.type)),
                    type: this.data.type
                },
                {
                    key: 2,
                    name: String.format('{0} {1}', this.amtXlatSvc.xlat('maintenanceSession.fit'), this.amtXlatSvc.xlat('maintenanceSession.' + secondOptionType)),
                    type: secondOptionType
                }
            ];

            this.data.selectedAction = this.actionOptions[0]; // default to "Fit [Type]"

            if (this.data.lubricatedStuds != null) {
                if (this.data.lubricatedStuds) {
                    this.data.lubricatedStuds = this.lubricatedStudsOptions[0];
                } else {
                    this.data.lubricatedStuds = this.lubricatedStudsOptions[1];
                }
            }

            this.showAction = !this.data.refit && this.data.type === EquipmentTypeName.rim;

            await this.loadComponentReferenceData();

        } finally {
            this.wnd.processing = false;
        }
    }

    serialSelect(event) {
        if (event.dataItem && event.dataItem.equipmentId)
            this.equipmentId = event.dataItem.equipmentId;

        if (this.allowPressureSensors && this.data.serialNumber && (this.data.type === EquipmentTypeName.tyre || this.data.type === EquipmentTypeName.assembly))
            this.loadPressureSensors();
    }

    getAutoCompleteMatchedItem() {

        let autoComplete = <kendo.ui.AutoComplete>kendo.widgetInstance($("#SerialAutoComplete"));

        if (!autoComplete)
            return;

        // close the autocomplete if it is somehow open                        
        autoComplete.close();

        return autoComplete.dataSource.options.data.find(item => {
            if (!this.equipmentId) {
                // match on serial number
                return this.data.serialNumber === item.serialNumber.formatted;
            } else {
                // match on equipment id
                return item.equipmentId === this.equipmentId;
            }
        });
    }

    async getValidFitments(): Promise<boolean> {

        let hasValidFitments = true;

        if (!this.data.edit && !this.data.refit) {

            let state = null;

            if (this.data.selectedStatus.statusTypeIdFrom !== this.amtConstants.emptyGuid)
                state = this.data.selectedStatus.statusTypeFromDescription;

            if (this.data.selectedPosition && (this.data.selectedPosition.id !== this.amtConstants.emptyGuid)) {

                if (this.data.type === EquipmentTypeName.assembly) {

                    if ((!this.assemblies || this.assemblies.length === 0) && !this.alertOpen) {

                        this.alertOpen = true;
                        hasValidFitments = false;

                        await this.WindowFactory.alert('exception.noValidFitmentsHeader', ['common.ok_label'], 'exception.noAssembliesRemoved');

                        this.alertOpen = false;

                        this.$timeout(); // force a refresh display;                                        

                    } else {

                        let validFitments = await this.dataBroker.getValidFitments(
                            null,
                            this.data.selectedPosition.id,
                            state,
                            this.initParams.schematic,
                            this.ocDateSvc.removeLocalTimeZoneOffset(this.initParams.lastEventDate)
                        );

                        if (validFitments.length === 0 && !this.data.refit && !this.alertOpen) {

                            this.alertOpen = true;
                            hasValidFitments = false;

                            await this.WindowFactory.alert('exception.noValidFitmentsHeader', ['common.ok_label'], 'exception.noValidFitments');
                            this.alertOpen = false;

                            this.WindowFactory.closeWindow(this.wnd);

                            this.$timeout(); // force a refresh display;
                        }

                        let equipmentIds = validFitments.map(f => f.equipmentId);

                        let assemblies = this.assemblies.filter(a => {
                            let valid = true;
                            for (let c of a.components || []) {
                                valid = equipmentIds.indexOf(c.equipmentId || c.componentId) > -1;
                            }
                            return valid;
                        });

                        this.fittableSerialNumbers = new kendo.data.DataSource({
                            data: assemblies
                        });

                        return hasValidFitments;
                    }

                } else {

                    let validFitments = await this.dataBroker.getValidFitments(
                        this.data.type,
                        this.data.selectedPosition.id,
                        state,
                        this.initParams.schematic,
                        this.ocDateSvc.removeLocalTimeZoneOffset(this.initParams.lastEventDate)
                    );

                    if (validFitments.length === 0 && !this.data.refit && !this.alertOpen) {

                        this.alertOpen = true;
                        hasValidFitments = false;

                        await this.WindowFactory.alert('exception.noValidFitmentsHeader', ['common.ok_label'], 'exception.noValidFitments');

                        this.alertOpen = false;

                        this.WindowFactory.closeWindow(this.wnd);

                        this.$timeout(); // force a refresh display;
                    }

                    this.fittableSerialNumbers = new kendo.data.DataSource({
                        data: validFitments
                    });

                    return hasValidFitments;
                }
            }
        }

        return hasValidFitments;
    }

    async getStatusFlows() {

        let getStatusFlowCriteria: IStatusFlowCriteria = {
            equipmentType: this.initParams.type === EquipmentTypeName.assembly ? EquipmentTypeName.tyre : this.initParams.type,
            excludeNewLife: true,
            statusTypeTo: StatusTypeName.fitted
        };

        try {
            let statuses = await this.dataBroker.getStatusFlows(getStatusFlowCriteria);

            this.statusFrom = statuses.result;
            this.statusFrom.unshift(this.defaultStatus)
        } catch (error) {
            this.errorReporter.logError(error, 'Fit-GetStatusFlows');
        }
    }

    async loadPressureSensors() {

        let sensorCriteria: IPressureSensorCriteria = {
            equipmentId: this.equipmentId,
            siteId: this.ocConfigSvc.user.site.id
        };

        try {
            let sensors = await this.dataBroker.getPressureSensors(sensorCriteria);

            this.pressureSensors = sensors.result;

            if (this.data.pressureSensors) {
                let filteredSensors = this.pressureSensors.filter(s => s.key === (this.data.pressureSensors.id || this.data.pressureSensors.key));

                if (filteredSensors)
                    this.data.pressureSensors = filteredSensors[0];
            }

        } catch (error) {
            this.errorReporter.logError(error, 'Fit-GetPressureSensors');
        }
    }

    async loadComponentReferenceData() {

        let promises: Promise<any>[] = [];

        promises.push(this.getStatusFlows());

        if (this.allowPressureSensors && this.data.serialNumber && (this.data.type === EquipmentTypeName.tyre || EquipmentTypeName.assembly))
            promises.push(this.loadPressureSensors());

        await Promise.all(promises);

        if (!this.fittableSerialNumbers)
            await this.getValidFitments();

        this.$timeout(); // make sure UI is updated
    }

    async onSave() {
        //First we need to update the init params as it is used in the caller and can be changed here for rim/assembly
        this.initParams.type = this.data.type;

        // pass the data back to the caller
        let fitment = this.data.fitment;

        if (!(this.data.edit || this.data.refit))
            fitment = this.getAutoCompleteMatchedItem();

        if (!fitment) {
            if (!this.alertOpen) {
                this.alertOpen = true;
                await this.WindowFactory.alert('maintenanceSession.invalidFitmentHeader', ['common.ok_label'], 'maintenanceSession.invalidFitment')
                this.alertOpen = false;
            }
        } else {

            // if fitting an assemby clear the event ids first
            if (fitment.components && fitment.components.length > 1) {
                fitment.components.forEach(c => c.eventId = undefined);

                // rim before tyre
                if (fitment.components[0].type.toLowerCase() === EquipmentTypeName.tyre)
                    fitment.components.reverse();
            }

            this.data.fitment = fitment;

            if (this.wnd.onDataChanged)
                this.wnd.onDataChanged(angular.copy(this.data));
        }
        this.api?.gridRefresh();
    }

    // close window
    async onClose() {
        try {
            await this.confirmSvc.confirmSaveChange(this.form && this.form.$dirty);
        } catch {
            return; // they cancelled
        }        
        this.WindowFactory.closeWindow(this.wnd);
    }
}

class FitComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = FitCtrl;
    public controllerAs = 'vm';
}

angular.module('app.directives').component('fitComponent', new FitComponent());