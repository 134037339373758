//import angular from 'angular';
import AdminUrls from '../../admin/adminUrls';


    angular.module('app.admin').controller('usersSearchCtrl', [
        '$scope', 'amtCommandQuerySvc', 'adminUrls', 'notifySvc', 'amtXlatSvc',
        'enums', 'exportSvc', 'WindowFactory', 'ocSecuritySvc', 'errorReporter',
        function ($scope, amtCommandQuerySvc, adminUrls: AdminUrls, notifySvc, amtXlatSvc: IAmtXlatSvc,
            enums, exportSvc, WindowFactory: IWindowFactory, ocSecuritySvc, errorReporter) {

            var vm = this;

            vm.apiUrls = adminUrls;

            vm.selectedItem = null;

            vm.criteria = {
                filterValues: {
                    clientIds: [],
                    siteIds: [],
                    roleIds: [],
                    firstName: null,
                    lastName: null,
                    options: [],
                    defaultToAllSitesWhenNoSystemHierarchyFilter: true
                }
            };

            vm.showAllLocations = false;

            $scope.$watch('vm.criteria.filterValues.clientIds.length', function (cnt) {
                if (cnt === 0 && vm.criteria.filterValues.siteIds.length > 0) {
                    // reset the sites if the clients are cleared
                    vm.criteria.filterValues.siteIds.length = 0;
                    //console.log('sites reset');
                }
            });

            vm.filter = function () {
                vm.refreshGrid();
            };

            vm.filterOptions = [
                { key: 'IncludeInactiveUsers', name: amtXlatSvc.xlat('userSecurity.includeInactiveUsers') },
                { key: 'IncludeUsersWithNoSystemLogin', name: amtXlatSvc.xlat('userSecurity.includeNoLoginUsers') }
            ];

            vm.gridControl = {};

            vm.addUser = addUser;
            vm.mergeUser = mergeUser;
            vm.deleteUser = deleteUser;
            vm.refreshGrid = refreshGrid;

            vm.defaultCriteria = { siteId: $scope.app.user.site.id };

            $scope.showUserDetails = showUserDetails;

            function showUserDetails(user) {
                WindowFactory.openItem({
                    caption: amtXlatSvc.xlat('userSecurity.open', user.user + (user.userName ? ' (' + user.userName + ')' : '')),
                    component: 'user-details',
                    initParams: {
                        mode: 'open',
                        personId: user.personId,
                        name: user.user,
                        section: 'admin',
                        showCloseOnSave: false
                    },
                    modal: false,
                    onDataChangeHandler: vm.refreshGrid,
                    width: 1050,
                    height: 650
                });
            }

            function addUser() {
                WindowFactory.openItem({
                    caption: amtXlatSvc.xlat('userSecurity.new'),
                    component: 'user-details',
                    initParams: {
                        mode: 'new',
                        section: 'admin',
                        showCloseOnSave: false
                    },
                    modal: false,
                    onDataChangeHandler: vm.refreshGrid,
                    width: 1050,
                    height: 650
                });
            }

            vm.setHeaderHeight = function (height, offset) {
                vm.gridTop = height + offset;
            };

            function mergeUser() {

                WindowFactory.openItem({
                    component: 'user-merge',
                    caption: amtXlatSvc.xlat('mergeUser.mergeTitle'),
                    width: 600,
                    canClose: false,
                    modal: true
                });
            }

            function deleteUser() {

                if (vm.selectedItem) {

                    var person = vm.selectedItem;

                    WindowFactory.iconAlert('glyphicon-check', "userSecurity.deleteConfirmationTitle", ["common.no_label", "common.yes_label"], "userSecurity.deleteConfirmation", person.user)
                        .then(function (result) {
                            if (result !== "common.yes_label") {
                                // do nothing
                                return;
                            }

                            WindowFactory.iconAlert('glyphicon-check', "userSecurity.deleteConfirmationTitle", ["common.keep", "common.delete"], "userSecurity.deleteDoubleConfirmation", person.user)
                                .then(function (result) {

                                    if (result !== "common.delete") {
                                        // do nothing
                                        return;
                                    }

                                    var cmd = {
                                        personId: person.personId
                                    };

                                    vm.processing = true;

                                    amtCommandQuerySvc.deleteItem(vm.apiUrls.deleteUser, cmd).then(function (response) {

                                        if (response.data === 'deleteUser_dundasFail') {
                                            notifySvc.error(amtXlatSvc.xlat("userSecurity.deleteUser_dundasFail", response.errors[0].message));
                                        } else {
                                            notifySvc.success(amtXlatSvc.xlat("userSecurity.deletionSuccessful"));
                                            refreshGrid();
                                        }

                                    }).catch(function (error) {
                                        errorReporter.logError(error);
                                    }).finally(function () {
                                        vm.processing = false;
                                    });
                                });
                        });
                }
            }

            function refreshGrid() {
                vm.gridControl.refresh();
            }

            vm.onSelectedItemChanged = function (items) {
                if (items != null && items.length === 1) {
                    vm.selectedItem = items[0];
                } else {
                    vm.selectedItem = null;
                }
            };

            vm.export = function () {
                exportSvc.exportData(vm.apiUrls.searchExport, vm.criteria, amtXlatSvc.xlat('userSecurity.searchExportFilename'));
            };

            function init() {
                vm.loadDefaults();
            }

            vm.loadDefaults = function () {
                vm.status = 'loading';

                // show all locations if the user has access to Settings.
                vm.showAllLocations = ocSecuritySvc.isAuthorised('Settings.Users.Search All Locations', 'readOnly');

                amtCommandQuerySvc.post(vm.apiUrls.getUserSearchDefaults, vm.defaultCriteria).then(function (response) {
                    if (response) {

                        vm.defaultClients = response.clients;
                        vm.defaultSites = response.sites;
                        vm.status = "ready";

                        // focus on first name field by default
                        vm.focusFirstName = true;
                    }
                }, function (error) {
                    vm.defaultErrors = [error];
                    vm.status = "error";
                    errorReporter.logError(error);
                });
            };
            init();
        }]);
