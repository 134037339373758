(function () {
    'use strict';

    angular.module('app.admin')
        .controller('userSettingsTabsCtrl', ['$rootScope', '$state', '$stateParams', 'confirmSvc', 'tabDirtyTrackingSvc', 'amtConstants', '$scope', 'notifySvc',
        'amtCommandQuerySvc', 'adminUrls', 'enums', 'amtXlatSvc', 'WindowFactory', function($rootScope, $state, $stateParams, confirmSvc, tabDirtyTrackingSvc, amtConstants, $scope, notifySvc,
            amtCommandQuerySvc, adminUrls, enums, xlatSvc, WindowFactory) {
        var vm = this;
        vm.apiUrls = adminUrls.url;
        vm.usersSection = $stateParams.userssection ? $stateParams.userssection === "true" : false;
        vm.personId = $state.params.personid ? $state.params.personid : "";
        vm.updateSettings = updateSettings;
        vm.saveUser = saveUser;
        //Tabs Params
        vm.activeTab = {};
        vm.currentTab = "admintab";
        vm.isNewUser = $stateParams.personid === "0";
        vm.userid = $stateParams.personid;
        vm.user = {
            currentStatus: "Active (Has Login)"
        };
        vm.username = "";
		
        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
			if (tabDirtyTrackingSvc.isTabDirty()) {
                event.preventDefault();

                confirmSvc.confirmSaveChange(tabDirtyTrackingSvc.isTabDirty()).then(function () {
                    $state.go(toState, toParams);
                }, function () {
                    vm.setActiveTab();
                    $state.go(fromState, fromParams);
                });

                tabDirtyTrackingSvc.clearTabDirty();
            }
        });

        vm.setActiveTab = function (tabName) {
            vm.activeTab = {};
            vm.activeTab = {
                'admintab': tabName === vm.tabs.admin,
                'rolestab': tabName === vm.tabs.roles,
                'usersettingstab': tabName === vm.tabs.userSettings,
                'subscriptionstab': tabName === vm.tabs.subscriptions
            };
        };

        vm.tabs = {
            admin: { save: vm.saveUser },
            roles: {},
            userSettings: { save: vm.updateSettings },
            subscriptions: {}
        };

        vm.currentTab = vm.tabs.admin;
        
        function goToParentRoute() {
            try {
                tabDirtyTrackingSvc.clearTabDirty();
            } finally {
                $state.go('app.admin.users');
            }
        }

        vm.onCancel = function () {
            goToParentRoute();
        };

        function init() {
            vm.setActiveTab();
            vm.getUserDetails();
        }

        vm.onSave = function () {
            if (vm.currentTab.save !== undefined) {
                vm.currentTab.save();
            }
        };
        $scope.onRefresh = function () {
            if (vm.currentTab.refresh !== undefined) {
                vm.currentTab.refresh();
            }
        };

        vm.exportFile = function ($event, reportName, formatType) {
            var exportFileUrl = [amtConstants.url.forecastReportUrl, "/", reportName,
                "/page/", 1, "/format/", formatType || "html", "/clearsession/true?PersonId=" + $state.params.personid].join('');

            $scope.$emit('requestStart');
            var hiddenIFrameId = 'hiddenDownloader' + this.reportName;
            var iframe = window.document.getElementById(hiddenIFrameId) as HTMLIFrameElement;

            if (iframe === null) {
                iframe = document.createElement('iframe');
                iframe.id = hiddenIFrameId;
                iframe.style.display = 'none';
                iframe.onload = function () {
                    $scope.$emit('requestEnd');
                };
                window.document.body.appendChild(iframe);
            }
            iframe.src = exportFileUrl;
        };

        // User/Admin Tab
        vm.getUserDetails = function () {
            amtCommandQuerySvc.get(String.format(vm.apiUrls.getUserDetails, vm.userid))
                    .then(function (response) {
                        if (response) {
                            vm.user = response;
                            vm.user.defaultLanguageId = response.data.defaultLanguage.key;
                            vm.user.yubikeyId = response.data.yubikey ? response.data.yubikey.key : "00000000-0000-0000-0000-000000000000";
                            vm.user.needRevalidate = vm.user.currentStatus.needsRevalidation;
                            vm.username = vm.user.firstName + " " + vm.user.lastName;
                        }
                    });
        };

        vm.revalidateUser = function () {
            var model = {
                personId: vm.user.personId,
                revalidate: vm.user.needRevalidate
            };

            amtCommandQuerySvc.put(vm.apiUrls.revalidateUser, model).then(function (response) {
                if (response.status === enums.httpUpdateStatus.success) {
                    vm.user.activeTo = null;
                    vm.user.LastLoginDate = new Date();

                    amtCommandQuerySvc.get(String.format(vm.apiUrls.getUserActiveStatus, vm.userid)).then(
                        function(status) {
                            if (status) {
                                vm.user.currentStatus = status.description;
                                vm.user.needRevalidate = status.needsRevalidation;
                            }
                        });

                    notifySvc.success(xlatSvc.xlat("userSecurity.userStatusChangeSuccessful"));
                } else {
                    notifySvc.error(response.errors[0].message);
                }
            }, amtCommandQuerySvc.handleProtocolError);
        };

        vm.loadNewUserDefaults = function () {
            vm.user.activeFrom = new Date();

            amtCommandQuerySvc.get(vm.apiUrls.getNewUserDefaultLanguage)
                .then(function (response) {
                    if (response) {
                        vm.user.defaultLanguage = response;
                        vm.user.defaultLanguageId = vm.user.defaultLanguage.key;
                    }
                });
        };

        function saveUser() {
            var promise;
            if (vm.isNewUser) {
                promise = amtCommandQuerySvc.post(vm.apiUrls.createNewUser, vm.user);
            } else {
                promise = amtCommandQuerySvc.put(vm.apiUrls.updateUserDetails, vm.user);
            }

            promise.then(function (response) {
                    if (response.status === enums.httpUpdateStatus.fail) {
                        console.error(response.errors[0].message);
                        notifySvc.error(response.errors[0].message);
                        return;
                    }
                    notifySvc.success(String.format(xlatSvc.xlat("userSecurity.saveSuccessful")));
                    goToParentRoute();
            }, amtCommandQuerySvc.handleProtocolError);
        }

        //Roles Tab
        vm.RolesGrid = {};

        vm.userRolesCriteria = {
            filterValues: {
                personId: $state.params.personid
            }
        };

        vm.addRole = function () {
            $state.go("app.admin.security.rolestab.addrole");
        };

        vm.filterUserRoles = function () {
            vm.RolesGrid.refresh();
        };

        $scope.refreshUserRoles = function () {
            vm.RolesGrid.refresh();
        };

        vm.copyRoles = function () {
            $state.go("app.admin.security.rolestab.copyrolesfromuser", {
                targetpersonid: $state.params.personid
            }, { location: false });
        };

        $scope.removerole = function (dataItem) {
            var item = {
                personRoleId: dataItem.personRoleId,
                equipmentTypeId: dataItem.componentTypeId
            };
            amtCommandQuerySvc.deleteItem(vm.apiUrls.deleteUserRole, item)
                .then(function (response) {                
                        notifySvc.show(xlatSvc.xlat("userSecurity.userRoleRemovedSuccess"), enums.notification.success);
                        vm.RolesGrid.refresh();                    
                }, amtCommandQuerySvc.handleProtocolError);
        };

        $scope.editRole = function (item) {
            WindowFactory.openItem({
                component: 'edit-role-popup',
				caption: vm.username + " - " + xlatSvc.xlat("userSecurity.editRoleTitle"),
				initParams: {
					item: item, 
					personname: vm.username
				},
				width: 700,
				onDataChangeHandler: vm.filterUserRoles
			});
        };

        vm.addRole = function () {
            WindowFactory.openItem({
                component: 'add-role-popup',
                caption: vm.username + " - " + xlatSvc.xlat("userSecurity.addRoleLabel"),
                initParams: {
                    personname: vm.username,
					personId: vm.personId
                },
                width: 850,
                onDataChangeHandler: vm.filterUserRoles
            });
        };

        //Copy Roles
        vm.copyUserRoles = function () {
            WindowFactory.openItem({
                component: 'copy-roles-from-user',
                caption: xlatSvc.xlat('userSecurity.copyRolesFromUser') + ' - ' + vm.username,
                initParams: {
             		personId: vm.personId
                },
                width: 650,
                onDataChangeHandler: vm.filterUserRoles
            });
        };

        //Subscriptions Tab
        vm.subscriptionsGrid = {};

        vm.userSubscriptionsCriteria = {
            filterValues: {
                personId: $state.params.personid
            }
        };

        $scope.unsubscribeFromAlert = function (dataItem) {
            var cmd = {
                alertConfigurationPersonId: dataItem.id
            };

            confirmSvc.confirmMessage("common.editPanelConfirmation_title","common.unsubscribeConfirmation", dataItem.alertConfiguration)
                .then(function () {
                    amtCommandQuerySvc.deleteItem(vm.apiUrls.deleteAlertSubscription, cmd).then(function (response) {                       
                        if (response.status === enums.httpUpdateStatus.fail) {
                            console.error(response.errors[0].message);
                            notifySvc.error(response.errors[0].message);
                            return;
                        }
                        notifySvc.success(xlatSvc.xlat("common.unsubscribedSuccessfully"));
                        vm.subscriptionsGrid.refresh();                        
                    }, amtCommandQuerySvc.handleProtocolError);
                });
        };

        // Settings Tab
        function updateSettings() {

            var promise;
            if (vm.isNewUser) {
                promise = amtCommandQuerySvc.post(vm.apiUrls.createNewUser, vm.user);
            } else {
                promise = amtCommandQuerySvc.put(vm.apiUrls.updateUserDetails, vm.user);
            }

            promise.then(function (response) {
                if (response.status === enums.httpUpdateStatus.fail) {
                    console.error(response.errors[0].message);
                    notifySvc.error(response.errors[0].message);
                    return;
                }
                notifySvc.success(xlatSvc.xlat("userSecurity.saveSuccessful"));                
            }, amtCommandQuerySvc.handleProtocolError);
        }

        init();
    }]);
})();
