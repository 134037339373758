import { Injectable } from '@angular/core';
import { UserPosition } from '../Interface/SiteDashboard.interface';
import { ReorderLocationHelper } from './ReorderLocation.helper';
@Injectable({
    providedIn: 'root'
})

export class ReorderHelper {

    constructor(private reorderLocationHelper: ReorderLocationHelper) {
    }

    // Some of the Reorder Event value return Double
    // change double to int;
    getIntValue(value: number) {
        if (value === 0) {
            return 1;
        } else if (value <= 1) {
            return 1;
        } else if (value <= 2) {
            return 2;
        } else if (value <= 3) {
            return 3;
        } else if (value <= 4) {
            return 4;
        } else if (value <= 5) {
            return 5;
        } else if (value <= 6) {
            return 6;
        } else if (value <= 7) {
            return 7;
        } else if (value <= 8) {
            return 8;
        } else if (value <= 9) {
            return 9;
        } else if (value <= 10) {
            return 10;
        } else if (value <= 11) {
            return 11;
        } else if (value <= 12) {
            return 12;
        }
    }

    //Check if the maxCol(siteDashboard.tileSetting) is over the new reference list
    checkMaxColOverNewRef(newRef: any, tileSetting: any) {
        let maxCol = tileSetting.col;
        let isMax = false;

        newRef.forEach(ref => {
            if (ref.col > maxCol) {
                isMax = true;
            }
        });
        return isMax;
    }

    //Check if the new space has not have a widget(s)
    checkEmpty(widget: UserPosition[]) {
        let isEmpty = false;
        if (widget.length === 0) {
            isEmpty = true;
        }
        return isEmpty;
    }

    //Check if the new Location has more then one wiget
    checkMultipleWidgets(widgets: UserPosition[]) {
        let isMultiple = false;
        if (widgets.length > 1) {
            isMultiple = true;
        }
        return isMultiple;
    }

    //check if newReference is already existed in the positionList
    checkIncluded(selectedDashboardId: string, selectedWidget: UserPosition, newReference: any, positionList: UserPosition[]) {
        let isInclude = false;

        positionList.forEach(widget => {
            if (selectedDashboardId === widget.siteDashboardId) {
                widget.reference.forEach(ref => {
                    newReference.forEach(newRef => {
                        if (ref.col === newRef.col && ref.row === newRef.row) {
                            if (selectedWidget.siteDashboardWidgetId === widget.siteDashboardWidgetId) {
                                isInclude = true;
                            }
                        }
                    });
                });
            }
        });
        return isInclude;
    }

    //check if the newReference is over used.
    checkOverlapping(selectedDashboardId: any, positionList: any, newReference: any) {
        let isOverlapping = false;

        positionList.forEach(widget => {
            if (selectedDashboardId === widget.siteDashboardId) {
                widget.reference.forEach(ref => {
                    newReference.forEach(newRef => {
                        if (newRef.col === ref.col && newRef.row === ref.row) {
                            isOverlapping = true;
                        }
                    });
                });
            }
        });
        return isOverlapping;
    }

    //find a widget location by using selected col and row
    //Using selectedDashboardId to prevent detect the other dashboard widget(s)
    findWidgetByLocation(selectedDashboardId: string, positionList: UserPosition[], selectedCol: number, selectedRow: number) {
        let selectedWidget: UserPosition;
        positionList.forEach(widget => {
            if (widget.siteDashboardId === selectedDashboardId) {
                widget.reference.forEach(ref => {
                    if (ref.col === selectedCol && ref.row === selectedRow) {
                        selectedWidget = widget;
                    }
                });
            }
        });
        return selectedWidget;
    }

    //get widget size from widget
    getWidgetSize(widget: UserPosition) {
        return widget.colSpan * widget.rowSpan;
    }

    //create new widget reference by using widget
    getNewWidgetReference(widget: UserPosition, newCol: number, newRow: number) {
        let reference = [{ row: newRow, col: newCol }];

        if (widget.rowSpan === 2 && widget.colSpan === 2) {
            let overlapRight = { row: newRow, col: newCol + 1 };
            let overlapBelow = { row: newRow + 1, col: newCol };
            let overlapAcute = { row: newRow + 1, col: newCol + 1 };
            reference.push(overlapBelow, overlapRight, overlapAcute);
        } else if (widget.rowSpan === 1 && widget.colSpan === 2) {
            let overlapRight = { row: newRow, col: newCol + 1 };
            reference.push(overlapRight);
        } else if (widget.rowSpan === 2 && widget.colSpan === 1) {
            let overlapBelow = { row: newRow + 1, col: newCol };
            reference.push(overlapBelow);
        }
        return reference;
    }

    //create new widget reference by using widget by SPAN
    getNewWidgetReferenceBySpan(colSpan: number, rowSpan: number, newCol: number, newRow: number) {
        let reference = [{ row: newRow, col: newCol }];

        if (rowSpan === 2 && colSpan === 2) {
            let overlapRight = { row: newRow, col: newCol + 1 };
            let overlapBelow = { row: newRow + 1, col: newCol };
            let overlapAcute = { row: newRow + 1, col: newCol + 1 };
            reference.push(overlapBelow, overlapRight, overlapAcute);
        } else if (rowSpan === 1 && colSpan === 2) {
            let overlapRight = { row: newRow, col: newCol + 1 };
            reference.push(overlapRight);
        } else if (rowSpan === 2 && colSpan === 1) {
            let overlapBelow = { row: newRow + 1, col: newCol };
            reference.push(overlapBelow);
        }
        return reference;
    }

    //delete target Widget from position List
    deletedWidgets(positionList: UserPosition[], targetWidgets: UserPosition[]) {
        let newList: UserPosition[] = [];
        positionList.forEach(item => {
            newList.push(item);
        });

        targetWidgets.forEach(target => {
            newList.forEach((item, index) => {
                if (target.siteDashboardWidgetId === item.siteDashboardWidgetId) {
                    newList.splice(index, 1);
                }
            });
        });

        return newList;
    }

    //delete the selected widget inside of user position list
    deletedWidgetsWithSelectedWidget(positionList: UserPosition[], targetWidgets: UserPosition[], selectedWidget: UserPosition) {
        let newList: UserPosition[] = [];
        positionList.forEach(item => {
            newList.push(item);
        });

        targetWidgets.forEach(target => {
            newList.forEach((item, index) => {
                if (target.siteDashboardWidgetId === item.siteDashboardWidgetId) {
                    newList.splice(index, 1);
                }
            });
        });

        newList.forEach((newWidget, index) => {
            if (newWidget.siteDashboardWidgetId === selectedWidget.siteDashboardWidgetId) {
                newList.splice(index, 1);
            }
        });

        return newList;
    }

    deleteSelectedWidget(targetList: UserPosition[], selectedWidget: UserPosition) {
        let deletedSelectedList: UserPosition[] = [];
        targetList.forEach(widget => {
            deletedSelectedList.push(widget);
        });

        deletedSelectedList.forEach((widget, index) => {
            if (selectedWidget.siteDashboardWidgetId === widget.siteDashboardWidgetId) {
                deletedSelectedList.splice(index, 1);
            }
        });

        return deletedSelectedList
    }

    //add widget in user positon list
    addSelectedWidget(positionList: UserPosition[], selectedWidget: UserPosition, newRef: any) {
        let newPositionList = positionList;
        let widget = selectedWidget;
        let newRow = newRef[0].row;
        let newCol = newRef[0].col;
        let reference = newRef;

        widget.col = newCol;
        widget.row = newRow;
        widget.reference = reference;

        newPositionList.push(widget);

        return newPositionList;
    }

    //change the position of selected widget by reference
    changeSelectedWidgets(positionList: UserPosition[], selectedWidget: UserPosition, newRef: any) {
        let newPositionList = positionList;

        newPositionList.forEach(position => {
            if (selectedWidget.siteDashboardWidgetId === position.siteDashboardWidgetId) {
                position.col = newRef[0].col;
                position.row = newRef[0].row;
                position.reference = newRef;
            }
        });
        return newPositionList;
    }

    //Swap the position of target widget(s) from selected widget position.
    addSwitechedTargetWidgets(positionList: UserPosition[], targetWidgets: UserPosition[], oldRef: any) {
        let newPositonList = positionList;
        let targetWiget = this.reorderLocationHelper.widgetSortbySmallToBig(targetWidgets);
        let availableLocations = oldRef;

        targetWiget.forEach((target) => {
            let newLocation = availableLocations[0];
            let newLocationReference = this.getNewWidgetReference(target, newLocation.col, newLocation.row);
            target.col = newLocation.col;
            target.row = newLocation.row;
            target.reference = newLocationReference;
            availableLocations = this.reorderLocationHelper.deleteUsedReference(availableLocations, newLocationReference);
            newPositonList.push(target)

        });

        return newPositonList;
    }

    addNewLocationTargetWidgets(positionList: UserPosition[], targetWidgets: UserPosition[], availableList: any, tileSetting: any, selectedDashboardId: string) {
        let newPositionList = positionList;
        let targetList = targetWidgets;
        let availableLocations = availableList;

        targetList.forEach((target, index) => {
            let availableLocation = availableLocations[0];
            let col = availableLocation.col;
            let row = availableLocation.row;
            let newReference = this.getNewWidgetReference(target, col, row);
            let reachToMaxCol = this.checkMaxColOverNewRef(newReference, tileSetting);
            let isOverlapping = this.checkOverlapping(selectedDashboardId, newPositionList, newReference);

            while (reachToMaxCol || isOverlapping) {
                availableLocations = this.reorderLocationHelper.deleteUsedReference(availableLocations, newReference);
                availableLocation = availableLocations[0];
                col = availableLocation.col;
                row = availableLocation.row;
                newReference = this.getNewWidgetReference(target, col, row);
                reachToMaxCol = this.checkMaxColOverNewRef(newReference, tileSetting);
                isOverlapping = this.checkOverlapping(selectedDashboardId, newPositionList, newReference);
            }

            target.col = col;
            target.row = row;
            target.reference = newReference;
            newPositionList.push(target);
            availableLocations = this.reorderLocationHelper.deleteUsedReference(availableLocations, newReference);
        });
        return newPositionList;
    }


}


