/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var column_base_1 = require("./column-base");
var cell_template_directive_1 = require("../rendering/cell-template.directive");
var id_service_1 = require("../common/id.service");
/**
 * Represents the command columns of the Grid. You have to define the content of the
 * column inside an `<ng-template>` tag. The template context is set to the current
 * data item. For more information and examples on using the passed fields
 * and the command directives, refer to the article on
 * [editing the Grid in Angular Reactive Forms]({% slug editing_reactive_forms_grid %}).
 *
 * The following additional fields are passed:
 * - `columnIndex`&mdash;The current column index.
 * - `rowIndex`&mdash;The current data row index. If inside a new item row, `rowIndex`is `-1`.
 * - `dataItem`&mdash;The current data item.
 * - `column`&mdash;The current column instance.
 * - `isNew`&mdash;The state of the current item.
 *
 * Usually, the template contains CRUD command directives such as:
 * - [EditCommandDirective]({% slug api_grid_editcommanddirective %})
 * - [RemoveCommandDirective]({% slug api_grid_removecommanddirective %})
 * - [CancelCommandDirective]({% slug api_grid_cancelcommanddirective %})
 * - [SaveCommandDirective]({% slug api_grid_savecommanddirective %})
 *
 * @example
 * ```ts-preview
 *
 * _@Component({
 *    selector: 'my-app',
 *    template: `
 *        <kendo-grid [data]="gridData">
 *          <kendo-grid-column field="ProductID" title="Product ID" width="120">
 *          </kendo-grid-column>
 *          <kendo-grid-column field="ProductName" title="Product Name">
 *          </kendo-grid-column>
 *          <kendo-grid-column field="UnitPrice" title="Unit Price" width="230">
 *          </kendo-grid-column>
 *          <kendo-grid-command-column title="command" width="220">
 *               <ng-template kendoGridCellTemplate>
 *                   <button kendoGridEditCommand class="k-primary">Edit</button>
 *                   <button kendoGridRemoveCommand>Remove</button>
 *               </ng-template>
 *           </kendo-grid-command-column>
 *        </kendo-grid>
 *    `
 * })
 *
 * class AppComponent {
 *    public gridData: any[];
 *
 *    constructor() {
 *        this.gridData = products;
 *    }
 * }
 *
 * const products = [{
 *    "ProductID": 1,
 *    "ProductName": "Chai",
 *    "UnitPrice": 18.0000,
 *    "Discontinued": true
 *  }, {
 *    "ProductID": 2,
 *    "ProductName": "Chang",
 *    "UnitPrice": 19.0000,
 *    "Discontinued": false
 *  }
 * ];
 *
 * ```
 */
var CommandColumnComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CommandColumnComponent, _super);
    function CommandColumnComponent(parent, idService) {
        var _this = _super.call(this, parent, idService) || this;
        _this.parent = parent;
        return _this;
    }
    CommandColumnComponent_1 = CommandColumnComponent;
    Object.defineProperty(CommandColumnComponent.prototype, "templateRef", {
        get: function () {
            return this.template ? this.template.templateRef : undefined;
        },
        enumerable: true,
        configurable: true
    });
    var CommandColumnComponent_1;
    tslib_1.__decorate([
        core_1.ContentChild(cell_template_directive_1.CellTemplateDirective, { static: false }),
        tslib_1.__metadata("design:type", cell_template_directive_1.CellTemplateDirective)
    ], CommandColumnComponent.prototype, "template", void 0);
    CommandColumnComponent = CommandColumnComponent_1 = tslib_1.__decorate([
        core_1.Component({
            providers: [
                {
                    provide: column_base_1.ColumnBase,
                    useExisting: core_1.forwardRef(function () { return CommandColumnComponent_1; })
                }
            ],
            selector: 'kendo-grid-command-column',
            template: ""
        }),
        tslib_1.__param(0, core_1.SkipSelf()), tslib_1.__param(0, core_1.Host()), tslib_1.__param(0, core_1.Optional()), tslib_1.__param(1, core_1.Optional()),
        tslib_1.__metadata("design:paramtypes", [column_base_1.ColumnBase, id_service_1.IdService])
    ], CommandColumnComponent);
    return CommandColumnComponent;
}(column_base_1.ColumnBase));
exports.CommandColumnComponent = CommandColumnComponent;
