/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var row_editing_directive_base_1 = require("./row-editing-directive-base");
var grid_component_1 = require("../grid.component");
var local_data_changes_service_1 = require("../editing/local-data-changes.service");
var utils_1 = require("./utils");
/**
 * A directive which encapsulates the editing operations of the Grid when using the
 * Reactive Forms ([see example]({% slug editing_directives_grid %}#toc-the-reactive-directive)).
 */
var ReactiveEditingDirective = /** @class */ (function (_super) {
    tslib_1.__extends(ReactiveEditingDirective, _super);
    function ReactiveEditingDirective(grid, localDataChangesService) {
        var _this = _super.call(this, grid, localDataChangesService) || this;
        _this.grid = grid;
        _this.localDataChangesService = localDataChangesService;
        return _this;
    }
    ReactiveEditingDirective.prototype.createModel = function (args) {
        return this.createFormGroup(args);
    };
    ReactiveEditingDirective.prototype.saveModel = function (_a) {
        var dataItem = _a.dataItem, formGroup = _a.formGroup, isNew = _a.isNew;
        if (!formGroup.dirty && !isNew) {
            return;
        }
        if (formGroup.valid) {
            this.editService.assignValues(dataItem, formGroup.value);
            return dataItem;
        }
        utils_1.markAllAsTouched(formGroup);
    };
    tslib_1.__decorate([
        core_1.Input('kendoGridReactiveEditing'),
        tslib_1.__metadata("design:type", Function)
    ], ReactiveEditingDirective.prototype, "createFormGroup", void 0);
    ReactiveEditingDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoGridReactiveEditing]'
        }),
        tslib_1.__metadata("design:paramtypes", [grid_component_1.GridComponent, local_data_changes_service_1.LocalDataChangesService])
    ], ReactiveEditingDirective);
    return ReactiveEditingDirective;
}(row_editing_directive_base_1.RowEditingDirectiveBase));
exports.ReactiveEditingDirective = ReactiveEditingDirective;
