/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_licensing_1 = require("@progress/kendo-licensing");
var utils_1 = require("../common/utils");
var package_metadata_1 = require("../package-metadata");
/**
 * Represents the directive that renders the [Kendo UI RadioButton]({% slug overview_checkbox %}) input component.
 * The directive is placed on input type="radio" elements.
 *
 * @example
 * ```ts-no-run
 * <input type="radio" kendoRadioButton />
 * ```
 */
var RadioButtonDirective = /** @class */ (function () {
    function RadioButtonDirective(renderer, hostElement) {
        this.renderer = renderer;
        this.hostElement = hostElement;
        this.kendoClass = true;
        this._size = 'medium';
        kendo_licensing_1.validatePackage(package_metadata_1.packageMetadata);
    }
    Object.defineProperty(RadioButtonDirective.prototype, "size", {
        get: function () {
            return this._size;
        },
        /**
         * The size property specifies the width and height of the RadioButton
         * ([see example]({% slug appearance_radiobuttondirective %}#toc-size)).
         * The possible values are:
         * * `'small'`
         * * `'medium'` (default)
         * * `'large'`
         * * `null`
         */
        set: function (size) {
            this.handleClasses(size, 'size');
            this._size = size;
        },
        enumerable: true,
        configurable: true
    });
    RadioButtonDirective.prototype.ngAfterViewInit = function () {
        var _this = this;
        // kept in sync with other inputs for easier refactoring
        // to a common base class
        var stylingInputs = ['size'];
        stylingInputs.forEach(function (input) {
            _this.handleClasses(_this[input], input);
        });
    };
    RadioButtonDirective.prototype.handleClasses = function (value, input) {
        var elem = this.hostElement.nativeElement;
        var classes = utils_1.getStylingClasses('radio', input, this[input], value);
        if (classes.toRemove) {
            this.renderer.removeClass(elem, classes.toRemove);
        }
        if (classes.toAdd) {
            this.renderer.addClass(elem, classes.toAdd);
        }
    };
    tslib_1.__decorate([
        core_1.HostBinding('class.k-radio'),
        tslib_1.__metadata("design:type", Boolean)
    ], RadioButtonDirective.prototype, "kendoClass", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], RadioButtonDirective.prototype, "size", null);
    RadioButtonDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: 'input[kendoRadioButton]'
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.Renderer2, core_1.ElementRef])
    ], RadioButtonDirective);
    return RadioButtonDirective;
}());
exports.RadioButtonDirective = RadioButtonDirective;
