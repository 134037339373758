//import angular from 'angular';


angular.module("app").factory("endOfMonthUrls", function () {
    var apiPath = baseUrl + 'api/';
        var sitePath = 'site/';
        var endOfMonthController = 'endOfMonth/';

        return {                    
            getReportYears: apiPath + sitePath + endOfMonthController + 'getReportYears',
            search: apiPath + sitePath + endOfMonthController + 'search',
            searchExport: sitePath + endOfMonthController + 'searchExport',
            getEndOfMonth: sitePath + endOfMonthController + 'getEndOfMonth',
            getEndOfMonthTrainingReceived: apiPath + sitePath + endOfMonthController + 'getEndOfMonthTrainingReceived',
            getEndOfMonthContinuousImprovement: apiPath + sitePath + endOfMonthController + 'getEndOfMonthContinuousImprovement',
            listTrainingTypes: apiPath + sitePath + endOfMonthController + 'listTrainingTypes',
            listTrainingForms: apiPath + sitePath + endOfMonthController + 'listTrainingForms',
            listContinuousImprovementStatuses: apiPath + sitePath + endOfMonthController + 'listContinuousImprovementStatuses',
            getContinuousImprovementAttachments: sitePath + endOfMonthController + 'getContinuousImprovementAttachments',
            delete: apiPath + sitePath + endOfMonthController + 'delete'
        };
    });
