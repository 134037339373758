//import angular from 'angular';
import tmplVehicleSearch from "./vehicleSearch.html";
import tmplMaintenanceSearch from "./maintenance/search.html";


const moduleName = "app.vehicle";
export default moduleName;

angular.module(moduleName, [])
        .config([
            "$stateProvider",
            function ($stateProvider) {
                $stateProvider
                    .state("app.vehicle.search", {
                        url: "/search",
                        template: tmplVehicleSearch,
                        controller: "vehicleSearchCtrl",
                        controllerAs: "vm"
                    })
                    .state("app.vehicle.receive", {
                        url: "/receive",
                        component: 'vehicleReceive'
                    })
                    .state('app.vehicle.fieldsurvey', {
                        url: '/fieldsurvey',
                        params: {
                            id: '',
                            serialNumber: '',
                            search: ''
                        },
                        resolve: {
                            initParams: ['$transition$', $transition$ => ({
                                id: $transition$.params().id,
                                serialNumber: $transition$.params().serialNumber,
                                search: $transition$.params().search
                            })]
                        },
                        component: 'fieldSurveySearch'
                    })
                    .state("app.vehicle.maintenance", {
                        url: "/maintenance",
                        params: {
                            search: ""
                        },
                        template: tmplMaintenanceSearch,
                        controller: "maintenanceSearchCtrl",
                        controllerAs: "vm"
                    });
            }
        ]);
