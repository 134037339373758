//import angular from 'angular';


    //copied from  https://stackoverflow.com/questions/14995884/select-text-on-input-focus
    angular.module('app.directives')
        .directive('selectOnClick', ['$window', function ($window) {
            return {
                restrict: 'A',
                link: function (scope, element: any, attrs) {
                    element.on('focus', function () {
                        if (!$window.getSelection().toString()) {
                            // Required for mobile Safari
                            this.setSelectionRange(0, this.value.length)
                        }
                    });
                }
            };
        }]);
