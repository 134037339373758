//import angular from 'angular';

angular.module("app").component('alertWindow', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        // in-lining this, so that if there is any issue mobile side we guarantee that the template is loaded.
        template: '<oc-form name="vm.form"><div class="{{$ctrl.initParams.textAlignClass}}"><div ng-bind-html="$ctrl.initParams.text"></div></div></oc-form>',
        controller: ['$scope', 'WindowFactory',
            function ($scope: IVmScope, WindowFactory: IWindowFactory) {
        		var vm = this;
        		
                this.$onInit = function () {
                    vm.close = function (button) {
                        WindowFactory.closeWindow(vm.wnd);
                        vm.wnd.onDataChanged(button);
                    };
                    vm.wnd.onClose = vm.close;

					if (vm.buttons.length > 0) {
					    vm.buttons.forEach(function(button) {
					        vm.buttonMethods[button.click] = vm.close;
					    });
                    }
				};
        	}
		]
    });
