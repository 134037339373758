import { Injectable } from '@angular/core';
import { WidgetConstant } from '../widgetHelper/WidgetConstant';
import { LiveForecastTyreApplyFilter, LiveForecastTyreElement, LiveForecastTyreFilterOption, LiveForecastTyreGroup, LiveForecastGroupValueValidation, LiveForecastTyreResponse, LiveForecastTyreSelectedFilter, LiveForecastTyreSelectedFilterItem, LiveForecastSelectedGroupCount, LiveForecastSelectionFilterOption, LiveForecastTyreWidget } from '../Interface/Widget.interface';
import { FilterConstant } from '../Interface/WidgetConstant.interface';

@Injectable({
    providedIn: 'root'
})

export class LiveForecastTyreWidgetHelper {
    constructor(private constant: WidgetConstant) {
    }

    /**If Change Type in Dialog has triggered, return the selection of group*/
    public getSelectedTyreGroup(selectedDisplay: string, result: LiveForecastTyreResponse) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();

        if (selectedDisplay === filterConstant.totalTyreForecast) {
            return result.allTyres;
        }
        if (selectedDisplay === filterConstant.endLifeTyre) {
            return result.endLifeTyres;
        }
        if (selectedDisplay === filterConstant.newTyreChangeOut) {
            return result.newChangeTyres;
        }
    }


    /**If Forecast Period in Dialog has triggered, return the selection of element*/
    public getSelectedTyre(selectedDisplay: string, group: LiveForecastTyreGroup) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();

        if (selectedDisplay === filterConstant.overDue) {
            return group.overDue;
        }
        if (selectedDisplay === filterConstant.next2Weeks) {
            return group.next2Weeks;
        }
        if (selectedDisplay === filterConstant.next2To4Weeks) {
            return group.next2To4Weeks;
        }
        if (selectedDisplay === filterConstant.next4To8Weeks) {
            return group.next4To8Weeks;
        }
        if (selectedDisplay === filterConstant.next8To12Weeks) {
            return group.next8To12Weeks;
        }
    }


    /**If Forecast Period in Dialog has triggered, return the default filterOption based on the period*/
    public getSelectedFilterOption(selectedDuration: string, group: LiveForecastTyreGroup) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();

        if (selectedDuration === filterConstant.overDue) {

            let selectedFilterOption: LiveForecastTyreSelectedFilterItem = {
                tyreSizeList: group.filterOption.tyreSizeList.overDue,
                tyreSpecificationList: group.filterOption.tyreSpecificationList.overDue,
                vehicleSerialNumberList: group.filterOption.vehicleSerialNumberList.overDue,
                vehicleSpecificationList: group.filterOption.vehicleSpecifiationList.overDue,
                filter: this.setDefaultFilter()
            }

            return selectedFilterOption;
        }

        if (selectedDuration === filterConstant.next2Weeks) {

            let selectedFilterOption: LiveForecastTyreSelectedFilterItem = {
                tyreSizeList: group.filterOption.tyreSizeList.next2Weeks,
                tyreSpecificationList: group.filterOption.tyreSpecificationList.next2Weeks,
                vehicleSerialNumberList: group.filterOption.vehicleSerialNumberList.next2Weeks,
                vehicleSpecificationList: group.filterOption.vehicleSpecifiationList.next2Weeks,
                filter: this.setDefaultFilter()
            }

            return selectedFilterOption;
        }

        if (selectedDuration === filterConstant.next2To4Weeks) {

            let selectedFilterOption: LiveForecastTyreSelectedFilterItem = {
                tyreSizeList: group.filterOption.tyreSizeList.next2To4Weeks,
                tyreSpecificationList: group.filterOption.tyreSpecificationList.next2To4Weeks,
                vehicleSerialNumberList: group.filterOption.vehicleSerialNumberList.next2To4Weeks,
                vehicleSpecificationList: group.filterOption.vehicleSpecifiationList.next2To4Weeks,
                filter: this.setDefaultFilter()
            }

            return selectedFilterOption;
        }

        if (selectedDuration === filterConstant.next4To8Weeks) {

            let selectedFilterOption: LiveForecastTyreSelectedFilterItem = {
                tyreSizeList: group.filterOption.tyreSizeList.next4To8Weeks,
                tyreSpecificationList: group.filterOption.tyreSpecificationList.next4To8Weeks,
                vehicleSerialNumberList: group.filterOption.vehicleSerialNumberList.next4To8Weeks,
                vehicleSpecificationList: group.filterOption.vehicleSpecifiationList.next4To8Weeks,
                filter: this.setDefaultFilter()
            }

            return selectedFilterOption;
        }
        if (selectedDuration === filterConstant.next8To12Weeks) {

            let selectedFilterOption: LiveForecastTyreSelectedFilterItem = {
                tyreSizeList: group.filterOption.tyreSizeList.next8To12Weeks,
                tyreSpecificationList: group.filterOption.tyreSpecificationList.next8To12Weeks,
                vehicleSerialNumberList: group.filterOption.vehicleSerialNumberList.next8To12Weeks,
                vehicleSpecificationList: group.filterOption.vehicleSpecifiationList.next8To12Weeks,
                filter: group.filterOption.filter
            }
            return selectedFilterOption;
        }
    }


    /**Return the default change type selection*/
    public getDefaultSelectionDropDownMenu() {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();

        return [filterConstant.totalTyreForecast, filterConstant.endLifeTyre, filterConstant.newTyreChangeOut];
    }


    /**Set up the default forecast period by checking the length of elements*/
    public getDefaultDurationDropDownMenu(validate: LiveForecastGroupValueValidation) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        let defaultDurationList = [];

        if (validate.hasOverDue) {
            defaultDurationList.push(filterConstant.overDue);
        }
        if (validate.hasNext2Weeks) {
            defaultDurationList.push(filterConstant.next2Weeks);
        }
        if (validate.hasNext2To4Weeks) {
            defaultDurationList.push(filterConstant.next2To4Weeks);
        }

        if (validate.hasNext4To8Weeks) {
            defaultDurationList.push(filterConstant.next4To8Weeks);
        }

        if (validate.hasNext8To12Weeks) {
            defaultDurationList.push(filterConstant.next8To12Weeks);
        }

        return defaultDurationList;
    }


    /**Priority is return the overDue however, if there no overDue, return first available item*/
    public getDefaultDurationSelected(list: Array<string>) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();

        if (list.includes(filterConstant.overDue)) {
            return filterConstant.overDue;
        } else {
            return list.length > 0 ? list[0] : '';
        }
    }


    /**Return default selected change type*/
    public getDefaultSelected() {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();

        return filterConstant.newTyreChangeOut;
    }


    /**Validate the tyre group has elements*/
    public validateLiveForecastTyreElementLength(tyre: LiveForecastTyreGroup) {
        let validate: LiveForecastGroupValueValidation = {
            hasOverDue: tyre.overDue.length > 0,
            hasNext2Weeks: tyre.next2Weeks.length > 0,
            hasNext2To4Weeks: tyre.next2To4Weeks.length > 0,
            hasNext4To8Weeks: tyre.next4To8Weeks.length > 0,
            hasNext8To12Weeks: tyre.next8To12Weeks.length > 0
        };

        return validate
    }


    /**Return total count of overdue, next2weeks, next4To8Weeks, and next8To12Weeks*/
    public setLiveForecastGroupCount(tyre: LiveForecastTyreGroup) {
        let validate = this.validateLiveForecastTyreElementLength(tyre);
        let count: LiveForecastSelectedGroupCount = {
            overDue: validate.hasOverDue ? tyre.overDue.length : 0,
            next2Weeks: validate.hasNext2Weeks ? tyre.next2Weeks.length : 0,
            next2To4Weeks: validate.hasNext2To4Weeks ? tyre.next2To4Weeks.length : 0,
            next4To8Weeks: validate.hasNext4To8Weeks ? tyre.next4To8Weeks.length : 0,
            next8To12Weeks: validate.hasNext8To12Weeks ? tyre.next8To12Weeks.length : 0
        }

        return count;
    }


    /**Validate selected vehicle size list and return it*/
    public setSelectedTyreSizeList(selectedList: Array<string>, selectionList: Array<string>) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        let lastIndex: number = selectedList.length - 1;
        let isEmptyList: boolean = selectedList.length === 0;
        let isMaximumItem: boolean = (selectionList.length - 1) === selectedList.length;
        let isFirstAllTyreSize: boolean = selectedList[0] === filterConstant.allTyreSize;
        let isLastAllTyreSize: boolean = selectedList[lastIndex] === filterConstant.allTyreSize;
        let defaultTyreSizeList: Array<string> = [filterConstant.allTyreSize];
        let removedAllTyreSizeList: Array<string> = selectedList.filter(size => { return size !== filterConstant.allTyreSize });

        return (isEmptyList || isLastAllTyreSize || isMaximumItem) ? defaultTyreSizeList : isFirstAllTyreSize ? removedAllTyreSizeList : selectedList;
    }


    /**Validate selected tyre specification list and return it*/
    public setSelectedTyreSpecificationList(selectedList: Array<string>, selectionList: Array<string>) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        let lastIndex: number = selectedList.length - 1;
        let isEmptyList: boolean = selectedList.length === 0;
        let isMaximumItem: boolean = (selectionList.length - 1) === selectedList.length;
        let isFirstAllTyreSpecification: boolean = selectedList[0] === filterConstant.allTyreSpecification;
        let isLastAllTyreSpecification: boolean = selectedList[lastIndex] === filterConstant.allTyreSpecification;
        let defaultTyreSpecificationList: Array<string> = [filterConstant.allTyreSpecification];
        let removedAllTyreSpecificationList: Array<string> = selectedList.filter(size => { return size !== filterConstant.allTyreSpecification });

        return (isEmptyList || isLastAllTyreSpecification || isMaximumItem) ? defaultTyreSpecificationList : isFirstAllTyreSpecification ? removedAllTyreSpecificationList : selectedList;
    }



    /**Validate selected vehicle serial number list and return it*/
    public setSelectedVehicleSerialNumberList(selectedList: Array<string>, selectionList: Array<string>) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        let isEmptyList: boolean = selectedList.length === 0;
        let isMaximumItem: boolean = (selectionList.length - 1) === selectedList.length;
        let isFirstAllSerialNumberItem: boolean = selectedList[0] === filterConstant.allVehicleSerialNumber;
        let isLastAllSerialNumberItem: boolean = selectedList[selectedList.length - 1] === filterConstant.allVehicleSerialNumber;
        let defaultList = [filterConstant.allVehicleSerialNumber];
        let removedAllSerialNumberItemList = selectedList.filter(size => { return size !== filterConstant.allVehicleSerialNumber });
        let isDefault = isEmptyList || isMaximumItem || isLastAllSerialNumberItem;

        return isDefault ? defaultList : isFirstAllSerialNumberItem ? removedAllSerialNumberItemList : selectedList;
    }


    /**Validate selected vehicle specification list and return it*/
    public setSelectedVehicleSpecificationList(selectedList: Array<string>, selectionList: Array<string>) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        let lastIndex: number = selectedList.length - 1;
        let isEmptyList: boolean = selectedList.length === 0;
        let isMaximumItem: boolean = (selectionList.length - 1) === selectedList.length;
        let isFirstAllVehicleSpecification: boolean = selectedList[0] === filterConstant.allVehicleSpecification;
        let isLastAllVehicleSpecification: boolean = selectedList[lastIndex] === filterConstant.allVehicleSpecification;
        let defaultVehicleSpecificationList: Array<string> = [filterConstant.allVehicleSpecification];
        let removedAllVehicleSpecificationList: Array<string> = selectedList.filter(size => { return size !== filterConstant.allVehicleSpecification });

        return (isEmptyList || isLastAllVehicleSpecification || isMaximumItem) ? defaultVehicleSpecificationList : isFirstAllVehicleSpecification ? removedAllVehicleSpecificationList : selectedList;
    }


    /**Set the apply trigger filter*/
    public setApplyFilter(filterOption: LiveForecastTyreSelectedFilterItem) {
        let validateFilter = this.validateApplySelectedFilter(filterOption);

        let filter: LiveForecastTyreApplyFilter = {
            tyreSizeList:
                validateFilter.isAllTyreSize ? filterOption.tyreSizeList : filterOption.filter.tyreSize,
            tyreSpecificationList:
                validateFilter.isAllTyreSpecification ? filterOption.tyreSpecificationList : filterOption.filter.tyreSpecification,
            vehicleSerialNumberList:
                validateFilter.isAllVehicleSerialNumber ? filterOption.vehicleSerialNumberList : filterOption.filter.vehicleSerialNumber,
            vehicleSpecificationList:
                validateFilter.isAllVehicleSpecification ? filterOption.vehicleSpecificationList : filterOption.filter.vehicleSpecification
        }
        return filter;
    }


    /**Validate the selected filter contains default filter*/
    private validateApplySelectedFilter(filterOption: LiveForecastTyreSelectedFilterItem) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();

        let validatedAppliedFilter = {
            isAllTyreSize: filterOption.filter.tyreSize.includes(filterConstant.allTyreSize),
            isAllTyreSpecification: filterOption.filter.tyreSpecification.includes(filterConstant.allTyreSpecification),
            isAllVehicleSerialNumber: filterOption.filter.vehicleSerialNumber.includes(filterConstant.allVehicleSerialNumber),
            isAllVehicleSpecification: filterOption.filter.vehicleSpecification.includes(filterConstant.allVehicleSpecification)
        };
        return validatedAppliedFilter;
    }


    /**Set the default filter option*/
    public setFilterOption(tyre: LiveForecastTyreGroup) {
        let validate = this.validateLiveForecastTyreElementLength(tyre);
        let filterOption: LiveForecastTyreFilterOption = {
            vehicleSerialNumberList: this.setVehicleSerialNumberFilterOption(tyre, validate),
            vehicleSpecifiationList: this.setVehicleSpecificationFilterOption(tyre, validate),
            tyreSizeList: this.setTyreSizeFilterOption(tyre, validate),
            tyreSpecificationList: this.setTyreSpecificationFilterOption(tyre, validate),
            filter: this.setDefaultFilter(),
            validate: validate
        }
        return filterOption;
    }


    /**Set the default selected filter*/
    private setDefaultFilter() {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        let selected: LiveForecastTyreSelectedFilter = {
            vehicleSerialNumber: [filterConstant.allVehicleSerialNumber],
            vehicleSpecification: [filterConstant.allVehicleSpecification],
            tyreSize: [filterConstant.allTyreSize],
            tyreSpecification: [filterConstant.allTyreSpecification]
        };

        return selected
    }


    /**Set up the default vehicle serial number filter option*/
    private setVehicleSerialNumberFilterOption(tyre: LiveForecastTyreGroup, validate: LiveForecastGroupValueValidation) {
        let vehicleSerialNumbers: LiveForecastSelectionFilterOption = {
            overDue: validate.hasOverDue ? this.getVehicleSerialNumberList(tyre.overDue) : [],
            next2Weeks: validate.hasNext2Weeks ? this.getVehicleSerialNumberList(tyre.next2Weeks) : [],
            next2To4Weeks: validate.hasNext2To4Weeks ? this.getVehicleSerialNumberList(tyre.next2To4Weeks) : [],
            next4To8Weeks: validate.hasNext4To8Weeks ? this.getVehicleSerialNumberList(tyre.next4To8Weeks) : [],
            next8To12Weeks: validate.hasNext8To12Weeks ? this.getVehicleSerialNumberList(tyre.next8To12Weeks) : [],
        }
        return vehicleSerialNumbers;
    }


    /**Set up the default vehicle serial number filter option list*/
    private getVehicleSerialNumberList(tyreElement: Array<LiveForecastTyreElement>) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        let list = [];
        let defaultSelection = [filterConstant.allVehicleSerialNumber];

        //Add vehicle SerialNumber
        tyreElement.forEach(tyre => {
            if (!list.includes(tyre.fittedVehicle.serialNumber)) {
                list.push(tyre.fittedVehicle.serialNumber);
            }
        });
        list.sort();

        // Return always default selection is first
        return defaultSelection.concat(list);
    }


    /**Set up the default vehicle specification filter option*/
    private setVehicleSpecificationFilterOption(tyre: LiveForecastTyreGroup, validate: LiveForecastGroupValueValidation) {
        let vehicleSpecifiations: LiveForecastSelectionFilterOption = {
            overDue: validate.hasOverDue ? this.getVehicleSpecificationList(tyre.overDue) : [],
            next2Weeks: validate.hasNext2Weeks ? this.getVehicleSpecificationList(tyre.next2Weeks) : [],
            next2To4Weeks: validate.hasNext2To4Weeks ? this.getVehicleSpecificationList(tyre.next2To4Weeks) : [],
            next4To8Weeks: validate.hasNext4To8Weeks ? this.getVehicleSpecificationList(tyre.next4To8Weeks) : [],
            next8To12Weeks: validate.hasNext8To12Weeks ? this.getVehicleSpecificationList(tyre.next8To12Weeks) : [],
        }

        return vehicleSpecifiations
    }


    /**Set up the default vehicle specification filter option list*/
    private getVehicleSpecificationList(tyreElement: Array<LiveForecastTyreElement>) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        let list = [];
        let defaultSelection = [filterConstant.allVehicleSpecification];

        //Add vehicle Specification
        tyreElement.forEach(tyre => {
            if (!list.includes(tyre.fittedVehicle.specification)) {
                list.push(tyre.fittedVehicle.specification);
            }
        });
        list.sort();

        // Return always default selection is first
        return defaultSelection.concat(list);
    }



    /**Set up the default tyre size filter option*/
    private setTyreSizeFilterOption(tyre: LiveForecastTyreGroup, validate: LiveForecastGroupValueValidation) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        let tyreSizes: LiveForecastSelectionFilterOption = {
            overDue: validate.hasOverDue ? this.getTyreSizeList(tyre.overDue) : [],
            next2Weeks: validate.hasNext2Weeks ? this.getTyreSizeList(tyre.next2Weeks) : [],
            next2To4Weeks: validate.hasNext2To4Weeks ? this.getTyreSizeList(tyre.next2To4Weeks) : [],
            next4To8Weeks: validate.hasNext4To8Weeks ? this.getTyreSizeList(tyre.next4To8Weeks) : [],
            next8To12Weeks: validate.hasNext8To12Weeks ? this.getTyreSizeList(tyre.next8To12Weeks) : [],
        }
        return tyreSizes;
    }


    /**Set up the default tyre size filter option list*/
    private getTyreSizeList(tyreElement: Array<LiveForecastTyreElement>) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        let list = [];
        let defaultSelection = [filterConstant.allTyreSize];

        //Add Tye size
        tyreElement.forEach(tyre => {
            if (!list.includes(tyre.size)) {
                list.push(tyre.size);
            }
        });
        list.sort();

        // Return always default selection is first
        return defaultSelection.concat(list);
    }


    /**Set up the default tyre specification filter option*/
    private setTyreSpecificationFilterOption(tyre: LiveForecastTyreGroup, validate: LiveForecastGroupValueValidation) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        let tyreSpecifications: LiveForecastSelectionFilterOption = {
            overDue: validate.hasOverDue ? this.getTyreSpecificationList(tyre.overDue) : [],
            next2Weeks: validate.hasNext2Weeks ? this.getTyreSpecificationList(tyre.next2Weeks) : [],
            next2To4Weeks: validate.hasNext2To4Weeks ? this.getTyreSpecificationList(tyre.next2To4Weeks) : [],
            next4To8Weeks: validate.hasNext4To8Weeks ? this.getTyreSpecificationList(tyre.next4To8Weeks) : [],
            next8To12Weeks: validate.hasNext8To12Weeks ? this.getTyreSpecificationList(tyre.next8To12Weeks) : [],
        }

        return tyreSpecifications;
    }

    /**Set up the default tyre specification filter option List*/
    private getTyreSpecificationList(tyreElement: Array<LiveForecastTyreElement>) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        let list = [];
        let defaultSelection = [filterConstant.allTyreSpecification];

        //Add Tyre Specification
        tyreElement.forEach(tyre => {
            if (!list.includes(tyre.specification)) {
                list.push(tyre.specification);
            }
        });
        list.sort();
        // Return always default selection is first
        return defaultSelection.concat(list);
    }

}