/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
/**
 * @hidden
 */
var NodeChildrenService = /** @class */ (function () {
    function NodeChildrenService() {
        this.changes = new rxjs_1.Subject();
    }
    NodeChildrenService.prototype.childrenLoaded = function (item, children) {
        this.changes.next({ item: item, children: children });
    };
    NodeChildrenService = tslib_1.__decorate([
        core_1.Injectable()
    ], NodeChildrenService);
    return NodeChildrenService;
}());
exports.NodeChildrenService = NodeChildrenService;
