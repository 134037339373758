//import angular from 'angular';
import AdminUrls from '../adminUrls';
import HelperSvc from '../../../services/helperSvc';


    angular.module('app.admin')
        .controller('alertsTriggersCtrl', ['$scope', 'helperSvc', 'amtCommandQuerySvc', 'amtXlatSvc', 'adminUrls', 'confirmSvc', 'WindowFactory', 'exportSvc', 'notifySvc', 'ocSecuritySvc', 'errorReporter',
            function ($scope, helperSvc: HelperSvc, amtCommandQuerySvc, xlatSvc, adminUrls: AdminUrls, confirmSvc, WindowFactory: IWindowFactory, exportSvc, notifySvc, ocSecuritySvc, errorReporter) {
                var vm = this;

                // security
                vm.canDelete = ocSecuritySvc.isAuthorised('Security.Settings.AlertsNotifications.Triggers.Delete', 'readWrite');
                vm.canViewTriggers = ocSecuritySvc.isAuthorised('Security.Settings.AlertsNotifications.Triggers', 'readOnly');
                vm.canAddTriggers = ocSecuritySvc.isAuthorised('Security.Settings.AlertsNotifications.Triggers.Add', 'readWrite');
                vm.canViewHistory = ocSecuritySvc.isAuthorised('Security.Settings.AlertsNotifications.History', 'readOnly');

                vm.canSelectAlertType = vm.canViewHistory || vm.canViewTriggers;

                vm.apiUrls = adminUrls;

                vm.gridControl = {};
                vm.triggerGridControl = {};
                vm.historyGridControl = {};

                vm.selectedAlertType = null;

                vm.alertCriteria = {
                    filterValues: {
                        includeAlertsWithNoTriggers: true,
                        alertTypeName: null,
                        includeOnlyIfActionRequired: false,
                        includeOnlyIfAllowsTriggers: true,
                        pageSize: 20
                    }
                };

                vm.triggerCriteria = {
                    filterValues: {
                        alertTypeId: null,
                        triggerName: null,
                        pageSize: 20
                    }
                };

                vm.historyCriteria = {
                    filterValues: {
                        alertTypeId: null,
                        triggerName: null,
                        pageSize: 20
                    }
                };

                vm.refreshAlertsGrid = function (selectedItems) {
                    if (vm.gridControl !== undefined && vm.gridControl.refresh) {
                        vm.gridControl.refresh();
                        $scope.loadDetails(selectedItems);
                    }
                };

                vm.refreshTriggersGrid = function () {
                    if (vm.triggerGridControl !== undefined && vm.triggerGridControl.refresh) {
                        vm.triggerGridControl.refresh();
                    }
                };

                vm.refreshHistoryGrid = function () {
                    if (vm.historyGridControl !== undefined && vm.historyGridControl.refresh) {
                        vm.historyGridControl.refresh();
                    }
                };

                vm.export = function () {
                    if (vm.selectedAlertType === null) {
                        exportSvc.exportData(vm.apiUrls.exportAlertTypes, vm.alertCriteria, xlatSvc.xlat('notifications.alertTypesExportFilename'));
                    } else {
                        exportSvc.exportData(vm.apiUrls.exportAlertTriggers, vm.triggerCriteria, xlatSvc.xlat('notifications.alertTriggersExportFilename', vm.selectedAlertType.description));
                    }
                };

                vm.addTrigger = function () {
                    WindowFactory.openItem({
                        caption: xlatSvc.xlat('notifications.addTrigger'),
                        component: 'trigger-popup',
                        initParams: {
                            alertConfigurationId: null,
                            alertType: vm.selectedAlertType,
                            isEdit: false,
                            showCloseOnSave: false
                        },
                        canClose: false,
                        width: 800,
                        modal: true,
                        onDataChangeHandler: vm.refreshTriggersGrid
                    });
                };

                $scope.loadDetails = function (items) {
                    if (angular.isArray(items)) {
                        vm.selectedAlertType = items[0];

                        vm.historyCriteria.filterValues.alertTypeId = helperSvc.getKey(vm.selectedAlertType);
                        vm.historyCriteria.filterValues.triggerName = null;

                        vm.triggerCriteria.filterValues.alertTypeId = helperSvc.getKey(vm.selectedAlertType);
                        vm.triggerCriteria.filterValues.triggerName = null;

                    } else {
                        vm.selectedAlertType = null;
                        vm.triggerCriteria.filterValues.alertTypeId = null;
                        vm.historyCriteria.filterValues.alertTypeId = null;
                    }

                    vm.refreshTriggersGrid();
                    vm.refreshHistoryGrid();
                };

                $scope.modifyTrigger = function (trigger) {
                    WindowFactory.openItem({
                        caption: xlatSvc.xlat('notifications.editTrigger'),
                        component: 'trigger-popup',
                        initParams: {
                            alertConfigurationId: trigger.key,
                            alertType: vm.selectedAlertType,
                            isEdit: true,
                            showCloseOnSave: false
                        },
                        canClose: false,
                        width: 800,
                        modal: true,
                        onDataChangeHandler: vm.refreshTriggersGrid
                    });
                };

                $scope.deleteTrigger = function (item) {
                    confirmSvc.confirmMessage('notifications.confirmDeleteAlertTrigger_title', 'notifications.confirmDeleteAlertTrigger_text', item.name).then(function () {

                        vm.processing = true;

                        amtCommandQuerySvc.post(vm.apiUrls.deleteAlertTrigger, item).then(function (response) {

                            notifySvc.success(xlatSvc.xlat("notifications.deleteSuccess"));
                            vm.refreshTriggersGrid();
                            vm.refreshHistoryGrid();

                        }).catch(function (error) {
                            errorReporter.logError(error);
                        }).finally(function () {
                            vm.processing = false;
                        });
                    });
                };
            }]);
