<!--Main-->
<div class="widget-table-box">
    <div id="resp-table">
        <div id="resp-table-body">

            <!--Title-->
            <div class="resp-table-title">
                <div class="table-body-title-cell"><b>{{ui.module}}</b></div>
                <div class="table-body-title-cell" align="right"><b>{{ui.errors}}</b></div>
            </div>

            <!-- <a href="/#!/component/search" (click)="setSearchBy()"></a>  -->
            <!--Maintenance Sessions-->
            <a href="/#!/vehicle/maintenance" class="resp-table-row-button" (click)="setSearchErrorFilter()">
                <div class="table-body-cell">{{ui.maintenanceSession}}</div>
                <div class="table-body-cell" align="right"> <div [ngStyle]="{'color': getColorName(result.maintenanceSessionError) }">{{result.maintenanceSessionError}}</div></div>
            </a>

            <!--Field Surveys-->
            <a href="/#!/vehicle/fieldsurvey" class="resp-table-row-button" (click)="setSearchErrorFilter()">
                <div class="table-body-cell">{{ui.fieldSurveys}}</div>
                <div class="table-body-cell" align="right"><div [ngStyle]="{'color': getColorName(result.fieldSurveyError) }">{{result.fieldSurveyError}}</div></div>
            </a>

            <!--Stocktake-->
            <a href="#!/component/stocktake" (click)="setSearchErrorFilter()" class="resp-table-row-button">
                <div class="table-body-cell">{{ui.stockTake}}</div>
                <div class="table-body-cell" align="right"><div [ngStyle]="{'color': getColorName(result.stocktakesInError) }">{{result.stocktakesInError}}</div></div>
            </a>

            <!--Component Receive-->
            <a href="#!/component/receive" (click)="setSearchErrorFilter()"  class="resp-table-row-button">
                <div class="table-body-cell">{{ui.componentReceive}}</div>
                <div class="table-body-cell" align="right"><div [ngStyle]="{'color': getColorName(result.componentReceive)}">{{result.componentReceive}}</div></div>
            </a>

            <!--Incomplete End of Month-->
            <a href="#!/site/endofmonth" (click)="setSearchInCompletedFilter()" class="resp-table-row-button">
                <div class="table-body-cell">{{ui.incompletedEndOfMonth}}</div>
                <div class="table-body-cell" align="right" [ngStyle]="{'color': getColorName(result.incompletedEndOfMonth) }">{{result.incompletedEndOfMonth}}</div>
            </a>

        </div>
    </div>
</div>