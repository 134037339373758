/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var loading_template_directive_1 = require("../loading-template.directive");
/**
 * @hidden
 */
var LoadingComponent = /** @class */ (function () {
    function LoadingComponent(localization) {
        this.localization = localization;
        this.hostClass = true;
    }
    Object.defineProperty(LoadingComponent.prototype, "loadingText", {
        get: function () {
            return this.localization.get('loading');
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        core_1.HostBinding('class.k-loading-mask'),
        tslib_1.__metadata("design:type", Boolean)
    ], LoadingComponent.prototype, "hostClass", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", loading_template_directive_1.LoadingTemplateDirective)
    ], LoadingComponent.prototype, "loadingTemplate", void 0);
    LoadingComponent = tslib_1.__decorate([
        core_1.Component({
            selector: '[kendoGridLoading]',
            template: "\n        <ng-container *ngIf=\"!loadingTemplate\">\n            <span class=\"k-loading-text\">{{ loadingText }}</span>\n            <div class=\"k-loading-image\"></div>\n            <div class=\"k-loading-color\"></div>\n        </ng-container>\n        <ng-template *ngIf=\"loadingTemplate\" [ngTemplateOutlet]=\"loadingTemplate?.templateRef\">\n        </ng-template>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService])
    ], LoadingComponent);
    return LoadingComponent;
}());
exports.LoadingComponent = LoadingComponent;
