/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var avatar_module_1 = require("./avatar.module");
var card_module_1 = require("./card.module");
var drawer_module_1 = require("./drawer.module");
var expansionpanel_module_1 = require("./expansionpanel.module");
var panelbar_module_1 = require("./panelbar.module");
var splitter_module_1 = require("./splitter.module");
var stepper_module_1 = require("./stepper.module");
var tabstrip_module_1 = require("./tabstrip.module");
var tilelayout_module_1 = require("./tilelayout.module");
var stacklayout_module_1 = require("./stacklayout.module");
var gridlayout_module_1 = require("./gridlayout.module");
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the Layout components.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Layout module
 * import { LayoutModule } from '@progress/kendo-angular-layout';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, LayoutModule], // import Layout module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
var LayoutModule = /** @class */ (function () {
    function LayoutModule() {
    }
    LayoutModule = tslib_1.__decorate([
        core_1.NgModule({
            exports: [
                avatar_module_1.AvatarModule,
                card_module_1.CardModule,
                drawer_module_1.DrawerModule,
                panelbar_module_1.PanelBarModule,
                expansionpanel_module_1.ExpansionPanelModule,
                splitter_module_1.SplitterModule,
                stepper_module_1.StepperModule,
                tabstrip_module_1.TabStripModule,
                tilelayout_module_1.TileLayoutModule,
                stacklayout_module_1.StackLayoutModule,
                gridlayout_module_1.GridLayoutModule
            ]
        })
    ], LayoutModule);
    return LayoutModule;
}());
exports.LayoutModule = LayoutModule;
