/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var tabstrip_scroll_service_1 = require("./tabstrip-scroll.service");
var constants_1 = require("./constants");
/**
 * @hidden
 */
var TabStripScrollableButtonComponent = /** @class */ (function () {
    function TabStripScrollableButtonComponent(host, scrollService, renderer, ngZone) {
        var _this = this;
        this.host = host;
        this.scrollService = scrollService;
        this.renderer = renderer;
        this.ngZone = ngZone;
        this.btnClasses = true;
        this.prev = false;
        this._disabled = false;
        this.subs = new rxjs_1.Subscription();
        this.clickHandler = function (scrollEvent) {
            var tabStripScrollEvent = _this.scrollService.emitScrollEvent(scrollEvent);
            var isTabStripScrollEventPrevented = tabStripScrollEvent.isDefaultPrevented();
            if (isTabStripScrollEventPrevented) {
                return;
            }
            var buttonType = _this.prev ? 'prev' : 'next';
            _this.scrollService.scrollTabs(buttonType);
        };
        this.subs.add(this.scrollService.scrollButtonActiveStateChange.subscribe(function (activeButtonSettings) {
            var applyActiveState = (_this.prev && activeButtonSettings.buttonType === 'prev') ||
                (!_this.prev && activeButtonSettings.buttonType === 'next');
            if (applyActiveState) {
                _this.disabled = !activeButtonSettings.active;
            }
        }));
    }
    Object.defineProperty(TabStripScrollableButtonComponent.prototype, "prevClass", {
        get: function () {
            return this.prev;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabStripScrollableButtonComponent.prototype, "nextClass", {
        get: function () {
            return !this.prev;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabStripScrollableButtonComponent.prototype, "disabled", {
        get: function () {
            return this._disabled;
        },
        set: function (value) {
            this._disabled = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabStripScrollableButtonComponent.prototype, "hostBoundingClientRect", {
        get: function () {
            return this.host.nativeElement.getBoundingClientRect();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabStripScrollableButtonComponent.prototype, "iconClass", {
        get: function () {
            return this.scrollService.scrollButtonIconClass[this.prev ? 'prevScrollButton' : 'nextScrollButton'];
        },
        enumerable: true,
        configurable: true
    });
    TabStripScrollableButtonComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.ngZone.runOutsideAngular(function () {
            _this.subs.add(_this.renderer.listen(_this.host.nativeElement, 'click', _this.clickHandler));
        });
    };
    TabStripScrollableButtonComponent.prototype.ngOnDestroy = function () {
        this.subs.unsubscribe();
    };
    TabStripScrollableButtonComponent.prototype.toggle = function (show) {
        this.renderer[show ? 'removeClass' : 'addClass'](this.host.nativeElement, constants_1.HIDDEN_CLASS);
    };
    tslib_1.__decorate([
        core_1.HostBinding('class.k-button'),
        core_1.HostBinding('class.k-button-md'),
        core_1.HostBinding('class.k-icon-button'),
        core_1.HostBinding('class.k-rounded-md'),
        core_1.HostBinding('class.k-button-flat'),
        core_1.HostBinding('class.k-button-flat-base'),
        tslib_1.__metadata("design:type", Boolean)
    ], TabStripScrollableButtonComponent.prototype, "btnClasses", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-tabstrip-prev'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], TabStripScrollableButtonComponent.prototype, "prevClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-tabstrip-next'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], TabStripScrollableButtonComponent.prototype, "nextClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-disabled'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [Boolean])
    ], TabStripScrollableButtonComponent.prototype, "disabled", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], TabStripScrollableButtonComponent.prototype, "prev", void 0);
    TabStripScrollableButtonComponent = tslib_1.__decorate([
        core_1.Component({
            template: "\n        <span class=\"k-icon k-button-icon\" [ngClass]=\"iconClass\"></span>\n    ",
            selector: '[kendoTabStripScrollableButton]'
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.ElementRef,
            tabstrip_scroll_service_1.ScrollService,
            core_1.Renderer2,
            core_1.NgZone])
    ], TabStripScrollableButtonComponent);
    return TabStripScrollableButtonComponent;
}());
exports.TabStripScrollableButtonComponent = TabStripScrollableButtonComponent;
