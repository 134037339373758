/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_licensing_1 = require("@progress/kendo-licensing");
var package_metadata_1 = require("../package-metadata");
var button_item_template_directive_1 = require("./button-item-template.directive");
var util_1 = require("../util");
/**
 * @hidden
 */
var ListComponent = /** @class */ (function () {
    function ListComponent() {
        this.onItemClick = new core_1.EventEmitter();
        this.onItemBlur = new core_1.EventEmitter();
        this.sizeClass = '';
        kendo_licensing_1.validatePackage(package_metadata_1.packageMetadata);
    }
    Object.defineProperty(ListComponent.prototype, "size", {
        set: function (size) {
            if (size) {
                this.sizeClass = "k-menu-group-" + util_1.SIZES[size];
            }
            else {
                this.sizeClass = '';
            }
        },
        enumerable: true,
        configurable: true
    });
    ListComponent.prototype.getText = function (dataItem) {
        if (dataItem) {
            return this.textField ? dataItem[this.textField] : dataItem.text || dataItem;
        }
        return undefined;
    };
    ListComponent.prototype.getIconClasses = function (dataItem) {
        var icon = dataItem.icon ? 'k-icon k-i-' + dataItem.icon : undefined;
        var classes = {};
        classes[icon || dataItem.iconClass] = true;
        return classes;
    };
    ListComponent.prototype.onClick = function (index) {
        this.onItemClick.emit(index);
    };
    ListComponent.prototype.onBlur = function () {
        this.onItemBlur.emit();
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Array)
    ], ListComponent.prototype, "data", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ListComponent.prototype, "textField", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", button_item_template_directive_1.ButtonItemTemplateDirective)
    ], ListComponent.prototype, "itemTemplate", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ListComponent.prototype, "onItemClick", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ListComponent.prototype, "onItemBlur", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], ListComponent.prototype, "size", null);
    ListComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-button-list',
            template: "\n        <ul class=\"k-group k-menu-group k-reset\" [ngClass]=\"sizeClass\" unselectable=\"on\" role=\"menu\">\n            <li role=\"menuitem\" unselectable=\"on\"\n                kendoButtonFocusable\n                *ngFor=\"let dataItem of data; let index = index;\"\n                [index]=\"index\"\n                tabindex=\"-1\"\n                class=\"k-item k-menu-item\"\n                (click)=\"onClick(index)\"\n                (blur)=\"onBlur()\"\n                [attr.aria-disabled]=\"dataItem.disabled ? true : false\">\n                <ng-template [ngIf]=\"itemTemplate?.templateRef\">\n                    <span class=\"k-link k-menu-link\" [class.k-disabled]=\"dataItem.disabled\">\n                        <ng-template [templateContext]=\"{templateRef: itemTemplate?.templateRef, $implicit: dataItem}\"></ng-template>\n                    </span>\n                </ng-template>\n                <ng-template [ngIf]=\"!itemTemplate?.templateRef\">\n                    <span class=\"k-link k-menu-link\" [class.k-disabled]=\"dataItem.disabled\">\n                        <span\n                            *ngIf=\"dataItem.icon || dataItem.iconClass\"\n                            [ngClass]=\"getIconClasses(dataItem)\"\n                        ></span>\n                        <img\n                            *ngIf=\"dataItem.imageUrl\"\n                            class=\"k-image\"\n                            [src]=\"dataItem.imageUrl\"\n                            alt=\"\"\n                        >\n                        <span *ngIf=\"getText(dataItem)\" class=\"k-menu-link-text\">\n                        {{ getText(dataItem) }}\n                        </span>\n                    </span>\n                </ng-template>\n            </li>\n        </ul>\n      "
        }),
        tslib_1.__metadata("design:paramtypes", [])
    ], ListComponent);
    return ListComponent;
}());
exports.ListComponent = ListComponent;
