/**
 * @ngdoc directive
 * @name amtFramework.filter.directive:amtIntegerFilteritem
 * @sortOrder 20
 * @restrict E
 * @requires amtFramework.filter.directive:amtFilter
 * 
 * @param {string} label Label to display above the control
 * @param {string} propertyName Name of the property that is added to filterValues property of {@link amtFramework.filter.directive:amtFilter amtFilter}
 * @param {expression=} [isRequired=false] Required
 * @param {expression=} min Sets the minimum value
 * @param {expression=} max Sets the maximum value
 *
 * @description
 * Filter item that let's the user filter on an integer value. Only adds the value to filtervalues when the user enters a value.
 * 
 * @example
 * See {@link amtFramework.filter.directive:amtFilter amtFilter}
 */
//import angular from 'angular';
import tmpl from './amtIntegerFilteritem.html';


angular.module('amtFramework.filter').directive('amtIntegerFilteritem', function() {
            return {
                require: '^amtFilter',
                restrict: 'E',
                scope: {
                    label: '@',
                    propertyName: "@",
                    isRequired: '=',
                    min: '=',
                    max: '='
                },
                template: tmpl,
                link: function(scope: any, elem, attr, filterCtrl) {
                    scope.filterItem = { propertyName: scope.propertyName };
                    filterCtrl.addFilterItem(scope.filterItem);
                }
            };
});
