    /**
        * @ngdoc directive
        * @name amtFramework.directive:amtPopupSelect
        * @restrict E
        * @description
        * Renders a control that displays a control that pops up a custom screen to make a selection of multiple values
        * @param {expression} ngModel Model to bind the selected values to
        * @param {expression} isRequired Required
        * @param {expression} isDisabled Disabled
        * @param {callback} onChange Fired when the selected value is changing
        * @param {string} template template of the popup
        * @param {string} controllerName Name of the controller for the popup
        * @param {expression} popupModel Property to bind to the model of the popup
    **/
    
//import angular from 'angular';
import tmpl from './amtPopupSelect.html';

angular.module('amtFramework').directive('amtPopupSelect', [
            '$modal',
            function ($modal, $modalInstance) {

                return {
                    restrict: 'E',
                    require: ['ngModel', '^?form'],
                    scope: {
                        ngModel: "=",
                        isRequired: '=',
                        isDisabled: '=',
                        onChange: "&",
                        template: '@',
                        controllerName: '@',
                        popupModel: '='
                    },
                    template: tmpl,
                    link: function (scope: any, element, attrs, ctrls) {
                        var modelCtrl = ctrls[0];
                        var formCtrl = ctrls[1];
                        var oldModel = angular.copy(scope.ngModel);

                        scope.$watch("ngModel", function (value) {
                            var valid = scope.isRequired === undefined || scope.isRequired === false || (scope.isRequired && scope.ngModel !== undefined && scope.ngModel !== null);
                            modelCtrl.$setValidity(scope.name, valid);
                            if (!angular.equals(oldModel, value)) {
                                if (formCtrl) {
                                    formCtrl.$setDirty();
                                }
                            }

                        });
                        scope.openPopup = function () {
                            if(scope.isDisabled && scope.$eval(attrs.isDisabled) === true)
                            {
                                return;
                            }
                            $modal.open({
                                template: scope.template,
                                controller: scope.controllerName,
                                size: 'lg',
                                backdrop: 'static',
                                //windowClass: 'modal-xlg',
                                resolve: {
                                    popupModel: function () {
                                        return scope.popupModel;
                                    }
                                }
                            });
                        };
                        scope.onTextChange = function () {
                            if (formCtrl) {
                                formCtrl.$setDirty();
                            }
                            if (scope.onChange()) {
                                scope.onChange()(scope.ngModel);
                            }
                        };
                        scope.clear = function () {
                            scope.ngModel = undefined;
                        };
                    }
                };
            }
]);
