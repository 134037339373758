import { BehaviorSubject } from 'rxjs';

//TODO: stuff in this file has terrible names

export function mapFrom<T>(src: { [key: string]: T; } | Iterable<[string, T]>) {
    let entries = Symbol.iterator in Object(src) ? src as Iterable<[string, T]> : Object.entries(src) as [string, T][];

    return new Map(entries) as Map<string, T>;
}

export function mapFromWithKeyMember<T>(src: { [key: string]: T; }, keyname: keyof T ) {
    let entries = Object.entries(src) as [string, T][];

    if (keyname) {
        for (let e of entries) {
            e[1][keyname] = e[0] as any; //TODO: add typecheck such that the keyname'd property is a string (or string like)
        }
    }

    return new Map(entries) as Map<string, T>;
}

export function indexByMap<T>(src: T[], key: keyof T) {
    return new Map(src.map(s => [s[key], s]));
}

export function makeLookup<T>(src: { [key: string]: T; }, key: keyof T) {
    return new Map(Object.entries(src).map(s => [s[1][key], s[0]]));
}