//import angular from 'angular';
import tmpl from './details.html';
import SysMaintActionSvc from './sysMaintActionSvc';

class VisualInspectionActionDetailsCtrl implements ng.IController, IWindowController {

    form: ng.IFormController;
    wnd: IWindowObj;

    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: () => void };

    status: ScreenStatus = ScreenStatus.loading;
    readonly: boolean;

    initParams: any;

    item: any;

    static $inject = ['$scope', 'confirmSvc', 'sysMaintActionSvc', 'WindowFactory', 'notifySvc',
        'amtXlatSvc', 'errorReporter', 'ocConfigSvc'];

    constructor(private $scope: ng.IScope, private confirmSvc: IConfirmSvc, private sysMaintActionSvc: SysMaintActionSvc,
        private WindowFactory: IWindowFactory, private notifySvc: INotifySvc, private amtXlatSvc: IAmtXlatSvc,
        private errorReporter: IErrorReporter, private ocConfigSvc: IOcConfigSvc) {

        // pass along dirty flag to the window for use on closing minimised windows
        this.$scope.$watch(() => this.form.$dirty, (newValue) => this.wnd.isDirty = newValue);

        this.$scope.$watchGroup([() => this.form.$invalid, () => this.form.$pristine, () => this.wnd.processing], () => {            
            this.buttonStates.saveButton.disabled = this.form.$invalid || this.form.$pristine || this.wnd.processing;
        });
    }

    async $onInit() {

        this.wnd.onClose = () => this.cancel();

        try {

            this.wnd.processing = true;

            this.item = angular.copy(this.initParams);
            this.readonly = this.initParams.readonly;

            this.WindowFactory.addButton(this, 'common.save_label', 'saveButton', () => this.save(), true, !this.readonly);
            this.WindowFactory.addButton(this, 'common.cancel_label', 'cancelButton', () => this.cancel());

            if (this.initParams.mode !== 'add')
                await this.getDetails(); // load the details

            this.status = ScreenStatus.ready;

        } catch (error) {
            this.errorReporter.logError(error);
            this.status = ScreenStatus.error;
        } finally {
            this.wnd.processing = false;
        }
    }

    onMonitorOnlyChange() {        
        this.item.daysToResolve = null;
    }

    async getDetails() {

        let response = await this.sysMaintActionSvc.getAsync(this.item.id);

        this.item = response;
        this.item.applyToAllLanguages = true; // default to true            

        if (!this.item.daysToResolve)
            this.item.monitorOnly = true;
    }

    async save() {

        this.wnd.processing = true;

        try {
            await this.sysMaintActionSvc.saveAsync(this.item);

            this.notifySvc.success(this.amtXlatSvc.xlat(this.sysMaintActionSvc.resourceGroup + '.saveSuccessful'));

            if (this.wnd.onDataChanged)
                this.wnd.onDataChanged(); // refresh the search grid            

            this.closeWindow();
        } catch (error) {
            this.errorReporter.logError(error);
        } finally {
            this.wnd.processing = false;
        }
    }
    
    closeWindow() {
        this.WindowFactory.closeWindow(this.wnd);
    }

    async cancel() {

        try {
            await this.confirmSvc.confirmSaveChange(this.form.$dirty);
        } catch (error) {
            return false; // they cancelled
        }

        this.form.$setPristine();
        this.closeWindow();

        return true;
    }
}

class VisualInspectionActionDetailsComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = VisualInspectionActionDetailsCtrl;
    public controllerAs = 'vm';
}

angular.module('app.admin').component('addEditVisualInspectionAction', new VisualInspectionActionDetailsComponent());