//import angular from 'angular';

angular.module("app.controllers").controller('statesCtrl', ['$state', function ($state) {
        var vm = this;
        vm.states = $state.get();         

        vm.prior = "";

        vm.section = function (s) {
            var res = s.name.split(".");
            var current = res[0] + "." + res[1];
            var result = true;
            if (vm.prior === current) {
                result = false;
            }
            vm.prior = current;
            return result && !s.abstract;
        };

        vm.goState = function (name) {
            $state.go(name);
        };

        return vm;
    }]);       
