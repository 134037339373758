/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var utils_1 = require("../utils");
var pair_set_1 = require("./pair-set");
/**
 * @hidden
 */
var Selection = /** @class */ (function () {
    function Selection(grid, cd) {
        this.grid = grid;
        this.cd = cd;
        /**
         * Defines the collection that will store the selected item keys.
         */
        this.selectedKeys = [];
        /**
         * Fires when the `selectedKeys` collection has been updated.
         */
        this.selectedKeysChange = new core_1.EventEmitter();
        this.rowSelectionState = new Set();
        this.cellSelectionState = new pair_set_1.PairSet();
        this.init();
    }
    Object.defineProperty(Selection.prototype, "isCellSelectionMode", {
        get: function () {
            return utils_1.isPresent(this.grid.selectable) && this.grid.selectable['cell'];
        },
        enumerable: true,
        configurable: true
    });
    Selection.prototype.ngOnChanges = function (changes) {
        // skip reinitialization if the user data is the same as the last state change
        if (utils_1.isPresent(changes.selectedKeys) && this.lastSelectionState !== this.selectedKeys) {
            this.setState(this.selectedKeys);
        }
    };
    Selection.prototype.init = function () {
        var _this = this;
        if (!utils_1.isPresent(this.grid.rowSelected)) {
            this.grid.rowSelected = function (row) { return _this.rowSelectionState.has(_this.getItemKey(row)); };
        }
        if (!utils_1.isPresent(this.grid.cellSelected)) {
            this.grid.cellSelected = function (row, column, colIndex) {
                var contender = _this.getSelectionItem(row, column, colIndex);
                return {
                    selected: _this.cellSelectionState.has(contender.itemKey, contender.columnKey),
                    item: contender
                };
            };
        }
        this.selectionChangeSubscription = this.grid
            .selectionChange
            .subscribe(this.onSelectionChange.bind(this));
    };
    /**
     * @hidden
     */
    Selection.prototype.destroy = function () {
        this.selectionChangeSubscription.unsubscribe();
    };
    /**
     * @hidden
     */
    Selection.prototype.reset = function () {
        this.rowSelectionState.clear();
        this.cellSelectionState.clear();
    };
    Selection.prototype.getItemKey = function (row) {
        if (this.selectionKey) {
            if (typeof this.selectionKey === "string") {
                return row.dataItem[this.selectionKey];
            }
            if (typeof this.selectionKey === "function") {
                return this.selectionKey(row);
            }
        }
        return row.index;
    };
    Selection.prototype.getSelectionItem = function (row, col, colIndex) {
        var itemIdentifiers = {};
        itemIdentifiers.itemKey = this.getItemKey(row);
        if (!utils_1.isPresent(col) && !utils_1.isPresent(colIndex)) {
            return itemIdentifiers;
        }
        if (this.columnKey) {
            if (typeof this.columnKey === "string") {
                itemIdentifiers.columnKey = row.dataItem[this.columnKey];
            }
            if (typeof this.columnKey === "function") {
                itemIdentifiers.columnKey = this.columnKey(col, colIndex);
            }
        }
        return {
            itemKey: itemIdentifiers.itemKey,
            columnKey: itemIdentifiers.columnKey ? itemIdentifiers.columnKey : colIndex
        };
    };
    Selection.prototype.onSelectionChange = function (selection) {
        var _this = this;
        if (selection.selectedRows) {
            selection.deselectedRows.forEach(function (item) {
                var itemKey = _this.getItemKey(item);
                _this.rowSelectionState.delete(itemKey);
            });
            if (this.grid.selectableSettings.mode === "single" && this.rowSelectionState.size > 0) {
                this.reset();
            }
            selection.selectedRows.forEach(function (item) {
                var itemKey = _this.getItemKey(item);
                _this.rowSelectionState.add(itemKey);
            });
        }
        else {
            selection.deselectedCells.forEach(function (_a) {
                var itemKey = _a.itemKey, columnKey = _a.columnKey;
                _this.cellSelectionState.delete(itemKey, columnKey);
            });
            if (this.grid.selectableSettings.mode === "single" && this.cellSelectionState.size > 0) {
                this.reset();
            }
            selection.selectedCells.forEach(function (_a) {
                var itemKey = _a.itemKey, columnKey = _a.columnKey;
                _this.cellSelectionState.add(itemKey, columnKey);
            });
        }
        this.cd.markForCheck();
        this.notifyChange();
    };
    Selection.prototype.notifyChange = function () {
        this.lastSelectionState = this.stateToArray();
        this.selectedKeysChange.emit(this.lastSelectionState);
    };
    Selection.prototype.setState = function (selectedKeys) {
        this.reset();
        if (this.isCellSelectionMode) {
            this.cellSelectionState = new pair_set_1.PairSet(selectedKeys, 'itemKey', 'columnKey');
        }
        else {
            this.rowSelectionState = new Set(selectedKeys);
        }
    };
    Selection.prototype.stateToArray = function () {
        return this.isCellSelectionMode ?
            this.cellSelectionState.toArray('itemKey', 'columnKey') :
            Array.from(this.rowSelectionState);
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Array)
    ], Selection.prototype, "selectedKeys", void 0);
    tslib_1.__decorate([
        core_1.Input("kendoGridSelectBy"),
        tslib_1.__metadata("design:type", Object)
    ], Selection.prototype, "selectionKey", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], Selection.prototype, "columnKey", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], Selection.prototype, "selectedKeysChange", void 0);
    return Selection;
}());
exports.Selection = Selection;
