/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var forms_1 = require("@angular/forms");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var text_fields_base_1 = require("../text-fields-common/text-fields-base");
var utils_1 = require("../common/utils");
var dom_utils_1 = require("../common/dom-utils");
var dom_utils_2 = require("./../common/dom-utils");
var kendo_licensing_1 = require("@progress/kendo-licensing");
var package_metadata_1 = require("../package-metadata");
var resizeClasses = {
    'vertical': 'k-resize-vertical',
    'horizontal': 'k-resize-horizontal',
    'both': 'k-resize-both',
    'none': 'k-resize-none',
    'auto': 'k-resize-none'
};
var FOCUSED = 'k-focus';
/**
 * Represents the [Kendo UI TextArea component for Angular]({% slug overview_textarea %}).
 */
var TextAreaComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TextAreaComponent, _super);
    function TextAreaComponent(localizationService, ngZone, changeDetector, renderer, injector, hostElement) {
        var _this = _super.call(this, localizationService, ngZone, changeDetector, renderer, injector, hostElement) || this;
        _this.localizationService = localizationService;
        _this.ngZone = ngZone;
        _this.changeDetector = changeDetector;
        _this.renderer = renderer;
        _this.injector = injector;
        _this.hostElement = hostElement;
        /**
         * @hidden
         */
        _this.focusableId = "k-" + kendo_angular_common_1.guid();
        _this.hostClasses = true;
        /**
         * Specifies the flow direction of the TextArea sections. This property is useful when adornments are used, in order to specify
         * their position in relation to the textarea element.
         *
         * The possible values are:
         * * `vertical`(Default) &mdash;TextArea sections are placed from top to bottom.
         * * `horizontal`&mdash;TextArea sections are placed from left to right in `ltr`, and from right to left in `rtl` mode.
         */
        _this.flow = 'vertical';
        /**
         * Specifies the [tabindex](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex) of the component.
         */
        _this.tabindex = 0;
        /**
         * Configures the resize behavior of the TextArea.
         *
         * The possible values are:
         * * `vertical`(Default)&mdash;The TextArea component can be resized only vertically.
         * * `horizontal`&mdash;The TextArea component can be resized only horizontally.
         * * `both`&mdash;The TextArea component can be resized in both (horizontal and vertical) directions.
         * * `auto`&mdash;Specifies whether the TextArea component will adjust its height automatically, based on the content.
         * * `none`&mdash;The TextArea cannot be resized.
         *
         */
        _this.resizable = 'vertical';
        /**
         * Fires each time the user focuses the TextArea component.
         *
         * > To wire the event programmatically, use the `onFocus` property.
         *
         * @example
         * ```ts
         * _@Component({
         * selector: 'my-app',
         * template: `
         *  <kendo-textarea (focus)="handleFocus()"></kendo-textarea>
         * `
         * })
         * class AppComponent {
         *   public handleFocus(): void {
         *      console.log('Component is focused');
         *   }
         * }
         * ```
         */
        _this.onFocus = new core_1.EventEmitter();
        /**
         * Fires each time the TextArea component gets blurred.
         *
         * > To wire the event programmatically, use the `onBlur` property.
         *
         * @example
         * ```ts
         * _@Component({
         * selector: 'my-app',
         * template: `
         *  <kendo-textarea (blur)="handleBlur()"></kendo-textarea>
         * `
         * })
         * class AppComponent {
         *   public handleBlur(): void {
         *      console.log('Component is blurred');
         *   }
         * }
         * ```
         */
        _this.onBlur = new core_1.EventEmitter();
        /**
         * Fires each time the value is changed or the component is blurred
         * ([see example]({% slug overview_textarea %}#toc-events)).
         * When the component value is changed programmatically or via its form control binding, the valueChange event is not emitted.
         */
        _this.valueChange = new core_1.EventEmitter();
        _this._size = 'medium';
        _this._rounded = 'medium';
        _this._fillMode = 'solid';
        /**
         * @hidden
         */
        _this.handleInput = function (ev) {
            var incomingValue = ev.target.value;
            _this.updateValue(incomingValue);
            _this.resize();
        };
        /**
         * @hidden
         */
        _this.handleInputFocus = function () {
            if (!_this.disabled) {
                if (_this.selectOnFocus && _this.value) {
                    _this.ngZone.run(function () {
                        setTimeout(function () { _this.selectAll(); });
                    });
                }
                if (kendo_angular_common_1.hasObservers(_this.onFocus)) {
                    if (!_this.isFocused) {
                        _this.ngZone.run(function () {
                            _this.onFocus.emit();
                        });
                    }
                }
                if (kendo_angular_common_1.hasObservers(_this.inputFocus)) {
                    if (!_this.focusChangedProgrammatically) {
                        _this.ngZone.run(function () {
                            _this.inputFocus.emit();
                        });
                    }
                }
                _this.ngZone.run(function () {
                    _this.isFocused = true;
                });
            }
        };
        kendo_licensing_1.validatePackage(package_metadata_1.packageMetadata);
        _this.direction = localizationService.rtl ? 'rtl' : 'ltr';
        return _this;
    }
    TextAreaComponent_1 = TextAreaComponent;
    Object.defineProperty(TextAreaComponent.prototype, "flowCol", {
        get: function () {
            return this.flow === 'vertical';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextAreaComponent.prototype, "flowRow", {
        get: function () {
            return this.flow === 'horizontal';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextAreaComponent.prototype, "tabIndex", {
        get: function () {
            return this.tabindex;
        },
        /**
         * @hidden
         */
        set: function (tabIndex) {
            this.tabindex = tabIndex;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextAreaComponent.prototype, "size", {
        get: function () {
            return this._size;
        },
        /**
         * The size property specifies the padding of the internal textarea element
         * ([see example]({% slug appearance_textarea %}#toc-size)).
         *
         * The possible values are:
         * * `'small'`
         * * `'medium'` (default)
         * * `'large'`
         * * `null`
         */
        set: function (size) {
            this.handleClasses(size, 'size');
            this._size = size;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextAreaComponent.prototype, "rounded", {
        get: function () {
            return this._rounded;
        },
        /**
         * The rounded property specifies the border radius of the TextArea
         * ([see example]({% slug appearance_textarea %}#toc-rounded)).
         *
         * The possible values are:
         * * `'small'`
         * * `'medium'` (default)
         * * `'large'`
         * * `null`
         */
        set: function (rounded) {
            this.handleClasses(rounded, 'rounded');
            this._rounded = rounded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextAreaComponent.prototype, "fillMode", {
        get: function () {
            return this._fillMode;
        },
        /**
         * The fillMode property specifies the background and border styles of the TextArea
         * ([see example]({% slug appearance_textarea %}#toc-fillMode)).
         *
         * The possible values are:
         * * `'flat'`
         * * `'solid'` (default)
         * * `'outline'`
         * * `null`
         */
        set: function (fillMode) {
            this.handleClasses(fillMode, 'fillMode');
            this._fillMode = fillMode;
        },
        enumerable: true,
        configurable: true
    });
    TextAreaComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        var hostElement = this.hostElement.nativeElement;
        var cursorInsideWrapper = false;
        var tabbing = false;
        this.ngZone.runOutsideAngular(function () {
            // focusIn and focusOut are relative to the host element
            _this.subscriptions.add(_this.renderer.listen(hostElement, 'focusin', function () {
                if (!_this.isFocused) {
                    _this.ngZone.run(function () {
                        _this.onFocus.emit();
                        _this.isFocused = true;
                    });
                }
            }));
            _this.subscriptions.add(_this.renderer.listen(hostElement, 'focusout', function (args) {
                if (!_this.isFocused) {
                    return;
                }
                if (tabbing) {
                    var closestTextbox = dom_utils_2.closest(args.relatedTarget, function (element) { return element === _this.hostElement.nativeElement; });
                    if (!closestTextbox) {
                        _this.handleBlur();
                    }
                    tabbing = false;
                }
                else {
                    if (!cursorInsideWrapper) {
                        _this.handleBlur();
                    }
                }
            }));
            _this.subscriptions.add(_this.renderer.listen(hostElement, 'mouseenter', function () {
                cursorInsideWrapper = true;
            }));
            _this.subscriptions.add(_this.renderer.listen(hostElement, 'mouseleave', function () {
                cursorInsideWrapper = false;
            }));
            _this.subscriptions.add(_this.renderer.listen(hostElement, 'keydown', function (args) {
                if (args.keyCode === kendo_angular_common_1.Keys.Tab) {
                    tabbing = true;
                }
                else {
                    tabbing = false;
                }
            }));
            _this.handleFlow();
        });
        var stylingInputs = ['size', 'rounded', 'fillMode'];
        stylingInputs.forEach(function (input) {
            _this.handleClasses(_this[input], input);
        });
    };
    TextAreaComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.control = this.injector.get(forms_1.NgControl, null);
        if (kendo_angular_common_1.isDocumentAvailable() && this.resizable === 'auto') {
            this.resizeSubscription = rxjs_1.fromEvent(window, 'resize')
                .pipe((operators_1.debounceTime(50)))
                .subscribe(function () { return _this.resize(); });
        }
        if (this.hostElement) {
            this.renderer.removeAttribute(this.hostElement.nativeElement, "tabindex");
        }
        this.subscriptions = this.localizationService.changes.subscribe(function (_a) {
            var rtl = _a.rtl;
            _this.direction = rtl ? 'rtl' : 'ltr';
        });
    };
    TextAreaComponent.prototype.ngOnChanges = function (changes) {
        var hostElement = this.hostElement.nativeElement;
        var element = this.input.nativeElement;
        if (changes.flow) {
            this.handleFlow();
        }
        if (changes.resizable) {
            if (this.resizable === 'auto') {
                this.renderer.removeClass(element, '\!k-overflow-y-auto');
                this.initialHeight = element.offsetHeight;
            }
            else {
                this.renderer.addClass(element, '\!k-overflow-y-auto');
                element.style.height = this.initialHeight + "px";
            }
        }
        if (changes.cols) {
            if (utils_1.isPresent(changes.cols.currentValue)) {
                this.renderer.setStyle(hostElement, 'width', 'auto');
            }
            else {
                this.renderer.removeStyle(hostElement, 'width');
            }
        }
    };
    TextAreaComponent.prototype.ngAfterViewChecked = function () {
        this.resize();
    };
    /**
     * @hidden
     */
    TextAreaComponent.prototype.writeValue = function (value) {
        this.value = value;
    };
    /**
     * @hidden
     */
    TextAreaComponent.prototype.registerOnChange = function (fn) {
        this.ngChange = fn;
    };
    /**
     * @hidden
     */
    TextAreaComponent.prototype.registerOnTouched = function (fn) {
        this.ngTouched = fn;
    };
    TextAreaComponent.prototype.updateValue = function (value) {
        var _this = this;
        if (!utils_1.areSame(this.value, value)) {
            this.ngZone.run(function () {
                _this.value = value;
                _this.ngChange(value);
                _this.valueChange.emit(value);
                _this.changeDetector.markForCheck();
            });
        }
    };
    TextAreaComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        if (this.resizeSubscription) {
            this.resizeSubscription.unsubscribe();
        }
    };
    Object.defineProperty(TextAreaComponent.prototype, "resizableClass", {
        /**
         * @hidden
         */
        get: function () {
            return resizeClasses[this.resizable];
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Focuses the TextArea component.
     *
     * @example
     * ```ts
     * _@Component({
     * selector: 'my-app',
     * template: `
     *  <button (click)="textarea.focus()">Focus the textarea</button>
     *  <kendo-textarea #textarea></kendo-textarea>
     * `
     * })
     * class AppComponent { }
     * ```
     */
    TextAreaComponent.prototype.focus = function () {
        if (!this.input) {
            return;
        }
        this.focusChangedProgrammatically = true;
        this.isFocused = true;
        this.input.nativeElement.focus();
        this.focusChangedProgrammatically = false;
    };
    /**
     * Blurs the TextArea component.
     */
    TextAreaComponent.prototype.blur = function () {
        this.focusChangedProgrammatically = true;
        var isFocusedElement = this.hostElement.nativeElement.querySelector(':focus');
        if (isFocusedElement) {
            isFocusedElement.blur();
        }
        this.isFocused = false;
        this.focusChangedProgrammatically = false;
    };
    TextAreaComponent.prototype.resize = function () {
        if (this.resizable !== 'auto') {
            return;
        }
        var element = this.input.nativeElement;
        this.renderer.setStyle(element, 'height', this.initialHeight + "px");
        var scrollHeight = element.scrollHeight;
        if (scrollHeight > this.initialHeight) {
            element.style.height = scrollHeight + "px";
        }
    };
    Object.defineProperty(TextAreaComponent.prototype, "isFocused", {
        get: function () {
            return this._isFocused;
        },
        set: function (value) {
            if (this._isFocused !== value && this.hostElement) {
                var element = this.hostElement.nativeElement;
                if (value && !this.disabled) {
                    this.renderer.addClass(element, FOCUSED);
                }
                else {
                    this.renderer.removeClass(element, FOCUSED);
                }
                this._isFocused = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    TextAreaComponent.prototype.handleBlur = function () {
        var _this = this;
        this.ngZone.run(function () {
            if (!_this.focusChangedProgrammatically) {
                _this.onBlur.emit();
            }
            _this.isFocused = false;
        });
    };
    TextAreaComponent.prototype.setSelection = function (start, end) {
        if (this.isFocused) {
            dom_utils_1.invokeElementMethod(this.input, 'setSelectionRange', start, end);
        }
    };
    TextAreaComponent.prototype.selectAll = function () {
        if (this.value) {
            this.setSelection(0, this.value.length);
        }
    };
    TextAreaComponent.prototype.handleClasses = function (value, input) {
        var elem = this.hostElement.nativeElement;
        var classes = utils_1.getStylingClasses('input', input, this[input], value);
        if (classes.toRemove) {
            this.renderer.removeClass(elem, classes.toRemove);
        }
        if (classes.toAdd) {
            this.renderer.addClass(elem, classes.toAdd);
        }
    };
    TextAreaComponent.prototype.handleFlow = function () {
        var isVertical = this.flow === 'vertical';
        var hostElement = this.hostElement.nativeElement;
        var element = this.input.nativeElement;
        var suffix = hostElement.children[1];
        this.renderer[isVertical ? 'addClass' : 'removeClass'](element, '\!k-flex-none');
        if (suffix) {
            this.renderer[isVertical ? 'removeClass' : 'addClass'](suffix, '\!k-align-items-start');
        }
    };
    var TextAreaComponent_1;
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], TextAreaComponent.prototype, "focusableId", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-textarea'),
        core_1.HostBinding('class.k-input'),
        tslib_1.__metadata("design:type", Boolean)
    ], TextAreaComponent.prototype, "hostClasses", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.\!k-flex-col'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], TextAreaComponent.prototype, "flowCol", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.\!k-flex-row'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], TextAreaComponent.prototype, "flowRow", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], TextAreaComponent.prototype, "flow", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], TextAreaComponent.prototype, "rows", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], TextAreaComponent.prototype, "cols", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], TextAreaComponent.prototype, "maxlength", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], TextAreaComponent.prototype, "tabindex", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number),
        tslib_1.__metadata("design:paramtypes", [Number])
    ], TextAreaComponent.prototype, "tabIndex", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], TextAreaComponent.prototype, "resizable", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], TextAreaComponent.prototype, "size", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], TextAreaComponent.prototype, "rounded", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], TextAreaComponent.prototype, "fillMode", null);
    tslib_1.__decorate([
        core_1.Output('focus'),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], TextAreaComponent.prototype, "onFocus", void 0);
    tslib_1.__decorate([
        core_1.Output('blur'),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], TextAreaComponent.prototype, "onBlur", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], TextAreaComponent.prototype, "valueChange", void 0);
    TextAreaComponent = TextAreaComponent_1 = tslib_1.__decorate([
        core_1.Component({
            exportAs: 'kendoTextArea',
            providers: [
                kendo_angular_l10n_1.LocalizationService,
                { provide: kendo_angular_l10n_1.L10N_PREFIX, useValue: 'kendo.textarea' },
                {
                    provide: forms_1.NG_VALUE_ACCESSOR,
                    useExisting: core_1.forwardRef(function () { return TextAreaComponent_1; }),
                    multi: true
                },
                { provide: kendo_angular_common_1.KendoInput, useExisting: core_1.forwardRef(function () { return TextAreaComponent_1; }) }
            ],
            selector: 'kendo-textarea',
            template: "\n        <textarea\n            #input\n            [attr.aria-multiline]=\"true\"\n            [attr.aria-disabled]=\"disabled ? true : undefined\"\n            [attr.aria-readonly]=\"readonly ? true : undefined\"\n            class=\"k-input-inner\"\n            [ngClass]=\"resizableClass\"\n            [id]=\"focusableId\"\n            [value]=\"value\"\n            [attr.placeholder]=\"placeholder\"\n            [disabled]=\"disabled\"\n            [readonly]=\"readonly\"\n            [attr.rows]=\"rows\"\n            [attr.cols]=\"cols\"\n            [attr.tabindex]=\"tabIndex\"\n            [attr.title]=\"title\"\n            [attr.maxlength]=\"maxlength\"\n            [kendoEventsOutsideAngular]=\"{\n                focus: handleInputFocus,\n                blur: handleInputBlur,\n                input: handleInput}\">\n            </textarea>\n            <ng-content select=\"kendo-textarea-suffix\"></ng-content>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService,
            core_1.NgZone,
            core_1.ChangeDetectorRef,
            core_1.Renderer2,
            core_1.Injector,
            core_1.ElementRef])
    ], TextAreaComponent);
    return TextAreaComponent;
}(text_fields_base_1.TextFieldsBase));
exports.TextAreaComponent = TextAreaComponent;
