import { Component, OnInit, HostBinding,    ChangeDetectionStrategy, Inject, OnChanges, SimpleChanges, Input, ViewChild, ElementRef} from '@angular/core';
import { IDisplayVehicle } from '../condition-monitoring.service';
import { IRendererConfig } from '../monitoringOptions/condition-monitoring-base.component';
import { VehicleRenderer } from './vehicle-renderer.service';


@Component({
    selector: '[condition-monitoring-icon]',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './condition-monitoring-icon.component.html',

    styleUrls: ['./condition-monitoring-icon.component.scss'],
    providers: [
        VehicleRenderer
    ]
})
export class ConditionMonitoringIconComponent implements OnInit {
    @HostBinding("style.--img-w") public readonly cssImgWidth = this.renderer.desiredWidth;
    @HostBinding("style.--img-h") public readonly cssImgHeight = this.renderer.desiredHeight;
    @Input('condition-monitoring-icon') vehicle!: IDisplayVehicle;
    @Input('renderer-config') rendererConfig: IRendererConfig;
    @ViewChild('iconCanvas', { static: true }) iconCanvas!: ElementRef<HTMLCanvasElement>;

    constructor(private renderer: VehicleRenderer) {}

    ngOnInit(): void {
        this.renderer.init(this.iconCanvas.nativeElement, this.vehicle, this.rendererConfig.lookupTable);
    }
}
