//import angular from 'angular';
import tmpl from './siteDashboardAdd.html';
//import './siteDashboardConfigurationDetails.scss';
import OcConfigSvc from '../../../services/ocConfigSvc';
import HelperSvc from '../../../services/helperSvc';
import { DashboardWidgetConfigurationType, IDashboardWidget, ISiteDashboardWidget, IDashboardWidgetConfiguration, ISaveSiteDashboardCriteria } from './siteDashboardConfiguration.interfaces';

class SiteDashboardAddCtrl implements ng.IController, IWindowController {

    wnd: IWindowObj;
    form: ng.IFormController;

    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: (button: string) => void }

    initParams: any = {};
    data: any = {};
    readonly: boolean;
    saveButtonDisabled: boolean;

    static $inject = ['$scope', 'ocConfigSvc', 'ocSecuritySvc', 'confirmSvc', 'WindowFactory', 'amtCommandQuerySvc',
        'adminUrls', 'errorReporter', 'equipmentUrls', 'vehicleUrls', 'amtXlatSvc', 'referenceDataUrls', 'helperSvc',
        'notifySvc', '$timeout', 'componentUrls'];

    constructor(private $scope: ng.IScope, private ocConfigSvc: OcConfigSvc, private ocSecuritySvc: IOcSecuritySvc, private confirmSvc: IConfirmSvc,
        private WindowFactory: IWindowFactory, private amtCommandQuerySvc: IAmtCommandQuerySvc, private adminUrls: IAdminUrls, private errorReporter: IErrorReporter,
        private equipmentUrls: IEquipmentUrls, private vehicleUrls: IVehicleUrls, private amtXlatSvc: IAmtXlatSvc, private referenceDataUrls: IReferenceDataUrls,
        private helperSvc: HelperSvc, private notifySvc: INotifySvc, private $timeout: ng.ITimeoutService, private componentUrls: IComponentUrls) {

        this.data.siteId = ocConfigSvc.currentSiteId();
    }

    async $onInit() {

        this.wnd.onClose = () => this.closeWindow();
        this.wnd.processing = true;
        this.saveButtonDisabled = true;

        try {
            this.data = angular.copy(this.initParams);
            this.readonly = this.initParams.readonly || !this.ocSecuritySvc.isAuthorised('Security.Settings.SiteDashboard', AccessTypes.readWrite);

            this.WindowFactory.addButton(this, 'common.cancel_label', 'cancelButton', () => this.closeWindow());
            this.WindowFactory.addButton(this, 'common.save_label', 'saveButton', () => this.saveSiteDashboard(), true, true, this.saveButtonDisabled);
            this.checkLength();

        } finally {
            this.wnd.processing = false;
        }
    }

    private setWindowTitle() {
        this.wnd.caption = this.data.name
    }

    async saveSiteDashboard() {

        if (this.data.name.length > 0) {

            let criteria: ISaveSiteDashboardCriteria = {
                siteId: this.data.siteId,
                dashboardId: null,
                dashboardName: this.data.name,
                active: true,
                isDefault: false
            }

            if (this.data.id != null) {
                criteria.dashboardId = this.data.id;
                criteria.active = this.data.active;
                criteria.isDefault = this.data.isDefault;
            }

            await this.PostDashboard(criteria);
        }

    }

    async PostDashboard(criteria) {

        this.wnd.processing = true;
        try {
            await this.amtCommandQuerySvc.post(this.adminUrls.saveSiteDashboards, criteria);

            this.notifySvc.success(this.amtXlatSvc.xlat('siteDashboard.saveSiteDashboardWidgetSuccess'));

            if (this.wnd.onDataChanged)
                this.wnd.onDataChanged();

            this.form.$setPristine();

            this.closeWindow();

        } catch (error) {
            this.errorReporter.logError(error);
        } finally {
            this.wnd.processing = false;
        }

    }

    async closeWindow() {
        try {
            await this.confirmSvc.confirmSaveChange(this.form.$dirty);
        } catch (error) {
            return false; // they cancelled
        }

        this.form.$setPristine();

        this.WindowFactory.closeWindow(this.wnd);

        return true;
    }

    checkLength() {
        //this.saveButtonDisabled = !((this.data.name != undefined) && (this.data.name.length > 0)));
        if ((this.data.name == undefined) || ((this.data.name != undefined) && (this.data.name.length == 0)))
            this.form.$setPristine();
        this.saveButtonDisabled = (this.form.$pristine || this.wnd.processing);
        this.wnd.buttonStates['saveButton'].disabled = this.saveButtonDisabled;
    }

    checkChanged() {
        this.wnd.buttonStates['saveButton'].disabled = false;
    }
}

angular.module('app.admin').component('siteDashboardAdd', {
    template: tmpl,
    controllerAs: 'vm',
    controller: SiteDashboardAddCtrl,
    bindings: {
        initParams: '<',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    }
});