/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * @hidden
 */
var FilterInputDirective = /** @class */ (function () {
    function FilterInputDirective(element, zone) {
        this.element = element;
        this.zone = zone;
    }
    FilterInputDirective.prototype.ngOnChanges = function () {
        var _this = this;
        if (this.focused) {
            this.nextTick(function () { return _this.element.nativeElement.focus(); });
        }
    };
    FilterInputDirective.prototype.nextTick = function (fn) {
        this.zone.runOutsideAngular(function () { return setTimeout(fn); });
    };
    tslib_1.__decorate([
        core_1.Input('filterInput'),
        tslib_1.__metadata("design:type", Boolean)
    ], FilterInputDirective.prototype, "focused", void 0);
    FilterInputDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[filterInput]' // tslint:disable-line
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.ElementRef,
            core_1.NgZone])
    ], FilterInputDirective);
    return FilterInputDirective;
}());
exports.FilterInputDirective = FilterInputDirective;
