//import angular from 'angular';
import * as _ from 'underscore';
import tmpl from './stockType.html';


    angular.module("app.stocktake").component('stockType', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['$scope', 'errorReporter', 'WindowFactory', 'enums', 'confirmSvc', 'dataBroker', '$timeout',
            function ($scope: IVmScope, errorReporter: IErrorReporter, WindowFactory: IWindowFactory, enums, confirmSvc: IConfirmSvc, dataBroker, $timeout: ng.ITimeoutService) {
                var vm = this;
                $scope.vm = this;

                vm.onChange = onChange;
                vm.cancel = cancel;                          
    
                this.$onInit = function () {

                    dataBroker.getStocktakeComponentTypes().then(function (data) {
                        vm.types = data.map(function (d) { return { key: d.name.toLowerCase(), name: d.description }; });
                        vm.selectedType = _.find(vm.types, function (t) { return t.key === vm.initParams.stocktake.id; });
                        $timeout();
                    });      
                    
                    vm.buttons = [
						{
							primary: false, 
							cancel: true, 
							value: 'common.cancel_label',
							name: 'cancelButton',
							click: 'cancel',
							type: enums.buttonTypes.button
						}
					];
                    
                    vm.buttonStates = {
                    	cancelButton: {
                    		visible: true, 
							disabled: false
						}
					};
                    
                    vm.buttonMethods = {
                    	cancel: vm.cancel
					};
                };

                function onChange(option) {
                    console.log("Data Change");
                    vm.wnd.onDataChanged(option.key);
                    WindowFactory.closeWindow(vm.wnd);
                }
    
                function cancel() {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        WindowFactory.closeWindow(vm.wnd);
                    });
                }
            }
        ]
    });
