/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_treeview_1 = require("@progress/kendo-angular-treeview");
/**
 * A directive which encapsulates the retrieval of the child nodes when flat data is provided.
 */
var DropDownTreeFlatBindingDirective = /** @class */ (function (_super) {
    tslib_1.__extends(DropDownTreeFlatBindingDirective, _super);
    function DropDownTreeFlatBindingDirective(dropDownTree) {
        return _super.call(this, dropDownTree) || this;
    }
    tslib_1.__decorate([
        core_1.Input('kendoDropDownTreeFlatBinding'),
        tslib_1.__metadata("design:type", Array)
    ], DropDownTreeFlatBindingDirective.prototype, "nodes", void 0);
    tslib_1.__decorate([
        core_1.Input('valueField'),
        tslib_1.__metadata("design:type", String)
    ], DropDownTreeFlatBindingDirective.prototype, "idField", void 0);
    DropDownTreeFlatBindingDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoDropDownTreeFlatBinding]'
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_treeview_1.DataBoundComponent])
    ], DropDownTreeFlatBindingDirective);
    return DropDownTreeFlatBindingDirective;
}(kendo_angular_treeview_1.FlatDataBindingDirective));
exports.DropDownTreeFlatBindingDirective = DropDownTreeFlatBindingDirective;
