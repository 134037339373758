//import angular from 'angular';
import tmpl from './purchaseOrdersMerge.html';


angular.module('app.site').component('purchaseOrdersMerge',
        {
            bindings: {
                initParams: '=',
                buttonMethods: '=',
                buttonStates: '=',
                buttons: '=',
                closeOnSave: '=',
                wnd: '='
            },
            template: tmpl,
            controller: [
                '$scope', 'amtCommandQuerySvc', 'componentUrls', 'amtXlatSvc', 'WindowFactory', 'notifySvc', 'enums', 'confirmSvc', 
                function (
                    $scope, amtCommandQuerySvc, componentUrls, xlatSvc, WindowFactory, notifySvc, enums, confirmSvc
                ) {
                    var vm = this;

                    $scope.vm = this; // necessary for child directives to access what they need.

                    vm.form = {};
                                        
                    vm.apiUrls = componentUrls;

                    vm.status = "loading";

                    this.$onInit = function () {
                        vm.sourcePurchaseOrder = vm.initParams.purchaseOrder;
                        
                        vm.buttons = [
                            {
                                primary: true,
                                cancel: false,
                                value: "equipment.purchaseOrderMergeLabel",
                                name: 'mergeButton',
                                click: 'merge',
                                type: enums.buttonTypes.button
                            },
                            {
                                primary: false,
                                cancel: true,
                                value: "common.cancel_label",
                                name: 'cancelButton',
                                click: 'cancel',
                                type: enums.buttonTypes.button
                            }
                        ];

                        vm.buttonStates = {
                            mergeButton: {
                                visible: true,
                                disabled: true
                            },
                            cancelButton: {
                                visible: true,
                                disabled: false
                            }
                        };

                        vm.buttonMethods = {
                            merge: vm.merge,
                            cancel: vm.cancel
                        };

                        vm.status = "ready";

                        vm.wnd.onClose = $scope.onClose;
                    };

                    vm.cancel = function () {
                        closeWindow();
                    };

                    vm.merge = function () {
                        vm.wnd.processing = true;

                        confirmSvc.confirmMessage('equipment.confirmPurchaseOrderMerge_title', 'equipment.confirmPurchaseOrderMerge_text', vm.sourcePurchaseOrder.purchaseOrderNumber, vm.targetPurchaseOrder.name)
                            .then(
                                function () {
                                    var data = {
                                        sourcePurchaseOrderId: vm.sourcePurchaseOrder.id, // id because its a result from searchPurchaseOrders
                                        targetPurchaseOrderId: vm.targetPurchaseOrder.key // key because it's a result from listPurchaseOrders
                                    };

                                    amtCommandQuerySvc
                                        .post(vm.apiUrls.mergePurchaseOrders, data)
                                        .then(
                                            function () {
                                                notifySvc.success(xlatSvc.xlat("equipment.purchaseOrderMergeSuccessful"));
                                                if (vm.wnd.onDataChanged)
                                                    vm.wnd.onDataChanged();
                                                closeWindow();
                                            }
                                        );
                                }
                            )
                            .finally(function () {
                                vm.wnd.processing = false;
                            });
                    };

                    $scope.setForm = function () {
                        // add a watch to the dirty flag
                        $scope.$watch("vm.form.$dirty", function (newValue) {
                            vm.wnd.isDirty = newValue;
                        });
                    };
                    
                    function closeWindow() {
                        WindowFactory.closeWindow(vm.wnd);
                    }

                    $scope.actionClick = function (action) {
                        action.click();
                    };

                    $scope.onMerge = function () {
                        if (vm.merge !== undefined) {
                            vm.merge();
                        }
                    };

                    $scope.$watch('$ctrl.form.$dirty', function () {
                        updateButtonStates();
                    });

                    $scope.$watch('$ctrl.form.$invalid', function () {
                        updateButtonStates();
                    });

                    $scope.$watch('vm.wnd.processing', function () {
                        updateButtonStates();
                    });

                    function updateButtonStates() {
                        vm.buttonStates.mergeButton.disabled = vm.form.$invalid || vm.wnd.processing || !vm.form.$dirty;
                    }
                }
            ]
        });
