//import angular from 'angular';
import AdminUrls from '../../../adminUrls';


angular.module('app.admin').controller("sizeSearchCtrl", sizeSearchCtrl);

    // @ts-ignore
    sizeSearchCtrl.$inject = ["adminUrls", "$scope", "enums", "ocSecuritySvc", "amtXlatSvc", "$timeout", "WindowFactory", "ocConfigSvc", "componentUrls", "$element", "exportSvc", "helperSvc", "referenceDataUrls"];

    // ReSharper disable once InconsistentNaming
    function sizeSearchCtrl(adminUrls: AdminUrls, $scope, enums, ocSecuritySvc, amtXlatSvc, $timeout, WindowFactory, ocConfigSvc, componentUrls, $element, exportSvc, helperSvc, referenceDataUrls) {

        var vm = this;

        vm.processing = false;

        vm.adminUrls = adminUrls;
        vm.componentUrls = componentUrls;
        vm.referenceDataUrls = referenceDataUrls;

        vm.config = ocConfigSvc;

        vm.criteria = {
            filterValues: {               
                activeOptions: null,
                sizeIds: null,
                componentTypeIds: null
            }
        };

        vm.sizeFilterControl = {};

        vm.activeOptions = [{
            name: amtXlatSvc.xlat('common.yes_label'),
            key: true
        }, {
            name: amtXlatSvc.xlat('common.no_label'),
            key: false
        }];

        vm.status = enums.screenStatus.loading;

        vm.gridControl = {};
        vm.selectedItem = null;

        function init() {
            vm.loadDefaults();
        }

        vm.onSelectedItemChanged = function (items) {
            if (items != null && items.length === 1) {
                vm.selectedItem = items[0];
            } else {
                vm.selectedItem = null;
            }
        };

        vm.setHeaderHeight = function (height, offset) {
            vm.gridTop = height + offset;
        };

        vm.loadDefaults = function () {
            vm.defaults = {
                activeOptions: [vm.activeOptions[0]]
            };

            vm.status = enums.screenStatus.ready;
        };

        vm.export = function () {
            exportSvc.exportData(vm.referenceDataUrls.exportComponentSizes, vm.criteria, amtXlatSvc.xlat('size.searchTitle'));
        };

        $scope.editSize = function (item) {
            if (item) {
                WindowFactory.openItem({
                    component: 'add-edit-size',
                    caption: amtXlatSvc.xlat("size.editSize"),
                    initParams: { id: item.id },
                    width: 550,
                    onDataChangeHandler: function () {
                        vm.refreshGrid();
                        vm.sizeFilterControl.search();
                    },
                    windowRelatedRecordId: item.id
                });
            }
        };

        $scope.addSize = function () {
            WindowFactory.openItem({
                component: 'add-edit-size',
                caption: amtXlatSvc.xlat("size.addSize"),
                initParams: null,
                width: 550,
                onDataChangeHandler: function () {
                    vm.refreshGrid();
                    vm.sizeFilterControl.search();
                }
            });
        };

        vm.refreshGrid = function () {
            vm.gridControl.refresh();
        };

        init();
    }
