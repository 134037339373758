//import angular from 'angular';


    angular.module("app").factory("componentUrls", function () {
        var apiPath = baseUrl + 'api/';
        var componentController = 'equipment/component/';
        var equipmentController = 'equipment/common/';

        return {
            //search
            getComponents:apiPath + componentController + 'search',
            getDefaults: componentController + 'getDefaults', // default values for search
            searchExport: componentController + 'searchExport',
            getStatusesDefault: componentController + 'getStatusesDefault',

            // details
            getComponentDetails: componentController + 'getComponentDetails',
            updateComponentDetails: componentController + 'updateComponentDetails',
                    
            // purchaseOrder
            addModifyPurchaseOrder: equipmentController + 'addModifyPurchaseOrder',
            deletePurchaseOrder: equipmentController + 'deletePurchaseOrder',
            getPurchaseOrderSearchDefaults: equipmentController + 'getPurchaseOrderSearchDefaults',
            listPurchaseOrders: baseApiUrl + equipmentController + 'listPurchaseOrders',
            searchPurchaseOrders: baseApiUrl + equipmentController + 'searchPurchaseOrders',
            getPurchaseOrderSchedule: equipmentController + 'getPurchaseOrderSchedule',
            getPurchaseOrderDetails: equipmentController + 'getPurchaseOrderDetails',
            getPurchaseOrderStatuses: baseApiUrl + equipmentController + 'getPurchaseOrderStatuses',
            searchPurchaseOrderExport: equipmentController + 'searchPurchaseOrderExport',
            mergePurchaseOrders: equipmentController + 'mergePurchaseOrders',                    
            getPurchaseOrderMonthCounts: equipmentController + 'getPurchaseOrderMonthCounts',
            getPurchaseOrderSpecifications: baseApiUrl + equipmentController + 'getPurchaseOrderSpecifications',

            // status
            getComponentStatusDetails: componentController + 'getComponentStatusDetails',
            addUpdateMoveEvent: equipmentController + 'addUpdateMoveEvent',
            undoMoveEvents: equipmentController + 'undoMoveEvents',
            getComponentEventDetails: componentController + 'getComponentEventDetails',

            // reference data
            getClients: baseApiUrl + 'usersecurity/getClients',
            getSites: baseApiUrl + 'usersecurity/getSites',
            getPressureSensors: baseApiUrl + componentController + 'getPressureSensors',
            getComponentTypes: baseApiUrl + equipmentController + 'getEquipmentTypes',
            getComponentTypes_short: componentController + 'getComponentTypes', // This is passed to amtCommandQuery which *adds* the baseApiUrl already, so don't prepend this with baseApiUrl or it'll break (again!).
            getManufacturers: baseApiUrl + componentController + 'getManufacturers',
            getManufacturers_short: componentController + "getManufacturers",
            getSpecifications: baseApiUrl + componentController + 'getSpecifications',
            getLocations: baseApiUrl + componentController + 'getLocations',
            getSizes: baseApiUrl + componentController + 'getSizes',
            getOwners: baseApiUrl + componentController + 'getOwners',
            getStatuses: baseApiUrl + componentController + 'getStatuses',

            // filter values
            getFilterOptions: baseApiUrl + componentController + 'getFilterOptions',

            // visual inspection
            getVisualInspection: componentController + 'getVisualInspection',
            deleteVisualInspection: componentController + 'deleteVisualInspection',
            getVisualInspections: apiPath + componentController + 'getVisualInspections',
            saveVisualInspection: componentController + 'saveVisualInspection',
            exportVisualInspections: componentController + 'exportVisualInspections',

            // warranty claims
            searchMwWarrantyClaims: apiPath + componentController + 'searchMwWarrantyClaims',
            getMwWarrantyClaim: componentController + 'getMwWarrantyClaim',
            generateWarrantyClaimNumber: componentController + 'generateWarrantyClaimNumber',
            deleteMwWarrantyClaim: componentController + 'deleteMwWarrantyClaim',
            updateMwWarrantyClaim: componentController + 'updateMwWarrantyClaim',
            exportMwWarrantyClaim: componentController + 'exportMwWarrantyClaim',

            //BulkMove
            getBulkMoveComponents: componentController + 'getBulkMoveComponents',
            bulkMoveComponents: componentController + 'bulkMoveComponents',
            resolveBulkMoveStatusType: componentController + 'resolveBulkMoveStatusType',

            getPendingComponents: componentController + 'getPendingComponents',
            receiveUnknownComponents: componentController + 'receiveUnknownComponents',                    
            receiveKnownComponents: componentController + 'receiveKnownComponents',
            receivePendingComponents: componentController + 'receivePendingComponents',

            // maintenance session
            removeComponents: componentController + 'removeComponents',
            fitComponents: componentController + 'fitComponents'
        };
    });
