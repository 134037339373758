//import angular from 'angular';

angular.module('amtFramework.filter').directive('amtFilterItem', function () {

            return {
                require: '^amtFilter',
                restrict: 'A',
                link: function (scope: any, elem, attr, filterCtrl) {
                    scope.filterItem = { propertyName: scope.amtFilterItem };

                    filterCtrl.addFilterItem(scope.filterItem);
                }
            };
});
