//import angular from 'angular';


angular.module('app.admin')
        .controller('sysOrgClientSearchCtrl', [
            '$scope', 'sysOrgClientSvc', 'amtXlatSvc', 'ocSecuritySvc', 'ocConfigSvc',
            function (
                $scope, sysOrgClientSvc, amtXlatSvc, ocSecuritySvc, ocConfigSvc
            ) {
                var vm = this;

                vm.status = "loading";
                vm.gridControl = {};

                vm.svc = sysOrgClientSvc;
                vm.config = ocConfigSvc;

                vm.clientFilterControl = {};

                vm.results = [];

                vm.criteria = {
                    filterValues: {
                        clientIds: [],
                        active: false
                    }
                };

                vm.defaultActiveStates = vm.svc.activeStates.filter(function (state) {
                    return state.default === true;
                });

                vm.setHeaderHeight = function (height, offset) {
                    vm.gridTop = height + offset;
                };

                vm.onSearchComplete = function () {
                    vm.selectedItem = null;
                };

                vm.selectItem = function (items) {
                    if (items != null && items.length === 1) {
                        vm.selectedItem = items[0];
                    } else {
                        vm.selectedItem = null;
                    }
                };

                vm.export = function () {
                    vm.svc.export(vm.criteria);
                };

                vm.rowParseFunction = function (response) {
                    var items = response.result;

                    for (var r = 0; r < items.length; r++) {
                        try {
                            items[r].accessType = ocSecuritySvc.getAccessType(vm.svc.mainPermission, items[r].clientId, items[r].siteId);
                        } catch {
                            items[r].accessType = ocSecuritySvc.accessTypes.hide;
                        }
                    }

                    return response;
                };

                function init() {
                    vm.status = 'ready';
                }

                vm.refreshGrid = function () {
                    vm.gridControl.refresh();
                };

                vm.searchSucessHandler = function (result) {
                    vm.results = result;
                };

                vm.searchErrorHandler = function (error) {
                    vm.results = null;
                };

                vm.filter = function () {
                    vm.selectedItem = undefined;

                    vm.refreshGrid();
                };

                vm.create = function () {
                    vm.svc.create(function() {
                        vm.refreshGrid();
                        vm.clientFilterControl.search();
                    });
                };

                vm.openItem = function (item) {
                    vm.svc.openItem(
                        item,
                        function() {
                            vm.refreshGrid();
                            vm.clientFilterControl.search();
                        },
                        amtXlatSvc.xlat(item.accessType === 2 ? 'client.edit' : 'client.view', item.name)
                    );
                };

                $scope.openItem = vm.openItem;

                $scope.openSite = vm.svc.openSite;

                $scope.$on('filterSearch', function () {
                    vm.filter();
                });

                init();
            }
        ]);
