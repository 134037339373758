//import angular from 'angular';

    angular.module('app.directives')
        .directive('ocFormSummary', [function () {
            return {
                require: '^form',
                restrict: 'E',
                replace: false,
                scope: {
                    form: "=",
                    showRawName: "="
                },
                template: function () {
                    var template = '';

                    template += '<ul class="form-validation-list" ng-show="form.$invalid">';
                    template += '    <li class="form-validation-header">{{"common.missingMandatoryData" | xlat}}</li>';
                    template += '    <li ng-repeat="e in form.$error" ng-class="{$invalid: e[0].$invalid}">{{e[0].$name == "" ? "unnammed" : (showRawName == true || e[0].$name.indexOf(".") == -1 ? e[0].$name : (e[0].$name | xlat))}}</li>';
                    template += '</ul>';

                    return template;
                },
                controller: function() {
                    console.warn(
                        'oc-form-summary is not a production ready directive. Please remove before deploying.');
                }
            };
        }]);
