//import angular from 'angular';
import DbClass from '../../common/local-db';


    angular.module('app.fieldSurveys')
        .service('vehicleReceiveService', ['baseModuleService', 'amtXlatSvc', '$db', 'errorReporter', '$q', 'enums', '$timeout', 'fileManagement',
            function (baseModuleService, xlatSvc, $db: DbClass, errorReporter, $q, enums, $timeout, fileManagement) {

                var vm = this;
                var internalName = 'receiveVehicle';
                var tableName = 'receive';

                var pendingVehicles = false;

                vm = new baseModuleService(internalName, tableName);

                vm.trimForUpload = function (records) {
                    return records[0]; // By default, we pass an array. Vehicle Receive does things 1 at a time.
                };

                vm.getEndpoint = function (records) {
                    if (records.vehicles[0].conditionType === enums.receiveConditionTypes.pending) {
                        pendingVehicles = true;
                        return 'equipment/vehicle/receivePendingVehicles';
                    } else if (records.vehicles[0].isKnown) {
                        return 'equipment/vehicle/receiveKnownVehicles';
                    } else {
                        return 'equipment/vehicle/receiveUnknownVehicles';
                    }
                };

                // update the display details for the module
                vm.updateDetails = function () {

                    // reset to defaults
                    if (!vm.active) {
                        // the description and what not should be set custom in the module.
                        // This could be permissions or something
                        return $q.resolve();
                    }

                    vm.count = 0;
                    vm.intDateDiff = 0;
                    vm.intStatus = '';
                    vm.description = ' --- ';
                    vm.readOnly = (!vm.active);

                    return $db[tableName].where('pristine').notEqual(1).toArray().then(
                        function (records) {
                            var vehicles = records.filter(function (r) { return r.vehicles; });
                            return vm.updateDescription(vehicles.length).then(function () {
                                $timeout();
                            });
                        }).catch(function (error) {
                            vm.description = 'Error:' + errorReporter.exceptionMessage(error);
                        });
                };

                vm.processErrors = function (response, uploadErrors, postedRecord) {
                    if (response && response.length > 0) {
                        var message = xlatSvc.xlat(internalName + '.elementName') + ' ' +
                            (postedRecord.displayDescription || postedRecord.id) + ':';

                        uploadErrors.push({
                            msg: message,
                            subErrors: response
                        });
                    }
                };

                vm.uploadFiles = async function (record) {

                    if (record) {
                        for (let vehicle of record.vehicles || []) {
                            for (let attachment of vehicle.eventDetails.attachments || []) {
                                let file = await fileManagement.uploadFile(attachment.id, false);
                                // update the file id
                                if (file) attachment.id = file.id;
                            }
                        }
                    }
                };

                vm.postProcess = function (record) {

                    // if it was a pending vehicle (3rd party) it needs to be cleared from the pendingVehicles table
                    if (pendingVehicles) {
                        record.vehicles.forEach(function (c) {
                            if (c.id) {
                                $db.pendingVehicles.where('id').equals(c.id).delete();
                            }
                        });
                    }
                    // if this was a known vehicle it needs to be cleared from the pendingReceivals table                    
                    else if (record.vehicles[0].equipmentId) {
                        record.vehicles.forEach(function (c) {
                            if (c.equipmentId) {
                                $db.pendingReceivals.where('id').equals(c.equipmentId).delete();
                            }
                        });
                    }
                };

                return vm;
            }]);
