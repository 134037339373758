//import angular from 'angular';
import FileManagement from '../../../services/fileManagement';
import OcDateSvc from '../../../services/ocDateSvc';
import OcConfigSvc from '../../../services/ocConfigSvc';
import tmpl from './vehicleChangeStatusPopup.html';



type vehicleChangeStatusPopup_InitParams = {
    equipmentEventId: guid;
    type: StatusTypeName;
    mode: NewOrEditMode
    vehicle: {
        siteId: guid;
    }
}

class vehicleChangeStatusPopupCtrl implements ng.IController, IWindowController {

    wnd: IWindowObj;
    form: ng.IFormController;

    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: (button: string) => void }

    readonly: boolean = !this.ocSecuritySvc.isAuthorised('Security.Vehicles.ChangeStatus', AccessTypes.readWrite);

    equipmentEventId: guid;
    initParams: vehicleChangeStatusPopup_InitParams;
    siteId: guid;
    details: any = {};

    status: ScreenStatus = ScreenStatus.loading;
    mode: NewOrEditMode = NewOrEditMode.new;
    type: StatusTypeName;

    vehicle: any = {};

    acceptedFileTypes: string[];

    static $inject = ['$scope', '$rootScope', 'amtCommandQuerySvc', 'vehicleUrls', 'referenceDataUrls', 'amtXlatSvc', 'confirmSvc',
        'WindowFactory', 'notifySvc', '$timeout', 'errorReporter', 'ocDateSvc', 'componentUrls', 'ocSecuritySvc', 'amtConstants', 'ocConfigSvc', 'fileManagement'];

    constructor(private $scope: ng.IScope, private $rootScope: ng.IRootScopeService, private amtCommandQuerySvc: IAmtCommandQuerySvc, private vehicleUrls: IVehicleUrls,
        private referenceDataUrls: IReferenceDataUrls, private amtXlatSvc: IAmtXlatSvc, private confirmSvc: IConfirmSvc, private WindowFactory: IWindowFactory,
        private notifySvc: INotifySvc, private $timeout: ng.ITimeoutService, private errorReporter, private ocDateSvc: OcDateSvc, private componentUrls: IComponentUrls,
        private ocSecuritySvc: IOcSecuritySvc, private amtConstants: IAmtConstants, private ocConfigSvc: OcConfigSvc, private fileManagement: FileManagement) {

        this.$scope.$watchGroup([() => this.form.$invalid, () => this.form.$pristine, () => this.readonly, () => this.wnd.processing], () => {
            this.evaluateButtons();
        });
    }

    $onInit() {

        this.type = this.initParams.type;
        this.mode = this.initParams.mode;
        this.vehicle = this.initParams.vehicle;
        this.siteId = this.vehicle.siteId;
        this.equipmentEventId = this.initParams.equipmentEventId;

        this.wnd.onClose = () => { return this.closeWindow() };

        this.acceptedFileTypes = this.fileManagement.getAcceptedFileExtensions([FileType.document, FileType.pdf, FileType.text, FileType.spreadsheet, FileType.image]);

        if (this.mode === "edit") {
            this.loadDetails();
        } else {
            this.details = {
                eventDate: new Date(),
                comment: null,
                attachments: []
            };

            this.status = ScreenStatus.ready;
        }

        if (this.type)
            this.setCaption();

        this.WindowFactory.addButton(this, 'common.save_label', 'saveButton', () => this.save(), true);
        this.WindowFactory.addButton(this, 'common.cancel_label', 'cancelButton', () => this.closeWindow());
    }

    evaluateButtons() {
        this.buttonStates.saveButton.visible = !this.readonly;
        this.buttonStates.saveButton.disabled = this.form.$invalid || (this.form.$pristine && this.mode === NewOrEditMode.edit) || this.wnd.processing;
    }

    setPristine() {
        if (this.form)
            this.form.$setPristine();
    }

    async closeWindow() {
        try {
            await this.confirmSvc.confirmSaveChange(this.form.$dirty);
        } catch (error) {
            return; // they cancelled
        }

        this.setPristine();
        this.WindowFactory.closeWindow(this.wnd);
    }

    async loadDetails() {

        this.wnd.processing = true;

        let criteria = {
            equipmentEventId: this.equipmentEventId
        };

        try {
            let response = await this.amtCommandQuerySvc.post(this.componentUrls.getComponentEventDetails, criteria);

            if (!response)
                return;

            this.details = {
                comment: response.comments,
                eventDate: response.eventDate,
                hasDistanceMeter: response.hasDistanceMeter,
                statusTypeId: response.statusTypeId,
                isReceive: response.moveDetails.isReceive,
                attachments: response.attachments
            };

            if (!this.type) {
                this.type = (response.moveDetails && response.moveDetails.isReceive ? 'receive' : response.statusType.name.toLowerCase());
                this.setCaption();
            }

            if (response.readings) {
                let hoursReading = response.readings.find(r => r.type.toLowerCase() === ReadingTypeName.hours);

                if (hoursReading) {
                    this.details.hours = hoursReading.value;
                    this.details.hoursNewMeter = hoursReading.isNewMeter;
                }

                let distanceReading = response.readings.find(r => r.type.toLowerCase() === ReadingTypeName.distance);

                if (distanceReading) {
                    this.details.distance = distanceReading.value;
                    this.details.distanceNewMeter = distanceReading.isNewMeter;
                }
            }

            this.status = ScreenStatus.ready;

            this.$timeout(() => { this.setPristine() }, 100);

        } catch (error) {
            this.status = ScreenStatus.error;

            this.details = {
                errors: [error]
            };

            this.errorReporter.logError(error);
        } finally {
            this.wnd.processing = false;
        }
    }

    setCaption() {
        switch (this.type) {
            case StatusTypeName.receive:
                this.wnd.caption = this.amtXlatSvc.xlat("vehicle.editReceiveHeader", this.vehicle.serialNumber.formatted);
                break;
            case StatusTypeName.outOfService:
                this.wnd.caption = this.amtXlatSvc.xlat("vehicle.outOfServiceHeader", this.vehicle.serialNumber.formatted);
                break;

            case StatusTypeName.disposed:
                this.wnd.caption = this.amtXlatSvc.xlat("vehicle.disposeHeader", this.vehicle.serialNumber.formatted);
                break;

            case StatusTypeName.production:
                this.wnd.caption = this.amtXlatSvc.xlat("vehicle.returnToProductionHeader", this.vehicle.serialNumber.formatted);
                break;
        }
    }

    async save() {

        if (new Date(this.details.eventDate) > this.ocDateSvc.dateTimeAsUTC(this.ocDateSvc.now())) {        
            this.notifySvc.error(this.amtXlatSvc.xlat("equipment.eventDateCannotOccurInFuture"));
            return;
        }

        this.wnd.processing = true;

        try {

            for (let attachment of this.details.attachments || [])
                attachment.source = AttachmentType.equipmentEventAttachment;

            await this.fileManagement.processFileUploads(this.details.attachments);

            let criteria = {
                equipmentEventId: this.equipmentEventId,
                eventDate: this.ocDateSvc.removeLocalTimeZoneOffset(this.details.eventDate),
                comment: this.details.comment,
                isEdit: this.mode === NewOrEditMode.edit,
                siteId: this.siteId,
                equipmentId: this.vehicle.id,
                attachments: this.details.attachments,
                movementDetails: {
                    newStatusTypeName: this.type,
                    newStatusTypeId: this.details.statusTypeId
                },
                readingDetails: {
                    readings: []
                }
            };

            // hours
            if (this.details.hours != null) {
                criteria.readingDetails.readings.push({
                    readingEventTypeName: ReadingTypeName.hours,
                    isNewMeter: this.details.hoursNewMeter,
                    unitOfMeasureAbbreviation: this.ocConfigSvc.user.site.units.time.unitAbbreviation,
                    values: [{
                        value: this.details.hours,
                        sequence: 1
                    }]
                });
            }

            // distance
            if (this.details.distance != null) {
                criteria.readingDetails.readings.push({
                    readingEventTypeName: ReadingTypeName.distance,
                    isNewMeter: this.details.distanceNewMeter,
                    unitOfMeasureAbbreviation: this.ocConfigSvc.user.site.units.distance.unitAbbreviation,
                    values: [{
                        value: this.details.distance,
                        sequence: 1
                    }]
                });
            }

            await this.amtCommandQuerySvc.post(this.vehicleUrls.addUpdateMoveEvent, criteria);

            this.notifySvc.success(this.amtXlatSvc.xlat("component.messageComponentStatusUpdateSuccess"));

            if (this.wnd.onDataChanged)
                this.wnd.onDataChanged();

            this.$rootScope.$broadcast('filterSearch');

            this.setPristine();

            this.closeWindow();

        } catch (error) {
            this.errorReporter.logError(error);
        } finally {
            this.wnd.processing = false;
        }
    }
}

class vehicleChangeStatusPopupComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '<',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = vehicleChangeStatusPopupCtrl;
    public controllerAs = "vm";
}

angular.module('app.component').component('vehicleChangeStatusPopup', new vehicleChangeStatusPopupComponent());