/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Renders the footer content of the list. To define the footer template, nest an `<ng-template>` tag
 * with the `kendo<ComponentName>FooterTemplate` directive inside the component tag.
 *
 * - [Using `FooterTemplate` with the AutoComplete]({% slug templates_autocomplete %}#toc-footer-template)
 * - [Using `FooterTemplate` with the ComboBox]({% slug templates_combobox %}#toc-footer-template)
 * - [Using `FooterTemplate` with the MultiColumnComboBox]({% slug templates_multicolumncombobox %}#toc-footer-template)
 * - [Using `FooterTemplate` with the DropDownList]({% slug templates_ddl %}#toc-footer-template)
 * - [Using `FooterTemplate` with the DropDownTree]({% slug templates_ddt %}#toc-footer-template)
 * - [Using `FooterTemplate` with the MultiSelect]({% slug templates_multiselect %}#toc-footer-template)
 *
 * @example
 * ```ts
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-combobox [data]="listItems">
 *    <ng-template kendoComboBoxFooterTemplate>
 *      <h4>Footer template</h4>
 *    </ng-template>
 *  </kendo-combobox>
 * `
 * })
 * class AppComponent {
 *   public listItems: Array<string> = ["Item 1", "Item 2", "Item 3", "Item 4"];
 * }
 * ```
 */
var FooterTemplateDirective = /** @class */ (function () {
    function FooterTemplateDirective(templateRef) {
        this.templateRef = templateRef;
    }
    FooterTemplateDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoDropDownListFooterTemplate],[kendoComboBoxFooterTemplate],[kendoDropDownTreeFooterTemplate],[kendoMultiColumnComboBoxFooterTemplate],[kendoAutoCompleteFooterTemplate],[kendoMultiSelectFooterTemplate],[kendoMultiSelectTreeFooterTemplate]'
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.TemplateRef])
    ], FooterTemplateDirective);
    return FooterTemplateDirective;
}());
exports.FooterTemplateDirective = FooterTemplateDirective;
