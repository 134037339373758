//import angular from 'angular';

    angular.module('app.directives')
        .directive('ocSpring', ['$timeout', '$window', function ($timeout, $window) {
            return {
                restrict: 'A',
                scope: {
                    onSized: '&'
                },
                link: function (scope: any, elm, attrs, parent) {

                    var win = angular.element($window);

                    win.bind('resize', function () {
                        scope.$apply();
                    });                 

                    $timeout(function () {
                        // watching a string, to ensure we pickup any changes, 
                        scope.$watch(function () {
                            return "" + elm[0].offsetTop + "," + elm[0].offsetHeight + elm[0].offsetLeft + "," + elm[0].offsetWidth
                        }, function (val) {
                            scope.onSized({
                                height: elm[0].offsetHeight,
                                width: elm[0].offsetWidth,
                                offsetLeft: elm[0].offsetLeft,
                                offsetTop: elm[0].offsetTop                                
                            });
                        });
                    });
                }
            };
        }]);
