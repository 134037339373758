//import angular from 'angular';


    angular.module("app")
        .factory("backupStockLevelUrls", function () {

            var apiPath = baseUrl + 'api/';
            var controllerPath = 'admin/backupStockLevel/';
            var componentController = 'equipment/component/';
            var equipmentControllerPath = 'equipment/common/';

            return {
                //search
                search: apiPath + controllerPath + 'search',
                getSearchDefaults: controllerPath + 'getSearchDefaults', // default values for search
                searchExport: controllerPath + 'searchExport',
                // details
                getDetails: controllerPath + 'getDetails',
                updateDetails: controllerPath + 'updateDetails',

                // reference data
                getClients: baseApiUrl + 'systemhierarchy/getClients',
                getSites: baseApiUrl + 'systemhierarchy/getSites',
                getLifeTypes: apiPath + controllerPath + 'getLifeTypes',
                getComponentTypes: baseApiUrl + controllerPath + 'getComponentTypes',
                getSizes: baseApiUrl + controllerPath + 'getSizes',
                getConstructions: apiPath + controllerPath + 'getConstructions',
                //getSpecifications: baseApiUrl + equipmentControllerPath + 'getSpecifications',
                getSpecifications: baseApiUrl + componentController + 'getSpecifications',

                //Updates
                modifyBackupStockLevel: controllerPath + 'modify',
                addBackupStockLevel: controllerPath + 'add',

                //Delete
                deleteBackupStockLevel: controllerPath + 'delete'
            };
        });
