import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Widget } from '../../service/Interface/SiteDashboard.interface';
import { PressureKPIWidget } from '../../service/Interface/Widget.interface';
import { PressureKpiGauge, PressureKpiUi } from '../../service/Interface/WidgetConstant.interface';
import { WidgetRxjsService } from '../../service/rxjs/WidgetRxjs.service';
import { WidgetConstant } from '../../service/widgetHelper/WidgetConstant';
import { Subscription } from 'rxjs';

@Component({
    selector: 'widget-pressure-kpi',
    templateUrl: './pressure-kpi.component.html',
    styleUrls: ['./../../site-dashboard.component.scss']
})

//Completed
export class SiteDashboardPressureKPIComponent implements OnInit, OnDestroy {
    public siteDashboardWidgetId: string;
    public gaugeElement: PressureKpiGauge;
    public ui: PressureKpiUi;
    public resultSubscription: Subscription;
    public result: PressureKPIWidget = {
        siteDashboardWidgetId: '',
        hasTargetKpi: false,
        receivedResult: false,
        targetKpiValue: 0,
        currentKpiValue: 0,
        maxKpiValue: 0,
        minorUnit: 0,
        majorUnit: 0
    };

    constructor(private widgetRxjs: WidgetRxjsService, private widgetConstant: WidgetConstant) {
        this.gaugeElement = this.widgetConstant.componentElement.pressureKpiGauge;
        this.ui = this.widgetConstant.getPressureKpiWidgetUi();
    }

    //Get widget detail from Site Dashboard
    @Input() set getWidgetDetail(widget: Widget) {
        this.siteDashboardWidgetId = widget.siteDashboardWidgetId;
    }

    ngOnInit() {
        this.getPressureKpiWidgetResult();
    }

    ngOnDestroy() {
        this.resultSubscription.unsubscribe();
    }

    //Get Selected Pressure Kpi Widget Result
    getPressureKpiWidgetResult() {
        this.resultSubscription = this.widgetRxjs.pressureKPIWidget.subscribe(
            widgets => {
                let hasPressureKpiComponents = widgets.length > 0;

                if (hasPressureKpiComponents)
                {
                    widgets.forEach(widget => {
                        if (widget.siteDashboardWidgetId === this.siteDashboardWidgetId) {
                            this.result = widget;
                        }
                    });
                }
            });
    }

}