/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var column_menu_item_base_1 = require("./column-menu-item-base");
/* tslint:disable:max-line-length */
/**
 * Represents the component for editing column filters in the Grid that can be placed
 * inside a [ColumnMenuTemplate]({% slug api_grid_columnmenutemplatedirective %}) directive.
 *
 * > You have to set the [ColumnMenuService]({% slug api_grid_columnmenuservice %}) that is passed by
 * > the template to the service input of the `kendo-grid-columnmenu-filter` component.
 *
 * @example
 * ```ts-preview
 *
 * _@Component({
 *     selector: 'my-app',
 *     template: `
 *       <kendo-grid [kendoGridBinding]="data" [columnMenu]="true">
 *          <ng-template kendoGridColumnMenuTemplate let-service="service">
 *              <kendo-grid-columnmenu-filter [service]="service">
 *              </kendo-grid-columnmenu-filter>
 *          </ng-template>
 *          <kendo-grid-column field="Field1"></kendo-grid-column>
 *          <kendo-grid-column field="Field2"></kendo-grid-column>
 *       </kendo-grid>
 *     `
 * })
 *
 * class AppComponent {
 *   public data: any[] = [{ Field1: 'Foo', Field2: 'Bar' }];
 * }
 *
 * ```
 */
var ColumnMenuFilterComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ColumnMenuFilterComponent, _super);
    function ColumnMenuFilterComponent(localization, hostElement) {
        var _this = _super.call(this) || this;
        _this.localization = localization;
        _this.hostElement = hostElement;
        /**
         * Fires when the content is expanded.
         */
        _this.expand = new core_1.EventEmitter();
        /**
         * Fires when the content is collapsed.
         */
        _this.collapse = new core_1.EventEmitter();
        /**
         * Specifies if the content is expanded.
         */
        _this.expanded = false;
        /**
         * @hidden
         */
        _this.isLast = false;
        /**
         * @hidden
         */
        _this.actionsClass = 'k-columnmenu-actions';
        return _this;
    }
    /**
     * @hidden
     */
    ColumnMenuFilterComponent.prototype.onCollapse = function () {
        this.expanded = false;
        if (this.isLast) {
            this.service.menuTabbingService.lastFocusable = this.hostElement.nativeElement.querySelector('.k-columnmenu-item');
        }
        this.collapse.emit();
    };
    /**
     * @hidden
     */
    ColumnMenuFilterComponent.prototype.onExpand = function () {
        this.expanded = true;
        this.expand.emit();
    };
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ColumnMenuFilterComponent.prototype, "expand", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ColumnMenuFilterComponent.prototype, "collapse", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], ColumnMenuFilterComponent.prototype, "expanded", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], ColumnMenuFilterComponent.prototype, "isLast", void 0);
    ColumnMenuFilterComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-grid-columnmenu-filter',
            template: "\n        <kendo-grid-columnmenu-item\n            [text]=\"localization.get('filter')\"\n            icon=\"filter\"\n            [expanded]=\"expanded\"\n            (collapse)=\"onCollapse()\"\n            (expand)=\"onExpand()\">\n            <ng-template kendoGridColumnMenuItemContentTemplate>\n                <kendo-grid-filter-menu-container\n                    [column]=\"service.column\"\n                    [menuTabbingService]=\"service.menuTabbingService\"\n                    [filter]=\"service.filter\"\n                    [actionsClass]=\"actionsClass\"\n                    [isLast]=\"isLast\"\n                    [isExpanded]=\"expanded\"\n                    (keydown.shift.tab)=\"$event.stopImmediatePropagation()\"\n                    (close)=\"close()\">\n                </kendo-grid-filter-menu-container>\n            </ng-template>\n        </kendo-grid-columnmenu-item>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService,
            core_1.ElementRef])
    ], ColumnMenuFilterComponent);
    return ColumnMenuFilterComponent;
}(column_menu_item_base_1.ColumnMenuItemBase));
exports.ColumnMenuFilterComponent = ColumnMenuFilterComponent;
