//import angular from 'angular';
import BrowserSvc from '../../../services/browserSvc';
import AdminUrls from '../../admin/adminUrls';
import tmpl from './changeSite.html';


angular.module('app.common').component('changeSite', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['$scope', 'amtCommandQuerySvc', 'adminUrls', 'WindowFactory', 'confirmSvc', 'ocConfigSvc', 'dataBroker', 'errorReporter', 'enums', 'ocCacheSvc', 'browserSvc',
            function ($scope, amtCommandQuerySvc, adminUrls: AdminUrls, WindowFactory: IWindowFactory, confirmSvc, ocConfigSvc, dataBroker, errorReporter, enums, ocCacheSvc, browserSvc: BrowserSvc) {
                var vm = this;
                $scope.vm = this;

                vm.adminUrls = adminUrls;

                vm.config = ocConfigSvc;
                vm.isMobile = browserSvc.isMobile;

                vm.form = {};

                vm.clientId = (vm.config.user !== null ? vm.config.user.client.id : null);
                vm.siteId = (vm.config.user !== null ? vm.config.user.site.id : null);

                // we want the site to load up front 
                vm.client = (!!vm.config.user && !!vm.config.user.client && !!vm.config.user.client.id ? { name: vm.config.user.client.name, key: vm.config.user.client.id } : null);

                this.$onInit = function () {
                    if (!vm.wnd.onClose) {
                        vm.wnd.onClose = vm.onClose;
                    }
                    if (WindowFactory.hasDirty()) {
                        confirmSvc.confirmMessage('shared.confirmChangeSite', 'shared.confirmChangeSiteMsg').then(function () {
                            console.log("OK to change");
                        }).catch(function () {
                            vm.onClose();
                        });
                    }

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: 'common.switchSite',
                            name: 'switchSiteButton',
                            click: 'switchSite',
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        switchSiteButton: {
                            visible: true,
                            disabled: true
                        }
                    };

                    vm.buttonMethods = {
                        switchSite: vm.switchSite
                    };
                };

                vm.switchSite = function () {

                    if (!vm.form.$invalid && !vm.form.$pristine) {

                        // prevent close during site switch to prevent data being submitted
                        vm.wnd.canClose = false;

                        vm.wnd.processing = true;

                        console.log("switching sites on the server");

                        return amtCommandQuerySvc.post(vm.adminUrls.switchSite, { clientId: vm.clientId, siteId: vm.siteId }).then(function (response) {

                            if (response && response.status === enums.httpUpdateStatus.success) {
                                
                                ocCacheSvc.clear();

                                // get the session data
                                return dataBroker.getSession('refresh', vm.siteId).then(function (response) {
                                    
                                    vm.config.updateUser(response);                                    

                                    if (vm.wnd.onDataChanged) {
                                        vm.wnd.onDataChanged(response);
                                    }

                                    if (vm.isMobile) {
                                        closeWindow();
                                    }
                                });
                            }
                            else {
                                throw new Error("bad response from change site.");
                            }
                        }).catch(function (error) {
                            errorReporter.logError(error, "ChangeSite");                            
                            WindowFactory.alert('common.changeSite', ['common.ok_label'], 'exception.generic_error');
                        });
                    }
                };

                var deregisterDataLoadFail = $scope.$on('dataLoadFail', function (event, error) {
                    if (error.status === -1) {
                        if (!vm.alert) {
                            vm.alert = true;
                            WindowFactory.alert('common.changeSite', ['common.ok_label'], 'mobileCommon.noSiteChangeOffline').then(function () {
                                vm.onClose();
                            });
                        }
                    }
                });

                $scope.$on('$destroy', function () {
                    deregisterDataLoadFail();
                });

                function closeWindow() {
                    WindowFactory.closeWindow(vm.wnd);
                }

                vm.onClose = function () {
                    closeWindow();
                };

                $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing'], function () {
                    vm.buttonStates.switchSiteButton.disabled = vm.form.$invalid || vm.form.$pristine || vm.wnd.processing;
                });
            }
        ]
    });
