//import angular from 'angular';

angular.module("app.mobile").controller('errorCtrl', [
                "$location",
                
        function ($location) {
            var vm = this;
            vm.continue = function () {
                $location.url("/mobile/landing");
            };
            return vm;
    }]);
