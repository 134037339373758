/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
/**
 * @hidden
 */
var FlatColorPickerActionButtonsComponent = /** @class */ (function () {
    function FlatColorPickerActionButtonsComponent(localizationService) {
        this.localizationService = localizationService;
        this.hostClasses = true;
        this.actionButtonClick = new core_1.EventEmitter();
    }
    FlatColorPickerActionButtonsComponent.prototype.getText = function (text) {
        return this.localizationService.get(text);
    };
    FlatColorPickerActionButtonsComponent.prototype.onActionButtonClick = function (type, ev) {
        var args = {
            target: type,
            originalEvent: ev
        };
        this.actionButtonClick.emit(args);
    };
    tslib_1.__decorate([
        core_1.HostBinding('class.k-coloreditor-footer'),
        core_1.HostBinding('class.k-actions'),
        core_1.HostBinding('class.k-hstack'),
        tslib_1.__metadata("design:type", Boolean)
    ], FlatColorPickerActionButtonsComponent.prototype, "hostClasses", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], FlatColorPickerActionButtonsComponent.prototype, "actionButtonClick", void 0);
    tslib_1.__decorate([
        core_1.ViewChild('last', { read: core_1.ElementRef, static: false }),
        tslib_1.__metadata("design:type", core_1.ElementRef)
    ], FlatColorPickerActionButtonsComponent.prototype, "lastButton", void 0);
    FlatColorPickerActionButtonsComponent = tslib_1.__decorate([
        core_1.Component({
            selector: '[kendoFlatColorPickerActionButtons]',
            template: "\n        <button\n            class=\"k-coloreditor-cancel k-button k-button-md k-button-solid k-button-solid-base\"\n            [attr.title]=\"getText('cancelButton')\"\n            (click)=\"onActionButtonClick('cancel', $event)\"\n            type=\"button\"\n        >{{getText('cancelButton')}}</button>\n        <button #last\n            class=\"k-coloreditor-apply k-button k-button-md k-button-solid k-button-solid-primary\"\n            [attr.title]=\"getText('applyButton')\"\n            (click)=\"onActionButtonClick('apply', $event)\"\n            type=\"button\"\n        >{{getText('applyButton')}}</button>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService])
    ], FlatColorPickerActionButtonsComponent);
    return FlatColorPickerActionButtonsComponent;
}());
exports.FlatColorPickerActionButtonsComponent = FlatColorPickerActionButtonsComponent;
