/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var avatar_component_1 = require("./avatar/avatar.component");
var exportedModules = [
    avatar_component_1.AvatarComponent
];
var declarations = exportedModules.slice();
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the Avatar component.
 */
var AvatarModule = /** @class */ (function () {
    function AvatarModule() {
    }
    AvatarModule = tslib_1.__decorate([
        core_1.NgModule({
            declarations: [declarations],
            exports: [exportedModules],
            imports: [common_1.CommonModule]
        })
    ], AvatarModule);
    return AvatarModule;
}());
exports.AvatarModule = AvatarModule;
