//import angular from 'angular';


    angular.module('app')
        .factory('maintenanceSessionFactory', [
            'amtXlatSvc', 'amtCommandQuerySvc', 'enums', 'confirmSvc', 'exportSvc', 'notifySvc',
            function (amtXlatSvc, amtCommandQuerySvc, enums, confirmSvc, exportSvc, notifySvc) {

                // static functions
                this.export = function (criteria) {
                    exportSvc.exportData('equipment/vehicle/exportMaintenanceSessions', criteria, amtXlatSvc.xlat('maintenanceSession.searchExportFilename'));
                };
                
                this.startOfMonth = function () {
                    var now = new Date();

                    // return the first of the current month
                    return new Date(now.getFullYear(), now.getMonth(), 1);
                };
                 
                // static data
                var searchCriteria = {
                    filterValues: {
                        dateFrom: undefined,
                        dateTo: undefined,
                        fleetIds: [],
                        specificationIds: [],
                        serialNumber: "",
                        statusIds: [],
                        defaultToAllSitesWhenNoSystemHierarchyFilter: true
                    },
                    criteria: {
                        localClientDateTime: undefined
                    }
                };

                // public interface
                return {
                    export: this.export,
                    searchCriteria: searchCriteria
                };
            }
        ]
    );

