/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Renders the content of each node in the DropDownTree. To define a node template, nest an `<ng-template>` tag
 * with the `kendoDropDownTreeNodeTemplate` directive inside the `<kendo-dropdowntree>` tag.
 *
 * The current data item and hierarchical index are available as context variables:
 *
 * - `let-dataItem` (`any`) - The current data item. Available as implicit context variable.
 * - `let-index="index"` (`string`) - The current item hierarchical index.
 */
var NodeTemplateDirective = /** @class */ (function () {
    function NodeTemplateDirective(templateRef) {
        this.templateRef = templateRef;
    }
    NodeTemplateDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoDropDownTreeNodeTemplate], [kendoMultiSelectTreeNodeTemplate]'
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.TemplateRef])
    ], NodeTemplateDirective);
    return NodeTemplateDirective;
}());
exports.NodeTemplateDirective = NodeTemplateDirective;
