import { Injectable } from '@angular/core';
import { PressureCheckVehiceWidgetResult } from '../Interface/Widget.interface';
import { FilterConstant } from '../Interface/WidgetConstant.interface';
import { WidgetConstant } from '../widgetHelper/WidgetConstant';

@Injectable({
    providedIn: 'root'
})

export class PressureCheckVehicleWidgetHelper {

    constructor(private constant: WidgetConstant) { }

    /**Set Sum pressureCheckCount = pendingMaintenanceSessionCount + fieldSuryveyCount per month*/
    public setPressureCheckCount(vehicles: PressureCheckVehiceWidgetResult[]) {
        let newVehiclesList: PressureCheckVehiceWidgetResult[] = []
        vehicles.forEach(vehicle => {
            //Check the value
            let pmsCount = vehicle.pendingMaintenanceSessionCount;
            let fsCount = vehicle.fieldSuryveyCount;
            let sum = pmsCount + fsCount;

            vehicle.pendingMaintenanceSessionCount = pmsCount;
            vehicle.fieldSuryveyCount = fsCount;
            vehicle.pressureCheckCount = sum;
            newVehiclesList.push(vehicle);
        });

        return newVehiclesList;
    }

    /**Get the all vehicle applciation: (Used: Pressure Check Per Vehicle Widget)*/
    public setPressureCheckVehicleApplications(response: PressureCheckVehiceWidgetResult[]) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        let vehicleApplicationList: Array<string> = [];
        let defaultVehicleApplicationList: Array<string> = [filterConstant.allVehicleApplication];

        response.forEach(res => {
            if (!vehicleApplicationList.includes(res.applicationName)) {
                vehicleApplicationList.push(res.applicationName)
            }
        });
        vehicleApplicationList.sort();

        return defaultVehicleApplicationList.concat(vehicleApplicationList);
    }

    /**Set the element of specification list by combining manufactuerName, applicationName, modelName*/
    setPressureCheckVehicleSpecificationDescriptionElement(manufacturerName: string, applicationName: string, modelName: string) {
        let description = manufacturerName + " " + applicationName + " " + modelName;
        return description;
    }

    //Get the lists of vehicle Specification by using default vehicle lists
    getAllPressureCheckVehicleSpecification(defaultVehicles: any) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        let vehicleSpecificationList = [filterConstant.allVehicleSpecification]

        defaultVehicles.forEach(vehicle => {
            let description = this.setPressureCheckVehicleSpecificationDescriptionElement(vehicle.manufacturerName, vehicle.applicationName, vehicle.modelName);
            if (!vehicleSpecificationList.includes(description)) {
                vehicleSpecificationList.push(description);
            }
        })
        vehicleSpecificationList.sort();
        return vehicleSpecificationList;
    }

    /**Get only the lists of vehicle speicfications by filtering out the vehicle application*/
    getPressureCheckVehicleSpeicifcationByApplicationName(defaultVehicles: any, selectedVehicles: any, selectedVehicleApplication) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();

        if (selectedVehicleApplication === filterConstant.allVehicleApplication) {
            return this.getAllPressureCheckVehicleSpecification(defaultVehicles);
        }
        else {
            let vehicleSpecificationList = [filterConstant.allVehicleSpecification];
            selectedVehicles.forEach(vehicle => {
                let description = this.setPressureCheckVehicleSpecificationDescriptionElement(vehicle.manufacturerName, vehicle.applicationName, vehicle.modelName);
                if (!vehicleSpecificationList.includes(description)) {
                    vehicleSpecificationList.push(description);
                }
            });

            vehicleSpecificationList.sort();
            return vehicleSpecificationList;
        }
    }


}