/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_treeview_1 = require("@progress/kendo-angular-treeview");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var util_1 = require("../../common/util");
var base_check_directive_1 = require("./base-check.directive");
/**
 * @hidden
 *
 * A directive which manages the in-memory checked state of the MultiSelectTree nodes.
 */
var CheckDirective = /** @class */ (function (_super) {
    tslib_1.__extends(CheckDirective, _super);
    function CheckDirective(treeView) {
        var _this = _super.call(this) || this;
        _this.treeView = treeView;
        /**
         * Fires when the `checkedItems` collection was updated.
         */
        _this.checkedItemsChange = new core_1.EventEmitter();
        /**
         * Holds a Set with just the checked item keys.
         *
         * Should be updated each time the `checkedItems` value or content is changed.
         * Can be used for efficient look-up of whether an item is checked or not (O(1) access time).
         */
        _this.checkedKeys = new Set();
        _this.subscriptions = new rxjs_1.Subscription();
        _this.subscriptions.add(_this.treeView.checkedChange
            .subscribe(_this.handleCheckedChange.bind(_this)));
        _this.treeView.isChecked = _this.getCheckedState.bind(_this);
        return _this;
    }
    CheckDirective.prototype.ngOnChanges = function (changes) {
        if (util_1.isPresent(changes.checkable)) {
            this.toggleCheckOnClick();
        }
        if (util_1.isPresent(changes.checkedItems)) {
            this.updateItems();
        }
    };
    CheckDirective.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
        this.unsubscribeClick();
    };
    CheckDirective.prototype.getCheckedState = function (dataItem, index) {
        if (this.isItemChecked({ dataItem: dataItem, index: index })) {
            return 'checked';
        }
        else if (this.checkable.checkChildren && this.isItemIndeterminate(this.treeView.itemLookup(index))) {
            return 'indeterminate';
        }
        else {
            return 'none';
        }
    };
    CheckDirective.prototype.handleCheckedChange = function (node) {
        this.checkNode(node);
        // parents should be checked if `checkChildren` is set to `true` (single config option for both)
        var checkParents = this.checkable.checkChildren;
        if (checkParents) {
            this.checkParents(node.parent);
        }
        this.checkedItemsChange.emit(this.checkedItems.slice());
    };
    CheckDirective.prototype.toggleCheckOnClick = function () {
        var _this = this;
        this.unsubscribeClick();
        if (this.checkable.checkOnClick) {
            this.clickSubscription = this.treeView.nodeClick
                .pipe(operators_1.filter(function (event) { return event.type === 'click'; }))
                .subscribe(function (event) {
                var lookup = _this.treeView.itemLookup(event.item.index);
                _this.handleCheckedChange(lookup);
            });
        }
    };
    CheckDirective.prototype.unsubscribeClick = function () {
        if (this.clickSubscription) {
            this.clickSubscription.unsubscribe();
            this.clickSubscription = null;
        }
    };
    CheckDirective.prototype.checkNode = function (lookup) {
        var _this = this;
        if (this.treeView.isDisabled(lookup.item.dataItem, lookup.item.index)) {
            return;
        }
        var target = lookup.item;
        var pendingCheck = [target];
        // TODO: extract in a separate `checkChildren` method?
        if (this.checkable.checkChildren) {
            var filter_1 = function (item) {
                return _this.treeView.isVisible(item.dataItem, item.index) &&
                    !_this.treeView.isDisabled(item.dataItem, item.index);
            };
            util_1.fetchDescendentNodes(lookup, filter_1)
                .forEach(function (lookup) { return pendingCheck.push(lookup.item); });
        }
        var shouldCheck = !this.isItemChecked(target);
        pendingCheck.forEach(function (item) {
            if (shouldCheck) {
                _this.addItem(item);
            }
            else {
                _this.removeItem(item);
            }
        });
    };
    CheckDirective.prototype.checkParents = function (parent) {
        var _this = this;
        var currentParent = parent;
        while (currentParent) {
            var allChildrenSelected = currentParent.children.every(function (item) { return _this.isItemChecked(item); });
            if (allChildrenSelected) {
                this.addItem(currentParent.item);
            }
            else {
                this.removeItem(currentParent.item);
            }
            currentParent = currentParent.parent;
        }
    };
    CheckDirective.prototype.isItemIndeterminate = function (lookup) {
        var children = lookup.children;
        if (!Array.isArray(children) || children.length === 0) {
            return false;
        }
        var index = 0;
        var child = children[index];
        while (util_1.isPresent(child)) {
            if (this.isItemChecked(child.item) || this.isItemIndeterminate(child)) {
                return true;
            }
            index += 1;
            child = children[index];
        }
        return false;
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], CheckDirective.prototype, "checkable", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], CheckDirective.prototype, "valueField", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Array)
    ], CheckDirective.prototype, "checkedItems", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], CheckDirective.prototype, "checkedItemsChange", void 0);
    CheckDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoMultiSelectTreeCheckable]'
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_treeview_1.TreeViewComponent])
    ], CheckDirective);
    return CheckDirective;
}(base_check_directive_1.BaseCheckDirective));
exports.CheckDirective = CheckDirective;
