//import angular from 'angular';
import FileManagement from '../../../../../services/fileManagement';
import OcConfigSvc from '../../../../../services/ocConfigSvc';
import tmpl from './sysOrgClientDetails.html';


class ClientDetailsCtrl implements ng.IController, IWindowController {

    form: ng.IFormController;
    wnd: IWindowObj;

    status = ScreenStatus.loading;

    initParams: any = {};

    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: (button: string) => void }

    showSaveButton: boolean;
    error: any;

    item: any;
    currencyType: any;
    country: any;

    hasNewWebLogoFile = false;
    hasNewReportLogoFile = false;

    acceptedLogoFileTypes: string[];

    static $inject = ['$scope', 'sysOrgClientSvc', 'WindowFactory', 'confirmSvc', '$timeout',
        'notifySvc', 'amtXlatSvc', 'fileManagement', 'ocConfigSvc', 'errorReporter', 'amtCommandQuerySvc'];

    constructor(private $scope: ng.IScope, private sysOrgClientSvc: any, private WindowFactory: IWindowFactory,
        private confirmSvc: IConfirmSvc, private $timeout: ng.ITimeoutService, private notifySvc: INotifySvc,
        private amtXlatSvc: IAmtXlatSvc, private fileManagement: FileManagement, private ocConfigSvc: OcConfigSvc,
        private errorReporter: IErrorReporter, private amtCommandQuerySvc: IAmtCommandQuerySvc) {

        this.$scope.$watch(() => this.form.$dirty, () => this.wnd.isDirty = this.form.$dirty);

        this.$scope.$watchGroup([() => this.form.$pristine, () => this.form.$invalid, () => this.wnd.processing], () => this.determineSaveButtonState());
    }

    $onInit() {

        if (this.wnd)
            this.wnd.onClose = () => this.closeWindow();

        this.WindowFactory.addButton(this, 'common.save_label', 'saveAndClose', () => this.save(true), true);
        this.WindowFactory.addButton(this, 'common.saveAndAddAnother_label', 'saveAndNew', () => this.save(false));

        this.acceptedLogoFileTypes = this.fileManagement.getAcceptedFileExtensions([FileType.image]);

        this.loadDetails();
    }

    onActiveChange() {
        if (this.item.active)
            this.item.displayInSystem = true;
    }

    async loadDetails() {

        if (this.initParams.id) {

            this.wnd.processing = true;

            try {
                // load existing
                let result = await this.sysOrgClientSvc.getAsync(this.initParams.id);

                this.item = result;
                this.item.mode = 'edit'; // loaded from the server, so it's edit mode                

            } catch (error) {
                this.errorReporter.logError(error);
            } finally {
                this.wnd.processing = false;
            }

        } else {
            // new
            this.item = this.initParams;
        }

        if (!this.item.webLogo)
            this.item.webLogo = this.fileManagement.emptyFile();

        if (!this.item.reportLogo)
            this.item.reportLogo = this.fileManagement.emptyFile();

        this.status = ScreenStatus.ready;
    }

    async save(close: boolean) {

        this.wnd.processing = true;

        try {

            if (this.hasNewWebLogoFile)
                this.item.webLogo.dataUrl = await this.fileManagement.blobToString(this.item.webLogo.data);

            if (this.hasNewReportLogoFile)
                this.item.reportLogo.dataUrl = await this.fileManagement.blobToString(this.item.reportLogo.data);

            let criteria = {
                id: this.item.id,
                name: this.item.name,
                active: this.item.active,
                currencyTypeId: this.item.currencyTypeId,
                displayInSystem: this.item.displayInSystem,
                webLogo: this.hasNewWebLogoFile ? this.item.webLogo : null,
                webLogoBackgroundValue: this.item.webLogoBackgroundValue,
                reportLogo: this.hasNewReportLogoFile ? this.item.reportLogo : null,
                countryId: this.item.country,
                isSyncTyrematics: this.item.isSyncTyrematics
            };

            // save the data
            await this.amtCommandQuerySvc.post(this.sysOrgClientSvc.saveUrl, criteria);

            if (this.wnd.onDataChanged)
                this.wnd.onDataChanged();

            this.notifySvc.success(this.amtXlatSvc.xlat('client.save_success'));

            if (close) {
                this.form.$setPristine();
                this.closeWindow()
            } else {

                this.item = await this.sysOrgClientSvc.reset();

                this.currencyType = null;
                this.country = null;
                this.form.$setPristine();
            }

        } catch (error) {
            this.errorReporter.logError(error);
        } finally {
            this.wnd.processing = false;
        }
    }

    async closeWindow() {

        try {
            await this.confirmSvc.confirmSaveChange(this.form.$dirty);
        } catch (error) {
            return; // cancelled
        }

        this.form.$setPristine();

        this.WindowFactory.closeWindow(this.wnd);
    }

    async showHideIntegration() {
        try {
            if (this.item.isSyncTyrematics)
                await this.confirmSvc.confirmMessage(this.amtXlatSvc.xlat('component.confirmWizardAbort_Title'), 'dataIntegration.confirmFleetSyncEnable', this.item.name);
            else
                await this.confirmSvc.confirmMessage(this.amtXlatSvc.xlat('component.confirmWizardAbort_Title'), 'dataIntegration.confirmFleetSyncDisable', this.item.name);
        } catch {
            this.item.isSyncTyrematics = !this.item.isSyncTyrematics;
            return false; // they cancelled
        }
    }

    onWebLogoSelected() {
        this.$timeout(() => {
            this.hasNewWebLogoFile = true;
            this.item.webLogo.description = this.item.name + ' Web Logo';
            this.form.$setDirty();
        });
    }

    onReportLogoSelected() {
        this.$timeout(() => {
            this.hasNewReportLogoFile = true;
            this.item.reportLogo.description = this.item.name + ' Report Logo';
            this.form.$setDirty();
        });
    }

    determineSaveButtonState() {
        this.buttonStates.saveAndClose.disabled = this.form.$pristine || this.form.$invalid || this.wnd.processing;
        this.buttonStates.saveAndNew.disabled = this.buttonStates.saveAndClose.disabled
    }
}

class ClientDetailsComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = ClientDetailsCtrl;
    public controllerAs = 'vm';
}

angular.module('app.admin').component('sysOrgClientDetails', new ClientDetailsComponent());