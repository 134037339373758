//import angular from 'angular';
import AdminUrls from '../adminUrls';


    angular.module('app.admin')
        .controller('performanceBatchSearchCtrl', ['$scope', '$q', 'amtCommandQuerySvc', '$timeout', 'enums', 'amtXlatSvc', 'confirmSvc', 'adminUrls', 'notifySvc',
            'componentUrls', 'referenceDataUrls', 'exportSvc', 'ocConfigSvc', 'WindowFactory', 'ocSecuritySvc', 'errorReporter',
            function ($scope, $q, amtCommandQuerySvc, $timeout, enums, xlatSvc: IAmtXlatSvc, confirmSvc, adminUrls: AdminUrls, notifySvc,
                componentUrls, referenceDataUrls, exportSvc, ocConfigSvc, WindowFactory: IWindowFactory, ocSecuritySvc, errorReporter) {

                var vm = this;

                vm.processing = false;

                // security
                vm.canDelete = ocSecuritySvc.isAuthorised('Security.Settings.PerformanceBatches.Delete', 'readWrite');

                vm.apiUrls = adminUrls;
                vm.componentUrls = componentUrls;
                vm.referenceDataUrls = referenceDataUrls;

                vm.showClients = false;
                vm.showSites = false;

                vm.criteria = {
                    filterValues: {
                        clientIds: [],
                        siteIds: [],
                        dateFrom: null,
                        dateTo: null,
                        componentTypeId: null,
                        batchName: null,
                        defaultToAllSitesWhenNoSystemHierarchyFilter: true
                    }
                };

                $scope.$watch('vm.criteria.filterValues.clientIds.length', function (cnt) {
                    if (cnt === 0 && vm.criteria.filterValues.siteIds.length > 0) {
                        // reset the sites if the clients are cleared
                        vm.criteria.filterValues.siteIds.length = 0;
                    }
                });

                vm.status = "loading";
                vm.isDirty = true;

                // get the config from root scope, this contains things like units of measure
                vm.config = ocConfigSvc;

                vm.gridControl = {};
                vm.selectedItem = null;

                function init() {
                    // set the state to loading
                    vm.status = 'loading';

                    vm.loadDefaults()
                        .then(
                            function (state) {
                                vm.status = state;

                                // focus on batch name field by default
                                vm.focusBatchName = true;
                            },
                            function (error) {
                                vm.defaultErrors = error;
                                vm.status = 'error';
                            }
                        );
                }

                vm.onDatabound = function () {
                    vm.selectedItem = null;
                };

                vm.setHeaderHeight = function (height, offset) {
                    vm.gridTop = height + offset;
                };

                vm.export = function () {
                    exportSvc.exportData(vm.apiUrls.PerformanceBatchsearchExport, vm.criteria, xlatSvc.xlat('performanceBatches.searchExportFilename'));
                };

                vm.onSelectedItemChanged = function (items) {
                    if (items !== null && items.length === 1) {
                        vm.selectedItem = items[0];
                    } else {
                        vm.selectedItem = null;
                    }
                };

                vm.loadDefaults = function () {

                    vm.processing = true;

                    //set up the search criteria
                    var defaultCriteria = {
                        componentTypeName: null,
                        statusTypeNames: []
                    };

                    return $q(function (resolve, reject) {
                        amtCommandQuerySvc.post(vm.componentUrls.getDefaults, defaultCriteria).then(function (response) {
                            if (response) {

                                vm.defaultClients = response.clients;
                                vm.defaultComponentType = response.componentType;

                                // delay this so that client is sorted and doesn't trigger a lookup of the site
                                $timeout(function () {
                                    vm.defaultSites = response.sites;
                                    vm.siteId = response.sites[0].key;
                                });

                                resolve("ready");
                            }
                        }, function (error) {
                            errorReporter.logError(error);
                            reject([error]);
                        });
                    }).finally(function () {
                        vm.processing = false;
                    });
                };

                $scope.editPerformanceBatch = function (item) {
                    if (item !== undefined && item !== null) {
                        WindowFactory.openItem({
                            component: 'performance-batch-details',
                            caption: xlatSvc.xlat('performanceBatches.editPerformanceBatch'),
                            initParams: {
                                batchId: item.id,
                                isEdit: true,
                                siteId: item.site.id
                            },
                            width: 700,
                            onDataChangeHandler: vm.filter
                        });
                    }
                };

                $scope.addPerformanceBatch = function () {
                    WindowFactory.openItem({
                        caption: xlatSvc.xlat('performanceBatches.addPerformanceBatch'),
                        component: 'performance-batch-details',
                        initParams: {
                            batchId: null,
                            isEdit: false,
                            siteId: vm.siteId
                        },
                        width: 700,
                        onDataChangeHandler: vm.filter
                    });
                };

                $scope.deletePerformanceBatch = function (item) {
                    confirmSvc.confirmMessage('performanceBatches.confirmDeletPerformanceBatch_title', 'performanceBatches.confirmDeletePerformanceBatch_text', item.batchName).then(function () {

                        vm.processing = true;

                        amtCommandQuerySvc.post(vm.apiUrls.deletePerformanceBatch, item).then(function (response) {

                            notifySvc.success(xlatSvc.xlat("performanceBatches.messagePerformanceBatchDeleteSuccess"));
                            vm.filter();

                        }).catch(function (error) {
                            errorReporter.logError(error);
                        }).finally(function () {
                            vm.processing = false;
                        });
                    });
                };

                vm.refreshGrid = function () {
                    vm.gridControl.refresh();
                };

                vm.filter = function () {
                    vm.showClients = vm.criteria.filterValues.clientIds.length !== 1;
                    vm.showSites = vm.criteria.filterValues.siteIds.length !== 1;
                    vm.refreshGrid();
                };

                init();
            }]);
