    /**
        * @ngdoc directive
        * @name amtFramework.grid.directive:amtDropdownColumnFilter
        * @restrict E
        * @requires amtFramework.grid.directive:amtGridColumn
        * @description 
        * Adds a dropdown to the filter in the columnheader
        * @param {expression} datasource Datasource
        * @param {string=} [idField=key] Name of the id field
        * @param {string=} [textField=name] Name of the text field
        * @element 
        * @example 
    **/
    

//import angular from 'angular';

angular.module('amtFramework.grid').directive('amtDropdownColumnFilter', ["amtXlatSvc",
    function (amtXlatSvc) {
            return {
                require: ['^?amtGridColumn'],
                restrict: 'E',
                scope: {
                    url: '@', //not used?
                    datasource: '=',
                    idField: '@',
                    textField: '@'
                },
                link: function (scope: any, element, attributes, controllers) {

                    if (!scope.idField) {
                        scope.intIdField = scope.idField || "key";
                    }

                    if (!scope.textField) {
                        scope.intTextField = scope.textField || "name";
                    }
                    var gridColumnController = controllers[0];


                    gridColumnController.setFilterDefinition(buildDropDownFilter, buildOperator());

                    function buildDropDownFilter(filterElement) {
                        filterElement.kendoDropDownList({
                            dataSource: scope.datasource,
                            dataTextField: scope.intTextField,
                            dataValueField: scope.intIdField,
                            optionLabel: amtXlatSvc.xlat("framework.dropdownSelectCaption")
                        });
                    }

                    function buildOperator() {
                        var operators = {
                            string: {
                                eq: amtXlatSvc.xlat("framework.isEqualTo"),
                            }
                        };
                        return operators;
                    }
                }
            };
    }
]);
