//import angular from 'angular';
import AdminUrls from '../../../adminUrls';

    angular.module('app.admin').controller("resourceSearchCtrl", resourceSearchCtrl);

    // @ts-ignore
    resourceSearchCtrl.$inject = ["adminUrls", "$scope", "enums", "amtXlatSvc", "WindowFactory", "ocConfigSvc", "exportSvc", "amtCommandQuerySvc",
        "referenceDataUrls", "errorReporter", "amtConstants"];

    // ReSharper disable once InconsistentNaming
    function resourceSearchCtrl(adminUrls: AdminUrls, $scope, enums, amtXlatSvc, WindowFactory, ocConfigSvc, exportSvc, amtCommandQuerySvc,
        referenceDataUrls, errorReporter, amtConstants) {

        var vm = this;

        vm.processing = false;

        vm.adminUrls = adminUrls;
        vm.referenceDataUrls = referenceDataUrls;

        vm.config = ocConfigSvc;

        vm.enums = enums;

        vm.criteria = {
            filterValues: {
                referenceDataTypeIds: null,
                value: null,
                languageIds: null,
                excludeNonEditableReferenceDataResources: true
            }
        };

        vm.status = enums.screenStatus.loading;

        vm.gridControl = {};
        vm.selectedItem = null;

        function init() {

            vm.processing = true;

            return getReferenceDataTypes().finally(function () {

                // focus on serial number field by default
                vm.focusValue = true;

                vm.status = enums.screenStatus.ready;
                vm.processing = false;
            });
        }

        vm.onSelectedItemChanged = function (items) {
            if (items != null && items.length === 1) {
                vm.selectedItem = items[0];
            } else {
                vm.selectedItem = null;
            }
        };

        vm.setHeaderHeight = function (height, offset) {
            vm.gridTop = height + offset;
        };

        vm.export = function () {
            exportSvc.exportData(vm.adminUrls.exportResources, vm.criteria, amtXlatSvc.xlat('resource.searchTitle'));
        };

        vm.onSearchResources = function (response) {

            var resources = response.result;

            if (resources && resources.length > 0) {

                for (var r = 0; r < resources.length; r++) {
                    if (resources[r].referenceDataType) {
                        resources[r].referenceDataType = resources[r].referenceDataType.replace(/([A-Z])([a-z])/g, ' $1$2').trim().replace(/([a-z])([A-Z])/g, '$1 $2').trim();
                    }
                }
            }

            return response;
        };

        function getReferenceDataTypes() {

            var criteria = {
                excludeNonEditableReferenceDataTypes: true
            };

            return amtCommandQuerySvc.post(vm.referenceDataUrls.getReferenceDataTypes, criteria).then(function (response) {

                vm.referenceDataTypes = response.result;

                vm.referenceDataTypes.forEach(function (r) {
                    r.name = r.name.replace(/([A-Z])([a-z])/g, ' $1$2').trim();
                    r.name = r.name.replace(/([a-z])([A-Z])/g, '$1 $2').trim();
                });

                vm.referenceDataTypes.unshift({
                    key: amtConstants.emptyGuid,
                    name: amtXlatSvc.xlat('resource.nonReferenceDataResources')
                });

            }).catch(function (error) {
                errorReporter.logError(error);
            });
        };

        $scope.editResource = function (item) {
            if (item) {
                WindowFactory.openItem({
                    component: 'add-edit-resource',
                    caption: amtXlatSvc.xlat("resource.edit"),
                    initParams: item,
                    width: 650,
                    onDataChangeHandler: function () {
                        vm.refreshGrid();
                    },
                    windowRelatedRecordId: item.id
                });
            }
        };

        vm.refreshGrid = function () {
            vm.gridControl.refresh();
        };

        init();
    }
