/**
 * @ngdoc directive
 * @name amtFramework.directive:amtMenuActions
 * @restrict E
 * 
 * @param {string} title Title
 *
 * @example
 */

import { Component, Input, HostBinding, ContentChildren, QueryList, forwardRef } from "@angular/core";
import { downgradeComponent } from "@angular/upgrade/static";
import { AmtMenuAction } from './amtMenuAction';

@Component({
    selector: 'amt-menu-actions',
    templateUrl: './amtMenuActions.html',
    styles: [':host { display: block }']
})
export class AmtMenuActions {
    @Input('title') title;
    @Input('tabindex') tabindex: number;
    @Input('dropUp') set dropUp(val: string | boolean) {
        this.dropupClass = (val === 'true' || val === true);
    };
    @Input('openLeft') openLeft: string;
    @HostBinding('class.dropdown') dropdown = true;
    @HostBinding('class.dropup') dropupClass: boolean;
}

angular.module('amtFramework').directive('amtMenuActions', downgradeComponent({ component: AmtMenuActions }));