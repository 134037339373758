import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FilterConstant, ForecastUi } from '../../service/Interface/WidgetConstant.interface';
import { Widget } from '../../service/Interface/SiteDashboard.interface';
import { LiveForecastDialogItem, LiveForecastTyreGroup, LiveForecastSelectedGroupCount, LiveForecastTyreWidget } from '../../service/Interface/Widget.interface';
import { LiveForecastTyreWidgetHelper } from '../../service/widgetHelper/LiveForecastTyreWidget.helper';
import { WidgetConstant } from '../../service/widgetHelper/WidgetConstant';
import { DialogRxjsService } from './../../service/rxjs/DialogRxjs.service';
import { WidgetRxjsService } from '../../service/rxjs/WidgetRxjs.service';
import { Subscription } from 'rxjs';


@Component({
    selector: 'widget-live-forecast-tyres-month',
    templateUrl: './live-forecast-tyres-month.component.html',
    styleUrls: ['./../../site-dashboard.component.scss']
})


//Completed
export class SiteDashboardLiveForecastTyresMonthComponent implements OnInit, OnDestroy {
    public resultSubscription: Subscription;
    public siteDashboardWidgetId: string;
    public hasReceivedResult: boolean = false;
    public hasLiveForecast: boolean = false;
    public ui: ForecastUi;
    @ViewChild("displaySelectionDropdownList", { static: true })
    public displaySelectionList: Array<string>;
    public selectedDisplay: string;
    public selectedTyreGroup: LiveForecastTyreGroup;
    public selectedGroupCount: LiveForecastSelectedGroupCount = {
        overDue: 0,
        next2Weeks: 0,
        next2To4Weeks: 0,
        next4To8Weeks: 0,
        next8To12Weeks: 0
    };
    public resultItem: LiveForecastTyreWidget = {
        siteDashboardWidgetId: '',
        result: {
            allTyres: null,
            endLifeTyres: null,
            newChangeTyres: null,
            hasLiveForecast: false
        }
    };

    constructor(private liveForecastTyreHelper: LiveForecastTyreWidgetHelper, private constant: WidgetConstant,
        private dialogRxjs: DialogRxjsService, private widgetRxjs: WidgetRxjsService) {
        this.ui = this.constant.getForecastWidgetUi();
    }

    @Input() set getWidgetDetail(widget: Widget) {
        this.siteDashboardWidgetId = widget.siteDashboardWidgetId;
    }

    ngOnInit(){
        this.getLiveForecastTyreMonthWidgetResult();
    }

    ngOnDestroy() {
        this.resultSubscription.unsubscribe();
    }

    //Subscribe Live ForecastTyreMonth Rxjs
    getLiveForecastTyreMonthWidgetResult() {
        this.resultSubscription = this.widgetRxjs.liveForecastTyreWidget.subscribe(
            widgets => {
                let hasForecastTyreWidget = widgets.length > 0;

                if (hasForecastTyreWidget) {
                    widgets.forEach(widget => {
                        if (widget.siteDashboardWidgetId === this.siteDashboardWidgetId) {
                            this.setDefaultLandingLiveForecastTyreMonthWidget(widget);
                        }
                    });
                }
            });
    }

    /**Set Default Landing Live Forecast Tyre Widget by selected widget*/
    setDefaultLandingLiveForecastTyreMonthWidget(resultItem: LiveForecastTyreWidget) {
        if (resultItem.result.hasLiveForecast) {
            this.resultItem = resultItem;
            this.displaySelectionList = this.liveForecastTyreHelper.getDefaultSelectionDropDownMenu();
            this.selectedDisplay = this.liveForecastTyreHelper.getDefaultSelected();
            this.changeDisplay(this.selectedDisplay);
        }
        this.hasLiveForecast = resultItem.result.hasLiveForecast;
        this.hasReceivedResult = true;
    }

    /**onChange Display Setting, set up the selected Display by using api result*/
    changeDisplay(selectedDisplay: string) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();

        if (selectedDisplay === filterConstant.totalTyreForecast) {
            this.selectedTyreGroup = this.resultItem.result.allTyres;
            this.selectedGroupCount = this.liveForecastTyreHelper.setLiveForecastGroupCount(this.selectedTyreGroup);
        }
        if (selectedDisplay === filterConstant.newTyreChangeOut) {
            this.selectedTyreGroup = this.resultItem.result.newChangeTyres;
            this.selectedGroupCount = this.liveForecastTyreHelper.setLiveForecastGroupCount(this.selectedTyreGroup);
        }
        if (selectedDisplay === filterConstant.endLifeTyre) {
            this.selectedTyreGroup = this.resultItem.result.endLifeTyres;
            this.selectedGroupCount = this.liveForecastTyreHelper.setLiveForecastGroupCount(this.selectedTyreGroup);
        }
    }

    /**Trigger the open up the Dialog*/
    async showLiveForecastTyreDialog() {
        let action: LiveForecastDialogItem = {
            action: true,
            siteDashboardWidgetId: this.resultItem.siteDashboardWidgetId
        }
        this.dialogRxjs.setLiveForecastTyreDialogActionListener(action);
    }


}