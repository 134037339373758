//import angular from 'angular';
import OcDateSvc from '../../../services/ocDateSvc';
import tmpl from './locationDetails.html';


    angular.module('app.admin').component('locationDetails',
        {
            bindings: {
                initParams: '=',
                buttonMethods: '=',
                buttonStates: '=',
                buttons: '=',
                closeOnSave: '=',
                wnd: '='
            },
            template: tmpl,
            controller: [
                '$scope', 'locationSvc', 'WindowFactory', 'confirmSvc',
                '$timeout', 'notifySvc', 'amtXlatSvc', 'ocDateSvc', 'ocSecuritySvc',
                function (
                    $scope, locationSvc, WindowFactory: IWindowFactory, confirmSvc,
                    $timeout, notifySvc, amtXlatSvc, ocDateSvc: OcDateSvc, ocSecuritySvc
                ) {
                    var vm = this;

                    $scope.vm = this; // necessary for child directives to access what they need.

                    vm.form = {};
                    vm.status = "loading";
                    vm.showSaveButton = true;
                    vm.svc = locationSvc;
                    vm.errors = [];                    
                    vm.activeToMustBeAfterFrom = amtXlatSvc.xlat('location.error_activeToMustBeAfterFrom');

                    vm.statusControl = {};

                    // pass along dirty flag to the window for use on closing minimised windows
                    $scope.$watch('vm.form.$dirty', function () {
                        vm.wnd.isDirty = vm.form.$dirty;
                    });

                    vm.setReady = function () {
                        vm.status = 'ready';

                        vm.wnd.processing = false;
                    };

                    this.$onInit = function () {

                        vm.wnd.processing = true;

                        WindowFactory.addButton(
                            this,
                            'common.save_label', // caption
                            'saveAndClose',
                            function () {
                                vm.save('close');
                            },
                            true // primary
                        );

                        if (!vm.initParams.id) {
                            WindowFactory.addButton(
                                this,
                                'common.saveAndAddAnother_label', // caption
                                'saveAndNew',
                                function () {
                                    vm.save('new');
                                }
                            );
                        }

                        vm.wnd.onClose = $scope.onClose;

                        vm.loadDetails();
                    };

                    vm.loadDetails = function () {
                        if (vm.initParams.id) {
                            // load existing
                            locationSvc.getAsync(vm.initParams.id, vm.initParams.systemHierarchySystemHierarchyRoleId).then(function (result) {
                                vm.item = result;

                                vm.setReady();
                            });
                        } else {
                            // new
                            vm.item = vm.initParams;

                            vm.setReady();
                        }
                    };

                    $scope.$watch('vm.item.siteId', function () {
                        if (vm.item) {
                            // disable editing if the user doesnt have write permissions at the client / site
                            try {
                                vm.accessType = ocSecuritySvc.getAccessType(vm.svc.mainPermission, vm.item.clientId, vm.item.siteId);
                            } catch {
                                vm.accessType = ocSecuritySvc.accessTypes.hide;
                            }
                        }
                    });

                    vm.setPristine = function () {
                        $timeout(function () {
                            vm.form.$setPristine();
                        });
                    };

                    vm.saveSuccessHandler = function () {
                        vm.wnd.processing = false;

                        if (vm.wnd.onDataChanged) {
                            vm.wnd.onDataChanged();
                        }

                        notifySvc.success(amtXlatSvc.xlat('location.save_success'));
                    };

                    vm.saveSuccessAndNewHandler = function () {
                        vm.saveSuccessHandler();

                        vm.item = locationSvc.reset();

                        vm.statusControl.clear();

                        vm.setPristine();
                    };

                    vm.saveSuccessAndCloseHandler = function () {
                        vm.saveSuccessHandler();

                        WindowFactory.closeWindow(vm.wnd);
                    };

                    vm.saveErrorsHandler = function (error) {
                        vm.errors = error;
                        vm.wnd.processing = false;

                        notifySvc.success(amtXlatSvc.xlat('location.save_error', error.message));
                    };

                    vm.save = function (successAction) {
                        vm.wnd.processing = true;
                        vm.errors = [];

                        var data = {
                            latitude: vm.item.latitude,
                            longitude: vm.item.longitude,
                            statuses: vm.item.statuses,
                            activeFrom: vm.item.activeFrom ? ocDateSvc.removeLocalTimeZoneOffset(vm.item.activeFrom) : vm.item.activeFrom,
                            activeTo: vm.item.activeTo ? ocDateSvc.removeLocalTimeZoneOffset(vm.item.activeTo) : vm.item.activeTo, 
                            id: vm.item.id,
                            name: vm.item.name,
                            siteId: vm.item.siteId,
                            description: vm.item.description
                        };

                        var successHandler = successAction == 'close' ? vm.saveSuccessAndCloseHandler : vm.saveSuccessAndNewHandler;

                        locationSvc.saveAsync({ item: data }).then(successHandler, vm.saveErrorHandler);
                    };

                    function closeWindow() {
                        WindowFactory.closeWindow(vm.wnd);
                    }

                    $scope.onClose = function () {
                        confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                            vm.form.$dirty = false;
                            closeWindow();
                            return true;
                        });

                        return false;
                    };

                    vm.findError = function (msg) {
                        return (vm.errors ? vm.errors.indexOf(msg) : -1);
                    };

                    vm.removeError = function (msg) {
                        var existing = vm.findError(msg);

                        if (existing > -1) {
                            vm.errors.splice(existing, 1);
                        }
                    };

                    vm.addError = function (msg) {
                        if (vm.findError(msg) == -1) {
                            vm.errors.push(msg);
                        }
                    };

                    $scope.$watchGroup(['vm.item.activeFrom', 'vm.item.activeTo'], function () {
                        if (vm.item && vm.item.activeFrom && vm.item.activeTo) {
                            if (vm.item.activeFrom > vm.item.activeTo) {
                                vm.addError(vm.activeToMustBeAfterFrom);
                            }
                        } else {
                            vm.removeError(vm.activeToMustBeAfterFrom);
                        }
                    });

                    $scope.$watchGroup(['vm.form.$pristine', 'vm.form.$invalid', 'vm.wnd.processing'], function () {
                        determineSaveButtonState();
                    });

                    function determineSaveButtonState() {
                        // hide the save buttons if the user cannot edit at this site
                        vm.buttonStates.saveAndClose.visible = vm.accessType === ocSecuritySvc.accessTypes.readWrite;

                        // Can't save if the form is invalid
                        vm.buttonStates.saveAndClose.disabled = vm.form.$pristine || vm.form.$invalid || vm.wnd.processing;

                        if (vm.buttonStates.saveAndNew) {
                            vm.buttonStates.saveAndNew.disabled = vm.buttonStates.saveAndClose.disabled;
                            vm.buttonStates.saveAndNew.visible = vm.buttonStates.saveAndClose.visible;
                        }
                    }
                }
            ]
        });
