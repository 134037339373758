<section class="amt-view-header bg-light lter b-b ng-scope ng-isolate-scope">
    <div class="site-dashboard-menu-flex-box" (click)="dismissLiveForecastRimElement()">
        <!-- Header-->
        <h2 class="dialog-title">{{ui.rimTitle}}</h2>
        <!--Close Button-->
        <button class="dialog-close-btn" (click)="dismissLiveForecastRimElement()"></button>
    </div>
</section>

<div *ngIf="hasReceivedElement">
    <!--Filter Selection-->
    <section class="live-forecast-detail-dropdown-container">
        <div class="flex-container">
            <section class="flex-container-col">
                <!--Display Selection Dropdown List -->
                <kendo-label class="live-forecast-label"
                             text="{{ui.changeType}}">
                    <kendo-dropdownlist class="live-forecast-dropdown-single"
                                        [data]="displaySelectionList"
                                        [(ngModel)]="selectedDisplay"
                                        (ngModelChange)="onChangeSelectedDisplay()">
                    </kendo-dropdownlist>
                </kendo-label>

                <!--Duration Selection Dropdown List -->
                <kendo-label class="live-forecast-label"
                             text="{{ui.forecastPeriod}}">
                    <kendo-dropdownlist class="live-forecast-dropdown-single"
                                        [data]="durationSelectionList"
                                        [(ngModel)]="selectedDuration"
                                        (ngModelChange)="onChangeSelectedDuration()">
                    </kendo-dropdownlist>
                </kendo-label>
            </section>

            <section class="flex-container-col">
                <!--Vehicle Specification  Selection Dropdown List -->
                <kendo-label class="live-forecast-label"
                             text="{{ui.vehicleSpecification}}">
                    <kendo-multiselect kendoMultiSelectSummaryTag
                                       class="live-forecast-multi-select"
                                       [autoClose]="false"
                                       [data]="filterOption.vehicleSpecificationList"
                                       [(ngModel)]="filterOption.filter.vehicleSpecification"
                                       (ngModelChange)="onChangeVehicleSpecification($event)"
                                       (reset)="onChangeVehicleSpecification($event)"
                                       (removeTag)="onChangeVehicleSpecification($event)">

                        <ng-template kendoMultiSelectGroupTagTemplate
                                     let-dataItems>
                            <span *ngIf="dataItems.length === 1">
                                {{ dataItems }}
                            </span>
                            <span *ngIf="dataItems.length > 1">
                                {{ dataItems.length }} {{ui.vehicleSpecificationSelected}}
                            </span>
                        </ng-template>

                    </kendo-multiselect>
                </kendo-label>

                <!--Vehicle Serial Selection Dropdown List -->
                <kendo-label class="live-forecast-label"
                             text="{{ui.vehicleSerialNumber}}">
                    <kendo-multiselect kendoMultiSelectSummaryTag
                                       class="live-forecast-multi-select"
                                       [autoClose]="false"
                                       [data]="filterOption.vehicleSerialNumberList"
                                       [(ngModel)]="filterOption.filter.vehicleSerialNumber"
                                       (reset)="onChangeVehicleSerialNumber($event)"
                                       (ngModelChange)="onChangeVehicleSerialNumber($event)"
                                       (removeTag)="onChangeVehicleSerialNumber($event)">

                        <ng-template kendoMultiSelectGroupTagTemplate
                                     let-dataItems>
                            <span *ngIf="dataItems.length === 1">
                                {{ dataItems }}
                            </span>
                            <span *ngIf="dataItems.length > 1">
                                {{ dataItems.length }} {{ui.vehicleSerialNumberSelected}}
                            </span>
                        </ng-template>

                    </kendo-multiselect>
                </kendo-label>
            </section>

            <section class="flex-container-col">
                <!--Rim Specification Selection Dropdown List -->
                <kendo-label class="live-forecast-label"
                             text="{{ui.rimSpecification}}">
                    <kendo-multiselect kendoMultiSelectSummaryTag
                                       class="live-forecast-multi-select"
                                       [autoClose]="false"
                                       [data]="filterOption.rimSpecificationList"
                                       [(ngModel)]="filterOption.filter.rimSpecification"
                                       (ngModelChange)="onChangeRimSpecification($event)"
                                       (reset)="onChangeRimSpecification($event)"
                                       (removeTag)="onChangeRimSpecification($event)">

                        <ng-template kendoMultiSelectGroupTagTemplate
                                     let-dataItems>
                            <span *ngIf="dataItems.length === 1">
                                {{ dataItems }}
                            </span>
                            <span *ngIf="dataItems.length > 1">
                                {{ dataItems.length }} {{ui.rimSpecificationSelected}}
                            </span>
                        </ng-template>

                    </kendo-multiselect>
                </kendo-label>

                <!--rim Size Selection Dropdown List -->
                <kendo-label class="live-forecast-label"
                             text="{{ui.rimSize}}">
                    <kendo-multiselect kendoMultiSelectSummaryTag
                                       class="live-forecast-multi-select"
                                       [autoClose]="false"
                                       [data]="filterOption.rimSizeList"
                                       [(ngModel)]="filterOption.filter.rimSize"
                                       (ngModelChange)="onChangeRimSize($event)"
                                       (reset)="onChangeRimSize($event)"
                                       (removeTag)="onChangeRimSize($event)">

                        <ng-template kendoMultiSelectGroupTagTemplate
                                     let-dataItems>
                            <span *ngIf="dataItems.length === 1">
                                {{ dataItems }}
                            </span>
                            <span *ngIf="dataItems.length > 1">
                                {{ dataItems.length }} {{ui.rimSizeSelected}}
                            </span>
                        </ng-template>

                    </kendo-multiselect>
                </kendo-label>
            </section>
        </div>
    </section>

    <kendo-grid [data]="displayRim"
                [height]="500"
                [resizable]="true">

        <!--Due Date Col-->
        <kendo-grid-column field="dueDate"
                           title="{{ui.dueDateTitle}}"
                           [width]="103">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.dueDate | date: 'dd-MMMM-yyyy'}}
            </ng-template>
        </kendo-grid-column>

        <!--Current Rim Hours-->
        <kendo-grid-column field="currentTotalHours"
                           title="{{ui.currentHoursTitle}}"
                           [width]="65">
        </kendo-grid-column>

        <!--Current Rim Hours Since Last Ndt-->
        <kendo-grid-column field="currentHourSinceLastNdt"
                           title="{{ui.currentHoursSinceLastNdtTitle}}"
                           [width]="65">
        </kendo-grid-column>

        <!--Rim Serial Number-->
        <kendo-grid-column field="rimSerialNumber"
                           title="Rim Serial Number"
                           [width]="108">
            <ng-template kendoGridCellTemplate let-dataItem>
                <a href="/#!/component/search" (click)="setSearchByComponentSerialNumber(dataItem.rimSerialNumber)">
                    <p style="margin: 0;">{{dataItem.rimSerialNumber}}</p>
                </a>
            </ng-template>
        </kendo-grid-column>

        <!--Rim Size-->
        <kendo-grid-column field="rimSize"
                           title="{{ui.rimSizeTitle}}"
                           [width]="78">
        </kendo-grid-column>

        <!--Tyre Specification-->
        <kendo-grid-column field="rimSpecification"
                           title="{{ui.rimSpecificationTitle}}"
                           [width]="200">
        </kendo-grid-column>


        <!--Vehicle Serial Number-->
        <kendo-grid-column field="fittedVehicle.vehicleSerialNumber"
                           title="{{ui.vehicleSerialNumberTitle}}"
                           [width]="75">
            <ng-template kendoGridCellTemplate let-dataItem>
                <a href="/#!/vehicle/search"><p style="margin: 0;" (click)="setSearchByVehicleSerialNumber(dataItem.fittedVehicle.vehicleSerialNumber)">{{dataItem.fittedVehicle.vehicleSerialNumber}}</p></a>
            </ng-template>

        </kendo-grid-column>

        <!--Current Rim Position Label-->
        <kendo-grid-column field="currentPosition"
                           title="{{ui.positionTitle}}"
                           [width]="55">
        </kendo-grid-column>

        <!--Fitment Type-->
        <kendo-grid-column field="task.changeType.fitmentType"
                           title="{{ui.fitmentTypeTitle}}"
                           [width]="90"
                           [class]="{ 'text-center': true }">
        </kendo-grid-column>

        <!--Removal Type-->
        <kendo-grid-column field="task.changeType.removalType"
                           title="{{ui.removalTypeTitle}}"
                           [width]="96">
        </kendo-grid-column>
    </kendo-grid>
</div>

