//import angular from 'angular';
import { UpdateForecastOverrideModelMaker } from '../updateForecastOverrideCtrl';



    angular.module("app.site").controller("rimSpecificationEditController", rimSpecificationEditController);

    // @ts-ignore
    rimSpecificationEditController.$inject = ['$scope', '$state', '$rootScope', 'amtCommandQuerySvc', 'amtConstants',
        'notifySvc', 'amtXlatSvc', 'enums', 'WindowFactory', 'errorReporter', 'helperSvc', 'forecastingSvc'];

    // ReSharper disable once InconsistentNaming
    function rimSpecificationEditController($scope, $state, $rootScope, amtCommandQuerySvc, amtConstants,
        notifySvc, xlatSvc, enums, WindowFactory, errorReporter, helperSvc, forecastingSvc) {

        var vm = this;

        vm.apiUrls = amtConstants.url;

        vm.gridControl = {};
        vm.gridControlDays = {};

        vm.rimSpecControl = {};

        vm.forecastId = $rootScope.$stateParams.forecastid ? $rootScope.$stateParams.forecastid : 0;
        vm.vehicleSpecificationId = $rootScope.$stateParams.vehiclespecificationid ? $rootScope.$stateParams.vehiclespecificationid : 0;
        vm.vehicleSpecification = $rootScope.$stateParams.vehiclespecification ? $rootScope.$stateParams.vehiclespecification : "";
        vm.startDate = $rootScope.$stateParams.startdate ? $rootScope.$stateParams.startdate : new Date();
        vm.endDate = $rootScope.$stateParams.enddate ? $rootScope.$stateParams.enddate : 0;
        vm.vehicleCount = $rootScope.$stateParams.vehiclecount ? $rootScope.$stateParams.vehiclecount : 0;
        vm.currency = $rootScope.$stateParams.currency;
        vm.includeRimIntervalsByDays = $rootScope.$stateParams.includeRimIntervalsByDays;

        vm.filterValues = {
            forecastId: vm.forecastId,
            vehicleSpecificationId: vm.vehicleSpecificationId
        };

        this.$onInit = function () {
            if (!(vm.forecastId > 0)) {
                $state.go('app.site.forecastlist');
            }
        };

        vm.addRimSpecification = function () {

            vm.processing = true;

            amtCommandQuerySvc.put(amtConstants.url.addNewRimSpecification, {
                forecastId: vm.forecastId,
                vehicleSpecificationId: vm.vehicleSpecificationId,
                rimSpecificationId: helperSvc.getKey(vm.selectedRimSpecification)
            }).then(function (response) {

                notifySvc.success(xlatSvc.xlat("rimSpecificationEdit.addNewRimSpecificationSuccess"));

                vm.refreshAllGrids();

                if (vm.rimSpecControl) {
                    vm.rimSpecControl.clear();
                    vm.rimSpecControl.rebind();
                }

            }).catch(function (error) {
                errorReporter.logError(error);
            }).finally(function () {
                vm.processing = false;
            });
        };

        vm.goToParentRoute = function () {
            forecastingSvc.setActiveTabName("Rims");
            $rootScope.goBack();
        };

        $scope.updateAverageFirstInspectionIntervalHours = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                vehicleSpecificationId: vm.vehicleSpecificationId,
                type: enums.forecastOverrideTypes.firstInspectionIntervalHours,
                value: dataItem.averageOverrideFirstInspectionIntervalHours,
                componentSpecificationId: dataItem.rimSpecificationId,
                heading: xlatSvc.xlat('forecastRims.firstInspectionIntervalHoursOverride')
            }), vm.refreshGridHours);
        };

        $scope.updateAverageSubsequentInspectionIntervalHours = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                vehicleSpecificationId: vm.vehicleSpecificationId,
                type: enums.forecastOverrideTypes.subsequentInspectionIntervalHours,
                value: dataItem.averageOverrideSubsequentInspectionIntervalHours,
                componentSpecificationId: dataItem.rimSpecificationId,
                heading: xlatSvc.xlat('forecastRims.subsequentInspectionIntervalHoursOverride')
            }), vm.refreshGridHours);
        };

        $scope.updateRimScrapLifeHours = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                vehicleSpecificationId: vm.vehicleSpecificationId,
                type: enums.forecastOverrideTypes.rimScrapLifeHours,
                value: dataItem.averageOverrideRimScrapLifeHours,
                componentSpecificationId: dataItem.rimSpecificationId,
                heading: xlatSvc.xlat('forecastRims.rimScrapLifeHoursOverride')
            }), vm.refreshGridHours);
        };

        $scope.updateAverageFirstInspectionIntervalDays = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                vehicleSpecificationId: vm.vehicleSpecificationId,
                type: enums.forecastOverrideTypes.firstInspectionIntervalDays,
                value: dataItem.averageOverrideFirstInspectionIntervalDays,
                componentSpecificationId: dataItem.rimSpecificationId,
                heading: xlatSvc.xlat('forecastRims.firstInspectionIntervalDaysOverride')
            }), vm.refreshGridDays);
        };

        $scope.updateAverageSubsequentInspectionIntervalDays = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                vehicleSpecificationId: vm.vehicleSpecificationId,
                type: enums.forecastOverrideTypes.subsequentInspectionIntervalDays,
                value: dataItem.averageOverrideSubsequentInspectionIntervalDays,
                componentSpecificationId: dataItem.rimSpecificationId,
                heading: xlatSvc.xlat('forecastRims.subsequentInspectionIntervalDaysOverride')
            }), vm.refreshGridDays);
        };

        $scope.updateRimScrapLifeDays = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                vehicleSpecificationId: vm.vehicleSpecificationId,
                type: enums.forecastOverrideTypes.rimScrapLifeDays,
                value: dataItem.averageOverrideRimScrapLifeDays,
                componentSpecificationId: dataItem.rimSpecificationId,
                heading: xlatSvc.xlat('forecastRims.rimScrapLifeDaysOverride')
            }), vm.refreshGridDays);
        };

        $scope.updateAverageInspectionCost = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                vehicleSpecificationId: vm.vehicleSpecificationId,
                type: enums.forecastOverrideTypes.averageInspectionCostPerComponent,
                value: dataItem.forecastAverageInspectionCost,
                componentSpecificationId: dataItem.rimSpecificationId,
                heading: xlatSvc.xlat('rimSpecificationEdit.rimNdtCostPerComponent') + (vm.currency ? String.format(' ({0})', vm.currency) : '')
            }), vm.refreshAllGrids);
        };

        vm.refreshGridHours = function () {
            if (vm.gridControl && vm.gridControl.refresh) {
                vm.gridControl.refresh();
            }
        };

        vm.refreshGridDays = function () {
            if (vm.gridControlDays && vm.gridControlDays.refresh) {
                vm.gridControlDays.refresh();
            }
        };

        vm.refreshAllGrids = function () {
            vm.refreshGridHours();
            vm.refreshGridDays();
        };
    }
