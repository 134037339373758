//import angular from 'angular';
import tmpl from './ocComment.html';

    angular.module('app.directives')
        .directive('ocComment', [function () {
            return {
                restrict: 'E',
                scope: {
                    maxLength: "=",
                    ngRequired: "=",
                    ngDisabled: "=",
                    rows: "=",
                    ngModel: "="
                },
                template: tmpl,
                link: function (scope: any, element, attrs, ngModel) {
                    scope.$watch('ngModel',
                        function(newValue) {
                            scope.remainingCharacters = scope.maxLength - (newValue ? newValue.length : 0);
                        });
                }
            };
        }]);
