/**
 * A button representing the condition of a component. Includes all required fields for the condition.
 */
//import angular from 'angular';
import { OcDynamicGridColumn, OcDynamicGridColumnMaker, OcDynamicGridColumnType, OcDynamicGridAlignment } from '../../../directives/ui/ocDynamicGrid';


class ComponentConditionButton {
    /**
     * @constructor 
     * @param id - Unique ID of the button.
     * @param name - Name of the button. Will be used as the title/header.
     * @param description - Description for the button. Will be shown under the title/header.
     * @param disabled - State of the button.
     * @param requiresSpecification - Whether or not to show the Specification field on the details step.
     * @param requiresUnitCost - Whether or not to show the Unit Cost field on the details step.
     * @param requiresPurchaseType - Whether or not to show the Unit Cost field on the details step.
     * @param requiresComponentOwner - Whether or not to show the Component Owner field on the details step.
     * @param requiresRetreader - Whether or not to show the Retreader field on the details step.
     * @param requiresStatusCombo - Whether or not to show the Status Combo field on the details popup.
     * @param requiresDataFields - Whether or not to show the Data Fields field on the details popup.
     * @param requiresCompOwner - Whether or not to show the Component Owner field on the details popup.
     * @param requiresRtd - Whether or not to show the RTD fields on the details popup.
     * @param requiresHours - Whether or not to show the Hours field on the details popup.
     * @param requiresDistance - Whether or not to show the Distance field on the details popup.
     * @param requiresCostDetails - Whether or not to show the Unit Cost field on the details popup.
     * @param requiresStatusLabel - Whether or not to show the Status label field on the details popup.
     * @param requiresHoursSinceLastNdt - Whether or not to show the Hours Since Last NDT field on the details popup.
     * @param requiresNdtStatus - Whether or not the show the NDT Status field on the details popup.
     * @param requiresRcd - Whether or not to show the RCD fields on the details popup.
     * @param showTypeColumn - Whether or not to show the Type column on the serial step.
     * @param showVehicleColumn - Whether or not to show the Vehicle/Pos column on the serial step.
     * @param showStatusColumn - Whether or not to show the Status column on the serial step.
     * @param showSpecificationColumn - Whether or not to show the Specification column on the serial step.
     * @param showDeleteColumn - Whether or not to show the Remove column on the serial step.
     * @param showReceiveColumn - Whether or not to show the Receive column on the serial step.
     * @param isKnown - Whether or not the components to be received are already known to the system.
     * @param associatedStatusType - Status type associated to a known component condition.
     * @param components - known components for the condition.
     */
    constructor(public id: string, public name: string, public description: string, public parentConditionId: string, public disabled: boolean, public loading: boolean,
        public isKnown: boolean, public defaultCost: boolean, public costLabel: string, public associatedStatusType: string,
        public detailsStepRequires: ComponentDetailsStepRequires, public serialPopupRequires: ComponentSerialPopupRequires, public components?: Array<Object>) { }
}

class ComponentConditionButtonMaker {
    static create(obj: ComponentConditionButton) {
        return new ComponentConditionButton(obj.id, obj.name, obj.description, obj.parentConditionId, obj.disabled, obj.loading, obj.isKnown, obj.defaultCost, obj.costLabel,
            obj.associatedStatusType, obj.detailsStepRequires, obj.serialPopupRequires, obj.components);
    }
}

class ComponentDetailsStepRequires {
    constructor(public specification: boolean, public unitCost: boolean, public purchaseType: boolean, public showPurchaseOrder: boolean, public needPurchaseOrder: boolean, public componentOwner: boolean) { }
}

class ComponentSerialPopupRequires {
    constructor(public statusCombo: boolean, public dataFields: boolean, public compOwner: boolean, public rtd: boolean,
        public hours: boolean, public hoursLabel: boolean, public distance: boolean, public distanceLabel: boolean, public costDetails: boolean, public statusLabel: boolean,
        public hoursSinceLastNdt: boolean, public hoursSinceLastNdtLabel: boolean, public ndtStatus: boolean, public rcd: boolean, public specification: boolean, public specificationLabel: boolean,
        public retreader: boolean, public repairer: boolean) { }
}

class ComponentReceiveSummaryItem {
    constructor(public labelResource: string, public value: any, public show: boolean, public cost: boolean) { }
}

class ComponentReceiveSummaryItemMaker {
    static create(obj: ComponentReceiveSummaryItem) {
        return new ComponentReceiveSummaryItem(obj.labelResource, obj.value, obj.show, obj.cost);
    }
}

export const enum ComponentConditionType {
    new = 'New',
    transferred = 'Transferred',
    secondHand = '2nd Hand',
    openingBalance = 'Opening Balance',
    retreaded = 'Retreaded',
    retreadedOperated = 'RetreadedOperated',
    retreadedUnknown = 'RetreadedUnknown',
    repaired = 'Repaired',
    ndt = 'Ndt',
    pending = 'Pending'
}

class ComponentReceiveFactory {

    constructor(private amtXlatSvc: IAmtXlatSvc, private ocSecuritySvc: IOcSecuritySvc, private $filter: ng.IFilterService, private ocConfigSvc: IOcConfigSvc) { }

    /**
     * Get an array of Component Condition objects for the given Component Type.
     *
     * @param {string} componentTypeName
     * @param {boolean} skipValidation - If true, all relevant conditions will be returned without validating.
     * @param {boolean} allowThirdyPartyComponents - Site Setting specifying whether or not the Site accepts Third Party components.
     * @returns {Array<ConditionButton>} - An Array of valid Conditions.
     */
    getComponentConditions(componentTypeName: string, skipValidation: boolean, allowThirdPartyComponents: boolean, parentCondition: string) {

        let canReceivePending: boolean = this.ocSecuritySvc.isAuthorised('Security.Components.Receive.Pending', AccessTypes.readWrite);
        let canReceiveNew: boolean = this.ocSecuritySvc.isAuthorised('Security.Components.Receive.New', AccessTypes.readWrite);
        let canReceiveTransferred: boolean = this.ocSecuritySvc.isAuthorised('Security.Components.Receive.Transferred', AccessTypes.readWrite);
        let canReceiveOpeningBalanceSecondHand: boolean = this.ocSecuritySvc.isAuthorised('Security.Components.Receive.OpeningBalanceSecondHand', AccessTypes.readWrite);
        let canReceiveRetreaded: boolean = this.ocSecuritySvc.isAuthorised('Security.Components.Receive.Retreaded', AccessTypes.readWrite);
        let canReceiveRepaired: boolean = this.ocSecuritySvc.isAuthorised('Security.Components.Receive.Repaired', AccessTypes.readWrite);
        let canReceiveNDT: boolean = this.ocSecuritySvc.isAuthorised('Security.Components.Receive.NDT', AccessTypes.readWrite);

        let costAccessType = this.ocSecuritySvc.getCostAccessType();

        let componentConditions: Array<ComponentConditionButton> = [];

        let componentTypeDescription: string = componentTypeName ? this.amtXlatSvc.xlat('equipment.equipmentType' + componentTypeName) : '';
        let componentTypeNamePlural: string = componentTypeName ? this.amtXlatSvc.xlat('component.' + componentTypeName.toLowerCase() + '_Plural') : '';

        let poEnforceForNewReceive = this.ocConfigSvc.user.site.settings.purchaseOrdersEnforceForNewReceive;

        let newCondition = ComponentConditionButtonMaker.create({
            id: ComponentConditionType.new,
            name: this.amtXlatSvc.xlat('component.componentConditionNameNew'),
            description: this.amtXlatSvc.xlat('component.componentConditionDescriptionNew', componentTypeNamePlural),
            parentConditionId: null,
            loading: false,
            disabled: false,
            defaultCost: true,
            costLabel: 'component.unitCost',
            isKnown: false,
            associatedStatusType: StatusTypeName.new.toUpperCase(),
            detailsStepRequires: {
                specification: true,
                unitCost: true,
                purchaseType: true,
                componentOwner: true,
                showPurchaseOrder: poEnforceForNewReceive != ConfigurationOption.hide,
                needPurchaseOrder: poEnforceForNewReceive == ConfigurationOption.mandatory,
            },
            serialPopupRequires: {
                statusCombo: true,
                dataFields: false,
                compOwner: false,
                rtd: false,
                hours: false,
                hoursLabel: false,
                distance: false,
                distanceLabel: false,
                costDetails: false,
                statusLabel: false,
                hoursSinceLastNdt: false,
                hoursSinceLastNdtLabel: false,
                ndtStatus: false,
                rcd: false,
                specification: false,
                specificationLabel: false,
                retreader: false,
                repairer: false
            }
        });

        let transferredCondition = ComponentConditionButtonMaker.create({
            id: ComponentConditionType.transferred,
            name: this.amtXlatSvc.xlat('component.componentConditionNameTransferred'),
            description: this.amtXlatSvc.xlat('component.componentConditionDescriptionTransferred_Unavailable', componentTypeNamePlural),
            parentConditionId: null,
            disabled: true,
            loading: true,
            defaultCost: true,
            costLabel: 'component.cost',
            isKnown: true,
            associatedStatusType: StatusTypeName.dispatchedTransfer.toUpperCase(),
            detailsStepRequires: {
                specification: false,
                unitCost: false,
                purchaseType: false,
                componentOwner: false,
                showPurchaseOrder: false,
                needPurchaseOrder: false,
            },
            serialPopupRequires: {
                statusCombo: false,
                dataFields: false,
                compOwner: true,
                rtd: true,
                hours: false,
                hoursLabel: true,
                distance: false,
                distanceLabel: true,
                costDetails: costAccessType > this.ocSecuritySvc.accessTypes.readOnly,
                statusLabel: true,
                hoursSinceLastNdt: false,
                hoursSinceLastNdtLabel: true,
                ndtStatus: false,
                rcd: true,
                specification: false,
                specificationLabel: true,
                retreader: false,
                repairer: false
            }
        });

        let secondHandCondition = ComponentConditionButtonMaker.create({
            id: ComponentConditionType.secondHand,
            name: this.amtXlatSvc.xlat('component.componentConditionNameSecondHand'),
            description: this.amtXlatSvc.xlat('component.componentConditionDescriptionSecondHand', componentTypeNamePlural),
            parentConditionId: null,
            disabled: false,
            loading: false,
            isKnown: false,
            defaultCost: true,
            costLabel: 'component.unitCost',
            associatedStatusType: null,
            detailsStepRequires: {
                specification: true,
                unitCost: false,
                purchaseType: false,
                componentOwner: true,
                showPurchaseOrder: false,
                needPurchaseOrder: false,
            },
            serialPopupRequires: {
                statusCombo: false,
                dataFields: false,
                compOwner: true,
                rtd: true,
                hours: true,
                hoursLabel: false,
                distance: true,
                distanceLabel: false,
                costDetails: costAccessType > this.ocSecuritySvc.accessTypes.readOnly,
                statusLabel: true,
                hoursSinceLastNdt: true,
                hoursSinceLastNdtLabel: false,
                ndtStatus: false,
                rcd: true,
                specification: false,
                specificationLabel: true,
                retreader: false,
                repairer: false
            }
        });

        let openingBalanceCondition = ComponentConditionButtonMaker.create({
            id: ComponentConditionType.openingBalance,
            name: this.amtXlatSvc.xlat('component.componentConditionNameOpeningBalance'),
            description: this.amtXlatSvc.xlat('component.componentConditionDescriptionOpeningBalance', componentTypeNamePlural),
            parentConditionId: null,
            disabled: false,
            loading: false,
            isKnown: false,
            defaultCost: true,
            costLabel: 'component.unitCost',
            associatedStatusType: null,
            detailsStepRequires: {
                specification: true,
                unitCost: false,
                purchaseType: false,
                componentOwner: true,
                showPurchaseOrder: false,
                needPurchaseOrder: false,
            },
            serialPopupRequires: {
                statusCombo: false,
                dataFields: false,
                compOwner: true,
                rtd: true,
                hours: true,
                hoursLabel: false,
                distance: true,
                distanceLabel: false,
                costDetails: costAccessType > this.ocSecuritySvc.accessTypes.readOnly,
                statusLabel: true,
                hoursSinceLastNdt: true,
                hoursSinceLastNdtLabel: false,
                ndtStatus: false,
                rcd: true,
                specification: false,
                specificationLabel: true,
                retreader: false,
                repairer: false
            }
        });

        let retreadedCondition = ComponentConditionButtonMaker.create({
            id: ComponentConditionType.retreaded,
            name: this.amtXlatSvc.xlat('component.componentConditionNameRetreaded'),
            description: this.amtXlatSvc.xlat('component.componentConditionDescriptionRetreaded', componentTypeNamePlural),
            parentConditionId: null,
            disabled: false,
            loading: false,
            isKnown: false,
            defaultCost: false,
            costLabel: null,
            associatedStatusType: null,
            detailsStepRequires: null,
            serialPopupRequires: null
        });

        let retreadedKnownCondition = ComponentConditionButtonMaker.create({
            id: ComponentConditionType.retreadedOperated,
            name: this.amtXlatSvc.xlat('component.componentConditionNameRetreadedOperated', componentTypeDescription),
            description: this.amtXlatSvc.xlat('component.componentConditionDescriptionRetreaded_Unavailable', componentTypeNamePlural),
            parentConditionId: ComponentConditionType.retreaded,
            disabled: true,
            loading: true,
            isKnown: true,
            defaultCost: false,
            costLabel: 'component.retreadCost',
            associatedStatusType: StatusTypeName.dispatchedRetread.toUpperCase(),
            detailsStepRequires: {
                specification: false,
                unitCost: false,
                purchaseType: false,
                componentOwner: false,
                showPurchaseOrder: false,
                needPurchaseOrder: false,
            },
            serialPopupRequires: {
                statusCombo: true,
                dataFields: false,
                compOwner: true,
                rtd: true,
                hours: false,
                hoursLabel: true,
                distance: false,
                distanceLabel: true,
                costDetails: costAccessType > this.ocSecuritySvc.accessTypes.readOnly,
                statusLabel: false,
                hoursSinceLastNdt: false,
                hoursSinceLastNdtLabel: false,
                ndtStatus: false,
                rcd: true,
                specification: false,
                specificationLabel: true,
                retreader: true,
                repairer: false
            }
        });

        let retreadedUnknownCondition = ComponentConditionButtonMaker.create({
            id: ComponentConditionType.retreadedUnknown,
            name: this.amtXlatSvc.xlat('component.componentConditionNameRetreadedNever', componentTypeDescription),
            description: this.amtXlatSvc.xlat('component.componentConditionDescriptionRetreadedNever', componentTypeDescription),
            parentConditionId: ComponentConditionType.retreaded,
            disabled: false,
            loading: false,
            isKnown: false,
            defaultCost: false,
            costLabel: 'component.retreadCost',
            associatedStatusType: null,
            detailsStepRequires: {
                specification: true,
                unitCost: false,
                purchaseType: true,
                componentOwner: false,
                showPurchaseOrder: false,
                needPurchaseOrder: false,
            },
            serialPopupRequires: {
                statusCombo: true,
                dataFields: false,
                compOwner: true,
                rtd: true,
                hours: true,
                hoursLabel: false,
                distance: true,
                distanceLabel: false,
                costDetails: costAccessType > this.ocSecuritySvc.accessTypes.readOnly,
                statusLabel: false,
                hoursSinceLastNdt: false,
                hoursSinceLastNdtLabel: false,
                ndtStatus: false,
                rcd: true,
                specification: false,
                specificationLabel: false,
                retreader: true,
                repairer: false
            }
        });

        let ndtCondition = ComponentConditionButtonMaker.create({
            id: ComponentConditionType.ndt,
            name: this.amtXlatSvc.xlat('component.componentConditionNameNdt'),
            description: this.amtXlatSvc.xlat('component.componentConditionDescriptionNdt_Unavailable', componentTypeNamePlural),
            parentConditionId: null,
            disabled: true,
            loading: true,
            isKnown: true,
            defaultCost: false,
            costLabel: 'component.ndtCost',
            associatedStatusType: StatusTypeName.dispatchedOffsiteNdtInspection.toUpperCase(),
            detailsStepRequires: {
                specification: false,
                unitCost: false,
                purchaseType: false,
                componentOwner: true,
                showPurchaseOrder: false,
                needPurchaseOrder: false,
            },
            serialPopupRequires: {
                statusCombo: true,
                dataFields: false,
                compOwner: true,
                rtd: false,
                hours: false,
                hoursLabel: true,
                distance: false,
                distanceLabel: true,
                costDetails: true,
                statusLabel: false,
                hoursSinceLastNdt: false,
                hoursSinceLastNdtLabel: false,
                ndtStatus: true,
                rcd: false,
                specification: false,
                specificationLabel: true,
                retreader: false,
                repairer: false
            }
        });

        let repairedCondition = ComponentConditionButtonMaker.create({
            id: ComponentConditionType.repaired,
            name: this.amtXlatSvc.xlat('component.componentConditionNameRepaired'),
            description: this.amtXlatSvc.xlat('component.componentConditionDescriptionRepaired_Unavailable', componentTypeNamePlural),
            parentConditionId: null,
            disabled: true,
            loading: true,
            isKnown: true,
            defaultCost: false,
            costLabel: 'component.repairCost',
            associatedStatusType: StatusTypeName.dispatchedRepair.toUpperCase(),
            detailsStepRequires: {
                specification: false,
                unitCost: false,
                purchaseType: false,
                componentOwner: false,
                showPurchaseOrder: false,
                needPurchaseOrder: false,
            },
            serialPopupRequires: {
                statusCombo: true,
                dataFields: false,
                compOwner: true,
                rtd: true,
                hours: false,
                hoursLabel: true,
                distance: false,
                distanceLabel: true,
                costDetails: true,
                statusLabel: false,
                hoursSinceLastNdt: false,
                hoursSinceLastNdtLabel: false,
                ndtStatus: false,
                rcd: true,
                specification: false,
                specificationLabel: true,
                retreader: false,
                repairer: true
            }
        });

        let pendingCondition = ComponentConditionButtonMaker.create({
            id: ComponentConditionType.pending,
            name: this.amtXlatSvc.xlat('component.componentConditionNamePending', componentTypeDescription),
            description: this.amtXlatSvc.xlat('component.componentConditionDescriptionPending_Unavailable', componentTypeNamePlural),
            parentConditionId: null,
            disabled: true,
            loading: true,
            isKnown: true,
            defaultCost: true,
            costLabel: 'component.cost',
            associatedStatusType: null,
            detailsStepRequires: {
                specification: false,
                unitCost: false,
                purchaseType: false,
                componentOwner: false,
                showPurchaseOrder: false,
                needPurchaseOrder: false,
            },
            serialPopupRequires: {
                statusCombo: false,
                dataFields: true,
                compOwner: true,
                rtd: false,
                hours: false,
                hoursLabel: false,
                distance: false,
                distanceLabel: false,
                costDetails: costAccessType > this.ocSecuritySvc.accessTypes.readOnly,
                statusLabel: false,
                hoursSinceLastNdt: false,
                hoursSinceLastNdtLabel: false,
                ndtStatus: false,
                rcd: false,
                specification: true,
                specificationLabel: false,
                retreader: false,
                repairer: false
            }
        });

        if (skipValidation) {

            componentConditions.push(newCondition);
            componentConditions.push(transferredCondition);
            componentConditions.push(secondHandCondition);
            componentConditions.push(openingBalanceCondition);
            componentConditions.push(retreadedCondition);
            componentConditions.push(retreadedKnownCondition);
            componentConditions.push(retreadedUnknownCondition);
            componentConditions.push(ndtCondition);
            componentConditions.push(repairedCondition);
            componentConditions.push(pendingCondition);

        } else {

            if (canReceiveNew && (!allowThirdPartyComponents || componentTypeName.toLowerCase() === EquipmentTypeName.chain))
                componentConditions.push(newCondition);

            if (canReceiveTransferred)
                componentConditions.push(transferredCondition);

            if (canReceiveOpeningBalanceSecondHand) {
                componentConditions.push(secondHandCondition);
                componentConditions.push(openingBalanceCondition);
            }

            if (canReceiveRetreaded && componentTypeName.toLowerCase() === EquipmentTypeName.tyre) {
                componentConditions.push(retreadedCondition);
                componentConditions.push(retreadedKnownCondition);
                componentConditions.push(retreadedUnknownCondition);
            }

            if (canReceiveNDT && componentTypeName.toLowerCase() === EquipmentTypeName.rim)
                componentConditions.push(ndtCondition);

            if (canReceiveRepaired)
                componentConditions.push(repairedCondition);

            if (canReceivePending && allowThirdPartyComponents && componentTypeName.toLowerCase() !== EquipmentTypeName.chain)
                componentConditions.push(pendingCondition);
        }

        if (!parentCondition) {
            componentConditions = componentConditions.filter(c => c.parentConditionId == null);
        } else if (parentCondition !== 'All') {
            componentConditions = componentConditions.filter(c => c.parentConditionId === parentCondition);
        }

        return componentConditions;
    }

    // explicitly define the pending fitted condition, which sits at the component type level
    getPendingFittedCondition() {

        let costAccessType = this.ocSecuritySvc.getCostAccessType();

        let pendingFittedCondition = ComponentConditionButtonMaker.create({
            id: ComponentConditionType.pending,
            name: this.amtXlatSvc.xlat('component.pendingFittedComponents'),
            description: this.amtXlatSvc.xlat('component.pendingFittedComponents'),
            parentConditionId: null,
            disabled: true,
            loading: true,
            isKnown: true,
            defaultCost: true,
            costLabel: 'component.cost',
            associatedStatusType: null,
            detailsStepRequires: {
                specification: false,
                unitCost: false,
                purchaseType: false,
                componentOwner: false,
                showPurchaseOrder: false,
                needPurchaseOrder: false,
            },
            serialPopupRequires: {
                statusCombo: false,
                dataFields: true,
                compOwner: true,
                rtd: false,
                hours: false,
                hoursLabel: false,
                distance: false,
                distanceLabel: false,
                costDetails: costAccessType > this.ocSecuritySvc.accessTypes.readOnly,
                statusLabel: false,
                hoursSinceLastNdt: false,
                hoursSinceLastNdtLabel: false,
                ndtStatus: false,
                rcd: false,
                specification: true,
                specificationLabel: false,
                retreader: false,
                repairer: false
            }
        });

        return pendingFittedCondition;
    }

    /**
    * Get the ocDynamicGrid columns for the 'Serials' grid for known vehicle receive.
    *
    * @param {ComponentConditionButton} condition - the condition to build the serial grid for.
    * @param {any} equipmentType - the selected equipment type.
    * @param {boolean} errored - is the receive in error? (pending receival)
    * @returns {Array<OcDynamicGridColumn>} - An Array of OcDynamicGrid columns that make up the serial screen for known vehicle require.
    */
    getSerialStepColumns(condition: ComponentConditionButton, equipmentType: any, errored: boolean) {

        let serialStepColumns: Array<OcDynamicGridColumn> = [];

        if (condition) {

            serialStepColumns.push(OcDynamicGridColumnMaker.create({
                headerResource: 'equipment.receiveErrorNo_label',
                type: OcDynamicGridColumnType.string,
                valuePath: 'itemNo',
                colSpan: 1,
                show: errored === true,
                columnAlignment: OcDynamicGridAlignment.center
            }));

            switch (condition.id) {

                case (ComponentConditionType.new):

                    // Man. Serial No.
                    serialStepColumns.push(OcDynamicGridColumnMaker.create({
                        headerResource: 'component.manufacturerSerialNumber_short_label',
                        type: OcDynamicGridColumnType.string,
                        valuePath: 'serialNumber.manufacturer',
                        onClick: 'editComponentSerial',
                        colSpan: 5,
                        columnAlignment: OcDynamicGridAlignment.left
                    }));

                    // Status
                    serialStepColumns.push(OcDynamicGridColumnMaker.create({
                        headerResource: 'component.status_label',
                        type: OcDynamicGridColumnType.string,
                        valuePath: 'status.description',
                        colSpan: 4,
                        columnAlignment: OcDynamicGridAlignment.center
                    }));

                    // Delete
                    serialStepColumns.push(OcDynamicGridColumnMaker.create({
                        headerResource: 'component.receiveWizardGridDeleteTitle',
                        type: OcDynamicGridColumnType.string,
                        columnResource: 'component.receiveWizardGridDeleteTitle',
                        colSpan: 2,
                        columnAlignment: OcDynamicGridAlignment.center,
                        onClick: 'deleteComponentSerial',
                        sortable: false
                    }));

                    break;

                case (ComponentConditionType.openingBalance):
                case (ComponentConditionType.secondHand):
                case (ComponentConditionType.retreadedUnknown):

                    // Man. Serial No.
                    serialStepColumns.push(OcDynamicGridColumnMaker.create({
                        headerResource: 'component.manufacturerSerialNumber_short_label',
                        type: OcDynamicGridColumnType.string,
                        valuePath: 'serialNumber.manufacturer',
                        onClick: 'editComponentSerial',
                        colSpan: 3,
                        columnAlignment: OcDynamicGridAlignment.left
                    }));

                    // Specification
                    serialStepColumns.push(OcDynamicGridColumnMaker.create({
                        headerResource: 'component.componentSpec_label',
                        type: OcDynamicGridColumnType.string,
                        valuePath: 'specification.description',
                        colSpan: 6,
                        columnAlignment: OcDynamicGridAlignment.left
                    }));

                    // Delete
                    serialStepColumns.push(OcDynamicGridColumnMaker.create({
                        headerResource: 'component.receiveWizardGridDeleteTitle',
                        type: OcDynamicGridColumnType.string,
                        columnResource: 'component.receiveWizardGridDeleteTitle',
                        colSpan: 2,
                        columnAlignment: OcDynamicGridAlignment.center,
                        onClick: 'deleteComponentSerial',
                        sortable: false
                    }));

                    break;

                case (ComponentConditionType.pending):

                    // Man. Serial No.
                    serialStepColumns.push(OcDynamicGridColumnMaker.create({
                        headerResource: 'component.manufacturerSerialNumber_short_label',
                        type: OcDynamicGridColumnType.string,
                        valuePath: 'serialNumber.manufacturer',
                        onClick: 'editComponentSerial',
                        colSpan: 3,
                        columnAlignment: OcDynamicGridAlignment.left
                    }));

                    // Type
                    serialStepColumns.push(OcDynamicGridColumnMaker.create({
                        headerResource: 'component.componentType_label',
                        type: OcDynamicGridColumnType.string,
                        valuePath: 'equipmentType.description',
                        colSpan: 2,
                        columnAlignment: OcDynamicGridAlignment.left
                    }));

                    // Vehicle (Position)
                    serialStepColumns.push(OcDynamicGridColumnMaker.create({
                        headerResource: 'component.componentVehiclePosition_label',
                        type: OcDynamicGridColumnType.string,
                        valuePath: 'fittedToPositionDescription',
                        show: !equipmentType || equipmentType.id === condition.id, // pending fitted
                        colSpan: 4,
                        columnAlignment: OcDynamicGridAlignment.center
                    }));

                    // Receive
                    serialStepColumns.push(OcDynamicGridColumnMaker.create({
                        headerResource: 'component.receive_label',
                        type: OcDynamicGridColumnType.checkbox,
                        valuePath: 'selected',
                        colSpan: 2,
                        columnAlignment: OcDynamicGridAlignment.center,
                        hideIfInvalid: true,
                        editable: 'valid'
                    }));

                    break;

                case (ComponentConditionType.transferred):
                case (ComponentConditionType.retreadedOperated):
                case (ComponentConditionType.repaired):
                case (ComponentConditionType.ndt):

                    // Man. Serial No.
                    serialStepColumns.push(OcDynamicGridColumnMaker.create({
                        headerResource: 'component.manufacturerSerialNumber_short_label',
                        type: OcDynamicGridColumnType.string,
                        valuePath: 'serialNumber.manufacturer',
                        onClick: 'editComponentSerial',
                        colSpan: 2,
                        columnAlignment: OcDynamicGridAlignment.left
                    }));

                    // Specification
                    serialStepColumns.push(OcDynamicGridColumnMaker.create({
                        headerResource: 'component.componentSpec_label',
                        type: OcDynamicGridColumnType.string,
                        valuePath: 'specification.description',
                        colSpan: 5,
                        columnAlignment: OcDynamicGridAlignment.left
                    }));

                    // Site
                    serialStepColumns.push(OcDynamicGridColumnMaker.create({
                        headerResource: 'equipment.site',
                        type: OcDynamicGridColumnType.string,
                        valuePath: 'site',
                        colSpan: 2,
                        columnAlignment: OcDynamicGridAlignment.left
                    }));

                    // Receive
                    serialStepColumns.push(OcDynamicGridColumnMaker.create({
                        headerResource: 'component.receive_label',
                        type: OcDynamicGridColumnType.checkbox,
                        valuePath: 'selected',
                        colSpan: 2,
                        columnAlignment: OcDynamicGridAlignment.center,
                        hideIfInvalid: true,
                        editable: 'valid'
                    }));

                    break;
            }
        }

        return serialStepColumns;
    }

    /**
    * Get an array of Component Condition objects for the given Component Type.
    *
    * @param {any} viewModel                 
    * @returns {Array<ComponentReceiveSummaryItem>} - An Array of valid Conditions.
    */
    buildReceiveSummary(viewModel: any) {

        let summaryItems: Array<ComponentReceiveSummaryItem> = [];

        let type = ComponentReceiveSummaryItemMaker.create({
            labelResource: 'component.componentType_label',
            value: viewModel.selectedComponentType.description,
            show: true,
            cost: false
        });

        let condition = ComponentReceiveSummaryItemMaker.create({
            labelResource: 'component.componentCondition_label',
            value: viewModel.selectedComponentCondition.name,
            show: true,
            cost: false
        });

        let date = ComponentReceiveSummaryItemMaker.create({
            labelResource: 'component.componentDateTime_label',
            value: this.$filter('date')(viewModel.combinedDateTime, 'short'),
            show: true,
            cost: false
        });

        let purchaseOrder = ComponentReceiveSummaryItemMaker.create({
            labelResource: 'component.componentPO_label',
            value: viewModel.purchaseOrder ? viewModel.purchaseOrder.name : viewModel.purchaseOrderNumber,
            show: !!(viewModel.purchaseOrder ? viewModel.purchaseOrder.name : viewModel.purchaseOrderNumber),
            cost: false
        });

        let specification = ComponentReceiveSummaryItemMaker.create({
            labelResource: 'component.componentSpec_label',
            value: viewModel.selectedSpec.description,
            show: true,
            cost: false
        });

        let cost = ComponentReceiveSummaryItemMaker.create({
            labelResource: viewModel.selectedComponentCondition.costLabel,
            value: (viewModel.unitCost != null && viewModel.unitCost.description ? viewModel.unitCost.description : viewModel.currencySymbol + this.$filter('number')(viewModel.unitCost, viewModel.currencyDecimalPlaces)),
            show: viewModel.unitCost != null,
            cost: true
        });

        let purchaseType = ComponentReceiveSummaryItemMaker.create({
            labelResource: 'component.componentPurchaseType_label',
            value: (viewModel.purchaseType ? viewModel.purchaseType.name : null),
            show: (!!viewModel.purchaseType && !!viewModel.purchaseType.name),
            cost: false
        });

        let comment = ComponentReceiveSummaryItemMaker.create({
            labelResource: 'component.componentComment_label',
            value: viewModel.comment,
            show: !!viewModel.comment,
            cost: false
        });

        let location = ComponentReceiveSummaryItemMaker.create({
            labelResource: 'component.componentLocation_label',
            value: (viewModel.selectedLocation ? viewModel.selectedLocation.name : null),
            show: (!!viewModel.selectedLocation && !!viewModel.selectedLocation.name),
            cost: false
        });

        let unitCount = ComponentReceiveSummaryItemMaker.create({
            labelResource: 'component.componentUnitCount_label',
            value: viewModel.serials.length,
            show: true,
            cost: false
        });

        if (type.show)
            summaryItems.push(type);

        if (condition.show)
            summaryItems.push(condition);

        if (date.show)
            summaryItems.push(date);

        if (purchaseOrder.show)
            summaryItems.push(purchaseOrder);

        if (specification.show)
            summaryItems.push(specification);

        if (cost.show)
            summaryItems.push(cost);

        if (purchaseType.show)
            summaryItems.push(purchaseType);

        if (comment.show)
            summaryItems.push(comment);

        if (location.show)
            summaryItems.push(location);

        if (unitCount.show)
            summaryItems.push(unitCount);

        return summaryItems;
    }

    buildPendingReceiveSummary(pendingFitted: boolean) {

        let summaryItems: Array<OcDynamicGridColumn> = [];

        summaryItems.push(OcDynamicGridColumnMaker.create({
            headerResource: 'component.manufacturerSerialNumber_short_label',
            type: OcDynamicGridColumnType.string,
            valuePath: 'serialNumber.manufacturer',
            colSpan: 5,
            columnAlignment: OcDynamicGridAlignment.center
        }));

        summaryItems.push(OcDynamicGridColumnMaker.create({
            headerResource: 'component.componentType_label',
            type: OcDynamicGridColumnType.string,
            valuePath: 'equipmentType.description',
            colSpan: 3,
            columnAlignment: OcDynamicGridAlignment.center
        }));

        summaryItems.push(OcDynamicGridColumnMaker.create({
            headerResource: 'component.componentVehiclePosition_label',
            type: OcDynamicGridColumnType.string,
            valuePath: 'fittedToPositionDescription',
            colSpan: 4,
            columnAlignment: OcDynamicGridAlignment.center,
            show: pendingFitted
        }));

        return summaryItems;
    }
}

angular.module('app.component').factory('componentReceiveFactory', ['amtXlatSvc', 'ocSecuritySvc', '$filter', 'ocConfigSvc',
    (amtXlatSvc: IAmtXlatSvc, ocSecuritySvc: IOcSecuritySvc, $filter: ng.IFilterService, ocConfigSvc: IOcConfigSvc) => new ComponentReceiveFactory(amtXlatSvc, ocSecuritySvc, $filter, ocConfigSvc)
]);