//import angular from 'angular';
import tmpl from './uploadErrors.html';



angular.module("app.fieldSurveys").component('uploadErrors', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
		controller: ['WindowFactory', 'enums',
			function (WindowFactory: IWindowFactory, enums)
            {
                var vm = this;
                
                this.$onInit = function () {
					angular.extend(vm, vm.wnd.initParams);
					
					vm.buttons = [
						{
							primary: true, 
							cancel: false, 
							value: 'framework.close_label',
							name: 'closeButton',
							click: 'close',
							type: enums.buttonTypes.button
						}
					];
					
					vm.buttonStates = {
						closeButton: {
							visible: true, 
							disabled: false
						}
					};
					
					vm.buttonMethods = {
						close: vm.close
					};
				};
				
                vm.close = function () {
                    if (vm.wnd.onDataChanged) {
                        vm.wnd.onDataChanged('close');
                    }

					WindowFactory.closeWindow(vm.wnd);
                };		
                
				return vm;
        	}
		]
    });
