<!--Main-->
<div class="k-loading-image" *ngIf="!result.receivedResult"></div>

<section class="scrap-life-trend-chart-container" *ngIf="result.receivedResult && result.isAuth">

    <!--Chart Container-->
    <kendo-chart class="scrap-trend-life-chart"
                 renderAs="canvas"
                 [pannable]="{ lock: 'y' }"
                 [zoomable]="{ mousewheel: { lock: 'y' } }"
                 [transitions]="true"
                 (legendItemClick)="onLegendItemClick($event)">
        <kendo-chart-legend [visible]="showChartLegend" position="bottom"></kendo-chart-legend>

        <kendo-chart-series>
            <!-- Column - Qty -->
            <!--New Qty-->
            <kendo-chart-series-item *ngIf="result.apiResult.include.new"
                                     type="column"
                                     [color]="result.apiResult.chartColor.new"
                                     [stack]="{group: 'column'}"
                                     [data]="result.apiResult.chart"
                                     [visible]="visible.new"
                                     [name]="ui.new"
                                     field="new.qty"
                                     axis="qty"
                                     categoryField="label">
                <kendo-chart-series-item-tooltip background="black">
                    <ng-template kendoChartSeriesTooltipTemplate
                                 let-value="value"
                                 let-category="category">
                        <div><span> {{ui.scrapDate}}: {{category}} </span></div>
                        <div><span> {{ui.lifeType}}: {{ui.new}} </span></div>
                        <div><span> {{ui.qtyOfScrap}}: {{value}} </span></div>
                    </ng-template>
                </kendo-chart-series-item-tooltip>
            </kendo-chart-series-item>

            <!--Major Repair Qty -->
            <kendo-chart-series-item *ngIf="result.apiResult.include.majorRepair"
                                     type="column"
                                     [color]="result.apiResult.chartColor.majorRepair"
                                     [stack]="{group: 'column'}"
                                     [data]="result.apiResult.chart"
                                     [name]="ui.major"
                                     [visible]="visible.majorRepair"
                                     field="majorRepair.qty"
                                     axis="qty"
                                     categoryField="label">
                <kendo-chart-series-item-tooltip background="black">
                    <ng-template kendoChartSeriesTooltipTemplate
                                 let-value="value"
                                 let-category="category">
                        <div><span> {{ui.scrapDate}}: {{category}} </span></div>
                        <div><span> {{ui.lifeType}}: {{ui.major}} </span></div>
                        <div><span> {{ui.qtyOfScrap}}: {{value}} </span></div>
                    </ng-template>
                </kendo-chart-series-item-tooltip>
            </kendo-chart-series-item>

            <!--Cosmetic Repair Qty -->
            <kendo-chart-series-item *ngIf="result.apiResult.include.cosmeticRepair"
                                     type="column"
                                     [color]="result.apiResult.chartColor.cosmeticRepair"
                                     [stack]="{group: 'column'}"
                                     [data]="result.apiResult.chart"
                                     [name]="ui.cosmetic"
                                     [visible]="visible.cosmeticRepair"
                                     field="cosmeticRepair.qty"
                                     axis="qty"
                                     categoryField="label">
                <kendo-chart-series-item-tooltip background="black">
                    <ng-template kendoChartSeriesTooltipTemplate
                                 let-value="value"
                                 let-category="category">
                        <div><span> {{ui.scrapDate}}: {{category}} </span></div>
                        <div><span> {{ui.lifeType}}: {{ui.cosmetic}} </span></div>
                        <div><span> {{ui.qtyOfScrap}}: {{value}} </span></div>
                    </ng-template>
                </kendo-chart-series-item-tooltip>
            </kendo-chart-series-item>

            <!--Transfer Qty -->
            <kendo-chart-series-item *ngIf="result.apiResult.include.transfer"
                                     type="column"
                                     [color]="result.apiResult.chartColor.transfer"
                                     [stack]="{group: 'column'}"
                                     [data]="result.apiResult.chart"
                                     [name]="ui.transfer"
                                     [visible]="visible.transfer"
                                     axis="qty"
                                     field="transfer.qty"
                                     categoryField="label">
                <kendo-chart-series-item-tooltip background="black">
                    <ng-template kendoChartSeriesTooltipTemplate
                                 let-value="value"
                                 let-category="category">
                        <div><span> {{ui.scrapDate}}: {{category}} </span></div>
                        <div><span> {{ui.lifeType}}: {{ui.transfer}} </span></div>
                        <div><span> {{ui.qtyOfScrap}}: {{value}} </span></div>
                    </ng-template>
                </kendo-chart-series-item-tooltip>
            </kendo-chart-series-item>

            <!--Retread Qty -->
            <kendo-chart-series-item *ngIf="result.apiResult.include.retread"
                                     type="column"
                                     [color]="result.apiResult.chartColor.retread"
                                     [stack]="{group: 'column'}"
                                     [data]="result.apiResult.chart"
                                     [name]="ui.retread"
                                     [visible]="visible.retread"
                                     axis="qty"
                                     [name]="ui.retread"
                                     field="retread.qty"
                                     categoryField="label">
                <kendo-chart-series-item-tooltip background="black">
                    <ng-template kendoChartSeriesTooltipTemplate
                                 let-value="value"
                                 let-category="category">
                        <div><span> {{ui.scrapDate}}: {{category}} </span></div>
                        <div><span> {{ui.lifeType}}: {{ui.retread}} </span></div>
                        <div><span> {{ui.qtyOfScrap}}: {{value}} </span></div>
                    </ng-template>
                </kendo-chart-series-item-tooltip>
            </kendo-chart-series-item>

            <!--SecondHand Qty -->
            <kendo-chart-series-item *ngIf="result.apiResult.include.secondHand"
                                     type="column"
                                     [color]="result.apiResult.chartColor.secondHand"
                                     [stack]="{group: 'column'}"
                                     [data]="result.apiResult.chart"
                                     [name]="ui.secondHand"
                                     [visible]="visible.secondHand"
                                     axis="qty"
                                     field="secondHand.qty"
                                     categoryField="label">
                <kendo-chart-series-item-tooltip background="black">
                    <ng-template kendoChartSeriesTooltipTemplate
                                 let-value="value"
                                 let-category="category">
                        <div><span> {{ui.scrapDate}}: {{category}} </span></div>
                        <div><span> {{ui.lifeType}}: {{ui.secondHand}} </span></div>
                        <div><span> {{ui.qtyOfScrap}}: {{value}} </span></div>
                    </ng-template>
                </kendo-chart-series-item-tooltip>
            </kendo-chart-series-item>

            <!-- Line - Hours-->
            <!--Target Hours-->
            <kendo-chart-series-item *ngIf="result.apiResult.include.targetHour"
                                     type="line"
                                     field="targetHours"
                                     axis="hours"
                                     categoryField="label"
                                     [color]="result.apiResult.chartColor.targetHours"
                                     [data]="result.apiResult.chart"
                                     [style]="targetLineStyle"
                                     [name]="ui.targetHours"
                                     [visible]="visible.targetHour"
                                     [markers]="{ visible: false }">
                <kendo-chart-series-item-tooltip background="black">
                    <ng-template kendoChartSeriesTooltipTemplate
                                 let-value="value"
                                 let-category="category">
                        <div><span> {{ui.targetHours}}: {{ value | number:'1.0-2' }} </span></div>
                    </ng-template>
                </kendo-chart-series-item-tooltip>
            </kendo-chart-series-item>

            <!--Previous Year Avg Hours-->
            <kendo-chart-series-item *ngIf="result.apiResult.include.previousYearAvgHours"
                                     type="line"
                                     field="previousYearAvgHours"
                                     axis="hours"
                                     categoryField="label"
                                     [name]="ui.averageLife"
                                     [color]="result.apiResult.chartColor.previousYearAvgHours"
                                     [data]="result.apiResult.chart"
                                     [style]="targetLineStyle"
                                     [visible]="visible.previousYearAvgHours"
                                     [markers]="{ visible: false }">
                <kendo-chart-series-item-tooltip background="black">
                    <ng-template kendoChartSeriesTooltipTemplate
                                 let-value="value"
                                 let-category="category">
                        <div><span> {{ui.scrapDate}}: {{category}} </span></div>
                        <div><span> {{ui.twelveMonthsMa}}: {{ value | number:'1.0-2' }} </span></div>
                    </ng-template>
                </kendo-chart-series-item-tooltip>
            </kendo-chart-series-item>

            <!--Scatter - Type Hours-->
            <!--New-->
            <kendo-chart-series-item *ngIf="result.apiResult.include.new"
                                     type="line"
                                     field="new.avgHours"
                                     axis="hours"
                                     categoryField="label"
                                     color="rgba(0, 0, 0, 0)"
                                     [data]="result.apiResult.chart"
                                     [visible]="visible.new"
                                     [markers]="{ visible: true, background: result.apiResult.chartColor.new, border: {color: '#000000', opacity: 1, width: 1},type: 'circle' }">
                <kendo-chart-series-item-tooltip background="black">
                    <ng-template kendoChartSeriesTooltipTemplate
                                 let-value="value"
                                 let-category="category">
                        <div><span> {{ui.scrapDate}}: {{category}} </span></div>
                        <div><span> {{ui.lifeType}}: {{ui.new}} </span></div>
                        <div><span> {{ui.averageLife}}: {{ value | number:'1.0-2' }} </span></div>
                    </ng-template>
                </kendo-chart-series-item-tooltip>
            </kendo-chart-series-item>

            <!--Major Repair-->
            <kendo-chart-series-item *ngIf="result.apiResult.include.majorRepair"
                                     type="line"
                                     field="majorRepair.avgHours"
                                     axis="hours"
                                     categoryField="label"
                                     color="rgba(0, 0, 0, 0)"
                                     [visible]="visible.majorRepair"
                                     [data]="result.apiResult.chart"
                                     [markers]="{ visible: true, background: result.apiResult.chartColor.majorRepair, border: {color: '#000000', opacity: 1, width: 1}, type: 'circle' }">
                <kendo-chart-series-item-tooltip background="black">
                    <ng-template kendoChartSeriesTooltipTemplate
                                 let-value="value"
                                 let-category="category">
                        <div><span> {{ui.scrapDate}}: {{category}} </span></div>
                        <div><span> {{ui.lifeType}}: {{ui.major}} </span></div>
                        <div><span> {{ui.averageLife}}: {{ value | number:'1.0-2' }} </span></div>
                    </ng-template>
                </kendo-chart-series-item-tooltip>
            </kendo-chart-series-item>

            <!--Transfer-->
            <kendo-chart-series-item *ngIf="result.apiResult.include.transfer"
                                     type="line"
                                     field="transfer.avgHours"
                                     axis="hours"
                                     categoryField="label"
                                     color="rgba(0, 0, 0, 0)"
                                     [visible]="visible.transfer"
                                     [data]="result.apiResult.chart"
                                     [markers]="{ visible: true, background: result.apiResult.chartColor.transfer, border: {color: '#000000', opacity: 1, width: 1}, type: 'circle' }">
                <kendo-chart-series-item-tooltip background="black">
                    <ng-template kendoChartSeriesTooltipTemplate
                                 let-value="value"
                                 let-category="category">
                        <div><span> {{ui.scrapDate}}: {{category}} </span></div>
                        <div><span> {{ui.lifeType}}: {{ui.transfer}} </span></div>
                        <div><span> {{ui.averageLife}}: {{ value | number:'1.0-2' }} </span></div>
                    </ng-template>
                </kendo-chart-series-item-tooltip>
            </kendo-chart-series-item>

            <!--Retread-->
            <kendo-chart-series-item *ngIf="result.apiResult.include.retread"
                                     type="line"
                                     field="retread.avgHours"
                                     axis="hours"
                                     categoryField="label"
                                     color="rgba(0, 0, 0, 0)"
                                     [visible]="visible.retread"
                                     [data]="result.apiResult.chart"
                                     [markers]="{ visible: true, background: result.apiResult.chartColor.retread, border: {color: '#000000', opacity: 1, width: 1}, type: 'circle' }">
                <kendo-chart-series-item-tooltip background="black">
                    <ng-template kendoChartSeriesTooltipTemplate
                                 let-value="value"
                                 let-category="category">
                        <div><span> {{ui.scrapDate}}: {{category}} </span></div>
                        <div><span> {{ui.lifeType}}: {{ui.retread}} </span></div>
                        <div><span> {{ui.averageLife}}: {{ value | number:'1.0-2' }} </span></div>
                    </ng-template>
                </kendo-chart-series-item-tooltip>
            </kendo-chart-series-item>

            <!--Cosmetic Repair-->
            <kendo-chart-series-item *ngIf="result.apiResult.include.cosmeticRepair"
                                     type="line"
                                     field="cosmeticRepair.avgHours"
                                     axis="hours"
                                     categoryField="label"
                                     color="rgba(0, 0, 0, 0)"
                                     [visible]="visible.cosmeticRepair"
                                     [data]="result.apiResult.chart"
                                     [markers]="{ visible: true, background: result.apiResult.chartColor.cosmeticRepair, border: {color: '#000000', opacity: 1, width: 1}, type: 'circle' }">
                <kendo-chart-series-item-tooltip background="black">
                    <ng-template kendoChartSeriesTooltipTemplate
                                 let-value="value"
                                 let-category="category">
                        <div><span> {{ui.scrapDate}}: {{category}} </span></div>
                        <div><span> {{ui.lifeType}}: {{ui.cosmetic}} </span></div>
                        <div><span> {{ui.averageLife}}: {{ value | number:'1.0-2' }} </span></div>
                    </ng-template>
                </kendo-chart-series-item-tooltip>
            </kendo-chart-series-item>

            <!--Second Hand-->
            <kendo-chart-series-item *ngIf="result.apiResult.include.secondHand"
                                     type="line"
                                     field="secondHand.avgHours"
                                     axis="hours"
                                     categoryField="label"
                                     color="rgba(0, 0, 0, 0)"
                                     [visible]="visible.secondHand"
                                     [data]="result.apiResult.chart"
                                     [markers]="{ visible: true, background: result.apiResult.chartColor.secondHand, border: {color: '#000000', opacity: 1, width: 1}, type: 'circle' }">
                <kendo-chart-series-item-tooltip background="black">
                    <ng-template kendoChartSeriesTooltipTemplate
                                 let-value="value"
                                 let-category="category">
                        <div><span> {{ui.scrapDate}}: {{category}} </span></div>
                        <div><span> {{ui.lifeType}}: {{ui.secondHand}} </span></div>
                        <div><span> {{ui.averageLife}}: {{ value | number:'1.0-2' }} </span></div>
                    </ng-template>
                </kendo-chart-series-item-tooltip>
            </kendo-chart-series-item>
        </kendo-chart-series>

        <!--Axis-->
        <kendo-chart-value-axis>
            <kendo-chart-value-axis-item name="hours"
                                         [min]="0"
                                         [max]="result.apiResult.chartMax.hours">
                <kendo-chart-value-axis-item-labels [content]="formatHours">
                </kendo-chart-value-axis-item-labels> 
            </kendo-chart-value-axis-item>
            <kendo-chart-value-axis-item name="qty"
                                         [min]="0"
                                         [labels]="{format: axisLabels.format}"
                                         [max]="result.apiResult.chartMax.qty">
            </kendo-chart-value-axis-item>
        </kendo-chart-value-axis>

        <!--Category-->
        <kendo-chart-category-axis>
            <kendo-chart-category-axis-item [categories]="labels"
                                            [axisCrossingValue]="crossingValues"
                                            [labels]="{ rotation: 'auto' }">
            </kendo-chart-category-axis-item>
        </kendo-chart-category-axis>
    </kendo-chart>
</section>

<div *ngIf="result.receivedResult && !result.isAuth">
    <h4 class="widget-description">{{ui.noResult}}</h4>
</div>

