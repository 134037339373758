/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var kendo_draggable_1 = require("@telerik/kendo-draggable");
var drag_clue_service_1 = require("./drag-clue/drag-clue.service");
var drop_hint_service_1 = require("./drop-hint/drop-hint.service");
var drag_clue_template_directive_1 = require("./drag-clue/drag-clue-template.directive");
var drop_hint_template_directive_1 = require("./drop-hint/drop-hint-template.directive");
var drag_and_drop_utils_1 = require("./drag-and-drop-utils");
var utils_1 = require("../utils");
var treeview_component_1 = require("../treeview.component");
var models_1 = require("./models");
var DEFAULT_SCROLL_SETTINGS = {
    enabled: true,
    step: 1,
    interval: 1
};
/**
 * A directive which enables the dragging and dropping items inside the current TreeView or between multiple linked TreeView component instances
 * ([see example]({% slug draganddrop_treeview %})).
 *
 * Triggers the [`nodeDragStart`]({% slug api_treeview_treeviewcomponent %}#toc-nodedragstart),
 * [`nodeDrag`]({% slug api_treeview_treeviewcomponent %}#toc-nodedrag),
 * [`nodeDrop`]({% slug api_treeview_treeviewcomponent %}#toc-nodedrop),
 * [`nodeDragEnd`]({% slug api_treeview_treeviewcomponent %}#toc-nodedragend),
 * [`addItem`]({% slug api_treeview_treeviewcomponent %}#toc-additem) and
 * [`removeItem`]({% slug api_treeview_treeviewcomponent %}#toc-removeitem)
 * events when the corresponding actions occur on the respective TreeView instance.
 */
var DragAndDropDirective = /** @class */ (function () {
    function DragAndDropDirective(element, zone, treeview, dragClueService, dropHintService) {
        this.element = element;
        this.zone = zone;
        this.treeview = treeview;
        this.dragClueService = dragClueService;
        this.dropHintService = dropHintService;
        /**
         * Specifies whether the `removeItem` event will be fired after an item is dropped when the `ctrl` key is pressed.
         * If enabled, the `removeItem` event will not be fired on the source TreeView
         * ([see example]({% slug draganddrop_treeview %}#toc-multiple-treeviews)).
         *
         * @default false
         */
        this.allowCopy = false;
        /**
         * Specifes the TreeViewComponent instances into which dragged items from the current TreeViewComponent can be dropped
         * ([see example]({% slug draganddrop_treeview %}#toc-multiple-treeviews)).
         */
        this.dropZoneTreeViews = [];
        /**
         * Specifies the distance in pixels from the initial item pointerdown event, before the dragging is initiated.
         * The `nodeDragStart` and all consequent TreeView drag events will not be fired until the actual dragging begins.
         *
         * @default 5
         */
        this.startDragAfter = 5;
        /**
         * Controlls the auto-scrolling behavior during drag-and-drop ([see example]({% slug draganddrop_treeview %}#toc-auto-scrolling)).
         * Enbaled by default. To turn the auto-scrolling off, set this prop to `false`.
         *
         * By default, the scrolling will be performed by 1 pixel at every 1 millisecond, when the dragged item reaches the top or the bottom of the scrollable container.
         * The `step` and `interval` can be overridden by providing a `DragAndDropScrollSettings` object to this prop.
         *
         * @default true
         */
        this.autoScroll = true;
        /**
         * @hidden
         */
        this.userSelectStyle = 'none';
        /**
         * Describes the offset of the parent element if the latter has the `transform` CSS prop applied.
         * Transformed parents create new stacking context and the fixed children must be position based on the transformed parent.
         * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
         */
        this.containerOffset = { top: 0, left: 0 };
        this.treeview.touchActions = false;
    }
    Object.defineProperty(DragAndDropDirective.prototype, "scrollSettings", {
        get: function () {
            var userProvidedSettings = typeof this.autoScroll === 'boolean' ?
                { enabled: this.autoScroll } :
                this.autoScroll;
            return Object.assign({}, DEFAULT_SCROLL_SETTINGS, userProvidedSettings);
        },
        enumerable: true,
        configurable: true
    });
    DragAndDropDirective.prototype.ngAfterContentInit = function () {
        this.initalizeDraggable();
        this.dragClueService.initialize(this.treeview.assetsContainer, this.dragClueTemplate && this.dragClueTemplate.templateRef);
        this.dropHintService.initialize(this.treeview.assetsContainer, this.dropHintTemplate && this.dropHintTemplate.templateRef);
    };
    DragAndDropDirective.prototype.ngOnDestroy = function () {
        this.draggable.destroy();
    };
    /**
     * @hidden
     */
    DragAndDropDirective.prototype.handlePress = function (_a) {
        var originalEvent = _a.originalEvent;
        if (!utils_1.isContent(originalEvent.target)) {
            return;
        }
        // store the drag target on press, show it only when it's actually dragged
        this.draggedItem = utils_1.closestWithMatch(originalEvent.target, '.k-treeview-leaf');
        // record the current pointer down coords - copared to the `startDragAfter` value to calculate whether to initiate dragging
        this.pendingDragStartEvent = originalEvent;
    };
    /**
     * @hidden
     */
    DragAndDropDirective.prototype.handleDrag = function (_a) {
        var _this = this;
        var originalEvent = _a.originalEvent, clientX = _a.clientX, clientY = _a.clientY;
        if (this.shouldInitiateDragStart({ clientX: clientX, clientY: clientY })) {
            this.initiateDragStart();
        }
        if (!utils_1.isPresent(this.draggedItem) || utils_1.isPresent(this.pendingDragStartEvent)) {
            return;
        }
        var dropTarget = drag_and_drop_utils_1.getDropTarget(originalEvent);
        if (kendo_angular_common_1.hasObservers(this.treeview.nodeDrag)) {
            this.zone.run(function () { return _this.notifyDrag(originalEvent, dropTarget); });
        }
        var targetTreeView = this.getTargetTreeView(dropTarget);
        var dropPosition = drag_and_drop_utils_1.getDropPosition(this.draggedItem, dropTarget, clientY, targetTreeView, this.containerOffset);
        var dropHintAnchor = utils_1.closestWithMatch(dropTarget, '.k-treeview-mid');
        var dropAction = drag_and_drop_utils_1.getDropAction(dropPosition, dropTarget);
        var sourceItem = drag_and_drop_utils_1.treeItemFromEventTarget(this.treeview, this.draggedItem);
        var destinationItem = drag_and_drop_utils_1.treeItemFromEventTarget(targetTreeView, dropTarget);
        this.updateDropHintState(dropPosition, dropHintAnchor, dropAction, sourceItem, destinationItem);
        this.updateDragClueState(dropAction, clientX, clientY, sourceItem, destinationItem);
        if (this.scrollSettings.enabled) {
            this.dragClueService.scrollIntoView(this.scrollSettings);
        }
    };
    /**
     * @hidden
     */
    DragAndDropDirective.prototype.handleRelease = function (_a) {
        var _this = this;
        var originalEvent = _a.originalEvent, clientY = _a.clientY;
        if (this.scrollSettings.enabled) {
            this.dragClueService.cancelScroll();
        }
        if (!utils_1.isPresent(this.draggedItem) || utils_1.isPresent(this.pendingDragStartEvent)) {
            this.pendingDragStartEvent = null;
            this.draggedItem = null;
            return;
        }
        var dropTarget = drag_and_drop_utils_1.getDropTarget(originalEvent);
        var sourceTree = this.treeview;
        var destinationTree = this.getTargetTreeView(dropTarget);
        var dropPosition = drag_and_drop_utils_1.getDropPosition(this.draggedItem, dropTarget, clientY, this.getTargetTreeView(dropTarget), this.containerOffset);
        var sourceItem = drag_and_drop_utils_1.treeItemFromEventTarget(sourceTree, this.draggedItem);
        var destinationItem = drag_and_drop_utils_1.treeItemFromEventTarget(destinationTree, dropTarget);
        if (utils_1.isPresent(destinationItem) && utils_1.isPresent(dropPosition)) {
            this.zone.run(function () { return _this.notifyDrop({ sourceItem: sourceItem, destinationItem: destinationItem, dropPosition: dropPosition, sourceTree: sourceTree, destinationTree: destinationTree }, originalEvent); });
        }
        else {
            this.dragClueService.animateDragClueToElementPosition(this.draggedItem);
        }
        if (kendo_angular_common_1.hasObservers(this.treeview.nodeDragEnd)) {
            this.zone.run(function () { return _this.notifyDragEnd({ sourceItem: sourceItem, destinationItem: destinationItem, originalEvent: originalEvent }); });
        }
        this.dropHintService.hide();
        this.draggedItem = null;
    };
    DragAndDropDirective.prototype.updateDropHintState = function (dropPosition, dropHintAnchor, dropAction, sourceItem, destinationItem) {
        if (!utils_1.isPresent(dropHintAnchor) || dropPosition === models_1.DropPosition.Over || !utils_1.isPresent(dropPosition)) {
            this.dropHintService.hide();
            return;
        }
        var anchorViewPortCoords = dropHintAnchor.getBoundingClientRect();
        var insertBefore = dropPosition === models_1.DropPosition.Before;
        var top = insertBefore ? anchorViewPortCoords.top : (anchorViewPortCoords.top + anchorViewPortCoords.height);
        this.dropHintService.updateDropHintData(dropAction, sourceItem, destinationItem);
        // clear any possible container offset created by parent elements with `transform` css property set
        this.dropHintService.move(anchorViewPortCoords.left - this.containerOffset.left, top - this.containerOffset.top);
        this.dropHintService.show();
    };
    DragAndDropDirective.prototype.updateDragClueState = function (dropAction, clientX, clientY, sourceItem, destinationItem) {
        // clear any possible container offset created by parent elements with `transform` css property set
        this.dragClueService.move(clientX - this.containerOffset.left, clientY - this.containerOffset.top);
        this.dragClueService.updateDragClueData(dropAction, sourceItem, destinationItem);
        this.dragClueService.show();
    };
    DragAndDropDirective.prototype.initalizeDraggable = function () {
        var _this = this;
        this.draggable = new kendo_draggable_1.default({
            press: this.handlePress.bind(this),
            drag: this.handleDrag.bind(this),
            release: this.handleRelease.bind(this)
        });
        this.zone.runOutsideAngular(function () { return _this.draggable.bindTo(_this.element.nativeElement); });
    };
    DragAndDropDirective.prototype.notifyDragStart = function (originalEvent, dropTarget) {
        var sourceItem = drag_and_drop_utils_1.treeItemFromEventTarget(this.treeview, dropTarget);
        var event = new models_1.TreeItemDragStartEvent({ sourceItem: sourceItem, originalEvent: originalEvent });
        this.treeview.nodeDragStart.emit(event);
        return event;
    };
    DragAndDropDirective.prototype.notifyDrag = function (originalEvent, dropTarget) {
        var dragEvent = {
            sourceItem: drag_and_drop_utils_1.treeItemFromEventTarget(this.treeview, this.draggedItem),
            destinationItem: drag_and_drop_utils_1.treeItemFromEventTarget(this.getTargetTreeView(dropTarget), dropTarget),
            originalEvent: originalEvent
        };
        this.treeview.nodeDrag.emit(dragEvent);
    };
    DragAndDropDirective.prototype.notifyDrop = function (args, originalEvent) {
        var event = new models_1.TreeItemDropEvent(args, originalEvent);
        args.destinationTree.nodeDrop.emit(event);
        // disable the animations on drop and restore them afterwards (if they were initially turned on)
        this.disableAnimationsForNextTick(args.destinationTree);
        if (args.sourceTree !== args.destinationTree) {
            this.disableAnimationsForNextTick(args.sourceTree);
        }
        if (!event.isDefaultPrevented() && event.isValid) {
            this.dragClueService.hide();
            // order matters in a flat data binding scenario (first add, then remove)
            args.destinationTree.addItem.emit(args);
            if (!(originalEvent.ctrlKey && this.allowCopy)) {
                args.sourceTree.removeItem.emit(args);
            }
        }
        else if (event.isDefaultPrevented()) {
            // directly hide the clue if the default is prevented
            this.dragClueService.hide();
        }
        else if (!event.isValid) {
            // animate the clue back to the source item position if marked as invalid
            this.dragClueService.animateDragClueToElementPosition(this.draggedItem);
        }
    };
    DragAndDropDirective.prototype.notifyDragEnd = function (dragEndEvent) {
        this.treeview.nodeDragEnd.emit(dragEndEvent);
    };
    DragAndDropDirective.prototype.getTargetTreeView = function (dropTarget) {
        var treeViewTagName = this.treeview.element.nativeElement.tagName;
        var targetTreeView = utils_1.closestWithMatch(dropTarget, treeViewTagName);
        return [this.treeview].concat(this.dropZoneTreeViews).find(function (treeView) {
            return utils_1.isPresent(treeView) && treeView.element.nativeElement === targetTreeView;
        });
    };
    DragAndDropDirective.prototype.disableAnimationsForNextTick = function (treeView) {
        // the treeView.animate getter returns `true` when the animations are turned off
        // confusing, but seems on purpose (the `animate` prop sets the value of the @.disabled host-bound attribute)
        if (treeView.animate) {
            return;
        }
        treeView.animate = false;
        this.zone.runOutsideAngular(function () {
            return setTimeout(function () { return treeView.animate = true; });
        });
    };
    DragAndDropDirective.prototype.shouldInitiateDragStart = function (currentPointerCoords) {
        if (!utils_1.isPresent(this.pendingDragStartEvent)) {
            return false;
        }
        var distanceFromPointerDown = Math.sqrt(Math.pow((this.pendingDragStartEvent.clientX - currentPointerCoords.clientX), 2) +
            Math.pow((this.pendingDragStartEvent.clientY - currentPointerCoords.clientY), 2));
        return distanceFromPointerDown >= this.startDragAfter;
    };
    DragAndDropDirective.prototype.initiateDragStart = function () {
        var _this = this;
        if (kendo_angular_common_1.hasObservers(this.treeview.nodeDragStart)) {
            var dragStartEvent = this.zone.run(function () {
                return _this.notifyDragStart(_this.pendingDragStartEvent, drag_and_drop_utils_1.getDropTarget(_this.pendingDragStartEvent));
            });
            if (dragStartEvent.isDefaultPrevented()) {
                this.pendingDragStartEvent = null;
                this.draggedItem = null;
                return;
            }
        }
        this.dragClueService.cancelReturnAnimation();
        this.dragClueService.updateText(this.draggedItem.innerText);
        this.containerOffset = drag_and_drop_utils_1.getContainerOffset(this.draggedItem);
        this.pendingDragStartEvent = null;
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], DragAndDropDirective.prototype, "allowCopy", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Array)
    ], DragAndDropDirective.prototype, "dropZoneTreeViews", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], DragAndDropDirective.prototype, "startDragAfter", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], DragAndDropDirective.prototype, "autoScroll", void 0);
    tslib_1.__decorate([
        core_1.ContentChild(drag_clue_template_directive_1.DragClueTemplateDirective, { static: false }),
        tslib_1.__metadata("design:type", drag_clue_template_directive_1.DragClueTemplateDirective)
    ], DragAndDropDirective.prototype, "dragClueTemplate", void 0);
    tslib_1.__decorate([
        core_1.ContentChild(drop_hint_template_directive_1.DropHintTemplateDirective, { static: false }),
        tslib_1.__metadata("design:type", drop_hint_template_directive_1.DropHintTemplateDirective)
    ], DragAndDropDirective.prototype, "dropHintTemplate", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('style.user-select'),
        core_1.HostBinding('style.-ms-user-select'),
        core_1.HostBinding('style.-moz-user-select'),
        core_1.HostBinding('style.-webkit-user-select'),
        tslib_1.__metadata("design:type", String)
    ], DragAndDropDirective.prototype, "userSelectStyle", void 0);
    DragAndDropDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoTreeViewDragAndDrop]',
            providers: [
                drag_clue_service_1.DragClueService,
                drop_hint_service_1.DropHintService
            ]
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.ElementRef,
            core_1.NgZone,
            treeview_component_1.TreeViewComponent,
            drag_clue_service_1.DragClueService,
            drop_hint_service_1.DropHintService])
    ], DragAndDropDirective);
    return DragAndDropDirective;
}());
exports.DragAndDropDirective = DragAndDropDirective;
