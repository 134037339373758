/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var rxjs_1 = require("rxjs");
var dragging_service_1 = require("./dragging-service");
var util_1 = require("./../common/util");
var constants_1 = require("./constants");
/**
 * Represents a tile item within the TileLayoutComponent.
 */
var TileLayoutItemComponent = /** @class */ (function () {
    function TileLayoutItemComponent(elem, renderer, localization, draggingService) {
        var _this = this;
        this.elem = elem;
        this.renderer = renderer;
        this.localization = localization;
        this.draggingService = draggingService;
        /**
         * Determines how many rows will the tile item span ([see example]({% slug tiles_tilelayout %}#toc-tile-size-and-position)).
         * @default 1
         */
        this.rowSpan = 1;
        /**
         * Determines how many columns will the tile item span ([see example]({% slug tiles_tilelayout %}#toc-tile-size-and-position)).
         * @default 1
         */
        this.colSpan = 1;
        /**
         * Determines whether the item can be reordered. By default all items are reorderable when the [reorderable]({% slug api_layout_tilelayoutcomponent %}#toc-reorderable) property of the TileLayoutComponent is set to `true` ([see example]({% slug reordering_tilelayout %}#toc-disable-reordering)).
         *
         * @default true
         */
        this.reorderable = true;
        /**
         * Determines whether the item can be resized. By default all items are resizable when the [resizable]({% slug api_layout_tilelayoutcomponent %}#resizable) property of the TileLayoutComponent is set to `true` ([see example]({% slug resizing_tilelayout %}#toc-disable-resizing)).
         * @default true
         */
        this.resizable = true;
        this.itemClass = true;
        this.subs = new rxjs_1.Subscription();
        this.subs.add(this.localization.changes.subscribe(function (_a) {
            var rtl = _a.rtl;
            _this.rtl = rtl;
        }));
        this.subs.add(this.draggingService.resizable.subscribe(function (resizable) {
            _this.resizeDirections = resizable && _this.resizable ?
                _this.rtl ? constants_1.RTL_RESIZE_DIRECTIONS : constants_1.RESIZE_DIRECTIONS : undefined;
        }));
    }
    Object.defineProperty(TileLayoutItemComponent.prototype, "order", {
        get: function () {
            return this._order;
        },
        /**
         * Determines the order of the tile items within the TileLayout.
         * If not set, the items will receive increasing sequential order in accordance with
         * their position in the DOM when initially rendered.
         */
        set: function (value) {
            this._order = value;
            this.renderer.setStyle(this.elem.nativeElement, 'order', "" + this._order);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TileLayoutItemComponent.prototype, "colEnd", {
        get: function () {
            return "span " + this.colSpan;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TileLayoutItemComponent.prototype, "rowEnd", {
        get: function () {
            return "span " + this.rowSpan;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TileLayoutItemComponent.prototype, "colStart", {
        get: function () {
            return util_1.isPresent(this.col) ? this.col.toString() : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TileLayoutItemComponent.prototype, "rowStart", {
        get: function () {
            return util_1.isPresent(this.row) ? this.row.toString() : undefined;
        },
        enumerable: true,
        configurable: true
    });
    TileLayoutItemComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.subs.add(this.draggingService.reorderable.subscribe(function (reorderable) {
            _this.toggleCursorClass(reorderable && _this.reorderable);
        }));
    };
    TileLayoutItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes.reorderable && !changes.reorderable.firstChange) {
            this.toggleCursorClass(changes.reorderable.currentValue && this.draggingService.reorderable.getValue());
        }
        if (changes.resizable) {
            this.resizeDirections = this.resizable && this.draggingService.resizable.getValue() ?
                this.rtl ? constants_1.RTL_RESIZE_DIRECTIONS : constants_1.RESIZE_DIRECTIONS : undefined;
        }
    };
    TileLayoutItemComponent.prototype.ngOnDestroy = function () {
        this.subs.unsubscribe();
    };
    TileLayoutItemComponent.prototype.toggleCursorClass = function (isReorderable) {
        var headerEl = this.elem.nativeElement.querySelector('.k-tilelayout-item-header');
        if (!headerEl) {
            return;
        }
        if (isReorderable) {
            this.renderer.addClass(headerEl, 'k-cursor-grab');
        }
        else {
            this.renderer.removeClass(headerEl, 'k-cursor-grab');
        }
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], TileLayoutItemComponent.prototype, "title", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], TileLayoutItemComponent.prototype, "rowSpan", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], TileLayoutItemComponent.prototype, "colSpan", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number),
        tslib_1.__metadata("design:paramtypes", [Number])
    ], TileLayoutItemComponent.prototype, "order", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], TileLayoutItemComponent.prototype, "col", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], TileLayoutItemComponent.prototype, "row", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], TileLayoutItemComponent.prototype, "reorderable", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], TileLayoutItemComponent.prototype, "resizable", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-tilelayout-item'),
        core_1.HostBinding('class.k-card'),
        tslib_1.__metadata("design:type", Boolean)
    ], TileLayoutItemComponent.prototype, "itemClass", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('style.grid-column-end'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], TileLayoutItemComponent.prototype, "colEnd", null);
    tslib_1.__decorate([
        core_1.HostBinding('style.grid-row-end'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], TileLayoutItemComponent.prototype, "rowEnd", null);
    tslib_1.__decorate([
        core_1.HostBinding('style.grid-column-start'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], TileLayoutItemComponent.prototype, "colStart", null);
    tslib_1.__decorate([
        core_1.HostBinding('style.grid-row-start'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], TileLayoutItemComponent.prototype, "rowStart", null);
    TileLayoutItemComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-tilelayout-item',
            template: "\n        <kendo-tilelayout-item-header *ngIf=\"title\">\n            <h5 class=\"k-card-title\">{{ title }}</h5>\n        </kendo-tilelayout-item-header>\n        <ng-content></ng-content>\n        <ng-container *ngIf=\"resizable\">\n            <div\n                *ngFor=\"let dir of resizeDirections\"\n                class=\"k-resize-handle k-cursor-{{dir}}-resize k-touch-action-none\"\n                kendoTileLayoutResizeHandle\n                [rtl]=\"rtl\"\n                [resizeDirection]=\"dir\">\n            </div>\n        </ng-container>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.ElementRef,
            core_1.Renderer2,
            kendo_angular_l10n_1.LocalizationService,
            dragging_service_1.TileLayoutDraggingService])
    ], TileLayoutItemComponent);
    return TileLayoutItemComponent;
}());
exports.TileLayoutItemComponent = TileLayoutItemComponent;
