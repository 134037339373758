//import angular from 'angular';
import FileManagement from '../../../../services/fileManagement';
import tmpl from './vehicleSpecificationAttachment.html';



class VehicleSpeciifcationAttachmentCtrl implements ng.IController, IWindowController {

    form: ng.IFormController;
    wnd: IWindowObj;

    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: (button: string) => void }

    initParams: any;

    siteControl: any = {};

    acceptedFileTypes: string[];

    status = ScreenStatus.loading;
    mode: string;

    data: any;
    initData: any;

    static $inject = ['$scope', 'WindowFactory', 'amtCommandQuerySvc', 'specificationAttachmentSvc',
        'amtXlatSvc', 'notifySvc', 'fileManagement', 'confirmSvc', 'errorReporter', '$timeout'];

    constructor(private $scope: ng.IScope, private WindowFactory: IWindowFactory, private amtCommandQuerySvc: IAmtCommandQuerySvc,
        private specificationAttachmentSvc: any, private amtXlatSvc: IAmtXlatSvc, private notifySvc: INotifySvc,
        private fileManagement: FileManagement, private confirmSvc: IConfirmSvc, private errorReporter: IErrorReporter, private $timeout: ng.ITimeoutService) {

        this.$scope.$watch(() => this.form.$dirty, () => this.wnd.isDirty = this.form.$dirty);

        this.$scope.$watchGroup([() => this.form.$pristine, () => this.form.$invalid, () => this.wnd.processing, () => this.initParams.mode], () => this.updateButtons());
    }

    async $onInit() {

        this.wnd.processing = true;
        this.wnd.onClose = () => this.closeWindow();

        this.mode = this.initParams.mode;

        this.WindowFactory.addButton(this, 'common.save_label', 'saveAndClose', () => this.save(true), true);
        this.WindowFactory.addButton(this, 'common.saveAndAddAnother_label', 'saveAndNext', () => this.save(false));

        this.acceptedFileTypes = this.fileManagement.getAcceptedFileExtensions([FileType.document, FileType.pdf, FileType.text, FileType.spreadsheet, FileType.image]);

        try {
            this.data = await this.loadDetails(this.initParams.data);
            this.initData = angular.copy(this.data);

            this.status = ScreenStatus.ready;
        } catch (error) {
            this.errorReporter.logError(error);
            this.status = ScreenStatus.error;
        } finally {
            this.wnd.processing = false;
        }
    }

    async loadDetails(data) {

        if (this.mode === this.specificationAttachmentSvc.modes.add)
            return data;

        return await this.specificationAttachmentSvc.loadAsync(data.attachmentId);
    }

    setPristine() {
        if (this.form)
            this.form.$setPristine();
    }

    async save(close: boolean) {

        this.wnd.processing = true;

        try {
            let response = await this.specificationAttachmentSvc.saveAsync(this.data, this.mode);

            if (this.wnd.onDataChanged)
                this.wnd.onDataChanged(response); // tell the caller that data has changed (refresh the grid)            

            this.notifySvc.success(this.amtXlatSvc.xlat(this.specificationAttachmentSvc.resourcePrefix + 'SaveSuccess'));

            if (close) {
                this.setPristine();
                this.closeWindow();
            } else {
                // save completed, reset the form
                this.data = {
                    clients: angular.copy(this.initData.clients),
                    sites: angular.copy(this.initData.sites),
                    equipmentTypeSpecificationTypeId: this.initData.equipmentTypeSpecificationTypeId
                };

                if (this.data.clients)
                    this.data.clientIds = this.initData.clients.map(s => s.key);

                if (this.data.sites)
                    this.data.siteIds = this.initData.sites.map(s => s.key);

                this.$timeout(() => this.setPristine());
            }

        } catch (error) {
            this.notifySvc.error(this.amtXlatSvc.xlat(this.specificationAttachmentSvc.resourcePrefix + 'SaveFail') + ' ' + error);
            throw error;
        } finally {
            this.wnd.processing = false;
        }
    }

    async closeWindow() {

        try {
            await this.confirmSvc.confirmSaveChange(this.form.$dirty);
        } catch (error) {
            return; // they cancelled
        }

        this.setPristine();

        this.WindowFactory.closeWindow(this.wnd);
    }

    fileSelected() {
        this.form.$setDirty();
    }

    onClientChange(options) {

        if (!options || options.length === 0) {
            this.data.siteIds = null;

            if (this.siteControl.clear)
                this.siteControl.clear(true);
        }
    }

    updateButtons() {
        this.buttonStates.saveAndClose.disabled = this.form.$invalid || this.form.$pristine || this.wnd.processing || this.initParams.mode === this.specificationAttachmentSvc.modes.view;
        this.buttonStates.saveAndNext.disabled = this.buttonStates.saveAndClose.disabled;
        this.buttonStates.saveAndNext.visible = this.initParams.mode === this.specificationAttachmentSvc.modes.add;
    }
}

class VehicleSpeciifcationAttachmentComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = VehicleSpeciifcationAttachmentCtrl;
    public controllerAs = 'vm';
}

angular.module('app.admin').component('vehicleSpecificationAttachment', new VehicleSpeciifcationAttachmentComponent());