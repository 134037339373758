import 'hammerjs';
import '@progress/kendo-angular-intl/locales/es-cl/all';
import '@progress/kendo-angular-intl/locales/es/all';


//Import Angular Module
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ElementRef} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//Import Kendo-UI Module
import { LabelModule } from "@progress/kendo-angular-label";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PopupModule, POPUP_CONTAINER } from '@progress/kendo-angular-popup';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { AutoCompleteModule } from '@progress/kendo-angular-dropdowns';
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { GridModule } from '@progress/kendo-angular-grid';
import { ListBoxModule } from '@progress/kendo-angular-listbox';
import { RadialGaugeModule } from '@progress/kendo-angular-gauges';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { IntlModule } from "@progress/kendo-angular-intl";
import { NotificationModule, NOTIFICATION_CONTAINER } from "@progress/kendo-angular-notification";

//Import Updgrade
import {
    errorReporterProvider, confirmServiceProvider, DbClassProvider, fileManagementProvider, notifyServiceProvider,
    ocDateSvcProvider, windowFactoryProvider
} from './services/ajs-upgraded-providers';

//Import UI Plug-in
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { servicesPlugin } from '@uirouter/angular';

//Import Bootstrap
import { TooltipModule } from 'ngx-bootstrap/tooltip';

//Import Oc
import { OcHeaderComponent } from './oc-common/components/oc-header.component';

//Import AMT Module
import { AmtModule } from './amt/amt.module';
import { PropertyRow } from './directives/ui/property-row.component';
import { XlatPipe } from './amt/i18n/xlat-pipe';

//Import Service
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { AuthService } from './services/auth.service';
import { CacheService } from './services/cache.service';
import HelperSvc from './services/helperSvc';
//import { MockBackendInterceptorService } from './services/mock-backend-interceptor.service';

//Import Condition Monitoring
import { ConditionMonitoringIconComponent } from './modules/site/conditionMonitoring/renderer/condition-monitoring-icon.component';
import { ConditionMonitoringBaseComponent } from './modules/site/conditionMonitoring/monitoringOptions/condition-monitoring-base.component';
import { ConditionMonitoringComponent } from './modules/site/conditionMonitoring/monitoringOptions/visualInspections/condition-monitoring.component';
import { TreadWearComponent } from './modules/site/conditionMonitoring/monitoringOptions/treadWear/tread-wear.component';
import { ConditionMonitoringDetailsComponent } from './modules/site/conditionMonitoring/components/condition-monitoring-details.component';
import { ConditionDetailsComponent } from './modules/site/conditionMonitoring/components/tabs/condition-details.component';
import { ConditionMonitoringService } from './modules/site/conditionMonitoring/condition-monitoring.service';
import { VehicleRendererOrtho } from './modules/site/conditionMonitoring/Renderer/vehicle-renderer-ortho.service';
import { VehicleRenderer } from './modules/site/conditionMonitoring/renderer/vehicle-renderer.service';
import { OcVehicleSchematicDirective } from './modules/equipment/directives/VehicleSchematic/ocVehicleSchematic.component';

//Import Visual Inspection
import { VisualInspectionReferenceService } from './services/visual-inspection-reference.service';
import { VisualInspectionService } from './services/visual-inspection.service';

//Import Site-Dashboard
import { SiteDashboardComponent } from './modules/site/siteDashboard/site-dashboard.component';
import { WidgetMenuComponent } from './modules/site/siteDashboard/widgetMenu/widget-menu.component';

//Import Site-Dashboard Widget Component
import { SiteDashboardTotalDownTimeComponent } from './modules/site/siteDashboard/widget/totalDownTime/total-down-time.component';
import { SiteDashboardTyresInInspectionComponent } from './modules/site/siteDashboard/widget/tyresInInspection/tyres-in-inspection.component';
import { SiteDashboardChangesCompletedComponent } from './modules/site/siteDashboard/widget/changesCompleted/changes-completed.component';
import { SiteDashboardVehicleInMaintenanceComponent } from './modules/site/siteDashboard/widget/vehicleInMaintenance/vehicle-in-maintenance.component';
import { SiteDashboardErrorsComponent } from './modules/site/siteDashboard/widget/errors/errors.component';
import { SiteDashboardHealthRatingComponent } from './modules/site/siteDashboard/widget/siteHealthRating/site-health-rating.component';
import { SiteDashboardLiveForecastRimComponent } from './modules/site/siteDashboard/widget/liveForecastRim/live-forecast-rim.component';
import { SiteDashboardScrapLifeTrendComponent } from './modules/site/siteDashboard/widget/scrapLifeTrend/scrap-life-trend.component';
import { SiteDashboardPressureCheckVehicleComponent } from './modules/site/siteDashboard/widget/pressureCheckVehicle/pressure-check-vehicle.component';
import { SiteDashboardLiveForecastTyresMonthComponent } from './modules/site/siteDashboard/widget/liveForecastTyresMonth/live-forecast-tyres-month.component';
import { SiteDashboardPressureKPIComponent } from './modules/site/siteDashboard/widget/pressureKPI/pressure-kpi.component';
import { SiteDashboardConditionMonitoringComponent } from './modules/site/siteDashboard/widget/conditionMonitoring/condition-monitoring.component';

//Import Site-Dashboard Widget Dialog
import { DialogHealthRateElementComponent } from './modules/site/siteDashboard/dialog/healthRateElement/health-rate-element.component';
import { DialogLiveForecastRimElementComponent } from './modules/site/siteDashboard/dialog/liveForecastRimElement/live-forecast-rim-element.component';
import { DialogLiveForecastTyreElementComponent } from './modules/site/siteDashboard/dialog/liveForecastTyreElement/live-forecast-tyre-element.component';

//Import Search Pipe Filter
import { Ng2SearchPipeModule } from 'ng2-search-filter';
// https://stackoverflow.com/questions/44802253/cannot-start-ui-router-hybrid-app-with-systemjs "FIX for the HYBRID"
servicesPlugin(null);

@NgModule({
    declarations: [
        PropertyRow,                        //AMT & OC Component
        OcHeaderComponent,
        ConditionMonitoringBaseComponent,
        ConditionMonitoringComponent,       //Condition Monitoring Component
        TreadWearComponent,       //Tread Wear Component
        ConditionMonitoringIconComponent,
        ConditionMonitoringDetailsComponent,
        ConditionDetailsComponent,
        PropertyRow,
        SiteDashboardComponent,             //SiteDashboard Component
        WidgetMenuComponent,
        SiteDashboardErrorsComponent,
        SiteDashboardTyresInInspectionComponent,
        SiteDashboardVehicleInMaintenanceComponent,
        SiteDashboardChangesCompletedComponent,
        SiteDashboardTotalDownTimeComponent,
        SiteDashboardHealthRatingComponent,
        SiteDashboardLiveForecastRimComponent,
        SiteDashboardScrapLifeTrendComponent,
        SiteDashboardPressureCheckVehicleComponent,
        SiteDashboardLiveForecastTyresMonthComponent,
        SiteDashboardPressureKPIComponent,
        SiteDashboardConditionMonitoringComponent,
        DialogHealthRateElementComponent,
        DialogLiveForecastTyreElementComponent,
        DialogLiveForecastRimElementComponent,
        PropertyRow,
        OcHeaderComponent,
        WidgetMenuComponent,
        OcVehicleSchematicDirective,
    ],
    imports: [
        UIRouterUpgradeModule.forRoot(),
        TooltipModule.forRoot(),
        UpgradeModule,
        CommonModule,
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AmtModule,
        LayoutModule,
        ChartsModule,
        GridModule,
        LabelModule,
        ListBoxModule,
        ButtonsModule,
        FormsModule,
        ReactiveFormsModule,
        PopupModule,
        DropDownsModule,
        AutoCompleteModule,
        ComboBoxModule,
        Ng2SearchPipeModule,
        RadialGaugeModule,
        ScrollViewModule,
        IntlModule,
        NotificationModule
    ],
    providers: [
        {
            provide: POPUP_CONTAINER,
            useFactory: () => {
                //return the container ElementRef, where the popup will be injected
                return { nativeElement: document.body } as ElementRef;
            }
        },
        confirmServiceProvider,
        notifyServiceProvider,
        fileManagementProvider,
        windowFactoryProvider,        
        errorReporterProvider,
        DbClassProvider,
        ocDateSvcProvider,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        {
            provide: AuthService,
            useClass: AuthService
        },
        {
            provide: ConditionMonitoringService,
            useClass: ConditionMonitoringService
        },
        {
            provide: VehicleRenderer,
            useClass: VehicleRenderer
        },
        {
            provide: VehicleRendererOrtho,
            useClass: VehicleRendererOrtho
        },
        {
            provide: VisualInspectionReferenceService,
            useClass: VisualInspectionReferenceService
        },
        {
            provide: VisualInspectionService,
            useClass: VisualInspectionService
        },
        {
            provide: HelperSvc,
            useClass: HelperSvc
        },
        {
            provide: CacheService,
            useClass: CacheService
        },
        XlatPipe,
        DatePipe,
        {
            provide: NOTIFICATION_CONTAINER,
            useFactory: () => {
                //return the container ElementRef, where the notification will be injected
                return { nativeElement: document.body } as ElementRef;
            }
    },
                //{
        //    provide: NgModuleFactoryLoader,
        //    useClass: SystemJsNgModuleLoader
        //},
        //{
        //    provide: HTTP_INTERCEPTORS,
        //    useClass: MockBackendInterceptorService,
        //    multi: true,
        //},
    ],
    
})

export class AppModule {
    constructor(private upgrade: UpgradeModule) { }
    ngDoBootstrap() {
        this.upgrade.bootstrap(document.documentElement, ['app'], { strictDi: true });        
    }
}

