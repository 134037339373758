/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Specifies a separator in the content of the Card.
 */
var CardSeparatorDirective = /** @class */ (function () {
    function CardSeparatorDirective() {
        this.hostClass = true;
        /**
         * Specifies the orientation of the Card separator.
         *
         * The possible values are:
         * (Default) `horizontal`
         * `vertical`
         */
        this.orientation = 'horizontal';
    }
    Object.defineProperty(CardSeparatorDirective.prototype, "verticalClass", {
        get: function () {
            return this.orientation === 'vertical';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CardSeparatorDirective.prototype, "horizontalClass", {
        get: function () {
            return this.orientation === 'horizontal';
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        core_1.HostBinding('class.k-card-separator'),
        tslib_1.__metadata("design:type", Boolean)
    ], CardSeparatorDirective.prototype, "hostClass", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-separator-vertical'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], CardSeparatorDirective.prototype, "verticalClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-separator-horizontal'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], CardSeparatorDirective.prototype, "horizontalClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('style.color'),
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], CardSeparatorDirective.prototype, "color", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], CardSeparatorDirective.prototype, "orientation", void 0);
    CardSeparatorDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoCardSeparator]'
        })
    ], CardSeparatorDirective);
    return CardSeparatorDirective;
}());
exports.CardSeparatorDirective = CardSeparatorDirective;
