import { Inject, Injectable, OnDestroy } from "@angular/core";
import { VehicleSpecsService } from "../../../../services/vehicle-specs.service";
import { VehiclesOnsiteService } from "../../../../services/vehicles-onsite.service";
import { IAxleSpecSize, ConditionMonitoringService } from "../condition-monitoring.service";
import { RenderPartTypes, VehicleRendererBase } from "./vehicle-renderer-base.service";

@Injectable()
export class VehicleRenderer extends VehicleRendererBase /*implements OnDestroy*/ {
    constructor(
        protected conditionMonitoringService: ConditionMonitoringService,
        protected vehSpecSvc: VehicleSpecsService,
        protected vehSvc: VehiclesOnsiteService,
        @Inject('desiredWidth') public readonly desiredWidth: number,
        @Inject('desiredHeight') public readonly desiredHeight: number
    ) {
        super(false);
    }

    /*ngOnDestroy() {
        this.OnDestroy();
    }*/

    protected renderInit() {
        this.ctx!.scale(this.diagramParams!.scale, this.diagramParams!.scale);

        //we don't create a part in the parts list for the central drive train line, in this renderer we just draw it first
        this.drawDriveShaft();
    }

    protected drawDriveShaft() {
        this.drawLine(0, .5 * this.axles[0].tyreDiameter + this.diagramParams!.diagramTopY,
            0, this.diagramParams!.diagramLength - .5 * this.axles[this.axles.length - 1].tyreDiameter + this.diagramParams!.diagramTopY,
            this.DRIVE_SHAFT_COLOUR, this.DRIVE_SHAFT_WIDTH_IN_PIXELS);
    }
    
    protected drawAxlePart(t: RenderPartTypes, x0: number, x1: number, y: number, size: IAxleSpecSize) {
        const len = x1 - x0;
        const gap = (2 * this.diagramParams!.lineWidth + .5 * size.tyreWidth) * (Math.sign(len));

        if (Math.abs(len) >= 3 * gap)
            this.drawLine(x0 + gap, y, x1 - gap, y, this.AXLE_COLOUR, this.AXLE_WIDTH_IN_PIXELS);
    }

    protected drawTyre(t: RenderPartTypes, x: number, y: number, issue: number, size: IAxleSpecSize) {
        this.drawRect(x - .5 * size.tyreWidth, y - .5 * size.tyreDiameter, size.tyreWidth, size.tyreDiameter, this.ISSUE_COLOURS_LOOKUP_TABLE[issue])
    }
}
