//import angular from 'angular';


angular.module("app").factory("maintenanceUrls", function () {
    var apiPath = baseUrl + 'api/';
        var vehicleController = 'equipment/vehicle/';
        var commonEquipmentPath = 'equipment/common/';

        return {
                search: apiPath + vehicleController + 'searchMaintenanceSessions',
                searchExport: vehicleController + 'exportMaintenanceSessions',
                getDefaults: vehicleController + 'getDefaults', // default values for search
                getMaintenanceSession: vehicleController + 'getMaintenanceSession' // load a maintenance session
        };
    });

