/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Represents the template directive of the PanelBar which helps to customize the item content.
 */
var PanelBarItemTemplateDirective = /** @class */ (function () {
    function PanelBarItemTemplateDirective(templateRef) {
        this.templateRef = templateRef;
    }
    PanelBarItemTemplateDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoPanelBarItemTemplate]'
        }),
        tslib_1.__param(0, core_1.Optional()),
        tslib_1.__metadata("design:paramtypes", [core_1.TemplateRef])
    ], PanelBarItemTemplateDirective);
    return PanelBarItemTemplateDirective;
}());
exports.PanelBarItemTemplateDirective = PanelBarItemTemplateDirective;
