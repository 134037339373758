import * as _ from 'underscore'

/**
 * @ngdoc directive
 * @name amtFramework.grid.directive: amtGridGroupHeaderColumn
 * @restrict E
 * 
 
 * @param {string} title Column header title
 * @param {string=} headerattributes Additional html attributes e.g {"style":"text-align:right"}
 * @description
 * Header Column {@link amtFramework.grid.directive:amtGrid amtGrid}
 */

//import angular from 'angular';

angular.module('amtFramework.grid').directive('amtGridGroupHeaderColumn', ['appConfig', 'amtXlatSvc', function (appConfig, xlatSvc) {
            return {
                require: '^amtGrid',
                restrict: 'E',
                scope: {
                    title: '@',
                    headerAttributes: '@',
                    forceHide: '@'
                },
                controller: ['$scope', function ($scope) {
                    $scope.columns = [];

                    function getLocalStorageKey(scope, field, property) {

                        if (scope.localStorageKey === undefined) {
                            return (scope.id + field + property);
                        }
                        else {
                            return (scope.localStorageKey + field + property);
                        }

                    }

                    this.addColumn = function (column, internal) {

                        if (typeof (Storage) !== "undefined") {
                            var columnWidth = localStorage.getItem(getLocalStorageKey($scope, column.id, "width"));
                            if (columnWidth !== null && columnWidth !== undefined) {
                                if (columnWidth.search("px") === -1) {
                                    columnWidth += "px";
                                }
                                column.width = columnWidth;
                            }

                            var lsVal = localStorage.getItem(getLocalStorageKey($scope, column.id, "hidden"));

                            var condA = column.forceHide;
                            var condB = (column.forceHide === undefined && column.optional && (lsVal === null ? column.hidden : lsVal === "true"));

                            var columnHidden = condA || condB;
                            if (columnHidden !== null) {
                                column.hidden = columnHidden;
                            }
                        }

                        var pos = $scope.columns.length;
                        //add the column to the grid
                        $scope.columns.splice(pos, 0, column);
                    };

                    this.removeColumn = function (column) {
                        $scope.columns = _.without($scope.columns, _.findWhere($scope.columns, { field: column.field }));
                    };

                }],
                link: function (scope: any, elem, attr, amtGridCtrl) {

                    var kendoColumnDefinition = {
                        title: scope.title,
                        columns: scope.columns,
                        forceHide: scope.forceHide === "true"
                    };

                    scope.kendoColumnDefinition = kendoColumnDefinition;

                    if (scope.headerAttributes) {
                        if (!scope.kendoColumnDefinition.headerAttributes) {
                            scope.kendoColumnDefinition.headerAttributes = {};
                        }
                        var attrString = scope.headerAttributes;
                        var attrJson = JSON.parse(attrString);
                        for (var attrname in attrJson) {
                            scope.kendoColumnDefinition.headerAttributes[attrname] = attrJson[attrname];
                        }
                    }

                    scope.$on('$destroy', function () {
                        amtGridCtrl.removeColumn(scope.kendoColumnDefinition);
                    });

                    scope.$watch('forceHide', function (newValue, oldValue) {
                        if (amtGridCtrl.showHideGridColumn !== undefined && scope.forceHide) {
                            amtGridCtrl.showHideGridColumn(scope, newValue);
                        }
                    });

                    amtGridCtrl.addColumn(kendoColumnDefinition);

                    if (amtGridCtrl.showHideGridColumn !== undefined && scope.forceHide === "true") {
                        amtGridCtrl.showHideGridColumn(scope, true);
                    }
                }
            };
}]);
