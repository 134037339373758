//import angular from 'angular';
import { Change } from '@progress/kendo-angular-charts/dist/es2015/common/configuration.service';
import AdminUrls from '../../admin/adminUrls';

angular.module('app.vehicle').controller('vehicleSearchCtrl', [
        '$scope', 'amtCommandQuerySvc', 'vehicleUrls', 'adminUrls', 'exportSvc', 'amtXlatSvc',
    'WindowFactory', 'ocConfigSvc', 'ocSecuritySvc', 'confirmSvc', 'notifySvc', 'enums', 'errorReporter', '$http',
        function vehicleSearchCtrl($scope, amtCommandQuerySvc, vehicleUrls, adminUrls: AdminUrls, exportSvc, xlatSvc,
            WindowFactory, ocConfigSvc, ocSecuritySvc, confirmSvc, notifySvc, enums, errorReporter, $http) {
            var vm = this;

            vm.canUndoReceive = ocSecuritySvc.isAuthorised('Security.Vehicles.Receive', 'readWrite'); // can undo vehicle receive

            vm.apiUrls = vehicleUrls;
            vm.adminUrls = adminUrls;

            vm.showClients = false;
            vm.showSites = false;
            vm.isDispatchedTransfer = false;
            vm.showDestination = false;

            vm.showActions = false;
            vm.criteria = {
                filterValues: {
                    clientIds: [],
                    siteIds: [],
                    sizeIds: [],
                    manufacturerIds: [],
                    specificationIds: [],
                    ownerIds: [],
                    locationIds: [],
                    serialNumber: null,
                    statusIds: [],
                    filterOptions: [],
                    defaultToAllSitesWhenNoSystemHierarchyFilter: true
                }
            };

            $scope.$watch('vm.criteria.filterValues.clientIds.length', function (cnt) {
                if (cnt === 0 && vm.criteria.filterValues.siteIds.length > 0) {
                    // reset the sites if the clients are cleared
                    vm.criteria.filterValues.siteIds.length = 0;
                }
            });

            //set event if user modified the Status selection, find 'Transfer' to change the filter value.
            $scope.$watch('vm.defaultStatusTypes', (newStatus, oldStatus) => {
                var isIncludeTrans = false;
                if (newStatus === undefined || newStatus === null) {
                } else if (newStatus.length === 0) {
                    isIncludeTrans = true;
                } else {
                    newStatus.forEach(status => {
                        var description = status.description;
                        if (description.endsWith('Transfer')) {
                            isIncludeTrans = true;
                        }
                    });
                }
                if (isIncludeTrans) {
                    vm.setFilter();
                    vm.isDispatchedTransfer = true;
                } else {
                    vm.criteria.filterOptions = [];
                    vm.isDispatchedTransfer = false;
                }
            });
            $scope.$watch('vm.criteria.filterOptions', (newOptions, oldOptions) => {
                let isDestination = false;
                if (newOptions.length != 0) {
                    newOptions.forEach(option => {
                        if (option.key === "Show Destination") {
                            isDestination  = true;
                        }
                    });
                }
                vm.showDestination = isDestination;
            });

            //set default filter value
            vm.setFilter = async function () {
                await $http.get(vm.apiUrls.getFilterOptions).then(response => {
                    vm.criteria.filterOptions = response.data.result;
                });
            };
            vm.setHeaderHeight = function (height, offset) {
                var offset = height + offset
                console.log("grid height based on :" + offset);
                vm.gridTop = offset;
            };

            $scope.rowParseFunction = function (response) {
                var equipments = response.result;

                for (var r = 0; r < equipments.length; r++) {
                    equipments[r].onSite = equipments[r].siteId === vm.config.user.site.id;
                    equipments[r].canChangeStatus = equipments[r].canChangeStatus && equipments[r].onSite;
                }
                return response;
            };

            vm.config = ocConfigSvc;
            vm.filter = filter;
            vm.gridControl = {};
            vm.selectedVehicle = {};
            vm.viewDetailsDisabled = true;

            vm.onDatabound = function () {
                vm.selectedVehicle = null;

                vm.onSelectedItemChanged();

                vm.showActions = vm.gridControl.getTotalItemsCount() > 0;
            };

            vm.onSelectedItemChanged = function (items) {
                if (items && items.length === 1) {
                    vm.selectedVehicle = items[0];
                } else {
                    vm.selectedVehicle = undefined;
                }
                vm.viewDetailsDisabled = vm.selectedVehicle === undefined;
            };

            vm.viewDetails = function () {
                if (vm.selectedVehicle) {
                    $scope.showVehicleDetails(vm.selectedVehicle);
                }
            };

            vm.export = function () {
                exportSvc.exportData(vm.apiUrls.searchExport, vm.criteria, xlatSvc.xlat('vehicle.searchExportFilename'));
            };

            vm.openChangeStatus = function (type, mode, width) {

                $scope.openModule(
                    'vehicle-change-status-popup',
                    xlatSvc.xlat("vehicle.statusChangeHeader", vm.selectedVehicle.formattedSerialNumber),
                    {
                        mode: mode,
                        type: type,
                        vehicle: {
                            id: vm.selectedVehicle.id,
                            siteId: vm.selectedVehicle.siteId,
                            serialNumber: {
                                formatted: vm.selectedVehicle.formattedSerialNumber
                            }
                        },
                        equipmentStatusId: vm.selectedVehicle.equipmentStatusId
                    },
                    (width || 750),
                    null,
                    true,
                    null,
                    false,
                    true
                );
            };

            vm.outOfService = function (mode) {
                vm.openChangeStatus('out of service', (mode || 'new'));
            };

            vm.dispose = function (mode) {
                vm.openChangeStatus('disposed', (mode || 'new'));
            };

            vm.production = function (mode) {
                vm.openChangeStatus('production', (mode || 'new'));
            };

            vm.maintenanceSession = function () {

                if (vm.selectedVehicle.statusName === 'MAINTENANCE') {

                    $scope.openModule(
                        'maintenance',
                        xlatSvc.xlat('equipment.maintenanceSession'),
                        {
                            vehicleId: vm.selectedVehicle.id,
                            display: vm.selectedVehicle.formattedSerialNumber + " - " + vm.selectedVehicle.specification,
                            id: vm.selectedVehicle.equipmentStatusId
                        },
                        1000,
                        700 + WindowFactory.headerHeight,
                        false,
                        vm.selectedVehicle.equipmentStatusId
                    );

                } else {

                    WindowFactory.openItem({
                        component: 'maintenance',
                        headerOff: false,
                        footerOff: true,
                        caption: xlatSvc.xlat('equipment.maintenanceSession'),
                        initParams: {
                            vehicleId: vm.selectedVehicle.id,
                            display: vm.selectedVehicle.formattedSerialNumber + " - " + vm.selectedVehicle.specification,
                        },
                        height: 700 + WindowFactory.headerHeight,
                        width: 1100,
                        modal: false
                    });
                }
            };

            vm.transfer = function () {
                if (ocSecuritySvc.isAuthorised('Security.Vehicles.Transfer', 'readWrite')) {
                    $scope.openModule(
                        'transfer-vehicle-popup',
                        xlatSvc.xlat('vehicle.transferVehicle', vm.selectedVehicle.formattedSerialNumber || vm.selectedVehicle.manufacturerSerialNumber),
                        { vehicle: vm.selectedVehicle.id, siteId: vm.selectedVehicle.siteId, clientId: vm.selectedVehicle.clientId },
                        800,
                        null,
                        true,
                        null,
                        false,
                        true);
                }
            };

            vm.undo = function () {
                if (vm.selectedVehicle.isInTransfer) {
                    undoTransfer();
                } else if (vm.selectedVehicle.isReceive) {
                    if (vm.canUndoReceive) {
                        undoReceive();
                    }
                } else {
                    undoEvent();
                }
            };

            function undoEvent() {

                var undoCriteria = {
                    equipmentStatusId: vm.selectedVehicle.equipmentStatusId
                };

                confirmSvc.confirmMessage('vehicle.confirmUndo_title', 'vehicle.confirmUndo')
                    .then(function () {
                        amtCommandQuerySvc.post(vm.apiUrls.undoMoveEvents, [undoCriteria]).then(function (response) {

                            notifySvc.success(xlatSvc.xlat("vehicle.messageUndoStatusSuccess"));
                            vm.filter();

                        }).catch(function (error) {
                            errorReporter.logError(error);
                        });
                    });
            };

            function undoTransfer() {

                var undoCriteria = {
                    equipmentStatusId: vm.selectedVehicle.equipmentStatusId
                };

                confirmSvc.confirmMessage('vehicle.confirmUndoTransfer_title', 'vehicle.confirmUndoTransfer')
                    .then(function () {
                        amtCommandQuerySvc.post(vm.apiUrls.undoTransfer, undoCriteria).then(function (response) {
                            notifySvc.success(xlatSvc.xlat("vehicle.messageUndoTransferSuccess"));
                            vm.filter();
                        }, amtCommandQuerySvc.handleError);
                    });
            };

            function undoReceive() {

                var undoCriteria = {
                    equipmentStatusId: vm.selectedVehicle.equipmentStatusId
                };

                confirmSvc.confirmMessage('vehicle.confirmUndoVehicleReceive_title',
                    'vehicle.confirmUndoVehicleReceive').then(function () {

                        amtCommandQuerySvc.post(vm.apiUrls.undoReceive, undoCriteria).then(function (response) {
                            notifySvc.success(xlatSvc.xlat("vehicle.messageUndoReceiveSuccess"));
                            vm.filter();
                        }, amtCommandQuerySvc.handleError);
                    });
            };

            vm.historyReport = function () {
                alert('This is pending Reports implementation');
            };

            $scope.openModule = function (component, title, initParams, width, height, modal, onDataChange, footerOff, hideClose, id) {
                WindowFactory.openItem({
                    component: component,
                    caption: title,
                    initParams: initParams,
                    parentWindow: vm.wnd,
                    width: width,
                    height: height,
                    modal: modal,
                    onDataChangeHandler: onDataChange,
                    footerOff: footerOff,
                    canClose: !hideClose,
                    windowRelatedRecordId: id
                });
            };

            function getKeys(arr) {
                var keys = [];

                if (arr !== undefined && arr != null) {
                    for (var i = 0; i < arr.length; i++) {
                        keys.push(arr[i].key);
                    }
                }

                return keys;
            }

            vm.loadDefaults = function () {
                vm.status = 'loading';
                var defaultCriteria = {
                    statusTypeNames: ['PRODUCTION', 'MAINTENANCE']
                };

                amtCommandQuerySvc.post(vm.apiUrls.getDefaults, defaultCriteria).then(function (response) {
                    if (response) {
                        var searchVehicleSerialNumber = localStorage.getItem("vehicleSerialNumber");
                        vm.defaultClients = response.clients;
                        vm.defaultSites = response.sites;
                        vm.defaultStatusTypes = response.statusTypes;
                        vm.siteId = response.sites[0].key;

                        vm.status = "ready";

                        // If the user clicked the pressure check per widget from site dashboard, set searchSerial Number
                        if (searchVehicleSerialNumber !== null) {
                            vm.criteria.filterValues.serialNumber = searchVehicleSerialNumber;
                            localStorage.removeItem("vehicleSerialNumber");

                            // hold the trigger, until the client loaded
                            setTimeout(function () {
                                vm.filter(true);
                            }, 3000);
                        }

                        // focus on serial number field by default
                        vm.focusSerialNumber = true;

                    }
                }).catch(function (error) {
                    vm.defaultErrors = [error];
                    vm.status = "error";
                    errorReporter.logError(error, 'VehicleSearch-LoadDefaults');
                });
            };

            function init() {
                vm.loadDefaults();
            }

            function refreshGrid() {
                vm.gridControl.refresh();
            }

            function filter() {
                vm.showClients = vm.criteria.filterValues.clientIds.length !== 1;
                vm.showSites = vm.criteria.filterValues.siteIds.length !== 1;

                refreshGrid();
            }

            $scope.$on('filterSearch', function () {
                vm.filter();
            });

            $scope.showVehicleDetails = function (item) {
                WindowFactory.openItem({
                    component: 'vehicle-details',
                    caption: xlatSvc.xlat('equipment.openVehicle', item.formattedSerialNumber),
                    windowRelatedRecordId: item.id,
                    initParams: {
                        equipmentId: item.id,
                        siteId: item.siteId,
                        clientId: item.clientId,
                        serialNumber: item.formattedSerialNumber,
                        showCloseOnSave: true
                    },
                    width: 800,
                    height: 850,
                    modal: false
                });
            };

            init();
        }
    ]);
