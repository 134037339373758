//import angular from 'angular';


angular.module('app').factory('tabDirtyTrackingSvc', function () {
        var isDirty;
        var tabDirtyTrackingSvc = {
            setTabDirty: setTabDirty,
            clearTabDirty: clearTabDirty,
            isTabDirty: isTabDirty
        };
        return tabDirtyTrackingSvc;

        function setTabDirty() {
            isDirty = true;
        }

        function clearTabDirty() {
            isDirty = false;
        }

        function isTabDirty() {
            return isDirty;
        }
    });
