/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var column_component_1 = require("../../columns/column.component");
var filter_service_1 = require("../filter.service");
var utils_1 = require("../../utils");
var menu_tabbing_service_1 = require("./menu-tabbing.service");
/**
 * @hidden
 */
var NumericFilterMenuInputComponent = /** @class */ (function () {
    function NumericFilterMenuInputComponent(localization) {
        this.localization = localization;
        this.operators = [];
        /**
         * Specifies the value which is used to increment or decrement the component value.
         * @type {numeric}
         */
        this.step = 1;
        /**
         * Specifies whether the **Up** and **Down** spin buttons will be rendered.
         * @type {boolean}
         */
        this.spinners = true;
    }
    NumericFilterMenuInputComponent.prototype.messageFor = function (key) {
        return this.localization.get(key);
    };
    Object.defineProperty(NumericFilterMenuInputComponent.prototype, "columnLabel", {
        /**
         * @hidden
         */
        get: function () {
            var localizationMsg = this.localization.get('filterInputLabel') || '';
            var columnName = this.column ? this.column.title || this.column.field : '';
            return utils_1.replaceMessagePlaceholder(localizationMsg, 'columnName', columnName);
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Array)
    ], NumericFilterMenuInputComponent.prototype, "operators", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", column_component_1.ColumnComponent)
    ], NumericFilterMenuInputComponent.prototype, "column", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], NumericFilterMenuInputComponent.prototype, "filter", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], NumericFilterMenuInputComponent.prototype, "operator", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], NumericFilterMenuInputComponent.prototype, "currentFilter", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", filter_service_1.FilterService)
    ], NumericFilterMenuInputComponent.prototype, "filterService", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], NumericFilterMenuInputComponent.prototype, "isFirstDropDown", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", menu_tabbing_service_1.MenuTabbingService)
    ], NumericFilterMenuInputComponent.prototype, "menuTabbingService", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], NumericFilterMenuInputComponent.prototype, "step", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], NumericFilterMenuInputComponent.prototype, "min", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], NumericFilterMenuInputComponent.prototype, "max", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], NumericFilterMenuInputComponent.prototype, "spinners", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], NumericFilterMenuInputComponent.prototype, "decimals", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], NumericFilterMenuInputComponent.prototype, "format", void 0);
    NumericFilterMenuInputComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-grid-numeric-filter-menu-input',
            template: "\n        <kendo-grid-filter-menu-input-wrapper\n            [column]=\"column\"\n            [filter]=\"filter\"\n            [operators]=\"operators\"\n            [defaultOperator]=\"operator\"\n            [currentFilter]=\"currentFilter\"\n            [filterService]=\"filterService\"\n            [isFirstDropDown]=\"isFirstDropDown\"\n            [menuTabbingService]=\"menuTabbingService\"\n        >\n            <kendo-numerictextbox\n                kendoFilterInput\n                [columnLabel]=\"columnLabel\"\n                [filterDelay]=\"0\"\n                [autoCorrect]=\"true\"\n                [value]=\"currentFilter?.value\"\n                [format]=\"format\"\n                [decimals]=\"decimals\"\n                [spinners]=\"spinners\"\n                [min]=\"min\"\n                [max]=\"max\"\n                [step]=\"step\"\n            >\n                <kendo-numerictextbox-messages\n                    [increment]=\"messageFor('filterNumericIncrement')\"\n                    [decrement]=\"messageFor('filterNumericDecrement')\"\n                >\n                </kendo-numerictextbox-messages>\n            </kendo-numerictextbox>\n        </kendo-grid-filter-menu-input-wrapper>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService])
    ], NumericFilterMenuInputComponent);
    return NumericFilterMenuInputComponent;
}());
exports.NumericFilterMenuInputComponent = NumericFilterMenuInputComponent;
