/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var selection_service_1 = require("./selection.service");
/**
 * @hidden
 */
var SelectableDirective = /** @class */ (function () {
    function SelectableDirective(selectionService) {
        this.checkboxes = { enabled: false };
        // @HostBinding('attr.offset-index')
        // @Input() public offsetIndex: number;
        this.multipleSelection = false;
        this.selectionService = selectionService;
    }
    Object.defineProperty(SelectableDirective.prototype, "focusedClassName", {
        get: function () {
            return this.selectionService.isFocused(this.index);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectableDirective.prototype, "selectedClassName", {
        get: function () {
            return !this.checkboxes.enabled && this.selectionService.isSelected(this.index);
        },
        enumerable: true,
        configurable: true
    });
    SelectableDirective.prototype.onClick = function (event) {
        event.stopPropagation();
        if (this.checkboxes.enabled && !this.checkboxes.checkOnClick) {
            return;
        }
        if (this.multipleSelection) {
            if (this.selectionService.isSelected(this.index)) {
                this.selectionService.unselect(this.index);
            }
            else {
                this.selectionService.add(this.index);
            }
        }
        else {
            this.selectionService.change(this.index);
        }
    };
    tslib_1.__decorate([
        core_1.HostBinding('attr.index'),
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], SelectableDirective.prototype, "index", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], SelectableDirective.prototype, "checkboxes", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('style.height.px'),
        core_1.HostBinding('style.minHeight.px'),
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], SelectableDirective.prototype, "height", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], SelectableDirective.prototype, "multipleSelection", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-focus'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], SelectableDirective.prototype, "focusedClassName", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-selected'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], SelectableDirective.prototype, "selectedClassName", null);
    tslib_1.__decorate([
        core_1.HostListener('click', ['$event']),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], SelectableDirective.prototype, "onClick", null);
    SelectableDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoDropDownsSelectable]'
        }),
        tslib_1.__metadata("design:paramtypes", [selection_service_1.SelectionService])
    ], SelectableDirective);
    return SelectableDirective;
}());
exports.SelectableDirective = SelectableDirective;
