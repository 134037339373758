//import angular from 'angular';
import _ from 'underscore';
import BrowserSvc from '../../../services/browserSvc';
import OcDateSvc from '../../../services/ocDateSvc';
import { OcDynamicGridColumnMaker, OcDynamicGridColumnType, OcDynamicGridAlignment } from '../../../directives/ui/ocDynamicGrid';
import tmpl from "./Checkout.html";
import { TMaintenanceSession_CheckOut, TMaintenanceSession_CheckOut_Delay } from './maintenanceCtrl';

class MaintenanceCheckoutCtrl implements ng.IController {

    form: ng.IFormController;

    session: any;
    save: Function;
    invalid: boolean;
    errorPanel: any;
    currentError: any;
    readOnly: boolean;
    interface: any;
    delayGridControl: any;
    fittersControl: any;

    fitters = null;
    selectedFitterItems = [];
    isMobile = this.browserSvc.isMobile;
    delayGridItems = [];
    currentDate = new Date();
    translations = {
        nothingSelected: this.amtXlatSvc.xlat('common.none'),
        selectAll: this.amtXlatSvc.xlat('common.selectAll'),
        selectNone: this.amtXlatSvc.xlat('common.clear'),
    };

    static $inject = ["$scope", "dataBroker", "WindowFactory", "amtXlatSvc", "confirmSvc", "ocDateSvc", "browserSvc", "errorReporter"];

    constructor(private $scope: ng.IScope, private dataBroker: IDataBroker, private WindowFactory: IWindowFactory,
        private amtXlatSvc: any, private confirmSvc: any, private ocDateSvc: OcDateSvc,
        private browserSvc: BrowserSvc, private errorReporter: IErrorReporter) {

        this.$scope.$watch(() => this.form.$invalid, async (newVal, oldVal) => {
            this.invalid = newVal;
            if (newVal && !oldVal && !this.form.$pristine) {

                try {
                    await this.save({
                        field: this.currentField(),
                        validateReason: 'onInvalidate',
                        andValidate: true
                    });
                } catch (error) {
                    if (error === 'ValidateAbort' || (error && (error.ValidateAbort || error.message === '0' || error.number === 0))) {
                        //User aborted out of validation
                    } else {
                        errorReporter.logError(error);
                    }
                }

                this.setPristine();
            }
        });

        this.$scope.$watch(() => this.form.$pristine, async (newVal) => {
            if (!newVal && browserSvc.isMobile && !this.form.$invalid) {
                try {
                    await this.save({
                        field: this.currentField(),
                        validateReason: 'onChange',
                        andValidate: true
                    });

                } catch (error) {
                    if (error === 'ValidateAbort' || (error && (error.ValidateAbort || error.message === '0' || error.number === 0))) {
                        //User aborted out of validation
                    } else {
                        errorReporter.logError(error);
                    }
                }

                this.setPristine();
            }
        });

        this.$scope.$watch('vm.session.checkin.checkinDate', async (newDate: Date, prevDate) => {
            let isCheckedIn: boolean = this?.session?.checkedIn !== undefined && this?.session?.checkedIn !== null ? this?.session?.checkedIn : false;
            if (isCheckedIn) {
                await this.onChangeCheckinDate(newDate, prevDate);
            }
        });

        this.$scope.$watch("vm.delayGridControl", (newValue) => {
            if (newValue) {
                // connect functions to dynamic grid api
                this.delayGridControl.deleteDelay = this.deleteDelay;
                this.delayGridControl.editDelay = this.editDelay;
            }
        });

        this.$scope.$watch('vm.session.checkout.checkoutDate', async (newCheckout: TMaintenanceSession_CheckOut, prevCheckout) => {
            //let isCheckedOut: boolean = this?.session?.checkedOut !== undefined && this?.session?.checkedOut !== null ? this?.session?.checkedOut : false;
            if (newCheckout != null) { // && isCheckedOut) {
                await this.updateTotalAndNetDowntime(false);
            }
        });
    }

    async $onInit() {
        let sessionNotExisted: boolean = !this.session;

        if (sessionNotExisted) {
            console.error("No Session!");
        }

        this.session.checkout = !this.session.checkout ? { checkoutDate: undefined } : this.session.checkout;
        this.session.checkout.delays = !this.session.checkout.delays ? [] : this.session.checkout.delays;

        if (this.interface) {
            this.interface.clearFitters = this.clearFitters;
        }
        else {
            this.interface = { clearFitters: this.clearFitters };
        }

        this.currentDate = new Date();
        this.prepareDelayGrid();
        this.loadComponentReferenceData();

        // fitters are required
        this.$scope.$watch("vm.session.checkout.selectedFitters", () => {
            if (this.session.checkout.selectedFitters && this.session.checkout.selectedFitters.length == 0)
                this.clearFitters();

            if (this?.form) {
                this.form.$setValidity('fitters', !!(this.session.checkout.selectedFitters && this.session.checkout.selectedFitters.length), this.form);
            }
        }, true);

        this.$scope.$watch("vm.session.checkout.hasNonRelatedTyreWork", (newVal) => {
            if (newVal !== null && !newVal)
                if (!this.session.checkout.hasNonRelatedTyreWork)
                    this.session.checkout.tyreRelatedWork = 0;

                this.updateTotalAndNetDowntime();
        });
    }

    isFitterChanged(newFitters: string[] | any[], prevFitters: string[] | any[]): boolean {
        let result: boolean = false;
        let isNewFittersStringArrary: boolean = this.isStringArray(newFitters);
        let isPrevFittersStringArrary: boolean = this.isStringArray(prevFitters);

        if (isNewFittersStringArrary && isPrevFittersStringArrary) {
            newFitters = newFitters.sort();
            prevFitters = prevFitters.sort();
            result = JSON.stringify(newFitters) !== JSON.stringify(prevFitters);
        }

        return result;
    }

    isStringArray(value: any): value is string[] {
        return Array.isArray(value) && value.every(item => typeof item === "string");
    }

    isDelayChanged(newDelay: TMaintenanceSession_CheckOut_Delay[] | undefined, prevDelay: TMaintenanceSession_CheckOut_Delay[] | undefined): boolean {
        let result: boolean = false;
        let isNewDelayundefined: boolean = newDelay === undefined;
        let isPrevDelayundefined: boolean = prevDelay === undefined;

        if (!isNewDelayundefined && !isPrevDelayundefined) {
            //Comparison Ids
            let newDelayReasonIds: string[] = newDelay.map(delay => delay.selectedDelayReason.id).sort();
            let prevDelayReasonIds: string[] = prevDelay.map(delay => delay.selectedDelayReason.id).sort();
            let isReasonChanged = JSON.stringify(newDelayReasonIds) !== JSON.stringify(prevDelayReasonIds);
            //Comparison Mintues
            let newDelayMinutesSum: number = newDelay.map(delay => delay.minutes).reduce((acc, curr) => acc + curr, 0);
            let prevDelayMinutesSum: number = prevDelay.map(delay => delay.minutes).reduce((acc, curr) => acc + curr, 0);
            let isSumChanged = newDelayMinutesSum !== prevDelayMinutesSum;

            result = isReasonChanged || isSumChanged;
        }

        return result;
    }

    setPristine() {
        if (this.form)
            this.form.$setPristine();
    }

    elementBinding(element, valueAttribute = 'ng-model') {
        return !element ? undefined : angular.element(element).attr(valueAttribute);
    }

    currentField() {
        if (!document.activeElement)
            return undefined;

        // get the ng-model property
        let binding = this.elementBinding(document.activeElement);

        if (!binding)
            return undefined; // no ng-model attribute        

        // strip off "vm.session" from the ngModel
        return binding.replace('vm.session.', '');
    }

    clearFitters() {
        if (this.fittersControl && this.fittersControl.clear) {
            this.fittersControl.clear(true);
        }
    }

    prepareDelayGrid() {
        this.delayGridItems = [];

        this.delayGridItems.push(OcDynamicGridColumnMaker.create({
            headerResource: 'maintenanceSession.delayReason',
            type: OcDynamicGridColumnType.string,
            valuePath: 'selectedDelayReason.description',
            colSpan: 5,
            onClick: 'editDelay',
            columnAlignment: OcDynamicGridAlignment.left
        }));

        this.delayGridItems.push(OcDynamicGridColumnMaker.create({
            headerResource: 'maintenanceSession.duration',
            type: OcDynamicGridColumnType.duration,
            valuePath: 'minutes',
            colSpan: 4,
            columnAlignment: OcDynamicGridAlignment.left
        }));

        this.delayGridItems.push(OcDynamicGridColumnMaker.create({
            headerResource: 'maintenanceSession.deleteShort',
            type: OcDynamicGridColumnType.string,
            columnResource: 'maintenanceSession.deleteShort',
            colSpan: 3,
            columnAlignment: OcDynamicGridAlignment.center,
            onClick: 'deleteDelay'
        }));
    }

    loadComponentReferenceData = () => {
        // get the fitters
        this.loadFitters();
    }

    async loadFitters() { 


        try {
            let fitterList = await this.dataBroker.getFitters({ date: this.ocDateSvc.removeLocalTimeZoneOffset(this.session.checkout.checkoutDate) });
            this.fitters = fitterList.result;

            if (!this.selectedFitterItems || this.selectedFitterItems.length === 0) {
                if (this.session.checkout.selectedFitters && this.session.checkout.selectedFitters.length > 0) {
                    this.selectedFitterItems = this.browserSvc.isMobile ?
                        this.session.checkout.selectedFitters.map(function (f) { return { key: f } }) :
                        this.session.checkout.selectedFitters;
                }
            }

            // if there is a fitter assigned to the checkout but that fitter is no longer valid for the period, add it anyway so we can retain historical links
            if (this.selectedFitterItems && this.selectedFitterItems.length > 0) {

                for (var i = 0; i < this.selectedFitterItems.length; i++) {
                    var matchedFitter = this.fitters.filter(
                        f => f.key === (
                            this.selectedFitterItems[i].key ? this.selectedFitterItems[i].key : this.selectedFitterItems[i])
                    );

                    if (!matchedFitter || matchedFitter.length === 0) {
                        var fitter = angular.copy(this.selectedFitterItems[i]);
                        if (this.selectedFitterItems[i].key) {
                            fitter.selected = true;
                        }
                        this.fitters.push(fitter);

                    }
                    else {
                        matchedFitter.forEach(function (f) {
                            if (f.key) {
                                f.selected = true;
                            }
                        });
                    }
                }
            }
            else {
                this.clearFitters();
            }

        } catch (error) {
            this.errorReporter.logError(error, 'CheckIn-GetProductionCrews');
        }
    }

    //Root => Watch "$ctrl.session.checkin.checkinDate
    async onChangeCheckinDate(newDate: Date, prevDate: Date): Promise<void> {
        let onDateChanged = this.isDateChanged(newDate, prevDate);
        if (onDateChanged) {
            await this.updateTotalAndNetDowntime();
        }
    }

    isDateChanged(newDate: Date | undefined, prevDate: Date | undefined): boolean {
        let result: boolean = false;
        let firstChange: boolean = (prevDate === undefined || prevDate === null) && ((newDate instanceof Date) && newDate !== undefined);

        if ((newDate instanceof Date) && (prevDate instanceof Date)) {
            let newDateString: string = newDate.toString();
            let prevDateString: string = prevDate.toString();
            let isDateChanged: boolean = newDateString !== prevDateString;
            result = isDateChanged;
        }

        return firstChange ? true : result;
    }

    async updateTotalAndNetDowntime(validate: boolean = true): Promise<void> {

        if (!this.session.checkout.checkoutDate) return;

        await this.updateTotalDowntime();
        await this.updateNetDowntime();

        if (validate) {
            await this.validate('tyreRelatedWork');
            await this.validate('delayTotal');
        }
    }

    //Root => Watch "$ctrl.session.checkout" => onChangeCheckout() => updateTotalAndNetDowntime()
    async updateTotalDowntime(): Promise<void> {
        if (this.session?.checkin?.checkinDate && this?.session?.checkout) {
            let checkInDateTime: Date | undefined | null = this.session?.checkin?.checkinDate;
            let checkOutDateTime: Date | undefined | null = this.session?.checkout?.checkoutDate;

            if (checkInDateTime !== null && checkOutDateTime !== null && checkInDateTime !== undefined && checkOutDateTime !== undefined) {

                if (checkOutDateTime < checkInDateTime) {
                    //Clear current total duration
                    this.session.checkout.totalDowntime = null;
                    return;
                }

                let differenceInMilliseconds: number = checkOutDateTime.getTime() - checkInDateTime.getTime();
                let differenceInMinutes: number = differenceInMilliseconds / (1000 * 60);

                this.session.checkout.totalDowntime = Math.floor(differenceInMinutes);
            }
        }
    }

    //Root => Watch "$ctrl.session.checkout" => onChangeCheckout() => updateTotalAndNetDowntime()
    async updateNetDowntime(): Promise<void> {
        if (this.session?.checkin?.checkinDate && this?.session?.checkout) {
            this.session.checkout.delayTotal = 0;

            let checkInDateTime: Date | undefined | null = this.session?.checkin?.checkinDate;
            let checkOutDateTime: Date | undefined | null = this.session?.checkout?.checkoutDate;

            if (checkInDateTime !== null && checkOutDateTime !== null && checkInDateTime !== undefined && checkOutDateTime !== undefined) {

                if (checkOutDateTime < checkInDateTime) {
                    //Clear current details
                    this.session.checkout.netDowntime = null;
                    this.session.checkout.averageTyreChange = null;
                    return;
                }

                if (this.session.checkout.delays) {
                    for (var d = 0; d < this.session.checkout.delays.length; d++) {
                        this.session.checkout.delayTotal += this.session.checkout.delays[d].minutes;
                    }
                }

                this.session.checkout.netDowntime =
                    (this.session.checkout.hasNonRelatedTyreWork ? this.session.checkout.tyreRelatedWork - this.session.checkout.delayTotal
                        : this.session.checkout.totalDowntime - this.session.checkout.delayTotal);
                this.session.checkout.averageTyreChange = this.session.changeCount === 0 ? 0
                    : this.session.checkout.netDowntime / this.session.changeCount;
            }
        }
    }

    async checkoutDateChange() {
        await this.updateTotalAndNetDowntime(false);
        this.validate('checkinDate');
    }

    // this function gets called when a control loses focus (onblur) with the attribute as the field (e.g. 'hours' or 'distance')
    onBlur(field) {

        try {
            // invoke the save function bound to this instance, which is actually on maintenanceCtrl.ts
            this.save({
                andValidate: true,
                validateReason: 'onBlur',
                field: field
            });
        } catch (error) {
            // absorb 
        }
    }

    async validate(field) {
        await this.save({
            andValidate: true,
            field: field
        }).catch(function () {
            //absorb 
        });
    };

    // Delay - Add
    addDelay = () => {
        var data = {
            isEdit: false
        };
        this.WindowFactory.openItem({
            component: "delay-dialog",
            caption: this.amtXlatSvc.xlat("maintenanceSession.addDelay"),
            initParams: data,
            width: 600,
            modal: true,
            canClose: false,
            onDataChangeHandler: (delayReason) => {
                var delays = this.session.checkout.delays;

                if (delayReason.reopen) {
                    delete delayReason.reopen;
                    this.addDelay();
                }
                delayReason.lastModifiedDate = new Date();
                var addDelay = true;

                // check for existing delay of same type
                if (delays && delays.length > 0) {
                    var delay = _.find(delays, d => d.selectedDelayReason.id == delayReason.selectedDelayReason.id);
                    if (delay) {
                        addDelay = false;
                        delay.minutes += delayReason.minutes;
                    }
                }

                if (addDelay) {
                    delays.push(delayReason);
                }

                this.updateTotalAndNetDowntime();
            }
        });
    }

    // Delay - Edit
    editDelay = (data) => {
        data.isEdit = true;
        data.id = uuid();

        this.WindowFactory.openItem({
            component: "delay-dialog",
            caption: this.amtXlatSvc.xlat("maintenanceSession.editDelay"),
            initParams: data,
            width: 600,
            modal: true,
            canClose: false,
            onDataChangeHandler: (delayReason) => {
                var delayIdx = this.session.checkout.delays.map(function (d) { return d.id; }).indexOf(data.id);
                if (delayReason.reopen) {
                    delete delayReason.reopen;
                    this.addDelay();
                }
                this.session.checkout.delays[delayIdx] = delayReason;

                this.updateTotalAndNetDowntime();
            }
        });
    }

    // Delay - Delete
    deleteDelay = (data) => {
        var delayReason = data.selectedDelayReason.description;
        var hours = (data.minutes / 60) | 0;
        var mins = data.minutes % 60;
        var hourDesc = String.format("{0} " + (hours === 1 ? this.amtXlatSvc.xlat("common.hour_abbrev") : this.amtXlatSvc.xlat("common.hours_abbrev")), hours);
        var minDesc = String.format("{0} " + (mins === 1 ? this.amtXlatSvc.xlat("common.minute_abbrev") : this.amtXlatSvc.xlat("common.minutes_abbrev")), mins);
        var delayTime = hours > 0 && mins > 0 ? String.format("{0} {1}", hourDesc, minDesc) : (hours > 0 ? hourDesc : minDesc);

        this.confirmSvc.confirmMessage("maintenanceSession.confirmDeleteDelay_title", "maintenanceSession.confirmDeleteDelay_text",
            delayReason, delayTime).then(() => {
                var index = this.session.checkout.delays.indexOf(data);
                if (index !== -1) {
                    this.session.checkout.delays.splice(index, 1);

                    this.updateTotalAndNetDowntime();
                }
            });
    }
}

class MaintenanceCheckoutComponent implements ng.IComponentOptions {
    public bindings = {
        session: '<',
        save: '&',
        invalid: '=',
        errorPanel: '=',
        currentError: '=',
        readOnly: '=',
        interface: '=',
        delayGridControl: '=?',
        fittersControl: '&'
    };
    public template = tmpl;
    public controller = MaintenanceCheckoutCtrl;
    public controllerAs = 'vm';
}

angular.module('app.directives').component('maintenanceCheckout', new MaintenanceCheckoutComponent());
