//import angular from 'angular';
import AdminUrls from '../../../adminUrls';
import tmpl from './modifyRole.html';



angular.module('app.admin').component('modifyRole', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['$scope', 'adminUrls', 'amtCommandQuerySvc', 'confirmSvc', 'enums', 'amtXlatSvc', 'WindowFactory', 'notifySvc', 'ocConfigSvc', 'errorReporter',
            function ($scope, adminUrls: AdminUrls, amtCommandQuerySvc, confirmSvc, enums, xlatSvc: IAmtXlatSvc, WindowFactory: IWindowFactory, notifySvc, ocConfigSvc, errorReporter) {

                var vm = this;
                $scope.vm = this;

                vm.config = ocConfigSvc;

                vm.form = {};

                this.$onInit = function () {

                    vm.model = vm.initParams.role;

                    vm.originalActive = vm.model.isActive;

                    vm.wnd.onClose = vm.onClose;

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: 'common.save_label',
                            name: 'saveButton',
                            click: 'save',
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: true,
                            disabled: true
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.onSave
                    };
                };

                $scope.$watch('vm.form.$dirty', function (newValue) {
                    vm.wnd.isDirty = newValue;
                });

                vm.onClose = function () {
                    closeWindow();
                };

                function closeWindow() {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        vm.form.$setPristine();

                        WindowFactory.closeWindow(vm.wnd);
                    });
                }

                function save() {

                    vm.wnd.processing = true;

                    vm.editPromise = amtCommandQuerySvc.put(adminUrls.modifyRole, vm.model).then(function (response) {

                        // display a toast
                        notifySvc.success(xlatSvc.xlat('rolesPermissions.modifyRoleSuccess'));

                        // refresh the parent
                        if ($scope.$parent.onDataChanged) {
                            $scope.$parent.onDataChanged();
                        }

                        // set the form as clean, so that when it closes, it doesn't throw up the unsaved changes message
                        vm.form.$setPristine();

                        // close the window
                        closeWindow();

                    }).catch(function (error) {
                        errorReporter.logError(error);
                    }).finally(function () {
                        vm.wnd.processing = false;
                    });
                }

                vm.onSave = function () {
                    if (vm.model.roleAssignments.length > 0 && vm.originalActive === true && vm.model.isActive === false) {
                        // if we're changing the active flag to inactive, and the role is being used, get the user to confirm that we're disabling the role
                        confirmSvc.confirmMessage(
                            'rolesPermissions.confirmDeactiveUsedRole_title',
                            'rolesPermissions.confirmDeactiveUsedRole_text', vm.model.description, vm.model.roleAssignments.length
                        ).then(
                            function () {
                                vm.model.forceDeactive = true;
                                save();
                            }
                        );
                    } else {
                        // just save it
                        vm.model.forceDeactive = true;
                        save();
                    }
                };

                $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing'], function () {
                    vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.form.$pristine || vm.wnd.processing;
                });
            }
        ]
    });
