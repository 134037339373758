/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Represents the template for the TreeView load more buttons.
 * To define a button template, nest an `<ng-template>`
 * tag with the `kendoTreeViewLoadMoreButtonTemplate` directive inside a `<kendo-treeview>` tag
 * ([see example]({% slug loadmorebutton_treeview %}#toc-button-template)).
 *
 * The hierarchical index of the load more button node is available as a context variable:
 *
 * - `let-index="index"` (`string`)
 */
var LoadMoreButtonTemplateDirective = /** @class */ (function () {
    function LoadMoreButtonTemplateDirective(templateRef) {
        this.templateRef = templateRef;
    }
    LoadMoreButtonTemplateDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoTreeViewLoadMoreButtonTemplate]'
        }),
        tslib_1.__param(0, core_1.Optional()),
        tslib_1.__metadata("design:paramtypes", [core_1.TemplateRef])
    ], LoadMoreButtonTemplateDirective);
    return LoadMoreButtonTemplateDirective;
}());
exports.LoadMoreButtonTemplateDirective = LoadMoreButtonTemplateDirective;
