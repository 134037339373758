/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var chip_component_1 = require("./chip.component");
var util_1 = require("../util");
var kendo_licensing_1 = require("@progress/kendo-licensing");
var package_metadata_1 = require("../package-metadata");
var ChipListComponent = /** @class */ (function () {
    function ChipListComponent(localizationService, renderer, element) {
        this.localizationService = localizationService;
        this.renderer = renderer;
        this.element = element;
        this.hostClass = true;
        /**
         * Sets the selection mode of the ChipList.
         *
         * The available values are:
         * * `none` (default)
         * * `single`
         * * `multiple`
         */
        this.selection = 'none';
        /**
         * Fires each time when the ChipList selection is changed.
         */
        this.selectedChange = new core_1.EventEmitter();
        /**
         * Fires each time the user clicks on the remove icon of the Chip.
         */
        this.remove = new core_1.EventEmitter();
        this.role = 'listbox';
        this._size = 'medium';
        kendo_licensing_1.validatePackage(package_metadata_1.packageMetadata);
        this.direction = localizationService.rtl ? 'rtl' : 'ltr';
    }
    Object.defineProperty(ChipListComponent.prototype, "size", {
        get: function () {
            return this._size;
        },
        /**
         * The size property specifies the gap between the Chips in the ChipList
         * ([see example]({% slug appearance_chiplist %}#toc-size)).
         *
         * The possible values are:
         * * `'small'`
         * * `'medium'` (default)
         * * `'large'`
         * * `null`
         */
        set: function (size) {
            this.handleClasses(size, 'size');
            this._size = size;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipListComponent.prototype, "single", {
        get: function () {
            return this.selection === 'single';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipListComponent.prototype, "multiple", {
        get: function () {
            return this.selection === 'multiple';
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @hidden
     */
    ChipListComponent.prototype.onClick = function ($event) {
        var target = $event.target;
        var isRemoveClicked = util_1.closest(target, '.k-chip-remove-action');
        var clickedChip = util_1.closest(target, '.k-chip');
        var chip = this.chips.find(function (chip) { return clickedChip === chip.element.nativeElement; });
        if (isRemoveClicked && clickedChip) {
            var removeEventArgs = { sender: this, originalEvent: $event, removedChip: chip };
            this.remove.emit(removeEventArgs);
        }
        if (this.selection !== 'none' && clickedChip && !isRemoveClicked) {
            this.setSelection(chip);
        }
    };
    ChipListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dynamicRTLSubscription = this.localizationService.changes
            .subscribe(function (_a) {
            var rtl = _a.rtl;
            return _this.direction = rtl ? 'rtl' : 'ltr';
        });
    };
    ChipListComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        var stylingInputs = ['size'];
        stylingInputs.forEach(function (input) {
            _this.handleClasses(_this[input], input);
        });
    };
    ChipListComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        this.chips.forEach(function (chip) {
            _this.renderer.setAttribute(chip.element.nativeElement, 'role', 'option');
        });
    };
    ChipListComponent.prototype.ngOnDestroy = function () {
        if (this.dynamicRTLSubscription) {
            this.dynamicRTLSubscription.unsubscribe();
        }
    };
    ChipListComponent.prototype.selectedChips = function () {
        return this.chips.reduce(function (acc, cur, idx) { return cur.selected ? acc.concat(idx) : acc; }, []);
    };
    /**
     * Updates the selection on click of a Chip. Emits events.
     */
    ChipListComponent.prototype.setSelection = function (chip) {
        if (this.selection === 'single') {
            this.clearSelection(chip);
        }
        chip.selected = !chip.selected;
        this.selectedChange.emit(this.selectedChips());
    };
    ChipListComponent.prototype.clearSelection = function (chip) {
        this.chips.forEach(function (c) {
            if (chip !== c) {
                c.selected = false;
            }
        });
    };
    ChipListComponent.prototype.handleClasses = function (value, input) {
        var elem = this.element.nativeElement;
        var classes = util_1.getStylingClasses('chip-list', input, this[input], value);
        if (classes.toRemove) {
            this.renderer.removeClass(elem, classes.toRemove);
        }
        if (classes.toAdd) {
            this.renderer.addClass(elem, classes.toAdd);
        }
    };
    tslib_1.__decorate([
        core_1.HostBinding('class.k-chip-list'),
        tslib_1.__metadata("design:type", Boolean)
    ], ChipListComponent.prototype, "hostClass", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('attr.dir'),
        tslib_1.__metadata("design:type", String)
    ], ChipListComponent.prototype, "direction", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ChipListComponent.prototype, "selection", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], ChipListComponent.prototype, "size", null);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ChipListComponent.prototype, "selectedChange", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ChipListComponent.prototype, "remove", void 0);
    tslib_1.__decorate([
        core_1.ContentChildren(chip_component_1.ChipComponent),
        tslib_1.__metadata("design:type", core_1.QueryList)
    ], ChipListComponent.prototype, "chips", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-selection-single'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ChipListComponent.prototype, "single", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-selection-multiple'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ChipListComponent.prototype, "multiple", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.role'),
        tslib_1.__metadata("design:type", String)
    ], ChipListComponent.prototype, "role", void 0);
    tslib_1.__decorate([
        core_1.HostListener('click', ['$event']),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ChipListComponent.prototype, "onClick", null);
    ChipListComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-chiplist, kendo-chip-list',
            template: "\n        <ng-content></ng-content>\n    ",
            providers: [
                kendo_angular_l10n_1.LocalizationService,
                {
                    provide: kendo_angular_l10n_1.L10N_PREFIX,
                    useValue: 'kendo.chiplist'
                }
            ]
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService,
            core_1.Renderer2,
            core_1.ElementRef])
    ], ChipListComponent);
    return ChipListComponent;
}());
exports.ChipListComponent = ChipListComponent;
