//import angular from 'angular';
import BrowserSvc from '../../../services/browserSvc';
import tmpl from "./Fitted.html";


angular.module("app.directives").component("maintenanceFitted",
        {
            template: tmpl,
            bindings: {
                session: "<",
                doRefresh: "<"
            },
            controller: maintenanceFittedCtrl
        });

    // @ts-ignore
    maintenanceFittedCtrl.$inject = ["$timeout", "browserSvc", "$filter", "amtXlatSvc"];

    function maintenanceFittedCtrl($timeout, browserSvc: BrowserSvc, $filter, amtXlatSvc) {

        var ctrl = this;

        ctrl.positions = [];
        ctrl.tyreGrid = {};
        ctrl.rimGrid = {};
        ctrl.chainGrid = {};
        ctrl.hasChains = false;
        ctrl.gridsMobile = browserSvc.isMobile ? "Mobile" : "Desker";

        // safely copies the fields specified in the template, from the data into a new object
        function resolveTemplate(data, template) {
            $filter("number")(0); // Need to get $filter into the scope of this, eval can't inject it dynamically.

            var result = {};
            for (var field in template) {
                if (template.hasOwnProperty(field)) {
                    try {
                        result[field] = eval(template[field]) || "";
                    } catch (e) {
                        result[field] = "";
                    }
                }
            }
            return result;
        }

        function updateData() {

            if (ctrl && ctrl.positions && ctrl.session && ctrl.session.vehicle) {

                ctrl.positions.splice(0, ctrl.positions.length);
                ctrl.hasChains = false;

                for (var positionId in ctrl.session.vehicle.positions) {

                    if (ctrl.session.vehicle.positions.hasOwnProperty(positionId)) {

                        var posData = ctrl.session.vehicle.positions[positionId];

                        var template = {
                            label: "data.label",
                            tyreSerialNumber: "data.TyreFitment.serialNumber",
                            tyreSpecification: "data.TyreFitment.description",
                            tyreTHours: "data.TyreFitment.totalHours || \"0\"",
                            //tyreTDistance: "$filter(\"defaultVal\")($filter(\"number\")(data.TyreFitment.totalDistance), 0) + data.TyreFitment.distanceUnit",
                            tyreTDistance: "$filter(\"defaultVal\")($filter(\"number\")(data.TyreFitment.totalDistance), 0)",
                            rtd: "data.TyreFitment.remainingDepth.values[0] + \"/\" + data.TyreFitment.remainingDepth.values[1]",
                            tyreWorn: "Math.round(Math.max(0, 100-((data.TyreFitment.remainingDepth.value / data.TyreFitment.originalDepth.value) * 100)))",
                            rimSerialNumber: "data.RimFitment.serialNumber",
                            rimSpecification: "data.RimFitment.description",
                            rimTHours: "data.RimFitment.totalHours || \"0\"",
                            //rimTDistance: "$filter(\"defaultVal\")($filter(\"number\")(data.RimFitment.totalDistance), 0) + data.RimFitment.distanceUnit",
                            rimTDistance: "$filter(\"defaultVal\")($filter(\"number\")(data.RimFitment.totalDistance), 0)",
                            rimGutter: "data.RimFitment.doubleGutterRim ? amtXlatSvc.xlat(\"framework.yes_label\") : amtXlatSvc.xlat(\"framework.no_label\")",
                            rimNDT: "data.RimFitment.hoursSinceLastNDT",
                            chainWorn: "Math.round(Math.max(0, 100-((data.ChainFitment.remainingDepth.value / data.ChainFitment.originalDepth.value) * 100)))",
                            chainSerialNumber: "data.ChainFitment.serialNumber",
                            chainSpecification: "data.ChainFitment.description",
                            chainTHours: "data.ChainFitment.totalHours || \"0\"",
                            //chainTDistance: "$filter(\"defaultVal\")($filter(\"number\")(data.ChainFitment.totalDistance), 0) + data.ChainFitment.distanceUnit",
                            chainTDistance: "$filter(\"defaultVal\")($filter(\"number\")(data.ChainFitment.totalDistance), 0)",
                            rcd: "data.ChainFitment.remainingDepth.values[0] + \"/\" + data.ChainFitment.remainingDepth.values[1]"
                        };

                        var pos = resolveTemplate(posData, template);

                        ctrl.positions.push(pos);

                        if (posData.ChainFitment) {
                            ctrl.hasChains = true;
                        }
                    }
                }

                if (ctrl.tyreGrid.refresh) {
                    ctrl.tyreGrid.refresh();
                }

                if (ctrl.rimGrid.refresh) {
                    ctrl.rimGrid.refresh();
                }

                if (ctrl.chainGrid.refresh) {
                    ctrl.chainGrid.refresh();
                }
            }
        }

        this.$onChanges = function () {
            updateData();
        }

        this.$onInit = function () {

            ctrl.selectTab = function (tab) {
                $timeout(); // render after change to force resize

                if (tab === 'tyre') {
                    if (ctrl.tyreGrid.refresh) {
                        ctrl.tyreGrid.refresh();
                    }
                }

                if (tab === 'rim') {
                    if (ctrl.rimGrid.refresh) {
                        ctrl.rimGrid.refresh();
                    }
                }

                if (tab === 'chain') {
                    if (ctrl.chainGrid.refresh) {
                        ctrl.chainGrid.refresh();
                    }
                }
            }

            ctrl.chainGridResize = function () {
                if (ctrl.chainGrid.resize) {
                    ctrl.chainGrid.resize();
                }
            }

            ctrl.rimGridResize = function () {
                if (ctrl.rimGrid.resize) {
                    ctrl.rimGrid.resize();
                }
            }

            ctrl.tyreGridResize = function () {
                if (ctrl.tyreGrid.resize) {
                    ctrl.tyreGrid.resize();
                }
            }

            if (ctrl.tyreGrid.refresh) {
                ctrl.tyreGrid.refresh();
            }
            if (ctrl.rimGrid.refresh) {
                ctrl.rimGrid.refresh();
            }

            if (ctrl.chainGrid.refresh) {
                ctrl.chainGrid.refresh();
            }
        }
    }
