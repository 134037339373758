//import angular from 'angular';
import tmpl from './ocTimePicker.html';


    angular.module('app.directives')
        .directive('ocTimePicker', ['$timeout', function ($timeout) {
            return {
                transclude: true,
                require: "ngModel",
                scope: {
                    ngModel: "=",
                    minValue: "=",
                    maxValue: "=",
                    ngRequired: "=",
                    isDisabled: "=",
                    ngBlur: "&"
                },
                template: tmpl,
                link: function (scope: any, element, attrs, ngModel) {

                    function calculateValue() {
                        return (scope.minute || 0) + (scope.hour || 0) * 60;
                    }

                    scope.$watch('ngModel', function (newVal, oldVal) {
                        if (newVal !== oldVal || newVal !== calculateValue()) {
                            scope.hour = Math.floor(newVal / 60);
                            scope.minute = newVal % 60;
                        }

                        if (scope.ngBlur) {
                            scope.ngBlur();
                        }
                    });

                    scope.$watch('hour', function (newVal, oldVal) {
                        if (newVal !== oldVal) {
                            scope.ngModel = calculateValue();
                        }
                    });

                    scope.$watch('minute', function (newVal, oldVal) {
                        if (newVal !== oldVal) {
                            scope.ngModel = calculateValue();
                        }
                    });

                    ngModel.$validators.checkValidity = function (modelValue, viewValue) {
                        let value = modelValue || viewValue;

                        if (!value && scope.ngRequired) {
                            // can't be null
                            return false;
                        }
                        
                        if (scope.minValue && value < scope.minValue) {
                            // can't be less than the min value
                            return false;
                        }

                        if (scope.maxValue && value && value > scope.maxValue) {
                            // can't be more than the max value
                            return false;
                        }

                        return true;
                    }
                }
            };
        }]);
