/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
/**
 * @hidden
 */
var LoadingNotificationService = /** @class */ (function () {
    function LoadingNotificationService() {
        this.changes = new rxjs_1.Subject();
    }
    LoadingNotificationService.prototype.notifyLoaded = function (index) {
        this.changes.next(index);
    };
    LoadingNotificationService = tslib_1.__decorate([
        core_1.Injectable()
    ], LoadingNotificationService);
    return LoadingNotificationService;
}());
exports.LoadingNotificationService = LoadingNotificationService;
