//import angular from 'angular';
import BrowserSvc from '../services/browserSvc';

interface IWindowButtonElementParams {
    window: any;
    name: string;
    type: ButtonTypes;
    captionResource: string;
    primary?: boolean;
    visible?: boolean;
    disabled?: boolean;
    position?: number;
    callback: Function;
}

    angular.module('app')
        .factory('WindowFactory', [
            '$rootScope', 'amtXlatSvc', 'enums', '$q', 'errorReporter', '$timeout', '$injector', '$window', 'browserSvc',
            function ($rootScope: IRootScope, amtXlatSvc: IAmtXlatSvc, enums, $q: ng.IQService, errorReporter: IErrorReporter, $timeout: ng.ITimeoutService,
                        $injector: ng.auto.IInjectorService, $window: ng.IWindowService, browserSvc: BrowserSvc) {

                var self = this;
                var windowCount = 0;
                var headerHeight = 40;
                var footerHeight = 55;
                var footerHeightMobile = 90;
                var windows = [];

                var isMobile = browserSvc.isMobile;

                // concrete class
                function Window(
                    icon, footerOff, headerOff, fullScreenOff, component,
                    caption, shortCaption, windowRelatedRecordId, pos, size, modal, initParams, factory, onDataChanged,
                    onWindowClosed, afterWindowClosed, canClose, canMinimise, isMobile, allowOverflow, isAngular
                ) {
                    this.icon = icon;
                    this.fullScreenOff = true; //Turn off fullscreen as not required
                    this.footerOff = footerOff;
                    this.headerOff = headerOff;
                    this.component = component;
                    this.order = 0;
                    this.caption = caption;
                    this.shortCaption = shortCaption || caption;
                    this.windowRelatedRecordId = windowRelatedRecordId;
                    this.position = pos;
                    this.size = size;
                    this.modal = modal;
                    this.initParams = initParams;
                    this.visible = true;
                    this.onDataChanged = onDataChanged;
                    this.open = true;
                    this.onClose = onWindowClosed;
                    this.afterWindowClosed = afterWindowClosed;
                    this.canClose = canClose;
                    this.canMinimise = canMinimise;
                    this.isMobile = isMobile;
                    this.allowOverflow = allowOverflow;
                    this.isAngular = isAngular;

                    this.dragPos = undefined;

                    if (this.isMobile) {
                        console.log('window is mobile');
                        footerHeight = 70;
                    }

                    this.mouseDown = function (e) {
                        focusWindow(this);

                        this.dragPos = { x: e.clientX, y: e.clientY };
                        $rootScope.dragObject = this;

                        e.preventDefault();
                    };

                    var cName = component.replace(/-(\w)/g, function (match) {
                        return match[1].toUpperCase();
                    });


                    if (!$injector.has(cName + "Directive")) {
                        throw new Error("[" + component + "] is not a valid component");
                    }

                    this.mouseMove = function (e) {
                        if (this.dragPos !== null) {
                            this.position.left = ((this.position.left ? this.position.left : 0) + e.clientX - this.dragPos.x);
                            this.position.top = (this.position.top + e.clientY - this.dragPos.y);

                            this.dragPos.x = e.clientX;
                            this.dragPos.y = e.clientY;

                            e.preventDefault();
                        }
                    };

                    this.mouseUp = function () {
                        if (this.dragPos !== null) {
                            this.dragPos = null;
                            $rootScope.dragObject = undefined;
                        }
                    };

                    this.visible = true;

                    this.fullScreen = function () {

                        this.element.addClass('full-screen');
                        this.isFullScreen = true;

                        var cn = this.element.find(".child-window-container");
                        // save in case we go back to normal. 
                        var st = cn.attr("style");
                        if (st) {
                            this.containerStyle = st;
                        }
                        cn.removeAttr("style");
                        cn.removeAttr("ng-style");

                        cn = this.element.find(".child-window-body");
                        // save in case we go back to normal. 
                        st = cn.attr("style");
                        if (st) {
                            this.bodyStyle = st;
                        }
                        cn.removeAttr("style");
                        cn.removeAttr("ng-style");
                    };

                    this.windowed = function (e) {
                        if (this.isFullScreen) {
                            this.element.removeClass('full-screen');
                            var cn = this.element.find(".child-window-container");
                            cn.attr("style", this.containerStyle);
                            cn = this.element.find(".child-window-body");
                            cn.attr("style", this.bodyStyle);
                        }
                    };

                    this.hide = function () {
                        this.visible = false;
                    };

                    this.show = function () {
                        this.visible = true;
                    };

                    this.centre = function (parent) {
                        if (parent !== undefined && parent !== null) {
                            this.position.left = parent.position.left + parent.size.width - this.size.width / 2;
                            this.position.top = parent.position.top + parent.size.height - this.size.height / 2;
                        } else {
                            this.position.left = $window.clientWidth - this.size.width / 2;
                            this.position.top = $window.clientHeight - this.size.height / 2;
                        }
                    };

                    if (!this.onClose) {
                        this.onClose = function () {
                            self.closeWindow(this);
                        };
                    }
                }

                // this opens the window/dialog, and returns a promise.
                // it then calls resolve with the data passed to the onDataHandler first time.
                // It is assumed that the window closes after the onDataHandler is called. If for some reason it doesn't, the promise is done with after fist call.
                // if the window is closed is any way and the onDataHandler has not been called, then reject is called with a zero which should be looked for
                // in the error handling. (Note that errorReport.logError(...) also looks for the zero, so logError can be called freely. )
                // all other errors still reject with an error and should be handled appropriately
                this.openItemAsync = function (item) {

                    var wndAsync = {
                        wnd: undefined,
                        promise: undefined,
                        resetPromise: null
                    };

                    if (item.onDataChangeHandler) {
                        return $q.reject("Can not open item '" + item.component + "' async when data change handler is set '");
                    }

                    item.modal = true; // async so must be modal

                    var defered = $q.defer();
                    var resolved = false;
                    var closed = false;

                    setPromise();

                    item.onDataChangeHandler = function (data) {
                        if (closed) {
                            console.error("for openItemAsync, onDataChanged must be called before the window is closed");
                            return;
                        }
                        resolved = true;
                        defered.resolve(data);
                    };

                    item.afterWindowClosed = function () {
                        closed = true;
                        if (!resolved) {
                            // @ts-ignore
                            defered.reject(new Error(0, "windowFactory.openItemAsync closed without onDataChangeHandler call"));
                        }
                    }

                    function resetPromise() {
                        resolved = false;
                        defered = $q.defer();
                        setPromise();
                    };

                    function setPromise() {
                        if (item.onResolve) {
                            defered.promise = defered.promise.then(data => item.onResolve(data)).catch((error) => {
                                if (closed === true) {
                                    return $q.reject(error);
                                } else {
                                    resetPromise();
                                }
                            });                            
                        }

                        wndAsync.promise = defered.promise;
                    };

                    var wnd = this.openItem(item);

                    wndAsync.wnd = wnd;
                    wndAsync.resetPromise = resetPromise;

                    return wndAsync;
                }

                this.openItem = function (item) {
                    //console.log("WindowOpen:" + item.component);
                    //console.log(item.initParams);

                    if (!item.width) {
                        item.width = 600;
                    }
                    if (item.modal && item.parentWindow) {
                        // center over parent
                        item.left = item.parentWindow.left + ((item.parentWindow.width - item.width) / 2);
                        item.top = item.parentWindow.top + ((item.parentWindow.height - item.height) / 2);
                    }

                    if (!item.component) {
                        if (!item.initParams.component) {
                            errorReporter.logError('"dialog "' + item.caption + '" should have component moved out of init params', 'WindowFactory-OpenItem');
                        }
                        else {
                            errorReporter.logError('"dialog "' + item.caption + '" has no component', 'WindowFactory-OpenItem');
                        }
                    }

                    var wndPos = {
                        top: undefined,
                        left: undefined
                    };

                    if (item.top !== undefined) {
                        wndPos.top = item.top;
                    }

                    if (item.left !== undefined) {
                        wndPos.left = item.top;
                    }

                    return createWindow(
                        item.icon,
                        !!item.footerOff,
                        !!item.headerOff,
                        !!item.fullScreenOff,
                        item.component,
                        item.caption,
                        wndPos,
                        {
                            width: item.width,
                            height: item.height
                        },
                        !!item.modal,  // force this to a boolean value
                        item.initParams,
                        item.parentWindow,
                        item.onDataChangeHandler,
                        item.onWindowClose,
                        item.afterWindowClosed,
                        item.canClose,
                        item.canMinimise,
                        item.shortCaption,
                        item.windowRelatedRecordId,
                        item.excludeFromWindowContainer,
                        item.isMobile,
                        item.allowOverflow,
                        item.isAngular
                    );
                };

                var createWindow = function (
                    icon, footerOff, headerOff, fullScreenOff, component,
                    caption, pos, size, modal, initParams, parent, onDataChanged,
                    onWindowClosed?, afterWindowClosed?, canClose?, canMinimise?, shortCaption?, windowRelatedRecordId?, excludeFromWindowContainer?, isMobile?, allowOverflow?, isAngular?) {

                    if (!initParams) {
                        initParams = {};
                    }

                    // the rendering of the buttons uses pull-right, which will underneath uses float. 
                    // This result in the button order reversing 
                    // To match the order passed in we reverse them here.
                    if (initParams.buttons) {
                        initParams.buttons.reverse();
                    }
                    if (initParams.buttonStates) {
                        initParams.buttonStates.reverse();
                    }
                    if (!modal) {
                        // check if the window is already open
                        for (var i = 0; i < windows.length; i++) {

                            if ((windowRelatedRecordId != undefined && windows[i].windowRelatedRecordId === windowRelatedRecordId) || (windowRelatedRecordId == undefined && windows[i].caption === caption)) {
                                // bring it to the front
                                focusWindow(windows[i]);
                                return windows[i];
                            }
                        }
                    }
                    // shuffle the order of the other windows
                    for (var j = 0; j < windows.length; j++) {
                        windows[j].order++;
                    }

                    if (!parent && modal) {
                        parent = { position: { left: 0, top: 0 }, size: { width: window.innerWidth, height: window.innerHeight } };
                    }

                    // TODO: This needs some love
                    if (parent && (!pos || pos.left === undefined || pos.top === undefined)) {
                        pos = pos || {};
                        if (!pos.left) {
                            pos.left = parent.position.left + ((parent.size.width - size.width) / 2);
                        }

                        if (!pos.top) {
                            pos.top = (size.height > 0) ?
                                (parent.position.top + ((parent.size.height - size.height) / 2))
                                : 150;
                        }

                        // Safeguard for now until someone actions the above todo.
                        if (pos.left < 0) {
                            pos.left = 0;
                        }

                        if (pos.top < 0) {
                            pos.top = 0;
                        }
                    } else if (!pos || (!pos.left && !pos.top)) {
                        // cascade from the top-right
                        // calc the number of windows that could fit on the current client height
                        /*jshint bitwise:false*/
                        var headerHeightsInClientArea = ((window.innerHeight / headerHeight) - 1) | 0;

                        //Get count of visible windows
                        var visibleWindowCount = 0;
                        for (var i = 0; i < windows.length; i++) {
                            if (windows[i].visible === true) {
                                visibleWindowCount++;
                            }
                        }

                        // x = number of times the headerHeights can be divided by the client height
                        /*jshint bitwise:false*/
                        var x = (visibleWindowCount / headerHeightsInClientArea) | 0;

                        var y = visibleWindowCount - (x * headerHeightsInClientArea);

                        pos = {
                            left: window.innerWidth - 20 - size.width - ((size.height ? size.height : window.innerHeight) / headerHeightsInClientArea * windowCount), // height is used for the cascade effect
                            top: (40 * y)
                        };

                        if (pos.left < 0) {
                            pos.left = 0;
                        }

                        windowCount++;
                    }

                    var wnd = new Window(
                        icon, footerOff, headerOff, fullScreenOff, component,
                        caption, shortCaption, windowRelatedRecordId, pos, size, modal, initParams, self, onDataChanged,
                        onWindowClosed, afterWindowClosed, canClose, canMinimise, isMobile, allowOverflow, isAngular);

                    if (!excludeFromWindowContainer) {
                        windows.splice(0, 0, wnd);
                    }

                    $timeout();

                    return wnd;
                };

                this.closeFullscreen = function () {
                    for (var i = 0; i < windows.length; i++) {
                        var wnd = windows[i];
                        if (wnd.isFullScreen) {
                            wnd.visible = false;
                            //wnd.element.removeClass('full-screen');
                            //var cn = wnd.element.find(".child-window-container");
                            //cn.attr("style", wnd.style);
                        }
                    }
                }

                this.unimplementedMessage = function (functionality) {
                    if (functionality) {
                        return this.alert('alerts.unimplementedHeading', ['common.ok_label'], 'alerts.unimplementedFunctionality', functionality);
                    }
                    else {
                        return this.alert('alerts.unimplementedHeading', ['common.ok_label'], 'alerts.unimplemented');
                    }
                };

                this.accessDeniedMessage = function (functionality) {
                    if (functionality) {
                        return this.alert('alerts.accessDeniedHeading', ['common.ok_label'], 'alerts.accessDeniedHeadingFunctionality', functionality);
                    }
                    else {
                        return this.alert('alerts.accessDeniedHeading', ['common.ok_label'], 'alerts.accessDenied');
                    }
                };

                // simplify the window creation
                this.alertWindow = function (caption, buttons, text, parameters, width, textAlignClass, nonResourcedText) {
                    if (!width) {
                        width = 500;
                    }
                    if (!parameters || Object.prototype.toString.call(parameters) !== '[object Array]') {
                        parameters = [];
                        for (var i = 0; i < arguments.length; ++i) {
                            if (i > 2) {
                                parameters.push(arguments[i]);
                            }
                        }
                    }
                    return self.iconAlertWindow('glyphicon-alert', caption, buttons, text, parameters, width, textAlignClass, nonResourcedText);
                };

                this.iconAlertWindow = function (icon, caption, buttons, text, parameters, width, textAlignClass, nonResourcedText) {
                    if (!width) {
                        width = 500;
                    }
                    var defer = $q.defer();
                    var wnd = {};
                    try {
                        if (!buttons || buttons.length === 0) {
                            buttons = ['framework.ok_label'];
                        }
                        // create the buttons
                        var dlgButtons = [];
                        var i;
                        for (i = 0; i < buttons.length; i++) {
                            dlgButtons.push(
                                {
                                    primary: false,
                                    value: buttons[i],
                                    name: buttons[i],  // any value really
                                    click: buttons[i],
                                    type: enums.buttonTypes.button,
                                    cancel: (
                                        (buttons[i].cancel !== undefined && buttons[i].cancel === true) ||
                                        (Object.prototype.toString.call(buttons[i]) === '[object String]' && buttons[i].indexOf('cancel') > -1)
                                    )
                                });
                        };
                        dlgButtons[dlgButtons.length - 1].primary = true;

                        var initParams = {
                            text: amtXlatSvc.xlat(text, ...(parameters || [])),
                            textAlignClass: "center-box",
                            buttons: dlgButtons,
                            buttonStates: [],
                            showCloseOnSave: false
                        };

                        if (textAlignClass) {
                            initParams.textAlignClass = textAlignClass;
                        }

                        var pos = {
                            left: (window.innerWidth - width) / 2,
                            top: 150
                        };

                        var size = { width: width, height: 0 };

                        wnd = createWindow(icon, false, false, false, 'alert-window', amtXlatSvc.xlat(caption), pos, size, /* modal */ true, initParams, /* parent */ null, function (button) { return defer.resolve(button); });
                    }
                    catch (error) {
                        defer.reject(error);
                    }
                    (defer.promise as any).windowHandle = wnd; //TODO: what?
                    return defer.promise;
                };

                function closeWindows(windowList) {
                    for (var i = windowList.length - 1; i > -1; i--) {
                        var w = windows.indexOf(windowList[i]);
                        if (w > -1) {
                            var wnd = windowList[i];

                            windows.splice(w, 1);
                            if (wnd.afterWindowClosed) {
                                wnd.afterWindowClosed();
                            }
                        }
                    }

                    windowCount = windows.length;
                }

                this.closeLastWindow = function () {
                    if (windows[0]) {
                        if (windows[0].isDirty) {

                        }
                        else {
                            this.closeWindow(windows[0]);
                        }
                    }
                }

                var addButtonElement = function (params: IWindowButtonElementParams) {

                    let wnd = params.window;

                    if (!wnd.buttons) {
                        wnd.buttonStates = {};
                        wnd.buttonMethods = {};
                        wnd.buttons = [];
                    }

                    wnd.buttonStates[params.name] = {
                        visible: params.visible,
                        disabled: params.disabled
                    };

                    wnd.buttonMethods[params.name] = params.callback;

                    let button = {
                        title: params.captionResource,
                        value: params.captionResource,
                        name: params.name,
                        primary: params.primary,
                        cancel: params.type === ButtonTypes.button && params.name == 'cancel',
                        click: params.name,
                        type: params.type,
                        visible: params.visible,
                        disabled: params.disabled
                    };

                    if (params.position != null) {
                        wnd.buttons.splice(params.position, 0, button);
                    } else {
                        wnd.buttons.push(button);
                    }
                }

                this.addButton = function (ctrl, captionResource, name, callback, primary: boolean = false, visible: boolean = true, disabled: boolean = false, position?: number) {
                    addButtonElement({
                        window: ctrl,
                        type: ButtonTypes.button,
                        captionResource: captionResource,
                        name: name,
                        callback: callback,
                        primary: primary,
                        visible: visible,
                        disabled: disabled,
                        position: position
                    });
                }

                this.addAction = function (ctrl, captionResource, name, callback, visible: boolean = true, disabled: boolean = false, position?: number) {
                    addButtonElement({
                        window: ctrl,
                        type: ButtonTypes.action,
                        captionResource: captionResource,
                        name: name,
                        callback: callback,
                        visible: visible,
                        disabled: disabled,
                        position: position
                    });
                }

                this.closeWindow = function (wnd) {
                    if (!wnd) {
                        console.warn("Attempted close of undefined window");
                        return;
                    }

                    wnd.open = false;

                    var w = windows.indexOf(wnd);

                    if (w > -1) {
                        // remove the window from the collection
                        windows.splice(w, 1);
                        if (wnd.afterWindowClosed) {
                            wnd.afterWindowClosed(wnd);
                        }
                    }

                    if (windows.length === 0) {
                        windowCount = 0;
                    }
                };

                this.closeWindowbyRelatedRecordId = function (recId) {
                    let idx = windows.findIndex(w => w.windowRelatedRecordId === recId);
                    if (idx < 0) {
                        return false;
                    }

                    let wnd = windows[idx];
 
                    wnd.open = false;
                    windows.splice(idx, 1);
                    if (wnd.afterWindowClosed) {
                        wnd.afterWindowClosed(wnd);
                    }

                    //TODO: seems like this is bad housekeeping for a count, but this behaviour seems like it might be needed for current window positioning behaviour
                    if (windows.length === 0) {
                        windowCount = 0;
                    }
                    return true;
                }

                this.closeMinimisedWindow = function (wnd) {
                    if (!wnd) {
                        console.warn("Attempted close of undefined window");
                        return;
                    }

                    if ($injector.has('confirmSvc')) {

                        var confirmSvc = <IConfirmSvc>$injector.get('confirmSvc');

                        confirmSvc.confirmSaveChange(wnd.isDirty).then(function () {

                            wnd.open = false;

                            var w = windows.indexOf(wnd);

                            if (w > -1) {
                                // remove the window from the collection
                                windows.splice(w, 1);
                                if (wnd.afterWindowClosed) {
                                    wnd.afterWindowClosed(wnd);
                                }
                            }

                            if (windows.length === 0) {
                                windowCount = 0;
                            }
                        }).catch(function () {
                            // this means they elected not to lose changes
                            // absorb to prevent console error
                        });
                    }
                };

                this.hasDirty = function () {
                    for (var i = 0; i < windows.length; i++) {
                        if (windows[i].isDirty) {
                            return true;
                        }
                    }
                    return false;
                };

                this.getFooterHeight = function () {
                    return this.isMobile ? footerHeightMobile : footerHeight;
                }

                this.closeAllWindows = function (bypassConfirmation) {
                    var windowsWithChanges = [];

                    for (var i = 0; i < windows.length; i++) {
                        if (windows[i].isDirty) {
                            windowsWithChanges.push(windows[i]);
                        }
                    }

                    if (bypassConfirmation || windowsWithChanges.length == 0)
                        closeWindows(windows);
                    else {
                        this.openItem({
                            component: 'close-all-windows-confirmation',
                            caption: amtXlatSvc.xlat("common.unsavedChangesTitle"),
                            initParams: {
                                windowList: windowsWithChanges,
                                onProceed: this.closeAllWindows
                            },
                            width: 400,
                            modal: true
                        });
                    }

                    if (windows.length === 0) {
                        windowCount = 0;
                    }
                };

                this.closeAllMinimisedWindows = function (bypassConfirmation) {
                    var windowsWithChanges = [];
                    var minimisedWindows = [];

                    for (var i = 0; i < windows.length; i++) {
                        if (windows[i].visible === false) {
                            // add to list of minimised windows
                            minimisedWindows.push(windows[i]);

                            if (windows[i].isDirty) {
                                windowsWithChanges.push(windows[i]);
                            }
                        }
                    }

                    if (bypassConfirmation || windowsWithChanges.length == 0)
                        closeWindows(minimisedWindows);
                    else {
                        this.openItem({
                            caption: amtXlatSvc.xlat("common.unsavedChangesTitle"),
                            component: 'close-all-windows-confirmation',
                            initParams: {
                                windowList: windowsWithChanges,
                                onProceed: this.closeAllMinimisedWindows
                            },
                            width: 400,
                            modal: true
                        });
                    }

                    if (windows.length === 0) {
                        windowCount = 0;
                    }
                };

                this.closeAllOpenWindows = function (bypassConfirmation) {
                    var windowsWithChanges = [];
                    var openWindows = [];

                    for (var i = 0; i < windows.length; i++) {
                        if (windows[i].visible === true) {
                            // add to list of open windows
                            openWindows.push(windows[i]);

                            if (windows[i].isDirty) {
                                windowsWithChanges.push(windows[i]);
                            }
                        }
                    }

                    if (bypassConfirmation || windowsWithChanges.length == 0)
                        closeWindows(openWindows);
                    else {
                        this.openItem({
                            caption: amtXlatSvc.xlat("common.unsavedChangesTitle"),
                            component: 'close-all-windows-confirmation',
                            initParams: {
                                windowList: windowsWithChanges,
                                onProceed: this.closeAllOpenWindows
                            },
                            width: 400,
                            modal: true
                        });
                    }

                    if (windows.length === 0) {
                        windowCount = 0;
                    }
                };

                var focusWindow = function (wnd) {
                    if (wnd.order > 0) {
                        for (var i = 0; i < windows.length; i++) {
                            if (windows[i].order < wnd.order) {
                                windows[i].order++;
                            }
                        }
                    }

                    // move the item to the top
                    wnd.order = 0;
                    wnd.visible = true;
                };

                function openWindows(dialogList) {
                    if (dialogList.length > 0) {
                        var width = dialogList[0].width || 800;
                        self.openItemAsync({
                            caption: 'Dialog Test:' + dialogList[0].component,
                            component: dialogList[0].component,
                            initParams: dialogList[0].data,
                            width: width,
                            modal: true
                        }).promise.catch(function () {

                        }).finally(function () {
                            dialogList.shift();
                            openWindows(dialogList);
                        });
                    }
                }


                // public interface
                return {
                    alert: this.alertWindow,
                    unimplementedMessage: this.unimplementedMessage,
                    accessDeniedMessage: this.accessDeniedMessage,
                    iconAlert: this.iconAlertWindow,
                    close: this.closeWindow,
                    fullScreen: this.fullScreen,
                    hasDirty: this.hasDirty,
                    addButton: this.addButton,
                    addAction: this.addAction,
                    focusWindow: focusWindow,
                    minimiseWindow: this.minimiseWindow,
                    closeAllMinimisedWindows: this.closeAllMinimisedWindows,
                    closeAllOpenWindows: this.closeAllOpenWindows,
                    closeAllWindows: this.closeAllWindows,
                    closeLastWindow: this.closeLastWindow,
                    closeWindow: this.closeWindow,
                    closeFullscreen: this.closeFullscreen,
                    closeWindows: this.closeWindows,
                    getFooterHeight: this.getFooterHeight,
                    windows: windows,
                    windowCount: windowCount,
                    openItem: this.openItem,
                    openItemAsync: this.openItemAsync,
                    headerHeight: headerHeight,
                    footerHeight: footerHeight,
                    closeMinimisedWindow: this.closeMinimisedWindow,
                    closeWindowbyRelatedRecordId: this.closeWindowbyRelatedRecordId
                };
            }]
        );
