//import angular from 'angular';


export default class FieldSurveyUrls {
    private readonly apiPath = baseUrl + 'api/';

    public readonly search = this.apiPath + 'equipment/vehicle/searchFieldSurveys';
    public readonly searchExport = 'equipment/vehicle/searchFieldSurveyExport';

    // reference data
    public readonly getFleets = baseApiUrl + 'equipment/common/getFleets';
    public readonly getSpecifications = baseApiUrl + 'equipment/vehicle/getSpecifications';
    public readonly getStatuses = baseApiUrl + 'equipment/vehicle/getFieldSurveyStatuses';

    public readonly getFieldSurveyTransactions = 'equipment/vehicle/getFieldSurveyTransactions';
    public readonly GetCauseIdFromReading = 'equipment/vehicle/getCauseIdFromReading';

    public readonly getFieldSurveyImages = 'equipment/vehicle/getFieldSurveyImages';
    public readonly getFieldSurveyVisualInspection = 'equipment/vehicle/getFieldSurveyVisualInspection';
    public readonly getVehicleForFieldSurvey = 'equipment/vehicle/getVehicleForFieldSurvey';
}

angular.module("app").service("fieldSurveyUrls", FieldSurveyUrls);