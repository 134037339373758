//import angular from 'angular';
import BrowserSvc from '../../../services/browserSvc';
import tmpl from './vehicleFitments.html';



    angular.module('app.directives').component('vehicleFitments', {
        template: tmpl,
        bindings: {
            vehicle: '<',
            showReadOrder: '=',
            svgReset: '&',
            readonly: '='
        },
        controller: ["$timeout", '$scope', 'dataBroker', 'WindowFactory', 'amtXlatSvc', 'convert', '$filter', 'browserSvc',
            function vehicleFitmentsCtrl($timeout, $scope, dataBroker, WindowFactory: IWindowFactory, amtXlatSvc: IAmtXlatSvc, convert, $filter: ng.IFilterService, browserSvc: BrowserSvc) {

                var ctrl = this;
                ctrl.isMobile = browserSvc.isMobile;
                ctrl.currentTread = currentTread;
                ctrl.currentChainDepth = currentChainDepth;
                ctrl.sorterFunc = sorterFunc;
                ctrl.toggleOrder = toggleOrder;
                ctrl.openTyreSerial = openTyreSerial;
                ctrl.openRimSerial = openRimSerial;
                ctrl.openChainSerial = openChainSerial;

                var numberFilter = $filter('number');

                function currentTread(r) {
                    let pos = ctrl.vehicle.positions[r.positionId];
                    if (pos && pos.TyreFitment) {
                        if (pos.TyreFitment.loading) {
                            return amtXlatSvc.xlat('fieldSurvey.loading');
                        }
                        if (ctrl.isMobile && pos.TyreFitment.remainingDepth && pos.TyreFitment.remainingDepth.values)
                            return pos.TyreFitment.remainingDepth.values[0] + '/' + pos.TyreFitment.remainingDepth.values[1];

                        if (pos.TyreFitment.remainingDepth && pos.TyreFitment.remainingDepth.value) {
                            if (ctrl.vehicle.units.depth.unitAbbreviation !== pos.TyreFitment.remainingDepth.unit) {
                                pos.TyreFitment.remainingDepth.value =
                                    convert.convertTo(pos.TyreFitment.remainingDepth, ctrl.vehicle.units.depth.unitAbbreviation);
                                pos.TyreFitment.remainingDepth.unit = ctrl.vehicle.units.depth.unitAbbreviation;
                            }
                            return numberFilter(pos.TyreFitment.remainingDepth.value, 1);
                        }
                    }

                    return "----";
                }

                function currentChainDepth(r) {
                    let pos = ctrl.vehicle.positions[r.positionId];
                    if (pos && pos.ChainFitment) {
                        if (pos.ChainFitment.loading) {
                            return amtXlatSvc.xlat('fieldSurvey.loading');
                        }

                        if (ctrl.isMobile && pos.ChainFitment.remainingDepth && pos.ChainFitment.remainingDepth.values)
                            return pos.ChainFitment.remainingDepth.values[0] + '/' + pos.ChainFitment.remainingDepth.values[1];

                        if (pos.ChainFitment.remainingDepth && pos.ChainFitment.remainingDepth.value) {
                            if (ctrl.vehicle.units.depth.unitAbbreviation !== pos.ChainFitment.remainingDepth.unit) {
                                pos.ChainFitment.remainingDepth.value =
                                    convert.convertTo(pos.ChainFitment.remainingDepth, ctrl.vehicle.units.depth.unitAbbreviation);
                                pos.ChainFitment.remainingDepth.unit = ctrl.vehicle.units.depth.unitAbbreviation;
                            }
                            return numberFilter(pos.ChainFitment.remainingDepth.value, 1);
                        }
                    }
                    return "----";
                }

                function sorterFunc(reading) {
                    return parseInt(ctrl.showReadOrder ? reading.positionNo : reading.positionLabel);
                }

                function toggleOrder() {
                    console.log("toggle order");

                    ctrl.showReadOrder = !ctrl.showReadOrder;

                    // need to let my changes run down to the Schematic before I redraw it.
                    $timeout(function() {
                        ctrl.svgReset();
                    });
                }

                function openTyreSerial(r) {
                    var pos = ctrl.vehicle.positions[r.positionId];
                    var data = {
                        name: amtXlatSvc.xlat('vehicle.fitmentTypeTyre'),
                        type: 'tyre',
                        serial: pos.TyreFitment ? pos.TyreFitment.serialNumber : amtXlatSvc.xlat('fieldSurvey.notInstalled'),
                        reading: r,
                        schematic: ctrl.vehicle.schematic
                    };
                    changeSerial(data);
                }

                function openRimSerial(r) {
                    var pos = ctrl.vehicle.positions[r.positionId];
                    var data = {
                        name: amtXlatSvc.xlat('vehicle.fitmentTypeRim'),
                        type: 'rim',
                        serial: pos.RimFitment ? pos.RimFitment.serialNumber : amtXlatSvc.xlat('fieldSurvey.notInstalled'),
                        reading: r,
                        schematic: ctrl.vehicle.schematic
                    };
                    changeSerial(data);
                }

                function openChainSerial(r) {
                    var pos = ctrl.vehicle.positions[r.positionId];
                    var data = {
                        name: amtXlatSvc.xlat('vehicle.fitmentTypeChain'),
                        type: 'chain',
                        serial: pos.ChainFitment ? pos.ChainFitment.serialNumber : amtXlatSvc.xlat('fieldSurvey.notInstalled'),
                        reading: r,
                        schematic: ctrl.vehicle.schematic
                    };
                    changeSerial(data);
                }

                function changeSerial(data) {

                    if (!browserSvc.isMobile) {

                        var r = data.reading;

                        switch (data.type) {
                            case 'tyre':
                            {
                                if (!ctrl.vehicle.positions[r.positionId].TyreFitment ||
                                    !ctrl.vehicle.positions[r.positionId].TyreFitment.serialNumber) {
                                    return WindowFactory.alert('fieldSurvey.invalidSerialNumberHeader',
                                            ['common.ok_label', 'fieldSurvey.clearSerialNumber'],
                                            'fieldSurvey.invalidSerialNumberEmptyPosition_tyre')
                                        .then(function(button) {
                                            if (button === 'fieldSurvey.clearSerialNumber') {
                                                delete r.tyreSerial;
                                            }
                                        });
                                } else if (r.tyreSerial && r.tyreSerial !== ctrl.vehicle.positions[r.positionId].TyreFitment.serialNumber) {
                                    // user entered serial number does not match otracom.
                                    return WindowFactory.alert('fieldSurvey.invalidSerialNumberHeader',
                                            ['common.cancel_label', 'fieldSurvey.useOtracom'],
                                            'fieldSurvey.invalidSerialNumberMatch', r.tyreSerial,
                                            ctrl.vehicle.positions[r.positionId].TyreFitment.serialNumber)
                                        .then(function(button) {
                                            if (button === 'fieldSurvey.useOtracom') {
                                                delete r.tyreSerial;
                                            }
                                        });
                                }
                            }

                            case 'chain':
                            {
                                if (!ctrl.vehicle.positions[r.positionId].ChainFitment ||
                                    !ctrl.vehicle.positions[r.positionId].ChainFitment.serialNumber) {
                                    return WindowFactory.alert('fieldSurvey.invalidSerialNumberHeader',
                                            ['common.ok_label', 'fieldSurvey.clearSerialNumber'],
                                            'fieldSurvey.invalidSerialNumberEmptyPosition_chain')
                                        .then(function(button) {
                                            if (button === 'fieldSurvey.clearSerialNumber') {
                                                delete r.chainSerial;
                                            }
                                        });
                                } else if (r.chainSerial && r.chainSerial !== ctrl.vehicle.positions[r.positionId].ChainFitment.serialNumber) {
                                    // user entered serial number does not match otracom.
                                    return WindowFactory.alert('fieldSurvey.invalidSerialNumberHeader',
                                            ['common.cancel_label', 'fieldSurvey.useOtracom'],
                                            'fieldSurvey.invalidSerialNumberMatch', r.chainSerial,
                                            ctrl.vehicle.positions[r.positionId].ChainFitment.serialNumber)
                                        .then(function(button) {
                                            if (button === 'fieldSurvey.useOtracom') {
                                                delete r.chainSerial;
                                            }
                                        });
                                }
                            }
                        }
                    }

                    var readingInfo = data.reading;

                    WindowFactory.openItem({
                        component: 'serial-change',
                        caption: amtXlatSvc.xlat('fieldSurvey.surveyChangeHeader', data.reading.positionLabel, data.reading.positionNo),
                        initParams: data,
                        width: 750,
                        modal: true,
                        onDataChangeHandler: function(newVal, chainRemoved, reading) {

                            if (!ctrl.vehicle.positions[readingInfo.positionId]) {
                                ctrl.vehicle.positions[readingInfo.positionId] = {};
                            }

                            var pos = ctrl.vehicle.positions[readingInfo.positionId];

                            switch (data.type) {
                                case 'tyre':
                                {
                                    if (!pos.TyreFitment) {
                                        pos.TyreFitment = {}
                                    }
                                    pos.TyreFitment.remainingDepth = reading;
                                    break;
                                }
                                case 'chain':
                                {
                                    if (!pos.ChainFitment) {
                                        pos.ChainFitment = {}
                                    }
                                    pos.ChainFitment.remainingDepth = reading;
                                    break;
                                }
                            }

                            // these are the field survey user entered data
                            data.reading[data.type + 'Serial'] = newVal;
                            data.reading.chainRemoved = chainRemoved;
                            $timeout();
                        }
                    });
                }
            }
        ]
    });
