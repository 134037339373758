//import angular from 'angular';
import * as _ from 'underscore';



    angular.module('app.admin')
        .controller('vehicleTyreStrategyCtrl', ['$scope', 'amtCommandQuerySvc', 'amtConstants', 'amtXlatSvc', 'notifySvc', 'errorReporter', 'ocSecuritySvc',
            function ($scope, amtCommandQuerySvc, amtConstants, amtXlatSvc, notifySvc, errorReporter, ocSecuritySvc) {

                var vm = this;

                vm.apiUrls = amtConstants.url;

                vm.gridControl = {};

                vm.processing = true;

                vm.model = [];

                this.$onInit = function () {

                    vm.readonly = !ocSecuritySvc.isAuthorised('Security.Site.Forecasting', 'readWrite');

                    vm.getPositions();
                };

                vm.getPositions = function () {

                    amtCommandQuerySvc.post(vm.apiUrls.getVehicleTyreStrategies, {}).then(function (response) {

                        vm.positionColumns = response.positionColumns;
                        vm.model = response.vehicleSpecificationTyreStrategies;

                        if (vm.gridControl && vm.gridControl.recompile) {
                            vm.gridControl.recompile();
                        }
                       
                    }).catch(function (error) {
                        errorReporter.logError(error);
                    }).finally(function () {
                        vm.processing = false;
                    });
                };

                vm.formatColumn = function (colName) {
                    return amtXlatSvc.xlat('forecastTyres.position', colName.replace("pos", ""));
                };

                $scope.updatePosition = function (dataItem, position) {

                    let row = _.find(vm.model, function (m) { return m.id === dataItem.id; });

                    if (row && row.defaultTyreStrategies[position]) {

                        vm.processing = true;

                        row.defaultTyreStrategies[position].isNewTyre = !row.defaultTyreStrategies[position].isNewTyre;

                        return amtCommandQuerySvc.put(vm.apiUrls.updateVehicleTyreStrategy, {
                            id: row.defaultTyreStrategies[position].id,
                            isNewTyre: row.defaultTyreStrategies[position].isNewTyre
                        }).then(function (response) {

                            notifySvc.success(amtXlatSvc.xlat("forecastTyres.vehicleSpecificationNewTyreChangeoutPositionsUpdateSuccess"));

                        }).catch(function (error) {
                            errorReporter.logError(error);
                        }).finally(function () {
                            vm.processing = false;
                        });
                    }
                };
            }
        ]);
