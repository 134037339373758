/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
/* tslint:disable:pipe-prefix */
var core_1 = require("@angular/core");
var kendo_common_1 = require("@progress/kendo-common");
var utils_1 = require("../../utils");
var kendo_angular_intl_1 = require("@progress/kendo-angular-intl");
var FORMAT_REGEX = /\{\d+:?/;
/**
 * @hidden
 */
var FieldAccessorPipe = /** @class */ (function () {
    function FieldAccessorPipe(intlService) {
        this.intlService = intlService;
    }
    FieldAccessorPipe.prototype.transform = function (dataItem, fieldName, format) {
        if (!utils_1.isNullOrEmptyString(fieldName)) {
            var value = kendo_common_1.getter(fieldName)(dataItem);
            if (!utils_1.isNullOrEmptyString(format)) {
                return this.formatValue(format, value);
            }
            return value;
        }
        return dataItem;
    };
    FieldAccessorPipe.prototype.formatValue = function (format, value) {
        var intl = this.intlService;
        if (utils_1.isString(format) && format.match(FORMAT_REGEX)) {
            return intl.format(format, value);
        }
        return intl.toString(value, format);
    };
    FieldAccessorPipe = tslib_1.__decorate([
        core_1.Pipe({
            name: 'valueOf',
            pure: false
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_intl_1.IntlService])
    ], FieldAccessorPipe);
    return FieldAccessorPipe;
}());
exports.FieldAccessorPipe = FieldAccessorPipe;
