//import angular from 'angular';

angular.module('app.directives')

        .directive('ocDroppable', function () {
            return {
                link: function (scope: any, element, attrs) {
                    // again we need the native object
                    var el = element[0];

                    var onDropFn = scope.$eval(attrs.onDrop);

                    el.addEventListener(
                        'dragover',
                        function (e) {
                            e.dataTransfer.dropEffect = 'move';
                            // allows us to drop
                            if (e.preventDefault) {
                                e.preventDefault();
                            }

                            this.classList.add('over');

                            // scroll the parent.
                            var ul: HTMLElement = this.parentNode as HTMLElement;
                            if (ul) {
                                var top = ul.scrollTop;
                                if (top > this.offsetTop) {
                                    // at the top scroll
                                    ul.scrollTop = ul.scrollTop - this.offsetHeight;
                                }
                                if (this.offsetTop + this.offsetHeight > ul.clientHeight + top) {
                                    // scroll at the bottom
                                    ul.scrollTop = ul.scrollTop + this.offsetHeight;
                                }
                            }
                            return false;
                        },
                        false
                    );

                    el.addEventListener(
                        'dragenter',
                        function (e) {
                            this.classList.add('over');
                            return false;
                        },
                        false
                    );

                    el.addEventListener(
                        'dragleave',
                        function (e) {
                            this.classList.remove('over');
                            return false;
                        },
                        false
                    );

                    el.addEventListener(
                        'drop',
                        function (e) {
                            // Stops some browsers from redirecting.
                            this.classList.remove('over');

                            if (onDropFn) {
                                if (e.stopPropagation) {
                                    e.stopPropagation();
                                }

                                var data = {
                                    sourceId: e.dataTransfer.getData('text'),
                                    targetId: attrs.dragDropId
                                };

                                onDropFn.call(scope, data);
                            }

                            return false;
                        },
                        false
                    );
                }
            };
        })

        .directive('ocDraggable', [function () {
            return function (scope, element, attrs) {
                var el = element[0];

                el.draggable = true;

                el.addEventListener(
                    'dragstart',
                    function (e) {
                        this.classList.add('drag');

                        e.dataTransfer.effectAllowed = 'move';
                        e.dataTransfer.setData('text', attrs.dragDropId);

                        return false;
                    },
                    false
                );

                el.addEventListener(
                    'dragend',
                    function (e) {
                        this.classList.remove('drag');
                        return false;
                    },
                    false
                );
            };
        }]);
