import { Component, ViewChild } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { LiveForecastRimElement, LiveForecastRimGroup, LiveForecastRimResponse, LiveForecastRimSelectedFilterItem } from '../../service/Interface/Widget.interface';
import { LiveForecastDialogUi } from '../../service/Interface/WidgetConstant.interface';
import { WidgetRxjsService } from '../../service/rxjs/WidgetRxjs.service';
import { DialogRxjsService } from './../../service/rxjs/DialogRxjs.service';
import { LiveForecastRimWidgetHelper } from './../../service/widgetHelper/LiveForecastRimWidget.helper';
import { WidgetConstant } from './../../service/widgetHelper/WidgetConstant';

@Component({
    selector: 'dialog-live-forecast-rim-element',
    templateUrl: './live-forecast-rim-element.component.html',
    styleUrls: ['./../../site-dashboard.component.scss']
})

export class DialogLiveForecastRimElementComponent {
    public title: string;
    public ui: LiveForecastDialogUi;
    public hasReceivedElement: boolean = false;
    public state: State = {
        skip: 0,
        take: 10,
    };
    //Display Selection Dropdown
    public displaySelectionList: Array<string>;
    public selectedDisplay: string;

    //Duration Selection Dropdown
    public durationSelectionList: Array<string>;
    public selectedDuration: string;

    //Result
    public result: LiveForecastRimResponse;
    public selectedResult: LiveForecastRimGroup;
    public selectedRim: Array<LiveForecastRimElement>;
    public displayRim: Array<LiveForecastRimElement>;
    public filterOption: LiveForecastRimSelectedFilterItem = {
        filter: {
                rimSize: [],
                rimSpecification: [],
                vehicleSerialNumber: [],
                vehicleSpecification: []
            },
        rimSizeList: [],
        rimSpecificationList: [],
        vehicleSerialNumberList: [],
        vehicleSpecificationList: [],
    };

    constructor(private widgetRxjs: WidgetRxjsService, private dialogRxjs: DialogRxjsService, private liveForecastRimHelper: LiveForecastRimWidgetHelper, private constant: WidgetConstant) {
        this.ui = this.constant.getLiveForecastDialog();
        this.subscribeLiveForecastDialogListener();
        this.title = this.ui.rimTitle;
    }

    private subscribeLiveForecastDialogListener() {
        this.dialogRxjs.liveForecastRimDialog.subscribe(element => {
            if (element.action) {
                this.setLiveForecastRimElement(element.siteDashboardWidgetId);
            }
        });
    }

    private setLiveForecastRimElement(siteDashboardWidgetId: string) {
        this.widgetRxjs.liveForecastRimWidget.subscribe(widgets => {
            widgets.forEach(widget => {
                if (widget.siteDashboardWidgetId === siteDashboardWidgetId && widget.result.hasLiveForecast) {
                    this.result = widget.result;
                    this.selectedDisplay = this.liveForecastRimHelper.getDefaultSelectedDisplay();
                    this.displaySelectionList = this.liveForecastRimHelper.getDefaultSelectionDropDownMenu();
                    this.onChangeSelectedDisplay();
                    this.hasReceivedElement = true;
                }
            });
        });
    }

    public dismissLiveForecastRimElement() {
        this.dialogRxjs.setLiveForecastRimDialogActionListener(this.dialogRxjs.defaultLiveForecastDialog);
    }

    public onChangeSelectedDisplay() {
        this.selectedResult = this.liveForecastRimHelper.getSelectedRimGroup(this.selectedDisplay, this.result);
        this.setDurationSelection();
    }

    public setDurationSelection() {
        this.durationSelectionList = this.liveForecastRimHelper.getDefaultDurationDropDownMenu(this.selectedResult.filterOption.validate);
        this.selectedDuration = this.liveForecastRimHelper.getDefaultDurationSelected(this.durationSelectionList);
        this.onChangeSelectedDuration();
    }

    public onChangeSelectedDuration() {
        this.selectedRim = this.liveForecastRimHelper.getSelectedRim(this.selectedDuration, this.selectedResult);
        this.displayRim = this.selectedRim;
        this.filterOption = this.liveForecastRimHelper.getSelectedFilterOption(this.selectedDuration, this.selectedResult);
    }

    public onChangeRimSize(newRimSizeList: Array<string>) {
        this.filterOption.filter.rimSize = this.liveForecastRimHelper.setSelectedRimSizeList(newRimSizeList, this.filterOption.rimSizeList);
        this.onChangeFilter();
    }

    public onChangeRimSpecification(newRimSpecList: Array<string>) {
        this.filterOption.filter.rimSpecification = this.liveForecastRimHelper.setSelectedRimSpecificationList(newRimSpecList, this.filterOption.rimSpecificationList);
        this.onChangeFilter();
    }

    public onChangeVehicleSpecification(newVehicleSpecList: Array<string>) {
        this.filterOption.filter.vehicleSpecification = this.liveForecastRimHelper.setSelectedVehicleSpecificationList(newVehicleSpecList, this.filterOption.vehicleSpecificationList);
        this.onChangeFilter();
    }

    public onChangeVehicleSerialNumber(newVehicleSerialList: Array<string>) {
        this.filterOption.filter.vehicleSerialNumber = this.liveForecastRimHelper.setSelectedVehicleSerialNumberList(newVehicleSerialList, this.filterOption.vehicleSerialNumberList);
        this.onChangeFilter();
    }

    private onChangeFilter() {
        let apply = this.liveForecastRimHelper.setApplyFilter(this.filterOption);
        this.displayRim = this.selectedRim.filter(rim => {
            return apply.rimSizeList.includes(rim.rimSize) && apply.rimSpecificationList.includes(rim.rimSpecification)
                && apply.vehicleSerialNumberList.includes(rim.fittedVehicle.vehicleSerialNumber) && apply.vehicleSpecificationList.includes(rim.fittedVehicle.vehicleSpecification)
        });
    }

    public setSearchByVehicleSerialNumber(selectedVehicleSerialNumber: string) {
        localStorage.setItem('vehicleSerialNumber', selectedVehicleSerialNumber);
    }

    public setSearchByComponentSerialNumber(selectedComponentSerialNumber: string) {
        localStorage.setItem('componentSearchByRim', "Rim");
        localStorage.setItem('componentSerialNumber', selectedComponentSerialNumber);
    }

}