//import angular from 'angular';
import FileManagement from '../../services/fileManagement';
import tmpl from './ocThumbnail.html';
import imgSpinner from '../../../Content/common/spinner.gif';
import imgDoc from '../../../Content/images/attachment_doc.png';
//import imgImg from '../../../Content/images/attachment_image.png';
import imgOther from '../../../Content/images/attachment_other.png';
import imgPdf from '../../../Content/images/attachment_pdf.png';
import imgTxt from '../../../Content/images/attachment_text.png';
import imgXls from '../../../Content/images/attachment_xls.png';

class ocThumbnailCtrl implements ng.IComponentController {
    ngModel: IFile;

    thumbUrl: string = imgSpinner;
    downloadOnClick: boolean;
    readOnly: boolean; // readOnly on this control means they see a placeholder thumbnail, and cannot view the image or download the file
    canViewAsImage = false;

    static $inject = ['$scope', '$timeout', 'fileManagement'];

    constructor(private $scope: ng.IScope, private $timeout: ng.ITimeoutService, private fileManagement: FileManagement) {
        this.$scope.$watch(() => this.ngModel?.data, (current: Blob, previous: Blob) => this.onDataChanged(current, previous));
    }

    $postLink() {
        this.$timeout(() => this.loadThumbnail());
    }

    // data property of the ngModel has changed
    protected async onDataChanged(current: Blob, previous: Blob) {
        if (!current) {
            this.revokeThumbUrl();
            return;
        }

        if (!previous || current != previous)
            this.loadThumbnail();
    }

    async loadThumbnail() {
        this.revokeThumbUrl();
        this.canViewAsImage = false;
        this.thumbUrl = imgOther;

        if (!this.ngModel || this.readOnly)
            return;

        let fileType = this.fileManagement.getExtensionType(this.ngModel.name);
        let isImage = fileType === FileType.image || this.fileManagement.isImage(this.ngModel.type);

        switch (fileType) {
            case FileType.document:
                this.thumbUrl = imgDoc;
                return;
            case FileType.pdf:
                this.thumbUrl = imgPdf;
                return;
            case FileType.spreadsheet:
                this.thumbUrl = imgXls
                return;
            case FileType.text:
                this.thumbUrl = imgTxt;
                return;
        }

        if (isImage && (this.ngModel.thumbUrl || this.ngModel.thumb || this.ngModel.id)) {
            this.canViewAsImage = true;
            this.thumbUrl = imgSpinner;
            if (this.ngModel.thumbUrl || this.ngModel.thumb) {
                this.thumbUrl = this.ngModel.thumbUrl || URL.createObjectURL(this.ngModel.thumb);
            } else {
                try {
                    let thumbnail = await this.fileManagement.getThumbnail(this.ngModel.id, this.ngModel);

                    if (!thumbnail || !thumbnail.thumb)
                        throw 'unable to retrieve thumb';

                    this.ngModel.thumb = thumbnail.thumb;
                    this.thumbUrl = URL.createObjectURL(thumbnail.thumb);
                }
                catch (error) {
                    this.thumbUrl = imgOther;
                }
            }
        }

    }

    revokeThumbUrl() {
        if (this.thumbUrl)
            URL.revokeObjectURL(this.thumbUrl);
    }

    // open the full-size image viewer
    async viewImage() {

        if (!this.ngModel.data && !this.ngModel.dataUrl) {

            //this.loading = true;

            try {
                let file = await this.fileManagement.getFile(this.ngModel.id);

                if (!file || !file.data)
                    return;

                this.ngModel.data = file.data;
            } finally {
                //this.loading = false;
            }
        }

        let imageUrl = this.ngModel.dataUrl || URL.createObjectURL(this.ngModel.data);

        //TODO: hacky garbage sorry! -- Nat
        this.$scope.$on("imageViewerClose", () => {
            URL.revokeObjectURL(imageUrl);
            (<any>this.$scope.$root).imageViewerDescription = null;
            (<any>this.$scope.$root).imageViewerUrl = null;
        });

        (<any>this.$scope.$root).imageViewerDescription = this.ngModel.description;
        (<any>this.$scope.$root).imageViewerUrl = imageUrl;


    }

    async onThumbClick() {
        if (this.readOnly)
            return;

        if (this.downloadOnClick) {
            await this.fileManagement.downloadFile(this.ngModel.id, this.ngModel);
        } else {
            if (this.canViewAsImage)
                this.viewImage();
        }
    }

    $onDestroy() {
        this.revokeThumbUrl();
    }
}

class ocThumbnailComponent implements ng.IComponentOptions {
    public bindings = {
        ngModel: '<',
        downloadOnClick: '@?',
        backgroundColour: '@?',
        readOnly: '=?'
    };
    public template = tmpl;
    public controller = ocThumbnailCtrl;
    public controllerAs = 'vm';
}

angular.module('app.directives').component('ocThumbnail', new ocThumbnailComponent());