import * as _ from 'underscore'

/**
 * @ngdoc directive
 * @name amtFramework.formControls.directive:amtMultiSelect
 * @sortOrder 20
 * @restrict E
 *
 * @param {expression} url Url to get the data from. Only use when options is not set.
 * @param {expression} options Array of options. Only use when Url is not set.
 * @param {expression} ngModel SelectedIds
 * @param {string=} name Name of the control. Used for validation.
 * @param {expression=} selectedItems Selected Items
 * @param {expression=} [isRequired=false] Required
 * @param {expression=} [isDisabled=false] Disabled
 * @param {expression=} [isResetDependency=false] Re-validates all items when extensionParams change.
 * @param {expression=} control Reference to the control to execute methods:
 *                      clear() To clear the selected items
 * @param {expression=} [extensionParams] Object that contains additional values that should be passed in the querystring. {branchId: 1, siteId: 2}
 * @param {boolean=} sortOnKey Wether the options are sorted by key or name
 * @param {string} idField The nme of the id field that will be used at the value of a selected item
 * @param {string} textField The nme of the text field that will be used at the description of a selected item

 * @param {callback} onSelectedChanged(selectedIds) Method that executes when the selection is changed.
 *
 * @param {expression=} [selectAllItems=false] Select all items first load. Defaults to false.
 * @param {expression=} [displayRight=false] Force the drop down to position on the right - for use in windows
 * @param {string} [descriptor=""] Used to define the message display when all items are selected. i.e 'All descriptor'
 *
 * @description
 *
 * Let's the user select multiple items from a list.
 *
 * @example
 * <doc:example module="amtFramework">
 *   <doc:source>
 *     Sort on Name
 *     <amt-multi-select ng-model="selectedIds" options='[{key: 201501, name:"01 2015"}, {key: 201502, name:"02 2015"}, {key: 201601, name:"01 2016"}, {key: 201602, name:"02 2016"}]'></amt-multi-select>
 *
 *     Sort on Key
 *     <amt-multi-select ng-model="selectedIds2" sort-on-key="true" options='[{key: 300, name:"01 2015"}, {key: 1001, name:"02 2015"}, {key: 20000, name:"01 2016"}, {key: 100000, name:"02 2016"}]'></amt-multi-select>
 * </doc:source>
 * </doc:example>
 *
 */

//import angular from 'angular';
import tmpl from './amtMultiSelect.html';
import './amtMultiSelect.scss';


angular.module('amtFramework.formControls').directive('amtMultiSelect', [
    'amtFilterSvc', '$timeout', 'amtXlatSvc', '$q', 'cgBusyDefaults',
    function (filterSvc, $timeout, xlat, $q, cgBusyDefaults) {
                return {
                    restrict: 'E',
                    require: ['ngModel', '^?form'],
                    scope: {
                        url: '=',
                        options: '=',
                        selectedIds: '=ngModel',
                        name: '@',
                        selectedItems: '=?',
                        isRequired: '=?',
                        isDisabled: '=?',
                        isResetDependency: '=?',
                        clearOnDependencyPropertyClear: '@?',
                        useHttpPost: '=?',
                        control: '=',
                        extensionParams: '=?',
                        preserveOrder: '=',
                        sortOnKey: '@',
                        idField: '@',
                        textField: '@',
                        onSelectedChange: '&',
                        selectAllItems: '=?',
                        skipValidateSelectedItems: "=?",
                        descriptor: '@',
                        emptyDescriptor: '@',
                        displayRight: '=?',
                        showInactive: '='
                    },
                    template: tmpl,
                    link: function (scope: any, element, attrs, ctrls) {

                        if (!scope.url && !scope.options) {
                            throw new Error("amtMultiSelect with no data or url passed in");
                        }

                        scope.textFieldProperty = scope.textField || "name";

                        cgBusyDefaults.message = xlat.xlat('framework.loadingMessage');

                        var modelCtrl = ctrls[0];
                        var formCtrl = ctrls[1];
                        var firstTimeLoad = true;
                        var dataProcessing = false;

                        // we use this to fetch from URL only if search parameters have changed
                        var contract;

                        scope.initialSelectAll = !!(scope.selectAllItems && (!scope.selectedItems));

                        scope.intSelectedIds = scope.selectedIds || [];
                        scope.intSelected = [];

                        // we use the flag to show if indeed the options were loaded with all options (true), or just the selected items (false)
                        scope.allAvaialableOptionsLoaded = false;
                        scope.allAvaialableOptions = [];

                        if (scope.sortOnKey) {
                            scope.intSortOnKey = scope.sortOnKey.toLowerCase() === 'true';
                        } else {
                            scope.intSortOnKey = false;
                        }

                        scope.remove = function (item) {
                            item.selected = false;
                            if (scope.selectedItems) {
                                var i = scope.selectedItems.indexOf(item);

                                if (i > -1) {
                                    scope.selectedItems.splice(i, 1);
                                }
                            }
                        };

                        scope.internalControl = scope.control || {};
                        scope.internalControl.clear = function (clearDisabled) {
                            scope.clear(clearDisabled);
                        };

                        scope.internalControl.select = function (option) {
                            scope.itemsToSelect = [option];
                            scope.select();
                        };

                        scope.internalControl.selectAll = function () {
                            scope.selectAll();
                        };

                        scope.internalControl.setEmptyDescriptor = function (descriptor) {
                            scope.emptyDescriptor = descriptor;
                        };

                        scope.internalControl.selectAll = function () {
                            scope.selectAll();
                        };

                        scope.internalControl.search = function () {
                            var defer = $q.defer();

                            scope.search(function () {
                                defer.resolve();
                            }, true);

                            return defer.promise;
                        };

                        scope.promise = null;

                        scope.status = {
                            isOpen: false
                        };

                        scope.maxItems = 0;

                        scope.keyPress = function (e) {
                            if (e.which === 9) { // TAB
                                if (scope && scope.status) {
                                    scope.status.isOpen = false;
                                }
                            }
                        };

                        function validateSelectedItems() {
                            scope.search(
                                function (results) {

                                    scope.allAvaialableOptions = results;
                                    var validSelections = [];
                                    for (var i = scope.selectedItems.length - 1; i > -1; i--) {
                                        for (var r = 0; r < results.length; r++) {
                                            if (results[r].key === scope.selectedItems[i].key) {
                                                validSelections.push(results[r]);
                                            }
                                        }
                                    }
                                    var st = scope.selectedTitles;
                                    scope.clear(false);
                                    scope.itemsToSelect = validSelections;
                                    firstTimeLoad = false; // we are actually now loaded.
                                    scope.select();
                                    // we reset the selected titles back to what it was, because if there is no change to the values,
                                    // the clear will have cleared it, but the watch will not recalulate it.
                                    scope.selectedTitles = st;
                                }
                            );

                        }

                        scope.$watch("extensionParams", function (newValue, oldValue) {
                            if (newValue === oldValue) {
                                return;
                            }

                            if (scope.isResetDependency) {
                                if (scope.clearOnDependencyPropertyClear && (!newValue || !newValue[scope.clearOnDependencyPropertyClear] || newValue[scope.clearOnDependencyPropertyClear].length === 0)) {
                                    scope.clear(false);
                                } else if (scope.selectedIds && scope.selectedIds.length > 0) {
                                    validateSelectedItems();
                                }
                            }
                        }, true);


                        scope.$watch('isRequired', function (newValue, oldValue) {
                            if (newValue === oldValue) {
                                return;
                            }
                            handleFormValidity();
                        });

                        function handleFormValidity() {
                            var valid = scope.isRequired === undefined || scope.isRequired === false ||
                                (scope.isRequired && scope.selectedIds !== undefined &&
                                    scope.selectedIds != null && scope.selectedIds.length > 0);

                            scope.valid = valid;
                            modelCtrl.$setValidity(scope.name, valid);
                        }

                        scope.toggled = function (open) {

                            if (open === undefined) {
                                open = !scope.status.isOpen;
                            }

                            // clear the display filters
                            scope.searchSelectedText = "";
                            scope.searchText = "";

                            if (open) {
                                scope.search();
                            }

                            positionDropdown(open);

                            $timeout();
                        };

                        scope.toggleDropdown = function ($event, value) {

                            $event.preventDefault();
                            $event.stopPropagation();

                            if (!scope.isDisabled) {
                                if (value !== undefined && value !== null) {
                                    scope.status.isOpen = value;
                                } else {
                                    scope.status.isOpen = !scope.status.isOpen;
                                }
                            }
                        };

                        scope.searchText = '';
                        scope.itemsToSelect = [];
                        scope.itemsToDeSelect = [];
                        scope.selectedTitles = '';

                        scope.showHide = function () {
                            scope.status.isOpen = !scope.status.isOpen;
                        };

                        var filterTextTimeout;

                        //main function to retrieve data
                        scope.search = function (callbackFunction, forceRefresh) {
                            //sometimes params from the parent will send the guid value. therefore, hide the guid value.
                            scope.selectedTitles = !scope.isGuid(scope.selectedTitles) ? scope.selectedTitles : "";
                            // you provided a url I'll use it.
                            if (scope.url) {

                                var newContract = {};

                                if (angular.isDefined(scope.extensionParams)) {
                                    newContract = angular.copy(scope.extensionParams);
                                }

                                if (JSON.stringify(newContract) !== JSON.stringify(contract) || (forceRefresh === true)) {

                                    if (filterTextTimeout) {
                                        $timeout.cancel(filterTextTimeout);
                                    }

                                    contract = newContract;

                                    //fetch the data
                                    filterTextTimeout = $timeout(function () {
                                        scope.maxItemsReached = false;
                                        scope.maxItems = 0;

                                        console.log("fetching the data. " + scope.url);


                                        if (scope.useHttpPost) {
                                            scope.promise = filterSvc.post(scope.url, contract).then(function (data) {
                                                processData(callbackFunction, data);
                                            }).catch(function (error) {
                                                // need to move into the digest to get somewhere the error can be handled in Angular
                                                // so we just store the error and use a watch to handle it.
                                                console.log(error);
                                                scope.processError = error;
                                            });
                                        } else {
                                            scope.promise = filterSvc.async(scope.url, scope.searchText, scope.extensionParams).then(function (data) {
                                                processData(callbackFunction, data);
                                            }).catch(function (error) {
                                                // need to move into the digest to get somewhere the error can be handled in Angular
                                                // so we just store the error and use a watch to handle it.
                                                console.log(error);
                                                scope.processError = error;
                                            });
                                        }
                                    }, 300);
                                }
                            } else {
                                scope.noItemFound = scope.allAvaialableOptions.length === 0;

                                if (scope.searchText.length > 0) {
                                    angular.forEach(scope.allAvaialableOptions, function (option) {
                                        if (option[scope.textFieldProperty].toLowerCase().includes(scope.searchText.toLowerCase())) {
                                            addItem(option, scope.filteredOptions);
                                        }
                                    });
                                }

                                sortArray(scope.filteredOptions);
                                sortArray(scope.allAvaialableOptions);

                                if (scope.filteredOptions.length === 0) {
                                    scope.filteredOptions.push({ key: 0, name: xlat.xlat('common.noRecordsFound') });
                                }

                                // let the watches run first
                                $timeout(function () {
                                    firstTimeLoad = false;
                                });
                            }
                        };

                        function processData(callbackFunction, data) {
                            if (callbackFunction) {
                                callbackFunction(data.result);
                            }
                            var activeResults = [];
                            if (data.result) {
                                // select all of the auto selected items which are to be displayed
                                data.result = data.result.map(function (a) {
                                    if (a.isActive !== false) {
                                        activeResults.push(a);
                                    }
                                    
                                    let itemVisible = !!(scope.showInactive || a.isActive !== false);

                                    if (scope.selectedIds?.length > 0) {
                                        a.selected = scope.selectedIds.some(s => s === a.key) && itemVisible;
                                    } else {
                                        a.selected = scope.initialSelectAll && itemVisible;
                                    }

                                    return a;
                                });
                            }
                            else {
                                data.result = [];
                            }

                            var selected = scope.allAvaialableOptions.filter(function (option) { return option.selected; });

                            var activeOptionsCount = 0;
                            angular.forEach(scope.allAvaialableOptions, function (option) {
                                activeOptionsCount += option.isActive !== false ? 1 : 0;
                            });

                            // Add new values and update common values
                            angular.forEach(data.result, function (record) {

                                var matchedOption = _.findWhere(scope.allAvaialableOptions, { key: record.key });

                                if (matchedOption === undefined || matchedOption === null) {

                                    if (!firstTimeLoad) {
                                        if (selected.length !== 0 && ((!scope.showInactive && selected.length === activeOptionsCount) || (scope.showInactive && selected.length === scope.allAvaialableOptions.length))) {
                                            record.selected = true;
                                        } else {
                                            record.selected = false;
                                        }
                                    }

                                    scope.allAvaialableOptions.push(record);
                                } else {
                                    // it already exists, make sure it's up-to-date
                                    matchedOption[scope.textFieldProperty] = record[scope.textFieldProperty];
                                    matchedOption.isActive = record.isActive;
                                }
                            });

                            // Remove old/stale values
                            var temp = scope.allAvaialableOptions.slice(); // we can't iterate over something we're editing.
                            angular.forEach(temp, function (option) {
                                if (_.findWhere(data.result, { key: option.key }) === undefined) {
                                    var index = scope.allAvaialableOptions.indexOf(option);
                                    if (index > -1) {
                                        scope.allAvaialableOptions.splice(index, 1);
                                    }
                                }
                            });

                            sortArray(scope.allAvaialableOptions);
                            scope.maxItems = scope.allAvaialableOptions.length;
                            if (scope.showInactive) {
                                scope.noItemFound = scope.allAvaialableOptions.length === 0;
                            } else {
                                scope.noItemFound = activeResults.length === 0; // display 'no records' message if there are no active items
                            }
                            scope.maxItemsReached = data.maxItemsReached;

                            // initialize with values
                            if (firstTimeLoad) {
                                if (scope.initialSelectAll && scope.emptyDescriptor) {
                                    scope.selectedItems = scope.allAvaialableOptions;
                                } else {
                                    selectDefaultItems();
                                }
                            }

                            firstTimeLoad = false;

                            // let the watches run first
                            dataProcessing = true;
                            $timeout(function () {
                                scope.allAvaialableOptionsLoaded = true;
                                dataProcessing = false;
                            });
                        }

                        scope.searchCache = function (callbackFunction) {
                            if (!scope.allAvaialableOptions) {
                                scope.search(callbackFunction);
                            }
                        };

                        scope.searchSelected = function (callbackFunction) {
                            if (scope.searchSelectedText.length === 0) {
                                scope.selectedItems = scope.intSelected;
                                return;
                            }

                            if (scope.selectedItems.length > 0) {
                                angular.copy(scope.selectedItem, scope.intSelected);
                                scope.selectedItems = [];
                                angular.forEach(scope.intSelected,
                                    function (option) {
                                        if (option[scope.textFieldProperty].toLowerCase().includes(scope.searchSelectedText.toLowerCase())) {
                                            addItem(option, scope.selectedItems);
                                        }
                                    });
                                sortArray(scope.selectedItems);
                                if (scope.selectedItems.length === 0) {
                                    scope.selectedItems.push({ key: 0, name: xlat.xlat('common.noRecordsFound') });
                                }
                            }
                        };

                        scope.clickOption = function ($evt, option) {
                            if ($evt) {
                                if ($evt.stopPropagation) {
                                    $evt.stopPropagation();
                                }

                                if ($evt.preventDefault) {
                                    $evt.preventDefault();
                                }
                            }

                            option.highlighted = !option.highlighted;

                            scope.updateHighlightedItems();
                        };

                        scope.doubleClickOption = function (option) {
                            option.selected = true;
                            option.highlighted = false;
                            scope.updateHighlightedItems();

                            scope.selectedItems = scope.allAvaialableOptions.filter(function (option) { return option.selected; });
                        };

                        scope.doubleClickSelection = function (option) {
                            option.selected = false;
                            option.highlighted = false;

                            scope.selectedItems = scope.allAvaialableOptions.filter(function (option) { return option.selected; });
                        };

                        scope.clickSelected = function ($evt, option) {
                            if ($evt) {
                                if ($evt.stopPropagation) {
                                    $evt.stopPropagation();
                                }

                                if ($evt.preventDefault) {
                                    $evt.preventDefault();
                                }
                            }

                            option.highlighted = !option.highlighted;

                            scope.updateHighlightedItems();
                        };

                        scope.select = function () {
                            angular.forEach(scope.itemsToSelect, function (option) {
                                option.highlighted = false;
                                _.defaults(option, { selected: true });
                                var matchedOption = _.findWhere(scope.allAvaialableOptions, { key: option.key });
                                if (matchedOption !== undefined && matchedOption !== null) {
                                    matchedOption.selected = true;
                                }
                            });

                            if (!firstTimeLoad) {
                                scope.selectedItems = scope.allAvaialableOptions.filter(function (option) { return option.selected; });
                            }

                            scope.updateHighlightedItems();
                        };

                        scope.updateHighlightedItems = function () {
                            if (scope.filteredOptions) {
                                scope.itemsToSelect = [];

                                for (var o = 0; o < scope.filteredOptions.length; o++) {
                                    var opt = scope.filteredOptions[o];

                                    if (!opt.selected && opt.highlighted) {
                                        scope.itemsToSelect.push(opt);
                                    }
                                }
                            }

                            if (scope.filteredSelections) {
                                scope.itemsToDeSelect = [];

                                for (var o2 = 0; o2 < scope.filteredSelections.length; o2++) {
                                    var opt2 = scope.filteredSelections[o2];

                                    if (opt2.selected && opt2.highlighted) {
                                        scope.itemsToDeSelect.push(opt2);
                                    }
                                }
                            }
                        };

                        scope.isGuid = function (value: string): boolean {
                            let guidRegExp = /^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}$/;
                            return guidRegExp.test(value);
                        }

                        scope.selectAll = function () {
                            // copy the filtered items from left panel
                            scope.filteredOptions = scope.filteredOptions.map(function (a) {
                                if (scope.showInactive || a.isActive !== false) {
                                    a.highlighted = false; // a.highlighted || !a.selected;
                                    a.selected = true;
                                }
                                return a;
                            });
                            scope.selectedItems = scope.filteredOptions.filter(function (option) { return option.selected; });
                            scope.updateHighlightedItems();
                        };

                        scope.deselectAll = function () {
                            if (scope.filteredSelections) {
                                scope.filteredSelections = scope.filteredSelections.map(function (a) {
                                    a.highlighted = false; // a.highlighted || a.selected;
                                    a.selected = false;
                                    return a;
                                });
                            }

                            if (scope.filteredOptions) {
                                scope.selectedItems = scope.filteredOptions.filter(function (option) { return option.selected; });
                            }

                            if (!scope.selectedItems || scope.selectedItems.length === 0) {
                                if (scope.emptyDescriptor) {
                                    scope.selectedTitles = scope.emptyDescriptor;
                                } else {
                                    if (scope.isRequired) {
                                        scope.selectedTitles = "";
                                    } else {
                                        scope.selectedTitles = getDescriptor();
                                    }
                                }
                            }

                            scope.updateHighlightedItems();
                        };

                        scope.deSelect = function () {
                            angular.forEach(scope.itemsToDeSelect, function (option) {
                                option.highlighted = false;
                                _.defaults(option, { selected: false });
                                var matchedOption = _.findWhere(scope.allAvaialableOptions, { key: option.key });
                                if (matchedOption !== undefined && matchedOption !== null) {
                                    matchedOption.selected = false;
                                }
                            });

                            if (!firstTimeLoad) {
                                scope.selectedItems = scope.allAvaialableOptions.filter(function (option) { return option.selected; });
                            }

                            scope.updateHighlightedItems();
                        };

                        scope.clear = function (clearDisabled) {
                            if (scope.isDisabled === true && clearDisabled !== true) {
                                return;
                            }
                            if (scope.selectAllItems === true && !firstTimeLoad) {
                                scope.selectAll();
                            } else {
                                scope.deselectAll();
                            }
                        };

                        function selectDefaultItems() {
                            if (scope.selectedItems && scope.selectedItems.length > 0) {
                                if (scope.selectedItems.length !== scope.allAvaialableOptions.filter(function (option) { return option.selected; }).length) {
                                    scope.itemsToSelect = scope.selectedItems;
                                    scope.select();
                                }
                            } else if (scope.selectedIds && scope.selectedIds.length > 0) {
                                if (scope.selectedIds.length !== scope.allAvaialableOptions.filter(function (option) { return option.selected; }).length) {
                                    scope.itemsToSelect = scope.allAvaialableOptions.filter(function (option) {
                                        return scope.selectedIds.indexOf(option.id) > -1;
                                    });
                                    scope.select();
                                }
                            }
                        }

                        scope.$watch('processError', function (newValue, oldValue) {
                            if (newValue) {
                                throw newValue;
                            }
                        });

                        scope.$watch('selectedItems', function (newValue, oldValue) {
                            if ((!newValue || newValue.length === 0) && (!oldValue || oldValue.length === 0)) {
                                return;
                            } else {

                                if (!newValue) {
                                    newValue = [];
                                }

                                if (!oldValue) {
                                    oldValue = [];
                                }

                                var valuesEqual = true;

                                angular.forEach(newValue, function (newVal) {
                                    if (_.indexOf(oldValue, newVal) === -1) {
                                        valuesEqual = false;
                                    }
                                });

                                if (!valuesEqual) {
                                    selectDefaultItems();
                                }

                                if (scope.selectedItems && scope.selectedItems.length > 0 && (!scope.allAvaialableOptions || scope.allAvaialableOptions.length === 0)) {
                                    scope.allAvaialableOptions = angular.copy(scope.selectedItems);
                                    scope.allAvaialableOptions.map(function (a) {
                                        a.selected = true;
                                        return a.key;
                                    });
                                }

                                // if everything is selected, and empty is same as full, nothing is selected. 
                                if (!firstTimeLoad &&
                                    scope.selectedItems &&
                                    scope.allAvaialableOptions &&
                                    scope.selectedItems.length === scope.allAvaialableOptions.length &&
                                    scope.selectedItems.length > 1 &&
                                    !scope.isRequired &&
                                    !scope.emptyDescriptor) {
                                    scope.selectedItems = [];
                                }
                            }
                        });

                        scope.$watch('selectedIds', function (newValue, oldValue) {

                            if (newValue?.length && firstTimeLoad) {
                                scope.search();
                                selectDefaultItems();
                                return;
                            }

                            if (formCtrl) {
                                // if value changes we can set to dirty
                                handleFormValidity();

                                if (!oldValue || dataProcessing) {
                                    return;
                                }

                                var n = newValue || [];

                                if ((n.length === 0 && oldValue.length === 0 && !scope.isRequired) || n.length !== oldValue.length) {
                                    formCtrl.$setDirty();
                                    return;
                                }

                                for (var i = 0; i < n.length; i++) {
                                    if (n[i] !== oldValue[i]) {
                                        formCtrl.$setDirty();
                                        return;
                                    }
                                }
                            }
                        });

                        scope.$watch('options', function () {

                            var options = null;

                            if (!!scope.options) {

                                options = angular.copy(scope.options);

                                // if an option is missing the selected property add it and set it to false
                                _.each(options, function (o) {
                                    if (o.selected === undefined || o.selected === null) {
                                        o.selected = false;
                                    }
                                });
                            }

                            scope.allAvaialableOptions = options ? options : [];
                        });

                        scope.$watch('allAvaialableOptions', function () {

                            if (!scope.allAvaialableOptions) {
                                // crap response from server ?
                                console.error("All available options was not set in multi select");
                                scope.allAvaialableOptions = [];
                            }

                            scope.selectedIds = _.reduce(scope.allAvaialableOptions.filter(function (option) { return option.selected; }), function (memo, option) {
                                memo.push(option.key);
                                return memo;
                            }, []);

                            if (!scope.emptyDescriptor) {
                                // empty means all selected
                                var selectOptions = scope.allAvaialableOptions.filter(function (option) { return option.selected; });
                                var possibleOptions = scope.allAvaialableOptions.filter(function (option) { return scope.showInactive || option.isActive !== false; });

                                if (selectOptions.length > 1 && selectOptions.length === possibleOptions.length) {
                                    // all possible are selected so same as empty
                                    // unless required
                                    if (!scope.isRequired) {
                                        scope.selectedIds = [];
                                    }
                                }
                            }

                            var selected = scope.allAvaialableOptions.filter(
                                function (option) {
                                    return option.selected;
                                }
                            );

                            if (firstTimeLoad) sortArray(selected);

                            var activeOptionsCount = 0;

                            angular.forEach(scope.allAvaialableOptions, function (option) {
                                activeOptionsCount += (scope.showInactive || option.isActive !== false) ? 1 : 0;
                            });

                            if (selected.length > 1 && (!firstTimeLoad || !scope.url) && scope.descriptor &&
                                ((!scope.showInactive && selected.length === activeOptionsCount) || (scope.showInactive && selected.length === scope.allAvaialableOptions.length)))
                            {
                                scope.selectedTitles = getDescriptor();
                            } else if (selected.length === 0) {
                                if (scope.emptyDescriptor) {
                                    scope.selectedTitles = scope.emptyDescriptor;
                                } else {
                                    if (scope.isRequired) {
                                        scope.selectedTitles = "";
                                    } else {
                                        scope.selectedTitles = getDescriptor();
                                    }
                                }
                            } else {
                                if (scope.allAvaialableOptionsLoaded && (!firstTimeLoad || activeOptionsCount > 1) && selected.length === activeOptionsCount && scope.descriptor) {
                                    scope.selectedTitles = getDescriptor();
                                } else {
                                    scope.selectedTitles = _.reduce(selected, function (memo, option) {
                                        if (memo.length > 0) {
                                            memo = memo + ", ";
                                        }
                                        if (option[scope.textFieldProperty]) {
                                            return memo + option[scope.textFieldProperty];
                                        } else if (option.name) {
                                            return memo + option.name;
                                        } else {
                                            return memo + "???";
                                        }
                                    }, "");
                                }
                            }

                            if (attrs.onSelectedChange !== undefined) {
                                scope.onSelectedChange({ options: scope.selectedIds });
                            }
                        }, true); // True is used so that we watch the properties of each array item.

                        //Helper functions
                        var moveItem = function (sourceArray, targetArray, item) {
                            addItem(item, targetArray);

                            var itemIndex = _.indexOf(sourceArray, item);
                            sourceArray.splice(itemIndex, 1);
                            sortArray(sourceArray);
                            sortArray(targetArray);
                        };

                        var addItem = function (item, targetArray) {
                            var idField = scope.idField || "key";
                            var textField = scope.textFieldProperty;

                            var targetItem = { key: item[idField], name: item[textField] };

                            targetArray.push(targetItem);
                        };

                        function getDescriptor() {

                            var available = scope.allAvaialableOptions.filter(function (option) {
                                return scope.showInactive || option.isActive !== false;
                            });

                            if (scope.descriptor && available.length > 1) {
                                return scope.descriptor;
                            } else {
                                if (available.length === 0) {
                                    if (scope.descriptor && firstTimeLoad) {
                                        return scope.descriptor;
                                    } else {
                                        return "";
                                    }
                                } else {
                                    if (available[0][scope.textFieldProperty]) {
                                        return available[0][scope.textFieldProperty];
                                    } else if (available[0].name) {
                                        return available[0].name;
                                    } else {
                                        return "???";
                                    }
                                }
                            }
                        }

                        function sortArray(sourceArray) {
                            if (scope.preserveOrder !== true) {
                                if (sourceArray.length > 1) {
                                    sourceArray.sort(function (first, second) {
                                        if (scope.intSortOnKey === true) {
                                            return parseInt(first.key) > parseInt(second.key) ? 1 : -1;
                                        }

                                        if (!first[scope.textFieldProperty]) {
                                            return 1;
                                        }

                                        if (!second[scope.textFieldProperty]) {
                                            return -1;
                                        }

                                        return first[scope.textFieldProperty].trim().toLowerCase() > second[scope.textFieldProperty].trim().toLowerCase() ? 1 : -1;
                                    });
                                }
                            }
                        }

                        var $container = $(element).find('.multi-select');
                        var $dropdown = $(element).find('.dropdown-menu');

                        $('.dropdown-menu.multi-select-box').on('click', function () {
                            return false;
                        });

                        function positionDropdown(isOpen) {
                            scope.status.positioned = isOpen;

                            // Remove any existing "dropup" classes. These may not be needed if the
                            // browser was resized
                            $dropdown.removeClass('dropdown-menu-up');

                            // Determine the original height of the element. We will need this in
                            // case we start to resize the elements.
                            if ($dropdown.data('prev-style') === undefined) {
                                // Save the height of the child dropdowns.
                                $dropdown.data('prev-style', $dropdown.find('select').first().attr('style'));
                            } else {
                                $dropdown.find('select').each(function () {
                                    $(this).attr('style', $dropdown.data('prev-style'));
                                });
                            }

                            // Reset the styles if the dropdown is already open
                            if (!isOpen) {
                                if ($dropdown) {
                                    $dropdown.hide();
                                }
                                return;
                            }

                            // keep it simple.
                            var windowH = $(window).height();
                            var windowW = $(window).width();
                            var windowOffsetTop = 0;

                            // Container
                            var containerOffset = $container.offset();
                            var containerOffsetLeft = containerOffset.left;
                            var containerOffsetTop = containerOffset.top;

                            var containerH = $dropdown.parent().outerHeight(false); // Container sometimes changes, this is more consistent
                            var containerW = $dropdown.parent().outerWidth(false); // Container sometimes changes, this is more consisten

                            // Dropdown
                            var dropdownH = $dropdown.outerHeight(false);
                            var dropdownW = $dropdown.outerWidth(false);


                            // Fits
                            var fitLeft = (scope.displayRight === true) ? false : containerOffsetLeft + dropdownW <= windowW;
                            var fitAbove = containerOffsetTop - windowOffsetTop > dropdownH;
                            var fitBelow = (windowH + windowOffsetTop) - containerOffsetTop - containerH > dropdownH;

                            // The CSS to apply.
                            var css: any = {
                                left: fitLeft ? 0 : containerW - dropdownW,
                                position: "absolute"
                            };

                            if (fitBelow || !fitAbove) {
                                css.top = containerH;
                            } else {
                                // Position it above.
                                css.top = -dropdownH - 3; // TODO: Wtf is this magic number?
                                $dropdown.addClass("dropdown-menu-up");
                            }

                            // Apply the styles.
                            $dropdown.css(css);
                            $dropdown.show();
                        }

                        scope.checkFilter = function (value, index, array) {
                            if (!value[scope.textFieldProperty]) {
                                value[scope.textFieldProperty] = value.key;
                            }
                            if (value.selected) {
                                if (scope.searchSelectedText === undefined || scope.searchSelectedText === '') {
                                    return true;
                                }
                                if (value[scope.textFieldProperty].toLowerCase().indexOf(scope.searchSelectedText.toLowerCase()) !== -1) {
                                    return true;
                                }
                            } else {
                                if (!scope.showInactive && value.isActive === false) {
                                    return false;
                                }
                                if (scope.searchText === undefined || scope.searchText === '') {
                                    return true;
                                }
                                if (value[scope.textFieldProperty].toLowerCase().indexOf(scope.searchText.toLowerCase()) !== -1) {
                                    return true;
                                }
                            }
                            return false;
                        };

                        function init() {
                            scope.search();
                        }

                        function initSelected() {
                            if (!scope.selectedItems) {
                                scope.selectedItems = [];
                            }
                            if (Object.prototype.toString.call(scope.selectedItems) !== '[object Array]') {
                                scope.selectedItems = [];
                            }

                            if (scope.url) {
                                if (!scope.initialSelectAll) {

                                    if (scope.selectedItems && scope.selectedItems.length > 0 && !scope.skipValidateSelectedItems) {
                                        $timeout(function () {
                                            validateSelectedItems();
                                        });
                                    } else {
                                        scope.allAvaialableOptions = [];
                                    }

                                    scope.allAvaialableOptions.map(function (a) {
                                        a.selected = true;
                                        return a.key;
                                    });
                                } else {
                                    scope.allAvaialableOptions = [];
                                }
                            }
                            else {
                                scope.allAvaialableOptions = scope.options;

                                // options bound from parent controller
                                scope.allAvaialableOptionsLoaded = true;

                                // set the selected items
                                if (scope.initialSelectAll) {
                                    scope.allAvaialableOptions.map(function (a) {
                                        a.selected = true;
                                        return a.key;
                                    });
                                }
                                else {
                                    scope.allAvaialableOptions.map(function (a) {
                                        a.selected = false;
                                        return a.key;
                                    });
                                }
                            }
                        }

                        initSelected();

                        selectDefaultItems();
                        handleFormValidity();

                        // ok so don't download data until needed
                        if ((scope.isRequired || scope.emptyDescriptor) && scope.initialSelectAll && !scope.selectedItems) {
                            // required and we want all items automatically
                            init();
                        }
                        else {
                            $timeout(function () {
                                dataProcessing = true;
                                if (!scope.initialSelectAll && scope.selectedItems && scope.selectedItems.length > 0) {
                                    scope.selectedIds = scope.selectedItems.map(function (a) {
                                        a.selected = true;
                                        return a.key;
                                    });
                                }
                                $timeout(function () {
                                    dataProcessing = false;
                                });
                            });
                        }
                    }
                };
    }
]);
