/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var rxjs_1 = require("rxjs");
var core_1 = require("@angular/core");
var utils_1 = require("../common/utils");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
/**
 * @hidden
 */
var TextFieldsBase = /** @class */ (function () {
    function TextFieldsBase(localizationService, ngZone, changeDetector, renderer, injector, hostElement) {
        var _this = this;
        this.localizationService = localizationService;
        this.ngZone = ngZone;
        this.changeDetector = changeDetector;
        this.renderer = renderer;
        this.injector = injector;
        this.hostElement = hostElement;
        /**
         * Sets the `title` attribute of the internal textarea input element of the component.
         */
        this.title = '';
        /**
         * Sets the disabled state of the TextArea component.
         *
         * @default false
         */
        this.disabled = false;
        /**
         * Sets the read-only state of the TextArea component.
         *
         * @default false
         */
        this.readonly = false;
        /**
         * Provides a value for the TextArea component.
         */
        this.value = null;
        /**
         * Determines whether the whole value will be selected when the TextArea is clicked. Defaults to `false`.
         *
         * @default false
         */
        this.selectOnFocus = false;
        /**
         * Fires each time the user focuses the internal textarea element of the component.
         * This event is useful when you need to distinguish between focusing the textarea element and focusing one of its adornments.
         */
        this.inputFocus = new core_1.EventEmitter();
        /**
         * Fires each time the internal textarea element gets blurred.
         * This event is useful when adornments are used, in order to distinguish between blurring the textarea element and blurring the whole TextArea component.
         */
        this.inputBlur = new core_1.EventEmitter();
        this.subscriptions = new rxjs_1.Subscription();
        this._isFocused = false;
        this.focusChangedProgrammatically = false;
        /**
         * @hidden
         */
        this.handleInputBlur = function () {
            _this.changeDetector.markForCheck();
            if (kendo_angular_common_1.hasObservers(_this.inputBlur) || utils_1.requiresZoneOnBlur(_this.control)) {
                _this.ngZone.run(function () {
                    _this.ngTouched();
                    _this.inputBlur.emit();
                });
            }
        };
        this.ngChange = function (_) { };
        this.ngTouched = function () { };
    }
    Object.defineProperty(TextFieldsBase.prototype, "disabledClass", {
        get: function () {
            return this.disabled;
        },
        enumerable: true,
        configurable: true
    });
    TextFieldsBase.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    /**
     * @hidden
     * Called when the status of the component changes to or from `disabled`.
     * Depending on the value, it enables or disables the appropriate DOM element.
     *
     * @param isDisabled
     */
    TextFieldsBase.prototype.setDisabledState = function (isDisabled) {
        this.changeDetector.markForCheck();
        this.disabled = isDisabled;
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], TextFieldsBase.prototype, "title", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], TextFieldsBase.prototype, "disabled", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], TextFieldsBase.prototype, "readonly", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], TextFieldsBase.prototype, "value", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], TextFieldsBase.prototype, "selectOnFocus", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], TextFieldsBase.prototype, "placeholder", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], TextFieldsBase.prototype, "inputFocus", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], TextFieldsBase.prototype, "inputBlur", void 0);
    tslib_1.__decorate([
        core_1.ViewChild('input', { static: true }),
        tslib_1.__metadata("design:type", core_1.ElementRef)
    ], TextFieldsBase.prototype, "input", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-disabled'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], TextFieldsBase.prototype, "disabledClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.dir'),
        tslib_1.__metadata("design:type", String)
    ], TextFieldsBase.prototype, "direction", void 0);
    return TextFieldsBase;
}());
exports.TextFieldsBase = TextFieldsBase;
