/**
 * @ngdoc directive
 * @name amtFramework.formControls.directive:amtCheckbox
 * @restrict E
 * 
 * @param {string} name Name of the control. Used for validation
 * @param {expression} ngModel Boolean to indicate if the checkbox is ticked
 * @param {expression=} [isRequired=false] Required
 * @param {expression=} [isDisabled=false] Disabled
 * @param {object=} [trueValue=true] Value to return when ticked
 * @param {object=} [falseValue=false] Value to return when unticked
 * @param {callback=} onChange(item) Event fires when checkbox state changes, item is current value of checkbox
 *
 * @description
 *
 * @example
 * <doc:example module="amtFramework">
 *   <doc:source>
 *    <amt-display label="AMT check box">
        <amt-checkbox name="amtcheckbox"
                      ng-model="vm.model"
                      is-required="true"
                      on-change="vm.changed()">
        </amt-checkbox>
        Checkbox: {{vm.model}}
   </amt-display>
 *   </doc:source>
 * </doc:example>
 * 
 */


//import angular from 'angular';
import tmpl from "./amtCheckbox.html";

angular.module("amtFramework.formControls").directive("amtCheckbox", amtCheckbox);

function amtCheckbox() {
        return {
            require: ["ngModel", "^?form"],
            restrict: "E",
            scope: {
                name: "@",
                selected: "=ngModel",
                isRequired: "=?",
                isDisabled: "=?",
                onChange: "&",
                trueValue: "@?",
                falseValue: "@?",
                leftAligned: "=?",
                labelText: "@"
            },

            controller: ['$scope', function ($scope) {

                if ($scope.trueValue === undefined) {
                    $scope.trueValue = "true";
                }
                if ($scope.falseValue === undefined) {
                    $scope.falseValue = "false";
                }
                if ($scope.leftAligned) {
                    $scope.leftAlignText = $scope.leftAligned.toString() === "true";
                }
            }],
            template: tmpl,
            link: function (scope, element, attrs, controllers) {
                
                scope.onCheckedChange = function (selectedItem) {
                    if (attrs.onChange !== undefined) {
                        scope.onChange({ item: selectedItem });
                    }
                };
            }
        };
}
