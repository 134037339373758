// hook the logging
var consoleText = [];
var maxLen = 500;
var consoleWindow;
if (!console.display) {
    var logger = {
        console: console,
        log: console.log,
        warn: console.warn,
        error: console.error,
        record: function (msg) {
            if (typeof msg === 'string') {
                var dt = new Date();
                var sec = "" + dt.getSeconds();
                if (sec.length < 2) {
                    sec = "0" + sec;
                }
                msg = dt.getMinutes() + ":" + sec + "  " + msg + "<br />\n";
                // store the message in memory
                consoleText.push(msg);
                if (consoleText.length > maxLen) {
                    consoleText.splice(0 - maxLen);
                }
                try {
                    // if the window is open put the message directly into the window.
                    if (consoleWindow && consoleWindow.document) {
                        var entry = consoleWindow.document.getElementById("_console");
                        $(entry).append(msg);
                    }
                } catch (ex) {
                    // window is probably been closed.
                }
                // this is for logging within the current window
                //$("#_console").append(msg + "<br>\n");
            }
        }
    };

    console.showConsoleFlag = !!localStorage.getItem("showConsole");
    //if (!console.showConsoleFlag) {
    //    logger.log.call(logger.console, "Console logging disabled;    use console.showConsole() / console.hideConsole() to enable or disable.");
    //} else {
    //    logger.log.call(logger.console, "Console logging enabled;   use console.showConsole() / console.hideConsole() to enable or disable.");
    //}

    console.showConsole = function () {
        localStorage.setItem("showConsole", 'true');
        console.showConsoleFlag = true;
        return "console logging on.";
    };
    console.hideConsole = function () {
        localStorage.removeItem("showConsole");
        console.showConsoleFlag = false;
        return "console logging off.";
    };

    console.log = function (msg, params1) {
        if (console.showConsoleFlag) {
            var dt = new Date();
            msg = "[" + pad(dt.getHours()) + ":" + pad(dt.getMinutes()) + ":" + pad(dt.getSeconds()) + "." + pad(dt.getMilliseconds()) + "] " + msg;
        }

        try {
            logger.record(msg);
            if (console.showConsoleFlag) {
                if (params1) {
                    logger.log.call(logger.console, msg, params1);
                } else {
                    logger.log.call(logger.console, msg);
                }
            }
        } catch (ex) {
            // do nothing?
        }
    };

    var pad = function (n) {
        return n > 9 ? "" + n : "0" + n;
    };

    console.warn = function (msg) {
        try {
            logger.record("! " + msg);
            logger.warn.call(logger.console, msg);
        } catch (ex) { }
    };

    console.error = function (msg) {
        try {
            logger.record("*** " + msg);
            logger.error.call(logger.console, msg);
        } catch (ex) { }
    };

    console.display = function () {
        // open a window.
        consoleWindow = window.open('', '_console');
        if (consoleWindow) {
            consoleWindow.document.write("<html><head><title>Otracom Debug Console</title></head><body style='font-family:\"Courier New\", Courier, monospace'>");
            consoleWindow.document.write("<div style='font-size:25px;heigth:100%;width:100%;padding:10px;white-space: nowrap; overflow: scroll;' id='_console'></div> <div style='height:100px'></div></body ></html > ");
            var entry = consoleWindow.document.getElementById("_console")
            for (var i = 0; i < consoleText.length; i++) {
                $(entry).append(consoleText[i] + "\n");
            }
        }
    };

    window.onerror = function (errorMsg: string, url, lineNumber) {

        var chromeCacheClearLink_en = 'https://support.google.com/accounts/answer/32050?hl=en-gb';
        var chromeCacheClearLink_es = 'https://support.google.com/accounts/answer/32050?hl=es-cl';

        var supportEmail = 'support@otracom.com';

        var gracefulMsg =

            // english
            'Something went wrong. You might be able to fix the issue with opening Otracom by clearing your browser\'s cache.\n\n'
            + 'For help clearing your cache, please refer to the relevant section for your device at: <a href="' + chromeCacheClearLink_en + '" target="_blank">' + chromeCacheClearLink_en + '</a>\n\n'
            + 'If this issue persists, please reach out to Otracom Support (<a href="mailto:' + supportEmail + '">' + supportEmail + '</a>) '
            + 'and provide a screenshot of this screen including the full error text below.\n\n'

            // spanish
            + '-----------------------------------------------------------------------------------------------------------------------\n\n'
            + 'Algo salió mal. Es posible que pueda solucionar el problema al abrir Otracom borrando la memoria caché de su navegador.\n\n'
            + 'Para obtener ayuda para borrar su caché, consulte la sección correspondiente a su dispositivo en: <a href="' + chromeCacheClearLink_es + '" target="_blank">' + chromeCacheClearLink_es + '</a>\n\n'
            + 'Si este problema persiste, comuníquese con el soporte de Otracom (<a href="mailto:' + supportEmail + '">' + supportEmail + '</a>) '
            + 'y proporcione una captura de pantalla de esta pantalla que incluya el texto de error completo a continuación.\n\n'

            // error
            + '-----------------------------------------------------------------------------------------------------------------------\n\n'
            + errorMsg.split('\n\n')[0];

        $("#loading_error").append('\n' + gracefulMsg + '\n\n');

        var msg = 'Unhandled Error: ' + errorMsg + ' Script: ' + url + ' Line: ' + lineNumber;
        console.error(msg);

        try {
            var error = {
                dateTime: new Date(),
                error: "Unhandled error",
                exception: {
                    message: msg
                },
                message: msg
            };

            var errors = [error];
            var data = JSON.stringify({
                clientErrors: errors,
                applicationVersion: window.appVersion,
                clientDetail: navigator.userAgent
            });

            // try and send online - don't care if it fails
            $.ajax({
                type: "POST",
                url: baseApiUrl + 'error/recordErrors',
                data: data,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function () { console.log("reported.") }
            });

        }
        catch (ex) {
            console.log(ex.message);
        }
    };

    if (window.location.hash.lastIndexOf('console=snowden') != -1) {
        localStorage.setItem("showConsole", "true");
        console.showConsoleFlag = true;
        console.display();
    }
}