/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Represents a template that defines the content of the whole dial item.
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoDialItemTemplate` directive inside the `<kendo-floatingactionbutton>` tag
 * ([see example]({% slug templates_floatingactionbutton %}#toc-dial-item-template)).
 */
var DialItemTemplateDirective = /** @class */ (function () {
    function DialItemTemplateDirective(templateRef) {
        this.templateRef = templateRef;
    }
    DialItemTemplateDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoDialItemTemplate]'
        }),
        tslib_1.__param(0, core_1.Optional()),
        tslib_1.__metadata("design:paramtypes", [core_1.TemplateRef])
    ], DialItemTemplateDirective);
    return DialItemTemplateDirective;
}());
exports.DialItemTemplateDirective = DialItemTemplateDirective;
