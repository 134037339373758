import * as _ from 'underscore'

/**
* @ngdoc directive
* @name amtFramework.formControls.directive:amtMultiFieldPicker
* @restrict E
* 
* @param {string} url Url to get the data from
* @param {expression} ngModel SelectedItem
* @param {array} primaryKey Array of fields which make up the primary key eg ['Id', 'Name']
* @param {expression=} selectedId Selected id
* @param {expression=} [isRequired=false] Required
* @param {expression=} [isDisabled=false] Disabled
* @param {expression=} control Reference to the control to execute methods:
*                  clear() Clears the selected item
* 
* @description
*
* @example
*/

//import angular from 'angular';
import tmpl from './amtMultiFieldPicker.html';
import './amtMultiFieldPicker.scss';


amtMultiFieldPickerCtrl.$inject = ['amtFilterSvc', '$timeout', '$http', 'filterFilter', 'amtXlatSvc', '$filter', 'cgBusyDefaults'];
function amtMultiFieldPickerCtrl(filterSvc, $timeout, $http, filterFilter, amtXlatSvc, $filter, cgBusyDefaults) {
        var vm = this;

        vm.fields = [];
        vm.filterConstants = [];
        vm.isUsingStaticData = vm.url ? false : true;
        vm.searchParameters = [];

        var forceRefresh = false;

        cgBusyDefaults.message = amtXlatSvc.xlat('framework.loadingMessage');

        vm.noResultsText = vm.noResultsText || "common.noRecordsFound";

        vm.promise = null;

        vm.status = { isopen: false };

        vm.filteredItems = [];
        vm.maxItemsReached = false;
        vm.selectedTitle = '';

        vm.filterTextTimeout;

        vm.hasNoData = false;

        vm.addField = function (field) {
            vm.fields.push(field);
        };

        vm.addFilterConstant = function (filterConstant) {
            vm.filterConstants.push(filterConstant);
        };

        vm.updateFilterConstant = function (filterConstant) {
            var filterItem = _.find(vm.filterConstants, function (item) {
                return item.searchParameter === filterConstant.searchParameter;
            });
            if (filterItem) {
                filterItem.value = filterConstant.value;
            }
        };

        vm.$onInit = function () {
            if (vm.primaryKey === undefined || vm.primaryKey.length === 0) {
                throw new Error('primaryKey is mandatory');
            }

            _.each(vm.filterConstants, function (constant) {
                vm.searchParameters[constant.searchParameter] = constant.value;
            });

            if (vm.toggleOnLoad === "true") {
                vm.showHide();
                vm.toggled();
            }
        };

        vm.toggled = function () {
            if (!vm.filteredItems) {
                vm.filteredItems = [];
            }
            if (vm.status.isopen) {
                if (vm.filteredItems.length === 0 || forceRefresh === true) {
                    vm.search();
                    forceRefresh = false;
                }
            }
        };

        vm.toggleDropdown = function ($event) {
            $event.preventDefault();
            $event.stopPropagation();
            vm.status.isopen = !vm.status.isopen;
        };

        vm.showHide = function () {
            vm.status.isopen = !vm.status.isopen;
        };

        vm.selectRow = function (item) {
            var pk = vm.primaryKey;

            if (!item || vm.canSelectItem && !vm.canSelectItem({ item: item })) {
                return;
            }

            var selected = _.find(vm.filteredItems, function (i) { return i[pk] === item[pk] });

            // if the passed in item is not in list use it anyway.
            if (!selected) {
                selected = item;
            }
            selected.selected = true;
            vm.setSelectedItem(selected);

            vm.status.isopen = false;
        };


        vm.selectNext = function () {
            var i = _.findIndex(vm.filteredItems, function (i) { return i[vm.primaryKey] === vm.selectedId });

            if (i < 0 || i + 1 >= vm.filteredItems.length)
                return false;

            var selected = vm.filteredItems[i + 1];

            selected.selected = true;
            vm.setSelectedItem(selected);

            vm.status.isopen = false;

            return true;
        };

        vm.setSelectedTitle = function (item) {
            if (item == null) {
                vm.selectedTitle = '';
            } else {
                var title = [];
                _.each(vm.fields, function (field) {
                    if (field.displayInSelected) {
                        title.push(item[field.field]);
                    }
                });
                vm.selectedTitle = title.join(' - ');
            }
        };

        vm.search = function () {
            if (vm.filterTextTimeout) {
                $timeout.cancel(vm.filterTextTimeout);
            }

            // the filter has changed - allow some time before applying the filter in case they are still typing.
            vm.filterTextTimeout = $timeout(function () {
                vm.searchParameters = {};

                vm.filteredItems = [];

                _.each(vm.filterConstants, function (constant) {
                    if (constant.value || constant.value === 0) {
                        vm.searchParameters[constant.searchParameter] = constant.value;
                    }
                });

                _.each(vm.fields, function (field) {
                    if (field.searchParameter) {
                        vm.searchParameters[field.searchField || field.field] = field.searchParameter;
                    }
                });

                vm.hasNoData = false;

                if (vm.isUsingStaticData) {

                    if (vm.datasource) {
                        vm.filteredItems = vm.datasource.result || vm.datasource;
                    }

                    if (!vm.filteredItems) {
                        vm.filteredItems = [];
                    }

                    // Manually filter here so we don't spam it during digest
                    vm.filteredItems = $filter("multiProperty")(vm.filteredItems, vm.searchParameters);

                    if (vm.filteredItems.length === 0) {
                        var noRecordFoundEntry = {};

                        // Try to dynamically build a "No Records Found" row entry.
                        // Lots of customization here making it hard to show a label like the grid does, this is easier.
                        for (var field in vm.fields) {
                            if (vm.fields.hasOwnProperty(field)) {
                                if (vm.primaryKey === vm.fields[field].field) {
                                    noRecordFoundEntry[vm.primaryKey] = 0;
                                } else if (vm.fields[field].displayInSelected) {
                                    noRecordFoundEntry[vm.fields[field].field] = amtXlatSvc.xlat(vm.noResultsText);
                                }
                            }
                        }

                        vm.filteredItems.push(noRecordFoundEntry);
                    }

                    vm.itemCount = vm.filteredItems.length;
                    vm.maxItemsReached = vm.maxItemsReached;

                    vm.hasNoData = vm.itemCount === 0;
                } else {
                    vm.promise = $http.get(vm.url, { params: vm.searchParameters }).then(function (response) {
                        var responseData = response.data.data === undefined ? response.data : response.data.data;
                        vm.filteredItems = responseData.result;
                        vm.itemCount = responseData.result.length;
                        vm.maxItemsReached = responseData.maxItemsReached;

                        vm.hasNoData = vm.itemCount === 0;
                    });
                }
            }, 300);

        };

        vm.clear = function () {
            if (vm.isDisabled === true) {
                return;
            }
            vm.setSelectedItem(null);
        };

        vm.setSelectedItem = function (item) {
            vm.selectedId = item ? item[vm.primaryKey] : null;

            vm.modelCtrl.$setValidity(vm.name, vm.isRequired ? !!item : true);

            if (vm.formCtrl && item) {
                vm.formCtrl.$setDirty();
            }

            vm.setSelectedTitle(item);
        };

        vm.setFocus = function(field) {
            angular.element('#' + field).focus();
        }

        vm.refresh = function () {
            forceRefresh = true;
            vm.search();
        }

        vm.$onChanges = function (changesObj) {
            if (changesObj.selectedItem) {
                vm.selectRow(changesObj.selectedItem.currentValue);
            }

            if (changesObj.control && changesObj.control.currentValue) {
                var api = changesObj.control.currentValue;
                api.clear = vm.clear.bind(vm);
                api.selectNext = vm.selectNext.bind(vm);
                api.setFocus = vm.setFocus.bind(vm);
                api.refresh = vm.refresh.bind(vm);
            }
        };
};


angular.module('amtFramework.formControls').component('amtMultiFieldPicker', {
        controller: amtMultiFieldPickerCtrl,
        controllerAs: '$ctrl',
        template: tmpl,
        bindings: {
            url: '<?',
            datasource: '<?',
            status: '=?',
            selectedItem: '<ngModel',
            primaryKey: '@',
            selectedId: '=?',
            isRequired: '<',
            isDisabled: '<',
            toggleOnLoad: '@',
            canSelectItem: '&?',
            holdOpen: '<?',
            control: '<',
            noResultsText: '@'
        },
        transclude: true,
        require: { modelCtrl: 'ngModel', formCtrl: '^?form' },
});

   /**
    * @ngdoc directive
    * @name amtFramework.formControls.directive:amtField
    * @restrict E
    * 
    * @param {string} field Field name
    *
    * @description
    *
    * @example
    * See amtMultiFieldPicker
    */
var amtFieldCtrl = function () {
        var vm = this;
        vm.fieldObj = {};

        vm.$onInit = function () {
            vm.fieldObj = {
                field: vm.field,
                searchParameter: vm.searchParameter,
                searchField: vm.searchField,
                display: vm.display,
                title: vm.title,
                displayInSelected: vm.displayInSelected,
                width: vm.width,
                type: vm.type,
                searchType: vm.searchType,
                cssClass: vm.cssClass,
                trueValue: vm.trueValue,
                falseValue: vm.falseValue
            };

            vm.amtMultiFieldPicker.addField(vm.fieldObj);
        }

        vm.$onChanges = function (changesObj) {
            if (changesObj.searchParameter) {
                vm.fieldObj.searchParameter = changesObj.searchParameter.currentValue;
            }
        };
}

angular.module('amtFramework.formControls').component('amtField', {
        controller: amtFieldCtrl,
        bindings: {
            field: '@',
            searchParameter: '<',
            searchField: '@',
            display: '@',
            displayInSelected: '<',
            searchType: '@',
            title: '@',
            width: '@?',
            type: '@',
            cssClass: "@?",
            trueValue: "@?",
            falseValue: "@?"
        },
        require: { amtMultiFieldPicker: '^amtMultiFieldPicker' }
});

   /**
    * @ngdoc directive
    * @name amtFramework.formControls.directive:amtFilterConstant
    * @restrict E
    * 
    * @param {string} searchParameter Search Parameter
    * @param {expression} value Value of the Search Parameter
    * 
    * @description
    *
    * @example
    */
var amtFilterConstantCtrl = function () {
        var vm = this;

        vm.$onInit = function () {
            vm.amtMultiFieldPicker.addFilterConstant({ searchParameter: vm.searchParameter, value: vm.value });
        }

        vm.$onChanges = function (changesObj) {
            if (changesObj.value && !changesObj.value.isFirstChange()) {
                vm.amtMultiFieldPicker.updateFilterConstant({ searchParameter: vm.searchParameter, value: changesObj.value.currentValue });
            }
        };
}

angular.module('amtFramework.formControls').component('amtFilterConstant', {
        controller: amtFilterConstantCtrl,
        bindings: {
            searchParameter: '@',
            value: '<'
        },
        require: { amtMultiFieldPicker: '^amtMultiFieldPicker' }
});
