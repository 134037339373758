/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var kendo_licensing_1 = require("@progress/kendo-licensing");
var package_metadata_1 = require("../package-metadata");
var splitter_pane_component_1 = require("./splitter-pane.component");
var splitter_bar_component_1 = require("./splitter-bar.component");
var splitter_service_1 = require("./splitter.service");
var util_1 = require("../common/util");
/**
 * Represents the [Kendo UI Splitter component for Angular]({% slug overview_splitter %}).
 *
 * ```ts-preview
 *
 *  @Component({
 *    selector: 'my-app',
 *    template: `
 *        <kendo-splitter style="height: 280px;">
 *
 *          <kendo-splitter-pane [collapsible]="true" size="30%">
 *            <h3>Inner splitter / left pane</h3>
 *            <p>Resizable and collapsible.</p>
 *          </kendo-splitter-pane>
 *
 *          <kendo-splitter-pane>
 *            <h3>Inner splitter / center pane</h3>
 *            <p>Resizable only.</p>
 *          </kendo-splitter-pane>
 *
 *          <kendo-splitter-pane [collapsible]="true" size="30%">
 *            <h3>Inner splitter / right pane</h3>
 *            <p>Resizable and collapsible.</p>
 *          </kendo-splitter-pane>
 *
 *        </kendo-splitter>
 *      `,
 *    styles: [ `
 *        h3 { font-size: 1.2em; }
 *        h3, p { margin: 10px; padding: 0; }
 *    ` ]
 *  })
 *  class AppComponent {}
 * ```
 */
var SplitterComponent = /** @class */ (function () {
    function SplitterComponent(element, splitterService, localization, renderer, enclosingPane) {
        this.element = element;
        this.splitterService = splitterService;
        this.localization = localization;
        this.renderer = renderer;
        this.enclosingPane = enclosingPane;
        /**
         * Specifies the orientation of the panes within the Splitter.
         * Panes in a horizontal Splitter are placed horizontally.
         * Panes in a vertical Splitter are placed vertically.
         */
        this.orientation = 'horizontal';
        this.ariaRole = 'splitter';
        kendo_licensing_1.validatePackage(package_metadata_1.packageMetadata);
        if (enclosingPane) {
            enclosingPane.containsSplitter = true;
        }
        // the handler only runs in NgZone if there are bound handlers
        // this line merges both streams
        this.layoutChange = this.splitterService.layoutChange;
        this.configure = this.configure.bind(this);
    }
    Object.defineProperty(SplitterComponent.prototype, "hostClasses", {
        get: function () {
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SplitterComponent.prototype, "horizontalHostClasses", {
        get: function () {
            return this.orientation === 'horizontal';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SplitterComponent.prototype, "verticalHostClasses", {
        get: function () {
            return this.orientation === 'vertical';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SplitterComponent.prototype, "dir", {
        get: function () {
            return this.direction;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SplitterComponent.prototype, "splitbars", {
        set: function (splitbars) {
            var _this = this;
            if (!util_1.isPresent(splitbars) || !util_1.isPresent(this.panes)) {
                return;
            }
            var components = this.panes.toArray().concat(splitbars.toArray()).sort(function (a, b) { return a.order - b.order; });
            var elements = components.map(function (component) { return component.element.nativeElement; });
            elements.forEach(function (element) { return _this.renderer.appendChild(_this.element.nativeElement, element); });
        },
        enumerable: true,
        configurable: true
    });
    SplitterComponent.prototype.ngAfterContentInit = function () {
        this.reconfigure();
    };
    SplitterComponent.prototype.ngOnChanges = function (changes) {
        if (changes.orientation && !changes.orientation.isFirstChange()) {
            this.reconfigure();
        }
    };
    SplitterComponent.prototype.ngOnDestroy = function () {
        if (this.enclosingPane) {
            this.enclosingPane.containsSplitter = false;
        }
        this.unsubscribeChanges();
    };
    SplitterComponent.prototype.reconfigure = function () {
        this.unsubscribeChanges();
        this.configure();
        this.paneChangesSubscription = this.panes.changes.subscribe(this.configure);
    };
    SplitterComponent.prototype.unsubscribeChanges = function () {
        if (this.paneChangesSubscription) {
            this.paneChangesSubscription.unsubscribe();
            this.paneChangesSubscription = null;
        }
    };
    SplitterComponent.prototype.configure = function () {
        var _this = this;
        this.splitterService.configure({
            panes: this.panes.toArray(),
            orientation: this.orientation,
            containerSize: function () {
                if (_this.orientation === 'vertical') {
                    return _this.element.nativeElement.clientHeight;
                }
                else {
                    return _this.element.nativeElement.clientWidth;
                }
            }
        });
    };
    Object.defineProperty(SplitterComponent.prototype, "direction", {
        get: function () {
            return this.localization.rtl ? 'rtl' : 'ltr';
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], SplitterComponent.prototype, "orientation", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], SplitterComponent.prototype, "layoutChange", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-widget'),
        core_1.HostBinding('class.k-splitter'),
        core_1.HostBinding('class.k-splitter-flex'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], SplitterComponent.prototype, "hostClasses", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-splitter-horizontal'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], SplitterComponent.prototype, "horizontalHostClasses", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-splitter-vertical'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], SplitterComponent.prototype, "verticalHostClasses", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.dir'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], SplitterComponent.prototype, "dir", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.role'),
        tslib_1.__metadata("design:type", String)
    ], SplitterComponent.prototype, "ariaRole", void 0);
    tslib_1.__decorate([
        core_1.ViewChildren(splitter_bar_component_1.SplitterBarComponent),
        tslib_1.__metadata("design:type", core_1.QueryList),
        tslib_1.__metadata("design:paramtypes", [core_1.QueryList])
    ], SplitterComponent.prototype, "splitbars", null);
    tslib_1.__decorate([
        core_1.ContentChildren(splitter_pane_component_1.SplitterPaneComponent),
        tslib_1.__metadata("design:type", core_1.QueryList)
    ], SplitterComponent.prototype, "panes", void 0);
    SplitterComponent = tslib_1.__decorate([
        core_1.Component({
            exportAs: 'kendoSplitter',
            selector: 'kendo-splitter',
            providers: [
                splitter_service_1.SplitterService,
                kendo_angular_l10n_1.LocalizationService,
                {
                    provide: kendo_angular_l10n_1.L10N_PREFIX,
                    useValue: 'kendo.spliter'
                }
            ],
            template: "\n      <ng-content select=\"kendo-splitter-pane\"></ng-content>\n      <ng-container *ngFor=\"\n        let pane of panes;\n        let index = index;\n        let last = last;\n      \">\n        <kendo-splitter-bar\n          kendoDraggable\n          *ngIf=\"!last\"\n          [index]=\"index\"\n          [orientation]=\"orientation\">\n        </kendo-splitter-bar>\n      </ng-container>\n    "
        }),
        tslib_1.__param(4, core_1.Optional()), tslib_1.__param(4, core_1.Host()), tslib_1.__param(4, core_1.Inject(splitter_pane_component_1.SplitterPaneComponent)),
        tslib_1.__metadata("design:paramtypes", [core_1.ElementRef,
            splitter_service_1.SplitterService,
            kendo_angular_l10n_1.LocalizationService,
            core_1.Renderer2,
            splitter_pane_component_1.SplitterPaneComponent])
    ], SplitterComponent);
    return SplitterComponent;
}());
exports.SplitterComponent = SplitterComponent;
