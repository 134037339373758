//import angular from 'angular';


    angular.module('app.directives')
        .directive(
        'ocCapitalise', function () {
            return {
                require: 'ngModel',
                link: function (scope, element, attrs, modelCtrl) {

                    var capitalise = function (inputValue) {
                        if (inputValue === undefined) {
                            inputValue = '';
                        }

                        var capitalised = inputValue.toUpperCase();

                        if (capitalised !== inputValue) {
                            modelCtrl.$setViewValue(capitalised);
                            modelCtrl.$render();
                        }
                        return capitalised;
                    };

                    modelCtrl.$parsers.push(capitalise);

                    capitalise(scope[attrs.ngModel]); // capitalize initial value
                }
            };
        }
    );
