//import angular from 'angular';
import AdminUrls from '../../../adminUrls';
import tmpl from './details.html';


    angular.module('app.admin').component('addEditResource', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['$scope', 'confirmSvc', 'enums', 'adminUrls', 'WindowFactory', 'amtCommandQuerySvc',
            'notifySvc', 'amtXlatSvc', 'ocConfigSvc', 'errorReporter', '$timeout', 'helperSvc',
            function ($scope, confirmSvc, enums, adminUrls: AdminUrls, WindowFactory: IWindowFactory, amtCommandQuerySvc,
                notifySvc, amtXlatSvc: IAmtXlatSvc, ocConfigSvc, errorReporter, $timeout: ng.ITimeoutService, helperSvc) {

                var vm = this;
                $scope.vm = this;

                vm.adminUrls = adminUrls;                

                vm.enums = enums;
                vm.config = ocConfigSvc;

                vm.secretGenerated = false;

                // pass along dirty flag to the window for use on closing minimised windows
                $scope.$watch('vm.form.$dirty', function () {
                    vm.wnd.isDirty = vm.form.$dirty;
                });
                
                this.$onInit = function () {

                    vm.status = enums.screenStatus.loading;

                    if (vm.wnd !== null) {
                        vm.wnd.onClose = vm.cancel;
                    }

                    vm.wnd.processing = true;

                    if (vm.initParams) {
                        vm.data = angular.copy(vm.initParams);                                              
                    }

                    vm.readonly = !vm.config.user.isSystemAdmin;

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: "common.save_label",
                            name: "saveButton",
                            click: "save",
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: !vm.readonly,
                            disabled: true
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.save
                    };

                    vm.status = enums.screenStatus.ready;

                    $timeout(function () {
                        vm.setPristine();
                        vm.wnd.processing = false;
                    });
                }

                vm.setPristine = function () {
                    if (vm.form) {
                        vm.form.$setPristine();
                    }
                };

                vm.save = function () {

                    var criteria = {
                        key: vm.data.resource.key,
                        languageId: helperSvc.getKey(vm.data.language),
                        description: vm.data.description                                      
                    };

                    vm.wnd.processing = true;

                    return amtCommandQuerySvc.post(vm.adminUrls.addModifyLanguageResource, criteria).then(function (response) {

                        notifySvc.success(amtXlatSvc.xlat('resource.saveSuccessful'));

                        if (vm.wnd.onDataChanged) {
                            vm.wnd.onDataChanged();
                        }

                        vm.closeWindow();
                    }).catch(function (error) {
                        errorReporter.logError(error);
                    }).finally(function () {
                        vm.wnd.processing = false;
                    });
                };

                vm.closeWindow = function () {
                    WindowFactory.closeWindow(vm.wnd);
                };

                vm.cancel = function () {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        vm.form.dirty = false;
                        vm.closeWindow();
                        return true;
                    });

                    return false;
                };
               
                vm.setDirty = function () {
                    if (vm.form) {
                        vm.form.$setDirty();
                    }
                }

                $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing'], function () {
                    vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.form.$pristine || vm.wnd.processing;
                });
            }
        ]
    });
