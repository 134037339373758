//import angular from 'angular';
import OcConfigSvc from '../../../services/ocConfigSvc';



    angular.module('app.directives').directive("ocChart", [
        '$compile', 'amtXlatSvc', 'WindowFactory', '$filter', 'errorReporter', 'fieldSurveyService', 'ocSecuritySvc', 'ocConfigSvc', 'amtConstants',
        function ($compile: ng.ICompileService, amtXlatSvc: IAmtXlatSvc, WindowFactory: IWindowFactory, $filter: ng.IFilterService, errorReporter: IErrorReporter, fieldSurveyService, ocSecuritySvc: IOcSecuritySvc, ocConfigSvc: OcConfigSvc, amtConstants: IAmtConstants) {
            return {
                scope: {
                    width: "=",
                    height: "=",
                    equipmentObject: "=",
                    siteId: "=",
                    chartData: "=", // param to allow markup to pass data into the ocChart
                    dateRange: "=", // values which are set when the user selects a date range on the chart
                    onDataChangeHandler: "=",
                    config: "=",
                    view: "=",
                    equipmentTypeName: "=",
                    statusAsGant: "=?",
                    openModule: "="
                },
                controllerAs: 'vm',
                controller: ["$scope", "$element", function ($scope, $element) {
                    var vm = this;

                    // security
                    vm.canOpenComponents = ocSecuritySvc.isAuthorised('Security.Components', AccessTypes.readOnly);
                    vm.canOpenMaintenanceSession = ocSecuritySvc.isAuthorised('Security.Vehicles.MaintenanceSession', AccessTypes.readOnly);
                    vm.canOpenFieldSurvey = ocSecuritySvc.isAuthorised('Security.Vehicles.FieldSurveys', AccessTypes.readOnly);
                    vm.canOpenComponentStatusChange = ocSecuritySvc.isAuthorised('Security.Components.ChangeStatus', AccessTypes.readOnly);
                    vm.canOpenVehicleStatusChange = ocSecuritySvc.isAuthorised('Security.Vehicles.ChangeStatus', AccessTypes.readOnly);

                    $scope.statusAsGant = $scope.statusAsGant === true || $scope.statusAsGant === "true";

                    vm.minDayWidth = 8;
                    vm.yearHeight = 20;
                    vm.monthHeight = 20;
                    vm.dayHeight = 0;
                    vm.statusTop = vm.yearHeight + vm.monthHeight + 5;
                    vm.statusHeight = 20;
                    vm.legendItems = [];
                    vm.legendHeight = 20;
                    vm.xAxisLabelWidth = 0;

                    vm.scrollbarWidth = 0;

                    function updateHeaderDimentions() {

                        switch ($scope.view) {
                            case "Readings":
                                vm.xAxisLabelWidth = 0;
                                break;
                            case "FitmentHistory":
                                vm.xAxisLabelWidth = 80;
                                break;
                        }

                        vm.statusTop = vm.yearHeight + vm.monthHeight + vm.dayHeight + 5;

                        vm.chartTop = vm.statusTop + vm.statusHeight + 5;
                        vm.chartHeight = $scope.height - vm.chartTop - vm.legendHeight - 5;

                        vm.chartLeft = vm.xAxisLabelWidth;

                        vm.chartWidth = $scope.width - vm.xAxisLabelWidth;

                        if ($scope.view === "FitmentHistory" && $scope.chartData && $scope.chartData.positions) {
                            if ($scope.config && $scope.config.maxPositions && $scope.chartData.positions.length > $scope.config.maxPositions) {
                                // make room for the scrollbar
                                vm.scrollbarWidth = 30;
                                vm.chartWidth = vm.chartWidth - vm.scrollbarWidth;
                            }
                        }
                    }

                    updateHeaderDimentions();

                    vm.chartItems = [];
                    vm.scale = {};

                    function scaleValue(reading, value) {
                        // need to inverse the value here because 0 is top, not bottom
                        return vm.chartTop + ((1 - (value.value / (reading.max - reading.min))) * vm.chartHeight) || 0;
                    }

                    vm.tooltip = {
                        width: 1,
                        visible: false,
                        fontHeight: 11,
                        fontWidth: 7
                    };

                    function addPoint(cssClass, x, y, tooltip, value) {
                        var point = {
                            type: 'point',
                            cssClass: cssClass,
                            projected: value.projectedValue,
                            isNewMeter: value.isNewMeter === true,
                            x: x,
                            y: y,
                            hoverStart: function () {
                                hoverEvent(x, y, tooltip, 8);
                            },
                            hoverEnd: function () {
                                hoverEvent();
                            },
                            onClick: function () {
                                openReadingEvent(value);
                            },
                            order: 9,
                            adjusted: value.adjusted
                        };

                        vm.chartItems.push(point);

                        return point;
                    }

                    function formatDate(d) {
                        var date = reparseDate(d);

                        return addZero(date.getDate()) + '-' +
                            addZero(date.getMonth() + 1) + '-' +
                            date.getFullYear() + ' ' +
                            addZero(date.getHours()) + ':' +
                            addZero(date.getMinutes());
                    }

                    function addZero(i) {
                        if (i < 10) {
                            i = '0' + i;
                        }
                        return i;
                    }

                    function createLineAxis(reading, stroke) {
                        var l = {
                            type: 'line',
                            cssClass: stroke.cssClass,
                            points: [],
                            pointsD: "",
                            order: 3
                        };

                        if (reading.max === 0) {
                            // don't plot the reading if it's zero
                            return;
                        }

                        var readingTypeString = amtXlatSvc.xlat('equipment.readingType_' + reading.type);
                        var projectedString = amtXlatSvc.xlat('equipment.projected');

                        var nitro = null;
                        if (reading.type === "PRESSURE") {
                            // see if we have some nitrogen
                            for (var r = 0; r < $scope.chartData.readings.length; r++) {
                                var readingSet = $scope.chartData.readings[r];
                                if (readingSet.type === "NITROGEN" && readingSet.values.length > 0) {
                                    nitro = readingSet.values;
                                }
                            }
                        }

                        for (var v = 0; v < reading.values.length; v++) {

                            var value = reading.values[v];
                            var tooltipLines = [];

                            // don't show unit for hours, it is always hours
                            if (reading.type === 'HOURS') {
                                value.unit = null;
                            }

                            tooltipLines.push({ name: amtXlatSvc.xlat('equipment.readingDate') + ':', value: formatDate(value.date) });

                            if (value.location) {
                                tooltipLines.push({ name: amtXlatSvc.xlat('equipment.location') + ':', value: value.location });
                            }

                            if (value.adjusted) {
                                tooltipLines.push({ name: amtXlatSvc.xlat('equipment.readingType') + ':', value: amtXlatSvc.xlat('equipment.adjusted') + " " + readingTypeString });
                            } else {
                                tooltipLines.push({ name: amtXlatSvc.xlat('equipment.readingType') + ':', value: readingTypeString });
                            }

                            if (value.isNewMeter === true) {
                                tooltipLines.push({ name: amtXlatSvc.xlat('equipment.newMeter') + ':', value: amtXlatSvc.xlat('equipment.newMeter_true') });
                            }

                            if (value.projected === true) {
                                tooltipLines.push({
                                    name: amtXlatSvc.xlat('equipment.readingValue') + ':', value: $filter('number')(Math.floor(value.value * 100) / 100)
                                        + (value.unit ? ' ' + amtXlatSvc.xlat('common.unit_' + value.unit) : '')
                                } + ' (' + projectedString + ')');
                            } else {
                                tooltipLines.push({
                                    name: amtXlatSvc.xlat('equipment.readingValue') + ':', value: (value.unit === 't/f' ? (value.value === 0 ? amtXlatSvc.xlat('common.fail') :
                                        amtXlatSvc.xlat('common.pass')) : $filter('number')(Math.floor(value.value * 100) / 100) + (value.unit ? ' ' + amtXlatSvc.xlat('common.unit_' + value.unit) : ''))
                                });

                                if (value.unit !== 't/f') {
                                    tooltipLines.push({
                                        name: amtXlatSvc.xlat('equipment.readingDifference') + ':', value: $filter('number')(Math.floor(value.difference * 100) / 100)
                                            + (value.unit ? ' ' + amtXlatSvc.xlat('common.unit_' + value.unit) : '')
                                    });
                                }
                            }

                            if (nitro) {
                                for (var nv = 0; nv < nitro.length; nv++) {
                                    if (nitro[nv].date.getTime() === value.date.getTime()) {
                                        tooltipLines.push({
                                            name: amtXlatSvc.xlat('equipment.nitrogen') + ':',
                                            value: $filter('number')(nitro[nv].value) + '%'
                                        });
                                    }
                                }
                            }

                            if (value.siteId !== $scope.siteId && value.siteName) {
                                tooltipLines.push({
                                    name: amtXlatSvc.xlat('common.site_label') + ':', value: value.siteName
                                });
                            }

                            var point = addPoint(
                                stroke.cssClass,
                                projectTimeScale(value.date),
                                scaleValue(reading, value),
                                tooltipLines,
                                value
                            );

                            if (l.pointsD.length === 0 || value.isNewMeter === true) {
                                l.pointsD += 'M';
                            } else {
                                l.pointsD += ' L';
                            }

                            l.pointsD += point.x + ' ' + point.y;
                        }

                        // add the line to the collection
                        vm.chartItems.push(l);

                    }

                    function projectTimeScale(d) {
                        d = reparseDate(d);
                        return vm.xAxisLabelWidth + ((d - vm.scale.start) / vm.scale.duration * vm.chartWidth) || 0;
                    }

                    function calculateScale() {
                        setScaleStart($scope.chartData.dateFrom);
                        setScaleEnd($scope.chartData.dateTo);

                        if ($scope.chartData.readings) {
                            for (var i = 0; i < $scope.chartData.readings.length; i++) {

                                var reading = $scope.chartData.readings[i];

                                reading.min = 0;

                                var total = 0;

                                for (var v = 0; v < reading.values.length; v++) {
                                    var value = reading.values[v];

                                    if (reading.cumulative === true) {
                                        total += value.value;
                                        value.value = total;
                                    } else {
                                        if (reading.max === undefined || reading.max < value.value) {
                                            reading.max = value.value;
                                        }
                                    }

                                    setScaleDates(value.date);

                                    if (reading.cumulative === true) {
                                        reading.max = total;
                                    }
                                }

                                reading.max = reading.max * 1.05;
                            }
                        }

                        if ($scope.chartData.statuses) {
                            for (var j = 0; j < $scope.chartData.statuses.length; j++) {
                                var status = $scope.chartData.statuses[j];

                                setScaleDates(status.start);
                                setScaleDates(status.end);
                            }
                        }

                        vm.scale.duration = vm.scale.end - vm.scale.start;
                    }

                    function calculateDateAtX(x) {
                        return new Date(vm.scale.start.getTime() - (vm.scale.start.getTimezoneOffset() * 60000) + (vm.scale.duration * ((x - vm.xAxisLabelWidth) / vm.chartWidth)));
                    }

                    var zoomHistory = [];

                    $scope.undoLastZoom = function () {
                        if (zoomHistory.length > 0) {
                            zoomHistory.pop();

                            if (zoomHistory.length > 0) {
                                $scope.zoomToDateRange(
                                    zoomHistory[zoomHistory.length - 1].from,
                                    zoomHistory[zoomHistory.length - 1].to,
                                    false
                                );
                                return;
                            }
                        }

                        $scope.zoomToDateRange(
                            null,
                            null,
                            false
                        );
                    };

                    $scope.zoomToDateRange = function (dateFrom, dateTo, keepHistory) {
                        var range = {
                            from: dateFrom,
                            to: dateTo
                        };

                        if (keepHistory === true) {
                            zoomHistory.push(range);
                        }

                        $scope.dateRange = range;
                    };

                    function hoverChartRange(range) {
                        vm.chartRange.x = projectTimeScale(range.from);
                        vm.chartRange.width = projectTimeScale(range.to) - vm.chartRange.x;
                    }

                    function hideChartRange() {
                        vm.chartRange.width = 0;
                    }

                    var millisecondsPerSecond = 1000;
                    var millisecondsPerMinute = 60 * millisecondsPerSecond;
                    var millisecondsPerHour = 60 * millisecondsPerMinute;
                    var millisecondsPerDay = 24 * millisecondsPerHour;



                    function buildHeader() {
                        let monthNames: string[] = kendo.cultures.current.calendars.standard.months.namesAbbr;
                        var year = vm.scale.start;

                        var alternateYear = false;

                        while (year < vm.scale.end) {
                            alternateYear = !alternateYear;

                            var nextYear = new Date(year.getFullYear() + 1, 0, 1);

                            if (nextYear > vm.scale.end) {
                                // cap the next year at the end of the chart date range
                                nextYear = vm.scale.end;
                            }

                            var left = projectTimeScale(year);
                            var width = projectTimeScale(nextYear) - left;

                            addRectangle(
                                left,
                                0,
                                width,
                                vm.yearHeight,
                                "chartScale" + (alternateYear ? "" : " alternateYear") + " year",
                                width < 25 ? "" : year.getFullYear(),
                                width < 25 ? year.getFullYear() : null, // no tooltip
                                {
                                    from: year,
                                    to: addSeconds(nextYear, -1)
                                }
                            );

                            // don't plot the months, if they're going to be very narrow
                            var alternateMonth = false;
                            var month = year;
                            while (month < nextYear) {
                                alternateMonth = !alternateMonth;

                                var nextMonth = month.getMonth() < 12 ? new Date(month.getFullYear(), month.getMonth() + 1, 1) : new Date(month.getFullYear() + 1, 0, 1);

                                if (nextMonth > nextYear) {
                                    nextMonth = nextYear;
                                }

                                if (month <= nextMonth) {
                                    var monthLeft = projectTimeScale(month);
                                    var monthWidth = projectTimeScale(nextMonth) - monthLeft;

                                    addRectangle(
                                        monthLeft,
                                        vm.yearHeight,
                                        monthWidth,
                                        vm.monthHeight,
                                        "chartScale" + (alternateMonth ? "" : " alternateMonth") + " month",
                                        monthWidth < 25 ? "" : monthNames[month.getMonth()],
                                        monthWidth < 25 ? monthNames[month.getMonth()] + '-' + year.getFullYear() : null,
                                        {
                                            from: month,
                                            to: addSeconds(nextMonth, -1)
                                        }
                                    );

                                    if (vm.dayHeight > 0) {
                                        var alternateDay = false;

                                        var day = month;

                                        var offset = (month.valueOf() - month.getTimezoneOffset() * 60000) % millisecondsPerDay;

                                        while (day < nextMonth) {
                                            alternateDay = !alternateDay;

                                            var nextDay = new Date(day.valueOf() + millisecondsPerDay - offset);

                                            offset = 0;

                                            var dayLeft = projectTimeScale(day);
                                            var dayWidth = projectTimeScale(nextDay) - dayLeft;

                                            addRectangle(
                                                dayLeft,
                                                vm.yearHeight + vm.monthHeight,
                                                dayWidth,
                                                vm.dayHeight,
                                                "chartScale" + (alternateYear ? "" : " alternateYear") + (alternateMonth ? "" : " alternateMonth") + (alternateDay ? "" : " alternateDay") + " day",
                                                dayWidth < vm.minDayWidth * 2 ? "" : day.getDate(),
                                                dayWidth < vm.minDayWidth * 2 ? day.getDate() + '-' + monthNames[month.getMonth()] + '-' + year.getFullYear() : null,
                                                {
                                                    from: day,
                                                    to: addSeconds(nextDay, -1)
                                                }
                                            );

                                            day = nextDay;
                                        }
                                    }
                                }
                                month = nextMonth;
                            }

                            vm.chartItems.push({
                                type: 'rect',
                                x: left,
                                y: vm.chartTop,
                                width: width,
                                height: vm.chartHeight,
                                cssClass: "chartBackground" + (alternateYear ? "" : " alternate") + " year",
                                order: 1
                            });

                            year = nextYear;
                        }
                    }

                    function setChartRange(x, w) {
                        vm.chartRange.x = x;
                        vm.chartRange.width = w;
                    }

                    function addRectangle(x, y, w, h, cssClass, text, tooltip?, dateRange?, layer?, textClickHandler?) {
                        if (w <= 0) {
                            return;
                        }

                        var textWidth = 0;

                        if (text) {
                            textWidth = text.toString().length * 6.5;
                        }

                        var r = {
                            type: 'rect',
                            x: x,
                            y: y,
                            width: w,
                            height: h,
                            cssClass: cssClass,
                            text: textWidth < w ? text : "",
                            textClickHandler: textClickHandler,
                            order: layer || 1,
                            hoverStart: undefined,
                            hoverEnd: undefined,
                            click: undefined
                        };

                        if (dateRange !== undefined && dateRange !== null) {
                            r.hoverStart = function () {
                                if (vm.isMouseDown !== true) {
                                    if ((tooltip !== undefined && tooltip != null && textWidth >= w) || tooltip !== text) {
                                        hoverEvent(x, y, tooltip, h);
                                    }
                                    setChartRange(x, w);
                                }
                            };

                            r.hoverEnd = function () {
                                if (vm.isMouseDown !== true) {
                                    hoverEvent();
                                    hideChartRange();
                                }
                            };

                            r.click = function () {
                                $scope.zoomToDateRange(dateRange.from, dateRange.to, true);
                            };
                        } else {
                            r.hoverStart = function () {
                                if (vm.isMouseDown !== true) {
                                    if ((tooltip !== undefined && tooltip != null && textWidth >= w) || tooltip !== text) {
                                        hoverEvent(x, y, tooltip, h);
                                    }
                                }
                            };

                            r.hoverEnd = function () {
                                if (vm.isMouseDown !== true) {
                                    hoverEvent();
                                }
                            };
                        }

                        vm.chartItems.push(r);

                        return r;
                    }

                    function renderStatuses() {
                        if ($scope.chartData.statuses.length === 0) {
                            return;
                        }

                        var layer = 1;
                        var top = vm.statusTop;
                        var statusHeight = vm.statusHeight;

                        if ($scope.statusAsGant === true) {
                            top = vm.chartTop + 5;
                            statusHeight = (vm.chartHeight - 10) / $scope.chartData.statuses.length;

                            if (statusHeight > vm.statusHeight) {
                                statusHeight = vm.statusHeight;
                                top = top + (vm.chartHeight - (statusHeight * $scope.chartData.statuses.length)) / 2;
                            }

                            layer = 6;
                        }

                        if (vm.xAxisLabelWidth > 0) {
                            addRectangle(
                                0,
                                top,
                                vm.xAxisLabelWidth,
                                statusHeight,
                                "chartPositionLabel",
                                $scope.equipmentObject.serialNumber.formatted,
                                $scope.equipmentObject.serialNumber.formatted,
                                null,
                                layer
                            );
                        }

                        for (var r = 0; r < $scope.chartData.statuses.length; r++) {
                            var status = $scope.chartData.statuses[r];

                            if (vm.legendItems.indexOf('statusType_' + status.type) === -1) {
                                vm.legendItems.push('statusType_' + status.type);
                            }

                            var statusLeft = (status.start == null ? 0 : projectTimeScale(status.start));
                            var statusWidth = (status.end == null ? vm.chartWidth + vm.xAxisLabelWidth : projectTimeScale(status.end)) - statusLeft;

                            var tooltipLines = [];

                            if (status.siteId !== $scope.siteId) {
                                tooltipLines.push({ name: amtXlatSvc.xlat('common.site_label') + ':', value: status.siteName });
                            }

                            tooltipLines.push({ name: amtXlatSvc.xlat('equipment.status') + ':', value: status.description });

                            if (status.positionDetails) {
                                tooltipLines.push({ name: amtXlatSvc.xlat('equipment.vehicleSerialNumber') + ':', value: status.positionDetails.vehicleSerialNumber + ' (' + status.positionDetails.label + ')' });
                            }

                            tooltipLines.push({ name: amtXlatSvc.xlat('equipment.statusFrom') + ':', value: formatDate(status.unclippedStart) });

                            if (status.unclippedEnd) {
                                tooltipLines.push({ name: amtXlatSvc.xlat('equipment.statusTo') + ':', value: formatDate(status.unclippedEnd) });
                            }

                            if (statusWidth < 2) {
                                statusWidth = 2;
                            }

                            addRectangle(
                                statusLeft,
                                top,
                                statusWidth,
                                statusHeight,
                                "status " + 'statusType_' + status.type,
                                statusHeight >= vm.statusHeight ? status.description : "",
                                tooltipLines,
                                {
                                    from: status.unclippedStart,
                                    to: status.unclippedEnd
                                },
                                layer
                            );

                            if ($scope.statusAsGant === true) {
                                top += statusHeight;
                            }
                        }
                    }

                    function renderReadings() {
                        for (var r = 0; r < $scope.chartData.readings.length; r++) {
                            var reading = $scope.chartData.readings[r];

                            // Nitrogen is injected into presure and not recored as an axis 
                            if (reading.type !== "NITROGEN") {
                                if (reading.max > 0) {
                                    vm.legendItems.push('readingType_' + reading.type);
                                }
                                createLineAxis(reading, {
                                    tooltip: reading.description,
                                    cssClass: 'reading ' + 'readingType_' + reading.type
                                });
                            }
                        }
                    }

                    $scope.$watch('config.top', function () {
                        $scope.renderChart();
                    });

                    function renderHistoricalFitments() {
                        if (!$scope.chartData.positions) {
                            return;
                        }

                        // set the position count on the config, so that it can display the scoll bar
                        $scope.config.positionCount = $scope.chartData.positions.length;

                        var posCount = $scope.chartData.positions.length;

                        var firstPos = 0;
                        var lastPos = posCount;

                        var posHeight = vm.chartHeight / posCount;

                        if ($scope.config && $scope.config.maxPositions && lastPos > $scope.config.maxPositions) {
                            firstPos = $scope.config.top;
                            lastPos = firstPos + $scope.config.maxPositions;

                            posHeight = vm.chartHeight / $scope.config.maxPositions;

                            // add the scrollbar
                            vm.chartItems.push({
                                left: vm.xAxisLabelWidth + vm.chartWidth,
                                top: vm.chartTop,
                                width: vm.scrollbarWidth,
                                height: vm.chartHeight,
                                min: 0,
                                max: posCount - 1,
                                itemsToDisplay: $scope.config.maxPositions,
                                value: $scope.config.top,
                                order: 15
                            });
                        }

                        for (var p = firstPos; p < lastPos; p++) {
                            var position = $scope.chartData.positions[p];

                            var posTop = vm.chartTop + posHeight * (p - firstPos) + 5;

                            addRectangle(0, posTop, vm.xAxisLabelWidth, posHeight - 10, "chartPositionLabel", position.label);

                            for (var f = 0; f < position.fitments.length; f++) {
                                var fitment = position.fitments[f];

                                var x = projectTimeScale(fitment.dateFrom);
                                var w = fitment.dateTo == null ? vm.chartWidth - x + vm.xAxisLabelWidth : projectTimeScale(fitment.dateTo) - x;

                                var tooltipLines = [];

                                tooltipLines.push({ name: amtXlatSvc.xlat('equipment.componentType') + ':', value: fitment.equipmentTypeDescription });
                                tooltipLines.push({ name: amtXlatSvc.xlat('equipment.serialNumber') + ':', value: fitment.serialNumber });
                                tooltipLines.push({ name: amtXlatSvc.xlat('equipment.fitted') + ':', value: $filter('date')(fitment.unclippedDateFrom, 'short') });


                                if (fitment.dateTo) {
                                    tooltipLines.push({ name: amtXlatSvc.xlat('equipment.removed') + ':', value: $filter('date')(fitment.unclippedDateTo, 'short') });
                                }

                                addRectangle(
                                    x,
                                    posTop,
                                    w,
                                    posHeight - 10,
                                    "chartPositionFitment " + fitment.equipmentTypeName,
                                    fitment.serialNumber,
                                    tooltipLines,
                                    null,
                                    6,
                                    (vm.canOpenComponents && ocConfigSvc.user.site.id === fitment.siteId ? openComponentFunction(fitment) : null)
                                );
                            }
                        }
                    }

                    function openComponentFunction(fitment) {
                        return function () {
                            showComponentDetails(fitment);
                        };
                    }

                    function showComponentDetails(fitment) {
                        WindowFactory.openItem({
                            component: 'component-details',
                            caption: amtXlatSvc.xlat('equipment.open' + fitment.equipmentTypeName, fitment.serialNumber),
                            windowRelatedRecordId: fitment.equipmentId,
                            initParams: {
                                mode: "open",
                                componentType: fitment.equipmentTypeName,
                                equipmentId: fitment.equipmentId,
                                siteId: fitment.siteId,
                                serialNumber: fitment.serialNumber,

                            },
                            width: 800,
                            height: 850
                        });
                    }

                    $scope.$watch('config.top', function (newValue, oldValue) {
                        if (newValue && newValue !== oldValue) {
                            $scope.renderChart();
                        }
                    });

                    function renderPolygonPoints(points) {
                        var s = '';
                        for (var e = 0; e < points.length; e++) {
                            var point = points[e];

                            if (e > 0) {
                                s += ',';
                            }
                            s += point.x + ',' + point.y;
                        }

                        return s;
                    }

                    function createTrianglePoints(left, top, width, height) {
                        return [
                            { x: (left), y: top - height / 2 },
                            {
                                x: left - width / 2, y: (top + height / 2)
                            },
                            {
                                x: (left + width / 2), y: (top + height / 2)
                            }
                        ];
                    }

                    function createInvertedTrianglePoints(left, top, width, height) {
                        return [
                            { x: left, y: top + height / 2 },
                            {
                                x: left - width / 2, y: top - height / 2
                            },
                            {
                                x: left + width / 2, y: top - height / 2
                            }
                        ];
                    }

                    function createDiamondPoints(left, top, width, height) {
                        return [
                            {
                                x: left, y: top - width / 2
                            },
                            {
                                x: left - width / 2, y: top
                            },
                            {
                                x: left, y: top + height / 2
                            },
                            {
                                x: left + width / 2, y: top
                            }
                        ];
                    }

                    function hoverEvent(x?, y?, description?, h?) {
                        if (!description || description.length === 0) {
                            vm.tooltip.visible = false;
                        } else {
                            var bArray = angular.isArray(description);

                            if (bArray) {
                                vm.tooltip.type = 'array';

                                vm.tooltipHeadingWidth = 0;
                                vm.tooltipValueWidth = 0;

                                for (var l = 0; l < description.length; l++) {
                                    if (vm.tooltipHeadingWidth < description[l].name.length) {
                                        vm.tooltipHeadingWidth = description[l].name.length;
                                    }

                                    if (description[l].value !== null) {
                                        if (vm.tooltipValueWidth < description[l].value.length) {
                                            vm.tooltipValueWidth = description[l].value.length;
                                        }
                                    }
                                }

                                vm.tooltip.width = (vm.tooltipHeadingWidth + vm.tooltipValueWidth) * vm.tooltip.fontWidth;
                                vm.tooltip.values = description;
                                vm.tooltip.height = vm.tooltip.fontHeight * description.length;
                            } else {
                                vm.tooltip.type = 'text';
                                vm.tooltip.text = description;
                                vm.tooltip.width = (description.length + 1) * vm.tooltip.fontWidth;
                                vm.tooltip.height = vm.tooltip.fontHeight;
                            }

                            if (x + vm.tooltip.width > vm.chartWidth) {
                                // left
                                vm.tooltip.x = x - vm.tooltip.width - 10;
                            } else {
                                // right
                                vm.tooltip.x = x + 5;
                            }

                            if (y + h + 6 + vm.tooltip.height > $scope.height) {
                                // above
                                vm.tooltip.y = y - vm.tooltip.height - 6;
                            } else {
                                // below
                                vm.tooltip.y = y + h + 12;
                            }


                            vm.tooltip.visible = true;
                        }
                    }

                    function openReadingEvent(value) {

                        var canOpenEvent = value.siteId === ocConfigSvc.user.site.id
                            || (value.siteName === amtConstants.secondHandPreviousSite
                                && value.clientName === amtConstants.secondHandPreviousClient && value.relatedToReceive);

                        if (canOpenEvent) {

                            var canOpenStatusChange = ($scope.equipmentObject.isVehicle ? vm.canOpenVehicleStatusChange : vm.canOpenComponentStatusChange);

                            switch (value.location.toLowerCase()) {
                                case "field":
                                    if ((value.projectedValue === false) && (value.eventType.toLowerCase() == "fieldsurvey")) {
                                        if (vm.canOpenFieldSurvey) {
                                            fieldSurveyService.fieldSurveyFromReading(value.readingEvent);
                                        }
                                    } else if (value.eventType.toLowerCase() == "move") {

                                        if (canOpenStatusChange) {

                                            if ($scope.equipmentObject.isVehicle) {
                                                WindowFactory.openItem({
                                                    component: 'vehicle-change-status-popup',
                                                    caption: amtXlatSvc.xlat("vehicle.statusChangeHeader", $scope.equipmentObject.serialNumber.formatted),
                                                    left: ((window.innerWidth - 1400) / 2),
                                                    top: ((window.innerHeight - 800) / 2),
                                                    initParams: {
                                                        mode: 'edit',
                                                        vehicle: $scope.equipmentObject,
                                                        equipmentEventId: value.relatedEquipmentEventId,
                                                    },
                                                    width: 750,
                                                    modal: true,
                                                    canClose: false,
                                                    parentWindow: vm.wnd,
                                                    onDataChangeHandler: vm.refresh
                                                });
                                            } else {
                                                WindowFactory.openItem({
                                                    caption: amtXlatSvc.xlat("equipment.editStatusChangeEvent"),
                                                    component: 'change-status-popup',
                                                    left: ((window.innerWidth - 1400) / 2),
                                                    top: ((window.innerHeight - 800) / 2),
                                                    width: 1400,
                                                    modal: true,
                                                    canClose: false,
                                                    initParams: {
                                                        equipmentId: $scope.equipmentObject.id,
                                                        equipmentEventId: value.relatedEquipmentEventId,
                                                        isEdit: true,
                                                        isBulkMove: false,
                                                        siteId: $scope.equipmentObject.siteId,
                                                        clientId: $scope.equipmentObject.clientId,
                                                        showCloseOnSave: false
                                                    },
                                                    parentWindow: vm.wnd, // parent
                                                    onDataChangeHandler: vm.refresh
                                                });
                                            }
                                        }
                                    }
                                    break;
                                case "maintenance":
                                    if (value.relatedToReceive) {

                                        if (canOpenStatusChange) {

                                            if ($scope.equipmentObject.isVehicle) {
                                                WindowFactory.openItem({
                                                    component: 'vehicle-change-status-popup',
                                                    caption: amtXlatSvc.xlat("vehicle.statusChangeHeader", $scope.equipmentObject.serialNumber.formatted),
                                                    left: ((window.innerWidth - 1400) / 2),
                                                    top: ((window.innerHeight - 800) / 2),
                                                    initParams: {
                                                        mode: 'edit',
                                                        vehicle: $scope.equipmentObject,
                                                        equipmentEventId: value.relatedEquipmentEventId,
                                                    },
                                                    width: 750,
                                                    modal: true,
                                                    canClose: false,
                                                    parentWindow: vm.wnd,
                                                    onDataChangeHandler: vm.refresh
                                                });
                                            } else {
                                                WindowFactory.openItem({
                                                    caption: amtXlatSvc.xlat("equipment.editStatusChangeEvent"),
                                                    component: 'change-status-popup',
                                                    left: ((window.innerWidth - 1400) / 2),
                                                    top: ((window.innerHeight - 800) / 2),
                                                    width: 1400,
                                                    modal: true,
                                                    canClose: false,
                                                    initParams: {
                                                        equipmentId: $scope.equipmentObject.id,
                                                        equipmentEventId: value.relatedEquipmentEventId,
                                                        isEdit: true,
                                                        isBulkMove: false,
                                                        clientId: $scope.equipmentObject.clientId,
                                                        siteId: $scope.equipmentObject.siteId,
                                                        showCloseOnSave: false
                                                    },
                                                    parentWindow: vm.wnd, // parent
                                                    onDataChangeHandler: vm.refresh
                                                });
                                            }
                                        }

                                    }
                                    else if (vm.canOpenMaintenanceSession) {
                                      WindowFactory.openItem({
                                            component: 'maintenance',
                                            caption: amtXlatSvc.xlat('equipment.maintenanceSession'),
                                            windowRelatedRecordId: value.relatedEquipmentEventId,
                                            initParams: {
                                                vehicleId: $scope.equipmentObject.id,
                                                date: value.date,
                                                id: value.relatedEquipmentEventId
                                            },
                                            headerOff: false,
                                            footerOff: true,
                                            height: 700,
                                            width: 1100,
                                            modal: false,
                                            parentWindow: null,
                                            onDataChangeHandler: null
                                        });
                                    }
                                    break;
                            }
                        }
                    }

                    function openStatusChange(item) {

                        var canOpenStatusChange = ($scope.equipmentObject.isVehicle ? vm.canOpenVehicleStatusChange : vm.canOpenComponentStatusChange);

                        if (canOpenStatusChange) {
                            if ($scope.equipmentObject.isVehicle) {
                                WindowFactory.openItem({
                                    component: 'vehicle-change-status-popup',
                                    caption: amtXlatSvc.xlat("vehicle.statusChangeHeader", $scope.equipmentObject.serialNumber.formatted),
                                    left: ((window.innerWidth - 1400) / 2),
                                    top: ((window.innerHeight - 800) / 2),
                                    initParams: {
                                        mode: 'edit',
                                        type: item.statusType.toLowerCase(),
                                        vehicle: $scope.equipmentObject,
                                        equipmentEventId: item.id
                                    },
                                    width: 750,
                                    modal: true,
                                    canClose: false,
                                    parentWindow: vm.wnd,
                                    onDataChangeHandler: vm.refresh
                                });
                            } else {
                                WindowFactory.openItem({
                                    caption: amtXlatSvc.xlat("equipment.editStatusChangeEvent"),
                                    component: 'change-status-popup',
                                    left: ((window.innerWidth - 1400) / 2),
                                    top: ((window.innerHeight - 800) / 2),
                                    width: 1400,
                                    modal: true,
                                    canClose: false,
                                    initParams: {
                                        equipmentId: $scope.equipmentObject.id,
                                        equipmentEventId: item.id,
                                        isEdit: true,
                                        isBulkMove: false,
                                        siteId: $scope.equipmentObject.siteId,
                                        clientId: $scope.equipmentObject.clientId,
                                        showCloseOnSave: false
                                    },
                                    parentWindow: $scope.wnd, // parent
                                    onDataChangeHandler: vm.refresh
                                });
                            }
                        }
                    }

                    function openEvent(item) {

                        var canOpenEvent = item.id
                            && (item.siteId === ocConfigSvc.user.site.id
                                || (item.siteName === amtConstants.secondHandPreviousSite
                                    && item.clientName === amtConstants.secondHandPreviousClient && item.isReceive));

                        if (canOpenEvent) {

                            switch (item.type.toLowerCase()) {
                                
                                case "movedtodispatchedtransfer":

                                    if ($scope.equipmentObject.isVehicle) {

                                        WindowFactory.openItem({
                                            component: 'transfer-vehicle-popup',
                                            caption: amtXlatSvc.xlat("vehicle.transferVehicle", $scope.equipmentObject.serialNumber.formatted),
                                            modal: true,
                                            canClose: false,
                                            windowRelatedRecordId: item.id,
                                            initParams: {
                                                vehicle: $scope.equipmentObject.id,
                                                clientId: $scope.equipmentObject.clientId,
                                                siteId: $scope.siteId,
                                                equipmentEventId: item.id
                                            },
                                            width: 800,
                                            onDataChangeHandler: item.onDataChangeHandler
                                        });

                                    } else {
                                        openStatusChange(item);
                                    }
                                    break;

                                case "movedtodispatchedrepair":
                                case "movedtodispatchedretread":
                                case "movedtodispatchedreturntosupplier":
                                case "movedtodispatchedoffsitendtinspection":
                                case "movedtooutofservice":
                                case "movedtodisposed":
                                case "movedtonew":
                                case "movedtoawaitingdispatchrepair":
                                case "movedtoawaitingdispatchretread":
                                case "movedtoawaitingdispatchreturntosupplier":                                
                                    openStatusChange(item);
                                    break;

                                case 'fieldsurvey':
                                    if (vm.canOpenFieldSurvey) {
                                        WindowFactory.openItem({
                                            component: 'field-surveys',
                                            caption: amtXlatSvc.xlat('fieldSurvey.elementName'),
                                            modal: false,
                                            canMinimise: true,
                                            canClose: true,
                                            parentWindow: null,
                                            windowRelatedRecordId: item.pendingFieldSurveyId,
                                            initParams: {
                                                id: item.pendingFieldSurveyId,
                                                date: item.date,
                                                equipmentId: $scope.equipmentObject.id
                                            },
                                            footerOff: true,
                                            headerOff: false,
                                            width: 1000,
                                            height: 700,
                                            onDataChangeHandler: item.onDataChangeHandler
                                        });
                                    }
                                    break;

                                case 'movedtomaintenance':
                                case 'movedtoproduction':
                                case 'movedtofitted':
                                case 'movedtospare':
                                case 'movedtoinspection':
                                case 'movedtoscrapped':
                                case "movedtoawaitingdispatchoffsitendtinspection":
                                case "movedtoawaitingdispatchtransfer":

                                    if (item.sessionEvent) {
                                        if (vm.canOpenMaintenanceSession) {
                                            WindowFactory.openItem({
                                                component: 'maintenance',
                                                headerOff: false,
                                                footerOff: true,
                                                caption: amtXlatSvc.xlat('equipment.maintenanceSession'),
                                                windowRelatedRecordId: (item.fittedVehicle ? item.fittedVehicle.equipmentEventId : item.id),
                                                initParams: {
                                                    vehicleId: (item.fittedVehicle ? item.fittedVehicle.vehicleId : $scope.equipmentObject.id),
                                                    display: (item.fittedVehicle ? item.fittedVehicle.serialNumber.formatted + " - " + item.fittedVehicle.specification : $scope.equipmentObject.serialNumber.formatted + " - " + $scope.equipmentObject.specification.description),
                                                    date: item.date,
                                                    id: (item.fittedVehicle ? item.fittedVehicle.equipmentEventId : item.id)
                                                },
                                                height: 700,
                                                width: 1100,
                                                onDataChangeHandler: vm.refresh
                                            });
                                        }
                                    } else {
                                        openStatusChange(item);
                                    }
                                    break;

                                case 'event':
                                    {
                                        switch (item.module.toLowerCase()) {
                                            default: {
                                                WindowFactory.unimplementedMessage(" Event '" + item.module + "' ");
                                            }
                                        }
                                    }
                                default:
                                    {
                                        WindowFactory.unimplementedMessage(" Item type " + item.type);
                                    }
                            }
                        }
                    }

                    function setupEvent(evt) {
                        var event = evt; // local closure;
                        var left = projectTimeScale(event.date);
                        var points = [];
                        switch (event.type) {
                            case 'MovedToMaintenance':
                                points = createInvertedTrianglePoints(left, vm.statusTop, 8, 8);
                                break;

                            case 'MovedToProduction':
                                points = createTrianglePoints(left, vm.statusTop + vm.statusHeight, 8, 8);
                                break;

                            case "FIELDSURVEY":
                                points = createInvertedTrianglePoints(left, vm.statusTop + vm.statusHeight + 10, 8, 8);
                                break;

                            default:
                                points = createInvertedTrianglePoints(left, vm.statusTop, 8, 8);
                                break;
                        }
                        addPolygon(
                            "event " + event.type,
                            event.moveEventId,
                            points,
                            left,
                            vm.chartTop + 8,
                            [
                                { name: amtXlatSvc.xlat("equipment.eventDate") + ":", value: formatDate(event.date) },
                                { name: amtXlatSvc.xlat("equipment.eventType") + ":", value: amtXlatSvc.xlat("equipment.eventType" + event.type) }
                            ]
                            /* jshint ignore:start */
                            , function () {
                                openEvent(event);
                            }
                            /* jshint ignore:end */
                        );
                    }

                    function renderEvents() {
                        if ($scope.chartData.events) {
                            for (var e = 0; e < $scope.chartData.events.length; e++) {
                                setupEvent($scope.chartData.events[e]);
                            }
                        }
                    }

                    function addPolygon(cssClass, id, points, x, y, tooltip, clickEvent) {
                        vm.chartItems.push(
                            {
                                type: 'polygon',
                                points: renderPolygonPoints(points),
                                cssClass: cssClass,
                                onClick: clickEvent,
                                hoverStart: function () {
                                    hoverEvent(x, y, tooltip, 8);
                                },
                                hoverEnd: function () {
                                    hoverEvent();
                                },
                                order: 9
                            }
                        );
                    }

                    function addSeconds(d, seconds) {
                        return new Date(d + millisecondsPerSecond * seconds);
                    }

                    $scope.renderChart = function () {
                        vm.scale = {};
                        vm.chartItems = [];

                        vm.tooltip.visible = false;

                        if (!$scope.chartData) {
                            return;
                        }

                        calculateScale();

                        if (vm.chartWidth / (vm.scale.duration / millisecondsPerDay) >= vm.minDayWidth) {
                            vm.dayHeight = 20;
                        } else {
                            vm.dayHeight = 0;
                        }

                        updateHeaderDimentions();

                        vm.legendItems = [];

                        buildHeader();

                        renderStatuses();

                        renderEvents();

                        switch ($scope.view) {
                            case "Readings":
                                renderReadings();
                                break;
                            case "FitmentHistory":
                                renderHistoricalFitments();
                                break;
                        }
                    };

                    $scope.$watch("view", function () {
                        $scope.renderChart();
                    });

                    vm.chartRange = {
                        target: null,
                        x: 0,
                        y: vm.chartTop,
                        width: 0,
                        height: vm.chartHeight,
                        visible: false
                    };

                    $scope.mouseMove = function (e) {
                        if (vm.isMouseDown === true) {
                            if (e.offsetX > vm.chartRange.x) {
                                vm.chartRange.width = e.offsetX - vm.chartRange.x;
                            }

                            e.preventDefault();
                        }
                    };

                    function reparseDate(d) {
                        if (d == null) {
                            return d;
                        }

                        if (typeof d !== 'object') {
                            return new Date(d);
                        }

                        return d;
                    }

                    function setScaleDates(d) {
                        if (!d) {
                            return;
                        }

                        setScaleStart(d);
                        setScaleEnd(d);
                    }

                    function setScaleStart(d) {
                        if (!d) {
                            return;
                        }

                        d = reparseDate(d);

                        if (vm.scale.start === undefined || d < vm.scale.start) {
                            vm.scale.start = d;
                        }
                    }

                    function setScaleEnd(d) {
                        if (!d) {
                            return;
                        }

                        d = reparseDate(d);

                        if (vm.scale.end === undefined || d > vm.scale.end) {
                            vm.scale.end = d;
                        }
                    }

                    $scope.mouseUp = function (e) {
                        if (vm.isMouseDown === true) { // e.target == vm.chartRange.target) {
                            if (vm.chartRange.x + 10 < e.offsetX) {
                                $scope.zoomToDateRange(calculateDateAtX(vm.chartRange.x), calculateDateAtX(e.offsetX), true);
                            }

                            e.preventDefault();
                        }

                        vm.chartRange.target = null;
                        vm.chartRange.width = 0;
                        vm.isMouseDown = false;
                    };

                    vm.isMouseDown = false;

                    $scope.mouseDown = function (e) {
                        if (e.which === 1 && e.offsetX > vm.xAxisLabelWidth && e.offsetX < vm.xAxisLabelWidth + vm.chartWidth) {
                            if (vm.isMouseDown === false) {
                                vm.isMouseDown = true;
                                vm.chartRange.target = e.target;
                                vm.chartRange.x = e.offsetX;
                                vm.chartRange.width = 0;
                            }

                            e.preventDefault();
                        }

                        if (e.which === 3) {
                            $scope.undoLastZoom();

                            e.preventDefault();
                            return false;
                        }
                    };

                    $scope.$watch('chartData', function () {
                        if ($scope.chartData) {
                            $scope.renderChart();
                        }
                    });
                }],
                link: function (scope, element, attr) {
                    var template = '';

                    template += '<svg title="vm.tooltip" oncontextmenu="return false" preserveAspectRatio="none" ng-attr-width="{{width}}px" ng-attr-height="{{height}}px" ng-mousemove="mouseMove($event)" ng-mousedown="mouseDown($event)" ng-mouseup="mouseUp($event)" >';

                    template += '    <g class="chartRectangles">';
                    template += '         <oc-chart-rect ng-repeat="r in vm.chartItems|filter:{order: 1}" ng-if="r.type == \'rect\'" config="r" />';
                    template += '    </g>';

                    template += '    <g class="chartLines">';
                    template += '         <oc-chart-line ng-repeat="r in vm.chartItems|filter:{order: 3}" ng-if="r.type == \'line\'" config="r" />';
                    template += '    </g>';

                    template += '    <g class="chartText">';
                    template += '         <oc-chart-text ng-repeat="r in vm.chartItems|filter:{order: 5}" ng-if="r.type == \'text\'" config="r" />';
                    template += '    </g>';

                    // this is the rectangle which is displayed when the used holds the mouse button down and drags toward the right to selct the chart range
                    template += '    <rect ng-show="vm.chartRange.width > 0" class="chartRange" ng-attr-x="{{vm.chartRange.x}}" ng-attr-y="{{vm.chartTop}}" ng-attr-width="{{vm.chartRange.width}}" ng-attr-height="{{vm.chartHeight}}" />';

                    // add a rectangle which we used to get consistent coordinates for the chart range
                    template += '    <rect ng-attr-x="{{vm.xAxisLabelWidth}}" ng-attr-y="{{vm.chartTop}}" ng-attr-width="{{vm.chartWidth}}" ng-attr-height="{{vm.chartHeight}}" style="fill: white; opacity: 0.0;" ></rect>';

                    template += '    <g class="chartRectangles">';
                    template += '         <oc-chart-rect ng-repeat="r in vm.chartItems|filter:{order: 6}" ng-if="r.type == \'rect\'" config="r" />';
                    template += '    </g>';

                    template += '    <g class="chartLegend">';
                    template += '        <g ng-repeat="r in vm.legendItems" class="{{r}}">';
                    template += '            <rect class="boundingBox" ng-attr-x="{{(width - vm.scrollbarWidth) / vm.legendItems.length * $index }}" ng-attr-y="{{height - vm.legendHeight}}" ng-attr-width="{{(width - vm.scrollbarWidth) / vm.legendItems.length}}" ng-attr-height="{{vm.legendHeight}}" />';
                    template += '            <rect class="samplePatch" ng-if="r.indexOf(\'statusType_\') == 0" ng-attr-x="{{(width - vm.scrollbarWidth) / vm.legendItems.length * $index + 5}}" ng-attr-y="{{height - vm.legendHeight + 4}}" ng-attr-width="{{vm.legendHeight - 8}}" ng-attr-height="{{vm.legendHeight - 8}}" />';
                    template += '            <line class="samplePatch" ng-if="r.indexOf(\'readingType_\') == 0" ng-attr-x1="{{(width - vm.scrollbarWidth) / vm.legendItems.length * $index + 5}}" ng-attr-y1="{{height - vm.legendHeight / 2}}" ng-attr-x2="{{width / vm.legendItems.length * $index + 20}}" ng-attr-y2="{{height - vm.legendHeight / 2}}" />';
                    template += '            <g class="chartPoint" ng-if="r.indexOf(\'readingType_\') == 0"><circle ng-attr-cx="{{(width - vm.scrollbarWidth) / vm.legendItems.length * $index + 12.5}}" ng-attr-cy="{{height - vm.legendHeight / 2}}" r="3.5" /></g>';
                    template += '            <text ng-attr-x="{{(width - vm.scrollbarWidth) / vm.legendItems.length * $index + vm.legendHeight + 2}}" ng-attr-y="{{height - 5}}" >{{\'equipment.\' + r | xlat}}</text>';
                    template += '        </g>';
                    template += '    </g>';

                    // all the points
                    template += '    <g class="chartPoints">';
                    template += '        <g ng-repeat="r in vm.chartItems|filter:{order: 9}">';
                    template += '             <oc-chart-point ng-if="r.type == \'point\'" config="r" />';
                    template += '             <oc-chart-polygon ng-if="r.type == \'polygon\'" config="r" />';
                    template += '        </g>';
                    template += '    </g>';

                    template += '    <g class="chartScrollbars">';
                    template += '        <g ng-repeat="r in vm.chartItems|filter:{order: 15}">';
                    template += '             <oc-chart-scrollbar left="r.left" top="r.top" width="r.width" height="r.height" min="r.min" max="r.max" items-to-display="r.itemsToDisplay" value="r.value" config="config" >';
                    template += '        </g>';
                    template += '    </g>';

                    template += '    <g ng-show="vm.tooltip.visible && vm.tooltip.type == \'text\'">';
                    template += '        <rect class="chart-tooltip-background" ng-attr-x="{{vm.tooltip.x}}" ng-attr-y="{{vm.tooltip.y - 6}}" ng-attr-width="{{vm.tooltip.width + vm.tooltip.fontWidth}}" height="16" />';
                    template += '        <text class="chart-tooltip-string" ng-attr-x="{{vm.tooltip.x + vm.tooltip.width / 2}}" ng-attr-y="{{vm.tooltip.y + 6}}">{{vm.tooltip.text}}</text>';
                    template += '    </g>';

                    template += '    <g ng-show="vm.tooltip.visible && vm.tooltip.type == \'array\'">';
                    template += '        <rect class="chart-tooltip-background" ng-attr-x="{{vm.tooltip.x}}" ng-attr-y="{{vm.tooltip.y - 6}}" ng-attr-width="{{vm.tooltip.width + vm.tooltip.fontWidth}}" ng-attr-height="{{vm.tooltip.height + 4}}" />';
                    template += '        <text class="chart-tooltip-property" ng-repeat="t in vm.tooltip.values track by $index" ng-attr-x="{{vm.tooltip.x + vm.tooltipHeadingWidth * vm.tooltip.fontWidth + 2}}" ng-attr-width="{{vm.tooltipHeadingWidth * vm.tooltip.fontWidth}}" ng-attr-y="{{vm.tooltip.y + 4 + $index * vm.tooltip.fontHeight}}">{{vm.tooltip.values[$index].name}}</text>';
                    template += '        <text class="chart-tooltip-value" ng-repeat="t in vm.tooltip.values track by $index" ng-attr-x="{{vm.tooltip.x + vm.tooltipHeadingWidth * vm.tooltip.fontWidth + 4}}" ng-attr-width="{{vm.tooltipValueWidth}}" ng-attr-y="{{vm.tooltip.y + 4 + $index * vm.tooltip.fontHeight}}">{{vm.tooltip.values[$index].value}}</text>';
                    template += '    </g>';

                    // this rectangle puts a bounding box around the scrollable area
                    template += '    <rect ng-show="vm.scrollbarWidth > 0" class="chart-area" ng-attr-x="0" ng-attr-y="{{vm.chartTop}}" ng-attr-width="{{width}}" ng-attr-height="{{vm.chartHeight}}" ></rect>';


                    template += '</svg>';

                    var linkFn = $compile(template);
                    var content = linkFn(scope);

                    element.append(content);
                }
            };
        }]);
