/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var button_directive_1 = require("../button/button.directive");
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var button_service_1 = require("../button/button.service");
var util_1 = require("../util");
var preventable_event_1 = require("../preventable-event");
var package_metadata_1 = require("../package-metadata");
var kendo_licensing_1 = require("@progress/kendo-licensing");
/**
 * @hidden
 */
var tabindex = 'tabindex';
/**
 * Represents the Kendo UI ButtonGroup component for Angular.
 */
var ButtonGroupComponent = /** @class */ (function () {
    function ButtonGroupComponent(service, localization, element) {
        var _this = this;
        this.service = service;
        this.element = element;
        /**
         * By default, the selection mode of the ButtonGroup is set to `multiple`.
         */
        this.selection = 'multiple';
        /**
         * Fires every time keyboard navigation occurs.
         */
        this.navigate = new core_1.EventEmitter();
        this._tabIndex = 0;
        this.currentTabIndex = 0;
        kendo_licensing_1.validatePackage(package_metadata_1.packageMetadata);
        this.localizationChangeSubscription = localization.changes.subscribe(function (_a) {
            var rtl = _a.rtl;
            return _this.direction = rtl ? 'rtl' : 'ltr';
        });
    }
    Object.defineProperty(ButtonGroupComponent.prototype, "tabIndex", {
        get: function () {
            return this._tabIndex;
        },
        /**
         * Specifies the [`tabIndex`](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex) of the component.
         */
        set: function (value) {
            this._tabIndex = value;
            this.currentTabIndex = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonGroupComponent.prototype, "wrapperClass", {
        get: function () {
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonGroupComponent.prototype, "disabledClass", {
        get: function () {
            return this.disabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonGroupComponent.prototype, "stretchedClass", {
        get: function () {
            return !!this.width;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonGroupComponent.prototype, "getRole", {
        get: function () {
            return this.isSelectionSingle() ? 'radiogroup' : 'group';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonGroupComponent.prototype, "dir", {
        get: function () {
            return this.direction;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonGroupComponent.prototype, "ariaDisabled", {
        get: function () {
            return this.disabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonGroupComponent.prototype, "wrapperWidth", {
        get: function () {
            return this.width;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonGroupComponent.prototype, "wrapperTabIndex", {
        get: function () {
            return this.disabled ? undefined : this.currentTabIndex;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @hidden
     */
    ButtonGroupComponent.prototype.keydown = function (event) {
        if (!this.disabled) {
            this.navigateFocus(event);
        }
    };
    /**
     * @hidden
     */
    ButtonGroupComponent.prototype.onFocus = function () {
        this.currentTabIndex = -1;
        var focusedIndex = this.buttons.toArray().findIndex(function (current) { return current.element.tabIndex !== -1; });
        var index = focusedIndex === -1 ? 0 : focusedIndex;
        this.focus(this.buttons.filter(function (_current, i) {
            return i === index;
        }));
    };
    /**
     * @hidden
     */
    ButtonGroupComponent.prototype.focusout = function (event) {
        if (event.relatedTarget && event.relatedTarget.parentNode !== this.element.nativeElement) {
            this.defocus(this.buttons.toArray());
            this.currentTabIndex = this.tabIndex;
        }
    };
    ButtonGroupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription = this.service.buttonClicked$.subscribe(function (button) {
            var newSelectionValue;
            if (_this.isSelectionSingle()) {
                newSelectionValue = true;
                _this.deactivate(_this.buttons.filter(function (current) { return current !== button; }));
            }
            else {
                _this.defocus(_this.buttons.toArray());
                newSelectionValue = !button.selected;
            }
            if (button.togglable) {
                button.setSelected(newSelectionValue);
            }
            button.setAttribute(tabindex, '0');
        });
    };
    ButtonGroupComponent.prototype.ngOnChanges = function (change) {
        var _this = this;
        if (kendo_angular_common_1.isChanged('disabled', change)) {
            this.buttons.forEach(function (button) {
                if (util_1.isPresent(_this.disabled)) {
                    button.disabled = _this.disabled;
                }
            });
        }
    };
    ButtonGroupComponent.prototype.ngAfterContentInit = function () {
        this.buttons.forEach(function (button) {
            if (button.selected) {
                button.setAttribute(tabindex, '0');
            }
            else {
                button.setAttribute(tabindex, '-1');
            }
        });
    };
    ButtonGroupComponent.prototype.ngAfterViewChecked = function () {
        if (this.buttons.length) {
            this.buttons.first.renderer.addClass(this.buttons.first.element, 'k-group-start');
            this.buttons.last.renderer.addClass(this.buttons.last.element, 'k-group-end');
        }
    };
    ButtonGroupComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
        this.localizationChangeSubscription.unsubscribe();
    };
    ButtonGroupComponent.prototype.ngAfterContentChecked = function () {
        this.verifySettings();
    };
    ButtonGroupComponent.prototype.navigateFocus = function (event) {
        var focusedIndex = this.buttons.toArray().findIndex(function (current) { return current.element.tabIndex !== -1; });
        var firstIndex = 0;
        var lastIndex = this.buttons.length - 1;
        var eventArgs = new preventable_event_1.PreventableEvent();
        if (event.keyCode === kendo_angular_common_1.Keys.ArrowRight && focusedIndex < lastIndex) {
            this.navigate.emit(eventArgs);
            if (!eventArgs.isDefaultPrevented()) {
                this.defocus(this.buttons.toArray());
                this.focus(this.buttons.filter(function (_current, index) {
                    return index === focusedIndex + 1;
                }));
            }
        }
        if (event.keyCode === kendo_angular_common_1.Keys.ArrowLeft && focusedIndex > firstIndex) {
            this.navigate.emit(eventArgs);
            if (!eventArgs.isDefaultPrevented()) {
                this.defocus(this.buttons.toArray());
                this.focus(this.buttons.filter(function (_current, index) {
                    return index === focusedIndex - 1;
                }));
            }
        }
    };
    ButtonGroupComponent.prototype.deactivate = function (buttons) {
        buttons.forEach(function (button) {
            button.setSelected(false);
            button.setAttribute(tabindex, '-1');
        });
    };
    ButtonGroupComponent.prototype.activate = function (buttons) {
        buttons.forEach(function (button) {
            button.setSelected(true);
            button.setAttribute(tabindex, '0');
            button.focus();
        });
    };
    ButtonGroupComponent.prototype.defocus = function (buttons) {
        buttons.forEach(function (button) {
            button.setAttribute(tabindex, '-1');
        });
    };
    ButtonGroupComponent.prototype.focus = function (buttons) {
        buttons.forEach(function (button) {
            button.setAttribute(tabindex, '0');
            button.focus();
        });
    };
    ButtonGroupComponent.prototype.verifySettings = function () {
        if (core_1.isDevMode()) {
            if (this.isSelectionSingle() && this.buttons.filter(function (button) { return button.selected; }).length > 1) {
                throw new Error('Having multiple selected buttons with single selection mode is not supported');
            }
        }
    };
    ButtonGroupComponent.prototype.isSelectionSingle = function () {
        return this.selection === 'single';
    };
    tslib_1.__decorate([
        core_1.Input('disabled'),
        tslib_1.__metadata("design:type", Boolean)
    ], ButtonGroupComponent.prototype, "disabled", void 0);
    tslib_1.__decorate([
        core_1.Input('selection'),
        tslib_1.__metadata("design:type", String)
    ], ButtonGroupComponent.prototype, "selection", void 0);
    tslib_1.__decorate([
        core_1.Input('width'),
        tslib_1.__metadata("design:type", String)
    ], ButtonGroupComponent.prototype, "width", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number),
        tslib_1.__metadata("design:paramtypes", [Number])
    ], ButtonGroupComponent.prototype, "tabIndex", null);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ButtonGroupComponent.prototype, "navigate", void 0);
    tslib_1.__decorate([
        core_1.ContentChildren(button_directive_1.ButtonDirective),
        tslib_1.__metadata("design:type", core_1.QueryList)
    ], ButtonGroupComponent.prototype, "buttons", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-button-group'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ButtonGroupComponent.prototype, "wrapperClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-disabled'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ButtonGroupComponent.prototype, "disabledClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-button-group-stretched'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ButtonGroupComponent.prototype, "stretchedClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.role'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], ButtonGroupComponent.prototype, "getRole", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.dir'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], ButtonGroupComponent.prototype, "dir", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.aria-disabled'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ButtonGroupComponent.prototype, "ariaDisabled", null);
    tslib_1.__decorate([
        core_1.HostBinding('style.width'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], ButtonGroupComponent.prototype, "wrapperWidth", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.tabindex'),
        tslib_1.__metadata("design:type", Number),
        tslib_1.__metadata("design:paramtypes", [])
    ], ButtonGroupComponent.prototype, "wrapperTabIndex", null);
    tslib_1.__decorate([
        core_1.HostListener('keydown', ['$event']),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ButtonGroupComponent.prototype, "keydown", null);
    tslib_1.__decorate([
        core_1.HostListener('focus'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", void 0)
    ], ButtonGroupComponent.prototype, "onFocus", null);
    tslib_1.__decorate([
        core_1.HostListener('focusout', ['$event']),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ButtonGroupComponent.prototype, "focusout", null);
    ButtonGroupComponent = tslib_1.__decorate([
        core_1.Component({
            exportAs: 'kendoButtonGroup',
            providers: [
                button_service_1.KendoButtonService,
                kendo_angular_l10n_1.LocalizationService,
                {
                    provide: kendo_angular_l10n_1.L10N_PREFIX,
                    useValue: 'kendo.buttongroup'
                }
            ],
            selector: 'kendo-buttongroup',
            template: "\n        <ng-content select=\"[kendoButton]\"></ng-content>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [button_service_1.KendoButtonService,
            kendo_angular_l10n_1.LocalizationService,
            core_1.ElementRef])
    ], ButtonGroupComponent);
    return ButtonGroupComponent;
}());
exports.ButtonGroupComponent = ButtonGroupComponent;
