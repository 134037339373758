//import angular from 'angular';
import tmpl from './ocVehicleSchematicRect.html';



angular.module('app.directives').directive('ocVehicleSchematicRect',
            function () {
                return {
                    replace: true,
                    templateNamespace: 'svg',
                    scope: {
                        config: "="
                    },         
                    controller: ['$scope', function ($scope) {
                        //formerly supported multiline text though it wasn't used, handle being passed an array of text (multiline) by concat just in case
                        $scope.config.text = Array.isArray($scope.config.text) ? $scope.config.text.join(' ') : $scope.config.text;
                    }],
                    template: tmpl
                };
            }
        );
