
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IVisualInspectionAction, IVisualInspectionCause, IVisualInspectionFrequency } from '../interfaces/visualInspection.interfaces';
import DbClass from '../modules/common/local-db';
import BrowserSvc from './browserSvc';
import { CacheKey, CacheService } from './cache.service';
import HelperSvc from './helperSvc';
import OcConfigSvc from './ocConfigSvc';

@Injectable({
    providedIn: 'root'
})
export class VisualInspectionReferenceService {

    constructor(
        private browserSvc: BrowserSvc,
        private cacheService: CacheService,
        private http: HttpClient,
        private $db: DbClass,
        private helperSvc: HelperSvc,
        private ocConfigSvc: OcConfigSvc) { }

    public get actions$(): Observable<IVisualInspectionAction[]> {
        return this.browserSvc.isMobile ?
            from(this.$db.visualInspectionActions.toCollection().sortBy('daysToResolve')) :
            this.cacheService.getCacheItem(CacheKey.VisualInspectionAction, this.http.get(baseApiUrl + 'referenceData/getVisualInspectionActions')).pipe(
                map(a => a.result)
            );
    }

    public get frequencies$(): Observable<IVisualInspectionFrequency[]> {
        return of(this.ocConfigSvc.user.site.settings.referenceData.visualInspectionFrequencies).pipe(
            map(result => {
                let frequencies: IVisualInspectionFrequency[] = [];

                for (let fr of result || []) {
                    frequencies.push({
                        days: fr,
                        description: this.helperSvc.frequencyDaysToText(fr)
                    })
                };

                return frequencies;
            })
        );
    }

    public get causes$(): Observable<IVisualInspectionCause[]> {
        return this.browserSvc.isMobile ?
            from(this.$db.visualInspectionCauses.toArray()) :
            this.cacheService.getCacheItem(CacheKey.VisualInspectionCause, this.http.get(baseApiUrl + 'referenceData/getVisualInspectionCauses')).pipe(
                map(c => c.result)
            );
    }

    public reloadCauses() { this.cacheService.forceReload(CacheKey.VisualInspectionCause); }
    public reloadActions() { this.cacheService.forceReload(CacheKey.VisualInspectionAction); }
    public reloadFrequencies() { this.cacheService.forceReload(CacheKey.VisualInspectionFrequency); }

    public getCausesByEquipmentTypeId$(equipmentTypeId: guid) {
        return this.causes$.pipe(
            map(causes => causes.filter(cause => cause.equipmentTypeId === equipmentTypeId))
        );
    }
}

angular.module('app.component').factory('visualInspectionReferenceService', downgradeInjectable(VisualInspectionReferenceService) as any);