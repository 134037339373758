//import angular from 'angular';
import OcDateSvc from '../../../services/ocDateSvc';
import OcConfigSvc from '../../../services/ocConfigSvc';
import AdminUrls from '../../admin/adminUrls';
import tmpl from './componentDetails.html';



    angular.module('app.component').component('componentDetails',
        {
            bindings: {
                initParams: '=',
                buttonMethods: '=',
                buttonStates: '=',
                buttons: '=',
                closeOnSave: '=',
                wnd: '='
            },
            template: tmpl,
            controller: ['$scope', 'helperSvc', 'amtCommandQuerySvc', 'componentUrls', 'adminUrls', 'referenceDataUrls', 'enums',
                'amtXlatSvc', 'confirmSvc', 'WindowFactory', 'ocConfigSvc', 'notifySvc', 'ocSecuritySvc', 'errorReporter', 'ocDateSvc', 'dataBroker',
                function ($scope, helperSvc: IHelperSvc, amtCommandQuerySvc: IAmtCommandQuerySvc, componentUrls, adminUrls: AdminUrls, referenceDataUrls, enums,
                    xlatSvc: IAmtXlatSvc, confirmSvc: IConfirmSvc, WindowFactory: IWindowFactory, ocConfigSvc: OcConfigSvc, notifySvc: INotifySvc, ocSecuritySvc: IOcSecuritySvc, errorReporter: IErrorReporter,
                    ocDateSvc: OcDateSvc, dataBroker: IDataBroker) {

                    var vm = this;
                    $scope.vm = this; // necessary for child directives to access what they need.

                    // security                        
                    vm.readonly_details = !ocSecuritySvc.isAuthorised('Security.Components', AccessTypes.readWrite); // details tab is read-only

                    vm.canViewStatusChange = ocSecuritySvc.isAuthorised('Security.Components.ChangeStatus', AccessTypes.readOnly);
                    vm.canViewHistoryReport = ocSecuritySvc.isAuthorised('Security.Reports.HistoryReport', AccessTypes.readOnly);
                    vm.canViewVehicles = ocSecuritySvc.isAuthorised('Security.Vehicles', AccessTypes.readOnly);

                    vm.locationDate = ocDateSvc.removeLocalTimeZoneOffset(new Date());

                    vm.forms = {
                        main: {},
                        details: {}
                    };

                    vm.apiUrls = componentUrls;
                    vm.adminUrls = adminUrls;
                    vm.referenceDataUrls = referenceDataUrls;

                    vm.config = ocConfigSvc;
                    vm.costPerDistance = 0;
                    vm.costPerHour = 0;
                    vm.status = "loading";

                    vm.showComponentOwner = vm.config.user.site.settings.enforceComponentOwner;
                    vm.allowPressureSensors = vm.config.user.site.settings.usePressureSensors;
                    vm.showNitrogenFilled = vm.config.user.site.settings.tyreMaintenance.useNitrogen;
                    vm.enforceStoreLocation = vm.config.user.site.settings.tyreMaintenance.storeLocation;

                    vm.currentTab = "details";
                    vm.activeTab = 0;
                    vm.autoloadVi = false;
                    vm.showSaveButton = true;


                    this.$onInit = function () {
                        vm.equipmentId = vm.initParams.equipmentId;
                        vm.siteId = vm.initParams.siteId;

                        vm.buttonStates = {
                            saveButton: {
                                visible: true,
                                disabled: true
                            },
                            refreshButton: {
                                visible: true,
                                disabled: false
                            },
                            addButton: {
                                visible: false,
                                disabled: false
                            },
                            // Action buttons don't have dynamic disable/enable, don't bother including it
                            viewEventButton: {
                                visible: false
                            },
                            exportButton: {
                                visible: false
                            },
                            historyButton: {
                                visible: true
                            }
                        };

                        vm.buttonMethods = {
                            updateDetails: vm.updateDetails
                        };

                        if (vm.equipmentId !== null) {
                            vm.loadDetails();
                        }

                        vm.wnd.onClose = $scope.onClose;

                        if (typeof (vm.initParams.showVi) !== 'undefined') {
                            vm.autoloadVi = true;
                            vm.activeTab = 2;
                            vm.selectTab(vm.tabs.visualInspection);
                        }
                    };

                    vm.selectTab = function (tabName) {
                        vm.currentTab = tabName;
                        vm.readonly = vm.currentTab.readonly;

                        $scope.onRefresh();
                    };

                    $scope.openModule = function (component, titleResourceKey, module, initParams, width, height, modal, onDataChange, canClose?, canMinimise?) {
                        WindowFactory.openItem({
                            component: component,
                            caption: titleResourceKey,
                            //module: module,
                            initParams: initParams,
                            parentWindow: vm.wnd,
                            width: width,
                            height: height,
                            modal: (modal !== undefined ? modal : true),
                            canClose: canClose,
                            canMinimise: canMinimise,
                            onDataChangeHandler: onDataChange
                        });
                    };

                    // load the edit event details window
                    $scope.showEventDetails = function (equipmentEventId, editMode, target) {
                        if (editMode === 'Move') {
                            WindowFactory.openItem({
                                component: 'change-status-popup',
                                caption: xlatSvc.xlat('equipment.editStatusChangeEvent'),
                                width: 1400,
                                initParams: {
                                    equipmentId: vm.equipmentId,
                                    equipmentEventId: equipmentEventId,
                                    isChangeStatus: false,
                                    isEdit: true,
                                    isBulkMove: false,
                                    siteId: vm.siteId
                                },
                                modal: true,
                                onDataChangeHandler: $scope.onRefresh
                            });
                        }
                    };

                    $scope.showVehicleDetails = function (item) {
                        if (item.fitmentData && item.fitmentData.vehicleId) {
                            WindowFactory.openItem({
                                component: 'vehicle-details',
                                caption: xlatSvc.xlat('equipment.openVehicle', item.fitmentData.formattedVehicleSerialNumber),
                                windowRelatedRecordId: item.fitmentData.vehicleId,
                                initParams: {
                                    equipmentId: item.fitmentData.vehicleId,
                                    siteId: item.siteId,
                                    serialNumber: item.fitmentData.formattedVehicleSerialNumber,
                                    wheelPostionId: item.fitmentData.wheelPositionId, //TODO: change the schematic js to select this position when the vehicle details open
                                },
                                width: 800,
                                height: 850,
                                modal: false
                            });
                        }
                    };

                    // details tab
                    vm.loadDetails = async function () {

                        vm.wnd.processing = true;

                        let criteria = {
                            equipmentId: vm.equipmentId
                        };

                        try {

                            let response = await amtCommandQuerySvc.post(vm.apiUrls.getComponentDetails, criteria);

                            if (response) {

                                vm.component = response;

                                if (vm.component.hasCasing && vm.allowPressureSensors)
                                    await vm.loadPressureSensors();

                                vm.showSiteSerialNumber = vm.config.user.site.settings.tyreMaintenance.siteSerialNumber[response.componentType.name.toLowerCase()];

                                if (vm.showSiteSerialNumber === 'Hide') {
                                    vm.component.serialNumber.formatted = vm.component.serialNumber.manufacturer;
                                }

                                if (vm.component.totalCost > 0) {
                                    if (vm.component.distance.value > 0) {
                                        vm.costPerDistance = vm.component.totalCost / vm.component.distance.value;
                                    }

                                    if (vm.component.hours.value > 0) {
                                        vm.costPerHour = vm.component.totalCost / vm.component.hours.value;
                                    }
                                }

                                vm.wnd.caption = xlatSvc.xlat('equipment.open' + vm.component.componentType.name, vm.component.serialNumber.formatted);
                                vm.wnd.shortCaption = vm.wnd.caption;

                                vm.selectTab(vm.tabs.details);
                                vm.status = "ready";
                            }
                        } catch (error) {
                            vm.status = "error";
                            errorReporter.logError(error);
                        } finally {
                            vm.wnd.processing = false;
                        }
                    };

                    vm.loadPressureSensors = async function () {

                        let sensorCriteria: IPressureSensorCriteria = {
                            equipmentId: vm.equipmentId,
                            siteId: vm.siteId
                        };

                        try {
                            let sensors = await dataBroker.getPressureSensors(sensorCriteria);

                            vm.pressureSensors = sensors.result;

                            if (vm.component.pressureSensor) {
                                let sensor = vm.pressureSensors.find(s => s.key === vm.component.pressureSensor.key);

                                if (sensor)
                                    vm.component.pressureSensor = sensor;
                            }

                        } catch (error) {
                            errorReporter.logError(error, 'ComponentDetails-GetPressureSensors');
                        }
                    };

                    vm.updateDetails = function () {

                        vm.wnd.processing = true;

                        vm.updateCriteria = {
                            equipmentId: vm.equipmentId,
                            siteId: vm.component.siteId,
                            manufacturerSerialNumber: vm.component.serialNumber.manufacturer,
                            siteSerialNumber: vm.component.serialNumber.site,
                            obsolete: vm.component.obsolete,
                            equipmentTypeSpecificationTypeId: helperSvc.getKey(vm.component.specification),
                            locationId: helperSvc.getKey(vm.component.location),
                            equipmentStatusId: vm.component.equipmentStatusId,
                            excludeFromPerformanceAnalysis: vm.component.excludeFromPerformanceAnalysis,
                            excludeFromPerformanceAnalysisReason: vm.component.excludeFromPerformanceAnalysisReason,
                            componentOwnerId: helperSvc.getKey(vm.component.componentOwner),
                            pressureSensorId: helperSvc.getKey(vm.component.pressureSensor),
                            nitrogenFilled: (vm.component.fitmentData !== null ? vm.component.fitmentData.nitrogenFilled : null)
                        };

                        amtCommandQuerySvc.post(vm.apiUrls.updateComponentDetails, vm.updateCriteria).then(function () {
                            notifySvc.success(xlatSvc.xlat("component.saveSuccessful"));
                            $scope.$parent.onDataChanged();
                            closeWindow();
                        }, amtCommandQuerySvc.handleError).finally(function () {
                            vm.wnd.processing = false;
                        });
                    };

                    vm.onExcludeFromPerformanceAnalysisChange = function (item) {
                        if (item === false) {
                            vm.component.excludeFromPerformanceAnalysisReason = null;
                        }
                    };

                    // visual inspection tab

                    vm.visualInspectionGrid = {};

                    // global

                    $scope.setForm = function () {
                        // add a watch to the dirty flag
                        $scope.$watch("vm.forms.details.$dirty", function (newValue) {
                            if (vm.currentTab === vm.tabs.details) {
                                vm.wnd.isDirty = newValue;
                            }
                        });
                    };

                    function closeWindow() {
                        WindowFactory.closeWindow(vm.wnd);
                    }

                    $scope.onClose = function () {
                        confirmSvc.confirmSaveChange(vm.wnd.isDirty).then(function () {
                            vm.forms.details.$dirty = false;
                            closeWindow();
                            return true;
                        });

                        return false;
                    };

                    vm.exportVisualInspectionsToExcel = function () {
                        console.log("export results to to excel.");
                    };

                    vm.exportVisualInspectionsToWord = function () {
                        console.log("export results to to word.");
                    };

                    $scope.actionClick = function (action) {
                        action.click();
                    };

                    vm.tabs = {
                        details: {
                            refreshSearch: true,
                            canSave: !vm.readonly_details,
                            readonly: vm.readonly_details
                        },
                        history: {
                            refreshSearch: false
                        },
                        visualInspection: {},
                        attachment: {},
                        comment: {}
                    };

                    $scope.onSave = function () {
                        if (vm.currentTab.save !== undefined) {
                            vm.currentTab.save();
                        }
                    };

                    $scope.onExport = function (format) {
                        if (vm.currentTab.export !== undefined) {
                            vm.currentTab.export(format);
                        }
                    };

                    $scope.onAdd = function () {
                        if (vm.currentTab.add !== undefined) {
                            vm.currentTab.add();
                        }
                    };

                    $scope.onRefresh = function () {
                        if (vm.currentTab.refresh !== undefined) {
                            vm.currentTab.refresh();
                        }

                        // removed this because it was causing the search to refresh when a component opens, which is unnecessary 
                        //if (vm.currentTab.refreshSearch) {
                        //	$rootScope.$broadcast('filterSearch');
                        //}
                    };

                    $scope.$on('refreshEquipmentDetails', function (event, equipmentId, equipmentExists) {
                        if (vm.equipmentId === equipmentId) {

                            if (equipmentExists === true) {
                                vm.loadDetails();
                            } else {
                                closeWindow();
                            }
                        }
                    });

                    $scope.$watch('vm.component', function () {
                        vm.buttonStates.historyButton.visible = vm.component && vm.canViewHistoryReport;
                    }, true);

                    $scope.$watch('$ctrl.forms.details.$dirty', function () {
                        determineSaveButtonState();
                    });

                    $scope.$watch('$ctrl.forms.details.$invalid', function () {
                        determineSaveButtonState();
                    });

                    $scope.$watch('vm.wnd.processing', function () {
                        determineSaveButtonState();
                    });

                    $scope.$watch('vm.currentTab.canSave', function () {
                        vm.buttonStates.saveButton.visible = vm.currentTab && !!vm.currentTab.canSave;
                    });

                    $scope.$watch('vm.currentTab.canRefresh', function () {
                        vm.buttonStates.refreshButton.visible = vm.currentTab && !!vm.currentTab.canRefresh;
                    });

                    $scope.$watch('vm.currentTab.canExport', function () {
                        vm.buttonStates.exportButton.visible = vm.currentTab && !!vm.currentTab.canExport;
                    });

                    $scope.$watch('vm.currentTab.canViewEvent', function () {
                        vm.buttonStates.viewEventButton.visible = vm.currentTab && !!vm.currentTab.canViewEvent;
                    });

                    $scope.$watch('vm.currentTab.canAdd', function () {
                        vm.buttonStates.addButton.visible = vm.currentTab && !!vm.currentTab.canAdd;
                    });

                    // Update which buttons are shown on the dialog
                    // remarks: currentTab is set after getting a response from the server w/ component details and before status is changed to ready
                    $scope.$watch('vm.currentTab', function () {
                        if (vm.component) {
                            switch (vm.currentTab) {
                                case vm.tabs.details:
                                    vm.buttons = [
                                        {
                                            primary: true,
                                            cancel: false,
                                            value: 'common.save_label',
                                            name: 'saveButton',
                                            click: 'updateDetails',
                                            type: enums.buttonTypes.button
                                        }];
                                    break;
                                case vm.tabs.history:
                                    vm.buttons = [
                                        {
                                            primary: true,
                                            cancel: false,
                                            value: 'common.refresh',
                                            name: 'refreshButton',
                                            click: 'refresh',
                                            type: enums.buttonTypes.button
                                        },
                                        {
                                            title: 'equipment.viewEvent',
                                            click: 'viewEvent',
                                            name: 'viewEventButton',
                                            type: enums.buttonTypes.action
                                        },
                                        {
                                            title: 'common.exportToExcel',
                                            click: 'export',
                                            name: 'exportButton',
                                            type: enums.buttonTypes.action
                                        }];
                                    break;
                                case vm.tabs.visualInspection:
                                    vm.buttons = [
                                        {
                                            primary: true,
                                            cancel: false,
                                            value: 'common.add_label',
                                            name: 'addButton',
                                            click: 'add',
                                            type: enums.buttonTypes.button
                                        }];
                                    break;
                                case vm.tabs.attachment:
                                case vm.tabs.comment:
                                    vm.buttons = [
                                        {
                                            primary: true,
                                            cancel: false,
                                            value: 'common.add_label',
                                            name: 'addButton',
                                            click: 'add',
                                            type: enums.buttonTypes.button
                                        },
                                        {
                                            title: 'common.exportToExcel',
                                            click: 'export',
                                            name: 'exportButton',
                                            type: enums.buttonTypes.action
                                        }];
                                    break;
                                default:
                                    vm.buttons = [];
                            }
                        } else {
                            vm.buttons = [];
                        }

                        if (vm.currentTab) {
                            if (vm.currentTab.add) {
                                vm.buttonMethods.add = vm.currentTab.add;
                            }

                            if (vm.currentTab.refresh) {
                                vm.buttonMethods.refresh = vm.currentTab.refresh;
                            }

                            if (vm.currentTab.export) {
                                vm.buttonMethods.export = vm.currentTab.export;
                            }

                            if (vm.currentTab.viewEvent) {
                                vm.buttonMethods.viewEvent = vm.currentTab.viewEvent;
                            }
                        }
                    });

                    function determineSaveButtonState() {
                        if (vm.forms.details.$invalid || vm.wnd.processing) {
                            // Can't save if the form is invalid
                            vm.buttonStates.saveButton.disabled = true;
                        } else {
                            // Can only save if the form is dirty
                            vm.buttonStates.saveButton.disabled = !vm.forms.details.$dirty;
                        }
                    }
                }
            ]
        });
