/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * @hidden
 */
var GroupIndicatorComponent = /** @class */ (function () {
    function GroupIndicatorComponent() {
        this.directionChange = new core_1.EventEmitter();
        this.remove = new core_1.EventEmitter();
    }
    Object.defineProperty(GroupIndicatorComponent.prototype, "groupIndicatorClass", {
        get: function () {
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GroupIndicatorComponent.prototype, "dir", {
        get: function () {
            return this.group.dir ? this.group.dir : "asc";
        },
        enumerable: true,
        configurable: true
    });
    GroupIndicatorComponent.prototype.toggleDirection = function () {
        this.directionChange.emit({
            dir: this.dir === "asc" ? "desc" : "asc",
            field: this.group.field
        });
        return false;
    };
    GroupIndicatorComponent.prototype.removeDescriptor = function () {
        this.remove.emit({
            dir: this.group.dir,
            field: this.group.field
        });
        return false;
    };
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], GroupIndicatorComponent.prototype, "directionChange", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], GroupIndicatorComponent.prototype, "remove", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], GroupIndicatorComponent.prototype, "group", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], GroupIndicatorComponent.prototype, "groupTitle", void 0);
    tslib_1.__decorate([
        core_1.HostBinding("class.k-group-indicator"),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], GroupIndicatorComponent.prototype, "groupIndicatorClass", null);
    GroupIndicatorComponent = tslib_1.__decorate([
        core_1.Component({
            changeDetection: core_1.ChangeDetectionStrategy.OnPush,
            selector: '[kendoGroupIndicator]',
            template: "\n        <a href=\"#\" class=\"k-link\" tabindex=\"-1\" (click)=\"toggleDirection()\">\n            <span class=\"k-icon\"\n                [class.k-i-sort-asc-sm]=\"dir === 'asc'\"\n                [class.k-i-sort-desc-sm]=\"dir === 'desc'\"></span>\n            {{groupTitle}}</a>\n        <a class=\"k-button k-button-flat-base k-button-flat k-icon-button k-button-md k-rounded-md k-button-rectangle k-icon-button\" tabindex=\"-1\" (click)=\"removeDescriptor()\">\n            <span class=\"k-button-icon k-icon k-i-group-delete\"></span>\n        </a>\n    "
        })
    ], GroupIndicatorComponent);
    return GroupIndicatorComponent;
}());
exports.GroupIndicatorComponent = GroupIndicatorComponent;
