/**
 * @ngdoc directive
 * @name amtFramework.formControls.amtTabValidity
  * @restrict E
 * 
 * @description
 * Wrapper your tab's content with this directive to make the tab's state changes which affect by form invalid
 * @example
 * <doc:example module="amtFramework.formControls">
 *   <doc:source>
 *      <tabset>
 *       <tab heading="Tab 1">
 *          <p>The value in below is binding to the same model with textbox on the "Tab 2".</p>
 *                  <input ng-model="inputValue"/>
 *      </tab>
 *       <tab heading="Tab 2">
 *           <amt-tab-validity form="formValidity">
 *              <ng-form name="formValidity">
 *                      <input ng-model="inputValue" ng-required="true"/>
 *              </ng-form>
 *          </amt-tab-validity>
 *      </tab> 
 *      </tabset>
 *   </doc:source>
 * </doc:example>
*/

//import angular from 'angular';

angular.module('amtFramework.formControls').directive('amtTabValidity', function () {
            return {
                require: '^uibTabset',
                restrict: 'E',
                link: function (scope, element, attrs, ctrl) {

                    //todo: drive with css class
                    var RED_TEXT = {
                        KEY:'color',
                        VALUE:'red'
                    };
                    
                    var index = element.parent().index();

                    if (index < 0) {
                        throw new Error('"amtTabValidity" must be direct child of "tab"');
                    }
                    if (!attrs.form) {
                        throw new Error('form attribute on "amtTabValidity" is mandatory value');
                    }

                    scope.$watchCollection(attrs.form, function (newValue: any) {

                        if (!ctrl.tabs || !ctrl.tabs[index]) {
                            return;
                        }

                        var invalid = newValue && newValue.$invalid;

                        if (!ctrl.tabs[index].defaultHeading) {
                            ctrl.tabs[index].defaultHeading = ctrl.tabs[index].heading;
                        }
                        var tabItem = lookupTabItem(index);
                        if (!tabItem) {
                            return;
                        }
                        if (invalid) {
                            ctrl.tabs[index].heading = ctrl.tabs[index].defaultHeading + "*";
                            tabItem.css(RED_TEXT.KEY,RED_TEXT.VALUE);
                        } else {
                            ctrl.tabs[index].heading = ctrl.tabs[index].defaultHeading;
                            tabItem.css(RED_TEXT.KEY,'');
                        }

                    });

                    function lookupTabItem(tabIndex) {
                        var tabContent = element.closest('.tab-content').prev();
                        return tabContent.find('li:nth-child(' + (tabIndex + 1) + ') > a');
                    }
                }
            };
});
