/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var utils_1 = require("./utils");
var constants_1 = require("./constants");
/**
 * @hidden
 */
var ContrastComponent = /** @class */ (function () {
    function ContrastComponent(localization) {
        this.localization = localization;
    }
    Object.defineProperty(ContrastComponent.prototype, "formatedRatio", {
        get: function () {
            return this.contrastRatio.toFixed(2);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContrastComponent.prototype, "contrastRatioText", {
        get: function () {
            return this.localization.get('contrastRatio') + ": " + (this.value ? this.formatedRatio : 'n/a');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContrastComponent.prototype, "satisfiesAACondition", {
        get: function () {
            return this.contrastRatio >= constants_1.AA_RATIO;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContrastComponent.prototype, "satisfiesAAACondition", {
        get: function () {
            return this.contrastRatio >= constants_1.AAA_RATIO;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContrastComponent.prototype, "contrastRatio", {
        get: function () {
            var contrast = utils_1.getContrastFromTwoRGBAs(utils_1.getRGBA(this.value), utils_1.getRGBA(this.ratio));
            return contrast;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ContrastComponent.prototype, "value", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ContrastComponent.prototype, "ratio", void 0);
    ContrastComponent = tslib_1.__decorate([
        core_1.Component({
            selector: '[kendoContrastTool]',
            template: "\n        <div class=\"k-contrast-ratio\">\n            <span class=\"k-contrast-ratio-text\">{{contrastRatioText}}</span>\n            <ng-container *ngIf=\"value\">\n                <span class=\"k-contrast-validation k-text-success\" *ngIf=\"satisfiesAACondition\">\n                    <span class=\"k-icon k-i-check\"></span>\n                    <span class=\"k-icon k-i-check\" *ngIf=\"satisfiesAAACondition\"></span>\n                </span>\n                <span class=\"k-contrast-validation k-text-error\" *ngIf=\"!satisfiesAACondition\">\n                    <span class=\"k-icon k-i-close\"></span>\n                </span>\n            </ng-container>\n        </div>\n        <div kendoContrastValidation\n            type=\"AA\"\n            [value]=\"value\"\n            [pass]=\"satisfiesAACondition\">\n        </div>\n        <div kendoContrastValidation\n            type=\"AAA\"\n            [value]=\"value\"\n            [pass]=\"satisfiesAAACondition\">\n        </div>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService])
    ], ContrastComponent);
    return ContrastComponent;
}());
exports.ContrastComponent = ContrastComponent;
