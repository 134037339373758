//import angular from 'angular';
import AdminUrls from '../../adminUrls';

    angular.module('app.admin').controller("vehicleSpecificationsSearchCtrl", vehicleSpecificationsSearchCtrl);

    // @ts-ignore
    vehicleSpecificationsSearchCtrl.$inject = ["adminUrls", "$scope", "enums", "ocSecuritySvc", "amtXlatSvc", "$timeout", "WindowFactory", "ocConfigSvc",
        "exportSvc", "vehicleUrls", "amtCommandQuerySvc", "confirmSvc", "notifySvc", "errorReporter"];

    // ReSharper disable once InconsistentNaming
    function vehicleSpecificationsSearchCtrl(adminUrls: AdminUrls, $scope, enums, ocSecuritySvc, amtXlatSvc, $timeout, WindowFactory, ocConfigSvc,
        exportSvc, vehicleUrls, amtCommandQuerySvc, confirmSvc, notifySvc, errorReporter) {

        var vm = this;

        vm.processing = false;

        // security
        vm.canAddRemoveFromSite = ocSecuritySvc.isAuthorised('Security.Settings.SpecificationsVehicles', 'readWrite');

        vm.showClients = false;
        vm.showSites = false;

        vm.adminUrls = adminUrls;
        vm.apiUrls = vehicleUrls;
        vm.enums = enums;

        vm.config = ocConfigSvc;

        vm.sources = [{
            name: amtXlatSvc.xlat('specifications.onSite'),
            key: true
        }, {
            name: amtXlatSvc.xlat('specifications.centralLibrary'),
            key: false
        }];

        vm.status = enums.screenStatus.loading;
        vm.isDirty = true;

        vm.gridControl = {};
        vm.selectedItem = null;

        vm.obsoleteOptions = [{
            name: amtXlatSvc.xlat('common.yes_label'),
            key: true
        }, {
            name: amtXlatSvc.xlat('common.no_label'),
            key: false
            }];

        function init() {

            vm.canAdd = ocSecuritySvc.isAuthorised('Security.Settings.SpecificationsVehicles.Add', 'readWrite', null, null, true);

            vm.loadDefaults();
        }

        vm.onDatabound = function () {
            vm.selectedItem = null;
        };

        vm.onSelectedItemChanged = function (items) {
            if (items != null && items.length === 1) {
                vm.selectedItem = items[0];
            } else {
                vm.selectedItem = null;
            }
        };

        vm.setHeaderHeight = function (height, offset) {
            vm.gridTop = height + offset;
        };

        vm.loadDefaults = function () {

            vm.defaults = {
                source: vm.sources[0],
                obsoleteOptions: [vm.obsoleteOptions[1]]
            };

            vm.status = enums.screenStatus.ready;

            // focus on description field by default
            $timeout(() => { vm.focusDescription = true });
        };

        vm.export = function () {
            exportSvc.exportData(vm.adminUrls.searchVehicleSpecificationsExport, vm.criteria, amtXlatSvc.xlat('specifications.vehicleSearchTitle'));
        };

        vm.onSearchVehicleSpecifications = function (response) {

            var specifications = response.result;

            if (specifications && specifications.length > 0) {

                var yesLabel = amtXlatSvc.xlat('common.yes_label');
                var noLabel = amtXlatSvc.xlat('common.no_label');

                for (var r = 0; r < specifications.length; r++) {
                    specifications[r].onSiteDisplay = (specifications[r].onSite === true ? yesLabel : noLabel);
                    specifications[r].attachmentsDisplay = (specifications[r].attachments === 0 ? noLabel : yesLabel);
                }
            }

            return response;
        };

        $scope.editSpecification = function (item) {
            if (item) {
                WindowFactory.openItem({
                    component: 'vehicle-specification-details',
                    caption: item.specification,
                    initParams: {
                        id: item.id
                    },
                    width: 890,
                    height: 650,
                    onDataChangeHandler: vm.filter
                });
            }
        };

        $scope.addSpecification = function () {
            WindowFactory.openItem({
                component: 'vehicle-specification-details',
                caption: amtXlatSvc.xlat("specifications.addVehicleSpecification"),
                windowRelatedRecordId: uuid(),
                initParams: null,
                width: 890,
                height: 650,
                onDataChangeHandler: vm.filter
            });
        };

        $scope.addToSite = function (item) {
            confirmSvc.confirmMessage('specifications.addToSite_title', 'specifications.addToSite', vm.config.user.site.name).then(function () {
                vm.processing = true;

                amtCommandQuerySvc.get(vm.adminUrls.addSpecificationToSite, { equipmentTypeSpecificationTypeId: item.id }).then(function (response) {

                    notifySvc.success(amtXlatSvc.xlat('specifications.addedToSiteSuccessfully'));
                    vm.filter();

                }).catch(function (error) {
                    errorReporter.logError(error);
                }).finally(function () {
                    vm.processing = false;
                });
            });
        };

        $scope.removeFromSite = function (item) {
            confirmSvc.confirmMessage('specifications.removeFromSite_title', 'specifications.removeFromSite', vm.config.user.site.name).then(function () {
                vm.processing = true;

                amtCommandQuerySvc.get(vm.adminUrls.removeSpecificationFromSite, { equipmentTypeSpecificationTypeId: item.id }).then(function (response) {

                    notifySvc.success(amtXlatSvc.xlat('specifications.removedFromSiteSuccessfully'));
                    vm.filter();

                }).catch(function (error) {
                    errorReporter.logError(error);
                }).finally(function () {
                    vm.processing = false;
                });
            });
        };

        vm.refreshGrid = function () {
            vm.gridControl.refresh();
        };

        vm.filter = function () {
            vm.refreshGrid();
        };

        init();
    }
