import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Widget } from '../../service/Interface/SiteDashboard.interface';
import {LiveForecastDialogItem, LiveForecastRimDisplayOption, LiveForecastRimGroup, LiveForecastRimWidget, LiveForecastSelectedGroupCount } from '../../service/Interface/Widget.interface';
import { FilterConstant, ForecastUi } from '../../service/Interface/WidgetConstant.interface';
import { WidgetRxjsService } from '../../service/rxjs/WidgetRxjs.service';
import { WidgetConstant } from '../../service/widgetHelper/WidgetConstant';
import { Subscription } from 'rxjs';
import { LiveForecastRimWidgetHelper } from '../../service/widgetHelper/LiveForecastRimWidget.helper';
import { DialogRxjsService } from '../../service/rxjs/DialogRxjs.service';


@Component({
    selector: 'widget-live-forecast-rim',
    templateUrl: './live-forecast-rim.component.html',
    styleUrls: ['./../../site-dashboard.component.scss']
})


//Completed
export class SiteDashboardLiveForecastRimComponent implements OnInit, OnDestroy {
    public ui: ForecastUi;
    public resultSubscription: Subscription;
    public hasReceivedResult: boolean = false;
    public hasLiveForecast: boolean = false;
    @ViewChild("displaySelectionDropdownList", { static: true })
    public displaySelectionList: Array<string>;
    public selectedDisplay: string;
    public selectedRimGroup: LiveForecastRimGroup;
    public selectedGroupCount: LiveForecastSelectedGroupCount = {
        overDue: 0,
        next2Weeks: 0,
        next2To4Weeks: 0,
        next4To8Weeks: 0,
        next8To12Weeks: 0
    };
    public resultItem: LiveForecastRimWidget = {
        siteDashboardWidgetId: '',
        result: {
            hasLiveForecast: false,
            ndtRim: null,
            scrapRim: null,
            allRims: null
        }
    };

    constructor(private widgetRxjs: WidgetRxjsService, private constant: WidgetConstant, private liveForecastRimHelper: LiveForecastRimWidgetHelper, private dialogListener: DialogRxjsService) {
        this.ui = this.constant.getForecastWidgetUi();
    }

    @Input() set getWidgetDetail(widget: Widget) {
        this.resultItem.siteDashboardWidgetId = widget.siteDashboardWidgetId;
    }

    ngOnInit(){
        this.getLiveForecastRimWidgetResult();
    }

    ngOnDestroy() {
        this.resultSubscription.unsubscribe();
    }

    private getLiveForecastRimWidgetResult() {
        this.resultSubscription = this.widgetRxjs.liveForecastRimWidget.subscribe(
            widgets => {
                let hasRim = widgets.length > 0;
                if (hasRim) {
                    widgets.forEach(widget => {
                        if (widget.siteDashboardWidgetId === this.resultItem.siteDashboardWidgetId) {
                            this.setDefaultLandingLiveForecastRimWidget(widget);
                        }
                    });
                }
            });
    }

    private setDefaultLandingLiveForecastRimWidget(resultItem: LiveForecastRimWidget) {
        if (resultItem.result.hasLiveForecast) {
            this.resultItem = resultItem;
            this.displaySelectionList = this.liveForecastRimHelper.getDefaultSelectionDropDownMenu();
            this.selectedDisplay = this.liveForecastRimHelper.getDefaultSelectedDisplay();
            this.onChangeDisplay(this.selectedDisplay);
        }
        this.hasLiveForecast = resultItem.result.hasLiveForecast;
        this.hasReceivedResult = true;
    }


    public onChangeDisplay(selectedDisplay: string) {
        let displayOption: LiveForecastRimDisplayOption = this.liveForecastRimHelper.getValidatedDisplayOption(selectedDisplay);

        if (displayOption.isAllRim) {
            this.selectedRimGroup = this.resultItem.result.allRims;
            this.selectedGroupCount = this.liveForecastRimHelper.setLiveForecastGroupCount(this.selectedRimGroup);
        }

        if (displayOption.isNdtRim) {
            this.selectedRimGroup = this.resultItem.result.ndtRim;
            this.selectedGroupCount = this.liveForecastRimHelper.setLiveForecastGroupCount(this.selectedRimGroup);
        }

        if (displayOption.isScrapRim) {
            this.selectedRimGroup = this.resultItem.result.scrapRim;
            this.selectedGroupCount = this.liveForecastRimHelper.setLiveForecastGroupCount(this.selectedRimGroup);
        }
    }

    public async showLiveForecastRimDialog() {
        let action: LiveForecastDialogItem = {
            action: true,
            siteDashboardWidgetId: this.resultItem.siteDashboardWidgetId
        };
        this.dialogListener.setLiveForecastRimDialogActionListener(action);
    }
}