import './modules/site/siteDashboard/polyfills';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app.module';
import { NgZone } from '@angular/core';
import { UIRouter, UrlService } from '@uirouter/core';


$(document).ready(() => {
    platformBrowserDynamic().bootstrapModule(AppModule).then(platformRef => {
        // Intialize the Angular Module
        // get() the UIRouter instance from DI to initialize the router
        const urlService: UrlService = platformRef.injector.get(UIRouter).urlService;

        // Instruct UIRouter to listen to URL changes
        function startUIRouter() {
            urlService.listen();
            urlService.sync();
        }

        platformRef.injector.get<NgZone>(NgZone).run(startUIRouter);
    });
});
