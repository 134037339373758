//import angular from 'angular';
import tmpl from './EventsActionsMenu.html';



class maintenanceEventsActionsMenuCtrl implements ng.IController {
    public close: (object) => void;
    public dismiss: (object) => void;
    public resolve: {
        position: string;
        serial: string;
        componentType: string;
        menuItems: string[];
    };

    static $inject = ['$element', '$scope'];
    constructor($element: JQuery<HTMLElement>, $scope: ng.IScope) {
        $element.addClass('maintenance-events-actions-menu');

        //TODO: login page has no special z-order, otracom windows at hidden with an ng-show based off of a flag on rootscope, it really should have a z-order...
        //as a workaround will use the following to listen for the logout event and treat this as cancelling, this is a hack and should probably be removed if/when the login stuff is sorted
        $scope.$on('logout', () => {
            this.dismiss({});
        });
    }

    public onClick(index: number) {
        let item = this.resolve.menuItems[index];
        let action: string = item.substring(item.indexOf('.') + 1);
        this.close({$value: action});
    }
}

class maintenanceEventsActionsMenu implements ng.IComponentOptions {
    public bindings = {
        resolve: '<',
        close: '&',
        dismiss: '&',
    };
    public controller = maintenanceEventsActionsMenuCtrl;
    public controllerAs = 'vm';
    public template = tmpl;
}

angular.module('app.directives').component('maintenanceEventsActionsMenu', new maintenanceEventsActionsMenu());