//import angular from 'angular';
import OcDateSvc from '../../../services/ocDateSvc';
import tmpl from './stockDateTime.html';



angular.module("app.stocktake").component('stockDateTime', {
	    bindings: {
		    initParams: '=',
		    buttonMethods: '=',
		    buttonStates: '=',
		    buttons: '=',
		    closeOnSave: '=',
		    wnd: '='
	    },
	    template: tmpl,
		controller: ['WindowFactory', '$scope', 'errorReporter', 'dataBroker', 'enums', 'confirmSvc', 'ocDateSvc',
			function (WindowFactory: IWindowFactory, $scope, errorReporter: IErrorReporter, dataBroker: IDataBroker, enums, confirmSvc: IConfirmSvc, ocDateSvc: OcDateSvc) {
				var vm = this;
				$scope.vm = this;
                vm.form = {};
				vm.maxDate = new Date();
	
				this.$onInit = function () {
					vm.stocktake = vm.initParams.stocktake;
	
					if (!vm.stocktake) {
						vm.stocktake = {};
					}
					
					if (vm.stocktake.createdDate) {
						if (typeof vm.stocktake.createdDate === "string") {
							vm.stocktake.createdDate = new Date(vm.stocktake.createdDate);
						}
						vm.date = new Date(vm.stocktake.createdDate);
						vm.time = new Date(vm.stocktake.createdDate);
					}
					else {
						vm.date = null;
						vm.time = null;
					}
					
					vm.buttons = [
						{
							primary: true, 
							cancel: false, 
							value: 'common.save_label',
							name: 'saveButton',
							click: 'save',
							type: enums.buttonTypes.button
						},
						{
							primary: false,
							cancel: true, 
							value: 'common.cancel_label',
							name: 'cancelButton',
							click: 'cancel',
							type: enums.buttonTypes.button
						}
					];
					
					vm.buttonStates = {
						saveButton: {
							visible: true, 
							disabled: true
						},
						cancelButton: {
							visible: true, 
							disabled: false
						}
					};
					
					vm.buttonMethods = {
						save: vm.save,
						cancel: vm.cancel
					};
				};
	
                vm.cancel = function () {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        WindowFactory.closeWindow(vm.wnd);
                    });
				};
	
				vm.save = function () {
					if (!vm.date || !vm.time) {
						return;
					}
					var combinedDateTime = ocDateSvc.combineDateAndTime(vm.date, vm.time);
					if (combinedDateTime > new Date()) {
						vm.time = null;
						return; // no future dates - for safety;
					}
                    vm.stocktake.createdDate = combinedDateTime; 
                    vm.wnd.onDataChanged(vm.stocktake.createdDate);
					WindowFactory.closeWindow(vm.wnd);
                };

                $scope.$watchGroup(['vm.form.$pristine', 'vm.form.$invalid', 'vm.time', 'vm.date'], function () {
                    vm.buttonStates.saveButton.disabled = !vm.date || !vm.time || vm.form.$invalid || vm.form.$pristine;
                });
			}
		]
    });
