/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var shared_module_1 = require("../common/shared.module");
var kendo_angular_treeview_1 = require("@progress/kendo-angular-treeview");
var shared_directives_module_1 = require("../common/shared-directives.module");
var dropdowntree_component_1 = require("./dropdowntree.component");
var multiselecttree_component_1 = require("./multiselecttree.component");
var flat_binding_directive_1 = require("./data-binding/dropdowntree/flat-binding.directive");
var hierarchy_binding_directive_1 = require("./data-binding/dropdowntree/hierarchy-binding.directive");
var flat_binding_directive_2 = require("./data-binding/multiselecttree/flat-binding.directive");
var hierarchy_binding_directive_2 = require("./data-binding/multiselecttree/hierarchy-binding.directive");
var expand_directive_1 = require("./expanded-state/expand.directive");
var node_template_directive_1 = require("./templates/node-template.directive");
var summary_tag_directive_1 = require("./summary-tag/summary-tag.directive");
var DROPDOWNTREE_DIRECTIVES = [
    dropdowntree_component_1.DropDownTreeComponent,
    multiselecttree_component_1.MultiSelectTreeComponent,
    flat_binding_directive_1.DropDownTreeFlatBindingDirective,
    hierarchy_binding_directive_1.DropDownTreeHierarchyBindingDirective,
    flat_binding_directive_2.MultiSelectTreeFlatBindingDirective,
    hierarchy_binding_directive_2.MultiSelectTreeHierarchyBindingDirective,
    expand_directive_1.DropDownTreesExpandDirective,
    node_template_directive_1.NodeTemplateDirective,
    summary_tag_directive_1.MultiSelectTreeSummaryTagDirective
];
/**
 * @hidden
 */
var DropDownTreesModule = /** @class */ (function () {
    function DropDownTreesModule() {
    }
    DropDownTreesModule = tslib_1.__decorate([
        core_1.NgModule({
            declarations: [DROPDOWNTREE_DIRECTIVES],
            exports: [DROPDOWNTREE_DIRECTIVES, shared_directives_module_1.SharedDirectivesModule],
            imports: [shared_module_1.SharedModule, kendo_angular_treeview_1.TreeViewModule]
        })
    ], DropDownTreesModule);
    return DropDownTreesModule;
}());
exports.DropDownTreesModule = DropDownTreesModule;
