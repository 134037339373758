import 'zone.js';
//import 'reflect-metadata';
import 'jquery';
import 'angular';

//some of these are commented out - they shouldn't need to be imported here
import 'angular-busy/dist/angular-busy.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap';
import 'toastr/toastr.scss';

// import 'angular-progress-button/src/progress-button.css';
// import 'angular-ui-bootstrap/dist/ui-bootstrap-csp.css';
import 'bootstrap-timepicker/css/bootstrap-timepicker.min.css';

import 'plotly.js-dist';
import 'hammerjs';
import 'angular-hammer';
import 'file-saver';
import 'json.date-extensions';
import 'uuid-random';

import '@progress/kendo-ui/css/web/kendo.common-bootstrap.css';
import '@progress/kendo-ui/css/web/kendo.bootstrap.css';
import '@progress/kendo-ui/js/kendo.core';
import '@progress/kendo-ui/js/kendo.angular';
import '@progress/kendo-ui/js/kendo.grid';
import '@progress/kendo-ui/js/kendo.notification';
import '@progress/kendo-ui/js/kendo.autocomplete';
import '@progress/kendo-ui/js/kendo.datepicker';
import '@progress/kendo-ui/js/kendo.numerictextbox';
import '@progress/kendo-ui/js/kendo.combobox';
import '@progress/kendo-ui/js/kendo.menu';
import '@progress/kendo-ui/js/kendo.tooltip';
import '@progress/kendo-angular-layout';
//import '@progress/kendo-angular-layout';
import '@progress/kendo-angular-charts';
import '@progress/kendo-angular-grid';

// styles.scss
import "@progress/kendo-theme-default/dist/all.scss";

import './lib/i18n/angular-locale_en-oc';
import './lib/i18n/kendo.culture.es-oc';
import './lib/i18n/kendo.culture.en-oc';
import './lib/consoleLogging';
import './lib/detectWebp';
import './lib/detectwebcolor';

import './amt/jsextension';

import './appModule';
import './bootstrapAngular';

import './styles/';

import './services';
import './models';
import './filters';
import './controllers';
import './factories';
import './directives';
import './modules';

//TODO: webpack - how to handle this?
import './mobile';

//These are the angular services that have been downgraded to run with the AngularJS stuff
import './downgraded.module';

import './appRun';

