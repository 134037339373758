/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var grid_layout_component_1 = require("./layouts/grid-layout.component");
var gridlayout_item_component_1 = require("./layouts/gridlayout-item.component");
var exportedModules = [
    grid_layout_component_1.GridLayoutComponent,
    gridlayout_item_component_1.GridLayoutItemComponent
];
var declarations = exportedModules.slice();
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the GridLayout component.
 */
var GridLayoutModule = /** @class */ (function () {
    function GridLayoutModule() {
    }
    GridLayoutModule = tslib_1.__decorate([
        core_1.NgModule({
            declarations: [declarations],
            exports: [exportedModules],
            imports: [common_1.CommonModule]
        })
    ], GridLayoutModule);
    return GridLayoutModule;
}());
exports.GridLayoutModule = GridLayoutModule;
