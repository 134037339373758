//import angular from 'angular';
import tmpl from './dropBox.html';



angular.module('app.stocktake').component('dropBox', {
        template: tmpl,
        transclude: {
            'header': 'dropHeader',
            'body': 'dropBody'
        },
        bindings: {
            dropEvent: '&?', // call back to indicate that an even has occurred 
            level: '<', // the level at which this drop box resides. Used to force certain levels to open
            startOpen: '<'
        },
        controller: ["$timeout", '$scope',
            function dropBoxCtrl($timeout, $scope) {
                var ctrl = this;
                ctrl.open = false;

                ctrl.show = show;
                ctrl.hide = hide;
                ctrl.toggle = toggle;

                ctrl.$onInit = function() {
                    ctrl.open = ctrl.startOpen;
                };

                function show(forced) {
                    if (ctrl.dropEvent({ direction: 'open', userInitiated: !forced })) {
                        ctrl.open = true;
                    }
                }

                function hide(forced) {
                    if (ctrl.dropEvent({ direction: 'close', userInitiated: !forced })) {
                        ctrl.open = false;
                    }
                }

                function toggle() {
                    console.log("toggle");
                    if (ctrl.open) {
                        ctrl.hide();
                    } else {
                        ctrl.show();
                    }
                }

                // all drop boxes above the request level need to be opened
                var deregisterDropOpenLevel = $scope.$on('dropOpenLevel', function($event, level) {
                    if (ctrl.level < level) {
                        ctrl.show(true);
                    } else {
                        ctrl.hide(true);
                    }
                });

                // something inside the drop box has indicated that it has become active. (Usually be via a search or something )
                // we respond to this by making sure it is visible.
                var deregisterDropContentActive = $scope.$on('dropContentActive', function($event) {
                    if (!ctrl.open) {
                        var target = $event.targetScope;
                        console.log("From:" + target);
                        // there needs to be a better way.
                        var header = $event.currentScope.$$childHead.$id;
                        while (target != null) {
                            if (target.$id === header) {
                                console.log('ignored header content');
                                return; // the event came from our header.
                            }
                            target = target.$parent;
                        }
                        ctrl.open = true;
                        ctrl.dropEvent({ direction: 'open', userInitiated: false });
                    }
                });

                ctrl.$onDestroy = function() {
                    deregisterDropContentActive();
                    deregisterDropOpenLevel();
                };
            }
        ]
    });
