import { LOCALE_ID, Inject } from "@angular/core";
import { Injectable, OnDestroy } from '@angular/core';
import { downgradeInjectable } from "@angular/upgrade/static";
import { TranslateService } from '@ngx-translate/core';
import { IntlService, CldrIntlService } from "@progress/kendo-angular-intl";



/**
 * @ngdoc service
 * @name amtFramework.i18n.Xlat
 *
 * @description
 * Replace amtXlatSvc with a light wrapper around ngx-translate, Angular2+ code should use ngx-translate directly (translate pipe instead of xlat filter, etc.)
 * though setCurrentLanguage() and private parts arguably have utility beyond supporting only AngularJS code
 * Used by {@link amtFramework.i18n.filter:xlat xlat filter}
 */
@Injectable({
    providedIn: 'root'
})
export class Xlat implements OnDestroy, IAmtXlatSvc {
    private langChangeSubscription = this.translate.onLangChange.subscribe(evt => {
        //update Kendo UI for jQuery/AngularJS
        kendo.culture(evt.lang + '-OC');

        //update AngularJS a little
        this.$locale.NUMBER_FORMATS.DECIMAL_SEP = evt.lang != 'es' ? "." : ",";
        this.$locale.NUMBER_FORMATS.GROUP_SEP   = evt.lang != 'es' ? "," : ".";

        //Update Kendo UI for Angular
        (<CldrIntlService>this.intlService).localeId = evt.lang;

        //update Angular Locale?
        this.localeId = evt.lang;
    });


    public constructor(
        @Inject(LOCALE_ID) protected localeId: string,
        protected translate: TranslateService,
        protected intlService: IntlService,
        @Inject('$locale') protected $locale: ng.ILocaleService
    ) {
    }

    public ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
    }

    /**
     * @ngdoc method
     * @name #setCurrentLanuage
     * @methodOf amtFramework.i18n.xlat
     *
     * @description
     * Sets the current language
     *
     * @param {string} language string with code of the new language. eg: 'en'
     */
    public async setCurrentLanguage(lang: string) {
        if (lang == null || lang.length < 2) {
            lang = this.translate.getBrowserLang() || this.translate.defaultLang;

            let acceptedLanguages = ['en', 'es'];
            lang = acceptedLanguages.indexOf(lang) >= 0 ? lang : this.translate.defaultLang;
        }
            

        await this.translate.use(lang.substring(0, 2)).toPromise();
    }

    /**
     * @ngdoc method
     * @name #xlat
     * @methodOf amtFramework.i18n.xlat
     * @param {string} label Label that needs to be translated [screen name/module name].control_property eg 'assetManager.confirmation_message'
     * @param {array} parameters Parameters to substitute in the translation.
     **/
    public xlat(label: string, ...parameters: any[]) {
        return this.translate.instant(label, parameters); //TODO: not ideal - as per doc these should be accessed async
    }
}

//Included in downgraded.module.ts
//angular.module('app', [])
//    .factory('amtXlatSvc', downgradeInjectable(Xlat) as any);
