import DbClass from '../modules/common/local-db';
import FileManagement from './fileManagement';
import OcDateSvc from './ocDateSvc';


export function errorReporterFactory(injector: ng.auto.IInjectorService)  {
    return injector.get('errorReporter');
}

export const errorReporterProvider = {
    provide: 'errorReporter',
    useFactory: errorReporterFactory,
    deps: ['$injector']
}

export function windowFactoryFactory(injector: ng.auto.IInjectorService)  {
    return injector.get('WindowFactory');
}

export const windowFactoryProvider = {
    provide: 'WindowFactory',
    useFactory: windowFactoryFactory,
    deps: ['$injector']
}

export function confirmServiceFactory(injector: ng.auto.IInjectorService)  {
    return injector.get('confirmSvc');
}

export const confirmServiceProvider = {
    provide: 'confirmSvc',
    useFactory: confirmServiceFactory,
    deps: ['$injector']
}

export function notifyServiceFactory(injector: ng.auto.IInjectorService)  {
    return injector.get('notifySvc');
}

export const notifyServiceProvider = {
    provide: 'notifySvc',
    useFactory: notifyServiceFactory,
    deps: ['$injector']
}

export function fileManagementFactory(injector: ng.auto.IInjectorService)  {
    return injector.get('fileManagement');
}

export const fileManagementProvider = {
    provide: FileManagement,
    useFactory: fileManagementFactory,
    deps: ['$injector']
}

export function dbClassFactory(injector: ng.auto.IInjectorService) {
    return injector.get('$db');
}

export const DbClassProvider = {
    provide: DbClass,
    useFactory: dbClassFactory,
    deps: ['$injector']
}

export function ocDateSvcFactory(injector: ng.auto.IInjectorService) {
    return injector.get('ocDateSvc');
}

export const ocDateSvcProvider = {
    provide: OcDateSvc,
    useFactory: ocDateSvcFactory,
    deps: ['$injector']
}