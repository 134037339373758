/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var drag_clue_component_1 = require("./drag-clue.component");
var models_1 = require("../models");
var utils_1 = require("../../utils");
var drag_and_drop_asset_service_1 = require("../editing-services/drag-and-drop-asset.service");
var drag_and_drop_utils_1 = require("../drag-and-drop-utils");
/**
 * @hidden
 */
exports.CLUE_OFFSET = 10;
/**
 * @hidden
 */
exports.RETURN_ANIMATION_DURATION = 200;
/**
 * @hidden
 */
var DragClueService = /** @class */ (function (_super) {
    tslib_1.__extends(DragClueService, _super);
    function DragClueService(componentFactoryResolver) {
        var _this = _super.call(this) || this;
        _this.componentFactoryResolver = componentFactoryResolver;
        return _this;
    }
    DragClueService.prototype.initialize = function (container, template) {
        if (utils_1.isPresent(this._componentRef)) {
            this.ngOnDestroy();
        }
        var clueComponentFactory = this.componentFactoryResolver.resolveComponentFactory(drag_clue_component_1.DragClueComponent);
        this.componentRef = container.createComponent(clueComponentFactory);
        this.hide();
        this.componentRef.instance.template = template;
        this.componentRef.changeDetectorRef.detectChanges();
    };
    DragClueService.prototype.ngOnDestroy = function () {
        this.cancelReturnAnimation();
        this.cancelScroll();
        _super.prototype.ngOnDestroy.call(this);
    };
    DragClueService.prototype.move = function (left, top) {
        _super.prototype.move.call(this, left, top, exports.CLUE_OFFSET);
    };
    DragClueService.prototype.animateDragClueToElementPosition = function (target) {
        var _this = this;
        if (!(utils_1.isPresent(target) && utils_1.isPresent(this.element.animate))) {
            this.hide();
            return;
        }
        var targetElementViewPortCoords = target.getBoundingClientRect();
        var clueElementViewPortCoords = this.element.getBoundingClientRect();
        this.returnAnimation = this.element.animate([
            { transform: 'translate(0, 0)' },
            { transform: "translate(" + (targetElementViewPortCoords.left - clueElementViewPortCoords.left) + "px, " + (targetElementViewPortCoords.top - clueElementViewPortCoords.top) + "px)" }
        ], exports.RETURN_ANIMATION_DURATION);
        this.returnAnimation.onfinish = function () { return _this.hide(); };
    };
    DragClueService.prototype.cancelReturnAnimation = function () {
        if (!utils_1.isPresent(this.returnAnimation)) {
            return;
        }
        this.returnAnimation.cancel();
        this.returnAnimation = null;
    };
    DragClueService.prototype.updateDragClueData = function (action, sourceItem, destinationItem) {
        var dragClue = this.componentRef.instance;
        if (action === dragClue.action && utils_1.dataItemsEqual(sourceItem, dragClue.sourceItem) && utils_1.dataItemsEqual(destinationItem, dragClue.destinationItem)) {
            return;
        }
        dragClue.action = action;
        dragClue.sourceItem = sourceItem;
        dragClue.destinationItem = destinationItem;
        dragClue.detectChanges();
    };
    DragClueService.prototype.updateText = function (text) {
        if (text === this.componentRef.instance.text) {
            return;
        }
        this.componentRef.instance.text = text;
        this.componentRef.instance.detectChanges();
    };
    /**
     * Triggers the first scrollable parent to scroll upwards or downwards.
     * Uses setInterval, so should be called outside the angular zone.
     */
    DragClueService.prototype.scrollIntoView = function (_a) {
        var step = _a.step, interval = _a.interval;
        this.cancelScroll();
        var scrollableContainer = drag_and_drop_utils_1.getScrollableContainer(this.element);
        if (!utils_1.isPresent(scrollableContainer)) {
            return;
        }
        var containerRect = scrollableContainer.getBoundingClientRect();
        var clueRect = this.element.getBoundingClientRect();
        // if the beginning of the scrollable container is above the current viewport, fall-back to 0
        var firstVisibleClientTopPart = Math.max(containerRect.top, 0);
        // start scrolling up when the first visible item is dragged over
        var topLimit = firstVisibleClientTopPart + clueRect.height;
        // if the end of the scrollable container is beneath the current viewport, fall-back to its client height
        // add the distance from the start of the viewport to the beginning of the container to ensure scrolling bottom begins when the actual end of the container is reached
        var bottomLimit = firstVisibleClientTopPart + Math.min(containerRect.bottom, scrollableContainer.clientHeight);
        if (clueRect.top < topLimit) {
            this.scrollInterval = setInterval(function () {
                return drag_and_drop_utils_1.scrollElementBy(scrollableContainer, step, models_1.ScrollDirection.Up);
            }, interval);
        }
        else if (clueRect.bottom > bottomLimit) {
            this.scrollInterval = setInterval(function () {
                return drag_and_drop_utils_1.scrollElementBy(scrollableContainer, step, models_1.ScrollDirection.Down);
            }, interval);
        }
    };
    /**
     * Cancels out the on-going scroll animation, if present.
     */
    DragClueService.prototype.cancelScroll = function () {
        if (utils_1.isPresent(this.scrollInterval)) {
            clearInterval(this.scrollInterval);
            this.scrollInterval = null;
        }
    };
    DragClueService = tslib_1.__decorate([
        core_1.Injectable(),
        tslib_1.__metadata("design:paramtypes", [core_1.ComponentFactoryResolver])
    ], DragClueService);
    return DragClueService;
}(drag_and_drop_asset_service_1.DragAndDropAssetService));
exports.DragClueService = DragClueService;
