//import angular from 'angular';
import tmpl from './amtForm.html';

angular.module('amtFramework')
        .directive('amtForm', [function() {
            return{
                restrict: 'E',
                transclude: true,
                scope: {
                    name: '@',
                    control: '='
                },
                template: tmpl,
                link: function(scope: any, element, attrs) {
                    scope.control = scope.control || {};

                    scope.control.submit = function () {
                        //TODO: Can't do this: (why is the name the element id, why not use element, submit() call is jquery but uses getElementById...)
                        //document.getElementById(scope.name).submit();
                    };
                }
            };
        }])
        .directive('amtSubmit', [
            '$parse', '$q', '$timeout', function($parse, $q, $timeout) {
                return {
                    restrict: 'A',
                    require: '?form',
                    compile: function() {
                        return {
                            post: function(scope, formElement, attributes, formController) {

                                var fn = $parse(attributes.amtSubmit);

                                formElement.bind('submit', function(event) {
                                    formController.attempted = true;
                                    //if (!scope.$$phase) {
                                    //    scope.$apply();
                                    //}

                                    if (!formController.$valid) {
                                        return false;
                                    }


                                    var promise = fn(scope, { $event: event }).then(function() {
                                        formController.attempted = false;

                                    });

                                    return promise;

                                    //    var returnPromise = $q.when(fn(scope, { $event: event }));

                                    //    returnPromise.then(function(result) {
                                    //        //if (!scope.$$phase) {
                                    //        //    scope.$apply();
                                    //        //}

                                    //    }, function(error) {
                                    //        if (!scope.$$phase) {
                                    //            scope.$apply();
                                    //        }
                                    //        $timeout(function() {
                                    //        });
                                    //    });
                                    //};

                                    //if (!scope.$$phase) {
                                    //    scope.$apply(doSubmit);
                                    //} else {
                                    //    doSubmit();
                                    //    if (!scope.$$phase) scope.$apply();
                                    //}
                                    //return true;
                                });
                            }
                        };
                    }
                };
            }
        ]);
