<div class="wrapper cm-details-tab" *ngIf="currentVehicle$ | async as currentVehicle">
    <div class="row equal">
        <div class="col-xs-6 mg-col">
            <oc-vehicle-schematic [vehicleObject]="currentVehicle"
                                  maxWidth="300"
                                  maxHeight="350"
                                  positionsHigh="2"
                                  positionsWide="5"
                                  topAxle="1"
                                  leftAxle="1"
                                  [showChains]="false"
                                  [showRims]="false"
                                  [showPositionLabels]="true"
                                  redraw="vm.svgReset"
                                  positionView="fitted"
                                  [useReadingSequence]="true"
                                  [includeFitments]="false"
                                  [disabledSegments]="false"
                                  [(selectedComponent)]="selectedComponent"
                                  (tyreSelected)="tyreSelected($event)">
            </oc-vehicle-schematic>
        </div>
        <div class="col-xs-6 mg-col" id="cmDetails">
            <div class="row" *ngIf="currentVehicle.latestReading">
                <div class="col-xs-6 mg-col"><label class="pull-right">{{"conditionmonitoring.LastInspectionDate"|xlat}}:</label></div>
                <div class="col-xs-6 mg-col">{{currentVehicle.latestReading.date | date : 'short'}}</div>
            </div>
            <div class="row" *ngIf="currentVehicle.latestReading">
                <div class="col-xs-6 mg-col"><label class="pull-right">{{"conditionmonitoring.InspectedBy"|xlat}}:</label></div>
                <div class="col-xs-6 mg-col">{{currentVehicle.latestReading.userFullName}}</div>
            </div>
            <div class="row" *ngIf="currentVehicle">
                <div class="col-xs-6 mg-col"><label class="pull-right">{{"conditionmonitoring.LastVisualInspection"|xlat}}:</label></div>
                <div class="col-xs-6 mg-col">{{ latestViDate() }}</div>
            </div>
            <div class="row" *ngIf="currentVehicle">
                <div class="col-xs-6 mg-col"><label class="pull-right">{{"conditionmonitoring.VisualInspectionBy"|xlat}}:</label></div>
                <div class="col-xs-6 mg-col">{{ latestViUser() }}</div>
            </div>
            <div class="row" *ngIf="currentVehicle.hasHoursMeter">
                <div class="col-xs-6 mg-col"><label class="pull-right">{{"conditionmonitoring.LastHoursReading"|xlat}}:</label></div>
                <div class="col-xs-6 mg-col">{{currentVehicle.lastHoursReading.date | date : 'short'}}</div>
            </div>
            <div class="row" *ngIf="currentVehicle.lastTreadReading">
                <div class="col-xs-6 mg-col"><label class="pull-right">{{"conditionmonitoring.LastTreadReading"|xlat}}:</label></div>
                <div class="col-xs-6 mg-col">{{currentVehicle.lastTreadReading.date | date : 'short'}}</div>
            </div>
        </div>
    </div>

    <!-- Once a position is selected -->
    <div class="row">
        <div class="col-xs-6 mg-col k-grid cm-overrides">
            <table width="100%">
                <thead class="k-grid-header" role="rowgroup">
                    <tr role="row">
                        <th scope="col" role="columnheader" data-field="description" aria-haspopup="true" colspan="2" data-title="Tyre Details" class="k-header ng-scope">{{tableHeaderDetails()}}</th>
                    </tr>
                </thead>
                <tbody role="rowgroup">
                    <tr role="row">
                        <td class="" role="gridcell"><div class="text-left">{{"conditionmonitoring.FittedVehicle"|xlat}}</div></td>
                        <td class="" role="gridcell"><div class="text-right"><a (click)="showVehicle()">{{ this.selectedComponent?.fittedVehicle }}</a></div></td>
                    </tr>
                    <tr role="row">
                        <td class="k-alt" role="gridcell"><div class="text-left">{{"conditionmonitoring.FittedPos"|xlat}}</div></td>
                        <td class="k-alt" role="gridcell"><div class="text-right">{{ selectedComponent?.positionLabel }}</div></td>
                    </tr>
                    <tr role="row">
                        <td class="" role="gridcell"><div class="text-left">{{"conditionmonitoring.SerialNumber"|xlat}}</div></td>
                        <td class="" role="gridcell"><div class="text-right"><a (click)="showComponent()">{{ this.selectedComponent?.serialNumber }}</a></div></td>
                    </tr>
                    <tr role="row">
                        <td class="k-alt" role="gridcell"><div class="text-left">{{"conditionmonitoring.Specification"|xlat}}</div></td>
                        <td class="k-alt" role="gridcell"><div class="text-right">{{ selectedComponent?.specification }}</div></td>
                    </tr>
                    <tr role="row">
                        <td class="" role="gridcell"><div class="text-left">{{"conditionmonitoring.Hours"|xlat}}</div></td>
                        <td class="" role="gridcell"><div class="text-right">{{ selectedComponent?.totalHours.value }}</div></td>
                    </tr>
                    <tr role="row">
                        <td class="k-alt" role="gridcell"><div class="text-left">{{"conditionmonitoring.RTDWorn"|xlat}}</div></td>
                        <td class="k-alt" role="gridcell"><div class="text-right">{{ selectedComponent?.remainingDepth.innerOuterValues }}{{ percentWorn() }}</div></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-xs-6 mg-col k-grid cm-overrides">
            <table width="100%">
                <thead class="k-grid-header" role="rowgroup">
                    <tr role="row">
                        <th scope="col" role="columnheader" data-field="description" aria-haspopup="true" colspan="2" data-title="Condition Monitoring Summary" class="k-header ng-scope">{{tableHeaderDetails("conditionmonitoring.ConditionMonitoringSummary")}}</th>
                    </tr>
                </thead>
                <tbody role="rowgroup">
                    <tr role="row">
                        <td class="" role="gridcell"><div class="text-left">{{"conditionmonitoring.TotalVisualInspections"|xlat}}</div></td>
                        <td class="" role="gridcell"><div class="text-right">{{ viCount(false) }}</div></td>
                    </tr>
                    <tr role="row">
                        <td class="k-alt" role="gridcell"><div class="text-left">{{"conditionmonitoring.ActiveVisualInspections"|xlat}}</div></td>
                        <td class="k-alt" role="gridcell"><div class="text-right">{{ viCount(true) }}</div></td>
                    </tr>
                    <tr role="row">
                        <td class="" role="gridcell"><div class="text-left">{{"conditionmonitoring.VisualInspectionStatus"|xlat}}</div></td>
                        <td class="" role="gridcell"><div class="text-right" [ngClass]="{'alert-highlight':viStatus() === 'conditionmonitoring.statusactionoverdue'}">{{ viStatus() | xlat }}</div></td>
                    </tr>
                    <tr role="row">
                        <td class="k-alt" role="gridcell"><div class="text-left">{{"conditionmonitoring.AsymmetricWearStatus"|xlat}}</div></td>
                        <td class="k-alt" role="gridcell"><div class="text-right" [ngClass]="{'alert-highlight':alertExists(alertTypes.AsymmetricWearLimitExceeded)}">{{ alertExists(alertTypes.AsymmetricWearLimitExceeded) ? ("conditionmonitoring.statusactionrequired" | xlat) : ("conditionmonitoring.statusok" | xlat)}}</div></td>
                    </tr>
                    <tr role="row">
                        <td class="" role="gridcell"><div class="text-left">{{"conditionmonitoring.TyreMatchingStatus"|xlat}}</div></td>
                        <td class="" role="gridcell"><div class="text-right" [ngClass]="{'alert-highlight':alertExists(alertTypes.MatchingLimitExceeded)}">{{ alertExists(alertTypes.MatchingLimitExceeded) ? ("conditionmonitoring.statusactionrequired" | xlat) : ("conditionmonitoring.statusok" | xlat)}}</div></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div> <!-- row -->
</div>
