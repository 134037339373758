//import angular from 'angular';
import AdminUrls from '../../../adminUrls';

    angular.module('app.admin').controller("companySearchCtrl", companySearchCtrl);

    // @ts-ignore
    companySearchCtrl.$inject = ["adminUrls", "$scope", "enums", "amtXlatSvc", "WindowFactory", "ocConfigSvc", "exportSvc", "componentUrls", "referenceDataUrls"];

    // ReSharper disable once InconsistentNaming
    function companySearchCtrl(adminUrls: AdminUrls, $scope, enums, amtXlatSvc, WindowFactory, ocConfigSvc, exportSvc, componentUrls, referenceDataUrls) {

        var vm = this;

        vm.processing = false;

        vm.adminUrls = adminUrls;
        vm.componentUrls = componentUrls;        
        vm.referenceDataUrls = referenceDataUrls;

        vm.config = ocConfigSvc;

        vm.enums = enums;

        vm.companyFilterControl = {};

        vm.criteria = {
            filterValues: {
                activeOptions: null,
                equipmentTypeIds: null,
                roleTypeIds: null,
                companyIds: null
            }
        };

        vm.activeOptions = [{
            name: amtXlatSvc.xlat('common.yes_label'),
            key: true
        }, {
            name: amtXlatSvc.xlat('common.no_label'),
            key: false
        }];

        vm.status = enums.screenStatus.loading;

        vm.gridControl = {};
        vm.selectedItem = null;

        function init() {
            vm.loadDefaults();
        }

        vm.onSelectedItemChanged = function (items) {
            if (items != null && items.length === 1) {
                vm.selectedItem = items[0];
            } else {
                vm.selectedItem = null;
            }
        };

        vm.setHeaderHeight = function (height, offset) {
            vm.gridTop = height + offset;
        };

        vm.loadDefaults = function () {
            vm.defaults = {
                activeOptions: [vm.activeOptions[0]]
            };

            vm.status = enums.screenStatus.ready;
        };

        vm.export = function () {
            exportSvc.exportData(vm.adminUrls.exportCompanies, vm.criteria, amtXlatSvc.xlat('company.searchTitle'));
        };

        vm.onSearchCompanies = function (response) {

            var companies = response.result;

            if (companies && companies.length > 0) {
                for (var r = 0; r < companies.length; r++) {
                    companies[r].equipmentTypeDisplay = companies[r].equipmentTypes.map(function (c) {
                        return c.description;
                    }).sort().join(", ").trim();

                    companies[r].roleTypeDisplay = companies[r].roleTypes.map(function (c) {
                        return c.description;
                    }).sort().join(", ").trim();
                }
            }

            return response;
        };

        $scope.editCompany = function (item) {
            if (item) {
                WindowFactory.openItem({
                    component: 'add-edit-company',
                    caption: amtXlatSvc.xlat("company.edit"),
                    initParams: item,
                    width: 550,
                    onDataChangeHandler: function () {
                        vm.refreshGrid();
                        vm.companyFilterControl.search();
                    },
                    windowRelatedRecordId: item.key
                });
            }
        };

        $scope.addCompany = function () {
            WindowFactory.openItem({
                component: 'add-edit-company',
                caption: amtXlatSvc.xlat("company.add"),
                initParams: null,
                width: 550,
                onDataChangeHandler: function () {
                    vm.refreshGrid();
                    vm.companyFilterControl.search();
                }
            });
        };

        vm.refreshGrid = function () {
            vm.gridControl.refresh();
        };

        init();
    }
