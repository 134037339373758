/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * @hidden
 */
var ListItemDirective = /** @class */ (function () {
    function ListItemDirective(element) {
        this.element = element;
    }
    ListItemDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '"li[role=option], li[role=group]"' // tslint:disable-line
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.ElementRef])
    ], ListItemDirective);
    return ListItemDirective;
}());
exports.ListItemDirective = ListItemDirective;
