/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Specifies a separator in the content of components like the TextArea and the TextBox. ([see examples]({% slug adornments_textbox %}#toc-separator)).
 * @example
 * ```ts-no-run
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-textbox>
 *    <ng-template kendoTextBoxSuffixTemplate>
 *        <kendo-input-separator></kendo-input-separator>
 *        <button kendoButton look="clear" icon="image"></button>
 *    </ng-template>
 *  </kendo-textbox>
 * `
 * })
 * class AppComponent {}
 * ```
 */
var InputSeparatorComponent = /** @class */ (function () {
    function InputSeparatorComponent() {
        this.hostClass = true;
    }
    tslib_1.__decorate([
        core_1.HostBinding('class.k-input-separator'),
        tslib_1.__metadata("design:type", Boolean)
    ], InputSeparatorComponent.prototype, "hostClass", void 0);
    InputSeparatorComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-input-separator, kendo-textbox-separator',
            template: ""
        })
    ], InputSeparatorComponent);
    return InputSeparatorComponent;
}());
exports.InputSeparatorComponent = InputSeparatorComponent;
