//import angular from 'angular';


export default class OcCacheSvc {
    private cache: { [key: string]: any } = {};

    public async getItem(key: string, fetchMethod: Function): Promise<any> {
        if (key in this.cache)
            return this.cache[key];

        // fetch the data, add it to the cache, and then return it
        try {
            let response = await fetchMethod();
            this.cache[key] = response.data ? response.data.result : response;
            return this.cache[key];
        } catch (error) {
            throw error; //catch rethrow for now, mimicing spirit of orig code
        }
    }

    public removeItem(key: string) {
        delete this.cache[key];
    }

    public clear() {
        this.cache = {};
    }
}

angular.module('app').service('ocCacheSvc', OcCacheSvc);
