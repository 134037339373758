<!--Main-->
<div class="widget-flex-box">
    <div class="widget-number-link">
        <a (click)="showSiteHealthRateDialog()"><h1 [ngStyle]="{'color': getColorName(healthRate)}"><b>{{healthRate | number:'1.0-0'}}</b></h1></a>
    </div>
    <div class="widget-number-unit">
        <p [ngStyle]="{'color': getColorName(healthRate)}">{{ui.percent}}</p>
    </div>
</div>

<!--Subtext-->
<div class="widget-description" *ngIf="isIncludeSubtext" [ngClass]="isIncludeSubtext ? 'small-font' : ''">
    <h4> {{ui.click}}<a class="widget-description-text" (click)="showSiteHealthRateDialog()">{{ui.here}} </a>{{ui.findOut}}</h4>
</div>