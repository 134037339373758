/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var util_1 = require("../../common/util");
/**
 * @hidden
 *
 * A directive which manages the in-memory checked state of the MultiSelectTree nodes.
 */
var BaseCheckDirective = /** @class */ (function () {
    function BaseCheckDirective() {
    }
    BaseCheckDirective.prototype.addItem = function (item) {
        if (this.isItemChecked(item)) {
            return;
        }
        var level = util_1.getHierarchicalItemLevel(item.index);
        var candidate = tslib_1.__assign({}, item, { level: level });
        this.checkedItems.push(candidate);
        this.checkedKeys.add(this.getKey(candidate));
    };
    BaseCheckDirective.prototype.removeItem = function (item) {
        var _this = this;
        if (!this.isItemChecked(item)) {
            return;
        }
        var level = util_1.getHierarchicalItemLevel(item.index);
        var candidate = tslib_1.__assign({}, item, { level: level });
        this.checkedItems = this.checkedItems
            .filter(function (item) { return util_1.valueFrom(item, _this.valueField) !== util_1.valueFrom(candidate, _this.valueField); });
        this.checkedKeys.delete(this.getKey(candidate));
    };
    BaseCheckDirective.prototype.isItemChecked = function (item) {
        var level = item.index.split('_').length - 1;
        item.level = level;
        return this.checkedKeys.has(this.getKey(item));
    };
    BaseCheckDirective.prototype.updateItems = function () {
        var _this = this;
        this.checkedItems = this.checkedItems || [];
        this.checkedKeys = new Set(this.checkedItems.map(function (item) { return _this.getKey(item); }));
    };
    /**
     * Adds the item's depth to the item's value to allow duplicate values on different levels.
     *
     * @param item - The checked key.
     * @returns { string } - A string key consisting of the item's `valueField` value and its depth.
     */
    BaseCheckDirective.prototype.getKey = function (item) {
        if (util_1.isArray(this.valueField)) {
            return util_1.valueFrom(item, this.valueField) + '_' + item.level;
        }
        return util_1.valueFrom(item, this.valueField);
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], BaseCheckDirective.prototype, "valueField", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Array)
    ], BaseCheckDirective.prototype, "checkedItems", void 0);
    return BaseCheckDirective;
}());
exports.BaseCheckDirective = BaseCheckDirective;
