//import angular from 'angular';

    angular.module('app.directives')
        .directive(
            'btnCancel', [
                function () {
                    return {
                        replace: false,
                        restrict: 'C',
                        //require: '^ocForm',
                        link: function (scope, elem, attr) {
                            scope.$on('form:cancel', function () {
                                if (attr.ngShow && scope.$eval(attr.ngShow) === false) {
                                    // hidden
                                    return;
                                }
                                if (!attr.disabled) {
                                    elem.click();
                                }
                            });
                        }
                    };
                }
            ]
        );
