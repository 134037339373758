import { Component } from '@angular/core';
import { SiteHealthRateElement } from '../../service/Interface/Widget.interface';
import { SiteHealthRateDialogUi } from '../../service/Interface/WidgetConstant.interface';
import { WidgetRxjsService } from '../../service/rxjs/WidgetRxjs.service';
import { DialogRxjsService } from './../../service/rxjs/DialogRxjs.service';
import { WidgetConstant } from './../../service/widgetHelper/WidgetConstant';

@Component({
    selector: 'dialog-health-rate-element',
    templateUrl: './health-rate-element.component.html',
    styleUrls: ['./../../site-dashboard.component.scss']
})

export class DialogHealthRateElementComponent {
    public ui: SiteHealthRateDialogUi;
    public healthRateElement: SiteHealthRateElement;

    constructor(private widgetRxjs: WidgetRxjsService, private dialogRxjs: DialogRxjsService, private constant: WidgetConstant) {
        this.ui = this.constant.getSiteHealthRateDialogUi();
        this.subscribeWidgetRxjs();
    }
    //subscribe Rxjs data
    subscribeWidgetRxjs() {
        this.widgetRxjs.siteHealthRateElement.subscribe(element => {
            this.healthRateElement = element;
        });
    }

    dismissSiteHealthRateElement() {
        let dismiss = false;
        this.dialogRxjs.setHealthRateDialogActionListener(dismiss);
    }

}