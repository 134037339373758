//import angular from 'angular';
const moduleName = 'app.stocktake';
export default moduleName;

angular.module(moduleName, [])
        .config([
            '$stateProvider',
            function ($stateProvider) {
                $stateProvider
                    .state('mobile.stocktake', {
                        url: '/stocktake',
                        params: {
                            receivedSerials: null
                        },
                        views: {
                            "content": {
                                component: 'stocktake'
                            }
                        }
                    });
            }
        ]);
