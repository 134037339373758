 /**
        * @ngdoc directive
        * @name amtFramework.directive:amtTitle
        * @restrict E
        * @description
        * Displays a title at the top of the screen 
        * @param {string} TitleText Text to display
        * @param {expression} suffixTtitle Text to dynamically add to the titleText
        * @param {string} seperateSign Text to seperate the titleText and the suffixTitle
        * @element 
        * @example 
    **/

//import angular from 'angular';
import template from './amtTitle.html';

angular.module('amtFramework').directive('amtTitle', function () {
            return {
                restrict: 'E',
                transclude: true,
                replace: true,
                template: template,
                scope: {
                    titleText: '@',
                    suffixTitle: '=?',
                    separateSign:'@'
                },
                controller: [
                    '$scope', function($scope) {
                        $scope.fullTitle = $scope.titleText;
                        $scope.$watch('suffixTitle', function(newValue, oldValue) {
                            if (oldValue === newValue) {
                                return;
                            }
                            
                            $scope.fullTitle = String.format("{0} {2} {1}", $scope.titleText, $scope.suffixTitle,$scope.separateSign==="false"?'':'-');
                        });
                    }
                ]
    };
});
