<section class="amt-view-header bg-light lter b-b ng-scope ng-isolate-scope">
    <div class="site-dashboard-menu-flex-box" (click)="dismissLiveForecastTyreElement()">
        <!-- Header-->
        <h2 class="dialog-title">{{ui.title}}</h2>
        <!--Close Button-->
        <button class="dialog-close-btn" (click)="dismissLiveForecastTyreElement()"></button>
    </div>
</section>

<div *ngIf="hasReceivedElement">
    <!--Filter Selection-->
    <section class="live-forecast-detail-dropdown-container">
        <div class="flex-container">
            <section class="flex-container-col">
                <!--Display Selection Dropdown List -->
                <kendo-label class="live-forecast-label"
                                text="{{ui.changeType}}">
                    <kendo-dropdownlist class="live-forecast-dropdown-single"
                                        [data]="displaySelectionList"
                                        [(ngModel)]="selectedDisplay"
                                        (ngModelChange)="onChangeSelectedDisplay()">
                    </kendo-dropdownlist>
                </kendo-label>

                <!--Duration Selection Dropdown List -->
                <kendo-label class="live-forecast-label"
                                text="{{ui.forecastPeriod}}">
                    <kendo-dropdownlist class="live-forecast-dropdown-single"
                                        [data]="durationSelectionList"
                                        [(ngModel)]="selectedDuration"
                                        (ngModelChange)="onChangeSelectedDuration()">
                    </kendo-dropdownlist>
                </kendo-label>
            </section>

            <section class="flex-container-col">
                <!--Vehicle Specification  Selection Dropdown List -->
                <kendo-label class="live-forecast-label"
                                text="{{ui.vehicleSpecification}}">
                    <kendo-multiselect kendoMultiSelectSummaryTag
                                        class="live-forecast-multi-select"
                                        [autoClose]="false"
                                        [data]="filterOption.vehicleSpecificationList"
                                        [(ngModel)]="filterOption.filter.vehicleSpecification"
                                        (ngModelChange)="onChangeVehicleSpecification($event)"
                                        (reset)="onChangeVehicleSpecification($event)"
                                        (removeTag)="onChangeVehicleSpecification($event)">

                            <ng-template kendoMultiSelectGroupTagTemplate 
                                         let-dataItems>
                                <span *ngIf="dataItems.length === 1">
                                    {{ dataItems }}
                                </span>
                                <span *ngIf="dataItems.length > 1">
                                    {{ dataItems.length }} {{ui.vehicleSpecificationSelected}}
                                </span>
                            </ng-template>

                    </kendo-multiselect>
                </kendo-label>

                <!--Vehicle Serial Selection Dropdown List -->
                <kendo-label class="live-forecast-label"
                                text="{{ui.vehicleSerialNumber}}">
                    <kendo-multiselect kendoMultiSelectSummaryTag
                                        class="live-forecast-multi-select"
                                        [autoClose]="false"
                                        [data]="filterOption.vehicleSerialNumberList"
                                        [(ngModel)]="filterOption.filter.vehicleSerialNumber"
                                        (reset)="onChangeVehicleSerialNumber($event)"
                                        (ngModelChange)="onChangeVehicleSerialNumber($event)"
                                        (removeTag)="onChangeVehicleSerialNumber($event)">

                            <ng-template kendoMultiSelectGroupTagTemplate 
                                         let-dataItems>
                                <span *ngIf="dataItems.length === 1">
                                    {{ dataItems }}
                                </span>
                                <span *ngIf="dataItems.length > 1">
                                    {{ dataItems.length }} {{ui.vehicleSerialNumberSelected}}
                                </span>
                            </ng-template>

                    </kendo-multiselect>
                </kendo-label>
            </section>

            <section class="flex-container-col">
                <!--Tyre Specification Selection Dropdown List -->
                <kendo-label class="live-forecast-label"
                                text="{{ui.tyreSpecification}}">
                    <kendo-multiselect kendoMultiSelectSummaryTag
                                        class="live-forecast-multi-select"
                                        [autoClose]="false"
                                        [data]="filterOption.tyreSpecificationList"
                                        [(ngModel)]="filterOption.filter.tyreSpecification"
                                        (ngModelChange)="onChangeTyreSpecification($event)"
                                        (reset)="onChangeTyreSpecification($event)"
                                        (removeTag)="onChangeTyreSpecification($event)">

                            <ng-template kendoMultiSelectGroupTagTemplate 
                                         let-dataItems>
                                <span *ngIf="dataItems.length === 1">
                                    {{ dataItems }}
                                </span>
                                <span *ngIf="dataItems.length > 1">
                                    {{ dataItems.length }} {{ui.tyreSpecificationSelected}}
                                </span>
                            </ng-template>

                    </kendo-multiselect>
                </kendo-label>

                <!--Tyre Size Selection Dropdown List -->
                <kendo-label class="live-forecast-label"
                                text="{{ui.tyreSize}}">
                    <kendo-multiselect kendoMultiSelectSummaryTag
                                        class="live-forecast-multi-select"
                                        [autoClose]="false"
                                        [data]="filterOption.tyreSizeList"
                                        [(ngModel)]="filterOption.filter.tyreSize"
                                        (ngModelChange)="onChangeTyreSize($event)"
                                        (reset)="onChangeTyreSize($event)"
                                        (removeTag)="onChangeTyreSize($event)">

                            <ng-template kendoMultiSelectGroupTagTemplate 
                                         let-dataItems>
                                <span *ngIf="dataItems.length === 1">
                                    {{ dataItems }}
                                </span>
                                <span *ngIf="dataItems.length > 1">
                                    {{ dataItems.length }} {{ui.tyreSizeSelected}}
                                </span>
                            </ng-template>

                    </kendo-multiselect>
                </kendo-label>
            </section>
        </div>
    </section>

    <kendo-grid [data]="displayTyre"
                [height]="500"
                [resizable]="true">

        <!--Due Date Col-->
        <kendo-grid-column field="dueDateField" 
                           title="{{ui.dueDateTitle}}"
                           [width]="103">
        </kendo-grid-column>

        <!--Current Tyre Hours-->
        <kendo-grid-column field="currentTyreHours" 
                           title="{{ui.currentHoursTitle}}"
                           [width]="65">
        </kendo-grid-column>

        <!--RTD-->
        <kendo-grid-column field="remainingTreadDepth" 
                           title="{{ui.rtdTitle}}"
                           [width]="60">
        </kendo-grid-column>

        <!--OTD-->
        <kendo-grid-column field="originalTreadDepth" 
                           title="{{ui.otdTitle}}"
                           [width]="60">
        </kendo-grid-column>

        <!--Percent-->
        <kendo-grid-column field="remainingTreadPercent" 
                           title="{{ui.remainingPercentTitle}}"
                           [width]="73">
        </kendo-grid-column>

        <!--Tyre Serial Number-->
        <kendo-grid-column field="serialNumber"
                           title="{{ui.tyreSerialNumberTitle}}"
                           [width]="108">
            <ng-template kendoGridCellTemplate let-dataItem>
                <a href="/#!/component/search" (click)="setSearchByComponentSerialNumber(dataItem.serialNumber)">
                    <p style="margin: 0;">{{dataItem.serialNumber}}</p>
                </a>
            </ng-template>
        </kendo-grid-column>

        <!--Tyre Size-->
        <kendo-grid-column field="size" 
                           title="{{ui.tyreSizeTitle}}"
                           [width]="78">
        </kendo-grid-column>

        <!--Tyre Specification-->
        <kendo-grid-column field="specification" 
                           title="{{ui.tyreSpecificationTitle}}"
                           [width]="200">
        </kendo-grid-column>

        <!--Vehicle Serial Number-->
        <kendo-grid-column field="fittedVehicle.serialNumber" 
                           title="{{ui.vehicleSerialNumberTitle}}"
                           [width]="75">
            <ng-template kendoGridCellTemplate let-dataItem>
                <a href="/#!/vehicle/search"><p style="margin: 0;" (click)="setSearchByVehicleSerialNumber(dataItem.fittedVehicle.serialNumber)">{{dataItem.fittedVehicle.serialNumber}}</p></a>
            </ng-template>

        </kendo-grid-column>

        <!--Current Tyre Position Label-->
        <kendo-grid-column field="currentTyrePosition" 
                           title="{{ui.positionTitle}}"
                           [width]="55">
        </kendo-grid-column>

        <!--Fitment Type-->
        <kendo-grid-column field="task.changeType.fitmentType" 
                           title="{{ui.fitmentTypeTitle}}"
                           [width]="90"
                           [class]="{ 'text-center': true }">
        </kendo-grid-column>

        <!--Removal Type-->
        <kendo-grid-column field="task.changeType.removalType" 
                           title="{{ui.removalTypeTitle}}"
                           [width]="96">
        </kendo-grid-column>
    </kendo-grid>
</div>

