/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var tilelayout_component_1 = require("./tilelayout/tilelayout.component");
var tilelayout_item_component_1 = require("./tilelayout/tilelayout-item.component");
var tilelayout_item_header_component_1 = require("./tilelayout/tilelayout-item-header.component");
var tilelayout_item_body_component_1 = require("./tilelayout/tilelayout-item-body.component");
var tilelayout_resize_handle_directive_1 = require("./tilelayout/tilelayout-resize-handle.directive");
var exportedModules = [
    tilelayout_component_1.TileLayoutComponent,
    tilelayout_item_component_1.TileLayoutItemComponent,
    tilelayout_item_header_component_1.TileLayoutItemHeaderComponent,
    tilelayout_item_body_component_1.TileLayoutItemBodyComponent,
    tilelayout_resize_handle_directive_1.TileLayoutResizeHandleDirective
];
var declarations = exportedModules.slice();
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the TileLayout component.
 *
 * The module registers:
 * - `TileLayoutComponent`&mdash;The `TileLayoutComponent` component class.
 * - `TileLayoutItemComponent`&mdash;The `TileLayoutItemComponent` component class.
 * - `TileLayoutItemHeaderComponent`&mdash;The `TileLayoutItemHeaderComponent` component class.
 * - `TileLayoutItemBodyComponent`&mdash;The `TileLayoutItemBodyComponent` component class.
 */
var TileLayoutModule = /** @class */ (function () {
    function TileLayoutModule() {
    }
    TileLayoutModule = tslib_1.__decorate([
        core_1.NgModule({
            declarations: [declarations],
            exports: [exportedModules],
            imports: [common_1.CommonModule]
        })
    ], TileLayoutModule);
    return TileLayoutModule;
}());
exports.TileLayoutModule = TileLayoutModule;
