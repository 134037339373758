import { HttpClient } from "@angular/common/http";
import { Inject } from "@angular/core";
import { TranslateLoader } from "@ngx-translate/core";
import { Observable, of } from 'rxjs';
import { catchError, tap } from "rxjs/operators";
import { IS_MOBILE } from "../services/is-mobile.provider";
import OcConfigSvc from '../../services/ocConfigSvc';

const URL = baseApiUrl + 'multilingual/getLanguageResources/';

export class XlatLoader implements TranslateLoader {
    constructor(
        private http: HttpClient,
        @Inject(IS_MOBILE) private isMobile: boolean
    ) { }

    public getTranslation(lang: string): Observable<any> {
        var res$ = this.http.get(URL + lang);

        //TODO: this saves every loaded language when on mobile, this is likely not quite right (but likely works well enough for now)
        if (this.isMobile) {
            res$ = res$.pipe(
                tap(r =>
                    localStorage.setItem(lang, JSON.stringify(r))
                ),
                catchError(e => {
                    var str = localStorage.getItem(lang);
                    if (str == null)
                        throw e;

                    return of(JSON.parse(str));
                })
            );
        }
        
        return res$;
    }
}