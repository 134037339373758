//import angular from 'angular';
import * as _ from 'underscore';
import AdminUrls from '../../adminUrls';
import HelperSvc from '../../../../services/helperSvc';
import tmpl from './vehicleSpecificationDetails.html';



    angular.module('app.admin').component('vehicleSpecificationDetails', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: [
            '$scope', 'confirmSvc', 'enums', 'adminUrls', 'WindowFactory', 'ocSecuritySvc', 'amtCommandQuerySvc', 'notifySvc', 'amtXlatSvc', 'helperSvc',
            'ocConfigSvc', 'vehicleUrls', 'referenceDataUrls', 'amtConstants', 'errorReporter', '$timeout', '$q', 'specificationAttachmentSvc',
            function (
                $scope, confirmSvc, enums, adminUrls: AdminUrls, WindowFactory: IWindowFactory, ocSecuritySvc, amtCommandQuerySvc, notifySvc, amtXlatSvc: IAmtXlatSvc, helperSvc: HelperSvc,
                ocConfigSvc, vehicleUrls, referenceDataUrls, amtConstants, errorReporter, $timeout, $q, specificationAttachmentSvc) {

                var vm = this;
                $scope.vm = this;

                vm.focusAxlePos = [];
                vm.vehicleLayoutCode = [];

                vm.status = enums.screenStatus.loading;

                vm.adminUrls = adminUrls;
                vm.vehicleUrls = vehicleUrls;
                vm.referenceDataUrls = referenceDataUrls;

                vm.constants = amtConstants;
                vm.enums = enums;

                vm.config = ocConfigSvc;
                vm.helperSvc = helperSvc;

                vm.siteControl = {};

                vm.defaults = {};

                vm.forms = {
                    details: {},
                    configuration: {}
                };

                vm.validFitmentsGridControls = [];
                vm.validFitmentsCriteria = {
                    filterValues: {
                        systemHierarchyId: null,
                        equipmentTypeSpecificationTypeId: null
                    }
                };

                vm.labels = {
                    schematicMaxWidth: 675,
                    schematicMaxHeight: 400,
                    positionsWide: 5,
                    positionsHigh: 3,
                    positionView: ocSecuritySvc.isAuthorised('Security.Settings.SpecificationsVehicles', 'readWrite') ? 'labels' : 'labelsReadonly',
                    openFullScreenSchematic: function () {
                        vm.labels.schematicFullScreen = true;

                        vm.labels.fullScreenWnd = WindowFactory.openItem({
                            component: 'full-screen-schematic',
                            caption: vm.wnd.caption + ' - ' + amtXlatSvc.xlat('specifications.labels'),
                            initParams: {
                                specification: vm.data,
                                positionView: vm.labels.positionView,
                                setLabel: vm.labels.setLabel,
                                zoomRect: {
                                    leftPos: 1,
                                    topAxle: 1,
                                    positionsHigh: vm.labels.positionsHigh,
                                    positionsWide: vm.labels.positionsWide,
                                    hidden: true
                                }
                            },
                            modal: false,
                            top: vm.wnd.position.top,
                            left: vm.wnd.position.left - 600,
                            width: 600,
                            height: 600,
                            afterWindowClosed: function (wnd) {
                                vm.labels.schematicFullScreen = undefined;

                                if (vm.labels.redrawSchematic) {
                                    vm.labels.redrawSchematic();
                                }
                            },
                            onDataChangeHandler: function (data) {
                                vm.labels.schematicFullScreen = false;
                                vm.labels.gridHeight = vm.baseGridHeight;

                                vm.labels.leftPos = data.zoomRect.leftPos;
                                vm.labels.topAxle = data.zoomRect.topAxle;
                            }
                        });
                    },
                    setLabel: function (position) {
                        return $q(function (resolve, reject) {
                            var wfParam = {
                                component: 'set-position-label',
                                caption: amtXlatSvc.xlat('specifications.setLabel_title'),
                                modal: true,
                                initParams: {
                                    systemHierarchyId: !!vm.site ? helperSvc.getKey(vm.site) : (!!vm.client ? helperSvc.getKey(vm.client) : vm.config.user.system.id),
                                    position: position,
                                    axles: vm.data.axles,
                                },
                                onDataChangeHandler: function (data) {

                                    position.label = data.labelText;
                                    position.wheelPositionLabelId = data.wheelPositionLabelId;

                                    if (vm.labels.schematicFullScreen !== true && vm.labels.redrawSchematic) {
                                        vm.labels.redrawSchematic();
                                    }

                                    return resolve();
                                },
                                width: 600
                            };

                            WindowFactory.openItem(wfParam);
                        });
                    }
                };

                vm.attachments = {
                    tabName: 'documents',
                    canAdd: false,
                    svc: specificationAttachmentSvc,
                    documents: [],

                    updateButtons: function () {
                        vm.buttonStates.refreshAttachmentButton.visible = (vm.activeTab === vm.attachments.tabName);
                        vm.buttonStates.addAttachmentButton.visible = (vm.activeTab === vm.attachments.tabName) && this.canAdd;
                    },

                    // attachment specific initialisation 
                    init: function () {
                        // these have to be scope because callOnParentScope dictates it to be so.
                        $scope.openAttachment = function (item) {
                            vm.attachments.svc.openItem(
                                item, // attachment to open
                                vm.attachments.list // callback to refresh the list if something changes
                            );
                        };

                        $scope.download = function (searchItem) {
                            // download the file
                            vm.attachments.svc.download(searchItem);
                        };

                        $scope.deleteAttachment = function (item) {
                            vm.wnd.processing = true;

                            return vm.attachments.svc.deleteAsync(
                                item, // item to delete
                                vm.client,
                                vm.site // this or all sites
                            ).then(function () {
                                return vm.attachments.refreshAttachments();
                            }).catch(function () {
                                // this was already handled
                            }).finally(function () {
                                vm.wnd.processing = false;
                            });
                        };

                        $scope.addAttachment = function () {
                            vm.attachments.svc.add(
                                vm.data.id, //equipmentTypeSpecificationTypeId
                                vm.client, // default client
                                vm.site, // default site
                                vm.attachments.refreshAttachments // callback to refresh the document list
                            );
                        };

                        WindowFactory.addButton(vm, vm.attachments.svc.resourcePrefix + 'Add', 'addAttachmentButton', $scope.addAttachment, true);
                        WindowFactory.addButton(vm, vm.attachments.svc.resourcePrefix + 'Refresh', 'refreshAttachmentButton', this.refreshAttachments, false);
                    },

                    gridControl: {},

                    getListCriteria: function (siteId?) {
                        return {
                            equipmentTypeSpecificationTypeId: vm.data.id,
                            systemHierarchyId: siteId ? siteId : (vm.site ? vm.site.key : (vm.client ? vm.client.key : null))
                        };
                    },

                    refreshAttachments: function () {
                        vm.wnd.processing = true;

                        var attachmentCount = vm.attachments.documents.length;

                        vm.attachments.list().then(function (response) {
                            if (response.result.length !== attachmentCount) {
                                if (vm.wnd.onDataChanged) {
                                    // reload seach if number of attachments changed
                                    vm.wnd.onDataChanged();
                                }
                            }
                        }).finally(function () {
                            vm.wnd.processing = false;
                        });
                    },

                    // tell the grid control to refresh
                    list: function (siteId?) {
                        if (vm.data && vm.data.id) {
                            return $q(function (resolve, reject) {
                                vm.attachments.svc.list(vm.attachments.getListCriteria(siteId)).then(
                                    function (response) {
                                        // refresh the list of documents
                                        vm.attachments.documents = response.result;

                                        if (vm.attachments.documents && vm.attachments.documents.length > 0) {

                                            vm.attachments.documents.forEach(function (doc) {
                                                doc.siteDisplay = doc.sites.length > 0 ?
                                                    (doc.sites.length == 1 ? doc.sites[0].name : amtXlatSvc.xlat('specifications.multiple')) :
                                                    (doc.clients.length > 0 ?
                                                        (doc.clients.length == 1 ? doc.clients[0].name : amtXlatSvc.xlat('specifications.multiple')) :
                                                        amtXlatSvc.xlat('common.system_label'));
                                            });
                                        }

                                        $timeout(function () {
                                            if (vm.attachments.gridControl && vm.attachments.gridControl.refresh) {
                                                vm.attachments.gridControl.refresh();
                                            }
                                        });

                                        // resolve the promise
                                        return resolve(response);
                                    },
                                    // error, bubble it back out
                                    reject
                                );
                            });
                        }
                    }
                };

                this.$onInit = function () {

                    vm.status = enums.screenStatus.loading;
                    vm.wnd.processing = true;

                    if (vm.wnd !== null) {
                        vm.wnd.onClose = vm.cancel;
                    }

                    vm.data = vm.initParams ? vm.initParams : {};

                    vm.stepId = vm.data.id ? 2 : 0; // start step 0 for new, step 2 for edit

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: (!!vm.data.id ? "common.save_label" : "common.create_label"),
                            name: "saveButton",
                            click: "save",
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: false,
                            value: "common.delete_label",
                            name: "deleteButton",
                            click: "delete",
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: true,
                            cancel: false,
                            value: "common.next_label",
                            name: "nextButton",
                            click: "moveToNext",
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: false,
                            value: "common.back",
                            name: "backButton",
                            click: "moveToPrevious",
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: false,
                            value: "specifications.addAxle",
                            name: "addAxleButton",
                            click: "addAxle",
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: false,
                            value: "specifications.reset",
                            name: "resetButton",
                            click: "resetConfiguration",
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: false,
                            value: "specifications.removeAxle",
                            name: "removeAxleButton",
                            click: "removeAxle",
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: false,
                            value: 'equipment.fullScreenSchematic',
                            name: "fullScreenSchematicButton",
                            click: "fullScreenSchematic",
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: true,
                            cancel: false,
                            value: 'specifications.add',
                            name: "addValidFitmentButton",
                            click: "addValidFitment",
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: false,
                            disabled: true
                        },
                        deleteButton: {
                            visible: false,
                            disabled: true
                        },
                        nextButton: {
                            visible: false,
                            disabled: true
                        },
                        backButton: {
                            visible: false,
                            disabled: false
                        },
                        addAxleButton: {
                            visible: false,
                            disabled: false
                        },
                        resetButton: {
                            visible: false,
                            disabled: true
                        },
                        removeAxleButton: {
                            visible: false,
                            disabled: false
                        },
                        fullScreenSchematicButton: {
                            visible: false,
                            disabled: false
                        },
                        addValidFitmentButton: {
                            visible: false,
                            disabled: false
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.save,
                        delete: vm.delete,
                        moveToNext: vm.moveToNext,
                        moveToPrevious: vm.moveToPrevious,
                        addAxle: vm.addAxle,
                        resetConfiguration: vm.resetConfiguration,
                        removeAxle: vm.removeAxle,
                        fullScreenSchematic: vm.labels.openFullScreenSchematic,
                        addValidFitment: vm.addValidFitment
                    };

                    vm.canViewSystemLevel = ocSecuritySvc.isAuthorised('Security.Settings.SpecificationsVehicles', 'readOnly', null, null, true);

                    // init the attachements
                    vm.attachments.init();

                    return getReferenceData().then(function () {

                        if (vm.data.id) {
                            return vm.loadDetails(vm.config.user.site.id).finally(function () {

                                // default to logged-in client/site
                                vm.defaults.site = vm.config.user.site.id;
                                vm.defaults.client = vm.config.user.client.id;

                                return vm.attachments.list(vm.config.user.site.id).then(function () {

                                    $timeout(function () {

                                        setSecurity();
                                        evaluateButtons();

                                        vm.attachments.updateButtons();
                                    });
                                });

                            }).finally(function () {
                                vm.status = enums.screenStatus.ready;
                                vm.wnd.processing = false;
                            });

                        } else {

                            // new spec, get default units for system level
                            return getUnitsOfMeasure(null).then(function () {

                                // set defaults for new spec
                                vm.data = {
                                    speedTolerance: 25,
                                    asymmetricWearLimit: 10
                                };

                                setSecurity();
                                evaluateButtons();

                                vm.status = enums.screenStatus.ready;
                                vm.wnd.processing = false;
                            });
                        }
                    });
                };

                function getReferenceData() {
                    var refDataPromises = [];

                    refDataPromises.push(getAxleLocations());
                    refDataPromises.push(getManufacturers());
                    refDataPromises.push(getVehicleApplications());
                    refDataPromises.push(getVehicleLayoutCode());

                    return $q.all(refDataPromises);
                };

                function getUnitsOfMeasure(systemHierarchyId) {
                    return amtCommandQuerySvc.post(vm.referenceDataUrls.getSystemHierarchyUnitsOfMeasure, { systemHierarchyId: systemHierarchyId }).then(function (response) {
                        if (response) {
                            vm.defaults.depthUnit = _.find(response, function (u) { return u.baseReadingUnit === 'Depth'; });
                            vm.defaults.torqueUnit = _.find(response, function (u) { return u.baseReadingUnit === 'Torque'; });
                            vm.defaults.pressureUnit = _.find(response, function (u) { return u.baseReadingUnit === 'Pressure'; });
                        }
                    }).catch(function (response) {
                        vm.status = enums.screenStatus.error;
                        errorReporter.logError(response);
                    });
                };

                function getManufacturers() {

                    var criteria = {
                        defaultToAllSitesWhenNoSystemHierarchyFilter: true
                    };

                    return amtCommandQuerySvc.post(vm.vehicleUrls.getManufacturers_short, criteria).then(function (response) {
                        if (response && response.result) {
                            vm.manufacturers = response.result;
                        }
                    }).catch(function (error) {
                        vm.status = enums.screenStatus.error;
                        errorReporter.logError(error);
                    });
                };

                function getVehicleApplications() {
                    return amtCommandQuerySvc.post(vm.referenceDataUrls.getVehicleApplications_short, null).then(function (response) {
                        if (response && response.result) {
                            vm.vehicleApplications = response.result;
                        }
                    }).catch(function (error) {
                        vm.status = enums.screenStatus.error;
                        errorReporter.logError(error);
                    });
                };

                function getAxleLocations() {
                    return amtCommandQuerySvc.get(vm.referenceDataUrls.getAxleLocations_short, null).then(function (response) {
                        if (response && response.data && response.data.result) {
                            vm.axleLocations = response.data.result;
                        }
                    }).catch(function (error) {
                        vm.status = enums.screenStatus.error;
                        errorReporter.logError(error);
                    });
                };

                function getVehicleLayoutCode() {
                    return amtCommandQuerySvc.get(vm.vehicleUrls.getVehicleLayoutCode).then(function (response) {
                        if (response) {
                            vm.vehicleLayoutCode = response.data.result;
                        }
                    }).catch(function (response) {
                        vm.status = enums.screenStatus.error;
                        errorReporter.logError(response);
                    });
                };

                // load specification details
                vm.loadDetails = function (systemHierarchyId) {

                    var defaultHierarchyId = !!vm.site ? helperSvc.getKey(vm.site) : (!!vm.client ? helperSvc.getKey(vm.client) : null);

                    var criteria = {
                        id: vm.data.id,
                        equipmentType: 'Vehicle',
                        systemHierarchyId: (systemHierarchyId !== undefined ? systemHierarchyId : defaultHierarchyId)
                    };

                    return getUnitsOfMeasure(criteria.systemHierarchyId).then(function () {

                        return amtCommandQuerySvc.post(vm.adminUrls.getSpecification, criteria).then(function (response) {

                            if (response) {

                                vm.data = response;

                                vm.originalData = angular.copy(response);

                                vm.data.manufacturer = _.find(vm.manufacturers, function (m) { return m.key === vm.data.manufacturerId; });
                                vm.data.vehicleApplication = _.find(vm.vehicleApplications, function (va) { return va.key === vm.data.vehicleApplicationId; });
                                vm.data.vehicleLayoutCode = _.find(vm.vehicleLayoutCode, function (va) { return va.key === vm.data.layoutCodeId.toString(); });
                                vm.data.idAsString = vm.data.layoutCodeId.toString();

                                vm.data.id = response.equipmentTypeSpecificationTypeId;
                                vm.data.axles.forEach(function (a) { a.positionsSet = true; });

                                vm.originalSpecificationName = response.description;
                                vm.dynamicDescription = response.description;

                                for (var i = 0; i < vm.data.axles.length; i++) {

                                    if (!vm.validFitmentsGridControls) {
                                        vm.validFitmentsGridControls = [];
                                    }

                                    if (!vm.validFitmentsGridControls[i]) {
                                        vm.validFitmentsGridControls.push({});
                                    }
                                }

                                if (vm.activeAxleTab && vm.data && vm.data.axles) {
                                    vm.refreshValidFitments(vm.data.axles[+vm.activeAxleTab - 1].id);
                                }
                            }

                        }, function (error) {
                            vm.status = enums.screenStatus.error;
                            errorReporter.logError(error);
                        });
                    });
                };

                // delete specification
                vm.delete = function () {

                    if (vm.data && !vm.client) {

                        confirmSvc.confirmMessage('specifications.confirmDelete_title', 'specifications.confirmDelete', vm.originalSpecificationName).then(function () {

                            var criteria = {
                                equipmentTypeSpecificationTypeId: vm.data.id
                            };

                            vm.wnd.processing = true;

                            return amtCommandQuerySvc.post(vm.adminUrls.deleteVehicleSpecification, criteria).then(function (response) {

                                notifySvc.success(amtXlatSvc.xlat('specifications.vehicleSpecificationDeletedSuccessfully'));

                                if (vm.wnd.onDataChanged) {
                                    vm.wnd.onDataChanged();
                                }

                                vm.closeWindow();

                            }).catch(function (error) {
                                errorReporter.logError(error);
                            }).finally(function () {
                                vm.wnd.processing = false;
                            });
                        });
                    }
                };

                // save specification
                vm.save = function () {

                    if (vm.data) {

                        var criteria = {
                            equipmentTypeSpecificationTypeId: vm.data.id,
                            manufacturerId: helperSvc.getKey(vm.data.manufacturer),
                            equipmentTypeName: 'Vehicle',
                            name: vm.dynamicDescription,
                            vehicleTypeDetails: {
                                model: vm.data.model,
                                vehicleApplicationId: helperSvc.getKey(vm.data.vehicleApplication),
                                speedTolerance: vm.data.speedTolerance,
                                asymmetricWearLimit: vm.data.asymmetricWearLimit,
                                asymmetricWearLimitUnitOfMeasureId: (vm.data.asymmetricWearLimit !== undefined && vm.data.asymmetricWearLimit !== null ? vm.defaults.depthUnit.id : null),
                                axles: []
                            },
                            systemHierarchyId: (!!vm.site ? helperSvc.getKey(vm.site) : (!!vm.client ? helperSvc.getKey(vm.client) : null)),
                            obsolete: vm.data.obsolete,
                            layoutCodeId: vm.data.idAsString
                        }

                        vm.data.axles.forEach(function (a) {
                            criteria.vehicleTypeDetails.axles.push({
                                id: a.id,
                                sequence: a.sequence,
                                axleLocationId: helperSvc.getKey(a.axleLocation),
                                allowChain: a.allowChain,
                                lubricateStuds: a.lubricateStuds,
                                allowBiasPly: a.allowBiasPly,
                                torque: a.torque,
                                torqueUnitOfMeasureId: (a.torque !== undefined && a.torque !== null ? vm.defaults.torqueUnit.id : null),
                                coldInflationPressure: a.coldInflationPressure,
                                coldInflationPressureUnitOfMeasureId: (a.coldInflationPressure !== undefined && a.coldInflationPressure !== null ? vm.defaults.pressureUnit.id : null),
                                treadDepthLimit: a.treadDepthLimit,
                                treadDepthLimitUnitOfMeasureId: (a.treadDepthLimit !== undefined && a.treadDepthLimit !== null ? vm.defaults.depthUnit.id : null),
                                wheelPositions: a.positions.map(function (p) {
                                    return {
                                        id: p.id,
                                        sequence: p.sequence,
                                        wheelPositionNumber: p.wheelPositionNumber
                                    };
                                })
                            });
                        });

                        vm.wnd.processing = true;

                        return amtCommandQuerySvc.post((vm.data.id ? vm.adminUrls.modifyVehicleSpecification : vm.adminUrls.addVehicleSpecification), criteria).then(function (response) {

                            notifySvc.success(amtXlatSvc.xlat('specifications.vehicleSpecificationSavedSuccessfully'));

                            if (vm.wnd.onDataChanged) {
                                vm.wnd.onDataChanged();
                            }

                            // just saved new
                            if (!vm.data.id) {

                                vm.data.id = response.data;

                                return vm.loadDetails().then(function () {

                                    // set window caption to spec name
                                    vm.wnd.caption = vm.data.description;

                                    // change 'Create' button to 'Save' button                                        
                                    var saveButton = _.find(vm.buttons, function (b) { return b.name === 'saveButton'; });

                                    if (saveButton) {
                                        saveButton.value = "common.save_label";
                                    }

                                    vm.form.$setPristine();

                                    $timeout(function () {
                                        setSecurity();
                                        evaluateButtons();

                                        vm.activeAxleTab = 1;
                                    });
                                });

                            } else {

                                // update 'original data' for reset
                                vm.originalData = angular.copy(vm.data);

                                vm.form.$setPristine();

                                $timeout(function () {
                                    setSecurity();
                                    evaluateButtons();
                                });
                            }

                        }).catch(function (error) {
                            errorReporter.logError(error);
                        }).finally(function () {
                            vm.wnd.processing = false;
                        });
                    }
                };

                vm.closeWindow = function () {
                    if (vm.labels.fullScreenWnd) {
                        WindowFactory.closeWindow(vm.labels.fullScreenWnd);
                    }

                    WindowFactory.closeWindow(vm.wnd);
                };

                vm.cancel = function () {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        vm.form.dirty = false;
                        vm.closeWindow();
                        return true;
                    });

                    return false;
                };

                vm.updateDynamicDescription = function () {

                    if (vm.status === enums.screenStatus.ready && !vm.form.$pristine) {
                        vm.dynamicDescription = String.format("{0} {1} {2}",
                            // manufacturer
                            (vm.data.manufacturer && vm.data.manufacturer.key ? (vm.data.manufacturer.name === 'CAT' ? vm.data.manufacturer.name : vm.data.manufacturer.description) : ""),
                            // model
                            (vm.data.model ? vm.data.model : ""),
                            // vehicle application
                            (vm.data.vehicleApplication && vm.data.vehicleApplication.key ? vm.data.vehicleApplication.description : "")
                        ).replace("  ", " ");
                    }
                };

                vm.moveToNext = function () {

                    if (vm.stepId === 0) {

                        // check axle count >= 1
                        if (vm.data.numberOfAxles < 1) {
                            notifySvc.error(amtXlatSvc.xlat("exception.specifications_vehicleSpecRequiresAtLeastOneAxle"));
                            return;
                        }

                        vm.data.axles = [];

                        for (var i = 1; i <= vm.data.numberOfAxles; i++) {
                            vm.data.axles.push({
                                id: null,
                                sequence: i,
                                positions: [],
                                axleLocation: _.find(vm.axleLocations, function (l) { return l.name === (i === 1 ? enums.axleLocations.front : enums.axleLocations.rear); })
                            });
                        }

                    } else if (vm.stepId === 1) {

                        // check wheel position counts are greater than 1
                        if (_.any(vm.data.axles, function (a) { return (a.numberOfPositions !== undefined ? a.numberOfPositions : a.positions.length) < 2; })) {
                            notifySvc.error(amtXlatSvc.xlat("exception.specifications_axleRequiresMoreThanOnePosition"));
                            return;
                        }

                        // check wheel position counts are even numbers
                        if (_.any(vm.data.axles, function (a) { return (a.numberOfPositions !== undefined ? a.numberOfPositions : a.positions.length) % 2 !== 0; })) {
                            notifySvc.error(amtXlatSvc.xlat("exception.specifications_axlePositionCountMustBeEven"));
                            return;
                        }

                        var positionCount = _.reduce(vm.data.axles, function (total, item) { return (!item.positions ? 0 : item.positions.length) + total; }, 0);

                        vm.data.axles.forEach(function (a) {

                            if (a.positionsSet !== true) {

                                a.positions = [];

                                for (var i = 1; i <= a.numberOfPositions; i++) {

                                    positionCount++;

                                    a.positions.push({
                                        id: null,
                                        sequence: i,
                                        wheelPositionNumber: positionCount
                                    });
                                }

                                a.positionsSet = true;
                            }
                        });

                        vm.activeTab = 'configuration'; // default to the configuration tab
                        vm.addAxleMode = false;
                    }

                    vm.stepId = (vm.stepId !== undefined ? vm.stepId + 1 : 0);
                };

                vm.moveToPrevious = function () {
                    vm.stepId = (vm.stepId ? vm.stepId - 1 : 0);
                };

                vm.resetConfiguration = function () {
                    if (vm.originalData) {

                        vm.wnd.processing = true;
                        vm.data.axles = angular.copy(vm.originalData.axles);

                        $timeout(function () {
                            vm.activeAxleTab = 1;
                            vm.wnd.processing = false;
                        });
                    }
                };

                vm.addAxle = function () {

                    var currentAxleCount = vm.data.axles.length;

                    vm.data.axles.push({
                        id: null,
                        sequence: currentAxleCount + 1,
                        positions: [],
                        axleLocation: _.find(vm.axleLocations, function (l) { return l.name === enums.axleLocations.rear; })
                    });

                    vm.addAxleMode = true;

                    vm.moveToPrevious();
                };

                vm.removeAxle = function () {

                    if (vm.activeAxleTab) {
                        confirmSvc.confirmMessage('specifications.removeAxle', 'specifications.removeAxle_description', vm.activeAxleTab).then(function () {

                            var index = _.findIndex(vm.data.axles, function (a) {
                                return a.sequence === vm.activeAxleTab;
                            });

                            if (index > -1) {
                                vm.data.axles.splice(index, 1);

                                for (var i = 0; i < vm.data.axles.length; i++) {
                                    vm.data.axles[i].sequence = i + 1;
                                    vm.data.axles[i].wheelPositionNumber = i + 1;
                                };

                                evaluateButtons();
                            }
                        });
                    }
                };

                vm.refreshValidFitments = function (axleTypeId) {

                    if (axleTypeId) {

                        var index = _.findIndex(vm.data.axles, function (a) { return a.id === axleTypeId; });

                        if (index > -1) {

                            if (vm.validFitmentsGridControls && vm.validFitmentsGridControls[index] && vm.validFitmentsGridControls[index].refresh) {

                                vm.validFitmentsCriteria.filterValues.equipmentTypeSpecificationTypeId = vm.data.id;
                                vm.validFitmentsCriteria.filterValues.systemHierarchyId = (!!vm.site ? helperSvc.getKey(vm.site) : helperSvc.getKey(vm.client));
                                vm.validFitmentsCriteria.filterValues.axleTypeId = axleTypeId;

                                vm.validFitmentsGridControls[index].refresh();
                            }
                        }
                    }
                };

                function evaluateButtons() {
                    if (vm.form) {

                        // save button - all tabs except valid fitment sub-tab on configuration tab, not read only, final step
                        vm.buttonStates.saveButton.visible =
                            (vm.activeTab === 'details' || vm.activeTab === 'configuration') &&
                            !vm.readonly &&
                            vm.stepId === 2 &&
                            !(vm.activeTab === 'configuration' && vm.activeAxleSubTab === 'validFitments');

                        // delete button - edit spec, details tab, not readonly, system-level, spec not in use
                        vm.buttonStates.deleteButton.visible =
                            vm.activeTab === 'details' &&
                            !!vm.data.id &&
                            !vm.readonly &&
                            !vm.client &&
                            vm.data.equipmentsUsingSpecification === 0;

                        // next button - all but final step, not read only, new spec or spec not in use
                        vm.buttonStates.nextButton.visible =
                            (!vm.data.id || vm.data.equipmentsUsingSpecification === 0) &&
                            !vm.readonly &&
                            vm.stepId < 2;

                        // back button - second step (wheel position counts), not read only, new spec or spec not in use, not from 'add axle'
                        vm.buttonStates.backButton.visible =
                            (!vm.data.id || vm.data.equipmentsUsingSpecification === 0) &&
                            !vm.readonly &&
                            vm.stepId === 1 &&
                            !vm.addAxleMode;;

                        // add axle button - details sub-tab on configuration tab, not read only, new spec or spec not in use, system-level
                        vm.buttonStates.addAxleButton.visible =
                            (vm.activeTab === 'configuration' && vm.activeAxleSubTab === 'details') &&
                            !vm.readonly &&
                            (!vm.data.id || vm.data.equipmentsUsingSpecification === 0) &&
                            !vm.addAxleMode &&
                            !vm.client;

                        // remove axle button - details sub-tab on configuration tab, not read only, new spec, more than 1 axle, system-level
                        vm.buttonStates.removeAxleButton.visible =
                            (vm.activeTab === 'configuration' && vm.activeAxleSubTab === 'details') &&
                            !vm.readonly &&
                            !vm.data.id &&
                            !vm.addAxleMode &&
                            vm.data.axles.length > 1 &&
                            !vm.client;

                        // reset button - details sub-tab on configuration tab, not read only, edit spec, form dirty
                        vm.buttonStates.resetButton.visible =
                            !!vm.data.id &&
                            (vm.activeTab === 'configuration' && vm.activeAxleSubTab === 'details') &&
                            !vm.readonly &&
                            !vm.form.$pristine &&
                            !vm.addAxleMode;

                        // add valid fitment button  - valid fitments sub-tab on configuration tab, not read only, edit spec
                        vm.buttonStates.addValidFitmentButton.visible =
                            !!vm.data.id &&
                            (vm.activeTab === 'configuration' && vm.activeAxleSubTab === 'validFitments') &&
                            !vm.readonly;

                        // full schematic button - labels tab, schematic has scroll bar, not already full screen
                        vm.buttonStates.fullScreenSchematicButton.visible =
                            vm.activeTab === 'labels' &&
                            vm.labels.schematicHasScrollbar &&
                            !vm.labels.schematicFullScreen;

                        vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.form.$pristine || vm.wnd.processing;
                        vm.buttonStates.deleteButton.disabled = vm.wnd.processing;
                        vm.buttonStates.nextButton.disabled = vm.form.$invalid || vm.form.$pristine || vm.wnd.processing;
                        vm.buttonStates.addAxleButton.disabled = vm.wnd.processing;
                        vm.buttonStates.removeAxleButton.disabled = vm.wnd.processing;
                        vm.buttonStates.resetButton.disabled = vm.wnd.processing;
                        vm.buttonStates.fullScreenSchematicButton.visible = vm.activeTab === 'labels' && vm.labels.schematicHasScrollbar && !vm.labels.schematicFullScreen;

                        if (vm.attachments) {
                            vm.attachments.updateButtons();
                        }
                        vm.buttonStates.addValidFitmentButton.disabled = vm.wnd.processing;
                    }
                };

                vm.addValidFitment = function () {

                    var axleTypeId = vm.data.axles[+vm.activeAxleTab - 1].id;

                    WindowFactory.openItem({
                        component: 'add-valid-fitment',
                        caption: amtXlatSvc.xlat("specifications.addValidFitment"),
                        modal: true,
                        initParams: {
                            equipmentTypeSpecificationTypeId: vm.data.id,
                            axleTypeId: axleTypeId,
                            systemHierarchyId: !!vm.site ? helperSvc.getKey(vm.site) : (!!vm.client ? helperSvc.getKey(vm.client) : vm.config.user.system.id)
                        },
                        width: 550,
                        onDataChangeHandler: function () {
                            vm.refreshValidFitments(axleTypeId);
                        }
                    });
                };

                $scope.removeValidFitment = function (item) {

                    confirmSvc.confirmMessage('specifications.confirmValidFitmentRemove_title', 'specifications.confirmValidFitmentRemove', item.equipmentType, item.equipmentSize)
                        .then(function () {

                            var criteria = {
                                id: item.id,
                                systemHierarchyId: !!vm.site ? helperSvc.getKey(vm.site) : (!!vm.client ? helperSvc.getKey(vm.client) : vm.config.user.system.id)
                            };

                            vm.wnd.processing = true;

                            return amtCommandQuerySvc.post(vm.adminUrls.removeValidFitment, criteria).then(function (response) {

                                notifySvc.success(amtXlatSvc.xlat('specifications.validFitmentRemovedSuccessfully'));

                                vm.refreshValidFitments(item.axleTypeId);

                            }).catch(function (error) {
                                errorReporter.logError(error);
                            }).finally(function () {
                                vm.wnd.processing = false;
                            });
                        });
                };

                $scope.$watch('vm.data', function () {
                    vm.updateDynamicDescription();
                }, true);

                $scope.$watchGroup([
                    'vm.form.$invalid',
                    'vm.form.$pristine',
                    'vm.wnd.processing',
                    'vm.activeTab',
                    'vm.stepId',
                    'vm.readonly',
                    'vm.labels.schematicHasScrollbar',
                    'vm.labels.schematicFullScreen',
                    'vm.activeAxleSubTab'
                ], function () {
                    evaluateButtons();
                });

                $scope.$watch('vm.form.$dirty', function () {
                    vm.wnd.isDirty = vm.form.$dirty;
                });

                $scope.$watchGroup(['vm.activeTab', 'vm.activeAxleTab'], function () {
                    if (vm.activeAxleTab && vm.activeAxleTab > 0 && vm.data && vm.data.axles && vm.data.axles.length >= vm.activeAxleTab) {
                        vm.refreshValidFitments(vm.data.axles[+vm.activeAxleTab - 1].id);
                    }

                    if (vm.activeTab === 'documents') {
                        vm.attachments.refreshAttachments();
                    }
                });

                $scope.$watch('vm.stepId', function (newValue) {
                    if (newValue === 1) { // axle position counts

                        // focus first axle field
                        $timeout(() => { vm.focusAxlePos[0] = true });
                    }
                });

                vm.onClientChange = function (option) {

                    var oldValue = angular.copy(vm.client);
                    var newValue = angular.copy(option);

                    if ((!oldValue && newValue && newValue.key) || (oldValue && oldValue.key)) {

                        return confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {

                            if (vm.site) {
                                vm.form.$setPristine();
                                // clear site and let it handle the change
                                vm.siteControl.clear(true);
                            } else {

                                var systemHierarchyId = (option && option.key ? helperSvc.getKey(option) : null);

                                vm.wnd.processing = true;

                                return vm.loadDetails(systemHierarchyId).then(function () {

                                    setSecurity();
                                    evaluateButtons();

                                    vm.attachments.updateButtons();

                                    return vm.attachments.list(systemHierarchyId).then(function () {

                                        $timeout(function () {
                                            vm.activeAxleTab = 1;
                                            vm.form.$setPristine();
                                        });
                                    });

                                }).finally(function () {
                                    vm.wnd.processing = false;
                                });
                            }

                        }).catch(function () {
                            vm.client = oldValue;
                        });
                    }
                };

                vm.onSiteChange = function (option) {

                    var oldValue = angular.copy(vm.site);
                    var newValue = angular.copy(option);

                    if ((!oldValue && newValue && newValue.key) || (oldValue && oldValue.key)) {

                        return confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {

                            $timeout(function () {
                                var systemHierarchyId = option && option.key ? helperSvc.getKey(option) : (!!vm.client ? helperSvc.getKey(vm.client) : null);

                                vm.wnd.processing = true;

                                return vm.loadDetails(systemHierarchyId).then(function () {

                                    setSecurity();
                                    evaluateButtons();

                                    vm.attachments.updateButtons();

                                    return vm.attachments.list(systemHierarchyId).then(function () {

                                        $timeout(function () {
                                            vm.activeAxleTab = 1;
                                            vm.form.$setPristine();
                                        });
                                    });

                                }).finally(function () {
                                    vm.wnd.processing = false;
                                });
                            });

                        }).catch(function () {
                            vm.site = oldValue;
                        });
                    }
                };

                function setSecurity() {

                    $timeout(function () {
                        vm.readonly = !!vm.data.id &&
                                            !ocSecuritySvc.isAuthorised('Security.Settings.SpecificationsVehicles', 'readWrite',
                                                                        helperSvc.getKey(vm.client), helperSvc.getKey(vm.site), true);

                        vm.canViewLabels = !!vm.data.id &&
                                            ocSecuritySvc.isAuthorised('Security.Settings.SpecificationsVehicle.Labels', 'readOnly',
                                                                        helperSvc.getKey(vm.client), helperSvc.getKey(vm.site), true);

                        vm.canModifyLabels = !!vm.data.id &&
                                            ocSecuritySvc.isAuthorised('Security.Settings.SpecificationsVehicle.Labels', 'readWrite',
                                                                        helperSvc.getKey(vm.client), helperSvc.getKey(vm.site), true);

                        // enable/disable the add button based on the security context
                        vm.attachments.canAdd = vm.attachments.svc.canAdd(vm.client, vm.site);
                    });
                };
            }
        ]
    });
