//import angular from 'angular';
import tmpl from './Delay.html';



class MaintenanceSessionDelayCtrl implements ng.IController, IWindowController {

    form: ng.IFormController;
    wnd: IWindowObj;

    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: () => void };

    initParams: any;
    
    delayReasons = [];

    data: any;

    static $inject = ['$scope', 'dataBroker', 'confirmSvc', 'WindowFactory'];

    constructor(private $scope: ng.IScope, private dataBroker: IDataBroker, private confirmSvc: IConfirmSvc, private WindowFactory: IWindowFactory) {

        this.$scope.$watchGroup([() => this.form.$invalid, () => this.wnd.processing], () => {
            this.buttonStates.saveButton.disabled = this.form.$invalid || this.wnd.processing;;
            this.buttonStates.saveAndAddButton.disabled = this.form.$invalid || this.wnd.processing;
        });
    }

    async $onInit() {

        this.wnd.processing = true;

        try {
            this.WindowFactory.addButton(this, this.initParams.isEdit ? 'common.save_label' : 'common.add_label', 'saveButton', () => this.save(false), true);
            this.WindowFactory.addButton(this, 'maintenanceSession.saveAddAnother', 'saveAndAddButton', () => this.save(true), false, !this.initParams.isEdit);
            this.WindowFactory.addButton(this, 'common.cancel_label', 'cancelButton', () => this.closeWindow());

            this.data = angular.copy(this.initParams) || {};

            await this.loadComponentReferenceData();
        } finally {
            this.wnd.processing = false;
        }
    }

    async loadComponentReferenceData() {

        let getWorkshopTimesCriteria: IWorkshopTimesCriteria = { category: 'JobDelayReason' };

        let response = await this.dataBroker.getWorkshopTimes(getWorkshopTimesCriteria);

        this.delayReasons = response.result;
    }

    save(addAnother?: boolean) {
        if (this.wnd.onDataChanged) {
            this.data.reopen = addAnother;
            this.wnd.onDataChanged(this.data);
        }

        this.WindowFactory.closeWindow(this.wnd);
    }

    async closeWindow() {
        try {
            await this.confirmSvc.confirmSaveChange(this.form && this.form.$dirty);
        } catch {
            return; // they cancelled
        }

        this.WindowFactory.closeWindow(this.wnd);
    }
}

class MaintenanceSessionDelayComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = MaintenanceSessionDelayCtrl;
    public controllerAs = 'vm';
}

angular.module('app.directives').component('delayDialog', new MaintenanceSessionDelayComponent());