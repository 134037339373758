/**
 * @ngdoc directive
 * @name amtFramework.formControls.directive:amtDatepicker
 * @restrict E
 * 
 * @param {expression} ngModel Date property, depending on the mode it will return date, {year: x, month: y} ot {year: x}
 * @param {expression=} [isRequired=false] Required
 * @param {expression=} [isDisabled=false] Disabled
 * @param {expression=} [deletable=true] Deletable
 * @param {expression=} laterThenDate To do validation compared to another date control
 * @param {expression=} earlierThenDate To do validation compared to another date control
 * @param {string=} [mode='day'] options are 'day', 'month', 'year'. Does not support runtime switching
 * @param {expression=} minDate To limit the min date a user can choose
 * @param {expression=} maxDate To limit the min date a user can choose
 * @param {expression=} isEqual ???
 * @param {string=} defaultValue Sets the default value when loading
 * @param {callback} onDateChange Is called when the user changes the date
 *                                Signature: onDateChanged(date) todo: return the right object depending on mode
 * @description
 * Date picker to select dates. Supports 3 modes: day month year
 *
 * <h1>snippet</h1>
 * amt-datepicker
 * 
 * @example
 * <doc:example module="amtFramework">
 *   <doc:source>
 *     <amt-datepicker ng-model="myDate" default-value="'1999-01-02'">
 *     </amt-datepicker> <div>{{myDate}}</div>
 *</doc:source>
 * </doc:example>
 */

//import angular from 'angular';
import tmpl from './amtDatepicker.html';
import './amtDatepicker.scss';

angular.module('amtFramework.formControls').directive('amtDatepicker', ['$filter', '$locale',
    function ($filter, $locale) {
                return {
                    restrict: 'E',
                    require: ['ngModel', '^?form'],
                    scope: {
                        amtModel: '=?ngModel',
                        name: '@',
                        isRequired: '=?',
                        isDisabled: '=',
                        deletable: '=?',
                        laterThenDate: '=',
                        earlierThenDate: '=',
                        isEqual: '=?',
                        mode: '@',
                        minDate: '=?',
                        maxDate: '=?',
                        defaultValue: '=?',
                        onDateChange: '&?',
                        isPickonly: '=?',
                        autocomplete: "=?"
                    },
                    link: function (scope: any, element, attrs, ctrls) {
                        var modelCtrl = ctrls[0];

                        if (scope.autocomplete === true) {
                            scope.autocompleteState = 'on';
                        } else {
                            scope.autocompleteState = 'off';
                        }

                        //OR-9164 decided to not let users type invalid characters, we keep this but only apply it when mode is 'day',
                        //regardless when the Kendo picker formats determine the entry isn't a valid date we should handle this correctly now
                        //but allow these defaults to be overridden

                        var restrictKeyEntry = (scope.isPickonly !== true && scope.mode == 'day');

                        if (scope.allowedCharacters == null) {
                            scope.allowedCharacters = restrictKeyEntry ? '0123456789-' : '';
                        }

                        if (scope.maxlength == null) {
                            scope.maxlength = restrictKeyEntry ? 10 : 80;
                        }

                        // default state
                        scope.isBlank = true;
                        scope.isInvalid = false;

                        scope.$watch("stringValue", function (newValue, oldValue) {
                            scope.isBlank = scope.amtModel == null /* null or undefined*/ && (!angular.isString(newValue) || newValue.trim().length == 0);
                            scope.isInvalid = !(scope.amtModel || scope.isBlank);
                            handleFormValidity();
                        });

                        scope.$watch("amtModel", function (newValue, oldValue) {
                            scope.isBlank = newValue == null /* null or undefined*/ && (!angular.isString(scope.stringValue) || scope.stringValue.trim().length == 0);
                            scope.isInvalid = !(newValue || scope.isBlank);
                            handleFormValidity();

                            // if the value has been cleared via code we need to also clear the internal stringValue                                                       
                            if (!newValue && !!scope.stringValue) {
                                scope.stringValue = newValue;
                            }                            

                            //unused and very likely completely unnecessary?
                            if (scope.onDateChange !== undefined) {

                                var returnDate: any = (newValue && angular.isDate(newValue)) ? newValue : null;

                                if (returnDate) switch (scope.mode) {
                                    case 'day':
                                        break;
                                    case 'month':
                                        returnDate = { year: newValue.getFullYear(), month: newValue.getMonth() + 1 };
                                        break;
                                    case 'year':
                                        returnDate = { year: newValue.getFullYear() };
                                        break;
                                    default:
                                        console.log('WARNING: unrecognised mode: ' + scope.mode);
                                        break;
                                }
                                scope.onDateChange({ date: returnDate });
                            }
                        });

                        scope.$watch('isRequired', function (newValue, oldValue) {
                            if (newValue === oldValue) {
                                return;
                            }
                            handleFormValidity();
                        });

                        scope.$watch('minDate', function (newValue, oldValue) {
                            if (newValue === oldValue) {
                                return;
                            }
                            handleFormValidity();
                        });

                        scope.$watch('maxDate', function (newValue, oldValue) {
                            if (newValue === oldValue) {
                                return;
                            }
                            handleFormValidity();
                        });

                        scope.onDateSelected = function (e) {
                            scope.inputMode = 'none';
                        };

                        scope.onTextSelected = function (e) {
                            scope.inputMode = 'numeric';
                        };

                        element.bind("keydown keypress", function (event) {
                            if (scope.isPickonly) {
                                scope.$apply(function () {
                                    event.preventDefault();
                                });
                            }
                        });

                        function handleFormValidity() {

                            var invalid = scope.isInvalid || (scope.isRequired && scope.isBlank);

                            modelCtrl.$setValidity(scope.name, !invalid);
                            if (invalid) {
                                element.find('input').addClass('ng-invalid-required');
                            }
                            else {
                                element.find('input').removeClass('ng-invalid-required');
                            }

                            if (angular.isDefined(scope.minDate)) {
                                scope.controlOptions.min = scope.minDate;
                            }

                            if (angular.isDefined(scope.maxDate)) {
                                scope.controlOptions.max = scope.maxDate;
                            }

                        }
                    },
                    controller: ['$scope', function ($scope) {

                        $scope.intNullDate = new Date(0);

                        if (angular.isUndefined($scope.amtModel)) {
                            $scope.isInvalid = true;
                        }

                        $scope.mode = $scope.mode || 'day';
                        if (angular.isDefined($scope.defaultValue)) {
                            $scope.amtModel = new Date($scope.defaultValue);
                        }

                        if (angular.isUndefined($scope.deletable)) {
                            $scope.deletable = true;
                        }

                        $scope.format = null;
                        $scope.controlOptions = {
                            start: "month",
                            depth: "month"
                        };

                        if (angular.isDefined($scope.minDate)) {
                            $scope.controlOptions.min = $scope.minDate;

                        }

                        if (angular.isDefined($scope.maxDate)) {
                            $scope.controlOptions.max = $scope.maxDate;

                        }

                        $scope.inputMode = 'text'
                        switch ($scope.mode) {
                            default:
                                $scope.mode = 'day';
                                $scope.inputMode = 'numeric'
                                //fallthrough
                            case 'day':

                                var format = $locale.DATETIME_FORMATS.shortDate;
                                var i = format.indexOf('y'), j = format.lastIndexOf('y');

                                if (i == j && i >= 0) {
                                    // "y" tends to translate to "yyyy" (kendo can't work with "y", needs "yy" or "yyyy").
                                    format = format.slice(0, i) + 'yyy' + format.slice(i);
                                }
                                $scope.format = format;
                                $scope.parseFormats = [format];
                                $scope.controlOptions.start = "month";
                                $scope.controlOptions.depth = "month";

                                $scope.inputMode = 'numeric'
                                break;
                            case 'month':
                                $scope.format = "MMM yyyy";
                                $scope.parseFormats = ["MM yyyy", "MMM yyyy", "MMMM yyyy", "MM yy", "MMM yy", "MMMM yy", "MM-yyyy", "MMM-yyyy", "MMMM-yyyy", "MM-yy", "MMM-yy", "MMMM-yy"];
                                $scope.controlOptions.start = "year";
                                $scope.controlOptions.depth = "year";
                                break;
                            case 'year':
                                $scope.format = "yyyy";
                                $scope.parseFormats = [format];
                                $scope.controlOptions.start = "decade";
                                $scope.controlOptions.depth = "decade";
                                break;
                        }

                        $scope.clear = function () {
                            if ($scope.isDisabled === true) {
                                return;
                            }
                            $scope.amtModel = null;
                        };

                    }],
                    template: tmpl
                };
    }
]);
