//import angular from 'angular';

    angular.module('app.directives').directive('ocOnChange', function () {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                // when the change event fires, bubble it out
                element.on('change', function (event) {
                    var fn = scope.$eval(attrs.ocOnChange);
                    
                    fn(event);
                });
                // clean up
                element.on('$destroy', function () {
                    element.off();
                });
            }
        };
    });
