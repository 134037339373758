/**
 * @ngdoc service
 * @name amtFramework.filter.amtFilterSvc
 *
 * @description
 * Performs a call to the given url and returns a promise.
 * Used in amtCombobox, amtMultiSelect, amtMultiFieldPicker
 * 
 * @example
 */

/**
 * @ngdoc method
 * @name #async
 * @methodOf amtFramework.filter.amtFilterSvc
 * 
 * @description
 * Calls the url async and returns a promise to the caller
 * 
 * @param {string} url Url to be called
 * @param {string=} [searchText] Searchtext
 * @param {Object=} parameters Additional parameters to pass in the url.
 *                  format: {prop1: 1, prop2: 'hello', prop3: true}
 */
//import angular from 'angular';

angular.module('amtFramework').service('amtFilterSvc', ['$http', '$q', function ($http, $q) {
            var svc = {
                async: getWrapper,
                get: get,
                post: post,
                put: put,
                del: del
            };
            return svc;
            
            function getWrapper (url, searchText, parameters) {
                var params = parameters ? $.param(parameters) : null;
                var requestUrl = String.format("{0}{1}searchText={2}{3}", url, url.includes("?") ? "&" : "?", searchText, params ? '&' + params : '');
                return get(requestUrl);
            }

            function verifedResponse(response) {
                if (!response || response.status !== 200) {
                    return $q.reject(response);
                }
                if (response.errors && response.errors.length > 0) {
                    return $q.reject(response);
                }
                if (response.data && response.data.errors && response.data.errors.length > 0) {
                    return $q.reject(response);
                }
                return response.data;
            }

            function get(url) {
                return $http.get(url).then(function (response) {
                    return verifedResponse(response);
                });
            }

            function post(url, contract) {
                return $http.post(url, contract).then(function (response) {
                    return verifedResponse(response);
                });
            }

            function put(url, contract, id) {
                id = id || "";
                return $http.put(url + '/' + id, contract).then(function (response) {
                    return verifedResponse(response);
                });
            }

            function del(url, contract) {
                return $http.delete(url, { params: contract }).then(function (response) {
                    return verifedResponse(response);
                });
            }
}]);

