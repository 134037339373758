//import angular from 'angular';


    angular.module('app.directives').directive('uiScroll', ['$timeout', function ($timeout) {
        return {
            restrict: 'AC',
            link: function (scope, el, attr) {
                var padding = 25;

                el.on('focus', function (e) {
                    // make sure this is visible
                    //el[0].scrollIntoView();
                    $timeout(function() {
                        let obj = el[0];
                        let curtop = -padding;
                        if (obj.offsetParent) {
                            do {
                                curtop += obj.offsetTop;
                                obj = obj.offsetParent as HTMLElement;
                            } while (obj);
                            var curr = document.documentElement.scrollTop || document.body.scrollTop;
                            if (curtop < curr) {
                                window.scroll(0, curtop);
                            }
                            //find the bottom 
                            var bottom = curr + $(window).height();
                            var curBottom = curtop + el.height() + padding * 2;
                            if (curBottom > bottom) {
                                window.scroll(0, (curBottom - $(window).height()));
                            }
                        }
                    });
                });
            }
        };
    }]);
