/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var utils_1 = require("./utils");
var isChildIndex = function (targetIndex, parentIndex) { return parentIndex !== targetIndex && targetIndex.startsWith(parentIndex); };
var ɵ0 = isChildIndex;
exports.ɵ0 = ɵ0;
/**
 * @hidden
 */
var GroupsService = /** @class */ (function () {
    function GroupsService() {
        this.changes = new rxjs_1.Subject();
        this.rowState = new Set();
    }
    GroupsService.prototype.reset = function () {
        this.rowState.clear();
    };
    GroupsService.prototype.ngOnDestroy = function () {
        this.reset();
    };
    GroupsService.prototype.isExpanded = function (groupArgs) {
        if (this.userCallback) {
            return this.userCallback(groupArgs);
        }
        return !this.rowState.has(groupArgs.groupIndex);
    };
    GroupsService.prototype.isInExpandedGroup = function (groupItem) {
        var expanded = true;
        while (groupItem && expanded) {
            expanded = this.isExpanded({
                group: groupItem.data,
                groupIndex: groupItem.index,
                parentGroup: utils_1.getGroupRowArgs(groupItem.parentGroup)
            });
            groupItem = groupItem.parentGroup;
        }
        return expanded;
    };
    GroupsService.prototype.toggleRow = function (groupItem) {
        var parentGroup = utils_1.getGroupRowArgs(groupItem.parentGroup);
        var expand = !this.isExpanded({ group: groupItem.data, groupIndex: groupItem.index, parentGroup: parentGroup });
        this.changes.next({ group: groupItem.data, expand: expand, groupIndex: groupItem.index, parentGroup: parentGroup });
        // if usercallback is given, the rowState should be ignored
        if (this.userCallback) {
            return;
        }
        if (expand) {
            this.rowState.delete(groupItem.index);
        }
        else {
            this.rowState.add(groupItem.index);
        }
    };
    GroupsService.prototype.expandChildren = function (parentIndex) {
        var _this = this;
        this.rowState.forEach(function (index) { return isChildIndex(index, parentIndex) && _this.rowState.delete(index); });
    };
    GroupsService = tslib_1.__decorate([
        core_1.Injectable()
    ], GroupsService);
    return GroupsService;
}());
exports.GroupsService = GroupsService;
