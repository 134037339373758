/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var chunk_progressbar_component_1 = require("./chunk/chunk-progressbar.component");
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var progressbar_component_1 = require("./progressbar.component");
var COMPONENT_DIRECTIVES = [progressbar_component_1.ProgressBarComponent, chunk_progressbar_component_1.ChunkProgressBarComponent];
var MODULES = [common_1.CommonModule];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmodules'] }})
 * definition for the ProgressBar components.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the ProgressBar module
 * import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, ProgressBarModule], // import ProgressBar module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
var ProgressBarModule = /** @class */ (function () {
    function ProgressBarModule() {
    }
    ProgressBarModule = tslib_1.__decorate([
        core_1.NgModule({
            declarations: COMPONENT_DIRECTIVES,
            exports: COMPONENT_DIRECTIVES,
            imports: MODULES
        })
    ], ProgressBarModule);
    return ProgressBarModule;
}());
exports.ProgressBarModule = ProgressBarModule;
