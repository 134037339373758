/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var constants_1 = require("../constants");
var models_1 = require("../models");
/**
 * @hidden
 */
var FlatColorPickerService = /** @class */ (function () {
    function FlatColorPickerService() {
    }
    FlatColorPickerService.prototype.getPaletteSettings = function (settings, format) {
        var defaultPreset = (format !== 'name') ? constants_1.DEFAULT_PRESET : constants_1.DEFAULT_ACCESSIBLE_PRESET;
        var settingsPalette = settings.palette;
        var presetColumns = typeof settingsPalette === 'string' && models_1.PALETTEPRESETS[settingsPalette] ?
            models_1.PALETTEPRESETS[settingsPalette].columns :
            undefined;
        return {
            palette: settingsPalette || defaultPreset,
            tileSize: settings.tileSize || 24,
            columns: settings.columns || presetColumns || 10
        };
    };
    FlatColorPickerService.prototype.paletteTileLayout = function (tileSize) {
        if (typeof tileSize === 'number') {
            return { width: tileSize, height: tileSize };
        }
        return {
            width: tileSize.width ? tileSize.width : tileSize.height,
            height: tileSize.height ? tileSize.height : tileSize.width
        };
    };
    FlatColorPickerService = tslib_1.__decorate([
        core_1.Injectable()
    ], FlatColorPickerService);
    return FlatColorPickerService;
}());
exports.FlatColorPickerService = FlatColorPickerService;
