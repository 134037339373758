//import angular from 'angular';

    angular.module('app.directives')
        .directive('ocAllowedCharacters', function () {
            return {
                require: 'ngModel',
                link: function (scope, elem, attr, ngModel) {

                    // add a validator to validate on change of the value
                    ngModel.$validators.allowedCharacters = function (modelValue, viewValue) {
                        var value = modelValue || viewValue;

                        if (value && value.length > 0) {
                            var allowedCharacters = attr["ocAllowedCharacters"];

                            if (allowedCharacters && allowedCharacters.length > 0) {
                                // check each character in the string is allowed
                                for (var c = 0; c < value.length; c++) {
                                    if (allowedCharacters.indexOf(value[c]) === -1) {
                                        // bail on the first invalid character
                                        return false;
                                    }
                                }
                            }
                        }

                        // valid
                        return true;
                    };

                    // https://css-tricks.com/snippets/javascript/javascript-keycodes/
                    var keycodes = {
                        BACKSPACE: 8,
                        TAB: 9,
                        END: 35,
                        HOME: 36,
                        LEFT: 37,
                        RIGHT: 39,
                        DELETE: 46
                    };

                    // prevent keys that are no valid, expect for some control characters
                    elem.on('keydown', function (e) {
                        var allowedCharacters = attr["ocAllowedCharacters"];

                        if (allowedCharacters && allowedCharacters.length > 0) {
                            if (!e.ctrlKey) {
                                switch (e.which) {
                                    case keycodes.BACKSPACE:
                                    case keycodes.DELETE:
                                    case keycodes.LEFT:
                                    case keycodes.RIGHT:
                                    case keycodes.TAB:
                                    case keycodes.END:
                                    case keycodes.HOME:
                                        // allow cursor control keys
                                        break;
                                    default:
                                        if (allowedCharacters.indexOf(e.key) === -1) {
                                            e.preventDefault();
                                        }
                                        break;
                                }
                            }
                        }
                    });
                }
            };
        });
