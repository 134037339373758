/**
 * @ngdoc directive
 * @name amtFramework.grid.directive: amtLinkColumn
 * @restrict E
 * 
 * @param {string} field Name of the field defined in the datasource
 * @param {string} displayField Name of the field defined in the datasource
 * @param {string} title Column header title
 * @param {string=} [width=150px] Width of the column
 * @param {string} route Route defined like "app.real.billing.contractinvoicedetail({parameterInRoute:#: fieldInGrid #})"
 * @param {boolean=} [optional=true] Indicates the field is optional and can be hidden by the user
 * @param {string=} attributes Additional html attributes e.g {"style":"text-align:right"}
 * @param {string=} [type=string] Possible options: 'integer', 'double', 'boolean', 'date', 'datetime', 'string'
 * @param {string=} [trueText=True] Text to display when the field is true. Only used when type=boolean
 * @param {string=} [falseText=False] Text to display when the field is false. Only used when type=boolean
 * @param {callback=} onClick Event that is raised when the link is clicked. Overrides the route. Usage:
 *  <pre>
 * <button on-click="callOnParentScope('linkClicked', dataItem, '{{field}}')"></button>
 * </pre>
 * On the controller define the function directly on the scope
 * <pre>
 * $scope.linkClicked = function(dataItem, params){
 *     console.log(dataItem);
 *     console.log(params);
 * };
 * </pre>
 * @description
 * Column with hyperlink used in {@link amtFramework.grid.directive:amtGrid amtGrid}
 */

//import angular from 'angular';

angular.module('amtFramework.grid').directive('amtLinkColumn', [
    'appConfig', 'amtXlatSvc', '$locale',
    function (appConfig, xlatSvc, $locale) {
            return {
                require: ['^amtGrid', '?^amtGridGroupHeaderColumn'],
                restrict: 'E',
                transclude: true,
                scope: {
                    field: '@',
                    displayField: '@',
                    title: '@',
                    width: '@',
                    route: '@',
                    optional: '@',
                    attributes: '@',
                    headerAttributes: '@',
                    type: '@',
                    trueText: '@',
                    falseText: '@',
                    onClick: '@',
                    forceHide: '@',
                    hidden: '@',
                    filterable: "=?",
                    wrap: "@",
                    alignment: "@",
                    enableLink: '@?'
                },
                controller: ['$scope', function ($scope) {
                    $scope.optional = $scope.optional ? $scope.optional.toString().toLowerCase() === "true" : false;
                    $scope.hidden = $scope.hidden ? $scope.hidden.toString().toLowerCase() === "true" : false;
                }],
                link: function (scope: any, elem, attr, controllerarr, $transclude) {

                    var transcludeElements;
                    $transclude(function (clone) {
                        transcludeElements = clone;
                    });

                    var amtGridCtrl = (controllerarr[1] == null) ? controllerarr[0] : controllerarr[1];

                    if (!scope.displayField) {
                        scope.displayField = scope.field;
                    }

                    var kendoColumnDefinition: any = {
                        field: scope.field,
                        id: scope.field,
                        width: scope.width || "150px",
                        title: scope.title,
                        optional: JSON.parse(scope.optional),
                        type: scope.type,
                        menu: scope.optional,
                        forceHide: scope.forceHide === "true",
                        hidden: scope.hidden
                    };

                    if (scope.wrap === "true") {
                        if (!kendoColumnDefinition.attributes) {
                            kendoColumnDefinition.attributes = {};
                        }

                        kendoColumnDefinition.attributes["style"] = "word-wrap: break-word; white-space: normal;";
                    }

                    if (scope.attributes) {
                        if (!kendoColumnDefinition.attributes) {
                            kendoColumnDefinition.attributes = {};
                        }
                        var attrString = scope.attributes;
                        var attrJson = JSON.parse(attrString);
                        for (var attrname in attrJson) {
                            kendoColumnDefinition.attributes[attrname] = attrJson[attrname];
                        }
                    }

                    if (scope.headerAttributes) {
                        if (!kendoColumnDefinition.headerAttributes) {
                            kendoColumnDefinition.headerAttributes = {};
                        }
                        attrString = scope.headerAttributes;
                        attrJson = JSON.parse(attrString);
                        for (attrname in attrJson) {
                            kendoColumnDefinition.headerAttributes[attrname] = attrJson[attrname];
                        }
                    }

                    var trueText = scope.trueText || xlatSvc.xlat('common.true_label');
                    var falseText = scope.falseText || xlatSvc.xlat('common.false_label');

                    scope.kendoColumnDefinition = kendoColumnDefinition;

                    scope.$on('$destroy', function () {
                        amtGridCtrl.removeColumn(scope.kendoColumnDefinition);
                    });

                    scope.$watch('forceHide', function (newValue, oldValue) {
                        if (amtGridCtrl.showHideGridColumn !== undefined && scope.forceHide) {
                            amtGridCtrl.showHideGridColumn(scope, newValue);
                        }
                    });

                    var classNames = "amt-link-column";
                    var displayField = "";

                    var kendoDateTimeFormat = function (dateOnly = false) {
                        var shortDateTimeFormat = dateOnly ? $locale.DATETIME_FORMATS.shortDate : $locale.DATETIME_FORMATS.short;
                        if (shortDateTimeFormat.split("y").length === 2) {
                            // "y" tends to translate to "yyyy" (kendo can't work with "y", needs "yy" or "yyyy").
                            shortDateTimeFormat = shortDateTimeFormat.slice(0, shortDateTimeFormat.indexOf("y")) +
                                "yyyy" +
                                shortDateTimeFormat.slice(shortDateTimeFormat.indexOf("y") + 1);
                        }
                        if (shortDateTimeFormat.split("a").length === 2) {
                            // kendo uses "tt" instead of "a"...
                            shortDateTimeFormat = shortDateTimeFormat.slice(0, shortDateTimeFormat.indexOf("a")) +
                                "tt" +
                                shortDateTimeFormat.slice(shortDateTimeFormat.indexOf("a") + 1);
                        }
                        return shortDateTimeFormat;
                    };

                    switch (scope.type) {
                        case "integer":                        
                            //displayField = '#: kendo.toString(' + scope.field + ', "n0") #';
                            //displayField = '#= ' + scope.field + ' !== null ? kendo.toString(' + scope.field + ', "n0") : "" #';
                            displayField = '<div>#= kendo.toString(' + scope.displayField + ', "n0") #</div>';
                            if (!scope.alignment) scope.alignment = 'right';
                            break;
                        case "double":
                            displayField = '#= ' + scope.displayField + ' !== null ? kendo.toString(' + scope.displayField + ', "n2") : "" #';
                            break;
                        case "boolean":
                            displayField = '<span>#=' + scope.displayField + ' == true ? "' + trueText + '" : "' + falseText + '" #</span>';
                            break;
                        case "date":
                            var shortDateFormat = kendoDateTimeFormat(true);
                            displayField = '#= ' + scope.displayField + ' !== null ? kendo.toString(new Date(' + scope.displayField + '), "' + shortDateFormat + '") : ""   #';
                            break;
                        case "datetime":
                            var shortDateTimeFormat = kendoDateTimeFormat();
                            displayField = '#= ' + scope.displayField + ' !== null ? kendo.toString(new Date(' + scope.displayField + '), "' + shortDateTimeFormat + '") : ""   #';
                            break;
                        case "image":
                            displayField = '<img src=#= ' + scope.field + ' !== null ? kendo.toString(' + scope.displayField + ', "") : "" #></img>';
                            break;
                        default:
                            if (scope.displayField) {
                                displayField = '#:' + scope.displayField + '#';
                            } else {
                                if (transcludeElements && transcludeElements.length > 0) {
                                    displayField = transcludeElements[0].wholeText;
                                } else {
                                    displayField = "";
                                }
                            }
                    }

                    if (attr.onClick) {
                        kendoColumnDefinition.template =
                            '<div class="' + classNames + (scope.alignment ? ' text-' + scope.alignment : "") + '">' +
                            '#if(' + scope.field + ' !== null){' +
                            'if(' + (scope.enableLink ? scope.enableLink  : 'true') + '){#<a href="" ng-click="' + scope.onClick + '"> ' + displayField + '</a>#}else{#' + displayField + '#}' +
                            '}#</div>';

                    } else {
                        kendoColumnDefinition.template =
                            '<div class="' + classNames + '">' +
                            '#if(' + scope.field + ' !== null){' +
                            'if(' + (scope.enableLink ? scope.enableLink : 'true') + '){#<a ui-sref="' + scope.route + '"> ' + displayField + '</a>#}else{#' + displayField + '#}' +
                            '}#</div>';
                    }                  

                    if (scope.filterable === false) {
                        kendoColumnDefinition.filterable = false;
                    }

                    amtGridCtrl.addColumn(kendoColumnDefinition);

                    if (scope.forceHide === "true") {
                        amtGridCtrl.showHideGridColumn(scope, "true");
                    }
                }
            };
    }
]);
