/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var core_1 = require("@angular/core");
var pager_settings_1 = require("./pager-settings");
var pager_template_directive_1 = require("./pager-template.directive");
var utils_1 = require("../utils");
var pager_context_service_1 = require("./pager-context.service");
var rxjs_1 = require("rxjs");
var constants_1 = require("../constants");
var navigation_service_1 = require("../navigation/navigation.service");
var operators_1 = require("rxjs/operators");
var cell_context_1 = require("../rendering/common/cell-context");
var focus_group_1 = require("../navigation/focus-group");
var focus_root_1 = require("../navigation/focus-root");
var dom_queries_1 = require("../rendering/common/dom-queries");
/**
 * @hidden
 */
var PagerComponent = /** @class */ (function () {
    function PagerComponent(pagerContext, navigationService, element, renderer, zone, localizationService, cellContext, focusRoot, focusGroup) {
        var _this = this;
        this.pagerContext = pagerContext;
        this.navigationService = navigationService;
        this.element = element;
        this.renderer = renderer;
        this.zone = zone;
        this.localizationService = localizationService;
        this.cellContext = cellContext;
        this.focusRoot = focusRoot;
        this.focusGroup = focusGroup;
        this.total = 0;
        this.skip = 1;
        this.pageChange = new core_1.EventEmitter();
        this.pagerWrapClass = true;
        this.gridPagerClass = true;
        this.settings = pager_settings_1.normalize({});
        this.subscriptions = new rxjs_1.Subscription();
        this._templateContext = {};
        this._isFocused = false;
        this.resizeHandler = function () {
            var element = _this.element.nativeElement;
            if (_this.template || !element) {
                return;
            }
            var width = element.offsetWidth;
            if (width < constants_1.RESPONSIVE_BREAKPOINT_MEDIUM) {
                _this.renderer.removeClass(element, 'k-pager-md');
                _this.renderer.addClass(element, 'k-pager-sm');
            }
            else if (width >= constants_1.RESPONSIVE_BREAKPOINT_MEDIUM && width < constants_1.RESPONSIVE_BREAKPOINT_LARGE) {
                _this.renderer.addClass(element, 'k-pager-md');
                _this.renderer.removeClass(element, 'k-pager-sm');
            }
            else {
                _this.clearResponsiveClasses();
            }
        };
    }
    Object.defineProperty(PagerComponent.prototype, "options", {
        set: function (value) {
            this.settings = pager_settings_1.normalize(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagerComponent.prototype, "isFocused", {
        get: function () {
            return this._isFocused;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagerComponent.prototype, "pagerAriaLabel", {
        get: function () {
            return this.navigationService.enabled ? this.pagerLabel : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagerComponent.prototype, "pagerLabel", {
        /**
         * @hidden
         */
        get: function () {
            var localizationMsg = this.localizationService.get('pagerLabel') || '';
            return utils_1.replaceMessagePlaceholder(utils_1.replaceMessagePlaceholder(localizationMsg, 'currentPage', this.currentPage.toString()), 'totalPages', this.totalPages.toString());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagerComponent.prototype, "focusTrapTabIndex", {
        get: function () {
            return this.focusGroup.isActive ? '0' : '-1';
        },
        enumerable: true,
        configurable: true
    });
    PagerComponent.prototype.onFocusIn = function (event) {
        if (this.navigationService.enabled) {
            var shouldFocusPager = event.target === this.element.nativeElement;
            if (shouldFocusPager) {
                this._isFocused = true;
                this.focusRoot.deactivate();
            }
            else {
                this.focusRoot.activate();
            }
        }
    };
    PagerComponent.prototype.onFocusOut = function () {
        if (this.navigationService.enabled) {
            this._isFocused = false;
        }
    };
    PagerComponent.prototype.onEscape = function () {
        if (this.navigationService.enabled) {
            this.focusRoot.deactivate();
            this.element.nativeElement.focus();
        }
    };
    PagerComponent.prototype.onEnter = function (event) {
        if (this.navigationService.enabled && event.target === this.element.nativeElement) {
            this.focusRoot.activate();
            this.focusFirstElement();
        }
    };
    PagerComponent.prototype.navigateToPreviousPage = function (e) {
        if (this.shouldTriggerPageChange(e.target, this.currentPage > 1)) {
            this.pagerContext.prevPage();
        }
    };
    PagerComponent.prototype.navigateToNextPage = function (e) {
        if (this.shouldTriggerPageChange(e.target, this.currentPage < this.totalPages)) {
            this.pagerContext.nextPage();
        }
    };
    PagerComponent.prototype.navigateToFirstPage = function (e) {
        if (this.shouldTriggerPageChange(e.target, this.currentPage > 1)) {
            this.pagerContext.changePage(0);
        }
    };
    PagerComponent.prototype.navigateToLastPage = function (e) {
        if (this.shouldTriggerPageChange(e.target, this.currentPage < this.totalPages)) {
            this.pagerContext.changePage(this.totalPages - 1);
        }
    };
    Object.defineProperty(PagerComponent.prototype, "totalPages", {
        get: function () {
            return Math.ceil((this.total || 0) / this.pageSize);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagerComponent.prototype, "currentPage", {
        get: function () {
            return Math.floor((this.skip || 0) / this.pageSize) + 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PagerComponent.prototype, "templateContext", {
        get: function () {
            var context = this._templateContext;
            context.totalPages = this.totalPages;
            context.total = this.total;
            context.skip = this.skip;
            context.pageSize = this.pageSize;
            context.currentPage = this.currentPage;
            return context;
        },
        enumerable: true,
        configurable: true
    });
    PagerComponent.prototype.ngOnInit = function () {
        this.subscriptions.add(this.pagerContext.pageChange.subscribe(this.changePage.bind(this)));
        if (this.navigationService.enabled) {
            this.focusRoot.deactivate();
        }
    };
    PagerComponent.prototype.ngDoCheck = function () {
        this.updateCellContext();
    };
    PagerComponent.prototype.ngOnChanges = function (changes) {
        if (utils_1.anyChanged(['pageSize', 'skip', 'total'], changes, false)) {
            this.pagerContext.notifyChanges({
                pageSize: this.pageSize,
                skip: this.skip,
                total: this.total
            });
        }
        if (changes.template) {
            changes.template.currentValue ? this.clearResponsiveClasses() : this.resizeHandler();
        }
        if (changes.options) {
            this.settings.responsive ? this.resizeHandler() : this.clearResponsiveClasses();
        }
    };
    PagerComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    };
    PagerComponent.prototype.changePage = function (event) {
        this.pageChange.emit(event);
    };
    PagerComponent.prototype.onInnerFocusIn = function (event, position) {
        var _this = this;
        this.zone.onStable.pipe(operators_1.take(1)).subscribe(function () {
            if (position === 'start') {
                if (event.relatedTarget === _this.element.nativeElement) {
                    _this.focusFirstElement();
                }
                else {
                    _this.focusLastElement();
                }
            }
            else {
                _this.focusFirstElement();
            }
        });
    };
    PagerComponent.prototype.clearResponsiveClasses = function () {
        var element = this.element.nativeElement;
        this.renderer.removeClass(element, 'k-pager-sm');
        this.renderer.removeClass(element, 'k-pager-md');
    };
    PagerComponent.prototype.shouldTriggerPageChange = function (target, condition) {
        return this.navigationService.enabled &&
            target === this.element.nativeElement &&
            condition;
    };
    PagerComponent.prototype.focusFirstElement = function () {
        var first = dom_queries_1.findFocusableChild(this.element.nativeElement, true);
        if (first) {
            first.focus();
        }
    };
    PagerComponent.prototype.focusLastElement = function () {
        var last = dom_queries_1.findLastFocusableChild(this.element.nativeElement, true);
        if (last) {
            last.focus();
        }
    };
    PagerComponent.prototype.updateCellContext = function () {
        if (this.cellContext) {
            this.cellContext.focusGroup = this.focusGroup;
        }
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], PagerComponent.prototype, "total", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], PagerComponent.prototype, "skip", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], PagerComponent.prototype, "pageSize", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object),
        tslib_1.__metadata("design:paramtypes", [Object])
    ], PagerComponent.prototype, "options", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", pager_template_directive_1.PagerTemplateDirective)
    ], PagerComponent.prototype, "template", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], PagerComponent.prototype, "pageChange", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-pager-wrap'),
        tslib_1.__metadata("design:type", Boolean)
    ], PagerComponent.prototype, "pagerWrapClass", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-grid-pager'),
        tslib_1.__metadata("design:type", Boolean)
    ], PagerComponent.prototype, "gridPagerClass", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-state-focused'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], PagerComponent.prototype, "isFocused", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.aria-label'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], PagerComponent.prototype, "pagerAriaLabel", null);
    tslib_1.__decorate([
        core_1.HostListener('focusin', ['$event']),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PagerComponent.prototype, "onFocusIn", null);
    tslib_1.__decorate([
        core_1.HostListener('focusout'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", void 0)
    ], PagerComponent.prototype, "onFocusOut", null);
    tslib_1.__decorate([
        core_1.HostListener('keydown.escape'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", void 0)
    ], PagerComponent.prototype, "onEscape", null);
    tslib_1.__decorate([
        core_1.HostListener('keydown.enter', ['$event']),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PagerComponent.prototype, "onEnter", null);
    tslib_1.__decorate([
        core_1.HostListener('keydown.arrowleft', ['$event']),
        core_1.HostListener('keydown.pageup', ['$event']),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PagerComponent.prototype, "navigateToPreviousPage", null);
    tslib_1.__decorate([
        core_1.HostListener('keydown.arrowright', ['$event']),
        core_1.HostListener('keydown.pagedown', ['$event']),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PagerComponent.prototype, "navigateToNextPage", null);
    tslib_1.__decorate([
        core_1.HostListener('keydown.home', ['$event']),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PagerComponent.prototype, "navigateToFirstPage", null);
    tslib_1.__decorate([
        core_1.HostListener('keydown.end', ['$event']),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PagerComponent.prototype, "navigateToLastPage", null);
    PagerComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-pager',
            providers: [{
                    provide: focus_root_1.FOCUS_ROOT_ACTIVE,
                    useValue: true
                }, {
                    provide: focus_root_1.FocusRoot,
                    deps: [focus_root_1.FOCUS_ROOT_ACTIVE],
                    useClass: focus_root_1.FocusRoot
                }, {
                    provide: focus_group_1.FocusGroup,
                    deps: [focus_root_1.FocusRoot],
                    useClass: focus_group_1.FocusGroup
                }],
            template: "\n        <div\n            *ngIf=\"navigationService.enabled\"\n            class=\"k-sr-only\"\n            [tabindex]=\"focusTrapTabIndex\"\n            [attr.aria-hidden]=\"true\"\n            (focusin)=\"onInnerFocusIn($event, 'start')\">\n        </div>\n        <ng-container\n            *ngIf=\"template?.templateRef\"\n            [ngTemplateOutlet]=\"template.templateRef\"\n            [ngTemplateOutletContext]=\"templateContext\">\n        </ng-container>\n        <ng-container *ngIf=\"!template?.templateRef\">\n            <kendo-pager-prev-buttons *ngIf=\"settings.previousNext\"></kendo-pager-prev-buttons>\n            <kendo-pager-numeric-buttons\n                *ngIf=\"settings.type === 'numeric'\"\n                [buttonCount]=\"settings.buttonCount\">\n            </kendo-pager-numeric-buttons>\n            <kendo-pager-input *ngIf=\"settings.type === 'input'\"></kendo-pager-input>\n            <kendo-pager-next-buttons *ngIf=\"settings.previousNext\"></kendo-pager-next-buttons>\n            <kendo-pager-info *ngIf='settings.info'></kendo-pager-info>\n            <kendo-pager-page-sizes\n                *ngIf=\"settings.pageSizes\"\n                [pageSizes]=\"settings.pageSizes\"\n                #pageSizes\n            ></kendo-pager-page-sizes>\n        </ng-container>\n        <div\n            *ngIf=\"navigationService.enabled\"\n            class=\"k-sr-only\"\n            [tabindex]=\"focusTrapTabIndex\"\n            [attr.aria-hidden]=\"true\"\n            (focusin)=\"onInnerFocusIn($event, 'end')\">\n        </div>\n        <kendo-resize-sensor *ngIf=\"settings.responsive\" (resize)=\"resizeHandler()\"></kendo-resize-sensor>\n  "
        }),
        tslib_1.__param(6, core_1.Optional()), tslib_1.__param(6, core_1.Inject(cell_context_1.CELL_CONTEXT)),
        tslib_1.__metadata("design:paramtypes", [pager_context_service_1.PagerContextService,
            navigation_service_1.NavigationService,
            core_1.ElementRef,
            core_1.Renderer2,
            core_1.NgZone,
            kendo_angular_l10n_1.LocalizationService, Object, focus_root_1.FocusRoot,
            focus_group_1.FocusGroup])
    ], PagerComponent);
    return PagerComponent;
}());
exports.PagerComponent = PagerComponent;
