import tmpl from './vehicleMonthlyUsageEditPopup.html';


angular.module("app.site").component("vehicleMonthlyUsageEdit",
        {
            template: tmpl,
            bindings: {
                initParams: '=',
                buttonMethods: '=',
                buttonStates: '=',
                buttons: '=',
                closeOnSave: '=',
                wnd: '='
            },
            controller: vehicleMonthlyUsageEditCtrl
        });

    // @ts-ignore
    vehicleMonthlyUsageEditCtrl.$inject = ['$scope', 'amtConstants', 'amtCommandQuerySvc', 'confirmSvc', 'enums', 'amtXlatSvc', 'notifySvc', 'WindowFactory', 'errorReporter'];

    function vehicleMonthlyUsageEditCtrl($scope, amtConstants, amtCommandQuerySvc, confirmSvc, enums, xlatSvc, notifySvc, WindowFactory, errorReporter) {

        var vm = this;
        $scope.vm = this;

        vm.url = amtConstants.url;

        vm.model = {
            id: 0,
            usage: 0.0,
            applyToRemainingMonths: false,
            applyDailyRateToRemainingMonths: false
        };

        this.$onInit = function () {

            vm.model.id = vm.wnd.initParams.monthlyusageid;
            vm.model.usage = vm.wnd.initParams.monthlyusage;
            vm.model.applyToRemainingMonths = false;
            vm.model.applyDailyRateToRemainingMonths = false;
            vm.model.forecastId = vm.wnd.initParams.forecastId;

            vm.yearMonth = vm.wnd.initParams.yearmonth;

            vm.buttons = [
                {
                    primary: true,
                    cancel: false,
                    value: 'common.save_label',
                    name: 'saveButton',
                    click: 'save',
                    type: enums.buttonTypes.button
                },
                {
                    primary: false,
                    cancel: true,
                    value: 'common.cancel_label',
                    name: 'cancelButton',
                    click: 'cancel',
                    type: enums.buttonTypes.button
                }
            ];

            vm.buttonStates = {
                saveButton: {
                    visible: true,
                    disabled: true
                },
                cancelButton: {
                    visible: true,
                    disabled: false
                }
            };

            vm.buttonMethods = {
                save: vm.save,
                cancel: vm.onCancelPopup
            };
        };

        vm.applyToRemainingMonths = function (item) {
            vm.model.applyDailyRateToRemainingMonths = false;
            vm.model.applyToRemainingMonths = item;
        };

        vm.applyDailyRateToRemainingMonths = function (item) {
            vm.model.applyToRemainingMonths = false;
            vm.model.applyDailyRateToRemainingMonths = item;
        };

        vm.getMonthForDisplay = function (month) {
            var tzDate = new Date(Math.floor(month / 100), ((month % 100) - 1)); // Javascript months are indexed from 0
            return tzDate;
        };

        vm.save = function () {

            if (+vm.model.id !== 0) {

                vm.wnd.processing = true;

                amtCommandQuerySvc.put(amtConstants.url.updateVehicleMonthlyUsage, vm.model).then(function (response) {

                    notifySvc.success(xlatSvc.xlat("forecastVehicles.messageVehicleMonthlyUsageUpdateSuccess"));

                    if (vm.wnd.onDataChanged) {
                        vm.wnd.onDataChanged();
                    }

                    WindowFactory.closeWindow(vm.wnd);

                }).catch(function (error) {
                    errorReporter.logError(error);
                }).finally(function () {
                    vm.wnd.processing = false;
                });
            }
        }

        vm.onCancelPopup = function () {
            confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                vm.form.dirty = false;
                WindowFactory.closeWindow(vm.wnd);
            });
        }

        $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing'], function () {
            vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.form.$pristine || !!vm.wnd.processing;
        });
    }
