//import angular from 'angular';
import FileManagement from '../../../services/fileManagement';
import tmpl from './attachmentPopup.html';



class attachmentPopupCtrl implements ng.IComponentController, IWindowController {

    form: ng.IFormController;
    wnd: IWindowObj;

    initParams: any = {};

    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: (button: string) => void }

    mode = AttachmentMode.open;

    readonly: boolean;

    equipmentId: guid;
    attachmentId?: guid;
    source: string;

    file: IFile;
    attachment: IEquipmentAttachment;

    acceptedFileTypes: string[];

    showComment: boolean;

    static $inject = ['$scope', 'amtCommandQuerySvc', 'confirmSvc', '$timeout', 'appConfig',
        'amtXlatSvc', 'equipmentUrls', 'fileManagement', 'WindowFactory', 'notifySvc', 'errorReporter'];

    constructor(private $scope: ng.IScope, private amtCommandQuerySvc: IAmtCommandQuerySvc, private confirmSvc: IConfirmSvc, private $timeout: ng.ITimeoutService,
        public appConfig: IAppConfig, private amtXlatSvc: IAmtXlatSvc, private equipmentUrls: IEquipmentUrls, private fileManagement: FileManagement,
        private WindowFactory: IWindowFactory, private notifySvc: INotifySvc, private errorReporter: IErrorReporter) {

        if (this.wnd) {
            // pass along dirty flag to the window for use on closing minimised windows
            this.$scope.$watch(() => this.form.$dirty, () => { this.wnd.isDirty = this.form.$dirty });
        }

        this.$scope.$watchGroup([() => this.form.$invalid, () => this.form.$pristine, () => this.readonly, () => this.wnd.processing], () => {
            this.buttonStates.saveButton.visible = !this.readonly;
            this.buttonStates.saveButton.disabled = this.form.$invalid || this.form.$pristine || this.readonly || this.wnd.processing;
        });
    }

    async $onInit() {

        this.wnd.processing = true;
        this.wnd.onClose = () => { return this.closeWindow() };

        this.acceptedFileTypes = this.fileManagement.getAcceptedFileExtensions([FileType.document, FileType.text, FileType.pdf, FileType.spreadsheet, FileType.image]);

        this.mode = this.initParams.mode || AttachmentMode.open;
        this.equipmentId = this.initParams.equipmentId;
        this.attachmentId = this.initParams.attachmentId;
        this.source = this.initParams.source;

        this.readonly = this.mode == AttachmentMode.open || this.initParams.readonly; // new and edit can edit

        this.showComment = this.source == AttachmentType.equipmentAttachment || this.source == AttachmentType.specification;

        this.WindowFactory.addButton(this, 'common.save_label', 'saveButton', () => this.onSave(), true);
        this.WindowFactory.addButton(this, 'common.cancel_label', 'cancelButton', () => this.closeWindow());

        await this.loadAttachment();

        this.wnd.processing = false;
    }

    public async loadAttachment() {

        if (this.mode != AttachmentMode.new) {

            let criteria = {
                attachmentId: this.attachmentId,
                source: this.source
            };

            this.attachment = await this.amtCommandQuerySvc.post(this.equipmentUrls.url.getAttachment, criteria);

        } else {
            // new attachment, add some default values
            this.attachment = {
                equipmentId: this.equipmentId,
                createdDate: new Date(),
                source: this.source,
                name: '',
                description: '',
                uploaded: false,
                persisted: false
            };
        }
    };

    public async closeWindow() {
        try {
            await this.confirmSvc.confirmSaveChange(this.form.$dirty);
        } catch (error) {
            return; // they cancelled
        }

        this.form.$setPristine();

        this.WindowFactory.closeWindow(this.wnd);
    }

    public async onSave() {

        this.wnd.processing = true;

        try {

            await this.fileManagement.processFileUploads([this.attachment]);

            await this.amtCommandQuerySvc.post(this.equipmentUrls.url.saveAttachment, this.attachment);

            // display a message for the user to show that the save was successful
            this.notifySvc.success(this.amtXlatSvc.xlat("equipment.messageAttachmentSaved"));

            // clear the file
            this.file = null;

            if (this.wnd.onDataChanged)
                this.wnd.onDataChanged();

            this.form.$setPristine();

            // close the window
            this.closeWindow();

        } catch (error) {
            this.errorReporter.logError(error);
        }

        this.wnd.processing = false;
    }

    public fileSelected() {
        this.form.$setDirty();
    }
}

class attachmentPopupComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '<',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = attachmentPopupCtrl;
    public controllerAs = "vm";
}

angular.module('app.equipment').component('attachmentPopup', new attachmentPopupComponent());