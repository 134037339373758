/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var GridLayoutItemComponent = /** @class */ (function () {
    function GridLayoutItemComponent(renderer, element) {
        this.renderer = renderer;
        this.element = element;
    }
    GridLayoutItemComponent.prototype.ngOnInit = function () {
        this.setItemStyle();
    };
    GridLayoutItemComponent.prototype.ngOnChanges = function () {
        this.setItemStyle();
    };
    GridLayoutItemComponent.prototype.setItemStyle = function () {
        var row = this.row || 'auto';
        var col = this.col || 'auto';
        var rowSpan = this.rowSpan ? "span " + this.rowSpan : 'auto';
        var colSpan = this.colSpan ? "span " + this.colSpan : 'auto';
        var gridAreaStyle = row + " / " + col + " / " + rowSpan + " / " + colSpan;
        this.renderer.setStyle(this.element.nativeElement, 'grid-area', gridAreaStyle);
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], GridLayoutItemComponent.prototype, "row", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], GridLayoutItemComponent.prototype, "col", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], GridLayoutItemComponent.prototype, "rowSpan", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], GridLayoutItemComponent.prototype, "colSpan", void 0);
    GridLayoutItemComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-gridlayout-item',
            template: "\n        <ng-content></ng-content>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.Renderer2,
            core_1.ElementRef])
    ], GridLayoutItemComponent);
    return GridLayoutItemComponent;
}());
exports.GridLayoutItemComponent = GridLayoutItemComponent;
