/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var util_1 = require("../common/util");
var events_1 = require("./events");
var util_2 = require("./util");
/**
 * @hidden
 */
var TabStripService = /** @class */ (function () {
    function TabStripService(localization, ngZone) {
        this.localization = localization;
        this.ngZone = ngZone;
    }
    TabStripService.prototype.onKeyDown = function (event) {
        if (!util_2.isTabElement(event.target)) {
            return;
        }
        var key = event.keyCode;
        if (this.shouldHandleKey(key)) {
            event.preventDefault();
        }
        else {
            return;
        }
        if (util_1.isNavigationKey(key) || util_1.isArrowKey(key)) {
            this.onNavigate(key);
        }
        else if (key === kendo_angular_common_1.Keys.Delete) {
            this.onDelete();
        }
    };
    TabStripService.prototype.onTabSelect = function (tab, index) {
        var selectArgs = new events_1.SelectEvent(index, tab.title);
        this.owner.tabSelect.emit(selectArgs);
        if (!selectArgs.isDefaultPrevented()) {
            if (tab.selected) {
                this.focusTabHeader(index);
                return;
            }
            this.selectTab(tab, index);
        }
    };
    TabStripService.prototype.selectTab = function (tab, index) {
        util_2.resetTabSelection(this.owner.tabs);
        this.focusTabHeader(index);
        tab.selected = true;
        if (this.owner.isScrollable) {
            this.owner.scrollToSelectedTab();
        }
    };
    TabStripService.prototype.onTabClose = function (tab, index) {
        var closeArgs = new events_1.TabCloseEvent(index, tab);
        this.owner.tabClose.emit(closeArgs);
    };
    TabStripService.prototype.onNavigate = function (keyCode) {
        var _this = this;
        var _a = util_2.getActiveTab(this.owner.tabs), activeTab = _a.tab, activeIndex = _a.index;
        if (!core_1.NgZone.isInAngularZone()) {
            this.ngZone.run(function () {
                if (activeIndex < 0) {
                    _this.owner.selectTab(_this.firstNavigatableIndex());
                    return;
                }
                activeTab.focused = false;
                var nextIndex = _this.computeNextIndex(activeIndex, keyCode);
                _this.activateTab(nextIndex);
            });
        }
    };
    TabStripService.prototype.onDelete = function () {
        var _this = this;
        var _a = util_2.getActiveTab(this.owner.tabs), activeTab = _a.tab, activeTabIndex = _a.index;
        if (util_2.isTabClosable(activeTab, this.owner.closable) && !activeTab.disabled) {
            this.ngZone.run(function () {
                _this.onTabClose(activeTab, activeTabIndex);
            });
        }
    };
    TabStripService.prototype.activateTab = function (index) {
        var tab = util_2.getTabByIndex(this.owner.tabs, index);
        if (tab.disabled) {
            this.focusTabHeader(index);
            tab.focused = true;
        }
        else {
            this.onTabSelect(tab, index);
        }
    };
    TabStripService.prototype.focusTabHeader = function (index) {
        var tabHeader = util_2.getTabHeaderByIndex(this.owner.tabHeaderContainers, index);
        tabHeader.nativeElement.focus();
    };
    TabStripService.prototype.shouldHandleKey = function (keyCode) {
        if (util_1.isNavigationKey(keyCode)) {
            return true;
        }
        if (util_2.isTablistHorizontal(this.owner.tabPosition) && util_1.isHorizontalArrowKey(keyCode)) {
            return true;
        }
        if (!util_2.isTablistHorizontal(this.owner.tabPosition) && util_1.isVerticalArrowKey(keyCode)) {
            return true;
        }
        if (keyCode === kendo_angular_common_1.Keys.Delete) {
            return true;
        }
        return false;
    };
    TabStripService.prototype.computeNextIndex = function (activeIndex, keyCode) {
        switch (keyCode) {
            case this.invertKeys(kendo_angular_common_1.Keys.ArrowLeft, kendo_angular_common_1.Keys.ArrowRight):
            case this.invertKeys(kendo_angular_common_1.Keys.ArrowUp, kendo_angular_common_1.Keys.ArrowDown):
                return this.prevNavigatableIndex(activeIndex);
            case this.invertKeys(kendo_angular_common_1.Keys.ArrowRight, kendo_angular_common_1.Keys.ArrowLeft):
            case this.invertKeys(kendo_angular_common_1.Keys.ArrowDown, kendo_angular_common_1.Keys.ArrowUp):
                return this.nextNavigatableIndex(activeIndex);
            case kendo_angular_common_1.Keys.Home:
                return this.firstNavigatableIndex();
            case kendo_angular_common_1.Keys.End:
                return this.lastNavigatableIndex();
            default:
                return;
        }
    };
    TabStripService.prototype.invertKeys = function (original, inverted) {
        return this.localization.rtl ? inverted : original;
    };
    TabStripService.prototype.firstNavigatableIndex = function () {
        return 0;
    };
    TabStripService.prototype.lastNavigatableIndex = function () {
        return this.owner.tabs.length - 1;
    };
    TabStripService.prototype.prevNavigatableIndex = function (selectedIndex) {
        if (selectedIndex - 1 < 0) {
            return this.lastNavigatableIndex();
        }
        return selectedIndex - 1;
    };
    TabStripService.prototype.nextNavigatableIndex = function (selectedIndex) {
        if (selectedIndex + 1 >= this.owner.tabs.length) {
            return this.firstNavigatableIndex();
        }
        return selectedIndex + 1;
    };
    TabStripService = tslib_1.__decorate([
        core_1.Injectable(),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService,
            core_1.NgZone])
    ], TabStripService);
    return TabStripService;
}());
exports.TabStripService = TabStripService;
