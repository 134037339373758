//import angular from 'angular';
import tmpl from './amtProgressBar.html';

angular.module('amtFramework').directive('amtProgressBar', function () {
            return {
                restrict: 'E',
                scope: {
                    title: '@',
                    control: '='
                },
                template: tmpl,
                link: function(scope: any, element, attr) {
                    var modal: any = $('#myModal');

                    scope.internalControl = scope.control || {};
                    scope.internalControl.show = function() {
                        modal.modal('show');
                    };
                    scope.internalControl.hide = function() {
                        modal.modal('hide');
                    };
                },
            };
});
