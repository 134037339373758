/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var core_1 = require("@angular/core");
var column_component_1 = require("../../columns/column.component");
var filter_service_1 = require("../filter.service");
var utils_1 = require("../../utils");
var menu_tabbing_service_1 = require("./menu-tabbing.service");
/**
 * @hidden
 */
var StringFilterMenuInputComponent = /** @class */ (function () {
    function StringFilterMenuInputComponent(localizationService) {
        this.localizationService = localizationService;
        this.operators = [];
    }
    Object.defineProperty(StringFilterMenuInputComponent.prototype, "columnLabel", {
        get: function () {
            var localizationMsg = this.localizationService.get('filterInputLabel') || '';
            var columnName = this.column ? this.column.title || this.column.field : '';
            return utils_1.replaceMessagePlaceholder(localizationMsg, 'columnName', columnName);
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Array)
    ], StringFilterMenuInputComponent.prototype, "operators", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", column_component_1.ColumnComponent)
    ], StringFilterMenuInputComponent.prototype, "column", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], StringFilterMenuInputComponent.prototype, "filter", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], StringFilterMenuInputComponent.prototype, "operator", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], StringFilterMenuInputComponent.prototype, "currentFilter", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", filter_service_1.FilterService)
    ], StringFilterMenuInputComponent.prototype, "filterService", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], StringFilterMenuInputComponent.prototype, "isFirstDropDown", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", menu_tabbing_service_1.MenuTabbingService)
    ], StringFilterMenuInputComponent.prototype, "menuTabbingService", void 0);
    StringFilterMenuInputComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-grid-string-filter-menu-input',
            template: "\n        <kendo-grid-filter-menu-input-wrapper\n            [column]=\"column\"\n            [filter]=\"filter\"\n            [operators]=\"operators\"\n            [defaultOperator]=\"operator\"\n            [currentFilter]=\"currentFilter\"\n            [filterService]=\"filterService\"\n            [isFirstDropDown]=\"isFirstDropDown\"\n            [menuTabbingService]=\"menuTabbingService\"\n            >\n            <input\n                kendoTextBox\n                kendoFilterInput\n                [columnLabel]=\"columnLabel\"\n                [filterDelay]=\"0\"\n                [ngModel]=\"currentFilter?.value\" />\n        </kendo-grid-filter-menu-input-wrapper>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService])
    ], StringFilterMenuInputComponent);
    return StringFilterMenuInputComponent;
}());
exports.StringFilterMenuInputComponent = StringFilterMenuInputComponent;
