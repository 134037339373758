//import angular from 'angular';
import FileManagement from '../../../../services/fileManagement';
import OcDateSvc from '../../../../services/ocDateSvc';
import tmpl from './addEditContinuousImprovement.html';



type AddEditContinuousImprovement_Data = {
    id: guid;
    attachments: IAttachment[];
    description?: string;
    details?: any;
    status?: any;
    date?: Date;
    responsibility?: any;
}

type AddEditContinuousImprovement_InitParams = {
    data: AddEditContinuousImprovement_Data;
    endOfMonthId: guid;
};

class AddEditContinuousImprovementCtrl implements ng.IController, IWindowController {

    form: ng.IFormController;
    wnd: IWindowObj;

    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: (button: string) => void }

    initParams: AddEditContinuousImprovement_InitParams;

    readonly: boolean;

    endOfMonthId: guid;

    data: AddEditContinuousImprovement_Data;

    acceptedFileTypes: string[];

    static $inject = ['$scope', 'endOfMonthSvc', 'amtXlatSvc', 'WindowFactory', 'confirmSvc', 'notifySvc',
        'errorReporter', 'endOfMonthUrls', 'helperSvc', 'ocSecuritySvc', 'fileManagement', 'ocDateSvc'];

    constructor(private $scope: ng.IScope, private endOfMonthSvc: IEndOfMonthSvc, private amtXlatSvc: IAmtXlatSvc,
        private WindowFactory: IWindowFactory, private confirmSvc: IConfirmSvc, private notifySvc: INotifySvc,
        private errorReporter: IErrorReporter, public endOfMonthUrls: IEndOfMonthUrls, private helperSvc: IHelperSvc,
        private ocSecuritySvc: IOcSecuritySvc, private fileManagement: FileManagement, private ocDateSvc: OcDateSvc) {

        this.$scope.$watchGroup([() => this.form.$invalid, () => this.form.$pristine, () => this.wnd.processing], () => {
            this.buttonStates.saveButton.disabled = this.form.$invalid || this.form.$pristine || this.wnd.processing;
            this.buttonStates.saveAndAddAnotherButton.disabled = this.form.$invalid || this.form.$pristine || this.wnd.processing;
        });
    }

    async $onInit() {

        if (this.wnd) {
            this.wnd.onClose = () => this.closeWindow();
        }

        this.wnd.processing = true;

        this.acceptedFileTypes = this.fileManagement.getAcceptedFileExtensions([FileType.image, FileType.document, FileType.spreadsheet, FileType.text, FileType.pdf]);

        this.data = angular.copy(this.initParams.data);
        this.endOfMonthId = this.initParams.endOfMonthId;

        if (!this.data)
            this.data = { id: null, attachments: [] };

        this.readonly = !!(this.data.id && !this.ocSecuritySvc.isAuthorised('Security.Site.EndOfMonth', AccessTypes.readWrite));

        this.WindowFactory.addButton(this, 'common.save_label', 'saveButton', () => this.save(false), true);
        this.WindowFactory.addButton(this, 'common.saveAndAddAnother_label', 'saveAndAddAnotherButton', () => this.save(true), false, !this.readonly && !this.data.id);
        this.WindowFactory.addButton(this, 'common.cancel_label', 'cancelButton', () => this.closeWindow());

        try {
            await this.getAttachments();
        } finally {
            this.form.$setPristine();
            this.wnd.processing = false;
        }
    }

    async save(reset: boolean) {

        this.wnd.processing = true;

        try {

            for (let attachment of this.data.attachments || [])
                attachment.source = AttachmentType.siteMonthReportHSEContinuousImprovementAttachment

            await this.fileManagement.processFileUploads(this.data.attachments);

            let criteria = {
                endOfMonthId: this.endOfMonthId,
                id: this.data.id,
                description: this.data.description,
                statusId: this.helperSvc.getKey(this.data.status),
                details: this.data.details,
                responsibility: this.data.responsibility,
                date: this.ocDateSvc.removeLocalTimeZoneOffset(this.data.date),
                attachments: this.data.attachments
            };

            await this.endOfMonthSvc.saveContinuousImprovementAsync(criteria);

            this.notifySvc.success(this.amtXlatSvc.xlat('endOfMonth.continuousImprovementSaveSuccessful'));

            if (this.wnd.onDataChanged) {
                this.wnd.onDataChanged();
            }

            if (reset) {

                // restore the defaults
                this.data = { id: null, attachments: [] };
                this.form.$setPristine();

            } else {

                this.form.$setPristine();
                this.closeWindow();
            }

        } catch (error) {
            this.errorReporter.logError(error);
        } finally {
            this.wnd.processing = false;
        }
    }

    async closeWindow() {

        try {
            await this.confirmSvc.confirmSaveChange(this.form.$dirty);
        } catch {
            return; // they cancelled
        }

        this.form.$setPristine();

        this.WindowFactory.closeWindow(this.wnd);
    }

    async getAttachments(): Promise<void> {

        if (!this.data.id)
            return;

        try {
            this.data.attachments = await this.endOfMonthSvc.getContinuousImprovementAttachmentsAsync(this.data.id);
        } catch (error) {
            this.errorReporter.logError(error);
        }
    }
}

class AddEditContinuousImprovementComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = AddEditContinuousImprovementCtrl;
    public controllerAs = 'vm';
}

angular.module('app.site').component('addEditContinuousImprovement', new AddEditContinuousImprovementComponent());