//import angular from 'angular';


angular.module("app").factory("vehicleUrls", function () {
        var apiPath = baseUrl + 'api/';
        var vehicleController = 'equipment/vehicle/';
        var equipmentController = 'equipment/common/';

        return {
            getVehicles: apiPath + vehicleController + 'search',
            getDefaults: vehicleController + 'getDefaults', // default values for search
            searchExport: vehicleController + 'searchExport',

            getFleetDetails: vehicleController + 'getFleetDetails',
                    
            // details
            getVehicleDetails: vehicleController + 'getVehicleDetails',
            updateVehicleDetails: vehicleController + 'updateVehicleDetails',
            getSchematic: vehicleController + 'getSchematic',

            // status
            getVehicleStatusDetails: vehicleController + 'getVehicleStatusDetails',
            generateWarrantyClaimNumber: vehicleController + 'generateWarrantyClaimNumber',
            changeVehicleStatus: vehicleController + 'changeVehicleStatus',                    
            addUpdateMoveEvent: equipmentController + 'addUpdateMoveEvent',
            undoMoveEvents: equipmentController + 'undoMoveEvents',
            getValidFitmentsForWheelPosition: vehicleController + 'getValidFitmentsForWheelPosition',                    

            // reference data
            getClients: baseApiUrl + 'usersecurity/getClients',
            getVehicleEventUsers: baseApiUrl + vehicleController + 'getVehicleEventUsers',
            getReadingTypeNames: baseApiUrl + vehicleController + 'getReadingTypeNames',
            getSites: baseApiUrl + 'usersecurity/getSites',
            getSitesRaw: 'usersecurity/getSites',
            getPressureSensors: baseApiUrl + vehicleController + 'getPressureSensors',
            getVehicleTypes: baseApiUrl + vehicleController + 'getVehicleTypes',
            getManufacturers: baseApiUrl + vehicleController + 'getManufacturers',
            getManufacturers_short: vehicleController + 'getManufacturers',
            getSpecifications: baseApiUrl + vehicleController + 'getSpecifications',
            getDistanceTypes: baseApiUrl + vehicleController + 'getDistanceTypes',
            getLocations: baseApiUrl + vehicleController + 'getLocations',
            getSizes: baseApiUrl + vehicleController + 'getSizes',
            getOwners: baseApiUrl + vehicleController + 'getOwners',
            getStatuses: baseApiUrl + vehicleController + 'getStatuses',                    
                    
            transferVehicle: vehicleController + 'transferVehicle',                    
            getOutOfServiceDetails: vehicleController + 'getOutOfServiceDetails',
            undoReceive: vehicleController + 'undoReceive',
            undoTransfer: vehicleController + 'undoTransfer',
            checkin: vehicleController + 'checkIn',
            checkout: vehicleController + 'checkOut',
            getFleetSpeed: vehicleController + 'getFleetSpeed',
            getVehicleLayoutCode: vehicleController  + 'getVehicleLayoutCode',
            deleteMaintenanceSession: vehicleController + 'deleteMaintenanceSession',
            swapComponentHistory: vehicleController + 'swapComponentHistory',
            swapPositionFitments: vehicleController + 'swapPositionFitments',
            stripAssembly: vehicleController + 'stripAssembly',

            getCurrentVehiclesLastReading: vehicleController + 'getVehicles',

            getSpecificationWheelPositions: vehicleController + 'getSpecificationWheelPositions',
                    
            // filter values
            getFilterOptions: baseApiUrl + vehicleController + 'getFilterOptions',
            getEmptyFilterOptions: baseApiUrl + vehicleController + 'getEmptyFilterOptions'
        };
    });
