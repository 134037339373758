/**
 * @ngdoc directive
 * @name amtFramework.filter.directive:amtComboboxFilteritem
 * @restrict E
 * @requires amtFramework.filter.directive:amtFilter
 * 
 * @param {string} [idField=key] Name of the property to bind the id to
 * @param {string} [textField=name] Name of the property to bind the text to
 * @param {string} label Label to display above the control
 * @param {string} propertyName Name of the property that is added to filterValues property of {@link amtFramework.filter.directive:amtFilter amtFilter}
 * @param {expression=} [isRequired=false] Required
 * @param {expression=} options Array of objects to display in the combobox. [{key: '1', name: 'one'}, {key: '2', name: 'two'}]
 * @param {expression=} [autoComplete=true] Autocomplete
 * @param {string} url Url to an http GET method wich accepts a ?searchText= parameter
 * @param {expression=} extensionParams Object that contains additional values that should be passed in the querystring.
 * @param {expression=} [searchable=true] Determines if the searchbox is displayed
 * @param {expression=} selectedItem Selected Item
 * @param {callback=} onSelectedChange Function to call when selectedItem changed
 *                                     Signature: onselectedChange(option)
 * @param {expression=} [isDisabled=false] Disabled
 * @param {expression=} isResetDependency ???
 *
 * @description
 * Filter item that let's the user filter on a single value selected from a list
 * 
 * @example
 * See {@link amtFramework.filter.directive:amtFilter amtFilter}
 */
//import angular from 'angular';
import tmpl from './amtComboboxFilteritem.html';

angular.module('amtFramework.filter').directive('amtComboboxFilteritem', function() {
            
            return {
                require: '^amtFilter',
                restrict: 'E',
                scope: {
                    idField: '@',
                    textField: '@',
                    label: '@',
                    propertyName: "@",
                    isRequired: '=',
                    options: '=',
                    autoComplete: '=',
                    preserveOrder: '=',
                    url: '@',
                    extensionParams: '=',
                    searchable: '=',
                    selectedItem: '@',
                    onSelectedChange: '&',
                    isDisabled: '=?',
                    useHttpPost: '=?',
                    isResetDependency: '@',
                    placeHolder: "@"
                },
                template: tmpl,
                link: function (scope: any, elem, attrs, filterCtrl) {
                    var idField = scope.idField || "key";
                    var textField = scope.textField || "name";
                    scope.internalControl = scope.control || {};
                    scope.filterItem = { propertyName: scope.propertyName, value: undefined };
                    filterCtrl.addFilterItem(scope.filterItem);

                    if (scope.selectedItem !== undefined && scope.selectedItem !== "") {
                        scope.selectedItem = typeof (scope.selectedItem) !== "object" ? JSON.parse(scope.selectedItem) : scope.selectedItem;
                        scope.filterItem.value = scope.selectedItem[idField];
                    }
                    else {
                        scope.selectedItem = undefined;
                    }

                    scope.$watch('selectedItem', function (item, oldItem) {
                        if (item === "" && oldItem === "") {
                            scope.selectedItem = undefined;
                            return;
                        }
                        if (item === oldItem) {
                            return;
                        }
                        if(item){
                            scope.selectedItem = typeof(item) !== "object" ? JSON.parse(item) : item;
                            scope.filterItem.value = scope.selectedItem[idField];
                        }
                        else{
                            scope.selectedItem = null;
                        }
                        if (attrs.onSelectedChange !== undefined) {
                            scope.onSelectedChange({ selectedItem: scope.selectedItem });
                        }
                    });
                }
            };
});
