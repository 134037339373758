/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var column_menu_item_base_1 = require("./column-menu-item-base");
/* tslint:disable:max-line-length */
/**
 * Represents the component for editing column filters in the Grid that can be placed
 * inside a [ColumnMenuTemplate]({% slug api_grid_columnmenutemplatedirective %}) directive.
 *
 * > You have to set the [ColumnMenuService]({% slug api_grid_columnmenuservice %}) that is passed by
 * > the template to the service input of the `kendo-grid-columnmenu-filter` component.
 *
 * @example
 * ```ts-preview
 *
 * _@Component({
 *     selector: 'my-app',
 *     template: `
 *       <kendo-grid [kendoGridBinding]="data" [columnMenu]="true">
 *          <ng-template kendoGridColumnMenuTemplate let-service="service">
 *              <kendo-grid-columnmenu-filter [service]="service">
 *              </kendo-grid-columnmenu-filter>
 *          </ng-template>
 *          <kendo-grid-column field="Field1"></kendo-grid-column>
 *          <kendo-grid-column field="Field2"></kendo-grid-column>
 *       </kendo-grid>
 *     `
 * })
 *
 * class AppComponent {
 *   public data: any[] = [{ Field1: 'Foo', Field2: 'Bar' }];
 * }
 *
 * ```
 */
var ColumnMenuPositionComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ColumnMenuPositionComponent, _super);
    function ColumnMenuPositionComponent(localization, hostElement) {
        var _this = _super.call(this) || this;
        _this.localization = localization;
        _this.hostElement = hostElement;
        /**
         * Fires when the content is expanded.
         */
        _this.expand = new core_1.EventEmitter();
        /**
         * Fires when the content is collapsed.
         */
        _this.collapse = new core_1.EventEmitter();
        /**
         * Specifies if the content is expanded.
         */
        _this.expanded = false;
        /**
         * @hidden
         */
        _this.actionsClass = 'k-columnmenu-actions';
        _this._isLast = false;
        return _this;
    }
    Object.defineProperty(ColumnMenuPositionComponent.prototype, "isLast", {
        /**
         * @hidden
         */
        get: function () {
            return this._isLast;
        },
        /**
         * @hidden
         */
        set: function (value) {
            this.service.menuTabbingService.lastFocusable = this.getLastFocusableItem();
            this._isLast = value;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @hidden
     */
    ColumnMenuPositionComponent.prototype.onTab = function (e, isLastItem) {
        if (this.isLast && isLastItem) {
            e.preventDefault();
            e.stopImmediatePropagation();
            if (this.service) {
                this.service.menuTabbingService.firstFocusable.focus();
            }
        }
    };
    /**
     * @hidden
     */
    ColumnMenuPositionComponent.prototype.onCollapse = function () {
        this.expanded = false;
        if (this.isLast) {
            this.service.menuTabbingService.lastFocusable = this.getLastFocusableItem();
        }
        this.collapse.emit();
    };
    /**
     * @hidden
     */
    ColumnMenuPositionComponent.prototype.onExpand = function () {
        this.expanded = true;
        if (this.isLast) {
            this.service.menuTabbingService.lastFocusable = this.getLastFocusableItem();
        }
        this.expand.emit();
    };
    ColumnMenuPositionComponent.prototype.getLastFocusableItem = function () {
        var menuItems = this.hostElement.nativeElement.querySelectorAll('.k-columnmenu-item');
        var lastFocusableIndex = this.expanded ? menuItems.length - 1 : 0;
        return menuItems[lastFocusableIndex];
    };
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ColumnMenuPositionComponent.prototype, "expand", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ColumnMenuPositionComponent.prototype, "collapse", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], ColumnMenuPositionComponent.prototype, "expanded", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], ColumnMenuPositionComponent.prototype, "showLock", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], ColumnMenuPositionComponent.prototype, "showStick", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [Boolean])
    ], ColumnMenuPositionComponent.prototype, "isLast", null);
    ColumnMenuPositionComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-grid-columnmenu-position',
            template: "\n        <kendo-grid-columnmenu-item\n            [text]=\"localization.get('setColumnPosition')\"\n            icon=\"set-column-position\"\n            [expanded]=\"expanded\"\n            (keydown.tab)=\"onTab($event, !expanded)\"\n            (collapse)=\"onCollapse()\"\n            (expand)=\"onExpand()\">\n            <ng-template kendoGridColumnMenuItemContentTemplate>\n                <kendo-grid-columnmenu-lock\n                    *ngIf=\"showLock\"\n                    (keydown.tab)=\"onTab($event, !showStick)\"\n                    [service]=\"service\">\n                </kendo-grid-columnmenu-lock>\n                <kendo-grid-columnmenu-stick\n                    *ngIf=\"showStick\"\n                    (keydown.tab)=\"onTab($event, true)\"\n                    [service]=\"service\">\n                </kendo-grid-columnmenu-stick>\n            </ng-template>\n        </kendo-grid-columnmenu-item>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService,
            core_1.ElementRef])
    ], ColumnMenuPositionComponent);
    return ColumnMenuPositionComponent;
}(column_menu_item_base_1.ColumnMenuItemBase));
exports.ColumnMenuPositionComponent = ColumnMenuPositionComponent;
