<!--Loading Page-->
<div class="k-loading-image" *ngIf="!result.receivedResult"></div>

<!--Pressure Gauage-->
<div *ngIf="result.receivedResult && result.hasTargetKpi">
    <section class="pressure-kpi-gauge-container">
        <kendo-radialgauge class="pressure-kpi-gauge">

            <!--Pointer-->
            <kendo-radialgauge-pointers>
                <kendo-radialgauge-pointer [value]="result.currentKpiValue"
                                            [color]="gaugeElement.rangeColor"
                                            [length]="1">
                </kendo-radialgauge-pointer>
            </kendo-radialgauge-pointers>

            <!--Scale-->
            <kendo-radialgauge-scale [startAngle]="0"
                                        [endAngle]="180"
                                        [rangeSize]="10"
                                        [rangePlaceholderColor]="gaugeElement.placeHolderColor"
                                        [majorUnit]="result.majorUnit"
                                        [majorTicks]="{ color: gaugeElement.tickColor }"
                                        [minorUnit]="result.minorUnit"
                                        [minorTicks]="{ color: gaugeElement.tickColor }"
                                        [max]="result.maxKpiValue">

                <!--Label-->
                <kendo-radialgauge-scale-labels font="10px Arial,Helvetica,sans-serif">
                </kendo-radialgauge-scale-labels>

                <!--Ranges-->
                <kendo-radialgauge-scale-ranges>
                    <kendo-radialgauge-scale-range [from]="0" [to]="result.targetKpiValue" [color]="gaugeElement.placeHolderColor"></kendo-radialgauge-scale-range>
                    <kendo-radialgauge-scale-range [from]="result.targetKpiValue" [to]="result.maxKpiValue" [color]="gaugeElement.rangeColor"></kendo-radialgauge-scale-range>
                </kendo-radialgauge-scale-ranges>

            </kendo-radialgauge-scale>
        </kendo-radialgauge>
        <div>
            <p class="pressure-kpi-range-target-text">{{ui.target}}{{result.targetKpiValue}}</p>
        </div>
    </section>
</div>

<div class="widget-description" *ngIf="result.receivedResult && !result.hasTargetKpi">
    <h4>{{ui.noResult}}</h4>
</div>