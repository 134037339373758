//import angular from 'angular';
import OcDateSvc from '../../../services/ocDateSvc';
import AdminUrls from '../../admin/adminUrls';


    angular.module('app.site').controller("endOfMonthSearchCtrl", endOfMonthSearchCtrl);

    // @ts-ignore
    endOfMonthSearchCtrl.$inject = [
        "adminUrls", "$scope", "enums", "ocSecuritySvc", "amtXlatSvc", "ocConfigSvc", "notifySvc",
        "endOfMonthUrls", "ocDateSvc", "$timeout", "endOfMonthSvc"
    ];

    // ReSharper disable once InconsistentNaming
    function endOfMonthSearchCtrl(
        adminUrls: AdminUrls, $scope, enums, ocSecuritySvc, amtXlatSvc, ocConfigSvc, notifySvc,
        endOfMonthUrls, ocDateSvc: OcDateSvc, $timeout, endOfMonthSvc
    ) {
        var vm = this;
        vm.processing = false;

        vm.showClients = false;
        vm.showSites = false;

        vm.adminUrls = adminUrls;
        vm.endOfMonthUrls = endOfMonthUrls;

        vm.config = ocConfigSvc;

        vm.criteria = {
            filterValues: {
                clientIds: [],
                siteIds: [],
                reportYear: null,
                statuses: null,
                completedBy: null,
                completedDate: null,
                defaultToAllSitesWhenNoSystemHierarchyFilter: true
            }
        };
        
        vm.statuses = [{
            name: amtXlatSvc.xlat('endOfMonth.incomplete'),
            key: 'Incomplete'
        }, {
            name: amtXlatSvc.xlat('endOfMonth.completed'),
            key: 'Complete'
        }];

        vm.status = enums.screenStatus.loading;
        vm.isDirty = true;

        vm.gridControl = {};
        vm.selectedItem = null;
        vm.resultsDisplayed = false;

        function init() {
            vm.canAdd = ocSecuritySvc.isAuthorised('Security.Site.EndOfMonth.Add', 'readWrite', vm.config.user.client.id, vm.config.user.site.id, true);
            vm.loadDefaults();
            vm.loadFromSiteDashboard();
        }

        vm.onDatabound = function () {
            vm.selectedItem = null;
        };

        vm.onSelectedItemChanged = function (items) {
            if (items != null && items.length === 1) {
                vm.selectedItem = items[0];
            } else {
                vm.selectedItem = null;
            }
        };

        vm.onSearch = function (response) {

            var eoms = response.result;

            vm.resultsDisplayed = response.result && response.result.length > 0;

            for (var i = 0; i < eoms.length; i++) {
                eoms[i].onSite = eoms[i].site.id === vm.config.user.site.id;
            }

            return response;
        };

        vm.setHeaderHeight = function (height, offset) {
            vm.gridTop = height + offset;
        };

        vm.loadDefaults = function () {
            vm.defaults = {
                sites: [{
                    key: vm.config.user.site.id,
                    name: vm.config.user.site.name
                }],
                clients: [{
                    key: vm.config.user.client.id,
                    name: vm.config.user.client.name
                }],
                statuses: vm.statuses
            };
            vm.status = enums.screenStatus.ready;
        };

        vm.loadFromSiteDashboard = function () {
            if (localStorage.getItem("siteDashboardSearchby") === "Incomplete") {
                vm.defaults.statuses = [{
                    name: amtXlatSvc.xlat('endOfMonth.incomplete'),
                    key: 'Incomplete'
                }];
                vm.criteria = {
                    filterValues: {
                        clientIds: [vm.config.user.client.id],
                        siteIds: [vm.config.user.client.id],
                        statuses: ["Incomplete"],
                        reportYear: null,
                        completedBy: [],
                        completedDate: null,
                        defaultToAllSitesWhenNoSystemHierarchyFilter: true
                    }
                };

                localStorage.setItem("siteDashboardSearchby", "undefined");
                // delay this so that client is sorted and doesn't trigger a lookup of the site
                setTimeout(function () {
                    vm.filter();
                }, 3000);
            }

        }

        vm.export = function () {
            endOfMonthSvc.export(vm.criteria);
        };

        $scope.editEndOfMonth = function (item) {
            item.mode = 'edit';
            endOfMonthSvc.openItem(item, vm.filter, amtXlatSvc.xlat("endOfMonth.editTitle", item.monthYear));
        };

        $scope.addEndOfMonth = function () {
            endOfMonthSvc.create(vm.filter);            
        };

        $scope.delete = async function (item) {
            await endOfMonthSvc.delete(item);
            vm.gridControl.refresh();
        };

        $scope.$watch('vm.criteria.filterValues.clientIds.length', function (cnt) {
            if (cnt === 0 && vm.criteria.filterValues.siteIds.length > 0) {
                // reset the sites if the clients are cleared
                vm.criteria.filterValues.siteIds.length = 0;
            }
        });

        vm.filter = function () {
            var completedDate = angular.copy(vm.criteria.filterValues.completedDate);

            vm.criteria.filterValues.completedDate = ocDateSvc.removeLocalTimeZoneOffset(vm.criteria.filterValues.completedDate);            
            vm.showClients = vm.criteria.filterValues.clientIds.length !== 1;
            vm.showSites = vm.criteria.filterValues.siteIds.length !== 1;

            vm.gridControl.refresh();

            $timeout(function () {
                vm.criteria.filterValues.completedDate = completedDate;
            });
        };

        init();
    }
