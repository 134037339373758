/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var tabstrip_tab_component_1 = require("../models/tabstrip-tab.component");
var tabstrip_service_1 = require("../tabstrip.service");
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var util_1 = require("../../common/util");
/**
 * @hidden
 */
var TabComponent = /** @class */ (function () {
    function TabComponent(localization, tabstripService) {
        this.localization = localization;
        this.tabstripService = tabstripService;
        this.hostClasses = true;
    }
    Object.defineProperty(TabComponent.prototype, "activeClass", {
        get: function () {
            return this.tab.selected;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabComponent.prototype, "disabledClass", {
        get: function () {
            return this.tab.disabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabComponent.prototype, "focusedClass", {
        get: function () {
            return this.tab.focused;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabComponent.prototype, "tabIndex", {
        get: function () {
            return this.tab.selected || this.tab.focused ? 0 : -1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabComponent.prototype, "tabClosable", {
        get: function () {
            if (this.tab.closable !== undefined) {
                return this.tab.closable;
            }
            return this.tabStripClosable;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabComponent.prototype, "closeButtonClasses", {
        get: function () {
            if (util_1.isPresent(this.tab.closeIcon)) {
                return this.tab.closeIcon;
            }
            return this.tabStripCloseIcon;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TabComponent.prototype, "closeButtonTitle", {
        get: function () {
            return this.localization.get('closeTitle');
        },
        enumerable: true,
        configurable: true
    });
    TabComponent.prototype.closeTab = function (index) {
        this.tabstripService.onTabClose(this.tab, index);
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", tabstrip_tab_component_1.TabStripTabComponent)
    ], TabComponent.prototype, "tab", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], TabComponent.prototype, "index", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], TabComponent.prototype, "tabStripClosable", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], TabComponent.prototype, "tabStripCloseIcon", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-item'),
        core_1.HostBinding('class.k-state-default'),
        tslib_1.__metadata("design:type", Boolean)
    ], TabComponent.prototype, "hostClasses", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('attr.aria-selected]'),
        core_1.HostBinding('class.k-state-active'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], TabComponent.prototype, "activeClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.aria-disabled]'),
        core_1.HostBinding('class.k-state-disabled'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], TabComponent.prototype, "disabledClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-state-focused'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], TabComponent.prototype, "focusedClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.tabindex'),
        tslib_1.__metadata("design:type", Number),
        tslib_1.__metadata("design:paramtypes", [])
    ], TabComponent.prototype, "tabIndex", null);
    TabComponent = tslib_1.__decorate([
        core_1.Component({
            selector: '[kendoTabStripTab]',
            template: "\n        <span class=\"k-link\" *ngIf=\"!tab.tabTitle\">{{ tab.title }}</span>\n        <span class=\"k-link\" *ngIf=\"tab.tabTitle\">\n            <ng-template [ngTemplateOutlet]=\"tab.tabTitle?.templateRef\">\n            </ng-template>\n        </span>\n        <span class=\"k-remove-tab k-button k-icon-button k-flat\" *ngIf=\"tabClosable\"\n            role=\"button\"\n            [title]=\"closeButtonTitle\"\n            [attr.aria-label]=\"closeButtonTitle\"\n            (click)=\"closeTab(index)\"\n        >\n            <span [ngClass]=\"closeButtonClasses\"></span>\n        </span>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService,
            tabstrip_service_1.TabStripService])
    ], TabComponent);
    return TabComponent;
}());
exports.TabComponent = TabComponent;
