//import angular from 'angular';


angular.module('app').factory('confirmSvc', ['amtXlatSvc', '$q', 'enums', 'WindowFactory',
        function (amtXlatSvc: IAmtXlatSvc, $q: ng.IQService, enums, WindowFactory: IWindowFactory) {
            var confirmSvc = {
                confirmSaveChange: confirmSaveChange,
                confirmMessage: confirmMessage,
                rejectConfirm: rejectConfirm,
                confirmMessage_cancelPrimary: confirmMessage_cancelPrimary,
                confirmMessage_customButtons: confirmMessage_customButtons
            };
            return confirmSvc;


            function confirmSaveChange(dirty) {
                if (arguments.length === 0) {
                    dirty = true;
                }

                var defer = $q.defer();

                if (!dirty) {
                    defer.resolve(true);
                } else {
                    confirmWithCustomMessage(defer, false, "common.editPanelConfirmation_title", "common.editPanelConfirmation_message");
                }
                return defer.promise;
            }

            function getArgs(index, args) {
                var parameters = [];
                for (var i = 0; i < args.length; ++i) {
                    if (i >= index) {
                        parameters.push(args[i]);
                    }
                }
                return parameters;
            }

            // Yes is primary button
            function confirmMessage(title, contentMessage) {
                var defer = $q.defer();
                confirmWithCustomMessage(defer, false, title, contentMessage, getArgs(2, arguments));
                return defer.promise;
            }

            function confirmMessage_customButtons(title: string, contentMessage: string, confirmLabel: string, cancelLabel: string, cancelOnClose: boolean) {
                var defer = $q.defer();
                confirmWithCustomMessage(defer, false, title, contentMessage, getArgs(5, arguments), confirmLabel, cancelLabel, cancelOnClose);
                return defer.promise;
            }

            // cancel is primary button
            function confirmMessage_cancelPrimary(title, contentMessage) {
                var defer = $q.defer();
                confirmWithCustomMessage(defer, true, title, contentMessage, getArgs(2, arguments));
                return defer.promise;
            }

            async function confirmWithCustomMessage(defer, cancelPrimary, title, contentMessage, params?, confirmLabel?: string, cancelLabel?: string, cancelOnClose?: boolean) {

                if (!confirmLabel)
                    confirmLabel = 'framework.yes_label';

                if (!cancelLabel)
                    cancelLabel = 'framework.cancel';

                try {
                    let result = await WindowFactory.iconAlert('glyphicon-check', title, (cancelPrimary === true ? [confirmLabel, cancelLabel] : [cancelLabel, confirmLabel]), contentMessage, params);

                    if (!result && cancelOnClose)
                        return defer.reject(false);

                    if (result === confirmLabel) {
                        defer.resolve(true);
                    }
                    else {
                        // we do reject this so that consumer of the confirm can do some processing on rejection if they wish.
                        // @ts-ignore
                        defer.reject(new Error(0, "ConfirmSvc Prompt Rejected"));
                    }
                } catch {
                    defer.reject(false);
                }
            }

            function rejectConfirm() {
                var defer = $q.defer();
                defer.reject();
                return defer.promise;
            }
        }]);
