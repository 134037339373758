/**
 * @ngdoc directive
 * @name amtFramework.directive:amtDisplay
 * @restrict E
 * @requires amtFramework.directive:amtRow
 * @param {string} label Label
 * @param {string} amtTooltip Takes text only and will escape any HTML provided.
 * @param {expression} ngRequired Required
 * @param {string} cssClass ???
 * @param {string} contentCssClass ???
 * @param {string} tooltipPlacement Where to place it? Defaults to "top", but also accepts "bottom", "left", "right".
 * @param {string} labelWidth ???
 * @param {string} valueWidth ???
 * 
 * @example
 * <doc:example module="amtFramework">
 *   <doc:source>
 *      <amt-row layout="horizontal">
            <amt-col>
                <amt-display label="This is a display example" ng-required="true">
                    <input type="text" class="form-control" maxlength="50" ng-required="true" />
                </amt-display>
            </amt-col>
        </amt-row>
 *   </doc:source>
 * </doc:example>
 * @function
 *
 * @description
 * Display. Usually used in conjunction with an AMT form control.
 */
//import angular from 'angular';
import tmpl from './amtDisplay.html';
import './amtDisplay.scss';

angular.module('amtFramework').directive('amtDisplay', [
        function () {
            return {
                restrict: 'E',
                require: '^?amtRow',
                transclude: true,
                template: tmpl,
                scope: {
                    label: '@',
                    amtTooltip: '@',
                    ngRequired: '=',
                    cssClass: '@',
                    contentCssClass: '@',
                    tooltipPlacement: '@',
                    labelWidth: '=?',
                    valueWidth: '=?',
                    suppressValue: '@'
                },
                link: function (scope: any, elem, attr, amtRow) {
                    var colSize = 12;
                    var defaultValueColSize = 7;
                    scope.canUseHtml = (scope.canUseHtml && scope.canUseHtml === "true") ? true : false;
                    scope.labelWidth = scope.labelWidth || colSize - (scope.valueWidth || defaultValueColSize);
                    scope.valueWidth = scope.valueWidth || colSize - scope.labelWidth;
                    scope.layout = amtRow == null ? 'vertical' : amtRow.layout;
                    if (amtRow) {
                        amtRow.hasDisplay = true;
                    }
                }
            };
        }
]);
