/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_date_math_1 = require("@progress/kendo-date-math");
var util_1 = require("../../util");
var bus_view_service_1 = require("./bus-view.service");
/**
 * @hidden
 */
var SelectionService = /** @class */ (function () {
    function SelectionService(bus) {
        this.bus = bus;
    }
    SelectionService.prototype.performSelection = function (args) {
        var date = args.date, modifiers = args.modifiers, selectionMode = args.selectionMode, activeViewEnum = args.activeViewEnum, rangePivot = args.rangePivot;
        var selectedDates = args.selectedDates.slice();
        if (selectionMode === 'multiple') {
            if (modifiers.ctrlKey || modifiers.metaKey) {
                if (this.isDateSelected(selectedDates, date)) {
                    selectedDates = selectedDates.filter(function (item) { return !kendo_date_math_1.isEqual(item, date); });
                }
                else {
                    selectedDates.push(date);
                }
                rangePivot = date;
            }
            else if (modifiers.shiftKey) {
                var _a = util_1.sortDates([rangePivot || date, date]), start = _a[0], end = _a[1];
                selectedDates = this.bus.service(activeViewEnum).dateRange(start, end);
                rangePivot = date > selectedDates[0] ? selectedDates[0] : util_1.last(selectedDates);
            }
            else {
                selectedDates = [date];
                rangePivot = date;
            }
        }
        else {
            selectedDates = [date];
            rangePivot = date;
        }
        return { selectedDates: selectedDates, rangePivot: rangePivot };
    };
    SelectionService.prototype.isDateSelected = function (selectedDates, date) {
        return selectedDates.some(function (item) { return kendo_date_math_1.isEqual(item, date); });
    };
    SelectionService = tslib_1.__decorate([
        core_1.Injectable(),
        tslib_1.__metadata("design:paramtypes", [bus_view_service_1.BusViewService])
    ], SelectionService);
    return SelectionService;
}());
exports.SelectionService = SelectionService;
