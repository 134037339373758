//import angular from 'angular';


    angular.module("app").factory("fleetUrls", function () {
        var apiPath = baseUrl + 'api/';
        var controllerPath = 'admin/fleet/';
        var vehicleController = 'equipment/vehicle/';
        var equipmentControllerPath = 'equipment/common/';

        return {
            //search
            search: apiPath + controllerPath + 'search',
            getSearchDefaults: controllerPath + 'getSearchDefaults', // default values for search
            searchExport: controllerPath + 'searchExport',
            // details
            getDetails: controllerPath + 'getDetails',
            updateDetails: controllerPath + 'updateDetails',

            getFleetSequences: controllerPath + 'getFleetSequences',
            updateFleetSequences: controllerPath + 'updateFleetSequences',
                                        
            // reference data
            getFleetTypes: apiPath + controllerPath + 'getFleetTypes',
            getFleetTypeList: controllerPath + 'getFleetTypes',

            getVehicles: apiPath + controllerPath + 'getVehicles',
            getApplications: apiPath + controllerPath + 'getApplications',
            getVehicleTypes: apiPath + controllerPath + 'getVehicleTypes',

            //Updates
            saveFleetDetails: controllerPath + 'saveFleetDetails',

            //Delete
            deleteFleet: controllerPath + 'delete'
        };
    });
