//import angular from 'angular';

angular.module('amtFramework').directive('stopBubble', [
    function () {

        return {
            restrict: 'AE',
            link: function (scope, element, attr) {
                element.bind(attr.stopBubble, function (e: any) {
                    if (!e) {
                        e = window.event;
                    }

                    e.cancelBubble = true;
                    e.returnValue = true;

                    e.stopPropagation();

                    return true;
                });
            }
        };
    }
]);

