//import angular from 'angular';

    angular.module('app.directives')
        .directive('ocWindowContainer', ['WindowFactory', function (WindowFactory: IWindowFactory) {
            return {
                template:
                    '<div class="child-windows-container" style="position: absolute; left: 0; top: 0;">' +
                    '   <oc-dialog ng-repeat="wnd in windowFactory.windows | orderBy: \'-order\'" ' +
                    '       mousedown="wnd.mouseDown(e);" ' +
                    '       on-minimize="wnd.hide()" ' +
                    '       on-full-screen="wnd.fullScreen()" ' +
                    '       on-windowed="wnd.windowed()" ' +
                    '       on-close="windowFactory.closeWindow(wnd)" ' +
                    '       on-data-changed="wnd.onDataChanged(sender)" ' +
                    '       wnd="wnd" ' +
                    '       init-params="{{wnd.data}}"></oc-dialog> ' +
                    '</div>',
                controller: ['$scope', function ($scope) {
                    $scope.windowFactory = WindowFactory;
                }]
            };
        }]);
