//import angular from 'angular';
import * as _ from 'underscore';
import FileManagement from '../../../services/fileManagement';
import BrowserSvc from '../../../services/browserSvc';
import OcDateSvc from '../../../services/ocDateSvc';
import OcConfigSvc from '../../../services/ocConfigSvc';
import ReportSvc from '../../../services/reportSvc';
import { ComponentConditionType } from './componentReceiveFactory';
import tmpl from './componentReceive.html';




const enum componentReceiveCtrl_stepEnum {
    type,
    condition,
    details,
    serial,
    summary
}

class ComponentReceiveCtrl implements ng.IController {

    wnd: IWindowObj;
    form: ng.IFormController;

    initParams: any = {};
    processing: boolean;
    isMobile: boolean = this.browserSvc.isMobile;

    // controls
    errorDisplay: IErrorPanel = {
        initialised: false
    };

    serialGridControl: any = {};

    kplaceholder = this.amtXlatSvc.xlat("component.searchReceiptedComponents");

    // security
    canReceivePending = this.ocSecuritySvc.isAuthorised("Security.Components.Receive.Pending", AccessTypes.readWrite);
    canReceiveNew = this.ocSecuritySvc.isAuthorised("Security.Components.Receive.New", AccessTypes.readWrite);
    canReceiveOpeningBalanceSecondHand = this.ocSecuritySvc.isAuthorised("Security.Components.Receive.OpeningBalanceSecondHand", AccessTypes.readWrite);

    // variables
    status: ScreenStatus = ScreenStatus.loading;
    canFinishReceive = false; // little bit of a hack, solves the issue cleanly though        
    deregisterWatchForPurchaseOrderId: () => void;

    // configuration
    enforceStoreLocation = this.ocConfigSvc.user.site.settings.tyreMaintenance.storeLocation;

    // errors
    currentError = null;
    erroredReceive = null;

    // flags
    processingValidations = true;
    processingConditions = true;
    receiveInProgress = false;
    inSummary = false;
    pendingFittedComponents = false;
    pendingComponents = false;
    showReceiveNone = false;

    // wizard
    wizardControl: any = {};
    hideNextButton = true;
    hidePreviousButton = false;
    finishText = this.amtXlatSvc.xlat("component.receiveWizardReturnToStocktake");
    previousText = this.amtXlatSvc.xlat("component.receiveWizardPrevious");
    returnToStartText = this.amtXlatSvc.xlat("component.receiveWizardReturnToStart");
    nextText: string = null;
    finishVisible = false;
    startingStep = undefined;
    summaryTitle: string = null;

    // selections
    lastReceivedId = undefined;
    selectedComponentType = undefined;
    selectedComponentCondition = undefined;

    receiveWizardComponentConditionReceiveDesc = undefined;
    serialDescription = undefined;

    selectedReceipted = undefined;

    // reference data
    componentTypes = [];
    componentConditions = [];
    purchaseTypes = [];
    statusTypes = [];
    specifications = [];
    specificationSelection = [];
    specificationSelectionLoading = false;
    purchaseOrders = [];
    locations = [];
    specificationDetails = null;
    componentOwners: any[] = [];

    // collections
    receiptedComponents = [];
    serials = [];

    // summary
    summaryItems = [];
    serialStepColumns = [];
    pendingSummaryColumns: any;

    // component details
    currencySymbol = this.ocConfigSvc.user.site.currency.symbol;
    currencyDecimalPlaces = this.ocConfigSvc.user.site.currency.decimalPlaces;
    combinedDateTime: Date = null;
    purchaseType = undefined;
    componentOwner = null;
    comment = undefined;
    selectedLocation = undefined;
    attachments: IEquipmentEventAttachment[] = [];
    selectedSpec = undefined;
    unitCost: any = 0;

    purchaseOrder: any = {};
    purchaseOrderId = null;
    purchaseOrderNumber = "";
    invoiceReference: string = null;

    costReadOnly = undefined;

    maxDate = undefined;
    pendingData = [];

    acceptedFileTypes: string[]

    static $inject = ["amtXlatSvc", "ocConfigSvc", "errorReporter", "WindowFactory",
        "$scope", "$state", "$stateParams", "confirmSvc", "dataBroker", "notifySvc", "$filter", "$timeout",
        "ocSecuritySvc", "amtConstants", "fileManagement", "componentReceiveFactory", "ocDateSvc", "$location", "helperSvc",
        "browserSvc", "reportSvc"];

    constructor(private amtXlatSvc: IAmtXlatSvc, private ocConfigSvc: OcConfigSvc, private errorReporter: IErrorReporter, private WindowFactory: IWindowFactory,
        private $scope: ng.IScope, private $state: ng.ui.IStateService, private $stateParams: ng.ui.IStateParamsService, private confirmSvc: IConfirmSvc, private dataBroker: IDataBroker,
        private notifySvc: INotifySvc, private $filter: ng.IFilterService, private $timeout: ng.ITimeoutService, private ocSecuritySvc: IOcSecuritySvc,
        private amtConstants: IAmtConstants, private fileManagement: FileManagement, private componentReceiveFactory: any, private ocDateSvc: OcDateSvc,
        private $location: ng.ILocationService, private helperSvc: IHelperSvc, private browserSvc: BrowserSvc, private reportSvc: ReportSvc) {

        if (this.wnd) {
            // pass along dirty flag to the window for use on closing minimised windows
            $scope.$watch(() => this.form.$dirty, () => { this.wnd.isDirty = this.form.$dirty });
        }

        // React to changing of specification
        $scope.$watch(() => this.selectedSpec, async (newValue, oldValue) => {
            if (!this.selectedSpec || this.inSummary)
                return;

            if (oldValue && (oldValue !== newValue)) {
                if (this.initParams.fromStocktake) {
                    for (let s of this.serials) {
                        s.specification = newValue;
                    }
                } else {
                    this.serials = []; // clear serials collection if specification changed
                }
            }

            let receiveDate = ocDateSvc.removeLocalTimeZoneOffset(this.combinedDateTime);

            try {
                let response = await this.dataBroker.getSpecificationDetails(this.selectedSpec.id, receiveDate);
                if (response) {

                    if (this.selectedComponentCondition.defaultCost === true) {
                        this.unitCost = (response.defaultCost ? response.defaultCost : 0);
                    }

                    this.specificationDetails = {
                        manufacturerSerialValidations: response.serialNumberValidations,
                        originalDepth: response.originalDepth
                    };
                }
            } catch (error) {
                this.errorReporter.logError(error, this.amtXlatSvc.xlat("component.componentSpecDetailsRetrieveFail"));
            }
        }, true);

        // React to changing of receive date
        $scope.$watch(() => this.combinedDateTime, async (newValue: Date, oldValue: Date) => {

            if (oldValue === newValue)
                return;

            this.selectedLocation = null;
            this.wizardControl.setPristine(componentReceiveCtrl_stepEnum.details);

            if (!oldValue || (Math.floor(oldValue.getTime() / 1000) !== Math.floor(newValue.getTime() / 1000))) {

                // clear serials collection if date changed (only for known)
                if (this.selectedComponentCondition && this.selectedComponentCondition.isKnown) {
                    this.serials = [];
                }

                try {

                    let criteria: ILocationCriteria = {
                        siteIds: [this.ocConfigSvc.user.site.id],
                        date: this.combinedDateTime,
                        statusTypeIds: this.statusTypes.map(s => s.id)
                    };

                    this.locations = (await this.dataBroker.getLocations(criteria)).result;

                } catch (error) {
                    this.errorReporter.logError(error, "component.locationRetrieveFail");
                }
            }

        });

        // React to changing of the component collection
        // little bit dirty, makes things work well though
        $scope.$watch(() => this.serials, (newValue, oldValue) => {

            if (this.erroredReceive && (!newValue || !oldValue || oldValue.length !== newValue.length))
                this.bindSerialErrors();

            if (!this.serials || !this.selectedComponentCondition)
                return;

            let hasComponents = this.selectedComponentCondition.isKnown ? this.serials.some(s => s && s.selected) : this.serials.some(s => s);

            this.$timeout(() => {
                this.canFinishReceive = !!(hasComponents && !this.processing);

                if (this.canFinishReceive && this.wizardControl && this.wizardControl.setPristine) {
                    this.wizardControl.setPristine(componentReceiveCtrl_stepEnum.serial);
                }
            });
        }, true);

        // React to selection of a receipted component from the dropdown (Mobile Only)
        $scope.$watch(() => this.selectedReceipted, async () => {

            if (!this.selectedReceipted)
                return;

            try {
                let response = await this.dataBroker.getPendingReceival(this.selectedReceipted.key);

                this.inSummary = true;
                this.lastReceivedId = response.id;

                let components = response.components;

                this.attachments = response.attachments || [];

                if (!response.fitComponents) {

                    this.selectedComponentType = _.find(this.componentTypes, ct => ct.id === components[0].equipmentTypeId);

                    await this.initialiseComponentConditionList(true, true);

                    this.selectedComponentCondition = _.find(this.componentConditions, condition => condition.id === components[0].conditionType);

                    // set once for all components
                    this.combinedDateTime = ocDateSvc.addLocalTimeZoneOffset(components[0].eventDetails
                        ? components[0].eventDetails.eventDate
                        : components[0].receiveDate);

                    // get reference data
                    await this.loadComponentReferenceData();

                    // map to format we need
                    await this.mapPendingReceiveToWizard(components);

                    // construct summary
                    this.prepareSummary(this.serials);

                } else {

                    this.pendingFittedComponents = response.fitComponents === true;
                    this.pendingComponents = !this.pendingFittedComponents;

                    let pendingFittedCondition = componentReceiveFactory.getPendingFittedCondition();
                    pendingFittedCondition.key = amtConstants.emptyGuid;

                    this.selectedComponentType = pendingFittedCondition;
                    this.selectedComponentCondition = pendingFittedCondition;

                    await this.initialiseComponentConditionList(true, true);

                    await this.loadComponentReferenceData();

                    this.serials = response.components.map(c => ({
                        serialNumber: {
                            manufacturer: c.manufacturerSerialNumber,
                            site: c.siteSerialNumber
                        },
                        id: c.equipmentId,
                        valid: true,
                        selected: true,
                        equipmentType: _.find(this.componentTypes, componentType => componentType.id === c.equipmentTypeId),
                        date: c.receiveDate,
                        cost: c.costDetails.amount,
                        specification: _.find(this.specifications, specification => specification.id === c.specificationId),
                        fittedToVehicleSerial: c.fittedToVehicleSerial,
                        fittedToPosition: c.fittedToPosition,
                        fittedToPositionDescription: String.format("{0} ({1})", c.fittedToVehicleSerial, c.fittedToPosition),
                        location: this.selectedLocation,
                        status: {
                            name: (this.pendingFittedComponents ? "Received & Fitted" : "Received"),
                            description: this.amtXlatSvc.xlat(this.pendingFittedComponents
                                ? "component.thirdPartyStatusReceivedFitted"
                                : "component.thirdPartyStatusReceived")
                        }
                    }));

                    // construct summary
                    this.pendingSummaryColumns = componentReceiveFactory.buildPendingReceiveSummary(this.pendingFittedComponents);
                }

                this.hidePreviousButton = true; // Otherwise "Back to Start" will show.
                this.goToStep(componentReceiveCtrl_stepEnum.summary); // Summary step

            } catch (error) {
                this.errorReporter.logError(error, this.amtXlatSvc.xlat("equipment.pendingReceiptedDetailsFail"));
            }

        }, true);

        $scope.$watch(() => this.serialGridControl, newValue => {
            if (newValue) { // connect functions to dynamic grid api for component serial step
                this.serialGridControl.editComponentSerial = (dataItem?) => this.editComponentSerial(dataItem);
                this.serialGridControl.deleteComponentSerial = (dataItem) => this.deleteComponentSerial(dataItem);

                this.bindSerialErrors();
            }
        });

    } //TODO: end of constructor! This is too long, needs refactoring

    // Initialize
    async $onInit() {

        try {
            await this.dataBroker.checkRefData();
        } catch {
            this.$location.url("/mobile/landing");
            return;
        }

        this.status = ScreenStatus.loading;
        this.processing = true;

        if (this.wnd)
            this.wnd.onClose = () => this.cancel();

        if (this.$stateParams && this.$stateParams.initParams)
            this.initParams = this.$stateParams.initParams;

        this.acceptedFileTypes = this.fileManagement.getAcceptedFileExtensions([FileType.document, FileType.pdf, FileType.text, FileType.spreadsheet, FileType.image]);

        this.initParams = this.initParams || {};

        await this.getComponentTypes(this.initParams.vehicleSerialNumber);
        await this.loadPendingReceivalsForMobile();

        try {
            if (this.initParams.fromStocktake) {
                this.loadWizardFromStocktake(this.initParams.date, this.initParams.type, this.initParams.serialNumber, this.initParams.status, this.initParams.specification);

            } else if (this.initParams.fromVehicleReceive) {
                // initiated from pending vehicle (receive fitted components)
                if (this.initParams.received) {
                    this.loadWizardFromVehicleReceive_Received(this.initParams.vehicleSerialNumber);

                } else {
                    await this.loadWizardFromVehicleReceive_NotReceived(this.initParams.vehicleSerialNumbers); //vehicleSerialNumber and vehicleSerialNumbers are different properties!
                }

            } else if (this.initParams.pendingReceive) {
                await this.openPendingReceival(this.initParams.pendingReceive);

            } else {
                /* new component - do nothing (!) */
            }
        } finally {
            this.processing = false;
            this.status = ScreenStatus.ready;
            this.setPristine();
        }
    }

    public async loadWizardFromStocktake(stocktakeDate, componentTypeName: string, serialNumber: string, componentStatus: string, componentSpec) {
        // go straight to component detail step
        this.startingStep = componentReceiveCtrl_stepEnum.details;

        this.maxDate = stocktakeDate;

        // get the component type and set it
        let componentType = this.componentTypes.find(c => c.name.toLowerCase() === componentTypeName.toLowerCase());

        if (componentType) {
            this.selectedComponentType = componentType;
        };

        // add a new component to the serials list
        this.serials.push({
            serialNumber: { manufacturer: serialNumber },
            status: { name: "New" },
            equipmentType: this.selectedComponentType,
            fittedToVehicleSerial: null,
            fittedToPosition: null,
            fittedToPositionDescription: null
        });

        // initialise component conditions
        this.componentConditions = this.componentReceiveFactory.getComponentConditions(this.selectedComponentType.name, false, false, null);

        // find and set the condition type (new or 2nd hand)
        switch (componentStatus.toLowerCase()) {
            case StatusTypeName.new:
                if (this.canReceiveNew)
                    this.selectedComponentCondition = this.componentConditions.find(c => c.id === "New");
                break;
            case StatusTypeName.spare:
                if (this.canReceiveOpeningBalanceSecondHand)
                    this.selectedComponentCondition = this.componentConditions.find(c => c.id === "2nd Hand");
                break;
        }

        try {
            // load reference data
            await this.loadComponentReferenceData();

            //TODO: this doesn't belong here, but this code is a mess of states and at the moment each method that tweaks the wizard state is responsible for setting it up correctly for that part
            //this method uses this.startingStep to set the wizard to the details step which will need this data loaded
            await this.loadPurchaseOrderData();

            // find and set the selected specification
            this.selectedSpec = componentSpec.id ? this.specifications.find(s => s.id === componentSpec.id)
                : this.specifications.find(s => s.description === componentSpec.name);

            // find and set the status type
            this.serials[0].status = this.statusTypes.find(item => item.name === componentStatus || item.description === componentStatus);

            // apply specification to the component
            this.serials[0].specification = this.selectedSpec;
        }
        catch (ex) {
            console.log("rejected promise: " + ex);
        }

        //  hide previous button
        this.hideNextButton = false;
        this.hidePreviousButton = true; //TODO: we can go next from here then previous and then it will no longer be hidden, this is probably a bug!
    }

    public async loadWizardFromVehicleReceive_Received(vehicleSerialNumber) {

        this.pendingFittedComponents = true;

        // get 'pending fitted' condition
        let pendingFittedCondition = this.componentReceiveFactory.getPendingFittedCondition();

        // set component type and condition to 'pending fitted'
        this.selectedComponentType = pendingFittedCondition;
        this.selectedComponentCondition = pendingFittedCondition

        this.serialStepColumns = this.componentReceiveFactory.getSerialStepColumns(this.selectedComponentCondition, this.selectedComponentType, !!this.erroredReceive);

        this.startingStep = componentReceiveCtrl_stepEnum.summary; // start at 'summary' step

        this.inSummary = true;

        try {
            let response = await this.dataBroker.getPendingReceivals('components', null);

            if (response && this.isMobile) {
                let components = [].concat(...response.data.map(c => c.components));

                this.serials = components.filter(f => f.fittedToVehicleSerial === vehicleSerialNumber).map(c => ({
                    serialNumber: {
                        manufacturer: c.manufacturerSerialNumber, site: c.siteSerialNumber
                    },
                    equipmentType: { description: this.componentTypes.find(a => a.id === c.equipmentTypeId).description },
                    date: c.receiveDate,
                    cost: c.costDetails.amount,
                    specification: this.specifications.find(spec => spec.id === c.specificationId),
                    fittedToVehicleSerial: c.fittedToVehicleSerial,
                    fittedToPosition: c.fittedToPosition,
                    fittedToPositionDescription: c.fittedToVehicleSerial + " (" + c.fittedToPosition + ")",
                    location: this.selectedLocation,
                    status: {
                        name: (this.pendingFittedComponents ? "Received & Fitted" : "Received"),
                        description: this.amtXlatSvc.xlat(this.pendingFittedComponents ? "component.thirdPartyStatusReceivedFitted" : "component.thirdPartyStatusReceived")
                    }
                }));
            }
        } catch (error) {
            this.errorReporter.logError(error, "component.pendingRetreiveFail");
        }

        this.previousText = this.returnToStartText;
        this.hideNextButton = true;
        this.hidePreviousButton = false;
    }

    public async loadWizardFromVehicleReceive_NotReceived(vehicleSerialNumbers) {

        this.pendingFittedComponents = true;

        // get 'pending fitted' condition
        let pendingFittedCondition = this.componentReceiveFactory.getPendingFittedCondition();

        // set component type and condition to 'pending fitted'
        this.selectedComponentType = pendingFittedCondition;
        this.selectedComponentCondition = pendingFittedCondition

        this.serialStepColumns = this.componentReceiveFactory.getSerialStepColumns(this.selectedComponentCondition, this.selectedComponentType, !!this.erroredReceive);

        this.startingStep = componentReceiveCtrl_stepEnum.serial; // start at 'component serial' step

        try {

            // get reference data
            await this.loadComponentReferenceData();

            // get the pending fitted components
            let pendingComponents = await this.getPendingComponents(null, this.pendingFittedComponents, true, !this.isMobile);

            // only keep components fitted to the specified vehicle
            let filteredResults = pendingComponents.filter(r => _.any(vehicleSerialNumbers, v => v === r.fittedToVehicleSerial));

            // if there are any components, clear out their specification and cost
            for (let el of filteredResults) {
                el.specification = {};
                el.cost = null;
            }

            // add components to the serial collection
            this.serials = filteredResults;

        } catch (error) {
            this.errorReporter.logError(error, "component.pendingRetreiveFail");
        }

        this.serialDescription = this.amtXlatSvc.xlat("component.receiveWizardComponentSerialDesc_edit");
        this.nextText = this.amtXlatSvc.xlat("common.wzrd_finish_label");
        this.hideNextButton = false;
        this.hidePreviousButton = true;
    }


    // get the available component types (step 1)
    public async getComponentTypes(vehicleSerialNumber) {
        try {
            // get the component types
            this.componentTypes = (await this.dataBroker.getComponentTypes(this.ocConfigSvc.user.site.id)).result;

            // if can receive 3rd party fitted components
            if (this.ocConfigSvc.user.site.settings.receive.allowThirdPartyComponents && this.canReceivePending) {

                // get the pending fitted condition
                let pendingFittedCondition = this.componentReceiveFactory.getPendingFittedCondition();
                pendingFittedCondition.key = this.amtConstants.emptyGuid;

                // add condition to list of component type
                this.componentTypes.push(pendingFittedCondition);

                // if component receive was loaded from vehicle receive (pending vehicle with fitted components)
                // automatically set this condition as the selected component type and selected condition
                if (vehicleSerialNumber) {
                    this.selectedComponentType = pendingFittedCondition;
                    this.selectedComponentCondition = pendingFittedCondition;
                }

                let pendingComponents = await this.getPendingComponents(null, true, true, true);

                // if there are any pending fitted components available, enable the option
                if (pendingComponents.length > 0) {
                    pendingFittedCondition.disabled = false;
                }

                // store the pending components against the condition for fast retrieval
                pendingFittedCondition.components = pendingComponents;
                pendingFittedCondition.loading = false;
            }
        } catch (error) {
            this.errorReporter.logError(error, this.amtXlatSvc.xlat("component.componentTypeRetrieveFail"));
        }
    }

    public async componentTypeClickHandlePending(clearExistingData: boolean) {
        try {
            this.processing = true;

            // User chose "Pending Fitted Components", grab them from the server.
            this.selectedComponentCondition = this.selectedComponentType;

            // get serial step columns
            this.serialStepColumns = this.componentReceiveFactory.getSerialStepColumns(this.selectedComponentCondition, this.selectedComponentType, !!this.erroredReceive);

            // get reference data
            await this.loadComponentReferenceData();

            if (clearExistingData) {
                this.serials = await this.getPendingComponents(null, true, true, !this.isMobile);

                for (let e of this.serials || []) {
                    e.specification = {};
                    e.cost = null;
                }
            }
        } catch (error) {
            this.errorReporter.logError(error, "component.pendingRetreiveFail");
        } finally {
            // go to 'component serial' step
            this.goToStep(componentReceiveCtrl_stepEnum.serial);

            this.serialDescription = this.amtXlatSvc.xlat("component.receiveWizardComponentSerialDesc_edit");

            // switch to finish button
            this.nextText = this.amtXlatSvc.xlat("common.wzrd_finish_label");
            this.hideNextButton = false;

            this.processing = false;
            this.$timeout();
        }
    }

    // selection of component type (step 1)
    public async componentTypeClick(componentType) {
        this.form.$dirty = true;

        let clearExistingData = !this.selectedComponentType || this.selectedComponentType.id !== componentType.id;

        if (clearExistingData) {
            // clear any existing components, details or condition if component type change
            this.selectedComponentCondition = undefined;
            this.serials = [];

            this.clearReceiveDetails();
        }

        this.receiveInProgress = true;

        this.selectedComponentType = componentType;

        this.pendingFittedComponents = (componentType.id === 'Pending');
        this.pendingComponents = (componentType.id === 'Pending');

        // if 'pending fitted components' selected
        if (this.pendingFittedComponents) {
            await this.componentTypeClickHandlePending(clearExistingData)
            return;
        }

        try {
            this.processing = true;
            this.serialDescription = this.amtXlatSvc.xlat("component.serialDescription_Default"); // This might have to move later...good for now though.

            this.receiveWizardComponentConditionReceiveDesc = this.amtXlatSvc.xlat("component.receiveWizardComponentConditionReceiveDesc", this.selectedComponentType.description);

            // initialise the component conditions
            await this.initialiseComponentConditionList(false, true);

            // go to 'component condition' step
            this.goToStep(componentReceiveCtrl_stepEnum.condition);
        } finally {
            this.processing = false;
            this.$timeout();
        }
    }

    public goToStep(step) {
        return this.wizardControl.goToStep(step);
    }

    // Load Component Conditions
    public async initialiseComponentConditionList(skipValidation, loadKnownComponents) {
        try {
            this.processingConditions = true; // If the user has hit "previous", then this property will be false, so make sure it's true.            

            // 1. Push all applicable conditions to the array so that buttons/spinners are shown
            this.componentConditions = this.componentReceiveFactory.getComponentConditions((this.selectedComponentType ? this.selectedComponentType.name : null), skipValidation,
                this.ocConfigSvc.user.site.settings.receive.allowThirdPartyComponents, 'All');

            // 2. Load the relevant data.
            if (loadKnownComponents) {
                await this.getKnownComponents(false, true, true, null);
            }
        } finally {
            this.processingConditions = false;
            this.$timeout(); //HACK: I don't think watch is actually awaiting, thus this seems to occur outside of a digest
            //maybe 1 out of 10 times you don't see any component conditions at step 2 as a result, if we don't have this
        }
    }

    // On selection of a Component Condition on the wizard        
    public async componentConditionClick(condition) {

        this.form.$dirty = true;

        this.pendingComponents = (condition.id === "Pending");

        let childConditions = _.filter(this.componentConditions, c => c.parentConditionId && c.parentConditionId === condition.id);

        if (childConditions && childConditions.length > 0) {

            let componentTypeNamePlural = this.selectedComponentType.name ?
                this.amtXlatSvc.xlat("component." + this.selectedComponentType.name.toLowerCase() + "_Plural") : "";

            this.WindowFactory.openItem({
                component: "component-receive-condition-popup",
                caption: this.amtXlatSvc.xlat("component.receiveConditionPopupTitle", condition.name, componentTypeNamePlural),
                initParams: { componentConditions: childConditions },
                parentWindow: this.wnd,
                width: 650,
                modal: true,
                canClose: false,
                onDataChangeHandler: (condition) => this.processConditionTypeSelect(condition)
            });

        } else {
            this.processing = true;

            let retainExistingData = !!(this.selectedComponentCondition && this.selectedComponentCondition.id == condition.id);

            // no previous condition or the condition has changed
            if (!retainExistingData) {
                this.serials = [];
                this.clearReceiveDetails();
            }

            this.selectedComponentCondition = condition;

            // get reference data
            await this.loadComponentReferenceData();

            // if 'pending' selected
            if (this.pendingComponents) {

                this.serialStepColumns = this.componentReceiveFactory.getSerialStepColumns(this.selectedComponentCondition, this.selectedComponentType, !!this.erroredReceive);


                try {
                    if (!retainExistingData) {
                        // get pending components
                        this.serials = await this.getPendingComponents(this.selectedComponentType.id, false, true, !this.isMobile);

                        for (let el of this.serials) {
                            el.specification = {};
                            el.cost = null;
                        }
                    }
                } catch (error) {
                    this.errorReporter.logError(error, "component.pendingRetreiveFail");
                } finally {
                    this.goToStep(componentReceiveCtrl_stepEnum.serial); // go to 'component serial' step
                    this.serialDescription = this.amtXlatSvc.xlat("component.receiveWizardComponentSerialDesc_edit");

                    this.nextText = this.amtXlatSvc.xlat("common.wzrd_finish_label");
                    this.hideNextButton = false;
                    this.processing = false;
                }
            } else {
                //TODO: this doesn't belong here, but this code is a mess of states and at the moment each method that tweaks the wizard state is responsible for setting it up correctly for that part
                //this method uses this.goToStep() to set the wizard to the details step which will need this data loaded
                await this.loadPurchaseOrderData();

                // go to 'component detail' step
                this.goToStep(componentReceiveCtrl_stepEnum.details);

                // Need to show the next button now. Step 3 has no implicit point to automatically move to step 4,
                // so user must use the Next button to manually move to step 4.
                this.hideNextButton = false;
                this.processing = false;
            }
        }
    }

    // get pending components (3rd party receive)
    public async getPendingComponents(equipmentTypeId, includeFittedOnly, excludeReceived, checkVehicleExists) {
        try {
            return (await this.dataBroker.getPendingComponents(this.ocConfigSvc.user.site.id, equipmentTypeId, includeFittedOnly, excludeReceived, checkVehicleExists)).result || [];
        } catch (error) {
            this.processing = false;
            this.errorReporter.logError(error, "component.pendingRetrieveFail");
        }
    };

    // generates component description used in receipted component dropdown on mobile
    private componentDescription(receival) {
        let component = this.amtXlatSvc.xlat("component.components");

        if (receival.components.length === 1) {
            if (receival.components[0].equipmentTypeName) {
                component = this.amtXlatSvc.xlat("equipment.equipmentType" + receival.components[0].equipmentTypeName);
            } else {
                if (receival.components[0].equipmentTypeId) {
                    let id = receival.components[0].equipmentTypeId;
                    component = this.componentTypes.find(c => c.id === id).description;
                } else {
                    component = this.amtXlatSvc.xlat("component.component");
                }
            }
        }

        if (receival.fitComponents !== undefined) {
            return {
                key: receival.id,
                name: receival.components.length + ' x "' + component + '" ' +
                    (receival.components[0].fittedToVehicleSerial ? '"' + receival.components[0].fittedToVehicleSerial + '" ' : "") +
                    this.$filter("date")(receival.components[0].receiveDate, "shortDate")
            };
        } else {
            return {
                key: receival.id,
                name: receival.components.length + " x " + receival.components[0].specificationName +
                    " (" + receival.components[0].conditionType + ") " +
                    this.$filter("date")(receival.components[0].eventDetails.eventDate, "shortDate")
            };
        }
    }

    // Load Pending Receivals
    // This is the list of pending received components in the dropdown on mobile
    public async loadPendingReceivalsForMobile() {
        if (!this.isMobile)
            return;

        try {
            let response = await this.dataBroker.getPendingReceivals('components', null);

            if (response) {
                this.receiptedComponents = response.data.map(receival => this.componentDescription(receival));
            }

            this.status = ScreenStatus.ready;
        } catch (error) {
            this.errorReporter.logError(error);
            this.status = ScreenStatus.error;
        }
    }

    // Move to Previous step on the wizard
    public async onPrevious(step) {

        this.hideNextButton = true;

        let currentStep = step;

        this.processing = true;

        try {

            if (this.isStepEqual(step, componentReceiveCtrl_stepEnum.serial)) {

                if (this.pendingFittedComponents || this.pendingComponents) { // return to component type / component condition

                    let st = this.pendingFittedComponents ? componentReceiveCtrl_stepEnum.type : componentReceiveCtrl_stepEnum.condition;

                    this.goToStep(st);

                    while (currentStep.$$prevSibling && !this.isStepEqual(currentStep.$$prevSibling, st)) {
                        currentStep = currentStep.$$prevSibling;
                        currentStep.selected = false;
                        currentStep.visited = false;
                        currentStep.invalid = false;
                    }

                } else { // component serial to component detail
                    this.hideNextButton = false;
                    this.nextText = this.amtXlatSvc.xlat("common.wzrd_next_label");

                    this.hidePreviousButton = this.initParams.fromStocktake;

                    //TODO: this doesn't belong here, but this code is a mess of states and at the moment each method that tweaks the wizard state is responsible for setting it up correctly for that part
                    //this method uses this.startingStep to set the wizard to the details step which will need this data loaded
                    await this.loadPurchaseOrderData();
                }

            } else if (this.isStepEqual(step, componentReceiveCtrl_stepEnum.summary)) { // from summary

                this.restart(false);
                this.selectedReceipted = undefined;
            }

            step.visited = false;
            step.selected = false;
            step.invalid = false;

            currentStep = currentStep.$$prevSibling;
            currentStep.visited = false;

        } finally {
            this.processing = false;
        }
    }

    public isStepEqual(step, val) {
        // handle the different data types
        return step.stepId === val.toString();
    }

    // Move to Next step on the wizard
    public async onNext(step) {

        if (step.stepId == componentReceiveCtrl_stepEnum.details) { // component detail to component serial

            this.showReceiveNone = false;

            // verify date is not in the future
            if (new Date(this.combinedDateTime).getTime() > Date.now()) {
                this.notifySvc.error(this.amtXlatSvc.xlat("equipment.eventDateCannotOccurInFuture"));
                this.errorReporter.logError(this.amtXlatSvc.xlat("equipment.eventDateCannotOccurInFuture", "component.receiveDate"));
                throw "";
            }

            let setNextTextToFinish = true;

            try {
                this.processing = true;

                this.serialStepColumns = this.componentReceiveFactory.getSerialStepColumns(this.selectedComponentCondition, this.selectedComponentType, !!this.erroredReceive);

                this.hidePreviousButton = this.initParams.fromStocktake && (this.previousText === this.returnToStartText || step < componentReceiveCtrl_stepEnum.serial);

                this.serialDescription = this.amtXlatSvc.xlat(this.selectedComponentCondition.isKnown ? "component.serialDescription_knownComponent" : "component.serialDescription_receiveUnknown");

                if (this.selectedComponentCondition.isKnown && (!this.serials || this.serials.length == 0)) {
                    try {
                        await this.getKnownComponents(true, false, true, [this.selectedComponentCondition.associatedStatusType]);
                    } catch {
                        setNextTextToFinish = false;
                    }
                }

                if (!this.selectedComponentCondition.isKnown && this.serials.length == 0)
                    this.editComponentSerial(); // if not known components and none yet in collection, automatically open the 'add' component window

            } finally {
                this.processing = false;
                if (setNextTextToFinish) {
                    this.nextText = this.amtXlatSvc.xlat("common.wzrd_finish_label");
                }
            }

        } else if (step.stepId == componentReceiveCtrl_stepEnum.serial) { // component serial to component summary (finish)

            this.processing = true;

            for (let attachment of this.attachments || [])
                attachment.source = AttachmentType.equipmentEventAttachment;

            // upload files --- this code looks to return a promise, that has no reject function, or error handler. might need some work later
            await this.fileManagement.processFileUploads(this.attachments);

            // receive pending components
            if (this.pendingFittedComponents || this.pendingComponents) {

                try {
                    await this.receivePendingComponents();

                    this.previousText = this.returnToStartText;
                    this.hidePreviousButton = this.initParams.fromStocktake && this.previousText === this.returnToStartText;

                    this.receiveInProgress = false;
                    this.inSummary = true;

                    this.setPristine();
                } catch (error) {
                    // if this is desktop we flagged the errors earlier.
                    if (this.isMobile) {
                        console.error(error);
                        this.WindowFactory.alert("component.saveFailHeading", ["common.ok_label"], "exception.unspecifiedError", [this.errorReporter.exceptionMessage(error)]);
                    }

                    this.canFinishReceive = true;

                    // Stay on the current step (details?)
                    this.goToStep(componentReceiveCtrl_stepEnum.details);
                    step.visited = false;
                } finally {
                    this.processing = false;
                }

            } else {

                try {
                    await this.receiveComponents();

                    this.previousText = this.returnToStartText;
                    this.hidePreviousButton = this.initParams.fromStocktake && (this.previousText === this.returnToStartText);

                    this.receiveInProgress = false;
                    this.inSummary = true;

                    this.setPristine();

                    // setting the location here, spent too long trying to find where the serials collection is being populated
                    for (let s of this.serials) {
                        s.location = this.selectedLocation;
                    }

                    if (this.wnd && this.wnd.onDataChanged) {
                        this.wnd.onDataChanged(this.serials);
                    }
                } catch (error) {
                    this.canFinishReceive = true;

                    //Stay on the current step (details?)
                    this.goToStep(componentReceiveCtrl_stepEnum.details);
                    step.visited = false;

                    this.notifySvc.error(error);
                } finally {
                    this.processing = false;
                }
            }
        }
    };

    // Load Main Reference Data
    public async loadComponentReferenceData() {
        let receiveDate = this.ocDateSvc.removeLocalTimeZoneOffset(this.combinedDateTime);
        let isPending = (this.pendingFittedComponents || this.pendingComponents);

        // Specifications
        if (this.isMobile || isPending || !(this.selectedComponentCondition && this.selectedComponentCondition.isKnown === true)) {

            let getSpecificationsCriteria = {
                componentTypeId: (this.selectedComponentType.id === this.amtConstants.emptyGuid ? null : this.selectedComponentType.id),
                siteIds: [this.ocConfigSvc.user.site.id]
            };

            try {
                this.specifications = (await this.dataBroker.getSpecifications(getSpecificationsCriteria, false)).result;
            } catch (error) {
                this.errorReporter.logError(error, "failed to get specifications");
            }
        }

        // Destination Statuses
        if (this.isMobile || !isPending) {
            try {
                this.statusTypes = await this.dataBroker.getUnknownComponentReceiveDestinationStatusTypes({ conditionType: this.selectedComponentCondition.id });
            } catch (error) {
                this.errorReporter.logError(error, "failed to get status types");
            }
        }

        // Locations
        try {

            let locationCriteria: ILocationCriteria = {
                siteIds: [this.ocConfigSvc.user.site.id],
                date: receiveDate,
                statusTypeIds: this.statusTypes.map(s => s.id)
            };

            this.locations = (await this.dataBroker.getLocations(locationCriteria)).result;

            if (this.initParams && this.initParams.location) {
                this.selectedLocation = this.locations.find(l => l.description === this.initParams.location);
            }

        } catch (error) {
            this.errorReporter.logError(error, "component.locationRetrieveFail");
        }


        // Cost Types
        if (this.isMobile || this.selectedComponentCondition.detailsStepRequires.purchaseType) {
            try {
                this.purchaseTypes = (await this.dataBroker.getCostTypes(null)).result;

                this.purchaseType = this.purchaseType != null ? this.purchaseTypes.find(p => p.name == this.purchaseType.name) : void 0;

            } catch (error) {
                this.errorReporter.logError(error, "component.purchaseTypeRetrieveFail");
            }
        }

        // Component Owners
        if (this.isMobile || this.selectedComponentCondition.detailsStepRequires.componentOwner) {
            try {
                this.componentOwners = (await this.dataBroker.getComponentOwners(this.ocConfigSvc.user.client.id)).result;
            } catch (error) {
                this.errorReporter.logError(error, "component.componentOwnerRetrieveFail");
            }
        }
    };

    //watcher function for PurchaseOrderId, this won't be called until this.loadPurchaseOrderData sets it as a watch
    public async watchPurchaseOrderId(newVal) {
        this.specificationSelection = []; // setting this empty simulates loading?!

        if (!this.selectedComponentCondition || (this.selectedComponentCondition.detailsStepRequires.needPurchaseOrder && newVal == null)) {
            return;
        }
        try {
            if (newVal == null || newVal == 'OTHER') {
                //we keep a copy of all the available specs on mobile (for some reason) might as well use it
                this.specificationSelection = this.specifications && this.specifications.length ? this.specifications :
                    (await this.dataBroker.getSpecifications({ componentTypeId: this.selectedComponentType.id }, false)).result;
            } else {
                this.specificationSelection = await this.dataBroker.getSpecificationsForPurchaseOrder(newVal, this.selectedComponentType.id);

                if (this.selectedSpec && this.specificationSelection.findIndex(s => s.id == this.selectedSpec.id) < 0) { //selected spec not in new specs list
                    this.selectedSpec = null;
                }

            }
        } catch (error) {
            this.errorReporter.logError(error, "failed to get specifications for selected Purchase Order"); //TODO: should this be translated, it shouldn't be seen?
        }
    }

    //Load Purchase Order details
    public async loadPurchaseOrderData() {
        try {
            if (!this.selectedComponentCondition || !this.selectedComponentCondition.detailsStepRequires.showPurchaseOrder) {
                this.specificationSelection = this.specifications && this.specifications.length ? this.specifications :
                    (await this.dataBroker.getSpecifications({ componentTypeId: this.selectedComponentType.id }, false)).result;
                return; //skip loading purchase order data
            }

            this.purchaseOrders = (await this.dataBroker.getUnfulfilledPurchaseOrders(this.selectedComponentType.id, this.selectedComponentType.name))
                .concat({ key: "OTHER", name: this.amtXlatSvc.xlat("component.purchaseOrderNumberOther") });

            if (this.deregisterWatchForPurchaseOrderId) {
                this.deregisterWatchForPurchaseOrderId();
            }

            this.deregisterWatchForPurchaseOrderId = this.$scope.$watch(() => this.purchaseOrderId, newVal => this.watchPurchaseOrderId(newVal));

        } catch (error) {
            this.errorReporter.logError(error, "failed to get purchase orders / specifications");
        }
    }

    componentSort() {
        return (a, b) => {
            let cond1 = a.onSite < b.onSite ? 1 : (a.onSite > b.onSite ? -1 : 0); // component is on current site
            let cond2 = a.site.localeCompare(b.site); // site name
            let cond3 = a.serialNumber.formatted.localeCompare(b.serialNumber.formatted); // serial number

            return cond1 || cond2 || cond3;
        };
    }

    //Filters out any components that have future events - can only be a receive...
    noFutureEvents(element, index, array) {
        return (!element.futureReceive);
    }

    // retrieve all known components that can be received (including pending 3rd party components if requested)
    public async getKnownComponents(setSerialsCollection: boolean, includePendingComponents: boolean, excludeReceived: boolean, statuses?: Array<string>) {

        let componentTypeNamePlural = this.selectedComponentType.name
            ? this.amtXlatSvc.xlat("component." + this.selectedComponentType.name.toLowerCase() + "_Plural")
            : "";

        let receiveDate = this.isMobile ? this.combinedDateTime : this.ocDateSvc.removeLocalTimeZoneOffset(this.combinedDateTime);

        statuses = statuses || this.componentConditions.filter(c => c.isKnown && c.associatedStatusType).map(c => c.associatedStatusType);

        let response = await this.dataBroker.getComponentsInStatus(this.ocConfigSvc.user.client.id, this.ocConfigSvc.user.site.id, statuses,
            [this.selectedComponentType.id], [this.selectedComponentType.name], receiveDate, setSerialsCollection, excludeReceived);

        if (response && response.result) {

            response.result.sort(this.componentSort())

            for (let condition of this.componentConditions) {

                // only do this if we searched for the status associated to the condition
                if (statuses.indexOf(condition.associatedStatusType) < 0)
                    continue;

                let components = response.result.filter(this.noFutureEvents);
                components = components.filter(c => c.status === condition.associatedStatusType);

                for (let c of components) {
                    c.selectedSpec = c.specification;
                    c.status = this.statusTypes.find(s => c.transferredFromNew === true ? s.name === 'NEW' : s.default === true);
                    c.retreader = c.retreaderId;
                    c.repairer = c.repairerId;

                    if (this.selectedComponentCondition && this.selectedComponentCondition.parentConditionId !== 'Retreaded') {
                        if (c.rtd1 != null) {
                            c.rtd = { one: c.rtd1, two: c.rtd2 };
                            c.originalDepth = { one: c.rtd1, two: c.rtd2 };
                        }

                        if (c.rcd1 != null) {
                            c.rcd = { one: c.rcd1, two: c.rcd2 };
                            c.originalDepth = { one: c.rcd1, two: c.rcd2 };
                        }
                    }

                    if (this.selectedComponentCondition && this.selectedComponentCondition.id === 'Transferred' && c.hoursSinceLastNdt) {
                        c.hoursSinceNDT = c.hoursSinceLastNdt;
                    }

                    // if we are changing site, don't default site serial number, unless it is a rim
                    // and the target site has 'Client or Site wide rim serial numbering' set to Client
                    if (c.siteId !== this.ocConfigSvc.user.site.id && (c.equipmentType.name !== 'Rim' ||
                        this.ocConfigSvc.user.site.settings.rimSerialNumberGenerationScheme !== 'ClientSpecific')) {
                        c.serialNumber.site = null;
                    }
                }

                if (components && components.length) {
                    condition.disabled = false;
                    condition.description = this.amtXlatSvc.xlat("component.componentConditionDescription" + condition.id, componentTypeNamePlural);
                }

                condition.loading = false;
                condition.components = components;

                //if setSerialsCollection is true, statuses must have exactly one element and this happens only once
                if (setSerialsCollection) {
                    this.serials = components;
                }
            }
        }

        if (includePendingComponents) {
            try {
                let pendingComponentsResult = await this.getPendingComponents(this.selectedComponentType.id, this.pendingFittedComponents, true, !this.isMobile);

                let pendingCondition = this.componentConditions.find(conditionButton => conditionButton.id === "Pending");

                if (pendingCondition) {
                    if (pendingComponentsResult.length > 0) {
                        pendingCondition.disabled = false;
                        pendingCondition.description = this.amtXlatSvc.xlat("component.componentConditionDescriptionPending", componentTypeNamePlural);
                    }
                    pendingCondition.loading = false;
                }
            } catch (error) {
                this.processing = false;
                this.errorReporter.logError(error, "component.pendingRetrieveFail");
            }
        }
    }

    // Request confirmation for receive process restart and then abandon data and return to first step
    public async restart(requireConfirmation) {
        try {
            if (!requireConfirmation || await this.confirmSvc.confirmMessage("component.confirmWizardAbort_Title", "component.confirmWizardAbort_Content")) {
                // Restart the receive process by abandoning data and returning to the first step on the wizard
                this.receiveInProgress = false;
                this.inSummary = false;
                this.lastReceivedId = undefined;
                this.erroredReceive = null;

                this.selectedComponentType = undefined;

                this.combinedDateTime = undefined;

                this.purchaseOrder = "";
                this.selectedSpec = undefined;
                this.unitCost = 0;
                this.purchaseOrder = {};
                this.purchaseOrderNumber = "";
                this.purchaseType = undefined;
                this.comment = "";
                this.selectedLocation = undefined;
                this.attachments = [];
                this.componentOwner = undefined;

                this.hideNextButton = true;
                this.hidePreviousButton = false;
                this.nextText = this.amtXlatSvc.xlat("common.wzrd_next_label"); // "Next »".
                this.previousText = this.amtXlatSvc.xlat("component.receiveWizardPrevious");
                this.summaryTitle = undefined;
                this.pendingFittedComponents = false;

                this.wizardControl.restart();

                await this.loadPendingReceivalsForMobile();
            }
        } catch { /* eat failure, it's probably confirmSvc throwing to tell us the user cancelled */ }
    }

    // Receive non-Pending (3rd party) components
    public async receiveComponents() {
        this.currencySymbol = this.ocConfigSvc.user.site.currency.symbol;
        let isKnown = this.selectedComponentCondition.isKnown === true;

        // check at least 1 component is checked for receive
        let componentsToReceive = isKnown ? this.serials.filter(s => s.selected === true) : this.serials;
        if (componentsToReceive.length <= 0) {
            throw this.amtXlatSvc.xlat("component.receiveNoComponentsSelected");
        }

        let hasInvalidComponents = false;
        for (let c of componentsToReceive) {
            if (!this.validateComponent(c)) {
                hasInvalidComponents = true;
                this.notifySvc.error(this.amtXlatSvc.xlat("component.receiveMissingMandatoryData", c.serialNumber.manufacturer));
            }
        }

        if (hasInvalidComponents) {
            throw ""; //TODO: we should throw better exception objects than this
        }

        let readingObj = (evtTypeName, newMeterParentCondId, units, val1, val2?) => ({
            readingEventTypeName: evtTypeName,
            isNewMeter: !!newMeterParentCondId && this.selectedComponentCondition.parentConditionId === newMeterParentCondId,
            unitOfMeasureAbbreviation: this.ocConfigSvc.user.site.units[units.toLowerCase()].unitAbbreviation,
            values: val2 == null ? [{ value: val1, sequence: 1 }] : [{ value: val1, sequence: 1 }, { value: val2, sequence: 2 }]
        });

        // process and upload any attachments added to individual components
        let attachments = [];

        for (let component of componentsToReceive || []) {
            for (let attachment of component.attachments || []) {
                attachment.source = AttachmentType.equipmentEventAttachment;
                attachments.push(attachment);
            }
        }

        await this.fileManagement.processFileUploads(attachments);

        let saveCriteria = {
            createdDate: new Date(),
            isDesktop: !this.isMobile,
            pendingReceivalId: this.erroredReceive ? this.erroredReceive.PendingReceivalId : null,
            displayDescription: undefined,
            isKnown: isKnown,
            attachments: angular.copy(this.attachments || []).map(f => this.fileManagement.clearData(f)),
            components: this.setComponentReceive(componentsToReceive, readingObj, isKnown)
        };

        saveCriteria.displayDescription = this.componentDescription(saveCriteria).name;

        try {
            let response = await (isKnown ?
                this.dataBroker.receiveKnownComponents(this.lastReceivedId, saveCriteria) :
                this.dataBroker.receiveUnknownComponents(this.lastReceivedId, saveCriteria)
            );

            // on desktop generate any component failure reports requested for components received into scrapped
            if (!this.isMobile) {
                let componentsToGenerateComponentFailureReports = componentsToReceive
                    .filter(c => c.generateComponentFailureReport)
                    .map(c => c.serialNumber.manufacturer);

                if (componentsToGenerateComponentFailureReports?.length)
                    this.reportSvc.downloadComponentFailureReports(componentsToGenerateComponentFailureReports);
            }

            this.lastReceivedId = response;
            this.erroredReceive = null;
            this.inSummary = true;
            this.summaryTitle = this.amtXlatSvc.xlat('component.receiveWizardSummaryTitle_normal');

            await this.loadPendingReceivalsForMobile();

            // this appears to be where we loose the location, but not sure how componentsToReceive should get the location.
            this.serials = componentsToReceive;

            this.prepareSummary(componentsToReceive);

            this.notifySvc.success(this.amtXlatSvc.xlat("component.receiveWizardSuccessful"));

        } catch (error) {
            this.errorReporter.logError(error, "component.receive");
            throw error;
        }
    };

    private isUndefinedOrNull(data: any): boolean{
        return data === undefined || data === null;
    }

    private setComponentReceive(componentReceive: any[], readingObj: any, isKnown: boolean) {
        return componentReceive.map(
            (c) => {
                let comp = { ...c };  // clone c to avoid mutating the original object
                comp.equipmentId = c.id;
                comp.conditionType = this.selectedComponentCondition.id;
                comp.manufacturerSerialNumber = !this.isUndefinedOrNull(c.serialNumber.manufacturer) ? c.serialNumber.manufacturer.toUpperCase() : null;
                comp.siteSerialNumber = !this.isUndefinedOrNull(c.serialNumber?.site) ? c.serialNumber.site.toUpperCase() : null;
                comp.specificationId = !this.isUndefinedOrNull(c.selectedSpec?.id) ? c.selectedSpec.id : !this.isUndefinedOrNull(this.selectedSpec?.id) ? this.selectedSpec.id : null;
                comp.specificationName = !this.isUndefinedOrNull(c.selectedSpec) ? c.selectedSpec.description : !this.isUndefinedOrNull(this.selectedSpec?.description) ? this.selectedSpec.description : null;
                comp.equipmentTypeId = !this.isUndefinedOrNull(this.selectedComponentType?.id) ? this.selectedComponentType.id : null;
                comp.excludeFromPerformanceAnalysis = c.excludeFromPerformanceAnalysis;
                comp.equipmentTypeName = this.selectedComponentType.name;
                comp.componentOwnerId = !this.isUndefinedOrNull(c.componentOwner) ? c.componentOwner : !this.isUndefinedOrNull(this.componentOwner?.id) ? this.componentOwner.id : null;
                comp.hoursSinceNDT = !isKnown && !this.isUndefinedOrNull(c.hoursSinceNDT) ? c.hoursSinceNDT : null;
                comp.ndtResult = c.ndtStatus != null ? c.ndtStatus.key === 1 : (this.selectedComponentType.name.toLowerCase() === EquipmentTypeName.rim && !isKnown && c.hoursSinceNDT ? true : null);
                comp.location = this.selectedLocation;
                comp.eventDetails = {
                    isEdit: false,
                    eventDate: this.ocDateSvc.removeLocalTimeZoneOffset(this.combinedDateTime),
                    comment: this.comment,
                    attachments: angular.copy(c.attachments || []).map(f => this.fileManagement.clearData(f)),
                    movementDetails: {
                        locationId: this.selectedLocation ? this.helperSvc.getKey(this.selectedLocation) : "",
                        locationName: this.selectedLocation ? this.selectedLocation.name : "", // Needed for mobile search only
                        newStatusTypeId: this.helperSvc.getKey(c.status),
                        receiveDetails: {
                            purchaseOrderNumber: this.purchaseOrderNumber,
                            purchaseOrderId: ((this.purchaseOrder && this.purchaseOrder.key !== 'OTHER') ? this.purchaseOrder.key : undefined),
                            invoiceReference: this.invoiceReference,
                            retreadName: null
                        },
                        repairDetails: c.repairer ? { repairerId: c.repairer } : null,
                        retreadDetails: c.retreader ? { retreaderId: c.retreader } : null
                    },
                    readingDetails: {
                        readings: [
                            c.rtd && c.rtd.one != null ? readingObj(ReadingTypeName.treadDepth, ComponentConditionType.retreaded, BaseReadingUnit.depth, c.rtd.one, c.rtd.two) : void 0,
                            c.rcd && c.rcd.one != null ? readingObj(ReadingTypeName.chainDepth, ComponentConditionType.retreaded, BaseReadingUnit.depth, c.rcd.one, c.rcd.two) : void 0,
                            !isKnown ? readingObj(ReadingTypeName.hours, null, BaseReadingUnit.time, c.hours || 0) : void 0,
                            !isKnown ? readingObj(ReadingTypeName.distance, null, BaseReadingUnit.distance, c.distance || 0) : void 0
                        ].filter(r => r)
                    },
                    costDetails: {
                        amount: (c.cost ? c.cost : this.unitCost),
                        costTypeId: this.helperSvc.getKey(this.purchaseType),
                        costTypeName: this.purchaseType ? this.purchaseType.name : "", // Needed for mobile search only
                        currencyTypeId: this.ocConfigSvc.user.site.currency.id,
                        currencyDecimalPlaces: this.ocConfigSvc.user.site.currency.decimalPlaces,
                        currencySymbol: this.ocConfigSvc.user.site.currency.symbol
                    }
                };
                return comp;  // return the modified object
            }
        );
    }

    private prepareSummary(components) {

        // if multiple specifications, show 'multiple' on summary
        if (!_.every(components, function (component) {
            return component.specification.id == components[0].specification.id;
        })) {
            this.selectedSpec = {
                description: this.amtXlatSvc.xlat("component.multiple_label")
            };
        } else {
            if (!this.selectedSpec || !this.selectedSpec.id) {
                this.selectedSpec = components[0].specification;
            }
        }

        // if multiple costs, show 'multiple' on summary
        if (!_.every(components, function (component) {
            return component.cost == components[0].cost;
        })) {
            this.unitCost = {
                description: this.amtXlatSvc.xlat("component.multiple_label")
            };
        } else {
            this.unitCost = (
                this.selectedComponentCondition.serialPopupRequires.costDetails === true && components[0].cost != null ?
                    components[0].cost :
                    this.unitCost
            );
        }

        if (this.selectedComponentCondition.parentConditionId === ReceiveConditionType.retreaded) {
            this.selectedComponentCondition.name = this.amtXlatSvc.xlat("component.componentConditionNameRetreaded");
        }

        this.summaryItems = this.componentReceiveFactory.buildReceiveSummary(this);
    };

    // validate that selected components have mandatory fields populated
    public validateComponent(component) {

        // tread depth
        if (this.selectedComponentCondition.serialPopupRequires.rtd &&
            this.selectedComponentType.name.toLowerCase() === EquipmentTypeName.tyre &&
            (!component.rtd || component.rtd.one == null || component.rtd.two == null)
        ) {
            return false;
        }

        // chain depth
        if (this.selectedComponentCondition.serialPopupRequires.rcd &&
            this.selectedComponentType.name.toLowerCase() === EquipmentTypeName.chain &&
            (!component.rcd || component.rcd.one == null || component.rcd.two == null)
        ) {
            return false;
        }

        // hours
        if (this.selectedComponentCondition.serialPopupRequires.hours && (!component.hours && component.hours !== 0)) {
            return false;
        }

        // site serial number
        if (this.ocConfigSvc.user.site.settings.tyreMaintenance.siteSerialNumber[this.selectedComponentType.name] === ConfigurationOption.mandatory &&
            (!component.serialNumber.site || component.serialNumber.site === "")) {
            return false;
        }

        // component owner
        if (this.ocConfigSvc.user.site.settings.enforceComponentOwner === ConfigurationOption.mandatory && !component.componentOwner && !this.componentOwner) {
            return false;
        }

        // cost
        if (this.selectedComponentCondition.serialPopupRequires.costDetails && component.cost == null && this.unitCost == null) {
            return false;
        }

        return true;
    }

    // Receive pending components (3rd party)
    public async receivePendingComponents() {
        let componentsToReceive = this.selectedComponentCondition.isKnown === true ? this.serials.filter(s => s.selected === true) : this.serials;

        if (componentsToReceive.length <= 0) {
            throw this.amtXlatSvc.xlat("component.receiveNoComponentsSelected");
        }

        let invalidComponents = [];
        let now = new Date();
        for (let item of componentsToReceive) {
            if (!this.validateComponent(item)) {
                invalidComponents.push(this.amtXlatSvc.xlat("component.receiveMissingMandatoryData", item.serialNumber.manufacturer));
            }

            if (new Date(item.date) > now) {
                invalidComponents.push(this.amtXlatSvc.xlat("component.receiveEventDateCannotOccurInFuture", item.serialNumber.manufacturer));
            }
        }

        if (invalidComponents && invalidComponents.length > 0) {
            invalidComponents.forEach(c => { this.notifySvc.error(c) });
            this.processing = false;
            throw invalidComponents[0];
        }

        let saveCriteria = {
            fitComponents: this.pendingFittedComponents,
            clientId: this.ocConfigSvc.user.client.id,
            siteId: this.ocConfigSvc.user.site.id,
            createdDate: new Date(),
            isDesktop: !this.isMobile,
            displayDescription: undefined,
            components: componentsToReceive.map(item => ({
                id: item.id,
                equipmentId: item.id,
                equipmentTypeId: this.helperSvc.getKey(item.equipmentType),
                equipmentTypeName: item.equipmentType.name,
                receiveDate: this.ocDateSvc.removeLocalTimeZoneOffset(item.date),
                componentOwnerId: item.componentOwner,
                siteSerialNumber: (item.serialNumber.site ? item.serialNumber.site.toUpperCase() : null),
                manufacturerSerialNumber: (item.serialNumber.manufacturer ? item.serialNumber.manufacturer.toUpperCase() : null),
                specificationId: item.specification.id,
                specificationName: this.specifications.find(s => s.id === item.specification.id).description,
                costDetails: {
                    amount: item.cost,
                    // TODO: CostTypeId & CostTypeName will never have a value for pending components. Question asked to Client.
                    costTypeId: this.helperSvc.getKey(this.purchaseType),
                    costTypeName: this.purchaseType && this.purchaseType.name, // Needed for mobile search only
                    currencyTypeId: this.ocConfigSvc.user.site.currency.id,
                    currencyDecimalPlaces: this.currencyDecimalPlaces,
                    currencySymbol: this.currencySymbol // Needed for mobile search only
                },
                fittedToVehicleSerial: item.fittedToVehicleSerial, // Needed for mobile search only
                fittedToPosition: item.fittedToPosition, // Needed for mobile search only
                fittedToPositionDescription: item.fittedToVehicleSerial + " (" + item.fittedToPosition + ")"
            }))
        };

        saveCriteria.displayDescription = this.componentDescription(saveCriteria).name;

        try {
            this.lastReceivedId = await this.dataBroker.receivePendingComponents(this.lastReceivedId, saveCriteria);

            this.summaryTitle = this.amtXlatSvc.xlat(this.pendingFittedComponents ? 'component.receiveWizardSummaryTitle_pending' : "component.receiveWizardSummaryTitle_ordered");

            this.pendingSummaryColumns = this.componentReceiveFactory.buildPendingReceiveSummary(this.pendingFittedComponents);

            await this.loadPendingReceivalsForMobile();

            this.serials = componentsToReceive;

            this.notifySvc.success(this.amtXlatSvc.xlat(this.pendingFittedComponents ? "component.receiveWizardSuccessful_pending" : "component.receiveWizardSuccessful_ordered"));

        } catch (error) {

            if (!this.isMobile) {
                this.notifySvc.error(this.errorReporter.exceptionMessage(error));
            }

            throw error;
        }
    }

    // On press of the Home button on mobile (return to landing page)
    public async menu() {
        try {
            if (!this.receiveInProgress || await this.confirmSvc.confirmMessage("component.confirmWizardAbort_Title", "component.confirmWizardAbort_Content"))
                this.$state.go("mobile.landing");
        } catch (e) {
            //they cancelled, no need to do anything
        }
    }

    // Binding of data to the error grid
    public onDatabound = function (event) {

        this.processing = true; // Should be true anyway, but just make sure.

        // Make all the rows red background
        if (event) {
            let rows = event.sender.tbody.children();

            for (let i = 0; i < rows.length; i++) {
                this.$(rows[i]).addClass("bg-danger"); // light red background
            }
        }

        this.processing = false;
        this.$timeout();
    }

    public bindSerialErrors() {
        if (!this.serialGridControl || !this.serialGridControl.setRowClass || !this.serialGridControl.removeRowClass || !this.serials)
            return;

        for (let s of this.serials) {
            if (this.erroredReceive != null && this.errorDisplay && this.errorDisplay.checkError('ManufacturerSerialNumber', s.serialNumber.manufacturer, false, s.itemNo)) {
                this.serialGridControl.setRowClass(s.itemNo - 1, this.currentError.rowNo === s.itemNo ? "bg-current-danger" : "bg-danger");
            } else {
                this.serialGridControl.removeRowClass(s.itemNo - 1);
            }
        }
    }

    // Deletes receipted component and returns to start of receive process (Mobile Only - Delete button on summary screen)
    public async deleteReceiptedComponents() {
        try {
            await this.confirmSvc.confirmMessage_cancelPrimary("component.confirmDeleteReceived_Title",
                (this.pendingFittedComponents ? "component.confirmDeleteReceivedFitted_Content" : "component.confirmDeleteReceived_Content"));
        } catch (e) {
            return; // they cancelled
        }

        if (this.lastReceivedId == null)
            return;

        try {
            await this.dataBroker.deleteReceiptedComponents(this.lastReceivedId);

            let filesToDelete = [];

            for (let attachment of this.attachments || [])
                filesToDelete.push(attachment.id);

            for (let component of this.serials || [])
                for (let attachment of component.attachments || [])
                    filesToDelete.push(attachment.id);

            await this.fileManagement.deleteFiles(filesToDelete);

            this.notifySvc.success(this.amtXlatSvc.xlat("component.receiveWizardDeleteSuccessful"));
            this.restart(false);
            this.selectedReceipted = undefined;
        } catch (error) {
            this.errorReporter.logError(error, "component.deletedPendingMobile");
            this.status = ScreenStatus.error;
        }
    }

    // Edit a receipted component (Mobile Only - Edit button on summary screen)    
    public async editReceiptedComponents() {

        if (this.lastReceivedId == null)
            return;

        // go to 'component serials' step
        this.serialStepColumns = this.componentReceiveFactory.getSerialStepColumns(this.selectedComponentCondition, this.selectedComponentType, false);

        this.nextText = this.amtXlatSvc.xlat("common.wzrd_finish_label");
        this.previousText = this.amtXlatSvc.xlat("component.receiveWizardPrevious");

        this.hideNextButton = false;
        this.hidePreviousButton = false;

        let currentStep = this.goToStep(componentReceiveCtrl_stepEnum.serial);
        currentStep.visited = false;

        this.receiveInProgress = true;
        this.inSummary = false;
        this.showReceiveNone = false;

        if (this.pendingFittedComponents || this.pendingComponents) {

            this.processing = true;

            this.serialDescription = this.amtXlatSvc.xlat("component.receiveWizardComponentSerialDesc_edit");

            try {
                // User chose "Pending Fitted Components", grab them from the server.
                let pendingComponents = await this.getPendingComponents(this.selectedComponentType.id, this.pendingFittedComponents, false, !this.isMobile);

                if (this.serials && this.serials.length > 0) {

                    let selectedComponent = pendingComponents.find(component => component.serialNumber.manufacturer === this.serials[0].serialNumber.manufacturer);

                    if (selectedComponent) {
                        this.serials[0].dataFields = selectedComponent.dataFields;
                        this.serials[0].equipmentType = selectedComponent.equipmentType;
                    }
                }
            } catch (error) {
                this.errorReporter.logError(error, "component.pendingRetreiveFail");
            } finally {
                this.processing = false;
            }

        } else if (this.selectedComponentCondition.isKnown === true) {

            this.processing = true;

            let receivedSerials = angular.copy(this.serials);

            try {
                await this.getKnownComponents(true, false, false, [this.selectedComponentCondition.associatedStatusType]);

                for (let rs of receivedSerials) {
                    let matchingItemIndex = _.findIndex(this.serials, s => s.id === rs.id);

                    if (matchingItemIndex > -1) {
                        rs.site = this.serials[matchingItemIndex].site;
                        this.serials[matchingItemIndex] = rs;
                    }
                }
            } catch (error) {
                this.errorReporter.logError(error, "component.pendingRetreiveFail");
            } finally {
                this.processing = false;
            }
        }

        await this.loadPendingReceivalsForMobile();
    }

    // Returns to the Stocktake if the receive was initiated from there (Mobile Only)
    public async returnToStocktake() {
        if (this.browserSvc.isMobile) {
            this.$state.go("mobile.stocktake", { receivedSerials: this.serials });
        } else {
            this.closeWindow();
        }
    }

    // Edit the details of a component to receive (click of serial number on component serial step)
    editComponentSerial(dataItem?) {

        let mode = dataItem ? 'edit' : 'new';

        let isPending = (this.pendingFittedComponents || this.pendingComponents);
        let isKnown = this.selectedComponentCondition.isKnown === true;

        let caption = this.pendingComponents ? this.amtXlatSvc.xlat("component.receivePopupTitle_pending", dataItem.serialNumber.manufacturer) :
            this.amtXlatSvc.xlat(dataItem /* new */ ? "component.receivePopupTitle_add" :
                                                                    /* edit */ "component.receivePopupTitle_edit", this.selectedComponentType.description);

        let componentOwnerId = dataItem && dataItem.componentOwner ? dataItem.componentOwner : (this.componentOwner ? this.componentOwner.id : null);

        let lastId = this.serials.length ? this.serials[this.serials.length - 1] : null;

        this.WindowFactory.openItem({
            component: "component-receive-popup",
            caption: caption,
            canClose: false,
            initParams: {
                mode: mode,
                lastId: lastId != null ? lastId : 0,
                date: this.combinedDateTime,
                source: dataItem,
                conditionType: this.selectedComponentCondition,
                componentType: (dataItem ? (dataItem.equipmentType || this.selectedComponentType) : this.selectedComponentType),
                componentOwnerId: componentOwnerId,
                pending: isPending,
                pendingFitted: this.pendingFittedComponents,
                existingSerials: this.serials.filter(s => !dataItem || s.id !== dataItem.id),
                currencySymbol: this.ocConfigSvc.user.site.currency.symbol,
                currencyDecimalPlaces: this.ocConfigSvc.user.site.currency.decimalPlaces,
                spec: this.selectedSpec,
                specDetails: this.specificationDetails,
                unitCost: this.unitCost,
                puchaseOrder: this.purchaseOrder,
                purchaseOrderId: this.purchaseOrder ? this.purchaseOrder.id : undefined,
                purchaseOrderNumber: this.purchaseOrderNumber,
                disableSaveAndNext: dataItem && dataItem.id != null && lastId == dataItem.id,
                // For normal items, this will return everything (i.e. no filtering). For pending items, it will reduce the list from all specs to relevant specs.
                specifications: dataItem ? this.specifications.filter(spec => spec.equipmentTypeId === dataItem.equipmentType.key) : this.specifications,
                requiredFields: {
                    manSerialNo: !isPending && !isKnown,
                    manSerialNoLabel: isKnown,
                    statusCombo: this.selectedComponentCondition.serialPopupRequires.statusCombo,
                    date: isPending,
                    dataFields: this.selectedComponentCondition.serialPopupRequires.dataFields,
                    componentOwner: this.selectedComponentCondition.serialPopupRequires.compOwner,
                    rtd: this.selectedComponentCondition.serialPopupRequires.rtd && this.selectedComponentType.name.toLowerCase() === "tyre",
                    hours: this.selectedComponentCondition.serialPopupRequires.hours,
                    hoursLabel: this.selectedComponentCondition.serialPopupRequires.hoursLabel,
                    distance: this.selectedComponentCondition.serialPopupRequires.distance,
                    distanceLabel: this.selectedComponentCondition.serialPopupRequires.distanceLabel,
                    cost: this.selectedComponentCondition.serialPopupRequires.costDetails,
                    statusLabel: this.selectedComponentCondition.serialPopupRequires.statusLabel,
                    retreader: this.selectedComponentCondition.serialPopupRequires.retreader,
                    repairer: this.selectedComponentCondition.serialPopupRequires.repairer,
                    hoursSinceLastNdt: this.selectedComponentCondition.serialPopupRequires.hoursSinceLastNdt && this.selectedComponentType.name.toLowerCase() === "rim",
                    hoursSinceLastNdtLabel: this.selectedComponentCondition.serialPopupRequires.hoursSinceLastNdtLabel && this.selectedComponentType.name.toLowerCase() === "rim",
                    ndtStatus: this.selectedComponentCondition.serialPopupRequires.ndtStatus && this.selectedComponentType.name.toLowerCase() === "rim",
                    rcd: this.selectedComponentCondition.serialPopupRequires.rcd && this.selectedComponentType.name.toLowerCase() === "chain",
                    specification: this.selectedComponentCondition.serialPopupRequires.specification,
                    specificationLabel: this.selectedComponentCondition.serialPopupRequires.specificationLabel
                }
            },
            width: 825,
            top: this.isMobile ? 5 : null,
            modal: true,
            onDataChangeHandler: (result) => {
                if (result.editedSerial) {
                    let target = result.editedSerial;

                    dataItem.serialNumber = {
                        manufacturer: target.serialNumber.manufacturer,
                        site: target.serialNumber.site
                    };

                    dataItem.status = target.status;
                    dataItem.date = target.date;
                    dataItem.specification = target.specification || dataItem.specification;
                    dataItem.cost = target.unitCost;
                    dataItem.purchaseOrder = target.purchaseOrder;
                    dataItem.purchaseOrderId = target.purchaseOrder ? target.purchaseOrder.key : undefined;
                    dataItem.purchaseOrderNumber = target.purchaseOrderNumber;
                    dataItem.componentOwner = target.componentOwner;
                    dataItem.rtd = target.rtd;
                    dataItem.hours = target.hours;
                    dataItem.distance = target.distance;
                    dataItem.retreader = target.retreader;
                    dataItem.repairer = target.repairer;
                    dataItem.hoursSinceNDT = target.hoursSince;
                    dataItem.ndtStatus = target.ndtStatus;
                    dataItem.excludeFromPerformanceAnalysis = target.excludeFromPerformanceAnalysis;
                    dataItem.rcd = target.rcd;
                    dataItem.attachments = target.attachments;
                    dataItem.valid = true;
                    dataItem.selected = true;
                    dataItem.generateComponentFailureReport = target.generateComponentFailureReport;

                } else if (result.newSerial) {
                    let target = result.newSerial;

                    // *Must* push a new object or the grid modifies the object and causes an infinite loop
                    this.serials.unshift({
                        id: target.id,
                        serialNumber: {
                            manufacturer: target.serialNumber.manufacturer,
                            site: target.serialNumber.site
                        },
                        location: this.selectedLocation,
                        status: target.status,
                        equipmentType: this.selectedComponentType,
                        componentOwner: target.componentOwner,
                        rtd: target.rtd,
                        hours: target.hours,
                        distance: target.distance,
                        cost: target.unitCost,
                        purchaseOrder: target.purchaseOrder,
                        purchaseOrderId: target.purchaseOrder ? target.purchaseOrder.key : undefined,
                        purchaseOrderNumber: target.purchaseOrderNumber,
                        // These are never edited, but need to be defined or grid will fail
                        fittedToVehicleSerial: undefined,
                        fittedToPosition: undefined,
                        fittedToPositionDescription: undefined,
                        specification: this.selectedSpec,
                        retreader: target.retreader,
                        repairer: target.repairer,
                        excludeFromPerformanceAnalysis: target.excludeFromPerformanceAnalysis,
                        hoursSinceNDT: target.hoursSince,
                        ndtStatus: target.ndtStatus,
                        rcd: target.rcd,
                        attachments: target.attachments,
                        valid: true,
                        selected: true
                    });
                }

                if (result.addAnother) {

                    this.editComponentSerial();

                } else if (result.next) {

                    let index = this.serials.findIndex(element => element.id === result.editedSerial.id);

                    this.editComponentSerial(this.serials[index + 1]);
                }
            }
        });
    }


    // toggle receive checkbox for all components in serials grid
    toggleSelectAllComponentSerials() {

        this.showReceiveNone = !this.showReceiveNone;

        if (this.serials && this.serials.length > 0) {
            for (let s of this.serials) {
                if (s.valid === true) { s.selected = this.showReceiveNone }
            }
        }
    };

    // Delete a component that was to be received (deleted from serials grid)
    async deleteComponentSerial(dataItem) {
        let index = _.findIndex(this.serials, element => element.id === dataItem.id);

        if (index > -1) {
            try {
                await this.confirmSvc.confirmMessage("component.confirmDeleteSerialTitle", "component.confirmDeleteSerialContent", dataItem.serialNumber.manufacturer);
            } catch (error) {
                return; // they cancelled
            }

            this.serials.splice(index, 1);
        }
    }

    public setPristine() {
        if (this.form) {
            this.form.$setPristine();
        }
    }

    // Open an errored component receive so that it can be completed (Desktop)
    public async openPendingReceival(dataItem) {
        try {
            this.processingValidations = true;
            this.receiveInProgress = true;

            this.erroredReceive = dataItem.data;
            this.erroredReceive.PendingReceivalId = dataItem.id;

            this.inSummary = false;

            let components = dataItem.data.components;

            this.serialDescription = this.amtXlatSvc.xlat("component.receiveWizardComponentSerialDesc_edit");
            this.nextText = this.amtXlatSvc.xlat("common.wzrd_finish_label");
            this.hideNextButton = false;

            if (dataItem.conditionType.id !== ReceiveConditionType.pending) {

                this.selectedComponentType = this.componentTypes.find(ct => (components[0].equipmentTypeId && ct.id === components[0].equipmentTypeId) ||
                    components[0].equipmentTypeName == ct.name);

                await this.initialiseComponentConditionList(true, true);

                this.selectedComponentCondition = _.find(this.componentConditions, condition => condition.id === components[0].conditionType);

                // set once for all components
                let hasComponentDate = !this.isUndefinedOrNull(components[0]?.eventDetails?.eventDate);
                let hasReceiveDate = !this.isUndefinedOrNull(components[0]?.receiveDate);
                this.combinedDateTime = hasComponentDate ? components[0]?.eventDetails?.eventDate
                                      : hasReceiveDate ? components[0]?.receiveDate
                                      : new Date();

                await this.loadComponentReferenceData();

                await this.mapPendingReceiveToWizard(components);

                this.serialStepColumns = this.componentReceiveFactory.getSerialStepColumns(this.selectedComponentCondition, this.selectedComponentType, !!this.erroredReceive);

                if (!this.serials || this.serials.length === 0) {

                    this.processingValidations = false;
                    this.processing = false;

                    this.WindowFactory.alert("component.pendingReceiveComponentsNoLongerExist_header", ["common.ok_label"],
                        "component.pendingReceiveComponentsNoLongerExist", null, 650);
                } else {

                    if (this.selectedComponentCondition.isKnown) {
                        //By using angular.copy, it will deeply copy from this.serials then create temporary seperated variable from this.serials
                        let receivedSerials = angular.copy(this.serials);

                        await this.getKnownComponents(true, false, false, [this.selectedComponentCondition.associatedStatusType]);

                        //by using map transform the data then feed into this.serial and delete the existing item
                        receivedSerials.map((item, index) => {
                            item.site = this.serials[index].site;
                            item.site = this.serials[index].site;
                            item.serialNumber.manufacturer = this.serials[index].serialNumber.manufacturer;
                            item.equipmentType = this.serials[index].equipmentType;
                            item.selectedSpec = this.serials[index].selectedSpec;
                            item.specification = this.serials[index].specification;
                            this.serials.splice(index, 1);
                            this.serials.unshift(item);
                        });
                    }
                    this.goToStep(componentReceiveCtrl_stepEnum.serial);
                }

            } else {

                this.pendingFittedComponents = dataItem.data.fitComponents === true;
                this.pendingComponents = !dataItem.data.fitComponents;

                if (this.pendingFittedComponents) {
                    // get pending fitted component condition
                    let pendingFittedCondition = this.componentReceiveFactory.getPendingFittedCondition();
                    pendingFittedCondition.key = this.amtConstants.emptyGuid;

                    this.selectedComponentType = pendingFittedCondition;
                    this.selectedComponentCondition = pendingFittedCondition;
                } else {
                    this.selectedComponentType = _.find(this.componentTypes, ct =>
                        (components[0].equipmentTypeId && ct.id === components[0].equipmentTypeId) || (components[0].equipmentTypeName == ct.name));

                    await this.initialiseComponentConditionList(true, true);

                    this.selectedComponentCondition = this.componentConditions.find(condition => condition.id === components[0].conditionType);
                }

                this.hidePreviousButton = true;

                await this.loadComponentReferenceData();

                let pendingStatusDescription = this.amtXlatSvc.xlat("component.pending");

                this.pendingData = await this.getPendingComponents(this.selectedComponentType.key, this.pendingFittedComponents, true, false);

                if (this.pendingData.length > 0) {

                    this.serials = components
                        .map(c => ({ component: c, pendingItem: this.pendingData.find(p => c.id === p.id) }))
                        .filter(c => c.pendingItem)
                        .map((c, i) => ({
                            itemNo: i + 1,
                            id: c.pendingItem.id,
                            serialNumber: {
                                manufacturer: c.pendingItem.serialNumber.manufacturer,
                                site: c.component.siteSerialNumber
                            },
                            equipmentType: this.componentTypes.find(t => t.id === c.pendingItem.equipmentType.key),
                            date: c.component.receiveDate,
                            cost: c.component.costDetails.amount,
                            specification: this.specifications.find(specification => specification.id === c.specificationId),
                            fittedToVehicleSerial: c.pendingItem.fittedToVehicleSerial,
                            fittedToPosition: c.pendingItem.fittedToPosition,
                            fittedToPositionDescription: c.pendingItem.fittedToVehicleSerial + " (" + c.pendingItem.fittedToPosition + ")",
                            status: {
                                name: "Pending",
                                description: pendingStatusDescription
                            },
                            dataFields: c.pendingItem.dataFields
                        }));

                    this.serialStepColumns = this.componentReceiveFactory.getSerialStepColumns(this.selectedComponentCondition, this.selectedComponentType, !!this.erroredReceive);
                }

                if (!this.serials || this.serials.length === 0) {
                    this.WindowFactory.alert("component.pendingReceiveComponentsNoLongerExist_header", ["common.ok_label"],
                        "component.pendingReceiveComponentsNoLongerExist", null, 650);
                } else {
                    this.goToStep(componentReceiveCtrl_stepEnum.serial);
                }
            }

        } catch (error) {
            this.errorReporter.logError(error, "component.pendingRetreiveFail");
        } finally {
            this.processingValidations = false;
        }
    }
    // end scope functions      

    processConditionTypeSelect(condition) {
        if (condition)
            this.componentConditionClick(condition);
    }

    private clearReceiveDetails() {
        // clear any 'component detail' value
        this.combinedDateTime = undefined;
        this.selectedSpec = undefined;
        this.unitCost = 0;
        this.purchaseOrder = undefined;
        this.purchaseOrderId = undefined;
        this.purchaseOrderNumber = undefined;
        this.purchaseType = undefined;
        this.componentOwner = undefined;
        this.comment = undefined;
        this.selectedLocation = undefined;
        this.attachments = [];
    }

    public closeWindow() {
        this.WindowFactory.closeWindow(this.wnd);
    }

    public async cancel() {
        try {
            await this.confirmSvc.confirmSaveChange(this.form.$dirty);
            this.setPristine();
            this.closeWindow();
            return true;
        } catch { /* confirmSvc throws if they cancel */ }
        return false;
    }

    // map from upload request format (pendingReceival) to format needed for display in component receive wizard
    private async mapPendingReceiveToWizard(components) {

        let component = components[0];

        this.purchaseOrderId = component.eventDetails.movementDetails.receiveDetails.purchaseOrderId;
        this.purchaseOrderNumber = component.eventDetails.movementDetails.receiveDetails.purchaseOrderNumber;

        this.invoiceReference = component.eventDetails.movementDetails.receiveDetails.invoiceReference;

        this.currencySymbol = component.eventDetails.costDetails.currencySymbol;
        this.currencyDecimalPlaces = component.eventDetails.costDetails.currencyDecimalPlaces;

        if (this.selectedComponentCondition.detailsStepRequires.unitCost) {
            this.unitCost = component.eventDetails.costDetails.amount;
        }

        this.comment = component.eventDetails.comment;

        this.purchaseType = _.find(this.purchaseTypes, purchaseType => purchaseType.id === component.eventDetails.costDetails.costTypeId);

        if (component.componentOwnerId) {
            this.componentOwner = _.find(this.componentOwners, o => o.id === component.componentOwnerId);
        }

        this.selectedSpec = _.find(this.specifications, specification => specification.id === component.specificationId);

        if (component.eventDetails && component.eventDetails.movementDetails) {
            this.selectedLocation = _.find(this.locations, location => location.id === component.eventDetails.movementDetails.locationId);
        }

        let getReadings = (evtD, name) => (evtD && evtD.readingDetails && evtD.readingDetails.readings) ?
            evtD.readingDetails.readings.find(r => r.readingEventTypeName == name) : null;

        let getReadingSingle = (evtD, name) => {
            let readings = getReadings(evtD, name);
            return readings ? readings.values.find(v => v.sequence === 1).value : null;
        }

        let getReadingDouble = (evtD, name) => {
            let readings = getReadings(evtD, name);
            return readings ? { one: readings.values.find(v => v.sequence == 1).value, two: readings.values.find(v => v.sequence == 2).value } : null;
        }

        this.serials = components.map((c, i) => ({
            itemNo: i + 1,
            id: c.equipmentId,
            equipmentId: c.equipmentId,
            serialNumber: {
                manufacturer: c.manufacturerSerialNumber,
                site: c.siteSerialNumber
            },
            specification: this.specifications.find(specification => specification.id === c.specificationId) || { // in case the specification wasn't on site
                id: c.specificationId,
                name: c.specificationName,
                description: c.specificationName
            },
            attachments: c.eventDetails.attachments,
            equipmentType: this.componentTypes.find(componentType => c.equipmentTypeId === componentType.id),
            cost: c.eventDetails && c.eventDetails.costDetails ? c.eventDetails.costDetails.amount : null,
            componentOwner: c.componentOwnerId,
            hoursSinceNDT: c.hoursSinceNDT,
            repairer: (c.eventDetails && c.eventDetails.movementDetails && c.eventDetails.movementDetails.repairDetails ? c.eventDetails.movementDetails.repairDetails.repairerId : null),
            retreader: (c.eventDetails && c.eventDetails.movementDetails && c.eventDetails.movementDetails.retreadDetails ? c.eventDetails.movementDetails.retreadDetails.retreaderId : null),
            ndtStatus: (c.ndtResult != null ? { key: (c.ndtResult ? 1 : 2) } : null),
            status: this.statusTypes.find(status => status.id === c.eventDetails.movementDetails.newStatusTypeId),
            rtd: getReadingDouble(c.eventDetails, 'treaddepth'),
            rcd: getReadingDouble(c.eventDetails, 'chaindepth'),
            hours: getReadingSingle(c.eventDetails, 'hours'),
            distance: getReadingSingle(c.eventDetails, 'distance'),
            location: this.selectedLocation,
            selected: true,
            valid: true
        }));
    }
}

class ComponentReceiveComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = ComponentReceiveCtrl;
    public controllerAs = "vm";
}

angular.module('app.component').component('componentReceive', new ComponentReceiveComponent());