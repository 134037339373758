//import angular from 'angular';
import { saveAs } from 'file-saver';

angular.module('app').factory('exportSvc', ['amtCommandQuerySvc',
    function (amtCommandQuerySvc) {
        var service = {
            exportData: exportData,
            exportDataGet: exportDataGet
        };

        function getFileNameFromHeader(header) {
            if (!header) {
                return null;
            }

            var result = header.split(";")[1].trim().split("=")[1];

            return result.replace(/"/g, '');
        }

        function exportData(url, criteria, fileName) {

            criteria.exportFilename = fileName;

            var config = {
                data: "",
                responseType: "arraybuffer"
            };
            
            amtCommandQuerySvc.postWithConfig(url, criteria, config).then(function (response) {
                if (response.data) {
                    var fileName = getFileNameFromHeader(response.headers("content-disposition"));
                    var type = response.headers("content-type");

                    var text = new Blob([response.data], {
                        type: type
                    });

                    saveAs(text, fileName);
                }
            }, amtCommandQuerySvc.handleError);
        }

        function exportDataGet(url) {

            amtCommandQuerySvc.getWithConfig(url, { responseType: "blob" }).then(function (response) {
                if (response.data) {
                    var fileName = getFileNameFromHeader(response.headers("content-disposition"));
                    var type = response.headers("content-type");

                    saveAs(response.data, fileName);
                }
            }, amtCommandQuerySvc.handleError);
        }

        return service;
    }
]);