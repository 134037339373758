<div class="amt-view-header bg-light lter b-b ng-scope ng-isolate-scope">
    <div class="site-dashboard-menu-flex-box">
        <!-- Header-->
        <h2 class="dialog-title">{{ui.title}}</h2>
        <!--Close Button-->
        <button class="dialog-close-btn" (click)="dismissSiteHealthRateElement()"></button>
    </div>

</div>

<div class="widget-table-box">
    <div id="resp-table">
        <div id="resp-table-body">

            <!--Title-->
            <div class="resp-table-row">
                <div class="table-body-title-cell"><b>{{ui.module}}</b></div>
                <div class="table-body-title-cell" align="right"><b>{{ui.error}}</b></div>
            </div>

            <!--Tyres in Inspection-->
            <div class="resp-table-row">
                <div class="table-body-cell">{{ui.tyreInInspection}}</div>
                <div class="table-body-cell" align="right"><div [ngStyle]="{'color': (healthRateElement.inspectTyre > 0 ? 'red' : 'black') }">{{healthRateElement.inspectTyre}}</div></div>
            </div>

            <!--Maintenance Sessions Errors-->
            <div class="resp-table-row">
                <div class="table-body-cell">{{ui.maintenanceSessionsErrors}}</div>
                <div class="table-body-cell" align="right"><div [ngStyle]="{'color': (healthRateElement.msError > 0 ? 'red' : 'black') }">{{healthRateElement.msError}}</div></div>
            </div>

            <!--Pending Maintenance Session-->
            <div class="resp-table-row">
                <div class="table-body-cell">{{ui.pendingMaintenanceSession}}</div>
                <div class="table-body-cell" align="right"><div [ngStyle]="{'color': (healthRateElement.msPending > 0 ? 'red' : 'black') }">{{healthRateElement.msPending}}</div></div>
            </div>

            <!--Field Survey Errors-->
            <div class="resp-table-row">
                <div class="table-body-cell">{{ui.fieldSurveyErrors}}</div>
                <div class="table-body-cell" align="right"><div [ngStyle]="{'color': (healthRateElement.fsError > 0 ? 'red' : 'black') }">{{healthRateElement.fsError}}</div></div>
            </div>

            <!--Component Receive Errors-->
            <div class="resp-table-row">
                <div class="table-body-cell">{{ui.componentReceiveError}}</div>
                <div class="table-body-cell" align="right"><div [ngStyle]="{'color': (healthRateElement.crPending > 0 ? 'red' : 'black') }">{{healthRateElement.crPending}}</div></div>
            </div>

            <!--Stocktake Errors-->
            <div class="resp-table-row">
                <div class="table-body-cell">{{ui.stoketakeError}}</div>
                <div class="table-body-cell" align="right"><div [ngStyle]="{'color': (healthRateElement.stError > 0 ? 'red' : 'black') }">{{healthRateElement.stError}}</div></div>
            </div>

            <!--Visual Inspections Not Actioned  Will Impemented with Condition Monitoring-->
            <!-- <div class="resp-table-row">
            <div class="table-body-cell">{ui.visualInspectionsNotActioned}</div>
            <div class="table-body-cell" align="right"><div [ngStyle]="{'color': (healthRateElement.viNotAct > 0 ? 'red' : 'black') }">{{healthRateElement.viNotAct}}</div></div>
            </div>-->

            <!--Currently Due New Tyre Fitments-->
            <div class="resp-table-row">
                <div class="table-body-cell">{{ui.currentlyDueNewTyreFitments}}</div>
                <div class="table-body-cell" align="right"><div [ngStyle]="{'color': (healthRateElement.dueNewFrontTyre > 0 ? 'red' : 'black') }"> {{healthRateElement.dueNewFrontTyre}}</div></div>
            </div>

            <!--Currently Due Rim NDT-->
            <div class="resp-table-row">
                <div class="table-body-cell">{{ui.currentlyDueRimNdt}}</div>
                <div class="table-body-cell" align="right"><div [ngStyle]="{'color': (healthRateElement.dueRimNDT > 0 ? 'red' : 'black') }"> {{healthRateElement.dueRimNDT}}</div></div>
            </div>

            <!--Currently Due Rim Scrap-->
            <div class="resp-table-row">
                <div class="table-body-cell">{{ui.currentlyDueRimScrap}}</div>
                <div class="table-body-cell" align="right"><div [ngStyle]="{'color': (healthRateElement.dueScrapRim > 0 ? 'red' : 'black') }">{{healthRateElement.dueScrapRim}}</div></div>
            </div>

        </div>
    </div>
</div>

