import * as _ from 'underscore';
import DbClass, { IDbStatusChange } from './local-db';
import FileManagement from '../../services/fileManagement';
import { TStatusChange } from '../components/statusChange/statusChangeCtrl';
import sha256 from 'crypto-js/sha256';
import encBase64 from 'crypto-js/enc-base64';
import BrowserSvc from '../../services/browserSvc';
import OcDateSvc from '../../services/ocDateSvc';
import OcConfigSvc from '../../services/ocConfigSvc';

// dataBroker
//
// The mobile components need to either save their data to the server directly through xhr
// or save it to a lobal database to be synced later. This applies to both the reference data
// and the user generated data. This service will send and retreive the data from the appropriate
// place depending on which mode the application is running in.
//
// For each endpoint at least three methods should be created
//
//  1. a method in the 'local' object that uses the local db
//  2. a method in the 'remote' object that uses amtCommandQuerySvc for its data
//  3. a method in the returned 'ms' object that calls isMobile and then the appropriate local or remote method
//
// The returned data structure MUST be identical for local and remote methods of the same name. This might
// require some transformations to be done on the raw data that is returned from the db or the webservice

//import angular from 'angular';
import FieldSurveyUrls from '../equipment/vehicle/fieldSurvey/fieldSurveyUrls';
import { AuthService } from '../../services/auth.service';
import { VisualInspectionReferenceService } from '../../services/visual-inspection-reference.service';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import { getUIRouter } from '@uirouter/angular-hybrid';



angular.module('app').service('dataBroker',
    ['appConfig', 'amtCommandQuerySvc', '$db', 'amtXlatSvc', '$http', '$q', '$location', '$rootScope', '$state', 'browserSvc',
        'amtConstants', '$filter', 'fieldSurveyUrls', 'enums', 'equipmentUrls', 'vehicleUrls', 'componentUrls', 'referenceDataUrls',
        'amtGridConfig', '$timeout', 'convert', 'ocDateSvc', 'errorReporter', 'WindowFactory', 'helperSvc', 'fileManagement',
        'ocConfigSvc', 'authService', 'visualInspectionReferenceService',
        function (appConfig, amtCommandQuerySvc: IAmtCommandQuerySvc, $db: DbClass, amtXlatSvc: IAmtXlatSvc, $http: ng.IHttpService, $q: ng.IQService,
            $location: ng.ILocationService, $rootScope: IRootScope, $state: ng.ui.IStateService, browserSvc: BrowserSvc,
            amtConstants, $filter: ng.IFilterService, fieldSurveyUrls: FieldSurveyUrls, enums, equipmentUrls, vehicleUrls, componentUrls, referenceDataUrls,
            amtGridConfig, $timeout: ng.ITimeoutService, convert, ocDateSvc: OcDateSvc, errorReporter: IErrorReporter, WindowFactory: IWindowFactory,
            helperSvc: IHelperSvc, fileManagement: FileManagement, ocConfigSvc: OcConfigSvc, authService: AuthService, visualInspectionReferenceService: VisualInspectionReferenceService) {

            var vm = this;

            //garbage for throttling setCurrentLanguageFrom()
            vm.languageDownloadTimeMs = {}; // languageDownloadTimeMs : { [key: string]: number } = {}

            vm.apiUrls = amtConstants.url;
            //TODO: these seem like a nice idea, but initialising these before we have loaded the language resources doesn't work
            //vm.loginFail = amtXlatSvc.xlat('login.InvalidLoginAttempt');
            //vm.loginFailExternal = amtXlatSvc.xlat('login.InvalidExternalLogin');
            //vm.lastUserOnly = amtXlatSvc.xlat('login.LastUserOnly');
            //vm.noOfflineLogin = amtXlatSvc.xlat('login.NoOfflineLogin');
            //vm.netWorkError = amtXlatSvc.xlat('login.NetworkError');

            // there is no local DB in the desktop world
            // this is conditionally injected because the code need not be included in Desktop at all
            var $db: DbClass;
            vm.isMobile = browserSvc.isMobile;

            if (vm.isMobile) {
                amtGridConfig.disableLocalStorage();
            }

            function addNewReadings(vehicle, positions) {
                vehicle.positionReadings = [];
                for (let attr in positions) {
                    if (positions.hasOwnProperty(attr)) {
                        let pos = positions[attr];

                        let reading = {
                            positionId: pos.id,
                            vehicleId: vehicle.id,
                            sequence: pos.sequence, // on the axle
                            positionNo: pos.wheelPositionNumber,
                            positionLabel: pos.label,
                            pressure: '',
                            temp: '',
                            adjPressure: '',
                            rtd1: '',
                            rtd2: '',
                            tyreComponentTypeId: vehicle.tyreComponentTypeId
                        };

                        if (pos.fitments) {
                            for (var i = 0; i < pos.fitments.length; i++) {
                                reading[pos.fitments[i].type + 'Fitment'] = pos.fitments[i];
                                reading[pos.fitments[i].type.toLowerCase() + 'ComponentId'] =
                                    pos.fitments[i].componentId;
                            }
                        }

                        vehicle.positionReadings.push(reading);
                    }
                }
            }

            function filterPendingComponents(components, excludeReceived) {

                return $db.receive.toArray().then(function (receivals) {

                    var receivedComponents = [];

                    var data = $filter('filter')(receivals, receival => receival.components !== undefined);

                    if (data !== null && data.length > 0) {
                        for (var d = 0; d < data.length; d++) {
                            for (var v = 0; v < data[d].components.length; v++) {
                                receivedComponents.push(data[d].components[v].equipmentId);
                            }
                        }

                        for (var i = 0; i < components.length; i++) {
                            if (receivedComponents.indexOf(components[i].id) > -1) {
                                if (excludeReceived) {
                                    components.splice(i, 1);
                                    i--;
                                } else {
                                    components[i].received = true;
                                }
                            }
                        }
                    }

                    return components;
                });
            };

            async function filterPendingVehicles(vehicles, excludeReceived) {
                let receivals = await $db.receive.toArray()
                let data = $filter('filter')(receivals, receival => receival.vehicles !== undefined);

                if (data !== null && data.length > 0) {
                    let receivedVehicles = [];

                    for (var d = 0; d < data.length; d++) {
                        for (var v = 0; v < data[d].vehicles.length; v++) {
                            receivedVehicles.push(data[d].vehicles[v].equipmentId);
                        }
                    }

                    for (var i = 0; i < vehicles.length; i++) {
                        if (receivedVehicles.indexOf(vehicles[i].id) > -1) {
                            if (excludeReceived) {
                                vehicles.splice(i, 1);
                                i--;
                            } else {
                                vehicles[i].received = true;
                            }
                        }
                    }
                }

                return vehicles;
            };

            function schematicToPositionsArray(schematic) {
                if (!schematic.axles) {
                    throw new Error("No Axles on Specification:" + schematic.id);
                }
                var p1 = schematic.axles.map(a => a.positions.map(function (b) {
                    if (b.fitments) {
                        for (var i = 0; i < b.fitments.length; i++) {
                            b[b.fitments[i].type + 'Fitment'] = b.fitments[i];
                            b[b.fitments[i].type.toLowerCase() + 'ComponentId'] = b.fitments[i].componentId;
                        }
                    }
                    return b;
                }));
                var p2 = [].concat.apply([], p1); // flatten;

                // ensure that they have fitments array;
                p2 = p2.map(function (a) {
                    a.fitments = a.fitments || [];
                    return a;
                });
                return p2;
            }

            function schematicToPositions(schematic) {
                return _.indexBy(schematicToPositionsArray(schematic), 'id');
            }

            // #region local
            // the get data methods in this object should have the same names as their corresponding ones
            // in the 'remote' object. The load data method will then call one or the other, depending if
            // the app is considered to be running in 'offline' mode or not
            //========================================================================================= 
            var local = {
                // gets the component readings
                lookupComponent: function (fitment, readingAtPosition) {

                    var ft = fitment;
                    var ftType = ft.type.toLowerCase();

                    if ($db[ftType]) {
                        return $db[ftType].get(ft.componentId).then(async function (component) {
                            if (!component) {
                                console.error('FitmentError:  component does not exist : ' + ftType + ':' + ft.componentId);
                                return null;
                            }
                            angular.extend(ft, component);
                            // maybe the schematic should use the full serial number

                            ft.serialNumber = component.serialNumber;
                            ft.description = component.description;

                            if (ft.type === 'Tyre') {
                                readingAtPosition.tyreComponentTypeId = ft.typeId;
                                if (ft.activeVisualInspections?.length && !readingAtPosition.visualInspections?.length) {

                                    combineLatest(
                                        visualInspectionReferenceService.getCausesByEquipmentTypeId$(ft.typeId),
                                        visualInspectionReferenceService.actions$
                                    ).pipe(first())
                                        .subscribe(async res => {
                                        let [causes, actions] = res;

                                        let fileMetadatas;

                                        if (ft.activeVisualInspections.some(a => a.images?.length))
                                            fileMetadatas = await $db.fileMetadata.toArray();                                            

                                        // add them to the readings .                                    
                                        readingAtPosition.visualInspections = ft.activeVisualInspections.map(a => ({
                                            inspectionDateTime: a.dateTaken,
                                            id: a.id, // existing entry
                                            causeId: a.causeId,
                                            cause: causes.find(c => a.causeId === c.key)?.description,
                                            actionId: a.actionId,
                                            action: actions.find(c => a.actionId === c.key)?.description,
                                            inspectionFrequencyDays: a.inspectionFrequencyDays,
                                            active: true, // these are all active
                                            comment: a.otherText,
                                            attachments: a.images.map(img => {

                                                let imgData = fileMetadatas.find(m => m.id === img.imageId);

                                                return {
                                                    name: imgData.name,
                                                    id: imgData.id,
                                                    description: imgData.description,
                                                    createdDate: imgData.createdDate,
                                                    fileSize: imgData.fileSize,
                                                    persisted: imgData.persisted,
                                                    uploaded: imgData.uploaded
                                                };
                                            })
                                        }));
                                    });
                                }
                                ft.remainingDepth = {};
                                ft.primaryOTD = {}; // original.
                                //console.log("get tyre readings");
                                return $db.reading
                                    .where('equipmentId')
                                    .equals(ft.componentId)
                                    .filter(reading => reading.type === 'TREADDEPTH')
                                    .sortBy('date').then(function (readings) {
                                        if (readings && readings.length > 0) {
                                            ft.remainingDepth = readings[0];
                                        }

                                        return component;
                                    });
                            }

                            if (ft.type === 'Chain') {
                                ft.remainingDepth = {};
                                //console.log("get chain readings");
                                return $db.reading
                                    .where('equipmentId')
                                    .equals(ft.componentId)
                                    .filter(reading => reading.type === 'CHAINDEPTH')
                                    .sortBy('date').then(function (readings) {
                                        if (readings && readings.length > 0) {
                                            ft.remainingDepth = readings[0];
                                        }
                                        //console.log('<< ' + ftType + ':' + ft.componentId);
                                        return component;
                                    });
                            }

                            return component;
                        }).catch(function (error) {
                            console.error('FitmentError:' + errorReporter.exceptionMessage(error));
                        });
                    } else {
                        console.error("Unknown fitment type : " + ftType);
                        return null;
                    }
                },

                _getStocktakeList: function (type) {
                    if ($db[type]) {
                        return $db[type]
                            .where('status.name')
                            .anyOf(['NEW', 'SPARE'])
                            .filter(c => c.obsolete !== true) // want this to default as included
                            .toArray(data => data);
                    } else {
                        return $q.when();
                    }
                },
                setStocktakeType: function (type) {
                    return $db.system.get(1).then(function (system) {
                        system.stocktakeType = type;
                        return $db.system.put(system).then(function () {
                        }).catch(function (error) {
                            errorReporter.logError(error, 'Stocktake-SetStocktakeType');
                        });
                    });
                },
                getStocktakeComponentTypes: function () {
                    return $db.equipmentTypes.filter(et => et.name !== 'Vehicle').sortBy('description');
                },
                checkRefData: function () {
                    return $db.system.get(1).then(function (system) {
                        if (!system) {
                            console.error("No System entry in local DB.");
                            $q.reject();
                        } else {
                            if (system.site !== ocConfigSvc.user.site.id) {
                                console.error("Local DB Site has changed.");
                                console.error("system.site:" + system.site);
                                console.error("Current user site:" + ocConfigSvc.user.site);
                                // site has changed
                                $q.reject();
                            }
                        }
                        if (isNaN(system.lastReferenceDataSyncDate)) {
                            console.error("Problem with Sync Date.");
                            console.error(system);
                            return $q.reject();
                        }
                        return true;
                    });
                },
                getSerials: function (type) {
                    if ($db[type.toLowerCase()]) {
                        return $db[type.toLowerCase()]
                            .filter(c => c.obsolete !== true) // want this to default as included
                            .toArray(function (data) {
                                return data;
                            });
                    } else {
                        return $q.when();
                    }
                },
                /**
                 * 
                 * @param {} ax 
                 * @param {} type 
                 * @param {string} statusName - Status name to add to the valid list. Use "ALL" to prevent status filtering.
                 * @returns {} 
                 */
                loadPositionFitments: function (ax, type, statusName) {

                    let types = (!type || type === "assembly") ? ["Rim", "Tyre"] : [type];

                    // get the type id
                    let validStates = ['NEW', 'SPARE'];

                    if (statusName) {
                        validStates = [statusName.toUpperCase()];
                    }

                    let promises = [];

                    types.forEach(function (type) {

                        promises.push($db[type.toLowerCase()].limit(1).first().then(function (equipmentType) {

                            var validSizeSet = [];

                            if (!equipmentType) {
                                console.error("No components on site of the specified type.");
                            } else if (!ax.validFitments) {
                                console.error("No valid sizes were set against the axle - assuming anything valid");
                            } else {
                                validSizeSet = ax.validFitments.filter(s => s.componentTypeId = equipmentType.id)
                                    .map(a => a.equipmentSizeId);
                            }

                            var data = $db[type.toLowerCase()];
                            var sizedData = data;

                            // filter for only those of the correct size if required.
                            if (validSizeSet.length > 0) {
                                validSizeSet.push(amtConstants.emptyGuid); // if a component has no size id, I guess there is nothing to say it wont fit.
                                sizedData = data.where('sizeId').anyOf(validSizeSet);
                            }

                            return sizedData.filter(c => c.obsolete !== true && // want this to default as included
                                (statusName === "ALL" || validStates.includes(c.status.adjustedState ? c.status.adjustedState : c.status.name)))
                                .toArray(data => _.sortBy(data.map(a => {
                                    a.serialNumber = { formatted: a.serialNumber };
                                    a.equipmentId = a.id;
                                    return a;
                                }), fitment => fitment.serialNumber.formatted));
                        }));
                    });

                    return promises;
                },
                getValidFitments: function (type, positionId, statusName, schematic, date) {
                    // from the schematic find the axle with the position on it
                    for (var i = 0; i < schematic.axles.length; i++) {
                        var ax = schematic.axles[i];
                        for (var j = 0; j < ax.positions.length; j++) {
                            var axlePos = ax.positions[j];
                            if (axlePos.id === positionId) {
                                // we found the pos                                    
                                var components = [];
                                return $q.all(local.loadPositionFitments(ax, type, statusName)).then(function (collection) {
                                    if (collection && collection.length > 0) {
                                        collection.forEach(function (items) {
                                            if (items && items.length > 0) {
                                                items.forEach(c => components.push(c));
                                            }
                                        });
                                    }
                                }).then(function () { return components });
                            }
                        }
                    }
                    console.error("the position to be fitted to is not on the supplied schematic");
                    // you will find no valid fitments here buddy!
                    return $q.resolve([]);
                },
                checkValidFitmentForPosition: function (wheelPositionId, equipmentId) {
                    return $q.resolve();
                },

                checkVehicleLockedOut: function (vehicleId, module) {
                    if (module === 'maintenanceSession') {
                        return $db.fieldSurvey.where('id').equals(vehicleId).count().then(function (count) {
                            return (count > 0);
                        });
                    } else if (module === 'fieldSurvey') {
                        return $db.maintenanceSession.where('id').equals(vehicleId).count().then(function (count) {
                            return (count > 0);
                        });
                    }
                },

                msUndoFit: function (fitData, session) {
                    //console.log("undo fit");
                    var pos = null;
                    var type = null;
                    var event = fitData.eventData || fitData;

                    if (event.selectedPosition) {
                        pos = event.selectedPosition.id;
                        type = event.type;
                    } else if (event.fitment) {
                        pos = event.fitment.id;
                        type = event.fitment.type;
                    } else if (event.position) {
                        pos = event.position.id;
                        type = event.type;
                    }

                    if (!pos) {
                        console.error("Can't find position for undo");
                        return $q.resolve();
                    }

                    var promise = Promise.resolve() as any; //TODO: evil cast, Typescript doesn't like how our code uses this promise

                    var fitPositionId = (event.selectedPosition ? event.selectedPosition.id : (event.position.typeId || event.position.id));

                    var componentIds = [];
                    var components = [];

                    if (event.components) {
                        componentIds = event.components.map(c => c.componentId || c.equipmentId);
                        components = event.components;
                    } else {
                        componentIds = [event.fitment.componentId || event.fitment.equipmentId];
                        components = [event.fitment];
                    }

                    var axles = session.vehicle.schematic.axles;

                    for (var k = 0; k < axles.length; k++) {
                        var positions = axles[k].positions;
                        for (var j = 0; j < positions.length; j++) {

                            var position = positions[j];

                            if (position.id === fitPositionId) {

                                // remove the component from the schematic
                                for (var i = 0; i < position.fitments.length; i++) {

                                    var ft = position.fitments[i];

                                    if (componentIds.indexOf(ft.componentId || ft.equipmentId) > -1) {

                                        position.fitments.splice(i, 1);
                                        position[ft.type + 'Fitment'] = undefined;

                                        i--;
                                    }
                                }
                            }
                        }
                    }

                    for (var i = 0; i < components.length; i++) {
                        type = components[i].type;

                        // get the fitment and mark it as deleted 
                        promise = promise.then(local.msUpdateFitmentDelete(pos, type, true, session));

                        var id = componentIds[i];

                        promise = promise.then(
                            $db[type.toLowerCase()].get(componentIds[i]).then(function (dbComponent) {
                                if (dbComponent) {
                                    dbComponent.status.adjustedState = dbComponent.status.previousState;
                                    $db[type.toLowerCase()].put(dbComponent);
                                } else {
                                    // try a rim ?
                                    console.error("Component to update was not found: [" + componentIds[i] + "]");
                                }
                            }));
                    }

                    return promise;
                },
                msUpdateFitmentDelete: function (position, type, deleted, session) {
                    return $db.fitment.where('positionId').equals(position).filter(a =>
                            a.type.toLowerCase() === type.toLowerCase() && a.vehicleId === session.vehicle.id
                        ).first().then(
                        function (ft) {
                            if (ft) {
                                ft.deleted = deleted;
                                if (session) {
                                    ft.maintenanceSession = session.id;
                                } else {
                                    delete ft.maintenanceSession;
                                }
                                $db.fitment.put(ft);
                            } else {
                                // the position is probably wrong.
                                console.error("Didn't find fitment to update");
                            }
                        });
                },
                msSwapComponentHistory: function (equipmentEventId, equipmentId) {
                    return $q.resolve();
                },
                msSwapPositionFitments: function (firstEquipmentEventId, secondEquipmentEventId) {
                    return $q.resolve();
                },
                msStripAssembly: function (assemblyId, eventType) {
                    return $q.resolve();
                },
                msUndoRemove: function (removeData, session) {

                    var promise = $q.resolve();
                    var event = removeData;

                    var removalPositionId = removeData.position.id || removeData.position.typeId;

                    for (var i = 0; i < event.components.length; i++) {

                        var matched = false;

                        var component = removeData.components[i];

                        // locate the position on the schematic 
                        var axles = session.vehicle.schematic.axles;

                        for (var k = 0; k < axles.length; k++) {

                            var positions = axles[k].positions;

                            for (var j = 0; j < positions.length; j++) {

                                var position = positions[j];

                                if (position.id === removalPositionId) {

                                    if (!removeData.components[i].componentId) {
                                        removeData.components[i].componentId = removeData.components[i].equipmentId || removeData.components[i].id;
                                    }

                                    // create a new fitment and add it into the vehicle schematic
                                    var newFitment = {
                                        componentId: component.equipmentId || component.componentId,
                                        equipmentId: component.equipmentId || component.componentId,
                                        eventId: component.eventId,
                                        id: component.id,
                                        type: component.type.charAt(0).toUpperCase() + component.type.slice(1),
                                        vehicleId: session.vehicle.id,
                                        typeId: component.equipmentTypeId || component.typeId,
                                        serialNumber: component.serialNumber.formatted || component.serialNumber
                                    };

                                    position.fitments.push(newFitment);
                                    position[newFitment.type + "Fitment"] = newFitment;

                                    var readingsAtPos = _.indexBy(session.vehicle.positionReadings, 'positionId');

                                    local.lookupComponent(position.fitments[position.fitments.length - 1], readingsAtPos[position.id]).then(function () {
                                        session.vehicle.updated = uuid();
                                    });

                                    matched = true;
                                }
                            }
                        }

                        if (!matched) {
                            // data must be corrupt - should never happen
                            return $q.reject("Failed to match");
                        }

                        promise = local.msUpdateFitmentDelete(removalPositionId, component.type, false, session);

                        promise = promise.then(
                            $db[component.type.toLowerCase()].get(component.componentId).then(function (dbComponent) {
                                if (dbComponent) {
                                    dbComponent.status.adjustedState = dbComponent.status.previousState;
                                    $db[component.type.toLowerCase()].put(dbComponent);
                                } else {
                                    // try a rim ?
                                    console.error("Component to update was not found: [" + component.componentId + "]");
                                }
                            }));
                    }

                    // just returning the last promise.
                    return promise;
                },
                msUndoCheckin: function (equipmentEventId) {
                    return $q.resolve();
                },
                msUpdateCheckIn: function (checkinData) {
                    console.error("Should not be able to call update checkin from mobile, it is always saving");
                },
                msUndoCheckOut: function (eventId) {
                    return $q.resolve();
                },
                msUpdateCheckOut: function (checkoutData) {
                    console.error("Should not be able to call update checkout from mobile, it is always saving");
                },
                msCheckinEvent: function (checkinData) {
                    return $q.resolve();
                },
                msCheckoutEvent: function (checkoutData) {
                    return $q.resolve();
                },
                updateFitmentData: function (session, positionIds, equipmentTypesToClear) {

                    if (equipmentTypesToClear) {

                        var vehicle = session.vehicle;

                        for (var key in vehicle.positions) {

                            if (vehicle.positions.hasOwnProperty(key) && positionIds.indexOf(key) > -1) {

                                var pos = vehicle.positions[key];

                                pos.fittedInThisSession = false;

                                if (equipmentTypesToClear.map(et => et.toLowerCase()).includes('tyre')) {

                                    let vehPosRead = vehicle.positionReadings.find(positionReading => positionReading.positionId === key);

                                    if (vehPosRead) {
                                        vehPosRead.rtd1 = null;
                                        vehPosRead.rtd2 = null;
                                        vehPosRead.rcd1 = null;
                                        vehPosRead.rcd2 = null;
                                        vehPosRead.nitrogen = null;
                                        vehPosRead.pressure = null;
                                        vehPosRead.adjPressure = null;
                                        vehPosRead.temp = null;
                                        vehPosRead.visualInspections = null
                                    }

                                }

                                equipmentTypesToClear.forEach(et => {
                                    var type = et.toLowerCase();

                                    var fitmentIndex = _.findIndex(pos.fitments, f => { return f.type.toLowerCase() === type; });

                                    if (fitmentIndex > -1) {
                                        pos.fitments.splice(fitmentIndex, 1);
                                    }

                                    if (type === 'tyre') {
                                        pos.TyreFitment = undefined;
                                    } else if (type === 'rim') {
                                        pos.RimFitment = undefined;
                                    } else if (type === 'chain') {
                                        pos.ChainFitment = undefined;
                                    }
                                });
                            }
                        }
                    }

                    return $q.resolve();
                },
                updateComponent: function (component, sessionId, newState) {
                    var id = component.componentId || component.equipmentId || component.id;
                    var type = component.type;
                    if (type === 'assembly') {
                        type = 'Tyre';
                        // I wonder, when does the rim get updated ?
                    }
                    $db[type.toLowerCase()].get(id).then(function (dbComponent) {
                        if (dbComponent) {
                            dbComponent.status.previousState = dbComponent.status.adjustedState || dbComponent.status.name;
                            dbComponent.status.adjustedState = newState;
                            dbComponent.status.maintenanceSession = sessionId;
                            $db[component.type.toLowerCase()].put(dbComponent);
                        } else {
                            // try a rim ?
                            console.error("Component to update was not found: [" + id + "]");
                        }
                    });
                },
                msRemoveEvent: function (removeData, session) {
                    // delete the fitment from the ref data - so it is not in the maintenance session 
                    var promise = $q.resolve();
                    for (var e = 0; e < removeData.eventData.length; e++) {
                        var event = removeData.eventData[e];
                        for (var i = 0; i < event.components.length; i++) {
                            var component = event.components[i];
                            promise = local.msUpdateFitmentDelete(component.positionId || component.id, component.type, true, session);
                            local.updateComponent(event.components[i],
                                session.sessionId,
                                event.selectedMoveTo.name);
                        }
                    }
                    return promise;
                },
                msFitEvent: async function (fitData, session) {
                    let ftSet = fitData.eventData.components && fitData.eventData.components.length > 0 ?
                            /* a refit or assembly fitment */ fitData.eventData.components : [fitData.eventData.fitment];

                    for (let ft of ftSet) {
                        if (ftSet.length === 1) {
                            ft.type = fitData.eventData.type;
                        }

                        ft.maintenanceSession = session.sessionId;

                        await $db.fitment.put({
                            id: uuid(),
                            vehicleId: session.vehicle.id,
                            componentId: ft.componentId || ft.equipmentId || ft.id,
                            type: ft.type,
                            typeId: ft.typeId,
                            positionId: fitData.position ? fitData.position.id : fitData.eventData.position.id
                        });

                        local.updateComponent(ft, session.sessionId, "FITTED");
                    }
                },
                msProcessRemove: function (type, session) {
                    $db[type.toLowerCase()].filter(c => c.status.maintenanceSession === session.sessionId)
                        .toArray(function (entries) {
                            if (entries) {
                                for (var j = 0; j < entries.length; j++) {
                                    delete entries[j].status.adjustedState;
                                    delete entries[j].status.maintenanceSession;
                                    $db[type.toLowerCase()].put(entries[j]);
                                }
                            }
                        });
                },
                msDeleteSession: async function (session) {
                    try {
                        let fileIds = [];
                        for (let pr of session.vehicle.positionReadings || []) {
                            for (let vi of pr.visualInspections || []) {
                                for (let f of vi.files || []) {
                                    if (f.file) {
                                        fileIds.push(f.file);
                                    }
                                }
                            }
                        }
                        await fileManagement.deleteFiles(fileIds);
                    } catch (error) {
                        errorReporter.logError(error, "maintenanceSession-Delete"); //continue after failure to delete VI files...
                    }

                    await $db.maintenanceSession.delete(session.id);

                    if (session.sessionId) {
                        // fix any tyre, rim or chain states
                        var types = ['tyre', 'rim', 'chain'];
                        for (var i = 0; i < types.length; i++) {
                            local.msProcessRemove(types[i], session);
                        }
                    }
                },
                getFleetSpeed: function (vehicleId) {
                    return $db.vehicle.get(vehicleId).then(function (vehicle) {
                        // get the spec
                        if (!vehicle) {
                            return null;
                        }
                        return $db.specification.get(vehicle.specificationId).then(function (spec) {
                            return {
                                fleetSpeed: spec.application.fleetSpeed,
                                fleetSpeedUnit: spec.application.fleetSpeedUnit
                            };
                        });
                    });
                },
                // level is passed in as a default level, existing stocktakes remember state
                getStocktake: function (type, level) {
                    if (type) {
                        return local._getStocktakedata(type, level);
                    } else {
                        return $db.system.get(1).then(function (system) {
                            if (system.stocktakeType) {
                                type = system.stocktakeType;
                            } else {
                                type = 'tyre';
                            }
                            return local._getStocktakedata(type, level);
                        });
                    }
                },
                createStocktake: function (date, type, level) {
                    throw new Error("Unimplemented");
                },
                // level is passed in as a default level, existing stocktakes remember state
                _getStocktakedata: function (componentType, level) {

                    return $q(function (resolve, reject) {
                        // do we have a local copy 
                        //console.log('get stocktake data');
                        if (!level) {
                            level = 3;  // DEFAULT STOCKTAKE LEVEL (PREFERRED LEVEL) - MOBILE
                        }

                        $db.stocktake.get(componentType).then(
                            function (st) {
                                if (st) {
                                    resolve(st);
                                } else {
                                    local._getStocktakeList(componentType).then(
                                        function (data) {
                                            let noLocationDescription = amtXlatSvc.xlat('stocktake.noLocation');

                                            // trim and add id's.
                                            let componentList = data.map(a => ({
                                                description: a.description,
                                                serialNumber: { site: a.siteSerialNumber, manufacturer: a.manufacturerSerialNumber },
                                                componentId: a.id,
                                                location: a.status.location ? a.status.location : noLocationDescription,
                                                status: {
                                                    siteStatusTypeLocationId: a.status.siteStatusTypeLocationId,
                                                    name: a.status.name,
                                                    description: amtXlatSvc.xlat('equipment.statusType_' + a.status.name, false)
                                                },
                                                equipmentTypeSpecificationTypeId: a.equipmentTypeSpecificationTypeId,
                                                id: a.status.location + ';' + a.status.name + ';' + a.description + ';' + a.serialNumber
                                            }));

                                            let stocktake = {
                                                components: componentList,
                                                items: {},
                                                level: level,
                                                expected: componentList.length,
                                                createdDate: new Date(),
                                                id: componentType
                                            };

                                            resolve(stocktake);
                                        }, reject);
                                }
                            }, reject);
                    });
                },
                saveStocktake: async function (data) {

                    if (data) {

                        if (!data.createdDate) {
                            data.createdDate = new Date();
                        }

                        data.pristine = 0;

                        let stocktake = await $db.stocktake.get(data.id);

                        // mobile audit logging
                        if (!stocktake) local.logAuditEntry('Stocktake', 'Create', data.id);

                        await $db.stocktake.put(data);
                    }
                },
                deleteStocktake: function (stocktake) {

                    // mobile audit logging
                    local.logAuditEntry('Stocktake', 'Delete', stocktake.id);

                    return $db.stocktake.delete(stocktake.id);
                },
                getThumbnail: function (id: guid) {

                },
                getVehicleDetails: function (vehicleId, surveyDate) {
                    //console.log("getVehicleDetails");
                    return $db.fieldSurvey.get(vehicleId).then(function (vehicle) {
                        if (vehicle) {
                            //var j = JSON.stringify(vehicle);
                            return vehicle;
                        } else {
                            return $db.vehicle.get(vehicleId).then(function (vehicle: any) { //TODO: any cast!
                                //console.log("Have the Vehicle");
                                // get the spec
                                if (!vehicle) {
                                    return null;
                                }
                                return $db.specification.get(vehicle.specificationId).then(function (spec) {
                                    if (!spec) {
                                        //console.log("Specification for vehicle '" +
                                        //    vehicle.description +
                                        //    ' (' +
                                        //    vehicle.id +
                                        //    ")' could not be found");
                                        return $q.reject("Specification for vehicle '" + vehicle.description + ' (' + vehicle.id + ")' could not be found");
                                    }
                                    //console.log('Set Spec:' + vehicle.id);
                                    vehicle.schematic = spec;

                                    // get all fitments for this vehicle
                                    return $db.fitment.where('vehicleId').equals(vehicleId).toArray().then(
                                        function (fitments) {
                                            //console.log('Mobile: Vehicle ' + vehicleId + ' : have fitments');

                                            // need to put the fitments in the right positions
                                            vehicle.positions = schematicToPositions(vehicle.schematic);

                                            // add a reading per position regardless of the fitments.
                                            addNewReadings(vehicle, vehicle.positions);

                                            var readingsAtPos = _.indexBy(vehicle.positionReadings, 'positionId');
                                            return $db.transaction('r', [
                                                $db.reading, $db.tyre, $db.rim, $db.chain, $db.fileMetadata,
                                                $db.visualInspectionActions, $db.visualInspectionCauses
                                            ],
                                                function () {
                                                    // for each fitment locate the components.
                                                    for (var i = 0; i < fitments.length; i++) {
                                                        var ft: any = fitments[i]; //TODO: any cast
                                                        var rap = readingsAtPos[ft.positionId];

                                                        ft.index = i;
                                                        var pos = vehicle.positions[ft.positionId];
                                                        if (!pos) {
                                                            console.error("This vehicle has a fitment which is not at a recognised position");
                                                            throw new Error(amtXlatSvc.xlat("equipment.fitmentPositionError"));
                                                        }

                                                        pos.fitments.push(ft); // put the fitment into the correct position
                                                        pos.assemblyId = ft.assemblyId;
                                                        pos[ft.type + 'Fitment'] = ft;

                                                        rap[ft.type.toLowerCase() + 'ComponentId'] = ft.componentId;
                                                        local.lookupComponent(ft, rap);
                                                    }

                                                    // do vehicle level readings
                                                    return $db.reading.where('equipmentId').equals(vehicleId)
                                                        .sortBy('date')
                                                        .then(function (readings) {
                                                            let require = { 'DISTANCE': true, 'HOURS': true }; // ok get the ones I want
                                                            vehicle.priorReadings = {};
                                                            while (Object.keys(require).length > 0 && readings.length > 0) {
                                                                if (require[readings[0].type]) {
                                                                    vehicle.priorReadings[readings[0].type] = readings[0];
                                                                    vehicle.priorReadings.date = readings[0].date;
                                                                    delete require[readings[0].type];
                                                                }
                                                                readings.shift();
                                                            }
                                                        }).catch(function (error) {
                                                            console.error("reading error");
                                                            return $q.reject(error);
                                                        });
                                                }).then(function () {
                                                    updateVehicleStats(vehicle);
                                                    //console.log('vehicle complete resolving :' + vehicle.id);
                                                    return vehicle;
                                                }).catch(function (error) {
                                                    console.error(" Error in vehicle details");
                                                    return $q.reject(error);
                                                });

                                            //console.log(" returning defer");
                                            //return defer.promise;
                                        });
                                });
                            });
                        }
                    });
                },
                loadVehicleListMaint: function () {
                    return $db.vehicle.toArray().then(function (vehicles: any) { //TODO: any cast
                        return $db.maintenanceSession.toArray().then(function (sessions) {
                            let indexedSessions = _.indexBy(sessions, "id");

                            vehicles = vehicles.map(function (a) {
                                // we map this here so we can get the last reading dates from the survey which is not yet uploaded
                                let ms = indexedSessions[a.id];
                                a.sessionDownloaded = !!ms;
                                a.maintenanceStatus = ms ? (ms.checkedOut ? "Completed" : "In Progress") : a.maintenanceStatus;
                                a.maintenanceStatusDescription = ms ?
                                    (ms.checkedOut ? amtXlatSvc.xlat("maintenanceSession.completed") : amtXlatSvc.xlat("maintenanceSession.inProgress"))
                                    : a.maintenanceStatusDescription;

                                a.displayDescription = String.format(a.siteSerialNumber ? "{0} ({1}) - {2}" : "{1} - {2}", a.siteSerialNumber, a.serialNumber, a.description);

                                return a;
                            }).sort((a, b) => buildSortKey([mapStatusToSortOrder(a.maintenanceStatus), a.serialNumber]).toLowerCase()
                                .localeCompare(buildSortKey([mapStatusToSortOrder(b.maintenanceStatus), b.serialNumber]).toLowerCase()));
                            return { result: vehicles };
                        });
                    });
                },

                // returns the local vehicle list
                loadVehicleList: async function (siteId) {

                    let tyresWithVisIn = await $db.tyre.filter(t => t.activeVisualInspections && t.activeVisualInspections.length).toArray();
                    let tyresWithVisInIds = tyresWithVisIn.map(t => t.id);

                    let fitmentsWithVisInspections = await $db.fitment.where('componentId').anyOf(tyresWithVisInIds).toArray();
                    let vehicleIdsWithVI = new Set(fitmentsWithVisInspections.map(f => f.vehicleId)); //ES6 but kind of supported in IE11

                    let fieldSurveys = _.indexBy(await $db.fieldSurvey.toArray(), 'id');
                    let maintSessions = _.indexBy(await $db.maintenanceSession.toArray(), 'id');

                    //TODO: any cast
                    let vehicles: any = await $db.vehicle.orderBy('serialNumber').toArray();
                    vehicles = vehicles.filter((v) => v.status.name === enums.statusTypes.production); // only want vehicles in production

                    let today = new Date();

                    vehicles = vehicles.map(a => { // we map this here so we can get the last reading dates from the survey which is not yet uploaded

                        //Check due dates for tread and pressure
                        a.pressureDue = a.nextPressureReading < today;
                        a.treadDue = a.nextTreadReading < today;

                        let fs = fieldSurveys[a.id];
                        let ms = maintSessions[a.id]
                        let v = fs || a;

                        v.activeSurvey = !!fs; // for check mark on field survey list on mobile
                        v.hasActiveVI = vehicleIdsWithVI.has(a.id); //whether the vehicle has tyres with active visual inspections

                        if (v.hasActiveVI) {

                            let fitments = new Set(fitmentsWithVisInspections.filter(f => f.vehicleId === a.id).map(f => f.componentId));
                            let visualInspections = _.flatten(tyresWithVisIn.filter(t => fitments.has(t.id)).map(t => t.activeVisualInspections));

                            v.lastVisualInspectionDate = _.max(visualInspections.map(vi => vi.dateTaken));
                        }

                        // check for any active visual inspection that has been added via field survey in this mobile session
                        if (fs && fs.positionReadings) {

                            let visualInspections = _.flatten(
                                fs.positionReadings
                                    .filter(pr => !!pr.visualInspections)
                                    .map(pr => pr.visualInspections
                                        .filter(vi => vi.active))
                            );

                            if (visualInspections.length > 0) {
                                v.hasActiveVI = true;
                                let mostRecent = _.max(visualInspections.map(vi => vi.inspectionDateTime));

                                if (!v.lastVisualInspectionDate || mostRecent > v.lastVisualInspectionDate) {
                                    v.lastVisualInspectionDate = mostRecent;
                                }
                            }
                        }

                        // check for any active visual inspection that has been added via maintenance session in this mobile session
                        if (ms && ms.positionReadings) {

                            let visualInspections = _.flatten(
                                ms.positionReadings
                                    .filter(pr => !!pr.visualInspections)
                                    .map(pr => pr.visualInspections
                                        .filter(vi => vi.active))
                            );

                            if (visualInspections.length > 0) {
                                v.hasActiveVI = true;
                                let mostRecent = _.max(visualInspections.map(vi => vi.inspectionDateTime));

                                if (!v.lastVisualInspectionDate || mostRecent > v.lastVisualInspectionDate) {
                                    v.lastVisualInspectionDate = mostRecent;
                                }
                            }
                        }

                        v.displayDescription = String.format(v.siteSerialNumber == null ? "{0} - {1}" : "{2} ({0}) - {1}", v.serialNumber, v.description, v.siteSerialNumber)

                        return v;
                    });

                    return { result: _.sortBy(vehicles, v => !v.activeSurvey) }; //already sorted on manufacturer serial number, put active surveys first
                },

                checkUniqueSerialNumber: async function (manSerialNumber: string, siteSerialNumber: string, type: string, equipmentId: guid | number) {

                    manSerialNumber = toUpper(manSerialNumber);
                    siteSerialNumber = toUpper(siteSerialNumber);
                    type = type.toLowerCase();

                    // manufacturer can only be validated at site for now, not across the client
                    let existingSiteSerial = false;
                    let existingManufacturerSerial = false;

                    let man_tyre = [];
                    let man_chain = [];
                    let man_rim = [];
                    let man_vehicle = [];
                    let man_receive = [];

                    let site_tyre = [];
                    let site_chain = [];
                    let site_rim = [];
                    let site_vehicle = [];
                    let site_receive = []

                    let tyres = await $db.tyre.toArray();
                    let rims = await $db.rim.toArray();
                    let chains = await $db.chain.toArray();
                    let vehicles = await $db.vehicle.toArray();
                    let receives = await $db.receive.toArray();

                    if (type === EquipmentTypeName.tyre) {
                        site_tyre = tyres.filter(t => t.siteSerialNumber && toUpper(t.siteSerialNumber) === siteSerialNumber && (!equipmentId || equipmentId !== t.id));
                        man_tyre = tyres.filter(t => toUpper(t.manufacturerSerialNumber) === manSerialNumber && (!equipmentId || equipmentId !== t.id));
                    }

                    if (type === EquipmentTypeName.chain) {
                        site_chain = chains.filter(t => t.siteSerialNumber && toUpper(t.siteSerialNumber) === siteSerialNumber && (!equipmentId || equipmentId !== t.id));
                        man_chain = chains.filter(t => toUpper(t.manufacturerSerialNumber) === manSerialNumber && (!equipmentId || equipmentId !== t.id));
                    }

                    if (type === EquipmentTypeName.rim) {
                        site_rim = rims.filter(t => t.siteSerialNumber && toUpper(t.siteSerialNumber) === siteSerialNumber && (!equipmentId || equipmentId !== t.id));
                        man_rim = rims.filter(t => toUpper(t.manufacturerSerialNumber) === manSerialNumber && (!equipmentId || equipmentId !== t.id));
                    }

                    if (type === EquipmentTypeName.vehicle) {
                        site_vehicle = vehicles.filter(t => t.siteSerialNumber && toUpper(t.siteSerialNumber) === siteSerialNumber && (!equipmentId || equipmentId !== t.id));
                        man_vehicle = vehicles.filter(t => toUpper(t.manufacturerSerialNumber) === manSerialNumber && (!equipmentId || equipmentId !== t.id));
                    }

                    // check if we have any pending receive on the mobile that conflict 
                    site_receive = receives.filter(r => r.components ?
                        r.components.find(c => c.siteSerialNumber && toUpper(c.siteSerialNumber) === siteSerialNumber && (!equipmentId || equipmentId !== c.equipmentId)) :
                        r.vehicles.find(c => c.siteSerialNumber && toUpper(c.siteSerialNumber) === siteSerialNumber && (!equipmentId || equipmentId !== c.equipmentId))
                    );

                    man_receive = receives.filter(r => r.components ?
                        r.components.find(c => toUpper(c.manufacturerSerialNumber) === manSerialNumber &&
                            (!equipmentId || equipmentId !== (c.equipmentId || r.id)) && type === c.equipmentTypeName.toLowerCase()) :
                        r.vehicles.find(c => toUpper(c.manufacturerSerialNumber) === manSerialNumber &&
                            (!equipmentId || equipmentId !== (c.equipmentId || r.id)) && type === c.equipmentTypeName.toLowerCase())
                    );

                    existingSiteSerial = !!siteSerialNumber && (site_tyre.length > 0 || site_chain.length > 0 || site_rim.length > 0 || site_vehicle.length > 0 || site_receive.length > 0);
                    existingManufacturerSerial = !!manSerialNumber && (man_tyre.length > 0 || man_chain.length > 0 || man_rim.length > 0 || man_vehicle.length > 0 || man_receive.length > 0);

                    return {
                        manufacturerSerialNumber: existingManufacturerSerial,
                        siteSerialNumber: existingSiteSerial
                    };
                },

                deleteFieldSurvey: function (vehicleId, pendingFieldSurveyId) {
                    return $db.fieldSurvey.get(vehicleId).then(function (vehicle) {
                        if (vehicle) {
                            return $db.fieldSurvey.delete(vehicleId);
                        }
                    });
                },
                saveMaintenanceSession: async function (session) {
                    if (!session.siteId) {
                        session.siteId = ocConfigSvc.user.site.id;
                    }

                    if (!session.createdDate) {
                        session.createdDate = new Date();
                    }

                    session.pristine = 0;
                    session.displayDescription = session.vehicle.serialNumber + ' - ' + session.vehicle.description;

                    let attachments = [];

                    for (let reading of session.vehicle.positionReadings || []) {
                        for (let vi of reading.visualInspections || []) {
                            for (let attachment of vi.attachments || []) {
                                attachment.source = AttachmentType.visualInspection;
                                attachments.push(attachment);
                            }
                        }
                    }

                    await fileManagement.processFileUploads(attachments);

                    //for (let attachment of attachments)
                    //    fileManagement.clearData(attachment);


                    let sessionClone = angular.copy(session, {});
                    delete sessionClone.errorData;
                    delete sessionClone.errorList;

                    return $db.maintenanceSession.put(sessionClone);
                },
                getComponentBySerialNumber: function (componentType, serialNumber, specificationId, date) {
                    return $q(function (resolve, reject) {
                        return $db[componentType].where("serialNumber").equals(serialNumber).toArray().then(function (components) {

                            if (!components || components.length === 0) return resolve();

                            if (specificationId) {
                                // prefer matching specification
                                for (var c = 0; c < components.length; c++) {
                                    if (components[c].equipmentTypeSpecificationTypeId === specificationId) {
                                        return resolve(components[c]);
                                    }
                                }
                            }

                            if (components.length === 1) {
                                return resolve(components[0]);
                            }

                            reject("");

                        }, reject);
                    });
                },
                loadMaintenanceSession: function (vehicleId, sessionId, date) {
                    return $db.maintenanceSession.get(vehicleId).then(function (session: any) { //TODO: any cast
                        if (!session) {
                            //console.log("Creating new Maintenance session");
                            session = {
                                id: vehicleId,
                                sessionId: uuid(),
                                createdDate: new Date()
                            };
                            return local.getVehicleDetails(vehicleId, date).then(function (v) {
                                //console.log("returning session");
                                if (!v) {
                                    console.error("No vehicle for session");
                                    throw new Error("No vehicle found for this session");
                                }
                                session.vehicle = v;
                                session.displayDescription = v.serialNumber + " - " + v.description;
                                return session;
                            });
                        } else {

                            var readingsAtPos = _.indexBy(session.vehicle.positionReadings, 'positionId');

                            // for each fitment locate the components.
                            for (var attr in session.vehicle.positions) {

                                if (session.vehicle.positions.hasOwnProperty(attr)) {

                                    var pos = session.vehicle.positions[attr];
                                    var rap = readingsAtPos[attr];

                                    if (!pos) {
                                        console.error(
                                            "This vehicle has a fitment which is not at a recognised position");
                                        throw new Error(
                                            amtXlatSvc.xlat("equipment.fitmentPositionError"));
                                    }

                                    for (let ft of pos.fitments) {
                                        local.lookupComponent(ft, rap).then(() => { session.vehicle.updated = uuid(); });
                                    }
                                }
                            }
                        }
                        //No future events on mobile
                        session.futureEvents = false;
                        return session;
                    });
                },
                getSchematic: function (searchCriteria, vehicleObject) {
                    return $db.vehicle.get(searchCriteria.vehicleId).then(function (vehicle) {
                        // get the spec
                        if (!vehicle) {
                            return null;
                        }
                        //console.log("Have the Vehicle - find the schematic ");
                        return $db.specification.get(vehicle.specificationId).then(function (response) {
                            adjustVehiclePositions(response, vehicleObject);
                            return response;
                        });
                    });
                },
                saveFieldSurvey: async function (vehicle) {

                    if (!vehicle.siteId) {
                        vehicle.siteId = ocConfigSvc.user.site.id;
                    }

                    if (!vehicle.createdDate) {
                        vehicle.createdDate = new Date();
                    }

                    //check if done
                    let treadDone = true;
                    let pressureDone = true;

                    let attachments: IFile[] = [];

                    for (let pr of vehicle.positionReadings || []) {

                        if (!pr.pressure)
                            pressureDone = false;

                        if (!(pr.rtd1 || pr.rtd2))
                            treadDone = false;

                        for (let vi of pr.visualInspections || []) {
                            for (let attachment of vi.attachments || []) {
                                attachment.source = AttachmentType.visualInspection;
                                attachments.push(attachment);
                            }
                        }
                    }

                    // visual inspection images need to be stored in the file tables
                    await fileManagement.processFileUploads(attachments);

                    //for (let attachment of attachments)
                    //    fileManagement.clearData(attachment);

                    if (pressureDone) {

                        vehicle.pressureDue = '';

                        if (!vehicle.pressureDate) {
                            vehicle.pressureDate = new Date();
                        }

                    } else {
                        if (vehicle.lastPressureReading > new Date('1/1/1971')) {
                            vehicle.pressureDate = vehicle.lastPressureReading;
                        }
                    }

                    if (treadDone) {

                        vehicle.treadDue = '';

                        if (!vehicle.treadDate) {
                            vehicle.treadDate = new Date();
                        }

                    } else {
                        if (vehicle.lastTreadReading > new Date('1/1/1971')) {
                            vehicle.treadDate = vehicle.lastTreadReading;
                        }
                    }

                    if (!vehicle.inProgress) {

                        // mobile audit logging
                        local.logAuditEntry('Field Survey', 'Create',
                            String.format('{0} ({1})', vehicle.serialNumber, ocDateSvc.toReportString(vehicle.createdDate) )
                        );

                        vehicle.inProgress = true;
                    }

                    vehicle.pristine = 0;

                    let vehicleClone = angular.copy(vehicle, {});
                    delete vehicleClone.errorData;
                    delete vehicleClone.errorList;

                    await $db.fieldSurvey.put(vehicleClone);
                },
                fieldSurveyCount: function (vehicle) {
                    return $db.fieldSurvey.count();
                },
                logAuditEntry: function (module, event, detail?) {
                    // get the browser, app version and db version at time of the entry, instead of during upload
                    // just in case these values change at all prior to the upload
                    let record = {
                        date: new Date(),
                        personId: ocConfigSvc.user ? ocConfigSvc.user.personId : null,
                        siteId: ocConfigSvc.user ? ocConfigSvc.user.site.id : null,
                        module: module,
                        event: event,
                        detail: detail,
                        applicationVersion: window.appVersion,
                        databaseVersion: $db.verno,
                        browser: navigator.userAgent
                    };

                    $db.auditRecords.add(record);
                },
                uploadAudits: function () {

                    if (browserSvc.isOnline) {

                        $db.auditRecords.toArray().then(records => {

                            if (records) {

                                if (!angular.isArray(records)) {
                                    records = [records]; // make it an array
                                }

                                return amtCommandQuerySvc.post('sync/uploadMobileAudit', {
                                    auditRecords: records
                                })
                                    .then(response => {
                                        records.forEach(r => {
                                            $db.auditRecords.delete(r.id);
                                        });
                                    })
                                    .catch(error => {
                                        errorReporter.logError(error);
                                    });
                            }
                        });
                    }
                },
                logError: function (errors) {

                    if (!angular.isArray(errors)) {
                        errors = [errors]; // make it an array
                    }

                    errors.forEach(function (error) {
                        $db.clientErrors.add(error);
                    });

                    return $q.resolve();
                },
                uploadErrors: function () {

                    if (browserSvc.isOnline) {
                        $db.clientErrors.count(function (c) {
                            if (c > 0) {
                                $db.clientErrors.reverse().toArray().then(function (errors) {
                                    remote.logError(errors, 'Mobile').then(function (response) {

                                        if (response && response.status !== 'fail') {
                                            errors.forEach(function (e) {
                                                $db.clientErrors.delete(e.id);
                                            });

                                        } else {
                                            console.log('failed to upload client errors to the server');
                                        }
                                    }).catch(function (ex) {
                                        console.log('failed to upload client errors to the server');
                                        console.log(ex);
                                    });
                                });
                            }
                        });
                    }
                },
                getFieldSurveySettings: function () {
                    return $db.system.get(1);
                },
                updateSystemSettings: async function (settings) {
                    if ($db.system)
                        await $db.system.put(settings);
                },
                getErrors: function () {
                    return $db.clientErrors.reverse().toArray();
                },
                refreshToken: function (forceRefresh, refreshSite) {
                    return remote.refreshToken(forceRefresh, refreshSite);
                },
                login: function (username, password, denyTestLocal, siteId) {
                    // mobile login
                    if (!password || !username) {
                        return $q.reject(['missing_fields', amtXlatSvc.xlat('login.InvalidLoginAttempt')]);
                    }

                    if (!siteId) {
                        // get it from the db if possible, then try again.
                        return $db.profile.get(1).catch(function (error) {
                            console.error('Issue fetching profile');
                            console.error(error); // mod likely a local DB issue
                            return $q.resolve(null);
                        }).then(function (profile) {
                            if (profile && profile.siteId) {
                                return local.login(username, password, denyTestLocal, profile.siteId);
                            } else {
                                return local.login(username, password, denyTestLocal, "--");
                            }
                        });
                    }
                    var hash = sha256(encBase64.parse(username.toLowerCase() + password + 'fds#4fff'));
                    vm.hashString = hash.toString(encBase64.Base64);
                    var data = 'grant_type=password&username=' + username + '&password=' + password + '&client_id=' + ocConfigSvc.client;
                    data = data + '&site=' + siteId;
                    data = data + '&mobile=true';
                    var deferred = $q.defer();

                    // **** DEBUGING
                    /*if (vm.quickLocalLogin && !denyTestLocal) {
                        return testLocalLogin(username, vm.hashString).then(function () {
                            /// these are only used for offline - online transition
                            authService.credentials = {
                                username: username,
                                password: password,
                                offline: true,
                            }
                        }).then(function () {
                            return deferred.resolve();
                        });
                    }*/
                    //// **** DEBUGING

                    //console.log('Post to server');
                    $http.post<any>(baseUrl + 'token',
                        data,
                        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
                        .then(function (response) {
                            //console.log('Attempted login on server');
                            if (response.status === -1) {
                                if (denyTestLocal) {
                                    return deferred.reject(
                                        ['generic_error', response.statusText || response.status]);
                                }
                                // offline
                                return testLocalLogin(username, vm.hashString).then(function () {
                                    /// these are only used for offline - online transition
                                    authService.credentials = {
                                        username: username,
                                        password: password,
                                        offline: true,
                                    }
                                }).then(function () {
                                    return deferred.resolve();
                                });
                            }
                            if (response.status !== 200) {
                                if (response.status >= 400 && response.status <= 403) {
                                    // we don't test the local login because they successfully went online here and got the credentials wrong.
                                    // perhaps the password changed ? Or perhaps they just got it wrong. ?
                                    
                                    let translationKey = 'login.' + response.data.error;
                                    let translatedMsg = amtXlatSvc.xlat(translationKey);
                                    // replace the detailed message with a translated generic
                                    let msg = translatedMsg && translatedMsg !== translationKey ? translatedMsg : response.data.error_description;

                                    console.error(response.data.error + ":" + response.data.error_description);
                                    return deferred.reject([response.data.error, msg]);
                                }
                                return deferred.reject(['generic_error', response.statusText || response.status]);
                            }
                            // logged into the server successfully.
                            // need to store user name and password is some manner for later offline validation
                            // we are now online authenticated.

                            authService.credentials = {
                                username: username,
                                password: password,
                                token: response.data.access_token,
                                refresh_token: response.data.refresh_token,
                            };

                            //console.log('Logged in');
                            deferred.resolve(response);
                        }).catch(function (err) {
                            console.error('Error while logging into server.');
                            return deferred.reject(['error', err]);
                        });

                    return deferred.promise;
                },
                getSession: function (refresh, siteId) {

                    // **** DEBUGING
                    /*if (vm.quickLocalLogin) {
                        return $db.profile.get(1);
                    }*/
                    //// **** DEBUGING


                    return remote.getSession(false, siteId).then(function (response) {
                        //console.log('have remote session');
                        // save the profile for offline later
                        response.id = 1;
                        response.hash = vm.hashString;
                        return $db.profile.get(1).then(function (profile) {
                            // use the profile if it's from a different site
                            if (profile && !refresh && response.siteId !== profile.siteId) {
                                response.siteId = profile.siteId || response.siteId;
                                response.siteName = profile.siteName || response.siteName;
                                response.clientId = profile.clientId || response.clientId;
                                response.clientName = profile.clientName || response.clientName;
                                response.units = profile.units || response.units;
                            }
                            //console.log("SITE:" + response.siteName);
                            return $db.profile.put(response).then(function () {
                                //console.log('Profile saved for offline');
                                return $q.resolve(response);
                            }).catch(function (error) {
                                console.error("Can't save profile");
                                console.error(error);
                                return $q.resolve(response);
                            });
                        });
                    }).catch(function (error) {
                        console.error('no remote session');
                        if (error.status === -1) {
                            return $db.profile.get(1).catch(function (dbError) {
                                console.error("failed to get profile");
                            });
                        } else {
                            return $q.reject(error);
                        }
                    });
                },
                resetPassword: function (email, password, resetToken) {
                    return $http.post(baseApiUrl + 'login/ResetPassword',
                        {
                            email: email,
                            password: password,
                            resetToken: resetToken
                        });
                },
                getComponentTypes: function () {
                    return $db.equipmentTypes.toCollection().reverse().sortBy('description').then(function (types) {
                        return { result: types.filter(et => et.name !== 'Vehicle') };
                    });
                },
                getStatuses: function () {
                    return $db.statuses.toCollection().sortBy('name');
                },
                getEquipmentStatusAtDate: function (equipmentId, date, type) {

                    return $db[type.toLowerCase()].get(equipmentId).then(function (equipment) {
                        if (equipment) {
                            return equipment.status.adjustedState || equipment.status.name;
                        } else {
                            console.error("Equipment not found");
                        }
                    });
                },
                getSpecificationDetails: function (specificationId, date) {
                    return $db.specification.get(specificationId).then(function (spec) {
                        return spec ? { id: spec.id, defaultCost: spec.defaultCost, serialNumberValidations: spec.serialNumberValidations } : null;
                    });
                },
                getVehicleSpecificationDetails: function (specificationId) {
                    return $db.specification.get(specificationId).then(function (spec) {
                        return spec ? { id: spec.id, hasHoursMeter: spec.hasHoursMeter } : null;
                    });
                },
                getCostTypes: function (criteria) {
                    return $db.costTypes.toArray().then(function (types) {
                        // un-indexed so just sort them here (There is only two)
                        return { result: types.sort((a, b) => a.name.localeCompare(b.name, 'en')) };
                    });
                },
                getPendingComponents: function (siteId, equipmentTypeId, includeFittedOnly, excludeReceived, checkVehicleExists) {

                    return $db.pendingComponents.toArray().then(

                        function (pending) {

                            // sorts need to be chained in reverse order (fittedToVehicleSerial is first)
                            var filteredResult = _.chain(pending)
                                .sortBy(c => c.serialNumber.formatted)
                                .sortBy(c => c.fittedToPosition)
                                .sortBy(c => c.equipmentType.equipmentType)
                                .sortBy(c => c.fittedToVehicleSerial)
                                .value();

                            if (equipmentTypeId && equipmentTypeId !== amtConstants.emptyGuid && equipmentTypeId !== 'Pending') {
                                filteredResult = filteredResult.filter(component => component.equipmentType.key === equipmentTypeId);
                            }

                            filteredResult = filteredResult.filter(component => includeFittedOnly ?
                                component.fittedToVehicleSerial : !component.fittedToVehicleSerial);

                            return filterPendingComponents(filteredResult, excludeReceived).then(function (response) {
                                return { result: response };
                            });
                        });
                },
                getSpecifications: function (criteria, forVehicle) {
                    if (!criteria.componentTypeId || criteria.componentTypeId === amtConstants.emptyGuid || criteria.componentTypeId == 'Pending') {
                        if (forVehicle === true) {
                            return local.getEquipmentTypeId('Vehicle').then(function (equipmentTypeId) {
                                return $db.specification.where('equipmentTypeId').equals(equipmentTypeId)
                                    .sortBy('description').then(specs => ({ result: specs }));
                            });
                        } else {
                            return $db.specification.orderBy('description').toArray().then(specs => ({ result: specs }));
                        }
                    } else {
                        return $db.specification.where('equipmentTypeId').equals(criteria.componentTypeId)
                            .sortBy('description').then(specs => ({ result: specs }));
                    }
                },
                getUnfulfilledPurchaseOrders: async function (componentTypeId: string, componentTypeName: string) {
                    let data = await $db.purchaseOrders.where(componentTypeName.toLowerCase()).above(0).toArray();
                    return data.map(p => ({ key: p.id, name: p.poNumber }));
                },
                getSpecificationsForPurchaseOrder: async function (id, componentTypeId) {
                    let po = await $db.purchaseOrders.where({ id: id }).first();

                    let specs = await $db.specification.where('id').anyOf(po.specifications).filter(s => !!s.equipmentTypeId).toArray();

                    return specs.map(s => ({ id: s.id, description: s.description }));
                },
                getPressureSensors: async function (criteria: IPressureSensorCriteria) {
                    let pressureSensorTemplate = amtXlatSvc.xlat('component.sensorFittedTo');
                    let fittedIds = await $db.tyre.filter(s => s.pressureSensorId != null && s.id !== criteria.equipmentId).toArray().then(ps => ps.map(function (i) { return { Id: i.pressureSensorId, Serial: i.serialNumber } }));
                    let allSensors = await $db.pressureSensor.orderBy('description').toArray();
                    let test = criteria.equipmentId;
                    allSensors.map(function (item) {
                        item.description = (fittedIds.some(i => i.Id === item.key))
                            ? item.description + ' (' + pressureSensorTemplate + ' ' + fittedIds.find((element) => element.Id === item.key).Serial + ')'
                            : item.description;
                    });

                    return { result: allSensors };
                },
                getProductionCrews: function (criteria: IProductionCrewCriteria) {
                    return $db.productionCrew.orderBy('description').toArray().then(function (data) {
                        return { result: data };
                    });
                },
                getMaintenanceTypes: function (criteria) {
                    return $db.maintenanceType.orderBy('description').toArray().then(function (data) {
                        return { result: data };
                    });
                },
                getDowntimeTypes: function (criteria) {
                    return $db.downTimeTypes.orderBy('description').toArray().then(function (data) {
                        return { result: data };
                    });
                },
                getRemovalReasons: function (criteria: IRemovalReasonCriteria) {
                    return $db.removalReasons.orderBy('description').toArray().then(function (data) {
                        if (criteria.componentTypeId) {
                            data = data.filter(r => r.componentTypeId.toLowerCase() === criteria.componentTypeId.toLowerCase());
                        }
                        return { result: data };
                    });
                },
                getFitters: function (criteria) {
                    return $db.fitters.orderBy('name').toArray().then(function (data) {
                        return { result: data };
                    });
                },
                getDamageCauses: function (criteria: IDamageCauseCriteria) {
                    return $db.damageCauses.orderBy('description').toArray().then(function (data) {
                        if (criteria.componentTypeId) {
                            data = data.filter(r => r.componentTypeId.toLowerCase() === criteria.componentTypeId.toLowerCase());
                        }
                        return { result: data };
                    });
                },
                getDamageSources: function (criteria: IDamageSourceCriteria) {
                    return $db.damageSources.orderBy('description').toArray().then(function (data) {
                        if (criteria.componentTypeId) {
                            data = data.filter(r => r.componentTypeId.toLowerCase() === criteria.componentTypeId.toLowerCase());
                        }
                        return { result: data };
                    });
                },
                getDamageLocations: function (criteria: IDamageLocationCriteria) {
                    return $db.damageLocations.orderBy('description').toArray().then(function (data) {
                        if (criteria.componentTypeId) {
                            data = data.filter(r => r.componentTypeId.toLowerCase() === criteria.componentTypeId.toLowerCase());
                        }
                        return { result: data };
                    });
                },
                getWorkshopTimes: function (criteria: IWorkshopTimesCriteria) {
                    return $db.workshopTimes.orderBy('description').toArray().then(function (data) {
                        if (criteria.category) {
                            data = data.filter(r => r.category === criteria.category);
                        }
                        return { result: data };
                    });
                },
                getEquipmentTypeId: async function (type): Promise<guid> {
                    if (!type) {
                        throw new Error("type not supplied for getEquipmentTypeId");
                    }

                    return await $db.equipmentTypes.filter(et => et.name === type.capitalize()).first(equipmentType => equipmentType.id);
                },
                getStatusFlows: async function (criteria: IStatusFlowCriteria) {

                    if (!criteria.equipmentTypeId && criteria.equipmentType)
                        criteria.equipmentTypeId = await local.getEquipmentTypeId(criteria.equipmentType);

                    let statusFlows;

                    if (criteria.statusTypeToId) {
                        statusFlows = await $db.statusFlows
                            .where('statusTypeIdTo')
                            .equals(criteria.statusTypeToId)
                            .sortBy('description');
                    } else if (criteria.statusTypeFromId) {
                        statusFlows = await $db.statusFlows
                            .where('statusTypeIdFrom')
                            .equals(criteria.statusTypeFromId)
                            .sortBy('description');
                    } else {
                        statusFlows = await $db.statusFlows.orderBy('description').toArray();
                    }

                    if (!criteria.statusTypeToId && criteria.statusTypeTo)
                        statusFlows = statusFlows.filter(d => d.name.toLowerCase() === criteria.statusTypeTo);

                    if (!criteria.statusTypeFromId && criteria.statusTypeFrom)
                        statusFlows = statusFlows.filter(d => d.statusTypeFromName.toLowerCase() === criteria.statusTypeFrom);

                    if (criteria.excludeNewLife)
                        statusFlows = statusFlows.filter(d => !d.startNewLife);

                    if (criteria.equipmentTypeId)
                        statusFlows = statusFlows.filter(d => d.equipmentTypeIds.some(e => e === criteria.equipmentTypeId));

                    return ({ result: statusFlows });
                },
                getLocations: function (criteria: ILocationCriteria) {

                    return $db.locations.where('siteId').anyOf(criteria.siteIds).toArray().then(function (locations: any) { //TODO: any cast

                        let result = locations || [];

                        if (criteria.date != null) {
                            result = result.filter(r => r.activeFrom <= criteria.date && (r.activeTo === null || r.activeTo > criteria.date));
                        }

                        if (criteria.statusTypeIds != null) {
                            result = result.filter(r => (r.siteStatusTypeLocationStatusTypes || []).some(s => criteria.statusTypeIds.includes(s.statusTypeId)));
                        }

                        // copy id value to key field for compatability with desktop
                        for (let r of result) {
                            r.key = r.id;
                        }

                        return { result: result };
                    });
                },
                getUnknownComponentReceiveDestinationStatusTypes: function (criteria) {
                    return $db.statuses.where('conditionType').equals(criteria.conditionType).toArray().then(statuses => statuses);
                },
                getPendingReceivals: function (type) { // This returns receivals that haven't been sync'd yet, in mobile only. Desktop is different.
                    return $db.receive
                        .toArray()
                        .then(receivals => ({
                            data: type === 'vehicles' ?
                                $filter('filter')(receivals, receival => receival.vehicles !== undefined) :
                                $filter('filter')(receivals, receival => receival.components !== undefined)
                        }));
                },
                getPendingReceival: async function (id) {

                    let response = await $db.receive.get(id);
                    let attachments = [];

                    for (let v of response.vehicles || []) {
                        for (let a of v.eventDetails.attachments || []) {
                            a.manufacturerSerialNumber = v.manufacturerSerialNumber;
                            attachments.push(a);
                        }

                        if (v.conditionType === enums.receiveConditionTypes.transferred) {
                            let p = await $db.pendingReceivals.filter(receival => receival.id === v.id).first();
                            if (p) {
                                v.positions = p.positions;
                            }
                        }
                    }

                    if (!response.vehicles && response.components[0].eventDetails) {
                        attachments = response.components[0].eventDetails.attachments;
                    }

                    //for (let at of attachments || []) {
                    //    let file = await $db.file.where('id').equals(at.id).first();

                    //    if (file)
                    //        at.data = file.data;
                    //}

                    return response;
                },
                deleteReceiptedComponents: async function (id) {

                    let receive = await $db.receive.where('id').equals(id).first();

                    if (receive) {

                        await $db.receive.where('id').equals(id).delete();

                        // mobile audit logging
                        receive.components.forEach(c => {
                            local.logAuditEntry('Component Receive', 'Delete',
                                String.format('[{0}] {1} - {2} ({3})', c.equipmentTypeName, c.manufacturerSerialNumber,
                                    c.specificationName, ocDateSvc.toReportString(ocDateSvc.addLocalTimeZoneOffset(c.eventDetails.eventDate)))
                            );
                        });
                    }
                },
                receiveUnknownComponents: async function (id, saveCriteria) {

                    if (id) {
                        await local.deleteReceiptedComponents(id);
                    }

                    saveCriteria.pristine = 0;
                    return $db.receive.add(saveCriteria);
                },
                receiveKnownComponents: async function (id, saveCriteria) {

                    if (id) {
                        await local.deleteReceiptedComponents(id);
                    }

                    saveCriteria.pristine = 0;
                    return $db.receive.add(saveCriteria);
                },
                receivePendingComponents: async function (id, saveCriteria) {

                    if (id) {
                        await local.deleteReceiptedComponents(id);
                    }

                    saveCriteria.pristine = 0;
                    return $db.receive.add(saveCriteria);
                },
                deleteReceiptedVehicles: async function (id) {

                    let receive = await $db.receive.where('id').equals(id).first();

                    if (receive) {
                        await $db.receive.where('id').equals(id).delete();

                        // mobile audit logging
                        receive.vehicles.forEach(c => {
                            local.logAuditEntry('Vehicle Receive', 'Delete',
                                String.format('{0} - {1} ({2})', c.manufacturerSerialNumber,
                                    c.specificationName, ocDateSvc.toReportString(ocDateSvc.addLocalTimeZoneOffset(c.eventDetails.eventDate)))
                            );
                        });
                    }
                },
                receiveUnknownVehicles: async function (id, saveCriteria) {

                    if (id) {
                        await local.deleteReceiptedVehicles(id);
                    }

                    saveCriteria.pristine = 0;
                    return $db.receive.add(saveCriteria);
                },
                receiveKnownVehicles: async function (id, saveCriteria) {

                    if (id) {
                        await local.deleteReceiptedVehicles(id);
                    }

                    saveCriteria.pristine = 0;
                    return $db.receive.add(saveCriteria);
                },
                receivePendingVehicles: async function (id, saveCriteria) {

                    if (id) {
                        await local.deleteReceiptedVehicles(id);
                    }

                    saveCriteria.pristine = 0;
                    return $db.receive.add(saveCriteria);
                },
                getUnitsOfMeasure: function (baseReadingUnit) {
                    return $db.unitsOfMeasure.where('baseReadingUnit').equals(baseReadingUnit).toArray().then(
                        function (units) {
                            return {
                                result: units.map(unit => ({
                                    id: unit.id,
                                    description: unit.unitAbbreviation
                                }))
                            }
                        });
                },
                getPendingVehicles: function (siteId, excludeReceived) {
                    return $db.pendingVehicles.orderBy('serialNumber.manufacturer').toArray().then(
                        function (pending: any) { //TODO: temporary any cast

                            // filter out any that have already been received
                            return $db.receive.toArray().then(function (receivals) {

                                let vehicleData = $filter('filter')(receivals, receival => receival.vehicles !== undefined);

                                let vehicles = [];

                                if (vehicleData && vehicleData.length > 0) {
                                    for (let vd of vehicleData) {
                                        for (let v of vd.vehicles) {
                                            vehicles.push(v.id);
                                        }
                                    }

                                    for (var i = 0; i < pending.length; i++) {
                                        if (vehicles.indexOf(pending[i].id) > -1) {
                                            if (excludeReceived === true) {
                                                pending.splice(i, 1);
                                                i--;
                                            } else {
                                                pending[i].received = true;
                                            }
                                        }
                                    }
                                }

                                return { result: pending };
                            });
                        });
                },
                deletePendingVehicle: function (pendingVehicleId) {
                    return $db.pendingVehicles.get(pendingVehicleId).then(function (vehicle) {
                        if (vehicle) {
                            return $db.pendingVehicles.delete(pendingVehicleId);
                        }
                        return $q.resolve();
                    });
                },
                getIncompleteWheelPositionStatusTypes: function () {
                    return $db.statusFlows.filter(s => s.statusTypeFromName === enums.statusTypes.maintenance)
                        .toArray()
                        .then(flows =>
                        ({
                            result: flows.map(flow =>
                            ({
                                key: flow.statusTypeIdTo,
                                name: flow.name,
                                description: flow.description
                            }))
                        }));
                },
                recordMaintenanceSessionReadings: function (checkInEquipmentEventId, readings) {
                    // not required for mobile
                    return $q.resolve();
                },
                getComponentOwners: function () {
                    return $db.componentOwner.toArray().then(owners => ({ result: owners }));
                },
                /**
                 * Get all the Components of a given Type in a particular Status.
                 * @param {string} siteId - Guid of Site to get Components for.
                 * @param {string} status - Status to filter on (Transferred/Retreaded/etc.)
                 * @param {string} [componentTypeId] - Type to filter on. Server assumes Tyre if not set.
                 * @returns {Object[]} 
                 */
                getComponentsInStatus: function (clientId, siteId, statuses, componentTypeIds, componentTypeNames, date, retrieveDetails, excludeReceived) {
                    // Don't actually need SiteId for Mobile; the reference data is *only* for the current site.
                    return $db.pendingReceivals.filter(receival => receival.equipmentType &&
                        componentTypeNames.includes(receival.equipmentType.name) && statuses.includes(receival.status) &&
                        ((!date && !receival.dateTo) || (date && receival.dateFrom < date && (!receival.dateTo || receival.dateTo > date))))
                        .toArray()
                        .then(components => filterPendingComponents(components, excludeReceived)
                            .then(response => ({ result: response })));
                },
                getVehiclesInStatus: function (clientId, siteId, statuses, date, retrievePositions, excludeReceived) {

                    return $db.pendingReceivals.filter(receival => receival.equipmentTypeName === 'Vehicle' && statuses.includes(receival.status) &&
                        ((!date && !receival.dateTo) || (date && receival.dateFrom <= date && (!receival.dateTo || receival.dateTo > date))))
                        .toArray()
                        .then(vehicles => filterPendingVehicles(vehicles, excludeReceived)
                            .then(response => ({ result: response })));
                },
                getVehiclePositionFitments: function (vehicleIds, siteId, date) {
                    return $q.resolve();
                },
                getRetreaders: function (criteria: IRetreaderCriteria) {
                    // Don't actually need SiteId for Mobile; the reference data is *only* for the current site.
                    return $db.retreaders.toArray().then(retreaders => {

                        if ((criteria.equipmentTypeIds || []).length > 0) {
                            retreaders = $filter("filter")(retreaders, retreader => retreader.requiresManualEntry === true ||
                                retreader.equipmentTypeIds.some(e => criteria.equipmentTypeIds.includes(e)));
                        }

                        if (!criteria.includeOther) {
                            retreaders = $filter("filter")(retreaders, retreader => retreader.requiresManualEntry === false);
                        }

                        return { result: retreaders };
                    });
                },
                getRepairers: function (criteria: IRepairerCriteria) {
                    // Don't actually need SiteId for Mobile; the reference data is *only* for the current site.
                    return $db.repairers.toArray().then(repairers => {

                        if ((criteria.equipmentTypeIds || []).length > 0) {
                            repairers = $filter("filter")(repairers, repairer => repairer.requiresManualEntry === true ||
                                repairer.equipmentTypeIds.some(e => criteria.equipmentTypeIds.includes(e)));

                        }

                        if (!criteria.includeOther) {
                            repairers = $filter("filter")(repairers, repairer => repairer.requiresManualEntry === false);
                        }

                        return { result: repairers };
                    });
                },
                getRepairTypes: function (criteria: IRepairTypeCriteria) {
                    return $db.repairTypes.toArray().then(repairTypes => { return { result: repairTypes } });
                },
                getReturnToSupplierReasons: function (criteria: IReturnToSupplierReasonCriteria) {
                    return $db.returnToSupplierReasons.toArray().then(returnToSupplierReasons => { return { result: returnToSupplierReasons } });
                },
                getSuppliers: function (criteria: ISupplierCriteria) {
                    return $db.suppliers.toArray().then(suppliers => {

                        if ((criteria.equipmentTypeIds || []).length > 0) {
                            suppliers = $filter("filter")(suppliers, repairer => repairer.requiresManualEntry === true ||
                                repairer.equipmentTypeIds.some(e => criteria.equipmentTypeIds.includes(e)));

                        }

                        if (!criteria.includeOther) {
                            suppliers = $filter("filter")(suppliers, repairer => repairer.requiresManualEntry === false);
                        }

                        return { result: suppliers };
                    });
                },
                getReadingEventTypeComments: function (criteria) {

                    return $db.readingEventTypeComment.toArray().then(function (comments) {

                        if (comments && comments.length > 0) {
                            if (criteria) {
                                if (criteria.readingEventTypeId) {
                                    comments = $filter("filter")(comments, comment => comment.readingEventTypeId == criteria.readingEventTypeId);
                                }

                                if (criteria.readingEventTypeName) {
                                    comments = $filter("filter")(comments, comment => comment.readingEventTypeName.toLowerCase() == criteria.readingEventTypeName.toLowerCase());
                                }
                            }

                            comments = _.sortBy(comments, c => c.description);
                        }

                        return { result: comments };
                    });
                },
                getCurrencyTypes: function (criteria: ICurrencyCriteria) {
                    return $db.currency.toArray().then(currencies => ({ result: currencies }));
                },
                causeFromReading: function () {
                    throw new Error("Unimplemented");
                },
                deletePendingReceival: function () {
                    throw new Error("Unimplemented");
                },
                loginExternal: function () {
                    throw new Error("Unimplemented");
                },
                changeComponentStatus: async function (event: TStatusChange) {

                    let date: Date = new Date();

                    let record: IDbStatusChange = await $db.statusChange.where('id').equals(event.equipmentId).first();

                    if (!event.id)
                        event.id = event.equipmentStatusIdFrom || uuid();

                    event.attachments = angular.copy(event.attachments || []).map(f => fileManagement.clearData(f));

                    if (!record) {

                        event.date = date;

                        record = {
                            id: event.equipmentId,
                            serialNumber: event.serialNumber,
                            componentTypeId: event.componentTypeId,
                            componentType: event.componentType,
                            pristine: 0,
                            createdDate: date,
                            events: [event]
                        };

                        // mobile audit logging
                        local.logAuditEntry('Status Change', 'Create',
                            String.format('[{0}] {1} - Move to {2}', event.componentType, event.serialNumber, event.destinationStatusType.name )
                        );

                    } else {

                        let existingEventIndex: number = null;

                        if (event.id) {
                            existingEventIndex = _.findIndex(record.events, e => e.id === event.id);
                        }

                        if (existingEventIndex != null && existingEventIndex >= 0) {
                            record.events[existingEventIndex] = event;
                        } else {
                            event.date = date;
                            record.events.push(event);
                        }
                    }

                    return $db.statusChange.put(record);
                },
                deletePendingStatusChanges: async function (statusChanges: Array<any>) {

                    for (let i = 0; i < statusChanges.length; i++) {

                        let equipmentId = statusChanges[i].equipmentId;

                        let record = await $db.statusChange.where('id').equals(equipmentId).first();

                        if (record) {
                            let eventIndex = _.findIndex(record.events, r => r.id === statusChanges[i].eventId);

                            let statusType: string;

                            if (eventIndex >= 0) {
                                statusType = record.events[eventIndex].destinationStatusType.name;

                                record.events.splice(eventIndex, 1);
                            }

                            if (record.events.length === 0) {
                                await $db.statusChange.where("id").equals(equipmentId).delete();
                            } else {
                                await $db.statusChange.put(record);
                            }

                            // mobile audit logging
                            local.logAuditEntry('Status Change', 'Delete',
                                String.format('[{0}] {1}{2}', record.componentType, record.serialNumber,
                                    statusType ? String.format(' - Move to {0}', statusType) : '')
                            );
                        }
                    }
                },
                getStatusChanges: async function () {

                    let statusChanges = await $db.statusChange.toArray();

                    let events = statusChanges ? _.flatten(statusChanges.map(s => s.events)) : [];

                    return events.sort((a, b) => a.searchDescription.localeCompare(b.searchDescription));
                }
            };
            // #endregion local
            // #region remote
            var remote = {
                lookupComponent: function (fitment, readingAtPosition) {
                    return $q.resolve();
                },
                causeFromReading: function (readingId) {
                    return amtCommandQuerySvc.post('equipment/vehicle/getCauseIdFromReading', { readingId: readingId });
                },
                loadVehicleListMaint: function () {
                    return amtCommandQuerySvc.post('equipment/vehicle/getVehiclesMaintenance', {}).then(
                        function (result) {
                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }
                            return {
                                result: result.result.sort((a, b) => buildSortKey([mapStatusToSortOrder(a.maintenanceStatus), a.serialNumber]).toLowerCase()
                                    .localeCompare(buildSortKey([mapStatusToSortOrder(b.maintenanceStatus), b.serialNumber]).toLowerCase()))
                            };
                        });
                },
                loadVehicleList: function () {
                    return amtCommandQuerySvc.post('equipment/vehicle/getVehicles')
                        .then(vehicles => ({ result: vehicles.result }));
                },
                checkUniqueSerialNumber: function (manSerialNumber, siteSerialNumber, type, equipmentId) {
                    return amtCommandQuerySvc.post('equipment/common/checkUniqueSerialNumber', {
                        manufacturerSerialNumber: manSerialNumber,
                        siteSerialNumber: siteSerialNumber,
                        equipmentType: type,
                        equipmentId: equipmentId
                    }).then(function (response) {
                        return response;
                    });
                },
                checkRefData: function () {
                    return $q.resolve(); // desktop needs no reference data.
                },
                getSerials: function (type) {
                    var criteria = {
                        listType: type
                    }
                    return amtCommandQuerySvc.post(equipmentUrls.url.serialNumberList, criteria);
                },
                getValidFitments: function (type, positionId, statusName, schematic, date) {
                    var criteria = {
                        wheelPositionId: positionId,
                        componentTypes: (type ? [type] : null),
                        date: date,
                        statusTypes: []
                    }

                    criteria.statusTypes = statusName ? [statusName] : ['NEW', 'SPARE'];

                    return amtCommandQuerySvc.post(vehicleUrls.getValidFitmentsForWheelPosition, criteria).then(
                        function (data) {
                            return _.sortBy(data.result, fitment => fitment.serialNumber.formatted);
                        });
                },
                checkValidFitmentForPosition: function (wheelPositionId, equipmentId) {
                    var criteria = {
                        wheelPositionId: wheelPositionId,
                        equipmentId: equipmentId
                    };

                    return amtCommandQuerySvc.post(referenceDataUrls.checkValidFitmentForPosition, criteria);
                },

                checkVehicleLockedOut: function (vehicleId, module) {
                    return $q.resolve(false);
                },

                msRemoveEvent: function (removeData, session) {

                    if (session.heldInError || session.pending) {

                        return $q.resolve(removeData.eventData[0].components.map(c => ({ equipmentEventId: c.eventId, date: null })));

                    } else {
                        var command = {
                            checkInEventId: session.checkin.eventId,
                            components: removeData.eventData.map(
                                i => i.components.map(c => ({
                                    serialNumber: c.serialNumber,
                                    equipmentId: c.componentId || c.equipmentId,
                                    equipmentType: c.type,
                                    comment: i.comment,
                                    equipmentEventId: c.eventId,
                                    statusTypeId: i.selectedMoveTo.id,
                                    sequenceDate: new Date(),
                                    sequence: amtConstants.removalOrder.findIndex(r => r === c.type.toLowerCase()),
                                    locationId: helperSvc.getKey(i.selectedLocation),
                                    removeDetails: {
                                        wheelPositionId: i.position.id,
                                        removeAsAssembly: i.components.length > 1,
                                        removeReasonId: helperSvc.getKey(i.selectedRemovalReason),
                                    },
                                    damageDetails: (i.selectedDamageCause ? {
                                        damageCauseId: helperSvc.getKey(i.selectedDamageCause),
                                        damageLocationId: helperSvc.getKey(i.selectedDamageLocation),
                                        damageSourceId: helperSvc.getKey(i.selectedDamageSource),
                                        instant: i.instant,
                                        tyreBurst: i.tyreBurst,
                                        tyreExplosion: i.tyreExplosion
                                    } : null),
                                    transferDetails: (i.destination ? {
                                        destinationSiteId: helperSvc.getKey(i.destination),
                                        destinationOther: i.otherDestination,
                                        transferredFromMaintenance: true
                                    } : null),
                                    repairDetails: (i.repairer ? {
                                        repairerId: helperSvc.getKey(i.repairer),
                                        repairerOther: i.otherRepairer
                                    } : null),
                                    costDetails: (i.creditAmount ? {
                                        amount: i.creditAmount,
                                        currencyTypeId: ocConfigSvc.user.site.currency.id
                                    } : null),
                                    readings: {
                                        readings:
                                            (i.rtd1 != null && c.type === 'Tyre') ? [{
                                                readingEventTypeName: "treaddepth",
                                                isNewMeter: false,
                                                unitOfMeasureAbbreviation: ocConfigSvc.user.site.units.depth.unitAbbreviation,
                                                values: [{ value: i.rtd1, sequence: 1 }, { value: i.rtd2, sequence: 2 }]
                                            }] : (i.rcd1 != null && c.type === 'Chain') ? [{
                                                readingEventTypeName: "chaindepth",
                                                isNewMeter: false,
                                                unitOfMeasureAbbreviation: ocConfigSvc.user.site.units.depth.unitAbbreviation,
                                                values: [{ value: i.rcd1, sequence: 1 }, { value: i.rcd2, sequence: 2 }]
                                            }] : []
                                    },
                                    visualInspections: i.visualInspections
                                }))
                            ).reduce((prev, current) => prev.concat(current))
                        };

                        return amtCommandQuerySvc.post(componentUrls.removeComponents, command).then(function (result) {

                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }

                            removeData.events = result.data;

                            return result.data;

                        });
                    }
                },

                msSwapComponentHistory: function (equipmentEventId, equipmentId) {
                    var criteria = {
                        equipmentEventId: equipmentEventId,
                        equipmentId: equipmentId
                    };

                    return amtCommandQuerySvc.post(vehicleUrls.swapComponentHistory, criteria)
                        .then(result => result, error => {
                            errorReporter.logError(error, "EditFitment-SwapComponentHistory");
                            return $q.reject();
                        });
                },

                msSwapPositionFitments: function (firstEquipmentEventId, secondEquipmentEventId) {

                    let criteria = {
                        firstEquipmentEventId: firstEquipmentEventId,
                        secondEquipmentEventId: secondEquipmentEventId
                    };

                    return amtCommandQuerySvc.post(vehicleUrls.swapPositionFitments, criteria).then(function (result) {
                        return result;
                    }, function (error) {
                        errorReporter.logError(error, "EditFitment-SwapPositionFitments");
                        return $q.reject();
                    });

                },

                msStripAssembly: function (assemblyId, eventType: string) {
                    return amtCommandQuerySvc.post(vehicleUrls.stripAssembly, { assemblyId: assemblyId, eventType: eventType }).then(function (response) {
                        return response;
                    });
                },

                msUndoRemove: function (data, session) {

                    var undoRemovePromise;

                    if (session.heldInError || session.pending) {

                        undoRemovePromise = $q.resolve({ data: data.components });

                    } else {

                        let undoCriteria = data.events.map(e => ({ equipmentEventId: e.equipmentEventId }));

                        undoRemovePromise = amtCommandQuerySvc.post(componentUrls.undoMoveEvents, undoCriteria);
                    }

                    return undoRemovePromise.then(function (result) {

                        if (result.errors && result.errors.length > 0) {
                            return $q.reject({ data: result });
                        }

                        if (session.heldInError || session.pending) {
                            var removalPositionId = (data.selectedPosition ? data.selectedPosition.id : data.position.id)

                            var position = session.vehicle.positions[removalPositionId];

                            if (position) {

                                for (var c = 0; c < result.data.length; c++) {
                                    position.fitments.push(result.data[c]);
                                    position[result.data[c].type + 'Fitment'] = result.data[c];
                                }
                            }
                        }

                        return result.data;

                    });
                },

                msUndoFit: function (data, session) {

                    var undoFitPromise;

                    if (session.heldInError || session.pending) {

                        undoFitPromise = $q.resolve({ data: data.components });

                    } else {

                        let undoCriteria = data.events.map(e => ({ equipmentEventId: e.equipmentEventId }));

                        undoFitPromise = amtCommandQuerySvc.post(componentUrls.undoMoveEvents, undoCriteria);
                    }

                    return undoFitPromise.then(function (result) {

                        if (result.errors && result.errors.length > 0) {
                            return $q.reject({ data: result });
                        }

                        return result.data;

                    });
                },

                msFitEvent: function (fitData, session) {

                    if (session.heldInError || session.pending) {

                        if (fitData.eventData.components && fitData.eventData.components.length > 0) {
                            return $q.resolve(fitData.eventData.components.map(c => c.eventId));
                        } else {
                            return $q.resolve(fitData.eventData.fitment.eventId);
                        }

                    } else {
                        if (fitData.eventData && fitData.eventData[0]) {
                            fitData.eventData = fitData.eventData[0];
                        }

                        let components = fitData.eventData.components ? /* essentially a refit */ fitData.eventData.components :
                            [{
                                serialNumber: fitData.eventData.serialNumber,
                                equipmentId: fitData.eventData.fitment.equipmentId,
                                equipmentType: fitData.eventData.type,
                                equipmentTypeId: fitData.eventData.fitment.equipmentTypeId
                            }];

                        for (let comp of components) {

                            if (fitData.eventData.refit !== true) {
                                comp.equipmentEventId = comp.eventId;
                            }

                            comp.fitDetails = {
                                // refit, or new fitment.
                                wheelPositionId: (fitData.selectedPosition || fitData.eventData.selectedPosition).id,
                                fitAsAssembly: fitData.type === 'assembly',
                                nitrogenFilled: fitData.eventData.nitroFilled,
                                studsLubricated: <any>helperSvc.getKey(fitData.eventData.lubricatedStuds) === 1, //TODO:
                                pressureSensorId: helperSvc.getKey(fitData.eventData.pressureSensors)
                            };

                            comp.sequenceDate = new Date();
                            comp.sequence = amtConstants.fitmentOrder.findIndex(r => r === (comp.type || comp.equipmentType).toLowerCase());
                            comp.readings = { readings: [] }
                        }

                        let tyre = components.find(item => (item.type || item.equipmentType).toLowerCase() === 'tyre');
                        let rim = components.find(item => (item.type || item.equipmentType).toLowerCase() === 'rim');

                        let units = ocConfigSvc.user.site.units;

                        if (fitData.eventData.temperature !== undefined && fitData.eventData.temperature !== null && tyre != null) {
                            tyre.readings.readings.push({
                                readingEventTypeName: "treadtemp",
                                isNewMeter: false,
                                unitOfMeasureAbbreviation: units.temperature.unitAbbreviation,
                                values: [{
                                    value: fitData.eventData.temperature,
                                    sequence: 1
                                }]
                            });
                        }

                        if (fitData.eventData.pressure !== undefined && fitData.eventData.pressure !== null && tyre != null) {
                            tyre.readings.readings.push({
                                readingEventTypeName: "pressure",
                                isNewMeter: false,
                                unitOfMeasureAbbreviation: units.pressure.unitAbbreviation,
                                values: [{
                                    value: fitData.eventData.pressure,
                                    sequence: 1
                                }]
                            });
                        }

                        if (fitData.eventData.torque !== undefined && fitData.eventData.torque !== null && rim != null) {
                            rim.readings.readings.push({
                                readingEventTypeName: "torque",
                                isNewMeter: false,
                                unitOfMeasureAbbreviation: units.torque.unitAbbreviation,
                                values: [{
                                    value: fitData.eventData.torque,
                                    sequence: 1
                                }]
                            });
                        }

                        let command = {
                            checkInEventId: session.checkin.eventId,
                            components: components
                        };

                        return amtCommandQuerySvc.post(componentUrls.fitComponents, command).then(function (result) {

                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }

                            fitData.events = result.data.events;
                            fitData.newAssemblyId = result.data.assemblyId;

                            return result.data.events;

                        });
                    }
                },

                updateFitmentData: function (session, positionIds, equipmentTypesToClear) {

                    var vehicle = session.vehicle;

                    if (equipmentTypesToClear) {

                        for (var key in vehicle.positions) {

                            if (vehicle.positions.hasOwnProperty(key) && positionIds.indexOf(key) > -1) {

                                var pos = vehicle.positions[key];

                                pos.fittedInThisSession = false;

                                if (equipmentTypesToClear.some(et => et.toLowerCase() === 'tyre')) {

                                    let vehPosRead = vehicle.positionReadings.find(positionReading => positionReading.positionId === key);

                                    if (vehPosRead) {
                                        vehPosRead.rtd1 = null;
                                        vehPosRead.rtd2 = null;
                                        vehPosRead.rcd1 = null;
                                        vehPosRead.rcd2 = null;
                                        vehPosRead.nitrogen = null;
                                        vehPosRead.pressure = null;
                                        vehPosRead.adjPressure = null;
                                        vehPosRead.temp = null;
                                        vehPosRead.visualInspections = null
                                    }

                                }

                                equipmentTypesToClear.forEach(et => {
                                    let type = et.toLowerCase();

                                    let fitmentIndex = _.findIndex(pos.fitments, f => f.type.toLowerCase() === type);

                                    if (fitmentIndex > -1) {
                                        pos.fitments.splice(fitmentIndex, 1);
                                    }

                                    if (type === 'tyre') {
                                        pos.TyreFitment = undefined;
                                    } else if (type === 'rim') {
                                        pos.RimFitment = undefined;
                                    } else if (type === 'chain') {
                                        pos.ChainFitment = undefined;
                                    }
                                });
                            }
                        }

                        return $q.resolve();

                    } else {

                        let equipmentIds = {};

                        if (session.heldInError || session.pending) {
                            for (var key in vehicle.positions) {
                                if (vehicle.positions.hasOwnProperty(key) && positionIds.indexOf(key) > -1) {
                                    equipmentIds[key] = vehicle.positions[key].fitments.map(f => { return f.equipmentId; });
                                }
                            }
                        }

                        let positions = positionIds
                            .filter(p => (!session.heldInError && !session.pending) || ((session.heldInError || session.pending) && equipmentIds[p] && equipmentIds[p].length > 0))
                            .map(p => ({ positionId: p, equipmentIds: equipmentIds[p] }));

                        // populate the readings in the background for performance
                        return amtCommandQuerySvc.post('equipment/vehicle/getFitmentData', {
                            positions: positions,
                            date: (ocDateSvc.removeLocalTimeZoneOffset(session.checkout.checkoutDate) || null),
                            readingDateFrom: (ocDateSvc.removeLocalTimeZoneOffset(session.checkin.checkinDate) || null)
                        }).then(function (data) {

                            for (let key in vehicle.positions) {

                                if (vehicle.positions.hasOwnProperty(key) && positionIds.indexOf(key) > -1) {

                                    let pos = vehicle.positions[key];

                                    pos.fitments = [];

                                    let vehPosRead = vehicle.positionReadings.find(positionReading => positionReading.positionId === key);

                                    let posData = data.filter(d => d.positionId == key);

                                    pos.assemblyId = posData && posData.length > 0 ? posData[0].assemblyId : null;

                                    let tyreFitmentData = _.find(data, d => { return d.positionId == key && d.type.toLowerCase() === 'tyre'; });

                                    if (tyreFitmentData) {

                                        pos.TyreFitment = tyreFitmentData;
                                        pos.fitments.push(tyreFitmentData);

                                        if (tyreFitmentData.status.dateFrom > (session.checkin.checkinDate || session.checkin.eventDate) || tyreFitmentData.status.name !== 'FITTED' || _.any(session.events.items, e => { return e.position.id === key; })) {

                                            pos.fittedInThisSession = true;

                                            if (vehPosRead) {
                                                vehPosRead.rtd1 = null;
                                                vehPosRead.rtd2 = null;
                                                vehPosRead.rcd1 = null;
                                                vehPosRead.rcd2 = null;

                                                if (!session.heldInError && !session.pending) {
                                                    vehPosRead.nitrogen = (tyreFitmentData.sessionReadings && tyreFitmentData.sessionReadings.nitrogen ? tyreFitmentData.sessionReadings.nitrogen.values[0] : null);
                                                    vehPosRead.pressure = (tyreFitmentData.sessionReadings && tyreFitmentData.sessionReadings.pressure ? tyreFitmentData.sessionReadings.pressure.values[0] : null);
                                                    vehPosRead.adjPressure = (tyreFitmentData.sessionReadings && tyreFitmentData.sessionReadings.pressure ? tyreFitmentData.sessionReadings.pressure.values[1] : null);
                                                    vehPosRead.temp = (tyreFitmentData.sessionReadings && tyreFitmentData.sessionReadings.temperature ? tyreFitmentData.sessionReadings.temperature.values[0] : null);
                                                }
                                            }
                                        } else {
                                            pos.fittedInThisSession = false;
                                        }

                                        if (vehPosRead && tyreFitmentData.activeVisualInspections) {
                                            if (!vehPosRead.visualInspections?.length || !(session.heldInError || session.pending)) {
                                                vehPosRead.visualInspections = tyreFitmentData.activeVisualInspections;
                                            } else {                                                

                                                let ids = vehPosRead.visualInspections?.map(v => v.id) || [];
                                                let additionalInspections = tyreFitmentData.activeVisualInspections.filter(v => !ids.some(i => v.id === i));

                                                for (let inspection of additionalInspections || [])
                                                    vehPosRead.visualInspections.push(inspection);
                                            }
                                        }

                                    } else {

                                        pos.TyreFitment = undefined;
                                        pos.fittedInThisSession = false;

                                        if (vehPosRead) {
                                            vehPosRead.rtd1 = null;
                                            vehPosRead.rtd2 = null;
                                            vehPosRead.rcd1 = null;
                                            vehPosRead.rcd2 = null;
                                            vehPosRead.nitrogen = null;
                                            vehPosRead.pressure = null;
                                            vehPosRead.adjPressure = null;
                                            vehPosRead.temp = null;
                                            vehPosRead.visualInspections = null
                                        }
                                    }

                                    let rimFitmentData = _.find(data, d => { return d.positionId == key && d.type.toLowerCase() == 'rim'; });

                                    if (rimFitmentData) {
                                        pos.RimFitment = rimFitmentData;
                                        pos.fitments.push(rimFitmentData);
                                    } else {
                                        pos.RimFitment = undefined;
                                    }

                                    let chainFitmentData = _.find(data, d => { return d.positionId == key && d.type.toLowerCase() == 'chain'; });

                                    if (chainFitmentData) {
                                        pos.ChainFitment = chainFitmentData;
                                        pos.fitments.push(chainFitmentData);
                                    } else {
                                        pos.ChainFitment = undefined;
                                    }
                                }
                            }

                            vehicle.updated = uuid();

                        }, amtCommandQuerySvc.handleError);
                    }
                },

                msUndoCheckin: function (equipmentEventId: guid) {

                    let data = [{ equipmentEventId: equipmentEventId }];

                    return amtCommandQuerySvc.post(componentUrls.undoMoveEvents, data).then(
                        function (result) {
                            return result.data;
                        });
                },
                msUpdateCheckIn: function (checkinData) {
                    return amtCommandQuerySvc.post(vehicleUrls.checkin, checkinData).then(
                        function (result) {
                            return result.data;
                        });
                },
                msUndoCheckOut: function (eventId) {
                    var data = [{ equipmentEventId: eventId }];

                    return amtCommandQuerySvc.post(componentUrls.undoMoveEvents, data).then(
                        function (result) {
                            return result.data;
                        });
                },
                msUpdateCheckOut: function (checkoutData) {
                    return amtCommandQuerySvc.post(vehicleUrls.checkout, checkoutData).then(
                        function (result) {
                            return result.data;
                        });
                },
                msCheckinEvent: function (checkinData) {
                    return amtCommandQuerySvc.post(vehicleUrls.checkin, checkinData).then(
                        function (result) {
                            return result.data;
                        });
                },
                msCheckoutEvent: function (checkoutData) {
                    return amtCommandQuerySvc.post(vehicleUrls.checkout, checkoutData).then(
                        function (result) {
                            return result.data;
                        });
                },
                msDeleteSession: function (session, id) {
                    return amtCommandQuerySvc.post(vehicleUrls.deleteMaintenanceSession, { id: id }).then(
                        function (result) {
                            return result.data;
                        });
                },
                getFleetSpeed: function (vehicleId) {
                    let criteria = { vehicleId: vehicleId };
                    return amtCommandQuerySvc.post(vehicleUrls.getFleetSpeed, criteria).then(
                        function (response) {
                            return response.data[0];
                        });
                },
                async getVehicleDetails(vehicleId, surveyDate) {

                    let criteria = {
                        equipmentId: vehicleId,
                        date: surveyDate
                    };

                    let visualInspectionCriteria = {
                        vehicles: [{
                            equipmentId: vehicleId,
                            date: surveyDate ? surveyDate : ocDateSvc.dateTimeAsUTC(ocDateSvc.now()) // always wants datetime and will use server now if we pass null
                        }]
                    };

                    // get the visual inspections - start this early because I don't need to wait for the Vehicle data to start
                    let visualInspections;

                    try {
                        visualInspections = await amtCommandQuerySvc.post(fieldSurveyUrls.getFieldSurveyVisualInspection, visualInspectionCriteria);
                    } catch (error) {
                        errorReporter.logError(error);
                        return;
                    };

                    let vehicle = await amtCommandQuerySvc.post(fieldSurveyUrls.getVehicleForFieldSurvey, criteria);

                    if (!vehicle)
                        throw amtXlatSvc.xlat('exception.surveyVehicleFail', $filter<DateFormatFilter>('dateFormat')(criteria.date) );

                    vehicle.positions = schematicToPositions(vehicle.specification);

                    // add a reading per position regardless of the fitments.
                    addNewReadings(vehicle, vehicle.positions);

                    // take the returned visual inspections and hook them on to the reading
                    if (visualInspections) {

                        for (let i = 0; i < visualInspections.length; i++) {
                            if (visualInspections[i]) {
                                let current = vehicle.positionReadings[i];
                                current.visualInspections = visualInspections[i].visualInspections;
                            }
                        }
                    }

                    // last vehicle reading                            
                    vehicle.priorReadings = {
                        'DISTANCE': vehicle.lastDistanceReading,
                        'HOURS': vehicle.lastHoursReading
                    };

                    updateVehicleStats(vehicle);

                    let tyres = [];
                    let chains = [];

                    for (let key in vehicle.positions) {
                        if (vehicle.positions.hasOwnProperty(key)) {
                            if (vehicle.positions[key].TyreFitment) {
                                tyres.push(vehicle.positions[key].TyreFitment.componentId);
                                // used for field survey to indicate readings are loading
                                //   on the field survey fitments page
                                vehicle.positions[key].TyreFitment.loading = true;

                            }
                            if (vehicle.positions[key].ChainFitment) {
                                chains.push(vehicle.positions[key].ChainFitment.componentId);
                                // used for field survey to indicate readings are loading
                                //   on the field survey fitments page
                                vehicle.positions[key].ChainFitment.loading = true;
                            }
                        }
                    }

                    let fitmentDate = surveyDate || ocDateSvc.removeLocalTimeZoneOffset(new Date());

                    // populate the readings in the background for performance
                    let readings = await amtCommandQuerySvc.post('equipment/vehicle/getVehicleReadings', {
                        type: 'TREADDEPTH',
                        fitments: tyres,
                        date: fitmentDate
                    });

                    for (let key in vehicle.positions) {
                        if (vehicle.positions.hasOwnProperty(key)) {
                            if (vehicle.positions[key].TyreFitment) {
                                let id = vehicle.positions[key].TyreFitment.componentId;
                                vehicle.positions[key].TyreFitment.loading = false;
                                vehicle.positions[key].TyreFitment.remainingDepth = readings[id];
                            }
                        }
                    }

                    vehicle.updated = uuid();

                    if (chains.length > 0) {

                        let readings = await amtCommandQuerySvc.post('equipment/vehicle/getVehicleReadings', {
                            type: 'CHAINDEPTH',
                            fitments: chains,
                            date: fitmentDate
                        });

                        for (let key in vehicle.positions) {
                            if (vehicle.positions.hasOwnProperty(key)) {
                                if (vehicle.positions[key].ChainFitment) {
                                    let id = vehicle.positions[key].ChainFitment.componentId;
                                    vehicle.positions[key].ChainFitment.loading = false;
                                    vehicle.positions[key].ChainFitment.remainingDepth = readings[id];
                                }
                            }
                        }

                        vehicle.updated = uuid();
                    }

                    return vehicle;
                },
                logError: function (errors, platform?) {
                    if (!angular.isArray(errors)) {
                        errors = [errors]; // make it an array
                    }

                    return amtCommandQuerySvc.post('error/recordErrors', {
                        clientErrors: errors,
                        siteId: ocConfigSvc.user ? ocConfigSvc.user.site.id : null,
                        personId: ocConfigSvc.user ? ocConfigSvc.user.personId : null,
                        platform: platform ? platform : "Desktop",
                        applicationVersion: window.appVersion,
                        clientDetail: navigator.userAgent
                    }, true /* don't log to server if the error fails to log */
                    ).catch(function (error) {
                        console.error('Error could not be sent to server');
                        console.error(error);
                    });
                },
                uploadErrors: function () {
                    // not implemented for desktop
                },
                logAuditEntry: function () {
                    // not implemented for desktop
                },
                uploadAudits: function () {
                    // not implemented for desktop
                },
                saveMaintenanceSession: function (vehicle) {
                    console.warn('Desktop saving is not implemented.');
                    return $q.resolve();
                },
                getComponentBySerialNumber:
                    function (componentType, serialNumber, specificationId, date) {
                        return $q(function (resolve, reject) {

                            var criteria = {
                                componentTypeName: componentType,
                                serialNumber: serialNumber,
                                equpmentTypeSpecificationTypeId: specificationId,
                                atDate: date
                            };

                            amtCommandQuerySvc.get('equipment/component/getComponentBySerialNumber', criteria).then(
                                function (response) {

                                    if (response.status !== 200) {
                                        return reject();
                                    }

                                    var components = response.data;

                                    if (!components || components.length === 0) {
                                        return resolve();
                                    }

                                    if (specificationId) {
                                        // prefer matching specification
                                        for (var c = 0; c < components.length; c++) {
                                            if (components[c].equipmentTypeSpecificationTypeId === specificationId) {
                                                return resolve(components[c]);
                                            }
                                        }
                                    }

                                    if (components.length === 1) {
                                        return resolve(components[0]);
                                    }

                                    return reject(amtXlatSvc.xlat('stocktake.multipleMatchingComponentsFound'));
                                }, reject
                            );
                        });
                    },
                loadMaintenanceSession: function (vehicleId, sessionId, date) {
                    return amtCommandQuerySvc.post('maintenanceSession/getMaintenanceSession',
                        { vehicleId: vehicleId, sessionIds: (sessionId ? [sessionId] : []), date: date }).then(
                            function (response) {
                                return mapMaintenanceSession(response);
                            });
                },
                getSchematic: function (searchCriteria, vehicleObject) {
                    return amtCommandQuerySvc.post(vehicleUrls.getSchematic, searchCriteria)
                        .then(function (response) {
                            return adjustVehiclePositions(response, vehicleObject);
                        });
                },
                saveFieldSurvey: function (vehicle) {
                    //not implemented / implementation is a no-op
                    return $q.resolve();
                },
                deleteFieldSurvey: function (vehicleId, pendingFieldSurveyId) {
                    return amtCommandQuerySvc
                        .post('fieldSurvey/deleteFieldSurvey', { pendingFieldSurveyId: pendingFieldSurveyId }).then(
                            function (result) {
                                if (result.errors && result.errors.length > 0) {
                                    return $q.reject({ data: result });
                                }
                                return $q.resolve();
                            });
                },
                getFieldSurveySettings: function () {
                    //nothing to do, right now on desktop we just have no settings
                    return $q.resolve();
                },
                updateSystemSettings: function () {
                    throw new Error('Unimplemented for desktop');
                },
                loginExternal: function (sessionKey) {
                    //console.log('loginExternal');
                    return $http.post<any>(baseUrl + 'login/LoginOtracomExternal', { sessionKey: sessionKey })
                        .then(function (response) {
                            //console.log('External system login to server');
                            if (!response.data) {
                                console.error("External login response contained no data");
                                return $q.reject(amtXlatSvc.xlat('login.InvalidExternalLogin'));
                            }
                            if (response.status !== 200) {
                                console.error("External login response was http:" + response.status);
                                return $q.reject(amtXlatSvc.xlat('login.InvalidExternalLogin'));
                            }
                            if (response.data.status === enums.httpUpdateStatus.fail) {
                                console.error("Error in external login");
                                for (var i = 0; i < response.data.errors.length; i++) {
                                    console.error(response.data.errors[i].Message);
                                }
                                return $q.reject(amtXlatSvc.xlat('login.InvalidExternalLogin'));
                            }
                            authService.credentials = {
                                token: response.data.access_token,
                                username: response.data.user,
                                refresh_token: response.data.refresh_token,
                                token_expires: response.data[".expires"]
                            };
                            return response;
                        });
                },
                refreshToken: function (forceRefresh, refreshSite) {
                    // desktop
                    let credentials = authService.credentials;

                    if (credentials.token == null) {
                        return $q.reject("Refresh token called but no token");
                    }

                    // check that the expiry date on the current token will expire in the next three minutes                            
                    if (!forceRefresh) {
                        const RefreshWith3MinutesOfExpiry = 3 * 60 * 1000;
                        if (Date.parse(credentials.token_expires) > RefreshWith3MinutesOfExpiry + Date.now()) {
                            return $q.resolve();
                        }
                    }

                    let data = `grant_type=refresh_token&refresh_token=${credentials.refresh_token}&client_id=${ocConfigSvc.client}&refreshSite=${(new Boolean(!!refreshSite)).toString()}`;

                    var deferred = $q.defer();
                    $http.post<any>(baseUrl + 'token',
                        data,
                        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
                        .then(function (response) {
                            if (response.status !== 200) {
                                // we don't test the local login because they successfully went online here and got the credentials wrong.
                                // perhaps the password changed ? Or perhaps they just got it wrong. ?
                                if (response.status >= 400 && response.status <= 403) {
                                    return deferred.reject(amtXlatSvc.xlat('login.InvalidLoginAttempt'));
                                }
                                if (response.status === -1) {
                                    return deferred.reject(amtXlatSvc.xlat('login.NetworkError'));
                                }
                                return deferred.reject(response.statusText || response.status);
                            }

                            //console.log('Successfully refreshed token.');

                            //store credentials
                            authService.credentials = {
                                token: response.data.access_token,
                                username: credentials.username,
                                refresh_token: response.data.refresh_token,
                                token_expires: response.data[".expires"],
                                password: credentials.password // this is for the mobile SSO, so they can walk away and come back
                            };

                            deferred.resolve(response);
                        }).catch(function (error) {
                            console.error('Error while logging into server.');
                            console.error(error);
                            deferred.reject(error);
                        });
                    return deferred.promise;
                },
                login: function (username, password, denyTestLocal, siteId) {
                    // desktop server only login
                    if (!password || !username) {
                        return $q.reject(['missing_fields', amtXlatSvc.xlat('login.InvalidLoginAttempt')]);
                    }
                    // try online
                    // want to share the normal controller, so can't use the amtCommandQuerySvc
                    var data = 'grant_type=password&username=' +
                        username +
                        '&password=' +
                        password +
                        '&client_id=' + ocConfigSvc.client;
                    data = data + '&site=' + siteId;
                    var deferred = $q.defer();
                    $http.post<any>(baseUrl + 'token', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
                        .then(function (response) {
                            if (response.status !== 200) {
                                // we don't test the local login because they successfully went online here and got the credentials wrong.
                                // perhaps the password changed ? Or perhaps they just got it wrong. ?
                                if (response.status >= 400 && response.status <= 403) {
                                    let translationKey = 'login.' + response.data.error; // replace the detailed message with a translated generic message if we can
                                    let translatedMsg = amtXlatSvc.xlat(translationKey);
                                    // replace the detailed message with a translated generic
                                    let msg = translatedMsg && translatedMsg !== translationKey ? translatedMsg : response.data.error_description;
                                    console.error(response.data.error + ":" + response.data.error_description);
                                    return deferred.reject([response.data.error, msg]);
                                }
                                if (response.status === -1) {
                                    return deferred.reject(['network', amtXlatSvc.xlat('login.NetworkError')]);
                                }
                                return deferred.reject(['generic_error', response.statusText || response.status]);
                            }
                            //console.log('Successfully logged into server.');
                            //store credentials
                            authService.credentials = {
                                token: response.data.access_token,
                                username: username,
                                refresh_token: response.data.refresh_token,
                                token_expires: response.data[".expires"]
                            };

                            deferred.resolve(response);
                        }).catch(function (error) {
                            console.error('Error while logging into server.');
                            deferred.reject(['error', error]);
                        });
                    return deferred.promise;
                },


                getSession: async function (refresh, siteId) {
                    // desktop
                    let response = await amtCommandQuerySvc.post(amtConstants.url.getUserSession,
                        { userName: authService.credentials.username, siteId: siteId, mobile: vm.isMobile });

                    await setCurrentLanguageFrom();

                    return response;
                },
                
                resetPassword: function (email, password, resetToken) {
                    return $http.post(baseApiUrl + 'login/ResetPassword',
                        {
                            email: email,
                            password: password,
                            resetToken: resetToken
                        });
                },
                getStocktakeComponentTypes: function () {
                    return remote.getComponentTypes();
                },
                getComponentTypes: function (siteId?) {
                    return amtCommandQuerySvc.post('equipment/component/getComponentTypes', { siteIds: [siteId] })
                        .then(function (result) {
                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }
                            return result;
                        });
                },
                getStatuses: function (componentTypeId) {
                    return $http
                        .get<any>(baseUrl + 'api/equipment/component/getStatuses?componentTypeId=' + componentTypeId)
                        .then(function (result) {
                            if (result && result.data && result.data.errors) {
                                return $q.reject(result);
                            }
                            return result.data;
                        });
                },
                getEquipmentStatusAtDate: function (equipmentId, date, type) {

                    return amtCommandQuerySvc.get('equipment/common/getEquipmentStatusAtDate', { equipmentId: equipmentId, date: ocDateSvc.dateTimeAsUTC(date) }).then(function (result) {
                        return result;
                    }, amtCommandQuerySvc.handleError);
                },
                getSpecificationDetails: function (specificationId, date) {

                    return amtCommandQuerySvc
                        .post('equipment/component/getSpecificationDetails',
                            { equipmentTypeSpecificationTypeId: specificationId, date: date }).then(
                                function (result) {
                                    if (result.errors && result.errors.length > 0) {
                                        return $q.reject({ data: result });
                                    }
                                    return result;
                                });
                },
                getVehicleSpecificationDetails: function (specificationId) {

                    return amtCommandQuerySvc
                        .post('equipment/vehicle/getSpecificationDetails',
                            { equipmentTypeSpecificationTypeId: specificationId }).then(
                                function (result) {
                                    if (result.errors && result.errors.length > 0) {
                                        return $q.reject({ data: result });
                                    }
                                    return result;
                                });
                },
                getCostTypes: function (criteria) {
                    return amtCommandQuerySvc.post('referenceData/getCostTypes', criteria || {}).then(function (result) {
                        if (result.errors && result.errors.length > 0) {
                            return $q.reject({ data: result });
                        }
                        result.result.sort((a, b) => a.name.localeCompare(b.name, 'en'));
                        return result;
                    });
                },
                getPendingComponents: function (siteId,
                    equipmentTypeId,
                    includeFittedOnly,
                    excludeReceived,
                    checkVehicleExists) {
                    return amtCommandQuerySvc.post('equipment/component/getPendingComponents',
                        {
                            siteId: siteId,
                            equipmentTypeId: equipmentTypeId,
                            includeFittedOnly: includeFittedOnly,
                            checkVehicleExists: checkVehicleExists
                        }).then(function (result) {
                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }
                            return { result: result };
                        });
                },
                getSpecifications: function (criteria, forVehicle) {
                    return amtCommandQuerySvc.post('equipment/' + (forVehicle ? 'vehicle' : 'component') + '/getSpecifications', criteria).then(
                        function (result) {
                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }
                            result.result.forEach(function (spec) {
                                spec.id = spec.key;
                            });
                            return result;
                        });
                },
                getUnfulfilledPurchaseOrders: async function (componentTypeId: string, componentTypeName: string) {
                    let criteria = { "componentTypeId": componentTypeId, "unfulfilledOnly": true };

                    return (await amtCommandQuerySvc.post('equipment/common/listPurchaseOrders', criteria)).result;
                },
                getSpecificationsForPurchaseOrder: async function (id, componentTypeId) {
                    let criteria = { purchaseOrderId: id, componentTypeId: componentTypeId, unfulfilledOnly: true };

                    return (await amtCommandQuerySvc.get('equipment/common/getPurchaseOrderSpecifications', criteria)).data.result;
                },
                getPressureSensors: function (criteria: IPressureSensorCriteria) {
                    return amtCommandQuerySvc.get('equipment/component/getPressureSensors', criteria).then(function (result) {
                        if (result.errors && result.errors.length > 0) {
                            return $q.reject({ data: result.data });
                        }
                        return { result: result.data };
                    });
                },
                getProductionCrews: function (criteria: IProductionCrewCriteria) {
                    return amtCommandQuerySvc.post('referenceData/getProductionCrews', criteria).then(
                        function (result) {
                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }
                            return result;
                        });
                },
                getMaintenanceTypes: function (criteria) {
                    return amtCommandQuerySvc.post('referenceData/getMaintenanceTypes', criteria).then(
                        function (result) {
                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }
                            return result;
                        });
                },
                getDowntimeTypes: function (criteria) {
                    return amtCommandQuerySvc.post('referenceData/getDowntimeTypes', criteria).then(
                        function (result) {
                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }
                            return result;
                        });
                },
                getRemovalReasons: function (criteria: IRemovalReasonCriteria) {
                    return amtCommandQuerySvc.post('referenceData/getRemoveReasons', criteria).then(
                        function (result) {
                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }
                            return result;
                        });
                },
                getFitters: function (criteria) {
                    return amtCommandQuerySvc.post('referenceData/getFitters', criteria).then(function (result) {
                        if (result.errors && result.errors.length > 0) {
                            return $q.reject({ data: result });
                        }
                        return result;
                    });
                },
                getDamageCauses: function (criteria: IDamageCauseCriteria) {
                    return amtCommandQuerySvc.post('referenceData/getDamageCauses', criteria).then(
                        function (result) {
                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }
                            return result;
                        });
                },
                getDamageSources: function (criteria: IDamageSourceCriteria) {
                    return amtCommandQuerySvc.post('referenceData/getDamageSources', criteria).then(
                        function (result) {
                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }
                            return result;
                        });
                },
                getDamageLocations: function (criteria: IDamageLocationCriteria) {
                    return amtCommandQuerySvc.post('referenceData/getDamageLocations', criteria).then(
                        function (result) {
                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }
                            return result;
                        });
                },
                getWorkshopTimes: function (criteria: IWorkshopTimesCriteria) {
                    return amtCommandQuerySvc.post('referenceData/getWorkshopSessionTimeTypes', criteria).then(
                        function (result) {
                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }
                            result.result = result.result.filter(r => r.category === criteria.category);
                            return result;
                        });
                },
                getStatusFlows: function (criteria: IStatusFlowCriteria) {
                    return amtCommandQuerySvc.post('referenceData/getStatusFlows', criteria).then(function (result) {
                        return result;
                    });
                },
                getLocations: function (criteria: ILocationCriteria) {
                    return amtCommandQuerySvc.post('equipment/component/getLocations', criteria).then(
                        function (result) {
                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }
                            return result;
                        });
                },
                receiveUnknownComponents: function (id, command) {
                    return amtCommandQuerySvc.post('equipment/component/receiveUnknownComponents', command).then(
                        function (result) {
                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }
                            return $q.resolve();
                        });
                },
                receiveKnownComponents: function (id, command) {
                    return amtCommandQuerySvc.post('equipment/component/receiveKnownComponents', command).then(
                        function (result) {
                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }
                            return $q.resolve();
                        });
                },
                receivePendingComponents: function (id, command) {
                    return amtCommandQuerySvc.post('equipment/component/receivePendingComponents', command).then(
                        function (result) {
                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }
                            return $q.resolve();
                        });
                },
                getUnknownComponentReceiveDestinationStatusTypes: function (criteria) {
                    return amtCommandQuerySvc.post('referenceData/getUnknownComponentReceiveDestinationStatusTypes', criteria != null ? criteria : {});
                },
                // TODO: Make this method take in receival type. It has a "IsComponent" property for exactly this purpose.
                getPendingReceivals: function (type, siteId) { // This returns receivals that failed to sync. Mobile is different.
                    return amtCommandQuerySvc
                        .post('equipment/component/getErroredReceivals', { isComponent: (type == 'components'), siteId: siteId })
                        .then(function (result) {

                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }

                            // filter out vehicles and set/translate equipment types
                            var data = result.reduce(function (result, rec) {

                                if (rec.data && angular.isArray(rec.data.components) && rec.data.components.length > 0) {

                                    // map the type names;
                                    rec.data.components.map(function (comp) {
                                        comp.equipmentTypeDescription = comp.equipmentTypeName ? amtXlatSvc.xlat('equipment.equipmentType' + comp.equipmentTypeName) : "";
                                        comp.eventDetails.eventDate = ocDateSvc.addLocalTimeZoneOffset(comp.eventDetails.eventDate);
                                        comp.receiveDate = comp.receiveDate !== undefined || comp.receiveDate !== null ? ocDateSvc.addLocalTimeZoneOffset(comp.receiveDate) : undefined
                                        return comp;
                                    });;

                                    result.push(rec);

                                } else {

                                    if (!rec.data && !rec.data.vehicles) {
                                        console.error("Error in pending receival data:");
                                        console.error(rec.data);
                                    }

                                }
                                return result;
                            }, []);

                            return { data: data };
                        });
                },
                deletePendingReceival: function (id) {
                    return $http.get(baseUrl + 'api/equipment/common/deletePendingReceival/' + id);
                },
                receiveUnknownVehicles: function (id, command) {
                    return amtCommandQuerySvc.post('equipment/vehicle/receiveUnknownVehicles', command).then(
                        function (result) {
                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }
                            return $q.resolve();
                        });
                },
                receiveKnownVehicles: function (id, command) {
                    return amtCommandQuerySvc.post('equipment/vehicle/receiveKnownVehicles', command).then(
                        function (result) {
                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }
                            return $q.resolve();
                        });
                },
                receivePendingVehicles: function (id, command) {
                    return amtCommandQuerySvc.post('equipment/vehicle/receivePendingVehicles', command).then(
                        function (result) {
                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }
                            return $q.resolve();
                        });
                },
                getUnitsOfMeasure: function (baseReadingUnit) {
                    return amtCommandQuerySvc
                        .post('referenceData/getUnitsOfMeasure', { baseReadingUnit: baseReadingUnit }).then(
                            function (result) {
                                if (result.errors && result.errors.length > 0) {
                                    return $q.reject({ data: result });
                                }
                                return result;
                            });
                },
                getPendingVehicles: function (siteId, excludeReceived) {
                    return amtCommandQuerySvc.post('equipment/vehicle/getPendingVehicles', { siteId: siteId, excludeReceived: excludeReceived }).then(
                        function (result) {
                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }
                            return { result: result };
                        });
                },
                deletePendingVehicle: function (pendingVehicleId) {
                    return amtCommandQuerySvc
                        .post('equipment/vehicle/deletePendingVehicle', { id: pendingVehicleId }).then(
                            function (result) {
                                if (result.errors && result.errors.length > 0) {
                                    return $q.reject({ data: result });
                                }
                                return $q.resolve();
                            });
                },
                getIncompleteWheelPositionStatusTypes: function () {
                    return amtCommandQuerySvc.get("maintenanceSession/getIncompleteWheelPositionStatusTypes", {}).then(
                        function (result) {
                            if (result.data && result.data.errors && result.data.errors.length > 0) {
                                return $q.reject({ data: result });
                            }
                            return { result: result.data.result };
                        });
                },
                recordMaintenanceSessionReadings: function (checkInEquipmentEventId, readings) {
                    return amtCommandQuerySvc.post("maintenanceSession/recordMaintenanceSessionReadings", {
                        checkInEquipmentEventId: checkInEquipmentEventId,
                        readings: readings
                    }).then(function (result) {
                        if (result.errors && result.errors.length > 0) {
                            return $q.reject({ data: result });
                        }
                        return $q.resolve();
                    });
                },
                getComponentOwners: function (clientId) {
                    return amtCommandQuerySvc.post("systemhierarchy/getComponentOwners", { clientIds: [clientId] }).then(function (result) {
                        if (result.errors && result.errors.length > 0) {
                            return $q.reject({ data: result });
                        }
                        return { result: result.result };
                    });
                },
                /**
                 * Get all the Components of a given Type in a particular Status.
                 * @param {string} siteId - Guid of Site to get Components for.
                 * @param {string} status - Status to filter on (Transferred/Retreaded/etc.)
                 * @param {string} [componentTypeId] - Type to filter on. Server assumes Tyre if not set.
                 * @param {date} date - Date when components should be in status.
                 * @returns {Object[]} 
                 */
                getComponentsInStatus: function (clientId, siteId, statuses, componentTypeIds, componentTypeNames, date, retrieveDetails, excludeReceived) {
                    return amtCommandQuerySvc
                        .post("equipment/component/getComponentsInStatus", {
                            clientId: clientId,
                            siteId: siteId,
                            statuses: statuses,
                            componentTypeIds: componentTypeIds,
                            date: date,
                            retrieveComponentDetails: retrieveDetails
                        })
                        .then(function (result) {
                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }
                            return { result: result };
                        });
                },
                getVehiclesInStatus: function (clientId, siteId, statuses, date, retrievePositions) {
                    return amtCommandQuerySvc
                        .post("equipment/vehicle/getVehiclesInStatus", {
                            clientId: clientId,
                            siteId: siteId,
                            statuses: statuses,
                            date: date,
                            retrievePositions: retrievePositions
                        })
                        .then(function (result) {
                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }
                            return { result: result };
                        });
                },
                getVehiclePositionFitments: function (vehicleIds, siteId, date) {
                    return amtCommandQuerySvc
                        .post("equipment/vehicle/getVehiclePositionFitments", {
                            siteId: siteId,
                            vehicleIds: vehicleIds,
                            date: date
                        })
                        .then(function (result) {
                            if (result.errors && result.errors.length > 0) {
                                return $q.reject({ data: result });
                            }
                            return { result: result };
                        });
                },
                getRetreaders: function (criteria: IRetreaderCriteria) {
                    return amtCommandQuerySvc.post("referenceData/getRetreaders", criteria)
                        .then(response => ({ result: response.result }));
                },
                getRepairers: function (criteria: IRepairerCriteria) {
                    return amtCommandQuerySvc.post("referenceData/getRepairers", criteria)
                        .then(response => ({ result: response.result }));
                },
                getRepairTypes: async function (criteria: IRepairTypeCriteria) {
                    let response = await amtCommandQuerySvc.get("referenceData/getRepairTypes", criteria);
                    return { result: response.data.result };
                },
                getReturnToSupplierReasons: function (criteria: IReturnToSupplierReasonCriteria) {
                    return amtCommandQuerySvc.post("referenceData/getReturnToSupplierReasons", criteria).then(response => {
                        return { result: response.result };
                    });
                },
                getSuppliers: function (criteria: ISupplierCriteria) {
                    return amtCommandQuerySvc.post("referenceData/getSuppliers", criteria).then(response => {
                        return { result: response.result };
                    });
                },
                getReadingEventTypeComments: function (criteria) {
                    return amtCommandQuerySvc.post("referenceData/getReadingEventTypeComments", criteria)
                        .then(response => ({ result: response.result }));
                },                
                getCurrencyTypes: function (criteria: ICurrencyCriteria) {
                    return amtCommandQuerySvc.get("referenceData/getCurrencyTypes", criteria)
                        .then(response => ({ result: response.data.result }));
                },
                fieldSurveyCount: function () {
                    throw new Error("Unimplemented");
                },
                getPendingReceival: function () {
                    throw new Error("Unimplemented");
                },
                deleteReceiptedComponents: function (id) {
                    throw new Error("Unimplemented");
                },
                deleteReceiptedVehicles: function () {
                    throw new Error("Unimplemented");
                },
                changeComponentStatus: function (id, criteria) {
                    throw new Error("Unimplemented");
                },
                deletePendingStatusChanges: function (ids) {
                    throw new Error("Unimplemented");
                },
                getStatusChanges: function () {
                    throw new Error("Unimplemented");
                },
                getStocktake: function (criteria) {
                    return amtCommandQuerySvc.post("stocktake/getStocktake", criteria)
                        .then(response => ({ result: response }));
                },
                createStocktake: async function (criteria) {

                    var safeData = {
                        createdDate: ocDateSvc.removeLocalTimeZoneOffset(criteria.createdDate),
                        preferredLevel: criteria.preferredLevel,
                        componentType: criteria.componentType
                    };

                    let response = await amtCommandQuerySvc.post("stocktake/createStocktake", safeData)
                        .then(response => ({ result: response }));

                    response.result.createdDate = ocDateSvc.addLocalTimeZoneOffset(response.result.createdDate);
                    return response;
                },
                setStocktakeType: function () {
                    throw new Error("Unimplemented");
                },
                saveStocktake: function (criteria) {

                    var data = {
                        id: criteria.id,
                        createdDate: ocDateSvc.removeLocalTimeZoneOffset(criteria.createdDate),
                        preferredLevel: criteria.preferredLevel,
                        type: criteria.type,
                        components: criteria.components,
                        aggregates: criteria.aggregates,
                        siteId: criteria.siteId,
                        comments: criteria.comments
                    };

                    return amtCommandQuerySvc.post("stocktake/saveStocktake", data)
                        .then(response => ({ result: response }));
                },
                deleteStocktake: function (criteria) {
                    return amtCommandQuerySvc.post("stocktake/deleteStocktake", criteria)
                        .then(response => ({ result: response }));
                },
                getThumbnail: function (id: guid) {
                    return amtCommandQuerySvc.getById("file/getThumbnail", id);
                },
                getErrors: function () {
                    throw new Error("Unimplemented");
                },
            };
            // #endregion remote
            // #region shared
            var target = vm.isMobile ? local : remote;

            function testLocalLogin(username, passwordhash) {
                console.log('Performing local login');
                try {
                    return $db.profile.get(1).then(function (profile) {
                        console.log('Profile load.');
                        if (!profile) {
                            // there is no profile no one has logged in.
                            console.log("Profile was not loaded from DB");
                            return $q.reject(amtXlatSvc.xlat('login.LastUserOnly'));
                        }
                        if (!profile.userName || !username || profile.userName.toLowerCase() !== username.toLowerCase()) {
                            console.log("user name does not match profile : [" + profile.userName + "]");
                            return $q.reject(amtXlatSvc.xlat('login.LastUserOnly'));
                        }

                        if (profile.hash !== passwordhash) {
                            // well, never perfect as they could always just bypass this code.
                            return $q.reject(amtXlatSvc.xlat('login.InvalidLoginAttempt'));
                        }
                        return $q.resolve();
                    });
                } catch (error) {
                    console.log('Error.');
                    return $q.reject(amtXlatSvc.xlat('login.NoOfflineLogin'));
                }
            }

            function clearProfile(username) {
                $db.profile.get(1).then(function (profile) {
                    if (!profile) {
                        profile = { id: 1 };
                    }
                    profile.userName = username;
                    profile.hash = 'XX';
                    return $db.profile.put(profile);
                });
            }

            function forgottenPassword(email) {
                return $http.post(baseApiUrl + 'login/forgotPassword', { email: email, refererUrl: window.location.origin })
                    .then(response => {
                        console.log('Attempted password recovery');
                        return response.data ? response : $q.reject(amtXlatSvc.xlat('login.InvalidLoginAttempt'));
                    });
            }

            function sessionExpire(text: string = 'login.ReauthenticationRequired') {
                authService.clearPasswordAndToken();

                $rootScope.$broadcast('logout', false);
                $rootScope.loggingIn = true;
                //leave $rootScope.loggedIn still set to true

                ocConfigSvc.loginHeading = amtXlatSvc.xlat(text);
            }

            async function logout() {
                try {
                    WindowFactory.closeAllWindows(true);
                } catch {
                    console.log('Error/s closing windows on logout');
                }

                // desktop and mobile
                authService.clearPasswordAndToken();

                localStorage.removeItem("dundasSessionId");

                $rootScope.$broadcast('logout', true);

                await ocConfigSvc.clearUser();

                ocConfigSvc.loginMessage = amtXlatSvc.xlat('login.loggedout');
                ocConfigSvc.loginHeading = null;

                $rootScope.loggedIn = false;
                $rootScope.loggingIn = true;

                $timeout(() => {
                    // we want the login page to be rendered before we change state so the destination state is not initialised.
                    console.log('Logged out');
                    $state.go(vm.isMobile ? 'mobile.landing' : 'app.landing');
                });

                // tell the server
                $http.post(baseApiUrl + 'login/logout', null);
            }

            function getPressureReadingTypes() {

                let criteria = {
                    readingEventTypeName: ReadingTypeName.pressure
                };

                return target.getReadingEventTypeComments(criteria);
            }

            function getSession(refresh, siteId) {
                return target.getSession(refresh, siteId);
            }

            function updateVehicleStats(vehicle) {
                vehicle.lastDate = null;
                if (vehicle.priorReadings.DISTANCE) {
                    vehicle.lastDate = vehicle.priorReadings.DISTANCE.date;
                    vehicle.lastDistance = convert.convertTo(vehicle.priorReadings.DISTANCE, vehicle.distanceUnit.unitAbbreviation);
                }
                if (vehicle.priorReadings.HOURS) {
                    vehicle.lastDate = vehicle.priorReadings.HOURS.date;
                    vehicle.lastHours = vehicle.priorReadings.HOURS.value;
                }
                if (vehicle.priorReadings.DISTANCE && vehicle.priorReadings.HOURS) {
                    vehicle.lastDate = vehicle.priorReadings.HOURS.date < vehicle.priorReadings.DISTANCE.date ?
                        vehicle.priorReadings.DISTANCE.date : vehicle.priorReadings.HOURS.date;
                    vehicle.averageLast = (vehicle.priorReadings.DISTANCE.value / vehicle.priorReadings.HOURS.value);
                }
            }

            function lookupTyreReading(key, vehicle, session) {
                $timeout(function () {
                    $db.reading.where('equipmentId')
                        .equals(vehicle.positions[key].TyreFitment.componentId)
                        .filter(function (reading) { return reading.type === 'TREADDEPTH'; })
                        .sortBy('date').then(function (readings) {
                            vehicle.positions[key].TyreFitment.remainingDepth = readings[0];
                            vehicle.positions[key].TyreFitment.loading = false;
                            // need to save this async update
                            $db.maintenanceSession.put(session);
                        });
                });
            }

            function lookupChainReading(key, vehicle, session) {
                $timeout(function () {
                    $db.reading
                        .where('equipmentId')
                        .equals(vehicle.positions[key].TyreFitment.componentId)
                        .filter(function (reading) { return reading.type === 'CHAINDEPTH'; })
                        .sortBy('date').then(function (readings) {
                            vehicle.positions[key].ChainFitment.remainingDepth = readings[0];
                            vehicle.positions[key].ChainFitment.loading = false;
                            // need to save this async update
                            $db.maintenanceSession.put(session);
                        });
                });
            }

            function adjustVehiclePositions(response, vehicleObject) {
                if (!vm.isMobile || !vehicleObject.positions) {
                    return response;
                }

                // back push the positions
                // loop through the schematic axles and for each position replace the position with the matching position from the vehicle position data
                for (let axle of response.axles) {
                    let vehiclePositionAxleData = [];

                    for (let axlePos of axle.positions || []) {
                        let idOfPositionOnAxle = axlePos.id;
                        let positionDataFromVehicle = vehicleObject.positions[idOfPositionOnAxle];

                        if (!positionDataFromVehicle) {
                            // this means the positions on the vehicle are desktop ids.
                            // match it by reading sequence
                            for (let vehiclePos of vehicleObject.positions) {

                                if (vehiclePos.readingSequence !== axlePos.readingSequence)
                                    continue;

                                let pos = vehicleObject.positions[vehiclePos.id];

                                // move from vehiclePos.id to axlePos.id
                                vehicleObject.positions[axlePos.id] = pos;

                                // remove the desktop identified pos
                                delete vehicleObject.positions[vehiclePos.id];
                                console.warn("Vehicle position updated:  " + vehiclePos.id + " => " + axlePos.id);

                                // update fitments
                                for (let fitment of pos.fitments) {
                                    fitment.positionId = axlePos.id;
                                }

                                // and update the readings information
                                for (let posreading of vehicleObject.positionReadings || []) {
                                    if (posreading.positionId === vehiclePos.id) {
                                        posreading.positionId = axlePos.id;
                                    }
                                }
                            }
                            positionDataFromVehicle = vehicleObject.positions[axlePos.id];
                            positionDataFromVehicle.id = axlePos.id;
                        }
                        vehiclePositionAxleData.push(positionDataFromVehicle);
                    }

                    axle.positions = vehiclePositionAxleData;
                }
                return response;
            }

            function mapMaintenanceSession(response) {

                let vehicle = response.sessionData.currentVehicleSchematic;
                let session = {
                    futureEvents: response.futureEvents,
                    canBeDeleted: response.canBeDeleted,
                    checkedIn: (response.sessionData.checkIn != null),
                    checkedOut: (response.sessionData.checkOut != null),
                    schematicDate: undefined,
                    siteId: response.sessionData.siteId,
                    checkin: {
                        eventId: undefined,
                        checkinDate: undefined,
                        travelTime: undefined,
                        workOrderNumber: undefined,
                        maintenanceTypeId: undefined,
                        downtimeTypeId: undefined,
                        productionCrewId: undefined,
                        comment: undefined,
                        hours: undefined,
                        hoursNewMeter: undefined,
                        distance: undefined,
                        distanceNewMeter: undefined
                    },
                    checkout: {
                        eventId: undefined,
                        checkoutDate: undefined,
                        delays: undefined,
                        selectedFitters: undefined,
                        comment: undefined,
                        tyreRelatedWork: undefined,
                        hasNonRelatedTyreWork: undefined,
                        nonRelatedTyreWork: undefined,
                        newStatusTypeId: undefined,
                        transferDetails: undefined,
                        totalDowntime: 0,
                        eventDate: undefined
                    },
                    events: undefined,
                    assemblies: undefined,
                    vehicle: vehicle,
                    id: vehicle.id,
                    createdDate: undefined,
                    sessionId: undefined,
                    pristine: undefined,
                    errorList: undefined,
                    heldInError: undefined,
                    pending: undefined
                };

                session.vehicle.positions = schematicToPositions(session.vehicle.specification);
                session.vehicle.schematic = session.vehicle.specification;

                // add a reading per position regardless of the fitments.
                addNewReadings(vehicle, vehicle.positions);

                // last vehicle reading                            
                vehicle.priorReadings = {
                    'DISTANCE': vehicle.lastDistanceReading,
                    'HOURS': vehicle.lastHoursReading
                };

                if (vehicle.nextReading) {
                    vehicle.nextReadings = {
                        'DISTANCE': vehicle.nextDistanceReading,
                        'HOURS': vehicle.nextHoursReading
                    }
                }

                updateVehicleStats(vehicle);

                if (response.sessionData.readings) {

                    _.each(vehicle.positionReadings, function (posReading) {

                        let resReading = _.find(response.sessionData.readings, r => r.positionNo === posReading.positionNo);

                        posReading.pressure = (resReading ? resReading.pressure : null);
                        posReading.temp = (resReading ? resReading.temp : null);
                        posReading.adjPressure = (resReading ? resReading.adjPressure : null);
                        posReading.nitrogen = (resReading ? resReading.nitrogen : null);
                        posReading.rtd1 = (resReading ? resReading.rtd1 : null);
                        posReading.rtd2 = (resReading ? resReading.rtd2 : null);
                        posReading.visualInspections = (resReading ? resReading.visualInspections : null);
                        posReading.rcd1 = (resReading ? resReading.rcd1 : null);
                        posReading.rcd2 = (resReading ? resReading.rcd2 : null);

                        if (posReading.pressure !== undefined && posReading.pressure !== null) {
                            session.vehicle.readingEventTypeCommentId = resReading.readingEventTypeCommentId;
                        }

                        // get the fit event
                        for (let fmt of vehicle.positions[posReading.positionId].fitments) {
                            if (fmt.type.toLowerCase() === "tyre") {
                                // if the fit event date is after the checkin date, then the fitment occurred in this session and cannot be edited in the readings tab
                                vehicle.positions[posReading.positionId].fittedInThisSession = fmt.fitmentDate > (session.checkin.checkinDate || response.sessionData.checkIn.eventDate);
                            }
                        }
                    });
                }

                session.schematicDate = response.sessionData.latestSessionEventDate;

                session.errorList = response.sessionData.errors;
                session.heldInError = session.errorList && session.errorList.length > 0;
                session.pending = response.pending;

                if (session.checkedIn === true) {

                    session.checkin.eventId = response.sessionData.checkIn.equipmentEventId;
                    session.checkin.checkinDate = response.sessionData.checkIn.eventDate;
                    session.checkin.travelTime = response.sessionData.checkIn.checkInDetails.travelTimeToWorkshop;
                    session.checkin.workOrderNumber = response.sessionData.checkIn.checkInDetails.workOrderNumber;
                    session.checkin.maintenanceTypeId = response.sessionData.checkIn.checkInDetails.maintenanceTypeId;
                    session.checkin.downtimeTypeId = response.sessionData.checkIn.checkInDetails.downtimeTypeId;
                    session.checkin.productionCrewId = response.sessionData.checkIn.checkInDetails.productionCrewId;
                    session.checkin.comment = response.sessionData.checkIn.comment;

                    if (response.sessionData.checkIn.readings) {
                        let hoursReading = response.sessionData.checkIn.readings.readings.find(r => r.readingEventTypeName.toLowerCase() === "hours");

                        if (hoursReading) {
                            session.checkin.hours = hoursReading.values[0].value;
                            session.checkin.hoursNewMeter = hoursReading.isNewMeter;
                        }

                        let distanceReading = response.sessionData.checkIn.readings.readings.find(r => r.readingEventTypeName.toLowerCase() === "distance");

                        if (distanceReading) {
                            session.checkin.distance = distanceReading.values[0].value;
                            session.checkin.distanceNewMeter = distanceReading.isNewMeter;
                        }
                    }
                };

                if (session.checkedOut === true) {

                    session.checkout.eventId = response.sessionData.checkOut.equipmentEventId;

                    session.checkout.eventDate = response.sessionData.checkOut.eventDate;
                    session.checkout.checkoutDate = response.sessionData.checkOut.eventDate;

                    session.checkout.delays = [];
                    session.checkout.selectedFitters = response.sessionData.checkOut.checkOutDetails.fitters;
                    session.checkout.comment = response.sessionData.checkOut.comment;
                    session.checkout.newStatusTypeId = response.sessionData.checkOut.newStatusTypeId;
                    session.checkout.transferDetails = response.sessionData.checkOut.checkOutDetails.transferDetails;

                    if (response.sessionData.checkOut.checkOutDetails.workshopSessionTimes) {

                        for (let delay of response.sessionData.checkOut.checkOutDetails.workshopSessionTimes) {
                            switch (delay.name) {
                                case "TRW":
                                    session.checkout.tyreRelatedWork = delay.durationMinutes;
                                    break;
                                case "NTRW":
                                    session.checkout.hasNonRelatedTyreWork = true;
                                    break;
                                default:
                                    session.checkout.delays.push({
                                        minutes: delay.durationMinutes,
                                        lastModifiedDate: delay.lastModifiedDate,
                                        selectedDelayReason: {
                                            id: delay.workshopSessionTimeTypeId,
                                            description: delay.description
                                        }
                                    });
                            }
                        }
                    }

                    session.checkout.totalDowntime = setTotalDownTime(session.checkin.checkinDate, session.checkout.checkoutDate);
                };

                if (response.sessionData.events) {

                    session.events = { items: [] };

                    let groupedEvents = _.groupBy(response.sessionData.events, event =>
                        (event.asAssembly ? (event.assemblyId || uuid()) + '#' + event.eventType : uuid()));

                    let assembliesToRemove = [];

                    Object.keys(groupedEvents).forEach(function (geKey) {

                        let event = {
                            eventData: undefined,
                            position: undefined,
                            sequenceDate: undefined,
                            id: undefined,
                            type: undefined,
                            assemblyId: undefined,
                            previousAssemblyId: undefined
                        };

                        let groupedEvent = groupedEvents[geKey]; // Could be 1 event or 2 events (for assembly)
                        groupedEvent = _.sortBy(groupedEvent, event => event.equipmentType.name.toLowerCase() === 'tyre' ? 0 : 1); // ensure tyre is first

                        let referenceEvent = groupedEvent[0]; // reference event

                        if (groupedEvent.length > 1) {

                            // assembly

                            // FR156 says to use site if present.
                            let serials = (referenceEvent.equipmentType.name.toLowerCase() === "tyre") ?
                                [(groupedEvent[1].serialNumber.site || groupedEvent[1].serialNumber.manufacturer),
                                (groupedEvent[0].serialNumber.site || groupedEvent[0].serialNumber.manufacturer)]
                                : // else type !== "tyre"
                                [(groupedEvent[0].serialNumber.site || groupedEvent[0].serialNumber.manufacturer),
                                (groupedEvent[1].serialNumber.site || groupedEvent[1].serialNumber.manufacturer)];


                            let type = "assembly";

                            event = {
                                type: type.toLowerCase(),
                                eventData: {
                                    serialNumber: serials.join(" / "),
                                    event: referenceEvent.eventType.toLowerCase(),
                                    eventDescription: referenceEvent.eventDescription,
                                    events: groupedEvent.map(e => ({
                                        equipmentEventId: e.id,
                                        date: e.eventDate
                                    })),
                                    type: type,
                                    comment: referenceEvent.comment,
                                    position: {
                                        label: referenceEvent.wheelPositionLabel,
                                        id: referenceEvent.wheelPositionId,
                                        typeId: referenceEvent.wheelPositionTypeId
                                    },
                                    components: [
                                        {
                                            serialNumber: groupedEvent[0].serialNumber.site || groupedEvent[0].serialNumber.manufacturer,
                                            equipmentId: groupedEvent[0].equipmentId,
                                            equipmentCurrentSiteId: groupedEvent[0].equipmentCurrentSiteId,
                                            id: groupedEvent[0].id,
                                            type: groupedEvent[0].equipmentType.name,
                                            typeId: groupedEvent[0].equipmentType.id,
                                            eventId: groupedEvent[0].id
                                        },
                                        {
                                            serialNumber: groupedEvent[1].serialNumber.site || groupedEvent[1].serialNumber.manufacturer,
                                            equipmentId: groupedEvent[1].equipmentId,
                                            equipmentCurrentSiteId: groupedEvent[1].equipmentCurrentSiteId,
                                            id: groupedEvent[1].id,
                                            type: groupedEvent[1].equipmentType.name,
                                            typeId: groupedEvent[1].equipmentType.id,
                                            eventId: groupedEvent[1].id
                                        }
                                    ]
                                },
                                position: {
                                    label: referenceEvent.wheelPositionLabel,
                                    id: referenceEvent.wheelPositionId,
                                    typeId: referenceEvent.wheelPositionTypeId
                                },
                                sequenceDate: undefined,
                                id: uuid(),
                                assemblyId: referenceEvent.assemblyId,
                                previousAssemblyId: referenceEvent.previousAssemblyId
                            };

                            let eventType: MaintenanceSession_EventType = referenceEvent.eventType.toLowerCase() === 'remove' ? MaintenanceSession_EventType.remove : MaintenanceSession_EventType.fit;
                            let active: boolean = (eventType === MaintenanceSession_EventType.fit || !assembliesToRemove.includes(referenceEvent.assemblyId)) ? true : false;

                            if (!session.assemblies) { session.assemblies = []; }

                            let assemblySerial = "(" + serials.join("/") + ")";

                            session.assemblies.push({
                                id: referenceEvent.assemblyId,
                                previousId: referenceEvent.previousAssemblyId,
                                eventType: eventType,
                                assemblyId: referenceEvent.assemblyId,
                                equipmentTypeId: amtConstants.emptyGuid,
                                serialNumber: { formatted: assemblySerial },
                                components: angular.copy(event.eventData.components),
                                active: active
                            });

                            if (eventType === MaintenanceSession_EventType.fit) {
                                assembliesToRemove.push(referenceEvent.previousAssemblyId);
                            }

                        } else {

                            event = {
                                type: referenceEvent.equipmentType.name.toLowerCase(),
                                eventData: {
                                    serialNumber: referenceEvent.serialNumber.site || referenceEvent.serialNumber.manufacturer,
                                    event: referenceEvent.eventType.toLowerCase(),
                                    eventDescription: referenceEvent.eventDescription,
                                    events: referenceEvent.id ? [{
                                        equipmentEventId: referenceEvent.id,
                                        date: referenceEvent.eventDate
                                    }] : [],
                                    type: referenceEvent.equipmentType.name,
                                    comment: referenceEvent.comment,
                                    position: {
                                        label: referenceEvent.wheelPositionLabel,
                                        id: referenceEvent.wheelPositionId,
                                        typeId: referenceEvent.wheelPositionTypeId
                                    },
                                    components: [{
                                        serialNumber: referenceEvent.serialNumber.site || referenceEvent.serialNumber.manufacturer,
                                        equipmentId: referenceEvent.equipmentId,
                                        equipmentCurrentSiteId: referenceEvent.equipmentCurrentSiteId,
                                        id: referenceEvent.id,
                                        type: referenceEvent.equipmentType.name,
                                        typeId: referenceEvent.equipmentType.id,
                                        eventId: referenceEvent.id
                                    }]
                                },
                                position: {
                                    label: referenceEvent.wheelPositionLabel,
                                    id: referenceEvent.wheelPositionId,
                                    typeId: referenceEvent.wheelPositionTypeId
                                },
                                sequenceDate: undefined,
                                id: uuid(),
                                assemblyId: referenceEvent.assemblyId,
                                previousAssemblyId: referenceEvent.previousAssemblyId
                            };
                        }

                        for (let eventExtraData of response.sessionData.removals.components) {

                            let idx = _.findIndex(event.eventData.events, i => i.equipmentEventId == eventExtraData.equipmentEventId);

                            // see databroker.msRemoveEvent
                            if (idx > -1) {
                                if (eventExtraData.pending) {
                                    event.eventData.events = [];
                                    event.eventData.components[idx].eventId = null;
                                }

                                event.eventData.selectedMoveTo = { id: eventExtraData.statusTypeId, key: eventExtraData.statusTypeId, name: eventExtraData.statusTypeName };

                                event.eventData.canRefit = eventExtraData.canRefit;
                                event.sequenceDate = eventExtraData.sequenceDate;
                                event.eventData.components[idx].pressureSensorId = eventExtraData.pressureSensorId;

                                if (eventExtraData.damageDetails) {

                                    event.eventData.selectedDamageCause = { id: eventExtraData.damageDetails.damageCauseId, key: eventExtraData.damageDetails.damageCauseId };
                                    event.eventData.selectedDamageLocation = { id: eventExtraData.damageDetails.damageLocationId, key: eventExtraData.damageDetails.damageLocationId };
                                    event.eventData.selectedDamageSource = { id: eventExtraData.damageDetails.damageSourceId, key: eventExtraData.damageDetails.damageSourceId };

                                    event.eventData.instant = eventExtraData.damageDetails.instant;
                                    event.eventData.tyreBurst = eventExtraData.damageDetails.tyreBurst;
                                    event.eventData.tyreExplosion = eventExtraData.damageDetails.tyreExplosion;
                                }

                                if (eventExtraData.transferDetails) {
                                    event.eventData.destination = {
                                        id: eventExtraData.transferDetails.destinationSiteId || amtConstants.emptyGuid,
                                        destinationOther: eventExtraData.transferDetails.destinationOther
                                    };
                                }

                                if (eventExtraData.repairDetails) {
                                    event.eventData.repairer = {
                                        id: eventExtraData.repairDetails.repairerId || amtConstants.emptyGuid,
                                        repairerOther: eventExtraData.repairDetails.repairerOther
                                    };
                                }

                                if (eventExtraData.removeDetails) {
                                    event.eventData.selectedRemovalReason = { id: eventExtraData.removeDetails.removeReasonId, key: eventExtraData.removeDetails.removeReasonId };
                                }

                                if (eventExtraData.costDetails) {
                                    event.eventData.creditAmount = eventExtraData.costDetails.amount;
                                }

                                event.eventData.selectedLocation = { id: eventExtraData.locationId, key: eventExtraData.locationId };

                                // flatten the readings 
                                if (eventExtraData.readings && eventExtraData.readings.readings) {
                                    for (let reading of eventExtraData.readings.readings) {
                                        switch (reading.readingEventTypeName.toUpperCase()) {
                                            case 'TREADDEPTH':
                                                event.eventData.rtd1 = reading.values[0].sequence === 1 ? reading.values[0].value : reading.values[1].value;
                                                event.eventData.rtd2 = reading.values[0].sequence === 2 ? reading.values[0].value : reading.values[1].value;
                                                break;
                                            case 'CHAINDEPTH':
                                                event.eventData.rcd1 = reading.values[0].sequence === 1 ? reading.values[0].value : reading.values[1].value;
                                                event.eventData.rcd2 = reading.values[0].sequence === 2 ? reading.values[0].value : reading.values[1].value;
                                                break;
                                        }
                                    }
                                }
                            }
                        }

                        // fitments
                        for (let component of response.sessionData.fitments.components) {

                            // any components that were fit in this session...
                            if (!component.statusTo) { // and are still fitted
                                let equipType = component.equipmentType.toLowerCase();
                                let equipId = component.equipmentId

                                // add their readings to the vehicle.positionReadings so that they appear on the readings tab
                                for (let posReading of vehicle.positionReadings) {
                                    if ((equipType === "tyre" && posReading.TyreFitment && equipId === posReading.TyreFitment.componentId) ||
                                        (equipType === "rim" && posReading.RimFitment && equipId === posReading.RimFitment.componentId) ||
                                        (equipType === "chain" && posReading.ChainFitment && equipId === posReading.ChainFitment.componentId)) {

                                        const getValue = v => v != null ? v.value : void 0;

                                        for (let reading of component.readings.readings) {
                                            switch (reading.readingEventTypeName.toUpperCase()) {
                                                case "TREADDEPTH":
                                                    posReading.rtd1 = getValue(reading.values[0]);
                                                    posReading.rtd2 = getValue(reading.values[1]);
                                                    break;
                                                case "PRESSURE":
                                                    posReading.pressure = getValue(reading.values[0]);
                                                    posReading.adjPressure = getValue(reading.values[1]);
                                                    break;
                                                case "TORQUE":
                                                    posReading.torque = getValue(reading.values[0]);
                                                    break;
                                                case "TREADTEMP":
                                                    posReading.temp = getValue(reading.values[0]);
                                                    break;
                                                case "CHAINDEPTH":
                                                    posReading.rcd1 = getValue(reading.values[0]);
                                                    posReading.rcd2 = getValue(reading.values[1]);
                                                    break;
                                            }
                                        }
                                        //TODO: this looks wrong, why are we even setting it above only to zero it here?! Going make it preserve the values
                                        posReading.rtd1 = posReading.rtd1 || 0;
                                        posReading.rtd2 = posReading.rtd2 || 0;
                                    }
                                }
                            }

                            let idx = _.findIndex(event.eventData.events, i => i.equipmentEventId == component.equipmentEventId);

                            if (idx > -1) {

                                let eventExtraData = component;

                                if (eventExtraData.pending) {
                                    event.eventData.events = [];
                                    event.eventData.components[idx].eventId = null;
                                }

                                event.sequenceDate = eventExtraData.sequenceDate;

                                if (eventExtraData.readings && eventExtraData.readings.readings) {

                                    for (let reading of eventExtraData.readings.readings) {
                                        switch (reading.readingEventTypeName.toUpperCase()) {
                                            case 'TORQUE':
                                                event.eventData.torque = reading.values[0].value;
                                                break;
                                            case 'PRESSURE':
                                                event.eventData.pressure = reading.values[0].value;
                                                break;
                                            case 'TREADTEMP':
                                                event.eventData.temperature = reading.values[0].value;
                                                break;
                                        }
                                    }
                                }

                                if (eventExtraData.fitDetails) {
                                    event.eventData.lubricatedStuds = eventExtraData.fitDetails.studsLubricated;
                                    event.eventData.nitroFilled = eventExtraData.fitDetails.nitrogenFilled;
                                    event.eventData.pressureSensors = { id: eventExtraData.fitDetails.pressureSensorId, key: eventExtraData.fitDetails.pressureSensorId };
                                    event.eventData.selectedPosition = { id: event.position.id };
                                }
                            }
                        }

                        session.events.items.push(event);
                    });
                }

                session.createdDate = response.sessionData.checkIn ? response.sessionData.checkIn.eventDate : new Date();

                session.sessionId = response.sessionData.pendingMaintenanceSessionId || uuid();

                session.pristine = 1;

                return session;
            };

            function setTotalDownTime(checkInDate: Date | undefined | null, checkOutDate: Date | undefined | null): number {
                let totalDownTime: number = 0;

                if (checkInDate !== undefined && checkOutDate !== undefined && checkOutDate !== null && checkInDate !== null) {
                    let differenceInMilliseconds: number = checkOutDate.getTime() - checkInDate.getTime();
                    let differenceInMinutes: number = differenceInMilliseconds / (1000 * 60);
                    totalDownTime = Math.floor(differenceInMinutes);
                }

                return totalDownTime;
            }

            /**
             * Maps a Status to a specific sort order.
             *
             * @param {string} status - Status to map. Expected: ["Error", "In Progress", "Ready", "Completed"]
             * @returns {string} - Sort Order string like "1".
             */
            function mapStatusToSortOrder(status) {
                switch (status) {
                    case "Error":
                        return "1";
                    case "In Progress":
                        return "2";
                    case "Pending":
                        return "3";
                    case "Ready":
                    case undefined: // The maintenanceStatusDescription is *always* "Ready" if maintenanceStatus is null or undefined.
                    case null:
                        return "4";
                    case "Completed":
                        return "5";
                    default:
                        return "999"; // put it at the bottom if we don't know what it is.
                }
            }

            function ETLHasError() {
                return $http.get(baseApiUrl + 'etl/etlHasError', null);
            }

            function toUpper(string) {
                return string ? string.toUpperCase() : string;
            }

            /**
             * Builds a sort key based on the provided arguments.
             *
             * @param {Array<string>} args - The arguments to join on.
             * @param {string} [separator] - The seperator to use. Defaults to "-".
             * @returns {string} - The full sort key, joined by the separator.
             */
            function buildSortKey(args, separator = "-") {
                return args.join(separator);
            }

            async function setCurrentLanguageFrom() {
                let language = ocConfigSvc.getLanguage();
                await amtXlatSvc.setCurrentLanguage(language);
            }

            // #endregion shared

            return {
                isMobile: vm.isMobile, //depreciated: don't use pls
                loadVehicleList: target.loadVehicleList,
                loadVehicleListMaint: target.loadVehicleListMaint,
                causeFromReading: target.causeFromReading,
                getVehicleDetails: target.getVehicleDetails,
                deleteFieldSurvey: target.deleteFieldSurvey,
                getSchematic: target.getSchematic,
                saveFieldSurvey: target.saveFieldSurvey,
                saveMaintenanceSession: target.saveMaintenanceSession,
                getComponentBySerialNumber: target.getComponentBySerialNumber,
                loadMaintenanceSession: target.loadMaintenanceSession,
                fieldSurveyCount: target.fieldSurveyCount,
                logError: target.logError,
                logout: logout,
                forgottenPassword: forgottenPassword,
                login: target.login, // validates credentials
                refreshToken: target.refreshToken,
                sessionExpire: sessionExpire,
                getSession: getSession,
                ETLHasError: ETLHasError,
                resetPassword: target.resetPassword,
                getFieldSurveySettings: target.getFieldSurveySettings,
                getComponentTypes: target.getComponentTypes,
                getStatuses: target.getStatuses,
                getEquipmentStatusAtDate: target.getEquipmentStatusAtDate,
                getSpecificationDetails: target.getSpecificationDetails,
                getVehicleSpecificationDetails: target.getVehicleSpecificationDetails,
                getPendingComponents: target.getPendingComponents,
                getSpecifications: target.getSpecifications,
                getUnfulfilledPurchaseOrders: target.getUnfulfilledPurchaseOrders,
                getSpecificationsForPurchaseOrder: target.getSpecificationsForPurchaseOrder,
                getProductionCrews: target.getProductionCrews,
                getPressureSensors: target.getPressureSensors,
                getMaintenanceTypes: target.getMaintenanceTypes,
                getDowntimeTypes: target.getDowntimeTypes,
                getRemovalReasons: target.getRemovalReasons,
                getFitters: target.getFitters,
                getDamageCauses: target.getDamageCauses,
                getDamageSources: target.getDamageSources,
                getDamageLocations: target.getDamageLocations,
                getWorkshopTimes: target.getWorkshopTimes,
                getStatusFlows: target.getStatusFlows,
                getLocations: target.getLocations,
                getCostTypes: target.getCostTypes,                
                receiveUnknownComponents: target.receiveUnknownComponents,
                receiveKnownComponents: target.receiveKnownComponents,
                receivePendingComponents: target.receivePendingComponents,
                getUnknownComponentReceiveDestinationStatusTypes: target.getUnknownComponentReceiveDestinationStatusTypes,
                getPendingReceivals: target.getPendingReceivals,
                getPendingReceival: target.getPendingReceival,
                deletePendingReceival: target.deletePendingReceival,
                getPendingVehicles: target.getPendingVehicles,
                deletePendingVehicle: target.deletePendingVehicle,
                receiveUnknownVehicles: target.receiveUnknownVehicles,
                receiveKnownVehicles: target.receiveKnownVehicles,
                receivePendingVehicles: target.receivePendingVehicles,
                deleteReceiptedComponents: target.deleteReceiptedComponents,
                deleteReceiptedVehicles: target.deleteReceiptedVehicles,
                //getStocktakeList: target.getStocktakeList,
                getStocktake: target.getStocktake,
                createStocktake: target.createStocktake,
                getSerials: target.getSerials,
                getValidFitments: target.getValidFitments,
                checkValidFitmentForPosition: target.checkValidFitmentForPosition,
                setStocktakeType: target.setStocktakeType,
                saveStocktake: target.saveStocktake,
                deleteStocktake: target.deleteStocktake,
                getUnitsOfMeasure: target.getUnitsOfMeasure,
                getStocktakeComponentTypes: target.getStocktakeComponentTypes,
                checkRefData: target.checkRefData,
                getThumbnail: target.getThumbnail,
                msRemoveEvent: target.msRemoveEvent,
                msFitEvent: target.msFitEvent,
                msCheckinEvent: target.msCheckinEvent,
                msCheckoutEvent: target.msCheckoutEvent,
                msUndoCheckin: target.msUndoCheckin,
                msUndoCheckOut: target.msUndoCheckOut,
                msUndoFit: target.msUndoFit,
                msUndoRemove: target.msUndoRemove,
                msUpdateCheckIn: target.msUpdateCheckIn,
                msUpdateCheckOut: target.msUpdateCheckOut,
                msDeleteSession: target.msDeleteSession,
                msSwapComponentHistory: target.msSwapComponentHistory,
                msSwapPositionFitments: target.msSwapPositionFitments,
                msStripAssembly: target.msStripAssembly,

                checkVehicleLockedOut: target.checkVehicleLockedOut,

                getFleetSpeed: target.getFleetSpeed,

                updateFitmentData: target.updateFitmentData,
                lookupComponent: target.lookupComponent,

                mapMaintenanceSession: mapMaintenanceSession,

                getIncompleteWheelPositionStatusTypes: target.getIncompleteWheelPositionStatusTypes,
                recordMaintenanceSessionReadings: target.recordMaintenanceSessionReadings,
                getComponentOwners: target.getComponentOwners,
                getComponentsInStatus: target.getComponentsInStatus,
                getVehiclesInStatus: target.getVehiclesInStatus,
                getVehiclePositionFitments: target.getVehiclePositionFitments,
                getRetreaders: target.getRetreaders,
                getRepairers: target.getRepairers,
                getRepairTypes: target.getRepairTypes,
                getReturnToSupplierReasons: target.getReturnToSupplierReasons,
                getSuppliers: target.getSuppliers,
                getCurrencyTypes: target.getCurrencyTypes,

                getReadingEventTypeComments: target.getReadingEventTypeComments,

                changeComponentStatus: target.changeComponentStatus,
                getStatusChanges: target.getStatusChanges,
                deletePendingStatusChanges: target.deletePendingStatusChanges,

                checkUniqueSerialNumber: target.checkUniqueSerialNumber,

                getPressureReadingTypes: getPressureReadingTypes,

                // mobile
                getErrors: target.getErrors,
                schematicToPositions: schematicToPositions,
                schematicToPositionsArray: schematicToPositionsArray,
                updateSystemSettings: target.updateSystemSettings,

                //desktop
                loginExternal: target.loginExternal,

                adjustVehiclePositions: adjustVehiclePositions,

                uploadErrors: target.uploadErrors,
                logAuditEntry: target.logAuditEntry,
                uploadAudits: target.uploadAudits,

                setCurrentLanguageFrom: setCurrentLanguageFrom,
            };
        }]);