/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Specifies the content in the Card body.
 */
var CardBodyComponent = /** @class */ (function () {
    function CardBodyComponent() {
        this.hostClass = true;
    }
    tslib_1.__decorate([
        core_1.HostBinding('class.k-card-body'),
        tslib_1.__metadata("design:type", Boolean)
    ], CardBodyComponent.prototype, "hostClass", void 0);
    CardBodyComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-card-body',
            template: "\n        <ng-content></ng-content>\n    "
        })
    ], CardBodyComponent);
    return CardBodyComponent;
}());
exports.CardBodyComponent = CardBodyComponent;
