
/**
* { string } type - type of the column (string, number, checkbox, date, template)
* { number } colSpan  - span of the column (total of all columns must sum to 12)
* { string } headerResource - resource for column header
* { string =} [valuePath = undefined] - path to value property on grid ng-model
* { string =} [columnResource = undefined] - resource for the column value (string column only)
* { boolean =} [show = true] - display the column
* { string =} [headerAlignment = columnAlignment or left] - alignment of column header (left, center, right) - if not specified will default to same as columnAlignment, otherwise left is none defined
* { string =} [columnAlignment = headerAlignment or left] - alignment of column body (left, center, right) - if not specified will default to same as headerAlignment, otherwise left is none defined
* { string =} [editable = false] - is the value editable? (true, false, or path to value property on grid ng-model where editable if property is not undefined, null or false)
* { boolean =} [required = false] - if editable, is value required?
* { string =} [onClick = null] - event to fire on click (hyperlink), only applies to non-editable string column, event must be connected to ocDynamicGrid via api
* { number =} [minValue = null] - minimum allowed value (editable number column only)
* { number =} [maxValue = null] - maximum allowed value (editable number column only)
* { number =} [decimalPlaces = null] - number of decimals (if not specified, allows  decimals with no limit) (editable number column only)
* { boolean =} [boolToString = false] - if boolean (true, false), change to Yes or No (non-editable string column only)
* { boolean =} [showNotAvailableOnEmpty = false] - if value undefined, display N/A (non-editable string column only)
* { string =} [template = null] - html string representing a control to put into the grid
* { object =} [templateValues = null] - mappings to provide values from outside the scope of the row, must be like { key: value, key: value } and keys must match what is in the template
* { object =} [templateEvents = false] - mappings to link events in the template control to methods outside of the scope of the row, must be like { key: { method: methodName, params: [parameters] }}, the method must then be mapped via the api
* { string =} [appendText = null] - for string/number columns this value will be appended after the actual value in the column, for example you could define this as '%' on a number column and the cells would then display like '20 %'
* { number =} [contentWidth = null] - specify the width of the content in the column in percent (between 0 and 100), if not set then it assumes 100% (fill the column)
* { boolean =} [sortable = true] - can the data by sorted by this column
*/

/**
 * Template column example
 * note that any instance of $row in the template will become a reference to the ng-model of the row in the grid
 * 
 * api: vm.gridControl
 * template:  '<amt-combobox ng-model="$row.futureTyreSpecification" is-required="true" on-selected-change="$onChange" options="$options" />'
 * templateValues: { $options: vm.availableTyreSpecifications }
 * templateEvents: { $onChange: {
 *                     method: 'futureTyreSpecChanged',
 *                     params: ['option', '$row']      // in this case 'option' is the product of the on-selected-change handler in amt-combobox
 *                   }
 *                 }
 * to connect the events you must define the api value on the ocDynamicGrid and have a watch on it in your parent control, and in there you must link the method
 * this is the same for when you link any event (such as onClick on a string column)
 * e.g.
 *  $scope.$watch("vm.gridControl", function (newValue) {
 *      if (newValue) {
 *          // connect functions to dynamic grid api
 *          vm.gridControl.futureTyreSpecChanged = vm.futureTyreSpecChanged;
 *      }
 *  });
 */
//import angular from 'angular';
import tmpl from './ocDynamicGrid.html';
import HelperSvc from '../../services/helperSvc';


export class OcDynamicGridColumn {
    constructor(

        public type: OcDynamicGridColumnType,
        public colSpan: number,

        public headerValue?: string,
        public headerResource?: string,

        public valuePath?: string,
        public columnResource?: string,

        public hideIfInvalid?: boolean,

        public show?: boolean,

        public headerAlignment?: OcDynamicGridAlignment,
        public columnAlignment?: OcDynamicGridAlignment,

        public editable?: string,
        public required?: boolean,

        public onClick?: string,

        public minValue?: number,
        public maxValue?: number,
        public decimalPlaces?: number,

        public boolToString?: boolean,
        public showNotAvailableOnEmpty?: boolean,

        public template?: string,
        public templateValues?: object,
        public templateEvents?: object,

        public appendText?: string,
        public contentWidth?: number,

        public sortable?: boolean

    ) { }
}

export class OcDynamicGridColumnMaker {
    static create(obj: OcDynamicGridColumn) {
        return new OcDynamicGridColumn(obj.type, obj.colSpan, obj.headerValue, obj.headerResource, obj.valuePath, obj.columnResource, obj.hideIfInvalid, obj.show, obj.headerAlignment, obj.columnAlignment,
            obj.editable, obj.required, obj.onClick, obj.minValue, obj.maxValue, obj.decimalPlaces, obj.boolToString, obj.showNotAvailableOnEmpty,
            obj.template, obj.templateValues, obj.templateEvents, obj.appendText, obj.contentWidth, obj.sortable);
    }
}

export enum OcDynamicGridColumnType {
    string = 'string',
    checkbox = 'checkbox',
    number = 'number',
    duration = 'duration',
    date = 'date',
    template = 'template'
}

export const enum OcDynamicGridAlignment {
    left = 'left',
    center = 'center',
    right = 'right'
}

/**
* @param { expression } columns - Array of OcDynamicGridColumns that defines the columns of the grid
* @param { expression } ngModel  - Data to populate the grid
* @param { string =} rowHideProperty - property name which if defined and truthy on the model array elements will cause them not to appear in the grid
* @param { string =} rowShowProperty - property name which if defined and falsey on the model array elements will cause them not to appear in the grid
* @param { expression =} [showAlternatingRowColours = false] - Show alternating colours on the rows of the grid
* @param { expression =} [removeHeaderTopBorder = false] - Remove the top border above the grid header
* @param { string =} width - width of the grid (px if not specified) - if not set will use 100% width but leave some space for vertical scrollbar
* @param { string =} height - height of the grid (px if not specified)
* @param { expression =} api - reference to this control to call methods (both direction)
*/



    angular.module('app.directives')
        .directive('ocDynamicGrid', [
            '$timeout', 'helperSvc',
            function ($timeout, helperSvc: HelperSvc) {
                return {
                    restrict: 'E',
                    scope: {
                        columns: "=",
                        ngModel: "=",
                        rowHideProperty: "@",
                        rowShowProperty: "@",
                        showAlternatingRowColours: "=?",
                        rowPadding: "@?",
                        removeHeaderTopBorder: "=?",
                        headerStyle: "=?",
                        width: "@?",
                        height: "@?",
                        api: "=?",
                        sortable: "=?"
                    },
                    template: tmpl,
                    controller: ["$scope", "$parse", "$filter", "amtXlatSvc", "$compile", function ($scope, $parse, $filter, amtXlatSvc, $compile) {
                        
                        $scope.sortColumn;
                        $scope.sortAscending;

                        $scope.headerHeight = 0;

                        $scope.gridId = uuid();

                        $scope.byString = Object.byString;
                        $scope.initWidth = angular.copy($scope.width);

                        $scope.setRowClass = function (index, cssClass) {
                            if ($scope.ngModel && $scope.ngModel[index]) {
                                $scope.ngModel[index].class = cssClass;
                            }
                        };

                        $scope.removeRowClass = function (index) {
                            if ($scope.ngModel && $scope.ngModel[index]) {
                                $scope.ngModel[index].class = null;
                            }
                        };

                        $scope.api = {
                            setRowClass: $scope.setRowClass,
                            removeRowClass: $scope.removeRowClass
                        };

                        $scope.$watch('ngModel.length', function (newValue, oldValue) {
                            if ($scope.sortable) {
                                let shiftSortOrderBy: number = 0;
                                let lastSortOrder: number = 0;

                                for (let i = 0; i < $scope.ngModel.length; i++) {
                                    if (!$scope.ngModel[i].sortOrder) {
                                        lastSortOrder++;
                                        $scope.ngModel[i].sortOrder = lastSortOrder;
                                        shiftSortOrderBy++;
                                    } else {
                                        $scope.ngModel[i].sortOrder += shiftSortOrderBy;
                                        lastSortOrder = $scope.ngModel[i].sortOrder;
                                    }
                                }

                                if ($scope.sortColumn >= 0) $scope.sort($scope.sortColumn, true);
                            }
                        });

                        $scope.$watch('columns', function (newValue) {

                            if (newValue && newValue.length > 0) {

                                // check columns are a collection of OcDynamicGridColumn objects 
                                if (!(newValue[0] instanceof OcDynamicGridColumn)) {
                                    throw new Error('Columns must be of type OcDynamicGridColumn');
                                }

                                // for each column defined
                                for (let i = 0; i < $scope.columns.length; i++) {    

                                    if ($scope.columns[i].type === OcDynamicGridColumnType.template) {

                                        if (!$scope.columns[i].template) {
                                            throw new Error('Template columns must define a template.');
                                        }

                                        // replace value placeholders in the template string with references to the actual values
                                        if ($scope.columns[i].templateValues) {
                                            Object.keys($scope.columns[i].templateValues).forEach(key => {
                                                $scope.columns[i].template = $scope.columns[i].template.split(key).join('col.templateValues[\'' + key + '\']');
                                            });
                                        }

                                        // replace event placeholders in the template string wtih references to the actual handlers
                                        if ($scope.columns[i].templateEvents) {
                                            Object.keys($scope.columns[i].templateEvents).forEach(key => {
                                                let event = $scope.columns[i].templateEvents[key];

                                                $scope.columns[i].template = $scope.columns[i].template.split(key).join('$eval(\'api.' + event.method + '\')(' + (event.params && event.params.length > 0 ? event.params.join(',') : '') + ')');
                                            });
                                        }

                                        // replace any instances of $row with reference to the row item in the grid
                                        $scope.columns[i].template = $scope.columns[i].template.split('$row').join('item');
                                    }

                                    // check valuePath or columnResource has been provided
                                    if (!$scope.columns[i].valuePath && !$scope.columns[i].columnResource && $scope.columns[i].type !== OcDynamicGridColumnType.template) {
                                        throw new Error('At least one of ValuePath and ColumnResource must be provided.');
                                    }

                                    // if column is not string, check valuePath is provided
                                    if (!$scope.columns[i].valuePath && $scope.columns[i].type !== OcDynamicGridColumnType.string && $scope.columns[i].type !== OcDynamicGridColumnType.template) {
                                        throw new Error('ValuePath must be provided for non-string non-template columns.');
                                    }

                                    if ($scope.columns[i].contentWidth && ($scope.columns[i].contentWidth <= 0 || $scope.columns[i].contentWidth > 100)) {
                                        throw new Error('ContentWidth must be between 1 and 100.');
                                    }

                                    // if column is set to not show
                                    if ($scope.columns[i].show === false && !$scope.columns[i].colSpanShifted) {

                                        // if this was the only column, throw error
                                        if ($scope.columns.length === 1) {
                                            throw new Error('No columns to display.')
                                        }

                                        // hand colSpan to next column to the left, unless this is the first column, in which case hand to the right
                                        if (i === 0) {
                                            $scope.columns[i + 1].colSpan += $scope.columns[i].colSpan;
                                        } else {
                                            $scope.columns[i - 1].colSpan += $scope.columns[i].colSpan;
                                        }

                                        $scope.columns[i].colSpanShifted = true;
                                    }

                                    if ($scope.columns[i].show !== false) {
                                        $scope.columns[i].colSpanShifted = false;
                                    }

                                    // if editable is set to True or False make lowercase
                                    if ($scope.columns[i].editable === 'False' || $scope.columns[i].editable === 'True') {
                                        $scope.columns[i].editable = $scope.columns[i].editable.toLowerCase();
                                    }

                                    // if valid header alignment but no or invalid column alignment, make column alignment the same as the header alignment
                                    // if valid column alignment but no or invalid header alignment, make header alignment the same as the column alignment
                                    // if not alignments provided or both invalid, default both to left
                                    if ($scope.columns[i].headerAlignment && !$scope.columns[i].columnAlignment) {
                                        $scope.columns[i].columnAlignment = angular.copy($scope.columns[i].headerAlignment);
                                    } else if (!$scope.columns[i].headerAlignment && $scope.columns[i].columnAlignment) {
                                        $scope.columns[i].headerAlignment = angular.copy($scope.columns[i].columnAlignment);
                                    } else if (!$scope.columns[i].headerAlignment && !$scope.columns[i].columnAlignment) {
                                        $scope.columns[i].headerAlignment = OcDynamicGridAlignment.left;
                                        $scope.columns[i].columnAlignment = OcDynamicGridAlignment.left;
                                    }
                                }

                                // check sum of colSpans is 12 as required by amt-row
                                if ($scope.columns.reduce((a, b) => { return a + (b.show === false ? 0 : b.colSpan); }, 0) !== 12) {
                                    throw new Error('ColSpan values must sum to 12 for columns.');
                                }

                                $timeout(function () {
                                    for (let i = 0; i < $scope.columns.length; i++) {

                                        // if this is a template column, iterate over each row and retrieve the placeholder element
                                        // and then append the template string and compile the html
                                        if ($scope.columns[i].type === OcDynamicGridColumnType.template) {

                                            for (let j = 0; j < $scope.ngModel.length; j++) {

                                                // construct a new scope because we can't get to the scope of the element in production
                                                let newScope = $scope.$new();

                                                // reference to column and row
                                                newScope['col'] = $scope.columns[i];
                                                newScope['colIndex'] = i;

                                                newScope['item'] = $scope.ngModel[j];
                                                newScope['rowIndex'] = j;

                                                let el = document.getElementById(String.format('{0}.{1}.{2}.template', $scope.gridId, j, i));

                                                if (el) {
                                                    angular.element(el).append($scope.columns[i].template);

                                                    $compile(el)(newScope);
                                                }
                                            }
                                        }
                                    }
                                });
                            }
                        });

                        $scope.getSetPropertyBinding = function (model, col) {

                            if (col && model) {

                                var p = $parse(col.valuePath);
                                var s = p.assign;

                                return function (newVal) {

                                    if (arguments.length > 0) {

                                        // SETTER
                                        if (angular.isDefined(newVal)) {

                                            // validate
                                            let validationResult = validateValue(col, newVal);

                                            if (validationResult.invalid === true) {
                                                return; //don't save
                                            } else {
                                                newVal = validationResult.newVal;
                                            }
                                        }

                                        // set the new value
                                        s(model, newVal);

                                    } else {

                                        // GETTER
                                        let val = p(model);

                                        if (!isNaN(val) && col.decimalPlaces !== null && col.decimalPlaces !== undefined) {
                                            val = Number(val).toFixed(col.decimalPlaces);
                                        }

                                        return val;
                                    }
                                }
                            }
                        };

                        $scope.applyFilters = function (col, item) {

                            let value = undefined;

                            if (col.columnResource) {
                                value = amtXlatSvc.xlat(col.columnResource);
                            } else {
                                value = Object.byString(item, col.valuePath);
                            }

                            if (value !== undefined) {
                                if (col.boolToString) {
                                    value = $filter('yesNo')(value);
                                }

                                if (col.showNotAvailableOnEmpty) {
                                    value = $filter('notAvailable')(value);
                                }

                                if (col.type === OcDynamicGridColumnType.duration) {
                                    value = $filter('durationFilter')(value);
                                }

                                if (col.type === OcDynamicGridColumnType.date) {
                                    value = $filter('date')(value);
                                }

                                if (!isNaN(value) && col.decimalPlaces !== null && col.decimalPlaces !== undefined) {
                                    value = Number(value).toFixed(col.decimalPlaces);
                                }
                            }

                            return value;
                        };

                        $scope.sort = function (colIndex, refreshOnly) {

                            if ($scope.sortable) {

                                try {
                                    let defaultSort = false;
                                    let column = $scope.columns[colIndex];

                                    if (column && column.sortable !== false && column.valuePath) {

                                        if ($scope.sortColumn === colIndex) {
                                            if (!refreshOnly) {
                                                if (!$scope.sortAscending) {
                                                    $scope.sortColumn = null;
                                                    $scope.sortAscending = null;
                                                    defaultSort = true;
                                                } else {
                                                    $scope.sortAscending = !$scope.sortAscending;
                                                }
                                            }
                                        } else {
                                            $scope.sortColumn = colIndex;
                                            $scope.sortAscending = true;
                                        }

                                        if (!$scope.sortAscending && !defaultSort) {
                                            $scope.ngModel.sort(sortBy(defaultSort, column)).reverse();
                                        } else {
                                            $scope.ngModel.sort(sortBy(defaultSort, column));
                                        }
                                    }
                                } catch (error) {
                                    console.error('error sorting ocDynamicGrid: ' + error);
                                }
                            }
                        };

                        function sortBy(defaultSort, column) {

                            return (a, b) => {

                                let val1;
                                let val2;

                                if (defaultSort) {
                                    val1 = a.sortOrder || 0;
                                    val2 = b.sortOrder || 0;
                                } else {
                                    val1 = Object.byString(a, column.valuePath);
                                    val2 = Object.byString(b, column.valuePath);
                                }

                                return column.type == OcDynamicGridColumnType.string && !defaultSort
                                    ? val1.localeCompare(val2)
                                    : val1 > val2 ? 1 : (val1 < val2 ? -1 : 0);
                            };
                        };

                        function validateValue(col, newVal) {

                            let invalid: boolean = false;

                            // number validation
                            switch (col.type) {
                                case OcDynamicGridColumnType.number:

                                    // if more than one - or . in the number, or if it begins with ., or if it contains a - after the first character
                                    if ((newVal.split('-').length - 1) > 1 || (newVal.split('.').length - 1) > 1 || newVal.indexOf('-') > 0 || newVal === '.') {
                                        invalid = true;
                                    } else {

                                        if (newVal !== '-') {

                                            // if number is below min, or above max
                                            if ((col.minValue !== undefined && parseFloat(newVal) < +col.minValue) || (col.maxValue !== undefined && parseFloat(newVal) > +col.maxValue)) {
                                                invalid = true;
                                            } else if (col.decimalPlaces !== undefined && newVal.indexOf('.') > -1) {

                                                // if decimal places set to 0, don't allow . character
                                                if (col.decimalPlaces === 0) {
                                                    invalid = true;
                                                } else {
                                                    // trim back the decimals if needed
                                                    var split = newVal.split('.');

                                                    if (split[1].length > col.decimalPlaces) {
                                                        newVal = split[0] + '.' + split[1].substring(0, col.decimalPlaces);
                                                    }
                                                }
                                            }

                                        } else {

                                            // if there is a min value that is 0 or higher, don't allow a - character
                                            if (col.minValue !== undefined && +col.minValue >= 0) {
                                                invalid = true;
                                            }
                                        }
                                    }
                                    break;
                            }

                            return {
                                invalid: invalid,
                                newVal: newVal
                            };
                        };
                    }],

                    link: function (scope: any, element) {

                        scope.adjustSize = function (width) {
                            if (!scope.initWidth && width > 0) {
                                scope.width = width - 10; // leave space for scrollbar                
                            }

                            $timeout();
                        };

                        if (scope.rowPadding && scope.rowPadding.indexOf('px') === -1) {
                            scope.rowPadding = scope.rowPadding + 'px';
                        }

                        if (scope.width && scope.width.indexOf('px') === -1 && scope.width.indexOf('%') === -1) {
                            scope.width = scope.width + 'px';
                        }

                        if (scope.height && scope.height.indexOf('px') === -1 && scope.height.indexOf('%') === -1) {
                            scope.height = scope.height + 'px';
                        }

                        scope.getRowClass = function (item, even) {
                            return (item.class ? item.class : (scope.showAlternatingRowColours ? (even ? 'even' : 'odd') : null));
                        };

                        $timeout(() => {

                            scope.headerElement = helperSvc.getElementById(element, "gridHeader" + scope.gridId)[0];
                            scope.bodyElement = helperSvc.getElementById(element, "gridBody" + scope.gridId)[0];

                            scope.$watch('bodyElement.scrollHeight', function (newVal) {
                                if (newVal) {
                                    scope.hasVerticalScroll = newVal > scope.bodyElement.clientHeight;
                                }
                            });

                            scope.$watch('headerElement.scrollHeight', function (newVal) {
                                if (newVal) {
                                    scope.headerHeight = newVal;
                                }
                            });
                        });
                    }
                };
            }]);
