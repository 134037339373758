/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_common_1 = require("@progress/kendo-common");
var combobox_component_1 = require("./combobox.component");
var multicolumncombobox_component_1 = require("./multicolumncombobox.component");
var combobox_column_component_1 = require("./combobox-column/combobox-column.component");
var shared_module_1 = require("../common/shared.module");
var shared_directives_module_1 = require("../common/shared-directives.module");
var touch_enabled_1 = require("../common/constants/touch-enabled");
var column_header_template_directive_1 = require("./combobox-column/column-header-template.directive");
var column_cell_template_directive_1 = require("./combobox-column/column-cell-template.directive");
var COMBOBOX_DIRECTIVES = [
    combobox_component_1.ComboBoxComponent,
    multicolumncombobox_component_1.MultiColumnComboBoxComponent,
    combobox_column_component_1.ComboBoxColumnComponent,
    column_header_template_directive_1.ColumnHeaderTemplateDirective,
    column_cell_template_directive_1.ColumnCellTemplateDirective
];
var ɵ0 = kendo_common_1.touchEnabled;
exports.ɵ0 = ɵ0;
/**
 * @hidden
 *
 * The exported package module.
 *
 * The package exports:
 * - `ComboBoxComponent`&mdash;The ComboBox component class.
 * - `MultiColumnComboBoxComponent`&mdash;The MultiColumnComboBox component class.
 * - `ItemTemplateDirective`&mdash;The item template directive.
 * - `HeaderTemplateDirective`&mdash;The header template directive.
 * - `FooterTemplateDirective`&mdash;The footer template directive.
 * - `ColumnHeaderTemplateDirective`&mdash;The column header template directive.
 * - `ColumnCellTemplateDirective`&mdash;The column cell template directive.
 */
var ComboBoxModule = /** @class */ (function () {
    function ComboBoxModule() {
    }
    ComboBoxModule = tslib_1.__decorate([
        core_1.NgModule({
            declarations: [COMBOBOX_DIRECTIVES],
            exports: [COMBOBOX_DIRECTIVES, shared_directives_module_1.SharedDirectivesModule],
            imports: [shared_module_1.SharedModule],
            providers: [{ provide: touch_enabled_1.TOUCH_ENABLED, useValue: ɵ0 }]
        })
    ], ComboBoxModule);
    return ComboBoxModule;
}());
exports.ComboBoxModule = ComboBoxModule;
