/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Renders the column header content of the MultiColumnComboBox. To define a column header template, nest an `<ng-template>` tag
 * with the `kendoMultiColumnComboBoxColumnHeaderTemplate` directive inside the [`<kendo-combobox-column>`]({% slug api_dropdowns_comboboxcolumncomponent %}) tag
 * ([see example]({% slug templates_multicolumncombobox %})).
 *
 * The current [`column`]({% slug api_dropdowns_comboboxcolumncomponent %}) is available as implicit context variable.
 */
var ColumnHeaderTemplateDirective = /** @class */ (function () {
    function ColumnHeaderTemplateDirective(templateRef) {
        this.templateRef = templateRef;
    }
    ColumnHeaderTemplateDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoMultiColumnComboBoxColumnHeaderTemplate]'
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.TemplateRef])
    ], ColumnHeaderTemplateDirective);
    return ColumnHeaderTemplateDirective;
}());
exports.ColumnHeaderTemplateDirective = ColumnHeaderTemplateDirective;
