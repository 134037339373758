//import angular from 'angular';
import tmpl from './createStocktake.html';




angular.module("app").component('createStocktake', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ["$scope", "WindowFactory", "amtXlatSvc", "enums", "stocktakeUrls",
            function ($scope, WindowFactory, amtXlatSvc, enums, stocktakeUrls) {
                var ctrl = this;

                ctrl.form = {};
                ctrl.apiUrls = stocktakeUrls;

                $scope.$watch("$ctrl.form.$invalid", function (newVal) {
                    if (ctrl.buttonStates) {
                        ctrl.buttonStates["create"].disabled = newVal === true;
                    }
                });

                ctrl.$onInit = function () {

                    // add the cancel button
                    WindowFactory.addButton(
                        ctrl,
                        'common.cancel_label', // caption
                        'cancel',
                        function () {
                            WindowFactory.closeWindow(ctrl.wnd);
                        } // callback
                    );

                    // add the create button
                    WindowFactory.addButton(
                        ctrl,
                        'common.create_label', // caption
                        'create', // name of the function
                        function () {
                            WindowFactory.closeWindow(ctrl.wnd);
                            ctrl.wnd.onDataChanged(ctrl.data);
                        }, // callback
                        true // isPrimary
                    );

                    ctrl.data = {
                        date: null,
                        type: ctrl.initParams.defaults.componentType,
                        preferredLevel: ctrl.initParams.defaults.preferredLevel
                    };

                    // set the dialog ready
                    ctrl.pageReady = true;
                    ctrl.processing = false;
                }
            }
        ]
    });
