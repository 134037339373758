import './app.directives';
import './compile';
import './ocCancel';
import './ocClickIf';
import './ocDragAndDrop';
import './ocFormSummary';
import './ocOnChange';
import './ocPropertyRow';
import './ocScrollTop';
import './ocSpring';
import './ocTouch';
import './ocUrlVal';
import './ocWindowContainer';
import './ocWindowList';

import './ui/amtGridActionButton';
import './ui/btnCancel';
import './ui/btnPrimary';
import './ui/focusMe';
import './ui/noDirtyCheck';
import './ui/ocAllowedCharacters';
import './ui/ocAttachments';
import './ui/ocCapitalise';
import './ui/ocCheckbox';
import './ui/ocComment';
import './ui/ocCostAccess';
import './ui/ocDateTimePicker';
import './ui/ocDirtyTab';
import './ui/ocDynamicGrid';
import './ui/ocFilePicker';
import './ui/ocForm';
import './ui/ocIgnoreDirty';
import './ui/ocImageViewer';
import './ui/ocInvalid';
import './ui/ocMenu';
import './ui/ocMenuItem';
import './ui/ocMenuSearch';
import './ui/ocPanel';
import './ui/ocParentMenuItem';
import './ui/ocQuickSearch';
import './ui/ocThumbnail';
import './ui/ocTimePicker';
import './ui/selectOnClick';
import './ui/ui.bootstrap.dropdown';
import './ui/uiButterbar';
import './ui/uiModule';
import './ui/uiNav';
import './ui/uiScroll';
import './ui/uiShift';
import './ui/uiToggleClass';
import './ui/chart/ocChart';
import './ui/chart/ocChartLine';
import './ui/chart/ocChartPoint';
import './ui/chart/ocChartPolygon';
import './ui/chart/ocChartRect';
import './ui/chart/ocChartScrollbar';
import './ui/chart/ocChartText';
import './ui/dialog/ocDialog';

import './ui/property-row.component';