//import angular from 'angular';
import FileManagement from '../../../../services/fileManagement';
import OcDateSvc from '../../../../services/ocDateSvc';
import AdminUrls from '../../adminUrls';

    angular.module('app.admin').factory('specificationAttachmentSvc',
        [
            'amtXlatSvc', 'WindowFactory', 'amtCommandQuerySvc', 'confirmSvc', 'notifySvc', 'ocSecuritySvc', 'adminUrls', 'fileManagement', 'ocDateSvc', 'errorReporter',
            function (
                amtXlatSvc: IAmtXlatSvc, WindowFactory: IWindowFactory, amtCommandQuerySvc, confirmSvc, notifySvc, ocSecuritySvc, adminUrls: AdminUrls, fileManagement: FileManagement, ocDateSvc: OcDateSvc, errorReporter
            ) {
                var svc = this;

                var baseUrl = 'admin/specifications/';
                var baseAPIUrl = 'api/' + baseUrl;

                //TODO: make this a string enum (is shared with other code, can't be here would have to be moved to another file)
                svc.modes = {
                    edit: 'edit',
                    add: 'add',
                    view: 'view'
                };

                svc.resourcePrefix = 'specifications.attachment_';

                svc.urls = {
                    getSites: adminUrls.getSites,
                    getClients: adminUrls.getClients,
                    list: baseUrl + 'listAttachments',
                    save: baseUrl + 'saveAttachment',
                    load: baseUrl + 'getAttachment',
                    delete: baseUrl + 'deleteAttachment'
                };

                svc.permissionName = 'Security.Settings.SpecificationsVehicle.Documents';

                svc.canAdd = function (client, site) {
                    return ocSecuritySvc.isAuthorised(svc.permissionName, AccessTypes.readWrite,
                                                        client ? client.key : null,
                                                        site ? site.key : null);
                };

                // delete the attachment
                svc.deleteAsync = async function (item, client, site) {
                    try {
                        let siteMsg = site ? site.name : (client ? client.name : amtXlatSvc.xlat("common.sites"));
                        await confirmSvc.confirmMessage(svc.resourcePrefix + 'ConfirmDelete_title', svc.resourcePrefix + 'ConfirmDelete_body', item.name, siteMsg);
                    } catch {
                        return; // nothing to do here, the user bailed out
                    }

                    try {
                        let criteria = {
                            id: item.attachmentId,
                            systemHierarchyId: site ? site.key : (client ? client.key : null)
                        };

                        await amtCommandQuerySvc.post(svc.urls.delete, criteria);
                        notifySvc.success(amtXlatSvc.xlat(svc.resourcePrefix + 'DeleteSuccess'));
                    } catch (error) {
                        await amtCommandQuerySvc.handleError(error);
                    }
                };

                svc.openItem = function (item, refreshCallback) {
                    svc.open(item, item.canEdit ? svc.modes.edit : svc.modes.view, refreshCallback);
                };

                svc.loadAsync = async function (id) {
                    return await amtCommandQuerySvc.post(svc.urls.load, { id: id });
                };

                svc.list = async function (criteria) {
                    return await amtCommandQuerySvc.post(svc.urls.list, criteria);
                };

                // create/edit attachment dialog
                svc.open = function (item, mode, refreshCallback) {
                    let wfItem = {
                        component: 'vehicle-specification-attachment',
                        caption: amtXlatSvc.xlat(svc.resourcePrefix + (mode == svc.modes.add ? 'addAttachment' : 'editAttachment')),
                        modal: true,
                        initParams: { mode: mode, data: item },
                        onDataChangeHandler: refreshCallback,
                        width: 650
                    };

                    WindowFactory.openItem(wfItem);
                };

                svc.saveAsync = async function (data, mode) {

                    if (!data.name)
                        throw amtXlatSvc.xlat(svc.resourcePrefix + 'Mandatory', amtXlatSvc.xlat(svc.resourcePrefix + 'Name'));                    

                    if (mode == svc.modes.add)
                        await fileManagement.processFileUploads([data]);                    

                    let dataToSave = {
                        createdDate: ocDateSvc.removeLocalTimeZoneOffset(new Date()),
                        systemHierarchyIds: (data.sites && data.sites.length > 0 ? data.sites.map(s => s.key) :
                            (data.clients && data.clients.length > 0 ? data.clients.map(s => s.key) : [])),
                        id: data.id,
                        attachmentId: data.attachmentId,
                        name: data.name,
                        description: data.description,
                        comment: data.comment,
                        equipmentTypeSpecificationTypeId: data.equipmentTypeSpecificationTypeId
                    };

                    let result = (await amtCommandQuerySvc.post(svc.urls.save, { data: dataToSave })).data;

                    return result;
                };

                // create a blank attachment record
                svc.create = async function (equipmentTypeSpecificationTypeId, client, site) {
                    return {
                        equipmentTypeSpecificationTypeId: equipmentTypeSpecificationTypeId,
                        clients: client ? [client] : null,
                        sites: site ? [site] : null
                    };
                };

                // create a new blank attachment and fire up the edit dialog
                svc.add = async function (equipmentTypeSpecificationTypeId, client, site, refreshCallback) {
                    let item = await svc.create(equipmentTypeSpecificationTypeId, client, site);
                    svc.open(item, svc.modes.add, refreshCallback);
                };

                return svc;
            }
        ]
    );
