/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var button_service_1 = require("./button.service");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var kendo_licensing_1 = require("@progress/kendo-licensing");
var package_metadata_1 = require("../package-metadata");
var util_1 = require("../util");
var SPAN_TAG_NAME = 'SPAN';
/**
 * Represents the Kendo UI Button component for Angular.
 */
var ButtonDirective = /** @class */ (function () {
    function ButtonDirective(element, renderer, service, localization, ngZone) {
        var _this = this;
        this.service = service;
        this.ngZone = ngZone;
        /**
         * Provides visual styling that indicates if the Button is active.
         * By default, `toggleable` is set to `false`.
         */
        this.toggleable = false;
        /**
         * @hidden
         */
        this.role = 'button';
        /**
         * Fires each time the selected state of a toggleable button is changed.
         *
         * The event argument is the new selected state (boolean).
         */
        this.selectedChange = new core_1.EventEmitter();
        /**
         * Fires each time the user clicks the button.
         */
        this.click = new core_1.EventEmitter();
        this.isDisabled = false;
        this.isIcon = false;
        this.isIconClass = false;
        this._size = 'medium';
        this._rounded = 'medium';
        this._shape = 'rectangle';
        this._fillMode = 'solid';
        this._themeColor = 'base';
        this._focused = false;
        this.domEvents = [];
        kendo_licensing_1.validatePackage(package_metadata_1.packageMetadata);
        this.direction = localization.rtl ? 'rtl' : 'ltr';
        this.localizationChangeSubscription = localization.changes.subscribe(function (_a) {
            var rtl = _a.rtl;
            return (_this.direction = rtl ? 'rtl' : 'ltr');
        });
        this.element = element.nativeElement;
        this.renderer = renderer;
    }
    Object.defineProperty(ButtonDirective.prototype, "togglable", {
        /**
         * Backwards-compatible alias
         *
         * @hidden
         */
        get: function () {
            return this.toggleable;
        },
        /**
         * @hidden
         */
        set: function (value) {
            this.toggleable = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonDirective.prototype, "selected", {
        /**
         * Sets the selected state of the Button.
         */
        get: function () {
            return this._selected || false;
        },
        set: function (value) {
            this._selected = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonDirective.prototype, "tabIndex", {
        get: function () {
            return this.element.tabIndex;
        },
        /**
         * @hidden
         */
        set: function (index) {
            this.element.tabIndex = index;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonDirective.prototype, "icon", {
        /**
         * Defines the name for an existing icon in a Kendo UI theme.
         * The icon is rendered inside the Button by a `span.k-icon` element.
         */
        set: function (icon) {
            var _this = this;
            if (icon) {
                this.iconSetter(icon, function () {
                    _this.isIcon = true;
                    var classes = 'k-button-icon k-icon k-i-' + icon;
                    _this.addIcon(classes);
                });
            }
            else {
                this.isIcon = false;
                this.updateIconNode();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonDirective.prototype, "iconClass", {
        /**
         * Defines a CSS class&mdash;or multiple classes separated by spaces&mdash;
         * which are applied to a `span` element inside the Button. Allows the usage of custom icons.
         */
        set: function (iconClassName) {
            var _this = this;
            if (iconClassName) {
                this.iconSetter(iconClassName, function () {
                    _this.isIconClass = true;
                    var classes = 'k-button-icon ' + iconClassName;
                    _this.addIcon(classes);
                });
            }
            else {
                this.isIconClass = false;
                this.updateIconNode();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonDirective.prototype, "imageUrl", {
        /**
         * Defines a URL which is used for an `img` element inside the Button.
         * The URL can be relative or absolute. If relative, it is evaluated with relation to the web page URL.
         */
        set: function (imageUrl) {
            if (imageUrl) {
                this.iconSetter(imageUrl, this.addImgIcon.bind(this));
            }
            else {
                this.removeImageNode();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonDirective.prototype, "disabled", {
        get: function () {
            return this.isDisabled;
        },
        /**
         * If set to `true`, it disables the Button.
         */
        set: function (disabled) {
            //Required, because in FF focused buttons are not blurred on disabled
            if (disabled && kendo_angular_common_1.isDocumentAvailable() && util_1.isFirefox(navigator.userAgent)) {
                this.blur();
            }
            this.isDisabled = disabled;
            this.renderer.setProperty(this.element, 'disabled', disabled);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonDirective.prototype, "size", {
        get: function () {
            return this._size;
        },
        /**
         * The size property specifies the padding of the Button
         * ([see example]({% slug appearance_button %}#toc-size)).
         *
         * The possible values are:
         * * `'small'`
         * * `'medium'` (default)
         * * `'large'`
         * * `null`
         */
        set: function (size) {
            this.handleClasses(size, 'size');
            this._size = size;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonDirective.prototype, "rounded", {
        get: function () {
            return this._rounded;
        },
        /**
         * The rounded property specifies the border radius of the Button
         * ([see example]({% slug appearance_button %}#toc-rounded)).
         *
         * The possible values are:
         * * `'small'`
         * * `'medium'` (default)
         * * `'large'`
         * * `'full'`
         * * `null`
         */
        set: function (rounded) {
            this.handleClasses(rounded, 'rounded');
            this._rounded = rounded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonDirective.prototype, "fillMode", {
        get: function () {
            return this._fillMode;
        },
        /**
         * The fillMode property specifies the background and border styles of the Button
         * ([see example]({% slug appearance_button %}#toc-fillMode)).
         *
         * The possible values are:
         * * `'flat'`
         * * `'solid'` (default)
         * * `'outline'`
         * * `'clear'`
         * * `'link'`
         * * `null`
         */
        set: function (fillMode) {
            this.handleClasses(fillMode, 'fillMode');
            this._fillMode = fillMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonDirective.prototype, "themeColor", {
        get: function () {
            return this._themeColor;
        },
        /**
         * The Button allows you to specify predefined theme colors.
         * The theme color will be applied as a background and border color while also amending the text color accordingly
         * ([see example]({% slug appearance_button %}#toc-themeColor)).
         *
         * The possible values are:
         * * `'base'` (default)
         * * `'primary'`
         * * `'secondary'`
         * * `'tertiary'`
         * * `'info'`
         * * `'success'`
         * * `'warning'`
         * * `'error'`
         * * `'dark'`
         * * `'light`'
         * * `'inverse'`
         */
        set: function (themeColor) {
            this.handleThemeColor(themeColor);
            this._themeColor = themeColor;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonDirective.prototype, "shape", {
        get: function () {
            return this._shape;
        },
        /**
         * The shape property specifies if the Button will form a rectangle or square.
         * ([see example]({% slug appearance_button %}#toc-shape)).
         *
         * The possible values are:
         * * `'square'`
         * * `'rectangle'` (default)
         */
        set: function (shape) {
            this.handleClasses(shape, 'shape');
            this._shape = shape;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonDirective.prototype, "isFocused", {
        get: function () {
            return this._focused;
        },
        set: function (isFocused) {
            this.toggleClass('k-focus', isFocused);
            this._focused = isFocused;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonDirective.prototype, "classButton", {
        get: function () {
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonDirective.prototype, "isToggleable", {
        get: function () {
            return this.toggleable;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonDirective.prototype, "roleSetter", {
        get: function () {
            return this.role;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonDirective.prototype, "classDisabled", {
        get: function () {
            return this.isDisabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonDirective.prototype, "classActive", {
        get: function () {
            return this.selected;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonDirective.prototype, "getDirection", {
        get: function () {
            return this.direction;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @hidden
     */
    ButtonDirective.prototype.onFocus = function () {
        this.isFocused = true;
    };
    /**
     * @hidden
     */
    ButtonDirective.prototype.onBlur = function () {
        this.isFocused = false;
    };
    Object.defineProperty(ButtonDirective.prototype, "primary", {
        /**
         * @hidden
         */
        set: function (value) {
            this.themeColor = value ? 'primary' : 'base';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonDirective.prototype, "look", {
        /**
         * @hidden
         */
        set: function (value) {
            switch (value) {
                case 'default':
                    this.fillMode = 'solid';
                    break;
                default:
                    this.fillMode = value;
                    break;
            }
        },
        enumerable: true,
        configurable: true
    });
    ButtonDirective.prototype.ngOnInit = function () {
        var _this = this;
        var isSpan = this.element.tagName === SPAN_TAG_NAME;
        this.addTextSpan();
        if (!this.element.hasAttribute('role') && this.togglable) {
            this.toggleAriaPressed(this.toggleable);
        }
        if (this.role) {
            this.setAttribute('role', this.role);
        }
        this.ngZone.runOutsideAngular(function () {
            _this.domEvents.push(_this.renderer.listen(_this.element, 'click', _this._onButtonClick.bind(_this)));
            _this.domEvents.push(_this.renderer.listen(_this.element, 'keydown', function (event) {
                var isSpaceOrEnter = event.keyCode === kendo_angular_common_1.Keys.Space || event.keyCode === kendo_angular_common_1.Keys.Enter;
                if (isSpan && isSpaceOrEnter) {
                    _this.click.emit(event);
                    _this._onButtonClick();
                }
            }));
        });
    };
    ButtonDirective.prototype.ngOnChanges = function (change) {
        if (kendo_angular_common_1.isChanged('togglable', change) || kendo_angular_common_1.isChanged('toggleable', change)) {
            this.toggleAriaPressed(this.toggleable);
        }
    };
    ButtonDirective.prototype.ngAfterViewInit = function () {
        var _this = this;
        var stylingOptions = ['size', 'rounded', 'shape', 'fillMode'];
        stylingOptions.forEach(function (input) {
            _this.handleClasses(_this[input], input);
        });
    };
    ButtonDirective.prototype.ngAfterViewChecked = function () {
        this.setIconTextClasses();
    };
    ButtonDirective.prototype.ngOnDestroy = function () {
        this.imageNode = null;
        this.iconNode = null;
        this.iconSpanNode = null;
        this.renderer = null;
        this.localizationChangeSubscription.unsubscribe();
        clearTimeout(this.deferTimeout);
        this.domEvents.forEach(function (unbindHandler) { return unbindHandler(); });
    };
    /**
     * Focuses the Button component.
     */
    ButtonDirective.prototype.focus = function () {
        if (kendo_angular_common_1.isDocumentAvailable()) {
            this.element.focus();
            this.isFocused = true;
        }
    };
    /**
     * Blurs the Button component.
     */
    ButtonDirective.prototype.blur = function () {
        if (kendo_angular_common_1.isDocumentAvailable()) {
            this.element.blur();
            this.isFocused = false;
        }
    };
    /**
     * @hidden
     */
    ButtonDirective.prototype.setAttribute = function (attribute, value) {
        this.renderer.setAttribute(this.element, attribute, value);
    };
    /**
     * @hidden
     */
    ButtonDirective.prototype.removeAttribute = function (attribute) {
        this.renderer.removeAttribute(this.element, attribute);
    };
    /**
     * @hidden
     *
     * Internal setter that triggers selectedChange
     */
    ButtonDirective.prototype.setSelected = function (value) {
        var _this = this;
        var changed = this.selected !== value;
        this.selected = value;
        this.setAttribute('aria-pressed', this.selected.toString());
        this.toggleClass('k-selected', this.selected);
        if (changed && kendo_angular_common_1.hasObservers(this.selectedChange)) {
            this.ngZone.run(function () {
                _this.selectedChange.emit(value);
            });
        }
    };
    ButtonDirective.prototype.toggleAriaPressed = function (shouldSet) {
        if (!kendo_angular_common_1.isDocumentAvailable()) {
            return;
        }
        if (shouldSet) {
            this.setAttribute('aria-pressed', this.selected.toString());
        }
        else {
            this.removeAttribute('aria-pressed');
        }
    };
    ButtonDirective.prototype.hasText = function () {
        return kendo_angular_common_1.isDocumentAvailable() && this.element.textContent.trim().length > 0;
    };
    ButtonDirective.prototype.addImgIcon = function (imageUrl) {
        var renderer = this.renderer;
        if (!this.iconSpanNode) {
            this.iconSpanNode = renderer.createElement('span');
            renderer.setProperty(this.iconSpanNode, 'className', 'k-button-icon k-icon');
        }
        if (this.imageNode) {
            renderer.setProperty(this.imageNode, 'src', imageUrl);
        }
        else if (kendo_angular_common_1.isDocumentAvailable()) {
            this.imageNode = renderer.createElement('img');
            renderer.setProperty(this.imageNode, 'src', imageUrl);
            renderer.setProperty(this.imageNode, 'className', 'k-image');
            renderer.setAttribute(this.imageNode, 'role', 'presentation');
        }
        this.iconSpanNode.appendChild(this.imageNode);
        this.prependChild(this.iconSpanNode);
    };
    ButtonDirective.prototype.addIcon = function (classNames) {
        var renderer = this.renderer;
        if (this.iconNode) {
            renderer.setProperty(this.iconNode, 'className', classNames);
        }
        else if (kendo_angular_common_1.isDocumentAvailable()) {
            this.iconNode = renderer.createElement('span');
            renderer.setProperty(this.iconNode, 'className', classNames);
            renderer.setAttribute(this.iconNode, 'role', 'presentation');
            this.prependChild(this.iconNode);
        }
    };
    ButtonDirective.prototype.addTextSpan = function () {
        var _this = this;
        if (kendo_angular_common_1.isDocumentAvailable() && this.hasText()) {
            var span_1 = this.renderer.createElement('span');
            this.renderer.addClass(span_1, 'k-button-text');
            var buttonContentNodes = Array.from(this.element.childNodes);
            buttonContentNodes.forEach(function (node) { return _this.renderer.appendChild(span_1, node); });
            this.renderer.appendChild(this.element, span_1);
        }
    };
    ButtonDirective.prototype.prependChild = function (node) {
        var _this = this;
        this.defer(function () {
            if (_this.renderer && node !== _this.element.firstChild) {
                _this.renderer.insertBefore(_this.element, node, _this.element.firstChild);
            }
        });
    };
    ButtonDirective.prototype.defer = function (callback) {
        var _this = this;
        this.ngZone.runOutsideAngular(function () {
            _this.deferTimeout = setTimeout(callback, 0);
        });
    };
    ButtonDirective.prototype.iconSetter = function (icon, insertIcon) {
        if (icon) {
            insertIcon(icon);
        }
        this.setIconTextClasses();
    };
    ButtonDirective.prototype.removeImageNode = function () {
        if (this.imageNode && this.renderer.parentNode(this.imageNode)) {
            this.renderer.removeChild(this.element, this.imageNode);
            this.renderer.removeChild(this.element, this.iconSpanNode);
            this.imageNode = null;
            this.iconSpanNode = null;
        }
    };
    ButtonDirective.prototype.removeIconNode = function () {
        if (this.iconNode && this.renderer.parentNode(this.iconNode)) {
            this.renderer.removeChild(this.element, this.iconNode);
            this.iconNode = null;
        }
        if (this.iconSpanNode) {
            this.renderer.removeChild(this.element, this.iconSpanNode);
            this.iconSpanNode = null;
        }
    };
    ButtonDirective.prototype.updateIconNode = function () {
        if (!this.isIcon && !this.isIconClass) {
            this.removeIconNode();
        }
    };
    ButtonDirective.prototype.setIconTextClasses = function () {
        var hasIcon = this.isIcon || this.isIconClass || this.imageNode;
        this.toggleClass('k-icon-button', hasIcon && !this.hasText());
    };
    ButtonDirective.prototype.toggleClass = function (className, add) {
        if (add) {
            this.renderer.addClass(this.element, className);
        }
        else {
            this.renderer.removeClass(this.element, className);
        }
    };
    ButtonDirective.prototype._onButtonClick = function () {
        var _this = this;
        if (!this.disabled && this.service) {
            this.ngZone.run(function () {
                _this.service.click(_this);
            });
        }
        if (this.togglable && !this.service) {
            this.setSelected(!this.selected);
        }
    };
    ButtonDirective.prototype.handleClasses = function (value, input) {
        var elem = this.element;
        var classes = util_1.getStylingClasses('button', input, this[input], value);
        if (input === 'fillMode') {
            this.handleThemeColor(this.themeColor, this[input], value);
        }
        if (classes.toRemove) {
            this.renderer.removeClass(elem, classes.toRemove);
        }
        if (classes.toAdd) {
            this.renderer.addClass(elem, classes.toAdd);
        }
    };
    ButtonDirective.prototype.handleThemeColor = function (value, prevFillMode, fillMode) {
        var elem = this.element;
        var removeFillMode = prevFillMode ? prevFillMode : this.fillMode;
        var addFillMode = fillMode ? fillMode : this.fillMode;
        var themeColorClass = util_1.getThemeColorClasses('button', removeFillMode, addFillMode, this.themeColor, value);
        this.renderer.removeClass(elem, themeColorClass.toRemove);
        if (addFillMode !== null && fillMode !== null) {
            if (themeColorClass.toAdd) {
                this.renderer.addClass(elem, themeColorClass.toAdd);
            }
        }
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], ButtonDirective.prototype, "toggleable", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [Boolean])
    ], ButtonDirective.prototype, "togglable", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [Boolean])
    ], ButtonDirective.prototype, "selected", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number),
        tslib_1.__metadata("design:paramtypes", [Number])
    ], ButtonDirective.prototype, "tabIndex", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], ButtonDirective.prototype, "icon", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], ButtonDirective.prototype, "iconClass", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], ButtonDirective.prototype, "imageUrl", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [Boolean])
    ], ButtonDirective.prototype, "disabled", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], ButtonDirective.prototype, "size", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], ButtonDirective.prototype, "rounded", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], ButtonDirective.prototype, "fillMode", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], ButtonDirective.prototype, "themeColor", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], ButtonDirective.prototype, "shape", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ButtonDirective.prototype, "role", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ButtonDirective.prototype, "selectedChange", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ButtonDirective.prototype, "click", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-button'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ButtonDirective.prototype, "classButton", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-toggle-button'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ButtonDirective.prototype, "isToggleable", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.role'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], ButtonDirective.prototype, "roleSetter", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.aria-disabled'),
        core_1.HostBinding('class.k-disabled'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ButtonDirective.prototype, "classDisabled", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-selected'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ButtonDirective.prototype, "classActive", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.dir'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], ButtonDirective.prototype, "getDirection", null);
    tslib_1.__decorate([
        core_1.HostListener('focus'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", void 0)
    ], ButtonDirective.prototype, "onFocus", null);
    tslib_1.__decorate([
        core_1.HostListener('blur'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", void 0)
    ], ButtonDirective.prototype, "onBlur", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [Boolean])
    ], ButtonDirective.prototype, "primary", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], ButtonDirective.prototype, "look", null);
    ButtonDirective = tslib_1.__decorate([
        core_1.Directive({
            exportAs: 'kendoButton',
            providers: [
                kendo_angular_l10n_1.LocalizationService,
                {
                    provide: kendo_angular_l10n_1.L10N_PREFIX,
                    useValue: 'kendo.button'
                }
            ],
            selector: 'button[kendoButton], span[kendoButton]',
        }),
        tslib_1.__param(2, core_1.Optional()),
        tslib_1.__metadata("design:paramtypes", [core_1.ElementRef,
            core_1.Renderer2,
            button_service_1.KendoButtonService,
            kendo_angular_l10n_1.LocalizationService,
            core_1.NgZone])
    ], ButtonDirective);
    return ButtonDirective;
}());
exports.ButtonDirective = ButtonDirective;
