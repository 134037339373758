//import angular from 'angular';
import { saveAs } from 'file-saver';
import OcDateSvc from './ocDateSvc';



export const enum ExportReports {
    componentAudit = 'ComponentAudit',
    fieldSurvey = 'FieldSurvey',
    blankPhysicalStocktake = 'BlankPhysicalStocktake',
    physicalStocktake = 'PhysicalStocktake',
    componentFailure = 'ComponentFailure'
}

export const enum ExportProviders {
    excel = 'Excel',
    pdf = 'PDF',
    csv = 'CSV',
    image = 'Image'
}

export const enum ExportOrientation {
    landscape = 'Landscape',
    portrait = 'Portrait'
}

export const enum ExportReportParameters {
    site = 'Site',
    client = 'Client',
    siteId = 'SiteId',
    clientId = 'ClientId',
    culture = 'Culture',
    reportName = 'ReportName',
    reportGroup = 'ReportGroup',
    userName = 'UserName',
    isBlank = 'IsBlank',
    siteList = 'SiteList',
    loggedInSite = 'LoggedInSite'
}

export const enum ExportReportParameterDefaults {
    empty = 'Empty',
    all = 'All',
    null = '$N$',
    any = '$*$'
}

export interface ExportReportCriteria {
    report: any;
    provider: string;
    parameters?: any[];
    filename?: string;
    suppressNotify?: boolean;
    orientation?: string;
}

export default class ReportSvc {

    static $inject = ['amtCommandQuerySvc', 'ocConfigSvc', 'errorReporter', 'ocDateSvc', 'amtXlatSvc', 'notifySvc', '$http'];

    constructor(private amtCommandQuerySvc: IAmtCommandQuerySvc, private ocConfigSvc: IOcConfigSvc, private errorReporter: IErrorReporter,
        private ocDateSvc: OcDateSvc, private amtXlatSvc: IAmtXlatSvc, private notifySvc: INotifySvc, private $http: ng.IHttpService) {
    }

    async getReports(suppressSession: boolean, updateReports = true) {

        try {

            let response = await this.amtCommandQuerySvc.post('reports/getReportList', {
                siteId: this.ocConfigSvc.user.site.id,
                personId: this.ocConfigSvc.user.personId,
                siteName: this.ocConfigSvc.user.site.name,
                cultureInfo: this.ocConfigSvc.user.cultureInfo,
                clientName: this.ocConfigSvc.user.client.name,
                existingSessionId: localStorage.getItem('dundasSessionId'),
                suppressSession: suppressSession
            });

            if (suppressSession)
                localStorage.removeItem('dundasSessionId');

            if (!response)
                throw Error();

            if (updateReports)
                this.ocConfigSvc.updateUserReports(response);

            return response;

        } catch (error) {
            this.errorReporter.logError(error, 'Reports-GetReportList', true);
        }
    }

    getReport(reportName: string) {

        let report = null;

        if (this.ocConfigSvc.reports && this.ocConfigSvc.reports.reports.length) {

            for (let r of this.ocConfigSvc.reports.reports) {
                if (r.isContainer) {
                    let rp = r.children.find(c => c.name.replace(" ", "").toLowerCase() === reportName.replace(" ", "").toLowerCase());

                    if (rp) report = rp;
                }
            }
        }

        return report;
    }

    constructBaseParameters(report: any) {

        let parameters = [];

        parameters = this.constructParameter(parameters, report, ExportReportParameters.reportName, null);
        parameters = this.constructParameter(parameters, report, ExportReportParameters.site, null);
        parameters = this.constructParameter(parameters, report, ExportReportParameters.client, null);
        parameters = this.constructParameter(parameters, report, ExportReportParameters.siteId, null);
        parameters = this.constructParameter(parameters, report, ExportReportParameters.clientId, null);
        parameters = this.constructParameter(parameters, report, ExportReportParameters.culture, null);
        parameters = this.constructParameter(parameters, report, ExportReportParameters.reportGroup, null);
        parameters = this.constructParameter(parameters, report, ExportReportParameters.siteList, null);
        parameters = this.constructParameter(parameters, report, ExportReportParameters.loggedInSite, null);

        return parameters;
    }

    constructParameter(parameters: any[], report: any, parameter: string, values: any) {

        let newParam: boolean = false;

        if (values != null && !angular.isArray(values))
            values = [values];

        let paramDetails = report.parameters.find(p => p.name.toLowerCase() === parameter.toLowerCase());

        if (paramDetails) {

            let p = parameters.find(pa => pa.name === paramDetails.name);

            if (!p) {
                p = {
                    name: paramDetails.name,
                    unformattedValue: paramDetails.format,
                    value: null
                };

                newParam = true;
            }

            let value = p.unformattedValue;

            value = value.replace('{ReportName}', report.name.replace(" ", ""));
            value = value.replace('{SiteId}', this.ocConfigSvc.user.site.id);
            value = value.replace('{SiteName}', this.ocConfigSvc.user.site.name);
            value = value.replace('{ClientId}', this.ocConfigSvc.user.client.id);
            value = value.replace('{ClientName}', this.ocConfigSvc.user.client.name);
            value = value.replace('{Culture}', this.ocConfigSvc.user.cultureInfo);
            value = value.replace('{ReportGroup}', report.parentName.replace(" ", ""));

            if (values != null && values.length) {

                for (let i = 0; i < values.length; i++)
                    value = value.replace('{Value' + i + '}', values[i] != null ? values[i] : ExportReportParameterDefaults.any);

                value = value.replace('{Value}', values[0] != null ? values[0] : ExportReportParameterDefaults.any);
            }

            if (newParam) {
                p.value = value;
                parameters.push(p);
            } else {
                p.value += ',' + value;
            }
        }

        return parameters;
    }

    async exportReport(criteria: ExportReportCriteria) {

        if (!criteria)
            return;

        let existingSessionId = localStorage.getItem("dundasSessionId");

        let reportDescription = criteria.report.description || JSON.parse(JSON.stringify(criteria.report)).name.replace(/([A-Z])/g, " $1").trim();

        // default filename format (reportname-site-date)
        if (!criteria.filename)
            criteria.filename = String.format("{0}-{1}-{2}", criteria.report.description || criteria.report.name, this.ocConfigSvc.user.site.name, this.ocDateSvc.toReportString(new Date()));

        let sessionId = await this.amtCommandQuerySvc.post('reports/getSessionId', { existingSessionId: existingSessionId });

        // store the sessionId so that we don't create unnecessary sessions for subsequent requests
        localStorage.setItem('dundasSessionId', sessionId);

        let exportProvider = this.ocConfigSvc.reports.exportProviders.find(e => e.name === criteria.provider);

        if (exportProvider) {

            let reportParameters = {};

            for (let p of criteria.parameters || [])
                reportParameters[p.name] = p.value;

            let exportCriteria = {
                //__classType: "dundas.export.ExportRequest",  - just here as an FYI                                     
                sessionId: sessionId,
                reportId: criteria.report.id,
                providerId: exportProvider.id,
                parameters: reportParameters,
                reportName: criteria.report.name,
                orientation: criteria.orientation
            };

            if (!criteria.suppressNotify)
                this.notifySvc.info(this.amtXlatSvc.xlat('reports.generatingReport', reportDescription));

            try {
                // tell dundas to generate the requested pdf export, returns either an error or a GUID to your pending export
                let response = await this.amtCommandQuerySvc.post('reports/exportReport', exportCriteria);

                if (!response)
                    throw new Error('Response not OK.');

                let config = {
                    responseType: 'blob',
                    params: {
                        sessionId: encodeURIComponent(sessionId),
                        isDownloadLink: false
                    }
                };

                // retrieve the pdf export from the dundas server and save on the clients machine
                let exportResponse = await this.$http.get(this.ocConfigSvc.reports.dundasHomeUrl + '/api/export/result/' + encodeURIComponent(response), config);

                if (!exportResponse || !exportResponse.data)
                    throw new Error('Response not OK.');

                saveAs(exportResponse.data as Blob, criteria.filename);

                if (!criteria.suppressNotify)
                    this.notifySvc.success(this.amtXlatSvc.xlat('reports.generatingReportSuccess', reportDescription));

            } catch (error) {
                this.errorReporter.logError(this.amtXlatSvc.xlat('reports.generatingReportFail', reportDescription, this.errorReporter.exceptionMessage(error)));
            }
        }
    }

    downloadComponentFailureReports(serialNumbers: string[]) {

        if (!serialNumbers?.length)
            return;

        try {
            let report = this.getReport(ExportReports.componentFailure);

            if (report) {

                let hasParameters: boolean = report.parameters?.length;
                let batchNotify = serialNumbers.length > 1;
                let parameters = [];

                if (hasParameters) {
                    parameters = this.constructBaseParameters(report);
                    parameters = this.constructParameter(parameters, report, 'Date', new Date().format('dd-MM-yyyy'));
                }                

                for (let serialNumber of serialNumbers) {

                    let exportCriteria: ExportReportCriteria = {
                        report: report,
                        provider: ExportProviders.pdf,
                        suppressNotify: batchNotify,
                        orientation: ExportOrientation.landscape
                    };

                    exportCriteria.parameters = angular.copy(parameters);

                    if (hasParameters)
                        exportCriteria.parameters = this.constructParameter(exportCriteria.parameters, report, 'SerialNumber', serialNumber);
                        // if at any point a scrap date is needed it must be formatted this way: scrapDate.format('yyyy-MM-dd HH:mm:ss\\.fff');

                    exportCriteria.filename = String.format("{0}-{1}-{2}-{3}", report.description || report.name, this.ocConfigSvc.user.site.name, serialNumber.split(".").join(""), this.ocDateSvc.toReportString(new Date()));
                    
                    this.exportReport(exportCriteria);
                }

                if (batchNotify)
                    this.notifySvc.info(this.amtXlatSvc.xlat('reports.generatingReports', report.description || report.name));
            }

        } catch (error) {
            this.errorReporter.logError(error);
        }
    }
}

angular.module('app').service('reportSvc', ReportSvc);