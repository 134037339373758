//import angular from 'angular';
import * as _ from 'underscore';
import FileManagement from '../../../../../services/fileManagement';
import OcCacheSvc from '../../../../../services/ocCacheSvc';



    angular.module('app.admin').factory('sysOrgClientSvc',
        [
            'amtXlatSvc', 'WindowFactory', 'amtCommandQuerySvc', 'exportSvc', '$q', 'ocCacheSvc', 'fileManagement', 'enums',
            function (
                amtXlatSvc: IAmtXlatSvc, WindowFactory: IWindowFactory, amtCommandQuerySvc: IAmtCommandQuerySvc, exportSvc, $q, ocCacheSvc: OcCacheSvc, fileManagement: FileManagement, enums
            ) {
                var svc = this;
                 
                svc.mainPermission = "Security.Settings.Organisation.Client";

                svc.detailsController = 'sys-org-client-details';

                svc.baseUrl = 'admin/client/';
                svc.baseAPIUrl = baseApiUrl + svc.baseUrl;

                svc.searchUrl = svc.baseAPIUrl + 'search';
                svc.exportUrl = svc.baseUrl + 'export';
                svc.saveUrl = svc.baseUrl + 'save';
                svc.getClientsUrl = baseApiUrl + 'systemhierarchy/getClients';
                svc.getConfigurationTypesUrl = 'admin/configuration/getConfigurationTypes';
                svc.getCurrencyTypesUrl = 'referenceData/getCurrencyTypes';
                svc.getDefaultsUrl = svc.baseUrl + 'getDefaults';

                svc.searchAsync = function (params) {
                    return amtCommandQuerySvc.post(svc.baseUrl + 'search', params);
                };

                svc.openSite = function (site, cb) {
                    WindowFactory.openItem({
                        component: 'add-edit-site',
                        caption: amtXlatSvc.xlat("site.editSite"),
                        initParams: { id: site.id },
                        width: 550,
                        windowRelatedRecordId: site.id
                    });
                };

                ocCacheSvc.getItem('currencyTypes', async () => await amtCommandQuerySvc.get(svc.getCurrencyTypesUrl, {}))
                          .then( items => { svc.currencyTypes = items } );

                ocCacheSvc.getItem('configurationTypes', async () => await amtCommandQuerySvc.post(svc.getConfigurationTypesUrl, {}))
                          .then( items => {
                                svc.configurationTypes = items;
                                svc.backgroundColors = _.find(svc.configurationTypes, item => item.name == "Client.Branding.WebLogoBackground").lookupValues;
                          });

                svc.getAsync = function (id) {
                    return $q(function (resolve, reject) {
                        amtCommandQuerySvc.post(svc.baseUrl + "getDetails", { id: id }).then(resolve, reject);
                    });
                };

                svc.export = function (params) {
                    exportSvc.exportData(svc.exportUrl, params, amtXlatSvc.xlat('client.searchTitle'));
                };

                svc.reset = function () {

                    return svc.getDefaults().then(function (response) {
                        return {
                            id: null,
                            name: null,
                            active: false,
                            displayInSystem: false,                            
                            mode: 'new',
                            webLogoBackgroundValue: response.webLogoBackgroundValue
                        };
                    });
                };

                svc.create = function (callback) {
                    return svc.reset().then(function (item) {
                        return svc.openItem(item, callback, amtXlatSvc.xlat('client.create'));
                    });
                };

                svc.openItem = function (item, callback, title) {
                    WindowFactory.openItem({
                        component: svc.detailsController,
                        caption: title,
                        initParams: item,
                        canClose: true,
                        width: 700,
                        modal: false,
                        onDataChangeHandler: callback,
                        windowRelatedRecordId: item.id
                    });
                };

                svc.getActiveStates = function () {
                    return [
                        {
                            key: true,
                            description: amtXlatSvc.xlat('client.activeTrue'),
                            default: true
                        },
                        {
                            key: false,
                            description: amtXlatSvc.xlat('client.activeFalse'),
                            default: false
                        }
                    ];
                };

                svc.activeStates = svc.getActiveStates();
            
                svc.getDefaults = function () {
                    return amtCommandQuerySvc.post(svc.getDefaultsUrl, {});
                };

                ocCacheSvc.getItem('countries', async () => await amtCommandQuerySvc.get(svc.baseUrl + 'getCountries', {}))
                    .then(items => {
                        svc.countries = items;
                        svc.test2 = "testtest";
                        svc.test3 = items.count();
                    });

                svc.testFunc = function () {
                    return async () => await amtCommandQuerySvc.get(svc.baseUrl + 'getCountries', {});
                };
                svc.test = svc.testFunc();

                return svc;
            }
        ]
    );
