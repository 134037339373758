/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var kendo_angular_intl_1 = require("@progress/kendo-angular-intl");
var kendo_date_math_1 = require("@progress/kendo-date-math");
var view_enum_1 = require("./models/view.enum");
var bus_view_service_1 = require("./services/bus-view.service");
var defaults_1 = require("../defaults");
var util_1 = require("../util");
var disabled_dates_service_1 = require("./services/disabled-dates.service");
var rxjs_1 = require("rxjs");
/**
 * @hidden
 */
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(bus, cdr, localization, intl, disabledDatesService) {
        this.bus = bus;
        this.cdr = cdr;
        this.localization = localization;
        this.intl = intl;
        this.disabledDatesService = disabledDatesService;
        this.navigate = true;
        this.todayAvailable = true;
        this.min = new Date(defaults_1.MIN_DATE);
        this.max = new Date(defaults_1.MAX_DATE);
        this.rangeLength = 1;
        this.isPrevDisabled = true;
        this.isNextDisabled = true;
        this.showNavigationButtons = false;
        this.todayButtonClick = new core_1.EventEmitter();
        this.prevButtonClick = new core_1.EventEmitter();
        this.nextButtonClick = new core_1.EventEmitter();
        this.getComponentClass = true;
        this.subscriptions = new rxjs_1.Subscription();
    }
    HeaderComponent.prototype.ngOnInit = function () {
        this.subscriptions.add(this.intl.changes.subscribe(this.intlChange.bind(this)));
        this.subscriptions.add(this.localization.changes.subscribe(this.l10nChange.bind(this)));
        this.subscriptions.add(this.disabledDatesService.changes.subscribe(this.setTodayAvailability.bind(this)));
    };
    HeaderComponent.prototype.ngOnChanges = function (_) {
        var service = this.bus.service(this.activeView);
        if (!service) {
            return;
        }
        this.activeViewValue = view_enum_1.CalendarViewEnum[this.activeView];
        this.todayMessage = this.localization.get('today');
        this.setTodayAvailability();
        this.navigate = this.bus.canMoveUp(this.activeView);
        this.title = this.getTitle();
    };
    HeaderComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    HeaderComponent.prototype.handleTodayClick = function () {
        if (!this.todayAvailable) {
            return;
        }
        this.bus.moveToBottom(this.activeView);
        this.todayButtonClick.emit(util_1.dateInRange(util_1.getToday(), this.min, this.max));
    };
    HeaderComponent.prototype.handleNavigation = function () {
        if (!this.navigate) {
            return;
        }
        this.bus.moveUp(this.activeView);
    };
    HeaderComponent.prototype.intlChange = function () {
        if (this.currentDate && this.bus.service(this.activeView)) {
            this.title = this.getTitle();
            this.cdr.markForCheck();
        }
    };
    HeaderComponent.prototype.l10nChange = function () {
        this.prevButtonTitle = this.localization.get('prevButtonTitle');
        this.nextButtonTitle = this.localization.get('nextButtonTitle');
        this.todayMessage = this.localization.get('today');
        this.cdr.markForCheck();
    };
    HeaderComponent.prototype.getTitle = function () {
        if (!this.currentDate) {
            return '';
        }
        var service = this.bus.service(this.activeView);
        var take = this.rangeLength - 1;
        var title = service.title(this.currentDate);
        var nextDate = service.addToDate(this.currentDate, take);
        if (take < 1 || !service.isInRange(nextDate, this.min, this.max)) {
            return title;
        }
        return title + " - " + service.title(nextDate);
    };
    HeaderComponent.prototype.setTodayAvailability = function () {
        var today = util_1.getToday();
        var isTodayInRange = util_1.isInRange(today, kendo_date_math_1.getDate(this.min), kendo_date_math_1.getDate(this.max));
        var isDisabled = this.disabledDatesService.isDateDisabled(today);
        this.todayAvailable = isTodayInRange && !isDisabled;
        this.cdr.markForCheck();
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], HeaderComponent.prototype, "activeView", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Date)
    ], HeaderComponent.prototype, "currentDate", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Date)
    ], HeaderComponent.prototype, "min", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Date)
    ], HeaderComponent.prototype, "max", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], HeaderComponent.prototype, "rangeLength", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", core_1.TemplateRef)
    ], HeaderComponent.prototype, "templateRef", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], HeaderComponent.prototype, "isPrevDisabled", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], HeaderComponent.prototype, "isNextDisabled", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], HeaderComponent.prototype, "showNavigationButtons", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], HeaderComponent.prototype, "todayButtonClick", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], HeaderComponent.prototype, "prevButtonClick", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], HeaderComponent.prototype, "nextButtonClick", void 0);
    tslib_1.__decorate([
        core_1.HostBinding("class.k-calendar-header"),
        core_1.HostBinding("class.k-hstack"),
        tslib_1.__metadata("design:type", Boolean)
    ], HeaderComponent.prototype, "getComponentClass", void 0);
    HeaderComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-calendar-header',
            template: "\n    <span class=\"k-button k-button-md k-rounded-md k-button-flat k-button-flat-base k-calendar-title\" [class.k-disabled]=\"!navigate\"\n        [kendoEventsOutsideAngular]=\"{\n            click: handleNavigation\n        }\"\n        [scope]=\"this\">\n        <ng-template [ngIf]=\"!templateRef\">{{title}}</ng-template>\n        <ng-template\n            [ngIf]=\"templateRef\"\n            [ngTemplateOutlet]=\"templateRef\"\n            [ngTemplateOutletContext]=\"{ $implicit: title, activeView: activeViewValue, date: currentDate }\"\n        ></ng-template>\n    </span>\n    <span class=\"k-spacer\"></span>\n    <span class=\"k-calendar-nav k-hstack\">\n        <button\n            *ngIf=\"showNavigationButtons\"\n            class=\"k-button k-button-md k-rounded-md k-button-flat k-button-flat-base k-icon-button k-prev-view\"\n            type=\"button\"\n            [attr.aria-disabled]=\"isPrevDisabled\"\n            [disabled]=\"isPrevDisabled\"\n            [title]=\"prevButtonTitle\"\n            (click)=\"prevButtonClick.emit()\"\n        >\n            <span class=\"k-icon k-i-arrow-60-left\"></span>\n        </button>\n        <span\n            class=\"k-today k-nav-today\"\n            [class.k-disabled]=\"!todayAvailable\"\n            [kendoEventsOutsideAngular]=\"{\n                click: handleTodayClick\n            }\"\n            [scope]=\"this\"\n        >\n            {{ todayMessage }}\n        </span>\n        <button\n            *ngIf=\"showNavigationButtons\"\n            class=\"k-button k-button-md k-rounded-md k-button-flat k-button-flat-base k-icon-button k-next-view\"\n            type=\"button\"\n            [attr.aria-disabled]=\"isNextDisabled\"\n            [disabled]=\"isNextDisabled\"\n            [title]=\"nextButtonTitle\"\n            (click)=\"nextButtonClick.emit()\"\n        >\n            <span class=\"k-icon k-i-arrow-60-right\"></span>\n        </button>\n    </span>\n  "
        }),
        tslib_1.__metadata("design:paramtypes", [bus_view_service_1.BusViewService,
            core_1.ChangeDetectorRef,
            kendo_angular_l10n_1.LocalizationService,
            kendo_angular_intl_1.IntlService,
            disabled_dates_service_1.DisabledDatesService])
    ], HeaderComponent);
    return HeaderComponent;
}());
exports.HeaderComponent = HeaderComponent;
