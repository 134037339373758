//import angular from 'angular';

    //From: https://stackoverflow.com/a/14837021
    angular.module('app.directives').directive('focusMe', ['$timeout', '$parse', function ($timeout, $parse) {
        return {
            //NB: Don't use an isolated scope for this directive, you only get one per element, and you might want to
            //use this on a component or directive element that has it's own isolate (it's a stupid AngularJS limitation)
            restrict: 'A',
            link: function (scope, element, attrs) {
                let model = $parse(attrs.focusMe);

                let watch = scope.$watch(model, function (value) {
                    //if no value set treat that as a request to focus
                    if (value || value === undefined) {
                        $timeout(() => {
                            //element[0].focus();

                            let selector = 'input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled])';

                            let el = element.is(selector) ? element[0] : element.find(selector).first();

                            if (el && el.focus)
                                el.focus();

                            // if the attribute is settable, set it to false now we've focused so the parent can re-focus later
                            if (model.assign)
                                model.assign(scope, false)
                        });
                    }

                    //if we were given an expression which can't be set, or undefined, unregister the watch (so only focus the one time)
                    if (!model.assign || value === undefined)
                        watch();
                });
            }
        };
    }]);
