/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_pdf_export_1 = require("@progress/kendo-angular-pdf-export");
var PDFMarginComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PDFMarginComponent, _super);
    function PDFMarginComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PDFMarginComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-grid-pdf-margin',
            template: ''
        })
    ], PDFMarginComponent);
    return PDFMarginComponent;
}(kendo_angular_pdf_export_1.PDFExportMarginComponent));
exports.PDFMarginComponent = PDFMarginComponent;
