//import angular from 'angular';

angular.module('app').filter('webPermission', webPermissionFilter);

    // @ts-ignore
    webPermissionFilter.$inject = ['ocSecuritySvc'];
    function webPermissionFilter(ocSecuritySvc) {
        return function (permissionName, minimumLevel, clientId, siteId) {
            return ocSecuritySvc.isAuthorised(permissionName, minimumLevel, clientId, siteId);
        };
    }
