//import angular from 'angular';
import * as _ from 'underscore';



    angular.module("app.site").controller("vehicleSpecificationNewTyreChangeoutPositionsCtrl", vehicleSpecificationNewTyreChangeoutPositionsCtrl);

    // @ts-ignore
    vehicleSpecificationNewTyreChangeoutPositionsCtrl.$inject = ['amtConstants', '$scope', '$state', 'amtCommandQuerySvc', '$rootScope',
        'amtXlatSvc', 'forecastingSvc', 'notifySvc', 'errorReporter', 'ocSecuritySvc', '$window', 'confirmSvc', 'WindowFactory'];

    // ReSharper disable once InconsistentNaming
    function vehicleSpecificationNewTyreChangeoutPositionsCtrl(amtConstants, $scope, $state, amtCommandQuerySvc,
        $rootScope, xlatSvc, forecastingSvc, notifySvc, errorReporter, ocSecuritySvc, $window, confirmSvc, WindowFactory) {

        var vm = this;

        vm.loadingPositions = true;

        vm.apiUrls = amtConstants.url;
        vm.gridControl = {};

        vm.processing = true;

        vm.forecastId = $rootScope.$stateParams.forecastid ? $rootScope.$stateParams.forecastid : 0;

        this.$onInit = function () {
            if (!(vm.forecastId > 0)) {
                $state.go('app.site.forecastlist');
            }

            vm.readonly = !ocSecuritySvc.isAuthorised('Security.Site.Forecasting', 'readWrite');

            vm.getPositions();
        };

        vm.getPositions = function () {

            amtCommandQuerySvc.post(vm.apiUrls.getVehicleSpecificationNewTyreChangeoutPositions, { forecastId: vm.forecastId }).then(function (response) {

                vm.positionColumns = response.positionColumns;
                vm.model = response.vehicleSpecificationTyreStrategies;

                if (vm.gridControl && vm.gridControl.recompile) {
                    vm.gridControl.recompile();
                }

            }).catch(function (error) {
                errorReporter.logError(error);
            }).finally(function () {
                vm.processing = false;
            });
        };

        vm.goToParentRoute = function () {

            confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                vm.form.dirty = false;

                forecastingSvc.setActiveTabName("Tyres");
                $rootScope.goBack();
            });
        };

        vm.save = function () {

            let positions = [];

            vm.model.forEach(vs => {
                angular.forEach(vs, (value, key: string) => {
                    if (key === "defaultTyreStrategies") {
                        angular.forEach(value, position => {
                            positions.push({
                                id: position.id,
                                isNewTyre: position.isNewTyre,
                                overwriteExistingPositionOverrides: position.overwriteExistingPositionOverrides
                            });
                        });
                    }
                });
            });

            vm.processing = true;

            return amtCommandQuerySvc.put(vm.apiUrls.updateVehicleSpecificationNewTyreChangeoutPositions, {
                forecastId: vm.forecastId,
                positions: positions
            }).then(function (response) {

                vm.form.$dirty = false;

                notifySvc.success(xlatSvc.xlat("forecastTyres.vehicleSpecificationNewTyreChangeoutPositionsUpdateSuccess"));

            }).catch(function (error) {
                errorReporter.logError(error);
            }).finally(function () {
                vm.processing = false;
            });
        };

        vm.formatColumn = function (colName) {
            return xlatSvc.xlat('forecastTyres.position', colName.replace("pos", ""));
        };

        vm.resize = function (height, offsetTop) {

            if (!vm.gridHeight) {
                vm.gridTop = height + offsetTop + 10;
            }

            let newHeight = $window.innerHeight - vm.gridTop;

            if (vm.gridHeight != newHeight) {

                vm.gridHeight = newHeight;

                if (vm.gridControl && vm.gridControl.resize) {
                    vm.gridControl.resize();
                }
            }
        };

        $scope.toggleNewTyreChangeoutPosition = function (dataItem, position) {

            let row = _.find(vm.model, m => m.id === dataItem.id);

            if (row && row.defaultTyreStrategies[position]) {
                row.defaultTyreStrategies[position].isNewTyre = !row.defaultTyreStrategies[position].isNewTyre;

                if (row.defaultTyreStrategies[position].vehicleOverridesExist === true) {
                    WindowFactory.alert('vehicleTyreStrategy.overwriteExistingSettings_title', ['common.yes_label', 'common.no_label'], 'vehicleTyreStrategy.overwriteExistingSettings').then(function (response) {
                        row.defaultTyreStrategies[position].overwriteExistingPositionOverrides = response === 'common.yes_label';
                    });
                }
            }
        };

        $scope.viewVehicleTyreStrategyOverrides = function (dataItem) {

            confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                vm.form.dirty = false;

                $state.go("app.site.vehiclerotationstrategies", {
                    forecastId: vm.forecastId,
                    vehicleSpecificationId: dataItem.id,
                    vehicleSpecification: dataItem.vehicleSpec
                });
            });
        };
    }
