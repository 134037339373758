import { Injectable } from "@angular/core";
import { downgradeInjectable } from "@angular/upgrade/static";
import { Subject, timer } from "rxjs";
import { Observable, EMPTY } from "rxjs";
import { shareReplay, catchError, switchMap, takeUntil } from 'rxjs/operators';

export interface ICacheItem {
    data$: Observable<any>;
    reload$: Subject<void>;
}

export const enum CacheKey {
    VisualInspectionCause = 'visualInspectionCause',
    VisualInspectionAction = 'visualInspectionAction',
    VisualInspectionFrequency = 'visualInspectionFrequency'
}

const REFRESH_INTERVAL = 600000; // ms

@Injectable({
    providedIn: 'root'
})
export class CacheService {

    private cache: Record<string, ICacheItem>;

    constructor() {
        this.cache = {};
    }

    getCacheItem(key: CacheKey, fetchMethod: Observable<any>): Observable<any> {

        if (!this.cache[key]) {

            const timer$ = timer(0, REFRESH_INTERVAL);
            const itemReload$ = new Subject<void>();

            let itemData$ = timer$.pipe(
                switchMap(() => fetchMethod.pipe(
                    catchError(err => {
                        this.deleteCacheItem(key);
                        return EMPTY;
                    })
                )),
                takeUntil(itemReload$),
                shareReplay(1)
            );

            this.cache[key] = { reload$: itemReload$, data$: itemData$ } as ICacheItem;
        }

        return this.cache[key].data$;
    }

    deleteCacheItem(key: CacheKey) {
        delete this.cache[key];
    }

    forceReload(key: CacheKey) {

        let cacheItem = this.cache[key];

        if (cacheItem) {
            cacheItem.reload$.next();
            this.deleteCacheItem(key);
        }
    }

    clearCache() {
        this.cache = {};
    }
}

angular.module('app').factory('cacheService', downgradeInjectable(CacheService) as any);