/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var treeview_component_1 = require("../treeview.component");
var utils_1 = require("../utils");
var LOAD_MORE_DOC_LINK = 'http://www.telerik.com/kendo-angular-ui/components/treeview/load-more-button/';
/**
 * A directive that enables the display of only a limited amount of nodes per level
 * ([see example]({% slug loadmorebutton_treeview %})).
 */
var LoadMoreDirective = /** @class */ (function () {
    function LoadMoreDirective(treeview) {
        this.treeview = treeview;
        /**
         * Keeps track of the current page size of each node over expand/collapse cycles.
         */
        this.pageSizes = new Map();
        /**
         * Used as an identifier for the root page size as the root collection of nodes is not associated with a data item.
         */
        this.rootLevelId = kendo_angular_common_1.guid();
        this.treeview.loadMoreService = {
            getInitialPageSize: this.getInitalPageSize.bind(this),
            getGroupSize: this.getGroupSize.bind(this),
            setGroupSize: this.setGroupSize.bind(this),
            getTotalNodesCount: this.getTotalNodesCount.bind(this)
        };
    }
    Object.defineProperty(LoadMoreDirective.prototype, "loadMoreNodes", {
        /**
         * Specifies the callback that will be called when the load more button is clicked.
         * Providing a function is only required when additional nodes are fetched on demand
         * ([see example]({% slug loadmorebutton_treeview %}#toc-remote-data)).
         */
        set: function (loadMoreNodes) {
            if (typeof loadMoreNodes === 'string') {
                return;
            }
            this.treeview.loadMoreService.loadMoreNodes = loadMoreNodes;
        },
        enumerable: true,
        configurable: true
    });
    LoadMoreDirective.prototype.ngOnChanges = function () {
        this.verifySettings();
    };
    LoadMoreDirective.prototype.verifySettings = function () {
        if (!core_1.isDevMode()) {
            return;
        }
        if (!utils_1.isPresent(this.pageSize)) {
            throw new Error("To use the TreeView `kendoTreeViewLoadMore` directive, you need to assign a `pageSize` value. See " + LOAD_MORE_DOC_LINK + ".");
        }
        var loadMoreNodes = this.treeview.loadMoreService.loadMoreNodes;
        if (utils_1.isPresent(loadMoreNodes) && typeof loadMoreNodes !== 'function') {
            throw new Error("The passed value to the `kendoTreeViewLoadMore` directive must be a function that retrieves additional nodes. See " + LOAD_MORE_DOC_LINK + ".");
        }
        if (utils_1.isPresent(loadMoreNodes) && !utils_1.isPresent(this.totalField)) {
            throw new Error("When a function to fetch additional nodes is provided to the `kendoTreeViewLoadMore` directive, the `totalField` and `totalRootNodes` values must also be provided. See " + LOAD_MORE_DOC_LINK + ".");
        }
    };
    LoadMoreDirective.prototype.getGroupSize = function (dataItem) {
        var itemKey = dataItem || this.rootLevelId;
        return this.pageSizes.has(itemKey) ? this.pageSizes.get(itemKey) : this.pageSize;
    };
    LoadMoreDirective.prototype.setGroupSize = function (dataItem, pageSize) {
        var itemKey = dataItem || this.rootLevelId;
        var normalizedSizeValue = pageSize > 0 ? pageSize : 0;
        this.pageSizes.set(itemKey, normalizedSizeValue);
    };
    LoadMoreDirective.prototype.getTotalNodesCount = function (dataItem, loadedNodesCount) {
        if (utils_1.isPresent(dataItem) && utils_1.isPresent(this.totalField)) {
            return dataItem[this.totalField];
        }
        else if (!utils_1.isPresent(dataItem) && utils_1.isPresent(this.totalRootNodes)) {
            return this.totalRootNodes;
        }
        else {
            return loadedNodesCount;
        }
    };
    LoadMoreDirective.prototype.getInitalPageSize = function () {
        return this.pageSize;
    };
    tslib_1.__decorate([
        core_1.Input('kendoTreeViewLoadMore'),
        tslib_1.__metadata("design:type", Object),
        tslib_1.__metadata("design:paramtypes", [Object])
    ], LoadMoreDirective.prototype, "loadMoreNodes", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], LoadMoreDirective.prototype, "pageSize", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], LoadMoreDirective.prototype, "totalRootNodes", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], LoadMoreDirective.prototype, "totalField", void 0);
    LoadMoreDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoTreeViewLoadMore]'
        }),
        tslib_1.__metadata("design:paramtypes", [treeview_component_1.TreeViewComponent])
    ], LoadMoreDirective);
    return LoadMoreDirective;
}());
exports.LoadMoreDirective = LoadMoreDirective;
