//import angular from 'angular';
import AdminUrls from '../adminUrls';
import HelperSvc from '../../../services/helperSvc';

    angular.module('app.admin').controller("sensorsSearchCtrl", sensorsSearchCtrl);

    // @ts-ignore
    sensorsSearchCtrl.$inject = ["adminUrls", "$scope", "enums", "ocSecuritySvc", "amtXlatSvc", "$timeout", "WindowFactory",
                                    "ocConfigSvc", "exportSvc", "helperSvc"];

    // ReSharper disable once InconsistentNaming
    function sensorsSearchCtrl(adminUrls: AdminUrls, $scope, enums, ocSecuritySvc, amtXlatSvc, $timeout, WindowFactory,
                                    ocConfigSvc, exportSvc, helperSvc: HelperSvc) {

        var vm = this;

        vm.processing = false;

        // security
        vm.canAdd = ocSecuritySvc.isAuthorised('Security.Settings.Sensors.Add', 'readWrite');

        vm.showClients = false;
        vm.showSites = false;

        vm.adminUrls = adminUrls;
        vm.config = ocConfigSvc;

        vm.criteria = {
            filterValues: {
                clientIds: [],
                siteIds: [],
                serialNumber: null,
                activeOptions: null,
                componentAttachedTo: null,
                vehicleAttachedTo: null,
                defaultToAllSitesWhenNoSystemHierarchyFilter: true
            }
        };

        vm.activeOptions = [{
            name: amtXlatSvc.xlat('common.yes_label'),
            key: true
        }, {
            name: amtXlatSvc.xlat('common.no_label'),
            key: false
        }];

        $scope.$watch('vm.criteria.filterValues.clientIds.length', function (cnt) {
            if (cnt === 0 && vm.criteria.filterValues.siteIds.length > 0) {
                // reset the sites if the clients are cleared
                vm.criteria.filterValues.siteIds.length = 0;
            }
        });

        vm.status = enums.screenStatus.loading;
        vm.isDirty = true;

        vm.gridControl = {};
        vm.selectedItem = null;

        function init() {
            vm.loadDefaults();
        }

        vm.onSelectedItemChanged = function (items) {
            if (items != null && items.length === 1) {
                vm.selectedItem = items[0];
            } else {
                vm.selectedItem = null;
            }
        };

        vm.setHeaderHeight = function (height, offset) {
            vm.gridTop = height + offset;
        };

        vm.loadDefaults = function () {
            vm.defaults = {
                sites: [{
                    key: vm.config.user.site.id,
                    name: vm.config.user.site.name
                }],
                clients: [{
                    key: vm.config.user.client.id,
                    name: vm.config.user.client.name
                }],
                activeOptions: [vm.activeOptions[0]]
            };

            vm.status = enums.screenStatus.ready;

            // focus on serial number field by default         
            $timeout(() => { vm.focusSerialNumber = true });
        };

        vm.export = function () {
            exportSvc.exportData(vm.adminUrls.searchSensorsExport, vm.criteria, amtXlatSvc.xlat('sensors.searchTitle'));
        };

        vm.onSearchSensors = function (response) {
            var sensors = response.result;

            if (sensors && sensors.length > 0) {
                for (var r = 0; r < sensors.length; r++) {
                    sensors[r].componentSerialNumber = (sensors[r].componentAttachedTo ? sensors[r].componentAttachedTo.serialNumber.formatted : null);
                    sensors[r].vehicleSerialNumber = (sensors[r].vehicleAttachedTo ? sensors[r].vehicleAttachedTo.name : null);
                    sensors[r].onSite = sensors[r].site.id === ocConfigSvc.user.site.id;
                }
            }

            return response;
        };

        $scope.editSensor = function (item) {
            if (item) {
                WindowFactory.openItem({
                    component: 'sensor-details',
                    caption: amtXlatSvc.xlat("sensors.editSensor", item.serialNumber),
                    initParams: item,
                    width: 550,
                    onDataChangeHandler: vm.filter,
                    windowRelatedRecordId: item.id
                });
            }
        };

        $scope.addSensor = function () {
            WindowFactory.openItem({
                component: 'sensor-details',
                caption: amtXlatSvc.xlat("sensors.addSensor"),
                initParams: null,
                width: 550,
                onDataChangeHandler: vm.filter,
                windowRelatedRecordId: uuid()
            });
        };

        $scope.showComponentDetails = function (item) {
            if (item.componentAttachedTo) {
                helperSvc.showComponentDetails({
                    equipmentId: item.componentAttachedTo.id,
                    siteId: item.site.id,
                    onDataChanged: () => vm.filter()
                });
            }
        };

        $scope.showVehicleDetails = function (item) {
            if (item.vehicleAttachedTo) {
                helperSvc.showVehicleDetails({
                    equipmentId: item.vehicleAttachedTo.key,
                    siteId: item.site.id,
                    onDataChanged: () => vm.filter()
                });
            }
        };

        vm.refreshGrid = function () {
            vm.gridControl.refresh();
        };

        vm.filter = function () {
            vm.showClients = vm.criteria.filterValues.clientIds.length !== 1;
            vm.showSites = vm.criteria.filterValues.siteIds.length !== 1;
            vm.refreshGrid();
        };

        init();
    }
