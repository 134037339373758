//import angular from 'angular';
import * as _ from 'underscore';
import Plotly from 'plotly.js-dist';

    angular.module("app.site").controller("forecastOrdersEditCtrl", forecastOrdersEditCtrl);

    // @ts-ignore
    forecastOrdersEditCtrl.$inject = [
        '$rootScope', '$scope', '$state', '$timeout', 'amtCommandQuerySvc', 'amtConstants',
        'amtXlatSvc', 'forecastingSvc', 'notifySvc', 'WindowFactory',
        'errorReporter', 'confirmSvc', 'exportSvc'
    ];

    // ReSharper disable once InconsistentNaming
    function forecastOrdersEditCtrl(
        $rootScope, $scope, $state, $timeout, amtCommandQuerySvc, amtConstants,
        xlatSvc, forecastingSvc, notifySvc, WindowFactory,
        errorReporter, confirmSvc, exportSvc
    ) {

        var vm = this;

        vm.apiUrls = amtConstants.url;
        vm.gridControl = {};

        vm.selectedItem = null;
        vm.selectedId = null;

        vm.forecastId = $rootScope.$stateParams.forecastId ? $rootScope.$stateParams.forecastId : 0;
        vm.forecastStartDate = $rootScope.$stateParams.forecastStartDate;
        vm.forecastEndDate = $rootScope.$stateParams.forecastEndDate;
        vm.componentType = $rootScope.$stateParams.componentType ? $rootScope.$stateParams.componentType : "";
        vm.parentTab = $rootScope.$stateParams.currentTab ? $rootScope.$stateParams.currentTab : "";

        this.$onInit = function () {
            if (vm.forecastId > 0) {
                vm.getColumns();
            } else {
                $state.go('app.site.forecastlist');
            }
        };

        vm.onAddOrder = function () {
            WindowFactory.openItem({
                component: 'order-create-popup',
                canMinimise: false,
                modal: true,
                canClose: false,
                footerOff: false,
                caption: xlatSvc.xlat('forecastOrdersEdit.addOrderPopupTitle'),
                onDataChangeHandler: function () {
                    if (vm.gridControl && vm.gridControl.refresh) {
                        vm.gridControl.refresh();
                    }
                }
            });

        };

        vm.confirmOrders = function () {

            confirmSvc.confirmMessage('equipment.confirmConvertForecastTitle', 'equipment.confirmConvertForecastMessage').then(function () {

                var data = {
                    forecastId: vm.forecastId,
                    componentType: vm.componentType
                };

                vm.processing = true;

                // delete the attachement from the collection
                amtCommandQuerySvc.post(vm.apiUrls.convertForecastToPurchaseOrder, data).then(function () {

                    notifySvc.success(xlatSvc.xlat('equipment.convertForecastSuccess'));

                    if (vm.gridControl && vm.gridControl.refresh) {
                        vm.gridControl.refresh();
                    }

                }, amtCommandQuerySvc.handleError).finally(function () {
                    vm.processing = false;
                });
            });
        };

        vm.goToParentRoute = function () {
            forecastingSvc.setActiveTabName(vm.parentTab);
            $rootScope.goBack();
        };

        vm.getColumns = function () {
            amtCommandQuerySvc.get(vm.apiUrls.getForecastMonths, { forecastId: vm.forecastId }).then(function (response) {

                if (!response || !response.data) {
                    return;
                }

                vm.gridColumns = response.data;

                $timeout(function () {
                    if (vm.gridControl && vm.gridControl.recompile) {
                        vm.gridControl.recompile();
                    }
                });
            });
        };

        vm.onOrdersLoad = function (response) {
            vm.orders = response.result;           

            return response;
        };

        vm.onDatabound = function () {
            if (vm.selectedId && vm.gridControl && vm.gridControl.selectRowById) {
                vm.gridControl.selectRowById(vm.selectedId);
            }
        };

        vm.exportOrders = function () {

            var criteria = {
                filterValues: {
                    forecastId: vm.forecastId,
                    componentType: vm.componentType
                }
            };

            exportSvc.exportData(vm.apiUrls.exportForecastOrders, criteria, xlatSvc.xlat(vm.componentType == 'Tyre' ? 'forecastOrdersEdit.forecastTyreOrdersExportFilename' : 'forecastOrdersEdit.forecastRimOrdersExportFilename'));
        };

        $scope.openEditPopup = function (item) {

            vm.retainSelected = true;

            var spec = _.find(vm.orders, o => o.specificationId === item.specificationId);
            var month = _.find(vm.gridColumns, c => c.yearMonthNum === item.month);
            var monthDate = new Date(month.year, month.month - 1, 1);

            WindowFactory.openItem({
                component: 'edit-orders-popup',
                canMinimise: false,
                modal: true,
                canClose: false,
                width: 700,
                caption: xlatSvc.xlat('forecastOrdersEdit.editViewMonthlyOrders', monthDate.toString().toDateTimeFormat("MMM-yyyy")),
                initParams: {
                    specificationId: spec.specificationId,
                    specification: spec.specification,
                    componentType: vm.componentType,
                    forecastId: vm.forecastId,
                    orders: spec.monthOrders[month.yearMonthForDisplay].orders,
                    month: monthDate,
                    forecastStartDate: vm.forecastStartDate,
                    forecastEndDate: vm.forecastEndDate
                },
                onDataChangeHandler: function () {
                    vm.selectedItem = null;

                    if (vm.gridControl && vm.gridControl.refresh) {                        
                        vm.gridControl.refresh();
                    }
                }
            });
        };

        vm.getMonthForDisplay = function (month) {
            return new Date(month.year, month.month - 1, 1).toString().toDateTimeFormat("MMM-yyyy");
        };

        /* Chart */

        var chartMinScaleY = 10;
        var chartMonthCountMax = 12;
        var chartId = 'componentOrderChart';

        var chartLayout: Partial<Plotly.Layout> = {
            title: xlatSvc.xlat('forecastOrdersEdit.specificationGraphTitle'),
            barmode: 'group',
            autosize: true,
            dragmode: false,             
            xaxis: { autorange: true },
            yaxis: { range: [0, chartMinScaleY], automargin: true, title: xlatSvc.xlat('forecastOrdersEdit.specificationGraphNumberOfComponents') }
        };

        var chartData: Partial<Plotly.PlotData>[] = [
            {
                name: xlatSvc.xlat('forecastOrdersEdit.specificationGraphForecastNewFitments'),
                type: 'bar',
                marker: {
                    color: '#71A100'
                },
                hoverlabel: {
                    namelength: -1
                }
            },
            {
                name: xlatSvc.xlat('forecastOrdersEdit.specificationGraphForecastDeliveries'),
                type: 'bar',
                marker: {
                    color: '#55C1E9'
                },
                hoverlabel: {
                    namelength: -1
                }
            },
            {
                name: xlatSvc.xlat('forecastOrdersEdit.specificationGraphMinimumBackupStock'),
                type: 'scatter',
                mode: 'lines',
                line: {
                    dash: 'dot',
                    width: 3,
                    color: '#465bb3'
                },
                hoverlabel: {
                    namelength: -1
                }
            },
            {
                name: xlatSvc.xlat('forecastOrdersEdit.specificationGraphForecastNewStockMonthEnd'),
                type: 'scatter',
                mode: 'lines+markers',
                marker: {
                    color: '#548BD4',
                    line: {
                        color: '#548BD4'
                    }
                },
                hoverlabel: {
                    namelength: -1
                }
            }
        ];

        var chartConfig: Partial<Plotly.Config> = {
            displayModeBar: false,
            responsive: true,
            autosizable: true,            
            doubleClick: false,
            showTips: false,
            showAxisDragHandles: false,
            showAxisRangeEntryBoxes: false
        };

        vm.onSelectedItemChanged = function (items) {

            if (items != null && items.length === 1) {
                vm.selectedItem = items[0];
                vm.selectedId = vm.selectedItem.id;

                $timeout(function () {
                    vm.loadChart(vm.selectedItem);
                });
            } else if (!vm.retainSelected) {
                vm.selectedItem = null;
                vm.selectedId = null;
                $timeout();
            }

            vm.retainSelected = false;
        };

        vm.loadChart = function (item) {

            // load chart (only for tyre orders)
            if (vm.componentType == 'Tyre') {

                let chartElement = document.getElementById(chartId);

                if (item && chartElement) {

                    let chartMonthNames: string[] = [];
                    let months: Date[] = item.monthOrdersBreakdown.map(function (o) {
                        return o.month;
                    });

                    let monthCount: number = Math.min(months.length, chartMonthCountMax);

                    // new fitments (trace 1)
                    let forecastNewFitments = item.monthOrdersBreakdown.map(function (o) {
                        return o.forecastNewFitments;
                    }).slice(0, monthCount);

                    // deliveries (trace 2)
                    let forecastDeliveries = item.monthOrdersBreakdown.map(function (o) {
                        return o.expectedDeliveries;
                    }).slice(0, monthCount);

                    // new stock at month end (track 3)
                    let forecastNewStockAtMonthEnd = item.monthOrdersBreakdown.map(function (o) {
                        return o.forecastNewStockAtMonthEnd;
                    }).slice(0, monthCount);

                    // minimum backup stock (trace 4)
                    let minimumBackupStock = item.monthOrdersBreakdown.map(function (o) {
                        return o.minimumBackupStock;
                    }).slice(0, monthCount);

                    let yscale = Math.max(
                        chartMinScaleY,
                        Math.max(...forecastNewFitments),
                        Math.max(...forecastDeliveries),
                        Math.max(...forecastNewStockAtMonthEnd),
                        Math.max(...minimumBackupStock)
                    );

                    for (let i = 0; i < monthCount; ++i) {
                        chartMonthNames.push(months[i].toString().toDateTimeFormat("MMM-yy"));
                    }

                    chartLayout.yaxis.range = [0, yscale];

                    chartData[0].x = chartMonthNames;
                    chartData[0].y = forecastNewFitments;

                    chartData[1].x = chartMonthNames;
                    chartData[1].y = forecastDeliveries;

                    chartData[2].x = chartMonthNames;
                    chartData[2].y = minimumBackupStock;

                    chartData[3].x = chartMonthNames;
                    chartData[3].y = forecastNewStockAtMonthEnd;

                    Plotly.newPlot(chartElement, chartData, chartLayout, chartConfig);
                }
            }
        };
    }
