/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var treeview_component_1 = require("./treeview.component");
var shared_module_1 = require("./shared.module");
var node_template_directive_1 = require("./node-template.directive");
var check_directive_1 = require("./check.directive");
var disable_directive_1 = require("./disable.directive");
var expand_directive_1 = require("./expand.directive");
var select_directive_1 = require("./selection/select.directive");
var hierarchy_binding_directive_1 = require("./hierarchy-binding.directive");
var flat_binding_directive_1 = require("./flat-binding.directive");
var drag_and_drop_directive_1 = require("./drag-and-drop/drag-and-drop.directive");
var drag_clue_template_directive_1 = require("./drag-and-drop/drag-clue/drag-clue-template.directive");
var drop_hint_template_directive_1 = require("./drag-and-drop/drop-hint/drop-hint-template.directive");
var drag_and_drop_editing_directive_1 = require("./drag-and-drop/drag-and-drop-editing.directive");
var load_more_directive_1 = require("./load-more/load-more.directive");
var load_more_button_template_directive_1 = require("./load-more/load-more-button-template.directive");
var EXPORTS = [
    treeview_component_1.TreeViewComponent,
    node_template_directive_1.NodeTemplateDirective,
    check_directive_1.CheckDirective,
    disable_directive_1.DisableDirective,
    expand_directive_1.ExpandDirective,
    select_directive_1.SelectDirective,
    hierarchy_binding_directive_1.HierarchyBindingDirective,
    flat_binding_directive_1.FlatDataBindingDirective,
    drag_and_drop_directive_1.DragAndDropDirective,
    drag_clue_template_directive_1.DragClueTemplateDirective,
    drop_hint_template_directive_1.DropHintTemplateDirective,
    drag_and_drop_editing_directive_1.DragAndDropEditingDirective,
    load_more_directive_1.LoadMoreDirective,
    load_more_button_template_directive_1.LoadMoreButtonTemplateDirective
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }}) definition for the TreeView component.
 */
var TreeViewModule = /** @class */ (function () {
    function TreeViewModule() {
    }
    TreeViewModule = tslib_1.__decorate([
        core_1.NgModule({
            exports: [EXPORTS],
            imports: [shared_module_1.SharedModule]
        })
    ], TreeViewModule);
    return TreeViewModule;
}());
exports.TreeViewModule = TreeViewModule;
