//import angular from 'angular';


angular.module('app.fieldSurveys')
        .service('moduleFactory', ['enums', '$injector', 'ocSecuritySvc',
            function (enums, $injector, ocSecuritySvc) {

                //List of loaded Modules
                var modules = [];

                function registerModule(type) {

                    // a dummy object in  case of error                     
                    var dummyModule = {
                        active: false,
                        name: type,
                        route: 'mobile.landing',
                        percent_complete: 0, // for upload ?
                        radOnly: true,
                        lastRecordedDate: new Date(0),
                        description: '---',
                        min_date_recorded: new Date(0),
                        updateDetails: function () { }
                    };
                    var m;
                    if ($injector.has(type)) {
                        m = $injector.get(type);
                    }
                    else {
                        console.log('module (' + type + ') not found');
                        m = dummyModule;
                    }
                    modules.push(m);
                    return m;
                }

                function loadModules() {

                    modules = [];

                    for (var m = 0; m < enums.mobile.modules.length; m++) {

                        var authorised = true;

                        if (enums.mobile.modules[m].permissions !== null && enums.mobile.modules[m].permissions.length > 0) {

                            for (var p = 0; p < enums.mobile.modules[m].permissions.length; p++) {

                                var authorisedPermission = ocSecuritySvc.isAuthorised(enums.mobile.modules[m].permissions[p],
                                    (enums.mobile.modules[m].permissionLevel || 'readOnly'));

                                if (enums.mobile.modules[m].matchAllPermissions) {
                                    authorised = authorised && authorisedPermission;
                                    if (!authorised) break;
                                } else {
                                    authorised = authorisedPermission;
                                    if (authorised) break;
                                }
                            }
                        }

                        if (authorised) {
                            registerModule(enums.mobile.modules[m].Service);
                        }
                    }

                    return modules;
                };

                return {
                    modules: modules,
                    loadModules: loadModules
                };
            }]);
