
//import angular from 'angular';


angular.module("app.site").controller("forecastReportViewerController", forecastReportViewerController);

    // @ts-ignore
    forecastReportViewerController.$inject = ['$stateParams', 'amtCommandQuerySvc', '$sce', '$state', 'amtConstants',
        'forecastReport', 'forecastingSvc', '$scope', 'exportSvc', 'amtXlatSvc'];

    // ReSharper disable once InconsistentNaming
    function forecastReportViewerController($stateParams, amtCommandQuerySvc, $sce, $state, amtConstants,
        forecastReport, forecastingSvc, $scope, exportSvc, amtXlatSvc) {

        var vm = this;

        vm.loadReportContent = loadReportContent;
        vm.pageBack = pageBack;
        vm.pageNext = pageNext;
        vm.cancel = cancel;
        vm.exportFile = exportFile;

        vm.forecastid = $stateParams.forecastid ? $stateParams.forecastid : 0;
        vm.reports = $stateParams.reports;

        vm.options = {
            showForecastReportViewer: false
        };

        vm.forecastReports = [];

        forecastReport.init();
        forecastReport.reportName = 'Configuration Settings';
        forecastReport.reportParams = 'OcForecastID=' + vm.forecastid;

        vm.forecastReports.push(forecastReport); //Add default report

        for (var i = 0; i < vm.reports.length; i++) {
            // @ts-ignore
            var rep = jQuery.extend(true, {}, forecastReport);
            // @ts-ignore
            rep.reportName = vm.reports[i].name;
            // @ts-ignore
            rep.reportParams = 'OcForecastID=' + vm.forecastid;
            vm.forecastReports.push(rep);
        }

        for (var j = 0; j < vm.forecastReports.length; j++) {
            vm.loadReportContent(vm.forecastReports[j], 1, true);
        }

        this.$onInit = function () {
            vm.options.showForecastReportViewer = true;
        };

        function loadReportContent(report, currentPage, clearSession) {

            report.isShowIndicator = true;

            var reportUrl = report.buildReportLinkByParams(null, currentPage, null, report.reportParams);

            console.log(reportUrl);

            amtCommandQuerySvc.get(reportUrl).then(function (res) {
                if (res && res.data) {
                    report.onLoadingFinished(currentPage, res.data.totalPage, clearSession);
                    report.content = $sce.trustAsHtml(res.data.reportContent);
                }
            }, function () {
                console.log('There are errors on Report.');
            }).finally(function () {
                report.isShowIndicator = false;
            });
        }

        function pageBack(report) {

            var currentPage = report.currentPage;

            currentPage--;

            if (currentPage >= 0) {
                vm.loadReportContent(report, currentPage, report.clearSession);
            }

            report.updatePaginationState(currentPage);
        }

        function pageNext(report) {

            var currentPage = report.currentPage;

            currentPage++;

            if (currentPage <= report.totalPage) {
                vm.loadReportContent(report, currentPage, report.clearSession);
            }

            report.updatePaginationState(currentPage);
        }

        function cancel() {
            forecastingSvc.setActiveTabName("Vehicles");
            $state.go('app.site.forecastdetails',
                {
                    forecastid: vm.forecastid,
                    forecastname: $stateParams.name,
                    forecaststartdate: $stateParams.forecaststartdate,
                    forecastenddate: $stateParams.forecastenddate
                });
        }

        function exportFile($event, formatType) {

            var reportNames = [];

            for (var k = 0; k < vm.forecastReports.length; k++) {
                reportNames.push(vm.forecastReports[k].reportName);
            }

            var exportFileUrl = [
                "forecasting/reports/reportname", "/Forecasting,", "Forecasting Export Report",
                "/page/", 1, "/format/", formatType || "html", "/clearsession/true?", "ReportNames=".concat(reportNames.join(";"), ";"),
                '&OcForecastID=' + vm.forecastid
            ].join('');

            exportSvc.exportDataGet(exportFileUrl);
        }
    }
