//import angular from 'angular';


    angular.module('app.reports').controller('reportCtrl', [
        '$sce', '$state', 'amtCommandQuerySvc', 'ocConfigSvc', '$scope',
        function reportCtrl(
            $sce, $state, amtCommandQuerySvc, ocConfigSvc, $scope
        ) {
            var vm = this;

            if ($state && $state.params && $state.params.url) {

                var reportFrame = document.getElementById('reportFrame');

                if (reportFrame) {
                    reportFrame.addEventListener("load", function () {
                        if (vm.src) {
                            $scope.frameLoading = false;
                        }
                    });

                    // only turn on the spinner if we were able to hook up the load event
                    $scope.frameLoading = true;
                }

                vm.url = $state.params.url;

                vm.url += "?vo=viewOnly";

                // get the  report name, and make sure its a string
                vm.reportName = String($state.params.name).split(" ").join("");

                vm.url += "&$paramSite=" + encodeURIComponent(vm.reportName) + "." + encodeURIComponent(ocConfigSvc.user.client.id) + "." + encodeURIComponent(ocConfigSvc.user.site.id) + ".C.SecurityReportName";
                vm.url += "&$paramSiteList=" + encodeURIComponent(ocConfigSvc.user.client.id) + "." + encodeURIComponent(ocConfigSvc.user.site.id) + ".A";
                vm.url += "&$paramLoggedInSite=" + encodeURIComponent(ocConfigSvc.user.client.id) + "." + encodeURIComponent(ocConfigSvc.user.site.id) + ".A";

                var existingSessionId = localStorage.getItem("dundasSessionId");

                amtCommandQuerySvc.post("reports/getSessionId", { existingSessionId: existingSessionId}).then(
                    function (response) {
                        vm.sessionId = response;

                        // store the sessionId so that we don't create unnecessary sessions for subsequent requests
                        localStorage.setItem("dundasSessionId", vm.sessionId);

                        // try to login and then open the report
                        // /static/otracom.html and /api/resource/staticresource/otracom.html
                        vm.src = $sce.trustAsResourceUrl(ocConfigSvc.reports.dundasHomeUrl + ocConfigSvc.reports.otracomRedirectUrl + "?sessionId=" + encodeURIComponent(vm.sessionId) + "&redirectUrl= " + encodeURIComponent(vm.url));
                    },
                    function (response) {
                        // couldn't get the sessionid, just open the report and let the user login
                        $sce.trustAsResourceUrl(vm.url);
                    }
                );
            }
        }
    ]);
