//import angular from 'angular';

    // this directive just injects the class 'dirty-tab' into the element that this attribute blongs to 
    // when the value is true, so bind it to the $dirty property on your form...

    // example usage:

    //  <tab heading="Details" oc-dirty-tab="vm.forms.details.$dirty">
    //      <ng-form name="vm.forms.details">
    //          ...
    //      </ng-form>
    //  </tab>

    angular.module('app.directives')
        .directive('ocDirtyTab', [function () {
            return {
                restrict: 'A',
                link: function (scope, elm, attrs, ctrl) {
                    scope.$watch(attrs.ocDirtyTab, function (newValue) {
                        if (newValue === true) {
                            elm.addClass('dirty-tab');
                        } else {
                            elm.removeClass('dirty-tab');
                        }
                    });
                }
            };
        }]);
