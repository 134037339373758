import { combineLatest, of, Subscription } from "rxjs";
import { distinctUntilChanged, map, tap } from "rxjs/operators";
import { VehicleSpecsService } from "../../../../services/vehicle-specs.service";
import { VehiclesOnsiteService } from "../../../../services/vehicles-onsite.service";
import { IAxleSpecSize, ConditionMonitoringService, MonitoringIssueData, ICMEquipmentAlerts, IDisplayVehicle } from "../condition-monitoring.service";

export const enum RenderPartTypes {
    AXLE,
    TYRE
};

export type PartDataTuple = [RenderPartTypes, number, number, number | number, IAxleSpecSize];

interface VehicleRendererDiagram {
    diagramWidth: number;
    diagramLength: number;
    scaleDownFactor: number;
    scale: number;
    lineWidth: number;
    minorGap: number;
    diagramTopY: number;
    additionalGapAfterFirstAxle: number;
}

interface IAxleSpecSizePos extends IAxleSpecSize {
        posCount: number;
}

export abstract class VehicleRendererBase {
    protected abstract conditionMonitoringService: ConditionMonitoringService;
    protected abstract vehSvc: VehiclesOnsiteService;
    protected abstract vehSpecSvc: VehicleSpecsService;
    public abstract readonly desiredWidth: number;
    public abstract readonly desiredHeight: number;

    protected readonly MULTI_AXLE_TYRE_SPACING_IN_PIXELS = 3;
    protected readonly SINGLE_AXLE_GAP_IN_TYRE_NORM = 1;
    protected readonly ADDITIONAL_GAP_AFTER_FIRST_AXLE_DIAMETER_RATIO = [.5, .5];
    protected readonly AXLE_WIDTH_IN_PIXELS = 4;
    protected readonly DRIVE_SHAFT_WIDTH_IN_PIXELS = 4;
    protected readonly CENTRAL_GAP_IN_PIXELS = 12; //inner distance between tyres on axle with the greatest total width of tyres

    protected readonly AXLE_COLOUR = "#F3F3F3";
    protected readonly DRIVE_SHAFT_COLOUR = "#F8F8F8";
    protected ISSUE_COLOURS_LOOKUP_TABLE = [];

    protected diagramParams?: Readonly<VehicleRendererDiagram>;

    protected deferredData?: PartDataTuple[][];
    protected ctx?: CanvasRenderingContext2D;
    private _axles: IAxleSpecSizePos[] = [];

    constructor(protected readonly isDeferred: boolean) {
        //patch ngOnDestroy per https://github.com/microsoft/TypeScript/issues/21388
        let __ngOnDestroy = (this as any).ngOnDestroy;
        (this as any).ngOnDestroy = () => {
            __ngOnDestroy.apply(this);
            this.__destroy();
        }

    }

    public init(canvas: HTMLCanvasElement, vehicle: IDisplayVehicle, lookupTable: Array<string>) {
        this.ISSUE_COLOURS_LOOKUP_TABLE = lookupTable;
        this.ctx = this.initContext(canvas);

        //let alerts$ = this.conditionMonitoringService.getAlertsBySerial$(vehicle.serialNo); //TODO: Will need to massage issues and alerts into a 'common' structure

        let axles = (vehicle.sizes.map(v => v || []) || []) as IAxleSpecSizePos[];
        for (let i = 0; i < axles.length; ++i) {
            axles[i].posCount = vehicle.spec.axles[i]?.length || 0;
        }

        this.axles = axles;
        if (this.axles.length > 0)
            this.render(vehicle.issues || [], []);
    }

    protected get axles() {
        return this._axles;
    }

    protected set axles(axles: IAxleSpecSizePos[]) {
        this._axles = axles;
        let hasSingleAxle = axles.length === 1;

        let data: Partial<VehicleRendererDiagram> = {};

        data.additionalGapAfterFirstAxle = (axles[1]?.tyreDiameter + axles[1]?.tyreWidth) *
            (this.ADDITIONAL_GAP_AFTER_FIRST_AXLE_DIAMETER_RATIO[axles.length - 2]) || 0;

        let singleAxleTyreSpacing = this.SINGLE_AXLE_GAP_IN_TYRE_NORM * (axles[0]?.tyreDiameter + axles[0]?.tyreWidth);

        [data.diagramWidth, data.diagramLength, data.scaleDownFactor] = hasSingleAxle ?
            this.findDiagramSizeSingleAxle(singleAxleTyreSpacing) : this.findDiagramSizeMultiAxle(data.additionalGapAfterFirstAxle);

        data.scale = 1 / (data.scaleDownFactor * Math.max(data.diagramWidth, data.diagramLength));
        data.lineWidth = 1 / (this.desiredHeight * data.scale);
        data.minorGap = hasSingleAxle ? singleAxleTyreSpacing : this.MULTI_AXLE_TYRE_SPACING_IN_PIXELS * data.lineWidth;
        data.diagramTopY = -.5 * data.diagramLength;

        this.diagramParams = data as VehicleRendererDiagram;
    }

    private __destroy(): void {
        //TODO: ensure that the hack in the constructor makes sure this is called by derived classes
        //(whether they implement the OnDestroy interface and/or implement ngOnDestoy, or not)
        //console.log("somehow this works?");
    }

    protected minimumDiagramSize() {
        let maxPositionsPerAxle = 0;
        let tyreWidthFromFirstMaxPosAxle = 0;
        let tyreTotalWidth = 0;
        let tyreTotalLength = 0;

        for (let a of this.axles) {
            tyreTotalWidth = Math.max(tyreTotalWidth, a.posCount * a.tyreWidth);
            tyreTotalLength += a.tyreDiameter;

            if (a.posCount > maxPositionsPerAxle) {
                maxPositionsPerAxle = a.posCount;
                tyreWidthFromFirstMaxPosAxle = a.tyreWidth;
            }
        }

        return [tyreTotalWidth, tyreTotalLength, maxPositionsPerAxle, tyreWidthFromFirstMaxPosAxle];
    }

    protected getScaleDownFactor(maxPositionsPerAxle: number, tyreWidthSum: number, tyreLengthSum: number) {
        return maxPositionsPerAxle > 3 ? 1 :
            Math.max(1, this.axles.length * 1.25 * tyreWidthSum / tyreLengthSum);
    }

    protected findDiagramSizeSingleAxle(singleAxleTyreSpacing: number) {
        let [diagramWidth, diagramLength, maxPositionsPerAxle] = this.minimumDiagramSize();

        //increase width by the gaps between tyres
        return [
            diagramWidth + singleAxleTyreSpacing * (maxPositionsPerAxle - 1),
            diagramLength,
            this.getScaleDownFactor(maxPositionsPerAxle, diagramWidth, diagramLength) /*maxPositionsPerAxle < 4 ? DOWNSCALE_FOR_2_BY_1    : 1*/];
    }

    protected findDiagramSizeMultiAxle(additionalGapAfterFirstAxle: number) {
        const [diagramWidthInitial, diagramLengthInitial, maxPositionsPerAxle, tyreWidthFromFirstMaxPosAxle] = this.minimumDiagramSize();
        const scaleDownFactor = this.getScaleDownFactor(maxPositionsPerAxle, diagramWidthInitial, diagramLengthInitial);

        //add some pixel sizes to the diagram length... this is complicated because the size of a pixel is unknown is related to the size of the diagram
        const lengthSpacingFromGapsInPixels = (this.axles.length - 1) * (this.MULTI_AXLE_TYRE_SPACING_IN_PIXELS + .5); //the .5 is 'magic'
        const diagramLength = (1 + lengthSpacingFromGapsInPixels / this.desiredHeight) * (diagramLengthInitial + additionalGapAfterFirstAxle);

        const pixelToDiagramUnits = scaleDownFactor * diagramLengthInitial / this.desiredHeight;
        const increaseWidthByPixels = 2 * maxPositionsPerAxle + this.CENTRAL_GAP_IN_PIXELS;

        return [
            diagramWidthInitial + increaseWidthByPixels * pixelToDiagramUnits + tyreWidthFromFirstMaxPosAxle,
            diagramLength,
            scaleDownFactor
        ];
    }

    protected renderInit(): void { }
    protected renderAfterParts(): void { }

    protected abstract drawAxlePart(t: RenderPartTypes, x0: number, x1: number, y: number, size: IAxleSpecSize): void;
    protected abstract drawTyre(t: RenderPartTypes, x: number, y: number, issue: number, size: IAxleSpecSize): void;

    protected drawPart(data: PartDataTuple) {
        switch (data[0]) {
            case RenderPartTypes.AXLE:
                this.drawAxlePart(...data);
                break;
            case RenderPartTypes.TYRE:
                this.drawTyre(...data);
                break;
        }
    }

    private initContext(canvas: HTMLCanvasElement) {
        let ctx = canvas.getContext('2d');

        if (ctx == null)
            throw Error("Failed to create 2D Canvas");

        let dpr = window.devicePixelRatio || 1;

        let w = this.desiredWidth * dpr;
        let h = this.desiredHeight * dpr;

        ctx.canvas.width = w;
        ctx.canvas.height = h;

        let scale = Math.min(w, h);
        ctx.scale(scale, scale);
        ctx.translate(.5, .5);

        return ctx;
    }

    protected render(issue: MonitoringIssueData[], alert: ICMEquipmentAlerts[]) {
        this.renderInit();

        if (this.isDeferred) {
            this.deferredData = new Array(this.axles.length);
            for (let j = 0; j < this.axles.length; ++j)
                this.deferredData[j] = new Array(this.axles[j].posCount);
        }

        const handlePart = this.isDeferred ?
            (i: number, j: number, data: PartDataTuple) => this.deferredData![j][i] = data :
            (i: number, j: number, data: PartDataTuple) => this.drawPart(data);

        let currentOffsetY = this.diagramParams!.diagramTopY;
        let k = 0;
        for (let j = 0; j < this.axles.length; ++j) {
            const axle = this.axles[j];

            let x = -.5 * (this.diagramParams!.diagramWidth - axle.tyreWidth);
            let y = currentOffsetY + .5 * axle.tyreDiameter;

            const lastPositionOnAxle = axle.posCount - 1;
            const innerLeftPositionOnAxle = (axle.posCount >> 1) - 1;

            for (let i = 0; i < axle.posCount; ++i) {
                //calculate the next tyre position on the axle (useful to have now to draw axle in between tyres)
                //from the left we render from the outside in, and then mirror at the centre, ignoring floating point error accumulation this should push errors estimating the width into the centre
                //(should be less than a px, but even that might be noticable if added the centre gap as the symmetry might be broken)
                const x2 = (i == innerLeftPositionOnAxle) ? -x : x + axle.tyreWidth + this.diagramParams!.minorGap;

                //draw the piece of the axle between this and the next tyre, code might be slightly simpler if this were drawn afterwards, but I want to keep
                //drawing left to right, top to bottom, as we are relying on painters algorithm
                if (i != lastPositionOnAxle)
                    //handleAxlePart(i, j, x, x2, y, axle.size);
                    handlePart(i, j, [RenderPartTypes.AXLE, x, x2, y, axle]);


                handlePart(i, j, [RenderPartTypes.TYRE, x, y, issue.find(mi => mi.posIdx == k)?.issue || 0, axle]);

                //we already calculated the next horizontal position on the axle, use it (value is invalid/ignored if this was the last position on the axle)
                x = x2;
                ++k;
            }
            currentOffsetY += axle.tyreDiameter + this.diagramParams!.minorGap + (j == 0 ? this.diagramParams!.additionalGapAfterFirstAxle : 0);
        }

        //for implementations to use
        this.renderAfterParts();
    }

    protected drawLine(x0: number, y0: number, x1: number, y1: number, style?: string, strokeWidth?: number) {
        if (style)
            this.ctx!.strokeStyle = style;

        this.ctx!.lineWidth = (strokeWidth || 1) * this.diagramParams!.lineWidth;

        this.ctx!.beginPath();
        this.ctx!.moveTo(x0, y0);
        this.ctx!.lineTo(x1, y1);
        this.ctx!.stroke();
    }

    protected drawRect(x: number, y: number, w: number, h: number, fillStyle?: string, strokeStyle?: string, strokeWidth?: number) {
        if (fillStyle || strokeStyle)
            this.ctx!.beginPath();

        this.ctx!.rect(x, y, w, h);

        if (fillStyle) {
            this.ctx!.fillStyle = fillStyle;
            this.ctx!.fill();
        }

        if (strokeStyle) {
            this.ctx!.strokeStyle = strokeStyle;
            let lineWidth = (strokeWidth || 1) * this.diagramParams!.lineWidth;
            this.ctx!.lineWidth = lineWidth;
            this.ctx!.stroke();
        }
    }
}
