/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var column_menu_position_component_1 = require("./column-menu-position.component");
var column_menu_chooser_component_1 = require("./column-menu-chooser.component");
var column_menu_filter_component_1 = require("./column-menu-filter.component");
/**
 * @hidden
 */
var ColumnMenuItemDirective = /** @class */ (function () {
    function ColumnMenuItemDirective(hostElement, renderer, ngZone) {
        var _this = this;
        this.hostElement = hostElement;
        this.renderer = renderer;
        this.ngZone = ngZone;
        this._isFirst = false;
        this._isLast = false;
        this.subs = new rxjs_1.Subscription();
        this.onTab = function (e) {
            if (e.keyCode !== kendo_angular_common_1.Keys.Tab) {
                return;
            }
            if (_this.isFirst && e.shiftKey && e.target === _this.columnMenuItems[0]) {
                e.preventDefault();
                _this.menuItemComponent.service.menuTabbingService.lastFocusable.focus();
            }
            if (_this.isLast && !e.shiftKey) {
                var lastColumnMenuItem = _this.getLastColumnMenuItem();
                var isExpanded = _this.menuItemComponent.expanded;
                if (lastColumnMenuItem === e.target && !isExpanded) {
                    e.preventDefault();
                    _this.menuItemComponent.service.menuTabbingService.firstFocusable.focus();
                }
            }
        };
    }
    Object.defineProperty(ColumnMenuItemDirective.prototype, "isFirst", {
        get: function () {
            return this._isFirst;
        },
        set: function (value) {
            var _this = this;
            if (value) {
                var focusableElement_1 = this.columnMenuItems[0];
                this.menuItemComponent.service.menuTabbingService.firstFocusable = focusableElement_1;
                this.ngZone.runOutsideAngular(function () {
                    var firstItemKeydownSub = _this.renderer.listen(focusableElement_1, 'keydown', _this.onTab);
                    _this.subs.add(firstItemKeydownSub);
                });
            }
            this._isFirst = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColumnMenuItemDirective.prototype, "isLast", {
        get: function () {
            return this._isLast;
        },
        set: function (value) {
            var _this = this;
            if (!this.columnMenuItems) {
                return;
            }
            if (value) {
                var lastFocusableElement_1 = this.getLastColumnMenuItem();
                this.menuItemComponent.service.menuTabbingService.lastFocusable = lastFocusableElement_1;
                this.ngZone.runOutsideAngular(function () {
                    var lastItemKeydownSub = _this.renderer.listen(lastFocusableElement_1, 'keydown', _this.onTab);
                    _this.subs.add(lastItemKeydownSub);
                });
                if (this.isExpandableItem()) {
                    this.menuItemComponent.isLast = true;
                }
            }
            this._isLast = value;
        },
        enumerable: true,
        configurable: true
    });
    ColumnMenuItemDirective.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.columnMenuItems = this.hostElement.nativeElement.querySelectorAll('.k-columnmenu-item');
        [].slice.apply(this.columnMenuItems).forEach(function (el) { return _this.renderer.setAttribute(el, 'tabindex', '0'); });
        if (this.menuItemComponent instanceof column_menu_filter_component_1.ColumnMenuFilterComponent) {
            this.menuItemComponent.service.menuTabbingService.isColumnMenu = true;
        }
    };
    ColumnMenuItemDirective.prototype.ngOnDestroy = function () {
        if (this.subs) {
            this.subs.unsubscribe();
        }
    };
    ColumnMenuItemDirective.prototype.getLastColumnMenuItem = function () {
        return (this.columnMenuItems.length === 1 ? this.columnMenuItems[0] : this.columnMenuItems[1]);
    };
    ColumnMenuItemDirective.prototype.isExpandableItem = function () {
        return this.menuItemComponent instanceof column_menu_filter_component_1.ColumnMenuFilterComponent ||
            this.menuItemComponent instanceof column_menu_chooser_component_1.ColumnMenuChooserComponent ||
            this.menuItemComponent instanceof column_menu_position_component_1.ColumnMenuPositionComponent;
    };
    tslib_1.__decorate([
        core_1.Input('kendoGridColumnMenuItem'),
        tslib_1.__metadata("design:type", Object)
    ], ColumnMenuItemDirective.prototype, "menuItemComponent", void 0);
    ColumnMenuItemDirective = tslib_1.__decorate([
        core_1.Directive({ selector: '[kendoGridColumnMenuItem]' }),
        tslib_1.__metadata("design:paramtypes", [core_1.ElementRef,
            core_1.Renderer2,
            core_1.NgZone])
    ], ColumnMenuItemDirective);
    return ColumnMenuItemDirective;
}());
exports.ColumnMenuItemDirective = ColumnMenuItemDirective;
