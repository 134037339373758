import { OnDestroy, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { LayoutRxjsService } from '../service/rxjs/LayoutRxjs.service';
import { SiteDashboard } from '../service/Interface/SiteDashboard.interface';
import { Subscription } from 'rxjs';

@Component({
    selector: 'site-dashboard-menu',
    templateUrl: './widget-menu.component.html',
    styleUrls: ['./widget-menu.component.scss']
})

export class WidgetMenuComponent implements OnInit, OnDestroy {
    @ViewChild("dropdownlist", { static: true }) public dropdownlist: any;
    public siteDashboardList: SiteDashboard[];
    public selectedDashboard: SiteDashboard = {
        siteId: '',
        active: false,
        default: false,
        id: '',
        name: ''
    };
    public selectedDashboardSubscription: Subscription;
    public dashboardSubScription: Subscription;

    constructor(private rxjs: LayoutRxjsService) {
    }

    ngOnInit() {
        this.subscribeDashboard();
    }

    ngOnDestroy() {
        this.selectedDashboardSubscription.unsubscribe();
        this.dashboardSubScription.unsubscribe();
    }

    subscribeDashboard() {
        this.selectedDashboardSubscription = this.rxjs.selectedDashboard.subscribe(response => {
            this.selectedDashboard = response;
        });
        this.dashboardSubScription = this.rxjs.siteDashboardList.subscribe(response => {
            this.siteDashboardList = response;
        });
    }

    changeDashboard(selectedDashboard: SiteDashboard) {
        this.rxjs.setSelectedDashboard(selectedDashboard);
    }

}