    /**
        * @ngdoc directive
        * @name amtFramework.directive:amtPopup
        * @restrict E
        * @description
        * Displays a popup
        * @param {string} title Title to show in the header of the popup
        * @param {expression} isVisible Visible
        * @param {string} size Size of the popup. Possible values 'lg' or 'sm' 
        * @param {expression} formParent Name of the form where the popup is in. Used to control fields validation
        * @param {string} saveText Text of the save button
        * @param {string} cancelText Text of the cancel button
        * @param {string} visibleCancel Cancel button visible
        * @param {expression} showBusy Promise on which the busy indicator is shown
        * @param {callback} onSave() Fired when save button is clicked
        * @param {callback} onCancel() Fired when cancel button is clicked
        * @param {callback} onClose() Fired when dialog is closed
        * @element 
        * @example 
    **/
    
//import angular from 'angular';
import tmplPopup from './amtPopup.html'

angular.module('amtFramework')
    .directive('amtPopupFooter', function () {
        return {
            template: '<span class="modal-footer-custom" ng-transclude></span>',
            restrict: 'E',
            transclude: true,
            replace: true,
            scope: true
        };
    })
    .directive('amtPopupBody', function () {
        return {
            template: '<div class="modal-body" ng-transclude></div>',
            require: "^amtPopup",
            restrict: 'E',
            transclude: true,
            replace: true,
            scope: true
        };
    })
    .directive('amtPopup', ['$modal', 'amtXlatSvc', 'cgBusyDefaults', function ($modal, amtXlatSvc, cgBusyDefaults) {
        return {
            template: tmplPopup,
            restrict: 'E',
            transclude: true,
            replace: true,
            scope: {
                title: '@',
                visible: '=isVisible',
                size: '@',
                formParent: '=',
                saveText: '@',
                cancelText: '@',
                visibleCancel: "@",
                showBusy: '=',
                onSave: '&',
                onCancel: '&',
                onClose: '&'
            },
            link: function (scope: any, element, attrs) {
                var footerBlock = element.find('div.modal-footer');
                var footerContent = element.find('span.modal-footer-custom');
                if (footerContent.length > 0) {
                    footerBlock.append(footerContent);
                }

                cgBusyDefaults.message = amtXlatSvc.xlat('framework.loadingMessage');

                var body = angular.element(document).find('body').eq(0);

                scope.$watch('visible', function (newValue, oldValue) {
                    if (newValue === true) {
                        scope.style = { display: 'block' };
                        body.toggleClass('modal-open', true);
                        scope.animate = true;
                    }
                    else if (newValue === false && oldValue === true) {
                        body.toggleClass('modal-open', false);
                        scope.style = { display: 'none' };
                        scope.animate = false;
                    }

                    if (newValue === false && oldValue === true) {
                       if ("onClose" in attrs) {
                            scope.onClose();
                        }
                    }
                });

                if (!("onSave" in attrs)) {
                    scope.btnSaveStyle = { display: 'none' };
                }

                if (!("visibleCancel" in attrs)) {
                    scope.btnCancelVisible = true;
                } else {
                    scope.btnCancelVisible = scope.visibleCancel;
                }

                if (!("onCancel" in attrs)) {
                    scope.onCancel = function () {
                        scope.visible = false;
                    };
                }

                scope.btnSaveText = !('saveText' in attrs) ? amtXlatSvc.xlat('common.save_label') : scope.saveText;
                scope.btnCancelText = !('cancelText' in attrs) ? amtXlatSvc.xlat('common.cancel_label') : scope.cancelText;

                scope.$on("$stateChangeStart", function () {
                    body.find(".modal.fade").css("display", "none");
                    scope.visible = false;
                });               
            }
        };
    }]);
