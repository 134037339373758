/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var filter_service_1 = require("../filter.service");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var boolean_filter_component_1 = require("../boolean-filter.component");
var utils_1 = require("../../utils");
/**
 * Represents a Boolean filter-cell component.
 *
 * @example
 *
 *  ```html-no-run
 *      <kendo-grid-column field="ProductName" title="Product Name">
 *          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
 *          <kendo-grid-boolean-filter-cell
 *              [column]="column"
 *              [filter]="filter">
 *          </kendo-grid-boolean-filter-cell>
 *          </ng-template>
 *      </kendo-grid-column>
 *   ```
 */
var BooleanFilterCellComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BooleanFilterCellComponent, _super);
    function BooleanFilterCellComponent(filterService, localization, cd) {
        var _this = _super.call(this, filterService, localization) || this;
        _this.cd = cd;
        return _this;
    }
    BooleanFilterCellComponent.prototype.localizationChange = function () {
        _super.prototype.localizationChange.call(this);
        this.cd.markForCheck();
    };
    Object.defineProperty(BooleanFilterCellComponent.prototype, "columnLabel", {
        /**
         * @hidden
         */
        get: function () {
            var localizationMsg = this.localization.get('filterInputLabel') || '';
            var columnName = this.column.title || this.column.field;
            return utils_1.replaceMessagePlaceholder(localizationMsg, 'columnName', columnName);
        },
        enumerable: true,
        configurable: true
    });
    BooleanFilterCellComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-grid-boolean-filter-cell',
            template: "\n        <kendo-grid-filter-wrapper-cell\n            [column]=\"column\"\n            [filter]=\"filter\"\n            [showOperators]=\"false\"\n            [defaultOperator]=\"operator\">\n            <kendo-dropdownlist\n                kendoFilterInput\n                kendoGridFocusable\n                [columnLabel]=\"columnLabel\"\n                [defaultItem]=\"defaultItem\"\n                [data]=\"items\"\n                textField=\"text\"\n                valueField=\"value\"\n                [popupSettings]=\"{ width: 'auto' }\"\n                [valuePrimitive]=\"true\"\n                [value]=\"currentFilter?.value\">\n            </kendo-dropdownlist>\n        </kendo-grid-filter-wrapper-cell>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [filter_service_1.FilterService,
            kendo_angular_l10n_1.LocalizationService,
            core_1.ChangeDetectorRef])
    ], BooleanFilterCellComponent);
    return BooleanFilterCellComponent;
}(boolean_filter_component_1.BooleanFilterComponent));
exports.BooleanFilterCellComponent = BooleanFilterCellComponent;
