//import angular from 'angular';
import OcDateSvc from '../../../../../services/ocDateSvc';
import tmpl from './editVehicleStartDate.html';


    angular.module("app.site").component("editVehicleStartDate",
        {
            template: tmpl,
            bindings: {
                initParams: '=',
                buttonMethods: '=',
                buttonStates: '=',
                buttons: '=',
                closeOnSave: '=',
                wnd: '='
            },
            controller: editVehicleStartDateCtrl
        });

    // @ts-ignore
    editVehicleStartDateCtrl.$inject = ['$scope', 'amtConstants', 'amtCommandQuerySvc', 'confirmSvc', 'enums', 'amtXlatSvc', 'notifySvc', 'WindowFactory', 'ocDateSvc', 'errorReporter'];

    function editVehicleStartDateCtrl($scope, amtConstants, amtCommandQuerySvc, confirmSvc, enums, xlatSvc, notifySvc, WindowFactory, ocDateSvc: OcDateSvc, errorReporter) {

        var vm = this;
        $scope.vm = this;

        vm.url = amtConstants.url;

        vm.model = {
            forecastId: 0,
            forecastVehicleId: 0,
            vehicleStartDate: "1999-01-02"
        };

        this.$onInit = function () {

            vm.model.forecastId = vm.wnd.initParams.forecastId;
            vm.model.forecastVehicleId = vm.wnd.initParams.vehicleId;
            vm.model.vehicleStartDate = ocDateSvc.convertDateStringsToDates(vm.wnd.initParams.vehicleStartDate);

            vm.forecastStartDate = vm.wnd.initParams.forecastStartDate;
            vm.forecastEndDate = vm.wnd.initParams.forecastEndDate;

            vm.buttons = [
                {
                    primary: true,
                    cancel: false,
                    value: 'common.save_label',
                    name: 'saveButton',
                    click: 'save',
                    type: enums.buttonTypes.button
                },
                {
                    primary: false,
                    cancel: true,
                    value: 'common.cancel_label',
                    name: 'cancelButton',
                    click: 'cancel',
                    type: enums.buttonTypes.button
                }
            ];

            vm.buttonStates = {
                saveButton: {
                    visible: true,
                    disabled: true
                },
                cancelButton: {
                    visible: true,
                    disabled: false
                }
            };

            vm.buttonMethods = {
                save: vm.save,
                cancel: vm.onCancelPopup
            };
        };

        vm.save = function () {

            if (+vm.model.forecastId !== 0) {

                vm.wnd.processing = true;

                var updateModel = {
                    forecastId: vm.model.forecastId,
                    forecastVehicleId: vm.model.forecastVehicleId,
                    vehicleStartDate: ocDateSvc.removeLocalTimeZoneOffset(vm.model.vehicleStartDate)
                };

                amtCommandQuerySvc.put(amtConstants.url.updateVehicleStartDate, updateModel).then(function (response) {

                    notifySvc.success(xlatSvc.xlat("forecastVehicles.messageVehicleStartDateUpdateSuccess"));

                    if (vm.wnd.onDataChanged) {
                        vm.wnd.onDataChanged();
                    }

                    WindowFactory.closeWindow(vm.wnd);

                }).catch(function (error) {
                    errorReporter.logError(error);
                }).finally(function () {
                    vm.wnd.processing = false;
                });
            }
        }

        vm.onCancelPopup = function () {
            confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                vm.form.dirty = false;
                WindowFactory.closeWindow(vm.wnd);
            });
        }

        $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing'], function () {
            vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.form.$pristine || !!vm.wnd.processing;
        });
    }
