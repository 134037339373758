//import angular from 'angular';
import tmplEndOfMonthSearch from "./endOfMonth/search.html";
import tmplForecastDetails from "../forecasting/forecastDetails/forecastDetails.html";
import tmplForecastExistingVehicleSchedule from "../forecasting/forecastDetails/forecastVehicles/existingVehicleSchedule/forecastExistingVehicleSchedule.html";
import tmplForecastNewVehicleSchedule from "../forecasting/forecastDetails/forecastVehicles/newVehicleSchedule/forecastNewVehicleSchedule.html";
import tmplEditVehicleStartDate from "../forecasting/forecastDetails/forecastVehicles/editVehiclePopups/editVehicleStartDate.html";
import tmplEditVehicleEndDate from "../forecasting/forecastDetails/forecastVehicles/editVehiclePopups/editVehicleEndDate.html";
import tmplForecastOrdersEdit from "../forecasting/forecastDetails/forecastOrders/forecastOrdersEdit.html";
import tmplOrderCreatePopup from "../forecasting/forecastDetails/forecastOrders/createOrdersPopup/orderCreatePopup.html";
import tmplTyreOccurrenceList from "../forecasting/forecastDetails/occurrence/tyreOccurrenceList.html";
import tmplRimOccurrenceList from "../forecasting/forecastDetails/occurrence/rimOccurrenceList.html";
import tmplForecastReportPopup from "../forecasting/forecastReports/forecastReportsPopup.html";
import tmplForecastReportViewer from "../forecasting/forecastReports/forecastReportsViewer.html";
import tmplForecastVehicleMonthlyUsage from "../forecasting/forecastDetails/forecastVehicles/vehicleMonthlyUsage/forecastVehicleMonthlyUsage.html";
import tmplVehicleMonthlyUsageEdit from "../forecasting/forecastDetails/forecastVehicles/vehicleMonthlyUsage/vehicleMonthlyUsageEditPopup.html";
import tmplForecastList from "../forecasting/forecastList/forecastList.html";
//import tmplForecastCreatePopup from "../forecasting/forecastCreate/forecastCreatePopup.html";
import tmplTyreSpecificationEdit from "../forecasting/forecastDetails/forecastTyres/tyreSpecificationEdit.html";
import tmplRimSpecificationEdit from "../forecasting/forecastDetails/forecastRims/rimSpecificationEdit.html";
import tmplVehicleSpecificationNewTyreChangeoutPositions from "../forecasting/forecastDetails/forecastTyres/vehicleSpecificationNewTyreChangeoutPositions.html";
import tmplVehicleRotationStrategies from "../forecasting/forecastDetails/forecastTyres/vehicleRotationStrategies.html";
import tmplVehicleTyreStrategy from "../admin/vehicleTyreStrategy/vehicleTyreStrategy.html";
import { ConditionMonitoringComponent } from "./conditionMonitoring/monitoringOptions/visualInspections/condition-monitoring.component";
import { TreadWearComponent } from "./conditionMonitoring/monitoringOptions/treadWear/tread-wear.component";
import { SiteDashboardComponent } from "./siteDashboard/site-dashboard.component";



const moduleName = "app.site";
export default moduleName;
angular.module(moduleName, [])
        .config([
            '$stateProvider',
            function ($stateProvider: ng.ui.IStateProvider) {

                $stateProvider

                    .state('app.site.conditionMonitoring', {
                        url: "/conditionmonitoring/visualinspection",
                        component: ConditionMonitoringComponent
                    })

                    .state('app.site.treadWear', {
                        url: "/conditionmonitoring/treadwear",
                        component: TreadWearComponent
                    })

                    .state('app.site.siteDashboard', {
                        url: "/sitedashboard",                        
                        component: SiteDashboardComponent
                    })

                    .state('app.site.endofmonth', {
                        url: "/endofmonth",
                        template: tmplEndOfMonthSearch,
                        controller: "endOfMonthSearchCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.site.purchaseOrders", {
                        url: "/purchaseorders",
                        component: "purchaseOrderSearch"
                    })

                    .state('app.site.forecastdetails', {
                        url: '/forecastdetails/:forecastid',
                        template: tmplForecastDetails,
                        controller: "forecastDetailsController",
                        controllerAs: "vm"
                    })    
                    
                    .state('app.site.existingvehicleschedule', {
                        url: '/existingvehicleschedule',
                        params: {
                            forecastid: null,
                            vehiclespecificationid: null,
                            vehiclespecname: null,
                            vehiclecount: null,
                            forecaststartdate: null,
                            forecastenddate: null
                        },
                        template: tmplForecastExistingVehicleSchedule,
                        controller: "forecastExistingVehicleScheduleController",
                        controllerAs: "vm"
                    })

                    .state('app.site.existingvehicleschedule.editvehicleenddate', {
                        url: '/editvehicleenddate/:vehicleid/:vehicleenddate',
                        template: tmplEditVehicleEndDate,
                        controller: "editVehicleEndDateCtrl",
                        controllerAs: "vm"

                    })              
                    
                    .state('app.site.newvehicleschedule', {
                        url: '/newvehicleschedule',
                        params: {
                            forecastid: {},
                            vehiclespecificationid: null,
                            vehiclespecname: null,
                            vehiclecount: null,
                            forecaststartdate: null,
                            forecastenddate: null
                        },
                        template: tmplForecastNewVehicleSchedule,
                        controller: "forecastNewVehicleScheduleController",
                        controllerAs: "vm"
                    })

                    .state('app.site.newvehicleschedule.editvehiclestartdate', {
                        url: '/editvehiclestartdate/:vehicleid/:vehiclestartdate',
                        template: tmplEditVehicleStartDate,
                        controller: "editVehicleStartDateCtrl",
                        controllerAs: "vm"

                    })

                    .state('app.site.newvehicleschedule.editvehicleenddate', {
                        url: '/editvehicleenddate/:vehicleid/:vehicleenddate',
                        template: tmplEditVehicleEndDate,
                        controller: "editVehicleEndDateCtrl",
                        controllerAs: "vm"

                    })

                    .state('app.site.vieworders', {
                        url: '/vieworders',
                        template: tmplForecastOrdersEdit,
                        controller: "forecastOrdersEditCtrl",
                        controllerAs: "vm",
                        params: {
                            forecastId: null,
                            forecastStartDate: null,
                            forecastEndDate: null,
                            componentType: null,
                            currentTab: null
                        }
                    })

                    .state('app.site.vieworders.createneworder', {
                        url: '/createneworder',
                        template: tmplOrderCreatePopup,
                        controller: "orderCreatePopupCtrl",
                        controllerAs: "vm"
                    })

                    .state('app.site.tyreoccurrencelist', {
                        url: '/tyreoccurrencelist',
                        params: {
                            forecastid: null,                            
                            year: null,
                            month: null,
                            week: null,
                            includeAll: false,
                            currentTab: null,
                            depthUnit: null,
                            dashboardType: null
                        },
                        template: tmplTyreOccurrenceList,
                        controller: "tyreOccurrenceListController",
                        controllerAs: "vm"
                    })

                    .state('app.site.rimoccurrencelist', {
                        url: '/rimoccurrencelist',
                        params: {
                            forecastid: null,                            
                            year: null,
                            month: null,
                            week: null,
                            includeAll: false,
                            dashboardType: null
                        },
                        template: tmplRimOccurrenceList,
                        controller: "rimOccurrenceListController",
                        controllerAs: "vm"
                    })

                    .state('app.site.forecastdetails.forecastreportlist', {
                        url: '/forecastreportlist',
                        params: {
                            forecastid: null,
                            name: null,
                            forecaststartdate: null,
                            forecastenddate: null
                        },
                        template: tmplForecastReportPopup,
                        controller: "forecastReportPopupCtrl",
                        controllerAs: "vm"
                    })

                    .state('app.site.forecastreport', {
                        url: '/forecastreport',
                        params:
                        {
                            reports: { array: true },
                            forecastid: { array: false },
                            name: null,
                            forecaststartdate: null,
                            forecastenddate: null
                        },
                        template: tmplForecastReportViewer,
                        controller: "forecastReportViewerController",
                        controllerAs: "vm"
                    })

                    .state('app.site.monthlyusage', {
                        url: '/monthlyusage',
                        params: {
                            forecastid: null,
                            vehiclespecificationid: null,
                            vehiclespecname: null,
                            forecaststartdate: null,
                            forecastenddate: null
                        },
                        template: tmplForecastVehicleMonthlyUsage,
                        controller: "forecastVehicleMonthlyUsageController",
                        controllerAs: "vm"
                    })

                    .state('app.site.monthlyusage.monthlyusageedit', {
                        url: '/monthlyusageedit/:monthlyusageid/:monthlyusage/:yearmonth',
                        template: tmplVehicleMonthlyUsageEdit,
                        controller: "vehicleMonthlyUsageEditController",
                        controllerAs: "vm"
                    })

                    .state('app.site.forecastlist', {
                        url: '/forecastlist',
                        template: tmplForecastList,
                        controller: "forecastListController",
                        controllerAs: "vm"
                    })

                    //.state('app.site.forecastlist.forecastcreate', {
                    //    url: '/forecastcreate/:id',
                    //    template: tmplForecastCreatePopup,
                    //    controller: "forecastCreatePopupController",
                    //    controllerAs: "vm"
                    //})

                    //.state('app.site.forecastdetails.forecastupdate', {
                    //    url: '/forecastcreate/:id',
                    //    template: tmplForecastCreatePopup,
                    //    controller: "forecastCreatePopupController",
                    //    controllerAs: "vm"
                    //})

                    .state('app.site.tyrespecificationedit', {
                        url: '/tyrespecificationedit',
                        params: {
                            forecastid: null,
                            vehiclespecificationid: null,
                            forecastvsactuallife: null,
                            sparetyreneed: null,
                            newtyrecostperhour: null,
                            vehiclespecification: null,
                            vehiclecount: null,
                            minVehicleStartDate: null,
                            maxVehicleEndDate: null,
                            newtyreposition: null,
                            totaltyreposition: null,
                            calculateByPercentWorn: null,
                            depthUnit: null,
                            forecastStartDate: null,
                            forecastEndDate: null
                        },
                        template: tmplTyreSpecificationEdit,
                        controller: "tyreSpecificationEditController",
                        controllerAs: "vm"
                    })        
                    
                    .state("app.site.rimspecificationedit", {
                        url: "/rimspecificationedit",
                        params: {
                            forecastid: null,
                            vehiclespecificationid: null,
                            vehiclespecification: null,
                            vehiclecount: null,
                            startdate: null,
                            enddate: null,
                            currency: null,
                            includeRimIntervalsByDays: null
                        },
                        template: tmplRimSpecificationEdit,
                        controller: "rimSpecificationEditController",
                        controllerAs: "vm"
                    })         
                    
                    .state("app.site.vehiclespecificationnewtyrechangeoutpositions", {
                        url: "/vehiclespecificationnewtyrechangeoutpositions/:forecastid",
                        template: tmplVehicleSpecificationNewTyreChangeoutPositions,
                        controller: "vehicleSpecificationNewTyreChangeoutPositionsCtrl",
                        controllerAs: "vm"
                    })

                    .state("app.site.vehiclerotationstrategies", {
                        url: "/vehiclerotationstrategies",
                        params: {
                            forecastId: null,
                            vehicleSpecificationId: null,
                            vehicleSpecification: null
                        },
                        template: tmplVehicleRotationStrategies,
                        controller: "vehicleRotationStrategiesCtrl",
                        controllerAs: "vm"
                    })

                    .state('app.admin.vehicletyrestrategy', {
                        url: '/vehicletyrestrategy',
                        template: tmplVehicleTyreStrategy,
                        controller: "vehicleTyreStrategyCtrl",
                        controllerAs: "vm"
                    });
            }
        ]);

