import { Injectable } from '@angular/core';
import {
    ChangeCompletedUi, ConditionMonitoringDisplayBiSelectionUi, ErrorsUi, FilterConstant, ForecastUi,
    LiveForecastDialogUi,
    PressureCheckUi, PressureKpiUi, ScrapLifeTrendUi, SiteHealthRateDialogUi,
    SiteHealtRatehUi, TotalDownTimeUi, TyreInInspectionUi, VehicleMaintenanceUi,
    WidgetComponentElements, WidgetConstantApiErrorUi, WidgetHelperConstant } from '../Interface/WidgetConstant.interface';
import { XlatPipe } from "../../../../../amt/i18n/xlat-pipe";

@Injectable({
    providedIn: 'root'
})

export class WidgetConstant {

    public componentElement: WidgetComponentElements = {
        pressureKpiGauge: {
            tickColor: "#292B2B",
            rangeColor: "#449D44",
            placeHolderColor: "#CCCCCC",
            font: "11px Arial,Helvetica,sans-serif"
        },
        siteHealthRateColor: {
            lessThan75Color: "#AB343E",
            between75and90Color: "#F0AD4E",
            above90Color: "#94D600",
        }
    };

    //Notificiation
    private readonly widgetConstantNotification: string = "widgetConstantApiNotification.";
    public readonly widgetNotification = {
        changesCompleted: this.xlatPipe.transform(this.widgetConstantNotification + "changesCompleted"),
        totalDowntime: this.xlatPipe.transform(this.widgetConstantNotification + "totalDowntime"),
        dataError: this.xlatPipe.transform(this.widgetConstantNotification + "dataError"),
        tyreInspection: this.xlatPipe.transform(this.widgetConstantNotification + "tyreInspection"),
        siteHealthRate: this.xlatPipe.transform(this.widgetConstantNotification + "siteHealthRate"),
        vehicleInMaintenance: this.xlatPipe.transform(this.widgetConstantNotification + "vehicleInMaintenance"),
        receivedMsg: this.xlatPipe.transform(this.widgetConstantNotification + "receivedMsg"),
        updatedMsg: this.xlatPipe.transform(this.widgetConstantNotification + "updatedMsg"),
        errorMsg: this.xlatPipe.transform(this.widgetConstantNotification + "errorMsg")
    }

    public readonly widgetName = {
        default: {
            siteHealthRating: "SiteHealthRating",
            dataErrors: "DataErrors",
            changesCompleted: "ChangesCompleted",
            maintenanceVehicles: "MaintenanceVehicles",
            inspectionTyres: "InspectionTyres",
            totalDowntime: "TotalDowntime"
        },
        siteDashboardWidgetIdDependancy: {
            conditionMonitoring: "ConditionMonitoring",
            pressureMaintenanceKPI: "PressureMaintenanceKPI",
            scrapLifeTrend: "ScrapLifeTrend",
            liveForecastRimNdt: "LiveForecastRimNDT",
            liveForecastTyresMonth: "LiveForecastTyresMonth",
            pressureChecksPerVehicle: "PressureChecksPerVehicle"
        }
    }

    private readonly widgetConstantApiError: string = "widgetConstantApiErrorUi.";
    private readonly widgetConstantApiErrorTransformItem: WidgetConstantApiErrorUi = {
        title: this.xlatPipe.transform(this.widgetConstantApiError + "title"),
        description: this.xlatPipe.transform(this.widgetConstantApiError + "description")
    };

    private readonly widgetConstantWidgetHelper: string = "widgetConstantWidgetHelper.";
    private readonly widgetHelperTransformItem: WidgetHelperConstant = {
        subtext: this.xlatPipe.transform(this.widgetConstantWidgetHelper + "subtext"),
        false: this.xlatPipe.transform(this.widgetConstantWidgetHelper + "false"),
        tyre: this.xlatPipe.transform(this.widgetConstantWidgetHelper + "tyre"),
        tyres: this.xlatPipe.transform(this.widgetConstantWidgetHelper + "tyres"),
        hr: this.xlatPipe.transform(this.widgetConstantWidgetHelper + "hr"),
        hrs: this.xlatPipe.transform(this.widgetConstantWidgetHelper + "hrs"),
        inProgress: this.xlatPipe.transform(this.widgetConstantWidgetHelper + "inProgress"),
        pending: this.xlatPipe.transform(this.widgetConstantWidgetHelper + "pedning"),
        errors: this.xlatPipe.transform(this.widgetConstantWidgetHelper + "errors"),
    };

    private readonly widgetConstantFilter: string = "widgetConstantFilter.";
    private readonly widgetFilterTransformItem: FilterConstant = {
        allVehicleSpecification: this.xlatPipe.transform(this.widgetConstantFilter + "allVehicleSpecification"),
        allVehicleApplication: this.xlatPipe.transform(this.widgetConstantFilter + "allVehicleApplication"),
        allComponentSpecification: this.xlatPipe.transform(this.widgetConstantFilter + "allComponentSpecification"),
        allLifeType: this.xlatPipe.transform(this.widgetConstantFilter + "allLifeType"),
        allEquipmentType: this.xlatPipe.transform(this.widgetConstantFilter + "allEquipmentType"),
        allManufacturer: this.xlatPipe.transform(this.widgetConstantFilter + "allManufacturer"),
        allScrapReason: this.xlatPipe.transform(this.widgetConstantFilter + "allScrapReason"),
        allRimSize: this.xlatPipe.transform(this.widgetConstantFilter + "allRimSize"),
        allTyreSize: this.xlatPipe.transform(this.widgetConstantFilter + "allTyreSize"),
        allVehicleSerialNumber: this.xlatPipe.transform(this.widgetConstantFilter + "allVehicleSerialNumber"),
        allRimSpecification: this.xlatPipe.transform(this.widgetConstantFilter + "allRimSpecification"),
        allTyreSpecification: this.xlatPipe.transform(this.widgetConstantFilter + "allTyreSpecification"),
        tyreSize: this.xlatPipe.transform(this.widgetConstantFilter + "tyreSize"),
        tyreSpecification: this.xlatPipe.transform(this.widgetConstantFilter + "tyreSpecification"),
        lifeType: this.xlatPipe.transform(this.widgetConstantFilter + "lifeType"),
        vehicleSpecification: this.xlatPipe.transform(this.widgetConstantFilter + "vehicleSpecification"),
        componentSpecification: this.xlatPipe.transform(this.widgetConstantFilter + "componentSpecification"),
        equipmentType: this.xlatPipe.transform(this.widgetConstantFilter + "equipmentType"),
        manufacturer: this.xlatPipe.transform(this.widgetConstantFilter + "manufacturer"),
        scrapReason: this.xlatPipe.transform(this.widgetConstantFilter + "scrapReason"),
        vehicleApplication: this.xlatPipe.transform(this.widgetConstantFilter + "vehicleApplication"),
        rimSize: this.xlatPipe.transform(this.widgetConstantFilter + "rimSize"),
        rimSpecification: this.xlatPipe.transform(this.widgetConstantFilter + "rimSpecification"),
        totalTyreForecast: this.xlatPipe.transform(this.widgetConstantFilter + "totalTyreForecast"),
        newTyreChangeOut: this.xlatPipe.transform(this.widgetConstantFilter + "newTyreChangeOut"),
        endLifeTyre: this.xlatPipe.transform(this.widgetConstantFilter + "endLifeTyre"),
        scrapRim: this.xlatPipe.transform(this.widgetConstantFilter + "scrapRim"),
        allRim: this.xlatPipe.transform(this.widgetConstantFilter + "allRim"),
        ndtRim: this.xlatPipe.transform(this.widgetConstantFilter + "ndtRim"),
        overDue: this.xlatPipe.transform(this.widgetConstantFilter + "overDue"),
        next2Weeks: this.xlatPipe.transform(this.widgetConstantFilter + "next2Weeks"),
        next2To4Weeks: this.xlatPipe.transform(this.widgetConstantFilter + "next2To4Weeks"),
        next4To8Weeks: this.xlatPipe.transform(this.widgetConstantFilter + "next4To8Weeks"),
        next8To12Weeks: this.xlatPipe.transform(this.widgetConstantFilter + "next8To12Weeks"),
    };

    private readonly changeCompletedUi: string = "widgetConstantChangeCompletedUi.";
    private readonly changeCompletedUiTransformItem: ChangeCompletedUi = {
        noChangeCompleted: this.xlatPipe.transform(this.changeCompletedUi + "noChangeCompleted"),
        percentOfCompleted: this.xlatPipe.transform(this.changeCompletedUi + "percentOfCompleted"),
        completed: this.xlatPipe.transform(this.changeCompletedUi + "completed"),
    }

    private readonly errorsUi: string = "widgetConstantErrorUi.";
    private readonly errorsUiTransformItem: ErrorsUi = {
        red: this.xlatPipe.transform(this.errorsUi + "red"),
        black: this.xlatPipe.transform(this.errorsUi + "black"),
        module: this.xlatPipe.transform(this.errorsUi + "module"),
        maintenanceSession: this.xlatPipe.transform(this.errorsUi + "maintenanceSession"),
        fieldSurveys: this.xlatPipe.transform(this.errorsUi + "fieldSurveys"),
        stockTake: this.xlatPipe.transform(this.errorsUi + "stockTake"),
        componentReceive: this.xlatPipe.transform(this.errorsUi + "componentReceive"),
        incompletedEndOfMonth: this.xlatPipe.transform(this.errorsUi + "incompletedEndOfMonth"),
        errors: this.xlatPipe.transform(this.errorsUi + "errors"),
    };

    private readonly forecastUi: string = "widgetConstantForecastUi.";
    private readonly forecastUiTransformItem: ForecastUi = {
        dueDate: this.xlatPipe.transform(this.forecastUi + "dueDate"),
        quantity: this.xlatPipe.transform(this.forecastUi + "quantity"),
        overDue: this.xlatPipe.transform(this.forecastUi + "overDue"),
        next2Weeks: this.xlatPipe.transform(this.forecastUi + "next2Weeks"),
        next2To4Weeks: this.xlatPipe.transform(this.forecastUi + "next2To4Weeks"),
        next4To8Weeks: this.xlatPipe.transform(this.forecastUi + "next4To8Weeks"),
        next8To12Weeks: this.xlatPipe.transform(this.forecastUi + "next8To12Weeks"),
        noResult: this.xlatPipe.transform(this.forecastUi + "noResult"),
        detail: this.xlatPipe.transform(this.forecastUi + "detail"),
    };

    private readonly pressureCheckUi: string = "widgetConstantPressureCheckUi.";
    private readonly pressureCheckUiTransformItem: PressureCheckUi = {
        search: this.xlatPipe.transform(this.pressureCheckUi + "search"),
        serialNumber: this.xlatPipe.transform(this.pressureCheckUi + "serialNumber"),
        checkNumber: this.xlatPipe.transform(this.pressureCheckUi + "checkNumber"),
        fieldSurvey: this.xlatPipe.transform(this.pressureCheckUi + "fieldSurvey"),
        maintenanceSession: this.xlatPipe.transform(this.pressureCheckUi + "maintenanceSession")
    };

    private readonly pressureKpiUi: string = "widgetConstantPressureKpiUi.";
    private readonly pressureKpiUiTransformItem: PressureKpiUi = {
        noResult: this.xlatPipe.transform(this.pressureKpiUi + "noResult"),
        target: this.xlatPipe.transform(this.pressureKpiUi + "target")
    };

    private readonly scrapLifeTrendUi: string = "widgetConstantScrapLifeTrendUi.";
    private readonly scrapLifeTrendUiTransformItem: ScrapLifeTrendUi = {
        noResult: this.xlatPipe.transform(this.scrapLifeTrendUi + "noResult"),
        targetHours: this.xlatPipe.transform(this.scrapLifeTrendUi + "targetHours"),
        qtyOfScrap: this.xlatPipe.transform(this.scrapLifeTrendUi + "qtyOfScrap"),
        new: this.xlatPipe.transform(this.scrapLifeTrendUi + "new"),
        major: this.xlatPipe.transform(this.scrapLifeTrendUi + "major"),
        cosmetic: this.xlatPipe.transform(this.scrapLifeTrendUi + "cosmetic"),
        transfer: this.xlatPipe.transform(this.scrapLifeTrendUi + "transfer"),
        secondHand: this.xlatPipe.transform(this.scrapLifeTrendUi + "secondHand"),
        scrapDate: this.xlatPipe.transform(this.scrapLifeTrendUi + "scrapDate"),
        twelveMonthsMa: this.xlatPipe.transform(this.scrapLifeTrendUi + "twelveMonthsMa"),
        averageLife: this.xlatPipe.transform(this.scrapLifeTrendUi + "averageLife"),
        lifeType: this.xlatPipe.transform(this.scrapLifeTrendUi + "lifeType"),
        retread: this.xlatPipe.transform(this.scrapLifeTrendUi + "retread")
    };

    private readonly siteHealthRateUi: string = "widgetConstantSiteHealthRateUi.";
    private readonly siteHealthRateUiTransformItem: SiteHealtRatehUi = {
        percent: this.xlatPipe.transform(this.siteHealthRateUi + "percent"),
        click: this.xlatPipe.transform(this.siteHealthRateUi + "click"),
        here: this.xlatPipe.transform(this.siteHealthRateUi + "here"),
        findOut: this.xlatPipe.transform(this.siteHealthRateUi + "findOut")
    };

    private readonly totalDownTimeUi: string = "widgetConstantTotalDownTimeUi.";
    private readonly totalDownTimeUiTransformItem: TotalDownTimeUi = {
        description: this.xlatPipe.transform(this.totalDownTimeUi + "description")
    };

    private readonly tyreInInspectionUi: string = "widgetConstantTyreInInspectionUi.";
    private readonly tyreInInspectionUiTransformItem: TyreInInspectionUi = {
        description: this.xlatPipe.transform(this.tyreInInspectionUi + "description")
    };

    private readonly vehicleInMaintenanceUi: string = "widgetConstantVehicleInMaintenanceUi.";
    private readonly vehicleInMaintenanceUiTransformItem: VehicleMaintenanceUi = {
        description: this.xlatPipe.transform(this.vehicleInMaintenanceUi + "description"),
        inProgress: this.xlatPipe.transform(this.vehicleInMaintenanceUi + "inProgress")
    };

    private readonly siteHealthRateDialogUi: string = "widgetConstantSiteHealthRateDialogUi.";
    private readonly siteHealthRateDialogUiTransformItem: SiteHealthRateDialogUi = {
        title: this.xlatPipe.transform(this.siteHealthRateDialogUi + "title"),
        module: this.xlatPipe.transform(this.siteHealthRateDialogUi + "module"),
        tyreInInspection: this.xlatPipe.transform(this.siteHealthRateDialogUi + "tyreInInspection"),
        error: this.xlatPipe.transform(this.siteHealthRateDialogUi + "error"),
        maintenanceSessionsErrors: this.xlatPipe.transform(this.siteHealthRateDialogUi + "maintenanceSessionsErrors"),
        pendingMaintenanceSession: this.xlatPipe.transform(this.siteHealthRateDialogUi + "pendingMaintenanceSession"),
        fieldSurveyErrors: this.xlatPipe.transform(this.siteHealthRateDialogUi + "fieldSurveyErrors"),
        componentReceiveError: this.xlatPipe.transform(this.siteHealthRateDialogUi + "componentReceiveError"),
        stoketakeError: this.xlatPipe.transform(this.siteHealthRateDialogUi + "stoketakeError"),
        visualInspectionsNotActioned: this.xlatPipe.transform(this.siteHealthRateDialogUi + "visualInspectionsNotActioned"),
        currentlyDueNewTyreFitments: this.xlatPipe.transform(this.siteHealthRateDialogUi + "currentlyDueNewTyreFitments"),
        currentlyDueRimNdt: this.xlatPipe.transform(this.siteHealthRateDialogUi + "currentlyDueRimNdt"),
        currentlyDueRimScrap: this.xlatPipe.transform(this.siteHealthRateDialogUi + "currentlyDueRimScrap")
    };

    private readonly forecastTyreDialogUi: string = "widgetConstantForecastTyreDialogUi.";
    private readonly forecastRimDialogUi: string = "widgetConstantForecastRimDialogUi.";
    private readonly forecastTyreDialogUiTransformItem: LiveForecastDialogUi = {
        title: this.xlatPipe.transform(this.forecastTyreDialogUi + "title"),
        changeType: this.xlatPipe.transform(this.forecastTyreDialogUi + "changeType"),
        forecastPeriod: this.xlatPipe.transform(this.forecastTyreDialogUi + "forecastPeriod"),
        vehicleSpecification: this.xlatPipe.transform(this.forecastTyreDialogUi + "vehicleSpecification"),
        vehicleSpecificationSelected: this.xlatPipe.transform(this.forecastTyreDialogUi + "vehicleSpecificationSelected"),
        vehicleSerialNumber: this.xlatPipe.transform(this.forecastTyreDialogUi + "vehicleSerialNumber"),
        vehicleSerialNumberSelected: this.xlatPipe.transform(this.forecastTyreDialogUi + "vehicleSerialNumberSelected"),
        tyreSpecification: this.xlatPipe.transform(this.forecastTyreDialogUi + "tyreSpecification"),
        tyreSpecificationSelected: this.xlatPipe.transform(this.forecastTyreDialogUi + "tyreSpecificationSelected"),
        tyreSize: this.xlatPipe.transform(this.forecastTyreDialogUi + "tyreSize"),
        tyreSizeSelected: this.xlatPipe.transform(this.forecastTyreDialogUi + "tyreSizeSelected"),
        dueDateTitle: this.xlatPipe.transform(this.forecastTyreDialogUi + "dueDateTitle"),
        currentHoursTitle: this.xlatPipe.transform(this.forecastTyreDialogUi + "currentHoursTitle"),
        rtdTitle: this.xlatPipe.transform(this.forecastTyreDialogUi + "rtdTitle"),
        otdTitle: this.xlatPipe.transform(this.forecastTyreDialogUi + "otdTitle"),
        remainingPercentTitle: this.xlatPipe.transform(this.forecastTyreDialogUi + "remainingPercentTitle"),
        tyreSerialNumberTitle: this.xlatPipe.transform(this.forecastTyreDialogUi + "tyreSerialNumberTitle"),
        tyreSizeTitle: this.xlatPipe.transform(this.forecastTyreDialogUi + "tyreSizeTitle"),
        tyreSpecificationTitle: this.xlatPipe.transform(this.forecastTyreDialogUi + "tyreSpecificationTitle"),
        vehicleSerialNumberTitle: this.xlatPipe.transform(this.forecastTyreDialogUi + "vehicleSerialNumberTitle"),
        positionTitle: this.xlatPipe.transform(this.forecastTyreDialogUi + "positionTitle"),
        fitmentTypeTitle: this.xlatPipe.transform(this.forecastTyreDialogUi + "fitmentTypeTitle"),
        removalTypeTitle: this.xlatPipe.transform(this.forecastTyreDialogUi + "removalTypeTitle"),
        rimSize: this.xlatPipe.transform(this.widgetConstantFilter + "rimSize"),
        rimSpecification: this.xlatPipe.transform(this.widgetConstantFilter + "rimSpecification"),
        rimSizeSelected: this.xlatPipe.transform(this.forecastRimDialogUi + "rimSizeSelected"),
        rimSpecificationSelected: this.xlatPipe.transform(this.forecastRimDialogUi + "rimSpecificationSelected"),
        currentHoursSinceLastNdtTitle: this.xlatPipe.transform(this.forecastRimDialogUi + "currentHoursSinceLastNdt"),
        rimSerialNumberTitle: this.xlatPipe.transform(this.forecastRimDialogUi + "rimSerialNumberTitle"),
        rimSizeTitle: this.xlatPipe.transform(this.forecastRimDialogUi + "rimSizeTitle"),
        rimSpecificationTitle: this.xlatPipe.transform(this.forecastRimDialogUi + "rimSpecificationTitle"),
        rimTitle: this.xlatPipe.transform(this.forecastRimDialogUi + "title"),
    };

    private readonly conditionMoniotringDisplayBySelectionUi: string = "widgetConstantconditionMoniotringDisplayBySelectionUi.";
    private readonly conditionMoniotringDisplayBySelectionUiTransformItem: ConditionMonitoringDisplayBiSelectionUi = {
        actionStatus: this.xlatPipe.transform(this.conditionMoniotringDisplayBySelectionUi + "actionStatus"),
        recommendedAction: this.xlatPipe.transform(this.conditionMoniotringDisplayBySelectionUi + "recommendedAction"),
        lastInspectedDate: this.xlatPipe.transform(this.conditionMoniotringDisplayBySelectionUi + "lastInspectedDate")
    };

    constructor(protected xlatPipe: XlatPipe) { }

    getApiErrorUi() {
        return this.widgetConstantApiErrorTransformItem;
    }

    getWidgetHelperConstant() {
        return this.widgetHelperTransformItem;
    }

    getFilterConstant() {
        return this.widgetFilterTransformItem;
    }

    getChangeCompletedWidgetUi() {
        return this.changeCompletedUiTransformItem;
    }

    getErrorWidgetUi() {
        return this.errorsUiTransformItem;
    }

    getForecastWidgetUi() {
        return this.forecastUiTransformItem;
    }

    getPressureCheckWidgetUi() {
        return this.pressureCheckUiTransformItem;
    }

    getPressureKpiWidgetUi() {
        return this.pressureKpiUiTransformItem;
    }

    getPressureKpiGauge() {
        return this.componentElement.pressureKpiGauge;
    }

    getScrapLifeTrendWidgetWidgetUi() {
        return this.scrapLifeTrendUiTransformItem;
    }

    getSiteHealthRateWidgetUi() {
        return this.siteHealthRateUiTransformItem;
    }

    getTotalDownTimeWidgetUi() {
        return this.totalDownTimeUiTransformItem;
    }

    getTyreInInspectionWidgetUi() {
        return this.tyreInInspectionUiTransformItem;
    }

    getVehicleMaintenanceWidgetUi() {
        return this.vehicleInMaintenanceUiTransformItem;
    }

    getSiteHealthRateDialogUi() {
        return this.siteHealthRateDialogUiTransformItem;
    }

    getLiveForecastDialog() {
        return this.forecastTyreDialogUiTransformItem;
    }

    getConditionMoinotringSelectionUi() {
        return this.conditionMoniotringDisplayBySelectionUiTransformItem;
    }

    getConditionMoinotringSelectionList() {
        let uiSelection = this.conditionMoniotringDisplayBySelectionUiTransformItem;
        return [uiSelection.actionStatus, uiSelection.lastInspectedDate, uiSelection.recommendedAction];
    }
    
}