    /**
        * @ngdoc directive
        * @name amtFramework.grid.directive:amtCommandColumn
        * @restrict E
        * @description
        * Command column that renders a button 
        * @param {string} title Column header title
        * @param {string=} width Initial width of the column
        * @param {callback} onClick Event fires when the user clickes on the button
        * @param {string=} attributes Additional html attributes
        * @element
        * @example 
    **/
    
//import angular from 'angular';

angular.module('amtFramework.grid').directive('amtCommandColumn',
    function () {
            return {
                require: ['^amtGrid', '?^amtGridGroupHeaderColumn'],
                restrict: 'E',
                scope: {
                    title: '@',
                    width: '@',
                    onClick: '&',
                    attributes: '@',
                    forceHide: '=?'
                },
                link: function (scope: any, elem, attr, controllerarr) {

                    var amtGridCtrl = (controllerarr[1] == null) ? controllerarr[0] : controllerarr[1];

                    var kendoColumnDefinition: any = {
                        id: "customCommand"
                    };

                    if (scope.width) {
                        kendoColumnDefinition.width = scope.width;
                    }

                    kendoColumnDefinition.forceHide = scope.forceHide;
                    kendoColumnDefinition.command = {
                        text: scope.title,
                        name: 'custom_' + scope.title.replace(/\(|\)|\s/g,""),
                        click: function(e) {
                            e.preventDefault();
                            var dataItem = this.dataItem($(e.currentTarget).closest("tr"));

                            var args = { primaryKey: dataItem[amtGridCtrl.primaryKey], dataItem: dataItem };

                            scope.onClick(args);

                        }
                    };

                    if (scope.attributes) {
                        kendoColumnDefinition.attributes = scope.attributes;
                    }

                    scope.kendoColumnDefinition = kendoColumnDefinition;
                    scope.$on('$destroy', function() {
                        amtGridCtrl.removeColumn(scope.kendoColumnDefinition);
                    });

                    amtGridCtrl.addColumn(kendoColumnDefinition);
                }
            };
    }
);
