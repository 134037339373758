//import angular from 'angular';
import { UpdateForecastOverrideModelMaker } from '../updateForecastOverrideCtrl';



angular.module("app.site").controller("tyreSpecificationEditController", tyreSpecificationEditController);

    // @ts-ignore
    tyreSpecificationEditController.$inject = ['$scope', '$state', '$rootScope', 'amtCommandQuerySvc',
        'amtConstants', 'amtXlatSvc', 'enums', 'notifySvc', 'WindowFactory', 'errorReporter', 'helperSvc', 'forecastingSvc'];

    // ReSharper disable once InconsistentNaming
    function tyreSpecificationEditController($scope, $state, $rootScope, amtCommandQuerySvc, amtConstants,
        xlatSvc, enums, notifySvc, WindowFactory, errorReporter, helperSvc, forecastingSvc) {

        var vm = this;

        vm.apiUrls = amtConstants.url;

        vm.gridControl = {};
        vm.tyreSpecControl = {};

        vm.forecastId = $rootScope.$stateParams.forecastid ? $rootScope.$stateParams.forecastid : 0;
        vm.vehicleSpecificationId = $rootScope.$stateParams.vehiclespecificationid ? $rootScope.$stateParams.vehiclespecificationid : 0;
        vm.forecastVsActualLife = $rootScope.$stateParams.forecastvsactuallife ? $rootScope.$stateParams.forecastvsactuallife.toFixed(2) + "%" : "0%";
        vm.spareTyreNeed = $rootScope.$stateParams.sparetyreneed ? $rootScope.$stateParams.sparetyreneed.toFixed(2) + "%" : "0%";

        vm.newTyreCostPerHour = 0;

        vm.calculateByPercentWorn = $rootScope.$stateParams.calculateByPercentWorn;

        vm.vehicleSpecification = $rootScope.$stateParams.vehiclespecification ? $rootScope.$stateParams.vehiclespecification : "";
        vm.vehicleCount = $rootScope.$stateParams.vehiclecount ? $rootScope.$stateParams.vehiclecount : 0;
        vm.minVehicleStartDate = $rootScope.$stateParams.minVehicleStartDate ? $rootScope.$stateParams.minVehicleStartDate : new Date();
        vm.maxVehicleEndDate = $rootScope.$stateParams.maxVehicleEndDate ? $rootScope.$stateParams.maxVehicleEndDate : 0;
        vm.tyrePositions = ($rootScope.$stateParams.newtyreposition ? $rootScope.$stateParams.newtyreposition : "") + "/" + ($rootScope.$stateParams.totaltyreposition ? $rootScope.$stateParams.totaltyreposition : "");

        vm.forecastStartDate = $rootScope.$stateParams.forecastStartDate ? $rootScope.$stateParams.forecastStartDate : new Date();
        vm.forecastEndDate = $rootScope.$stateParams.forecastStartDate ? $rootScope.$stateParams.forecastEndDate : null;

        vm.depthUnit = $rootScope.$stateParams.depthUnit;

        vm.filterValues = {
            forecastId: vm.forecastId,
            vehicleSpecificationId: vm.vehicleSpecificationId
        };

        this.$onInit = function () {
            if (!(vm.forecastId > 0)) {
                $state.go('app.site.forecastlist');
            }
        };

        vm.addTyreSpecification = function () {

            vm.processing = true;

            amtCommandQuerySvc.put(amtConstants.url.addNewTyreSpecification, {
                forecastId: vm.forecastId,
                vehicleSpecificationId: vm.vehicleSpecificationId,
                tyreSpecificationId: helperSvc.getKey(vm.selectedTyreSpecification)
            }).then(function (response) {

                notifySvc.success(xlatSvc.xlat("tyreSpecificationEdit.addNewTyreSpecificationSuccess"));

                vm.refresh();

                if (vm.tyreSpecControl) {
                    vm.tyreSpecControl.clear();
                    vm.tyreSpecControl.rebind();
                }

            }).catch(function (error) {
                errorReporter.logError(error);
            }).finally(function () {
                vm.processing = false;
            });
        };

        vm.goToParentRoute = function () {
            forecastingSvc.setActiveTabName("Tyres");
            $rootScope.goBack();
        };

        vm.refreshHeading = function () {
            vm.newTyreCostPerHour = $rootScope.$stateParams.newtyrecostperhour ? $rootScope.$stateParams.newtyrecostperhour : 0;
            if (typeof vm.newTyreCostPerHour === 'number') {
                vm.newTyreCostPerHour = vm.newTyreCostPerHour.toFixed(2);
            }
        };

        vm.refreshHeading();

        vm.refresh = function () {
            if (vm.gridControl && vm.gridControl.refresh) {
                vm.gridControl.refresh();
            }
            vm.refreshHeading();
        };

        $scope.updateScrapHoursOverride = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                vehicleSpecificationId: vm.vehicleSpecificationId,
                type: enums.forecastOverrideTypes.tyreScrapHours,
                value: dataItem.scrapHoursOverride,
                componentSpecificationId: dataItem.tyreSpecificationId,
                heading: xlatSvc.xlat('forecastTyres.tyreLifeHoursOverride')
            }), vm.refresh);
        };

        $scope.updateScrapPercentWornOverride = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                vehicleSpecificationId: vm.vehicleSpecificationId,
                type: enums.forecastOverrideTypes.tyreScrapPercentWorn,
                value: dataItem.scrapPercentWornOverride,
                componentSpecificationId: dataItem.tyreSpecificationId,
                max: 100,
                heading: xlatSvc.xlat('forecastTyres.scrapPercentWornOverride')
            }), vm.refresh);
        };

        $scope.updateNewRotationHoursOverride = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                vehicleSpecificationId: vm.vehicleSpecificationId,
                type: enums.forecastOverrideTypes.tyreNewRotationHours,
                value: dataItem.newRotationHoursOverride,
                componentSpecificationId: dataItem.tyreSpecificationId,
                heading: xlatSvc.xlat('forecastTyres.newTyreChangeoutHoursOverride')
            }), vm.refresh);
        };

        $scope.updateNewRotationPercentWornOverride = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                vehicleSpecificationId: vm.vehicleSpecificationId,
                type: enums.forecastOverrideTypes.tyreNewRotationPercentWorn,
                value: dataItem.newRotationPercentWornOverride,
                componentSpecificationId: dataItem.tyreSpecificationId,
                max: 100,
                heading: xlatSvc.xlat('forecastTyres.newRotationPercentWornOverride')
            }), vm.refresh);
        };

        $scope.updateWearRateOverride = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                vehicleSpecificationId: vm.vehicleSpecificationId,
                type: enums.forecastOverrideTypes.tyreWearRate,
                value: dataItem.wearRateOverride,
                componentSpecificationId: dataItem.tyreSpecificationId,
                decimalPlaces: 2,
                heading: xlatSvc.xlat('forecastTyres.wearRateOverride', vm.depthUnit)
            }), vm.refresh);
        };
     
        $scope.updateForecastFitment = function (dataItem) {

            WindowFactory.openItem({
                component: 'update-forecast-fitment',
                canMinimise: false,
                modal: true,
                canClose: false,
                footerOff: false,
                width: 1100,
                top: 10,
                caption: xlatSvc.xlat('tyreSpecificationEdit.assignTyreSpecsToVehicles'),
                initParams: {
                    forecastId: vm.forecastId,
                    vehicleSpecificationId: vm.vehicleSpecificationId,
                    forecastStartDate: vm.forecastStartDate,
                    forecastEndDate: vm.forecastEndDate
                },
                onDataChangeHandler: function () {
                    vm.refresh();
                }
            });
        };
    }
