//import angular from 'angular';
import tmpl from './moduleChooserPopup.html';



angular.module('app').component('moduleChooserPopup', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='            
        },
        template: tmpl,
		controller: ['$scope', 'WindowFactory', '$state', 'enums',
			function ($scope, WindowFactory: IWindowFactory, $state, enums) {
				var vm = this;
				$scope.vm = this;
				
				this.$onInit = function () {
					vm.buttons = [
						{
							primary: false, 
							cancel: true, 
							value: 'common.cancel_label',
							name: 'cancelButton',
							click: 'cancel',
							type: enums.buttonTypes.button
						}
					];
					
					vm.buttonStates = {
						cancelButton: {
							visible: true, 
							disabled: false
						}
					};
					
					vm.buttonMethods = {
						cancel: vm.onCancel
					};
				};
	
				vm.onCancel = function() {
					WindowFactory.closeWindow(vm.wnd);
				};
	
				vm.close = function(button) {
					$state.go('mobile.' + button.route);
					WindowFactory.closeWindow(vm.wnd);
				};
    		}
		]
    });
