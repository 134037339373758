//import angular from 'angular';
import FileManagement from '../../../services/fileManagement';
import OcConfigSvc from '../../../services/ocConfigSvc';
import tmpl from './vehicleReceiveDetails.html';



class VehicleReceiveDetailsCtrl implements ng.IController {

    form: ng.IFormController;    

    showSiteSerialNumber: string;

    initialised: () => void;
    
    acceptedFileTypes: string[];
    
    details: {
        manufacturerSerialNumber: string;
        combinedDateTime: Date;
        hasDistanceMeter: boolean;
        distance?: number;
        hours: number;
        attachments: IFile[];
        specification: {
            id: guid;
            hasHoursMeter: boolean;
        }
    };

    static $inject = ['$scope', 'dataBroker', 'ocConfigSvc', '$timeout', 'amtConstants', 'fileManagement'];

    constructor(private $scope: ng.IScope, private dataBroker: IDataBroker, private ocConfigSvc: OcConfigSvc,
        private $timeout: ng.ITimeoutService, private amtConstants: IAmtConstants, private fileManagement: FileManagement) {

        // ocDateTimePicker doesn't seem to set pristine properly, so do it here
        this.$scope.$watch(() => this.details.combinedDateTime, (newVal, oldVal) => {
            if (newVal !== oldVal)
                this.form.$setDirty();
        });

        this.$scope.$watch(() => this.details.hasDistanceMeter, newVal => {
            if (!newVal)
                this.details.distance = null; // if hasDistanceMeter is false clear the distance
        });

        this.$scope.$watch(() => this.details.specification, async newVal => {
            if (!newVal)
                return;

            let details = await this.dataBroker.getVehicleSpecificationDetails(newVal.id);
            
            this.details.specification.hasHoursMeter = details.hasHoursMeter;

            if (!details.hasHoursMeter)
                this.details.hours = null;
        });
    }

    $onInit() {

        this.acceptedFileTypes = this.fileManagement.getAcceptedFileExtensions([FileType.document, FileType.text, FileType.pdf, FileType.spreadsheet, FileType.image]);

        this.showSiteSerialNumber = this.ocConfigSvc.user.site.settings.tyreMaintenance.siteSerialNumber.vehicle;        

        if (!this.details.attachments)
            this.details.attachments = [];

        if (this.initialised)
            this.$timeout(() => this.initialised());        
    }
}

class VehicleReceiveDetailsComponent implements ng.IComponentOptions {
    public bindings = {
        details: '=',        
        referenceData: '<',
        selectedCondition: '<',
        existingSerials: '<',
        errorMessage: '<',
        initialised: '&'
    };
    public template = tmpl;
    public controller = VehicleReceiveDetailsCtrl;
    public controllerAs = "vm";
}

angular.module('app.vehicle').component('vehicleReceiveDetails', new VehicleReceiveDetailsComponent());  