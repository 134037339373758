//import angular from 'angular';
import * as _ from 'underscore'


    angular.module('app').filter('truncate', truncateFilter);

    // @ts-ignore
    truncateFilter.$inject = ["appConfig"];
    function truncateFilter(appConfig) {

        return function (inputString, customLength) {
            if (_.isEmpty(inputString)) {
                return inputString;
            }
            var textLength = customLength || appConfig.defaultMaxTextLength;
            if (inputString.length > textLength) {
                return inputString.substring(0, textLength) + " ...";
            }
            return inputString;
        };
    }
