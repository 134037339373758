/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var progressbar_base_1 = require("./common/progressbar-base");
var core_1 = require("@angular/core");
var util_1 = require("./common/util");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
/**
 * Represents the [Kendo UI ProgressBar component for Angular]({% slug overview_progressbar %}).
 *
 * @example
 * ```ts-preview
 * _@Component({
 *    selector: 'my-app',
 *    template: `
 *        <kendo-progressbar [value]="value">
 *        </kendo-progressbar>
 *    `
 * })
 * class AppComponent {
 *     public value = 50;
 * }
 * ```
 */
var ProgressBarComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ProgressBarComponent, _super);
    /**
     * @hidden
     */
    function ProgressBarComponent(localization, zone, renderer) {
        var _this = _super.call(this, localization) || this;
        _this.localization = localization;
        _this.zone = zone;
        _this.renderer = renderer;
        /**
         * Determines whether the status label will be visible.
         * Defaults to `true`&mdash;the label will be visible and displayed with the default
         * `LabelSettings` having its position set to `end` and its format set to `value`.
         */
        _this.label = true;
        /**
         * The animation configuration of the ProgressBar.
         * Defaults to `false`.
         */
        _this.animation = false;
        /**
         * Fires when the animation which indicates the latest value change is completed.
         */
        _this.animationEnd = new core_1.EventEmitter();
        return _this;
    }
    Object.defineProperty(ProgressBarComponent.prototype, "showLabel", {
        /**
         * @hidden
         */
        get: function () {
            if (typeof this.label === 'boolean') {
                return this.label;
            }
            else {
                if (this.label && !this.label.hasOwnProperty('visible')) {
                    this.label.visible = true;
                }
                return this.label.visible;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressBarComponent.prototype, "labelPosition", {
        /**
         * @hidden
         */
        get: function () {
            if (typeof this.label === 'boolean') {
                return 'end';
            }
            else {
                if (this.label && !this.label.hasOwnProperty('position')) {
                    this.label.position = 'end';
                }
                return this.label.position;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressBarComponent.prototype, "isPositionStart", {
        /**
         * @hidden
         */
        get: function () {
            return this.labelPosition === 'start';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressBarComponent.prototype, "isPositionCenter", {
        /**
         * @hidden
         */
        get: function () {
            return this.labelPosition === 'center';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressBarComponent.prototype, "isPositionEnd", {
        /**
         * @hidden
         */
        get: function () {
            return this.labelPosition === 'end';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressBarComponent.prototype, "formattedLabelValue", {
        /**
         * @hidden
         */
        get: function () {
            return util_1.formatValue(this.displayValue, this.min, this.max, this.label);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @hidden
     */
    ProgressBarComponent.prototype.ngOnChanges = function (changes) {
        _super.prototype.ngOnChanges.call(this, changes);
        if (this.isAnimationInProgress && util_1.stopCurrentAnimation(changes)) {
            this.cancelCurrentAnimation = true;
        }
        if (util_1.runAnimation(changes, this.animation, this.previousValue, this.displayValue) && !changes.value.firstChange) {
            this.startAnimation(this.previousValue);
        }
    };
    /**
     * @hidden
     */
    ProgressBarComponent.prototype.ngOnDestroy = function () {
        if (this.animationFrame) {
            cancelAnimationFrame(this.animationFrame);
        }
    };
    /**
     * @hidden
     */
    ProgressBarComponent.prototype.startAnimation = function (previousValue) {
        var _this = this;
        this.isAnimationInProgress = true;
        var element = this.progressStatusElement.nativeElement;
        var wrapperElement = this.progressStatusWrapperElement.nativeElement;
        var animationOptions = this.getAnimationOptions(previousValue);
        this.zone.runOutsideAngular(function () {
            if (_this.animationFrame) {
                cancelAnimationFrame(_this.animationFrame);
            }
            var animate = function () {
                var elapsed = new Date().getTime() - animationOptions.startTime;
                var position = Math.min(elapsed / animationOptions.duration, 1);
                var size = animationOptions.startSize + animationOptions.deltaSize * position;
                var wrapperSize = (100 / size) * 100;
                _this.renderValueChange(element, wrapperElement, animationOptions.property, size, wrapperSize);
                if (position < 1) {
                    if (_this.cancelCurrentAnimation) {
                        _this.resetProgress(element, wrapperElement, animationOptions.property);
                        return;
                    }
                    _this.animationFrame = requestAnimationFrame(animate);
                }
                else {
                    _this.stopAnimation(previousValue);
                }
            };
            animate();
        });
    };
    Object.defineProperty(ProgressBarComponent.prototype, "animationDuration", {
        /**
         * @hidden
         */
        get: function () {
            if (typeof this.animation === 'boolean') {
                return 400;
            }
            else {
                if (this.animation && !this.animation.hasOwnProperty('duration')) {
                    this.animation.duration = 400;
                }
                return this.animation.duration;
            }
        },
        enumerable: true,
        configurable: true
    });
    ProgressBarComponent.prototype.stopAnimation = function (value) {
        var _this = this;
        if (kendo_angular_common_1.hasObservers(this.animationEnd)) {
            this.zone.run(function () {
                _this.animationEnd.emit({
                    from: value,
                    to: _this.displayValue
                });
            });
        }
        this.zone.run(function () {
            _this.isAnimationInProgress = false;
        });
    };
    ProgressBarComponent.prototype.getAnimationOptions = function (value) {
        var isHorizontal = this.orientation === 'horizontal';
        var previousRatio = util_1.calculateRatio(this.min, this.max, value);
        var previousStatusWidth = isHorizontal ? previousRatio * 100 : 100;
        var previousStatusHeight = !isHorizontal ? previousRatio * 100 : 100;
        var property = isHorizontal ? 'width' : 'height';
        var startTime = new Date().getTime();
        var startSize = isHorizontal ? previousStatusWidth : previousStatusHeight;
        var deltaSize = isHorizontal ? this.statusWidth - previousStatusWidth : this.statusHeight - previousStatusHeight;
        var duration = this.animationDuration * Math.abs((deltaSize / 100));
        return {
            property: property,
            startTime: startTime,
            startSize: startSize,
            deltaSize: deltaSize,
            duration: duration
        };
    };
    ProgressBarComponent.prototype.renderValueChange = function (element, wrapperElement, property, size, wrapperSize) {
        this.renderer.setStyle(element, property, size + '%');
        this.renderer.setStyle(wrapperElement, property, wrapperSize + '%');
    };
    ProgressBarComponent.prototype.resetProgress = function (element, wrapperElement, property) {
        var _this = this;
        var size = util_1.calculateRatio(this.min, this.max, this.value);
        var newSize = size * 100;
        var newWrapperSize = 100 / size;
        this.renderValueChange(element, wrapperElement, property, newSize, newWrapperSize);
        this.zone.run(function () {
            _this.cancelCurrentAnimation = false;
            _this.isAnimationInProgress = false;
        });
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], ProgressBarComponent.prototype, "label", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], ProgressBarComponent.prototype, "progressCssStyle", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], ProgressBarComponent.prototype, "progressCssClass", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], ProgressBarComponent.prototype, "emptyCssStyle", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], ProgressBarComponent.prototype, "emptyCssClass", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], ProgressBarComponent.prototype, "animation", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ProgressBarComponent.prototype, "animationEnd", void 0);
    tslib_1.__decorate([
        core_1.ViewChild('progressStatus', { static: false }),
        tslib_1.__metadata("design:type", core_1.ElementRef)
    ], ProgressBarComponent.prototype, "progressStatusElement", void 0);
    tslib_1.__decorate([
        core_1.ViewChild('progressStatusWrap', { static: false }),
        tslib_1.__metadata("design:type", core_1.ElementRef)
    ], ProgressBarComponent.prototype, "progressStatusWrapperElement", void 0);
    ProgressBarComponent = tslib_1.__decorate([
        core_1.Component({
            exportAs: 'kendoProgressBar',
            selector: 'kendo-progressbar',
            template: "\n        <span class=\"k-progress-status-wrap\"\n            [class.k-progress-start]=\"isPositionStart\"\n            [class.k-progress-center]=\"isPositionCenter\"\n            [class.k-progress-end]=\"isPositionEnd\"\n            [ngStyle]=\"emptyCssStyle\"\n            [ngClass]=\"emptyCssClass\">\n            <span *ngIf=\"showLabel\" class=\"k-progress-status\">{{formattedLabelValue}}</span>\n        </span>\n        <div\n            #progressStatus\n            class=\"k-state-selected\"\n            [class.k-complete]=\"isCompleted\"\n            [ngStyle]=\"progressCssStyle\"\n            [ngClass]=\"progressCssClass\"\n            [style.width.%]=\"statusWidth\"\n            [style.height.%]=\"statusHeight\"\n            >\n            <span\n                #progressStatusWrap\n                class=\"k-progress-status-wrap\"\n                [style.width.%]=\"statusWrapperWidth\"\n                [style.height.%]=\"statusWrapperHeight\"\n                [class.k-progress-start]=\"isPositionStart\"\n                [class.k-progress-center]=\"isPositionCenter\"\n                [class.k-progress-end]=\"isPositionEnd\"\n                >\n                <span *ngIf=\"showLabel\" class=\"k-progress-status\">{{formattedLabelValue}}</span>\n            </span>\n        </div>\n       ",
            providers: [
                kendo_angular_l10n_1.LocalizationService,
                {
                    provide: kendo_angular_l10n_1.L10N_PREFIX,
                    useValue: 'kendo.progressbar'
                }
            ]
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService,
            core_1.NgZone,
            core_1.Renderer2])
    ], ProgressBarComponent);
    return ProgressBarComponent;
}(progressbar_base_1.ProgressBarBase));
exports.ProgressBarComponent = ProgressBarComponent;
