/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var utils_1 = require("../../utils");
var drop_hint_component_1 = require("./drop-hint.component");
var drag_and_drop_asset_service_1 = require("../editing-services/drag-and-drop-asset.service");
/**
 * @hidden
 */
var DropHintService = /** @class */ (function (_super) {
    tslib_1.__extends(DropHintService, _super);
    function DropHintService(componentFactoryResolver) {
        var _this = _super.call(this) || this;
        _this.componentFactoryResolver = componentFactoryResolver;
        return _this;
    }
    DropHintService.prototype.initialize = function (container, template) {
        if (utils_1.isPresent(this._componentRef)) {
            this.ngOnDestroy();
        }
        var hintComponentFactory = this.componentFactoryResolver.resolveComponentFactory(drop_hint_component_1.DropHintComponent);
        this.componentRef = container.createComponent(hintComponentFactory);
        this.hide();
        this.componentRef.instance.template = template;
        this.componentRef.changeDetectorRef.detectChanges();
    };
    DropHintService.prototype.updateDropHintData = function (action, sourceItem, destinationItem) {
        var dropHint = this.componentRef.instance;
        if (action === dropHint.action && utils_1.dataItemsEqual(sourceItem, dropHint.sourceItem) && utils_1.dataItemsEqual(destinationItem, dropHint.destinationItem)) {
            return;
        }
        dropHint.action = action;
        dropHint.sourceItem = sourceItem;
        dropHint.destinationItem = destinationItem;
        dropHint.detectChanges();
    };
    DropHintService = tslib_1.__decorate([
        core_1.Injectable(),
        tslib_1.__metadata("design:paramtypes", [core_1.ComponentFactoryResolver])
    ], DropHintService);
    return DropHintService;
}(drag_and_drop_asset_service_1.DragAndDropAssetService));
exports.DropHintService = DropHintService;
