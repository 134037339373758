import { Injectable } from '@angular/core';
import { UserPosition } from '../Interface/SiteDashboard.interface';

@Injectable({
    providedIn: 'root'
})

export class ReorderLocationHelper {

    constructor() {
    }

    //Sort the widgets from small to big size by using RowSpan
    widgetSortbySmallToBig(widget: UserPosition[]) {
        let sortedWidgets = widget.sort((a, b) => (a.rowSpan < b.rowSpan ? -1 : 1));
        return sortedWidgets;
    }

    //Sort the widgets from big to small size by using RowSpan
    widgetSortbyBigToSmall(widget: UserPosition[]) {
        let sortedWidgets = widget.sort((a, b) => (a.rowSpan > b.rowSpan ? -1 : 1));
        return sortedWidgets;
    }

    //get sorted availableLocation by asc (using col)
    availableLocationSortbyCol(location: any) {
        let sortedLocation = location.sort((a, b) => (a.col < b.col ? -1 : 1));
        return sortedLocation;
    }

    //Filter out the used reference
    deleteUsedReference(reference, usedReference) {
        let newList = [];
        newList = reference.filter(ref => {
            return !usedReference.find(used => {
                return used.col === ref.col && used.row === ref.row;
            });
        });
        return newList;
    }

    //Set the inital available location by using maxRow, maxCol from tileSetting
    setInitAvailableLocation(tileSetting: any) {
        let availableLocation = [];

        for (let row = 0; row < tileSetting.row; row++) {
            for (let col = 0; col < tileSetting.col; col++) {
                availableLocation.push({ row: row + 1, col: col + 1 })
            }
        }

        return availableLocation;
    }

    //Get available location
    //Removed the used location from the position list
    getAvailableLocation(tileSetting: any, positionList: UserPosition[], selectedDashboardId: string) {
        let availableList = [];
        availableList = this.setInitAvailableLocation(tileSetting);

        positionList.forEach(widget => {
            if (widget.siteDashboardId === selectedDashboardId) {
                availableList.forEach((available, index) => {
                    widget.reference.forEach(ref => {
                        if (ref.col === available.col && ref.row === available.row) {
                            availableList.splice(index, 1);
                        }
                    });
                });
            }
        });

        return availableList;
    }

}


