import * as _ from 'underscore'

/**
 * @ngdoc directive
 * @name amtFramework.wizard.directive:amtWizard
 * @restrict E
 *
 * @param {string=} [nextText=Next &raquo;] Text on the next button
 * @param {string=} [previousText=&laquo; Previous] Text on the previous button
 * @param {string=} [finishText=Finish] Text on the finish button
 * @param {boolean=} finishVisible Finish button visible
 * @param {expression=} finishDisabled Finish button disabled
 * @param {expression=} nextDisabled Next button disabled
 * @param {expression=} previousDisabled Previous button disabled
 * @param {expression=} control reference to control to execute methods:
 * @param {expression=} wizardTitle Title of the wizard
 * @param {boolean=} readOnlyListSteps List stepson the right clickable
 * @param {boolean=} sequential Only go through the steps in order
 * @param {callback=} onNext On next event
 * @param {callback=} onPrevious On previous event
 * @param {callback=} onFinish On finish event
 * @param {expression=} hideNextButton Boolean to show/hide the next button
 *
 * @description
 * Wizard
 *
 * @example
 * <doc:example module="amtFramework">
 *   <doc:source>
 *     <amt-wizard>
 *       <amt-wizard-step wizard-title="Step 1">
                    Hello this is step 1
                </amt-wizard-step>
                <amt-wizard-step wizard-title="Step 2">
                    Hello this is step 2
                </amt-wizard-step>
 *     </amt-wizard>
 *   </doc:source>
 * </doc:example>
 */

//import angular from 'angular';
import tmpl from "./amtWizard.html";
import './amtWizard.scss';

angular.module("amtFramework.wizard").directive("amtWizard",
    function () {
            return {
                restrict: "E",
                transclude: {
                    "belowSteps": "amtWizardBelowSteps",
                    "body": "amtWizardBody",
                    "additionalButtons": "?amtWizardAdditionalButtons"
                },
                scope: {
                    nextText: "=?",
                    previousText: "=?",
                    finishText: "=?",
                    finishVisible: "=?",
                    finishDisabled: "=?",
                    nextDisabled: "=",
                    previousDisabled: "=",
                    control: "=",
                    wizardTitle: "=?",
                    readOnlyListSteps: "@",
                    sequential: "@",
                    onNext: "&",
                    onPrevious: "&",
                    onFinish: "&",
                    ctrl: "=?",
                    hideNextButton: "=",
                    hidePreviousButton: "=",
                    startingStep: "=?"
                },
                template: tmpl,
                link: function (scope: any, element, attrs) {
                    scope.intSequential = attrs.sequential === undefined ? false : scope.$eval(attrs.sequential);
                    scope.intReadOnlyListSteps = attrs.readOnlyListSteps === undefined ? false : scope.$eval(attrs.readOnlyListSteps);
                },
                controller: [
                    "$scope", "$q", "amtXlatSvc", function ($scope, $q, xlatSvc) {
                        var steps = $scope.steps = [];

                        if (!$scope.nextText) {
                            $scope.nextText = xlatSvc.xlat("common.wzrd_next_label");
                        }

                        if (!$scope.previousText) {
                            $scope.previousText = xlatSvc.xlat("common.wzrd_previous_label");
                        }

                        if (!$scope.finishText) {
                            $scope.finishText = xlatSvc.xlat("common.wzrd_finish_label");
                        }

                        var getSelectedStep = function() {
                            var selected;
                            angular.forEach(steps, function(step) {
                                if (step.selected) {
                                    selected = step;
                                }

                            });
                            return selected;
                        };

                        var updateSequential = function() {
                            if ($scope.intSequential === true) {
                                var unClickable = false;

                                for (var i = 0; i < $scope.steps.length - 1; i++) {
                                    if (unClickable === false) {
                                        var isCurrentStepRequired = $scope.steps[i].selected === true && $scope.steps[i].stepRequired === true;
                                        unClickable = $scope.steps[i].invalid || isCurrentStepRequired;
                                    }
                                    $scope.steps[i + 1].unClickable = unClickable;
                                }
                            }
                        };

                        var next = function() {
                            var currentStep = $scope.selectedStep;
                            if (currentStep.lastStep === false) {
                                var i = steps.indexOf(currentStep);
                                while (i < steps.length && steps[i + 1].stepDisabled) {
                                    i++;
                                }
                                $scope.select(steps[i + 1]);
                            }
                            if (currentStep.stepRequired) {
                                updateSequential();
                            }
                        };

                        var previous = function() {
                            // Go back to the previous, non-disabled, step.
                            if ($scope.selectedStep.firstStep === false) {
                                var i = steps.indexOf($scope.selectedStep);
                                while (i > -1 && steps[i - 1].stepDisabled) {
                                    i--;
                                }
                                $scope.select(steps[i - 1]);
                            }
                        };

                        $scope.moveToStep = function(newStep) {
                            var step = getSelectedStep();
                            if (step.invalid) {
                                $scope.select(newStep);
                            } else {
                                $q.when($scope.onNext({ step: step })).then(function(result) {
                                    if (result === undefined || result === true) {
                                        $scope.select(newStep);
                                    }
                                });
                            }
                        };

                        $scope.intNext = function() {
                            var step = getSelectedStep();
                            $q.when($scope.onNext({ step: step })).then(function(result) {
                                if (result === undefined || result === true) {
                                    next();
                                }
                            });
                        };
                        $scope.intPrevious = function() {
                            var step = getSelectedStep();
                            $q.when($scope.onPrevious({ step: step })).then(function(result) {
                                if (result === undefined || result === true) {
                                    previous();

                                    // Otherwise, if ng-required controls are cleared, the step will incorrectly think that it's invalid.
                                    step.form.$setPristine();
                                }
                            });
                        };

                        $scope.intFinish = function() {
                            $scope.onFinish();
                        };

                        $scope.select = function (step) {
                            angular.forEach(steps, function(currentStep) {

                                if (currentStep.selected && !currentStep.invalid) {
                                    currentStep.visited = true;
                                }

                                currentStep.selected = false;

                                if (currentStep.element) {
                                    if (currentStep.element.hasClass('flex')) {
                                        currentStep.element.removeClass('flex');
                                        currentStep.element.removeClass('flex-container');
                                    }
                                    if (!currentStep.element.hasClass('flex-none')) {
                                        currentStep.element.addClass('flex-none');
                                    }
                                }
                            });
                            step.selected = true;

                            if (step.element) {
                                step.element.addClass('flex');
                                step.element.addClass('flex-container');
                                step.element.removeClass('flex-none');
                            }

                            $scope.selectedStep = step;
                            if (step.onLoad) {
                                step.onLoad();
                            }
                            $scope.wizardTitle = step.wizardTitle;
                        };

                        this.updateInvalid = function (stepId, invalid, checkPristine) {

                            if (checkPristine === undefined) {
                                checkPristine = true;
                            }

                            $scope.finishDisabled = false;

                            angular.forEach(steps, function (currentStep) {

                                $scope.finishDisabled = invalid;

                                if (currentStep.stepId === stepId.toString()) {
                                    currentStep.invalid = checkPristine ? !currentStep.form.$pristine && invalid : invalid;
                                }

                                if (currentStep.invalid) {
                                    $scope.finishDisabled = true;
                                }
                            });

                            updateSequential();
                        };

                        this.setPristine = function (stepId) {
                            angular.forEach(steps, function (currentStep) {
                                if (currentStep.stepId === stepId.toString()) {
                                    currentStep.form.$setPristine();
                                    currentStep.invalid = false;
                                }
                            });
                        };
                       
                        this.getSequential = function() {
                            return $scope.intSequential;
                        };

                        this.addstep = function (step, element) {

                            if (steps.length === 0) {
                                step.firstStep = true;
                                $scope.select(step);
                            } else {
                                step.firstStep = false;
                            }

                            for (var i = 0; i < steps.length; i++) {
                                steps[i].lastStep = false;
                            }

                            step.element = element;
                            step.lastStep = true;
                            steps.push(step);
                        };

                        $scope.$watch("startingStep", function () {
                            if ($scope.startingStep !== undefined && $scope.startingStep !== null && steps.length >= $scope.startingStep) {
                                goToStep($scope.startingStep - 1);
                            }
                        });

                        $scope.internalControl = $scope.control || {};
                        $scope.internalControl.goToStep = goToStep;
                        $scope.internalControl.updateSequential = updateSequential;
                        $scope.internalControl.restart = restart;
                        $scope.internalControl.getCurrentStep = getCurrentStep;
                        $scope.internalControl.updateInvalid = this.updateInvalid;
                        $scope.internalControl.setPristine = this.setPristine;


                        function getCurrentStep() {
                            return $scope.selectedStep !== undefined ? $scope.selectedStep.stepId : 0;
                        }

                        function goToStep(stepIndex) {
                            var i = steps.indexOf($scope.selectedStep);

                            if (i >= 0 && i <= steps.length) {
                                if (i < stepIndex) {
                                    for (i; i <= stepIndex; i++) {
                                        $scope.select(steps[i]);
                                    }
                                } else {
                                    for (i; i >= stepIndex; i--) {
                                        $scope.select(steps[i]);
                                    }
                                }
                            }

                            return getSelectedStep();
                        }

                        function restart() {
                            // "reset" all the steps
                            steps.forEach(function(step) {
                                step.visited = false;
                                step.selected = false;
                                step.invalid = false;

                                // steps use the form.$invalid property to infer thier own state, if we're resetting, it should be pristine.
                                step.form.$setPristine();
                            });

                            // Ensure the wizard is back to the first step
                            $scope.select(steps[0]);
                        }

                        if ($scope.ctrl !== undefined) {
                            $scope.ctrl.nextStep = next;
                            $scope.ctrl.previousStep = previous;
                            $scope.ctrl.goToStep = goToStep;
                            $scope.ctrl.updateInvalid = this.updateInvalid;
                        }

                        this.hideStepTitle = function() {
                            return !_.isEmpty($scope.wizardTitle) || !_.isUndefined($scope.wizardTitle) || !_.isNull($scope.wizardTitle);
                        };
                    }
                ]
            };
    }
);
