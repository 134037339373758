//import angular from 'angular';
import OcDateSvc from '../../../services/ocDateSvc';
import AdminUrls from '../adminUrls';
import tmpl from './details.html';



    angular.module('app.admin').component('productionCrewDetails', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['$scope', 'confirmSvc', 'enums', 'adminUrls', 'WindowFactory', 'ocSecuritySvc', 'amtCommandQuerySvc', 'notifySvc', 'amtXlatSvc', 'ocDateSvc', 'errorReporter',
            function ($scope, confirmSvc, enums, adminUrls: AdminUrls, WindowFactory: IWindowFactory, ocSecuritySvc, amtCommandQuerySvc, notifySvc, amtXlatSvc: IAmtXlatSvc, ocDateSvc: OcDateSvc, errorReporter) {

                var vm = this;
                $scope.vm = this;

                vm.urls = adminUrls;

                // pass along dirty flag to the window for use on closing minimised windows
                $scope.$watch('vm.form.$dirty', function () {
                    vm.wnd.isDirty = vm.form.$dirty;
                });

                this.$onInit = function () {

                    if (vm.wnd !== null) {
                        vm.wnd.onClose = vm.cancel;
                    }

                    vm.data = angular.copy(vm.initParams);

                    if (!vm.data.activeFrom) {
                        vm.data.activeFrom = new Date(); // default new production crew active from to now
                    }

                    vm.readonly = (vm.data.id && !ocSecuritySvc.isAuthorised('Security.Settings.ProductionCrews', 'readWrite'));

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: "common.save_label",
                            name: "saveButton",
                            click: "save",
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: !vm.readonly,
                            disabled: true
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.save
                    };
                }

                vm.save = function () {

                    // @ts-ignore
                    // active from cannot be in future
                    if (new Date(vm.data.activeFrom) > Date.now()) {
                        notifySvc.error(amtXlatSvc.xlat("productionCrews.activeFromCannotBeInFuture"));
                        return;
                    }

                    // active from must be before active to
                    if (vm.data.activeTo) {
                        if (new Date(vm.data.activeFrom) >= new Date(vm.data.activeTo)) {
                            notifySvc.error(amtXlatSvc.xlat("productionCrews.activeToBeforeActiveFrom"));
                            return;
                        }
                    }

                    var criteria = {
                        id: vm.data.id,
                        name: vm.data.name,
                        description: vm.data.description,
                        activeFrom: ocDateSvc.removeLocalTimeZoneOffset(vm.data.activeFrom),
                        activeTo: (vm.data.activeTo ? ocDateSvc.removeLocalTimeZoneOffset(vm.data.activeTo) : null)
                    };

                    vm.wnd.processing = true;

                    return amtCommandQuerySvc.post((criteria.id ? vm.urls.modifyProductionCrew : vm.urls.addProductionCrew), criteria).then(function (response) {

                        notifySvc.success(amtXlatSvc.xlat('productionCrews.savedSuccessfully'));

                        if (response === true) {
                            notifySvc.info(amtXlatSvc.xlat('productionCrews.noActiveCrewsAtSite'));
                        }

                        if (vm.wnd.onDataChanged) {
                            vm.wnd.onDataChanged();
                        }

                        vm.closeWindow();

                    }).catch(function (error) {
                        errorReporter.logError(error);
                    }).finally(function () {
                        vm.wnd.processing = false;
                    });
                };

                vm.closeWindow = function () {
                    WindowFactory.closeWindow(vm.wnd);
                };

                vm.cancel = function () {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        vm.form.dirty = false;
                        vm.closeWindow();
                        return true;
                    });

                    return false;
                };

                $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing'], function () {
                    vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.form.$pristine || vm.wnd.processing;
                });
            }
        ]
    });
