// @ts-check

/**
 * @ngdoc directive
 * @name amtFramework.wizard.directive:amtWizardStep
 * @restrict E
 * @property {string} wizardTitle Title of the step
 * @property {string} stepId Id of the step
 * @property {expression} [stepDisabled=false] Enable/disable the step
 * @property {boolean} [stepVisible=true] Show/hide the step in the step list
 *
 * @requires amtFramework.wizard.directive:amtWizard
 *
 * @description
 * Step of a wizard
 */

//import angular from 'angular';
import tmpl from "./amtWizardStep.html";


angular.module("amtFramework.wizard").directive("amtWizardStep",
    function () {
        return {
            require: ["^amtWizard"],
            restrict: "E",
            transclude: true,
            scope: {
                wizardTitle: "@",
                stepId: "@",
                stepDisabled: "=?",
                stepVisible: "@"
            },
            template: tmpl,
            /**
             * This is a dirty hack to get around the ts-check enforcing strong types and the fact that we can't declare
             * interfaces in ECMAScript 5. A better solution would be something like this; https://stackoverflow.com/a/29667319/1222199
             * @param {any} scope 
             */
            link: function(scope: any, element, attrs, controllers) {
                var wizardCtrl = controllers[0];

                if (scope.stepVisible == null) {
                    scope.stepVisible = true;
                }

                if (scope.stepDisabled == null) {
                    scope.stepDisabled = false;
                }

                function checkValid() {
                    scope.invalid = (scope.form && scope.form.$invalid);
                    scope.nextDisabled = scope.invalid;
                    wizardCtrl.updateInvalid(scope.stepId, scope.invalid);
                }

                function checkDirty(isDirty) {
                    if (isDirty) {
                        scope.nextDisabled = scope.form.$invalid;
                    }
                }

                scope.$watch("form.$invalid", checkValid);
                scope.$watch("form.$dirty", checkDirty);

                wizardCtrl.addstep(scope, element);
            }
        };
    }
);
