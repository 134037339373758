/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var events_outside_angular_directive_1 = require("./events-outside-angular.directive");
/**
 * @hidden
 */
var EventsModule = /** @class */ (function () {
    function EventsModule() {
    }
    EventsModule = tslib_1.__decorate([
        core_1.NgModule({
            declarations: [events_outside_angular_directive_1.EventsOutsideAngularDirective],
            exports: [events_outside_angular_directive_1.EventsOutsideAngularDirective]
        })
    ], EventsModule);
    return EventsModule;
}());
exports.EventsModule = EventsModule;
