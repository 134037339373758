//import angular from 'angular';
import { AuthService } from './auth.service';
import BrowserSvc from './browserSvc';
import OcConfigSvc from './ocConfigSvc';



angular.module("app").factory("authInterceptor", [
    '$q', '$injector', '$location', 'ocConfigSvc', 'browserSvc', 'authService', 'notifySvc', 'amtXlatSvc',
    function ($q: ng.IQService, $injector: ng.auto.IInjectorService, $location: ng.ILocationService, ocConfigSvc: OcConfigSvc, browserSvc: BrowserSvc,
        authService: AuthService, notifySvc, amtXlatSvc: IAmtXlatSvc) {
        var vm = this;
        return {
            request: function (config) {
                config.headers = config.headers || {};

                // add bearer token to every request (if we have one)
                const bearer = authService.bearer;
                if (bearer)
                    config.headers.Authorization = bearer;

                if (ocConfigSvc.user) {
                    config.headers.OcClientId = ocConfigSvc.user.client.id;
                    config.headers.OcSiteId = ocConfigSvc.user.site.id;
                }

                return config;
            },
            response: function (response) {
                if (response.status === 401) {
                    console.error('401');
                }
                vm.retry = false;
                return response;
            },
            responseError: function (rejection) {
                console.warn('Authintercept Rejection (' + rejection.config.url + ')');
                if ((rejection.status === 401) && !vm.retry) {
                    var dataBroker = <IDataBroker>$injector.get('dataBroker');
                    if (browserSvc.isMobile) {
                        console.log("Mobile device");
                        // at this point see if I can get the credentials and log them on
                        var authData = authService.credentials;
                        if (authData?.password) {
                            console.log("Have cached credentials - sending them.");
                            vm.retry = true;
                            // log then in again to the correct site
                            return dataBroker.login(authData.username, authData.password, true, ocConfigSvc.currentSiteId()).then(function (response) {
                                vm.retry = true;
                                var $http = $injector.get('$http');
                                return $http(rejection.config);
                            }).catch(function (error) {
                                //Just return to the login screen here
                                notifySvc.error(amtXlatSvc.xlat("login.ReauthenticationRequired"));
                                dataBroker.logout();
                                return $q.reject(rejection);
                            });
                        }
                        else {
                            console.log("Could not locate cached credentials - not sending them.");
                        }
                    }
                    let url: string = rejection.config.url || '';
                    if (!(url.endsWith('error/recordErrors') || url.endsWith('lkjh=65FFdd'))) {
                        dataBroker.sessionExpire('login.AuthenticationRequired');
                    }
                }
                vm.retry = false;
                return $q.reject(rejection);
            }
        };
    }
]);
