//import angular from 'angular';


angular.module("app").factory("notificationUrls", function () {
    var apiPath = baseUrl + 'api/';
        var controller = 'admin/notifications/';
            
        return {
            //search
            listAlerts: apiPath + controller + 'listAlerts',
            listNotifications: apiPath + controller + 'listNotifications',

            // export
            exportAlerts: controller + 'exportAlerts',
            exportNotifications: controller + 'exportNotifications',

            // dismiss
            dismissAllNotifications: controller + 'dismissAllNotifications',                    
            dismissNotification: controller + 'dismissNotification',

            // toast
            acknowledgeToast: controller + 'acknowledgeToast'
        };
    });
