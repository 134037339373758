//import angular from 'angular';
import BrowserSvc from '../../../services/browserSvc';
import OcDateSvc from '../../../services/ocDateSvc';
import AdminUrls from '../../admin/adminUrls';
import tmpl from './userDetails.html';



angular.module('app.admin').component('userDetails', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: [
            '$scope', 'amtCommandQuerySvc', 'enums', 'amtXlatSvc', 'errorReporter', 'adminUrls', 'confirmSvc', 'WindowFactory',
            'tabDirtyTrackingSvc', 'ocSecuritySvc', 'ocConfigSvc', 'notifySvc', 'browserSvc', 'exportSvc', 'ocDateSvc',
            function (
                $scope, amtCommandQuerySvc, enums, amtXlatSvc: IAmtXlatSvc, errorReporter, adminUrls: AdminUrls, confirmSvc, WindowFactory: IWindowFactory, tabDirtyTrackingSvc,
                ocSecuritySvc, ocConfigSvc, notifySvc, browserSvc: BrowserSvc, exportSvc, ocDateSvc: OcDateSvc
            ) {
                var vm = this;

                $scope.vm = this;

                vm.config = ocConfigSvc;
                $scope.adminTab = {};
                vm.isMobile = browserSvc.isMobile;
                vm.status = enums.screenStatus.loading;
                vm.closeOnSave = false;
                vm.form = {};
                vm.user = {
                    currentStatus: "Active (Has Login)"
                };
                vm.RolesGrid = {};
                vm.subscriptionsGrid = {};
                vm.apiUrls = adminUrls;
                vm.maxUserCommentLength = 500;
                vm.onKeyUp = keyUp;
                vm.resetUI = resetUI;
                vm.onSave = save;
                vm.revalidateUser = revalidateUser;
                vm.getUserDetails = getUserDetails;
                vm.loadNewUserDefaults = loadNewUserDefaults;

                vm.tabs = {
                    roles: {
                        onRefresh: function () {
                            if (vm.RolesGrid) {
                                vm.RolesGrid.resize();
                            }
                        }
                    }
                };

                this.$onInit = function () {

                    vm.isNewUser = vm.initParams.mode === 'new';

                    vm.userid = vm.initParams.personId;
                    vm.currentUserId = ocConfigSvc.user.personId;

                    // security
                    vm.canAddUser = ocSecuritySvc.isAuthorised('Security.Settings.Users.Add', 'readWrite');
                    vm.canDeleteRoles = ocSecuritySvc.isAuthorised('Security.Settings.Users.Roles.Delete', 'readWrite');
                    vm.canResetUI = ocSecuritySvc.isAuthorised('Security.Settings.Users.ResetUI', 'readWrite');
                    vm.canUnsubscribeSubscriptions = (vm.getPersonId() === vm.currentUserId) || ocSecuritySvc.isAuthorised('Security.Settings.Users.Subscriptions', 'readWrite');
                    vm.canSetInternalUserFlag = ocSecuritySvc.isAuthorised('Security.Settings.Users.SetInternal', 'readWrite');

                    vm.readOnlyAdmin = !((ocSecuritySvc.isAuthorised('Security.Settings.Users.Admin', 'readWrite') && !vm.isNewUser) || (vm.isNewUser && vm.canAddUser));
                    vm.readOnlyRoles = !((ocSecuritySvc.isAuthorised('Security.Settings.Users.Roles', 'readWrite') && !vm.isNewUser) || (vm.isNewUser && vm.canAddUser));

                    vm.userRolesCriteria = {
                        filterValues: {
                            personId: vm.getPersonId(),
                            pageSize: 20
                        }
                    };

                    vm.userSubscriptionsCriteria = vm.userRolesCriteria;

                    vm.wnd.onClose = $scope.onClose;

                    vm.buttonStates = {
                        saveButton: {
                            visible: true,
                            disabled: true
                        },
                        addRoleButton: {
                            visible: true,
                            disabled: false
                        },
                        copyRolesButton: {
                            visible: true,
                            disabled: false
                        },
                        exportRolesButton: {
                            visible: true,
                            disabled: false
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.onSave,
                        addRole: vm.addRole,
                        copyRoles: vm.copyRoles,
                        exportRoles: vm.exportRoles
                    };

                    if (vm.isNewUser) {
                        vm.loadNewUserDefaults();
                    } else {
                        vm.getUserDetails();
                    }

                    vm.setActiveTab(vm.initParams.section);
                };

                $scope.$watch('vm.form.$dirty', function (newValue) {
                    vm.wnd.isDirty = newValue;
                });

                function closeWindow() {
                    WindowFactory.closeWindow(vm.wnd);
                }

                $scope.onClose = function () {
                    confirmSvc.confirmSaveChange(vm.forms && ((vm.forms.details && vm.forms.details.$dirty) || (vm.forms.settings && vm.forms.settings.$dirty))).then(function () {
                        closeWindow();
                        return true;
                    });

                    return false;
                };

                vm.setActiveTab = function (tabName) {
                    if (!tabName) {
                        throw new Error("setting active tab to undefined tab");
                    }
                    tabName = tabName.toLowerCase();
                    vm.activeTab = tabName;

                    if (vm.tabs[vm.activeTab] && vm.tabs[vm.activeTab].onRefresh) {
                        vm.tabs[vm.activeTab].onRefresh();
                    }
                };

                vm.selectTab = function (tabName) {
                    vm.setActiveTab(tabName);
                };

                vm.getPersonId = function () {
                    return vm.userid !== undefined ? vm.userid : vm.user.personId;
                }

                function getUserDetails() {

                    vm.wnd.processing = true;

                    var url = String.format(vm.apiUrls.getUserDetails, vm.userid);

                    amtCommandQuerySvc.get(url).then(function (response) {

                        vm.user = response.data;

                        if (response.data.activeTo != null) {
                            let utcDate: Date = response.data.activeTo;
                            response.data.activeTo = ocDateSvc.translatedFromUTCToLocalStartofDay(utcDate);
                        }

                        vm.user.currentlyHasOtracomLogin = vm.user.hasOtracomLogin;

                        if (response.data.defaultLanguage) {
                            vm.user.defaultLanguageId = response.data.defaultLanguage.key;
                        }

                        // clear the password fields
                        vm.user.password = "";
                        vm.user.newPassword = "";

                        if (response.data.yubikey === null) {
                            vm.user.yubikeyId = undefined;
                        } else {
                            vm.user.yubikeyId = response.data.yubikey ? response.data.yubikey.key : "00000000-0000-0000-0000-000000000000";
                        }

                        vm.user.needRevalidate = vm.user.currentStatus.needsRevalidation;
                        vm.status = enums.screenStatus.ready;
                        vm.lastUser = angular.copy(vm.user);
                    }).catch(function (error) {
                        if (error.status === -1) {
                            WindowFactory.alert('exception.ActionOfflineHeader', ['common.ok_label'], 'exception.ActionOffline').then(function () {
                                closeWindow();
                            })
                        }
                        else {
                            vm.status = 'error';
                            errorReporter.logError(error, "UserDetails-GetUserDetails");
                        }
                    }).finally(function () {
                        vm.wnd.processing = false;
                    });
                }

                function loadNewUserDefaults() {

                    vm.wnd.processing = true;
                    vm.user.activeFrom = new Date();

                    amtCommandQuerySvc.get(vm.apiUrls.getNewUserDefaultLanguage)
                        .then(function (response) {

                            if (response) {
                                vm.user.defaultLanguage = response.data;
                                vm.user.defaultLanguageId = vm.user.defaultLanguage.key;
                            }

                            // fight the auto - complete

                            // clear the username field
                            vm.user.username = "";

                            // clear the password fields 
                            vm.user.password = "";
                            vm.user.newPassword = "";

                            vm.status = 'ready';
                            vm.lastUser = angular.copy(vm.user);
                        }).finally(function () {
                            vm.wnd.processing = false;
                        });
                }

                $scope.removerole = function (dataItem) {

                    vm.wnd.processing = true;

                    var item = {
                        personId: vm.getPersonId(),
                        personRoleId: dataItem.personRoleId,
                        equipmentTypeId: dataItem.componentTypeId
                    };

                    amtCommandQuerySvc.deleteItem(vm.apiUrls.deleteUserRole, item).then(function (response) {

                        if (response.data === 'removeUserRoles_dundasFail') {
                            notifySvc.error(amtXlatSvc.xlat("userSecurity.removeRole_dundasFail", response.errors[0].message));
                        } else {
                            notifySvc.success(amtXlatSvc.xlat("userSecurity.userRoleRemovedSuccess"));
                        }

                        vm.RolesGrid.refresh();

                    }).catch(function (error) {
                        errorReporter.logError(error);
                    }).finally(function () {
                        vm.wnd.processing = false;
                    });
                };

                $scope.editRole = function (item) {

                    vm.wnd.processing = true;

                    var roleCriteria = {
                        filterValues: vm.userRolesCriteria.filterValues,
                        personRoleId: item.personRoleId
                    };

                    amtCommandQuerySvc.post(vm.apiUrls.getUserRole, roleCriteria).then(function (response) {

                        WindowFactory.openItem({
                            component: 'edit-role-popup',
                            caption: vm.user.firstName + ' ' + vm.user.lastName + ' - ' + amtXlatSvc.xlat('userSecurity.editRoleTitle'),
                            initParams: {
                                item: response,
                                personname: vm.user.firstName + ' ' + vm.user.lastName
                            },
                            modal: true,
                            canClose: false,
                            width: 650,
                            parentWindow: vm.wnd,
                            onDataChangeHandler: function () {
                                // Ensure the criteria is up to date or the refresh might not work
                                vm.userRolesCriteria = {
                                    filterValues: {
                                        personId: vm.getPersonId(),
                                        pageSize: 20
                                    }
                                };

                                vm.RolesGrid.refresh();
                            }
                        });

                    }, amtCommandQuerySvc.handleError).finally(function () {
                        vm.wnd.processing = false;
                    });
                };

                vm.getSearchCriteria = function () {
                    return {
                        filterValues: {
                            personId: vm.getPersonId()
                        }
                    };
                }

                vm.exportRoles = function () {
                    var fileName = amtXlatSvc.xlat('userSecurity.rolesExportFilename', vm.user.firstName, vm.user.lastName);

                    exportSvc.exportData(vm.apiUrls.exportUserRoles, vm.getSearchCriteria(), fileName);
                };

                vm.copyRoles = function () {
                    WindowFactory.openItem({
                        component: 'copy-roles-from-user',
                        caption: amtXlatSvc.xlat("userSecurity.copyRolesFrom"),
                        initParams: {
                            personId: vm.user.personId
                        },
                        modal: true,
                        width: 600,
                        parentWindow: vm.wnd,
                        onDataChangeHandler: function () {
                            // Ensure the criteria is up to date or the refresh might not work
                            vm.userRolesCriteria = {
                                filterValues: {
                                    personId: vm.getPersonId(),
                                    pageSize: 20
                                }
                            };

                            vm.RolesGrid.refresh();
                        }
                    });
                };

                vm.addRole = function () {
                    WindowFactory.openItem({
                        component: 'add-role-popup',
                        caption: vm.user.firstName + " " + vm.user.lastName + " - " + amtXlatSvc.xlat("userSecurity.addRoleLabel"),
                        initParams: {
                            user: vm.user
                        },
                        modal: true,
                        width: 650,
                        canClose: false,
                        parentWindow: vm.wnd,
                        onDataChangeHandler: function () {
                            // Ensure the criteria is up to date or the refresh might not work
                            vm.userRolesCriteria = {
                                filterValues: {
                                    personId: vm.getPersonId(),
                                    pageSize: 20
                                }
                            };

                            vm.RolesGrid.refresh();
                        }
                    });
                };

                // subscriptions
                $scope.unsubscribeFromAlert = function (dataItem) {

                    confirmSvc.confirmMessage("common.editPanelConfirmation_title", "common.unsubscribeConfirmation", dataItem.alertConfiguration)
                        .then(function () {

                            vm.wnd.processing = true;

                            var cmd = {
                                alertConfigurationPersonId: dataItem.id
                            };

                            amtCommandQuerySvc.post(vm.apiUrls.deleteAlertSubscription, cmd).then(function (response) {
                                notifySvc.success(amtXlatSvc.xlat("common.unsubscribedSuccessfully"));
                                vm.subscriptionsGrid.refresh();
                            }).catch(function (error) {
                                errorReporter.logError(error);
                            }).finally(function () {
                                vm.wnd.processing = false;
                            });
                        });
                };

                function save() {

                    if (vm.user.password !== '' && vm.user.repeatPassword !== vm.user.password) {
                        notifySvc.error(amtXlatSvc.xlat("common.passwordsDontMatch"));
                        return;
                    }

                    if ($scope.adminTab.$invalid) {
                        if ($scope.adminTab.$error.required.length > 0) {
                            notifySvc.error(amtXlatSvc.xlat("common.validationRequired_genericMessage"));
                        }
                        return;
                    }

                    vm.wnd.processing = true;

                    var promise;

                    if (vm.isNewUser) {
                        promise = amtCommandQuerySvc.post(vm.apiUrls.createNewUser, vm.user);
                    } else {
                        promise = amtCommandQuerySvc.put(vm.apiUrls.updateUserDetails, vm.user);
                    }

                    promise.then(function (response) {

                        vm.user.password = undefined;
                        vm.user.repeatPassword = undefined;
                        vm.user.currentPassword = undefined;

                        // set the form as clean
                        vm.form.$setPristine();

                        if ($scope.$parent.onDataChanged) {
                            $scope.$parent.onDataChanged();
                        }

                        // throw up the success toast
                        notifySvc.success(amtXlatSvc.xlat("userSecurity.saveSuccessful"));

                        // keep the form open if it's a new user, or if they have elected to keep it open.
                        if (vm.closeOnSave === true && vm.isNewUser === false) {
                            // close on save
                            closeWindow();
                        } else {
                            if (vm.isNewUser) {
                                // get the personId
                                vm.user.personId = response.data;
                            }

                            // keep the form open, and update the caption
                            vm.initParams.mode = 'open';
                            vm.wnd.title = amtXlatSvc.xlat("userSecurity." + vm.wnd.initParams.mode, vm.user.name);
                            vm.isNewUser = false;

                            vm.lastUser = angular.copy(vm.user);
                        }

                    }, amtCommandQuerySvc.handleError).finally(function () {
                        vm.wnd.processing = false;
                    });
                }

                function revalidateUser() {

                    var model = {
                        personId: vm.user.personId,
                        revalidate: vm.user.needRevalidate
                    };

                    vm.wnd.processing = true;

                    amtCommandQuerySvc.put(vm.apiUrls.revalidateUser, model).then(function (response) {

                        vm.user.activeTo = null;
                        vm.user.LastLoginDate = new Date();

                        return amtCommandQuerySvc.get(String.format(vm.apiUrls.getUserActiveStatus, vm.userid)).then(
                            function (response) {

                                if (response.data) {
                                    vm.user.currentStatus = response.data;
                                    vm.user.needRevalidate = response.data.needsRevalidation;

                                    if (vm.wnd.onDataChanged) {
                                        vm.wnd.onDataChanged();
                                    }
                                    notifySvc.success(amtXlatSvc.xlat("userSecurity.userStatusChangeSuccessful"));
                                }
                            }).catch(function (error) {
                                if (error.status === -1) {
                                    WindowFactory
                                        .alert('exception.ActionOfflineHeader',
                                            ['common.ok_label'],
                                            'exception.ActionOffline').then(function () {
                                                closeWindow();
                                            });
                                }

                                errorReporter.logError(error);
                            });

                    }).catch(function (error) {
                        errorReporter.logError(error);
                    }).finally(function () {
                        vm.wnd.processing = false;
                    });
                }

                function keyUp() {
                    tabDirtyTrackingSvc.setTabDirty();
                }

                function resetUI() {
                    localStorage.clear();
                }

                $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing'], function () {
                    vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.form.$pristine || vm.wnd.processing;
                });

                $scope.$watch('vm.activeTab', function () {
                    switch (vm.activeTab) {
                        case 'admin':

                            vm.buttons = [
                                {
                                    primary: true,
                                    cancel: false,
                                    value: 'common.save_label',
                                    name: 'saveButton',
                                    click: 'save',
                                    type: enums.buttonTypes.button
                                }
                            ];

                            vm.buttonStates.saveButton.visible = (!vm.isNewUser && ocSecuritySvc.isAuthorised('Security.Settings.Users.Admin', 'readWrite')) ||
                                (vm.isNewUser && ocSecuritySvc.isAuthorised('Security.Settings.Users.Add', 'readWrite'));

                            break;
                        case 'usersettings':
                            vm.buttons = [
                                {
                                    primary: true,
                                    cancel: false,
                                    value: 'common.save_label',
                                    name: 'saveButton',
                                    click: 'save',
                                    type: enums.buttonTypes.button
                                }
                            ];

                            vm.buttonStates.saveButton.visible = true;

                            break;
                        case 'roles':
                            vm.buttons = [
                                {
                                    primary: false,
                                    cancel: false,
                                    value: 'userSecurity.copyRoles',
                                    title: 'userSecurity.copyRoles',
                                    name: 'copyRolesButton',
                                    click: 'copyRoles',
                                    type: enums.buttonTypes.action
                                },
                                {
                                    primary: false,
                                    cancel: false,
                                    value: 'userSecurity.exportRoles',
                                    title: 'userSecurity.exportRoles',
                                    name: 'exportRolesButton',
                                    click: 'exportRoles',
                                    type: enums.buttonTypes.action
                                },
                                {
                                    primary: true,
                                    cancel: false,
                                    value: 'userSecurity.addRoleLabel',
                                    name: 'addRoleButton',
                                    click: 'addRole',
                                    type: enums.buttonTypes.button
                                }
                            ];
                            vm.buttonStates.addRoleButton.visible = ocSecuritySvc.isAuthorised('Security.Settings.Users.Roles.Add', 'readWrite');
                            vm.buttonStates.exportRolesButton.visible = ocSecuritySvc.isAuthorised('Security.Settings.Users.Roles.Export', 'readOnly');
                            vm.buttonStates.copyRolesButton.visible = ocSecuritySvc.isAuthorised('Security.Settings.Users.Roles.Copy', 'readWrite');
                            break;

                        case 'subscriptions':
                            vm.buttons = [];
                            break;

                        default:
                    }
                });
            }
        ]
    });
