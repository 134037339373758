    // <oc-data-sync-button> Contains all the logic to ensure the progress bar reflects the age of the data.
//import angular from 'angular';
import OcDateSvc from '../../../services/ocDateSvc';


    angular.module('app.directives').directive("ocDataSyncButton", ['$compile', 'amtXlatSvc', '$filter', 'ocDateSvc', function ($compile, xlatSvc, $filter, ocDateSvc: OcDateSvc) {


        return {
            restrict: 'E',
            replace: true,
            scope: {
                type: "@",
                notification: "=",
                active: "=",
                minDateRecorded: "=",
                message: "=",
                clickEnabled: "=",
                lastTransferred: "=",
                startProcess: "&",
                progressPct: "=",
                progressText: "=",
                progressSubText: "="
            },
            transclude: false,
            template: '<div>' +
                            '<div class="progress-button-data-sync" ng-class="{\'disabled\': clickEnabled === false}" ng-click="startProcess()">' +
                                '<div id="progress" ng-style="{width: progressPct + \'%\'}" class="progress-bar" ng-class="generatedClass" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">' +
                                    '<span id="progress-text" class="show">{{progressText}}</span>' +         
                                '</div>' +
                            '</div></p>' +
                            '<div class="progress-button-data-sync-msg" ng-show=\'!progressSubText\'>{{message}}</div>' + 
                            '<div class="progress-button-data-sync-msg" ng-show=\'progressSubText\'>{{progressSubText}} {{progressPct | number : 2}}%</div>' +
                      '</div>' ,

            link: function (scope : any, element, attrs, controller) {                
                scope.intType = (scope.type === 'download') ? "download" : "upload";
                scope.intStates = scope.states[scope.intType];
                scope.generatedClass = "";
                
                scope.$watch('lastTransferred', function (value) {
                    let dateMs = Date.parse(value);

                    if (dateMs)
                    {
                        scope.age = ocDateSvc.getDaysDiffLegacy(new Date(dateMs));
                    }
                    else
                    {
                        scope.age = -1;
                    }

                    scope.generatedClass = "";
                    scope.progressPct = 0;
                    for (var s = 0; s < scope.intStates.length; s++) {
                        if (scope.age >= scope.intStates[s].min) {
                            scope.generatedClass = scope.intStates[s].className;
                            scope.progressPct = 100;
                        }
                    }
                });
            },
            controller: ["$scope", function ($scope) {
                // States if directive. Use scope type to filter
                $scope.states =
                    {
                        "download": [
                            { min: -10, className: 'module-status-old' }, //missing
                            { min: 0, className: 'module-status-ready' }, // ZERO
                            { min: 0.0416, className: 'module-status-data' }, //1 hour to 1 day
                            { min: 1, className: 'module-status-warning' }, // Less than 2 days
                            { min: 2, className: 'module-status-old' } // Greater than 2 days
                        ],

                        "upload": [
                            { min: 0, className: 'module-status-data' }, //Less than 1 day
                            { min: 1, className: 'module-status-warning' }, // Less than 2 days
                            { min: 2, className: 'module-status-old' } // Greater than 2 days
                        ]
                    };
            }]
        };
    }]);
