/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Represents the virtual scrolling cell template of the Grid while loading new data. Provides an option to customize the
 * appearance of the indicator that is displayed while new data is loading. To define the loading cell template,
 * nest an `<ng-template>` tag with the `kendoGridCellLoadingTemplate` directive inside `<kendo-grid>` ([see example]({% slug virtualscrolling_grid %}#toc-custom-loading-skeleton)).
 *
 * The template context is set to the current column, and utilizes the `let-x` syntax&mdash;for example, `let-column`.
 *
 * @example
 * ```ts-no-run
 *       <kendo-grid [data]="data" scrollable="virtual" [rowHeight]="36">
 *         <ng-template kendoGridCellLoadingTemplate let-column>
 *             ...
 *         </ng-template>
 *       </kendo-grid>
 * ```
 */
var CellLoadingTemplateDirective = /** @class */ (function () {
    function CellLoadingTemplateDirective(templateRef) {
        this.templateRef = templateRef;
    }
    CellLoadingTemplateDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoGridCellLoadingTemplate]'
        }),
        tslib_1.__param(0, core_1.Optional()),
        tslib_1.__metadata("design:paramtypes", [core_1.TemplateRef])
    ], CellLoadingTemplateDirective);
    return CellLoadingTemplateDirective;
}());
exports.CellLoadingTemplateDirective = CellLoadingTemplateDirective;
