/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var kendo_licensing_1 = require("@progress/kendo-licensing");
var operators_1 = require("rxjs/operators");
var colorpalette_localization_service_1 = require("./localization/colorpalette-localization.service");
var package_metadata_1 = require("../package-metadata");
var models_1 = require("./models");
var utils_1 = require("./utils");
var utils_2 = require("../common/utils");
var color_palette_service_1 = require("./services/color-palette.service");
var DEFAULT_TILE_SIZE = 24;
var DEFAULT_COLUMNS_COUNT = 10;
var DEFAULT_PRESET = 'office';
var DEFAULT_ACCESSIBLE_PRESET = 'accessible';
var serial = 0;
/**
 * The ColorPalette component provides a set of predefined palette presets and enables you to implement a custom color palette.
 * The ColorPalette is independently used by `kendo-colorpicker` and can be directly added to the page.
 */
var ColorPaletteComponent = /** @class */ (function () {
    function ColorPaletteComponent(host, service, cdr, renderer, localizationService, ngZone) {
        var _this = this;
        this.host = host;
        this.service = service;
        this.cdr = cdr;
        this.renderer = renderer;
        this.localizationService = localizationService;
        this.ngZone = ngZone;
        /**
         * @hidden
         */
        this.id = "k-colorpalette-" + serial++;
        /**
         * Specifies the output format of the ColorPaletteComponent.
         * The input value may be in a different format. However, it will be parsed into the output `format`
         * after the component processes it.
         *
         * The supported values are:
         * * (Default) `hex`
         * * `rgba`
         * * `name`
         */
        this.format = 'hex';
        /**
         * Sets the disabled state of the ColorPalette.
         */
        this.disabled = false;
        /**
         * Sets the read-only state of the ColorPalette.
         */
        this.readonly = false;
        /**
         * Specifies the size of a color cell.
         *
         * The possible values are:
         * * (Default) `tileSize = 24`
         * * `{ width: number, height: number }`
         */
        this.tileSize = { width: DEFAULT_TILE_SIZE, height: DEFAULT_TILE_SIZE };
        /**
         * Fires each time the color selection is changed.
         */
        this.selectionChange = new core_1.EventEmitter();
        /**
         * Fires each time the value is changed.
         */
        this.valueChange = new core_1.EventEmitter();
        /**
         * Fires each time the user selects a cell with the mouse or presses `Enter`.
         *
         * @hidden
         */
        this.cellSelection = new core_1.EventEmitter();
        /**
         * @hidden
         */
        this.hostClasses = true;
        /**
         * @hidden
         */
        this.uniqueId = kendo_angular_common_1.guid();
        this._tabindex = 0;
        this.notifyNgTouched = function () { };
        this.notifyNgChanged = function () { };
        kendo_licensing_1.validatePackage(package_metadata_1.packageMetadata);
        this.dynamicRTLSubscription = localizationService.changes.subscribe(function (_a) {
            var rtl = _a.rtl;
            _this.direction = rtl ? 'rtl' : 'ltr';
        });
    }
    ColorPaletteComponent_1 = ColorPaletteComponent;
    Object.defineProperty(ColorPaletteComponent.prototype, "paletteId", {
        /**
         * @hidden
         */
        get: function () {
            return this.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorPaletteComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        /**
         * Specifies the value of the initially selected color.
         */
        set: function (value) {
            this._value = utils_1.parseColor(value, this.format);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorPaletteComponent.prototype, "columns", {
        get: function () {
            return this._columns;
        },
        /**
         * Specifies the number of columns that will be displayed.
         * Defaults to `10`.
         */
        set: function (value) {
            var minColumnsCount = 1;
            this._columns = value > minColumnsCount ? value : minColumnsCount;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorPaletteComponent.prototype, "palette", {
        get: function () {
            return this._palette;
        },
        /**
         * The color palette that will be displayed.
         *
         * The supported values are:
         * * The name of the predefined palette preset (for example, `office`, `basic`, and `apex`).
         * * A string with comma-separated colors.
         * * A string array.
         */
        set: function (value) {
            var _this = this;
            if (!utils_2.isPresent(value)) {
                value = DEFAULT_PRESET;
            }
            if (typeof value === 'string' && utils_2.isPresent(models_1.PALETTEPRESETS[value])) {
                this.columns = this.columns || models_1.PALETTEPRESETS[value].columns;
                value = models_1.PALETTEPRESETS[value].colors;
            }
            var colors = (typeof value === 'string') ? value.split(',') : value;
            this._palette = colors.map(function (color) { return utils_1.parseColor(color, _this.format, false, false); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorPaletteComponent.prototype, "tabindex", {
        get: function () {
            return !this.disabled ? this._tabindex : undefined;
        },
        /**
         * Specifies the [tabindex](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex) of the component.
         */
        set: function (value) {
            var tabindex = Number(value);
            var defaultValue = 0;
            this._tabindex = !isNaN(tabindex) ? tabindex : defaultValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorPaletteComponent.prototype, "tileLayout", {
        /**
         * @hidden
         */
        get: function () {
            if (typeof this.tileSize !== 'number') {
                return this.tileSize;
            }
            return { width: this.tileSize, height: this.tileSize };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorPaletteComponent.prototype, "colorRows", {
        /**
         * @hidden
         */
        get: function () {
            return this.service.colorRows;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorPaletteComponent.prototype, "hostTabindex", {
        /**
         * @hidden
         */
        get: function () { return this.tabindex; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorPaletteComponent.prototype, "disabledClass", {
        /**
         * @hidden
         */
        get: function () { return this.disabled; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorPaletteComponent.prototype, "readonlyAttribute", {
        /**
         * @hidden
         */
        get: function () { return this.readonly; },
        enumerable: true,
        configurable: true
    });
    ColorPaletteComponent.prototype.ngOnInit = function () {
        if (this.colorRows.length === 0) {
            var defaultPreset = (this.format !== 'name') ? DEFAULT_PRESET : DEFAULT_ACCESSIBLE_PRESET;
            this.palette = this.palette || defaultPreset;
            this.setRows();
        }
    };
    ColorPaletteComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.setHostElementAriaLabel();
        if (this.value) {
            this.ngZone.onStable.pipe(operators_1.take(1)).subscribe(function () {
                _this.selectCell(_this.value);
            });
        }
    };
    ColorPaletteComponent.prototype.ngOnDestroy = function () {
        if (this.dynamicRTLSubscription) {
            this.dynamicRTLSubscription.unsubscribe();
        }
    };
    ColorPaletteComponent.prototype.ngOnChanges = function (changes) {
        if (changes.palette || changes.columns) {
            this.setRows();
        }
        if (changes.palette || changes.value || changes.columns) {
            this.selectCell(this.value);
            this.setHostElementAriaLabel();
        }
    };
    /**
     * @hidden
     */
    ColorPaletteComponent.prototype.handleKeydown = function (event) {
        var isRTL = this.direction === 'rtl';
        switch (event.keyCode) {
            case kendo_angular_common_1.Keys.ArrowDown:
                this.handleCellNavigation(0, 1);
                break;
            case kendo_angular_common_1.Keys.ArrowUp:
                this.handleCellNavigation(0, -1);
                break;
            case kendo_angular_common_1.Keys.ArrowRight:
                this.handleCellNavigation(isRTL ? -1 : 1, 0);
                break;
            case kendo_angular_common_1.Keys.ArrowLeft:
                this.handleCellNavigation(isRTL ? 1 : -1, 0);
                break;
            case kendo_angular_common_1.Keys.Enter:
                this.handleEnter();
                break;
            default: return;
        }
        event.preventDefault();
    };
    /**
     * @hidden
     */
    ColorPaletteComponent.prototype.handleHostBlur = function () {
        this.notifyNgTouched();
        this.handleCellFocusOnBlur();
    };
    /**
     * @hidden
     */
    ColorPaletteComponent.prototype.handleCellSelection = function (value, focusedCell) {
        if (this.readonly) {
            return;
        }
        this.selectedCell = focusedCell;
        this.focusedCell = this.selectedCell;
        this.focusInComponent = true;
        var parsedColor = utils_1.parseColor(value, this.format, false, false);
        this.cellSelection.emit(parsedColor);
        this.handleValueChange(parsedColor);
        if (this.selection !== parsedColor) {
            this.selection = parsedColor;
            this.selectionChange.emit(parsedColor);
        }
        if (focusedCell) {
            this.activeCellId = "k-" + this.selectedCell.row + "-" + this.selectedCell.col + "-" + this.uniqueId;
        }
    };
    /**
     * @hidden
     */
    ColorPaletteComponent.prototype.writeValue = function (value) {
        this.value = value;
        this.selectCell(value);
    };
    /**
     * @hidden
     */
    ColorPaletteComponent.prototype.registerOnChange = function (fn) {
        this.notifyNgChanged = fn;
    };
    /**
     * @hidden
     */
    ColorPaletteComponent.prototype.registerOnTouched = function (fn) {
        this.notifyNgTouched = fn;
    };
    /**
     * @hidden
     */
    ColorPaletteComponent.prototype.setDisabledState = function (isDisabled) {
        this.cdr.markForCheck();
        this.disabled = isDisabled;
    };
    /**
     * @hidden
     * Used by the FloatingLabel to determine if the component is empty.
     */
    ColorPaletteComponent.prototype.isEmpty = function () {
        return false;
    };
    /**
     * Clears the color value of the ColorPalette.
     */
    ColorPaletteComponent.prototype.reset = function () {
        this.focusedCell = null;
        if (utils_2.isPresent(this.value)) {
            this.handleValueChange(undefined);
        }
        this.selectedCell = undefined;
    };
    ColorPaletteComponent.prototype.handleValueChange = function (color) {
        if (this.value === color) {
            return;
        }
        this.value = color;
        this.valueChange.emit(color);
        this.notifyNgChanged(color);
        this.setHostElementAriaLabel();
    };
    ColorPaletteComponent.prototype.handleCellFocusOnBlur = function () {
        this.focusInComponent = false;
        this.focusedCell = this.selectedCell;
    };
    ColorPaletteComponent.prototype.selectCell = function (value) {
        this.selectedCell = this.service.getCellCoordsFor(value);
        this.focusedCell = this.selectedCell;
    };
    ColorPaletteComponent.prototype.setRows = function () {
        if (!utils_2.isPresent(this.palette)) {
            return;
        }
        this.columns = this.columns || DEFAULT_COLUMNS_COUNT;
        this.service.setColorMatrix(this.palette, this.columns);
    };
    ColorPaletteComponent.prototype.handleCellNavigation = function (horizontalStep, verticalStep) {
        if (this.readonly) {
            return;
        }
        this.focusedCell = this.service.getNextCell(this.focusedCell, horizontalStep, verticalStep);
        this.focusInComponent = true;
    };
    ColorPaletteComponent.prototype.setHostElementAriaLabel = function () {
        var parsed = utils_1.parseColor(this.value, this.format);
        this.renderer.setAttribute(this.host.nativeElement, 'aria-label', "" + (this.value ? parsed : this.localizationService.get('colorPaletteNoColor')));
    };
    ColorPaletteComponent.prototype.handleEnter = function () {
        if (!utils_2.isPresent(this.focusedCell)) {
            return;
        }
        var selectedColor = this.service.getColorAt(this.focusedCell);
        this.handleCellSelection(selectedColor, this.focusedCell);
    };
    var ColorPaletteComponent_1;
    tslib_1.__decorate([
        core_1.HostBinding('attr.dir'),
        tslib_1.__metadata("design:type", String)
    ], ColorPaletteComponent.prototype, "direction", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('attr.id'),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [])
    ], ColorPaletteComponent.prototype, "paletteId", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ColorPaletteComponent.prototype, "id", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ColorPaletteComponent.prototype, "format", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], ColorPaletteComponent.prototype, "value", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number),
        tslib_1.__metadata("design:paramtypes", [Number])
    ], ColorPaletteComponent.prototype, "columns", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object),
        tslib_1.__metadata("design:paramtypes", [Object])
    ], ColorPaletteComponent.prototype, "palette", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number),
        tslib_1.__metadata("design:paramtypes", [Number])
    ], ColorPaletteComponent.prototype, "tabindex", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], ColorPaletteComponent.prototype, "disabled", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], ColorPaletteComponent.prototype, "readonly", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], ColorPaletteComponent.prototype, "tileSize", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ColorPaletteComponent.prototype, "selectionChange", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ColorPaletteComponent.prototype, "valueChange", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ColorPaletteComponent.prototype, "cellSelection", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('attr.tabindex'),
        tslib_1.__metadata("design:type", Number),
        tslib_1.__metadata("design:paramtypes", [])
    ], ColorPaletteComponent.prototype, "hostTabindex", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-colorpalette'),
        tslib_1.__metadata("design:type", Boolean)
    ], ColorPaletteComponent.prototype, "hostClasses", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('attr.aria-disabled'),
        core_1.HostBinding('class.k-state-disabled'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ColorPaletteComponent.prototype, "disabledClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.aria-readonly'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ColorPaletteComponent.prototype, "readonlyAttribute", null);
    tslib_1.__decorate([
        core_1.HostListener('keydown', ['$event']),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ColorPaletteComponent.prototype, "handleKeydown", null);
    tslib_1.__decorate([
        core_1.HostListener('blur'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", void 0)
    ], ColorPaletteComponent.prototype, "handleHostBlur", null);
    ColorPaletteComponent = ColorPaletteComponent_1 = tslib_1.__decorate([
        core_1.Component({
            exportAs: 'kendoColorPalette',
            selector: 'kendo-colorpalette',
            providers: [
                {
                    multi: true,
                    provide: forms_1.NG_VALUE_ACCESSOR,
                    useExisting: core_1.forwardRef(function () { return ColorPaletteComponent_1; }) // tslint:disable-line:no-forward-ref
                }, {
                    provide: kendo_angular_common_1.KendoInput,
                    useExisting: core_1.forwardRef(function () { return ColorPaletteComponent_1; })
                },
                color_palette_service_1.ColorPaletteService,
                colorpalette_localization_service_1.ColorPaletteLocalizationService,
                {
                    provide: kendo_angular_l10n_1.LocalizationService,
                    useExisting: colorpalette_localization_service_1.ColorPaletteLocalizationService
                },
                {
                    provide: kendo_angular_l10n_1.L10N_PREFIX,
                    useValue: 'kendo.colorpalette'
                }
            ],
            template: "\n        <ng-container kendoColorPaletteLocalizedMessages\n            i18n-colorPaletteNoColor=\"kendo.colorpalette.colorPaletteNoColor|The aria-label applied to the ColorPalette component when the value is empty.\"\n            colorPaletteNoColor=\"Colorpalette no color chosen\">\n        </ng-container>\n        <div role=\"listbox\" class=\"k-colorpalette-table-wrap\"\n            [attr.aria-activedescendant]=\"activeCellId\">\n            <table class=\"k-colorpalette-table k-palette\">\n                <tbody>\n                    <tr *ngFor=\"let row of colorRows; let rowIndex = index\">\n                        <td *ngFor=\"let color of row; let colIndex = index\"\n                            role=\"option\"\n                            [class.k-state-selected]=\"selectedCell?.row === rowIndex && selectedCell?.col === colIndex\"\n                            [class.k-state-focus]=\"focusInComponent && focusedCell?.row === rowIndex && focusedCell?.col === colIndex\"\n                            [attr.aria-selected]=\"selectedCell?.row === rowIndex && selectedCell?.col === colIndex\"\n                            [attr.aria-label]=\"color\"\n                            class=\"k-colorpalette-tile\"\n                            [id]=\"'k-' + rowIndex + '-' + colIndex + '-' + uniqueId\"\n                            [attr.value]=\"color\"\n                            (click)=\"handleCellSelection(color, { row: rowIndex, col: colIndex })\"\n                            [ngStyle]=\"{\n                                backgroundColor: color,\n                                width: tileLayout.width + 'px',\n                                height: tileLayout.height + 'px',\n                                minWidth: tileLayout.width + 'px'\n                            }\">\n                        </td>\n                    </tr>\n                </tbody>\n            </table>\n        </div>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.ElementRef,
            color_palette_service_1.ColorPaletteService,
            core_1.ChangeDetectorRef,
            core_1.Renderer2,
            kendo_angular_l10n_1.LocalizationService,
            core_1.NgZone])
    ], ColorPaletteComponent);
    return ColorPaletteComponent;
}());
exports.ColorPaletteComponent = ColorPaletteComponent;
