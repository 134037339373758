//import angular from 'angular';
import tmpl from './search.html';


class StockCodeSearchCtrl implements ng.IComponentController {

    processing: boolean;
    status: ScreenStatus = ScreenStatus.loading;    

    gridControl: IGridControl = {};
    gridTop: number;

    selectedItem: any;

    defaults: any;
    defaultClient: any = null;
    defaultSite: any = null;
    defaultError: any;

    criteria = {
        filterValues: {
            clientIds: [],
            siteIds: [],
            equipmentTypeId: null,
            specificationIds: [],
            stockCode: '',
            componentTypeId: null,
            specifications: [],
            defaultToAllSitesWhenNoSystemHierarchyFilter: true
        },
        atDate: new Date()
    };

    static $inject = ['$scope', 'amtXlatSvc', '$timeout', 'stockCodesUrls', 'WindowFactory', 'amtCommandQuerySvc', 'errorReporter', 'confirmSvc', 'notifySvc', 'ocSecuritySvc', 'exportSvc'];

    constructor(private $scope: ng.IScope, private amtXlatSvc: IAmtXlatSvc, private $timeout: ng.ITimeoutService, private stockCodesUrls: IStockCodesUrls,
        private WindowFactory: IWindowFactory, private amtCommandQuerySvc: IAmtCommandQuerySvc, private errorReporter: IErrorReporter, private confirmSvc: IConfirmSvc,
        private notifySvc: INotifySvc, private ocSecuritySvc: IOcSecuritySvc, private exportSvc: IExportSvc) {

        this.$scope['editStockCode'] = (item) => this.addEditStockCode(item);
        this.$scope['deleteStockCode'] = (item) => this.deleteStockCode(item);
    }

    $onInit() {
        this.loadDefaults();
    }

    onStockCodesDataBound() {
        this.selectedItem = null;
    }

    onStockCodesLoaded(response: IResultObj<any>) {

        let stockCodes = response.result;

        let multipleLabel = this.amtXlatSvc.xlat('common.multiple_label');

        for (let stockCode of stockCodes || []) {

            try {
                stockCode.canEdit = this.ocSecuritySvc.isAuthorised('Security.Settings.StockCodes', AccessTypes.readOnly, stockCode.client.id, (stockCode.site ? stockCode.site.id : null));
            } catch { stockCode.canEdit = false; }

            try {
                stockCode.canDelete = this.ocSecuritySvc.isAuthorised('Security.Settings.StockCodes.Delete', AccessTypes.readWrite, stockCode.client.id, (stockCode.site ? stockCode.site.id : null));
            } catch { stockCode.canDelete = false; }

            stockCode.specificationDisplay = stockCode.specifications && stockCode.specifications.length ? (stockCode.specifications.length > 1 ? multipleLabel : stockCode.specifications[0]) : '';
        }

        return response;
    }

    onSelectedItemChanged(items: any[]) {
        this.selectedItem = items != null && items.length ? items[0] : null;
    }

    setHeaderHeight(height: number, offset: number) {
        this.gridTop = height + offset;
    }

    export() {
        this.exportSvc.exportData(this.stockCodesUrls.searchExport, this.criteria, this.amtXlatSvc.xlat('stockCodes.searchExportFilename'));
    }

    async loadDefaults() {

        this.status = ScreenStatus.loading;
        this.processing = true;

        let defaultCriteria = {
            componentTypeName: null,
            statusTypeNames: []
        };

        try {

            let response = await this.amtCommandQuerySvc.post(this.stockCodesUrls.getSearchDefaults, defaultCriteria);

            if (!response)
                return;

            let sites = angular.copy(response.sites);

            this.defaults = response;

            this.defaults.sites = []; // we delay this so the client change doesn't trigger a lookup to validate;            
            this.defaultClient = response.clients[0]; // delay this so that client is sorted and doesn't trigger a lookup of the site

            this.$timeout(() => {
                this.defaults.sites = sites;
                this.defaultSite = sites[0];
            });

            this.status = ScreenStatus.ready;

        } catch (error) {
            this.defaultError = error;
            this.errorReporter.logError(error);
        } finally {
            this.processing = false;
        }
    }

    addEditStockCode(item?) {

        this.WindowFactory.openItem({
            component: 'stock-code-details',
            caption: item ? String.format('{0} - {1}', this.amtXlatSvc.xlat('stockCodes.editStockCode'), item.stockCode) : this.amtXlatSvc.xlat('stockCodes.newStockCode'),
            initParams: {
                stockCodeId: item ? item.id : null,
                isEdit: item != null,
                site: this.defaultSite,
                client: this.defaultClient,
                showCloseOnSave: false
            },
            width: 650,
            onDataChangeHandler: () => this.filter()
        });
    }

    async deleteStockCode(item) {

        try {
            await this.confirmSvc.confirmMessage('stockCodes.confirmDeleteStockCode_title', 'stockCodes.confirmDeleteStockCode_text', item.stockCode);
        } catch {
            return; // they canelled
        }

        this.processing = true;

        try {
            await this.amtCommandQuerySvc.post(this.stockCodesUrls.deleteStockCode, item);

            this.notifySvc.success(this.amtXlatSvc.xlat('stockCodes.deleteSuccess'));
            this.filter();

        } catch (error) {
            this.errorReporter.logError(error);
        } finally {
            this.processing = false;
        }
    }

    filter() {
        if (this.gridControl && this.gridControl.refresh)
            this.gridControl.refresh();
    }
}

angular.module('app.admin').component('stockCodeSearch', {
    template: tmpl,
    controllerAs: 'vm',
    controller: StockCodeSearchCtrl
});
