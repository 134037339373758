//import angular from 'angular';
import tmpl from './refreshReferenceData.html';


angular.module("app.site").component("refreshReferenceData",
        {
            template: tmpl,
            bindings: {
                initParams: '=',
                buttonMethods: '=',
                buttonStates: '=',
                buttons: '=',
                closeOnSave: '=',
                wnd: '='
            },
            controller: refreshReferenceDataCtrl
        });

    // @ts-ignore
    refreshReferenceDataCtrl.$inject = ['$scope', 'amtCommandQuerySvc', 'enums', '$interval', '$timeout', 'amtConstants', 'amtXlatSvc', 'WindowFactory', 'errorReporter'];

    function refreshReferenceDataCtrl($scope, amtCommandQuerySvc, enums, $interval, $timeout, amtConstants, amtXlatSvc, WindowFactory, errorReporter) {

        var vm = this;
        $scope.vm = this;

        vm.apiUrls = amtConstants.url;

        var interval;

        this.$onInit = function () {
            vm.buttons = [
                {
                    primary: true,
                    cancel: false,
                    value: 'common.retry_label',
                    name: 'retryButton',
                    click: 'refresh',
                    type: enums.buttonTypes.button
                }
            ];

            vm.buttonStates = {
                retryButton: {
                    visible: false,
                    disabled: false
                }
            };

            vm.buttonMethods = {
                refresh: vm.refresh
            };
        };

        vm.refresh = function () {
            $scope.refreshReferenceDataProgressPercentage = 0;
            $scope.refreshReferenceDataProgressMessage = "";
            $scope.refreshReferenceDataError = false;

            amtCommandQuerySvc.put(vm.apiUrls.refreshReferenceData);

            interval = $interval(function () {
                $scope.checkRefreshReferenceDataProgress();
            }, 1000);
        };

        $scope.checkRefreshReferenceDataProgress = function () {

            amtCommandQuerySvc.put(vm.apiUrls.checkRefreshreferenceDataProgress).then(function (response) {

                $scope.refreshReferenceDataProgressPercentage = response.percentage;
                $scope.refreshReferenceDataProgressMessage = response.message;
                $scope.refreshReferenceDataError = response.refreshReferenceDataProgressStatus === "error";

            }).catch(function (error) {
                
                $scope.refreshReferenceDataError = true;
                $scope.refreshReferenceDataProgressMessage = error.message;

                errorReporter.logError(error);
                
            }).finally(function () {
                if ($scope.refreshReferenceDataProgressPercentage === 100 || $scope.refreshReferenceDataError) {

                    $interval.cancel(interval);

                    if (!$scope.refreshReferenceDataError) {
                        $timeout(function () {
                            try {

                                if (vm.wnd.onDataChanged) {
                                    vm.wnd.onDataChanged();
                                }

                                WindowFactory.closeWindow(vm.wnd);
                            } catch (e) {

                            }
                        }, 1000);
                    }
                }
            });
        };

        $scope.$watch('refreshReferenceDataError', function () {
            if ($scope.refreshReferenceDataError) {
                vm.wnd.footerOff = false;
                vm.buttonStates.retryButton.visible = true;
            } else {
                vm.wnd.footerOff = true;
                vm.buttonStates.retryButton.visible = false;
            }
        });

        vm.refresh();

        return vm;
    }
