//import angular from 'angular';


angular.module('app.admin')
        .service("currencySvc", [
            "amtXlatSvc", "WindowFactory", "exportSvc", "$q", "amtCommandQuerySvc",
            function ( 
                amtXlatSvc, WindowFactory, exportSvc, $q, amtCommandQuerySvc
            ) {
                var svc = this;

                svc.module = "CurrencyType";
                svc.resourceGroup = "currency";
                svc.mainPermission = 'Security.Settings.Organisation.Currency';

                svc.getAsync = function (id) {
                    return amtCommandQuerySvc.get(svc.urls.get, { id: id });
                };

                svc.loadDefaultsAsync = function () {
                    return $q(function (resolve) {

                        svc.activeStates = svc.getActiveStates();

                        return resolve(svc.defaults);
                    });
                };

                svc.getActiveStates = function () {
                    return [
                        {
                            key: true,
                            description: amtXlatSvc.xlat('client.activeTrue'),
                            default: true
                        },
                        {
                            key: false,
                            description: amtXlatSvc.xlat('client.activeFalse'),
                            default: false
                        }
                    ];
                };

                svc.openSite = function (site, cb) {
                    WindowFactory.openItem({
                        component: 'add-edit-site',
                        caption: amtXlatSvc.xlat("site.editSite"),
                        initParams: { id: site.id },
                        width: 550,
                        windowRelatedRecordId: site.id
                    });
                };

                svc.urls = {
                    getLookups: baseApiUrl + 'referenceData/get' + svc.module + 's',
                    get: 'referenceData/get' + svc.module + '',
                    save: 'referenceData/save' + svc.module + '',
                    search: baseApiUrl + 'referenceData/search' + svc.module + 's',
                    export: 'referenceData/export' + svc.module + 's'
                };

                svc.export = function (searchCriteria) {
                    exportSvc.exportData(svc.urls.export, searchCriteria, amtXlatSvc.xlat(svc.resourceGroup + '.searchTitle'));
                };

                svc.saveAsync = function (item) {
                    var criteria = {
                        id: item.id,
                        mode: item.mode,
                        name: item.name,
                        description: item.description,
                        decimalPlaces: item.decimalPlaces,
                        currency: item.currency,
                        symbol: item.symbol,
                        isActive: item.isActive,
                        applyToAllLanguages: item.applyToAllLanguages
                    };

                    return amtCommandQuerySvc.post(svc.urls.save, criteria);
                };

                svc.addItem = function (cb) {
                    var item = {
                        id: null,
                        isActive: false,
                        mode: 'add',
                        decimalPlaces: 2
                    };

                    svc.openItem(item, cb);
                };

                svc.openItem = function (item, cb) {
                    WindowFactory.openItem({
                        component: 'add-edit-currency',
                        caption: amtXlatSvc.xlat(svc.resourceGroup + "." + (item.mode === "add" ? "addItem" : "editItem")),
                        initParams: item,
                        width: 550,
                        onDataChangeHandler: cb,
                        windowRelatedRecordId: item.id
                    });
                };

            }
        ]
        )
