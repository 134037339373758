//import angular from 'angular';

angular.module('amtFramework.validation').directive('ngInvalid', [
    function () {
            return {
                restrict: 'A',
                require: 'ngModel',
                link: function (scope, elem, attr, ctrl) {
                    attr.$observe('ngInvalid', function (newValue) {
                        ctrl.$setValidity('ngInvalid', newValue === 'false' );
                    });
                }
            };
    }
]);
