//import angular from 'angular';

    angular.module('app.directives').directive('ocChartLine', function () {
        return {
            replace: true,
            scope: {
                config: "="
            },
            template:
                    '<g>' +
                    '    <path ' +
                    '        ng-mouseover="hoverStart($event)" ' +
                    '        ng-mouseleave="hoverEnd($event)" ' +
                    '        ng-attr-d="{{config.pointsD}}" ' +
                    '        class="{{config.cssClass}}" ' +
                    '    />' +
                    '    <oc-chart-point ng-repeat="p in config.points" config="p" ng-cloak />' +
                    '</g>'
        };
    });
