//import angular from 'angular';
import * as _ from 'underscore';
import BrowserSvc from '../../../services/browserSvc';
import tmpl from "./stockGrid.html";
import tmplMobile from "./stockGridMobile.html";


    angular.module("app.stocktake")
        .component("stockGrid", {
            template: ['browserSvc', (browserSvc: BrowserSvc) => browserSvc.isMobile ? tmplMobile : tmpl ],
            bindings: {
                heading: "@",
                data: "=",
                activeLevel: "=?",
                gridIndex: "=",
                preferredLevel: "<",
                reindexCallback: "&",
                setDirty: "&",
                activeRow: "<" // currently active row.
            },
            controller: stockGridCtrl
        });

    stockGridCtrl.$inject = ["dataBroker", "$timeout", "WindowFactory", "amtXlatSvc", "$scope", "errorReporter", "notifySvc", "browserSvc"];

    function stockGridCtrl(dataBroker, $timeout, WindowFactory: IWindowFactory, amtXlatSvc, $scope: ng.IScope, errorReporter: IErrorReporter, notifySvc, browserSvc: BrowserSvc) {
        var ctrl = this;
        ctrl.isMobile = browserSvc.isMobile;

        ctrl.idField = ctrl.isMobile ? "id" : "key";


        // enable this if you want children counts to be set when accepting a parent
        var childUpdateMode = 'CLEAR';

        ctrl.dropEvent = dropEvent;
        ctrl.rowSelected = rowSelected;
        ctrl.recalcParent = recalcParent;

        ctrl.$onInit = function () {
            dataBroker.getStocktakeComponentTypes().then(function (data) {
                ctrl.componentTypes = data.result;

                // desktop type is type
                // mobile type is id... stupid if you ask me. not sure why
                if (ctrl.data.type || ctrl.data.id) {
                    getSpecifications();
                }
            });
        };

        $scope.$watch('$ctrl.activeItem', function (newValue: string) {
            if (newValue) {
                ctrl.activeLevel = newValue.split(';').length;
            } else {
                ctrl.activeLevel = 0;
            }
        });

        ctrl.$onChanges = function (changesObj) {
            // the active item was set from above.
            if (changesObj.activeRow && changesObj.activeRow.currentValue && changesObj.activeRow.currentValue !== ctrl.activeItem) {
                ctrl.activeItem = ctrl.activeRow;
            }

            if (changesObj.preferredLevel && changesObj.preferredLevel.currentValue) {
                ctrl.preferredLevel = changesObj.preferredLevel.currentValue;
                $scope.$broadcast("dropOpenLevel", changesObj.preferredLevel.currentValue);
            }
        };

        ctrl.keyPress = (event: JQuery.KeyPressEvent) => {
            //mobile only: only the template for mobile binds ngkeypress and calls this method
            event.preventDefault();
            switch (event.which) {
                case 32 /* space */:
                    clear();
                    return;
                case 37 /* left */:
                case 38 /* up */:
                    moveUp();
                    return;
                case 39 /* right */:
                    acceptExpected();
                    return;
                case 13 /* enter */:
                case 40 /* down */:
                    moveDown();
                    return;
                case 46 /* delete */:
                    removeComponent();
                    return;
            }

            if (event.which >= 48 && event.which <= 57 /* digits */)
                handleKey(String.fromCharCode(event.which));

            if (event.which >= 96 && event.which <= 105 /* numpad */)
                handleKey(String.fromCharCode(event.which - 96 + 48));
        }

        ctrl.initCap = str => str.toLowerCase().replace(/(?:^|\b)[a-z]/g, (m) => { return m.toUpperCase(); });

        ctrl.openComponent = (item) => {
            if (item) {
                if (item.componentId) {
                    WindowFactory.openItem({
                        component: 'component-details',
                        caption: amtXlatSvc.xlat('equipment.open' + ctrl.initCap(ctrl.data.type), item.serialNumber),
                        windowRelatedRecordId: item.componentId,
                        initParams: {
                            equipmentId: item.componentId,
                            siteId: ctrl.data.siteId,
                            componentType: ctrl.initCap(ctrl.data.type),
                            serialNumber: item.serialNumber,
                            showCloseOnSave: false
                        },
                        width: 800,
                        height: 850
                    });
                } else {
                    notifySvc.info(amtXlatSvc.xlat('stocktake.componentNotReceived'));
                }
            }
        };

        var keyClickOff = $scope.$on(Keypad.KEY_PRESSED, (event, key) => {
            handleKey(key);
        });

        function handleKey(key: string) {
            if (key) {
                var currentElement = findElement(ctrl.activeItem);

                if (isNaN(currentElement.actual)) {
                    currentElement.actual = 0;
                }

                var newActual = (currentElement.actual * 10) + Number(key);
                if (newActual <= 999) {
                    currentElement.actual = newActual;
                    currentElement.manualEdit = true;
                }
                // save the data 

                currentElement.deleted = false;
                bubbleTotalsUp(ctrl.activeItem);

                if (childUpdateMode == 'CLEAR') {
                    clearChildren(ctrl.activeItem);
                }

                if (ctrl.isMobile) {
                    dataBroker.saveStocktake(ctrl.data).catch(function (error) {
                        errorReporter.logError(error);
                    });
                }

                $timeout();
            }
        }

        function addComponentDataChangeHandler(response) {

            var matchedSerial = response;

            var originalKey = matchedSerial.originalId;

            var locationName = response.location ? response.location.name || response.status.location || response.location : amtXlatSvc.xlat("stocktake.noLocation");
            var locationId = response.location ? response.location.id : null;
            var serialNumber = (response.serial || response.serialNumber.site || response.serialNumber.manufacturer || response.serialNumber);
            var statusName = response.status.description || response.status;
            var specificationName = response.description || response.equipmentTypeSpecificationTypeName || response.specification.description || response.specification.name || response.specification;
            var specificationId = response.equipmentTypeSpecificationTypeId || response.specification.id;

            if (response.includeComment && response.comment) {
                if (!ctrl.data.comments) ctrl.data.comments = [];
                ctrl.data.comments.push({
                    id: uuid(),
                    comment: response.comment
                });
            }

            if (matchedSerial && originalKey) {

                var matchedComponent = findElement(originalKey);

                if (matchedComponent) {
                    // flag the old one as deleted
                    matchedComponent.deleted = true;

                    if (matchedComponent.actual === 1) {
                        // need to bubble up the count corrections
                        bubbleTotalsUp(originalKey, true /* preserve manual edits */);
                    }

                    matchedComponent.manualEdit = false;
                }
            }

            var newId = locationName + ";" + statusName + ";" + specificationName + ";" + serialNumber;

            // Determine if this is a previously unknown serial (i.e. new entry vs moved entry)
            var entryToAdd;
            var index = ctrl.isMobile ? _.findIndex(ctrl.gridIndex, item => item.serialNumber === serialNumber) :
                                              _.findIndex(ctrl.gridIndex, item => item.key.split(";")[3] === serialNumber)

            if (index > -1) {

                // This serial is already in the stocktake.

                ctrl.gridIndex.splice(index, 1); // Remove the "original".                

                if (ctrl.isMobile) {
                    entryToAdd = {
                        moved: true,
                        toBeReceived: response.toBeReceived || response.received === false,
                        actual: 1,
                        expected: response.expected,
                        deleted: false,
                        serialNumber: serialNumber,
                        manualEdit: true,
                        componentId: response.componentId,
                        id: newId,
                        description: specificationName,
                        status: {
                            location: locationName, // Don't use data.element, because we want the new value
                            name: statusName, // Don't use data.element, because we want the new value
                            siteStatusTypeLocationId: locationId
                        },
                        equipmentTypeSpecificationTypeId: specificationId
                    };

                } else {
                    entryToAdd = matchedSerial;

                    entryToAdd.moved = true;
                    entryToAdd.actual = 1;
                    entryToAdd.expected = 1;
                    entryToAdd.deleted = false;
                    entryToAdd.manualEdit = true;
                    entryToAdd.description = specificationName;
                    entryToAdd.status = {
                        location: locationName, // Don't use data.element, because we want the new value
                        name: matchedSerial.statusName, // Don't use data.element, because we want the new value
                        siteStatusTypeLocationId: matchedSerial.siteStatusTypeLocationId
                    };
                }

            } else {

                entryToAdd = {
                    moved: true,
                    toBeReceived: response.received === false,
                    actual: 1,
                    expected: 1,
                    deleted: false,
                    serialNumber: serialNumber,
                    manualEdit: true,
                    componentId: response.componentId,
                    id: newId,
                    description: specificationName,
                    status: {
                        location: locationName,
                        name: statusName,
                        siteStatusTypeLocationId: locationId
                    },
                    equipmentTypeSpecificationTypeId: specificationId
                };
            }

            if (ctrl.isMobile) {
                entryToAdd.id = newId;
            } else {
                entryToAdd.key = newId;
            }

            // get level 1 - location
            var location = findElement(entryToAdd.status.location);

            if (!location) {
                // not found - add it
                location = {
                    description: locationName,
                    items: {},
                    actual: 1,
                    expected: 1,
                    extra: true
                };

                ctrl.data.items[location.description] = location;
            } else {
                location.expected += 1;
                location.actual += 1;
            }

            // find the level 2 node
            var status = findElement(entryToAdd.status.location + ";" + entryToAdd.status.name);

            if (!status) {
                // status not found - add it
                status = {
                    description: statusName,
                    items: {},
                    actual: 1,
                    expected: 1,
                    extra: true
                };

                location.items[entryToAdd.status.name] = status;
            } else {
                status.expected += 1;
                status.actual += 1;
            }

            // find the level 3 node - specification
            var spec = findElement(entryToAdd.status.location + ";" + entryToAdd.status.name + ";" + entryToAdd.description);

            if (!spec) {
                // spec not found - add it
                spec = {
                    description: specificationName,
                    items: {},
                    actual: 1,
                    expected: 1,
                    extra: true
                };

                status.items[entryToAdd.description] = spec;
            } else {
                // increment the expected and actual counts
                spec.expected += 1;
                spec.actual += 1;
            }

            // add the received component to the spec
            spec.items[entryToAdd.serialNumber] = entryToAdd;

            var indexItem = ctrl.gridIndex.find(function (item) { return item.serialNumber === serialNumber; });;

            if (!indexItem) {
                ctrl.gridIndex.push(entryToAdd);
            }

            ctrl.reindexCallback();

            ctrl.activeItem = newId;
            bubbleTotalsUp(ctrl.activeItem);

            ctrl.setDirty();

            if (ctrl.isMobile) {
                // save the data 
                dataBroker.saveStocktake(ctrl.data).catch(function (error) {
                    errorReporter.logError(error);
                });
            } else {
                if (ctrl.data.components) {
                    ctrl.data.components.push({
                        description: specificationName,
                        serialNumber: serialNumber,
                        statusLocation: locationName,
                        componentId: response.componentId,
                        statusName: statusName,
                        deleted: false,
                        actual: 1,
                        equipmentTypeSpecificationTypeName: specificationName,
                        equipmentTypeSpecificationTypeId: specificationId,
                        key: newId
                    });
                }
            }
        }

        function addComponent() {
            var level = currentLevel();

            var currentElement = findElement(ctrl.activeItem);

            var segs = ctrl.activeItem.split(";");

            var data = {
                element: currentElement,
                grid: ctrl.data,
                level: level,
                gridIndex: ctrl.gridIndex,
                date: ctrl.data.createdDate,
                type: ctrl.isMobile ? ctrl.data.id : ctrl.data.type,
                specifications: ctrl.specifications,
                location: segs[0],
                status: segs[1],
                specification: {
                    id: level === 3 ? currentElement.id : currentElement.equipmentTypeSpecificationTypeId,
                    name: segs[2]
                }
            };

            WindowFactory.openItem({
                component: "tyre-add",
                caption: amtXlatSvc.xlat("stocktake.checkSerialNumber_" + data.type.toLowerCase()), // new comes through as lowercase, but existing comes through as PascalCase, not sure if this raises other issues
                width: 600,
                modal: true,
                canClose: false,
                initParams: data,
                onDataChangeHandler: (response) => {
                    addComponentDataChangeHandler(response);
                }
            });
        }

        function acceptExpectedRecusive(element, level) {
            if (element.actual !== element.expected) {
                element.actual = element.expected;

                if (level > 0) {
                    element.flagged = false;
                    element.deleted = false;
                    element.manualEdit = false;
                }

                ctrl.setDirty();

                if (childUpdateMode == 'SET_ACTUAL' && element.items) {
                    for (var key in element.items) {
                        if (element.items.hasOwnProperty(key)) {
                            acceptExpectedRecusive(element.items[key], level + 1);
                        }
                    }
                }
            }
        }

        function acceptExpected() {
            var element = findElement(ctrl.activeItem);

            if (element) {
                if (element.actual !== element.expected) {
                    acceptExpectedRecusive(element, 0);

                    element.manualEdit = true;
                    element.deleted = false;
                    element.flagged = false;

                    bubbleTotalsUp(ctrl.activeItem);

                    if (childUpdateMode == 'CLEAR') {
                        clearChildren(ctrl.activeItem);
                    }

                    if (ctrl.isMobile) {
                        // save the data 
                        dataBroker.saveStocktake(ctrl.data).catch(function (error) {
                            errorReporter.logError(error);
                        });
                    }
                }

                moveDown();
            }
        }

        function moveUp() {
            var activeParent = findElementParent(ctrl.activeItem);

            // traverse the children and return one before;
            var el = "";
            var previous = previousSibling(ctrl.activeItem);
            if (previous) {
                el = previous;

                // now drill as required
                var element = findElement(el);
                while (element.open) {
                    var child = el + ";" + lastChildProperty(element);
                    element = findElement(child);
                    if (element) {
                        el = child;
                    } else {
                        ctrl.activeItem = el;
                        break;
                    }
                }
            } else {
                el = activeParent;
            }
            if (el) {
                ctrl.activeItem = el;
            }
        }

        function removeComponent() {
            if (currentLevel() < 4) {
                // really want to pass the levels up and down to the number pad to enable disable keys.
                return;
            }
            var element = findElement(ctrl.activeItem);

            element.deleted = true;
            element.actual = 0;

            ctrl.reindexCallback();

            element.actual = " - ";
            moveDown();
            bubbleTotalsUp(ctrl.activeItem);

            if (ctrl.isMobile) {
                // save the data 
                dataBroker.saveStocktake(ctrl.data).catch(function (error) {
                    errorReporter.logError(error);
                });
            }
        }

        function clear() {
            var element = findElement(ctrl.activeItem);

            if (element.actual > 0) {
                ctrl.setDirty();

                element.deleted = false;
                element.actual = undefined;
                clearChildren(ctrl.activeItem);
                bubbleTotalsUp(ctrl.activeItem);

                if (ctrl.isMobile) {
                    dataBroker.saveStocktake(ctrl.data).catch(function (error) {
                        errorReporter.logError(error);
                    });
                }
            }
        }

        var modifierClickOff = $scope.$on(Keypad.MODIFIER_KEY_PRESSED, function (event: ng.IAngularEvent, key: string) {

            if (event.stopPropagation)
                event.stopPropagation();
            
            event.preventDefault();

            switch (key) {
                case "PLUS":
                    addComponent();
                    break;
                case "RIGHT":
                case "OK":
                    acceptExpected();
                    break;
                // and results in a down
                case "DOWN":
                    moveDown();
                    break;
                case "LEFT":
                case "UP":
                    moveUp();
                    break;
                case "DELETE":
                    removeComponent();
                    break;
                case "CLEAR":
                    clear();
                    break;
            }
            $timeout();
        });

        ctrl.$onDestroy = () => {
            modifierClickOff();
            keyClickOff();
        };

        function getSpecifications() {
            var componentType = _.find(ctrl.componentTypes, function (c) {

                // desktop type is type
                // mobile type is id... stupid if you ask me. not sure why
                return c.name.toLowerCase() === (ctrl.data.type || ctrl.data.id);
            });

            var specCriteria = {
                componentTypeId: (componentType ? componentType.key : null),
                siteIds: [ctrl.data.siteId]
            };

            dataBroker.getSpecifications(specCriteria, false).then(function (response) {
                if (response.result) {
                    ctrl.specifications = response.result;
                }
            });
        };

        function dropEvent(rowId, direction, userInitiated) {
            if (ctrl.blockToggle === rowId) {
                ctrl.blockToggle = null;
                return false;
            }
            var el;
            ctrl.blockToggle = null;
            console.log("drop " + direction + " - " + rowId);
            var element = findElement(rowId);
            if (direction === "close") {
                if (element) {
                    element.open = false;
                }
                // if the active row is in this then make it active
                if (ctrl.activeItem && ctrl.activeItem.indexOf(rowId) !== -1) {
                    ctrl.activeItem = rowId;
                }
                if (!userInitiated) {
                    el = findElement(rowId);
                    if (el && el.actual === "-") {
                        el.actual = undefined;
                    }
                }
            } else {
                if (element) {
                    element.open = true;
                }
                if (userInitiated) {
                    // make first child active
                    ctrl.activeItem = rowId + ";" + firstChildProperty(element);
                } else {
                    el = findElement(rowId);
                    if (el) {
                        if (!el.actual) {
                            el.actual = "-";
                        }
                    }
                }
            }
            return true;
        }

        function rowSelected(rowId) {
            // here we block the toggle, because you don't really want it springing open, if it's not yet selected.
            if (ctrl.activeItem !== rowId) {
                ctrl.blockToggle = rowId;
            }
            ctrl.activeItem = rowId;
        }

        function findElement(elementId) {
            if (!elementId) {
                return null;
            }

            if (elementId.indexOf(";") === -1) {
                return ctrl.data.items[elementId];
            }

            var sections = elementId.split(";");
            var element = ctrl.data;

            while (sections.length > 0) {
                if (!element) {
                    return undefined;
                }
                element = element.items[sections[0]];
                sections.shift();
            }

            return element;
        }

        function findElementParent(elementId) {
            if (!elementId) {
                return null;
            }

            var id = null;

            if (elementId.indexOf(";") === -1) {
                // at the root, nothing to return
                return id;
            }

            var sections = elementId.split(";");

            while (sections.length > 1) {
                if (id) {
                    id = id + ";" + sections[0];
                } else {
                    id = sections[0];
                }
                sections.shift();
            }

            return id;
        }

        function firstChildProperty(element) {
            if (element.items === null) {
                return null;
            }

            var k = Object.keys(element.items);

            if (k.length > 0) {
                return k[0];
            }

            return null;
        }

        function lastChildProperty(element) {
            if (element.items === null) {
                return null;
            }

            var k = Object.keys(element.items);
            var l = k.length;

            // need to find the last noMetadata item.
            while (l > 0) {
                return k[l - 1];
            }

            return null;
        }

        function previousSibling(item) {
            var activeParent = findElementParent(item);
            var parentElement = findElement(activeParent);
            var key;
            var prior = "";

            if (!parentElement) {
                parentElement = ctrl.data;
            }

            for (key in parentElement.items) {
                if (parentElement.items.hasOwnProperty(key)) {
                    var prefix = "";
                    if (activeParent) {
                        prefix = activeParent + ";";
                    }
                    if (prefix + key === item) {
                        if (prior) {
                            return prefix + prior;
                        } else {
                            return null;
                        }
                    }
                    prior = key;
                }
            }

            return null;
        }

        function nextSibling(item) {
            var activeParent = findElementParent(item);
            var parentElement;

            var next = false;
            var key;
            var prefix = "";

            if (!activeParent) {
                parentElement = ctrl.data;
            } else {
                parentElement = findElement(activeParent);
            }

            if (activeParent) {
                prefix = activeParent + ";";
            }

            for (key in parentElement.items) {
                if (parentElement.items.hasOwnProperty(key)) {
                    if (next) {
                        return prefix + key;
                    }
                    if (prefix + key === item) {
                        next = true;
                    }
                }
            }

            return null;
        }

        function bubbleTotalsUp(item, preserveManuEdit?) {
            // get parent
            var parent = findElementParent(item);

            if (parent) {
                var key;
                var flagged = false;
                var parentElement = findElement(parent);

                if (!parentElement) {
                    // reached the top, stop looking
                    return;
                }

                parentElement.actual = 0;

                if (!preserveManuEdit || !parentElement.manualEdit) {
                    for (key in parentElement.items) {
                        if (parentElement.items.hasOwnProperty(key)) {
                            if (parentElement.items[key].actual) {
                                if (!parentElement.items[key].deleted) {
                                    parentElement.actual += Number(parentElement.items[key].actual);
                                    parentElement.manualEdit = false;
                                }
                            } else {
                                flagged = true;
                            }
                        }
                    }
                }

                if (flagged && parentElement.actual === 0) {
                    parentElement.actual = undefined;
                    parentElement.flagged = false;
                } else {
                    parentElement.flagged = flagged;
                }

                bubbleTotalsUp(parent);
            }
        }

        function recalcParent(rowId) {
            var element = findElement(rowId);

            if (element) {
                element.manualEdit = true;
                element.flagged = false;

                bubbleTotalsUp(rowId, false);

                if (childUpdateMode == 'CLEAR') {
                    clearChildren(rowId);
                }
            }
        }

        function clearChildren(item) {
            var element = findElement(item);

            if (element) {
                if (element.items === null) {
                    return null;
                }

                var key;
                for (key in element.items) {
                    if (element.items.hasOwnProperty(key)) {
                        element.items[key].deleted = false;
                        element.items[key].manualEdit = false;
                        element.items[key].flagged = false;
                        element.items[key].actual = undefined;

                        var childKey = item + ";" + key;
                        clearChildren(childKey);
                    }
                }
            }

            return null;
        }

        function countIncomplete(atlevel, level, parent) {
            var c = 0;

            for (var key in parent.items) {
                if (parent.items.hasOwnProperty(key)) {
                    var thisItem = parent.items[key];

                    if (atlevel === level) {
                        if (thisItem.actual !== undefined && thisItem.actual !== null && thisItem.flagged !== true) {
                            c++;
                        }
                    } else {
                        if (atlevel > level && thisItem.items) {
                            // sum up the sub items
                            c = c + countIncomplete(atlevel, level + 1, thisItem.items);
                        }
                    }
                }
            }

            return c;
        }

        function moveDown() {

            var currentElement = findElement(ctrl.activeItem);

            if (currentElement) {

                // navigate through the children
                if (currentElement.open) {
                    
                    var child = firstChildProperty(currentElement);

                    if (child) {
                        ctrl.activeItem = ctrl.activeItem + ";" + child;
                        return;
                    }
                }

                var item = ctrl.activeItem;

                while (item) {

                    // grab the next sibling
                    var next = nextSibling(item);

                    // was there a sibling?
                    if (next) {
                        ctrl.activeItem = next;

                        // exit the loop
                        break;
                    }

                    // no sibling, go to the parent and then the next sibling
                    item = findElementParent(item);
                }              
            }
        };

        function currentLevel() {
            if (!ctrl.activeItem || ctrl.activeItem.indexOf(";") === -1) {
                return 0;
            }
            return ctrl.activeItem.split(";").length;
        }
    }
