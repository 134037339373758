/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Specifies the content in the Card header.
 */
var CardHeaderComponent = /** @class */ (function () {
    function CardHeaderComponent() {
        this.hostClass = true;
    }
    tslib_1.__decorate([
        core_1.HostBinding('class.k-card-header'),
        tslib_1.__metadata("design:type", Boolean)
    ], CardHeaderComponent.prototype, "hostClass", void 0);
    CardHeaderComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-card-header',
            template: "\n        <ng-content></ng-content>\n    "
        })
    ], CardHeaderComponent);
    return CardHeaderComponent;
}());
exports.CardHeaderComponent = CardHeaderComponent;
