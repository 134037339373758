//import angular from 'angular';
import tmpl from './Remove.html';



export type TRemoveComponentData = {
    id: guid;
    position: any;
    sessionDate: Date;
    selectedMoveTo?: any;
    event: string;
    eventDescription: string;
    selectedDamageCause?: any;
    selectedDamageLocation?: any;
    selectedDamageSource?: any;
    instant?: boolean;
    tyreBurst?: boolean;
    tyreExplosion?: boolean;
    selectedRemovalReason?: any;
    selectedLocation?: any;
    destination?: any;
    otherDestination?: string;
    creditAmount?: number;
    repairer?: any;
    otherRepairer?: string;
    components?: any[];
    type?: string;
    componentTypeId?: guid;
    serialNumber?: string;
    equipmentId?: guid;
    canRefit?: boolean;
}

const enum RemoveActionOption {
    assembly = 'assembly',
    individual = 'individual'
}

class RemoveCtrl implements ng.IController, IWindowController {

    form: ng.IFormController;
    wnd: IWindowObj;

    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: () => void };

    initParams: any;

    showAssembly: boolean = true;
    errorMessage: string = null;

    components: any[];
    data: TRemoveComponentData[];

    selectedAction: any;

    actionOptions = [
        { key: RemoveActionOption.assembly, name: this.amtXlatSvc.xlat('maintenanceSession.removeAssembly') },
        { key: RemoveActionOption.individual, name: this.amtXlatSvc.xlat('maintenanceSession.removeIndividual') }
    ];

    static $inject = ['$scope', 'WindowFactory', 'confirmSvc', 'amtXlatSvc'];

    constructor(private $scope: ng.IScope, private WindowFactory: IWindowFactory, private confirmSvc: IConfirmSvc, private amtXlatSvc: IAmtXlatSvc) {

        this.$scope.$watchGroup([() => this.form.$invalid, () => this.wnd.processing, () => this.form.$pristine], () => {
            this.buttonStates.saveButton.disabled = !this.form || this.form.$invalid || this.form.$pristine || this.wnd.processing;
        });

        this.$scope.$watch(() => this.selectedAction, (newVal, oldVal) => {
            if (newVal && oldVal && newVal !== oldVal) {
                this.showAssembly = newVal.key === RemoveActionOption.assembly;
                this.data = this.setupData(this.components, this.showAssembly);

                // this.wnd.position.top = newVal.key === 'assembly' ? 150 : 5;
            }
        });

        this.$scope.$watch(() => this.initParams.errorMessage, () => {
            this.errorMessage = this.initParams.errorMessage;
        });
    }

    $onInit() {

        this.components = angular.copy(this.initParams.components);

        if (!this.components) {

            this.components = [].concat(angular.copy(this.initParams.data.components))

            let data = angular.copy(this.initParams.data);

            for (let component of data.components || [])
                component.componentTypeId = component.typeId;

            data.componentTypeId = this.components[0].typeId;

            this.data = [data];
        }

        if (!this.components || !this.components.length) {
            this.WindowFactory.closeWindow(this.wnd); // no components
        } else if (this.components.length === 1) {
            this.showAssembly = false;
        } else {
            // multiple components, ensure tyre is first.
            if (this.components[1].type.toLowerCase() === EquipmentTypeName.tyre)
                this.components.reverse();
            
            let defaultSelectedAction = RemoveActionOption.assembly;

            if (this.initParams.checkedOut) { // remove as individual when checked out
                defaultSelectedAction = RemoveActionOption.individual;
            } else if (this.initParams.refit) { // remove as assembly on refit
                defaultSelectedAction = RemoveActionOption.assembly;
            }

            this.selectedAction = this.selectedAction = this.actionOptions.find(o => o.key === defaultSelectedAction);
            this.showAssembly = !this.initParams.checkedOut;
        }

        let saveButtonText = this.data ? 'framework.save_label' : 'maintenanceSession.remove';

        if (!this.data)
            this.data = this.setupData(this.components, this.showAssembly);

        this.WindowFactory.addButton(this, saveButtonText, 'saveButton', () => this.onSave(), true);
        this.WindowFactory.addButton(this, 'common.cancel_label', 'cancelButton', () => this.onClose());
    }

    setupData(components, showAssembly): TRemoveComponentData[] {

        let newData: TRemoveComponentData[] = [];

        if (!showAssembly) {

            // individual - ensure there is an entry per component in the data array and each entry has 1 component.
            for (let component of components || []) {

                let newComponent: TRemoveComponentData;

                if (this.data) {
                    // this is a switch from assembly to stripping individual components so we copy the data to the individual parts
                    newComponent = angular.copy(this.data[0]);
                } else {
                    newComponent =
                    {
                        position: this.initParams.fromPosition || this.initParams.position,
                        sessionDate: this.initParams.sessionDate,
                        id: uuid(),
                        eventDescription: this.amtXlatSvc.xlat('maintenanceSession.remove'),
                        event: MaintenanceSession_EventType.remove
                    };
                }

                newComponent.selectedMoveTo = undefined;

                newComponent.components = [];
                newComponent.components.push(angular.copy(component));

                newComponent.type = component.type;
                newComponent.componentTypeId = component.typeId;

                newComponent.serialNumber = component.serialNumber;
                newComponent.equipmentId = component.componentId;

                newData.push(newComponent);
            }

        } else {

            let newAssembly: TRemoveComponentData;

            //  assembly - ensure there is 1 entry in the data array and all components belong to it.
            newAssembly =
            {
                position: this.initParams.fromPosition || this.initParams.position,
                sessionDate: this.initParams.sessionDate,
                id: uuid(),
                eventDescription: this.amtXlatSvc.xlat('maintenanceSession.remove'),
                event: MaintenanceSession_EventType.remove,
                components: []
            };

            // default to using tyre if there are multiple 
            for (let component of components || []) {
                if (!newAssembly.type || component.type.toLowerCase() === EquipmentTypeName.tyre) {
                    newAssembly.componentTypeId = component.typeId;
                    newAssembly.type = component.type.toLowerCase();
                    newAssembly.serialNumber = component.serialNumber;
                    newAssembly.equipmentId = component.componentId;
                }
            }

            newAssembly.type = EquipmentTypeName.assembly;
            newAssembly.components = components; // save the components associated with the remove

            newData.push(newAssembly);
        }

        for (let component of newData) {
            // some sanity checks
            if (!(component.position && component.type))
                throw new Error('Removal data invalid');
        }

        return newData;
    }

    // close window
    async onClose() {
        try {
            await this.confirmSvc.confirmSaveChange(this.form && this.form.$dirty);
        } catch {
            return; // they cancelled
        }

        this.WindowFactory.closeWindow(this.wnd);
    }

    onSave() {
        if (this.wnd.onDataChanged)
            this.wnd.onDataChanged(this.data);
    }
}

class RemoveComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = RemoveCtrl;
    public controllerAs = 'vm';
}

angular.module('app.directives').component('removeComponent', new RemoveComponent());