/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var kendo_angular_dropdowns_1 = require("@progress/kendo-angular-dropdowns");
var core_1 = require("@angular/core");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
/**
 * @hidden
 */
var FilterMenuDropDownListDirective = /** @class */ (function () {
    function FilterMenuDropDownListDirective(host) {
        var _this = this;
        this.host = host;
        this.keydownHandler = function (e) {
            if (e.keyCode === kendo_angular_common_1.Keys.Escape && _this.host.isOpen) {
                e.stopPropagation();
                _this.host.toggle(false);
            }
        };
    }
    FilterMenuDropDownListDirective.prototype.ngAfterViewInit = function () {
        var wrapperElement = this.host.wrapper.nativeElement;
        wrapperElement.setAttribute('aria-label', this.filterMenuDropDownLabel);
        wrapperElement.addEventListener('keydown', this.keydownHandler, true);
    };
    FilterMenuDropDownListDirective.prototype.ngOnDestroy = function () {
        this.host.wrapper.nativeElement.removeEventListener('keydown', this.keydownHandler);
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], FilterMenuDropDownListDirective.prototype, "filterMenuDropDownLabel", void 0);
    FilterMenuDropDownListDirective = tslib_1.__decorate([
        core_1.Directive({ selector: '[kendoFilterMenuDropDown]' }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_dropdowns_1.DropDownListComponent])
    ], FilterMenuDropDownListDirective);
    return FilterMenuDropDownListDirective;
}());
exports.FilterMenuDropDownListDirective = FilterMenuDropDownListDirective;
