//import angular from 'angular';
import OcDateSvc from '../../../services/ocDateSvc';
import OcConfigSvc from '../../../services/ocConfigSvc';
import AdminUrls from '../../admin/adminUrls';
import { ExportReports, ExportReportCriteria, ExportProviders, ExportOrientation } from '../../../services/reportSvc';
import * as _ from 'underscore';

    angular.module('app.component')
        .controller('componentSearchCtrl', [
            '$scope', 'amtCommandQuerySvc', 'amtXlatSvc', 'componentUrls',
            'referenceDataUrls', 'adminUrls', 'exportSvc', 'WindowFactory',
            'ocConfigSvc', 'ocSecuritySvc', 'enums', 'errorReporter', 'ocDateSvc', 'reportSvc',
            function ($scope, amtCommandQuerySvc: IAmtCommandQuerySvc, xlatSvc: IAmtXlatSvc, componentUrls,
                referenceDataUrls, adminUrls: AdminUrls, exportSvc, WindowFactory: IWindowFactory,
                ocConfigSvc: OcConfigSvc, ocSecuritySvc: IOcSecuritySvc, enums, errorReporter: IErrorReporter, ocDateSvc: OcDateSvc, reportSvc) {

                var vm = this;

                // security
                vm.canChangeStatus = ocSecuritySvc.isAuthorised('Security.Components.ChangeStatus', AccessTypes.readWrite) && ocConfigSvc.user.site.active;
                vm.canViewVehicles = ocSecuritySvc.isAuthorised('Security.Vehicles', AccessTypes.readOnly);

                $scope.selectAll = true;

                vm.apiUrls = componentUrls;
                vm.adminUrls = adminUrls;
                vm.referenceDataUrls = referenceDataUrls;

                vm.selectedComponentType = null;

                vm.showClients = false;
                vm.showSites = false;
                vm.showFittedDetails = false;
                vm.showLocations = false;
                vm.showDestinations = false;

                vm.locationDate = ocDateSvc.removeLocalTimeZoneOffset(new Date());

                vm.showActions = false;

                vm.noOwnerListedText = xlatSvc.xlat("component.noOwnerListed");

                $scope.rowParseFunction = function (response) {
                    var equipments = response.result;

                    for (var r = 0; r < equipments.length; r++) {
                        equipments[r].onSite = equipments[r].siteId === vm.config.user.site.id;
                        equipments[r].canChangeStatus = equipments[r].canChangeStatus && equipments[r].onSite;
                    }

                    return response;
                };

                vm.criteria = {
                    filterValues: {
                        clientIds: [],
                        siteIds: [],
                        componentTypeId: null,
                        sizeIds: [],
                        manufacturerIds: [],
                        specificationIds: [],
                        componentOwnerIds: [],
                        locationIds: [],
                        serialNumber: null,
                        statusIds: [],
                        includeInactive: false,
                        defaultToAllSitesWhenNoSystemHierarchyFilter: true
                    }
                };

                $scope.$watch('vm.criteria.filterValues.clientIds.length', function (cnt) {
                    if (cnt === 0 && vm.criteria.filterValues.siteIds.length > 0) {
                        // reset the sites if the clients are cleared
                        vm.criteria.filterValues.siteIds.length = 0;
                    }
                });

                $scope.showVehicleDetails = function (item) {
                    if (item.fitmentData && item.fitmentData.vehicleId) {
                        WindowFactory.openItem({
                            component: 'vehicle-details',
                            caption: xlatSvc.xlat('equipment.openVehicle', item.fitmentData.formattedVehicleSerialNumber),
                            windowRelatedRecordId: item.fitmentData.vehicleId,
                            initParams: {
                                equipmentId: item.fitmentData.vehicleId,
                                siteId: item.siteId,
                                serialNumber: item.fitmentData.formattedVehicleSerialNumber,
                                wheelPostionId:
                                    item.fitmentData
                                        .wheelPositionId, //TODO: change the schematic js to select this position when the vehicle details open
                            },
                            width: 800,
                            height: 850,
                            modal: false
                        });
                    }
                };

                vm.status = "loading";
                vm.isDirty = true;

                // get the config from root scope, this contains things like units of measure
                vm.config = ocConfigSvc;

                vm.gridControl = {};
                vm.selectedItem = null;

                vm.onDatabound = function () {
                    vm.selectedItem = null;

                    vm.showActions = vm.gridControl.getTotalItemsCount() > 0;
                };

                vm.onSelectedItemChanged = function (items) {
                    if (items != null && items.length === 1) {
                        vm.selectedItem = items[0];
                    } else {
                        vm.selectedItem = null;
                    }
                };

                vm.setHeaderHeight = function (height, offset) {
                    vm.gridTop = height + offset;
                };

                vm.export = function () {
                    exportSvc.exportData(vm.apiUrls.searchExport, vm.criteria, xlatSvc.xlat('component.searchExportFilename'));
                };

                vm.loadDefaults = function () {
                    vm.status = 'loading';
                    var defaultCriteria = {
                        statusTypeNames: []
                    };

                    amtCommandQuerySvc.post(vm.apiUrls.getDefaults, defaultCriteria).then(function (response) {
                        if (response) {
                            var searchSerialNumber = localStorage.getItem("componentSerialNumber");
                            var searchByRim: boolean = localStorage.getItem('componentSearchByRim') === "Rim";
                            vm.defaultClients = response.clients;
                            vm.defaultSites = response.sites;
                            vm.defaultComponentType = searchByRim ? response.componentRimType : response.componentTyreType;
                            vm.statuses = response.statusTypeKeys;
                            vm.siteId = response.sites[0].key;
                            localStorage.setItem('componentSearchByRim', "undefined");
                            
                            if (localStorage.getItem("siteDashboardSearchby") == "INSPECTION") {
                                vm.defaultStatuses = _.filter(vm.statuses, s => {
                                    return s.name === localStorage.getItem("siteDashboardSearchby");
                                });
                                // delay this so that client is sorted and doesn't trigger a lookup of the site
                                setTimeout(function () {                                    
                                        vm.filter(true);                                    
                                    }
                                    , 3000);
                                localStorage.setItem("siteDashboardSearchby", "undefined");
                            };

                            if (searchSerialNumber !== null) {
                                vm.criteria.filterValues.serialNumber = searchSerialNumber;
                                localStorage.removeItem("componentSerialNumber");

                                // hold the trigger, until the client loaded
                                setTimeout(function () {
                                    vm.filter(true);
                                }, 3000);
                            }
                                
                            vm.status = "ready";

                            // focus on serial number field by default
                            vm.focusSerialNumber = true;
                        }
                    }).catch(function (error) {
                        vm.defaultErrors = [error];
                        vm.status = "error";
                        errorReporter.logError(error, 'ComponentSearch-LoadDefaults');
                    });
                };                

                vm.getComponentFailureReport = function (item) {
                    if (item && item.canGenerateComponentFailureReport)
                        reportSvc.downloadComponentFailureReports([item.manufacturerSerialNumber]);
                };

                function init() {
                    vm.loadDefaults();
                }

                $scope.onDataChanged = function () {
                    $scope.showComponentDetails(vm.selectedItem);
                };

                vm.refreshGrid = function (recompile) {
                    refreshWhenReady(recompile)
                }

                function refreshWhenReady(recompile) {
                    if (!vm.gridControl || !vm.gridControl.refresh || !vm.gridControl.recompile) {
                        setTimeout(function () { refreshWhenReady(recompile) });
                    } else {
                        if (recompile === true) {
                            vm.gridControl.recompile();
                        } else {
                            vm.gridControl.refresh();
                        }
                    }
                };

                vm.filter = function (recompile) {
                    vm.showClients = vm.criteria.filterValues.clientIds.length !== 1;
                    vm.showSites = vm.criteria.filterValues.siteIds.length !== 1;
                    vm.showFittedDetails = (vm.criteria.filterValues.filterOptions.indexOf("Show Fitted Details") !== -1);
                    vm.showLocations = (vm.criteria.filterValues.filterOptions.indexOf("Show Location") !== -1)
                    vm.showDestinations = (vm.criteria.filterValues.filterOptions.indexOf("Show Destination") !== -1)

                    if (!!vm.selectedComponentType) {
                        vm.showDepthColumns = [enums.equipmentTypes.tyre, enums.equipmentTypes.chain].indexOf(vm.selectedComponentType.name.toLowerCase()) > -1;
                        vm.showHoursSinceLastNdt = vm.selectedComponentType.name.toLowerCase() === enums.equipmentTypes.rim;
                    }

                    vm.refreshGrid(recompile);
                };

                vm.onComponentTypeChange = function (selectedItem) {
                    vm.selectedComponentType = selectedItem;
                };

                $scope.showComponentStatus = function (item) {

                    WindowFactory.openItem({
                        component: 'change-status-popup',
                        caption: xlatSvc.xlat('equipment.changeStatus'),
                        initParams: {
                            equipmentId: item.id,
                            equipmentEventId: item.equipmentEventId,
                            isChangeStatus: true,
                            isEdit: false,
                            isBulkMove: false,
                            siteId: item.siteId,
                            clientId: item.clientId
                        },
                        canClose: false,
                        width: 1400,
                        modal: true,
                        onDataChangeHandler: vm.filter(false)
                    });
                };

                $scope.showComponentDetails = function (item) {
                    WindowFactory.openItem({
                        component: 'component-details',
                        caption: xlatSvc.xlat('equipment.open' + item.equipmentTypeName, item.formattedSerialNumber),
                        windowRelatedRecordId: item.id,
                        initParams: {
                            equipmentId: item.id,
                            siteId: item.siteId,
                            componentType: item.equipmentTypeName,
                            serialNumber: item.formattedSerialNumber,
                            showCloseOnSave: false
                        },
                        width: 800,
                        height: 850,
                        onDataChangeHandler: vm.filter(false)
                    });
                };

                $scope.getComponentAudit = function (item) {

                    var report = reportSvc.getReport(ExportReports.componentAudit);

                    if (report) {

                        var parameters = [];

                        var serialNumber = item.manufacturerSerialNumber;

                        if (report.parameters && report.parameters.length > 0) {

                            parameters = reportSvc.constructBaseParameters(report);
                            parameters = reportSvc.constructParameter(parameters, report, 'SerialNumber', serialNumber);
                            parameters = reportSvc.constructParameter(parameters, report, 'EquipmentType', item.equipmentTypeName);
                        }

                        var filename = String.format("{0}-{1}-{2}-{3}", report.description || report.name, ocConfigSvc.user.site.name, item.formattedSerialNumber.split(".").join(""), ocDateSvc.toReportString(new Date()));

                        let exportCriteria: ExportReportCriteria = {
                            report: report,
                            provider: ExportProviders.pdf,
                            parameters: parameters,
                            filename: filename,
                            orientation: ExportOrientation.landscape
                        };

                        return reportSvc.exportReport(exportCriteria).catch(function (error) {
                            errorReporter.logError(error, 'ComponentSearch-ComponentAudit');
                        });

                    }
                };

                $scope.$on('filterSearch', function () {
                    vm.filter(false);
                });

                init();
            }
        ]);
