/**
 * @ngdoc directive
 * @name amtFramework.filter.directive:amtFilterPanel
 * @restrict E
 * 
 * @param {string} label Title to put in the section
 * @param {boolean} collapsed Boolean to set the initial state of the panel
 * @param {expression} applyFilterDisabled Boolean to disable the filters on the panel ???
 * @param {callback} onApplyFilters Callback that is called when the user clicks "Apply Filters"
 * @param {boolean} applyFilterHidden Boolean to hide the "Apply Filters" button and glyphicon
 *
 * @description
 * Creates a collapsible panel inside {@link amtFramework.filter.directive:amtFilter amtFilter}
 *
 * @example
 * See {@link amtFramework.filter.directive:amtFilter amtFilter}
 */
//import angular from 'angular';
import tmpl from './amtFilterPanel.html';

angular.module('amtFramework.filter').directive('amtFilterPanel', [
    'amtXlatSvc',
    function (xlatSvc) {
                
                return {
                    transclude: true,
                    restrict: 'E',
                    template: tmpl,
                    scope: {
                        label: '@',
                        collapsed: '@',
                        applyFilterDisabled: '=',
                        onApplyFilters: '&',
                        applyFilterHidden: '@'
                    },
                    controller: ['$scope', function ($scope) {
                        $scope.intapplyFilterHidden = true; 
                    }],
                    link: function (scope: any, elem, attr) {
                        scope.intApplyFilterDisabled = scope.applyFilterDisabled ? scope.applyFilterDisabled : false;
                        scope.intFilterCollapse = scope.collapsed || "true";
                        scope.intFilterCollapse = scope.intFilterCollapse === "true";
                        scope.intapplyFilterHidden = (attr.applyfilterhidden && attr.applyfilterhidden === "false") ? false : true;

                        //if label is not passed in then use the default
                        scope.intLabel = scope.label || xlatSvc.xlat("common.filter_label");

                        scope.filterToggle = function() {
                            scope.intFilterCollapse = !scope.intFilterCollapse;
                        };

                        scope.applyFilters = function() {
                            if (scope.onApplyFilters) {
                                scope.onApplyFilters();
                            }
                            //scope.intFilterCollapse = true;
                        };
                    }
                };
    }
]);
