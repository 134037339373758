/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("../../utils");
/**
 * @hidden
 */
var DragAndDropAssetService = /** @class */ (function () {
    function DragAndDropAssetService() {
    }
    Object.defineProperty(DragAndDropAssetService.prototype, "componentRef", {
        get: function () {
            if (!utils_1.isPresent(this._componentRef)) {
                throw new Error('The `initalize` method must be called before calling other service methods.');
            }
            return this._componentRef;
        },
        set: function (componentRef) {
            this._componentRef = componentRef;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DragAndDropAssetService.prototype, "element", {
        get: function () {
            return this.componentRef.location.nativeElement;
        },
        enumerable: true,
        configurable: true
    });
    DragAndDropAssetService.prototype.ngOnDestroy = function () {
        if (!utils_1.isPresent(this._componentRef)) {
            return;
        }
        this.element.parentElement.removeChild(this.element);
        this.componentRef.destroy();
        this.componentRef = null;
    };
    DragAndDropAssetService.prototype.show = function () {
        this.element.style.display = '';
    };
    DragAndDropAssetService.prototype.hide = function () {
        this.element.style.display = 'none';
    };
    DragAndDropAssetService.prototype.move = function (left, top, offset) {
        if (offset === void 0) { offset = 0; }
        this.element.style.left = left + offset + "px";
        this.element.style.top = top + offset + "px";
    };
    return DragAndDropAssetService;
}());
exports.DragAndDropAssetService = DragAndDropAssetService;
