/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var filterable_component_1 = require("./filterable-component");
var util_1 = require("../util");
var DEFAULT_FILTER_SETTINGS = {
    caseSensitive: false,
    operator: 'startsWith'
};
/**
 * Implements an event handler for the `filterChange` event of a DropDowns component
 * which performs simple data filtering.
 *
 * @example
 * ```ts
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-autocomplete
 *      [data]="data"
 *      kendoDropDownFilter
 *      placeholder="e.g. Andorra"
 *  >
 *  </kendo-autocomplete>
 * `
 * })
 * class AppComponent {
 *     public data: Array<string> = ["Albania", "Andorra", "Armenia", "Austria", "Azerbaijan"];
 * }
 * ```
 * > Currently, the built-in filtering does not work with [grouped data]({% slug api_kendo-data-query_groupby %}).
 */
var FilterDirective = /** @class */ (function () {
    function FilterDirective(component) {
        this.component = component;
        /**
         * @hidden
         *
         * Sets whether the filtering functionality is enabled on component init.
         */
        this.filterable = true;
        this._data = [];
    }
    Object.defineProperty(FilterDirective.prototype, "data", {
        get: function () {
            return this._data;
        },
        /**
         * The initial data that will be used as a source array for the filtering operations.
         */
        set: function (data) {
            this._data = data || [];
        },
        enumerable: true,
        configurable: true
    });
    FilterDirective.prototype.ngOnInit = function () {
        this.component.filterable = this.filterable;
        this.filterChangeSubscription = this.component.filterChange
            .subscribe(this.handleFilterChange.bind(this));
    };
    FilterDirective.prototype.ngOnDestroy = function () {
        if (util_1.isPresent(this.filterChangeSubscription)) {
            this.filterChangeSubscription.unsubscribe();
        }
    };
    FilterDirective.prototype.handleFilterChange = function (query) {
        var _this = this;
        this.component.data = this.data.filter(function (item) { return _this.matchesAnyField(item, query); });
    };
    FilterDirective.prototype.matchesAnyField = function (item, query) {
        var _this = this;
        var normalizedQuery = this.normalizeValue(query);
        var fields = this.filterSettings.fields;
        // if no filter fields are present, we are dealing with primitive data
        if (fields.length === 0) {
            return this.checkItem(item, normalizedQuery);
        }
        return fields.some(function (field) { return _this.checkItem(util_1.getter(item, field), normalizedQuery); });
    };
    FilterDirective.prototype.checkItem = function (target, query) {
        target = this.normalizeValue(target);
        if (this.filterSettings.operator === 'contains') {
            return target.indexOf(query) !== -1;
        }
        else {
            return target.indexOf(query) === 0;
        }
    };
    FilterDirective.prototype.normalizeValue = function (value) {
        var normalizedValue = util_1.isPresent(value) ? value.toString() : '';
        return this.filterSettings.caseSensitive ? normalizedValue : normalizedValue.toLowerCase();
    };
    FilterDirective.prototype.getFilterFields = function (providedFields) {
        // ignore provided fields if the component deals with primitive data
        if (!this.component.textField && !this.component.valueField) {
            return [];
        }
        if (util_1.isArray(providedFields) && providedFields.length > 0) {
            return providedFields;
        }
        else {
            // the autocomplete uses `valueField` for text extraction
            var textField = this.component.textField || this.component.valueField;
            return [textField];
        }
    };
    Object.defineProperty(FilterDirective.prototype, "filterSettings", {
        get: function () {
            var settings = this.rawSettings;
            var providedFields = util_1.isPresent(settings) && typeof settings === 'object' ? settings.fields : [];
            return Object.assign({}, DEFAULT_FILTER_SETTINGS, settings, { fields: this.getFilterFields(providedFields) });
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Array),
        tslib_1.__metadata("design:paramtypes", [Array])
    ], FilterDirective.prototype, "data", null);
    tslib_1.__decorate([
        core_1.Input('kendoDropDownFilter'),
        tslib_1.__metadata("design:type", Object)
    ], FilterDirective.prototype, "rawSettings", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], FilterDirective.prototype, "filterable", void 0);
    FilterDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoDropDownFilter]'
        }),
        tslib_1.__metadata("design:paramtypes", [filterable_component_1.FilterableComponent])
    ], FilterDirective);
    return FilterDirective;
}());
exports.FilterDirective = FilterDirective;
