/**
 * adapted from http://trochette.github.com/
 */
//import angular from 'angular';


    angular.module('app.directives')
        .directive('ocActivity', ['$rootScope', function ($rootScope) {
            return {
                restrict: 'A',
                link: function ($scope, $element, $attrs) {
                    var activity = function(){
                        $rootScope.$broadcast('OC.Activity');
                    };
                    
                    $element.on('mousemove', activity);
                    $element.on('touchstart', activity);
                }
            };
        }
        ]);
