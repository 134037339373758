//import angular from 'angular';
import tmpl from './uploadOptions.html';



    angular.module('app.mobile').component('uploadOptions', { 
	    bindings: {
		    initParams: '=',
		    buttonMethods: '=',
		    buttonStates: '=',
		    buttons: '=',
		    closeOnSave: '=',
		    wnd: '='
        },
        template: tmpl,
        controller: ['$scope', 'WindowFactory', 'enums',
			function ($scope, WindowFactory: IWindowFactory, enums) {
				var vm = this;
				$scope.vm = this;
				
				this.$onInit = function () {
					vm.buttons = [
						{
							primary: true, 
							cancel: false, 
							value: 'mobileCommon.upload',
							name: 'uploadButton',
							click: 'upload',
							type: enums.buttonTypes.button
						},
						{
							primary: false, 
							cancel: true,
							value: 'framework.cancel_label',
							name: 'cancelButton',
							click: 'cancel',
							type: enums.buttonTypes.button
						}
					];
					
					vm.buttonStates = {
						uploadButton: {
							visible: true,
							disabled: false
						},
						cancelButton: {
							visible: true, 
							disabled: false
						}
					};
					
					vm.buttonMethods = {
						upload: vm.upload,
						cancel: vm.cancel
					};
				};
		
				vm.cancel = function () {
					WindowFactory.closeWindow(vm.wnd);            
				};
		
				vm.upload = function () {
					vm.wnd.onDataChanged(vm.initParams.downloadReferenceData);
				};
		
				return this;
    		}
		]
    });
