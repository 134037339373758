//import angular from 'angular';
import tmpl from './vehicleValidation.html';


    angular.module("app.fieldSurveys").component('vehicleValidation', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
		controller: ['WindowFactory', '$scope', 'amtXlatSvc', 'enums',
			function (WindowFactory: IWindowFactory, $scope, amtXlatSvc: IAmtXlatSvc, enums) {
                var vm = this;
                $scope.vm = this;
                
                this.$onInit = function () {
                    angular.extend(vm, vm.wnd.initParams);
					vm.buttons = [
						{
							primary: true, 
							cancel: false,
							value: 'framework.close_label',
							name: 'closeButton',
							click: 'close',
							type: enums.buttonTypes.button
						},
						{
							primary: false, 
							cancel: false, 
							value: 'fieldSurvey.updateSerial',
							name: 'updateButton',
							click: 'update',
							type: enums.buttonTypes.button
						}/*,
						{
							primary: false, 
							cancel: false,
							value: 'common.allow',
							name: 'allowButton',
							click: 'allow',
							type: enums.buttonTypes.button
						}*/
					];
					
					vm.buttonStates = {
						closeButton: {
							visible: true, 
							disabled: false
						},
						updateButton: {
							visible: true, 
							disabled: true
						}
						/*allowButton: {
							visible: true,
							disabled: false
						}*/
					};
					
					vm.buttonMethods = {
						close: vm.close,
						update: vm.update
						//allow: vm.allow
					};
				};
                            
                var name = "tyre"; //TODO: xlat this?
                vm.currentSerial = amtXlatSvc.xlat('fieldSurvey.CurrentSerial', name);
                vm.serialChange = amtXlatSvc.xlat('fieldSurvey.SerialChange', name);
    
                /*vm.allow = function () {
                    WindowFactory.closeWindow(vm.wnd);
                    vm.wnd.onDataChanged(true);
                };*/
    
                vm.close = function () {
                    WindowFactory.closeWindow(vm.wnd);
                    vm.wnd.onDataChanged(/*false*/);
                };
    
                vm.update = function () {
                    WindowFactory.closeWindow(vm.wnd);
                    vm.wnd.onDataChanged(/*true, */vm.newSerial);
                };
                
                $scope.$watch('vm.newSerial', function () {
					vm.buttonStates.updateButton.disabled = !vm.newSerial;
				});
            }
        ]
    });
