/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Creates a loading template that overrides the default loading indicator of the Grid Component.
 * To define a loading template, nest an `<ng-template>` tag with the `kendoGridLoadingTemplate` directive inside the `<kendo-grid>` tag
 * [see example]({% slug databinding_grid %}#toc-loading-template).
 */
var LoadingTemplateDirective = /** @class */ (function () {
    function LoadingTemplateDirective(templateRef) {
        this.templateRef = templateRef;
    }
    LoadingTemplateDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoGridLoadingTemplate]'
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.TemplateRef])
    ], LoadingTemplateDirective);
    return LoadingTemplateDirective;
}());
exports.LoadingTemplateDirective = LoadingTemplateDirective;
