//import angular from 'angular';
import { PurchaseOrderLoadState } from './purchaseOrderDetailsCtrl';
import Plotly from 'plotly.js-dist';
import OcDateSvc from '../../../services/ocDateSvc';
import AdminUrls from '../../admin/adminUrls';
import tmpl from "./purchaseOrderSearch.html";


interface PurchaseOrderSearchScope extends ng.IScope {
    showDetails: (any) => void;
}

class PurchaseOrderSearchCtrl implements ng.IComponentController {
    public showClients = false;
    public showSites = false;

    public defaultClients: any[];
    defaultSites: any[];
    defaultComponentType: any[];
    defaultPurchaseOrderStatusTypes: any[];

    public criteria = {
        filterValues: {
            clientIds: [],
            siteIds: [],
            specificationIds: [],
            purchaseOrderNumber: null,
            componentTypeId: null,
            startDate: this.ocDateSvc.halfStart(this.ocDateSvc.now()),
            endDate: null
        },
        atDate: this.ocDateSvc.now()
    };

    public pickerEndDate = null;

    public status: PurchaseOrderLoadState = PurchaseOrderLoadState.loading;
    public defaultError: string;
    public isDirty = true;

    public chartStatus: PurchaseOrderLoadState = PurchaseOrderLoadState.loading;

    public gridControl: { refresh?: ()=>void } = {};
    public selectedItem = null;

    public gridTop: string;

    public focusPurchaseOrderNumber = true;

    public readonly chartMinScaleY = 10;
    public readonly chartMonthCountMax = 6;
    public readonly chartId = 'purchaseOrderChart';

    static $inject = ['$scope', '$window', '$timeout', 'amtXlatSvc', 'componentUrls', 'referenceDataUrls', 'adminUrls',
        'WindowFactory', 'ocConfigSvc', 'ocDateSvc', 'amtCommandQuerySvc',
        '$element', 'errorReporter', 'confirmSvc', 'notifySvc'];
    constructor(private $scope: PurchaseOrderSearchScope, private $window: ng.IWindowService, private $timeout: ng.ITimeoutService, private xlatSvc: IAmtXlatSvc, private apiUrls: IComponentUrls, private referenceDataUrls: IReferenceDataUrls, private adminUrls: AdminUrls,
        private WindowFactory: IWindowFactory, private ocConfigSvc: IOcConfigSvc, private ocDateSvc: OcDateSvc, private amtCommandQuerySvc: IAmtCommandQuerySvc,
        private $element: JQuery<HTMLElement>, private errorReporter: IErrorReporter, private confirmSvc: IConfirmSvc, private notifySvc: INotifySvc) {
    }

    $onInit() {
        this.$scope.showDetails = o => this.showDetails(o);

        this.loadDefaults();

        //size the chart using our knockoff media query sizing method and then bind to window resize events
        this.sizeChart();
        angular.element(this.$window).on('resize', this.sizeChart);

        this.$scope.$watch(()=>this.criteria.filterValues.clientIds.length, cnt => {
            if (cnt === 0 && this.criteria.filterValues.siteIds.length > 0) {
                // reset the sites if the clients are cleared
                this.criteria.filterValues.siteIds.length = 0;
            }
        });
    }

    $onDestroy() {
        angular.element(this.$window).off('resize', this.sizeChart);
    }

    rowParseFunction(response) {
        for (let row of response.result) {
            row.onSite = row.siteId === this.ocConfigSvc.user.site.id;
            row.actualVsExpected = Math.trunc(row.actualVsExpected * 100) + "%"
        }

        return response;
    }

    async loadDefaults() {
        this.status = PurchaseOrderLoadState.loading;

        try {
            let response = await this.amtCommandQuerySvc.post(this.apiUrls.getPurchaseOrderSearchDefaults, { statusTypeNames: [] });
            if (!response)
                return;

            this.defaultClients = response.clients;
            this.defaultSites = response.sites;
            this.defaultComponentType = response.componentType;
            this.defaultPurchaseOrderStatusTypes = response.purchaseOrderStatusTypes;

            this.status = PurchaseOrderLoadState.ready;

            //load the chart here as it needs the defaults to know what to display for the user
            this.loadChart(this.chartCriteriaFromDefaults(true));
        } catch (error) {
            this.defaultError = error.message;
            this.status = PurchaseOrderLoadState.error;
            this.errorReporter.logError(error, 'ComponentSearch-LoadDefaults');
        }
    }

    async loadChart(criteria) {
        //if there is already a chart then delay showing a spinner for 1000ms a less distracting experience
        var delaySpinner = null;
        if (this.chartStatus == PurchaseOrderLoadState.ready) {
            this.chartStatus = PurchaseOrderLoadState.loadingBackground;
            this.$timeout(() => {
                if (this.chartStatus == PurchaseOrderLoadState.loadingBackground) this.chartStatus = PurchaseOrderLoadState.loading }, 1000);
        }

        try {
            let response = await this.amtCommandQuerySvc.post(this.apiUrls.getPurchaseOrderMonthCounts, criteria);
            //user may have navigated away before we get a response, make sure we have an element before waste any more effort on the graph
            //TODO: we should probably handle this better ie. cancel the promise & ajax request when controller gets $destroy and treat this as a non-error
            this.$timeout.cancel(delaySpinner);

            let chartElement = document.getElementById(this.chartId);
            if (!response || !chartElement)
                return;

            let chartConfig: Partial<Plotly.Config> = { displayModeBar: false, responsive: true, autosizable: true, staticPlot: true };
            let chartMonthNames: string[] = [];
            let startDate: Date = new Date(response.startDate);
            let startMonth: number = startDate.getMonth();
            let monthNames: string[] = kendo.cultures.current.calendars.standard.months.namesAbbr;
            let monthCount: number = Math.min(response.scheduledDeliveries.length, response.actualDeliveries.length, this.chartMonthCountMax);

            let scheduledDeliveries = response.scheduledDeliveries.slice(0, monthCount);
            let actualDeliveries = response.actualDeliveries.slice(0, monthCount);
            let yscale = Math.max(this.chartMinScaleY, Math.max(...scheduledDeliveries), Math.max(...actualDeliveries));

            for (let i = startMonth; i < monthCount + startMonth; ++i) {
                chartMonthNames.push(monthNames[i % monthNames.length]);
            }

            let chartLayout: Partial<Plotly.Layout> = {
                barmode: 'group',
                autosize: true,
                margin: { l: 20, r: 20, b: 20, t: 20, pad: 4 },
                xaxis: { autorange: true },
                yaxis: { range: [0, yscale], automargin: true }
            };

            let chartData: Partial<Plotly.PlotData>[] = [
                {
                    name: this.xlatSvc.xlat('equipment.purchaseOrderGraphScheduledDeliveries'),
                    type: 'bar',
                    x: chartMonthNames,
                    y: scheduledDeliveries,
                    marker: {
                        color: '#97D700',
                        line: { color: '#71A100' }
                    }
                }, {
                    name: this.xlatSvc.xlat('equipment.purchaseOrderGraphActualDeliveries'),
                    type: 'bar',
                    x: chartMonthNames,
                    y: actualDeliveries,
                    marker: {
                        color: '#548BD4',
                        line: { color: '#3F689F' }
                    }
                }
            ];

            Plotly.newPlot(chartElement, chartData, chartLayout, chartConfig);

            this.chartStatus = PurchaseOrderLoadState.ready;

        } catch (error) {
            this.$timeout.cancel(delaySpinner);
            this.defaultError = error.message;
            this.chartStatus = PurchaseOrderLoadState.error;
            this.errorReporter.logError(error, 'ComponentSearch-LoadDefaults');
        }
    }

    public chartCriteriaFromDefaults(criteriaNotInitialisedAndFiltered = false) {
        let chartCriteria: any = angular.copy(this.criteria);

        try {
            chartCriteria.filterValues = chartCriteria.filterValues || {};
            chartCriteria.filterValues.componentTypeId = chartCriteria.filterValues.componentTypeId || this.defaultComponentType['key'];


            if (criteriaNotInitialisedAndFiltered) {
                let startDate = chartCriteria.filterValues.startDate || this.ocDateSvc.halfStart(this.ocDateSvc.now());
                chartCriteria.filterValues.startDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), 1));

                if ((!Array.isArray(chartCriteria.filterValues.clientIds) || chartCriteria.filterValues.clientIds.length < 1)) {
                    let clientIds: string[] = [];
                    angular.forEach(this.defaultClients, v => clientIds.push(v['key']));
                    chartCriteria.filterValues.clientIds = clientIds;
                }

                if ((!Array.isArray(chartCriteria.filterValues.siteIds) || chartCriteria.filterValues.siteIds.length < 1)) {
                    let siteIds: string[] = [];
                    angular.forEach(this.defaultSites, v => siteIds.push(v['key']));
                    chartCriteria.filterValues.siteIds = siteIds;
                }
            }


        }
        catch (err) { } //ignore failure setting initial criteria from loaded defaults

        return chartCriteria;
    }

    onSelectedItemChanged(items) {
        this.selectedItem = items != null && items.length == 1 ? items[0] : null;
    }

    setHeaderHeight(height, offset) {
        this.gridTop = (height + offset + 22) + 'px';
    }

    async deletePurchaseOrder(selected) {
        let id = selected.id;
        if (!id)
            return;
        try {
            await this.confirmSvc.confirmMessage('purchaseOrdersEdit.ConfirmDelete_title', 'purchaseOrdersEdit.ConfirmDelete', selected.purchaseOrderNumber);
            let response = await this.amtCommandQuerySvc.post(this.apiUrls.deletePurchaseOrder, { id: id });
            this.notifySvc.successXlat('purchaseOrdersEdit.DeleteSucceeded');
            this.WindowFactory.closeWindowbyRelatedRecordId(id);
            this.refreshGrid();
            this.loadChart(this.chartCriteriaFromDefaults());
        } catch (error) {
            this.errorReporter.logError(error);
        }
    }

    refreshGrid() {
        if (this.gridControl)
        this.gridControl.refresh();
    }

    onDatabound() {
        this.selectedItem = null;
    }

    filter() {
        this.criteria.atDate = this.ocDateSvc.dateTimeAsUTC(this.ocDateSvc.now());
        this.showClients = this.criteria.filterValues.clientIds.length !== 1;
        this.showSites = this.criteria.filterValues.siteIds.length !== 1;

        let startDate = this.criteria.filterValues.startDate;
        let endDate = this.pickerEndDate;

        if (endDate != null) { //if there's an end date selected round it to the start of that month
            endDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), 1))
        }

        //if the user has cleared start and end date it likely wasn't to see POs from all time, so reinit the startDate (they should enter/pick at least one date)
        if (startDate == null && endDate == null) {
            startDate = this.ocDateSvc.halfStart(this.ocDateSvc.now());
        }

        if (startDate != null) {
            //if there's a start date, round it to the start of that month
            startDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), 1));

            //if the endDate selected is less than the start date selected we should switch them (note endDate is used later and needs to be switched in addition to the picker)
            if (endDate != null && endDate < startDate) {
                this.criteria.filterValues.startDate = endDate;
                this.pickerEndDate = endDate = startDate;
            } else {
                this.criteria.filterValues.startDate = startDate;
            }
        }

        //filter sent to server is the start date of the month after the month the user has selected
        this.criteria.filterValues.endDate = (endDate != null) ? new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth() + 1, 1)) : null;

        this.refreshGrid();
        this.loadChart(this.chartCriteriaFromDefaults());
    }

    createPurchaseOrder() {
        this.WindowFactory.openItem({
            component: 'purchase-order-details',
            caption: this.xlatSvc.xlat('equipment.purchaseOrderCaptionCreate'),
            initParams: {
                //siteId: ocConfigSvc.user.site.id,
                //showCloseOnSave: true,
                mode: 'create',
                componentTypeId: this.criteria.filterValues.componentTypeId
            },
            width: 1000,
            height: 600,
            onDataChangeHandler: () => this.filter()
        });
    }

    mergePurchaseOrder(item) {
        this.WindowFactory.openItem({
            component: 'purchase-orders-merge',
            caption: this.xlatSvc.xlat('equipment.purchaseOrdersMerge'),
            initParams: {
                purchaseOrder: item
            },
            width: 600,
            modal: true,
            onDataChangeHandler: () => this.filter()
        });
    }

    showDetails(item) {
        this.WindowFactory.openItem({
            component: 'purchase-order-details',
            caption: this.xlatSvc.xlat('equipment.purchaseOrderCaption', item.purchaseOrderNumber),
            windowRelatedRecordId: item.id,
            initParams: {
                purchaseOrderId: item.id,
                siteId: item.siteId,
                purchaseOrderNumber: item.purchaseOrderNumber,
                mode: 'edit',
                componentTypeId: this.criteria.filterValues.componentTypeId
            },
            width: 1000,
            height: 600,
            onDataChangeHandler: () => this.filter()
        });
    }

    //used to set classes as Plotly our chart library's 'responsive' support isn't very responsive and doesn't seem to support things like CSS media queries
    sizeChart() {
        let width = this.$window.document.body.clientWidth;
        let el = angular.element('.purchaseOrderChartSearchContainer');

        el.toggleClass('xs', width < 768);
        el.toggleClass('sm', width >= 768 && width < 992);
    }

    //TODO: currently unused - not implemented serverside (action item commented out in HTML template)
    //export() {
    //    exportSvc.exportData(this.apiUrls.searchExport, this.criteria, xlatSvc.xlat('equipment.purchaseOrderSearchExportFilename'));
    //}
}

angular.module('app.site').component('purchaseOrderSearch', {
    template: tmpl,
    controllerAs: "vm",
    controller: PurchaseOrderSearchCtrl
});