/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_treeview_1 = require("@progress/kendo-angular-treeview");
/**
 * A directive which manages the expanded state of the popup TreeView.
 */
var DropDownTreesExpandDirective = /** @class */ (function (_super) {
    tslib_1.__extends(DropDownTreesExpandDirective, _super);
    function DropDownTreesExpandDirective(dropDownTree) {
        return _super.call(this, dropDownTree) || this;
    }
    tslib_1.__decorate([
        core_1.Input('isNodeExpanded'),
        tslib_1.__metadata("design:type", Function)
    ], DropDownTreesExpandDirective.prototype, "isExpanded", void 0);
    DropDownTreesExpandDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoDropDownTreeExpandable], [kendoMultiSelectTreeExpandable]'
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_treeview_1.ExpandableComponent])
    ], DropDownTreesExpandDirective);
    return DropDownTreesExpandDirective;
}(kendo_angular_treeview_1.ExpandDirective));
exports.DropDownTreesExpandDirective = DropDownTreesExpandDirective;
