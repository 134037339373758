/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Specifies any media that will be displayed and aligned in the Card.
 */
var CardMediaDirective = /** @class */ (function () {
    function CardMediaDirective() {
        this.hostClass = true;
    }
    tslib_1.__decorate([
        core_1.HostBinding('class.k-card-media'),
        tslib_1.__metadata("design:type", Boolean)
    ], CardMediaDirective.prototype, "hostClass", void 0);
    CardMediaDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoCardMedia]'
        })
    ], CardMediaDirective);
    return CardMediaDirective;
}());
exports.CardMediaDirective = CardMediaDirective;
