/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var numerictextbox_component_1 = require("./numerictextbox/numerictextbox.component");
var common_1 = require("@angular/common");
var localized_numerictextbox_messages_directive_1 = require("./numerictextbox/localization/localized-numerictextbox-messages.directive");
var custom_messages_component_1 = require("./numerictextbox/localization/custom-messages.component");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the NumericTextBox component.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the NumericTextBox module
 * import { NumericTextBoxModule } from '@progress/kendo-angular-inputs';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, NumericTextBoxModule], // import NumericTextBox module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
var NumericTextBoxModule = /** @class */ (function () {
    function NumericTextBoxModule() {
    }
    NumericTextBoxModule = tslib_1.__decorate([
        core_1.NgModule({
            declarations: [
                localized_numerictextbox_messages_directive_1.LocalizedNumericTextBoxMessagesDirective,
                numerictextbox_component_1.NumericTextBoxComponent,
                custom_messages_component_1.NumericTextBoxCustomMessagesComponent
            ],
            exports: [
                numerictextbox_component_1.NumericTextBoxComponent,
                custom_messages_component_1.NumericTextBoxCustomMessagesComponent
            ],
            imports: [common_1.CommonModule, kendo_angular_common_1.EventsModule]
        })
    ], NumericTextBoxModule);
    return NumericTextBoxModule;
}());
exports.NumericTextBoxModule = NumericTextBoxModule;
