//import angular from 'angular';
import BrowserSvc from '../../../services/browserSvc';
import tmpl from "./stockRow.html";
import tmplMobile from "./stockRowMobile.html";



angular.module('app.stocktake').component('stockRow', {
        template: ['browserSvc', (browserSvc: BrowserSvc) => browserSvc.isMobile ? tmplMobile : tmpl ],
        bindings: {
            header: '@',
            element: '<',
            rowId: '<',
            activeItem: '<',
            isMobile: "=",
            selected: '&',
            preferredLevel: '<',
            recalcParent: "&",
            minValue: "@",
            maxValue: "@",
            level: '<'
        },
        transclude: true,
        controller: [
            "$timeout", '$scope',
            function stockRowCtrl($timeout, $scope) {
                var ctrl = this;
                ctrl.id = $scope.$id;

                ctrl.$onInit = function () {
                    if (!ctrl.element.expected && ctrl.element.expected !== 0) {
                        ctrl.element.expected = 1;
                    }

                    if (ctrl.element.deleted) {
                        ctrl.element.actual =
                            '-'; // the way angular works putting a space here wont pass to rendering, since it is not a 'truthy' value.
                    }
                    $scope.$emit('stockRow', ctrl.rowId, ctrl.level);
                };

                ctrl.textClicked = function (e) {
                    e.preventDefault();
                    e.stopPropagation();

                    ctrl.active = true;
                    ctrl.selected({
                        rowId: ctrl.rowId
                    });

                    center();
                };

                ctrl.focus = () => {
                    var txt = $("#txt" + ctrl.id);

                    if (txt && txt[0]) {
                        txt[0].focus();
                    }
                };

                ctrl.valueChange = function () {
                    // recalculate this utem
                    this.recalcParent({ rowId: this.rowId } );
                };

                ctrl.$onChanges = changesObj => {
                    if (changesObj.activeItem) {
                        if (changesObj.activeItem.currentValue === this.rowId) {
                            if (!this.active) {
                                this.active = true;

                                if (changesObj.activeItem.isFirstChange() ||
                                    !changesObj.activeItem.previousValue ||
                                    (changesObj.activeItem.previousValue.indexOf(changesObj.activeItem.currentValue) !== 0)) {
                                    // became active - need to ensure we are expanded and visible.
                                    console.log(this.rowId + ': => Active');
                                    $scope.$emit('dropContentActive', this.rowId);
                                    this.selected({ rowId: this.rowId });
                                }
                                center();

                                if (ctrl.isMobile === false) {
                                    $timeout(function () {
                                        ctrl.focus();
                                    });
                                }
                            }
                        } else {
                            this.active = false;
                        }
                    }
                };

                var autoCenter = false; // disable auto-centering for OR-8445

                function center() {
                    if (autoCenter) { 
                        if (ctrl.isMobile) {
                            $timeout(function () {
                                var item = $('#' + ctrl.id);
                                if (item.length > 0) {
                                    var center = $('#stockGrid').height() / 2;
                                    var inner = $('#stockGridInner');
                                    // offset of this from top                                 
                                    var offsetItem = item.offset().top;
                                    var move = offsetItem - center;
                                    inner.stop(true, true).animate({ scrollTop: inner.scrollTop() + move }, '500');
                                    //inner.scrollTop();
                                }
                            });
                        }
                    }
                }
            }
        ]
    });
