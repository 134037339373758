//import angular from 'angular';
import tmpl from './details.html';



angular.module('app.admin').component('addEditCurrency', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: [
            "$scope", "confirmSvc", "enums", "currencySvc", "WindowFactory", "ocSecuritySvc",
            "notifySvc", "amtXlatSvc", "errorReporter", "ocConfigSvc",
            function (
                $scope, confirmSvc, enums, currencySvc, WindowFactory, ocSecuritySvc,
                notifySvc, amtXlatSvc, errorReporter, ocConfigSvc
            ) {
                var vm = this;
                $scope.vm = this;

                vm.svc = currencySvc;
                vm.config = ocConfigSvc;
                vm.screenStatusEnums = enums.screenStatus;

                // pass along dirty flag to the window for use on closing minimised windows
                $scope.$watch('vm.form.$dirty', function () {
                    vm.wnd.isDirty = vm.form.$dirty;
                });

                this.$onInit = function () {
                    vm.status = vm.screenStatusEnums.loading;

                    if (vm.wnd !== null) {
                        vm.wnd.onClose = vm.cancel;
                    }

                    vm.item = vm.initParams;

                    if (vm.initParams.mode !== 'add') {
                        // load the details
                        getDetails();                        
                    } else {
                        // add
                        vm.wnd.processing = false;
                        vm.status = vm.screenStatusEnums.ready;
                    }
                    
                    WindowFactory.addButton(
                        vm,
                        'common.save_label',
                        'save',
                        vm.save,
                        true
                    );
                }

                $scope.$watch("vm.readonly", function () {
                    if (vm.buttonStates && vm.buttonStates.save) {
                        vm.buttonStates.save.visible = !vm.readonly;
                    }
                });

                $scope.$watchGroup(["vm.form.$dirty", "vm.form.$invalid"], function () {
                    if (vm.buttonStates && vm.buttonStates.save) {
                        vm.buttonStates.save.disabled = !vm.form.$dirty || vm.form.$invalid;
                    }
                });

                vm.setPristine = function () {
                    if (vm.form) {
                        vm.form.$setPristine();
                    }
                };

                vm.save = function () {
                    
                    vm.wnd.processing = true;

                    vm.svc.saveAsync(vm.item).then(function (response) {
                        notifySvc.success(amtXlatSvc.xlat(vm.svc.resourceGroup + '.saveSuccessful'));

                        if (vm.wnd.onDataChanged) {
                            // refresh the search grid
                            vm.wnd.onDataChanged();
                        }

                        // close the window
                        vm.closeWindow();
                    }).catch(function (error) {
                        errorReporter.logError(error);
                    }).finally(function () {
                        vm.wnd.processing = false;
                    });
                };

                vm.closeWindow = function () {
                    WindowFactory.closeWindow(vm.wnd);
                };

                vm.cancel = function () {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        vm.form.dirty = false;
                        vm.closeWindow();
                        return true;
                    });

                    return false;
                };

                function getDetails() {
                    vm.wnd.processing = true;

                    return vm.svc.getAsync(vm.item.id).then(function (response) {
                        vm.item = response.data;

                        vm.readonly = (vm.item.id && !ocSecuritySvc.isAuthorised(vm.svc.mainPermission, 'readWrite'));

                        vm.item.applyToAllLanguages = true; // default to change all languages

                        vm.wnd.processing = false;
                        vm.status = vm.screenStatusEnums.ready;
                    }).catch(function (error) {
                        errorReporter.logError(error);
                        vm.wnd.processing = false;
                        vm.status = vm.screenStatusEnums.error;
                    });
                };
            }
        ]
    });
