//import angular from 'angular';
import { UpdateForecastOverrideModelMaker } from './updateForecastOverrideCtrl';


angular.module("app.site").controller("forecastDetailsController", forecastDetailsController);

    // @ts-ignore
    forecastDetailsController.$inject = ["$scope", "$state", "$rootScope", "amtConstants", "amtCommandQuerySvc", "WindowFactory",
        "amtXlatSvc", "$timeout", "helperSvc", "enums", "errorReporter", "notifySvc", "confirmSvc", "ocConfigSvc", "exportSvc", "forecastingSvc"];

    // ReSharper disable once InconsistentNaming
    function forecastDetailsController($scope, $state, $rootScope, amtConstants, amtCommandQuerySvc, WindowFactory,
        amtXlatSvc, $timeout, helperSvc, enums, errorReporter, notifySvc, confirmSvc, ocConfigSvc, exportSvc, forecastingSvc) {

        var vm = this;

        vm.apiUrls = amtConstants.url;
        vm.config = ocConfigSvc;

        vm.loading = true;
        vm.loadingSummary = true;

        vm.gridControlVehicles = {};
        vm.gridControlTyres = {};
        vm.gridControlRims = {};
        vm.gridControlRimsDays = {};
        vm.gridControlRimOrders = {};
        vm.gridControlTyreOrders = {};
        vm.gridControlNewTyreChangeOutForecast = {};
        vm.gridControlSpareTyreHighHoursForecast = {};
        vm.gridControlRimNDTForecast = {};
        vm.gridControlRimScrapForecast = {};

        vm.gridControlSummary = {};
        vm.gridControlSummaryTyre = {};
        vm.gridControlSummaryRim = {};

        vm.showTyresDashboard = false;
        vm.showRimsDashboard = false;
        vm.showSummaryExportButton = false;

        vm.alignCenter = { "style": "text-align: center; vertical-align: middle;" };
        vm.styleBold = { "style": "font-weight: bold" };

        vm.forecastId = $rootScope.$stateParams.forecastid ? $rootScope.$stateParams.forecastid : 0;
        vm.threeMonth = "";
        vm.twelveMonth = "";

        vm.filterValues = {
            forecastId: vm.forecastId
        };

        vm.NewTyreChangeoutfilterValues = {
            forecastId: vm.forecastId,
            dashboardTypes: "NT,NTS"
        };

        vm.SpareTyreHightHoursForecastFilterValues = {
            forecastId: vm.forecastId,
            dashboardTypes: "STS,NTS"
        };

        this.$onInit = function () {
            vm.getForecastDetails(vm.forecastId).then(function () {
                vm.getColumns();
                vm.showDashboard(forecastingSvc.getActiveTabName(), false);
            }).finally(function () {
                vm.loading = false;
            });
        };

        vm.recalculateForecast = function () {

            WindowFactory.openItem({
                component: 'recalculate-forecast',
                canMinimise: false,
                modal: true,
                canClose: false,
                width: 650,
                initParams: {
                    forecastId: vm.forecastId,
                },
                caption: amtXlatSvc.xlat('forecastDetails.recalculateForecast'),
                onDataChangeHandler: () => {
                    refreshAllGrids();

                    if (vm.currentTab == 'Summary')
                        vm.showDashboard('Summary', true);                    
                }
            });
        };

        vm.RimNDTForecastfilterValues = {
            forecastId: vm.forecastId,
            dashboardTypes: "NDT"
        };

        vm.rimScrapForecastFilterValues = {
            forecastId: vm.forecastId,
            dashboardTypes: "RS"
        };

        vm.getColumns = function () {

            if (!vm.liveForecast) {

                return amtCommandQuerySvc.get(vm.apiUrls.getDashboardForecastMonths, { forecastId: vm.forecastId }).then(function (response) {

                    if (!response || !response.data) {
                        return;
                    }

                    vm.gridColumns = response.data;

                    vm.gridThreeMonthForecastColumns = ["forecastVehicles.threeMonthForecast"];
                    vm.gridTweleveMonthForecastColumns = ["forecastVehicles.tweleveMonthForecast"];
                    vm.threeMonth = vm.gridColumns[2].yearMonthForDisplay;
                    vm.twelveMonth = vm.gridColumns[11].yearMonthForDisplay;

                    $timeout(function () {
                        if (vm.gridControlNewTyreChangeOutForecast && vm.gridControlNewTyreChangeOutForecast.recompile) {
                            vm.gridControlNewTyreChangeOutForecast.recompile();
                        }

                        if (vm.gridControlSpareTyreHighHoursForecast && vm.gridControlSpareTyreHighHoursForecast.recompile) {
                            vm.gridControlSpareTyreHighHoursForecast.recompile();
                        }

                        if (vm.gridControlRimNDTForecast && vm.gridControlRimNDTForecast.recompile) {
                            vm.gridControlRimNDTForecast.recompile();
                        }

                        if (vm.gridControlRimScrapForecast && vm.gridControlRimScrapForecast.recompile) {
                            vm.gridControlRimScrapForecast.recompile();
                        }
                    });
                });
            } else {

                return amtCommandQuerySvc.get(vm.apiUrls.getDashboardForecastWeeks, { forecastId: vm.forecastId }).then(function (response) {

                    if (!response || !response.data) {
                        return;
                    }

                    vm.gridColumns = response.data;

                    vm.gridTweleveMonthForecastColumns = ["forecastDetails.allWeeks"];

                    $timeout(function () {
                        if (vm.gridControlNewTyreChangeOutForecast && vm.gridControlNewTyreChangeOutForecast.recompile) {
                            vm.gridControlNewTyreChangeOutForecast.recompile();
                        }

                        if (vm.gridControlSpareTyreHighHoursForecast && vm.gridControlSpareTyreHighHoursForecast.recompile) {
                            vm.gridControlSpareTyreHighHoursForecast.recompile();
                        }

                        if (vm.gridControlRimNDTForecast && vm.gridControlRimNDTForecast.recompile) {
                            vm.gridControlRimNDTForecast.recompile();
                        }

                        if (vm.gridControlRimScrapForecast && vm.gridControlRimScrapForecast.recompile) {
                            vm.gridControlRimScrapForecast.recompile();
                        }
                    });
                });
            }
        };

        vm.getForecastSummary = function (forecastId) {

            vm.loadingSummary = true;

            return amtCommandQuerySvc.get(vm.apiUrls.getForecastSummary, { forecastId: forecastId }).then(function (response) {
                if (response) {
                    vm.forecastSummary = response.data;
                }
            }).catch(function (error) {
                errorReporter.logError(error, 'Forecast-GetSummary');
            }).finally(function () {
                vm.loadingSummary = false;
            })
        };

        vm.getForecastDetails = function (forecastId) {

            return amtCommandQuerySvc.get(vm.apiUrls.getForecastDetails, { forecastId: forecastId }).then(function (response) {

                if (!response || !response.data) {
                    return;
                }

                let details = response.data;

                vm.forecastName = details.name;
                vm.forecastStartDate = details.startDate;
                vm.forecastEndDate = details.endDate;
                vm.forecastDisplayName = details.displayName;
                vm.otracomSiteId = details.otracomSiteId;
                vm.fleetIds = details.fleetIds;
                vm.forecastType = details.forecastType;
                vm.forecastLength = details.forecastLength;
                vm.budgetForecastStatusType = details.budgetForecastStatusType;
                vm.approvedBy = details.budgetForecastApprovedBy;
                vm.currency = details.currency;
                vm.depthUnit = details.depthUnit;
                vm.budgetRestricted = details.budgetRestricted;
                vm.budgetCap = details.budgetCap;
                vm.allowNewVehicleFitmentsToExceedBudgetCap = details.allowNewVehicleFitmentsToExceedBudgetCap;
                vm.budgetCapTaskTypes = details.budgetCapTaskTypeIds;
                vm.includeRimIntervalsByDays = details.includeRimIntervalsByDays;
                vm.active = details.active;
                vm.forecastMethod = details.forecastMethod;

                vm.forecastTypeName = amtXlatSvc.xlat('forecastDetails.forecastType' + vm.forecastType.name)
                    + (vm.forecastType.name == enums.forecastTypes.budget ? String.format(" - {0}", amtXlatSvc.xlat('forecastDetails.budgetForecastStatusType' + vm.budgetForecastStatusType.name)) : '')

                vm.liveForecast = vm.forecastType.name === enums.forecastTypes.live;
                vm.budgetForecast = vm.forecastType.name === enums.forecastTypes.budget;
                vm.monthToMonthForecast = vm.forecastType.name === enums.forecastTypes.monthToMonth;

                vm.calculateByPercentWorn = vm.forecastMethod && vm.forecastMethod.name === enums.forecastMethods.percentWorn;

                if (vm.budgetRestricted) {
                    return forecastingSvc.getBudgetRestrictedTaskTypes().then(function (taskTypes) {
                        let selectedTaskTypes = taskTypes.filter(function (t) { return vm.budgetCapTaskTypes.includes(t.id); });

                        vm.budgetCapTaskTypesDisplay = selectedTaskTypes.map(function (t) {
                            return t.resolvedName;
                        }).join(', ');
                    });
                }
            }).catch(function (error) {
                errorReporter.logError(error, 'Forecast-GetForecastDetails');
            });
        };

        vm.onVehicleSpecificationNewTyreChangeoutPositions = function () {
            $state.go("app.site.vehiclespecificationnewtyrechangeoutpositions", {
                forecastid: vm.forecastId
            });
        };

        vm.onGetInventory = function (response) {

            let specs = response.result;

            if (specs && specs.length > 0) {

                for (let s = 0; s < specs.length; s++) {
                    specs[s].minimumBackupStockOverrideDisplay = specs[s].minimumBackupStockOverride === 0 || specs[s].minimumBackupStockOverride > 0
                        ? specs[s].minimumBackupStockOverride
                        : '-';
                }
            }

            return response;
        };

        vm.onUpdateForecast = function () {
            WindowFactory.openItem({
                component: 'add-update-forecast',
                canMinimise: false,
                modal: true,
                canClose: false,
                width: 800,
                initParams: {
                    mode: enums.forecastCreateModes.update,
                    model: {
                        forecastId: vm.forecastId,
                        forecastName: vm.forecastName,
                        startDate: vm.forecastStartDate,
                        endDate: vm.forecastEndDate,
                        selectedForecastLength: vm.forecastLength,
                        selectedForecastTypeId: helperSvc.getKey(vm.forecastType),
                        selectedForecastMethodId: helperSvc.getKey(vm.forecastMethod),
                        fleetIds: vm.fleetIds,
                        selectedBudgetForecastStatusType: helperSvc.getKey(vm.budgetForecastStatusType),
                        selectedApprovedBy: vm.approvedBy,
                        budgetRestricted: vm.budgetRestricted,
                        budgetCap: vm.budgetCap,
                        budgetCapTaskTypeIds: vm.budgetCapTaskTypes,
                        allowNewVehicleFitmentsToExceedBudgetCap: vm.allowNewVehicleFitmentsToExceedBudgetCap,
                        includeRimIntervalsByDays: vm.includeRimIntervalsByDays,
                        active: vm.active
                    }
                },
                footerOff: false,
                caption: amtXlatSvc.xlat('forecastDetails.title'),
                onDataChangeHandler: function (forecastId) {

                    if (vm.forecastId != forecastId) {
                        $state.go("app.site.forecastdetails", { forecastid: forecastId });
                    } else {
                        vm.refreshDetails();
                    }
                }
            });
        };

        // tyre update screens
        $scope.tyreSpecificationEdit = function (dataItem) {
            $state.go("app.site.tyrespecificationedit", {
                forecastid: vm.forecastId,
                vehiclespecificationid: dataItem.vehicleSpecificationId,
                forecastvsactuallife: dataItem.forecastVsActualLife,
                sparetyreneed: dataItem.spareTyreNeed,
                newtyrecostperhour: dataItem.forecastAverageTyreLifeError ? amtXlatSvc.xlat('forecastTyres.errorValue') : dataItem.newTyreCostPerHour,
                vehiclespecification: dataItem.vehicleSpecification,
                vehiclecount: dataItem.vehicleCount,
                minVehicleStartDate: dataItem.startDate,
                maxVehicleEndDate: dataItem.endDate,
                newtyreposition: dataItem.newTyrePosition,
                totaltyreposition: dataItem.totalTyrePosition,
                calculateByPercentWorn: vm.calculateByPercentWorn,
                depthUnit: vm.depthUnit,
                forecastStartDate: vm.forecastStartDate,
                forecastEndDate: vm.forecastEndDate
            });
        };

        // rim update screens
        $scope.rimSpecificationEdit = function (dataItem) {
            $state.go("app.site.rimspecificationedit", {
                forecastid: vm.forecastId,
                vehiclespecificationid: dataItem.vehicleSpecificationId,
                vehiclespecification: dataItem.vehicleSpecification,
                vehiclecount: dataItem.vehicleCount,
                startdate: dataItem.startDate,
                enddate: dataItem.endDate,
                currency: vm.currency,
                includeRimIntervalsByDays: vm.includeRimIntervalsByDays
            });
        };

        // vehicle update screens
        $scope.showExistingVehicleSchedule = function (dataItem) {
            $state.go("app.site.existingvehicleschedule", {
                forecastid: vm.forecastId,
                vehiclespecificationid: dataItem.vehicleSpecificationId,
                vehiclespecname: dataItem.vehicleSpecification,
                vehiclecount: dataItem.existingVehicleQtySchedule,
                forecaststartdate: vm.forecastStartDate,
                forecastenddate: vm.forecastEndDate
            }, { reload: false });
        };

        $scope.showNewVehicleSchedule = function (dataItem) {
            $state.go("app.site.newvehicleschedule", {
                forecastid: vm.forecastId,
                vehiclespecificationid: dataItem.vehicleSpecificationId,
                vehiclespecname: dataItem.vehicleSpecification,
                vehiclecount: dataItem.newVehicleQtySchedule,
                forecaststartdate: vm.forecastStartDate,
                forecastenddate: vm.forecastEndDate
            });
        };

        $scope.showMonthlyUsageHoursEdit = function (dataItem) {
            $state.go("app.site.monthlyusage", {
                forecastid: vm.forecastId,
                vehiclespecificationid: dataItem.vehicleSpecificationId,
                vehiclespecname: dataItem.vehicleSpecification,
                forecaststartdate: vm.forecastStartDate,
                forecastenddate: vm.forecastEndDate
            });
        };

        // order update screens
        vm.viewOrders = function (componentType) {
            $state.go("app.site.vieworders", {
                forecastId: vm.forecastId,
                forecastStartDate: vm.forecastStartDate,
                forecastEndDate: vm.forecastEndDate,
                componentType: componentType,
                currentTab: forecastingSvc.getActiveTabName()
            });
        };

        // rim override popups
        $scope.updateRimScrapLifeHoursOverride = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                vehicleSpecificationId: dataItem.vehicleSpecificationId,
                type: enums.forecastOverrideTypes.vehicleSpecificationRimScrapLifeHours,
                value: dataItem.forecastAverageRimScrapLifeOverride,
                heading: amtXlatSvc.xlat('forecastRims.rimScrapLifeHoursOverride')
            }), refreshEquipmentGrids);
        };

        $scope.updateRimFirstInspectionIntervalHoursOverride = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                vehicleSpecificationId: dataItem.vehicleSpecificationId,
                type: enums.forecastOverrideTypes.vehicleSpecificationFirstInspectionIntervalHours,
                value: dataItem.forecastAverageFirstInspectionIntervalOverride,
                heading: amtXlatSvc.xlat('forecastRims.firstInspectionIntervalHoursOverride')
            }), refreshEquipmentGrids);
        };

        $scope.updateRimSubsequentInspectionIntervalHoursOverride = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                vehicleSpecificationId: dataItem.vehicleSpecificationId,
                type: enums.forecastOverrideTypes.vehicleSpecificationSubsequentInspectionIntervalHours,
                value: dataItem.forecastAverageSubsequentInspectionIntervalOverride,
                heading: amtXlatSvc.xlat('forecastRims.subsequentInspectionIntervalHoursOverride')
            }), refreshEquipmentGrids);
        };

        $scope.updateRimScrapLifeDaysOverride = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                vehicleSpecificationId: dataItem.vehicleSpecificationId,
                type: enums.forecastOverrideTypes.vehicleSpecificationRimScrapLifeDays,
                value: dataItem.forecastAverageRimScrapLifeDaysOverride,
                heading: amtXlatSvc.xlat('forecastRims.rimScrapLifeDaysOverride')
            }), refreshEquipmentGrids);
        };

        $scope.updateRimFirstInspectionIntervalDaysOverride = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                vehicleSpecificationId: dataItem.vehicleSpecificationId,
                type: enums.forecastOverrideTypes.vehicleSpecificationFirstInspectionIntervalDays,
                value: dataItem.forecastAverageFirstInspectionIntervalOverrideDays,
                heading: amtXlatSvc.xlat('forecastRims.firstInspectionIntervalDaysOverride')
            }), refreshEquipmentGrids);
        };

        $scope.updateRimSubsequentInspectionIntervalDaysOverride = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                vehicleSpecificationId: dataItem.vehicleSpecificationId,
                type: enums.forecastOverrideTypes.vehicleSpecificationSubsequentInspectionIntervalDays,
                value: dataItem.forecastAverageSubsequentInspectionIntervalOverrideDays,
                heading: amtXlatSvc.xlat('forecastRims.subsequentInspectionIntervalDaysOverride')
            }), refreshEquipmentGrids);
        };

        // tyre override popups
        $scope.updateScrapHoursOverride = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                vehicleSpecificationId: dataItem.vehicleSpecificationId,
                type: enums.forecastOverrideTypes.vehicleSpecificationTyreScrapHours,
                value: dataItem.forecastAverageScrapHoursOverride,
                heading: amtXlatSvc.xlat('forecastTyres.tyreLifeHoursOverride')
            }), refreshEquipmentGrids);
        };

        $scope.updateScrapPercentWornOverride = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                vehicleSpecificationId: dataItem.vehicleSpecificationId,
                type: enums.forecastOverrideTypes.vehicleSpecificationTyreScrapPercentWorn,
                value: dataItem.forecastAverageScrapPercentWornOverride,
                max: 100,
                heading: amtXlatSvc.xlat('forecastTyres.scrapPercentWornOverride')
            }), refreshEquipmentGrids);
        };

        $scope.updateNewRotationHoursOverride = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                vehicleSpecificationId: dataItem.vehicleSpecificationId,
                type: enums.forecastOverrideTypes.vehicleSpecificationTyreNewRotationHours,
                value: dataItem.forecastAverageNewRotationHoursOverride,
                heading: amtXlatSvc.xlat('forecastTyres.newTyreChangeoutHoursOverride')
            }), refreshEquipmentGrids);
        };

        $scope.updateNewRotationPercentWornOverride = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                vehicleSpecificationId: dataItem.vehicleSpecificationId,
                type: enums.forecastOverrideTypes.vehicleSpecificationTyreNewRotationPercentWorn,
                value: dataItem.forecastAverageNewRotationPercentWornOverride,
                max: 100,
                heading: amtXlatSvc.xlat('forecastTyres.newRotationPercentWornOverride')
            }), refreshEquipmentGrids);
        };

        // vehicle override popups
        $scope.updateMonthlyUsageHoursOverride = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                vehicleSpecificationId: dataItem.vehicleSpecificationId,
                type: enums.forecastOverrideTypes.monthlyUsageHours,
                value: dataItem.forecastAverageOverrideMonthlyUsageHours,
                stockId: dataItem.componentStockId,
                heading: amtXlatSvc.xlat('forecastVehicles.monthlyUsageHoursOverride')
            }), refreshAllGrids);
        };

        // order update popups
        $scope.updateTyreMinimumBackupStockOverride = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                type: enums.forecastOverrideTypes.tyreMinimumBackupStock,
                value: dataItem.minimumBackupStockOverride,
                componentSpecificationId: dataItem.specificationId,
                stockId: dataItem.componentStockId,
                heading: amtXlatSvc.xlat('forecastOrders.minimumBackupStockOverride')
            }), refreshOrderGrids);
        };

        $scope.updateRimMinimumBackupStockOverride = function (dataItem) {
            forecastingSvc.updateForecastOverride(UpdateForecastOverrideModelMaker.create({
                forecastId: vm.forecastId,
                type: enums.forecastOverrideTypes.rimMinimumBackupStock,
                value: dataItem.minimumBackupStockOverride,
                componentSpecificationId: dataItem.specificationId,
                stockId: dataItem.componentStockId,
                heading: amtXlatSvc.xlat('forecastOrders.minimumBackupStockOverride')
            }), refreshOrderGrids);
        };

        // dashboard forecast lists
        $scope.showTyreOccurrenceList = function (dataItem, params) {

            var period = params.period;

            var year = null;
            var month = null;
            var week = null;

            if (period === "Due") {
                year = null;
            } else if (period === "ThreeMonth") {
                var threeMonth = new Date(vm.forecastStartDate.getTime());
                threeMonth.setMonth(threeMonth.getMonth() + 2);
                year = threeMonth.getFullYear();
                month = threeMonth.getMonth() + 1;
            } else if (period === "TwelveMonth") {
                var twelveMonth = new Date(vm.forecastStartDate.getTime());
                twelveMonth.setMonth(twelveMonth.getMonth() + 11);
                year = twelveMonth.getFullYear();
                month = twelveMonth.getMonth() + 1;
            } else {
                period = period.substring(1);

                if (period.indexOf('w') > -1) {
                    var vals = period.split('w');
                    year = vals[0];
                    week = vals[1];
                } else {
                    var date = new Date(Math.floor(period / 100), period % 100, 0);
                    year = date.getFullYear();
                    month = date.getMonth() + 1;
                }
            }

            $state.go("app.site.tyreoccurrencelist", {
                forecastid: vm.forecastId,                
                year: year,
                month: month,
                week: week,
                includeAll: params.includeAll,
                currentTab: forecastingSvc.getActiveTabName(),
                depthUnit: vm.depthUnit,
                dashboardType: params.dashboardType
            });
        };

        $scope.showRimOccurrenceList = function (dataItem, params) {

            var period = params.period;

            var year = null;
            var month = null;
            var week = null;

            if (period === "Due") {
                year = null;
            } else if (period === "ThreeMonth") {
                var threeMonth = new Date(vm.forecastStartDate.getTime());
                threeMonth.setMonth(threeMonth.getMonth() + 2);
                year = threeMonth.getFullYear();
                month = threeMonth.getMonth() + 1;
            } else if (period === "TwelveMonth") {
                var twelveMonth = new Date(vm.forecastStartDate.getTime());
                twelveMonth.setMonth(twelveMonth.getMonth() + 11);
                year = twelveMonth.getFullYear();
                month = twelveMonth.getMonth() + 1;
            } else {
                period = period.substring(1);

                if (period.indexOf('w') > -1) {
                    var vals = period.split('w');
                    year = vals[0];
                    week = vals[1];
                } else {
                    var date = new Date(Math.floor(period / 100), period % 100, 0);
                    year = date.getFullYear();
                    month = date.getMonth() + 1;
                }
            }

            $state.go("app.site.rimoccurrencelist", {
                forecastid: vm.forecastId,                
                year: year,
                month: month,
                week: week,
                includeAll: params.includeAll,
                dashboardType: params.dashboardType
            });
        };

        vm.getMonthForDisplay = function (month) {
            var tzDate = new Date(Math.floor(month / 100), ((month % 100) - 1)); // Javascript months are indexed from 0
            return tzDate;
        };

        vm.addNewVehicle = function () {
            WindowFactory.openItem({
                component: 'forecast-add-new-vehicle',
                canMinimise: false,
                modal: true,
                canClose: false,
                footerOff: false,
                caption: amtXlatSvc.xlat('forecastNewVehicleSchedule.addNewVehicle'),
                width: 700,
                initParams: {
                    forecastId: vm.forecastId,
                    forecastStartDate: vm.forecastStartDate,
                    forecastEndDate: vm.forecastEndDate,
                },
                onDataChangeHandler: function () {
                    refreshAllGrids();
                }
            });
        };

        vm.exportTyreOrders = function () {
            exportSvc.exportData(vm.apiUrls.exportForecastTyreInventory, { filterValues: vm.filterValues }, amtXlatSvc.xlat('forecastOrders.tyreOrdersExportFilename', vm.forecastDisplayName));
        };

        vm.exportRimOrders = function () {
            exportSvc.exportData(vm.apiUrls.exportForecastRimInventory, { filterValues: vm.filterValues }, amtXlatSvc.xlat('forecastOrders.rimOrdersExportFilename', vm.forecastDisplayName));
        };

        vm.exportSummary = function () {
            exportSvc.exportData(vm.apiUrls.exportForecastSummary, { forecastId: vm.forecastId }, amtXlatSvc.xlat('forecastSummary.exportFilename', vm.forecastDisplayName));
        };

        vm.generateTyreOrders = function () {

            confirmSvc.confirmMessage('forecastOrders.confirmGenerateOrders_title', 'forecastOrders.confirmGenerateOrders').then(function () {

                var criteria = {
                    forecastId: vm.forecastId
                };

                vm.processing = true;

                amtCommandQuerySvc.post(vm.apiUrls.generateTyreOrders, criteria).then(function (response) {

                    notifySvc.success(amtXlatSvc.xlat('forecastOrders.ordersSuccessfullyGenerated'));

                }).catch(function (error) {
                    errorReporter.logError(error);
                }).finally(function () {
                    vm.processing = false;
                })
            });
        };

        vm.generateRimOrders = function () {

            confirmSvc.confirmMessage('forecastOrders.confirmGenerateOrders_title', 'forecastOrders.confirmGenerateOrders').then(function () {

                var criteria = {
                    forecastId: vm.forecastId
                };

                vm.processing = true;

                amtCommandQuerySvc.post(vm.apiUrls.generateRimOrders, criteria).then(function (response) {

                    notifySvc.success(amtXlatSvc.xlat('forecastOrders.ordersSuccessfullyGenerated'));

                }).catch(function (error) {
                    errorReporter.logError(error);
                }).finally(function () {
                    vm.processing = false;
                })
            });
        };

        vm.showDashboard = function (type, forceReload) {

            if (vm.currentTab !== type || forceReload) {

                vm.currentTab = type;
                forecastingSvc.setActiveTabName(type);

                if (type === "Tyres" || type === "Vehicles") {
                    vm.showTyresDashboard = true;
                    vm.showRimsDashboard = false;
                    vm.showSummaryExportButton = false;
                } else if (type === "Rims") {
                    vm.showTyresDashboard = false;
                    vm.showRimsDashboard = true;
                    vm.showSummaryExportButton = false;
                } else if (type === "Summary") {
                    vm.showTyresDashboard = false;
                    vm.showRimsDashboard = false;
                    vm.getForecastSummary(vm.forecastId);
                    vm.showSummaryExportButton = true;
                } else {
                    vm.showTyresDashboard = false;
                    vm.showRimsDashboard = false;
                    vm.showSummaryExportButton = false;
                }
            }
        };

        vm.refreshDetails = function () {

            vm.loading = true;

            vm.showTyresDashboard = false;
            vm.showRimsDashboard = false;

            vm.gridColumns = undefined;
            vm.gridThreeMonthForecastColumns = undefined;
            vm.gridTweleveMonthForecastColumns = undefined;

            vm.getForecastDetails(vm.forecastId).then(function () {
                vm.getColumns().then(function () {

                    vm.showDashboard(forecastingSvc.getActiveTabName(), true);

                    vm.gridControlVehicles.refresh();
                    vm.gridControlTyres.refresh();
                    vm.gridControlRims.refresh();
                    vm.gridControlRimOrders.refresh();
                    vm.gridControlTyreOrders.refresh();
                });
            }).finally(function () {
                vm.loading = false;
            });
        };

        function refreshRimGrids() {
            if (vm.gridControlRims && vm.gridControlRims.refresh) {
                vm.gridControlRims.refresh();
            }

            if (vm.gridControlRimsDays && vm.gridControlRimsDays.refresh) {
                vm.gridControlRimsDays.refresh();
            }

            if (vm.gridControlRimNDTForecast && vm.gridControlRimNDTForecast.refresh) {
                vm.gridControlRimNDTForecast.refresh();
            }

            if (vm.gridControlRimScrapForecast && vm.gridControlRimScrapForecast.refresh) {
                vm.gridControlRimScrapForecast.refresh();
            }
        };

        function refreshTyreGrids() {
            if (vm.gridControlTyres && vm.gridControlTyres.refresh) {
                vm.gridControlTyres.refresh();
            }

            if (vm.gridControlNewTyreChangeOutForecast && vm.gridControlNewTyreChangeOutForecast.recompile) {
                vm.gridControlNewTyreChangeOutForecast.recompile();
            }

            if (vm.gridControlSpareTyreHighHoursForecast && vm.gridControlSpareTyreHighHoursForecast.recompile) {
                vm.gridControlSpareTyreHighHoursForecast.recompile();
            }
        };

        function refreshVehicleGrids() {
            if (vm.gridControlVehicles && vm.gridControlVehicles.refresh) {
                vm.gridControlVehicles.refresh();
            }
        };

        function refreshOrderGrids() {
            if (vm.gridControlRimOrders && vm.gridControlRimOrders.refresh) {
                vm.gridControlRimOrders.refresh();
            }

            if (vm.gridControlTyreOrders && vm.gridControlTyreOrders.refresh) {
                vm.gridControlTyreOrders.refresh();
            }
        };

        function refreshEquipmentGrids() {
            refreshTyreGrids();
            refreshRimGrids();
        };

        function refreshAllGrids() {
            refreshVehicleGrids();
            refreshTyreGrids();
            refreshRimGrids();
            refreshOrderGrids();
        };
    }
