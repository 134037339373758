//import angular from 'angular';
import tmpl from './support.html';



angular.module("app").component('support', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['$scope', 'WindowFactory', 'amtXlatSvc', 'enums',
            function ($scope, WindowFactory: IWindowFactory, amtXlatSvc: IAmtXlatSvc, enums) {
                var vm = this;
                $scope.vm = this;
    
                vm.supportText = amtXlatSvc.xlat('login.SupportText');
                
                this.$onInit = function () {
					vm.buttons = [
						{
							primary: true, 
							cancel: false, 
							value: 'common.close_label',
							name: 'closeButton',
							click: 'close',
							type: enums.buttonTypes.button
						}
					];
					
					vm.buttonStates = {
						closeButton: {
							visible: true, 
							disabled: false
						}
					};
					
					vm.buttonMethods = {
						close: vm.close
					};
				};
    
                vm.close = function () {

                    if (vm.wnd.onDataChanged) {
                        vm.wnd.onDataChanged();
                    }

                    WindowFactory.closeWindow(vm.wnd);
                };
    
                return vm;
            }
        ]
    });
