//import angular from 'angular';
import BrowserSvc from '../../../services/browserSvc';
import tmpl from './ocKeypad.html';



class OcKeypad implements ng.IController {
    public stocktakeType: string;
    canAddComponent: any;
    displayLeft: boolean;
    broadcastScopeLevel: number;
    plusDescription: string;

    static $inject = ['amtXlatSvc', 'browserSvc', '$scope', '$element'];
    constructor(private amtXlatSvc: IAmtXlatSvc, browserSvc: BrowserSvc, private $scope: ng.IScope, private $element: ng.IRootElementService) {
        this.displayLeft = browserSvc.isMobile && localStorage.getItem('KeypadLocation') == 'Left'; //default to right
    }

    public click(key: string | number) {
        //call parent for each ancestor level, we don't want to broadcast too far up the scope heirarchy
        let scope = this.$scope, i = 0;
        let levels = (this.broadcastScopeLevel || 0) + 1;
        while (scope.$parent && i <= levels) {
            scope = scope.$parent;
            ++i;
        }

        let isDigit = !isNaN(key as any);
        scope.$broadcast(isDigit ? Keypad.KEY_PRESSED : Keypad.MODIFIER_KEY_PRESSED, String(key));
    }

    public moveKeyPad() {
        this.displayLeft = !this.displayLeft;
        this.$element.toggleClass('numpad-left', this.displayLeft);
        localStorage.setItem('KeypadLocation', this.displayLeft ? 'Left' : 'Right');
    }

    public $onChanges(changes: ng.IOnChangesObject) {
        if (changes.stocktakeType) {
            switch (changes.stocktakeType.currentValue) {
                case 'tyre':
                    this.plusDescription = this.amtXlatSvc.xlat('mobileCommon.keyboardTyreAdd');
                    break;
                case 'rim':
                    this.plusDescription = this.amtXlatSvc.xlat('mobileCommon.keyboardRimAdd');
                    break;
                case 'chain':
                    this.plusDescription = this.amtXlatSvc.xlat('mobileCommon.keyboardChainAdd');
                    break;
                default:
                    this.plusDescription = '+';
                    break;
            }
        }
    }
}

angular.module('app.directives').component('ocKeypad', {
    bindings: {
        stocktakeType: '@',
        canAddComponent: "<?",
        displayLeft: "=?",
        broadcastScopeLevel: "<?" //HACK: what ancestor to broadcast keypresses from
    },
    template: tmpl,
    controllerAs: 'vm',
    controller: OcKeypad
});