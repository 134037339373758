/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var kendo_licensing_1 = require("@progress/kendo-licensing");
var package_metadata_1 = require("../package-metadata");
var utils_1 = require("../common/utils");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var kendo_common_1 = require("@progress/kendo-common");
var FOCUSED = 'k-focus';
/**
 * Represents the [Kendo UI Switch component for Angular]({% slug overview_switch %}).
 */
var SwitchComponent = /** @class */ (function () {
    function SwitchComponent(renderer, hostElement, localizationService, injector, changeDetector, ngZone) {
        var _this = this;
        this.renderer = renderer;
        this.hostElement = hostElement;
        this.localizationService = localizationService;
        this.injector = injector;
        this.changeDetector = changeDetector;
        this.ngZone = ngZone;
        /**
         * @hidden
         */
        this.focusableId = "k-" + kendo_angular_common_1.guid();
        /**
         * Determines whether the Switch is disabled ([see example]({% slug disabled_switch %})).
         */
        this.disabled = false;
        /**
         * Determines whether the Switch is in its read-only state ([see example]({% slug readonly_switch %})).
         */
        this.readonly = false;
        /**
         * Specifies the [tabindex](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex) of the Switch.
         */
        this.tabindex = 0;
        /**
         * Fires each time the user focuses the `input` element.
         */
        this.onFocus = new core_1.EventEmitter(); //tslint:disable-line:no-output-rename
        /**
         * Fires each time the `input` element gets blurred.
         */
        this.onBlur = new core_1.EventEmitter(); //tslint:disable-line:no-output-rename
        /**
         * Fires each time the user selects a new value.
         */
        this.valueChange = new core_1.EventEmitter();
        this.hostClasses = true;
        /**
         * @hidden
         */
        this.initialized = false;
        this.hostClickSubscription = new rxjs_1.Subscription;
        this._checked = false;
        this._size = 'medium';
        this._trackRounded = 'full';
        this._thumbRounded = 'full';
        this.ngChange = function (_) { };
        this.ngTouched = function () { };
        /**
         * @hidden
         */
        this.handleFocus = function () {
            if (_this.isFocused) {
                return;
            }
            _this.focused = true;
            if (kendo_angular_common_1.hasObservers(_this.onFocus)) {
                _this.ngZone.run(function () {
                    _this.onFocus.emit();
                });
            }
        };
        /**
         * @hidden
         */
        this.handleBlur = function (event) {
            var relatedTarget = event && event.relatedTarget;
            if (_this.hostElement.nativeElement.contains(relatedTarget)) {
                return;
            }
            _this.changeDetector.markForCheck();
            _this.focused = false;
            if (kendo_angular_common_1.hasObservers(_this.onBlur) || utils_1.requiresZoneOnBlur(_this.control)) {
                _this.ngZone.run(function () {
                    _this.ngTouched();
                    _this.onBlur.emit();
                });
            }
        };
        kendo_licensing_1.validatePackage(package_metadata_1.packageMetadata);
        this.direction = localizationService.rtl ? 'rtl' : 'ltr';
        this.keyDownHandler = this.keyDownHandler.bind(this);
        this.clickHandler = this.clickHandler.bind(this);
    }
    SwitchComponent_1 = SwitchComponent;
    Object.defineProperty(SwitchComponent.prototype, "checked", {
        get: function () {
            return this._checked;
        },
        /**
         * Sets the value of the Switch when it is initially displayed.
         */
        set: function (value) {
            this.setHostClasses(value);
            this._checked = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SwitchComponent.prototype, "size", {
        get: function () {
            return this._size;
        },
        /**
         * Specifies the width and height of the Switch.
         *
         * The possible values are:
         * * `'small'`
         * * `'medium'` (default)
         * * `'large'`
         * * `null`
         */
        set: function (size) {
            this.handleClasses(size, 'size');
            this._size = size;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SwitchComponent.prototype, "thumbRounded", {
        get: function () {
            return this._thumbRounded;
        },
        /**
         * Specifies the border radius of the Switch thumb.
         *
         * The possible values are:
         * * `'full'` (default)
         * * `'small'`
         * * `'medium'`
         * * `'large'`
         * * `null`
         */
        set: function (thumbRounded) {
            this.handleThumbClasses(thumbRounded);
            this._thumbRounded = thumbRounded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SwitchComponent.prototype, "trackRounded", {
        get: function () {
            return this._trackRounded;
        },
        /**
         * Specifies the border radius of the Switch track.
         *
         * The possible values are:
         * * `'full'` (default)
         * * `'small'`
         * * `'medium'`
         * * `'large'`
         * * `null`
         */
        set: function (trackRounded) {
            this.handleTrackClasses(trackRounded);
            this._trackRounded = trackRounded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SwitchComponent.prototype, "tabIndex", {
        get: function () {
            return this.tabindex;
        },
        /**
         * @hidden
         */
        set: function (tabIndex) {
            this.tabindex = tabIndex;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SwitchComponent.prototype, "ieClass", {
        get: function () {
            return kendo_common_1.browser && kendo_common_1.browser.msie;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SwitchComponent.prototype, "ariaDisabled", {
        get: function () {
            return this.disabled ? true : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SwitchComponent.prototype, "ariaReadonly", {
        get: function () {
            return this.readonly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SwitchComponent.prototype, "disabledClass", {
        get: function () {
            return this.disabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SwitchComponent.prototype, "onLabelMessage", {
        /**
         * @hidden
         */
        get: function () {
            return this.onLabel || this.localizationService.get('on');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SwitchComponent.prototype, "offLabelMessage", {
        /**
         * @hidden
         */
        get: function () {
            return this.offLabel || this.localizationService.get('off');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SwitchComponent.prototype, "isEnabled", {
        get: function () {
            return !this.disabled && !this.readonly;
        },
        enumerable: true,
        configurable: true
    });
    SwitchComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.hostElement) {
            var wrapper = this.hostElement.nativeElement;
            this.renderer.removeAttribute(wrapper, "tabindex");
        }
        this.localizationChangeSubscription = this.localizationService
            .changes
            .pipe(operators_1.skip(1))
            .subscribe(function (_a) {
            var rtl = _a.rtl;
            _this.direction = rtl ? 'rtl' : 'ltr';
        });
        this.control = this.injector.get(forms_1.NgControl, null);
        this.ngZone.onStable.pipe(operators_1.take(1)).subscribe(function () { return _this.initialized = true; });
    };
    SwitchComponent.prototype.ngAfterViewInit = function () {
        var wrapper = this.hostElement.nativeElement;
        this.attachHostClickHandler();
        if (!this.checked && !wrapper.classList.contains('k-switch-off')) {
            this.renderer.addClass(wrapper, 'k-switch-off');
        }
        this.handleClasses(this.size, 'size');
        this.handleTrackClasses(this.trackRounded);
        this.handleThumbClasses(this.thumbRounded);
    };
    SwitchComponent.prototype.ngOnDestroy = function () {
        if (this.localizationChangeSubscription) {
            this.localizationChangeSubscription.unsubscribe();
        }
        if (this.hostClickSubscription) {
            this.hostClickSubscription.unsubscribe();
        }
    };
    /**
     * Focuses the Switch.
     *
     * @example
     * ```ts-no-run
     * _@Component({
     * selector: 'my-app',
     * template: `
     *  <button (click)="switch.focus()">Focus</button>
     *  <kendo-switch #switch></kendo-switch>
     * `
     * })
     * class AppComponent { }
     * ```
     */
    SwitchComponent.prototype.focus = function () {
        if (!this.track) {
            return;
        }
        this.track.nativeElement.focus();
    };
    /**
     * Blurs the Switch.
     */
    SwitchComponent.prototype.blur = function () {
        if (!this.track) {
            return;
        }
        this.track.nativeElement.blur();
    };
    /**
     * @hidden
     * Called when the status of the component changes to or from `disabled`.
     * Depending on the value, it enables or disables the appropriate DOM element.
     */
    SwitchComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
        this.changeDetector.markForCheck();
    };
    /**
     * @hidden
     */
    SwitchComponent.prototype.writeValue = function (value) {
        this.checked = value === null ? false : value;
        this.changeDetector.markForCheck();
    };
    /**
     * @hidden
     */
    SwitchComponent.prototype.registerOnChange = function (fn) {
        this.ngChange = fn;
    };
    /**
     * @hidden
     */
    SwitchComponent.prototype.registerOnTouched = function (fn) {
        this.ngTouched = fn;
    };
    /**
     * @hidden
     */
    SwitchComponent.prototype.keyDownHandler = function (e) {
        var keyCode = e.keyCode;
        if (this.isEnabled && (keyCode === kendo_angular_common_1.Keys.Space || keyCode === kendo_angular_common_1.Keys.Enter)) {
            this.changeValue(!this.checked);
            e.preventDefault();
        }
    };
    /**
     * @hidden
     */
    SwitchComponent.prototype.clickHandler = function () {
        if (this.isEnabled) {
            this.changeValue(!this.checked);
        }
    };
    /**
     * @hidden
     * Used by the FloatingLabel to determine if the component is empty.
     */
    SwitchComponent.prototype.isEmpty = function () {
        return false;
    };
    SwitchComponent.prototype.changeValue = function (value) {
        var _this = this;
        if (this.checked !== value) {
            this.ngZone.run(function () {
                _this.checked = value;
                _this.ngChange(value);
                _this.valueChange.emit(value);
                _this.changeDetector.markForCheck();
            });
        }
    };
    Object.defineProperty(SwitchComponent.prototype, "focused", {
        set: function (value) {
            if (this.isFocused !== value && this.hostElement) {
                var wrapper = this.hostElement.nativeElement;
                if (value) {
                    this.renderer.addClass(wrapper, FOCUSED);
                }
                else {
                    this.renderer.removeClass(wrapper, FOCUSED);
                }
                this.isFocused = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    SwitchComponent.prototype.attachHostClickHandler = function () {
        var _this = this;
        this.ngZone.runOutsideAngular(function () {
            _this.hostClickSubscription.add(_this.renderer.listen(_this.hostElement.nativeElement, 'click', _this.clickHandler));
        });
    };
    SwitchComponent.prototype.setHostClasses = function (value) {
        var wrapper = this.hostElement.nativeElement;
        if (value) {
            this.renderer.removeClass(wrapper, 'k-switch-off');
            this.renderer.addClass(wrapper, 'k-switch-on');
        }
        else {
            this.renderer.removeClass(wrapper, 'k-switch-on');
            this.renderer.addClass(wrapper, 'k-switch-off');
        }
    };
    SwitchComponent.prototype.handleClasses = function (value, input) {
        var elem = this.hostElement.nativeElement;
        var classes = utils_1.getStylingClasses('switch', input, this[input], value);
        if (classes.toRemove) {
            this.renderer.removeClass(elem, classes.toRemove);
        }
        if (classes.toAdd) {
            this.renderer.addClass(elem, classes.toAdd);
        }
    };
    SwitchComponent.prototype.handleTrackClasses = function (value) {
        var elem = this.hostElement.nativeElement;
        var track = this.track.nativeElement;
        var classes = utils_1.getStylingClasses('switch', 'rounded', this.trackRounded, value);
        if (classes.toRemove) {
            this.renderer.removeClass(elem, classes.toRemove);
            this.renderer.removeClass(track, classes.toRemove);
        }
        if (classes.toAdd) {
            this.renderer.addClass(elem, classes.toAdd);
            this.renderer.addClass(track, classes.toAdd);
        }
    };
    SwitchComponent.prototype.handleThumbClasses = function (value) {
        var thumb = this.thumb.nativeElement;
        var classes = utils_1.getStylingClasses('switch', 'rounded', this.thumbRounded, value);
        if (classes.toRemove) {
            this.renderer.removeClass(thumb, classes.toRemove);
        }
        if (classes.toAdd) {
            this.renderer.addClass(thumb, classes.toAdd);
        }
    };
    var SwitchComponent_1;
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], SwitchComponent.prototype, "focusableId", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], SwitchComponent.prototype, "onLabel", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], SwitchComponent.prototype, "offLabel", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [Boolean])
    ], SwitchComponent.prototype, "checked", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], SwitchComponent.prototype, "disabled", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], SwitchComponent.prototype, "readonly", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], SwitchComponent.prototype, "tabindex", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], SwitchComponent.prototype, "size", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], SwitchComponent.prototype, "thumbRounded", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], SwitchComponent.prototype, "trackRounded", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number),
        tslib_1.__metadata("design:paramtypes", [Number])
    ], SwitchComponent.prototype, "tabIndex", null);
    tslib_1.__decorate([
        core_1.Output('focus'),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], SwitchComponent.prototype, "onFocus", void 0);
    tslib_1.__decorate([
        core_1.Output('blur'),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], SwitchComponent.prototype, "onBlur", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], SwitchComponent.prototype, "valueChange", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('attr.dir'),
        tslib_1.__metadata("design:type", String)
    ], SwitchComponent.prototype, "direction", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-ie'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], SwitchComponent.prototype, "ieClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.aria-disabled'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], SwitchComponent.prototype, "ariaDisabled", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.aria-readonly'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], SwitchComponent.prototype, "ariaReadonly", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-switch'),
        tslib_1.__metadata("design:type", Boolean)
    ], SwitchComponent.prototype, "hostClasses", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-disabled'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], SwitchComponent.prototype, "disabledClass", null);
    tslib_1.__decorate([
        core_1.ViewChild('track', { static: true }),
        tslib_1.__metadata("design:type", Object)
    ], SwitchComponent.prototype, "track", void 0);
    tslib_1.__decorate([
        core_1.ViewChild('thumb', { static: true }),
        tslib_1.__metadata("design:type", Object)
    ], SwitchComponent.prototype, "thumb", void 0);
    SwitchComponent = SwitchComponent_1 = tslib_1.__decorate([
        core_1.Component({
            exportAs: 'kendoSwitch',
            providers: [
                kendo_angular_l10n_1.LocalizationService,
                { provide: kendo_angular_l10n_1.L10N_PREFIX, useValue: 'kendo.switch' },
                {
                    multi: true,
                    provide: forms_1.NG_VALUE_ACCESSOR,
                    useExisting: core_1.forwardRef(function () { return SwitchComponent_1; }) /* tslint:disable-line */
                },
                {
                    provide: kendo_angular_common_1.KendoInput,
                    useExisting: core_1.forwardRef(function () { return SwitchComponent_1; })
                }
            ],
            selector: 'kendo-switch',
            template: "\n        <ng-container kendoSwitchLocalizedMessages\n            i18n-on=\"kendo.switch.on|The **On** label of the Switch.\"\n            on=\"ON\"\n            i18n-off=\"kendo.switch.off|The **Off** label of the Switch.\"\n            off=\"OFF\"\n        >\n\n        <span\n            #track\n            class=\"k-switch-track\"\n            [id]=\"focusableId\"\n            role=\"switch\"\n            [style.transitionDuration]=\"initialized ? '200ms' : '0ms'\"\n            [attr.aria-checked]=\"checked\"\n            [attr.tabindex]=\"(disabled ? undefined : tabIndex)\"\n            [kendoEventsOutsideAngular]=\"{ keydown: keyDownHandler, focus: handleFocus, blur: handleBlur }\"\n        >\n            <span class=\"k-switch-label-on\" [attr.aria-hidden]=\"true\" >{{onLabelMessage}}</span>\n            <span class=\"k-switch-label-off\" [attr.aria-hidden]=\"true\">{{offLabelMessage}}</span>\n        </span>\n        <span\n            class=\"k-switch-thumb-wrap\"\n            tabindex=\"-1\"\n            [style.transitionDuration]=\"initialized ? '200ms' : '0ms'\" [kendoEventsOutsideAngular]=\"{\n                keydown: keyDownHandler,\n                focus: handleFocus,\n                blur: handleBlur\n            }\">\n            <span #thumb class=\"k-switch-thumb\"></span>\n        </span>\n  "
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.Renderer2,
            core_1.ElementRef,
            kendo_angular_l10n_1.LocalizationService,
            core_1.Injector,
            core_1.ChangeDetectorRef,
            core_1.NgZone])
    ], SwitchComponent);
    return SwitchComponent;
}());
exports.SwitchComponent = SwitchComponent;
