/**
 * @ngdoc directive
 * @name amtFramework.grid.directive: amtTemplateColumn
 * @restrict E
 * @requires amtFramework.grid.directive:amtGrid
 * 
 * @param {string} field Name of the field defined in the datasource. Only used for filtering and sorting
 * @param {string} title Column header title
 * @param {string} width Width in pixels like '150px'
 * @param {boolean=} [optional=true] Indicates the field is optional and can be hidden by the user
 * @param {string=} attributes Additional html attributes e.g {"style":"text-align:right"}
 * @param {string=} type Data type of column. Possible values: 'number', 'boolean', 'date', 'datetime', 'json'
 * @param {boolean=} [filterable=true] Filterable
 * @param {boolean=} [sortable=true] Sortable
 *
 * @description
 * Template Column used in {@link amtFramework.grid.directive:amtGrid amtGrid}
 * Inside the template use the Kendo template language
 *
 * To call a function on the controller of the page from the template use the "callOnParentScope" function
 * In the template use 
 * <pre>
 * <button ng-click="callOnParentScope('buttonClicked', dataItem, '{{field}}')"></button>
 * </pre>
 * On the controller define the function directly on the scope
 * <pre>
 * $scope.buttonClicked = function(dataItem, params){
 *     console.log(dataItem);
 *     console.log(params);
 * };
 * </pre>
 *
 * @example
 * <doc:example module="amtFramework">
 *   <doc:source>
 *     <amt-grid id="testGrid" read-url="" primary-key="field1">
 *      <amt-template-column optional="false" field="field1" max-length="50" title="{{'screen1.site_label' | xlat}}">
 *              <span>#: field1 # </span>
 *              #if(field2){#
 *              <span class="default-caption">{{'screen1.site_label'| xlat}}</span>
 *              #}#
 *      </amt-template-column>
 *     </amt-grid>
 *   </doc:source>
 * </doc:example>       
 */    

//import angular from 'angular';

angular.module('amtFramework.grid').directive('amtTemplateColumn', [
    '$interpolate',
    function ($interpolate) {
            return {
                require: ['^amtGrid', '?^amtGridGroupHeaderColumn'],
                restrict: 'E',
                transclude: true,
                template: '<span style="display:none;" ng-transclude></span>',
                scope: {
                    field: '@',
                    title: '@',
                    width: '@',
                    optional: '@',
                    attributes: '@',
                    headerAttributes: '@',
                    type: '@',
                    filterable: '@',
                    sortable: '@',
                    forceHide: '@',
                    hidden: '@',
                    wrap: '@',
                    locked: '@'
                },
                controller: ['$scope', function ($scope) {
                    if ($scope.forceHide !== undefined) {
                        $scope.forceHide = $scope.forceHide ? $scope.forceHide.toString().toLowerCase() === "true" : false;
                    }

                    $scope.optional = $scope.optional ? $scope.optional.toString().toLowerCase() === "true" : false;
                    $scope.hidden = $scope.hidden ? $scope.hidden.toString().toLowerCase() === "true" : false;
                }],
                link: function (scope: any, elem, attrs, controllerarr) {
                    //Grid Controller or the Optional Grouping Header
                    var amtGridCtrl = (controllerarr[1] == null) ? controllerarr[0] : controllerarr[1];

                    var filterable = true;
                    if (scope.filterable) {
                        filterable = JSON.parse(scope.filterable);
                    }

                    var sortable = true;
                    if (scope.sortable) {
                        sortable = JSON.parse(scope.sortable);
                    }
                    var template = $interpolate(elem.get()[0].children[0].innerHTML)(scope);
                    //var template = elem.get()[0].children[0].innerHTML;

                    var kendoColumnDefinition: any = {
                        optional: JSON.parse(scope.optional),
                        hidden: attrs.hidden === undefined ? false : scope.$eval(attrs.hidden),
                        template: template,
                        title: scope.title,
                        field: scope.field,
                        id: scope.field,
                        type: scope.type,
                        filterable: filterable,
                        sortable: sortable,
                        width: scope.width,
                        forceHide: scope.forceHide,
                        menu: scope.optional,
                        locked: scope.locked
                    };

                    if (scope.attributes) {
                        if (!kendoColumnDefinition.attributes) {
                            kendoColumnDefinition.attributes = {};
                        }
                        var attrString = scope.attributes;
                        var attrJson = JSON.parse(attrString);
                        for (var attrname in attrJson) {
                            kendoColumnDefinition.attributes[attrname] = attrJson[attrname];
                        }
                    }

                    if (scope.headerAttributes) {
                        if (!kendoColumnDefinition.headerAttributes) {
                            kendoColumnDefinition.headerAttributes = {};
                        }
                        attrString = scope.headerAttributes;
                        attrJson = JSON.parse(attrString);
                        for (attrname in attrJson) {
                            kendoColumnDefinition.headerAttributes[attrname] = attrJson[attrname];
                        }
                    }

                    if (scope.wrap === "true") {
                        if (!kendoColumnDefinition.attributes) {
                            kendoColumnDefinition.attributes = {};
                        }

                        kendoColumnDefinition.attributes["style"] = "word-wrap: break-word; white-space: normal;";
                    }

                    scope.kendoColumnDefinition = kendoColumnDefinition;
                    scope.$on('$destroy', function() {
                        amtGridCtrl.removeColumn(scope.kendoColumnDefinition);
                    });
                    
                    scope.$watch('forceHide', function (newValue, oldValue) {
                        if (amtGridCtrl.showHideGridColumn !== undefined && scope.forceHide) {
                            amtGridCtrl.showHideGridColumn(scope, newValue);
                        }
                    });

                    scope.$watch('locked', function (newValue) {
                        scope.kendoColumnDefinition.locked = newValue;
                    });

                    amtGridCtrl.addColumn(kendoColumnDefinition);

                    if (scope.forceHide) {
                        amtGridCtrl.showHideGridColumn(scope, true);
                    }

                   
                }
            };
    }
]);
