//import angular from 'angular';
import OcConfigSvc from './ocConfigSvc';



angular.module('app').factory('ocAlertAndNotificationSvc', [
    'amtCommandQuerySvc', '$timeout', 'ocConfigSvc', 'WindowFactory', 'notificationUrls', 
    'dataBroker', 'errorReporter', '$rootScope', 'amtXlatSvc',
    function (
        amtCommandQuerySvc: IAmtCommandQuerySvc, $timeout: ng.ITimeoutService, ocConfigSvc: OcConfigSvc, WindowFactory: IWindowFactory, notificationUrls,
        dataBroker, errorReporter: IErrorReporter, $rootScope: IRootScope, amtXlatSvc: IAmtXlatSvc
    ) {
        var lastRunDate;
        var alertAndNotificationPollPromise;

        var pollRate = 60;

        function runNow() {
            if (!$rootScope.loggingIn) {
                amtCommandQuerySvc.post('admin/notifications/poll', { lastRunDate: lastRunDate })
                    .then(response => pollResponseHandler(response))
                    .catch(function (error) {
                        console.log("Polling Error:" + errorReporter.exceptionMessage(error));
                    });
                dataBroker.refreshToken(false, false).catch(function (error) {                    
                    console.error("Failed refresh of token. " + errorReporter.exceptionMessage(error));
                });
            }            
            start(pollRate); // poll again regardless.            
        }

        function start(intervalInSeconds) {
            // create a timer to fire to check for new alerts/notifications
            if (alertAndNotificationPollPromise) {
                $timeout.cancel(alertAndNotificationPollPromise);
            }
            alertAndNotificationPollPromise = $timeout(() => runNow(), intervalInSeconds * 1000, true);            
        }

        function acknowledgeToast() {
            return amtCommandQuerySvc.post(notificationUrls.acknowledgeToast, ocConfigSvc.toast).then(function (response) {
                if (response) {
                    ocConfigSvc.toast = undefined;
                }
            });
        }

        function clearToast() {
            if (ocConfigSvc.toast) {
                if (ocConfigSvc.toast.timeoutPromise) {
                    // stop the timeout
                    $timeout.cancel(ocConfigSvc.toast.timeoutPromise);
                }

                ocConfigSvc.toast = undefined;
            }
        }

        function updateToast(newToast) {
            if (!newToast) {
                // toast was cleared by something else, so clear it.
                clearToast();
                return;
            }

            // check if the toast has changed
            if (ocConfigSvc.toast) { // previous toast exists
                // the toast has changed, if the ID is different, or the count changes
                if (ocConfigSvc.toast.count === newToast.count && ocConfigSvc.toast.id === newToast.id) {
                    // exit if the toast hasn't changed
                    return;
                }

                // reset the timer, by clearing it from the old toast, and adding it later
                if (ocConfigSvc.toast.timeoutPromise) {
                    // stop the timeout
                    $timeout.cancel(ocConfigSvc.toast.timeoutPromise);
                }
            }

            // update the toast
            ocConfigSvc.toast = newToast;
            ocConfigSvc.toast.clicked = false;

            // wire-up the onclick to open the alerts and notification screen on the appropriate tab
            ocConfigSvc.toast.onClick = function () {
                WindowFactory.openItem({
                    component: 'notifications-list',
                    caption: amtXlatSvc.xlat('common.notificationAndAlerts'),                    
                    initParams: {
                        showCloseOnSave: false,
                        defaultTab: newToast.type + "s"
                    },
                    width: 1400,
                    height: 515,
					modal: false
                });

                ocConfigSvc.toast.clicked = true;
            };

            if (ocConfigSvc.user.notificationSettings.timeout >= 1) {
                // create a timeout for the message
                ocConfigSvc.toast.timeoutPromise = $timeout(function () {
                    // just in case session is refreshed 
                    if (ocConfigSvc.toast) {
                        ocConfigSvc.toast.timedOut = true;
                    }
                }, ocConfigSvc.user.notificationSettings.timeout * 1000);
            }

            // wireup the acknowledge function so that the user can close the notification
            ocConfigSvc.toast.acknowledge = function () { ocConfigSvc.toast.clicked = true; };
        }

        function pollResponseHandler(response) {
            if (!response || typeof response === 'string')
                return;

            ocConfigSvc.user.alerts = response.alerts;
            ocConfigSvc.user.notifications = response.notifications;

            if (ocConfigSvc.user.notificationSettings.show) {
                updateToast(response.toast);
            }

            // update the run date for the next poll
            lastRunDate = new Date();
        }

        return {
            start: start,
            runNow: runNow,
            lastRunDate: lastRunDate,
            acknowledgeToast: acknowledgeToast
        };
    }
]);