import { Component, OnInit, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import { ConditionMonitoringService, CONTINUE_TO_MONITOR_RES, IDisplayVehicle, IVisualInspectionBasicApi, MonitoringIssueData } from '../../condition-monitoring.service';
import { map, tap } from 'rxjs/operators';
import { XlatPipe } from '../../../../../amt/i18n/xlat-pipe';
import { combineLatest, Observable } from 'rxjs';
import { Inject } from '@angular/core';
import { ConditionMonitoringBaseComponent, ICON_HEIGHT, ICON_WIDTH, IGroup } from '../condition-monitoring-base.component';

@Component({
    selector: 'condition-monitoring',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './../condition-monitoring-base.component.html',
    styleUrls: ['./condition-monitoring.component.scss'],
    providers: [
        { provide: 'desiredWidth', useValue: ICON_WIDTH },
        { provide: 'desiredHeight', useValue: ICON_HEIGHT },
        ConditionMonitoringService]
})
export class ConditionMonitoringComponent extends ConditionMonitoringBaseComponent implements OnInit, OnDestroy {
    _displayBy = [
        { text: this.xlatPipe.transform("conditionmonitoring.action_status"), value: 0 },
        { text: this.xlatPipe.transform("conditionmonitoring.recommended_action"), value: 1 }];
    displayBySelectedItem = this._displayBy.find(elem => elem.value === 1); 

    constructor(protected monSvc: ConditionMonitoringService, protected xlatPipe: XlatPipe,
                @Inject('WindowFactory') protected WindowFactory: any) {
        super(monSvc, xlatPipe, WindowFactory);
        this.setTitle("conditionmonitoring.VisualInspections");
    }

    public groupedVehicles$: Observable<IGroup<IDisplayVehicle>[]>;

    public onUpdateButtonClick(): void {
        super.toggleFilter(true);

        this.setRendererConfig();
        this.updateData();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.setRendererConfig();
        this.updateData();
    }

    ngOnDestroy(): void { }

    private updateData(): void {
        this.setProcessing();

        this.groupedVehicles$ = combineLatest(this.monSvc.getVehicles$, this.monSvc.visualInspections$).pipe(
            map((v) => {
                if (this.SpecsToShow.length === 0)
                    return v;

                //Not sure there is an easier way to do this. Might move this to filter on the server - but it works for now
                //Filter vehicles on selected specs
                //Filter issues on remaining vehicles
                //Update original variable and return it
                let x = v[0].filter(veh => this.SpecsToShow.some(sp => sp === veh.spec.model)); 
                let y = v[1].filter(vi => x.some(veh => veh.equipmentId === vi[0]));
                v[0] = x;
                v[1] = y
                return v;
            }),
            map(v => this.sortVehicles(v, this.displayBySelectedItem.value)),
            map(v => this.groupBy(v, this.sortBySelectedItem.value)),
            tap(x => this.isProcessing = false)
        );
    }

    /// Used to configure wheel colours and legend
    private setRendererConfig() {
        switch (this.displayBySelectedItem.value) {
            case 0: //Action status
                this.rendererConfig.lookupTable = ["#DDDDDD", "#6677EE", "#AA66EE", "#EEAA00", "#DD5555"];
                this.rendererConfig.issues.set(0, "NoIssue");
                this.rendererConfig.issues.set(1, "ContMonitor");
                this.rendererConfig.issues.set(2, "ContMonitorDue");
                this.rendererConfig.issues.set(3, "SchedRemoval");
                this.rendererConfig.issues.set(4, "SchedRemovalDue");
                break;

            case 1: //Recommended action
            default:
                this.rendererConfig.lookupTable = ["#DDDDDD", "#6677EE", "#E0D400", "#E08E23", "#DD5555"];
                this.rendererConfig.issues.set(0, "NoIssue");
                this.rendererConfig.issues.set(1, "ContMonitor");
                this.rendererConfig.issues.set(2, "SchedRemoval30");
                this.rendererConfig.issues.set(3, "SchedRemoval7");
                this.rendererConfig.issues.set(4, "SchedRemoval1");
        }
    }

    private sortVehicles(data: [IDisplayVehicle[], [string, IVisualInspectionBasicApi[]][]], displayBy: number): IDisplayVehicle[] {
        let vehicles = data[0];
        let issues = data[1];
        let now = new Date();

        for (let issue of issues) {
            let broombroom = vehicles.find((veh) => { return veh.equipmentId === issue[0] })
            var newIssueList: (MonitoringIssueData)[] = [];

            //Per vehicle NOT issue
            let axles = broombroom.spec?.axles;
            let posLabels = [].concat(...axles);

            for (let item of issue[1]) {
                switch (displayBy) {
                    case 0: //Action status
                        let notContMon: boolean = item.action != CONTINUE_TO_MONITOR_RES;
                        let isDue: boolean = (item.daysToResolve === 0)
                            ? now >= this.addDays(new Date(item.inspectionDate), item.inspectionFrequencyDays)
                            : now >= this.addDays(new Date(item.inspectionDate), item.daysToResolve);
                        let mi = (+notContMon << 1) + (+isDue) + 1; //TODO: bad hack
                        //ensure each position only has the most serious issue - smaller number is less serious issue
                        let miDupIdx = newIssueList.findIndex(i => i.posLabel === item.posLabel);
                        if (miDupIdx < 0 || mi > newIssueList[miDupIdx].issue) {
                            let newIssue = <MonitoringIssueData>item;
                            newIssue.issue = mi;
                            newIssue.posIdx = posLabels.indexOf(item.posLabel);
                            newIssue.issueText = `Pos ${item.posLabel}: ` + this.xlatPipe.transform(`conditionmonitoring.${this.rendererConfig.issues.get(mi)}`);
                            if (miDupIdx < 0) {
                                newIssueList.push(newIssue);
                            } else {
                                newIssueList[miDupIdx] = newIssue;
                            }
                        }
                        break;

                    case 1: //Recommended action
                    default:
                        let contMon: boolean = item.action === CONTINUE_TO_MONITOR_RES;
                        let daysDue: number = item.daysToResolve;
                        let mis = contMon ? 1 : daysDue === 1 ? 4 : daysDue === 7 ? 3 : 2;

                        //ensure each position only has the most serious issue - smaller number is longer time
                        let misDupIdx = newIssueList.findIndex(i => i.posLabel === item.posLabel);
                        if (misDupIdx < 0 || mis > newIssueList[misDupIdx].issue) {
                            let newIssue = <MonitoringIssueData>item;
                            newIssue.issue = mis;
                            newIssue.posIdx = posLabels.indexOf(item.posLabel);
                            newIssue.issueText = `Pos ${item.posLabel}: ` + this.xlatPipe.transform(`conditionmonitoring.${this.rendererConfig.issues.get(mis)}`);
                            if (misDupIdx < 0) {
                                newIssueList.push(newIssue);
                            } else {
                                newIssueList[misDupIdx] = newIssue;
                            }
                        }
                }
            }
            broombroom.issues = newIssueList;
            let issueText = newIssueList.sort((a, b) => parseInt(a.posLabel) - parseInt(b.posLabel)).map(m => m.issueText).join("<br />") || this.xlatPipe.transform("conditionmonitoring.NoIssue");//Sort the array here for now. PosLabels were in DESC order (from the database - may have been because of date ordering - leave the sort in anyway?)
            broombroom.altText = this.xlatPipe.transform("conditionmonitoring.VisualInspections") + ":<br />" + issueText;
        }

        return vehicles;
    }
}
