import { Injectable } from '@angular/core';
import { LiveForecastGroupValueValidation, LiveForecastRimApplyFilter, LiveForecastRimDisplayOption, LiveForecastRimElement, LiveForecastRimFilterOption, LiveForecastRimGroup, LiveForecastRimResponse, LiveForecastRimSelectedFilter, LiveForecastRimSelectedFilterItem, LiveForecastSelectedGroupCount, LiveForecastSelectionFilterOption } from '../Interface/Widget.interface';
import { FilterConstant } from '../Interface/WidgetConstant.interface';
import { WidgetConstant } from '../widgetHelper/WidgetConstant';

@Injectable({
    providedIn: 'root'
})

export class LiveForecastRimWidgetHelper {

    constructor(private constant: WidgetConstant) { }



    /**If Change Type in Dialog has triggered, return the selection of group*/
    public getSelectedRimGroup(selectedDisplay: string, result: LiveForecastRimResponse) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();

        if (selectedDisplay === filterConstant.allRim) {
            return result.allRims;
        }
        if (selectedDisplay === filterConstant.scrapRim) {
            return result.scrapRim;
        }
        if (selectedDisplay === filterConstant.ndtRim) {
            return result.ndtRim;
        }
    }

    /**If Forecast Period in Dialog has triggered, return the selection of element*/
    public getSelectedRim(selectedDisplay: string, group: LiveForecastRimGroup) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();

        if (selectedDisplay === filterConstant.overDue) {
            return group.overDue;
        }
        if (selectedDisplay === filterConstant.next2Weeks) {
            return group.next2Weeks;
        }
        if (selectedDisplay === filterConstant.next2To4Weeks) {
            return group.next2To4Weeks;
        }
        if (selectedDisplay === filterConstant.next4To8Weeks) {
            return group.next4To8Weeks;
        }
        if (selectedDisplay === filterConstant.next8To12Weeks) {
            return group.next8To12Weeks;
        }
    }

    /**If Forecast Period in Dialog has triggered, return the default filterOption based on the period*/
    public getSelectedFilterOption(selectedDuration: string, group: LiveForecastRimGroup) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();

        if (selectedDuration === filterConstant.overDue) {

            let selectedFilterOption: LiveForecastRimSelectedFilterItem = {
                rimSizeList: group.filterOption.rimSizeList.overDue,
                rimSpecificationList: group.filterOption.rimSpecificationList.overDue,
                vehicleSerialNumberList: group.filterOption.vehicleSerialNumberList.overDue,
                vehicleSpecificationList: group.filterOption.vehicleSpecifiationList.overDue,
                filter: this.setDefaultFilter()
            }

            return selectedFilterOption;
        }

        if (selectedDuration === filterConstant.next2Weeks) {

            let selectedFilterOption: LiveForecastRimSelectedFilterItem = {
                rimSizeList: group.filterOption.rimSizeList.next2Weeks,
                rimSpecificationList: group.filterOption.rimSpecificationList.next2Weeks,
                vehicleSerialNumberList: group.filterOption.vehicleSerialNumberList.next2Weeks,
                vehicleSpecificationList: group.filterOption.vehicleSpecifiationList.next2Weeks,
                filter: this.setDefaultFilter()
            }

            return selectedFilterOption;
        }

        if (selectedDuration === filterConstant.next2To4Weeks) {

            let selectedFilterOption: LiveForecastRimSelectedFilterItem = {
                rimSizeList: group.filterOption.rimSizeList.next2To4Weeks,
                rimSpecificationList: group.filterOption.rimSpecificationList.next2To4Weeks,
                vehicleSerialNumberList: group.filterOption.vehicleSerialNumberList.next2To4Weeks,
                vehicleSpecificationList: group.filterOption.vehicleSpecifiationList.next2To4Weeks,
                filter: this.setDefaultFilter()
            }

            return selectedFilterOption;
        }

        if (selectedDuration === filterConstant.next4To8Weeks) {

            let selectedFilterOption: LiveForecastRimSelectedFilterItem = {
                rimSizeList: group.filterOption.rimSizeList.next4To8Weeks,
                rimSpecificationList: group.filterOption.rimSpecificationList.next4To8Weeks,
                vehicleSerialNumberList: group.filterOption.vehicleSerialNumberList.next4To8Weeks,
                vehicleSpecificationList: group.filterOption.vehicleSpecifiationList.next4To8Weeks,
                filter: this.setDefaultFilter()
            }

            return selectedFilterOption;
        }
        if (selectedDuration === filterConstant.next8To12Weeks) {

            let selectedFilterOption: LiveForecastRimSelectedFilterItem = {
                rimSizeList: group.filterOption.rimSizeList.next8To12Weeks,
                rimSpecificationList: group.filterOption.rimSpecificationList.next8To12Weeks,
                vehicleSerialNumberList: group.filterOption.vehicleSerialNumberList.next8To12Weeks,
                vehicleSpecificationList: group.filterOption.vehicleSpecifiationList.next8To12Weeks,
                filter: group.filterOption.filter
            }
            return selectedFilterOption;
        }
    }


    /**Set up the default forecast period by checking the length of elements*/
    public getDefaultDurationDropDownMenu(validate: LiveForecastGroupValueValidation) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        let defaultDurationList = [];
        defaultDurationList.push(filterConstant.overDue);
        defaultDurationList.push(filterConstant.next2Weeks);
        defaultDurationList.push(filterConstant.next2To4Weeks);
        defaultDurationList.push(filterConstant.next4To8Weeks);
        defaultDurationList.push(filterConstant.next8To12Weeks);

        return defaultDurationList;
    }


    /**Priority is return the overDue however, if there no overDue, return first available item*/
    public getDefaultDurationSelected(list: Array<string>) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();

        if (list.includes(filterConstant.overDue)) {
            return filterConstant.overDue;
        } else {
            return list.length > 0 ? list[0] : '';
        }
    }


    public getDefaultSelectionDropDownMenu() {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();

        return [filterConstant.allRim, filterConstant.ndtRim, filterConstant.scrapRim];
    }

    public getValidatedDisplayOption(selectedDisplay: string): LiveForecastRimDisplayOption {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        return {
            isAllRim: selectedDisplay === filterConstant.allRim,
            isScrapRim: selectedDisplay === filterConstant.scrapRim,
            isNdtRim: selectedDisplay === filterConstant.ndtRim
        };
    }

    /**Return default selected change type*/
    public getDefaultSelectedDisplay() {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();

        return filterConstant.ndtRim;
    }

    /**Return total count of overdue, next2weeks, next4To8Weeks, and next8To12Weeks*/
    public setLiveForecastGroupCount(rim: LiveForecastRimGroup) {
        let validate = this.validateLiveForecastRimElementLength(rim);
        let count: LiveForecastSelectedGroupCount = {
            overDue: validate.hasOverDue ? rim.overDue.length : 0,
            next2Weeks: validate.hasNext2Weeks ? rim.next2Weeks.length : 0,
            next2To4Weeks: validate.hasNext2To4Weeks ? rim.next2To4Weeks.length : 0,
            next4To8Weeks: validate.hasNext4To8Weeks ? rim.next4To8Weeks.length : 0,
            next8To12Weeks: validate.hasNext8To12Weeks ? rim.next8To12Weeks.length : 0
        }
        return count;
    }


    /**Validate selected rim size list and return it*/
    public setSelectedRimSizeList(selectedList: Array<string>, selectionList: Array<string>) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        let lastIndex: number = selectedList.length - 1;
        let isEmptyList: boolean = selectedList.length === 0;
        let isMaximumItem: boolean = (selectionList.length - 1) === selectedList.length;
        let isFirstAllRimSize: boolean = selectedList[0] === filterConstant.allRimSize;
        let isLastAllRimSize: boolean = selectedList[lastIndex] === filterConstant.allRimSize;
        let defaultRimSizeList: Array<string> = [filterConstant.allRimSize];
        let removedallRimSizeList: Array<string> = selectedList.filter(size => { return size !== filterConstant.allRimSize });

        return (isEmptyList || isLastAllRimSize || isMaximumItem) ? defaultRimSizeList : isFirstAllRimSize ? removedallRimSizeList : selectedList;
    }


    /**Validate selected rim specification list and return it*/
    public setSelectedRimSpecificationList(selectedList: Array<string>, selectionList: Array<string>) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        let lastIndex: number = selectedList.length - 1;
        let isEmptyList: boolean = selectedList.length === 0;
        let isMaximumItem: boolean = (selectionList.length - 1) === selectedList.length;
        let isFirstAllRimSpecification: boolean = selectedList[0] === filterConstant.allRimSpecification;
        let isLastAllRimSpecification: boolean = selectedList[lastIndex] === filterConstant.allRimSpecification;
        let defaultRimSpecificationList: Array<string> = [filterConstant.allRimSpecification];
        let removedAllRimSpecificationList: Array<string> = selectedList.filter(size => { return size !== filterConstant.allRimSpecification });

        return (isEmptyList || isLastAllRimSpecification || isMaximumItem) ? defaultRimSpecificationList : isFirstAllRimSpecification ? removedAllRimSpecificationList : selectedList;
    }


    /**Validate selected vehicle serial number list and return it*/
    public setSelectedVehicleSerialNumberList(selectedList: Array<string>, selectionList: Array<string>) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        let isEmptyList: boolean = selectedList.length === 0;
        let isMaximumItem: boolean = (selectionList.length - 1) === selectedList.length;
        let isFirstAllSerialNumberItem: boolean = selectedList[0] === filterConstant.allVehicleSerialNumber;
        let isLastAllSerialNumberItem: boolean = selectedList[selectedList.length - 1] === filterConstant.allVehicleSerialNumber;
        let defaultList = [filterConstant.allVehicleSerialNumber];
        let removedAllSerialNumberItemList = selectedList.filter(size => { return size !== filterConstant.allVehicleSerialNumber });
        let isDefault = isEmptyList || isMaximumItem || isLastAllSerialNumberItem;

        return isDefault ? defaultList : isFirstAllSerialNumberItem ? removedAllSerialNumberItemList : selectedList;
    }


    /**Validate selected vehicle specification list and return it*/
    public setSelectedVehicleSpecificationList(selectedList: Array<string>, selectionList: Array<string>) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        let lastIndex: number = selectedList.length - 1;
        let isEmptyList: boolean = selectedList.length === 0;
        let isMaximumItem: boolean = (selectionList.length - 1) === selectedList.length;
        let isFirstAllVehicleSpecification: boolean = selectedList[0] === filterConstant.allVehicleSpecification;
        let isLastAllVehicleSpecification: boolean = selectedList[lastIndex] === filterConstant.allVehicleSpecification;
        let defaultVehicleSpecificationList: Array<string> = [filterConstant.allVehicleSpecification];
        let removedAllVehicleSpecificationList: Array<string> = selectedList.filter(size => { return size !== filterConstant.allVehicleSpecification });

        return (isEmptyList || isLastAllVehicleSpecification || isMaximumItem) ? defaultVehicleSpecificationList : isFirstAllVehicleSpecification ? removedAllVehicleSpecificationList : selectedList;
    }


    /**Set the apply trigger filter*/
    public setApplyFilter(filterOption: LiveForecastRimSelectedFilterItem) {
        let validateFilter = this.validateApplySelectedFilter(filterOption);

        let filter: LiveForecastRimApplyFilter = {
            rimSizeList:
                validateFilter.isAllTyreSize ? filterOption.rimSizeList : filterOption.filter.rimSize,
            rimSpecificationList:
                validateFilter.isAllTyreSpecification ? filterOption.rimSpecificationList : filterOption.filter.rimSpecification,
            vehicleSerialNumberList:
                validateFilter.isAllVehicleSerialNumber ? filterOption.vehicleSerialNumberList : filterOption.filter.vehicleSerialNumber,
            vehicleSpecificationList:
                validateFilter.isAllVehicleSpecification ? filterOption.vehicleSpecificationList : filterOption.filter.vehicleSpecification
        }
        return filter;
    }


    /**Validate the selected filter contains default filter*/
    private validateApplySelectedFilter(filterOption: LiveForecastRimSelectedFilterItem) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();

        let validatedAppliedFilter = {
            isAllTyreSize: filterOption.filter.rimSize.includes(filterConstant.allRimSize),
            isAllTyreSpecification: filterOption.filter.rimSpecification.includes(filterConstant.allRimSpecification),
            isAllVehicleSerialNumber: filterOption.filter.vehicleSerialNumber.includes(filterConstant.allVehicleSerialNumber),
            isAllVehicleSpecification: filterOption.filter.vehicleSpecification.includes(filterConstant.allVehicleSpecification)
        };
        return validatedAppliedFilter;
    }

    public setFilterOption(rim: LiveForecastRimGroup) {
        let validate = this.validateLiveForecastRimElementLength(rim);
        let filterOption: LiveForecastRimFilterOption = {
            vehicleSerialNumberList: this.setVehicleSerialNumberFilterOption(rim, validate),
            vehicleSpecifiationList: this.setVehicleSpecificationFilterOption(rim, validate),
            rimSizeList: this.setRimSizeFilterOption(rim, validate),
            rimSpecificationList: this.setRimSpecificationFilterOption(rim, validate),
            filter: this.setDefaultFilter(),
            validate: validate
        };

        return filterOption;
    }

    /**Validate the rim group has elements*/
    public validateLiveForecastRimElementLength(rim: LiveForecastRimGroup) {
        let validate: LiveForecastGroupValueValidation = {
            hasOverDue: rim.overDue.length > 0,
            hasNext2Weeks: rim.next2Weeks.length > 0,
            hasNext2To4Weeks: rim.next2To4Weeks.length > 0,
            hasNext4To8Weeks: rim.next4To8Weeks.length > 0,
            hasNext8To12Weeks: rim.next8To12Weeks.length > 0
        };

        return validate
    }

    /**Set the default selected filter*/
    private setDefaultFilter() {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        let selected: LiveForecastRimSelectedFilter = {
            vehicleSerialNumber: [filterConstant.allVehicleSerialNumber],
            vehicleSpecification: [filterConstant.allVehicleSpecification],
            rimSize: [filterConstant.allRimSize],
            rimSpecification: [filterConstant.allRimSpecification]
        };

        return selected
    }


    /**Set up the default vehicle serial number filter option*/
    private setVehicleSerialNumberFilterOption(rim: LiveForecastRimGroup, validate: LiveForecastGroupValueValidation) {
        let vehicleSerialNumbers: LiveForecastSelectionFilterOption = {
            overDue: validate.hasOverDue ? this.getVehicleSerialNumberList(rim.overDue) : [],
            next2Weeks: validate.hasNext2Weeks ? this.getVehicleSerialNumberList(rim.next2Weeks) : [],
            next2To4Weeks: validate.hasNext2To4Weeks ? this.getVehicleSerialNumberList(rim.next2To4Weeks) : [],
            next4To8Weeks: validate.hasNext4To8Weeks ? this.getVehicleSerialNumberList(rim.next4To8Weeks) : [],
            next8To12Weeks: validate.hasNext8To12Weeks ? this.getVehicleSerialNumberList(rim.next8To12Weeks) : [],
        }
        return vehicleSerialNumbers;
    }

    /**Set up the default vehicle serial number filter option list*/
    private getVehicleSerialNumberList(rimElement: Array<LiveForecastRimElement>) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        let list = [];
        let defaultSelection = [filterConstant.allVehicleSerialNumber];

        //Add vehicle SerialNumber
        rimElement.forEach(rim => {
            if (!list.includes(rim.fittedVehicle.vehicleSerialNumber)) {
                list.push(rim.fittedVehicle.vehicleSerialNumber);
            }
        });
        list.sort();

        // Return always default selection is first
        return defaultSelection.concat(list);
    }

    /**Set up the default vehicle specification filter option*/
    private setVehicleSpecificationFilterOption(rim: LiveForecastRimGroup, validate: LiveForecastGroupValueValidation) {
        let vehicleSpecifiations: LiveForecastSelectionFilterOption = {
            overDue: validate.hasOverDue ? this.getVehicleSpecificationList(rim.overDue) : [],
            next2Weeks: validate.hasNext2Weeks ? this.getVehicleSpecificationList(rim.next2Weeks) : [],
            next2To4Weeks: validate.hasNext2To4Weeks ? this.getVehicleSpecificationList(rim.next2To4Weeks) : [],
            next4To8Weeks: validate.hasNext4To8Weeks ? this.getVehicleSpecificationList(rim.next4To8Weeks) : [],
            next8To12Weeks: validate.hasNext8To12Weeks ? this.getVehicleSpecificationList(rim.next8To12Weeks) : [],
        }

        return vehicleSpecifiations
    }

    /**Set up the default vehicle specification filter option list*/
    private getVehicleSpecificationList(rimElement: Array<LiveForecastRimElement>) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        let list = [];
        let defaultSelection = [filterConstant.allVehicleSpecification];

        //Add vehicle Specification
        rimElement.forEach(rim => {
            if (!list.includes(rim.fittedVehicle.vehicleSpecification)) {
                list.push(rim.fittedVehicle.vehicleSpecification);
            }
        });
        list.sort();

        // Return always default selection is first
        return defaultSelection.concat(list);
    }


    /**Set up the default rim size filter option*/
    private setRimSizeFilterOption(rim: LiveForecastRimGroup, validate: LiveForecastGroupValueValidation) {
        let rimSizes: LiveForecastSelectionFilterOption = {
            overDue: validate.hasOverDue ? this.getRimSizeList(rim.overDue) : [],
            next2Weeks: validate.hasNext2Weeks ? this.getRimSizeList(rim.next2Weeks) : [],
            next2To4Weeks: validate.hasNext2To4Weeks ? this.getRimSizeList(rim.next2To4Weeks) : [],
            next4To8Weeks: validate.hasNext4To8Weeks ? this.getRimSizeList(rim.next4To8Weeks) : [],
            next8To12Weeks: validate.hasNext8To12Weeks ? this.getRimSizeList(rim.next8To12Weeks) : [],
        }
        return rimSizes;
    }

    /**Set up the default rim size filter option list*/
    private getRimSizeList(rimElement: Array<LiveForecastRimElement>) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        let list = [];
        let defaultSelection = [filterConstant.allRimSize];

        //Add Tye size
        rimElement.forEach(rim => {
            if (!list.includes(rim.rimSize)) {
                list.push(rim.rimSize);
            }
        });
        list.sort();

        // Return always default selection is first
        return defaultSelection.concat(list);
    }


    /**Set up the default rim specification filter option*/
    private setRimSpecificationFilterOption(rim: LiveForecastRimGroup, validate: LiveForecastGroupValueValidation) {
        let rimSpecifications: LiveForecastSelectionFilterOption = {
            overDue: validate.hasOverDue ? this.getRimSpecificationList(rim.overDue) : [],
            next2Weeks: validate.hasNext2Weeks ? this.getRimSpecificationList(rim.next2Weeks) : [],
            next2To4Weeks: validate.hasNext2To4Weeks ? this.getRimSpecificationList(rim.next2To4Weeks) : [],
            next4To8Weeks: validate.hasNext4To8Weeks ? this.getRimSpecificationList(rim.next4To8Weeks) : [],
            next8To12Weeks: validate.hasNext8To12Weeks ? this.getRimSpecificationList(rim.next8To12Weeks) : [],
        }

        return rimSpecifications;
    }

    /**Set up the default rim specification filter option List*/
    private getRimSpecificationList(rimElement: Array<LiveForecastRimElement>) {
        let filterConstant: FilterConstant = this.constant.getFilterConstant();
        let list = [];
        let defaultSelection = [filterConstant.allRimSpecification];

        //Add rim Specification
        rimElement.forEach(rim => {
            if (!list.includes(rim.rimSpecification)) {
                list.push(rim.rimSpecification);
            }
        });
        list.sort();
        // Return always default selection is first
        return defaultSelection.concat(list);
    }

}