//import angular from 'angular';

    angular.module('app.controllers').controller(
            'SessionCtrl',
		['$state', '$stateParams', '$scope',
            function ($state, $stateParams, $scope) {

                var vm = this;

                vm.sessionKey = $stateParams.sessionKey;

                if ($scope.app.operation) {
                    vm.operation = $scope.app.operation.toLowerCase();
                }                
                vm.message = "";
                switch (vm.operation) {
                    case "component":
                        vm.requestedState = 'app.component.search';
                        break;
                    case "purchaseorders":
                        vm.requestedState = 'app.site.purchaseOrders';
                        break;
                    case "vehicle":
                        vm.requestedState = 'app.vehicle.search';
                        break;
                    case "forecasting":
                        vm.requestedState = 'app.site.forecastlist';
                        break;
                    case "bulkmove":
                        vm.requestedState = 'app.component.bulkMove';
                        break;
                    case "stockcode":
                        vm.requestedState = 'app.admin.stockCodes';
                        break;
                    case "backupstocklevel":
                        vm.requestedState = 'app.admin.backupStockLevels';
                        break;
                    case "user":
                        vm.requestedState = 'app.admin.users';
                        break;
                    case "mw_warranty":
                        vm.requestedState = 'app.component.mwWarranty';
                        break;
                    case "performancebatch":
                        vm.requestedState = 'app.admin.performanceBatches';
                        break;
                    case "fleet":
                        vm.requestedState = 'app.admin.fleets';
                        break;
                    case "alerttrigger":
                        vm.requestedState = 'app.admin.alerts';
                        break;
                    case "componentreceive":
                        vm.requestedState = 'app.component.receive';
                        break;
                    case "vehiclereceive":
                        vm.requestedState = 'app.vehicle.receive';
                        break;
                    case "fieldsurveyfrequency":
                        vm.requestedState = 'app.admin.fsFrequency';
                        break;
                    case "stocktake":
                        vm.requestedState = 'app.component.stocktake';
                        break;
                    default:
                        vm.requestedState = 'app.landing';
                        break;
                }

                if ($state.href(vm.requestedState)) {
                    $state.go(vm.requestedState);
                }
                else {
                    console.error('Failed to find state:' + vm.requestedState);
                    $state.go('app.landing');
                }
            }]);
