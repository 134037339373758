import 'bootstrap-timepicker/js/bootstrap-timepicker';
    
import * as _ from 'underscore';

/**
 * @ngdoc directive
 * @name amtFramework.formControls.directive:amtTimePicker
 * @restrict E
 * 
 * @param {string} name Name, used for validation
 * @param {number} maxHours Max number of hours. Currently not used defaulted to 24
 * @param {expression} ngModel Two way binding of time
 * @param {expression=} [ngRequired=false] Required
 * @param {expression=} [ngDisabled=false] Disabled
 * @param {callback=} onTimeChangeHandler Fired when the value is changed
 * 
 */

//import angular from 'angular';
import tmpl from './amtTimePicker.html';


angular.module('amtFramework.formControls').directive('amtTimePicker', ['$timeout',
    function ($timeout) {
            return {
                restrict: 'E',
                require: ['ngModel', '^?form'],
                scope: {
                    id: '@',
                    name: '@',
                    maxHours: '@',
                    amtModel: '=?ngModel',
                    isRequired: '=?',
                    isDisabled: '=',
                    onTimeChangeHandler: '&onTimeChange'
                },
                template: tmpl,
                link: function (scope: any, element, attrs, ctrls) {
                    scope.time = scope.amtModel;
                    var form = ctrls[1];
                 
                    var timePickerElement = element.find('input');

                    //HACK: don't leave this garbage any cast
                    (<any>timePickerElement).timepicker({
                        defaultTime: scope.time,
                        minuteStep: 1,
                        showSeconds: false,
                        showMeridian: false,
                        showInputs: true,
                        maxHours: scope.maxHours
                    }).on('show.timepicker', function (e) { // dirty hack because the placement code doesn't work properly
                        setTimeout(function () { // timeout is necessary so that the modified dom is actually accessible.
                            $('.bootstrap-timepicker-widget.dropdown-menu.open').css({
                                left: timePickerElement.offset().left
                            });
                        });
                    });

                    // if time is passed in need to ensure it's not dirty
                    form.$setPristine();
                    timePickerElement.bind('propertychange change', handleTimeChange);

                    handleFormValidity();

                    function handleFormValidity() {
                        if (scope.time === undefined && scope.isRequired) {
                            element.find('input').addClass('ng-invalid-required');
                        }
                        else {
                            element.find('input').removeClass('ng-invalid-required');
                        }
                    }

                    //HACK:
                    (<any>timePickerElement).timepicker().on('changeTime.timepicker', function (e) {
                        if (!scope.amtModel) {
                            scope.amtModel = new Date();
                        }
                        scope.amtModel.setHours(e.time.hours);
                        scope.amtModel.setMinutes(e.time.minutes);
                        scope.amtModel.setSeconds(e.time.seconds);
                        scope.amtModel.setMilliseconds(0);

                        _.defer(function () {
                            scope.$apply();
                        });

                        handleFormValidity();

                        if (scope.onTimeChangeHandler()) {
                            scope.onTimeChangeHandler()(e.time);
                        }
                    });

                    scope.$watch('isRequired', function (newValue, oldValue) {
                        if (newValue === oldValue) {
                            return;
                        }
                        handleFormValidity();
                    });

                    scope.$watch("amtModel", function (newValue, oldValue) {
                        (<any>timePickerElement).timepicker('setTime', newValue); //HACK: any cast
                        handleFormValidity();
                    });

                    function handleTimeChange() {
                        // dirty hack because the timepicker doesn't fire the changeTime.timepicker event when the time is cleared
                        if (timePickerElement.val() === "") {
                            $timeout(function () {
                                scope.amtModel = null;
                            }, 200);                           
                            (<any>timePickerElement).timepicker('setTime', ""); //HACK: any cast
                        }
                    }
                }
            };
    }
]);
