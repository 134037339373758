//import angular from 'angular';
import FileManagement from '../../../services/fileManagement';
import BrowserSvc from '../../../services/browserSvc';
import FieldSurveyUrls from '../../equipment/vehicle/fieldSurvey/fieldSurveyUrls';
import tmpl from "./fieldSurveys.html";


const enum FieldSurveyTab {
    tyreReadings = 'TyreReadings',
    vehicleReadings = 'VehicleReadings',
    fittedComponents = 'FittedComponents'
}

type TVehicle = {
    id: string,
    displayDescription: string,
    lastPressureReading: Date,
    lastTreadReading: Date,
    nextPressureReading: Date,
    nextTreadReading: Date,
    serialNumber: string
};

type FieldSurveys_VehiclesData = {
    result: TVehicle[]
};

type FieldSurveys_InitParams = {
    id?: string,
    equipmentId?: string,
    serialNumber?: string,
    date?: Date,
    closeWindowOnNoDate?: boolean
};

// ReSharper disable once InconsistentNaming
class fieldSurveysCtrl implements ng.IController {

    wnd: IWindowObj = null;
    apiUrls: FieldSurveyUrls;
    processing = true; // flag used to display busy when on-line direct upload
    fieldSurveyLoading: boolean = false; // not sure we need this & processing, but unlike processing I think it displays behind the picker (?)
    isMobile: boolean;
    vehicles: FieldSurveys_VehiclesData = null;
    vehiclesLoaded: boolean;

    currentTab: FieldSurveyTab = FieldSurveyTab.vehicleReadings; // tab selection
    initParams: FieldSurveys_InitParams;
    vehicleId: string;

    selectedVehicleId: guid = null; //Do not use this - this is only for the watch
    selectedVehicle: any = null; //Do not use this - this is only for the watch

    schematicOn: boolean = true;  // whether to show the vehicle schematic
    schematicClickOff: () => void; // deregistration callback for our schematic listener
    readonly: boolean;
    canDelete: boolean;
    error: any; //this probably needs a proper type but we use it a bit creatively at the moment
    defaultDate: Date;
    readingGridForm: ng.IFormController;
    system: any; //might be used on mobile? may be not, but unsure, not used on desktop

    fieldSurvey: any = {}; // we keep this set to an empty object even if there is no field survey to keep the code more simple, check fieldSurvey.id to see if we have one atm

    vehicleHasMeters: boolean = true;

    pressureReadingTypes: any[];

    allowRtdInReadingGrid: boolean = false;

    // schematic details
    readonly schematicMargin = 20;
    readonly schematicDesktopMargin = 8;
    readonly schematicNoChangesWidth = 210;
    schematicMaxWidth: number = 560;
    schematicMaxHeight: number = 210;

    //needed for bound directives/controllers
    fitmentsGrid: any = {};

    vehiclePicker: any = {};

    topAxle = 1; //bound to ocVehicleSchematic

    activeError: any = {};
    scaleSchematic = false; //might be used in child scopes?
    showReadOrder: boolean = false; //readingGrid

    //picker search properties
    displayDescription = '';
    displayPressureDue = false;
    displayTreadDue = false;

    displayKeyPadLeft;
    public readingGridSave = (validate: boolean = false) => this.validateAndSelectTab(null, validate, validate);
    schematicSelection = (posId: guid, sel: string) => this.$scope.$broadcast("SCHEMATIC_SELECTION", posId, sel);

    _dirty: boolean = false;
    set dirty(val: boolean) {
        this._dirty = val;
        if (this.readingGridForm)
            this.readingGridForm.$setPristine();
    }
    get dirty() { return this._dirty || (!!this.readingGridForm && this.readingGridForm.$dirty); }

    get vehicleSelected() { return this.vehicleId || this.fieldSurvey.id; }

    get readingActive() { return this.currentTab === FieldSurveyTab.tyreReadings && this.vehicleSelected; }
    get vehicleActive() { return this.currentTab === FieldSurveyTab.vehicleReadings && this.vehicleSelected; }

    static $inject = ["$rootScope", "$scope", "dataBroker", "errorReporter", "WindowFactory",
        "ocConfigSvc", "$state", "$timeout", "fieldSurveyUrls", "browserSvc",
        "amtCommandQuerySvc", "confirmSvc", "amtXlatSvc", "fieldSurveyService", "enums",
        "ocSecuritySvc", "appConfig", "ocDateSvc", "notifySvc", "$window", "amtConstants", "fileManagement"];

    constructor(private $rootScope: ng.IScope, private $scope: ng.IScope, private dataBroker, private errorReporter: any, private WindowFactory: any,
        private ocConfigSvc: any, private $state: any, private $timeout: any, private fieldSurveyUrls: FieldSurveyUrls, browserSvc: BrowserSvc,
        private amtCommandQuerySvc: any, private confirmSvc: any, private amtXlatSvc: any, private fieldSurveyService: any, private enums: any,
        private ocSecuritySvc: any, private appConfig: any, private ocDateSvc: any, private notifySvc: any, private $window: ng.IWindowService, private amtConstants: any,
        private fileManagement: FileManagement) {

        this.apiUrls = fieldSurveyUrls;
        this.isMobile = browserSvc.isMobile;

        try {
            this.dataBroker.checkRefData()
        } catch {
            this.$state.go("mobile.landing");
        }
    }

    public async $onInit() {

        this.processing = true;

        try {

            this.initParams = this.initParams || {};
            this.vehicleId = this.vehicleId || this.initParams.equipmentId;

            this.fieldSurveyLoading = !!this.vehicleId;

            if (this.wnd) {
                this.$scope.$watch(() => this.dirty, val => this.wnd.isDirty = val); // pass along dirty flag to the window for use on closing minimised windows
                this.wnd.onClose = () => this.close();
            }

            this.$scope.$watch(() => this.selectedVehicleId, newVal => this.onVehicleSelected(newVal));
            this.schematicClickOff = this.$scope.$on("SchematicClicked", (ev, pos, sel) => this.onSchematicClicked(ev, pos, sel));

            this.setSchematicWidth();

            if (this.isMobile) {
                //HACK: 500ms delay via $timeout is a hack for iOS being buggy https://bugs.webkit.org/show_bug.cgi?id=170595
                //when the event triggers the width and height may not be correct
                angular.element(this.$window).on('resize.fieldSurveys', () => this.$timeout(() => this.setSchematicWidth(), 500));
            }

            this.readonly = !this.ocSecuritySvc.isAuthorised(this.vehicleSelected ? "Security.Vehicles.FieldSurveys.Add" : "Security.Vehicles.FieldSurveys", AccessTypes.readWrite);
            this.canDelete = this.ocSecuritySvc.isAuthorised("Security.Vehicles.FieldSurveys.Delete", AccessTypes.readWrite);

            // no vehicle selected yet, so we are loading the vehicle picker
            if (!this.vehicleSelected) {
                await this.loadVehiclePicker(true);
                return;
            }

            try {
                this.defaultDate = this.initParams.date;
                this.system = await this.dataBroker.getFieldSurveySettings();

                await this.showVehicle(this.vehicleId, this.initParams.id);
            } catch (error) {
                // OK page is never going to be ready -- but we need to allow the user to close the window and move on
                this.errorReporter.logError(error);
                this.error = this.errorReporter.exceptionMessage(error);
                this.WindowFactory.alert("exception.internal_fault_heading", ["common.ok_label"], "exception.serverError", this.errorReporter.exceptionMessage(error));
                this.loadVehiclePicker();
            }

        } finally {
            this.processing = false;
        }
    }

    $onDestroy() {
        angular.element(this.$window).off('resize.fieldSurveys');
        this.schematicClickOff();
    }

    public setWindowTitle() {
        if (!this.isMobile && this.wnd) {

            let fieldSurveyDate = this.fieldSurvey.createdDate;
            let serialNumber = this.fieldSurvey.serialNumber;

            if (fieldSurveyDate && serialNumber) {
                //use jsextension.js from AMT framework / Jeff Sinclair?
                //TODO: Looks unnecessary, but too lazy to review format specifiers and move code to better alternatives at this time
                let dateTimeString = fieldSurveyDate.format(this.appConfig.dateFormat.windowTitleDateTime);
                let dateString = fieldSurveyDate.format(this.appConfig.dateFormat.windowTitleDate);

                this.wnd.caption = this.amtXlatSvc.xlat('fieldSurvey.fieldSurveyTitle', dateTimeString, serialNumber);
                this.wnd.shortCaption = this.amtXlatSvc.xlat('fieldSurvey.fieldSurveyShortTitle', serialNumber, dateString);
            } else {
                this.wnd.caption = this.amtXlatSvc.xlat('fieldSurvey.elementName');
                this.wnd.shortCaption = this.wnd.caption;
            }
        }
    }

    public setSchematicWidth() {
        let mobilePortrait = this.isMobile && this.$window.innerWidth < this.$window.innerHeight
        let keypadWidth = mobilePortrait ? 0 : this.schematicNoChangesWidth;
        this.schematicMaxWidth = (this.isMobile ? this.$window.innerWidth : this.wnd.size.width - this.schematicDesktopMargin) - this.schematicMargin - keypadWidth;

        this.$timeout();
    }

    public schematicToggle() {
        this.schematicOn = !this.schematicOn;
    }

    public async validateAndSelectTab(tabName?: FieldSurveyTab, validate: boolean = true, focus = true): Promise<boolean> {
        let valid = true;

        this.toggleRtd();

        try {
            if (validate) {
                this.saveReadingsForMobile();
                await this.fieldSurveyService.validate(this.fieldSurvey);
            }
        } catch (error) {
            valid = false;
            if (error && error.tab) {
                tabName = error.tab; // forget the tab the callee asked to go to, we are going to display the tab with the error
            } else {
                this.errorReporter.logError(error, "FieldSurvey-SelectTab");
            }
        } finally {
            if (tabName && this.currentTab != tabName) {
                this.currentTab = tabName;

                if (focus && this.currentTab == FieldSurveyTab.tyreReadings)
                    this.$scope.$broadcast(Keypad.MODIFIER_KEY_PRESSED, "SET-FOCUS");
            }
            return valid;
        }
    }

    private removeNull(obj) {
        for (var prop in obj) {
            if (!obj.hasOwnProperty(prop))
                continue;
            if (obj[prop] == null) {
                delete obj[prop];
            } else if (typeof obj[prop] === "object") {
                this.removeNull(obj[prop]);
            }
        }
    }

    private toggleRtd() {
        this.allowRtdInReadingGrid = this.ocConfigSvc.user.site.settings.tyreMaintenance.allowTreadReadingsWithoutVehicleReadings ||
            (this.fieldSurvey.hours >= 0 && this.fieldSurvey.hours !== '');
    }

    public async onReturn(returnHome: boolean) {

        this.processing = true;

        try {

            if (this.dirty) {
                if (this.isMobile) { // validate and save changes
                    try {
                        await this.fieldSurveyService.validate(this.fieldSurvey);
                        await this.saveReadingsForMobile();
                    } catch {
                        // validation error already handled
                    }
                } else { // confirm with user if they want to abandon their unsaved changes
                    try {
                        await this.confirmSvc.confirmMessage("fieldSurvey.confirmCloseHeader", "fieldSurvey.confirmCloseMessage")
                    } catch {
                        return; //they cancelled returning to the vehicle list
                    }
                }
            }

            if (returnHome) {
                this.$state.go('mobile.landing'); // return to home screen
            } else {
                if (this.isMobile) {
                    this.$state.go('mobile.fieldSurvey', { vehicleId: null }, { reload: true, location: true }); // reset the controller and return to vehicle selector
                } else {
                    await this.loadVehiclePicker();
                }
            }

        } finally {
            this.processing = false;
        }
    }

    public async close(fireOnDataChanged?: boolean) {

        if (this.dirty) {
            try {
                await this.confirmSvc.confirmMessage("fieldSurvey.confirmCloseHeader", "fieldSurvey.confirmCloseMessage")
            } catch {
                return false; //they cancelled closing
            }
        }

        if (fireOnDataChanged && this.wnd.onDataChanged)
            this.wnd.onDataChanged();

        this.WindowFactory.closeWindow(this.wnd);
        return true;
    }

    public async loadVehiclePicker(reloadVehicleList?: boolean) {

        if (!this.vehicles || reloadVehicleList) { //update vehicle list and refresh picker
            this.vehicles = await this.dataBroker.loadVehicleList();

            for (let v of this.vehicles.result)
                v['highlighted'] = v['hasActiveVI']; //hack for picker                    

            this.vehiclesLoaded = true;

            if (this.vehiclePicker.refresh)
                this.vehiclePicker.refresh();
        }

        // clear out any existing data and reset the window
        if (this.readingGridForm)
            this.readingGridForm.$setPristine();

        this.fieldSurvey = {};
        this.vehicleId = null;
        this.selectedVehicle = null;
        this.selectedVehicleId = null;
        this.error = null;
        this.dirty = false;
        this.setWindowTitle();

        if (this.vehiclePicker.clear)
            this.vehiclePicker.clear();
    }

    private async loadPendingFieldSurveyTransactions(id: string, equipmentId: string, date: Date): Promise<boolean> {

        if (this.isMobile)
            return false;

        let transactions = await this.amtCommandQuerySvc.post(this.apiUrls.getFieldSurveyTransactions, { id: id, equipmentId: equipmentId, date: this.ocDateSvc.dateTimeAsUTC(date) });

        if (!transactions)
            return false;

        transactions.errorList = transactions.errors;

        this.removeNull(transactions); //TODO: do we really need/want this behaviour of this call?

        let ld = [];

        for (let key in this.fieldSurvey.positions) {
            if (this.fieldSurvey.positions.hasOwnProperty(key) &&
                this.fieldSurvey.positions[key].TyreFitment &&
                this.fieldSurvey.positions[key].TyreFitment.loading) {

                ld.push(key);
            }
        }

        // The mobile uses wheel position type to id the positions, because of the way it's built from the the vehicle specifications.
        // Whilst on the desktop we use the wheel positions.
        // This code is needed when opening errored mobile field surveys on the desktop
        for (let r of transactions.positionReadings || []) {
            for (let key in this.fieldSurvey.positions) {
                if (this.fieldSurvey.positions.hasOwnProperty(key) &&
                    this.fieldSurvey.positions[key].label === r.positionLabel) {
                    r.positionId = key;
                }
            }
        }

        // if the server did not return the pendingFieldSurvey id, set it from the id used to open the field survey
        // this is so the field survey can be deleted, and impacts some older errored field surveys
        if (!transactions.pendingFieldSurveyId)
            transactions.pendingFieldSurveyId = id;

        angular.merge(this.fieldSurvey, transactions);

        for (let p of ld) {
            this.fieldSurvey.positions[p].TyreFitment.loading = true;
        }

        // set units
        this.fieldSurvey.units = angular.copy(this.ocConfigSvc.user.site.units);
        this.fieldSurvey.units.distance = this.fieldSurvey.distanceUnit;

        //reload window Id        
        this.wnd.windowRelatedRecordId = this.fieldSurvey.pendingFieldSurveyId;
    }

    public async loadVehicle(id) {
        try {
            this.processing = true;
            this.fieldSurvey = {};

            //update the URL for mobile, but use showVehicle to actually change the state
            if (this.isMobile) {
                this.$state.go('mobile.fieldSurvey', { vehicleId: id }, { reload: false, location: true });
            } else {
                await this.showVehicle(id);
            }

        } finally {
            this.processing = false;
        }
    }

    private async showVehicle(vehicleId: string, id?: string) {
        try {
            this.fieldSurveyLoading = true;

            let date = this.defaultDate ? this.defaultDate : (this.isMobile ? new Date() : await this.promptForSurveyDateTime(vehicleId, true));

            if (!date) { // no date found or they cancelled date selection, return to the vehicle picker
                if (this.initParams.closeWindowOnNoDate) {
                    this.WindowFactory.closeWindow(this.wnd);
                } else {
                    await this.loadVehiclePicker(); //TODO: we should prob return failure and let the caller decide what to do
                }
                return;
            }

            date.setSeconds(0, 0);

            let checkDatesClosure = (created, last, next) => created && ((last && (last >= created)) || (next && (next > last) && (next < created)));

            let vehicleDetails: any = {};

            for (let tried = false; ;) {

                vehicleDetails = await this.dataBroker.getVehicleDetails(vehicleId, this.ocDateSvc.dateTimeAsUTC(tried ? vehicleDetails.createdDate : date));

                if (!vehicleDetails || vehicleDetails.id !== vehicleId) {
                    // they attempted to go to a vehicle I don't have
                    this.loadVehiclePicker();
                    return;
                }

                if (vehicleDetails.createdDate)
                    vehicleDetails.createdDate.setSeconds(0, 0);

                if (vehicleDetails.lastDate)
                    vehicleDetails.lastDate.setSeconds(0, 0);

                if (vehicleDetails.nextReading)
                    vehicleDetails.nextReading.setSeconds(0, 0);


                if (tried || !checkDatesClosure(vehicleDetails.createdDate, vehicleDetails.lastDate, vehicleDetails.nextReading))
                    break;

                tried = true;
            }

            if (this.readingGridForm)
                this.readingGridForm.$setPristine();

            if (!vehicleDetails.createdDate)
                vehicleDetails.createdDate = date;

            if (!vehicleDetails.siteId)
                vehicleDetails.siteId = this.ocConfigSvc.user.site.id;

            vehicleDetails.units = angular.copy(this.ocConfigSvc.user.site.units); // set units
            vehicleDetails.units.distance = vehicleDetails.distanceUnit;

            vehicleDetails.displayDescription = vehicleDetails.serialNumber + ' - ' + vehicleDetails.description;

            this.fieldSurvey = vehicleDetails;

            await this.loadPendingFieldSurveyTransactions(id, vehicleId, date); // load the readings from the existing field survey

            if (!this.fieldSurvey.readingEventTypeCommentId && this.system)
                this.fieldSurvey.readingEventTypeCommentId = this.system.readingEventTypeCommentId;

            if (this.fieldSurvey.readingEventTypeCommentId) {
                let pressureReadingTypesResponse = await this.dataBroker.getPressureReadingTypes();

                if (pressureReadingTypesResponse && pressureReadingTypesResponse.result) {
                    this.pressureReadingTypes = pressureReadingTypesResponse.result;

                    this.fieldSurvey.readingEventTypeComment = this.pressureReadingTypes.find(p => p.id === this.fieldSurvey.readingEventTypeCommentId);
                }
            }

            this.vehicleHasMeters = this.fieldSurvey.hasDistanceMeter || this.fieldSurvey.hasHoursMeter;

            if (!this.vehicleHasMeters)
                this.validateAndSelectTab(FieldSurveyTab.tyreReadings);

            this.setWindowTitle();
            this.toggleRtd();

        } catch (error) {
            this.errorReporter.logError(error, "FieldSurvey-GetVehicleDetails");
            this.error = this.errorReporter.exceptionMessage(error);
            this.WindowFactory.alert("exception.internal_fault_heading", ["common.ok_label"], "exception.serverError", this.errorReporter.exceptionMessage(error));
            this.loadVehiclePicker();
        } finally {
            this.processing = false;
            this.fieldSurveyLoading = false;
        }
    }

    public async saveReadingsForMobile() {
        if (!this.isMobile || !this.dirty)
            return; // no need to save or validate (for desktop there is no pre-validate step)

        try {
            await this.dataBroker.saveFieldSurvey(this.fieldSurvey);
            this.dirty = false;
        } catch (error) {
            this.errorReporter.logError(error, "FieldSurvey-MobileSave");
            throw error;
        }
    }

    public async validateAndSaveDesktop(andNext) {

        try {

            await this.fieldSurveyService.validate(this.fieldSurvey, null, true /*atEnd*/);

            // OK lets save this
            this.processing = true;

            var localCreatedDate = this.fieldSurvey.createdDate;

            try {

                let attachments = [];

                for (let reading of this.fieldSurvey.positionReadings || []) {
                    for (let vi of reading.visualInspections || []) {
                        for (let attachment of vi.attachments || []) {
                            attachment.source = AttachmentType.visualInspection;
                            attachments.push(attachment);
                        }
                    }
                }

                await this.fileManagement.processFileUploads(attachments);

                let errors = await this.fieldSurveyService.directUploadRecord(this.fieldSurvey);

                this.fieldSurvey.errorList = [];

                if (errors.length) {
                    if (errors[0].subErrors) {  // hook the error list in here.
                        for (var i = 0; i < errors[0].subErrors.length; i++) {
                            this.fieldSurvey.errorList.push(errors[0].subErrors[i]);
                        }

                        this.notifySvc.error(errors[0].subErrors.length > 1 ? this.amtXlatSvc.xlat('fieldSurvey.saveMultipleErrors') :
                            this.errorReporter.exceptionMessage(errors[0].subErrors ? { message: errors[0].subErrors[0].Message } : { message: errors[0].msg }));
                    } else {
                        this.notifySvc.error(this.errorReporter.exceptionMessage(errors[0]));
                    }
                }
                else {
                    this.notifySvc.success(this.amtXlatSvc.xlat('fieldSurvey.saveSuccessful'));

                    if (this.readingGridForm)
                        this.readingGridForm.$setPristine();

                    this.dirty = false;

                    if (!andNext && !this.fieldSurvey.pendingFieldSurveyId) {
                        await this.loadVehiclePicker(true);
                    } else if (andNext && !this.vehiclePicker.selectNext()) { // move to the next vehicle in the list, and if there are no more show the picker
                        await this.WindowFactory.alert("common.errorPanel_saveAndNext", ['common.ok_label'], "fieldSurvey.surveyNoMoreVehicles");
                        await this.loadVehiclePicker(true);
                    } else {

                        let visualInspectionCriteria = {
                            vehicles: [{
                                equipmentId: this.fieldSurvey.id,
                                date: this.ocDateSvc.dateTimeAsUTC(this.fieldSurvey.createdDate)
                            }]
                        };

                        let visualInspections = await this.amtCommandQuerySvc.post(this.fieldSurveyUrls.getFieldSurveyVisualInspection, visualInspectionCriteria);

                        if (visualInspections) {

                            for (let i = 0; i < visualInspections.length; i++) {
                                if (visualInspections[i]) {
                                    let pos = this.fieldSurvey.positionReadings[i];
                                    pos.visualInspections = visualInspections[i].visualInspections;
                                }
                            }
                        }
                    }
                }

                this.broadcastSurveyChange();

            } catch (error) {
                this.errorReporter.logError(error, "FieldSurvey-Upload");
            } finally {
                this.processing = false;
                this.fieldSurvey.createdDate = localCreatedDate;
            }

        } catch (error) {
            if (error === 'ValidateAbort' || (error && (error.ValidateAbort || error.message === "0" || error.number === 0))) {
                //User aborted out of validation
            } else {
                this.errorReporter.logError(error, "FieldSurvey-Validate");
            }
        }
    }

    public async onVehicleSelected(vehicleId: guid) {

        try {
            if (!vehicleId || vehicleId == this.fieldSurvey.id)
                return;

            // if mobile, check that there isn't already an active maintenance session on the device for the vehicle
            if (await this.dataBroker.checkVehicleLockedOut(vehicleId, 'fieldSurvey')) {
                let serialNo = this.selectedVehicle && this.selectedVehicle.serialNumber ? this.selectedVehicle.serialNumber : '';
                this.WindowFactory.alert('fieldSurvey.lockedForFS_title', ['common.close_label'], 'fieldSurvey.lockedForFS_body', [serialNo], 600);
                return;
            }

            // check the vehicle was in Production status at the selected date
            if (this.defaultDate && !await this.isVehicleInProduction(vehicleId, this.defaultDate, true))
                return;

            await this.loadVehicle(vehicleId);
        } catch (error) {
            this.errorReporter.logError(error);
        }
    }

    public async isVehicleInProduction(id: string, date: Date, showError: boolean): Promise<boolean> {
        if (!date)
            return false;

        let response = await this.dataBroker.getEquipmentStatusAtDate(id, date, this.enums.equipmentTypes.vehicle);

        let result = response && response.data && response.data.name === this.enums.statusTypes.production;

        if (showError && !result)
            this.notifySvc.error(this.amtXlatSvc.xlat("fieldSurvey.vehicleMustBeInProduction"));

        return result;
    }

    public async onSchematicClicked(event, position, selector) {
        if (event.defaultPrevented)  // already handled somewhere else
            return;

        event.preventDefault();

        await this.validateAndSelectTab(FieldSurveyTab.tyreReadings, false, false);
        this.$scope.$broadcast('SchematicClickedInt', position, selector);
    }

    public selectPressureReadingType() {
        this.WindowFactory.openItem({
            component: "pressure-reading-type",
            caption: this.amtXlatSvc.xlat("fieldSurvey.pressureReadingType"),
            width: this.isMobile ? 750 : 500,
            initParams: {
                vehicle: this.fieldSurvey,
                system: this.system,
                pressureReadingTypes: this.pressureReadingTypes
            },
            top: this.isMobile ? this.amtConstants.windowTopMarginMobile : null,
            modal: true,
            canClose: false,
            onDataChangeHandler: async () => {
                try {
                    await this.fieldSurveyService.validate(this.fieldSurvey, null, false, false);
                } catch { }

                this.dirty = true;
                this.saveReadingsForMobile();
            }
        });
    }

    public async changeDateTime() {
        let date = await this.promptForSurveyDateTime(this.fieldSurvey.id, false, this.fieldSurvey.createdDate);

        if (!date)
            return; //they cancelled

        // OR-2697 any visual inspection with matching dates needs to update also.
        if (this.fieldSurvey.createdDate) {
            for (let current of this.fieldSurvey.positionReadings) {

                if (!current.visualInspections)
                    continue;

                for (let vi of current.visualInspections) {
                    if (this.ocDateSvc.approxEqual(vi.inspectionDateTime, this.fieldSurvey.createdDate)) {
                        vi.inspectionDateTime = date;
                    }
                }
            }
        }

        this.fieldSurvey.createdDate = date;

        // update the window caption to reflect the new date
        this.setWindowTitle();

        try {
            await this.fieldSurveyService.validate(this.fieldSurvey, null, false, false);
        } catch { }

        this.dirty = true;
        await this.saveReadingsForMobile();
    }

    public async promptForSurveyDateTime(id: string, allowSetDefault: boolean, createdDate?: Date): Promise<Date> {

        let params = { vehicleId: id, createdDate: createdDate, /* in/out */ default: allowSetDefault };

        try {
            let date = await new Promise((resolve) => {
                this.WindowFactory.openItem({
                    component: "vehicle-date-time",
                    caption: this.amtXlatSvc.xlat("fieldSurvey.FieldSurveyDateTime"),
                    width: 550,
                    initParams: params,
                    top: this.isMobile ? this.amtConstants.windowTopMarginMobile : null,
                    canClose: false,
                    modal: true,
                    onDataChangeHandler: (date: Date) => resolve(date)
                })
            }) as Date;

            if (date) {
                date.setSeconds(0, 0);

                if (!await this.isVehicleInProduction(id, date, /* show error */ true)) {
                    return null;
                }

                if (params.default) {
                    this.defaultDate = date;
                }

                return date;
            }
        }
        catch { /* falls through */ }

        return null;
    }

    public async deleteSurvey() {

        try {
            await this.confirmSvc.confirmMessage("fieldSurvey.DeleteSurvey", "fieldSurvey.DeleteSure");
        } catch {
            return; // they cancelled the delete
        }

        try {

            // if this is desktop & brand new field survey we can skip this bit and just close
            if (this.isMobile || this.fieldSurvey.pendingFieldSurveyId) {
                this.processing = true;
                await this.dataBroker.deleteFieldSurvey(this.fieldSurvey.id, this.fieldSurvey.pendingFieldSurveyId);
            }

            this.notifySvc.success(this.amtXlatSvc.xlat('fieldSurvey.deleteSuccessful'));

            // mobile audit logging
            this.dataBroker.logAuditEntry('Field Survey', 'Delete',
                String.format('{0} ({1})',
                    this.fieldSurvey.serialNumber,
                    this.ocDateSvc.toReportString(this.fieldSurvey.createdDate)
                )
            );

            if (this.isMobile) { // stay on current vehicle but reload it for mobile
                this.$state.go('mobile.fieldSurvey', { vehicleId: this.fieldSurvey.id }, { reload: true, location: false });
            } else {
                this.broadcastSurveyChange();
                if (this.wnd) this.WindowFactory.closeWindow(this.wnd);
            }

        } catch (error) {
            this.errorReporter.logError(error);
        } finally {
            this.processing = false;
        }
    }

    public deleteAllSurvey() {
        this.WindowFactory.openItem({
            component: "field-survey-delete-all",
            caption: this.amtXlatSvc.xlat("fieldSurvey.DeleteAllSurvey"),
            width: 700,
            height: 400,
            isMobile: this.isMobile,
            top: this.isMobile ? this.amtConstants.windowTopMarginMobile : null,
            initParams: {},
            modal: true,
            onDataChangeHandler: (deleted) => {
                if (deleted) // deleted is false if they cancelled
                    this.loadVehiclePicker(true);
            }
        });
    };

    private broadcastSurveyChange() {

        this.$rootScope.$broadcast('RefreshFieldSurveyCounts');

        if (this.wnd && this.wnd.onDataChanged)
            this.wnd.onDataChanged();
    }
}

angular.module("app.fieldSurveys").component("fieldSurveys", {
    bindings: {
        vehicleId: '<',
        initParams: "=",
        buttonMethods: "=",
        buttonStates: "=",
        buttons: "=",
        closeOnSave: "=",
        wnd: "="
    },
    template: tmpl,
    controllerAs: 'vm',
    controller: fieldSurveysCtrl
});