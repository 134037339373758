/**
 * @ngdoc directive
 * @name amtFramework.directive:amtNumber
 * @restrict E
 * @param {string} decimalPlaces ???
 * @param {string} decimalSeparator ???
 *  
 * @function
 *
 * @description
 * 
 */
//import angular from 'angular';

angular.module('amtFramework').directive('amtNumber', [
    '$compile',
    function ($compile) {      
                var FLOAT_REGEXP = /^\-?\d+((\.|\,)\d+)?$/;
                return {
                    restrict: 'A',
                    require: 'ngModel',
                    scope: {
                        decimalPlaces: '@',
                        decimalSeparator: '@'
                    },
                    link: function(scope: any, element, attrs, modelCtrl) {

                        var name = attrs.name;
                        if (name === undefined) {
                            throw new Error("name not defined");
                        }

                        element.addClass("form-control");

                        var valSpan = angular.element('<span class="error-message error-number" ng-hide="valid">{{"common.validationInValidNumber_message" | xlat}}</span>');

                        valSpan.insertAfter(element);
                        $compile(valSpan)(scope);

                        var decimalSeperator = scope.decimalSeparator || ".";

                        var thousandSeperator = decimalSeperator === "." ? "," : ".";

                        var decimalPlaces = scope.decimalPlaces || 0;

                        var multiplier = Math.pow(10, decimalPlaces);

                        function numberWithCommas(x) {
                            x = (Math.round(x * multiplier) / multiplier).toFixed(decimalPlaces);
                            var parts = x.toString().split(decimalSeperator);
                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeperator);
                            return parts.join(decimalSeperator);
                        }

                        modelCtrl.$formatters.push(function(value) {
                            return numberWithCommas(value);
                        });

                        modelCtrl.$parsers.push(function(value) {
                            if (value === undefined || value == null || value.length === 0) {
                                modelCtrl.$setValidity('number', true);
                                scope.valid = true;
                                return value;
                            }
                            if (FLOAT_REGEXP.test(value)) {
                                modelCtrl.$setValidity('number', true);
                                scope.valid = true;
                                var result = numberWithCommas(value);
                                return result;
                            } else {
                                modelCtrl.$setValidity('number', false);
                                scope.valid = false;
                                return undefined;
                            }
                        });
                    }
                };
    }
]);
