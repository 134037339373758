//import angular from 'angular';
import * as _ from 'underscore';
import BrowserSvc from '../../../services/browserSvc';
import OcDateSvc from '../../../services/ocDateSvc';
import tmpl from './stockTake.html';



    angular.module("app")
        .component('stocktake', {
            bindings: {
                initParams: '=',
                buttonMethods: '=',
                buttonStates: '=',
                buttons: '=',
                closeOnSave: '=',
                wnd: '='
            },
            template: tmpl,
            controller: stockTakeCtrl
        });

    // @ts-ignore
    stockTakeCtrl.$inject = [
        "dataBroker", "$timeout", "errorReporter", "$state", "$scope", "$filter", "WindowFactory", "amtXlatSvc", "notifySvc",
        "$location", "$q", "confirmSvc", 'amtConstants', 'ocDateSvc', '$stateParams', 'ocConfigSvc', 'browserSvc'];

    function stockTakeCtrl(
        dataBroker, $timeout, errorReporter, $state, $scope, $filter, WindowFactory, amtXlatSvc, notifySvc,
        $location, $q, confirmSvc, amtConstants, ocDateSvc: OcDateSvc, $stateParams, ocConfigSvc, browserSvc: BrowserSvc) {

        dataBroker.checkRefData().catch(() => {
            if (browserSvc.isMobile) {
                $location.url("/mobile/landing");
            }
        });

        var ctrl = this;

        ctrl.type = null; // default     
        ctrl.isMobile = browserSvc.isMobile;

        ctrl.viewStocktakeSubmenuVisible = false;
        ctrl.changeTypeSubmenuVisible = false;

        // id on mobile is the composite key (concatenated location, status, spec and serial number fields)
        // id on desktop is the guid for the stock aggregate/component
        // desktop stores the composite key in the "key" property
        ctrl.idField = ctrl.isMobile ? "id" : "key";

        // this needs to be set later, for now we need to be able to add
        ctrl.activeLevel = 1;

        // can only add if specification or component is selected
        ctrl.minLevelToAdd = 3;

        ctrl.errorPanel = {};
        ctrl.currentError = null;
        ctrl.processingValidations = false;
        ctrl.form = {};
        ctrl.autoCompleteOptions = {
            noDataTemplate: amtXlatSvc.xlat('framework.noDataFound')
        };

        ctrl.$onInit = () => {
            if ($stateParams && $stateParams.receivedSerials) {
                ctrl.receivedSerials = $stateParams.receivedSerials;
                ctrl.setDirty();
            } else {
                ctrl.receivedSerials = null;
            }

            if (!ctrl.isMobile) {
                init(this.initParams.data.preferredLevel);
                ctrl.setWindowTitle(this.initParams.data.componentType, this.initParams.data.createdDate);

                ctrl.wnd.onClose = () => {
                    confirmSvc.confirmSaveChange(ctrl.wnd.isDirty).then(() => {
                        ctrl.form.$dirty = false;
                        closeWindow();
                        return true;
                    });

                    return false;
                };

                $scope.$watch("$ctrl.form.$dirty", (newValue) => {
                    ctrl.wnd.isDirty = newValue;
                });
            } else {
                init();
            }
        };

        function init(level?) {
            ctrl.pageReady = false;
            ctrl.active = [];
            ctrl.activeItem = "";
            ctrl.form = {};
            ctrl.processing = true;
            ctrl.preferredLevel = level ? level : 3;
            ctrl.rowInit = false;

            if (ctrl.isMobile) {
                getStocktakeComponentTypes().then(() => {
                    initMobile(level);
                });
            } else {
                initDesktop();
            }
        }

        function getStocktakeComponentTypes() {
            return dataBroker.getStocktakeComponentTypes().then(function (data) {
                ctrl.stocktakeComponentTypes = data.map(function (d) {
                    return { key: d.name.toLowerCase(), name: d.description };
                });
            });
        };

        function addComponentsForAggregate(spec, agg, components) {
            var expected = 0;

            for (var f = 0; f < components.length; f++) {
                // get the component 
                var equipment = components[f];

                if (equipment.statusLocation === agg.statusLocation && equipment.statusName === agg.statusName && equipment.equipmentTypeSpecificationTypeName == agg.equipmentTypeSpecificationTypeName) {

                    if (!spec.items[equipment.serialNumber]) { // only add it if it's not already there
                        //id: agg.statusLocation + ";" + agg.statusName + ";" + agg.equipmentTypeSpecificationTypeName + ";" + agg.serialNumber,
                        equipment.flagged = false;
                        equipment.expected = 1;
                        equipment.manualEdit = false;

                        expected++;

                        // add it to the items collection
                        spec.items[equipment.serialNumber] = equipment;
                    }
                }
            }

            return expected;
        }

        function addSpecificationForAggregate(status, agg, components) {
            var expected = 0;

            if (agg.equipmentTypeSpecificationTypeName) {
                // get the spec if it exists
                var spec = status.items[agg.equipmentTypeSpecificationTypeName];

                if (!spec) {
                    if (agg.componentId) {
                        // if this agg is a child of the spec, then create an aggregate for this level
                        spec = {
                            id: agg.id,
                            actual: agg.actual,
                            deleted: agg.deleted,
                            description: agg.description,
                            equipmentTypeSpecificationTypeId: agg.equipmentTypeSpecificationTypeId,
                            equipmentTypeSpecificationTypeName: agg.equipmentTypeSpecificationTypeName,
                            expected: 0,
                            flagged: agg.flagged,
                            items: {},
                            key: agg.key.split(";")[0] + ";" + agg.key.split(";")[1] + ";" + agg.key.split(";")[2],
                            manualEdit: agg.manualEdit,
                            siteStatusTypeLocationId: agg.siteStatusTypeLocationId,
                            statusLocation: agg.statusLocation,
                            statusName: agg.statusName
                        };
                        ctrl.stocktake.aggregates.push(spec);
                    } else {
                        spec = agg;

                        // not found, create it
                        spec.expected = 0;
                        spec.flagged = agg.flagged;
                        spec.manualEdit = agg.manualEdit;
                        spec.open = ctrl.preferredLevel > 3 || (agg.actual && agg.serialNumber);
                        spec.items = {};
                    }

                    // not found, create it
                    spec.open = ctrl.preferredLevel > 3 || (agg.actual && agg.serialNumber);

                    expected = addComponentsForAggregate(spec, agg, components);

                    // add it to the items collection
                    status.items[agg.equipmentTypeSpecificationTypeName] = spec;
                } else {
                    // open the node if it's already open, or if an actual has been entered for one of it's children
                    spec.open = spec.open || (agg.actual && agg.serialNumber); // expand the node if one of the child nodes have an actual
                }

                spec.expected += expected;
            }

            return expected;
        }

        function addStatusForAggregate(location, agg, components) {
            var expected = 0;

            if (agg.statusName) {
                // get the status if it exists
                var status = location.items[agg.statusName];

                if (!status) {
                    // not found, create it
                    if (agg.equipmentTypeSpecificationTypeId) {
                        // this agg is for child of the status, then create a new aggregate this level
                        status = {
                            id: agg.id,
                            deleted: agg.deleted,
                            description: agg.key.split(";")[1],
                            expected: 0,
                            actual: 0,
                            flagged: agg.flagged,
                            items: {},
                            key: agg.key.split(";")[0] + ";" + agg.key.split(";")[1],
                            manualEdit: agg.manualEdit,
                            siteStatusTypeLocationId: agg.siteStatusTypeLocationId,
                            statusLocation: agg.statusLocation,
                            statusName: agg.statusName
                        };
                        ctrl.stocktake.aggregates.push(status);
                    } else {
                        status = agg;

                        status.expected = 0;
                        status.flagged = agg.flagged;
                        status.manualEdit = agg.manualEdit;
                        status.open = ctrl.preferredLevel > 2 || (agg.actual && agg.equipmentTypeSpecificationTypeName); // expand the node if one of the child nodes have an actual
                        status.items = {};
                    }

                    status.open = ctrl.preferredLevel > 2 || (agg.actual && agg.equipmentTypeSpecificationTypeName); // expand the node if one of the child nodes have an actual

                    // add it to the items collection
                    location.items[agg.statusName] = status;
                } else {
                    // found it...

                    // open the node if it's already open, or if an actual has been entered for one of it's children
                    status.open = status.open || (agg.actual && agg.equipmentTypeSpecificationTypeName);
                }

                // create the child nodes
                expected = addSpecificationForAggregate(status, agg, components);

                status.expected += expected;
            }

            return expected;
        }

        function createLocationFromAggregate(agg) {
            return {
                id: agg.id,
                actual: 0,
                deleted: agg.deleted,
                description: agg.key.split(";")[0],
                expected: 0,
                flagged: agg.flagged,
                items: {},
                key: agg.key.split(";")[0],
                manualEdit: agg.manualEdit,
                siteStatusTypeLocationId: agg.siteStatusTypeLocationId,
                statusLocation: agg.statusLocation
            };
        };

        function addLocationForAggregate(items, agg, components) {
            if (!agg.statusLocation) {
                agg.statusLocation = amtXlatSvc.xlat("stocktake.noLocation");
            }

            // get the location, if it exists...
            var location = items[agg.statusLocation];

            if (!location) {
                // not found, create it
                if (agg.statusName) {
                    // this agg is for child of the location, then create a new aggregate for this level
                    location = createLocationFromAggregate(agg);
                    ctrl.stocktake.aggregates.push(location);
                } else {
                    location = agg;

                    location.expected = 0;
                    location.flagged = agg.flagged;
                    location.manualEdit = agg.manualEdit;
                    location.open = ctrl.preferredLevel > 1 || (agg.actual && agg.statusName); // expand the node if one of the child nodes have an actual
                    location.items = {};
                }

                location.open = ctrl.preferredLevel > 1 || (agg.actual && agg.statusName); // expand the node if one of the child nodes have an actual

                // add it to the items collection
                items[agg.statusLocation] = location;
            } else {
                // found

                // open the node if it's already open, or if an actual has been entered for one of it's children
                location.open = location.open || (agg.actual && agg.statusName);
            }

            // create the child nodes
            var expected = addStatusForAggregate(location, agg, components);

            location.expected += (expected || 0);

            return expected;
        }

        // loop over the aggregates and create the (nested) items object, which is used by the ui
        function mapItems() {
            var items = {};

            for (var c = 0; c < ctrl.stocktake.aggregates.length; c++) {
                var agg = ctrl.stocktake.aggregates[c];

                addLocationForAggregate(items, agg, ctrl.stocktake.components);
            }

            ctrl.stocktake.items = items;
        }

        function handleGetStocktakeResponse(data) {
            try {
                ctrl.stocktake = data.result;

                if (!ctrl.stocktake.comments) ctrl.stocktake.comments = [];

                ctrl.stocktake.expected = ctrl.stocktake.expected || 0;
                ctrl.stocktake.pristine = true; // this doesn't appear to be used

                ctrl.type = ctrl.stocktake.type.toLowerCase();

                if (!ctrl.isMobile) {
                    ctrl.setWindowTitle(ctrl.stocktake.type, ctrl.stocktake.createdDate);
                }

                ctrl.id = ctrl.stocktake.id;

                mapItems();

                // we keep the index separate because we don't want to store it in DB. 
                ctrl.index = indexStocktake(ctrl.stocktake);
                ctrl.itemLookup = _.indexBy(ctrl.index, "serialNumber");

                ctrl.localDataSource = new kendo.data.DataSource({
                    data: ctrl.index
                });

                ctrl.pageReady = true;

                $timeout(() => {
                    console.log("Stock take ready");
                    ctrl.processing = false;
                    ctrl.processingValidations = false;
                });

                ctrl.setPristine();
            } catch (ex) {
                errorReporter.logError(ex);
                ctrl.processing = false;
                ctrl.errorMsg = errorReporter.exceptionMessage(ex);
            }
        }

        function handleGetStocktakeResponseError(error) {
            errorReporter.logError(error);
            ctrl.processing = false;
            ctrl.errorMsg = errorReporter.exceptionMessage(error);
        }

        function initDesktop() {
            if (!ctrl.stocktake) {
                ctrl.stocktake = $scope.$parent.wnd.initParams.data;

                if (!ctrl.stocktake.comments) ctrl.stocktake.comments = [];
            }

            if ($scope.$parent.wnd.initParams.mode === NewOrEditMode.edit) {
                dataBroker.getStocktake(ctrl.stocktake).then(data => handleGetStocktakeResponse(data)).catch(error => handleGetStocktakeResponseError(error));
            } else {
                dataBroker.createStocktake(ctrl.stocktake).then(data => handleGetStocktakeResponse(data)).catch(error => handleGetStocktakeResponseError(error));
            }
        }

        ctrl.setDirty = function () {
            $timeout(function () {
                if (ctrl.form && ctrl.form.$setDirty) {
                    ctrl.form.$setDirty();
                }
            });
        };

        ctrl.setPristine = function () {
            $timeout(function () {
                if (ctrl.form && ctrl.form.$setPristine) {
                    ctrl.form.$setPristine();
                }
            });
        }

        function getLocationKey(itemKey) {
            return itemKey.split(";")[0];
        }

        function findOrCreateLocation(st, key, receivedSerial, actual?) {
            var locationKey = getLocationKey(key);

            var location = st.items[locationKey];

            if (!location) {
                // create the location
                location = {
                    description: receivedSerial.location,
                    expected: 1,
                    actual: actual,
                    open: ctrl.preferredLevel > 0,
                    manualEdit: false,
                    items: {}
                };

                // add the location
                st.items[locationKey] = location;
            } else {
                if (actual) {
                    location.actual = (location.actual || 0) + 1;
                }
                location.expected = (location.expected || 0) + 1;
            }

            // return the location
            return location;
        }

        function getStatusKey(itemKey) {
            return itemKey.split(";")[1];
        }

        function findOrCreateStatus(st, itemKey, receivedSerial, location?, actual?) {
            if (!location) {
                location = findOrCreateLocation(st, itemKey, receivedSerial, actual);
            }

            var statusKey = getStatusKey(itemKey);
            var status = location.items[statusKey];

            if (!status) {
                // create the status
                status = {
                    description: receivedSerial.status.description || receivedSerial.status,
                    expected: 1,
                    actual: actual,
                    open: ctrl.preferredLevel > 1,
                    manualEdit: false,
                    items: {}
                };

                // add the status to the location
                location.items[statusKey] = status;
            } else {
                if (actual) {
                    status.actual = (status.actual || 0) + 1;
                }
                status.expected = (status.expected || 0) + 1;
            }

            return status;
        }

        function getSpecificationKey(itemKey) {
            return itemKey.split(";")[2];
        }

        function findOrCreateSpecification(st, itemKey, receivedSerial, status?, actual?) {
            if (!status) {
                status = findOrCreateStatus(st, itemKey, receivedSerial, null, actual);
            }

            var specificationKey = getSpecificationKey(itemKey);
            var specification = status.items[specificationKey];

            if (!specification) {
                // create the status
                specification = {
                    id: receivedSerial.equipmentTypeSpecificationTypeId,
                    description: receivedSerial.description || receivedSerial.specification.description,
                    items: {},
                    actual: actual,
                    open: ctrl.preferredLevel > 2,
                    expected: 1,
                    manualEdit: false,
                    extra: true
                };

                // add the status to the location
                status.items[specificationKey] = specification;
            } else {
                if (actual) {
                    specification.actual = (specification.actual || 0) + actual;
                }

                specification.expected = (specification.expected || 0) + 1;
            }

            return specification;
        }

        function getCompnentKey(itemKey) {
            return itemKey.split(";")[3];
        }

        function buildReceivedComponentKey(receivedSerial) {
            var key = "";

            if (receivedSerial.location) {
                key += receivedSerial.location + ";";
            } else {
                key += amtXlatSvc.xlat("stocktake.noLocation") + ";";
            }

            key += receivedSerial.status.description + ";";

            key += (receivedSerial.description || receivedSerial.specification.description) + ";";

            key += receivedSerial.serialNumber.site || receivedSerial.serialNumber.manufacturer;

            return key;
        }

        function findOrCreateComponent(st, receivedSerial, specification?, actual?) {
            var itemKey = buildReceivedComponentKey(receivedSerial);

            if (!specification) {
                specification = findOrCreateSpecification(st, itemKey, receivedSerial, null, actual);
            }

            var componentKey = getCompnentKey(itemKey);
            var component = specification.items[componentKey];

            if (!component) {
                // create the status
                component = {
                    actual: actual,
                    expected: 1,
                    deleted: false,
                    manualEdit: actual !== null,
                    componentId: receivedSerial.componentId,
                    open: ctrl.preferredLevel > 3,
                    description: receivedSerial.description,
                    toBeReceived: false,
                    serialNumber: receivedSerial.serialNumber.site || receivedSerial.serialNumber.manufacturer,
                    equipmentTypeSpecificationTypeId: receivedSerial.equipmentTypeSpecificationTypeId,
                    equipmentTypeSpecificationTypeName: receivedSerial.description,
                    status: {
                        location: receivedSerial.location,
                        name: receivedSerial.status.description || receivedSerial.status,
                        siteStatusTypeLocationId: receivedSerial.status.siteStatusTypeLocationId
                    }
                };

                component[ctrl.idField] = componentKey;

                // add the status to the location
                specification.items[componentKey] = component;
            }

            return component;
        }

        function itemCompare(item1, item2) {
            if (item1.location < item2.location) {
                return -1;
            }

            if (item1.location > item2.location) {
                return 1;
            }

            if (item1.status.description < item2.status.description) {
                return -1;
            }

            if (item1.status.description > item2.status.description) {
                return 1;
            }

            if (item1.description < item2.description) {
                return -1;
            }

            if (item1.description > item2.description) {
                return 1;
            }

            var serial1 = item1.serialNumber.site || item1.serialNumber.manufacturer;
            var serial2 = item2.serialNumber.site || item2.serialNumber.manufacturer;

            if (serial1 < serial2) {
                return -1;
            }

            if (serial1 > serial2) {
                return 1;
            }

            return 0;
        }

        function initMobile(level?) {
            dataBroker.getStocktake(ctrl.type, level).then(data => {
                var stocktake = data;

                if (!stocktake.comments) stocktake.comments = [];

                if (data.components) {
                    // first, get them in the right order (location, status, spec, serial)
                    stocktake.components = stocktake.components.sort(itemCompare);

                    // then chuck them in a tree
                    for (var component in stocktake.components) {
                        if (data.components.hasOwnProperty(component)) {
                            findOrCreateComponent(stocktake, data.components[component], null, null);
                        }
                    }

                    // don't need to keep this once we have put them into a tree
                    data.components = undefined;
                }

                if (ctrl.receivedSerials && ctrl.receivedSerials.length > 0) {
                    for (var c = 0; c < ctrl.receivedSerials.length; c++) {
                        findOrCreateComponent(data, ctrl.receivedSerials[c], null, 1);
                    }
                    // we added some components, set the form dirty to enable the save buttons
                    ctrl.setDirty();
                }

                ctrl.stocktake = stocktake;
                ctrl.type = data.id;

                // we keep the index separate because we don't want to store it in DB. 
                ctrl.index = indexStocktake(ctrl.stocktake);
                ctrl.itemLookup = _.indexBy(ctrl.index, "serialNumber");

                ctrl.localDataSource = new kendo.data.DataSource({
                    data: ctrl.index
                    //group: { field: 'description' }
                });

                ctrl.pageReady = true;
                $timeout(() => {
                    console.log("Stock take ready");
                    ctrl.processing = false;

                    if (ctrl.receivedSerials && ctrl.receivedSerials.length > 0) {
                        ctrl.activeItem = buildReceivedComponentKey(ctrl.receivedSerials[0]);
                    }
                });

                // set the form to no changes
                ctrl.setPristine();
            }).catch(error => {
                errorReporter.logError(error);
                ctrl.processing = false;
                ctrl.errorMsg = errorReporter.exceptionMessage(error);
            });
        }

        function closeWindow() {
            WindowFactory.closeWindow(ctrl.wnd);
        }

        $scope.$on("stockRow", ($event, id, level) => {
            var lId = id;
            // they currently want it to default to the first row, not the first row at the given level.
            if (!this.rowInit /* && level === ctrl.preferredLevel */) {
                console.log("init rows! " + lId);
                this.rowInit = true;
                $timeout(() => {
                    ctrl.activeItem = lId;
                });
            }
        });

        $scope.$watch("$ctrl.currentError", (newValue) => {
            if (newValue) {
                // set focus to the row with the error
                $timeout(key => {
                    ctrl.activeItem = key;
                }, 0, true, newValue.Data["key"]);

            }
        });

        $scope.$watch("$ctrl.searchItem", (newValue, oldValue) => {
            if (newValue) {
                var se = this.itemLookup[newValue];
                if (se) {
                    ctrl.activeItem = se[ctrl.idField];

                    if (!ctrl.activeItem) {
                        errorReporter.logError("Unable to find stocktake search Item");
                    }

                    this.search = "";

                    $timeout(() => {
                        // we clear the active item afterwards, because we had an issue where the user collapsed things 
                        // then selected the same item as before
                        // and no change was registered. 
                        ctrl.activeItem = "";
                        ctrl.searchItem = undefined;
                    });
                }
            }
        });

        function indexLevel(data: any, index: any);
        function indexLevel(data, index) {
            if (data.items) {
                for (var key in data.items) {
                    indexLevel(data.items[key], index);
                }
            }
            else {
                if (data.deleted !== true) {
                    index.push(data);
                }
            }
        }

        ctrl.reindexStocktake = function () {
            indexStocktake(ctrl.stocktake);
        }

        function indexStocktake(stocktake: any);
        function indexStocktake(stocktake) {
            var order = $filter("orderBy");
            var index = [];

            indexLevel(stocktake, index);

            return order(index, "serialNumber");
        }

        function editCounts(data: any);
        function editCounts(data) {
            var count = 0;
            if (data.manualEdit) {
                count++;
            }
            if (data.items) {
                for (var key in data.items) {
                    count += editCounts(data.items[key]);
                }
            }
            return count;
        }

        // methods
        ctrl.setWindowTitle = function (componentType, stocktakeDate) {
            var componentTypeDesc = '';
            if (componentType) {
                componentTypeDesc = amtXlatSvc.xlat('equipment.equipmentType' + ctrl.toTitleCase(componentType));
            }

            if (ctrl.wnd && stocktakeDate) {
                ctrl.wnd.caption = amtXlatSvc.xlat('stocktake.stocktakeTitle', componentTypeDesc, ocDateSvc.toString(stocktakeDate));
                ctrl.wnd.shortCaption = amtXlatSvc.xlat('stocktake.stocktakeTitle', componentTypeDesc, ocDateSvc.toString(stocktakeDate));
            }
        };

        ctrl.toTitleCase = function (str) {
            return str.replace(
                /\w\S*/g,
                function (txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
            );
        };

        ctrl.menuShow = () => {
            if (!this.menuShowing || !this.preventMenuClose) {
                this.menuShowing = !this.menuShowing;
                this.preventBlurClose = false;
                this.viewStocktakeSubmenuVisible = false;
                this.changeTypeSubmenuVisible = false;
            }

            this.preventMenuClose = false;
        };

        ctrl.preventClose = function () {
            this.preventMenuClose = true;
        };

        ctrl.noBlur = () => {
            console.log("no blur");
            this.preventBlurClose = true;
        };

        ctrl.menuClose = () => {
            console.log("fieldMenuClose");
            if (!this.preventBlurClose) {
                this.menuShowing = false;
            } else {
                console.log("blur disabled");
            }
            this.preventBlurClose = false;
        };

        ctrl.return = () => {
            console.log("Return home");
            $state.go("mobile.landing");
        };

        ctrl.byLocation = () => {
            console.log("byLocation");
            this.preferredLevel = 1;
        };

        ctrl.byStatus = () => {
            console.log("byStatus");
            this.preferredLevel = 2;
        };

        ctrl.bySpecification = () => {
            console.log("bySpecification");
            this.preferredLevel = 3;
        };

        ctrl.bySerialNumber = () => {
            console.log("bySerialNumber");
            this.preferredLevel = 4;
        };

        ctrl.save = () => {
            var savePromise = ctrl.saveAsync();

            savePromise.then(
                function () { },
                function () { } // suppress the unhandled promise rejection
            );
        };

        ctrl.saveAsync = () => {
            return $q(function (resolve, reject) {
                if (ctrl.stocktake) {

                    ctrl.processingValidations = true;

                    ctrl.processing = true;

                    ctrl.stocktake.errorData = undefined; // this is transient data;
                    ctrl.stocktake.errorList = undefined; // this is looped so clone or fix; currently not needed for mobile so we can just delete it.

                    dataBroker.saveStocktake(ctrl.stocktake).then(
                        function (response) {
                            handleGetStocktakeResponse(response);

                            if (!ctrl.isMobile) {
                                ctrl.updateSearchResults();

                                ctrl.wnd.windowRelatedRecordId = ctrl.stocktake.id;
                            }

                            ctrl.setPristine();

                            ctrl.processing = false;

                            return resolve();
                        },
                        function (response) {
                            return reject(response.message);
                        }
                    ).catch(
                        function (error) {
                            console.error("Failed to save stocktake session");
                            errorReporter.logError(error);
                            return reject(error);
                        }
                    ).finally(function () {
                        ctrl.processing = false;
                        ctrl.processingValidations = false;
                    });
                } else {
                    return resolve();
                }
            });
        };

        ctrl.dataTypeChange = type => {
            this.pageReady = false;
            this.processing = true;
            this.type = type;
            dataBroker.setStocktakeType(type);
            init();
        };

        ctrl.dateTime = () => {
            var data = {
                stocktake: this.stocktake
            };

            WindowFactory.openItem({
                component: "stock-date-time",
                caption: amtXlatSvc.xlat("stocktake.dateTimeChange"),
                initParams: data,
                width: 550,
                modal: true,
                onDataChangeHandler: function (date) {
                    if (!ctrl.isMobile) {
                        ctrl.setWindowTitle(ctrl.stocktake.type, date);
                        ctrl.save();
                    }
                }
            });

        };

        ctrl.updateSearchResults = function () {
            if (ctrl.wnd.onDataChanged) {
                ctrl.wnd.onDataChanged();
            }
        };

        ctrl.delete = () => {
            var count = editCounts(this.stocktake);
            var msg = ctrl.isMobile ? (count === 1 ? "stocktake.deleteStocktakeConfirmSingular" : "stocktake.deleteStocktakeConfirm") : "stocktake.deleteStocktakeConfirmDesktop";
            WindowFactory.iconAlert("glyphicon-check", "stocktake.deleteStocktakeConfirmHeading",
                ["framework.cancel_label", "framework.delete_label"], msg, count)
                .then(result => {
                    if (result === "framework.delete_label") {

                        ctrl.processing = true;

                        dataBroker.deleteStocktake(this.stocktake).then(() => {
                            ctrl.processing = false;
                            notifySvc.success(amtXlatSvc.xlat("stocktake.stocktakeDeleted"));

                            if (ctrl.isMobile) {
                                this.pageReady = false;
                                this.processing = true;

                                $timeout(() => {
                                    init(1);
                                });
                            } else {
                                closeWindow();

                                ctrl.updateSearchResults();
                            }
                        }).catch(ex => {
                            ctrl.processing = false;
                            errorReporter.logError(ex);
                        });
                    }
                });
        };

        ctrl.viewComments = function () {
            WindowFactory.openItem({
                component: 'stocktake-comments',
                caption: amtXlatSvc.xlat('stocktake.stocktakeComments'),
                top: ctrl.isMobile ? amtConstants.windowTopMarginMobile : null,
                width: 1000,                
                modal: true,
                initParams: {
                    comments: angular.copy(ctrl.stocktake.comments),
                    api: {
                        deleteComment: ctrl.deleteComment,
                        modifyComment: ctrl.modifyComment
                    }
                },
                canClose: false
            });
        };

        ctrl.addComment = function () {
            WindowFactory.openItem({
                component: 'stocktake-comment-popup',
                caption: amtXlatSvc.xlat('stocktake.addComment'),
                width: 500,
                modal: true,
                initParams: {
                    comment: null
                },
                onDataChangeHandler: function (comment) {

                    // add an id (just for use on the client, does not get committed)
                    comment.id = uuid();
                    
                    comment.username = ocConfigSvc.user.name;

                    ctrl.stocktake.comments.push(comment);

                    if (ctrl.isMobile) {
                        // save the data 
                        dataBroker.saveStocktake(ctrl.stocktake).catch(function (error) {
                            errorReporter.logError(error);
                            throw error;
                        });
                    }

                    notifySvc.success(amtXlatSvc.xlat('stocktake.commentAddedSuccessfully'));

                    ctrl.setDirty();
                },
                canClose: false
            });
        };

        ctrl.deleteComment = function (comment) {
            return $q(function (resolve, reject) {
                try {
                    // find the comment by id
                    let matchedComment = ctrl.stocktake.comments.find(c => c.id === comment.id);

                    if (matchedComment) {
                        ctrl.stocktake.comments.removeItem(matchedComment);

                        if (ctrl.isMobile) {
                            // save the data 
                            dataBroker.saveStocktake(ctrl.stocktake).catch(function (error) {
                                errorReporter.logError(error);
                                throw error;
                            });
                        }

                        notifySvc.success(amtXlatSvc.xlat('stocktake.commentDeletedSuccessfully'));

                        ctrl.setDirty();

                        return resolve();
                    } else {
                        return reject();
                    }
                } catch (error) {
                    return reject(error);
                }
            });
        };

        ctrl.modifyComment = function (comment) {
            return $q(function (resolve, reject) {
                try {
                    // find the comment by id
                    let matchedCommentIndex = ctrl.stocktake.comments.findIndex(c => c.id === comment.id);

                    if (matchedCommentIndex > -1) {

                        ctrl.stocktake.comments[matchedCommentIndex].comment = comment.comment;

                        // update the user associated with the comment (for display on desktop)                        
                        ctrl.stocktake.comments[matchedCommentIndex].username = ocConfigSvc.user.name;

                        if (ctrl.isMobile) {
                            // save the data 
                            dataBroker.saveStocktake(ctrl.stocktake).catch(function (error) {
                                errorReporter.logError(error);
                                throw error;
                            });
                        }

                        notifySvc.success(amtXlatSvc.xlat('stocktake.commentUpdatedSuccessfully'));

                        ctrl.setDirty();

                        return resolve();
                    } else {
                        return reject();
                    }
                } catch (error) {
                    return reject(error);
                }
            });
        };

        ctrl.toggleViewStocktakeMenu = function (value) {

            if (!!value && ctrl.isMobile) return;

            if (value) {
                ctrl.viewStocktakeSubmenuVisible = value;
            } else {
                ctrl.viewStocktakeSubmenuVisible = ctrl.viewStocktakeSubmenuVisible !== true;
            }

            if (ctrl.viewStocktakeSubmenuVisible === true) ctrl.changeTypeSubmenuVisible = false;
        };

        ctrl.toggleChangeTypeMenu = function (value) {

            if (!!value && ctrl.isMobile) return;

            if (value) {
                ctrl.changeTypeSubmenuVisible = value;
            } else {
                ctrl.changeTypeSubmenuVisible = ctrl.changeTypeSubmenuVisible !== true;
            }

            if (ctrl.changeTypeSubmenuVisible === true) ctrl.viewStocktakeSubmenuVisible = false;
        };

    }
