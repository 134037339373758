//import angular from 'angular';

angular.module('app').filter('monthYear', monthYearFilter);

    // @ts-ignore
    monthYearFilter.$inject = ["appConfig", "$filter"];
    function monthYearFilter(appConfig, $filter) {
        var dateFilter = $filter('date');

        return function (date) {
            if (!date) {
                return '';
            }
            if (date.getYear) {
                return dateFilter(date, appConfig.dateFormat.monthYear);
            }

            if (!isNaN(+date)) {
                if ((date + '').length === 6) {
                    return dateFilter(new Date(+(date + '').substr(0, 4), +((date + '').substr(4)) - 1, 1), appConfig.dateFormat.monthYear);
                }
            }

            throw Error("Value in this format is not support");
        };
    }
