//import angular from 'angular';
import OcDateSvc from '../../../services/ocDateSvc';


angular.module('app.fieldSurveys')
        .service('stocktakeService', ['baseModuleService', 'ocDateSvc', function (BaseModuleService, ocDateSvc: OcDateSvc) {
            var vm = this;
            var internalName = "stocktake";
            vm = new BaseModuleService(internalName);

            // custom methods here
            vm.trimForUpload = function (records) {
                if (!records[0].dataAdjusted) {
                    records[0].createdDate = ocDateSvc.removeLocalTimeZoneOffset(records[0].createdDate);
                    records[0].dataAdjusted = true;
                }
                return records[0]; // not an array;
            };
            return vm;
        }]);
