//import angular from 'angular';
import FileManagement from '../../../services/fileManagement';
import OcConfigSvc from '../../../services/ocConfigSvc';
import tmpl from './ocEquipmentAttachment.html';



interface IEquipmentAttachmentSearchCriteria extends IGridFilterCriteria {
    equipmentId?: guid;
}

class ocEquipmentAttachmentCtrl implements ng.IComponentController {

    equipmentObject: any;
    tabObject: any;

    searchCriteria: IEquipmentAttachmentSearchCriteria = { filterValues: {} }

    loading = false;

    canAdd: boolean;
    canDelete: boolean;
    canEdit: boolean;
    canViewStatusChange: boolean;

    showEventCallback: Function;

    attachmentGrid: any = {};

    get exportFilename() { return this.amtXlatSvc.xlat('equipment.attachmentsExportFilename', this.equipmentObject.serialNumber.formatted) }

    static $inject = ['$scope', 'equipmentUrls', 'fileManagement', 'amtCommandQuerySvc', 'confirmSvc',
        'amtXlatSvc', 'exportSvc', 'notifySvc', 'ocSecuritySvc', 'errorReporter', 'ocConfigSvc', 'WindowFactory'];

    constructor(private $scope: ng.IScope, private equipmentUrls: IEquipmentUrls, private fileManagement: FileManagement, private amtCommandQuerySvc: IAmtCommandQuerySvc,
        private confirmSvc: IConfirmSvc, private amtXlatSvc: IAmtXlatSvc, private exportSvc: IExportSvc, private notifySvc: INotifySvc,
        private ocSecuritySvc: IOcSecuritySvc, private errorReporter: IErrorReporter, private ocConfigSvC: OcConfigSvc, private WindowFactory: IWindowFactory) {

        this.$scope.$watch(() => this.equipmentObject, () => {
            if (this.equipmentObject) {
                this.searchCriteria.filterValues.equipmentId = this.equipmentObject.id;

                // security
                let isVehicle = this.equipmentObject.isVehicle;

                this.canAdd = this.ocSecuritySvc.isAuthorised(isVehicle ? 'Security.Vehicles.Attachments.Add' : 'Security.Components.Attachments.Add', AccessTypes.readWrite);
                this.canDelete = this.ocSecuritySvc.isAuthorised(isVehicle ? 'Security.Vehicles.Attachments.Delete' : 'Security.Components.Attachments.Delete', AccessTypes.readWrite);
                this.canEdit = this.ocSecuritySvc.isAuthorised(isVehicle ? 'Security.Vehicles.Attachments' : 'Security.Components.Attachments', AccessTypes.readWrite);
                this.canViewStatusChange = this.ocSecuritySvc.isAuthorised(isVehicle ? 'Security.Vehicles.ChangeStatus' : 'Security.Components.ChangeStatus', AccessTypes.readOnly);

                this.updateTabObjects();
            }
        })

        // bind functions to scope so they can be called from the grid
        this.$scope['openAttachment'] = (item) => this.openAttachment(item);
        this.$scope['showEventDetails'] = (id) => this.showEventDetails(id);
        this.$scope['deleteAttachment'] = (item) => this.deleteAttachment(item);
    }

    $onInit() {
        this.updateTabObjects();
    }

    attachmentsLoaded(response) {

        for (let item of response.result || []) {

            let accessible = !item.systemHierarchyId ||
                (item.systemHierarchyId === this.ocConfigSvC.user.site.id ||
                    item.systemHierarchyId === this.ocConfigSvC.user.client.id ||
                    item.systemHierarchyId === this.ocConfigSvC.user.system.id);

            item.canDelete = this.canDelete && item.canDelete && accessible;
            item.canEditAttachment = !!item.attachmentId && this.canEdit && accessible;
            item.canOpenEvent = this.canViewStatusChange && accessible;
        }

        return response;
    }

    refresh() {
        if (this.attachmentGrid.refresh && this.equipmentObject.id) {
            this.searchCriteria.filterValues.equipmentId = this.equipmentObject.id;
            this.attachmentGrid.refresh();
        }
    }

    openAttachment(item?) {
        this.WindowFactory.openItem({
            component: 'attachment-popup',
            caption: this.amtXlatSvc.xlat(item ? 'equipment.attachmentEdit' : 'equipment.attachmentAdd'),
            initParams: {
                mode: item ? NewOrEditMode.edit : NewOrEditMode.new,
                attachmentId: item ? item.attachmentId : null,
                equipmentId: this.equipmentObject.id,
                source: item ? item.source : AttachmentType.equipmentAttachment, // can't add a EquipmentEventAttachment without an event                                
                showCloseOnSave: false,
                readonly: item ? !this.canEdit : !this.canAdd
            },
            width: 700,
            modal: true,
            canClose: false,
            onDataChangeHandler: () => this.refresh()
        })
    }

    async deleteAttachment(item) {

        try {
            await this.confirmSvc.confirmMessage('equipment.confirmDeleteAttachment_title', 'equipment.confirmDeleteAttachment_text', item.name);
        } catch (error) {
            return; // they cancelled
        }

        this.loading = true;

        try {
            await this.amtCommandQuerySvc.post(this.equipmentUrls.url.deleteAttachment, item);

            this.notifySvc.success(this.amtXlatSvc.xlat('equipment.attachmentDeleteSuccessful'));
            this.refresh();

        } catch (error) {
            this.errorReporter.logError(error);
        }

        this.loading = false;
    }

    exportAttachmentList() {
        this.exportSvc.exportData(
            this.equipmentUrls.url.attachmentsExport,
            this.searchCriteria,
            this.exportFilename
        );
    }

    showEventDetails(id: guid) {
        if (this.showEventCallback)
            this.showEventCallback({ equipmentEventId: id, editMode: 'Move' });
    }

    updateTabObjects() {
        this.tabObject.refresh = () => this.refresh();
        this.tabObject.canRefresh = true;

        this.tabObject.delete = (item) => this.deleteAttachment(item);
        this.tabObject.canDelete = this.canDelete;

        this.tabObject.editAttachment = (item) => this.openAttachment(item);
        this.tabObject.canEdit = this.canEdit;

        this.tabObject.add = () => this.openAttachment();
        this.tabObject.canAdd = this.canAdd;

        this.tabObject.export = () => this.exportAttachmentList();
        this.tabObject.canExport = true;
    }
}

class ocEquipmentAttachmentComponent implements ng.IComponentOptions {
    public bindings = {
        tabObject: '=',
        equipmentObject: '=',
        showEventCallback: '&?'
    };
    public template = tmpl;
    public controller = ocEquipmentAttachmentCtrl;
    public controllerAs = 'vm';
}

angular.module('app.directives').component('ocEquipmentAttachment', new ocEquipmentAttachmentComponent());
