//import angular from 'angular';
import tmpl from './DownloadPrompt.html';




class MaintenanceSessionDownloadPromptCtrl implements ng.IController, IWindowController {

    form: ng.IFormController;
    wnd: IWindowObj;

    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: () => void };

    initParams: any;

    static $inject = ['$scope', 'confirmSvc', 'WindowFactory'];

    constructor(private $scope: ng.IScope, private confirmSvc: IConfirmSvc, private WindowFactory: IWindowFactory) {
    }

    $onInit() {
        this.WindowFactory.addButton(this, 'common.yes_label', 'yesButton', () => this.confirmDownloadMaintenanceSessions(), true);
        this.WindowFactory.addButton(this, 'common.no_label', 'noButton', () => this.skipDownloadMaintenanceSession());
    }

    // close window
    skipDownloadMaintenanceSession() {
        this.WindowFactory.closeWindow(this.wnd);
    }

    confirmDownloadMaintenanceSessions() {
        if (this.wnd.onDataChanged)
            this.wnd.onDataChanged();

        this.WindowFactory.closeWindow(this.wnd);
    }
}

class MaintenanceSessionDownloadPromptComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = MaintenanceSessionDownloadPromptCtrl;
    public controllerAs = 'vm';
}

angular.module('app.directives').component('maintenanceSessionDownloadPrompt', new MaintenanceSessionDownloadPromptComponent());