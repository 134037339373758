/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var treeview_filter_settings_1 = require("./treeview-filter-settings");
var utils_1 = require("./utils");
/**
 * @hidden
 */
var FilteringBase = /** @class */ (function () {
    function FilteringBase(component) {
        this.component = component;
        this.visibleNodes = new Set();
        this._filterSettings = treeview_filter_settings_1.DEFAULT_FILTER_SETTINGS;
    }
    Object.defineProperty(FilteringBase.prototype, "filterSettings", {
        get: function () {
            return this._filterSettings;
        },
        /**
         * The settings which are applied when performing a filter on the component's data.
         */
        set: function (settings) {
            this._filterSettings = tslib_1.__assign({}, treeview_filter_settings_1.DEFAULT_FILTER_SETTINGS, settings);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilteringBase.prototype, "filter", {
        /**
         * Applies a filter and changes the visibility of the component's nodes accordingly.
         */
        set: function (term) {
            this.handleFilterChange(term);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @hidden
     */
    FilteringBase.prototype.handleFilterChange = function (term) {
        if (!this.filterData) {
            return;
        }
        this.resetNodesVisibility(this.filterData);
        if (term) {
            utils_1.filterTree(this.filterData, term, this.filterSettings, this.component.textField);
        }
        this.updateVisibleNodes(this.filterData);
        if (utils_1.isPresent(this.component.filterStateChange)) {
            this.component.filterStateChange.emit({
                nodes: this.filterData,
                matchCount: this.visibleNodes.size,
                term: term,
                filterSettings: this.filterSettings
            });
        }
    };
    FilteringBase.prototype.updateVisibleNodes = function (items) {
        var _this = this;
        items.forEach(function (wrapper) {
            if (wrapper.visible) {
                _this.visibleNodes.add(wrapper.dataItem);
            }
            if (wrapper.children) {
                _this.updateVisibleNodes(wrapper.children);
            }
        });
    };
    FilteringBase.prototype.resetNodesVisibility = function (items) {
        var _this = this;
        this.visibleNodes.clear();
        items.forEach(function (wrapper) {
            wrapper.visible = true;
            if (wrapper.children) {
                _this.resetNodesVisibility(wrapper.children);
            }
        });
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object),
        tslib_1.__metadata("design:paramtypes", [Object])
    ], FilteringBase.prototype, "filterSettings", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], FilteringBase.prototype, "filter", null);
    return FilteringBase;
}());
exports.FilteringBase = FilteringBase;
