//import angular from 'angular';
import tmpl from './siteDashboardConfigurationDetails.html';
import './siteDashboardConfigurationDetails.scss';
import OcConfigSvc from '../../../services/ocConfigSvc';
import HelperSvc from '../../../services/helperSvc';
import { DashboardWidgetConfigurationType, IDashboardWidget, ISiteDashboardWidget, IDashboardWidgetConfiguration, ISaveSiteDashboardWidgetCriteria } from './siteDashboardConfiguration.interfaces';

class SiteDashboardConfigurationDetailsCtrl implements ng.IController, IWindowController {

    wnd: IWindowObj;
    form: ng.IFormController;

    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: (button: string) => void }

    initParams: any = {};
    readonly: boolean;

    dashboardName: string;
    siteDashboardId: guid;

    siteDashboardWidget: ISiteDashboardWidget;

    dashboardWidgets: IDashboardWidget[];
    
    configurationLoaded: boolean;

    private _showDashboardWidgets: boolean;
    get showDashboardWidgets() { return this._showDashboardWidgets; }
    set showDashboardWidgets(show: boolean) {
        this._showDashboardWidgets = show;
        this.setButtonVisibility();
    }

    static $inject = ['$scope', 'ocConfigSvc', 'ocSecuritySvc', 'confirmSvc', 'WindowFactory', 'amtCommandQuerySvc',
        'adminUrls', 'errorReporter', 'equipmentUrls', 'vehicleUrls', 'amtXlatSvc', 'referenceDataUrls', 'helperSvc',
        'notifySvc', '$timeout', 'componentUrls'];

    constructor(private $scope: ng.IScope, private ocConfigSvc: OcConfigSvc, private ocSecuritySvc: IOcSecuritySvc, private confirmSvc: IConfirmSvc,
        private WindowFactory: IWindowFactory, private amtCommandQuerySvc: IAmtCommandQuerySvc, private adminUrls: IAdminUrls, private errorReporter: IErrorReporter,
        private equipmentUrls: IEquipmentUrls, private vehicleUrls: IVehicleUrls, private amtXlatSvc: IAmtXlatSvc, private referenceDataUrls: IReferenceDataUrls,
        private helperSvc: HelperSvc, private notifySvc: INotifySvc, private $timeout: ng.ITimeoutService, private componentUrls: IComponentUrls) {

        this.$scope.$watchGroup([() => this.form.$invalid, () => this.form.$pristine, () => this.wnd.processing], () => {
            this.buttonStates.saveButton.disabled = this.form.$invalid || (this.siteDashboardWidget?.id && this.form.$pristine) || this.wnd.processing;
        });
    }

    async $onInit() {

        this.wnd.onClose = () => this.closeWindow();
        this.wnd.processing = true;

        try {
            if (this.initParams.siteDashboardWidgetId) {
                this.siteDashboardWidget = {
                    id: this.initParams.siteDashboardWidgetId,
                    dashboardWidget: this.initParams.dashboardWidget,
                    values: {}
                };
            }

            this.siteDashboardId = this.initParams.siteDashboardId;
            this.dashboardName = this.initParams.dashboardName;

            this.readonly = this.initParams.readonly || !this.ocSecuritySvc.isAuthorised('Security.Settings.SiteDashboard', AccessTypes.readWrite);

            this.WindowFactory.addButton(this, 'common.cancel_label', 'cancelButton', () => this.closeWindow());
            this.WindowFactory.addButton(this, 'common.save_label', 'saveButton', () => this.saveSiteDashboardWidgetConfiguration(), true, false);

            if (!this.siteDashboardWidget) // only need 'previous' button when adding a new widget
                this.WindowFactory.addButton(this, 'siteDashboard.previous', 'previousButton', () => this.changeDashboardWidget(), false, false);

            if (this.siteDashboardWidget) { // editing an existing element
                await this.getDashboardWidgetConfiguration();
                await this.getSiteDashboardWidget();
                this.showDashboardWidgets = false;
                this.configurationLoaded = true;
                this.$timeout(() => this.form.$setPristine());
            } else { // adding a new widget
                this.showDashboardWidgets = true;
                await this.getDashboardWidgets();
            }

        } finally {
            this.wnd.processing = false;
        }
    }

    // get the list of dashboard element/widget types to choose from when adding an element/widget to a dashboard
    async getDashboardWidgets() {
        try {
            let response = await this.amtCommandQuerySvc.get(this.adminUrls.getDashboardWidgets);

            if (response?.data)
                this.dashboardWidgets = response.data;
        } catch (error) {
            this.errorReporter.logError(error);
        }
    }

    // on selection of the dashboard element/widget type when adding an element/widget to a dashboard
    async selectDashboardWidget(widget: any) {

        if (!this.siteDashboardWidget || this.siteDashboardWidget.dashboardWidget.key !== widget.key) { // no widget type had been previously selected, or the selection has changed

            this.siteDashboardWidget = {
                dashboardWidget: widget,
                active: true,
                values: {}
            };

            this.wnd.processing = true;

            try {
                await this.getDashboardWidgetConfiguration();
            } catch (error) {
                this.errorReporter.logError(error);
                return;
            } finally {
                this.wnd.processing = false;
            }
        }

        this.showDashboardWidgets = false;        
        this.configurationLoaded = true;
        this.setWindowTitle();
    }

    // on click of the 'previous' button, to change the selected dashboard element/widget type when adding an element/widget to a dashboard
    changeDashboardWidget() {
        this.showDashboardWidgets = true;        
        this.configurationLoaded = false;        
        this.setWindowTitle();
    }

    // alter the title of the popup window
    private setWindowTitle() {
        this.wnd.caption = String.format('{0} - {1}',
            this.dashboardName, (
            this.showDashboardWidgets ?
                this.amtXlatSvc.xlat('siteDashboard.addDashboardWidget') :
                this.amtXlatSvc.xlat('siteDashboard.addDashboardWidgetOfType', this.siteDashboardWidget.dashboardWidget.description)
        ));
    }

    // when editing an existing dashboard element/widget, get the existing configuration
    async getSiteDashboardWidget() {

        let criteria = {
            id: this.siteDashboardWidget.id
        };

        try {
            let response = await this.amtCommandQuerySvc.get(this.adminUrls.getSiteDashboardWidget, criteria);

            if (response?.data) {
                this.siteDashboardWidget.active = response.data.active;
                this.siteDashboardWidget.values = response.data.configurationValues;
                this.siteDashboardWidget.roleTypes = response.data.roleTypes;

                for (let configId of Object.keys(this.siteDashboardWidget.values) || []) {
                    let config = this.siteDashboardWidget.configuration.find(c => c.id === configId);

                    if (config?.name === DashboardWidgetConfigurationType.boolean)
                        this.siteDashboardWidget.values[configId] = this.siteDashboardWidget.values[configId] == 'true';

                    if (config?.name === DashboardWidgetConfigurationType.number)
                        this.siteDashboardWidget.values[configId] = +this.siteDashboardWidget.values[configId];
                }
            }
        } catch (error) {
            this.errorReporter.logError(error);
        }
    }

    // disable a field if in read-only mode or if the field has mandatory dependencies that don't have values
    protected configurationFieldDisabled(config: IDashboardWidgetConfiguration) {

        let requiredDependencies = this.siteDashboardWidget.configuration.filter(c => c.isRequired && config.dependencies?.some(d => d === c.id));

        return this.readonly ||
            (requiredDependencies?.length > 0 && !requiredDependencies.every(d => this.siteDashboardWidget?.values[d.id] && (!angular.isArray(this.siteDashboardWidget.values[d.id]) || this.siteDashboardWidget.values[d.id].length > 0)));
    }

    // get the configuration for the dashboard element/widget type
    async getDashboardWidgetConfiguration() {

        let criteria = {
            DashboardWidgetId: this.siteDashboardWidget.dashboardWidget.key
        };

        try {
            let response = await this.amtCommandQuerySvc.get(this.adminUrls.getDashboardWidgetConfiguration, criteria);

            if (response?.data) {

                this.siteDashboardWidget.configuration = response.data;

                for (let config of this.siteDashboardWidget.configuration) {

                    config.dependentFields = this.siteDashboardWidget.configuration.filter(c => c.dependencies?.some(d => d === config.id));

                    switch (config.name) {

                        case DashboardWidgetConfigurationType.fleet:
                            config.url = this.equipmentUrls.url.getFleets;
                            config.useHttpPost = false;
                            config.textField = 'name';
                            config.onChange = ((config, options) => {
                                for (let field of config.dependentFields || []) {
                                    this.setConfigurationFieldParameter(field, 'fleetId', false, options);
                                    this.setConfigurationFieldParameter(field, 'fleetIds', true, options);
                                }
                            }).bind(this, config);
                            break;

                        case DashboardWidgetConfigurationType.vehicleSpecification:
                            config.url = this.vehicleUrls.getSpecifications;
                            config.useHttpPost = true;
                            config.descriptor = this.amtXlatSvc.xlat('siteDashboard.allVehicleSpecifications');
                            config.parameters = { fleetIds: null, vehicleApplicationIds: null, activeOnly: true, siteIds: [this.ocConfigSvc.user.site.id] };
                            config.onChange = ((config, options) => {
                                for (let field of config.dependentFields || []) {
                                    this.setConfigurationFieldParameter(field, 'vehicleSpecificationId', false, options);
                                    this.setConfigurationFieldParameter(field, 'vehicleSpecificationIds', true, options);
                                }
                            }).bind(this, config);
                            break;

                        case DashboardWidgetConfigurationType.vehicleApplication:
                            config.url = this.referenceDataUrls.getVehicleApplications;
                            config.useHttpPost = true;
                            config.parameters = { hasVehicleSpecificationsOnSite: true };
                            config.onChange = ((config, options) => {
                                for (let field of config.dependentFields || []) {
                                    this.setConfigurationFieldParameter(field, 'vehicleApplicationId', false, options);
                                    this.setConfigurationFieldParameter(field, 'vehicleApplicationIds', true, options);
                                }
                            }).bind(this, config);
                            break;

                        case DashboardWidgetConfigurationType.componentType:
                            config.url = this.equipmentUrls.url.getEquipmentTypes;
                            config.useHttpPost = true;
                            config.descriptor = this.amtXlatSvc.xlat('siteDashboard.allComponentTypes');
                            config.parameters = { siteIds: [this.ocConfigSvc.user.site.id] };
                            config.onChange = ((config, options) => {
                                for (let field of config.dependentFields || []) {
                                    this.setConfigurationFieldParameter(field, 'equipmentTypeId', false, options);
                                    this.setConfigurationFieldParameter(field, 'componentTypeId', false, options);
                                    this.setConfigurationFieldParameter(field, 'equipmentTypeIds', true, options);
                                    this.setConfigurationFieldParameter(field, 'componentTypeIds', true, options);
                                }
                            }).bind(this, config);
                            break;

                        case DashboardWidgetConfigurationType.componentSpecification:
                            config.url = this.componentUrls.getSpecifications;
                            config.useHttpPost = true;
                            config.descriptor = this.amtXlatSvc.xlat('siteDashboard.allComponentSpecifications');
                            config.parameters = { siteIds: [this.ocConfigSvc.user.site.id] };
                            config.onChange = ((config, options) => {
                                for (let field of config.dependentFields || []) {
                                    this.setConfigurationFieldParameter(field, 'componentSpecificationId', false, options);
                                    this.setConfigurationFieldParameter(field, 'componentSpecificationIds', true, options);
                                }
                            }).bind(this, config);
                            break;

                        case DashboardWidgetConfigurationType.boolean:
                            if (config.defaultValue)
                                this.siteDashboardWidget.values[config.id] = config.defaultValue == 'true';
                            break;

                        case DashboardWidgetConfigurationType.number:
                            if (config.defaultValue)
                                this.siteDashboardWidget.values[config.id] = +config.defaultValue;
                            break;

                        default:
                            config.preserveOrder = true;
                            if (config.defaultValue)
                                this.siteDashboardWidget.values[config.id] = config.defaultValue;
                            break;
                    }
                }
            }
        } catch (error) {
            this.errorReporter.logError(error);
        }
    }

    // apply a value to the parameters of a field
    private setConfigurationFieldParameter(field: IDashboardWidgetConfiguration, parameter: string, multiple: boolean, values: any) {
        let paramValue = !values ? null : (angular.isArray(values) ? (multiple ? values : values[0]) : (multiple ? [values.key] : values.key));
        field.parameters[parameter] = paramValue;
    }

    // save the dashboard element/widget configuration
    async saveSiteDashboardWidgetConfiguration() {

        let criteria: ISaveSiteDashboardWidgetCriteria = {
            siteDashboardId: this.siteDashboardId,
            siteDashboardWidgetId: this.siteDashboardWidget.id,
            dashboardWidgetId: this.siteDashboardWidget.dashboardWidget.key,
            active: this.siteDashboardWidget.active,
            roleTypes: this.siteDashboardWidget.roleTypes,
            configurations: []
        };

        let configKeys = Object.keys(this.siteDashboardWidget.values);

        for (let configKey of configKeys || []) {
            let configuration: IDashboardWidgetConfiguration = this.siteDashboardWidget.configuration.find(c => c.id === configKey);
            let values = this.siteDashboardWidget.values[configKey];

            criteria.configurations.push({
                dashboardWidgetConfigurationId: configKey,
                values: configuration.isLookup ? (configuration.allowMultipleValues ? values : [this.helperSvc.getKey(values)]) : [values]
            });
        }

        this.wnd.processing = true;

        try {
            await this.amtCommandQuerySvc.post(this.adminUrls.saveSiteDashboardWidget, criteria);

            this.notifySvc.success(this.amtXlatSvc.xlat('siteDashboard.saveSiteDashboardWidgetSuccess'));

            if (this.wnd.onDataChanged)
                this.wnd.onDataChanged();

            this.form.$setPristine();

            this.closeWindow();

        } catch (error) {
            this.errorReporter.logError(error);
        } finally {
            this.wnd.processing = false;
        }
    }

    private setButtonVisibility() {
        if (this.buttonStates.previousButton)
            this.buttonStates.previousButton.visible = !this.siteDashboardWidget?.id && !this.showDashboardWidgets;

        this.buttonStates.saveButton.visible = !this.showDashboardWidgets && !this.readonly;
    }

    async closeWindow() {
        try {
            await this.confirmSvc.confirmSaveChange(this.form.$dirty);
        } catch (error) {
            return false; // they cancelled
        }

        this.form.$setPristine();

        this.WindowFactory.closeWindow(this.wnd);

        return true;
    }
}

angular.module('app.admin').component('siteDashboardConfigurationDetails', {
    template: tmpl,
    controllerAs: 'vm',
    controller: SiteDashboardConfigurationDetailsCtrl,
    bindings: {
        initParams: '<',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    }
});