/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
// Incremented each time the service is instantiated.
var sequence = 0;
/**
 * @hidden
 */
var IdService = /** @class */ (function () {
    function IdService() {
        this.prefix = "k-grid" + sequence++;
    }
    IdService.prototype.gridId = function () {
        return this.prefix;
    };
    IdService.prototype.cellId = function (rowIndex, colIndex) {
        return this.prefix + "-r" + rowIndex + "c" + colIndex;
    };
    IdService.prototype.selectionCheckboxId = function (itemIndex) {
        return this.prefix + "-checkbox" + itemIndex;
    };
    IdService.prototype.selectAllCheckboxId = function () {
        return this.prefix + "-select-all";
    };
    IdService = tslib_1.__decorate([
        core_1.Injectable(),
        tslib_1.__metadata("design:paramtypes", [])
    ], IdService);
    return IdService;
}());
exports.IdService = IdService;
