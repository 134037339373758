/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var animations_1 = require("@angular/animations");
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var kendo_licensing_1 = require("@progress/kendo-licensing");
var package_metadata_1 = require("../package-metadata");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var kendo_angular_popup_1 = require("@progress/kendo-angular-popup");
var focus_service_1 = require("../focusable/focus.service");
var navigation_action_1 = require("../navigation/navigation-action");
var navigation_config_1 = require("../navigation/navigation-config");
var navigation_service_1 = require("../navigation/navigation.service");
var util_1 = require("../util");
var utils_1 = require("./utils");
var animations_2 = require("./animations/animations");
var preventable_event_1 = require("../preventable-event");
var dial_item_template_directive_1 = require("./templates/dial-item-template.directive");
var fab_template_directive_1 = require("./templates/fab-template.directive");
var NAVIGATION_SETTINGS = {
    useLeftRightArrows: false
};
var ɵ0 = NAVIGATION_SETTINGS;
exports.ɵ0 = ɵ0;
var NAVIGATION_SETTINGS_PROVIDER = {
    provide: navigation_config_1.NAVIGATION_CONFIG,
    useValue: ɵ0
};
var SIZE_CLASSES = {
    small: 'k-fab-sm',
    medium: 'k-fab-md',
    large: 'k-fab-lg'
};
var SHAPE_CLASSES = {
    rectangle: 'k-fab-rectangle',
    square: 'k-fab-square'
};
var ROUNDED_CLASSES = {
    small: 'k-rounded-sm',
    medium: 'k-rounded-md',
    large: 'k-rounded-lg',
    full: 'k-rounded-full'
};
var FILLMODE_CLASS = 'k-fab-solid';
var DEFAULT_DURATION = 180;
var DEFAULT_ITEM_GAP = 90;
var DEFAULT_OFFSET = '16px';
/**
 *
 * Represents the [Kendo UI FloatingActionButton component for Angular]({% slug overview_floatingactionbutton %}).
 * Used to specify the primary or the most common action in an application.
 *
 */
var FloatingActionButtonComponent = /** @class */ (function () {
    function FloatingActionButtonComponent(renderer, element, focusService, navigationService, ngZone, popupService, builder, localizationService) {
        var _this = this;
        this.renderer = renderer;
        this.element = element;
        this.focusService = focusService;
        this.navigationService = navigationService;
        this.ngZone = ngZone;
        this.popupService = popupService;
        this.builder = builder;
        this.localizationService = localizationService;
        /**
         * Specifies the positionMode of the FloatingActionButton
         * ([see example]({% slug positioning_floatingactionbutton %}#toc-positionMode)).
         *
         * * The possible values are:
         * * `absolute`&mdash;Positions the FloatingActionButton absolutely to its first positioned parent element.
         * * `fixed` (Default)&mdash;Positions the FloatingActionButton relative to the viewport. It always stays in the same place even if the page is scrolled.
         */
        this.positionMode = 'fixed';
        /**
         * Specifies the animation settings of the FloatingActionButton dial items.
         * ([see example]({% slug dialitems_floatingactionbutton %}#toc-animation)).
         *
         * The possible values are:
         * * Boolean
         *    * (Default) `true`&mdash;Applies the default [`DialItemAnimation`]({% slug api_buttons_dialitemanimation %}) settings.
         *    * `false`
         * * `DialItemAnimation`
         *    * `duration`&mdash;Specifies the animation duration in milliseconds for each dial item. Defaults to `180ms`.
         *    * `gap`&mdash;Specifies the animation duration gap in milliseconds after each dial item is animated. Defaults to `90ms`.
         */
        this.dialItemAnimation = true;
        /**
         * Specifies the [`tabIndex`](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex) of the FloatingActionButton.
         */
        this.tabIndex = 0;
        /**
         * Specifies the collection of the dial items that will be rendered in the FloatingActionButton popup.
         */
        this.dialItems = [];
        /**
         * Fires each time the FloatingActionButton gets blurred.
         */
        this.onBlur = new core_1.EventEmitter();
        /**
         * Fires each time the FloatingActionButton gets focused.
         */
        this.onFocus = new core_1.EventEmitter();
        /**
         * Fires each time a dial item is clicked.
         */
        this.dialItemClick = new core_1.EventEmitter();
        /**
         * Fires each time the popup is about to open.
         * This event is preventable. If you cancel the event, the popup will remain closed
         * ([more information and example]({% slug overview_floatingactionbutton %}#toc-events)).
         */
        this.open = new core_1.EventEmitter();
        /**
         * Fires each time the popup is about to close.
         * This event is preventable. If you cancel the event, the popup will remain open
         * ([more information and example]({% slug overview_floatingactionbutton %}#toc-events)).
         */
        this.close = new core_1.EventEmitter();
        /**
         * @hidden
         */
        this.id = "k-" + kendo_angular_common_1.guid();
        this._themeColor = 'primary';
        this._size = 'medium';
        this._shape = 'rectangle';
        this._disabled = false;
        this._align = { horizontal: 'end', vertical: 'bottom' };
        this._offset = { x: DEFAULT_OFFSET, y: DEFAULT_OFFSET };
        this._rounded = 'full';
        this.subscriptions = new rxjs_1.Subscription();
        this.rtl = false;
        this.animationEnd = new core_1.EventEmitter();
        this.initialSetup = true;
        kendo_licensing_1.validatePackage(package_metadata_1.packageMetadata);
        this.subscribeNavigationEvents();
        this.subscriptions.add(this.localizationService.changes.subscribe(function (_a) {
            var rtl = _a.rtl;
            _this.rtl = rtl;
            _this.direction = _this.rtl ? 'rtl' : 'ltr';
        }));
    }
    Object.defineProperty(FloatingActionButtonComponent.prototype, "fixedClass", {
        get: function () {
            return this.positionMode === 'fixed';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FloatingActionButtonComponent.prototype, "absoluteClass", {
        get: function () {
            return this.positionMode === 'absolute';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FloatingActionButtonComponent.prototype, "themeColor", {
        get: function () {
            return this._themeColor;
        },
        /**
         * Specifies the theme color of the FloatingActionButton
         * ([see example]({% slug appearance_floatingactionbutton %}#toc-themeColor)).
         * The theme color will be applied as background color of the component.
         *
         * The possible values are:
         * * `'primary'` (Default)&mdash;Applies coloring based on the `primary` theme color.
         * * `'secondary'`&mdash;Applies coloring based on the `secondary` theme color.
         * * `'tertiary'`&mdash; Applies coloring based on the `tertiary` theme color.
         * * `'info'`&mdash;Applies coloring based on the `info` theme color.
         * * `'success'`&mdash; Applies coloring based on the `success` theme color.
         * * `'warning'`&mdash; Applies coloring based on the `warning` theme color.
         * * `'error'`&mdash; Applies coloring based on the `error` theme color.
         * * `'dark'`&mdash; Applies coloring based on the `dark` theme color.
         * * `'light'`&mdash; Applies coloring based on the `light` theme color.
         * * `'inverse'`&mdash; Applies coloring based on the `inverse` theme color.
         */
        set: function (themeColor) {
            this.handleClasses(themeColor, 'themeColor');
            this._themeColor = themeColor;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FloatingActionButtonComponent.prototype, "size", {
        get: function () {
            return this._size;
        },
        /**
         * Specifies the size of the FloatingActionButton
         * ([see example]({% slug appearance_floatingactionbutton %}#toc-size)).
         *
         * The possible values are:
         * * `'small'`
         * * `'medium'` (Default)
         * * `'large'`
         */
        set: function (size) {
            this.handleClasses(size, 'size');
            this._size = size;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FloatingActionButtonComponent.prototype, "rounded", {
        get: function () {
            return this._rounded;
        },
        /**
         * The rounded property specifies the border radius of the FloatingActionButton.
         *
         * The possible values are:
         * * `'small'`
         * * `'medium'`
         * * `'large'`
         * * `'full'` (default)
         * * `null`
         */
        set: function (rounded) {
            this.handleClasses(rounded, 'rounded');
            this._rounded = rounded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FloatingActionButtonComponent.prototype, "shape", {
        get: function () {
            return this._shape;
        },
        /**
         * Specifies the shape of the FloatingActionButton
         * ([see example]({% slug appearance_floatingactionbutton %}#toc-shape)).
         *
         * The possible values are:
         * * `rectangle` (Default)&mdash;Applies rectangular shape on the FloatingActionButton.
         * * `square`&mdash;Applies square shape on the FloatingActionButton.
         *
         */
        set: function (shape) {
            this.handleClasses(shape, 'shape');
            this._shape = shape;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FloatingActionButtonComponent.prototype, "disabled", {
        get: function () {
            return this._disabled;
        },
        /**
         * Specifies whether the FloatingActionButton is disabled.
         */
        set: function (disabled) {
            this._disabled = disabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FloatingActionButtonComponent.prototype, "align", {
        get: function () {
            return this._align;
        },
        /**
         * Specifies the horizontal and vertical alignment of the FloatingActionButton
         * ([see example]({% slug positioning_floatingactionbutton %}#toc-align)).
         *
         * The possible values are:
         * * `{ horizontal: 'start'|'center'|'end', vertical: 'top'|'middle'|'bottom' }`
         *
         * The default value is:
         * * `{ horizontal: 'end', vertical: 'bottom' }`
         *
         */
        set: function (align) {
            this._align = Object.assign(this._align, align);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FloatingActionButtonComponent.prototype, "offset", {
        get: function () {
            return this._offset;
        },
        /**
         * Specifies the horizontal and vertical offset position of the FloatingActionButton
         * ([see example]({% slug positioning_floatingactionbutton %}#toc-offset)).
         *
         * * The default value is:
         * * `{ x: '16px', y: '16px' }`
         */
        set: function (offset) {
            this._offset = Object.assign(this._offset, offset);
            this.offsetStyles();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FloatingActionButtonComponent.prototype, "componentTabIndex", {
        /**
         * @hidden
         */
        get: function () {
            return this.disabled ? (-1) : this.tabIndex;
        },
        enumerable: true,
        configurable: true
    });
    FloatingActionButtonComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        ['shape', 'size', 'rounded', 'themeColor'].forEach(function (option) { return _this.handleClasses(_this[option], option); });
        this.renderer.addClass(this.element.nativeElement, this.alignClass());
        this.offsetStyles();
        this.initialSetup = false;
    };
    FloatingActionButtonComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    Object.defineProperty(FloatingActionButtonComponent.prototype, "isOpen", {
        /**
         * Indicates whether the FloatingActionButton is currently open.
         */
        get: function () { return util_1.isPresent(this.popupRef); },
        enumerable: true,
        configurable: true
    });
    /**
     * Focuses the FloatingActionButton.
     */
    FloatingActionButtonComponent.prototype.focus = function () {
        if (kendo_angular_common_1.isDocumentAvailable()) {
            this.button.nativeElement.focus();
        }
    };
    /**
     * Blurs the FloatingActionButton.
     */
    FloatingActionButtonComponent.prototype.blur = function () {
        if (kendo_angular_common_1.isDocumentAvailable()) {
            this.button.nativeElement.blur();
        }
    };
    /**
     * Toggles the visibility of the FloatingActionButton dial items popup.
     *
     * If you use the `toggleDial` method to open or close the dial items,
     * the `open` and `close` events do not fire ([more information and examples]({% slug openstate_floatingactionbutton %}#toc-events)).
     *
     * @param open - The state of dial items popup.
     */
    FloatingActionButtonComponent.prototype.toggleDial = function (open) {
        var _this = this;
        if (this.disabled || !this.hasDialItems) {
            return;
        }
        var shouldOpen = util_1.isPresent(open) ? open : !this.isOpen;
        if (this.disabled || shouldOpen === this.isOpen) {
            return;
        }
        shouldOpen ? setTimeout(function () { return _this.openDial(); }) : this.closeDial();
    };
    Object.defineProperty(FloatingActionButtonComponent.prototype, "role", {
        /**
         * @hidden
         */
        get: function () {
            return (this.dialItems && this.dialItems.length) ? 'menubutton' : 'button';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FloatingActionButtonComponent.prototype, "ariaExpanded", {
        /**
         * @hidden
         */
        get: function () {
            return util_1.isPresent(this.isOpen);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FloatingActionButtonComponent.prototype, "ariaHasPopup", {
        /**
         * @hidden
         */
        get: function () {
            return util_1.isPresent(this.isOpen);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FloatingActionButtonComponent.prototype, "iconClasses", {
        /**
         * @hidden
         */
        get: function () {
            var classes = [];
            if (this.iconClass) {
                classes.push("" + this.iconClass);
            }
            if (this.icon) {
                classes.push("k-fab-icon k-icon k-i-" + this.icon);
            }
            return classes;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @hidden
     */
    FloatingActionButtonComponent.prototype.clickHandler = function () {
        var _this = this;
        if (this.disabled || !this.hasDialItems) {
            return;
        }
        this.ngZone.run(function () {
            var shouldOpen = !_this.isOpen;
            _this.toggleDialWithEvents(shouldOpen);
        });
    };
    /**
     * @hidden
     */
    FloatingActionButtonComponent.prototype.keyDownHandler = function (event) {
        this.keyHandler(event);
    };
    /**
     * @hidden
     */
    FloatingActionButtonComponent.prototype.keyHandler = function (event, keyEvent) {
        if (this.disabled) {
            return;
        }
        var focused = this.focusService.focused || 0;
        var keyCode = event.keyCode;
        var action = this.navigationService.process({
            altKey: event.altKey,
            current: focused,
            keyCode: keyCode,
            keyEvent: keyEvent,
            max: this.dialItems ? this.dialItems.length - 1 : 0,
            min: 0,
            flipNavigation: this.align.vertical === 'bottom'
        });
        if (action !== navigation_action_1.NavigationAction.Undefined &&
            action !== navigation_action_1.NavigationAction.Tab) {
            event.preventDefault();
        }
    };
    /**
     * @hidden
     */
    FloatingActionButtonComponent.prototype.onItemClick = function (event) {
        var item = util_1.closest(event.target, '.k-fab-item');
        if (!item) {
            return;
        }
        var index = item.getAttribute('data-fab-item-index');
        this.emitItemClick(index);
    };
    /**
     * @hidden
     */
    FloatingActionButtonComponent.prototype.focusHandler = function () {
        if (!this.disabled) {
            this.onFocus.emit();
        }
    };
    /**
     * @hidden
     */
    FloatingActionButtonComponent.prototype.blurHandler = function () {
        this.onBlur.emit();
        this.toggleDialWithEvents(false);
    };
    FloatingActionButtonComponent.prototype.handleClasses = function (inputValue, input) {
        if (util_1.isPresent(this.button) && (this[input] !== inputValue || this.initialSetup)) {
            var button = this.button.nativeElement;
            var classesToRemove = {
                themeColor: FILLMODE_CLASS + "-" + this.themeColor,
                size: SIZE_CLASSES[this.size],
                shape: SHAPE_CLASSES[this.shape],
                rounded: ROUNDED_CLASSES[this.rounded]
            };
            var classesToAdd = {
                themeColor: inputValue ? FILLMODE_CLASS + "-" + inputValue : null,
                size: SIZE_CLASSES[inputValue],
                shape: SHAPE_CLASSES[inputValue],
                rounded: ROUNDED_CLASSES[inputValue]
            };
            this.renderer.removeClass(button, classesToRemove[input]);
            if (classesToAdd[input]) {
                this.renderer.addClass(button, classesToAdd[input]);
            }
        }
    };
    FloatingActionButtonComponent.prototype.onEnterPressed = function () {
        var index = this.focusService.focused;
        this.emitItemClick(index);
    };
    FloatingActionButtonComponent.prototype.emitItemClick = function (index) {
        var item = this.dialItems[index];
        if (item && !item.disabled) {
            var clickEventArgs = { item: item, index: index };
            this.dialItemClick.emit(clickEventArgs);
            this.toggleDialWithEvents(false);
        }
    };
    FloatingActionButtonComponent.prototype.subscribeNavigationEvents = function () {
        this.subscriptions.add(this.navigationService.navigate.subscribe(this.onArrowKeyNavigate.bind(this)));
        this.subscriptions.add(this.navigationService.enter.subscribe(this.onNavigationEnterPress.bind(this)));
        this.subscriptions.add(rxjs_1.merge(this.navigationService.close, this.navigationService.esc).subscribe(this.onNavigationClose.bind(this)));
    };
    FloatingActionButtonComponent.prototype.onArrowKeyNavigate = function (index) {
        this.focusService.focus(index);
    };
    FloatingActionButtonComponent.prototype.onNavigationEnterPress = function () {
        var _this = this;
        this.ngZone.run(function () {
            if (_this.isOpen) {
                var focusedIndex = _this.focusService.focused;
                var focusedItem = _this.dialItems[focusedIndex];
                if (focusedItem && focusedItem.disabled) {
                    return;
                }
                if (util_1.isPresent(focusedIndex) && focusedIndex !== -1) {
                    _this.onEnterPressed();
                    return;
                }
            }
            if (!_this.isOpen && kendo_angular_common_1.isDocumentAvailable()) {
                _this.toggleDialWithEvents(true);
                _this.button.nativeElement.focus();
            }
        });
    };
    FloatingActionButtonComponent.prototype.onNavigationClose = function () {
        var _this = this;
        if (this.isOpen) {
            this.ngZone.run(function () {
                _this.toggleDialWithEvents(false);
                if (kendo_angular_common_1.isDocumentAvailable()) {
                    _this.button.nativeElement.focus();
                }
            });
        }
    };
    FloatingActionButtonComponent.prototype.alignClass = function () {
        return "k-pos-" + this.align.vertical + "-" + this.align.horizontal;
    };
    FloatingActionButtonComponent.prototype.toggleDialWithEvents = function (open) {
        if (open === this.isOpen) {
            return;
        }
        var event = new preventable_event_1.PreventableEvent();
        open ? this.open.emit(event) : this.close.emit(event);
        if (event.isDefaultPrevented()) {
            return;
        }
        open ? this.openDial() : this.closeDial();
    };
    FloatingActionButtonComponent.prototype.openPopup = function () {
        var _this = this;
        if (this.isOpen) {
            return;
        }
        var isIconFab = this.icon && !this.text;
        var rtl = this.rtl;
        var align = this.align;
        this.popupRef = this.popupService.open({
            anchor: this.element.nativeElement,
            animate: false,
            content: this.popupTemplate,
            anchorAlign: utils_1.getAnchorAlign(align, rtl),
            popupAlign: utils_1.getPopupAlign(align, rtl),
            popupClass: 'k-fab-popup k-popup-transparent'
        });
        var popupElement = this.popupRef.popupElement;
        this.renderer.setStyle(popupElement, 'box-shadow', 'none');
        if (isIconFab) {
            this.subscriptions.add(this.popupRef.popupOpen.subscribe(function () { return _this.positionPopup(); }));
        }
        this.ngZone.runOutsideAngular(function () {
            _this.popupMouseDownListener = _this.renderer.listen(popupElement, 'mousedown', function (event) {
                event.preventDefault();
            });
        });
        this.popupRef.popupAnchorViewportLeave.subscribe(function () { return _this.toggleDialWithEvents(false); });
    };
    FloatingActionButtonComponent.prototype.closePopup = function () {
        if (this.isOpen) {
            if (this.popupMouseDownListener) {
                this.popupMouseDownListener();
            }
            this.popupRef.close();
            this.popupRef = null;
        }
    };
    FloatingActionButtonComponent.prototype.openDial = function () {
        this.openPopup();
        this.focusService.focus(0);
        if (this.dialItemAnimation && this.isValidAnimation()) {
            this.playAnimation(true);
        }
    };
    FloatingActionButtonComponent.prototype.closeDial = function () {
        var _this = this;
        if (this.dialItemAnimation && this.isValidAnimation()) {
            this.playAnimation(false);
            this.animationEnd.pipe(operators_1.take(1)).subscribe(function () { return _this.closePopup(); });
        }
        else {
            this.closePopup();
        }
    };
    FloatingActionButtonComponent.prototype.isValidAnimation = function () {
        var animation = this.dialItemAnimation;
        if (typeof animation !== 'boolean') {
            return animation.duration !== 0;
        }
        return true;
    };
    FloatingActionButtonComponent.prototype.positionPopup = function () {
        if (this.dialItemTemplate) {
            return;
        }
        var fab = this.element.nativeElement;
        var fabWidth = fab.getBoundingClientRect().width;
        var popupEl = this.popupRef.popupElement;
        var icon = popupEl.querySelector('.k-fab-item-icon');
        if (!icon) {
            return;
        }
        var iconWidth = icon.getBoundingClientRect().width;
        var left = (fabWidth / 2) - (iconWidth / 2);
        var popupLeft = popupEl.getBoundingClientRect().left;
        var isEndAlign = this.align.horizontal === 'end';
        var leftValue = isEndAlign ? (popupLeft - left) : (left + popupLeft);
        var rtlLeftValue = isEndAlign ? (left + popupLeft) : (popupLeft - left);
        popupEl.style.left = this.rtl ? rtlLeftValue + "px" : leftValue + "px";
    };
    FloatingActionButtonComponent.prototype.offsetStyles = function () {
        var hostElement = this.element.nativeElement;
        this.renderer.setStyle(hostElement, this.horizontalPosition, this.horizontalOffset);
        this.renderer.setStyle(hostElement, this.verticalPosition, this.verticalOffset);
    };
    Object.defineProperty(FloatingActionButtonComponent.prototype, "hasDialItems", {
        get: function () {
            return util_1.isPresent(this.dialItems) && this.dialItems.length !== 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FloatingActionButtonComponent.prototype, "verticalPosition", {
        /**
         * Gets the CSS prop name of the selected vertical position (`top`/`bottom`);
         */
        get: function () {
            return {
                top: 'top',
                middle: 'top',
                bottom: 'bottom'
            }[this.align.vertical];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FloatingActionButtonComponent.prototype, "verticalOffset", {
        /**
         * Gets the offset according to the selected vertical position.
         */
        get: function () {
            if (this.align.vertical === 'middle') {
                return this.offset.y === DEFAULT_OFFSET ? '50%' : "calc(50% + " + this.offset.y + ")";
            }
            return this.offset.y;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FloatingActionButtonComponent.prototype, "horizontalPosition", {
        /**
         * Gets the CSS prop name of the selected horizontal position (`left`/`right`);
         */
        get: function () {
            var horizontal = this.align.horizontal;
            return {
                end: this.rtl ? 'left' : 'right',
                center: 'left',
                start: this.rtl ? 'right' : 'left'
            }[horizontal];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FloatingActionButtonComponent.prototype, "horizontalOffset", {
        /**
         * Gets the offset according to the selected horizontal position.
         */
        get: function () {
            if (this.align.horizontal === 'center') {
                return this.offset.x === DEFAULT_OFFSET ? '50%' : "calc(50% + " + this.offset.x + ")";
            }
            return this.offset.x;
        },
        enumerable: true,
        configurable: true
    });
    FloatingActionButtonComponent.prototype.playerFor = function (element, animation) {
        var factory = this.builder.build(animation);
        return factory.create(element);
    };
    FloatingActionButtonComponent.prototype.playAnimation = function (open) {
        var _this = this;
        var durationSettings = this.durationSettings();
        var animationSettings = {
            duration: durationSettings.duration,
            gap: durationSettings.gap,
            align: this.align
        };
        var animation = open ? animations_2.openAnimation(animationSettings) : animations_2.closeAnimation(animationSettings);
        var player = this.playerFor(this.popupRef.popupElement, animation);
        player.play();
        player.onDone(function () {
            if (player) {
                _this.animationEnd.emit();
                player.destroy();
                player = null;
            }
        });
    };
    FloatingActionButtonComponent.prototype.durationSettings = function () {
        return {
            duration: this.animationDuration(),
            gap: this.animationGap()
        };
    };
    FloatingActionButtonComponent.prototype.animationGap = function () {
        var animation = this.dialItemAnimation;
        if (typeof animation !== 'boolean' && util_1.isPresent(animation.gap)) {
            return animation.gap;
        }
        return DEFAULT_ITEM_GAP;
    };
    FloatingActionButtonComponent.prototype.animationDuration = function () {
        var animation = this.dialItemAnimation;
        if (typeof animation !== 'boolean' && util_1.isPresent(animation.duration)) {
            return animation.duration;
        }
        return DEFAULT_DURATION;
    };
    tslib_1.__decorate([
        core_1.HostBinding('class.k-pos-fixed'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], FloatingActionButtonComponent.prototype, "fixedClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-pos-absolute'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], FloatingActionButtonComponent.prototype, "absoluteClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.dir'),
        tslib_1.__metadata("design:type", String)
    ], FloatingActionButtonComponent.prototype, "direction", void 0);
    tslib_1.__decorate([
        core_1.ViewChild('button', { static: true }),
        tslib_1.__metadata("design:type", core_1.ElementRef)
    ], FloatingActionButtonComponent.prototype, "button", void 0);
    tslib_1.__decorate([
        core_1.ViewChild('popupTemplate', { static: true }),
        tslib_1.__metadata("design:type", core_1.TemplateRef)
    ], FloatingActionButtonComponent.prototype, "popupTemplate", void 0);
    tslib_1.__decorate([
        core_1.ContentChild(dial_item_template_directive_1.DialItemTemplateDirective, { static: false }),
        tslib_1.__metadata("design:type", dial_item_template_directive_1.DialItemTemplateDirective)
    ], FloatingActionButtonComponent.prototype, "dialItemTemplate", void 0);
    tslib_1.__decorate([
        core_1.ContentChild(fab_template_directive_1.FloatingActionButtonTemplateDirective, { static: false }),
        tslib_1.__metadata("design:type", fab_template_directive_1.FloatingActionButtonTemplateDirective)
    ], FloatingActionButtonComponent.prototype, "fabTemplate", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], FloatingActionButtonComponent.prototype, "themeColor", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], FloatingActionButtonComponent.prototype, "size", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], FloatingActionButtonComponent.prototype, "rounded", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], FloatingActionButtonComponent.prototype, "shape", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [Boolean])
    ], FloatingActionButtonComponent.prototype, "disabled", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object),
        tslib_1.__metadata("design:paramtypes", [Object])
    ], FloatingActionButtonComponent.prototype, "align", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object),
        tslib_1.__metadata("design:paramtypes", [Object])
    ], FloatingActionButtonComponent.prototype, "offset", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], FloatingActionButtonComponent.prototype, "positionMode", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], FloatingActionButtonComponent.prototype, "icon", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], FloatingActionButtonComponent.prototype, "iconClass", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], FloatingActionButtonComponent.prototype, "buttonClass", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], FloatingActionButtonComponent.prototype, "dialClass", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], FloatingActionButtonComponent.prototype, "text", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], FloatingActionButtonComponent.prototype, "dialItemAnimation", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], FloatingActionButtonComponent.prototype, "tabIndex", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Array)
    ], FloatingActionButtonComponent.prototype, "dialItems", void 0);
    tslib_1.__decorate([
        core_1.Output('blur'),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], FloatingActionButtonComponent.prototype, "onBlur", void 0);
    tslib_1.__decorate([
        core_1.Output('focus'),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], FloatingActionButtonComponent.prototype, "onFocus", void 0);
    tslib_1.__decorate([
        core_1.Output('dialItemClick'),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], FloatingActionButtonComponent.prototype, "dialItemClick", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], FloatingActionButtonComponent.prototype, "open", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], FloatingActionButtonComponent.prototype, "close", void 0);
    FloatingActionButtonComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-floatingactionbutton',
            providers: [
                focus_service_1.FocusService,
                navigation_service_1.NavigationService,
                NAVIGATION_SETTINGS_PROVIDER,
                kendo_angular_l10n_1.LocalizationService,
                {
                    provide: kendo_angular_l10n_1.L10N_PREFIX,
                    useValue: 'kendo.floatingactionbutton'
                }
            ],
            template: "\n        <button\n            #button\n            [attr.id]=\"id\"\n            [attr.role]=\"role\"\n            [tabIndex]=\"componentTabIndex\"\n            type=\"button\"\n            class=\"k-fab k-fab-solid\"\n            [class.k-disabled]=\"disabled\"\n            [ngClass]=\"buttonClass\"\n            [disabled]=\"disabled\"\n            [attr.aria-disabled]=\"disabled\"\n            [attr.aria-expanded]=\"ariaExpanded\"\n            [attr.aria-haspopup]=\"ariaHasPopup\"\n            (focus)=\"focusHandler()\"\n            (blur)=\"blurHandler()\"\n            [kendoEventsOutsideAngular]=\"{\n                keydown: keyDownHandler,\n                click: clickHandler\n            }\"\n            [scope]=\"this\"\n        >\n            <ng-template *ngIf=\"fabTemplate\"\n                [ngTemplateOutlet]=\"fabTemplate?.templateRef\"\n            >\n            </ng-template>\n\n            <ng-container *ngIf=\"!fabTemplate\">\n                <span *ngIf=\"icon || iconClass\" [ngClass]=\"iconClasses\"></span>\n                <span *ngIf=\"text\" class=\"k-fab-text\">{{ text }}</span>\n            </ng-container>\n        </button>\n\n        <ng-template #popupTemplate>\n            <ul\n                kendoDialList\n                [ngClass]=\"dialClass\"\n                [dialItems]=\"dialItems\"\n                [dialItemTemplate]='dialItemTemplate?.templateRef'\n                [align]=\"align\"\n                [attr.aria-labelledby]=\"id\"\n                (click)=\"onItemClick($event)\"\n            >\n            </ul>\n        </ng-template>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.Renderer2,
            core_1.ElementRef,
            focus_service_1.FocusService,
            navigation_service_1.NavigationService,
            core_1.NgZone,
            kendo_angular_popup_1.PopupService,
            animations_1.AnimationBuilder,
            kendo_angular_l10n_1.LocalizationService])
    ], FloatingActionButtonComponent);
    return FloatingActionButtonComponent;
}());
exports.FloatingActionButtonComponent = FloatingActionButtonComponent;
