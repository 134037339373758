//import angular from 'angular';


angular.module('app')
        .provider('enums', [function () {
            var enums = {
                action: {
                    view: 'View',
                    create: 'Create',
                    copy: 'Copy',
                    edit: 'Edit',
                    remove: 'Remove'
                },
                formState: {
                    ready: "ready",
                    loading: "loading",
                    error: "error"
                },
                objectState: {
                    created: 'created',
                    loaded: 'loaded',
                    loading: 'loading',
                    error: 'error'
                },
                httpUpdateStatus: {
                    fail: 'fail',
                    success: 'success'
                },
                httpStatus: {
                    fail: 'fail',
                    success: 'success'
                },
                screenStatus: {
                    loading: 'loading',
                    error: 'error',
                    ready: 'ready'
                },
                browser: {
                    name: {
                        chrome: 'Chrome',
                        firefox: 'Firefox',
                        safari: 'Safari',
                        ie: 'IE',
                        unknown: 'Unknown'
                    },
                    alias: {
                        chrome: 'Chrome',
                        firefox: 'Firefox',
                        safari: 'Safari',
                        msie: 'MSIE',
                        trident: 'Trident',
                        unknown: 'Unknown'
                    },
                    versionSupported: {
                        chrome: 37,
                        firefox: 27,
                        safari: 4,
                        msie: 10,
                        trident: 6
                    }
                },
                os: {
                    name: {
                        windows: 'Windows',
                        mac: 'Mac'
                    }
                },
                mobile: {
                    modules: [{
                        active: true,
                        permissions: ['Security.Mobile.Maintenance'],
                        permissionLevel: 'readWrite',
                        matchAllPermissions: true,
                        Service: 'maintenanceSessionService'
                    },
                    {
                        active: true,
                        permissions: ['Security.Mobile.FieldSurvey'],
                        permissionLevel: 'readWrite',
                        matchAllPermissions: true,
                        Service: 'fieldSurveyService'
                    },
                    {
                        active: true,
                        permissions: ['Security.Mobile.Receive.Component'],
                        permissionLevel: 'readWrite',
                        matchAllPermissions: true,
                        Service: 'componentReceiveService'
                    },
                    {
                        active: true,
                        permissions: ['Security.Mobile.Stocktake'],
                        permissionLevel: 'readWrite',
                        matchAllPermissions: true,
                        Service: 'stocktakeService'
                    },
                    {
                        active: true,
                        permissions: ['Security.Mobile.Receive.Vehicle'],
                        permissionLevel: 'readWrite',
                        matchAllPermissions: true,
                        Service: 'vehicleReceiveService'
                    },
                    {
                        active: true,
                        permissions: ['Security.Mobile.StatusChange'],
                        permissionLevel: 'readWrite',
                        matchAllPermissions: true,
                        Service: 'statusChangeService'
                    }]
                },
                modalSize: {
                    sm: 'sm',
                    lg: 'lg',
                    md: ''
                },
                modalBackdrop: {
                    "static": 'static',
                },
                mode: {
                    view: 'View',
                    create: 'Create',
                    update: 'Update'
                },
                notification: {
                    success: 'success',
                    error: 'error',
                    info: 'info'
                },
                rating: {
                    rating1: 1,
                    rating2: 2,
                    rating3: 3,
                    rating4: 4
                },

                shopFieldIndicator: {
                    shop: 'S',
                    field: 'F'
                },
                assetType: {
                    real: 1,
                    centerlines: 4,
                    modelling: 5
                },
                costSource: {
                    manual: 0,
                    standardJob: 1,
                    costAllocation: 2,
                    consumable: 3,
                    rrTask: 4
                },
                utilisationMethod: {
                    usageBased: 1,
                    dateBased: 2
                },
                schedulingGroupType: {
                    suppression: 1,
                    dependency: 2
                },
                schedulingGroupSubType: {
                    parentSuppression: false,
                    siblingSuppression: true
                },
                schedulingGroupSubTypeValue: {
                    parentSuppression: 0,
                    siblingSuppression: 1
                },
                advancedSchedulingLogic: {
                    noAdvancedScheduling: 0,
                    dependencyLogic: 1,
                    parentSuppression: 2,
                    siblingSuppression: 3,
                    inspectionLogic: 4
                },
                taskMode: {
                    planningTask: 1,
                    nonPlanningTask: 0,
                    unassignedTask: 1
                },
                performanceStrategyType: {
                    onCondition: 1,
                    onSchedule: 2,
                    runToFailure: 3,
                    billingType: {
                        usageRateByMonthlyDateRange: 'UsageRateByMonthlyDateRange',
                        usageRateByUsageSteps: 'UsageRateByUsageSteps',
                        dailyRateByDateRange: 'DailyRateByDateRange',
                        monthlyRateByMonthlyDateRange: 'MonthlyRateByMonthlyDateRange',
                        unknown: 'Unknown'
                    }
                },
                budEqpRoutines: {
                    replaceUsages: 1,
                    replaceCosts: 2,
                    flexUtilization: 3,
                    replaceCostsMonth: 4,
                    recalculation: 5,
                    replacePHRevenue: 6
                },
                budgetSatus: {
                    editable: 1,
                    locked: 2
                },
                taskEditorMode: {
                    none: 0,
                    planning: 1,
                    nonPlanning: 2,
                    unassigned: 3,
                    otherOperatingCosts: 4,
                    capital: 5,
                    repairReserve: 6
                },
                taskEditorUtilisationMethod: {
                    advancedUtilisation: 1,
                    simpleUtilisation: 2,
                    noUtilisation: 3
                },
                scheduleType: {
                    lastPerformedUOM: 1,
                    lastScheduledUOM: 2,
                    lastPerformedDate: 3,
                    lastScheduledDate: 4
                },
                numberRange: {
                    maxInt32: 2147483647
                },
                formatStyle: {
                    numberOverWritten: 'NUMBER_OVERWRITTEN',
                    normalNumber: 'NORM_NUMBER',
                    numberEdited: 'NUMBER_EDITED',
                    normalTextReadonly: 'NORM_TEXT_READONLY',
                    centreline: 5,
                    estimateCurrent: 6,
                    estimateDraft: 7
                },
                projectionType: {
                    current: 1,
                    budget: 2,
                    alternate: 3,
                    archived: 4,
                    centreline: 5,
                    estimateCurrent: 6,
                    estimateDraft: 7
                },
                schedulingMethod: {
                    usageBased: 0,
                    dateBased: 1
                },
                projectionTypes: {
                    current: '1',
                    alternate: '42'
                },
                taskJobFields: ['jobCodeId', 'currencyId', 'costSourceId', 'costBearerId', 'costCentreId', 'workGroupId', 'partTypeId', 'oHSId', 'pricingDate', 'consumableQty', 'consumableTopUpPercent', 'consumablePrice', 'jobPartsCost',
                    'jobLabourCost', 'jobMiscCost', 'partsExpElementId', 'labourExpElementId', 'miscExpElementId', 'parts_EDC', 'labour_EDC', 'misc_EDC', 'labourTypeId', 'job_Labour_Hrs', 'labourActivityId', 'jobDuration'],
                buttonTypes: {
                    button: 1,
                    action: 2
                },

                statusTypes: {
                    spare: 'SPARE',
                    new: 'NEW',
                    fitted: 'FITTED',
                    inspection: 'INSPECTION',
                    awaitingDispatchRepair: 'AWAITING DISPATCH - REPAIR',
                    awaitingDispatchRetread: 'AWAITING DISPATCH - RETREAD',
                    awaitingDispatchOffSiteNdt: 'AWAITING DISPATCH - OFFSITE NDT INSPECTION',
                    awaitingDispatchTransfer: 'AWAITING DISPATCH - TRANSFER',
                    awaitingDispatchReturnToSupplier: 'AWAITING DISPATCH - RETURN TO SUPPLIER',
                    dispatchedTransfer: 'DISPATCHED - TRANSFER',
                    dispatchedRepair: 'DISPATCHED - REPAIR',
                    dispatchedRetread: 'DISPATCHED - RETREAD',
                    dispatchedReturnToSupplier: 'DISPATCHED - RETURN TO SUPPLIER',
                    dispatchedOffSiteNdt: 'DISPATCHED - OFF-SITE NDT',
                    outOfService: 'OUT OF SERVICE',
                    maintenance: 'MAINTENANCE',
                    production: 'PRODUCTION'
                },

                receiveConditionTypes: {
                    new: 'New',
                    pending: 'Pending',
                    transferred: 'Transferred'
                },

                equipmentTypes: {
                    tyre: 'tyre',
                    rim: 'rim',
                    chain: 'chain',
                    vehicle: 'vehicle'
                },

                levels: {
                    site: 'site',
                    client: 'client',
                    system: 'system'
                },

                axleLocations: {
                    front: 'Front',
                    rear: 'Rear'
                },

                endOfMonthStatuses: {
                    complete: 'complete',
                    incomplete: 'incomplete'
                },

                fieldSurveyReadingsDue: {
                    pressure: 'pressure',
                    tread: 'tread'
                },           

                forecastTypes: {
                    live: 'Live',
                    monthToMonth: 'Month-to-Month',
                    budget: 'Budget'
                },

                forecastMethods: {
                    horus: 'Hours',
                    percentWorn: 'PercentWorn'                    
                },

                budgetForecastStatusTypes: {
                    approved: 'Approved',
                    pending: 'Pending',
                    superseded: 'Superseded'
                },

                forecastCreateModes: {
                    add: 'add',
                    update: 'update',
                    copy: 'copy'
                },

                forecastOverrideTypes: {
                    vehicleSpecificationRimScrapLifeHours: 'vehicleSpecificationRimScrapLifeHours',
                    vehicleSpecificationFirstInspectionIntervalHours: 'vehicleSpecificationFirstInspectionIntervalHours',
                    vehicleSpecificationSubsequentInspectionIntervalHours: 'vehicleSpecificationSubsequentInspectionIntervalHours',
                    vehicleSpecificationRimScrapLifeDays: 'vehicleSpecificationRimScrapLifeDays',
                    vehicleSpecificationFirstInspectionIntervalDays: 'vehicleSpecificationFirstInspectionIntervalDays',
                    vehicleSpecificationSubsequentInspectionIntervalDays: 'vehicleSpecificationSubsequentInspectionIntervalDays',

                    rimScrapLifeHours: 'rimScrapLifeHours',                    
                    firstInspectionIntervalHours: 'firstInspectionIntervalHours',
                    subsequentInspectionIntervalHours: 'subsequentInspectionIntervalHours',                    
                    rimScrapLifeDays: 'rimScrapLifeDays',                    
                    firstInspectionIntervalDays: 'firstInspectionIntervalDays',
                    subsequentInspectionIntervalDays: 'subsequentInspectionIntervalDays',
                    averageInspectionCostPerComponent: 'averageInspectionCostPerComponent',

                    vehicleSpecificationTyreScrapHours: 'vehicleSpecificationTyreScrapHours',
                    vehicleSpecificationTyreScrapPercentWorn: 'vehicleSpecificationTyreScrapPercentWorn',
                    vehicleSpecificationTyreNewRotationHours: 'vehicleSpecificationTyreNewRotationHours',
                    vehicleSpecificationTyreNewRotationPercentWorn: 'vehicleSpecificationTyreNewRotationPercentWorn',

                    tyreScrapHours: 'tyreScrapHours',
                    tyreScrapPercentWorn: 'tyreScrapPercentWorn',
                    tyreNewRotationHours: 'tyreNewRotationHours',
                    tyreNewRotationPercentWorn: 'tyreNewRotationPercentWorn',
                    tyreWearRate: 'tyreWearRate',                    

                    monthlyUsageHours: 'monthlyUsageHours',
                    vehicleHoursAtStartOfForecast: 'vehicleHoursAtStartOfForecast',

                    rimMinimumBackupStock: 'rimMinimumBackupStock',
                    tyreMinimumBackupStock: 'tyreMinimumBackupStock'
                }
            };

            this.$get = function () {
                return enums;
            };
        }]);
