/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var kendo_angular_popup_1 = require("@progress/kendo-angular-popup");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var slider_module_1 = require("./slider.module");
var numerictextbox_module_1 = require("./numerictextbox.module");
var colorpicker_component_1 = require("./colorpicker/colorpicker.component");
var color_palette_component_1 = require("./colorpicker/color-palette.component");
var color_gradient_component_1 = require("./colorpicker/color-gradient.component");
var color_input_component_1 = require("./colorpicker/color-input.component");
var flatcolorpicker_header_component_1 = require("./colorpicker/flatcolorpicker-header.component");
var flatcolorpicker_actions_component_1 = require("./colorpicker/flatcolorpicker-actions.component");
var flatcolorpicker_component_1 = require("./colorpicker/flatcolorpicker.component");
var custom_messages_component_1 = require("./colorpicker/localization/custom-messages.component");
var localized_colorpicker_messages_directive_1 = require("./colorpicker/localization/localized-colorpicker-messages.directive");
var focus_on_dom_ready_directive_1 = require("./colorpicker/focus-on-dom-ready.directive");
var contrast_validation_component_1 = require("./colorpicker/contrast-validation.component");
var contrast_component_1 = require("./colorpicker/contrast.component");
var color_contrast_svg_component_1 = require("./colorpicker/color-contrast-svg.component");
var PUBLIC_DIRECTIVES = [
    colorpicker_component_1.ColorPickerComponent,
    color_palette_component_1.ColorPaletteComponent,
    color_gradient_component_1.ColorGradientComponent,
    flatcolorpicker_component_1.FlatColorPickerComponent,
    localized_colorpicker_messages_directive_1.LocalizedColorPickerMessagesDirective,
    custom_messages_component_1.ColorPickerCustomMessagesComponent
];
var INTERNAL_DIRECTIVES = [
    color_input_component_1.ColorInputComponent,
    focus_on_dom_ready_directive_1.FocusOnDomReadyDirective,
    contrast_component_1.ContrastComponent,
    contrast_validation_component_1.ContrastValidationComponent,
    flatcolorpicker_header_component_1.FlatColorPickerHeaderComponent,
    flatcolorpicker_actions_component_1.FlatColorPickerActionButtonsComponent,
    color_contrast_svg_component_1.ColorContrastSvgComponent
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the ColorPicker.
 */
var ColorPickerModule = /** @class */ (function () {
    function ColorPickerModule() {
    }
    ColorPickerModule = tslib_1.__decorate([
        core_1.NgModule({
            declarations: [
                PUBLIC_DIRECTIVES,
                INTERNAL_DIRECTIVES
            ],
            exports: [PUBLIC_DIRECTIVES],
            imports: [
                slider_module_1.SliderModule,
                numerictextbox_module_1.NumericTextBoxModule,
                common_1.CommonModule,
                kendo_angular_popup_1.PopupModule,
                kendo_angular_common_1.DraggableModule
            ]
        })
    ], ColorPickerModule);
    return ColorPickerModule;
}());
exports.ColorPickerModule = ColorPickerModule;
