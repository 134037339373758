//import angular from 'angular';
import * as _ from 'underscore';



    angular.module("app.site").controller("vehicleRotationStrategiesCtrl", vehicleRotationStrategiesCtrl);

    // @ts-ignore
    vehicleRotationStrategiesCtrl.$inject = ['amtConstants', '$scope', '$state', 'amtCommandQuerySvc', '$rootScope',
        'amtXlatSvc', 'forecastingSvc', 'notifySvc', 'errorReporter', 'ocSecuritySvc', '$window', 'confirmSvc', 'helperSvc', '$timeout'];

    // ReSharper disable once InconsistentNaming
    function vehicleRotationStrategiesCtrl(amtConstants, $scope, $state, amtCommandQuerySvc,
        $rootScope, xlatSvc, forecastingSvc, notifySvc, errorReporter, ocSecuritySvc, $window, confirmSvc, helperSvc, $timeout) {

        var vm = this;

        var minimumPositionColumnWidth = 330;

        vm.serialNumberColumnWidth = 200;

        vm.apiUrls = amtConstants.url;
        vm.gridControl = {};

        vm.processing = true;

        vm.positionStrategies = {};

        vm.forecastId = $rootScope.$stateParams.forecastId;
        vm.vehicleSpecificationId = $rootScope.$stateParams.vehicleSpecificationId;
        vm.vehicleSpecification = $rootScope.$stateParams.vehicleSpecification;

        this.$onInit = function () {
            if (!(vm.forecastId > 0)) {
                $state.go('app.site.forecastlist');
            }

            vm.readonly = !ocSecuritySvc.isAuthorised('Security.Site.Forecasting', 'readWrite');

            vm.getRotationStrategies().then(function () {

                return vm.getVehicles();

            }).finally(function () {
                vm.processing = false;
            });
        };

        vm.getRotationStrategies = function () {

            return amtCommandQuerySvc.get(vm.apiUrls.getRotationStrategies, {}).then(function (response) {

                if (!response || !response.data) {
                    return;
                }
                
                vm.rotationStrategies = response.data.result;

            }).catch(function (error) {
                errorReporter.logError(error);
            });
        };

        vm.getVehicles = function () {

            let criteria = {
                forecastId: vm.forecastId,
                vehicleSpecificationId: vm.vehicleSpecificationId
            };

            return amtCommandQuerySvc.post(vm.apiUrls.getVehicleRotationStrategies, criteria).then(function (response) {

                vm.vehicles = response.vehicles;
                vm.positions = response.specificationPositions;

                let rotationStrategyGridDiv = document.getElementById('rotationStrategyGrid');

                if (rotationStrategyGridDiv) {

                    let remainingWidth = rotationStrategyGridDiv.offsetWidth - vm.serialNumberColumnWidth - 23;

                    let adjustedPositionColumnWidth = remainingWidth / vm.positions.length;

                    if (adjustedPositionColumnWidth > minimumPositionColumnWidth) {
                        vm.positionColumnWidth = adjustedPositionColumnWidth;
                    } else {
                        vm.positionColumnWidth = minimumPositionColumnWidth;
                    }

                } else {
                    vm.positionColumnWidth = minimumPositionColumnWidth;
                }

                let defaultDescription = xlatSvc.xlat('common.default');

                // get the default strategy for each position and add them as options
                vm.positions.forEach(p => {

                    vm.positionStrategies[p.defaultVehicleTyreStrategyId] = angular.copy(vm.rotationStrategies);

                    let positionDefault = _.find(vm.rotationStrategies, s => +s.key === p.rotationStrategyId);

                    vm.positionStrategies[p.defaultVehicleTyreStrategyId].unshift({
                        key: 0,
                        name: 'default',
                        description: String.format('{0} ({1})', defaultDescription, positionDefault.description)
                    });
                });

                // for each position on each vehicle map it to any existing rotation strategy override, 
                // otherwise map it to the default for the position
                vm.vehicles.forEach(v =>
                    v.positions.forEach(p => {
                        if (p.rotationStrategyId) {
                            p.rotationStrategy = _.find(vm.rotationStrategies, r => +r.key === p.rotationStrategyId);
                        } else {
                            p.rotationStrategy = vm.positionStrategies[p.defaultVehicleTyreStrategyId][0];
                        }

                        p.positionStrategies = vm.positionStrategies[p.defaultVehicleTyreStrategyId];
                    })
                );

                if (vm.gridControl && vm.gridControl.recompile) {
                    vm.gridControl.recompile();
                }

            }).catch(function (error) {
                errorReporter.logError(error);
            });
        };

        vm.goToParentRoute = function () {
            confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                vm.form.$dirty = false;
                $rootScope.goBack();
            });
        };

        vm.save = function () {

            let criteria = {
                forecastId: vm.forecastId,
                vehicleSpecificationId: vm.vehicleSpecificationId,
                vehicles: []
            };

            vm.vehicles.forEach(v => {

                let vehicle = {
                    forecastVehicleId: v.forecastVehicleId,
                    positions: []
                };

                v.positions.forEach(p => {

                    let position = {
                        defaultVehicleTyreStrategyId: p.defaultVehicleTyreStrategyId,
                        rotationStrategyId: helperSvc.getKey(p.rotationStrategy)
                    };

                    // clear out the rotation strategy id if it set to the default
                    if (position.rotationStrategyId == 0) position.rotationStrategyId = null;

                    vehicle.positions.push(position);
                });

                criteria.vehicles.push(vehicle);
            });

            vm.processing = true;

            return amtCommandQuerySvc.post(vm.apiUrls.updateVehicleRotationStrategies, criteria).then(function (response) {

                vm.form.$dirty = false;

                notifySvc.success(xlatSvc.xlat("vehicleTyreStrategy.updateVehicleRotationStrategiesSuccess"));

            }).catch(function (error) {
                errorReporter.logError(error);
            }).finally(function () {
                vm.processing = false;
            });
        };

        vm.restoreDefaultValues = function () {

            vm.processing = true;

            vm.vehicles.forEach(v =>
                v.positions.forEach(p => {
                    p.rotationStrategy = vm.positionStrategies[p.defaultVehicleTyreStrategyId][0];
                })
            );

            vm.form.$setDirty();

            $timeout(function () {
                if (vm.gridControl && vm.gridControl.refresh) {
                    vm.gridControl.refresh();
                }

                vm.processing = false;
            });
        };

        vm.resize = function (height, offsetTop) {

            if (!vm.gridHeight) {
                vm.gridTop = height + offsetTop;
            }

            let newHeight = $window.innerHeight - vm.gridTop - 10;

            if (vm.gridHeight != newHeight) {

                vm.gridHeight = newHeight;

                if (vm.gridControl && vm.gridControl.resize) {
                    vm.gridControl.resize();
                }
            }
        };

        $scope.setRotationStrategy = function (option, param) {

            let vehicle = _.find(vm.vehicles, v => v.forecastVehicleId === param.vehicle.forecastVehicleId);

            if (vehicle && param.positionIndex) {
                vehicle.positions[+param.positionIndex].rotationStrategy = option;
            }
        };
    }
