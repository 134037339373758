import { Injectable } from '@angular/core';
import { LegendItemClickEvent } from '@progress/kendo-angular-charts';
import { ScrapLifeTrendOverviewResponse, ScrapLifeTrendWidget, WidgetScrapLifeTrendChartVisibleControl } from '../Interface/Widget.interface';
import { ScrapLifeTrendUi } from '../Interface/WidgetConstant.interface';
import { WidgetConstant } from '../widgetHelper/WidgetConstant';

@Injectable({
    providedIn: 'root'
})

export class ScrapLifeTrendWidgetHelper {

    constructor(private constant: WidgetConstant) {
    }

    public setErrorResult(widgetId: string): ScrapLifeTrendWidget {
        return { siteDashboardWidgetId: widgetId, apiResult: null, isAuth: false, receivedResult: true}
    }

    public setDefaultScrapLifeTrendWidgetResult(widgetId: string, response: ScrapLifeTrendOverviewResponse): ScrapLifeTrendWidget {
        return {
            siteDashboardWidgetId: widgetId,
            apiResult: response,
            isAuth: response.isAuth,
            receivedResult: true,
        }
    }

    public handleLegendClickEvent(event: LegendItemClickEvent, visible: WidgetScrapLifeTrendChartVisibleControl, ui: ScrapLifeTrendUi): WidgetScrapLifeTrendChartVisibleControl{
        //Hours
        visible.targetHour = event.text === ui.targetHours ? !visible.targetHour : visible.targetHour;
        visible.previousYearAvgHours = event.text === ui.averageLife ? !visible.previousYearAvgHours : visible.previousYearAvgHours;
        //Column + Hours
        visible.new = event.text === ui.new ? !visible.new : visible.new;
        visible.retread = event.text === ui.retread ? !visible.retread : visible.retread;
        visible.secondHand = event.text === ui.secondHand ? !visible.secondHand : visible.secondHand;
        visible.transfer = event.text === ui.transfer ? !visible.transfer : visible.transfer;
        visible.majorRepair = event.text === ui.major ? !visible.majorRepair : visible.majorRepair;
        visible.cosmeticRepair = event.text === ui.cosmetic ? !visible.cosmeticRepair : visible.cosmeticRepair;

        return visible;
    }
}