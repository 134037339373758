/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Specifies the text and styles for the title of the Card.
 */
var CardTitleDirective = /** @class */ (function () {
    function CardTitleDirective() {
        this.hostClass = true;
    }
    tslib_1.__decorate([
        core_1.HostBinding('class.k-card-title'),
        tslib_1.__metadata("design:type", Boolean)
    ], CardTitleDirective.prototype, "hostClass", void 0);
    CardTitleDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoCardTitle]'
        })
    ], CardTitleDirective);
    return CardTitleDirective;
}());
exports.CardTitleDirective = CardTitleDirective;
