//import angular from 'angular';

    angular.module('app.directives').directive('amtGridActionButton', [function () {
        return {
            restrict: 'A',
            require: "^dropdown",
            link: function (scope, element) {
                element.click(function (e) {
                    element.parent().find('ul').css({
                        top: e.clientY,
                        left: e.clientX,
                        position: 'fixed'
                    });
                });
            }
        };
    }]);
