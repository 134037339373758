/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var drag_and_drop_service_1 = require("./drag-and-drop.service");
var operators_1 = require("rxjs/operators");
/**
 * @hidden
 */
var DropTargetDirective = /** @class */ (function () {
    function DropTargetDirective(element, service) {
        this.element = element;
        this.service = service;
        this.context = {};
        this.enter = new core_1.EventEmitter();
        this.leave = new core_1.EventEmitter();
        this.drop = new core_1.EventEmitter();
        this.subscriptions = new rxjs_1.Subscription();
    }
    DropTargetDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.service.add(this);
        var changes = this.service.changes.pipe(operators_1.filter(function (_a) {
            var target = _a.target;
            return target === _this;
        }));
        this.subscriptions.add(changes.pipe(operators_1.filter(function (_a) {
            var type = _a.type;
            return type === 'leave';
        }))
            .subscribe(function (e) {
            _this.leave.next(_this.eventArgs(e));
        }));
        this.subscriptions.add(changes.pipe(operators_1.filter(function (_a) {
            var type = _a.type;
            return type === 'enter';
        }))
            .subscribe(function (e) {
            _this.enter.next(_this.eventArgs(e));
        }));
        this.subscriptions.add(changes.pipe(operators_1.filter(function (_a) {
            var type = _a.type;
            return type === 'drop';
        }))
            .subscribe(function (e) {
            _this.drop.next(_this.eventArgs(e));
        }));
    };
    DropTargetDirective.prototype.ngOnDestroy = function () {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    };
    DropTargetDirective.prototype.eventArgs = function (e) {
        return {
            target: this,
            mouseEvent: e.mouseEvent,
            draggable: e.draggable
        };
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], DropTargetDirective.prototype, "context", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], DropTargetDirective.prototype, "enter", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], DropTargetDirective.prototype, "leave", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], DropTargetDirective.prototype, "drop", void 0);
    DropTargetDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoDropTarget]'
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.ElementRef,
            drag_and_drop_service_1.DragAndDropService])
    ], DropTargetDirective);
    return DropTargetDirective;
}());
exports.DropTargetDirective = DropTargetDirective;
