/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_treeview_1 = require("@progress/kendo-angular-treeview");
/**
 * A directive which encapsulates the retrieval of the child nodes when hierarchical data is provided.
 */
var DropDownTreeHierarchyBindingDirective = /** @class */ (function (_super) {
    tslib_1.__extends(DropDownTreeHierarchyBindingDirective, _super);
    function DropDownTreeHierarchyBindingDirective(dropDownTree) {
        var _this = _super.call(this, dropDownTree) || this;
        _this.dropDownTree = dropDownTree;
        return _this;
    }
    Object.defineProperty(DropDownTreeHierarchyBindingDirective.prototype, "data", {
        /**
         * The nodes which will be displayed by the DropDownTree.
         */
        set: function (nodes) {
            this.dropDownTree.nodes = nodes;
            this.nodes = nodes;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        core_1.Input('kendoDropDownTreeHierarchyBinding'),
        tslib_1.__metadata("design:type", Array),
        tslib_1.__metadata("design:paramtypes", [Array])
    ], DropDownTreeHierarchyBindingDirective.prototype, "data", null);
    DropDownTreeHierarchyBindingDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoDropDownTreeHierarchyBinding]'
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_treeview_1.DataBoundComponent])
    ], DropDownTreeHierarchyBindingDirective);
    return DropDownTreeHierarchyBindingDirective;
}(kendo_angular_treeview_1.HierarchyBindingDirective));
exports.DropDownTreeHierarchyBindingDirective = DropDownTreeHierarchyBindingDirective;
