//import angular from 'angular';


    angular.module('app').filter('notAvailable', notAvailableFilter);

    // @ts-ignore
    notAvailableFilter.$inject = ['amtXlatSvc'];
    function notAvailableFilter(xlatSvc) {
        return function (input) {
            return input ? input : xlatSvc.xlat('common.notAvailable');
        };
    }
