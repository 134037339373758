/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var column_common_1 = require("../columns/column-common");
var column_resizing_service_1 = require("./column-resizing.service");
var operators_1 = require("rxjs/operators");
/**
 * @hidden
 */
var columnsToResize = function (_a) {
    var columns = _a.columns;
    return Math.max(1, column_common_1.resizableColumns(columns).length);
};
var ɵ0 = columnsToResize;
exports.ɵ0 = ɵ0;
/**
 * @hidden
 */
var row = function (selector) { return function (element) { return element.querySelector(selector); }; };
var ɵ1 = row;
exports.ɵ1 = ɵ1;
/**
 * @hidden
 */
var headerRow = function (index) { return function (element) { return element.querySelectorAll('thead>tr')[index]; }; };
var ɵ2 = headerRow;
exports.ɵ2 = ɵ2;
/**
 * @hidden
 */
var cell = function (index, selector) {
    if (selector === void 0) { selector = 'td'; }
    return function (element) {
        return element.querySelectorAll(selector + ":not(.k-group-cell):not(.k-hierarchy-cell)")[index];
    };
};
var ɵ3 = cell;
exports.ɵ3 = ɵ3;
/**
 * @hidden
 */
var offsetWidth = function (element) { return element.offsetWidth; };
var ɵ4 = offsetWidth;
exports.ɵ4 = ɵ4;
/**
 * @hidden
 */
var pipe = function () {
    var fns = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        fns[_i] = arguments[_i];
    }
    return function (data) { return fns.reduce(function (state, fn) { return state ? fn(state) : 0; }, data); };
};
var ɵ5 = pipe;
exports.ɵ5 = ɵ5;
/**
 * @hidden
 */
var TableDirective = /** @class */ (function () {
    function TableDirective(element, renderer, service, zone, cdr) {
        this.element = element;
        this.renderer = renderer;
        this.service = service;
        this.zone = zone;
        this.cdr = cdr;
        this.locked = false;
        this.firstResize = false;
    }
    Object.defineProperty(TableDirective.prototype, "minWidth", {
        get: function () {
            return this.firstResize ? 0 : null;
        },
        enumerable: true,
        configurable: true
    });
    TableDirective.prototype.ngOnInit = function () {
        var _this = this;
        var obs = this.service
            .changes.pipe(operators_1.filter(function (e) { return _this.locked === e.locked; }));
        this.subscription = obs.pipe(operators_1.filter(function (e) { return e.type === 'start'; }), operators_1.tap(this.initState.bind(this)), operators_1.map(columnsToResize), operators_1.switchMap(function (take) {
            return obs.pipe(operators_1.filter(function (e) { return e.type === 'resizeTable'; }), operators_1.map(function (e) { return e.delta; }), operators_1.bufferCount(take));
        })).subscribe(this.resize.bind(this));
        this.autoFitSubscription = this.service
            .registerTable({
            autoFit: this.autoFitObservable.bind(this),
            locked: this.locked
        });
    };
    TableDirective.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.autoFitSubscription) {
            this.autoFitSubscription();
            this.autoFitSubscription = null;
        }
    };
    TableDirective.prototype.initState = function () {
        this.firstResize = true;
        if (!this.virtualColumns || this.locked) {
            this.originalWidth = offsetWidth(this.element.nativeElement);
        }
    };
    TableDirective.prototype.resize = function (deltas) {
        if (!this.virtualColumns || this.locked) {
            var delta = deltas.reduce(function (sum, item) { return sum + item; }, 0);
            var width = this.originalWidth + delta;
            this.renderer.setStyle(this.element.nativeElement, 'width', width + 'px');
        }
        this.cdr.detectChanges();
    };
    TableDirective.prototype.autoFitObservable = function (columnInfo) {
        var _this = this;
        return rxjs_1.Observable.create(function (observer) {
            _this.zone.runOutsideAngular(function () {
                _this.renderer.addClass(_this.element.nativeElement, 'k-autofitting');
                _this.cdr.detectChanges();
                var widths = columnInfo.map(_this.measureColumn.bind(_this));
                _this.renderer.removeClass(_this.element.nativeElement, 'k-autofitting');
                observer.next(widths);
            });
        });
    };
    TableDirective.prototype.measureColumn = function (info) {
        var dom = this.element.nativeElement;
        var header = pipe(headerRow(info.level), cell(info.headerIndex, 'th'), offsetWidth)(dom);
        var data = 0;
        if (!info.isParentSpan || (info.isParentSpan && info.isLastInSpan)) {
            data = pipe(row('tbody>tr:not(.k-grouping-row):not(.k-grid-norecords)'), cell(info.index), offsetWidth)(dom);
        }
        var footer = pipe(row('tfoot>tr'), cell(info.index), offsetWidth)(dom);
        return Math.max(header, data, footer);
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], TableDirective.prototype, "locked", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], TableDirective.prototype, "virtualColumns", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('style.min-width'),
        tslib_1.__metadata("design:type", Number),
        tslib_1.__metadata("design:paramtypes", [])
    ], TableDirective.prototype, "minWidth", null);
    TableDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: 'table' // tslint:disable-line:directive-selector
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.ElementRef,
            core_1.Renderer2,
            column_resizing_service_1.ColumnResizingService,
            core_1.NgZone,
            core_1.ChangeDetectorRef])
    ], TableDirective);
    return TableDirective;
}());
exports.TableDirective = TableDirective;
