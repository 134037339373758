/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Represents the pane component of the Splitter.
 */
var SplitterPaneComponent = /** @class */ (function () {
    function SplitterPaneComponent(element, renderer, cdr) {
        this.element = element;
        this.renderer = renderer;
        this.cdr = cdr;
        /**
         * Specifies if the user is allowed to resize the pane and provide space for other panes.
         */
        this.resizable = true;
        /**
         * Specifies if the user is allowed to hide the pane and provide space for other panes.
         */
        this.collapsible = false;
        /**
         * Specifies if overflowing content is scrollable or hidden.
         */
        this.scrollable = true;
        /**
         * Specifies if the pane is initially collapsed.
         */
        this.collapsed = false;
        /**
         * @hidden
         */
        this.orientation = 'horizontal';
        /**
         * @hidden
         */
        this.overlayContent = false;
        /**
         * Fires each time the user resizes the Splitter pane.
         * The event data contains the new pane size.
         * Allows a two-way binding of the pane `size` property.
         */
        this.sizeChange = new core_1.EventEmitter();
        /**
         * Fires each time the `collapsed` property changes.
         * The event data contains the new property state.
         * Allows a two-way binding of the `collapsed` pane property.
         */
        this.collapsedChange = new core_1.EventEmitter();
        this.hostClass = true;
        /**
         * @hidden
         */
        this.forceExpand = false;
        /**
         * @hidden
         */
        this.isResized = false;
    }
    Object.defineProperty(SplitterPaneComponent.prototype, "order", {
        get: function () {
            return this._order;
        },
        /**
         * @hidden
         */
        set: function (paneOrder) {
            this._order = paneOrder;
            this.setOrderStyles();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SplitterPaneComponent.prototype, "size", {
        get: function () {
            return this._size;
        },
        /**
         * Sets the initial size of the pane.
         * Has to be between the `min` and `max` properties.
         */
        set: function (newSize) {
            this._size = newSize;
            var element = this.element.nativeElement;
            this.renderer.setStyle(element, '-ms-flex-preferred-size', newSize);
            this.renderer.setStyle(element, 'flex-basis', newSize);
            if (this.staticPaneClass) {
                this.renderer.addClass(element, 'k-pane-static');
            }
            else {
                this.renderer.removeClass(element, 'k-pane-static');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SplitterPaneComponent.prototype, "containsSplitter", {
        /**
         * @hidden
         */
        set: function (value) {
            var element = this.element.nativeElement;
            if (value) {
                this.renderer.addClass(element, 'k-pane-flex');
            }
            else {
                this.renderer.removeClass(element, 'k-pane-flex');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SplitterPaneComponent.prototype, "isHidden", {
        get: function () {
            return this.collapsed;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SplitterPaneComponent.prototype, "staticPaneClass", {
        get: function () {
            if (this.forceExpand) {
                return false;
            }
            return !this.resizable && !this.collapsible || this.fixedSize;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SplitterPaneComponent.prototype, "scrollablePaneClass", {
        get: function () {
            return this.scrollable;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SplitterPaneComponent.prototype, "fixedSize", {
        get: function () {
            return this.size && this.size.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    SplitterPaneComponent.prototype.ngAfterViewChecked = function () {
        var element = this.element.nativeElement;
        if (this.isHidden) {
            this.renderer.addClass(element, 'k-state-hidden');
            this.renderer.addClass(element, 'hidden');
        }
        else {
            this.renderer.removeClass(element, 'k-state-hidden');
            this.renderer.removeClass(element, 'hidden');
        }
    };
    Object.defineProperty(SplitterPaneComponent.prototype, "computedSize", {
        /**
         * @hidden
         */
        get: function () {
            if (this.orientation === 'vertical') {
                return this.element.nativeElement.offsetHeight;
            }
            else {
                return this.element.nativeElement.offsetWidth;
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @hidden
     */
    SplitterPaneComponent.prototype.toggleOverlay = function (show) {
        this.overlayContent = show;
        this.cdr.detectChanges();
    };
    /**
     * @hidden
     */
    SplitterPaneComponent.prototype.detectChanges = function () {
        this.cdr.detectChanges();
    };
    SplitterPaneComponent.prototype.setOrderStyles = function () {
        var element = this.element.nativeElement;
        this.renderer.setStyle(element, '-ms-flex-order', this.order);
        this.renderer.setStyle(element, 'order', this.order);
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number),
        tslib_1.__metadata("design:paramtypes", [Number])
    ], SplitterPaneComponent.prototype, "order", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], SplitterPaneComponent.prototype, "size", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], SplitterPaneComponent.prototype, "min", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], SplitterPaneComponent.prototype, "max", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], SplitterPaneComponent.prototype, "resizable", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], SplitterPaneComponent.prototype, "collapsible", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], SplitterPaneComponent.prototype, "scrollable", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], SplitterPaneComponent.prototype, "collapsed", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], SplitterPaneComponent.prototype, "orientation", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [Boolean])
    ], SplitterPaneComponent.prototype, "containsSplitter", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], SplitterPaneComponent.prototype, "overlayContent", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], SplitterPaneComponent.prototype, "sizeChange", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], SplitterPaneComponent.prototype, "collapsedChange", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-pane'),
        tslib_1.__metadata("design:type", Boolean)
    ], SplitterPaneComponent.prototype, "hostClass", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-pane-static'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], SplitterPaneComponent.prototype, "staticPaneClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-scrollable'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], SplitterPaneComponent.prototype, "scrollablePaneClass", null);
    SplitterPaneComponent = tslib_1.__decorate([
        core_1.Component({
            exportAs: 'kendoSplitterPane',
            selector: 'kendo-splitter-pane',
            template: "\n        <ng-container *ngIf=\"!collapsed\"><ng-content></ng-content></ng-container>\n        <div *ngIf=\"overlayContent\" class=\"k-splitter-overlay k-overlay\"></div>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.ElementRef,
            core_1.Renderer2,
            core_1.ChangeDetectorRef])
    ], SplitterPaneComponent);
    return SplitterPaneComponent;
}());
exports.SplitterPaneComponent = SplitterPaneComponent;
