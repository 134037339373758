//import angular from 'angular';
import OcDateSvc from '../../../services/ocDateSvc';
import tmpl from './details.html';



interface IPerformanceBatchDetails {
    id?: guid;
    batchName: string;
    dateFrom: Date;
    dateTo?: Date;
    performanceWarranty: any;
    componentTypes: any[];
    componentTypeIds: guid[];
    batchType: any;
    lifeType: any;
    fleets?: any[];
    fleetIds?: guid[];
    specifications?: any[];
    specificationIds?: guid[];
    equipments?: any[];
    equipmentIds?: guid[];
    inclusionOptions?: any[];
    errors?: any[];
}

interface IPerformanceBatchDetailsTabs {
    details: IPerformanceBatchDetailsTab;
    performanceWarranty: IPerformanceBatchDetailsTab;
}

interface IPerformanceBatchDetailsTab {
    canSave: boolean;
    saveResource: string;
    save?: Function;
    refresh?: Function;
}

class PerformanceBatchDetailsCtrl implements ng.IController, IWindowController {

    form: ng.IFormController;
    wnd: IWindowObj;

    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: () => void };

    initParams: any;
    status: ScreenStatus = ScreenStatus.loading;

    defaultInclusions: any[] = [];
    inclusionOptions: any[] = [];
    lifeInclusionOptions: any[] = [];

    batchId: guid;
    siteId: guid;
    isEdit: boolean;
    readonly: boolean;

    batchDetails: IPerformanceBatchDetails = {
        batchName: null,
        dateFrom: null,
        performanceWarranty: null,
        componentTypeIds: [],
        componentTypes: [],
        batchType: null,
        lifeType: null
    };

    currentTab: IPerformanceBatchDetailsTab;

    tabs: IPerformanceBatchDetailsTabs = {
        details: { canSave: true, saveResource: 'common.save_label', save: () => this.saveBatchDetails() },
        performanceWarranty: { canSave: true, saveResource: 'common.save_label', save: () => this.saveBatchDetails() }
    };

    static $inject = ['$scope', 'amtCommandQuerySvc', 'confirmSvc', 'amtXlatSvc', 'adminUrls', 'equipmentUrls', 'componentUrls',
        'WindowFactory', '$timeout', 'notifySvc', 'ocSecuritySvc', 'errorReporter', 'helperSvc', 'ocDateSvc'];

    constructor(private $scope: ng.IScope, private amtCommandQuerySvc: IAmtCommandQuerySvc, private confirmSvc: IConfirmSvc,
        private amtXlatSvc: IAmtXlatSvc, private adminUrls: IAdminUrls, private equipmentUrls: IEquipmentUrls, private componentUrls: IComponentUrls,
        private WindowFactory: IWindowFactory, private $timeout: ng.ITimeoutService, private notifySvc: INotifySvc,
        private ocSecuritySvc: IOcSecuritySvc, private errorReporter: IErrorReporter, private helperSvc: IHelperSvc, private ocDateSvc: OcDateSvc) {

        this.$scope.$watch(() => this.form.$dirty, () => {
            this.wnd.isDirty = this.form.$dirty;
        });

        this.$scope.$watch(() => this.batchDetails.lifeType, () => {
            if (this.batchDetails.lifeType) {
                for (let inclusionOption of this.lifeInclusionOptions || []) {
                    if (inclusionOption.lifeTypeId === this.batchDetails.lifeType.key)
                        this.defaultInclusions = inclusionOption.defaults;
                }
            }
        });

        this.$scope.$watchGroup([() => this.currentTab, () => this.readonly], () => {
            this.buttonStates.saveButton.visible = this.currentTab.canSave && !this.readonly;
        });

        this.$scope.$watchGroup([() => this.form.$invalid, () => this.form.$pristine, () => this.wnd.processing], () => {
            this.buttonStates.saveButton.disabled = this.form.$invalid || this.form.$pristine || this.wnd.processing;
        });
    }

    async $onInit() {

        this.wnd.processing = true;
        this.status = ScreenStatus.loading;

        this.wnd.onClose = () => this.onClose();

        try {
            this.currentTab = this.tabs.details;

            if (this.initParams) {
                this.batchId = this.initParams.batchId;
                this.siteId = this.initParams.siteId;
                this.isEdit = this.initParams.isEdit;
            }

            // security
            this.readonly = !(this.isEdit ?
                this.ocSecuritySvc.isAuthorised('Security.Settings.PerformanceBatches', AccessTypes.readWrite) :
                this.ocSecuritySvc.isAuthorised('Security.Settings.PerformanceBatches.Add', AccessTypes.readWrite)
            );

            this.WindowFactory.addButton(this, 'common.save_label', 'saveButton', () => this.onSave(), true, this.currentTab.canSave, true);
            this.WindowFactory.addButton(this, 'common.cancel_label', 'cancelButton', () => this.onClose());

            await this.initData();

        } finally {
            this.wnd.processing = false;
        }
    }

    async initData() {

        this.status = ScreenStatus.loading;
        this.wnd.processing = true;

        try {
            await this.loadInclusionOptions();

            if (this.batchId) {
                await this.loadBatchDetails();
            } else {
                await this.loadDefaults();

                this.status = ScreenStatus.ready;
            }
        } catch (error) {
            this.batchDetails.errors = [error];
            this.status = ScreenStatus.error;
            this.errorReporter.logError(error, 'PeformanceBatchDetails-InitData');
        } finally {
            this.wnd.processing = false;
        }
    }

    async loadDefaults() {

        try {

            let criteria = {
                siteId: this.siteId
            };

            let response = await this.amtCommandQuerySvc.post(this.adminUrls.getPerformanceBatchDefaults, criteria);

            if (response) {

                this.lifeInclusionOptions = response.inclusionOptions;

                this.batchDetails.performanceWarranty = {
                    warrantyType: response.warrantyType,
                    performanceMeasure: response.performanceMeasure
                };

                this.batchDetails.batchType = response.batchType;
            }

        } catch (error) {
            this.batchDetails.errors = [error];
            this.status = ScreenStatus.error;
            this.errorReporter.logError(error, 'PerformanceBatchDetails-LoadDefaults')
        }
    }

    async loadInclusionOptions() {

        try {
            let response = await this.amtCommandQuerySvc.post(this.adminUrls.getPerformanceBatchInclusionOptions, { equipmentLifeTypeId: null });

            if (response)
                this.inclusionOptions = response.result;

        } catch (error) {
            this.batchDetails.errors = [error];
            this.status = ScreenStatus.error;
            this.errorReporter.logError(error, 'PerformanceBatchDetails-LoadInclusionOptions');
        }
    }

    async loadBatchDetails() {

        if (!this.batchId)
            return;

        try {

            let criteria = {
                id: this.batchId
            };

            let response = await this.amtCommandQuerySvc.post(this.adminUrls.getPerformanceBatchDetails, criteria);

            if (response) {

                this.batchDetails = response;
                this.defaultInclusions = response.inclusionOptions;

                this.status = ScreenStatus.ready;

                this.$timeout(() => this.form.$setPristine());
            }

        } catch (error) {
            this.status = ScreenStatus.error;
            this.batchDetails.errors = [error];
            this.errorReporter.logError(error, 'PerformanceBatchDetails-LoadBatchDetails');
        }
    }

    async saveBatchDetails() {

        this.wnd.processing = true;

        try {
            let saveCriteria = {
                batchId: this.batchDetails.id,
                isEdit: this.isEdit,
                siteId: this.siteId,
                batchName: this.batchDetails.batchName,
                fleetIds: this.batchDetails.fleetIds,
                dateFrom: this.ocDateSvc.removeLocalTimeZoneOffset(this.batchDetails.dateFrom),
                dateTo: this.ocDateSvc.removeLocalTimeZoneOffset(this.batchDetails.dateTo),
                equipmentTypeIds: this.batchDetails.componentTypeIds,
                equipmentLifeTypeId: this.helperSvc.getKey(this.batchDetails.lifeType),
                batchTypeId: this.helperSvc.getKey(this.batchDetails.batchType),
                inclusionOptions: this.batchDetails.inclusionOptions,
                performanceWarranty: this.batchDetails.performanceWarranty == null ? null :
                    {
                        warrantyTypeId: this.helperSvc.getKey(this.batchDetails.performanceWarranty.warrantyType),
                        minimumBatchSize: this.batchDetails.performanceWarranty.minimumBatchSize,
                        performanceValue: this.batchDetails.performanceWarranty.performanceValue,
                        performanceUnitOfMeasureId: this.helperSvc.getKey(this.batchDetails.performanceWarranty.performanceMeasure)
                    },
                equipments: this.batchDetails.equipmentIds,
                specifications: this.batchDetails.specificationIds
            };

            await (this.isEdit ? this.modifyBatchDetails(saveCriteria) : this.addBatchDetails(saveCriteria));

        } finally {
            this.wnd.processing = false;
        }
    }

    async addBatchDetails(model) {

        try {
            await this.amtCommandQuerySvc.post(this.adminUrls.addPerformanceBatch, model);

            this.notifySvc.success(this.amtXlatSvc.xlat('performanceBatches.messagePerformanceBatchCreateSuccess'));

            this.form.$setPristine();

            if (this.wnd.onDataChanged)
                this.wnd.onDataChanged();

            this.closeWindow();

        } catch (error) {
            this.errorReporter.logError(error, 'PerformanceBatchDetails-AddBatch');
        }
    }

    async modifyBatchDetails(model) {

        try {
            await this.amtCommandQuerySvc.post(this.adminUrls.modifyPerformanceBatch, model);

            this.notifySvc.success(this.amtXlatSvc.xlat('performanceBatches.messagePerformanceBatchUpdateSuccess'));
            this.form.$setPristine();

            if (this.wnd.onDataChanged)
                this.wnd.onDataChanged();

            this.closeWindow();

        } catch (error) {
            this.errorReporter.logError(error, 'PerformanceBatchDetails-ModifyBatch');
        }
    }

    selectTab(tab: IPerformanceBatchDetailsTab) {
        this.currentTab = tab;
        this.onRefresh();
    }

    closeWindow() {
        this.WindowFactory.closeWindow(this.wnd);
    }

    async onClose() {
        try {
            await this.confirmSvc.confirmSaveChange(this.form.$dirty);
        } catch {
            return; // they cancelled
        }

        this.closeWindow();
    }

    onSave() {
        if (this.currentTab.save)
            this.currentTab.save();
    }

    onRefresh() {
        if (this.currentTab.refresh)
            this.currentTab.refresh();
    }

    onLifeTypeChange(option) {
        if (!option || !option.hasInclusionOptions)
            this.batchDetails.inclusionOptions = [];
    }

    onBatchTypeChange(option) {
        if (!option) {
            this.batchDetails.equipmentIds = [];
            this.batchDetails.specificationIds = [];
            return;
        }

        if (!option.showSerialNumbers)
            this.batchDetails.equipments = [];

        if (!option.showSpecifications) {
            this.batchDetails.specifications = [];
            this.batchDetails.specificationIds = [];
        }
    }

    onWarrantyTypeChange(option) {
        if (option) {
            if (!option.requiresMinimumBatchSize)
                this.batchDetails.performanceWarranty.minimumBatchSize = null;

            if (!option.requiresPerformanceValue)
                this.batchDetails.performanceWarranty.performanceValue = null;

            if (!option.requiresPerformanceMeasure && !option.requiresPerformanceValue)
                this.batchDetails.performanceWarranty.performanceMeasure = null;
        }
    }
}

class PerformanceBatchDetailsComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = PerformanceBatchDetailsCtrl;
    public controllerAs = 'vm';
}

angular.module('app.admin').component('performanceBatchDetails', new PerformanceBatchDetailsComponent());