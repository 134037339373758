import { Injectable } from '@angular/core';
import { Dashboard, UserPosition, Reference } from '../Interface/SiteDashboard.interface';

@Injectable({
    providedIn: 'root'
})

export class InitWidgetPositionHelper {

    constructor() {
    }

    //Set the initial widget position list by tilesetting col and row value.
    setInitalWidgetPositionList(dashboardItemList: Dashboard[], tileSetting: any) {
        const positionList: UserPosition[] = [];
        let maxCol = tileSetting.col;
        let maxRow = tileSetting.row;

        dashboardItemList.forEach(siteDashboard => {
            let siteDashboardId = siteDashboard.siteDashboardId;
            let row = 0;
            let col = 1;
            let overlapLocation: Reference[] = [];

            siteDashboard.widget.forEach((item, i) => {
                let index = i;
                let siteDashboardWidgetId = item.siteDashboardWidgetId;
                let rowSpan = item.rowSpan;
                let colSpan = item.colSpan;

                //generate sequence of maxCol
                if (index % maxCol === 0) {
                    row += 1;
                    col = 1;
                }

                //find Avilable space
                let available = this.findAvailableLocation(positionList, siteDashboardId, overlapLocation, maxCol, maxRow);
                col = available.col;
                row = available.row;

                let reference = [{ col: col, row: row }];

                //check spanValue and mark as overlapping area
                if (rowSpan === 2 && colSpan === 2) {
                    let overlapRight = { row: row, col: col + 1 };
                    let overlapBelow = { row: row + 1, col: col };
                    let overlapAcute = { row: row + 1, col: col + 1 };
                    reference.push(overlapBelow, overlapRight, overlapAcute);
                    overlapLocation.push(overlapBelow, overlapRight, overlapAcute);
                } else if (rowSpan === 1 && colSpan === 2) {
                    let overlapRight = { row: row, col: col + 1 };
                    reference.push(overlapRight);
                    overlapLocation.push(overlapRight);
                } else if (rowSpan === 2 && colSpan === 1) {
                    let overlapBelow = { row: row + 1, col: col };
                    reference.push(overlapBelow);
                    overlapLocation.push(overlapBelow);
                }
      
                let position = { siteDashboardId: siteDashboardId, siteDashboardWidgetId: siteDashboardWidgetId, col: col, row: row, reference: reference, colSpan: colSpan, rowSpan: rowSpan};
                positionList.push(position);
                col += 1;
            });
        });

        return positionList;
    }

    findAvailableLocation(positionList: UserPosition[], siteDashboardId: string, overlapping: Reference[], maxCol: number, maxRow: number) {
        let layoutLocation = this.setInitialAvailableLocation(maxRow, maxCol);

        //delete overlapping location
        overlapping.forEach(overlap => {
            layoutLocation.forEach((available, index) => {
                if (available.row === overlap.row && available.col === overlap.col) {
                    layoutLocation.splice(index, 1);
                }
            })
        });

        //delete selected location
        positionList.forEach(selected => {
            if (selected.siteDashboardId === siteDashboardId) {
                layoutLocation.forEach((available, index) => {
                    if (available.row === selected.row && available.col === selected.col) {
                        layoutLocation.splice(index, 1);
                    }
                });
            }
        });
        return layoutLocation[0];
    }

    //create a location.
    setInitialAvailableLocation(maxRow: number, maxCol: number) {
        let availableLocation: Reference[] = [];

        for (let row = 0; row < maxRow; row++) {
            for (let col = 0; col < maxCol; col++) {
                availableLocation.push({ row: row + 1, col: col + 1 })
            }
        }
        return availableLocation;
    }

}


