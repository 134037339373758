/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var focus_service_1 = require("../focusable/focus.service");
/**
 * @hidden
 */
var DialListComponent = /** @class */ (function () {
    function DialListComponent(focusService, cdr) {
        var _this = this;
        this.focusService = focusService;
        this.cdr = cdr;
        this.hostClass = true;
        this.subscriptions = new rxjs_1.Subscription();
        this.subscriptions.add(this.focusService.onFocus.subscribe(function () { return _this.cdr.detectChanges(); }));
    }
    Object.defineProperty(DialListComponent.prototype, "bottomClass", {
        get: function () {
            return this.align.vertical === 'top' || this.align.vertical === 'middle';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DialListComponent.prototype, "topClass", {
        get: function () {
            return this.align.vertical === 'bottom';
        },
        enumerable: true,
        configurable: true
    });
    DialListComponent.prototype.isFocused = function (index) {
        return this.focusService.isFocused(index);
    };
    DialListComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    tslib_1.__decorate([
        core_1.HostBinding('class.k-fab-items'),
        tslib_1.__metadata("design:type", Boolean)
    ], DialListComponent.prototype, "hostClass", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-fab-items-bottom'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], DialListComponent.prototype, "bottomClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-fab-items-top'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], DialListComponent.prototype, "topClass", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Array)
    ], DialListComponent.prototype, "dialItems", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", core_1.TemplateRef)
    ], DialListComponent.prototype, "dialItemTemplate", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], DialListComponent.prototype, "align", void 0);
    DialListComponent = tslib_1.__decorate([
        core_1.Component({
            selector: '[kendoDialList]',
            template: "\n        <ng-container *ngFor='let item of dialItems; let idx = index'>\n            <li\n                kendoButtonFocusable\n                kendoDialItem\n                [item]=\"dialItems[idx]\"\n                [index]=\"idx\"\n                [dialItemTemplate]=\"dialItemTemplate\"\n                [isFocused]=\"isFocused(idx)\"\n                [ngClass]='item.cssClass'\n                [ngStyle]='item.cssStyle'\n                [align]=\"align\"\n            >\n            </li>\n        </ng-container>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [focus_service_1.FocusService, core_1.ChangeDetectorRef])
    ], DialListComponent);
    return DialListComponent;
}());
exports.DialListComponent = DialListComponent;
