//import angular from 'angular';
import * as _ from 'underscore';
import Dexie from 'dexie';
import FileManagement from '../services/fileManagement';
import DbClass, { IDbSystem } from '../modules/common/local-db';
import BrowserSvc from '../services/browserSvc';
import OcDateSvc, { DateUnits } from '../services/ocDateSvc';
import OcConfigSvc from '../services/ocConfigSvc';


interface IMobileSyncPhase {
    code: MobileSyncPhase;
    startPct: number;
    endPct: number;
}

interface IMobileModuleReference {
    active: boolean;
    permissions: string[];
    permissionLevel: AccessTypes;
    matchAllPermissions: boolean;
    service: string;
}

interface IMobileModule {
    active: boolean;
    name: string;
    route: string;
    percent_complete: number;
    readOnly: boolean;
    lastRecordedDate?: Date;
    description: string;
    min_date_recorded?: Date;
    updateDetails: Function;
    progress?: number;
    count?: number;
}

interface IMobileReferenceItem {
    name: string;
    pageSize: number;
    prompt?: Function;
    mapping?: Function;
    tableCleared?: boolean;
    table?: string;
    count?: number;
    received?: number;
}

class DataSyncCtrl implements ng.IController {

    initialised = false;
    showingError = false;
    ignoreCache = false;
    inProgress = false;
    paused = false;
    uploading = false;

    online: boolean;
    isReadOnly: boolean;
    uploadErrored: boolean;

    lastReferenceDataSyncDate: Date = null;
    lastRecordedDate?: number;

    siteId: guid;
    clientId: guid;

    uploadRequired: string = this.amtXlatSvc.xlat('mobileCommon.uploadBeforeDownload');
    uploadText: string = this.amtXlatSvc.xlat('mobileCommon.upload');
    uploadProgressSubText: string = '';
    uploadMessage: string = this.amtXlatSvc.xlat('mobileCommon.noUpload');

    referenceProgressStatus: string = this.amtXlatSvc.xlat('mobileCommon.download_reference_data');
    referenceProgressStatusSubtext: string = '';
    downloadMesssage: string = 'download';

    errorMessage: string = '';
    storageError: string;
    downloadError: string;

    retries: number;
    lastPercent: number;

    totalRecords: number;
    itemsOutstanding: number;
    recordsOutstanding: number;
    uploadTotal: number;

    referenceProgressPercent: number = 100;
    uploadProgressPercent: number = 100;

    promiseQueue: any[];
    syncPhases = {};
    moduleList: IMobileModule[];

    currentItems: any[] = [];

    networkChange: () => void;
    menuDownloadReferenceData: () => void;
    menuClearSyncOff: () => void;
    menuDeleteLocalDbOff: () => void;
    menuDeleteDbOff: () => void;
    mobileLocationOff: () => void;

    sectionCount: { val: number } = { val: 0 };

    uploadWindow: IWindowObj;
    uploadOptions: {
        downloadReferenceData: boolean,
        moduleList: IMobileModule[]
    };

    systemSettings: IDbSystem;

    waitingTimer: number;
    downloadTimer: number;

    outStandingRequests: any = {}; // request that have been sent and not returned
    recordsOutstandingIndex: number = 0; // used to pick the outstanding item in the list of items to display

    // one big grab for the moment 
    // note that any new dataset must be added to dbo.MobileDataSet in the Otracom database
    referenceItems: IMobileReferenceItem[] = [
        { name: 'specification', pageSize: 1000 },
        { name: 'reading', pageSize: 5000 },
        { name: 'pressureSensor', pageSize: 1000 },
        {
            name: 'maintenanceSession', pageSize: 10,
            prompt: (count) => this.downloadMaintenanceSession(count),
            mapping: (response) => this.dataBroker.mapMaintenanceSession(response)
        },
        { name: 'maintenanceType', pageSize: 100 },
        { name: 'statusFlows', pageSize: 100 },
        { name: 'tyre', pageSize: 250 },
        { name: 'visualInspectionCauses', pageSize: 1000 },
        { name: 'visualInspectionActions', pageSize: 1000 },
        { name: 'locations', pageSize: 1000 },
        { name: 'vehicle', pageSize: 1000 },
        { name: 'fitment', pageSize: 1000 },
        { name: 'chain', pageSize: 1000 },
        { name: 'rim', pageSize: 1000 },
        { name: 'equipmentTypes', pageSize: 1000 },
        { name: 'costTypes', pageSize: 1000 },
        { name: 'statuses', pageSize: 1000 },
        { name: 'pendingComponents', pageSize: 1000 },
        { name: 'unitsOfMeasure', pageSize: 1000 },
        { name: 'pendingVehicles', pageSize: 1000 },
        { name: 'productionCrew', pageSize: 100 },
        { name: 'fitters', pageSize: 100 },
        { name: 'damageCauses', pageSize: 100 },
        { name: 'damageSources', pageSize: 100 },
        { name: 'damageLocations', pageSize: 100 },
        { name: 'downTimeTypes', pageSize: 100 },
        { name: 'removalReasons', pageSize: 100 },
        { name: 'workshopTimes', pageSize: 100 },
        { name: 'componentOwner', pageSize: 100 },
        { name: 'pendingReceivals', pageSize: 1000 },
        { name: 'retreaders', pageSize: 100 },
        { name: 'repairers', pageSize: 100 },
        { name: 'readingEventTypeComment', pageSize: 100 },
        { name: 'purchaseOrders', pageSize: 1000 },
        { name: 'repairTypes', pageSize: 100 },
        { name: 'returnToSupplierReasons', pageSize: 100 },
        { name: 'suppliers', pageSize: 100 },
        { name: 'currency', pageSize: 100 },
        { name: 'fileMetadata', pageSize: 100 }
    ];

    static $inject = ['$scope', '$db', 'amtXlatSvc', 'moduleFactory', 'WindowFactory', 'confirmSvc', 'browserSvc', 'ocDateSvc',
        'amtCommandQuerySvc', '$q', 'errorReporter', '$timeout', '$rootScope', '$filter', 'dataBroker', 'ocConfigSvc', 'fileManagement'];

    constructor(private $scope: ng.IScope, private $db: DbClass, private amtXlatSvc: IAmtXlatSvc, private moduleFactory: any, private WindowFactory: IWindowFactory,
        private confirmSvc: IConfirmSvc, private browserSvc: BrowserSvc, private ocDateSvc: OcDateSvc, private amtCommandQuerySvc: IAmtCommandQuerySvc,
        private $q: ng.IQService, private errorReporter: IErrorReporter, private $timeout: ng.ITimeoutService, private $rootScope: IRootScope, private $filter: any,
        private dataBroker: IDataBroker, private ocConfigSvc: OcConfigSvc, private fileManagement: FileManagement) {

        // listen out for network changing
        this.networkChange = this.$rootScope.$on('networkChange', async (evt, online) => {

            this.online = online;

            if (online && this.initialised && !this.lastReferenceDataSyncDate) {
                try {
                    await this.confirmSvc.confirmMessage('mobileCommon.ReferenceDataUpdate', 'mobileCommon.ReferenceDataUpdatePrompt');
                } catch {
                    // they don't want to download data, then set it to readonly because is incomplete reference data on the device
                    this.isReadOnly = true;
                    return;
                }

                await this.start();
            }
        });

        this.menuDownloadReferenceData = this.$rootScope.$on('menu.downloadReferenceDataNoCache', () => this.start(true));

        this.menuClearSyncOff = this.$rootScope.$on('menu.clearSync', async () => {

            this.systemSettings.lastReferenceDataSyncDate = null;
            this.lastReferenceDataSyncDate = null;
            this.isReadOnly = true;

            try {
                await this.$db.system.put(this.systemSettings);
            } catch (error) {
                this.errorReporter.logError(error, 'MobileDataSync-ClearSync');
            }
        });

        this.menuDeleteLocalDbOff = this.$rootScope.$on('menu.deleteLocal', () => {
            this.$db.fieldSurvey.clear();
            this.$db.maintenanceSession.clear();
            this.$db.pitsAndAudit.clear();
            this.$db.receive.clear();
            this.$db.safetyAudit.clear();
            this.$db.stocktake.clear();
            this.$db.statusChange.clear();
        });

        this.menuDeleteDbOff = this.$rootScope.$on('menu.deleteDB', () => this.$db.promptToDeleteOtracomDatabase());

        this.mobileLocationOff = this.$rootScope.$on('menu.mobileLocation', () => {

            if (this.lastRecordedDate) {
                this.WindowFactory.alert('common.changeSite', ['common.ok_label'], 'mobileCommon.noSiteChange');
            }
            else {

                this.WindowFactory.openItem({
                    component: 'change-site',
                    caption: this.amtXlatSvc.xlat('common.changeSite'),
                    width: 600,
                    initParams: {
                        showCloseOnSave: false
                    },
                    modal: true,
                    onDataChangeHandler: async () => {

                        this.moduleList = this.moduleFactory.loadModules();

                        this.systemSettings.lastReferenceDataSyncDate = null;
                        this.lastReferenceDataSyncDate = undefined;
                        this.isReadOnly = true;

                        this.siteId = this.ocConfigSvc.user.site.id;
                        this.clientId = this.ocConfigSvc.user.client.id;

                        this.$timeout(async () => {

                            try {
                                await this.$db.system.put(this.systemSettings);

                                try {
                                    await this.confirmSvc.confirmMessage('mobileCommon.ReferenceDataUpdate', 'mobileCommon.ReferenceDataUpdatePrompt');
                                } catch {
                                    // they don't want to download data, then set it to readonly because is incomplete reference data on the device
                                    this.isReadOnly = true;
                                    return;
                                }

                                this.start(); // load reference data

                            } catch (error) {
                                this.errorReporter.logError(error, 'MobileDataSync-StoreSystemSettings');
                            } finally {
                                this.$timeout();
                            }
                        });
                    }
                });
            }
        });

        this.$scope.$watch(() => this.sectionCount.val, value => {

            if (value === 0) {
                this.uploadMessage = this.amtXlatSvc.xlat('mobileCommon.noUpload');
                return;
            }

            let msg = this.amtXlatSvc.xlat('mobileCommon.uploadSections');

            if (value === 1)
                msg = this.amtXlatSvc.xlat('mobileCommon.uploadSection');

            this.uploadMessage = value + ' ' + msg;
        });


        this.$scope.$watch(() => this.lastReferenceDataSyncDate, value => {
            if (!value) {
                this.downloadMesssage = this.amtXlatSvc.xlat('mobileCommon.noReferenceData');
                this.referenceProgressStatusSubtext = '';
            }
            else {
                this.downloadMesssage = this.amtXlatSvc.xlat('mobileCommon.lastDownloaded') + ' ' + this.$filter('date')(value, 'short');
            }
        });
    }

    async $onInit() {

        // tell the browser serice to start watching network change events, or start an interval timer to trigger updates
        this.browserSvc.startConnectionMonitoring();

        try {
            this.siteId = this.ocConfigSvc.user.site.id;
            this.clientId = this.ocConfigSvc.user.client.id;
        } catch (error) {
            console.error('User info is not set');
        }

        // Use the 'setStatus' method to change the phase and set the phase
        // percentage. The phases below are just for testing, for some reason
        // the actual cut over percentages are specified in the BRS, so these
        // phases will need to be updated to reflect the spec at some point
        let data = [{ code: MobileSyncPhase.prompt, startPct: 0, endPct: 1 },
        { code: MobileSyncPhase.waitingForServer, startPct: 1, endPct: 2 },
        { code: MobileSyncPhase.downloading, startPct: 2, endPct: 90 },
        { code: MobileSyncPhase.downloadingFiles, startPct: 90, endPct: 100 },
        { code: MobileSyncPhase.complete, startPct: 100, endPct: 100 },
        { code: MobileSyncPhase.error, startPct: 100, endPct: 100 }];
        this.syncPhases = _.indexBy(data, 'code');

        this.moduleList = this.moduleFactory.loadModules();

        // data structure used for upload popup
        this.uploadOptions = {
            downloadReferenceData: false,
            moduleList: this.moduleList
        };

        try {

            let newSettings: IDbSystem = {
                id: 1,
                identifier: uuid(),
                site: this.ocConfigSvc.user.site.id
            };

            console.warn('Data Sync');

            let system;

            try {
                system = await this.$db.system.get(1);
            } catch (error) {
                this.errorReporter.logError(error, 'MobileDataSync-GetSystemSettings');
                this.initialised = true;
                return;
            }

            //This stops processing if we have an auth failure
            if (!this.$rootScope.loggedIn)
                return;

            if (!system) {
                console.warn('No system entry in DB');
                this.$db.system.add(newSettings);
                system = newSettings;
            }
            else {
                if (system.site !== this.ocConfigSvc.user.site.id) {
                    // site has changed                    
                    this.$db.system.put(newSettings);
                    system = newSettings;
                }
            }

            this.systemSettings = system;

            if (this.systemSettings.lastReferenceDataSyncDate)
                this.lastReferenceDataSyncDate = new Date(this.systemSettings.lastReferenceDataSyncDate);

            if (!this.lastReferenceDataSyncDate || this.ocDateSvc.getDaysDiffLegacy(this.lastReferenceDataSyncDate) > 2) {

                if (this.sectionCount.val === 0 && !this.lastReferenceDataSyncDate) {
                    // introduced for OR-1853
                    try {
                        await this.confirmSvc.confirmMessage('mobileCommon.ReferenceDataUpdate', 'mobileCommon.ReferenceDataUpdatePrompt');

                        if (this.sectionCount.val > 0) {
                            this.loadUploadOptions();
                        } else {
                            this.start();
                        }

                    } catch {
                        // they don't want to download data, then set it to readonly because there is incomplete reference data on the device
                        this.isReadOnly = true;
                    }

                } else {
                    // warn about old ref data
                    this.WindowFactory.alert('mobileCommon.ReferenceDataUpdate', ['common.close_label'], 'mobileCommon.ReferenceDataStaleUpdatePrompt');
                }

                this.referenceProgressStatus = this.amtXlatSvc.xlat('mobileCommon.download_reference_data');

                this.initialised = true;
            }

        } catch (error) {
            console.error('Failed data sync system info load');
            this.errorReporter.logError(error, 'MobileDataSync');
            this.initialised = true;
        }

        this.setStatus(MobileSyncPhase.prompt, 100);
        this.referenceProgressStatusSubtext = '';
    }

    updateOldestEdit() {

        let oldestEdit = null;

        for (let ml of this.moduleList || []) {
            ml.progress = 100; // force it to clear the progress bar here.

            if (ml.lastRecordedDate) {
                if (!oldestEdit || ml.lastRecordedDate.getTime() < oldestEdit.getTime()) {
                    oldestEdit = ml.lastRecordedDate;
                }
            }
        }

        this.lastRecordedDate = oldestEdit;

        this.$timeout();
    }

    async downloadMaintenanceSession(count: number): Promise<any> {

        return this.WindowFactory.openItemAsync({
            component: 'maintenance-session-download-prompt',
            caption: this.amtXlatSvc.xlat('maintenanceSession.downloadPromptHeader'),
            canClose: false,
            initParams: {
                count: count
            },
            width: 800
        }).promise;
    }

    async onUpload(doDataDownload: boolean) {

        this.uploadErrored = false;

        if (this.uploadWindow)
            this.WindowFactory.closeWindow(this.uploadWindow);

        await this.startUpload();

        // download reference data here if requested.
        this.$timeout(() => {
            if (doDataDownload && !this.uploadErrored) {
                this.start();
            }
        });
    }

    private clearPromiseQueue() {
        if (this.promiseQueue) {
            for (let promise of this.promiseQueue || []) {
                if (promise._httpTimeout && promise._httpTimeout.resolve)
                    promise._httpTimeout.resolve();
            }
        }

        this.promiseQueue = [];
    }

    private addPromiseToQueue(promise) {
        this.promiseQueue.push(promise);
    }

    private removePromiseFromQueue(promise) {
        if (!this.promiseQueue)
            return;

        let idx = this.promiseQueue.indexOf(promise);

        if (promise && promise._httpTimeout && promise._httpTimeout.resolve)
            promise._httpTimeout.resolve();

        if (idx > -1)
            this.promiseQueue = this.promiseQueue.splice(idx, 1);
    }

    loadUploadOptions() {

        if (!this.sectionCount || !this.sectionCount.val) {
            // this shouldn't happen. item should be disabled
            console.warn('upload clicked with no data for upload');
            return;
        }

        if (this.uploading)
            return;

        this.uploadWindow = this.WindowFactory.openItem({
            component: 'upload-options',
            caption: this.amtXlatSvc.xlat('mobileCommon.upload'),
            width: 450,
            initParams: this.uploadOptions,
            modal: true,
            onDataChangeHandler: (doDataDownload) => this.onUpload(doDataDownload)
        });

        this.uploadWindow.onClose = (param) => this.onCloseUploadWindow(param);
    }

    // the percent is the percent in the phase, this will be converted to the total%
    // percent for the status that is shown to the user, either in a label or as a 
    // progress bar
    setStatus(statusCode: string, percent?: number) {
        let phase = this.syncPhases[statusCode];

        if (phase) {
            this.referenceProgressPercent = this.getTotalPct(statusCode, percent || 0);
            this.referenceProgressStatus = this.amtXlatSvc.xlat('mobileCommon.' + phase.code);
        }
    }

    getTotalPct(statusCode: string, phasePct: number): number {
        let phase = this.syncPhases[statusCode];
        return phase ? phasePct * (phase.endPct - phase.startPct) + phase.startPct : 0;
    }

    onCloseUploadWindow(param?: string) {

        if (this.uploadWindow) {
            this.WindowFactory.closeWindow(this.uploadWindow);
            this.uploadWindow = null; // window is gone.
        }

        if (param)
            this.start();
    }

    async checkProcessingState(): Promise<boolean> {

        // check is any processing is still happening for the site on the server
        let response = await this.amtCommandQuerySvc.get('sync/checkProcessingState', { siteId: this.siteId });

        // if processing is still happening for the site, 
        if (response.data === true) {

            try {
                // ask the user if they want to continue with reference data download
                await this.confirmSvc.confirmMessage('mobileCommon.processingConfirmHeader', 'mobileCommon.processingConfirmText');
            } catch {
                // cancel download
                return false;
            }

            // continue with download
            return true;

        } else {
            // continue with download
            return true;
        }
    }

    updateDownloadProgress(e) {
        if (e.total > 0) {
            let phasePct = e.loaded / e.total;
            if (phasePct < this.lastPercent) {
                phasePct = this.lastPercent;
            }
            this.lastPercent = phasePct;
            this.setStatus(e.phase, phasePct);
        } else {
            this.setStatus(MobileSyncPhase.waitingForServer, e.wait || 0);
        }
    }

    // show the list of errors resulting from an upload
    showErrorList(uploadErrors): Promise<string> {

        let data = {
            errors: uploadErrors
        };

        return new Promise(resolve => {
            this.WindowFactory.openItem({
                component: 'upload-errors',
                caption: this.amtXlatSvc.xlat('fieldSurvey.UploadErrors') + ' ' + this.$filter('date')(new Date(), 'medium'),
                initParams: data,
                width: 600,
                modal: true,
                onDataChangeHandler: (result: string) => resolve(result)
            })
        });
    }

    async storeDataInTable(items: any[], table: string) {

        if (items.length === 0)
            return;

        if (this.$db[table]) {

            try {
                await this.$db[table].bulkPut(items).catch(Dexie.BulkError, e => {
                    // Explicitly catching the bulkAdd() operation makes those successful
                    // additions commit despite that there were errors.
                    console.error(table + ': Put fails: ' + e.failures.length);
                });
            } catch (error) {
                console.error('General Error storing data: ' + table);
                console.error(error);
                this.storageError = error;
            }

        } else {
            throw new Error('Table ' + table + ' does not exist');
        }
    }

    async start(ignoreCache?: boolean) {

        this.retries = 5;

        this.clearPromiseQueue();

        this.downloadError = null;
        this.storageError = null;
        this.ignoreCache = ignoreCache == true;

        //Download the Reference Data.
        //This needs to be abstracted out so that different types of reference data come down and overall progress is not lost.
        // e.g 10 calls each accounting for 10% of progress
        if (!this.inProgress) {

            let currentProgressPercent = this.referenceProgressPercent;

            this.inProgress = true;

            this.setStatus(MobileSyncPhase.waitingForServer);

            let proceed = false;

            try {
                proceed = await this.checkProcessingState();
            } catch (error) {
                this.errorReporter.logError(error);
            }

            if (!proceed) { // processing still occurring and user chose not to proceed with download
                this.inProgress = false;
                this.setStatus(MobileSyncPhase.prompt, currentProgressPercent);
                return;
            }

            let syncDate = new Date();

            this.dataBroker.logAuditEntry('Download', 'Start');

            // set up a timer to measure the duration of the download process (excluding while waiting for user input)
            let downloadDuration = 0;

            if (this.downloadTimer) {
                clearInterval(this.downloadTimer);
                this.downloadTimer = null;
            }

            this.downloadTimer = window.setInterval(() => {
                if (!this.paused)
                    downloadDuration += 1;
            }, 1000);

            // clear the current sync data
            this.systemSettings.lastReferenceDataSyncDate = null;
            this.isReadOnly = true;

            try {

                await this.$db.system.put(this.systemSettings);

                try {

                    await this.getReferenceData();

                    this.setStatus(MobileSyncPhase.downloadingFiles);

                    await this.getFiles();

                    this.setStatus(MobileSyncPhase.complete);

                    this.referenceProgressStatusSubtext = '';

                    this.systemSettings.lastReferenceDataSyncDate = syncDate.getTime();
                    this.systemSettings.site = this.ocConfigSvc.user.site.id;
                    this.systemSettings.readingEventTypeCommentId = null;

                    this.lastReferenceDataSyncDate = syncDate;

                    try {
                        await this.$db.system.put(this.systemSettings);
                    } catch (error) {
                        this.errorReporter.logError(error, 'MobileDataSync-StoreSystemSettings');
                    }

                    // force a refresh
                    for (let module of this.moduleList)
                        module.updateDetails();

                    this.updateOldestEdit();

                    this.inProgress = false;
                    this.isReadOnly = false;

                } catch (error) {

                    console.error('Ref data download error');
                    console.error(error);

                    this.uploadErrored = true;
                    this.setStatus(MobileSyncPhase.error);
                    this.inProgress = false;

                    if (error.status === -1) {
                        this.WindowFactory.alert('exception.ActionOfflineHeader', ['common.ok_label'], 'exception.ActionOffline');
                        return this.$q.resolve();
                    } else {
                        return this.$q.reject(error);
                    }
                }

            } catch (error) {

                this.uploadErrored = true;

                if (error !== 'Offline-Chunk')
                    this.errorReporter.logError(error, 'MobileDataSync-StoreSystemSettings');

            } finally {

                this.dataBroker.uploadErrors();

                this.dataBroker.logAuditEntry('Download', 'Finish',
                    String.format('{0} second(s) taken', downloadDuration));

                this.inProgress = false;
                this.setStatus(MobileSyncPhase.complete);
                this.referenceProgressStatusSubtext = '';

                if (this.waitingTimer) {
                    clearInterval(this.waitingTimer);
                    this.waitingTimer = null;
                }

                if (this.downloadTimer) {
                    clearInterval(this.downloadTimer);
                    this.downloadTimer = null;
                }
            }
        }
    }

    async getFiles() {

        // use existing data in file table and what we just got from filemetadata to get a list of files we need to download
        // retrieve required files from the server and save to the file table

        if (this.waitingTimer) {
            clearInterval(this.waitingTimer);
            this.waitingTimer = null
        }

        let updateProgress = (() => {
            this.updateDownloadProgress({
                total: this.totalRecords,
                loaded: this.totalRecords - this.recordsOutstanding,
                phase: MobileSyncPhase.downloadingFiles
            });

            this.$timeout();
        });

        this.lastPercent = 0;
        this.referenceProgressStatusSubtext = this.amtXlatSvc.xlat('mobileCommon.files');

        let fileMetadatas = await this.$db.fileMetadata.toArray();
        let existingFileIds = await this.$db.file.toCollection().primaryKeys();

        // uploaded files in $fileMetadata that are not in $file
        let fileIdsToDownload = fileMetadatas
            .filter(f => !existingFileIds.some(i => i === f.id) && f.uploaded)
            .map(f => f.id);

        this.totalRecords = fileIdsToDownload.length;
        this.recordsOutstanding = this.totalRecords;

        updateProgress();

        this.waitingTimer = window.setInterval(() => updateProgress(), 200);

        // files that don't have a metadata record 
        let fileIdsToRemove = existingFileIds.filter(f => !fileMetadatas.map(f => f.id).some(i => i === f));

        for (let id of fileIdsToRemove || []) {
            await this.$db.file.delete(id);
            await this.$db.thumb.delete(id);
        }

        let filePromises: Promise<void>[] = [];

        // retrieve any needed files from the server that we don't already have
        for (let id of fileIdsToDownload || []) {
            filePromises.push(new Promise<void>(async resolve => {

                let file: IFile = await this.fileManagement.getFile(id, true);

                if (file)
                    await this.fileManagement.saveFileLocal(file, false, true);

                this.recordsOutstanding--;

                return resolve();
            }));
        }

        await Promise.all(filePromises);
    }

    $onDestroy() {
        // stop watching the connection here
        this.browserSvc.stopConnectionMonitoring();

        // remove the subscriber from the message bus
        if (this.networkChange)
            this.networkChange();

        if (this.menuClearSyncOff)
            this.menuClearSyncOff();

        if (this.mobileLocationOff)
            this.mobileLocationOff();

        if (this.menuDeleteDbOff)
            this.menuDeleteDbOff();

        if (this.menuDeleteLocalDbOff)
            this.menuDeleteLocalDbOff();

        if (this.menuDownloadReferenceData)
            this.menuDownloadReferenceData();
    }

    // gets the set of reference data for the items
    async getReferenceData() {

        console.log('getReferenceData');

        let defered = this.$q.defer();

        if (!this.online)
            return defered.reject("Offline");

        this.lastPercent = 0;

        let waitPct = 0;
        let waitCount = 1;

        this.referenceItems.forEach(i => i.tableCleared = false);

        // delete records from fileMetadata that have been uploaded (so they can be replaced by the download)
        // non-uploaded records should remain
        // this is the one rare case where the table forms part of both the download and the upload
        let fileMetadata = await this.$db.fileMetadata.toArray();

        if (fileMetadata && fileMetadata.length) {
            let uploadedFileIds = fileMetadata.filter(f => f.uploaded).map(f => f.id);

            if (uploadedFileIds && uploadedFileIds.length) {
                await this.$db.fileMetadata.bulkDelete(uploadedFileIds);
            }
        }

        // while waiting for the server to generate the response we don't get any
        // progress indications from them, but we don't want the user to thing that
        // nothing is happening, or that the application is frozen, so we have the 
        // percentage slowly change.
        //
        // We don't ever want the percentage to get to the end of phase so the progress
        // will get slower as the wait goes along
        //
        // Once the downloading starts, we go to the next phase and use the amount of
        // data downloaded to get the progress
        if (this.waitingTimer) {
            clearInterval(this.waitingTimer);
            this.waitingTimer = null
        }

        this.waitingTimer = window.setInterval(() => {
            if (!this.paused) {
                waitCount += 1;
                waitPct += (1 / Math.sqrt(waitCount));
                this.updateDownloadProgress({ wait: waitPct / 100 });
                this.$timeout(); // push the UI
            }
        }, 200);

        // flatten the items, and extract names in order to get some counts
        let countItems = [].concat.apply([], this.referenceItems).map(a => a.name);

        if (!this.siteId)
            defered.reject('Site Id not set');

        if (!this.clientId)
            defered.reject('Client Id not set');

        this.referenceProgressStatusSubtext = '';

        let sections = _.indexBy(this.referenceItems, 'name');

        for (let item in sections) {
            // default as 100 per section.
            if (sections.hasOwnProperty(item)) {
                sections[item].count = 100;
                sections[item].received = 0;
                this.totalRecords += 100;
            }
        }

        let confirmPromise = new Promise<void>(resolve => resolve());

        this.currentItems = this.referenceItems.slice(); // these are the items we will download

        let addPromise = (confirmPromise, item, count) => {

            return new Promise<void>(async resolve => {

                try {
                    await confirmPromise;
                } catch (error) {
                    // OK an error confirming - move on, you are going to get it.
                    this.errorReporter.logError(error, 'MobileDataSync');
                    return resolve();
                }

                try {
                    await sections[item].prompt(count);
                } catch {

                    // they chose to not download the items
                    console.warn('remove ' + item + ' from the download');

                    for (var i = 0; i < this.currentItems.length; i++) {

                        if (this.currentItems[i].name === item) {

                            this.currentItems.splice(i, 1);

                            try {
                                this.$db[item].clear(); // and ditch the current table
                            } catch (error) {
                                console.warn('Could not clear table. Probably no data');
                            }

                            return resolve();
                        }
                    }
                }

                return resolve();
            });
        };

        try {
            // if you get it in chunks there is a problem with physical size versus logical size.
            let response = await this.amtCommandQuerySvc.get('sync/getReferenceDataCounts', {
                params: {
                    siteId: this.siteId,
                    clientId: this.clientId,
                    items: countItems,
                    lastUpdated: this.lastReferenceDataSyncDate,
                    ignoreCache: this.ignoreCache
                }
            });

            // use the response to setup the progress bar correctly
            this.totalRecords = 0;

            let dc = response.data.dataCounts;

            for (let item in dc) {
                if (dc.hasOwnProperty(item)) {
                    // if any have result and require prompting we add the prompt to the confirm promise
                    if (sections[item].prompt && dc[item] > 0) {
                        confirmPromise = addPromise(confirmPromise, item, dc[item]);
                    }

                    sections[item].count = dc[item];
                    this.totalRecords += dc[item];
                }
            }

        } catch (error) {

            this.totalRecords = 0;
            console.warn('Did not get count data:' + error);
            this.errorReporter.logError(error, 'MobileDataSync-GetRefDataCounts', true);

            // make it up.
            for (let i = 0; i < countItems.length; i++) {
                let itemX = countItems[i];
                sections[itemX].count = 100;
                sections[itemX].received = 0;
                this.totalRecords += 100;
            }

        } finally {

            // we confirm download of any that require prompting
            this.paused = true;
            this.storageError = null;
            this.downloadError = null;

            try {
                await confirmPromise;
            } catch {
                return;
            }

            this.paused = false;
            this.itemsOutstanding = this.currentItems.length;
            this.recordsOutstanding = this.totalRecords;

            console.warn('items to download:' + this.itemsOutstanding);

            let itemY = this.currentItems[0];

            this.currentItems.shift();
            this.retries = 2;

            if (this.waitingTimer) {
                clearInterval(this.waitingTimer);
                this.waitingTimer = null;
            }

            let updateProgress = (() => {
                this.updateDownloadProgress({
                    total: this.totalRecords,
                    loaded: this.totalRecords - this.recordsOutstanding,
                    phase: MobileSyncPhase.downloading
                });

                this.$timeout();
            });

            this.referenceProgressStatusSubtext = this.amtXlatSvc.xlat('mobileCommon.' + itemY.name);
            updateProgress();

            this.waitingTimer = window.setInterval(() => updateProgress(), 200);

            this.getReferenceDataChunk(defered, 1, itemY);
        }

        return defered.promise;
    }

    async getReferenceDataChunk(defered, page, item) {

        if (!this.online) {
            console.log("getReferenceDataChunk:Aborted-offline");
            return defered.reject("Offline-Chunk");
        }

        if (this.downloadError) {
            console.warn("Rejected due to prior download error");
            return defered.reject(this.downloadError);
        }

        if (this.storageError) {
            // not much point carrying on
            console.warn("Rejected due to storage error");
            return defered.reject(this.storageError);
        }

        if (!page)
            page = 1;

        // poke the UI
        this.$timeout();

        if (!angular.isArray(item))
            item = [item];

        if (!item) {
            defered.reject('No Item');
            return;
        }

        let itemNames = item.map(a => a.name);

        this.outStandingRequests[itemNames[0]] = 1;

        let grdPromise = this.amtCommandQuerySvc.get('sync/getReferenceData', {
            params: {
                siteId: this.siteId,
                clientId: this.clientId,
                items: itemNames,
                page: page,
                pageSize: item[0].pageSize,
                lastUpdated: this.lastReferenceDataSyncDate,
                ignoreCache: this.ignoreCache
            },
            eventHandlers: {
                progress: c => this.$timeout()
            },
            //Even though this will never be called I am leaving this here for reference
            uploadEventHandlers: {
                progress: e => { }
            }
        });

        this.addPromiseToQueue(grdPromise);

        try {

            let response = await grdPromise;

            if (!this.inProgress) {
                console.warn('Returned because not in progress');
                return; // we already aborted - there was a data issue;
            }

            this.retries = 3; // data is coming down so it is worth doing retries if we get a fail;

            let refData = response.data;
            if (typeof refData !== 'object' || refData.status === 'fail') {
                // something's not right here 
                console.warn('Rejected because data failure');
                return defered.reject(response);
            }

            this.removePromiseFromQueue(grdPromise);

            let data = refData.dataSets;
            let moreData = [];
            let currKey = '--';

            for (let key in data) {

                if ({}.hasOwnProperty.call(data, key)) {

                    let set = data[key];
                    let table = key;

                    // check to see if the key returned is not the table name and has been remapped.
                    let blockData = null;
                    for (let i = 0; i < this.referenceItems.length; i++) {
                        if (this.referenceItems[i].name === key) {

                            blockData = this.referenceItems[i];

                            if (this.referenceItems[i].table)
                                table = this.referenceItems[i].table;
                        }
                    }

                    if (!this.$db[table]) {
                        this.errorReporter.logError('Local DB Table missing ' + table, 'MobileDataSync-GetRefData', true); // UI Suppressed
                        this.$q.reject(this.amtXlatSvc.xlat('mobileCommon.databaseError'));
                    }

                    let increment = 0;

                    if (set.moreData) {
                        if (blockData.received + set.items.length > blockData.count) {
                            // extra data                            
                            increment = blockData.count - blockData.received;
                            blockData.received = blockData.count;
                        } else {
                            blockData.received += set.items.length;
                            increment = set.items.length;
                        }
                    } else {
                        // OK we have all data.
                        increment = blockData.count - blockData.received;
                    }

                    this.recordsOutstanding -= increment;

                    delete this.outStandingRequests[key];

                    let outstandingKeys = Object.keys(this.outStandingRequests);

                    if (outstandingKeys.length === 0) {
                        this.referenceProgressStatusSubtext = this.amtXlatSvc.xlat('mobileCommon.' + key); // not waiting for anything at the moment, so we show last recieved.   
                    } else {

                        if (this.referenceProgressStatusSubtext === outstandingKeys[this.recordsOutstandingIndex])
                            this.recordsOutstandingIndex++;

                        if (this.recordsOutstandingIndex >= outstandingKeys.length)
                            this.recordsOutstandingIndex = 0;

                        this.referenceProgressStatusSubtext = this.amtXlatSvc.xlat('mobileCommon.' + outstandingKeys[this.recordsOutstandingIndex]);
                    }

                    if (set.clearAllExisting && page === 1) {

                        // if the table has already been cleared during this run don't clear it again
                        if (blockData.tableCleared) {
                            console.warn('table ' + table + ' already cleared');
                        } else {

                            try {
                                this.$db[table].clear();
                            } catch (error) {
                                console.warn('Could not clear table. Probably no data');
                            }

                            // mark any reference items that target this table as cleared
                            // this is currently to prevent tyres and tyresWithVI from wiping eachother out sometimes
                            for (let ri of this.referenceItems) {
                                if ((ri.table || ri.name) === table)
                                    ri.tableCleared = true;
                            }

                            if (set.items.length === 0)
                                this.errorReporter.logError('No data for ' + table, 'MobileDataSync-GetRefData', true); // silently log this on server                            
                        }
                    }

                    currKey = key;

                    if (angular.isArray(set.items)) {
                        if (blockData.mapping)
                            set.items = set.items.map(blockData.mapping);

                        // put the data in the local DB. will overwrite any existing.
                        this.storeDataInTable(set.items, table);
                    }

                    if (angular.isArray(set.deletions)) {
                        // put the data in the local DB. will overwrite any existing.
                        /* jshint ignore:start */
                        // yes I am creating a function in a loop, but I want a closure for each
                        this.$db[table].bulkDelete(set.deletions).catch(Dexie.BulkError, e => {
                            // Explicitly catching the bulkAdd() operation makes those successful
                            // additions commit despite that there were errors.
                            console.error(currKey + ': Delete fails: ' + e.failures.length);
                        });
                        /* jshint ignore:end */
                    }

                    if (set.moreData) {
                        moreData.push({
                            name: key,
                            pageSize: refData.pageSize,
                            table: table,
                        });
                    }
                }
            }

            if (moreData.length > 0) {
                // loop round for more as part of this items 
                this.getReferenceDataChunk(defered, page + 1, moreData);
            } else {
                // this item is complete 

                // we are using a counter here because if we run in parallel the array is empty before they all respond. 
                this.itemsOutstanding--;

                if (this.itemsOutstanding === 0) {

                    if (this.storageError) {
                        defered.reject(this.storageError);
                    } else {
                        if (this.downloadError) {
                            defered.reject(this.downloadError);
                        } else {

                            // Everything should be downloaded now, i.e. 100%
                            this.$timeout(); // Kick the buttons to update to "Ready" (OR-4469)
                            defered.resolve();
                        }
                    }

                    return null;
                }

                // kick the next one now                
                item = this.currentItems[0];
                this.currentItems.shift();

                if (item)
                    this.getReferenceDataChunk(defered, 1, item);

            }
        } catch (error) {

            if (error !== 'Offline-Chunk') {
                console.error(this.errorReporter.exceptionMessage(error));
            }

            if (error.status === -1 && this.retries > 0) {
                // network issue we can retry                
                this.retries--;
                // go round with the same data
                this.getReferenceDataChunk(defered, page, item);
            } else {
                this.downloadError = error;

                if (this.waitingTimer) {
                    clearInterval(this.waitingTimer);
                    this.waitingTimer = null;
                }

                if (!this.showingError) {
                    this.showingError = true;

                    try {
                        let selection = await this.WindowFactory.alert('mobileCommon.download_reference_data', ['common.retry_label', 'common.cancel'], 'mobileCommon.downloadFailure');

                        if (selection === 'common.cancel') {
                            defered.reject(error);
                        } else {
                            this.retries = 3;
                            this.$timeout(() => this.getReferenceDataChunk(defered, page, item), 1500);
                        }

                    } catch {
                        defered.reject(error);
                    } finally {
                        this.showingError = false;
                    }
                }
            }
        }

        this.$timeout(() => {
            item = this.currentItems[0];
            this.currentItems.shift();

            if (item)
                this.getReferenceDataChunk(defered, 1, item);

        }, 1500); // space them out by a few seconds        
    }

    uploadOldest(uploadErrors) {

        return new Promise<void>(async (resolve, reject) => {
            // while we have something to upload, call the oldest
            let oldestDate = new Date();
            let oldestMod = null;
            let ml = this.moduleList;

            for (let m of ml) {
                if (m.lastRecordedDate && m.lastRecordedDate < oldestDate) {
                    oldestMod = m;
                    oldestDate = m.lastRecordedDate;
                }
            }

            if (!oldestMod) { // nothing left to upload
                this.uploadProgressSubText = '';
                return resolve();
            }

            try {
                await oldestMod.doUpload(uploadErrors);

                this.totalRecords--;
                this.uploadProgressSubText = this.amtXlatSvc.xlat('mobileCommon.uploadingProgress', this.uploadTotal - this.totalRecords, this.uploadTotal) + ' - ';
                this.uploadProgressPercent = ((this.uploadTotal - this.totalRecords) / this.uploadTotal) * 100;

                try {
                    await this.uploadOldest(uploadErrors);
                    return resolve();
                } catch {
                    return reject();
                }

            } catch (error) {
                this.uploadProgressSubText = '';
                return reject(error);
            }
        });
    }

    startUpload() {
        /* jshint loopfunc: true */

        this.uploading = true;

        return new Promise<void>(async (resolve, reject) => {

            let uploadErrors = [];

            this.uploadText = this.amtXlatSvc.xlat('mobileCommon.uploading');

            // get the total record from here;
            let ml = this.moduleList;

            this.totalRecords = 0;
            this.uploadProgressPercent = 0;

            for (let m of ml)
                this.totalRecords += m.count;

            this.uploadTotal = this.totalRecords;

            this.dataBroker.logAuditEntry('Upload', 'Start', String.format('{0} record(s)', this.uploadTotal));

            let uploadStartTime = new Date();

            this.uploadProgressSubText = this.amtXlatSvc.xlat('mobileCommon.uploadingProgress', 0, this.totalRecords) + ' - ';

            try {

                await this.uploadOldest(uploadErrors);

                if (uploadErrors.length > 0) {
                    await this.showErrorList(uploadErrors); // wait for a dialog result before continuing

                    this.uploadText = this.amtXlatSvc.xlat('mobileCommon.completed');

                    return resolve();
                }

                // delete any remaining files that are not marked as uploaded
                // these are orphaned files and can be cleared from the device
                let fileMetadatas = await this.$db.fileMetadata.toArray();

                if (fileMetadatas && fileMetadatas.length) {
                    let orphanedFileIds = fileMetadatas.filter(f => !f.uploaded).map(f => f.id);

                    await this.fileManagement.deleteFiles(orphanedFileIds);
                }

                // clear any remembered pressure reading type
                if (this.systemSettings.readingEventTypeCommentId) {
                    this.systemSettings.readingEventTypeCommentId = null;

                    try {
                        await this.$db.system.put(this.systemSettings);
                    } catch (error) {
                        this.errorReporter.logError(error, 'MobileUpload-StoreSystemSettings');
                    }
                }

                this.uploadText = this.amtXlatSvc.xlat('mobileCommon.completed');

            } catch (error) {

                console.error("upload Errored:" + this.errorReporter.exceptionMessage(error));

                this.uploadErrored = true;

                if (error.status === -1) {

                    let msg = 'exception.ActionOffline';

                    if (this.totalRecords != this.uploadTotal)
                        msg = 'exception.interrupted';

                    let selection = await this.WindowFactory.alert('exception.ActionOfflineHeader', ['common.retry_label', 'common.ok_label'], msg);

                    if (selection === 'common.retry_label') {
                        try {
                            await this.startUpload();
                            resolve();
                        } catch {
                            reject();
                        }
                    } else {
                        this.uploadProgressPercent = 100;
                    }
                } else {

                    let selection = await this.WindowFactory.alert('exception.internal_fault_heading', ['common.retry_label', 'common.ok_label'], 'exception.uploadError',
                        (error.identifier ? error.identifier : '') + this.errorReporter.exceptionMessage(error), 750);

                    if (selection === 'common.retry_label') {
                        try {
                            await this.startUpload();
                            resolve();
                        } catch {
                            reject();
                        }
                    } else {
                        this.uploadProgressPercent = 100;
                    }
                }

                this.uploadText = this.amtXlatSvc.xlat('mobileCommon.upload');

            } finally {

                let uploadEndTime = new Date();

                this.dataBroker.logAuditEntry('Upload', 'Finish', String.format('{0} second(s) taken',
                    this.ocDateSvc.getDifference(uploadStartTime, uploadEndTime, DateUnits.seconds)));

                this.dataBroker.uploadErrors();
                this.dataBroker.uploadAudits();

                this.$timeout(() => {
                    this.uploading = false;
                    this.updateOldestEdit(); // this resets the colors for the uploader.
                });
            }
        });
    }
}

angular.module('app.mobile').controller('dataSyncCtrl', DataSyncCtrl);





