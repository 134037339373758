<!--Loading Page-->
<div class="k-loading-image" *ngIf="!result.hasReceivedResult"></div>

<section class="pressure-check-vehicle-widget-container" *ngIf="result.hasReceivedResult">

    <div *ngIf="!isMouseHover" class="pressure-check-per-vehicle-search-container">
        <!--Search bar-->
        <section class="form-group">
            <input type="text" class="form-control" placeholder="{{ui.search}}" [(ngModel)]="filterTerm" />
        </section>

        <!--Vehicle Application Drop Down List-->
        <section class="pressure-check-vehicle-application-container">
            <kendo-dropdownlist #vehicleApplicationDropdownList
                                [data]="vehicleApplicationList"
                                [(ngModel)]="selectedVehicleApplication"
                                (ngModelChange)="changeVehicleApplication($event)">
            </kendo-dropdownlist>
        </section>
    </div>

    <!-- Pressure Check Per Vehicle Detail View Container-->
    <div class="pressure-check-per-vehicle-detail-container" *ngIf="isMouseHover">
        <p class="pressure-check-per-vehicle-detail-title"><b>{{hoveredVehicle.manufacturerName}} {{hoveredVehicle.applicationName}} {{hoveredVehicle.modelName}} </b> </p>
        <p class="pressure-check-per-vehicle-detail-text"><b>{{ui.fieldSurvey}}</b>{{hoveredVehicle.fieldSuryveyCount}}</p>
        <p class="pressure-check-per-vehicle-detail-text"><b>{{ui.maintenanceSession}} </b>{{hoveredVehicle.pendingMaintenanceSessionCount}}</p>
    </div>

    <!--Scroll Container-->
    <section class="widget-scroll-container">
        <!--Title Container-->
        <section class="widget-scroll-title-container">

            <!--Vehicle Serial Number Title-->
            <div class="widget-scroll-title" (click)="sortBySerialNumber()">
                <b>{{ui.serialNumber}}</b>
                <span class="k-icon k-i-sort-asc-sm" *ngIf="isSerialNumberAscOrder && !isSerialNumberDescOrder"></span>
                <span class="k-icon k-i-sort-desc-sm" *ngIf="!isSerialNumberAscOrder && isSerialNumberDescOrder"></span>
            </div>

            <!--Vehicle Pressure Check Per Vehicle Title-->
            <div class="widget-scroll-title" (click)="sortByPressureCheckCount()">
                <b>{{ui.checkNumber}}</b>
                <span class="k-icon k-i-sort-asc-sm" *ngIf="isPressureCheckCountAscOrder && !isPressureCheckCountDescOrder"></span>
                <span class="k-icon k-i-sort-desc-sm" *ngIf="!isPressureCheckCountAscOrder && isPressureCheckCountDescOrder"></span>
            </div>

        </section>

        <!--Item Container-->
        <div (mouseout)="dismissVehicleDetail()">
            <section *ngFor="let vehicle of selectedVehicles | filter: filterTerm" (mouseover)="showVehicleDetail(vehicle.serialNumber)">

                <a class="widget-scroll-row" href="/#!/vehicle/search" (click)="setSearchBy(vehicle.serialNumber)">
                    <!--Vehicle Serial Number Item-->
                    <div class="widget-scroll-col">
                        <a href="/#!/vehicle/search" ng-click="setSearchBy(vehicle.serialNumber)" class="text-u-l">{{vehicle.serialNumber}}</a>
                    </div>

                    <!--Vehicle Pressure Check Per Vehicle Item-->
                    <div class="widget-scroll-col" align="right">
                        {{vehicle.pressureCheckCount}}
                    </div>
                </a>

            </section>
        </div>

    </section>

</section>

