//import angular from 'angular';
import * as _ from 'underscore';
import OcDateSvc from '../../../../services/ocDateSvc';
import AdminUrls from '../../../admin/adminUrls';
import tmpl from './addEditTraining.html';


    angular.module('app.site').component('addEditTraining',
        {
            bindings: {
                initParams: '=',
                buttonMethods: '=',
                buttonStates: '=',
                buttons: '=',
                closeOnSave: '=',
                wnd: '='
            },
            template: tmpl,
            controller: [
                '$scope', 'endOfMonthSvc', 'amtXlatSvc', 'WindowFactory', 'confirmSvc', 'notifySvc',
                'errorReporter', 'enums', 'endOfMonthUrls', 'ocConfigSvc', 'helperSvc', 'adminUrls',
                'ocSecuritySvc', 'amtCommandQuerySvc', '$timeout', 'ocDateSvc',
                function (
                    $scope, endOfMonthSvc, amtXlatSvc, WindowFactory, confirmSvc, notifySvc,
                    errorReporter, enums, endOfMonthUrls, ocConfigSvc, helperSvc, adminUrls: AdminUrls,
                    ocSecuritySvc, amtCommandQuerySvc, $timeout, ocDateSvc: OcDateSvc
                ) {
                    var vm = this;
                    $scope.vm = this;

                    vm.config = ocConfigSvc;
                    vm.svc = endOfMonthSvc;

                    vm.pageReady = false;

                    vm.endOfMonthUrls = endOfMonthUrls;
                    vm.adminUrls = adminUrls;

                    vm.attendees = null;
                    vm.selectedAttendees = [];
                    vm.attendeesControl = {};

                    this.$onInit = function () {

                        if (vm.wnd !== null) {
                            vm.wnd.onClose = vm.cancel;
                        }

                        vm.wnd.processing = true;

                        vm.data = angular.copy(vm.initParams.data);
                        vm.endOfMonthId = vm.initParams.endOfMonthId;

                        if (!vm.data) {
                            vm.data = {
                                id: null
                            };
                        }

                        vm.readonly = (!!vm.data.id && !ocSecuritySvc.isAuthorised('Security.Site.EndOfMonth', 'readWrite'));

                        vm.costLabel = amtXlatSvc.xlat('endOfMonth.knownCost', ocConfigSvc.user.site.currency.symbol);

                        vm.buttons = [
                            {
                                primary: true,
                                cancel: false,
                                value: "common.save_label",
                                name: "saveButton",
                                click: "save",
                                type: enums.buttonTypes.button
                            },
                            {
                                primary: false,
                                cancel: false,
                                value: "common.saveAndAddAnother_label",
                                name: "saveAndAddAnotherButton",
                                click: "saveAndAddAnother",
                                type: enums.buttonTypes.button
                            },
                            {
                                primary: false,
                                cancel: true,
                                value: "common.cancel_label",
                                name: "cancelButton",
                                click: "cancel",
                                type: enums.buttonTypes.button
                            }
                        ];

                        vm.buttonStates = {
                            saveButton: {
                                visible: !vm.readonly,
                                disabled: true
                            },
                            saveAndAddAnotherButton: {
                                visible: !vm.readonly && !vm.data.id,
                                disabled: true
                            },
                            cancelButton: {
                                visible: true,
                                disabled: false
                            }
                        };

                        vm.buttonMethods = {
                            save: vm.save,
                            saveAndAddAnother: vm.save,
                            cancel: vm.cancel
                        };

                        vm.getAttendees().finally(function () {
                            vm.pageReady = true;

                            $timeout(function () {
                                vm.form.$setPristine();
                                vm.wnd.processing = false;
                            });
                        });
                    };

                    vm.getAttendees = function () {

                        var criteria = {
                            systemHierarchyIds: [vm.config.user.site.id],
                            includeParentRoles: true,
                            includeChildRoles: true,
                            usersWithLoginOnly: false,
                            returnPersonLoginId: false
                        };

                        return amtCommandQuerySvc.post('userSecurity/listPeople', criteria).then(function (response) {
                            vm.attendees = response.result;
                            vm.selectedAttendees = [];

                            if (vm.data.attendees && vm.data.attendees.length > 0) {
                                for (var i = 0; i < vm.data.attendees.length; i++) {
                                    var attendee = _.find(vm.attendees, a => a.name === vm.data.attendees[i].name);

                                    if (attendee) {
                                        vm.selectedAttendees.push(attendee);
                                    }
                                }
                            }
                        }).catch(function (error) {
                            errorReporter.logError(error);
                        });
                    };

                    vm.save = function (button) {

                        var criteria = {
                            endOfMonthId: vm.endOfMonthId,
                            id: vm.data.id,
                            trainingTypeId: helperSvc.getKey(vm.data.trainingType),
                            trainingFormId: helperSvc.getKey(vm.data.trainingForm),
                            otherTrainingType: vm.data.otherTrainingType,
                            trainer: vm.data.trainer,
                            cost: vm.data.cost,
                            costCurrencyTypeId: vm.config.user.site.currency.id,
                            attendees: vm.data.attendees,
                            date: ocDateSvc.removeLocalTimeZoneOffset(vm.data.date)
                        };

                        vm.wnd.processing = true;

                        return vm.svc.saveTrainingReceivedAsync(criteria).then(function (response) {

                            notifySvc.success(amtXlatSvc.xlat('endOfMonth.trainingReceivedSaveSuccessful'));

                            if (vm.wnd.onDataChanged) {
                                vm.wnd.onDataChanged();
                            }

                            if (button === 'saveAndAddAnother') {

                                // restore the defaults
                                vm.data = {
                                    id: null
                                };

                                vm.attendeesControl.clear();

                                $timeout(function () {
                                    vm.form.$setPristine();
                                });

                            } else {
                                vm.closeWindow();
                            }

                        }).catch(function (error) {
                            errorReporter.logError(error);
                        }).finally(function () {
                            vm.wnd.processing = false;
                        });
                    };

                    vm.closeWindow = function () {
                        WindowFactory.closeWindow(vm.wnd);
                    };

                    vm.cancel = function () {
                        confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                            vm.form.dirty = false;
                            vm.closeWindow();
                            return true;
                        });

                        return false;
                    };

                    $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing'], function () {
                        vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.form.$pristine || vm.wnd.processing;
                        vm.buttonStates.saveAndAddAnotherButton.disabled = vm.form.$invalid || vm.form.$pristine || vm.wnd.processing;
                    });
                }
            ]
        });
