/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var messages_1 = require("./messages");
/**
 * Custom component messages override default component messages
 * ([see example]({% slug rtl_buttons %}).
 */
var SplitButtonCustomMessagesComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SplitButtonCustomMessagesComponent, _super);
    function SplitButtonCustomMessagesComponent(service) {
        var _this = _super.call(this) || this;
        _this.service = service;
        return _this;
    }
    SplitButtonCustomMessagesComponent_1 = SplitButtonCustomMessagesComponent;
    Object.defineProperty(SplitButtonCustomMessagesComponent.prototype, "override", {
        get: function () {
            return true;
        },
        enumerable: true,
        configurable: true
    });
    var SplitButtonCustomMessagesComponent_1;
    SplitButtonCustomMessagesComponent = SplitButtonCustomMessagesComponent_1 = tslib_1.__decorate([
        core_1.Component({
            providers: [
                {
                    provide: messages_1.Messages,
                    useExisting: core_1.forwardRef(function () { return SplitButtonCustomMessagesComponent_1; })
                }
            ],
            selector: 'kendo-splitbutton-messages',
            template: ""
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService])
    ], SplitButtonCustomMessagesComponent);
    return SplitButtonCustomMessagesComponent;
}(messages_1.Messages));
exports.SplitButtonCustomMessagesComponent = SplitButtonCustomMessagesComponent;
