//import angular from 'angular';
import AdminUrls from '../../adminUrls';
import HelperSvc from '../../../../services/helperSvc';
import tmpl from './triggerPopup.html';


    angular.module('app.admin').component('triggerPopup', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['$scope', '$state', 'amtCommandQuerySvc', 'enums', 'amtXlatSvc', 'referenceDataUrls', 'helperSvc', 'adminUrls', 'componentUrls',
            'equipmentUrls', 'vehicleUrls', 'confirmSvc', 'WindowFactory', 'ocConfigSvc', '$timeout', 'notifySvc', 'ocSecuritySvc', 'fleetUrls', '$q', 'errorReporter',
            function ($scope, $state, amtCommandQuerySvc, enums, xlatSvc, referenceDataUrls, helperSvc: HelperSvc, adminUrls: AdminUrls, componentUrls,
                equipmentUrls, vehicleUrls, confirmSvc, WindowFactory: IWindowFactory, ocConfigSvc, $timeout, notifySvc, ocSecuritySvc, fleetUrls, $q, errorReporter) {
                var vm = this;
                $scope.vm = this;

                vm.apiUrls = adminUrls;
                vm.componentUrls = componentUrls;
                vm.equipmentUrls = equipmentUrls.url;
                vm.vehicleUrls = vehicleUrls;
                vm.referenceDataUrls = referenceDataUrls;
                vm.fleetUrls = fleetUrls;

                vm.config = ocConfigSvc;

                vm.fleetsControl = {};
                vm.componentTypesControl = {};
                vm.vehicleSpecificationsControl = {};
                vm.componentSpecificationsControl = {};
                vm.isTransfer = false;

                vm.alertType = null;
                vm.alertConfigurationId = null;

                vm.status = enums.screenStatus.loading;

                vm.trigger = {
                    active: true,
                    alertConfigurationValues: [],
                    referenceData: {},
                    configurationValues: {},
                    //systemHierarchyId: vm.config.user.site.id,
                    equipmentTypes: [],
                    fleets: [],
                    destinationSites: [],
                    destinationSiteIds: [],
                    componentSpecifications: [],
                    vehicleSpecifications: []
                };

                vm.extensionParams = {
                    statusType: {
                        componentTypeId: null,
                        preferReferenceDataIdAsKey: true
                    },
                    axleLocation: {
                        preferReferenceDataIdAsKey: true
                    },
                    vehicleApplication: {
                        preferReferenceDataIdAsKey: true
                    },
                    equipmentLifeType: {
                        preferReferenceDataIdAsKey: true
                    }
                };

                vm.goToFieldSurveyFrequency = function () {
                    $state.go('app.admin.fsFrequency');
                };

                vm.tabLevels = [];
                vm.escalationLevels = 1;

                vm.$onInit = function () {

                    if (vm.wnd !== null) {
                        vm.alertConfigurationId = vm.initParams.alertConfigurationId;
                        vm.alertType = vm.initParams.alertType;
                        vm.isEdit = vm.initParams.isEdit;
                    }

                    // security
                    vm.canAdd = ocSecuritySvc.isAuthorised('Security.Settings.AlertsNotifications.Triggers.Add', 'readWrite');
                    vm.readonly = (vm.isEdit && !ocSecuritySvc.isAuthorised('Security.Settings.AlertsNotifications.Triggers', 'readWrite')) || (!vm.isEdit && !vm.canAdd);
                    vm.canViewEffectiveRecipients = ocSecuritySvc.isAuthorised('Security.Settings.Users.Roles', 'readOnly') &&
                                                    ocSecuritySvc.isAuthorised('Security.Settings.Users.Subscriptions', 'readOnly') &&
                                                    ocSecuritySvc.isAuthorised('Security.Settings.SiteDetails.Details', 'readOnly');

                    vm.wnd.onClose = vm.onClose;

                    vm.escalationLevels = vm.alertType.isAlert ? 3 : 1;

                    for (var i: number = 1; i <= vm.escalationLevels; ++i) {
                        vm.tabLevels.push({
                            escalationPeriodInDays: i == 1 ? 0 : void 0,
                            minEscalationPeriod: i == 1 ? 0 : 1,
                            persons: [],
                            roles: []
                        });
                    }

                    if (vm.alertType.alertConfigurationValueTypes !== null && vm.alertType.alertConfigurationValueTypes.length > 0) {
                        vm.alertType.alertConfigurationValueTypes.forEach(function (t) {

                            if (t.readingUnits !== null && t.readingUnits.length > 0) {
                                var defaultReadingUnit = t.readingUnits.filter(function (f) { return f.default === true; })[0];

                                vm.trigger.configurationValues[t.id] = {
                                    readingUnit: defaultReadingUnit
                                };
                            }
                        });
                    }

                    if (vm.alertType.alertTypeConfigurationTypes !== null && vm.alertType.alertTypeConfigurationTypes.length > 0) {
                        vm.alertType.alertTypeConfigurationTypes.forEach(function (t) {

                            vm.trigger.referenceData[t.id] = [];

                            t.control = {};

                            switch (t.type) {
                                case "StatusType":
                                    t.url = vm.componentUrls.getStatuses;
                                    t.extensionParams = vm.extensionParams.statusType;
                                    t.descriptor = xlatSvc.xlat("equipment.statuses");
                                    t.options = undefined;
                                    break;
                                case "AxleLocation":
                                    t.url = vm.referenceDataUrls.getAxleLocations;
                                    t.extensionParams = vm.extensionParams.axleLocation;
                                    t.options = undefined;
                                    t.descriptor = xlatSvc.xlat("notifications.allAxleLocations");
                                    break;
                                case "EquipmentLifeType":
                                    t.url = vm.referenceDataUrls.getEquipmentLifeTypes;
                                    t.extensionParams = vm.extensionParams.equipmentLifeType;
                                    t.descriptor = xlatSvc.xlat("notifications.allLifeTypes");
                                    t.options = undefined;
                                    break;

                                case "VehicleApplication":
                                    t.url = vm.fleetUrls.getApplications;
                                    t.extensionParams = vm.extensionParams.vehicleApplication;
                                    t.descriptor = xlatSvc.xlat("notifications.allVehicleApplications");
                                    t.options = undefined;
                                    break;
                                default:
                                    t.url = undefined;
                                    break;
                            }
                        });
                    }

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: 'common.save_label',
                            name: 'saveButton',
                            click: 'save',
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: true,
                            value: 'common.cancel_label',
                            name: 'cancelButton',
                            click: 'cancel',
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: true,
                            disabled: true
                        },
                        cancelButton: {
                            visible: true,
                            disabled: false
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.saveTrigger,
                        cancel: vm.onClose
                    };

                    vm.wnd.processing = true;

                    var loadPromise = (vm.alertConfigurationId != null ? vm.loadDetails() : $q.resolve());

                    return loadPromise.then(function () {

                        return vm.loadReferenceData().then(function () {

                            if (vm.alertConfigurationId !== null) {

                                vm.loadSubscriptions();

                            } else {

                                if ((!vm.trigger.equipmentTypeIds || vm.trigger.equipmentTypeIds.length === 0) && vm.alertType.defaultEquipmentTypeIds) {
                                    vm.trigger.equipmentTypeIds = vm.alertType.defaultEquipmentTypeIds;
                                }
                            }

                            vm.selectTab('details');
                            vm.status = enums.screenStatus.ready;
                        });

                    }).finally(function () {
                        vm.wnd.processing = false;
                    });
                };

                vm.valueChange = function (cfgType) {
                    switch (cfgType.name) {
                        case "Destination Status":
                            var values = vm.trigger.referenceData[cfgType.id];

                            var transferCount = 0;

                            if (values !== null) {
                                for (var v = 0; v < values.length; v++) {
                                    if (values[v].name.toUpperCase().indexOf("TRANSFER") > -1) {
                                        transferCount++;
                                    }
                                }
                            }

                            vm.isTransfer = transferCount > 0;

                            break;
                    }
                };

                vm.loadReferenceData = function () {

                    var defer = $q.defer();

                    $timeout(function () {
                        if (vm.alertConfigurationId !== null) {

                            var searchPromises = [];

                            if (vm.fleetsControl && vm.fleetsControl.search && !vm.trigger.fleets) {
                                searchPromises.push(vm.fleetsControl.search());
                            }

                            if (vm.componentTypesControl && vm.componentTypesControl.search && !vm.trigger.equipmentTypes) {
                                searchPromises.push(vm.componentTypesControl.search());
                            }

                            if (vm.vehicleSpecificationsControl && vm.vehicleSpecificationsControl.search && !vm.trigger.vehicleSpecifications) {
                                searchPromises.push(vm.vehicleSpecificationsControl.search());
                            }

                            if (vm.componentSpecificationsControl && vm.componentSpecificationsControl.search && !vm.trigger.componentSpecifications) {
                                searchPromises.push(vm.componentSpecificationsControl.search());
                            }

                            if (vm.alertType.alertTypeConfigurationTypes != null && vm.alertType.alertTypeConfigurationTypes.length > 0) {
                                for (var i = 0; i < vm.alertType.alertTypeConfigurationTypes.length; i++) {
                                    if (vm.alertType.alertTypeConfigurationTypes[i].control && vm.alertType.alertTypeConfigurationTypes[i].control.search
                                        && !vm.trigger.referenceData[vm.alertType.alertTypeConfigurationTypes[i].id]) {
                                        searchPromises.push(vm.alertType.alertTypeConfigurationTypes[i].control.search());
                                    }
                                }
                            }

                            if (searchPromises.length > 0) {
                                $q.all(searchPromises).then(function () {
                                    defer.resolve();
                                });
                            } else {
                                defer.resolve();
                            }
                        } else {
                            defer.resolve();
                        }
                    });

                    return defer.promise;
                };


                vm.updateMinEscalationPeriod = function () {
                    //kind of hacky code to set the min value for each level, it should be one day more than the previous level if that is set and valid itself
                    //but this tries to set a valid minimum for each tab even if the previous level isn't set correctly, minimising the validation errors from propogating needlessly
                    let cumulativeMin = 0;
                    for (let i = 0; i < vm.tabLevels.length; ++i) {
                        if (i <= 1) {
                            cumulativeMin = i; //evil magic for the first two levels, so that the first unseen escalation period need not be set to zero for correct function
                        } else {
                            //assumes minFromPrevLevel maybe be NaN (if field is blank or whitespace) then the comparision is false and cumulativeMin won't be updated
                            let minFromPrevLevel = Number(vm.tabLevels[i - 1].escalationPeriodInDays) + 1;
                            if (minFromPrevLevel > cumulativeMin) {
                                cumulativeMin = minFromPrevLevel;
                            }
                        }

                        vm.tabLevels[i].minEscalationPeriod = cumulativeMin;
                    }
                };

                vm.selectTab = function (tabObject) {
                    vm.currentTab = tabObject;
                    vm.updateMinEscalationPeriod();
                };

                vm.showEffectiveRecipients = function (level: Number) {
                    WindowFactory.openItem({
                        component: "trigger-popup-effective-recipients",
                        initParams: { levels: vm.tabLevels.map(a => ({ personIds: a.persons.map(t => t.key), roleIds: a.roles.map(t => t.key)})), level: level },
                        parentWindow: vm.wnd,
                        width: 900,
                        modal: true,
                        canClose: true,
                    });
                }

                $scope.$watch("vm.trigger.equipmentTypes[0]", function (newValue, oldValue) {
                    if (newValue === oldValue) {
                        return;
                    }
                    vm.extensionParams.statusType.componentTypeId = helperSvc.getKey(vm.trigger.equipmentTypes[0]);
                });

                function closeWindow() {
                    WindowFactory.closeWindow(vm.wnd);
                }

                vm.updateWndDirty = function () {
                    vm.wnd.isDirty = (vm.parentForm && vm.parentForm.$dirty);
                }

                vm.onClose = function () {
                    vm.updateWndDirty();

                    confirmSvc.confirmSaveChange(vm.wnd.isDirty).then(function () {
                        try {
                            vm.detailsForm.$dirty = false;
                            vm.parentForm.$dirty = false;
                        } catch (ex) {
                            closeWindow();
                        }

                        closeWindow();
                        return true;
                    });

                    return false;
                };

                vm.loadDetails = function () {

                    var criteria = {
                        id: vm.alertConfigurationId
                    };

                    return amtCommandQuerySvc.post(vm.apiUrls.getAlertTrigger, criteria).then(function (response) {
                        if (response) {

                            vm.trigger = response;

                            if ((!vm.trigger.equipmentTypeIds || vm.trigger.equipmentTypeIds.length === 0) && vm.alertType.defaultEquipmentTypeIds) {
                                vm.trigger.equipmentTypeIds = vm.alertType.defaultEquipmentTypeIds;
                            }

                            $timeout(function () {
                                if (vm.detailsForm) {
                                    vm.detailsForm.$setPristine();
                                }
                            }, 100);

                            vm.status = enums.screenStatus.ready;
                        }
                    }, function (error) {
                        vm.status = enums.screenStatus.error;
                        errorReporter.logError(error);
                    });
                };

                vm.loadSubscriptions = function () {

                    var criteria = {
                        alertConfigurationId: vm.alertConfigurationId
                    };

                    amtCommandQuerySvc.post(vm.apiUrls.listAlertSubscriptions, criteria).then(function (response) {
                        if (!response)
                            return;

                        vm.tabLevels = response.slice(0, vm.escalationLevels); //server impl is lazy (currently) and might send more levels than we want tabs shown for

                        $timeout(() => {
                            if (vm.detailsForm && vm.detailsForm.$pristine && vm.parentForm) {
                                vm.parentForm.$setPristine();
                            }

                            vm.updateMinEscalationPeriod();
                        });

                    }, function (error) {
                        vm.status = enums.screenStatus.error;
                        errorReporter.logError(error);
                    });
                };

                vm.saveTrigger = function () {
                    //validation for interdependancies between tabs
                    vm.updateMinEscalationPeriod();

                    $timeout(() => {
                        //check if forms are invalid since they clicked save, this is a hack
                        if (vm.parentForm.$invalid) {
                            notifySvc.error(xlatSvc.xlat("exception.notifications_validationFailedNoSave"));
                            return;
                        }

                        if (!vm.parentForm || vm.parentForm.$pristine)
                            return;

                        vm.save();

                    })
                };

                vm.save = function () {

                    var referenceData = [];
                    var configurationValues = [];

                    if (vm.trigger.referenceDataIds !== null || vm.trigger.referenceData !== null) {
                        var refKeys = (vm.trigger.referenceDataIds !== null && vm.trigger.referenceDataIds !== undefined ? Object.keys(vm.trigger.referenceDataIds) : Object.keys(vm.trigger.referenceData));
                        refKeys.forEach(function (k) {
                            var defaultAlertTypeConfigurationType = vm.alertType.alertTypeConfigurationTypes.filter(function (a) {
                                return a.id === k;
                            })[0];

                            referenceData.push({ alertTypeConfigurationTypeId: k, referenceDataIds: (defaultAlertTypeConfigurationType.selectionType === 'Multi' ? vm.trigger.referenceDataIds[k] : helperSvc.getKeys(vm.trigger.referenceData[k])) });
                        });
                    }

                    if (vm.trigger.configurationValues !== null) {

                        var configKeys = Object.keys(vm.trigger.configurationValues);

                        // validate
                        var percentLowValueType = vm.alertType.alertConfigurationValueTypes.filter(function (v) { return v.name === 'ColdInflationPressurePercentLow'; })[0];
                        var percentHighValueType = vm.alertType.alertConfigurationValueTypes.filter(function (v) { return v.name === 'ColdInflationPressurePercentHigh'; })[0];

                        var percentLow = (percentLowValueType !== null && percentLowValueType !== undefined ? vm.trigger.configurationValues[percentLowValueType.id] : null);
                        var percentHigh = (percentHighValueType !== null && percentHighValueType !== undefined ? vm.trigger.configurationValues[percentHighValueType.id] : null);

                        if (percentLow !== null && percentHigh !== null) {
                            if (parseInt(percentLow.value) > parseInt(percentHigh.value)) {
                                console.error("exception.notifications_percentLowCannotBeGreaterThanPercentHigh");
                                notifySvc.error(xlatSvc.xlat("exception.notifications_percentLowCannotBeGreaterThanPercentHigh"));
                                return;
                            }
                        }

                        // end validation                        

                        configKeys.forEach(function (k) {
                            var alertConfigurationValueType = vm.alertType.alertConfigurationValueTypes.filter(function (a) {
                                return a.id === k;
                            })[0];

                            configurationValues.push({
                                alertConfigurationValueTypeId: k,
                                value: vm.trigger.configurationValues[k].value,
                                unitOfMeasureId: (vm.trigger.configurationValues[k].readingUnit !== null ? helperSvc.getKey(vm.trigger.configurationValues[k].readingUnit) : (alertConfigurationValueType.readingUnits !== null ? helperSvc.getKey(alertConfigurationValueType.readingUnits[0]) : null))
                            });
                        });
                    }

                    vm.wnd.processing = true;

                    var saveCriteria = {
                        id: (vm.isEdit ? vm.alertConfigurationId : null),
                        isEdit: vm.isEdit,
                        //systemHierarchyId: vm.alertType.systemHierarchyId,
                        alertTypeId: vm.alertType.id,
                        name: vm.trigger.name,
                        equipmentTypes: (vm.alertType.componentTypeOption === 'many' ? vm.trigger.equipmentTypeIds : helperSvc.getKeys(vm.trigger.equipmentTypes)),
                        fleets: (vm.alertType.fleetOption === 'many' ? vm.trigger.fleetIds : helperSvc.getKeys(vm.trigger.fleets)),
                        destinationSiteIds: (vm.alertType.destinationSiteOption === 'many'
                            ? vm.trigger.destinationSiteIds
                            : helperSvc.getKeys(vm.trigger.destinationSites)
                        ),
                        componentSpecifications: (vm.alertType.componentSpecificationOption === 'many' ? vm.trigger.componentSpecificationIds : helperSvc.getKeys(vm.trigger.componentSpecifications)),
                        vehicleSpecifications: (vm.alertType.vehicleSpecificationOption === 'many' ? vm.trigger.vehicleSpecificationIds : helperSvc.getKeys(vm.trigger.vehicleSpecifications)),
                        active: vm.trigger.active,
                        referenceData: referenceData,
                        configurationValues: configurationValues
                    };

                    amtCommandQuerySvc.post(vm.apiUrls.addModifyAlertTrigger, saveCriteria).then(function (response) {
                        vm.detailsForm.$setPristine();

                        if (vm.alertConfigurationId == null) {
                            vm.isEdit = true;
                            vm.alertConfigurationId = response.data.alertConfigurationId;
                        }

                        var saveCriteria = {
                            alertConfigurationId: vm.alertConfigurationId,
                            subscriptions: vm.tabLevels.map((t) => ({
                                persons: t.personIds,
                                roles: t.roleIds,
                                escalationPeriodInDays: Number(t.escalationPeriodInDays)
                            }))
                        };

                        return amtCommandQuerySvc.post(vm.apiUrls.updateAlertSubscriptions, saveCriteria).then(function (response) {
                            notifySvc.success(vm.isEdit
                                ? xlatSvc.xlat("notifications.updateSuccessful")
                                : xlatSvc.xlat("notifications.addSuccessful"));

                            if (vm.parentForm) {
                                vm.parentForm.$setPristine();
                            }
                        });

                    }).catch(function (error) {
                        errorReporter.logError(error);
                    }).finally(function () {
                        vm.wnd.processing = false;
                    });
                };

                $scope.$watchGroup(['vm.parentForm.$pristine', 'vm.parentForm.$invalid', 'vm.wnd.processing'],
                    function (newVals) {
                        vm.buttonStates.saveButton.disabled = newVals.some(v => v);
                    }
                );

                $scope.$watch('vm.parentForm.$dirty', function () {
                    vm.updateWndDirty();
                });

                $scope.$watch('vm.readonly', function () {
                    vm.buttonStates.saveButton.visible = !vm.readonly;
                });
            }
        ]
    });
