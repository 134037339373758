//import angular from 'angular';


angular.module('app')
        .service('amtCommandQuerySvc', ['$http', '$q', '$injector', 'enums', 'notifySvc', function ($http, $q, $injector, enums, notifySvc) {
            var promise;

            function postWithConfig(url, contract, config) {
                promise = $http.post(baseApiUrl + url, contract, config).then(function (response) {
                    return response;
                }, function (response) {
                    handleException(response);
                    return $q.reject(response.data);
                });
                return promise;
            }

            function getWithConfig(url, config) {
                promise = $http.get(baseApiUrl + url, config).then(function (response) {
                    return response;
                }, function (response) {
                    handleException(response);
                    return $q.reject(response.data);
                });
                return promise;
            }

            function handleError(error, detail, suppressUI) {
                // redirect to errorReporter               
                var errorReporter = $injector.get('errorReporter');

                return errorReporter.logError(error, detail, suppressUI);
            }

            function handleException(response, suppressErrorLogging = false) {
                handleProtocolError(response, true, suppressErrorLogging);
            }

            function handleProtocolError(response, suppressUI, suppressErrorLogging = false) {

                try {

                    var errorReporter = $injector.get('errorReporter');
                    var WindowFactory = $injector.get('WindowFactory');

                    if (!response) {
                        response = {
                            status: -1,
                            message: "http request response was empty."
                        };
                    };

                    if (response.status === -1 && !suppressUI) {
                        WindowFactory.alert('exception.ActionOfflineHeader', ['common.ok_label'], 'exception.ActionOffline');
                    }
                    else {
                        throw response;
                    }

                } catch (ex) {
                    errorReporter.logError(ex, 'amtCommandQuerySvc', suppressUI, suppressErrorLogging);
                    response.preventLogException = true;
                }

                return $q.resolve(); // if your calling this then no bubbled error
            }

            function get(url, contract?) {

                if (!url) {
                    throw new Error('amtCommandQuery called with no url');
                }

                let httpTimeout = $q.defer();

                if (contract && !contract.params) {
                    contract = {
                        params: contract,
                        timeout: httpTimeout.promise
                    }
                }

                promise = $http.get(baseApiUrl + url, contract).then(function (response) {

                    if (response.status === 200) {

                        if (response.data && response.data.status === enums.httpUpdateStatus.fail) {

                            if (!response.data.exception || !response.data.exception.SupressClientNotification) {
                                notifySvc.error(response.data.errors[0].message);
                            }

                            var error = {
                                key: response.data.errors[0].key,
                                message: response.data.errors[0].message,
                                preventLogException: true,
                                param: (response.data.exception ? response.data.exception.param : null)
                            };

                            return $q.reject(error);
                        } else {
                            return response;
                        }
                    }
                    else {
                        handleException(response);
                        return $q.reject(response);
                    }
                }, function (response) {
                    handleException(response);
                    return $q.reject(response);
                });

                promise._httpTimeout = httpTimeout;

                return promise;
            }

            function getById(url, id) {
                if (!url) {
                    throw new Error('amtCommandQuery called with no url')
                }
                if (!id) {
                    console.error("No ID supplied");
                    return $q.reject("No Id Supplied");
                }
                if (!url) {
                    console.error("No URL supplied");
                    return $q.reject("No Url Supplied");
                }
                id = id || "";
                promise = $http.get(baseApiUrl + url + '/' + id).then(function (response) {
                    if (response.status === 200) {

                        if (response.data && response.data.status === enums.httpUpdateStatus.fail) {

                            if (!response.data.exception || !response.data.exception.SupressClientNotification) {
                                notifySvc.error(response.data.errors[0].message);
                            }

                            response.data.errors[0].preventLogException = true;
                            return $q.reject(response.data.errors[0]);
                        } else {
                            return response.data;
                        }

                    }
                    else {
                        handleException(response);
                        return $q.reject(response);
                    }
                }, function (response) {
                    handleException(response);
                    return $q.reject(response);
                });
                return promise;
            }

            function getAttachment(url, id) {

                if (!id) {
                    console.error("No ID supplied");
                    return $q.reject("No Id Supplied");
                }

                if (!url) {
                    console.error("No URL supplied");
                    return $q.reject("No Url Supplied");
                }

                id = id || "";

                promise = $http.get(url + '/' + id, { responseType: 'arraybuffer' }).then(function (response: ng.IHttpResponse<any>) {

                    if (response.data) {
                        if (response.status === 200) {

                            if (response.data.status === enums.httpUpdateStatus.fail) {

                                if (!response.data.exception || !response.data.exception.SupressClientNotification) {
                                    notifySvc.error(response.data.errors[0].message);
                                }

                                response.data.errors[0].preventLogException = true;
                                return $q.reject(response.data.errors[0]);
                            } else {
                                //adapted from https://stackoverflow.com/a/62173022
                                //TODO: this will truncate filenames containing a semicolon

                                let contentDisposition = response.headers('Content-Disposition');

                                let filename = contentDisposition ? response.headers('Content-Disposition').split('filename=')[1].split(';')[0] : 'file';

                                // if there are quotes around the name, trim them off
                                if (filename[0] == "\"")
                                    filename = filename.slice(1, filename.length - 1);

                                return new File([response.data], filename);
                            }
                        } else {
                            // response is an arraybuffer, so turn it into a JSON object                        
                            let data = arrayBufferToJSON(response.data);

                            if (data.errors && data.errors.length) {
                                if (!data.exception || !data.exception.SupressClientNotification)
                                    notifySvc.error(data.errors[0].message);

                                data.errors[0].preventLogException = true;

                                return $q.reject(data.errors[0]);
                            } else {
                                return $q.reject();
                            }
                        }
                    }

                }, function (response) {
                    handleException(response);
                    return $q.reject(response);
                });

                return promise;
            }

            function arrayBufferToJSON(buffer: ArrayBuffer) {
                let str = '';

                let arr = new Uint8Array(buffer);

                for (let i = 0; i < arr.length; i++)
                    str += String.fromCharCode(arr[i]);

                return JSON.parse(str);
            }

            function post(url, contract, suppressErrorLogging = false) {

                if (!url) {
                    throw new Error('amtCommandQuery called with no url');
                }

                return $http.post(baseApiUrl + url, contract).then(function (response) {

                    if (response.status === 200) {

                        if (response.data && response.data.status === enums.httpUpdateStatus.fail) {

                            if (!response.data.exception || !response.data.exception.SupressClientNotification) {
                                notifySvc.error(response.data.errors[0].message);
                            }

                            var error = {
                                key: response.data.errors[0].key,
                                message: response.data.errors[0].message,
                                preventLogException: true,
                                param: (response.data.exception ? response.data.exception.param : null)
                            };

                            return $q.reject(error);

                        } else {
                            return response.data;
                        }
                    }
                    else {
                        handleException(response, suppressErrorLogging);
                        return $q.reject(response);
                    }

                }, function (response) {
                    handleException(response, suppressErrorLogging);
                    return $q.reject(response);
                });
            }

            function postWithRetry(url, contract) {
                return $q(function (resolve, reject) {
                    post(url, contract).then(
                        resolve,
                        function (err) {
                            if (confirm("Click OK to retry")) {
                                postWithRetry(url, contract).then(resolve, reject);
                            } else {
                                reject(err);
                            }
                        }
                    );
                }
                );
            }

            function getWithRetry(url, contract) {
                return $q(function (resolve, reject) {
                    get(url, contract).then(
                        resolve,
                        function (err) {
                            if (confirm("Click OK to retry")) {
                                getWithRetry(url, contract).then(resolve, reject);
                            } else {
                                reject(err);
                            }
                        }
                    );
                }
                );
            }

            function put(url, contract, id) {
                id = id || "";
                if (!url) {
                    /*jshint -W087 */
                    debugger;
                    /*jshint +W087 */
                }
                promise = $http.put(baseApiUrl + url + '/' + id, contract).then(function (response) {
                    if (response.status === 200) {
                        if (response.data && response.data.status === enums.httpUpdateStatus.fail) {

                            if (!response.data.exception || !response.data.exception.SupressClientNotification) {
                                notifySvc.error(response.data.errors[0].message);
                            }

                            response.data.errors[0].preventLogException = true;
                            return $q.reject(response.data.errors[0]);
                        } else {
                            return response.data;
                        }
                    }
                    else {
                        handleException(response);
                        return $q.reject(response);
                    }
                }, function (response) {
                    handleException(response);
                    return $q.reject(response);
                });
                return promise;
            }

            function deleteItem(url, contract) {
                if (!url) {
                    /*jshint -W087 */
                    debugger;
                    /*jshint +W087 */
                }
                var promise = $http.delete(baseApiUrl + url, { params: contract }).then(function (response) {
                    if (response.status === 200) {
                        if (response.data && response.data.status === enums.httpUpdateStatus.fail) {

                            if (!response.data.exception || !response.data.exception.SupressClientNotification) {
                                notifySvc.error(response.data.errors[0].message);
                            }

                            response.data.errors[0].preventLogException = true;
                            return $q.reject(response.data.errors[0]);
                        } else {
                            return response.data;
                        }
                    }
                    else {
                        handleException(response);
                        return $q.reject(response);
                    }
                }, function (response) {
                    handleException(response);
                    return $q.reject(response);
                });
                return promise;
            }

            var svc = {
                get: get,
                getWithRetry: getWithRetry,
                getById: getById,
                getAttachment: getAttachment,
                post: post,
                postWithRetry: postWithRetry,
                put: put,
                deleteItem: deleteItem,
                //handleProtocolError: handleProtocolError, //I don't think this needs to be exposed
                postWithConfig: postWithConfig,
                handleError: handleError,
                getWithConfig: getWithConfig
            };
            return svc;
        }
        ]);
