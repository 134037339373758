//import angular from 'angular';


    angular.module('app').factory(
        'FleetFactory', [
            'amtXlatSvc', 'amtCommandQuerySvc', 'enums',
            'exportSvc', 'notifySvc', 'WindowFactory',
            function (
                amtXlatSvc, amtCommandQuerySvc, enums,
                exportSvc, notifySvc, WindowFactory: IWindowFactory
            ) {
                // concrete class
                function Fleet() {
                    this.state = enums.objectState.created;

                    this.open = function (mode, onDataChangeFn) {
                        WindowFactory.openItem({
                            component: 'fleet-details',
                            caption: amtXlatSvc.xlat('fleet.' + mode + 'Fleet') + (this.fleetName === undefined ? '' : ' - ' + this.fleetName),
                            initParams: {
                                fleet: this,
                                mode: mode
                            },
                            width: 760,
                            modal: false,
                            onDataChangeHandler: onDataChangeFn
                        });
                    };

                    this.populate = function (result) {
                        this.id = result.id;
                        this.client = result.client;
                        this.site = result.site;
                        this.fleetName = result.fleetName;
                        this.typeId = result.fleetTypeId;
                        this.site = result.site;
                        this.active = result.active;
                        this.fleetType = result.fleetType;
                        this.sequence = result.sequence;
                        this.active = result.active;
                        this.canDelete = result.canDelete;
                        this.batchCount = result.batchCount;
                        this.fieldSurveyCount = result.fieldSurveyCount;
                        this.alertConfigurationCount = result.alertConfigurationCount;
                    };

                    this.delete = function (callbackFn) {
                        var fleet = this;

                        return amtCommandQuerySvc.post('admin/fleet/delete', { id: fleet.id }).then(function () {

                            notifySvc.success(amtXlatSvc.xlat("fleet.deleteSuccess"));

                            if (callbackFn) {
                                callbackFn();
                            }

                        }, amtCommandQuerySvc.handleError);
                    };

                    function save(fleet) {
                        amtCommandQuerySvc.post('admin/fleet/save', fleet).then(function () {
                            fleet.state = enums.objectState.saved;
                        }, amtCommandQuerySvc.handleError);
                    }
                }

                this.constructCannotDeleteMessage = function (fleet) {
                    var msg = amtXlatSvc.xlat('fleet.cannotDelete_message', fleet.fleetName);

                    msg += '<p />';
                                        
                    if (fleet.alertConfigurationCount > 0) {
                        msg += '<p>';
                        msg += amtXlatSvc.xlat('fleet.cannotDelete_alertConfiguration', fleet.alertConfigurationCount);
                        msg += '</p>';
                    }

                    if (fleet.batchCount > 0) {
                        msg += '<p>';
                        msg += amtXlatSvc.xlat('fleet.cannotDelete_batch', fleet.batchCount);
                        msg += '</p>';
                    }

                    if (fleet.fieldSurveyCount > 0) {
                        msg += '<p>';
                        msg += amtXlatSvc.xlat('fleet.cannotDelete_fieldSurvey', fleet.fieldSurveyCount);
                        msg += '</p>';
                    }

                    return msg;
                };

                // reference data
                this.fleetTypes = undefined;
                this.listFleetTypes = function (callbackFn) {
                    if (this.fleetTypes !== undefined) {
                        callbackFn(this.fleetTypes);
                        return;
                    }

                    var criteria = { searchText: "" };

                    amtCommandQuerySvc.post('admin/fleet/getFleetTypes', criteria).then(function (response) {
                        callbackFn(response.result);
                    }, amtCommandQuerySvc.handleError);
                };

                // static functions
                this.rearrange = function (onDataChanged) {
                    WindowFactory.openItem({
                        component: 'fleet-order',
                        //type: 'fleet-order',
                        caption: amtXlatSvc.xlat('fleet.rearrange'),
                        initParams: {
                        },
                        width: 650,
                        onDataChangeHandler: onDataChanged
                    });
                };

                this.export = function (criteria) {
                    exportSvc.exportData('admin/fleet/searchExport', criteria, amtXlatSvc.xlat('fleet.searchExportFilename'));
                };

                this.search = function (criteria, onDataBound) {
                    // since all results come down in one query we can client side sort.
                    return amtCommandQuerySvc.post('admin/fleet/search', criteria).then(function (response) {

                        var results = response.result.map(function (r) {
                            var f = new Fleet();
                            f.populate(r);
                            return f;
                        });

                        results.sort(function (a, b) {
                            if (a.fleetName.toLowerCase() < b.fleetName.toLowerCase())
                                return -1;
                            if (a.fleetName.toLowerCase() > b.fleetName.toLowerCase())
                                return 1;
                            return 0;
                        });

                        if (onDataBound) {
                            onDataBound();
                        }

                        return results;

                    }, amtCommandQuerySvc.handleError);
                };

                this.open = function (item) {
                    var fleet = new Fleet();

                    fleet.id = item.initParams.id;
                    fleet.fleetName = item.initParams.name;

                    fleet.open(item.initParams.mode, item.onDataChangeHandler);
                };

                this.create = function () {
                    return new Fleet();
                };

                this.load = function (id) {
                    var fleet = new Fleet();

                    fleet.state = enums.objectState.loading;

                    amtCommandQuerySvc.post('admin/fleet/getDetails', { id: id }).then(function (response) {
                        fleet.populate(response);
                        fleet.state = enums.objectState.loaded;
                    }, amtCommandQuerySvc.handleError);

                    return fleet;
                };

                // static data
                var searchCriteria = {
                    filterValues: {
                        fleetName: ""
                    },
                    forExport: true,
                    group: [],
                    page: 1,
                    pageSize: 10,
                    skip: 0,
                    take: 10
                };

                // public interface
                return {
                    load: this.load,
                    open: this.open,
                    rearrange: this.rearrange,
                    listFleetTypes: this.listFleetTypes,
                    export: this.export,
                    searchCriteria: searchCriteria,
                    search: this.search,
                    create: this.create,
                    constructCannotDeleteMessage: this.constructCannotDeleteMessage
                };
            }
        ]);
