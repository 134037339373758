/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Represents the column edit-cell template of the Grid ([see example]({% slug editing_template_forms_grid %})).
 * Helps to customize the content of the edited cells. To define the cell template, nest an `<ng-template>`
 * tag with the `kendoGridEditTemplate` directive inside a `<kendo-grid-column>` tag.
 *
 * The template context is set to the current form group and the following additional fields are passed:
 * - `formGroup`&mdash;The current [FormGroup]({{ site.data.urls.angular['formgroupapi'] }}).
 * Represents the default context that will be assigned to any template variable which utilizes the `let-x` syntax&mdash;for example, `let-formGroup`.
 * If you use the Grid inside [Template-Driven Forms]({{ site.data.urls.angular['forms'] }}), it will be `undefined`.
 * - `rowIndex`&mdash;The current data row index. If inside a new item row, `rowIndex` is `-1`. Use it as an alias for a template variable by utilizing the `let-rowIndex="rowIndex"` syntax.
 * - `dataItem`&mdash;The current data item. Use it as an alias for a template variable by utilizing the `let-dataItem="dataItem"` syntax.
 * - `column`&mdash;The current column instance. Use it as an alias for a template variable by utilizing the `let-column="column"` syntax.
 * - `isNew`&mdash;The state of the current item. Use it as an alias for a template variable by utilizing the `let-isNew="isNew"` syntax.
 */
var EditTemplateDirective = /** @class */ (function () {
    function EditTemplateDirective(templateRef) {
        this.templateRef = templateRef;
    }
    EditTemplateDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoGridEditTemplate]'
        }),
        tslib_1.__param(0, core_1.Optional()),
        tslib_1.__metadata("design:paramtypes", [core_1.TemplateRef])
    ], EditTemplateDirective);
    return EditTemplateDirective;
}());
exports.EditTemplateDirective = EditTemplateDirective;
