//import angular from 'angular';

    angular.module('app.directives').directive('ocChartPoint', function () {
        return {
            replace: true,
            scope: {
                config: "="
            },
            controllerAs: 'vm',
            template:
                '<g ng-class="[\'chartPoint\', {projected: config.projected }, {\'adjusted\': config.adjusted }, {\'new-meter-true\': config.isNewMeter} ]">' +
                '    <circle ng-attr-cx="{{config.x}}" ng-attr-cy="{{config.y}}" ng-attr-r="{{ config.projected ? 2.5 : 3.5 }}" ng-click="config.onClick()" ng-mouseenter="config.hoverStart()" ng-mouseleave="config.hoverEnd()" />' +
                '</g>'
        };
    });
