/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var constants_1 = require("./constants");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var core_1 = require("@angular/core");
/**
 * @hidden
 */
var ContrastValidationComponent = /** @class */ (function () {
    function ContrastValidationComponent(localization) {
        this.localization = localization;
    }
    Object.defineProperty(ContrastValidationComponent.prototype, "passMessage", {
        get: function () {
            return this.localization.get('passContrast');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContrastValidationComponent.prototype, "failMessage", {
        get: function () {
            return this.localization.get('failContrast');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContrastValidationComponent.prototype, "contrastText", {
        get: function () {
            var ratio = this.type === 'AA' ? constants_1.AA_RATIO : constants_1.AAA_RATIO;
            return this.type + ": " + ratio.toFixed(1);
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ContrastValidationComponent.prototype, "type", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], ContrastValidationComponent.prototype, "pass", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ContrastValidationComponent.prototype, "value", void 0);
    ContrastValidationComponent = tslib_1.__decorate([
        core_1.Component({
            selector: '[kendoContrastValidation]',
            template: "\n        <span>{{contrastText}}</span>\n        <ng-container *ngIf=\"value\">\n            <span class=\"k-contrast-validation k-text-success\" *ngIf=\"pass\">\n                {{passMessage}}\n                <span class=\"k-icon k-i-check\"></span>\n            </span>\n            <span class=\"k-contrast-validation k-text-error\" *ngIf=\"!pass\">\n                {{failMessage}}\n                <span class=\"k-icon k-i-close\"></span>\n            </span>\n        </ng-container>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService])
    ], ContrastValidationComponent);
    return ContrastValidationComponent;
}());
exports.ContrastValidationComponent = ContrastValidationComponent;
