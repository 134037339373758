import { defer, from, Subscription } from 'rxjs';
import { finalize, first } from 'rxjs/operators';
import { ISetVisualInspectionDamageCauseCriteria, IVisualInspectionCause, IVisualInspectionCause_InitParams } from '../../../../interfaces/visualInspection.interfaces';
import { VisualInspectionReferenceService } from '../../../../services/visual-inspection-reference.service';
import { VisualInspectionService } from '../../../../services/visual-inspection.service';
import tmpl from './visual-inspection-cause.component.html';

export default class VisualInspectionCauseCtrl implements ng.IController, IWindowComponent<IVisualInspectionCause_InitParams> {

    wnd: IWindowObj<IVisualInspectionCause_InitParams>;
    form: ng.IFormController;

    visualInspectionCauses: IVisualInspectionCause[];

    selectedCause: IVisualInspectionCause;
    visualInspectionCausesSub: Subscription;

    static $inject = ['$scope', 'visualInspectionReferenceService', 'visualInspectionService', 'WindowFactory', 'confirmSvc'];

    constructor(private $scope: ng.IScope, private visualInspectionReferenceService: VisualInspectionReferenceService, private visualInspectionService: VisualInspectionService,
        private WindowFactory: IWindowFactory, private confirmSvc: IConfirmSvc) {

        this.$scope.$watchGroup([() => this.form.$pristine, () => this.form.$invalid, () => this.wnd.processing], () => {
            this.enableDisableSave();            
        });
    }

    $onInit() {

        this.wnd.onClose = () => this.cancel();

        this.WindowFactory.addButton(this.wnd, 'common.save_label', 'saveButton', () => this.save(), true, true, true);
        this.WindowFactory.addButton(this.wnd, 'common.cancel_label', 'cancelButton', () => this.cancel());        

        this.visualInspectionCausesSub = this.visualInspectionReferenceService.getCausesByEquipmentTypeId$(this.wnd.initParams.equipmentTypeId)
            .subscribe(causes => {
                this.visualInspectionCauses = causes;

                if (this.wnd.initParams.causeId)
                    this.selectedCause = this.visualInspectionCauses.find(c => c.id === this.wnd.initParams.causeId);
            });
    }

    save() {
        defer(() => {
            this.wnd.processing = true;

            let criteria: ISetVisualInspectionDamageCauseCriteria = {
                visualInspectionId: this.wnd.initParams.visualInspectionId,                
                causeId: this.selectedCause.id
            };

            return this.visualInspectionService.setVisualInspectionDamageCause(criteria);
        }).pipe(
            first(),
            finalize(() => this.wnd.processing = false)
        ).subscribe(
            success => {
                if (success) {
                    if (this.wnd.onDataChanged)
                        this.wnd.onDataChanged();

                    this.WindowFactory.closeWindow(this.wnd);
                }
            }
        );
    }

    cancel() {
        from(this.confirmSvc.confirmSaveChange(this.form.$dirty))
            .pipe(first())
            .subscribe(() => this.WindowFactory.closeWindow(this.wnd));
    }

    private enableDisableSave() {
        if (this.wnd.buttonStates?.saveButton)
            this.wnd.buttonStates.saveButton.disabled = this.form.$pristine || this.form.$invalid || this.wnd.processing;
    }

    $onDestroy() {
        this.visualInspectionCausesSub?.unsubscribe();
    }
}

class VisualInspectionCauseComponent implements ng.IComponentOptions {
    public bindings = {
        wnd: '='
    };
    public template = tmpl;
    public controller = VisualInspectionCauseCtrl;
    public controllerAs = 'vm';
}

angular.module('app.component').component('visualInspectionCause', new VisualInspectionCauseComponent());