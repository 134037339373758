<div class="wrapper">
    <div [ngClass]="{processing: (isProcessing)}" class="flex-none ng-scope"><div></div></div>
    <kendo-tabstrip (tabSelect)="onTabSelect($event)">
        <kendo-tabstrip-tab title="Condition Details" [selected]="true"> <!-- xlat this?? -->
            <ng-template kendoTabContent>
                <div class="content">
                    <condition-details [vehicle]="vehicle" (finishedProcessing)="onDoneProcessing($event)"></condition-details>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <!--<kendo-tabstrip-tab title="Visual Inspections">
            <ng-template kendoTabContent>
                <div class="content">
                    Visual inspections goes here...
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="Tread Wear">
            <ng-template kendoTabContent>
                <div class="content">
                    Tread wear goes here...
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="Planned Work">
            <ng-template kendoTabContent>
                <div class="content">
                    Planned work goes here...
                </div>
            </ng-template>
        </kendo-tabstrip-tab>-->
    </kendo-tabstrip>
</div>
