/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var grid_component_1 = require("../grid.component");
var resize_service_1 = require("./resize.service");
/**
 * @hidden
 */
var ResizableContainerDirective = /** @class */ (function () {
    function ResizableContainerDirective(el, renderer, resizeService, grid) {
        this.el = el;
        this.renderer = renderer;
        this.resizeService = resizeService;
        this.grid = grid;
        this.enabled = false;
    }
    Object.defineProperty(ResizableContainerDirective.prototype, "lockedWidth", {
        set: function (value) {
            this._lockedWidth = value;
            if (this.enabled) {
                this.attachResize();
                this.resize();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ResizableContainerDirective.prototype, "kendoGridResizableContainer", {
        set: function (enabled) {
            var refresh = enabled !== this.enabled;
            this.enabled = enabled;
            if (refresh) {
                this.attachResize();
                this.resize();
            }
        },
        enumerable: true,
        configurable: true
    });
    ResizableContainerDirective.prototype.ngOnDestroy = function () {
        if (this.resizeSubscription) {
            this.resizeSubscription.unsubscribe();
        }
    };
    ResizableContainerDirective.prototype.attachResize = function () {
        if (this.resizeSubscription && !this.enabled) {
            this.resizeSubscription.unsubscribe();
            this.resizeSubscription = null;
        }
        if (!this.resizeSubscription && this.enabled) {
            this.resizeSubscription = this.resizeService.changes.subscribe(this.resize.bind(this));
        }
    };
    ResizableContainerDirective.prototype.resize = function () {
        if (this.grid && this.grid.wrapper) {
            var containerElement = this.grid.wrapper.nativeElement;
            var width = Math.max(containerElement.clientWidth - this._lockedWidth, 0);
            if (this.enabled && width > 0) {
                this.renderer.setStyle(this.el.nativeElement, "width", width + "px");
            }
            else {
                this.renderer.setStyle(this.el.nativeElement, "width", "");
            }
        }
    };
    tslib_1.__decorate([
        core_1.Input('lockedWidth'),
        tslib_1.__metadata("design:type", Number),
        tslib_1.__metadata("design:paramtypes", [Number])
    ], ResizableContainerDirective.prototype, "lockedWidth", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [Boolean])
    ], ResizableContainerDirective.prototype, "kendoGridResizableContainer", null);
    ResizableContainerDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoGridResizableContainer]'
        }),
        tslib_1.__param(3, core_1.Optional()),
        tslib_1.__metadata("design:paramtypes", [core_1.ElementRef,
            core_1.Renderer2,
            resize_service_1.ResizeService,
            grid_component_1.GridComponent])
    ], ResizableContainerDirective);
    return ResizableContainerDirective;
}());
exports.ResizableContainerDirective = ResizableContainerDirective;
