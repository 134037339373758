/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var focus_service_1 = require("./focus.service");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
/**
 * @hidden
 */
var FocusableDirective = /** @class */ (function () {
    function FocusableDirective(focusService, elementRef, renderer) {
        this.focusService = focusService;
        this.renderer = renderer;
        this.element = elementRef.nativeElement;
        this.subscribeEvents();
    }
    FocusableDirective.prototype.ngOnInit = function () {
        if (this.index === this.focusService.focused) {
            this.renderer.addClass(this.element, 'k-focus');
        }
        else {
            this.renderer.removeClass(this.element, 'k-focus');
        }
    };
    /**
     * @hidden
     */
    FocusableDirective.prototype.ngOnDestroy = function () {
        this.unsubscribeEvents();
    };
    FocusableDirective.prototype.subscribeEvents = function () {
        var _this = this;
        if (!kendo_angular_common_1.isDocumentAvailable()) {
            return;
        }
        this.focusSubscription = this.focusService.onFocus.subscribe(function (index) {
            if (_this.index === index) {
                _this.renderer.addClass(_this.element, 'k-focus');
                _this.element.focus();
            }
            else {
                _this.renderer.removeClass(_this.element, 'k-focus');
            }
        });
    };
    FocusableDirective.prototype.unsubscribeEvents = function () {
        if (!kendo_angular_common_1.isDocumentAvailable()) {
            return;
        }
        if (this.focusSubscription) {
            this.focusSubscription.unsubscribe();
        }
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], FocusableDirective.prototype, "index", void 0);
    FocusableDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoButtonFocusable]'
        }),
        tslib_1.__metadata("design:paramtypes", [focus_service_1.FocusService, core_1.ElementRef, core_1.Renderer2])
    ], FocusableDirective);
    return FocusableDirective;
}());
exports.FocusableDirective = FocusableDirective;
