//import angular from 'angular';

    angular.module('app.controllers').controller(
            'DiagnosticCtrl',
		['$state', '$stateParams', '$scope', 'amtCommandQuerySvc',
            function ($state, $stateParams, $scope, amtCommandQuerySvc) {
                var vm = this;
                vm.getMethodApi = baseApiUrl + 'admin/diagnostic/getMethods';
                vm.result = "";
                vm.data = "{\n\n\"SearchText\":\"FL\"\n\n}";
                vm.response = "";
                vm.action = "admin/fleet/getFleetTypes";
                vm.post = function () {
                    vm.response = "<i>Processing</i>";
                    var jsonData;
                    try {
                        var cleanData = vm.data.replace(/\n/g, '');
                        cleanData = cleanData.replace(/\t/g, '');
                        jsonData = JSON.parse(cleanData);
                    } catch (error) {
                        vm.result = "DATA ERROR:";
                        vm.response = syntaxHighlight(error);
                        return;
                    }
                    // url fragment #lkjh=65FFdd not sent to server bu used to prevent re-auth on 401
                    amtCommandQuerySvc.post(vm.action + "#lkjh=65FFdd", jsonData).then(function (response) {
                        vm.result = "OK";
                        vm.response = syntaxHighlight(response);
                    }, function (error) {
                        vm.result = "COMMUNICATIONS ERROR:";
                        vm.response = syntaxHighlight(error);
                    });
                };

                vm.get = function () {
                    vm.result = "";
                    vm.response = "<i>Processing</i>";
                    var jsonData;
                    try {
                        var cleanData = vm.data.replace(/\n/g, '');
                        cleanData = cleanData.replace(/\t/g, '');
                        jsonData = JSON.parse(cleanData);
                    } catch (error) {
                        vm.result = "DATA ERROR:";
                        vm.response = syntaxHighlight(error);
                        return;
                    }
                    // url fragment #lkjh=65FFdd not sent to server bu used to prevent re-auth on 401
                    amtCommandQuerySvc.get(vm.action, jsonData).then(function (response) {
                        vm.result = "OK";
                        vm.response = syntaxHighlight(response);
                    }, function (error) {
                        vm.result = "COMMUNICATIONS ERROR:";
                        vm.response = syntaxHighlight(error);
                    });
                };

                $scope.$watch('vm.actionApi', function (newvalue, oldvalue) {
                    if (newvalue) {
                        vm.action = newvalue.name.replace(/^api\//g, '');
                        if (newvalue.description) {
                            vm.data = JSON.stringify(JSON.parse(newvalue.description), null, '\t'); 
                        }
                    }
                });

                function syntaxHighlight(data) {

                    var json = JSON.stringify(data, null, '\t');
                    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
                    json = json.replace(/\n/g, '<br/>');
                    json = json.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;');
                    return json;
                }

            }]);
