//import angular from 'angular';
import * as _ from 'underscore';
import OcDateSvc from '../../../../../services/ocDateSvc';
import { OcDynamicGridColumnMaker, OcDynamicGridColumnType, OcDynamicGridAlignment } from '../../../../../directives/ui/ocDynamicGrid';
import tmpl from './updateForecastFitment.html';



    angular.module("app.site").component("updateForecastFitment",
        {
            template: tmpl,
            bindings: {
                initParams: '=',
                buttonMethods: '=',
                buttonStates: '=',
                buttons: '=',
                closeOnSave: '=',
                wnd: '='
            },
            controller: updateForecastFitmentCtrl
        });

    // @ts-ignore
    updateForecastFitmentCtrl.$inject = ['$scope', 'amtConstants', 'amtCommandQuerySvc', 'confirmSvc', 'enums', 'amtXlatSvc', 'notifySvc', 'WindowFactory', 'errorReporter', 'ocDateSvc'];

    function updateForecastFitmentCtrl($scope, amtConstants, amtCommandQuerySvc, confirmSvc, enums, xlatSvc, notifySvc, WindowFactory, errorReporter, ocDateSvc: OcDateSvc) {

        var vm = this;
        $scope.vm = this;

        vm.url = amtConstants.url;

        vm.model = {
            forecastId: 0,
            vehicleSpecificationId: 0,
            tyreSpecifications: [],
            vehicles: []
        };

        vm.availableTyreSpecifications = [];

        this.$onInit = function () {

            vm.model.forecastId = vm.initParams.forecastId;
            vm.model.vehicleSpecificationId = vm.initParams.vehicleSpecificationId;
            vm.model.vehicles = [];

            vm.forecastStartDate = vm.initParams.forecastStartDate;
            vm.forecastEndDate = vm.initParams.forecastEndDate;

            vm.buttons = [
                {
                    primary: true,
                    cancel: false,
                    value: 'common.save_label',
                    name: 'saveButton',
                    click: 'save',
                    type: enums.buttonTypes.button
                },
                {
                    primary: false,
                    cancel: true,
                    value: 'common.cancel_label',
                    name: 'cancelButton',
                    click: 'cancel',
                    type: enums.buttonTypes.button
                }
            ];

            vm.buttonStates = {
                saveButton: {
                    visible: true,
                    disabled: true
                },
                cancelButton: {
                    visible: true,
                    disabled: false
                }
            };

            vm.buttonMethods = {
                save: vm.save,
                cancel: vm.onCancelPopup
            };

            vm.wnd.processing = true;

            vm.loadDetails().finally(function () {
                vm.wnd.processing = false;
            });

        };

        vm.loadDetails = function () {

            return vm.getVehicles().then(function () {

                return vm.getTyreSpecifications().then(function () {

                    // define the tyre specification grid
                    vm.specificationGridItems = [];

                    vm.specificationGridItems.push(OcDynamicGridColumnMaker.create({
                        headerResource: 'tyreSpecificationEdit.tyreSpecification',
                        type: OcDynamicGridColumnType.string,
                        valuePath: 'specification',
                        colSpan: 4,
                        columnAlignment: OcDynamicGridAlignment.center
                    }));

                    vm.specificationGridItems.push(OcDynamicGridColumnMaker.create({
                        headerResource: 'tyreSpecificationEdit.currentFitment',
                        type: OcDynamicGridColumnType.number,
                        decimalPlaces: 0,
                        valuePath: 'currentFitment',
                        appendText: '%',
                        colSpan: 3,
                        columnAlignment: OcDynamicGridAlignment.center,
                        contentWidth: 50
                    }));

                    vm.specificationGridItems.push(OcDynamicGridColumnMaker.create({
                        headerResource: 'tyreSpecificationEdit.futureFitment',
                        type: OcDynamicGridColumnType.number,
                        decimalPlaces: 0,
                        minValue: 0,
                        maxValue: 100,
                        valuePath: 'futureFitment',
                        editable: 'true',
                        appendText: '%',
                        colSpan: 3,
                        columnAlignment: OcDynamicGridAlignment.center,
                        contentWidth: 50
                    }));

                    vm.specificationGridItems.push(OcDynamicGridColumnMaker.create({
                        headerResource: 'tyreSpecificationEdit.availableDate',
                        type: OcDynamicGridColumnType.template,
                        template: '<amt-datepicker ng-model="$row.availableDate" max-date="$maxDate" is-disabled="$readonly" />',
                        templateValues: { $maxDate: vm.initParams.forecastEndDate, $readonly: vm.readonly },
                        colSpan: 2,
                        columnAlignment: OcDynamicGridAlignment.center
                    }));

                    // define the vehicle grid
                    vm.vehicleGridItems = [];

                    vm.vehicleGridItems.push(OcDynamicGridColumnMaker.create({
                        headerResource: 'tyreSpecificationEdit.vehicleSerial',
                        type: OcDynamicGridColumnType.string,
                        valuePath: 'vehicleSerialNumber',
                        colSpan: 2,
                        columnAlignment: OcDynamicGridAlignment.center
                    }));

                    vm.vehicleGridItems.push(OcDynamicGridColumnMaker.create({
                        headerResource: 'tyreSpecificationEdit.currentSpecification',
                        type: OcDynamicGridColumnType.string,
                        valuePath: 'currentTyreSpecification.name',
                        colSpan: 4,
                        columnAlignment: OcDynamicGridAlignment.left
                    }));

                    vm.vehicleGridItems.push(OcDynamicGridColumnMaker.create({
                        headerResource: 'tyreSpecificationEdit.futureSpecification',
                        type: OcDynamicGridColumnType.template,
                        template: '<amt-combobox ng-model="$row.futureTyreSpecification" is-required="true" on-selected-change="$onChange" options="$options" />',
                        templateValues: { $options: vm.availableTyreSpecifications },
                        templateEvents: {
                            $onChange: {
                                method: 'futureTyreSpecChanged',
                                params: ['option', '$row']
                            }
                        },
                        colSpan: 4,
                        columnAlignment: OcDynamicGridAlignment.left
                    }));

                    vm.vehicleGridItems.push(OcDynamicGridColumnMaker.create({
                        headerResource: 'tyreSpecificationEdit.availableDate',
                        type: OcDynamicGridColumnType.template,
                        template: '<amt-datepicker ng-model="$row.availableDate" is-required="true" max-date="$maxDate" is-disabled="$readonly" on-date-change="$onChange" />',
                        templateValues: { $maxDate: vm.initParams.forecastEndDate, $readonly: vm.readonly },
                        templateEvents: {
                            $onChange: {
                                method: 'availableDateChanged',
                                params: ['date', '$row']
                            }
                        },
                        colSpan: 2,
                        columnAlignment: OcDynamicGridAlignment.center
                    }));

                });
            });
        };

        vm.save = function () {

            if (vm.model.forecastId !== 0 && vm.model.vehicleSpecificationId !== 0) {

                vm.wnd.processing = true;

                var updateModel = {
                    forecastId: vm.model.forecastId,
                    vehicleSpecificationId: vm.model.vehicleSpecificationId,
                    vehicles: []
                };

                vm.model.vehicles.forEach(function (v) {
                    updateModel.vehicles.push({
                        forecastVehicleId: v.forecastVehicleId,
                        tyreSpecificationId: (v.futureTyreSpecification != null ? v.futureTyreSpecification.key : null),
                        availableDate: ocDateSvc.removeLocalTimeZoneOffset(v.availableDate)
                    });
                });

                amtCommandQuerySvc.put(amtConstants.url.updateForecastVehicleTyreSpecifications, updateModel).then(function (response) {

                    notifySvc.success(xlatSvc.xlat("tyreSpecificationEdit.forecastFitmentUpdateSuccess"));

                    if (vm.wnd.onDataChanged) {
                        vm.wnd.onDataChanged();
                    }

                    WindowFactory.closeWindow(vm.wnd);

                }).catch(function (error) {
                    errorReporter.logError(error);
                }).finally(function () {
                    vm.wnd.processing = false;
                });
            }
        };

        vm.onCancelPopup = function () {
            confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                vm.form.dirty = false;
                WindowFactory.closeWindow(vm.wnd);
            });
        };

        vm.futureTyreSpecChanged = function (option, item) {

            // get the current future spec of the vehicle and the one it has been changed to
            var currentSpec = item.futureTyreSpecification.key;
            var newSpec = option.key;

            // get the total number of vehicles
            var vehicleCount = vm.model.vehicles.length;

            // for each tyre spec
            _.each(vm.model.tyreSpecifications, function (a) {

                // get the vehicles using the tyre spec as their future spec (and the vehicle count)
                var filtered = vm.model.vehicles.filter(function (b) { return b.futureTyreSpecification.key == a.tyreSpecificationId; });
                var specCount = filtered.length;

                // if this tyre spec is the one they were using as the future spec for the vehicle, decrease the vehicle count for spec
                if (a.tyreSpecificationId == currentSpec) {
                    specCount = specCount - 1;
                }

                // if this tyre spec is the one they are using as the new future spec for the vehicle, increase the vehicle count
                if (a.tyreSpecificationId == newSpec) {
                    specCount = specCount + 1;
                }

                // calculate the new future fitment % for the tyre spec
                if (vehicleCount > 0) {
                    a.futureFitment = (specCount / vehicleCount) * 100;
                } else {
                    a.futureFitment = 0;
                }
            });
        };

        vm.availableDateChanged = function (date, item) {

            // get the current future spec of the vehicle and the one it has been changed to
            let currentSpec = item.futureTyreSpecification.key;

            // get the available dates from all vehicles using this future spec
            let availableDates = vm.model.vehicles.filter(function (v) {
                return v.futureTyreSpecification.key == currentSpec
            }).map(function (v) {
                return v.availableDate;
            });

            // get the tyre specification
            let tyreSpec = _.find(vm.model.tyreSpecifications, function (s) { return s.tyreSpecificationId == currentSpec; });

            // set the tyre specs available date to the minimum of the vehicle available dates
            if (tyreSpec) {
                tyreSpec.availableDate = new Date(Math.min(...availableDates));
            }
        };

        vm.getVehicles = function () {

            var criteria = {
                forecastId: vm.model.forecastId,
                vehicleSpecificationId: vm.model.vehicleSpecificationId
            };

            return amtCommandQuerySvc.post(amtConstants.url.getVehicleTyreSpecifications, criteria).then(function (response) {
                if (response) {
                    vm.model.vehicles = response;

                    _.each(vm.model.vehicles, v => {
                        if (!v.availableDate) {
                            v.availableDate = vm.forecastStartDate;
                        }
                    });

                } else {
                    notifySvc.error("Failed to retrieve vehicles.");
                }
            }).catch(function (error) {
                errorReporter.logError(error);
            });
        };

        vm.getTyreSpecifications = function () {

            var criteria = {
                forecastId: vm.model.forecastId,
                vehicleSpecificationId: vm.model.vehicleSpecificationId
            };

            return amtCommandQuerySvc.get(amtConstants.url.getVehicleSpecificationTyreSpecifications, criteria).then(function (response) {
                if (response) {

                    vm.model.tyreSpecifications = response.data;

                    _.each(vm.model.tyreSpecifications, spec => {

                        // set the specification available date to the minimum available date across the individual vehicles with matching future spec
                        spec.availableDate = _.min(vm.model.vehicles.filter(function (v) {
                            return v.futureTyreSpecification.key == spec.tyreSpecificationId;
                        }), function (v) {
                            return v.availableDate;
                        }).availableDate;

                        // if no vehicles were using the spec, default available date to the forecast start date
                        if (!spec.availableDate) spec.availableDate = vm.forecastStartDate;

                        vm.availableTyreSpecifications.push({
                            key: spec.tyreSpecificationId,
                            name: spec.specification
                        });
                    });
                }

            }).catch(function (error) {
                errorReporter.logError(error);
            });
        };

        vm.applyFutureFitments = function () {

            // check that the future fitment percentages sum to 100%
            if (+vm.model.tyreSpecifications.reduce(function (s, f) { return s + +f.futureFitment; }, 0).toFixed(0) !== 100) {
                notifySvc.error(xlatSvc.xlat("tyreSpecificationEdit.futureFitmentsMustSumTo100"));
                return;
            }

            if (_.any(vm.model.tyreSpecifications, function (s) { return s.futureFitment > 0 && !s.availableDate; })) {
                notifySvc.error(xlatSvc.xlat("tyreSpecificationEdit.availableDateRequired"));
                return;
            }

            let vehicleCount = vm.model.vehicles.length;
            let assignedVehicleCount = 0;

            // for each tyre spec calculate the number of whole vehicles the spec should be applied to (roundest to nearest whole vehicle)
            // and apply the spec (and the available date)
            for (let i = 0; i < vm.model.tyreSpecifications.length; i++) {

                let tyreSpec = vm.model.tyreSpecifications[i];
                let selectedTyreSpec = _.find(vm.availableTyreSpecifications, function (s) { return s.key == tyreSpec.tyreSpecificationId; });

                let numberOfVehiclesToAssignSpec = Math.round((tyreSpec.futureFitment / 100) * vehicleCount);

                // if this is the final spec apply it to all remaining vehicles, otherwise apply it to the calculated numbers of vehicles
                let applyBelowIndex = (i === vm.model.tyreSpecifications.length - 1) ? vehicleCount : Math.min(vehicleCount, assignedVehicleCount + numberOfVehiclesToAssignSpec);

                for (let j = assignedVehicleCount; j < applyBelowIndex; j++) {
                    vm.model.vehicles[j].futureTyreSpecification = selectedTyreSpec;
                    vm.model.vehicles[j].availableDate = tyreSpec.availableDate;
                }

                assignedVehicleCount += numberOfVehiclesToAssignSpec;
            }

            // for each tyre specification, after updating the individual vehicles, recalculate the future fitment %
            // this is because the percentages entered and applied likely didn't perfectly match with the number of vehicles
            // so this will adjust them back to the true values
            for (let i = 0; i < vm.model.tyreSpecifications.length; i++) {

                let tyreSpec = vm.model.tyreSpecifications[i];
                let vehicleSpecCount = vm.model.vehicles.filter(function (v) { return v.futureTyreSpecification.key == tyreSpec.tyreSpecificationId; }).length;

                tyreSpec.futureFitment = (vehicleCount > 0 ? (vehicleSpecCount / vehicleCount) * 100 : 0);
            }
        };

        $scope.$watch("vm.vehicleGridControl", function (newValue) {

            if (newValue) {
                // connect functions to dynamic grid api
                vm.vehicleGridControl.futureTyreSpecChanged = vm.futureTyreSpecChanged;
                vm.vehicleGridControl.availableDateChanged = vm.availableDateChanged;
            }
        });

        $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing'], function () {
            vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.form.$pristine || !!vm.wnd.processing;
        });
    }
