/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
/* tslint:disable:max-line-length */
var core_1 = require("@angular/core");
/**
 * Renders the grouped tag values in the MultiSelect
 * ([see example]({% slug summarytagmode_multiselect %})).
 * The template context is set to the current component.
 * To get a reference to the current grouped
 * data items collection, use the `let-dataItems` directive.
 *
 * > The `GroupTagTemplate` directive can only be used with the MultiSelect and MultiSelectTree components.
 *
 * @example
 * ```ts
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-multiselect kendoMultiSelectSummaryTag [data]="items">
 *    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
 *      <span>{{dataItems.length}} item(s) selected</span>
 *    </ng-template>
 *  </kendo-multiselect>
 * `
 * })
 * class AppComponent {
 *   public items: Array<string> = ["Item 1", "Item 2", "Item 3", "Item 4"];
 * }
 * ```
 */
var GroupTagTemplateDirective = /** @class */ (function () {
    function GroupTagTemplateDirective(templateRef) {
        this.templateRef = templateRef;
    }
    GroupTagTemplateDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoMultiSelectGroupTagTemplate],[kendoMultiSelectTreeGroupTagTemplate]'
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.TemplateRef])
    ], GroupTagTemplateDirective);
    return GroupTagTemplateDirective;
}());
exports.GroupTagTemplateDirective = GroupTagTemplateDirective;
