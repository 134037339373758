/**
 * @ngdoc service
 * @name amtFramework.amtNotificationSvc
 *
 * @description
 * @deprecated
 * 
 * @example
 */
//import angular from 'angular';

angular.module('amtFramework').factory('amtNotificationSvc', [function () {
        return {
            messages: [],
            error: function (message) {
                this.messages.push({ message: message, type: 'error' });
            }
        };
}]);
