    /**
        * @ngdoc directive
        * @name amtFramework.directive:amtRow
        * @restrict E
        * @description 
        * Renders a div with the right bootstrap classes and supports multiple amtCol inside
        * @param {string} [layout=horizontal] determines how the layout of {@link amtFramework.directive:amtDisplay amtDisplay} works. OPtions are "horizontal" and "vertical" 
    **/
    
//import angular from 'angular';
import tmpl from './amtRow.html';


angular.module('amtFramework').directive('amtRow', function () {
            return {
                restrict: 'E',
                transclude: true,
                replace: true,
                template: tmpl,
                scope: {
                    layout: '@',
                    showAlternateRowColors: '<',
                    alternateRow: '<',
                    hasDisplay: '<'
                },
                controller: [
                    '$scope', function($scope) {
                        var self = this;

                        self.cols = 0;
                        self.layout = $scope.layout;
                        self.hasDisplay = $scope.hasDisplay;

                        this.add = function(col) {
                            self.cols += col;
                        };
                    }
                ]
            };
});
