//import angular from 'angular';
import ReportSvc, { ExportReports, ExportReportParameterDefaults, ExportReportCriteria, ExportProviders, ExportOrientation }
    from '../../../../services/reportSvc';
import StocktakeUrls from './stocktakeUrls';
import tmpl from './blankStocktakeForm.html';



enum StocktakeLevel {
    Location = 1,
    Status = 2,
    Specification = 3,
    Serial = 4            
}

class BlankStocktakeFormCtrl implements ng.IController, IWindowController {

    wnd: IWindowObj;
    form: ng.IFormController;

    initParams: any;

    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: () => void };

    status: ScreenStatus = ScreenStatus.loading
 
    componentType: KeyNamePair;
    stocktakeLevel: KeyNamePair;

    static $inject = ['$scope', 'stocktakeUrls', 'WindowFactory', 'errorReporter', 'reportSvc'];

    constructor(private $scope: ng.IScope, private stocktakeUrls: StocktakeUrls, private WindowFactory: IWindowFactory,
        private errorReporter: IErrorReporter, private reportSvc: ReportSvc) {

        this.$scope.$watchGroup([() => this.form.$invalid, () => this.wnd.processing], () => {
            this.buttonStates.downloadButton.disabled = this.form.$invalid || this.wnd.processing;
        });
    }

    $onInit() {

        this.wnd.processing = true;
        this.wnd.onClose = () => this.closeWindow();

        this.WindowFactory.addButton(this, 'common.download_label', 'downloadButton', () => this.download(), true);
        this.WindowFactory.addButton(this, 'common.cancel_label', 'cancelButton', () => this.closeWindow());

        this.status = ScreenStatus.ready;
        this.wnd.processing = false;
    }

    download() {

        let report = this.reportSvc.getReport(ExportReports.blankPhysicalStocktake);

        if (report) {

            let parameters = [];

            if (report.parameters && report.parameters.length) {
                parameters = this.reportSvc.constructBaseParameters(report);
                parameters = this.reportSvc.constructParameter(parameters, report, 'Date', ExportReportParameterDefaults.null);
                parameters = this.reportSvc.constructParameter(parameters, report, 'EquipmentType', this.componentType.name);
                parameters = this.reportSvc.constructParameter(parameters, report, 'ComponentSpecification', this.stocktakeLevel.key == StocktakeLevel.Specification.toString() ? StocktakeLevel[StocktakeLevel.Specification] : ExportReportParameterDefaults.empty);
                parameters = this.reportSvc.constructParameter(parameters, report, 'SerialNumber', this.stocktakeLevel.key == StocktakeLevel.Serial.toString() ? StocktakeLevel[StocktakeLevel.Serial] : ExportReportParameterDefaults.empty);
                parameters = this.reportSvc.constructParameter(parameters, report, 'Level', this.stocktakeLevel.key == StocktakeLevel.Location.toString() ? StocktakeLevel[StocktakeLevel.Location] : StocktakeLevel[StocktakeLevel.Status]);
            }

            let exportCriteria: ExportReportCriteria = {
                report: report,
                provider: ExportProviders.pdf,
                parameters: parameters,
                orientation: ExportOrientation.portrait
            };

            this.wnd.processing = true;

            try {
                this.reportSvc.exportReport(exportCriteria);

                this.closeWindow();

            } catch (error) {
                this.errorReporter.logError(error);
            } finally {
                this.wnd.processing = false;
            }
        }
    }

    closeWindow() {
        this.WindowFactory.closeWindow(this.wnd);
    }
}

class BlankStocktakeFormComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = BlankStocktakeFormCtrl;
    public controllerAs = 'vm';
}

angular.module('app.component').component('blankStocktakeForm', new BlankStocktakeFormComponent());
