/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var constants_1 = require("./constants");
var core_1 = require("@angular/core");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var rxjs_1 = require("rxjs");
var tabscroll_event_1 = require("./events/tabscroll-event");
var util_1 = require("./util");
/**
 * @hidden
 */
var ScrollService = /** @class */ (function () {
    function ScrollService(ngZone) {
        this.ngZone = ngZone;
        this.position = 0;
        this.scrollButtonActiveStateChange = new rxjs_1.Subject();
    }
    Object.defineProperty(ScrollService.prototype, "tablistElement", {
        get: function () {
            return this.owner.tablist.nativeElement;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScrollService.prototype, "scrollButtonIconClass", {
        get: function () {
            var tabStrip = this.owner;
            var defaultPrevIcon = util_1.isTablistHorizontal(tabStrip.tabPosition) ? constants_1.DIRECTION_CLASSES.left : constants_1.DIRECTION_CLASSES.up;
            var defaultNextIcon = util_1.isTablistHorizontal(tabStrip.tabPosition) ? constants_1.DIRECTION_CLASSES.right : constants_1.DIRECTION_CLASSES.down;
            if (typeof tabStrip.scrollable === 'object') {
                var prevIcon = typeof tabStrip.scrollable.prevButtonIcon === 'undefined' ? defaultPrevIcon : tabStrip.scrollable.prevButtonIcon;
                var nextIcon = typeof tabStrip.scrollable.nextButtonIcon === 'undefined' ? defaultNextIcon : tabStrip.scrollable.nextButtonIcon;
                return { prevScrollButton: prevIcon, nextScrollButton: nextIcon };
            }
            return { prevScrollButton: defaultPrevIcon, nextScrollButton: defaultNextIcon };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScrollService.prototype, "tabstripSize", {
        get: function () {
            var hostElement = this.owner.wrapper.nativeElement;
            var wrapperWidth = parseFloat(getComputedStyle(hostElement).width);
            var wrapperHeight = parseFloat(getComputedStyle(hostElement).height);
            return util_1.isTablistHorizontal(this.owner.tabPosition) ? wrapperWidth : wrapperHeight;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScrollService.prototype, "tablistOverflowSize", {
        get: function () {
            if (!kendo_angular_common_1.isDocumentAvailable()) {
                return 0;
            }
            var isHorizontal = util_1.isTablistHorizontal(this.owner.tabPosition);
            var overflowSize = Math.floor(this.tablistElement[isHorizontal ? 'scrollWidth' : 'scrollHeight']
                - this.tablistElement.getBoundingClientRect()[isHorizontal ? 'width' : 'height']);
            return overflowSize < 0 ? 0 : overflowSize;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScrollService.prototype, "tabsOverflow", {
        get: function () {
            return this.tablistOverflowSize > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScrollService.prototype, "scrollButtonsSize", {
        get: function () {
            if (!this.owner.hasScrollButtons) {
                return 0;
            }
            var prevRect = this.owner.prevScrollButton.hostBoundingClientRect;
            var prevSize = util_1.isTablistHorizontal(this.owner.tabPosition) ? prevRect.width : prevRect.height;
            var nextRect = this.owner.nextScrollButton.hostBoundingClientRect;
            var nextSize = util_1.isTablistHorizontal(this.owner.tabPosition) ? nextRect.width : nextRect.height;
            return prevSize + nextSize;
        },
        enumerable: true,
        configurable: true
    });
    ScrollService.prototype.toggleScrollButtonsState = function () {
        var _this = this;
        var tabStrip = this.owner;
        if (!tabStrip.hasScrollButtons) {
            return;
        }
        var currentPrevButtonActive = !tabStrip.prevScrollButton.disabled;
        var currentNextButtonActive = !tabStrip.nextScrollButton.disabled;
        var calculatedPrevButtonActive = this.position > 0 && this.tablistOverflowSize > 0;
        var calculatedNextButtonActive = this.position < this.tablistOverflowSize + this.scrollButtonsSize && this.tablistOverflowSize > 0;
        if (calculatedPrevButtonActive !== currentPrevButtonActive) {
            this.ngZone.run(function () { return _this.toggleButtonActiveState('prev', calculatedPrevButtonActive); });
        }
        if (calculatedNextButtonActive !== currentNextButtonActive) {
            this.ngZone.run(function () { return _this.toggleButtonActiveState('next', calculatedNextButtonActive); });
        }
    };
    ScrollService.prototype.scrollToSelectedTab = function () {
        var _this = this;
        if (!this.tabsOverflow) {
            return;
        }
        var activeIndex = util_1.getActiveTab(this.owner.tabs).index;
        if (activeIndex === -1) {
            return;
        }
        this.position += this.getScrollOffset(activeIndex);
        if (util_1.isTablistHorizontal(this.owner.tabPosition)) {
            this.tablistElement.scrollLeft = this.position;
        }
        else {
            this.tablistElement.scrollTop = this.position;
        }
        this.toggleScrollButtonsState();
        var tabStrip = this.owner;
        if (!tabStrip.hasScrollButtons) {
            return;
        }
        var isFirstTabActive = activeIndex === 0;
        var isLastTabActive = activeIndex === this.owner.tabs.length - 1;
        if (isFirstTabActive && !tabStrip.prevScrollButton.disabled) {
            this.ngZone.run(function () { return _this.toggleButtonActiveState('prev', false); });
        }
        if (isLastTabActive && !tabStrip.nextScrollButton.disabled) {
            this.ngZone.run(function () { return _this.toggleButtonActiveState('next', false); });
        }
    };
    ScrollService.prototype.getScrollOffset = function (activeIndex) {
        if (!kendo_angular_common_1.isDocumentAvailable()) {
            return 0;
        }
        var isHorizontal = util_1.isTablistHorizontal(this.owner.tabPosition);
        this.tablistElement["scroll" + (isHorizontal ? 'Left' : 'Top')] = this.position;
        var activeTabRect = this.tablistElement.children[activeIndex].getBoundingClientRect();
        var tablistRect = this.tablistElement.getBoundingClientRect();
        var end = isHorizontal ? 'right' : 'bottom';
        var start = isHorizontal ? 'left' : 'top';
        var activeTabStart = activeTabRect[start];
        var activeTabEnd = activeTabRect[end];
        var tablistStart = tablistRect[start];
        var tablistEnd = tablistRect[end];
        var tabEndIsInVisibleRange = activeTabEnd <= tablistEnd;
        var tabStartIsInVisibleRange = activeTabStart >= tablistStart;
        var isWholeTabVisible = tabEndIsInVisibleRange && tabStartIsInVisibleRange;
        if (isWholeTabVisible) {
            return 0;
        }
        if (!tabEndIsInVisibleRange) {
            return activeTabEnd - tablistEnd;
        }
        if (!tabStartIsInVisibleRange) {
            return activeTabStart - tablistStart;
        }
    };
    ScrollService.prototype.onMouseScroll = function (event) {
        event.preventDefault();
        if (!util_1.mouseScrollEnabled(this.owner.scrollable)) {
            return;
        }
        var direction = event.deltaY < 0 ? 'prev' : 'next';
        this.calculateListPosition(direction, this.owner.scrollable.mouseScrollSpeed);
        if (util_1.isTablistHorizontal(this.owner.tabPosition)) {
            this.tablistElement.scrollLeft = this.position;
        }
        else {
            this.tablistElement.scrollTop = this.position;
        }
        this.toggleScrollButtonsState();
    };
    ScrollService.prototype.scrollTabs = function (direction) {
        this.calculateListPosition(direction, this.owner.scrollable.buttonScrollSpeed);
        if (util_1.isTablistHorizontal(this.owner.tabPosition)) {
            this.tablistElement.scrollTo({ left: this.position, behavior: constants_1.DEFAULT_SCROLL_BEHAVIOR });
        }
        else {
            this.tablistElement.scrollTo({ top: this.position, behavior: constants_1.DEFAULT_SCROLL_BEHAVIOR });
        }
        this.toggleScrollButtonsState();
    };
    ScrollService.prototype.calculateListPosition = function (direction, scrollSpeed) {
        var adjustedMaxScroll = this.tablistOverflowSize + this.scrollButtonsSize;
        if (direction === 'prev' && this.position > 0) {
            this.position = this.position - scrollSpeed <= 0 ? 0 : this.position - scrollSpeed;
        }
        else if (direction === 'next' && this.position < adjustedMaxScroll) {
            if (this.position + scrollSpeed > adjustedMaxScroll) {
                this.position = adjustedMaxScroll;
                return;
            }
            this.position += scrollSpeed;
        }
    };
    ScrollService.prototype.emitScrollEvent = function (ev) {
        var scrollEvent = new tabscroll_event_1.TabScrollEvent({
            originalEvent: ev
        });
        if (kendo_angular_common_1.hasObservers(this.owner.tabScroll)) {
            this.owner.tabScroll.emit(scrollEvent);
        }
        return scrollEvent;
    };
    ScrollService.prototype.restoreScrollPosition = function () {
        if (util_1.isTablistHorizontal(this.owner.tabPosition)) {
            this.tablistElement.scrollTo({ left: this.position });
        }
        else {
            this.tablistElement.scrollTo({ top: this.position });
        }
        this.toggleScrollButtonsState();
    };
    ScrollService.prototype.toggleButtonActiveState = function (buttonType, active) {
        this.scrollButtonActiveStateChange.next({ buttonType: buttonType, active: active });
    };
    ScrollService = tslib_1.__decorate([
        core_1.Injectable(),
        tslib_1.__metadata("design:paramtypes", [core_1.NgZone])
    ], ScrollService);
    return ScrollService;
}());
exports.ScrollService = ScrollService;
