//import angular from 'angular';
const moduleName = 'amtFilters';
export default moduleName;
let amtFilters = angular.module(moduleName, []);

amtFilters.filter('percentage', ['$window',
    function ($window) {
                return function(input, decimals, suffix) {
                    decimals = decimals || 3;
                    suffix = suffix || '%';
                    if ($window.isNaN(input)) {
                        return '';
                    }
                    return Math.round(input * Math.pow(10, decimals + 2)) / Math.pow(10, decimals) + suffix;
                };
            }
        ])
amtFilters.filter('amtDate', ['$filter', '$locale',
    function ($filter, $locale) {
                
                var angularDateFilter = $filter('date');
                return function(theDate) {
					var format = $locale.DATETIME_FORMATS.shortDate;
					if ($locale.DATETIME_FORMATS.shortDate.split("y").length === 2) {
						// "y" tends to translate to "yyyy" (kendo can't work with "y", needs "yy" or "yyyy").
						format = format.slice(0, format.indexOf("y")) + "yyyy" + format.slice(format.indexOf("y") + 1);
					}
                    return angularDateFilter(theDate, format);
                };
            }
        ])
amtFilters.filter("multiProperty", [
    function () {
            /**
             * @param {Array<Object>} input - This is automatic; The list of items to be filtered.
             * @param {Array<Object>} filterOptions - Options to filter on.
             */
            return function(input, filterOptions) {
                var filtered = [];
                for (var i = 0; i < input.length; i++) {
                    var item = input[i];
                    var itemIsValid = true;
                    for (var property in filterOptions) {
                        if (filterOptions.hasOwnProperty(property)) {
                            var filterProperty = property;
                            var filterValue = filterOptions[property];

                            if (!!filterValue) {
                                if (!!item[filterProperty]) {
                                    if (Array.isArray(filterValue)) {
                                        itemIsValid = itemIsValid && filterValue.indexOf(item[filterProperty]) > -1;
                                    } else {
                                        itemIsValid = itemIsValid && item[filterProperty].toString().toLowerCase().contains(filterValue.toString().toLowerCase());
                                    }
                                } else {
                                    itemIsValid = false;
                                }
                            }
                        }
                    }

                    if (itemIsValid) {
                        filtered.push(item);
                    }
                }
                return filtered;
            };
        }]);