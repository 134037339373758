/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var filter_operator_base_1 = require("./filter-operator.base");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
/*
 * Represents the `Less then` (**Is before**) date filter operator.
 *
 * For more information and examples, refer to:
 * * [Setting the default filter operators]({% slug builtinfiltertemplate_grid %}#toc-setting-the-default-filter-operator)
 * * [Setting the order of the filter operators]({% slug builtinfiltertemplate_grid %}#toc-setting-the-order-of-the-filter-operators)
 */
var BeforeFilterOperatorComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BeforeFilterOperatorComponent, _super);
    function BeforeFilterOperatorComponent(localization) {
        return _super.call(this, "before", localization) || this;
    }
    BeforeFilterOperatorComponent_1 = BeforeFilterOperatorComponent;
    /**
     * @hidden
     */
    BeforeFilterOperatorComponent.prototype.toJSON = function () {
        return {
            text: this.text,
            value: "lt"
        };
    };
    var BeforeFilterOperatorComponent_1;
    BeforeFilterOperatorComponent = BeforeFilterOperatorComponent_1 = tslib_1.__decorate([
        core_1.Component({
            providers: [
                {
                    provide: filter_operator_base_1.FilterOperatorBase,
                    useExisting: core_1.forwardRef(function () { return BeforeFilterOperatorComponent_1; })
                }
            ],
            selector: 'kendo-filter-before-operator',
            template: ""
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService])
    ], BeforeFilterOperatorComponent);
    return BeforeFilterOperatorComponent;
}(filter_operator_base_1.FilterOperatorBase));
exports.BeforeFilterOperatorComponent = BeforeFilterOperatorComponent;
