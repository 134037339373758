//import angular from 'angular';
import BrowserSvc from '../../../services/browserSvc';
import tmpl from "./vehicleReadings.html";


//TODO: this is global :_( it probably should not be
const enum ReadingTypes {
    hours = "hours",
    distance = "distance"
}

interface IVehicleReadingsVehicle {
    id: guid;
    hours: number;
    distance: number;

    lastHours: number;
    lastDistance: number;
    hasHoursMeter: boolean;
    hasDistanceMeter: boolean;
    newHourMeter: boolean;
    newDistanceMeter: boolean;
    distanceUnit: { unitAbbreviation: string };
}

class vehicleReadingsCtrl {
    public showDistance = true;
    public showHours = true;

    public distanceUnit: string;

    public sessionAvg: string;
    public sessionHours: number;
    public sessionDist: number;

    public fleetSpeed: OcUnit;

    public active: boolean;

    public activeElement: ReadingTypes;

    private deregisterKeypadHandler : ()=>void = null

    //bindings
    public vehicle: IVehicleReadingsVehicle;
    public readonly: boolean;
    public dirty: boolean;

    static $inject = ["$scope", "$element", "dataBroker", "errorReporter", "fieldSurveyService", "amtConstants", "browserSvc"];

    constructor(private $scope: ng.IScope, private $element: JQuery<HTMLElement>, private dataBroker: IDataBroker, private errorReporter: IErrorReporter,
        private fieldSurveyService, private amtConstants, private browserSvc: BrowserSvc) {
    }

    public async $onInit() {
        this.$element.addClass('form-horizontal');

        this.showDistance = !!this.vehicle.hasDistanceMeter;
        this.showHours = !!this.vehicle.hasHoursMeter;

        this.distanceUnit = this.vehicle.distanceUnit.unitAbbreviation;

        // focus something
        this.activeElement = this.showHours ? ReadingTypes.hours : (this.showDistance ? ReadingTypes.distance : null);

        //calc stats
        this.change(false);

        try {
            let response = await this.dataBroker.getFleetSpeed(this.vehicle.id);
            if (response && response.fleetSpeed) {
                this.fleetSpeed = {
                    value: response.fleetSpeed,
                    unit: response.fleetSpeedUnit.unitAbbreviation,
                    unitType: response.fleetSpeedUnit.baseReadingUnit
                };
            }
        } catch (error) {
            this.errorReporter.logError(error);
        }

        this.deregisterKeypadHandler = this.$scope.$on(Keypad.MODIFIER_KEY_PRESSED, (evt: ng.IAngularEvent, key: string) => this.keypadHandler(evt, key));
    }

    public keypadHandler(evt: ng.IAngularEvent | JQuery.KeyPressEvent, key?: string) {
        if (!this.active)
            return;

        switch (key || (<JQuery.KeyPressEvent>evt).which) {
            case 9 /* tab */:
                this.setFocus(<ng.IAngularEvent>evt, this.activeElement != ReadingTypes.hours ? ReadingTypes.hours : ReadingTypes.distance);
                break;

            case 38 /* up */:
            //case 37 /* left */:
            case "UP":
            case "LEFT":
            case "SHIFTTAB":
            case "SET-FOCUS":
                this.setFocus(key != "SET-FOCUS" ? <ng.IAngularEvent>evt : null, ReadingTypes.hours);
                break;

            case 40 /* down */:
            //case 39 /* right */:
            case "DOWN":
            case "RIGHT":
            case "TAB":
                this.setFocus(<ng.IAngularEvent>evt, ReadingTypes.distance);
                break;
        }
    }

    public setFocus(evt: ng.IAngularEvent, field: ReadingTypes) {
        if (this.readonly)
            return;

        if (evt) {
            evt.preventDefault();
        }

        let show = false;

        switch (field) {
            case ReadingTypes.hours:
                show = this.showHours;
                break;
            case ReadingTypes.distance:
                show = this.showDistance;
                break;
        }

        if (show) {
            this.activeElement = field;
        }
    }

    public async save() {
        await this.fieldSurveyService.validate(this.vehicle, null, false, true);
        await this.dataBroker.saveFieldSurvey(this.vehicle);
    }

    public change(saveChanges = true) {
        let distanceChange = this.vehicle.distance - this.vehicle.lastDistance;
        this.sessionDist = !this.vehicle.newDistanceMeter && this.vehicle.lastDistance && distanceChange > 0 ? distanceChange : null;

        let hoursChange = this.vehicle.hours - this.vehicle.lastHours;
        this.sessionHours = !this.vehicle.newHourMeter && this.vehicle.lastHours && hoursChange > 0 ? hoursChange : null;

        this.sessionAvg = "";
        if (this.sessionHours && this.sessionDist) {
            let avg = this.sessionDist / this.sessionHours;
            this.sessionAvg = (avg >= 0.1 ? avg.toFixed(1) : "> 0.1") + " " + this.distanceUnit + "/h";
        }

        if (saveChanges) {
            //TODO: this is ALOT of change for a single keystroke, maybe it could be throttled at least? (if field surveys can't handle saving on close)            
            this.save();

            if (!this.browserSvc.isMobile)
                this.dirty = true;
        }
    }

    public $onDestroy() {
        if (this.deregisterKeypadHandler)
            this.deregisterKeypadHandler();
    }
}


angular.module("app.directives").component("vehicleReadings", {
    template: tmpl,
    bindings: {
        vehicle: "<",
        readonly: "<",
        active: "<",
        dirty: "=?"
    },
    controller: vehicleReadingsCtrl,
    controllerAs: '$ctrl'
});