//import angular from 'angular';
import tmpl from './ocPanel.html';


    angular.module('app.directives')
        .directive('ocPanel', [function () {
            return {
                transclude: true,
                restrict: 'E',
                scope: {
                    heading: "@"
                },
                template: tmpl
            };
        }]);
