//import angular from 'angular';


    angular.module('app')
        .factory('UserFactory', [
            '$state',
            function ($state) {

                // concrete class
                function User() {}

                this.openQuickSearchItem = function (item) {
                    $state.go('app.admin.security.admintab', { personid: item.id, personname: item.text, userssection: true });
                };

                // public interface
                return {
                    openQuickSearchItem: this.openQuickSearchItem
                };
            }]
        );
