//import angular from 'angular';
import * as _ from 'underscore';
import FileManagement from '../../../../services/fileManagement';
import OcDateSvc from '../../../../services/ocDateSvc';
import OcConfigSvc from '../../../../services/ocConfigSvc';
import ReportSvc from '../../../../services/reportSvc';
import tmpl from "./bulkMove.html";



    interface IBulkMoveScope extends IVmScope {
        showEventDetails(item: any);
        showComponentDetails(item: any);
        modifyComponent(item: any);
    }


    angular.module("app.component").component("bulkMove", {
        template: tmpl,
        controller: bulkMoveCtrl,
        controllerAs: "vm"
    });

    // @ts-ignore
    bulkMoveCtrl.$inject = ['$scope', 'amtCommandQuerySvc', 'amtXlatSvc', 'componentUrls', 'ocConfigSvc', 'notifySvc',
        'WindowFactory', 'fileManagement', 'enums', '$timeout', 'confirmSvc', '$q', 'ocDateSvc', 'errorReporter', 'reportSvc',
        'dataBroker'];

    function bulkMoveCtrl($scope: IBulkMoveScope, amtCommandQuerySvc: IAmtCommandQuerySvc, xlatSvc: IAmtXlatSvc, componentUrls, ocConfigSvc: OcConfigSvc, notifySvc: INotifySvc,
        WindowFactory: IWindowFactory, fileManagement: FileManagement, enums, $timeout: ng.ITimeoutService, confirmSvc: IConfirmSvc, $q: ng.IQService, ocDateSvc: OcDateSvc,
        errorReporter: IErrorReporter, reportSvc: ReportSvc, dataBroker: IDataBroker) {

        var vm = this;

        vm.config = ocConfigSvc;
        vm.enums = enums;
        vm.apiUrls = componentUrls;

        vm.status = enums.screenStatus.loading;

        vm.bulkMoveInProgress = false;
        vm.inSummary = false;
        vm.canFinishBulkMove = undefined;
        vm.bulkMoveCompleteOption = xlatSvc.xlat('component.bulkMoveCompleteOption');

        vm.wizardControl = {};
        vm.restart = restart;

        vm.hidePreviousButton = false;
        vm.hideNextButton = true;
        vm.nextText = xlatSvc.xlat("common.wzrd_finish_label");

        vm.componentTypes = undefined;
        vm.selectedComponentType = undefined;

        vm.moveTypes = undefined;
        vm.selectedMoveType = undefined;

        vm.serials = [];
        vm.serialGrid = {};
        vm.noResultsFound_componentType = undefined;
        vm.noResultsFound_moveType = undefined;

        vm.filterOptions = { format: "n0" };

        vm.dateInstructionLabel = undefined;
        vm.selectedItemsLabel = undefined;

        vm.eventDateTime = undefined;

        vm.modifiedComponents = {};

        vm.locations = undefined;
        vm.locationId = undefined;

        vm.getLocations = async function (description) {

            let statusTypes = (await dataBroker.getStatuses()).result;
            statusTypes = statusTypes.filter(x => x.description == description)
            if (statusTypes) {
                let statusType = statusTypes[0];
                let criteria: ILocationCriteria = {
                    siteIds: [vm.config.user.site.id],
                    statusTypeIds: [statusType.key]
                };
                vm.locations = (await dataBroker.getLocations(criteria)).result;
            }
        }

        // move to previous step
        vm.onPrevious = function (step) {

            // if summary we restart
            if (step.stepId === '4') {

                vm.restart(false, false);

            } else {

                step.selected = false;
                step.visited = false;
                step.invalid = false;
                step.$$prevSibling.visited = false;

                vm.hideNextButton = true;
            }
        };

        // move to the next step
        vm.onNext = function (step) {

            // finish
            if (step.stepId === '3') {

                vm.processing = true;

                // validate
                let valid = true;

                if (new Date(vm.eventDateTime) > new Date()) {
                    valid = false;
                    console.log("equipment.eventDateCannotOccurInFuture");
                    notifySvc.error(xlatSvc.xlat("equipment.eventDateCannotOccurInFuture"));
                } else if (_.any(vm.selectedItems, s => vm.eventDateTime <= s.eventDateTime)) {
                    valid = false;
                    console.log("component.bulkMove_eventDatePriorToPreviousDate");
                    notifySvc.error(xlatSvc.xlat("component.bulkMove_eventDatePriorToPreviousDate"));
                }

                if (valid === true) {

                    return vm.bulkMoveComponents().then(function () {

                        vm.bulkMoveInProgress = false;
                        vm.inSummary = true;

                        vm.hidePreviousButton = true;
                        vm.wizardControl.goToStep(3); // go to summary
                        vm.wizardControl.updateInvalid(3, false);

                        return $q.resolve();

                    }).catch(function (error) {

                        //stay on current step
                        vm.wizardControl.goToStep(1);
                        vm.wizardControl.updateInvalid(3, true);

                    }).finally(function () {
                        vm.processing = false;
                    });

                } else {
                    //stay on current step
                    vm.wizardControl.goToStep(1);
                    vm.wizardControl.updateInvalid(3, true);

                    vm.processing = false;

                    return $q.resolve();
                }
            }
        };

        // --------- STEP 1 ---------

        // get the component types (step 1)
        vm.getComponentTypes = function () {

            var criteria = {
                siteIds: [vm.config.user.site.id]
            };

            amtCommandQuerySvc.post(vm.apiUrls.getComponentTypes_short, criteria).then(function (response) {

                if (response) {

                    vm.componentTypes = response;
                    vm.status = enums.screenStatus.ready;
                }

                $timeout();

            }, function (error) {
                vm.defaultErrors = [error];
                vm.status = enums.screenStatus.error;
                errorReporter.logError(error);
            });
        };

        // component type selected (step 1)
        vm.componentTypeClick = function (componentType) {

            // clear any existing components and move type if component type change
            if (!vm.selectedComponentType || vm.selectedComponentType.id !== componentType.id) {
                vm.selectedItems = [];
                vm.serials = [];
                vm.modifiedComponents = {};
                vm.eventDateTime = undefined;
                vm.selectedMoveType = undefined;
            }

            vm.selectedComponentType = componentType;

            vm.hidePreviousButton = false;
            vm.wizardControl.goToStep(1);
            vm.bulkMoveInProgress = true;

            $timeout();
        };

        // --------- STEP 2 ---------

        // get the movement types (step 2)
        vm.getMoveTypes = function () {

            vm.moveTypes = [];

            // inspection -> scrapped
            vm.moveTypes.push({
                id: 'inspection_scrapped',
                description: xlatSvc.xlat('component.inspection_scrapped'),
                categoryFrom: 'inspection',
                categoryTo: 'scrapped',
                fromDescription: xlatSvc.xlat('component.inspection'),
                toDescription: xlatSvc.xlat('component.scrapped'),
                statusTypeFlows: [{
                    statusFrom: 'INSPECTION',
                    statusTo: 'SCRAPPED'
                }]
            });

            // awaiting dispatch -> dispatched
            vm.moveTypes.push({
                id: 'awaitingdispatch_dispatched',
                description: xlatSvc.xlat('component.awaitingdispatch_dispatched'),
                categoryFrom: 'awaitingdispatch',
                categoryTo: 'dispatched',
                fromDescription: xlatSvc.xlat('component.awaiting'),
                toDescription: xlatSvc.xlat('component.dispatchedRepair'),
                statusTypeFlows: [
                    { statusFrom: 'AWAITING DISPATCH - REPAIR', statusTo: 'DISPATCHED - REPAIR' },
                    { statusFrom: 'AWAITING DISPATCH - RETREAD', statusTo: 'DISPATCHED - RETREAD' },
                    { statusFrom: 'AWAITING DISPATCH - RETURN TO SUPPLIER', statusTo: 'DISPATCHED - RETURNED TO SUPPLIER' }
                ]
            });

            // awaiting dispatch -> transferred
            vm.moveTypes.push({
                id: 'awaitingdispatch_transferred',
                description: xlatSvc.xlat('component.awaitingdispatch_transferred'),
                categoryFrom: 'awaitingdispatch',
                categoryTo: 'transfer',
                fromDescription: xlatSvc.xlat('component.awaiting'),
                toDescription: xlatSvc.xlat('component.transferred'),
                statusTypeFlows: [
                    { statusFrom: 'AWAITING DISPATCH - TRANSFER', statusTo: 'DISPATCHED - TRANSFER' }
                ]
            });

            // scrapped -> dispoed
            vm.moveTypes.push({
                id: 'scrapped_dispoed',
                description: xlatSvc.xlat('component.scrapped_disposed'),
                categoryFrom: 'scrapped',
                categoryTo: 'disposed',
                fromDescription: xlatSvc.xlat('component.scrapped'),
                toDescription: xlatSvc.xlat('component.disposed'),
                statusTypeFlows: [{
                    statusFrom: 'SCRAPPED',
                    statusTo: 'DISPOSED'
                }]
            });

            $timeout();
        };

        // move type selected (step 2)
        vm.moveTypeClick = function (moveType) {

            // clear any existing components if move type changed
            if (!vm.selectedMoveType || vm.selectedMoveType.id !== moveType.id) {
                vm.selectedItems = [];
                vm.serials = [];
                vm.eventDateTime = undefined;
                vm.modifiedComponents = {};
            }

            vm.selectedMoveType = moveType;

            vm.noResultsFound_componentType = xlatSvc.xlat('component.bulkMove_noResultsFound_componentType', vm.selectedComponentType.description);
            vm.noResultsFound_moveType = xlatSvc.xlat('component.bulkMove_noResultsFound_moveType', vm.selectedMoveType.description);

            vm.dateInstructionLabel = xlatSvc.xlat('component.bulkMove_dateInstruction' + vm.selectedComponentType.name, vm.selectedMoveType.fromDescription, vm.selectedMoveType.toDescription);


            if (moveType.toDescription == 'Disposed')
                vm.getLocations(moveType.toDescription);

            vm.updateResultCountLabel();

            if (!vm.serials || vm.serials.length === 0) {

                vm.processing = true;

                vm.getBulkMoveComponents().then(function () {

                    $timeout(function () {
                        vm.hidePreviousButton = false;

                        if (vm.serials && vm.serials.length > 0) {
                            vm.hideNextButton = false;
                        }

                        vm.wizardControl.goToStep(2);

                        vm.processing = false;
                    });

                });
            } else {
                vm.hidePreviousButton = false;
                vm.hideNextButton = false;
                vm.wizardControl.goToStep(2);
            }

        };

        // --------- STEP 3 ---------

        // get the components available to be bulk moved
        vm.getBulkMoveComponents = function () {

            var criteria = {
                equipmentTypeId: vm.selectedComponentType.id,
                statuses: vm.selectedMoveType.statusTypeFlows.map(s => s.statusFrom)
            };

            return amtCommandQuerySvc.post(vm.apiUrls.getBulkMoveComponents, criteria).then(function (response) {

                if (response) {
                    vm.serials = response;

                    //Add an event date field (without the time) to use with grid filters
                    vm.serials.forEach(function (serial) {
                        serial.eventDate = new Date(serial.eventDateTime.getFullYear(), serial.eventDateTime.getMonth(), serial.eventDateTime.getDate());
                    });

                    vm.updateResultCountLabel();
                }

            }, amtCommandQuerySvc.handleError).catch(function (error) {
                notifySvc.error(errorReporter.exceptionMessage(error));
            })
        };

        // component/components ticked
        vm.onSelectedItemsChanged = function (items) {

            vm.selectedItems = items;

            vm.serials.forEach(s => {

                let ticked = _.any(vm.selectedItems, c => c.equipmentEventId === s.equipmentEventId);

                s.ticked = ticked;
            });

            vm.updateResultCountLabel();
        };

        // set damage cause/location description on grid load
        vm.rowParseFunction = function (response) {

            var components = response.result;

            for (var r = 0; r < components.length; r++) {
                components[r].damageCauseDescription = (components[r].damageCause ? components[r].damageCause.description : null);
                components[r].damageLocationDescription = (components[r].damageLocation ? components[r].damageLocation.description : null);
            }

            return response;
        };

        // refresh the serial grid
        vm.refreshGrid = function () {
            if (vm.serialGrid.refresh) {
                vm.serialGrid.refresh();
            }
        };

        // update records selected label when components selected/deselected
        vm.updateResultCountLabel = function () {
            vm.selectedItemsLabel = xlatSvc.xlat("component.bulkMove_recordsSelected", (vm.selectedItems ? vm.selectedItems.length : 0), vm.serials.length);
        };

        // move the components (finish button click)
        vm.bulkMoveComponents = async function () {

            let criteria = {
                unmodifiedComponents: [],
                modifiedComponents: [],
                bulkMoveTargetStatusCategory: vm.selectedMoveType.categoryTo,
                equipmentTypeId: vm.selectedComponentType.id,
                locationId: vm.locationId
            };

            let eventDate = ocDateSvc.removeLocalTimeZoneOffset(vm.eventDateTime);

            //add all components as unmodified initially, we remove modified components in the next step
            if (vm.selectedItems && vm.selectedItems.length) {
                criteria.unmodifiedComponents = vm.selectedItems.map(event => ({
                    equipmentEventId: event.equipmentEventId,
                    movementDate: eventDate
                }));
            }

            //if a component is modified remove it from unmodified components and add it to our criteria list of modified components
            for (let key in vm.modifiedComponents || {}) {

                let i = criteria.unmodifiedComponents.findIndex(c => c.equipmentEventId == key);

                if (i < 0)
                    continue;

                criteria.unmodifiedComponents.splice(i, 1);

                let item = vm.modifiedComponents[key];
                item.eventDate = eventDate;

                criteria.modifiedComponents.push(item);
            }

            try {

                let attachments = criteria.modifiedComponents.reduce((acc, curr) => acc.concat(curr.attachments), []);

                for (let attachment of attachments || [])
                    attachment.source = AttachmentType.equipmentEventAttachment;

                await fileManagement.processFileUploads(attachments);

                let response = await amtCommandQuerySvc.post(vm.apiUrls.bulkMoveComponents, criteria);

                if (response) {
                    notifySvc.success(xlatSvc.xlat("component.bulkMoveSuccessful"));

                    vm.bulkMoveSuccessMsg = xlatSvc.xlat('component.bulkMove_successDescription' + vm.selectedComponentType.name,
                        [vm.selectedMoveType.fromDescription, vm.selectedMoveType.toDescription]);

                    // generate component failure reports for any components that requested it
                    if (criteria.modifiedComponents?.length) {
                        let componentsToGenerateComponentFailureReports = criteria.modifiedComponents
                            .filter(c => c.generateComponentFailureReport)
                            .map(c => c.serialNumber.manufacturer);

                        if (componentsToGenerateComponentFailureReports?.length)
                            reportSvc.downloadComponentFailureReports(componentsToGenerateComponentFailureReports);
                    }
                }

            } catch (error) {
                errorReporter.logError(error);
                throw error;
            }
        };

        // only enable Finish button if at least 1 component is selected
        $scope.$watchCollection('vm.selectedItems', function () {
            vm.canFinishBulkMove = (vm.selectedItems && vm.selectedItems.length > 0 ? true : undefined);

            // if nothing is left selected don't let the step go red and claim to be invalid
            if ((!vm.selectedItems || vm.selectedItems.length === 0) && vm.wizardControl.setPristine) {
                vm.wizardControl.setPristine(3);
            }
        });

        // if they clear the date time set the step pristine so it doesn't go red and claim it is invalid
        $scope.$watch('vm.eventDateTime', function () {
            if (!vm.eventDateTime && vm.wizardControl.setPristine) {
                vm.wizardControl.setPristine(3);
            }
        });

        // open the event details for the component (previous event date time link)
        $scope.showEventDetails = function (item) {

            let modified = vm.modifiedComponents[item.equipmentEventId];

            WindowFactory.openItem({
                caption: xlatSvc.xlat('component.editComponentDetailsTitle'),
                component: 'change-status-popup',
                canClose: false,
                initParams: {
                    equipmentEventId: item.equipmentEventId,
                    equipmentId: item.equipmentId,
                    defaultEventDate: item.eventDateTime,
                    isChangeStatus: false,
                    isEdit: false,
                    isBulkMove: true,
                    clientId: item.clientId,
                    siteId: item.siteId,
                    bulkMoveTargetStatusCategory: vm.selectedMoveType.categoryTo,
                    existingStatusDetails: (modified !== null ? modified : null),
                },
                width: 1400,
                height: 600,
                modal: true,
                onDataChangeHandler: $scope.modifyComponent
            });
        };

        // open the component details (serial number link)
        $scope.showComponentDetails = function (item) {
            WindowFactory.openItem({
                component: 'component-details',
                caption: xlatSvc.xlat('equipment.open' + vm.selectedComponentType.name, item.serialNumber.formatted),
                windowRelatedRecordId: item.equipmentId,
                initParams: {
                    equipmentId: item.equipmentId,
                    siteId: item.siteId,
                    componentType: vm.selectedComponentType.name,
                    serialNumber: item.serialNumber.formatted,
                    showCloseOnSave: false
                },
                width: 800,
                height: 850
            });
        };

        // react to modification of component via status change window (previous event date time link)
        $scope.modifyComponent = function (item) {

            var result = item.text.details;

            vm.modifiedComponents[result.equipmentEventId] = result;

            var componentIndex = vm.serials.map(s => s.equipmentEventId).indexOf(result.equipmentEventId);

            if (componentIndex > -1) {

                var component = vm.serials[componentIndex];

                component.damageCause = result.moveDetails.damageCause;
                component.damageLocation = result.moveDetails.damageLocation;

                vm.refreshGrid();
            }

            if (item.text.goToNext === true) {
                if (vm.serials.length > componentIndex + 1) {
                    $scope.showEventDetails(vm.serials[componentIndex + 1]);
                }
            }
        };

        // restart bulk move process
        function restart(requireConfirmation, retainData) {
            if (requireConfirmation) {
                confirmSvc.confirmMessage('component.confirmWizardAbort_Title',
                    'component.confirmWizardAbort_Content').then(function (result) {
                        if (result) {
                            restartProcess(retainData);
                        }
                    });
            } else restartProcess(retainData);
        }

        function restartProcess(retainData) {

            vm.bulkMoveInProgress = false;
            vm.inSummary = false;

            if (!retainData) {
                // reset values                
                vm.selectedMoveType = undefined;
                vm.selectedComponentType = undefined;
                vm.eventDateTime = undefined;
                vm.serials = [];
                vm.selectedItems = [];
                vm.modifiedComponents = {};
            }

            vm.hideNextButton = true;
            vm.hidePreviousButton = true;

            vm.wizardControl.restart();

            init();
        }

        // initialise (get reference data)
        function init() {
            vm.getComponentTypes();
            vm.getMoveTypes();
        }

        init();
    };
