/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Represents the content of the [Kendo UI Drawer component for Angular]({% slug overview_drawer %}).
 */
var DrawerContentComponent = /** @class */ (function () {
    function DrawerContentComponent() {
        this.hostClasses = true;
    }
    tslib_1.__decorate([
        core_1.HostBinding('class.k-drawer-content'),
        tslib_1.__metadata("design:type", Boolean)
    ], DrawerContentComponent.prototype, "hostClasses", void 0);
    DrawerContentComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-drawer-content',
            template: "\n        <ng-content></ng-content>\n    ",
            encapsulation: core_1.ViewEncapsulation.None
        }),
        tslib_1.__metadata("design:paramtypes", [])
    ], DrawerContentComponent);
    return DrawerContentComponent;
}());
exports.DrawerContentComponent = DrawerContentComponent;
