import 'font-awesome/scss/font-awesome.scss'; //TODO: do we need all of font awesome, it probably supports tree shaking to get just the icons we need

//TODO: these make Otracom not look quite right... not sure what that's about, might be that they are being affected by some order dependancy thing...
//looks like this would be an ok spot though based on files.json
import './base/amt-theme-kendo.bootstrap.scss';
import './base/amt-theme-bootstrap-customisations.scss';
import './base/amt-theme-kendo-customisations.scss';

import './base/amt-overrides.scss';
import './base/bootstrap-overrides.scss';
import './base/kendo-overrides.scss';
import './base/common.scss';
import './base/typography.scss';

//TODO: component styles don't belong here - don't add to these!
import './components/actionmenu.scss';
import './components/attachment.scss';
import './components/bulkMove.scss';
import './components/butterbar.scss';
import './components/buttons.scss';
import './components/charts.scss';
import './components/componentReceive.scss';
import './components/componentSpecification.scss';
import './components/details.scss';
import './components/dynamicGrid.scss';
import './components/eom.scss';
import './components/forms.scss';
import './components/inputs.scss';
import './components/misc.scss';
import './components/modals.scss';
import './components/panel.scss';
import './components/pickers.scss';
import './components/purchaseOrder.scss';
import './components/quickSearch.scss';
import './components/readingGrid.scss';
import './components/receive.scss';
import './components/statusChange.scss';
import './components/tab.scss';
import './components/table.scss';
import './components/vehicleSchematic.scss';
import './components/vehicleSurvey.scss';
import './components/window.scss';

import './layout/keypad.scss';
import './layout/layout.scss';
import './layout/navigation.scss';
import './modules/admin.scss';
import './modules/datasync.scss';
import './modules/forecasting.scss';
import './modules/login.scss';
import './modules/notifications.scss';
import './modules/security.scss';
import './modules/stocktake.scss';
import '../modules/site/siteDashboard/site-dashboard.component.scss';