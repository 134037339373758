import AdminUrls from '../../../adminUrls';
import HelperSvc from '../../../../../services/helperSvc';

angular.module('app.admin')
    //.controller('systemAlertsCtrl', ['$scope', 'helperSvc', 'amtCommandQuerySvc', 'amtXlatSvc', 'adminUrls', 'confirmSvc', 'WindowFactory', 'exportSvc', 'notifySvc', 'ocSecuritySvc', 'errorReporter',
    .controller('systemAlertsCtrl', ['$scope', 'ocConfigSvc', 'adminUrls', 'helperSvc', 'amtCommandQuerySvc', 'errorReporter', 'enums', '$timeout', 'amtXlatSvc', 'WindowFactory',
        function ($scope, ocConfigSvc, adminUrls: AdminUrls, helperSvc: HelperSvc, amtCommandQuerySvc, errorReporter, enums, $timeout, xlatSvc, WindowFactory: IWindowFactory) {
            var vm = this;

            vm.config = ocConfigSvc;
            vm.apiUrls = adminUrls;

            vm.gridControl = {};

            vm.alertCriteria = {
                filterValues: {
                    includeAlertsWithNoTriggers: true,
                    alertTypeName: null,
                    includeOnlyIfActionRequired: false,
                    includeOnlyIfAllowsTriggers: true,
                    pageSize: 20,
                    systemAlerts: true
                },
                siteId: ocConfigSvc.user.system.id
            };

            vm.refreshAlertsGrid = function (selectedItems) {
                if (vm.gridControl !== undefined && vm.gridControl.refresh) {
                    vm.gridControl.refresh();
                    $scope.loadDetails(selectedItems);
                }
            };

            $scope.loadDetails = function (items) {
                if (angular.isArray(items)) {
                    vm.selectedAlertType = items[0];

                    var criteria = {
                        id: vm.selectedAlertType.id
                    };

                    amtCommandQuerySvc.post(vm.apiUrls.getSystemAlertTrigger, criteria).then(function (response) {
                        if (response) {

                            vm.trigger = response;

                            WindowFactory.openItem({
                                caption: xlatSvc.xlat('notifications.addTrigger'),
                                component: 'system-alert-popup',
                                initParams: {
                                    alertConfigurationId: vm.trigger.id,
                                    alertType: vm.selectedAlertType,
                                    isEdit: true,
                                    showCloseOnSave: false
                                },
                                canClose: false,
                                width: 800,
                                modal: true,
                                onDataChangeHandler: vm.refreshTriggersGrid
                            });

                            //if ((!vm.trigger.equipmentTypeIds || vm.trigger.equipmentTypeIds.length === 0) && vm.alertType.defaultEquipmentTypeIds) {
                            //    vm.trigger.equipmentTypeIds = vm.alertType.defaultEquipmentTypeIds;
                            //}

                            //$timeout(function () {
                            //    if (vm.detailsForm) {
                            //        vm.detailsForm.$setPristine();
                            //    }
                            //}, 100);

                            vm.status = enums.screenStatus.ready;
                        } else {
                            WindowFactory.openItem({
                                caption: xlatSvc.xlat('notifications.addTrigger'),
                                component: 'system-alert-popup',
                                initParams: {
                                    alertConfigurationId: null,
                                    alertType: vm.selectedAlertType,
                                    isEdit: false,
                                    showCloseOnSave: false
                                },
                                canClose: false,
                                width: 800,
                                modal: true,
                                onDataChangeHandler: vm.refreshTriggersGrid
                            });
                        }
                    }, function (error) {
                        vm.status = enums.screenStatus.error;
                        errorReporter.logError(error);
                    });

                } else {
                    vm.selectedAlertType = null;
                }
            };
        }]);
