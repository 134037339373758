/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var column_cell_template_directive_1 = require("./column-cell-template.directive");
var column_header_template_directive_1 = require("./column-header-template.directive");
/**
 * Represents the column definition of the [MultiColumnComboBox]({% slug overview_multicolumncombobox %})
 * ([see example]({% slug columns_multicolumncombobox %})).
 */
var ComboBoxColumnComponent = /** @class */ (function () {
    function ComboBoxColumnComponent() {
        /**
         * Sets the visibility of the column.
         *
         * @default false
         */
        this.hidden = false;
        /**
         * @hidden
         */
        this.matchesMedia = true;
    }
    tslib_1.__decorate([
        core_1.ContentChild(column_cell_template_directive_1.ColumnCellTemplateDirective, { static: false }),
        tslib_1.__metadata("design:type", column_cell_template_directive_1.ColumnCellTemplateDirective)
    ], ComboBoxColumnComponent.prototype, "cellTemplate", void 0);
    tslib_1.__decorate([
        core_1.ContentChild(column_header_template_directive_1.ColumnHeaderTemplateDirective, { static: false }),
        tslib_1.__metadata("design:type", column_header_template_directive_1.ColumnHeaderTemplateDirective)
    ], ComboBoxColumnComponent.prototype, "headerTemplate", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ComboBoxColumnComponent.prototype, "field", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ComboBoxColumnComponent.prototype, "title", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Number)
    ], ComboBoxColumnComponent.prototype, "width", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], ComboBoxColumnComponent.prototype, "hidden", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], ComboBoxColumnComponent.prototype, "style", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], ComboBoxColumnComponent.prototype, "headerStyle", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], ComboBoxColumnComponent.prototype, "class", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], ComboBoxColumnComponent.prototype, "headerClass", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ComboBoxColumnComponent.prototype, "media", void 0);
    ComboBoxColumnComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-combobox-column',
            template: ''
        })
    ], ComboBoxColumnComponent);
    return ComboBoxColumnComponent;
}());
exports.ComboBoxColumnComponent = ComboBoxColumnComponent;
