import { Inject, Injectable } from "@angular/core";
import { IRootScopeService } from "angular";

@Injectable({
    providedIn: 'root'
})
export default class BrowserSvc {
    private browserChecked = false;
    private online = true;
    private monitoringConnection = false;
    private readonly _isMobile: boolean;
    private readonly _isiOS12orEarlier: boolean = !!(/iPad|iPhone|iPod/.test(navigator.platform));

    public get isMobile(): boolean {
        return this._isMobile;
    }

    public get isOnline(): boolean {
        return window.navigator.onLine;
    }

    private setNetworkStatusLambda = () => this.setNetworkStatus(false);

    constructor(@Inject('$rootScope') private $rootScope: IRootScopeService) {
        this._isMobile = document.documentElement.classList.contains('mobile');
    }

    public setNetworkStatus(force: boolean) {
        if (force || this.online != this.isOnline) {
            this.online = this.isOnline;
            this.$rootScope.$emit('networkChange', this.online);
        }
    }

    public startConnectionMonitoring() {
        if (!this.monitoringConnection) {
            angular.element(window).on('online offline', this.setNetworkStatusLambda);
        }
        this.monitoringConnection = true;
        this.setNetworkStatus(true);
    }

    public stopConnectionMonitoring() {
        angular.element(window).off('online offline', this.setNetworkStatusLambda);
        this.monitoringConnection = false;
    }

    public isSupported() {
        return !(navigator.userAgent.search("Edge") >= 0 || navigator.userAgent.search("SamsungBrowser") >= 0 ||
            (navigator.userAgent.search("Chrome") < 0 && navigator.userAgent.search("CriOS") < 0));
    }

    public isInternetExplorer() {
        return !!(document['documentMode']); // Internet Explorer only property
    }

    public isiOS12orEarlier() {
        return this._isiOS12orEarlier;
    }

    public shouldPromptForUnsupported() {
        return this.browserChecked;
    }

    public shownAlertForUnsupported = function (shown: boolean) {
        this.browserChecked = shown && !this.isInternetExplorer();
    }
}
