//import angular from 'angular';
import * as _ from 'underscore';
import FileManagement from '../../../services/fileManagement';
import DbClass from '../../common/local-db';
import BrowserSvc from '../../../services/browserSvc';
import OcDateSvc from '../../../services/ocDateSvc';
import tmpl from './maintenance.html';
import { TMaintenanceSessionCheckInCheckOutTimeValidator } from '../ModuleServices/maintenanceSessionService';
import { ISetVisualInspectionActiveStatusCriteria } from '../../../interfaces/visualInspection.interfaces';
import { VisualInspectionService } from '../../../services/visual-inspection.service';


const enum MaintenanceSessionTab {
    checkin = 'checkin',
    events = 'events',
    readings = 'readings',
    fitted = 'fitted',
    checkout = 'checkout'
}

export type TMaintenanceSession = {
    id: guid,
    createdDate: Date,
    events: {
        items: TMaintenanceSession_Event[]
    },
    checkin: TMaintenanceSession_Checkin,
    checkedIn: boolean,
    checkout: TMaintenanceSession_CheckOut,
    vehicle: any,
    errorList: any[],
    errorData: any,
    heldInError: boolean,
    updating: boolean,
    pending: boolean,
    assemblies: IMaintenanceSession_Assembly[],
    checkedOut: boolean,
    changeCount: number,
    canBeDeleted: boolean,
    futureEvents: boolean,
    sessionId: any
}

export type TMaintenanceSession_TransferDetails = {
    destinationSiteId: guid,
    destinationOther: string,
    transferredFromMaintenance: boolean
}

export type TMaintenanceSession_Checkin = {
    checkinDate?: Date;
    eventId?: guid;
}

export type TMaintenanceSession_CheckOut = {
    checkoutDate: Date;
    eventId: guid;
    selectedFitters: any[];
    comment: string;
    hasNonRelatedTyreWork: boolean;
    totalDowntime: number;
    nonRelatedTyreWork: number;
    tyreRelatedWork: number;
    delays: TMaintenanceSession_CheckOut_Delay[] | undefined;
    delayTotal: number;
    transferDetails: TMaintenanceSession_TransferDetails;
    eventDate: Date | null;
}

export type TMaintenanceSessionEvents_Api = {
    gridRefresh?: Function;
    gridRebind?: Function;
    refit?: Function;
    stripAssembly?: Function;
}

export type TMaintenanceSession_Event = {
    id: guid;
    editDetail?: any;
    sequenceDate?: Date;
    type: string;
    position: any;
    assemblyId?: guid;
    eventData: {
        edit: boolean;
        type: string,
        event: string;
        typeTranslated: string;
        sessionDate: Date;
        selectedPosition: any;
        components: any[];
        serialNumber: string;
        fitment: any;
        canRefit: boolean;
        selectedMoveTo: any;
        eventDescription: string;
    }
}

export interface IMaintenanceSession_Assembly {
    id: guid; //Not sure what this is? Seems to track the assemblies in session
    previousId?: guid, //Used to track if this fitment in from an assembly remove or not so we can restore the active flag if this is undone
    active: boolean;
    eventType: MaintenanceSession_EventType;
    assemblyId: guid;
    serialNumber: {
        formatted: string;
    };
    equipmentTypeId: guid;
    components: any; //ToDo: Add type for this too
}

export interface TMaintenanceSession_CheckOut_Delay {
    $$hashKey: string;
    isEdit: boolean;
    lastModifiedDate: Date;
    minutes: number;
    reopen: boolean;
    selectedDelayReason: TMaintenanceSession_CheckOut_Delay_Reason;
}

export type TMaintenanceSession_CheckOut_Delay_Reason = {
    $$hashKey: string;
    id: string;
    category: string;
    categoryId: string;
    description: string;
    isActive: boolean;
    key: string;
    name: string;
    referenceDataId: string;
    sequence: any;
}

export default class MaintenanceSessionCtrl implements ng.IController {

    wnd: IWindowObj = null;
    form: ng.IFormController;

    initParams: any;

    session: TMaintenanceSession;
    sessionId: guid;
    vehicle: any;
    vehicleId: guid;

    schematic: any = { loadingSchematic: false };

    schematicDate: Date;

    currentTab: MaintenanceSessionTab;

    defaultDate: Date;
    displayDescription: string;

    schematicOn = true;
    hasReadings = false;
    saving = false;
    pageReady = false;
    showFullSchematic = false;
    mobilePortrait = false;
    showReadOrder = false;
    andValidate = true;

    errorPanel: any = {};
    currentError: any = {};
    error: any;

    isMobile: boolean;
    loadedFitmentData: boolean;
    displayKeyPadLeft: boolean;
    readonly: boolean;
    readingsLoaded: boolean;
    desktopWorkRelatedError: boolean = false;

    loadingExisting: boolean;
    maintenanceSessionLoading: boolean;
    processingValidations: boolean;

    checkoutInterface: any = {};
    eventsApi: TMaintenanceSessionEvents_Api = {};

    // vehicle selector
    vehiclePicker: any = {};
    selectedVehicle: any;
    selectedVehicleId: guid;

    vehicles: any[];
    vehiclesLoaded: boolean;

    vehiclePickerStatusOptions: KeyNamePair[] = [
        { key: 'All', name: this.amtXlatSvc.xlat('maintenanceSession.all') },
        { key: VehicleOperationStatus.ready, name: this.amtXlatSvc.xlat('maintenanceSession.ready') },
        { key: VehicleOperationStatus.inProgress, name: this.amtXlatSvc.xlat('maintenanceSession.inProgress') },
        { key: VehicleOperationStatus.error, name: this.amtXlatSvc.xlat('maintenanceSession.error') },
        { key: VehicleOperationStatus.completed, name: this.amtXlatSvc.xlat('maintenanceSession.completed') },
        { key: VehicleOperationStatus.pending, name: this.amtXlatSvc.xlat('maintenanceSession.pending') }
    ];

    vehiclePickerSelectedStatusId: string | guid;
    vehiclePickerSelectedStatus: KeyNamePair;
    vehiclePickerStatusFilterValue: string;

    // security
    canOpenComponentDetails: boolean;
    canOpenVehicleDetails: boolean;
    canCreateMaintenanceSessions: boolean;
    canEditMaintenanceSessions: boolean;
    canSaveReadings: boolean;

    // vehicle schematic
    readonly schematicMargin = 20;
    readonly schematicDesktopMargin = 4;
    readonly schematicNoChangesWidth = 210;
    readonly keypadWidth = 214;

    topAxle: number;
    leftPos: number;

    schematicSelection = (posId: guid, sel: string) => this.$scope.$broadcast("SCHEMATIC_SELECTION", posId, sel);

    // ref data
    pressureReadingTypes: any[];

    get vehicleSelected() { return this.vehicleId || this.session; }

    get readingActive() { return this.currentTab === MaintenanceSessionTab.readings && this.vehicleSelected; }
    get eventsActive() { return this.currentTab === MaintenanceSessionTab.events && this.vehicleSelected; }

    // showing/hiding of actions
    get showActionsMenu() {
        return this.pageReady && this.vehicleSelected && this.session && !this.readonly // page and session loaded, not readonly
            && this.session.checkedIn // vehicle is checked in             
            && (this.session.checkedOut || this.currentTab !== MaintenanceSessionTab.checkout) // not on checkout tab, or on checkout tab and the vehicle is checked out
            && (this.isMobile || this.session.canBeDeleted || (this.currentTab !== MaintenanceSessionTab.events && this.currentTab !== MaintenanceSessionTab.fitted)); // not on events or fitted tab, or on events/fitted tab and session is allowed to be deleted
    }

    // check in
    get showCheckIn() {
        return !!(this.pageReady && this.vehicleSelected && this.session && !this.readonly // page and session loaded, not readonly             
            && !this.session.checkedIn // not checked in
            && this.currentTab === MaintenanceSessionTab.checkin); // on checkin tab
    }

    get showUpdateCheckIn() {
        return !!(this.currentTab === MaintenanceSessionTab.checkin // on checkin tab
            && !this.isMobile // not mobile
            && this.session && this.session.checkedIn // vehicle checked in
            && !this.session.heldInError && !this.session.pending); // session not in error or pending
    }

    get showUndoCheckIn() {
        return !!(this.currentTab === MaintenanceSessionTab.checkin // on checkin tab
            && this.session && this.session.checkedIn // vehicle checked in
            && !this.session.checkedOut // not checked out
            && (this.isMobile || this.session.checkin.eventId) // mobile, or checkin committed on desktop
            && (!this.session.events || this.session.events.items.length === 0) // no events recorded
            && !this.hasReadings); // no position readings entered
    }

    // check out
    get showCheckOut() {
        return !!(this.pageReady && this.vehicleSelected && this.session && !this.readonly // page and session loaded, not readonly             
            && !this.session.checkedOut // not checked out
            && this.currentTab === MaintenanceSessionTab.checkout // on checkout tab
            && !this.session.heldInError && !this.session.pending // session not in error or pending
            && (this.isMobile || this.ocSecuritySvc.isAuthorised('Security.Vehicles.MaintenanceSession.CheckOut', AccessTypes.readWrite))); // mobile, or user allowed to perform checkout
    }

    get showUpdateCheckOut() {
        return !!(this.currentTab === MaintenanceSessionTab.checkout // on checkout tab
            && !this.isMobile // not mobile
            && this.session && this.session.checkedOut // vehicle checked out
            && !this.session.heldInError && !this.session.pending // session not in error or pending
            && this.ocSecuritySvc.isAuthorised('Security.Vehicles.MaintenanceSession.CheckOut', AccessTypes.readWrite)); // user allowed to perform checkout
    }

    get showUndoCheckOut() {
        return !!(this.currentTab === MaintenanceSessionTab.checkout // on checkout tab
            && this.session && this.session.checkedOut // vehicle checked out
            && (this.isMobile || this.session.checkout.eventId)); // mobile, or checkout committed on desktop
    }

    // delete
    get showDeleteMaintenanceSession() {
        return !!(this.session // there is a session
            && (this.isMobile
                || (this.session.canBeDeleted && this.ocSecuritySvc.isAuthorised('Security.Vehicles.MaintenanceSession.Delete', AccessTypes.readWrite))
            )); // mobile, or session can be deleted and user allowed to perform a delete
    }

    static $inject = ['$scope', 'dataBroker', 'errorReporter', 'WindowFactory',
        'ocConfigSvc', '$state', '$rootScope', '$timeout', 'browserSvc',
        'confirmSvc', 'amtXlatSvc', '$db', 'maintenanceSessionService',
        'ocSecuritySvc', 'notifySvc', 'amtConstants', '$window', 'appConfig', 'fileManagement', 'ocDateSvc', 'amtCommandQuerySvc', 'visualInspectionService'];

    constructor(private $scope: ng.IScope, private dataBroker: IDataBroker, private errorReporter: IErrorReporter, private WindowFactory: IWindowFactory,
        private ocConfigSvc: IOcConfigSvc, private $state: any, private $rootScope: ng.IRootScopeService, private $timeout: ng.ITimeoutService,
        private browserSvc: BrowserSvc, private confirmSvc: IConfirmSvc, private amtXlatSvc: IAmtXlatSvc, private $db: DbClass, private maintenanceSessionService: any,
        private ocSecuritySvc: IOcSecuritySvc, private notifySvc: INotifySvc, private amtConstants: IAmtConstants, private $window: any, private appConfig: IAppConfig, private fileManagement: FileManagement,
        private ocDateSvc: OcDateSvc, private amtCommandQuerySvc: IAmtCommandQuerySvc, private visualInspectionService: VisualInspectionService) {

        try {
            this.dataBroker.checkRefData()
        } catch {
            this.$state.go('mobile.landing');
        }

        this.$scope.$on('PositionClicked', (event, position, selector1, selector2) => {
            event.preventDefault(); // stop this event bubbling to $rootscope  
            this.$scope.$broadcast('PositionClickedInt', position, selector1, selector2); // send it to the child scopes
        });

        this.$scope.$on('SchematicClicked', (event, position, selector) => {
            event.preventDefault(); // stop this event bubbling to $rootscope            
            this.$scope.$broadcast('SchematicClickedInt', position, selector); // send it to the child scopes
        });

        // vehicle selected from the vehicle picker
        this.$scope.$watch(() => this.selectedVehicleId, newvalue => this.onVehicleSelected(newvalue));

        // status changed on the vehicle picker
        this.$scope.$watch(() => this.vehiclePickerSelectedStatusId, (newVal) => {
            if (!newVal || newVal === 'All') {
                this.filterVehiclePickerStatus();
                return;
            }

            for (let option of this.vehiclePickerStatusOptions || []) {
                if (option.key === newVal) {
                    this.filterVehiclePickerStatus(option.key);
                    return;
                }
            }
        }, true);

        this.$scope.$watch(() => this.session, async (currentSession) => {
            if (this.session !== undefined && this.session !== null) {
                let disabledDesktopSaveButton = await this.disableDesktopSaveBtnByCheckInOutTimeRelatedDelays(currentSession);
                this.desktopWorkRelatedError = disabledDesktopSaveButton;
            }
        }, true);

        this.$scope.$watchCollection(() => this.session?.events?.items, () => this.revalidateCanRefit());

        //Once schematic is loaded - re validate whether can re-fit components
        this.$scope.$watch(() => this.schematic.loadingSchematic, () => {
            if (!this.schematic.loadingSchematic)
                this.revalidateCanRefit();
        });

        this.$scope.$watch(() => this.errorPanel.initialised, (newVal) => {

            if (newVal) {

                this.errorPanel.showVehicleDetails = () => this.showVehicleDetails();
                this.errorPanel.showComponentDetails = (id, serial, equipmentType) => this.showComponentDetails(id, serial, equipmentType);

                this.errorPanel.save = () => this.desktopSave();

                // add hyperlinks to hours errors
                let hourErrors = this.errorPanel.checkError('ReadingEventTypeName', 'HOURS', false);

                let vehicleMsg = String.format(this.canOpenVehicleDetails ? ' {0}: <a ng-click="$ctrl.api.showVehicleDetails()" class="text-u-l">{1}</a>' : ' {0}: {1}', this.amtXlatSvc.xlat('component.vehicle'), this.vehicle.serialNumber);

                if (hourErrors)
                    this.errorPanel.appendErrors(null, vehicleMsg, hourErrors);

                // add hyperlinks to distance errors
                let distanceErrors = this.errorPanel.checkError('ReadingEventTypeName', 'DISTANCE', false);

                if (distanceErrors)
                    this.errorPanel.appendErrors(null, vehicleMsg, distanceErrors);

                // add hyperlinks to depth errors
                let depthErrors = [];
                depthErrors = depthErrors.concat(this.errorPanel.checkError('ReadingEventTypeName', 'TREADDEPTH', false));
                depthErrors = depthErrors.concat(this.errorPanel.checkError('ReadingEventTypeName', 'CHAINDEPTH', false));

                if (depthErrors && depthErrors.length > 0) {
                    let componentMsg = ' ' + this.amtXlatSvc.xlat('component.serial') + ': ' + (this.canOpenComponentDetails ? '<a ng-click="$ctrl.api.showComponentDetails(\'{0}\', \'{1}\', \'{2}\')" class="text-u-l">{1}</a>' : '{1}');
                    this.errorPanel.appendErrors(['EquipmentId', 'ManufacturerSerialNumber', 'EquipmentTypeName'], componentMsg, depthErrors);
                }
            }
        });
    }

    private async disableDesktopSaveBtnByCheckInOutTimeRelatedDelays(currentSession: TMaintenanceSession | undefined): Promise<boolean> {
        let validated: TMaintenanceSessionCheckInCheckOutTimeValidator = this.maintenanceSessionService.setValidateCheckInCheckOutTime(currentSession);
        let disableSaveButton: boolean = (validated.isTotalOverTime || validated.isOverDelayWork || validated.isOverRelatedWork) && !this.isMobile;

        await this.handleDeregisterTyreRelatedError(validated);

        return disableSaveButton;
    }

    private async handleDeregisterTyreRelatedError(validated: TMaintenanceSessionCheckInCheckOutTimeValidator) {
        let isBR012: boolean = validated.isOverRelatedWork;
        let isBR013: boolean = validated.isTotalOverTime || validated.isOverDelayWork;
        if (!isBR012) {
            this.session.errorList = this.maintenanceSessionService.deregisterInErrorList(this.session, "BR012");
        }
        if (!isBR013) {
            this.session.errorList = this.maintenanceSessionService.deregisterInErrorList(this.session, "BR013");
        }
    }

    async $onInit() {

        this.isMobile = this.browserSvc.isMobile;

        this.loadedFitmentData = this.isMobile;

        if (this.wnd) {
            // pass along dirty flag to the window for use on closing minimised windows
            this.$scope.$watch(() => this.form.dirty, () => this.wnd.isDirty = this.form.dirty);
            this.wnd.onClose = () => this.close();
        }

        if (this.initParams) {
            this.vehicleId = this.vehicleId || this.initParams.vehicleId;
            this.sessionId = this.initParams.id;

            if (this.initParams.tab)
                this.selectTab(this.initParams.tab);

            if (!this.isMobile && this.initParams.date && this.initParams.serialNumber)
                this.setWindowTitle(this.initParams.date, this.initParams.serialNumber);
        }

        this.canOpenComponentDetails = this.ocSecuritySvc.isAuthorised('Security.Components', AccessTypes.readOnly);
        this.canOpenVehicleDetails = this.ocSecuritySvc.isAuthorised('Security.Vehicles', AccessTypes.readOnly);
        this.canCreateMaintenanceSessions = this.ocSecuritySvc.isAuthorised('Security.Vehicles.MaintenanceSession.Add', AccessTypes.readWrite) && this.ocConfigSvc.user.site.active;
        this.canEditMaintenanceSessions = this.ocSecuritySvc.isAuthorised('Security.Vehicles.MaintenanceSession', AccessTypes.readWrite);
        this.canSaveReadings = this.ocSecuritySvc.isAuthorised('Security.Vehicles.MaintenanceSession.SaveReadings', AccessTypes.readWrite);

        // read only
        this.readonly = !this.isMobile && ((this.loadingExisting && !this.ocSecuritySvc.isAuthorised('Security.Vehicles.MaintenanceSession', AccessTypes.readWrite)) ||
            (!this.loadingExisting && !this.ocSecuritySvc.isAuthorised('Security.Vehicles.MaintenanceSession.Add', AccessTypes.readWrite)));

        // for the schematic
        this.schematic = {
            changeCount: () => this.calculateChangeCount(),
            loadingSchematic: false,
            removeComponents: this.removeComponents,
            maxHeight: 210, // Same as containing div height.
            maxWidth: 0
        };

        this.setSchematicWidth();

        if (this.isMobile) {
            //HACK: 500ms delay via $timeout is a hack for iOS being buggy https://bugs.webkit.org/show_bug.cgi?id=170595
            //when the event triggers the width and height may not be correct
            angular.element(this.$window).on('resize.maintenance', () => this.$timeout(() => this.setSchematicWidth(), 500));
        }

        // no vehicle selected yet, so we are loading the vehicle picker
        if (!this.vehicleId) {

            this.vehiclePickerSelectedStatusId = this.vehiclePickerStatusOptions[0].key;
            this.vehiclePickerSelectedStatus = this.vehiclePickerStatusOptions[0];

            await this.loadVehiclePicker(true);
            return;
        }

        try {
            if (this.vehicleId)
                await this.loadSession(this.sessionId);
        } finally {
            this.setPristine();
        }
    }

    setWindowTitle(checkInDate?: Date, serialNumber?: string) {
        if (this.wnd) {
            if (checkInDate && serialNumber) {
                let checkInDateTimeString = checkInDate.format(this.appConfig.dateFormat.windowTitleDateTime);
                let checkInDateString = checkInDate.format(this.appConfig.dateFormat.windowTitleDate);

                this.wnd.caption = this.amtXlatSvc.xlat('equipment.maintenanceSessionTitle', checkInDateTimeString, serialNumber);
                this.wnd.shortCaption = this.amtXlatSvc.xlat('equipment.maintenanceSessionShortTitle', serialNumber, checkInDateString);
            } else {
                this.wnd.caption = this.amtXlatSvc.xlat('equipment.maintenanceSession');
                this.wnd.shortCaption = this.wnd.caption;
            }
        }
    }

    async loadVehiclePicker(reloadVehicleList?: boolean) {

        if (!this.vehicles || reloadVehicleList) { //update vehicle list and refresh picker

            let vehicles = await this.dataBroker.loadVehicleListMaint();

            this.vehicles = vehicles.result.filter(v => (v.maintenanceStatus && v.maintenanceStatus !== VehicleOperationStatus.ready) ||
                ((this.isMobile || this.canCreateMaintenanceSessions) && (!v.maintenanceStatus || v.maintenanceStatus === VehicleOperationStatus.ready)));

            this.vehiclesLoaded = true;

            if (this.vehiclePicker.refresh)
                this.vehiclePicker.refresh();
        }

        this.checkPageReady();

        // clear out any existing data
        this.session = null;
        this.vehicleId = null;
        this.selectedVehicleId = null;
        this.selectedVehicle = null;
        this.displayDescription = null;
        this.setWindowTitle(); // clear the window title

        if (this.vehiclePicker.clear)
            this.vehiclePicker.clear();
    }

    async onVehicleSelected(vehicleId: guid) {

        try {
            if (!vehicleId || (this.session && vehicleId == this.session.id))
                return;

            // if mobile, check that there isn't already an active field survey on the device for the vehicle
            if (await this.dataBroker.checkVehicleLockedOut(vehicleId, 'maintenanceSession')) {
                let serialNo = this.selectedVehicle && this.selectedVehicle.serialNumber ? this.selectedVehicle.serialNumber : '';
                this.WindowFactory.alert('maintenanceSession.lockedForMS_title', ['common.close_label'], 'maintenanceSession.lockedForMS_body', [serialNo], 600);
                return;
            }

            await this.loadVehicle(vehicleId);

        } catch (error) {
            this.errorReporter.logError(error);
        }
    }

    removeComponents = async (data) => {
        let dialog;

        try {

            let deactivateVisualInspections = false;
            let activeVis = data.components[0].activeVisualInspections;
            if (activeVis != null && activeVis.length > 0) {
                deactivateVisualInspections = await this.checkActiveVisualInspection();
            }

            let postRemoveData;
            let serials = this.removeComponentInit(data);
            dialog = await this.showRemoveComponentDialog(data, serials);

            data.refit = !!data.toPosition;

            while (true) {
                try {
                    data.eventData = await dialog.promise;
                    data.eventData[0].visualInspections = [];
                    this.processingValidations = true;
                    if (dialog)
                        dialog.wnd.processing = true;

                    // deactivate Active Visual inspection
                    if (deactivateVisualInspections) {
                        

                        angular.forEach(activeVis, async function (value, key) {
                            let criteria: ISetVisualInspectionActiveStatusCriteria = {
                                visualInspectionId: value.id,
                                active: false
                            };
                            data.eventData[0].visualInspections.push(criteria);
                        }.bind(this));
                    }

                    postRemoveData = await this.removeComponentProcess(data, serials);
                    break;
                } catch (error) {
                    if (error?.message == 0 /* stupid exception WindowFactory throws on cancel */) {
                        return;
                    } else {
                        this.errorReporter.logError(error);
                        if (dialog)
                            dialog.resetPromise();
                    }

                } finally {
                    this.processingValidations = false;
                    if (dialog)
                        dialog.wnd.processing = false;
                }
            }

            if (data.toPosition) { //and move to...
                let refitData = angular.copy(postRemoveData);
                refitData.eventData.position = data.toPosition;

                if (dialog)
                    this.WindowFactory.closeWindow(dialog.wnd);

                dialog = this.eventsApi.refit(refitData, true);

                try {
                    while (!await dialog.promise) { }

                    this.updateChangeCount();
                    this.triggerUpdateOnFittedTab();
                } catch {
                    // refit was cancelled
                    let eventEntry = this.session.events.items[0];

                    this.$timeout(() => this.eventsApi?.gridRefresh());

                    // if they removed an assembly and this is a historical session, strip the assembly
                    if (eventEntry.eventData.type === EquipmentTypeName.assembly && this.session.checkedOut)
                        await this.eventsApi.stripAssembly(postRemoveData);
                }
            }

        } catch (error) {
            if (error?.message === "maintenanceSession.useUndo")
                await this.WindowFactory.alert("maintenanceSession.error", ["common.ok_label"], "maintenanceSession.useUndo");
            else
                this.errorReporter.logError(error);
        } finally {
            if (dialog)
                this.WindowFactory.closeWindow(dialog.wnd);

            this.processingValidations = false;
        }
    }

    async checkActiveVisualInspection() {
        try {
            await this.confirmSvc.confirmMessage_customButtons('conditionmonitoring.ActiveVisualInspections', 'visualInspection.deactivateActiveVi', 'common.yes_label', 'common.no_label', true)
            return true;
        } catch {
            return false;   // nothing to do here, the user bailed out
        }
        finally {
            //
        }
    }

    async removeComponentProcess(data, serials) {
        let removeData = data.eventData;
        let events = await this.dataBroker.msRemoveEvent(data, this.session);
        let eventEntry;

        // find the position and remove any reading entered (see OR-3519)
        for (let i = 0; i < this.session.vehicle.positionReadings.length; i++) {
            if (this.session.vehicle.positionReadings[i].positionId === data.fromPosition.id) {
                //any reading are now void
                this.maintenanceSessionService.clearReading(this.session.vehicle.positionReadings[i]);
                console.warn('removing entered readings');
            }
        }

        if (removeData.length > 0 && removeData[0].type.toLowerCase() === EquipmentTypeName.tyre)
            serials = serials.reverse();

        // multiple removeData when removing assembly as individual components
        let removeDataCount = 0;
        for (let event of removeData || []) {
            // prevent events having the same sequence date on remove as individual assembly by adding a second for each removal
            let sequenceDate: Date = events ? events[0].date : new Date(Date.now() + removeDataCount * 1000);

            if (events) {
                // multiple components when removing assembly as assembly
                for (let c = 0; c < event.components.length; c++) {
                    event.components[c].eventId = event.components.length > 1 ? events[c].equipmentEventId : events[removeDataCount].equipmentEventId;
                }

                event.events = removeData.length > 1 ? [events[removeDataCount]] : events;
            }

            for (let component of event.components || [])
                component.onSite = true;

            event.serialNumber = event.type === EquipmentTypeName.assembly ? serials.join(' / ') : serials[removeDataCount];
            event.eventDescription = this.amtXlatSvc.xlat('maintenanceSession.eventType_remove', event.selectedMoveTo.description);
            event.typeTranslated = this.amtXlatSvc.xlat('maintenanceSession.' + event.type.toLowerCase());
            event.canRefit = event.selectedMoveTo.name && event.selectedMoveTo.name.toLowerCase() === StatusTypeName.spare; // only refittable when removed to Spare status

            eventEntry = {
                position: { label: event.position.label },
                assemblyId: event.position.assemblyId,
                eventData: event,
                type: event.type.toLowerCase(),
                sequenceDate: sequenceDate,
                id: uuid()
            };

            this.session.events.items.unshift(eventEntry);

            // if we removed an assembly keep track of it
            if (event.type === EquipmentTypeName.assembly) {
                this.session.assemblies = this.session.assemblies || [];
                let assemblySerial = '(' + serials.join('/') + ')';

                if (!this.session.assemblies.some(a => a.serialNumber.formatted === assemblySerial)) {

                    let assembly: IMaintenanceSession_Assembly = {
                        id: event.id,
                        active: true,
                        eventType: MaintenanceSession_EventType.remove,
                        assemblyId: event.position.assemblyId,
                        serialNumber: { formatted: assemblySerial },
                        equipmentTypeId: this.amtConstants.emptyGuid,
                        components: data.components
                    };

                    this.session.assemblies.push(assembly);
                }
            }

            removeDataCount++;
        }

        await this.dataBroker.updateFitmentData(this.session, [data.fromPosition.id], data.components.map(c => c.type));

        this.session.vehicle.updated = uuid();

        try {
            await this.dataBroker.saveMaintenanceSession(this.session);

            await this.$timeout(() => {
                this.eventsApi?.gridRefresh();
                this.schematic?.svgReset();

                this.triggerUpdateOnFittedTab();
                this.updateChangeCount();
            });

            return eventEntry;
        } catch (error) {
            this.errorReporter.logError(error, 'maintenance session save'); // not a user displayed error.
        }
    }

    removeComponentInit(data) {
        if (!data.components || data.components.length === 0)
            throw new Error("no components for remove");

        let componentFitted = this.session.events?.items?.length && this.session.events.items.some(event => event.eventData.event === MaintenanceSession_EventType.fit &&
            (event.eventData.components[0]?.equipmentId || event.eventData.components[0]?.componentId || event.eventData.fitment.equipmentId) ===
            (data.components[0].equipmentId || data.components[0].componentId));

        // Prevent removal if component was fitted in this MS.
        if (componentFitted)
            throw new Error("maintenanceSession.useUndo");

        // set typeId
        let position = this.session.vehicle.positions[data.fromPosition.id];
        if (data.fromPosition && position?.fitments) {
            for (let compData of data.components) {
                for (let fitment of position.fitments) {
                    if (fitment.componentId !== compData.equipmentId)
                        continue;

                    compData.typeId = fitment.typeId;

                    if (data.components.length == 1)
                        data.typeId = fitment.typeId;
                }
            }
        }

        data.sessionDate = this.session.checkin.checkinDate;
        data.checkedOut = this.session.checkedOut;

        let serials = [data.components[0].serialNumberDetails?.hierarchical || data.components[0].serialNumber];

        if (data.components.length > 1) {
            // ensure serials array has rim first, then tyre
            let otherSerial = data.components[1].serialNumberDetails?.hierarchical || data.components[1].serialNumber;

            if (data.components[0].type.toLowerCase() === EquipmentTypeName.tyre /* type of component 0 */)
                serials.unshift(otherSerial);
            else
                serials.push(otherSerial);
        }
        return serials;
    }

    showRemoveComponentDialog(data, serials) {
        let isAssembly = serials.length > 1;
        let type: string = isAssembly ? EquipmentTypeName.assembly : data.components[0].type.toLowerCase()
        let posLabel = this.amtXlatSvc.xlat('maintenanceSession.position');
        let removeLabel = this.amtXlatSvc.xlat('maintenanceSession.remove');
        let typeLabel = this.amtXlatSvc.xlat('maintenanceSession.' + type);
        let serialsLabel = serials.length === 1 ? serials[0] : '(' + serials.join('/') + ')';

        let wnd = this.WindowFactory.openItemAsync({
            component: 'remove-component',
            caption: String.format('{0} {1} - {2} {3} - {4}', posLabel, data.fromPosition.label, removeLabel, typeLabel, serialsLabel),
            initParams: data,
            canClose: false,
            isMobile: this.isMobile,
            width: 700,
            allowOverflow: this.isMobile && /* is Assembly */ serials.length > 1,
            top: this.isMobile ? this.amtConstants.windowTopMarginMobile : null,
            modal: true,
        });

        return wnd;
    }

    updateChangeCount() {
        if (this.session)
            this.session.changeCount = this.calculateChangeCount();
    }

    onVehiclePickerStatusClear() {
        if (this.vehiclePickerStatusOptions.length > 0) {
            this.vehiclePickerSelectedStatusId = this.vehiclePickerStatusOptions[0].key;
            this.vehiclePickerSelectedStatus = this.vehiclePickerStatusOptions[0];
        }
    }

    calculateChangeCount() {
        let cnt = 0;

        let tyreText = this.amtXlatSvc.xlat('equipment.equipmentTypeTyre');

        if (this.session) {
            this.session.changeCount = 0;

            if (this.session.events) {
                // loop over the events
                for (let i = 0; i < this.session.events.items.length; i++) {
                    let data = this.session.events.items[i].eventData;

                    // loop over the components in the event
                    for (let c = 0; c < data.components.length; c++) {
                        if (data.components[c].type === tyreText || data.components[c].type.toLowerCase() === EquipmentTypeName.tyre) {
                            // increment the change count by .5 if the component type is tyre
                            cnt += 0.5;
                        }
                    }
                }
            }
        }

        return cnt;
    }

    triggerUpdateOnFittedTab() {
        this.session.vehicle.updated = uuid();
    }

    filterVehiclePickerStatus(status?) {
        this.vehiclePickerStatusFilterValue = status;

        if (this.vehiclePicker.refresh)
            this.vehiclePicker.refresh();
    }

    schematicToggle() {
        this.schematicOn = !this.schematicOn;
    }

    setSchematicWidth() {
        this.mobilePortrait = this.isMobile && this.$window.innerWidth < this.$window.innerHeight;

        let keypadWidth = this.mobilePortrait ? 0 : this.keypadWidth;

        this.schematic.maxWidth = (this.isMobile ? this.$window.innerWidth : this.wnd.size.width - this.schematicDesktopMargin) - this.schematicMargin;
        this.schematic.maxHeight = 210;

        switch (this.currentTab) {
            case MaintenanceSessionTab.events:
                if (this.mobilePortrait)
                    this.schematic.maxHeight = 310;
                break;
            case MaintenanceSessionTab.readings:
                this.schematic.maxWidth -= keypadWidth;
                break;
        }

        this.$timeout();
    }

    selectPressureReadingType() {
        this.WindowFactory.openItem({
            component: 'pressure-reading-type',
            caption: this.amtXlatSvc.xlat('fieldSurvey.pressureReadingType'),
            width: this.isMobile ? 750 : 500,
            isMobile: this.isMobile,
            initParams: {
                vehicle: this.session.vehicle,
                readOnly: this.readonly,
                pressureReadingTypes: this.pressureReadingTypes
            },
            modal: true,
            canClose: false,
            onDataChangeHandler: () => {
                this.form.$setDirty();
                this.save();
            }
        });
    }

    /**
     * Loads the requested Maintenance Session
     * @param {string} id - Maintenance Session Id (Guid)
     */
    async loadSession(id?: guid) {

        this.pageReady = false;

        let newSession = !id || id === this.amtConstants.emptyGuid;

        // read only
        this.readonly = !this.isMobile && ((!newSession && !this.ocSecuritySvc.isAuthorised('Security.Vehicles.MaintenanceSession', AccessTypes.readWrite)) ||
            (newSession && !this.ocSecuritySvc.isAuthorised('Security.Vehicles.MaintenanceSession.Add', AccessTypes.readWrite)));

        if (!this.defaultDate)
            this.defaultDate = new Date();

        let date = this.ocDateSvc.removeLocalTimeZoneOffset(this.defaultDate);

        this.loadingExisting = true;
        this.maintenanceSessionLoading = true;

        if (id === this.amtConstants.emptyGuid)
            id = null; // loadMaintenanceSession then handles it correctly.        

        try {

            let session = await this.dataBroker.loadMaintenanceSession(this.vehicleId, id, date);

            if (!session) {
                this.errorReporter.logError(new Error("Session was not loaded"));
                return;
            }

            if (!this.isMobile && session.checkedIn)
                await this.updateFitmentData(session);

            if (!session.schematicDate) {
                session.schematicDate = session.createdDate;
            } else {
                this.schematicDate = this.ocDateSvc.removeLocalTimeZoneOffset(session.schematicDate);
            }

            this.vehicle = session.vehicle;
            this.session = session;

            if (!this.session.assemblies)
                this.session.assemblies = [];

            if (this.session && this.session.checkedIn && this.wnd) {
                // after loading the session, set the related, this is necessary because the user could switch vehicles after the window opens.
                this.wnd.windowRelatedRecordId = this.session.checkin.eventId;
            }

            this.updateChangeCount();

            if (!this.currentTab) {
                if (this.session && this.session.checkedIn) {
                    this.selectTab(MaintenanceSessionTab.events);
                } else {
                    this.selectTab(MaintenanceSessionTab.checkin);
                }
            }

            this.loadingExisting = false;
            this.maintenanceSessionLoading = false;

            this.checkPageReady();

            this.displayDescription = this.vehicle.serialNumber + ' - ' + this.vehicle.description;

            if (!this.isMobile)
                this.setWindowTitle(this.session.checkin.checkinDate, this.vehicle.serialNumber);

            if (this.session.vehicle.readingEventTypeCommentId) {
                let pressureReadingTypesResponse = await this.dataBroker.getPressureReadingTypes();

                if (pressureReadingTypesResponse && pressureReadingTypesResponse.result) {
                    this.pressureReadingTypes = pressureReadingTypesResponse.result;
                    this.session.vehicle.readingEventTypeComment = this.pressureReadingTypes.find(p => p.id === this.session.vehicle.readingEventTypeCommentId);
                }
            }

        } catch (error) {

            this.errorReporter.logError(error);

            this.error = this.errorReporter.exceptionMessage(error);

            return this.WindowFactory
                .alert('exception.internal_fault_heading',
                    ['common.ok_label'],
                    'exception.dataError',
                    this.errorReporter.exceptionMessage(error)).then(() => {
                        if (this.wnd) {
                            // let the user out of here.
                            this.WindowFactory.closeWindow(this.wnd);
                        }
                    });
        }
    }

    canSelectItem(item) {

        if (!this.isMobile) // can open anything on desktop
            return true;

        if (item && item.maintenanceStatus) { // if it has no maintenance status, then it's just an id and we should let it through
            if (item.maintenanceStatus === 'Ready')
                return true; // can create a new session

            if (item.sessionDownloaded !== true) {
                // session in flight, but not downloaded

                this.WindowFactory.alert('maintenanceSession.sessionNotDownloadedTitle', ['common.ok_label'],
                    item.maintenanceStatus === 'Pending' ? 'maintenanceSession.sessionPending' : 'maintenanceSession.sessionNotDownloadedText');

                return false;
            }
        }

        // session is downloaded, or not item selected
        return true;
    }

    openSchematic() {

        this.wnd = this.WindowFactory.openItem({
            component: 'full-screen-schematic',
            initParams: {
                equipmentObject: this.session.vehicle,
                positionView: this.currentTab,
                zoomRect: {
                    leftPos: 1,
                    topAxle: 1,
                    positionsHigh: 2,
                    positionsWide: 5
                }
            },
            modal: true,
            width: 600,
            height: 600,
            isMobile: this.isMobile,
            onDataChangeHandler: (data) => {
                this.topAxle = data.zoomRect.topAxle;
                this.leftPos = data.zoomRect.leftPos;
            }
        });

        this.$timeout(() => this.wnd.fullScreen());
    }

    /**
     * Selects the appropriate tab, making any necessary changes.
     * @param {string} tab - The name (uib-tab index) of the tab to select.
     */
    selectTab(tab: MaintenanceSessionTab) {

        this.$timeout(); // Make the schematic re-render or it'll show up as default width/height (100x100)

        this.currentTab = tab;

        // clear selection
        if (this.schematic.schematicSelection)
            this.schematic.schematicSelection();

        this.setSchematicWidth();

        // refresh the grids - need for scrollbar
        if (this.eventsApi.gridRefresh)
            this.eventsApi.gridRefresh();

        switch (tab) {
            case MaintenanceSessionTab.checkin:
                this.checkHasReadings();
                break;
            case MaintenanceSessionTab.fitted:
                this.triggerUpdateOnFittedTab();
                break;
            case MaintenanceSessionTab.readings:
                this.readingsLoaded = true;
                break;
        }

        this.$timeout(() => this.checkHasReadings());
    }

    setPristine() {
        this.$timeout(() => {
            if (this.form)
                this.form.$setPristine();
        });
    }

    async checkIn() {
        // the save will do client side validations
        this.processingValidations = true;

        try {
            await this.save(this.andValidate, 'All');

            let checkinData = this.maintenanceSessionService.setCheckinData(this.session);

            try {
                let response = await this.dataBroker.msCheckinEvent(checkinData);

                this.notifySvc.success(this.amtXlatSvc.xlat("maintenanceSession.vehicleSuccessfullyCheckedIn"));

                this.session.checkedIn = true;

                if (response) {
                    this.sessionId = response.sessionId;
                    this.session.checkin.eventId = response.equipmentEventId;
                    this.wnd.windowRelatedRecordId = response.equipmentEventId;
                }

                await this.save();

                if (!this.isMobile)
                    this.setWindowTitle(this.session.checkin.checkinDate, this.vehicle.serialNumber);

                this.broadcastSessionChange();

                // mobile audit logging
                this.dataBroker.logAuditEntry('Maintenance Session', 'Check In',
                    String.format('{0} ({1})',
                        this.session.vehicle.serialNumber,
                        this.ocDateSvc.toReportString(this.session.checkin.checkinDate)
                    )
                );

                await this.updateFitmentData(this.session);

                // let it become valid then switch to it
                this.$timeout(() => this.selectTab(MaintenanceSessionTab.events));

                this.setPristine();

            } catch (error) {
                this.errorReporter.logError(error);
            }
        } catch (error) {
            // absorb these UI validations
        } finally {
            this.processingValidations = false;
        }
    }

    async checkOut() {

        // the save will do client side validations
        this.processingValidations = true;

        try {

            await this.save(); //Initial save to validate everything...
            await this.save(this.andValidate, 'checkOut'); //Specific save for 'check out' to capture incomplete wheel positions

            if (!this.checkChainEvents()) {
                try {
                    await this.confirmSvc.confirmMessage('maintenanceSession.confirmChainMismatch_title', 'maintenanceSession.confirmChainMismatch');
                } catch {
                    this.selectTab(MaintenanceSessionTab.events);
                    return false; // they cancelled
                }
            }

            let checkoutData = this.maintenanceSessionService.setCheckoutData(this.session);

            try {
                let response = await this.dataBroker.msCheckoutEvent(checkoutData);

                this.notifySvc.success(this.amtXlatSvc.xlat('maintenanceSession.vehicleSuccessfullyCheckedOut'));

                this.session.checkedOut = true;

                if (response)
                    this.session.checkout.eventId = response.equipmentEventId;

                // mobile audit logging
                this.dataBroker.logAuditEntry('Maintenance Session', 'Check Out',
                    String.format('{0} ({1})',
                        this.session.vehicle.serialNumber,
                        this.ocDateSvc.toReportString(this.session.checkout.checkoutDate)
                    )
                );

                await this.save();

                this.broadcastSessionChange();

                this.session = null;
                this.setPristine();

                this.onReturn(); // return to the vehicle selector

            } catch (error) {
                this.errorReporter.logError(error);
            }

        } catch (error) {
            if (error === 'ValidateAbort' || (error && (error.ValidateAbort || error.message === '0' || error.number === 0))) {
                //User aborted out of validation
            } else {
                this.errorReporter.logError(error);
            }
        } finally {
            this.processingValidations = false;
        }
    }

    async updateCheckIn() {

        this.processingValidations = true;

        try {
            await this.save(this.andValidate, 'All');

            let checkinData = this.maintenanceSessionService.setCheckinData(this.session);

            try {
                await this.dataBroker.msUpdateCheckIn(checkinData);

                this.notifySvc.success(this.amtXlatSvc.xlat('maintenanceSession.vehicleSuccessfullyUpdatedCheckIn'));

                this.updateFitmentData(this.session);

                if (!this.isMobile)
                    this.setWindowTitle(this.session.checkin.checkinDate, this.vehicle.serialNumber);

                this.setPristine();
            } catch (error) {
                this.errorReporter.logError(error);
            }

        } catch (error) {
            if (error === 'ValidateAbort' || (error && (error.ValidateAbort || error.message === '0' || error.number === 0))) {
                //User aborted out of validation
            } else {
                this.errorReporter.logError(error);
            }
        } finally {
            this.processingValidations = false;
        }
    }

    async updateCheckOut() {

        this.processingValidations = true;

        try {
            await this.save(this.andValidate, 'checkOut');

            let checkoutData = this.maintenanceSessionService.setCheckoutData(this.session);

            try {
                await this.dataBroker.msUpdateCheckOut(checkoutData);
                this.notifySvc.success(this.amtXlatSvc.xlat("maintenanceSession.vehicleSuccessfullyUpdatedCheckOut"));
            } catch (error) {
                this.errorReporter.logError(error);
            }

        } catch (error) {
            if (error === 'ValidateAbort' || (error && (error.ValidateAbort || error.message === "0" || error.number === 0))) {
                //User aborted out of validation
            } else {
                this.errorReporter.logError(error);
            }
        } finally {
            this.processingValidations = false;
        }
    }

    async undoCheckIn() {

        if (this.session.events && this.session.events.items && this.session.events.items.length)
            return this.WindowFactory.alert('exception.CantUndoHeader', ['common.ok_label'], 'exception.CantUndo');

        this.processingValidations = true;

        let checkinData = this.maintenanceSessionService.setCheckinData(this.session);

        try {
            await this.dataBroker.msUndoCheckin(checkinData.equipmentEventId);

            this.notifySvc.success(this.amtXlatSvc.xlat('maintenanceSession.vehicleSuccessfullyUncheckedIn'));

            let checkinDate = angular.copy(this.session.checkin.checkinDate);

            this.session.checkedIn = false;
            this.session.checkin = {};

            if (this.isMobile) {
                await this.dataBroker.msDeleteSession(this.session);

                // mobile audit logging
                this.dataBroker.logAuditEntry('Maintenance Session', 'Delete Session',
                    String.format('{0} ({1})',
                        this.session.vehicle.serialNumber,
                        this.ocDateSvc.toReportString(checkinDate)
                    )
                );
            }

            this.broadcastSessionChange();

            this.$timeout(() => {
                this.selectTab(MaintenanceSessionTab.checkin);
                this.setPristine();
            });

        } catch (error) {
            this.errorReporter.logError(error);
        } finally {
            this.processingValidations = false;
        }
    }

    async undoCheckOut() {

        try {
            await this.confirmSvc.confirmMessage('maintenanceSession.confirmUndoCheckout_title', 'maintenanceSession.confirmUndoCheckout');
        } catch {
            return; // they cancelled
        }

        this.processingValidations = true;

        try {
            if (!this.session.heldInError && !this.session.pending)
                await this.dataBroker.msUndoCheckOut(this.session.checkout.eventId);

            this.notifySvc.success(this.amtXlatSvc.xlat("maintenanceSession.vehiceCheckOutSuccessfullyUndone"));

            this.session.checkedOut = false;
            this.session.canBeDeleted = true;

            // mobile audit logging
            this.dataBroker.logAuditEntry('Maintenance Session', 'Undo Check Out',
                String.format('{0} ({1})',
                    this.session.vehicle.serialNumber,
                    this.ocDateSvc.toReportString(this.session.checkout.checkoutDate)
                )
            );

            this.session.checkout.selectedFitters = [];
            this.session.checkout.checkoutDate = null;
            this.session.checkout.comment = null;
            this.session.checkout.eventId = null;
            this.session.checkout.hasNonRelatedTyreWork = false;
            this.session.checkout.nonRelatedTyreWork = null;
            this.session.checkout.tyreRelatedWork = 0;
            this.session.checkout.delays = [];

            if (this.checkoutInterface)
                this.checkoutInterface.clearFitters();

            this.revalidateCanRefit();

            this.$timeout(() => this.selectTab(this.session.heldInError || this.session.pending ? MaintenanceSessionTab.events : MaintenanceSessionTab.checkout));

        } catch (error) {
            this.errorReporter.logError(error);
        } finally {
            this.processingValidations = false;
        }
    }

    // this is called on vehicle change
    // mobile loops round to load the details during controller startup, 
    // desktop just loads it.
    async loadVehicle(vehicleId: guid) {

        if (!vehicleId)
            return;

        try {
            this.loadingExisting = true;
            this.session = null;

            this.selectTab(MaintenanceSessionTab.checkin); // load check-in tab, regardless of status.

            if (this.isMobile) {
                this.$state.go('mobile.maintenanceSession', { vehicle: vehicleId, tab: this.currentTab }, { reload: false, location: true });
            } else {

                this.vehicleId = vehicleId;
                let vehicle = this.vehicles.find(v => v.id === vehicleId);

                if (vehicle)
                    await this.loadSession(vehicle.maintenanceSessionId);
            }
        } finally {
            this.loadingExisting = false;
        }
    }

    checkPageReady() {

        if (this.error) // never ready.
            return;

        if ((this.session && !this.loadingExisting) || (!this.vehicleSelected && this.vehiclesLoaded)) {

            this.$timeout(() => this.pageReady = true);

            if (this.session && !this.session.createdDate)
                this.session.createdDate = new Date();

            // validate in the background to allow any errors to highlight/or clear
            if (this.vehicle && this.session && this.form && !this.form.$pristine)
                this.maintenanceSessionService.validate(this.session, null, false, true, this.vehicle);

            this.checkHasReadings();
        }

        this.$timeout(() => this.setPristine());
    }

    broadcastSessionChange() {
        this.$rootScope.$broadcast('RefreshMaintenanceSessionCounts');

        if (this.wnd && this.wnd.onDataChanged)
            this.wnd.onDataChanged();
    }

    readingGridDataReload() {
        this.$scope.$broadcast(Keypad.MODIFIER_KEY_PRESSED, 'RELOAD-READING-GRID');
    }

    async mobileSave() {
        if (this.session) {
            let session = this.session;

            this.session.errorData = undefined; // this is transient data;
            this.session.errorList = undefined; // this is looped so clone or fix; currently not needed for mobile so we can just delete it.

            try {
                await this.dataBroker.saveMaintenanceSession(session);
            } catch (error) {
                console.error("Failed to save maintenance session");
                this.errorReporter.logError(error);
                throw (error);
            }
        }
    }

    async deleteSession() {

        this.processingValidations = true;

        try {
            let response = await this.maintenanceSessionService.deleteSession(this.session, this.session.sessionId);

            if (response) {

                // mobile audit logging
                this.dataBroker.logAuditEntry('Maintenance Session', 'Delete',
                    String.format('{0} ({1})',
                        this.session.vehicle.serialNumber,
                        this.ocDateSvc.toReportString(this.session.checkin.checkinDate)
                    )
                );

                if (this.isMobile) {
                    this.$state.go('mobile.maintenanceSession', { vehicle: null, tab: null }, { reload: true, location: true }); // reset the controller and return to vehicle selector
                } else {
                    if (this.wnd) {
                        if (this.wnd.onDataChanged)
                            this.wnd.onDataChanged();

                        // close the window
                        this.WindowFactory.closeWindow(this.wnd);
                    }
                }

                this.broadcastSessionChange();
            }
        } catch (error) {
            this.errorReporter.logError(error);
        } finally {
            this.processingValidations = false;
        }
    }

    async deleteAllSessions() {

        let arg = await this.WindowFactory.iconAlert(
            'glyphicon-check',
            'maintenanceSession.deleteAll',
            ['framework.cancel_label', 'common.delete_label'],
            'maintenanceSession.deleteAllSessionMsg',
            null,
            650
        );

        if (arg === 'common.delete_label') {

            // delete them all
            let sessions = await this.$db.maintenanceSession.toArray();

            let sessionCount = sessions ? sessions.length : 0;

            for (let s of sessions || [])
                await this.dataBroker.msDeleteSession(s);

            this.notifySvc.success(this.amtXlatSvc.xlat('maintenanceSession.allSessionsSuccessfullyDeleted'));

            // mobile audit logging
            this.dataBroker.logAuditEntry('Maintenance Session', 'Delete All',
                String.format('{0} maintenance session(s) deleted', sessionCount)
            );

            this.$state.go('mobile.landing');
        }
    }

    /**
     * Validate (and save if in mobile mode) the maintenance session.
     * @param {boolean} [andValidate] - Show the Validation errors as pop-ups if true.
     * @param {any} [field] - Specific field to validate (expected to be passed as $event.target).
     * @return {Promise}
     */
    async save(andValidate?, field?, tab?, validateReason?) {
        if (this.saving)
            return;

        this.saving = true;

        try {
            if (this.isMobile && this.session.checkedIn) {
                await this.mobileSave(); // save it to local DB first           
            }

            let skipUi = !this.isMobile && andValidate !== true;
            await this.maintenanceSessionService.validate(this.session, null, false, skipUi, this.vehicle, field, tab, validateReason);
        } finally {
            this.saving = false;
        }
    }

    // save an errored maintenance session on the desktop
    async desktopSave() {

        // the save will do client side validations
        this.processingValidations = true;

        try {

            await this.save(this.andValidate, 'All');

            try {
                await this.saveVisualInspectionAttachments();

                let errors = await this.maintenanceSessionService.directUploadRecord(this.session);

                this.session.errorList = [];

                if (errors.length > 0) {

                    // hook the error list in here.
                    if (errors[0].subErrors) {
                        this.session.errorList = this.session.errorList.concat(errors[0].subErrors);

                        this.notifySvc.error(errors[0].subErrors.length > 1 ? this.amtXlatSvc.xlat('maintenanceSession.saveMultipleErrors') :
                            this.errorReporter.exceptionMessage({ message: errors[0].subErrors[0].Message })
                        );
                    } else {
                        this.notifySvc.error(this.errorReporter.exceptionMessage(errors[0]));
                    }

                } else {

                    this.notifySvc.success(this.amtXlatSvc.xlat('maintenanceSession.saveSuccessful'));

                    if (this.wnd && this.wnd.onDataChanged)
                        this.wnd.onDataChanged();

                    if (this.session.checkedOut && this.wnd) {
                        this.WindowFactory.closeWindow(this.wnd); // close the window
                    } else {
                        //reload the session
                        this.session = null;
                        this.loadingExisting = true;

                        try {
                            await this.loadSession(this.sessionId);
                        } finally {
                            this.setPristine();
                        }
                    }
                }

                this.broadcastSessionChange();
            } catch (error) {
                this.errorReporter.logError(error);
            }
        } catch (error) {
            // absorb these UI validations
            this.errorReporter.logError(error, "", true, true);
        } finally {
            this.processingValidations = false;
        }
    }

    async onReturn(returnHome?: boolean) {

        this.processingValidations = true;

        try {

            if (this.session) {
                if (this.isMobile && !this.session.checkedOut) { // validate and save changes
                    if (!this.checkChainEvents()) {
                        try {
                            await this.confirmSvc.confirmMessage('maintenanceSession.confirmChainMismatch_title', 'maintenanceSession.confirmChainMismatch');
                        } catch {
                            this.selectTab(MaintenanceSessionTab.events);
                            return false; // they cancelled
                        }
                    }

                    try {
                        await this.maintenanceSessionService.validate(this.session, null, undefined, undefined, this.vehicle);
                        await this.save();
                    } catch {
                        // validation error already handled
                    }
                } else { // confirm with user if they want to abandon their unsaved changes
                    try {
                        await this.confirmSvc.confirmSaveChange(this.form.$dirty);
                    } catch {
                        return; //they cancelled returning to the vehicle list
                    }
                }
            }

            if (this.isMobile) {
                if (returnHome) {
                    this.$state.go('mobile.landing'); // return to home screen
                } else {
                    this.$state.go('mobile.maintenanceSession', { vehicle: null, tab: null }, { reload: true, location: true }); // reset the controller and return to vehicle selector
                }
            } else {
                await this.loadVehiclePicker(true);
            }

        } finally {
            this.processingValidations = false;
        }
    }

    async close() {
        if (this.session && !this.session.checkedOut) {
            if (!this.checkChainEvents()) {
                try {
                    await this.confirmSvc.confirmMessage('maintenanceSession.confirmChainMismatch_title', 'maintenanceSession.confirmChainMismatch');
                } catch {
                    this.selectTab(MaintenanceSessionTab.events);
                    return false; // they cancelled
                }
            }
        }

        try {
            await this.confirmSvc.confirmSaveChange(this.form.$dirty);
        } catch {
            return false; // they cancelled
        }

        this.WindowFactory.closeWindow(this.wnd);
        return true;
    }

    revalidateCanRefit() {
        if (this.session?.events?.items?.length) {

            for (let item of this.session.events.items) {
                if (item.eventData) {
                    let edc: any[] = item.eventData.components.map(c => c.equipmentId || c.componentId || c.id);

                    let futureEvent = this.session.events.items.find(i => i.eventData && (i.sequenceDate > item.sequenceDate) &&
                        i.eventData.components.some(ic => edc.includes(ic.equipmentId || ic.componentId || ic.id))
                    );

                    let hasPositions = this.hasAvailablePositions(item.eventData.type);

                    item.eventData.canRefit = hasPositions && item.eventData.selectedMoveTo && item.eventData.selectedMoveTo.name && item.eventData.selectedMoveTo.name.toLowerCase() === StatusTypeName.spare && !futureEvent
                        && !this.session.futureEvents
                        && (this.isMobile || (!this.isMobile && !this.session.checkedOut));
                }
            }

            if (this.eventsApi.gridRebind)
                this.eventsApi.gridRebind();

            if (this.eventsApi?.gridRefresh)
                this.eventsApi?.gridRefresh();
        }
    }

    checkHasReadings() {
        this.hasReadings = false;

        if (!this.session || !this.session.vehicle || !this.session.vehicle.positionReadings)
            return;

        for (let pr of this.session.vehicle.positionReadings) {
            if (pr.adjPressure || pr.pressure || pr.rtd1 || pr.rtd2 || pr.temp) {
                this.hasReadings = true;
                return;
            }
        }
    }

    errorPanelSaveCallback() {
        // only save readings if session is not in Error or Pending
        if (this.session.heldInError || this.session.pending) {
            return this.desktopSave();
        } else {
            return this.saveReadings();
        }
    }

    async saveVisualInspectionAttachments() {

        if (this.session && this.session.vehicle) {
            let attachments = [];

            for (let reading of this.session.vehicle.positionReadings || []) {
                for (let vi of reading.visualInspections || []) {
                    for (let attachment of vi.attachments || []) {
                        attachment.source = AttachmentType.equipmentEventAttachment;
                        attachments.push(attachment);
                    }
                }
            }

            await this.fileManagement.processFileUploads(attachments);
        }
    }

    // save changes on the readings tab (desktop)
    async saveReadings() {

        this.processingValidations = true;

        try {
            await this.save(this.andValidate, null, 'readings');

            await this.saveVisualInspectionAttachments();

            let unmodifiedPositions = angular.copy(this.session.vehicle.positionReadings.filter(pr =>
                !this.session.vehicle.positions[pr.positionId].fittedInThisSession && this.session.vehicle.positions[pr.positionId].TyreFitment));

            let visualInspectionsSaved = false;

            for (let pos of unmodifiedPositions || []) {

                pos.visualInspections = pos.visualInspections?.filter(v => v.modified);

                if (pos.visualInspections?.length)
                    visualInspectionsSaved = true;

                for (let vi of pos.visualInspections || [])
                    vi.inspectionDateTime = this.ocDateSvc.dateTimeAsUTC(vi.inspectionDateTime);

                if (pos.pressure != null)
                    pos.readingEventTypeCommentId = this.session.vehicle.readingEventTypeCommentId;
            }

            try {
                await this.dataBroker.recordMaintenanceSessionReadings(this.session.checkin.eventId, unmodifiedPositions);

                this.notifySvc.success(this.amtXlatSvc.xlat('maintenanceSession.readingsSaveSuccessful'));

                if (visualInspectionsSaved)
                    await this.updateFitmentData(this.session);

            } catch (error) {
                this.errorReporter.logError(error);
            }
        } catch (error) {
            if (error === 'ValidateAbort' || (error && (error.ValidateAbort || error.message === "0" || error.number === 0))) {
                //User aborted out of validation
            } else {
                this.errorReporter.logError(error);
            }
        } finally {
            this.processingValidations = false;
        }
    }

    hasAvailablePositions(componentType: string): boolean {

        let hasPositions = false;

        if (this.vehicle.schematic && this.vehicle.schematic.axles) {
            let availablePositions = _.filter(this.dataBroker.schematicToPositionsArray(this.vehicle.schematic), (position) => {

                // 1. For Assembly or Rim, only return the position if there are no fitments.
                if ((componentType.toLowerCase() === EquipmentTypeName.assembly || componentType.toLowerCase() === EquipmentTypeName.rim) && position.fitments.length === 0)
                    return true;

                // 2. For Tyre
                if (componentType.toLowerCase() === EquipmentTypeName.tyre) {
                    if (this.ocConfigSvc.user.site.settings.tyreMaintenance.trackRims &&
                        _.some(position.fitments, fitment => fitment.type.toLowerCase() === EquipmentTypeName.rim) &&
                        _.every(position.fitments, fitment => fitment.type.toLowerCase() !== EquipmentTypeName.tyre)) {
                        // Site is tracking Rims, only return the position if there's a Rim fitment and no Tyre fitments.
                        return true;
                    } else if (!this.ocConfigSvc.user.site.settings.tyreMaintenance.trackRims &&
                        _.every(position.fitments, fitment => fitment.type.toLowerCase() !== EquipmentTypeName.tyre)) {
                        // Site is not tracking Rims, only return the position if there's no Tyre fitments.
                        return true;
                    }
                }

                // 3. For Chain, only return the position if there is a Tyre fitment.
                return componentType.toLowerCase() === EquipmentTypeName.chain &&
                    _.some(position.fitments, fitment => fitment.type.toLowerCase() === EquipmentTypeName.tyre) &&
                    _.every(position.fitments, fitment => fitment.type.toLowerCase() !== EquipmentTypeName.chain);
            });

            hasPositions = availablePositions && availablePositions.length > 0;
        }

        return hasPositions;
    }

    async updateFitmentData(session) {

        await this.dataBroker.updateFitmentData(session, Object.keys(session.vehicle.positions), null);

        this.$timeout(() => {
            if (this.eventsApi && this.eventsApi.gridRefresh)
                this.eventsApi.gridRefresh();

            if (this.schematic && this.schematic.svgReset)
                this.schematic.svgReset();
        });

        this.loadedFitmentData = true;
    }

    showVehicleDetails() {
        this.WindowFactory.openItem({
            component: 'vehicle-details',
            caption: this.amtXlatSvc.xlat('equipment.openVehicle', this.vehicle.serialNumber),
            windowRelatedRecordId: this.vehicle.id,
            isMobile: this.isMobile,
            initParams: {
                equipmentId: this.vehicle.id,
                serialNumber: this.vehicle.serialNumber.formattedSerialNumber,
                showCloseOnSave: true
            },
            width: 800,
            height: 850,
            modal: false
        });
    }

    showComponentDetails(id, serial, equipmentType) {
        this.WindowFactory.openItem({
            component: 'component-details',
            caption: String.format(this.amtXlatSvc.xlat('equipment.open' + equipmentType), serial),
            windowRelatedRecordId: id,
            initParams: {
                equipmentId: id,
                serialNumber: serial,
                showCloseOnSave: true
            },
            width: 800,
            height: 850,
            isMobile: this.isMobile,
            modal: false
        });
    }

    $onDestroy() {
        angular.element(this.$window).off('resize.maintenance');
    }

    checkChainEvents(): boolean {
        var chainRemovals = this.session.events.items.filter(function (el) {
            return el.type === "chain" && el.eventData.event === "remove"
        });

        var chainFitments = this.session.events.items.filter(function (el) {
            return el.type === "chain" && el.eventData.event === "fit"
        });

        return (chainRemovals.length - chainFitments.length === 0);
    }
}

class MaintenanceSessionComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = MaintenanceSessionCtrl;
    public controllerAs = 'vm';
}

angular.module('app').component('maintenance', new MaintenanceSessionComponent());