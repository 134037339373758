//import angular from 'angular';


angular.module('app').factory('notifySvc', ['amtXlatSvc', 'enums',
    function (amtXlatSvc, enums) {
        var xlat = amtXlatSvc.xlat;

        // the $scope.notification is in index.cshtml (data-kendo-notification)
        // http://docs.telerik.com/kendo-ui/controls/layout/notification/overview
        var notificationHandler = null;
        
        var service = {
            successXlat: successXlat,
            success: success,
            error: error,
            errorXlat: errorXlat,
            info: info,
            infoXlat: infoXlat,
            show: show,
            showXlat: showXlat,            
        };
        return service;
        
        function successXlat(message, parameters) {
            success(xlat(message, parameters));
        }

        function success(message) {
            showNotify(enums.notification.success, message);
        }


        function errorXlat(message, parameters) {
            error(xlat(message, parameters));
        }

        // this is user error, edit trying to do something invalid, like reusing a password.
        function error(message) {
            showNotify(enums.notification.error, message);
        }

        function infoXlat(message, parameters) {
            info(xlat(message, parameters));
        }

        function info(message) {
            showNotify(enums.notification.info, message);
        }

        function showXlat(message, type, parameters) {
            show(xlat(message, parameters), type);
        }

        function show(message, type) {
            console.warn("should use: info, error, success instead of show method");
            showNotify(type, message);
        }

        function showNotify(type, message) {
            if (!notificationHandler) {
                // @ts-ignore
                notificationHandler = $('#notificationContainer').kendoNotification().data("kendoNotification");                

                if (!notificationHandler) {
                    // this is normally the unit tests, running some code in isolation so there is no outer place to toast to
                    console.warn("No Notification handler configured");
                    console.warn("   " + type + ":" + message);
                    return;
                }
            }

            notificationHandler.options.autoHideAfter = (type === enums.notification.success ? 8000 : 15000);

            if (!notificationHandler[type]) {
                console.warn("   " + type + ":" + message);
                throw new Error("Unknown notification type:" + type);
            }
            notificationHandler[type](message);
        }
    }
]);