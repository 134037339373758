//import angular from 'angular';


export default class StocktakeUrls {
    private readonly apiPath = baseApiUrl;
    private readonly controller = 'stocktake/';

    public readonly search = this.apiPath + this.controller + 'searchStocktakes';
    public readonly searchExport = this.controller + 'searchStocktakeExport';

    public readonly getComponentTypes = this.apiPath + 'equipment/component/getComponentTypes';
    public readonly getLevels = this.apiPath + this.controller + 'getLevels';
    public readonly getStatuses = this.apiPath + 'equipment/component/getStatuses';
    public readonly getDefaults = this.controller + 'getDefaults';
    public readonly deleteStocktake = this.controller + 'deleteStocktake';
}

angular.module('app').service('stocktakeUrls', StocktakeUrls);