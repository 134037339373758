/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var dragging_service_1 = require("./dragging-service");
var core_1 = require("@angular/core");
/**
 * Holds the content of the header section of the TileLayoutItem component.
 * This is the area which can be dragged to reorder the items if reordering is enabled for this item.
 */
var TileLayoutItemHeaderComponent = /** @class */ (function () {
    function TileLayoutItemHeaderComponent(draggingService) {
        this.draggingService = draggingService;
        this.hostClass = true;
    }
    Object.defineProperty(TileLayoutItemHeaderComponent.prototype, "touchActionNone", {
        get: function () {
            return this.draggingService.reorderable.getValue();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TileLayoutItemHeaderComponent.prototype, "touchActionAuto", {
        get: function () {
            return !this.draggingService.reorderable.getValue();
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        core_1.HostBinding('class.k-tilelayout-item-header'),
        core_1.HostBinding('class.k-card-header'),
        tslib_1.__metadata("design:type", Boolean)
    ], TileLayoutItemHeaderComponent.prototype, "hostClass", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-touch-action-none'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], TileLayoutItemHeaderComponent.prototype, "touchActionNone", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-touch-action-auto'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], TileLayoutItemHeaderComponent.prototype, "touchActionAuto", null);
    TileLayoutItemHeaderComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-tilelayout-item-header',
            template: "\n        <ng-content></ng-content>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [dragging_service_1.TileLayoutDraggingService])
    ], TileLayoutItemHeaderComponent);
    return TileLayoutItemHeaderComponent;
}());
exports.TileLayoutItemHeaderComponent = TileLayoutItemHeaderComponent;
