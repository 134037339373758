//import angular from 'angular';
import * as _ from 'underscore';
import HelperSvc from '../../../../services/helperSvc';
import AdminUrls from '../../adminUrls';



    angular.module('app.admin').controller("componentSpecificationsSearchCtrl", componentSpecificationsSearchCtrl);

    // @ts-ignore
    componentSpecificationsSearchCtrl.$inject = ["adminUrls", "$scope", "enums", "ocSecuritySvc", "amtXlatSvc", "$timeout", "WindowFactory", "ocConfigSvc",
        "exportSvc", "helperSvc", "componentUrls", "amtCommandQuerySvc", "confirmSvc", "notifySvc", "errorReporter"];

    // ReSharper disable once InconsistentNaming
    function componentSpecificationsSearchCtrl(adminUrls: AdminUrls, $scope, enums, ocSecuritySvc, amtXlatSvc, $timeout, WindowFactory, ocConfigSvc,
        exportSvc, helperSvc: HelperSvc, componentUrls, amtCommandQuerySvc, confirmSvc, notifySvc, errorReporter) {

        var vm = this;

        vm.processing = false;
        // security
        vm.canAddRemoveFromSite = ocSecuritySvc.isAuthorised('Security.Settings.SpecificationsComponents', 'readWrite');

        vm.showClients = false;
        vm.showSites = false;
        vm.showOTD = false;
        vm.showChain = false;
        vm.showDepth = false;
        vm.adminUrls = adminUrls;
        vm.componentUrls = componentUrls;

        vm.config = ocConfigSvc;

        vm.sources = [{
            name: amtXlatSvc.xlat('specifications.onSite'),
            key: true
        }, {
            name: amtXlatSvc.xlat('specifications.centralLibrary'),
            key: false
        }];

        vm.status = enums.screenStatus.loading;
        vm.isDirty = true;

        vm.gridControl = {};
        vm.selectedItem = null;

        function init() {

            vm.canAdd = ocSecuritySvc.isAuthorised('Security.Settings.SpecificationsComponents.Add', 'readWrite', null, null, true);

            vm.loadComponentTypes().then(function () {
                vm.loadDefaults();
            });
        }

        vm.onDatabound = function () {
            vm.selectedItem = null;
        };

        vm.onSelectedItemChanged = function (items) {
            if (items != null && items.length === 1) {
            } else {
                vm.selectedItem = null;
            }
        };

        vm.setHeaderHeight = function (height, offset) {
            vm.gridTop = height + offset;
        };

        vm.loadComponentTypes = function () {
            return amtCommandQuerySvc.post(vm.adminUrls.getEquipmentTypes, { includeVehicle: false }).then(function (response) {
                if (response && response.result) {
                    vm.componentTypes = response.result;
                }
            }, amtCommandQuerySvc.handleError);
        };

        vm.loadDefaults = function () {

            vm.defaults = {
                source: vm.sources[0],
                componentType: _.find(vm.componentTypes, function (ct) {
                    return ct.name.toLowerCase() === enums.equipmentTypes.tyre;
                })
            };

            vm.status = enums.screenStatus.ready;

            // focus on description field by default
            $timeout(() => { vm.focusDescription = true });
        };

        vm.export = function () {
            exportSvc.exportData(vm.adminUrls.searchComponentSpecificationsExport, vm.criteria, amtXlatSvc.xlat('specifications.componentSearchTitle'));
        };

        vm.onSearchComponentSpecifications = function (response) {

            var specifications = response.result;

            if (specifications && specifications.length > 0) {

                var yesLabel = amtXlatSvc.xlat('common.yes_label');
                var noLabel = amtXlatSvc.xlat('common.no_label');

                for (var r = 0; r < specifications.length; r++) {
                    specifications[r].onSiteDisplay = (specifications[r].onSite === true ? yesLabel : noLabel);
                }
            }

            return response;
        };

        $scope.editSpecification = function (item) {
            if (item) {
                WindowFactory.openItem({
                    component: 'component-specification-details',
                    caption: item.specification,
                    windowRelatedRecordId: item.id,
                    initParams: {
                        id: item.id,
                        equipmentType: item.componentTypeName
                    },
                    width: 780,
                    height: 700,
                    onDataChangeHandler: vm.filter
                });
            }
        };

        vm.onComponentTypeChange = function (item) {
            var isChain = false;
            var isTyre = false;
            var isDepth = false;

            if (item != null) {
                if (item.name == 'Chain') {
                    isChain = true;
                    isDepth = true;
                }
                if (item.name == 'Tyre') {
                    isTyre = true;
                    isDepth = true;
                }
            } else {
                isChain = true;
                isTyre = true;
                isDepth = true;
            }

            vm.showOTD = isTyre;
            vm.showChain = isChain;
            vm.showDepth = isDepth;

        };

        $scope.addSpecification = function () {
            WindowFactory.openItem({
                component: 'component-specification-details',
                caption: amtXlatSvc.xlat("specifications.addComponentSpecification"),
                windowRelatedRecordId: uuid(),
                initParams: null,
                width: 700,
                height: 700,
                onDataChangeHandler: vm.filter
            });
        };

        $scope.addToSite = function (item) {
            confirmSvc.confirmMessage('specifications.addToSite_title', 'specifications.addToSite', vm.config.user.site.name).then(function () {
                vm.processing = true;

                amtCommandQuerySvc.get(vm.adminUrls.addSpecificationToSite, { equipmentTypeSpecificationTypeId: item.id }).then(function (response) {

                    notifySvc.success(amtXlatSvc.xlat('specifications.addedToSiteSuccessfully'));
                    vm.filter();

                }).catch(function (error) {
                    errorReporter.logError(error);
                }).finally(function () {
                    vm.processing = false;
                });
            });
        };

        $scope.removeFromSite = function (item) {
            confirmSvc.confirmMessage('specifications.removeFromSite_title', 'specifications.removeFromSite', vm.config.user.site.name).then(function () {
                vm.processing = true;

                amtCommandQuerySvc.get(vm.adminUrls.removeSpecificationFromSite, { equipmentTypeSpecificationTypeId: item.id }).then(function (response) {

                    notifySvc.success(amtXlatSvc.xlat('specifications.removedFromSiteSuccessfully'));
                    vm.filter();

                }).catch(function (error) {
                    errorReporter.logError(error);
                }).finally(function () {
                    vm.processing = false;
                });
            });
        };

        vm.refreshGrid = function () {
            vm.gridControl.refresh();
        };

        vm.filter = function () {
            vm.refreshGrid();
        };

        init();
    }
