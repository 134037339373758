/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var messages_1 = require("./messages");
/**
 * @hidden
 */
var LocalizedColorPickerMessagesDirective = /** @class */ (function (_super) {
    tslib_1.__extends(LocalizedColorPickerMessagesDirective, _super);
    function LocalizedColorPickerMessagesDirective(service) {
        var _this = _super.call(this) || this;
        _this.service = service;
        return _this;
    }
    LocalizedColorPickerMessagesDirective_1 = LocalizedColorPickerMessagesDirective;
    var LocalizedColorPickerMessagesDirective_1;
    LocalizedColorPickerMessagesDirective = LocalizedColorPickerMessagesDirective_1 = tslib_1.__decorate([
        core_1.Directive({
            providers: [
                {
                    provide: messages_1.ColorPickerMessages,
                    useExisting: core_1.forwardRef(function () { return LocalizedColorPickerMessagesDirective_1; }) // tslint:disable-line:no-forward-ref
                }
            ],
            selector: '[kendoColorPickerLocalizedMessages], [kendoFlatColorPickerLocalizedMessages], [kendoColorGradientLocalizedMessages], [kendoColorPaletteLocalizedMessages]'
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService])
    ], LocalizedColorPickerMessagesDirective);
    return LocalizedColorPickerMessagesDirective;
}(messages_1.ColorPickerMessages));
exports.LocalizedColorPickerMessagesDirective = LocalizedColorPickerMessagesDirective;
