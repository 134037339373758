//import angular from 'angular';
import DbClass from '../../common/local-db';
import BrowserSvc from '../../../services/browserSvc';
import tmpl from './serialChange.html';



    angular.module('app.fieldSurveys').component('serialChange', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['WindowFactory', '$scope', 'amtXlatSvc', 'enums', 'confirmSvc', 'dataBroker', '$db', 'browserSvc',
            function (WindowFactory: IWindowFactory, $scope, amtXlatSvc: IAmtXlatSvc, enums, confirmSvc, dataBroker, $db: DbClass, browserSvc: BrowserSvc) {
                var vm = this;
                $scope.vm = this;
                vm.autoCompleteOptions = {
                    noDataTemplate: amtXlatSvc.xlat('framework.noDataFound')
                };
                vm.form = {};

                this.$onInit = function () {
                    var type = vm.wnd.initParams.type;
                    var pos = vm.wnd.initParams.reading.positionId;
                    var schematic = vm.wnd.initParams.schematic;
                    dataBroker.getValidFitments(type, pos, "ALL", schematic).then(function(data) {
                        vm.localDataSource = new kendo.data.DataSource({
                            data: data
                        });
                    });

                    angular.extend(vm, vm.wnd.initParams);
                    
				    var capName = vm.initParams.name.substr(0, 1).toUpperCase() + vm.initParams.name.substr(1).toLowerCase();        
                    vm.currentSerialLabelText = amtXlatSvc.xlat('fieldSurvey.CurrentSerial', vm.initParams.name) + ':';
                    vm.serialChangeLabelText = amtXlatSvc.xlat('fieldSurvey.SerialChange', vm.initParams.name) + ':';

                    vm.newSerial = vm.wnd.initParams.reading[vm.initParams.name + 'Serial'];
                    vm.chainRemoved = vm.wnd.initParams.reading.chainRemoved;
                    vm.hadStartingVal = !!vm.newSerial;
                    vm.orginalChainState = vm.chainRemoved;

					vm.buttons = [
						{
							primary: true, 
							cancel: false, 
							value: 'framework.close_label',
							name: 'closeButton',
							click: 'close',
							type: enums.buttonTypes.button
						},
						{
							primary: false, 
							cancel: false, 
							value: 'fieldSurvey.updateSerialNamed',
							valueParameters: [capName], 
							name: 'updateButton',
							click: 'update',
							type: enums.buttonTypes.button
						}
					];
					
					vm.buttonStates = {
						closeButton: {
							visible: true, 
							disabled: false
						},
						updateButton: {
							visible: true, 
							disabled: true
						}
					};
					
					vm.buttonMethods = {
						close: vm.close,
						update: vm.update
					};
				};	

                vm.autoCompleteChange = function(kendoEvent) {
                    if (vm.selectedItem && vm.selectedItem.serialNumber !== vm.newSerial) {
                        vm.selectedItem = null;
                    }
                }
                vm.autoCompleteSelect = function(kendoEvent)
                {
                    vm.selectedItem = (kendoEvent).dataItem;
                }

                vm.close = function () {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        WindowFactory.closeWindow(vm.wnd);
                    });
                };

                function getAutoCompleteMatchedItem(item) {
                    var autoComplete = <kendo.ui.AutoComplete>kendo.widgetInstance($("#SerialAutoComplete"));
                    if (!autoComplete) {
                        return undefined; // not matched
                    }
                    autoComplete.search(vm.newSerial);
                    autoComplete.close();
                    if (autoComplete.dataItem(1)) {
                        return undefined; // more than one option
                    } else {
                        return autoComplete.dataItem(0);
                    }
                }

                vm.update = function () {
                    var dataItem = getAutoCompleteMatchedItem(vm.newSerial);
                    if (vm.wnd.initParams.type !== 'rim' && dataItem) {
                        var readingType = vm.wnd.initParams.type === "tyre" ? 'TREADDEPTH' : 'CHAINDEPTH';
                        if (browserSvc.isMobile) {
                            $db.reading
                                .where('equipmentId')
                                .equals(dataItem.id)
                                .filter(function(reading) { return reading.type === readingType; })
                                .sortBy('date')
                                .then(function(readings) {
                                    var reading = null;
                                    if (readings && readings.length > 0) {
                                        reading = readings[0];
                                    }
                                    WindowFactory.closeWindow(vm.wnd);
                                    vm.wnd.onDataChanged(vm.chainRemoved ? undefined : vm.newSerial,
                                        vm.chainRemoved,
                                        reading);
                                }).catch(function() {
                                    // ok well what do we do here. I assume we have no reading  
                                    WindowFactory.closeWindow(vm.wnd);
                                    vm.wnd.onDataChanged(vm.chainRemoved ? undefined : vm.newSerial,
                                        vm.chainRemoved,
                                        undefined);
                                });
                        } else {
                            WindowFactory.closeWindow(vm.wnd);
                            vm.wnd.onDataChanged(vm.chainRemoved ? undefined : vm.newSerial, vm.chainRemoved, undefined);
                        }
                    } else {
                        WindowFactory.closeWindow(vm.wnd);
                        vm.wnd.onDataChanged(vm.chainRemoved ? undefined : vm.newSerial, vm.chainRemoved, undefined);
                    }
                };

                function checkSubmit() {
                    vm.buttonStates.updateButton.disabled = !vm.newSerial && !vm.hadStartingVal && vm.chainRemoved === vm.originalChainState;
                }

                $scope.$watch('vm.newSerial', function () {
                    checkSubmit();
                });

                $scope.$watch('vm.chainRemoved', function () {
                    checkSubmit();
                });
            }
        ]
    });
