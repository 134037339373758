/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * @hidden
 */
exports.FOCUS_ROOT_ACTIVE = new core_1.InjectionToken('focus-root-initial-active-state');
/**
 * @hidden
 */
var FocusRoot = /** @class */ (function () {
    function FocusRoot(active) {
        if (active === void 0) { active = false; }
        this.active = active;
        this.groups = new Set();
    }
    FocusRoot.prototype.registerGroup = function (group) {
        if (this.active) {
            this.groups.add(group);
        }
    };
    FocusRoot.prototype.unregisterGroup = function (group) {
        if (this.active) {
            this.groups.delete(group);
        }
    };
    FocusRoot.prototype.activate = function () {
        if (this.active) {
            this.groups.forEach(function (f) { return f.activate(); });
        }
    };
    FocusRoot.prototype.deactivate = function () {
        if (this.active) {
            this.groups.forEach(function (f) { return f.deactivate(); });
        }
    };
    FocusRoot = tslib_1.__decorate([
        core_1.Injectable(),
        tslib_1.__param(0, core_1.Optional()), tslib_1.__param(0, core_1.Inject(exports.FOCUS_ROOT_ACTIVE)),
        tslib_1.__metadata("design:paramtypes", [Boolean])
    ], FocusRoot);
    return FocusRoot;
}());
exports.FocusRoot = FocusRoot;
