/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_treeview_1 = require("@progress/kendo-angular-treeview");
var util_1 = require("../../common/util");
var base_check_directive_1 = require("./base-check.directive");
/**
 * @hidden
 *
 * A directive which manages the in-memory checked state of the MultiSelectTree nodes.
 */
var CheckAllDirective = /** @class */ (function (_super) {
    tslib_1.__extends(CheckAllDirective, _super);
    function CheckAllDirective(element, zone, cdr, renderer) {
        var _this = _super.call(this) || this;
        _this.element = element;
        _this.zone = zone;
        _this.cdr = cdr;
        _this.renderer = renderer;
        /**
         * Fires when the `checkedItems` collection was updated.
         */
        _this.checkedItemsChange = new core_1.EventEmitter();
        /**
         * Holds a Set with just the checked item keys.
         *
         * Should be updated each time the `checkedItems` value or content is changed.
         * Can be used for efficient look-up of whether an item is checked or not (O(1) access time).
         */
        _this.checkedKeys = new Set();
        return _this;
    }
    CheckAllDirective.prototype.handleChange = function (event) {
        var _this = this;
        // Need to store the current checkbox state at the moment of click
        this.currentCheckedState = event.checked;
        this.currentIndeterminateState = this.isIndeterminate;
        this.treeview.nodes.map(function (_value, index) {
            var itemIndex = String(index);
            var itemLookup = _this.treeview.itemLookup(itemIndex);
            _this.checkNode(itemLookup);
        });
        this.checkedItemsChange.emit(this.checkedItems.slice());
    };
    Object.defineProperty(CheckAllDirective.prototype, "isIndeterminate", {
        get: function () {
            var _this = this;
            var isIndeterminate = this.treeview.nodes.some(function (_node, index) {
                var itemIndex = String(index);
                var itemLookup = _this.treeview.itemLookup(itemIndex);
                return _this.someChecked(itemLookup);
            });
            return this.isChecked ? false : isIndeterminate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CheckAllDirective.prototype, "isChecked", {
        get: function () {
            var _this = this;
            var isChecked = this.treeview.nodes.every(function (_node, index) {
                var itemIndex = String(index);
                var itemLookup = _this.treeview.itemLookup(itemIndex);
                return _this.allChecked(itemLookup);
            });
            return isChecked;
        },
        enumerable: true,
        configurable: true
    });
    CheckAllDirective.prototype.ngOnChanges = function (changes) {
        if (util_1.isPresent(changes.checkedItems)) {
            this.updateItems();
            this.renderer.setProperty(this.element.nativeElement, 'checked', this.isChecked);
            this.renderer.setProperty(this.element.nativeElement, 'indeterminate', this.isIndeterminate);
        }
    };
    CheckAllDirective.prototype.ngOnInit = function () {
        var _this = this;
        if (this.focused) {
            this.nextTick(function () { return _this.element.nativeElement.focus(); });
        }
    };
    CheckAllDirective.prototype.nextTick = function (fn) {
        this.zone.runOutsideAngular(function () { return setTimeout(fn); });
    };
    CheckAllDirective.prototype.checkNode = function (itemLookup) {
        var _this = this;
        if (this.treeview.isDisabled(itemLookup.item.dataItem, itemLookup.item.index)) {
            return;
        }
        var pendingCheck = [];
        var filter = function (item) {
            return _this.treeview.isVisible(item.dataItem, item.index) &&
                !_this.treeview.isDisabled(item.dataItem, item.index);
        };
        pendingCheck.push(itemLookup.item);
        util_1.fetchDescendentNodes(itemLookup, filter)
            .forEach(function (lookup) { return pendingCheck.push(lookup.item); });
        pendingCheck.forEach(function (item) {
            if (_this.currentIndeterminateState) {
                _this.lastAction === 'check' ?
                    _this.addItem(item) :
                    _this.removeItem(item);
                return;
            }
            _this.currentCheckedState ?
                _this.addItem(item) :
                _this.removeItem(item);
        });
    };
    CheckAllDirective.prototype.allChecked = function (lookup) {
        var _this = this;
        var children = lookup && lookup.children;
        if (!Array.isArray(children)) {
            return;
        }
        var childrenChecked = children.every(function (child) {
            if (child.children.length) {
                return _this.isItemChecked(child.item) && _this.allChecked(child);
            }
            return _this.isItemChecked(child.item);
        });
        return childrenChecked && this.isItemChecked(lookup.item);
    };
    CheckAllDirective.prototype.someChecked = function (lookup) {
        var _this = this;
        var children = lookup && lookup.children;
        if (!Array.isArray(children)) {
            return;
        }
        var childrenChecked = children.some(function (child) {
            if (child.children.length) {
                return _this.isItemChecked(child.item) || _this.someChecked(child);
            }
            return _this.isItemChecked(child.item);
        });
        return childrenChecked || this.isItemChecked(lookup.item);
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], CheckAllDirective.prototype, "lastAction", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", kendo_angular_treeview_1.TreeViewComponent)
    ], CheckAllDirective.prototype, "treeview", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Array)
    ], CheckAllDirective.prototype, "checkedItems", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], CheckAllDirective.prototype, "valueField", void 0);
    tslib_1.__decorate([
        core_1.Input('checkAll'),
        tslib_1.__metadata("design:type", Boolean)
    ], CheckAllDirective.prototype, "focused", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], CheckAllDirective.prototype, "checkedItemsChange", void 0);
    tslib_1.__decorate([
        core_1.HostListener('change', ['$event.target']),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CheckAllDirective.prototype, "handleChange", null);
    CheckAllDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[checkAll]' // tslint:disable-line
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.ElementRef, core_1.NgZone, core_1.ChangeDetectorRef, core_1.Renderer2])
    ], CheckAllDirective);
    return CheckAllDirective;
}(base_check_directive_1.BaseCheckDirective));
exports.CheckAllDirective = CheckAllDirective;
