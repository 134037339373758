//import angular from 'angular';
import tmpl from './ocMenu.html';


angular.module('app.directives')
        .directive('ocMenu', [function () {
            return {
                replace: true,
                transclude: true,
                restrict: 'E',
                scope: {                    
                    menuItems: "=",
                    allowSearch: "=?"
                },
                template: tmpl,
                link: function (scope: any) {             

                    scope.filteredMenuItems = null;

                    scope.filterMenuItems = function (items) {
                        scope.filteredMenuItems = items;
                    };                
                }
            };
        }]);
