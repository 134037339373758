//import angular from 'angular';
import * as _ from 'underscore'


    angular.module('app').filter('distinct', distinctFilter);

    // @ts-ignore
    distinctFilter.$inject = [];

    function distinctFilter() {
        return function (arr, field) {
            return _.uniq(arr,
                function (a) {
                    return a[field];
                }
            );
        };
    }
