/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var column_menu_item_directive_1 = require("./column-menu-item.directive");
var core_1 = require("@angular/core");
var operators_1 = require("rxjs/operators");
var column_menu_service_1 = require("./column-menu.service");
/**
 * @hidden
 */
var ColumnMenuContainerComponent = /** @class */ (function () {
    function ColumnMenuContainerComponent(service, ngZone) {
        this.service = service;
        this.ngZone = ngZone;
    }
    ColumnMenuContainerComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        if (!this.columnMenuItems.length) {
            return;
        }
        this.columnMenuItems.first.isFirst = true;
        this.columnMenuItems.last.isLast = true;
        this.ngZone.onStable.pipe(operators_1.take(1)).subscribe(function () { return _this.service.menuTabbingService.firstFocusable.focus(); });
    };
    tslib_1.__decorate([
        core_1.ContentChildren(column_menu_item_directive_1.ColumnMenuItemDirective, { descendants: true }),
        tslib_1.__metadata("design:type", core_1.QueryList)
    ], ColumnMenuContainerComponent.prototype, "columnMenuItems", void 0);
    ColumnMenuContainerComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-grid-columnmenu-container',
            template: "\n        <ng-content></ng-content>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [column_menu_service_1.ColumnMenuService,
            core_1.NgZone])
    ], ColumnMenuContainerComponent);
    return ColumnMenuContainerComponent;
}());
exports.ColumnMenuContainerComponent = ColumnMenuContainerComponent;
