//import angular from 'angular';
import * as _ from 'underscore';
import OcDateSvc from '../../../../services/ocDateSvc';
import ReportSvc, { ExportReports, ExportReportParameters, ExportReportParameterDefaults, ExportReportCriteria, ExportProviders, ExportOrientation }
    from '../../../../services/reportSvc';
import tmpl from './blankFieldSurvey.html';


angular.module('app.vehicle').component('blankFieldSurvey', {
    bindings: {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    },
    template: tmpl,
    controller: ['$scope', 'enums', 'WindowFactory', 'amtCommandQuerySvc',
        'amtXlatSvc', 'ocConfigSvc', 'errorReporter', 'vehicleUrls', 'fleetUrls', 'reportSvc', '$q', 'ocDateSvc',
        function ($scope, enums, WindowFactory, amtCommandQuerySvc,
            amtXlatSvc, ocConfigSvc, errorReporter, vehicleUrls, fleetUrls, reportSvc: ReportSvc, $q, ocDateSvc: OcDateSvc) {

            var vm = this;
            $scope.vm = this;

            vm.vehicleUrls = vehicleUrls;
            vm.fleetUrls = fleetUrls;

            vm.enums = enums;
            vm.config = ocConfigSvc;

            vm.showChains = vm.config.user.site.settings.tyreMaintenance.showChains;
            vm.useNitrogen = vm.config.user.site.settings.tyreMaintenance.useNitrogen;

            // options for the 'Readings Due' field
            vm.readingsDueOptions = [{
                name: amtXlatSvc.xlat('fieldSurvey.blankFormPressures'),
                key: vm.enums.fieldSurveyReadingsDue.pressure
            }, {
                name: amtXlatSvc.xlat('fieldSurvey.blankFormTreads'),
                key: vm.enums.fieldSurveyReadingsDue.tread
            }];

            // options for the 'Reading Type' field
            vm.readingTypeOptions = [{
                name: amtXlatSvc.xlat('fieldSurvey.blankFormReadingTypePressure'),
                key: FieldSurveyReadingTypes.pressure
            }, {
                name: amtXlatSvc.xlat('fieldSurvey.blankFormReadingTypeTemperature'),
                key: FieldSurveyReadingTypes.temperature
            }, {
                name: amtXlatSvc.xlat('fieldSurvey.blankFormReadingTypeTreadDepth'),
                key: FieldSurveyReadingTypes.treadDepth
            }];

            if (vm.showChains === true) {
                vm.readingTypeOptions.push({
                    name: amtXlatSvc.xlat('fieldSurvey.blankFormReadingTypeChainDepth'),
                    key: FieldSurveyReadingTypes.chainDepth
                });
            }

            vm.readingsDueControl = {};
            vm.vehicleSpecificationControl = {};
            vm.vehicleSerialNumberControl = {};
            vm.readingTypeControl = {};

            this.$onInit = function () {

                vm.status = enums.screenStatus.loading;

                if (vm.wnd !== null) {
                    vm.wnd.onClose = vm.cancel;
                }

                vm.wnd.processing = true;

                vm.buttons = [
                    {
                        primary: true,
                        cancel: false,
                        value: "common.download_label",
                        name: "downloadButton",
                        click: "download",
                        type: enums.buttonTypes.button
                    },
                    {
                        primary: false,
                        cancel: true,
                        value: 'common.cancel_label',
                        name: 'cancelButton',
                        click: 'cancel',
                        type: enums.buttonTypes.button
                    }
                ];

                vm.buttonStates = {
                    downloadButton: {
                        visible: true,
                        disabled: true
                    },
                    cancelButton: {
                        visible: true,
                        disabled: false
                    }
                };

                vm.buttonMethods = {
                    download: vm.onDownload,
                    cancel: vm.closeWindow
                };

                vm.status = enums.screenStatus.ready;
                vm.wnd.processing = false;
            };

            vm.onDownload = async function () {

                let selectedReadingTypes = [];

                let includeAllReadingTypes = !vm.readingTypes?.length;

                let includePressure = vm.readingTypes?.some(r => r === FieldSurveyReadingTypes.pressure);
                let includeNitrogen = !!includePressure && vm.useNitrogen;
                let includeTemperature = vm.readingTypes?.some(r => r === FieldSurveyReadingTypes.temperature);
                let includeTreadDepth = vm.readingTypes?.some(r => r === FieldSurveyReadingTypes.treadDepth);
                let includeChainDepth = vm.readingTypes?.some(r => r === FieldSurveyReadingTypes.chainDepth) && vm.showChains;

                // build up reading types
                if (includeAllReadingTypes || includePressure)
                    selectedReadingTypes.push(FieldSurveyReadingTypes.pressure);

                if (includeAllReadingTypes || includeNitrogen)
                    selectedReadingTypes.push(FieldSurveyReadingTypes.nitrogen);

                if (includeAllReadingTypes || includeTemperature)
                    selectedReadingTypes.push(FieldSurveyReadingTypes.temperature);

                if (includeAllReadingTypes || includeTreadDepth)
                    selectedReadingTypes.push(FieldSurveyReadingTypes.treadDepth);

                if (includeAllReadingTypes || includeChainDepth)
                    selectedReadingTypes.push(FieldSurveyReadingTypes.chainDepth);

                let reportName = String.format('{0} - {1}', ExportReports.fieldSurvey, selectedReadingTypes.join('-'));

                let report = angular.copy(reportSvc.getReport(reportName));

                if (report) {

                    vm.wnd.processing = true;

                    try {

                        let vehicleIds;

                        // if generating by due pressure/tread retrieve the vehicles in production at the site that are due for pressure/tread
                        if (vm.readingsDue?.length > 0) {

                            let vehicles = await amtCommandQuerySvc.post(vm.vehicleUrls.getCurrentVehiclesLastReading, null);

                            let showPressureReadingDue = vm.readingsDue.some(r => r === enums.fieldSurveyReadingsDue.pressure);
                            let showTreadReadingDue = vm.readingsDue.some(r => r === enums.fieldSurveyReadingsDue.tread);

                            vehicleIds = vehicles.result.filter(v => v.status.toLowerCase() === StatusTypeName.production
                                && ((showPressureReadingDue && v.pressureDue) || (showTreadReadingDue && v.treadDue))                                        
                            ).map(v => v.id);

                        } else {
                            vehicleIds = vm.vehicles;
                        }

                        let parameters = [];

                        if (report.parameters?.length > 0) {

                            report.name = 'BlankFieldSurvey';
                            report.description = amtXlatSvc.xlat('fieldSurvey.blankFieldSurvey');

                            parameters = reportSvc.constructBaseParameters(report);

                            // blank
                            parameters = reportSvc.constructParameter(parameters, report, ExportReportParameters.isBlank, 1);

                            // vehicle specifications
                            if (vehicleIds?.length > 0) {
                                reportSvc.constructParameter(parameters, report, 'VehicleSpecification', ExportReportParameterDefaults.all);
                            } else {
                                _.each(vm.vehicleSpecificationDetails, v => parameters = reportSvc.constructParameter(parameters, report, 'VehicleSpecification', v.referenceDataId));
                            }

                            // vehicles
                            if (vehicleIds?.length > 0) {
                                _.each(vehicleIds, v => parameters = reportSvc.constructParameter(parameters, report, 'Vehicle', v));
                            } else {
                                parameters = reportSvc.constructParameter(parameters, report, 'Vehicle', ExportReportParameterDefaults.all);
                            }

                            // reading types
                            parameters = reportSvc.constructParameter(parameters, report, 'ReadingTypes', selectedReadingTypes.join('-'));
                        }

                        let exportCriteria: ExportReportCriteria = {
                            report: report,
                            provider: ExportProviders.pdf,
                            parameters: parameters,
                            orientation: ExportOrientation.portrait
                        };

                        reportSvc.exportReport(exportCriteria);

                        vm.closeWindow();

                    } catch (error) {
                        errorReporter.logError(error);
                    } finally {
                        vm.wnd.processing = false;
                    }
                }
            };

            vm.closeWindow = function () {
                WindowFactory.closeWindow(vm.wnd);
            };

            $scope.$watchGroup(['vm.form.$invalid', 'vm.wnd.processing'], function () {
                vm.buttonStates.downloadButton.disabled = vm.form.$invalid || vm.wnd.processing;
            });

            $scope.$watch('vm.readingsDue', function (newValue) {
                if (!!newValue && newValue.length > 0) {

                    // when readings due are select clear the specs, serial numbers and reading types
                    vm.vehicleSpecifications = null;

                    if (vm.vehicleSpecificationControl && vm.vehicleSpecificationControl.clear) {
                        vm.vehicleSpecificationControl.clear();
                    }

                    vm.vehicles = null;

                    if (vm.vehicleSerialNumberControl && vm.vehicleSerialNumberControl.clear) {
                        vm.vehicleSerialNumberControl.clear();
                    }

                    vm.readingTypes = null;

                    if (vm.readingTypeControl && vm.readingTypeControl.clear) {
                        vm.readingTypeControl.clear();
                    }
                }
            }, true);

            $scope.$watch('vm.readingTypes', function (newValue) {
                // if 'Temperature' reading type is selected automatically select 'Pressure'
                // 'Pressure' cannot be removed unless 'Temperature' is removed
                if (!!newValue && _.find(newValue, function (rt) { return rt === 'Temp'; })) {
                    if (!_.find(newValue, function (rt) { return rt === 'Pres'; })) {
                        var pressure = _.find(vm.readingTypeOptions, function (rt) { return rt.key === 'Pres'; });

                        if (vm.readingTypeControl && vm.readingTypeControl.select) {
                            vm.readingTypeControl.select(pressure);
                        }
                    }
                }

                // if 'Chain Depth' reading type is selected automatically select 'Tread Depth'
                // 'Tread Depth' cannot be removed unless 'Chain Depth' is removed
                if (!!newValue && _.find(newValue, function (rt) { return rt === 'Chain'; })) {
                    if (!_.find(newValue, function (rt) { return rt === 'Tread'; })) {
                        var treadDepth = _.find(vm.readingTypeOptions, function (rt) { return rt.key === 'Tread'; });

                        if (vm.readingTypeControl && vm.readingTypeControl.select) {
                            vm.readingTypeControl.select(treadDepth);
                        }
                    }
                }
            }, true);

            $scope.$watch('vm.vehicleSpecifications', function (newValue) {
                if (!!newValue && newValue.length > 0) {

                    // when a vehicle specification is selected clear 'Readings Due'
                    // vehicle serial numbers and reading types should also clear and have there descriptor set to 'All'
                    vm.readingsDue = null;

                    if (vm.readingsDueControl && vm.readingsDueControl.clear) {
                        vm.readingsDueControl.clear();
                    }

                    if (vm.vehicleSerialNumberControl) {
                        if (vm.vehicleSerialNumberControl.setEmptyDescriptor) {
                            vm.vehicleSerialNumberControl.setEmptyDescriptor(amtXlatSvc.xlat('fieldSurvey.blankFormAllVehicleSerialNumbers'));
                        }

                        if (vm.vehicleSerialNumberControl.clear) {
                            vm.vehicleSerialNumberControl.clear();
                        }
                    }

                    if (vm.readingTypeControl) {
                        if (vm.readingTypeControl.setEmptyDescriptor) {
                            vm.readingTypeControl.setEmptyDescriptor(amtXlatSvc.xlat('fieldSurvey.blankFormAllReadings'));
                        }

                        if (vm.readingTypeControl.clear) {
                            vm.readingTypeControl.clear();
                        }
                    }

                } else {

                    // if all vehicle specifications have been removed clear the vehicle serial numbers and reading types
                    // and set their empty descriptors to blank
                    if (vm.vehicleSerialNumberControl) {

                        if (vm.vehicleSerialNumberControl.setEmptyDescriptor) {
                            vm.vehicleSerialNumberControl.setEmptyDescriptor(" ");
                        }

                        if (vm.vehicleSerialNumberControl.clear) {
                            vm.vehicleSerialNumberControl.clear(true);
                        }
                    }

                    if (vm.readingTypeControl) {

                        if (vm.readingTypeControl.setEmptyDescriptor) {
                            vm.readingTypeControl.setEmptyDescriptor(" ");
                        }

                        if (vm.readingTypeControl.clear) {
                            vm.readingTypeControl.clear(true);
                        }
                    }
                }
            }, true);
        }
    ]
});
