import { Inject, Injectable, OnDestroy } from "@angular/core";
import { VehicleSpecsService } from "../../../../services/vehicle-specs.service";
import { VehiclesOnsiteService } from "../../../../services/vehicles-onsite.service";
import { IAxleSpecSize, ConditionMonitoringService } from "../condition-monitoring.service";
import { RenderPartTypes, VehicleRendererBase } from "./vehicle-renderer-base.service";

@Injectable()
export class VehicleRendererOrtho extends VehicleRendererBase {
    protected scaleYZ: number = .5;
    protected rotYZ: number = 0; //.05;
    //private lastAxleIdx = -1;

    constructor(
        protected conditionMonitoringService: ConditionMonitoringService,
        protected vehSpecSvc: VehicleSpecsService,
        protected vehSvc: VehiclesOnsiteService,
        @Inject('desiredWidth') public readonly desiredWidth: number,
        @Inject('desiredHeight') public readonly desiredHeight: number
    ) {
        super(true);
    }

    protected renderInit(): void {
    }

    protected renderAfterParts(): void {
        this.ctx!.scale(this.diagramParams!.scale, this.diagramParams!.scale);

        //quick hack for drive train
        //this.drawLine(0, .5 * this.axles[0].size.tyreDiameter + this.diagramTopY,
        //    0, this.diagramLength - .5 * this.axles[this.axles.length - 1].size.tyreDiameter + this.diagramTopY,
        //    this.DRIVE_SHAFT_COLOUR, this.DRIVE_SHAFT_WIDTH_IN_PIXELS);

        //TODO: traverse back to front (in 'camera' / projected space)
        for (let axle of this.deferredData!) {
            for (let part of axle) {
                this.drawPart(part);
            }
        }
    }

    drawCircle(x: number, y: number, radius: number, fillStyle?: string, strokeStyle?: string, strokeWidth?: number) {
        if (fillStyle || strokeStyle)
            this.ctx!.beginPath();

        this.ctx!.ellipse(x, y, radius * this.scaleYZ, radius, this.rotYZ, 0, 2 * Math.PI);

        if (fillStyle) {
            this.ctx!.fillStyle = fillStyle;
            this.ctx!.fill();
        }

        if (strokeStyle) {
            this.ctx!.strokeStyle = strokeStyle;
            this.ctx!.lineWidth = (strokeWidth || 1) * this.diagramParams!.lineWidth;
            this.ctx!.stroke();
        }
    }

    drawTyre(t: RenderPartTypes, x: number, y: number, issue: number, size: IAxleSpecSize) {
        let hw = .5 * size.tyreWidth;
        let hd = .5 * size.tyreDiameter;

        let col = this.ISSUE_COLOURS_LOOKUP_TABLE[issue];

        this.drawCircle(x + hw, y, hd, col);
        this.drawRect(x - hw, y - hd, size.tyreWidth, size.tyreDiameter, col);
        this.drawCircle(x - hw, y, hd, col);
        //this.drawCircle(x + hw, y, .5 * size.RimDiameter, '#762', '#A93', 2);
        //this.drawCircle(x + hw, y, (.4 * .5) * size.RimDiameter, '#A93');
    }

    protected drawAxlePart(t: RenderPartTypes, x0: number, x1: number, y: number, size: IAxleSpecSize): void {
        //const len = x1 - x0;
        const gap = 0 //(2 * this.lineWidth + .5 * size.tyreWidth) * (Math.sign(len));

        //if(Math.abs(len) >= 3 * gap)
            this.drawLine(x0 + gap, y, x1 - gap, y, this.AXLE_COLOUR, this.AXLE_WIDTH_IN_PIXELS);
    }
}
