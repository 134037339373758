//import angular from 'angular';
import AdminUrls from '../adminUrls';


    angular.module('app.admin').controller("productionCrewsSearchCtrl", productionCrewsSearchCtrl);

    // @ts-ignore
    productionCrewsSearchCtrl.$inject = ["adminUrls", "$scope", "enums", "ocSecuritySvc", "amtXlatSvc", "$timeout", "WindowFactory", "ocConfigSvc", "exportSvc"];

    // ReSharper disable once InconsistentNaming
    function productionCrewsSearchCtrl(adminUrls: AdminUrls, $scope, enums, ocSecuritySvc, amtXlatSvc, $timeout, WindowFactory, ocConfigSvc, exportSvc) {

        var vm = this;

        vm.processing = false;

        // security
        vm.canAdd = ocSecuritySvc.isAuthorised('Security.Settings.ProductionCrews.Add', 'readWrite');

        vm.showClients = false;
        vm.showSites = false;

        vm.adminUrls = adminUrls;
        vm.config = ocConfigSvc;

        vm.criteria = {
            filterValues: {
                clientIds: [],
                siteIds: [],
                dateFrom: null,
                dateTo: null,
                active: null,
                name: null,
                defaultToAllSitesWhenNoSystemHierarchyFilter: true
            }
        };

        vm.activeOptions = [{
            name: amtXlatSvc.xlat('common.yes_label'),
            key: true
        }, {
            name: amtXlatSvc.xlat('common.no_label'),
            key: false
        }];

        $scope.$watch('vm.criteria.filterValues.clientIds.length', function (cnt) {
            if (cnt === 0 && vm.criteria.filterValues.siteIds.length > 0) {
                // reset the sites if the clients are cleared
                vm.criteria.filterValues.siteIds.length = 0;
            }
        });

        vm.status = enums.screenStatus.loading;
        vm.isDirty = true;

        vm.gridControl = {};
        vm.selectedItem = null;

        function init() {
            vm.loadDefaults();
        }

        vm.onSelectedItemChanged = function (items) {
            if (items != null && items.length === 1) {
                vm.selectedItem = items[0];
            } else {
                vm.selectedItem = null;
            }
        };

        vm.setHeaderHeight = function (height, offset) {
            vm.gridTop = height + offset;
        };

        vm.loadDefaults = function () {
            vm.defaults = {
                sites: [{
                    key: vm.config.user.site.id,
                    name: vm.config.user.site.name
                }],
                clients: [{
                    key: vm.config.user.client.id,
                    name: vm.config.user.client.name
                }],
                activeOptions: [vm.activeOptions[0]]
            };

            vm.status = enums.screenStatus.ready;

            // focus on name field by default
            $timeout(() => { vm.focusName = true });
        };

        vm.export = function () {
            exportSvc.exportData(vm.adminUrls.searchProductionCrewsExport, vm.criteria, amtXlatSvc.xlat('productionCrews.searchTitle'));
        };

        vm.onSearchProductionCrews = function (response) {

            var crews = response.result;

            if (crews && crews.length > 0) {
                for (var r = 0; r < crews.length; r++) {
                    crews[r].onSite = crews[r].site.id === ocConfigSvc.user.site.id;
                }
            }

            return response;
        };

        $scope.editProductionCrew = function (item) {
            if (item) {
                WindowFactory.openItem({
                    component: 'production-crew-details',
                    caption: amtXlatSvc.xlat("productionCrews.editProductionCrew", item.name),
                    initParams: item,
                    width: 650,
                    onDataChangeHandler: vm.filter,
                    windowRelatedRecordId: item.id
                });
            }
        };

        $scope.addProductionCrew = function () {
            WindowFactory.openItem({
                component: 'production-crew-details',
                caption: amtXlatSvc.xlat("productionCrews.addProductionCrew"),
                initParams: null,
                width: 650,
                onDataChangeHandler: vm.filter,
                windowRelatedRecordId: uuid()
            });
        };

        vm.refreshGrid = function () {
            vm.gridControl.refresh();
        };

        vm.filter = function () {
            vm.showClients = vm.criteria.filterValues.clientIds.length !== 1;
            vm.showSites = vm.criteria.filterValues.siteIds.length !== 1;
            vm.refreshGrid();
        };

        init();
    }
