//import angular from 'angular';
import AdminUrls from '../../../adminUrls';

    angular.module('app.admin').controller("reportIntegrationSearchCtrl", reportIntegrationSearchCtrl);

    // @ts-ignore
    reportIntegrationSearchCtrl.$inject = ["adminUrls", "$scope", "enums", "amtXlatSvc", "WindowFactory", "ocConfigSvc", "exportSvc"];

    // ReSharper disable once InconsistentNaming
    function reportIntegrationSearchCtrl(adminUrls: AdminUrls, $scope, enums, amtXlatSvc, WindowFactory, ocConfigSvc, exportSvc) {

        var vm = this;

        vm.processing = false;

        vm.adminUrls = adminUrls;        

        vm.config = ocConfigSvc;

        vm.enums = enums;

        vm.criteria = {
            filterValues: {               
                activeOptions: null,
                reports: null,
                clientIds: [],
                siteIds: [],
                defaultToAllSitesWhenNoSystemHierarchyFilter: true
            }
        };

        vm.activeOptions = [{
            name: amtXlatSvc.xlat('common.yes_label'),
            key: true
        }, {
            name: amtXlatSvc.xlat('common.no_label'),
            key: false
        }];

        vm.status = enums.screenStatus.loading;

        vm.gridControl = {};
        vm.selectedItem = null;

        function init() {
            vm.loadDefaults();
        }

        vm.onSelectedItemChanged = function (items) {
            if (items != null && items.length === 1) {
                vm.selectedItem = items[0];
            } else {
                vm.selectedItem = null;
            }
        };

        vm.setHeaderHeight = function (height, offset) {
            vm.gridTop = height + offset;
        };

        vm.loadDefaults = function () {
            vm.defaults = {
                activeOptions: [vm.activeOptions[0]]
            };

            vm.status = enums.screenStatus.ready;
        };

        vm.export = function () {
            exportSvc.exportData(vm.adminUrls.exportReportIntegrations, vm.criteria, amtXlatSvc.xlat('reportIntegration.searchTitle'));
        };

        $scope.editReportIntegration = function (item) {
            if (item) {
                WindowFactory.openItem({
                    component: 'add-edit-report-integration',
                    caption: amtXlatSvc.xlat("reportIntegration.edit"),
                    initParams: item,
                    width: 650,
                    onDataChangeHandler: function () {
                        vm.refreshGrid();                        
                    },
                    windowRelatedRecordId: item.id
                });
            }
        };

        $scope.addReportIntegration = function () {
            WindowFactory.openItem({
                component: 'add-edit-report-integration',
                caption: amtXlatSvc.xlat("reportIntegration.add"),
                initParams: null,
                width: 650,
                onDataChangeHandler: function () {
                    vm.refreshGrid();                    
                }
            });
        };

        vm.refreshGrid = function () {
            vm.gridControl.refresh();
        };

        init();
    }
