//import angular from 'angular';
import OcDateSvc from '../../../services/ocDateSvc';
import tmpl from './ocEquipmentHistory.html';



    angular.module('app.directives').directive("ocEquipmentHistory", [
        'exportSvc', 'WindowFactory', 'errorReporter',
        function (exportSvc, WindowFactory, errorReporter) {
            return {
                scope: {
                    tabObject: "=",
                    equipmentId: "=",
                    equipmentObject: "=",
                    siteId: "=",
                    notification: "=",
                    parentWindow: "=",
                    defaultSearchRangeInDays: "=?"
                },
                template: tmpl,
                controllerAs: 'vm',
                controller:
                    ["$scope", "equipmentUrls", "amtCommandQuerySvc", "confirmSvc", "amtXlatSvc", "exportSvc", "ocConfigSvc",
                        "notifySvc", "fieldSurveyService", "ocSecuritySvc", "ocDateSvc", "amtConstants",
                        function ocEquipmentHistory($scope, equipmentUrls, amtCommandQuerySvc, confirmSvc, amtXlatSvc, exportSvc, ocConfigSvc,
                            notifySvc, fieldSurveyService, ocSecuritySvc, ocDateSvc: OcDateSvc, amtConstants) {
                            var vm = this;

                            //security
                            vm.canViewVehicles = ocSecuritySvc.isAuthorised('Security.Vehicles', 'readOnly');

                            vm.config = ocConfigSvc;
                            vm.enableAllHistory = true;

                            vm.selectedEvent = null;

                            vm.positionHistory = {
                                config: {
                                    maxPositions: 6,
                                    positionCount: 6,
                                    top: 0
                                }
                            };

                            $scope.selectedItem = {};

                            $scope.onItemSelected = function (item) {
                                $scope.selectedItem = item;
                            };

                            vm.getEventType = function (desc) {
                                return desc;
                            };

                            vm.chartDataChanged = function () {
                                vm.refresh();
                            };

                            function enableRefresh() {
                                $scope.tabObject.canRefresh = vm.currentTab.refresh !== undefined;
                            }

                            function disableRefresh() {
                                $scope.tabObject.canRefresh = false;
                            }

                            vm.onSelectedEventChanged = function (items) {
                                if (items && items.length === 1) {
                                    vm.selectedEvent = items[0];
                                } else {
                                    vm.selectedEvent = null;
                                }
                            };

                            vm.refreshReadings = function () {                                

                                disableRefresh();

                                var endPoint = null;
                                var fromPoint = null;                                

                                if (vm.tabs.readings.dateRange != null && vm.tabs.readings.dateRange.to != null) {
                                    endPoint = ocDateSvc.removeLocalTimeZoneOffset(vm.tabs.readings.dateRange.to);
                                    endPoint.setDate(endPoint.getDate() + 1); // move to be inclusive
                                }
                                
                                if (vm.tabs.readings.dateRange != null && vm.tabs.readings.dateRange.from != null) {
                                    fromPoint = ocDateSvc.removeLocalTimeZoneOffset(vm.tabs.readings.dateRange.from);
                                }

                                var criteria = {
                                    filterValues: {
                                        dateFrom: fromPoint,
                                        dateTo: endPoint,
                                        equipmentId: $scope.equipmentObject.id,
                                        fitmentEquipmentTypeId: $scope.equipmentObject.equipmentTypeId,
                                        readingTypeIds: vm.tabs.readings.criteria.filterValues.readingTypes,
                                        isVehicle: $scope.equipmentObject.isVehicle
                                    }
                                };

                                vm.loadingReadingHistory = true;

                                amtCommandQuerySvc.post(vm.apiUrls.getChartData, criteria).then(function (response) {
                                    if (response && response.status !== 'fail') {
                                        vm.readingData = response;
                                    } else {
                                        errorReporter.logError(response);
                                    }
                                    enableRefresh();

                                }, amtCommandQuerySvc.handleError).finally(function () {
                                    vm.loadingReadingHistory = false;
                                });
                            };

                            vm.clearFilters = function () {
                                vm.currentTab.dateRange = {};

                                if (vm.currentTab === vm.tabs.readings || vm.currentTab === vm.tabs.data) {
                                    vm.tabs.readings.criteria.filterValues.readingTypes.length = 0;
                                    //TODO: this seems like trash, esp could match other windowed instances and just random DOM stuff with similar generic classes
                                    var readingElement = document.getElementsByClassName("item-display ng-pristine");
                                    if (readingElement.length > 0) {
                                        (readingElement[0] as HTMLInputElement).value = '';
                                    }
                                }

                                if (vm.currentTab === vm.tabs.fitments) {
                                    //TODO: this seems like trash, esp could match other windowed instances and just random DOM stuff with similar binding to similarly named stuff
                                    var fitmentElement = angular.element('[ng-bind="selectedItem[intTextField]"]');
                                    if (fitmentElement.length > 0 && fitmentElement[2] !== undefined) {
                                        fitmentElement[2].innerHTML = '';
                                    }
                                }

                                if (vm.currentTab === vm.tabs.data) {
                                    vm.currentTab.criteria.filterValues.eventTypeNames.length = 0;
                                    vm.currentTab.criteria.filterValues.userIds.length = 0;
                                }

                                vm.enableAllHistory = false;
                                vm.currentTab.refresh();

                            };

                            vm.addDateRange = function (tab) {
                                if (!tab.dateRangeHistory) {
                                    tab.dateRangeHistory = [];
                                }

                                var range = tab.dateRange;

                                if (range) {
                                    if (tab.dateRangeHistory.length > 0) {
                                        var mostRecentRange = tab.dateRangeHistory[tab.dateRangeHistory.length - 1];

                                        if (mostRecentRange.from !== range.from || mostRecentRange !== range.to) {
                                            tab.dateRangeHistory.push({
                                                from: range.from,
                                                to: range.to
                                            });
                                        }
                                    } else {
                                        tab.dateRangeHistory.push({
                                            from: range.from,
                                            to: range.to
                                        });
                                    }

                                    vm.enableAllHistory = true;
                                }
                            };

                            vm.revertDateRange = function (tab) {
                                if (!tab) {
                                    tab = vm.currentTab;
                                }

                                if (tab.dateRangeHistory && tab.dateRangeHistory.length > 1) {
                                    // remove the current date range
                                    tab.dateRangeHistory.pop();

                                    // get the previous date range
                                    var range = tab.dateRangeHistory.pop();

                                    if (range) {
                                        // update the search criteria
                                        tab.dateRange.from = range.from;
                                        tab.dateRange.to = range.to;

                                        // run the search (which will add this range back to the queue)
                                        vm.refresh();
                                    }
                                }
                            };

                            vm.refreshFitments = function () {

                                if ($scope.equipmentObject.isVehicle === true) {                                    

                                    disableRefresh();

                                    var criteria = {
                                        filterValues: {
                                            dateFrom: vm.tabs.fitments.dateRange.from,
                                            dateTo: vm.tabs.fitments.dateRange.to,
                                            equipmentId: $scope.equipmentObject.id,
                                            fitmentEquipmentTypeId: vm.tabs.fitments.criteria.filterValues.equipmentTypeId,
                                            readingTypeIds: vm.tabs.fitments.criteria.filterValues.readingTypeIds,
                                            isVehicle: $scope.equipmentObject.isVehicle
                                        }
                                    };

                                    vm.loadingFitmentHistory = true;

                                    amtCommandQuerySvc.post(vm.apiUrls.getFitmentHistory, criteria).then(function (fitmentsResponse) {
                                        if (fitmentsResponse) {
                                            vm.fitmentData = fitmentsResponse;
                                        } else {
                                            console.error("Unable to get fitment history");
                                        }
                                        enableRefresh();

                                    }, amtCommandQuerySvc.handleError).finally(function () {
                                        vm.loadingFitmentHistory = false;
                                    });
                                }
                            };

                            vm.refreshData = function () {
                                if (vm.currentTab.criteria && isSearchCriteriaValid(vm.currentTab.criteria)) {
                                    updateForceHide();

                                    vm.selectedEvent = null; // clear selection

                                    // set the search criteria
                                    if (vm.historyGrid.refresh !== undefined) {
                                        vm.historyGrid.refresh();
                                    }
                                }
                            };

                            // Order is important. This must be declared BEFORE it is used.
                            vm.export = function () {
                                if (isSearchCriteriaValid(vm.currentTab.criteria)) {
                                    exportSvc.exportData(
                                        vm.apiUrls.historyExport, // url
                                        vm.currentTab.criteria, // criteria
                                        getExportFilename() // output filename
                                    );
                                }
                            };

                            // config for the SUB-tabs
                            vm.tabs = {
                                readings: {
                                    name: "readings",
                                    dateRange: {},
                                    criteria: { filterValues: {} },
                                    refresh: vm.refreshReadings
                                },
                                fitments: {
                                    name: "fitments",
                                    dateRange: {},
                                    criteria: { filterValues: {} },
                                    refresh: vm.refreshFitments
                                },
                                data: {
                                    name: "data",
                                    dateRange: {},
                                    criteria: null,
                                    refresh: vm.refreshData
                                }
                            };

                            vm.selectTab = function (tab, bypassRefresh) {
                                if (vm.currentTab && vm.currentTab.criteria && vm.currentTab.criteria.filterValues) {

                                    // this is a bit of a hack...
                                    //   I tried to use the same variable, but that didnt work
                                    //   I tried to set the vm.currentTab.dateRange
                                    // nothing seems to work because the amt-date-filteritem doesnt bind that way,
                                    // so I have to set the filter value directly. 
                                    tab.criteria.filterValues.dateFrom = vm.currentTab.criteria.filterValues.dateFrom;
                                    tab.criteria.filterValues.dateTo = vm.currentTab.criteria.filterValues.dateTo;

                                    // setting the date range here as well to be safe
                                    tab.dateRange.from = vm.currentTab.criteria.filterValues.dateFrom;
                                    tab.dateRange.to = vm.currentTab.criteria.filterValues.dateTo;
                                }

                                vm.currentTab = tab;

                                $scope.tabObject.canRefresh = tab.refresh !== undefined;
                                $scope.tabObject.canExport = tab === vm.tabs.data;

                                if (bypassRefresh !== true) {
                                    if (tab.refresh) {
                                        tab.refresh();
                                    }
                                }
                            };

                            // default to the readings tab
                            vm.selectTab(vm.tabs.readings, true);

                            vm.historyGrid = {};

                            vm.apiUrls = equipmentUrls.url;

                            vm.hideColumns = {
                                vehicle: true,
                                hours: false,
                                distance: false,
                                treadDepth: true,
                                chainDepth: true,
                                pressure: true,
                                treadTemperature: true,
                                lubricatedStuds: true,
                                nitrogen: true,
                                torque: true
                            };

                            function isReadingTypeSelected(criteria, readingType) {
                                if (criteria.filterValues.readingTypes && criteria.filterValues.readingTypes.length > 0) {
                                    // return true if the item is in the list
                                    return criteria.filterValues.readingTypes.indexOf(readingType) > -1;
                                }

                                return true;
                            }

                            function updateForceHide() {
                                vm.hideColumns.hours = isReadingTypeSelected(vm.currentTab.criteria, equipmentUrls.readingIds.HOURS) === false;
                                vm.hideColumns.distance = isReadingTypeSelected(vm.currentTab.criteria, equipmentUrls.readingIds.DISTANCE) === false;

                                vm.hideColumns.chainDepth = ($scope.equipmentObject.isChain !== true) || isReadingTypeSelected(vm.currentTab.criteria, equipmentUrls.readingIds.CHAINDEPTH) === false;
                                vm.hideColumns.treadDepth = ($scope.equipmentObject.hasTread !== true) || isReadingTypeSelected(vm.currentTab.criteria, equipmentUrls.readingIds.TREADDEPTH) === false;
                                vm.hideColumns.pressure = ($scope.equipmentObject.isVehicle === true) || ($scope.equipmentObject.hasCasing !== true) || isReadingTypeSelected(vm.currentTab.criteria, equipmentUrls.readingIds.PRESSURE) === false;
                                vm.hideColumns.treadTemperature = ($scope.equipmentObject.isVehicle === true) || ($scope.equipmentObject.hasCasing !== true) || isReadingTypeSelected(vm.currentTab.criteria, equipmentUrls.readingIds.TREADTEMP) === false;
                                vm.hideColumns.lubricatedStuds = $scope.equipmentObject.isRim !== true;
                                vm.hideColumns.torque = $scope.equipmentObject.isRim !== true;
                                vm.hideColumns.vehicle = $scope.equipmentObject.isVehicle === true;
                            }

                            $scope.$watch("equipmentObject", function (c) {
                                if ($scope.equipmentObject != null) {
                                    updateForceHide();
                                }
                            });

                            function getExportFilename() {
                                return amtXlatSvc.xlat('equipment.historyExportFilename', $scope.equipmentObject.serialNumber.formatted);
                            }

                            function isSearchCriteriaValid(criteria) {
                                var currentDate = getCurrentDate();

                                if (criteria.filterValues.dateFrom && criteria.filterValues.dateTo) {
                                    if (criteria.filterValues.dateFrom > criteria.filterValues.dateTo) {
                                        notifySvc.error(amtXlatSvc.xlat("equipment.dateRangeInvalid"));
                                        return false;
                                    }
                                }

                                if (criteria.filterValues.dateFrom) {
                                    if (criteria.filterValues.dateFrom > currentDate) {
                                        notifySvc.error(amtXlatSvc.xlat("equipment.dateFromInvalid"));
                                        return false;
                                    }
                                }

                                if (criteria.filterValues.dateTo) {
                                    criteria.filterValues.dateTo = new Date(criteria.filterValues.dateTo.getFullYear(), criteria.filterValues.dateTo.getMonth(), criteria.filterValues.dateTo.getDate(), 23, 59, 59);
                                }

                                return true;
                            }

                            function getCurrentDate() {
                                var currentDate = new Date();

                                // default to end of today
                                return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);
                            }

                            var millisecondsPerDay = 86400000;

                            function defaultSearchRange() {
                                if ($scope.defaultSearchRangeInDays) {
                                    var currentDate = getCurrentDate();

                                    var timestamp = currentDate.getTime() - ($scope.defaultSearchRangeInDays * millisecondsPerDay);

                                    var defaultStartDate = new Date(timestamp);

                                    vm.tabs.readings.dateRange.from = defaultStartDate;
                                    vm.tabs.readings.dateRange.to = currentDate;

                                    vm.tabs.fitments.dateRange.from = defaultStartDate;
                                    vm.tabs.fitments.dateRange.to = currentDate;
                                } else {
                                    //TODO: isVehicle property seems to be missing at least when I test and in testing equipmentObject wasn't even set for a vehicle but I don't think we should rely on that to differentiate vehicles from other components
                                    if ($scope.equipmentObject?.componentType && $scope.equipmentObject.componentType.name.toLowerCase() !== EquipmentTypeName.vehicle) {
                                        let status = $scope.equipmentObject.status.name.toLowerCase();
                                        if (status === StatusTypeName.scrapped || status === StatusTypeName.disposed) {
                                            let statusDate = $scope.equipmentObject.status.date;
                                            vm.tabs.readings.dateRange.from = statusDate ? ocDateSvc.dateAsUTC(ocDateSvc.addMonths(statusDate, -36)) : null;
                                            vm.tabs.readings.dateRange.to = statusDate ? ocDateSvc.dateAsUTC(statusDate) : null;
                                        } else {
                                            let today = ocDateSvc.dateAsUTC(ocDateSvc.today());
                                            vm.tabs.readings.dateRange.from = ocDateSvc.addMonths(today, -24);
                                            vm.tabs.readings.dateRange.to = today;
                                        }
                                    }
                                }


                            }

                            defaultSearchRange();

                            $scope.$watch('vm.chartDateRange', function () {
                                if (vm.chartDateRange && vm.currentTab.criteria) {
                                    vm.currentTab.dateRange.from = vm.chartDateRange.from;
                                    vm.currentTab.dateRange.to = vm.chartDateRange.to;
                                    vm.refresh();
                                }
                            });

                            $scope.$watch('vm.selectedEvent', function () {
                                $scope.tabObject.canViewEvent = (vm.selectedEvent && vm.selectedEvent.onSite);
                            });

                            vm.refresh = function () {
                                vm.addDateRange(vm.currentTab);

                                vm.currentTab.refresh();
                            };

                            $scope.rowParseFunction = function (response) {

                                if (response && response.result) {
                                    for (var x = 0; x < response.result.length; x++) {
                                        var elem = response.result[x];

                                        if (elem.eventType) {                                            

                                            elem.canEditEvent = (elem.siteId === vm.config.user.site.id
                                                || (elem.client === amtConstants.secondHandPreviousClient && elem.siteName === amtConstants.secondHandPreviousSite && elem.isReceive))
                                                && elem.eventType !== 'eventTypeNDT' // prevent edit of the old on-site ndt events for now
                                                && $scope.canViewEventDetails(elem);

                                            elem.eventType = amtXlatSvc.xlat('equipment.' + elem.eventType);

                                            // don't link to the vehicle if the vehicle is offsite, or the user can't edit vehicles
                                            elem.vehicleOnSite = elem.vehicleOnSite && vm.canViewVehicles;
                                        }
                                    }
                                }

                                return response;
                            };

                            $scope.showVehicleDetails = function (item) {
                                if (item.vehicleId) {
                                    WindowFactory.openItem({
                                        component: 'vehicle-details',
                                        caption: amtXlatSvc.xlat('equipment.openVehicle', item.vehicleSerialNumber),
                                        windowRelatedRecordId: item.vehicleId,
                                        initParams: {
                                            equipmentId: item.vehicleId,
                                            siteId: item.siteId,
                                            serialNumber: item.vehicleSerialNumber
                                        },
                                        width: 800,
                                        height: 850,
                                        modal: false
                                    });
                                }
                            };

                            $scope.canViewEventDetails = function (dataItem) {
                                if (dataItem !== undefined) {
                                    if (dataItem.editMode === null || dataItem.editMode === undefined) {
                                        console.log("Edit mode not specified");
                                        return false;
                                    }

                                    switch (dataItem.editMode) {
                                        case 'Move':
                                        case "MOVE":
                                            return ocSecuritySvc.isAuthorised(($scope.equipmentObject.isVehicle ? 'Security.Vehicles.ChangeStatus' : 'Security.Components.ChangeStatus'), 'readOnly');

                                        case 'MaintenanceSession':
                                            return ocSecuritySvc.isAuthorised('Security.Vehicles.MaintenanceSession', 'readOnly');

                                        case 'FieldSurvey':
                                        case 'Reading':
                                            return ocSecuritySvc.isAuthorised('Security.Vehicles.FieldSurveys', 'readOnly');

                                        default:
                                            console.error("unhandled edit mode: " + dataItem.editMode);
                                            return false;
                                    }
                                } else {
                                    console.log('dataitem undefined'); return false;
                                }
                            };

                            $scope.showEventDetails = function (dataItem) {

                                if (!dataItem || !dataItem.id) {
                                    if (vm.selectedEvent) {
                                        dataItem = vm.selectedEvent;
                                    } else {
                                        console.log("id not specified");
                                    }
                                }

                                if ($scope.canViewEventDetails(dataItem)) {
                                    switch (dataItem.editMode.toUpperCase()) {
                                        case 'MOVE':
                                            if ($scope.equipmentObject.isVehicle || dataItem.eventOnFittedVehicle) {

                                                if (dataItem.eventType === amtXlatSvc.xlat('equipment.eventTypeMovedToDispatchedTransfer')) {
                                                    WindowFactory.openItem({
                                                        component: 'transfer-vehicle-popup',
                                                        caption: amtXlatSvc.xlat("vehicle.transferVehicle", $scope.equipmentObject.serialNumber.formatted),
                                                        modal: true,
                                                        canClose: false,
                                                        windowRelatedRecordId: dataItem.id,
                                                        initParams: {
                                                            vehicle: $scope.equipmentObject.id,
                                                            clientId: $scope.equipmentObject.clientId,
                                                            siteId: $scope.siteId,
                                                            equipmentEventId: dataItem.id
                                                        },
                                                        width: 800,
                                                        onDataChangeHandler: vm.refresh
                                                    });
                                                } else {
                                                    WindowFactory.openItem({
                                                        component: 'vehicle-change-status-popup',
                                                        caption: amtXlatSvc.xlat("vehicle.statusChangeHeader", $scope.equipmentObject.serialNumber.formatted),
                                                        initParams: {
                                                            mode: 'edit',
                                                            vehicle: $scope.equipmentObject,
                                                            equipmentEventId: dataItem.id,
                                                        },
                                                        canClose: false,
                                                        width: 750,
                                                        modal: true,
                                                        parentWindow: vm.wnd,
                                                        onDataChangeHandler: vm.refresh
                                                    });
                                                }
                                            } else {
                                                WindowFactory.openItem({
                                                    caption: amtXlatSvc.xlat("equipment.editStatusChangeEvent"),
                                                    component: 'change-status-popup',
                                                    width: 1400,
                                                    modal: true,
                                                    canClose: false,
                                                    initParams: {
                                                        equipmentId: $scope.equipmentObject.id,
                                                        equipmentEventId: dataItem.id,
                                                        isEdit: true,
                                                        isBulkMove: false,
                                                        siteId: $scope.equipmentObject.siteId,
                                                        clientId: $scope.equipmentObject.clientId,
                                                        showCloseOnSave: false
                                                    },
                                                    parentWindow: vm.wnd, // parent
                                                    onDataChangeHandler: vm.refresh
                                                });
                                            }
                                            break;

                                        case 'READING':
                                            // get the data from the reading
                                            fieldSurveyService.fieldSurveyFromReading(dataItem.id);
                                            break;

                                        case 'FIELDSURVEY':
                                            WindowFactory.openItem({
                                                component: 'field-surveys',
                                                caption: amtXlatSvc.xlat('fieldSurvey.elementName'),
                                                canClose: true,
                                                canMinimise: true,
                                                fullScreenOff: true,
                                                windowRelatedRecordId: dataItem.id,
                                                initParams: {
                                                    id: dataItem.id,
                                                    date: dataItem.eventDate,
                                                    equipmentId: dataItem.vehicleId || $scope.equipmentId
                                                },
                                                headerOff: false,
                                                footerOff: true,
                                                height: 700,
                                                width: 1000,
                                                modal: false,
                                                parentWindow: null,
                                                onDataChangeHandler: null
                                            });

                                            break;

                                        case 'MAINTENANCESESSION':

                                            console.log('dataItem.relatedEquipmentEventId ' + dataItem.relatedEquipmentEventId);
                                            console.log('dataItem.id ' + dataItem.id);

                                            WindowFactory.openItem({
                                                component: 'maintenance',
                                                caption: amtXlatSvc.xlat('equipment.maintenanceSession'),
                                                headerOff: false,
                                                footerOff: true,
                                                canClose: true,
                                                canMinimise: true,
                                                windowRelatedRecordId: (dataItem.relatedEquipmentEventId || dataItem.id),
                                                initParams: {
                                                    vehicleId: dataItem.vehicleId || $scope.equipmentId,
                                                    date: dataItem.eventDate,
                                                    id: (dataItem.relatedEquipmentEventId || dataItem.id)
                                                },
                                                height: 700,
                                                width: 1100,
                                                modal: false,
                                                parentWindow: null,
                                                onDataChangeHandler: null
                                            });

                                            break;
                                        default:
                                            console.log("unhandled event type: " + dataItem.editMode);
                                            WindowFactory.unimplementedMessage(dataItem.editMode + " Event");
                                            break;
                                    }
                                } else {
                                    WindowFactory.deniedAccessMessage(dataItem.editMode + " Event");
                                }
                            };

                            $scope.tabObject.refresh = vm.refresh;
                            $scope.tabObject.refreshResource = "equipment.refreshHistory";
                            $scope.tabObject.canRefresh = true;
                            $scope.tabObject.canDelete = false;
                            $scope.tabObject.canEdit = false;
                            $scope.tabObject.canAdd = false;
                            $scope.tabObject.canExport = false;
                            $scope.tabObject.canViewEvent = false;
                            $scope.tabObject.export = vm.export;
                            $scope.tabObject.viewEvent = $scope.showEventDetails;
                        }]
            };
        }]);
