<div class="k-loading-image" *ngIf="!resultItem.receivedResult"></div>

<section *ngIf="resultItem.receivedResult">
    <!--Filter Selection Dropdown List -->
    <section class="search-container">
        <kendo-dropdownlist [data]="displayBySelectionList"
                            [(ngModel)]="selectedDisplayBy"
                            (ngModelChange)="onChangeDisplayBy($event)">
        </kendo-dropdownlist>
    </section>

    <!--TODO: Action Status UI -->
    <section *ngIf="resultItem.result.displayBy.isActionStatus">
    </section>

    <!--TODO: Last Inspected Date UI -->
    <section *ngIf="resultItem.result.displayBy.isLastInspectedDate">
    </section>

    <!--TODO: Last Inspected Date UI -->
    <section *ngIf="resultItem.result.displayBy.isRecommendedAction">
    </section>

</section>