//import angular from 'angular';
import BrowserSvc from '../../../services/browserSvc';
import OcDateSvc from '../../../services/ocDateSvc';
import tmpl from './Checkin.html';



class MaintenanceCheckinCtrl implements ng.IController {

    form: ng.IFormController;

    session: any;
    vehicle: any;
    save: Function;
    invalid: boolean;
    errorPanel: any;
    currentError: any;
    readOnly: boolean;

    currentDate = new Date();

    productionCrews: any[];

    enforceWorkOrderNumber = this.ocConfigSvc.user.site.settings.tyreMaintenance.workOrderNumber;

    loading = false;

    static $inject = ['$scope', 'helperSvc', 'ocConfigSvc', 'dataBroker', 'errorReporter', 'ocDateSvc', 'browserSvc'];

    constructor(private $scope: ng.IScope, private helperSvc: IHelperSvc, private ocConfigSvc: IOcConfigSvc, private dataBroker: IDataBroker,
        private errorReporter: IErrorReporter, private ocDateSvc: OcDateSvc, private browserSvc: BrowserSvc) {

        this.$scope.$watch(() => this.form.$invalid, async (newVal, oldVal) => {

            this.invalid = newVal;

            if (newVal && !oldVal && !this.form.$pristine && !this.loading) {
                try {
                    await this.save({
                        field: this.currentField(),
                        validateReason: 'onInvalidate',
                        andValidate: true
                    });
                } catch (error) {
                    if (error === 'ValidateAbort' || (error && (error.ValidateAbort || error.message === '0' || error.number === 0))) {
                        //User aborted out of validation
                    } else {
                        this.errorReporter.logError(error);
                    }
                }

                this.setPristine();
            }
        });

        this.$scope.$watch(() => this.form.$pristine, async () => {
            if (!this.form.$pristine && this.browserSvc.isMobile && !this.form.$invalid && !this.loading) {

                try {
                    await this.save({
                        field: this.currentField(),
                        validateReason: 'onChange',
                        andValidate: true
                    });

                } catch (error) {
                    if (error === 'ValidateAbort' || (error && (error.ValidateAbort || error.message === '0' || error.number === 0))) {
                        //User aborted out of validation
                    } else {
                        this.errorReporter.logError(error);
                    }
                }

                this.setPristine();
            }
        });

        this.$scope.$watch('vm.session.checkin.checkinDate', (newVal?: Date, oldVal?: Date) => {
            if (newVal && newVal !== oldVal)
                this.getProductionCrews(newVal);
        });

        this.$scope.$watchGroup(['vm.session.checkin.hoursNewMeter', 'vm.session.checkin.distanceNewMeter'], (newValue, oldValue) => {
            if (newValue != null && newValue !== oldValue)
                this.onBlur('newMeter');
        });
    }

    async $onInit() {

        this.loading = true;

        try {
            if (!this.session) {
                console.error('No Session!');
            } else {

                if (!this.session.checkin)
                    this.session.checkin = {};

                if (this.session.checkin && this.session.checkin.checkinDate)
                    await this.getProductionCrews(this.session.checkin.checkinDate);

                this.setPristine();
            }
        } finally {
            this.loading = false;
        }
    }

    async getProductionCrews(date: Date) {

        try {
            let getProductionCrewCriteria: IProductionCrewCriteria = {
                atDate: this.ocDateSvc.removeLocalTimeZoneOffset(date)
            };

            let productionCrews = await this.dataBroker.getProductionCrews(getProductionCrewCriteria);

            this.productionCrews = productionCrews.result;

            // if a production crew is already selected and it is no longer in the list of production crews, clear it
            if (this.session.checkin.productionCrewControl && !this.productionCrews.find(p => p.id === this.helperSvc.getKey(this.session.checkin.productionCrewControl)))
                this.session.checkin.productionCrewControl = null;
        } catch (error) {
            this.errorReporter.logError(error, 'CheckIn-GetProductionCrews');
        }
    }

    setPristine() {
        if (this.form)
            this.form.$setPristine();
    }

    // this function gets called when a control loses focus (onblur) with the attribute as the field (e.g. 'hours' or 'distance')
    onBlur(field) {

        try {
            // invoke the save function bound to this instance, which is actually on maintenanceCtrl.ts
            this.save({
                andValidate: true,
                validateReason: 'onBlur',
                field: field
            });
        } catch (error) {
            // absorb 
        }
    }

    elementBinding(element, valueAttribute = 'ng-model') {
        return !element ? undefined : angular.element(element).attr(valueAttribute);
    }

    currentField() {
        if (!document.activeElement)
            return undefined;

        // get the ng-model property
        let binding = this.elementBinding(document.activeElement);

        if (!binding)
            return undefined; // no ng-model attribute        

        // strip off "vm.session" from the ngModel
        return binding.replace('vm.session.', '');
    }
}

class MaintenanceCheckinComponent implements ng.IComponentOptions {
    public bindings = {
        session: '<',
        vehicle: '<',
        save: '&',
        invalid: '=',
        errorPanel: '=',
        currentError: '=',
        readOnly: '='
    };
    public template = tmpl;
    public controller = MaintenanceCheckinCtrl;
    public controllerAs = 'vm';
}

angular.module('app.directives').component('maintenanceCheckin', new MaintenanceCheckinComponent());