/**This method is to add css custom variables to distinguish between UAT and PROD Environments
* In order to manipulate the css custom variables, please go to app/styles/base/_variables.scss to adjust the color in .uat and .prod class.
* https://uat.otracom.com and https://localhost will used uat class from _variables.scss
* https://otracom.com will used prod class from _variables.scss
*/

window.document.addEventListener('DOMContentLoaded', function () {
    const url: string = window.location.href;
    const isDevelopmentUrl: boolean = url.includes('uat') || url.includes('localhost');

    if (isDevelopmentUrl && !document.body.classList.contains('uat')) {
        document.body.classList.add('uat');
    } else if (!isDevelopmentUrl && !document.body.classList.contains('prod')) {
        document.body.classList.add('prod');
    };

});