//import angular from 'angular';
import tmpl from './ocMenuSearch.html';


    angular.module('app.directives')
        .directive('ocMenuSearch', [function () {
            return {
                replace: true,
                transclude: true,
                restrict: 'E',
                requires: 'ocMenu',
                scope: {
                    menuItems: "=",
                    includeTopLevel: "@?",
                    onSearch: "&"
                },
                template: tmpl,
                link: function (scope: any) {

                    scope.menuSearchText = null;
                    scope.filteredMenuItems = null;

                    // left-side menu functions
                    scope.clearMenuSearchText = function () {
                        scope.menuSearchText = null;
                        scope.filterMenu(null);
                    };

                    scope.filterMenu = function (groupSequence) {
                        if (scope.menuSearchText && scope.menuSearchText.length > 0) {

                            scope.filteredMenuItems = angular.copy(scope.menuItems).map(function (i) {
                                return scope.displayItem(i, groupSequence, true);
                            }).filter(function (i) {
                                return i !== null;
                            });

                        } else {
                            scope.filteredMenuItems = null;
                        }

                        scope.onSearch({ items: scope.filteredMenuItems });
                    };

                    scope.displayItem = function (item, groupSequence, topLevel) {

                        if (groupSequence && groupSequence !== item.groupSequence) {
                            return item;
                        }

                        if (!topLevel || (topLevel && (scope.includeToplevel || !item.childItems || item.childItems.length === 0))) {
                            if (item.text.toLowerCase().indexOf(scope.menuSearchText.toLowerCase()) > -1) {
                                return item;
                            }
                        }

                        if (item.childItems && item.childItems.length > 0) {

                            var filteredChildren = item.childItems.map(function (c) {
                                return scope.displayItem(c, groupSequence, false);
                            }).filter(function (c) {
                                return c !== null;
                            });

                            if (filteredChildren && filteredChildren.length > 0) {
                                item.childItems = filteredChildren;
                                return item;
                            }
                        }

                        return null;
                    };

                    scope.filteredMenuContainsGroupSequence = function (groupSequence) {
                        return scope.filteredMenuItems && scope.filteredMenuItems.filter(function (i) { return i.groupSequence === groupSequence; }).length > 0;
                    };
                }
            };
        }]);
