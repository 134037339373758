/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Holds the main content of the TileLayoutItem component.
 */
var TileLayoutItemBodyComponent = /** @class */ (function () {
    function TileLayoutItemBodyComponent() {
        this.hostClass = true;
        this.minHeight = 0;
    }
    tslib_1.__decorate([
        core_1.HostBinding('class.k-tilelayout-item-body'),
        core_1.HostBinding('class.k-card-body'),
        tslib_1.__metadata("design:type", Boolean)
    ], TileLayoutItemBodyComponent.prototype, "hostClass", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('style.min-height'),
        tslib_1.__metadata("design:type", Number)
    ], TileLayoutItemBodyComponent.prototype, "minHeight", void 0);
    TileLayoutItemBodyComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-tilelayout-item-body',
            template: "\n        <ng-content></ng-content>\n    "
        })
    ], TileLayoutItemBodyComponent);
    return TileLayoutItemBodyComponent;
}());
exports.TileLayoutItemBodyComponent = TileLayoutItemBodyComponent;
