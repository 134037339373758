//import angular from 'angular';
import BrowserSvc from '../../../services/browserSvc';
import tmpl from './pressureReadingType.html';


class PressureReadingTypeCtrl implements ng.IController, IWindowController {

    form: ng.IFormController;
    wnd: IWindowObj;

    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: () => void };

    initParams: any;

    vehicle: any;
    system: any;
    readonly: boolean;

    rememberPressureReadingType: boolean;

    pressureReadingTypes: any[] = [];
    pressureReadingType: any = null;

    static $inject = ['$scope', 'WindowFactory', 'errorReporter', 'confirmSvc', '$injector', 'dataBroker', 'helperSvc', 'browserSvc'];

    constructor(private $scope: ng.IScope, private WindowFactory: IWindowFactory, private errorReporter: IErrorReporter,
        private confirmSvc: IConfirmSvc, private $injector: any, private dataBroker: IDataBroker,
        private helperSvc: IHelperSvc, private browserSvc: BrowserSvc) {

        this.$scope.$watch(() => this.pressureReadingType, (newValue, oldValue) => {
            if (oldValue && newValue !== oldValue)
                this.form.$setDirty();
        });

        this.$scope.$watchGroup([() => this.form.$invalid, () => this.wnd.processing, () => this.form.$pristine], () => {
            this.buttonStates.saveButton.disabled = this.form.$invalid || this.form.$pristine || this.wnd.processing;
        });
    }

    async $onInit() {

        this.wnd.processing = true;

        try {
            this.vehicle = this.initParams.vehicle;
            this.system = this.initParams.system;
            this.readonly = this.initParams.readonly;

            this.WindowFactory.addButton(this, 'common.save_label', 'saveButton', () => this.save(), true, !this.readonly);
            this.WindowFactory.addButton(this, 'common.cancel_label', 'cancelButton', () => this.cancel());

            this.pressureReadingTypes = this.initParams.pressureReadingTypes || (await this.dataBroker.getPressureReadingTypes()).result;

            if (this.vehicle && this.vehicle.readingEventTypeCommentId)
                this.pressureReadingType = this.pressureReadingTypes.find(p => p.id === this.vehicle.readingEventTypeCommentId);

        } finally {
            this.wnd.processing = false;
        }
    }

    async cancel() {
        try {
            await this.confirmSvc.confirmSaveChange(this.form.$dirty);
        } catch {
            return false; // they cancelled
        }

        this.WindowFactory.closeWindow(this.wnd);
        return true;
    }

    async save() {

        this.wnd.processing = true;

        try {
            if (this.vehicle) {                
                this.vehicle.readingEventTypeComment = this.pressureReadingType;
                this.vehicle.readingEventTypeCommentId = this.helperSvc.getKey(this.pressureReadingType);
            }

            if (this.rememberPressureReadingType && this.system) {

                this.system.readingEventTypeCommentId = this.helperSvc.getKey(this.pressureReadingType);

                if (this.browserSvc.isMobile) {
                    try {
                        await this.dataBroker.updateSystemSettings(this.system);
                    } catch (error) {
                        this.errorReporter.logError(error, 'PressureReadingTypeSave');
                    }
                }
            }

            if (this.wnd.onDataChanged)
                this.wnd.onDataChanged();

            this.WindowFactory.closeWindow(this.wnd);

        } finally {
            this.wnd.processing = false;
        }
    }
}

class PressureReadingTypeComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = PressureReadingTypeCtrl;
    public controllerAs = 'vm';
}

angular.module('app.fieldSurveys').component('pressureReadingType', new PressureReadingTypeComponent());