<div class="property-row" [ngStyle]="getColumnStyles()">
    <div class="property-label" [ngStyle]="{'align-self': verticalAlignLabel}">
        <label [ngClass]="{'ng-required': required}">
            {{label}}
        </label>
    </div>    
    <div class="property-value">
        <ng-content></ng-content>
    </div>
</div>
