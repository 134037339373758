//import angular from 'angular';
import _ from 'underscore';
import AdminUrls from '../../../adminUrls';
import tmpl from './addValidFitment.html';



angular.module('app.admin').component('addValidFitment', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['$scope', 'confirmSvc', 'enums', 'adminUrls', 'WindowFactory', 'componentUrls', 'amtCommandQuerySvc', 'notifySvc', 'amtXlatSvc', 'errorReporter',
            function ($scope, confirmSvc, enums, adminUrls: AdminUrls, WindowFactory: IWindowFactory, componentUrls, amtCommandQuerySvc, notifySvc, amtXlatSvc: IAmtXlatSvc, errorReporter) {

                var vm = this;
                $scope.vm = this;

                vm.readonly = false;

                vm.adminUrls = adminUrls;
                vm.componentUrls = componentUrls;

                this.$onInit = function () {

                    if (vm.wnd !== null) {
                        vm.wnd.onClose = vm.cancel;
                    }

                    vm.wnd.processing = true;

                    vm.data = angular.copy(vm.initParams);

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: "common.save_label",
                            name: "saveButton",
                            click: "save",
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: false,
                            value: "common.saveAndAddAnother_label",
                            name: "saveAndAddAnotherButton",
                            click: "saveAndAddAnother",
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: !vm.readonly,
                            disabled: true
                        },
                        saveAndAddAnotherButton: {
                            visible: !vm.readonly,
                            disabled: true
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.save,
                        saveAndAddAnother: vm.save
                    };

                    loadEquipmentTypes().then(function () {

                        if (!vm.data.id) {
                            vm.data.equipmentType = _.find(vm.componentTypes, ct => ct.name.toLowerCase() === enums.equipmentTypes.tyre);
                        }

                    }).finally(function () {
                        vm.wnd.processing = false;
                    })
                };

                function loadEquipmentTypes() {
                    return amtCommandQuerySvc.post(vm.adminUrls.getEquipmentTypes, { includeVehicle: false }).then(function (response) {
                        if (response && response.result) {
                            vm.componentTypes = response.result;
                        }
                    }, amtCommandQuerySvc.handleError);
                };

                vm.save = function (button) {

                    var criteria = {
                        systemHierarchyId: vm.data.systemHierarchyId,
                        equipmentTypeSpecificationTypeId: vm.data.equipmentTypeSpecificationTypeId,
                        axleTypeId: (vm.data.applyToAllAxles === true ? null : vm.data.axleTypeId),
                        equipmentSizeId: vm.data.equipmentSizeId,
                        exclude: false
                    };

                    vm.wnd.processing = true;

                    return amtCommandQuerySvc.post(vm.adminUrls.addModifyValidFitment, criteria).then(function (response) {

                        notifySvc.success(amtXlatSvc.xlat('specifications.validFitmentSavedSuccessfully'));

                        if (vm.wnd.onDataChanged) {
                            vm.wnd.onDataChanged();
                        }

                        if (button === 'saveAndAddAnother') {

                            // restore the defaults
                            vm.data.equipmentType = _.find(vm.componentTypes, function (ct) {
                                return ct.name.toLowerCase() === enums.equipmentTypes.tyre;
                            });

                            vm.data.equipmentSize = null;
                            vm.data.applyToAllAxles = false;

                            vm.form.$setPristine();

                        } else {
                            vm.closeWindow();
                        }

                    }).catch(function (error) {
                        errorReporter.logError(error);
                    }).finally(function () {
                        vm.wnd.processing = false;
                    });
                };

                vm.closeWindow = function () {
                    WindowFactory.closeWindow(vm.wnd);
                };

                vm.cancel = function () {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        vm.form.dirty = false;
                        vm.closeWindow();
                        return true;
                    });

                    return false;
                };

                $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing'], function () {
                    vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.form.$pristine || vm.wnd.processing;
                    vm.buttonStates.saveAndAddAnotherButton.disabled = vm.form.$invalid || vm.form.$pristine || vm.wnd.processing;
                });
            }
        ]
    });
