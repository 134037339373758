/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Specifies the adornments in the suffix container ([see examples]({% slug adornments_textbox %}#toc-suffixadornments)).
 * ```ts-no-run
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-textbox>
 *    <ng-template kendoTextBoxSuffixTemplate>
 *        <button kendoButton look="clear" icon="image"></button>
 *    </ng-template>
 *  </kendo-textbox>
 * `
 * })
 * class AppComponent {}
 * ```
 */
var TextBoxSuffixTemplateDirective = /** @class */ (function () {
    function TextBoxSuffixTemplateDirective(templateRef) {
        this.templateRef = templateRef;
    }
    TextBoxSuffixTemplateDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoTextBoxSuffixTemplate]'
        }),
        tslib_1.__param(0, core_1.Optional()),
        tslib_1.__metadata("design:paramtypes", [core_1.TemplateRef])
    ], TextBoxSuffixTemplateDirective);
    return TextBoxSuffixTemplateDirective;
}());
exports.TextBoxSuffixTemplateDirective = TextBoxSuffixTemplateDirective;
