//import angular from 'angular';

angular.module('amtFramework.validation').directive('amtRequired', ['$compile',
    function ($compile) {
                return {
                    require: '?ngModel',
                    link: function (scope: any, elem, attr, ctrl) {
                        if (!ctrl) {
                            return;
                        }

                        attr.required = true; // force truthy in case we are on non input element


                        var valSpan = angular.element('<span class="error-message error-required" ng-hide="requiredValid">{{"common.validationRequired_message" | xlat}}</span>');

                        valSpan.insertAfter(elem);
                        $compile(valSpan)(scope);

                        var validator = function(value) {
                            if (attr.required && ctrl.$isEmpty(value)) {
                                ctrl.$setValidity('required', false);
                                scope.requiredValid = false;
                                return undefined;
                            } else {
                                ctrl.$setValidity('required', true);
                                scope.requiredValid = true;
                                return value;
                            }
                        };

                        ctrl.$formatters.push(validator);
                        ctrl.$parsers.unshift(validator);

                        attr.$observe('required', function() {
                            validator(ctrl.$viewValue);
                        });
                    }
                };
    }
]);
