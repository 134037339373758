interface IRecalculateForecastCriteria {
    forecastId: number;
    sessionId: guid;
    updateStockData: boolean;
    updateEquipmentData: boolean;
}

class RecalculateForecastCtrl implements ng.IController, IWindowController {

    form: ng.IFormController;
    wnd: IWindowObj;

    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: () => void };

    initParams: any;

    forecastId: number;
    updateStockData: boolean;
    updateEquipmentData: boolean;

    recalculateProgressTimer: number;
    recalculateForecastProgress: any;

    sessionId: guid;

    static $inject = ['$scope', 'WindowFactory', 'errorReporter', 'amtConstants', 'amtCommandQuerySvc', 'notifySvc', 'amtXlatSvc'];

    constructor(private $scope: ng.IScope, private WindowFactory: IWindowFactory, private errorReporter: IErrorReporter,
        private amtConstants: IAmtConstants, private amtCommandQuerySvc: IAmtCommandQuerySvc, private notifySvc: INotifySvc, private amtXlatSvc: IAmtXlatSvc) {

        this.$scope.$watch(() => this.wnd.processing, () => {
            this.buttonStates.recalculateButton.disabled = this.wnd.processing;
        });
    }

    $onInit() {

        this.forecastId = this.initParams.forecastId;

        this.WindowFactory.addButton(this, 'forecast.recalculate', 'recalculateButton', () => this.recalculate(), true);
        this.WindowFactory.addButton(this, 'common.cancel_label', 'cancelButton', () => this.cancel());
    }

    cancel() {
        this.WindowFactory.closeWindow(this.wnd);
    }

    async recalculate() {

        this.wnd.processing = true;

        try {

            this.sessionId = uuid();

            let criteria: IRecalculateForecastCriteria = {
                sessionId: this.sessionId,
                forecastId: this.forecastId,
                updateStockData: this.updateStockData,
                updateEquipmentData: this.updateEquipmentData
            };
           
            this.cancelRecalculateProgressTimer();
            this.recalculateProgressTimer = window.setInterval(() => this.checkForecastProgressCall(), 1000);

            await this.amtCommandQuerySvc.post(this.amtConstants.url.recalculateForecast, criteria);

            this.notifySvc.success(this.amtXlatSvc.xlat('forecastDetails.forecastSuccessfullyRecalculated'));

            if (this.wnd.onDataChanged)
                this.wnd.onDataChanged();

            this.WindowFactory.closeWindow(this.wnd);

        } catch (error) {            
            this.errorReporter.logError(error, 'Forecast-RecalculateForecast');
            this.checkForecastProgressCall();
        } finally {
            this.wnd.processing = false;
            this.cancelRecalculateProgressTimer();
        }
    }

    async checkForecastProgressCall() {

        try {
            let response = await this.amtCommandQuerySvc.put(this.amtConstants.url.checkForecastProgress, { sessionId: this.sessionId });

            this.recalculateForecastProgress = response;

            if (this.recalculateForecastProgress.percentage === 100 || this.recalculateForecastProgress.forecastProgressStatus === 'error')
                this.cancelRecalculateProgressTimer();
        } catch (error) {
            this.errorReporter.logError(error);
            this.cancelRecalculateProgressTimer();
        }
    }

    cancelRecalculateProgressTimer() {
        if (this.recalculateProgressTimer) {
            window.clearInterval(this.recalculateProgressTimer);
            this.recalculateProgressTimer = null;
        }
    }
}

class RecalculateForecastComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    };
    public templateUrl = 'app/modules/forecasting/forecastDetails/recalculateForecast.html';
    public controller = RecalculateForecastCtrl;
    public controllerAs = 'vm';
}

angular.module('app.site').component('recalculateForecast', new RecalculateForecastComponent());