/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var kendo_licensing_1 = require("@progress/kendo-licensing");
var package_metadata_1 = require("../package-metadata");
var util_1 = require("../util");
/**
 * Displays a Chip that represents an input, attribute or an action.
 */
var ChipComponent = /** @class */ (function () {
    function ChipComponent(element, renderer, ngZone, localizationService) {
        this.element = element;
        this.renderer = renderer;
        this.ngZone = ngZone;
        this.localizationService = localizationService;
        /**
         * Specifies the selected state of the Chip.
         * @default false
         */
        this.selected = false;
        /**
         * Specifies if the Chip will be removable or not.
         * If the property is set to `true`, the Chip renders a remove icon.
         * @default false
         */
        this.removable = false;
        /**
         * If set to `true`, the Chip will be disabled.
         * @default false
         */
        this.disabled = false;
        /**
         * Fires each time the user clicks the remove icon of the Chip.
         */
        this.remove = new core_1.EventEmitter();
        /**
         * Fires each time the user clicks the content of the Chip.
         */
        this.contentClick = new core_1.EventEmitter();
        this.tabIndex = 0;
        this.hostClass = true;
        this._size = 'medium';
        this._rounded = 'medium';
        this._fillMode = 'solid';
        this._themeColor = 'base';
        this.focused = false;
        kendo_licensing_1.validatePackage(package_metadata_1.packageMetadata);
        this.direction = localizationService.rtl ? 'rtl' : 'ltr';
    }
    Object.defineProperty(ChipComponent.prototype, "size", {
        get: function () {
            return this._size;
        },
        /**
         * The size property specifies the padding of the Chip
         * ([see example]({% slug appearance_chip %}#toc-size)).
         *
         * The possible values are:
         * * `'small'`
         * * `'medium'` (default)
         * * `'large'`
         * * `null`
         */
        set: function (size) {
            this.handleClasses(size, 'size');
            this._size = size;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipComponent.prototype, "rounded", {
        get: function () {
            return this._rounded;
        },
        /**
         * The rounded property specifies the border radius of the Chip
         * ([see example]({% slug appearance_chip %}#toc-rounded)).
         *
         * The possible values are:
         * * `'small'`
         * * `'medium'` (default)
         * * `'large'`
         * * `'full'`
         * * `null`
         */
        set: function (rounded) {
            this.handleClasses(rounded, 'rounded');
            this._rounded = rounded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipComponent.prototype, "fillMode", {
        get: function () {
            return this._fillMode;
        },
        /**
         * The fillMode property specifies the background and border styles of the Chip
         * ([see example]({% slug appearance_chip %}#toc-fillMode)).
         *
         * The possible values are:
         * * `'solid'` (default)
         * * `'outline'`
         * * `null`
         */
        set: function (fillMode) {
            this.handleClasses(fillMode, 'fillMode');
            this._fillMode = fillMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipComponent.prototype, "themeColor", {
        get: function () {
            return this._themeColor;
        },
        /**
         * The Chip allows you to specify predefined theme colors.
         * The theme color will be applied as a background and border color while also amending the text color accordingly
         * ([see example]({% slug appearance_chip %}#toc-themeColor)).
         *
         * The possible values are:
         * * `'base'` (default)
         * * `'info'`
         * * `'success'`
         * * `'warning'`
         * * `'error'`
         * * `null`
         */
        set: function (themeColor) {
            this.handleThemeColor(themeColor);
            this._themeColor = themeColor;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipComponent.prototype, "ariaChecked", {
        get: function () {
            return this.selected;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipComponent.prototype, "hasIconClass", {
        get: function () {
            return this.icon || this.iconClass || this.avatarClass ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipComponent.prototype, "disabledClass", {
        get: function () {
            return this.disabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipComponent.prototype, "selectedClass", {
        get: function () {
            return this.selected;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipComponent.prototype, "focusedClass", {
        get: function () {
            return this.focused;
        },
        enumerable: true,
        configurable: true
    });
    ChipComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dynamicRTLSubscription = this.localizationService.changes
            .subscribe(function (_a) {
            var rtl = _a.rtl;
            return _this.direction = rtl ? 'rtl' : 'ltr';
        });
    };
    ChipComponent.prototype.ngOnDestroy = function () {
        if (this.dynamicRTLSubscription) {
            this.dynamicRTLSubscription.unsubscribe();
        }
        if (this.detachDomEvents) {
            this.detachDomEvents();
        }
    };
    ChipComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        var chip = this.element.nativeElement;
        var stylingOptions = ['size', 'rounded', 'fillMode'];
        stylingOptions.forEach(function (input) {
            _this.handleClasses(_this[input], input);
        });
        this.attachElementEventHandlers(chip);
    };
    Object.defineProperty(ChipComponent.prototype, "kendoIconClass", {
        /**
         * @hidden
         */
        get: function () {
            this.verifyIconSettings([this.iconClass, this.avatarClass]);
            return "k-i-" + this.icon;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipComponent.prototype, "customIconClass", {
        /**
         * @hidden
         */
        get: function () {
            this.verifyIconSettings([this.icon, this.avatarClass]);
            return "" + this.iconClass;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipComponent.prototype, "chipAvatarClass", {
        /**
         * @hidden
         */
        get: function () {
            this.verifyIconSettings([this.icon, this.iconClass]);
            return "" + this.avatarClass;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChipComponent.prototype, "removeIconClass", {
        /**
         * @hidden
         */
        get: function () {
            if (this.removeIcon) {
                return "" + this.removeIcon;
            }
            return "k-i-close-circle";
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Focuses the Chip component.
     */
    ChipComponent.prototype.focus = function () {
        if (kendo_angular_common_1.isDocumentAvailable()) {
            this.element.nativeElement.focus();
        }
    };
    /**
     * Blurs the Chip component.
     */
    ChipComponent.prototype.blur = function () {
        if (kendo_angular_common_1.isDocumentAvailable()) {
            this.element.nativeElement.blur();
        }
    };
    /**
     * @hidden
     */
    ChipComponent.prototype.onRemoveClick = function (e) {
        if (this.removable) {
            this.remove.emit({ sender: this, originalEvent: e });
        }
    };
    ChipComponent.prototype.attachElementEventHandlers = function (chip) {
        var _this = this;
        this.ngZone.runOutsideAngular(function () {
            var focusListener = _this.renderer.listen(chip, 'focus', function () {
                _this.renderer.addClass(chip, 'k-focus');
            });
            var blurListener = _this.renderer.listen(chip, 'blur', function () {
                _this.renderer.removeClass(chip, 'k-focus');
            });
            var contentClickListener = _this.renderer.listen(chip, 'click', function (e) {
                var isRemoveClicked = util_1.closest(e.target, '.k-chip-remove-action');
                if (!isRemoveClicked) {
                    _this.ngZone.run(function () {
                        _this.contentClick.emit({ sender: _this, originalEvent: e });
                    });
                }
            });
            _this.detachDomEvents = function () {
                focusListener();
                blurListener();
                contentClickListener();
            };
        });
    };
    /**
     * @hidden
     */
    ChipComponent.prototype.verifyIconSettings = function (iconsToCheck) {
        if (core_1.isDevMode()) {
            if (iconsToCheck.filter(function (icon) { return icon !== null && icon !== undefined; }).length > 0) {
                this.renderer.removeClass(this.element.nativeElement, 'k-chip-has-icon');
                throw new Error('Invalid configuration: Having multiple icons is not supported. Only a single icon on a chip can be displayed.');
            }
        }
    };
    ChipComponent.prototype.handleClasses = function (value, input) {
        var elem = this.element.nativeElement;
        var classes = util_1.getStylingClasses('chip', input, this[input], value);
        if (input === 'fillMode') {
            this.handleThemeColor(this.themeColor, this[input], value);
        }
        if (classes.toRemove) {
            this.renderer.removeClass(elem, classes.toRemove);
        }
        if (classes.toAdd) {
            this.renderer.addClass(elem, classes.toAdd);
        }
    };
    ChipComponent.prototype.handleThemeColor = function (value, prevFillMode, fillMode) {
        var elem = this.element.nativeElement;
        var removeFillMode = prevFillMode ? prevFillMode : this.fillMode;
        var addFillMode = fillMode ? fillMode : this.fillMode;
        var themeColorClass = util_1.getThemeColorClasses('chip', removeFillMode, addFillMode, this.themeColor, value);
        this.renderer.removeClass(elem, themeColorClass.toRemove);
        if (addFillMode !== null && fillMode !== null) {
            if (themeColorClass.toAdd) {
                this.renderer.addClass(elem, themeColorClass.toAdd);
            }
        }
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ChipComponent.prototype, "label", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ChipComponent.prototype, "icon", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ChipComponent.prototype, "iconClass", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ChipComponent.prototype, "avatarClass", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], ChipComponent.prototype, "selected", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], ChipComponent.prototype, "removable", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], ChipComponent.prototype, "removeIcon", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], ChipComponent.prototype, "disabled", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], ChipComponent.prototype, "size", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], ChipComponent.prototype, "rounded", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], ChipComponent.prototype, "fillMode", null);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String),
        tslib_1.__metadata("design:paramtypes", [String])
    ], ChipComponent.prototype, "themeColor", null);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ChipComponent.prototype, "remove", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], ChipComponent.prototype, "contentClick", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('attr.tabindex'),
        tslib_1.__metadata("design:type", Number)
    ], ChipComponent.prototype, "tabIndex", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('attr.aria-checked'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ChipComponent.prototype, "ariaChecked", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-chip'),
        tslib_1.__metadata("design:type", Boolean)
    ], ChipComponent.prototype, "hostClass", void 0);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-chip-has-icon'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ChipComponent.prototype, "hasIconClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.aria-disabled'),
        core_1.HostBinding('class.k-disabled'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ChipComponent.prototype, "disabledClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-selected'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ChipComponent.prototype, "selectedClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('class.k-focus'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], ChipComponent.prototype, "focusedClass", null);
    tslib_1.__decorate([
        core_1.HostBinding('attr.dir'),
        tslib_1.__metadata("design:type", String)
    ], ChipComponent.prototype, "direction", void 0);
    ChipComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-chip',
            template: "\n        <span\n            *ngIf=\"icon\"\n            class=\"k-chip-icon k-icon\"\n            [ngClass]=\"kendoIconClass\"\n        >\n        </span>\n\n        <span\n            *ngIf=\"iconClass\"\n            class=\"k-chip-icon\"\n            [ngClass]=\"customIconClass\"\n        >\n        </span>\n\n        <span\n            *ngIf=\"avatarClass\"\n            class=\"k-chip-avatar k-avatar k-rounded-full\"\n        >\n            <span class=\"k-avatar-image\" [ngClass]=\"chipAvatarClass\"></span>\n        </span>\n\n        <span class=\"k-chip-content\">\n            <span class=\"k-chip-label\" *ngIf=\"label\">\n                {{ label }}\n            </span>\n            <ng-content *ngIf=\"!label\"></ng-content>\n        </span>\n\n        <span class=\"k-chip-actions\">\n            <span class=\"k-chip-action k-chip-remove-action\"\n                *ngIf=\"removable\"\n                (click)=\"onRemoveClick($event)\"\n                >\n                <span\n                    class=\"k-icon\"\n                    [ngClass]=\"removeIconClass\"\n                >\n                </span>\n            </span>\n        </span>\n    ",
            providers: [
                kendo_angular_l10n_1.LocalizationService,
                {
                    provide: kendo_angular_l10n_1.L10N_PREFIX,
                    useValue: 'kendo.chip'
                }
            ]
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.ElementRef,
            core_1.Renderer2,
            core_1.NgZone,
            kendo_angular_l10n_1.LocalizationService])
    ], ChipComponent);
    return ChipComponent;
}());
exports.ChipComponent = ChipComponent;
