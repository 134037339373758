import * as _ from 'underscore'

/* global kendo: false */

/**
 * @ngdoc directive
 * @name amtFramework.grid.directive:amtGrid
 * @restrict E
 * @scope
 *
 * @param {string} id The id of the grid. Has to be unique in the whole application as it is used for storing user preferences
 * @param {string} readUrl Url to an http POST method with signature like: 
 * <pre>
 * {"filterValues":{},
 *  "page":1,
 *  "pageSize":10,
 *  "sort":[{"field":"version","dir":"asc"}],
 *           "filter":{"logic":"and", 
 *                     "filters":[{"field":"budgetType",
 *                                 "operator":"contains",
 *                                 "value":"5"
 *                               }]
 *                           }
 *                     }
 * </pre>
 * In WebApi controller use the PagedCriteria class.
 * @param {string} primaryKey Fieldname that is the Primary Key
 * @param {string=} updateUrl Url to an http PUT method
 * @param {string=} deleteUrl: Url to an http DELETE method
 * @param {string=} createUrl: Url to an http POST method
 * @param {expression=} filterValues To filter the data based on external filters. {fieldname: fieldvalue, ...} Works directly with {@link amtFramework.filter.directive:amtFilter amtFilter}
 * @param {expression=} criteria criteria object to add to query
 * @param {expression=} control reference to this control to call methods(see below)
 * @param {boolean=} [editable=true] Editable
 * @param {boolean=} [deletable=true] Deletable 
 * @param {boolean=} [sortable=false] Sorting
 * @param {boolean=} [groupable=false] NOT USED YET
 * @param {boolean=} [filterable=true] Filter on columns.
 * @param {boolean=} [resizable=true] Columns resizable
 * @param {boolean=} [reorderable=true] Columns reorderable
 * @param {boolean=} [pageable=true] Paging enabled
 * @param {boolean=} [tickable=false] Tickbox column visible
 * @param {boolean=} [selectable=false] Rows selectable
 * @param {boolean=} [autoBind=true] When set to false the refresh() method has to be called manually.
 *                                  Useful when the user has to set filters first.
 * @param {boolean=} [showTooltips=true] Show tooltips on the column header.
 * @param {boolean=} [showRefresh=true] Show the refresh glyph. Defaults to false
 * @param {integer=} [grid-height=100] Fix the height of the grid. Useful for modal windows.
 * @param {boolean=} [scrollable=false] Enable/disable scrolling of the grid. Used in tandem with grid-height. Defaults to false.
 * @param {expression=} datasource Use an external datasource
 * 
 * @param {callback=} onRead Is called after the read has succeeded
 * @param {callback=} onDatabound Is called after the grid is databound
 * @param {callback=} onSelectedItemChange Is called after a row is selected. 
 *                                       Signature: onSelectecItemChanged(items) where items = Object[]
 * @param {callback=} onSave Is called after save was succesful.
 *                                       Signature: onSave(item) where items = Object
 * @param {callback=} onDelete Is called after delete was succesful
 *                                       Signature: onDelete(items) where items = Object
 * @param {string=} localStorageKey Used to control the key when setting/getting from local storage Format: Key+control+property
 * @param {string=} noResultMessage Message to display on no results being returned from Url
 * 
 * @param {function} refresh() refreshes the grid by calling the readUrl again
 * @param {function} recompile() Recompiles the grid to support dynamically adding columns
 * @param {function} getTickedItems() Gives a list of the ticked rows as objects
 * @param {function} getColumns() Gives a list of columns in the grid
 * @param {function} getAllItems() Gives a list of all rows
 * @param {function} getSelectedItems() Gives a list of all selected rows
 * @param {function} selectRowById(id) Selects the row with the given primary key
 * @param {function} changeTickedSelectAll(value) Ticks or unticks all rows depending on value (true or false)
 * @param {function} getTotalItemsCount() Gives a total count of all rows
 * 
 * @description
 * Grid with support for paging sorting filtering etc.
 * 
 * @example
 * <doc:example module="amtFramework">
 *   <doc:source>
 *     <amt-grid id="demoGrid"
 *               primary-key="id"
 *               read-url="http://isauci01/getGridItems"
 *               delete-url="http://isauci01/deleteGridItem"
 *               deletable="true">
 *       <amt-grid-column field="id" title="Id"></amt-column>
 * 
 *     </amt-grid>
 *   </doc:source>
 * </doc:example>
 */

    /*jshint maxcomplexity:false */

//import angular from 'angular';
import tmpl from './amtGrid.html';
import './amtGrid.scss';

angular.module('amtFramework.grid').provider("amtGridConfig", [
    function () {
        var _localStorageDisabled = false;

        this.disableLocalStorage = function () {
            _localStorageDisabled = true;
        };

        this.localStorageIsDisabled = function () {
            return _localStorageDisabled;
        };

        this.$get = function () {
            return this;
        };
    }
]);

angular.module('amtFramework.grid').directive("amtGrid", amtGrid);
amtGrid.$inject = ['amtXlatSvc', 'appConfig', '$timeout', '$rootScope'];

angular.module('amtFramework.grid').controller("amtGridController", amtGridController);

amtGridController.$inject = ['$rootScope', '$scope', '$timeout', 'amtXlatSvc', 'appConfig', '$compile', '$http', 'amtGridConfig', '$q'];
function amtGridController($rootScope, $scope, $timeout, xlatSvc, appConfig, $compile, $http, amtGridConfig, $q) {
        if ($scope.id === undefined) {
            throw new Error("id is mandatory (you need an id attribute on the amt-grid element)");
        }

        if ($scope.primaryKey === undefined) {
            throw new Error("Primary Key is mandatory");
        }

        var self = this;
        var internalEditable = false;
        var isFirstBindDataSource = true;
        var intDataTypeName = $scope.datatypeName;
        if ($scope.editable) {
            internalEditable = true;
        }
        /*if ($scope.editableMode !== "batch") {
            internalEditable = { mode: "inline" };
        }*/
        $scope.editable = $scope.$eval($scope.editable);

        //TODO: this code looks like garbage
        var intSelectable: any = false;
        if ($scope.selectable) {
            intSelectable = $scope.$eval($scope.selectable);
            if (intSelectable === true) {
                intSelectable = "row";
            }
        }

        var gridHeight = $scope.gridHeight;

        var scrollable = false;
        if ($scope.scrollable) {
            scrollable = $scope.$eval($scope.scrollable);
        }

        var navigatable = false;
        if ($scope.navigatable) {
            navigatable = $scope.$eval($scope.navigatable);
        }


        var isUsingStaticData = $scope.readUrl ? false : true;
        $scope.hasData = true;
        $scope.internalControl = $scope.control || {};
        $scope.contextMenus = [];

        var remoteDataTransport = {
            read: function (o) {
                var grid = getGrid();
                var readCriteria: any = {};

                if (!!$scope.criteria && typeof $scope.criteria === 'object') {
                    readCriteria = angular.copy($scope.criteria);
                }

                readCriteria['filterValues'] = $scope.filterValues;
                readCriteria['page'] = o.data.page;
                readCriteria['pageSize'] = o.data.pageSize;
                readCriteria['filter'] = o.data.filter;
                readCriteria['sort'] = o.data.sort;

                if (filterValuesChanged && grid.pager && grid.pager.dataSource) {
                    let ds: any = grid.pager.dataSource; //TODO: any cast, possibly hides bug, we are setting nonstandard properties
                    ds._page = 1;
                    ds._skip = 0;
                    ds._currentRangeStart = 0;

                    readCriteria.page = 1;
                }

                if (readCriteria.sort) {
                    for (var c = 0; c < readCriteria.sort.length; c++) {
                        var sortCol = readCriteria.sort[c];

                        var gridCol = $scope.columns.find(function (col) { return col.field == sortCol.field; });

                        if (gridCol && gridCol.searchField) {
                            sortCol.field = gridCol.searchField;
                        }

                        switch (gridCol.searchFieldType) {
                            case 'daysInPast':
                                // flip the direction
                                sortCol.dir = sortCol.dir == "asc" ? "desc" : "asc";
                                break;
                        }
                    }
                }

                if (readCriteria.filter) {
                    var millisecondsPerDay = 24 * 60 * 60 * 1000;
                    var now = new Date();

                    for (var c = 0; c < readCriteria.filter.filters.length; c++) {
                        var filterCol = readCriteria.filter.filters[c];

                        var gridCol = $scope.columns.find(function (col) { return col.field == filterCol.field; });

                        if (gridCol && gridCol.searchField) {
                            // change the filter field
                            filterCol.field = gridCol.searchField;

                            // change the filter value
                            switch (gridCol.searchFieldType) {
                                case 'daysInPast':
                                    filterCol.value = new Date(now.getTime() - (filterCol.value * millisecondsPerDay));

                                    // flip greater than and less than
                                    switch (filterCol.operator) {
                                        case "gte":
                                            filterCol.operator = "lte";
                                            break;
                                        case "gt":
                                            filterCol.operator = "lt";
                                            break;
                                        case "lte":
                                            filterCol.operator = "gte";
                                            break;
                                        case "lt":
                                            filterCol.operator = "gt";
                                            break;
                                    }
                                    break;
                                case 'daysInFuture':
                                    filterCol.value = new Date(now.getTime() + (filterCol.value * millisecondsPerDay));
                                    break;
                            }
                        }
                    }
                }

                $http.post($scope.readUrl, readCriteria).then(function (response) {
                    if (response.data.status === "fail") {
                        $q.reject("Server Error on Grid Search: " + response.data.errors[0].message);
                    }
                    filterValuesChanged = false;

                    for (var i = 0; i < response.data.result.length; i++) {
                        response.data.result[i]._rowId = i;
                    }

                    o.success(response.data);
                    if (response.responseJSON) {
                        handleNotification(null, response.responseJSON);

                        if (grid && grid.pager && grid.pager.page() > 0 && response.responseJSON.result && response.responseJSON.result.length === 0) {
                            grid.pager.page(grid.pager.page() - 1);
                            grid.dataSource.fetch();
                        }

                        $scope.datasource = response.responseJSON.result;
                        $rootScope.$broadcast("datasourceReady");
                    }
                }).catch(function (error) {
                    // the error reporter is not available in the AMT framework
                    console.error("Amt grid post failure");
                    console.error(error);
                });
            },
            update: {
                url: function (opts) {
                    return replaceParam($scope.updateUrl, opts);
                },
                dataType: 'json',
                method: 'Put',
                contentType: 'application/json',
                data: function (request) {
                    setNotificationEntity("Update", request[$scope.primaryName]);
                    return request;
                },
                complete: function (e) {
                    handleNotification("Update", e.responseJSON);
                    //$scope.gridElement.data("kendoGrid").dataSource.cancelChanges();
                    $scope.gridElement.data("kendoGrid").dataSource.read();
                }
            },
            destroy: {
                url: function (opts) {
                    return replaceParam($scope.deleteUrl, opts);
                },
                dataType: 'json',
                method: 'Delete',
                contentType: 'application/json',
                data: function (request) {
                    setNotificationEntity("Delete", request[$scope.primaryName]);
                    return request;
                },
                complete: function (e) {
                    handleNotification("Delete", e.responseJSON);
                    $scope.gridElement.data("kendoGrid").dataSource.cancelChanges();
                    $scope.gridElement.data("kendoGrid").dataSource.read();
                }
            },
            create: {
                url: $scope.createUrl,
                dataType: 'json',
                method: 'Post',
                contentType: 'application/json',
                data: function (request) {
                    setNotificationEntity("Create", request[$scope.primaryName]);
                    return request;
                },
                complete: function (e) {
                    handleNotification("Create", e.responseJSON);
                    //$scope.gridElement.data("kendoGrid").dataSource.cancelChanges();
                    $scope.gridElement.data("kendoGrid").dataSource.read();
                }
            },
            parameterMap: function (data) {
                if (data.filter && data.filter.filters && data.filter.filters.length > 0) {
                    for (var i = 0; i < data.filter.filters.length; i++) {
                        if (data.filter.filters[i].value instanceof Date) {
                            data.filter.filters[i].value = kendo.toString(data.filter.filters[i].value, appConfig.dateFormat.shortDateServer);
                        }
                    }
                }
                return kendo.stringify(data);
            }
        };

        var staticDataTransport = {
            read: function (e) {
                var response = {
                    result: angular.copy($scope.datasource),
                    total: $scope.datasource && $scope.datasource.length
                };
                for (var i = 0; i < response.result.length; i++) {
                    response.result[i]._rowId = i;
                }
                return e.success(response);
            },
            update: function (e) {
                return e.success();
            },
            destroy: function (e) {
                return e.success();
            }
        };

        var gridOptions = {
            transport: isUsingStaticData ? staticDataTransport : remoteDataTransport,
            schema: {
                model: {
                    id: $scope.primaryKey,
                    fields: {}
                },
                data: function (response) {
                    $timeout(function () {
                        $scope.isSelectAll = false;
                        $scope.internalControl.isSelected = $scope.isSelectAll;
                    });
                    if ($scope.onRead()) {
                        response = $scope.onRead()({ response: response });
                    }
                    return response.result || response instanceof Array && response;
                },
                total: function (response) {
                    if ($scope.onRead()) {
                        response = $scope.onRead()({ response: response });
                    }

                    return response.total || response instanceof Array && response.length;
                },
                parse: function (response) {
                    if (angular.isDefined($scope.rowParseFunction)) {
                        return $scope.rowParseFunction({ response: response });
                    }
                    return response;
                }            
            }
        };


        var dataSource = {
            transport: gridOptions.transport,
            schema: gridOptions.schema,
            pageSize: getPageSize(),
            batch: /*$scope.editable && $scope.editableMode === "batch" ? true : */ false,
            change: function (e) {

                $timeout(function () {
                    var updatedCell = e.items[0];
                    if (isUsingStaticData) {
                        if (e.action === undefined || e.action === "sync") {
                            updateUidDataSource();
                            return;
                        }
                        if (e.action) {
                            $scope.internalControl.updatedItems = $scope.internalControl.getUpdatedItems();
                            //if (e.action === "itemchange" || e.action === "sync") {
                            if (e.action === "itemchange") {
                                var grid = getGrid();
                                var updatedRow = _.find(grid.dataSource.data(), function (item) {
                                    return item.uid === updatedCell.uid;
                                });

                                if (updatedRow) {
                                    for (var i = 0; i < $scope.datasource.length; i++) {
                                        if (equalObject($scope.datasource[i], updatedRow, "id")) {
                                            for (var prop in $scope.datasource[i]) {
                                                $scope.datasource[i][prop] = updatedRow[prop];
                                            }
                                            break;
                                        }
                                    }
                                }
                            } else if (e.action === "remove") {
                                for (var j = 0; j < $scope.datasource.length; j++) {
                                    var tempRow = $scope.datasource[j];
                                    if (equalObject(tempRow, updatedCell, "id")) {
                                        $scope.datasource.splice(j, 1);
                                        break;
                                    }
                                }
                            }
                        }
                    }
                });


            }
        };

        function updateUidDataSource() {
            var grid = getGrid();
            if (grid) {
                if (grid.dataSource.data().length > 0) {
                    for (var k = 0; k < grid.dataSource.data().length; k++) {
                        if ($scope.datasource[k]) {
                            $scope.datasource[k].uid = grid.dataSource.data()[k].uid;
                            $scope.datasource[k].id = grid.dataSource.data()[k].id;
                        }
                    }
                }
            }
        }

        function equalObject(obj1, obj2, keys) {
            var obj1Pick = _.pick(obj1, keys);
            var obj2Pick = _.pick(obj2, keys);

            return _.isEqual(obj1Pick, obj2Pick);
        }

    if (!isUsingStaticData) {
            //TODO: any cast to set random properties
            (<any>dataSource).serverPaging = true;
            (<any>dataSource).serverFiltering = true;
            (<any>dataSource).serverSorting = true;
            (<any>dataSource).serverGrouping = true;
            (<any>dataSource).navigatable = true;
        }
        $scope.options = createAmtGridOptions();

        function createAmtGridOptions() {
            var options = {
                dataSource: angular.copy(dataSource),
                filterable: getFilterableOptions(),
                selectable: intSelectable,
                editable: internalEditable,
                columnResize: columnResize,
                columnHide: columnShowHide,
                columnShow: columnShowHide,
                columnReorder: columnReorder,
                dataBinding: dataBinding,
                dataBound: dataBound,
                columnMenu: false,                // set this later if there are optional columns
                columns: [],
                sortable: $scope.sortable ? $scope.sortable === "true" : true,                
                change: function () {
                    var grid = this;
                    $timeout(function () {
                        var selectedRows = grid.select();
                        var selectedDataItems = [];
                        for (var i = 0; i < selectedRows.length; i++) {
                            var dataItem = grid.dataItem(selectedRows[i]);
                            selectedDataItems.push(dataItem);
                        }
                        if ($scope.onSelectedItemChange) {
                            $scope.onSelectedItemChange({ items: selectedDataItems });
                        }
                    });
                },
                edit: function (e) {
                    if (e.container.attr("cell-disabled") === "true") {
                        this.closeCell();
                    }
                },
                cancel: function (e) {
                    this.cancelChanges();
                },
                columnMenuOpen: function (e) {
                    var menu = e.container.children().data("kendoMenu");
                    // check the optional columns for forceHide and remove them from the list
                    for (var i = 0; i < $scope.columns.length; i++) {
                        var column = $scope.columns[i];
                        if (column.forceHide || !column.optional) {
                            menu.element.find(".k-columns-item").find("[data-index='" + column.index + "']").parent().hide();
                        }
                    }
                },
                height: gridHeight,
                scrollable: scrollable,
                navigatable: navigatable,
                noRecords: getNoRecordOptions(),
                messages: { noRecords: $scope.noResultMessage }
            };

            return options;
        }

        function getNoRecordOptions() {
            if ($scope.noResultMessage === undefined) {
                return false;
            }

            return true;
        }

        function getFilterableOptions() {
            if ($scope.filterable !== "true") {
                return false;
            }

            return {
                mode: "menu",
                extra: false,
                messages: {
                    isTrue: xlatSvc.xlat("framework.true_label"),
                    isFalse: xlatSvc.xlat("framework.false_label"),
                    and: xlatSvc.xlat("framework.and_label"),
                    clear: xlatSvc.xlat("framework.clear_label"),
                    filter: xlatSvc.xlat("framework.filter_label"),
                    info: "",
                    or: xlatSvc.xlat("framework.or_label"),
                    selectValue: xlatSvc.xlat("framework.selectValue_label"),
                    cancel: xlatSvc.xlat("framework.cancel_label"),
                    operator: xlatSvc.xlat("framework.operator_label"),
                    value: xlatSvc.xlat("framework.value_label")
                },
                operators: {
                    string: {
                        contains: xlatSvc.xlat("framework.contains_label"),
                        eq: xlatSvc.xlat("framework.eq_label"),
                        startswith: xlatSvc.xlat("framework.startsWith_label"),
                        endswith: xlatSvc.xlat("framework.endsWith_label"),
                    },
                    integer: {
                        eq: xlatSvc.xlat("framework.eq_label"),
                        gte: xlatSvc.xlat("framework.greaterThanOrEqual_label"),
                        gt: xlatSvc.xlat("framework.greaterThan_label"),
                        lte: xlatSvc.xlat("framework.smallerThanOrEqual_label"),
                        lt: xlatSvc.xlat("framework.smallerThan_label")
                    },
                    number: {
                        eq: xlatSvc.xlat("framework.eq_label"),
                        gte: xlatSvc.xlat("framework.greaterThanOrEqual_label"),
                        gt: xlatSvc.xlat("framework.greaterThan_label"),
                        lte: xlatSvc.xlat("framework.smallerThanOrEqual_label"),
                        lt: xlatSvc.xlat("framework.smallerThan_label")
                    },
                    double: {
                        eq: xlatSvc.xlat("framework.eq_label"),
                        gte: xlatSvc.xlat("framework.greaterThanOrEqual_label"),
                        gt: xlatSvc.xlat("framework.greaterThan_label"),
                        lte: xlatSvc.xlat("framework.smallerThanOrEqual_label"),
                        lt: xlatSvc.xlat("framework.smallerThan_label")
                    },
                    date: {
                        eq: xlatSvc.xlat("framework.eq_label"),
                        gte: xlatSvc.xlat("framework.greaterThanOrEqual_label"),
                        gt: xlatSvc.xlat("framework.greaterThan_label"),
                        lte: xlatSvc.xlat("framework.smallerThanOrEqual_label"),
                        lt: xlatSvc.xlat("framework.smallerThan_label")
                    },
                    dropdown: {
                        eq: xlatSvc.xlat("framework.eq_label")
                    }
                }
            };
        }

        this.primaryKey = $scope.primaryKey;

        $scope.columns = [];

        this.showHideGridColumn = function showHideGridColumn(column, hide) {
            // called from watch of "forceHide"

            //Unsure as to why the column properties are being returned as string from Kendo
            column.forceHide = column.forceHide ? column.forceHide.toString().toLowerCase() === "true" : false;
            column.optional = column.optional ? column.optional.toString().toLowerCase() === "true" : false;
            column.hidden = column.hidden ? column.hidden.toString().toLowerCase() === "true" : false;

            //console.log("Column:" + column.title);
            //console.log("       forceHide:" + column.forceHide);
            //console.log("       optional:" + column.optional);
            //console.log("       hidden:" + column.hidden);


            var grid = getGrid();

            if (!grid) {
                return;
            }

            var lsVal = localStorage.getItem(getLocalStorageKey($scope, column.kendoColumnDefinition.index, "hidden"));

            var condA = (column.forceHide ? column.forceHide : false);
            var condB = (column.forceHide === undefined && column.optional && (lsVal === null ? column.hidden : lsVal === "true"));

            var columnHidden = condA || condB;

            if (columnHidden === true) {
                grid.hideColumn(column.kendoColumnDefinition.index);
            }
            else {
                grid.showColumn(column.kendoColumnDefinition.index);
            }
        };

        this.addColumn = function (column, internal) {

            var internalCol = internal || false;
            column.index = $scope.columns.length;

            if (typeof (Storage) !== "undefined") {
                var columnWidth = localStorage.getItem(getLocalStorageKey($scope, column.index, "width"));
                if (columnWidth !== null && columnWidth !== undefined) {
                    if (columnWidth.search("px") === -1) {
                        columnWidth += "px";
                    }
                    column.width = columnWidth;
                }

                var lsVal = localStorage.getItem(getLocalStorageKey($scope, column.index, "hidden"));

                var condA = column.forceHide;
                var condB = ((column.forceHide === undefined || column.forceHide === false) && column.optional && (lsVal === null ? column.hidden : lsVal === "true"));

                var columnHidden = condA || condB;
                if (columnHidden !== null) {
                    column.hidden = columnHidden;
                }
            }

            //Insert after selectcolumn and before command column
            var pos = $scope.options.columns.length;
            /*if (($scope.editable && $scope.editable !== "false" && $scope.editableMode !== "batch") && internalCol === false) {
                pos--;
            }*/

            if (column.optional) {
                // the column menu is on
                $scope.options.columnMenu = {
                    messages: {
                        columns: xlatSvc.xlat("common.columns"),
                        settings: xlatSvc.xlat("common.columnsTooltip"),
                        sortAscending: xlatSvc.xlat("framework.sortAscending"),
                        sortDescending: xlatSvc.xlat("framework.sortDescending"),
                        filter: xlatSvc.xlat("framework.filter_label"),
                    }
                };
                column.menu = true;
            }
            else if (!this.nonOptionalAdded && column.field) {
                //Nat 2021: I think this is about always having a column with a menu users can use to reshow columns they hide, incase they hide them all?
                this.nonOptionalAdded = true;
                column.menu = true;
            }

            //add the column to the grid
            $scope.options.columns.splice(pos, 0, column);
            //$scope.options.editable = false;

            //add the column to the columns array
            $scope.columns.splice(pos, 0, column);

            //set the data type of the field in the datasource
            if (internalCol === false && $scope.options.dataSource.schema) {
                $scope.options.dataSource.schema.model.fields[column.field] = {
                    editable: column.editable === true,
                    type: column.type || 'string'
                };
            }
        };

        this.removeColumn = function (column) {
            $scope.columns = _.without($scope.columns, _.findWhere($scope.columns, { field: column.field }));
            for (var i = 0; i < $scope.columns.length; i++) {
                $scope.columns[i].index = i;
            }
        };

        this.addAggregate = function (aggregate) {
            if ($scope.options.dataSource.aggregate === undefined) {
                $scope.options.dataSource.aggregate = [];
            }
            $scope.options.dataSource.aggregate.push(aggregate);
        };

        this.getDataSource = function () {
            return $scope.datasource;
        };
        this.removeDynamicData = function (field) {
            //remove dynamic columns
            $scope.options.columns = _.filter($scope.options.columns, function (item) {
                return item.field && !item.field.startsWith(field + "[");
            });

            //remove schema of dynamic field
            $scope.options.dataSource.schema.model.fields = _.omit($scope.options.dataSource.schema.model.fields, function (value, key) {
                return key.startsWith(field + '[');
            });

            //remove aggregate of dynamic field
            if ($scope.options.dataSource.aggregate && $scope.options.dataSource.aggregate.length > 0) {
                $scope.options.dataSource.aggregate = _.filter($scope.options.dataSource.aggregate, function (item) {
                    return item.field && !item.field.startsWith(item.field, field + "[");
                });
            }
        };

        this.refresh = function () {
            $scope.internalControl.refresh();
        };

        this.resize = function () {
            $scope.internalControl.resize();
        };

        this.addContextMenu = function (menu) {
            $scope.contextMenus.push(menu);
        };

        $scope.customCommandClick = function (delegateFunction) {
            $scope.$parent.$evalAsync(delegateFunction);
        };

        $scope.create = function () {
            $scope.gridElement.data("kendoGrid").addRow();
        };

        //Add select column
        if ($scope.tickable) {
            this.addColumn({
                optional: false,
                width: "40px",
                template: "<input type='checkbox' ng-click='toggleSelect(dataItem)' ng-model='dataItem.ticked' />",
                title: "<input id='select-all' type='checkbox' ng-model='isSelectAll' />",
                attributes: {
                    "class": "table-cell",
                    style: "text-align: center;"
                },
                headerAttributes: {
                    "class": "table-cell",
                    style: "text-align: center;"
                },
                id: "select"
            }, true);
        }

        $scope.$watch('datasource', function (newValue, oldValue) {
            if (newValue && newValue.length > 0) {
                $rootScope.$broadcast("datasourceReady");
                if (!isFirstBindDataSource) {
                    setFormDirty($scope.form);
                }
                isFirstBindDataSource = false;
            }
        }, true);

        var filterValuesChanged = false;
        $scope.$watch('filterValues', function (newValue, oldValue) {
            filterValuesChanged = true;
        }, true);

        $scope.rebind = false;

        $scope.internalControl.rebind = function () {
            $timeout(function () {
                $scope.rebind = !$scope.rebind;
            });
        };


        /* this applies filters to the grid to display a portion of the current client side data.
        example filter :
        {
            field: "applicationName",
            operator: "contains",
            value: vm.filter
        };

        */
        $scope.internalControl.applyFilters = function (filters) {
            var grid = getGrid();
            if (grid) {
                if (!filters) {
                    filters = {};
                }
                grid.dataSource.filter(filters);
            }
        };

        $scope.internalControl.resize = function () {
            var grid = getGrid();
            if (!grid || !grid.dataSource) {
                return false;
            }
            // the grid sometime resizes due to the browser window resizing is when logout due to inactivity 
            // so it ends up deeper down causing issues so let it finish the original event first
            $timeout(function () {
                grid.refresh();
            });
            return true;
        };

        $scope.internalControl.refresh = function () {

            var grid = getGrid();
            if (!grid || !grid.dataSource) {
                return false;
            }
            grid.setOptions({
                noRecords: getNoRecordOptions(),
                messages: { noRecords: $scope.noResultMessage },
            });
            grid.dataSource.read();

            return true;
        };

        $scope.internalControl.recompile = function () {
            setTimeout(function () {

                var grid = getGrid();
                if (!grid) {
                    return;
                }

                grid.setOptions({
                    columns: $scope.columns
                });

                grid.columns = $scope.columns;

                //TODO: random any casts to hide accessing random properties
                (<any>grid)._columns($scope.columns);
                (<any>grid)._templates();
                grid.thead.empty();
                (<any>grid)._thead();
                (<any>grid)._renderContent(grid.dataSource.view());

                $scope.addTooltips($scope.intShowTooltips);

            }, 300);

            setTimeout(function () {
                $scope.internalControl.refresh();
            }, 300);
        };

        $scope.internalControl.getTickedItems = function () {
            var tickedItems = [];
            var gridControl = getGrid();
            if (gridControl) {
                var items = (<any>gridControl)._data; //TODO: any cast possibly hides bug
                if (!items) {
                    return tickedItems;
                }

                items.forEach(function (item) {
                    if (item.ticked) {
                        tickedItems.push(item);
                    }
                });
            }
            return tickedItems;
        };

        $scope.internalControl.getColumns = function () {
            var cols = [];
            _.each($scope.options.columns, function (column) {
                if (!(column.internal)) {
                    cols.push(column);
                }
            });
            return cols;
        };
        $scope.internalControl.getAllItems = function () {
            var grid = getGrid();
            if (!grid || !grid.dataSource) {
                return [];
            }

            var items = [];

            for (var i = 0; i < grid.dataSource.total(); i++) {
                items.push((<any>grid.dataSource)._data[i]); //TODO: possibly buggy any cast 
            }

            return items;
        };

        $scope.internalControl.getTotalItemsCount = function () {
            var grid = getGrid();
            if (!grid || !grid.dataSource) {
                return;
            }
            return grid.dataSource.total();

        };

        $scope.internalControl.select = function (arg) {
            var grid = getGrid();
            if (!grid) {
                return;
            }
            return grid.select(arg);

        };

        $scope.internalControl.getSelectedItems = function () {
            var grid = getGrid();
            var rows = grid && grid.select && grid.select();
            var result = [];
            if (rows && rows.length > 0) {
                rows.each(function (index, row) {
                    result.push(grid.dataItem(row));
                });
            }
            return result;
        };

        //Adding these so we can set the page correctly when rebinding the grid
        $scope.internalControl.getPage = function () {
            var grid = getGrid();
            if (!grid) {
                return;
            }
            return grid.pager.page();
        };

        $scope.internalControl.setPage = function (page) {
            var grid = getGrid();
            if (!grid) {
                return;
            }
            grid.pager.page(page);

        };

        /*$scope.gridScrolled = function () {
            var grid = getGrid();
            var content = grid.content;

            var contentTop = content.offset().top;
            var contentScroll = content.scrollTop();

            var dataItems = grid.dataItems("tr");

            for (var i = 0; i < dataItems.length; i++) {
                var item = dataItems[i];
                var row = grid.tbody.find(">tr:not(.k-grouping-row)").eq(i);

                var rowTop = row.offset().top - contentTop;
                var rowHeight = row.height();
                var rowBottom = rowTop + rowHeight;

                if (rowTop !== -1 && rowBottom > 0) {
                    // found the first visible row
                    $scope.onTopItemChange({ item: item });
                    break;
                }
            }
        };

        $scope.$watch('onTopItemChange', function (newValue) {
            if (newValue) {
                var grid = getGrid();
                if (grid && grid.content) {
                    grid.content.scroll($scope.gridScrolled);
                }
            }
        });*/

        $scope.internalControl.scrollToRowById = function (id) {

            //http://jsfiddle.net/pfox72au/ageaqtxu/2/

            var grid = getGrid();
            var content = grid.content;

            //TODO: kendo bug(?) https://feedback.telerik.com/kendo-jquery-ui/1399331-kendo-all-d-ts-is-missing-dataitems-function-for-grid
            var dataItems = (<any>grid).dataItems("tr");

            for (var i = 0; i < dataItems.length; i++) {
                var item = dataItems[i];
                if (item[$scope.primaryKey] === id) {
                    var row = grid.tbody.find(">tr:not(.k-grouping-row)").eq(i);
                    //find the row element

                    var contentTop = content.offset().top;
                    var contentScroll = content.scrollTop();
                    //var contentHeight = content.height();

                    var rowTop = row.offset().top - contentTop;
                    //var rowHeight = row.height();
                    //var rowBottom = rowTop + rowHeight;

                    content.scrollTop(rowTop + contentScroll);

                    // exit for loop
                    break;
                }
            }

        };

        $scope.internalControl.selectRowById = function (id) {
            var grid = getGrid();

            if (grid) {
                //TODO: kendo bug(?) https://feedback.telerik.com/kendo-jquery-ui/1399331-kendo-all-d-ts-is-missing-dataitems-function-for-grid
                var dataItems = (<any>grid).dataItems("tr");
                var rowIndex = 0;
                for (var i = 0; i < dataItems.length; i++) {
                    var item = dataItems[i];
                    if (item[$scope.primaryKey] === id) {
                        rowIndex = i;
                        break;
                    }
                }
                var row = grid.tbody.find(">tr:not(.k-grouping-row)").eq(rowIndex);
                grid.select(row);
            }
        };
        $scope.internalControl.changeTickedSelectAll = function (value) {
            $scope.isSelectAll = value;
        };

        $scope.internalControl.getUpdatedItems = function (value) {
            var grid = getGrid();
            if (!grid) {
                return 0;
            }
            return _.filter(grid.dataSource.data(), function (item) {
                return item.dirty === true || item.isNew === true || item.isNew() === true;
            });
        };
        $scope.internalControl.updatedItems = $scope.internalControl.getUpdatedItems();

        $scope.internalControl.changeTitle = function (field, newTitle) {
            $(getGridSelector()).find('#grid th[data-field=' + field + ']').contents().last().replaceWith(newTitle);
        };


        $scope.model = {};
        $scope.isSelectAll = false;

        $scope.toggleSelect = function (dataItem) {
            //Ticked Items
            let checkboxElement = <HTMLInputElement>document.getElementById('select-all');
            let tickedItems = $scope.internalControl.getTickedItems();
            let items = ((<any>getGrid())._data);
            let hasItem: boolean = items && items.length > 0;
            let hasTicked: boolean = tickedItems && tickedItems.length > 0;
            let isSelectAll: boolean = hasItem && hasTicked ? tickedItems.length === items.length : false;

            //Selected All Control
            $scope.internalControl.tickedItems = tickedItems;
            $scope.internalControl.isSelected = hasTicked ? true : false;
            $scope.isSelectAll = isSelectAll;
            checkboxElement.checked = isSelectAll;

            if ($scope.onTickChange) {
                $scope.onTickChange({ items: tickedItems });
            }
    };

    

        function getLocalStorageKey(scope, index, property) {
            if (amtGridConfig.localStorageIsDisabled()) {
                return new Date().getTime().toString(); // returns an invalid storage key, so no values are found.
            }
            if (scope.localStorageKey === undefined) {
                return (scope.id + index + property);
            }
            else {
                return (scope.localStorageKey + index + property);
            }

        }

        function columnResize() {
            storeColumProperties("width");
        }

        function columnShowHide() {
            storeColumProperties("hidden");
        }

        function columnReorder() {
            storeColumProperties("index");
        }

        function dataBinding(e) {
            //console.log("grid binding");
        }

        function dataBound(e) {
            //Save Page Size
            $timeout(function () {
                savePageSize();
            });


            if ($scope.onDatabound) {
                $scope.onDatabound({ event: e });
            }

            //enter key
            var grid = getGrid();
            if (grid && grid.content) {
                grid.content.on("focus", "td", function () {
                    $("input").on("keydown", function (event) {

                        if (event.keyCode === 13) {
                            event.preventDefault();
                        }
                    });


                });
            }

            //De-select row
            if (grid && grid.tbody) {
                grid.tbody.on("click", "tr", function (e) {
                    var rowElement = this;
                    var $row = $(rowElement);

                    if ($row.hasClass("k-state-selected")) {
                        $row.removeClass("k-state-selected");

                        if ($scope.onSelectedItemChange) {
                            $scope.onSelectedItemChange({ items: [] });
                        }

                        e.stopPropagation();
                    }
                });
            }

            //Call resize to recalc scrollbar
            kendo.resize($(getGridSelector()), false);
        }

        function savePageSize() {

            var grid = getGrid();

            //TODO: naughty any cast
            if (!grid || !grid.dataSource || !(<any>grid).dataSource._take) {
                return;
            }

            if (amtGridConfig.localStorageIsDisabled()) {
                return;
            }

            //TODO: naughty any cast
            localStorage.setItem(getLocalStorageKey($scope, '', "pageSize"), (<any>grid).dataSource._take);
        }

        function getPageSize() {

            var pageSize = ($scope.filterValues !== undefined && $scope.filterValues.pageSize !== undefined ? $scope.filterValues.pageSize : appConfig.defaultPageSize);

            if (typeof (Storage) !== "undefined") {
                var setting = parseInt(localStorage.getItem(getLocalStorageKey($scope, '', "pageSize")));
                if (!isNaN(setting)) {
                    pageSize = setting;
                }
            }

            if ($scope.pageable === "false") {
                pageSize = appConfig.int_max;
            }

            return pageSize;
        }

        function getGridSelector() {
            return ['amt-grid[id=', $scope.id, ']'].join('');
        }

        function getGrid() {
            return ($(getGridSelector()).find('#grid').data("kendoGrid"));
        }

        function getAMTGrid() {
            var selector = ['amt-grid[id=', $scope.id, ']'].join('');
            return $(selector);
        }

        function storeColumProperties(propertyName) {
            if (typeof (Storage) !== "undefined") {
                _.each(getGrid().columns, function (col: any) {
                    var propertyValue = col[propertyName];
                    if (propertyValue !== undefined) {
                        if (amtGridConfig.localStorageIsDisabled()) {
                            return;
                        }
                        localStorage.setItem(getLocalStorageKey($scope, col.index, propertyName), propertyValue);
                    }
                });
            }

        }

        function replaceParam(pattern, params) {
            if (pattern === undefined) {
                return pattern;
            }
            for (var paramName in params) {
                var paramValue = params[paramName];
                pattern = pattern.replace("[[" + paramName + "]]", encodeURIComponent(paramValue));
            }
            return pattern;
        }

        var notificationItem = {};

        function setNotificationEntity(action, recordName) {
            notificationItem[action] = recordName;
        }

        function handleNotification(action, response) {
            var recordName = notificationItem[action];
            if (response.status === httpDataResult.fail) {
                $scope.notification.show(response.errors[0].message, "error");
            } else if (action) {
                var message = action + " successfully";
                if ($scope.primaryName && intDataTypeName) {
                    message = String.format("{0} '{1}' {2} {3}", action, recordName, intDataTypeName.toLowerCase(), "successfully"); //TODO: translate this?
                }
                $scope.notification.show(message, "success"); //TODO: translate this?
            }
        }

        function getColumnWidth(columnCommand) {
            var width = "200px";
            if (columnCommand.length === 1) {
                if (columnCommand[0].name === 'remove') {
                    width = "100px";
                }
            }
            return width;
        }

        var httpDataResult = {
            fail: "fail",
            success: "success"
        };

        $scope.onLinkClick = function (dataItem) {
            if ($scope.onLinkColumnClick) {
                $scope.onLinkColumnClick({ item: dataItem });
            }
        };

        function setFormDirty(form) {
            if (form) {
                form.$setDirty();
            }
        }

        $scope.onContextMenuInternalClick = function (menu) {
            var grid = getGrid();
            var selectedCell = grid.element.find('table td[selected]');
            var selectedModelCell = selectedCell.attr("model") && JSON.parse(decodeURIComponent(selectedCell.attr("model")));

            var selectedHeader = $(selectedCell.closest('#grid')).find('.k-grid-header th').eq(selectedCell.index());

            var selectedRow = selectedCell.closest('tr');
            var dataItem = grid.dataItem(selectedRow);

            menu.onClick({ dataItem: { dataRow: dataItem, dataCell: selectedModelCell } });
        };

        $scope.onSelect = function (kendoEvent) {
            var selectedMenuItem = _.find($scope.contextMenus, function (item) {
                return item.name === $(kendoEvent.item).attr('name');
            });
            if (selectedMenuItem) {
                var cellModel = getSelectedCellModel(kendoEvent.target);
                var rowModel = getSelectedRowModel(kendoEvent.target);
                selectedMenuItem.onClick({ dataItem: { dataRow: rowModel, dataCell: cellModel } });
            }
        };
        $scope.onOpen = function (kendoEvent) {
            //TODO: implement function to handle disable menu item

            var cellModel = getSelectedCellModel(kendoEvent.target);
            var rowModel = getSelectedRowModel(kendoEvent.target);

            $scope.onOpenContextMenu({ dataItem: { dataRow: rowModel, dataCell: cellModel } });

        };
        $scope.internalControl.enableContextMenu = function (name) {
            var contextMenu = getContextMenu();
            contextMenu.enable('li[name=' + name + ']', true);
        };
        $scope.internalControl.disableContextMenu = function (name) {
            var contextMenu = getContextMenu();
            contextMenu.enable('li[name=' + name + ']', false);
        };

        function getContextMenu() {

            return (<any>$('ul[kendo-context-menu]')).kendoMenu().data("kendoMenu");
        }

        function getSelectedCellModel(target) {
            return $(target).attr("model") && JSON.parse(decodeURIComponent($(target).attr("model")));
        }

        function getSelectedRowModel(cellTarget) {
            var grid = getGrid();
            var selectedRow = $(cellTarget).closest('tr');
            return grid.dataItem(selectedRow);
        }

        $scope.addTooltips = function (showTooltips) {
            if (showTooltips === true) {
                var grid = getGrid();
                if (grid) {
                    grid.thead.kendoTooltip({
                        filter: "th",
                        content: function (e) {
                            var target = e.target; // element for which the tooltip is shown
                            return $(target).text();
                        }
                    });
                } else {
                    console.error("Failed to add tool tips to grid:" + $scope.id);
                }
            }
        };

        function bracketsHack(val: string) {
            //TODO: Janky hack - replaced curly braces with round ones so they aren't removed in translation (kendo format doesn't seem to support escaping sadly) we should fix this
            return val && val.replace(/\(/g, '{').replace(/\)/g, '}');
        }

        $scope.callOnParentScope = function (functionName, dataItem, params) {
            $scope.$parent[functionName](dataItem, params);
        };

        $scope.intAutoBind = $scope.autoBind === undefined ? true : $scope.$eval($scope.autoBind);
        $scope.intPageable = $scope.pageable === undefined ? true : $scope.$eval($scope.pageable);
        $scope.intshowRefresh = $scope.showRefresh === undefined ? false : $scope.$eval($scope.showRefresh);        
        $scope.pageDefinition = ($scope.intPageable === true ? {
            refresh: $scope.intshowRefresh,
            alwaysVisible: true,
            pageSizes: [1, 10, 20, 50, 100, 200],
            messages: {
                display: bracketsHack(xlatSvc.xlat('framework.gridPagerDisplay')),
                empty: xlatSvc.xlat('framework.gridPagerEmpty'),
                page: xlatSvc.xlat('framework.gridPagerPage'),
                allPages: xlatSvc.xlat('framework.gridPagerAllPages'),
                of: bracketsHack(xlatSvc.xlat('framework.gridPagerOf')),
                itemsPerPage: xlatSvc.xlat('framework.gridPagerItemsPerPage'),
                first: xlatSvc.xlat('framework.gridPagerFirst'),
                previous: xlatSvc.xlat('framework.gridPagerPrevious'),
                next: xlatSvc.xlat('framework.gridPagerNext'),
                last: xlatSvc.xlat('framework.gridPagerLast'),
                refresh: xlatSvc.xlat('framework.gridPagerRefresh'),
            }
        } : false);
        $scope.intSortable = $scope.sortable === undefined ? false : $scope.$eval($scope.sortable);
        $scope.intGroupable = $scope.groupable === undefined ? false : $scope.$eval($scope.groupable);
        $scope.intResizable = $scope.resizable === undefined ? true : $scope.$eval($scope.resizable);
        $scope.intReOrderable = $scope.reorderable === undefined ? true : $scope.$eval($scope.reorderable);
        $scope.intShowTooltips = $scope.showTooltips === undefined ? true : $scope.$eval($scope.showTooltips);
        $scope.options.resizable = $scope.intResizable;
        $scope.options.reorderable = $scope.intReOrderable;
        $scope.options.pageable = $scope.pageDefinition;
        $scope.options.autoBind = $scope.intAutoBind;
        $scope.options.sortable = $scope.intSortable;
        $scope.options.groupable = $scope.intGroupable;
    $scope.options.columnMenu = false;

    setTimeout(function () {
        if ($scope.tickable) {

            //Direct Listener to click event;
            const selectAllElement = document.getElementById('select-all');
            if (selectAllElement) {
                selectAllElement.addEventListener('click', () => {
                    var items = ((<any>getGrid())._data);
                    $scope.isSelectAll = $scope.isSelectAll ? false : true;

                    if ($scope.isSelectAll) {
                        for (let i = 0; i < items.length; i++){
                            let item = items[i];
                            item.ticked = true;
                            (<any>getGrid())._data[i] = item;
                            $scope.toggleSelect();
                        }
                    } else {
                        for (let i = 0; i < items.length; i++) {
                            let item = items[i];
                            item.ticked = false;
                            (<any>getGrid())._data[i] = item;
                            $scope.toggleSelect();
                        }
                    }
                });
            }

            //Monitoring the $scope.isSelectAll has changed.
            $scope.$watch('isSelectAll', function (newVal, oldVal) {
                const checkboxElement = <HTMLInputElement>document.getElementById('select-all');
                checkboxElement.checked = newVal !== oldVal ? newVal : oldVal;
            });
        }
    }, 500); // Delay for 1 second (1000 milliseconds)

}

function amtGrid(xlatSvc, appConfig, $timeout, $rootScope) {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                id: '@',
                readUrl: '@',
                updateUrl: '@',
                deleteUrl: '@',
                createUrl: '@',
                filterValues: '=',
                criteria: '=',
                primaryKey: '@',
                primaryName: '@',
                datatypeName: '@',
                control: '=',
                editable: '@',
                deletable: '@',
                //editableMode: "@",
                inlineCreate: '@',
                addButtonLabel: '@',
                sortable: '@',
                groupable: '@',
                filterable: '@',
                resizable: '@',
                reorderable: '@',
                pageable: '@',
                tickable: '@',
                selectable: '@',
                autoBind: '@',
                datasource: '=?',
                form: '=?',
                onRead: '&',
                resultCount: "=?",
                onDatabound: '&',
                onSelectedItemChange: '&',
                onSave: '&',
                onDelete: '&',
                onLinkColumnClick: '&',
                visibleEditButton: '@',
                onOpenContextMenu: "&",
                showTooltips: '@',
                showRefresh: '@',
                noResultMessage: '@',
                localStorageKey: '@',
                rowParseFunction: '&?',
                hideColumnMenu: '@',
                gridHeight: '@',
                scrollable: '@',
                navigatable: '@',
                //onTopItemChange: "&",
                onTickChange: "&"
            },
            template: tmpl,
            controller: amtGridController,
            link: function (scope, element, attrs) {

                scope.gridElement = $(element).find("#grid");
                $timeout(function () {
                    scope.addTooltips(scope.intShowTooltips);
                });
            }

        };
}
