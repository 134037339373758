//import angular from 'angular';
import BrowserSvc from '../../../services/browserSvc';
import tmpl from "./stocktakeComments.html";



class stocktakeCommentsCtrl {

        wnd: any = null;
        buttons: any = null;
        buttonStates: any = null;
        buttonMethods: any = null;

        api: any = {};
        comments: TStocktakeComment[];

        form: any;
        isMobile: boolean = this.browserSvc.isMobile;

        commentGrid: any = {};

        static $inject = ["$scope", "$state", "dataBroker", "enums", "amtXlatSvc",
            "$timeout", "WindowFactory", "confirmSvc", "errorReporter", "browserSvc"];

        constructor(
            private $scope: ng.IScope, private $state: any, private dataBroker: any, private enums: any,
            private amtXlatSvc: any, private $timeout: any, private WindowFactory: any, private confirmSvc: any,
            private errorReporter: any, private browserSvc: BrowserSvc) {

            $scope["vm"] = this;

            try {
                this.dataBroker.checkRefData();
            } catch {
                this.$state.go("mobile.landing");
            }
        };

        public async $onInit() {

            if (this.isMobile) {
                this.wnd.fullScreen();
            }

            this.api = this.wnd.initParams.api;
            this.comments = this.wnd.initParams.comments;

            this.$scope['deleteComment'] = this.deleteComment;
            this.$scope['modifyComment'] = this.modifyComment;

            this.buttons = [
                {
                    primary: false,
                    cancel: true,
                    value: 'common.cancel_label',
                    name: 'cancelButton',
                    click: 'cancel',
                    type: this.enums.buttonTypes.button
                }
            ];

            this.buttonStates = {
                cancelButton: {
                    visible: true,
                    cancel: false
                }
            };

            this.buttonMethods = {
                cancel: this.closeWindow
            };

            this.$timeout(() => {
                this.refreshGrid();
            });
        };

        public closeWindow = () => {
            this.WindowFactory.closeWindow(this.wnd);
        };

        public modifyComment = (comment: TStocktakeComment) => {
            this.WindowFactory.openItem({
                component: 'stocktake-comment-popup',
                caption: this.amtXlatSvc.xlat('stocktake.editComment'),
                width: 500,
                modal: true,
                initParams: {
                    comment: comment
                },
                onDataChangeHandler: async (comment: TStocktakeComment) => {

                    if (this.api && this.api.modifyComment) {

                        try {
                            await this.api.modifyComment(comment);

                            let gridComment = this.comments.findIndex(c => c.id === comment.id);

                            if (gridComment > -1) {
                                this.comments[gridComment] = comment;

                                this.refreshGrid();
                            }

                        } catch (error) {
                            throw error;
                        }
                    }
                },
                canClose: false
            });
        };

        public deleteComment = async (comment: TStocktakeComment) => {

            try {
                await this.confirmSvc.confirmMessage("stocktake.confirmDeleteComment_title", "stocktake.confirmDeleteComment");
            } catch (e) {
                return; // they cancelled the cancel
            }

            if (this.api && this.api.deleteComment) {

                this.wnd.processing = true;

                try {
                    await this.api.deleteComment(comment);

                    let matchedComment = this.comments.find(c => c.id === comment.id);

                    if (matchedComment) {
                        this.comments.removeItem(matchedComment);
                    }

                    this.refreshGrid();

                } catch (error) {
                    this.errorReporter.logError(error);
                }

                this.wnd.processing = false;
            }
        };

        public refreshGrid() {
            if (this.commentGrid && this.commentGrid.refresh) {
                this.commentGrid.refresh();
            }
        };
    }

    angular.module("app.stocktake")
        .controller("stocktakeCommentsCtrl", stocktakeCommentsCtrl)
        .component("stocktakeComments", {
            bindings: {
                initParams: "=",
                buttonMethods: "=",
                buttonStates: "=",
                buttons: "=",
                closeOnSave: "=",
                wnd: "="
            },
            template: tmpl,
            controllerAs: 'vm',
            controller: 'stocktakeCommentsCtrl'
        });
