/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var kendo_angular_dropdowns_1 = require("@progress/kendo-angular-dropdowns");
var core_1 = require("@angular/core");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
/**
 * @hidden
 */
var PagerDropDownListDirective = /** @class */ (function () {
    function PagerDropDownListDirective(host) {
        var _this = this;
        this.host = host;
        this.keydownHandler = function (e) {
            if (e.keyCode === kendo_angular_common_1.Keys.Escape && _this.host.isOpen) {
                e.stopPropagation();
                _this.host.toggle(false);
            }
        };
    }
    PagerDropDownListDirective.prototype.ngAfterViewInit = function () {
        var wrapperElement = this.host.wrapper.nativeElement;
        wrapperElement.addEventListener('keydown', this.keydownHandler, true);
    };
    PagerDropDownListDirective.prototype.ngOnDestroy = function () {
        this.host.wrapper.nativeElement.removeEventListener('keydown', this.keydownHandler);
    };
    PagerDropDownListDirective = tslib_1.__decorate([
        core_1.Directive({ selector: '[kendoGridPagerDropDown]' }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_dropdowns_1.DropDownListComponent])
    ], PagerDropDownListDirective);
    return PagerDropDownListDirective;
}());
exports.PagerDropDownListDirective = PagerDropDownListDirective;
