//import angular from 'angular';
import tmpl from './vehicleStats.html';



    angular.module('app.directives').component('vehicleStats', {
        template: tmpl,
        bindings: {
            vehicle: '<',
            checkinData: '<',
            save: '&'
        },
        controller: ["dataBroker", "errorReporter", "amtXlatSvc", vehicleStatsCtrl]
    });

    function vehicleStatsCtrl(dataBroker, errorReporter, amtXlatSvc) {

        var ctrl = this;

        this.notApplicableText = amtXlatSvc.xlat('common.notAvailable');

        ctrl.vehicleSpeed = this.notApplicableText;
        ctrl.loading = false;

        this.$onInit = function () {
            ctrl.fleetSpeed = null;
            ctrl.fleetSpeedUnit = null;
            ctrl.loading = true;

            dataBroker.getFleetSpeed(ctrl.vehicle.id).then(function (response) {
                if (!response) {
                    return;
                }
                ctrl.loading = false;

                if (response.fleetSpeed) {
                    ctrl.fleetSpeed = {
                        value: response.fleetSpeed,
                        unit: response.fleetSpeedUnit.unitAbbreviation,
                        unitType: response.fleetSpeedUnit.baseReadingUnit
                    };
                }

            }).catch(function (error) {
                ctrl.loading = false;
                errorReporter.logError(error);
            });
        }

        var lastHours = null;
        var lastDistance = null;
        var lastHoursMeterFlag = null;
        var lastDistanceMeterFlag = null;

        this.$doCheck = function () {
            if (ctrl.checkinData.hours === lastHours &&
                ctrl.checkinData.distance === lastDistance &&
                ctrl.checkinData.hoursNewMeter === lastHoursMeterFlag &&
                ctrl.checkinData.distanceNewMeter === lastDistanceMeterFlag) {
                return;
            }

            ctrl.checkinData.averageSpeed = undefined;

            lastHours = ctrl.checkinData.hours;
            lastDistance = ctrl.checkinData.distance;

            lastHoursMeterFlag = ctrl.checkinData.hoursNewMeter;
            lastDistanceMeterFlag = ctrl.checkinData.distanceNewMeter;

            ctrl.vehicleSpeed = this.notApplicableText;

            if (!ctrl.checkinData.hoursNewMeter && !ctrl.checkinData.distanceNewMeter && ctrl.checkinData.hours && ctrl.checkinData.distance &&
                ctrl.vehicle.priorReadings && ctrl.vehicle.priorReadings.HOURS != null && ctrl.vehicle.priorReadings.DISTANCE != null) {
                let hoursDelta = ctrl.checkinData.hours - ctrl.vehicle.priorReadings.HOURS.value;
                let distanceDelta = ctrl.checkinData.distance - ctrl.vehicle.priorReadings.DISTANCE.value;
                if (hoursDelta !== 0) {
                    ctrl.checkinData.averageSpeed = (distanceDelta / hoursDelta).toFixed(1);
                    ctrl.vehicleSpeed = ctrl.checkinData.averageSpeed + " " + ctrl.vehicle.distanceUnit.unitAbbreviation + "/h";
                }
            }
        }
    }

