//import angular from 'angular';
import tmpl from './search.html';
import SysMaintActionSvc from './sysMaintActionSvc';

interface IVisualInspectionActionSearchScope extends ng.IScope {
    editVisualInspectionAction: (item) => void;
}

class VisualInspectionActionSearchCtrl implements ng.IComponentController {

    processing: boolean;

    gridControl: IGridControl = {};
    actionFilterControl: any;

    selectedItem: any;
    gridTop: number;

    defaultActiveStates = this.sysMaintActionSvc.activeStates.filter(s => s.default);

    criteria: IGridFilterCriteria = {
        filterValues: {
            activeOptions: [],
            ids: []
        }
    };

    static $inject = ['$scope', 'sysMaintActionSvc', 'ocConfigSvc'];

    constructor(private $scope: IVisualInspectionActionSearchScope, private sysMaintActionSvc: SysMaintActionSvc, private ocConfigSvc: IOcConfigSvc) {

        this.$scope.editVisualInspectionAction = (item) => this.editItem(item);
    }

    onSelectedItemChanged(items) {
        this.selectedItem = items?.length ? items[0] : null;
    }

    setHeaderHeight(height: number, offset: number) {
        this.gridTop = height + offset;
    }

    export() {
        this.sysMaintActionSvc.export(this.criteria);
    }

    filter() {
        this.gridControl?.refresh();
    }

    editItem(item) {
        this.sysMaintActionSvc.openItem(item, () => {
            this.filter();
            this.actionFilterControl?.search();
        });
    };

    addItem() {
        this.sysMaintActionSvc.addItem(() => {
            this.filter();
            this.actionFilterControl?.search();
        });
    }
}

angular.module('app.admin').component('sysMaintActionSearchCtrl', {
    template: tmpl,
    controllerAs: 'vm',
    controller: VisualInspectionActionSearchCtrl
});