//Filter to ensure that resx entries are not stripped of HTML
//import angular from 'angular';


    angular.module('app').filter('trustAsHtml', trustAsHtmlFilter);

    // @ts-ignore
    trustAsHtmlFilter.$inject = ['$filter','$sce'];
    function trustAsHtmlFilter($filter, $sce) {
        return function (text) {
            return $sce.trustAsHtml(text);
        };
    }
