import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Widget } from '../../service/Interface/SiteDashboard.interface';
import { ScrapLifeTrendWidget, WidgetScrapLifeTrendChartVisibleControl } from '../../service/Interface/Widget.interface';
import { ScrapLifeTrendUi } from '../../service/Interface/WidgetConstant.interface';
import { WidgetRxjsService } from '../../service/rxjs/WidgetRxjs.service';
import { WidgetConstant } from '../../service/widgetHelper/WidgetConstant';
import { Subscription } from 'rxjs';
import { LegendItemClickEvent, LineStyle } from '@progress/kendo-angular-charts';
import { ScrapLifeTrendWidgetHelper } from '../../service/widgetHelper/ScrapLifeTrendWidget.helper';

@Component({
    selector: 'widget-scrap-life-trend',
    templateUrl: './scrap-life-trend.component.html',
    styleUrls: ['./../../site-dashboard.component.scss']
})

export class SiteDashboardScrapLifeTrendComponent implements OnInit, OnDestroy {
    public siteDashboardWidgetId: string;
    public resultSubscription: Subscription;
    public targetLineStyle: LineStyle = "normal";
    public ui: ScrapLifeTrendUi;
    public axisLabels = { format: "#,###" };
    public showChartLegend: boolean = true;
    public labels: string[] = [];
    public crossingValues: Array<number> = [0, 100000];
    public result: ScrapLifeTrendWidget = {
        siteDashboardWidgetId: '',
        isAuth: false,
        receivedResult: false,
        apiResult: {
            startDate: null,
            endDate: null,
            displayPeriodMonth: 0,
            isAuth: false,
            chart: [],
            include: {
                new: false,
                majorRepair: false,
                transfer: false,
                cosmeticRepair: false,
                secondHand: false,
                retread: false,
                targetHour: false,
                previousYearAvgHours: false
            },
            chartMax: {
                qty: 0,
                hours: 0
            },
            chartColor: {
                targetHours: "",
                new: "",
                majorRepair: "",
                transfer: "",
                cosmeticRepair: "",
                retread: "",
                secondHand: "",
                previousYearAvgHours: ""
            }
        },
    };
    public visible: WidgetScrapLifeTrendChartVisibleControl = {
        new: true,
        majorRepair: true,
        transfer: true,
        cosmeticRepair: true,
        retread: true,
        secondHand: true,
        targetHour: true,
        previousYearAvgHours: true
    };

    constructor(private widgetRxjs: WidgetRxjsService, private widgetConstant: WidgetConstant, private scrapLifeTrendWidget: ScrapLifeTrendWidgetHelper) {
        this.ui = this.widgetConstant.getScrapLifeTrendWidgetWidgetUi();
    }

    @Input() set getWidgetDetail(widget: Widget) {
        this.siteDashboardWidgetId = widget.siteDashboardWidgetId;

    }
    ngOnInit() {
        this.getScrapLifeTrendResult();
    }

    ngOnDestroy() {
        this.resultSubscription.unsubscribe();
    }

    private getScrapLifeTrendResult() {
        this.resultSubscription = this.widgetRxjs.scrapLifeTrendWidget.subscribe(
            widgets => {
                let hasScrapLifeTrend = widgets.length > 0;

                if (hasScrapLifeTrend) {
                    widgets.forEach(widget => {
                        if (widget.siteDashboardWidgetId === this.siteDashboardWidgetId) {
                            this.result = widget;
                            this.labels = this.result.apiResult.chart.map(c => c.label);
                            this.replaceZeroValueToHideNumber();
                        }
                    });
                }
            });
    }

    public formatHours(args: any): string {
        let num: number = args.value;
        let isZero: boolean = num === 0;
        let stringValue: string = num.toString();
        let separator: number = stringValue.length > 4 ? 2 : 1;
        let stringValueSlice: string = stringValue.slice(0, separator);

        return isZero ? "0" : stringValueSlice + "K";
    }

    /**This method is to hide the zero value above the chart so that the zero value cannot display on the chart */
    public replaceZeroValueToHideNumber() {
        //The hide number set to over the maximum hours so that is not show on the chart.
        let hideNumber = this.result.apiResult.chartMax.hours * 1.5;
        this.result.apiResult.chart.map(r => {
            r.cosmeticRepair.avgHours = r.cosmeticRepair.avgHours > 0 ? r.cosmeticRepair.avgHours : hideNumber;
            r.majorRepair.avgHours = r.majorRepair.avgHours > 0 ? r.majorRepair.avgHours : hideNumber;
            r.new.avgHours = r.new.avgHours > 0 ? r.new.avgHours : hideNumber;
            r.transfer.avgHours = r.transfer.avgHours > 0 ? r.transfer.avgHours : hideNumber;
            r.secondHand.avgHours = r.secondHand.avgHours > 0 ? r.secondHand.avgHours : hideNumber;
            r.total.avgHours = r.total.avgHours > 0 ? r.total.avgHours : hideNumber;
            r.retread.avgHours = r.retread.avgHours > 0 ? r.retread.avgHours : hideNumber;
        });
    }

    public onLegendItemClick(e: LegendItemClickEvent) {
        this.visible = this.scrapLifeTrendWidget.handleLegendClickEvent(e, this.visible, this.ui);
    } 

}