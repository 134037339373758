//import angular from 'angular';

    angular.module('app.directives')
        .directive('ocUrlVal', ['$timeout', function ($timeout) {
            return {
                restrict: 'E',
                scope: {
                    url : '@',
                    path: '@',
                    updateTrigger: '@', // this can be used to trigger an update, but must be in scope
                    updateOnBroadcastFrom: '@?'
                },
                controller: ["$scope", "amtCommandQuerySvc", "errorReporter", "$rootScope",
                    function ($scope, amtCommandQuerySvc, errorReporter, $rootScope) {

                    $scope.value = "";

                    var doUpdate = function () {
                        return amtCommandQuerySvc.get($scope.url).then(function (response) {
                            $scope.value = Object.byString(response, $scope.path);
                        }).catch(function (error) {
                            errorReporter.logError(error, 'ocUrlVal', true); // silent
                        });
                    }
                    doUpdate();

                    if ($scope.updateTrigger) {
                        $scope.$watch($scope.updateTrigger, function (newvalue, oldvalue) {
                            doUpdate();
                        });
                        }

                        if ($scope.updateOnBroadcastFrom) {
                            $rootScope.$on($scope.updateOnBroadcastFrom, function () {
                                doUpdate();
                            });
                        }
                }],
                template: function (scope) {
                    return '{{value}}';
                }
            };
        }]);
