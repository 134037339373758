//import angular from 'angular';
import * as _ from 'underscore';
import Plotly from 'plotly.js-dist';
import OcDateSvc from '../../../services/ocDateSvc';
import AdminUrls from '../../admin/adminUrls';
import tmpl from './purchaseOrderDetails.html';


export const enum PurchaseOrderLoadState {
    loading = "loading",
    loadingBackground = "loadingBackground",
    error = "error",
    ready = "ready"
}

export interface PurchaseOrderLineItem {
    id: string|guid;
    componentTypeId: guid;
    equipmentTypeSpecificationTypeId: guid;
    description: string;
    expectedDate: Date;
    expectedCount: number;
    expectedTotalValueSymbol: string;
    totalValueCurrencyTypeId: guid;
    expectedTotalValue: number;
    dirty: boolean;
}

export default class purchaseOrderDetailsCtrl implements IWindowController, ng.IComponentController {
    public form: ng.IFormController;
    public wnd: IWindowObj;
    public buttons: IButton[];
    public buttonStates: { [key: string]: IButtonState; };
    public buttonMethods: { [key: string]: (button?: string) => void; };
    public closeOnSave: boolean;

    // security                        
    public readonly: boolean;

    public purchaseOrderScheduleGrid: {
        refresh?: () => void;
    } = {};

    private readonly unsavedIdPrefixChar = 'Z'; //should not be valid for a GUID, should be const
    protected newId = 0; //used for allocating temporary ids to newly added / unsaved items

    protected lineItemsDirty = false;

    public status = PurchaseOrderLoadState.loading;

    public showSaveButton = true;

    public chartStatus = PurchaseOrderLoadState.loading;

    protected readonly chartMinScaleY = 10;
    protected readonly chartMonthCountMin = 3;
    protected readonly chartMonthCountMax = 24;
    protected readonly purchaseOrderDetailsChart: string;

    public componentTypeId: guid;
    public purchaseOrderId: guid;
    public siteId: guid;
    public purchaseOrderNumber: string;
    public mode: string; //unused?

    public data: {
        id: guid;
        startDate?: Date;
        endDate?: Date;
        siteId: guid;
        purchaseOrderNumber?: string;
        lineItems: PurchaseOrderLineItem[];
        componentCount: number;
        recieveToDate: number;
        expectedToDate: number;
        totalValue: number;
    };

    public initParams: {
        showCloseOnSave?: boolean;
        purchaseOrderId?: guid;
        siteId?: guid;
        purchaseOrderNumber?: string;
        mode?: string;
        componentTypeId?: guid;
    } = {};


    public scheduleData = [];

    protected readonly chartConfig: Partial<Plotly.Config> = { displayModeBar: false, responsive: true, autosizable: true, staticPlot: true };

    static $inject = [  '$scope', 'amtCommandQuerySvc', 'componentUrls', 'adminUrls', 'referenceDataUrls',
                        'amtXlatSvc', 'confirmSvc', 'WindowFactory', 'ocConfigSvc', 'notifySvc', 'ocSecuritySvc',
                        'errorReporter', 'ocDateSvc', 'exportSvc', '$timeout'];
    constructor(private $scope: ng.IScope, private amtCommandQuerySvc: IAmtCommandQuerySvc, private apiUrls: IComponentUrls, private adminUrls: AdminUrls, private referenceDataUrls: IReferenceDataUrls,
        private xlatSvc: IAmtXlatSvc, private confirmSvc: IConfirmSvc, private WindowFactory: IWindowFactory, private config: IOcConfigSvc, private notifySvc: INotifySvc, ocSecuritySvc: IOcSecuritySvc,
        private errorReporter: IErrorReporter, private ocDateSvc: OcDateSvc, private exportSvc: IExportSvc, private $timeout: ng.ITimeoutService) {

        this.purchaseOrderDetailsChart = 'purchaseOrderDetailsChart' + $scope.$id;
        this.readonly = !ocSecuritySvc.isAuthorised('Security.Site.PurchaseOrders.Edit', AccessTypes.readWrite);
    }

    createNewId(): string {
        return this.unsavedIdPrefixChar + ("00000" + this.newId++).slice(-6); //"Z" + zero padded number
    }

    isUnsavedId(id: string): boolean {
        return id.charAt(0) == this.unsavedIdPrefixChar;
    }

    handleLineItemChange(lineItem) {
        this.componentTypeId = lineItem.componentTypeId;

        if (!this.data || !this.data.lineItems || !Array.isArray(this.data.lineItems))
            return; //should this error?

        let li = _.find(this.data.lineItems, d => d.id && d.id == lineItem.id );
        let isExisting = li && !!li.id;

        if (isExisting) {
            li.id = lineItem.id;
        } else {
            li = <PurchaseOrderLineItem>{};
            li.id = this.createNewId()
        }

        li.componentTypeId = lineItem.componentTypeId;
        li.equipmentTypeSpecificationTypeId = lineItem.equipmentTypeSpecificationTypeId;
        li.description = lineItem.description;
        li.expectedDate = lineItem.expectedDate;
        li.expectedCount = lineItem.expectedCount;
        li.expectedTotalValueSymbol = lineItem.expectedTotalValueSymbol;
        li.totalValueCurrencyTypeId = lineItem.totalValueCurrencyTypeId;
        li.expectedTotalValue = lineItem.expectedTotalValue;
        li.dirty = true;

        if (!isExisting) {
            this.data.lineItems.push(li);
        }

        this.lineItemsDirty = true;

        this.determineSaveButtonState();
    }

    doLineItemPopup(item) {
        let data;
        if (item) {
            data = angular.copy(item);
        } else {
            data = {
                componentTypeId: this.componentTypeId,
            };
        }
        data.minDate = this.data.startDate || void 0;
        data.maxDate = this.data.endDate || void 0;

        this.WindowFactory.openItem({
            component: "purchase-order-specification-details",
            caption: this.xlatSvc.xlat("equipment.addPurchaseOrderSpecification"),
            initParams: data,
            width: 600,
            modal: true,
            canClose: false,
            onDataChangeHandler: li => this.handleLineItemChange(li)
        });
    }
                    
    deleteLineItem(item) {
        let idx = this.data.lineItems.indexOf(item);

        if (idx > -1) {
            //this is what we'll use to flag a line item can be deleted on the server
            item.expectedCount = 0;
            item.dirty = true;

            //if it's unsaved, no need to tell the server it was created and deleted
            if (this.isUnsavedId(item.id)) {
                this.data.lineItems.splice(idx, 1);
            }
        }

        this.lineItemsDirty = this.data.lineItems.some((li) => li.dirty);

        this.determineSaveButtonState();
    }

    async loadSchedule() {
        let criteria = {
            purchaseOrderId: this.data.id
        };

        let response = await this.amtCommandQuerySvc.post(this.apiUrls.getPurchaseOrderSchedule, criteria);

        //hacky inline type as not ready to go full TypeScript on source files yet (wait for build improvements)
        let res = response as { specification: string, scheduledDeliveries: number, actualDeliveries: number, monthDescription: string, deliveryStatus: string }[];
        let currDate: Date = this.ocDateSvc.now();
        let thisMonth: Date = new Date(currDate.getFullYear(), currDate.getMonth(), 1);
        let nextMonth: Date = new Date(currDate.getFullYear(), currDate.getMonth()+1, 1);

        for (let o of res) {
            let monthParts = o.monthDescription.split('-').map(Number);

            if (o.scheduledDeliveries == 0 || monthParts.length != 2) {
                o.deliveryStatus = this.xlatSvc.xlat("UI.Resource.Common.Error");
            } else {
                let ordDate = new Date(monthParts[1], monthParts[0] - 1, 1);

                if (o.actualDeliveries >= o.scheduledDeliveries) {
                    o.deliveryStatus = this.xlatSvc.xlat("equipment.purchaseOrderScheduleStatus_Delivered");
                } else if (ordDate >= nextMonth) {
                    o.deliveryStatus = this.xlatSvc.xlat("equipment.purchaseOrderScheduleStatus_Scheduled");
                } else if (ordDate < thisMonth) {
                    o.deliveryStatus = this.xlatSvc.xlat("equipment.purchaseOrderScheduleStatus_Late");
                } else {
                    o.deliveryStatus = this.xlatSvc.xlat("equipment.purchaseOrderScheduleStatus_InProgress");
                }
            }
        }
        //crumby string sort on month assumes the format and that it's zero padded
        this.scheduleData = res.sort((a, b) => a.monthDescription.localeCompare(b.monthDescription, 'en'));

        if (this.purchaseOrderScheduleGrid) {
            this.purchaseOrderScheduleGrid.refresh();
        }

        this.$timeout();
    }

    async loadChart() {
        try {
            this.chartStatus = PurchaseOrderLoadState.loading;

            let criteria = {
                filterValues: {
                    startDate: this.ocDateSvc.dateAsUTC(this.ocDateSvc.today()),
                    purchaseOrderId: this.purchaseOrderId,
                }
            };

            let response = await this.amtCommandQuerySvc.post(this.apiUrls.getPurchaseOrderMonthCounts, criteria);
            
            //user may have navigated away before we get a response, make sure we have an element before waste any more effort on the graph
            let chartElement = document.getElementById(this.purchaseOrderDetailsChart);
            if (response && chartElement) {
                let chartMonthNames: string[] = [];
                let startDate: Date = new Date(response.startDate);
                let startMonth: number = startDate.getMonth();
                let monthNames: string[] = kendo.cultures.current.calendars.standard.months.namesAbbr;
                let monthCount: number = Math.min(this.chartMonthCountMax, Math.max(response.scheduledDeliveries.length, response.actualDeliveries.length, this.chartMonthCountMin));

                let scheduledDeliveries = response.scheduledDeliveries.slice(0, monthCount);
                let actualDeliveries = response.actualDeliveries.slice(0, monthCount);
                //use of ... to apply array as argument list may not work IE (even 11)
                let yscale = Math.max(this.chartMinScaleY, Math.max(...scheduledDeliveries), Math.max(...actualDeliveries));

                const monthsInYear: number = 12;

                let showYear = monthCount > monthsInYear;
             
                let year: number = startDate.getFullYear();
                for (let i = 0, month = startMonth; i < monthCount; ++i) {
                    //concatenating the month and year is probably not 100% ok with localisation, but it's close enough for what we support
                    chartMonthNames.push(monthNames[month] + (showYear ? " " + year : ""));
                    if (++month >= monthsInYear) { month = 0; ++year; }
                }

                let chartLayout: Partial<Plotly.Layout> = {
                    barmode: 'group',
                    height: 250,
                    autosize: true,
                    margin: {
                        l: 20,
                        r: 20,
                        b: showYear ? 60 : 20,
                        t: 20,
                        pad: 4
                    },
                    xaxis: { autorange: true },
                    yaxis: {
                        range: [0, yscale],
                        automargin: true,
                        angle: showYear ? 45 : 0
                    }
                };

                let chartData: Partial<Plotly.PlotData>[] = [
                    {
                        x: chartMonthNames,
                        y: scheduledDeliveries,
                        name: this.xlatSvc.xlat('equipment.purchaseOrderGraphScheduledDeliveries'),
                        type: 'bar',
                        marker: {
                            color: '#97D700',
                            line: { color: '#71A100' }
                        }
                    }, {
                        x: chartMonthNames,
                        y: actualDeliveries,
                        name: this.xlatSvc.xlat('equipment.purchaseOrderGraphActualDeliveries'),
                        type: 'bar',
                        marker: {
                            color: '#548BD4',
                            line: { color: '#3F689F' }
                        }
                    }
                ];

                Plotly.newPlot(chartElement, chartData, chartLayout, this.chartConfig);

                this.chartStatus = PurchaseOrderLoadState.ready;
            }
        } catch (error) {
            this.chartStatus = PurchaseOrderLoadState.error;
            this.errorReporter.logError(error, 'ComponentSearch-LoadDefaults');
        }
    }

    $onInit() {
        this.initParams.showCloseOnSave = false; //TODO: to support this we need the control to update properly on save - the save needs to send us ids for new line items
        this.purchaseOrderId = this.initParams.purchaseOrderId;
        this.siteId = this.initParams.siteId;
        this.purchaseOrderNumber = this.initParams.purchaseOrderNumber;
        this.mode = this.initParams.mode;
        this.componentTypeId = this.initParams.componentTypeId;

        this.WindowFactory.addButton(this, 'common.save_label', 'saveButton', () => this.save(), true, true, true);
        this.WindowFactory.addButton(this, 'common.delete_label', 'deleteButton', () => this.deletePurchaseOrder(), false, false, false);

        this.wnd.onClose = () => this.onClose();

        // add a watch to the dirty flag
        this.$scope.$watch(() => this.form.$dirty, dirty => this.wnd.isDirty = dirty);

        this.$scope.$watchGroup(
            [() => this.form.$dirty, () => this.form.$invalid, () => this.wnd.processing],
            () => this.determineSaveButtonState());

        if (this.purchaseOrderId) {
            this.wnd.windowRelatedRecordId = this.purchaseOrderId;
            this.loadDetails();
        } else {
            this.data = {
                id: null,
                siteId: this.initParams.siteId,
                startDate: new Date(),
                lineItems: [],
                componentCount: 0,
                recieveToDate: 0,
                expectedToDate: 0,
                totalValue: 0
            };

            this.status = PurchaseOrderLoadState.ready;
        }
    }

    async loadDetails() {
        try {
            this.wnd.processing = true;
            let response = await this.amtCommandQuerySvc.post(this.apiUrls.getPurchaseOrderDetails, { id: this.purchaseOrderId });
            if (!response)
                return;

            this.data = response;
            this.status = PurchaseOrderLoadState.ready;

            this.buttonStates.deleteButton.visible = true;

            //load graph after main tab but before it is accessed
            this.wnd.processing = false;
            await this.loadChart();
            await this.loadSchedule();
        } catch (error) {
            this.status = PurchaseOrderLoadState.error;
            this.errorReporter.logError(error);
        } finally {
            this.wnd.processing = false;
        }
    }

    async save() {
        try {
            this.wnd.processing = true;

            let lineItems = angular.copy(this.data.lineItems)
                                .filter(o => o.dirty);

            //TODO: remove garbage any cast, we should construct the object we need not use a deep copy & then delete/null-out properties off of it
            lineItems.forEach((o:any) => {
                //remove fields the server doesn't need (because why waste bytes sending them over the wire)
                delete o.componentTypeId;
                delete o.componentTypeDescription;
                delete o.manufacturer;
                delete o.size;
                delete o.totalValueCurrencyTypeDescription;
                delete o.expectedTotalValueSymbol;
                delete o.dirty;
                delete o.description;

                o.expectedDate = this.ocDateSvc.dateAsUTC(o.expectedDate);

                //server expects unsaved id will be null
                if (this.isUnsavedId(o.id)) {
                    o.id = null
                }
            });

            let formDirty = this.form.$dirty;

            let criteria = {
                id: this.data.id,
                lineItems: lineItems,

                //optionally set these only if potentially modified, 'void 0' is used for undefined,
                //server can use to to avoid updating the PO if only line items have changed (better record keeping of changes)
                purchaseOrderNumber: formDirty ? this.data.purchaseOrderNumber : void 0,
                startDate: formDirty ? this.ocDateSvc.dateAsUTC(this.data.startDate) : void 0,
                endDate: formDirty ? this.ocDateSvc.dateAsUTC(this.data.endDate) : void 0,
            };


            await this.amtCommandQuerySvc.post(this.apiUrls.addModifyPurchaseOrder, criteria);

            this.notifySvc.success(this.xlatSvc.xlat('component.saveSuccessful'));

            this.form.$dirty = false;
            //TODO: clear lineItemsDirty and dirty flag on line items, get/update ids for new line items from server, update this.wnd.windowRelatedRecordId too
            if (this.closeOnSave !== false) {
                if (this.wnd.onDataChanged)
                    this.wnd.onDataChanged();

                this.WindowFactory.closeWindow(this.wnd);
            }
                

        } catch (error) {
            this.errorReporter.logError(error);
        } finally {
            this.wnd.processing = false;
        }
    }

    async deletePurchaseOrder() {
        if (!this.data.id)
            return;
        try {
            await this.confirmSvc.confirmMessage('purchaseOrdersEdit.ConfirmDelete_title', 'purchaseOrdersEdit.ConfirmDelete', this.data.purchaseOrderNumber);
        } catch {
            return; //they cancelled
        }
        
        try {
            this.wnd.processing = true;
            let response = this.amtCommandQuerySvc.post(this.apiUrls.deletePurchaseOrder, { id: this.data.id });
            
            this.notifySvc.success(this.xlatSvc.xlat('purchaseOrdersEdit.DeleteSucceeded'));

            if (this.wnd.onDataChanged)
                this.wnd.onDataChanged();

            this.WindowFactory.closeWindow(this.wnd);
        } catch (error) {
            this.errorReporter.logError(error);
        } finally {
            this.wnd.processing = false;
        }
    }



    //export() {
    //    this.exportSvc.exportData(this.apiUrls.searchPurchaseOrderExport, this.criteria, this.xlatSvc.xlat('equipment.searchPurchaseOrdersExportFilename'));
    //}

    async onClose() {
        this.determineSaveButtonState();
        try {
            await this.confirmSvc.confirmSaveChange(!this.buttonStates.saveButton.disabled);
            this.form.$dirty = false;
            this.WindowFactory.closeWindow(this.wnd);
            return true;
        } catch {
            return false; //they cancelled
        }
    }

    private determineSaveButtonState() {
        // Can't save if the form is invalid, and can only save if the form is dirty and has line items (even deleted ones, as we need to tell the server to delete them)
        this.buttonStates.saveButton.disabled = (this.form.$invalid || this.wnd.processing) || !(this.form.$dirty || this.lineItemsDirty);
    }
}

angular.module('app.site').component('purchaseOrderDetails', {
    bindings: {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    },
    template: tmpl,
    controller: purchaseOrderDetailsCtrl,
    controllerAs: 'vm'
});