
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { Xlat } from '../amt/i18n/xlat';
import {
    ISaveVisualInspectionCriteria, ISetVisualInspectionActiveStatusCriteria, ISetVisualInspectionDamageCauseCriteria, IVisualInspectionComplete,
    IVisualInspectionHistory, IVisualInspectionHistory_InitParams, IVisualInspectionItem_InitParams
} from '../interfaces/visualInspection.interfaces';
import BrowserSvc from './browserSvc';
import FileManagement from './fileManagement';
import HelperSvc from './helperSvc';
import OcDateSvc from './ocDateSvc';

@Injectable({
    providedIn: 'root'
})
export class VisualInspectionService {

    constructor(
        @Inject('confirmSvc') private confirmSvc: IConfirmSvc,
        @Inject('notifySvc') private notifySvc: INotifySvc,        
        @Inject('WindowFactory') private WindowFactory: IWindowFactory,
        @Inject('errorReporter') private errorReporter: IErrorReporter,
        private amtXlatSvc: Xlat,
        private browserSvc: BrowserSvc,
        private helperSvc: HelperSvc,
        private ocDateSvc: OcDateSvc,
        private fileManagement: FileManagement,
        private http: HttpClient) { }

    getVisualInspectionHistory(visualInspectionId: guid): Observable<IVisualInspectionHistory> {
        return this.http.get<IVisualInspectionComplete>('api/equipment/component/getVisualInspectionComplete', { params: { id: visualInspectionId } }).pipe(            
            map(v => {

                let history = v as IVisualInspectionHistory;

                history.inspections.sort((a, b) => b.date.getTime() - a.date.getTime());

                let latestInspection = history.inspections[0];
                let originalInspection = history.inspections.find(i => i.id === visualInspectionId);

                let latestActionSetDate = history.inspections.filter(i =>
                    i.actionId === latestInspection.actionId &&
                    !history.inspections.some(h => h.actionId !== latestInspection.actionId && h.date > i.date)
                ).reverse()[0].date;

                if (latestInspection.actionDaysToResolve > 0)
                    history.actionDueDate = this.ocDateSvc.addDays(latestActionSetDate, latestInspection.actionDaysToResolve);

                if (latestInspection.inspectionFrequencyDays > 0)
                    history.inspectionDueDate = this.ocDateSvc.addDays(latestInspection.date, latestInspection.inspectionFrequencyDays);

                history.originalInspectionDate = originalInspection.date;
                history.identifiedBy = originalInspection.inspectedBy;

                history.lastInspectedBy = latestInspection.inspectedBy;
                history.latestAction = latestInspection.action;
                history.lastInspectionDate = latestInspection.date;

                history.inspectionOverdue = history.inspectionDueDate && history.inspectionDueDate <= new Date();
                history.actionOverdue = history.actionDueDate && history.actionDueDate <= new Date();

                for (let inspection of history.inspections)
                    inspection.frequency = this.helperSvc.frequencyDaysToText(inspection.inspectionFrequencyDays);

                return history;
            })
        );
    }

    getVisualInspection(visualInspectionId: guid, visualInspectionRecordId?: guid): Observable<any> {
        return this.http.get('api/equipment/component/getVisualInspection', {
            params: {
                visualInspectionId: visualInspectionId,
                visualInspectionRecordId: visualInspectionRecordId
            }
        });
    }

    deleteVisualInspection(visualInspectionId: guid) {
        return from(this.confirmSvc.confirmMessage('visualInspection.confirmDelete_title', 'visualInspection.confirmDelete_text')).pipe(
            switchMap(() => this.http.post('api/equipment/component/deleteVisualInspection', { id: visualInspectionId }).pipe(                
                tap(() => this.notifySvc.success(this.amtXlatSvc.xlat('visualInspection.deleteSuccessful'))),
                mapTo(true),
                catchError(err => {
                    this.errorReporter.logError(err);
                    return of(false);
                })
            )),
            catchError(() => of(false)) // they cancelled the delete
        );
    }

    deleteVisualInspectionRecord(visualInspectionRecordId: guid) {
        return from(this.confirmSvc.confirmMessage('visualInspection.confirmDelete_update_title', 'visualInspection.confirmDelete_update_text')).pipe(
            switchMap(() => this.http.post('api/equipment/component/deleteVisualInspectionRecord', { id: visualInspectionRecordId }).pipe(                
                tap(() => this.notifySvc.success(this.amtXlatSvc.xlat('visualInspection.updateDeleteSuccessful'))),
                mapTo(true),
                catchError(err => {
                    this.errorReporter.logError(err);
                    return of(false);
                })
            )),
            catchError(() => of(false)) // they cancelled the delete
        );
    }
    
    saveVisualInspection(criteria: ISaveVisualInspectionCriteria) {
        return from(this.fileManagement.processFileUploads(criteria.attachments)).pipe(
            switchMap(() => this.http.post('api/equipment/component/saveVisualInspection', criteria).pipe(                
                tap(() => this.notifySvc.success(this.amtXlatSvc.xlat('visualInspection.saveSuccessful'))),
                mapTo(true),
                catchError(err => {
                    this.errorReporter.logError(err);
                    return of(false);
                })
            ))
        );
    }

    setVisualInspectionActiveStatus(criteria: ISetVisualInspectionActiveStatusCriteria) {
        return this.http.post('api/equipment/component/setVisualInspectionActiveStatus', criteria).pipe(            
            tap(() => this.notifySvc.success(this.amtXlatSvc.xlat('visualInspection.saveSuccessful'))),
            mapTo(true),
            catchError(err => {
                this.errorReporter.logError(err);
                return of(false);
            })
        );
    }

    setVisualInspectionDamageCause(criteria: ISetVisualInspectionDamageCauseCriteria) {
        return this.http.post('api/equipment/component/setVisualInspectionDamageCause', criteria).pipe(
            tap(() => this.notifySvc.success(this.amtXlatSvc.xlat('visualInspection.saveSuccessful'))),
            mapTo(true),
            catchError(err => {
                this.errorReporter.logError(err);
                return of(false);
            })
        );
    }

    openVisualInspectionItem(params: IVisualInspectionItem_InitParams, onSave?: Function) {
        this.WindowFactory.openItem({
            component: 'visual-inspection-item',
            width: 800,
            modal: true,
            canClose: false,
            initParams: params,
            onDataChangeHandler: onSave,
            allowOverflow: this.browserSvc.isMobile
        });
    }

    viewVisualInspectionHistory(params: IVisualInspectionHistory_InitParams, serialNumber: string, onChange?: Function) {
        this.WindowFactory.openItem({
            component: 'visual-inspection-history',
            caption: String.format('{0} - {1}', this.amtXlatSvc.xlat('visualInspection.history'), serialNumber),
            width: 1000,
            windowRelatedRecordId: params.visualInspectionId,
            initParams: params,
            onDataChangeHandler: onChange
        });
    }
   
    editVisualInspectionCause(visualInspectionId: guid, equipmentTypeId: guid, currentCauseId?: guid, onSave?: Function) {
        this.WindowFactory.openItem({
            component: 'visual-inspection-cause',
            caption: this.amtXlatSvc.xlat('visualInspection.editCause'),
            width: 500,
            modal: true,
            canClose: false,
            initParams: {
                visualInspectionId: visualInspectionId,
                equipmentTypeId: equipmentTypeId,
                causeId: currentCauseId
            },
            onDataChangeHandler: onSave            
        });
    }

    // TODO: move this to a fieldSurveySvc? (existing fieldSurveyService is more an extension of the baseModuleService for mobile)
    addFieldSurvey(equipmentId: guid, date?: Date, onSave?: Function) {
        this.WindowFactory.openItem({
            component: 'field-surveys',
            caption: this.amtXlatSvc.xlat('fieldSurvey.elementName'),
            initParams: {
                date: date,
                equipmentId: equipmentId,
                closeWindowOnNoDate: true
            },
            footerOff: true,
            height: 740,
            width: 1000,
            onDataChangeHandler: onSave
        });
    }
}

angular.module('app').factory('visualInspectionService', downgradeInjectable(VisualInspectionService) as any);