//import angular from 'angular';
import AdminUrls from '../../../adminUrls';

angular.module('app.admin').controller("siteSearchCtrl", siteSearchCtrl);

    // @ts-ignore
    siteSearchCtrl.$inject = ["adminUrls", "$scope", "enums", "ocSecuritySvc", "amtXlatSvc", "$timeout", "WindowFactory", "ocConfigSvc", "exportSvc", "helperSvc"];

    // ReSharper disable once InconsistentNaming
    function siteSearchCtrl(adminUrls: AdminUrls, $scope, enums, ocSecuritySvc, amtXlatSvc, $timeout, WindowFactory, ocConfigSvc, exportSvc, helperSvc) {

        var vm = this;

        vm.processing = false;

        vm.adminUrls = adminUrls;
        vm.config = ocConfigSvc;

        vm.siteFilterControl = {};

        vm.criteria = {
            filterValues: {
                clientIds: [],
                siteIds: [],
                activeOptions: null,
                defaultToAllSitesWhenNoSystemHierarchyFilter: true
            }
        };

        vm.activeOptions = [{
            name: amtXlatSvc.xlat('common.yes_label'),
            key: true
        }, {
            name: amtXlatSvc.xlat('common.no_label'),
            key: false
        }];

        $scope.$watch('vm.criteria.filterValues.clientIds.length', function (cnt) {
            if (cnt === 0 && vm.criteria.filterValues.siteIds.length > 0) {
                // reset the sites if the clients are cleared
                vm.criteria.filterValues.siteIds.length = 0;
            }
        });

        vm.status = enums.screenStatus.loading;

        vm.gridControl = {};
        vm.selectedItem = null;

        function init() {
            vm.loadDefaults();
        }

        vm.onSelectedItemChanged = function (items) {
            if (items != null && items.length === 1) {
                vm.selectedItem = items[0];
            } else {
                vm.selectedItem = null;
            }
        };

        vm.setHeaderHeight = function (height, offset) {
            vm.gridTop = height + offset;
        };

        vm.loadDefaults = function () {
            vm.defaults = {
                activeOptions: [vm.activeOptions[0]]
            };

            vm.status = enums.screenStatus.ready;
        };

        vm.export = function () {
            exportSvc.exportData(vm.adminUrls.searchSitesExport, vm.criteria, amtXlatSvc.xlat('site.searchTitle'));
        };

        $scope.editSite = function (item) {
            if (item) {
                WindowFactory.openItem({
                    component: 'add-edit-site',
                    caption: amtXlatSvc.xlat("site.editSite"),
                    initParams: { id: item.id },
                    width: 550,
                    onDataChangeHandler: function () {
                        vm.refreshGrid();
                        vm.siteFilterControl.search();
                    },
                    windowRelatedRecordId: item.id
                });
            }
        };

        $scope.addSite = function () {
            WindowFactory.openItem({
                component: 'add-edit-site',
                caption: amtXlatSvc.xlat("site.addSite"),
                initParams: null,
                width: 550,
                onDataChangeHandler: function () {
                    vm.refreshGrid();
                    vm.siteFilterControl.search();
                }
            });
        };

        vm.refreshGrid = function () {
            vm.gridControl.refresh();
        };

        init();
    }
