//import angular from 'angular';
import OcConfigSvc from '../../../../services/ocConfigSvc';
import AdminUrls from '../../../admin/adminUrls';


angular.module('app.component').controller('mwWarrantyClaimSearchCtrl', [
        '$scope', 'amtCommandQuerySvc', 'enums', 'amtXlatSvc', 'confirmSvc', 'componentUrls', 'referenceDataUrls',
        'notifySvc', 'adminUrls', 'exportSvc', 'ocConfigSvc', 'WindowFactory', 'ocSecuritySvc', 'errorReporter',
        function mwWarrantyClaimSearchCtrl($scope, amtCommandQuerySvc: IAmtCommandQuerySvc, enums, xlatSvc: IAmtXlatSvc, confirmSvc: IConfirmSvc, componentUrls,
            referenceDataUrls, notifySvc: INotifySvc, adminUrls: AdminUrls, exportSvc: IExportSvc, ocConfigSvc: OcConfigSvc, WindowFactory: IWindowFactory,
            ocSecuritySvc: IOcSecuritySvc, errorReporter: IErrorReporter) {
            var vm = this;

            // security
            vm.canDelete = ocSecuritySvc.isAuthorised('Security.Components.Warranty.Delete', AccessTypes.readWrite);
            vm.canViewStatusChange = ocSecuritySvc.isAuthorised('Security.Components.ChangeStatus', AccessTypes.readOnly);

            vm.apiUrls = componentUrls;
            vm.adminUrls = adminUrls;
            vm.referenceDataUrls = referenceDataUrls;

            vm.showClients = false;
            vm.showSites = false;

            vm.criteria = {
                filterValues: {
                    clientIds: [],
                    siteIds: [],
                    statusId: null,
                    dateFrom: null,
                    dateTo: null,
                    claimNumber: null,
                    serialNumber: null,
                    defaultToAllSitesWhenNoSystemHierarchyFilter: true
                }
            };

            $scope.$watch('vm.criteria.filterValues.clientIds.length', function (cnt) {
                if (cnt === 0 && vm.criteria.filterValues.siteIds.length > 0) {
                    // reset the sites if the clients are cleared
                    vm.criteria.filterValues.siteIds.length = 0;
                }
            });

            $scope.rowParseFunction = function (response) {
                var claims = response.result;

                for (var r = 0; r < claims.length; r++) {
                    claims[r].onSite = claims[r].siteId === vm.config.user.site.id;
                    claims[r].canViewScrapEvent = claims[r].onSite && vm.canViewStatusChange;
                }

                return response;
            };

            vm.status = "loading";
            vm.isDirty = true;

            // get the config from root scope, this contains things like units of measure
            vm.config = ocConfigSvc;

            vm.gridControl = {};
            vm.selectedItem = null;

            function init() {
                vm.loadDefaults();
            }

            vm.onDatabound = function () {
                vm.selectedItem = null;
            };

            vm.onSelectedItemChanged = function (items) {
                if (items !== null && items.length === 1) {
                    vm.selectedItem = items[0];
                } else {
                    vm.selectedItem = null;
                }
            };

            vm.setHeaderHeight = function (height, offset) {
                vm.gridTop = height + offset;
            };

            vm.export = function () {
                exportSvc.exportData(vm.apiUrls.exportMwWarrantyClaim, vm.criteria, xlatSvc.xlat('component.searchExportFilenameMW'));
            };

            vm.loadDefaults = function () {
                vm.status = 'loading';
                var defaultCriteria = {
                    componentTypeName: null,
                    statusTypeNames: []
                };

                amtCommandQuerySvc.post(vm.apiUrls.getDefaults, defaultCriteria).then(function (response) {
                    if (response) {

                        vm.defaultClients = response.clients;
                        vm.defaultSites = response.sites;

                        vm.siteId = response.sites[0].key;

                        vm.status = "ready";

                        // focus on serial number field by default
                        vm.focusSerialNumber = true;
                    }
                }, function (error) {
                    vm.defaultErrors = [error];
                    vm.status = "error";
                    errorReporter.logError(error);
                });
            };

            $scope.editClaim = function (item) {
                if (item !== undefined && item !== null) {
                    WindowFactory.openItem({
                        component: 'mw-warranty-claim-detail',
                        caption: String.format('{0}{1}', item.formattedSerialNumber,
                            (item.claimNumber !== null ? ' (' + item.claimNumber + ')' : '')),
                        initParams: {
                            claimId: item.id
                        },
                        width: 700,
                        onDataChangeHandler: vm.filter
                    });
                }
            };

            $scope.deleteClaim = function (item) {
                confirmSvc.confirmMessage('component.confirmDeleteMwWarrantyClaim_title', 'component.confirmDeleteMwWarrantyClaim_text').then(
                    function () {
                        amtCommandQuerySvc.post(vm.apiUrls.deleteMwWarrantyClaim, item).then(function (response) {

                            notifySvc.success(xlatSvc.xlat("component.messageWarrantyClaimDeleteSuccess"));
                            vm.filter();

                        }).catch(function (error) {
                            errorReporter.logError(error);
                        });
                    });
            };

            $scope.showComponentDetails = function (item) {
                WindowFactory.openItem({
                    component: 'component-details',
                    caption: xlatSvc.xlat('equipment.open' + item.equipmentTypeName, item.formattedSerialNumber),
                    windowRelatedRecordId: item.equipmentId,
                    initParams: {
                        equipmentId: item.equipmentId,
                        siteId: item.siteId,
                        componentType: item.equipmentTypeName,
                        serialNumber: item.formattedSerialNumber,
                        showCloseOnSave: false
                    },
                    width: 800,
                    height: 850
                });
            };

            $scope.editEvent = function (item) {
                WindowFactory.openItem({
                    component: 'change-status-popup',
                    caption: xlatSvc.xlat('equipment.changeStatus'),
                    initParams: {
                        equipmentId: item.equipmentId,
                        equipmentEventId: item.scrapEventId,
                        isEdit: true,
                        isBulkMove: false,
                        clientId: item.clientId,
                        siteId: item.siteId,
                        showCloseOnSave: false
                    },
                    width: 1400,
                    modal: true,
                    onDataChangeHandler: vm.filter
                });
            };

            vm.refreshGrid = function () {
                vm.gridControl.refresh();
            };

            vm.filter = function () {
                vm.showClients = vm.criteria.filterValues.clientIds.length !== 1;
                vm.showSites = vm.criteria.filterValues.siteIds.length !== 1;
                vm.refreshGrid();
            };

            init();
        }
    ]);
