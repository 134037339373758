/**
 * @ngdoc directive
 * @name amtFramework.directive:amtWell
 * @restrict E
 * @param {boolean} isHideBorder ???
 * @param {boolean} allowCollapse ???
 * @param {boolean} isCollapse Provides a simple way to hide and show an element with a css transition
 *  
 * @function
 *
 * @description
 * AMT Tooltip
 */

//import angular from 'angular';
import template from './amtWell.html';
import templateBody from './amtWellBody.html';
import templateHeader from './amtWellHeader.html';
import templateFooter from './amtWellFooter.html';

angular.module('amtFramework')
        .directive('amtWell', function () {
            return {
                restrict: 'E',
                transclude: true,
                scope: {
                    isHideBorder: '@',
                    allowCollapse: "@",
                    isCollapsed: '=?'
                },
                template: template,
                controller: ['$scope', function ($scope) {
                    this.allowCollapse = $scope.allowCollapse === "true" ? true : false;
                    if (this.allowCollapse && $scope.isCollapsed === undefined) {
                        this.isCollapsed = $scope.isCollapsed = false;
                    }
                    else {
                        this.isCollapsed = $scope.isCollapsed;
                    }
                    $scope.internalIsCollapsed = $scope.$eval($scope.isCollapsed);
                    this.toogleCollapse = function () {
                        $scope.internalIsCollapsed = !$scope.internalIsCollapsed;
                    };
                }]
            };
        })
        .directive('amtWellBody', function () {
            return {
                restrict: 'E',
                require: '^amtWell',
                transclude: true,
                template: templateBody,
                link: function (scope: any, element, attr, ctrl) {
                    var amtWellCtrl = ctrl;
                    scope.allowCollapse = amtWellCtrl.allowCollapse;
                    scope.isCollapsed = amtWellCtrl.isCollapsed;
                }
            };
        })
        .directive('amtWellHeader', function () {
            return {
                restrict: 'E',
                require: '^amtWell',
                transclude: true,
                template: templateHeader,
                link: function (scope: any, element, attr, ctrl) {
                    var amtWellCtrl = ctrl;
                    scope.allowCollapse = amtWellCtrl.allowCollapse;
                    scope.isCollapsed = amtWellCtrl.isCollapsed;
                    scope.toogleCollapse = function () {
                        if (!scope.allowCollapse) {
                            return;
                        }
                        scope.isCollapsed = !scope.isCollapsed;
                        amtWellCtrl.toogleCollapse();
                    };
                }
            };
        })
        .directive('amtWellFooter', function () {
            return {
                restrict: 'E',
                require: '^amtWell',
                transclude: true,
                template: templateFooter,
            };
        });
