//import angular from 'angular';


    angular.module('app.directives').directive('ocChartPolygon', function () {
        return {
            replace: true,
            scope: {
                config: "="
            },
            template:
                '<g ng-class="[\'chartPolygon\',config.cssClass]">' +
                '    <polygon ng-attr-points="{{config.points}}" ng-click="config.onClick()" ng-mouseenter="config.hoverStart()" ng-mouseleave="config.hoverEnd()"/>' +
                '</g>'
        };
    });
