/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
/**
 * @hidden
 */
var ColorPickerLocalizationService = /** @class */ (function (_super) {
    tslib_1.__extends(ColorPickerLocalizationService, _super);
    function ColorPickerLocalizationService(prefix, messageService, _rtl) {
        return _super.call(this, prefix, messageService, _rtl) || this;
    }
    ColorPickerLocalizationService = tslib_1.__decorate([
        tslib_1.__param(0, core_1.Inject(kendo_angular_l10n_1.L10N_PREFIX)),
        tslib_1.__param(1, core_1.Optional()),
        tslib_1.__param(2, core_1.Optional()), tslib_1.__param(2, core_1.Inject(kendo_angular_l10n_1.RTL)),
        tslib_1.__metadata("design:paramtypes", [String, kendo_angular_l10n_1.MessageService, Boolean])
    ], ColorPickerLocalizationService);
    return ColorPickerLocalizationService;
}(kendo_angular_l10n_1.LocalizationService));
exports.ColorPickerLocalizationService = ColorPickerLocalizationService;
