import { Injectable } from '@angular/core';
import { WidgetConstant } from '../widgetHelper/WidgetConstant';
import { WidgetHelperConstant } from '../Interface/WidgetConstant.interface';
import {  } from '../Interface/Widget.interface';
import { DetailElementItem } from '../Interface/SiteDashboard.interface';

@Injectable({
    providedIn: 'root'
})

export class WidgetHelper {

    constructor(private constant: WidgetConstant) { }

    /**Set tyre unit By the number */
    public getTyreUnit(value: number) {
        let widgetConstant: WidgetHelperConstant = this.constant.getWidgetHelperConstant();
        return (value === 0 || value === 1) ? widgetConstant.tyre : widgetConstant.tyres;
    }

    /**Set hour unit by the number */
    public getHourUnit(value: number) {
        let widgetConstant: WidgetHelperConstant = this.constant.getWidgetHelperConstant();
        return (value === 0 || value === 1) ? widgetConstant.hr : widgetConstant.hrs;
    }

    public getValidateValue(value: number) {
        let isNullOrUndefinedOrIsNaN: boolean = value === null || value === undefined || Number.isNaN(Number(value));
        return isNullOrUndefinedOrIsNaN ? 0 : value;
    }


    /**Return Boolean by check if the widget detailElement includes the "Subtext"*/
    public getIncludeSubtext(detailElement: Array<DetailElementItem>) {
        let widgetConstant: WidgetHelperConstant = this.constant.getWidgetHelperConstant();
        let detailElementLength = detailElement.length;
        if (detailElementLength === 1) {
            let element = detailElement[0];
            let description = element.configurationDescription;
            let value = element.configurationValues[0];
            if (description.includes(widgetConstant.subtext)) {
                if (value.includes(widgetConstant.false)) {
                    return false;
                } else {
                    return true;
                }
            }
        } else {
            return true;
        }
    }
}