import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { mapFrom } from '../modules/site/conditionMonitoring/helpers';
import { IVehicle } from './vehicles-onsite.service';

export interface IVehicleSpec {
    vehicleApplicationId: string,
    model: string,
    //equipmentTypeSpecificationTypeId: string,
    manufacturerId: string,
    manufacturer: string,
    equipmentTypeId: string,
    vehicleApplication: string,
    axles: string[][]
};

@Injectable({
    providedIn: 'root'
})
export class VehicleSpecsService {
    constructor(private http: HttpClient) { }
    private vehicleSpecsRequest$ = this.http.get<Record<string, IVehicleSpec>>(baseApiUrl + 'equipment/vehicle/specList').pipe(
        //shareReplay(1)
    );
    
    private specMapById$ = this.vehicleSpecsRequest$.pipe(
        map(s => mapFrom(s))
    );

    public get vehicleSpecs$() {
        return this.specMapById$;
    }

    public getSpec$(id$: Observable<string | null>) {
        return combineLatest(id$, this.vehicleSpecs$).pipe(
            switchMap(s => of( s[0] && s[1].get(s[0]) || null))
        );
    }

    public getSpecForVehicle$(vehicle$: Observable<IVehicle|null>) {
        let $id = vehicle$.pipe(
            map(w => w?.specificationId || null), 
            distinctUntilChanged(),
        );

        return this.getSpec$($id);
    }
}
