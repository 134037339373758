    // <oc-data-sync-module> Contains all the logic to ensure the module styling reflects the age of the data.
//import angular from 'angular';
import tmpl from './ocDataSyncModule.html';



    angular.module('app.directives').directive("ocDataSyncModule", ['$compile', 'amtXlatSvc', '$timeout',
        function ($compile, xlatSvc, $timeout) {
   

    return {
        restrict: 'E',
        replace: false,
        scope: {
            module: "=",
            lastRecordedDate: "=",
            sectionCount : "="
        },
        transclude: false,
        template: tmpl,
        controller: ['$scope', function ($scope) {
            var vm = this;
            console.log("ocDataSYnc:" + $scope.module.name);
            $scope.$watch('module.lastRecordedDate', function (newValue, oldValue) {
                if (!newValue && oldValue) {
                    if (vm.up) {
                        console.warn("section count decrement:" + $scope.module.name);
                        $scope.sectionCount.val--;
                        vm.up = false;
                    }
                }                                    
            });

            // we update our details on display
            console.log("ocDataSYnc: Update " + $scope.module.name);
            $scope.module.updateDetails().finally(function () {
                // and now tell the parent
                console.log("ocDataSYnc: Updated " + $scope.module.name);
                if ($scope.module.lastRecordedDate)
                {
                    if (!vm.up) {
                        console.log("ocDataSYnc: Records " + $scope.module.name + ":" + $scope.sectionCount.val);
                        vm.up = true;
                        $scope.sectionCount.val++;
                        if (!$scope.lastRecordedDate || $scope.module.lastRecordedDate.getTime() < $scope.lastRecordedDate.getTime()) {
                            $scope.lastRecordedDate = $scope.module.lastRecordedDate;
                        }
                    }
                }
                $timeout(); // force an update of the display
            });



        }]
    };
    }]);
