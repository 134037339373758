//import angular from 'angular';
import tmpl from './ocVehicleSchematic.html';


    angular.module('app.directives').directive("ocVehicleSchematic", [
        'amtXlatSvc', 'WindowFactory', '$timeout', 'errorReporter', 'dataBroker',
        function (xlatSvc, WindowFactory, $timeout, errorReporter, dataBroker) {
            return {
                scope: {
                    maxWidth: "=", // TWO-WAY binding here because we want to pass by reference to maintain the data-type (ie number)
                    maxHeight: "=", // TWO-WAY binding here because we want to pass by reference to maintain the data-type (ie number)
                    includeFitments: "@",
                    vehicleObject: "=",
                    specification: "=?",
                    schematicDate: "=",
                    openModule: "@",
                    scaleToFit: "=",
                    topAxle: "=?",
                    leftPos: "=?",
                    notification: "@",
                    schematicWidth: "=?",
                    schematicHeight: "=?",
                    overflowX: "@?",
                    overflowY: "@?",
                    svgScale: "=?",
                    schematicHasScrollbar: "=?",
                    showChains: "=?",
                    showRims: "=?",
                    excludeReadings: "=",
                    zoomRect: "=?",
                    processing: "=?",
                    selectedComponent: "=?",
                    selectedPosition: "=?",
                    showPositionLabels: "=?",
                    showPositionAsReading: "<",
                    redraw: '=?',
                    roundAxles: "@",
                    doRefresh: '=?', // use to indicate that the schematic has loaded additional data
                    positionsWide: "=?",
                    positionsHigh: "=?",
                    changeCount: "=",
                    allowDragDrop: "=",
                    showChangeCount: "=",
                    positionView: "=?",
                    fitComponent: "&",
                    removeComponents: "<?",
                    chevronIsVisible: "=?",
                    canOpenComponentDetails: "<?",
                    useReadingSequence: "=?",
                    disabledSegments: "=?",
                    onLabelSelected: "&?",
                    tyreSelected: '&?'
                },
                // the url case must match the directory structure for caching and testing - the VehicleSchematic has a capital V.
                template: tmpl,
                //link: function (scope: any, element, attr) {
                //    scope.round = scope.roundAxles ? scope.roundAxles - 1 : 0;
                //},
                controllerAs: 'vm',
                controller:
                    ["$scope", "vehicleUrls", "amtCommandQuerySvc", "confirmSvc", "amtXlatSvc", "ocSecuritySvc",
                        function ocVehicleSchematic($scope, vehicleUrls, amtCommandQuerySvc, confirmSvc, amtXlatSvc, ocSecuritySvc) {
                            var vm = this;

                            var allowMoveOtherComponents = true;

                            function getResources(countResource, valueResource) {
                                var c = parseInt(xlatSvc.xlat(countResource).replace('(', '').replace(')', ''));

                                var resources = [];

                                for (var i = 1; i <= c; i++) {
                                    resources.push(xlatSvc.xlat(valueResource + i));
                                }

                                return resources;
                            }

                            //Add for CM
                            vm.currentSelectedComponent = undefined;

                            vm.chevronSize = 24;
                            vm.bothChevrons = vm.chevronSize * 2;
                            vm.showChevrons = false;
                            vm.showVerticalChevrons = false;
                            vm.showHorizontalChevrons = false;
                            vm.chevronWidth = 0;
                            vm.chevronHeight = 0;

                            vm.dropTargetWidth = 200;
                            vm.verticalChevronPadding = $scope.allowDragDrop || $scope.showChangeCount ? vm.dropTargetWidth : 0;;

                            function setDropTargetPos() {
                                $scope.dropTarget = {
                                    left: $scope.maxWidth > 0 ? $scope.maxWidth - (vm.dropTargetWidth + vm.chevronWidth) : 0,
                                    top: 0,
                                    width: vm.dropTargetWidth,
                                    height: $scope.maxHeight > 0 ? $scope.maxHeight - vm.chevronHeight : 0,
                                    labels: getResources('vehicle.dropHereLabels', 'vehicle.dropHere'),
                                    captionHeight: 35,
                                    labelHeight: 30
                                };
                            }

                            setDropTargetPos();

                            // security
                            $scope.canOpenComponentDetails = $scope.canOpenComponentDetails === undefined ? true : $scope.canOpenComponentDetails;
                            vm.canOpenFittedComponents = ocSecuritySvc.isAuthorised('Security.Components', 'readOnly') && $scope.canOpenComponentDetails;

                            $scope.redraw = drawSchematic;

                            $scope.schematicWidth = $scope.maxWidth || 100;
                            $scope.schematicHeight = $scope.maxHeight || 100;

                            vm.apiUrls = vehicleUrls;

                            var margin = 10;
                            vm.leftMargin = 10;
                            vm.topMargin = 10;
                            var axleWidth = $scope.schematicWidth - margin * 2;
                            var axleHeight = 30;

                            var shaftWidth = axleHeight;
                            var positionWidth = 120;
                            var positionHeight = 90;

                            var chainWidth = positionWidth;
                            var rimWidth = positionWidth / 3;
                            var tyreWidth = positionWidth - rimWidth;

                            var tyreHeight = positionHeight;
                            var rimHeight = positionHeight;
                            var chainHeight = positionHeight;

                            var frontRearGap = 0;

                            vm.setSize = function (x, y, w, h) {
                                if (w > 0) {
                                    $scope.maxWidth = w;
                                }

                                if (h > 0) {
                                    $scope.maxHeight = h;
                                }
                            }

                            function calculatePositionWidth() {

                                if (maxWheelPositions() === 0) {
                                    return;
                                }

                                vm.verticalChevronPadding = $scope.allowDragDrop || $scope.showChangeCount ? vm.dropTargetWidth + 35 : 0;;

                                if ($scope.positionsWide) {
                                    var positionsWide = $scope.positionsWide;

                                    if (positionsWide > maxWheelPositions() + 1) {
                                        positionsWide = maxWheelPositions() + 1;
                                    }

                                    //Only show left & right chevrons if there are more than 4 positions on an axle
                                    vm.showHorizontalChevrons = maxWheelPositions() > 4;
                                    vm.chevronWidth = vm.showHorizontalChevrons ? vm.bothChevrons : 0;

                                    //Recaculate drop target width (taking into account chevrons if shown)
                                    if (vm.showHorizontalChevrons) {
                                        setDropTargetPos();
                                    }

                                    positionWidth = (($scope.maxWidth - vm.chevronWidth - (($scope.dropTarget && ($scope.allowDragDrop === true || $scope.showChangeCount === true)) ? $scope.dropTarget.width + margin : 0)) / positionsWide) - margin;

                                    if ($scope.showRims === true) {
                                        rimWidth = positionWidth / 3;
                                    }

                                    if ($scope.showChains === true) {
                                        chainWidth = positionWidth;
                                    }
                                } else {
                                    if ($scope.zoomRect && $scope.zoomRect.positionsWide) {
                                        positionWidth = ($scope.maxWidth - margin) / (maxWheelPositions() + 1) - margin;

                                        //positionWidth = positionWidth - positionWidth % 1;

                                        $scope.zoomRect.left = ($scope.zoomRect.leftPos - 1) * (positionWidth + + margin) + margin / 2;
                                        $scope.zoomRect.width = (positionWidth + margin) * ($scope.zoomRect.positionsWide > maxWheelPositions() + 1 ? maxWheelPositions() + 1 : $scope.zoomRect.positionsWide);
                                    }
                                }

                                tyreWidth = positionWidth - rimWidth;
                                shaftWidth = positionWidth - margin;

                                setDropTargetPos();

                                drawSchematic();
                            }

                            function calculatePositionHeight() {
                                //Recalculate drop target height if chevrons are shown
                                if (vm.showVerticalChevrons) {
                                    setDropTargetPos();
                                }

                                if ($scope.positionsHigh) {
                                    positionHeight = ($scope.maxHeight - vm.chevronHeight) / $scope.positionsHigh - margin;
                                } else {

                                    if (!$scope.specification || !$scope.specification.axles) {
                                        return;
                                    }

                                    if ($scope.zoomRect && $scope.zoomRect.positionsHigh) {
                                        positionHeight = ($scope.maxHeight - vm.chevronHeight) / $scope.specification.axles.length - margin;

                                        $scope.zoomRect.top = ($scope.zoomRect.topAxle - 1) * (positionHeight + margin) + margin / 2;
                                        $scope.zoomRect.height = (positionHeight + margin) * ($scope.zoomRect.positionsHigh);
                                    }
                                }

                                tyreHeight = positionHeight;
                                rimHeight = positionHeight;
                                chainHeight = positionHeight;

                                drawSchematic();
                            }

                            $scope.$watchGroup(['maxWidth', 'positionsWide', 'allowDragDrop', 'positionView', 'showChangeCount', 'droptarget'],
                                                calculatePositionWidth);

                            $scope.$watch('specification', function () {
                                if ($scope.specification && $scope.specification.axles) {
                                    //Only show up  & down chevrons if there are more than 2 axles
                                    vm.showVerticalChevrons = $scope.specification.axles.length > 2;
                                    vm.chevronHeight = vm.showVerticalChevrons ? vm.bothChevrons : 0;

                                    calculatePositionHeight();
                                    calculatePositionWidth();

                                    drawSchematic();
                                }
                            });

                            $scope.$watchGroup(['maxHeight', 'positionsHigh'], function () {
                                if ($scope.specification && $scope.specification.axles) {
                                    calculatePositionHeight();
                                }
                            });

                            $scope.svgScale = 1;
                            vm.transformFunc = "scale(1)";

                            if ($scope.zoomRect) {
                                $scope.scrollTop = 0;
                                $scope.scrollLeft = 0;
                            } else {
                                $scope.scrollTop = vm.topMargin / 2;
                                $scope.scrollLeft = vm.leftMargin / 2;
                            }

                            $scope.$watch('showRims', function () {
                                if ($scope.showRims === true) {
                                    rimWidth = positionWidth / 3;
                                } else {
                                    rimWidth = 0;
                                }
                                tyreWidth = positionWidth - rimWidth;

                                drawSchematic();
                            });

                            $scope.$watch('showChains', function () {
                                if ($scope.showChains === true) {
                                    chainWidth = positionWidth;
                                } else {
                                    chainWidth = 0;
                                }
                                tyreWidth = positionWidth - rimWidth;

                                drawSchematic();
                            });

                            $scope.moveAxle = function (increment) {
                                if (!$scope.topAxle) {
                                    $scope.topAxle = 1;
                                }
                                var newTopAxle = $scope.topAxle + increment;

                                if (newTopAxle > 0) {
                                    if (newTopAxle <= $scope.specification.axles.length) {
                                        $scope.topAxle = newTopAxle;
                                    }
                                } else {
                                    $scope.topAxle = 1;
                                }
                            };

                            $scope.movePos = function (increment) {
                                if (!$scope.leftPos) {
                                    $scope.leftPos = 1;
                                }
                                var newLeftPos = $scope.leftPos + increment;

                                if (newLeftPos > 0) {
                                    if (newLeftPos <= maxWheelPositions()) {
                                        $scope.leftPos = newLeftPos;
                                    }
                                } else {
                                    $scope.leftPos = 1;
                                }
                            };

                            $scope.$watch('topAxle', function (newValue) {
                                if (newValue && $scope.specification) {
                                    if ($scope.topAxle > $scope.specification.frontAxles) {
                                        $scope.scrollTop = (margin + positionHeight) * ($scope.topAxle - 1) + frontRearGap + margin / 2;
                                    } else {
                                        $scope.scrollTop = (margin + positionHeight) * ($scope.topAxle - 1) + margin / 2;
                                    }
                                }
                            });

                            $scope.$watch('leftPos', function (newValue) {
                                if (newValue && $scope.specification) {
                                    $scope.scrollLeft = (margin + positionWidth) * (newValue - 1) + margin / 2;
                                }
                            });

                            vm.calculateSVGScale = function () {
                                if ($scope.scaleToFit === true) {
                                    var xScale = $scope.maxWidth / $scope.schematicWidth;
                                    var yScale = $scope.maxHeight / $scope.schematicHeight;

                                    if (xScale < yScale) {
                                        $scope.svgScale = xScale;
                                    } else {
                                        $scope.svgScale = yScale;
                                    }

                                    if ($scope.svgScale > 1) {
                                        $scope.svgScale = 1;
                                    }
                                } else {
                                    $scope.svgScale = 1;
                                }

                                vm.transformFunc = "scale(" + $scope.svgScale + ")";
                            };

                            $scope.$watch('scaleToFit', function () {
                                vm.calculateSVGScale();
                            });

                            vm.refresh = function () {
                                if ($scope.vehicleObject.specification && $scope.vehicleObject.specification.axles) {
                                    $scope.specification = $scope.vehicleObject.specification;
                                    // verify that the wheel positions are correct. 
                                    // This only needs to occur if the data came from he server in the first place
                                    dataBroker.adjustVehiclePositions($scope.vehicleObject.specification, $scope.vehicleObject);
                                    drawSchematic();
                                } else {
                                    $scope.processing = true;

                                    vm.searchCriteria.excludeReadings = $scope.excludeReadings;

                                    dataBroker.getSchematic(vm.searchCriteria, $scope.vehicleObject).then(function (response) {
                                        if (response) {
                                            $scope.specification = response;
                                            $scope.doRefresh = uuid();
                                            drawSchematic();
                                        } else {
                                            errorReporter.logError("No response trying to fetch schematic");
                                        }
                                    }, amtCommandQuerySvc.handleError).finally(function () {
                                        $scope.processing = false;
                                    });
                                }
                            };

                            vm.rectangles = [];

                            function addRectangle(left, top, width, height, text, cssClass, clickEvent?, position?, component?, opacity?, hoverEvent?, pattern?, textOffset?) {
                                var config = {
                                    x: left,
                                    y: top,
                                    width: width,
                                    height: height,
                                    text: text,
                                    cssClass: cssClass,
                                    textCssClass: (text && text.length > 0 ? "text-length-" + text.length : ""),
                                    position: position,
                                    click: clickEvent,
                                    component: component,
                                    opacity: opacity,
                                    pattern: pattern,
                                    hover: hoverEvent,
                                    rx: 0,
                                    ry: 0,
                                    selector: undefined,
                                    right: undefined,
                                    hideRect: cssClass === 'schematicWheelPositionLabel',
                                    clickDisabled: false,
                                    id: uuid(),
                                    textOffset: textOffset ? textOffset : 0
                                };

                                vm.rectangles.push(config);

                                return config;
                            }

                            function drawAxle(axle, axleIndex, axleCount) {
                                var top = vm.topMargin + ((positionHeight - axleHeight) / 2) + ((positionHeight + margin) * axleIndex);

                                if (axleIndex >= $scope.specification.frontAxles) {
                                    top = top + frontRearGap;
                                }

                                var left = vm.leftMargin;
                                var width = $scope.schematicWidth - (vm.leftMargin + margin);
                                var height = axleHeight;
                                var axleText = amtXlatSvc.xlat('equipment.axle');

                                addRectangle(left, top, width, height, axleText + " " + axle.label, "schematicAxleRect", null, null, null, null, null, null);

                                for (var w = 0; w < axle.positions.length; w++) {
                                    drawPosition(axle.positions[w], w, axleIndex, axle.positions.length);
                                }
                            }

                            function clearSelection() {
                                if ($scope.selectedPosition) {
                                    $scope.selectedPosition.selected = false;
                                    $scope.selectedPosition = undefined;
                                }

                                if ($scope.selectedComponent) {
                                    $scope.selectedComponent.selected = false;
                                    $scope.selectedComponent = undefined;
                                }

                                var currentRect = $scope.currentRectSelected;
                                if (currentRect && currentRect.position && currentRect.selector) {
                                    currentRect.position[currentRect.selector + "Selected"] = false;
                                }
                            }

                            $scope.doubleClick = function (rect) {
                                if (rect.component) {
                                    if (vm.canOpenFittedComponents) showComponentDetails(rect.component);
                                }
                            };

                            var clickTimeoutPromise;
                            var clickedRect;

                            $scope.highlightPosition = function (rect) {
                                clickTimeoutPromise = undefined;
                                clickedRect = undefined;

                                // clear the current selection
                                clearSelection();

                                $scope.selectedComponent = rect.component;
                                $scope.selectedPosition = rect.position;

                                if (rect.component) {
                                    // highligh the component
                                    rect.component.selected = true;

                                    //call the change function if defined
                                    if ($scope.tyreSelected) {
                                        //If the new selection is the same as the old clear the selection
                                        if (vm.currentSelectedComponent != undefined && vm.currentSelectedComponent.equipmentId === rect.component.equipmentId) {
                                            vm.currentSelectedComponent = undefined;
                                            clearSelection();
                                        } else {
                                            vm.currentSelectedComponent = rect.component;
                                        }

                                        //Call the event. 
                                        $scope.tyreSelected({ component: rect.component, position: rect.position?.label });
                                    }
                                } else {
                                    // highlight the position
                                    if (rect.position) {
                                        rect.position.selected = true;
                                    }
                                }

                                $scope.$emit('PositionClicked', rect.position, rect.selector, rect.selector);
                            };

                            $scope.currentRect = undefined;

                            vm.$onInit = function() {
                                $scope.$on("SCHEMATIC_SELECTION", (evt: ng.IAngularEvent, posId: guid, sel: string) => {
                                    let rect = posId && sel ? vm.rectangles.find(r => r.position && r.position.id == posId && r.selector == sel) : null;
                                    if (rect)
                                        $scope.highlightSegment(rect);
                                    else
                                        clearSelection();
                                });

                                $scope.$on("ReadingGridClickedInt", (evt: any, position: number, selector: string) => {
                                    let selectedRect = vm.rectangles.find(r => r.selector === selector && r.position.label === position.toString());
                                    if (selectedRect) {
                                        $scope.highlightSegment(selectedRect);
                                    } else {
                                        clearSelection();
                                    }
                                });
                            }

                            $scope.highlightSegment = function (rect) {
                                if (rect && rect.position && rect.selector) {

                                    var currentRect = $scope.currentRectSelected;

                                    if (currentRect) {
                                        currentRect.position[currentRect.selector + "Selected"] = false;

                                        if (currentRect.component) {
                                            currentRect.component.selected = false;
                                        } else {                                            
                                            if (currentRect.position) {
                                                currentRect.position.selected = false;
                                            }
                                        }
                                    }

                                    // clear the current selection
                                    clearSelection();

                                    $scope.selectedComponent = rect.component;
                                    $scope.selectedPosition = rect.position;
                                    rect.position[rect.selector + "Selected"] = true;
                                    $scope.currentRectSelected = rect;
                                    $timeout();
                                }
                            };

                            function getPosition(rect) {
                                if (rect.component) {
                                    for (var a in $scope.specification.axles) {
                                        var axle = $scope.specification.axles[a];

                                        // find the position
                                        for (var p in axle.positions) {
                                            var position = axle.positions[p];

                                            // if the position has fitments
                                            if (position.fitments) {
                                                var fitmentIndex = position.fitments.indexOf(rect.component);

                                                if (fitmentIndex > -1) {
                                                    return position;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            function getAxle(pos) {
                                for (var a = 0; a < $scope.specification.axles.length; a++) {
                                    var axle = $scope.specification.axles[a];

                                    for (var p = 0; p < axle.positions.length; p++) {
                                        if (axle.positions[p] == pos) {
                                            return axle;
                                        }
                                    }
                                }
                            }

                            function canRemove(component, outerPosition) {
                                var valid = true;

                                for (var c = 0; c < outerPosition.fitments.length; c++) {
                                    var outerFitment = outerPosition.fitments[c];
                                    switch (outerFitment.type.toLowerCase()) {
                                        case "tyre":
                                            // can't remove a component if there is a TYRE on an outer pos
                                            valid = false;
                                            break;
                                        case "rim":
                                            // can only remove a TYRE over a rim if the rim is flagged as double gutter
                                            valid = valid && component.type.toLowerCase() === 'tyre' && outerFitment.isDoubleGutter;
                                            break;
                                    }
                                }

                                return valid;
                            }

                            function canDrag(rect) {
                                if (!rect.component) {
                                    return false;
                                }

                                var position = getPosition(rect);
                                if (!position) {
                                    console.error("Position of rect was not found.");
                                    console.warn(rect);
                                    // this means there are no fitments here. (but it has a component associated ? )
                                    // OK so something has gone wrong. Delete this rect perhaps
                                    rect.component = null;
                                    return false;
                                }

                                var axle = getAxle(position);

                                //TODO: loop over the other (more-outer) positions on this axle and make sure those positions are empty

                                var posIndex = axle.positions.indexOf(position);
                                if (posIndex < axle.positions.length / 2) {
                                    // left
                                    for (var lp = posIndex - 1; lp > -1; lp--) {
                                        if (!canRemove(rect.component, axle.positions[lp])) {
                                            return false;
                                        }
                                    }
                                } else {
                                    // right
                                    for (var rp = posIndex + 1; rp < axle.positions.length; rp++) {
                                        if (!canRemove(rect.component, axle.positions[rp])) {
                                            return false;
                                        }
                                    }
                                }

                                var disallowedTypes = [];
                                if (rect.component.type === 'Rim' || rect.component.type === 'Tyre') {
                                    disallowedTypes.push('Chain');
                                }

                                if (disallowedTypes.length > 0) {
                                    for (var f in position.fitments) {
                                        if (disallowedTypes.indexOf(position.fitments[f].type) > -1) {
                                            return false;
                                        }
                                    }
                                }

                                return true;
                            }

                            $scope.mouseEnter = function (rect) {
                                if (rect && $scope.positionView === "events") {
                                    rect.canDrag = canDrag(rect);
                                }
                            }

                            function getRect(x, y) {
                                for (var r = vm.rectangles.length - 1; r > -1; r--) {
                                    var rect = vm.rectangles[r];

                                    if (x >= rect.x && y >= rect.y && x <= (rect.x + rect.width) && y <= (rect.y + rect.height)) {

                                        if (rect.cssClass !== 'schematicWheelPositionLabel') {
                                            return rect;
                                        }
                                    }
                                }
                            }

                            function pageToClient(touch) {
                                if (touch) {
                                    var rect = touch.target.getBoundingClientRect();

                                    return {
                                        x: touch.clientX - rect.left,
                                        y: touch.clientY - rect.top
                                    };
                                }
                            }

                            function coordsInRect(coords, rect) {
                                if (coords.x >= rect.left && coords.x <= rect.left + rect.width) {
                                    if (coords.y >= rect.top && coords.y <= rect.top + rect.height) {
                                        return true;
                                    }
                                }
                            }

                            function setZoom(x, y) {
                                if (x >= 0) {
                                    if (x + $scope.zoomRect.width <= $scope.maxWidth) {
                                        $scope.zoomRect.left = x;
                                    } else {
                                        $scope.zoomRect.left = $scope.maxWidth - $scope.zoomRect.width;
                                    }
                                } else {
                                    $scope.zoomRect.left = 0;
                                }

                                if (y >= 0) {
                                    if (y + $scope.zoomRect.height <= $scope.maxHeight) {
                                        $scope.zoomRect.top = y;
                                    } else {
                                        $scope.zoomRect.top = $scope.maxHeight - $scope.zoomRect.height;
                                    }
                                } else {
                                    $scope.zoomRect.top = 0;
                                }
                            }

                            $scope.mouseDown = function (e) {
                                $scope.resetDrag();

                                var coords = normaliseCoordinates(e);

                                if ($scope.zoomRect && !$scope.zoomRect.hidden) {
                                    if (!coordsInRect(coords, $scope.zoomRect)) {
                                        setZoom(coords.x - $scope.zoomRect.width / 2, coords.y - $scope.zoomRect.height / 2);
                                    }

                                    $scope.snapRect = {
                                        x: $scope.zoomRect.left,
                                        y: $scope.zoomRect.top
                                    };

                                    $scope.dragContext = {
                                        origin: {
                                            x: $scope.zoomRect.left,
                                            y: $scope.zoomRect.top,
                                        },
                                        start: new Date(),
                                        x: coords.x,
                                        y: coords.y,
                                        rect: $scope.zoomRect
                                    };

                                    return;
                                }

                                $scope.currentRect = getRect(coords.x + $scope.scrollLeft, coords.y + $scope.scrollTop);

                                if ($scope.currentRect && !$scope.currentRect.clickDisabled) {
                                    $scope.mouseEnter($scope.currentRect);
                                    $scope.highlightSegment($scope.currentRect);
                                }

                                if (!$scope.currentRect || !$scope.currentRect.component || $scope.currentRect.canDrag !== true || $scope.allowDragDrop !== true) {
                                    //console.log("nothing to drag");
                                    return;
                                }

                                var linkTypes = [];

                                if ($scope.currentRect.component.type === 'Rim') {
                                    linkTypes.push('Tyre');
                                }

                                var linkedComponents = [];

                                // if we're dragging a RIM, then remove all the other components too
                                if (linkTypes.length > 0) {
                                    var position = getPosition($scope.currentRect);

                                    // if the position has fitments
                                    if (position && position.fitments) {
                                        var fitmentIndex = position.fitments.indexOf($scope.currentRect.component);

                                        // if the component we're dragging is fitted to this position
                                        if (fitmentIndex > -1) {
                                            // find the other components
                                            for (var f in position.fitments) {
                                                if (linkTypes.indexOf(position.fitments[f].type) > -1) {
                                                    // find the rectangle for the fitment
                                                    for (var r in vm.rectangles) {
                                                        if (vm.rectangles[r].component === position.fitments[f] && vm.rectangles[r] != $scope.currentRect) {
                                                            vm.rectangles[r].dragging = true;
                                                            linkedComponents.push(vm.rectangles[r]);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                $scope.currentRect.dragging = true;

                                $scope.dragContext = {
                                    origin: {
                                        x: $scope.currentRect.x,
                                        y: $scope.currentRect.y
                                    },
                                    start: new Date(),
                                    x: coords.x,
                                    y: coords.y,
                                    rect: $scope.currentRect,
                                    linkedComponents: linkedComponents
                                };

                                // highlight other positions that can accept the component(s) being dragged
                                drawPositionDropTargets($scope.dragContext);
                            };

                            function overPosition(x, y) {
                                for (var p in $scope.positionTargets) {
                                    var position = $scope.positionTargets[p];
                                    if (x >= position.x && x <= (position.x + position.width)) {
                                        if (y >= position.y && y <= (position.y + position.height)) {
                                            position.hover = true;
                                            return position;
                                        }
                                    }
                                    position.hover = false;
                                }
                            }

                            function normaliseCoordinates(e) {
                                if (e.touches) {
                                    if (e.touches.length > 0) {
                                        return pageToClient(e.touches[0]);
                                    }
                                } else {
                                    return {
                                        x: e.offsetX,
                                        y: e.offsetY
                                    };
                                }
                            }

                            function leftButton(e) {
                                if (e.buttons === 1) {
                                    return true;
                                }
                            }

                            $scope.mouseMove = function (e) {
                                if ($scope.dragContext) {

                                    $scope.dragContext.moved = true;

                                    if (!leftButton(e) && !e.touches) {
                                        // no mouse buttons down, cancel drag
                                        $timeout(function () {
                                            $scope.resetDrag();
                                        });

                                        return;
                                    }

                                    var coords = normaliseCoordinates(e);

                                    // timeout here because it looks like mouse move is blocking the digest cycle
                                    $timeout(function (coords) {
                                        if ($scope.zoomRect && !$scope.zoomRect.hidden) {
                                            if ($scope.dragContext) {
                                                var xChange = coords.x - $scope.dragContext.x;
                                                var yChange = coords.y - $scope.dragContext.y;

                                                setZoom($scope.zoomRect.left + xChange, $scope.zoomRect.top + yChange);

                                                $scope.snapRect.x = $scope.zoomRect.left - (($scope.zoomRect.left + positionWidth / 2) % (positionWidth + margin) - positionWidth / 2) + vm.leftMargin / 2;
                                                $scope.snapRect.y = $scope.zoomRect.top - (($scope.zoomRect.top + positionHeight / 2) % (positionHeight + margin) - positionHeight / 2) + vm.topMargin / 2;

                                                $scope.dragContext.x = coords.x;
                                                $scope.dragContext.y = coords.y;
                                            }

                                        } else {

                                            $scope.dropTarget.hover = overDropTarget(coords.x, coords.y);

                                            if ($scope.dragContext) {
                                                var xChange = coords.x - $scope.dragContext.x;
                                                var yChange = coords.y - $scope.dragContext.y;

                                                if ($scope.dragContext.linkedComponents && $scope.dragContext.linkedComponents.length > 0) {
                                                    for (var lc in $scope.dragContext.linkedComponents) {
                                                        var linkedComponent = $scope.dragContext.linkedComponents[lc];

                                                        linkedComponent.x += xChange;
                                                        linkedComponent.y += yChange;
                                                    }
                                                }

                                                $scope.dragContext.rect.x += xChange;
                                                $scope.dragContext.rect.y += yChange;

                                                $scope.dragContext.x = coords.x;
                                                $scope.dragContext.y = coords.y;
                                            }
                                        }

                                    }, 0, true, coords);

                                } else {
                                    if (leftButton(e)) {
                                        var coords = normaliseCoordinates(e);

                                        var rect = getRect(coords.x + $scope.scrollLeft, coords.y + $scope.scrollTop);

                                        if (rect) {
                                            $scope.mouseEnter(rect);
                                        }
                                    }

                                    $scope.resetDrag();
                                }
                            }

                            function removeRect(rect) {
                                var i = vm.rectangles.indexOf(rect);

                                if (i > -1) {
                                    vm.rectangles.splice(i, 1);
                                }
                            }

                            function removePositionDropTargets() {
                                if ($scope.positionTargets) {
                                    for (var r = 0; r < $scope.positionTargets.length; r++) {
                                        removeRect($scope.positionTargets[r]);
                                    }
                                }

                                $scope.positionTargets = [];
                            }

                            $scope.resetDrag = function () {
                                removePositionDropTargets();

                                if ($scope.dragContext) {
                                    $scope.dragContext.rect.dragging = false;

                                    if ($scope.dragContext.linkedComponents) {
                                        for (var l = 0; l < $scope.dragContext.linkedComponents.length; l++) {
                                            $scope.dragContext.linkedComponents[l].x -= $scope.dragContext.rect.x - $scope.dragContext.origin.x;
                                            $scope.dragContext.linkedComponents[l].y -= $scope.dragContext.rect.y - $scope.dragContext.origin.y;
                                            $scope.dragContext.linkedComponents[l].dragging = false;
                                        }
                                    }

                                    $scope.dragContext.rect.x = $scope.dragContext.origin.x;
                                    $scope.dragContext.rect.y = $scope.dragContext.origin.y;

                                    $scope.dragContext = undefined;
                                }

                                $timeout();
                            }

                            function overDropTarget(x, y) {
                                if (x >= $scope.dropTarget.left && x <= ($scope.dropTarget.left + $scope.dropTarget.width)) {

                                    //TODO: might have to handle scrolled schematic
                                    if (y >= $scope.dropTarget.top && y <= ($scope.dropTarget.top + $scope.dropTarget.height)) {
                                        return true;
                                    }
                                }

                                return false;
                            }

                            async function dropEvent(toPosition) {
                                if (!$scope.dragContext || !$scope.removeComponents) {
                                    $scope.resetDrag();
                                    return;
                                }

                                let componentsToRemove = [];
                                componentsToRemove.push($scope.dragContext.rect.component);
                                if ($scope.dragContext.linkedComponents) {
                                    for (var l = 0; l < $scope.dragContext.linkedComponents.length; l++) {
                                        componentsToRemove.push($scope.dragContext.linkedComponents[l].component);
                                    }
                                }
                                let removeData = {
                                    components: componentsToRemove, // array of components being removed
                                    fromPosition: getPosition($scope.dragContext.rect), // position the component is being removed from
                                    toPosition: toPosition,
                                    change: 0, // change count
                                    errors: [] // array of error messages
                                };

                                try {
                                    await $scope.removeComponents(removeData);
                                } finally {
                                    $scope.resetDrag();
                                    vm.refresh();
                                }
                            };

                            function removeComponents() {
                                dropEvent(null);
                            }

                            function moveComponents(toPosition) {
                                dropEvent(toPosition);
                            }

                            function clickOrDrag() {
                                if (!$scope.dragContext) {
                                    return "click";
                                }

                                var now = new Date();

                                if (now.getTime() - $scope.dragContext.start.getTime() < 100) {
                                    return "click";
                                }

                                //if (Math.abs($scope.dragContext.origin.x - $scope.dragContext.rect.x) < 1 && Math.abs($scope.dragContext.origin.y - $scope.dragContext.rect.y) > 1) {
                                //    // didn't move far, assume click?
                                //    return "click";
                                //}

                                return "drag";
                            }

                            $scope.mouseUp = function (e) {
                                if ($scope.dragContext) {
                                    if ($scope.currentRect && clickOrDrag() === "click") {
                                        // call the click event
                                        if ($scope.currentRect.click) {
                                            $scope.currentRect.click($scope.currentRect);
                                        }
                                    }

                                    var coords = undefined;

                                    if (e.touches) {
                                        // convert the touch coordinates into offsetX, offsetY coordinates
                                        coords = {
                                            x: $scope.dragContext.x,
                                            y: $scope.dragContext.y
                                        };
                                    } else {
                                        coords = normaliseCoordinates(e);
                                    }

                                    if ($scope.zoomRect && !$scope.zoomRect.hidden) {

                                        $timeout(function () {
                                            // snap to position
                                            $scope.zoomRect.left -= ($scope.zoomRect.left + positionWidth / 2) %
                                                (positionWidth + margin) -
                                                positionWidth / 2 -
                                                vm.leftMargin / 2;
                                            $scope.zoomRect.top -= ($scope.zoomRect.top + positionHeight / 2) %
                                                (positionHeight + margin) -
                                                positionHeight / 2 -
                                                vm.topMargin / 2;

                                            $scope.zoomRect.leftPos =
                                                ($scope.zoomRect.left - vm.leftMargin / 2) / (positionWidth + margin) +
                                                1;
                                            $scope.zoomRect.topAxle =
                                                ($scope.zoomRect.top - vm.topMargin / 2) / (positionHeight + margin) +
                                                1;

                                            $scope.dragContext = undefined;
                                        });

                                        return;
                                    }

                                    var positionTarget = overPosition(coords.x, coords.y);

                                    if (positionTarget) {
                                        // move the components to another position
                                        moveComponents(positionTarget.position);
                                        return;
                                    }

                                    // check if mouse is over REMOVE drop target
                                    if (!overDropTarget(coords.x, coords.y)) {
                                        // dropped outside drop target, reset back onto position
                                        $scope.resetDrag();
                                    } else {
                                        $timeout(function () {
                                            // remove the components from the vehicle
                                            removeComponents();
                                        });
                                    }
                                } else {
                                    if ($scope.currentRect) {
                                        // call the click event
                                        if ($scope.currentRect.click) {
                                            $scope.currentRect.click($scope.currentRect);
                                        }
                                    }
                                }

                            }

                            $scope.positionInnerClicked = function (rect) {
                                if (!rect.clickDisabled) {
                                    $scope.highlightSegment(rect);
                                    $scope.$emit('SchematicClicked', rect.position, rect.selector);
                                }
                            };

                            $scope.positionOuterClicked = function (rect) {
                                if (!rect.clickDisabled) {
                                    $scope.highlightSegment(rect);
                                    $scope.$emit('SchematicClicked', rect.position, rect.selector);
                                }
                            };

                            $scope.positionCenterClicked = function (rect) {
                                if (!rect.clickDisabled) {
                                    $scope.highlightSegment(rect);
                                    $scope.$emit('SchematicClicked', rect.position, rect.selector);
                                }
                            };

                            $scope.positionClicked = function (rect) {
                                if (!rect.clickDisabled) {
                                    if (clickTimeoutPromise === undefined || rect !== clickedRect) {
                                        clickedRect = rect;
                                        clickTimeoutPromise = $timeout($scope.highlightPosition, 300, true, clickedRect);
                                    } else {
                                        $timeout.cancel(clickTimeoutPromise);
                                        clickTimeoutPromise = undefined;
                                        $scope.doubleClick(rect);
                                    }
                                }
                            };

                            $scope.labelClicked = function (rect) {
                                if ($scope.onLabelSelected) {
                                    $scope.onLabelSelected({ position: rect.position });
                                }
                            };

                            function showComponentDetails(component) {
                                WindowFactory.openItem({
                                    component: 'component-details',
                                    caption: amtXlatSvc.xlat('equipment.open' + component.type, component.serialNumber),
                                    windowRelatedRecordId: component.equipmentId,
                                    initParams: {
                                        equipmentId: component.equipmentId,
                                        siteId: component.siteId,
                                        serialNumber: component.serialNumber,
                                        componentType: component.type
                                    },
                                    width: 800,
                                    height: 850
                                });
                            }

                            function drawPosition(position, positionIndex, axleIndex, axlePositionCount) {
                                /*jshint maxcomplexity:false */
                                var top = vm.topMargin + ((positionHeight + margin) * axleIndex);

                                var tyreSerialNumber = null;
                                var rimSerialNumber = null;
                                var chainSerialNumber = null;

                                var rimFitment;
                                var tyreFitment;
                                var chainFitment;

                                if (axleIndex >= $scope.specification.frontAxles) {
                                    top = top + frontRearGap;
                                }

                                if (position.fitments) {
                                    for (var f = 0; f < position.fitments.length; f++) {
                                        var fitment = position.fitments[f];

                                        if (fitment.type === 'Rim') {
                                            rimFitment = f;
                                            if ($scope.showPositionLabels !== true) {
                                                rimSerialNumber = fitment.serialNumber;
                                            }
                                        }

                                        if (fitment.type === 'Tyre') {
                                            tyreFitment = f;
                                            if ($scope.showPositionLabels !== true) {
                                                tyreSerialNumber = fitment.serialNumber;
                                            }
                                        }

                                        if (fitment.type === 'Chain') {
                                            chainFitment = f;
                                            if ($scope.showPositionLabels !== true) {
                                                chainSerialNumber = fitment.serialNumber;
                                            }
                                        }
                                    }
                                }

                                var inverted = positionIndex >= axlePositionCount / 2;
                                var r;

                                var left = inverted === true ? $scope.schematicWidth - ((positionWidth + margin) * (axlePositionCount - positionIndex)) :
                                    vm.leftMargin + ((positionWidth + margin) * positionIndex);

                                switch ($scope.positionView) {
                                    case "readings": // field survey readings & maintenance session readings
                                        if (tyreFitment === undefined) { // !tyreFitment doesn't work here because 0 evaluates to false and it thinks there is nothing fitted to pos 0.
                                            // empty for readings

                                            if (chainFitment == undefined) {
                                                r = addRectangle(left,
                                                    top + positionHeight - tyreHeight,
                                                    positionWidth,
                                                    tyreHeight,
                                                    "",
                                                    "schematicWheelPositionRectFieldSurvey noTyre",
                                                    null,
                                                    position);
                                            } else {
                                                // just render the chain
                                                r = addRectangle(left,
                                                    top + positionHeight - tyreHeight,
                                                    positionWidth,
                                                    tyreHeight,
                                                    "",
                                                    "schematicWheelPositionRectFieldSurvey noTyre",
                                                    null,
                                                    position,
                                                    null,
                                                    null,
                                                    null,
                                                    'chainPattern');
                                            }

                                        } else {
                                            // tyre fitted, show the reading segments

                                            // left half
                                            r = addRectangle(left,
                                                top + positionHeight - tyreHeight,
                                                positionWidth / 2,
                                                tyreHeight,
                                                null,
                                                "schematicWheelPositionRectFieldSurvey " +
                                                (tyreFitment !== undefined ? "fittedTyre" : "noTyre"),
                                                (inverted ? $scope.positionInnerClicked : $scope.positionOuterClicked),
                                                position,
                                                null,
                                                null,
                                                $scope.mouseDown);

                                            r.selector = inverted ? "rtd2" : "rtd1";
                                            r.position[r.selector + "Selected"] = null;

                                            if ($scope.disabledSegments && $scope.disabledSegments.indexOf(r.selector) > -1) {
                                                r.clickDisabled = true;
                                            }

                                            // right half
                                            r = addRectangle(left + positionWidth / 2,
                                                top + positionHeight - tyreHeight,
                                                positionWidth / 2,
                                                tyreHeight,
                                                null,
                                                "schematicWheelPositionRectFieldSurvey " +
                                                (tyreFitment !== undefined ? "fittedTyre" : "noTyre"),
                                                (inverted ? $scope.positionOuterClicked : $scope.positionInnerClicked),
                                                position,
                                                null,
                                                null,
                                                $scope.mouseDown);

                                            r.selector = inverted ? "rtd1" : "rtd2";
                                            r.position[r.selector + "Selected"] = null;

                                            if ($scope.disabledSegments && $scope.disabledSegments.indexOf(r.selector) > -1) {
                                                r.clickDisabled = true;
                                            }

                                            // center - tyre
                                            r = addRectangle(left + positionWidth / 3,
                                                top + positionHeight - tyreHeight,
                                                positionWidth / 3,
                                                tyreHeight,
                                                tyreSerialNumber,
                                                "schematicWheelPositionRectFieldSurvey center",
                                                $scope.positionCenterClicked,
                                                position,
                                                null,
                                                null,
                                                $scope.mouseDown);

                                            r.selector = "pressure";
                                            r.position[r.selector + "Selected"] = null;

                                            if ($scope.disabledSegments && $scope.disabledSegments.indexOf(r.selector) > -1) {
                                                r.clickDisabled = true;
                                            }

                                            if (chainFitment !== undefined) {
                                                // add chain crosshatch to the tyre segment

                                                if ($scope.showChains === true) {
                                                    r = addRectangle(left + positionWidth / 3,
                                                        top + positionHeight - tyreHeight,
                                                        positionWidth / 3,
                                                        tyreHeight,
                                                        chainSerialNumber,
                                                        "schematicWheelPositionRectFieldSurvey " + (chainFitment !== undefined ? "fittedChain" : "noChain"),
                                                        $scope.positionCenterClicked,
                                                        position,
                                                        null,
                                                        null,
                                                        $scope.mouseDown,
                                                        'chainPattern');

                                                    r.selector = "pressure";
                                                    r.position[r.selector + "Selected"] = null;
                                                }

                                            }
                                        }

                                        if ($scope.showPositionLabels === true) {
                                            // add the position label
                                            addRectangle(left, top, positionWidth, tyreHeight,
                                                $scope.showPositionAsReading ? position.readingSequence : position.label,
                                                "schematicWheelPositionLabel", null, position);
                                        }

                                        break;

                                    case "fitments": // vehicle details
                                    case "fitted": // mainteance fitted tab
                                    case "events": // maintenance events tab
                                        var l;

                                        //rim
                                        if ($scope.showRims === true) {
                                            l = inverted === true ? left : left + positionWidth - rimWidth;
                                            var rim = addRectangle(l, top + (positionHeight - rimHeight) / 2, rimWidth, rimHeight, rimSerialNumber,
                                                "schematicWheelPositionRect " + (rimFitment !== undefined ? "fittedRim" : "noRim"),
                                                $scope.positionClicked, position, position.fitments[rimFitment], null, $scope.mouseDown);
                                            if (inverted === true) {
                                                rim.right = true;
                                            }
                                            rim.selector = "rim." + (rimFitment !== undefined ? "fitted" : "empty");
                                        }

                                        // tyre
                                        l = inverted === true ? left + rimWidth : left;
                                        var tyreRect = addRectangle(l, top + (positionHeight - tyreHeight) / 2, tyreWidth, tyreHeight, tyreSerialNumber,
                                            "schematicWheelPositionRect " + (tyreFitment !== undefined ? "fittedTyre" : "noTyre"),
                                            $scope.positionClicked, position, position.fitments ? position.fitments[tyreFitment] : null, null, $scope.mouseDown);

                                        // chain on top
                                        if ($scope.showChains === true) {
                                            if (chainFitment !== undefined) {
                                                addRectangle(left,
                                                    top + (positionHeight - chainHeight) / 2,
                                                    chainWidth,
                                                    chainHeight,
                                                    chainSerialNumber,
                                                    "schematicWheelPositionRect " +
                                                    (chainFitment !== undefined ? "fittedChain" : "noChain"),
                                                    $scope.positionClicked,
                                                    position,
                                                    position.fitments[chainFitment],
                                                    null,
                                                    $scope.mouseDown,
                                                    'chainPattern');
                                            }

                                            // OR-7194: enable clicking on the tyre to fit a chain
                                            tyreRect.selector = "chain." + (chainFitment !== undefined ? "fitted" : "empty");
                                        } else {
                                            tyreRect.selector = "tyre." + (tyreFitment !== undefined ? "fitted" : "empty");
                                        }

                                        if ($scope.showPositionLabels === true) {
                                            // add the position label
                                            addRectangle(left, top, positionWidth, tyreHeight,
                                                $scope.showPositionAsReading ? position.readingSequence : position.label,
                                                "schematicWheelPositionLabel", null, position);
                                        }

                                        break;
                                    case "labels":
                                        addRectangle(left, top, positionWidth, tyreHeight,
                                            position.label || "Set Label",
                                            "schematicWheelPositionLabel setlabel", $scope.labelClicked, position);
                                        break;
                                    case "labelsReadonly":
                                        addRectangle(left, top, positionWidth, tyreHeight,
                                            position.label || "Set Label",
                                            "schematicWheelPositionLabel viewlabel", null, position);
                                        break;
                                }
                            }

                            $scope.positionTargets = [];

                            function positionCanAcceptDrop(position, dragContext) {
                                // assemblies only
                                if (position.fitments.length === 0 && // has nothing currently
                                    dragContext.rect.component.type === "Rim" && // dragging a rim
                                    dragContext.linkedComponents &&
                                    dragContext.linkedComponents.length !== 0 // and has a tyre along with it 
                                ) {
                                    return true;
                                } else if (!allowMoveOtherComponents) {
                                    return false; // for now - assemblies only
                                }

                                if (position.fitments.length === 0) {
                                    return true;
                                }

                                for (var f = 0; f < position.fitments.length; f++) {
                                    // return false if the position already has the source component
                                    if (position.fitments[f].type == dragContext.rect.component.type) {
                                        return false;
                                    }

                                    // or a linked component
                                    for (var l = 0; l < dragContext.linkedComponents.length; l++) {
                                        if (position.fitments[f].type == dragContext.linkedComponents[l].component.type) {
                                            return false;
                                        }
                                    }
                                }

                                return true;
                            }

                            function drawPositionDropTargets(dragContext) {
                                $scope.positionTargets = [];

                                for (var a = 0; a < $scope.specification.axles.length; a++) {
                                    var top = vm.topMargin + ((positionHeight + margin) * a);

                                    var axle = $scope.specification.axles[a];

                                    if (a >= $scope.specification.frontAxles) {
                                        top = top + frontRearGap;
                                    }

                                    var left = 0;

                                    for (var p = 0; p < axle.positions.length; p++) {
                                        var position = axle.positions[p];

                                        // check if the position can accept the components being dragged
                                        if (positionCanAcceptDrop(position, dragContext)) {

                                            // add a rect to allow dropping, and add the rect to the drop targets
                                            if (p >= axle.positions.length / 2) {
                                                // right
                                                left = $scope.schematicWidth - ((positionWidth + margin) * (axle.positions.length - p));
                                            } else {
                                                // left
                                                left = margin + ((positionWidth + margin) * p);
                                            }

                                            $scope.positionTargets.push(
                                                addRectangle(left, top, positionWidth, positionHeight, $scope.showPositionAsReading ? position.readingSequence : position.label, "schematicWheelPositionRect empty", null, position)
                                            );
                                        }
                                    }
                                }
                            }

                            function maxWheelPositions() {
                                if (!$scope.specification || !$scope.specification.axles) {
                                    return 0;
                                }

                                var w = 0;

                                for (let axles of $scope.specification.axles) {
                                    w = Math.max(w, axles.positions.length);
                                }

                                return w;
                            }

                            var drawSchematicWorker;
                            function drawSchematic() {
                                // only draw the schematic once
                                if (drawSchematicWorker !== undefined) {
                                    // cancel the pending request
                                    $timeout.cancel(drawSchematicWorker);
                                }

                                // set a background thread to draw the schematic.
                                drawSchematicWorker = $timeout(drawSchematicBackground, 100, true);
                            }

                            function drawSchematicBackground() {
                                drawSchematicWorker = undefined;

                                vm.rectangles = [];

                                if (!$scope.specification || !$scope.specification.axles) {
                                    // nothing to draw yet.
                                    return;
                                }

                                // calculate the total width of the schematic
                                $scope.schematicWidth = ((positionWidth + margin) * maxWheelPositions()) + shaftWidth + (margin * 3);

                                // calculate the total height of the schematic
                                $scope.schematicHeight = ((positionHeight + margin) * $scope.specification.axles.length) + frontRearGap + margin;

                                // calculate the view width
                                $scope.scrollWidth = $scope.schematicWidth + (($scope.allowDragDrop || $scope.showChangeCount) ? $scope.dropTarget.width + margin : 0);

                                vm.chevronWidth = vm.showHorizontalChevrons ? vm.bothChevrons : 0;

                                setDropTargetPos();

                                if ($scope.schematicHeight > $scope.maxHeight) {
                                    $scope.scrollHeight = $scope.schematicHeight;
                                } else {
                                    $scope.scrollHeight = $scope.maxHeight;
                                }

                                if ($scope.maxWidth === 0) {
                                    $scope.maxWidth = $scope.schematicWidth;
                                }
                                axleWidth = $scope.schematicWidth - margin * 2;

                                $scope.schematicHasScrollbar = $scope.schematicHeight > ($scope.maxHeight + 30) || $scope.schematicWidth > $scope.maxWidth;

                                vm.calculateSVGScale();

                                var centre = $scope.schematicWidth / 2;

                                if ($scope.specification.axles.length > 1) {
                                    // centre shaft
                                    addRectangle(
                                        centre - axleHeight / 2,
                                        vm.topMargin + positionHeight / 2,
                                        axleHeight,
                                        ($scope.specification.axles.length - 1) * (positionHeight + margin) + frontRearGap,
                                        "",
                                        "schematicShaft"
                                    );
                                }

                                for (var a = 0; a < $scope.specification.axles.length; a++) {
                                    // draw the axle
                                    drawAxle($scope.specification.axles[a], a, $scope.specification.axles.length);
                                }
                            }

                            $scope.$watch('vehicleObject', function () {
                                if ($scope.vehicleObject) {
                                    $scope.specification = $scope.vehicleObject.schematic;

                                    if ($scope.specification === undefined) {
                                        vm.searchCriteria = {
                                            vehicleId: $scope.vehicleObject.id,
                                            includeFitments: $scope.includeFitments,
                                            date: $scope.schematicDate,
                                            useReadingSequence: $scope.useReadingSequence
                                        };
                                        vm.refresh();
                                    }
                                }
                            });

                            $scope.$watch('schematicDate', function (oldValue, newValue) {

                                if ($scope.vehicleObject) {
                                    if (oldValue !== newValue) {
                                        vm.searchCriteria = {
                                            vehicleId: $scope.vehicleObject.id,
                                            includeFitments: $scope.includeFitments,
                                            date: $scope.schematicDate,
                                            useReadingSequence: $scope.useReadingSequence
                                        };

                                        vm.refresh();
                                    }
                                }
                            });

                            $scope.$watch('disabledSegments', function () {
                                if ($scope.positionView == "readings" && vm.rectangles) {
                                    for (var i = 0; i < vm.rectangles.length; i++) {

                                        vm.rectangles[i].clickDisabled = $scope.disabledSegments.indexOf(vm.rectangles[i].selector) > -1;

                                        if (vm.rectangles[i].clickDisabled && vm.rectangles[i].selector) {
                                            vm.rectangles[i].position[vm.rectangles[i].selector + "Selected"] = false;
                                        }
                                    }
                                }
                            });

                            $scope.$watch(function () {
                                var chevrons = document.getElementsByClassName("chevron");
                                return angular.element(chevrons).is(":visible");
                            },
                                function (newValue) { $scope.chevronIsVisible = newValue; });
                        }]
            };
        }]);
