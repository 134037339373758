/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_dropdowns_1 = require("@progress/kendo-angular-dropdowns");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
/**
 * Represents a component which accommodates the filter operators.
 */
var FilterCellOperatorsComponent = /** @class */ (function () {
    function FilterCellOperatorsComponent(localization) {
        this.localization = localization;
        this.clearText = 'Clear';
        /**
         * The filter operators that will be displayed.
         */
        this.operators = [];
        /**
         * Determines if the list of operators will be displayed.
         * @type {boolean}
         */
        this.showOperators = true;
        /**
         * Fires when the operator is selected.
         * @type {EventEmitter<string>}
         */
        this.valueChange = new core_1.EventEmitter();
        /**
         * Fires when the **Clear** button is clicked.
         * @type {EventEmitter<{}>}
         */
        this.clear = new core_1.EventEmitter();
    }
    Object.defineProperty(FilterCellOperatorsComponent.prototype, "hostClasses", {
        /**
         * @hidden
         */
        get: function () {
            return true;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @hidden
     */
    FilterCellOperatorsComponent.prototype.onChange = function (dataItem) {
        this.valueChange.emit(dataItem);
    };
    /**
     * @hidden
     */
    FilterCellOperatorsComponent.prototype.clearClick = function () {
        this.clear.emit();
        return false;
    };
    /**
     * @hidden
     */
    FilterCellOperatorsComponent.prototype.clearKeydown = function (args) {
        if (args.keyCode === kendo_angular_common_1.Keys.Enter || args.keyCode === kendo_angular_common_1.Keys.Space) {
            this.clear.emit();
        }
    };
    /**
     * @hidden
     */
    FilterCellOperatorsComponent.prototype.dropdownKeydown = function (args) {
        if (args.defaultPrevented) {
            return;
        }
        if (args.keyCode === kendo_angular_common_1.Keys.Enter && !this.dropdown.isOpen) {
            this.dropdown.toggle(true);
            args.preventDefault();
        }
    };
    FilterCellOperatorsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.localization.changes.subscribe(function () { return _this.clearText = _this.localization.get("filterClearButton"); });
    };
    tslib_1.__decorate([
        core_1.HostBinding('class.k-filtercell-operator'),
        tslib_1.__metadata("design:type", Boolean),
        tslib_1.__metadata("design:paramtypes", [])
    ], FilterCellOperatorsComponent.prototype, "hostClasses", null);
    tslib_1.__decorate([
        core_1.ViewChild('dropdown', { static: false }),
        tslib_1.__metadata("design:type", kendo_angular_dropdowns_1.DropDownListComponent)
    ], FilterCellOperatorsComponent.prototype, "dropdown", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Array)
    ], FilterCellOperatorsComponent.prototype, "operators", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], FilterCellOperatorsComponent.prototype, "showButton", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], FilterCellOperatorsComponent.prototype, "showOperators", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], FilterCellOperatorsComponent.prototype, "value", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], FilterCellOperatorsComponent.prototype, "valueChange", void 0);
    tslib_1.__decorate([
        core_1.Output(),
        tslib_1.__metadata("design:type", core_1.EventEmitter)
    ], FilterCellOperatorsComponent.prototype, "clear", void 0);
    FilterCellOperatorsComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-grid-filter-cell-operators',
            template: "\n        <kendo-dropdownlist\n            #dropdown\n            *ngIf=\"showOperators\"\n            kendoGridFocusable\n            [data]=\"operators\"\n            class=\"k-dropdown-operator\"\n            (valueChange)=\"onChange($event)\"\n            [value]=\"value\"\n            iconClass=\"k-i-filter\"\n            [valuePrimitive]=\"true\"\n            textField=\"text\"\n            [popupSettings]=\"{ width: 'auto' }\"\n            valueField=\"value\"\n            (keydown)=\"dropdownKeydown($event)\">\n        </kendo-dropdownlist>\n        <button type=\"button\"\n            kendoGridFocusable\n            *ngIf=\"showButton\"\n            class=\"k-button k-button-solid-base k-button-solid k-button-md k-rounded-md k-button-rectangle k-icon-button\"\n            [title]=\"clearText\"\n            (click)=\"clearClick()\"\n            (keydown)=\"clearKeydown($event)\">\n                <span class=\"k-icon k-button-icon k-i-filter-clear\"></span>\n        </button>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [kendo_angular_l10n_1.LocalizationService])
    ], FilterCellOperatorsComponent);
    return FilterCellOperatorsComponent;
}());
exports.FilterCellOperatorsComponent = FilterCellOperatorsComponent;
