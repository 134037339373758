//import angular from 'angular';


class FileUrls implements IFileUrls {
    private readonly apiPath = baseApiUrl;
    private readonly filePath = 'file/';

    public readonly uploadFile = this.buildRoute('uploadFile')
    public readonly getFile = this.buildRoute('getFile')
    public readonly deleteFile = this.buildRoute('deleteFile', true)
    public readonly updateDescription = this.buildRoute('updateDescription')
    public readonly getThumbnail = this.buildRoute('getThumbnail');

    private buildRoute(endpoint: string, excludeApiPath = false): string {
        return (!excludeApiPath ? this.apiPath : '') + this.filePath + endpoint;
    }
}

angular.module('app').service('fileUrls', FileUrls);