/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var editing_directive_base_1 = require("./editing-directive-base");
var grid_component_1 = require("../grid.component");
var local_data_changes_service_1 = require("../editing/local-data-changes.service");
var utils_1 = require("./utils");
/**
 * A directive which encapsulates the editing operations of the Grid when using the in-cell
 * editing with Reactive Forms ([see example]({% slug editing_directives_grid %}#toc-the-incell-directive)).
 */
var InCellEditingDirective = /** @class */ (function (_super) {
    tslib_1.__extends(InCellEditingDirective, _super);
    function InCellEditingDirective(grid, localDataChangesService, cdr) {
        var _this = _super.call(this, grid, localDataChangesService) || this;
        _this.grid = grid;
        _this.localDataChangesService = localDataChangesService;
        _this.cdr = cdr;
        return _this;
    }
    // Need mixin
    InCellEditingDirective.prototype.createModel = function (args) {
        return this.createFormGroup(args);
    };
    InCellEditingDirective.prototype.saveModel = function (_a) {
        var dataItem = _a.dataItem, formGroup = _a.formGroup, isNew = _a.isNew;
        if (!formGroup.dirty && !isNew) {
            return;
        }
        if (formGroup.valid) {
            this.editService.assignValues(dataItem, formGroup.value);
            return dataItem;
        }
        utils_1.markAllAsTouched(formGroup);
    };
    /**
     * @hidden
     */
    InCellEditingDirective.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.subscriptions.add(this.grid.cellClick.subscribe(this.cellClickHandler.bind(this)));
        this.subscriptions.add(this.grid.cellClose.subscribe(this.cellCloseHandler.bind(this)));
    };
    InCellEditingDirective.prototype.removeHandler = function (args) {
        _super.prototype.removeHandler.call(this, args);
        this.grid.cancelCell();
    };
    InCellEditingDirective.prototype.cellClickHandler = function (args) {
        if (!args.isEdited && args.type !== 'contextmenu') {
            this.grid.editCell(args.rowIndex, args.columnIndex, this.createFormGroup(args));
            this.cdr.markForCheck();
        }
    };
    InCellEditingDirective.prototype.cellCloseHandler = function (args) {
        var formGroup = args.formGroup, dataItem = args.dataItem;
        if (!formGroup.valid) {
            args.preventDefault();
        }
        else if (formGroup.dirty) {
            if (args.originalEvent && args.originalEvent.keyCode === kendo_angular_common_1.Keys.Escape) {
                return;
            }
            this.editService.assignValues(dataItem, formGroup.value);
            this.editService.update(dataItem);
        }
    };
    tslib_1.__decorate([
        core_1.Input('kendoGridInCellEditing'),
        tslib_1.__metadata("design:type", Function)
    ], InCellEditingDirective.prototype, "createFormGroup", void 0);
    InCellEditingDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoGridInCellEditing]'
        }),
        tslib_1.__metadata("design:paramtypes", [grid_component_1.GridComponent,
            local_data_changes_service_1.LocalDataChangesService,
            core_1.ChangeDetectorRef])
    ], InCellEditingDirective);
    return InCellEditingDirective;
}(editing_directive_base_1.EditingDirectiveBase));
exports.InCellEditingDirective = InCellEditingDirective;
