/**
 * @ngdoc directive
 * @name amtFramework.formControls.directive:amtNumberInput
 * @restrict E
 *
 * @param {expression} ngModel Number
 * @param {number} [decimalPlaces=0] Number of decimal places to display
 * @param {expression} [ngRequired=false] Required - defaults to false
 * @param {expression} ngDisabled Disabled
 * @param {number} [max] Maximum value
 * @param {number} [min] Minimum value
 * @param {callback} [onValueChange] Callback that is called when the input changes
 *
 * @description
 * Used for number inputs
 * @example
 */

//import angular from 'angular';
import tmpl from "./amtNumberInput.html";
import './amtNumberInput.scss';


angular.module("amtFramework.formControls").directive("amtNumberInput", ['$timeout',
    function ($timeout: ng.ITimeoutService) {
            return {
                require: "ngModel",
                restrict: "E",
                scope: {
                    ngModel: "=",
                    decimalPlaces: "=",
                    numeralPlaces: "@?",
                    ngRequired: "=",
                    ngDisabled: "=",
                    setFocus: "=?",
                    max: "=?",
                    min: "=?"
                },
                template: tmpl,
                link: function (scope: any, elem, attr, ngModelCtrl) {

                    scope.name = attr.name;

                    elem.on('focus', function () {
                        $timeout(function () {
                            // select all text on focus
                            //self.select();
                        });
                    });

                    if (!scope.setFocus) {
                        scope.setFocus = false;
                    }

                    if (!!scope.decimalPlaces) {
                        scope.step = 1 / Math.pow(10, scope.decimalPlaces);
                    }

                    if (!!scope.numeralPlaces && !scope.max) {
                        var maxValue = "9".repeat(scope.numeralPlaces);

                        if (!!scope.decimalPlaces) {
                            maxValue = maxValue + "." + "9".repeat(scope.decimalPlaces);
                        }

                        scope.max = maxValue;
                    }

                    if (!!scope.ngModel) {
                        // ensure converted to number if it is in string format
                        // don't convert "undefined", because it becomes "NaN" which is considered valid.
                        scope.ngModel = +scope.ngModel;
                    }

                    scope.$watch("decimalPlaces", function () {
                        if (scope.decimalPlaces > 0) {
                            scope.step = 1 / Math.pow(10, scope.decimalPlaces);
                        } else {
                            scope.step = 1;
                        }
                    });

                    ngModelCtrl.$validators.decimals = function (modelValue, viewValue) {
                        var value = modelValue || viewValue;

                        if (!!scope.ngRequired && !isNaN(value) && !isNaN(scope.decimalPlaces)) {
                            var rem = (value * Math.pow(10, scope.decimalPlaces)) % 1;

                            if (rem !== 0) {
                                return (value.toString().split(".")[1].length <= scope.decimalPlaces);
                            } else return true;
                        } else {
                            return true;
                        }
                    };

                    elem.on("keydown", function (e) {
                        if (scope.decimalPlaces === 0 && e.key === ".") {
                            e.preventDefault();
                        }
                    });
                }
            };
    }
]);
