//import angular from 'angular';


    angular.module('app.directives').directive('ocChartScrollbar',
            function () {
                return {
                    replace: true,
                    scope: {
                        top: "=",
                        left: "=",
                        width: "=",
                        height: "=",
                        itemsToDisplay: "=",
                        min: "=",
                        max: "=",
                        value: "=",
                        config: "="
                    },
                    controllerAs: "vm",
                    controller: ["$scope", "$q", function($scope, $q) {
                        var vm = this;

                        vm.scrollPage = function (e) {
                            if (e.offsetY > vm.selectedAreaTop + vm.selectedAreaHeight) {
                                // page down
                                vm.scroll($scope.itemsToDisplay);
                            } else {
                                if (e.offsetY < vm.selectedAreaTop) {
                                    // page up
                                    vm.scroll($scope.itemsToDisplay * -1);
                                }
                            }

                            e.preventDefault();
                        };

                        vm.isMouseDown = false;
                        vm.dragStartPos = 0;

                        vm.startDrag = function (e) {
                            vm.isMouseDown = true;

                            vm.dragStartPos = e.offsetY;
                            vm.dragPos = e.offsetY;

                            e.preventDefault();
                        };

                        vm.drag = function (e) {
                            if (vm.isMouseDown === true && e.which === 1) {
                                var scrollableHeight = $scope.height - ($scope.width * 2);

                                var newScrollPos = vm.selectedAreaTop + (e.offsetY - vm.dragPos);
                                
                                if (newScrollPos < scrollableHeight - ($scope.width * 2) && newScrollPos - $scope.top > $scope.width) {
                                    vm.selectedAreaTop = newScrollPos;
                                }

                                vm.dragPos = e.offsetY;
                            }
                        };

                        vm.endDrag = function (e) {
                            if (vm.isMouseDown) {
                                vm.isMouseDown = false;

                                var scrollableHeight = $scope.height - ($scope.width * 2) - 2;
                                var itemHeight = scrollableHeight / ($scope.max - $scope.min + 1);

                                vm.scroll(Math.round((e.offsetY - vm.dragStartPos) / itemHeight));

                                e.preventDefault();
                            }
                        };

                        vm.updateScrollDimensions = function () {
                            var scrollableHeight = $scope.height - ($scope.width * 2) - 2;

                            var itemHeight = scrollableHeight / ($scope.max - $scope.min + 1);

                            vm.selectedAreaTop = $scope.top + 1 + $scope.width + $scope.value * itemHeight;
                            vm.selectedAreaHeight = itemHeight * $scope.itemsToDisplay;

                            vm.scrollUpPoints = renderPolygonPoints(createTrianglePoints(
                                $scope.left + $scope.width / 2,
                                $scope.top + $scope.width / 2,
                                12,
                                12));

                            vm.scrollDownPoints = renderPolygonPoints(createInvertedTrianglePoints(
                                $scope.left + $scope.width / 2,
                                $scope.top + $scope.height - $scope.width / 2,
                                12,
                                12));
                                                        
                            vm.positionHeight = itemHeight;
                            vm.positionWidth = 10;
                            vm.positions = [];

                            for (var p = $scope.min; p < $scope.max + 1; p++) {
                                vm.positions.push({
                                    left: ($scope.width - vm.positionWidth) / 2,
                                    top: vm.positionHeight * p + 3
                                });
                            //    vm.positions.push({
                            //        left: $scope.width / 2 - vm.positionWidth - 3,
                            //        top: vm.positionHeight * p + 3
                            //    });

                            //    vm.positions.push({
                            //        left: $scope.width / 2 + 3,
                            //        top: vm.positionHeight * p + 3
                            //    });
                            }
                        };

                        vm.scrollUp = function () {
                            vm.scroll(-1);
                        };

                        vm.scrollDown = function () {
                            vm.scroll(1);
                        };

                        vm.scroll = function (delta) {
                            var newVal = $scope.value + delta;

                            if (newVal > ($scope.max - $scope.min + 1) - $scope.itemsToDisplay) {
                                newVal = ($scope.max - $scope.min + 1) - $scope.itemsToDisplay;
                            }

                            if (newVal < $scope.min) {
                                newVal = $scope.min;
                            }

                            if ($scope.value !== newVal) {
                                $scope.value = newVal;
                                $scope.config.top = newVal;
                            }
                        };

                        function createTrianglePoints(left, top, width, height) {
                            return [
                                { x: (left), y: top - height / 2 },
                                {
                                    x: left - width / 2, y: (top + height / 2)
                                },
                                {
                                    x: (left + width / 2), y: (top + height / 2)
                                }
                            ];
                        }

                        function createInvertedTrianglePoints(left, top, width, height) {
                            return [
                                { x: left, y: top + height / 2 },
                                {
                                    x: left - width / 2, y: top - height / 2
                                },
                                {
                                    x: left + width / 2, y: top - height / 2
                                }
                            ];
                        }

                        function renderPolygonPoints(points) {
                            var s = '';
                            for (var e = 0; e < points.length; e++) {
                                var point = points[e];

                                if (e > 0) {
                                    s += ',';
                                }
                                s += point.x + ',' + point.y;
                            }

                            return s;
                        }

                        vm.updateScrollDimensions();
                    }],
                    template: 
                    '<g ng-class="chartScrollbar">' +

                    //// positions
                    //'    <path class="sbAxleSilhouette" ng-attr-d="M{{left + width / 2}} {{ top + width + vm.positionHeight / 2 }} L{{left + width / 2}} {{ top + height - width - vm.positionHeight / 2 }}"></path>' +
                    '    <rect ng-repeat="a in vm.positions" class="sbAxleSilhouette" ng-attr-x="{{left + a.left}}" ng-attr-y="{{ top + width + a.top + 1}}" ng-attr-width="{{vm.positionWidth}}" ng-attr-height="{{vm.positionHeight - 6}}"></rect>' +

                    // background
                    '    <rect class="sbBackground" ng-attr-x="{{left}}" ng-attr-y="{{top}}" ng-attr-width="{{width}}" ng-attr-height="{{height}}" ng-mouseDown="vm.scrollPage($event);" />' +

                    // scroll up
                    '    <polygon class="sbNav" ng-attr-points="{{vm.scrollUpPoints}}"></polygon>' +
                    '    <rect class="sbNavBackground" ng-attr-x="{{left}}" ng-attr-y="{{top}}" ng-attr-width="{{width}}" ng-attr-height="{{width}}" ng-click="vm.scrollUp();" />' +

                    // selected area
                    '    <rect class="sbRange" ng-attr-x="{{ left + 3 }}" ng-attr-y="{{ vm.selectedAreaTop }}" ng-attr-width="{{ width - 6 }}" ng-attr-height="{{ vm.selectedAreaHeight }}" ng-mouseDown="vm.startDrag($event);" ng-mouseMove="vm.drag($event);" ng-mouseUp="vm.endDrag($event);" ></rect>' +

                    // scroll down
                    '    <polygon class="sbNav" ng-attr-points="{{vm.scrollDownPoints}}"></polygon>' +
                    '    <rect class="sbNavBackground" ng-attr-x="{{left}}" ng-attr-y="{{top + height - width}}" ng-attr-width="{{width}}" ng-attr-height="{{width}}" ng-click="vm.scrollDown();" />' +
                    '</g>'
                };
            }
        );