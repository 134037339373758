//import angular from 'angular';
import tmpl from './siteDashboardConfiguration.html';
import './siteDashboardConfiguration.scss';
import { ISiteDashboard, ISiteDashboardWidget, ISiteDashboardConfigurationScope, ISiteDashboardSearchCriteria, ISiteDashboardWidgetSearchCriteria, ISaveSiteDashboardCriteria } from './siteDashboardConfiguration.interfaces';

class SiteDashboardConfigurationCtrl implements ng.IComponentController {

    processing: boolean;
    readonly: boolean;

    canDeleteSiteDashboardWidgets: boolean;

    selectedDashboard: ISiteDashboard;
    selectedDashboardWidget: ISiteDashboardWidget;

    dashboardsGridControl: IGridControl = {};
    dashboardWidgetsGridControl: IGridControl = {};

    dashboardCriteria: ISiteDashboardSearchCriteria = {
        filterValues: {
            includeInactive: false
        }
    };

    dashboardWidgetCriteria: ISiteDashboardWidgetSearchCriteria = {
        filterValues: {
            includeInactive: false,
            siteDashboardId: null
        }
    };

    static $inject = ['$scope', 'ocSecuritySvc', 'adminUrls', '$timeout', 'WindowFactory', 'amtXlatSvc', 'confirmSvc', 'amtCommandQuerySvc', 'notifySvc', 'errorReporter'];

    constructor(private $scope: ISiteDashboardConfigurationScope, private ocSecuritySvc: IOcSecuritySvc,
        private adminUrls: IAdminUrls, private $timeout: ng.ITimeoutService,
        private WindowFactory: IWindowFactory, private amtXlatSvc: IAmtXlatSvc, private confirmSvc: IConfirmSvc,
        private amtCommandQuerySvc: IAmtCommandQuerySvc, private notifySvc: INotifySvc, private errorReporter: IErrorReporter) {

        this.$scope.editDashboard = (item) => this.editDashboard(item);
        this.$scope.setDefaultDashboard = (item) => this.setDefaultDashboard(item);
        this.$scope.setActiveDashboard = (item) => this.setActiveDashboard(item);
        this.$scope.deleteDashboard = (item) => this.deleteDashboard(item);

        this.$scope.editSiteDashboardWidget = (item) => this.addEditDashboardWidget(item);
        this.$scope.deleteSiteDashboardWidget = (item) => this.deleteSiteDashboardWidget(item);
    }

    $onInit() {
        this.canDeleteSiteDashboardWidgets = this.ocSecuritySvc.isAuthorised('Security.Settings.SiteDashboard', AccessTypes.readWrite) && !this.readonly;
    }

    // on selection of a dashboard, load any existing dashboard elements (or clear them on deselection)
    onDashboardSelected(items: ISiteDashboard[]) {

        if (items?.length) { // dashboard selected

            this.selectedDashboard = items[0];
            this.dashboardWidgetCriteria.filterValues.siteDashboardId = this.selectedDashboard?.id;

        } else { // dashboard deselected

            this.selectedDashboard = null;
            this.dashboardWidgetCriteria.filterValues.siteDashboardId = null;
            this.dashboardWidgetCriteria.filterValues.includeInactive = false;
        }

        this.$timeout(() => this.refreshDashboardWidgets());
    }

    // on selection of an existing dashboard element
    onDashboardWidgetSelected(items: any) {
        if (items?.length) {
            this.selectedDashboardWidget = items[0];
        } else {
            this.selectedDashboardWidget = null;
        }
    }

    // refresh the dashboard elements grid
    refreshDashboardWidgets() {
        this.dashboardWidgetsGridControl?.refresh();
    }

    // refresh the dashboards grid
    refreshDashboards() {
        this.dashboardsGridControl?.refresh();
    }

    // react to changing the dashboards include inactive filter (clear selection and refresh the dashboards grid)
    onDashboardsInactiveFilterChange() {
        this.selectedDashboard = null;
        this.refreshDashboards();
    }

    // add an element to a dashboard, or edit an existing element
    addEditDashboardWidget(item?: any) {
        this.WindowFactory.openItem({
            component: 'site-dashboard-configuration-details',
            caption: String.format('{0} - {1}', this.selectedDashboard.name, this.amtXlatSvc.xlat(item ? 'siteDashboard.editDashboardWidget' : 'siteDashboard.addDashboardWidget')),
            initParams: {
                siteDashboardId: this.selectedDashboard.id,
                dashboardName: this.selectedDashboard.name,
                dashboardWidget: item?.dashboardWidget,
                siteDashboardWidgetId: item?.id
            },
            width: 700,
            modal: true,
            canClose: false,
            onDataChangeHandler: () => this.refreshDashboardWidgets()
        });
    }

    // delete a widget from a site dashboard
    async deleteSiteDashboardWidget(item: any) {

        try {
            await this.confirmSvc.confirmMessage('siteDashboard.deleteSiteDashboardWidget_title', 'siteDashboard.deleteSiteDashboardWidget', item.dashboardWidget.description);
        } catch {
            return false; // they cancelled
        }

        let criteria = {
            id: item.id,
            siteDashboardId: item.siteDashboardId
        };

        this.processing = true;

        try {
            await this.amtCommandQuerySvc.post(this.adminUrls.deleteSiteDashboardWidget, criteria);

            this.notifySvc.success(this.amtXlatSvc.xlat('siteDashboard.deleteSiteDashboardWidgetSuccess'));

            this.refreshDashboardWidgets();

            return true;

        } catch (error) {
            this.errorReporter.logError(error);
        } finally {
            this.processing = false;
        }
    }

    // Dashboard
    addDashboard() {
        this.WindowFactory.openItem({
            component: 'site-dashboard-add',
            caption: this.amtXlatSvc.xlat('siteDashboard.addDashboard'),
            width: 700,
            modal: true,
            canClose: false,
            onDataChangeHandler: () => this.refreshDashboards()
        });
    }

    editDashboard = function(item) {
        this.WindowFactory.openItem({
            component: 'site-dashboard-add',
            caption: this.amtXlatSvc.xlat('common.edit_label') + ' ' + this.amtXlatSvc.xlat('siteDashboard.dashboard') + ' - ' + item.name,
            width: 700,
            modal: true,
            canClose: false,
            initParams: item,
            onDataChangeHandler: () => this.refreshDashboards()
        });
    }

    setActiveDashboard = function (item) {
        let criteria: ISaveSiteDashboardCriteria = {
            siteId: item.siteId,
            dashboardId: item.id,
            dashboardName: item.name,
            active: !item.active,
            isDefault: item.isDefault
        }
        this.callApi(criteria, this.adminUrls.saveSiteDashboards);
    }

    setDefaultDashboard = function (item) {
        let criteria: ISaveSiteDashboardCriteria = {
            siteId: item.siteId,
            dashboardId: item.id,
            dashboardName: item.name,
            active: item.active,
            isDefault: true
        }
        this.callApi(criteria, this.adminUrls.saveSiteDashboards);
    };


    async deleteDashboard(item) {

        try {
            await this.confirmSvc.confirmMessage(this.amtXlatSvc.xlat('common.delete') + ' ' + this.amtXlatSvc.xlat('siteDashboard.dashboard'), 'equipment.confirmDeleteAttachment_text', item.name);
        } catch {
            return false; // they cancelled
        }

        let criteria: ISaveSiteDashboardCriteria = {
            siteId: item.siteId,
            dashboardId: item.id,
            dashboardName: item.name,
            active: false,
            isDefault: item.isDefault
        }
        this.callApi(criteria, this.adminUrls.deleteSiteDashboards);
    };

    async callApi(item, url) {
        this.processing = true;
        try {
            await this.amtCommandQuerySvc.post(url, item);
            this.notifySvc.success(this.amtXlatSvc.xlat('siteDashboard.saveSiteDashboardWidgetSuccess'));
            this.setPristine();
        } catch (error) {
            this.errorReporter.logError(error);
        } finally {
            this.processing = false;
            this.refreshDashboards();
        }
    }

    setPristine = function () {
        if (this.onDataChanged)
            this.onDataChanged();
        this.form.$setPristine();
    }
}

angular.module('app.admin').component('siteDashboardConfiguration', {
    template: tmpl,
    controllerAs: 'vm',
    controller: SiteDashboardConfigurationCtrl
});
