
    angular
        .module("app.directives")
        .directive("noDirtyCheck", noDirtyCheck);

    function noDirtyCheck() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                ctrl.$setPristine = function () { };
                ctrl.$pristine = false;
            }
        };
    }
