//import angular from 'angular';
import BrowserSvc from '../../../../services/browserSvc';
import OcDateSvc from '../../../../services/ocDateSvc';
import ReportSvc, { ExportReports, ExportReportParameters, ExportReportCriteria, ExportProviders, ExportOrientation }
                    from '../../../../services/reportSvc';
import FieldSurveyUrls from './fieldSurveyUrls';
import tmpl from './search.html';



type TFieldSurveySearchInitParams = {
    id: string;
    serialNumber: string;
    search: string;
}

type TFieldSurveySearchDefaults = {
    statuses?: any[],
    dateFrom?: Date
}

interface TFieldSurveySearchCriteria extends IGridFilterCriteria {
    filterValues: {
        dateFrom?: Date,
        dateTo?: Date,
        fleetIds?: guid[],
        specificationIds?: guid[],
        statusIds?: guid[]
    }
}

class FieldSurveySearchCtrl implements ng.IController {

    form: ng.IFormController;
    initParams: TFieldSurveySearchInitParams;

    status: ScreenStatus = ScreenStatus.loading;
    processing: boolean = false;

    canViewVehicles: boolean;

    showChains: boolean;
    useNitrogen: boolean;
    selectAll: boolean = true;

    gridTop: number;
    gridControl: IGridControl = {};

    selectedItem: any = null;
    defaults: TFieldSurveySearchDefaults = {};

    criteria: TFieldSurveySearchCriteria = {
        filterValues: {}
    };

    statuses: KeyNamePair[] = [];

    static $inject = ['$rootScope', '$scope', '$stateParams', 'fieldSurveyFactory', 'fieldSurveyUrls', 'WindowFactory', 'amtXlatSvc',
        'ocDateSvc', 'ocConfigSvc', 'ocSecuritySvc', 'browserSvc', '$timeout', 'errorReporter', 'reportSvc',
        'amtCommandQuerySvc'];

    constructor(private $rootScope: ng.IRootScopeService, private $scope: ng.IScope, private $stateParams: ng.ui.IStateParamsService,
        private fieldSurveyFactory: any, private fieldSurveyUrls: FieldSurveyUrls, private WindowFactory: IWindowFactory,
        private amtXlatSvc: IAmtXlatSvc, private ocDateSvc: OcDateSvc, private ocConfigSvc: IOcConfigSvc, private ocSecuritySvc: IOcSecuritySvc,
        private browserSvc: BrowserSvc, private $timeout: ng.ITimeoutService,
        private errorReporter: IErrorReporter, private reportSvc: ReportSvc, private amtCommandQuerySvc: IAmtCommandQuerySvc) {

        this.$scope['openFieldSurvey'] = (dataItem) => this.openFieldSurvey(dataItem);
        this.$scope['openVehicleDetails'] = (dataItem) => this.openVehicleDetails(dataItem);
    }

    async $onInit() {

        this.processing = true;

        try {
            this.canViewVehicles = this.ocSecuritySvc.isAuthorised('Security.Vehicles', AccessTypes.readOnly);

            this.showChains = this.ocConfigSvc.user.site.settings.tyreMaintenance.showChains;
            this.useNitrogen = this.ocConfigSvc.user.site.settings.tyreMaintenance.useNitrogen;

            await this.getStatuses();

            this.loadDefaults();
        } finally {
            this.processing = false;
        }
    }

    onFieldSurveysLoaded(response) {
        let fieldSurveys = response.result;

        for (let r = 0; r < fieldSurveys.length; r++) {
            fieldSurveys[r].readingTypes = fieldSurveys[r].readingTypes.join(', ');
            fieldSurveys[r].onSite = fieldSurveys[r].vehicleCurrentSiteId == this.ocConfigSvc.user.site.id;
        }

        return response;
    }

    onFieldSurveyGridDatabound(event: any) {

        this.selectedItem = null;

        if (event) {

            let rows = event.sender.tbody.children();
            let data = event.sender._data;

            for (let i = 0; i < rows.length; i++) {

                if (data[i].errorCount > 0) {
                    let row = $(rows[i]);
                    row.addClass('bg-danger'); // light red background
                }
            }
        }
    }

    onSelectedItemChanged(items: any) {
        if (items && items.length) {
            this.selectedItem = items[0];
        } else {
            this.selectedItem = null;
        }
    }

    setHeaderHeight(height: number, offset: number) {
        this.gridTop = height + offset;
    }

    export() {
        this.fieldSurveyFactory.export(this.criteria);
    }

    loadDefaults() {
        // focus on serial number field by default
        this.$timeout(() => {
            this.initParams.search = localStorage.getItem("siteDashboardSearchby") === "Error" ? FilterStatusShortcut.inError : this.initParams.search;

            if (this.initParams.search === FilterStatusShortcut.inError || localStorage.getItem("siteDashboardSearchby") === "Error") {
                // only error field surveys, all time
                this.defaults.statuses = this.statuses.filter(s => s.key === VehicleOperationStatus.error);
            } else {
                // default from start of current month
                this.defaults.dateFrom = this.ocDateSvc.getStartOfMonth(new Date());
            }

            this.status = ScreenStatus.ready;

            this.$timeout(() => {

                if (this.$stateParams.search || localStorage.getItem("siteDashboardSearchby") === "Error") {
                    this.filter();
                }

                if (localStorage.getItem("siteDashboardSearchby") === "Error") {
                    localStorage.setItem("siteDashboardSearchby", "undefined");
                }
            });
        });
    }

    async getStatuses() {
        try {
            let response = await this.amtCommandQuerySvc.post('equipment/vehicle/getFieldSurveyStatuses', {});

            if (response)
                this.statuses = response.result;

        } catch (error) {
            this.errorReporter.logError(error);
        }
    }

    openFieldSurvey(item: any) {
        this.WindowFactory.openItem({
            component: 'field-surveys',
            modal: this.browserSvc.isMobile,
            parentWindow: null,
            caption: this.amtXlatSvc.xlat('fieldSurvey.elementName'),
            canMinimise: true,
            canClose: true,
            fullScreenOff: true,
            windowRelatedRecordId: item.id,
            initParams: {
                id: item.id,
                date: item.date,
                equipmentId: item.equipmentId,
                serialNumber: item.serialNumber
            },
            footerOff: true,
            headerOff: this.browserSvc.isMobile,
            width: 1000,
            height: 700 + (!this.browserSvc.isMobile ? this.WindowFactory.headerHeight : 0),
            onDataChangeHandler: () => this.filter()
        });
    }

    openVehicleDetails(item: any) {
        this.WindowFactory.openItem({
            caption: this.amtXlatSvc.xlat('equipment.openVehicle', item.serialNumber),
            component: 'vehicle-details',
            fullScreenOff: true,
            windowRelatedRecordId: item.equipmentId,
            initParams: {
                equipmentId: item.equipmentId,
                siteId: item.siteId,
                serialNumber: item.serialNumber
            },
            width: 800,
            height: 850,
            modal: false
        });
    }

    createFieldSurvey() {
        this.WindowFactory.openItem({
            component: 'field-surveys',
            headerOff: this.browserSvc.isMobile,
            modal: this.browserSvc.isMobile,
            fullScreenOff: true,
            canClose: true,
            canMinimise: true,
            caption: this.amtXlatSvc.xlat('fieldSurvey.elementName'),
            footerOff: true,
            initParams: {},
            height: 700 + (!this.browserSvc.isMobile ? this.WindowFactory.headerHeight : 0),
            width: 1000
        });
    };

    filter(tries: number = 0) {
        if (!this.gridControl || !this.gridControl.refresh) {
            //try to wait for the grid to be ready
            if (tries < 8) {
                this.$timeout(() => this.filter(++tries), 500);
            } else {
                console.error('Field Survey Search: max retries reached');
                this.status = ScreenStatus.error;
            }

            return;
        }

        this.criteria.filterValues.dateFrom = this.ocDateSvc.dateAsUTC(this.criteria.filterValues.dateFrom);
        this.criteria.filterValues.dateTo = this.ocDateSvc.dateAsUTC(this.criteria.filterValues.dateTo);
        this.gridControl.refresh();

        //delay refresh of field survey count a few seconds, the search results is what they care about
        this.$timeout(() => this.broadcastFieldSurveyChange(), 2000);
    }

    downloadBlankFieldSurveyForm() {
        this.WindowFactory.openItem({
            component: 'blank-field-survey',
            modal: true,
            canClose: false,
            caption: this.amtXlatSvc.xlat('fieldSurvey.downloadBlankForm'),
            width: 600
        });
    }

    async downloadFieldSurveyReport(item: any) {

        let report = this.reportSvc.getReport(ExportReports.fieldSurvey);

        if (report) {

            let parameters = [];

            if (report.parameters && report.parameters.length) {

                parameters = this.reportSvc.constructBaseParameters(report);

                parameters = this.reportSvc.constructParameter(parameters, report, ExportReportParameters.isBlank, 0);
                parameters = this.reportSvc.constructParameter(parameters, report, 'Id', item.fieldSurveyEventId);

                let readingTypes = [];

                readingTypes.push(FieldSurveyReadingTypes.pressure);

                if (this.useNitrogen)
                    readingTypes.push(FieldSurveyReadingTypes.nitrogen);

                readingTypes.push(FieldSurveyReadingTypes.temperature);
                readingTypes.push(FieldSurveyReadingTypes.treadDepth);

                if (this.showChains)
                    readingTypes.push(FieldSurveyReadingTypes.chainDepth);

                parameters = this.reportSvc.constructParameter(parameters, report, 'ReadingTypes', readingTypes.join('-'));
            }

            let filename = String.format("{0}-{1}-{2}-{3}", report.description || report.name, this.ocConfigSvc.user.site.name, item.serialNumber, this.ocDateSvc.toReportString(item.date));

            let exportCriteria: ExportReportCriteria = {
                report: report,
                provider: ExportProviders.pdf,
                parameters: parameters,
                filename: filename,
                orientation: ExportOrientation.landscape
            };

            try {
                await this.reportSvc.exportReport(exportCriteria);
            } catch (error) {
                this.errorReporter.logError(error);
            }
        }
    }

    broadcastFieldSurveyChange() {
        this.$rootScope.$broadcast('RefreshFieldSurveyCounts');
    }
}

class FieldSurveySearchComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '='
    };
    public template = tmpl;
    public controller = FieldSurveySearchCtrl;
    public controllerAs = 'vm';
}

angular.module('app.vehicle').component('fieldSurveySearch', new FieldSurveySearchComponent());