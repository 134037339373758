//import angular from 'angular';
import AdminUrls from '../../admin/adminUrls';
import tmpl from './vehicleDetails.html';


    angular.module('app.vehicle').component('vehicleDetails',
        {
            bindings: {
                initParams: '=',
                buttonMethods: '=',
                buttonStates: '=',
                buttons: '=',
                closeOnSave: '=',
                wnd: '='
            },
            template: tmpl,
            controller: [
                '$scope', '$rootScope', '$state', 'amtCommandQuerySvc', 'errorReporter', 'vehicleUrls', 'adminUrls',
                'referenceDataUrls', 'enums', 'amtXlatSvc', 'confirmSvc', 'WindowFactory', 'ocConfigSvc',
                'FleetFactory', 'notifySvc', 'ocSecuritySvc', 'amtConstants',
                function ($scope, $rootScope, $state, amtCommandQuerySvc, errorReporter, vehicleUrls, adminUrls: AdminUrls,
                    referenceDataUrls, enums, amtXlatSvc, confirmSvc, WindowFactory, ocConfigSvc,
                    FleetFactory, notifySvc, ocSecuritySvc, amtConstants) {
                    var vm = this;
                    $scope.vm = this; // necessary for child directives to access what they need.

                    // security                        
                    vm.readonly_details = !ocSecuritySvc.isAuthorised('Security.Vehicles', 'readWrite'); // details tab is read-only

                    vm.canViewFleets = ocSecuritySvc.isAuthorised('Security.Settings.Fleets', 'readOnly'); // can open fleet details
                    vm.canTransfer = ocSecuritySvc.isAuthorised('Security.Vehicles.Transfer', 'readWrite'); // can transfer/undo transfer of vehicle                    
                    vm.canUndoReceive = ocSecuritySvc.isAuthorised('Security.Vehicles.Receive', 'readWrite'); // can undo vehicle receive

                    vm.canViewMaintenanceSession = ocSecuritySvc.isAuthorised('Security.Vehicles.MaintenanceSession', 'readOnly'); // can view maintenance session
                    vm.canCreateMaintenanceSession = ocSecuritySvc.isAuthorised('Security.Vehicles.MaintenanceSession.Add', 'readWrite'); // can create maintenance session

                    vm.canChangeStatus = ocSecuritySvc.isAuthorised('Security.Vehicles.ChangeStatus', 'readWrite');
                    vm.canViewStatusChange = ocSecuritySvc.isAuthorised('Security.Vehicles.ChangeStatus', 'readOnly');
                    vm.canViewHistoryReport = ocSecuritySvc.isAuthorised('Security.Reports.HistoryReport', 'readOnly');

                    vm.forms = {
                        main: {},
                        details: {}
                    };

                    vm.apiUrls = vehicleUrls;
                    vm.adminUrls = adminUrls;
                    vm.referenceDataUrls = referenceDataUrls;

                    vm.showActionMenu = false;

                    vm.config = ocConfigSvc;
                    vm.costPerDistance = 0;
                    vm.costPerHour = 0;
                    vm.status = "loading";
                    vm.hoursUnit = vm.config.user.site.units.time.unitAbbreviation;

                    this.$onInit = function () {
                        vm.siteId = vm.initParams.siteId;
                        vm.equipmentId = vm.initParams.equipmentId;
                        vm.clientId = vm.initParams.clientId;

                        vm.buttonStates = {
                            saveButton: {
                                visible: true,
                                disabled: true
                            },
                            refreshButton: {
                                visible: false,
                                disabled: false
                            },
                            addButton: {
                                visible: true,
                                disabled: false
                            },
                            // Action buttons don't have dynamic disable/enable, don't bother including it
                            exportButton: {
                                visible: false
                            },
                            maintenanceButton: {
                                visible: ocConfigSvc.user.site.active,
                            },
                            transferButton: {
                                visible: false
                            },
                            productionButton: {
                                visible: true
                            },
                            outOfServiceButton: {
                                visible: true
                            },
                            disposeButton: {
                                visible: true
                            },
                            historyButton: {
                                visible: true
                            },
                            undoButton: {
                                visible: true
                            }
                        };

                        vm.buttonMethods = {
                            updateDetails: vm.updateDetails,
                            maintenanceSession: vm.maintenanceSession,
                            transfer: $scope.transfer,
                            undo: vm.undo,
                            historyReport: vm.historyReport,
                            outOfService: vm.outOfService,
                            dispose: vm.dispose,
                            production: vm.production
                        };

                        if (vm.equipmentId !== null && vm.equipmentId !== undefined) {
                            vm.loadDetails();
                        }

                        $scope.$watch('ctrl.chartDateRange', function () {
                            if (vm.chartDateRange !== undefined) {
                                vm.historyCriteria.filterValues.dateFrom = vm.chartDateRange.from;
                                vm.historyCriteria.filterValues.dateTo = vm.chartDateRange.to;
                                $scope.refreshHistory();
                            }
                        });

                        vm.wnd.onClose = $scope.onClose;
                    };

                    vm.selectTab = function (tabObject) {
                        vm.currentTab = tabObject;
                        vm.readonly = vm.currentTab.readonly;

                        $scope.onRefresh();
                    };

                    // load the edit event details window
                    $scope.showEventDetails = function (equipmentEventId, editMode, target) {

                        vm.equipmentEventId = equipmentEventId;                        

                        if (editMode === 'Move') {
                            if (vm.canViewStatusChange) {
                                vm.openChangeStatus(target, 'edit');
                            }
                        } else if (editMode === 'Maintenance') {
                            if (vm.canViewMaintenanceSession) {
                                WindowFactory.openItem({
                                    component: 'maintenance',
                                    headerOff: false,
                                    footerOff: true,
                                    canMinimise: true,
                                    canClose: true,
                                    caption: amtXlatSvc.xlat('equipment.maintenanceSession'),
                                    windowRelatedRecordId: equipmentEventId,
                                    initParams: {
                                        vehicleId: vm.equipmentId,
                                        display: vm.vehicle.serialNumber.formatted + " - " + vm.vehicle.specification.description,
                                        id: equipmentEventId,
                                        tab: target
                                    },
                                    height: 700 + WindowFactory.headerHeight,
                                    width: 1100,
                                    onDataChangeHandler: $scope.refreshAll
                                });
                            }
                        }
                    };

                    $scope.openModule = function (component, titleResourceKey, module, initParams, width, height, modal, onDataChange, canClose?, canMinimise?) {
                        WindowFactory.openItem({
                            component: component,
                            caption: titleResourceKey,
                            //module: module,
                            initParams: initParams,
                            parentWindow: vm.wnd,
                            width: width,
                            height: height,
                            modal: (modal !== undefined ? modal : true),
                            canClose: canClose,
                            canMinimise: canMinimise,
                            onDataChangeHandler: onDataChange
                        });
                    };


                    $scope.displayFleetDetails = function (item) {
                        if (item !== undefined && item !== null) {
                            FleetFactory.open({
                                initParams: {
                                    mode: 'open',
                                    id: item.id,
                                    name: item.name
                                },
                                onDataChangeHandler: vm.filter
                            });
                        }
                    };

                    $scope.refreshSearch = function () {
                        $rootScope.$broadcast('filterSearch');
                    };

                    vm.getVehicleDetailsTimeout = function (response) {
                        if (response && response.status === 403) {
                            closeWindow();
                            notifySvc.error(amtXlatSvc.xlat('common.AccessDenied'));
                        } else {
                            vm.status = "error";
                            errorReporter.logError(response);
                        }
                    };

                    vm.getVehicleDetailsResponse = function (response) {
                        if (response) {

                            vm.vehicle = response;

                            vm.vehicle.isVehicle = true;

                            vm.vehicle.maintenance = (vm.vehicle.status.name as string).toLowerCase() === 'maintenance';
                            vm.vehicle.disposed = (vm.vehicle.status.name as string).toLowerCase() === 'disposed';

                            if (vm.vehicle.totalCost > 0) {
                                if (vm.vehicle.distance.value > 0) {
                                    vm.costPerDistance = vm.vehicle.totalCost / vm.vehicle.distance.value;
                                }

                                if (vm.vehicle.hours.value > 0) {
                                    vm.costPerHour = vm.vehicle.totalCost / vm.vehicle.hours.value;
                                }
                            }

                            vm.showSiteSerialNumber = vm.config.user.site.settings.tyreMaintenance.siteSerialNumber.vehicle;

                            if (vm.showSiteSerialNumber === 'Hide') {
                                vm.vehicle.serialNumber.formatted = vm.vehicle.serialNumber.manufacturer;
                            }

                            vm.wnd.caption = amtXlatSvc.xlat('equipment.openVehicle', vm.vehicle.serialNumber.formatted);
                            vm.wnd.shortCaption = vm.wnd.caption;

                            vm.showActionMenu = true;
                            vm.selectTab(vm.tabs.details);

                            vm.status = "ready";
                        }
                    };

                    // details tab
                    vm.loadDetails = function () {

                        vm.wnd.processing = true;

                        var criteria = {
                            equipmentId: vm.equipmentId
                        };

                        amtCommandQuerySvc.post(vm.apiUrls.getVehicleDetails, criteria).then(
                            vm.getVehicleDetailsResponse,
                            vm.getVehicleDetailsTimeout
                        ).finally(function () {
                            vm.wnd.processing = false;
                        });
                    };

                    vm.updateDetails = function () {

                        vm.wnd.processing = true;

                        vm.updateCriteria = {
                            equipmentId: vm.equipmentId,
                            siteSerialNumber: vm.vehicle.serialNumber.site,
                            manufacturerSerialNumber: vm.vehicle.serialNumber.manufacturer,
                            specificationId: vm.vehicle.specification.key,
                            hasHoursMeter: vm.vehicle.hasHoursMeter,
                            hasDistanceMeter: vm.vehicle.hasDistanceMeter,
                            distanceReadingUnitId: vm.vehicle.distanceReadingUnit.key
                        };

                        amtCommandQuerySvc.post(vm.apiUrls.updateVehicleDetails, vm.updateCriteria).then(
                            function (response) {

                                notifySvc.success(amtXlatSvc.xlat("vehicle.saveSuccessful"));
                                if (vm.closeOnSave) {
                                    closeWindow();
                                } else {
                                    vm.forms.details.$setPristine();
                                }
                                $scope.refreshSearch();

                            }).catch(function (error) {
                                errorReporter.logError(error);
                            }).finally(function () {
                                vm.wnd.processing = false;
                            });
                    };

                    // global

                    $scope.setForm = function () {
                        // add a watch to the dirty flag
                        $scope.$watch("vm.forms.details.$dirty", function (newValue) {
                            if (vm.currentTab === vm.tabs.details) { // only relevant on the details tab, otherwise we get false positives.
                                vm.wnd.isDirty = newValue;
                            }
                        });
                    };

                    function closeWindow() {
                        WindowFactory.closeWindow($scope.$parent.wnd);
                    }

                    $scope.onClose = function () {
                        confirmSvc.confirmSaveChange(vm.wnd.isDirty).then(function () {
                            if (vm.vehicle && vm.schematicFullScreen) {
                                WindowFactory.closeWindow(vm.schematicFullScreen);
                            }
                            vm.forms.details.$dirty = false;
                            closeWindow();
                            return true;
                        });

                        return false;
                    };

                    $scope.actionClick = function (action) {
                        action.click();
                    };

                    vm.maintenanceSession = function () {
                        var data = {
                            vehicleId: vm.equipmentId,
                            display: vm.vehicle.serialNumber.formatted + " - " + vm.vehicle.specification.description,
                            id: undefined
                        };

                        var relatedId = vm.equipmentId + amtConstants.emptyGuid;

                        if ((vm.vehicle.status.name as string).toLowerCase() === 'maintenance') {
                            if (!vm.canViewMaintenanceSession) {
                                // no permission, exit
                                return;
                            }
                            
                            data.id = vm.vehicle.equipmentStatusId;
                            relatedId = vm.vehicle.sourceEquipmentEventId;

                        } else if (!vm.canCreateMaintenanceSession) {
                            // not in maintenance, and they don't have permissions to created one, so just exit
                            return;
                        }

                        WindowFactory.openItem({
                            component: 'maintenance',
                            headerOff: false,
                            footerOff: true,
                            canMinimise: true,
                            canClose: true,
                            caption: amtXlatSvc.xlat('equipment.maintenanceSession'),
                            initParams: data,
                            windowRelatedRecordId: relatedId, // use the vehicleId (with an empty guid attached to separate from vehicle details) until the checkin occurs
                            height: 700 + WindowFactory.headerHeight,
                            width: 1100,
                            onDataChangeHandler: vm.loadDetails,
                            modal: false
                        });
                    };

                    // This is scope level so that ocEquipmentHistory can access it
                    $scope.transfer = function () {

                        // check permissions 
                        if (vm.canTransfer) {

                            $scope.openModule(
                                'transfer-vehicle-popup',
                                amtXlatSvc.xlat("vehicle.transferVehicle", vm.vehicle.serialNumber.formatted),
                                null,
                                {
                                    vehicle: vm.vehicle.id,
                                    siteId: vm.siteId,
                                    clientId: vm.clientId
                                },
                                800,
                                null,
                                true,
                                vm.loadDetails,
                                false,
                                false
                            );

                        }
                    };

                    vm.undo = function () {
                        if (vm.vehicle.isInTransfer) {
                            undoTransfer();
                        } else if (vm.vehicle.isReceive) {
                            if (vm.canUndoReceive) {
                                undoReceive();
                            }
                        } else {
                            undoEvent();
                        }
                    };

                    function undoEvent() {

                        var undoCriteria = {
                            equipmentStatusId: vm.vehicle.equipmentStatusId
                        };

                        confirmSvc.confirmMessage('vehicle.confirmUndo_title', 'vehicle.confirmUndo')
                            .then(function () {

                                vm.wnd.processing = true;

                                amtCommandQuerySvc.post(vm.apiUrls.undoMoveEvents, [undoCriteria]).then(function (response) {

                                    notifySvc.success(amtXlatSvc.xlat("vehicle.messageUndoStatusSuccess"));
                                    vm.forms.details.$dirty = false;
                                    vm.loadDetails();
                                    $scope.refreshSearch();

                                }).catch(function (error) {
                                    errorReporter.logError(error);
                                }).finally(function () {
                                    vm.wnd.processing = false;
                                });
                            });
                    };

                    function undoTransfer() {

                        var undoCriteria = {
                            equipmentStatusId: vm.vehicle.equipmentStatusId
                        };

                        confirmSvc.confirmMessage('vehicle.confirmUndoTransfer_title', 'vehicle.confirmUndoTransfer')
                            .then(function () {

                                vm.wnd.processing = true;

                                amtCommandQuerySvc.post(vm.apiUrls.undoTransfer, undoCriteria).then(function (response) {

                                    notifySvc.success(amtXlatSvc.xlat("vehicle.messageUndoTransferSuccess"));
                                    vm.forms.details.$dirty = false;
                                    vm.loadDetails();
                                    $scope.refreshSearch();

                                }).catch(function (error) {
                                    errorReporter.logError(error);
                                }).finally(function () {
                                    vm.wnd.processing = false;
                                });
                            });
                    };

                    function undoReceive() {

                        var undoCriteria = {
                            equipmentStatusId: vm.vehicle.equipmentStatusId
                        };

                        confirmSvc.confirmMessage('vehicle.confirmUndoVehicleReceive_title',
                            'vehicle.confirmUndoVehicleReceive').then(function () {

                                vm.showActionMenu = false;
                                vm.wnd.processing = true;

                                amtCommandQuerySvc.post(vm.apiUrls.undoReceive, undoCriteria).then(function (response) {

                                    notifySvc.success(amtXlatSvc.xlat("vehicle.messageUndoReceiveSuccess"));
                                    vm.forms.details.$dirty = false;
                                    $scope.refreshSearch();
                                    $scope.onClose();

                                }).catch(function (error) {
                                    errorReporter.logError(error);
                                }).finally(function () {
                                    vm.wnd.processing = false;
                                });
                            });
                    };

                    vm.historyReport = function () { };

                    vm.openChangeStatus = function (type, mode, width) {

                        $scope.openModule(
                            'vehicle-change-status-popup',
                            amtXlatSvc.xlat("vehicle.statusChangeHeader", vm.vehicle.serialNumber.formatted),
                            null,
                            {
                                mode: mode,
                                type: type,
                                vehicle: vm.vehicle,
                                equipmentEventId: vm.equipmentEventId
                            },
                            (width || 750),
                            null,
                            true,
                            (mode === 'new' ? vm.loadDetails : $scope.refreshAll),
                            false,
                            false
                        );
                    };

                    vm.outOfService = function (mode) {
                        vm.openChangeStatus('out of service', (mode || 'new'));
                    };

                    vm.dispose = function (mode) {
                        vm.openChangeStatus('disposed', (mode || 'new'));
                    };

                    vm.production = function (mode) {
                        vm.openChangeStatus('production', (mode || 'new'));
                    };

                    vm.tabs = {
                        details: { canSave: !vm.readonly_details, readonly: vm.readonly_details },
                        history: {},
                        fitments: {},
                        attachment: {},
                        comment: {}
                    };

                    $scope.onSave = function () {
                        if (vm.currentTab.save !== undefined) {
                            vm.currentTab.save();
                        }
                    };

                    $scope.onAdd = function () {
                        if (vm.currentTab.add !== undefined) {
                            vm.currentTab.add();
                        }
                    };

                    $scope.refreshAll = function () {
                        $scope.onRefresh(true);
                    };

                    $scope.onRefresh = function (refreshDetails?) {
                        if (vm.currentTab.refresh !== undefined) {
                            vm.currentTab.refresh();
                        }

                        if (refreshDetails) {
                            vm.loadDetails();
                        }
                    };

                    // watch this to update the button state
                    $scope.$watch('$ctrl.forms.details.$invalid', function () {
                        determineSaveButtonState();
                    });

                    // watch this to update the button state
                    $scope.$watch('$ctrl.forms.details.$dirty', function () {
                        determineSaveButtonState();
                    });

                    $scope.$watch('vm.wnd.processing', function () {
                        determineSaveButtonState();
                    });

                    $scope.$watch('vm.currentTab.canSave', function () {
                        vm.buttonStates.saveButton.visible = vm.currentTab && vm.currentTab.canSave;
                    });

                    $scope.$watch('vm.currentTab.canRefresh', function () {
                        vm.buttonStates.refreshButton.visible = vm.currentTab && vm.currentTab.canRefresh;
                    });

                    $scope.$watch('vm.currentTab.canAdd', function () {
                        vm.buttonStates.addButton.visible = vm.currentTab && vm.currentTab.canAdd;
                    });

                    $scope.$watch('vm.currentTab.canExport', function () {
                        vm.buttonStates.exportButton.visible = vm.currentTab && vm.currentTab.canExport;
                    });

                    // TODO: WARNING - This (deep watch) causes an infinite loop when trying to open the schematic full screen.
                    // No idea why. Schematic is complex, putting on the backburner for now.
                    $scope.$watch('vm.vehicle', function () {
                        vm.buttonStates.transferButton.visible = vm.vehicle && vm.vehicle.isTransferable && vm.canTransfer && ocConfigSvc.user.site.active;
                        vm.buttonStates.undoButton.visible = vm.vehicle && vm.vehicle.canUndo && ((vm.vehicle.isReceive && vm.canUndoReceive) || (!vm.vehicle.isReceive && vm.canChangeStatus)) && ocConfigSvc.user.site.active;
                        vm.buttonStates.outOfServiceButton.visible = vm.vehicle && (vm.vehicle.inService || vm.vehicle.disposed) && vm.canChangeStatus && ocConfigSvc.user.site.active;
                        vm.buttonStates.productionButton.visible = vm.vehicle && vm.canChangeStatus && (vm.vehicle.outOfService || vm.vehicle.disposed) && ocConfigSvc.user.site.active;
                        vm.buttonStates.maintenanceButton.visible = vm.vehicle && (vm.vehicle.maintenance || vm.vehicle.inService) && (vm.vehicle.maintenance ? vm.canViewMaintenanceSession : vm.canCreateMaintenanceSession) && ocConfigSvc.user.site.active;
                        vm.buttonStates.disposeButton.visible = vm.vehicle && vm.canChangeStatus && (vm.vehicle.inService || vm.vehicle.outOfService) && !vm.vehicle.maintenance && !vm.vehicle.isInTransfer && ocConfigSvc.user.site.active;
                        vm.buttonStates.historyButton.visible = vm.vehicle && vm.canViewHistoryReport;
                    }, true);

                    function determineSaveButtonState() {
                        if (vm.forms.details.$invalid || vm.wnd.processing) {
                            // Can't save if the form is invalid
                            vm.buttonStates.saveButton.disabled = true;
                        } else {
                            // Can only save if the form is dirty
                            vm.buttonStates.saveButton.disabled = !vm.forms.details.$dirty;
                        }
                    }

                    // Update which buttons are shown on the dialog
                    // remarks: currentTab is set after getting a response from the server w/ vehicle details and before status is changed to ready
                    $scope.$watch('vm.currentTab', function () {
                        if (vm.vehicle) {
                            switch (vm.currentTab) {
                                case vm.tabs.details:
                                    vm.buttons = [
                                        {
                                            primary: true,
                                            cancel: false,
                                            value: 'common.save_label',
                                            name: 'saveButton',
                                            click: 'updateDetails',
                                            type: enums.buttonTypes.button
                                        },
                                        {
                                            title: 'equipment.maintenanceSession',
                                            click: 'maintenanceSession',
                                            name: 'maintenanceButton',
                                            type: enums.buttonTypes.action
                                        },
                                        {
                                            title: 'equipment.transferOffSite',
                                            click: 'transfer',
                                            name: 'transferButton',
                                            type: enums.buttonTypes.action
                                        },
                                        {
                                            title: 'vehicle.production',
                                            click: 'production',
                                            name: 'productionButton',
                                            type: enums.buttonTypes.action
                                        },
                                        {
                                            title: 'vehicle.outOfService',
                                            click: 'outOfService',
                                            name: 'outOfServiceButton',
                                            type: enums.buttonTypes.action
                                        },
                                        {
                                            title: 'vehicle.dispose',
                                            click: 'dispose',
                                            name: 'disposeButton',
                                            type: enums.buttonTypes.action
                                        },
                                        {
                                            title: (vm.vehicle.isReceive ? 'vehicle.undoReceive' : (vm.vehicle.isInTransfer ? 'vehicle.undoTransfer' : 'vehicle.undo')),
                                            click: 'undo',
                                            name: 'undoButton',
                                            type: enums.buttonTypes.action
                                        }
                                    ];
                                    break;
                                case vm.tabs.fitments:
                                    vm.buttons = [{
                                        title: 'equipment.maintenanceSession',
                                        click: 'maintenanceSession',
                                        name: 'maintenanceButton',
                                        type: enums.buttonTypes.action
                                    }];
                                    break;
                                case vm.tabs.history:
                                    vm.buttons = [
                                        {
                                            primary: true,
                                            cancel: false,
                                            value: 'common.refresh',
                                            name: 'refreshButton',
                                            click: 'refresh',
                                            type: enums.buttonTypes.button
                                        },
                                        {
                                            title: 'equipment.maintenanceSession',
                                            click: 'maintenanceSession',
                                            name: 'maintenanceButton',
                                            type: enums.buttonTypes.action
                                        },
                                        {
                                            title: 'equipment.transferOffSite',
                                            click: 'transfer',
                                            name: 'transferButton',
                                            type: enums.buttonTypes.action
                                        },
                                        {
                                            title: 'common.exportToExcel',
                                            click: 'export',
                                            name: 'exportButton',
                                            type: enums.buttonTypes.action
                                        }];
                                    break;
                                case vm.tabs.attachment:
                                case vm.tabs.comment:
                                    vm.buttons = [
                                        {
                                            primary: true,
                                            cancel: false,
                                            value: "common.add_label",
                                            name: "addButton",
                                            click: "add",
                                            type: enums.buttonTypes.button
                                        },
                                        {
                                            title: "equipment.maintenanceSession",
                                            click: "maintenanceSession",
                                            name: "maintenanceButton",
                                            type: enums.buttonTypes.action
                                        },
                                        {
                                            title: 'equipment.transferOffSite',
                                            click: 'transfer',
                                            name: 'transferButton',
                                            type: enums.buttonTypes.action
                                        }//,
                                        //{
                                        //    title: "equipment.historyReport",
                                        //    click: "historyReport",
                                        //    name: "historyButton",
                                        //    type: enums.buttonTypes.action
                                        //}
                                    ];
                                    break;
                                default:
                                    console.warn("Unknown Tab");
                                    vm.buttons = [];
                            }
                        } else {
                            console.warn("Vehicle not set");
                            vm.buttons = [];
                        }

                        if (vm.currentTab) {
                            if (vm.currentTab.refresh) {
                                vm.buttonMethods.refresh = vm.currentTab.refresh;
                            }

                            if (vm.currentTab.add) {
                                vm.buttonMethods.add = vm.currentTab.add;
                            }

                            if (vm.currentTab.export) {
                                vm.buttonMethods.export = vm.currentTab.export;
                            }
                        }
                    });
                }
            ]
        });
