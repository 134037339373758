//import angular from 'angular';
import tmpl from './details.html';
import HelperSvc from '../../../services/helperSvc';


angular.module('app.admin').component('backupStockLevelDetails', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['$scope', '$state', 'helperSvc', '$window', '$route', 'amtCommandQuerySvc', 'enums', 'amtXlatSvc',
            'backupStockLevelUrls', 'confirmSvc', 'WindowFactory', '$timeout', 'notifySvc', 'ocSecuritySvc',
            function ($scope, $state, helperSvc: HelperSvc, $window, $route, amtCommandQuerySvc, enums, xlatSvc, apiUrls, confirmSvc,
                WindowFactory: IWindowFactory, $timeout: ng.ITimeoutService, notifySvc, ocSecuritySvc) {

                var vm = this;
                $scope.vm = this;

                vm.status = enums.screenStatus.loading;

                // set urls
                vm.apiUrls = apiUrls;

                vm.backupStockLevelDetails = {};

                this.$onInit = function () {
                    if (vm.initParams !== null) {
                        vm.backupStockLevelId = vm.initParams.backupStockLevelId;
                        vm.isEdit = vm.initParams.isEdit;
                        vm.siteId = vm.initParams.siteId;
                    }

                    // security
                    vm.readonly = !(vm.isEdit ?
                        ocSecuritySvc.isAuthorised('Security.Settings.BackupStockLevels', 'readWrite') :
                        ocSecuritySvc.isAuthorised('Security.Settings.BackupStockLevels.Add', 'readWrite')
                    );

                    if (vm.backupStockLevelId !== null && vm.backupStockLevelId !== undefined) {
                        vm.loadBackupStockLevelDetails();
                    } else {
                        vm.status = enums.screenStatus.ready;
                    }

                    vm.wnd.onClose = vm.onClose;

                    vm.labelColSpan = 4;
                    vm.rightBufferColSpan = 1;
                    vm.dataColSpan = (12 - vm.rightBufferColSpan - vm.labelColSpan);

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: 'common.save_label',
                            name: 'saveButton',
                            click: 'save',
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: true,
                            value: 'common.cancel_label',
                            name: 'cancelButton',
                            click: 'cancel',
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: true,
                            disabled: true
                        },
                        cancelButton: {
                            visible: true,
                            disabled: false
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.onSave,
                        cancel: vm.onClose
                    };
                };

                $scope.setForm = function () {
                    // add a watch to the dirty flag
                    $scope.$watch('vm.form.$dirty', function () {
                        vm.wnd.isDirty = vm.form.$dirty;
                    });
                };

                vm.onSave = function () {

                    vm.wnd.processing = true;

                    vm.saveCriteria = {
                        id: vm.backupStockLevelId,
                        isEdit: vm.isEdit,
                        siteId: vm.siteId,
                        quantity: vm.backupStockLevelDetails.quantity,
                        equipmentTypeId: helperSvc.getKey(vm.backupStockLevelDetails.equipmentType),
                        equipmentConstructionId: helperSvc.getKey(vm.backupStockLevelDetails.equipmentConstruction),
                        equipmentSizeId: helperSvc.getKey(vm.backupStockLevelDetails.equipmentSize),
                        specifications: vm.backupStockLevelDetails.specificationsIds
                    };

                    if (vm.isEdit) {
                        modifyBackupStockLevel(vm.saveCriteria);
                    } else {
                        addBackupStockLevel(vm.saveCriteria);
                    }
                };

                function addBackupStockLevel(model) {
                    amtCommandQuerySvc.post(vm.apiUrls.addBackupStockLevel, model).then(function () {

                        notifySvc.success(xlatSvc.xlat("backupStockLevels.saveSuccessful"));
                        vm.form.$setPristine();

                        if (vm.wnd.onDataChanged) {
                            vm.wnd.onDataChanged();
                        }
                        closeWindow();

                    }, amtCommandQuerySvc.handleError).finally(function () {
                        vm.wnd.processing = false;
                    });
                }

                function modifyBackupStockLevel(model) {
                    amtCommandQuerySvc.post(vm.apiUrls.modifyBackupStockLevel, model).then(function () {

                        notifySvc.success(xlatSvc.xlat("backupStockLevels.saveSuccessful"));
                        vm.form.$setPristine();
                        if (vm.wnd.onDataChanged) {
                            vm.wnd.onDataChanged();
                        }
                        closeWindow();

                    }, amtCommandQuerySvc.handleError).finally(function () {
                        vm.wnd.processing = false;
                    });
                }

                vm.loadBackupStockLevelDetails = function () {

                    vm.wnd.processing = true;

                    var criteria = {
                        id: vm.backupStockLevelId
                    };

                    amtCommandQuerySvc.post(vm.apiUrls.getDetails, criteria).then(function (response) {
                        if (response) {
                            vm.backupStockLevelDetails = response;

                            vm.status = enums.screenStatus.ready;
                            $timeout(function () { vm.form.$setPristine(); });
                        }
                    }, function (error) {
                        amtCommandQuerySvc.handleError(error);
                        vm.status = enums.screenStatus.error;
                    }).finally(function () {
                        vm.wnd.processing = false; 
                    });
                };

                vm.onComponentTypeChange = function () {
                    vm.backupStockLevelDetails.equipmentSize = null;
                    vm.backupStockLevelDetails.equipmentConstruction = null;
                    vm.backupStockLevelDetails.specifications = [];
                };

                vm.onSizeChange = function () {
                    vm.backupStockLevelDetails.specifications = [];
                };

                vm.onConstructionChange = function () {
                    vm.backupStockLevelDetails.specifications = [];
                };

                function closeWindow() {
                    WindowFactory.closeWindow(vm.wnd);
                }

                vm.onClose = function () {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        vm.form.dirty = false;
                        closeWindow();
                        return true;
                    });

                    return false;
                };

                $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing', 'vm.readonly'], function () {
                    vm.buttonStates.saveButton.visible = !vm.readonly;
                    vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.form.$pristine || vm.wnd.processing;
                });
            }
        ]
    });
