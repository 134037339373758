/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * Renders the list item content. To define the item template, nest an `<ng-template>` tag
 * with the `kendo<ComponentName>ItemTemplate` directive inside the component tag. The template context is
 * set to the current component. To get a reference to the current data item, use the `let-dataItem` directive.
 *
 * - [Using `ItemTemplate` with the AutoComplete]({% slug templates_autocomplete %}#toc-item-template)
 * - [Using `ItemTemplate` with the ComboBox]({% slug templates_combobox %}#toc-item-template)
 * - [Using `ItemTemplate` with the DropDownList]({% slug templates_ddl %}#toc-item-template)
 * - [Using `ItemTemplate` with the MultiSelect]({% slug templates_multiselect %}#toc-item-template)
 *
 * @example
 * ```ts
 * _@Component({
 * selector: 'my-app',
 * template: `
 *  <kendo-combobox [data]="listItems">
 *    <ng-template kendoComboBoxItemTemplate let-dataItem>
 *      <span>{{dataItem}} option</span>
 *    </ng-template>
 *  </kendo-combobox>
 * `
 * })
 * class AppComponent {
 *   public listItems: Array<string> = ["Item 1", "Item 2", "Item 3", "Item 4"];
 * }
 * ```
 */
var ItemTemplateDirective = /** @class */ (function () {
    function ItemTemplateDirective(templateRef) {
        this.templateRef = templateRef;
    }
    ItemTemplateDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoDropDownListItemTemplate],[kendoComboBoxItemTemplate],[kendoAutoCompleteItemTemplate],[kendoMultiSelectItemTemplate]'
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.TemplateRef])
    ], ItemTemplateDirective);
    return ItemTemplateDirective;
}());
exports.ItemTemplateDirective = ItemTemplateDirective;
