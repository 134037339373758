//import angular from 'angular';
import * as _ from 'underscore';
import tmpl from "./vehicleReceivePopup.html";



    angular.module("app.equipment").component("vehicleReceivePopup", {
        bindings: {
            initParams: "=",
            buttonMethods: "=",
            buttonStates: "=",
            buttons: "=",
            closeOnSave: "=",
            wnd: "="
        },
        template: tmpl,
        controller: ["$scope", "confirmSvc", "WindowFactory", "dataBroker", "amtXlatSvc",
            "errorReporter", "ocConfigSvc", "$timeout", "enums", "$q", "amtConstants", "vehicleReceiveFactory",
            function ($scope, confirmSvc, windowFactory, dataBroker, xlatSvc,
                errorReporter, ocConfigSvc, $timeout, enums, $q, amtConstants, vehicleReceiveFactory) {

                var vm = this;
                $scope.vm = this;
                vm.config = ocConfigSvc;

                vm.errorMessage = undefined;

                vm.showChains = vm.config.user.site.settings.tyreMaintenance.showChains;

                this.$onInit = function () {

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: "common.save_label",
                            name: "saveButton",
                            click: "save",
                            type: enums.buttonTypes.button
                        },
                        {
                            primary: false,
                            cancel: true,
                            value: "common.cancel_label",
                            name: "cancelButton",
                            click: "cancel",
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: true,
                            disabled: true
                        },
                        cancelButton: {
                            visible: true,
                            disabled: false
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.save,
                        cancel: vm.onCancel
                    };

                    if (vm.initParams) {
                        vm.vehicle = angular.copy(vm.initParams.vehicle);                        
                        vm.referenceData = vm.initParams.referenceData;
                        vm.selectedCondition = vm.initParams.selectedCondition;
                        vm.serials = vm.initParams.serials;
                    }

                    if (vm.selectedCondition.showFittedComponents) {

                        vm.fittedComponentColumns = vehicleReceiveFactory.getFittedComponentColumns();

                        if (!vm.vehicle.fittedComponents || vm.vehicle.fittedComponents.length === 0) {

                            vm.wnd.processing = true;

                            dataBroker.getVehiclePositionFitments([vm.vehicle.id], vm.config.user.site.id, null).then(function (response) {

                                if (response && response.result) {

                                    // map fitments
                                    vm.vehicle.fittedComponents = response.result.map(p => {
                                        return {
                                            wheelPosition: p.wheelPositionLabel,
                                            tyre: _.find(p.fitments, f => f.equipmentTypeName.toLowerCase() === 'tyre'),
                                            rim: _.find(p.fitments, f => f.equipmentTypeName.toLowerCase() === 'rim'),
                                            chain: _.find(p.fitments, f => f.equipmentTypeName.toLowerCase() === 'chain')
                                        }
                                    });

                                    vm.vehicle.hasRims = _.any(vm.vehicle.fittedComponents, c => {
                                        return c.rim;
                                    });

                                    vm.vehicle.hasChains = _.any(vm.vehicle.fittedComponents, c => {
                                        return c.chain;
                                    });

                                    vm.initParams.vehicle.fittedComponents = angular.copy(vm.vehicle.fittedComponents);
                                    vm.initParams.vehicle.hasRims = angular.copy(vm.vehicle.hasRims);
                                    vm.initParams.vehicle.hasChains = angular.copy(vm.vehicle.hasChains);
                                }
                            }).finally(function () {
                                vm.wnd.processing = false;
                            });
                        }
                    }

                    vm.wnd.onClose = vm.onCancel;

                    $timeout(function () {
                        vm.form.$setPristine();
                    });
                };

                // local functions
                vm.save = function () {

                    vm.wnd.processing = true;

                    return vm.validate().then(function () {

                        if (!vm.errorMessage) {

                            vm.vehicle.valid = true;
                            vm.vehicle.selected = true;

                            if (vm.wnd.onDataChanged)
                                vm.wnd.onDataChanged(vm.vehicle);                            

                            closeWindow("save");
                        }
                    }).finally(function () {
                        $timeout(function () {
                            vm.wnd.processing = false;
                        });
                    });
                };

                vm.validate = function () {
                    return vehicleReceiveFactory.validateVehicle(vm.vehicle, vm.selectedCondition, vm.serials).then(function (error) {
                        vm.errorMessage = error;                       
                    });
                };

                vm.onCancel = function () {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        vm.form.$dirty = false;
                        closeWindow("cancel");
                        return true;
                    });

                    return false;
                };
                // end local functions

                // scope functions
                $scope.setForm = function () {
                    $scope.$watch("vm.form.$dirty", function () {
                        // TODO: I think one of the AMT controls is a bit broken and making this dirty on initialise...
                        vm.wnd.isDirty = vm.form.$dirty;
                    });
                };
                // end scope functions             

                $scope.$watchGroup(['vm.form.$invalid', 'vm.wnd.processing', 'vm.form.$pristine'], function () {
                    vm.buttonStates.saveButton.disabled = (vm.form.$invalid === true || vm.wnd.processing === true || vm.form.$pristine);
                });
                // end watches

                // other stuff
                function closeWindow(closeReason) {
                    $timeout(function () {
                        windowFactory.closeWindow(vm.wnd);
                    });
                }
            }
        ]
    });
