/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var navigation_service_1 = require("./../../navigation/navigation.service");
var core_1 = require("@angular/core");
var filter_service_1 = require("../filter.service");
var column_component_1 = require("../../columns/column.component");
var single_popup_service_1 = require("../../common/single-popup.service");
var base_filter_cell_component_1 = require("../base-filter-cell.component");
var kendo_angular_l10n_1 = require("@progress/kendo-angular-l10n");
var utils_1 = require("../../utils");
/**
 * @hidden
 */
var FilterMenuComponent = /** @class */ (function () {
    function FilterMenuComponent(filterService, popupService, localization, navigationService) {
        this.filterService = filterService;
        this.popupService = popupService;
        this.localization = localization;
        this.navigationService = navigationService;
        this.tabIndex = '-1';
    }
    Object.defineProperty(FilterMenuComponent.prototype, "hasFilters", {
        get: function () {
            return base_filter_cell_component_1.filtersByField(this.filter, (this.column || {}).field).length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterMenuComponent.prototype, "filterLabel", {
        /**
         * @hidden
         */
        get: function () {
            var localizationMsg = this.localization.get('filterMenuTitle') || '';
            var columnName = this.column.title || this.column.field;
            return utils_1.replaceMessagePlaceholder(localizationMsg, 'columnName', columnName);
        },
        enumerable: true,
        configurable: true
    });
    FilterMenuComponent.prototype.toggle = function (anchor, template) {
        this.popupRef = this.popupService.open(anchor, template, this.popupRef);
        if (!this.popupRef) {
            if (this.navigationService.enabled) {
                this.navigationService.focusCell(0, this.column.leafIndex);
            }
            else {
                this.anchor.nativeElement.focus();
            }
        }
        return false;
    };
    FilterMenuComponent.prototype.close = function () {
        this.popupService.destroy();
        if (this.navigationService.enabled) {
            this.navigationService.focusCell(0, this.column.leafIndex);
        }
        else {
            this.anchor.nativeElement.focus();
        }
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", column_component_1.ColumnComponent)
    ], FilterMenuComponent.prototype, "column", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Object)
    ], FilterMenuComponent.prototype, "filter", void 0);
    tslib_1.__decorate([
        core_1.ViewChild('anchor', { static: true }),
        tslib_1.__metadata("design:type", core_1.ElementRef)
    ], FilterMenuComponent.prototype, "anchor", void 0);
    tslib_1.__decorate([
        core_1.ViewChild('template', { static: true, read: core_1.TemplateRef }),
        tslib_1.__metadata("design:type", core_1.TemplateRef)
    ], FilterMenuComponent.prototype, "template", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], FilterMenuComponent.prototype, "tabIndex", void 0);
    FilterMenuComponent = tslib_1.__decorate([
        core_1.Component({
            selector: 'kendo-grid-filter-menu',
            template: "\n        <a #anchor\n            [ngClass]=\"{'k-grid-filter':true, 'k-state-active': hasFilters}\"\n            [tabindex]=\"tabIndex\"\n            (click)=\"toggle(anchor, template)\"\n            (keydown.enter)=\"$event.stopImmediatePropagation()\"\n            href=\"#\"\n            [attr.title]=\"filterLabel\">\n            <span class=\"k-icon k-i-filter\"></span>\n        </a>\n        <ng-template #template>\n            <kendo-grid-filter-menu-container\n                [column]=\"column\"\n                [filter]=\"filter\"\n                (close)=\"close()\"\n                (keydown.escape)=\"close()\"\n                (keydown.enter)=\"$event.stopImmediatePropagation()\"\n                >\n            </kendo-grid-filter-menu-container>\n        </ng-template>\n    "
        }),
        tslib_1.__metadata("design:paramtypes", [filter_service_1.FilterService,
            single_popup_service_1.SinglePopupService,
            kendo_angular_l10n_1.LocalizationService,
            navigation_service_1.NavigationService])
    ], FilterMenuComponent);
    return FilterMenuComponent;
}());
exports.FilterMenuComponent = FilterMenuComponent;
