/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * @hidden
 */
var DomEventsService = /** @class */ (function () {
    function DomEventsService() {
        this.cellClick = new core_1.EventEmitter();
        this.cellMousedown = new core_1.EventEmitter();
        this.cellMouseup = new core_1.EventEmitter();
        this.click = new core_1.EventEmitter();
        this.keydown = new core_1.EventEmitter();
        this.focus = new core_1.EventEmitter();
        this.focusIn = new core_1.EventEmitter();
        this.focusOut = new core_1.EventEmitter();
        this.windowBlur = new core_1.EventEmitter();
    }
    DomEventsService = tslib_1.__decorate([
        core_1.Injectable()
    ], DomEventsService);
    return DomEventsService;
}());
exports.DomEventsService = DomEventsService;
