//import angular from 'angular';
import * as _ from 'underscore';
import tmpl from './errorPanel.html'


    angular.module('app.directives').component('errorPanel', {
        template: tmpl,
        bindings: {
            validationObject: '=',
            errors: '=',
            readonly: '=',
            processing: '=', // this is used by the component to indicate that it is processing - doing validation or something
            api: '=', // this is an API used for interacting with this process.
            currentError: '=',
            showSave: "=",
            showSaveAndNext: "=",
            saveDisabled: "=",
            saveCallback: "&?",
            nextCallback: "&?",
            showFullError: "=",
            maxDetailRows: "=?"            
        },
        controller: [
            '$scope', 'amtXlatSvc', 'WindowFactory', '$q', 'errorReporter',
            function errorPanelCtrl($scope: ng.IScope, amtXlatSvc: IAmtXlatSvc, WindowFactory: IWindowFactory, $q: ng.IQService, errorReporter: IErrorReporter) {

                var ctrl = this;

                ctrl.$onInit = function () {

                    ctrl.status = 'OK';
                    ctrl.index = 0;

                    if (!ctrl.maxDetailRows) {
                        ctrl.maxDetailRows = 5;
                    }

                    ctrl.api = ctrl.api || {};
                    ctrl.api.updateStatus = updateStatus;
                    ctrl.api.removeError = removeError;
                    ctrl.api.checkError = checkError;
                    ctrl.api.appendErrors = appendErrors;
                    ctrl.api.evaluateErrors = evaluateErrors;

                    ctrl.api.initialised = true;

                    evaluateErrors();
                };

                function removeError(index) {

                    if (index === undefined || index === null) {
                        index = ctrl.index;
                    }

                    ctrl.errors.splice(index, 1);
                    reindexErrors();

                    updateStatus();
                };

                function appendErrors(types, value, indexes) {

                    if (ctrl.errors) {
                        for (var i = 0; i < ctrl.errors.length; i++) {
                            if (indexes && indexes.length && indexes.indexOf(i) > -1) {

                                if (types && types.length) {
                                    if (ctrl.errors[i].detailedParams && ctrl.errors[i].detailedParams.length) {

                                        var details = ctrl.errors[i].detailedParams.filter(p => types.indexOf(p.type || p.Type) >= 0).map(p => p.value || p.Value);

                                        if (details && details.length === types.length) {
                                            ctrl.errors[i].ErrorMessage = ctrl.errors[i].ErrorMessage + String.format(value, ...details);
                                        }
                                    }
                                } else {
                                    ctrl.errors[i].ErrorMessage = ctrl.errors[i].ErrorMessage + value;
                                }
                            }
                        }
                    }

                    updateStatus();
                }

                function updateStatus() {

                    if (!ctrl.errors) {
                        ctrl.errors = [];
                    }

                    switch (ctrl.errors.length) {
                        case 0:
                            ctrl.status = amtXlatSvc.xlat('common.errorPanel_ok');
                            break;
                        case 1:
                            ctrl.status = '1 ' + amtXlatSvc.xlat('common.errorPanel_error');
                            break;
                        default:
                            ctrl.status = ctrl.errors.length + ' ' + amtXlatSvc.xlat('common.errorPanel_errors');
                            break;
                    }

                    // index is the index of the displayed error / update the display
                    if (ctrl.index > ctrl.errors.length - 1) {
                        ctrl.index = 0;
                    }

                    ctrl.currentError = (ctrl.errors && ctrl.errors.length > 0 ? angular.copy(_.find(ctrl.errors, function (e) { return e.index === ctrl.index; })) : null);

                    if (ctrl.currentError) {

                        ctrl.currentError.display = '';

                        var msg = angular.copy(ctrl.currentError.ErrorMessage);

                        if (ctrl.currentError.rowNo && ctrl.maxDetailRows > 1) {
                            ctrl.currentError.display = amtXlatSvc.xlat('common.errorPanel_no') + ctrl.currentError.rowNo + '</br>';
                        }

                        ctrl.currentError.display += msg;
                    }
                }

                function checkError(type, value, currentOnly, recordNo) {

                    var hasError: boolean = false;
                    var errorIndexes = [];

                    if (currentOnly === true) {
                        if (ctrl.currentError.detailedParams && ctrl.currentError.detailedParams.length > 0) {
                            var detail = _.find(ctrl.currentError.detailedParams, function (p) { return (p.type || p.Type) === type; });
                            if (detail && (detail.value || detail.Value) === value) {
                                hasError = true;
                                errorIndexes.push(ctrl.index);
                            }
                        }
                    } else if (ctrl.errors) {
                        for (var i = 0; i < ctrl.errors.length; i++) {
                            if (ctrl.errors[i].detailedParams && ctrl.errors[i].detailedParams.length > 0) {
                                var detail = _.find(ctrl.errors[i].detailedParams, function (p) { return (p.type || p.Type) === type; });
                                if (detail && (detail.value || detail.Value) === value) {
                                    hasError = true;
                                    errorIndexes.push(i);
                                }
                            }
                        }
                    }

                    if (hasError && recordNo && errorIndexes.length > 0) {
                        for (var e = 0; e < errorIndexes.length; e++) {
                            ctrl.errors[errorIndexes[e]].rowNo = recordNo;

                            if (ctrl.index === errorIndexes[e]) {
                                updateStatus();
                            }
                        }
                    }

                    if (errorIndexes.length === 0) {
                        errorIndexes = null;
                    }

                    return errorIndexes;
                };

                ctrl.previous = function () {
                    ctrl.index--;

                    if (ctrl.index < 0) {
                        ctrl.index = 0;
                    }

                    updateStatus();
                };

                ctrl.next = function () {
                    ctrl.index++;

                    if (ctrl.index >= ctrl.errors.length) {
                        ctrl.index = ctrl.errors.length - 1;
                    }

                    updateStatus();
                };

                ctrl.details = function () {
                    if (ctrl.errors && ctrl.errors[ctrl.index]) {

                        ctrl.validationObject.errorData = ctrl.errors[ctrl.index].item;

                        if (ctrl.errors[ctrl.index].rule) {
                            if (ctrl.errors[ctrl.index].rule.outcome) {
                                ctrl.errors[ctrl.index].rule.outcome(ctrl.validationObject).catch(function () { });
                            } else {
                                return WindowFactory.alert('exception.serverValidationErrorTitle', ['common.ok_label'], 'exception.serverValidationError',
                                    ctrl.errors[ctrl.index].rule.name);
                            }
                        } else if (ctrl.errors[ctrl.index].RawErrorMessage) {
                            return WindowFactory.alert('exception.serverValidationErrorTitle', ['common.ok_label'], ctrl.errors[ctrl.index].RawErrorMessage, null, null, null, true);
                        }

                    } else {
                        return WindowFactory.alert('fieldSurvey.noCurrentError', ['common.ok_label'], 'fieldSurvey.noCurrentErrorPosition');
                    }
                };

                // fire and forget save, dont wait for the promise to complete/fail
                ctrl.save = function (andNext) {
                    // done this way because we need to prevent the "possibly unhandled promise rejection" error
                    ctrl.saveAsync(andNext).then(function () { }, function (error) {
                        if (error) errorReporter.logError(error);
                    });
                };

                ctrl.saveAsync = function (andNext) {
                    return $q(function (resolve, reject) {
                        // call the save function
                        if (ctrl.saveCallback) {

                            ctrl.saveCallback({ andNext: andNext }).then(function (result) {
                                if (evaluateErrors()) {
                                    // no errors
                                    if (andNext === true && ctrl.nextCallback) {
                                        // move to the next item
                                        ctrl.nextCallback().then(resolve, reject);
                                    } else {
                                        return resolve();
                                    }
                                } else {
                                    return reject();
                                }
                            }).catch(function (error) {
                                if (error === 'ValidateAbort' || (error && (error.ValidateAbort || error.message === "0" || error.number === 0))) {
                                    // not an error, just closing error window. absorb this.
                                } else {                                    
                                    return reject(error);
                                }
                            });
                        } else if (ctrl.api && ctrl.api.save) {
                            ctrl.api.save().then(function (result) {
                                if (evaluateErrors()) {
                                    return resolve();
                                } else {
                                    return reject(amtXlatSvc.xlat("exception.cantMoveNextDueToError"));
                                }
                            }).catch(function (error) {
                                if (error === 'ValidateAbort' || (error && (error.ValidateAbort || error.message === "0" || error.number === 0))) {
                                    // not an error, just closing error window. absorb this.
                                } else {
                                    return reject(error);
                                }
                            });
                        } else {
                            // nothing to do, return a resolved promise
                            return resolve();
                        }
                    });
                }

                function evaluateErrors() {

                    var result = true; // return true if there was no error

                    if (!ctrl.errors) {
                        ctrl.errors = [];
                    }

                    if (ctrl.errors.length > 0) {

                        result = false;

                        for (var i = 0; i < ctrl.errors.length; i++) {

                            // if client
                            if (ctrl.errors[i].rule && !ctrl.errors[i].RawErrorMessage) {

                                // find matching server error and copy rule over
                                var matchedIndex = _.findIndex(ctrl.errors, function (e) {

                                    var match = false;

                                    if (e.detailedParams) {

                                        var fi = _.find(e.detailedParams, function (p) { return (p.type || p.Type) === 'FieldIdentifier'; });

                                        if (fi && ctrl.errors[i].item && ctrl.errors[i].item.fieldIdentifier && ctrl.errors[i].item.keyIdentifier) {
                                            match = (fi.value || fi.Value).toLowerCase() === ctrl.errors[i].item.fieldIdentifier.toLowerCase() &&
                                                ctrl.errors[i].item.keyIdentifier &&
                                                e.resourceKey.toLowerCase() === ctrl.errors[i].item.keyIdentifier.toLowerCase();
                                        }
                                    }

                                    return match;
                                });

                                if (matchedIndex > -1) {

                                    if (!ctrl.errors[matchedIndex].identifier) {
                                        ctrl.errors[matchedIndex].rule = angular.copy(ctrl.errors[i].rule);
                                        ctrl.errors[matchedIndex].identifier = angular.copy(ctrl.errors[i].identifier);
                                        ctrl.errors[matchedIndex].item = angular.copy(ctrl.errors[i].item);
                                    }

                                    removeError(i);

                                } else {
                                    ctrl.validationObject.errorData = ctrl.errors[i].item;
                                    ctrl.errors[i].ErrorMessage = ctrl.errors[i].rule.summary(ctrl.validationObject);
                                }
                            }
                            else if (ctrl.errors[i].detailedParams && ctrl.errors[i].detailedParams.length > 0 && ctrl.errors[i].detailedParamsEvaluated !== true) {
                                _.each(ctrl.errors[i].detailedParams, function (p) {
                                    if (p.type === 'SerialNumbers') {
                                        ctrl.errors[i].RawErrorMessage = String.format(ctrl.errors[i].ErrorMessage + ' ({0})', p.value.join(", "));
                                    }

                                    ctrl.errors[i].detailedParamsEvaluated = true;
                                });
                            }
                        }
                    }

                    updateStatus();

                    return result;
                }

                function reindexErrors() {
                    if (ctrl.errors && ctrl.errors.length > 0) {
                        for (var i = 0; i < ctrl.errors.length; i++) {
                            ctrl.errors[i].index = i;
                        }
                    }
                };

                $scope.$watch("$ctrl.currentError", function (newValue: any) {
                    if (newValue && ctrl.index !== newValue.index) {
                        ctrl.index = newValue.index;
                    }
                });

                $scope.$watch("$ctrl.errors", function () {
                    reindexErrors();
                    evaluateErrors();
                }, true);
            }
        ]
    });
