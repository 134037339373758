//import angular from 'angular';


    angular.module('app.admin').factory('componentOwnerSvc',
        [
            'amtXlatSvc', 'WindowFactory', 'amtCommandQuerySvc', 'exportSvc', 'ocConfigSvc', '$q',
            function (
                amtXlatSvc, WindowFactory: IWindowFactory, amtCommandQuerySvc, exportSvc, ocConfigSvc, $q
            ) {
                var svc = this;

                svc.baseUrl = 'admin/componentowner/';
                svc.baseAPIUrl = baseApiUrl + svc.baseUrl;

                svc.searchUrl = svc.baseAPIUrl + 'search';

                svc.searchAsync = function (params) {
                    return amtCommandQuerySvc.post(svc.baseUrl + 'search', params);
                };

                svc.getOwnerOptionsAsync = function (criteria) {
                    return $q(function (resolve, reject) {
                        amtCommandQuerySvc.post(svc.baseUrl + "getSiteOptions", criteria).then(resolve, reject);
                    });
                };

                svc.getSearchDefaultsAsync = function (params) {
                    return amtCommandQuerySvc.post(svc.baseUrl + 'getSearchDefaults', params);
                };

                svc.getAsync = function (id, systemHierarchySystemHierarchyRoleId) {
                    return $q(function (resolve, reject) {
                        amtCommandQuerySvc.post(svc.baseUrl + "getDetails", { id: id, systemHierarchySystemHierarchyRoleId: systemHierarchySystemHierarchyRoleId }).then(resolve, reject);
                    });
                };

                svc.saveAsync = function (params) {
                    return amtCommandQuerySvc.post(svc.baseUrl + 'save', params);
                };

                svc.export = function (params) {
                    exportSvc.exportData(svc.baseUrl + 'export', params, amtXlatSvc.xlat('componentOwner.searchExportFilename'));
                };

                svc.reset = function () {
                    return {
                        client: ocConfigSvc.user.client.name,
                        clientId: ocConfigSvc.user.client.id,           
                        siteIds: [],
                        sites: [{ key: ocConfigSvc.user.site.id, name: ocConfigSvc.user.site.name }],
                        name: '',
                        id: undefined
                    };
                };

                svc.create = function (callback) {
                    return svc.openItem(svc.reset(), callback, amtXlatSvc.xlat('componentOwner.create'));
                };

                svc.openItem = function (item, callback, title) {
                    WindowFactory.openItem({
                        component: 'component-owner-details',
                        caption: title,
                        initParams: item,
                        canClose: true,
                        width: 800,
                        modal: false,
                        onDataChangeHandler: callback,
                        windowRelatedRecordId: !!item.id ? item.id : uuid()
                    });
                };

                svc.getActiveStates = function () {
                    return [
                        {
                            key: true,
                            description: amtXlatSvc.xlat('componentOwner.activeTrue')
                        },
                        {
                            key: false,
                            description: amtXlatSvc.xlat('componentOwner.activeFalse')
                        }
                    ];
                };

                svc.activeStates = svc.getActiveStates();

                return svc;
            }
        ]
    );
