import { Injectable } from "@angular/core";
import { TranslateParser } from "@ngx-translate/core";

@Injectable()
export class XlatParser extends TranslateParser {
    public interpolate(expr: string, params?: any): string {
        return Array.isArray(params) ? String.format(expr, ...params) : String.format(expr, params);
    }

    getValue(target: Record<string, string>, key: string): any {
        return target[key] ?? key;
    }
}