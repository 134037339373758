//import angular from 'angular';


    // this forces a submit event to occur with the enter key. However doesn't work correctly if the user already has an ng-submit.'
    // see https://github.com/angular/angular.js/issues/2513
    angular.module('app.directives').directive('ocForm', [
                function () {
                    return {
                        restrict: 'E',
                        transclude: true,
                        replace: true,
                        template: '<ng-form tabindex="0" focus-me ng-transclude></ng-form>',
                        link: function (scope: any, elem) {

                            elem.bind('keyup', function (e: JQuery.KeyUpEvent) {
                                const enterKeyCode = 13;
                                const escapeKeyCode = 27;

                                if ((e.which != enterKeyCode && e.which != escapeKeyCode)) {
                                    return;
                                }

                                if (e.which == enterKeyCode) {
                                    let tagName = e.target.localName.toLowerCase();
                                    if (tagName == 'textarea' || tagName == 'button')
                                        return;
                                }
                                    
                                let wnd = null;

                                //TODO: we shouldn't depend on implementations using oc-form to do this, get the wnd and buttons and buttonStates objects differently!
                                //need a better way to find an Otracom window for a given scope! (than to make assumptions about there data)
                                if (scope.$ctrl && scope.$ctrl.wnd) {
                                    wnd = scope.$ctrl.wnd;
                                } else if (scope.vm && scope.vm.wnd) {
                                    wnd = scope.vm.wnd;
                                }

                                if (wnd == null) {
                                    //form isn't in a window, so we use these non-standard events to signal
                                    scope.$broadcast(e.which == enterKeyCode ? "form:submit" : "form:cancel");
                                    e.preventDefault();
                                    return;
                                }

                                // does the window have buttons
                                if (!wnd.buttons || !Array.isArray(wnd.buttons)) {
                                    e.preventDefault();
                                    return;
                                }

                                switch (e.which) {
                                    case enterKeyCode:
                                        // get the primary buttons
                                        let primaryButtons = wnd.buttons.filter(g => g.primary);

                                        let activeButtons = primaryButtons;

                                        // get buttons with state
                                        var stateButtons = primaryButtons.filter(b => wnd.buttonStates[b.name]);

                                        if (stateButtons.length > 0) {
                                            // if there are any buttons with states get the buttons with an active state
                                            activeButtons = stateButtons.filter(b => wnd.buttonStates[b.name].visible &&
                                                                                (!wnd.buttonStates[b.name].disabled || wnd.buttonStates[b.name].enabled));
                                        }

                                        // if there are active buttons, click the first one
                                        if (activeButtons.length > 0) {
                                            wnd.buttonClick(activeButtons[0].click);
                                        }

                                        break;

                                    case escapeKeyCode:
                                        // get the cancel button
                                        let cancel = wnd.buttons.filter(g => g.cancel);

                                        let state = cancel.length > 0 ? wnd.buttonStates[cancel[0].name] : null;

                                        //don't simulate a click
                                        if (cancel.length != 1 || (state && (!state.visible || (state.disabled && !state.enabled))))
                                            break;

                                        // trigger the action associated to the button
                                        wnd.buttonClick(cancel[0].click);

                                        break;
                                }

                                e.preventDefault();
                            });
                        }
                    };
                }
            ]
        );
