/** 
 * Kendo UI v2016.3.1118 (http://www.telerik.com/kendo-ui)                                                                                                                                              
 * Copyright 2016 Telerik AD. All rights reserved.                                                                                                                                                      
 *                                                                                                                                                                                                      
 * Kendo UI commercial licenses may be obtained at                                                                                                                                                      
 * http://www.telerik.com/purchase/license-agreement/kendo-ui-complete                                                                                                                                  
 * If you do not own a commercial license, this file shall be governed by the trial license terms.                                                                                                      
*/
import kendo from '@progress/kendo-ui/js/kendo.core';

kendo.cultures["en-OC"] = {
    name: "en-OC",
    numberFormat: {
        pattern: ["-n"],
        decimals: 2,
        ",": ",",
        ".": ".",
        groupSize: [3],
        percent: {
            pattern: ["-n%", "n%"],
            decimals: 2,
            ",": ",",
            ".": ".",
            groupSize: [3],
            symbol: "%"
        },
        currency: {
            name: "Australian Dollar",
            abbr: "AUD",
            pattern: ["-$n", "$n"],
            decimals: 2,
            ",": ",",
            ".": ".",
            groupSize: [3],
            symbol: "$"
        }
    },
    calendars: {
        standard: {
            days: {
                names: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                namesAbbr: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                namesShort: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
            },
            months: {
                names: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                namesAbbr: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
            },
            AM: ["AM", "am", "AM"],
            PM: ["PM", "pm", "PM"],
            patterns: {
                d: "dd-MM-yyyy",
                D: "dddd, d MMMM yyyy",
                F: "dddd, d MMMM yyyy HH:mm:ss",
                g: "dd-MM-yyyy HH:mm",
                G: "dd-MM-yyyy HH:mm:ss",
                m: "MMMM d",
                M: "MMMM d",
                s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
                t: "HH:mm",
                T: "HH:mm:ss",
                u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'",
                y: "MMMM yyyy",
                Y: "MMMM yyyy"
            },
            "/": "/",
            ":": ":",
            firstDay: 1
        }
    }
}