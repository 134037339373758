//import angular from 'angular';
import tmpl from './details.html';
import HelperSvc from '../../../services/helperSvc';



interface IStockCodeDetails {
    site: any;
    client: any;
    equipmentType?: any;
    stockCode?: string;
    specificationIds?: guid[];
    lifeTypeIds?: guid[];
    specifications?: any[];
}

class StockCodeDetailsCtrl implements ng.IController, IWindowController {

    form: ng.IFormController;
    wnd: IWindowObj;

    buttons: IButton[];
    buttonStates: { [key: string]: IButtonState };
    buttonMethods: { [key: string]: () => void };

    specificationsControl: any = {};

    initParams: any;

    status: ScreenStatus = ScreenStatus.loading;
    error: any;

    isEdit: boolean;
    readonly: boolean;

    stockCodeId: guid;
    stockCodeDetails: IStockCodeDetails;

    static $inject = ['$scope', 'amtCommandQuerySvc', 'errorReporter', 'WindowFactory', '$timeout', 'ocSecuritySvc', 'confirmSvc', 'amtXlatSvc', 'helperSvc',
        'stockCodesUrls', 'notifySvc'];

    constructor(private $scope: ng.IScope, private amtCommandQuerySvc: IAmtCommandQuerySvc, private errorReporter: IErrorReporter,
        private WindowFactory: IWindowFactory, private $timeout: ng.ITimeoutService, private ocSecuritySvc: IOcSecuritySvc,
        private confirmSvc: IConfirmSvc, private amtXlatSvc: IAmtXlatSvc, private helperSvc: HelperSvc, private stockCodesUrls: IStockCodesUrls,
        private notifySvc: INotifySvc) {

        this.$scope.$watch(() => this.form.$dirty, (newValue) => this.wnd.isDirty = newValue);

        this.$scope.$watchGroup([() => this.form.$invalid, () => this.form.$pristine, () => this.wnd.processing, () => this.readonly], () => {
            this.buttonStates.saveButton.visible = !this.readonly;
            this.buttonStates.saveButton.disabled = this.form.$invalid || this.form.$pristine || this.wnd.processing;
        });
    }

    async $onInit() {

        this.wnd.onClose = () => this.cancel();

        this.stockCodeId = this.initParams.stockCodeId;
        this.isEdit = this.initParams.isEdit;

        this.readonly = !this.ocSecuritySvc.isAuthorised(this.isEdit ? 'Security.Settings.StockCodes' : 'Security.Settings.StockCodes.Add', AccessTypes.readWrite);

        if (!this.isEdit) {
            this.stockCodeDetails = {
                site: this.initParams.site,
                client: this.initParams.client
            };
        }

        this.WindowFactory.addButton(this, 'common.save_label', 'saveButton', () => this.save(), true);

        if (this.stockCodeId) {
            await this.loadStockCodeDetails();
        } else {
            this.status = ScreenStatus.ready;
        }
    }

    async save() {

        this.wnd.processing = true;

        try {
            let criteria = {
                stockCodeId: this.stockCodeId,
                isEdit: this.isEdit,
                clientId: this.helperSvc.getKey(this.stockCodeDetails.client),
                siteId: this.helperSvc.getKey(this.stockCodeDetails.site),
                stockCode: this.stockCodeDetails.stockCode,
                equipmentTypeId: this.helperSvc.getKey(this.stockCodeDetails.equipmentType),
                equipmentLifeTypeIds: this.stockCodeDetails.lifeTypeIds,
                specifications: this.stockCodeDetails.specificationIds
            };

            let url = this.isEdit ? this.stockCodesUrls.modifyStockCode : this.stockCodesUrls.addStockCode;

            await this.amtCommandQuerySvc.post(url, criteria);

            this.notifySvc.success(this.amtXlatSvc.xlat('stockCodes.saveSuccessful'));

            this.form.$setPristine();

            if (this.wnd.onDataChanged)
                this.wnd.onDataChanged();

            this.closeWindow();

        } catch (error) {
            this.errorReporter.logError(error);
        } finally {
            this.wnd.processing = false;
        }
    }

    async loadStockCodeDetails() {

        this.wnd.processing = true;

        try {

            let criteria = {
                id: this.stockCodeId
            };

            let response = await this.amtCommandQuerySvc.post(this.stockCodesUrls.getDetails, criteria);

            if (!response)
                return;

            this.stockCodeDetails = response;

            this.status = ScreenStatus.ready;

            this.$timeout(() => this.form.$setPristine());

        } catch (error) {
            this.status = ScreenStatus.error;
            this.error = error;
            this.errorReporter.logError(error);
        } finally {
            this.wnd.processing = false;
        }
    }

    onClientChange() {
        this.stockCodeDetails.site = null;
        this.stockCodeDetails.equipmentType = null;
        this.clearSpecifications();
    }

    onSiteChange() {
        this.stockCodeDetails.equipmentType = null;
        this.clearSpecifications();
    }

    onComponentTypeChange() {
        this.clearSpecifications();        
    }

    clearSpecifications() {

        this.stockCodeDetails.specificationIds = null;

        if (this.specificationsControl && this.specificationsControl.clear)
            this.specificationsControl.clear();
    }

    closeWindow() {
        this.WindowFactory.closeWindow(this.wnd);
    }

    async cancel() {
        try {
            await this.confirmSvc.confirmSaveChange(this.form.$dirty);
        } catch {
            return false; // they cancelled
        }

        this.form.$setPristine();
        this.WindowFactory.closeWindow(this.wnd);

        return true;
    }
}

class StockCodeDetailsComponent implements ng.IComponentOptions {
    public bindings = {
        initParams: '=',
        buttonMethods: '=',
        buttonStates: '=',
        buttons: '=',
        closeOnSave: '=',
        wnd: '='
    };
    public template = tmpl;
    public controller = StockCodeDetailsCtrl;
    public controllerAs = 'vm';
}

angular.module('app.admin').component('stockCodeDetails', new StockCodeDetailsComponent());