/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var constants_1 = require("./constants");
/**
 * @hidden
 */
var TileLayoutResizeHandleDirective = /** @class */ (function () {
    function TileLayoutResizeHandleDirective(el, renderer) {
        this.el = el;
        this.renderer = renderer;
    }
    TileLayoutResizeHandleDirective.prototype.ngOnInit = function () {
        this.sizeHandle();
    };
    TileLayoutResizeHandleDirective.prototype.setHorizontalPosition = function (element) {
        this.renderer.setStyle(element, this.rtl ? 'left' : 'right', -constants_1.RESIZE_HANDLE_DIMENSIONS[this.resizeDirection].overlapX + 'px');
    };
    TileLayoutResizeHandleDirective.prototype.setBottom = function (element) {
        this.renderer.setStyle(element, 'bottom', -constants_1.RESIZE_HANDLE_DIMENSIONS[this.resizeDirection].overlapY + 'px');
    };
    TileLayoutResizeHandleDirective.prototype.sizeHandle = function () {
        var element = this.el.nativeElement;
        var handleWidth = constants_1.RESIZE_HANDLE_DIMENSIONS[this.resizeDirection].width ?
            constants_1.RESIZE_HANDLE_DIMENSIONS[this.resizeDirection].width + "px" : '100%';
        var handleHeight = constants_1.RESIZE_HANDLE_DIMENSIONS[this.resizeDirection].height ?
            constants_1.RESIZE_HANDLE_DIMENSIONS[this.resizeDirection].height + "px" : '100%';
        this.renderer.setStyle(element, 'width', handleWidth);
        this.renderer.setStyle(element, 'height', handleHeight);
        if (this.resizeDirection === 'ew') {
            this.setHorizontalPosition(element);
        }
        else if (this.resizeDirection === 'ns') {
            this.setBottom(element);
        }
        else {
            this.setHorizontalPosition(element);
            this.setBottom(element);
        }
    };
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", String)
    ], TileLayoutResizeHandleDirective.prototype, "resizeDirection", void 0);
    tslib_1.__decorate([
        core_1.Input(),
        tslib_1.__metadata("design:type", Boolean)
    ], TileLayoutResizeHandleDirective.prototype, "rtl", void 0);
    TileLayoutResizeHandleDirective = tslib_1.__decorate([
        core_1.Directive({
            selector: '[kendoTileLayoutResizeHandle]'
        }),
        tslib_1.__metadata("design:paramtypes", [core_1.ElementRef,
            core_1.Renderer2])
    ], TileLayoutResizeHandleDirective);
    return TileLayoutResizeHandleDirective;
}());
exports.TileLayoutResizeHandleDirective = TileLayoutResizeHandleDirective;
