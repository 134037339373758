//import angular from 'angular';
import * as _ from 'underscore';
import OcDateSvc from '../../../services/ocDateSvc';
import { UpdateForecastOverrideModel } from '../forecastDetails/updateForecastOverrideCtrl';



    angular.module('app.site').service("forecastingSvc", [
        "$q", "enums", "notifySvc", "amtXlatSvc", "errorReporter", "ocDateSvc", "WindowFactory", "amtConstants", "amtCommandQuerySvc", "$state",
        function ($q, enums, notifySvc, amtXlatSvc, errorReporter, ocDateSvc: OcDateSvc, WindowFactory, amtConstants, amtCommandQuerySvc, $state) {

            var svc = this;

            var apiUrls = amtConstants.url;
            var activeTabName = "Summary";

            // active tabs
            svc.setActiveTabName = function (tabName) {
                activeTabName = tabName;
            };

            svc.getActiveTabName = function () {
                return activeTabName;
            };

            svc.isHttpError = function (response) {

                if (!isNaN(+response) || enums.httpUpdateStatus.success === response.status) {
                    return false;
                }

                if (!response.errors) {
                    notifySvc.error(response.message || amtXlatSvc.xlat("common.anServerError"));
                    return true;
                }

                angular.forEach(response.errors, function (item) {
                    if (item && item.message) {
                        notifySvc.error(item.message);
                    }
                });

                return true;
            };

            // add new forecast / copy forecast / update forecast
            svc.addUpdateCopyForecast = function (mode, model) {

                return $q(function (resolve, reject) {

                    let savePromise = $q.resolve;          
                    let successMessage;

                    switch (mode) {
                        case enums.forecastCreateModes.add:
                            savePromise = amtCommandQuerySvc.post(apiUrls.createForecast, model);
                            successMessage = amtXlatSvc.xlat("forecastList.createSuccessful_message");
                            break;

                        case enums.forecastCreateModes.update:
                            savePromise = amtCommandQuerySvc.post(apiUrls.updateForecast, model);
                            successMessage = amtXlatSvc.xlat("forecastDetails.updateSuccessful_message");
                            break;

                        case enums.forecastCreateModes.copy:
                            savePromise = amtCommandQuerySvc.post(apiUrls.copyForecast, model);
                            successMessage = amtXlatSvc.xlat("forecastList.copySuccessful_message");
                            break;
                    }

                    return savePromise.then(function (response) {
                        if (successMessage) {
                            notifySvc.success(successMessage);
                        }
                        return resolve(response);
                    }).catch(function (error) {
                        errorReporter.logError(error);
                        return reject(error);
                    });
                });
            };

            // soft delete a forecast
            svc.deleteForecast = function (item) {

                return $q(function (resolve, reject) {

                    let cmd = {
                        forecastId: item.id
                    };

                    return amtCommandQuerySvc.deleteItem(apiUrls.deleteForecast, cmd).then(function (response) {
                        notifySvc.success(amtXlatSvc.xlat("forecastList.deleteSuccessful_message"));
                        return resolve();
                    });
                });
            };

            // refresh reference data
            svc.refreshReferenceData = function () {

                return $q(function (resolve, reject) {

                    WindowFactory.openItem({
                        component: 'refresh-reference-data',
                        canMinimise: false,
                        modal: true,
                        canClose: true,
                        footerOff: true,
                        caption: amtXlatSvc.xlat('forecastList.refreshReferenceData'),
                        onDataChangeHandler: function () {
                            svc.referenceDataRefreshed = new Date();
                            return resolve();
                        }
                    });
                });
            };

            // open a forecat
            svc.showForecast = function (item) {
                svc.setActiveTabName("Summary");
                $state.go("app.site.forecastdetails", { forecastid: item.id });
            };

            svc.getForecastLengths = function () {

                let forecastLengths = [];

                for (let i = 4; i <= 12; i++) {
                    forecastLengths.push({
                        key: i,
                        name: amtXlatSvc.xlat('forecastDetails.forecastLengthWeeks', i)
                    });
                }

                return forecastLengths;
            };

            // update a forecast override
            svc.updateForecastOverride = function (model: UpdateForecastOverrideModel, onDataChangeHandler) {
                WindowFactory.openItem({
                    component: 'update-forecast-override',
                    canMinimise: false,
                    modal: true,
                    canClose: false,
                    footerOff: false,
                    caption: amtXlatSvc.xlat('forecastDetails.updateForecastOverride'),
                    initParams: model,
                    onDataChangeHandler: onDataChangeHandler
                });
            };         

            // get forecast types
            svc.getForecastTypes = function () {

                return amtCommandQuerySvc.get(apiUrls.getForecastTypes).then(function (response) {

                    let forecastTypes = response.data.result;

                    _.each(forecastTypes, function (t) {
                        t.description = amtXlatSvc.xlat('forecastDetails.forecastTypeDescription' + t.name);
                        t.resolvedName = amtXlatSvc.xlat('forecastDetails.forecastType' + t.name);
                    });

                    return forecastTypes;
                }).catch(function (error) {
                    errorReporter.logError(error);
                    error.preventLogException = true;
                    return $q.reject(error);
                });
            };

            // get forecast methods
            svc.getForecastMethods = function () {

                return amtCommandQuerySvc.get(apiUrls.getForecastMethods).then(function (response) {

                    let forecastMethods = response.data.result;

                    _.each(forecastMethods, function (t) {
                        t.resolvedName = amtXlatSvc.xlat('forecastDetails.forecastMethod' + t.name);
                    });

                    return forecastMethods;
                }).catch(function (error) {
                    errorReporter.logError(error);
                    error.preventLogException = true;
                    return $q.reject(error);
                });
            };

            // get budget restricted task types
            svc.getBudgetRestrictedTaskTypes = function () {

                return amtCommandQuerySvc.get(apiUrls.getTaskTypes, { budgetRestrictedOnly: true }).then(function (response) {

                    let budgetRestrictedTaskTypes = response.data;

                    _.each(budgetRestrictedTaskTypes, function (t) {
                        t.key = t.id;
                        t.resolvedName = amtXlatSvc.xlat('forecastDetails.budgetRestrictedCostsTaskType' + t.taskCode);
                    });

                    return budgetRestrictedTaskTypes;

                }).catch(function (error) {
                    errorReporter.logError(error);
                    error.preventLogException = true;
                    return $q.reject(error);
                });
            };

            // get budget forecast status types
            svc.getBudgetForecastStatusTypes = function () {

                return amtCommandQuerySvc.get(apiUrls.getBudgetForecastStatusTypes).then(function (response) {

                    let budgetForecastStatusTypes = response.data.result;

                    _.each(budgetForecastStatusTypes, function (t) {
                        t.resolvedName = amtXlatSvc.xlat('forecastDetails.budgetForecastStatusType' + t.name);
                    });

                    return budgetForecastStatusTypes;

                }).catch(function (error) {
                    errorReporter.logError(error);
                    error.preventLogException = true;
                    return $q.reject(error);
                });
            };

            // get fleets
            svc.getFleets = function () {

                return amtCommandQuerySvc.get(apiUrls.getForecastingFleets).then(function (response) {

                    let fleets = response.data;

                    return fleets;

                }).catch(function (error) {
                    errorReporter.logError(error);
                    error.preventLogException = true;
                    return $q.reject(error);
                });
            };

            // set the controls for the add/update/copy forecast window
            svc.setForecastControls = function (mode: ForecastCreateMode, forecastType, date: Date) {

                let controls = {
                    forecastType: {
                        visible: true,
                        disabled: mode == ForecastCreateMode.update
                    },
                    forecastTypeDescription: {
                        visible: mode != ForecastCreateMode.update
                    },
                    startDate: {
                        visible: true,
                        disabled: false,
                        minDate: undefined,
                        maxDate: undefined
                    },
                    endDate: {
                        visible: true,
                        disabled: false,
                        minDate: undefined,
                        maxDate: undefined
                    },
                    fleets: {
                        visible: true,
                        disabled: false
                    },
                    forecastLength: {
                        visible: true,
                        disabled: false
                    },
                    budgetForecastStatusType: {
                        visible: false,
                        disabled: false
                    },
                    budgetRestricted: {
                        visible: false,
                        disabled: false
                    },
                    includeRimIntervalsByDays: {
                        visible: true,
                        disabled: false
                    },
                    active: {
                        visible: mode == ForecastCreateMode.update,
                        disabled: false
                    },
                    forecastMethod: {
                        visible: false,
                        disabled: false
                    }
                };

                if (forecastType && date) {

                    let currentDate = new Date();
                    let year = date.getFullYear();

                    switch (forecastType.name) {

                        case ForecastType.live:

                            // control visibility
                            controls.startDate.disabled = true;

                            controls.forecastMethod.visible = true;

                            controls.forecastLength.visible = true;
                            controls.forecastLength.disabled = false;

                            controls.endDate.visible = false;

                            controls.fleets.visible = false;                            

                            controls.budgetRestricted.visible = false;
                            controls.budgetForecastStatusType.visible = false;

                            break;

                        case ForecastType.monthToMonth:

                            // control visibility
                            controls.startDate.disabled = false;

                            controls.forecastMethod.visible = false;

                            controls.forecastLength.visible = false;

                            controls.endDate.visible = true;
                            controls.endDate.disabled = false;

                            controls.fleets.visible = true;
                            controls.fleets.disabled = false;

                            controls.budgetForecastStatusType.visible = false;

                            controls.startDate.maxDate = ocDateSvc.getEndOfMonth(currentDate);

                            controls.endDate.minDate = ocDateSvc.addMonths(date, 3);
                            controls.endDate.maxDate = new Date(year + 1, 11, 31); // end of next calendar year (months 0-based in javascript)       

                            controls.budgetRestricted.visible = true;
                            controls.budgetRestricted.disabled = false;

                            break;

                        case ForecastType.budget:

                            // control visibility
                            controls.startDate.disabled = false;
                            controls.forecastMethod.visible = false;
                            controls.forecastLength.visible = false;
                            controls.endDate.visible = true;
                            controls.fleets.disabled = false;
                            controls.budgetForecastStatusType.visible = mode == ForecastCreateMode.update;

                            controls.startDate.minDate = ocDateSvc.halfStart(currentDate);
                            controls.startDate.maxDate = currentDate;

                            controls.endDate.minDate = ocDateSvc.addMonths(date, 12); // 1 year
                            controls.endDate.maxDate = ocDateSvc.addMonths(date, 60); // 5 years

                            controls.budgetRestricted.visible = true;
                            controls.budgetRestricted.disabled = false;

                            break;
                    }                  
                }

                return controls;
            };

            return svc;
        }]);
