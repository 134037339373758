//import angular from 'angular';
import AdminUrls from '../../../adminUrls';

angular.module("app.admin").controller("rolesPermissionsCtrl", ["$scope", "adminUrls", "amtXlatSvc", "WindowFactory", "ocConfigSvc",
        function ($scope, adminUrls: AdminUrls, xlatSvc, WindowFactory, ocConfigSvc) {

            var vm = this;

            vm.config = ocConfigSvc;

            vm.gridControl = {};
            vm.permissionGridControl = {};
            vm.reportPermissionGridControl = {};

            vm.apiUrls = adminUrls;

            vm.filterValues = {
                searchText: "",
                includeInactiveRoles: false,
                pageSize: 20
            };

            vm.permissionFilterValues = {
                searchText: "",
                pageSize: 20,
                reportPermissions: false
            };
            vm.reportPermissionFilterValues = {
                searchText: "",
                pageSize: 20,
                reportPermissions: true
            };

            vm.permissionLevels = {};
            vm.selectedRole = null;

            vm.columnWrapAttribute = {
                "style": "white-space: normal;"
            };

            vm.init = function () {
                //amtCommandQuerySvc.get(vm.apiUrls.getPermissionLevels).then(function (response) {
                //    if (!response) { return; }
                //    vm.permissionLevels = response.data;
                //    vm.permissionGridAccessTypeColumn = ["accessType"];
                //    vm.permissionGridControl.recompile();
                //});
            };

            vm.filterTab = function ($event, f) {
                if ($event.which === 13) {
                    f();
                    $event.stopPropagation();
                }
            };

            vm.roleSelectionChanged = function (items) {
                if (items && items.length > 0) {
                    vm.selectedRole = items[0];
                    vm.permissionFilterValues.roleTypeId = items[0].id;
                    vm.permissionGridControl.refresh();
                    vm.reportPermissionFilterValues.roleTypeId = items[0].id;
                    vm.reportPermissionGridControl.refresh();
                }
            };

            vm.addNewRole = function () {
                WindowFactory.openItem({
                    component: 'modify-role',
                    caption: xlatSvc.xlat('rolesPermissions.new'),
                    initParams: {
                        mode: 'new', // This is purely used for the title
                        role: {
                            id: '',
                            name: '',
                            description: '',
                            canBeDisabled: true,
                            isActive: true,
                            roleAssignments: []
                        },
                        showCloseOnSave: false
                    },
                    modal: true,
                    onDataChangeHandler: vm.refreshAll,
                    width: 500
                });
            };

            vm.addNewPermission = function () {
                WindowFactory.openItem({
                    component: 'new-permission',
                    caption: xlatSvc.xlat('rolesPermissions.newPermission'),
                    initParams: {
                        mode: 'new', // This is purely used for the title
                        showCloseOnSave: false
                    },
                    canClose: false,
                    modal: true,
                    onDataChangeHandler: vm.refreshAll,
                    width: 800
                });
            };

            $scope.editPermission = function (item) {
                WindowFactory.openItem({
                    component: 'new-permission',
                    caption: xlatSvc.xlat('rolesPermissions.editPermission', item.name),
                    initParams: {
                        mode: 'edit', // This is purely used for the title
                        id: item.id,
                        name: item.name,                        
                        description: item.description,
                        showCloseOnSave: false
                    },
                    canClose: false,
                    modal: true,
                    onDataChangeHandler: vm.refreshAll,
                    width: 800
                });
            };

            $scope.modifyRole = function (item) {
                WindowFactory.openItem({
                    caption: xlatSvc.xlat('rolesPermissions.edit', item.description),
                    component: 'modify-role',
                    initParams: {
                        mode: 'edit', // This is purely used for the title
                        role: {
                            id: item.id,
                            name: item.name,
                            description: item.description,
                            canBeDisabled: item.canBeDisabled,
                            isActive: item.isActive,
                            roleAssignments: item.roleAssignments
                        },
                        showCloseOnSave: false
                    },
                    modal: true,
                    onDataChangeHandler: vm.refreshAll,
                    width: 500
                });
            };

            $scope.rolesRowParseFunction = function (response) {
                var roles = response.result;

                for (var r = 0; r < roles.length; r++) {
                    //TODO: this seems dumb... find a better way (maybe see amtGridColumn yesno... but generalised?)
                    roles[r].activeDisplay = xlatSvc.xlat(roles[r].isActive ? 'rolesPermissions.active' : 'rolesPermissions.inactive');
                }

                return response;
            };

            vm.refreshAll = function () {
                vm.gridControl.refresh();
                vm.permissionGridControl.refresh();
                vm.reportPermissionGridControl.refresh();
            };

            vm.refreshPermissions = function () {
                vm.permissionGridControl.refresh();
                vm.reportPermissionGridControl.refresh();
            };

            $scope.updateAccessType = function (item, params) {

                WindowFactory.openItem({
                    caption: vm.selectedRole.description + ': ' + item.name,
                    component: 'update-access-type',
                    initParams: {
                        roleTypeId: vm.selectedRole.id,
                        permissionId: item.id,
                        roleName: vm.selectedRole.description,
                        permissionName: item.name,
                        accessType: item.accessType,
                        selectedAccessType: {
                            key: item.accessType,
                            name: xlatSvc.xlat(params.accessTypeName)
                        },
                        showCloseOnSave: false
                    },
                    modal: true,
                    width: 800,
                    onDataChangeHandler: vm.refreshPermissions
                });
            };

            vm.init();
        }]);
