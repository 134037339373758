/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
/**
 * @hidden
 */
var MenuTabbingService = /** @class */ (function () {
    function MenuTabbingService() {
        this.isColumnMenu = false;
        this.isPopupOpen = false;
    }
    MenuTabbingService = tslib_1.__decorate([
        core_1.Injectable()
    ], MenuTabbingService);
    return MenuTabbingService;
}());
exports.MenuTabbingService = MenuTabbingService;
