//import angular from 'angular';
import tmpl from './earlierThen.html';


angular.module('amtFramework.validation').directive('earlierThen',
    ['$filter', '$parse', '$compile', 'amtXlatSvc', 
        function ($filter, $parse, $compile, amtXlatSvc) {
            return {
                require: 'ngModel',
                link: function (scope: any, elem, attrs, ctrl) {

                    var valSpan = angular.element(tmpl);
                    valSpan.insertAfter(elem);
                    $compile(valSpan)(scope);
                    ctrl.$parsers.unshift(function (value) {
                        return validate(value);
                    });

                    scope.$watch(attrs.earlierThen, function () {
                        validate(ctrl.$modelValue);
                    });

                    var validate = function (value) {
                        var getter = $parse(attrs.earlierThen);
                        var comparisonValue = getter(scope);
                        var compareMessageValue = '';
                        var equalGetter = $parse(attrs.equal);
                        var isEqual = equalGetter(scope);

                        if (value === undefined || value === null || comparisonValue === undefined || comparisonValue === null) {
                            // It's valid because we have nothing to compare against
                            ctrl.$setValidity('earlierThen', true);
                            scope.earlierThenValid = true;
                            return value;
                        }

                        // It's valid if model is earlier than the model we're comparing against
                        var valid = true;
                        if (value.getYear) {
                            if (comparisonValue.getYear) {
                                valid = value < comparisonValue;
                                if (isEqual) {
                                    valid = value <= comparisonValue;
                                }
                                compareMessageValue = comparisonValue.toString();
                            }
                            if (comparisonValue.year && comparisonValue.month) {
                                var comparisonDateValue = new Date(comparisonValue.year, comparisonValue.month - 1, 1);
                                valid = value < comparisonDateValue;
                                if (isEqual) {
                                    valid = value <= comparisonDateValue;
                                }
                                compareMessageValue = $filter('monthYear')(comparisonDateValue);
                            }
                        }
                        if (!isNaN(value) && !isNaN(comparisonValue)) {
                            valid = value < comparisonValue;
                            if (isEqual) {
                                valid = value <= comparisonValue;
                            }
                            compareMessageValue = comparisonValue;
                        }
                        ctrl.$setValidity('earlierThen', valid);
                        
                        setUiValidation(valid);
                        scope.earlierThenValid = valid;
                        scope.validationEarlierThenMessage = amtXlatSvc.xlat("common.validationLaterThen_message", compareMessageValue);

                        return value;
                    };
                    function setUiValidation(valid) {
                        var datePickerElement = angular.element(elem).parent();
                        if (!valid) {
                            angular.element(datePickerElement).addClass('ng-invalid');
                        }
                        else {
                            angular.element(datePickerElement).removeClass('ng-invalid');
                        }
                    }
                }
            };
        }
    ]
);
