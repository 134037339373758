//import angular from 'angular';


    angular.module('app.directives').directive('ocChartText', function () {
        return {
            replace: true,
            scope: {
                config: "="
            },
            template:
                '<text ng-class="{{config.cssClass}}" ng-attr-x="{{config.x + config.width / 2}}" ng-attr-y="{{config.y + config.height - 4}}">{{config.text}}</text>'
        };
    });
