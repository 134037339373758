//import angular from 'angular';
import AdminUrls from '../../admin/adminUrls';
import tmpl from '../vehicle/tabs/transferVehicleDetails.html';



    angular.module("app.component").component("transferVehiclePopup",
        {
            template: tmpl,
            bindings: {
                initParams: '=',
                buttonMethods: '=',
                buttonStates: '=',
                buttons: '=',
                closeOnSave: '=',
                wnd: '='
            },
            controller: transferVehiclePopupCtrl
        });

    // @ts-ignore
    transferVehiclePopupCtrl.$inject = ['$scope', '$rootScope', 'amtCommandQuerySvc', 'vehicleUrls', 'referenceDataUrls', 'adminUrls', 'enums', 'amtXlatSvc', 'confirmSvc',
        'WindowFactory', 'notifySvc', 'ocSecuritySvc', 'ocDateSvc', 'errorReporter', '$timeout', 'componentUrls', 'amtConstants', 'helperSvc'];

    function transferVehiclePopupCtrl($scope, $rootScope, amtCommandQuerySvc, vehicleUrls, referenceDataUrls, adminUrls: AdminUrls, enums, amtXlatSvc, confirmSvc, WindowFactory, notifySvc, ocSecuritySvc, ocDateSvc, errorReporter, $timeout, componentUrls, amtConstants, helperSvc) {

        var vm = this;
        $scope.vm = this;

        vm.apiUrls = vehicleUrls;
        vm.adminUrls = adminUrls;
        vm.referenceDataUrls = referenceDataUrls;
        vm.componentUrls = componentUrls;

        vm.readOnly = !ocSecuritySvc.isAuthorised('Security.Vehicles.Transfer', 'readWrite');

        vm.status = enums.screenStatus.loading;

        var currentDate = new Date();

        // default to current date
        vm.transfer = {
            eventDate: currentDate
        };

        this.$onInit = function () {

            vm.vehicleId = vm.initParams.vehicle;
            vm.siteId = vm.initParams.siteId;
            vm.clientId = vm.initParams.clientId;
            vm.equipmentEventId = vm.initParams.equipmentEventId;

            if (vm.equipmentEventId) {
                vm.loadDetails();
            } else {
                vm.status = enums.screenStatus.ready;
            }

            vm.wnd.onClose = vm.onClose;

            vm.buttons = [
                {
                    primary: true,
                    cancel: false,
                    value: 'vehicle.transferVehicle_label',
                    name: 'transferButton',
                    click: 'transfer',
                    type: enums.buttonTypes.button
                },
                {
                    primary: false,
                    cancel: true,
                    value: 'common.cancel_label',
                    name: 'cancelButton',
                    click: 'cancel',
                    type: enums.buttonTypes.button
                }
            ];

            vm.buttonStates = {
                transferButton: {
                    visible: true,
                    disabled: true
                },
                cancelButton: {
                    visible: true,
                    disabled: false
                }
            };

            vm.buttonMethods = {
                transfer: vm.onSave,
                cancel: vm.onClose
            };
        };

        var pristineWatch = $scope.$watch('vm.form.$setPristine', function (newValue, oldValue) {
            if (oldValue === undefined && newValue) {
                // call pristine once the function is available
                vm.form.$setPristine();

                // remove the watch
                pristineWatch();
            }
        });

        $scope.setForm = function (scope) {
            // add a watch to the dirty flag
            $scope.$watch('vm.form.$dirty', function () {
                vm.wnd.isDirty = vm.form.$dirty;
            });
        };

        vm.closeWindow = function () {
            WindowFactory.closeWindow(vm.wnd);
        };

        vm.onClose = function () {
            confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                vm.form.dirty = false;
                vm.closeWindow();

                return true;
            });

            return false;
        };

        vm.onDestinationChange = function (option) {
            if (!option || option.requiresManualEntry === false) {
                vm.transfer.destinationOther = null;
            }
        };

        vm.tabs = {
            details: {
                canSave: true,
                saveResource: 'common.save_label',
                save: vm.updateDetails,
                canExport: false
            },
            fitments: {}
        };

        vm.selectTab = function (tabName) {
            vm.currentTab = tabName;
            $scope.onRefresh();
        };

        vm.currentTab = vm.tabs.details;

        $scope.onRefresh = function () {
            if (vm.currentTab.refresh !== undefined) {
                vm.currentTab.refresh();
            }
        };

        vm.loadDetails = function () {

            vm.wnd.processing = true;

            var criteria = {
                equipmentEventId: vm.equipmentEventId
            };

            return amtCommandQuerySvc.post(vm.componentUrls.getComponentEventDetails, criteria).then(function (response) {

                if (response) {

                    vm.transfer = {
                        comments: response.comments,
                        eventDate: response.eventDate,
                        destinationId: (response.moveDetails.destinationId ? response.moveDetails.destinationId : (response.moveDetails.destinationOther ? amtConstants.emptyGuid : null)),
                        destinationOther: response.moveDetails.destinationOther
                    };

                    vm.status = enums.screenStatus.ready;

                    $timeout(function () { if (vm.form) { vm.form.$setPristine(); } }, 100);
                }
            }, function (error) {
                vm.status = enums.screenStatus.error;

                vm.details = {
                    errors: [error]
                };

                errorReporter.logError(error);
            }).finally(function () {
                vm.wnd.processing = false;
            })
        };

        vm.onSave = function () {

            if (new Date(vm.transfer.eventDate).getTime() > Date.now()) {
                console.log(amtXlatSvc.xlat("equipment.eventDateCannotOccurInFuture"));
                notifySvc.error(amtXlatSvc.xlat("equipment.eventDateCannotOccurInFuture"));
                return;
            }

            vm.wnd.processing = true;

            vm.saveCriteria = {
                equipmentEventId: vm.equipmentEventId,
                eventDate: ocDateSvc.removeLocalTimeZoneOffset(vm.transfer.eventDate),
                comment: vm.transfer.comments,
                isEdit: !!vm.equipmentEventId,
                equipmentId: vm.vehicleId,
                movementDetails: {
                    transferDetails: {
                        destinationSiteId: helperSvc.getKey(vm.transfer.destination),
                        destinationOther: vm.transfer.destinationOther
                    },
                    dispatchDetails: {}
                }
            };

            transferVehicle(vm.saveCriteria);
        };

        function transferVehicle(model) {
            amtCommandQuerySvc.post(vm.apiUrls.transferVehicle, model).then(function (response) {

                notifySvc.success(amtXlatSvc.xlat("vehicle.transferredSuccessfully"));

                if (vm.wnd.onDataChanged) {
                    vm.wnd.onDataChanged();
                }

                $rootScope.$broadcast('filterSearch');

                vm.form.$setPristine();
                vm.closeWindow();

            }).catch(function (error) {
                errorReporter.logError(error);
            }).finally(function () {
                vm.wnd.processing = false;
            });
        }

        $scope.$watch('vm.readOnly', function () {
            vm.buttonStates.transferButton.visible = !vm.readOnly;
        });

        $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing'], function () {
            vm.buttonStates.transferButton.disabled = vm.form.$invalid || vm.form.$pristine || vm.wnd.processing;
        });
    }
