//import angular from 'angular';
import * as _ from 'underscore';
import OcDateSvc from '../../../services/ocDateSvc';
import AdminUrls from '../../admin/adminUrls';
import tmpl from './addUpdateForecast.html';


    angular.module("app.site").component("addUpdateForecast", {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['$scope', 'amtCommandQuerySvc', '$interval', '$timeout', 'amtConstants', 'amtXlatSvc', 'WindowFactory', 'ocDateSvc',
            'forecastingSvc', 'confirmSvc', 'ocConfigSvc', 'helperSvc', 'notifySvc', 'errorReporter', '$q', 'ocSecuritySvc', 'adminUrls',

            function ($scope, amtCommandQuerySvc, $interval, $timeout, amtConstants, amtXlatSvc, WindowFactory: IWindowFactory, ocDateSvc: OcDateSvc,
                forecastingSvc, confirmSvc, ocConfigSvc, helperSvc, notifySvc, errorReporter, $q, ocSecuritySvc, adminUrls: AdminUrls) {

                var vm = this;
                $scope.vm = this;

                var interval;

                vm.config = ocConfigSvc;

                vm.apiUrls = amtConstants.url;
                vm.adminUrls = adminUrls;

                vm.pageReady = false;

                // security
                vm.readonly = !ocSecuritySvc.isAuthorised('Security.Site.Forecasting', AccessTypes.readWrite);

                vm.showCopyHeaderText = false;
                vm.showBudgetApprovedBy = false;

                vm.currentDate = ocDateSvc.now();

                vm.forecastLengths = [];

                vm.fleets = null;
                vm.fleetControl = {};

                vm.budgetRestrictedTaskTypes = null;
                vm.budgetCapTaskTypesControl = {};

                this.$onInit = function () {

                    vm.wnd.processing = true;
                    vm.wnd.onClose = vm.onClose;

                    vm.mode = vm.wnd.initParams.mode;
                    vm.model = vm.wnd.initParams.model || {};

                    vm.budgetCapLabel = String.format('{0} ({1})', amtXlatSvc.xlat('forecastDetails.budgetCap'), ocConfigSvc.user.site.currency.symbol);
                    vm.showProgress = true;

                    WindowFactory.addButton(this, (vm.mode == ForecastCreateMode.copy ? 'common.copy_label' : 'common.save_label'), 'saveButton', () => vm.save(), true, !vm.readonly);
                    WindowFactory.addButton(this, 'common.cancel_label', 'cancelButton', () => vm.cancel());               

                    if (vm.mode == ForecastCreateMode.copy) {
                        vm.model.forecastName = String.format('{0} - {1}', vm.model.forecastName, amtXlatSvc.xlat('forecastList.copy'));
                    }

                    vm.getReferenceData().finally(function () {

                        vm.controls = forecastingSvc.setForecastControls(vm.mode, vm.model.forecastType, vm.model.startDate);

                        vm.pageReady = true;

                        $timeout(function () {
                            vm.setPristine();
                            vm.wnd.processing = false;
                        });
                    });
                };

                vm.getReferenceData = function () {

                    vm.getForecastLengths();

                    let refDataPromises = [];

                    refDataPromises.push(vm.getFleets());
                    refDataPromises.push(vm.getBudgetForecastStatusTypes());
                    refDataPromises.push(vm.getTaskTypes());
                    refDataPromises.push(vm.getForecastTypes());
                    refDataPromises.push(vm.getForecastMethods());

                    return $q.all(refDataPromises);
                };

                vm.setPristine = function () {
                    if (vm.form) {
                        vm.form.$setPristine();
                    }
                };

                vm.generateSessionId = function () {
                    function s4() {
                        /*jshint bitwise: false*/
                        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
                    }

                    return new Date().getTime() + "-" + s4();
                };

                vm.getForecastLengths = function () {

                    vm.forecastLengths = forecastingSvc.getForecastLengths();

                    if (!!vm.model.selectedForecastLength) {
                        vm.model.forecastLength = _.find(vm.forecastLengths, f => f.key === vm.model.selectedForecastLength);
                    }
                };

                vm.getForecastTypes = function () {
                    return forecastingSvc.getForecastTypes().then(function (forecastTypes) {
                        vm.forecastTypes = forecastTypes;

                        if (!!vm.model.selectedForecastTypeId) {
                            vm.model.forecastType = _.find(vm.forecastTypes, f => f.key == vm.model.selectedForecastTypeId);

                            vm.onForecastTypeChange(vm.model.forecastType, true);
                        }
                    });
                };

                vm.getTaskTypes = function () {
                    return forecastingSvc.getBudgetRestrictedTaskTypes().then(function (taskTypes) {
                        vm.budgetRestrictedTaskTypes = taskTypes;
                    });
                };

                vm.getForecastMethods = function () {
                    return forecastingSvc.getForecastMethods().then(function (forecastMethods) {
                        vm.forecastMethods = forecastMethods;

                        if (!!vm.model.selectedForecastMethodId) {
                            vm.model.forecastMethod = _.find(vm.forecastMethods, f => f.key == vm.model.selectedForecastMethodId);
                        }
                    });
                };

                vm.getFleets = function () {
                    return forecastingSvc.getFleets().then(function (fleets) {
                        vm.fleets = fleets;
                    });
                };

                vm.getBudgetForecastStatusTypes = function () {
                    return forecastingSvc.getBudgetForecastStatusTypes().then(function (budgetForecastStatusTypes) {
                        vm.budgetForecastStatusTypes = budgetForecastStatusTypes;
                    });
                };

                vm.onForecastTypeChange = function (forecastType, initialLoad) {

                    if (!initialLoad) {
                        // always default start date to now
                        vm.model.startDate = vm.currentDate;
                        vm.model.forecastMethod = null;
                    }

                    vm.controls = forecastingSvc.setForecastControls(vm.mode, forecastType, vm.currentDate);

                    $timeout(function () {

                        switch (forecastType.name) {

                            case ForecastType.live:

                                vm.showCopyHeaderText = false;

                                if (vm.fleetControl && vm.fleetControl.selectAll) {
                                    vm.fleetControl.selectAll(); // select all fleets
                                }

                                vm.model.endDate = null; // clear the end date                            

                                vm.clearBudgetDetails();
                                vm.model.budgetRestricted = false; // not budget restricted

                                break;

                            case ForecastType.monthToMonth:

                                vm.showCopyHeaderText = vm.mode == ForecastCreateMode.copy;

                                vm.model.forecastLength = null; // clear forecast length

                                if (!initialLoad) {

                                    if (vm.mode == ForecastCreateMode.add && vm.fleetControl && vm.fleetControl.clear) {
                                        vm.fleetControl.clear(true); // clear fleets
                                    }

                                    // if current date is before April 1, default end date to June 30
                                    // if current date is between April 1 and September 31, default end date to December 31
                                    // if current date is between October 1 and December 31, default end date to June 30 the next year
                                    let currentYear = vm.currentDate.getFullYear();

                                    vm.model.endDate = (
                                        new Date(vm.model.startDate) < new Date(currentYear, 3, 1) ? new Date(currentYear, 5, 30) :
                                            (new Date(vm.model.startDate) < new Date(currentYear, 9, 1) ? new Date(currentYear, 11, 31) : new Date(currentYear + 1, 5, 30))
                                    );
                                }

                                break;

                            case ForecastType.budget:

                                vm.showCopyHeaderText = vm.mode == ForecastCreateMode.copy;

                                vm.model.forecastLength = null; // clear forecast length

                                if (!initialLoad) {

                                    if (vm.mode == ForecastCreateMode.add && vm.fleetControl && vm.fleetControl.clear) {
                                        vm.fleetControl.clear(true); // clear fleets
                                    }

                                    vm.model.endDate = ocDateSvc.addMonths(vm.model.startDate, 12); // default to 12 months from start
                                }

                                break;
                        }                    
                    });
                };

                vm.onStartDateChanged = function (date) {

                    if (!!date && !!vm.model.forecastType && !vm.wnd.processing) {

                        vm.controls = forecastingSvc.setForecastControls(vm.mode, vm.model.forecastType, date);

                        // if the existing forecast end date is now outside of the allowed range for the forecast, clear it
                        if ((vm.controls.endDate.minDate && vm.model.endDate < vm.controls.endDate.minDate) || (vm.controls.endDate.maxDate && vm.model.endDate > vm.controls.endDate.maxDate)) {
                            vm.model.endDate = null;
                        }
                    }
                };

                vm.onBudgetForecastStatusTypeChange = function (option) {
                    vm.model.approvedBy = null;
                };

                vm.onBudgetRestrictedChange = function () {
                    // if 'budget restricted forecast' gets unchecked then clear the budget cap and budget cap inclusions
                    if (!vm.model.budgetRestricted) {
                        vm.clearBudgetDetails();
                    }
                };

                vm.clearBudgetDetails = function () {
                    vm.model.budgetCap = null;
                    vm.model.budgetCapTaskTypeIds = null;
                    vm.model.allowNewVehicleFitmentsToExceedBudgetCap = false;

                    if (vm.budgetCapTaskTypesControl && vm.budgetCapTaskTypesControl.clear) {
                        vm.budgetCapTaskTypesControl.clear(true);
                    }
                };

                vm.save = function () {

                    if (vm.model.endDate && new Date(vm.model.startDate) > new Date(vm.model.endDate)) {
                        notifySvc.error(amtXlatSvc.xlat("forecastDetails.endDateMustBeGreaterThanStartDate"));
                        return;
                    }

                    let savePromise = $q.resolve();

                    vm.wnd.processing = true;

                    vm.sessionId = vm.generateSessionId();
                    $scope.checkForecastProgress = {};

                    // if approving a budget forecast check if there is already an approved budget forecast
                    if (vm.model.forecastType.name == ForecastType.budget) {

                        if (vm.model.budgetForecastStatusType && vm.model.budgetForecastStatusType.name == BudgetForecastStatusType.approved) {

                            savePromise = amtCommandQuerySvc.get(vm.apiUrls.checkApprovedBudgetForecast).then(function (response) {
                                if (response && +response.data && +response.data !== +vm.model.forecastId) {
                                    return confirmSvc.confirmMessage('forecastDetails.approvedBudgetExistsHeader', 'forecastDetails.approvedBudgetExists');
                                }
                            });
                        }
                    }

                    savePromise.then(function () {

                        let model = {
                            forecastId: vm.model.forecastId,
                            name: vm.model.forecastName,
                            startDate: ocDateSvc.removeLocalTimeZoneOffset(ocDateSvc.toDate(vm.model.startDate)),
                            endDate: vm.model.endDate ? ocDateSvc.removeLocalTimeZoneOffset(ocDateSvc.toDate(vm.model.endDate)) : null,
                            fleetIds: vm.model.fleetIds,
                            sessionId: vm.sessionId,
                            forecastTypeId: helperSvc.getKey(vm.model.forecastType),
                            forecastMethodId: helperSvc.getKey(vm.model.forecastMethod),
                            forecastLength: helperSvc.getKey(vm.model.forecastLength),
                            budgetForecastStatusTypeId: helperSvc.getKey(vm.model.budgetForecastStatusType),
                            budgetForecastApprovedBy: helperSvc.getKey(vm.model.approvedBy),
                            budgetRestricted: vm.model.budgetRestricted,
                            budgetCap: vm.model.budgetCap,
                            budgetCapTaskTypeIds: vm.model.budgetCapTaskTypeIds,
                            allowNewVehicleFitmentsToExceedBudgetCap: vm.model.allowNewVehicleFitmentsToExceedBudgetCap,
                            includeRimIntervalsByDays: vm.model.includeRimIntervalsByDays,
                            active: vm.model.active
                        };

                        if (vm.showProgress) {
                            interval = $interval(function () {
                                $scope.checkForecastProgressCall();
                            }, 1000);
                        }

                        return forecastingSvc.addUpdateCopyForecast(vm.mode, model).then(function (response) {

                            if (vm.wnd.onDataChanged) {
                                vm.wnd.onDataChanged(response);
                            }

                            WindowFactory.closeWindow(vm.wnd);

                        }).catch(function (error) {

                            errorReporter.logError(error);                            

                            if (interval) {
                                $scope.checkForecastProgressCall();
                                $interval.cancel(interval);
                            }

                        });

                    }).finally(function () {
                        vm.wnd.processing = false;
                    });
                };

                vm.cancel = function () {
                    closeWindow();
                };

                function closeWindow() {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {

                        if (interval) {
                            $interval.cancel(interval);
                        }

                        vm.setPristine();

                        WindowFactory.closeWindow(vm.wnd);
                    });
                }

                $scope.checkForecastProgressCall = function () {

                    amtCommandQuerySvc.put(vm.apiUrls.checkForecastProgress, {
                        sessionId: vm.sessionId
                    }).then(function (response) {

                        $scope.checkForecastProgress = response;
                        $scope.checkForecastProgress.type = "success";

                        if ($scope.checkForecastProgress.percentage === 100 || $scope.checkForecastProgress.forecastProgressStatus === "error") {

                            $interval.cancel(interval);                            

                            if ($scope.checkForecastProgress.forecastProgressStatus === "error") {
                                $scope.checkForecastProgress.type = "warning";
                            }
                        }
                    }).catch(function (error) {
                        errorReporter.logError(error);
                        $interval.cancel(interval);
                    });
                };

                $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.readonly', 'vm.wnd.processing'], function () {
                    vm.buttonStates.saveButton.disabled = vm.form.$invalid || (vm.form.$pristine && vm.mode == ForecastCreateMode.update) || vm.readonly || !!vm.wnd.processing;
                });

                $scope.$watchGroup(['vm.model.forecastType', 'vm.model.budgetForecastStatusType'], function () {
                    vm.showBudgetApprovedBy = (vm.model.forecastType && vm.model.forecastType.name == ForecastType.budget)
                        && (vm.model.budgetForecastStatusType && vm.model.budgetForecastStatusType.name == BudgetForecastStatusType.approved);
                });

                return vm;
            }
        ]
    });
