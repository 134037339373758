/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var pager_input_directive_1 = require("./pager-input.directive");
var pager_dropdown_directive_1 = require("./pager-dropdown.directive");
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var kendo_angular_inputs_1 = require("@progress/kendo-angular-inputs");
var kendo_angular_label_1 = require("@progress/kendo-angular-label");
var pager_component_1 = require("./pager.component");
var pager_prev_buttons_component_1 = require("./pager-prev-buttons.component");
var pager_next_buttons_component_1 = require("./pager-next-buttons.component");
var pager_numeric_buttons_component_1 = require("./pager-numeric-buttons.component");
var pager_input_component_1 = require("./pager-input.component");
var pager_info_component_1 = require("./pager-info.component");
var pager_page_sizes_component_1 = require("./pager-page-sizes.component");
var pager_template_directive_1 = require("./pager-template.directive");
var shared_module_1 = require("../shared.module");
var kendo_angular_common_1 = require("@progress/kendo-angular-common");
var kendo_angular_dropdowns_1 = require("@progress/kendo-angular-dropdowns");
var importedModules = [
    common_1.CommonModule,
    kendo_angular_inputs_1.InputsModule,
    kendo_angular_label_1.LabelModule,
    kendo_angular_dropdowns_1.DropDownsModule,
    kendo_angular_common_1.ResizeSensorModule,
    shared_module_1.SharedModule
];
var INTERNAL_COMPONENTS = [
    pager_component_1.PagerComponent,
    pager_prev_buttons_component_1.PagerPrevButtonsComponent,
    pager_next_buttons_component_1.PagerNextButtonsComponent,
    pager_numeric_buttons_component_1.PagerNumericButtonsComponent,
    pager_input_component_1.PagerInputComponent,
    pager_info_component_1.PagerInfoComponent,
    pager_page_sizes_component_1.PagerPageSizesComponent,
    pager_template_directive_1.PagerTemplateDirective,
    pager_dropdown_directive_1.PagerDropDownListDirective,
    pager_input_directive_1.PagerInputDirective
];
/**
 * @hidden
 */
var PagerModule = /** @class */ (function () {
    function PagerModule() {
    }
    PagerModule.exports = function () {
        return [
            pager_component_1.PagerComponent,
            pager_prev_buttons_component_1.PagerPrevButtonsComponent,
            pager_next_buttons_component_1.PagerNextButtonsComponent,
            pager_numeric_buttons_component_1.PagerNumericButtonsComponent,
            pager_input_component_1.PagerInputComponent,
            pager_info_component_1.PagerInfoComponent,
            pager_page_sizes_component_1.PagerPageSizesComponent,
            pager_template_directive_1.PagerTemplateDirective,
            pager_dropdown_directive_1.PagerDropDownListDirective,
            pager_input_directive_1.PagerInputDirective
        ];
    };
    PagerModule = tslib_1.__decorate([
        core_1.NgModule({
            declarations: [INTERNAL_COMPONENTS],
            exports: [INTERNAL_COMPONENTS],
            imports: importedModules.slice()
        })
    ], PagerModule);
    return PagerModule;
}());
exports.PagerModule = PagerModule;
