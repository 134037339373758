/**
 * A button representing the condition of a vehicle. Includes all required fields for the condition.
 */
//import angular from 'angular';
import OcDateSvc from '../../../services/ocDateSvc';
import { OcDynamicGridColumn, OcDynamicGridColumnMaker, OcDynamicGridColumnType, OcDynamicGridAlignment } from '../../../directives/ui/ocDynamicGrid';



class VehicleConditionButton {
    constructor(public id: string, public name: string, public description: string, public parentConditionId: string, public disabled: boolean, public loading: boolean,
        public isKnown: boolean, public defaultCost: boolean, public showFittedComponents: boolean, public detailFields: VehicleDetailFields, public associatedStatusType?: string, public vehicles?: Array<Object>) { }
}

class VehicleConditionButtonMaker {
    static create(obj: VehicleConditionButton) {
        return new VehicleConditionButton(obj.id, obj.name, obj.description, obj.parentConditionId, obj.disabled, obj.loading, obj.isKnown, obj.defaultCost,
            obj.showFittedComponents, obj.detailFields, obj.associatedStatusType, obj.vehicles);
    }
}

class VehicleDetailFields {
    constructor(public date: VehicleDetailField, public sapFields: VehicleDetailField, public specification: VehicleDetailField, public siteSerialNumber: VehicleDetailField, public manufacturerSerialNumber: VehicleDetailField, public hasHoursMeter: VehicleDetailField,
        public hasDistanceMeter: VehicleDetailField, public hours: VehicleDetailField, public hoursNewMeter: VehicleDetailField, public distance: VehicleDetailField, public distanceNewMeter: VehicleDetailField,
        public comment: VehicleDetailField, public attachments: VehicleDetailField) { }
}

class VehicleDetailField {
    constructor(public show: boolean, public readonly: boolean, public prefill: boolean) { }
}

class VehicleDetailFieldsMaker {
    static create(obj: VehicleDetailFields) {
        return new VehicleDetailFields(obj.date, obj.sapFields, obj.specification, obj.siteSerialNumber, obj.manufacturerSerialNumber, obj.hasHoursMeter, obj.hasDistanceMeter,
            obj.hours, obj.hoursNewMeter, obj.distance, obj.distanceNewMeter, obj.comment, obj.attachments);
    }
}

class VehicleDetailFieldMaker {
    static create(obj: VehicleDetailField) {
        return new VehicleDetailField(obj.show, obj.readonly, obj.prefill);
    }
}

class VehicleFittedComponentColumns {
    constructor(public tyres: Array<OcDynamicGridColumn>, public rims: Array<OcDynamicGridColumn>, public chains: Array<OcDynamicGridColumn>) { }
}

const enum VehicleConditionType {
    new = 'New',
    transferred = 'Transferred',
    pending = 'Pending'
}

class VehicleReceiveFactory {
    
    constructor(private amtXlatSvc: IAmtXlatSvc, private ocSecuritySvc: IOcSecuritySvc, private dataBroker: IDataBroker, private ocDateSvc: OcDateSvc) { }    

    /**
     * Get an array of Vehicle Condition objects.
     *
     * @param {boolean} skipValidation - If true, all relevant conditions will be returned without validating.
     * @param {boolean} allowThirdyPartyVehicles - Site Setting specifying whether or not the Site accepts Third Party vehicles.
     * @param {string} parentCondition - name of a condition where the conditions to be returned are the children of this condition.
     * @returns {Array<VehicleConditionButton>} - An Array of valid vehicle Conditions.
     */
    getVehicleConditions(skipValidation: boolean, allowThirdPartyVehicles: boolean, parentCondition: string) {

        let canReceivePending: boolean = this.ocSecuritySvc.isAuthorised('Security.Vehicles.Receive.Pending', AccessTypes.readWrite);
        let canReceiveNew: boolean = this.ocSecuritySvc.isAuthorised('Security.Vehicles.Receive.New', AccessTypes.readWrite);
        let canReceiveTransferred: boolean = this.ocSecuritySvc.isAuthorised('Security.Vehicles.Receive.Transferred', AccessTypes.readWrite);

        let conditions: Array<VehicleConditionButton> = [];

        let newCondition = VehicleConditionButtonMaker.create({
            id: VehicleConditionType.new,
            name: this.amtXlatSvc.xlat('vehicle.new2ndHand'),
            description: null,
            parentConditionId: null,
            disabled: false,
            loading: false,
            defaultCost: true,
            isKnown: false,
            associatedStatusType: StatusTypeName.new.toUpperCase(),
            showFittedComponents: false,
            detailFields: VehicleDetailFieldsMaker.create({
                date: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                }),
                sapFields: VehicleDetailFieldMaker.create({
                    show: false,
                    readonly: false,
                    prefill: false
                }),
                specification: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                }),
                siteSerialNumber: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                }),
                manufacturerSerialNumber: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                }),
                hasHoursMeter: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                }),
                hasDistanceMeter: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                }),
                hours: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                }),
                hoursNewMeter: VehicleDetailFieldMaker.create({
                    show: false,
                    readonly: false,
                    prefill: false
                }),
                distance: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                }),
                distanceNewMeter: VehicleDetailFieldMaker.create({
                    show: false,
                    readonly: false,
                    prefill: false
                }),
                comment: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                }),
                attachments: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                })
            })
        });

        let transferredCondition = VehicleConditionButtonMaker.create({
            id: VehicleConditionType.transferred,
            name: this.amtXlatSvc.xlat('vehicle.transferred'),
            description: null,
            parentConditionId: null,
            disabled: true,
            loading: true,
            defaultCost: true,
            isKnown: true,
            associatedStatusType: StatusTypeName.dispatchedTransfer.toUpperCase(),
            showFittedComponents: true,
            detailFields: VehicleDetailFieldsMaker.create({
                date: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                }),
                sapFields: VehicleDetailFieldMaker.create({
                    show: false,
                    readonly: false,
                    prefill: false
                }),
                specification: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: true,
                    prefill: true
                }),
                siteSerialNumber: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                }),
                manufacturerSerialNumber: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: true,
                    prefill: true
                }),
                hasHoursMeter: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: true
                }),
                hasDistanceMeter: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: true
                }),
                hours: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: true
                }),
                hoursNewMeter: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                }),
                distance: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: true
                }),
                distanceNewMeter: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                }),
                comment: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                }),
                attachments: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                })
            })
        });

        let pendingCondition = VehicleConditionButtonMaker.create({
            id: VehicleConditionType.pending,
            name: this.amtXlatSvc.xlat('vehicle.pending'),
            description: null,
            parentConditionId: null,
            disabled: true,
            loading: true,
            defaultCost: true,
            isKnown: true,
            showFittedComponents: false,
            detailFields: VehicleDetailFieldsMaker.create({
                date: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                }),
                sapFields: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: true
                }),
                specification: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                }),
                siteSerialNumber: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                }),
                manufacturerSerialNumber: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: true,
                    prefill: true
                }),
                hasHoursMeter: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                }),
                hasDistanceMeter: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                }),
                hours: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                }),
                hoursNewMeter: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                }),
                distance: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                }),
                distanceNewMeter: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                }),
                comment: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                }),
                attachments: VehicleDetailFieldMaker.create({
                    show: true,
                    readonly: false,
                    prefill: false
                })
            })
        });

        if (skipValidation) {

            conditions.push(newCondition);
            conditions.push(transferredCondition);
            conditions.push(pendingCondition);

        } else {

            if (canReceiveNew && (!allowThirdPartyVehicles))
                conditions.push(newCondition);

            if (canReceiveTransferred)
                conditions.push(transferredCondition);

            if (canReceivePending && allowThirdPartyVehicles)
                conditions.push(pendingCondition);
        }

        if (!parentCondition) {
            conditions = conditions.filter(c => c.parentConditionId === null);
        } else if (parentCondition !== 'All') {
            conditions = conditions.filter(c => c.parentConditionId === parentCondition);
        }

        return conditions;
    }

    /**
    * Get an array of OcDynamicGrid column objects representing the receive summary page for a given vehicle condition.
    *
    * @param {VehicleConditionButton} condition - the condition to build the receive summary for.                 
    * @returns {Array<OcDynamicGridColumn>} - An Array of OcDynamicGrid columns that make up the receive summary grid.
    */
    buildReceiveSummary(condition: VehicleConditionButton) {

        let summaryItems: Array<OcDynamicGridColumn> = [];

        summaryItems.push(OcDynamicGridColumnMaker.create({
            type: OcDynamicGridColumnType.string,
            colSpan: 4,
            headerResource: 'vehicle.summaryManSerial_label',
            valuePath: 'manufacturerSerialNumber',
            columnAlignment: OcDynamicGridAlignment.center
        }));

        summaryItems.push(OcDynamicGridColumnMaker.create({
            type: OcDynamicGridColumnType.string,
            colSpan: 8,
            headerResource: 'vehicle.summarySpecification_label',
            valuePath: (condition.showFittedComponents ? 'specificationDescription' : 'specification.description'),
            columnAlignment: OcDynamicGridAlignment.center
        }));

        return summaryItems;
    }

    /**
    * Get the ocDynamicGrid columns for the 'Serials' grid for known vehicle receive.
    *
    * @param {string} conditionId - the name of the condition to build the serial grid for.
    * @returns {Array<OcDynamicGridColumn>} - An Array of OcDynamicGrid columns that make up the serial screen for known vehicle require.
    */
    getSerialStepColumns(conditionId: string) {

        let serialStepColumns: Array<OcDynamicGridColumn> = [];

        switch (conditionId) {
            case (VehicleConditionType.pending):

                serialStepColumns.push(OcDynamicGridColumnMaker.create({
                    headerResource: 'vehicle.summarySerialNumber_label',
                    type: OcDynamicGridColumnType.string,
                    valuePath: 'manufacturerSerialNumber',
                    onClick: 'editVehicleSerial',
                    colSpan: 2,
                    columnAlignment: OcDynamicGridAlignment.center
                }));

                serialStepColumns.push(OcDynamicGridColumnMaker.create({
                    headerResource: 'vehicle.helpFields',
                    type: OcDynamicGridColumnType.string,
                    valuePath: 'dataFields',
                    colSpan: 8,
                    columnAlignment: OcDynamicGridAlignment.left
                }));

                serialStepColumns.push(OcDynamicGridColumnMaker.create({
                    headerResource: 'vehicle.receive_label',
                    type: OcDynamicGridColumnType.checkbox,
                    valuePath: 'selected',
                    colSpan: 2,
                    columnAlignment: OcDynamicGridAlignment.center,
                    hideIfInvalid: true,
                    editable: 'valid'
                }));

                break;

            case (VehicleConditionType.transferred):

                serialStepColumns.push(OcDynamicGridColumnMaker.create({
                    headerResource: 'vehicle.summaryManSerial_label',
                    type: OcDynamicGridColumnType.string,
                    valuePath: 'manufacturerSerialNumber',
                    onClick: 'editVehicleSerial',
                    colSpan: 2,
                    columnAlignment: OcDynamicGridAlignment.center
                }));

                serialStepColumns.push(OcDynamicGridColumnMaker.create({
                    headerResource: 'vehicle.summarySpecification_label',
                    type: OcDynamicGridColumnType.string,
                    valuePath: 'specificationDescription',
                    colSpan: 6,
                    columnAlignment: OcDynamicGridAlignment.center
                }));

                serialStepColumns.push(OcDynamicGridColumnMaker.create({
                    headerResource: 'equipment.site',
                    type: OcDynamicGridColumnType.string,
                    valuePath: 'site',
                    colSpan: 2,
                    columnAlignment: OcDynamicGridAlignment.center
                }));

                serialStepColumns.push(OcDynamicGridColumnMaker.create({
                    headerResource: 'vehicle.receive_label',
                    type: OcDynamicGridColumnType.checkbox,
                    valuePath: 'selected',
                    colSpan: 2,
                    columnAlignment: OcDynamicGridAlignment.center,
                    hideIfInvalid: true,
                    editable: 'valid'
                }));

                break;
        }

        return serialStepColumns;
    }

    /**
    * Get the ocDynamicGrid columns for the Fitted Components tabs of the vehicle receive popup.
    *                
    * @returns {VehicleFittedComponentColumns} - sets of OcDynamicGrid columns defining the Fitted Components tab of vehicle receive popup (tyres, rims, chains)
    */
    getFittedComponentColumns() {

        // remember that colSpans must equal 12 for each component type
        let columns = new VehicleFittedComponentColumns([], [], []);

        // TYRES

        columns.tyres.push(OcDynamicGridColumnMaker.create({
            headerResource: 'vehicle.receiveFittedComponents_position',
            type: OcDynamicGridColumnType.string,
            valuePath: 'wheelPosition',
            colSpan: 1,
            columnAlignment: OcDynamicGridAlignment.center
        }));

        columns.tyres.push(OcDynamicGridColumnMaker.create({
            headerResource: 'vehicle.receiveFittedComponents_serial',
            type: OcDynamicGridColumnType.string,
            valuePath: 'tyre.manufacturerSerialNumber',
            colSpan: 2,
            columnAlignment: OcDynamicGridAlignment.left
        }));

        columns.tyres.push(OcDynamicGridColumnMaker.create({
            headerResource: 'vehicle.receiveFittedComponents_specification',
            type: OcDynamicGridColumnType.string,
            valuePath: 'tyre.specification',
            colSpan: 4,
            columnAlignment: OcDynamicGridAlignment.left
        }));

        columns.tyres.push(OcDynamicGridColumnMaker.create({
            headerResource: 'vehicle.receiveFittedComponents_totalHours',
            type: OcDynamicGridColumnType.string,
            valuePath: 'tyre.totalHours',
            colSpan: 1,
            columnAlignment: OcDynamicGridAlignment.right
        }));

        columns.tyres.push(OcDynamicGridColumnMaker.create({
            headerResource: 'vehicle.receiveFittedComponents_totalDistance',
            type: OcDynamicGridColumnType.string,
            valuePath: 'tyre.totalDistance',
            colSpan: 1,
            columnAlignment: OcDynamicGridAlignment.right
        }));

        columns.tyres.push(OcDynamicGridColumnMaker.create({
            headerResource: 'vehicle.receiveFittedComponents_rtd1',
            type: OcDynamicGridColumnType.number,
            valuePath: 'tyre.depth1',
            colSpan: 1,
            columnAlignment: OcDynamicGridAlignment.right,
            editable: 'tyre.manufacturerSerialNumber',
            required: true,
            decimalPlaces: 0,
            minValue: 0
        }));

        columns.tyres.push(OcDynamicGridColumnMaker.create({
            headerResource: 'vehicle.receiveFittedComponents_rtd2',
            type: OcDynamicGridColumnType.number,
            valuePath: 'tyre.depth2',
            colSpan: 1,
            columnAlignment: OcDynamicGridAlignment.left,
            editable: 'tyre.manufacturerSerialNumber',
            required: true,
            decimalPlaces: 0,
            minValue: 0
        }));

        columns.tyres.push(OcDynamicGridColumnMaker.create({
            headerResource: 'vehicle.receiveFittedComponents_percentWorn',
            type: OcDynamicGridColumnType.string,
            valuePath: 'tyre.percentWorn',
            colSpan: 1,
            columnAlignment: OcDynamicGridAlignment.center
        }));

        // RIMS

        columns.rims.push(OcDynamicGridColumnMaker.create({
            headerResource: 'vehicle.receiveFittedComponents_position',
            type: OcDynamicGridColumnType.string,
            valuePath: 'wheelPosition',
            colSpan: 1,
            columnAlignment: OcDynamicGridAlignment.center
        }));

        columns.rims.push(OcDynamicGridColumnMaker.create({
            headerResource: 'vehicle.receiveFittedComponents_serial',
            type: OcDynamicGridColumnType.string,
            valuePath: 'rim.manufacturerSerialNumber',
            colSpan: 2,
            columnAlignment: OcDynamicGridAlignment.left
        }));

        columns.rims.push(OcDynamicGridColumnMaker.create({
            headerResource: 'vehicle.receiveFittedComponents_specification',
            type: OcDynamicGridColumnType.string,
            valuePath: 'rim.specification',
            colSpan: 4,
            columnAlignment: OcDynamicGridAlignment.left
        }));

        columns.rims.push(OcDynamicGridColumnMaker.create({
            headerResource: 'vehicle.receiveFittedComponents_totalHours',
            type: OcDynamicGridColumnType.string,
            valuePath: 'rim.totalHours',
            colSpan: 1,
            columnAlignment: OcDynamicGridAlignment.right
        }));

        columns.rims.push(OcDynamicGridColumnMaker.create({
            headerResource: 'vehicle.receiveFittedComponents_totalDistance',
            type: OcDynamicGridColumnType.string,
            valuePath: 'rim.totalDistance',
            colSpan: 1,
            columnAlignment: OcDynamicGridAlignment.right
        }));

        columns.rims.push(OcDynamicGridColumnMaker.create({
            headerResource: 'vehicle.receiveFittedComponents_doubleGutter',
            type: OcDynamicGridColumnType.string,
            valuePath: 'rim.doubleGutterRim',
            colSpan: 1,
            columnAlignment: OcDynamicGridAlignment.center,
            boolToString: true
        }));

        columns.rims.push(OcDynamicGridColumnMaker.create({
            headerResource: 'vehicle.receiveFittedComponents_lastNdt',
            type: OcDynamicGridColumnType.string,
            valuePath: 'rim.hoursSinceLastNdt',
            colSpan: 2,
            columnAlignment: OcDynamicGridAlignment.center,
            showNotAvailableOnEmpty: false
        }));

        // CHAINS

        columns.chains.push(OcDynamicGridColumnMaker.create({
            headerResource: 'vehicle.receiveFittedComponents_position',
            type: OcDynamicGridColumnType.string,
            valuePath: 'wheelPosition',
            colSpan: 1,
            columnAlignment: OcDynamicGridAlignment.center
        }));

        columns.chains.push(OcDynamicGridColumnMaker.create({
            headerResource: 'vehicle.receiveFittedComponents_serial',
            type: OcDynamicGridColumnType.string,
            valuePath: 'chain.manufacturerSerialNumber',
            colSpan: 2,
            columnAlignment: OcDynamicGridAlignment.left
        }));

        columns.chains.push(OcDynamicGridColumnMaker.create({
            headerResource: 'vehicle.receiveFittedComponents_specification',
            type: OcDynamicGridColumnType.string,
            valuePath: 'chain.specification',
            colSpan: 4,
            columnAlignment: OcDynamicGridAlignment.left
        }));

        columns.chains.push(OcDynamicGridColumnMaker.create({
            headerResource: 'vehicle.receiveFittedComponents_totalHours',
            type: OcDynamicGridColumnType.string,
            valuePath: 'chain.totalHours',
            colSpan: 1,
            columnAlignment: OcDynamicGridAlignment.right
        }));

        columns.chains.push(OcDynamicGridColumnMaker.create({
            headerResource: 'vehicle.receiveFittedComponents_totalDistance',
            type: OcDynamicGridColumnType.string,
            valuePath: 'chain.totalDistance',
            colSpan: 1,
            columnAlignment: OcDynamicGridAlignment.right
        }));

        columns.chains.push(OcDynamicGridColumnMaker.create({
            headerResource: 'vehicle.receiveFittedComponents_rcd1',
            type: OcDynamicGridColumnType.number,
            valuePath: 'chain.depth1',
            colSpan: 1,
            columnAlignment: OcDynamicGridAlignment.right,
            editable: 'chain.manufacturerSerialNumber',
            required: true,
            decimalPlaces: 0,
            minValue: 0
        }));

        columns.chains.push(OcDynamicGridColumnMaker.create({
            headerResource: 'vehicle.receiveFittedComponents_rcd2',
            type: OcDynamicGridColumnType.number,
            valuePath: 'chain.depth2',
            colSpan: 1,
            columnAlignment: OcDynamicGridAlignment.left,
            editable: 'chain.manufacturerSerialNumber',
            required: true,
            decimalPlaces: 0,
            minValue: 0
        }));

        columns.chains.push(OcDynamicGridColumnMaker.create({
            headerResource: 'vehicle.receiveFittedComponents_percentWorn',
            type: OcDynamicGridColumnType.string,
            valuePath: 'chain.percentWorn',
            colSpan: 1,
            columnAlignment: OcDynamicGridAlignment.center
        }));

        return columns;
    }

    /**
    * Validate the details entered for a vehicle and raise errors if required
    *                
    * @param {any} vehicle - the vehicle to validate.
    * @param {VehicleConditionButton} condition - the selected vehicle condition.
    * @param {any} serials - an array of the vehicles already loaded into the serial grid (for serial number validation)
    * @returns {string} - an error message if one is raised.
    */
    async validateVehicle(vehicle: any, condition: VehicleConditionButton, serials: any) {

        let error: string = null;

        let receiveDate: Date = new Date(vehicle.combinedDateTime);

        // date in future        
        if (receiveDate > new Date()) {
            error = this.amtXlatSvc.xlat('equipment.eventDateCannotOccurInFuture');
            return error;
        }

        // date before current status start date
        if (vehicle.dateFrom) {
            if (this.ocDateSvc.removeLocalTimeZoneOffset(new Date(vehicle.combinedDateTime)) <= this.ocDateSvc.removeLocalTimeZoneOffset(vehicle.dateFrom)) {
                error = this.amtXlatSvc.xlat('receive.validation_cannotReceiveBeforeCurrentStatus', vehicle.dateFrom.toString().toDateTimeFormat('dd-MM-yyyy HH:mm'));
                return error;
            }
        }

        // hours decreased without new meter
        if (vehicle.originalHours && vehicle.hours && !vehicle.hoursNewMeter && vehicle.hours < vehicle.originalHours) {
            error = this.amtXlatSvc.xlat('receive.validationSummary_HoursDecreased', vehicle.originalHours);
            return error;
        }

        // distance decreased without new meter
        if (vehicle.originalDistance && vehicle.distance && !vehicle.distanceNewMeter && vehicle.distance < vehicle.originaldistance) {
            error = this.amtXlatSvc.xlat('receive.validationSummary_DistanceDecreased', vehicle.originaldistance);
            return error;
        }

        // hours increased by more than the real hours elapsed since the last reading
        if (vehicle.lastHoursReading && vehicle.hours && !vehicle.hoursNewMeter) {

            let realHoursElapsed = Math.floor(Math.abs(receiveDate.getTime() - new Date(vehicle.lastHoursReading.date).getTime()) / (60 * 60 * 1000));

            let hoursChange = vehicle.hours - vehicle.lastHoursReading.value;

            if (realHoursElapsed >= 0 && realHoursElapsed < hoursChange) {
                error = this.amtXlatSvc.xlat('receive.validationSummary_HoursIncreasedByMoreThanRealHoursElapsed', hoursChange, realHoursElapsed);
                return error;
            }
        }

        // average rtd increased
        if (vehicle.fittedComponents && vehicle.fittedComponents.length > 0) {

            vehicle.fittedComponents.forEach(p => {
                if (p.tyre && p.tyre.originalRemainingDepth && p.tyre.originalRemainingDepth < ((+p.tyre.depth1 + +p.tyre.depth2) / 2)) {
                    error = this.amtXlatSvc.xlat('receive.validationSummary_TyreDepthIncreased', p.tyre.manufacturerSerialNumber, p.wheelPosition, p.tyre.originalRemainingDepth);
                    return error;
                }

                if (p.chain && p.chain.originalRemainingDepth && p.chain.originalRemainingDepth < ((+p.chain.depth1 + +p.chain.depth2) / 2)) {
                    error = this.amtXlatSvc.xlat('receive.validationSummary_ChainDepthIncreased', p.chain.manufacturerSerialNumber, p.wheelPosition, p.chain.originalRemainingDepth);
                    return error;
                }
            });
        }

        // check if we have used these serial numbers already during the current receive
        if (vehicle.manufacturerSerialNumber)
            var localManufacturerMatch = serials.find(item => item.manufacturerSerial && item.manufacturerSerial.toUpperCase() === vehicle.manufacturerSerialNumber.toUpperCase() && item.id !== vehicle.id);

        if (vehicle.siteSerialNumber)
            var localSiteMatch = serials.find(item => item.siteSerial && item.siteSerial.toUpperCase() === vehicle.siteSerialNumber.toUpperCase() && item.id !== vehicle.id);

        if (localManufacturerMatch) {
            error = this.amtXlatSvc.xlat('equipment.errorManSerialExists');
            return error;
        } else if (localSiteMatch) {
            error = this.amtXlatSvc.xlat('equipment.errorSiteSerialExists');
            return error;
        }

        // check format of serial (as necessary), only when possible to modify manufacturer serial number
        if (condition.detailFields.specification.show && vehicle.specification) {
            if (vehicle.specification.serialValidation &&
                condition.detailFields.manufacturerSerialNumber.show &&
                !vehicle.manufacturerSerialNumber.match(vehicle.specification.serialValidation.validator)) {
                // No duplicates but validation failed
                error = this.amtXlatSvc.xlat('receive.invalidSerialNumber', vehicle.specification.serialValidation.exampleSerial);
                return error;
            }
        }

        if (!condition.isKnown) {

            // check the site/client for an existing serial number
            let response = await this.dataBroker.checkUniqueSerialNumber(
                vehicle.manufacturerSerialNumber,
                vehicle.siteSerialNumber,
                EquipmentTypeName.vehicle,
                vehicle.id
            );

            if (response.manufacturerSerialNumber === true) {
                error = this.amtXlatSvc.xlat('equipment.errorManSerialExists');
            } else if (response.siteSerialNumber === true) {
                error = this.amtXlatSvc.xlat('equipment.errorSiteSerialExists');
            }

            return error;
        }

        return error;
    }

    /**
    * gets a default instance of a vehicle (for new/2nd hand)
    *                
    * @returns {any} - a default instance of a vehicle.
    */
    getDefaultVehicleDetails() {

        return {
            manufacturerSerialNumber: '',
            siteSerialNumber: '',
            combinedDateTime: undefined,
            hasHoursMeter: true,
            hasDistanceMeter: false,
            hours: undefined,
            distance: undefined,
            specification: undefined,
            comment: '',
            attachments: [],
            selected: false,
            valid: false,
            fittedComponents: undefined
        };
    }
}

angular.module('app.vehicle').factory('vehicleReceiveFactory', ['amtXlatSvc', 'ocSecuritySvc', 'dataBroker', 'ocDateSvc',
    (amtXlatSvc: IAmtXlatSvc, ocSecuritySvc: IOcSecuritySvc, dataBroker: IDataBroker, ocDateSvc: OcDateSvc) => new VehicleReceiveFactory(amtXlatSvc, ocSecuritySvc, dataBroker, ocDateSvc)
]);
