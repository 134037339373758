//import angular from 'angular';
import AdminUrls from '../../../adminUrls';
import tmpl from './details.html';
import HelperSvc from '../../../../../services/helperSvc';



    angular.module('app.admin').component('addEditCompound', {
        bindings: {
            initParams: '=',
            buttonMethods: '=',
            buttonStates: '=',
            buttons: '=',
            closeOnSave: '=',
            wnd: '='
        },
        template: tmpl,
        controller: ['$scope', 'confirmSvc', 'enums', 'adminUrls', 'WindowFactory', 'amtCommandQuerySvc',
            'notifySvc', 'amtXlatSvc', 'referenceDataUrls', 'ocConfigSvc', 'errorReporter', '$timeout', 'helperSvc', 'componentUrls',
            function ($scope, confirmSvc, enums, adminUrls: AdminUrls, WindowFactory: IWindowFactory, amtCommandQuerySvc,
                notifySvc, amtXlatSvc: IAmtXlatSvc, referenceDataUrls, ocConfigSvc, errorReporter, $timeout, helperSvc: HelperSvc, componentUrls) {

                var vm = this;
                $scope.vm = this;

                vm.urls = adminUrls;
                vm.referenceDataUrls = referenceDataUrls;
                vm.componentUrls = componentUrls;

                vm.enums = enums;
                vm.config = ocConfigSvc;

                // pass along dirty flag to the window for use on closing minimised windows
                $scope.$watch('vm.form.$dirty', function () {
                    vm.wnd.isDirty = vm.form.$dirty;
                });

                this.$onInit = function () {

                    vm.status = enums.screenStatus.loading;

                    if (vm.wnd !== null) {
                        vm.wnd.onClose = vm.cancel;
                    }

                    vm.wnd.processing = true;

                    if (vm.initParams) {
                        vm.data = angular.copy(vm.initParams);                        
                        vm.id = vm.data.id;

                        vm.data.changeAllLanguages = true; // default to change all languages for tread compounds
                    }

                    vm.readonly = !vm.config.user.isSystemAdmin;

                    vm.buttons = [
                        {
                            primary: true,
                            cancel: false,
                            value: "common.save_label",
                            name: "saveButton",
                            click: "save",
                            type: enums.buttonTypes.button
                        }
                    ];

                    vm.buttonStates = {
                        saveButton: {
                            visible: !vm.readonly,
                            disabled: true
                        }
                    };

                    vm.buttonMethods = {
                        save: vm.save
                    };

                    vm.status = enums.screenStatus.ready;

                    $timeout(function () {
                        vm.setPristine();
                        vm.wnd.processing = false;
                    });
                }

                vm.setPristine = function () {
                    if (vm.form) {
                        vm.form.$setPristine();
                    }
                };

                vm.save = function () {

                    var criteria = {
                        id: vm.id,
                        name: vm.data.description,
                        manufacturerId: helperSvc.getKey(vm.data.manufacturer),
                        active: vm.data.isActive,
                        changeAllLanguages: vm.data.changeAllLanguages
                    };

                    vm.wnd.processing = true;

                    return amtCommandQuerySvc.post((!!vm.id ? vm.referenceDataUrls.modifyTreadCompound : vm.referenceDataUrls.addTreadCompound), criteria).then(function (response) {

                        notifySvc.success(amtXlatSvc.xlat('compound.saveSuccessful'));

                        if (vm.wnd.onDataChanged) {
                            vm.wnd.onDataChanged();
                        }

                        vm.closeWindow();
                    }).catch(function (error) {
                        errorReporter.logError(error);
                    }).finally(function () {
                        vm.wnd.processing = false;
                    });
                };

                vm.closeWindow = function () {
                    WindowFactory.closeWindow(vm.wnd);
                };

                vm.cancel = function () {
                    confirmSvc.confirmSaveChange(vm.form.$dirty).then(function () {
                        vm.form.dirty = false;
                        vm.closeWindow();
                        return true;
                    });

                    return false;
                };

                $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing'], function () {
                    vm.buttonStates.saveButton.disabled = vm.form.$invalid || vm.form.$pristine || vm.wnd.processing;
                });
            }
        ]
    });
