//import angular from 'angular';
import tmpl from './forecastReportsPopup.html';



angular.module("app.site").component("forecastReportPopup",
        {
            template: tmpl,
            bindings: {
                initParams: '=',
                buttonMethods: '=',
                buttonStates: '=',
                buttons: '=',
                closeOnSave: '=',
                wnd: '='
            },
            controller: forecastReportPopupCtrl
        });

    // @ts-ignore
    forecastReportPopupCtrl.$inject = ['$stateParams', '$state', '$scope', 'amtConstants', 'confirmSvc', 'WindowFactory', 'enums'];

    function forecastReportPopupCtrl($stateParams, $state, $scope, amtConstants, confirmSvc, WindowFactory, enums) {

        var vm = this;
        $scope.vm = this;

        vm.apiUrls = amtConstants.url;

        vm.model = {
            reports: null
        };

        this.$onInit = function () {

            vm.forecastId = vm.initParams.forecastId;
            vm.forecastStartDate = vm.initParams.forecastStartDate;
            vm.forecastEndDate = vm.initParams.forecastEndDate;
            vm.name = vm.initParams.name;

            vm.buttons = [
                {
                    primary: true,
                    cancel: false,
                    value: 'forecastReportList.createReports',
                    name: 'viewButton',
                    click: 'view',
                    type: enums.buttonTypes.button
                },
                {
                    primary: false,
                    cancel: true,
                    value: 'common.cancel_label',
                    name: 'cancelButton',
                    click: 'cancel',
                    type: enums.buttonTypes.button
                }
            ];

            vm.buttonStates = {
                viewButton: {
                    visible: true,
                    disabled: true
                },
                cancelButton: {
                    visible: true,
                    disabled: false
                }
            };

            vm.buttonMethods = {
                view: vm.onViewForecast,
                cancel: vm.onCancelPopup
            };
        }

        vm.onViewForecast = function () {
            
            $state.go('app.site.forecastreport',
                {
                    forecastid: vm.forecastId,
                    reports: vm.model.reports,
                    name: vm.name,
                    forecaststartdate: vm.forecastStartDate,
                    forecastenddate: vm.forecastEndDate
                }, { location: false });

            WindowFactory.closeWindow(vm.wnd);
        };

        vm.onCancelPopup = function () {
            WindowFactory.closeWindow(vm.wnd);
        };

        $scope.$watchGroup(['vm.form.$invalid', 'vm.form.$pristine', 'vm.wnd.processing'], function () {
            vm.buttonStates.viewButton.disabled = vm.form.$invalid || vm.form.$pristine || !!vm.wnd.processing;
        });
    }
