//import angular from 'angular';


angular.module('app.site').factory("forecastReport", [
        '$sce', '$state', 'amtConstants',
        function forecastReport($sce, $state, amtConstants) {

            var report = {
                reportName: "",
                reportParams: "",
                content: "",
                isShowIndicator: true,

                currentPage: 1,
                clearSession: true,
                currentPageText: "",
                isFirstPage: true,
                isLastPage: true,
                totalPages: 1,

                init: function() {
                    this.reportName = "";
                    this.reportParams = "";
                    this.content = "";
                    this.isShowIndicator = true;

                    this.currentPage = 1;
                    this.clearSession = true;
                    this.currentPageText = "";
                    this.isFirstPage = true;
                    this.isLastPage = true;
                    this.totalPages = 1;
                },

                //=========================================
                // Load Report        
                //=========================================        

                buildReportLinkByParams: function buildReportLinkByParams(serverApiUrl, currentPage, format, reportParams) {
                    return [
                        serverApiUrl || "forecasting/reports/reportname", "/", this.reportName, "/page/", currentPage, "/format/",
                        format || "html", "/clearsession/true?", reportParams
                    ].join('');
                },

                //=========================================
                // Action on prev, next navigation
                //=========================================
                updatePaginationState: function(currentPage) {
                    this.isFirstPage = (currentPage === 1 ? true : false);
                    this.isLastPage = (currentPage === this.totalPage ? true : false);
                },

                initialPaginationBar: function(totalPage) {
                    if (totalPage === 1) {
                        this.isFirstPage = true;
                        this.isLastPage = true;
                    }
                },

                setCurrentPageText: function(currentPage, totalPage) {
                    this.currentPageText = currentPage + " / " + totalPage;
                },

                //=========================================
                // Export PDF, Excel, Word
                //=========================================
                exportFile: function($event, formatType) {
                    var exportFileUrl =
                        this.buildReportLinkByParams(amtConstants.url.forecastReportUrl, this.currentPage, formatType, this.reportParams);

                    var hiddenIFrameID = 'hiddenDownloader' + this.reportName;
                    var iframe = window.document.createElement('iframe');
                    iframe.id = hiddenIFrameID;
                    iframe.style.display = 'none';
                    window.document.body.appendChild(iframe);
                    iframe.src = exportFileUrl;
                },

                onLoadingFinished: function(currentPage, totalPage, clearSession) {
                    if (clearSession === true) {
                        this.clearSession = false;
                        this.totalPage = totalPage;
                    }

                    report.currentPage = currentPage;
                    this.setCurrentPageText(currentPage, this.totalPage);
                    this.updatePaginationState(currentPage);
                    this.initialPaginationBar(totalPage);
                }
            };

            return report;
        }
    ]);
