/**
 * @ngdoc directive
 * @name amtFramework.filter.directive:amtMultiFieldPickerFilteritem
 * @sortOrder 20
 * @restrict E
 * @requires amtFramework.filter.directive:amtFilter
 * 
 * @param {string} label Label to display above the control
 * @param {string} propertyName Name of the property that is added to filterValues property of {@link amtFramework.filter.directive:amtFilter amtFilter}
 * @param {array} primaryKey Names of the fields that make up the primary key. Should only contain 1 field, eg ['Id']
 * @param {string} url Url to get the data from.
 * @param {expression=} [isRequired=false] Required
 * @param {expression=} [isResetDependency=false] ???
 *
 * @description
 * Filter item that let's the user filter on a selected value selected from a list. Only adds the value to filtervalues when the user selects a value.
 * 
 * @example
 * See {@link amtFramework.filter.directive:amtFilter amtFilter}
 */
//import angular from 'angular';
import tmpl from './amtMultiFieldPickerFilteritem.html';

angular.module('amtFramework.filter').directive('amtMultiFieldPickerFilteritem', function() {
            return {
                require: '^amtFilter',
                restrict: 'E',
                transclude: true,
                scope: {
                    label: '@',
                    propertyName: "@",
                    primaryKey: "@",
                    url: '@',
                    isRequired: '=?',
                    isResetDependency: '=?'
                },
                template: tmpl,
                link: function(scope: any, elem, attr, filterCtrl) {
                    scope.filterItem = { propertyName: scope.propertyName };
                    filterCtrl.addFilterItem(scope.filterItem);
                    scope.$watch('selectedItem', function() {
                        if (scope.selectedItem) {
                            scope.filterItem.value = scope.selectedItem[scope.primaryKey];
                        }
                    });
                }
            };
});
