//import angular from 'angular';


    angular.module("app").factory("amtConstants", function () {
        return {
            emptyGuid: '00000000-0000-0000-0000-000000000000',
            componentHoursMaxLength: 5,
            componentDistanceMaxLength: 7,
            vehicleHoursMaxLength: 6,
            vehicleDistanceMaxLength: 7,
            costMaxLength: 15,
            depthMaxLength: 4,
            windowTopMarginMobile: 10,
            secondHandPreviousClient: 'Previous Client',
            secondHandPreviousSite: 'Previous Site',
            removalOrder: ['chain', 'tyre', 'rim'],
            fitmentOrder: ['rim', 'tyre', 'chain'],
            url: {                    
                getForecastSummary: 'forecasting/getForecastSummary',
                exportForecastSummary: 'forecasting/exportForecastSummary',
                recalculateForecast: 'forecasting/recalculateForecast',
                getForecastVehicles: baseApiUrl + 'forecasting/getForecastVehicles',
                getForecastNewVehicleSchedule: baseApiUrl + 'forecasting/getForecastNewVehicleSchedule',
                getForecastExistingVehicleSchedule: baseApiUrl + 'forecasting/getForecastExistingVehicleSchedule',                    
                updateVehicleEndDate: 'forecasting/updateVehicleEndDate',
                updateVehicleStartDate: 'forecasting/updateVehicleStartDate',
                updateVehicleExcludeFromForecast: 'forecasting/updateVehicleExcludeFromForecast',
                getVehicleTyreStrategies: "forecasting/tyrestrategies/getVehicleTyreStrategies",
                getVehicleSurveyFrequencies: "fieldSurveyFrequency/GetVehicleSurveyFrequencies",
                setVehicleSurveyFrequency: "fieldSurveyFrequency/SetVehicleSurveyFrequency",
                getReportKeyNamePair: baseApiUrl + "forecasting/reports/getReportKeyNamePair",
                updateVehicleTyreStrategy: "forecasting/tyrestrategies/update",

                //Forecast (Taken from: app/modules/forecasting/config/apiUrlConfig.ts)
                createForecast: 'forecasting/createForecast',
                checkForecastProgress: 'forecasting/checkForecastProgress',
                updateForecast: 'forecasting/updateForecast',
                getForecastVehicleMonthlyUsage: 'forecasting/vehicle/getVehicleMonthlyUsages',
                exportVehicleMonthlyUsages: 'forecasting/vehicle/exportVehicleMonthlyUsages',
                getForecastMonths: 'forecasting/getForecastMonths',
                updateVehicleMonthlyUsage: 'forecasting/vehicle/updateMonthlyUsage',
                saveMonthlyUsages: 'forecasting/vehicle/saveMonthlyUsages',
                clearVehicleUsage: 'forecasting/vehicle/clearVehicleUsage',
                getForecastDashboardData: baseApiUrl + 'forecasting/dashboard/getForecastDashboardData',
                forecastReportUrl: baseApiUrl + 'forecasting/reports/reportName',
                getDashboardForecastMonths: 'forecasting/dashboard/getDashboardForecastMonths',
                getDashboardForecastWeeks: 'forecasting/dashboard/getDashboardForecastWeeks',
                getTyreOccurrenceList: baseApiUrl + 'forecasting/dashboard/getTyreOccurrencelist',
                getRimOccurrenceList: baseApiUrl + 'forecasting/dashboard/getRimOccurrencelist',
                getForecastDetails: 'forecasting/getForecastDetails',
                getComponentTypes: 'forecasting/getComponentTypes',

                //Forecast Rim Tab
                getForecastTyres: baseApiUrl + 'forecasting/getForecastTyres',
                getForecastRims: baseApiUrl + 'forecasting/getForecastRims',
                getForecastList: baseApiUrl + 'forecasting/getForecastList',         
                exportForecastList: 'forecasting/exportForecastList',
                getForecastTypes: 'forecasting/reference/getForecastTypes',
                getForecastMethods: 'forecasting/reference/getForecastMethods',
                getTaskTypes: 'forecasting/reference/getTaskTypes',
                getRotationStrategies: 'forecasting/reference/getRotationStrategies',
                getForecastingFleets: 'forecasting/reference/getFleets',
                getBudgetForecastStatusTypes: 'forecasting/reference/getBudgetForecastStatusTypes',
                checkApprovedBudgetForecast: 'forecasting/checkApprovedBudgetForecast',
                copyForecast: 'forecasting/copyForecast',
                deleteForecast: 'forecasting/deleteForecast/{0}',
                restoreForecast: 'forecasting/restoreForecast/{0}',
                hardDeleteForecast: 'forecasting/hardDeleteForecast/{0}',
                addModifyNewVehicle: 'forecasting/addModifyNewVehicle',
                getForecastNewVehicleDetails: 'forecasting/getForecastNewVehicleDetails',                                        
                getVehicleSpecifications: baseApiUrl + 'forecasting/reference/getVehicleSpecifications',
                getValidFitments: 'forecasting/reference/getValidFitments',
                getAvailableTyreSpecifications: baseApiUrl + 'forecasting/getAvailableTyreSpecifications',
                getForecastTyresByVehicleSpecification: baseApiUrl + "forecasting/getForecastTyresByVehicleSpecification",
                getVehicleTyreSpecifications: 'forecasting/getVehicleTyreSpecifications',
                getVehicleSpecificationTyreSpecifications: 'forecasting/getVehicleSpecificationTyreSpecifications',
                getVehicleRotationStrategies: 'forecasting/getVehicleRotationStrategies',

                updateForecastOverride: 'forecasting/updateForecastOverride',                 
                updateForecastFitment: "forecasting/updateForecastFitment",
                updateForecastVehicleTyreSpecifications: "forecasting/updateForecastVehicleTyreSpecifications",
                addNewTyreSpecification: "forecasting/addNewTyreSpecification",                    
                getVehicleSpecificationNewTyreChangeoutPositions: "forecasting/tyreStrategies/getVehicleSpecificationNewTyreChangeoutPositions",
                updateVehicleSpecificationNewTyreChangeoutPositions: "forecasting/tyreStrategies/updateVehicleSpecificationNewTyreChangeoutPositions",         
                updateVehicleRotationStrategies: "forecasting/tyreStrategies/updateVehicleRotationStrategies",

                //Rim Specification Edit
                getAvailableRimSpecifications: baseApiUrl + 'forecasting/getAvailableRimSpecifications',
                getForecastRimsByVehicleSpecification: baseApiUrl + "forecasting/getForecastRimsByVehicleSpecification",                   
                addNewRimSpecification: "forecasting/addNewRimSpecification",

                //Orders
                getForecastRimInventory: baseApiUrl + 'forecasting/getForecastRimInventory',
                exportForecastRimInventory: 'forecasting/exportForecastRimInventory',
                getForecastTyreInventory: baseApiUrl + 'forecasting/getForecastTyreInventory',
                exportForecastTyreInventory: 'forecasting/exportForecastTyreInventory',
                getForecastOrders: baseApiUrl + 'forecasting/getForecastOrders',                  
                exportForecastOrders: 'forecasting/exportForecastOrders',                                    
                createUpdateOrders: "forecasting/createUpdateOrders",
                convertForecastToPurchaseOrder: 'forecasting/convertForecastToPurchaseOrder',
                generateTyreOrders: "forecasting/generateTyreOrders",
                generateRimOrders: "forecasting/generateRimOrders",

                //menu
                //getMenuList: "security/getMenuList",

                //Reports
                getReports: 'reports/getReportList',
                deleteReportSession: 'reports/deleteSession',                

                //Users
                getUserSession: 'usersecurity/getUserSession',
                getFilteredUserList: baseApiUrl + 'usersecurity/getUsersFiltered'
            }
        };
    });
